<template>
    <div>
        <div class="row mb-2">
            <div class="col-12">
                <div class="d-flex justify-content-between">
                    <strong>Responsable</strong>
                    <div class="d-flex align-items-center">
                        <button
                            v-tooltip="getTooltip('Réinitialiser les tests d\'envois des mails ou des sms')"
                            class="btn btn-secondary btn-square btn-sm mr-2"
                            @click="canShowResetLimiterModal = true"
                            :disabled="loading">
                            <feather type="refresh-ccw"></feather>
                        </button>
                        <button
                            v-tooltip="getTooltip($t('labels.form.actions.edit'))"
                            class="btn btn-success btn-square btn-sm mr-2"
                            @click="$emit('edit-owner')"
                            :disabled="loading">
                            <feather type="edit"></feather>
                        </button>
                        <button
                            v-tooltip="getTooltip($t('labels.form.actions.delete'))"
                            class="btn btn-danger btn-square btn-sm"
                            @click="deleteOwner()"
                            :disabled="loading">
                            <feather type="trash-2"></feather>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <label for="category">Prénom :</label>
            </div>
            <div class="col-7">
                {{ capitalize(ownerDetails.firstname) }}
            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <label for="category">Nom :</label>
            </div>
            <div class="col-7">
                {{ capitalize(ownerDetails.lastname) }}
            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <label for="category">Email :</label>
            </div>
            <div class="col-7">
                {{ ownerDetails.email }}
            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <label for="category">Téléphone :</label>
            </div>
            <div class="col-7">
                {{ ownerDetails.tel }}
            </div>
        </div>
        <div class="row" v-if="isNoShow">
            <div class="col-5">
                <label for="category">N° client Fiducial :</label>
            </div>
            <div class="col-7">
                {{ ownerDetails.fiducial_client_id || "Non renseigné" }}
            </div>
        </div>
        <div class="row" v-if="isYservices">
            <div class="col-5">
                <label for="category">Identifiant du compte Yproximité :</label>
            </div>
            <div class="col-7">
                {{ ownerDetails.yprox_user_id || "Non renseigné" }}
            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <label for="category">Date d'inscription :</label>
            </div>
            <div class="col-7">
                {{ moment(ownerDetails.created_at).format("DD MMMM Y") }}
                <button v-if="isYservices" class="btn btn-success btn-sm btn-circle ml-2" :disabled="loading" @click="sendResetPasswordLink">
                    Envoyer un mail de réinitialisation du mot de passe
                </button>
            </div>
        </div>
        <div class="row" v-if="!ownerDetails.had_active_subscription">
            <div class="col-12 text-warning">
                <feather type="alert-triangle" class="text-warning mr-1" />Ce restaurateur n'a jamais payé de licence
            </div>
        </div>
        <reset-rate-limiter-modal v-if="canShowResetLimiterModal" :userId="ownerDetails.id" @close="canShowResetLimiterModal = false" />
    </div>
</template>

<script>
import moment from "moment";
import ResetRateLimiterModal from "../Modals/Admin/Clients/ResetRateLimiterModal.vue";

export default {
    data() {
        return {
            moment,
            loading: false,
            canShowResetLimiterModal: false,
        };
    },
    props: {
        ownerDetails: {
            required: true,
            type: Object,
        },
    },
    components: {
        ResetRateLimiterModal,
    },
    methods: {
        sendResetPasswordLink() {
            if (!confirm(`Souhaitez-vous vraiment envoyer un lien de réinitialisation de mot de passe à l'adresse ${this.ownerDetails.email} ?`)) {
                return;
            }

            this.loading = true;

            this.httpPost("/api/admin/owner/password/reset", { email: this.ownerDetails.email }).finally(() => (this.loading = false));
        },
        deleteOwner() {
            if (
                !confirm(
                    `Supprimer le restaurateur ${this.ownerDetails.firstname} ${this.ownerDetails.lastname} ? Cette action est irréversible et supprime l'ensemble des données.`
                )
            ) {
                return;
            }

            this.loading = true;

            this.$store
                .dispatch("admin/deleteOwner", { owner_id: this.ownerDetails.id })
                .then((response) => {
                    this.notifySuccess(response);

                    this.$emit("owner-deleted");
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => (this.loading = false));
        },
    },
};
</script>
