<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="alert alert-danger" v-else-if="!website_enabled">Votre site web n'est pas activé</div>
        <template v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div
                        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">Configuration du référencement</h5>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-3">
                            <div class="col-12">
                                <strong>Mentions Légales</strong>
                                <a
                                    target="_blank"
                                    :href="`/api/website/${restaurant_id}/default_legal_mentions`"
                                    class="btn btn-sm btn-circle btn-success float-right">
                                    Voir les mentions légales
                                </a>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <p>
                                    Nous mettons à votre disposition des Mentions Légales standards pour vous permettre
                                    de mettre en place site web rapidement et dans les meilleures conditions. Nous vous
                                    invitons à en prendre connaissance attentivement.
                                </p>
                                <p>
                                    Nous vous conseillons toutefois d'intégrer vos propres Mentions Légales en important
                                    votre fichier ci-dessous.
                                </p>
                                <small class="mb-0 text-warning"
                                    >Sans action de votre part, les mentions par défaut seront appliquées.</small
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-7">
                                <FileUploaderComponent
                                    :disabled="!has_right_to_update_config"
                                    :src="website_settings.legal_mention_pdf"
                                    suffixId="legal_mention_pdf"
                                    :postURI="baseURILegalMention"
                                    :deleteURI="baseURILegalMention"
                                    :callBackPost="
                                        (response) => {
                                            this.website_settings.legal_mention_pdf = response.data.file_name;
                                        }
                                    "
                                    :callBackDelete="
                                        () => {
                                            this.website_settings.legal_mention_pdf = null;
                                        }
                                    " />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent";
import FileUploaderComponent from "../../../components/forms/FileUploaderComponent";

export default {
    data() {
        return {
            loading: 0,
        };
    },
    props: {
        website_settings: {
            type: Object,
            required: true,
        },
        website_enabled: {
            required: true,
        },
    },
    computed: {
        baseURILegalMention() {
            return `/api/website/${this.restaurant_id}/legal_mention`;
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read_config() {
            return this.rights.includes("website.config.read");
        },
        has_right_to_update_config() {
            return this.rights.includes("website.config.update");
        },
        has_right_to_publish_config() {
            return this.rights.includes("website.config.publish");
        },
    },
    components: {
        LoaderComponent,
        FileUploaderComponent,
    },
};
</script>
