<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="error">
                {{ error }}
            </div>
            <div v-else>
                <div v-if="formErrors && formErrors.message" class="alert alert-danger">
                    {{ $tl("errors.common.unknown") }}
                </div>
                <div v-if="formSuccess" class="alert alert-success">
                    {{ formSuccess }}
                </div>

                <LoaderComponent v-if="formLoading" :message="$t('labels.saveInProgress')" />
                <form v-else @submit="postForm" method="post" class="mb-4 mt-5">
                    <p v-if="slot">
                        {{
                            $tl("questions.booking.services.openSlotForDate", restaurant_id, {
                                hour: slot.hour_start,
                                service: service.name,
                                date: formatDate(date),
                            })
                        }}
                    </p>
                    <p v-else>
                        {{
                            $tl("questions.booking.services.openServiceForDate", restaurant_id, {
                                service: service.name,
                                date: formatDate(date),
                            })
                        }}
                    </p>
                    <div class="sepa"></div>
                    <div v-if="showSaveButton" class="form-row mb-2 mt-4">
                        <div class="col-12">
                            <input
                                type="submit"
                                name="submit"
                                :value="$t('labels.form.actions.continue')"
                                class="btn btn-success btn-circle btn-sm" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../LoaderComponent.vue";

export default {
    props: {
        restaurant_id: {
            required: true,
        },
        service_id: {
            required: true,
        },
        slot_id: {
            default: null,
        },
        date: {
            required: true,
        },
        showSaveButton: {
            required: false,
            default: true,
        },
    },
    data() {
        return {
            formLoading: false,
            formErrors: null,
            formSuccess: null,
            loading: false,
            error: null,
            service: null,
            slot: null,
        };
    },
    methods: {
        initData() {
            if (this.service_id && this.restaurant_id) {
                this.loading = true;

                axios
                    .get(`/api/restaurants/${this.restaurant_id}/services/${this.service_id}`)
                    .then((response) => {
                        this.service = response.data;
                        if (this.slot_id) {
                            this.service.slots.forEach((slot) => {
                                if (slot.id === this.slot_id) {
                                    this.slot = slot;
                                }
                            });
                        }
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.error = this.getErrorMsgFromErrorResponse(error);
                        this.loading = false;
                    });
            }
        },
        postForm: function (e) {
            if (e !== null) {
                e.preventDefault();
            }
            this.formLoading = true;
            this.formSuccess = null;
            this.formErrors = null;

            // adding a new closure
            const axiosPromise = axios.delete(`/api/restaurants/${this.restaurant_id}/services/full`, {
                data: {
                    date: this.date,
                    services: this.formatForm(),
                },
            });

            if (this.showSaveButton) {
                axiosPromise
                    .then((response) => {
                        // display success message
                        this.formLoading = false;
                        this.formSuccess = this.$tl("success.booking.services.removeFull", this.restaurant_id);
                        // empty form fields
                        this.emptyForm();
                    })
                    .catch((error) => {
                        this.formErrors = error.response ? error.response.data : {};
                        this.formLoading = false;
                    });
            } else {
                return axiosPromise;
            }
        },
        setFormErrors(value) {
            this.formErrors = value;
        },
        setFormLoading(value) {
            this.formLoading = value;
        },
        formatDate(date) {
            return this.displayDate(date, this.DATE_FULL);
        },
        formatForm() {
            var result = [];

            result.push({
                id: this.service_id,
                value: true,
            });

            if (this.slot_id) {
                result[0].slots = [];
                result[0].slots.push({
                    id: this.slot_id,
                    value: true,
                });
            }

            return result;
        },
    },
    components: {
        LoaderComponent,
    },
    watch: {
        restaurant_id: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal && newVal !== oldVal) {
                    this.initData();
                }
            },
        },
        service_id: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal && newVal !== oldVal) {
                    this.initData();
                }
            },
        },
    },
};
</script>
