<template>
    <div class="d-inline-block">
        <button type="button" class="btn btn-sm btn-danger btn-circle" @click="showEventsForDay()">
            <feather @click.stop="showEventsForDay(true)" class="ml-1" type="plus" />
            <feather class="mx-1" type="volume-2" />
            {{ $tl("labels.booking.customEvents.titleForDay") }} ({{ eventsForDay.length }})
        </button>

        <list-custom-events ref="listCustomEvents" v-bind="{ restaurantId, date: date.toISODate() }" />
    </div>
</template>

<script>
import { DateTime } from "luxon";
import listCustomEvents from "./listCustomEvents.vue";

export default {
    props: {
        restaurantId: {
            type: Number,
            required: true,
        },
        date: {
            type: DateTime,
            required: true,
        },
    },
    computed: {
        restaurant() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurantId) || null;
        },
        restaurantCustomEvents() {
            return this.restaurant !== null ? this.restaurant.custom_events.data : [];
        },
        eventsForDay() {
            const date = this.date.toJSDateCustom(false);

            return this.restaurantCustomEvents.filter(
                (customEvent) => this.getDateTime(customEvent.date_begin) <= date && this.getDateTime(customEvent.date_end) >= date
            );
        },
    },
    methods: {
        showEventsForDay(showAddModal = false) {
            this.$refs.listCustomEvents.open();

            if (showAddModal) {
                this.$nextTick(() => this.$refs.listCustomEvents.addCustomEvent());
            }
        },
    },
    components: {
        listCustomEvents,
    },
};
</script>
