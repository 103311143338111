var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pb-5 auto-website",
      staticStyle: { height: "initial !important" },
    },
    [
      _vm.loading
        ? _c("LoaderComponent")
        : !_vm.website_enabled || !_vm.ga_enabled
        ? _c("div", { staticClass: "ml-2 alert alert-danger" }, [
            _vm._v(
              "\n        Vous devez d'abord publier votre site pour voir les statistiques\n    "
            ),
          ])
        : _vm.error
        ? _c("div", { staticClass: "ml-2 alert alert-danger" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                  },
                  [
                    _c("h5", { staticClass: "title mt-2" }, [
                      _vm._v("Statistiques - Google Analytics"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0 mb-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filter.from,
                                expression: "filter.from",
                              },
                            ],
                            staticClass: "custom-select mb-0 w-auto",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.filter,
                                  "from",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(Object.keys(this.periods), function (period) {
                            return _c(
                              "option",
                              { key: period, domProps: { value: period } },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm.periods[period]) +
                                    "\n                                    "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "ml-3 btn btn-sm btn-success btn-circle",
                            on: {
                              click: function ($event) {
                                return _vm.fetchStats()
                              },
                            },
                          },
                          [_vm._v("Appliquer")]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.stats && _vm.stats.main
              ? _c("div", { staticClass: "row m-0" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "row m-0 mb-2" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("strong", { staticClass: "text-success" }, [
                          _vm._v(_vm._s(_vm.displayingFilterDate)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row m-0 mb-2" }, [
                      _c("div", { staticClass: "col-lg-3 col-md-6 p-1" }, [
                        _c(
                          "div",
                          { staticClass: "border-light p-3 b-radius-20" },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getGaMetricLabel(
                                    _vm.GA_METRIC_TOTAL_USERS.value
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("strong", { staticClass: "value" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.stats.main[
                                        _vm.GA_METRIC_TOTAL_USERS.value
                                      ]
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-lg-3 col-md-6 p-1" }, [
                        _c(
                          "div",
                          { staticClass: "border-light p-3 b-radius-20" },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getGaMetricLabel(
                                    _vm.GA_METRIC_SESSIONS.value
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("strong", { staticClass: "value" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.stats.main[
                                        _vm.GA_METRIC_SESSIONS.value
                                      ]
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-lg-3 col-md-6 p-1" }, [
                        _c(
                          "div",
                          { staticClass: "border-light p-3 b-radius-20" },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getGaMetricLabel(
                                    _vm.GA_METRIC_AVERAGE_SESSION_DURATION.value
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("strong", { staticClass: "value" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.stats.main[
                                        _vm.GA_METRIC_AVERAGE_SESSION_DURATION
                                          .value
                                      ]
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-lg-3 col-md-6 p-1" }, [
                        _c(
                          "div",
                          { staticClass: "border-light p-3 b-radius-20" },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getGaMetricLabel(
                                    _vm.GA_METRIC_SCREEN_PAGE_VIEWS_PER_SESSION
                                      .value
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("strong", { staticClass: "value" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.stats.main[
                                        _vm
                                          .GA_METRIC_SCREEN_PAGE_VIEWS_PER_SESSION
                                          .value
                                      ]
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }