var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "border-light b-radius-20 p-4 text-center" },
    [
      _c("div", { staticClass: "row mb-2" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "div",
              { staticClass: "rounded-circle div-profil-svg" },
              [
                _c("feather", {
                  staticClass: "feather-22",
                  attrs: { type: "user" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("inline-client", {
              attrs: {
                client: _vm.client,
                "module-enum": _vm.moduleEnum,
                "hide-name": "",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sepa" }),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-0 radio" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("small", [
            _vm._v(_vm._s(_vm.getClientCivilityLabelLong(_vm.client.civility))),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 text-uppercase font-weight-bold" }, [
          _vm._v(
            _vm._s(_vm.client.firstname) + " " + _vm._s(_vm.client.lastname)
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm._v("\n            " + _vm._s(_vm.client.company) + "\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sepa" }),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-1" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("a", { attrs: { href: `mailto:${_vm.client.email}` } }, [
            _vm._v(_vm._s(_vm.client.email)),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("a", { attrs: { href: `tel:${_vm.client.tel}` } }, [
            _c(
              "div",
              [
                _c("vue-tel-input", {
                  staticClass: "d-flex phone-view border-0",
                  attrs: {
                    defaultCountry: "FR",
                    name: "telInput",
                    enabledCountryCode: true,
                    mode: "international",
                    inputClasses: "m-0 border-0",
                    disabled: "",
                    placeholder: "-- -- -- -- --",
                  },
                  model: {
                    value: _vm.client.tel,
                    callback: function ($$v) {
                      _vm.$set(_vm.client, "tel", $$v)
                    },
                    expression: "client.tel",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._t("additionalInfos"),
      _vm._v(" "),
      _c("div", { staticClass: "sepa" }),
      _vm._v(" "),
      _vm.isChangeInProgress
        ? [
            _c(
              "button",
              {
                staticClass: "btn-sm btn btn-secondary btn-circle",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("cancel-in-progress")
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-success btn-circle",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("update-in-progress")
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.save")) +
                    "\n        "
                ),
              ]
            ),
          ]
        : [
            _vm.moduleEnum.value === _vm.MODULE_TYPE_BOOKING.value
              ? _c(
                  "div",
                  { staticClass: "badge badge-secondary text-blue-light mb-2" },
                  [
                    _vm.client.overResaCount > 0
                      ? [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("small", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tcl(
                                      "labels.booking.reservations.nbHonored",
                                      _vm.client.overResaCount
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      : [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("small", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.reservations.firstOne"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("small", {
                  domProps: {
                    innerHTML: _vm._s(_vm.formatComment(_vm.client.comment)),
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            !_vm.hideClientEditBtn
              ? _c("div", { staticClass: "row mt-3" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      !_vm.client.is_deleted
                        ? _c(
                            "router-link",
                            {
                              staticClass: "btn btn-sm btn-success btn-circle",
                              attrs: {
                                to: {
                                  name: `${_vm.moduleEnum.urlValue}.clients.form`,
                                  params: { client_id: _vm.client.id },
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$tl("labels.clients.show")) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-success btn-circle",
                              attrs: { type: "button", disabled: "" },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$tl("labels.clients.show")) +
                                  "\n                "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }