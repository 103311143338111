<template>
    <div>
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <div v-if="menuOptionId !== null">
                        <h5 class="title mt-2">
                            {{ $t(`labels.booking.${translationKey}.edit`) }}
                            <span class="value">{{ menuOptionName }}</span>
                        </h5>
                    </div>
                    <div v-else>
                        <h5 class="title mt-2">
                            {{ $tl("labels.booking.menus.options.add") }}
                        </h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="row m-0">
            <div class="col-12">
                <menu-option-form
                    ref="form"
                    @fully-loaded="onFormLoaded"
                    @submit="onFormSubmitted"
                    :restaurant-id="restaurantId"
                    :menu-option-id="menuOptionId"
                    :option-type="menuOptionType"
                    show-option-type-choice
                    show-save-button />
            </div>
        </div>
    </div>
</template>

<script>
import menuOptionForm from "../../../../components/menuOptions/menuOptionForm.vue";
import MenuOptionTypeEnum from "../../../../mixins/enums/booking/MenuOptionTypeEnum.js";

export default {
    data() {
        return {
            menuOptionName: null,
        };
    },
    mixins: [MenuOptionTypeEnum],
    computed: {
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        menuOptionId() {
            const paramKey = this.menuOptionType === this.MENU_OPTION_GENERAL.value ? "general_option_id" : "menu_option_id";

            return typeof this.$route.params[paramKey] !== "undefined" ? Number.parseInt(this.$route.params[paramKey]) : null;
        },
        menuOptionType() {
            if (typeof this.$route.name === "string" && this.$route.name.includes("general_options")) {
                return this.MENU_OPTION_GENERAL.value;
            }

            return this.MENU_OPTION_MENU.value;
        },
        translationKey() {
            switch (this.menuOptionType) {
                case this.MENU_OPTION_MENU.value:
                    return "menus.options";
                case this.MENU_OPTION_GENERAL.value:
                    return "generalOptions";
                default:
                    return "";
            }
        },
    },
    methods: {
        onFormLoaded({ menuOption }) {
            this.menuOptionName = menuOption.name;
        },
        onFormSubmitted({ promise }) {
            promise.then((success) => {
                if (success !== false) {
                    this.$router.push({
                        name: "booking.restaurants.settings.menus_options",
                        params: { restaurant_id: this.restaurantId },
                    });
                }
            });
        },
    },
    components: {
        menuOptionForm,
    },
};
</script>
