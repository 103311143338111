<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Conditions Générales d'Utilisation</h5>
        <div slot="body">
            <LoaderComponent v-if="loading" />
            <template v-else>
                <p>
                    Pour accéder à {{ isNoShow ? "NoShow" : "Y-Services" }}, vous devez accepter les Conditions Générales d'Utilisation du service et
                    la Politique de Confidentialité des Données Personnelles.
                </p>
                <div class="row m-0 mt-2">
                    <label class="container-box w-100">
                        <input type="checkbox" v-model="cguAccepted" />
                        <span class="checkmark"></span>
                        Je déclare avoir pris connaissance des
                        <a class="alert-link" :href="`/cgu_global.pdf?v=${$__BUILD_ID__}`" target="_blank">
                            Conditions Générales d'Utilisation du service
                        </a>
                    </label>
                </div>
                <div class="row m-0 mt-2">
                    <label class="container-box w-100">
                        <input type="checkbox" v-model="datapolicyAccepted" />
                        <span class="checkmark"></span>
                        Je déclare avoir pris connaissance de la
                        <a class="alert-link" :href="`/datapolicy.pdf?v=${$__BUILD_ID__}`" target="_blank">
                            Politique de Confidentialité des Données Personnelles
                        </a>
                    </label>
                </div>
            </template>
        </div>
        <div slot="footer" class="d-flex">
            <button class="btn btn-success btn-sm btn-circle ml-2" :disabled="!cguAccepted || !datapolicyAccepted || loading" @click="acceptCgv">
                Valider
            </button>
        </div>
    </modal>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../LoaderComponent";

export default {
    data: function () {
        return {
            loading: false,
            cguAccepted: false,
            datapolicyAccepted: false,
        };
    },
    methods: {
        acceptCgv() {
            this.loading = true;

            axios
                .post("/api/accepted_cgvs/default")
                .then(() => {
                    this.loading = false;
                    this.close();
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            }
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>
