var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { staticClass: "modal-pax-selection" }, [
    _c(
      "h5",
      {
        staticClass: "d-flex justify-content-between w-100",
        attrs: { slot: "header" },
        slot: "header",
      },
      [
        _vm.selectedReservation.is_passing_customer
          ? [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.$tl("labels.booking.reservations.passingCustomer.title")
                  ) +
                  "\n        "
              ),
            ]
          : [
              _c(
                "span",
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.reservation")) +
                      _vm._s(_vm.$tl("labels.colon")) +
                      "\n                "
                  ),
                  _vm.selectedReservation.client !== null &&
                  _vm.selectedReservation.client.fullname !== ""
                    ? [_vm._v(_vm._s(_vm.selectedReservation.client.fullname))]
                    : [_vm._v(_vm._s(_vm.$tl("labels.clients.unknown")))],
                ],
                2
              ),
            ],
        _vm._v(" "),
        _c("span", { staticClass: "text-uppercase" }, [
          _vm._v(
            _vm._s(_vm.selectedReservation.slot.hour_start) +
              " | " +
              _vm._s(_vm.selectedReservation.nb_pers) +
              " " +
              _vm._s(_vm.$tl("labels.pax"))
          ),
        ]),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { attrs: { slot: "body" }, slot: "body" }, [
      !_vm.canDisplayTable
        ? _c(
            "div",
            _vm._l(_vm.tables, function (table, index) {
              return _c("div", { staticClass: "mb-3" }, [
                _c("p", { staticClass: "text-center" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$tl(
                        "labels.booking.seatingPlan.selectNbPeopleByTable"
                      )
                    ) +
                      " " +
                      _vm._s(table.name)
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "row col-12 m-0 paxes-container d-flex justify-content-between align-items-start",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "container-input col" },
                      [
                        table.enabledPax.length > 0
                          ? _vm._l(table.enabledPax, function (pax, subIndex) {
                              return _c(
                                "span",
                                { key: subIndex, staticClass: "radio" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "container-box" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.selectedPaxes[index]
                                                .paxSelected,
                                            expression:
                                              "selectedPaxes[index].paxSelected",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: `table-${table.id}-pax`,
                                        },
                                        domProps: {
                                          value: pax,
                                          checked: _vm._q(
                                            _vm.selectedPaxes[index]
                                              .paxSelected,
                                            pax
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.selectedPaxes[index],
                                              "paxSelected",
                                              pax
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "checkmark" }),
                                      _vm._v(
                                        " " +
                                          _vm._s(pax) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            })
                          : _c("span", { staticClass: "radio" }, [
                              _c("label", { staticClass: "container-box" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.selectedPaxes[index].paxSelected,
                                      expression:
                                        "selectedPaxes[index].paxSelected",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    name: `table-${table.id}-pax`,
                                  },
                                  domProps: {
                                    value: table.seats,
                                    checked: _vm._q(
                                      _vm.selectedPaxes[index].paxSelected,
                                      table.seats
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.selectedPaxes[index],
                                        "paxSelected",
                                        table.seats
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "checkmark" }),
                                _vm._v(
                                  " " +
                                    _vm._s(table.seats) +
                                    "\n                            "
                                ),
                              ]),
                            ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col p-0" }, [
                      !_vm.canDisplayTable
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success w-100 btn-circle",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.canDisplayTable = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.reservations.placeSelectedSeats"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ])
            }),
            0
          )
        : _c(
            "div",
            [
              _c("select-tables", {
                attrs: {
                  "remaining-paxes": _vm.remainingPaxes,
                  reservation: _vm.selectedReservation,
                  "selected-table": _vm.dropRelatedObject,
                  "current-room-index": _vm.currentRoomIndex,
                },
                on: {
                  "updated-tables": function ($event) {
                    _vm.selectedTables = $event
                  },
                },
              }),
            ],
            1
          ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex row w-100 justify-content-between px-0 mx-3",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c("div", { staticClass: "col d-flex" }, [
          _c(
            "button",
            {
              staticClass:
                "modal-default-button btn btn-secondary btn-circle mx-0",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                  "\n            "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        !_vm.canDisplayTable
          ? _c("div", { staticClass: "col p-0 d-flex" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-warning btn-circle mx-0 w-100",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("place-all", {
                        dropedOnType: _vm.dropedOnType,
                        dropRelatedObject: _vm.dropRelatedObject,
                      })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.reservations.placeEntireBooking"
                        )
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ])
          : _c(
              "button",
              {
                staticClass: "btn btn-success btn-circle mx-0",
                attrs: { type: "button" },
                on: { click: _vm.placeResa },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.place")) +
                    "\n        "
                ),
              ]
            ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }