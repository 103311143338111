<template>
    <div class="p-2">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <div>
                        <h5 class="title mt-2">Créer un nouveau client</h5>
                    </div>
                </div>
            </div>
        </div>

        <edit-owner hide-cancel-btn @added="onOwnerAdded" />
    </div>
</template>

<script>
import EditOwner from "../../components/Admin/Owners/Edit.vue";

export default {
    methods: {
        onOwnerAdded(response) {
            this.$router.push({ name: "admin.ownerDetails", params: { owner_id: response.data.id } });
        },
    },
    components: {
        EditOwner,
    },
};
</script>
