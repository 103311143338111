<template>
    <div class="pb-5 pt-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Produits</h5>
                    <div>
                        <template v-if="has_right_to_update && config && !config.display_categories">
                            <button v-if="!isOrdering" @click="startOrdering()" class="btn btn-sm btn-success mr-1 btn-circle">
                                <svg
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="css-i6dzq1 feather">
                                    <polyline points="9 5 12 2 15 5"></polyline>
                                    <polyline points="15 19 12 22 9 19"></polyline>
                                    <line x1="12" y1="2" x2="12" y2="22"></line>
                                </svg>
                                <span>Ordonner les produits</span>
                            </button>
                            <template v-else>
                                <button :disabled="loading" @click="saveOrdering()" class="btn btn-sm btn-success mr-1 btn-circle">
                                    <span>Enregistrer</span>
                                </button>
                                <button :disabled="loading" @click="cancelOrdering()" class="btn btn-sm btn-outline-secondary mr-1 btn-circle">
                                    <span>Annuler</span>
                                </button>
                            </template>
                        </template>
                        <button :disabled="!has_right_to_create" @click="addProduct()" class="btn btn-sm btn-success mr-1 btn-circle">
                            <feather type="plus"></feather>
                            <span>Ajouter un produit</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-0 pb-5">
            <div class="col-12">
                <LoaderComponent v-if="loading" />
                <div v-else>
                    <div v-if="error" class="alert alert-danger">
                        {{ error }}
                    </div>
                    <div v-else-if="has_right_to_read">
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-2">
                            <div>
                                <div style="margin-top: 4px" class="d-inline-block">
                                    <form @submit.prevent="fetchData">
                                        <div style="border-radius: 20px 0 0 20px !important" class="form-control search-client d-inline pr-1">
                                            <input class="search-input" placeholder="Nom, Description..." type="text" v-model="filter" />
                                            <button
                                                @click="resetFilter()"
                                                type="button"
                                                class="p-0 m-0 clear-search-button"
                                                style="background: none; border: none">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-x">
                                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                                </svg>
                                            </button>
                                        </div>
                                        <button
                                            type="submit"
                                            class="btn btn-sm btn-outline-secondary search-client-btn"
                                            style="border-radius: 0 20px 20px 0">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-search">
                                                <circle cx="11" cy="11" r="8"></circle>
                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                            </svg>
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div></div>
                        </div>

                        <table class="table table-sm table-striped border-bottom">
                            <thead class="border-bottom">
                                <tr>
                                    <th v-if="isOrdering">Ordre</th>
                                    <th>Nom</th>
                                    <th>Catégorie</th>
                                    <th>Statut</th>
                                    <th>Prix TTC</th>
                                    <th>Validité</th>
                                    <th>Service</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <draggable :disabled="!isOrdering" tag="tbody" v-model="products" draggable=".products">
                                <tr v-for="product in products" :key="product.id" class="products">
                                    <td v-if="isOrdering">
                                        <svg
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="css-i6dzq1 feather"
                                            style="cursor: grab; color: rgb(170, 170, 170)">
                                            <line x1="3" y1="9" x2="21" y2="9"></line>
                                            <line x1="3" y1="15" x2="21" y2="15"></line>
                                        </svg>
                                    </td>
                                    <td>
                                        <span v-if="product.name.length < 40">{{ product.name }}</span>
                                        <span v-tooltip="getTooltip(product.name)" v-else-if="product.name.length >= 40">{{
                                            product.name.substring(0, 40) + "..."
                                        }}</span>
                                    </td>
                                    <td>{{ !Array.isArray(product.gvCategory) ? product.gvCategory.name : "Aucune Catégorie" }}</td>
                                    <td>
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                type="checkbox"
                                                :disabled="!has_right_to_update"
                                                class="switch align-self-center is-rounded"
                                                v-model="product.active" />
                                            <label
                                                v-tooltip="getTooltip(product.active ? 'Actif' : 'Inactif')"
                                                @click="editActiveProduct(product)"></label>
                                        </div>
                                    </td>
                                    <td v-if="product.type === 'amount'">Montant personnalisé</td>
                                    <td v-else>{{ formatCurrency(product.price) }}</td>
                                    <td>{{ product.validity }} jours</td>
                                    <td>{{ product.services.length === nb_services ? "Tous" : "Limité" }}</td>
                                    <td>
                                        <button
                                            class="btn btn-sm btn-success btn-square"
                                            :disabled="!has_right_to_update"
                                            v-tooltip="getTooltip('Éditer')"
                                            @click="editProduct(product)">
                                            <feather type="edit" />
                                        </button>
                                        <button
                                            class="btn btn-sm btn-success btn-square"
                                            :disabled="!has_right_to_create"
                                            v-tooltip="getTooltip('Dupliquer')"
                                            @click="duplicateProduct(product)">
                                            <feather type="copy" />
                                        </button>
                                        <button
                                            class="btn btn-sm btn-danger btn-square"
                                            :disabled="!has_right_to_delete"
                                            v-tooltip="getTooltip($t('labels.form.actions.delete'))"
                                            @click="deleteProduct(product)">
                                            <feather type="trash-2" />
                                        </button>
                                    </td>
                                </tr>
                            </draggable>
                        </table>
                        <add-gift-voucher-product-modal
                            v-if="showAddGiftVoucherProductModal && (has_right_to_create || has_right_to_update)"
                            :product="product"
                            :duplicate="product.duplicate"
                            @saved="productSaved()"
                            @close="productSaved" />
                    </div>
                    <div v-else class="alert alert-danger">Désolé, vous n'avez pas le droit d'accéder à cette page.</div>
                </div>
            </div>
        </div>
        <div class="row m-0 mb-3" v-if="config && config.display_categories">
            <div class="col-12 text-muted">
                Vous avez la possibilité d'ordonner vos produits en cliquant sur "Catégories" puis sur l'icone
                <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="css-i6dzq1 feather">
                    <polyline points="9 5 12 2 15 5"></polyline>
                    <polyline points="15 19 12 22 9 19"></polyline>
                    <line x1="12" y1="2" x2="12" y2="22"></line></svg
                >.
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import addGiftVoucherProductModal from "../../../components/Modals/giftVouchers/addGiftVoucherProductModal.vue";
import draggable from "vuedraggable";

function defaultProduct() {
    return {
        id: null,
        type: "menu",
        name: "",
        description: "",
        img: null,
        price: null,
        nb_pers: null,
        tva: null,
        services: [],
        product_options: [],
        predefined_amounts: [],
        allow_custom_amounts: false,
        min_amount: null,
        max_amount: null,
        validity: null,
        terms_of_use: null,
        collect_enabled: false,
        delivery_enabled: false,
        dispatch_enabled: false,
        gvCategory: {},
    };
}

export default {
    data() {
        return {
            loading: false,
            filter: null,
            error: null,
            product_statut_enable: true,
            product_statut_disable: false,
            showAddGiftVoucherProductModal: false,
            product: defaultProduct(),
            products: null,
            nb_services: null,
            isOrdering: false,
            savedProducts: null,
            config: null,
        };
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create() {
            return this.rights.includes("gift_vouchers.products.create");
        },
        has_right_to_update() {
            return this.rights.includes("gift_vouchers.products.update");
        },
        has_right_to_delete() {
            return this.rights.includes("gift_vouchers.products.delete");
        },
        has_right_to_read() {
            return this.rights.includes("gift_vouchers.products.read");
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        getConfig() {
            this.loading = true;

            this.httpGet(`/api/gift_vouchers/${this.restaurant_id}/config`)
                .then((response) => {
                    if (response !== false) {
                        this.config = response.data.config;
                    }
                })
                .finally(() => (this.loading = false));
        },
        startOrdering() {
            this.savedProducts = _.cloneDeep(this.products);
            this.isOrdering = true;
        },
        cancelOrdering() {
            this.products = this.savedProducts;
            this.savedProducts = null;
            this.isOrdering = false;
        },
        saveOrdering() {
            this.loading = true;

            const products = this.products.map((product, index) => {
                return {
                    id: product.id,
                    order: index + 1,
                };
            });

            this.httpPost(`/api/gift_vouchers/${this.restaurant_id}/products/order`, { products })
                .then((response) => {
                    if (response !== false) {
                        this.isOrdering = false;
                        this.savedProducts = null;
                        this.fetchData();
                    }
                })
                .finally(() => (this.loading = false));
        },
        resetFilter() {
            this.filter = null;
            this.fetchData();
        },
        addProduct() {
            this.product = defaultProduct();
            this.showAddGiftVoucherProductModal = true;
        },
        editProduct(product) {
            this.product = _.cloneDeep(product);
            this.showAddGiftVoucherProductModal = true;
        },
        duplicateProduct(product) {
            this.product = _.cloneDeep(product);
            this.product.id = null;
            if (this.product.name) {
                this.product.name += " - Copie";
            }
            this.product.img = null;
            this.product.duplicate = true;
            this.showAddGiftVoucherProductModal = true;
        },
        deleteProduct(product) {
            if (!confirm(this.$tl("questions.giftVouchers.products.delete"))) {
                return;
            }

            this.loading = true;

            this.httpDelete(`/api/gift_vouchers/${this.restaurant_id}/products/${product.id}`)
                .then((response) => {
                    if (response !== false) {
                        this.fetchData();
                    }
                })
                .finally(() => (this.loading = false));
        },
        productSaved() {
            this.showAddGiftVoucherProductModal = false;
            this.fetchData();
        },
        fetchData() {
            this.loading = true;
            this.error = null;
            this.products = null;
            this.nb_services = null;

            let url = `/api/gift_vouchers/${this.restaurant_id}/products?include=gvCategory`;

            if (this.filter !== null) {
                url += "?filter=" + this.filter;
            }

            axios
                .get(url)
                .then((response) => {
                    this.loading = false;
                    this.products = response.data.products;
                    this.nb_services = response.data.nb_services;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        editActiveProduct(product) {
            if (!this.has_right_to_update) {
                return;
            }

            this.loading = true;
            this.error = null;

            product.active = !product.active;

            this.httpPut(`/api/gift_vouchers/${this.restaurant_id}/products/${product.id}/active`, { active: product.active })
                .then((response) => {
                    if (response === false) {
                        product.active = !product.active;
                    }
                })
                .finally(() => (this.loading = false));
        },
    },
    components: {
        addGiftVoucherProductModal,
        LoaderComponent,
        draggable,
    },
    created() {
        if (this.has_right_to_read) {
            this.fetchData();
            this.getConfig();
        }
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
