<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("questions.booking.services.full", restaurant_id) }}</h5>
        <div slot="body">
            <set-service-full
                ref="addServiceFull"
                :restaurant_id="restaurant_id"
                :service_id="service_id"
                :slot_id="slot_id"
                :date="date"
                :setFree="setFree"
                :readonly="readonly"
                :prefill="prefill"
                :showSaveButton="false" />
        </div>
        <div slot="footer" class="d-flex">
            <button class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="$emit('close')">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button class="btn btn-success btn-sm btn-circle ml-2" @click="save">
                {{ $tl("labels.form.actions.save") }}
            </button>
        </div>
    </modal>
</template>

<script>
import SetServiceFull from "../../services/SetServiceFull";

export default {
    props: {
        restaurant_id: {
            default: "",
        },
        service_id: {
            default: "",
        },
        slot_id: {
            default: "",
        },
        date: {
            default: null,
        },
        readonly: {
            default: false,
        },
        setFree: {
            default: false,
        },
        prefill: {
            required: false,
            default: true,
        },
    },
    methods: {
        save() {
            this.$refs.addServiceFull.setFormErrors(null);
            this.$refs.addServiceFull.setFormLoading(true);
            const result = this.$refs.addServiceFull.postForm(null);

            if (result !== false) {
                result
                    .then(() => {
                        this.$emit("closure-saved");
                    })
                    .catch((err) => {
                        if (err.response && err.response.data && err.response.data) {
                            this.$refs.addServiceFull.setFormErrors(err.response.data);
                        } else if (err.message) {
                            this.$refs.addServiceFull.setFormErrors({ message: err.message });
                        }
                        this.$refs.addServiceFull.setFormLoading(false);
                    });
            } else {
                this.$refs.addServiceFull.setFormLoading(false);
            }
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        SetServiceFull,
    },
};
</script>
