var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Attention"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm._v(
          "\n        Des commandes en cours existent pour les créneaux que vous souhaitez supprimer.\n        "
        ),
        _c("command-list", {
          staticClass: "modal-table mt-3",
          attrs: {
            commands: _vm.commands,
            restaurant_id: _vm.restaurant_id,
            columns: {
              number: true,
              client: true,
              status: {
                show: true,
                readonly: true,
              },
              date: true,
              slot: true,
              price: true,
              products: true,
              actions: false,
            },
          },
          on: {
            next: function ($event) {
              return _vm.$emit("next", $event)
            },
            previous: function ($event) {
              return _vm.$emit("previous", $event)
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v("\n            Annuler\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-success btn-circle ml-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("cancel-and-save")
              },
            },
          },
          [_vm._v("\n            Annuler et rembourser\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-success btn-circle ml-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("save")
              },
            },
          },
          [_vm._v("\n            Enregistrer\n        ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }