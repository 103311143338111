<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Détails de la commande</h5>
        <div slot="body">
            <validate-command
                :showValidateButton="false"
                :command_id="command_id"
                :restaurant_id="restaurant_id"
                @command-get="setCommand($event)" />
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">Fermer</button>
            <button
                v-if="command && ['to_prepare', 'ready'].includes(command.status)"
                class="btn btn-sm btn-success btn-circle"
                @click="$emit('validate-command', { restaurant_id, command_id })">
                Valider
            </button>
        </div>
    </modal>
</template>

<script>
import ValidateCommand from "../../../clickAndCollect/Command/ValidateCommand.vue";

export default {
    data() {
        return {
            command: null,
        };
    },
    props: {
        command_id: {
            default: "",
        },
        restaurant_id: {
            default: "",
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        setCommand(command) {
            this.command = command;
        },
    },
    components: {
        ValidateCommand,
    },
};
</script>
