var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _vm.loading
        ? _c("loader-component")
        : _vm.error
        ? _c("div", [_vm._v("\n        " + _vm._s(_vm.error) + "\n    ")])
        : _c("div", { staticClass: "default-home" }, [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                  },
                  [
                    _c("h5", { staticClass: "title mt-2" }, [
                      _vm._v(_vm._s(_vm.$tl("labels.routes.rwg"))),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row m-0" },
              _vm._l(_vm.restaurants, function (restaurant) {
                return _c("restaurant-card", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.cantEditRwgTooltip(restaurant.id),
                      expression: "cantEditRwgTooltip(restaurant.id)",
                    },
                  ],
                  key: restaurant.id,
                  staticClass: "col-md-4 mb-3",
                  attrs: {
                    clickable: _vm.canEditRwg(restaurant.id),
                    restaurant: restaurant,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.contractRwg(restaurant)
                    },
                  },
                })
              }),
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }