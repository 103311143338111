<template>
    <PublicWidget />
</template>

<script>
import PublicWidget from './PublicWidget';

export default {
    components: {PublicWidget}    
}
</script>