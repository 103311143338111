<template>
    <div class="pb-5 campaign">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Campagnes</h5>
                    <button @click="addCampaign" class="btn btn-sm btn-success btn-circle" :disabled="!has_right_to_create_campaigns">
                        <feather type="plus"></feather>
                        Créer une campagne
                    </button>
                </div>
                <div>
                    <button
                        v-if="!loading"
                        class="btn btn-sm btn-outline-secondary btn-circle"
                        type="button"
                        @click="showPurchaseEmailsCampaignPackModal = has_right_to_buy_sms"
                        :disabled="is_paying_emails_campaign == 1">
                        {{ nb_campaign_emails_remaining }} crédits emails
                    </button>
                    <small class="d-block text-warning" v-if="is_paying_emails_campaign">
                        Une tentative d'achat d'un pack d'emails de campagne est en cours
                    </small>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div class="row m-0 pt-2">
                <div class="container-fluid" v-if="campaigns.length > 0">
                    <div class="row">
                        <div class="col-12 mt-2">
                            <div class="border-bottom">
                                <table class="capitalize table table-book table-striped table-sm">
                                    <thead class="border-bottom">
                                        <tr>
                                            <th>
                                                <feather type="user" class="none-desk feather-20" />
                                                <span class="none-mobile">Nom</span>
                                            </th>
                                            <th>
                                                <feather type="user" class="none-desk feather-20" />
                                                <span class="none-mobile">Type</span>
                                            </th>
                                            <th>
                                                <feather type="user" class="none-desk feather-20" />
                                                <span class="none-mobile">Statut</span>
                                            </th>
                                            <th>
                                                <feather type="user" class="none-desk feather-20" />
                                                <span class="none-mobile">Date d'envoi</span>
                                            </th>
                                            <th>
                                                <feather type="user" class="none-desk feather-20" />
                                                <span class="none-mobile">Nb Envois</span>
                                            </th>
                                            <th>
                                                <feather type="user" class="none-desk feather-20" />
                                                <span class="none-mobile">Nb Ouvertures</span>
                                            </th>
                                            <th>
                                                <feather type="user" class="none-desk feather-20" />
                                                <span class="none-mobile">Nb Clics</span>
                                            </th>
                                            <th class="none-mobile">
                                                <span class="none-mobile">Actions</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="campaign in campaigns" :key="campaign.id">
                                            <td>{{ campaign.name }}</td>
                                            <td>{{ campaign.type === "sms" ? "SMS" : "Email" }}</td>
                                            <td :class="getStatusClass(campaign)">
                                                <span
                                                    v-if="
                                                        (campaign.will_be_send_at != null &&
                                                            campaign.sent_at == null &&
                                                            campaign.type === 'email' &&
                                                            campaign.nb_clients > nb_campaign_emails_remaining) ||
                                                        (campaign.paid_at === null && campaign.type === 'sms')
                                                    "
                                                    v-tooltip="{
                                                        content:
                                                            campaign.paid_at === null && campaign.type === 'sms'
                                                                ? 'Vous n\'avez pas encore payé cette campagne'
                                                                : 'Nombre de crédits emails insuffisants',
                                                    }">
                                                    {{ getStatusLabel(campaign) }}
                                                </span>
                                                <span v-else>{{ getStatusLabel(campaign) }}</span>
                                            </td>
                                            <td>{{ campaign.sent_at != null ? campaign.sent_at : "-" }}</td>
                                            <td>
                                                <span v-if="campaign.is_sending">En cours d'envoi ({{ campaign.nb_sent || 0 }})</span>
                                                <span v-else>{{ campaign.sent_at != null ? campaign.nb_sent : "-" }}</span>
                                            </td>
                                            <td>
                                                <span v-if="campaign.type == 'sms'">Indisponible</span>
                                                <span v-else-if="campaign.sent_at != null">{{ campaign.nb_opened }}</span>
                                                <span v-else>-</span>
                                            </td>
                                            <td>
                                                <span v-if="campaign.type == 'sms'">Indisponible</span>
                                                <span v-else-if="campaign.sent_at != null">{{ campaign.nb_clicked }}</span>
                                                <span v-else>-</span>
                                            </td>
                                            <td class="none-mobile">
                                                <button
                                                    type="button"
                                                    title="Voir la campagne"
                                                    class="btn btn-sm btn-outline-secondary btn-square none-tablet"
                                                    data-toggle="modal"
                                                    data-target="#view-resa"
                                                    @click="showCampaign(campaign.id)"
                                                    v-tooltip="{ content: 'Voir les détails' }">
                                                    <feather type="eye" />
                                                </button>
                                                <button
                                                    v-if="campaign.sent_at === null"
                                                    class="btn btn-sm btn-success btn-square"
                                                    title="Modifier la campagne"
                                                    @click="editCampaign(campaign)"
                                                    :disabled="!has_right_to_update_campaigns"
                                                    v-tooltip="{ content: 'Éditer' }">
                                                    <feather type="edit" />
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-lg-6 offset-lg-3 pt-5 text-center">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <h4 class="mb-4">Vous n'avez pas encore créé de campagne</h4>
                        <button class="btn btn-success btn-circle" :disabled="!has_right_to_create_campaigns" @click="addCampaign()">
                            Créer ma première campagne
                        </button>
                        <img class="mt-5 none-mobile" width="450" src="/images/no-campaign.png" alt="No Show" />
                        <img class="mt-3 none-desk" width="300" src="/images/no-campaign.png" alt="No Show" />
                    </div>
                </div>
            </div>

            <add-campaign-modal
                v-if="showCampaignAddModal"
                :restaurant-id="restaurantId"
                :campaign="selectedCampaign"
                :clients="clients"
                :is_paying_emails_campaign="is_paying_emails_campaign"
                @setIsPayingEmailsCampaign="is_paying_emails_campaign = $event"
                @close="campaignsUpdated"
                @updated="campaignsUpdated" />

            <show-campaign-modal
                v-if="showCampaignShowModal"
                :campaign_id="showCampaignId"
                :clients="clients"
                @close="showCampaignShowModal = false" />

            <PurchaseEmailsCampaignPackModal
                v-if="showPurchaseEmailsCampaignPackModal"
                @close="showPurchaseEmailsCampaignPackModal = false"
                @paid="onEmailsPackPaid" />
        </div>
    </div>
</template>

<script>
import addCampaignModal from "../../components/Modals/campaign/addCampaignModal.vue";
import ShowCampaignModal from "../../components/Modals/campaign/ShowCampaignModal.vue";
import LoaderComponent from "../../components/LoaderComponent.vue";
import PurchaseEmailsCampaignPackModal from "../../components/Modals/campaign/PurchaseEmailsCampaignPackModal.vue";

const defaultCampaign = {
    id: null,
    type: "email",
    mail_object: "",
    sms_expeditor: "",
    content: "",
    img_path: null,
    img_link: null,
    at_least_one_resa: false,
    at_least_one_past_resa: false,
    at_least_one_future_resa: false,
    at_least_one_resa_in_restaurant: false,
    send_to_foreign_numbers: false,
    will_be_send_at: null,
    paid_at: null,
    save_type: "send",
    nb_sms_test_remaining: 2,
    include_vip: false,
    include_risky: false,
    client_types: [],
    loyalty_levels: [],
};

export default {
    data() {
        return {
            loading: false,
            smsEnabled: false,
            showCampaignAddModal: false,
            showCampaignShowModal: false,
            showPurchaseEmailsCampaignPackModal: false,
            campaigns: [],
            clients: null,
            selectedCampaign: this.$_.cloneDeep(defaultCampaign),
            showCampaignId: null,
            sms_emitter_name: null,
            nb_campaign_emails_remaining: 0,
            is_paying_emails_campaign: false,
        };
    },
    computed: {
        isOwner() {
            return this.$store.getters["users/role"] === "owner";
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_campaigns() {
            return this.rights.includes("campaigns.campaign.create");
        },
        has_right_to_update_campaigns() {
            return this.rights.includes("campaigns.campaign.update");
        },
        has_right_to_buy_sms() {
            return this.rights.includes("default.sms.update");
        },
    },
    methods: {
        onEmailsPackPaid() {
            this.showPurchaseEmailsCampaignPackModal = false;
            this.fetchData();
        },
        getStatusClass(campaign) {
            if (campaign.sent_at != null || campaign.paid_at != null) {
                return "text-success";
            }

            if (campaign.will_be_send_at != null) {
                if (campaign.type === "email" && campaign.nb_clients > this.nb_campaign_emails_remaining) {
                    return "text-danger";
                }

                if (campaign.type === "sms" && campaign.paid_at === null) {
                    return "text-danger";
                }

                return "text-success";
            }

            return "text-muted";
        },
        getStatusLabel(campaign) {
            if (campaign.sent_at != null) {
                return "Envoyée";
            }

            if (campaign.will_be_send_at != null) {
                return "Programmée";
            }

            if (campaign.paid_at != null) {
                return "Payée";
            }

            return "Brouillon";
        },
        resetSelectedCampaign() {
            this.selectedCampaign = this.$_.cloneDeep(defaultCampaign);
        },
        addCampaign() {
            this.resetSelectedCampaign();
            this.showCampaignAddModal = true;
        },
        showCampaign(campaign_id) {
            this.showCampaignId = campaign_id;
            this.showCampaignShowModal = true;
        },
        campaignsUpdated() {
            this.showCampaignAddModal = false;
            this.fetchData();
        },
        editCampaign(campaign) {
            this.selectedCampaign = Object.assign(
                {
                    save_type: campaign.will_be_send_at != null ? "prog" : "save",
                    at_least_one_resa:
                        campaign.at_least_one_past_resa || campaign.at_least_one_future_resa || campaign.at_least_one_resa_in_restaurant,
                },
                campaign
            );
            this.showCampaignAddModal = true;
        },
        fetchData() {
            this.loading = true;
            this.campaigns = [];
            this.clients = null;
            this.sms_emitter_name = "";

            this.httpGet(`/api/campaigns/restaurants/${this.restaurantId}/campaigns`)
                .then((response) => {
                    if (response !== false) {
                        this.campaigns = response.data.data;
                        this.clients = response.data.clients;
                        this.sms_emitter_name = response.data.sms_emitter_name;
                        this.nb_campaign_emails_remaining = response.data.nb_campaign_emails_remaining;
                        this.is_paying_emails_campaign = response.data.is_paying_emails_campaign;
                    }
                })
                .finally(() => (this.loading = false));
        },
    },
    components: {
        addCampaignModal,
        LoaderComponent,
        ShowCampaignModal,
        PurchaseEmailsCampaignPackModal,
    },
    created() {
        this.fetchData();
    },
};
</script>
