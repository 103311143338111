<template>
    <div>
        <div class="col-md-5 pt-1 mb-2">
            <label class="mb-0">Choix des villes <small>*</small></label>
            <small class="d-block text-muted">Merci de privilégier la recherche par code postal</small>
        </div>
        <div class="col-md-7">
            <input type="text" class="form-control mb-2" v-model="currentCity" />
            <span class="tag is-rounded mb-1" v-for="(city, index) in deliveryArea.cities.data" :key="index">
                {{ city.name }} ({{ city.postcode }})
                <button class="delete" type="button" @click="deliveryArea.cities.data.splice(index, 1)"></button>
            </span>
            <span class="d-block text-danger" v-if="errorCity">{{ errorCity }}</span>
            <LoaderComponent v-if="loadingPlaceDetails" />
            <div class="dropdown-menu prename-field shadow show p-0" v-else-if="availableCities.length > 0">
                <ul class="list-group">
                    <li class="list-group-item p-0" v-for="(city, index) in availableCities" :key="index">
                        <a class="d-block p-3" href="javascript:" @click="addCity(city)">
                            <span>{{ city.description }}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <ShowErrors class="d-block" :errors="errors" errorKey="cities" />
            <ShowErrors class="d-block" :errors="errors" errorKey="cities.*" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../LoaderComponent";
import ShowErrors from "../../../errors/ShowErrors";

export default {
    data() {
        return {
            availableCities: [],
            loadingPlaceDetails: false,
            currentCity: "",
            errorCity: null,
            sessionToken: null,
        };
    },
    props: {
        deliveryArea: {
            type: Object,
            required: true,
        },
        errors: {
            default: null,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        addCity(city) {
            this.loadingPlaceDetails = true;
            this.errorCity = null;

            axios
                .get(
                    `/api/click_and_collect/${this.restaurant_id}/placeDetails/${city.place_id}?sessionToken=${this.sessionToken}`
                )
                .then((response) => {
                    this.loadingPlaceDetails = false;
                    if (!response.data.postalCode) this.errorCity = "Merci de spécifier le code postal de la ville";
                    else if (
                        this.deliveryArea.cities.data.some(
                            (c) => c.postcode === response.data.postalCode && c.name === response.data.city
                        )
                    )
                        this.errorCity = "Désolé, cette ville est déjà présente dans la liste.";
                    else {
                        this.deliveryArea.cities.data.push({
                            name: response.data.city,
                            postcode: response.data.postalCode,
                        });
                        this.currentCity = "";
                        this.sessionToken = null;
                    }
                })
                .catch((error) => {
                    this.loadingPlaceDetails = false;
                    this.availableCities = [];
                    this.errorCity = this.getErrorMsgFromErrorResponse(error);
                });
        },
        searchCity(oldCityVal) {
            if (this.currentCity !== oldCityVal) return;

            this.errorCity = null;
            if (!this.sessionToken) this.sessionToken = this.generateRandomString();

            axios
                .get(
                    `/api/click_and_collect/${this.restaurant_id}/searchCity?query=${this.currentCity}&sessionToken=${this.sessionToken}`
                )
                .then((response) => {
                    if (response.data.data.length > 0) this.availableCities = response.data.data;
                    else {
                        this.errorCity = "Cette ville n'a pas été trouvée";
                        this.availableCities = [];
                    }
                })
                .catch((error) => {
                    this.availableCities = [];
                    this.errorCity = this.getErrorMsgFromErrorResponse(error);
                });
        },
    },
    watch: {
        currentCity(newVal) {
            if (newVal.length < 3) {
                this.availableCities = [];
                this.errorCity = null;
                this.sessionToken = null;
                return;
            }
            setTimeout(() => {
                this.searchCity(newVal);
            }, 500);
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
};
</script>
