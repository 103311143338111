var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5" },
    [
      _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [
                _vm._v(
                  _vm._s(_vm.$tl("labels.clickAndCollect.waitingOrders.title"))
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row m-0 pb-5" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm.loading > 0
              ? _c("LoaderComponent")
              : _c("div", [
                  _vm.error
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.error) +
                            "\n                "
                        ),
                      ])
                    : _c(
                        "div",
                        [
                          _vm.commands.data.length < 1
                            ? _c("div", [
                                _c("em", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.clickAndCollect.waitingOrders.noWaitingOrders",
                                        _vm.restaurant_id
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _c("command-list", {
                                attrs: {
                                  restaurant_id: _vm.restaurant_id,
                                  commands: _vm.commands,
                                  columns: {
                                    number: true,
                                    client: true,
                                    status: {
                                      show: true,
                                      readonly: false,
                                    },
                                    date: true,
                                    slot: true,
                                    price: true,
                                    products: true,
                                    actions: {
                                      edit: true,
                                      send: true,
                                      clickable: true,
                                    },
                                  },
                                },
                              }),
                        ],
                        1
                      ),
                ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.modals.displayShowCommandModal
        ? _c("show-command-modal", {
            attrs: {
              command_id: _vm.modals.params.selected_command_id,
              restaurant_id: _vm.modals.params.selected_restaurant_id,
            },
            on: {
              close: function ($event) {
                _vm.modals.displayShowCommandModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }