<template>
    <div>
        <div class="row mb-3">
            <div class="col-12">
                <strong>{{ $tl("labels.form.facturation.title") }}</strong>
                <feather
                    v-if="isDevelopement()"
                    v-tooltip="'Affichées sur les factures émises par NoShow vers le compte principal (sms, emails ...)'"
                    type="info"
                    class="ml-1 pointer tooltip-infos" />
            </div>
        </div>
        <form @submit="postForm">
            <label style="width: 100%">
                <div class="row">
                    <div class="col-md-5 pt-2">{{ $tl("labels.form.businessName") }} *</div>
                    <div class="col-md-7">
                        <input
                            type="text"
                            :class="{ 'is-invalid': hasErrors('company') }"
                            id="company"
                            name="company"
                            class="form-control"
                            v-model="user.company"
                            :disabled="!editable"
                            required="required" />
                        <div v-if="hasErrors('company')" class="invalid-feedback" role="alert" v-html="joinErrors('company')"></div>
                    </div>
                </div>
            </label>
            <label style="width: 100%">
                <div class="row">
                    <div class="col-md-5 pt-2">{{ $tl("labels.form.businessType") }} *</div>
                    <div class="col-md-7">
                        <select
                            class="custom-select mb-0"
                            :class="{ 'is-invalid': hasErrors('company_type') }"
                            id="company_type"
                            name="company_type"
                            v-model="user.company_type"
                            :disabled="!editable"
                            required>
                            <option v-for="businessType in ALL_BUSINESS_TYPES" :key="businessType.value" :value="businessType.value">
                                {{ getBusinessTypeLabel(businessType.value) }}
                            </option>
                        </select>
                        <div v-if="hasErrors('company_type')" class="invalid-feedback" role="alert" v-html="joinErrors('company_type')"></div>
                    </div>
                </div>
            </label>
            <label style="width: 100%">
                <div class="row">
                    <div class="col-md-5 pt-2">{{ $tl("labels.form.facturation.siret") }} *</div>
                    <div class="col-md-7">
                        <input
                            type="text"
                            minlength="14"
                            maxlength="14"
                            :class="{ 'is-invalid': hasErrors('company_siret') }"
                            id="company_siret"
                            name="company_siret"
                            class="form-control"
                            v-model="user.company_siret"
                            :disabled="!editable"
                            required />
                        <div v-if="hasErrors('company_siret')" class="invalid-feedback" role="alert" v-html="joinErrors('company_siret')"></div>
                    </div>
                </div>
            </label>
            <label style="width: 100%">
                <div class="row">
                    <div class="col-md-5 pt-2">{{ $tl("labels.form.facturation.nafCode") }} *</div>
                    <div class="col-md-7">
                        <input
                            type="text"
                            :class="{ 'is-invalid': hasErrors('company_code_naf') }"
                            id="company_code_naf"
                            name="company_code_naf"
                            class="form-control"
                            :disabled="!editable"
                            required
                            v-model="user.company_code_naf" />
                        <div v-if="hasErrors('company_code_naf')" class="invalid-feedback" role="alert" v-html="joinErrors('company_code_naf')"></div>
                    </div>
                </div>
            </label>
            <label style="width: 100%">
                <div class="row">
                    <div class="col-md-5 pt-2">{{ $tl("labels.form.facturation.tvaNumber") }}</div>
                    <div class="col-md-7">
                        <input
                            type="text"
                            :class="{ 'is-invalid': hasErrors('company_code_tva') }"
                            id="company_code_tva"
                            name="company_code_tva"
                            class="form-control"
                            :disabled="!editable"
                            v-model="user.company_code_tva" />
                        <div v-if="hasErrors('company_code_tva')" class="invalid-feedback" role="alert" v-html="joinErrors('company_code_tva')"></div>
                    </div>
                </div>
            </label>
            <label style="width: 100%">
                <div class="row">
                    <div class="col-md-5 pt-2">{{ $tl("labels.form.address") }} *</div>
                    <div class="col-md-7">
                        <input
                            type="text"
                            id="company_address"
                            name="company_address"
                            :class="{ 'is-invalid': hasErrors('company_address') }"
                            class="form-control"
                            v-model="user.company_address"
                            :disabled="!editable"
                            required />
                        <div v-if="hasErrors('company_address')" class="invalid-feedback" role="alert" v-html="joinErrors('company_address')"></div>
                    </div>
                </div>
            </label>
            <label style="width: 100%">
                <div class="row">
                    <div class="col-md-5 pt-2">{{ $tl("labels.form.postalCode") }} *</div>
                    <div class="col-md-7">
                        <input
                            type="text"
                            id="company_zipcode"
                            :class="{ 'is-invalid': hasErrors('company_zipcode') }"
                            name="company_zipcode"
                            class="form-control"
                            v-model="user.company_zipcode"
                            :disabled="!editable"
                            required />
                        <div v-if="hasErrors('company_zipcode')" class="invalid-feedback" role="alert" v-html="joinErrors('company_zipcode')"></div>
                    </div>
                </div>
            </label>
            <label style="width: 100%">
                <div class="row">
                    <div class="col-md-5 pt-2">{{ $tl("labels.form.city") }} *</div>
                    <div class="col-md-7">
                        <input
                            type="text"
                            id="company_city"
                            name="company_city"
                            :class="{ 'is-invalid': hasErrors('company_city') }"
                            class="form-control"
                            v-model="user.company_city"
                            :disabled="!editable"
                            required />
                        <div v-if="hasErrors('company_city')" class="invalid-feedback" role="alert" v-html="joinErrors('company_city')"></div>
                    </div>
                </div>
            </label>
            <label style="width: 100%">
                <div class="row">
                    <div class="col-md-5 pt-2">{{ $tl("labels.form.country") }} *</div>
                    <div class="col-md-7">
                        <input
                            type="text"
                            :class="{ 'is-invalid': hasErrors('company_country') }"
                            id="company_country"
                            name="company_country"
                            v-model="user.company_country"
                            :disabled="!editable"
                            class="form-control"
                            required />
                        <div v-if="hasErrors('company_country')" class="invalid-feedback" role="alert" v-html="joinErrors('company_country')"></div>
                    </div>
                </div>
            </label>
            <button v-if="showButtons" type="submit" class="btn btn-success btn-circle">
                {{ $tl("labels.form.actions.save") }}
            </button>
        </form>
    </div>
</template>

<script>
import BusinessTypesEnum from "../../../mixins/enums/BusinessTypesEnum";

export default {
    name: "StripeCustomerForm",
    props: {
        showButtons: {
            default: true,
        },
        errors: {
            default: undefined,
        },
        value: {
            default: undefined,
        },
        editable: {
            default: true,
        },
    },
    mixins: [BusinessTypesEnum],
    data() {
        return {
            errors_: null,
            user_: {
                company: "",
                company_type: "",
                company_siret: "",
                company_code_naf: "",
                company_code_tva: "",
                company_address: "",
                company_zipcode: "",
                company_city: "",
                company_country: "",
            },
        };
    },
    created() {
        this.fillUser();
    },
    computed: {
        user: {
            get: function () {
                if (typeof this.value == "undefined") {
                    return this.user_;
                } else {
                    return this.value;
                }
            },
            set: function (value) {
                if (typeof this.value == "undefined") {
                    this.user_ = value;
                } else {
                    this.$emit("input", value);
                }
            },
        },
        restaurantCgv() {
            const restaurants = this.$store.getters["restaurants/restaurants"];

            if (restaurants.length < 1) {
                return {};
            }

            return this.$store.getters["restaurants/getCGV"](restaurants[0].id) ?? {};
        },
    },
    methods: {
        postForm() {},
        hasErrors(key) {
            return typeof this.errors_ != "undefined" && this.errors_ !== null && this.errors_[key] && this.errors_[key].length > 0;
        },
        joinErrors(key) {
            return this.errors_[key].join("<br/>");
        },
        fillUser() {
            this.user = {
                company: this.$store.getters["users/company"] ?? this.restaurantCgv.contact?.company,
                company_type: this.$store.getters["users/companyType"],
                company_siret: this.$store.getters["users/companySiret"] ?? this.restaurantCgv.facturation?.siret,
                company_code_naf: this.$store.getters["users/companyCodeNaf"] ?? this.restaurantCgv.facturation?.code_naf,
                company_code_tva: this.$store.getters["users/companyCodeTva"] ?? this.restaurantCgv.facturation?.code_tva,
                company_address: this.$store.getters["users/companyAddress"] ?? this.restaurantCgv.contact?.address,
                company_zipcode: this.$store.getters["users/companyZipcode"] ?? this.restaurantCgv.contact?.zipcode,
                company_city: this.$store.getters["users/companyCity"] ?? this.restaurantCgv.contact?.city,
                company_country: this.$store.getters["users/companyCountry"] ?? this.restaurantCgv.contact?.country,
            };
        },
    },
    watch: {
        errors: {
            immediate: true,
            handler(newVal) {
                if (newVal && typeof newVal != "undefined") {
                    this.errors_ = newVal;
                }
            },
        },
    },
};
</script>

<style scoped>
.invalid-feedback {
    display: block !important;
}
</style>
