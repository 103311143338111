<template>
    <div>
        <div class="row m-0 justify-content-center">
            <loader-component v-if="loading" />
            <template v-else>
                <div class="alert alert-danger" v-if="error">
                    {{ error }}
                </div>
                <template v-else v-for="(module, index) in modules">
                    <module-product
                        class="col-lg-3 col-md-3 mb-3"
                        v-if="displayModule(module)"
                        :key="index"
                        :onlyOneModule="modules.length > 1 && onlyOneModule"
                        :max="max"
                        :allModules="modules"
                        :module="module"
                        v-model="module.count"
                        @count_option_dispatch="module.count_option_dispatch = $event"
                        :plan="planComputed"
                        :disable-payment="disablePayment"
                        :disable-plan="disablePlan"
                        @input-plan="planComputed = $event" />
                </template>
            </template>
        </div>
        <div class="text-center" v-if="!loading">
            <div v-if="!disablePayment && plan == 'yearly'">{{ $tl("labels.form.licences.discountYearly") }}</div>
            <div v-if="!disableTraining" class="mt-2">
                <label class="container-box w-auto" style="padding-left: 20px !important">
                    <input type="checkbox" v-model="training_" />
                    <span class="checkmark"></span>
                    <span class="ml-2">{{ $tl("labels.form.licences.askTraining") }}</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import ModuleProduct from "./ModuleProduct.vue";
import LoaderComponent from "../LoaderComponent.vue";

export default {
    name: "ModuleProductsList",
    data() {
        return {
            loading: false,
            modules_: [],
            error: null,
            plan_: "yearly",
        };
    },
    props: {
        plan: {
            default: undefined,
            validator: function (value) {
                return ["yearly", "monthly"].indexOf(value) !== -1;
            },
        },
        value: {
            default: undefined,
        },
        preselectedModules: {
            default: () => [],
        },
        modulesEnabled: {
            default: null,
        },
        onlyOneModule: {
            default: false,
        },
        max: {
            default: null,
        },
        training: {
            default: false,
        },
        disablePayment: {
            type: Boolean,
            default: false,
        },
        disableTraining: {
            type: Boolean,
            default: false,
        },
        disablePlan: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        training_: {
            get() {
                return this.training;
            },
            set(newVal) {
                this.$emit("set-training", newVal);
            },
        },
        planComputed: {
            get: function () {
                if (typeof this.plan == "undefined") {
                    return this.plan_;
                } else {
                    return this.plan;
                }
            },
            set: function (value) {
                if (typeof this.plan == "undefined") {
                    this.plan_ = value;
                } else {
                    this.$emit("input-plan", value);
                }
            },
        },
        modules: {
            get: function () {
                if (typeof this.value == "undefined") {
                    return this.modules_;
                } else {
                    return this.value;
                }
            },
            set: function (value) {
                if (typeof this.value == "undefined") {
                    this.modules_ = value;
                } else {
                    this.$emit("input", value);
                }
            },
        },
        selectedModules() {
            return this.modules.filter((module) => module.count && module.count > 0);
        },
    },
    created() {
        this.fetchModules();
    },
    methods: {
        fetchModules() {
            this.loading = true;
            this.error = null;
            return this.$store
                .dispatch("modules/fetchAll", {
                    modulesEnabled: this.modulesEnabled,
                })
                .then((response) => {
                    this.fillModules(response.data.data);
                    this.loading = false;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                    this.loading = false;
                });
        },
        fillModules(modules) {
            modules.forEach((module) => {
                if (module.type !== "campaigns") this.modules.push(this.buildModule(module));
            });
        },
        buildModule(module) {
            let count = 0;
            const preselectedModule = this.preselectedModules.find((m) => m.type === module.type && m.id === module.id);

            if (preselectedModule) {
                count = preselectedModule.count;
            }

            return {
                ...module,
                count,
            };
        },
        displayModule(module) {
            return !(this.onlyOneModule && this.selectedModules.length > 0 && this.selectedModules[0].id !== module.id);
        },
    },
    components: {
        ModuleProduct,
        LoaderComponent,
    },
};
</script>
