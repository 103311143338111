import Clients from "../../views/Clients.vue";
import ClientsForm from "../../views/ClientsForm.vue";

import GiftVouchersHome from "../../views/GiftVouchers/Home.vue";
import GiftVouchersSales from "../../views/GiftVouchers/GiftVouchersSales.vue";
import GiftVouchersPayments from "../../views/GiftVouchers/GiftVouchersPayments.vue";
import GiftVouchersReport from "../../views/GiftVouchers/GiftVouchersReport.vue";
import GiftVouchersProducts from "../../views/GiftVouchers/GiftVouchersProducts.vue";
import GiftVouchersProductsChild from "../../views/GiftVouchers/Products/GiftVouchersProducts.vue";
import GiftVouchersCategories from "../../views/GiftVouchers/Products/GiftVouchersCategories.vue";
import GiftVouchersConfiguration from "../../views/GiftVouchers/GiftVouchersConfiguration.vue";
import GiftVouchersConfigurationTab from "../../views/GiftVouchers/Configuration/GiftVouchersConfigurationTab.vue";
import GiftVouchersIntegrationTab from "../../views/GiftVouchers/Configuration/GiftVouchersIntegrationTab.vue";
import GiftVouchersCgv from "../../views/GiftVouchers/Configuration/cgv.vue";
import GiftVouchersInputParams from "../../views/GiftVouchers/Configuration/GiftVouchersInputParams.vue";

const routes = [
    {
        path: "/gift-vouchers",
        name: "gift_vouchers.home",
        component: GiftVouchersHome,
    },
    {
        path: "/gift-vouchers/restaurants/:restaurant_id/sales",
        name: "gift_vouchers.restaurants.sales",
        component: GiftVouchersSales,
    },
    {
        path: "/gift-vouchers/restaurants/:restaurant_id/payments",
        name: "gift_vouchers.restaurants.payments",
        component: GiftVouchersPayments,
    },
    {
        path: "/gift-vouchers/restaurants/:restaurant_id/report",
        name: "gift_vouchers.restaurants.report",
        component: GiftVouchersReport,
    },
    {
        path: "/gift-vouchers/restaurants/:restaurant_id/products",
        component: GiftVouchersProducts,
        children: [
            {
                path: "",
                name: "gift_vouchers.restaurants.products.products",
                component: GiftVouchersProductsChild,
            },
            {
                path: "categories",
                name: "gift_vouchers.restaurants.categories",
                component: GiftVouchersCategories,
            },
        ],
    },
    {
        path: "/gift-vouchers/restaurants/:restaurant_id/configuration",
        name: "gift_vouchers.restaurants.configuration",
        component: GiftVouchersConfiguration,
        children: [
            {
                path: "configuration",
                name: "gift_vouchers.restaurants.configuration.configuration",
                component: GiftVouchersConfigurationTab,
            },
            {
                path: "integration",
                name: "gift_vouchers.restaurants.configuration.integration",
                component: GiftVouchersIntegrationTab,
            },
            { path: "cgv", name: "gift_vouchers.restaurants.configuration.cgv", component: GiftVouchersCgv },
            { path: "input-params", name: "gift_vouchers.restaurants.configuration.inputParams", component: GiftVouchersInputParams },
        ],
    },
    {
        path: "/gift-vouchers/restaurants/:restaurant_id/clients/:client_id",
        name: "gift_vouchers.clients.form",
        component: ClientsForm,
    },
    {
        path: "/gift-vouchers/restaurants/:restaurant_id/clients",
        name: "gift_vouchers.clients",
        component: Clients,
    },
];

export default routes;
