var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canDisplayAlert
    ? _c(
        "div",
        {
          staticClass:
            "d-inline-flex justify-content-center align-items-center bg-danger w-100 p-1",
        },
        [
          _c("span", { staticClass: "text-white ml-auto" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$tl(
                    "labels.notifications.booking.seatingPlanRoomDisabled.content",
                    undefined,
                    {
                      seatingPlan:
                        _vm.unreadSeatingPlanNotifications.data.seatingPlanName,
                    }
                  )
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "text-white ml-1 is-link",
              attrs: {
                to: {
                  name: "booking.restaurants.settings.seatingPlan",
                  params: {
                    restaurant_id:
                      _vm.unreadSeatingPlanNotifications.restaurant_id,
                    seating_plan_id:
                      _vm.unreadSeatingPlanNotifications.data.seatingPlanId,
                  },
                  q: _vm.q,
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$tl(
                      "labels.notifications.booking.seatingPlanRoomDisabled.link"
                    )
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("feather", {
            staticClass: "text-white pointer ml-auto",
            attrs: { type: "x" },
            on: { click: _vm.markAsRead },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }