<template>
    <div class="row">
        <div class="col-12">
            <p class="text-muted">{{ $tl("labels.clients.merge.keep") }}</p>
        </div>
        <div class="col-6">
            <form
                class="border-light b-radius-20 p-4 clickable"
                :class="{
                    selected_client_for_merging: selectedClientNum === 1,
                    not_selected_client_for_merging: selectedClientNum === 2,
                }"
                @click="selectClient(1)">
                <clientsReservations :reservation="reservation1" :readOnly="true" :hideEditButton="true" />
            </form>
        </div>
        <div class="col-6">
            <form
                class="border-light b-radius-20 p-4 clickable"
                :class="{
                    selected_client_for_merging: selectedClientNum === 2,
                    not_selected_client_for_merging: selectedClientNum === 1,
                }"
                @click="selectClient(2)">
                <clientsReservations :reservation="reservation2" :readOnly="true" :hideEditButton="true" />
            </form>
        </div>
    </div>
</template>

<script>
import clientsReservations from "../../reservations/add/clientsReservations";

export default {
    data() {
        return {
            reservation1: null,
            reservation2: null,
            styleClient1: null,
            styleClient2: null,
            selectedClientNum: null,
        };
    },
    props: {
        client1: {
            type: Object,
            required: true,
        },
        client2: {
            type: Object,
            required: true,
        },
    },
    methods: {
        selectClient(num) {
            this.selectedClientNum = num;
            this.$emit("client-selected", num);
        },
    },
    components: {
        clientsReservations,
    },
    created() {
        // Add data for child component
        this.reservation1 = {
            client: {
                ...this.client1,
                search: {
                    results: null,
                    errors: null,
                    isLoading: false,
                    firstnameHasFocus: false,
                    lastnameHasFocus: false,
                    companyHasfocus: false,
                    emailHasFocus: false,
                    phoneNumberHasFocus: false,
                },
                phone: {
                    raw: this.client1.tel,
                    isValid: null,
                    country: this.client1.tel_country,
                    value: this.client1.tel,
                    search: {
                        results: null,
                    },
                },
            },
        };
        this.reservation2 = {
            client: {
                ...this.client2,
                search: {
                    results: null,
                    errors: null,
                    isLoading: false,
                    firstnameHasFocus: false,
                    lastnameHasFocus: false,
                    companyHasfocus: false,
                    emailHasFocus: false,
                    phoneNumberHasFocus: false,
                },
                phone: {
                    raw: this.client2.tel,
                    isValid: null,
                    country: this.client2.tel_country,
                    value: this.client2.tel,
                    search: {
                        results: null,
                    },
                },
            },
        };
    },
};
</script>

<style scoped>
.selected_client_for_merging {
    box-shadow: 0 0 0.3em 0.15em #f3f3f3;
}
.not_selected_client_for_merging {
    opacity: 0.5;
}
</style>
