var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("loader-component")
        : _c(
            "div",
            _vm._l(_vm.logs, function (log) {
              return _c("div", { key: log.id, staticClass: "row mt-2" }, [
                _c("div", { staticClass: "col-md-9" }, [
                  log.crud_type === _vm.CRUD_TYPE_CREATE.value
                    ? _c("span", [
                        _vm._v(
                          "\n                    Le code promo a été créé par "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.displayUserName(log.user, log.user_type))
                          ),
                        ]),
                      ])
                    : log.crud_type === _vm.CRUD_TYPE_UPDATE.value
                    ? _c("span", [
                        _vm._v(
                          "\n                    Le code promo a été " +
                            _vm._s(log.is_active ? "activé" : "désactivé") +
                            " par\n                    "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.displayUserName(log.user, log.user_type))
                          ),
                        ]),
                      ])
                    : log.crud_type === _vm.CRUD_TYPE_SOFT_DELETE.value
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n                    Le code promo a été archivé par\n                    "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.displayUserName(log.user, log.user_type))
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3 text-right" }, [
                  _c("i", [
                    _c("small", [_vm._v(_vm._s(_vm.displayLogDate(log)))]),
                  ]),
                ]),
              ])
            }),
            0
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }