<template>
    <modal-rounded-no-header @close="closeFromModal">
        <div slot="body">
            <p class="text-danger text-center">
                {{ message }}
            </p>
        </div>
    </modal-rounded-no-header>
</template>

<script>
import ModalRoundedNoHeader from "../base/ModalRoundedNoHeader.vue";

export default {
    props: {
        message: {
            type: String,
            required: true,
        },
    },
    components: {
        ModalRoundedNoHeader,
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e = null) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                if (e) {
                    e.stopPropagation();
                }
                this.close();
            }
        },
    },
};
</script>
