var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading > 0
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.errorMessages && _vm.errorMessages.length > 0
              ? _c("div", {
                  staticClass: "alert alert-danger",
                  domProps: {
                    innerHTML: _vm._s(_vm.errorMessages.join("<br/>")),
                  },
                })
              : _c("div", { staticClass: "default-home" }, [
                  _c("div", { staticClass: "row m-0" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                        },
                        [
                          _c("h5", { staticClass: "title mt-2" }, [
                            _vm._v(
                              _vm._s(_vm.$tl("labels.routes.subscription"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-sm btn-success btn-circle",
                                  attrs: {
                                    to: { name: "subscription.invoices" },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$tl("labels.routes.invoices")
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.has_right_to_update_subscription &&
                              !_vm.hasManualBilling
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "none-mobile btn btn-sm btn-success btn-circle",
                                      attrs: {
                                        to: {
                                          name: "subscription.licences.create",
                                        },
                                      },
                                    },
                                    [
                                      _c("feather", {
                                        attrs: { type: "plus" },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.routes.addSubscription"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.has_right_to_update_subscription &&
                              !_vm.hasManualBilling
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "none-desk btn btn-sm btn-success btn-circle",
                                      attrs: {
                                        to: {
                                          name: "subscription.licences.create",
                                        },
                                      },
                                    },
                                    [
                                      _c("feather", {
                                        attrs: { type: "plus" },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$tl("labels.form.actions.add")
                                          ) +
                                          "\n                            "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row m-0" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4 mb-3" },
                        [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("strong", { staticClass: "float-left" }, [
                                _vm._v(
                                  _vm._s(_vm.$tl("labels.subscriptions.actual"))
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm._l(
                                _vm.flattenSubscriptions,
                                function (licence, index) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        key: licence.id,
                                        staticClass:
                                          "border-light p-3 mb-3 position-relative",
                                      },
                                      [
                                        _c("div", { staticClass: "row mb-2" }, [
                                          licence.status === "past_due" ||
                                          licence.status === "unpaid"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 text-danger mb-1",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "infos.psp.checkPaymentMethod"
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-7" }, [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getNameString(licence)
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            licence.stripe_subscription !==
                                              undefined &&
                                            licence.stripe_subscription
                                              .status === "trialing"
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.subscriptions.freeTrial"
                                                      )
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]),
                                          _vm._v(" "),
                                          licence.active_promo_codes &&
                                          licence.active_promo_codes.length >
                                            0 &&
                                          !_vm.memoPromoCodeLicence(
                                            licence,
                                            index
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-5 text-right",
                                                },
                                                [
                                                  _c("s", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getPriceString(
                                                          _vm.getPrice(licence)
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.getPriceString(
                                                          _vm.getDiscountedPrice(
                                                            licence
                                                          )
                                                        )
                                                      ) +
                                                      " / " +
                                                      _vm._s(
                                                        _vm.getLabelPlan(
                                                          _vm.getPlan(licence)
                                                        )
                                                      ) +
                                                      "\n                                            "
                                                  ),
                                                  _c("div", [
                                                    _c("small", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getDiscountedPeriod(
                                                            licence
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-5 text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.getFormattedPrice(
                                                          licence
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row mb-2" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-12 d-flex align-items-center mb-2",
                                            },
                                            [
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        licence.restaurant_id,
                                                      expression:
                                                        "licence.restaurant_id",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "custom-select m-0",
                                                  attrs: {
                                                    disabled:
                                                      !_vm.has_right_to_update_subscription ||
                                                      licence.module.type ===
                                                        _vm
                                                          .MODULE_TYPE_CLICK_AND_COLLECT_DISPATCH
                                                          .value,
                                                  },
                                                  on: {
                                                    change: [
                                                      function ($event) {
                                                        var $$selectedVal =
                                                          Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function (o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function (o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                        _vm.$set(
                                                          licence,
                                                          "restaurant_id",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.changeLicenceRestaurant(
                                                          $event,
                                                          licence
                                                        )
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: { disabled: true },
                                                      domProps: { value: null },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.subscriptions.assignRestaurant"
                                                            )
                                                          ) +
                                                          "\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.getRestaurantsForModule(
                                                      licence.module.type
                                                    ),
                                                    function (
                                                      restaurant,
                                                      index
                                                    ) {
                                                      return [
                                                        restaurant.isFree ||
                                                        licence.restaurant_id ==
                                                          restaurant.id
                                                          ? _c(
                                                              "option",
                                                              {
                                                                key: index,
                                                                domProps: {
                                                                  value:
                                                                    restaurant.id,
                                                                  selected:
                                                                    licence.restaurant_id ==
                                                                    restaurant.id,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                        " +
                                                                    _vm._s(
                                                                      restaurant.name
                                                                    ) +
                                                                    "\n                                                    "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        !_vm.hasManualBilling
                                          ? [
                                              !licence.cancel_at_period_end
                                                ? _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value: _vm.getTooltip(
                                                            _vm.$t(
                                                              "labels.subscriptions.delete"
                                                            )
                                                          ),
                                                          expression:
                                                            "getTooltip($t('labels.subscriptions.delete'))",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn top-right m-0 p-0",
                                                      attrs: {
                                                        disabled:
                                                          !_vm.has_right_to_update_subscription,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteLicence(
                                                            licence
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("feather", {
                                                        staticClass:
                                                          "bg-none icon m-0 p-0",
                                                        attrs: { type: "x" },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value: _vm.getTooltip(
                                                            _vm.$t(
                                                              "labels.subscriptions.reactivate"
                                                            )
                                                          ),
                                                          expression:
                                                            "getTooltip($t('labels.subscriptions.reactivate'))",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn top-right m-0 p-0 mr-1",
                                                      attrs: {
                                                        disabled:
                                                          !_vm.canReactivateLicence(
                                                            licence
                                                          ) ||
                                                          !_vm.has_right_to_update_subscription,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.reactivateLicence(
                                                            licence
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("feather", {
                                                        staticClass:
                                                          "bg-none icon m-0 p-0",
                                                        attrs: {
                                                          type: "refresh-ccw",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row" }, [
                                          _c("div", { staticClass: "col-12" }, [
                                            licence.status === "past_due" ||
                                            licence.status === "unpaid"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "labels.subscriptions.willBeCanceledOn",
                                                          {
                                                            date: _vm.getCancelationDate(
                                                              licence
                                                            ),
                                                          }
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm.getRenewDate(licence) !==
                                                undefined
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "red",
                                                    },
                                                  },
                                                  [
                                                    licence.cancel_at_period_end
                                                      ? [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.subscriptions.endAt",
                                                                {
                                                                  date: _vm.getRenewDate(
                                                                    licence,
                                                                    true
                                                                  ),
                                                                }
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      : [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.subscriptions.nextRenew",
                                                                {
                                                                  date: _vm.getRenewDate(
                                                                    licence
                                                                  ),
                                                                }
                                                              )
                                                            )
                                                          ),
                                                        ],
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            licence.status === "past_due" ||
                                            licence.status === "unpaid"
                                              ? _c("div", [
                                                  licence.stripe_subscription
                                                    .latest_invoice
                                                    .payment_intent.status ===
                                                  "processing"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              _vm.$tl(
                                                                "labels.subscriptions.pendingPayment"
                                                              )
                                                            ) +
                                                            "\n                                                "
                                                        ),
                                                      ])
                                                    : _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-sm btn-circle btn-warning float-right",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.payPastDueSubscription(
                                                                licence.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                _vm.$tl(
                                                                  "labels.subscriptions.tryPay"
                                                                )
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                ])
                                              : _vm._e(),
                                          ]),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4 mb-3" },
                        [
                          _c("ShowBillingDetails"),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [_c("stripe-customer-card-list")],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row m-0" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4 mb-3" },
                        [
                          _c(
                            "div",
                            { staticClass: "row text-center" },
                            [
                              _vm._l(_vm.getCgvs(), function (cgv, index) {
                                return [
                                  !cgv.at_least_one_subscription ||
                                  _vm.atLeastOneSubscription(
                                    cgv.at_least_one_subscription
                                  )
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            key: `${index}-1`,
                                            staticClass: "col-sm-6 pt-2",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: `${cgv.file}?v=${_vm.$__BUILD_ID__}`,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "bg-light p-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(cgv.label) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.isOwner
                                          ? _c(
                                              "div",
                                              {
                                                key: `${index}-2`,
                                                staticClass: "col-sm-6 pt-3",
                                              },
                                              [
                                                cgv.accepted_cgv
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "infos.cgvs.acceptedOn",
                                                              {
                                                                date: _vm.displayDate(
                                                                  cgv
                                                                    .accepted_cgv
                                                                    .last_accepted_at,
                                                                  _vm.DATE_SHORT
                                                                ),
                                                              }
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }