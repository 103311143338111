var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(_vm._s(_vm.$tl("labels.form.restaurant.select"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.loading
          ? _c("LoaderComponent")
          : _c("div", [
              _vm.error
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.error) +
                        "\n            "
                    ),
                  ])
                : _c(
                    "div",
                    [
                      _vm._l(_vm.restaurants, function (restau, index) {
                        return [
                          restau.id != _vm.exclude_restaurant_id
                            ? _c(
                                "div",
                                { key: index, staticClass: "radio mt-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "container-box" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.selectedRestaurant,
                                            expression: "selectedRestaurant",
                                          },
                                        ],
                                        attrs: { type: "radio" },
                                        domProps: {
                                          value: index,
                                          checked: _vm._q(
                                            _vm.selectedRestaurant,
                                            index
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.selectedRestaurant = index
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "checkmark" }),
                                      _vm._v(
                                        " " +
                                          _vm._s(restau.name) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-secondary btn-sm btn-circle",
            on: { click: _vm.close },
          },
          [_vm._v("Fermer")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-success btn-sm btn-circle",
            attrs: { disabled: _vm.selectedRestaurant === null },
            on: {
              click: function ($event) {
                return _vm.$emit(
                  "selected",
                  _vm.restaurants[_vm.selectedRestaurant]
                )
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.select")) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }