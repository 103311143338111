var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "col-12" }, [
      _vm.restaurant
        ? _c(
            "div",
            [
              _vm._l(_vm.servicesToDisplay, function (service) {
                return _vm.servicesToDisplay.length > 0
                  ? _c("div", { staticClass: "mb-3" }, [
                      service.reservations.length > 0 ||
                      service.nbPassingCustomer > 0
                        ? _c(
                            "div",
                            {
                              ref: `service-${service.id}`,
                              refInFor: true,
                              staticClass:
                                "d-flex flex-wrap justify-content-between flex-md-nowrap pb-1 mb-3 border-bottom",
                            },
                            [
                              _c(
                                "h6",
                                {
                                  staticStyle: {
                                    "text-transform": "uppercase",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.displayDate(
                                          _vm.reservation_date,
                                          "cccc"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "capacite badge badge-secondary",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(service.category) +
                                          " " +
                                          _vm._s(
                                            service.special === 1
                                              ? " (exceptionnel) "
                                              : ""
                                          )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  service.is_full === 1
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn badge badge-danger",
                                          staticStyle: {
                                            color: "white !important",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showServiceFull(
                                                service.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm
                                                  .$tl(
                                                    "labels.booking.services.closed"
                                                  )
                                                  .toUpperCase()
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  service.is_full === 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn text-white badge btn-warning",
                                          on: {
                                            click: function ($event) {
                                              return _vm.showServiceFull(
                                                service.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm
                                                  .$tl(
                                                    "labels.booking.services.closedPartially"
                                                  )
                                                  .toUpperCase()
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c("h6", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "capacite badge badge-secondary",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(service.pax) +
                                        " / " +
                                        _vm._s(
                                          service.new_pax !== null
                                            ? service.new_pax
                                            : service.max_pax
                                        )
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      service.reservations.length > 0 ||
                      service.nbPassingCustomer > 0
                        ? _c("div", { staticClass: "border-bottom mb-2" }, [
                            _c(
                              "table",
                              {
                                staticClass:
                                  "capitalize table table-book table-striped table-sm",
                              },
                              [
                                _c("thead", { staticClass: "border-bottom" }, [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      { staticClass: "client-column" },
                                      [
                                        _c("feather", {
                                          staticClass: "none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: { type: "user" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.reservations.grid.columns.client"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      [
                                        _c("feather", {
                                          staticClass: "none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: { type: "users" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.reservations.grid.columns.pax"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      [
                                        _c("feather", {
                                          staticClass: "none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: { type: "monitor" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.reservations.grid.columns.table",
                                                  _vm.restaurant_id
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      [
                                        _c("feather", {
                                          staticClass: "none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: { type: "clock" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.reservations.grid.columns.hour"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      [
                                        _c("feather", {
                                          staticClass: "none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: { type: "book-open" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.reservations.grid.columns.menusAndOptions",
                                                  _vm.selected_restaurant_id
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      [
                                        _c("feather", {
                                          staticClass: "none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: { type: "message-circle" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.reservations.grid.columns.comments"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      { staticClass: "text-center" },
                                      [
                                        _c("feather", {
                                          staticClass: "none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: { type: "check-circle" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.reservations.grid.columns.status"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    service.reservations,
                                    function (reservation) {
                                      return _c(
                                        "tr",
                                        {
                                          key: `${service.id}-${reservation.id}`,
                                          staticClass: "resa",
                                          class: {
                                            updated:
                                              reservation.updated &&
                                              reservation.updated === true,
                                            new:
                                              reservation.new &&
                                              reservation.new === true,
                                          },
                                          attrs: { id: "resaRow" },
                                        },
                                        [
                                          _c(
                                            "td",
                                            [
                                              _c("inline-client", {
                                                attrs: {
                                                  client: reservation.client,
                                                  "module-enum":
                                                    _vm.MODULE_TYPE_BOOKING,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "table-text-center",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    reservation.nb_pers * 1 +
                                                      reservation.nb_children *
                                                        1
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "table-text-center",
                                            },
                                            [
                                              _vm._l(
                                                reservation.tables.data,
                                                function (table, index) {
                                                  return reservation.tables.data
                                                    .length > 0
                                                    ? [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(table.name) +
                                                            _vm._s(
                                                              index + 1 <
                                                                reservation
                                                                  .tables.data
                                                                  .length
                                                                ? " - "
                                                                : ""
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    : reservation.num_table &&
                                                      reservation.num_table !==
                                                        ""
                                                    ? [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              reservation.num_table
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    : _vm._e()
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.displayDate(
                                                    _vm.setHourOnDateTime(
                                                      reservation.slot
                                                        .hour_start
                                                    ),
                                                    _vm.TIME_SIMPLE
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.formatMenusAndGeneralOptions(
                                                  reservation
                                                )
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("td", [
                                            reservation.comment
                                              ? _c("div", [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass:
                                                        "text-success",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.clientColon"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        reservation.comment
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.$_.isEmpty(
                                              reservation.comment
                                            ) &&
                                            !_vm.$_.isEmpty(
                                              reservation.restaurant_comment
                                            )
                                              ? _c("div", {
                                                  staticClass: "sepa",
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            reservation.restaurant_comment
                                              ? _c("div", [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass:
                                                        "text-warning",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.privateColon"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        reservation.restaurant_comment
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]),
                                          _vm._v(" "),
                                          reservation.status ===
                                            _vm.RESERVATION_STATUS_VALIDATED
                                              .value ||
                                          reservation.status ===
                                            _vm.RESERVATION_STATUS_CONFIRMED
                                              .value
                                            ? _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.booking.reservations.status.confirmed"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : reservation.status ===
                                              _vm.RESERVATION_STATUS_AT_TABLE
                                                .value
                                            ? _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-center bg-main-color-light",
                                                },
                                                [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass: "text-white",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.reservations.status.at_table",
                                                            _vm.restaurant_id
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      service.reservations.length > 0 ||
                      service.nbPassingCustomer > 0
                        ? _c(
                            "span",
                            { staticClass: "badge badge-secondary ml-1" },
                            [
                              service.reservations.length > 0
                                ? _c("div", [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.$tc(
                                            "labels.booking.reservations.count",
                                            service.reservations.length
                                          )
                                        ) +
                                        "\n                    "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              service.nbPassingCustomer > 0
                                ? _c("div", [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.$tcl(
                                            "labels.booking.reservations.passingCustomer.showCount",
                                            service.nbPassingCustomer
                                          )
                                        ) +
                                        "\n                    "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.services.length > 0 && !_vm.hasReservations
                ? _c("div", [
                    _c("div", { staticClass: "row m-0" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-lg-6 offset-lg-3 pt-5 text-center",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "border-light b-radius-20 p-4 mb-3",
                            },
                            [
                              _c("h5", [
                                _vm._v(
                                  _vm._s(_vm.$tl("labels.booking.list.empty"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "mt-5 none-mobile",
                                attrs: {
                                  width: "360",
                                  src: "/images/empty-table.png",
                                  alt: "No Show",
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "mt-3 none-desk",
                                attrs: {
                                  width: "260",
                                  src: "/images/empty-table.png",
                                  alt: "No Show",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }