<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="row" v-else>
            <div class="col-lg-12 pt-3">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <div class="row">
                        <div class="col-12">
                            <strong>Diffuser votre campagne</strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row mb-2 radio mt-3">
                                <div class="col-12 pt-2">
                                    <label
                                        class="container-box"
                                        style="display: block; width: 100%"
                                        v-for="save_type in ALL_SAVE_TYPES"
                                        :key="save_type.value">
                                        <input type="radio" :value="save_type.value" v-model="campaign.save_type" />
                                        <span class="checkmark"></span> {{ save_type.label }}
                                    </label>
                                    <ShowErrors class="d-block" :errors="errors" errorKey="save_type" />
                                </div>
                            </div>
                            <template v-if="campaign.save_type === SAVE_TYPE_PROG.value">
                                <div class="row mt-3">
                                    <div class="col-md-5">Date</div>
                                    <div class="col-md-7">
                                        <datepicker
                                            format="dd/MM/yyyy"
                                            :disabled-dates="disabledDates"
                                            calendar-class="positition-relative mt-2"
                                            input-class="w-auto"
                                            :monday-first="true"
                                            :language="fr"
                                            v-model="willBeSendAtDate"></datepicker>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-5">Heure</div>
                                    <div class="col-md-7">
                                        <vue-timepicker
                                            format="HH:mm"
                                            :minute-interval="15"
                                            v-model="willBeSendAtHour"
                                            :hide-disabled-items="true"
                                            :hour-range="hourRange"
                                            :lazy="true"></vue-timepicker>
                                    </div>
                                </div>
                                <div class="row">
                                    <ShowErrors class="col-12" :errors="errors" errorKey="will_be_send_at" />
                                </div>
                            </template>
                        </div>
                        <div class="col-md-6 mt-3">
                            <div class="row">
                                <div class="col-8 mb-1">Nombre de cibles potentielles :</div>
                                <div class="col-4">{{ targets.length }}</div>
                                <div class="col-12 mt-1 mb-1">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-success btn-circle mb-1"
                                        @click="showTargetsDetails = !showTargetsDetails">
                                        Détails
                                    </button>
                                    <template v-if="showTargetsDetails">
                                        <small class="d-block text-muted" v-for="target in targets" :key="target.id">
                                            {{ getUserDisplayable(target) }}
                                        </small>
                                    </template>
                                </div>
                            </div>
                            <template v-if="campaign.type == CAMPAIGN_TYPE_SMS.value">
                                <div class="row">
                                    <div class="col-12">Envoyer un SMS de test</div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12">
                                        <input
                                            type="phone"
                                            name="tel"
                                            :disabled="isSendingTestSms"
                                            v-model="tel_test"
                                            class="form-control"
                                            placeholder="Votre numéro de teléphone" />
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12">
                                        <button
                                            class="btn btn-sm btn-success btn-circle"
                                            :disabled="isSendingTestSms || !tel_test"
                                            type="button"
                                            @click="sendSmsTest">
                                            Envoyer
                                        </button>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="row mt-1">
                                    <div class="col-12">Envoyer un EMAIL de test</div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12">
                                        <input
                                            type="email"
                                            name="email"
                                            :disabled="isSendingTestEmail"
                                            v-model="email_test"
                                            class="form-control"
                                            placeholder="Votre email" />
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12">
                                        <button
                                            class="btn btn-sm btn-success btn-circle"
                                            :disabled="isSendingTestEmail || !email_test"
                                            type="button"
                                            @click="sendEmailTest">
                                            Envoyer
                                        </button>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div v-if="campaign.type == CAMPAIGN_TYPE_SMS.value" class="row mt-2">
                        <div class="col-12">
                            <small class="text-danger">
                                Pour information, les envois de campagnes vers des numéros français ne sont pas
                                autorisés le dimanche, les jours fériés et la nuit entre 22h et 8h
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ShowErrors from "../../../errors/ShowErrors";
import LoaderComponent from "../../../LoaderComponent";
import SaveTypesEnum from "../../../../mixins/enums/campaigns/SaveTypesEnum";
import CampaignTypesEnum from "../../../../mixins/enums/campaigns/CampaignTypesEnum";
import VueTimepicker from "vue2-timepicker";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import RolesEnum from "../../../../mixins/enums/RolesEnum";

export default {
    data() {
        return {
            loading: false,
            isSendingTestSms: false,
            isSendingTestEmail: false,
            potentialTargets: null,
            tel_test: null,
            email_test: null,
            willBeSendAtDate: this.campaign.will_be_send_at
                ? new Date(this.campaign.will_be_send_at.split(" ")[0])
                : new Date(),
            willBeSendAtHour: {
                HH: this.campaign.will_be_send_at ? this.campaign.will_be_send_at.split(" ")[1].split(":")[0] : "10",
                mm: this.campaign.will_be_send_at ? this.campaign.will_be_send_at.split(" ")[1].split(":")[1] : "00",
            },
            fr,
            targets: [],
            showTargetsDetails: false,
        };
    },
    props: {
        campaign: {
            type: Object,
            required: true,
        },
        errors: {
            default: null,
        },
        step: {
            type: Number,
            required: true,
        },
    },
    mixins: [SaveTypesEnum, CampaignTypesEnum, RolesEnum],
    computed: {
        hourRange() {
            return this.campaign.type === "sms" ? [[8, 21]] : [[0, 23]];
        },
        disabledDates() {
            let today = new Date();
            today.setDate(today.getDate() - 1);
            let disabledDates = {
                to: today,
            };
            if (this.campaign.type !== this.CAMPAIGN_TYPE_SMS.value) return disabledDates;
            disabledDates.days = [0];
            return disabledDates;
        },
    },
    methods: {
        getUserDisplayable(user) {
            let userDisplayable = `ID : ${user.id} - ${user.firstname} ${user.lastname}`;
            if (this.campaign.type == this.CAMPAIGN_TYPE_SMS.value) userDisplayable += ` - ${user.tel}`;
            else if (this.campaign.type == this.CAMPAIGN_TYPE_EMAIL.value) userDisplayable += ` - ${user.email}`;
            return `${userDisplayable} - ${this.getRoleLabel(user.role)}`;
        },
        sendSmsTest() {
            this.isSendingTestSms = true;

            let data = {
                ...this.campaign,
                tel_test: this.tel_test,
            };

            axios
                .post("/api/campaigns/admin/campaigns/test_sms", data)
                .then((response) => {
                    this.isSendingTestSms = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                })
                .catch((error) => {
                    this.isSendingTestSms = false;
                    if (error.response && error.response.data.errors)
                        this.$emit("set-errors", error.response.data.errors);
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
        sendEmailTest() {
            this.isSendingTestEmail = true;

            let data = {
                ...this.campaign,
                email_test: this.email_test,
            };
            if (data.img_path) data.img_path = this.fileNameOnly(data.img_path);

            axios
                .post("/api/campaigns/admin/campaigns/test_email", data)
                .then((response) => {
                    this.isSendingTestEmail = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                })
                .catch((error) => {
                    this.isSendingTestEmail = false;
                    if (error.response && error.response.data.errors)
                        this.$emit("set-errors", error.response.data.errors);
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
        fetchTargets() {
            this.loading = true;
            this.$emit("set-errors", null);

            axios
                .post("/api/campaigns/admin/campaigns/targets", {
                    ...this.campaign,
                    for_targets: true,
                })
                .then((response) => {
                    this.loading = false;
                    this.targets = response.data.data;
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.data.errors)
                        this.$emit("set-errors", error.response.data.errors);
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
    },
    watch: {
        willBeSendAtDate(newVal) {
            this.campaign.will_be_send_at = moment(newVal)
                .startOf("day")
                .add(this.willBeSendAtHour.HH, "hours")
                .add(this.willBeSendAtHour.mm, "minute")
                .format("Y-MM-DD HH:mm");
        },
        willBeSendAtHour: {
            deep: true,
            immediate: true,
            handler(newVal) {
                this.campaign.will_be_send_at = moment(this.willBeSendAtDate)
                    .startOf("day")
                    .add(newVal.HH, "hours")
                    .add(newVal.mm, "minute")
                    .format("Y-MM-DD HH:mm");
            },
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
        VueTimepicker,
        Datepicker,
    },
};
</script>
