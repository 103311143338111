<template>
    <div class="pb-5 pt-5">
        <LoaderComponent v-if="loading" />
        <div class="alert alert-danger" v-else-if="error">{{ error }}</div>
        <template v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">CGV</h5>
                    </div>
                </div>
            </div>
            <div class="row m-0 mb-3">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-3">
                            <div class="col-12">
                                <strong>Informations concernant les Conditions Générales de Vente</strong>
                                <a
                                    target="_blank"
                                    style="pointer-events: inherit"
                                    :href="cgvUrl"
                                    class="btn btn-sm btn-circle btn-success float-right"
                                    :class="{ disabled: !cc_api_key }"
                                    v-tooltip="
                                        cc_api_key
                                            ? undefined
                                            : {
                                                  content: 'Vous devez générer votre clé d\'API pour voir les CGV par défaut',
                                              }
                                    ">
                                    Voir les CGV par défaut
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p>
                                    Nous mettons à votre disposition des Conditions Générales de Vente standards pour vous permettre de mettre en
                                    place le système de vente à emporter rapidement et dans les meilleures conditions. Nous vous invitons à en prendre
                                    connaissance attentivement.
                                </p>
                                <p>
                                    Afin de vous informer au mieux et de comprendre les enjeux de bonnes CGV, nous vous invitons à lire la
                                    documentation officielle
                                    <a
                                        target="_blank"
                                        href="https://www.francenum.gouv.fr/guides-et-conseils/developpement-commercial/site-e-commerce/rediger-des-conditions-generales-de">
                                        en cliquant ici.
                                    </a>
                                    Vous pouvez donc rédiger vous-même vos CGV, vous faire assister par votre avocat ou contacter nos services
                                    compétents dans la rédaction de CGV adaptées a vos besoins :
                                    <a href="mailto:contact@yproximite.com">contact@yproximite.com.</a>
                                </p>
                                <template v-if="!isDeliveryEnabled">
                                    <p>
                                        Nous vous conseillons toutefois d'intégrer vos propres Conditions Générales de Vente en important votre
                                        fichier ci-dessous.
                                    </p>
                                    <p class="mb-0 text-danger">Sans action de votre part, les conditions par défaut seront appliquées.</p>
                                </template>
                                <p class="mb-0 text-danger font-weight-bold" v-else>
                                    Veillez à intégrer vos propres Conditions Générales de Vente en lien avec la livraison.
                                </p>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-7 mt-2">
                                <FileUploaderComponent
                                    :disabled="!has_right_to_update_config"
                                    :src="cgv"
                                    suffixId="cgv"
                                    :postURI="baseURIPdf"
                                    :deleteURI="baseURIPdf"
                                    :callBackPost="
                                        (response) => {
                                            this.cgv = response.data.file_name;
                                        }
                                    "
                                    :callBackDelete="
                                        () => {
                                            this.cgv = null;
                                        }
                                    " />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import FileUploaderComponent from "../../../components/forms/FileUploaderComponent.vue";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import ShowErrors from "../../../components/errors/ShowErrors.vue";

export default {
    data() {
        return {
            loading: false,
            cgv: null,
            error: null,
            isDeliveryEnabled: false,
            cc_api_key: null,
        };
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_config() {
            return this.rights.includes("click_and_collect.config.update");
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        baseURIPdf() {
            return `/api/click_and_collect/${this.restaurant_id}/cgv`;
        },
        cgvUrl() {
            if (!this.cc_api_key) return null;
            return `/click_and_collect/widget/public/${this.cc_api_key}/cgv`;
        },
    },
    methods: {
        fetchPdf() {
            this.loading = true;
            this.error = null;

            axios
                .get(this.baseURIPdf)
                .then((response) => {
                    this.cgv = response.data.cgv;
                    this.isDeliveryEnabled = response.data.is_delivery_enabled;
                    this.cc_api_key = response.data.cc_api_key;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => (this.loading = false));
        },
    },
    components: {
        FileUploaderComponent,
        LoaderComponent,
        ShowErrors,
    },
    created() {
        this.fetchPdf();
    },
};
</script>
