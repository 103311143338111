<template>
    <thead>
        <ns-th
            v-for="(heading, index) in activeHeadings"
            :key="index"
            :field="heading.field"
            class="text-nowrap"
            :class="{ 'none-mobile': heading.hideOnMobile, 'none-desk': heading.hideOnDesktop }"
            :width="heading.width"
            :allow-sorting="heading.allowSorting"
            :allow-filtering="heading.allowFiltering"
            :sort-comparer="heading.sortableFunction ?? null"
            :filter-options="heading.filterOptions ?? null">
            <span
                v-if="heading.title && heading.title.content"
                :class="{ 'none-mobile': heading.title.hideOnMobile, 'none-desk': heading.title.hideOnDesktop }">
                {{ heading.title.content }}
            </span>
            <feather
                v-if="heading.icon && heading.icon.name"
                :type="heading.icon.name"
                class="feather-20 mr-4"
                :class="{ 'none-mobile': heading.icon.hideOnMobile, 'none-desk': heading.icon.hideOnDesktop }" />
        </ns-th>
    </thead>
</template>
<script>
import NsTh from "./NsTh.vue";

export default {
    props: {
        headings: {
            type: Array,
            required: true,
        },
        excludedColumns: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        activeHeadings() {
            return this.headings.filter((heading) => !heading.disabled && !this.excludedColumns.includes(heading.columnName));
        },
    },
    components: {
        NsTh,
    },
};
</script>
<style scoped>
table thead th {
    border-left: 1px solid #dee2e6;
}
</style>
