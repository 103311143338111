<template>
    <div>
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <div>
                        <h5 class="title mt-2">Désinscriptions</h5>
                    </div>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div class="row m-0" v-else>
            <div class="col-12">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <form @submit="fetchData">
                        <div>
                            Renouvellement&nbsp;
                            <select class="custom-select d-inline-block" style="width: auto" v-model="filter.plan">
                                <option :value="null">Tous</option>
                                <option :value="type.value" v-for="type in ALL_STRIPE_PLAN_TYPES" :key="type.value">
                                    {{ type.label }}
                                </option>
                            </select>
                            &nbsp;Module&nbsp;
                            <select class="custom-select d-inline-block" style="width: auto" v-model="filter.module">
                                <option :value="null">Tous</option>
                                <option :value="type.value" v-for="type in ALL_MODULES_TYPES_WITH_OPTIONS" :key="type.value">
                                    {{ type.label }}
                                </option>
                            </select>
                        </div>
                        Entre le&nbsp;
                        <datepicker
                            class="d-inline-block"
                            format="dd/MM/yyyy"
                            input-class="form-control"
                            :monday-first="true"
                            :language="fr"
                            v-model="filter.from_date"></datepicker>
                        &nbsp;et le&nbsp;
                        <datepicker
                            class="d-inline-block"
                            format="dd/MM/yyyy"
                            input-class="form-control"
                            :monday-first="true"
                            :language="fr"
                            v-model="filter.to_date"></datepicker>
                        <button type="submit" class="btn btn-sm btn-success btn-circle ml-2">Rechercher</button>
                        <button @click="resetFilter" type="button" class="btn btn-sm btn-success btn-circle ml-2">Réinitialiser les filtres</button>
                    </form>
                </div>
            </div>
            <div class="col-12" v-if="subscriptions">
                <span class="d-block mt-1">Total : {{ subscriptions.length }}</span>
                <SortableTable tableClass="table table-sm table-striped border-bottom" :columns="columns" :data="formattedData" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import LoaderComponent from "../LoaderComponent";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum";
import StripePlanTypesEnum from "../../mixins/enums/StripePlanTypesEnum";
import SubscriptionStatusEnum from "../../mixins/enums/SubscriptionStatusEnum";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import SortableTable from "../SortableTable";

const defaultFilter = {
    from_date: null,
    to_date: null,
    plan: null,
    module: null,
};

export default {
    data() {
        return {
            loading: false,
            filter: _.cloneDeep(defaultFilter),
            fr,
            moment,
            subscriptions: null,
            columns: [
                {
                    name: "Compte admin",
                    style: "text-transform: capitalize;",
                    sortable: true,
                    sortable_type: "string",
                    sort_on: "label",
                    data_format: "custom",
                    data_format_fct: (data) => (data.owner ? `${data.owner.firstname} ${data.owner.lastname}` : "Owner inexistant"),
                    get_custom_class: (data) => (data.owner ? "" : "text-danger"),
                },
                {
                    name: "Module",
                    sortable: true,
                    sortable_type: "string",
                    sort_on: "label",
                    data_format: "custom",
                    data_format_fct: (data) => this.getModuleTypeLabel(data.module_type),
                },
                {
                    name: "Engagement",
                    sortable: true,
                    sortable_type: "string",
                    sort_on: "label",
                    data_format: "custom",
                    data_format_fct: (data) => {
                        const hasBeenActive = Object.values(data.invoices).some((invoice) => invoice.amount > 0);
                        if (!hasBeenActive) return "Essai gratuit";
                        return this.getStripePlanTypeLabel(data.subscription.plan);
                    },
                },
                {
                    name: "Date de souscription",
                    key: "subscription.created_at",
                    sortable: true,
                    sortable_type: "datetime",
                    data_format: "moment",
                    data_format_moment: "DD MMMM Y",
                    default_sort: {
                        order: "desc",
                    },
                },
                {
                    name: "Date d'annulation",
                    key: "subscription.canceled",
                    sortable: true,
                    sortable_type: "datetime",
                    data_format: "moment",
                    data_format_moment: "DD MMMM Y",
                    get_custom_class: (data) => (data.subscription.canceled ? "" : "text-danger"),
                },
                {
                    name: "Stripe subscription_id",
                    key: "stripe_subscription.id",
                    sortable: false,
                    data_format: "string",
                    link: {
                        url: (data) =>
                            data.stripe_subscription !== null && `https://dashboard.stripe.com/test/subscriptions/${data.stripe_subscription.id}`,
                        target: "_blank",
                    },
                },
            ],
        };
    },
    mixins: [ModuleTypesEnum, StripePlanTypesEnum, SubscriptionStatusEnum],
    computed: {
        formattedData() {
            if (!this.subscriptions) return [];
            let data = [];
            Object.values(this.subscriptions).forEach((subscription) => {
                Object.keys(subscription.modules).forEach((module_type) => {
                    Object.values(subscription.modules[module_type].subscriptions.data).forEach((s) => {
                        data.push({
                            owner: subscription.owner,
                            module_type,
                            subscription: s,
                            invoices: Object.values(subscription.modules[module_type].invoices)
                                .flat(1)
                                .filter((invoice) => {
                                    const invoiceCreatedAt = moment.unix(invoice.created);
                                    return (
                                        moment(s.canceled).isSameOrAfter(invoiceCreatedAt) &&
                                        moment(s.created_at).subtract(5, "minute").isSameOrBefore(invoiceCreatedAt)
                                    );
                                }),
                            stripe_subscription: subscription.stripe_subscription,
                        });
                    });
                });
            });
            return data;
        },
    },
    methods: {
        getFormattedRestaurants(subscription) {
            if (
                !subscription.owner ||
                !subscription.owner.restaurants ||
                !subscription.owner.restaurants.data ||
                subscription.owner.restaurants.data.length == 0
            )
                return "--";
            return subscription.owner.restaurants.data.map((r) => r.name).join("<br/>");
        },
        resetFilter(e) {
            if (e) e.preventDefault();
            this.filter = _.cloneDeep(defaultFilter);
        },
        fetchData(e) {
            if (e) e.preventDefault();

            this.subscriptions = null;
            this.loading = true;

            let params = {
                include: "restaurants",
                filter_subscription_status: this.SUBSCRIPTION_STATUS_CANCELED.value,
                with_stripe_subscription: true,
            };
            if (this.filter.from_date) params.from_date = moment(this.filter.from_date).format("Y-MM-DD");
            if (this.filter.to_date) params.to_date = moment(this.filter.to_date).format("Y-MM-DD");
            if (this.filter.plan) params.plan = this.filter.plan;
            if (this.filter.module) params.module = this.filter.module;
            this.$store
                .dispatch("admin/fetchSubscriptionsWithStripeInvoices", { params })
                .then((response) => {
                    this.loading = false;
                    this.subscriptions = response.data.data;
                })
                .catch((error) => {
                    this.loading = false;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
    },
    components: {
        LoaderComponent,
        Datepicker,
        SortableTable,
    },
    created() {},
};
</script>
