<template>
    <modal @close.stop="closeFromModal($event)">
        <h5 slot="header">Ajouter une catégorie</h5>
        <div slot="body">
            <LoaderComponent v-if="isLoading" />
            <div v-else class="row mb-3">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-md-5">
                                <label>Nom <small>*</small></label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" v-model="name" required />
                                <show-errors class="d-block" :errors="errors.form" errorKey="name" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="d-flex">
            <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="$emit('close')">Annuler</button>
            <button type="button" class="btn btn-sm btn-success btn-circle ml-2" @click="saveCategory">Enregistrer</button>
        </div>
    </modal>
</template>
<script>
import LoaderComponent from "../../LoaderComponent.vue";
import ShowErrors from "../../errors/ShowErrors.vue";

export default {
    data() {
        return {
            isLoading: false,
            name: "",
            errors: {
                form: null,
            },
        };
    },
    props: {
        currentCategory: {
            type: Object,
            default: null,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        baseApiUrl() {
            return `/api/gift_vouchers/${this.restaurant_id}/gvCategories`;
        },
    },
    methods: {
        closeFromModal(event) {
            if (event && event.target && event.target.classList.contains("modal-wrapper")) {
                this.$emit("close");
            }
        },
        editCategory() {
            this.isLoading = true;

            this.httpPut(`${this.baseApiUrl}/${this.currentCategory.id}`, { name: this.name })
                .then((response) => {
                    if (response !== false) {
                        this.$emit("category-edited", response.data);
                        this.$emit("close");
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        addCategory() {
            this.isLoading = true;

            this.httpPost(this.baseApiUrl, { name: this.name })
                .then((response) => {
                    if (response !== false) {
                        this.$emit("category-added", response.data);
                        this.$emit("close");
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        saveCategory() {
            if (this.currentCategory === null) {
                return this.addCategory();
            }
            return this.editCategory();
        },
    },
    mounted() {
        this.name = this.currentCategory !== null ? this.currentCategory.name : "";
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
};
</script>
