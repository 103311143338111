var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "pointer",
        on: {
          click: function ($event) {
            _vm.show = !_vm.show
          },
        },
      },
      [
        _c("strong", [_vm._v(_vm._s(_vm.categoryName))]),
        _vm._v(" "),
        _c("feather", {
          attrs: { type: _vm.show ? "chevron-down" : "chevron-right" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show",
          },
        ],
        staticClass: "p-1",
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }