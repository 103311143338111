var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("drop-down", {
        attrs: {
          options: _vm.isCreatedBooking ? [] : _vm.currentStatuses,
          "use-icon": _vm.useIcon,
          "use-label-for-icon": "",
          "use-label": false,
          "selected-option": _vm.selectedOption,
          "btn-classes-string":
            "btn btn-sm btn-outline-secondary btn-circle booking-status",
        },
        on: { "option-changed": _vm.changeOption },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }