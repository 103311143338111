var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c("div", { staticClass: "div-profil" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-7 mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm._v("Numéro"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6 value" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.currentSale.numero) +
                                  "\n                            "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm._v("Statut"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6 value" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.capitalize(
                                      _vm.getSaleStatusLabel(
                                        _vm.currentSale.status
                                      )
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm._v("Utilisé"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6 value" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.currentSale.used ? "Oui" : "Non") +
                                  "\n                            "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm._v("Produit"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-6 value" },
                              [
                                _vm.currentSale.gv_product
                                  ? [
                                      _vm.currentSale.gv_product.type === "menu"
                                        ? _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.currentSale
                                                      .menu_quantity
                                                  ) +
                                                  " x " +
                                                  _vm._s(
                                                    _vm.currentSale.gv_product
                                                      .name
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.currentSale.gv_product.type ===
                                      "amount"
                                        ? _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.currentSale.gv_product
                                                      .name
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  : _c("span", { staticClass: "d-block" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.currentSale
                                            .imported_product_name || "--"
                                        )
                                      ),
                                    ]),
                                _vm._v(" "),
                                _vm.currentSale.expedition_type ===
                                "postal_mail"
                                  ? _c("span", { staticClass: "d-block" }, [
                                      _vm._v("1 x Livraison"),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.currentSale.gv_sale_product_options.data.length >
                          0
                            ? _c("div", { staticClass: "sepa" })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentSale.gv_sale_product_options.data.length >
                          0
                            ? _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v("Options"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-6 value" },
                                  _vm._l(
                                    _vm.currentSale.gv_sale_product_options
                                      .data,
                                    function (products_option) {
                                      return _c(
                                        "div",
                                        {
                                          key: products_option.id,
                                          staticClass: "row",
                                        },
                                        [
                                          _c("div", { staticClass: "col-12" }, [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  products_option.quantity
                                                ) +
                                                " x\n                                        " +
                                                _vm._s(
                                                  products_option
                                                    .gv_products_option.name
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm._v("Montant"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6 value" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.currentSale.amount !== null
                                      ? _vm.formatCurrency(
                                          _vm.currentSale.expedition_fees !==
                                            null
                                            ? _vm.currentSale.amount +
                                                _vm.currentSale.expedition_fees
                                            : _vm.currentSale.amount
                                        )
                                      : "--"
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm._v("Moyen de paiement"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6 value" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.getPaymentTypeLabel(
                                      _vm.currentSale.payment_type
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm._v("Date d'expiration"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6 value" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.formatDateToFr(
                                      _vm.currentSale.expires_on
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "sepa" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12 mb-1" }, [
                              _vm._v("Commentaire public"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12 value" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.currentSale.comment_client) +
                                  "\n                            "
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-5 mb-3" },
                      [
                        _vm.isLoadingClient
                          ? _c("loader-component")
                          : [
                              _vm.currentSale.client
                                ? _c("client-card", {
                                    attrs: {
                                      client: _vm.currentSale.client,
                                      "module-enum":
                                        _vm.MODULE_TYPE_GIFT_VOUCHERS,
                                      "is-change-in-progress":
                                        _vm.isAssociatingInProgress,
                                    },
                                    on: {
                                      "cancel-in-progress": _vm.emptyClient,
                                      "update-in-progress":
                                        _vm.associateClientBack,
                                    },
                                  })
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-center h-100",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-success btn-circle",
                                          on: {
                                            click: function ($event) {
                                              _vm.canOpenSearchClientModal = true
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.form.actions.associate.client"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                            ],
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.currentSale.has_beneficiary
                    ? _c("div", { staticClass: "row mb-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "border-light b-radius-20 p-4" },
                            [
                              _c("label", { staticClass: "mb-1" }, [
                                _c("strong", [_vm._v("Bénéficiaire")]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currentSale.beneficiary_firstname
                                    ) +
                                      " " +
                                      _vm._s(
                                        _vm.currentSale.beneficiary_lastname
                                      )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.currentSale.beneficiary_email
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.currentSale.beneficiary_email
                                          ) +
                                          "\n                            "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentSale.expedition_type ===
                  _vm.EXPEDITION_TYPE_POSTAL_MAIL.value
                    ? _c("div", { staticClass: "row mb-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "border-light b-radius-20 p-4" },
                            [
                              _c("label", { staticClass: "mb-1" }, [
                                _c("strong", [_vm._v("Expédition")]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _vm._v(
                                    _vm._s(_vm.currentSale.delivery_firstname) +
                                      " " +
                                      _vm._s(_vm.currentSale.delivery_lastname)
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currentSale.full_delivery_address
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("label", { staticClass: "mb-1" }, [
                            _c("strong", [_vm._v("Commentaire privé")]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.currentSale.comment_private) +
                                  "\n                            "
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  _vm.showLog = !_vm.showLog
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "col-12" }, [
                                _c("strong", { staticClass: "mb-0" }, [
                                  _vm._v("Historique des modifications"),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.showLog
                            ? [
                                _c("ShowSaleLogsList", {
                                  attrs: { sale: _vm.currentSale },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row m-0" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 text-right pr-4" },
                      [
                        _c("div", [
                          _c(
                            "i",
                            [
                              _vm._v(
                                "Bon cadeau " +
                                  _vm._s(
                                    _vm.getSaleTypeLabel(
                                      _vm.currentSale.sale_type
                                    )
                                  )
                              ),
                              _vm.currentSale.sale_type !=
                              _vm.SALE_TYPE_IMPORTED.value
                                ? _c("span", [_vm._v(" ajouté")])
                                : _vm._e(),
                              _vm._v(
                                " le\n                            " +
                                  _vm._s(
                                    _vm.formatDateTime(
                                      _vm.currentSale.created_at
                                    )
                                  )
                              ),
                              _vm.saleUserDisplayable
                                ? _c("span", [
                                    _vm._v(
                                      " par " + _vm._s(_vm.saleUserDisplayable)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v("."),
                              _vm.showSendBy
                                ? [
                                    _vm._v(
                                      "\n                                Envoyé par " +
                                        _vm._s(
                                          _vm.getExpeditionTypeLabel(
                                            _vm.currentSale.expedition_type
                                          )
                                        ) +
                                        " au\n                                " +
                                        _vm._s(
                                          _vm.currentSale.has_beneficiary
                                            ? "bénéficiaire"
                                            : "client"
                                        )
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.currentSale.mail_logs
                          ? _vm._l(
                              _vm.currentSale.mail_logs.data,
                              function (mailLog) {
                                return _c("div", { key: mailLog.id }, [
                                  mailLog.permanent_failed_at ||
                                  !mailLog.sent_at
                                    ? _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            "Une erreur est survenue lors de l'envoi du mail de " +
                                              _vm._s(
                                                _vm.getSaleMailLogLabel(
                                                  mailLog.type
                                                )
                                              )
                                          ),
                                        ]
                                      )
                                    : mailLog.sent_at && !mailLog.delivered_at
                                    ? _c(
                                        "small",
                                        { staticClass: "text-warning" },
                                        [
                                          _vm._v(
                                            "Le mail de " +
                                              _vm._s(
                                                _vm.getSaleMailLogLabel(
                                                  mailLog.type
                                                )
                                              ) +
                                              " est en cours d'envoi"
                                          ),
                                        ]
                                      )
                                    : mailLog.delivered_at
                                    ? _c("small", [
                                        _vm._v(
                                          "Mail de " +
                                            _vm._s(
                                              _vm.getSaleMailLogLabel(
                                                mailLog.type
                                              )
                                            ) +
                                            " envoyé le\n                                " +
                                            _vm._s(
                                              _vm
                                                .moment(mailLog.delivered_at)
                                                .format("DD/MM/Y[ à ]HH:mm")
                                            )
                                        ),
                                        mailLog.opened_at
                                          ? _c("span", [_vm._v(" (ouvert)")])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ])
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ]),
          ]),
      _vm._v(" "),
      _vm.canOpenSearchClientModal
        ? _c("wrapper-search-client-modal", {
            on: {
              close: function ($event) {
                _vm.canOpenSearchClientModal = false
              },
              "selected-client": _vm.associateClient,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }