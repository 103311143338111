<template>
    <table class="table table-sm table-striped border-bottom">
        <thead class="border-bottom">
            <tr>
                <th>{{ $tl("labels.booking.reservations.history.columns.date") }}</th>
                <th>{{ $tl("labels.booking.reservations.history.columns.restaurant", restaurantId) }}</th>
                <th>{{ $tl("labels.booking.reservations.history.columns.slot") }}</th>
                <th>{{ $tl("labels.booking.reservations.history.columns.status") }}</th>
                <th class="none-mobile">{{ $tl("labels.booking.reservations.history.columns.nbPers") }}</th>
                <th class="none-desk">{{ $tl("labels.booking.reservations.history.columns.pax") }}</th>
                <th class="none-mobile">{{ $tl("labels.booking.reservations.history.columns.nbChildren") }}</th>
                <th class="none-desk">{{ $tl("labels.booking.reservations.history.columns.children") }}</th>
                <th>{{ $tl("labels.booking.reservations.history.columns.actions") }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="resa in reservations" :key="resa.id">
                <td>{{ displayDate(resa.reservation_datetime, DATE_FULL) }}</td>
                <td>{{ resa.restaurant.name }}</td>
                <td>{{ displayDate(setHourOnDateTime(resa.slot.hour_start), TIME_SIMPLE) }}</td>
                <td>
                    {{ getReservationStatusLabel(resa.status) }}
                    <hotel-client-icon v-if="resa.is_hotel_client" />
                </td>
                <td>{{ resa.nb_pers }}</td>
                <td>{{ resa.nb_children || 0 }}</td>
                <td>
                    <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary btn-square"
                        @click="$emit('show-resa', resa)"
                        v-tooltip="getTooltip($t('labels.booking.reservations.history.actions.view'))">
                        <feather type="eye" />
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum";
import hotelClientIcon from "../../components/reservations/hotelClientIcon.vue";

export default {
    data() {
        return {};
    },
    mixins: [ReservationStatusEnum],
    props: {
        reservations: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    computed: {
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
    },
    components: {
        hotelClientIcon,
    },
};
</script>
