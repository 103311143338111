<template>
    <modal>
        <h5 slot="header">{{ $tl("labels.booking.reservations.add.title") }}</h5>
        <div slot="body">
            <AddReservation
                ref="addReservation"
                :restaurant_id="restaurant_id"
                :slotIdToPreselect="slot_id"
                :serviceIdToPreselect="service_id"
                :tablesIdToPreselect="tablesIdToPreselect"
                :date="date"
                :defaultValues="defaultValues"
                @enable-next-button="nextButtonEnable = $event"
                @change-step="step = $event"
                @saved="$emit('reservation-saved')" />
        </div>
        <div slot="footer" class="d-flex">
            <button class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="$emit('close')">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button v-if="step > 1" class="btn btn-outline-secondary btn-sm btn-circle ml-2" @click="$refs.addReservation.setStep(step - 1)">
                {{ $tl("labels.form.actions.previous") }}
            </button>
            <button
                class="btn btn-outline-secondary btn-sm btn-circle ml-2"
                v-if="step === 1 || step === 3"
                :disabled="!nextButtonEnable"
                @click="$refs.addReservation.setStep(step + 1)">
                {{ $tl("labels.form.actions.next") }}
            </button>
            <template v-if="showWaitingListButton && step === 4">
                <button class="btn btn-outline-secondary btn-sm btn-circle ml-2" @click="$refs.addReservation.saveInWaitingList()">
                    {{ $tl("labels.booking.waitings.list.title") }}
                </button>
                <button class="btn btn-outline-secondary btn-sm btn-circle ml-2" @click="$refs.addReservation.saveAsOption()">
                    {{ $tl("labels.booking.reservations.status.option") }}
                </button>
            </template>
            <button v-if="step === 4" class="btn btn-success btn-sm btn-circle ml-2" @click="$refs.addReservation.save()">
                {{ $tl("labels.form.actions.save") }}
            </button>
        </div>
    </modal>
</template>

<script>
import AddReservation from "../../reservations/add/AddReservation.vue";

export default {
    data() {
        return {
            step: 1,
            nextButtonEnable: true,
        };
    },
    props: {
        restaurant_id: {
            default: "",
        },
        slot_id: {
            default: null,
        },
        service_id: {
            default: null,
        },
        date: {
            default: null,
        },
        defaultValues: {
            type: Object,
            default: null,
        },
        showWaitingListButton: {
            default: true,
        },
        tablesIdToPreselect: {
            default: () => {
                return [];
            },
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
    components: {
        AddReservation,
    },
};
</script>
