<template>
    <div class="w-100">
        <loader-component v-if="loading" />
        <div v-else-if="error">
            {{ error }}
        </div>
        <div v-else>
            <div class="row m-0 pt-5 p-4">
                <restaurant-card
                    @click="showBoardForRestaurant($event.id)"
                    v-for="(restaurant, index) in restaurants"
                    :key="index"
                    :restaurant="restaurant"
                    class="col-md-4 mb-3" />
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import RestaurantCard from "../Default/Restaurants/RestaurantCard";

export default {
    name: "WebsiteListRestaurants",
    data() {
        return {
            error: null,
            loading: false,
        };
    },
    props: {
        user: {
            required: true,
        },
    },
    computed: {
        restaurants() {
            return this.$store.getters["restaurants/getRestaurantsByService"]("gift-vouchers").filter((r) => {
                return this.$store.getters["users/restaurantIds"].includes(r.id);
            });
        },
    },
    created() {
        if (this.restaurants && this.restaurants.length == 1) {
            this.$router.push({
                name: "gift_vouchers.restaurants.sales",
                params: { restaurant_id: this.restaurants[0].id },
            });
        }
    },
    methods: {
        showBoardForRestaurant(restaurant_id) {
            this.$router.push({ name: "gift_vouchers.restaurants.sales", params: { restaurant_id } });
        },
    },
    components: {
        LoaderComponent,
        RestaurantCard,
    },
};
</script>
