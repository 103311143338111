<template>
    <div>
        <div class="default-home">
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">{{ $tl("labels.routes.rgpd") }}</h5>
                    </div>
                </div>
            </div>
            <LoaderComponent v-if="loading" />
            <template v-else>
                <div class="row m-0">
                    <div class="col-sm-12">
                        <div class="border-light b-radius-20 p-4 mb-3">
                            <div class="row mb-3">
                                <div class="col-12">
                                    <strong class="float-left">{{ $tl("labels.rgpd.timeToKeep") }}</strong>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-8 pt-2">{{ $tl("labels.rgpd.clientNewsletter") }}</div>
                                <div class="col-md-4">
                                    <input
                                        type="number"
                                        class="form-control"
                                        v-model="rgpd.keep_clients_on_newsletter_for"
                                        step="1"
                                        min="1"
                                        max="3" />
                                    <ShowErrors class="d-block" :errors="errors" errorKey="keep_clients_on_newsletter_for" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-8 pt-2">{{ $tl("labels.rgpd.clientData") }}</div>
                                <div class="col-md-4">
                                    <input
                                        type="number"
                                        class="form-control"
                                        v-model="rgpd.keep_clients_details_and_history_for"
                                        step="1"
                                        min="1"
                                        max="10" />
                                    <ShowErrors class="d-block" :errors="errors" errorKey="keep_clients_details_and_history_for" />
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12 text-muted">
                                    <small>{{ $t("infos.rgpd.legalTimeToKeep1") }}<br />{{ $t("infos.rgpd.legalTimeToKeep2") }}</small>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <button class="btn btn-success btn-circle" @click="save">
                                        {{ $tl("labels.form.actions.save") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-sm-12">
                        <div class="border-light b-radius-20 p-4 mb-3">
                            <div class="row mb-3">
                                <div class="col-12">
                                    <strong class="float-left">{{ $tl("labels.rgpd.pcdp") }}</strong>
                                </div>
                            </div>
                            <div class="row text-center">
                                <div class="col-12 pt-2">
                                    <a :href="`/default_data_policy`" target="_blank">
                                        <p class="bg-light p-3">{{ $tl("labels.rgpd.pcdp") }}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent";
import ShowErrors from "../../../components/errors/ShowErrors";
import axios from "axios";

export default {
    data() {
        return {
            loading: false,
            errors: null,
            criticalError: false,
            rgpd: {
                keep_clients_on_newsletter_for: undefined,
                keep_clients_details_and_history_for: undefined,
            },
        };
    },
    computed: {
        isOwner() {
            return this.$store.getters["users/role"] === "owner";
        },
    },
    methods: {
        fetchData(loading = true) {
            this.loading = loading;

            this.$store
                .dispatch("users/fetchCurrentUser", { set: true })
                .then((data) => {
                    this.loading = false;
                    _.assign(this.rgpd, data);
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        save() {
            this.loading = true;
            this.errors = null;

            axios
                .post("/api/rgpd", this.rgpd)
                .then((response) => {
                    this.fetchData(false);
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
    created() {
        if (!this.isOwner) this.$router.push("/");
        else this.fetchData();
    },
};
</script>
