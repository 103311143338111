var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.firstMailLog
    ? _c(
        "div",
        [
          _c("ShowReservationMailLog", {
            attrs: { mailLog: _vm.firstMailLog },
          }),
          _vm._v(" "),
          _vm.showMore
            ? _c(
                "div",
                _vm._l(_vm.otherMailLogs, function (log) {
                  return _c("ShowReservationMailLog", {
                    key: log.id,
                    attrs: { mailLog: log },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.otherMailLogs.length > 0
            ? _c(
                "small",
                {
                  staticClass: "text-muted pointer",
                  on: {
                    click: function ($event) {
                      _vm.showMore = !_vm.showMore
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.showMore
                          ? _vm.$tl("labels.showLess")
                          : _vm.$tl("labels.showMore")
                      ) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }