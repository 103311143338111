<template>
    <div>
        <a href="#" @click.prevent="opened = !opened">{{ opened ? "Cacher les logs" : "Voir les logs" }}</a>
        <ul v-if="opened" class="mt-1">
            <li v-for="log in logs" :key="log.id">
                {{ getActionRightLabel(log.action) }} : {{ getDisplayableUser(log) }}, le {{ displayDate(log.created_at, DATETIME_SHORT) }}
            </li>
        </ul>
    </div>
</template>

<script>
import ActionRightTypesEnum from "../../../mixins/enums/ActionRightTypesEnum.js";

export default {
    data() {
        return {
            opened: false,
        };
    },
    mixins: [ActionRightTypesEnum],
    props: {
        logs: {
            type: Array,
            required: true,
        },
    },
    methods: {
        getDisplayableUser(log) {
            if (log.user !== null) {
                return `${log.user.firstname} ${log.user.lastname}`;
            }

            return "{Compte supprimé}";
        },
    },
};
</script>
