var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      on: {
        close: function ($event) {
          $event.stopPropagation()
          return _vm.closeFromModal.apply(null, arguments)
        },
      },
    },
    [
      _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(
          _vm._s(
            _vm.customEventId !== null
              ? _vm.$tl("labels.booking.customEvents.edit")
              : _vm.$tl("labels.booking.customEvents.add")
          )
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _c("custom-event-form", {
            ref: "form",
            attrs: {
              "restaurant-id": _vm.restaurantId,
              "custom-event-id": _vm.customEventId,
              date: _vm.date,
            },
            on: {
              "fully-loaded": _vm.onFormLoaded,
              "is-editable": function ($event) {
                _vm.isEditable = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "button",
            {
              staticClass:
                "modal-default-button btn btn-sm btn-secondary btn-circle",
              attrs: { type: "button" },
              on: { click: _vm.close },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-success btn-circle ml-2",
              attrs: {
                type: "button",
                disabled: _vm.isLoading || !_vm.isEditable,
              },
              on: { click: _vm.save },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$tl("labels.form.actions.save")) +
                  "\n        "
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }