<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <label class="mb-3">{{ label }}</label>
            </div>
            <div class="col-md-8">
                <div class="d-flex justify-content-between">
                    <div class="radio">
                        <radio-button v-model="modeComputed" :inputValue="NOTIFICATION_TYPE_SMS.value" :disabled="disabled">
                            {{ $tl("labels.notifications.sms") }}
                            <div
                                @click.stop
                                v-if="hasSmsUnsavedChange"
                                class="red-bubble cursor-default"
                                v-tooltip="getTooltip($tl('infos.common.unsavedChanges'))"></div>
                        </radio-button>

                        <radio-button v-model="modeComputed" :inputValue="NOTIFICATION_TYPE_EMAIL.value" :disabled="disabled">
                            {{ $tl("labels.notifications.mail") }}
                            <div
                                @click.stop
                                v-if="hasMailUnsavedChange"
                                class="red-bubble cursor-default"
                                v-tooltip="getTooltip($tl('infos.common.unsavedChanges'))"></div>
                        </radio-button>

                        <radio-button
                            v-if="canBeDisabled"
                            v-model="modeComputed"
                            :inputValue="NOTIFICATION_TYPE_DISABLED.value"
                            :label="$tl('labels.notifications.disabled')"
                            :disabled="disabled">
                            {{ $tl("labels.notifications.disabled") }}
                        </radio-button>
                    </div>

                    <button
                        v-if="hasMailPreview && modeComputed === NOTIFICATION_TYPE_EMAIL.value"
                        type="button"
                        class="btn btn-sm btn-outline-secondary btn-circle"
                        style="margin-top: -4px"
                        @click="showPreviewEmailModal = true">
                        {{ $tl("labels.form.actions.preview") }}
                    </button>

                    <button
                        v-if="hasSmsPreview && modeComputed === NOTIFICATION_TYPE_SMS.value"
                        type="button"
                        class="btn btn-sm btn-outline-secondary btn-circle"
                        style="margin-top: -4px"
                        :disabled="doesSmsContainEmojis"
                        v-tooltip="doesSmsContainEmojis ? getTooltip($tl('errors.common.sms.emojisNotAllowed')) : undefined"
                        @click="showPreviewSmsModal = true">
                        {{ $tl("labels.form.actions.preview") }}
                    </button>
                </div>
            </div>
        </div>

        <slot></slot>

        <div class="row mt-1">
            <div class="col-md-8 offset-md-4">
                <editor-with-merge-tags
                    v-show="modeComputed === NOTIFICATION_TYPE_EMAIL.value"
                    v-model="mailContentComputed"
                    :disabled="disabled || !isMailContentEditable"
                    :content-locale="contentLocale"
                    :merge-tags="mailsMergeTags"></editor-with-merge-tags>

                <editor-with-merge-tags
                    v-show="modeComputed === NOTIFICATION_TYPE_SMS.value"
                    v-model="smsContentComputed"
                    strip-tags
                    :maxlength="maxCreditsPerSms * nbCharsPerCredits"
                    :disabled="disabled || !isSmsContentEditable"
                    :content-locale="contentLocale"
                    :merge-tags="smsMergeTags">
                    <div v-if="doesSmsContainEmojis" class="text-danger font-weight-bold">
                        {{ $tl("errors.common.sms.emojisNotAllowed") }}
                    </div>
                    <div v-if="nbCreditsNeeded > 3" class="text-danger">
                        {{ $tl("errors.common.sms.exceedMaxLength") }}
                    </div>
                    <div
                        v-else
                        :class="{ 'text-success': nbCreditsNeeded < 2, 'text-warning': nbCreditsNeeded === 2, 'text-danger': nbCreditsNeeded > 2 }">
                        {{ $t("labels.smsLengthApprox", { smsLength }) }} ({{ $tc("labels.smsCost", nbCreditsNeeded) }})
                    </div>
                </editor-with-merge-tags>
            </div>
        </div>

        <ShowErrors v-if="errorKey !== undefined" class="d-block" :errors="formErrors" :errorKey="`${errorKey}_mode`" />
        <ShowErrors v-if="errorKey !== undefined" class="d-block" :errors="formErrors" :errorKey="`${errorKey}_sms_content`" />
        <ShowErrors v-if="errorKey !== undefined" class="d-block" :errors="formErrors" :errorKey="`${errorKey}_mail_content`" />

        <preview-email-modal
            v-if="hasMailPreview && showPreviewEmailModal"
            :saas="saas"
            :mailType="mailPreviewId"
            :locale="contentLocale"
            :custom-content="mailContentComputed"
            @close="showPreviewEmailModal = false" />

        <preview-sms-modal
            v-if="hasSmsPreview && showPreviewSmsModal && !doesSmsContainEmojis"
            :saas="saas"
            :smsType="smsPreviewId"
            :locale="contentLocale"
            :custom-content="smsContentComputed"
            @close="showPreviewSmsModal = false" />

        <sms-not-activated-alert-modal v-if="showSmsNotActivatedAlertModal" @close="onSmsNotActivatedAlertModalClosed" />
    </div>
</template>

<script>
import NotificationTypesEnum from "../../mixins/enums/NotificationTypesEnum.js";
import PreviewEmailModal from "../../components/Modals/previewEmailModal.vue";
import RadioButton from "../forms/RadioButtonComponent.vue";
import SmsNotActivatedAlertModal from "../../components/Modals/sms/NotActivatedAlertModal.vue";
import ShowErrors from "../../components/errors/ShowErrors.vue";
import editorWithMergeTags from "../forms/editorWithMergeTags.vue";
import SmsUtils from "../../mixins/helpers/SmsUtils.js";
import previewSmsModal from "../Modals/notifications/previewSmsModal.vue";

export default {
    data() {
        return {
            showPreviewEmailModal: false,
            showPreviewSmsModal: false,
            showSmsNotActivatedAlertModal: false,
            oldMode: undefined,
        };
    },
    mixins: [NotificationTypesEnum, SmsUtils],
    props: {
        label: {
            type: String,
            required: true,
        },
        mode: {
            type: String,
            required: true,
        },
        smsContent: {
            type: String,
            default: "",
        },
        hasSmsUnsavedChange: {
            type: Boolean,
            default: false,
        },
        mailContent: {
            type: String,
            default: "",
        },
        hasMailUnsavedChange: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: true,
        },
        isSmsContentEditable: {
            type: Boolean,
            default: true,
        },
        isMailContentEditable: {
            type: Boolean,
            default: true,
        },
        canBeDisabled: {
            type: Boolean,
            default: false,
        },
        mailPreviewId: {
            type: String,
        },
        smsPreviewId: {
            type: String,
        },
        contentLocale: {
            type: String,
            default: undefined,
        },
        isSmsModeAvailable: {
            type: Boolean,
            default: true,
        },
        formErrors: {
            default: null,
        },
        errorKey: {
            type: String,
        },
        saas: {
            type: String,
            default: undefined,
        },
        mailsMergeTags: {
            type: Array,
            default: null,
        },
        smsMergeTags: {
            type: Array,
            default: null,
        },
    },
    computed: {
        hasMailPreview() {
            return this.mailPreviewId !== undefined;
        },
        hasSmsPreview() {
            return this.smsPreviewId !== undefined;
        },
        modeComputed: {
            get() {
                return this.mode;
            },
            set(value) {
                if (value === this.NOTIFICATION_TYPE_SMS.value && !this.isSmsModeAvailable) {
                    this.showSmsNotActivatedAlertModal = true;
                    this.oldMode = this.mode;
                }

                this.$emit("set-mode", value);
            },
        },
        mailContentComputed: {
            get() {
                return this.mailContent;
            },
            set(value) {
                this.$emit("set-mail-content", value);
            },
        },
        smsContentComputed: {
            get() {
                return this.smsContent;
            },
            set(value) {
                this.$emit("set-sms-content", value);
            },
        },
        smsLength() {
            return this.getSmsLength(this.smsContentComputed, this.smsMergeTags);
        },
        nbCreditsNeeded() {
            return this.getNbCreditsNeeded(this.smsLength);
        },
        doesSmsContainEmojis() {
            return this.emojisRegex.test(this.smsContentComputed);
        },
    },
    methods: {
        onSmsNotActivatedAlertModalClosed() {
            this.modeComputed = this.oldMode;
            this.showSmsNotActivatedAlertModal = false;
        },
    },
    components: {
        PreviewEmailModal,
        RadioButton,
        SmsNotActivatedAlertModal,
        ShowErrors,
        editorWithMergeTags,
        previewSmsModal,
    },
};
</script>
