<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Détails du paiement</h5>
        <div slot="body">
            <show-click-and-collect-payment :showSaveButton="false" />
        </div>
        <div slot="footer" class="d-flex">
            <button
                type="button"
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                @click="$emit('close')">
                Fermer
            </button>
        </div>
    </modal>
</template>

<script>
import showClickAndCollectPayment from "../../clickAndCollect/showClickAndCollectPayment.vue";

export default {
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        showClickAndCollectPayment,
    },
};
</script>
