export default {
    data() {
        return {
            LEXICON_RESTAURANT: {
                value: "restaurant",
            },
            LEXICON_GENERIC: {
                value: "generic",
            },
            LEXICON_VETOWEB: {
                value: "vetoweb",
            },
            LEXICON_ESCAPE_GAME: {
                value: "escape_game",
            },
        };
    },
    methods: {
        getLexiconLabel(value) {
            const found = this.ALL_LEXICONS.find((lexicon) => lexicon.value === value);

            return typeof found !== "undefined" ? this.$t(`labels.lexicons.${value}`) : value;
        },
    },
    computed: {
        ALL_LEXICONS() {
            return [this.LEXICON_RESTAURANT, this.LEXICON_GENERIC, this.LEXICON_VETOWEB, this.LEXICON_ESCAPE_GAME];
        },
        restaurantLexicon() {
            return (
                this.$store.getters["restaurants/lexicon"](this.restaurantId || this.restaurant_id || this.$route.params.restaurant_id) ||
                this.LEXICON_RESTAURANT.value
            );
        },
    },
};
