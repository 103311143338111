<template>
    <div>
        <loader-component v-if="loadings.menu || loadings.form" />
        <div v-else-if="errors.fetchMenu !== null" class="text-danger">
            {{ errors.fetchMenu }}
        </div>
        <form v-show="!loadings.menu && !loadings.form && errors.fetchMenu === null" ref="form" method="post" @submit.prevent="submit">
            <div class="row">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <input-component
                            v-model="menu.name"
                            inputName="name"
                            required
                            :disabled="!isEditable"
                            :label="$tl('labels.form.name')"
                            :form-errors="errors.form" />

                        <switch-input-component
                            v-if="widget.enable_children"
                            v-model="menu.for_children"
                            inputName="for_children"
                            :disabled="!isEditable"
                            :label="$tl('labels.booking.menus.childrenOnly', restaurantId)"
                            :form-errors="errors.form" />

                        <input-component
                            v-model="menu.price"
                            inputName="price"
                            inputType="number"
                            required
                            step="0.01"
                            min="0"
                            :disabled="!isEditable"
                            :label="$t('labels.booking.menus.price', { currency: getRestaurantCurrency(restaurantId) })"
                            :form-errors="errors.form" />

                        <div class="row mb-2" v-if="!isTvaDisabled">
                            <div class="col-5 pt-2">
                                <label for="tax_rate">{{ $tl("labels.taxRate") }} <small>*</small></label>
                            </div>
                            <div class="col-7">
                                <div class="row">
                                    <div class="col-md-4">
                                        <select
                                            class="custom-select mb-0"
                                            placeholder="Veuillez sélectionner un taux"
                                            name="tax_rate"
                                            :disabled="!isEditable"
                                            v-model="menu.tax_rate"
                                            required>
                                            <option :value="null">--</option>
                                            <option :value="5.5">5,5</option>
                                            <option :value="10">10</option>
                                            <option :value="20">20</option>
                                            <option :value="8.1">8.1</option>
                                        </select>
                                    </div>
                                    <div class="col-md-8 mt-1">%</div>
                                    <show-errors class="col-12" :errors="errors.form" errorKey="tax_rate" />
                                </div>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-5">
                                <label for="description">{{ $tl("labels.form.description") }}</label>
                            </div>
                            <div class="col-7">
                                <textarea name="description" :disabled="!isEditable" v-model="menu.description" class="form-control"></textarea>
                                <show-errors class="d-block" :errors="errors.form" errorKey="description" />
                            </div>
                        </div>

                        <div class="row my-2">
                            <div class="col-5">
                                <label>
                                    {{ $tl("labels.booking.menus.externalDescription.externalDescription") }}
                                    <feather
                                        v-tooltip="getTooltipNoticeLexicon('notices.booking.menus.externalDescription', restaurantId)"
                                        type="info"
                                        class="ml-1 pointer tooltip-infos" />
                                    <show-new-badge-details class="ml-1" badge_uuid="fa229fe9-ff8c-4390-b288-843b65b8176d" />
                                </label>
                            </div>
                            <div class="col-7">
                                <div class="radio">
                                    <radio-button-component v-model="menu.external_description_type" :inputValue="EXTERNAL_DESCRIPTION_FILE.value">
                                        {{ $tl("labels.booking.menus.externalDescription.file.pdf") }}
                                    </radio-button-component>
                                    <radio-button-component v-model="menu.external_description_type" :inputValue="EXTERNAL_DESCRIPTION_LINK.value">
                                        {{ $tl("labels.booking.menus.externalDescription.link.link") }}
                                    </radio-button-component>
                                    <radio-button-component v-model="menu.external_description_type" :inputValue="null">
                                        {{ $tl("labels.booking.menus.externalDescription.disabled") }}
                                    </radio-button-component>
                                </div>
                                <show-errors class="d-block" :errors="errors.form" errorKey="external_description_type" />
                                <div v-if="menu.external_description_type === EXTERNAL_DESCRIPTION_FILE.value" class="my-2">
                                    <file-uploader-component
                                        :props-file="file"
                                        :errors="errors.form"
                                        :hasDirectUpload="false"
                                        suffixId="ext-desc"
                                        @upload-file="file = $event"
                                        :src="pathFile"
                                        :deleteURI="deleteURIFile"
                                        :max-size="20"
                                        :call-back-delete="
                                            () => {
                                                pathFile = '';
                                                file = null;
                                                menu.external_description.file = '';
                                            }
                                        " />
                                    <show-errors class="d-block" :errors="errors.form" errorKey="external_description.file" />
                                </div>
                                <div v-else-if="menu.external_description_type === EXTERNAL_DESCRIPTION_LINK.value" class="my-2">
                                    <input-component
                                        v-model="menu.external_description.link"
                                        label=""
                                        inputName="external_description.link"
                                        :form-errors="errors.form"
                                        :placeholder="$tl('labels.booking.menus.externalDescription.link.placeholder')"
                                        labelWrapperClasses="d-none"
                                        type="url" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-5">{{ $tl("labels.form.picture") }}</div>
                            <div class="col-7">
                                <image-uploader
                                    :uploaded-img-url="img.uploadedImgUrl"
                                    :uploaded-img-path="img.uploadedImgPath"
                                    :delete-url="deleteImageURL"
                                    :maxSize="2"
                                    :disabled="!isEditable"
                                    @image-selected="img.file = $event"
                                    @image-deleted="onImageDeleted" />
                                <show-errors class="d-block" :errors="errors.form" errorKey="img_file" />
                            </div>
                        </div>

                        <loader-component v-if="loadings.menuOptions" />
                        <div v-else class="row mb-2 mt-4">
                            <div class="col-5">
                                <label>{{ $tl("labels.booking.menus.options.link") }}</label>
                            </div>
                            <div class="col-7">
                                <div v-if="errors.fetchMenuOptions !== null" class="text-danger">
                                    {{ errors.fetchMenuOptions }}
                                </div>
                                <div v-else-if="availableMenuOptions.length > 0">
                                    <div class="mb-2" v-for="option in availableMenuOptions" :key="option.id">
                                        <label class="container-box" style="margin-bottom: 0; width: 100%">
                                            <input
                                                type="checkbox"
                                                name="menu_options[]"
                                                v-model="menu.menu_options"
                                                :disabled="!isEditable"
                                                :value="option.id" />
                                            <span class="checkmark"></span> {{ option.name }}
                                        </label>
                                    </div>
                                </div>
                                <show-errors class="d-block" :errors="errors.form" errorKey="menu_options" />
                                <show-errors class="d-block" :errors="errors.form" errorKey="menu_options.*" />
                                <div class="mt-4">
                                    <button
                                        type="button"
                                        class="btn btn-success btn-sm btn-circle"
                                        :disabled="!has_right_to_create_menu"
                                        @click="showAddMenuOptionModal = true">
                                        {{ $tl("labels.booking.menus.options.add") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3" v-if="showSaveButton">
                        <div class="col-12">
                            <input
                                type="submit"
                                name="submit"
                                :disabled="!isEditable"
                                :value="$tl('labels.form.actions.save')"
                                class="btn btn-success btn-circle" />
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <add-menu-option-modal
            v-if="showAddMenuOptionModal"
            :option-type="MENU_OPTION_MENU.value"
            :restaurant-id="restaurantId"
            @close="showAddMenuOptionModal = false"
            @menu-option-added="fetchMenuOptions" />
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import InputComponent from "../forms/InputComponent.vue";
import SwitchInputComponent from "../forms/SwitchInputComponent.vue";
import RadioButtonComponent from "../forms/RadioButtonComponent.vue";
import FileUploaderComponent from "../forms/FileUploaderComponent.vue";
import ShowErrors from "../errors/ShowErrors.vue";
import AddMenuOptionModal from "../Modals/menuOptions/addMenuOptionModal.vue";
import ExternalDescriptionTypeEnum from "../../mixins/enums/booking/ExternalDescriptionTypeEnum.js";
import MenuOptionTypeEnum from "../../mixins/enums/booking/MenuOptionTypeEnum.js";
import showNewBadgeDetails from "../NewBadges/showNewBadgeDetails.vue";
import ImageUploader from "../forms/uploaders/Image.vue";

export default {
    data() {
        return {
            loadings: {
                menu: false,
                menuOptions: false,
                form: false,
            },
            errors: {
                fetchMenu: null,
                fetchMenuOptions: null,
                form: null,
            },
            menu: {
                id: null,
                name: "",
                for_children: false,
                price: null,
                tax_rate: null,
                description: "",
                menu_options: [],
                external_description_type: null,
                external_description: {
                    file: "",
                    link: "",
                },
            },
            file: null,
            pathFile: "",
            img: {
                uploadedImgUrl: null,
                uploadedImgPath: null,
                file: null,
            },
            availableMenuOptions: [],
            showAddMenuOptionModal: false,
        };
    },
    mixins: [ExternalDescriptionTypeEnum, MenuOptionTypeEnum],
    props: {
        restaurantId: {
            type: Number,
            required: true,
        },
        menuId: {
            type: Number,
            default: null,
        },
        showSaveButton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        widget() {
            return this.$store.getters["widgets/getWidget"];
        },
        isTvaDisabled() {
            return this.$store.getters["restaurants/isTvaDisabled"](this.restaurantId);
        },
        isEditable() {
            return this.menuId === null ? this.has_right_to_create_menu : this.has_right_to_update_menu;
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_menu() {
            return this.rights.includes("booking.menu.create");
        },
        has_right_to_update_menu() {
            return this.rights.includes("booking.menu.update");
        },
        deleteURIFile() {
            return this.menuId !== null ? `/api/restaurants/${this.restaurantId}/menus/${this.menuId}/file` : null;
        },
        deleteImageURL() {
            return this.menuId !== null ? `/api/restaurants/${this.restaurantId}/menus/${this.menuId}/img` : null;
        },
    },
    methods: {
        fetchMenu() {
            this.errors.fetchMenu = null;

            if (this.menuId === null) {
                this.$emit("fully-loaded", { menu: this.menu });
                return;
            }

            this.loadings.menu = true;

            this.httpGet(`/api/restaurants/${this.restaurantId}/menus/${this.menuId}?include=menu_options`, { mustReject: true })
                .then((response) => {
                    this.menu = response.data;

                    this.menu.menu_options = response.data.menu_options.data.map((option) => option.id);

                    if (this.menu.external_description === null) {
                        this.menu.external_description = { file: "", link: "" };
                    }

                    this.pathFile = this.menu.path_file ?? "";

                    if (response.data.img !== null) {
                        this.img.uploadedImgPath = response.data.img.path;
                        this.img.uploadedImgUrl = response.data.img.public_uri;
                    }

                    this.$emit("fully-loaded", { menu: this.menu });
                })
                .catch((error) => (this.errors.fetchMenu = this.getErrorMsgFromErrorResponse(error)))
                .finally(() => (this.loadings.menu = false));
        },
        fetchMenuOptions() {
            this.loadings.menuOptions = true;
            this.errors.fetchMenuOptions = null;

            this.httpGet(`/api/restaurants/${this.restaurantId}/menus/options`, { mustReject: true })
                .then((response) => {
                    this.availableMenuOptions = response.data.data;
                })
                .catch((error) => (this.errors.fetchMenuOptions = this.getErrorMsgFromErrorResponse(error)))
                .finally(() => (this.loadings.menuOptions = false));
        },
        submit() {
            if (!this.isEditable) {
                return null;
            }

            this.loadings.form = true;

            const formData = this.getFormData();

            let promise = null;
            if (this.menuId !== null) {
                promise = this.httpPut(`/api/restaurants/${this.restaurantId}/menus/${this.menuId}`, formData);
            } else {
                promise = this.httpPost(`/api/restaurants/${this.restaurantId}/menus`, formData);
            }

            promise.finally(() => {
                this.loadings.form = false;
            });

            this.$emit("submit", { promise });
            return promise;
        },
        getFormData() {
            const formData = new FormData();

            const obj = Object.assign({}, this.menu);

            if (!this.isValidExternalDescription()) {
                obj.external_description_type = null;
            }

            if (this.isValidFile(this.file) && obj.external_description_type === this.EXTERNAL_DESCRIPTION_FILE.value) {
                formData.append("file", this.file);
            }

            if (this.img.file !== null) {
                formData.append("img_file", this.img.file);
            }

            formData.append("payload", JSON.stringify(obj)); // necessary to keep type of datas and pass laravel's validation

            return formData;
        },
        isValidExternalDescription() {
            if (!this.ALL_EXTERNAL_DESCRIPTION_TYPES.includes(this.menu.external_description_type)) {
                return false;
            }

            if (this.menu.external_description_type === this.EXTERNAL_DESCRIPTION_LINK.value && this.menu.external_description.link) {
                return true;
            }

            if (
                this.menu.external_description_type === this.EXTERNAL_DESCRIPTION_FILE.value &&
                (this.isValidFile(this.file) || this.menu.external_description.file)
            ) {
                return true;
            }

            return false;
        },
        onImageDeleted() {
            this.img.uploadedImgPath = null;
            this.img.uploadedImgUrl = null;
        },
    },
    created() {
        this.fetchMenuOptions();

        this.fetchMenu();
    },
    watch: {
        isEditable: {
            immediate: true,
            handler(newVal) {
                this.$emit("is-editable", newVal);
            },
        },
        restaurantId() {
            this.fetchMenu();
        },
        menuId() {
            this.fetchMenu();
        },
    },
    components: {
        ImageUploader,
        LoaderComponent,
        InputComponent,
        SwitchInputComponent,
        RadioButtonComponent,
        FileUploaderComponent,
        ShowErrors,
        AddMenuOptionModal,
        showNewBadgeDetails,
    },
};
</script>
