<template>
    <div class="row">
        <div class="col-12">
            <div class="border-light b-radius-20 p-4">
                <div class="row">
                    <div class="col-12 pointer" @click="showIntegration = !showIntegration">
                        <strong>{{ $tl("infos.widget.facebookIntegration.title") }}</strong
                        ><showNewBadgeDetails v-if="badgeUUID !== null" :badge_uuid="badgeUUID" />
                    </div>
                </div>
                <div v-if="showIntegration" class="row mt-3">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-5">
                                <p>{{ $tl("infos.widget.subtitle") }}</p>
                            </div>
                            <div class="col-7">
                                <strong>{{ $t("labels.stepX", { step: 1 }) }}{{ $tl("labels.colon") }}</strong>
                                {{ $t("infos.widget.facebookIntegration.step1") }}
                                <p class="mt-1 mb-3">{{ $t("infos.widget.facebookIntegration.content1") }}</p>
                                <strong>{{ $t("labels.stepX", { step: 2 }) }}{{ $tl("labels.colon") }}</strong>
                                {{ $t("infos.widget.facebookIntegration.step2") }}
                                <p class="mt-1">
                                    {{ $tl("infos.widget.facebookIntegration.menu") }} <feather type="chevron-right" />
                                    {{ $tl("infos.widget.facebookIntegration.page") }} <feather type="chevron-right" />
                                    {{ $tl("infos.widget.facebookIntegration.relatedPage") }}
                                    <feather type="chevron-right" />
                                    {{ $tl("infos.widget.facebookIntegration.threePoints") }}
                                    <feather type="chevron-right" />
                                    {{ $tl("infos.widget.facebookIntegration.addButton") }}
                                    <feather type="chevron-right" /> {{ $tl("infos.widget.facebookIntegration.optionChoice") }}
                                    <feather type="chevron-right" />
                                    {{ $tl("infos.widget.facebookIntegration.pasteLink") }}
                                </p>
                                <p>{{ $t("infos.widget.facebookIntegration.content2") }}</p>
                                <a :href="facebookLinkGif" target="_blank">
                                    <img class="w-100 mb-3" :src="facebookLinkGif" alt="Gif représentatif des étapes d'ajout du bouton." />
                                </a>
                                <p>{{ $t("infos.widget.facebookIntegration.content3") }}</p>
                                <p>{{ $t("infos.widget.facebookIntegration.content4") }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";
import showNewBadgeDetails from "../../components/NewBadges/showNewBadgeDetails.vue";

export default {
    data() {
        return {
            showIntegration: false,
        };
    },
    props: {
        module: {
            type: String,
            default: null,
        },
    },
    mixins: [ModuleTypesEnum],
    computed: {
        badgeUUID() {
            if (this.module === this.MODULE_TYPE_BOOKING.value) {
                return "4c323dcb-8357-4c2b-9ee3-492078b870b0";
            }

            if (this.module === this.MODULE_TYPE_CLICK_AND_COLLECT.value) {
                return "2d9c9b38-a42f-420f-9bbf-14c91f72ec60";
            }

            if (this.module === this.MODULE_TYPE_GIFT_VOUCHERS.value) {
                return "43978ce2-25f1-4cda-9012-f81b923f32a7";
            }

            return null;
        },
        facebookLinkGif() {
            if (this.isYservices) {
                return "https://storage.googleapis.com/yservices-dev-data/yservices/Bouton-facebook.gif";
            }

            return "https://storage.googleapis.com/noshow-data/noshow/Bouton-facebook.gif";
        },
    },
    components: {
        showNewBadgeDetails,
    },
};
</script>
