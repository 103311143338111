<template>
    <div>
        <div v-if="hasErrors('choosen_menus')">
            <div class="invalid-feedback d-block" v-for="err in formErrors.errors.choosen_menus">
                {{ err }}
            </div>
        </div>
        <div v-if="are_menus_unique" class="mb-3 text-danger">
            {{ $tl("infos.booking.reservations.menus.unique", restaurantId) }}
        </div>
        <div class="one-menu mb-3" v-for="(m, mIndex) in available_menu">
            <div class="row text-left mb-0">
                <div class="col-sm-8">
                    <label class="mb-1">{{ m.name }} - {{ formatCurrency(m.price, getRestaurantCurrency(restaurantId)) }}</label>
                    <small class="d-block text-muted mb-2">
                        {{ m.description }}
                    </small>
                </div>
                <div class="col-sm-4">
                    <select
                        style="height: 44px"
                        class="custom-select"
                        :disabled="readonly"
                        :ref="`ref_menu_${m.id}`"
                        :class="{ 'is-invalid': hasErrors('choosen_menus') }"
                        @change="chooseMenu(m, $event)">
                        <option :value="0">{{ 0 }}</option>
                        <option v-if="!are_menus_unique" v-for="(n, index) in menusLeftToChoose(m.id)" :value="index + 1">
                            {{ index + 1 }}
                        </option>
                        <option v-if="are_menus_unique && menusLeftToChoose(m.id) > 0" :value="menusLeftToChoose(m.id)">
                            {{ menusLeftToChoose(m.id) }}
                        </option>
                    </select>
                </div>
            </div>
            <div
                v-if="choosen_menu[`id_${m.id}`] !== undefined && choosen_menu[`id_${m.id}`].value > 0 && m.menu_options.data.length > 0"
                class="p-3 border-right-light border-bottom-light border-left-light">
                <div class="row text-left mb-0" v-for="option in m.menu_options.data">
                    <div class="col-8">
                        <label class="mb-0">
                            {{ option.name }} - {{ formatCurrency(option.price, getRestaurantCurrency(restaurantId)) }}
                            <small v-if="option.quantity_type === 'mandatory_for_each'" class="text-muted"> ({{ $tl("labels.perPerson") }}) </small>
                        </label>
                        <small class="d-block text-muted">
                            {{ option.description }}
                        </small>
                    </div>
                    <div class="col-4">
                        <div v-if="option.quantity_type === 'mandatory_for_each'">
                            <select
                                :disabled="readonly"
                                :ref="`ref_option_${m.id}${option.id}`"
                                @change="chooseMenuOption(m, option, $event)"
                                class="custom-select">
                                <option value="0">--</option>
                                <option :value="choosen_menu[`id_${m.id}`].value">
                                    {{ choosen_menu[`id_${m.id}`].value }}
                                </option>
                            </select>
                        </div>

                        <select
                            v-else-if="option.quantity_type === 'limited_by_pers'"
                            :disabled="readonly"
                            class="custom-select"
                            @change="chooseMenuOption(m, option, $event)"
                            :ref="`ref_option_${m.id}${option.id}`">
                            <option value="0">0</option>
                            <option v-for="(menu, index) in choosen_menu[`id_${m.id}`].value * 1" :value="index * 1 + 1">
                                {{ index * 1 + 1 }}
                            </option>
                        </select>

                        <input
                            class="form-control mb-2"
                            type="number"
                            :disabled="readonly"
                            step="1"
                            min="0"
                            value="0"
                            :ref="`ref_option_${m.id}${option.id}`"
                            @change="chooseMenuOption(m, option, $event)"
                            v-else-if="option.quantity_type === 'unlimited'" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";

export default {
    name: "menusReservation",
    data() {
        return this.$_.cloneDeep(this.$_.defaultsDeep(this.defaultValues, {}));
    },
    props: {
        available_menus: {
            default: null,
        },
        available_menus_trashed: {
            default: null,
        },
        are_menus_unique: {
            default: false,
        },
        choosen_menus: {
            default: null,
        },
        nb_total: {
            default: null,
        },
        formErrors: {
            default: null,
        },
        restaurantId: {
            type: Number,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        deleted_menus() {
            return Object.values(this.choosen_menu).filter((cm) => !this.available_menus.some((am) => am.id === cm.id));
        },
        available_menu: {
            get: function () {
                let available_menus = this.available_menus || [];
                let available_menus_trashed = this.available_menus_trashed || [];
                available_menus_trashed = available_menus_trashed.filter((menu) => {
                    return this.deleted_menus.some((m) => m.id === menu.id);
                });
                return available_menus.concat(available_menus_trashed);
            },
        },
        choosen_menu: {
            get: function () {
                return this.choosen_menus;
            },
            set: function (value) {
                this.$emit("update-menus", value);
            },
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            this.initInputs();
                        });
                    });
                });
            });
        });
    },
    methods: {
        initInputs() {
            if (this.choosen_menu) {
                for (var menuKey of Object.keys(this.choosen_menu)) {
                    var menu = this.choosen_menu[menuKey];
                    if (this.$refs[`ref_menu_${menu.id}`] && this.$refs[`ref_menu_${menu.id}`].length > 0) {
                        this.$refs[`ref_menu_${menu.id}`][0].value = menu.value.toString();
                        if (menu.options) {
                            for (var optionKey of Object.keys(menu.options)) {
                                var option = menu.options[optionKey];
                                if (this.$refs[`ref_option_${menu.id}${option.id}`] && this.$refs[`ref_option_${menu.id}${option.id}`].length > 0) {
                                    this.$refs[`ref_option_${menu.id}${option.id}`][0].value = option.value.toString();
                                }
                            }
                        }
                    }
                }
            }
            this.$forceUpdate();
        },
        menusLeftToChoose(menuIdToAvoid) {
            var nb_pers = this.nb_total;

            for (const menuKey in this.choosen_menu) {
                const menu = this.choosen_menu[menuKey];

                if (menu.id !== menuIdToAvoid) {
                    nb_pers -= Number.parseInt(menu.value);
                }
            }

            return nb_pers;
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
        chooseMenu(menu, event) {
            let nbm = event.target.value;
            let max_menus = this.nb_total;
            var menu_id = menu.id;

            if (nbm > max_menus) {
                event.target.value = max_menus;
                nbm = max_menus;
            }

            if (nbm > 0) {
                let newVal = undefined;
                this.choosen_menu[`id_${menu_id}`] !== undefined
                    ? ((newVal = this.choosen_menu[`id_${menu_id}`]), (newVal.value = nbm))
                    : (newVal = { id: menu_id, value: nbm, price: menu.price, options: {} });
                this.$set(this.choosen_menu, `id_${menu_id}`, newVal);
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        if (Object.keys(this.choosen_menu[`id_${menu_id}`].options).length > 0) {
                            for (const index in this.choosen_menu[`id_${menu_id}`].options) {
                                const option = this.choosen_menu[`id_${menu_id}`].options[index];
                                const value = isNaN(Number.parseInt(option.value)) ? 0 : Number.parseInt(option.value);
                                if (["mandatory_for_each", "limited_by_pers"].indexOf(option.quantity_type) > -1) {
                                    if (option.quantity_type === "mandatory_for_each") {
                                        this.$set(this.choosen_menu[`id_${menu_id}`].options[index], "value", nbm);
                                        this.setOptionInput(option, this.choosen_menu[`id_${menu_id}`], nbm);
                                    } else if (value > nbm) {
                                        this.$set(this.choosen_menu[`id_${menu_id}`].options[index], "value", nbm);
                                        this.setOptionInput(option, this.choosen_menu[`id_${menu_id}`], nbm);
                                    }
                                }
                            }
                        }
                    });
                });
            } else {
                this.$set(this, "choosen_menu", _.omit(this.choosen_menu, `id_${menu_id}`));
            }
        },
        chooseMenuOption(menu, option, event) {
            let value = Number.parseInt(event.target.value);

            if (isNaN(value) || value < 0) {
                event.target.value = 0;
                value = 0;
            }

            if (value && value > 0) {
                this.$set(this.choosen_menu[`id_${menu.id}`].options, `id_${option.id}`, {
                    id: option.id,
                    value: value,
                    quantity_type: "mandatory_for_each",
                });
            } else {
                const optionsArray = this.choosen_menu[`id_${menu.id}`].options;
                this.$set(this.choosen_menu[`id_${menu.id}`], "options", _.omit(optionsArray, `id_${option.id}`));
            }
        },
    },
};
</script>
