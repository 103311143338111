var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("strong", [
              _vm._v(
                _vm._s(
                  _vm.$tl("labels.booking.services.footprintAndPayment.title")
                )
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        !_vm.hasPaymentSystem
          ? _c("div", { staticClass: "pt-2" }, [
              _c("span", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.$tl("infos.common.needStripeAccount"))),
              ]),
            ])
          : _c(
              "div",
              { staticClass: "menu-option mt-3" },
              [
                _c("overlay-layout", {
                  attrs: { "display-overlay": !_vm.hasPaymentSystem },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "row border-bottom mb-2" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-5" }, [
                                    _c(
                                      "label",
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.booking.services.footprint.enable"
                                              )
                                            ) +
                                            "\n                                            "
                                        ),
                                        _c("feather", {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip.right",
                                              value:
                                                _vm.getTooltipNoticeLexicon(
                                                  "notices.booking.servicesConfig.bankFootprint",
                                                  _vm.restaurant_id,
                                                  ["click"],
                                                  true,
                                                  "tooltip-custom-width"
                                                ),
                                              expression:
                                                "\n                                                    getTooltipNoticeLexicon(\n                                                        'notices.booking.servicesConfig.bankFootprint',\n                                                        restaurant_id,\n                                                        ['click'],\n                                                        true,\n                                                        'tooltip-custom-width'\n                                                    )\n                                                ",
                                              modifiers: { right: true },
                                            },
                                          ],
                                          staticClass:
                                            "ml-1 pointer tooltip-infos",
                                          attrs: { type: "info" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-7" }, [
                                    _c(
                                      "label",
                                      { staticClass: "container-box" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.service.payment.bankImprint
                                                  .enabled,
                                              expression:
                                                "service.payment.bankImprint.enabled",
                                            },
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            value: true,
                                            checked: Array.isArray(
                                              _vm.service.payment.bankImprint
                                                .enabled
                                            )
                                              ? _vm._i(
                                                  _vm.service.payment
                                                    .bankImprint.enabled,
                                                  true
                                                ) > -1
                                              : _vm.service.payment.bankImprint
                                                  .enabled,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.service.payment
                                                    .bankImprint.enabled,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = true,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.service.payment
                                                        .bankImprint,
                                                      "enabled",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.service.payment
                                                        .bankImprint,
                                                      "enabled",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.service.payment
                                                    .bankImprint,
                                                  "enabled",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.service.payment.bankImprint.enabled
                                  ? [
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-5 pt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.capitalize(
                                                    _vm.$tl("labels.from")
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-7" }, [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-5" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.service.payment
                                                          .bankImprint
                                                          .enabledAbovePax,
                                                      expression:
                                                        "service.payment.bankImprint.enabledAbovePax",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  class: {
                                                    disabled:
                                                      !_vm.service.payment
                                                        .bankImprint.enabled,
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    disabled:
                                                      !_vm.service.payment
                                                        .bankImprint.enabled,
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.service.payment
                                                        .bankImprint
                                                        .enabledAbovePax,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.service.payment
                                                          .bankImprint,
                                                        "enabledAbovePax",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-7 mt-2" },
                                              [
                                                _vm._v(
                                                  "\n                                                    " +
                                                    _vm._s(
                                                      _vm.$tl("labels.persons")
                                                    ) +
                                                    "\n                                                "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c("div", { staticClass: "col-5" }, [
                                          _c("label", [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.booking.services.footprint.amountToCapture",
                                                    _vm.restaurant_id
                                                  )
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-7" }, [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-5" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.service.payment
                                                          .bankImprint.amount,
                                                      expression:
                                                        "service.payment.bankImprint.amount",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  class: {
                                                    disabled:
                                                      !_vm.service.payment
                                                        .bankImprint.enabled,
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    disabled:
                                                      !_vm.service.payment
                                                        .bankImprint.enabled,
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.service.payment
                                                        .bankImprint.amount,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.service.payment
                                                          .bankImprint,
                                                        "amount",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-7 mt-2" },
                                              [
                                                _vm._v(
                                                  "\n                                                    " +
                                                    _vm._s(
                                                      _vm.$tl("labels.euros")
                                                    ) +
                                                    "\n                                                "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            (_vm.service.menus.value &&
                              !_vm.$_.isEmpty(_vm.service.menus.value)) ||
                            (_vm.service.generalOptions.value &&
                              !_vm.$_.isEmpty(_vm.service.generalOptions.value))
                              ? _c("div", { staticClass: "sepa" })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-12 mb-2 mt-2" },
                              [
                                (_vm.service.menus.value &&
                                  !_vm.$_.isEmpty(_vm.service.menus.value)) ||
                                (_vm.service.generalOptions.value &&
                                  !_vm.$_.isEmpty(
                                    _vm.service.generalOptions.value
                                  ))
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "row mb-2" },
                                        [
                                          _c("div", { staticClass: "col-5" }, [
                                            _c(
                                              "label",
                                              [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.booking.services.payment.enable"
                                                      )
                                                    ) +
                                                    "\n                                                "
                                                ),
                                                _c("feather", {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.getTooltipNoticeLexicon(
                                                          "notices.booking.servicesConfig.prepayment",
                                                          _vm.restaurant.id
                                                        ),
                                                      expression:
                                                        "\n                                                        getTooltipNoticeLexicon('notices.booking.servicesConfig.prepayment', restaurant.id)\n                                                    ",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "ml-1 pointer tooltip-infos",
                                                  attrs: { type: "info" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-7" }, [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "container-box",
                                                staticStyle: { width: "100%" },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.service.payment
                                                          .prepayment.enabled,
                                                      expression:
                                                        "service.payment.prepayment.enabled",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    name: "prepayment_enabled",
                                                  },
                                                  domProps: {
                                                    value: true,
                                                    checked: Array.isArray(
                                                      _vm.service.payment
                                                        .prepayment.enabled
                                                    )
                                                      ? _vm._i(
                                                          _vm.service.payment
                                                            .prepayment.enabled,
                                                          true
                                                        ) > -1
                                                      : _vm.service.payment
                                                          .prepayment.enabled,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.service.payment
                                                            .prepayment.enabled,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = true,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.service
                                                                .payment
                                                                .prepayment,
                                                              "enabled",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.service
                                                                .payment
                                                                .prepayment,
                                                              "enabled",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.service.payment
                                                            .prepayment,
                                                          "enabled",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _vm.service.payment.prepayment.enabled
                                            ? [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-5 pt-2" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.services.payment.is"
                                                          )
                                                        ) + " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-6 d-table w-100 h-100 pt-2",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-table-cell align-middle",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "slot_btn_select radio",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "container-box",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .service
                                                                          .payment
                                                                          .prepayment
                                                                          .mandatory,
                                                                      expression:
                                                                        "service.payment.prepayment.mandatory",
                                                                    },
                                                                  ],
                                                                  class: {
                                                                    disabled:
                                                                      !_vm
                                                                        .service
                                                                        .payment
                                                                        .prepayment
                                                                        .enabled,
                                                                  },
                                                                  attrs: {
                                                                    disabled:
                                                                      !_vm
                                                                        .service
                                                                        .payment
                                                                        .prepayment
                                                                        .enabled,
                                                                    type: "radio",
                                                                  },
                                                                  domProps: {
                                                                    value: true,
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .service
                                                                          .payment
                                                                          .prepayment
                                                                          .mandatory,
                                                                        true
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm
                                                                            .service
                                                                            .payment
                                                                            .prepayment,
                                                                          "mandatory",
                                                                          true
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("span", {
                                                                  staticClass:
                                                                    "checkmark",
                                                                }),
                                                                _vm._v(
                                                                  "\n                                                            " +
                                                                    _vm._s(
                                                                      _vm.$tl(
                                                                        "labels.mandatory"
                                                                      )
                                                                    ) +
                                                                    "\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "container-box",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .service
                                                                          .payment
                                                                          .prepayment
                                                                          .mandatory,
                                                                      expression:
                                                                        "service.payment.prepayment.mandatory",
                                                                    },
                                                                  ],
                                                                  class: {
                                                                    disabled:
                                                                      !_vm
                                                                        .service
                                                                        .payment
                                                                        .prepayment
                                                                        .enabled,
                                                                  },
                                                                  attrs: {
                                                                    disabled:
                                                                      !_vm
                                                                        .service
                                                                        .payment
                                                                        .prepayment
                                                                        .enabled,
                                                                    type: "radio",
                                                                  },
                                                                  domProps: {
                                                                    value: false,
                                                                    checked:
                                                                      _vm._q(
                                                                        _vm
                                                                          .service
                                                                          .payment
                                                                          .prepayment
                                                                          .mandatory,
                                                                        false
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          _vm
                                                                            .service
                                                                            .payment
                                                                            .prepayment,
                                                                          "mandatory",
                                                                          false
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("span", {
                                                                  staticClass:
                                                                    "checkmark",
                                                                }),
                                                                _vm._v(
                                                                  "\n                                                            " +
                                                                    _vm._s(
                                                                      _vm.$tl(
                                                                        "labels.optional"
                                                                      )
                                                                    ) +
                                                                    "\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _vm.service.payment.prepayment.enabled
                                        ? [
                                            _vm.service.payment.prepayment
                                              .mandatory !== null
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row mb-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-5" },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$tl(
                                                                "labels.booking.services.payment.amountToCapture"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-7" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-5",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .service
                                                                          .payment
                                                                          .prepayment
                                                                          .percentage,
                                                                      expression:
                                                                        "service.payment.prepayment.percentage",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  class: {
                                                                    disabled:
                                                                      !_vm
                                                                        .service
                                                                        .payment
                                                                        .prepayment
                                                                        .enabled,
                                                                  },
                                                                  attrs: {
                                                                    type: "number",
                                                                    name: "prepayment_percent",
                                                                    disabled:
                                                                      !_vm
                                                                        .service
                                                                        .payment
                                                                        .prepayment
                                                                        .enabled,
                                                                    min: "1",
                                                                    step: "1",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm
                                                                        .service
                                                                        .payment
                                                                        .prepayment
                                                                        .percentage,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          _vm
                                                                            .service
                                                                            .payment
                                                                            .prepayment,
                                                                          "percentage",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-7 mt-2",
                                                              },
                                                              [_vm._v("%")]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.service.payment.prepayment
                                              .mandatory
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row mb-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-5 pt-2",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.capitalize(
                                                                _vm.$tl(
                                                                  "labels.from"
                                                                )
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-7" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-5",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .service
                                                                          .payment
                                                                          .prepayment
                                                                          .enabledAbovePax,
                                                                      expression:
                                                                        "service.payment.prepayment.enabledAbovePax",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  class: {
                                                                    disabled:
                                                                      !_vm
                                                                        .service
                                                                        .payment
                                                                        .prepayment
                                                                        .enabled ||
                                                                      !_vm
                                                                        .service
                                                                        .payment
                                                                        .prepayment
                                                                        .mandatory,
                                                                  },
                                                                  attrs: {
                                                                    type: "text",
                                                                    disabled:
                                                                      !_vm
                                                                        .service
                                                                        .payment
                                                                        .prepayment
                                                                        .enabled ||
                                                                      !_vm
                                                                        .service
                                                                        .payment
                                                                        .prepayment
                                                                        .mandatory,
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm
                                                                        .service
                                                                        .payment
                                                                        .prepayment
                                                                        .enabledAbovePax,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          _vm
                                                                            .service
                                                                            .payment
                                                                            .prepayment,
                                                                          "enabledAbovePax",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-7 mt-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                        " +
                                                                    _vm._s(
                                                                      _vm.$tl(
                                                                        "labels.personss"
                                                                      )
                                                                    ) +
                                                                    "\n                                                    "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mt-3 radio" }, [
                            _c("div", { staticClass: "col-12 row" }, [
                              _c("div", { staticClass: "col-6 pt-2" }, [
                                _c(
                                  "label",
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.services.footprint.autoCancelation",
                                            _vm.restaurant_id,
                                            {
                                              shortDelay:
                                                _vm.optionBankShortDelay,
                                            }
                                          )
                                        ) +
                                        "\n                                        "
                                    ),
                                    _c("feather", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltipNotice(
                                            "notices.booking.servicesConfig.bookingCancellationWithoutFootprint"
                                          ),
                                          expression:
                                            "getTooltipNotice('notices.booking.servicesConfig.bookingCancellationWithoutFootprint')",
                                        },
                                      ],
                                      staticClass: "ml-1 pointer tooltip-infos",
                                      attrs: { type: "info" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "col-6 d-table w-100 h-100 pt-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-table-cell align-middle",
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "container-box" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.service.payment
                                                    .autoCancel,
                                                expression:
                                                  "service.payment.autoCancel",
                                              },
                                            ],
                                            attrs: {
                                              type: "radio",
                                              name: "paymentAutoCancel",
                                            },
                                            domProps: {
                                              value: false,
                                              checked: _vm._q(
                                                _vm.service.payment.autoCancel,
                                                false
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$set(
                                                  _vm.service.payment,
                                                  "autoCancel",
                                                  false
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$tl("labels.manual")) +
                                              "\n                                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { staticClass: "container-box" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.service.payment
                                                    .autoCancel,
                                                expression:
                                                  "service.payment.autoCancel",
                                              },
                                            ],
                                            attrs: {
                                              type: "radio",
                                              name: "paymentAutoCancel",
                                            },
                                            domProps: {
                                              value: true,
                                              checked: _vm._q(
                                                _vm.service.payment.autoCancel,
                                                true
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$set(
                                                  _vm.service.payment,
                                                  "autoCancel",
                                                  true
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$tl("labels.auto")) +
                                              "\n                                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("send-reminder-payment", {
                            attrs: {
                              "cancel-auto": _vm.service.payment.autoCancel,
                            },
                            model: {
                              value: _vm.service.sendReminderPayment,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.service,
                                  "sendReminderPayment",
                                  $$v
                                )
                              },
                              expression: "service.sendReminderPayment",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "overlay",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "is-white text-center d-table" },
                            [
                              _c(
                                "h6",
                                { staticClass: "d-table-cell align-middle" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl("infos.common.needStripeAccount")
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }