var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.evolutionClass },
    [
      _c("feather", { attrs: { type: _vm.featherIcon } }),
      _vm._v(" "),
      _vm.difference !== null
        ? _c("span", [
            _vm._v("\n        " + _vm._s(_vm.difference)),
            _c("span", { staticClass: "ml-2" }, [
              _vm._v("(" + _vm._s(Math.abs(_vm.percent).toFixed(0)) + "%)"),
            ]),
          ])
        : _c("span", [
            _vm._v(" " + _vm._s(Math.abs(_vm.percent).toFixed(0)) + "% "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }