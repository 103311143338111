var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.computeClasses("wrapper") }, [
    !_vm.removeLabelPart
      ? _c(
          "div",
          { class: _vm.computeClasses("labelWrapper") },
          [
            _c(
              "label",
              [
                _vm.label !== null
                  ? [_vm._v(_vm._s(_vm.label))]
                  : _vm._t("label"),
              ],
              2
            ),
            _vm._v(" "),
            _vm.notice
              ? _c("feather", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.getTooltipNotice(_vm.notice),
                      expression: "getTooltipNotice(notice)",
                    },
                  ],
                  staticClass: "ml-1 pointer tooltip-infos",
                  attrs: { type: "info" },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.computeClasses("inputWrapper") },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value_,
              expression: "value_",
            },
          ],
          ref: "input",
          class: _vm.computeClasses("input"),
          attrs: {
            name: _vm.inputName,
            type: "checkbox",
            disabled: _vm.disabled,
            required: _vm.required,
            "data-test-id": _vm.dataTestId_,
          },
          domProps: {
            checked: Array.isArray(_vm.value_)
              ? _vm._i(_vm.value_, null) > -1
              : _vm.value_,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.value_,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.value_ = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.value_ = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.value_ = $$c
              }
            },
          },
        }),
        _vm._v(" "),
        _c("label", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.switchTooltip,
              expression: "switchTooltip",
            },
          ],
          staticClass: "m-0",
          on: { click: _vm.toggleValue },
        }),
        _vm._v(" "),
        _vm.inputName
          ? _c("ShowErrors", {
              staticClass: "d-block",
              attrs: { errors: _vm.formErrors, errorKey: _vm.inputName },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }