import axios from "axios";
import moment from "moment";

export const actions = {
    fetchCommandsAtDate({}, { params = {}, restaurant_id, url = null }) {
        if (!params.date) {
            params.date = moment().format("YYYY-MM-DD");
        }

        url = url || `/api/click_and_collect/${restaurant_id}/commands`;
        if (url.indexOf("?") !== -1)
            url +=
                "&include=command_payments,client,slot,cc_delivery_area,command_products,command_products.product,command_products.command_product_option.product_option.cc_product";
        else
            url +=
                "?include=command_payments,client,slot,cc_delivery_area,command_products,command_products.product,command_products.command_product_option.product_option.cc_product";

        return axios.get(url, {
            params,
        });
    },
    fetchCommandsWaitingConfirmation({}, { params = {}, restaurant_id }) {
        return axios.get(
            `/api/click_and_collect/${restaurant_id}/commands/waiting_confirmation?include=command_payments,client,slot,cc_delivery_area,command_products,command_products.product,command_products.command_product_option.product_option.cc_product`,
            {
                params,
            }
        );
    },
    fetchCommandById({}, { command_id, restaurant_id }) {
        return axios.get(
            `/api/click_and_collect/${restaurant_id}/commands/${command_id}?include=logs.slot,logs.user,user,gv_sale,promo_code,command_payments,client,cc_delivery_area,slot,command_products,command_products.refund,command_products.product,command_products.command_product_option.refund,command_products.command_product_option.product_option.cc_product,mail_logs`
        );
    },
    areCommandClosedForDate({}, { params, restaurant_id }) {
        return axios.get(`/api/click_and_collect/${restaurant_id}/commands/closed`, {
            params,
        });
    },
    closeCommandForDate({}, { params, restaurant_id }) {
        return axios.post(`/api/click_and_collect/${restaurant_id}/commands/close`, params);
    },
    openCommandForDate({}, { params, restaurant_id }) {
        return axios.post(`/api/click_and_collect/${restaurant_id}/commands/open`, params);
    },
    updateStatus({}, { restaurant_id, command_id, status, keep_fees }) {
        return axios.put(`/api/click_and_collect/${restaurant_id}/commands/${command_id}/status?status=${status}`, {
            keep_fees,
        });
    },
    fetchOpenDaysPeriods({}, { restaurant_id, collect_type = "collect" }) {
        return axios.get(`/api/click_and_collect/${restaurant_id}/commands/openings`, {
            params: { collect_type },
        });
    },
    fetchSlotsForDay({}, { restaurant_id, params }) {
        return axios.get(`/api/click_and_collect/${restaurant_id}/commands/slots`, {
            params,
        });
    },
    fetchProductsForDay({}, { restaurant_id, params }) {
        return axios.get(`/api/click_and_collect/${restaurant_id}/commands/products`, { params });
    },
    addCommand({}, { restaurant_id, form }) {
        return axios.post(`/api/click_and_collect/${restaurant_id}/commands`, form);
    },
    sendPaymentAgain({}, { restaurant_id, command_id }) {
        return axios.post(`/api/click_and_collect/${restaurant_id}/commands/${command_id}/payment/send`);
    },
    payManually({}, { restaurant_id, command_id, params }) {
        return axios.post(`/api/click_and_collect/${restaurant_id}/commands/${command_id}/pay`, params);
    },
    fetchByToken({}, { restaurant_id, token }) {
        return axios.get(
            `/api/click_and_collect/${restaurant_id}/commands/${token}?include=command_payments,client,slot,command_products,command_products.product,command_products.command_product_option.product_option.cc_product,restaurant`
        );
    },
    updateDateAndSlot({}, { restaurant_id, command_id, params }) {
        return axios.put(`/api/click_and_collect/${restaurant_id}/commands/${command_id}/date_and_slot`, params);
    },
};

export default actions;
