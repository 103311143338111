import RestaurantsList from "../../views/Default/Restaurants/List.vue";
import MySubscription from "../../views/MySubscription.vue";
import MySubscriptionCard from "../../views/MySubscriptionCard.vue";
import LicencesCreate from "../../views/Default/Licences/Create.vue";
import LicencesCreateDispatchOption from "../../views/Default/Licences/CreateOptionDispatch.vue";
import LicencesInvoices from "../../views/Default/Licences/Invoices.vue";
import Employees from "../../views/Default/Employees/Employees.vue";
import EmployeeAdd from "../../views/Default/Employees/EmployeeAdd.vue";
import OnlineHelp from "../../views/Default/Help/OnlineHelp.vue";
import OnlineHelpForModule from "../../views/Default/Help/OnlineHelpForModule.vue";
import ReserveWithGoogle from "../../views/Default/Restaurants/ReserveWithGoogle.vue";
import ContractRwg from "../../views/Default/Restaurants/ContractRwg.vue";

export default [
    {
        path: "/restaurants",
        name: "restaurants",
        component: RestaurantsList,
    },
    {
        path: "/mon-abonnement/license",
        name: "subscription.licences.create",
        component: LicencesCreate,
    },
    {
        path: "/mon-abonnement/license/:subscription_id/option_dispatch",
        name: "subscription.licences.create_option_dispatch",
        component: LicencesCreateDispatchOption,
    },
    {
        path: "/mon-abonnement/carte",
        name: "subscription.card",
        component: MySubscriptionCard,
    },
    {
        path: "/mon-abonnement",
        name: "subscription",
        component: MySubscription,
    },
    {
        path: "/mon-abonnement/factures",
        name: "subscription.invoices",
        component: LicencesInvoices,
    },
    {
        path: "/employees",
        name: "employees",
        component: Employees,
    },
    {
        path: "/employees/add",
        name: "employees.add",
        component: EmployeeAdd,
    },
    {
        path: "/employees/:employee_id",
        name: "employees.edit",
        component: EmployeeAdd,
    },
    {
        path: "/online_help",
        name: "online_help",
        component: OnlineHelp,
    },
    {
        path: "/online_help/:module",
        name: "online_help.module",
        component: OnlineHelpForModule,
    },
    {
        path: "/restaurants/reserve_with_google",
        name: "restaurants.reserve_with_google",
        component: ReserveWithGoogle,
    },
    {
        path: "/restaurants/reserve_with_google/:restaurant_id",
        name: "restaurants.reserve_with_google.edit",
        component: ContractRwg,
    },
];
