<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="alert alert-danger" v-else-if="error">{{ error }}</div>
        <div v-else-if="ownerDetails && subscriptions">
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-3 mb-2">
                        <div>
                            <h5 class="title mt-2">
                                Détails du compte <span class="text-success">{{ ownerDetails.email }}</span>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12">
                    <ShowOwnerDetails
                        v-if="!isEditingOwner"
                        class="border-light b-radius-20 p-4 mb-3"
                        :ownerDetails="ownerDetails"
                        @edit-owner="isEditingOwner = true"
                        @owner-deleted="$router.push('/')" />
                    <EditOwner
                        v-else
                        :ownerId="owner_id"
                        @cancel-edit="isEditingOwner = false"
                        @edited="
                            isEditingOwner = false;
                            fetchOwnerDetails();
                        "
                        class="border-light b-radius-20 p-4 mb-3" />
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="row m-0">
                            <div class="col-12 mb-3">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <strong>Licences</strong>
                                        <span v-if="subscriptions.length > 0">({{ subscriptions.length }})</span>
                                        <small v-if="ownerDetails.has_manual_billing" class="d-block text-muted mt-1">
                                            <feather type="alert-triangle" class="text-warning" />
                                            Facturation manuelle
                                        </small>
                                    </div>
                                    <div class="d-flex">
                                        <div v-if="isYservices || ownerDetails.has_manual_billing">
                                            <router-link
                                                class="btn btn-sm btn-circle btn-success"
                                                :to="{ name: 'admin.owners.licences.create' }"
                                                :class="{ disabled: !isYservices && !canUpdateSubscription }">
                                                <feather type="plus" />
                                                Ajouter des licences
                                            </router-link>
                                        </div>
                                        <div v-if="!ownerDetails.has_active_subscription" class="ml-2 text-warning">
                                            <div class="mb-1">
                                                <template v-if="ownerDetails.enabled_by_admin">
                                                    <feather
                                                        v-tooltip="getTooltipNotice('notices.admin.archive.disable')"
                                                        type="info"
                                                        class="pointer tooltip-infos" />
                                                    <button
                                                        type="button"
                                                        class="btn btn-sm btn-success btn-circle"
                                                        @click="disableArchiveAccount"
                                                        :disabled="isTogglingArchiveAccount">
                                                        Désactiver le compte
                                                    </button>
                                                </template>
                                                <template v-else>
                                                    <feather
                                                        v-tooltip="getTooltipNotice('notices.admin.archive.enable')"
                                                        type="info"
                                                        class="pointer tooltip-infos" />
                                                    <button
                                                        type="button"
                                                        class="btn btn-sm btn-success btn-circle"
                                                        @click="enableArchiveAccount"
                                                        :disabled="isTogglingArchiveAccount">
                                                        Ré-activer temporairement le compte
                                                    </button>
                                                </template>
                                            </div>
                                            <div>
                                                <feather type="alert-triangle" class="text-warning mr-1" />
                                                Ce restaurateur ne possède aucune licence active
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-danger" v-if="subscriptions.length === 0">Ce restaurateur ne possède aucune licence</div>
                            <div v-else class="col-12 mb-3" v-for="subscription in subscriptions" :key="subscription.id">
                                <ShowSubscriptionDetails
                                    @subscription-canceled="onSubscriptionCanceled"
                                    @subscription-assigned="onSubscriptionAssigned"
                                    :subscription="subscription"
                                    :restaurants="ownerDetails.restaurants.data"
                                    :has-manual-billing="ownerDetails.has_manual_billing"
                                    class="border-light b-radius-20 p-4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0" v-if="isNoShow">
                <div class="col-12">
                    <Invoices class="border-light b-radius-20 p-4 mb-3" :isComponent="true" :user_id="ownerDetails.id" />
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12">
                    <ShowOwnerSmsList class="border-light b-radius-20 p-4 mb-3" :ownerDetails="ownerDetails" />
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12">
                    <ShowOwnerRestaurants
                        class="border-light b-radius-20 p-4 mb-3"
                        :ownerDetails="ownerDetails"
                        :subscriptions="subscriptions"
                        @restaurant-deleted="fetchOwnerDetails" />
                </div>
            </div>
            <div class="row m-0" v-if="isNoShow">
                <div class="col-12">
                    <ShowOwnerEmployees
                        class="border-light b-radius-20 p-4 mb-3"
                        :employees="ownerDetails.employees.data"
                        @employee-deleted="fetchOwnerDetails" />
                </div>
            </div>
            <div class="row m-0" v-if="isNoShow">
                <div class="col-12">
                    <ShowOwnerAcceptedCgvs class="border-light b-radius-20 p-4 mb-3" :cgvs="ownerDetails.accepted_cgvs.data" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import LoaderComponent from "../../components/LoaderComponent.vue";
import ShowOwnerRestaurants from "../../components/Admin/ShowOwnerRestaurants.vue";
import ShowOwnerSmsList from "../../components/Admin/ShowOwnerSmsList.vue";
import ShowOwnerEmployees from "../../components/Admin/ShowOwnerEmployees.vue";
import ShowOwnerAcceptedCgvs from "../../components/Admin/ShowOwnerAcceptedCgvs.vue";
import ShowOwnerDetails from "../../components/Admin/ShowOwnerDetails.vue";
import ShowSubscriptionDetails from "../../components/Admin/ShowSubscriptionDetails.vue";
import Invoices from "../Default/Licences/Invoices.vue";
import EditOwner from "../../components/Admin/Owners/Edit.vue";

export default {
    data() {
        return {
            loading: 0,
            error: null,
            ownerDetails: null,
            moment,
            stripeSubscriptions: [],
            stripeSubscriptionsInvoices: [],
            isEditingOwner: false,
            isTogglingArchiveAccount: false,
        };
    },
    computed: {
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        canUpdateSubscription() {
            return this.rights.includes("admin.subscription.update");
        },
        owner_id() {
            return this.$route.params.owner_id;
        },
        subscriptions() {
            var subscriptions = [];

            for (var key in this.stripeSubscriptions) {
                subscriptions = subscriptions.concat(this.stripeSubscriptions[key]);
            }

            return subscriptions;
        },
    },
    methods: {
        onSubscriptionCanceled() {
            this.fetchSubscriptions();
            this.fetchOwnerDetails();
        },
        onSubscriptionAssigned() {
            this.fetchSubscriptions();
            this.fetchOwnerDetails();
        },
        toggleArchiveAccount() {
            if (this.ownerDetails.enabled_by_admin) {
                this.disableArchiveAccount();
            } else {
                this.enableArchiveAccount();
            }
        },
        enableArchiveAccount() {
            if (this.ownerDetails === null || this.isTogglingArchiveAccount) {
                return;
            }

            if (!confirm("Êtes vous sûr de vouloir réactiver ce compte de façon temporaire ?")) {
                return;
            }

            this.isTogglingArchiveAccount = true;

            axios
                .post(`/api/admin/archive/${this.owner_id}/enable`)
                .then((response) => {
                    this.notifySuccess(response);
                    this.fetchOwnerDetails();
                    this.fetchSubscriptions();
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.isTogglingArchiveAccount = false;
                });
        },
        disableArchiveAccount() {
            if (this.ownerDetails === null || this.isTogglingArchiveAccount) {
                return;
            }

            if (!confirm("Êtes vous sûr de vouloir désactiver ce compte ?")) {
                return;
            }

            this.isTogglingArchiveAccount = true;

            axios
                .post(`/api/admin/archive/${this.owner_id}/disable`)
                .then((response) => {
                    this.notifySuccess(response);
                    this.fetchOwnerDetails();
                    this.fetchSubscriptions();
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.isTogglingArchiveAccount = false;
                });
        },
        fetchOwnerDetails() {
            this.loading++;

            axios
                .get(`/api/admin/owners/${this.owner_id}?include=restaurants.subscriptions.module,has_active_subscription,employees,accepted_cgvs`)
                .then((response) => {
                    this.loading--;
                    this.ownerDetails = response.data;
                })
                .catch((error) => {
                    this.loading--;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        fetchSubscriptions() {
            this.loading++;

            axios
                .get(`/api/stripe/licences?owner_id=${this.owner_id}`)
                .then((response) => {
                    this.loading--;
                    this.stripeSubscriptions = response.data.data;
                })
                .catch((error) => {
                    this.loading--;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        fetchSubscriptionsInvoices() {
            this.loading++;

            axios
                .get(`/api/stripe/invoices?type=subscription&owner_id=${this.owner_id}`)
                .then((response) => {
                    this.loading--;
                    this.stripeSubscriptionsInvoices = response.data.data;
                })
                .catch((error) => {
                    this.loading--;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
    },
    components: {
        LoaderComponent,
        ShowOwnerDetails,
        ShowSubscriptionDetails,
        ShowOwnerRestaurants,
        ShowOwnerEmployees,
        ShowOwnerAcceptedCgvs,
        ShowOwnerSmsList,
        Invoices,
        EditOwner,
    },
    created() {
        this.fetchOwnerDetails();
        this.fetchSubscriptions();
    },
};
</script>
