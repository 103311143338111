export default {
    data() {
        return {
            COLUMN_RESERVATION_TABLE: {
                value: "table",
                label: "table",
            },
            COLUMN_RESERVATION_DATE: {
                value: "created_at",
                label: "creationDate",
            },
            COLUMN_RESERVATION_ROOM: {
                value: "room_number",
                label: "room_number",
            },
            COLUMN_RESERVATION_PAYMENT: {
                value: "payment",
                label: "footprintPayment",
            },
            COLUMN_RESERVATION_CONFIRMATION: {
                value: "confirmation",
                label: "confirmation",
            },
            COLUMN_RESERVATION_COMPANY: {
                value: "company",
                label: "company",
            },
        };
    },
    methods: {
        getLabelColumnReservation(columnValue, restaurantId = undefined) {
            const column = this.ALL_COLUMN_RESERVATIONS.find((element) => element.value === columnValue);
            return column !== undefined ? this.$tl(`labels.booking.reservations.grid.columns.${column.label}`, restaurantId) : columnValue;
        },
    },
    computed: {
        ALL_COLUMN_RESERVATIONS() {
            return [
                this.COLUMN_RESERVATION_TABLE,
                this.COLUMN_RESERVATION_DATE,
                this.COLUMN_RESERVATION_ROOM,
                this.COLUMN_RESERVATION_PAYMENT,
                this.COLUMN_RESERVATION_CONFIRMATION,
                this.COLUMN_RESERVATION_COMPANY,
            ];
        },
    },
};
