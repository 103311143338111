<template>
    <div>
        <div class="row mb-2">
            <div class="col-12 d-flex justify-content-between">
                <div>
                    <strong class="pointer" @click="show = !show">Établissements ({{ ownerDetails.restaurants.data.length }})</strong>
                </div>
                <div v-if="isYservices">
                    <router-link class="btn btn-sm btn-circle btn-success" :to="{ name: 'restaurants.add', query: { owner_id: ownerDetails.id } }">
                        <feather type="plus" /> Ajouter un établissement
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="show" class="row">
            <div class="col-12">
                <table class="table table-sm table-striped border-bottom">
                    <thead class="border-bottom">
                        <tr>
                            <th>Nom</th>
                            <th>Nombre de licences</th>
                            <th>Fct. "Numéro Chambres"</th>
                            <th>Fct. "Une résa par semaine"</th>
                            <th v-if="isYservices">Fct. "Plan de salle"</th>
                            <th v-if="isReserveWithGoogleEnabled()">Fct. "Réserver avec Google"</th>
                            <th>Fct. "Marque blanche"</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="restaurant in ownerDetails.restaurants.data" :key="restaurant.id">
                            <td style="text-transform: capitalize">{{ restaurant.name }} - {{ restaurant.id }}</td>
                            <td>{{ getNbSubscriptionsForRestaurant(restaurant.id) }}</td>
                            <td>
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="restaurant.feat_room_numbers"
                                        :disabled="restaurant.hotel_mode || featRoomNumbersEditing.some((id) => id === restaurant.id)" />
                                    <label v-tooltip="getFeatRoomNumbersTooltip(restaurant)" @click="toggleFeatRoomNumbers(restaurant)"></label>
                                </div>
                            </td>
                            <td>
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="restaurant.feat_one_booking_per_week"
                                        :disabled="featOneBookingPerWeekEditing.some((id) => id === restaurant.id)" />
                                    <label @click="toggleFeatOneBookingPerWeek(restaurant)"></label>
                                </div>
                            </td>
                            <td v-if="isYservices">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="restaurant.feat_seating_plan"
                                        :disabled="featSeatingPlanEditing.some((id) => id === restaurant.id)" />
                                    <label @click="toggleFeatSeatingPlan(restaurant)"></label>
                                </div>
                            </td>
                            <td v-if="isReserveWithGoogleEnabled()">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="restaurant.feat_reserve_with_google"
                                        :disabled="featReserveWithGoogleEditing.some((id) => id === restaurant.id)" />
                                    <label @click="toggleFeatReserveWithGoogle(restaurant)"></label>
                                </div>
                            </td>
                            <td>
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="restaurant.feat_white_labeled"
                                        :disabled="featWhiteLabeledEditing.some((id) => id === restaurant.id)" />
                                    <label @click="toggleFeatWhiteLabeled(restaurant)"></label>
                                </div>
                            </td>
                            <td>
                                <router-link
                                    v-if="isYservices && !loading"
                                    v-tooltip="getTooltip('Éditer')"
                                    :to="{ name: 'restaurants.edit', query: { owner_id: ownerDetails.id }, params: { restaurant_id: restaurant.id } }"
                                    class="btn btn-success btn-square btn-sm mr-2">
                                    <feather type="edit"></feather>
                                </router-link>
                                <button
                                    type="button"
                                    v-tooltip="getTooltip('Supprimer')"
                                    class="btn btn-danger btn-square btn-sm"
                                    :class="{ disabled: loading }"
                                    @click="deleteRestaurant(restaurant)"
                                    :disabled="loading">
                                    <feather type="trash-2"></feather>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            loading: false,
            featRoomNumbersEditing: [],
            featOneBookingPerWeekEditing: [],
            featReserveWithGoogleEditing: [],
            featSeatingPlanEditing: [],
            featWhiteLabeledEditing: [],
        };
    },
    props: {
        ownerDetails: {
            required: true,
            type: Object,
        },
        subscriptions: {
            required: true,
            type: Array,
        },
    },
    methods: {
        getFeatRoomNumbersTooltip(restaurant) {
            return (
                (restaurant.hotel_mode &&
                    this.getTooltip(
                        "Il n'est pas possible d'activer la fonctionnalité \"Numéro de Chambres\" si le mode hôtel est activé sur le restaurant"
                    )) ||
                undefined
            );
        },
        toggleFeatRoomNumbers(restaurant) {
            if (restaurant.hotel_mode) {
                return;
            }

            this.toggleCustomFeature(restaurant, "feat_room_numbers", "Numéro de Chambres", "featRoomNumbersEditing");
        },
        toggleFeatOneBookingPerWeek(restaurant) {
            this.toggleCustomFeature(restaurant, "feat_one_booking_per_week", "Une réservation par semaine", "featOneBookingPerWeekEditing");
        },
        toggleFeatSeatingPlan(restaurant) {
            this.toggleCustomFeature(restaurant, "feat_seating_plan", "Plan de salle", "featSeatingPlanEditing");
        },
        toggleFeatReserveWithGoogle(restaurant) {
            this.toggleCustomFeature(restaurant, "feat_reserve_with_google", "Réserver avec Google", "featReserveWithGoogleEditing");
        },
        toggleFeatWhiteLabeled(restaurant) {
            this.toggleCustomFeature(restaurant, "feat_white_labeled", "Marque blanche", "featWhiteLabeledEditing");
        },
        toggleCustomFeature(restaurant, feat, label, loaders) {
            if (this[loaders].includes(restaurant.id)) {
                return;
            }

            const newVal = !restaurant[feat];

            if (!confirm(`Êtes vous sûr de vouloir ${newVal ? "activer" : "désactiver"} la fonctionnalité "${label}" ?`)) {
                return;
            }

            this[loaders].push(restaurant.id);

            this.httpPut(`/api/admin/custom_features/${restaurant.id}/${feat}/${newVal ? "enable" : "disable"}`, {}, { handleResolve: false })
                .then((response) => {
                    if (response !== false) {
                        restaurant[feat] = response.data.enable;

                        this.notifySuccess(null, `La fonctionnalité "${label}" a été ${response.data.enable ? "activée" : "désactivée"}`);
                    }
                })
                .finally(() => this[loaders].splice(this[loaders].indexOf(restaurant.id), 1));
        },
        getNbSubscriptionsForRestaurant(restaurant_id) {
            return this.subscriptions.filter((s) => s.restaurant_id === restaurant_id).length;
        },
        deleteRestaurant(restaurant) {
            if (!confirm(`Supprimer l'établissement "${restaurant.name}" ? Cette action est irréversible et supprime l'ensemble des données.`)) {
                return;
            }

            this.loading = true;

            this.httpDelete(`/api/admin/restaurants/${restaurant.id}`)
                .then((response) => {
                    if (response !== false) {
                        this.$emit("restaurant-deleted");
                    }
                })
                .finally(() => (this.loading = false));
        },
    },
};
</script>
