<template>
    <div>
        <div class="row">
            <div class="col-lg-12 pt-3">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <div class="row">
                        <div class="col-md-4 mb-2">
                            <strong>Nom de la campagne</strong>
                        </div>
                        <div class="col-md-8">
                            <input type="text" v-model="campaign.name" class="form-control" />
                            <ShowErrors class="d-block" :errors="errors" errorKey="name" />
                        </div>
                    </div>
                    <div class="row mt-3 switch-type">
                        <div class="col-md-4 mb-2">
                            <strong>Type de campagne</strong>
                        </div>
                        <div class="col-md-8">
                            <input
                                type="checkbox"
                                class="switch align-self-center is-rounded"
                                :checked="campaign.type == CAMPAIGN_TYPE_SMS.value" />
                            Email <label @click="toggleCampaignType"></label> SMS
                            <ShowErrors class="d-block" :errors="errors" errorKey="type" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <div class="row">
                        <div class="col-12">
                            <strong>Composez votre message</strong>
                        </div>
                    </div>
                    <template v-if="campaign.type == CAMPAIGN_TYPE_EMAIL.value">
                        <div class="row mt-3">
                            <div class="col-md-4 pt-2 mb-2">Objet *</div>
                            <div class="col-md-8">
                                <input type="text" class="form-control" v-model="campaign.mail_object" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="mail_object" />
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-4 pt-2 mb-2">
                                <span class="d-block mb-1">Message *</span>
                                <button
                                    type="button"
                                    @click="addToMessage('[FIRSTNAME]')"
                                    class="badge badge-secondary mb-1"
                                    style="border: none">
                                    Insérer le prénom du client
                                </button>
                                <button
                                    type="button"
                                    @click="addToMessage('[LASTNAME]')"
                                    class="badge badge-secondary mb-1"
                                    style="border: none">
                                    Insérer le nom du client
                                </button>
                            </div>
                            <div class="col-md-8">
                                <textarea
                                    ref="txtEmailBody"
                                    class="form-control"
                                    style="font-size: 0.75rem"
                                    v-model="campaign.content"
                                    rows="8"></textarea>
                                <ShowErrors class="d-block" :errors="errors" errorKey="content" />
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-4 mb-2">Image</div>
                            <div class="col-md-8">
                                <ImageComponent
                                    :src="campaign.img_path"
                                    suffixId="campaign"
                                    :postURI="imgPostUri"
                                    :maxSize="10"
                                    :callBack="(response) => (this.campaign.img_path = response.data.img_path)" />
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-4 pt-2 mb-2">Lien au clique sur l'image</div>
                            <div class="col-md-8">
                                <input type="text" class="form-control" v-model="campaign.img_link" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="img_link" />
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="row mt-3">
                            <div class="col-md-5">
                                <label class="mb-1">Nom affiché au client, à la réception : *</label>
                                <small class="d-block">11 caractères maximum</small>
                            </div>
                            <div class="col-md-7">
                                <input
                                    type="text"
                                    :class="{
                                        'is-invalid':
                                            !campaign.sms_expeditor || !campaign.sms_expeditor.match(/^[a-z]+$/i),
                                    }"
                                    v-model="campaign.sms_expeditor"
                                    class="form-control mb-2"
                                    maxlength="11" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="sms_expeditor" />
                                <span class="limiter text-muted pl-2"
                                    >Il vous reste {{ sms_emitter_name_count }} caractère{{
                                        sms_emitter_name_count > 1 ? "s" : ""
                                    }}</span
                                >
                                <small class="text-danger d-block pl-2"
                                    >Attention : seule les lettres minuscules et majuscules sont autorisées, les
                                    caractères spéciaux, chiffres, espaces, ou lettres accentuées ne sont pas acceptés
                                    par notre prestataire d'envoi de SMS.</small
                                >
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-5 pt-2 mb-2">Message *</div>
                            <div class="col-md-7">
                                <textarea
                                    class="form-control mb-2"
                                    v-model="campaign.content"
                                    rows="4"
                                    maxlength="469"></textarea>
                                <span class="limiter text-muted pl-2"
                                    >{{ sms_message_count }} caractère{{ sms_message_count > 1 ? "s" : "" }}</span
                                >
                                /
                                <span v-if="sms_message_count <= 149" class="text-muted">1 crédit</span>
                                <span v-else-if="sms_message_count <= 309" class="text-muted">2 crédits</span>
                                <span v-else class="text-muted">3 crédits</span>
                                <ShowErrors class="d-block" :errors="errors" errorKey="content" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ShowErrors from "../../../errors/ShowErrors";
import ImageComponent from "../../../forms/ImageComponent";
import CampaignTypesEnum from "../../../../mixins/enums/campaigns/CampaignTypesEnum";

export default {
    data() {
        return {
            smsEnabled: false,
        };
    },
    mixins: [CampaignTypesEnum],
    props: {
        campaign: {
            type: Object,
            required: true,
        },
        errors: {
            default: null,
        },
    },
    computed: {
        imgPostUri() {
            const campaignId = this.campaign.id || "none";
            const oldImg = this.campaign.img_path ? this.fileNameOnly(this.campaign.img_path) : "none";
            return `/api/campaigns/admin/campaigns/${campaignId}/imgs/${oldImg}`;
        },
        sms_emitter_name_count() {
            if (this.campaign.sms_expeditor) return 11 - this.campaign.sms_expeditor.length;
            return 11;
        },
        sms_message_count() {
            if (this.campaign.content) {
                let charsCountDouble = ["€", "{", "|", "}", "~", "[", "\\", "]", "^"];
                let nb = 0;
                for (let i = 0; i < this.campaign.content.length; i++) {
                    const letter = this.campaign.content.charAt(i);
                    if (charsCountDouble.includes(letter)) nb++;
                }
                return this.campaign.content.length + nb;
            }
            return 0;
        },
    },
    methods: {
        toggleCampaignType() {
            if (this.campaign.type === this.CAMPAIGN_TYPE_SMS.value)
                this.campaign.type = this.CAMPAIGN_TYPE_EMAIL.value;
            else this.campaign.type = this.CAMPAIGN_TYPE_SMS.value;
        },
        addToMessage(str) {
            this.campaign.content += str;
            this.$refs.txtEmailBody.focus();
        },
    },
    components: {
        ShowErrors,
        ImageComponent,
    },
};
</script>
