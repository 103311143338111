<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="row" v-else>
            <div class="col-12">
                <div class="border-light b-radius-20 p-4">
                    <div class="row mb-2">
                        <div class="col-5 pt-2">
                            <label for="name">Code promo <small>*</small></label>
                        </div>
                        <div class="col-7">
                            <input type="text" v-model="promoCode.name" required class="form-control" />
                            <show-errors :errors="errors" errorKey="name" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-5">Type <small>*</small></div>
                        <div class="col-7">
                            <div class="d-inline-block align-middle lead-switch">
                                {{ PROMO_CODE_TYPE_AMOUNT.label }}
                                <input
                                    type="checkbox"
                                    class="switch align-self-center is-rounded"
                                    :true-value="PROMO_CODE_TYPE_PERCENT.value"
                                    :false-value="PROMO_CODE_TYPE_AMOUNT.value"
                                    v-model="promoCode.type" />
                                <label @click="toggleType"></label>{{ PROMO_CODE_TYPE_PERCENT.label }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-5 pt-2">{{ getPromoCodeTypeLabel(promoCode.type) }} <small>*</small></div>
                        <div class="col-7">
                            <div class="row">
                                <div class="col-md-4">
                                    <input type="number" v-model="promoCode.amount" required class="form-control" />
                                </div>
                                <div class="col-md-8 mt-1">{{ getPromoCodeTypeUnit(promoCode.type) }}</div>
                            </div>
                            <show-errors :errors="errors" errorKey="amount" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-5 pt-2">
                            <label>Date de début</label>
                        </div>
                        <div class="col-7 date-resa-cal">
                            <datepicker
                                class="d-inline-block w-auto"
                                :monday-first="true"
                                v-model="promoCode.starts_on"
                                :language="fr" />
                            <feather type="x" class="text-danger" @click="promoCode.starts_on = null" />
                            <show-errors class="d-block" :errors="errors" errorKey="starts_on" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-5 pt-2">
                            <label>Date de fin</label>
                        </div>
                        <div class="col-7 date-resa-cal">
                            <datepicker
                                class="d-inline-block w-auto"
                                :monday-first="true"
                                v-model="promoCode.ends_on"
                                :language="fr" />
                            <feather type="x" class="text-danger" @click="promoCode.ends_on = null" />
                            <show-errors class="d-block" :errors="errors" errorKey="ends_on" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-5 pt-2">Nombre maximum d'utilisation par personne</div>
                        <div class="col-7">
                            <input type="number" v-model="promoCode.limit_per_pers" required class="form-control" />
                            <show-errors :errors="errors" errorKey="limit_per_pers" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-5 pt-2">Nombre maximum d'utilisation total</div>
                        <div class="col-7">
                            <input type="number" v-model="promoCode.total_limit" required class="form-control" />
                            <show-errors :errors="errors" errorKey="total_limit" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-5 pt-2">Montant minimum de commande</div>
                        <div class="col-7">
                            <div class="row">
                                <div class="col-md-4">
                                    <input type="number" v-model="promoCode.min_amount" required class="form-control" />
                                </div>
                                <div class="col-md-8 mt-1">€</div>
                            </div>
                            <show-errors :errors="errors" errorKey="min_amount" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-5">
                            <label> Services concernés <small>*</small> </label>
                        </div>
                        <div class="col-7">
                            <show-errors class="d-block" :errors="errors" errorKey="collect_types" />
                            <show-errors class="d-block" :errors="errors" errorKey="collect_types.*" />
                            <div v-if="availableCollectTypes.length > 0">
                                <div v-for="collectType in availableCollectTypes" :key="collectType.value">
                                    <label class="container-box" style="width: 100%; text-transform: capitalize">
                                        <input
                                            type="checkbox"
                                            name="collect_types[]"
                                            v-model="promoCode.collect_types"
                                            :value="collectType.value" />
                                        <span class="checkmark"></span> {{ collectType.label }}
                                    </label>
                                </div>
                            </div>
                            <div v-else>
                                <em>Aucun service n'est disponible. Merci de vérifier votre configuration.</em>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ShowErrors from "../../errors/ShowErrors";
import LoaderComponent from "../../LoaderComponent";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import PromoCodeTypeEnum from "../../../mixins/enums/click_and_collect/PromoCodeTypeEnum";
import CollectTypesEnum from "../../../mixins/enums/click_and_collect/CollectTypesEnum";

export default {
    data() {
        return {
            loading: 0,
            errors: null,
            fr,
            promoCode: {
                id: null,
                name: null,
                group_id: null,
                restaurant_id: null,
                type: null,
                amount: null,
                starts_on: null,
                ends_on: null,
                limit_per_pers: null,
                total_limit: null,
                min_amount: null,
                collect_types: [],
            },
            availableCollectTypes: [],
        };
    },
    props: {
        promoCodeId: {
            default: null,
        },
    },
    mixins: [PromoCodeTypeEnum, CollectTypesEnum],
    computed: {
        builtData() {
            let data = _.cloneDeep(this.promoCode);
            if (data.type === this.PROMO_CODE_TYPE_AMOUNT.value && data.amount) data.amount *= 100;
            if (data.min_amount) data.min_amount *= 100;
            if (data.starts_on) data.starts_on = moment(data.starts_on).format("Y-MM-DD");
            if (data.ends_on) data.ends_on = moment(data.ends_on).format("Y-MM-DD");
            return data;
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        toggleType() {
            if (this.promoCode.type === this.PROMO_CODE_TYPE_AMOUNT.value)
                this.promoCode.type = this.PROMO_CODE_TYPE_PERCENT.value;
            else this.promoCode.type = this.PROMO_CODE_TYPE_AMOUNT.value;
        },
        assignResponseToPromoCode(data) {
            this.promoCode = data;
            this.promoCode.starts_on = data.starts_on ? moment(data.starts_on).toDate() : null;
            this.promoCode.ends_on = data.ends_on ? moment(data.ends_on).toDate() : null;
            if (data.type === this.PROMO_CODE_TYPE_AMOUNT.value) this.promoCode.amount /= 100;
            if (data.min_amount) this.promoCode.min_amount /= 100;
        },
        fetchAvailableCollectTypes() {
            this.loading++;
            this.availableCollectTypes = [];

            this.$store
                .dispatch("ccConfiguration/fetchConfiguration", { restaurant_id: this.restaurant_id })
                .then((response) => {
                    this.loading--;
                    if (response.data.is_click_and_collect_enabled)
                        this.availableCollectTypes.push(this.COLLECT_TYPE_COLLECT);
                    if (response.data.is_delivery_enabled) this.availableCollectTypes.push(this.COLLECT_TYPE_DELIVERY);
                    if (response.data.is_dispatch_enabled) this.availableCollectTypes.push(this.COLLECT_TYPE_DISPATCH);
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        fetchData() {
            this.loading++;

            axios
                .get(`/api/click_and_collect/${this.restaurant_id}/promo_codes/${this.promoCodeId}`)
                .then((response) => {
                    this.loading--;
                    this.assignResponseToPromoCode(response.data);
                })
                .catch((error) => {
                    this.loading--;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
        save() {
            this.loading++;
            this.errors = null;

            if (this.promoCodeId === null)
                var axiosPromise = axios.post(
                    `/api/click_and_collect/${this.restaurant_id}/promo_codes`,
                    this.builtData
                );
            else
                var axiosPromise = axios.put(
                    `/api/click_and_collect/${this.restaurant_id}/promo_codes/${this.promoCodeId}`,
                    this.builtData
                );
            axiosPromise
                .then((response) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.$emit("saved");
                })
                .catch((error) => {
                    this.loading--;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
    },
    created() {
        this.promoCode.type = this.PROMO_CODE_TYPE_AMOUNT.value;
        this.fetchAvailableCollectTypes();
        if (this.promoCodeId) this.fetchData();
    },
    components: {
        LoaderComponent,
        Datepicker,
        ShowErrors,
    },
};
</script>
