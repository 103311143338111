<template>
    <div class="row mb-3">
        <div class="col-12">
            <loader-component v-if="nbLoading > 0" />
            <div v-else-if="stats.main" class="border-light b-radius-20 p-4">
                <div class="row m-0">
                    <h6 class="mt-3 col-10">
                        <strong>{{ $tl("labels.booking.stats.pax.title") }}</strong>
                    </h6>
                    <div class="d-flex justify-content-end align-items-center lead-switch col-2">
                        <button
                            class="btn btn-sm btn-square"
                            type="button"
                            :class="showGraph ? 'btn-success' : 'btn-secondary'"
                            @click.stop.prevent="showGraph = !showGraph">
                            <feather type="pie-chart" />
                        </button>
                    </div>
                </div>
                <div class="row m-0 click-collect-report">
                    <div :class="nbResaAndPaxGridClasses">
                        <div class="border-light p-3 b-radius-20 h-100">
                            <span>{{ $tl("labels.booking.stats.pax.web") }}</span>
                            <div class="pt-2">
                                <strong class="value">
                                    <span v-if="!filters.hasGroupComparison"> {{ $tl("labels.booking.stats.periodOne") }} {{ stats.main.web }} </span>
                                    <span v-else> {{ $tl("labels.booking.stats.groupOne") }} {{ stats.main.web }} </span>
                                    <template v-if="stats.main.web != 0">
                                        <span class="ml-2">({{ formatPercent(stats.main.web_percent) }}%)</span>
                                    </template>
                                </strong>
                            </div>
                            <template v-if="(stats && stats.comparison && filters.hasComparison) || (stats.comparison && filters.hasGroupComparison)">
                                <div class="pt-2">
                                    <strong class="text-success">
                                        <span v-if="!filters.hasGroupComparison">
                                            {{ $tl("labels.booking.stats.periodTwo") }} {{ stats.comparison.web }}
                                        </span>
                                        <span v-else> {{ $tl("labels.booking.stats.groupTwo") }} {{ stats.comparison.web }} </span>
                                        <template v-if="stats.comparison.web != 0">
                                            <span class="ml-2">({{ formatPercent(stats.comparison.web_percent) }}%)</span>
                                        </template>
                                    </strong>
                                    <ArrowPercentEvolutionComponent
                                        v-if="!(stats.main.web == 0 && stats.comparison.web == 0)"
                                        :difference="stats.main.web - stats.comparison.web"
                                        :percent="getEvolution(stats.main.web, stats.comparison.web)"
                                        class="pt-2 font-weight-bold" />
                                </div>
                            </template>
                        </div>
                    </div>
                    <div :class="nbResaAndPaxGridClasses">
                        <div class="border-light p-3 b-radius-20 h-100">
                            <span>{{ $tl("labels.booking.stats.pax.manual") }}</span>
                            <div class="pt-2">
                                <strong class="value">
                                    <span v-if="!filters.hasGroupComparison">
                                        {{ $tl("labels.booking.stats.periodOne") }} {{ stats.main.manual }}
                                    </span>
                                    <span v-else> {{ $tl("labels.booking.stats.groupOne") }} {{ stats.main.manual }} </span>
                                    <template v-if="stats.main.manual != 0">
                                        <span class="ml-2">({{ formatPercent(stats.main.manual_percent) }}%)</span>
                                    </template>
                                </strong>
                            </div>
                            <template v-if="(stats && stats.comparison && filters.hasComparison) || (stats.comparison && filters.hasGroupComparison)">
                                <div class="pt-2">
                                    <strong class="text-success">
                                        <span v-if="!filters.hasGroupComparison">
                                            {{ $tl("labels.booking.stats.periodTwo") }} {{ stats.comparison.manual }}
                                        </span>
                                        <span v-else> {{ $tl("labels.booking.stats.groupTwo") }} {{ stats.comparison.manual }} </span>
                                        <template v-if="stats.comparison.manual != 0">
                                            <span class="ml-2">({{ formatPercent(stats.comparison.manual_percent) }}%)</span>
                                        </template>
                                    </strong>
                                    <ArrowPercentEvolutionComponent
                                        v-if="!(stats.main.manual == 0 && stats.comparison.manual == 0)"
                                        :difference="stats.main.manual - stats.comparison.manual"
                                        :percent="getEvolution(stats.main.manual, stats.comparison.manual)"
                                        class="pt-2 font-weight-bold" />
                                </div>
                            </template>
                        </div>
                    </div>
                    <div v-if="hasEnabledRwg" :class="nbResaAndPaxGridClasses">
                        <div class="border-light p-3 b-radius-20 h-100">
                            <span>{{ $tl("labels.booking.stats.pax.rwg") }}</span>
                            <div class="pt-2">
                                <strong class="value">
                                    <span v-if="!filters.hasGroupComparison"> {{ $tl("labels.booking.stats.periodOne") }} {{ stats.main.rwg }} </span>
                                    <span v-else> {{ $tl("labels.booking.stats.groupOne") }} {{ stats.main.rwg }} </span>
                                    <template v-if="stats.main.rwg != 0">
                                        <span>({{ formatPercent(stats.main.rwg_percent) }}%)</span>
                                    </template>
                                </strong>
                            </div>
                            <template v-if="(stats && stats.comparison && filters.hasComparison) || (stats.comparison && filters.hasGroupComparison)">
                                <div class="pt-2">
                                    <strong class="text-success">
                                        <span v-if="!filters.hasGroupComparison">
                                            {{ $tl("labels.booking.stats.periodTwo") }} {{ stats.comparison.rwg }}
                                        </span>
                                        <span v-else> {{ $tl("labels.booking.stats.groupTwo") }} {{ stats.comparison.rwg }} </span>
                                        <template v-if="stats.comparison.rwg != 0">
                                            <span class="ml-2">({{ formatPercent(stats.comparison.rwg_percent) }}%)</span>
                                        </template>
                                    </strong>
                                    <ArrowPercentEvolutionComponent
                                        v-if="!(stats.main.rwg == 0 && stats.comparison.rwg == 0)"
                                        :difference="stats.main.rwg - stats.comparison.rwg"
                                        :percent="getEvolution(stats.main.rwg, stats.comparison.rwg)"
                                        class="pt-2 font-weight-bold" />
                                </div>
                            </template>
                        </div>
                    </div>
                    <div :class="nbResaAndPaxGridClasses">
                        <div class="border-light p-3 b-radius-20 h-100">
                            <span>{{ $tl("labels.booking.stats.pax.total") }}</span>
                            <div class="pt-2">
                                <strong class="value">
                                    <span v-if="!filters.hasGroupComparison">
                                        {{ $tl("labels.booking.stats.periodOne") }} {{ stats.main.total }}
                                    </span>
                                    <span v-else> {{ $tl("labels.booking.stats.groupOne") }} {{ stats.main.total }} </span>
                                </strong>
                            </div>
                            <template v-if="(stats && stats.comparison && filters.hasComparison) || (stats.comparison && filters.hasGroupComparison)">
                                <div class="pt-2">
                                    <strong class="text-success">
                                        <span v-if="!filters.hasGroupComparison">
                                            {{ $tl("labels.booking.stats.periodTwo") }} {{ stats.comparison.total }}
                                        </span>
                                        <span v-else> {{ $tl("labels.booking.stats.groupTwo") }} {{ stats.comparison.total }} </span>
                                    </strong>
                                </div>
                                <ArrowPercentEvolutionComponent
                                    v-if="stats.main.total !== 0 || stats.comparison.total !== 0"
                                    :difference="stats.main.total - stats.comparison.total"
                                    :percent="getEvolution(stats.main.total, stats.comparison.total)"
                                    class="pt-2 font-weight-bold" />
                            </template>
                        </div>
                    </div>
                </div>
                <div v-if="showGraph" class="row mt-3">
                    <div
                        class="pl-1 pl-xl-3 pr-3 pr-xl-1 pb-2 pb-xl-0"
                        :class="filters.hasComparison && stats.comparison ? 'col-xl-6 col-12' : 'col-xl-12'">
                        <div
                            class="p-1 d-flex flex-column align-items-center h-100"
                            :class="filters.hasComparison && stats.comparison ? 'border-light' : 'col-xl-12'">
                            <strong class="text-success py-3">{{ labelPeriodMain }}</strong>
                            <div v-if="stats.main.total === 0" class="h-100 d-flex align-items-center">
                                <strong>
                                    {{ $tl("labels.booking.stats.noDataOnPeriod") }}
                                </strong>
                            </div>
                            <v-chart
                                v-else
                                class="chart"
                                :option="{
                                    title: nbPaxByOrigin.title,
                                    series: pieChartNbPaxByOrigin,
                                    tooltip: tooltipOrigin,
                                    grid: grid,
                                }">
                            </v-chart>
                        </div>
                    </div>
                    <div v-if="filters.hasComparison && stats.comparison" class="col-xl-6 col-12 pl-1 pr-3">
                        <div class="border-light b-radius-20 p-1 d-flex flex-column align-items-center h-100">
                            <strong class="text-success py-3">{{ labelPeriodComparison }}</strong>
                            <div v-if="stats.comparison.total === 0" class="h-100 d-flex align-items-center">
                                <strong>
                                    {{ $tl("labels.booking.stats.noDataOnPeriod") }}
                                </strong>
                            </div>
                            <v-chart
                                v-else
                                class="chart"
                                :option="{
                                    title: nbPaxByOrigin.title,
                                    series: pieChartNbPaxByOriginComparison,
                                    tooltip: tooltipOrigin,
                                    grid: grid,
                                }">
                            </v-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArrowPercentEvolutionComponent from "./ArrowPercentEvolutionComponent.vue";
import VChart from "vue-echarts";
import LoaderComponent from "../LoaderComponent.vue";
import StatsUtils from "../../mixins/helpers/booking/StatsUtils.js";

export default {
    props: {
        grid: {
            required: false,
            type: Object,
            default: () => ({}),
        },
        filters: {
            required: true,
            type: Object,
        },
        hasRwg: {
            default: false,
            type: Boolean,
        },
    },
    mixins: [StatsUtils],
    data() {
        return {
            showGraph: false,
            nbPaxByOrigin: {
                title: {
                    text: this.$tl("labels.booking.stats.pax.title"),
                    left: "center",
                    textStyle: {
                        fontWeight: "normal",
                    },
                },
            },
            stats: {
                main: null,
                comparison: null,
            },
            currentHasRwg: this.hasRwg,
        };
    },
    computed: {
        hasEnabledGoogleReserve() {
            return this.$store.getters["widgets/getWidget"].enable_google_reserve || false;
        },
        nbResaAndPaxGridClasses() {
            return "p-1 " + (this.currentHasRwg ? "col-xl-3 col-lg-6" : "col-lg-4 col-md-6");
        },
        hasEnabledRwg() {
            return this.currentHasRwg || (this.isReserveWithGoogleEnabled() && this.hasEnabledGoogleReserve);
        },
        pieChartNbPaxByOrigin() {
            return [
                {
                    type: "pie",
                    data: this.seriesPieChartNbPaxByOrigin,
                    color: ["#5490ff", "#8c54ff", "#bc43e1"],
                },
            ];
        },
        seriesPieChartNbPaxByOrigin() {
            const stats = this.stats.main;
            return this.mapTransToSeries(stats);
        },
        pieChartNbPaxByOriginComparison() {
            return [
                {
                    type: "pie",
                    data: this.seriesPieChartNbPaxByOriginComparison,
                    color: ["#5490ff", "#8c54ff", "#bc43e1"],
                },
            ];
        },
        seriesPieChartNbPaxByOriginComparison() {
            const stats = this.stats.comparison;
            return this.mapTransToSeries(stats);
        },
        tooltipOrigin() {
            return {
                trigger: "item",
                formatter: (params) => {
                    const so = this.$tl("labels.booking.stats.charts.tooltip.so");
                    const nb = params.value;
                    const reservations = this.$tcl("labels.booking.stats.charts.tooltip.reservations", nb);
                    return `<b>${params.name}</b><br />${reservations}, ${so} ${params.percent}%<br/>`;
                },
            };
        },
    },
    methods: {
        fetchStats(abortControllerSignal = null, forComparison = false) {
            const filters = this.getFiltersQueryParams(forComparison);

            return this.httpGet(`/api/stats/reservations-pax-per-origin?${filters}`, {
                config: { signal: abortControllerSignal },
                handleReject: false,
            }).then((response) => {
                if (response !== false) {
                    this.stats[forComparison ? "comparison" : "main"] = response.data;
                }
            });
        },
        mapTransToSeries(stats) {
            const translationMap = {
                web: this.$tl("labels.booking.stats.reservations.web"),
                manual: this.$tl("labels.booking.stats.reservations.manual"),
                rwg: this.$tl("labels.booking.stats.reservations.rwg"),
            };

            return Object.keys(stats)
                .filter((key) => ["web", "manual", "rwg"].includes(key))
                .map((key) => ({
                    name: translationMap[key],
                    value: stats[key],
                }));
        },
    },
    components: {
        LoaderComponent,
        VChart,
        ArrowPercentEvolutionComponent,
    },
    updated() {
        this.currentHasRwg = this.hasRwg;
    },
};
</script>

<style scoped>
button.btn:disabled {
    cursor: default;
}

.chart {
    height: 450px;
}
</style>
