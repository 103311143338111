var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.immutableTooltip,
            expression: "immutableTooltip",
          },
        ],
        ref: "dropdown",
      },
      [
        _vm.current_tag_raw === _vm.RESERVATION_STATUS_CREATED.value
          ? _c(
              "div",
              [
                _c(
                  "ejs-tooltip",
                  {
                    staticClass: "d-inline",
                    attrs: {
                      content: _vm.translate_(
                        "labels.booking.reservations.actions.accept"
                      ),
                      cssClass: _vm.cssClass,
                      animation: _vm.tooltipAnimation,
                    },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success btn-sm btn-square",
                        attrs: {
                          disabled: !_vm.editable,
                          "data-status": _vm.RESERVATION_STATUS_VALIDATED.value,
                          "data-name": _vm.getReservationStatusLabel(
                            _vm.RESERVATION_STATUS_VALIDATED.value,
                            _vm.translate,
                            _vm.restaurant_id
                          ),
                          "data-class": _vm.getReservationStatusClass(
                            _vm.RESERVATION_STATUS_VALIDATED.value
                          ),
                        },
                        on: {
                          click: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "btn",
                                undefined,
                                $event.key,
                                undefined
                              )
                            )
                              return null
                            return _vm.changeStatus($event, false)
                          },
                        },
                      },
                      [
                        _c("feather", {
                          staticStyle: { "pointer-events": "none" },
                          attrs: { type: "check" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ejs-tooltip",
                  {
                    staticClass: "d-inline",
                    attrs: {
                      content: _vm.translate_(
                        "labels.booking.reservations.actions.refuse"
                      ),
                      cssClass: _vm.cssClass,
                      animation: _vm.tooltipAnimation,
                    },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-danger btn-sm text-white btn-square",
                        attrs: {
                          href: "javascript:",
                          disabled: !_vm.editable,
                          "data-status": "refused",
                          "data-name": "Refusée",
                          "data-class": "btn-default",
                        },
                        on: {
                          click: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "btn",
                                undefined,
                                $event.key,
                                undefined
                              )
                            )
                              return null
                            return _vm.changeStatus($event, false)
                          },
                        },
                      },
                      [
                        _c("feather", {
                          staticStyle: { "pointer-events": "none" },
                          attrs: { type: "x" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ejs-tooltip",
                  {
                    staticClass: "d-inline",
                    attrs: {
                      content: _vm.translate_(
                        "labels.booking.reservations.actions.acceptFootprint"
                      ),
                      cssClass: _vm.cssClass,
                      animation: _vm.tooltipAnimation,
                    },
                  },
                  [
                    _vm.isStripeEnabled &&
                    !_vm.reservation.option_bank &&
                    _vm.reservation.client &&
                    _vm.reservation.client.email &&
                    _vm.reservation.client.noshows > 0 &&
                    _vm.reservation.created_from !==
                      _vm.RESERVATION_ISSUER_GOOGLE_RESERVE.value
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-warning btn-sm btn-square text-white",
                            attrs: { disabled: !_vm.editable },
                            on: {
                              click: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "btn",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                )
                                  return null
                                return _vm.askFootprint.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("feather", {
                              staticStyle: { "pointer-events": "none" },
                              attrs: { type: "credit-card" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("drop-down", {
                  attrs: {
                    options: _vm.tagsFormatted,
                    "selected-option": _vm.selectedTag,
                    "btn-classes-string": `btn btn-sm btn-outline-secondary btn-circle resa-status ${_vm.current_tag_class} ${_vm.current_tag_raw}`,
                    "option-label": "text",
                    "option-value": "tag",
                  },
                  on: {
                    "option-changed": function ($event) {
                      return _vm.changeStatus($event, true)
                    },
                  },
                }),
              ],
              1
            ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }