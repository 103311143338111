var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("loader-component")
        : _vm.loyaltyConfigs !== null
        ? [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("h6", [_vm._v(_vm._s(_vm.$tl("labels.rules")))]),
                  _vm._v(" "),
                  _vm._l(_vm.loyaltyConfigs, function (loyaltyConfig, index) {
                    return _c(
                      "div",
                      { key: loyaltyConfig.level },
                      [
                        _c("div", [
                          _c(
                            "label",
                            {
                              staticClass: "container-box mr-0",
                              staticStyle: {
                                width: "initial",
                                "margin-top": "5px",
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: loyaltyConfig.enabled,
                                    expression: "loyaltyConfig.enabled",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  disabled: !_vm.hasRightToEdit,
                                },
                                domProps: {
                                  checked: Array.isArray(loyaltyConfig.enabled)
                                    ? _vm._i(loyaltyConfig.enabled, null) > -1
                                    : loyaltyConfig.enabled,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = loyaltyConfig.enabled,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            loyaltyConfig,
                                            "enabled",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            loyaltyConfig,
                                            "enabled",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(loyaltyConfig, "enabled", $$c)
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "checkmark mr-1",
                                class: { disabled: !_vm.hasRightToEdit },
                                staticStyle: { "margin-top": "0px" },
                              }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.getClientLoyaltyLevelLabel(
                                      loyaltyConfig.level
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          loyaltyConfig.enabled
                            ? _c("div", { staticClass: "d-inline-block" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.$tl("labels.from")) +
                                    "\n                            "
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: loyaltyConfig.starts_from,
                                      expression: "loyaltyConfig.starts_from",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  staticClass:
                                    "form-control d-inline-block ml-1 mr-1 p-1",
                                  staticStyle: {
                                    width: "50px",
                                    height: "30px",
                                  },
                                  attrs: {
                                    type: "number",
                                    disabled: !_vm.hasRightToEdit,
                                    min: 2,
                                  },
                                  domProps: {
                                    value: loyaltyConfig.starts_from,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        loyaltyConfig,
                                        "starts_from",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.startsFromLabel) +
                                    "\n                        "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("show-errors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors.form,
                            "error-key": `loyaltyConfigs.${index}.level`,
                          },
                        }),
                        _vm._v(" "),
                        _c("show-errors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors.form,
                            "error-key": `loyaltyConfigs.${index}.starts_from`,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm.moduleEnum.value === _vm.MODULE_TYPE_BOOKING.value
                ? _c("div", { staticClass: "col-lg-6" }, [
                    _c("h6", [_vm._v(_vm._s(_vm.$tl("labels.benefits")))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("div", [
                          _c(
                            "label",
                            {
                              staticClass: "container-box mr-0",
                              staticStyle: { width: "initial" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.booking.disable_footprint_for_vip,
                                    expression:
                                      "booking.disable_footprint_for_vip",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  disabled: !_vm.hasRightToEdit,
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.booking.disable_footprint_for_vip
                                  )
                                    ? _vm._i(
                                        _vm.booking.disable_footprint_for_vip,
                                        null
                                      ) > -1
                                    : _vm.booking.disable_footprint_for_vip,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.booking.disable_footprint_for_vip,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.booking,
                                            "disable_footprint_for_vip",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.booking,
                                            "disable_footprint_for_vip",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.booking,
                                        "disable_footprint_for_vip",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "checkmark mr-1",
                                class: { disabled: !_vm.hasRightToEdit },
                                staticStyle: { "margin-top": "0px" },
                              }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.clients.disableFootprintVIP"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("show-errors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors.form,
                            "error-key": "disable_footprint_for_vip",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("div", [
                          _c(
                            "label",
                            {
                              staticClass: "container-box mr-0",
                              staticStyle: { width: "initial" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.disableFootprintOnLoyaltyLevel,
                                    expression:
                                      "disableFootprintOnLoyaltyLevel",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  disabled:
                                    _vm.activeLoyaltyLevels.length < 1 ||
                                    !_vm.hasRightToEdit,
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.disableFootprintOnLoyaltyLevel
                                  )
                                    ? _vm._i(
                                        _vm.disableFootprintOnLoyaltyLevel,
                                        null
                                      ) > -1
                                    : _vm.disableFootprintOnLoyaltyLevel,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.disableFootprintOnLoyaltyLevel,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.disableFootprintOnLoyaltyLevel =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.disableFootprintOnLoyaltyLevel =
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.disableFootprintOnLoyaltyLevel = $$c
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "checkmark mr-1",
                                class: { disabled: !_vm.hasRightToEdit },
                                staticStyle: { "margin-top": "0px" },
                              }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.clients.disableFootprintLoyaltyLevel"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.disableFootprintOnLoyaltyLevel
                            ? _c("div", { staticClass: "d-inline-block" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.$tl("labels.from")) +
                                    "\n                            "
                                ),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.booking
                                            .disable_footprint_from_loyalty_level,
                                        expression:
                                          "booking.disable_footprint_from_loyalty_level",
                                      },
                                    ],
                                    staticClass:
                                      "d-inline-block custom-select ml-1 mb-0 w-auto",
                                    attrs: { disabled: !_vm.hasRightToEdit },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.booking,
                                          "disable_footprint_from_loyalty_level",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.ALL_CLIENT_LOYALTIES,
                                    function (level) {
                                      return _c(
                                        "option",
                                        {
                                          key: level.value,
                                          domProps: { value: level.value },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.getClientLoyaltyLevelLabel(
                                                  level.value
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("show-errors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors.form,
                            "error-key": "disable_footprint_from_loyalty_level",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-success btn-circle",
                    attrs: { type: "button", disabled: !_vm.hasRightToEdit },
                    on: { click: _vm.saveLoyaltyConfigs },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$tl("labels.form.actions.save")) +
                        "\n                "
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }