<template>
    <div v-if="canCreatePromoCode" class="row">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto">
            <router-link class="btn btn-circle btn-sm btn-primary mt-3" :to="{ name: 'admin.promo_codes.list' }">
                <feather type="arrow-left" />
                Retour à la liste des codes promos
            </router-link>
            <h2 class="my-4">Création d'un code promo</h2>
            <form
                style="min-height: 420px; box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.13)"
                class="p-3 justify-content-between flex-column d-flex"
                @submit.prevent="handleForm(step)">
                <horizontal-stepper ref="stepper" :steps="steps"></horizontal-stepper>
                <transition :name="transitionName" mode="out-in">
                    <keep-alive>
                        <component
                            :is="getCurrentStepComponent"
                            :errors="errors"
                            @update-form="form = Object.assign({}, form, $event)"
                            :parent-form="form" />
                    </keep-alive>
                </transition>
                <div class="buttons-container">
                    <button
                        v-if="step > 0"
                        :disabled="isLoading"
                        type="button"
                        class="btn btn-circle btn-secondary"
                        @click.prevent.stop="changeStep(step - 1)">
                        Précédent
                    </button>
                    <button
                        v-if="step < maxStep"
                        :disabled="isLoading"
                        type="button"
                        class="btn btn-circle btn-success ml-auto"
                        @click.prevent.stop="changeStep(step + 1)">
                        Suivant
                    </button>
                    <button v-if="step === maxStep" :disabled="isLoading" type="submit" class="btn btn-circle btn-success">
                        Créer le code promo
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import InfosStep from "../../../components/Admin/PromoCodes/Create/InfosStep.vue";
import DurationStep from "../../../components/Admin/PromoCodes/Create/DurationStep.vue";
import AmountStep from "../../../components/Admin/PromoCodes/Create/AmountStep.vue";
import RestrictStep from "../../../components/Admin/PromoCodes/Create/RestrictStep.vue";
import OverviewStep from "../../../components/Admin/PromoCodes/Create/OverviewStep.vue";
import HorizontalStepper from "vue-stepper/src/HorizontalStepper.vue";

export default {
    components: {
        LoaderComponent,
        InfosStep,
        DurationStep,
        AmountStep,
        RestrictStep,
        OverviewStep,
        HorizontalStepper,
    },
    data() {
        return {
            isLoading: false,
            transitionName: "slide-x-left-fade",
            form: {},
            errors: {
                form: null,
            },
            step: 0,
            steps: [
                {
                    icon: "format_list_bulleted",
                    name: "infos-step",
                    title: "Informations",
                },
                {
                    icon: "date_range",
                    name: "duration-step",
                    title: "Durée & Validité",
                },
                {
                    icon: "euro_symbol",
                    name: "amount-step",
                    title: "Montant",
                },
                {
                    icon: "person",
                    name: "restrict-step",
                    title: "Restriction",
                },
                {
                    icon: "done",
                    name: "overview-step",
                    title: "Récapitulatif",
                },
            ],
        };
    },
    computed: {
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        canCreatePromoCode() {
            return this.rights.includes("admin.promocodes.create");
        },
        maxStep() {
            return this.steps.length - 1;
        },
        getCurrentStepComponent() {
            return this.steps[this.step].name;
        },
    },
    created() {
        this.STEP_INFOS = "infos";
        this.STEP_DURATION = "duration";
        this.STEP_AMOUNT = "amount";
        this.STEP_RESTRICT = "restrict";
        this.STEP_OVERVIEW = "overview";
        this.stepKeys = [this.STEP_INFOS, this.STEP_DURATION, this.STEP_AMOUNT, this.STEP_RESTRICT, this.STEP_OVERVIEW];
    },
    watch: {
        step(newValue, oldValue) {
            this.transitionName = newValue >= oldValue ? "slide-x-left-fade" : "slide-x-right-fade";
            this.$refs.stepper.activateStep(newValue);
        },
    },
    methods: {
        handleForm(step) {
            const isLastStep = this.stepKeys[this.step] === this.STEP_OVERVIEW;

            if (isLastStep) {
                if (!confirm("Confirmez vous la création du code promo ? Tout changement sera impossible.")) {
                    return;
                }
            }

            this.isLoading = true;
            this.errors = {
                form: null,
            };

            this.httpPost(`/api/admin/promo_codes?step=${this.stepKeys[this.step]}`, this.form, { handleResolve: isLastStep })
                .then((response) => {
                    if (response !== false) {
                        if (isLastStep) {
                            this.$router.push({ name: "admin.promo_codes.list" });
                        } else {
                            this.step = step;
                        }
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        changeStep(step) {
            if (step < 0) {
                step = 0;
            }
            if (step > this.maxStep) {
                step = this.maxStep;
            }

            if (step > this.step) {
                this.handleForm(step);
            } else {
                this.step = step;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.buttons-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.slide-x-left-fade-enter-active {
    transition: all 0.3s ease;
}

.slide-x-left-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-x-left-fade-enter,
.slide-x-left-fade-leave-to {
    transform: translateX(-10px);
    opacity: 0;
}
.slide-x-right-fade-enter-active {
    transition: all 0.3s ease;
}

.slide-x-right-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-x-right-fade-enter,
.slide-x-right-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
</style>
