export default {
    common: {
        services: "Vous devez créer des services au préalable pour pouvoir modifier ce paramètre.",
    },
    roles: {
        kitchen:
            "Le compte cuisine est destiné à être le compte accessible depuis une tablette mise à disposition en cuisine. Elle permet d’accéder à la liste des réservations, menus choisis par les clients lors de la réservation, commentaires clients et notes internes (allergènes, intolérances...).",
    },
    booking: {
        services: {
            maxPaxIfRotation: "Si la rotation des tables est activée, il s'agit du nombre de couverts maximum en temps réel",
            enableBack: "Pour réactiver un service, rendez-vous sur votre {dashboard}.",
            cantDelete: "Vous ne pouvez pas supprimer ce service car des réservations futures y sont associées",
            hasFutureReservations: "Ce service fait référence à plusieurs réservations à venir.",
            changeSeatingPlan: [
                "Si vous validez le changement de plan de salle sur ce service, les réservations futures ne seront plus liées aux tables de l'ancien plan de salle, et les préférences de salle ne seront pas conservées.",
                "L'algorithme tentera de replacer vos réservations à la prochaine prise de réservation.",
                "Si toutefois des réservations ne peuvent pas être placées, la prise de réservation sera momentanément suspendue.",
                "Nous vous conseillons vivement de vérifier vos prochains services.",
            ],
        },
        reservations: {
            saveCauseSurbooking: "En validant cette réservation vous serez en surbooking pour ce service.",
            menus: {
                unique: "Un menu unique est demandé pour l'ensemble de la table",
            },
            add: {
                changeSlotSelectedTables: "Merci de délier les tables afin de pouvoir choisir un autre slot",
                willBePlacedAuto:
                    "La réservation sera placée automatiquement sur votre plan de salle, choisissez une table uniquement si vous souhaitez attribuer une table spécifique à votre client.",
            },
            edit: {
                date: "Attention, modifier la date supprimera automatiquement la table sélectionnée.",
                nbPers: "Attention, modifier le nombre d'adultes supprimera automatiquement la table sélectionnée.",
                nbChildren: "Attention, modifier le nombre d'enfants supprimera automatiquement la table sélectionnée.",
                slot: "Attention, modifier le créneau de réservation supprimera automatiquement la table sélectionnée.",
                changeRestaurant: "Attention, veuillez supprimer la table sélectionnée pour déplacer la réservation vers un autre établissement.",
                duration: "Pour modifier la durée de la réservation, vous devez d'abord délier la table sélectionnée.",
            },
        },
        seatingPlan: {
            delete: "Vous devez délier le plan de salle de toutes les plages horaires pour le supprimer",
        },
    },
};
