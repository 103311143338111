var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex align-items-center w-100 none-mobile" },
      [
        _c(
          "button",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.getTooltip(_vm.$tl("labels.pager.firstPage")),
                expression: "getTooltip($tl('labels.pager.firstPage'))",
              },
            ],
            staticClass: "btn btn-sm p-0",
            attrs: { type: "button", disabled: _vm.currentPageComputed < 2 },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.currentPageComputed = 1
              },
            },
          },
          [_c("feather", { attrs: { type: "skip-back" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.getTooltip(_vm.$tl("labels.pager.previousPage")),
                expression: "getTooltip($tl('labels.pager.previousPage'))",
              },
            ],
            staticClass: "btn btn-sm p-0",
            attrs: { type: "button", disabled: _vm.currentPageComputed < 2 },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                --_vm.currentPageComputed
              },
            },
          },
          [_c("feather", { attrs: { type: "chevron-left" } })],
          1
        ),
        _vm._v(" "),
        _vm.startPageNumbersRange > 1
          ? _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.getTooltip(_vm.$tl("labels.pager.previous")),
                    expression: "getTooltip($tl('labels.pager.previous'))",
                  },
                ],
                staticClass: "btn btn-sm p-0",
                on: {
                  click: function ($event) {
                    _vm.currentPageComputed = _vm.startPageNumbersRange - 1
                  },
                },
              },
              [_c("feather", { attrs: { type: "more-horizontal" } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.pageNumbersRange, function (numPage) {
          return _c(
            "button",
            {
              key: numPage,
              staticClass: "btn btn-sm btn-circle py-1 px-2 mx-1",
              class: { "btn-success": _vm.currentPageComputed === numPage },
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.currentPageComputed = numPage
                },
              },
            },
            [_vm._v("\n            " + _vm._s(numPage) + "\n        ")]
          )
        }),
        _vm._v(" "),
        _vm.endPageNumbersRange < _vm.nbPages
          ? _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.getTooltip(_vm.$tl("labels.pager.next")),
                    expression: "getTooltip($tl('labels.pager.next'))",
                  },
                ],
                staticClass: "btn btn-sm p-0",
                on: {
                  click: function ($event) {
                    _vm.currentPageComputed = _vm.endPageNumbersRange
                  },
                },
              },
              [_c("feather", { attrs: { type: "more-horizontal" } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.getTooltip(_vm.$tl("labels.pager.nextPage")),
                expression: "getTooltip($tl('labels.pager.nextPage'))",
              },
            ],
            staticClass: "btn btn-sm p-0",
            attrs: {
              type: "button",
              disabled: _vm.currentPageComputed > _vm.nbPages - 1,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                ++_vm.currentPageComputed
              },
            },
          },
          [_c("feather", { attrs: { type: "chevron-right" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.getTooltip(_vm.$tl("labels.pager.lastPage")),
                expression: "getTooltip($tl('labels.pager.lastPage'))",
              },
            ],
            staticClass: "btn btn-sm p-0",
            attrs: {
              type: "button",
              disabled: _vm.currentPageComputed > _vm.nbPages - 1,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.currentPageComputed = _vm.nbPages
              },
            },
          },
          [_c("feather", { attrs: { type: "skip-forward" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.nbResultsPerPageComputed,
                expression: "nbResultsPerPageComputed",
              },
            ],
            staticClass: "custom-select w-auto mx-2 mb-1",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.nbResultsPerPageComputed = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          _vm._l(_vm.availableNbResultsPerPage, function (nbResult) {
            return _c(
              "option",
              { key: nbResult.value, domProps: { value: nbResult.value } },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(nbResult.label) +
                    "\n            "
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex justify-content-between w-100" }, [
          _c(
            "p",
            { staticClass: "my-auto", staticStyle: { "min-width": "150px" } },
            [_vm._v(_vm._s(_vm.$tl("labels.pager.nbResultsPerPage")))]
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "my-auto", staticStyle: { "min-width": "185px" } },
            [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$t("labels.pager.currentPageInfo", {
                      currentPage: _vm.currentPageComputed,
                      nbPages: _vm.nbPages,
                    })
                  ) +
                  "\n                " +
                  _vm._s(
                    _vm.$t("labels.pager.nbResults", {
                      nbResults: _vm.nbResults,
                    })
                  ) +
                  "\n            "
              ),
            ]
          ),
        ]),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex w-100 none-desk" }, [
      _c(
        "button",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.getTooltip(_vm.$tl("labels.pager.firstPage")),
              expression: "getTooltip($tl('labels.pager.firstPage'))",
            },
          ],
          staticClass: "btn btn-sm p-0",
          attrs: { type: "button", disabled: _vm.currentPageComputed < 2 },
          on: {
            click: function ($event) {
              _vm.currentPageComputed = 1
            },
          },
        },
        [_c("feather", { attrs: { type: "skip-back" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.getTooltip(_vm.$tl("labels.pager.previousPage")),
              expression: "getTooltip($tl('labels.pager.previousPage'))",
            },
          ],
          staticClass: "btn btn-sm p-0",
          attrs: { type: "button", disabled: _vm.currentPageComputed < 2 },
          on: {
            click: function ($event) {
              --_vm.currentPageComputed
            },
          },
        },
        [_c("feather", { attrs: { type: "chevron-left" } })],
        1
      ),
      _vm._v(" "),
      _c("div", [
        _c("p", { staticClass: "my-auto mx-2" }, [
          _vm._v(
            "\n                " +
              _vm._s(
                _vm.$t("labels.pager.currentPageInfo", {
                  currentPage: _vm.currentPageComputed,
                  nbPages: _vm.nbPages,
                })
              ) +
              "\n            "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.getTooltip(_vm.$tl("labels.pager.nextPage")),
              expression: "getTooltip($tl('labels.pager.nextPage'))",
            },
          ],
          staticClass: "btn btn-sm p-0",
          attrs: {
            type: "button",
            disabled: _vm.currentPageComputed > _vm.nbPages - 1,
          },
          on: {
            click: function ($event) {
              ++_vm.currentPageComputed
            },
          },
        },
        [_c("feather", { attrs: { type: "chevron-right" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.getTooltip(_vm.$tl("labels.pager.lastPage")),
              expression: "getTooltip($tl('labels.pager.lastPage'))",
            },
          ],
          staticClass: "btn btn-sm p-0",
          attrs: {
            type: "button",
            disabled: _vm.currentPageComputed > _vm.nbPages - 1,
          },
          on: {
            click: function ($event) {
              _vm.currentPageComputed = _vm.nbPages
            },
          },
        },
        [_c("feather", { attrs: { type: "skip-forward" } })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }