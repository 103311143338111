<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Choisir un moyen de paiement</h5>
        <div slot="body">
            <p>Veuillez choisir le mode de paiement utilisé.</p>
            <small class="text-danger" v-if="is_online">La demande de paiement en cours sera annulée.</small>
            <show-errors :errors="errors" class="d-block mt-1 mb-1" errorKey="*" />
            <ul>
                <li v-for="(paymentMethod, index) in paymentMethods" :key="index">
                    <label class="container-box">
                        <input type="checkbox" :value="paymentMethod.value" readonly v-model="paymentMethod.checked" />
                        <span class="checkmark"></span> {{ paymentMethod.label }}
                    </label>
                    <template v-if="paymentMethod.checked && nbChecked > 1">
                        <input
                            type="number"
                            placeholder="Montant *"
                            required
                            class="form-control d-inline-block w-auto mr-2"
                            v-model="paymentMethod.amount" />€
                    </template>
                </li>
            </ul>
            <small class="text-danger d-block" v-if="error">{{ error }}</small>
        </div>
        <div slot="footer" class="d-flex">
            <button
                :disabled="isProcessing"
                type="button"
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                @click="$emit('close')">
                Annuler
            </button>
            <button :disabled="isProcessing" type="button" class="btn btn-sm btn-success btn-circle ml-2" @click="pay">
                Valider
            </button>
        </div>
    </modal>
</template>

<script>
import ShowErrors from "../../../errors/ShowErrors";
export default {
    name: "CcCommandPayManuallyModal",
    data() {
        return {
            error: null,
            isProcessing: false,
            paymentMethods: [
                { value: "cash", label: "Espèces", checked: false, amount: null },
                { value: "check", label: "Chèque", checked: false, amount: null },
                { value: "card", label: "Carte bancaire", checked: false, amount: null },
                { value: "meal_voucher", label: "Titre restaurant", checked: false, amount: null },
            ],
        };
    },
    computed: {
        nbChecked() {
            return this.paymentMethods.filter((p) => p.checked).length;
        },
    },
    props: {
        is_online: {
            default: true,
        },
        errors: {
            default: null,
        },
    },
    methods: {
        startProcessing() {
            this.isProcessing = true;
        },
        stopProcessing() {
            this.isProcessing = false;
        },
        pay() {
            if (this.nbChecked == 0) {
                this.error = "Merci de sélectionner le moyen de paiement utilisé";
                return;
            }
            this.$emit(
                "pay",
                this.paymentMethods.filter((p) => p.checked)
            );
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        ShowErrors,
    },
};
</script>
