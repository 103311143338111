<template>
    <modal-rounded-no-header @close="closeFromModal">
        <div slot="body">
            <p class="text-center">
                <span class="d-block" v-for="(tmpMsg, index) in $tl('infos.booking.seatingPlan.needed')" :key="index">
                    {{ tmpMsg }}
                </span>
            </p>
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button type="button" class="modal-default-button btn btn-secondary btn-circle" @click="$emit('close')">
                {{ closeButtonTitle }}
            </button>
            <button
                type="button"
                class="btn btn-success btn-circle d-flex align-items-center justify-content-between mr-2"
                @click="goToSeatingPlanBuilder">
                <feather type="plus" class="feather-plus mr-1"></feather>
                {{ $tl("labels.booking.seatingPlan.add") }}
            </button>
        </div>
    </modal-rounded-no-header>
</template>

<script>
import ModalRoundedNoHeader from "../base/ModalRoundedNoHeader.vue";

export default {
    props: {
        closeButtonTitle: {
            required: true,
            type: String,
        },
    },
    components: {
        ModalRoundedNoHeader,
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e = null) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                if (e) {
                    e.stopPropagation();
                }
                this.close();
            }
        },
        goToSeatingPlanBuilder() {
            this.$router.push({
                name: "booking.restaurants.settings.seatingPlan",
                params: { restaurant_id: this.$route.params.restaurant_id, seating_plan_id: "add" },
            });
        },
    },
};
</script>
