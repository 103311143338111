export default {
    data() {
        return {
            PAYMENT_PENDING: {
                label: "En attente",
                value: "pending",
                class: "text-warning",
            },
            PAYMENT_PAID: {
                label: "Payé",
                value: "paid",
                class: "text-success",
            },
            PAYMENT_CAPTURED: {
                label: "Payé",
                value: "captured",
                class: "text-success",
            },
            PAYMENT_CAPTURABLE: {
                label: "Payé",
                value: "capturable",
                class: "text-success",
            },
            PAYMENT_REFUNDED: {
                label: "Remboursé",
                value: "refunded",
                class: "text-warning",
            },
            PAYMENT_PARTIALLY_REFUNDED: {
                label: "Remboursé partiellement",
                value: "partially_refunded",
                class: "text-warning",
            },
            PAYMENT_CANCELED: {
                label: "Annulé",
                value: "canceled",
                class: "text-danger",
            },
        };
    },
    methods: {
        getPaymentStatusLabel(value) {
            const found = this.ALL_PAYMENT_STATUS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
        getPaymentStatusClass(value) {
            const found = this.ALL_PAYMENT_STATUS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.class;
            return "";
        },
    },
    computed: {
        ALL_PAYMENT_STATUS() {
            return [
                this.PAYMENT_PENDING,
                this.PAYMENT_PAID,
                this.PAYMENT_CAPTURABLE,
                this.PAYMENT_CAPTURED,
                this.PAYMENT_REFUNDED,
                this.PAYMENT_PARTIALLY_REFUNDED,
                this.PAYMENT_CANCELED,
            ];
        },
        PAID_STATUS() {
            return [this.PAYMENT_PAID, this.PAYMENT_CAPTURABLE, this.PAYMENT_CAPTURED];
        },
        PAID_STATUS_WITH_REFUNDED() {
            return [
                this.PAYMENT_PAID,
                this.PAYMENT_CAPTURABLE,
                this.PAYMENT_CAPTURED,
                this.PAYMENT_REFUNDED,
                this.PAYMENT_PARTIALLY_REFUNDED,
            ];
        },
    },
};
