var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dropdown dropdown-lang align-content-end" },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-outline-secondary dropdown-toggle",
          staticStyle: {
            "text-transform": "capitalize",
            "border-color": "#ededed",
          },
          attrs: {
            "data-test-id": "btn-change_lang",
            disabled: _vm.disabled,
            type: "button",
            id: "dropdownMenu2",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false",
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.shouldTranslateLabel
                  ? _vm.$tl(`labels.langs.${_vm.userLang}`)
                  : _vm.getLangLabel(_vm.userLang)
              ) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu dropdown-menu-right shadow",
          staticStyle: {
            transform: "translate3d(-69px, 29px, 0px)",
            "min-width": "100px",
          },
          attrs: { "aria-labelledby": "dropdownMenu2" },
        },
        _vm._l(_vm.ALL_LANGS, function (lang) {
          return _c(
            "button",
            {
              key: lang.value,
              staticClass: "dropdown-item d-flex pl-1",
              attrs: {
                type: "button",
                "data-test-id": `btn-change_lang-${lang.value}`,
                disabled: _vm.disabled,
              },
              on: {
                click: function ($event) {
                  return _vm.setUserLang(lang.value)
                },
              },
            },
            [
              _c("country-flag", {
                staticClass: "dropdown-item",
                attrs: { country: lang.countryFlag },
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "name-lang" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.shouldTranslateLabel
                        ? _vm.$tl(`labels.langs.${lang.value}`)
                        : lang.dropdownLabel
                    )
                  ),
                  _vm._t("default", null, null, { lang }),
                ],
                2
              ),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }