var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "border-light b-radius-20 p-4 mb-3" },
            [
              _c("show-emails-campaign-pack", {
                attrs: { emailsCampaignPack: _vm.emailsCampaignPack },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("Facturation", {
        attrs: { getInvoiceUrl: "/api/campaigns/emailsCampaigns/invoice" },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
          paid: _vm.paid,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }