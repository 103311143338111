<template>
    <div class="pb-5 pt-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Codes promotionnels</h5>
                    <button
                        :disabled="!has_right_to_create_promo_code"
                        @click="showAddClickAndCollectPromoCodeModal = null"
                        class="btn btn-sm btn-success mr-1 btn-circle">
                        <feather type="plus"></feather>
                        <span>Ajouter un code promotionnel</span>
                    </button>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <span class="text-danger d-block" v-else-if="error">{{ error }}</span>
        <template v-else>
            <div class="row m-0 mb-3">
                <div class="col-12">
                    <table v-if="ccPromoCodes.length > 0" class="capitalize table table-sm table-striped border-bottom">
                        <thead class="border-bottom">
                            <tr>
                                <th>Code promo</th>
                                <th>Type</th>
                                <th>Montant</th>
                                <th>Date d'activation</th>
                                <th>Limite / pers</th>
                                <th>Limite totale</th>
                                <th>Montant min.</th>
                                <th>Nombre d'utilisations</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="promoCode in ccPromoCodes" :key="promoCode.id">
                                <td>
                                    {{ promoCode.name }}
                                </td>
                                <td>
                                    {{ getPromoCodeTypeLabel(promoCode.type) }}
                                </td>
                                <td>
                                    <span v-if="promoCode.type === PROMO_CODE_TYPE_AMOUNT.value">{{ formatCurrency(promoCode.amount / 100) }}</span>
                                    <span v-else-if="promoCode.type === PROMO_CODE_TYPE_PERCENT.value">{{ promoCode.amount }}%</span>
                                </td>
                                <td>
                                    {{ promoCode.starts_on ? moment(promoCode.starts_on).format("DD/MM/Y") : "--" }} -
                                    {{ promoCode.ends_on ? moment(promoCode.ends_on).format("DD/MM/Y") : "--" }}
                                </td>
                                <td>{{ promoCode.limit_per_pers || "--" }}</td>
                                <td>{{ promoCode.total_limit || "--" }}</td>
                                <td>
                                    {{ promoCode.min_amount ? formatCurrency(promoCode.min_amount / 100) : "--" }}
                                </td>
                                <td>{{ promoCode.nb_used }}</td>
                                <td>
                                    <button
                                        class="btn btn-sm btn-success btn-square"
                                        v-tooltip="getTooltip('Éditer')"
                                        :disabled="!has_right_to_update_promo_code"
                                        @click="showAddClickAndCollectPromoCodeModal = promoCode.id">
                                        <svg
                                            class="feather feather-edit sc-dnqmqq jxshSx"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            aria-hidden="true">
                                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                                        </svg>
                                    </button>
                                    <button
                                        :disabled="!has_right_to_delete_promo_code"
                                        @click="deletePromoCode(promoCode.id)"
                                        class="btn btn-sm btn-danger btn-square"
                                        v-tooltip="getTooltip('Supprimer')">
                                        <svg
                                            class="feather feather-trash-2 sc-dnqmqq jxshSx"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            aria-hidden="true">
                                            <polyline points="3 6 5 6 21 6"></polyline>
                                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                            <line x1="10" y1="11" x2="10" y2="17"></line>
                                            <line x1="14" y1="11" x2="14" y2="17"></line>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="mb-0" v-else>Vous n'avez pas encore créé de code promotionnel.</p>
                </div>
            </div>
        </template>
        <add-click-and-collect-promo-code-modal
            v-if="showAddClickAndCollectPromoCodeModal !== false"
            :promoCodeId="showAddClickAndCollectPromoCodeModal"
            @close="showAddClickAndCollectPromoCodeModal = false"
            @saved="onPromoCodeSaved" />
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import PromoCodeTypeEnum from "../../../mixins/enums/click_and_collect/PromoCodeTypeEnum";
import AddClickAndCollectPromoCodeModal from "../../../components/Modals/clickAndCollect/settings/addClickAndCollectPromoCodeModal";

export default {
    data() {
        return {
            loading: false,
            error: null,
            ccPromoCodes: [],
            showAddClickAndCollectPromoCodeModal: false,
            moment,
        };
    },
    mixins: [PromoCodeTypeEnum],
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_promo_code() {
            return this.rights.includes("click_and_collect.products.create");
        },
        has_right_to_update_promo_code() {
            return this.rights.includes("click_and_collect.products.update");
        },
        has_right_to_delete_promo_code() {
            return this.rights.includes("click_and_collect.products.delete");
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        onPromoCodeSaved() {
            this.showAddClickAndCollectPromoCodeModal = false;
            this.fetchData();
        },
        fetchData() {
            this.loading = true;
            this.error = null;

            axios
                .get(`/api/click_and_collect/${this.restaurant_id}/promo_codes?include=nb_used`)
                .then((response) => {
                    this.loading = false;
                    this.ccPromoCodes = response.data.data;
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                    else this.error = "Une erreur est survenue";
                });
        },
        deletePromoCode(promoCodeId) {
            if (!this.has_right_to_delete_promo_code) return;
            if (!confirm("Êtes vous sûr de vouloir supprimer ce code promotionnel ?")) return;

            this.loading = true;
            axios
                .delete(`/api/click_and_collect/${this.restaurant_id}/promo_codes/${promoCodeId}`)
                .then((response) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.fetchData();
                })
                .catch((error) => {
                    this.loading = false;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
    },
    created() {
        this.fetchData();
    },
    components: {
        LoaderComponent,
        AddClickAndCollectPromoCodeModal,
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
