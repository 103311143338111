<template>
    <div class="row">
        <div class="col-12 p-5">
            <ChooseCardPaymentSystem
                class="border-light b-radius-20 p-4"
                :prefered_payment_system="restaurant.prefered_payment_system"
                :restaurantId="restaurant_id"
                @set-prefered-payment-system="restaurant.prefered_payment_system = $event"
                @done="$emit('done')" />
            <div class="row m-0 mt-3">
                <p class="w-100">{{ $tl("labels.form.optionalStep") }}</p>
                <button type="button" class="btn btn-secondary btn-circle mr-2" @click="$emit('back')">
                    {{ $tl("labels.form.actions.back") }}
                </button>
                <button type="button" class="btn btn-secondary btn-circle" @click="$emit('done')">
                    {{ $tl("labels.form.actions.next") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import StripeConnection from "../../../components/Default/Restaurants/StripeConnection";
import ChooseCardPaymentSystem from "../../../components/Default/Restaurants/ChooseCardPaymentSystem";

export default {
    props: {
        restaurant_id: {
            required: true,
        },
        restaurant: {
            type: Object,
            required: true,
        },
    },
    components: {
        StripeConnection,
        ChooseCardPaymentSystem,
    },
};
</script>
