<template>
    <div class="mt-4">
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div v-if="formErrors" class="alert alert-danger">
                    <p v-for="(error, index) in formErrors" :key="index">
                        {{ error }}
                    </p>
                </div>

                <div v-if="formSuccess" class="alert alert-success">
                    {{ formSuccess }}
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="border-light b-radius-20 p-4">
                            <img class="img-connect-serv" src="/images/Stripe-Logo-blue.png" />

                            <div v-if="can_edit_stripe">
                                <div>
                                    <div v-if="restaurant.stripe_client_id" class="mb-3 id-stripe">
                                        {{ $tl("labels.form.psp.stripe.clientID") }} {{ restaurant.stripe_client_id }}<br />
                                        {{ $tl("labels.form.psp.stripe.clientEmail") }}
                                        {{ restaurant.stripe_client_email }}<br />
                                    </div>
                                    <div class="mb-3" v-else-if="stripe_connect_url">
                                        <stripe-connect-button
                                            :restaurant_id="restaurant_id"
                                            @error="connectError"
                                            @success="connectSuccess"
                                            @capabilities-error="connectCapabilitiesError" />
                                    </div>
                                    <p class="notice-text m-0">
                                        {{ $tl("labels.form.psp.stripe.description1") }}
                                    </p>
                                    <p class="notice-text m-0">
                                        {{ $tl("labels.form.psp.stripe.description2") }}
                                    </p>
                                    <p class="notice-text" v-html="$tl('labels.form.psp.stripe.description3')"></p>
                                    <div v-if="restaurant.stripe_client_id" class="mt-4">
                                        <button
                                            :disabled="!has_right_to_update_restaurant"
                                            @click="logoutStripe"
                                            class="btn btn-sm btn-warning btn-circle text-white">
                                            {{ $tl("labels.form.psp.stripe.disconnectAccount") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <capabilities-error-modal v-if="showCapabilitiesErrorModal" @close="showCapabilitiesErrorModal = false" :params="connectErrorParams" />
    </div>
</template>

<script>
import axios from "axios";
import StripeConnectButton from "../../Buttons/StripeConnectButton.vue";
import LoaderComponent from "../../LoaderComponent.vue";
import CapabilitiesErrorModal from "../../Modals/stripe_connect/CapabilitiesErrorModal";

export default {
    data() {
        return {
            loading: false,
            error: null,
            formLoading: null,
            formSuccess: null,
            formErrors: null,
            stripe_connect_url: null,
            restaurant: {
                id: null,
                enable_footprint: "",
                footprint_min_pers: "",
                amount_noshow: "",
                stripe_client_id: "",
            },
            can_edit_stripe: false,
            connectErrorParams: null,
            showCapabilitiesErrorModal: false,
        };
    },
    props: {
        restaurant_id: {
            default: null,
        },
    },
    methods: {
        fetchData() {
            this.error = this.restaurant = null;
            this.loading = true;

            // control that user can edit stripe infos
            axios
                .get(`/api/restaurants/${this.restaurantId}/stripe/control`)
                .then((response) => {
                    this.can_edit_stripe = true;

                    // load stripe data
                    axios
                        .get(`/api/restaurants/${this.restaurantId}/stripe`)
                        .then((response) => {
                            this.restaurant = response.data;
                            if (!this.restaurant.stripe_client_id) {
                                axios
                                    .get(`/api/restaurants/${this.restaurantId}/stripe/connectUrl`)
                                    .then((response) => {
                                        this.stripe_connect_url = response.data;
                                        this.loading = false;
                                    })
                                    .catch((error) => {
                                        this.loading = false;
                                        this.error = error.response.data.message || error.message;
                                    });
                            } else {
                                this.loading = false;
                            }
                        })
                        .catch((error) => {
                            this.error = error.response.data.message || error.message;
                            if (this.error === "Unauthenticated.") {
                                document.location.reload(true);
                            } else {
                                this.loading = false;
                            }
                        });
                })
                .catch((error) => {
                    this.can_edit_stripe = false;
                    this.loading = false;
                });
        },
        postForm: function (e) {
            e.preventDefault();
            this.formLoading = true;
            this.formSuccess = null;
            this.formErrors = null;

            axios
                .put(`/api/restaurants/${this.restaurantId}/stripe`, {
                    enable_footprint: this.restaurant.enable_footprint,
                    footprint_min_pers: this.restaurant.footprint_min_pers,
                    amount_noshow: this.restaurant.amount_noshow,
                })
                .then((response) => {
                    // display success message
                    this.formLoading = false;
                    //this.formSuccess = response.data.message;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    // reload data
                    this.fetchData();
                    this.$emit("done");
                })
                .catch((error) => {
                    this.formErrors = error.response.data.message;
                    this.formLoading = false;
                });
        },
        connectSuccess() {
            this.$notify({
                group: "notification",
                type: "success",
                title: "Votre compte stripe à été connecté",
            });
            // reload data
            this.fetchData();
        },
        connectError(error) {
            this.$notify({
                group: "notification",
                type: "error",
                title: error,
            });
        },
        connectCapabilitiesError(errorParams) {
            this.connectErrorParams = errorParams;
            this.showCapabilitiesErrorModal = true;
        },
        logoutStripe: function (e) {
            e.preventDefault();
            if (!confirm("Êtes vous sûr de vouloir déconnecter votre compte Stripe ?")) return;
            this.formLoading = true;
            this.formSuccess = null;
            this.formErrors = null;

            axios
                .get(`/api/restaurants/${this.restaurantId}/stripeLogout`)
                .then((response) => {
                    axios
                        .post(`/api/restaurants/${this.restaurantId}/stripeLogout`)
                        .then((response) => {
                            // display success message
                            this.formLoading = false;
                            //this.formSuccess = response.data.message;
                            this.$notify({
                                group: "notification",
                                type: "success",
                                title: response.data.message,
                            });
                            // reload data
                            this.fetchData();
                        })
                        .catch((error) => {
                            this.formErrors = error.response.data.message;
                            this.formLoading = false;
                        });
                })
                .catch((error) => {
                    this.formErrors = [
                        "Veuillez désactiver le prépaiement et l'empreinte bancaire pour chaque service avant de désactiver Stripe. (Configuration / Configurations des services)",
                    ];
                });
        },
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_restaurant() {
            return this.rights.includes("default.restaurants.update");
        },
        restaurantId() {
            if (this.restaurant_id) {
                return this.restaurant_id;
            }

            return this.$route.params.restaurant_id;
        },
    },
    created() {
        this.fetchData();
    },
    watch: {
        restaurantId: function (id) {
            this.fetchData();
        },
    },
    components: {
        StripeConnectButton,
        LoaderComponent,
        CapabilitiesErrorModal,
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
