<template>
    <div class="p-2">
        <loader-component v-if="loading" />
        <show-errors :errors="errors.form" error-key="*" />
        <select-licence
            v-show="!loading"
            class="px-3"
            :modules-enabled="modulesEnabled"
            :preselected-modules="modules"
            show-back-button
            disable-payment
            disable-training
            disable-plan
            next-label="Créer les licences"
            @back="$router.go(-1)"
            @selected="createLicences" />
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import SelectLicence from "../../../components/Modules/Licences/Select.vue";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";
import StripePlanTypesEnum from "../../../mixins/enums/StripePlanTypesEnum.js";
import ShowErrors from "../../../components/errors/ShowErrors.vue";

export default {
    data() {
        return {
            loading: false,
            errors: {
                form: null,
            },
            modules: [],
            plan: "yearly",
            step: 1,
        };
    },
    mixins: [ModuleTypesEnum, StripePlanTypesEnum],
    computed: {
        ownerId() {
            return this.$route.params.owner_id;
        },
        modulesEnabled() {
            const disabledModules = [];
            if (!this.isNoShow) {
                disabledModules.push(this.MODULE_TYPE_WEBSITE.value);
            }
            const availableModules = this.$store.getters["modules/getModulesLabels"].filter((module) => !disabledModules.includes(module));

            if (this.$route.query.modules) {
                const validatedModules = this.$route.query.modules.split(",").filter((module) => availableModules.includes(module));

                if (validatedModules.length > 0) {
                    return validatedModules;
                }
            }

            return availableModules;
        },
    },
    methods: {
        createLicences(e) {
            this.loading = true;
            this.errors.form = null;

            const data = {
                modules: e.selectedModules.map((selectedModule) => {
                    return {
                        id: selectedModule.id,
                        count: selectedModule.count,
                    };
                }),
                plan: e.plan,
            };

            this.httpPost(`/api/admin/owners/${this.ownerId}/subscriptions`, data)
                .then((response) => {
                    if (response !== false) {
                        this.$router.push({ name: "admin.ownerDetails", params: { owner_id: this.ownerId } });
                    }
                })
                .finally(() => (this.loading = false));
        },
    },
    components: {
        SelectLicence,
        LoaderComponent,
        ShowErrors,
    },
};
</script>
