import axios from "axios";

export const actions = {
    fetchClickAndCollectApiKey({}, { restaurant_id }) {
        return axios.get(`/api/click_and_collect/${restaurant_id}/widget/key`);
    },
    generateClickAndCollectApiKey({}, { restaurant_id }) {
        return axios.post(`/api/click_and_collect/${restaurant_id}/widget/key`);
    },
    getCurrentWidget({ commit }, { restaurant_id }) {
        commit("setIsLoading", true);
        axios
            .get(`/api/restaurants/${restaurant_id}/widget`)
            .then((response) => {
                commit("setWidget", response.data);
            })
            .catch((error) => {
                commit("emptyWidget");
            })
            .finally(() => commit("setIsLoading", false));
    },
};

export default actions;
