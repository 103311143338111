<template>
    <div class="row mb-3">
        <div class="col-12">
            <loader-component v-if="nbLoading > 0" />
            <div v-else-if="stats.main" class="border-light b-radius-20 p-4">
                <div class="row pointer" @click="showCaPerEntity = !showCaPerEntity">
                    <strong class="col-10 d-flex align-items-center">
                        {{ $tl(`labels.booking.stats.charts.${entity.translationKeyCA}.title`) }}
                    </strong>
                    <div class="d-flex justify-content-end align-items-center col-2">
                        <button
                            v-if="!filters.hasComparison"
                            class="btn btn-sm btn-square btn-secondary mr-2"
                            type="button"
                            @click.stop.prevent="toggleFilters">
                            <feather type="filter" />
                        </button>
                        <button
                            class="btn btn-sm btn-square"
                            type="button"
                            :class="pinCaPerEntity ? 'btn-success' : 'btn-secondary'"
                            @click.stop.prevent="pinCaPerEntity = !pinCaPerEntity">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="18px" height="18px" fill="currentColor">
                                <path
                                    d="M300.8 203.9L290.7 128H328c13.2 0 24-10.8 24-24V24c0-13.2-10.8-24-24-24H56C42.8 0 32 10.8 32 24v80c0 13.2 10.8 24 24 24h37.3l-10.1 75.9C34.9 231.5 0 278.4 0 335.2c0 8.8 7.2 16 16 16h160V472c0 .7 .1 1.3 .2 1.9l8 32c2 8 13.5 8.1 15.5 0l8-32c.2-.6 .2-1.3 .2-1.9V351.2h160c8.8 0 16-7.2 16-16 .1-56.8-34.8-103.7-83.1-131.3zM33.3 319.2c6.8-42.9 39.6-76.4 79.5-94.5L128 96H64V32h256v64h-64l15.3 128.8c40 18.2 72.7 51.8 79.5 94.5H33.3z" />
                            </svg>
                        </button>
                    </div>
                </div>

                <div v-if="isFilter" class="d-flex p-4 border-light b-radius-20 mt-3 flex-column">
                    <div class="col-12 pb-2 pl-0">
                        <strong class="text-success">{{ $tl("labels.booking.stats.charts.CAPerEntity.filters.titleFilters") }}</strong>
                    </div>
                    <div class="col-12 d-flex pt-3">
                        <div class="col-3 p-0">
                            {{ $tl(`labels.booking.stats.charts.${entity.translationKeyCA}.filters.onlySold`) }}
                        </div>
                        <div class="col-9 p-0">
                            <label class="container-box">
                                <input type="checkbox" v-model="entitiesFilters.onlySold" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div v-if="entitiesFilters.onlySold" class="col-12 d-flex pt-3">
                        <div class="col-3 p-0">
                            {{ $tl("labels.booking.stats.charts.CAPerEntity.filters.topSales") }}
                        </div>
                        <div class="col-9 p-0 radio">
                            <radio-button-component v-model="entitiesFilters.topSold" input-value="all" input-name="topSales">
                                {{ $tl(`labels.booking.stats.charts.${entity.translationKeyCA}.filters.all`) }}
                            </radio-button-component>
                            <radio-button-component v-model="entitiesFilters.topSold" :input-value="3" input-name="topSales">
                                {{ $tl("labels.booking.stats.charts.CAPerEntity.filters.top3") }}
                            </radio-button-component>
                            <radio-button-component v-model="entitiesFilters.topSold" :input-value="10" input-name="topSales">
                                {{ $tl("labels.booking.stats.charts.CAPerEntity.filters.top10") }}
                            </radio-button-component>
                        </div>
                    </div>
                    <div class="mt-3">
                        <button class="btn btn-sm btn-success btn-circle" @click="applyFilters">{{ $tl("labels.form.actions.apply") }}</button>
                    </div>
                </div>
                <div v-if="showCaPerEntity || pinCaPerEntity" class="row d-flex flex-column align-items-center">
                    <div class="p-3 d-flex">
                        <strong class="text-success pr-1">{{ labelPeriodMain }}</strong>
                        <span v-if="stats.comparison && filters.hasComparison">
                            {{ $tl("labels.filters.comparedTo") }}
                            <strong class="text-success">{{ labelPeriodComparison }}</strong>
                        </span>
                    </div>
                    <VChart
                        v-if="!noEntitiesSold || (noEntitiesSold && filters.hasComparison && stats.comparison !== null && !noEntitiesSoldComparison)"
                        class="chart"
                        :option="{
                            title: caPerEntity.title,
                            series: seriesDataBarCa,
                            xAxis: caPerEntity.xAxis,
                            yAxis: caPerEntity.yAxis,
                            tooltip: tooltipCaPerMenu,
                            grid: grid,
                        }">
                    </VChart>
                    <div v-else class="h-100 d-flex align-items-center">
                        <strong>
                            {{ $tl("labels.booking.stats.noDataOnPeriod") }}
                        </strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VChart from "vue-echarts";
import LoaderComponent from "../LoaderComponent.vue";
import StatsUtils from "../../mixins/helpers/booking/StatsUtils.js";
import radioButtonComponent from "../forms/RadioButtonComponent.vue";
import { ALL_ENTITIES } from "../../utils/stats/revenueEntities.js";

/**
 * Generic component for :
 *  - App\Models\Menu
 *  - App\Models\MenuOption
 *  - App\Models\GeneralOption
 */
export default {
    name: "CaPerEntityComponent",
    props: {
        grid: {
            required: true,
            type: Object,
        },
        filters: {
            required: true,
            type: Object,
        },
        entity: {
            required: true,
            type: Object,
            validator(entity) {
                return ALL_ENTITIES.includes(entity);
            },
        },
    },
    mixins: [StatsUtils],
    data() {
        return {
            showCaPerEntity: false,
            caPerEntity: {
                title: {
                    text: this.$tl(`labels.booking.stats.charts.${this.entity.translationKeyCA}.title`),
                    left: "center",
                    textStyle: {
                        fontWeight: "normal",
                    },
                },
                xAxis: {
                    type: "value",
                    name: this.$tl(`labels.booking.stats.charts.${this.entity.translationKeyCA}.x`),
                    nameLocation: "middle",
                    nameTextStyle: {
                        fontWeight: "bold",
                        padding: [10, 0, 10, 0],
                    },
                    axisLabel: {
                        formatter: (value) => {
                            return this.formatCurrency(value, this.currency);
                        },
                    },
                },
                yAxis: {
                    type: "category",
                    name: this.$tl(`labels.booking.stats.charts.${this.entity.translationKeyCA}.y`),
                    nameLocation: "end",
                    nameTextStyle: {
                        fontWeight: "bold",
                    },
                    axisLabel: {
                        interval: 0,
                        formatter: (value) => {
                            let menu = this.stats.main.find((item) => this.getItemKey(item) === value);

                            if (menu === undefined) {
                                menu = this.stats.comparison.find((item) => this.getItemKey(item) === value);
                            }
                            return menu !== undefined ? menu.name : value;
                        },
                    },
                },
            },
            originalStats: {
                main: null,
                comparison: null,
            },
            stats: {
                main: null,
                comparison: null,
            },
            isFilter: false,
            entitiesFilters: {
                onlySold: false,
                topSold: "all",
            },
        };
    },
    computed: {
        pinCaPerEntity: {
            get() {
                return this.$store.getters[`userPreferences/${this.entity.pinKeyCA}`];
            },
            set(newVal) {
                this.$store.dispatch(`userPreferences/set${this.capitalize(this.entity.pinKeyCA)}`, newVal);
            },
        },
        tooltipCaPerMenu() {
            return {
                trigger: "item",
                formatter: (params) => {
                    const totalPrice = this.formatCurrency(Number(params.data[0]), this.currency);
                    const nb = params.data[2];
                    const so = this.$tl("labels.booking.stats.charts.tooltip.so");
                    const services = this.$tcl(`labels.booking.stats.charts.${this.entity.translationKeyCA}.tooltip`, nb);

                    return `<b>${services}</b>, <br/> ${so} ${totalPrice}`;
                },
            };
        },
        seriesDataBarCa() {
            const seriesBarCa = [
                {
                    type: "bar",
                    data: this.seriesDataSoldEntities.map((item) => [item[2], item[0], item[3], item[1]]),
                    itemStyle: {
                        color: "#8C54FF",
                    },
                    label: {
                        show: true,
                        position: "right",
                        formatter: (params) => {
                            return this.formatCurrency(Number(params.data[0]), this.currency);
                        },
                    },
                },
            ];

            if (this.filters.hasComparison && this.stats.comparison) {
                seriesBarCa.push({
                    type: "bar",
                    data: this.seriesDataSoldEntitiesComparison.map((item) => [item[2], item[0], item[3], item[1]]),
                    itemStyle: {
                        color: "#bc43e1",
                    },
                    label: {
                        show: true,
                        position: "right",
                        formatter: (params) => {
                            return this.formatCurrency(Number(params.data[0]), this.currency);
                        },
                    },
                });
            }

            return seriesBarCa;
        },
        seriesDataSoldEntities() {
            return this.stats.main.map((item) => [this.getItemKey(item), item.name, item.revenue, item.count_sold]);
        },
        seriesDataSoldEntitiesComparison() {
            return this.stats.comparison.map((item) => [this.getItemKey(item), item.name, item.revenue, item.count_sold]);
        },
        noEntitiesSold() {
            return this.stats.main?.every((menu) => !menu.count_sold);
        },
        noEntitiesSoldComparison() {
            return this.stats.comparison?.every((menu) => !menu.count_sold);
        },
    },
    methods: {
        resetStats() {
            this.stats.main = [...this.originalStats.main];
            if (this.filters.hasComparison) {
                this.stats.comparison = [...this.originalStats.comparison];
            }
        },
        applyFilterOnMainStats() {
            let filteredMainStats;

            filteredMainStats = this.originalStats.main.filter((menu) => menu.revenue > 0);

            if (this.entitiesFilters.topSold !== "all") {
                filteredMainStats = filteredMainStats.sort((a, b) => b.revenue - a.revenue).slice(0, Number(this.entitiesFilters.topSold));
            }

            this.stats.main = filteredMainStats;
        },
        applyFilterOnComparisonStats() {
            let filteredComparisonStats;

            filteredComparisonStats = this.originalStats.comparison.filter((entity) => entity.revenue > 0);

            if (this.entitiesFilters.topSold !== "all") {
                filteredComparisonStats = this.originalStats.comparison.filter((entity) =>
                    this.stats.main.some((mainEntity) => mainEntity.name === entity.name)
                );
            }

            this.stats.comparison = filteredComparisonStats;
        },
        applyFilters() {
            if (!this.entitiesFilters.onlySold) {
                this.entitiesFilters.topSold = "all";
                this.resetStats();
                return;
            }

            this.applyFilterOnMainStats();

            if (this.filters.hasComparison) {
                this.applyFilterOnComparisonStats();
            }
        },
        fetchStats(abortControllerSignal = null, forComparison = false) {
            const filters = this.getFiltersQueryParams(forComparison);

            return this.httpGet(`/api/stats/${this.entity.endpoint}?${filters}`, {
                config: { signal: abortControllerSignal },
                handleReject: false,
            }).then((response) => {
                if (response !== false) {
                    const target = forComparison ? "comparison" : "main";
                    this.stats[target] = response.data;
                    this.originalStats[target] = [...response.data];
                }
            });
        },
        toggleFilters() {
            this.isFilter = !this.isFilter;
            this.showCaPerEntity = true;
        },
    },
    components: { LoaderComponent, VChart, radioButtonComponent },
    watch: {
        pinCaPerEntity(newVal) {
            if (!newVal) {
                this.showCaPerEntity = false;
            }
        },
    },
};
</script>

<style scoped>
button.btn:disabled {
    cursor: default;
}

.chart {
    min-height: 650px;
    height: auto;
}
</style>
