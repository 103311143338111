<template>
    <div class="container-fluid h-100 login">
        <div v-if="!isBrowserIE" class="row m-0 h-100 w-100">
            <nav class="col-md-4 col-lg-4 d-md-block bg-dark sidebar shadow"></nav>
            <main class="col-md-8 ml-sm-auto col-lg-8 px-md-5 h-100 d-flex">
                <router-view />
            </main>
        </div>
        <div v-else style="text-align: center">
            <h1>{{ $tl("errors.browser.invalid.title") }}</h1>
            <p v-html="$t('errors.browser.invalid.infos')"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "AuthLayout",
};
</script>
