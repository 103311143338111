<template>
    <div class="pb-5">
        <loader-component v-if="loading" />
        <div class="default-home" v-else>
            <div class="row m-0">
                <div class="col-12" :class="{ 'd-flex': showBackButton }">
                    <button v-if="showBackButton" @click="$router.go(-1)" class="btn d-flex pl-0">
                        <feather type="chevron-left" class="feather-24" />
                    </button>
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 v-if="!restaurant_id" class="title mt-2">{{ $tl("labels.routes.addRestaurant") }}</h5>
                        <h5 v-else class="title mt-2">{{ restaurant.name }}</h5>
                    </div>
                </div>
            </div>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div v-if="userRole === 'owner'">
                    <div class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <div class="row">
                                    <div class="col-12 mb-3">
                                        <strong>{{ $tl("labels.licences") }}</strong>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3 p-2" v-for="MODULE_TYPE in ALL_MODULES_TYPES" :key="MODULE_TYPE.value">
                                        <strong>{{ getModuleTypeLabel(MODULE_TYPE.value) }}</strong>
                                        <select
                                            :disabled="!has_right_to_update_subscription"
                                            class="custom-select m-0"
                                            v-model="licences[MODULE_TYPE.value]">
                                            <option :value="null" :disabled="true">
                                                {{ $tl("labels.form.licences.assign") }}
                                            </option>
                                            <template v-for="(licence, index) in getAvailableSubscriptions(MODULE_TYPE.value)">
                                                <option
                                                    v-if="licence.restaurant_id == restaurant_id || !licence.restaurant_id"
                                                    :key="index"
                                                    :value="licence.id"
                                                    :selected="licence.id == licences[MODULE_TYPE.value]">
                                                    {{ $tl("labels.licence") }}
                                                    {{ getModulePlanTypeLabel(licence.plan) }} {{ index + 1 }}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <div class="row">
                                    <div class="col-12 mb-3">
                                        <strong>{{ $tl("labels.visualIdentity") }}</strong>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5 mb-3">
                                        <label>{{ $tl("labels.form.logo") }}</label>
                                    </div>
                                    <div class="col-7">
                                        <ImageComponent
                                            :src="restaurant.logo.url"
                                            :disabled="!hasRightToEdit"
                                            :upload="false"
                                            :maxSize="10"
                                            suffixId="logo"
                                            @new-file="restaurant.logo.file = $event" />
                                        <ShowErrors class="d-block" :errors="errors.form" error-key="logo" />
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-5 pt-2">
                                        <label>{{ $tl("labels.form.color") }}</label>
                                    </div>
                                    <div class="col-md-7 pt-2">
                                        <chrome-picker v-if="hasRightToEdit" v-model="restaurant.widget_color" />
                                        <span class="p-1" v-else :style="`background-color: ${restaurant.widget_color}`">
                                            {{ restaurant.widget_color }}
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5 pt-2">
                                        <label>{{ $tl("labels.form.restaurant.titleFont") }} <small>*</small></label>
                                    </div>
                                    <div class="col-md-7 d-flex align-items-center">
                                        <select v-model="restaurant.widget_font_title" :disabled="!hasRightToEdit" class="custom-select w-auto m-0">
                                            <option v-for="font in availableFontsTitle" :key="font.value" :value="font.value">
                                                {{ font.name }}
                                            </option>
                                        </select>
                                        <span class="ml-2 font-previsu" :style="'font-family: ' + restaurant.widget_font_title">{{
                                            restaurant.name || $tl("labels.reservation")
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form id="editRestaurant" @submit.prevent="postForm" method="post">
                    <div class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <loader-component v-if="formLoading" />
                                <div v-show="!formLoading">
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <strong>{{ $tl("labels.infos") }}</strong>
                                        </div>
                                    </div>

                                    <InputComponent
                                        v-model="restaurant.name"
                                        required
                                        :label="$t('labels.form.restaurant.name')"
                                        inputName="name"
                                        :disabled="!hasRightToEdit"
                                        :form-errors="errors.form" />

                                    <div class="row mb-2">
                                        <div class="col-md-5 pt-2">
                                            {{ $tl("labels.form.address") }} <small>*</small>
                                            <small v-if="!hasFillGoogleMapsAddress" class="text-warn d-block mt-2">
                                                {{ $tl("infos.restaurants.newAddressComponent") }}
                                            </small>
                                        </div>
                                        <div class="col-md-7">
                                            <postal-address-autocomplete
                                                v-model="restaurant.google_maps_address"
                                                :disabled="!hasRightToEdit"
                                                :search-address-type="false" />
                                            <ShowErrors class="d-block" :errors="errors.form" error-key="google_maps_address" />
                                        </div>
                                    </div>

                                    <InputComponent
                                        v-model="restaurant.further_address_details"
                                        :label="$t('labels.form.addressComp')"
                                        notice="notices.restaurants.addressComp"
                                        inputName="further_address_details"
                                        :form-errors="errors.form" />

                                    <template v-if="!hasFillGoogleMapsAddress">
                                        <InputComponent
                                            v-model="restaurant.address"
                                            required
                                            :label="$t('labels.form.address')"
                                            inputName="address"
                                            disabled
                                            :form-errors="errors.form" />

                                        <InputComponent
                                            v-model="restaurant.further_address_details"
                                            :label="$t('labels.form.addressComp')"
                                            inputName="further_address_details"
                                            disabled
                                            :form-errors="errors.form" />

                                        <InputComponent
                                            v-model="restaurant.zipcode"
                                            required
                                            :label="$t('labels.form.postalCode')"
                                            inputName="zipcode"
                                            maxlength="5"
                                            disabled
                                            :form-errors="errors.form" />

                                        <InputComponent
                                            v-model="restaurant.city"
                                            required
                                            :label="$t('labels.form.city')"
                                            inputName="city"
                                            disabled
                                            :form-errors="errors.form" />

                                        <InputComponent
                                            v-model="restaurant.country"
                                            required
                                            :label="$t('labels.form.country')"
                                            inputName="country"
                                            disabled
                                            :form-errors="errors.form" />
                                    </template>

                                    <div class="row mb-2">
                                        <div class="col-5 pt-2">
                                            <label>{{ $tl("labels.form.phoneNumber") }}</label>
                                        </div>
                                        <div class="col-7">
                                            <TelInputComponent
                                                v-model="restaurant.tel"
                                                inputName="tel"
                                                :disabled="!hasRightToEdit"
                                                :form-errors="errors.form"
                                                :placeholder="$t('labels.form.phoneNumber')"
                                                @set-tel-country="restaurant.telCountry = $event" />
                                        </div>
                                    </div>

                                    <InputComponent
                                        v-model="restaurant.email"
                                        notice="notices.booking.restaurant.email"
                                        :label="$t('labels.form.email')"
                                        inputName="email"
                                        inputType="email"
                                        :disabled="!hasRightToEdit"
                                        :form-errors="errors.form" />

                                    <InputComponent
                                        v-model="restaurant.website"
                                        placeholder="www.monsite.com"
                                        :label="$t('labels.form.website')"
                                        inputName="website"
                                        :disabled="!hasRightToEdit"
                                        :form-errors="errors.form" />

                                    <div class="row pt-1 mb-1">
                                        <div class="col-5">
                                            <label>{{ $tl("labels.form.restaurant.noTVA") }}</label>
                                        </div>
                                        <div class="col-7">
                                            <label class="container-box" style="width: initial">
                                                <input type="checkbox" v-model="restaurant.tva_disabled" true-value="1" :disabled="!hasRightToEdit" />
                                                <span class="checkmark" :class="{ disabled: !hasRightToEdit }"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5 pt-2">
                                            <label>{{ $tl("labels.timezone") }} <small>*</small></label>
                                            <ShowNewBadgeDetails badge_uuid="8dd53584-2fad-474e-97f1-ffbe45f87b93" />
                                        </div>
                                        <div class="col-md-7">
                                            <TimeZonePicker
                                                v-model="restaurant.timezone"
                                                :disabled="!hasRightToEdit"
                                                class="custom-select w-auto m-0"
                                                data-test-id="select-txt-timezone" />
                                            <ShowErrors class="d-block" :errors="errors.form" error-key="timezone" />
                                        </div>
                                    </div>

                                    <div class="row mb-2 mt-2">
                                        <div class="col-5 pt-2">
                                            <label>{{ $tl("labels.form.indicative") }}</label>
                                            <feather
                                                v-tooltip="getTooltipNotice('notices.defaultTelCountry')"
                                                type="info"
                                                class="ml-1 pointer tooltip-infos" />
                                            <ShowNewBadgeDetails badge_uuid="1481b527-49c6-462c-a69f-74f0855ce7cd" />
                                        </div>
                                        <div style="padding-left: 15px">
                                            <vue-tel-input
                                                data-test-id="input-tel-default_tel_country"
                                                class="form-control p-0"
                                                :disabled="!hasRightToEdit"
                                                :defaultCountry="restaurant.default_tel_country"
                                                @country-changed="(e) => (restaurant.default_tel_country = e.iso2)"
                                                inputClasses="d-none">
                                            </vue-tel-input>
                                        </div>
                                    </div>

                                    <div class="row mb-2 mt-2">
                                        <div class="col-5 pt-2">
                                            <label>{{ $tl("labels.form.restaurant.currency") }}</label>
                                            <feather
                                                v-tooltip="getTooltipNotice('notices.currency')"
                                                type="info"
                                                class="ml-1 pointer tooltip-infos" />
                                        </div>
                                        <div class="col-md-7 d-flex align-items-center">
                                            <choose-currency :disabled="hasPsp" v-model="restaurant.currency" />
                                        </div>
                                    </div>

                                    <template v-if="isYservices">
                                        <div class="row mb-2 mt-2">
                                            <div class="col-5 pt-2">
                                                <label>{{ $tl("labels.lexicons.lexicon") }}</label>
                                            </div>
                                            <div class="col-md-7 d-flex align-items-center">
                                                <choose-lexicon
                                                    v-model="restaurant.lexicon"
                                                    v-tooltip="
                                                        restaurant_id !== null
                                                            ? getTooltip('Ce paramètre n\'est modifiable que lors de la création d\'établissement')
                                                            : undefined
                                                    "
                                                    :disabled="restaurant_id !== null" />
                                            </div>
                                        </div>

                                        <switch-input-component
                                            v-if="restaurant.lexicon !== LEXICON_RESTAURANT.value"
                                            :label="$t('labels.form.restaurant.showToRealizeView')"
                                            v-model="restaurant.show_to_realize_view"></switch-input-component>

                                        <switch-input-component
                                            v-if="restaurant.lexicon !== LEXICON_RESTAURANT.value"
                                            :label="$t('labels.form.restaurant.enableSeatingPlan')"
                                            v-model="restaurant.feat_seating_plan"></switch-input-component>
                                    </template>
                                </div>
                            </div>
                            <Cgv
                                v-show="!formLoading"
                                :disabled="!hasRightToEdit"
                                :restaurant_id="restaurant_id"
                                :form-errors="errors.form"
                                ref="cgvComponent"
                                :owner-id="ownerId"
                                :isTvaDisabledProp="restaurant.tva_disabled" />
                            <EditSmsSettings
                                v-show="!formLoading"
                                v-if="restaurant_id && !created_restaurant_id && restaurant.notifications_setting.sms_activated"
                                :restaurant="restaurant"
                                :form-errors="errors.form" />
                            <div class="row mt-3" v-if="isNoShow && restaurant_id !== null && !created_restaurant_id">
                                <div class="col-12">
                                    <ChooseCardPaymentSystem
                                        v-if="restaurant.prefered_payment_system"
                                        class="border-light b-radius-20 p-4"
                                        :prefered_payment_system="restaurant.prefered_payment_system"
                                        @set-prefered-payment-system="restaurant.prefered_payment_system = $event" />
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <div class="d-flex justify-content-between">
                                        <button
                                            v-if="isOwner && restaurant_id"
                                            :disabled="formLoading"
                                            @click="showDeleteRestaurantModal = true"
                                            class="btn btn-danger btn-circle"
                                            type="button">
                                            {{ $tl("labels.form.restaurant.actions.delete") }}
                                        </button>
                                        <button
                                            :disabled="!hasRightToEdit || formLoading"
                                            data-test-id="btn-save"
                                            class="btn btn-success btn-circle"
                                            type="submit">
                                            {{ $tl("labels.form.actions.save") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <CreateRestaurantModal
            v-if="showCreateRestaurantModal"
            @close="onCreateRestaurantModalClosed"
            :smsActivated="restaurant.notifications_setting.sms_activated"
            :restaurant_id="restaurant_id" />

        <DeleteRestaurantModal v-if="showDeleteRestaurantModal" :restaurant="restaurant" @close="showDeleteRestaurantModal = false" />
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import ImageComponent from "../../../components/forms/ImageComponent.vue";
import { Chrome } from "vue-color";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";
import ModulePlanTypesEnum from "../../../mixins/enums/ModulePlanTypesEnum.js";
import EditSmsSettings from "../../../components/sms/EditSmsSettings.vue";
import Cgv from "../../../components/Default/Restaurants/cgv.vue";
import CreateRestaurantModal from "../../../components/Modals/CreateRestaurantModal.vue";
import ChooseCardPaymentSystem from "../../../components/Default/Restaurants/ChooseCardPaymentSystem.vue";
import CardPaymentSystemEnum from "../../../mixins/enums/CardPaymentSystemEnum.js";
import TimeZonePicker from "../../../components/forms/TimeZonePicker.vue";
import ShowNewBadgeDetails from "../../../components/NewBadges/showNewBadgeDetails.vue";
import InputComponent from "../../../components/forms/InputComponent.vue";
import TelInputComponent from "../../../components/forms/TelInputComponent.vue";
import ChooseCurrency from "../../../components/Default/ChooseCurrency.vue";
import RolesEnum from "../../../mixins/enums/RolesEnum.js";
import ShowErrors from "../../../components/errors/ShowErrors.vue";
import ChooseLexicon from "../../../components/Default/ChooseLexicon.vue";
import PostalAddressAutocomplete from "../../../components/forms/PostalAddressAutocomplete.vue";
import SwitchInputComponent from "../../../components/forms/SwitchInputComponent.vue";
import LexiconEnum from "../../../mixins/enums/LexiconEnum.js";
import DeleteRestaurantModal from "../../../components/Modals/DeleteRestaurantModal.vue";

export default {
    name: "CreateRestaurants",
    data() {
        return {
            formLoading: null,
            loading: false,
            error: null,
            errors: {
                form: null,
            },
            restaurant: {
                id: null,
                name: "",
                logo: {
                    url: "",
                    file: null,
                },
                address: "",
                further_address_details: "",
                zipcode: "",
                city: "",
                country: "",
                tel: "",
                telCountry: "",
                email: "",
                website: "",
                widget_color: "#b4975a",
                widget_font_title: "'Open Sans', sans-serif",
                notifications_setting: {
                    sms_activated: false,
                    sms_emitter_name: "",
                    enable_international_sms: false,
                },
                tva_disabled: false,
                prefered_payment_system: null,
                timezone: "Europe/Paris",
                default_tel_country: "FR",
                currency: "EUR",
                lexicon: "restaurant",
                google_maps_address: null,
                show_to_realize_view: false,
                feat_seating_plan: true,
            },
            phone: {
                raw: null,
                isValid: null,
                country: null,
                value: null,
                search: {
                    results: null,
                },
            },
            licences: {},
            created_restaurant_id: null,
            showCreateRestaurantModal: false,
            availableFontsTitle: [
                {
                    name: "Montserrat Alternates",
                    value: "'Montserrat Alternates', sans-serif",
                },
                {
                    name: "Roboto",
                    value: "'Roboto', sans-serif",
                },
                {
                    name: "Open Sans",
                    value: "'Open Sans', sans-serif",
                },
                {
                    name: "Poppins",
                    value: "'Poppins', sans-serif",
                },
                {
                    name: "Oswald",
                    value: "'Oswald', sans-serif",
                },
                {
                    name: "Playfair Display",
                    value: "'Playfair Display', sans-serif",
                },
                {
                    name: "DM Serif Display",
                    value: "'DM Serif Display', sans-serif",
                },
                {
                    name: "Lato",
                    value: "'Lato', sans-serif",
                },
                {
                    name: "Raleway",
                    value: "'Raleway', sans-serif",
                },
            ],
            hasPsp: false,
            hasFillGoogleMapsAddress: false,
            showDeleteRestaurantModal: false,
        };
    },
    mixins: [ModuleTypesEnum, ModulePlanTypesEnum, CardPaymentSystemEnum, RolesEnum, LexiconEnum],
    props: {
        showBackButton: {
            default: true,
            type: Boolean,
        },
    },
    computed: {
        ownerId() {
            return this.$route.query.owner_id || null;
        },
        isAdmin() {
            return this.userRole === this.ROLE_ADMIN.value;
        },
        isOwner() {
            return this.userRole === this.ROLE_OWNER.value;
        },
        userRole() {
            return this.$store.getters["users/role"];
        },
        restaurant_id: {
            get() {
                return this.created_restaurant_id || this.$route.params.restaurant_id || null;
            },
            set(newVal) {
                this.created_restaurant_id = newVal;
            },
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        hasRightToEdit() {
            if (this.isAdmin) {
                return true;
            }

            return this.restaurant_id === null
                ? this.rights.includes("default.restaurants.create")
                : this.rights.includes("default.restaurants.update");
        },
        has_right_to_update_subscription() {
            return this.isAdmin || this.rights.includes("default.subscription.update");
        },
    },
    created() {
        const promises = this.initLicences();

        if (this.restaurant_id) {
            Promise.all(promises).then(() => {
                this.fetchRestaurantById(this.restaurant_id);
            });
        } else {
            if (this.isYservices) {
                this.restaurant.lexicon = "generic";
                this.restaurant.feat_seating_plan = false;
            }

            if (!this.restaurant.prefered_payment_system) {
                this.restaurant.prefered_payment_system = this.CARD_PAYMENT_SYSTEM_STRIPE.value;
            }
        }
    },
    methods: {
        getAvailableSubscriptions(type) {
            return this.$store.getters["users/getLicencesByModuleType"](type).filter((s) => s.status != "canceled");
        },
        initLicences() {
            var promises = [];
            this.ALL_MODULES_TYPES.forEach((ModuleType) => {
                promises.push(this.$set(this.licences, ModuleType.value, null));
            });

            return promises;
        },
        setLicences(licences) {
            licences.forEach((licence) => {
                this.licences[licence.module.type] = licence.id;
            });
        },
        fillRestaurant(data) {
            this.restaurant.id = data.id;
            this.restaurant.name = data.name;
            this.restaurant.address = data.address;
            this.restaurant.further_address_details = data.further_address_details;
            this.restaurant.zipcode = data.zipcode;
            this.restaurant.city = data.city;
            this.restaurant.country = data.country;
            this.restaurant.tel = data.tel;
            this.restaurant.email = data.email;
            this.restaurant.website = data.website;
            this.restaurant.logo.url = data.logo_url;
            this.restaurant.widget_color = data.widget_color;
            this.restaurant.widget_font_title = data.widget_font_title;
            this.restaurant.tva_disabled = data.tva_disabled;
            this.restaurant.notifications_setting = data.notifications_setting;
            this.restaurant.notifications_setting.sms_emitter_name = data.notifications_setting.sms_emitter_name || "";
            this.restaurant.prefered_payment_system = data.prefered_payment_system;
            this.restaurant.timezone = data.timezone;
            this.restaurant.default_tel_country = data.default_tel_country;
            this.restaurant.currency = data.currency;
            this.restaurant.lexicon = data.lexicon;
            this.restaurant.feat_seating_plan = data.feat_seating_plan;
            this.restaurant.google_maps_address = data.google_maps_address;
            this.restaurant.show_to_realize_view = data.show_to_realize_view;
            this.phone.raw = data.tel;
            this.hasPsp = data.has_psp;
            this.setLicences(data.subscriptions.data);
        },
        buildForm() {
            var formData = new FormData();

            formData.append("name", this.restaurant.name);
            formData.append(
                "further_address_details",
                this.restaurant.further_address_details !== null ? this.restaurant.further_address_details : ""
            );
            formData.append("tel", this.restaurant.tel || "");
            formData.append("tel_country", this.restaurant.telCountry || "");
            formData.append("email", this.restaurant.email || "");
            formData.append("website", this.restaurant.website || "");
            formData.append("sms_emitter_name", this.restaurant.notifications_setting.sms_emitter_name);
            formData.append("enable_international_sms", this.restaurant.notifications_setting.enable_international_sms ? 1 : 0);
            formData.append("tva_disabled", this.restaurant.tva_disabled ? 1 : 0);
            formData.append("widget_color", this.restaurant.widget_color.hex ? this.restaurant.widget_color.hex : this.restaurant.widget_color);
            formData.append("widget_font_title", this.restaurant.widget_font_title);
            formData.append("prefered_payment_system", this.restaurant.prefered_payment_system);
            formData.append("timezone", this.restaurant.timezone);
            formData.append("default_tel_country", this.restaurant.default_tel_country);
            formData.append("currency", this.restaurant.currency);
            formData.append("lexicon", this.restaurant.lexicon);
            formData.append(
                "show_to_realize_view",
                (this.restaurant.lexicon === this.LEXICON_RESTAURANT.value ? true : this.restaurant.show_to_realize_view) ? 1 : 0
            );
            formData.append(
                "feat_seating_plan",
                (this.restaurant.lexicon === this.LEXICON_RESTAURANT.value ? true : this.restaurant.feat_seating_plan) ? 1 : 0
            );
            formData.append(
                "google_maps_address",
                this.restaurant.google_maps_address !== null ? JSON.stringify(this.restaurant.google_maps_address) : null
            );
            const cgvData = this.$refs.cgvComponent.getParams();
            Object.keys(cgvData).forEach((key) => {
                formData.append(key, cgvData[key]);
            });
            if (this.restaurant.logo.file) {
                formData.append("logo", this.restaurant.logo.file);
            }

            if (this.isNoShow) {
                for (const [key, value] of Object.entries(this.licences)) {
                    if (value) {
                        formData.append(`subscriptions_${key}`, value);
                    }
                }
            }

            return formData;
        },
        postForm() {
            if (this.isAdmin) {
                if (this.ownerId === null) {
                    throw "Invalid owner id";
                }

                this.postFormAdmin();
            } else {
                this.postFormOwner();
            }
        },
        postFormAdmin() {
            this.formLoading = true;
            this.errors.form = null;

            const formData = this.buildForm();

            let promise = null;
            if (this.restaurant_id) {
                promise = this.httpPost(`/api/admin/owners/${this.ownerId}/restaurants/${this.restaurant_id}`, formData);
            } else {
                promise = this.httpPost(`/api/admin/owners/${this.ownerId}/restaurants`, formData);
            }

            promise.then((response) => {
                if (response !== false) {
                    this.$router.push({ name: "admin.ownerDetails", params: { owner_id: this.ownerId } });
                } else {
                    this.formLoading = false;
                }
            });
        },
        postFormOwner() {
            this.formLoading = true;
            this.errors.form = null;

            const formData = this.buildForm();

            let promise = null;
            if (this.restaurant_id) {
                promise = this.$store.dispatch("restaurants/editRestaurant", {
                    restaurant_id: this.restaurant_id,
                    formData,
                });
            } else {
                promise = this.$store.dispatch("restaurants/addRestaurant", {
                    formData,
                });
            }
            promise
                .then((response) => {
                    this.$store
                        .dispatch("restaurants/fetchAllRestaurants", { store: true })
                        .then(() => {
                            this.notifySuccess(response);

                            this.$store.dispatch("users/fetchCurrentUser", { set: true }).catch(() => false);
                            if (!this.restaurant_id) {
                                this.restaurant_id = response.data.restaurant_id;
                                this.showCreateRestaurantModal = true;
                            } else {
                                // redirect to edit page
                                this.$router.push({ name: "restaurants" });
                            }
                        })
                        .catch(() => false);
                })
                .catch((error) => {
                    this.notifyError(error);

                    if (error.response && error.response.data && error.response.data.errors) {
                        this.errors.form = error.response.data.errors;
                    }

                    this.formLoading = false;
                });
        },
        onCreateRestaurantModalClosed() {
            this.$store
                .dispatch("restaurants/fetchAllRestaurants", { store: true })
                .then(() => {
                    this.$store.dispatch("users/fetchCurrentUser", { set: true }).catch(() => false);
                    // redirect to edit page
                    this.$router.push({ name: "restaurants" });
                })
                .catch(() => false);
        },
        fetchRestaurantById(restaurant_id) {
            this.loading = true;
            this.error = false;
            this.$store
                .dispatch("restaurants/fetchRestaurantById", {
                    restaurant_id: restaurant_id,
                    includes: ["subscriptions", "notifications_setting", "has_psp", "google_maps_address"],
                })
                .then((response) => {
                    this.fillRestaurant(response.data);
                    this.hasFillGoogleMapsAddress = response.data.google_maps_address !== null;
                    this.loading = false;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                    this.loading = false;
                });
        },
    },
    components: {
        DeleteRestaurantModal,
        LoaderComponent,
        ImageComponent,
        "chrome-picker": Chrome,
        EditSmsSettings,
        Cgv,
        CreateRestaurantModal,
        ChooseCardPaymentSystem,
        TimeZonePicker,
        ShowNewBadgeDetails,
        InputComponent,
        TelInputComponent,
        ChooseCurrency,
        ShowErrors,
        ChooseLexicon,
        PostalAddressAutocomplete,
        SwitchInputComponent,
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
