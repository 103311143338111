var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.getTooltip(_vm.labelHasNotRights),
            expression: "getTooltip(labelHasNotRights)",
          },
        ],
        staticClass: "tile restaurant border-light",
        class: { clickable: _vm.hasRights, disabled: !_vm.hasRights },
        on: {
          click: function ($event) {
            return _vm.emitClick(_vm.link)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "logo" },
          [
            typeof _vm.link.icon !== "undefined"
              ? _c("feather", {
                  staticClass: "grey feather-40",
                  attrs: { type: _vm.link.icon },
                })
              : typeof _vm.link.rawIcon !== "undefined"
              ? _c("i", {
                  staticClass: "grey",
                  domProps: { innerHTML: _vm._s(_vm.link.rawIcon) },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "h6",
          { staticClass: "restaurant-title border-top-light text-center" },
          [_vm._v(_vm._s(_vm.link.name))]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }