var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-5 sevices-report" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row m-0 pb-5" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.loading
            ? _c("LoaderComponent")
            : _c("div", [
                _vm.error
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.error) +
                          "\n                "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.stats !== null && _vm.has_right_to_read
                  ? _c("div", [
                      _c("div", { staticClass: "row mb-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "border-light b-radius-20 p-4" },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-5" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.stat_period,
                                              expression: "stat_period",
                                            },
                                          ],
                                          staticClass: "custom-select mb-0",
                                          attrs: { name: "stat_period" },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.stat_period = $event.target
                                                .multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "today" } },
                                            [_vm._v("Aujourd'hui")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "yesterday" } },
                                            [_vm._v("Hier")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "last_week" } },
                                            [_vm._v("La semaine dernière")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "last_month" } },
                                            [_vm._v("Le mois dernier")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "last_year" } },
                                            [_vm._v("L'an dernier")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            {
                                              attrs: { value: "custom_period" },
                                            },
                                            [_vm._v("Période Personnalisée")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _vm.stat_period === "custom_period"
                                    ? _c("div", { staticClass: "row mt-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 d-flex" },
                                          [
                                            _c("datepicker", {
                                              staticClass:
                                                "d-inline-block date-resa-cal",
                                              staticStyle: { width: "150px" },
                                              attrs: {
                                                disabledDates:
                                                  _vm.disabledDates,
                                                format: "dd/MM/yyyy",
                                                "input-class": "form-control",
                                                placeholder: "Date de début",
                                                "monday-first": true,
                                                language: _vm.fr,
                                              },
                                              model: {
                                                value: _vm.from_date,
                                                callback: function ($$v) {
                                                  _vm.from_date = $$v
                                                },
                                                expression: "from_date",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("feather", {
                                              staticClass: "m-2",
                                              attrs: { type: "arrow-right" },
                                            }),
                                            _vm._v(" "),
                                            _c("datepicker", {
                                              staticClass:
                                                "d-inline-block date-resa-cal",
                                              staticStyle: { width: "150px" },
                                              attrs: {
                                                disabledDates:
                                                  _vm.disabledDates,
                                                format: "dd/MM/yyyy",
                                                "input-class": "form-control",
                                                placeholder: "Date de fin",
                                                "monday-first": true,
                                                language: _vm.fr,
                                              },
                                              model: {
                                                value: _vm.to_date,
                                                callback: function ($$v) {
                                                  _vm.to_date = $$v
                                                },
                                                expression: "to_date",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-2 text-center pt-2 pb-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-inline-block align-middle lead-switch ml-1",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.stat_comparison,
                                              expression: "stat_comparison",
                                            },
                                          ],
                                          staticClass:
                                            "switch align-self-center is-rounded",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.stat_comparison
                                            )
                                              ? _vm._i(
                                                  _vm.stat_comparison,
                                                  null
                                                ) > -1
                                              : _vm.stat_comparison,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.stat_comparison,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.stat_comparison =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.stat_comparison = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.stat_comparison = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("label", {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value:
                                                _vm.getTooltip("Comparaison"),
                                              expression:
                                                "getTooltip('Comparaison')",
                                            },
                                          ],
                                          on: {
                                            click: function ($event) {
                                              _vm.stat_comparison =
                                                !_vm.stat_comparison
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.stat_comparison
                                  ? _c("div", { staticClass: "col-md-5" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col-12" }, [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.comparison_period,
                                                  expression:
                                                    "comparison_period",
                                                },
                                              ],
                                              staticClass:
                                                "custom-select mb-0 mr-2",
                                              attrs: {
                                                name: "stat_comparison_period",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.comparison_period = $event
                                                    .target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "previous_period",
                                                  },
                                                },
                                                [_vm._v("Période précédente")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "previous_year",
                                                  },
                                                },
                                                [_vm._v("Année précédente")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "custom_period",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Période Personnalisée"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _vm.comparison_period === "custom_period"
                                        ? _c(
                                            "div",
                                            { staticClass: "row mt-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-12 d-flex",
                                                },
                                                [
                                                  _c("datepicker", {
                                                    staticClass:
                                                      "d-inline-block date-resa-cal",
                                                    staticStyle: {
                                                      width: "150px",
                                                    },
                                                    attrs: {
                                                      disabledDates:
                                                        _vm.disabledDates,
                                                      format: "dd/MM/yyyy",
                                                      "input-class":
                                                        "form-control",
                                                      placeholder:
                                                        "Date de début",
                                                      "monday-first": true,
                                                      language: _vm.fr,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.from_date_comparison,
                                                      callback: function ($$v) {
                                                        _vm.from_date_comparison =
                                                          $$v
                                                      },
                                                      expression:
                                                        "from_date_comparison",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("feather", {
                                                    staticClass: "m-2",
                                                    attrs: {
                                                      type: "arrow-right",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("datepicker", {
                                                    staticClass:
                                                      "d-inline-block date-resa-cal",
                                                    staticStyle: {
                                                      width: "150px",
                                                    },
                                                    attrs: {
                                                      disabledDates:
                                                        _vm.disabledDates,
                                                      format: "dd/MM/yyyy",
                                                      "input-class":
                                                        "form-control",
                                                      placeholder:
                                                        "Date de fin",
                                                      "monday-first": true,
                                                      language: _vm.fr,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.to_date_comparison,
                                                      callback: function ($$v) {
                                                        _vm.to_date_comparison =
                                                          $$v
                                                      },
                                                      expression:
                                                        "to_date_comparison",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mt-1" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("small", { staticClass: "text-muted" }, [
                                    _vm._v(
                                      "(les statistiques ne concernent que\n                                            " +
                                        _vm._s(
                                          _vm.keepClientsDetailsAndHistoryFor >
                                            1
                                            ? `les ${_vm.keepClientsDetailsAndHistoryFor} dernières années`
                                            : "l'année dernière"
                                        ) +
                                        ")"
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mt-2" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-success btn-circle",
                                      on: {
                                        click: function ($event) {
                                          return _vm.fetchData()
                                        },
                                      },
                                    },
                                    [_vm._v("Appliquer")]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row m-0 mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("strong", { staticClass: "text-success" }, [
                            _vm._v(_vm._s(_vm.labelPeriodMain)),
                          ]),
                          _vm._v(" "),
                          _vm.displaying.stat_comparison
                            ? _c("span", [
                                _vm._v(
                                  "\n                                comparé à "
                                ),
                                _c("strong", { staticClass: "text-success" }, [
                                  _vm._v(_vm._s(_vm.labelPeriodComparison)),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row m-0 click-collect-report" },
                        [
                          _c("div", { staticClass: "col-lg-3 col-md-6 p-1" }, [
                            _c(
                              "div",
                              { staticClass: "border-light p-3 b-radius-20" },
                              [
                                _c("span", [_vm._v("Total des ventes")]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("strong", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatCurrency(
                                          _vm.stats.main.total_amount / 100
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.displaying.stat_comparison
                                  ? [
                                      !(
                                        _vm.stats.main.total_amount == 0 &&
                                        _vm.stats.comparison.total_amount == 0
                                      )
                                        ? _c("ArrowPercentEvolutionComponent", {
                                            attrs: {
                                              percent:
                                                _vm.stats.evolution
                                                  .total_amount,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        { staticClass: "text-muted" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.labelPeriodComparison)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "text-success" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatCurrency(
                                                  _vm.stats.comparison
                                                    .total_amount / 100
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-lg-3 col-md-6 p-1" }, [
                            _c(
                              "div",
                              { staticClass: "border-light p-3 b-radius-20" },
                              [
                                _c("span", [_vm._v("Ventes nettes")]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("strong", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatCurrency(
                                          _vm.stats.main.total_net_amount / 100
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.displaying.stat_comparison
                                  ? [
                                      !(
                                        _vm.stats.main.total_net_amount == 0 &&
                                        _vm.stats.comparison.total_net_amount ==
                                          0
                                      )
                                        ? _c("ArrowPercentEvolutionComponent", {
                                            attrs: {
                                              percent:
                                                _vm.stats.evolution
                                                  .total_net_amount,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        { staticClass: "text-muted" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.labelPeriodComparison)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "text-success" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatCurrency(
                                                  _vm.stats.comparison
                                                    .total_net_amount / 100
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-lg-3 col-md-6 p-1" }, [
                            _c(
                              "div",
                              { staticClass: "border-light p-3 b-radius-20" },
                              [
                                _c("span", [_vm._v("Commandes")]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("strong", { staticClass: "value" }, [
                                    _vm._v(_vm._s(_vm.stats.main.nb_sales)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.displaying.stat_comparison
                                  ? [
                                      !(
                                        _vm.stats.main.nb_sales == 0 &&
                                        _vm.stats.comparison.nb_sales == 0
                                      )
                                        ? _c("ArrowPercentEvolutionComponent", {
                                            attrs: {
                                              percent:
                                                _vm.stats.evolution.nb_sales,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        { staticClass: "text-muted" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.labelPeriodComparison)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "text-success" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.stats.comparison.nb_sales
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-lg-3 col-md-6 p-1" }, [
                            _c(
                              "div",
                              { staticClass: "border-light p-3 b-radius-20" },
                              [
                                _c("span", [_vm._v("Panier moyen")]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("strong", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatCurrency(
                                          _vm.stats.main.avg_amount / 100
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.displaying.stat_comparison
                                  ? [
                                      !(
                                        _vm.stats.main.avg_amount == 0 &&
                                        _vm.stats.comparison.avg_amount == 0
                                      )
                                        ? _c("ArrowPercentEvolutionComponent", {
                                            attrs: {
                                              percent:
                                                _vm.stats.evolution.avg_amount,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        { staticClass: "text-muted" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.labelPeriodComparison)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "text-success" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatCurrency(
                                                  _vm.stats.comparison
                                                    .avg_amount / 100
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.isAdmin && !_vm.isTvaDisabled
                        ? _c(
                            "div",
                            { staticClass: "row m-0 click-collect-report" },
                            [
                              _c(
                                "div",
                                { staticClass: "col-lg-3 col-md-6 p-1" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "border-light p-3 b-radius-20",
                                    },
                                    [
                                      _c("span", [_vm._v("TVA 5.5%")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("strong", { staticClass: "value" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatCurrency(
                                                _vm.stats.main.tva
                                                  .five_dot_five / 100
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _vm.displaying.stat_comparison
                                        ? [
                                            !(
                                              _vm.stats.main.tva
                                                .five_dot_five == 0 &&
                                              _vm.stats.comparison.tva
                                                .five_dot_five == 0
                                            )
                                              ? _c(
                                                  "ArrowPercentEvolutionComponent",
                                                  {
                                                    attrs: {
                                                      percent:
                                                        _vm.stats.evolution.tva
                                                          .five_dot_five,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "small",
                                              { staticClass: "text-muted" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.labelPeriodComparison
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "text-success" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatCurrency(
                                                        _vm.stats.comparison.tva
                                                          .five_dot_five / 100
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-lg-3 col-md-6 p-1" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "border-light p-3 b-radius-20",
                                    },
                                    [
                                      _c("span", [_vm._v("TVA 10%")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("strong", { staticClass: "value" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatCurrency(
                                                _vm.stats.main.tva.ten / 100
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _vm.displaying.stat_comparison
                                        ? [
                                            !(
                                              _vm.stats.main.tva.ten == 0 &&
                                              _vm.stats.comparison.tva.ten == 0
                                            )
                                              ? _c(
                                                  "ArrowPercentEvolutionComponent",
                                                  {
                                                    attrs: {
                                                      percent:
                                                        _vm.stats.evolution.tva
                                                          .ten,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "small",
                                              { staticClass: "text-muted" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.labelPeriodComparison
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "text-success" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatCurrency(
                                                        _vm.stats.comparison.tva
                                                          .ten / 100
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-lg-3 col-md-6 p-1" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "border-light p-3 b-radius-20",
                                    },
                                    [
                                      _c("span", [_vm._v("TVA 20%")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("strong", { staticClass: "value" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatCurrency(
                                                _vm.stats.main.tva.twenty / 100
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _vm.displaying.stat_comparison
                                        ? [
                                            !(
                                              _vm.stats.main.tva.twenty == 0 &&
                                              _vm.stats.comparison.tva.twenty ==
                                                0
                                            )
                                              ? _c(
                                                  "ArrowPercentEvolutionComponent",
                                                  {
                                                    attrs: {
                                                      percent:
                                                        _vm.stats.evolution.tva
                                                          .twenty,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "small",
                                              { staticClass: "text-muted" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.labelPeriodComparison
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "text-success" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatCurrency(
                                                        _vm.stats.comparison.tva
                                                          .twenty / 100
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-lg-3 col-md-6 p-1" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "border-light p-3 b-radius-20",
                                    },
                                    [
                                      _c("span", [_vm._v("TVA totale")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("strong", { staticClass: "value" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatCurrency(
                                                _vm.stats.main.tva.total / 100
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _vm.displaying.stat_comparison
                                        ? [
                                            !(
                                              _vm.stats.main.tva.total == 0 &&
                                              _vm.stats.comparison.tva.total ==
                                                0
                                            )
                                              ? _c(
                                                  "ArrowPercentEvolutionComponent",
                                                  {
                                                    attrs: {
                                                      percent:
                                                        _vm.stats.evolution.tva
                                                          .total,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "small",
                                              { staticClass: "text-muted" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.labelPeriodComparison
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "text-success" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatCurrency(
                                                        _vm.stats.comparison.tva
                                                          .total / 100
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : !_vm.has_right_to_read
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(
                        "Désolé, vous n'avez pas le droit d'accéder à cette page."
                      ),
                    ])
                  : _vm._e(),
              ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [
            _c("h5", { staticClass: "title mt-2" }, [
              _vm._v("Rapport Financier"),
            ]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }