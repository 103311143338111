<template>
    <div class="row m-0 div-valid-resa">
        <div class="col-lg-8 offset-lg-2 mb-3">
            <div class="bg-danger text-center text-white p-1" style="border-radius: 100px">
                {{ $tl("labels.booking.reservations.reservationNotMatch") }}
            </div>
            <div class="border-light b-radius-20 p-4 text-center">
                <div class="row">
                    <div class="col-12">
                        <div>{{ $tl("labels.booking.reservations.dateAndHour") }}</div>
                        <div class="value">
                            {{ displayDate(reservation.reservation_datetime, DATE_MED) }}
                        </div>
                    </div>
                </div>
                <div class="sepa"></div>
                <div class="row">
                    <div class="col-12">{{ $tl("labels.booking.reservations.grid.columns.client") }}</div>
                    <div class="col-12 value">
                        <inline-client :client="reservation.client" :module-enum="MODULE_TYPE_BOOKING"></inline-client>
                    </div>
                </div>
                <template v-if="reservation.tables.data.length > 0 || reservation.num_table">
                    <div class="sepa"></div>
                    <div class="row">
                        <div class="col-12">{{ $tl("labels.booking.reservations.grid.columns.table", restaurant_id) }}</div>
                        <div class="col-12 value">
                            <template v-for="(table, index) in reservation.tables.data">
                                {{ table.name }}{{ index + 1 < reservation.tables.data.length ? " - " : "" }}
                            </template>
                            <template v-if="reservation.num_table && reservation.num_table !== ''">
                                {{ reservation.num_table }}
                            </template>
                        </div>
                    </div>
                </template>
                <div class="sepa"></div>
                <div class="row">
                    <div class="col-6">
                        <div>{{ $tl("labels.booking.reservations.edit.nbPers") }}</div>
                        <div class="value">
                            {{ reservation.nb_pers }}
                        </div>
                    </div>
                    <div class="col-6">
                        <div>{{ $tl("labels.booking.reservations.edit.nbChildren") }}</div>
                        <div class="value">
                            {{ reservation.nb_children }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InlineClient from "../Default/Clients/inlineClient.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";

export default {
    name: "showReservation",
    data() {
        return {};
    },
    mixins: [ModuleTypesEnum],
    props: {
        reservation: {
            required: true,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    components: {
        InlineClient,
    },
};
</script>
