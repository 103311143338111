export default {
    data() {
        return {
            SETTINGS_STEP_WEBSITE_SETTINGS: {
                value: "website_settings",
            },
            SETTINGS_STEP_GET_TOKEN: {
                value: "get_token",
            },
            SETTINGS_STEP_VERIFY: {
                value: "verify",
            },
            SETTINGS_STEP_PUBLISH: {
                value: "publish",
            },
            SETTINGS_STEP_PUBLISHED: {
                value: "published",
            },
        };
    },
    methods: {},
    computed: {
        ALL_SETTINGS_STEPS() {
            return [
                this.SETTINGS_STEP_WEBSITE_SETTINGS,
                this.SETTINGS_STEP_GET_TOKEN,
                this.SETTINGS_STEP_VERIFY,
                this.SETTINGS_STEP_PUBLISH,
                this.SETTINGS_STEP_PUBLISHED,
            ];
        },
    },
};
