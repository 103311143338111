var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n        " + _vm._s(_vm.error) + "\n    "),
          ])
        : _c(
            "div",
            [
              _vm.formErrors && _vm.formErrors.message
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _c("p", [_vm._v(_vm._s(_vm.$tl("errors.common.unknown")))]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.formErrors && _vm.formErrors.max_pax
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _c("p", [_vm._v(_vm._s(_vm.formErrors.max_pax))]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.seatingPlanError || _vm.vueMultiSelect.isInvalid
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _vm.seatingPlanError
                      ? _c("p", {
                          domProps: { innerHTML: _vm._s(_vm.seatingPlanError) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.vueMultiSelect.isInvalid
                      ? _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$tl(
                                "labels.booking.reservations.edit.tableNotAvailable",
                                _vm.restaurant_id
                              )
                            )
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.formSuccess
                ? _c("div", { staticClass: "alert alert-success" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.formSuccess) + "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isImmutable
                ? _c("div", { staticClass: "mb-2" }, [
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm.$tl(
                            "infos.booking.reservations.immutable",
                            undefined,
                            { theme: _vm.themeUppercased }
                          )
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.formLoading
                ? _c("LoaderComponent")
                : _c(
                    "form",
                    {
                      attrs: {
                        id: "editReservation",
                        method: "post",
                        autocomplete: "off",
                      },
                      on: { submit: _vm.postForm },
                    },
                    [
                      _c("div", { staticClass: "row m-0" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "border-light b-radius-20 p-4" },
                            [
                              _vm.loading.services
                                ? _c("LoaderComponent")
                                : _c(
                                    "div",
                                    [
                                      _c("services-reservations", {
                                        ref: "servicesReservations",
                                        attrs: {
                                          formErrors: _vm.formErrors,
                                          nb_pers: _vm.nb_pers,
                                          nb_children: _vm.nb_children,
                                          restaurant_id:
                                            _vm.selected_restaurant_id,
                                          reservation_date:
                                            _vm.reservation_date,
                                          readOnly:
                                            _vm.loading.service ||
                                            _vm.loading.tables ||
                                            _vm.isImmutable,
                                          hasTablesSelected:
                                            _vm.hasTablesSelected,
                                        },
                                        on: {
                                          "update-reservation-date":
                                            _vm.handleReservationDate,
                                          "update-nb-pers": _vm.handleNbPers,
                                          "update-nb-children":
                                            _vm.handleNbChildren,
                                          "unlink-all-tables":
                                            _vm.unlinkAllTables,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row m-0 mt-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "border-light b-radius-20 p-4" },
                            [
                              _vm.loading.slots
                                ? _c("LoaderComponent")
                                : _c("div", [
                                    _vm.openServicesError
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "invalid-feedback d-block",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.openServicesError
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.openServices &&
                                    _vm.openServices.length > 0
                                      ? _c("div", { staticClass: "row" }, [
                                          _c("div", { staticClass: "col-12" }, [
                                            _vm.openServices &&
                                            _vm.openServices.length > 0
                                              ? _c(
                                                  "ul",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "-40px",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.openServices,
                                                    function (service) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "list-slot-resa",
                                                          staticStyle: {
                                                            float: "left",
                                                            width: "32%",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "slots-reservations",
                                                            {
                                                              ref: "slotsReservations",
                                                              refInFor: true,
                                                              attrs: {
                                                                reservation_date:
                                                                  _vm.reservation_date,
                                                                readOnly:
                                                                  _vm.loading
                                                                    .service ||
                                                                  _vm.loading
                                                                    .tables ||
                                                                  _vm.isImmutable,
                                                                hasTablesSelected:
                                                                  _vm.hasTablesSelected,
                                                                formErrors:
                                                                  _vm.formErrors,
                                                                service_id:
                                                                  service,
                                                                restaurant_id:
                                                                  _vm.selected_restaurant_id,
                                                                slot_id:
                                                                  _vm.slot_id,
                                                                preselected_service_id:
                                                                  _vm.old_service_id,
                                                                preselected_slot_hour:
                                                                  _vm.old_slot_hour,
                                                              },
                                                              on: {
                                                                "update-slot-id":
                                                                  _vm.handleSlotId,
                                                                "unlink-all-tables":
                                                                  _vm.unlinkAllTables,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-12 mt-3" }, [
                                      _c("div", { staticClass: "row m-0" }, [
                                        _vm.restaurants_count &&
                                        _vm.restaurants_count > 1 &&
                                        _vm.selected_destination_restaurant ===
                                          null
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-12 p-0 text-right",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value:
                                                          _vm.hasTablesSelected
                                                            ? {
                                                                content:
                                                                  _vm.$tl(
                                                                    "infos.booking.reservations.edit.changeRestaurant"
                                                                  ),
                                                              }
                                                            : undefined,
                                                        expression:
                                                          "\n                                                hasTablesSelected\n                                                    ? {\n                                                          content: $tl('infos.booking.reservations.edit.changeRestaurant'),\n                                                      }\n                                                    : undefined\n                                            ",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "d-inline-block",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-success btn-circle",
                                                        attrs: {
                                                          disabled:
                                                            _vm.option_bank ||
                                                            _vm.hasTablesSelected ||
                                                            _vm.isImmutable,
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.showSelectRestaurantModal = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass:
                                                              "feather css-i6dzq1",
                                                            attrs: {
                                                              viewBox:
                                                                "0 0 24 24",
                                                              width: "24",
                                                              height: "24",
                                                              stroke:
                                                                "currentColor",
                                                              "stroke-width":
                                                                "2",
                                                              fill: "none",
                                                              "stroke-linecap":
                                                                "round",
                                                              "stroke-linejoin":
                                                                "round",
                                                            },
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                d: "M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("polyline", {
                                                              attrs: {
                                                                points:
                                                                  "16 17 21 12 16 7",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("line", {
                                                              attrs: {
                                                                x1: "21",
                                                                y1: "12",
                                                                x2: "9",
                                                                y2: "12",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.$tl(
                                                                "labels.booking.reservations.edit.changeRestaurant",
                                                                _vm.restaurant_id
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.option_bank
                                                  ? _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "d-block text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm.$tl(
                                                                "infos.booking.reservations.edit.cantChangeRestaurant"
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _vm._v(" "),
                                      _vm.selected_destination_restaurant
                                        ? _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-12 d-flex justify-content-between",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass: "pt-1",
                                                    staticStyle: {
                                                      color: "#30a4b7",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        staticClass:
                                                          "feather css-i6dzq1",
                                                        attrs: {
                                                          viewBox: "0 0 24 24",
                                                          width: "24",
                                                          height: "24",
                                                          stroke:
                                                            "currentColor",
                                                          "stroke-width": "2",
                                                          fill: "none",
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round",
                                                        },
                                                      },
                                                      [
                                                        _c("polyline", {
                                                          attrs: {
                                                            points:
                                                              "15 10 20 15 15 20",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M4 4v7a4 4 0 0 0 4 4h12",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm
                                                            .selected_destination_restaurant
                                                            .name
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-success btn-circle",
                                                      attrs: {
                                                        type: "button",
                                                        disabled:
                                                          _vm.hasTablesSelected ||
                                                          _vm.isImmutable,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.goBackToBaseRestaurant()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "feather feather-arrow-left",
                                                          attrs: {
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                            width: "24",
                                                            height: "24",
                                                            viewBox:
                                                              "0 0 24 24",
                                                            fill: "none",
                                                            stroke:
                                                              "currentColor",
                                                            "stroke-width": "2",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        },
                                                        [
                                                          _c("line", {
                                                            attrs: {
                                                              x1: "19",
                                                              y1: "12",
                                                              x2: "5",
                                                              y2: "12",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("polyline", {
                                                            attrs: {
                                                              points:
                                                                "12 19 5 12 12 5",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "labels.backToRestaurant",
                                                              {
                                                                restaurant:
                                                                  _vm
                                                                    .baseRestaurant
                                                                    .name,
                                                              }
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.restaurants_count &&
                                                  _vm.restaurants_count > 2
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-sm btn-success btn-circle",
                                                          attrs: {
                                                            type: "button",
                                                            disabled:
                                                              _vm.hasTablesSelected ||
                                                              _vm.isImmutable,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.showSelectRestaurantModal = true
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "feather css-i6dzq1",
                                                              attrs: {
                                                                viewBox:
                                                                  "0 0 24 24",
                                                                width: "24",
                                                                height: "24",
                                                                stroke:
                                                                  "currentColor",
                                                                "stroke-width":
                                                                  "2",
                                                                fill: "none",
                                                                "stroke-linecap":
                                                                  "round",
                                                                "stroke-linejoin":
                                                                  "round",
                                                              },
                                                            },
                                                            [
                                                              _c("path", {
                                                                attrs: {
                                                                  d: "M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("polyline", {
                                                                attrs: {
                                                                  points:
                                                                    "16 17 21 12 16 7",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("line", {
                                                                attrs: {
                                                                  x1: "21",
                                                                  y1: "12",
                                                                  x2: "9",
                                                                  y2: "12",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(
                                                                _vm.$tl(
                                                                  "labels.changeRestaurant"
                                                                )
                                                              ) +
                                                              "\n                                            "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.nb_pers_total > 0 && _vm.slotSelected
                        ? _c("div", { staticClass: "row m-0 mt-3" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "border-light b-radius-20 p-4" },
                                [
                                  _vm.loading.service
                                    ? _c("LoaderComponent")
                                    : _c("div", [
                                        _c("div", [
                                          _vm.service &&
                                          _vm.service
                                            .is_table_rotation_enabled == true
                                            ? _c(
                                                "div",
                                                { staticClass: "row mb-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "col-6 pt-1",
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.booking.reservations.edit.duration"
                                                            )
                                                          ) + " "
                                                        ),
                                                        _c("small", [
                                                          _vm._v("*"),
                                                        ]),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-6" },
                                                    [
                                                      _c("vue-timepicker", {
                                                        attrs: {
                                                          format: "HH:mm",
                                                          "minute-interval": 15,
                                                          disabled:
                                                            _vm.hasTablesSelected ||
                                                            _vm.isImmutable,
                                                          lazy: true,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            _vm.is_duration_customized = true
                                                          },
                                                        },
                                                        model: {
                                                          value: _vm.duration,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.duration = $$v
                                                          },
                                                          expression:
                                                            "duration",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.service &&
                                          _vm.nb_pers_total > 0 &&
                                          _vm.service.has_seating_plan == true
                                            ? _c(
                                                "div",
                                                [
                                                  _vm.loading.tables
                                                    ? _c("LoaderComponent")
                                                    : _c("div", [
                                                        _vm.vueMultiSelect
                                                          .paxFull
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row mt-2 mb-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "offset-6 col-md-6",
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$tl(
                                                                            "labels.booking.reservations.fullyPlaced"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row mb-2",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-md-6 pt-2",
                                                              },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$tl(
                                                                        "labels.booking.reservations.table",
                                                                        _vm.restaurant_id
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-md-6",
                                                              },
                                                              [
                                                                _c(
                                                                  "vue-multiselect",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "12.8px !important",
                                                                      },
                                                                    attrs: {
                                                                      options:
                                                                        _vm.tablesMultiselectOptions,
                                                                      multiple: true,
                                                                      label:
                                                                        "name",
                                                                      "track-by":
                                                                        "name",
                                                                      "group-values":
                                                                        "entities",
                                                                      "group-label":
                                                                        "roomName",
                                                                      "group-select": false,
                                                                      "close-on-select": false,
                                                                      "clear-on-select": false,
                                                                      "preserve-search": true,
                                                                      "show-labels": false,
                                                                      placeholder:
                                                                        "Tables",
                                                                      "custom-label":
                                                                        _vm.customTablesLabel,
                                                                      "open-direction":
                                                                        "bottom",
                                                                      disabled:
                                                                        _vm.ignore_placement ||
                                                                        _vm.isImmutable,
                                                                    },
                                                                    on: {
                                                                      select:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.tableSelected(
                                                                            $event
                                                                          )
                                                                        },
                                                                      remove:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.tableUnselected(
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "option",
                                                                            fn: function (
                                                                              props
                                                                            ) {
                                                                              return [
                                                                                !props
                                                                                  .option
                                                                                  .$isLabel
                                                                                  ? [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "float-left",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                " +
                                                                                              _vm._s(
                                                                                                props
                                                                                                  .option
                                                                                                  .type ===
                                                                                                  "table"
                                                                                                  ? _vm.$tl(
                                                                                                      "labels.booking.seatingPlan.table.title"
                                                                                                    )
                                                                                                  : _vm.$tl(
                                                                                                      "labels.booking.seatingPlan.group.title"
                                                                                                    )
                                                                                              ) +
                                                                                              "\n                                                                " +
                                                                                              _vm._s(
                                                                                                props
                                                                                                  .option
                                                                                                  .name
                                                                                              ) +
                                                                                              "\n                                                            "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      props
                                                                                        .option
                                                                                        .enabledPax
                                                                                        .length >
                                                                                      0
                                                                                        ? _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "float-right",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                                                " +
                                                                                                  _vm._s(
                                                                                                    _vm.capitalize(
                                                                                                      _vm.$tl(
                                                                                                        "labels.pax"
                                                                                                      )
                                                                                                    )
                                                                                                  ) +
                                                                                                  " :\n                                                                "
                                                                                              ),
                                                                                              _vm._l(
                                                                                                props
                                                                                                  .option
                                                                                                  .enabledPax,
                                                                                                function (
                                                                                                  item,
                                                                                                  index
                                                                                                ) {
                                                                                                  return [
                                                                                                    _vm._v(
                                                                                                      "\n                                                                    " +
                                                                                                        _vm._s(
                                                                                                          index >
                                                                                                            0
                                                                                                            ? " "
                                                                                                            : ""
                                                                                                        ) +
                                                                                                        _vm._s(
                                                                                                          props
                                                                                                            .option
                                                                                                            .enabledPax
                                                                                                            .length -
                                                                                                            1 ===
                                                                                                            index
                                                                                                            ? `${item}`
                                                                                                            : `${item},`
                                                                                                        ) +
                                                                                                        "\n                                                                "
                                                                                                    ),
                                                                                                  ]
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            2
                                                                                          )
                                                                                        : _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "float-right",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                                                " +
                                                                                                  _vm._s(
                                                                                                    _vm.capitalize(
                                                                                                      _vm.$tl(
                                                                                                        "labels.pax"
                                                                                                      )
                                                                                                    )
                                                                                                  ) +
                                                                                                  " :\n                                                                " +
                                                                                                  _vm._s(
                                                                                                    props
                                                                                                      .option
                                                                                                      .seats
                                                                                                  ) +
                                                                                                  "\n                                                            "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                    ]
                                                                                  : [
                                                                                      _c(
                                                                                        "div",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              props
                                                                                                .option
                                                                                                .$groupLabel
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        false,
                                                                        519218257
                                                                      ),
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .vueMultiSelect
                                                                          .entitiesSelected,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.vueMultiSelect,
                                                                            "entitiesSelected",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "vueMultiSelect.entitiesSelected",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot: "noOptions",
                                                                      },
                                                                      [
                                                                        !_vm.slotSelected
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                            " +
                                                                                    _vm._s(
                                                                                      _vm.$tl(
                                                                                        "labels.booking.reservations.selectSlot"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                        "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                            " +
                                                                                    _vm._s(
                                                                                      _vm.$tl(
                                                                                        "labels.noResult"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                        "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                                _vm._v(" "),
                                                                _vm.service &&
                                                                _vm.service
                                                                  .is_seating_plan_algorithm_enabled &&
                                                                !_vm.loadingCanPlace &&
                                                                !_vm.can_place &&
                                                                _vm
                                                                  .vueMultiSelect
                                                                  .entitiesSelected
                                                                  .length === 0
                                                                  ? _c("div", [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "container-box text-danger mt-2 mb-1 w-auto",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "checkbox",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  checked:
                                                                                    _vm.ignore_placement,
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  _vm.toggleIgnorePlacement,
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "checkmark",
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$tl(
                                                                                "labels.booking.seatingPlan.ignorePlacement"
                                                                              )
                                                                            ) +
                                                                              "\n                                                    "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "text-danger",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "small",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$tl(
                                                                                    "infos.booking.seatingPlan.ignorePlacement"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                ],
                                                1
                                              )
                                            : _vm.nb_pers_total > 0 &&
                                              _vm.service &&
                                              _vm.service.has_seating_plan ==
                                                false
                                            ? _c(
                                                "div",
                                                { staticClass: "row mb-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-md-6 pt-2",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "num_table",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$tl(
                                                                "labels.booking.seatingPlan.table.title"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-6" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.num_table,
                                                            expression:
                                                              "num_table",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        class: {
                                                          "is-invalid":
                                                            _vm.hasErrors(
                                                              "num_table"
                                                            ),
                                                        },
                                                        attrs: {
                                                          id: "num_table",
                                                          type: "text",
                                                          tabindex: "4",
                                                          autocomplete: "off",
                                                        },
                                                        domProps: {
                                                          value: _vm.num_table,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.num_table =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.hasErrors("num_table")
                                                        ? _c(
                                                            "div",
                                                            _vm._l(
                                                              _vm.formErrors
                                                                .errors
                                                                .num_table,
                                                              function (err) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "invalid-feedback d-block",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                " +
                                                                        _vm._s(
                                                                          err
                                                                        ) +
                                                                        "\n                                            "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.serviceIdSelected &&
                      _vm.slotSelected &&
                      _vm.nb_pers > 0 &&
                      _vm.available_menus.length > 0
                        ? _c("div", { staticClass: "row m-0 mt-3" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "border-light b-radius-20 p-4" },
                                [
                                  _vm.loading.menus ||
                                  _vm.available_menus_trashed === null
                                    ? _c("LoaderComponent")
                                    : _c(
                                        "div",
                                        [
                                          _c("menus-reservation", {
                                            ref: "menusReservations",
                                            attrs: {
                                              "restaurant-id":
                                                _vm.selected_restaurant_id,
                                              formErrors: _vm.formErrors,
                                              available_menus:
                                                _vm.available_menus,
                                              available_menus_trashed:
                                                _vm.available_menus_trashed,
                                              choosen_menus: _vm.choosen_menus,
                                              are_menus_unique:
                                                _vm.areMenusUnique,
                                              readonly: _vm.isImmutable,
                                              nb_total:
                                                _vm.nb_pers * 1 +
                                                _vm.nb_children * 1,
                                            },
                                            on: {
                                              "update-menus":
                                                _vm.handleMenusChoosen,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.slotSelected &&
                      _vm.available_general_options &&
                      _vm.available_general_options.length > 0 &&
                      _vm.nb_pers > 0
                        ? _c("div", { staticClass: "row m-0 mt-3" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "border-light b-radius-20 p-4" },
                                [
                                  _vm.loading.general_options
                                    ? _c("LoaderComponent")
                                    : _c(
                                        "div",
                                        [
                                          _c("general-options-reservation", {
                                            ref: "generalOptionsReservation",
                                            attrs: {
                                              "restaurant-id":
                                                _vm.selected_restaurant_id,
                                              available_general_options:
                                                _vm.available_general_options,
                                              available_general_options_trashed:
                                                _vm.available_general_options_trashed,
                                              readonly: _vm.isImmutable,
                                              nb_pers_total: _vm.nb_pers_total,
                                            },
                                            model: {
                                              value:
                                                _vm.choosen_general_options,
                                              callback: function ($$v) {
                                                _vm.choosen_general_options =
                                                  $$v
                                              },
                                              expression:
                                                "choosen_general_options",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "row m-0 mt-3" }, [
                        _c("div", { staticClass: "col-md-7 mt-3" }, [
                          _c(
                            "div",
                            {
                              staticClass: "border-light b-radius-20 p-4",
                              class: _vm.associateClasses,
                            },
                            [
                              _vm.loading.clients
                                ? _c("LoaderComponent")
                                : _c(
                                    "div",
                                    [
                                      _vm.client.id !== null
                                        ? _c("clients-reservations", {
                                            ref: "clientsReservations",
                                            attrs: {
                                              formErrors: _vm.formErrors,
                                              restaurant_id:
                                                _vm.selected_restaurant_id,
                                              client: _vm.client,
                                              readonly: true,
                                            },
                                          })
                                        : _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-success btn-circle",
                                              attrs: {
                                                disabled: _vm.isImmutable,
                                                type: "button",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.canOpenSearchClientModal = true
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "labels.form.actions.associate.client"
                                                    )
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-5 mt-3" }, [
                          _c(
                            "div",
                            { staticClass: "border-light b-radius-20 p-4" },
                            [
                              _vm.loading.comments
                                ? _c("LoaderComponent")
                                : _c(
                                    "div",
                                    [
                                      _c("commentary-reservations", {
                                        ref: "commentaryReservations",
                                        attrs: {
                                          gift: _vm.giftCode,
                                          room_number_: _vm.room_number,
                                          is_hotel_client_: _vm.is_hotel_client,
                                          readonly: _vm.isImmutable,
                                          formErrors: _vm.formErrors,
                                          reservation_id: _vm.reservation_id,
                                        },
                                        on: {
                                          "add-gift-code":
                                            _vm.handleAddGiftCode,
                                          "set-room-number": function ($event) {
                                            _vm.room_number = $event
                                          },
                                          "set-is-hotel-client": function (
                                            $event
                                          ) {
                                            _vm.is_hotel_client = $event
                                          },
                                        },
                                        model: {
                                          value: _vm.restaurantComment,
                                          callback: function ($$v) {
                                            _vm.restaurantComment = $$v
                                          },
                                          expression: "restaurantComment",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.comment && _vm.comment !== ""
                        ? _c("div", { staticClass: "row m-0 mt-3" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "border-light b-radius-20 p-4" },
                                [
                                  _c("label", { staticClass: "mb-1" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.reservations.clientComment"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "row radio",
                                      staticStyle: { position: "relative" },
                                    },
                                    [
                                      _c("div", { staticClass: "col-12" }, [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.comment) +
                                            "\n                            "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canAddOptionBank
                        ? _c("div", { staticClass: "row m-0 mt-3" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "border-light b-radius-20 p-4" },
                                [
                                  _vm.loading.paiement
                                    ? _c("LoaderComponent")
                                    : _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "row radio",
                                            staticStyle: {
                                              position: "relative",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-3 mb-2" },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.booking.reservations.secure"
                                                      )
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-9" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "container-box",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.option_bank,
                                                          expression:
                                                            "option_bank",
                                                        },
                                                      ],
                                                      attrs: { type: "radio" },
                                                      domProps: {
                                                        value: null,
                                                        checked: _vm._q(
                                                          _vm.option_bank,
                                                          null
                                                        ),
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          _vm.option_bank = null
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      staticClass: "checkmark",
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$tl("labels.none")
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "none-desk",
                                                  staticStyle: {
                                                    height: "8px",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value:
                                                          _vm.isFootprintAvailable !==
                                                          true
                                                            ? {
                                                                content:
                                                                  _vm.isFootprintAvailable,
                                                              }
                                                            : undefined,
                                                        expression:
                                                          "isFootprintAvailable !== true ? { content: isFootprintAvailable } : undefined",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "container-box",
                                                    class: {
                                                      "cursor-disabled":
                                                        _vm.isFootprintAvailable !==
                                                        true,
                                                    },
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.option_bank,
                                                          expression:
                                                            "option_bank",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "radio",
                                                        disabled:
                                                          _vm.isFootprintAvailable !==
                                                            true ||
                                                          _vm.isImmutable,
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm
                                                            .OPTION_BANK_FOOTPRINT
                                                            .value,
                                                        checked: _vm._q(
                                                          _vm.option_bank,
                                                          _vm
                                                            .OPTION_BANK_FOOTPRINT
                                                            .value
                                                        ),
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          _vm.option_bank =
                                                            _vm.OPTION_BANK_FOOTPRINT.value
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      staticClass: "checkmark",
                                                      class: {
                                                        disabled:
                                                          _vm.isFootprintAvailable !==
                                                          true,
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.reservations.footprint.title"
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "none-desk",
                                                  staticStyle: {
                                                    height: "8px",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value:
                                                          _vm.isPaymentAvailable !==
                                                          true
                                                            ? {
                                                                content:
                                                                  _vm.isPaymentAvailable,
                                                              }
                                                            : undefined,
                                                        expression:
                                                          "isPaymentAvailable !== true ? { content: isPaymentAvailable } : undefined",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "container-box",
                                                    class: {
                                                      "cursor-disabled":
                                                        _vm.isPaymentAvailable !==
                                                        true,
                                                    },
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.option_bank,
                                                          expression:
                                                            "option_bank",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "radio",
                                                        disabled:
                                                          _vm.isPaymentAvailable !==
                                                            true ||
                                                          _vm.isImmutable,
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm
                                                            .OPTION_BANK_PAYMENT
                                                            .value,
                                                        checked: _vm._q(
                                                          _vm.option_bank,
                                                          _vm
                                                            .OPTION_BANK_PAYMENT
                                                            .value
                                                        ),
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          _vm.option_bank =
                                                            _vm.OPTION_BANK_PAYMENT.value
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      staticClass: "checkmark",
                                                      class: {
                                                        disabled:
                                                          _vm.isPaymentAvailable !==
                                                          true,
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.reservations.payment.title"
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.option_bank ===
                                        _vm.OPTION_BANK_PAYMENT.value
                                          ? _c(
                                              "div",
                                              { staticClass: "row mt-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-3" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.reservations.payment.amountToPay"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-9" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.amount_to_pay,
                                                          expression:
                                                            "amount_to_pay",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "number",
                                                        disabled:
                                                          !_vm.client ||
                                                          !_vm.client.email ||
                                                          _vm.isImmutable,
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.amount_to_pay,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.amount_to_pay =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "d-block text-danger mt-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$tl(
                                                                "infos.booking.reservations.payment.willBeSendOnSave"
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm.option_bank ===
                                              _vm.OPTION_BANK_FOOTPRINT.value &&
                                            _vm.footprint_date_send
                                          ? _c(
                                              "div",
                                              { staticClass: "offset-md-3" },
                                              [
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass:
                                                      "d-block alert-info text-info-noshow mt-2 p-2",
                                                  },
                                                  [
                                                    _vm.footprint_date_send ===
                                                    "NOW"
                                                      ? [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.$tl(
                                                                  "infos.booking.reservations.footprint.willBeSendOnSave"
                                                                )
                                                              ) +
                                                              "\n                                    "
                                                          ),
                                                        ]
                                                      : [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "infos.booking.reservations.footprint.willBeSendOnDate",
                                                                  {
                                                                    date: _vm.footprint_date_send,
                                                                  }
                                                                )
                                                              ) +
                                                              "\n                                    "
                                                          ),
                                                        ],
                                                  ],
                                                  2
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _c("div", { staticClass: "row m-0 mt-3" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "border-light b-radius-20 p-4" },
                                [
                                  _vm.loading.paiement
                                    ? _c("LoaderComponent")
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "row radio",
                                          staticStyle: { position: "relative" },
                                        },
                                        [
                                          _c("div", { staticClass: "col-12" }, [
                                            _vm.option_bank ===
                                            _vm.OPTION_BANK_PAYMENT.value
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.booking.reservations.payment.showResa.title"
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                  _vm.baseReservation.payment
                                                    .status ===
                                                  _vm.PAYMENT_STATUS_CAPTURED
                                                    .value
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.booking.reservations.payment.showResa.captured",
                                                                {
                                                                  amount:
                                                                    _vm.formatCurrency(
                                                                      _vm
                                                                        .baseReservation
                                                                        .payment
                                                                        .amount_captured /
                                                                        100
                                                                    ),
                                                                }
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ])
                                                    : _vm.baseReservation
                                                        .payment.status ===
                                                      _vm
                                                        .PAYMENT_STATUS_CAPTURABLE
                                                        .value
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.booking.reservations.payment.showResa.capturable",
                                                                {
                                                                  amount:
                                                                    _vm.formatCurrency(
                                                                      _vm
                                                                        .baseReservation
                                                                        .payment
                                                                        .stripe_intent_amount /
                                                                        100
                                                                    ),
                                                                }
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ])
                                                    : _vm.baseReservation
                                                        .payment.status ===
                                                      _vm
                                                        .PAYMENT_STATUS_REFUNDED
                                                        .value
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.booking.reservations.payment.showResa.refunded",
                                                                {
                                                                  amount:
                                                                    _vm.formatCurrency(
                                                                      _vm
                                                                        .baseReservation
                                                                        .payment
                                                                        .stripe_intent_amount /
                                                                        100
                                                                    ),
                                                                  date: _vm.displayDate(
                                                                    _vm
                                                                      .baseReservation
                                                                      .payment
                                                                      .refunded_at
                                                                  ),
                                                                }
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ])
                                                    : _vm.baseReservation
                                                        .payment.status ===
                                                      _vm
                                                        .PAYMENT_STATUS_PARTIALLY_REFUNDED
                                                        .value
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.booking.reservations.payment.showResa.refundedPartially",
                                                                {
                                                                  total_amount:
                                                                    _vm.formatCurrency(
                                                                      _vm
                                                                        .baseReservation
                                                                        .payment
                                                                        .stripe_intent_amount /
                                                                        100
                                                                    ),
                                                                  amount_refunded:
                                                                    _vm.formatCurrency(
                                                                      _vm
                                                                        .baseReservation
                                                                        .payment
                                                                        .amount_refunded /
                                                                        100
                                                                    ),
                                                                  date: _vm.displayDate(
                                                                    _vm
                                                                      .baseReservation
                                                                      .payment
                                                                      .refunded_at
                                                                  ),
                                                                }
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ])
                                                    : _vm.baseReservation
                                                        .payment.status ===
                                                      _vm
                                                        .PAYMENT_STATUS_CANCELED
                                                        .value
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$tl(
                                                                "labels.booking.reservations.payment.showResa.canceled"
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ])
                                                    : _vm.baseReservation
                                                        .payment.status ===
                                                      _vm.PAYMENT_STATUS_PENDING
                                                        .value
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.booking.reservations.payment.showResa.pending",
                                                                {
                                                                  amount:
                                                                    _vm.formatCurrency(
                                                                      _vm
                                                                        .baseReservation
                                                                        .payment
                                                                        .stripe_intent_amount /
                                                                        100
                                                                    ),
                                                                }
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ])
                                                    : _vm.baseReservation
                                                        .payment.status ===
                                                      _vm.PAYMENT_STATUS_EXPIRED
                                                        .value
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$tl(
                                                                "labels.booking.reservations.payment.showResa.expired"
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ])
                                              : _vm.option_bank ===
                                                _vm.OPTION_BANK_FOOTPRINT.value
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.booking.reservations.footprint.showResa.title"
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                  _vm.baseReservation.status ===
                                                    "canceled" ||
                                                  _vm.baseReservation.status ===
                                                    "refused"
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$tl(
                                                                "labels.booking.reservations.footprint.showResa.noOne"
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ])
                                                    : _vm.baseReservation
                                                        .payment.status ===
                                                      _vm.PAYMENT_STATUS_PENDING
                                                        .value
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$tl(
                                                                "labels.booking.reservations.footprint.showResa.pending"
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ])
                                                    : _vm.baseReservation
                                                        .payment.status ===
                                                        _vm
                                                          .PAYMENT_STATUS_CAPTURABLE
                                                          .value ||
                                                      _vm.baseReservation
                                                        .payment.status ===
                                                        _vm
                                                          .PAYMENT_STATUS_CAPTURED
                                                          .value
                                                    ? _c("div", [
                                                        _vm.baseReservation
                                                          .status === "noshow"
                                                          ? _c("div", [
                                                              _vm._v(
                                                                "\n                                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.booking.reservations.footprint.showResa.captured",
                                                                      {
                                                                        amount:
                                                                          _vm.formatCurrency(
                                                                            _vm
                                                                              .baseReservation
                                                                              .payment
                                                                              .amount_captured /
                                                                              100
                                                                          ),
                                                                      }
                                                                    )
                                                                  ) +
                                                                  "\n                                        "
                                                              ),
                                                            ])
                                                          : _vm.baseReservation
                                                              .status === "over"
                                                          ? _c("div", [
                                                              _vm._v(
                                                                "\n                                            " +
                                                                  _vm._s(
                                                                    _vm.$tl(
                                                                      "labels.booking.reservations.footprint.showResa.over"
                                                                    )
                                                                  ) +
                                                                  "\n                                        "
                                                              ),
                                                            ])
                                                          : _c("div", [
                                                              _vm._v(
                                                                "\n                                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.booking.reservations.footprint.showResa.capturable",
                                                                      {
                                                                        amount:
                                                                          _vm.formatCurrency(
                                                                            _vm
                                                                              .baseReservation
                                                                              .amount_if_noshow /
                                                                              100
                                                                          ),
                                                                      }
                                                                    )
                                                                  ) +
                                                                  "\n                                        "
                                                              ),
                                                            ]),
                                                      ])
                                                    : _vm.baseReservation
                                                        .payment.status ===
                                                      _vm.PAYMENT_STATUS_EXPIRED
                                                        .value
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.$tl(
                                                                "labels.booking.reservations.footprint.showResa.expired"
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.booking.reservations.noFootprintNorPayment"
                                                        )
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]),
                                          ]),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                      _vm._v(" "),
                      _vm.baseReservation && _vm.baseReservation.user
                        ? _c("div", { staticClass: "row m-0 mt-3" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "label",
                                { staticClass: "d-block text-right" },
                                [
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.reservations.savedBy"
                                        )
                                      ) +
                                        "\n                            " +
                                        _vm._s(
                                          `${_vm.baseReservation.user.firstname} ${_vm.baseReservation.user.lastname}`
                                        )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showSaveButton
                        ? _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("input", {
                                staticClass: "btn btn-success btn-circle",
                                attrs: {
                                  disabled: _vm.formLoading || _vm.isImmutable,
                                  type: "submit",
                                  name: "submit",
                                },
                                domProps: {
                                  value: _vm.$tl(
                                    "labels.booking.reservations.actions.save"
                                  ),
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success btn-circle",
                                attrs: {
                                  disabled: _vm.formLoading || _vm.isImmutable,
                                },
                                on: { click: _vm.saveInWaitingList },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.waitings.list.title"
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
            ],
            1
          ),
      _vm._v(" "),
      _vm.showSelectRestaurantModal
        ? _c("select-restaurant-modal", {
            ref: "SelectRestaurantModal",
            on: {
              close: function ($event) {
                _vm.showSelectRestaurantModal = false
              },
              selected: _vm.destinationRestaurantSelected,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.canOpenSearchClientModal
        ? _c("wrapper-search-client-modal", {
            on: {
              close: function ($event) {
                _vm.canOpenSearchClientModal = false
              },
              "selected-client": _vm.associateClient,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }