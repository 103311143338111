var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal-rounded-no-header", { on: { close: _vm.closeFromModal } }, [
    _c("div", { attrs: { slot: "body" }, slot: "body" }, [
      _c("p", { staticClass: "text-danger text-center" }, [
        _vm._v("\n            " + _vm._s(_vm.message) + "\n        "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }