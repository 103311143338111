<template>
    <div class="p-2">
        <div class="row">
            <div class="col-12">
                <div
                    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Nouveautés</h5>
                    <button type="button" class="btn btn-sm btn-success btn-circle" @click="addNewFeature">
                        <feather type="plus" /><span class="ml-1">Ajouter une nouveauté</span>
                    </button>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <table class="table table-sm table-striped border-bottom">
                <thead class="border-bottom">
                    <tr>
                        <th>Titre</th>
                        <th>Nombre d'utilisateurs initial</th>
                        <th>Nombre de vues</th>
                        <th>Date de création</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="new_feature in new_features" :key="new_feature.id">
                        <td>{{ new_feature.title }}</td>
                        <td>{{ new_feature.nb_users }}</td>
                        <td>{{ new_feature.nb_users - new_feature.remaining_nb_users }}</td>
                        <td>{{ moment(new_feature.created_at).format("DD/MM/Y") }}</td>
                        <td>
                            <button
                                type="button"
                                class="btn btn-sm btn-outline-secondary btn-square"
                                @click="showShowNewFeatureModal = new_feature.id">
                                <feather type="eye" />
                            </button>
                            <button
                                type="button"
                                class="btn btn-sm btn-danger btn-square"
                                @click="deleteNewFeature(new_feature.id)">
                                <feather type="trash-2" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <addNewFeatureModal
            v-if="showAddNewFeatureModal"
            @close="showAddNewFeatureModal = false"
            @saved="onNewFeatureSaved" />
        <showNewFeatureModal
            v-if="showShowNewFeatureModal !== false"
            :markAsRead="false"
            :new_feature_id="showShowNewFeatureModal"
            @hide="showShowNewFeatureModal = false" />
    </div>
</template>

<script>
import moment from "moment";
import LoaderComponent from "../../components/LoaderComponent";
import addNewFeatureModal from "../../components/Modals/Admin/NewFeatures/addNewFeatureModal";
import showNewFeatureModal from "../../components/Modals/NewFeatures/showNewFeatureModal";

export default {
    data() {
        return {
            loading: 0,
            new_features: [],
            showAddNewFeatureModal: false,
            showShowNewFeatureModal: false,
            moment,
        };
    },
    methods: {
        addNewFeature() {
            this.showAddNewFeatureModal = true;
        },
        onNewFeatureSaved() {
            this.showAddNewFeatureModal = false;
            this.fetchNewFeatures();
        },
        fetchNewFeatures() {
            this.loading++;

            axios
                .get(`/api/admin/new_features?include=remaining_nb_users`)
                .then((response) => {
                    this.loading--;
                    this.new_features = response.data.data;
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        deleteNewFeature(new_feature_id) {
            if (!confirm("Supprimer cette nouveauté ?")) return;
            this.loading++;

            axios
                .delete(`/api/admin/new_features/${new_feature_id}`)
                .then((response) => {
                    this.loading--;
                    this.fetchNewFeatures();
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
    },
    components: {
        LoaderComponent,
        addNewFeatureModal,
        showNewFeatureModal,
    },
    created() {
        this.fetchNewFeatures();
    },
};
</script>
