<template>
    <LoaderComponent v-if="loading" />
    <div v-else>
        <div v-if="error" class="alert alert-danger">
            {{ error }}
        </div>
        <div v-else-if="reservation" class="div-valid-resa">
            <div class="row m-0">
                <div class="col-lg-8 offset-lg-2 mb-3">
                    <div
                        v-if="reservation.status === RESERVATION_STATUS_CANCELED.value"
                        class="bg-danger text-center text-white p-1"
                        style="border-radius: 100px">
                        {{ $tl("infos.booking.reservations.hasBeenCanceled") }}
                    </div>
                    <div
                        v-if="reservation.status === RESERVATION_STATUS_OVER.value"
                        class="bg-danger text-center text-white p-1"
                        style="border-radius: 100px">
                        {{ $tl("infos.booking.reservations.alreadyScanned") }}
                    </div>
                    <div class="border-light b-radius-20 p-4 text-center">
                        <div class="row">
                            <div class="col-12">
                                <div>{{ $tl("labels.booking.reservations.dateAndHour") }}</div>
                                <div class="value">
                                    {{ displayDate(reservation.reservation_datetime, DATE_MED) }} -
                                    {{ displayDate(reservation.reservation_datetime, TIME_SIMPLE) }}
                                </div>
                            </div>
                        </div>
                        <div class="sepa"></div>
                        <div class="row">
                            <div class="col-12">{{ $tl("labels.booking.reservations.grid.columns.client") }}</div>
                            <div class="col-12 value">
                                <inline-client :client="reservation.client" :module-enum="MODULE_TYPE_BOOKING"></inline-client>
                            </div>
                        </div>
                        <template v-if="reservation.tables.data.length > 0 || reservation.num_table">
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-12">{{ $tl("labels.booking.reservations.grid.columns.table", restaurant_id) }}</div>
                                <div class="col-12 value">
                                    <template v-for="(table, index) in reservation.tables.data">
                                        {{ table.name }}{{ index + 1 < reservation.tables.data.length ? " - " : "" }}
                                    </template>
                                    <template v-if="reservation.num_table">
                                        {{ reservation.num_table }}
                                    </template>
                                </div>
                            </div>
                        </template>
                        <div class="sepa"></div>
                        <div class="row">
                            <div class="col-6">
                                <div>{{ $tl("labels.booking.reservations.edit.nbPers") }}</div>
                                <div class="value">
                                    {{ reservation.nb_pers }}
                                </div>
                            </div>
                            <div class="col-6">
                                <div>{{ $tl("labels.booking.reservations.edit.nbChildren") }}</div>
                                <div class="value">
                                    {{ reservation.nb_children }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showValidateButton">
            <button
                :disabled="!reservation || !inEnum(reservation.status, editableReservationStatus) || isValidating"
                :class="{ disabled: !reservation || !inEnum(reservation.status, editableReservationStatus) || isValidating }"
                class="btn btn-success btn-sm btn-circle"
                @click="validate">
                {{ $tl("labels.form.actions.validate") }}
            </button>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import axios from "axios";
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum.js";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";
import inlineClient from "../Default/Clients/inlineClient.vue";

export default {
    name: "showReservation",
    data() {
        return {
            loading: false,
            isValidating: false,
            error: null,
            reservation: null,
        };
    },
    mixins: [ReservationStatusEnum, ModuleTypesEnum],
    props: {
        reservation_id: {
            default: null,
        },
        restaurant_id: {
            default: null,
        },
        showValidateButton: {
            default: true,
        },
    },
    methods: {
        fetchData() {
            this.error = null;

            if ([null, ""].includes(this.restaurant_id) || [null, ""].includes(this.reservation_id)) {
                return;
            }

            this.loading = true;

            this.httpGet(
                `/api/restaurants/${this.restaurant_id}/reservations/${this.reservation_id}?include=slot,client,slot.service,menus,menus.options,general_options,tables`,
                { mustReject: true }
            )
                .then((response) => {
                    this.$set(this, "reservation", response.data);
                    this.$emit("reservation-get", response.data);
                })
                .catch((error) => (this.error = this.getErrorMsgFromErrorResponse(error)))
                .finally(() => (this.loading = false));
        },
        validate() {
            if (this.reservation === null || this.isValidating) {
                return;
            }

            this.isValidating = true;

            this.httpPut(
                `/api/restaurants/${this.restaurant_id}/reservations/${this.reservation.id}/status?status=${this.RESERVATION_STATUS_OVER.value}`
            ).finally(() => (this.isValidating = false));
        },
    },
    watch: {
        restaurant_id: {
            handler: function (newVal) {
                this.fetchData();
            },
        },
        reservation_id: {
            handler: function (newVal) {
                this.fetchData();
            },
        },
    },
    created() {
        this.fetchData();
    },
    components: {
        LoaderComponent,
        inlineClient,
    },
};
</script>
