var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _vm.loading
        ? _c("loader-component")
        : _vm.error
        ? _c("div", [_vm._v("\n        " + _vm._s(_vm.error) + "\n    ")])
        : _c("div", [
            _c(
              "div",
              { staticClass: "row m-0 pt-5 p-4" },
              _vm._l(_vm.restaurants, function (restaurant, index) {
                return _c("restaurant-card", {
                  key: index,
                  staticClass: "col-md-4 mb-3",
                  attrs: { restaurant: restaurant },
                  on: {
                    click: function ($event) {
                      return _vm.showBoardForRestaurant($event.id)
                    },
                  },
                })
              }),
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }