<template>
    <div>
        <LoaderComponent v-if="loading" />
        <span class="text-danger" v-else-if="error">{{ error }}</span>
        <div class="p-1" v-else>
            <div class="row">
                <div class="col-12">
                    {{ $tl("labels.booking.reservations.add.client") }}
                </div>
            </div>
            <div class="row">
                <div class="col-md-7 mt-3">
                    <div class="border-light b-radius-20 p-4">
                        <clients-reservations
                            :formErrors="formErrors"
                            :restaurant_id="restaurant_id"
                            :reservation="reservation"
                            :readOnly="readOnly" />
                    </div>
                </div>
                <div class="col-md-5 mt-3">
                    <div class="border-light b-radius-20 p-4">
                        <commentary-reservations
                            :restaurant_id="restaurant_id"
                            :reservation="reservation"
                            :restaurant="restaurant"
                            :formErrors="formErrors"
                            :readOnly="readOnly" />
                    </div>
                </div>
            </div>
            <div v-if="canAddOptionBank" class="row m-0 mt-3">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row radio" style="position: relative">
                            <div class="col-md-3 mb-2">
                                {{ $tl("labels.booking.reservations.secure") }}
                            </div>
                            <div class="col-md-9">
                                <label class="container-box">
                                    <input type="radio" v-model="reservation.option_bank" :value="null" />
                                    <span class="checkmark"></span> {{ $tl("labels.none") }}
                                </label>
                                <div class="none-desk" style="height: 8px"></div>
                                <label
                                    class="container-box"
                                    :class="{ 'cursor-disabled': isFootprintAvailable !== true }"
                                    v-tooltip="isFootprintAvailable !== true ? { content: isFootprintAvailable } : undefined">
                                    <input
                                        type="radio"
                                        v-model="reservation.option_bank"
                                        :value="OPTION_BANK_FOOTPRINT.value"
                                        :disabled="isFootprintAvailable !== true" />
                                    <span class="checkmark" :class="{ disabled: isFootprintAvailable !== true }"></span>
                                    {{ $tl("labels.booking.reservations.footprint.title") }}
                                </label>
                                <div class="none-desk" style="height: 8px"></div>
                                <label
                                    class="container-box"
                                    :class="{ 'cursor-disabled': isPaymentAvailable !== true }"
                                    v-tooltip="isPaymentAvailable !== true ? { content: isPaymentAvailable } : undefined">
                                    <input
                                        type="radio"
                                        v-model="reservation.option_bank"
                                        :value="OPTION_BANK_PAYMENT.value"
                                        :disabled="isPaymentAvailable !== true" />
                                    <span class="checkmark" :class="{ disabled: isPaymentAvailable !== true }"></span>
                                    {{ $tl("labels.booking.reservations.payment.title") }}
                                </label>
                            </div>
                        </div>
                        <div class="row mt-3" v-if="reservation.option_bank === OPTION_BANK_PAYMENT.value">
                            <div class="col-md-3">
                                <label>{{ $tl("labels.booking.reservations.payment.amountToPay") }}</label>
                            </div>
                            <div class="col-md-9">
                                <input
                                    type="number"
                                    v-model="reservation.amount_to_pay"
                                    class="form-control"
                                    :disabled="isPaymentAvailable !== true" />
                                <small class="d-block text-danger mt-2">
                                    {{ $tl("infos.booking.reservations.payment.willBeSendOnSave") }}
                                </small>
                            </div>
                        </div>
                        <div v-else-if="reservation.option_bank === OPTION_BANK_FOOTPRINT.value && footprint_date_send" class="offset-md-3">
                            <small class="d-block alert-info text-info-noshow mt-2 p-2">
                                <template v-if="footprint_date_send === 'NOW'">
                                    {{ $tl("infos.booking.reservations.footprint.willBeSendOnSave") }}
                                </template>
                                <template v-else>
                                    {{
                                        $t("infos.booking.reservations.footprint.willBeSendOnDate", {
                                            date: footprint_date_send,
                                        })
                                    }}
                                </template>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="reservation.option_bank === null && showConfirmation" class="row m-0 mt-3">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <label class="container-box d-inline" style="width: 70px">
                            <input type="checkbox" v-model="reservation.send_confirmation" :disabled="send_confirmation_disabled" />
                            <span class="checkmark"></span> {{ $tl("labels.booking.reservations.confirmation.send") }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="row m-0 mt-3" v-if="employee_accounts && employee_accounts.length > 0">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row m-0">
                            <div class="col-md-4 pt-1">
                                <label class="d-block">
                                    {{ $tl("labels.booking.reservations.savedBy") }}
                                </label>
                            </div>
                            <div class="col-md-8">
                                <select class="d-block custom-select mb-0" v-model="reservation.user_id">
                                    <option v-for="user in employee_accounts" :key="user.id" :value="user.id">
                                        {{ user.firstname }} {{ user.lastname }}
                                    </option>
                                </select>
                                <ShowErrors class="d-block" :errors="formErrors ? formErrors.errors : null" errorKey="user_id" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../LoaderComponent.vue";
import clientsReservations from "../clientsReservations.vue";
import commentaryReservations from "../commentaryReservations.vue";
import ShowErrors from "../../../errors/ShowErrors.vue";
import OptionBankEnum from "../../../../mixins/enums/booking/OptionBankEnum.js";

export default {
    data() {
        return {
            loading: false,
            employee_accounts: [],
            send_confirmation_disabled: false,
        };
    },
    mixins: [OptionBankEnum],
    props: {
        restaurant_id: {
            default: null,
        },
        reservation: {
            type: Object,
            required: true,
        },
        restaurant: {
            type: Object,
            required: true,
        },
        formErrors: {
            default: null,
        },
        readOnly: {
            default: false,
        },
    },
    computed: {
        showConfirmation() {
            if (this.restaurant.payment_notif_settings.send_confirmation_by === "mail") {
                return !!(this.reservation.client !== null && this.reservation.client.email);
            }
            if (this.restaurant.payment_notif_settings.send_confirmation_by === "sms") {
                return !!(this.reservation.client !== null && this.reservation.client.phone.raw);
            }
            return false;
        },
        menusTotalPrice() {
            if (!_.isEmpty(this.reservation.choosen_menus)) {
                let amount = 0;
                Object.values(this.reservation.choosen_menus).forEach((menu) => {
                    amount += menu.price * parseInt(menu.value);
                    if (!_.isEmpty(menu.options)) Object.values(menu.options).forEach((option) => (amount += option.price * option.value));
                });
                return amount;
            }
            if (!_.isEmpty(this.reservation.choosen_general_options)) {
                let amount = 0;
                Object.values(this.reservation.choosen_general_options).forEach(
                    (general_option) => (amount += general_option.price * parseInt(general_option.value))
                );
                return amount;
            }
            return 0;
        },
        optionBankShortDelay() {
            return this.$store.getters["widgets/getWidget"].option_bank_short_delay || 1;
        },
        canAddOptionBank() {
            if (!this.reservation.reservation_date || !this.reservation.slot || !this.reservation.slot.hour_start) {
                return false;
            }

            const resaDateTimeSubHourHalf = this.setHourOnDateTime(this.reservation.slot.hour_start, this.reservation.reservation_date).minus({
                hours: this.optionBankShortDelay,
                minutes: 30,
            });

            return resaDateTimeSubHourHalf > this.getDateTime();
        },
        footprint_date_send() {
            if (this.reservation_date) {
                const resaDateStartOfDay = this.reservation.reservation_date.startOf("day");
                const nowStartOfDay = this.getDateTime().startOf("day");

                if (resaDateStartOfDay.diff(nowStartOfDay, "days").days <= 6) {
                    return "NOW";
                }

                return this.displayDate(resaDateStartOfDay.minus({ days: 6 }), this.DATE_SHORT);
            }
            return null;
        },
        isFootprintAvailable() {
            if (!this.restaurant) return "Une erreur est survenue";
            if (this.restaurant.payment_notif_settings.ask_footprint_by === "mail" && (!this.reservation.client || !this.reservation.client.email))
                return "Pour sécuriser la réservation avec une empreinte bancaire, un email client valide doit être renseigné.";
            if (
                this.restaurant.payment_notif_settings.ask_footprint_by === "sms" &&
                (!this.reservation.client || !this.reservation.client.phone || !this.reservation.client.phone.raw)
            )
                return "Pour sécuriser la réservation avec une empreinte bancaire, un téléphone client valide doit être renseigné.";
            return true;
        },
        isPaymentAvailable() {
            if (!this.restaurant) return "Une erreur est survenue";
            if (this.restaurant.payment_notif_settings.ask_payment_by === "mail" && (!this.reservation.client || !this.reservation.client.email))
                return "Pour sécuriser la réservation avec un acompte, un email client valide doit être renseigné.";
            if (
                this.restaurant.payment_notif_settings.ask_payment_by === "sms" &&
                (!this.reservation.client || !this.reservation.client.phone || !this.reservation.client.phone.raw)
            )
                return "Pour sécuriser la réservation avec un acompte, un téléphone client valide doit être renseigné.";
            return true;
        },
    },
    methods: {
        getRestaurantsEmployeeAccounts() {
            this.loading = true;
            this.error = null;

            axios
                .get(`/api/restaurants/${this.restaurant_id}/users?role=user_without_account`)
                .then((response) => {
                    this.loading = false;
                    this.employee_accounts = response.data.data;
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                    else this.error = "Une erreur est survenue";
                });
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
    },
    watch: {
        "reservation.option_bank": function (newVal) {
            if (newVal === this.OPTION_BANK_PAYMENT.value || newVal === this.OPTION_BANK_FOOTPRINT.value) {
                this.send_confirmation_disabled = true;
                this.reservation.send_confirmation = true;
            } else {
                this.send_confirmation_disabled = false;
            }
        },
        "reservation.choosen_menus": {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                if (this.reservation.service) {
                    const prepayment_percent = this.reservation.service.prepayment_percent || 100;

                    this.reservation.amount_to_pay = this.menusTotalPrice * (prepayment_percent / 100);
                }
            },
        },
        "reservation.choosen_general_options": {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                if (this.reservation.service) {
                    const prepayment_percent = this.reservation.service.prepayment_percent || 100;

                    this.reservation.amount_to_pay = this.menusTotalPrice * (prepayment_percent / 100);
                }
            },
        },
        restaurant_id: {
            immediate: true,
            handler() {
                this.getRestaurantsEmployeeAccounts();
            },
        },
    },
    components: {
        LoaderComponent,
        clientsReservations,
        commentaryReservations,
        ShowErrors,
    },
};
</script>

<style></style>
