<template>
    <thead>
        <ns-th field="gv_sales.client" width="6" allow-sorting>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.client')"></span>
            <feather class="none-desk" type="user" />
        </ns-th>
        <ns-th field="gv_sales.numero" width="6" allow-sorting>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.numero')"></span>
            <span class="none-desk">N°</span>
        </ns-th>
        <ns-th field="gv_sales.created_at" width="6" allow-sorting>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.giftVouchers.gvSaleDate')"></span>
            <feather class="none-desk" type="calendar" />
        </ns-th>
        <ns-th field="gv_sales.gv_product" width="7" allow-sorting>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.giftVouchers.product')"></span>
            <feather class="none-desk" type="send" />
        </ns-th>
        <ns-th field="gv_sales.beneficiary" width="7" allow-sorting>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.giftVouchers.beneficiary')"></span>
            <feather class="none-desk" type="send" />
        </ns-th>
        <ns-th field="status" width="18">
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.shipmentStatus')"></span>
            <feather class="none-desk" type="message-square" />
        </ns-th>
        <ns-th field="re_sent" width="2">
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.resendMail')"></span>
            <feather class="none-desk" type="send" />
        </ns-th>
    </thead>
</template>
<script>
import NsTh from "../../../../Datatable/NsTh.vue";

export default {
    components: {
        NsTh,
    },
};
</script>
