var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : !_vm.website_enabled
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("Votre site web n'est pas activé"),
          ])
        : [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                  },
                  [
                    _c("h5", { staticClass: "title mt-2" }, [
                      _vm._v("Configuration des liens externes"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12 mb-3" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 mb-3" }, [
                      _c("strong", [_vm._v("Réseaux sociaux")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col-md-5 pt-2" }, [
                      _c("label", { attrs: { for: "txt-facebook-link" } }, [
                        _vm._v("Url de la page Facebook"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-7" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.website_settings.facebook_link,
                              expression: "website_settings.facebook_link",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            disabled: !_vm.has_right_to_update_customization,
                            type: "text",
                            id: "txt-facebook-link",
                            placeholder:
                              "https://facebook.com/votreidentifiant",
                          },
                          domProps: {
                            value: _vm.website_settings.facebook_link,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.website_settings,
                                "facebook_link",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors,
                            errorKey: "facebook_link",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-5 pt-2" }, [
                      _c("label", { attrs: { for: "txt-instagram-link" } }, [
                        _vm._v("Url de votre compte Instagram"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-7" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.website_settings.instagram_link,
                              expression: "website_settings.instagram_link",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            disabled: !_vm.has_right_to_update_customization,
                            type: "text",
                            id: "txt-instagram-link",
                            placeholder:
                              "https://instagram.com/votreidentifant",
                          },
                          domProps: {
                            value: _vm.website_settings.instagram_link,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.website_settings,
                                "instagram_link",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors,
                            errorKey: "instagram_link",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 mb-3" }, [
                      _c("strong", [_vm._v("Liens externes")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col-md-5 pt-2" }, [
                      _c("label", { attrs: { for: "txt-deliveroo-link" } }, [
                        _vm._v("Url de la page Deliveroo"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-7" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.website_settings.deliveroo_link,
                              expression: "website_settings.deliveroo_link",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            disabled: !_vm.has_right_to_update_customization,
                            type: "text",
                            id: "txt-deliveroo-link",
                            placeholder: "https://deliveroo.fr/xxxxx",
                          },
                          domProps: {
                            value: _vm.website_settings.deliveroo_link,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.website_settings,
                                "deliveroo_link",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors,
                            errorKey: "deliveroo_link",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-5 pt-2" }, [
                      _c("label", { attrs: { for: "txt-uber_eats-link" } }, [
                        _vm._v("Url de la page UberEats"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-7" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.website_settings.uber_eats_link,
                              expression: "website_settings.uber_eats_link",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            disabled: !_vm.has_right_to_update_customization,
                            type: "text",
                            id: "txt-uber_eats-link",
                            placeholder: "https://www.ubereats.com/xxxxx",
                          },
                          domProps: {
                            value: _vm.website_settings.uber_eats_link,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.website_settings,
                                "uber_eats_link",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors,
                            errorKey: "uber_eats_link",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0 mt-3" }, [
              _c("div", { staticClass: "col-12 d-inline-block" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success btn-circle mr-1",
                    attrs: {
                      type: "button",
                      disabled: !_vm.has_right_to_update_customization,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.postForm()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Enregistrer\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    'Pour rendre vos modifications visibles sur votre site internet, pensez à "'
                  ),
                  _c("strong", [_vm._v("Enregistrer et publier")]),
                  _vm._v('" en cliquant sur le bouton dans la navigation.'),
                ]),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }