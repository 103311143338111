var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 pt-3" }, [
        _c("div", { staticClass: "border-light b-radius-20 p-4 mb-3" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("strong", [_vm._v("Choisissez votre cible")]),
                _vm._v(" "),
                _c("ShowErrors", {
                  attrs: { errors: _vm.errors, errorKey: "targets" },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 mt-3" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-4" }, [_vm._v("Clients")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-8" },
                  [
                    _c("input", {
                      staticClass: "switch align-self-center is-rounded",
                      attrs: { type: "checkbox" },
                      domProps: { checked: !_vm.campaign.send_to_owners_only },
                    }),
                    _vm._v(
                      "\n                                Comptes admin uniquement (" +
                        _vm._s(_vm.clientsAdmin.length) +
                        ")\n                                "
                    ),
                    _c("label", {
                      on: {
                        click: function ($event) {
                          _vm.campaign.send_to_owners_only =
                            !_vm.campaign.send_to_owners_only
                        },
                      },
                    }),
                    _vm._v(
                      "\n                                Tous (" +
                        _vm._s(_vm.clients.length) +
                        ")\n                                "
                    ),
                    _c("ShowErrors", {
                      staticClass: "d-block",
                      attrs: {
                        errors: _vm.errors,
                        errorKey: "send_to_owners_only",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col-md-4 pt-1" }, [
                  _vm._v("Clients actifs uniquement"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-8" },
                  [
                    _c("label", { staticClass: "container-box" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.campaign.send_to_active_owners_only,
                            expression: "campaign.send_to_active_owners_only",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          disabled: _vm.campaign.send_to_trials_only,
                          "true-value": "1",
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.campaign.send_to_active_owners_only
                          )
                            ? _vm._i(
                                _vm.campaign.send_to_active_owners_only,
                                null
                              ) > -1
                            : _vm._q(
                                _vm.campaign.send_to_active_owners_only,
                                "1"
                              ),
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.campaign.send_to_active_owners_only,
                              $$el = $event.target,
                              $$c = $$el.checked ? "1" : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.campaign,
                                    "send_to_active_owners_only",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.campaign,
                                    "send_to_active_owners_only",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.campaign,
                                "send_to_active_owners_only",
                                $$c
                              )
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "checkmark",
                        class: { disabled: _vm.campaign.send_to_trials_only },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("ShowErrors", {
                      staticClass: "d-block",
                      attrs: {
                        errors: _vm.errors,
                        errorKey: "send_to_active_owners_only",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col-md-4 pt-1" }, [
                  _vm._v("Clients en période d'essai uniquement"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-8" },
                  [
                    _c("label", { staticClass: "container-box" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.campaign.send_to_trials_only,
                            expression: "campaign.send_to_trials_only",
                          },
                        ],
                        attrs: { type: "checkbox", "true-value": "1" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.campaign.send_to_trials_only
                          )
                            ? _vm._i(_vm.campaign.send_to_trials_only, null) >
                              -1
                            : _vm._q(_vm.campaign.send_to_trials_only, "1"),
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.campaign.send_to_trials_only,
                              $$el = $event.target,
                              $$c = $$el.checked ? "1" : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.campaign,
                                    "send_to_trials_only",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.campaign,
                                    "send_to_trials_only",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.campaign, "send_to_trials_only", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "checkmark" }),
                    ]),
                    _vm._v(" "),
                    _c("ShowErrors", {
                      staticClass: "d-block",
                      attrs: {
                        errors: _vm.errors,
                        errorKey: "send_to_trials_only",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col-md-4 pt-1" }, [
                  _vm._v("Type de renouvellement"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-8" },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.campaign.send_to_plan,
                            expression: "campaign.send_to_plan",
                          },
                        ],
                        staticClass: "custom-select",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.campaign,
                              "send_to_plan",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { domProps: { value: null } }, [
                          _vm._v("Tous"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.ALL_STRIPE_PLAN_TYPES, function (plan) {
                          return _c(
                            "option",
                            {
                              key: plan.value,
                              domProps: { value: plan.value },
                            },
                            [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(plan.label) +
                                  "\n                                    "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("ShowErrors", {
                      staticClass: "d-block",
                      attrs: { errors: _vm.errors, errorKey: "send_to_plan" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col-md-4 pt-1" }, [
                  _vm._v("Clients qui ont une licence"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-8" },
                  [
                    _vm._l(
                      _vm.ALL_MODULES_TYPES_WITH_OPTIONS,
                      function (module) {
                        return _c(
                          "label",
                          {
                            key: module.value,
                            staticClass: "container-box",
                            staticStyle: { width: "auto !important" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.campaign.has_modules,
                                  expression: "campaign.has_modules",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: module.value,
                                checked: Array.isArray(_vm.campaign.has_modules)
                                  ? _vm._i(
                                      _vm.campaign.has_modules,
                                      module.value
                                    ) > -1
                                  : _vm.campaign.has_modules,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.campaign.has_modules,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = module.value,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.campaign,
                                          "has_modules",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.campaign,
                                          "has_modules",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.campaign, "has_modules", $$c)
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" }),
                            _vm._v(
                              " " +
                                _vm._s(module.label) +
                                "\n                                "
                            ),
                          ]
                        )
                      }
                    ),
                    _vm._v(" "),
                    _c("ShowErrors", {
                      staticClass: "d-block",
                      attrs: { errors: _vm.errors, errorKey: "has_modules" },
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col-md-4 pt-1" }, [
                  _vm._v("Clients qui n'ont pas de licence"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-8" },
                  [
                    _vm._l(
                      _vm.ALL_MODULES_TYPES_WITH_OPTIONS,
                      function (module) {
                        return _c(
                          "label",
                          {
                            key: module.value,
                            staticClass: "container-box",
                            staticStyle: { width: "auto !important" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.campaign.doesnt_have_modules,
                                  expression: "campaign.doesnt_have_modules",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: module.value,
                                checked: Array.isArray(
                                  _vm.campaign.doesnt_have_modules
                                )
                                  ? _vm._i(
                                      _vm.campaign.doesnt_have_modules,
                                      module.value
                                    ) > -1
                                  : _vm.campaign.doesnt_have_modules,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.campaign.doesnt_have_modules,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = module.value,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.campaign,
                                          "doesnt_have_modules",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.campaign,
                                          "doesnt_have_modules",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.campaign,
                                      "doesnt_have_modules",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" }),
                            _vm._v(
                              " " +
                                _vm._s(module.label) +
                                "\n                                "
                            ),
                          ]
                        )
                      }
                    ),
                    _vm._v(" "),
                    _c("ShowErrors", {
                      staticClass: "d-block",
                      attrs: {
                        errors: _vm.errors,
                        errorKey: "doesnt_have_modules",
                      },
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _vm.campaign.type == "sms"
                ? _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col-md-4 pt-1" }, [
                      _vm._v("Envoyer la campagne au numéros étrangers"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-8" },
                      [
                        _c("label", { staticClass: "container-box" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.campaign.send_to_foreign_numbers,
                                expression: "campaign.send_to_foreign_numbers",
                              },
                            ],
                            attrs: { type: "checkbox", "true-value": "1" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.campaign.send_to_foreign_numbers
                              )
                                ? _vm._i(
                                    _vm.campaign.send_to_foreign_numbers,
                                    null
                                  ) > -1
                                : _vm._q(
                                    _vm.campaign.send_to_foreign_numbers,
                                    "1"
                                  ),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.campaign.send_to_foreign_numbers,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "1" : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.campaign,
                                        "send_to_foreign_numbers",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.campaign,
                                        "send_to_foreign_numbers",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.campaign,
                                    "send_to_foreign_numbers",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "checkmark" }),
                        ]),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors,
                            errorKey: "send_to_foreign_numbers",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }