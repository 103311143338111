var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "default-home" },
    [
      _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [
                _vm._v(_vm._s(_vm.$tl("labels.routes.sms"))),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "border-light b-radius-20 p-4 mb-3" }, [
            _c("div", [
              _c("strong", [_vm._v(_vm._s(_vm.$tl("infos.sms.stats.title")))]),
              _vm._v(" "),
              _c("br"),
              _c("br"),
              _vm._v(
                "\n                    - " +
                  _vm._s(_vm.$tl("infos.sms.stats.openingRate.title")) +
                  "\n                    "
              ),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("infos.sms.stats.openingRate.value", {
                      percentSms: 95,
                      percentEmail: 20,
                    })
                  )
                ),
              ]),
              _c("br"),
              _vm._v(
                "\n                    - " +
                  _vm._s(_vm.$tl("infos.sms.stats.readTimeDelay.title")) +
                  "\n                    "
              ),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("infos.sms.stats.readTimeDelay.value", {
                      timeSms: 4,
                      timeEmail: 90,
                    })
                  )
                ),
              ]),
              _c("br"),
              _vm._v(
                "\n                    - " +
                  _vm._s(_vm.$tl("infos.sms.stats.spam.title")) +
                  "\n                    "
              ),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("infos.sms.stats.spam.value", {
                      percentSms: 0,
                      percentEmail: 80,
                    })
                  )
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.loadingSmsMode
        ? _c("loader-component")
        : _c(
            "div",
            [
              _vm.smsActivated
                ? [_c("sms-pack")]
                : _c("div", { staticClass: "row m-0" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4 mb-3" },
                        [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _vm.isNoShow
                                ? [
                                    _c("p", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$t(
                                              "infos.sms.freeWhenEnabled",
                                              { nb: 100 }
                                            )
                                          )
                                      ),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("i", { staticClass: "text-muted" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tl("infos.sms.configNotifs")
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                : _c("p", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$tl("infos.sms.configNotifs")
                                        ) +
                                        "\n                        "
                                    ),
                                  ]),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-sm btn-circle btn-success",
                                  attrs: {
                                    type: "button",
                                    disabled: !_vm.isOwner,
                                  },
                                  on: { click: _vm.activateSms },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.$tl("labels.form.sms.enable")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.isOwner
                                ? _c(
                                    "span",
                                    { staticClass: "text-danger d-block" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.form.sms.notEnoughRights"
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]),
                  ]),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }