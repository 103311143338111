var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "bg-danger text-center text-white p-1 mb-2",
          staticStyle: { "border-radius": "100px" },
        },
        [_vm._v("\n        " + _vm._s(_vm.invalid_reason) + "\n    ")]
      ),
      _vm._v(" "),
      _c("show-gift-voucher", { attrs: { sale: _vm.gv_sale } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }