<template>
    <form class="form-register" @submit="post">
        <div class="text-center">
            <img class="mb-5" width="200" src="/images/logo-lead.png" alt="No Show" />

            <h5 class="mb-4">
                {{ welcome ? "Créer mon mot de passe" : "Mot de passe perdu" }}
            </h5>
        </div>

        <LoaderComponent v-if="loading" />
        <template v-else>
            <div class="form-group row">
                <label for="email" class="col-md-4 col-form-label text-md-right">Votre adresse mail</label>

                <div class="col-md-6">
                    <input
                        id="email"
                        type="email"
                        class="form-control"
                        :class="{ 'is-invalid': hasErrors('email') }"
                        name="email"
                        :value="email_"
                        required
                        disabled />
                    <ShowErrors :errors="errors" errorKey="email" />
                </div>
            </div>

            <div class="form-group row">
                <label for="password" class="col-md-4 col-form-label text-md-right">
                    {{ welcome ? "Mot de passe" : "Nouveau mot de passe" }}
                </label>

                <div class="col-md-6">
                    <PasswordInputComponent v-model="user.password" />
                    <ShowErrors :errors="errors" errorKey="password" />
                </div>
            </div>

            <div class="form-group row">
                <label for="password-confirm" class="col-md-4 col-form-label text-md-right">Confirmez le mot de passe</label>

                <div class="col-md-6">
                    <PasswordConfirmationInputComponent :mainPassword="user.password" v-model="user.password_confirmation" />
                    <ShowErrors :errors="errors" errorKey="password_confirmation" />
                </div>
            </div>

            <div class="form-group row mb-0">
                <div class="col-md-6 offset-md-4">
                    <button type="submit" class="btn btn-success">
                        {{ welcome ? "Créer mon mot de passe" : "Réinitialiser mon mot de passe" }}
                    </button>
                </div>
            </div>
        </template>
    </form>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import ShowErrors from "../../components/errors/ShowErrors.vue";
import PasswordInputComponent from "../../components/forms/PasswordInputComponent.vue";
import PasswordConfirmationInputComponent from "../../components/forms/PasswordConfirmationInputComponent.vue";
export default {
    data() {
        return {
            loading: false,
            errors: null,
            user: {
                password: null,
                password_confirmation: null,
            },
        };
    },
    computed: {
        welcome() {
            return this.$route.query.welcome == 1;
        },
        token() {
            return this.$route.params.token;
        },
        email_() {
            return this.$route.query.email;
        },
    },
    methods: {
        post(e) {
            if (e) e.preventDefault();
            this.loading = true;
            this.errors = null;
            this.$store
                .dispatch("auth/resetPassword", {
                    ...this.user,
                    email: this.email_,
                    token: this.token,
                })
                .then(() => {
                    window.location.href = "/";
                })
                .catch((error) => {
                    this.loading = false;
                    this.user.password = "";
                    this.user.password_confirmation = "";
                    if (error.response && error.response.data.errors) {
                        this.errors = error.response.data.errors;
                    } else {
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: this.getErrorMsgFromErrorResponse(error),
                        });
                    }
                });
        },
        hasErrors(key) {
            return this.errors !== null && this.errors[key] && this.errors[key].length > 0;
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
        PasswordInputComponent,
        PasswordConfirmationInputComponent,
    },
};
</script>
