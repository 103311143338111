var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-2" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "pb-2 pt-2 mt-1 mb-1" }, [
            _c(
              "h5",
              { staticClass: "title mt-2" },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$tl("labels.appUpdates.list")) +
                    "\n                    "
                ),
                _c("show-new-badge-details", {
                  attrs: { badge_uuid: "ceee56bb-d4a8-4b2d-9cc3-d90792bd71c0" },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent")
        : _vm.appUpdatesList.length > 0
        ? _c(
            "div",
            _vm._l(_vm.appUpdatesList, function (appUpdate) {
              return _c("div", { key: appUpdate.id, staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "border-light b-radius-20 p-4 pointer",
                      on: {
                        click: function ($event) {
                          return _vm.showAppUpdate(appUpdate)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between" },
                        [
                          _c("h5", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("labels.appUpdates.title", {
                                  date: _vm.displayDate(
                                    appUpdate.update_date,
                                    _vm.DATE_MED
                                  ),
                                })
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "text-right" },
                            _vm._l(appUpdate.modules, function (tmpModule) {
                              return _c(
                                "span",
                                {
                                  key: tmpModule,
                                  staticClass: "badge badge-success mr-1",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.getModuleTypeLabel(tmpModule)
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatDescription(appUpdate.description)
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("labels.appUpdates.lastUpdate", {
                              date: _vm.displayDate(
                                appUpdate.updated_at,
                                _vm.DATE_MED
                              ),
                            })
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
              ])
            }),
            0
          )
        : _c("p", { staticClass: "font-italic" }, [
            _vm._v(_vm._s(_vm.$tl("labels.appUpdates.noOnePublished"))),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }