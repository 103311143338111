<template>
    <div>
        <tab-bar direction="horizontal" side="top" :entries="tabBarEntries" />
        <router-view />
    </div>
</template>

<script>
import TabBar from "../../components/tabBar/TabBar";

export default {
    data() {
        return {};
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        tabBarEntries() {
            return [
                {
                    to: {
                        name: "click_and_collect.restaurants.configuration.settings",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Retrait",
                    exactUrlMatching: true,
                },
                {
                    to: {
                        name: "click_and_collect.restaurants.configuration.settings_delivery",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Livraison",
                    exactUrlMatching: true,
                },
                {
                    to: {
                        name: "click_and_collect.restaurants.configuration.settings_dispatch",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Expédition",
                    exactUrlMatching: true,
                },
                {
                    to: {
                        name: "click_and_collect.restaurants.configuration.promo_codes",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Codes promotionnels",
                    exactUrlMatching: true,
                },
                {
                    to: {
                        name: "click_and_collect.restaurants.configuration.closures",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Fermetures",
                    exactUrlMatching: true,
                },
                {
                    to: {
                        name: "click_and_collect.restaurants.configuration.openings",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Ouvertures",
                    exactUrlMatching: true,
                },
                {
                    to: {
                        name: "click_and_collect.restaurants.configuration.widget",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Intégration",
                    exactUrlMatching: true,
                },
                {
                    to: {
                        name: "click_and_collect.restaurants.configuration.cgv",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "CGV",
                    exactUrlMatching: true,
                },
                {
                    to: {
                        name: "click_and_collect.restaurants.configuration.inputParams",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Paramètres de saisie",
                    exactUrlMatching: true,
                },
            ];
        },
    },
    components: {
        TabBar,
    },
    created() {
        if (this.$route.name === "click_and_collect.restaurants.configuration")
            this.$router.push({ name: "click_and_collect.restaurants.configuration.settings" });
    },
};
</script>
