var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", [
        _vm._v(_vm._s(_vm.$tl("labels.clients.import.steps.2.linkColumns"))),
      ]),
      _vm._v(" "),
      _vm.missingRequiredColumn
        ? _c("p", { staticClass: "text-danger" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$tl("labels.clients.import.steps.2.fillMandatoryColumns")
                ) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("ShowErrors", {
        staticClass: "alert alert-danger position-relative",
        staticStyle: { top: "0" },
        attrs: { errClass: "d-block", errors: _vm.errors.form, errorKey: "*" },
      }),
      _vm._v(" "),
      _vm.firstLine.length === 0
        ? _c("p", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.$tl("labels.clients.import.steps.2.emptyLine"))),
          ])
        : _vm._l(_vm.firstLine, function (lineColumn, index) {
            return _c("div", { key: index, staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t("labels.clients.import.steps.2.colNb", {
                        nb: index + 1,
                      })
                    )
                ),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.getExamples(index))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c(
                  "select",
                  {
                    staticClass: "custom-select",
                    on: {
                      change: function ($event) {
                        return _vm.columnAssociationChanged(index, $event)
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$tl("labels.clients.import.steps.2.ignoreCol")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.columns, function (column) {
                      return _c(
                        "option",
                        {
                          key: column.value,
                          attrs: {
                            disabled: _vm.isColumnAlreadyUsed(
                              column.value,
                              index
                            ),
                          },
                          domProps: { value: column.value },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                column.required
                                  ? column.label + " *"
                                  : column.label
                              ) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sepa" }),
            ])
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }