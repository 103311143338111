<template>
    <div :class="evolutionClass">
        <feather :type="featherIcon" />
        <span v-if="difference !== null">
            {{ difference }}<span class="ml-2">({{ Math.abs(percent).toFixed(0) }}%)</span>
        </span>
        <span v-else> {{ Math.abs(percent).toFixed(0) }}% </span>
    </div>
</template>

<script>
export default {
    name: "ArrowPercentEvolutionComponent",
    props: {
        percent: {
            type: Number,
            required: true,
        },
        difference: {
            type: Number,
            default: null,
        },
    },
    computed: {
        featherIcon() {
            if (this.percent < 0) {
                return "arrow-down-right";
            }

            if (this.percent > 0) {
                return "arrow-up-right";
            }

            return "arrow-right";
        },
        evolutionClass() {
            if (this.percent < 0) {
                return "text-danger";
            }

            if (this.percent > 0) {
                return "text-success";
            }

            return "text-muted";
        },
    },
};
</script>
