export default {
    data() {
        return {
            MODULE_TYPE_BOOKING: {
                label: "Réservations",
                value: "booking",
                urlValue: "booking",
                icon: "calendar",
            },
            MODULE_TYPE_CLICK_AND_COLLECT: {
                label: "Vente en ligne",
                value: "click-and-collect",
                urlValue: "click_and_collect",
                icon: "shopping-cart",
            },
            MODULE_TYPE_GIFT_VOUCHERS: {
                label: "Bons cadeaux",
                value: "gift-vouchers",
                urlValue: "gift_vouchers",
                icon: "tag",
            },
            MODULE_TYPE_WEBSITE: {
                label: "Site web",
                value: "website",
                urlValue: "website",
                icon: "monitor",
            },
            MODULE_TYPE_CLICK_AND_COLLECT_DISPATCH: {
                label: "Option Expédition",
                value: "click-and-collect-dispatch",
            },
            MODULE_TYPE_CAMPAIGNS: {
                value: "campaigns",
                label: "Campagnes marketing",
                urlValue: "campaigns",
                icon: "mail",
            },
            MODULE_TYPE_DEFAULT: {
                value: "default",
                label: "Général",
                icon: "home",
            },
        };
    },
    methods: {
        getModuleTypeLabel(value) {
            return this.$t(`labels.modules.${value}`);
        },
        getModuleTypeIcon(value) {
            const found = this.ALL_MODULES_TYPES_ALL.find((w) => w.value == value);
            if (found !== undefined) {
                return found.icon;
            }
            return value;
        },
    },
    computed: {
        ALL_MODULES_TYPES() {
            return [this.MODULE_TYPE_BOOKING, this.MODULE_TYPE_CLICK_AND_COLLECT, this.MODULE_TYPE_GIFT_VOUCHERS, this.MODULE_TYPE_WEBSITE];
        },
        ALL_MODULES_TYPES_YSERVICES() {
            return [this.MODULE_TYPE_BOOKING, this.MODULE_TYPE_CLICK_AND_COLLECT, this.MODULE_TYPE_GIFT_VOUCHERS];
        },
        ALL_MODULES_TYPES_WITH_OPTIONS() {
            return this.ALL_MODULES_TYPES.concat([this.MODULE_TYPE_CLICK_AND_COLLECT_DISPATCH]);
        },
        ALL_MODULES_TYPES_WITH_OPTIONS_AND_CAMPAIGNS() {
            return this.ALL_MODULES_TYPES.concat([this.MODULE_TYPE_CLICK_AND_COLLECT_DISPATCH, this.MODULE_TYPE_CAMPAIGNS]);
        },
        ALL_MODULES_TYPES_WITH_CAMPAIGNS() {
            return this.ALL_MODULES_TYPES.concat([this.MODULE_TYPE_CAMPAIGNS]);
        },
        ALL_MODULES_TYPES_ALL() {
            return [
                this.MODULE_TYPE_DEFAULT,
                this.MODULE_TYPE_BOOKING,
                this.MODULE_TYPE_CLICK_AND_COLLECT,
                this.MODULE_TYPE_GIFT_VOUCHERS,
                this.MODULE_TYPE_WEBSITE,
                this.MODULE_TYPE_CAMPAIGNS,
            ];
        },
    },
};
