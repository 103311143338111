<template>
    <div class="d-flex flex-column">
        <div class="row mb-2">
            <div class="col-12">
                <strong>{{ $tl("labels.detail") }}</strong>
            </div>
        </div>
        <div>
            <LoaderComponent v-if="loading" />
            <template v-else>
                <div class="alert alert-danger" v-if="error">
                    {{ error }}
                </div>
                <template v-else>
                    <div>
                        <div class="p-2 my-1 border-light" v-for="(module, index) in modules">
                            <div class="d-flex justify-content-between align-items-center">
                                <span
                                    >{{ $tl("labels.licence") }} {{ getModuleTitle(module) }}
                                    {{ plan === "yearly" ? $tl("labels.yearlyF") : $tl("labels.monthlyF") }}</span
                                >
                                <span>x {{ module.count }}</span>
                            </div>
                        </div>
                        <div class="p-2 my-1 border-light" v-if="training">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ $tl("labels.form.licences.training") }}</span>
                                <small>{{ $tl("labels.form.licences.quotationSendAfter") }}</small>
                            </div>
                        </div>
                    </div>
                    <div v-if="isNoShow" class="d-flex justify-content-between align-items-center ml-2">
                        <label for="promo-code">{{ $tl("labels.promoCode.title") }}</label>
                        <div class="d-flex align-items-center position-relative">
                            <div class="row mb-2">
                                <div class="col-12">
                                    <input v-model="promoCode" :disabled="hasReduction" name="promo-code" placeholder="XXXXXX" class="form-control" />
                                </div>
                            </div>
                            <button
                                v-if="!hasReduction"
                                class="btn btn-sm btn-circle btn-success h-100 ml-2"
                                type="button"
                                :disabled="isPromoCodeLoading"
                                @click.stop.prevent="submitPromoCode">
                                {{ $tl("labels.form.actions.apply") }}
                            </button>
                            <span v-else class="d-inline-block pointer position-absolute" style="right: 0.25rem" @click.prevent.stop="resetPromoCode">
                                <feather type="x" class="feather-red" />
                            </span>
                        </div>
                    </div>
                    <small v-if="hasReduction && reduction.duration !== COUPON_DURATION_FOREVER.value">
                        {{
                            $tl("labels.promoCode.appliedWithDuration", undefined, {
                                promoCode: getReductionLabelAmount(),
                                period: getPeriod(),
                                total: getTotalReduction(),
                            })
                        }}
                    </small>
                    <small v-else-if="hasReduction">
                        {{ $tl("labels.promoCode.applied", undefined, { promoCode: getReductionLabelAmount() }) }}
                    </small>
                    <div>
                        <div class="p-2 my-1 border-light d-flex justify-content-between align-items-center bg-light">
                            <span>{{
                                plan === STRIPE_PLAN_TYPE_MONTHLY.value
                                    ? $tl("labels.form.licences.monthlyCost")
                                    : $tl("labels.form.licences.yearlyCost")
                            }}</span>
                            <div>
                                <s v-if="hasReduction">
                                    <span>{{ this.formatCurrency(licencesCost / 100) }}</span>
                                </s>
                                <strong>{{ this.formatCurrency(calculatedPrice / 100) }}</strong>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";
import SubscriptionStatusEnum from "../../../mixins/enums/SubscriptionStatusEnum";
import LangsEnum from "../../../mixins/enums/LangsEnum";
import InputComponent from "../../forms/InputComponent.vue";
import CouponDurationTypeEnum from "../../../mixins/enums/CouponDurationTypeEnum";

export default {
    name: "LicencesDetails",
    components: {
        LoaderComponent,
        InputComponent,
    },
    mixins: [SubscriptionStatusEnum, LangsEnum, CouponDurationTypeEnum],
    data() {
        return {
            loading: false,
            isPromoCodeLoading: false,
            error: false,
            promoCode: null,
            promoCodeFromBo: null,
            reduction: null,
            errors: {
                form: null,
            },
        };
    },
    props: {
        modules: {
            required: true,
        },
        plan: {
            required: true,
        },
        training: {
            default: false,
        },
    },
    computed: {
        hasReduction() {
            return this.reduction !== null;
        },
        labelPlanModule() {
            return `stripe_${this.plan}_price`;
        },
        labelDiscountedPlanModule() {
            return `stripe_${this.plan}_discounted_price`;
        },
        selectedModules() {
            return this.modules.filter((module) => module.count > 0);
        },
        licencesCost() {
            return this.getPriceForModules(this.selectedModules);
        },
        calculatedPrice() {
            let price = this.licencesCost;

            if (this.hasReduction) {
                price -= this.getReduction(
                    this.getPriceForModules(this.selectedModules.filter((module) => module.type === this.promoCodeFromBo.module))
                );
            }

            return price;
        },
    },
    watch: {
        promoCode(newValue) {
            if (typeof newValue === "string") {
                this.promoCode = newValue.toUpperCase();
            }
        },
    },
    methods: {
        getPriceForModules(modules) {
            if (this.plan === this.STRIPE_PLAN_TYPE_MONTHLY.value) {
                // No discounted price in monthly plan
                return modules.reduce((accumulator, module) => accumulator + module[this.labelPlanModule] * module.count, 0);
            } else {
                let price = 0;
                const modulesLength = modules.length;

                modules.forEach((module, index) => {
                    let label = this.labelDiscountedPlanModule;
                    if (this.getValidForDiscountSubscriptions(this.plan).length === 0 && (index === 0 || modulesLength === 1)) {
                        label = this.labelPlanModule;
                    }

                    price += module[label] * module.count;
                });

                return price;
            }
        },
        submitPromoCode() {
            if (this.promoCode === null || this.promoCode === "" || typeof this.promoCode !== "string") {
                return;
            }

            this.isPromoCodeLoading = true;
            const data = {
                plan: this.plan,
                modules: this.modules,
            };

            this.httpPost(`/api/stripe/promo-codes/${this.promoCode.trim()}`, data)
                .then((response) => {
                    if (response !== false) {
                        this.reduction = response.data.reduction;
                        this.promoCodeFromBo = response.data.promoCodeFromBo;
                        this.$emit("update-promo-code", response.data.promoCodeId);
                    }
                })
                .finally(() => (this.isPromoCodeLoading = false));
        },
        getReduction(price) {
            if (this.reduction.amount_off !== null) {
                return this.reduction.amount_off;
            }

            if (this.reduction.percent_off !== null) {
                return Math.round((this.reduction.percent_off * price) / 100);
            }
            return 0;
        },
        resetPromoCode() {
            this.promoCode = null;
            this.reduction = null;
            this.$emit("update-promo-code", null);
        },
        getTotalReduction() {
            let duration = this.reduction.duration_in_months !== null ? this.reduction.duration_in_months : 1;
            const price = this.reduction.amount_off !== null ? this.reduction.amount_off / 100 : this.getPercentPrice();

            if (this.plan === this.STRIPE_PLAN_TYPE_YEARLY.value && this.reduction.duration !== this.COUPON_DURATION_ONCE.value) {
                duration = duration / 12;
            }
            return this.formatCurrency(price * duration);
        },
        getPercentPrice() {
            const subscriptionPrice = this.getLicencePriceByPlanAndModule();
            const reductionPrice = ((subscriptionPrice / 100) * this.reduction.percent_off) / 100;

            return Math.round(reductionPrice * 100) / 100;
        },
        getLicencePriceByPlanAndModule() {
            const modules = this.modules.filter((module) => module.type === this.promoCodeFromBo.module);

            return modules.reduce((accumulator, module) => accumulator + module[`stripe_${this.promoCodeFromBo.plan}_price`] * module.count, 0);
        },
        getPeriod() {
            if (this.reduction.duration !== this.COUPON_DURATION_FOREVER.value) {
                return `${this.displayDate(this.getDateTime().plus({ months: this.promoCodeFromBo.duration_in_month }))}`;
            }
            return "";
        },
        getReductionLabelAmount() {
            if (this.reduction.amount_off !== null) {
                return this.formatCurrency(this.reduction.amount_off / 100);
            } else {
                return `${this.reduction.percent_off} %`;
            }
        },
    },
};
</script>
