var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Stepper", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading",
          },
        ],
        staticClass: "border-light p-2 pl-5 pr-5",
        attrs: {
          step: _vm.step,
          reservation: _vm.reservation,
          "restaurant-id": _vm.selected_restaurant_id,
        },
        on: { "change-step": _vm.setStep },
      }),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent")
        : _vm.error
        ? _c("span", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _c(
            "keep-alive",
            [
              _c(_vm.currentStepComponent, {
                ref: "currentStepComponent",
                tag: "component",
                staticClass: "border-light p-3",
                staticStyle: { "border-top": "none" },
                attrs: {
                  reservation: _vm.reservation,
                  baseRestaurant: _vm.baseRestaurant,
                  restaurant: _vm.currentRestaurant,
                  formErrors: _vm.formErrors,
                  tablesIdToPreselect: _vm.tablesIdToPreselect,
                  serviceIdToPreselect: _vm.serviceIdToPreselect,
                  slotIdToPreselect: _vm.slotIdToPreselect,
                  restaurant_id: _vm.selected_restaurant_id,
                  selected_destination_restaurant:
                    _vm.selected_destination_restaurant,
                  vueMultiSelect: _vm.vueMultiSelect,
                },
                on: {
                  "next-step": function ($event) {
                    _vm.step++
                  },
                  "select-destination-restaurant":
                    _vm.selectDestinationRestaurant,
                  "enable-next-button": function ($event) {
                    return _vm.$emit("enable-next-button", $event)
                  },
                },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }