var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", { staticClass: "row m-0" }, [
            _c(
              "form",
              {
                staticClass: "col-12",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.saveOwner.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "row m-0" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4 mb-3" },
                          [
                            _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.$t("labels.personalInfos"))
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("input-component", {
                              attrs: {
                                "input-name": "firstname",
                                required: "",
                                label: _vm.$t("labels.form.firstname"),
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.ownerDetails.firstname,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ownerDetails, "firstname", $$v)
                                },
                                expression: "ownerDetails.firstname",
                              },
                            }),
                            _vm._v(" "),
                            _c("input-component", {
                              attrs: {
                                "input-name": "lastname",
                                required: "",
                                label: _vm.$t("labels.form.lastname"),
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.ownerDetails.lastname,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ownerDetails, "lastname", $$v)
                                },
                                expression: "ownerDetails.lastname",
                              },
                            }),
                            _vm._v(" "),
                            _c("input-component", {
                              attrs: {
                                "input-name": "email",
                                "input-type": "email",
                                required: "",
                                label: _vm.$t("labels.form.email"),
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.ownerDetails.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ownerDetails, "email", $$v)
                                },
                                expression: "ownerDetails.email",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-3" }, [
                              _c("div", { staticClass: "col-5" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "mb-0",
                                    attrs: { for: "tel" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("labels.form.phoneNumber")
                                        ) +
                                        " "
                                    ),
                                    _c("small", [_vm._v("*")]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("small", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(
                                        _vm.$t(
                                          "infos.account.changePhoneNumber"
                                        )
                                      ) +
                                      "\n                                        "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("tel-input-component", {
                                    attrs: {
                                      inputName: "tel",
                                      placeholder: _vm.$t(
                                        "labels.form.phoneNumber"
                                      ),
                                      formErrors: _vm.errors.form,
                                    },
                                    on: {
                                      "set-tel-country": function ($event) {
                                        _vm.ownerDetails.tel_country = $event
                                      },
                                      "set-tel-is-mobile": function ($event) {
                                        _vm.ownerDetails.tel_is_mobile = $event
                                      },
                                    },
                                    model: {
                                      value: _vm.ownerDetails.tel,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ownerDetails, "tel", $$v)
                                      },
                                      expression: "ownerDetails.tel",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.isNoShow
                              ? _c("input-component", {
                                  attrs: {
                                    "input-name": "fiducial_client_id",
                                    label: "N° client Fiducial",
                                    "form-errors": _vm.errors.form,
                                  },
                                  model: {
                                    value: _vm.ownerDetails.fiducial_client_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ownerDetails,
                                        "fiducial_client_id",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ownerDetails.fiducial_client_id",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isYservices
                              ? _c("input-component", {
                                  attrs: {
                                    "input-name": "yprox_user_id",
                                    label: "Identifiant du compte Yproximité",
                                    "label-classes": "mb-0",
                                    "form-errors": _vm.errors.form,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "after-label",
                                        fn: function () {
                                          return [
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "small",
                                              { staticClass: "text-muted" },
                                              [
                                                _vm._v(
                                                  "\n                                            Identifiant du compte Yproximité du client pour permettre l'autologin. Attention : si le client ne\n                                            possède pas de compte Yproximité, il faut impérativement laisser ce champ vide.\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1635239390
                                  ),
                                  model: {
                                    value: _vm.ownerDetails.yprox_user_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ownerDetails,
                                        "yprox_user_id",
                                        $$v
                                      )
                                    },
                                    expression: "ownerDetails.yprox_user_id",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4 mb-3" },
                          [
                            _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "labels.form.facturation.titleClient"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("input-component", {
                              attrs: {
                                "input-name": "company",
                                required: "",
                                label: _vm.$t("labels.form.businessName"),
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.ownerDetails.company,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ownerDetails, "company", $$v)
                                },
                                expression: "ownerDetails.company",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col-5 pt-2" }, [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(_vm.$t("labels.form.businessType")) +
                                      " "
                                  ),
                                  _c("small", [_vm._v("*")]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-7" },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.ownerDetails.company_type,
                                          expression:
                                            "ownerDetails.company_type",
                                        },
                                      ],
                                      staticClass: "custom-select mb-0",
                                      attrs: {
                                        id: "company_type",
                                        name: "companyType",
                                        required: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.ownerDetails,
                                            "company_type",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.ALL_BUSINESS_TYPES,
                                      function (businessType) {
                                        return _c(
                                          "option",
                                          {
                                            key: businessType.value,
                                            domProps: {
                                              value: businessType.value,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm.getBusinessTypeLabel(
                                                    businessType.value
                                                  )
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    attrs: {
                                      errors: _vm.errors.form,
                                      errorKey: "company_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col-5 pt-2" }, [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("labels.form.facturation.siret")
                                    ) + " "
                                  ),
                                  _c("small", [_vm._v("*")]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-7" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.ownerDetails.company_siret,
                                        expression:
                                          "ownerDetails.company_siret",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      minlength: "14",
                                      maxlength: "14",
                                      type: "text",
                                      name: "companySiret",
                                      required: "",
                                    },
                                    domProps: {
                                      value: _vm.ownerDetails.company_siret,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.ownerDetails,
                                          "company_siret",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    attrs: {
                                      errors: _vm.errors.form,
                                      errorKey: "company_siret",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input-component", {
                              attrs: {
                                "input-name": "company_code_naf",
                                required: "",
                                label: _vm.$t(
                                  "labels.form.facturation.nafCode"
                                ),
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.ownerDetails.company_code_naf,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ownerDetails,
                                    "company_code_naf",
                                    $$v
                                  )
                                },
                                expression: "ownerDetails.company_code_naf",
                              },
                            }),
                            _vm._v(" "),
                            _c("input-component", {
                              attrs: {
                                "input-name": "company_code_tva",
                                required: "",
                                label: _vm.$t(
                                  "labels.form.facturation.tvaNumber"
                                ),
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.ownerDetails.company_code_tva,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ownerDetails,
                                    "company_code_tva",
                                    $$v
                                  )
                                },
                                expression: "ownerDetails.company_code_tva",
                              },
                            }),
                            _vm._v(" "),
                            _c("input-component", {
                              attrs: {
                                "input-name": "company_address",
                                required: "",
                                label: _vm.$t("labels.form.address"),
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.ownerDetails.company_address,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ownerDetails,
                                    "company_address",
                                    $$v
                                  )
                                },
                                expression: "ownerDetails.company_address",
                              },
                            }),
                            _vm._v(" "),
                            _c("input-component", {
                              attrs: {
                                "input-name": "company_zipcode",
                                required: "",
                                label: _vm.$t("labels.form.postalCode"),
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.ownerDetails.company_zipcode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ownerDetails,
                                    "company_zipcode",
                                    $$v
                                  )
                                },
                                expression: "ownerDetails.company_zipcode",
                              },
                            }),
                            _vm._v(" "),
                            _c("input-component", {
                              attrs: {
                                "input-name": "company_city",
                                required: "",
                                label: _vm.$t("labels.form.city"),
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.ownerDetails.company_city,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ownerDetails,
                                    "company_city",
                                    $$v
                                  )
                                },
                                expression: "ownerDetails.company_city",
                              },
                            }),
                            _vm._v(" "),
                            _c("input-component", {
                              attrs: {
                                "input-name": "company_country",
                                required: "",
                                label: _vm.$t("labels.form.country"),
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.ownerDetails.company_country,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ownerDetails,
                                    "company_country",
                                    $$v
                                  )
                                },
                                expression: "ownerDetails.company_country",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between px-3" },
                  [
                    !_vm.hideCancelBtn
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-success btn-circle",
                            attrs: { type: "button" },
                            on: { click: _vm.cancel },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("labels.form.actions.cancel")) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-success btn-circle",
                        attrs: { type: "submit" },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("labels.form.actions.save")) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }