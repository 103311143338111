<template>
    <thead>
        <ns-th field="reservations.client" width="6" allow-sorting>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.client')"></span>
            <feather class="none-desk" type="user" />
        </ns-th>
        <ns-th field="reservations.reservation_datetime" width="14" allow-sorting>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.reservation.reservationDate')"></span>
            <feather class="none-desk" type="calendar" />
        </ns-th>
        <ns-th field="slots.hour_start" width="9">
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.hours')"></span>
            <feather class="none-desk" type="clock" />
        </ns-th>
        <ns-th field="reservations.total_nb_pers" width="9" allow-sorting>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.reservation.pax')"></span>
            <feather class="none-desk" type="users" />
        </ns-th>
        <ns-th field="reservation_mail_logs.created_at" width="7" allow-sorting>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.sentDate')"></span>
            <feather class="none-desk" type="send" />
        </ns-th>
        <ns-th field="status" width="18">
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.shipmentStatus')"></span>
            <feather class="none-desk" type="message-square" />
        </ns-th>
        <ns-th field="re_sent" width="2">
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.resendMail')"></span>
            <feather class="none-desk" type="send" />
        </ns-th>
    </thead>
</template>
<script>
import NsTh from "../../../../Datatable/NsTh.vue";

export default {
    components: {
        NsTh,
    },
};
</script>
