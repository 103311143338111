<template>
    <div class="container-fluid h-100">
        <div class="row h-100">
            <div class="col-12 h-100">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ErrorsLayout",
};
</script>
