var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "stats-vue p-0 padding-vue pb-5 sevices-report" },
    [
      _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [
                _vm._v(_vm._s(_vm.$tl("labels.routes.booking.stats"))),
              ]),
              _vm._v(" "),
              _vm.has_right_to_export_stats && !_vm.isAdmin
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-sm btn-circle btn-outline-secondary",
                      attrs: { type: "button", disabled: _vm.filters === null },
                      on: { click: _vm.excelExport },
                    },
                    [
                      _c("feather", {
                        staticClass: "mr-1",
                        attrs: { type: "download-cloud" },
                      }),
                      _vm._v(
                        _vm._s(_vm.$tl("labels.form.actions.excelExport")) +
                          "\n                "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pt-0 pr-3 pb-3 pl-3" },
        [
          _c("div", { staticClass: "row mb-3" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("booking-stats-filters", {
                  ref: "filters",
                  on: { "filters-updated": _vm.onFiltersUpdated },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.filters !== null
            ? [
                _c("div", { staticClass: "row m-0 mb-2" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("strong", { staticClass: "text-success" }, [
                      _vm._v(_vm._s(_vm.labelPeriodMain)),
                    ]),
                    _vm._v(" "),
                    _vm.filters.hasComparison
                      ? _c("span", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$tl("labels.filters.comparedTo")) +
                              "\n                        "
                          ),
                          _c("strong", { staticClass: "text-success" }, [
                            _vm._v(_vm._s(_vm.labelPeriodComparison)),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("origin-booking-component", {
                  attrs: {
                    filters: _vm.filters,
                    grid: _vm.grid,
                    "has-rwg": _vm.hasEnabledGoogleReserve,
                  },
                }),
                _vm._v(" "),
                _c("nb-pax-by-origin", {
                  attrs: {
                    filters: _vm.filters,
                    grid: _vm.grid,
                    "has-rwg": _vm.hasEnabledGoogleReserve,
                  },
                }),
                _vm._v(" "),
                _c("waiting-list-component", {
                  attrs: { filters: _vm.filters, grid: _vm.grid },
                }),
                _vm._v(" "),
                _c("OtherStatsComponent", { attrs: { filters: _vm.filters } }),
                _vm._v(" "),
                _vm._l(_vm.orderGraphs, function (graph) {
                  return _c(graph.name, {
                    key: graph.name,
                    tag: "component",
                    attrs: { filters: _vm.filters, grid: _vm.grid },
                  })
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }