var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid h-100 login" }, [
    !_vm.isBrowserIE
      ? _c("div", { staticClass: "row m-0 h-100 w-100" }, [
          _c("nav", {
            staticClass: "col-md-4 col-lg-4 d-md-block bg-dark sidebar shadow",
          }),
          _vm._v(" "),
          _c(
            "main",
            {
              staticClass: "col-md-8 ml-sm-auto col-lg-8 px-md-5 h-100 d-flex",
            },
            [_c("router-view")],
            1
          ),
        ])
      : _c("div", { staticStyle: { "text-align": "center" } }, [
          _c("h1", [_vm._v(_vm._s(_vm.$tl("errors.browser.invalid.title")))]),
          _vm._v(" "),
          _c("p", {
            domProps: {
              innerHTML: _vm._s(_vm.$t("errors.browser.invalid.infos")),
            },
          }),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }