<template>
    <div class="mt-4">
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-md-12">
                        <div class="border-light b-radius-20 p-4">
                            <img class="img-connect-serv" src="/images/logo-payplug.png" />

                            <div class="mt-4" v-if="!is_connected">
                                <template v-if="!is_connected">
                                    <p class="value">
                                        {{ $tl("labels.form.psp.payplug.register1") }}
                                        <a :href="`https://portal.payplug.com/signup?sponsor=${sponsorId}`" target="_blank">
                                            {{ $tl("labels.form.psp.payplug.register2") }}.
                                        </a>
                                    </p>
                                    <p class="text-warning font-weight-bold">
                                        {{ $tl("labels.form.psp.payplug.minimumOffer") }}
                                    </p>
                                </template>
                                <p>
                                    {{ $t("labels.form.psp.payplug.help1", { appName }) }}<br />
                                    {{ $tl("labels.form.psp.payplug.help2") }}
                                    <a href="https://portal.payplug.com/#/configuration/api" target="_blank">{{
                                        $tl("labels.form.psp.payplug.help3")
                                    }}</a>
                                    {{ $tl("labels.form.psp.payplug.help4") }}
                                </p>
                            </div>
                            <div v-else class="mb-3 mt-4" style="border: 2px solid #ffc107; padding: 20px">
                                <h5>
                                    <strong>{{ $tl("labels.useful") }}</strong>
                                </h5>
                                <p class="m-0">
                                    {{ $tl("labels.form.psp.payplug.notifications.help1") }}<br /><br />
                                    {{ $tl("labels.form.psp.payplug.notifications.help2") }}
                                    <a href="https://portal.payplug.com/#/configuration/notifications" target="_blank">{{
                                        $tl("labels.form.psp.payplug.notifications.help3")
                                    }}</a
                                    >.<br /><br />
                                    {{ $tl("labels.form.psp.payplug.notifications.help4") }}
                                </p>
                                <ul class="m-0">
                                    <li
                                        v-for="(notif, index) in $tl('labels.form.psp.payplug.notifications.notifs')"
                                        style="list-style: inherit"
                                        :key="index">
                                        {{ notif }}
                                    </li>
                                </ul>
                            </div>

                            <div class="mb-3 id-stripe">
                                <div class="row">
                                    <div class="col-md-6 pt-1">
                                        {{ $tl("labels.form.psp.payplug.publicApiKey") }}
                                    </div>
                                    <div class="col-md-6">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="restaurant.payplug_public_key"
                                            :class="{ disabled: is_connected || !can_edit_payplug_ }"
                                            :disabled="is_connected || !can_edit_payplug_" />
                                        <ShowErrors :errors="formErrors" errorKey="payplug_public_key" />
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-6 pt-1">
                                        {{ $tl("labels.form.psp.payplug.privateApiKey") }}
                                    </div>
                                    <div class="col-md-6">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="restaurant.payplug_secret_key"
                                            :class="{ disabled: is_connected || !can_edit_payplug_ }"
                                            :disabled="is_connected || !can_edit_payplug_" />
                                        <ShowErrors :errors="formErrors" errorKey="payplug_secret_key" />
                                    </div>
                                </div>
                            </div>

                            <p class="notice-text">
                                {{ $tl("labels.form.psp.payplug.description1") }}<br />
                                {{ $tl("labels.form.psp.payplug.description2") }}
                            </p>

                            <div v-if="can_edit_payplug_ && !is_connected">
                                <button class="btn btn-success btn-sm btn-circle mt-3" type="button" @click="connectPayPlugAccount">
                                    {{ $tl("labels.form.psp.payplug.linkAccount") }}
                                </button>
                            </div>
                            <div v-else-if="can_edit_payplug_ && is_connected">
                                <button class="btn btn-sm btn-warning btn-circle text-white mt-3" type="button" @click="disconnectPayPlugAccount">
                                    {{ $tl("labels.form.psp.payplug.disconnectAccount") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../LoaderComponent.vue";
import ShowErrors from "../../errors/ShowErrors";

export default {
    data() {
        return {
            sponsorId: PAYPLUG_SPONSOR_ID,
            appName: APP_NAME,
            loading: false,
            error: null,
            formErrors: null,
            restaurant: {
                id: null,
                payplug_public_key: null,
                payplug_secret_key: null,
            },
            can_edit_payplug: false,
            is_connected: false,
        };
    },
    props: {
        restaurant_id: {
            default: null,
        },
    },
    methods: {
        fetchData() {
            this.error = this.restaurant = null;
            this.loading = true;

            // control that user can edit payplug infos
            axios
                .get(`/api/restaurants/${this.restaurantId}/stripe/control`)
                .then(() => {
                    this.can_edit_payplug = true;
                    this.fetchRestaurant();
                })
                .catch(() => {
                    this.loading = false;
                    this.can_edit_payplug = false;
                });
        },
        fetchRestaurant() {
            this.loading = true;
            this.error = this.restaurant = null;
            this.$store
                .dispatch("restaurants/fetchRestaurantById", {
                    restaurant_id: this.restaurantId,
                })
                .then((response) => {
                    this.loading = false;
                    this.restaurant = response.data;
                    this.is_connected = this.restaurant.payplug_public_key ? true : false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        connectPayPlugAccount(e) {
            if (e) e.preventDefault();
            this.loading = true;
            this.formErrors = null;

            axios
                .post(`/api/restaurants/${this.restaurantId}/payplug/connect`, this.restaurant)
                .then((response) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.fetchData();
                    this.$emit("done");
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                    if (error.response && error.response.data.errors) this.formErrors = error.response.data.errors;
                });
        },
        disconnectPayPlugAccount(e) {
            if (e) e.preventDefault();
            if (!confirm("Êtes vous sûr de vouloir déconnecter votre compte PayPlug ?")) return;
            this.loading = true;
            this.formErrors = null;

            axios
                .post(`/api/restaurants/${this.restaurantId}/payplug/disconnect`)
                .then((response) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.fetchData();
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                    if (error.response && error.response.data.errors) this.formErrors = error.response.data.errors;
                });
        },
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_restaurant() {
            return this.rights.includes("default.restaurants.update");
        },
        restaurantId() {
            if (this.restaurant_id) {
                return this.restaurant_id;
            }

            return this.$route.params.restaurant_id;
        },
        can_edit_payplug_() {
            return this.can_edit_payplug && this.has_right_to_update_restaurant;
        },
    },
    created() {
        this.fetchData();
    },
    watch: {
        restaurantId: function (id) {
            this.fetchData();
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
