var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row",
      class: {
        "default-home":
          _vm.userRole != _vm.ROLE_ADMIN.value &&
          _vm.userRole != _vm.ROLE_COURIER.value,
      },
      staticStyle: { height: "auto !important" },
    },
    [
      _vm.userRole != _vm.ROLE_ADMIN.value &&
      _vm.userRole != _vm.ROLE_COURIER.value
        ? _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                  },
                  [
                    _c(
                      "h5",
                      {
                        staticClass: "title mt-2",
                        attrs: { "data-test-id": "title-default_home" },
                      },
                      [_vm._v(_vm._s(_vm.$tl("labels.services")))]
                    ),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.userRole === _vm.ROLE_ADMIN.value
            ? _c("HomeAdmin")
            : _vm.userRole === _vm.ROLE_COURIER.value
            ? _c("HomeCourier")
            : _c("modules-list"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }