var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-100" }, [
    !_vm.isBrowserIE
      ? _c(
          "div",
          { staticClass: "back-off h-100" },
          [
            _c("topbar"),
            _vm._v(" "),
            _c("div", { staticClass: "container-fluid" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "main",
                    {
                      staticClass: "default mr-sm-auto mt-5",
                      class:
                        _vm.isAdmin || _vm.isCourier
                          ? "col-12"
                          : "col-lg-8 pl-0 pr-0",
                      attrs: { role: "main" },
                    },
                    [
                      _c("notifications", {
                        staticStyle: { "z-index": "9999" },
                        attrs: { group: "notification" },
                      }),
                      _vm._v(" "),
                      _vm._t("default"),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.isAdmin && !_vm.isCourier
                    ? _c("sidebar", {
                        staticClass: "col-lg-4",
                        staticStyle: { "margin-top": "60px" },
                        attrs: { user: _vm.user, entries: _vm.sidebarEntries },
                        on: {
                          "reload-router-view": function ($event) {
                            return _vm.$emit("reload-router-view")
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ],
          1
        )
      : _c("div", { staticStyle: { "text-align": "center" } }, [
          _c("h1", [_vm._v(_vm._s(_vm.$tl("errors.browser.invalid.title")))]),
          _vm._v(" "),
          _c("p", {
            domProps: {
              innerHTML: _vm._s(_vm.$t("errors.browser.invalid.infos")),
            },
          }),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }