var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.stripTags
        ? [
            _vm.hasMergeTags
              ? _c("div", [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-sm btn-outline-secondary dropdown-toggle border-bottom-0",
                      staticStyle: { "border-color": "#ededed" },
                      attrs: { type: "button", "data-toggle": "dropdown" },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$tl("labels.mergeTags")) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu dropdown-menu-left shadow",
                      staticStyle: {
                        transform: "translate3d(13px, 29px, 0px)",
                        "min-width": "100px",
                      },
                    },
                    _vm._l(_vm.mergeTags, function (mergeTag) {
                      return _c(
                        "button",
                        {
                          key: mergeTag.name,
                          staticClass: "dropdown-item d-flex pl-1",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.addText(mergeTag.tag)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(mergeTag.description) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.valueComputed,
                  expression: "valueComputed",
                },
              ],
              ref: "smsTextarea",
              staticClass: "form-control",
              attrs: { disabled: _vm.disabled, rows: "4" },
              domProps: { value: _vm.valueComputed },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.valueComputed = $event.target.value
                },
              },
            }),
          ]
        : _c("ckeditor", {
            ref: "ckMailEditor",
            attrs: {
              editor: _vm.editor,
              config: _vm.editorConfig,
              disabled: _vm.disabled,
            },
            model: {
              value: _vm.valueComputed,
              callback: function ($$v) {
                _vm.valueComputed = $$v
              },
              expression: "valueComputed",
            },
          }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }