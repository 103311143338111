<template>
    <form @submit="save">
        <loader-component v-if="loading" />
        <div v-show="!loading">
            <div class="mb-4 pointer">
                <span @click="$emit('clear-selected-form')"> <feather type="chevron-left" class="mr-1" />{{ $tl("labels.form.actions.back") }} </span>
            </div>
            <div v-if="formError" class="alert alert-danger">
                {{ formError }}
            </div>
            <div v-if="ibanError" class="alert alert-danger">
                {{ ibanError }}
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    {{ $tl("labels.form.psp.paymentMethods.sepaDebit.owner") }}
                </div>
                <div class="col-md-6">
                    <input type="text" v-model="bankAccountOwner.name" class="form-control" required />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    {{ $tl("labels.form.psp.paymentMethods.sepaDebit.email") }}
                </div>
                <div class="col-md-6">
                    <input type="email" v-model="bankAccountOwner.email" class="form-control" required />
                </div>
            </div>
            <div ref="ibanForm"></div>
            <button v-if="showButtons" :disabled="ibanError" type="submit" class="btn btn-success btn-circle" :class="{ disabled: ibanError }">
                {{ $tl("labels.form.actions.save") }}
            </button>
        </div>
    </form>
</template>

<script>
import LoaderComponent from "../../../LoaderComponent";
import LangsEnum from "../../../../mixins/enums/LangsEnum";

export default {
    name: "AddSepaDebit",
    data() {
        return {
            loading: false,
            ibanError: null,
            formError: null,
            stripe: {
                api: null,
                elements: null,
                ibanElement: null,
                setupIntent: null,
            },
            bankAccountOwner: {
                name: null,
                email: null,
            },
        };
    },
    mixins: [LangsEnum],
    props: {
        showButtons: {
            default: true,
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.init();
        });
    },
    updated() {
        this.init();
    },
    created() {
        this.loading = true;
        this.getSetupIntent().then(() => {
            this.loading = false;
        });
    },
    methods: {
        init() {
            if (this.stripe.api === null) {
                this.stripe.api = Stripe(STRIPE_PUBLIC_API_KEY, {
                    locale: this.userLang,
                });

                this.stripe.elements = this.stripe.api.elements();

                this.ibanElement = this.stripe.elements.create("iban", {
                    supportedCountries: ["SEPA"],
                });
                this.ibanElement.mount(this.$refs.ibanForm);

                this.ibanElement.addEventListener("change", (event) => {
                    this.ibanError = event.error ? event.error.message : "";
                });
            }
        },
        save(e) {
            if (e) e.preventDefault();
            if (this.ibanError) return;
            if (!this.bankAccountOwner.name) {
                this.formError = this.$tl("errors.psp.sepaDebit.owner");
                return;
            }
            if (!this.bankAccountOwner.name) {
                this.formError = this.$tl("errors.psp.sepaDebit.email");
                return;
            }
            this.formError = null;
            this.loading = true;

            this.stripe.api
                .confirmSepaDebitSetup(this.stripe.setupIntent, {
                    payment_method: {
                        sepa_debit: this.ibanElement,
                        billing_details: this.bankAccountOwner,
                    },
                })
                .then((result) => {
                    if (result.error) {
                        this.formError = result.error.message;
                        this.loading = false;
                    } else {
                        this.$store
                            .dispatch("stripe/newPaymentMethod", { id: result.setupIntent.payment_method })
                            .then(() => {
                                this.$emit("updated");
                            })
                            .catch((error) => {
                                this.formError = this.getErrorMsgFromErrorResponse(error);
                                this.loading = false;
                            });
                    }
                });
        },
        getSetupIntent() {
            return this.$store
                .dispatch("stripe/getSepaSetupIntent")
                .then((response) => {
                    this.stripe.setupIntent = response.data.setupIntent;
                })
                .catch((error) => {
                    this.formError = this.getErrorMsgFromErrorResponse(error);
                });
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>
