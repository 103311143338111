export const actions = {
    fetchAllRestaurants({ commit }, { store = false, includes = "" } = {}) {
        const defaultIncludes = "cgv,has_psp,custom_events,subscriptions,notifications_setting,google_maps_address";

        if (includes && includes.length > 0) {
            includes += `,${defaultIncludes}`;
        } else {
            includes = `?include=${defaultIncludes}`;
        }

        return new Promise((resolve, reject) => {
            axios
                .get(`/api/restaurants${includes}`)
                .then((response) => {
                    if (store) {
                        commit("setRestaurants", response.data.data);
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchRestaurantById({}, { restaurant_id, includes = [] }) {
        return axios.get(`/api/restaurants/${restaurant_id}` + (includes.length > 0 ? `?include=${includes.join(",")}` : ""));
    },
    addRestaurant({}, { formData }) {
        return axios.post("/api/restaurants", formData);
    },
    editRestaurant({}, { restaurant_id, formData }) {
        return axios.post(`/api/restaurants/${restaurant_id}`, formData);
    },
    editSmsSettings({}, { restaurant_id, formData }) {
        return axios.post(`/api/restaurants/${restaurant_id}/sms_settings`, formData);
    },
    updateCGV({ dispatch }, { params, restaurant_id, fetch = true }) {
        const promise = axios.post(`/api/booking/${restaurant_id}/cgv-contact`, params);

        if (fetch) {
            promise.then((response) => {
                dispatch("fetchAllRestaurants", { store: true });
                return response;
            });
        }

        return promise;
    },
    setUnsavedNotificationsChanges({ commit }, unsavedNotificationsChanges) {
        commit("setUnsavedNotificationsChanges", unsavedNotificationsChanges);
    },
};

export default actions;
