export default {
    setIsReservationListCollapsed(state, isReservationListCollapsed) {
        state.isReservationListCollapsed = isReservationListCollapsed;
    },
    setIsGroupsAndPrintTabCollapsed(state, isGroupsAndPrintTabCollapsed) {
        state.isGroupsAndPrintTabCollapsed = isGroupsAndPrintTabCollapsed;
    },
    setPinPercentOfMenuSold(state, pinPercentOfMenuSold) {
        state.pinPercentOfMenuSold = pinPercentOfMenuSold;
    },
    setPinPercentOfMenuOptionSold(state, pinPercentOfMenuOptionSold) {
        state.pinPercentOfMenuOptionSold = pinPercentOfMenuOptionSold;
    },
    setPinPercentOfGeneralOptionSold(state, pinPercentOfGeneralOptionSold) {
        state.pinPercentOfGeneralOptionSold = pinPercentOfGeneralOptionSold;
    },
    setPinPax(state, pinPax) {
        state.pinPax = pinPax;
    },
    setPinEvolutionNbResa(state, pinEvolutionNbResa) {
        state.pinEvolutionNbResa = pinEvolutionNbResa;
    },
    setPinCaPerMenu(state, pinCaPerMenu) {
        state.pinCaPerMenu = pinCaPerMenu;
    },
    setPinBookingsPeriod(state, pinBookingsPeriod) {
        state.pinBookingsPeriod = pinBookingsPeriod;
    },
    setPinClientLoyal(state, pinClientLoyal) {
        state.pinClientLoyal = pinClientLoyal;
    },
    setPinCaPerGeneralOption(state, pinCaPerGeneralOption) {
        state.pinCaPerGeneralOption = pinCaPerGeneralOption;
    },
    setPinCaPerMenuOption(state, pinCaPerMenuOption) {
        state.pinCaPerMenuOption = pinCaPerMenuOption;
    },
};
