var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5 pt-5" },
    [
      _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [_vm._v("Intégration")]),
              _vm._v(" "),
              _c("a", { attrs: { target: "_blank", href: _vm.widgetLink } }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-sm btn-success btn-sm btn-circle mr-1",
                    attrs: {
                      disabled:
                        _vm.apiKey === null ||
                        !_vm.restaurant ||
                        (!_vm.restaurant.stripe_client_id &&
                          (!_vm.restaurant.payplug_public_key ||
                            !_vm.restaurant.payplug_secret_key)),
                      type: "button",
                    },
                  },
                  [
                    _vm._v(
                      "\n                        Voir mon widget\n                    "
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("overlay-layout", {
        staticClass: "m-1",
        attrs: {
          "display-overlay":
            !_vm.restaurant ||
            (!_vm.restaurant.stripe_client_id &&
              (!_vm.restaurant.payplug_public_key ||
                !_vm.restaurant.payplug_secret_key)),
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "row m-0 mb-3" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                      _c("div", { staticClass: "row mb-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("strong", [
                            _vm._v(
                              "Informations concernant l'intégration sur votre site web"
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("p", [
                            _vm._v(
                              "\n                                    Pour intégrer le widget vente en ligne sur votre site web, vous devez transmettre les informations ci-dessous\n                                    à votre développeur.\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n                                    Nous vous recommandons d'intégrer le module directement dans votre site internet en suivant les étapes\n                                    ci-dessous. Pour un affichage optimal, il est recommandé d'utiliser la largeur totale de l'écran en limitant\n                                    les marges gauche et droite.\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              '\n                                    Vous pouvez toutefois intégrer le widget sous forme de bouton qui redirige vers une page externe\n                                    (copier-coller le lien accessible depuis le bouton "voir mon widget").\n                                '
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 text-danger" }, [
                            _vm._v(
                              "\n                                    Merci de ne pas intégrer le widget avec un iframe car la hauteur ne peut pas s'adapter automatiquement au\n                                    contenu et cela impacte l'expérience utilisateur.\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-sm btn-success btn-sm btn-circle",
                              attrs: {
                                disabled: !_vm.has_right_to_update_config,
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showSendWidgetDocToDevModal = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                    Transmettre les instructions d'intégration\n                                "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row m-0 mb-3" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("strong", [
                            _vm._v("Intégration du widget sur votre site web"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mt-3" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _c("label", [_vm._v("Lien court")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(_vm.appUrl) + "/vente-en-ligne/"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.shortlink,
                                    expression: "shortlink",
                                  },
                                ],
                                staticClass: "form-control",
                                staticStyle: { width: "45%" },
                                attrs: {
                                  type: "text",
                                  maxlength: "40",
                                  disabled: !_vm.has_right_to_update_config,
                                },
                                domProps: { value: _vm.shortlink },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.shortlink = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-sm btn-success btn-sm btn-circle ml-2",
                                  class: {
                                    disabled:
                                      !_vm.has_right_to_update_config ||
                                      _vm.isSavingShortlink,
                                  },
                                  attrs: {
                                    disabled:
                                      !_vm.has_right_to_update_config ||
                                      _vm.isSavingShortlink,
                                    type: "button",
                                  },
                                  on: { click: _vm.saveShortLink },
                                },
                                [
                                  _vm._v(
                                    "\n                                        Enregistrer\n                                    "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v(
                                "Le lien court ne peut contenir que des lettres minuscules, des tirets et des underscores (_). Max : 40\n                                        caractères"
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mt-3" }, [
                        _vm.apiKeyError
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "alert alert-danger col-md-12 pt-1",
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.apiKeyError) +
                                    "\n                            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-5" }, [
                          _c("label", [_vm._v("Clé API")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7" }, [
                          _vm.apiKey
                            ? _c("div", { staticClass: "mb-3 code-source" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.apiKey) +
                                    "\n                                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-sm btn-success btn-sm btn-circle",
                              attrs: {
                                disabled: !_vm.has_right_to_update_config,
                                type: "button",
                              },
                              on: { click: _vm.askApiKey },
                            },
                            [
                              !_vm.apiKey
                                ? _c("span", [_vm._v("Générer une clé API")])
                                : _c("span", [
                                    _vm._v("Générer une nouvelle clé API"),
                                  ]),
                            ]
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _vm.apiKey
                            ? _c("small", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "L'ancienne clé sera révoquée. Pensez à changer la valeur sur votre widget."
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mt-3" }, [
                        _vm.apiKey !== null
                          ? _c("div", { staticClass: "col-md-5 pt-1" }, [
                              _c("label", [_vm._v("Méthode d'intégration")]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.apiKey !== null
                          ? _c("div", { staticClass: "col-md-7 pt-1" }, [
                              _c("p", [
                                _c("strong", [_vm._v("Étape 1 :")]),
                                _vm._v(
                                  " Créez un conteneur (balise 'div') à l'endroit où vous souhaitez afficher le widget\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "code-source mb-3" }, [
                                _vm._v('<div id="click-and-collect"></div>'),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("strong", [_vm._v("Étape 2 :")]),
                                _vm._v(" Ajoutez le code suivant à votre site"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "code-source mb-3" },
                                _vm._l(_vm.jsToInclude, function (js, index) {
                                  return _c(
                                    "span",
                                    { key: index, staticClass: "d-block" },
                                    [_vm._v(_vm._s(js))]
                                  )
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _c("strong", [_vm._v("Étape 3 :")]),
                                _vm._v(
                                  " Initialisez le widget à l'aide du script ci-dessous"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "code-source mb-3" }, [
                                _vm._v(
                                  _vm._s(_vm.classFunctionName) +
                                    "('click-and-collect', '" +
                                    _vm._s(_vm.apiKey) +
                                    "');"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "mb-0" }, [
                                _c("strong", [
                                  _vm._v("Étape 4 (optionnelle) :"),
                                ]),
                                _vm._v(
                                  " Ajouter des paramètres à l'initialisation du widget"
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                                    Des paramètres optionnels sont disponibles afin de modifier le comportement du widget :\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("ol", [
                                _c(
                                  "li",
                                  { staticClass: "list-style-inherit" },
                                  [
                                    _vm._v(
                                      '\n                                        "type" : Permet de bloquer le widget en vente à emporter, livraison par coursier ou expédition\n                                        '
                                    ),
                                    _c("ul", [
                                      _c(
                                        "li",
                                        { staticClass: "list-style-inherit" },
                                        [_vm._v('"collect": Vente à emporter')]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        { staticClass: "list-style-inherit" },
                                        [
                                          _vm._v(
                                            '"delivery": Livraison par coursier'
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        { staticClass: "list-style-inherit" },
                                        [_vm._v('"dispatch": Expédition')]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "code-source" }, [
                                _vm._v(
                                  _vm._s(_vm.classFunctionName) +
                                    "('click-and-collect', '" +
                                    _vm._s(_vm.apiKey) +
                                    "', { type: 'delivery' });"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("FacebookIntegration", {
                  staticClass: "m-0 mb-3",
                  attrs: { module: _vm.MODULE_TYPE_CLICK_AND_COLLECT.value },
                }),
                _vm._v(" "),
                _c("InstagramIntegration", {
                  staticClass: "m-0 mb-3",
                  attrs: { module: _vm.MODULE_TYPE_CLICK_AND_COLLECT.value },
                }),
                _vm._v(" "),
                _c("GoogleLinkIntegration", { staticClass: "m-0 mb-3" }),
              ]
            },
            proxy: true,
          },
          {
            key: "overlay",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "is-white d-flex justify-content-center align-items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c("p", [
                          _vm._v(
                            "Veuillez connecter votre compte stripe pour accéder à la configuration du widget."
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.isYservices
                          ? _c(
                              "router-link",
                              {
                                staticClass: "btn btn-circle btn-success",
                                attrs: { to: { name: "restaurants" } },
                              },
                              [
                                _vm._v(
                                  "\n                        Ajouter un compte Stripe\n                    "
                                ),
                              ]
                            )
                          : _c(
                              "router-link",
                              {
                                staticClass: "btn btn-circle btn-success",
                                attrs: {
                                  to: {
                                    name: "restaurants.edit",
                                    params: {
                                      restaurant_id: _vm.restaurant_id,
                                    },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Ajouter un compte Stripe\n                    "
                                ),
                              ]
                            ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _vm.showSendWidgetDocToDevModal
        ? _c("send-click-and-collect-widget-doc-to-dev-modal", {
            ref: "sendClickAndCollectWidgetDocToDevModal",
            attrs: { restaurant_id: _vm.restaurant_id },
            on: {
              close: function ($event) {
                _vm.showSendWidgetDocToDevModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }