<template>
    <div class="default-sidebar sticky">
        <ul class="text-white mt-5 ml-4">
            <h5 class="title main text-uppercase mb-4 text-white" style="padding-left: 1rem">
                {{ $tl("labels.myAccount") }}
            </h5>
            <template v-for="(entry, index) in entries">
                <SidebarNavItem :key="index" v-if="typeof entry.display === 'undefined' || entry.display === true" :entry="entry" :user="user" />
            </template>
        </ul>
    </div>
</template>

<script>
import SidebarNavItem from "./SidebarNavItem.vue";

export default {
    name: "Sidebar",
    props: {
        entries: {
            default: () => [],
        },
        user: {
            required: true,
        },
    },
    components: {
        SidebarNavItem,
    },
};
</script>
