var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-5 pt-5" }, [
    _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [
            _c("h5", { staticClass: "title mt-2" }, [_vm._v("Produits")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.has_right_to_update &&
                _vm.config &&
                !_vm.config.display_categories
                  ? [
                      !_vm.isOrdering
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-sm btn-success mr-1 btn-circle",
                              on: {
                                click: function ($event) {
                                  return _vm.startOrdering()
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "css-i6dzq1 feather",
                                  attrs: {
                                    viewBox: "0 0 24 24",
                                    width: "24",
                                    height: "24",
                                    stroke: "currentColor",
                                    "stroke-width": "2",
                                    fill: "none",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                  },
                                },
                                [
                                  _c("polyline", {
                                    attrs: { points: "9 5 12 2 15 5" },
                                  }),
                                  _vm._v(" "),
                                  _c("polyline", {
                                    attrs: { points: "15 19 12 22 9 19" },
                                  }),
                                  _vm._v(" "),
                                  _c("line", {
                                    attrs: {
                                      x1: "12",
                                      y1: "2",
                                      x2: "12",
                                      y2: "22",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", [_vm._v("Ordonner les produits")]),
                            ]
                          )
                        : [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-success mr-1 btn-circle",
                                attrs: { disabled: _vm.loading },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveOrdering()
                                  },
                                },
                              },
                              [_c("span", [_vm._v("Enregistrer")])]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-outline-secondary mr-1 btn-circle",
                                attrs: { disabled: _vm.loading },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelOrdering()
                                  },
                                },
                              },
                              [_c("span", [_vm._v("Annuler")])]
                            ),
                          ],
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-success mr-1 btn-circle",
                    attrs: { disabled: !_vm.has_right_to_create },
                    on: {
                      click: function ($event) {
                        return _vm.addProduct()
                      },
                    },
                  },
                  [
                    _c("feather", { attrs: { type: "plus" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Ajouter un produit")]),
                  ],
                  1
                ),
              ],
              2
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row m-0 pb-5" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.loading
            ? _c("LoaderComponent")
            : _c("div", [
                _vm.error
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.error) +
                          "\n                "
                      ),
                    ])
                  : _vm.has_right_to_read
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-2",
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "d-inline-block",
                                  staticStyle: { "margin-top": "4px" },
                                },
                                [
                                  _c(
                                    "form",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.fetchData.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-control search-client d-inline pr-1",
                                          staticStyle: {
                                            "border-radius":
                                              "20px 0 0 20px !important",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.filter,
                                                expression: "filter",
                                              },
                                            ],
                                            staticClass: "search-input",
                                            attrs: {
                                              placeholder:
                                                "Nom, Description...",
                                              type: "text",
                                            },
                                            domProps: { value: _vm.filter },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.filter = $event.target.value
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "p-0 m-0 clear-search-button",
                                              staticStyle: {
                                                background: "none",
                                                border: "none",
                                              },
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.resetFilter()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "feather feather-x",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "24",
                                                    height: "24",
                                                    viewBox: "0 0 24 24",
                                                    fill: "none",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                  },
                                                },
                                                [
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "18",
                                                      y1: "6",
                                                      x2: "6",
                                                      y2: "18",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "6",
                                                      y1: "6",
                                                      x2: "18",
                                                      y2: "18",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-outline-secondary search-client-btn",
                                          staticStyle: {
                                            "border-radius": "0 20px 20px 0",
                                          },
                                          attrs: { type: "submit" },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "feather feather-search",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "24",
                                                height: "20",
                                                viewBox: "0 0 24 24",
                                                fill: "none",
                                                stroke: "currentColor",
                                                "stroke-width": "2",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                              },
                                            },
                                            [
                                              _c("circle", {
                                                attrs: {
                                                  cx: "11",
                                                  cy: "11",
                                                  r: "8",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "21",
                                                  y1: "21",
                                                  x2: "16.65",
                                                  y2: "16.65",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div"),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-sm table-striped border-bottom",
                          },
                          [
                            _c("thead", { staticClass: "border-bottom" }, [
                              _c("tr", [
                                _vm.isOrdering
                                  ? _c("th", [_vm._v("Ordre")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("th", [_vm._v("Nom")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Catégorie")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Statut")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Prix TTC")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Validité")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Service")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Actions")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "draggable",
                              {
                                attrs: {
                                  disabled: !_vm.isOrdering,
                                  tag: "tbody",
                                  draggable: ".products",
                                },
                                model: {
                                  value: _vm.products,
                                  callback: function ($$v) {
                                    _vm.products = $$v
                                  },
                                  expression: "products",
                                },
                              },
                              _vm._l(_vm.products, function (product) {
                                return _c(
                                  "tr",
                                  { key: product.id, staticClass: "products" },
                                  [
                                    _vm.isOrdering
                                      ? _c("td", [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "css-i6dzq1 feather",
                                              staticStyle: {
                                                cursor: "grab",
                                                color: "rgb(170, 170, 170)",
                                              },
                                              attrs: {
                                                viewBox: "0 0 24 24",
                                                width: "24",
                                                height: "24",
                                                stroke: "currentColor",
                                                "stroke-width": "2",
                                                fill: "none",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                              },
                                            },
                                            [
                                              _c("line", {
                                                attrs: {
                                                  x1: "3",
                                                  y1: "9",
                                                  x2: "21",
                                                  y2: "9",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "3",
                                                  y1: "15",
                                                  x2: "21",
                                                  y2: "15",
                                                },
                                              }),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("td", [
                                      product.name.length < 40
                                        ? _c("span", [
                                            _vm._v(_vm._s(product.name)),
                                          ])
                                        : product.name.length >= 40
                                        ? _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: {
                                                    delay: {
                                                      show: 400,
                                                      hide: 0,
                                                    },
                                                    content: product.name,
                                                  },
                                                  expression:
                                                    "{ delay: { show: 400, hide: 0 }, content: product.name }",
                                                },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  product.name.substring(
                                                    0,
                                                    40
                                                  ) + "..."
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          !Array.isArray(product.gvCategory)
                                            ? product.gvCategory.name
                                            : "Aucune Catégorie"
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-inline-block align-middle lead-switch",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: product.active,
                                                expression: "product.active",
                                              },
                                            ],
                                            staticClass:
                                              "switch align-self-center is-rounded",
                                            attrs: {
                                              type: "checkbox",
                                              disabled:
                                                !_vm.has_right_to_update,
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                product.active
                                              )
                                                ? _vm._i(product.active, null) >
                                                  -1
                                                : product.active,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = product.active,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        product,
                                                        "active",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        product,
                                                        "active",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    product,
                                                    "active",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("label", {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: {
                                                  delay: { show: 400, hide: 0 },
                                                  content: product.active
                                                    ? "Actif"
                                                    : "Inactif",
                                                },
                                                expression:
                                                  "{\n                                                delay: { show: 400, hide: 0 },\n                                                content: product.active ? 'Actif' : 'Inactif',\n                                            }",
                                              },
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.editActiveProduct(
                                                  product
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    product.type === "amount"
                                      ? _c("td", [
                                          _vm._v("Montant personnalisé"),
                                        ])
                                      : _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatCurrency(product.price)
                                            )
                                          ),
                                        ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(product.validity) + " jours"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          product.services.length ===
                                            _vm.nb_services
                                            ? "Tous"
                                            : "Limité"
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: {
                                                delay: { show: 400, hide: 0 },
                                                content: "Éditer",
                                              },
                                              expression:
                                                "{ delay: { show: 400, hide: 0 }, content: 'Éditer' }",
                                            },
                                          ],
                                          staticClass:
                                            "btn btn-sm btn-success btn-square",
                                          attrs: {
                                            disabled: !_vm.has_right_to_update,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editProduct(product)
                                            },
                                          },
                                        },
                                        [
                                          _c("feather", {
                                            attrs: { type: "edit" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: {
                                                delay: { show: 400, hide: 0 },
                                                content: "Dupliquer",
                                              },
                                              expression:
                                                "{ delay: { show: 400, hide: 0 }, content: 'Dupliquer' }",
                                            },
                                          ],
                                          staticClass:
                                            "btn btn-sm btn-success btn-square",
                                          attrs: {
                                            disabled: !_vm.has_right_to_create,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.duplicateProduct(
                                                product
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("feather", {
                                            attrs: { type: "copy" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: {
                                                delay: { show: 400, hide: 0 },
                                                content: _vm.$t(
                                                  "labels.form.actions.delete"
                                                ),
                                              },
                                              expression:
                                                "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $t('labels.form.actions.delete'),\n                                        }",
                                            },
                                          ],
                                          staticClass:
                                            "btn btn-sm btn-danger btn-square",
                                          attrs: {
                                            disabled: !_vm.has_right_to_delete,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteProduct(product)
                                            },
                                          },
                                        },
                                        [
                                          _c("feather", {
                                            attrs: { type: "trash-2" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.showAddGiftVoucherProductModal &&
                        (_vm.has_right_to_create || _vm.has_right_to_update)
                          ? _c("add-gift-voucher-product-modal", {
                              attrs: {
                                product: _vm.product,
                                duplicate: _vm.product.duplicate,
                              },
                              on: {
                                saved: function ($event) {
                                  return _vm.productSaved()
                                },
                                close: _vm.productSaved,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(
                        "Désolé, vous n'avez pas le droit d'accéder à cette page."
                      ),
                    ]),
              ]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.config && _vm.config.display_categories
      ? _c("div", { staticClass: "row m-0 mb-3" }, [
          _c("div", { staticClass: "col-12 text-muted" }, [
            _vm._v(
              "\n            Vous avez la possibilité d'ordonner vos produits en cliquant sur \"Catégories\" puis sur l'icone\n            "
            ),
            _c(
              "svg",
              {
                staticClass: "css-i6dzq1 feather",
                attrs: {
                  viewBox: "0 0 24 24",
                  width: "24",
                  height: "24",
                  stroke: "currentColor",
                  "stroke-width": "2",
                  fill: "none",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              },
              [
                _c("polyline", { attrs: { points: "9 5 12 2 15 5" } }),
                _vm._v(" "),
                _c("polyline", { attrs: { points: "15 19 12 22 9 19" } }),
                _vm._v(" "),
                _c("line", {
                  attrs: { x1: "12", y1: "2", x2: "12", y2: "22" },
                }),
              ]
            ),
            _vm._v(".\n        "),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }