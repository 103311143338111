<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.booking.reservations.details") }}</h5>
        <div slot="body">
            <show-reservation :reservation_id="reservation_id" :restaurant_id="restaurant_id" />
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.close") }}
            </button>
            <button
                :disabled="!has_right_to_update_reservation"
                v-if="showEditButton"
                class="btn btn-sm btn-success btn-circle"
                @click="$emit('resa-edit', { restaurant_id, reservation_id })">
                {{ $tl("labels.booking.reservations.actions.edit") }}
            </button>
        </div>
    </modal>
</template>

<script>
import ShowReservation from "../../reservations/ShowReservation";

export default {
    name: "ShowReservationModal",
    props: {
        reservation_id: {
            default: "",
        },
        restaurant_id: {
            default: "",
        },
        showEditButton: {
            default: true,
        },
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_reservation() {
            return this.rights.includes("booking.booking.update");
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        ShowReservation,
    },
};
</script>
