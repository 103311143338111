<template>
    <div class="lead-switch d-flex align-items-center">
        <slot name="pre-label"></slot>
        <input
            type="checkbox"
            class="switch d-none is-rounded"
            :id="inputId"
            :disabled="disabled"
            :checked="value"
            @change="$emit('input', $event.target.checked)" />
        <label :for="inputId" class="m-2" v-tooltip="tooltip"></label>
        <slot name="post-label"></slot>
    </div>
</template>

<script>
export default {
    name: "ToggleInput",
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        inputId: {
            type: String,
            required: false,
            default() {
                return Math.random().toString(20).substring(2);
            },
        },
        tooltip: {
            type: [Object, String],
            required: false,
            default: undefined,
        },
    },
};
</script>
