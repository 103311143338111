<template>
    <div class="dropdown" :class="{ 'text-center': isLoading }">
        <div v-if="isLoading" class="spinner-grow spinner-grow-sm text-info" role="status">
            <span class="sr-only">Chargement...</span>
        </div>
        <div v-else>
            <button
                class="btn btn-sm btn-outline-secondary btn-circle resa-status"
                :class="[getSaleStatusClass(saleStatusToDisplay), { 'dropdown-toggle': hasActions }]"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                {{ capitalize(getSaleStatusLabel(saleStatusToDisplay)) }}
            </button>

            <div v-if="hasActions" id="dropDownStatus" class="dropdown-menu shadow" aria-labelledby="dropdownMenuLink">
                <div v-for="action in actions" :key="action">
                    <button type="button" v-on:click.btn="changeStatus(action)" class="dropdown-item">
                        {{ capitalize(getSaleStatusLabelInfinitif(action)) }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import SaleStatusEnum from "../../mixins/enums/gift_vouchers/SaleStatusEnum";
import SaleTypesEnum from "../../mixins/enums/gift_vouchers/SaleTypesEnum";

export default {
    name: "GiftVoucherStatusComponent",
    props: {
        sale: {
            type: Object,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [SaleStatusEnum, SaleTypesEnum],
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        validStatusForExpired() {
            return [this.SALE_PAID.value, this.SALE_CAPTURED.value].includes(this.sale.status);
        },
        expireBeforeToday() {
            const expiresOn = moment(this.sale.expires_on).startOf("day");
            const now = moment().startOf("day");

            return expiresOn.isBefore(now);
        },
        hasExpired() {
            return !this.sale.used && this.expireBeforeToday && this.validStatusForExpired;
        },
        saleStatusToDisplay() {
            return this.hasExpired ? this.SALE_EXPIRED.value : this.sale.status;
        },
        canAttributeStatus() {
            return !this.sale.used && (this.sale.stripe_payment_intent_id !== null || this.sale.payplug_payment_id !== null);
        },
        actions() {
            const actions = [];

            const statuses = {};

            statuses[this.SALE_PENDING.value] = this.SALE_CANCELED.value;
            statuses[this.SALE_CAPTURED.value] = this.canAttributeStatus ? this.SALE_REFUND.value : this.SALE_CANCELED.value;

            if (this.sale.sale_type !== this.SALE_TYPE_IMPORTED.value && statuses[this.sale.status] !== undefined) {
                actions.push(statuses[this.sale.status]);
            }

            return actions;
        },
        hasActions() {
            return this.actions.length > 0 && this.editable;
        },
    },
    methods: {
        changeStatus(status) {
            const phrase = `Êtes vous certain de vouloir ${this.getSaleStatusLabelInfinitif(status).toLowerCase()} ce bon cadeau ?`;

            if (!confirm(phrase)) {
                return;
            }

            this.isLoading = true;
            this.httpPut(`/api/gift_vouchers/${this.$route.params.restaurant_id}/sales/${this.sale.id}/status`, { status: status })
                .then((response) => {
                    if (response !== false) {
                        this.sale.status = status;
                    }
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
