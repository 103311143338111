export default {
    data() {
        return {
            MENU_OPTION_MENU: {
                value: "menu",
            },
            MENU_OPTION_GENERAL: {
                value: "general",
            },
        };
    },
    methods: {
        getMenuOptionTypeLabel(value, restaurantId = undefined) {
            const found = this.ALL_MENU_OPTION_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") {
                return this.$tl(`labels.booking.menus.options.types.${value}`, restaurantId);
            }
            return value;
        },
    },
    computed: {
        ALL_MENU_OPTION_TYPES() {
            return [this.MENU_OPTION_MENU, this.MENU_OPTION_GENERAL];
        },
    },
};
