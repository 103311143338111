<template>
    <div class="pb-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Paiements</h5>
                </div>
            </div>
        </div>
        <div class="row m-0 pb-5">
            <div class="col-12" v-if="has_right_to_read">
                <gift-vouchers-action-bar
                    is-payment
                    :advanced-filters="advancedFilters"
                    @fetch-data="updateDataFromFilterEvent"
                    @export-payments="exportPayments"
                    @dl-payments="downloadPayments" />
                <LoaderComponent v-if="isLoading" />
                <ns-table
                    v-else
                    tableClass="table table-striped overflowx-auto table-responsive-xl"
                    :data-source="sales"
                    is-server-side
                    clickable
                    allow-paging
                    :pagination="pagination"
                    @update-data="updateDataFromTableEvent"
                    @row-selected="rowSelected"
                    :current-sort="queries.sort">
                    <template v-slot:head>
                        <ns-th field="gv_sales.numero" allow-sorting>N°</ns-th>
                        <ns-th field="gv_sales.amount" allow-sorting>Montant</ns-th>
                        <ns-th field="status">Statut</ns-th>
                        <ns-th field="gv_sales.client" allow-sorting>Client</ns-th>
                        <ns-th field="gv_sales.paid_at" allow-sorting>Date</ns-th>
                        <ns-th field="gv_sales.payment_type" allow-sorting><small>Moyen de</small> paiement</ns-th>
                        <ns-th v-if="!isTvaDisabled" field="gv_sales.tva.five_dot_five"><small>TVA</small> 5.5%</ns-th>
                        <ns-th v-if="!isTvaDisabled" field="gv_sales.tva.ten"><small>TVA</small> 10%</ns-th>
                        <ns-th v-if="!isTvaDisabled" field="gv_sales.tva.twenty"><small>TVA</small> 20%</ns-th>
                        <ns-th field="gv_sales.stripe_fees" allow-sorting>Frais</ns-th>
                        <ns-th>Actions</ns-th>
                    </template>
                    <template v-slot:body="{ data }">
                        <td>
                            {{ data.numero }}
                        </td>
                        <td>
                            {{ formatCurrency(data.expedition_fees !== null ? data.amount + data.expedition_fees : data.amount) }}
                            <feather v-if="data.is_group_payment" v-tooltip="getTooltip('Paiment de groupe')" type="users" class="ml-2" />
                        </td>
                        <td>
                            <gift-voucher-status-component :sale="data" :editable="has_right_to_update_sales" />
                        </td>
                        <td>
                            <inline-client v-if="data.client" :client="data.client" :module-enum="MODULE_TYPE_GIFT_VOUCHERS" />
                        </td>
                        <td>
                            {{ data.paid_at !== null ? formatDate(data.paid_at) : "--" }}
                        </td>
                        <td>
                            {{ getPaymentTypeLabel(data.payment_type) }}
                            <img
                                v-if="hasPaymentType(data)"
                                v-tooltip="getTooltip('Paiement en ligne')"
                                style="width: 14px; margin-left: 2px; margin-bottom: 2px"
                                :src="imageURL(data)" />
                        </td>
                        <td v-if="!isTvaDisabled">
                            {{ data.tva && data.tva.five_dot_five && data.status !== "refund" ? formatCurrency(data.tva.five_dot_five / 100) : "--" }}
                        </td>
                        <td v-if="!isTvaDisabled">
                            {{ data.tva && data.tva.ten && data.status !== "refund" ? formatCurrency(data.tva.ten / 100) : "--" }}
                        </td>
                        <td v-if="!isTvaDisabled">
                            {{ data.tva && data.tva.twenty && data.status !== "refund" ? formatCurrency(data.tva.twenty / 100) : "--" }}
                        </td>
                        <td>
                            <span v-if="data.card_system_type === CARD_PAYMENT_SYSTEM_PAYPLUG.value && data.payplug_payment_id">NC</span>
                            <span v-else>
                                {{ data.stripe_fees !== null ? formatCurrency(data.stripe_fees) : "--" }}
                            </span>
                        </td>
                        <td>
                            <button
                                class="btn btn-sm btn-outline-secondary btn-square"
                                :disabled="
                                    !has_right_to_update_sales ||
                                    !data.invoice ||
                                    (!data.stripe_payment_intent_id && !data.payplug_payment_id) ||
                                    !data.paid_at ||
                                    data.status == 'pending'
                                "
                                @click.prevent.stop="downloadInvoice(data.id)"
                                v-tooltip="getTooltip('Télécharger la facture')">
                                <feather type="download-cloud" />
                            </button>
                        </td>
                    </template>
                </ns-table>
            </div>
            <div v-else class="alert alert-danger">Désolé, vous n'avez pas le droit d'accéder à cette page.</div>
            <show-gift-voucher-modal
                v-if="canDisplayModal && has_right_to_read_sales"
                :sale_id="selectedSale.id"
                must-not-emit
                @close="canDisplayModal = false" />
        </div>
    </div>
</template>

<script>
import showGiftVoucherModal from "../../components/Modals/giftVouchers/showGiftVoucherModal.vue";
import LoaderComponent from "../../components/LoaderComponent.vue";
import GiftVoucherStatusComponent from "../../components/giftVouchers/GiftVoucherStatusComponent.vue";
import moment from "moment";
import CardPaymentSystemEnum from "../../mixins/enums/CardPaymentSystemEnum.js";
import PaymentTypesEnum from "../../mixins/enums/gift_vouchers/PaymentTypesEnum.js";
import NsTable from "../../components/Datatable/NsTable.vue";
import NsTh from "../../components/Datatable/NsTh.vue";
import GiftVouchersActionBar from "../../components/giftVouchers/GiftVouchersActionBar.vue";
import inlineClient from "../../components/Default/Clients/inlineClient.vue";
import { DateTime } from "luxon";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum";

export default {
    props: {
        user: {
            required: true,
        },
    },
    mixins: [ModuleTypesEnum, CardPaymentSystemEnum, PaymentTypesEnum],
    data() {
        return {
            isLoading: false,
            pagination: {
                count: 0,
                current_page: 1,
                per_page: 12,
                total: 0,
                total_pages: 1,
            },
            queries: {
                page: 1,
                per_page: 12,
                sort: {
                    field: null,
                    order: null,
                },
                filters: {
                    status: null,
                    payment_type: null,
                    from: null,
                    to: null,
                },
                search: "",
            },
            canDisplayModal: false,
            selectedSale: null,
            error: null,
            sales: [],
            moment,
        };
    },
    computed: {
        paymentTypes() {
            return this.PAYMENT_TYPES_FOR_FILTERING;
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        isTvaDisabled() {
            return this.$store.getters["restaurants/isTvaDisabled"](this.restaurantId);
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_sales() {
            return this.rights.includes("gift_vouchers.sales.update");
        },
        has_right_to_read_sales() {
            return this.rights.includes("gift_vouchers.sales.read");
        },
        has_right_to_read() {
            return this.rights.includes("gift_vouchers.payments.read");
        },
        has_right_to_export() {
            return this.rights.includes("gift_vouchers.payments.export");
        },
        advancedFilters() {
            return [
                {
                    title: "Statut",
                    defaultLabel: "--",
                    filter: "status",
                    options: [
                        {
                            label: "Payé",
                            value: "paid",
                        },
                        {
                            label: "Annulé",
                            value: "canceled",
                        },
                        {
                            label: "Remboursé",
                            value: "refund",
                        },
                        {
                            label: "En attente",
                            value: "pending",
                        },
                    ],
                },
                {
                    title: "Moyen de paiement",
                    defaultLabel: "--",
                    filter: "payment_type",
                    options: this.PAYMENT_TYPES_FOR_FILTERING,
                },
            ];
        },
    },
    methods: {
        hasPaymentType(data) {
            const cardPaymentSystem = {};

            cardPaymentSystem[this.CARD_PAYMENT_SYSTEM_PAYPLUG.value] = "payplug_payment_id";
            cardPaymentSystem[this.CARD_PAYMENT_SYSTEM_STRIPE.value] = "stripe_payment_intent_id";

            return data[cardPaymentSystem[data.card_system_type]] !== null;
        },
        imageURL(data) {
            const cardPaymentSystem = {};

            cardPaymentSystem[this.CARD_PAYMENT_SYSTEM_PAYPLUG.value] = "/images/PayPlug-logo-small.png";
            cardPaymentSystem[this.CARD_PAYMENT_SYSTEM_STRIPE.value] = "/images/Stripe-no-pad.png";
            return cardPaymentSystem[data.card_system_type];
        },
        formatDate(date) {
            return DateTime.fromSQL(date).toLocaleString();
        },
        rowSelected({ event, data }) {
            const tagsToExclude = ["button", "a", "svg", "rect", "path", "line"];
            const targetTagName = event && event.target && event.target.tagName ? event.target.tagName.toLowerCase() : "";

            if (!tagsToExclude.includes(targetTagName)) {
                event.stopPropagation();
                event.preventDefault();
                this.selectedSale = data;
                this.canDisplayModal = true;
            }
        },
        updateDataFromTableEvent(event) {
            this.queries.sort.field = null;
            this.queries.sort.order = null;

            this.queries.page = event.page;
            this.queries.per_page = event.per_page;
            if (event.field !== null && event.order !== null) {
                this.queries.sort.field = event.field;
                this.queries.sort.order = event.order;
            }
            this.getSales();
        },
        updateDataFromFilterEvent(event) {
            this.queries.filters = { ...event.filters };
            this.queries.search = event.search;
            this.queries.page = 1;
            this.getSales();
        },
        downloadInvoice(saleId) {
            window.open(`/api/gift_vouchers/${this.restaurantId}/sales/${saleId}/pdf`, "_blank");
        },
        exportPayments() {
            if (!confirm("L'exportation des paiements prendra en compte les filtres sélectionnés. Souhaitez vous continuer ?")) {
                return;
            }

            const url = `/api/gift_vouchers/${this.restaurantId}/export/payments?${this.parseQueries()}`;

            if (this.pagination.total <= 100) {
                window.open(url, "_blank");
            } else {
                this.httpGet(url, { handleResolve: true });
            }
        },
        downloadPayments() {
            if (!confirm("L'exportation des paiements prendra en compte les filtres sélectionnés. Souhaitez vous continuer ?")) {
                return;
            }

            const url = `/api/gift_vouchers/${this.restaurantId}/export/payments/bill?${this.parseQueries()}`;

            this.httpGet(url, { handleResolve: true });
        },
        getSales() {
            this.isLoading = true;
            const includes =
                "?include=mail_logs,logs.user,user,tva,client,gv_product,gv_sale_product_options,gv_sale_product_options.gv_products_option";

            const queries = this.parseQueries();

            this.httpGet(`/api/gift_vouchers/restaurants/${this.restaurantId}/sales/payments${includes}${queries}`)
                .then((response) => {
                    this.sales = response.data.data;
                    this.pagination = response.data.meta.pagination;
                    if (this.pagination.per_page === this.pagination.total) {
                        this.pagination.per_page = "all";
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        parseQueries() {
            const filters = this.parseQueryFilters();
            const search = this.parseSearch();
            const sort = this.parseQuerySort();
            const page = this.parseQueryPage();

            return filters + search + sort + page;
        },
        parseQueryFilters() {
            let queryFilters = "";

            Object.keys(this.queries.filters).forEach((key) => {
                if (this.queries.filters[key] !== null) {
                    const value = this.queries.filters[key] instanceof DateTime ? this.queries.filters[key].toISODate() : this.queries.filters[key];
                    queryFilters = queryFilters.concat("&", `${key}=${value}`);
                }
            });
            return queryFilters;
        },
        parseSearch() {
            if (this.queries.search !== "" && this.queries.search !== null) {
                return "&".concat("search=", this.queries.search);
            }
            return "";
        },
        parseQuerySort() {
            if (this.queries.sort.field !== null && this.queries.sort.order !== null) {
                return "&".concat("sort=", this.queries.sort.field) + "&".concat("order=", this.queries.sort.order);
            }
            return "";
        },
        parseQueryPage() {
            return "&".concat("page=", this.queries.page) + "&".concat("per_page=", this.queries.per_page);
        },
    },
    components: {
        showGiftVoucherModal,
        LoaderComponent,
        GiftVoucherStatusComponent,
        GiftVouchersActionBar,
        NsTable,
        NsTh,
        inlineClient,
    },
    created() {
        if (this.has_right_to_read) {
            this.getSales();
        }
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
