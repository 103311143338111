<template>
    <div class="pb-5 pt-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Retrait</h5>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div v-show="!loading" class="row m-0">
            <div class="alert alert-danger" v-if="error">{{ error }}</div>
            <div class="col-12">
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-md-5">
                                    <strong>Créneaux de retrait</strong>
                                </div>
                                <div class="col-md-7 pt-1">
                                    <div class="d-inline-block align-middle lead-switch">
                                        <input
                                            :disabled="!has_right_to_update_config"
                                            type="checkbox"
                                            class="switch align-self-center is-rounded"
                                            v-model="pickunEnable" />
                                        <label @click="pickunEnable = has_right_to_update_config ? !pickunEnable : pickunEnable"></label>
                                    </div>
                                </div>
                            </div>
                            <template v-if="pickunEnable">
                                <div class="row mt-3 mb-2">
                                    <div class="col-12 pt-2">
                                        <div class="mb-2">
                                            <label class="container-box" style="margin-bottom: 0; width: 100%">
                                                <input
                                                    type="checkbox"
                                                    :checked="services.lundi.isOpen"
                                                    :disabled="!has_right_to_update_config"
                                                    @input="setDayIsOpen(services.lundi, $event)" />
                                                <span class="checkmark" :class="{ disabled: !has_right_to_update_config }"></span>
                                                Lundi
                                            </label>
                                        </div>
                                        <div class="setting-open" v-if="services.lundi.isOpen == true">
                                            <div class="setting-hours">
                                                <span>
                                                    <template v-for="(service, index) in services.lundi.services">
                                                        <div v-if="!service.deleted" :key="index">
                                                            <vue-timepicker
                                                                :disabled="!has_right_to_update_config"
                                                                format="HH:mm"
                                                                :minute-interval="15"
                                                                :value="service.start_hour"
                                                                @input="calculSlot('lundi', index, $event)"
                                                                @close="timePickerClosed('lundi')"></vue-timepicker>
                                                            <vue-timepicker
                                                                :disabled="!has_right_to_update_config"
                                                                format="HH:mm"
                                                                :minute-interval="15"
                                                                :value="service.end_hour"
                                                                :hour-range="calculHourRange(service.start_hour)"
                                                                hide-disabled-hours
                                                                @input="calculSlot('lundi', index, null, $event)"></vue-timepicker>
                                                            <template v-for="(slot, index) in service.slots">
                                                                <label
                                                                    class="container-box ml-3"
                                                                    style="width: initial; margin-right: 0; padding-left: 28px"
                                                                    v-if="slot.displayed"
                                                                    v-bind:key="index">
                                                                    <input
                                                                        type="checkbox"
                                                                        v-model="slot.enabled"
                                                                        :disabled="!has_right_to_update_config" />
                                                                    <span
                                                                        class="checkmark"
                                                                        :class="{
                                                                            disabled: !has_right_to_update_config,
                                                                        }"></span>
                                                                    {{ formatHour(slot.hour) }}
                                                                </label>
                                                            </template>
                                                            <svg
                                                                @click="removeOpening('lundi', index)"
                                                                style="color: red"
                                                                viewBox="0 0 24 24"
                                                                width="24"
                                                                height="24"
                                                                stroke="currentColor"
                                                                stroke-width="2"
                                                                fill="none"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather css-i6dzq1 pt-1">
                                                                <line x1="18" y1="6" x2="6" y2="18" />
                                                                <line x1="6" y1="6" x2="18" y2="18" />
                                                            </svg>
                                                        </div>
                                                    </template>
                                                    <svg
                                                        @click="addOpening('lundi')"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        aria-hidden="true"
                                                        class="feather feather-plus sc-dnqmqq jxshSx">
                                                        <line x1="12" y1="5" x2="12" y2="19" />
                                                        <line x1="5" y1="12" x2="19" y2="12" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <label class="container-box" style="margin-bottom: 0; width: 100%">
                                                <input
                                                    type="checkbox"
                                                    :disabled="!has_right_to_update_config"
                                                    :checked="services.mardi.isOpen"
                                                    @input="setDayIsOpen(services.mardi, $event)" />
                                                <span class="checkmark" :class="{ disabled: !has_right_to_update_config }"></span>
                                                Mardi
                                            </label>
                                        </div>
                                        <div class="setting-open" v-if="services.mardi.isOpen == true">
                                            <div class="setting-hours">
                                                <span>
                                                    <template v-for="(service, index) in services.mardi.services">
                                                        <div v-if="!service.deleted" v-bind:key="index">
                                                            <vue-timepicker
                                                                :disabled="!has_right_to_update_config"
                                                                format="HH:mm"
                                                                @input="calculSlot('mardi', index, $event)"
                                                                :minute-interval="15"
                                                                :value="service.start_hour"
                                                                @close="timePickerClosed('mardi')"></vue-timepicker>
                                                            <vue-timepicker
                                                                :disabled="!has_right_to_update_config"
                                                                format="HH:mm"
                                                                :minute-interval="15"
                                                                @input="calculSlot('mardi', index, null, $event)"
                                                                :hour-range="calculHourRange(service.start_hour)"
                                                                hide-disabled-hours
                                                                :value="service.end_hour"></vue-timepicker>
                                                            <template v-for="(slot, index) in service.slots">
                                                                <label
                                                                    class="container-box ml-3"
                                                                    style="width: initial; margin-right: 0; padding-left: 28px"
                                                                    v-if="slot.displayed"
                                                                    v-bind:key="index">
                                                                    <input
                                                                        :disabled="!has_right_to_update_config"
                                                                        type="checkbox"
                                                                        v-model="slot.enabled" />
                                                                    <span
                                                                        class="checkmark"
                                                                        :class="{
                                                                            disabled: !has_right_to_update_config,
                                                                        }"></span>
                                                                    {{ formatHour(slot.hour) }}
                                                                </label>
                                                            </template>
                                                            <svg
                                                                @click="removeOpening('mardi', index)"
                                                                style="color: red"
                                                                viewBox="0 0 24 24"
                                                                width="24"
                                                                height="24"
                                                                stroke="currentColor"
                                                                stroke-width="2"
                                                                fill="none"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather css-i6dzq1 pt-1">
                                                                <line x1="18" y1="6" x2="6" y2="18" />
                                                                <line x1="6" y1="6" x2="18" y2="18" />
                                                            </svg>
                                                        </div>
                                                    </template>
                                                    <svg
                                                        @click="addOpening('mardi')"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        aria-hidden="true"
                                                        class="feather feather-plus sc-dnqmqq jxshSx">
                                                        <line x1="12" y1="5" x2="12" y2="19" />
                                                        <line x1="5" y1="12" x2="19" y2="12" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <label class="container-box" style="margin-bottom: 0; width: 100%">
                                                <input
                                                    :disabled="!has_right_to_update_config"
                                                    type="checkbox"
                                                    :checked="services.mercredi.isOpen"
                                                    @input="setDayIsOpen(services.mercredi, $event)" />
                                                <span class="checkmark" :class="{ disabled: !has_right_to_update_config }"></span>
                                                Mercredi
                                            </label>
                                        </div>
                                        <div class="setting-open" v-if="services.mercredi.isOpen == true">
                                            <div class="setting-hours">
                                                <span>
                                                    <template v-for="(service, index) in services.mercredi.services">
                                                        <div v-if="!service.deleted" v-bind:key="index">
                                                            <vue-timepicker
                                                                :disabled="!has_right_to_update_config"
                                                                format="HH:mm"
                                                                :minute-interval="15"
                                                                @input="calculSlot('mercredi', index, $event)"
                                                                :value="service.start_hour"
                                                                @close="timePickerClosed('mercredi')"></vue-timepicker>
                                                            <vue-timepicker
                                                                :disabled="!has_right_to_update_config"
                                                                format="HH:mm"
                                                                @input="calculSlot('mercredi', index, null, $event)"
                                                                :minute-interval="15"
                                                                :hour-range="calculHourRange(service.start_hour)"
                                                                hide-disabled-hours
                                                                :value="service.end_hour"></vue-timepicker>
                                                            <template v-for="(slot, index) in service.slots">
                                                                <label
                                                                    class="container-box ml-3"
                                                                    style="width: initial; margin-right: 0; padding-left: 28px"
                                                                    v-if="slot.displayed"
                                                                    v-bind:key="index">
                                                                    <input
                                                                        :disabled="!has_right_to_update_config"
                                                                        type="checkbox"
                                                                        v-model="slot.enabled" />
                                                                    <span
                                                                        class="checkmark"
                                                                        :class="{
                                                                            disabled: !has_right_to_update_config,
                                                                        }"></span>
                                                                    {{ formatHour(slot.hour) }}
                                                                </label>
                                                            </template>
                                                            <svg
                                                                @click="removeOpening('mercredi', index)"
                                                                style="color: red"
                                                                viewBox="0 0 24 24"
                                                                width="24"
                                                                height="24"
                                                                stroke="currentColor"
                                                                stroke-width="2"
                                                                fill="none"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather css-i6dzq1 pt-1">
                                                                <line x1="18" y1="6" x2="6" y2="18" />
                                                                <line x1="6" y1="6" x2="18" y2="18" />
                                                            </svg>
                                                        </div>
                                                    </template>
                                                    <svg
                                                        @click="addOpening('mercredi')"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        aria-hidden="true"
                                                        class="feather feather-plus sc-dnqmqq jxshSx">
                                                        <line x1="12" y1="5" x2="12" y2="19" />
                                                        <line x1="5" y1="12" x2="19" y2="12" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <label class="container-box" style="margin-bottom: 0; width: 100%">
                                                <input
                                                    type="checkbox"
                                                    :disabled="!has_right_to_update_config"
                                                    :checked="services.jeudi.isOpen"
                                                    @input="setDayIsOpen(services.jeudi, $event)" />
                                                <span class="checkmark" :class="{ disabled: !has_right_to_update_config }"></span>
                                                Jeudi
                                            </label>
                                        </div>
                                        <div class="setting-open" v-if="services.jeudi.isOpen == true">
                                            <div class="setting-hours">
                                                <span>
                                                    <template v-for="(service, index) in services.jeudi.services">
                                                        <div v-if="!service.deleted" v-bind:key="index">
                                                            <vue-timepicker
                                                                :disabled="!has_right_to_update_config"
                                                                format="HH:mm"
                                                                :minute-interval="15"
                                                                @input="calculSlot('jeudi', index, $event)"
                                                                :value="service.start_hour"
                                                                @close="timePickerClosed('jeudi')"></vue-timepicker>
                                                            <vue-timepicker
                                                                :disabled="!has_right_to_update_config"
                                                                format="HH:mm"
                                                                @input="calculSlot('jeudi', index, null, $event)"
                                                                :minute-interval="15"
                                                                :hour-range="calculHourRange(service.start_hour)"
                                                                hide-disabled-hours
                                                                :value="service.end_hour"></vue-timepicker>
                                                            <template v-for="(slot, index) in service.slots">
                                                                <label
                                                                    class="container-box ml-3"
                                                                    style="width: initial; margin-right: 0; padding-left: 28px"
                                                                    v-if="slot.displayed"
                                                                    v-bind:key="index">
                                                                    <input
                                                                        :disabled="!has_right_to_update_config"
                                                                        type="checkbox"
                                                                        v-model="slot.enabled" />
                                                                    <span
                                                                        class="checkmark"
                                                                        :class="{
                                                                            disabled: !has_right_to_update_config,
                                                                        }"></span>
                                                                    {{ formatHour(slot.hour) }}
                                                                </label>
                                                            </template>
                                                            <svg
                                                                @click="removeOpening('jeudi', index)"
                                                                style="color: red"
                                                                viewBox="0 0 24 24"
                                                                width="24"
                                                                height="24"
                                                                stroke="currentColor"
                                                                stroke-width="2"
                                                                fill="none"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather css-i6dzq1 pt-1">
                                                                <line x1="18" y1="6" x2="6" y2="18" />
                                                                <line x1="6" y1="6" x2="18" y2="18" />
                                                            </svg>
                                                        </div>
                                                    </template>
                                                    <svg
                                                        @click="addOpening('jeudi')"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        aria-hidden="true"
                                                        class="feather feather-plus sc-dnqmqq jxshSx">
                                                        <line x1="12" y1="5" x2="12" y2="19" />
                                                        <line x1="5" y1="12" x2="19" y2="12" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <label class="container-box" style="margin-bottom: 0; width: 100%">
                                                <input
                                                    type="checkbox"
                                                    :disabled="!has_right_to_update_config"
                                                    :checked="services.vendredi.isOpen"
                                                    @input="setDayIsOpen(services.vendredi, $event)" />
                                                <span class="checkmark" :class="{ disabled: !has_right_to_update_config }"></span>
                                                Vendredi
                                            </label>
                                        </div>
                                        <div class="setting-open" v-if="services.vendredi.isOpen == true">
                                            <div class="setting-hours">
                                                <span>
                                                    <template v-for="(service, index) in services.vendredi.services">
                                                        <div v-if="!service.deleted" v-bind:key="index">
                                                            <vue-timepicker
                                                                :disabled="!has_right_to_update_config"
                                                                format="HH:mm"
                                                                :minute-interval="15"
                                                                @input="calculSlot('vendredi', index, $event)"
                                                                :value="service.start_hour"
                                                                @close="timePickerClosed('vendredi')"></vue-timepicker>
                                                            <vue-timepicker
                                                                :disabled="!has_right_to_update_config"
                                                                format="HH:mm"
                                                                @input="calculSlot('vendredi', index, null, $event)"
                                                                :minute-interval="15"
                                                                :hour-range="calculHourRange(service.start_hour)"
                                                                hide-disabled-hours
                                                                :value="service.end_hour"></vue-timepicker>
                                                            <template v-for="(slot, index) in service.slots">
                                                                <label
                                                                    class="container-box ml-3"
                                                                    style="width: initial; margin-right: 0; padding-left: 28px"
                                                                    v-if="slot.displayed"
                                                                    v-bind:key="index">
                                                                    <input
                                                                        :disabled="!has_right_to_update_config"
                                                                        type="checkbox"
                                                                        v-model="slot.enabled" />
                                                                    <span
                                                                        class="checkmark"
                                                                        :class="{
                                                                            disabled: !has_right_to_update_config,
                                                                        }"></span>
                                                                    {{ formatHour(slot.hour) }}
                                                                </label>
                                                            </template>
                                                            <svg
                                                                @click="removeOpening('vendredi', index)"
                                                                style="color: red"
                                                                viewBox="0 0 24 24"
                                                                width="24"
                                                                height="24"
                                                                stroke="currentColor"
                                                                stroke-width="2"
                                                                fill="none"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather css-i6dzq1 pt-1">
                                                                <line x1="18" y1="6" x2="6" y2="18" />
                                                                <line x1="6" y1="6" x2="18" y2="18" />
                                                            </svg>
                                                        </div>
                                                    </template>
                                                    <svg
                                                        @click="addOpening('vendredi')"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        aria-hidden="true"
                                                        class="feather feather-plus sc-dnqmqq jxshSx">
                                                        <line x1="12" y1="5" x2="12" y2="19" />
                                                        <line x1="5" y1="12" x2="19" y2="12" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <label class="container-box" style="margin-bottom: 0; width: 100%">
                                                <input
                                                    type="checkbox"
                                                    :disabled="!has_right_to_update_config"
                                                    :checked="services.samedi.isOpen"
                                                    @input="setDayIsOpen(services.samedi, $event)" />
                                                <span class="checkmark" :class="{ disabled: !has_right_to_update_config }"></span>
                                                Samedi
                                            </label>
                                        </div>
                                        <div class="setting-open" v-if="services.samedi.isOpen == true">
                                            <div class="setting-hours">
                                                <span>
                                                    <template v-for="(service, index) in services.samedi.services">
                                                        <div v-if="!service.deleted" v-bind:key="index">
                                                            <vue-timepicker
                                                                :disabled="!has_right_to_update_config"
                                                                format="HH:mm"
                                                                :minute-interval="15"
                                                                @input="calculSlot('samedi', index, $event)"
                                                                :value="service.start_hour"
                                                                @close="timePickerClosed('samedi')"></vue-timepicker>
                                                            <vue-timepicker
                                                                :disabled="!has_right_to_update_config"
                                                                format="HH:mm"
                                                                @input="calculSlot('samedi', index, null, $event)"
                                                                :hour-range="calculHourRange(service.start_hour)"
                                                                hide-disabled-hours
                                                                :minute-interval="15"
                                                                :value="service.end_hour"></vue-timepicker>
                                                            <template v-for="(slot, index) in service.slots">
                                                                <label
                                                                    class="container-box ml-3"
                                                                    style="width: initial; margin-right: 0; padding-left: 28px"
                                                                    v-if="slot.displayed"
                                                                    v-bind:key="index">
                                                                    <input
                                                                        :disabled="!has_right_to_update_config"
                                                                        type="checkbox"
                                                                        v-model="slot.enabled" />
                                                                    <span
                                                                        class="checkmark"
                                                                        :class="{
                                                                            disabled: !has_right_to_update_config,
                                                                        }"></span>
                                                                    {{ formatHour(slot.hour) }}
                                                                </label>
                                                            </template>
                                                            <svg
                                                                @click="removeOpening('samedi', index)"
                                                                style="color: red"
                                                                viewBox="0 0 24 24"
                                                                width="24"
                                                                height="24"
                                                                stroke="currentColor"
                                                                stroke-width="2"
                                                                fill="none"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather css-i6dzq1 pt-1">
                                                                <line x1="18" y1="6" x2="6" y2="18" />
                                                                <line x1="6" y1="6" x2="18" y2="18" />
                                                            </svg>
                                                        </div>
                                                    </template>
                                                    <svg
                                                        @click="addOpening('samedi')"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        aria-hidden="true"
                                                        class="feather feather-plus sc-dnqmqq jxshSx">
                                                        <line x1="12" y1="5" x2="12" y2="19" />
                                                        <line x1="5" y1="12" x2="19" y2="12" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <label class="container-box" style="margin-bottom: 0; width: 100%">
                                                <input
                                                    type="checkbox"
                                                    :disabled="!has_right_to_update_config"
                                                    :checked="services.dimanche.isOpen"
                                                    @input="setDayIsOpen(services.dimanche, $event)" />
                                                <span class="checkmark" :class="{ disabled: !has_right_to_update_config }"></span>
                                                Dimanche
                                            </label>
                                        </div>
                                        <div class="setting-open" v-if="services.dimanche.isOpen == true">
                                            <div class="setting-hours">
                                                <span>
                                                    <template v-for="(service, index) in services.dimanche.services">
                                                        <div v-if="!service.deleted" v-bind:key="index">
                                                            <vue-timepicker
                                                                :disabled="!has_right_to_update_config"
                                                                format="HH:mm"
                                                                :minute-interval="15"
                                                                @input="calculSlot('dimanche', index, $event)"
                                                                :value="service.start_hour"
                                                                @close="timePickerClosed('dimanche')"></vue-timepicker>
                                                            <vue-timepicker
                                                                :disabled="!has_right_to_update_config"
                                                                format="HH:mm"
                                                                @input="calculSlot('dimanche', index, null, $event)"
                                                                :hour-range="calculHourRange(service.start_hour)"
                                                                hide-disabled-hours
                                                                :minute-interval="15"
                                                                :value="service.end_hour"></vue-timepicker>
                                                            <template v-for="(slot, index) in service.slots">
                                                                <label
                                                                    class="container-box ml-3"
                                                                    style="width: initial; margin-right: 0; padding-left: 28px"
                                                                    v-if="slot.displayed"
                                                                    v-bind:key="index">
                                                                    <input
                                                                        :disabled="!has_right_to_update_config"
                                                                        type="checkbox"
                                                                        v-model="slot.enabled" />
                                                                    <span
                                                                        class="checkmark"
                                                                        :class="{
                                                                            disabled: !has_right_to_update_config,
                                                                        }"></span>
                                                                    {{ formatHour(slot.hour) }}
                                                                </label>
                                                            </template>
                                                            <svg
                                                                @click="removeOpening('dimanche', index)"
                                                                style="color: red"
                                                                viewBox="0 0 24 24"
                                                                width="24"
                                                                height="24"
                                                                stroke="currentColor"
                                                                stroke-width="2"
                                                                fill="none"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather css-i6dzq1 pt-1">
                                                                <line x1="18" y1="6" x2="6" y2="18" />
                                                                <line x1="6" y1="6" x2="18" y2="18" />
                                                            </svg>
                                                        </div>
                                                    </template>
                                                    <svg
                                                        @click="addOpening('dimanche')"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        aria-hidden="true"
                                                        class="feather feather-plus sc-dnqmqq jxshSx">
                                                        <line x1="12" y1="5" x2="12" y2="19" />
                                                        <line x1="5" y1="12" x2="19" y2="12" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <show-errors :errors="errors" errorKey="open_days" />
                        </div>
                    </div>
                </div>
                <template v-if="pickunEnable">
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <div class="row">
                                    <div class="col-12">
                                        <strong>Paramètres généraux</strong>
                                    </div>
                                </div>
                                <div class="row mt-3 mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>
                                            Délai de préparation de commande <small>*</small>
                                            <feather
                                                v-tooltip="
                                                    getTooltipNoticeLexicon('notices.clickAndCollect.config.orderPreparationDelay', restaurant_id)
                                                "
                                                type="info"
                                                class="ml-1 pointer tooltip-infos" />
                                        </label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <input
                                                    :disabled="!has_right_to_update_config"
                                                    type="number"
                                                    class="form-control"
                                                    min="1"
                                                    v-model="preparation_time" />
                                            </div>
                                            <div class="col-md-8">
                                                <select
                                                    :disabled="!has_right_to_update_config"
                                                    v-model="preparation_time_format"
                                                    class="custom-select mb-0"
                                                    style="width: initial">
                                                    <option>minutes</option>
                                                    <option>heures</option>
                                                    <option>jours</option>
                                                </select>
                                            </div>
                                        </div>
                                        <show-errors :errors="errors" errorKey="preparation_time" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label> Période d'ouverture des commandes <small>*</small> </label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <input
                                                    :disabled="!has_right_to_update_config"
                                                    type="number"
                                                    min="0"
                                                    class="form-control"
                                                    v-model="open_period_days" />
                                            </div>
                                            <div class="col-md-8 mt-1">jours</div>
                                        </div>
                                        <show-errors :errors="errors" errorKey="opening_period" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>
                                            {{ $tl("labels.clickAndCollect.config.nbOrderMax", restaurant_id) }}
                                            <small>*</small>
                                        </label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <input
                                                    :disabled="serviceMaxOrder || !has_right_to_update_config"
                                                    min="1"
                                                    type="number"
                                                    class="form-control"
                                                    v-model="cmd_per_service" />
                                            </div>
                                            <div class="col-md-8 mt-1">
                                                <label class="container-box" style="width: initial">
                                                    <input :disabled="!has_right_to_update_config" type="checkbox" v-model="serviceMaxOrder" />
                                                    <span class="checkmark" :class="{ disabled: !has_right_to_update_config }"></span>Illimité
                                                </label>
                                            </div>
                                        </div>
                                        <show-errors :errors="errors" errorKey="orders_max_per_service" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>
                                            Nombre de commandes maximum par créneau de retrait
                                            <small>*</small>
                                        </label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <input
                                                    :disabled="slotMaxOrder || !has_right_to_update_config"
                                                    type="number"
                                                    min="1"
                                                    class="form-control"
                                                    v-model="cmd_per_slot" />
                                            </div>
                                            <div class="col-md-8 mt-1">
                                                <label class="container-box" style="width: initial">
                                                    <input :disabled="!has_right_to_update_config" type="checkbox" v-model="slotMaxOrder" />
                                                    <span class="checkmark" :class="{ disabled: !has_right_to_update_config }"></span>
                                                    Illimité
                                                </label>
                                            </div>
                                        </div>
                                        <show-errors :errors="errors" errorKey="orders_max_per_slot" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>{{ $tl("labels.clickAndCollect.config.pendingOrder", restaurant_id) }}</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="disable_live_commands" />
                                            <label
                                                @click="
                                                    disable_live_commands = has_right_to_update_config
                                                        ? !disable_live_commands
                                                        : disable_live_commands
                                                "></label>
                                        </div>
                                    </div>
                                    <show-errors :errors="errors" errorKey="show_out_of_stock_products" />
                                </div>
                                <div class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label>
                                            Confirmation des commandes <small>*</small>
                                            <feather
                                                v-tooltip="getTooltipNoticeLexicon('notices.clickAndCollect.config.commandConfirm', restaurant_id)"
                                                type="info"
                                                class="ml-1 pointer tooltip-infos" />
                                        </label>
                                    </div>
                                    <div class="col-7 radio w-100 h-100 d-table pt-2">
                                        <div class="d-table-cell align-middle">
                                            <label class="container-box">
                                                <input
                                                    type="radio"
                                                    :disabled="!has_right_to_update_config"
                                                    v-model="autoconfirm.autoconfirm"
                                                    :value="0" />
                                                <span class="checkmark"></span> Manuelle
                                            </label>
                                            <label class="container-box">
                                                <input
                                                    :disabled="!has_right_to_update_config"
                                                    type="radio"
                                                    v-model="autoconfirm.autoconfirm"
                                                    :value="1" />
                                                <span class="checkmark"></span> Automatique
                                            </label>
                                        </div>
                                        <show-errors class="d-block" :errors="errors" errorKey="autoconfirm" />
                                    </div>
                                </div>
                                <template v-if="autoconfirm.autoconfirm">
                                    <div class="row mb-2">
                                        <div class="col-4 pt-2 offset-1">
                                            <label> Jusqu'à </label>
                                        </div>
                                        <div class="col-7">
                                            <div class="row">
                                                <div class="col-4">
                                                    <input
                                                        type="number"
                                                        v-model="autoconfirm.autoconfirm_command"
                                                        class="form-control"
                                                        :disabled="!has_right_to_update_config" />
                                                </div>
                                                <div class="col-8 mt-2">
                                                    {{ $tl("labels.clickAndCollect.config.orderByServices", restaurant_id)
                                                    }}<small> - 0 pour ne pas bloquer</small>
                                                </div>
                                                <show-errors class="col-12" :errors="errors" errorKey="autoconfirm_command" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-4 pt-2 offset-1">
                                            <label> Jusqu'à </label>
                                        </div>
                                        <div class="col-7">
                                            <div class="row">
                                                <div class="col-4">
                                                    <input
                                                        type="number"
                                                        v-model="autoconfirm.autoconfirm_until"
                                                        class="form-control"
                                                        :disabled="!has_right_to_update_config" />
                                                </div>
                                                <div class="col-8 mt-2">
                                                    {{ $tl("labels.clickAndCollect.config.beforeStarting", restaurant_id)
                                                    }}<small> - 0 pour ne pas bloquer</small>
                                                </div>
                                                <show-errors class="col-12" :errors="errors" errorKey="autoconfirm_until" />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Afficher les produits en rupture</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="show_product_out" />
                                            <label
                                                @click="show_product_out = has_right_to_update_config ? !show_product_out : show_product_out"></label>
                                        </div>
                                    </div>
                                    <show-errors :errors="errors" errorKey="show_out_of_stock_products" />
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Afficher les produits inactifs</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="show_product_disable" />
                                            <label
                                                @click="
                                                    show_product_disable = has_right_to_update_config ? !show_product_disable : show_product_disable
                                                "></label>
                                        </div>
                                    </div>
                                    <show-errors :errors="errors" errorKey="show_inactive_products" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <div class="row">
                                    <div class="col-12">
                                        <strong>Paiements</strong>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-5 pt-1">
                                        <label>Autoriser les paiements de groupe</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="group_payments" />
                                            <label @click="group_payments = has_right_to_update_config ? !group_payments : group_payments"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="hasMealVouchersEnabled && isNoShow">
                                    <div class="col-md-5 pt-1">
                                        <label>Autoriser les paiements en titres-restaurant</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="collect_mealvouchers_enabled" />
                                            <label
                                                @click="
                                                    collect_mealvouchers_enabled = has_right_to_update_config
                                                        ? !collect_mealvouchers_enabled
                                                        : collect_mealvouchers_enabled
                                                "></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5 pt-1">
                                        <label>Liaison au module Bons Cadeaux</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config || !hasGvSubscription"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="collect_connect_to_gv" />
                                            <label
                                                @click="
                                                    collect_connect_to_gv =
                                                        has_right_to_update_config && hasGvSubscription
                                                            ? !collect_connect_to_gv
                                                            : collect_connect_to_gv
                                                "></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <div class="row">
                                    <div class="col-12">
                                        <strong>Conditions de retrait</strong>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-5 pt-1">
                                        <label>Informations relatives aux conditions de retrait</label>
                                    </div>
                                    <div class="col-md-7">
                                        <textarea
                                            :disabled="!has_right_to_update_config"
                                            class="form-control"
                                            maxlength="190"
                                            rows="3"
                                            v-model="legal_information"></textarea>
                                        <small class="text-muted">Max : 190 caractères</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <automatic-export-component
                        v-model="automaticExport"
                        :module="MODULE_TYPE_CLICK_AND_COLLECT.value"
                        services-name="collect_services"
                        :can-fetch-services="canFetchServices"
                        @services-fetched="canFetchServices = false"
                        class="row mb-3" />
                    <editClickAndCollectNotifications
                        class="mt-2"
                        ref="editClickAndCollectNotifications"
                        :show-save-button="false"
                        :notifications-data="notificationsData"
                        :collect-type="COLLECT_TYPE_COLLECT.value" />
                </template>
                <div class="row">
                    <div class="col-12">
                        <input
                            @click="post()"
                            type="submit"
                            name="submit"
                            value="Enregistrer"
                            class="btn btn-success btn-circle"
                            :class="{ disabled: loading }"
                            :disabled="loading || !has_right_to_update_config" />
                    </div>
                </div>
            </div>
        </div>
        <alert-slot-deletion-modal
            v-if="showHasFutureCommandsModal"
            @close="
                showHasFutureCommandsModal = false;
                futureCommands = [];
            "
            :commands="futureCommands"
            :restaurant_id="restaurant_id"
            @next="loadNextFutureCommands($event)"
            @save="
                showHasFutureCommandsModal = false;
                putForm(false);
            "
            @cancel-and-save="
                showHasFutureCommandsModal = false;
                putForm(true);
            " />
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import FileUploaderComponent from "../../../components/forms/FileUploaderComponent.vue";
import VueTimepicker from "vue2-timepicker";
import moment from "moment";
import ShowErrors from "../../../components/errors/ShowErrors.vue";
import AlertSlotDeletionModal from "../../../components/Modals/clickAndCollect/settings/AlertSlotDeletionModal.vue";
import editClickAndCollectNotifications from "../../../components/clickAndCollect/settings/editClickAndCollectNotifications.vue";
import CollectTypesEnum from "../../../mixins/enums/click_and_collect/CollectTypesEnum.js";
import AutomaticExportComponent from "../../Booking/Settings/AutomaticExport/AutomaticExportComponent.vue";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum";

function getDefaultService() {
    return {
        lundi: {
            day: 1,
            isOpen: false,
            services: [],
        },
        mardi: {
            day: 2,
            isOpen: false,
            services: [],
        },
        mercredi: {
            day: 3,
            isOpen: false,
            services: [],
        },
        jeudi: {
            day: 4,
            isOpen: false,
            services: [],
        },
        vendredi: {
            day: 5,
            isOpen: false,
            services: [],
        },
        samedi: {
            day: 6,
            isOpen: false,
            services: [],
        },
        dimanche: {
            day: 0,
            isOpen: false,
            services: [],
        },
    };
}

export default {
    data() {
        return {
            loading: false,
            errors: null,
            error: null,
            canFetchServices: false,
            pickunEnable: false,
            services: getDefaultService(),
            preparation_time: null,
            preparation_time_format: "minutes",
            legal_information: "",
            cc_configuration_id: null,
            open_period_days: null,
            cmd_per_service: "",
            cmd_per_slot: "",
            group_payments: false,
            serviceMaxOrder: false,
            collect_mealvouchers_enabled: false,
            slotMaxOrder: false,
            show_product_out: false,
            show_product_disable: false,
            disable_live_commands: false,
            futureCommands: [],
            showHasFutureCommandsModal: false,
            autoconfirm: {
                autoconfirm: 1,
                autoconfirm_command: 0,
                autoconfirm_until: 0,
            },
            collect_connect_to_gv: false,
            notificationsData: [
                {
                    label: "Confirmation de commande",
                    previewId: "order-confirmed",
                    key: "order_confirmation",
                },
                {
                    label: "Commande en attente",
                    previewId: "order-waiting-confirmation",
                    key: "order_waiting_confirmation",
                },
                {
                    label: "Commande prête",
                    previewId: "order-ready",
                    key: "order_ready",
                    canBeDisabled: true,
                },
                {
                    label: "Commande annulée",
                    previewId: "order-cancel",
                    key: "order_cancel",
                },
                {
                    label: "Commande partiellement remboursée",
                    previewId: "order-partial-refund",
                    key: "order_partial_refund",
                },
                {
                    label: "Commande retirée",
                    previewId: "order-take-off",
                    key: "order_take_off",
                    canBeDisabled: true,
                },
                {
                    label: "Commande modifiée",
                    previewId: "order-edition",
                    key: "order_edition",
                },
            ],
            automaticExport: {
                is_enabled: false,
                minutes: 30,
                collect_services: [],
                emails: [""],
            },
        };
    },
    mixins: [CollectTypesEnum, ModuleTypesEnum],
    computed: {
        restaurant() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id);
        },
        hasMealVouchersEnabled() {
            if (!this.restaurant) return false;
            return this.restaurant.edenred_enabled;
        },
        hasGvSubscription() {
            return this.$store.getters["restaurants/hasModule"]("gift-vouchers", this.restaurant_id);
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_config() {
            return this.rights.includes("click_and_collect.config.update");
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    components: {
        AlertSlotDeletionModal,
        VueTimepicker,
        FileUploaderComponent,
        LoaderComponent,
        ShowErrors,
        editClickAndCollectNotifications,
        AutomaticExportComponent,
    },
    watch: {
        preparation_time_format: function (newVal, oldVal) {
            if (newVal === "minutes" && oldVal === "heures") {
                this.preparation_time = this.preparation_time * 60;
            } else if (newVal === "heures" && oldVal === "minutes") {
                this.preparation_time = this.preparation_time / 60;
            } else if (newVal === "minutes" && oldVal === "jours") {
                this.preparation_time = this.preparation_time * 1440;
            } else if (newVal === "jours" && oldVal === "minutes") {
                this.preparation_time = this.preparation_time / 1440;
            } else if (newVal === "jours" && oldVal === "heures") {
                this.preparation_time = this.preparation_time / 24;
            } else if (newVal === "heures" && oldVal === "jours") {
                this.preparation_time = this.preparation_time * 24;
            }
        },
        serviceMaxOrder: function (newVal) {
            if (newVal) this.cmd_per_service = null;
        },
        slotMaxOrder: function (newVal) {
            if (newVal) this.cmd_per_slot = null;
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        sanitizeAutomaticExportEmails() {
            if (this.automaticExport.emails.length > 1) {
                this.automaticExport.emails = this.automaticExport.emails.filter((email) => email !== "");
            }
        },
        getPreparationTimeInMinutes() {
            if (this.preparation_time_format === "minutes") {
                return this.preparation_time;
            } else if (this.preparation_time_format === "heures") {
                return this.preparation_time * 60;
            } else if (this.preparation_time_format === "jours") {
                return this.preparation_time * 1440;
            }
        },
        formatHour(hour) {
            return moment(hour, "HH:mm").format("HH[h]mm");
        },
        calculHourRange(start_hour) {
            var start = parseInt(start_hour.HH);
            return [[start, 23]];
        },
        getDate(date) {
            const toTwoDigits = (num) => (num < 10 ? "0" + num : num);
            let year = date.getFullYear();
            let month = toTwoDigits(date.getMonth() + 1);
            let day = toTwoDigits(date.getDate());
            return `${year}-${month}-${day}`;
        },
        setDayIsOpen(day, event) {
            if (event.target.checked) {
                day.services.forEach((ds) => {
                    ds.deleted = false;
                });
                day.isOpen = true;
            } else {
                day.services.forEach((ds) => {
                    ds.deleted = true;
                    ds.slots.forEach((s) => {
                        s.enabled = false;
                    });
                });
                day.isOpen = false;
            }
        },
        calculSlot(day, serviceIndex, startInput = null, endInput = null) {
            var service = this.services[day].services[serviceIndex];
            if (startInput !== null) {
                service.start_hour = startInput;
            }

            if (endInput !== null) {
                service.end_hour = endInput;
            }

            const currentStartInput = service.start_hour;
            const currentEndInput = service.end_hour;
            const currentStartMoment = currentStartInput ? moment(`${service.start_hour.HH}:${service.start_hour.mm}`, "HH:mm") : null;
            const currentEndMoment = currentEndInput ? moment(`${service.end_hour.HH}:${service.end_hour.mm}`, "HH:mm") : null;

            if (currentStartInput && currentEndInput && currentStartMoment.isSameOrBefore(currentEndMoment)) {
                this.enableSlotsForService(service);
            } else {
                service.slots.forEach((s) => {
                    s.enabled = false;
                    s.displayed = false;
                });
            }
            this.orderSlotsForService(service);
        },
        timePickerClosed(day) {
            this.orderServicesForDay(this.services[day]);
        },
        enableSlotsForService(service) {
            var starting_hour = moment(`${service.start_hour.HH}:${service.start_hour.mm}`, "HH:mm");
            var end_hour = moment(`${service.end_hour.HH}:${service.end_hour.mm}`, "HH:mm");

            service.slots.forEach((s) => {
                var slotStartsAt = moment(s.hour, "HH:mm");

                if (slotStartsAt.isBefore(starting_hour) || slotStartsAt.isAfter(end_hour)) {
                    s.enabled = false;
                    s.displayed = false;
                }
            });

            while (starting_hour.isSameOrBefore(end_hour)) {
                var found = service.slots.find((s) => {
                    var slotStartsAt = moment(s.hour, "HH:mm");

                    return slotStartsAt.isSame(starting_hour);
                });

                if (found) {
                    found.displayed = true;
                } else {
                    service.slots.push({
                        hour: starting_hour.format("HH:mm"),
                        enabled: false,
                        displayed: true,
                        id: null,
                    });
                }
                starting_hour.add(15, "m");
            }
        },
        orderServicesForDay(day) {
            day.services.sort((a, b) => {
                if (!a.start_hour && !b.start_hour) {
                    return 0;
                } else if (a.start_hour && !b.start_hour) {
                    return -1;
                } else if (!a.start_hour && b.start_hour) {
                    return 1;
                }

                const aStartAt = moment(`${a.start_hour.HH}:${a.start_hour.mm}`, "HH:mm");
                const bStartAt = moment(`${b.start_hour.HH}:${b.start_hour.mm}`, "HH:mm");

                return aStartAt.valueOf() - bStartAt.valueOf();
            });
        },
        orderSlotsForService(service) {
            service.slots.sort((a, b) => {
                const aStartAt = moment(a.hour, "HH:mm");
                const bStartAt = moment(b.hour, "HH:mm");

                return aStartAt.valueOf() - bStartAt.valueOf();
            });
        },
        addOpening(day) {
            if (!this.has_right_to_update_config) return;
            this.services[day].services.push({
                id: null,
                start_hour: {
                    HH: "HH",
                    mm: "mm",
                },
                end_hour: {
                    HH: "HH",
                    mm: "mm",
                },
                deleted: false,
                slots: [],
            });
        },
        removeOpening(day, index) {
            if (!this.has_right_to_update_config) return;
            this.services[day].services[index].deleted = true;
        },
        hasErrors(key) {
            return this.errors !== null && this.errors[key] && this.errors[key].length > 0;
        },
        joinErrors(key) {
            return this.errors[key].join("<br/>");
        },
        fetchData() {
            this.loading = true;
            this.errors = null;
            this.error = null;

            axios
                .get(`/api/click_and_collect/${this.restaurant_id}/settings`)
                .then((response) => {
                    this.preparation_time_format = "minutes";
                    this.pickunEnable = response.data.is_click_and_collect_enabled;
                    this.$nextTick(() => {
                        this.preparation_time = response.data.preparation_time;
                        if (Number.isInteger(response.data.preparation_time / 1440)) {
                            this.preparation_time_format = "jours";
                        } else if (Number.isInteger(response.data.preparation_time / 60)) {
                            this.preparation_time_format = "heures";
                        } else {
                            this.preparation_time_format = "minutes";
                        }
                    });
                    this.open_period_days = response.data.opening_period;
                    this.cc_configuration_id = response.data.id;
                    this.cmd_per_service = response.data.orders_max_per_service;
                    this.cmd_per_slot = response.data.orders_max_per_slot;

                    if (this.cmd_per_service == null) {
                        this.serviceMaxOrder = true;
                    }

                    if (this.cmd_per_slot == null) {
                        this.slotMaxOrder = true;
                    }

                    this.disable_live_commands = response.data.disable_live_commands;
                    this.legal_information = response.data.legal_information;
                    this.show_product_out = response.data.show_out_of_stock_products;
                    this.show_product_disable = response.data.show_inactive_products;
                    this.group_payments = response.data.group_payment;
                    this.collect_mealvouchers_enabled = response.data.collect_mealvouchers_enabled;
                    this.autoconfirm.autoconfirm = response.data.autoconfirm;
                    this.autoconfirm.autoconfirm_command = response.data.autoconfirm_command;
                    this.autoconfirm.autoconfirm_until = response.data.autoconfirm_until;

                    if (this.hasGvSubscription) {
                        this.collect_connect_to_gv = response.data.collect_connect_to_gv;
                    }
                    if (!this.$_.isEmpty(response.data.automatic_exports)) {
                        this.automaticExport = Object.assign(this.automaticExport, response.data.automatic_exports[0]);
                    }
                    this.formatServices(response.data.services);
                    this.canFetchServices = true;
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.not_a_fatal_error === true) {
                        return;
                    }

                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => (this.loading = false));
        },
        formatServices(services) {
            this.services = getDefaultService();
            Object.values(services).forEach((value) => {
                switch (value.day) {
                    case 0:
                        this.formatService("dimanche", value);
                        break;
                    case 1:
                        this.formatService("lundi", value);
                        break;
                    case 2:
                        this.formatService("mardi", value);
                        break;
                    case 3:
                        this.formatService("mercredi", value);
                        break;
                    case 4:
                        this.formatService("jeudi", value);
                        break;
                    case 5:
                        this.formatService("vendredi", value);
                        break;
                    case 6:
                        this.formatService("samedi", value);
                        break;
                    default:
                }
            });
        },
        formatService(day, value) {
            this.services[day].isOpen = true;
            var service = {
                id: value.id,
                start_hour: {
                    HH: value.start_hour.substr(0, 2),
                    mm: value.start_hour.substr(3, 5),
                },
                end_hour: {
                    HH: value.end_hour.substr(0, 2),
                    mm: value.end_hour.substr(3, 5),
                },
                slots: value.slots.map((s) => {
                    s.displayed = false;
                    return s;
                }),
                deleted: false,
            };
            const index = this.services[day].services.push(service) - 1;
            this.calculSlot(day, index, service.start_hour, service.end_hour);
        },
        post() {
            this.loading = true;

            const callBack = (result) => {
                if (result === true) {
                    if (this.cc_configuration_id) {
                        this.checkSlotsChanges().then((result) => {
                            if (result) {
                                this.errors = null;
                                this.putForm();
                            } else {
                                this.loading = false;
                            }
                        });
                    } else {
                        this.errors = null;
                        this.postForm();
                    }
                } else {
                    this.loading = false;

                    this.notifyError(null, result || "Une erreur est survenue");
                }
            };

            if (this.pickunEnable) {
                this.$refs.editClickAndCollectNotifications.postForm().then(callBack);
            } else {
                callBack(true);
            }
        },
        checkSlotsChanges() {
            const slotsIds = this.getDeletedSlotsIds();
            if (slotsIds.length > 0) {
                return this.$store
                    .dispatch("ccConfiguration/fetchSlotsFutureCommands", {
                        params: { slots_ids: slotsIds.join(",") },
                        restaurant_id: this.restaurant_id,
                    })
                    .then((result) => {
                        if (result.data.data.length > 0) {
                            this.futureCommands = result.data;
                            this.showHasFutureCommandsModal = true;

                            return false;
                        } else {
                            return true;
                        }
                    })
                    .catch((error) => {
                        return false;
                    });
            } else {
                return new Promise((resolve, reject) => {
                    resolve(true);
                });
            }
        },
        loadNextFutureCommands(page) {
            const slotsIds = this.getDeletedSlotsIds();
            if (slotsIds.length > 0) {
                return this.$store
                    .dispatch("ccConfiguration/fetchSlotsFutureCommands", {
                        params: { slots_ids: slotsIds.join(","), page },
                        restaurant_id: this.restaurant_id,
                    })
                    .then((result) => {
                        if (this.futureCommands) {
                            this.futureCommands.data = this.futureCommands.data.concat(result.data.data);
                            this.futureCommands.meta = result.data.meta;
                        }
                        return true;
                    })
                    .catch((error) => {
                        return false;
                    });
            }
        },
        getDeletedSlotsIds() {
            return Object.keys(this.services)
                .map((key) => {
                    const day = this.services[key];

                    return day.services
                        .map((s) => {
                            return s.slots.map((slot) => {
                                if (slot.id && (!day.isOpen || s.deleted === true || !slot.enabled)) {
                                    return slot.id;
                                }
                            });
                        })
                        .flat()
                        .filter((s) => s);
                })
                .flat();
        },
        postForm(cancel = false) {
            this.errors = null;
            this.loading = false;

            return axios
                .post(`/api/click_and_collect/${this.restaurant_id}/settings`, this.getFormattedData(cancel))
                .then(() => {
                    this.notifySuccess(null, "La configuration a été enregistrée avec succès.");

                    this.fetchData();
                })
                .catch((error) => {
                    this.loading = false;

                    this.notifyError(error);

                    if (error.response && error.response.data) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
        putForm(cancel = false) {
            this.loading = true;
            this.errors = null;

            return axios
                .put(`/api/click_and_collect/${this.restaurant_id}/settings/${this.cc_configuration_id}`, this.getFormattedData(cancel))
                .then(() => {
                    this.notifySuccess(null, "La configuration a été enregistrée avec succès.");

                    this.fetchData();
                })
                .catch((error) => {
                    this.loading = false;

                    this.notifyError(error);

                    if (error.response && error.response.data) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
        getFormattedData(cancel = false) {
            this.sanitizeAutomaticExportEmails();

            let data = {
                restaurant_id: this.restaurant_id,
                preparation_time: this.getPreparationTimeInMinutes(),
                opening_period: this.open_period_days,
                orders_max_per_service: this.serviceMaxOrder ? null : this.cmd_per_service,
                orders_max_per_slot: this.slotMaxOrder ? null : this.cmd_per_slot,
                show_out_of_stock_products: this.show_product_out,
                show_inactive_products: this.show_product_disable,
                group_payment: this.group_payments,
                collect_mealvouchers_enabled: this.collect_mealvouchers_enabled,
                open_days: this.services,
                legal_information: this.legal_information,
                disable_live_commands: this.disable_live_commands,
                cancel_deleted_slots_commands: cancel,
                is_click_and_collect_enabled: this.pickunEnable,
                ...this.autoconfirm,
                collect_connect_to_gv: this.collect_connect_to_gv,
                automatic_export: this.automaticExport,
            };

            if (this.cc_configuration_id) {
                data.cc_configuration_id = this.cc_configuration_id;
            }

            return data;
        },
    },
};
</script>

<style scoped>
input[type="submit"]:disabled {
    cursor: default;
}
</style>
