var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "navbar navbar-dark fixed-top flex-md-nowrap p-0 bg-light" },
    [
      _c(
        "div",
        {
          ref: "icon",
          staticClass:
            "navbar-brand col-sm-12 mr-0 bg-dark d-flex flex-row justify-content-between",
          class: {
            "col-lg-2 col-md-3 navbar-brand-not-collaped":
              !_vm.isSidebarCollapsed,
            "col-lg-1 col-md-1 text-center navbar-brand-collapsed":
              _vm.isSidebarCollapsed,
          },
        },
        [
          _c(
            "div",
            { style: _vm.isSidebarCollapsed ? "margin: 0 auto" : "" },
            [
              _c(
                "button",
                {
                  staticClass: "btn text-white",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("module-switcher-button-clicked")
                    },
                  },
                },
                [
                  _c("feather", {
                    staticClass: "w-auto h-auto",
                    attrs: { type: "grid", size: "22" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: _vm.home } },
                [
                  !_vm.isSidebarCollapsed
                    ? [
                        _vm.isBooking
                          ? _c("img", {
                              staticClass: "img-logo display-sid-min",
                              attrs: {
                                src: "/images/logos-saas/reservation.png",
                              },
                            })
                          : _vm.isClickAndCollect
                          ? _c("img", {
                              staticClass: "img-logo display-sid-min",
                              attrs: { src: "/images/logos-saas/vae.png" },
                            })
                          : _vm.isGiftVoucher
                          ? _c("img", {
                              staticClass: "img-logo display-sid-min",
                              attrs: { src: "/images/logos-saas/bc.png" },
                            })
                          : _vm.isWebSite
                          ? _c("img", {
                              staticClass: "img-logo display-sid-min",
                              attrs: { src: "/images/logos-saas/sw.png" },
                            })
                          : _vm.isCampaigns
                          ? _c("img", {
                              staticClass: "img-logo display-sid-min",
                              attrs: { src: "/images/logos-saas/campagne.png" },
                            })
                          : _vm._e(),
                      ]
                    : _c("div", {
                        staticClass: "img-logo-sid-min",
                        staticStyle: { height: "28px" },
                      }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "none-desk nav-btn mx-md-5",
              staticStyle: { right: "55px" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm p-0 dropdown-toggle",
                  class: {
                    "bg-light": _vm.user && _vm.user.role === "kitchen",
                  },
                  attrs: {
                    type: "button",
                    id: "dropdownCompte",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false",
                  },
                },
                [
                  _c("feather", {
                    staticClass: "feather-22 feather-top-bar",
                    class: {
                      "mr-0": _vm.isSidebarCollapsed,
                    },
                    staticStyle: { color: "white" },
                    attrs: { type: "user" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.user
                ? _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu text-right dropdown-menu-right shadow",
                      attrs: { "aria-labelledby": "dropdownCompte" },
                    },
                    [
                      _c("h6", { staticClass: "dropdown-header" }, [
                        _vm._v(
                          _vm._s(_vm.user.firstname) +
                            " " +
                            _vm._s(_vm.user.lastname)
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: { name: "account" } },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$tl("labels.routes.account")) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: { name: "home" } },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$tl("labels.routes.home")) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.has_right_to_read_restaurants
                        ? _c(
                            "router-link",
                            {
                              staticClass: "dropdown-item",
                              attrs: { to: { name: "restaurants" } },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$tl("labels.routes.restaurants")) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isNoShow && _vm.has_right_to_read_subscription
                        ? _c(
                            "router-link",
                            {
                              staticClass: "dropdown-item",
                              attrs: { to: { name: "subscription" } },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$tl("labels.routes.subscription")
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isNoShow && _vm.has_right_to_read_employees
                        ? _c(
                            "router-link",
                            {
                              staticClass: "dropdown-item",
                              attrs: { to: { name: "employees" } },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$tl("labels.routes.users")) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.has_right_to_update_sms
                        ? _c(
                            "router-link",
                            {
                              staticClass: "dropdown-item",
                              attrs: { to: { name: "sms" } },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$tl("labels.routes.sms")) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _vm._v(" "),
                      _vm.isNoShow
                        ? [
                            _c(
                              "a",
                              {
                                staticClass: "open-intercom dropdown-item",
                                attrs: { href: "#" },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.$tl("labels.routes.help")) +
                                    "\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: { name: "online_help" } },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$tl("labels.routes.onlineHelp")
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]
                        : _vm.isYservices
                        ? _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openHelpscoutBeacon.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$tl("labels.routes.help")) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: { name: "app_updates.list" } },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$tl("labels.routes.appUpdates")) +
                              "\n                    "
                          ),
                          _vm.hasUnreadAppUpdates
                            ? _c("div", { staticClass: "red-bubble ml-2" })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.checkForExistingNewBadge("app_updates")
                            ? _c("new-badge", { staticClass: "ml-2" })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          staticStyle: { color: "#f75b61" },
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.logout.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$tl("labels.routes.disconnect")) +
                              "\n                    "
                          ),
                          _c("feather", {
                            class: { "mr-0": _vm.isSidebarCollapsed },
                            attrs: { type: "log-out" },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("feather", {
            staticClass: "d-md-none w-auto h-auto mt-1",
            attrs: { type: _vm.mobileMenuDisplayed ? "x" : "menu" },
            on: {
              click: function ($event) {
                return _vm.$emit(
                  "mobile-menu-updated",
                  !_vm.mobileMenuDisplayed
                )
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.restaurant_id && _vm.isSidebarCollapsed
        ? _c("div", { staticClass: "input-group restaurant-title p-2" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restaurant_id,
                    expression: "restaurant_id",
                  },
                ],
                staticClass: "custom-select rounded-pill pl-3",
                attrs: { id: "inputGroupSelect01" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.restaurant_id = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.selectRestaurant($event)
                    },
                  ],
                },
              },
              _vm._l(_vm.restaurants, function (restaurant) {
                return _c(
                  "option",
                  { key: restaurant.id, domProps: { value: restaurant.id } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(restaurant.name) +
                        "\n            "
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "none-mobile none-tablet" },
        [
          _vm.has_right_to_update_sms && _vm.sms.activated !== null
            ? _c(
                "router-link",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.getTooltip(
                        _vm.sms.activated !== null &&
                          _vm.sms.activated === false
                          ? ""
                          : "Commander des SMS"
                      ),
                      expression:
                        "getTooltip(sms.activated !== null && sms.activated === false ? '' : 'Commander des SMS')",
                    },
                  ],
                  staticClass:
                    "ml-2 btn btn-sm btn-circle btn-outline-secondary",
                  attrs: { to: { name: "sms" } },
                },
                [
                  _vm.sms.activated === false
                    ? _c("span", { staticClass: "sms-count" }, [
                        _vm._v(_vm._s(_vm.$tl("labels.form.sms.enable"))),
                      ])
                    : _vm.sms.credits
                    ? _c("span", { staticClass: "“sms-count”" }, [
                        _vm._v(
                          _vm._s(_vm.sms.credits) +
                            " " +
                            _vm._s(_vm.$tl("labels.smsCredits"))
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm.sms.activated !== null
            ? _c(
                "div",
                {
                  staticClass:
                    "ml-3 btn btn-sm btn-circle btn-outline-secondary",
                },
                [
                  _vm.sms.activated === false
                    ? _c("span", { staticClass: "sms-count" }, [
                        _vm._v(_vm._s(_vm.$tl("labels.form.sms.enable"))),
                      ])
                    : _vm.sms.credits
                    ? _c("span", { staticClass: "“sms-count”" }, [
                        _vm._v(
                          _vm._s(_vm.sms.credits) +
                            " " +
                            _vm._s(_vm.$tl("labels.smsCredits"))
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("ShowNotificationsComponent", {
        attrs: {
          showNotifications: _vm.notifications.show,
          module_type: _vm.module_type,
        },
        on: {
          open: function ($event) {
            _vm.notifications.show = true
          },
          close: function ($event) {
            _vm.notifications.show = false
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "nav-btn mx-md-5" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm p-0 dropdown-toggle",
            class: { "bg-light": _vm.user && _vm.user.role === "kitchen" },
            attrs: {
              type: "button",
              id: "dropdownCompte",
              "data-toggle": "dropdown",
              "aria-haspopup": "true",
              "aria-expanded": "false",
            },
          },
          [
            _c("feather", {
              staticClass: "feather-22 feather-top-bar",
              class: {
                "mr-0": _vm.isSidebarCollapsed,
              },
              attrs: { type: "user" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.user
          ? _c(
              "div",
              {
                staticClass:
                  "dropdown-menu text-right dropdown-menu-right shadow",
                attrs: { "aria-labelledby": "dropdownCompte" },
              },
              [
                _c("h6", { staticClass: "dropdown-header" }, [
                  _vm._v(
                    _vm._s(_vm.user.firstname) + " " + _vm._s(_vm.user.lastname)
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "account" } },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.routes.account")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "home" } },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.routes.home")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.has_right_to_read_restaurants
                  ? _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item",
                        attrs: { to: { name: "restaurants" } },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$tl("labels.routes.restaurants")) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isNoShow && _vm.has_right_to_read_subscription
                  ? _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item",
                        attrs: { to: { name: "subscription" } },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$tl("labels.routes.subscription")) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isNoShow && _vm.has_right_to_read_employees
                  ? _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item",
                        attrs: { to: { name: "employees" } },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$tl("labels.routes.users")) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.has_right_to_update_sms
                  ? _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item",
                        attrs: { to: { name: "sms" } },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$tl("labels.routes.sms")) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _vm.isNoShow
                  ? [
                      _c(
                        "a",
                        {
                          staticClass: "open-intercom dropdown-item",
                          attrs: { href: "#" },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$tl("labels.routes.help")) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: { name: "online_help" } },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$tl("labels.routes.onlineHelp")) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]
                  : _vm.isYservices
                  ? _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openHelpscoutBeacon.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$tl("labels.routes.help")) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "app_updates.list" } },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.routes.appUpdates")) +
                        "\n                "
                    ),
                    _vm.hasUnreadAppUpdates
                      ? _c("div", { staticClass: "red-bubble ml-2" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.checkForExistingNewBadge("app_updates")
                      ? _c("new-badge", { staticClass: "ml-2" })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    staticStyle: { color: "#f75b61" },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.logout.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.routes.disconnect")) +
                        "\n                "
                    ),
                    _c("feather", {
                      class: { "mr-0": _vm.isSidebarCollapsed },
                      attrs: { type: "log-out" },
                    }),
                  ],
                  1
                ),
              ],
              2
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }