var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.value === null
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search.input,
              expression: "search.input",
            },
          ],
          staticClass: "form-control",
          attrs: { type: "text", disabled: _vm.disabled },
          domProps: { value: _vm.search.input },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.search, "input", $event.target.value)
            },
          },
        })
      : _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("input", {
              staticClass: "form-control",
              attrs: { type: "text", disabled: "" },
              domProps: { value: _vm.displayableAddress },
            }),
            _vm._v(" "),
            _c("feather", {
              staticClass: "text-danger position-absolute",
              class: { pointer: _vm.disabled },
              staticStyle: { top: "10px", right: "18px" },
              attrs: { type: "x" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.resetAddress.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
    _vm._v(" "),
    _vm.errors.search !== null
      ? _c("div", { staticClass: "text-danger" }, [
          _vm._v(_vm._s(_vm.errors.search)),
        ])
      : _vm.search.hits.length > 0
      ? _c(
          "div",
          {
            staticClass: "dropdown-menu prename-field shadow show p-0",
            staticStyle: { top: "auto", left: "auto" },
          },
          [
            _vm.loading.placeDetails
              ? _c("loader-component")
              : _c(
                  "ul",
                  { staticClass: "list-group" },
                  _vm._l(_vm.search.hits, function (hit) {
                    return _c(
                      "li",
                      {
                        key: hit.description,
                        staticClass: "list-group-item p-0",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "d-block p-3",
                            attrs: { href: "javascript:" },
                            on: {
                              click: function ($event) {
                                return _vm.selectAddress(hit.place_id)
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(hit.description))])]
                        ),
                      ]
                    )
                  }),
                  0
                ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }