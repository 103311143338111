<template>
    <div class="border-light d-flex justify-content-between p-3 align-items-center">
        <div>
            <span>{{ paidAt }}</span>
            <h5 class="mb-0" style="line-height: 1.6">
                {{ $t("labels.invoices.number", { number: invoice.number }) }}
            </h5>
            <div>{{ formatCurrency(invoice.amount / 100) }}</div>
            <span>{{ $tl("labels.invoices.smsCampaign") }}</span>
        </div>
        <div>
            <a target="_blank" class="btn btn-sm btn-circle btn-success" :href="downloadLink">
                {{ $tl("labels.form.actions.download") }}
            </a>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    props: {
        invoice: {
            required: true,
        },
    },
    computed: {
        downloadLink() {
            return `/api/stripe/campaign_invoices/sms/${this.invoice.id}`;
        },
        paidAt() {
            const momentDate = moment.unix(this.invoice.paid_at);
            return momentDate.format("DD MMMM Y");
        },
    },
};
</script>
