<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <show-emails-campaign-pack :emailsCampaignPack="emailsCampaignPack"> </show-emails-campaign-pack>
                </div>
            </div>
        </div>
        <Facturation getInvoiceUrl="/api/campaigns/emailsCampaigns/invoice" @close="$emit('close')" @paid="paid" />
    </div>
</template>

<script>
import ShowEmailsCampaignPack from "../campaign/ShowEmailsCampaignPack";
import Facturation from "../payment/Facturation";

export default {
    name: "PurchaseEmailsCampaignPack",
    props: {
        emailsCampaignPack: {
            default: {
                name: "Pack emails de campagne",
                description: "Ce pack vous octroiera un crédit de 10.000 emails de campagne.",
                price: 12,
                price_ht: 10,
            },
        },
    },
    components: {
        ShowEmailsCampaignPack,
        Facturation,
    },
    methods: {
        paid(e) {
            this.$emit("disable-close-button");
            const thenFct = () => {
                setTimeout(() => {
                    this.$emit("paid", e);
                }, 3000);
            };
            if (!e.hasPaidWithCard)
                axios
                    .post(`/api/campaigns/isPayingEmails/${this.$route.params.restaurant_id}`, {
                        stripe_invoice_id: e.stripe_invoice_id,
                    })
                    .then(thenFct)
                    .catch(thenFct);
            else thenFct();
        },
    },
};
</script>
