var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row m-0" }, [
    _c("div", { staticClass: "col-lg-6 offset-lg-3 pt-5 text-center" }, [
      _c("div", { staticClass: "border-light b-radius-20 p-4 mb-3" }, [
        !_vm.isForbidden
          ? _c("h5", [_vm._v(_vm._s(_vm.label))])
          : _c("h5", [
              _vm._v(_vm._s(_vm.$tl("errors.common.rights.notEnough"))),
            ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "mt-4 none-mobile",
          attrs: { width: "340", src: "/images/no-resa.png", alt: "No Show" },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "mt-3 none-desk",
          attrs: { width: "250", src: "/images/no-resa.png", alt: "No Show" },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }