var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _c(
              "div",
              [
                _vm.formErrors
                  ? _c(
                      "div",
                      { staticClass: "alert alert-danger" },
                      _vm._l(_vm.formErrors, function (error, index) {
                        return _c("p", { key: index }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(error) +
                              "\n                "
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.formLoading
                  ? _c("LoaderComponent")
                  : _c("div", [
                      _c("div", { staticClass: "row m-0" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            {
                              staticClass: "border-light b-radius-20 p-4 mb-3",
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12 mb-2" }, [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.inputParams.tags.addCustom"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "form",
                                  {
                                    staticClass: "col-12",
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.addCurrentTag.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col-6" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.currentTag,
                                              expression: "currentTag",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "text" },
                                          domProps: { value: _vm.currentTag },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.currentTag =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-6" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-circle btn-success",
                                            attrs: { type: "submit" },
                                          },
                                          [
                                            _c("feather", {
                                              attrs: { type: "plus" },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.actions.add"
                                                  )
                                                ) +
                                                "\n                                            "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _vm.custom_tags.length === 0
                                      ? _c(
                                          "p",
                                          { staticClass: "text-muted m-0" },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.booking.inputParams.tags.empty"
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.custom_tags,
                                      function (tag, index) {
                                        return _c(
                                          "span",
                                          {
                                            key: index,
                                            staticClass: "tag is-rounded mb-1",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.getReservationTagLabelFromFrLabel(
                                                    tag
                                                  ) || tag
                                                ) +
                                                "\n                                        "
                                            ),
                                            _c("button", {
                                              staticClass: "delete",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.custom_tags.splice(
                                                    index,
                                                    1
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }