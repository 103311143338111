<template>
    <div :class="computeClasses('wrapper')">
        <div :class="computeClasses('labelWrapper')">
            <label v-if="label !== null" :class="labelClasses">{{ label }} <small v-if="required">*</small></label>
            <slot v-else name="label"></slot>
            <feather v-if="notice" v-tooltip="getTooltipNotice(notice)" type="info" class="ml-1 pointer tooltip-infos" />
            <slot name="after-label"></slot>
        </div>
        <div :class="computeClasses('inputWrapper')">
            <input
                ref="input"
                :name="inputName"
                :type="inputType"
                v-model="value_"
                :class="computeClasses('input')"
                :required="required"
                :disabled="disabled"
                :data-test-id="dataTestId_"
                :minlength="minlength"
                :maxlength="maxlength"
                :min="min"
                :max="max"
                :step="step"
                :placeholder="placeholder" />
            <div v-if="appendElement !== null" class="input-group-append">
                <span class="input-group-text">{{ appendElement }}</span>
            </div>
            <ShowErrors v-if="inputName" class="d-block" :errors="formErrors" :errorKey="inputName" />
        </div>
    </div>
</template>

<script>
import ShowErrors from "../errors/ShowErrors.vue";

export default {
    name: "InputComponent",
    data() {
        return {
            defaultWrapperClasses: "row mb-2",
            defaultLabelWrapperClasses: "col-md-5 pt-2",
            defaultInputWrapperClasses: "col-md-7 pt-2",
            defaultInputClasses: "form-control",
        };
    },
    props: {
        /**
         *  - wrapperClasses: Object|Array|String
         *  - labelWrapperClasses: Object|Array|String
         *  - inputWrapperClasses: Object|Array|String
         *  - inputClasses: Object|Array|String
         */
        appendElement: {
            type: String,
            default: null,
        },
        formErrors: {
            default: null,
        },
        value: {
            default: undefined,
        },
        label: {
            type: String,
            default: null,
        },
        wrapperClasses: {
            default: undefined,
        },
        labelWrapperClasses: {
            default: undefined,
        },
        inputWrapperClasses: {
            default: undefined,
        },
        labelClasses: {
            default: undefined,
        },
        inputClasses: {
            default: undefined,
        },
        inputType: {
            type: String,
            default: "text",
        },
        inputName: {
            type: String,
            default: undefined,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        minlength: {
            default: undefined,
        },
        maxlength: {
            default: undefined,
        },
        min: {
            default: undefined,
        },
        max: {
            default: undefined,
        },
        step: {
            default: undefined,
        },
        placeholder: {
            default: undefined,
        },
        notice: {
            type: String,
            default: undefined,
        },
        dataTestId: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        value_: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        dataTestId_() {
            if (this.dataTestId !== undefined) {
                return this.dataTestId;
            }

            if (this.inputName !== undefined) {
                return `input-txt-${this.inputName}`;
            }

            return undefined;
        },
    },
    methods: {
        convertClassToObject(classes) {
            let newClasses = {};

            if (typeof classes === "string") {
                classes.split(" ").forEach((tmpClass) => (newClasses[tmpClass] = true));
            } else if (Array.isArray(classes)) {
                classes.forEach((tmpClass) => (newClasses[tmpClass] = true));
            } else if (typeof classes === "object") {
                newClasses = classes;
            }

            return newClasses;
        },
        computeClasses(classesType) {
            return {
                ...this.convertClassToObject(this[`default${this.capitalize(classesType)}Classes`]),
                ...this.convertClassToObject(this[`${classesType}Classes`]),
                "input-group": this.appendElement !== null,
            };
        },
    },
    components: {
        ShowErrors,
    },
};
</script>
