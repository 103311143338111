import WebsiteHome from "../../views/Website/Home.vue";
import WebsiteCustomization from "../../views/Website/WebsiteCustomization.vue";
import WebsiteCustomizationContent from "../../views/Website/Customization/Content.vue";
import WebsiteCustomizationModals from "../../views/Website/Customization/Modals.vue";
import WebsiteCustomizationConnectedModules from "../../views/Website/Customization/ConnectedModules.vue";
import WebsiteCustomizationExternalLinks from "../../views/Website/Customization/ExternalLinks.vue";
import WebsiteStats from "../../views/Website/WebsiteStats.vue";
import WebsiteSettings from "../../views/Website/WebsiteSettings.vue";
import WebsiteSettingsDomain from "../../views/Website/Settings/Domain.vue";
import WebsiteSettingsLegalMentions from "../../views/Website/Settings/LegalMentions.vue";
import WebsiteSettingsSEO from "../../views/Website/Settings/SEO.vue";
import WebsiteSettingsStyleGuide from "../../views/Website/Settings/StyleGuide.vue";

const routes = [];

if (THEME === "noshow") {
    routes.push(
        {
            path: "/website",
            name: "website.home",
            component: WebsiteHome,
        },
        {
            path: "/website/restaurants/:restaurant_id/customization",
            name: "website.restaurants.customization",
            component: WebsiteCustomization,
            children: [
                {
                    path: "content",
                    name: "website.restaurants.customization.content",
                    component: WebsiteCustomizationContent,
                },
                {
                    path: "modals",
                    name: "website.restaurants.customization.modals",
                    component: WebsiteCustomizationModals,
                },
                {
                    path: "connected_modules",
                    name: "website.restaurants.customization.connected_modules",
                    component: WebsiteCustomizationConnectedModules,
                },
                {
                    path: "external_links",
                    name: "website.restaurants.customization.external_links",
                    component: WebsiteCustomizationExternalLinks,
                },
            ],
        },
        {
            path: "/website/restaurants/:restaurant_id/stats",
            name: "website.restaurants.stats",
            component: WebsiteStats,
        },
        {
            path: "/website/restaurants/:restaurant_id/settings",
            name: "website.restaurants.settings",
            component: WebsiteSettings,
            children: [
                { path: "domain", name: "website.restaurants.settings.domain", component: WebsiteSettingsDomain },
                { path: "seo", name: "website.restaurants.settings.seo", component: WebsiteSettingsSEO },
                {
                    path: "style_guide",
                    name: "website.restaurants.settings.style_guide",
                    component: WebsiteSettingsStyleGuide,
                },
                {
                    path: "legal_mentions",
                    name: "website.restaurants.settings.legal_mentions",
                    component: WebsiteSettingsLegalMentions,
                },
            ],
        }
    );
}

export default routes;
