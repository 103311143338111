import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import AuthStore from "./modules/Auth";
import ModuleStore from "./modules/Module";
import UserStore from "./modules/user";
import RestaurantStore from "./modules/restaurant";
import StripeStore from "./modules/Stripe";
import WidgetStore from "./modules/Widget";
import CcCommandsStore from "./modules/CcCommands";
import CcConfigurationStore from "./modules/CcConfiguration";
import PublicStore from "./modules/Public";
import SmsStore from "./modules/Sms";
import ErrorStore from "./modules/Error";
import SocketStore from "./modules/Socket";
import AdminStore from "./modules/Admin";
import WebsiteStore from "./modules/Website";
import UserPreferencesStore from "./modules/userPreferences";

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        errors: ErrorStore,
        auth: AuthStore,
        users: UserStore,
        modules: ModuleStore,
        restaurants: RestaurantStore,
        stripe: StripeStore,
        widgets: WidgetStore,
        ccCommands: CcCommandsStore,
        ccConfiguration: CcConfigurationStore,
        public: PublicStore,
        sms: SmsStore,
        sockets: SocketStore,
        admin: AdminStore,
        websites: WebsiteStore,
        userPreferences: UserPreferencesStore,
    },
    plugins: [
        createPersistedState({
            key: `${THEME}-user-lang-bo`,
            paths: ["users.lang"],
        }),
        createPersistedState({
            key: `${THEME}-user-preferences`,
            paths: ["userPreferences"],
        }),
    ],
});

export default store;
