<!--
    /!\ DEPRECATED /!\
    Use "ImageUploaderComponent" instead
-->
<template>
    <div>
        <LoaderComponent v-show="loading" />
        <form v-show="!loading" class="image-component-display pt-1 pr-1 pb-0 pl-1">
            <img v-show="!isLoadSrcEmpty" :src="this.loadSrc" :alt="'Image ' + this.alt" class="img-fluid" :style="addStyle" />
            <div :class="{ 'mt-1': !isLoadSrcEmpty }">
                <label :for="formImgId" class="label-file mr-2" :class="{ 'text-muted': disabled, 'cursor-d': disabled }">
                    {{ !isSrcEmpty ? $tl("labels.form.image.chooseNew") : $tl("labels.form.image.choose") }}
                </label>
                <input
                    ref="fileInput"
                    :disabled="disabled"
                    type="file"
                    class="input-file"
                    :id="formImgId"
                    @change="showPreview"
                    :accept="allowedExtensions" />
                <input
                    :disabled="disabled || isFileTooBig"
                    v-if="upload && unsaveChanges"
                    @click.prevent.stop="postImage"
                    type="submit"
                    name="submit"
                    :value="$tl('labels.form.actions.send')"
                    class="btn btn-success btn-sm btn-circle" />
                <button
                    :disabled="disabled"
                    v-if="deleteURI && !isSrcEmpty"
                    class="btn btn-danger btn-sm btn-circle"
                    type="button"
                    @click.prevent.stop="deleteImage">
                    {{ $tl("labels.form.image.delete") }}
                </button>
            </div>
            <div class="mb-2" v-if="maxSize !== null">
                <small class="text-danger" v-if="isFileTooBig">{{ $t("errors.common.image.tooBig", { size: fileSize, maxSize }) }}</small>
                <small v-else class="text-warning d-block">{{ $t("infos.common.file.maxSize", { maxSize }) }}</small>
                <small>
                    <a href="https://www.iloveimg.com/fr/compresser-image" target="_blank">
                        <feather type="image" class="text-warning mr-1" />{{ $tl("labels.form.image.compress") }}
                    </a>
                </small>
            </div>
        </form>
        <span class="text-danger" v-if="upload && unsaveChanges && !isFileTooBig">
            <small>{{ $tl("labels.form.image.dontForgetSave") }}</small>
        </span>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../LoaderComponent";

export default {
    name: "ImageComponent",
    data() {
        return {
            unsaveChanges: false,
            loading: false,
            loadSrc: this.src,
            fileSize: null,
        };
    },
    props: {
        src: {
            default: null,
        },
        addStyle: {
            required: false,
            default: "",
        },
        suffixId: {
            type: String,
            required: true,
        },
        postURI: {
            type: String,
            default: "",
        },
        allowedExtensions: {
            type: String,
            default: ".png,.jpg,.jpeg,.JPG",
        },
        postKey: {
            type: String,
            default: "img",
        },
        callBack: {
            type: Function,
            default: null,
        },
        alt: {
            type: String,
            default: "preview",
        },
        upload: {
            default: true,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        deleteURI: {
            type: String,
            default: null,
        },
        callBackDelete: {
            type: Function,
            default: null,
        },
        callBackOnUnsaveChanges: {
            type: Function,
            default: null,
        },
        maxSize: {
            // In MB
            type: Number,
            default: null,
        },
    },
    computed: {
        isLoadSrcEmpty() {
            return ["", null].includes(this.loadSrc);
        },
        isSrcEmpty() {
            return ["", null].includes(this.src);
        },
        formImgId() {
            return "img-imgcomponent-form-" + this.suffixId;
        },
        isFileTooBig() {
            if (this.maxSize === null || this.fileSize === null) {
                return false;
            }
            return this.fileSize > this.maxSize;
        },
    },
    methods: {
        showPreview(event) {
            if (event.target.files.length < 1) {
                return;
            }

            this.loadSrc = URL.createObjectURL(event.target.files[0]);
            this.unsaveChanges = true;
            this.fileSize = Number.parseFloat(event.target.files[0].size / 1048576);

            if (this.fileSize % 1 !== 1) {
                this.fileSize = this.fileSize.toFixed(1);
            }

            if (!this.upload) {
                this.$emit("new-file", event.target.files[0]);
            }
        },
        postImage() {
            if (!this.unsaveChanges) {
                return;
            }

            this.loading = true;

            let formData = new FormData();
            formData.append(this.postKey, document.getElementById(this.formImgId).files[0]);

            axios
                .post(this.postURI, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    this.notifySuccess(response);

                    this.unsaveChanges = false;
                    this.src = this.loadSrc;

                    if (this.callBack !== null) {
                        this.callBack(response);
                    }
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => (this.loading = false));
        },
        deleteImage() {
            if (this.isSrcEmpty || !confirm(this.$tl("questions.common.deleteImg"))) {
                return;
            }

            this.loading = true;

            axios
                .delete(this.deleteURI)
                .then((response) => {
                    this.notifySuccess(response);

                    this.loadSrc = null;

                    if (this.callBackDelete !== null) {
                        this.callBackDelete(response);
                    }
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => (this.loading = false));
        },
    },
    watch: {
        unsaveChanges(newVal) {
            if (this.callBackOnUnsaveChanges) {
                this.callBackOnUnsaveChanges(newVal);
            }
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>

<style scoped>
.image-component-display > img.img-fluid {
    max-width: 200px;
    max-height: 120px;
}

.image-component-display input[type="submit"]:disabled {
    cursor: default;
}

.cursor-d,
.cursor-d:hover {
    cursor: default !important;
}
</style>
