var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "thead",
    [
      _c(
        "ns-th",
        {
          attrs: {
            field: "reservations.client",
            width: "6",
            "allow-sorting": "",
          },
        },
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.commons.client"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("feather", { staticClass: "none-desk", attrs: { type: "user" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ns-th",
        {
          attrs: {
            field: "reservations.reservation_datetime",
            width: "14",
            "allow-sorting": "",
          },
        },
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.reservation.reservationDate"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("feather", {
            staticClass: "none-desk",
            attrs: { type: "calendar" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ns-th",
        { attrs: { field: "slots.hour_start", width: "9" } },
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.commons.hours"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("feather", { staticClass: "none-desk", attrs: { type: "clock" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ns-th",
        {
          attrs: {
            field: "reservations.total_nb_pers",
            width: "9",
            "allow-sorting": "",
          },
        },
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.reservation.pax"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("feather", { staticClass: "none-desk", attrs: { type: "users" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ns-th",
        {
          attrs: {
            field: "reservation_mail_logs.created_at",
            width: "7",
            "allow-sorting": "",
          },
        },
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.commons.sentDate"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("feather", { staticClass: "none-desk", attrs: { type: "send" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ns-th",
        { attrs: { field: "status", width: "18" } },
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.commons.shipmentStatus"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("feather", {
            staticClass: "none-desk",
            attrs: { type: "message-square" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ns-th",
        { attrs: { field: "re_sent", width: "2" } },
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.commons.resendMail"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("feather", { staticClass: "none-desk", attrs: { type: "send" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }