var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading ? _c("loader-component") : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c(
          "p",
          { staticClass: "text-center" },
          _vm._l(_vm.message, function (tmpMsg, index) {
            return _c("span", { key: index, staticClass: "d-block" }, [
              _vm._v("\n                " + _vm._s(tmpMsg) + "\n            "),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "d-flex align-items-center justify-content-center w-100 text-center",
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-secondary btn-circle d-flex align-items-center justify-content-between mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [
                _c("feather", {
                  staticClass: "feather-list mr-1",
                  attrs: { type: "list" },
                }),
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$tl("labels.booking.reservations.viewList")) +
                    "\n            "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "btn btn-success btn-circle d-flex align-items-center justify-content-between mr-2",
                attrs: { type: "button" },
                on: { click: _vm.goToSeatingPlanBuilder },
              },
              [
                _c("feather", {
                  staticClass: "feather-plus mr-1",
                  attrs: { type: "plus" },
                }),
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$tl("labels.booking.seatingPlan.add")) +
                    "\n            "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.hasASeatingPlan
              ? _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-success btn-circle d-flex align-items-center justify-content-between mr-2",
                    attrs: { type: "button" },
                    on: { click: _vm.goToServiceEdit },
                  },
                  [
                    _c("feather", {
                      staticClass: "feather-edit mr-1",
                      attrs: { type: "edit" },
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$tl(
                            "labels.booking.reservations.editService",
                            _vm.$route.params.restaurant_id
                          )
                        ) +
                        "\n            "
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }