var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    { on: { close: _vm.closeFromModal } },
    [
      _vm.isLoading
        ? [
            _c("LoaderComponent", { attrs: { slot: "body" }, slot: "body" }),
            _vm._v(" "),
            _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
          ]
        : _vm.isWarningModal
        ? [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _c(
                "h5",
                { staticClass: "d-flex align-items-center" },
                [
                  _c("feather", {
                    staticClass: "mr-2",
                    attrs: { type: "alert-circle" },
                  }),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("infos.subscriptions.modal.title")) +
                      "\n            "
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "body" }, slot: "body" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$tl("infos.subscriptions.modal.body")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-circle btn-sm mr-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.close.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-success btn-circle btn-sm",
                  attrs: { type: "button" },
                  on: { click: _vm.askForSubscription },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.form.actions.confirm")) +
                      "\n            "
                  ),
                ]
              ),
            ]),
          ]
        : [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _c(
                "h5",
                { staticClass: "d-flex align-items-center" },
                [
                  _c("feather", {
                    staticClass: "mr-2",
                    attrs: { type: "check-circle" },
                  }),
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$tl("success.subscriptions.modalConfirm.title")
                      ) +
                      "\n            "
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "body" }, slot: "body" }, [
              _vm._v(
                _vm._s(_vm.$tl("success.subscriptions.modalConfirm.body"))
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "mx-auto",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success btn-circle btn-sm mr-2",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.close.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.form.actions.ok")) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }