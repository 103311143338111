<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Importer des bons cadeaux</h5>
        <div slot="body">
            <LoaderComponent v-if="formLoading" />
            <div v-else class="row">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div>
                            <p>
                                Le fichier importé doit être au format CSV UTF-8. Il doit comporter 6 colonnes dans
                                l'ordre suivant, sans entête :
                            </p>
                            <ul>
                                <li class="list-style-type-circle">
                                    1ère colonne <span style="color: #f75b61">*</span> : Référence du bon cadeau (10
                                    caractères maximum)
                                </li>
                                <li class="list-style-type-circle">
                                    2ème colonne <span style="color: #f75b61">*</span> : Date d'expiration du bon cadeau
                                    (au format JJ/MM/AAAA)
                                </li>
                                <li class="list-style-type-circle">3ème colonne : Nom du produit</li>
                                <li class="list-style-type-circle">4ème colonne : Prix TTC</li>
                                <li class="list-style-type-circle">5ème colonne : Nom du client</li>
                                <li class="list-style-type-circle">6ème colonne : Prénom du client</li>
                            </ul>
                            <span style="color: #f75b61">*</span> Champs obligatoires
                        </div>
                        <br />
                        <div>
                            <a href="/assets/bons_cadeaux.csv" class="d-block">
                                <small>Exemple</small>
                            </a>
                            <input id="fileInput" @change="fileInputChanged" type="file" />
                        </div>
                        <div>
                            <div v-if="formErrors" class="alert alert-danger d-block position-relative">
                                <p v-for="error in formErrors">
                                    {{ error }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="d-flex">
            <button class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="$emit('close')">
                Fermer
            </button>
            <button
                class="btn btn-sm btn-success btn-circle ml-2"
                :class="{ disabled: this.file === undefined }"
                :disabled="this.file === undefined"
                @click="sendForm">
                Importer
            </button>
        </div>
    </modal>
</template>

<script>
import LoaderComponent from "../../LoaderComponent";

export default {
    data() {
        return {
            file: undefined,
            formLoading: false,
            formErrors: undefined,
        };
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        sendForm() {
            if (this.file) {
                this.formLoading = true;
                this.formErrors = undefined;

                const formData = new FormData();

                formData.append("file", this.file);

                axios
                    .post(`/api/gift_vouchers/${this.restaurant_id}/import`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        this.formLoading = false;
                        this.file = undefined;

                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: response.data.message,
                        });

                        this.$emit("imported");
                    })
                    .catch((err) => {
                        this.formLoading = false;
                        if (err.response && err.response.data.errors) {
                            this.formErrors = err.response.data.errors;
                        } else if (err.message) this.formErrors = [err.message];
                        else this.formErrors = ["Une erreur est survenue"];
                    });
            }
        },
        cleanForm() {
            this.formLoading = false;
            this.formErrors = undefined;
            this.file = undefined;
        },
        fileInputChanged(event) {
            if (event.target.files && event.target.files.length > 0) {
                this.file = event.target.files[0];
            }
        },
        closeFromModal: function (e) {
            if (!e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.$emit("close");
            }
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>
