export default {
    booking: {
        services: {
            delete: "Êtes-vous sûr de vouloir supprimer ce jeu ?",
            removeFull: "Ouvrir le jeu aux réservations ?",
            openServiceForDate: 'Le jeu "{service}" sera ouvert aux réservations pour le {date}. Voulez-vous continuer ?',
            openSlotForDate: 'Le créneau de {hour} pour le jeu "{service}" sera ouvert aux réservations pour le {date}. Voulez-vous continuer ?',
        },
    },
};
