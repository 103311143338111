var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "thead",
    [
      _c(
        "ns-th",
        {
          attrs: {
            field: "cc_commands.client",
            width: "6",
            "allow-sorting": "",
          },
        },
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.commons.client"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("feather", { staticClass: "none-desk", attrs: { type: "user" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ns-th",
        {
          attrs: {
            field: "cc_commands.numero",
            width: "6",
            "allow-sorting": "",
          },
        },
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.commons.numero"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "none-desk" }, [_vm._v("N°")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "ns-th",
        {
          attrs: {
            field: "cc_commands.collect_type",
            width: "6",
            "allow-sorting": "",
          },
        },
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.clickAndCollect.collectType"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("feather", { staticClass: "none-desk", attrs: { type: "truck" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ns-th",
        {
          attrs: {
            field: "cc_commands.reservation_date",
            width: "6",
            "allow-sorting": "",
          },
        },
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.clickAndCollect.commandDate"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("feather", {
            staticClass: "none-desk",
            attrs: { type: "calendar" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ns-th",
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.commons.hours"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("feather", { staticClass: "none-desk", attrs: { type: "clock" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ns-th",
        {
          attrs: {
            field: "cc_command_mail_logs.created_at",
            width: "7",
            "allow-sorting": "",
          },
        },
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.commons.sentDate"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("feather", { staticClass: "none-desk", attrs: { type: "send" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ns-th",
        { attrs: { field: "status", width: "18" } },
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.commons.shipmentStatus"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("feather", {
            staticClass: "none-desk",
            attrs: { type: "message-square" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ns-th",
        { attrs: { field: "re_sent", width: "2" } },
        [
          _c("span", {
            staticClass: "none-mobile",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tl(
                  "labels.notifications.listNotificationsLogs.grid.columns.commons.resendMail"
                )
              ),
            },
          }),
          _vm._v(" "),
          _c("feather", { staticClass: "none-desk", attrs: { type: "send" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }