<template>
    <div class="pr-0 pb-5">
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div class="row m-0">
                    <div class="col-12">
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                            <div>
                                <h5 class="title mt-2" v-html="headerText"></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="formErrors" class="alert alert-danger">
                    <p v-for="(error, index) in formErrors" :key="index" v-html="Array.isArray(error) ? error.join('<br/>') : error"></p>
                </div>
                <div v-if="formSuccess" class="alert alert-success">
                    {{ formSuccess }}
                </div>
                <form id="addService" @submit="postForm" method="post">
                    <LoaderComponent v-if="formLoading" />
                    <template v-else>
                        <div class="row m-0">
                            <div class="col-12">
                                <div class="border-light b-radius-20 p-4 mb-3">
                                    <div class="row mb-2">
                                        <div class="col-5">
                                            <label for="name">
                                                {{ $tl("labels.form.name") }} <small>*</small>
                                                <feather
                                                    v-tooltip="getTooltipNotice('notices.booking.servicesConfig.serviceName')"
                                                    type="info"
                                                    class="ml-1 pointer tooltip-infos" />
                                            </label>
                                        </div>
                                        <div class="col-7">
                                            <input
                                                :disabled="!has_right_to_update_service"
                                                type="text"
                                                name="name"
                                                v-model="name"
                                                id="name"
                                                required
                                                class="form-control" />
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-5">
                                            <label for="category">{{ $tl("labels.booking.services.category") }} <small>*</small></label>
                                        </div>
                                        <div class="col-7">
                                            <select
                                                :disabled="!has_right_to_update_service"
                                                name="category"
                                                id="category"
                                                v-model="category_vmodel"
                                                required
                                                class="custom-select">
                                                <option
                                                    v-for="serviceCategory in ALL_DEFAULT_SERVICE_CATEGORIES_WITH_OTHER"
                                                    :key="serviceCategory.value"
                                                    :value="serviceCategory.value">
                                                    {{ getDefaultServiceCategoryLabel(serviceCategory.value) }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mb-2" v-if="category_vmodel === 'autre'">
                                        <div class="col-5 pt-2">
                                            <label for="category"
                                                >{{ $tl("labels.booking.services.categoryName") }} ({{ $tl("labels.langs.fr") }})<small
                                                    >*</small
                                                ></label
                                            >
                                        </div>
                                        <div class="col-7 pt-2">
                                            <input
                                                :disabled="!has_right_to_update_service"
                                                type="text"
                                                name="custom_category_fr"
                                                id="custom_category_fr"
                                                v-model="custom_category_fr"
                                                required
                                                class="form-control" />
                                        </div>
                                        <div class="col-5 pt-2">
                                            <label for="category"
                                                >{{ $tl("labels.booking.services.categoryName") }} ({{ $tl("labels.langs.en") }})<small
                                                    >*</small
                                                ></label
                                            >
                                        </div>
                                        <div class="col-7 pt-2">
                                            <input
                                                :disabled="!has_right_to_update_service"
                                                type="text"
                                                name="custom_category_en"
                                                id="custom_category_en"
                                                v-model="custom_category_en"
                                                required
                                                class="form-control" />
                                        </div>
                                    </div>

                                    <div>
                                        <div>
                                            <div class="row mb-3">
                                                <div class="col-12">
                                                    <strong>
                                                        {{ $tl("labels.booking.services.special.dates") }}
                                                    </strong>
                                                    <feather
                                                        v-tooltip="
                                                            getTooltipNoticeLexicon(
                                                                'notices.booking.servicesConfig.exceptionalOpening',
                                                                restaurant_id
                                                            )
                                                        "
                                                        type="info"
                                                        class="ml-1 pointer tooltip-infos" />
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-5">
                                                    <label>{{ $tl("labels.startDate") }} <small>*</small></label>
                                                </div>
                                                <div class="col-7 date-resa-cal">
                                                    <DatePicker
                                                        v-model="special_date_begin"
                                                        @selected="verifDatesBegin"
                                                        :readonly="!has_right_to_update_service"
                                                        :class="{ disabled: !has_right_to_update_service }"
                                                        :disabled="!has_right_to_update_service" />
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-5">
                                                    <label>{{ $tl("labels.endDate") }} <small>*</small></label>
                                                </div>
                                                <div class="col-7 date-resa-cal">
                                                    <DatePicker
                                                        v-model="special_date_end"
                                                        @selected="verifDatesEnd"
                                                        :readonly="!has_right_to_update_service"
                                                        :class="{ disabled: !has_right_to_update_service }"
                                                        :disabled="!has_right_to_update_service" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-5">
                                            <label>
                                                {{ $tl("labels.booking.services.activeDays") }} <small>*</small>
                                                <feather
                                                    v-tooltip="getTooltipNotice('notices.booking.servicesConfig.activeDays')"
                                                    type="info"
                                                    class="ml-1 pointer tooltip-infos" />
                                            </label>
                                        </div>
                                        <div class="col-7">
                                            <label class="container-box" v-for="englishDay in englishDays()" :key="englishDay">
                                                <input
                                                    type="checkbox"
                                                    name="days[]"
                                                    v-model="days"
                                                    :value="englishDay"
                                                    :readonly="!has_right_to_update_service"
                                                    :class="{ disabled: !has_right_to_update_service }"
                                                    :disabled="!has_right_to_update_service" />
                                                <span
                                                    :class="{ disabled: !has_right_to_update_service }"
                                                    :disabled="!has_right_to_update_service"
                                                    class="checkmark"></span>
                                                {{ $t(`labels.days.${englishDay}`) }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row mb-1">
                                        <div class="col-5 pt-2">
                                            <label>{{ $tl("labels.startHour") }} <small>*</small></label>
                                        </div>
                                        <div class="col-7">
                                            <vue-timepicker
                                                format="HH:mm"
                                                :minute-interval="15"
                                                v-model="hour_begin"
                                                @change="getPossibleSlots"
                                                :class="{ disabled: !has_right_to_update_service }"
                                                :disabled="!has_right_to_update_service"></vue-timepicker>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-5 pt-2">
                                            <label>{{ $tl("labels.endHour") }} <small>*</small></label>
                                        </div>
                                        <div class="col-7">
                                            <vue-timepicker
                                                format="HH:mm"
                                                :minute-interval="15"
                                                v-model="hour_end"
                                                @change="getPossibleSlots"
                                                :class="{ disabled: !has_right_to_update_service }"
                                                :disabled="!has_right_to_update_service"></vue-timepicker>
                                        </div>
                                    </div>
                                    <div class="row mb-2" v-if="possible_slots.length > 0">
                                        <div class="col-5">
                                            <label>
                                                {{ $tl("labels.booking.services.slots") }} <small>*</small>
                                                <feather
                                                    v-tooltip="getTooltipNotice('notices.booking.servicesConfig.reservationSlots')"
                                                    type="info"
                                                    class="ml-1 pointer tooltip-infos" />
                                            </label>
                                        </div>
                                        <div class="col-7">
                                            <div style="margin-right: 13px" class="container-box" v-for="(s, index) in possible_slots" :key="index">
                                                <label style="margin-bottom: 0">
                                                    <input
                                                        @click="slotClicked($event, s)"
                                                        :value="true"
                                                        :checked="!$_.isEmpty(slots[s]) && slots[s].enabled"
                                                        type="checkbox"
                                                        :readonly="!has_right_to_update_service"
                                                        :class="{ disabled: !has_right_to_update_service }"
                                                        :disabled="!has_right_to_update_service" />
                                                    <span
                                                        :class="{ disabled: !has_right_to_update_service }"
                                                        :disabled="!has_right_to_update_service"
                                                        class="checkmark"></span>
                                                    {{ s }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-5">
                                            <label class="mb-0">
                                                {{ $tl("labels.booking.services.maxPax") }} <small>*</small>
                                                <feather
                                                    v-tooltip="getTooltipNotice('notices.booking.servicesConfig.paxMaxPerService')"
                                                    type="info"
                                                    class="ml-1 pointer tooltip-infos" /> </label
                                            ><br />
                                            <small class="text-muted">{{ $tl("infos.booking.services.maxPaxIfRotation") }}</small>
                                        </div>
                                        <div class="col-7">
                                            <div class="row">
                                                <div class="col-4">
                                                    <input
                                                        :disabled="!has_right_to_update_service"
                                                        type="number"
                                                        name="max_pax"
                                                        v-model="max_pax"
                                                        required
                                                        id="max_pax"
                                                        class="form-control"
                                                        min="1"
                                                        step="1" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-5 pt-1">
                                            <label> {{ $tl("labels.booking.services.minPers") }} <small>*</small> </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="d-inline-block align-middle lead-switch pt-1">
                                                        <input
                                                            type="checkbox"
                                                            class="switch align-self-center is-rounded"
                                                            :disabled="!has_right_to_update_service"
                                                            :checked="use_widget_min_pers" />
                                                        <label
                                                            @click="
                                                                use_widget_min_pers = has_right_to_update_service
                                                                    ? !use_widget_min_pers
                                                                    : use_widget_min_pers
                                                            "></label>
                                                        {{ $tl("labels.booking.widget.useDefaultConfig") }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2" v-if="!use_widget_min_pers">
                                                <div class="col-4">
                                                    <input
                                                        type="number"
                                                        v-model="min_pers"
                                                        :required="!use_widget_min_pers"
                                                        :disabled="!has_right_to_update_service"
                                                        class="form-control"
                                                        min="1"
                                                        step="1" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-1">
                                        <div class="col-5 pt-1">
                                            <label> {{ $tl("labels.booking.services.maxPers") }} <small>*</small> </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="d-inline-block align-middle lead-switch pt-1">
                                                        <input
                                                            type="checkbox"
                                                            class="switch align-self-center is-rounded"
                                                            :disabled="!has_right_to_update_service"
                                                            :checked="use_widget_max_pers" />
                                                        <label
                                                            @click="
                                                                use_widget_max_pers = has_right_to_update_service
                                                                    ? !use_widget_max_pers
                                                                    : use_widget_max_pers
                                                            "></label>
                                                        {{ $tl("labels.booking.widget.useDefaultConfig") }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2" v-if="!use_widget_max_pers">
                                                <div class="col-4">
                                                    <input
                                                        type="number"
                                                        v-model="max_pers"
                                                        :required="!use_widget_max_pers"
                                                        :disabled="!has_right_to_update_service"
                                                        class="form-control"
                                                        min="1"
                                                        step="1" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-if="!$_.isEmpty(slots)">
                                        <div class="row mb-2 mt-4">
                                            <div class="col-md-5">
                                                <i18n path="labels.booking.services.enableMaxPax" tag="label">
                                                    <template slot="term">
                                                        <strong>{{ $tl("labels.booking.covers").toLowerCase() }}</strong>
                                                    </template>
                                                </i18n>
                                            </div>
                                            <div class="col-md-7">
                                                <div class="d-inline-block align-middle lead-switch pt-1">
                                                    <input
                                                        type="checkbox"
                                                        class="switch align-self-center is-rounded"
                                                        :checked="is_slot_max_pax_enabled" />
                                                    <label @click="is_slot_max_pax_enabled = !is_slot_max_pax_enabled"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="is_slot_max_pax_enabled == true" class="row mb-2 m-0">
                                            <div class="col-md-12 border-light p-4 mb-4">
                                                <template v-for="slotKey in sortedSlotsKeys">
                                                    <div class="row mb-1" :key="slotKey">
                                                        <div class="col-1">
                                                            <div class="d-inline-block align-middle lead-switch">
                                                                <input
                                                                    type="checkbox"
                                                                    class="switch align-self-center is-small is-rounded"
                                                                    :checked="slots[slotKey].is_max_pax_enabled" />
                                                                <label @click="changeSlotMaxPaxEnabled(slotKey)"></label>
                                                            </div>
                                                        </div>
                                                        <div class="col-1 pt-1">
                                                            {{ slots[slotKey].hour_start }}
                                                        </div>
                                                        <div class="col-3">
                                                            <input
                                                                :disabled="!has_right_to_update_service || !slots[slotKey].is_max_pax_enabled"
                                                                type="number"
                                                                :name="`slot_${slots[slotKey].hour_start}_pax`"
                                                                v-model="slots[slotKey].max_pax"
                                                                class="form-control"
                                                                min="0"
                                                                step="1" />
                                                        </div>
                                                        <div class="col-6 pt-1">
                                                            {{ $tl("labels.booking.services.coversPerSlot") }}
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-5">
                                                <i18n path="labels.booking.services.enableMaxPax" tag="label">
                                                    <template slot="term">
                                                        <strong>{{ $tl("labels.booking.reservations.title").toLowerCase() }}</strong>
                                                    </template>
                                                </i18n>
                                            </div>
                                            <div class="col-md-7">
                                                <div class="d-inline-block align-middle lead-switch pt-1">
                                                    <input
                                                        type="checkbox"
                                                        class="switch align-self-center is-rounded"
                                                        :checked="is_slot_max_reservations_enabled" />
                                                    <label @click="is_slot_max_reservations_enabled = !is_slot_max_reservations_enabled"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="is_slot_max_reservations_enabled == true" class="row mb-2 m-0">
                                            <div class="col-md-12 border-light p-4">
                                                <template v-for="slotKey in sortedSlotsKeys">
                                                    <div class="row mb-1" :key="slotKey">
                                                        <div class="col-1">
                                                            <div class="d-inline-block align-middle lead-switch">
                                                                <input
                                                                    type="checkbox"
                                                                    class="switch align-self-center is-small is-rounded"
                                                                    :checked="slots[slotKey].is_max_reservations_enabled" />
                                                                <label @click="changeSlotMaxReservationsEnabled(slotKey)"></label>
                                                            </div>
                                                        </div>
                                                        <div class="col-1 pt-1">
                                                            {{ slots[slotKey].hour_start }}
                                                        </div>
                                                        <div class="col-3">
                                                            <input
                                                                :disabled="
                                                                    !has_right_to_update_service || !slots[slotKey].is_max_reservations_enabled
                                                                "
                                                                type="number"
                                                                :name="`slot_${slots[slotKey].hour_start}_reservation`"
                                                                v-model="slots[slotKey].max_reservations"
                                                                class="form-control"
                                                                min="0"
                                                                step="1" />
                                                        </div>
                                                        <div class="col-6 pt-1">
                                                            {{ $tl("labels.booking.services.resasPerSlot") }}
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="row mt-4 mb-4">
                                        <div class="col-5">
                                            <label>
                                                {{ $tl("labels.booking.services.takeResas") }} <small>*</small>
                                                <feather
                                                    v-tooltip="getTooltipNotice('notices.booking.servicesConfig.limitReservation')"
                                                    type="info"
                                                    class="ml-1 pointer tooltip-infos" />
                                            </label>
                                        </div>
                                        <div class="col-7">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="radio">
                                                        <label class="container-box">
                                                            <input
                                                                type="radio"
                                                                v-model="lock_reservation_until_enabled"
                                                                :disabled="!has_right_to_update_service"
                                                                :value="false" />
                                                            <span class="checkmark"></span>
                                                            {{ $tl("labels.booking.services.takeResaLive", restaurant_id) }}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 d-inline radio mt-1">
                                                    <label class="container-box">
                                                        <input
                                                            type="radio"
                                                            v-model="lock_reservation_until_enabled"
                                                            :disabled="!has_right_to_update_service"
                                                            :value="true" />
                                                        <span class="checkmark"></span>
                                                        {{ $tl("labels.booking.services.blockResas") }}
                                                    </label>

                                                    <input
                                                        type="number"
                                                        class="form-control d-inline"
                                                        style="width: 70px"
                                                        name="lock_reservation_until"
                                                        v-model="lock_reservation_until"
                                                        :disabled="!lock_reservation_until_enabled || !has_right_to_update_service"
                                                        id="lock_reservation_until" />
                                                    {{ $tl("labels.booking.services.minsBeforeStart", restaurant_id) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-5 pt-2">
                                            <label> {{ $tl("labels.booking.services.enableOnWidget") }} <small>*</small> </label>
                                        </div>
                                        <div class="col-7">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="d-inline-block align-middle lead-switch mt-1 pt-2">
                                                        <input
                                                            type="checkbox"
                                                            class="switch align-self-center is-rounded"
                                                            :disabled="!has_right_to_update_service"
                                                            :checked="show_on_widget" />
                                                        <label
                                                            @click="
                                                                show_on_widget = has_right_to_update_service ? !show_on_widget : show_on_widget
                                                            "></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-5 pt-2">
                                            <label>
                                                {{ $tl("labels.booking.services.cancelationDelay") }} <small>*</small>
                                                <feather
                                                    v-tooltip="getTooltipNotice('notices.booking.servicesConfig.cancellationPeriod')"
                                                    type="info"
                                                    class="ml-1 pointer tooltip-infos" />
                                            </label>
                                        </div>
                                        <div class="col-7">
                                            <div class="row">
                                                <div class="col-4">
                                                    <input
                                                        :disabled="!has_right_to_update_service"
                                                        type="number"
                                                        v-model="cancellation_until"
                                                        class="form-control" />
                                                </div>
                                                <div class="col-8 mt-2">
                                                    {{ $tl("labels.booking.services.hoursBeforeResa") }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-2 mt-4">
                                        <div class="col-5 pt-2">
                                            <label>
                                                {{ $tl("labels.booking.services.resasConfirm") }} <small>*</small>
                                                <feather
                                                    v-tooltip="getTooltipNotice('notices.booking.servicesConfig.reservationConfirm')"
                                                    type="info"
                                                    class="ml-1 pointer tooltip-infos" />
                                            </label>
                                        </div>
                                        <div class="col-7 radio w-100 h-100 d-table pt-2">
                                            <div class="d-table-cell align-middle">
                                                <label class="container-box">
                                                    <input
                                                        :disabled="!has_right_to_update_service"
                                                        type="radio"
                                                        v-model="autoconfirm"
                                                        true-value="0"
                                                        value="0" />
                                                    <span class="checkmark"></span> {{ $tl("labels.manual") }}
                                                </label>
                                                <label class="container-box">
                                                    <input
                                                        :disabled="!has_right_to_update_service"
                                                        type="radio"
                                                        v-model="autoconfirm"
                                                        true-value="1"
                                                        value="1" />
                                                    <span class="checkmark"></span> {{ $tl("labels.auto") }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-if="isAutoconfirm">
                                        <div class="row mb-2">
                                            <div class="col-4 pt-2 offset-1">
                                                <label>
                                                    {{ $tl("labels.to") }}
                                                </label>
                                            </div>
                                            <div class="col-7">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <input
                                                            type="number"
                                                            v-model="autoconfirm_percent"
                                                            class="form-control"
                                                            :disabled="autoconfirm == 0 || !has_right_to_update_service" />
                                                    </div>
                                                    <div class="col-8 mt-2">
                                                        {{ $tl("labels.booking.services.percentFillRate") }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-4 pt-2 offset-1">
                                                <label>
                                                    {{ $tl("labels.to") }}
                                                </label>
                                            </div>
                                            <div class="col-7">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <input
                                                            type="number"
                                                            v-model="autoconfirm_max_pers"
                                                            class="form-control"
                                                            :disabled="autoconfirm == 0 || !has_right_to_update_service" />
                                                    </div>
                                                    <div class="col-8 mt-2">
                                                        {{ $tl("labels.personss") }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-4 pt-2 offset-1">
                                                <label>
                                                    {{ $tl("labels.to") }}
                                                </label>
                                            </div>
                                            <div class="col-7">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <input
                                                            type="number"
                                                            v-model="autoconfirm_until"
                                                            class="form-control"
                                                            :disabled="autoconfirm == 0 || !has_right_to_update_service" />
                                                    </div>
                                                    <div class="col-8 mt-2">
                                                        {{ $tl("labels.booking.services.minsBeforeSlotStarts") }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-4 offset-1">
                                                <label> {{ $tl("labels.booking.services.blockAutoConfirm") }}<br /> </label>
                                            </div>
                                            <div class="col-7">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <input
                                                            type="number"
                                                            v-model="lock_nb_noshow"
                                                            class="form-control"
                                                            :disabled="autoconfirm == 0 || !has_right_to_update_service" />
                                                    </div>
                                                    <div class="col-8 mt-2">
                                                        {{ capitalize($tl("labels.clients.noshow"))
                                                        }}<small> - {{ $tl("infos.booking.services.blockAutoConfirm") }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <div class="row mb-2 mt-4">
                                        <div class="col-5 pt-2">
                                            <label>
                                                {{ $tl("labels.booking.services.enableTableRotation", restaurant_id) }}
                                                <feather
                                                    v-tooltip="getTooltipNoticeLexicon('notices.booking.servicesConfig.tableRotation', restaurant_id)"
                                                    type="info"
                                                    class="ml-1 pointer tooltip-infos" />
                                            </label>
                                        </div>
                                        <div class="col-7 w-100 h-100 d-table pt-2">
                                            <div class="d-table-cell align-middle">
                                                <label class="container-box">
                                                    <input
                                                        :disabled="!has_right_to_update_service"
                                                        type="checkbox"
                                                        name="enable_table_rotation"
                                                        v-model="paxes.rotation" />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="paxes.rotation">
                                        <div class="row">
                                            <div class="col-12 pt-1">
                                                <label>{{ $tl("labels.booking.services.averageTimeAtTable") }}</label>
                                            </div>
                                        </div>
                                        <template v-for="(pax, index) in paxes.value">
                                            <div class="row" v-if="pax.pax <= 10" :key="index">
                                                <div class="offset-3 col-4 pt-1">{{ pax.pax === 10 ? "+" : "" }}{{ pax.pax }}</div>
                                                <div class="col-5 pt-1">
                                                    <vue-timepicker
                                                        :disabled="!has_right_to_update_service"
                                                        v-if="pax.pax < 10"
                                                        format="HH:mm"
                                                        :minute-interval="15"
                                                        v-model="pax.duration"></vue-timepicker>
                                                    <vue-timepicker
                                                        :disabled="!has_right_to_update_service"
                                                        v-else
                                                        @change="setPaxesHigherThanTen($event)"
                                                        :minute-interval="15"
                                                        v-model="pax.duration"></vue-timepicker>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <template v-if="hasFeatSeatingPlan">
                                        <div class="row mb-2 mt-2">
                                            <div class="col-5 pt-2">
                                                <label>
                                                    {{ $tl("labels.booking.seatingPlan.title") }}
                                                </label>
                                            </div>
                                            <div class="col-7 w-100 h-100 d-table pt-2">
                                                <div>
                                                    <select
                                                        class="custom-select"
                                                        v-model="seatingPlan.value"
                                                        :disabled="!this.hasSeatingPlan"
                                                        v-tooltip="
                                                            !this.hasSeatingPlan
                                                                ? {
                                                                      content: $t('infos.booking.services.enableSeatingPlan'),
                                                                  }
                                                                : null
                                                        ">
                                                        <option :value="null">--</option>
                                                        <option v-for="seatingPlan in seatingPlans" :key="seatingPlan.id" :value="seatingPlan">
                                                            {{ seatingPlan.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <small class="d-block text-muted" v-if="seatingPlan.algorithm_enabled && seatingPlan.hasChanged">
                                                    <span
                                                        v-for="(tmpMsg, index) in $tl('infos.booking.services.changeSeatingPlan', restaurant_id)"
                                                        :key="index"
                                                        >{{ tmpMsg }}<br
                                                    /></span>
                                                </small>
                                            </div>
                                        </div>
                                        <div class="row mb-2 mt-2" v-if="seatingPlan.value">
                                            <div class="col-5 pt-2">
                                                <label>
                                                    {{ $tl("labels.booking.services.enableAlgorithm", restaurant_id) }}
                                                    <feather
                                                        v-tooltip="
                                                            getTooltipNoticeLexicon(
                                                                'notices.booking.servicesConfig.automaticSeatingPlan',
                                                                restaurant_id
                                                            )
                                                        "
                                                        type="info"
                                                        class="ml-1 pointer tooltip-infos" />
                                                </label>
                                            </div>
                                            <div class="col-7 w-100 h-100 d-table pt-2">
                                                <div class="d-table-cell align-middle">
                                                    <label
                                                        class="container-box"
                                                        v-tooltip="
                                                            !this.hasSeatingPlan
                                                                ? {
                                                                      content: $t('infos.booking.services.enableSeatingPlan'),
                                                                  }
                                                                : null
                                                        ">
                                                        <input
                                                            type="checkbox"
                                                            v-model="seatingPlan.algorithm_enabled"
                                                            :disabled="!this.hasSeatingPlan || !has_right_to_update_service" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-2 mt-2" v-if="seatingPlan.algorithm_enabled">
                                            <div class="col-5 pt-2">
                                                <label>
                                                    {{ $tl("labels.booking.services.allowClientsChooseRoom") }}
                                                </label>
                                            </div>
                                            <div class="col-7 w-100 h-100 d-table pt-2">
                                                <div class="d-table-cell align-middle">
                                                    <label class="container-box">
                                                        <input type="checkbox" v-model="seatingPlan.allow_clients_choose_room" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="radio" v-if="seatingPlan.allow_clients_choose_room">
                                                    <div class="d-table-cell align-middle">
                                                        <label class="container-box">
                                                            <input
                                                                :disabled="!has_right_to_update_service"
                                                                type="radio"
                                                                v-model="seatingPlan.allow_clients_choose_room_mandatory"
                                                                value="0" />
                                                            <span class="checkmark"></span> {{ $tl("labels.optional") }}
                                                        </label>
                                                        <label class="container-box">
                                                            <input
                                                                :disabled="!has_right_to_update_service"
                                                                type="radio"
                                                                v-model="seatingPlan.allow_clients_choose_room_mandatory"
                                                                value="1" />
                                                            <span class="checkmark"></span> {{ $tl("labels.mandatory") }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="row mb-2 mt-2">
                                        <div class="col-5">
                                            <label>
                                                {{ $tl("labels.booking.menus.externalMenu.externalMenu", restaurant_id) }}
                                                <feather
                                                    v-tooltip="getTooltipNotice('notices.booking.externalMenus')"
                                                    type="info"
                                                    class="ml-1 pointer tooltip-infos" />
                                            </label>
                                        </div>
                                        <div class="col-7">
                                            <div class="radio">
                                                <RadioButtonComponent v-model="externalMenu.type" :inputValue="EXTERNAL_MENU_FILE.value">
                                                    {{ $tl("labels.booking.menus.externalMenu.file.pdf") }}
                                                </RadioButtonComponent>
                                                <RadioButtonComponent v-model="externalMenu.type" :inputValue="EXTERNAL_MENU_LINK.value">
                                                    {{ $tl("labels.booking.menus.externalMenu.link.link") }}
                                                </RadioButtonComponent>
                                                <RadioButtonComponent v-model="externalMenu.type" :inputValue="null">
                                                    {{ $tl("labels.booking.menus.externalMenu.disabled") }}
                                                </RadioButtonComponent>
                                            </div>
                                            <div v-if="externalMenu.type === EXTERNAL_MENU_FILE.value" class="my-2">
                                                <FileUploaderComponent
                                                    :hasDirectUpload="false"
                                                    suffixId="ext-desc"
                                                    @upload-file="externalMenu.rawFile = $event"
                                                    :src="externalMenu.pathFile"
                                                    :deleteURI="deleteURIFile"
                                                    :max-size="20"
                                                    :props-file="externalMenu.rawFile"
                                                    :call-back-delete="
                                                        () => {
                                                            externalMenu.pathFile = '';
                                                            externalMenu.rawFile = null;
                                                            externalMenu.content.file = '';
                                                        }
                                                    " />
                                            </div>
                                            <div v-if="externalMenu.type === EXTERNAL_MENU_LINK.value" class="my-2">
                                                <InputComponent
                                                    v-model="externalMenu.content.link"
                                                    label=""
                                                    :placeholder="$t('labels.booking.menus.externalMenu.link.placeholder')"
                                                    labelWrapperClasses="d-none"
                                                    type="url" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-12">
                                <div class="border-light b-radius-20 p-4 mb-3">
                                    <div class="row" style="cursor: pointer" @click="showMenuOption = !showMenuOption">
                                        <div class="col-12">
                                            <strong>{{ $tl("labels.booking.services.menusAndOptions.title", $route.params.restaurant_id) }}</strong>
                                        </div>
                                    </div>
                                    <div class="menu-option mt-3" v-if="showMenuOption">
                                        <div class="row mb-3 pb-3">
                                            <div class="col-3">
                                                <label>{{ $tl("labels.booking.services.menusAndOptions.link", $route.params.restaurant_id) }}</label>
                                            </div>
                                            <div class="col-9">
                                                <div v-if="available_menus.length > 0">
                                                    <div class="mb-2" v-for="menu in available_menus" :key="menu.id">
                                                        <label class="container-box" style="margin-bottom: 0; width: 100%">
                                                            <input
                                                                type="checkbox"
                                                                :disabled="!has_right_to_update_service"
                                                                name="menus[]"
                                                                @change="changedMenu($event, menu)"
                                                                :checked="menus[`id_${menu.id}`] && menus[`id_${menu.id}`].selected" />
                                                            <span class="checkmark"></span> {{ menu.name }}
                                                        </label>
                                                        <div class="mt-1" v-if="menus[`id_${menu.id}`] && menus[`id_${menu.id}`].selected">
                                                            <div class="row mb-0">
                                                                <div class="col-5 offset-1">
                                                                    <label class="container-box" style="width: 30px">
                                                                        <input
                                                                            :disabled="!has_right_to_update_service"
                                                                            type="checkbox"
                                                                            true-value="1"
                                                                            v-model="menus[`id_${menu.id}`].enable_nb_min_pers" />
                                                                        <span style="margin-top: -2px" class="checkmark"></span>
                                                                    </label>
                                                                    <label class="pt-2">{{
                                                                        $tl(
                                                                            "labels.booking.services.menusAndOptions.enableFrom",
                                                                            $route.params.restaurant_id
                                                                        )
                                                                    }}</label>
                                                                </div>
                                                                <div class="col-6 d-flex align-items-center">
                                                                    <input
                                                                        style="width: 30%"
                                                                        type="number"
                                                                        step="1"
                                                                        min="0"
                                                                        name="nb_pers_min"
                                                                        id="nb_pers_min"
                                                                        class="form-control"
                                                                        v-model="menus[`id_${menu.id}`].nb_min_pers"
                                                                        :disabled="
                                                                            !menus[`id_${menu.id}`].enable_nb_min_pers || !has_right_to_update_service
                                                                        " />
                                                                    <p class="m-0 p-0 ml-2">
                                                                        {{ capitalize($tl("labels.persons")) }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <div class="col-5 offset-1">
                                                                    <label class="container-box" style="width: 30px">
                                                                        <input
                                                                            :disabled="!has_right_to_update_service"
                                                                            type="checkbox"
                                                                            true-value="1"
                                                                            v-model="menus[`id_${menu.id}`].enable_nb_max_pers" />
                                                                        <span style="margin-top: -2px" class="checkmark"></span>
                                                                    </label>
                                                                    <label class="pt-2">{{
                                                                        $tl(
                                                                            "labels.booking.services.menusAndOptions.disableFrom",
                                                                            $route.params.restaurant_id
                                                                        )
                                                                    }}</label>
                                                                </div>
                                                                <div class="col-6 d-flex align-items-center">
                                                                    <input
                                                                        style="width: 30%"
                                                                        type="number"
                                                                        step="1"
                                                                        min="0"
                                                                        name="nb_pers_max"
                                                                        id="nb_pers_max"
                                                                        class="form-control"
                                                                        v-model="menus[`id_${menu.id}`].nb_max_pers"
                                                                        :disabled="
                                                                            !menus[`id_${menu.id}`].enable_nb_max_pers || !has_right_to_update_service
                                                                        " />
                                                                    <p class="m-0 p-0 ml-2">
                                                                        {{ capitalize($tl("labels.persons")) }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-4 mb-4">
                                                    <button
                                                        type="button"
                                                        class="btn btn-success btn-circle btn-sm"
                                                        :class="{ disabled: !has_right_to_create_menu }"
                                                        :disabled="!has_right_to_create_menu"
                                                        @click="showAddMenuModal = true">
                                                        {{ $tl("labels.booking.menus.add", restaurant_id) }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-if="!isMenusEmpty">
                                            <div class="row mb-0">
                                                <div class="col-5">
                                                    <label>
                                                        {{ $tl("labels.booking.menus.mandatory", restaurant_id) }}
                                                        <feather
                                                            v-tooltip="getTooltipNotice('notices.booking.servicesConfig.mandatoryMenu')"
                                                            type="info"
                                                            class="ml-1 pointer tooltip-infos" />
                                                    </label>
                                                </div>
                                                <div class="col-7">
                                                    <label class="container-box" style="width: 100%">
                                                        <input
                                                            type="checkbox"
                                                            name="menu_unique"
                                                            v-model="menu_mandatory"
                                                            :value="true"
                                                            :disabled="isMenusEmpty || !has_right_to_update_service"
                                                            :readonly="isMenusEmpty || !has_right_to_update_service" />
                                                        <span class="checkmark"></span> {{ $tl("labels.yes") }}
                                                    </label>
                                                </div>
                                            </div>
                                            <div v-if="menu_mandatory" class="row mb-4">
                                                <div class="col-5 pt-2">
                                                    <label>{{ $tl("labels.booking.menus.mandatoryFrom", restaurant_id) }}</label>
                                                </div>
                                                <div class="col-7">
                                                    <div class="row">
                                                        <div class="col-4">
                                                            <input
                                                                type="number"
                                                                name="menu_mandatory_from"
                                                                v-model="menu_mandatory_from"
                                                                class="form-control"
                                                                min="1"
                                                                step="1"
                                                                :disabled="isMenusEmpty || !has_right_to_update_service || !menu_mandatory" />
                                                        </div>
                                                        <div class="col-8 mt-2">
                                                            {{ $tl("labels.personss") }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-0">
                                                <div class="col-5">
                                                    <label>
                                                        {{ $tl("labels.booking.menus.unique", restaurant_id) }}
                                                        <feather
                                                            v-tooltip="
                                                                getTooltipNoticeLexicon('notices.booking.servicesConfig.uniqueMenu', restaurantId)
                                                            "
                                                            type="info"
                                                            class="ml-1 pointer tooltip-infos" />
                                                    </label>
                                                </div>
                                                <div class="col-7">
                                                    <label class="container-box" style="width: 100%">
                                                        <input
                                                            type="checkbox"
                                                            name="menu_unique"
                                                            v-model="menu_unique"
                                                            true-value="1"
                                                            :disabled="isMenusEmpty || !has_right_to_update_service" />
                                                        <span class="checkmark"></span> {{ $tl("labels.yes") }}
                                                    </label>
                                                </div>
                                            </div>
                                            <div v-if="menu_unique" class="row mb-4">
                                                <div class="col-5 pt-2">
                                                    <label>{{ $tl("labels.booking.menus.uniqueFrom", restaurant_id) }}</label>
                                                </div>
                                                <div class="col-7">
                                                    <div class="row">
                                                        <div class="col-4">
                                                            <input
                                                                type="number"
                                                                name="menu_unique_from"
                                                                v-model="menu_unique_from"
                                                                class="form-control"
                                                                min="1"
                                                                step="1"
                                                                :disabled="isMenusEmpty || !has_right_to_update_service || !menu_unique" />
                                                        </div>
                                                        <div class="col-8 mt-2">
                                                            {{ $tl("labels.personss") }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <div class="sepa"></div>
                                        <div class="row mt-4">
                                            <div class="col-3">
                                                <label>{{ $tl("labels.booking.services.generalOptions.link") }}</label>
                                            </div>
                                            <div class="col-9">
                                                <div v-if="general_options.length > 0">
                                                    <div class="mb-2" v-for="(option, index) in general_options" :key="index">
                                                        <label class="container-box" style="margin-bottom: 0; width: 100%">
                                                            <input
                                                                :disabled="!has_right_to_update_service"
                                                                type="checkbox"
                                                                name="general_options_selected[]"
                                                                v-model="general_options_selected"
                                                                :value="option.id" />
                                                            <span class="checkmark"></span> {{ option.name }}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="mt-4 mb-4">
                                                    <button
                                                        type="button"
                                                        class="btn btn-success btn-circle btn-sm"
                                                        :class="{ disabled: !has_right_to_create_menu }"
                                                        :disabled="!has_right_to_create_menu"
                                                        @click="showAddGeneralOptionModal = true">
                                                        {{ $tl("labels.booking.generalOptions.add") }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row m-0">
                            <div class="col-12">
                                <div class="border-light b-radius-20 p-4 mb-3">
                                    <div class="row" style="cursor: pointer" @click="showEbPaiement = !showEbPaiement">
                                        <div class="col-12">
                                            <strong>{{ $tl("labels.booking.services.footprintAndPayment.title") }}</strong>
                                        </div>
                                    </div>
                                    <div class="menu-option mt-3" v-if="showEbPaiement">
                                        <overlay-layout :display-overlay="!hasPaymentSystem">
                                            <template v-slot:content>
                                                <div class="row border-bottom mb-2">
                                                    <div class="col-12">
                                                        <div class="row mb-2">
                                                            <div class="col-5">
                                                                <label>
                                                                    {{ $tl("labels.booking.services.footprint.enable") }}
                                                                    <feather
                                                                        v-tooltip.right="
                                                                            getTooltipNoticeLexicon(
                                                                                'notices.booking.servicesConfig.bankFootprint',
                                                                                restaurant_id,
                                                                                'click',
                                                                                true,
                                                                                'tooltip-custom-width'
                                                                            )
                                                                        "
                                                                        type="info"
                                                                        class="ml-1 pointer tooltip-infos" />
                                                                </label>
                                                            </div>
                                                            <div class="col-7">
                                                                <label class="container-box">
                                                                    <input
                                                                        :disabled="!has_right_to_update_service"
                                                                        type="checkbox"
                                                                        v-model="enable_footprint"
                                                                        true-value="1" />
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <template v-if="enable_footprint">
                                                            <div class="row mb-2">
                                                                <div class="col-5 pt-2">
                                                                    <label>{{ capitalize($tl("labels.from")) }}</label>
                                                                </div>
                                                                <div class="col-7">
                                                                    <div class="row">
                                                                        <div class="col-5">
                                                                            <input
                                                                                type="text"
                                                                                v-model="footprint_min_pers"
                                                                                v-bind:disabled="!enable_footprint || !has_right_to_update_service"
                                                                                class="form-control"
                                                                                v-bind:class="{
                                                                                    disabled: !enable_footprint || !has_right_to_update_service,
                                                                                }" />
                                                                        </div>
                                                                        <div class="col-7 mt-2">
                                                                            {{ $tl("labels.persons") }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-5">
                                                                    <label>
                                                                        {{ $tl("labels.booking.services.footprint.amountToCapture", restaurant_id) }}
                                                                    </label>
                                                                </div>
                                                                <div class="col-7">
                                                                    <div class="row">
                                                                        <div class="col-5">
                                                                            <input
                                                                                type="text"
                                                                                v-model="amount_noshow"
                                                                                v-bind:disabled="!enable_footprint || !has_right_to_update_service"
                                                                                class="form-control"
                                                                                v-bind:class="{
                                                                                    disabled: !enable_footprint || !has_right_to_update_service,
                                                                                }" />
                                                                        </div>
                                                                        <div class="col-7 mt-2">
                                                                            {{ $tl("labels.euros") }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <div v-if="(hasPaymentSystem && !isMenusEmpty) || !isGeneralOptionsEmpty" class="sepa"></div>
                                                    <div class="col-12 mb-2 mt-2">
                                                        <template v-if="(hasPaymentSystem && !isMenusEmpty) || !isGeneralOptionsEmpty">
                                                            <div class="row mb-2">
                                                                <div class="col-5">
                                                                    <label>
                                                                        {{ $tl("labels.booking.services.payment.enable") }}
                                                                        <feather
                                                                            v-tooltip="
                                                                                getTooltipNoticeLexicon(
                                                                                    'notices.booking.servicesConfig.prepayment',
                                                                                    restaurant_id
                                                                                )
                                                                            "
                                                                            type="info"
                                                                            class="ml-1 pointer tooltip-infos" />
                                                                    </label>
                                                                </div>
                                                                <div class="col-7">
                                                                    <label class="container-box" style="width: 100%">
                                                                        <input
                                                                            :disabled="!has_right_to_update_service"
                                                                            v-on:click="checkIfStripeEnabled"
                                                                            type="checkbox"
                                                                            name="prepayment_enabled"
                                                                            v-model="prepayment_enabled"
                                                                            true-value="1" />
                                                                        <stripe-connect-modal
                                                                            v-if="showStripeConnectModal && has_right_to_update_subscription"
                                                                            @close="showStripeConnectModal = false"
                                                                            v-on:error="stripeConnectModalError"
                                                                            v-on:success="stripeConnectModalSuccess" />
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                <template v-if="prepayment_enabled">
                                                                    <div class="col-5">
                                                                        <label>{{ $tl("labels.booking.services.payment.is") }} </label>
                                                                    </div>
                                                                    <div class="col-6 d-table w-100 h-100 pt-2">
                                                                        <div class="d-table-cell align-middle">
                                                                            <div class="slot_btn_select radio">
                                                                                <label class="container-box">
                                                                                    <input
                                                                                        v-bind:disabled="
                                                                                            !prepayment_enabled || !has_right_to_update_service
                                                                                        "
                                                                                        v-bind:class="{
                                                                                            disabled:
                                                                                                !prepayment_enabled || !has_right_to_update_service,
                                                                                        }"
                                                                                        type="radio"
                                                                                        v-model="prepayment_mandatory"
                                                                                        value="1" />
                                                                                    <span class="checkmark"></span>
                                                                                    {{ $tl("labels.mandatory") }}
                                                                                </label>
                                                                                <label class="container-box">
                                                                                    <input
                                                                                        v-bind:disabled="
                                                                                            !prepayment_enabled || !has_right_to_update_service
                                                                                        "
                                                                                        v-bind:class="{
                                                                                            disabled:
                                                                                                !prepayment_enabled || !has_right_to_update_service,
                                                                                        }"
                                                                                        type="radio"
                                                                                        v-model="prepayment_mandatory"
                                                                                        value="0" />
                                                                                    <span class="checkmark"></span>
                                                                                    {{ $tl("labels.optional") }}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                            </div>
                                                            <template v-if="prepayment_enabled">
                                                                <div v-if="prepayment_mandatory !== null" class="row mb-2">
                                                                    <div class="col-5">
                                                                        <label>{{ $tl("labels.booking.services.payment.amountToCapture") }}</label>
                                                                    </div>
                                                                    <div class="col-7">
                                                                        <div class="row">
                                                                            <div class="col-5">
                                                                                <input
                                                                                    type="number"
                                                                                    name="prepayment_percent"
                                                                                    v-model="prepayment_percent"
                                                                                    v-bind:disabled="
                                                                                        !prepayment_enabled || !has_right_to_update_service
                                                                                    "
                                                                                    class="form-control"
                                                                                    v-bind:class="{
                                                                                        disabled: !prepayment_enabled || !has_right_to_update_service,
                                                                                    }"
                                                                                    min="1"
                                                                                    step="1" />
                                                                            </div>
                                                                            <div class="col-7 mt-2">%</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div v-if="prepayment_mandatory == 1" class="row mb-2">
                                                                    <div class="col-5 pt-2">
                                                                        <label>{{ capitalize($tl("labels.from")) }}</label>
                                                                    </div>
                                                                    <div class="col-7">
                                                                        <div class="row">
                                                                            <div class="col-5">
                                                                                <input
                                                                                    type="text"
                                                                                    v-model="prepayment_min_pers"
                                                                                    v-bind:disabled="
                                                                                        !prepayment_mandatory || !has_right_to_update_service
                                                                                    "
                                                                                    class="form-control"
                                                                                    v-bind:class="{
                                                                                        disabled:
                                                                                            !prepayment_mandatory || !has_right_to_update_service,
                                                                                    }" />
                                                                            </div>
                                                                            <div class="col-7 mt-2">
                                                                                {{ $tl("labels.personss") }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="row mt-3 radio">
                                                    <div class="col-12 row">
                                                        <div class="col-6 pt-2">
                                                            <label>
                                                                {{
                                                                    $tl("labels.booking.services.footprint.autoCancelation", restaurant_id, {
                                                                        shortDelay: optionBankShortDelay,
                                                                    })
                                                                }}
                                                                <feather
                                                                    v-tooltip="
                                                                        getTooltipNotice(
                                                                            'notices.booking.servicesConfig.bookingCancellationWithoutFootprint'
                                                                        )
                                                                    "
                                                                    type="info"
                                                                    class="ml-1 pointer tooltip-infos" />
                                                            </label>
                                                        </div>
                                                        <div class="col-6 d-table w-100 h-100 pt-2">
                                                            <div class="d-table-cell align-middle">
                                                                <label class="container-box">
                                                                    <input
                                                                        :disabled="!has_right_to_update_service"
                                                                        type="radio"
                                                                        v-model="option_bank_auto_cancel"
                                                                        value="0" />
                                                                    <span class="checkmark"></span> Manuelle
                                                                </label>

                                                                <label class="container-box">
                                                                    <input
                                                                        :disabled="!has_right_to_update_service"
                                                                        type="radio"
                                                                        v-model="option_bank_auto_cancel"
                                                                        value="1" />
                                                                    <span class="checkmark"></span> Automatique
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <send-reminder-payment v-model="sendReminderPayment" :cancel-auto="option_bank_auto_cancel" />
                                            </template>
                                            <template v-slot:overlay>
                                                <div class="is-white text-center d-table">
                                                    <h6 class="d-table-cell align-middle">
                                                        {{ $tl("infos.common.needStripeAccount") }}
                                                    </h6>
                                                </div>
                                            </template>
                                        </overlay-layout>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row m-0 mb-3" v-if="hasEnabledGoogleReserve && isReserveWithGoogleEnabled()">
                            <div class="col-12">
                                <google-reserve :settings="googleReserveSettings" expandable for-services></google-reserve>
                            </div>
                        </div>

                        <div class="row m-0" v-if="restaurants.length > 0">
                            <div class="col-12">
                                <div class="border-light b-radius-20 p-4 mb-3">
                                    <div class="row" style="cursor: pointer" @click="showIfComplet = !showIfComplet">
                                        <div class="col-12">
                                            <strong>{{ $tl("labels.booking.services.inCaseFull", restaurant_id) }}</strong>
                                        </div>
                                    </div>
                                    <div class="menu-option mt-3" v-if="showIfComplet">
                                        <div v-if="restaurants.length > 0" class="row">
                                            <div class="col-6">
                                                <label>
                                                    {{ $tl("labels.booking.services.showRestaurantService", restaurant_id) }}
                                                    <feather
                                                        v-tooltip="getTooltipNotice('notices.booking.servicesConfig.restaurantProposal')"
                                                        type="info"
                                                        class="ml-1 pointer tooltip-infos" />
                                                </label>
                                            </div>
                                            <div class="col-6">
                                                <div v-if="restaurants.length > 0">
                                                    <div class="mb-2" v-for="restau in restaurants" :key="restau.id">
                                                        <label
                                                            v-if="restau.id !== $route.params.restaurant_id"
                                                            class="container-box"
                                                            style="margin-bottom: 0; width: 100%">
                                                            <input
                                                                :disabled="!has_right_to_update_service"
                                                                type="checkbox"
                                                                name="alternative_restaurants[]"
                                                                v-model="alternative_restaurants"
                                                                :value="restau.id" />
                                                            <span class="checkmark"></span> {{ restau.name }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row m-0">
                            <div class="col-12">
                                <input
                                    :disabled="!has_right_to_update_service || formLoading"
                                    type="submit"
                                    name="submit"
                                    :value="$t('labels.form.actions.save')"
                                    class="btn btn-success btn-circle" />
                            </div>
                        </div>
                    </template>
                </form>
            </div>
            <addMenuModal v-if="showAddMenuModal" @close="showAddMenuModal = false" @menu-added="loadMenus(false, true)" />
            <add-menu-option-modal
                v-if="showAddGeneralOptionModal"
                :option-type="MENU_OPTION_GENERAL.value"
                @close="showAddGeneralOptionModal = false"
                @menu-option-added="loadGeneralOptions(true)" />
        </div>
        <no-seating-plan-modal v-if="showNoSeatingPlanModal" closeButtonTitle="Retour" @close="showNoSeatingPlanModal = false" />
        <ComfirmDeleteSlotsModal
            v-if="showConfirmDeleteSlotsModal"
            :headerText="headerText"
            :reservations="futuresReservations"
            :loading="formLoading"
            @confirmed-without-cancel="sendForm(null, true)"
            @confirmed="cancelReservations"
            @close="showConfirmDeleteSlotsModal = false" />
        <confirm-opening-create-modal
            v-if="showConfirmModal"
            @close="showConfirmModal = false"
            @confirmed="sendForm(null, false)"
            :formLoading="formLoading"
            :date_begin="special_date_begin"
            :date_end="special_date_end"
            :servicesId="showConfirmModalServicesId"
            :cancelData="confirmModalData" />
    </div>
</template>

<script>
import axios from "axios";
import VueTimepicker from "vue2-timepicker";
import DatePicker from "../../../../components/forms/DatePicker.vue";
import OverlayLayout from "../../../../components/layouts/OverlayLayout.vue";
import LoaderComponent from "../../../../components/LoaderComponent.vue";
import _ from "lodash";
import NoSeatingPlanModal from "../../../../components/Modals/seatingPlan/NoSeatingPlanModal.vue";
import ComfirmDeleteSlotsModal from "../../../../components/Modals/ConfirmDeleteSlotsModal";
import addMenuModal from "../../../../components/Modals/menus/addMenuModal";
import addMenuOptionModal from "../../../../components/Modals/menuOptions/addMenuOptionModal.vue";
import ConfirmOpeningCreateModal from "../../../../components/Modals/openings/ConfirmOpeningCreateModal";
import DefaultServiceCategoriesEnum from "../../../../mixins/enums/booking/DefaultServiceCategoriesEnum";
import MenuOptionTypeEnum from "../../../../mixins/enums/booking/MenuOptionTypeEnum.js";
import RadioButtonComponent from "../../../../components/forms/RadioButtonComponent.vue";
import ExternalMenuTypeEnum from "../../../../mixins/enums/booking/ExternalMenuTypeEnum.js";
import FileUploaderComponent from "../../../../components/forms/FileUploaderComponent.vue";
import InputComponent from "../../../../components/forms/InputComponent.vue";
import LexiconEnum from "../../../../mixins/enums/LexiconEnum.js";
import googleReserve from "../../../../components/services/googleReserve.vue";
import SendReminderPayment from "./Forms/SendReminderPayment.vue";

export default {
    data() {
        return {
            loading: false,
            formLoading: false,
            error: null,
            formErrors: null,
            formSuccess: null,
            service_id: null,
            name: null,
            category: null,
            days: [],
            raw_hour_begin: null,
            hour_begin: { HH: "HH", mm: "mm" },
            raw_hour_end: null,
            hour_end: { HH: "HH", mm: "mm" },
            max_pax: null,
            lock_reservation_until_enabled: false,
            lock_reservation_until: 0,
            special: 1,
            special_date_begin_: null,
            special_date_end_: null,
            menu_mandatory_from: null,
            menu_mandatory: false,
            menu_unique: null,
            menu_unique_from: null,
            autoconfirm: 0,
            autoconfirm_percent: 80,
            autoconfirm_max_pers: 10,
            autoconfirm_until: 1,
            cancellation_until: 24,
            enable_footprint: 0,
            footprint_min_pers: 1,
            amount_noshow: 20,
            prepayment_min_pers: null,
            prepayment_mandatory: null,
            prepayment_enabled: null,
            prepayment_percent: null,
            option_bank_auto_cancel: 0,
            menus: {},
            available_menus: [],
            general_options: {},
            general_options_selected: [],
            possible_slots: [],
            possible_slots_checked: [],
            slots: {},
            restaurant: {},
            restaurants: [],
            alternative_restaurants: [],
            showStripeConnectModal: false,
            lock_nb_noshow: 0,
            showMenuOption: false,
            showEbPaiement: false,
            showIfComplet: false,
            isDuplicating: false,
            custom_category_en: null,
            custom_category_fr: null,
            widget: {
                min_pax: null,
                max_pax: null,
            },
            paxes: {
                rotation: false,
                value: [],
            },
            seatingPlan: {
                algorithm_enabled: false,
                value: null,
                initialValue: null,
                hasChanged: false,
                allow_clients_choose_room: false,
                allow_clients_choose_room_mandatory: 0,
            },
            showConfirmModal: false,
            showConfirmModalServicesId: "",
            confirmModalData: {
                message_object: this.$tl("labels.booking.reservations.defaultMailObj"),
                message_body: "",
                cancel_enabled: false,
                reservations_ids: "",
            },
            showNoSeatingPlanModal: false,
            hasSeatingPlan: false,
            seatingPlans: [],
            is_slot_max_pax_enabled: false,
            is_slot_max_reservations_enabled: false,
            futuresReservations: [],
            showConfirmDeleteSlotsModal: false,
            showAddMenuModal: false,
            showAddGeneralOptionModal: false,
            use_widget_min_pers: true,
            min_pers: null,
            use_widget_max_pers: true,
            max_pers: null,
            show_on_widget: true,
            sendReminderPayment: {
                isAuto: false,
                hours: 12,
            },
            externalMenu: {
                type: null,
                content: {
                    file: "",
                    link: "",
                },
                rawFile: null,
                pathFile: "",
            },
            googleReserveSettings: {
                enabled: false,
            },
        };
    },
    mixins: [DefaultServiceCategoriesEnum, MenuOptionTypeEnum, ExternalMenuTypeEnum, LexiconEnum],
    computed: {
        optionBankShortDelay() {
            return this.$store.getters["widgets/getWidget"].option_bank_short_delay || 1;
        },
        hasEnabledGoogleReserve() {
            return this.$store.getters["widgets/getWidget"].enable_google_reserve || false;
        },
        restaurantFromStore() {
            return this.$store.getters["restaurants/findRestaurantById"](this.$route.params.restaurant_id);
        },
        hasFeatSeatingPlan() {
            return typeof this.restaurantFromStore !== "undefined" && this.restaurantFromStore.feat_seating_plan;
        },
        hasPaymentSystem() {
            return this.restaurantFromStore && this.restaurantFromStore.has_psp;
        },
        special_date_begin: {
            get() {
                return this.special_date_begin_ || this.getDateTime();
            },
            set(date) {
                this.special_date_begin_ = this.getDateTime(date, false);
            },
        },
        special_date_end: {
            get() {
                return this.special_date_end_ || this.getDateTime();
            },
            set(date) {
                this.special_date_end_ = this.getDateTime(date, false);
            },
        },
        sortedSlotsKeys() {
            let keys = Object.keys(this.slots).filter((sk) => this.slots[sk].enabled);

            return keys.sort((a, b) => {
                const dateTimeA = this.setHourOnDateTime(a);
                const dateTimeB = this.setHourOnDateTime(b);

                return dateTimeA.diff(dateTimeB, "minutes").minutes;
            });
        },
        cancelData() {
            return {
                slots: Object.values(this.slots)
                    .filter((s) => s.hour_start && s.hour_start != "undefined:undefined" && s.enabled)
                    .map((s) => s.hour_start),
                days: this.days
                    .map((day) => {
                        switch (day) {
                            case "sunday":
                                return 0;
                            case "monday":
                                return 1;
                            case "tuesday":
                                return 2;
                            case "wednesday":
                                return 3;
                            case "thursday":
                                return 4;
                            case "friday":
                                return 5;
                            case "saturday":
                                return 6;
                            default:
                                return undefined;
                        }
                    })
                    .filter((day) => typeof day != "undefined"),
                special_date_begin: this.special_date_begin.toISODate(),
                special_date_end: this.special_date_end.toISODate(),
            };
        },
        headerText() {
            if (this.service_id && !this.isDuplicating)
                return `${this.$tl("labels.booking.services.special.edit")} <span class="value">${this.name}</span>`;
            if (this.service_id && this.isDuplicating) return this.$tl("labels.booking.services.special.duplicate");
            return this.$tl("labels.booking.services.special.add");
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_menu() {
            return this.rights.includes("booking.menu.create");
        },
        has_right_to_update_subscription() {
            return this.rights.includes("booking.subscription.update");
        },
        has_right_to_update_service() {
            if (this.service_id && !this.isDuplicating) return this.rights.includes("booking.service.update");
            return this.rights.includes("booking.service.create");
        },
        isAutoconfirm() {
            if (this.autoconfirm === 1 || this.autoconfirm === "1") {
                return true;
            } else {
                return false;
            }
        },
        category_vmodel: {
            get() {
                if (this.inEnum(this.category, this.ALL_DEFAULT_SERVICE_CATEGORIES)) {
                    return this.category;
                } else {
                    return this.SERVICE_CATEGORY_AUTRE.value;
                }
            },
            set(value) {
                this.category = value;
            },
        },
        isMenusEmpty() {
            return !this.menus || _.isEmpty(this.menus);
        },
        isGeneralOptionsEmpty() {
            return !this.general_options_selected || _.isEmpty(this.general_options_selected);
        },
        minPax() {
            if (this.widget.min_pax) {
                return this.widget.min_pax;
            } else {
                return 0;
            }
        },
        maxPax() {
            if (this.widget.max_pax) {
                if (this.widget.enable_children && this.widget.max_children && this.widget.enable_children === 1) {
                    return this.widget.max_pax + this.widget.max_children;
                }

                return this.widget.max_pax;
            } else {
                return 0;
            }
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        deleteURIFile() {
            return !this.isDuplicating ? `/api/restaurants/${this.restaurant_id}/services/${this.service_id}/file` : null;
        },
    },
    methods: {
        cleanDays: function (days) {
            let tempDays = JSON.parse(days);
            this.days = this.englishDays().filter((englishDay) => tempDays[englishDay]);
        },
        changeSlotMaxReservationsEnabled(index) {
            var slots = this.$_.cloneDeep(this.slots);
            var slot = slots[index];

            slots[index].is_max_reservations_enabled = slot.is_max_reservations_enabled ? 0 : 1;

            this.$set(this, "slots", slots);
        },
        changeSlotMaxPaxEnabled(index) {
            var slots = this.$_.cloneDeep(this.slots);
            var slot = slots[index];

            slots[index].is_max_pax_enabled = slot.is_max_pax_enabled ? 0 : 1;

            this.$set(this, "slots", slots);
        },
        loadMenus(reloadService = true, loading = false) {
            if (loading) this.loading = true;
            axios
                .get("/api/restaurants/" + this.$route.params.restaurant_id + "/menus?all=1")
                .then((response) => {
                    this.available_menus = response.data.data;
                    if (loading) this.loading = false;
                    if (!reloadService) return;

                    // if we are editing a service
                    if (this.service_id) {
                        var api = `/api/restaurants/${this.$route.params.restaurant_id}/services/${this.service_id}?include=slots,general_options,paxDurations,menus,restaurants_proposal,service_seating_plan`;
                        axios
                            .get(api)
                            .then((response) => {
                                this.loading = false;
                                this.name = this.isDuplicating ? `${response.data.name} (${this.$tl("labels.replica")})` : response.data.name;
                                this.category = response.data.category;
                                this.custom_category_fr = response.data.category;
                                this.custom_category_en = response.data.category_en;
                                let raw_days = response.data.days;
                                this.cleanDays(raw_days);

                                this.raw_hour_begin = response.data.hour_begin;
                                this.hour_begin = this.convertHour(this.raw_hour_begin);
                                this.raw_hour_end = response.data.hour_end;
                                this.hour_end = this.convertHour(this.raw_hour_end);

                                this.max_pax = response.data.max_pax;
                                this.lock_reservation_until_enabled = response.data.lock_reservation_until_enabled === 1 ? true : false;
                                this.lock_reservation_until = response.data.lock_reservation_until;
                                this.special = response.data.special;

                                this.special_date_begin = this.getDateTime(response.data.special_datetime_begin);
                                this.special_date_end = this.getDateTime(response.data.special_datetime_end);

                                let raw_slots = response.data.slots.data;
                                this.initSlots(raw_slots);

                                this.menu_mandatory_from = response.data.menu_mandatory_from;
                                this.menu_mandatory = response.data.menu_mandatory;
                                this.menu_unique = response.data.menu_unique;
                                this.menu_unique_from = response.data.menu_unique_from;
                                this.prepayment_mandatory = response.data.prepayment_mandatory;
                                this.prepayment_enabled = response.data.prepayment_enabled;
                                this.prepayment_percent = response.data.prepayment_percent;

                                this.autoconfirm = response.data.autoconfirm;
                                this.autoconfirm_percent = response.data.autoconfirm_percent;
                                this.autoconfirm_max_pers = response.data.autoconfirm_max_pers;
                                this.autoconfirm_until = response.data.autoconfirm_until;

                                this.cancellation_until = response.data.cancellation_until;
                                this.cancellation_until /= 60;

                                this.enable_footprint = response.data.enable_footprint;
                                this.footprint_min_pers = response.data.footprint_min_pers;
                                this.amount_noshow = response.data.amount_noshow;
                                this.prepayment_min_pers = response.data.prepayment_min_pers;

                                this.option_bank_auto_cancel = response.data.option_bank_auto_cancel;
                                this.general_options_selected = response.data.general_options.data.map((item) => item.id);

                                this.lock_nb_noshow = response.data.lock_nb_noshow;
                                this.paxes.value = this.formatPaxesDurations(response.data.paxDurations.data);
                                this.paxes.rotation = response.data.is_table_rotation_enabled;
                                this.enable_pax_per_slot = response.data.enable_pax_per_slot;

                                this.seatingPlan.algorithm_enabled = response.data.is_seating_plan_algorithm_enabled;
                                this.seatingPlan.value = response.data.service_seating_plan || null;
                                this.seatingPlan.initialValue = response.data.service_seating_plan || null;
                                this.seatingPlan.allow_clients_choose_room = response.data.allow_clients_choose_room;
                                this.seatingPlan.allow_clients_choose_room_mandatory = response.data.allow_clients_choose_room_mandatory;

                                this.is_slot_max_reservations_enabled = response.data.is_slot_max_reservations_enabled;
                                this.is_slot_max_pax_enabled = response.data.is_slot_max_pax_enabled;

                                this.use_widget_min_pers = response.data.min_pers === null;
                                this.use_widget_max_pers = response.data.max_pers === null;
                                this.min_pers = response.data.min_pers;
                                this.max_pers = response.data.max_pers;
                                this.show_on_widget = response.data.show_on_widget;

                                this.sendReminderPayment.isAuto = response.data.reminder_payment_is_auto;
                                this.sendReminderPayment.hours = response.data.reminder_payment_hours;

                                // external menu
                                if (response.data.external_menu) {
                                    this.externalMenu.content.file = response.data.external_menu.file ?? "";
                                    this.externalMenu.content.link = response.data.external_menu.link ?? "";
                                }
                                this.externalMenu.type = response.data.external_menu_type;
                                this.externalMenu.pathFile = response.data.path_file;

                                this.googleReserveSettings.enabled = response.data.enable_google_reserve;

                                let raw_menus = response.data.menus.data;
                                this.cleanMenus(raw_menus);
                                this.cleanAlternativeRestaurants(response.data.restaurants_proposal.data);
                            })
                            .catch((error) => {
                                this.error = this.getErrorMsgFromErrorResponse(error);
                                this.loading = false;
                            });
                    } else {
                        this.loading = false;

                        // if user come from a editing reservation view
                        if (changing_reservation_id) {
                            // empty form fields
                            this.emptyForm();
                        }
                    }
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                    this.loading = false;
                });
        },
        loadGeneralOptions(loading = false) {
            if (loading) this.loading = true;
            axios
                .get("/api/restaurants/" + this.$route.params.restaurant_id + "/general/options")
                .then((response) => {
                    if (loading) this.loading = false;
                    this.general_options = response.data.data;
                })
                .catch((error) => {
                    if (loading) this.loading = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        // load services of the restaurant
        initData(changing_reservation_id) {
            this.error = this.restaurant = null;
            this.loading = true;

            this.service_id = this.$route.params.service_id;
            this.isDuplicating = this.$route.params.isDuplicating ? this.$route.params.isDuplicating : false;

            if (this.hasFeatSeatingPlan) {
                this.httpGet(`/api/restaurants/${this.$route.params.restaurant_id}/seatingPlans`).then((response) => {
                    if (response !== false && response.data.data.length > 0) {
                        this.hasSeatingPlan = true;
                        this.seatingPlans = response.data.data;
                    }
                });
            }

            axios
                .get("/api/booking/restaurants?all=1&exclude_canceled=1")
                .then((response) => {
                    this.restaurants = this.filterRestaurantsProposals(response.data.data);

                    axios
                        .get("/api/restaurants/" + this.$route.params.restaurant_id + "/widget")
                        .then((response) => {
                            this.widget.min_pax = isNaN(Number.parseInt(response.data.min_pers)) ? null : Number.parseInt(response.data.min_pers);
                            this.widget.max_pax = isNaN(Number.parseInt(response.data.max_pers)) ? null : Number.parseInt(response.data.max_pers);

                            this.widget.enable_children = response.data.enable_children;
                            this.widget.max_children = isNaN(Number.parseInt(response.data.max_children))
                                ? null
                                : Number.parseInt(response.data.max_children);

                            if (this.widget.min_pax === null || this.widget.max_pax === null) {
                                this.errors = ["Une erreur est survenue."];
                            }
                        })
                        .catch((error) => {
                            this.error = this.getErrorMsgFromErrorResponse(error);
                        });

                    this.loadGeneralOptions();

                    this.loadMenus(true, false, changing_reservation_id);
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                    this.loading = false;
                });
        },
        filterRestaurantsProposals(list) {
            let foundIndex = -1;
            const restaurantId = Number.parseInt(this.$route.params.restaurant_id);

            for (const index in list) {
                if (list[index].id === restaurantId) {
                    foundIndex = index;
                }
            }

            if (foundIndex >= 0) {
                list.splice(foundIndex, 1);
            }

            return list;
        },
        convertHour: function (time) {
            let temp = time.split(":");
            let converted_hour = {
                HH: temp[0],
                mm: temp[1],
            };
            return converted_hour;
        },
        cleanAlternativeRestaurants: function (restaurants) {
            let arrayRestaurants = [];

            for (var i = 0; i < restaurants.length; i++) {
                if (this.restaurants.some((r) => r.id === restaurants[i].id)) arrayRestaurants.push(restaurants[i].id);
            }

            this.alternative_restaurants = arrayRestaurants;
        },
        cleanHour: function (time) {
            let clean_hour = time;
            if (time.HH && time.mm) {
                clean_hour = time.HH + ":" + time.mm;
            }
            return clean_hour;
        },
        convertSlots: function (slots) {
            for (var i = 0; i < slots.length; i++) {
                slots[i].hour_start = this.convertHour(slots[i].hour_start);
            }
            return slots;
        },
        cleanSlots: function (slots) {
            for (var i = 0; i < slots.length; i++) {
                slots[i].hour_start = this.cleanHour(slots[i].hour_start);
            }
            return slots;
        },
        put() {
            this.formLoading = true;

            axios
                .post("/api/restaurants/" + this.$route.params.restaurant_id + "/services/" + this.service_id, this.formatServiceForForm("put"))
                .then((response) => {
                    this.formLoading = false;

                    this.notifySuccess(response);

                    // redirect to services list
                    this.$router.push({
                        name: "booking.restaurants.settings.openings",
                        params: {
                            restaurant_id: this.$route.params.restaurant_id,
                        },
                    });

                    // reload data
                    this.initData();
                })
                .catch((error) => {
                    this.formErrors = error.response && error.response.data.errors ? error.response.data.errors : [];
                    this.formLoading = false;
                });
        },
        getServicesOverrideByOpening() {
            return axios.post(
                `/api/restaurants/${this.$route.params.restaurant_id}/services/openings/getServicesOverride`,
                this.formatServiceForForm()
            );
        },
        formatServiceForForm(method = "post") {
            const formData = new FormData();

            const data = {
                name: this.name,
                category: this.category_vmodel,
                days: this.days,
                hour_begin: this.cleanHour(this.hour_begin),
                hour_end: this.cleanHour(this.hour_end),
                max_pax: this.max_pax,
                lock_reservation_until_enabled: this.lock_reservation_until_enabled,
                lock_reservation_until: this.lock_reservation_until,
                special: 1,
                special_date_begin: this.special_date_begin.toISODate(),
                special_date_end: this.special_date_end.toISODate(),
                general_options_selected: this.general_options_selected,
                slots: Object.values(this.slots).filter((s) => s.enabled),
                menu_mandatory_from: this.menu_mandatory_from,
                menu_mandatory: this.menu_mandatory,
                menu_unique: this.menu_unique,
                menu_unique_from: this.menu_unique_from,
                prepayment_mandatory: this.prepayment_mandatory,
                prepayment_enabled: this.isMenusEmpty && this.isGeneralOptionsEmpty ? false : this.prepayment_enabled,
                prepayment_percent: this.prepayment_percent,
                menus: this.menus,
                alternative_restaurants: this.alternative_restaurants,
                autoconfirm: this.autoconfirm,
                autoconfirm_percent: this.autoconfirm_percent,
                autoconfirm_max_pers: this.autoconfirm_max_pers,
                autoconfirm_until: this.autoconfirm_until,
                cancellation_until: this.cancellation_until,
                enable_footprint: this.enable_footprint,
                footprint_min_pers: this.footprint_min_pers,
                amount_noshow: this.amount_noshow,
                option_bank_auto_cancel: this.option_bank_auto_cancel,
                prepayment_min_pers: this.prepayment_min_pers,
                lock_nb_noshow: this.lock_nb_noshow,
                custom_category_fr: this.custom_category_fr,
                custom_category_en: this.custom_category_en,
                is_table_rotation_enabled: this.paxes.rotation,
                paxes_durations: this.paxes.value,
                enable_pax_per_slot: this.enable_pax_per_slot,
                is_seating_plan_algorithm_enabled: this.seatingPlan.value ? this.seatingPlan.algorithm_enabled : false,
                seating_plan_id: this.seatingPlan.value ? this.seatingPlan.value.id : null,
                is_slot_max_pax_enabled: this.is_slot_max_pax_enabled,
                is_slot_max_reservations_enabled: this.is_slot_max_reservations_enabled,
                use_widget_min_pers: this.use_widget_min_pers,
                use_widget_max_pers: this.use_widget_max_pers,
                min_pers: this.min_pers,
                max_pers: this.max_pers,
                allow_clients_choose_room:
                    this.seatingPlan.value && this.seatingPlan.algorithm_enabled ? this.seatingPlan.allow_clients_choose_room : false,
                allow_clients_choose_room_mandatory:
                    this.seatingPlan.value && this.seatingPlan.algorithm_enabled && this.seatingPlan.allow_clients_choose_room
                        ? this.seatingPlan.allow_clients_choose_room_mandatory
                        : 0,
                ...this.confirmModalData,
                show_on_widget: this.show_on_widget,
                external_menu: this.externalMenu.content,
                enable_google_reserve: this.googleReserveSettings.enabled,
                google_reserve_texts: this.googleReserveSettings.texts,
                reminder_payment_is_auto: this.sendReminderPayment.isAuto,
            };

            if (this.sendReminderPayment.isAuto) {
                data["reminder_payment_hours"] = this.sendReminderPayment.hours;
            }

            data["external_menu_type"] = this.externalMenu.type;

            if (this.isDuplicating) {
                data["is_duplicating"] = this.isDuplicating;
            }
            if (method === "put") {
                formData.append("_method", "PUT");
            }
            if (this.isValidFile(this.externalMenu.rawFile) && this.externalMenu.type === this.EXTERNAL_MENU_FILE.value) {
                formData.append("file", this.externalMenu.rawFile);
            }
            formData.append("payload", JSON.stringify(data));

            return formData;
        },
        postForm(e, confirm = false) {
            if (e) e.preventDefault();

            this.formLoading = true;

            this.getServicesOverrideByOpening()
                .then((response) => {
                    if (!response.data.services_override) {
                        return this.sendForm(null, confirm);
                    }
                    const servicesId = response.data.services_override.join(",");
                    axios
                        .get(
                            `/api/restaurants/${this.$route.params.restaurant_id}/reservations` +
                                `/filter?begin=${this.special_date_begin.toISODate()}&end=${this.special_date_end.toISODate()}` +
                                `&services=${servicesId}&excluded_status=pending-canceled-refused-noshow`
                        )
                        .then((response) => {
                            if (response.data.length > 0) {
                                this.showConfirmModalServicesId = servicesId;
                                this.showConfirmModal = true;
                                this.formLoading = false;
                            } else {
                                return this.sendForm(null, confirm);
                            }
                        });
                })
                .catch((error) => {
                    this.formErrors = error.response ? error.response.data.errors : [this.getErrorMsgFromErrorResponse(error)];
                    this.formLoading = false;
                });
        },
        sendForm: function (e, confirm = false) {
            if (e) e.preventDefault();
            if (this.showConfirmModal) this.showConfirmModal = false;
            if (confirm) this.showConfirmDeleteSlotsModal = false;
            this.formLoading = true;
            this.formSuccess = null;
            this.formErrors = null;

            // if we are editing a service
            if (this.service_id && !this.isDuplicating) {
                if (!confirm)
                    axios
                        .post(
                            `/api/restaurants/${this.$route.params.restaurant_id}/services/${this.service_id}/checkFutureReservations`,
                            this.cancelData
                        )
                        .then((response) => {
                            if (response.data && response.data.data && response.data.data.length > 0) {
                                this.formLoading = false;
                                this.futuresReservations = response.data.data;
                                this.showConfirmDeleteSlotsModal = true;
                                return;
                            }
                            this.put();
                        })
                        .catch((error) => {
                            this.formErrors =
                                error.response && error.response.data.errors
                                    ? error.response.data.errors
                                    : [this.getErrorMsgFromErrorResponse(error)];
                            this.formLoading = false;
                        });
                else this.put();
            } else {
                // we are adding a new service
                axios
                    .post("/api/restaurants/" + this.$route.params.restaurant_id + "/services", this.formatServiceForForm())
                    .then((response) => {
                        // display success message
                        this.formLoading = false;
                        this.formSuccess = response.data.message;
                        // empty form fields
                        this.emptyForm();

                        // redirect to services list
                        this.$router.push({
                            name: "booking.restaurants.settings.openings",
                            params: {
                                restaurant_id: this.$route.params.restaurant_id,
                            },
                        });
                    })
                    .catch((error) => {
                        this.formErrors =
                            error.response && error.response.data.errors ? error.response.data.errors : [this.getErrorMsgFromErrorResponse(error)];
                        this.formLoading = false;
                    });
            }
        },
        addMenu: function (e) {
            e.preventDefault();
            this.menus.push({ id: "", name: "" });
        },
        removeMenu: function (menu) {
            var index = this.menus.indexOf(menu);
            this.menus[index].deleted = 1;
            this.menus[index].deleted_at = 1;
        },
        verifDatesBegin(date) {
            if (this.getDateTime(date, false) > this.special_date_end) {
                this.special_date_end = date;
            }
        },
        verifDatesEnd(date) {
            if (this.getDateTime(date, false) < this.special_date_begin) {
                this.special_date_begin = date;
            }
        },
        emptyForm() {
            this.name = null;
            this.category = null;
            this.days = [];
            this.raw_hour_begin = null;
            this.hour_begin = { HH: "HH", mm: "mm" };
            this.raw_hour_end = null;
            this.hour_end = { HH: "HH", mm: "mm" };
            this.max_pax = null;
            this.lock_reservation_until = null;
            this.special = 1;
            this.special_date_begin = null;
            this.special_date_end = null;
            this.slots = {};
            this.menu_mandatory_from = null;
            this.menu_mandatory = false;
            this.menu_unique = null;
            this.menu_unique_from = null;
            this.prepayment_enabled = null;
            this.prepayment_percent = null;
            this.menus = {};
            this.possible_slots = [];
            this.possible_slots_checked = [];
            this.show_on_widget = true;
            this.googleReserveSettings.enabled = false;
        },
        changedMenu(event, menu) {
            if (event.target.checked) {
                this.$set(this.menus, `id_${menu.id}`, {
                    id: menu.id,
                    selected: true,
                    enable_nb_min_pers: false,
                    nb_min_pers: null,
                    enable_nb_max_pers: false,
                    nb_max_pers: null,
                });
            } else {
                this.$set(this, "menus", _.omit(this.menus, `id_${menu.id}`));
            }
        },
        cleanMenus: function (menus) {
            const self = this;
            menus.forEach((m) => {
                self.$set(self.menus, `id_${m.id}`, {
                    id: m.id,
                    selected: true,
                    enable_nb_min_pers: m.pivot.enable_nb_min_pers,
                    nb_min_pers: m.pivot.nb_min_pers,
                    enable_nb_max_pers: m.pivot.enable_nb_max_pers,
                    nb_max_pers: m.pivot.nb_max_pers,
                });
            });
        },
        getPossibleSlots() {
            let raw_h_begin = null;
            let raw_h_end = null;
            if (this.hour_begin.HH && this.hour_begin.HH !== "HH" && this.hour_begin.mm && this.hour_begin.mm !== "mm") {
                raw_h_begin = this.hour_begin.HH + ":" + this.hour_begin.mm;
            }
            if (this.hour_end.HH && this.hour_end.HH !== "HH" && this.hour_end.mm && this.hour_begin.mm !== "mm") {
                raw_h_end = this.hour_end.HH + ":" + this.hour_end.mm;
            }

            if (raw_h_begin && raw_h_end) {
                let hour_details = raw_h_begin.split(":");
                let hour_end_details = raw_h_end.split(":");
                if (hour_details[0] && hour_details[1] && hour_end_details[0] && hour_end_details[1]) {
                    let minutes_interval = 15; //minutes interval
                    let times = []; // time array
                    let start_time = hour_details[0] * 1 * 60 + hour_details[1] * 1; // start time in minutes
                    let end_time = hour_end_details[0] * 1 * 60 + hour_end_details[1] * 1; // end time in minutes

                    //loop to increment the time and push results in array
                    for (let i = 0; start_time <= end_time; i++) {
                        let hh = Math.floor(start_time / 60); // getting hours of day in 0-24 format
                        let mm = start_time % 60; // getting minutes of the hour in 0-55 format
                        times[i] = ("0" + (hh % 24)).slice(-2) + ":" + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
                        start_time = start_time + minutes_interval;
                    }

                    this.possible_slots = times;
                    let tmpSlots = {};
                    Object.keys(this.slots).forEach((hour) => {
                        if (this.possible_slots.includes(hour)) tmpSlots[hour] = this.slots[hour];
                    });
                    this.$set(this, "slots", tmpSlots);
                }
            }
        },
        checkIfStripeEnabled(event) {
            if (!this.hasPaymentSystem) {
                this.showStripeConnectModal = true;
                event.stopPropagation();
                event.preventDefault();
            }
        },
        isHourSet(obj) {
            return obj && obj.HH && obj.HH !== "HH" && obj.mm !== "mm" && !isNaN(Number.parseInt(obj.HH)) && !isNaN(Number.parseInt(obj.mm));
        },
        formatPaxesDurations(obj) {
            var result = [];

            obj.forEach(function (value) {
                result.push({
                    pax: value.pax,
                    duration: {
                        HH: value.duration.split(":")[0],
                        mm: value.duration.split(":")[1],
                    },
                });
            });

            return result;
        },
        slotClicked(event, slot) {
            var value = event.target.checked;

            if (value && typeof Object.values(this.slots).find((s) => s.hour_start === slot) != "undefined") {
                let tmpSlots = {};
                Object.values(this.slots).forEach((s) => {
                    if (s.hour_start === slot) tmpSlots[s.hour_start] = { ...s, enabled: true };
                    else tmpSlots[s.hour_start] = s;
                });
                this.$set(this, "slots", tmpSlots);
            } else if (value) {
                this.$set(this.slots, slot, {
                    hour_start: slot,
                    is_max_pax_enabled: false,
                    max_pax: null,
                    is_max_reservations_enabled: false,
                    max_reservations: null,
                    enabled: true,
                });
            } else {
                let tmpSlots = {};
                Object.values(this.slots).forEach((s) => {
                    if (s.hour_start === slot) tmpSlots[s.hour_start] = { ...s, enabled: false };
                    else tmpSlots[s.hour_start] = s;
                });
                this.$set(this, "slots", tmpSlots);
            }
        },
        initSlots: function (slots) {
            for (var i = 0; i < slots.length; i++) {
                this.slots[slots[i].hour_start] = slots[i];
                this.slots[slots[i].hour_start].enabled = true;
            }
        },
        setPaxesHigherThanTen(event) {
            for (const index in this.paxes.value) {
                const pax = this.paxes.value[index];
                if (pax.pax > 10) {
                    this.paxes.value[index].duration = event.data;
                }
            }
        },
        cancelReservations(cancelData) {
            this.formLoading = true;
            this.errors = [];

            axios
                .post(`/api/restaurants/${this.$route.params.restaurant_id}/services/${this.service_id}/cancelFutureReservations`, {
                    ...this.cancelData,
                    ...cancelData,
                })
                .then((response) => {
                    this.showConfirmDeleteSlotsModal = false;
                    this.notifySuccess(response);
                    this.put();
                })
                .catch((error) => {
                    this.formErrors =
                        error.response && error.response.data.errors ? error.response.data.errors : [this.getErrorMsgFromErrorResponse(error)];
                    this.formLoading = false;
                });
        },
    },
    created() {
        this.initData();
    },
    watch: {
        "$route.params.restaurant_id": function (id) {
            this.initData();
        },
        "$route.params.service_id": function (id) {
            this.initData(true);
        },
        "paxes.rotation": {
            handler: function (newVal) {
                if (this.$_.isEmpty(this.paxes.value)) {
                    this.paxes.value = [];
                    const startOfDay = this.getDateTime().startOf("day");

                    if (newVal === true) {
                        var defaultDuration = {
                            HH: "01",
                            mm: "00",
                        };

                        if (this.isHourSet(this.hour_begin) && this.isHourSet(this.hour_end)) {
                            const hourStart = startOfDay.plus({
                                hours: Number.parseInt(this.hour_begin.HH),
                                minutes: Number.parseInt(this.hour_begin.mm),
                            });
                            const hourEnd = startOfDay.plus({
                                hours: Number.parseInt(this.hour_end.HH),
                                minutes: Number.parseInt(this.hour_end.mm),
                            });

                            const diff = Math.abs(hourEnd.diff(hourStart, "minutes"));
                            const hourDuration = Math.round(diff / 60);
                            const minutesDuration = Math.round(diff % 60);

                            defaultDuration.HH = `0${hourDuration}`.substr(-2);
                            defaultDuration.mm = `0${minutesDuration}`.substr(-2);
                        }

                        this.$_.range(this.minPax, this.maxPax + 1).forEach((pax) => {
                            this.paxes.value.push({
                                duration: Object.assign({}, defaultDuration),
                                pax,
                            });
                        });
                    }
                }
            },
        },
        "seatingPlan.algorithm_enabled": {
            handler: function (newVal) {
                if (newVal === true && !this.hasSeatingPlan) {
                    this.$nextTick(() => {
                        this.seatingPlan.algorithm_enabled = false;
                        this.showNoSeatingPlanModal = true;
                    });
                }
            },
        },
        "seatingPlan.value": {
            handler(newVal) {
                const newValId = newVal ? newVal.id : null;
                const initialValId = this.seatingPlan.initialValue ? this.seatingPlan.initialValue.id : null;
                this.seatingPlan.hasChanged = initialValId && newValId !== initialValId;
                if (!newVal) {
                    this.seatingPlan.algorithm_enabled = false;
                    this.seatingPlan.allow_clients_choose_room = false;
                    this.seatingPlan.allow_clients_choose_room_mandatory = 0;
                }
            },
        },
    },
    components: {
        VueTimepicker,
        DatePicker,
        OverlayLayout,
        LoaderComponent,
        NoSeatingPlanModal,
        ComfirmDeleteSlotsModal,
        addMenuModal,
        addMenuOptionModal,
        ConfirmOpeningCreateModal,
        RadioButtonComponent,
        FileUploaderComponent,
        InputComponent,
        googleReserve,
        SendReminderPayment,
    },
};
</script>
