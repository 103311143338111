import axios from "axios";

export const actions = {
    getWebsiteSettings({ commit }, { restaurant_id }) {
        return axios.get(`/api/website/${restaurant_id}/website?include=ga`).then((response) => {
            if (response.data && response.data.website_settings) {
                commit("setGaEnabled", response.data.website_settings.ga && response.data.website_settings.ga.enabled);
                commit("setWebsiteEnabled", response.data.website_enabled);
                commit("setAllSettings", response.data.website_settings);
            } else {
                commit("setGaEnabled", false);
                commit("setWebsiteEnabled", false);
                commit("setAllSettings", null);
            }
            return response;
        });
    },
    getStats({}, { restaurant_id, params }) {
        return axios.get(`/api/website/${restaurant_id}/website/stats`, { params });
    },
    setUnsavedChanges({ commit }, unsaved_changes) {
        commit("setUnsavedChanges", unsaved_changes);
    },
    updateWebsiteSettings({ commit }, { restaurant_id, params }) {
        return axios.put(`/api/website/${restaurant_id}/website`, params).then((response) => {
            commit("setUnsavedChanges", false);
            return response;
        });
    },
    publish({ commit }, { restaurant_id, params }) {
        return axios.post(`/api/website/${restaurant_id}/website/publish`, {
            ...params,
            section: "publish",
        });
    },
    setAllSettings({ commit }, website_settings) {
        commit("setAllSettings", website_settings);
    },
    setIsPublishing({ commit }, is_publishing) {
        commit("setIsPublishing", is_publishing);
    },
};

export default actions;
