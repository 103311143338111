export default {
    booking: {
        dashboard:
            "Piloter mes disponibilités : vous pouvez passer un jeu en complet, bloquer certains créneaux ou bloquer des PAX en cliquant directement sur la case du jeu concerné.",
        servicesConfig: {
            serviceName: `Ce nom s'affichera uniquement pour vous et ne sera pas visible par vos clients.<br/> Il doit vous permettre de différencier deux jeux, par exemple Après-midi semaine - Après-midi week-end si vous avez créé deux jeux distincts.`,
            activeDays: `Si vous avez des conditions de réservation qui varient entre plusieurs jeux, pensez à créer des jeux différents.<br/> Par exemple, si les créneaux de réservation ne sont pas identiques la semaine du week-end, vous devrez créer 2 jeux : un qui sera actif pour les jours de la semaine, un autre pour les jours du week-end.`,
            limitReservation: `Si vous souhaitez prendre des réservations jusqu'à l'heure de début de votre jeu, la valeur à indiquer est 0.`,
            tableRotation: `Si vous proposez plusieurs jeux, cette fonctionnalité est idéale pour avoir une vue d'ensemble de vos réservations et utiliser la vue timeline (vous aurez une visibilité sur les places réattribuées).`,
            exceptionalClosureConcernedServices: `Si vous souhaitez fermer uniquement un des jeux (et non l'ensemble), vous pouvez sélectionner uniquement le ou les jeux concernés.<br/> Cela peut-être utilisé par exemple dans le cadre d'une privatisation ou d'un changement temporaire des jeux proposés.`,
        },
    },
};
