import Clients from "../../views/Clients.vue";
import ClientsForm from "../../views/ClientsForm.vue";

import ClickAndCollectList from "../../views/ClickAndCollect/Board/ClickAndCollectList.vue";
import ClickAndCollectBoard from "../../views/ClickAndCollect/Board.vue";
import ClickAndCollectSales from "../../views/ClickAndCollect/ClickAndCollectSales.vue";
import ClickAndCollectSalesCollect from "../../views/ClickAndCollect/Sales/ClickAndCollectSalesCollect.vue";
import ClickAndCollectSalesDelivery from "../../views/ClickAndCollect/Sales/ClickAndCollectSalesDelivery.vue";
import ClickAndCollectSalesDispatch from "../../views/ClickAndCollect/Sales/ClickAndCollectSalesDispatch.vue";
import ClickAndCollectSalesWaitingConfirmation from "../../views/ClickAndCollect/ClickAndCollectSalesWaitingConfirmation.vue";
import ClickAndCollectPayments from "../../views/ClickAndCollect/ClickAndCollectPayments.vue";
import ClickAndCollectReport from "../../views/ClickAndCollect/ClickAndCollectReport.vue";
import ClickAndCollectProducts from "../../views/ClickAndCollect/ClickAndCollectProducts.vue";
import ClickAndCollectProductsTab from "../../views/ClickAndCollect/Products/ClickAndCollectProductsTab.vue";
import ClickAndCollectCategoriesTab from "../../views/ClickAndCollect/Products/ClickAndCollectCategoriesTab.vue";
import ClickAndCollectConfiguration from "../../views/ClickAndCollect/ClickAndCollectConfiguration.vue";
import ClickAndCollectSettings from "../../views/ClickAndCollect/Settings/ClickAndCollectSettings.vue";
import ClickAndCollectDeliverySettings from "../../views/ClickAndCollect/Settings/ClickAndCollectDeliverySettings.vue";
import ClickAndCollectDispatchSettings from "../../views/ClickAndCollect/Settings/ClickAndCollectDispatchSettings.vue";
import ClickAndCollectPromoCodes from "../../views/ClickAndCollect/Settings/ClickAndCollectPromoCodes.vue";
import ClickAndCollectClosures from "../../views/ClickAndCollect/Settings/ClickAndCollectClosures.vue";
import ClickAndCollectOpenings from "../../views/ClickAndCollect/Settings/ClickAndCollectOpenings.vue";
import ClickAndCollectCGV from "../../views/ClickAndCollect/Settings/ClickAndCollectCGV.vue";
import ClickAndCollectWidget from "../../views/ClickAndCollect/Settings/ClickAndCollectWidget.vue";
import ClickAndCollectInputParams from "../../views/ClickAndCollect/Settings/ClickAndCollectInputParams.vue";

const routes = [
    {
        path: "/click-and-collect",
        name: "click_and_collect.home",
        component: ClickAndCollectList,
    },
    {
        path: "/click-and-collect/restaurants/:restaurant_id/board",
        name: "click_and_collect.restaurants.board",
        component: ClickAndCollectBoard,
    },
    {
        path: "/click-and-collect/restaurants/:restaurant_id/sales",
        name: "click_and_collect.restaurants.sales",
        component: ClickAndCollectSales,
        children: [
            {
                path: "collect",
                name: "click_and_collect.restaurants.sales.collect",
                component: ClickAndCollectSalesCollect,
            },
            {
                path: "delivery",
                name: "click_and_collect.restaurants.sales.delivery",
                component: ClickAndCollectSalesDelivery,
            },
            {
                path: "dispatch",
                name: "click_and_collect.restaurants.sales.dispatch",
                component: ClickAndCollectSalesDispatch,
            },
        ],
    },
    {
        path: "/click-and-collect/restaurants/:restaurant_id/salesWaitingConfirmation",
        name: "click_and_collect.restaurants.sales_waiting_confirmation",
        component: ClickAndCollectSalesWaitingConfirmation,
    },
    {
        path: "/click-and-collect/restaurants/:restaurant_id/payments",
        name: "click_and_collect.restaurants.payments",
        component: ClickAndCollectPayments,
    },
    {
        path: "/click-and-collect/restaurants/:restaurant_id/report",
        name: "click_and_collect.restaurants.report",
        component: ClickAndCollectReport,
    },
    {
        path: "/click-and-collect/restaurants/:restaurant_id/products",
        name: "click_and_collect.restaurants.products",
        component: ClickAndCollectProducts,
        children: [
            {
                path: "products",
                name: "click_and_collect.restaurants.products.products",
                component: ClickAndCollectProductsTab,
            },
            {
                path: "categories",
                name: "click_and_collect.restaurants.products.categories",
                component: ClickAndCollectCategoriesTab,
            },
        ],
    },
    {
        path: "/click-and-collect/restaurants/:restaurant_id/configuration",
        name: "click_and_collect.restaurants.configuration",
        component: ClickAndCollectConfiguration,
        children: [
            {
                path: "settings",
                name: "click_and_collect.restaurants.configuration.settings",
                component: ClickAndCollectSettings,
            },
            {
                path: "settings_delivery",
                name: "click_and_collect.restaurants.configuration.settings_delivery",
                component: ClickAndCollectDeliverySettings,
            },
            {
                path: "settings_dispatch",
                name: "click_and_collect.restaurants.configuration.settings_dispatch",
                component: ClickAndCollectDispatchSettings,
            },
            {
                path: "promo_codes",
                name: "click_and_collect.restaurants.configuration.promo_codes",
                component: ClickAndCollectPromoCodes,
            },
            {
                path: "closures",
                name: "click_and_collect.restaurants.configuration.closures",
                component: ClickAndCollectClosures,
            },
            {
                path: "openings",
                name: "click_and_collect.restaurants.configuration.openings",
                component: ClickAndCollectOpenings,
            },
            { path: "cgv", name: "click_and_collect.restaurants.configuration.cgv", component: ClickAndCollectCGV },
            {
                path: "widget",
                name: "click_and_collect.restaurants.configuration.widget",
                component: ClickAndCollectWidget,
            },
            {
                path: "input-params",
                name: "click_and_collect.restaurants.configuration.inputParams",
                component: ClickAndCollectInputParams,
            },
        ],
    },
    {
        path: "/click-and-collect/restaurants/:restaurant_id/clients/:client_id",
        name: "click_and_collect.clients.form",
        component: ClientsForm,
    },
    {
        path: "/click-and-collect/restaurants/:restaurant_id/clients",
        name: "click_and_collect.clients",
        component: Clients,
    },
];

export default routes;
