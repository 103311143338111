var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "widgetPagePublic" } }, [
    _c(
      "div",
      { staticClass: "container", staticStyle: { "max-width": "700px" } },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [_c("router-view")], 1),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }