var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("LoaderComponent")
    : _vm.error
    ? _c("div", { staticClass: "alert alert-danger" }, [
        _vm._v("\n    " + _vm._s(_vm.error) + "\n"),
      ])
    : _vm.can_edit_stripe && _vm.stripe_connect_url !== null
    ? _c(
        "button",
        {
          staticClass: "btn btn-success btn-sm btn-circle",
          attrs: {
            type: "button",
            disabled: !_vm.has_right_to_update_restaurant,
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.authenticate.apply(null, arguments)
            },
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$tl("labels.form.psp.stripe.linkAccount")) +
              "\n"
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }