<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="alert alert-danger" v-else-if="!website_enabled">Votre site web n'est pas activé</div>
        <template v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">Configuration des pop-ups</h5>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-3">
                            <div class="col-md-5">
                                <label for="chk-instagram-gallery-enabled"><strong>Pop-up "Actualités"</strong></label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        :disabled="!has_right_to_update_customization"
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="website_settings.news_enabled" />
                                    <label
                                        v-tooltip="getTooltip('Activer / désactiver cette section')"
                                        @click="
                                            website_settings.news_enabled = has_right_to_update_customization
                                                ? !website_settings.news_enabled
                                                : website_settings.news_enabled
                                        "></label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="website_settings.news_enabled">
                            <div class="col-md-5">
                                <label for="txt-news-title">Titre de la pop-up <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-1">
                                <input
                                    :disabled="!has_right_to_update_customization"
                                    max="50"
                                    type="text"
                                    class="form-control"
                                    v-model="website_settings.news_title"
                                    id="txt-news-title" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="news_title" />
                            </div>
                            <div class="col-md-5">
                                <label for="txt-news-content">Contenu texte <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-2">
                                <TextAreaComponentOld
                                    :disabled="!has_right_to_update_customization"
                                    :maxNbChar="350"
                                    txtAreaId="txt-news-content"
                                    :txtAreaObj="website_settings"
                                    txtAreaObjKey="news_content"
                                    txtAreaRows="6" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="news_content" />
                            </div>
                            <div class="col-md-5">
                                <label>Ajouter un bouton de redirection</label>
                            </div>
                            <div class="col-md-7 mb-2">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        :disabled="!has_right_to_update_customization"
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="website_settings.news_btn_enabled" />
                                    <label
                                        v-tooltip="getTooltip('Activer / désactiver cette section')"
                                        @click="
                                            website_settings.news_btn_enabled = has_right_to_update_customization
                                                ? !website_settings.news_btn_enabled
                                                : website_settings.news_btn_enabled
                                        "></label>
                                </div>
                            </div>
                            <template v-if="website_settings.news_btn_enabled">
                                <div class="col-md-5">
                                    <label for="txt-news-btn-title">Titre du bouton <small class="text-danger">*</small></label>
                                </div>
                                <div class="col-md-7 mb-1">
                                    <input
                                        :disabled="!has_right_to_update_customization"
                                        max="20"
                                        type="text"
                                        class="form-control"
                                        v-model="website_settings.news_btn_title"
                                        id="txt-news-btn-title" />
                                    <ShowErrors class="d-block" :errors="errors" errorKey="news_btn_title" />
                                </div>
                                <div class="col-md-5">
                                    <label for="txt-news-btn-title">Lien <small class="text-danger">*</small></label>
                                    <small class="text-warning d-block">Merci de préfixer le lien par "http://" ou "https://"</small>
                                </div>
                                <div class="col-md-7 mb-2">
                                    <input
                                        :disabled="!has_right_to_update_customization"
                                        type="text"
                                        class="form-control"
                                        v-model="website_settings.news_btn_link"
                                        id="txt-news-btn-title" />
                                    <ShowErrors class="d-block" :errors="errors" errorKey="news_btn_link" />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0 mt-3">
                <div class="col-12 d-inline-block">
                    <button type="button" class="btn btn-success btn-circle mr-1" @click="postForm()" :disabled="!has_right_to_update_customization">
                        Enregistrer
                    </button>
                    <small
                        >Pour rendre vos modifications visibles sur votre site internet, pensez à "<strong>Enregistrer et publier</strong>" en
                        cliquant sur le bouton dans la navigation.</small
                    >
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent";
import ShowErrors from "../../../components/errors/ShowErrors";
import TextAreaComponentOld from "../../../components/forms/TextAreaComponentOld";

export default {
    data() {
        return {
            loading: 0,
            errors: null,
        };
    },
    props: {
        website_settings: {
            type: Object,
            required: true,
        },
        website_enabled: {
            required: true,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_customization() {
            return this.rights.includes("website.customization.update");
        },
    },
    methods: {
        postForm(doNotFetch = false) {
            this.loading++;
            this.errors = null;

            return this.$store
                .dispatch("websites/updateWebsiteSettings", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        ...this.website_settings,
                        show_address: this.website_settings.show_address ? false : true,
                    },
                })
                .then((response) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    if (!doNotFetch) this.$emit("fetch-data");
                    return true;
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    return false;
                });
        },
    },
    components: {
        ShowErrors,
        LoaderComponent,
        TextAreaComponentOld,
    },
};
</script>
