var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.has_right_to_read_subscription
    ? _c("div", [
        _c("div", { staticClass: "row mb-2" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.$tl("labels.form.facturation.title"))),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-2" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("span", { staticClass: "d-block" }, [
              _vm._v(_vm._s(_vm.billingDetails.company)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "d-block" }, [
              _vm._v(
                _vm._s(_vm.getBusinessTypeLabel(_vm.billingDetails.companyType))
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "d-block" }, [
              _vm._v(_vm._s(_vm.billingDetails.companySiret)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "d-block" }, [
              _vm._v(_vm._s(_vm.billingDetails.companyAddress)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "d-block" }, [
              _vm._v(_vm._s(_vm.billingDetails.companyZipcode)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "d-block" }, [
              _vm._v(_vm._s(_vm.billingDetails.companyCity)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "d-block" }, [
              _vm._v(_vm._s(_vm.billingDetails.companyCountry)),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }