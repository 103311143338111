var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-inline-block" },
        [
          _vm.client !== null
            ? [
                _c("show-client-type", {
                  attrs: { "client-type": _vm.client.type },
                }),
                _vm._v(" "),
                _vm.loyaltyLevel !== null
                  ? _c("show-client-loyalty-level", {
                      attrs: { "client-loyalty-level": _vm.loyaltyLevel },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.client.is_vip
                  ? _c("feather", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.getTooltip(_vm.$tl("labels.clients.vip")),
                          expression: "getTooltip($tl('labels.clients.vip'))",
                        },
                      ],
                      staticClass: "feather-orange",
                      attrs: { type: "star" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.client.is_risky
                  ? _c("feather", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.getTooltip(
                            _vm.$tl("labels.clients.risky")
                          ),
                          expression: "getTooltip($tl('labels.clients.risky'))",
                        },
                      ],
                      staticClass: "feather-orange",
                      attrs: { type: "alert-triangle" },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm._t("icons-start"),
          _vm._v(" "),
          ![null, ""].includes(_vm.publicComment)
            ? _c("feather", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.getTooltip(_vm.publicComment),
                    expression: "getTooltip(publicComment)",
                  },
                ],
                staticClass: "feather-blue",
                attrs: { type: "message-circle" },
              })
            : _vm._e(),
          _vm._v(" "),
          ![null, ""].includes(_vm.privateComment)
            ? _c("feather", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.getTooltip(_vm.privateComment),
                    expression: "getTooltip(privateComment)",
                  },
                ],
                staticClass: "feather-orange",
                attrs: { type: "message-circle" },
              })
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.hideName && _vm.client !== null
        ? _c(
            _vm.link !== null ? "router-link" : "div",
            {
              tag: "component",
              staticClass: "d-inline-block m-auto",
              attrs: { to: _vm.link },
            },
            [
              _c("span", { staticClass: "none-tablet" }, [
                _vm._v(_vm._s(_vm.civility) + " "),
              ]),
              _vm._v(_vm._s(_vm.client.firstname) + "\n        "),
              _c("span", { staticClass: "text-uppercase" }, [
                _vm._v(_vm._s(_vm.client.lastname)),
              ]),
              _vm._v(" "),
              _vm.showNoshows && _vm.client.noshows > 0
                ? _c("feather", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltip(
                          `${_vm.client.noshows} ${_vm.$tl(
                            "labels.clients.noshow"
                          )}`
                        ),
                        expression:
                          "getTooltip(`${client.noshows} ${$tl('labels.clients.noshow')}`)",
                      },
                    ],
                    staticClass: "feather-red",
                    attrs: { type: "alert-triangle" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._t("icons-end"),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }