<template>
    <thead>
        <ns-th field="cc_commands.client" width="6" allow-sorting>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.client')"></span>
            <feather class="none-desk" type="user" />
        </ns-th>
        <ns-th field="cc_commands.numero" width="6" allow-sorting>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.numero')"></span>
            <span class="none-desk">N°</span>
        </ns-th>
        <ns-th field="cc_commands.collect_type" width="6" allow-sorting>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.clickAndCollect.collectType')"></span>
            <feather class="none-desk" type="truck" />
        </ns-th>
        <ns-th field="cc_commands.reservation_date" width="6" allow-sorting>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.clickAndCollect.commandDate')"></span>
            <feather class="none-desk" type="calendar" />
        </ns-th>
        <ns-th>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.hours')"></span>
            <feather class="none-desk" type="clock" />
        </ns-th>
        <ns-th field="cc_command_mail_logs.created_at" width="7" allow-sorting>
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.sentDate')"></span>
            <feather class="none-desk" type="send" />
        </ns-th>
        <ns-th field="status" width="18">
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.shipmentStatus')"></span>
            <feather class="none-desk" type="message-square" />
        </ns-th>
        <ns-th field="re_sent" width="2">
            <span class="none-mobile" v-html="$tl('labels.notifications.listNotificationsLogs.grid.columns.commons.resendMail')"></span>
            <feather class="none-desk" type="send" />
        </ns-th>
    </thead>
</template>
<script>
import NsTh from "../../../../Datatable/NsTh.vue";

export default {
    components: {
        NsTh,
    },
};
</script>
