<template>
    <div>
        <Stepper
            v-show="!loading"
            class="border-light p-2 pl-5 pr-5"
            :step="step"
            :reservation="reservation"
            :restaurant-id="selected_restaurant_id"
            @change-step="setStep" />
        <LoaderComponent v-if="loading" />
        <span class="text-danger" v-else-if="error">{{ error }}</span>
        <keep-alive v-else>
            <component
                class="border-light p-3"
                style="border-top: none"
                ref="currentStepComponent"
                :is="currentStepComponent"
                :reservation="reservation"
                :baseRestaurant="baseRestaurant"
                :restaurant="currentRestaurant"
                :formErrors="formErrors"
                :tablesIdToPreselect="tablesIdToPreselect"
                :serviceIdToPreselect="serviceIdToPreselect"
                :slotIdToPreselect="slotIdToPreselect"
                :restaurant_id="selected_restaurant_id"
                :selected_destination_restaurant="selected_destination_restaurant"
                :vueMultiSelect="vueMultiSelect"
                @next-step="step++"
                @select-destination-restaurant="selectDestinationRestaurant"
                @enable-next-button="$emit('enable-next-button', $event)" />
        </keep-alive>
    </div>
</template>

<script>
import Step0 from "./steps/Date.vue";
import Step1 from "./steps/Pax.vue";
import Step2 from "./steps/Slot.vue";
import Step3 from "./steps/TablesAndMenus.vue";
import Step4 from "./steps/ClientAndOptionBank.vue";
import Stepper from "../../reservations/add/Stepper.vue";
import LoaderComponent from "../../LoaderComponent.vue";
import LangsEnum from "../../../mixins/enums/LangsEnum.js";

export default {
    data() {
        return {
            loading: false,
            error: null,
            formErrors: null,
            stepData: null,
            baseRestaurant: null,
            restaurant: null,
            reservation: {
                reservation_date: null,
                waiting_id: null,
                nb_pers: null,
                nb_children: 0,
                slot_id: null,
                slot: null,
                service: null,
                choosen_menus: {},
                choosen_general_options: {},
                restaurant_comment: "",
                num_table: null,
                option_bank: null,
                amount_to_pay: 0,
                send_confirmation: true,
                gift: null,
                user_id: null,
                duration: {
                    HH: "01",
                    mm: "00",
                },
                is_duration_customized: false,
                client: {
                    id: null,
                    civility: null,
                    firstname: null,
                    lastname: null,
                    company: null,
                    email: null,
                    newsletter: false,
                    phone: {
                        raw: null,
                        isValid: null,
                        country: null,
                        value: null,
                        search: {
                            results: null,
                        },
                    },
                    type: null,
                    is_vip: false,
                    locale: null,
                    firstnameHasFocus: false,
                    lastnameHasFocus: false,
                    companyHasFocus: false,
                    emailHasFocus: false,
                    phoneNumberHasFocus: false,
                    search: {
                        results: null,
                        errors: null,
                        isLoading: false,
                        firstnameHasFocus: false,
                        lastnameHasFocus: false,
                        companyHasfocus: false,
                        emailHasFocus: false,
                        phoneNumberHasFocus: false,
                    },
                },
                room_number: null,
                ignore_placement: false,
                is_hotel_client: false,
            },
            currentService: null,
            selected_destination_restaurant: null,
            vueMultiSelect: {
                entitiesSelected: [],
                paxFull: false,
            },
        };
    },
    mixins: [LangsEnum],
    props: {
        startStep: {
            type: Number,
            default: 1,
        },
        restaurant_id: {
            default: null,
        },
        date: {
            default: null,
        },
        tablesIdToPreselect: {
            default: () => {
                return [];
            },
        },
        serviceIdToPreselect: {
            type: Number,
            default: null,
        },
        slotIdToPreselect: {
            type: Number,
            default: null,
        },
        defaultValues: {
            type: Object,
            default: null,
        },
    },
    computed: {
        currentRestaurant() {
            return this.restaurant || this.baseRestaurant;
        },
        invalidPax() {
            return this.step > 0 && ([0, null].includes(this.reservation.nb_pers) || this.nb_pers_total < 1);
        },
        noTableSelected() {
            return (
                this.step === 3 &&
                this.reservation.service &&
                this.reservation.service.is_seating_plan_algorithm_enabled &&
                !this.$refs.currentStepComponent.can_place &&
                this.vueMultiSelect.entitiesSelected.length === 0
            );
        },
        step: {
            get() {
                if (this.stepData === null) return this.startStep;
                return this.stepData;
            },
            set(newVal) {
                this.stepData = newVal;
                this.$emit("change-step", newVal);
            },
        },
        currentStepComponent() {
            return `Step${this.step}`;
        },
        selected_restaurant_id() {
            if (this.selected_destination_restaurant) {
                return this.selected_destination_restaurant.id;
            }

            return this.restaurant_id;
        },
        nb_pers_total() {
            const nb_pers = this.reservation.nb_pers || 0;
            const nb_children = this.reservation.nb_children || 0;
            return nb_pers + nb_children;
        },
        tablesSelected() {
            var result = [];
            for (var entity of this.vueMultiSelect.entitiesSelected) {
                switch (entity.type) {
                    case "table":
                        result.push({
                            id: entity.id,
                            pax: this.nb_pers_total,
                        });
                        break;
                    case "group":
                        for (const tableIndex in entity.tables) {
                            const table = entity.tables[tableIndex];

                            result.push({
                                id: table.id,
                                pax: this.nb_pers_total,
                            });
                        }
                        break;
                }
            }

            return result;
        },
    },
    methods: {
        transformMenus() {
            if (this.reservation.choosen_menus instanceof Array) {
                return this.reservation.choosen_menus.reduce((accumulator, menu) => {
                    if (menu.quantity && menu.quantity > 0) {
                        accumulator[`id_${menu.id}`] = {
                            id: menu.id,
                            value: menu.quantity,
                            options: [],
                        };

                        accumulator[`id_${menu.id}`].options = menu.options.reduce((accumulator, option) => {
                            if (option.quantity && option.quantity > 0) {
                                accumulator[`id_${option.id}`] = {
                                    id: option.id,
                                    value: option.quantity,
                                };
                            }

                            return accumulator;
                        }, {});
                    }
                    return accumulator;
                }, {});
            }
            return this.reservation.choosen_menus;
        },
        formatMenus() {
            delete this.reservation.back_choosen_menus;
            delete this.reservation.back_choosen_general_options;

            /** Useful to keep backward compatibility for now */
            this.reservation.choosen_menus = this.transformMenus();
        },
        selectDestinationRestaurant(restaurant) {
            this.selected_destination_restaurant = restaurant;
            this.reservation.slot = null;
            this.reservation.slot_id = null;
            this.reservation.choosen_menus = {};
            this.reservation.choosen_general_options = {};
            this.reservation.service = null;
        },
        setStep(step) {
            let errorMsg = undefined;
            if (step <= 3) this.reservation.ignore_placement = false;
            if (this.invalidPax && step > 1) errorMsg = this.$tl("errors.booking.reservations.invalidPax");
            else if (this.noTableSelected && step > this.step && !this.reservation.ignore_placement)
                errorMsg = this.$tl("errors.booking.reservations.cantPlace", this.restaurant_id);
            if (errorMsg)
                this.$notify({
                    group: "notification",
                    type: "error",
                    title: errorMsg,
                });
            else this.step = step;
        },
        loadRestaurant() {
            this.loading = true;
            this.error = null;

            axios
                .get(`/api/restaurants/${this.selected_restaurant_id}?include=closures,payment_notif_settings,custom_tags`)
                .then((response) => {
                    this.loading = false;
                    if (response.data.id == this.restaurant_id) this.baseRestaurant = response.data;
                    this.restaurant = response.data;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        save(option = false) {
            this.loading = true;
            this.error = null;
            this.formErrors = null;

            this.formatMenus();

            axios
                .post(`/api/restaurants/${this.selected_restaurant_id}/reservations/add`, {
                    ...this.reservation,
                    ...this.reservation.client,
                    client_id: this.reservation.client.id,
                    phoneNumber: this.reservation.client.phone.raw ? this.reservation.client.phone.raw : null,
                    phoneCountry: this.reservation.client.phone.country,
                    stripeToken: false,
                    restaurant_id: this.selected_restaurant_id,
                    tables: this.tablesSelected,
                    option,
                })
                .then(() => {
                    this.$emit("saved");
                })
                .catch((error) => {
                    this.loading = false;
                    const errorMsg = this.getErrorMsgFromErrorResponse(error, this.$tl("errors.common.checkAllFields"));
                    if (error.response && error.response.data && error.response.data.errors) this.formErrors = error.response.data;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
        saveAsOption() {
            this.save(true);
        },
        saveInWaitingList() {
            this.loading = true;
            this.error = null;
            this.formErrors = null;

            this.formatMenus();

            axios
                .post(`/api/restaurants/${this.selected_restaurant_id}/service/waiting`, {
                    ...this.reservation.client,
                    client_id: this.reservation.client.id,
                    phone_number: this.reservation.client.phone.raw ? this.reservation.client.phone.raw : null,
                    phone_country: this.reservation.client.phone.country,
                    reservation_date: this.reservation.reservation_date.toISODate(),
                    nb_pers: this.reservation.nb_pers,
                    nb_children: this.reservation.nb_children,
                    slot_id: this.reservation.slot_id,
                    restaurant_id: this.selected_restaurant_id,
                    restaurant_comment: this.reservation.restaurant_comment,
                    room_number: this.reservation.room_number,
                    choosen_menus: this.reservation.choosen_menus,
                    choosen_general_options: this.reservation.choosen_general_options,
                })
                .then(() => {
                    this.$emit("saved");
                })
                .catch((error) => {
                    this.loading = false;

                    this.notifyError(error, this.$tl("errors.common.checkAllFields"));

                    if (error.response && error.response.data && error.response.data.errors) {
                        this.formErrors = error.response.data;
                    }
                });
        },
    },
    watch: {
        selected_restaurant_id: {
            immediate: true,
            handler() {
                this.loadRestaurant();
            },
        },
    },
    components: {
        Stepper,
        Step0,
        Step1,
        Step2,
        Step3,
        Step4,
        LoaderComponent,
    },
    created() {
        if (this.date) {
            this.reservation.reservation_date = this.getDateTime(this.date, false);
        } else if (this.$route.query.date) {
            this.reservation.reservation_date = this.getDateTime(this.$route.query.date, false);
        } else {
            this.reservation.reservation_date = this.getDateTime();
        }

        if (this.defaultValues) {
            _.merge(this.reservation, this.defaultValues);
        }

        this.reservation.client.locale = this.userLang;
    },
};
</script>
