<template>
    <div class="pb-5">
        <div class="row m-0">
            <div class="col-12">
                <div
                    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Ventes</h5>
                </div>
            </div>
        </div>
        <div class="row m-0 pb-3">
            <div class="col-12">
                <LoaderComponent v-if="loading > 0" />
                <div v-else>
                    <div v-if="error" class="alert alert-danger">
                        {{ error }}
                    </div>
                    <div v-else>
                        <div class="d-flex justify-content-between mb-2">
                            <h5>Commandes à expédier</h5>
                            <button
                                :disabled="!has_right_to_export_commands"
                                @click="exportCommands()"
                                class="btn btn-sm btn-outline-secondary btn-circle">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-upload">
                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                    <polyline points="17 8 12 3 7 8"></polyline>
                                    <line x1="12" y1="3" x2="12" y2="15"></line>
                                </svg>
                                Export Excel
                            </button>
                        </div>
                        <command-list
                            :restaurant_id="restaurant_id"
                            :commands="commands"
                            @status-updated="fetchData(links.current)"
                            :type="COLLECT_TYPE_DISPATCH.value"
                            :columns="{
                                number: true,
                                client: true,
                                status: {
                                    show: true,
                                    readonly: false,
                                },
                                date: true,
                                slot: true,
                                sendcloud_status: true,
                                dispatch_fees: true,
                                price: true,
                                products: true,
                                actions: {
                                    edit: false,
                                    send: true,
                                    clickable: true,
                                    download_label: true,
                                    print_order_form: true,
                                },
                            }" />
                    </div>
                </div>
            </div>
        </div>
        <span class="badge badge-secondary ml-3 mr-2 float-left mt-1" v-if="links.total !== null">
            {{ links.total }} commande{{ links.total > 1 ? "s" : "" }}
        </span>
        <div class="ml-3" v-if="links.previous || links.next">
            <button
                class="btn btn-sm btn-outline-secondary btn-circle"
                v-if="links.previous"
                @click="fetchData(links.previous)">
                Précédent
            </button>
            <button
                class="btn btn-sm btn-outline-secondary btn-circle"
                v-if="links.next"
                @click="fetchData(links.next)">
                Suivant
            </button>
        </div>
        <show-command-modal
            :command_id="modals.params.selected_command_id"
            :restaurant_id="modals.params.selected_restaurant_id"
            @close="modals.displayShowCommandModal = false"
            v-if="modals.displayShowCommandModal" />
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import addClickAndCollectSaleModal from "../../../components/Modals/clickAndCollect/addClickAndCollectSaleModal.vue";
import moment from "moment";
import ClickAndCollectStatusTag from "../../../components/clickAndCollect/Command/StatusTag";
import ShowCommandModal from "../../../components/Modals/clickAndCollect/ShowClickAndCollectCommand";
import CommandList from "../../../components/clickAndCollect/Command/CommandList";
import CollectTypesEnum from "../../../mixins/enums/click_and_collect/CollectTypesEnum";
import CommandStatusEnum from "../../../mixins/enums/click_and_collect/CommandStatusEnum";

export default {
    data() {
        return {
            loading: 0,
            error: null,
            commands_: {
                data: [],
            },
            modals: {
                params: {
                    selected_command_id: null,
                    selected_restaurant_id: null,
                },
                displayShowCommandModal: false,
            },
            socket: {
                channel: null,
            },
            links: {
                prev: null,
                next: null,
                total: null,
                current: null,
            },
        };
    },
    mixins: [CollectTypesEnum, CommandStatusEnum],
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        commands() {
            return {
                ...this.commands_,
                data: this.commands_.data.filter((c) => c.status !== this.COMMAND_PENDING.value),
            };
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_export_commands() {
            return this.rights.includes("click_and_collect.commands.export");
        },
    },
    created() {
        // this.registerSocketsForDate();
        this.fetchData();
    },
    beforeDestroy() {
        // this.unregisterAllSockets();
    },
    methods: {
        exportCommands() {
            if (!this.has_right_to_export_commands) return;
            let api_url = `/api/click_and_collect/${this.$route.params.restaurant_id}/export/commands/${this.COLLECT_TYPE_DISPATCH.value}`;
            window.open(api_url, "_blank");
        },
        fetchData(url = null) {
            this.loading++;
            this.error = null;
            this.links.previous = null;
            this.links.next = null;
            this.links.total = null;
            this.links.current = url;
            this.$store
                .dispatch("ccCommands/fetchCommandsAtDate", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        date: "all",
                        type: this.COLLECT_TYPE_DISPATCH.value,
                        paginate: 20,
                    },
                    url,
                })
                .then((response) => {
                    this.commands_ = response.data;
                    if (response.data.meta && response.data.meta.pagination) {
                        if (response.data.meta.pagination.links) {
                            if (response.data.meta.pagination.links.next)
                                this.links.next = response.data.meta.pagination.links.next;
                            if (response.data.meta.pagination.links.previous)
                                this.links.previous = response.data.meta.pagination.links.previous;
                        }
                        this.links.total = response.data.meta.pagination.total;
                    }
                    this.loading--;
                })
                .catch((error) => {
                    if (error.response && error.response.data.message) {
                        this.error = error.response.data.message;
                    } else if (error.message) {
                        this.error = error.message;
                    } else {
                        this.error = "Une erreur est survenue.";
                    }
                    this.loading--;
                });
        },
        showCommand(command, event) {
            const excludedTags = ["BUTTON", "A"];

            if (!event || !event.target || !excludedTags.includes(event.target.tagName)) {
                this.modals.params.selected_command_id = command.id;
                this.modals.params.selected_restaurant_id = command.restaurant_id;
                this.$nextTick(() => {
                    this.modals.displayShowCommandModal = true;
                });
            }
        },
        sortCommandsHandler(commandA, commandB) {
            const statusScore = {
                pending: 0,
                to_prepare: 1,
                ready: 2,
                collected: 3,
                canceled: 4,
            };

            return statusScore[commandA.status] - statusScore[commandB.status];
        },
    },
    watch: {
        restaurant_id() {
            // this.unregisterSockets();
            // this.registerSocketsForDate();
            this.fetchData();
        },
    },
    components: {
        LoaderComponent,
        addClickAndCollectSaleModal,
        ClickAndCollectStatusTag,
        ShowCommandModal,
        CommandList,
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
