<template>
    <modal>
        <h5 slot="header">{{ $tl("labels.booking.reservations.passingCustomer.add.title") }}</h5>
        <div slot="body">
            <add-passing-customer
                ref="addPassingCustomer"
                :restaurant-id="restaurantId"
                :service-id="serviceId"
                :tablesIdToPreselect="tablesIdToPreselect"
                :date="date"
                :defaultValues="defaultValues"
                @enable-next-button="isNextButtonEnabled = $event"
                @set-step="step = $event"
                @saved="$emit('saved')" />
        </div>
        <div slot="footer" class="d-flex">
            <button class="modal-default-button btn btn-sm btn-secondary btn-circle" @click.prevent="close">
                {{ $tl("labels.form.actions.cancel", restaurantId) }}
            </button>
            <button v-if="step > 1" class="btn btn-outline-secondary btn-sm btn-circle ml-2" @click.prevent="$refs.addPassingCustomer.previousStep">
                {{ $tl("labels.form.actions.previous", restaurantId) }}
            </button>
            <button
                v-if="step < 3"
                class="btn btn-outline-secondary btn-sm btn-circle ml-2"
                :disabled="!isNextButtonEnabled"
                @click.prevent="$refs.addPassingCustomer.nextStep">
                {{ $tl("labels.form.actions.next", restaurantId) }}
            </button>
            <button v-if="step === 3" class="btn btn-success btn-sm btn-circle ml-2" @click.prevent="$refs.addPassingCustomer.save">
                {{ $tl("labels.form.actions.save", restaurantId) }}
            </button>
        </div>
    </modal>
</template>

<script>
import { DateTime } from "luxon";
import AddPassingCustomer from "../../reservations/add/AddPassingCustomer.vue";

export default {
    data() {
        return {
            step: 1,
            isNextButtonEnabled: true,
        };
    },
    props: {
        restaurantId: {
            type: Number,
            required: true,
        },
        serviceId: {
            type: Number,
            required: true,
        },
        date: {
            type: DateTime,
            required: true,
        },
        defaultValues: {
            type: Object,
            default: null,
        },
        tablesIdToPreselect: {
            default: () => [],
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
    components: {
        AddPassingCustomer,
    },
};
</script>
