var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "strong",
          {
            staticClass: "pointer",
            on: {
              click: function ($event) {
                _vm.show = !_vm.show
              },
            },
          },
          [_vm._v("CGV / CGU / CP Acceptées")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.show
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm.cgvs && _vm.cgvs.length > 0
              ? _c(
                  "table",
                  { staticClass: "table table-sm table-striped border-bottom" },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.cgvs, function (cgv) {
                        return _c("tr", { key: cgv.id }, [
                          _c("td", {
                            domProps: {
                              innerHTML: _vm._s(_vm.getFormattedType(cgv)),
                            },
                          }),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                cgv.email_address || "Aucun mail n'a été envoyé"
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(cgv.ip_address || "--"))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.moment(cgv.created_at).format("DD/MM/Y")
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  cgv.email_delivered_at
                                    ? _vm
                                        .moment(cgv.email_delivered_at)
                                        .format("DD/MM/Y")
                                    : "--"
                                ) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                cgv.email_opened_at
                                  ? _vm
                                      .moment(cgv.email_opened_at)
                                      .format("DD/MM/Y")
                                  : "--"
                              )
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                )
              : _c("div", [
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v("Cet utilisateur n'a rien accepté"),
                  ]),
                ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", [_vm._v("Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Email")]),
      _vm._v(" "),
      _c("th", [_vm._v("Adresse IP")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date d'acceptation")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date de réception du mail")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date d'ouverture du mail")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }