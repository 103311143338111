export default {
    booking: {
        menus: {
            deleted: "The menu has been deleted",
        },
        seatingPlan: {
            rooms: {
                enabledForService: "Room enabled for service",
                disabledForService: "Room disabled for service",
            },
            tables: {
                enabledForService: "Table enabled for service",
                disabledForService: "Table disabled for service",
            },
        },
        services: {
            added: "The service has been added.",
            duplicated: "The service has been duplicated",
            deleted: "The service has been deleted",
            nowFull: 'The selected services are now displayed as "full".',
            removeFull: "The selected service is no longer full",
        },
    },
};
