var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5" },
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "error" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c("div", [
                  _c("div", { staticClass: "row m-0" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1",
                          class: { "mb-1": _vm.hasMore, "pb-2": !_vm.hasMore },
                        },
                        [
                          _c("h5", { staticClass: "title mt-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.reservations.created.title"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.hasMore
                        ? _c(
                            "small",
                            { staticClass: "d-block text-warning mb-3" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$t(
                                      "infos.booking.reservations.onlyXDisplayed",
                                      { viewLimit: _vm.viewLimit }
                                    )
                                  ) +
                                  "\n                        "
                              ),
                              _c("br"),
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "infos.booking.reservations.viewMoreCreated"
                                  )
                                ) + "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row m-0" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _vm.restaurant
                        ? _c("div", [
                            _vm.dates.length <= 0
                              ? _c("div", [
                                  _c("em", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.reservations.created.empty",
                                          _vm.restaurant_id
                                        )
                                      )
                                    ),
                                  ]),
                                ])
                              : _c(
                                  "div",
                                  _vm._l(_vm.dates, function (date, dateIndex) {
                                    return _c(
                                      "div",
                                      { key: dateIndex },
                                      [
                                        _c(
                                          "h5",
                                          {
                                            staticClass: "mb-3",
                                            staticStyle: { color: "#083e60" },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.displayDate(
                                                    date.full_date,
                                                    _vm.DATE_FULL_NO_YEAR,
                                                    false
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          date.services,
                                          function (service, serviceIndex) {
                                            return _c(
                                              "div",
                                              {
                                                key: `${dateIndex}-${serviceIndex}`,
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-wrap justify-content-between flex-md-nowrap pb-2 mb-3 border-bottom",
                                                  },
                                                  [
                                                    _c(
                                                      "h6",
                                                      {
                                                        staticStyle: {
                                                          "text-transform":
                                                            "uppercase",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm.getDateTime(
                                                                date.full_date
                                                              ).weekdayLong
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "capacite badge badge-secondary",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getServiceCategoryLabel(
                                                                  service
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("h6", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "capacite badge badge-secondary",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm
                                                                .$tl(
                                                                  "labels.booking.reservations.created.confirmedPAX"
                                                                )
                                                                .toUpperCase()
                                                            ) +
                                                              " : " +
                                                              _vm._s(
                                                                service.pax
                                                              ) +
                                                              " /\n                                                " +
                                                              _vm._s(
                                                                service.new_pax !==
                                                                  null
                                                                  ? service.new_pax
                                                                  : service.max_pax
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "border-bottom mb-5",
                                                  },
                                                  [
                                                    _c(
                                                      "table",
                                                      {
                                                        staticClass:
                                                          "capitalize table-size-30 table table-book table-striped table-sm",
                                                      },
                                                      [
                                                        _c(
                                                          "thead",
                                                          {
                                                            staticClass:
                                                              "border-bottom",
                                                          },
                                                          [
                                                            _c("tr", [
                                                              _c(
                                                                "th",
                                                                [
                                                                  _c(
                                                                    "feather",
                                                                    {
                                                                      staticClass:
                                                                        "none-desk",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "20px",
                                                                          height:
                                                                            "20px",
                                                                        },
                                                                      attrs: {
                                                                        type: "user",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "none-mobile",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$tl(
                                                                            "labels.booking.reservations.grid.columns.client"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "th",
                                                                [
                                                                  _c(
                                                                    "feather",
                                                                    {
                                                                      staticClass:
                                                                        "none-desk",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "20px",
                                                                          height:
                                                                            "20px",
                                                                        },
                                                                      attrs: {
                                                                        type: "users",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "none-mobile",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$tl(
                                                                            "labels.booking.reservations.grid.columns.pax"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "th",
                                                                [
                                                                  _c(
                                                                    "feather",
                                                                    {
                                                                      staticClass:
                                                                        "none-desk",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "20px",
                                                                          height:
                                                                            "20px",
                                                                        },
                                                                      attrs: {
                                                                        type: "clock",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "none-mobile",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$tl(
                                                                            "labels.booking.reservations.grid.columns.hour"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "th",
                                                                {
                                                                  staticClass:
                                                                    "none-mobile",
                                                                },
                                                                [
                                                                  _c(
                                                                    "feather",
                                                                    {
                                                                      staticClass:
                                                                        "none-desk",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "20px",
                                                                          height:
                                                                            "20px",
                                                                        },
                                                                      attrs: {
                                                                        type: "monitor",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "none-mobile",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$tl(
                                                                            "labels.booking.reservations.grid.columns.table",
                                                                            _vm.restaurant_id
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "th",
                                                                [
                                                                  _c(
                                                                    "feather",
                                                                    {
                                                                      staticClass:
                                                                        "none-desk",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "20px",
                                                                          height:
                                                                            "20px",
                                                                        },
                                                                      attrs: {
                                                                        type: "check-circle",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "none-mobile",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$tl(
                                                                            "labels.booking.reservations.grid.columns.status"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "th",
                                                                {
                                                                  staticClass:
                                                                    "none-mobile",
                                                                },
                                                                [
                                                                  _c(
                                                                    "feather",
                                                                    {
                                                                      staticClass:
                                                                        "none-desk",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "20px",
                                                                          height:
                                                                            "20px",
                                                                        },
                                                                      attrs: {
                                                                        type: "credit-card",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "none-mobile",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$tl(
                                                                            "labels.booking.reservations.grid.columns.optionBankStatus"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "th",
                                                                {
                                                                  staticClass:
                                                                    "none-mobile",
                                                                },
                                                                [
                                                                  _c(
                                                                    "feather",
                                                                    {
                                                                      staticClass:
                                                                        "none-desk",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "20px",
                                                                          height:
                                                                            "20px",
                                                                        },
                                                                      attrs: {
                                                                        type: "mouse-pointer",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "none-mobile",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$tl(
                                                                            "labels.booking.reservations.grid.columns.actions"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "tbody",
                                                          _vm._l(
                                                            service
                                                              .createdReservations
                                                              .data,
                                                            function (
                                                              reservation
                                                            ) {
                                                              return _c(
                                                                "tr",
                                                                {
                                                                  key: reservation.id,
                                                                  staticClass:
                                                                    "pointer",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showResa(
                                                                          reservation.id,
                                                                          $event
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "inline-client",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              client:
                                                                                reservation.client,
                                                                              "module-enum":
                                                                                _vm.MODULE_TYPE_BOOKING,
                                                                              "public-comment":
                                                                                reservation.comment,
                                                                              "private-comment":
                                                                                reservation.restaurant_comment,
                                                                              "show-noshows":
                                                                                "",
                                                                            },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "icons-start",
                                                                                  fn: function () {
                                                                                    return [
                                                                                      reservation.gift
                                                                                        ? _c(
                                                                                            "feather",
                                                                                            {
                                                                                              directives:
                                                                                                [
                                                                                                  {
                                                                                                    name: "tooltip",
                                                                                                    rawName:
                                                                                                      "v-tooltip",
                                                                                                    value:
                                                                                                      _vm.getTooltip(
                                                                                                        reservation.gift
                                                                                                      ),
                                                                                                    expression:
                                                                                                      "getTooltip(reservation.gift)",
                                                                                                  },
                                                                                                ],
                                                                                              staticClass:
                                                                                                "feather-blue",
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "tag",
                                                                                                },
                                                                                            }
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  },
                                                                                  proxy: true,
                                                                                },
                                                                              ],
                                                                              null,
                                                                              true
                                                                            ),
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "table-text-center",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                        " +
                                                                          _vm._s(
                                                                            reservation.nb_pers *
                                                                              1 +
                                                                              reservation.nb_children *
                                                                                1
                                                                          ) +
                                                                          "\n                                                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      "\n                                                        " +
                                                                        _vm._s(
                                                                          reservation
                                                                            .slot
                                                                            .hour_start
                                                                        ) +
                                                                        "\n                                                    "
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "table-text-center none-mobile",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                        " +
                                                                          _vm._s(
                                                                            reservation.num_table
                                                                          ) +
                                                                          "\n                                                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    [
                                                                      _c(
                                                                        "ReservationTag",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              cancellation:
                                                                                service.cancellation_until,
                                                                              update: false,
                                                                              reservation:
                                                                                reservation,
                                                                              restaurant_id:
                                                                                _vm
                                                                                  .restaurant
                                                                                  .id,
                                                                              isStripeEnabled:
                                                                                _vm
                                                                                  .restaurant
                                                                                  .stripe_client_id ||
                                                                                (_vm
                                                                                  .restaurant
                                                                                  .payplug_public_key &&
                                                                                  _vm
                                                                                    .restaurant
                                                                                    .payplug_secret_key),
                                                                            },
                                                                          on: {
                                                                            displayCancelModal:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.displayCancelModal(
                                                                                  {
                                                                                    newStatus:
                                                                                      $event,
                                                                                    data: reservation,
                                                                                  }
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "table-text-center none-mobile",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "option-bank-status-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              reservation:
                                                                                reservation,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "none-mobile",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "tooltip",
                                                                                rawName:
                                                                                  "v-tooltip",
                                                                                value:
                                                                                  _vm.getTooltip(
                                                                                    _vm.$t(
                                                                                      "labels.form.actions.edit"
                                                                                    )
                                                                                  ),
                                                                                expression:
                                                                                  "getTooltip($t('labels.form.actions.edit'))",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "btn btn-sm btn-success btn-square",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.editReservation(
                                                                                  $event,
                                                                                  reservation.id
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "feather",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "edit",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      reservation.status ===
                                                                      "created"
                                                                        ? _c(
                                                                            "button",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "tooltip",
                                                                                    rawName:
                                                                                      "v-tooltip",
                                                                                    value:
                                                                                      _vm.getTooltip(
                                                                                        _vm.$t(
                                                                                          "labels.booking.reservations.sendDetailsToClient"
                                                                                        )
                                                                                      ),
                                                                                    expression:
                                                                                      "getTooltip($t('labels.booking.reservations.sendDetailsToClient'))",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "btn btn-sm btn-outline-secondary btn-square",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.sendConfirmationEmail(
                                                                                      reservation.id
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "feather",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      type: "send",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
          ]),
      _vm._v(" "),
      _vm.showReservationModal
        ? _c("show-reservation-modal", {
            attrs: {
              reservation_id: _vm.selected_reservation_id,
              restaurant_id: _vm.selected_restaurant_id,
            },
            on: {
              "resa-edit": _vm.editReservationFromShowModal,
              close: function ($event) {
                _vm.showReservationModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showEditReservationModal
        ? _c("edit-reservation-modal", {
            attrs: {
              restaurant_id: _vm.selected_restaurant_id,
              reservation_id: _vm.selected_reservation_id,
            },
            on: {
              close: function ($event) {
                _vm.showEditReservationModal = false
              },
              "reservation-edited": _vm.reservationEdited,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showCancelModal
        ? _c("cancel-reservation-modal", {
            attrs: {
              "reservation-id": _vm.selected_reservation_id,
              client: _vm.selected_reservation_client,
              status: _vm.newCancelStatus,
            },
            on: {
              close: function ($event) {
                _vm.showCancelModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }