<template>
    <modal @close="closeFromModal" style="overflow: auto" :modalContainerStyle="`overflow: visible`">
        <h5 slot="header">{{ $tl("labels.booking.reservations.noshowStatus", restaurant_id) }}</h5>
        <div slot="body">
            <set-noshow
                :restaurant_id="restaurant_id"
                :reservation_id="reservation.id"
                :showSaveButton="false"
                v-on:noShowTaken="noShowTaken"
                :ref="`setNoshow`" />
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="ml-5 btn btn-secondary btn-circle btn btn-sm" @click="$emit('close')">Annuler</button>
            <div class="d-flex mr-5">
                <div tabindex="-1" :ref="`dropdown-main`" class="dropdown" v-if="reservation.client && reservation.chargable_if_no_show">
                    <button
                        class="btn-sm btn-danger btn-circle btn dropdown-toggle"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        Prélever
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <template v-if="reservation.payment.card_system_type === CARD_PAYMENT_SYSTEM_STRIPE.value">
                            <button
                                v-for="n in totalNbPers"
                                :key="n"
                                @click="takeNoShow(n)"
                                class="dropdown-item"
                                type="button"
                                :disabled="n * reservation.amount_if_noshow > reservation.stripe_intent_amount">
                                {{ n }} personne(s) ({{ (n * reservation.amount_if_noshow) / 100 }}€)
                            </button>
                        </template>
                        <button
                            v-else
                            @click="takeNoShow(totalNbPers)"
                            class="dropdown-item"
                            type="button"
                            :disabled="totalNbPers * reservation.amount_if_noshow > reservation.stripe_intent_amount">
                            {{ totalNbPers }} personne(s) ({{ (totalNbPers * reservation.amount_if_noshow) / 100 }}€)
                        </button>
                    </div>
                </div>
                <button @click="markNoShow" class="ml-2 btn btn-sm btn-warning btn-circle text-white">
                    {{ $tl("labels.booking.reservations.noshowWithoutDeduction", restaurant_id) }}
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import SetNoshow from "../../reservations/setNoshow.vue";
import CardPaymentSystemTypeEnum from "../../../mixins/enums/CardPaymentSystemEnum";

export default {
    props: {
        restaurant_id: {
            required: true,
        },
        reservation: {
            required: true,
        },
    },
    mixins: [CardPaymentSystemTypeEnum],
    components: {
        SetNoshow,
    },
    computed: {
        totalNbPers() {
            return this.reservation.nb_pers + this.reservation.nb_children;
        },
    },
    mounted() {
        $("#dropdownMenu2").dropdown();
    },
    methods: {
        takeNoShow(nb_people) {
            this.$refs["setNoshow"]
                .takeNoShow(nb_people)
                .then(() => {
                    this.notifySuccess(null, this.$tl("success.booking.reservations.updated"));

                    this.close();
                })
                .catch((error) => {
                    this.notifyError(error);

                    this.$refs["setNoshow"].setLoading(false);
                });
        },
        noShowTaken() {
            this.location.reload(true);
        },
        markNoShow() {
            this.$refs["setNoshow"]
                .markNoShow()
                .then(() => {
                    this.notifySuccess(null, this.$tl("success.booking.reservations.updated"));

                    this.close();
                })
                .catch((error) => {
                    this.notifyError(error);

                    this.$refs["setNoshow"].setLoading(false);
                });
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
};
</script>
