<template>
    <div>
        <toolbar :toolbar-settings="toolbarSettings" :data-source="filteredDataSource" @emit-event="$emit($event.eventName, $event.data)">
            <slot name="toolbar"></slot>
        </toolbar>

        <table :ref="`ns-table-${refSuffix}`" :class="tableClass">
            <thead v-if="!useSlotHeadings">
                <slot name="head"></slot>
            </thead>
            <slot v-else name="head"></slot>
            <tbody v-if="!useSlotBody">
                <tr
                    v-for="(data, index) in filteredDataSource"
                    :key="filteredDataSource[keyColumn] || index"
                    @click="onRowSelected({ event: $event, data })"
                    :class="{
                        pointer: clickable,
                        clickable,
                        new: data.new,
                        updated: data.updated,
                    }">
                    <slot name="body" v-bind="{ data }"></slot>
                </tr>
            </tbody>
            <slot v-else name="body" v-bind="{ filteredDataSource, clickable, keyColumn }"></slot>
        </table>

        <Pagination
            v-if="allowPaging"
            class="mt-2"
            v-bind="getPaginationSettings"
            @set-current-page="setAttributePage($event, 'currentPage')"
            @set-nb-results-per-page="setAttributePage($event, 'nbResultsPerPage')">
        </Pagination>
    </div>
</template>

<script>
import Pagination from "./Pagination.vue";
import Toolbar from "./Toolbar/Toolbar.vue";

/**
 * pageSettings: {
 *  ?nbResultsPerPage: int,
 *  ?currentPage: int,
 *  ?nbNumPages: int,
 * },
 * toolbarSettings: Object, see component for details
 */
export default {
    data() {
        return {
            sortedDataSource: [],
            sort: {
                field: null,
                order: null,
                comparer: null,
            },
            filters: [],
            paging: {
                currentPage_: null,
                nbResultsPerPage_: null,
                nbNumPages_: null,
            },
            refSuffix: "",
        };
    },
    props: {
        dataSource: {
            type: Array,
            required: true,
        },
        allowPaging: {
            type: Boolean,
            default: false,
        },
        pageSettings: {
            type: Object,
            required: false,
        },
        keyColumn: {
            type: String,
            default: "id",
        },
        tableClass: {
            type: [String, Object],
        },
        clickable: {
            type: Boolean,
            default: false,
        },
        toolbarSettings: {
            type: Object,
        },
        useSlotHeadings: {
            type: Boolean,
            default: false,
        },
        useSlotBody: {
            type: Boolean,
            default: false,
        },
        isServerSide: {
            type: Boolean,
            default: false,
        },
        currentSort: {
            type: Object,
            required: false,
        },
        pagination: {
            type: Object,
            required: false,
        },
    },
    computed: {
        getPaginationSettings() {
            let isPaginationServerSide = this.isServerSide;

            if (!isPaginationServerSide && this.pagination instanceof Object) {
                isPaginationServerSide = !!this.pagination.isServerSide;
            }

            return {
                nbPages: isPaginationServerSide ? this.pagination.total_pages : this.nbPages,
                nbResultsPerPage: isPaginationServerSide ? this.pagination.per_page : this.nbResultsPerPage,
                currentPage: isPaginationServerSide ? this.pagination.current_page : this.currentPage,
                nbResults: isPaginationServerSide ? this.pagination.total : this.dataSource.length,
                nbNumPages: this.nbNumPages,
            };
        },
        currentDataSource() {
            return this.dataSource.slice();
        },
        filteredDataSource() {
            if (this.isServerSide) {
                return this.currentDataSource;
            }
            let filteredDataSource = this.applyFilter(this.sortedDataSource);

            if (this.allowPaging && this.nbResultsPerPage !== "all") {
                filteredDataSource = filteredDataSource.slice(
                    (this.currentPage - 1) * this.nbResultsPerPage,
                    this.currentPage * this.nbResultsPerPage
                );
            }
            return filteredDataSource;
        },
        nbPages() {
            if (this.nbResultsPerPage !== "all") {
                return Math.ceil(this.dataSource.length / this.nbResultsPerPage);
            }

            return 1;
        },
        nbResultsPerPage: {
            get() {
                if (this.paging.nbResultsPerPage_ !== null) {
                    return this.paging.nbResultsPerPage_;
                }

                if (typeof this.pageSettings !== "undefined" && typeof this.pageSettings.nbResultsPerPage !== "undefined") {
                    return this.pageSettings.nbResultsPerPage;
                }

                return 12;
            },
            set(value) {
                this.paging.nbResultsPerPage_ = value;
            },
        },
        currentPage: {
            get() {
                if (this.paging.currentPage_ !== null) {
                    return this.paging.currentPage_;
                }

                if (typeof this.pageSettings !== "undefined" && typeof this.pageSettings.currentPage !== "undefined") {
                    return this.pageSettings.currentPage;
                }

                return 1;
            },
            set(value) {
                this.paging.currentPage_ = value;
            },
        },
        nbNumPages: {
            get() {
                if (this.paging.nbNumPages_ !== null) {
                    return this.paging.nbNumPages_;
                }

                if (typeof this.pageSettings !== "undefined" && typeof this.pageSettings.nbNumPages !== "undefined") {
                    return this.pageSettings.nbNumPages;
                }

                return 10;
            },
            set(value) {
                this.paging.nbNumPages_ = value;
            },
        },
    },
    methods: {
        emitUpdateData() {
            const options = { ...this.sort, dynamic_filters: this.filters };

            if (this.allowPaging) {
                options.page = this.currentPage;
                options.per_page = this.nbResultsPerPage;
            }
            this.$emit("update-data", options);
        },
        setAttributePage($event, attribute) {
            this[attribute] = $event;
            if (this.isServerSide && attribute === "currentPage") {
                this.emitUpdateData();
            }
        },
        sortToCheck() {
            if (this.isServerSide) {
                return [this.currentSort.field, this.currentSort.order];
            }
            return [this.sort.field, this.sort.order];
        },
        setSort(field = null, order = null, comparer = null) {
            this.sort.field = field;
            this.sort.order = order;
            if (!this.isServerSide) {
                this.sort.comparer = comparer;
            }
        },
        sortDataSource({ field, comparer }) {
            const [fieldToCheck, order] = this.sortToCheck();

            if (field !== fieldToCheck || order === null) {
                this.setSort(field, "asc", comparer);
            } else if (this.sort.order === "desc") {
                this.setSort();
            } else {
                this.setSort(field, "desc", comparer);
            }
            return this.isServerSide ? this.emitUpdateData() : this.applySort();
        },
        applySort() {
            this.sortedDataSource = [...this.currentDataSource];
            if (this.sort.field !== null && this.sort.order !== null) {
                this.sortedDataSource.sort((a, b) => {
                    let result = null;

                    if (typeof this.sort.comparer === "function") {
                        result = this.sort.comparer(a, b);
                    } else {
                        result = this.defaultSortComparer(this.$_.get(a, this.sort.field), this.$_.get(b, this.sort.field));
                    }

                    if (this.sort.order === "asc") {
                        result *= -1;
                    }

                    return result;
                });
            }
        },
        defaultSortComparer(a, b) {
            if (typeof a === "string" && typeof b === "string") {
                return (a || "").localeCompare(b || "");
            }

            return (a || 0) - (b || 0);
        },
        filterDataSource({ field, filterOptions }) {
            const currentFilterIndex = this.filters.findIndex((filter) => filter.field === field);

            if (currentFilterIndex !== -1) {
                this.filters.splice(currentFilterIndex, 1, { field, filterOptions });
            } else {
                this.filters.push({ field, filterOptions });
            }
        },
        applyFilter(dataSource, currentFilterIndex = 0) {
            if (currentFilterIndex >= this.filters.length) {
                return dataSource;
            }
            const currentFilter = this.filters[currentFilterIndex];

            if (currentFilter.filterOptions.type === "choices") {
                if (typeof currentFilter.filterOptions.filterFunction === "function") {
                    dataSource = currentFilter.filterOptions.filterFunction(dataSource, currentFilter.filterOptions.choices);
                } else {
                    dataSource = dataSource.filter((data) => currentFilter.filterOptions.choices.includes(this.$_.get(data, currentFilter.field)));
                }
            }

            if (currentFilterIndex < this.filters.length - 1) {
                return this.applyFilter(dataSource, currentFilterIndex + 1);
            }

            return dataSource;
        },
        getTable() {
            return this.$refs[`ns-table-${this.refSuffix}`];
        },
        getDataSource({ filtered = true } = {}) {
            return filtered ? this.filteredDataSource : this.sortedDataSource;
        },
        onRowSelected({ event, data }) {
            if (this.clickable) {
                this.$emit("row-selected", { event, data });
            }
        },
    },
    components: {
        Pagination,
        Toolbar,
    },
    created() {
        this.refSuffix = (Math.random() % 10000).toString();

        if (this.isServerSide) {
            if (this.currentSort === undefined) {
                throw new Error("Missing props currentSort");
            }
            if (this.allowPaging && this.pagination === undefined) {
                throw new Error("Missing props pagination");
            }
            this.sort.field = this.currentSort.field;
            this.sort.order = this.currentSort.order;
            this.paging.nbResultsPerPage_ = this.pagination.per_page;
        } else {
            this.applySort();
        }
    },
    watch: {
        currentDataSource: {
            handler() {
                if (!this.isServerSide) {
                    this.applySort();
                }
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
table thead th {
    border-left: 1px solid #dee2e6;
}
</style>
