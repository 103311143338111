<template>
    <div>
        {{ capitalize(cleanDays.join(", ")) }}
    </div>
</template>

<script>
export default {
    props: ["days"],
    data() {
        return {
            cleanDays: [],
        };
    },
    created() {
        let tempDays = JSON.parse(this.days);
        this.cleanDays = [];
        Object.keys(tempDays).forEach((key) => {
            if (tempDays[key]) {
                this.cleanDays.push(this.$t(`labels.days.${key}`));
            }
        });
    },
};
</script>
