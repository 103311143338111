var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.restaurant_id && _vm.user && _vm.user.role !== "kitchen"
    ? _c(
        "div",
        { staticClass: "nav-btn-2 mx-md-5" },
        [
          _c(
            "div",
            { staticClass: "btn-group" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success btn-nav-mob",
                  attrs: {
                    title: _vm.$t("labels.booking.reservations.add.title"),
                    disabled: !_vm.has_right_to_create_reservation,
                  },
                  on: {
                    click: function ($event) {
                      _vm.showAddReservationModal = true
                    },
                  },
                },
                [
                  _c("feather", {
                    class: { "mr-0": _vm.isSidebarCollapsed },
                    attrs: { type: "plus" },
                  }),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$tl("labels.reservation")) +
                      "\n        "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass:
                    "btn btn-sm btn-warning btn-bot-resp btn-nav-mob",
                  class: { disabled: !_vm.has_right_to_read_reservation },
                  attrs: {
                    to: {
                      name: "booking.restaurants.reservationsCreated",
                      params: {
                        restaurant_id: _vm.$route.params.restaurant_id,
                      },
                    },
                    title: _vm.$t("labels.booking.reservations.created.show"),
                  },
                },
                [
                  _c("feather", {
                    class: { "mr-0": _vm.isSidebarCollapsed },
                    attrs: { type: "clock" },
                  }),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$tl("labels.pending")) +
                      "\n        "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-danger btn-nav-mob",
                  attrs: {
                    title: _vm.$t("labels.booking.services.setFull"),
                    id: "show-modal",
                    disabled: !_vm.has_right_to_update_service,
                  },
                  on: {
                    click: function ($event) {
                      _vm.showSetServiceFullModal = true
                    },
                  },
                },
                [
                  _c("feather", {
                    class: { "mr-0": _vm.isSidebarCollapsed },
                    attrs: { type: "pause" },
                  }),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$tl("labels.booking.services.full")) +
                      "\n        "
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showAddReservationModal
            ? _c("add-reservation-modal-new", {
                attrs: { restaurant_id: _vm.restaurant_id },
                on: {
                  redirected: function ($event) {
                    _vm.showAddReservationModal = false
                  },
                  close: function ($event) {
                    _vm.showAddReservationModal = false
                  },
                  "reservation-saved": _vm.reservationSaved,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showSetServiceFullModal
            ? _c("set-service-full-modal", {
                attrs: {
                  restaurant_id: _vm.restaurant_id,
                  prefill: false,
                  service_id: _vm.selected_service_id,
                },
                on: {
                  close: function ($event) {
                    _vm.showSetServiceFullModal = false
                  },
                  "closure-saved": _vm.closureSaved,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }