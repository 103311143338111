<template>
    <div>
        <LoaderComponent v-if="loading > 0" />
        <div v-else>
            <div>
                <gift-vouchers-category-component
                    :categories="categories"
                    :current-category="currentCategory"
                    v-model="customCategoryName"
                    @category-changed="currentCategory = $event" />

                <div class="row mb-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-md-5">
                                    <label>Type de produit</label>
                                </div>
                                <div class="col-md-7">
                                    <div class="d-flex justify-content-around align-items-center lead-switch switch-choice">
                                        <input type="checkbox" class="switch align-self-center is-rounded" v-model="product_type" />
                                        {{ isYservices ? "Prestation" : "Offre" }} <label @click="product_type = !product_type"></label>Chèque cadeau
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!product_type" class="row">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Nom de {{ isYservices ? "la prestation" : "l'offre" }} <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <input maxlength="70" type="text" class="form-control" v-model="product.name" />
                                    <small class="text-muted">Max : 70 caractères</small>
                                    <ShowErrors :errors="errors.form" errorKey="name" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label for="txt-description">Description <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <TextAreaComponentOld
                                        :maxNbChar="150"
                                        txtAreaId="txt-description"
                                        :txtAreaObj="product"
                                        txtAreaObjKey="description"
                                        txtAreaRows="3" />
                                    <ShowErrors :errors="errors.form" errorKey="description" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Image <small>(recommandé)</small></label>
                                </div>
                                <div class="col-md-7">
                                    <image-uploader
                                        :public-image-uri="product.img !== null ? product.img.public_uri : null"
                                        :image-path="product.img !== null ? product.img.path : null"
                                        :upload-uri="imgPostUri"
                                        :delete-uri="imgPostUri"
                                        @image-uploaded="product.img = $event"
                                        @image-deleted="product.img = null"
                                        :maxSize="10" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Prix TTC<small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="number" class="form-control" v-model="product.price" />
                                        </div>
                                        <div class="col-md-8 mt-2">euros</div>
                                        <ShowErrors class="col-12" :errors="errors.form" errorKey="price" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Nombre de personne(s) <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="number" class="form-control" v-model="product.nb_pers" />
                                        </div>
                                        <div class="col-md-8 mt-2">personne(s)</div>
                                        <ShowErrors class="col-12" :errors="errors.form" errorKey="nb_pers" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2" v-if="!isTvaDisabled">
                                <div class="col-md-5 pt-1">
                                    <label>TVA <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <select v-model="product.tva" class="custom-select">
                                                <option :value="null">--</option>
                                                <option :value="5.5">5.5</option>
                                                <option :value="10">10</option>
                                                <option :value="20">20</option>
                                            </select>
                                        </div>
                                        <div class="col-md-8 mt-2">%</div>
                                        <ShowErrors class="col-12" :errors="errors.form" errorKey="tva" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Validité <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="number" class="form-control" v-model="product.validity" />
                                        </div>
                                        <div class="col-md-8 mt-2">jours</div>
                                        <ShowErrors class="col-12" :errors="errors.form" errorKey="validity" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2" v-if="hasBooking">
                                <div class="col-md-5 pt-1">
                                    <label>{{ $tl("labels.giftVouchers.products.service", restaurantId) }}<small> *</small></label>
                                </div>
                                <div class="col-md-7 pt-2">
                                    <ShowErrors class="mb-2" :errors="errors.form" errorKey="services" />
                                    <div class="mb-2">
                                        <label class="container-box" style="margin-bottom: 0; width: 100%">
                                            <input type="checkbox" v-model="all_services" />
                                            <span class="checkmark"></span> Tous
                                        </label>
                                    </div>
                                    <div class="mb-2" v-for="service in services" :key="service.id">
                                        <label class="container-box" style="margin-bottom: 0; width: 100%">
                                            <input type="checkbox" v-model="service.checked" @change="serviceChecked(service)" />
                                            <span class="checkmark"></span> {{ service.name }} ({{ service.category }})
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5 pt-1">
                                    <label>Conditions d'utilisation</label>
                                </div>
                                <div class="col-md-7">
                                    <textarea class="form-control" rows="3" v-model="product.terms_of_use"></textarea>
                                    <ShowErrors :errors="errors.form" errorKey="terms_of_use" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="border-light b-radius-20 mt-3 p-4">
                            <div class="row">
                                <div class="col-md-5 pt-1">
                                    <label>Options</label>
                                </div>
                                <div class="col-md-7">
                                    <div v-for="gv_products_option in gv_products_options" :key="gv_products_option.id" class="row mb-1">
                                        <div class="col-6">
                                            <div class="mb-2 pt-1">
                                                <label class="container-box" style="margin-bottom: 0; width: 100%"
                                                    ><input type="checkbox" v-model="gv_products_option.active" /> <span class="checkmark"></span>
                                                    {{ gv_products_option.name }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-6 pt-1 text-right">
                                                    <span>{{
                                                        gv_products_option.include ? "Inclus" : "+ " + formatCurrency(gv_products_option.price)
                                                    }}</span>
                                                </div>
                                                <div class="col-6">
                                                    <button
                                                        @click="editProductOption(gv_products_option)"
                                                        class="btn btn-sm btn-success btn-square"
                                                        title="Modifier la réservation"
                                                        v-tooltip="{
                                                            delay: { show: 400, hide: 0 },
                                                            content: 'Éditer',
                                                        }">
                                                        <svg
                                                            class="feather feather-edit sc-dnqmqq jxshSx"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            aria-hidden="true">
                                                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                                        </svg>
                                                    </button>
                                                    <button
                                                        @click="deleteProductOption(gv_products_option)"
                                                        class="btn btn-sm btn-danger btn-square"
                                                        v-tooltip="{
                                                            delay: { show: 400, hide: 0 },
                                                            content: 'Supprimer',
                                                        }">
                                                        <svg
                                                            class="feather feather-trash-2 sc-dnqmqq jxshSx"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            aria-hidden="true">
                                                            <polyline points="3 6 5 6 21 6"></polyline>
                                                            <path
                                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                            <line x1="10" y1="11" x2="10" y2="17"></line>
                                                            <line x1="14" y1="11" x2="14" y2="17"></line>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ShowErrors class="d-block" :errors="errors.form" errorKey="product_options" />
                                    <button @click="addProductOption()" class="btn btn-sm btn-success btn-circle mt-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            aria-hidden="true"
                                            class="feather feather-plus sc-dnqmqq jxshSx">
                                            <line x1="12" y1="5" x2="12" y2="19"></line>
                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                        </svg>
                                        <span>Option</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else class="row">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Nom du chèque cadeau <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <input maxlength="70" type="text" class="form-control" v-model="product.name" />
                                    <small class="text-muted">Max : 70 caractères</small>
                                    <ShowErrors :errors="errors.form" errorKey="name" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label for="txt-description-amount">Description <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <TextAreaComponentOld
                                        :maxNbChar="150"
                                        txtAreaId="txt-description-amount"
                                        :txtAreaObj="product"
                                        txtAreaObjKey="description"
                                        txtAreaRows="3" />
                                    <ShowErrors :errors="errors.form" errorKey="description" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Image <small>(recommandé)</small></label>
                                </div>
                                <div class="col-md-7">
                                    <image-uploader
                                        :public-image-uri="product.img !== null ? product.img.public_uri : null"
                                        :image-path="product.img !== null ? product.img.path : null"
                                        :upload-uri="imgPostUri"
                                        :delete-uri="imgPostUri"
                                        @image-uploaded="product.img = $event"
                                        @image-deleted="product.img = null"
                                        :maxSize="10" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Montants prédéfinis</label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-6">
                                            <input type="number" class="form-control" v-model="amount_to_add" />
                                            <span class="text-danger" v-if="errors.amountToAdd">{{ errors.amountToAdd }}</span>
                                        </div>
                                        <div class="col-6 pt-1">
                                            <button type="button" class="btn btn-sm btn-success btn-circle ml-2" @click="addAmount()">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    aria-hidden="true"
                                                    class="feather feather-plus sc-dnqmqq jxshSx">
                                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                                </svg>
                                                <span>Ajouter</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="mt-2 tags">
                                                <span class="tag is-rounded" v-for="amount in product.predefined_amounts" :key="amount">
                                                    {{ amount }} €
                                                    <button class="delete" @click="delAmount(amount)"></button>
                                                </span>
                                            </div>
                                        </div>
                                        <ShowErrors class="col-12" :errors="errors.form" errorKey="predefined_amounts" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Autoriser les montants personnalisés</label>
                                </div>
                                <div class="col-md-7 pt-1">
                                    <div class="d-inline-block align-middle lead-switch">
                                        <input type="checkbox" class="switch align-self-center is-rounded" v-model="product.allow_custom_amounts" />
                                        <label @click="product.allow_custom_amounts = !product.allow_custom_amounts"></label>
                                    </div>
                                    <ShowErrors :errors="errors.form" errorKey="allow_custom_amounts" />
                                </div>
                            </div>
                            <template v-if="product.allow_custom_amounts">
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Montant minimum <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <input type="number" class="form-control" v-model="product.min_amount" />
                                            </div>
                                            <div class="col-md-8 mt-2">euros</div>
                                            <ShowErrors class="col-12" :errors="errors.form" errorKey="min_amount" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Montant maximum <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <input type="number" class="form-control" v-model="product.max_amount" />
                                            </div>
                                            <div class="col-md-8 mt-2">euros</div>
                                            <ShowErrors class="col-12" :errors="errors.form" errorKey="max_amount" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Validité <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="number" class="form-control" v-model="product.validity" />
                                        </div>
                                        <div class="col-md-8 mt-2">jours</div>
                                        <ShowErrors class="col-12" :errors="errors.form" errorKey="validity" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2" v-if="hasBooking">
                                <div class="col-md-5 pt-1">
                                    <label>{{ $tl("labels.giftVouchers.products.service", restaurantId) }} <small> *</small></label>
                                </div>
                                <div class="col-md-7 pt-2">
                                    <ShowErrors class="mb-2" :errors="errors.form" errorKey="services" />
                                    <div class="mb-2">
                                        <label class="container-box" style="margin-bottom: 0; width: 100%">
                                            <input type="checkbox" v-model="all_services" />
                                            <span class="checkmark"></span> Tous
                                        </label>
                                    </div>
                                    <div class="mb-2" v-for="service in services" :key="service.id">
                                        <label class="container-box" style="margin-bottom: 0; width: 100%">
                                            <input type="checkbox" v-model="service.checked" @change="serviceChecked(service)" />
                                            <span class="checkmark"></span> {{ service.name }} ({{ service.category }})
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2" v-if="hasClickAndCollect && (collect_enabled || delivery_enabled || dispatch_enabled)">
                                <div class="col-md-5 pt-1">
                                    <label>Module Vente en ligne</label>
                                </div>
                                <div class="col-md-7 pt-2">
                                    <div class="mb-2" v-if="collect_enabled">
                                        <label class="container-box" style="margin-bottom: 0; width: 100%">
                                            <input type="checkbox" v-model="product.collect_enabled" />
                                            <span class="checkmark"></span> {{ COLLECT_TYPE_COLLECT.label }}
                                        </label>
                                    </div>
                                    <ShowErrors class="mb-2" :errors="errors.form" errorKey="collect_enabled" />
                                    <div class="mb-2" v-if="delivery_enabled">
                                        <label class="container-box" style="margin-bottom: 0; width: 100%">
                                            <input type="checkbox" v-model="product.delivery_enabled" />
                                            <span class="checkmark"></span> {{ COLLECT_TYPE_DELIVERY.label }}
                                        </label>
                                    </div>
                                    <ShowErrors class="mb-2" :errors="errors.form" errorKey="delivery_enabled" />
                                    <div class="mb-2" v-if="dispatch_enabled">
                                        <label class="container-box" style="margin-bottom: 0; width: 100%">
                                            <input type="checkbox" v-model="product.dispatch_enabled" />
                                            <span class="checkmark"></span> {{ COLLECT_TYPE_DISPATCH.label }}
                                        </label>
                                    </div>
                                    <ShowErrors class="mb-2" :errors="errors.form" errorKey="dispatch_enabled" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5 pt-1">
                                    <label>Conditions d'utilisation</label>
                                </div>
                                <div class="col-md-7">
                                    <textarea class="form-control" rows="3" v-model="product.terms_of_use"></textarea>
                                    <ShowErrors :errors="errors.form" errorKey="terms_of_use" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <add-product-option-modal
                v-if="showAddProductOptionModal"
                :product_option="product_option"
                @saved="productOptionSaved"
                @close="showAddProductOptionModal = false" />
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import ImageUploader from "../forms/ImageUploaderComponent.vue";
import TextAreaComponentOld from "../forms/TextAreaComponentOld";
import ShowErrors from "../errors/ShowErrors";
import addProductOptionModal from "../../components/Modals/giftVouchers/addProductOptionModal";
import CollectTypesEnum from "../../mixins/enums/click_and_collect/CollectTypesEnum";
import GiftVouchersCategoryComponent from "./addGiftVouchersProduct/GiftVouchersCategoryComponent.vue";

function defaultProductOption() {
    return {
        id: null,
        include: 1,
        name: "",
        price: null,
        tva: null,
    };
}

export default {
    data() {
        return {
            loading: 0,
            errors: {
                form: null,
                amountToAdd: null,
            },
            product_type: this.product.type === "amount",
            amount_to_add: null,
            services: [],
            all_services: false,
            showAddProductOptionModal: false,
            product_option: null,
            gv_products_options: [],
            collect_enabled_: false,
            delivery_enabled_: false,
            dispatch_enabled_: false,
            categories: [],
            currentCategory: null,
            customCategoryName: null,
        };
    },
    mixins: [CollectTypesEnum],
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isTvaDisabled() {
            return this.$store.getters["restaurants/isTvaDisabled"](this.restaurantId);
        },
        hasBooking() {
            return this.$store.getters["restaurants/hasModule"]("booking", this.restaurantId);
        },
        hasClickAndCollect() {
            return this.$store.getters["restaurants/hasModule"]("click-and-collect", this.restaurantId);
        },
        collect_enabled() {
            return this.hasClickAndCollect && this.collect_enabled_;
        },
        delivery_enabled() {
            return this.hasClickAndCollect && this.delivery_enabled_;
        },
        dispatch_enabled() {
            return this.hasClickAndCollect && this.dispatch_enabled_;
        },
        imgPostUri() {
            let uri = `/api/gift_vouchers/${this.restaurantId}/products/img`;

            if (this.product.id !== null) {
                uri += `/${this.product.id}`;
            }

            return uri;
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        nbServicesChecked() {
            return this.services.reduce((count, service) => (service.checked ? count + 1 : count), 0);
        },
    },
    methods: {
        getFormattedData() {
            const data = {};

            Object.assign(data, this.product);

            (data.img = data.img !== null ? data.img.path : null), (data.services = this.product.services.join("-"));
            data.product_options = [];
            this.gv_products_options.forEach((gv_products_option) => {
                if (gv_products_option.active) {
                    data.product_options.push(gv_products_option.id);
                }
            });
            data.product_options = data.product_options.join("-");
            data.price = Number.parseInt(Number.parseFloat(data.price) * 100); // Price in cent
            data.min_amount = Number.parseInt(Number.parseFloat(data.min_amount) * 100); // Min amount in cent
            data.max_amount = Number.parseInt(Number.parseFloat(data.max_amount) * 100); // Max amount in cent
            data.type = this.product_type ? "amount" : "menu";
            data.predefined_amounts = data.predefined_amounts.join("-");
            data.category = this.currentCategory;
            data.custom_category_name = this.customCategoryName;

            return data;
        },
        getCategories() {
            this.loading++;

            this.httpGet(`/api/gift_vouchers/${this.restaurantId}/gvCategories`)
                .then((response) => {
                    if (response !== false) {
                        this.categories = response.data.data;
                        if (this.currentCategory === null) {
                            this.currentCategory = this.categories[0].id;
                        }
                    }
                })
                .finally(() => this.loading--);
        },
        addProductOption() {
            this.product_option = defaultProductOption();
            this.showAddProductOptionModal = true;
        },
        editProductOption(product_option) {
            this.product_option = _.cloneDeep(product_option);
            this.showAddProductOptionModal = true;
        },
        productOptionSaved(id) {
            this.showAddProductOptionModal = false;
            this.product.product_options.push(id);
            this.fetchProductOptions();
        },
        deleteProductOption(product_option) {
            if (!confirm("Êtes vous sûrs de vouloir supprimer cette option ?")) {
                return;
            }

            this.loading++;

            this.httpDelete(`/api/gift_vouchers/${this.restaurantId}/productsOptions/${product_option.id}`)
                .then((response) => {
                    if (response !== false) {
                        const index = this.gv_products_options.findIndex((po) => po.id === product_option.id);

                        if (index !== -1) {
                            this.gv_products_options.splice(index, 1);
                        }
                    }
                })
                .finally(() => this.loading--);
        },
        fetchServices() {
            this.loading++;

            this.services = [];

            this.httpGet(`/api/restaurants/${this.restaurantId}/services?without_slots=1&all=1`)
                .then((response) => {
                    if (response !== false) {
                        this.services = response.data.map((service) => ({
                            id: service.id,
                            name: service.name,
                            category: service.category,
                            checked: this.product.services.indexOf(service.id) !== -1,
                        }));

                        this.$nextTick(() => (this.all_services = this.nbServicesChecked === this.services.length));
                    }
                })
                .finally(() => this.loading--);
        },
        fetchProductOptions() {
            this.loading++;

            this.gv_products_options = [];

            this.httpGet(`/api/gift_vouchers/${this.restaurantId}/productsOptions`)
                .then((response) => {
                    if (response !== false) {
                        this.gv_products_options = response.data.gv_products_options.map((gv_products_option) => ({
                            ..._.cloneDeep(gv_products_option),
                            active: this.product.product_options.indexOf(gv_products_option.id) !== -1,
                        }));
                    }
                })
                .finally(() => this.loading--);
        },
        save() {
            this.loading++;

            this.errors.form = null;
            this.errors.amountToAdd = null;

            let promise = null;

            if (this.product.id === null) {
                promise = this.httpPost(`/api/gift_vouchers/${this.restaurantId}/products`, this.getFormattedData());
            } else {
                promise = this.httpPut(`/api/gift_vouchers/${this.restaurantId}/products/${this.product.id}`, this.getFormattedData());
            }

            promise
                .then((response) => {
                    if (response !== false) {
                        this.$emit("saved");
                    }
                })
                .finally(() => this.loading--);
        },
        addAmount() {
            const amount = Number.parseInt(this.amount_to_add);

            this.errors.amountToAdd = null;

            if (isNaN(amount) || amount < 1) {
                this.errors.amountToAdd = "Merci de saisir un prix valide";
                return;
            }

            if (this.product.predefined_amounts.indexOf(amount) === -1) {
                this.product.predefined_amounts.push(amount);
            } else {
                this.errors.amountToAdd = "Ce prix est déjà défini";
            }

            this.product.predefined_amounts.sort((a, b) => a - b);
            this.amount_to_add = null;
        },
        delAmount(amount) {
            this.errors.amountToAdd = null;

            const i = this.product.predefined_amounts.indexOf(amount);

            if (i !== -1) {
                this.product.predefined_amounts.splice(i, 1);
            }
        },
        serviceChecked(service) {
            if (service.checked && this.product.services.indexOf(service.id) === -1) {
                this.product.services.push(service.id);
            } else if (!service.checked) {
                this.all_services = false;

                const i = this.product.services.indexOf(service.id);

                if (i !== -1) {
                    this.product.services.splice(i, 1);
                }
            }
        },
        fetchClickAndCollectConfig() {
            this.loading++;

            this.httpGet(`/api/click_and_collect/${this.restaurantId}/settings`, { handleReject: false })
                .then((response) => {
                    if (response !== false) {
                        this.collect_enabled_ = response.data.is_click_and_collect_enabled;
                        this.delivery_enabled_ = response.data.is_delivery_enabled;
                        this.dispatch_enabled_ = response.data.is_dispatch_enabled;
                    }
                })
                .finally(() => this.loading--);
        },
    },
    watch: {
        "product.allow_custom_amounts": function (newVal) {
            if (!newVal) {
                this.product.min_amount = null;
                this.product.max_amount = null;
            }
        },
        all_services(newVal) {
            if (newVal) {
                this.services.forEach((service) => {
                    service.checked = true;
                    this.serviceChecked(service);
                });
            }
        },
    },
    components: {
        LoaderComponent,
        ImageUploader,
        TextAreaComponentOld,
        ShowErrors,
        addProductOptionModal,
        GiftVouchersCategoryComponent,
    },
    created() {
        if (this.hasBooking) {
            this.fetchServices();
        }

        if (this.hasClickAndCollect) {
            this.fetchClickAndCollectConfig();
        }

        this.fetchProductOptions();
        this.getCategories();

        this.currentCategory = !this.$_.isEmpty(this.product.gvCategory) ? this.product.gvCategory.id : null;
    },
};
</script>
