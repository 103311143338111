<template>
    <div class="col-12 mt-2">
        <div class="row">
            <div class="col-12">
                <p>{{ $tl("labels.booking.reservations.remainingPaxes", restaurantId, { paxes: currentRemainingPaxes }) }}</p>
            </div>
            <div class="col-md-6">
                {{ $tl("labels.booking.reservations.edit.table") }}
            </div>
            <div class="col-md-6">
                <div>
                    <multi-select-tables
                        :reservation-date="reservationDate"
                        :service-id="reservation.slot.service_id"
                        :restaurant-id="restaurantId"
                        :reservation="reservation"
                        :selected-table="selectedTable"
                        @pax-used="paxUsed = $event"
                        @updated-tables="$emit('updated-tables', $event)"
                        :current-room-index="currentRoomIndex"
                        :current-remaining-paxes="currentRemainingPaxes" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MultiSelectTables from "./MultiSelectTables.vue";

export default {
    props: {
        remainingPaxes: {
            required: true,
            type: Number,
        },
        reservation: {
            required: true,
            type: Object,
        },
        selectedTable: {
            required: true,
            type: Object,
        },
        currentRoomIndex: {
            required: true,
            type: Number,
        },
    },
    components: {
        MultiSelectTables,
    },
    data() {
        return {
            paxUsed: 0,
        };
    },
    computed: {
        currentRemainingPaxes() {
            let result = 0;

            if (this.remainingPaxes > this.paxUsed) {
                result = this.remainingPaxes - this.paxUsed;
            }

            return result;
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        reservationDate() {
            return this.getDateTime(this.reservation.reservation_datetime);
        },
    },
};
</script>
