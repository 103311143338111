<template>
    <th :class="{ pointer: allowSorting }" v-bind="{ scope, style }">
        <div @click.prevent.stop="sortDataSource">
            <slot></slot>
            <feather v-if="allowSorting && isAscSorted" type="corner-right-up" />
            <feather v-if="allowSorting && isDescSorted" type="corner-right-down" />
            <feather v-if="allowFiltering" type="filter" @click.prevent.stop="filter.show = !filter.show" :class="{ 'text-success': filter.has }" />
        </div>
        <div v-if="filter.show" class="pt-0 px-2 pb-2 border" style="position: absolute; background-color: white; width: 17em">
            <button type="button" class="btn btn-sm" @click.stop="close" style="margin-left: 88%">
                <feather type="x" />
            </button>

            <ul v-if="filterOptions && filterOptions.type === 'choices'" class="m-0 p-0 ml-2">
                <li v-for="choice in filterOptions.choices" :key="choice.value" @click.stop>
                    <label class="container-box w-auto">
                        <input type="checkbox" v-model="filter.selectedChoices" :value="choice.value" />
                        <span class="checkmark"></span> {{ choice.label }}
                    </label>
                </li>
            </ul>
            <div class="w-100 text-center">
                <button type="button" class="btn btn-sm btn-success mr-1" @click.prevent.stop="filterDataSource">
                    {{ $tl("labels.form.actions.filter") }}
                </button>
                <button type="button" class="btn btn-sm btn-outline-secondary" @click.prevent.stop="resetFilter">
                    {{ $tl("labels.form.actions.cancel") }}
                </button>
            </div>
        </div>
    </th>
</template>

<script>
export default {
    data() {
        return {
            filter: {
                show: false,
                has: false,
                selectedChoices: [],
                oldSelectedChoices: [],
            },
        };
    },
    props: {
        scope: {
            type: String,
            default: "col",
        },
        field: {
            type: String,
            required: false,
        },
        allowSorting: {
            type: Boolean,
            default: false,
        },
        sortComparer: {
            type: Function,
            required: false,
        },
        allowFiltering: {
            type: Boolean,
            default: false,
        },
        filterOptions: {
            type: Object,
            required: false,
        },
        width: {
            type: [Number, String],
            required: false,
        },
        backendSelectedChoices: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        activeParent() {
            return this.$parent.$options._componentTag.endsWith("-headings") ? this.$parent.$parent : this.$parent;
        },
        currentSelectedChoices() {
            return this.activeParent.isServerSide ? this.backendSelectedChoices : this.filter.selectedChoices;
        },
        isSorted() {
            return this.activeParent.sort.field === this.field;
        },
        isAscSorted() {
            return this.isSorted && this.activeParent.sort.order === "asc";
        },
        isDescSorted() {
            return this.isSorted && this.activeParent.sort.order === "desc";
        },
        style() {
            let style = "";

            if (typeof this.width !== "undefined") {
                const widthString = this.width.toString();

                if (widthString.match(/[0-9]+/)) {
                    style += `min-width: ${widthString}em;`;
                } else {
                    style += `min-width: ${widthString};`;
                }
            }

            return style;
        },
    },
    methods: {
        sortDataSource() {
            if (this.allowSorting) {
                this.activeParent.sortDataSource({
                    field: this.field,
                    comparer: this.sortComparer,
                });
            }
        },
        filterDataSource() {
            if (this.allowFiltering && this.filterOptions) {
                this.activeParent.filterDataSource({
                    field: this.field,
                    filterOptions: {
                        type: this.filterOptions.type,
                        choices: this.filter.selectedChoices,
                        filterFunction: this.filterOptions.filterFunction ?? null,
                    },
                });
            }
            this.filter.show = false;
            this.filter.has = this.filter.selectedChoices.length !== this.filterOptions.choices.length;
            this.filter.oldSelectedChoices = this.filter.selectedChoices.slice();
            this.activeParent.emitUpdateData();
        },
        initFilter() {
            if (this.filterOptions && this.filterOptions.type === "choices") {
                this.filter.selectedChoices = this.activeParent.isServerSide
                    ? this.backendSelectedChoices.map((c) => c.value)
                    : this.filterOptions.choices.map((c) => c.value);
                this.filter.oldSelectedChoices = this.filter.selectedChoices.slice();
            }
        },
        close() {
            this.filter.show = false;
            this.filter.selectedChoices = this.filter.oldSelectedChoices.slice();
        },
        resetFilter() {
            this.initFilter();
            this.filterDataSource();
        },
    },
    created() {
        if (typeof this.filterOptions === "object") {
            this.initFilter();
        }
    },
    watch: {
        "filterOptions.choices": {
            handler(newValue, oldValue) {
                if (Array.isArray(newValue) && Array.isArray(oldValue)) {
                    const newValueArr = newValue.map((choice) => choice.value);
                    const oldValueArr = oldValue.map((choice) => choice.value);
                    const diff = newValueArr.filter((choice) => !oldValueArr.includes(choice));

                    this.filter.selectedChoices.push(...diff);
                }
            },
            deep: true,
        },
    },
};
</script>
