var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "border-light b-radius-20 p-4" },
          [
            _vm.loading
              ? _c("LoaderComponent")
              : _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v(_vm._s(_vm.error)),
                ])
              : _vm.command && _vm.to_refund
              ? [
                  _c("ShowErrors", {
                    staticClass: "d-block",
                    attrs: { errors: _vm.errors.form, errorKey: `to_refund` },
                  }),
                  _vm._v(" "),
                  _c("strong", { staticStyle: { color: "#414141" } }, [
                    _vm._v("Choix des produits à rembourser"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.command.command_products.data, function (product) {
                    return _c(
                      "div",
                      { key: product.id, staticClass: "mt-4" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "container-box",
                            staticStyle: { width: "initial" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.to_refund[product.id].refund,
                                  expression: "to_refund[product.id].refund",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.to_refund[product.id].refund
                                )
                                  ? _vm._i(
                                      _vm.to_refund[product.id].refund,
                                      null
                                    ) > -1
                                  : _vm.to_refund[product.id].refund,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.to_refund[product.id].refund,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.to_refund[product.id],
                                          "refund",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.to_refund[product.id],
                                          "refund",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.to_refund[product.id],
                                      "refund",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" }),
                            _vm._v(" "),
                            product.quantity > 1
                              ? _c("span", [
                                  _vm._v(_vm._s(product.quantity) + " x "),
                                ])
                              : _vm._e(),
                            _vm._v(
                              _vm._s(product.product.name) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.to_refund[product.id].refund && product.quantity > 1
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.to_refund[product.id].quantity,
                                  expression: "to_refund[product.id].quantity",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control d-inline-block",
                              staticStyle: { "max-width": "100px" },
                              attrs: {
                                type: "number",
                                step: "1",
                                min: 1,
                                max: product.quantity,
                              },
                              domProps: {
                                value: _vm.to_refund[product.id].quantity,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.to_refund[product.id],
                                    "quantity",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.to_refund[product.id].refund
                          ? _c("span", [
                              _vm._v(
                                "\n                            ( " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      product.product.price *
                                        _vm.to_refund[product.id].quantity
                                    )
                                  ) +
                                  " )\n                        "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors.form,
                            errorKey: `to_refund.${product.id}.cc_command_product_id`,
                          },
                        }),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors.form,
                            errorKey: `to_refund.${product.id}.quantity`,
                          },
                        }),
                        _vm._v(" "),
                        product.command_product_option.data.length > 0
                          ? _c(
                              "div",
                              { staticClass: "ml-4" },
                              _vm._l(
                                product.command_product_option.data,
                                function (option) {
                                  return _c(
                                    "div",
                                    { key: option.id },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "container-box",
                                          staticStyle: {
                                            width: "initial",
                                            "margin-top": "7px",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "checkbox",
                                              disabled: "",
                                            },
                                            domProps: {
                                              checked:
                                                _vm.to_refund[product.id]
                                                  .refund,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark disabled",
                                          }),
                                          _vm._v(" "),
                                          option.quantity > 1
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(option.quantity) +
                                                    " x "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(
                                            _vm._s(
                                              option.product_option.cc_product
                                                .name
                                            ) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.to_refund[product.id].refund &&
                                      option.quantity * product.quantity > 1
                                        ? _c("input", {
                                            staticClass:
                                              "form-control d-inline-block",
                                            staticStyle: {
                                              "max-width": "100px",
                                            },
                                            attrs: {
                                              type: "number",
                                              disabled: "",
                                            },
                                            domProps: {
                                              value:
                                                _vm.to_refund[product.id]
                                                  .quantity * option.quantity,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.to_refund[product.id].refund
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                    (\n                                    " +
                                                _vm._s(
                                                  _vm.formatCurrency(
                                                    option.product_option
                                                      .price *
                                                      _vm.to_refund[product.id]
                                                        .quantity *
                                                      option.quantity
                                                  )
                                                ) +
                                                "\n                                    )\n                                "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        staticClass: "d-block",
                                        attrs: {
                                          errors: _vm.errors.form,
                                          errorKey: `to_refund.${product.id}.options.${option.id}.*`,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "sep" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-1" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 text-right pr-4" }, [
                        _c("strong", { staticClass: "value" }, [
                          _vm._v(
                            "Total : " +
                              _vm._s(
                                _vm.formatCurrency(_vm.total_refund_amount)
                              )
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }