<template>
    <div class="row">
        <div class="col-5 mt-2">Image {{ nb }} <small class="text-danger">*</small></div>
        <div class="col-7 mt-1">
            <ImageComponent
                :maxSize="10"
                :disabled="disabled"
                alt="galerie"
                :src="src"
                :suffixId="'gallery' + nb"
                :addStyle="'max-width:200px;'"
                :postURI="basePostURL + 'gallery' + nb"
                :allowedExtensions="allowedExtensions" />
        </div>
    </div>
</template>

<script>
import ImageComponent from "./ImageComponent";

export default {
    name: "GalleryFormComponent",
    props: {
        nb: {
            type: Number,
            required: true,
        },
        src: {
            default: null,
        },
        basePostURL: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        allowedExtensions: {
            type: String,
            default: ".jpg,.jpeg,.JPG",
        },
    },
    components: {
        ImageComponent,
    },
};
</script>
