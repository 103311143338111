import moment from "moment";
import CollectTypesEnum from "./CollectTypesEnum";
import CommandStatusEnum from "./CommandStatusEnum";
import PaymentStatusEnum from "./PaymentStatusEnum";

export default {
    data() {
        return {
            COMMAND_COLUMN_STATUS: {
                label: "le statut",
                value: "status",
                valueLabel: (log) => {
                    switch (log.status) {
                        case this.COMMAND_PENDING.value:
                            return "Pending";
                        case this.COMMAND_TO_ACCEPT.value:
                            return "En attente";
                        case this.COMMAND_TO_PREPARE.value:
                            return "À préparer";
                        case this.COMMAND_READY.value:
                            if (log.collect_type === this.COLLECT_TYPE_COLLECT.value) return "Prête";
                            return "À livrer";
                        case this.COMMAND_DISPATCHED.value:
                            return "Expédiée";
                        case this.COMMAND_COLLECTED.value:
                            if (log.collect_type === this.COLLECT_TYPE_COLLECT.value) return "Retirée";
                            return "Livrée";
                        case this.COMMAND_CANCELED.value:
                            return "Annulée";
                        default:
                            return "";
                    }
                },
                icon: "user",
            },
            COMMAND_COLUMN_PAYMENT_STATUS: {
                label: "le statut du paiement",
                value: "payment_status",
                valueLabel: (log) => this.capitalize(this.getPaymentStatusLabel(log.payment_status)),
                icon: "user",
            },
            COMMAND_COLUMN_RESERVATION_DATE: {
                label: "la date",
                value: "reservation_date",
                valueLabel: (log) => moment(log.reservation_date).format("DD/MM/Y"),
                icon: "calendar",
            },
            COMMAND_COLUMN_SLOT: {
                label: (log) => {
                    if (log.collect_type === this.COLLECT_TYPE_COLLECT.value) return "l'heure";
                    else if (log.collect_type === this.COLLECT_TYPE_DELIVERY.value) return "le créneau de livraison";
                    return "";
                },
                value: "slot",
                valueLabel: (log) => {
                    if (log.collect_type === this.COLLECT_TYPE_COLLECT.value) return this.formatHour(log.slot.hour);
                    else if (log.collect_type === this.COLLECT_TYPE_DELIVERY.value)
                        return this.formatHour(log.slot.hour_start) + "-" + this.formatHour(log.slot.hour_end);
                    return "";
                },
                icon: "clock",
            },
        };
    },
    mixins: [CollectTypesEnum, CommandStatusEnum, PaymentStatusEnum],
    methods: {
        getCommandColumnLabel(value, log) {
            const found = this.ALL_COMMAND_COLUMNS.find((w) => w.value == value);
            if (typeof found != "undefined") return typeof found.label == "function" ? found.label(log) : found.label;
            return value;
        },
        getCommandColumnValueLabel(value, log) {
            const found = this.ALL_COMMAND_COLUMNS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.valueLabel(log);
            return value;
        },
        getCommandColumnIcon(value, log) {
            const found = this.ALL_COMMAND_COLUMNS.find((w) => w.value == value);
            if (typeof found != "undefined") return typeof found.icon == "function" ? found.icon(log) : found.icon;
            return "x";
        },
    },
    computed: {
        ALL_COMMAND_COLUMNS() {
            return [
                this.COMMAND_COLUMN_STATUS,
                this.COMMAND_COLUMN_PAYMENT_STATUS,
                this.COMMAND_COLUMN_RESERVATION_DATE,
                this.COMMAND_COLUMN_SLOT,
            ];
        },
    },
};
