<template>
    <modal @close.stop="closeFromModal">
        <h5 slot="header">{{ customEventId !== null ? $tl("labels.booking.customEvents.edit") : $tl("labels.booking.customEvents.add") }}</h5>
        <div slot="body">
            <custom-event-form
                ref="form"
                @fully-loaded="onFormLoaded"
                @is-editable="isEditable = $event"
                :restaurant-id="restaurantId"
                :custom-event-id="customEventId"
                :date="date" />
        </div>
        <div slot="footer" class="d-flex">
            <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button type="button" :disabled="isLoading || !isEditable" class="btn btn-sm btn-success btn-circle ml-2" @click="save">
                {{ $tl("labels.form.actions.save") }}
            </button>
        </div>
    </modal>
</template>

<script>
import customEventForm from "../../customEvents/customEventForm.vue";

export default {
    name: "customEventFormModal",
    data() {
        return {
            isLoading: true,
            isEditable: false,
        };
    },
    props: {
        restaurantId: {
            type: Number,
            required: true,
        },
        customEventId: {
            type: Number,
            default: null,
        },
        date: {
            type: String,
            default: null,
        },
    },
    methods: {
        onFormLoaded() {
            this.isLoading = false;
        },
        save() {
            this.isLoading = true;

            this.$refs.form.submit().then((success) => {
                if (success !== false) {
                    if (this.customEventId !== null) {
                        this.$emit("custom-event-edited");
                    } else {
                        this.$emit("custom-event-added");
                    }

                    this.close();
                } else {
                    this.isLoading = false;
                }
            });
        },
        close() {
            this.$emit("close");
        },
        closeFromModal(e) {
            if (e && e.target && e.target.classList.contains("modal-wrapper")) {
                this.close();
            }
        },
    },
    components: {
        customEventForm,
    },
};
</script>
