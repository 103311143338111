<template>
    <nav
        class="d-md-block bg-dark sidebar"
        :class="{
            'col-lg-2 col-md-3': !isSidebarCollapsed,
            'col-lg-1 col-md-1 text-center mr-0': isSidebarCollapsed,
        }">
        <div class="sidebar-sticky">
            <div>
                <div class="input-group restaurant-title p-3" v-if="restaurant_id && !isSidebarCollapsed">
                    <select
                        class="custom-select rounded-pill pl-3"
                        id="inputGroupSelect01"
                        v-model="restaurant_id"
                        @change="selectRestaurant($event)">
                        <option v-for="restaurant in restaurants" :value="restaurant.id">{{ restaurant.name }}</option>
                    </select>
                </div>
                <ul class="sid-nav nav flex-column" v-if="shouldShowEntries" @click="$emit('close-menu')">
                    <sidebar-nav-item v-for="(entry, index) in entries" :user="user" :key="index" :isCollapsed="isSidebarCollapsed" :entry="entry" />
                </ul>

                <template>
                    <h6
                        class="sidebar-heading px-3 mt-4 mb-1 text-muted"
                        :class="{
                            'text-center': isSidebarCollapsed,
                        }">
                        {{ $tl("labels.help") }}
                    </h6>
                    <ul class="sid-nav-bot nav flex-column">
                        <li class="nav-item" :class="{ 'text-center': isSidebarCollapsed }">
                            <a class="nav-link" :href="externalHelpRoute" target="_blank">
                                <feather type="book" :class="{ 'size-22': isSidebarCollapsed, 'size-16': !isSidebarCollapsed }" />
                                <span v-show="!isSidebarCollapsed" class="display-sid-min">{{ $tl("labels.routes.documentation") }}</span>
                            </a>
                        </li>
                        <li v-if="isYservices" class="nav-item none-tablet" :class="{ 'text-center': isSidebarCollapsed }">
                            <a class="nav-link" href="#" @click.prevent="openHelpscoutBeacon">
                                <feather type="help-circle" :class="{ 'size-22': isSidebarCollapsed, 'size-16': !isSidebarCollapsed }" />
                                <span v-show="!isSidebarCollapsed" class="display-sid-min">{{ $tl("labels.routes.help") }}</span>
                            </a>
                        </li>
                        <template v-else-if="isNoShow">
                            <li class="nav-item none-tablet" :class="{ 'text-center': isSidebarCollapsed }">
                                <a class="nav-link open-intercom" href="#">
                                    <feather type="help-circle" :class="{ 'size-22': isSidebarCollapsed, 'size-16': !isSidebarCollapsed }" />
                                    <span v-show="!isSidebarCollapsed" class="display-sid-min">{{ $tl("labels.routes.help") }}</span>
                                </a>
                            </li>
                            <li class="nav-item none-tablet" :class="{ 'text-center': isSidebarCollapsed }">
                                <router-link class="nav-link" :to="onlineHelpRoute">
                                    <feather type="film" :class="{ 'size-22': isSidebarCollapsed, 'size-16': !isSidebarCollapsed }" />
                                    <span v-show="!isSidebarCollapsed" class="display-sid-min">{{ $tl("labels.routes.onlineHelp") }}</span>
                                    <newBadge class="ml-2" v-if="checkForExistingNewBadge('online_help')" />
                                </router-link>
                            </li>
                        </template>
                        <li class="nav-item none-tablet" :class="{ 'text-center': isSidebarCollapsed }">
                            <router-link class="nav-link" :to="{ name: 'app_updates.list' }">
                                <svg
                                    :class="{ 'size-22': isSidebarCollapsed, 'size-16': !isSidebarCollapsed }"
                                    version="1.1"
                                    viewBox="0 0 1200 1200"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g fill="#fff">
                                        <path
                                            d="m913.84 399.6 0.61328-319.37-116.74-0.074219-22.5 32.555c-70.32 102.23-150.04 218.09-274.7 218.44l-35.16-0.14453 0.17969-41.027-284.03-1.3203-0.44531 97.969-117.56-0.63672-1.1641 245.1 117.6 0.60156-0.44531 97.535 47.316 0.21484 104.14 390.41 141.09-48.73-57.215-340.84 48.758 0.21484 0.22656-51.277 34.559 0.058594c125.5 0.50391 205.3 117.1 275.72 219.97l21.949 31.945 116.79 0.26562 0.66016-342.47c40.02-12.719 69.121-50.09 69.324-94.273 0.17578-44.422-28.805-82.176-68.957-95.121zm-791.22 171.79 0.60156-125.07 57.574 0.3125-0.57422 125.06zm730.31 299.93-25.297-0.058593-4.043-5.9023c-74.855-109.34-168.01-245.44-325.05-246.07l-34.465-0.058593 1.0195-228.21 35.387 0.14453c156.3-0.43359 249.38-135.72 324.17-244.43l4.5234-6.5391 25.176 0.023438z" />
                                        <path d="m1035.7 464.26h101.91v60h-101.91z" />
                                        <path d="m992.95 255.69 103.12-61.875 30.871 51.449-103.12 61.875z" />
                                        <path d="m992.95 732.8 30.871-51.449 103.12 61.875-30.871 51.449z" />
                                    </g>
                                </svg>
                                <span v-show="!isSidebarCollapsed" class="display-sid-min">
                                    {{ $tl("labels.routes.appUpdates") }}
                                    <div v-if="hasUnreadAppUpdates" class="red-bubble ml-2"></div>
                                    <new-badge class="ml-2" v-if="checkForExistingNewBadge('app_updates')" />
                                </span>
                            </router-link>
                        </li>
                    </ul>
                </template>
                <div class="btn-sid-min" v-show="restaurant_id">
                    <button class="btn text-white" @click="$emit('sidebar-collapse-changed', !isSidebarCollapsed)">
                        <feather v-show="!isSidebarCollapsed" class="feather-24" type="chevrons-left" />
                        <feather v-show="isSidebarCollapsed" class="feather-24" type="chevrons-right" />
                    </button>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import newBadge from "../components/NewBadges/newBadge.vue";
import SidebarNavItem from "./SidebarNavItem.vue";

export default {
    props: {
        user: {
            required: true,
        },
        restaurants: {
            required: true,
        },
        isSidebarCollapsed: {
            required: true,
            type: Boolean,
        },
        entries: {
            default: () => [],
        },
        restaurantRouteName: {
            required: true,
        },
        forceShow: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            restaurant_id: this.$route.params.restaurant_id,
            reservationsMenuActive: false,
            roomsMenuActive: false,
            clientsMenuActive: false,
            statsMenuActive: false,
            siteMenuActive: false,
            settingsMenuActive: false,
            adminMenuActive: false,
            waitinglistMenuActive: false,
            clickAndCollectMenuActive: false,
        };
    },
    created() {
        this.checkRestaurantId();
        this.activeMenu();

        if (this.isClubMedOwnerId(this.$store.getters["users/ownerId"])) {
            this.restaurants.sort((a, b) => a.name.localeCompare(b.name));
        }
    },
    computed: {
        shouldShowEntries() {
            if (this.forceShow) {
                return true;
            }

            return (
                (this.$route.name !== "booking.home" || this.restaurants.length == 1) &&
                this.$route.name !== "click_and_collect.home" &&
                this.$route.name !== "gift_vouchers.home" &&
                this.$route.name !== "website.home" &&
                this.$route.name !== "campaigns.home"
            );
        },
        externalHelpRoute() {
            if (this.isYservices) {
                return "https://aide.y-services.fr/";
            }

            return "http://help.noshow.io/";
        },
        onlineHelpRoute() {
            const routeExploded = this.customTrim(this.$route.path, "/").split("/");
            const module = routeExploded.length > 0 ? routeExploded[0] : "";

            if (module === "") {
                return { name: "online_help" };
            }

            return { name: "online_help.module", params: { module } };
        },
        hasUnreadAppUpdates() {
            return this.$store.getters["users/hasUnreadAppUpdates"];
        },
    },
    methods: {
        openHelpscoutBeacon() {
            if (typeof Beacon !== "undefined") {
                Beacon("open");
            }
        },
        checkRestaurantId() {
            this.restaurant_id = this.$route.params.restaurant_id;
            if (!this.$route.params.restaurant_id && this.restaurants.length == 1) {
                this.restaurant_id = this.restaurants[0].id;
            }
        },
        activeMenu() {
            this.settingsMenuActive = false;
            if (this.$router.currentRoute.name.startsWith("booking.restaurants.settings")) {
                this.settingsMenuActive = true;
            }
            this.clickAndCollectMenuActive = false;
            if (this.$router.currentRoute.name.startsWith("booking.restaurants.click_and_collect")) {
                this.clickAndCollectMenuActive = true;
            }
        },
        selectRestaurant(event) {
            if (this.user != "" && this.user.role === "kitchen") {
                this.$router.push({
                    name: "booking.restaurants.reservationsCuisine",
                    params: { restaurant_id: event.target.value },
                });
                return;
            }
            this.$router.push({ name: this.$route.name, params: { restaurant_id: event.target.value } });
            this.$emit("reload-router-view");
        },
    },
    watch: {
        $route: function (id) {
            this.activeMenu();
        },
        "$route.params.restaurant_id": function (id) {
            this.checkRestaurantId();
        },
        "$route.query.reload": function (id) {
            this.fetchRestaurants();
        },
    },
    components: {
        SidebarNavItem,
        newBadge,
    },
};
</script>
