<template>
    <div>
        <LoaderComponent v-if="loading > 0" />
        <div v-else>
            <div v-if="errorMessages && errorMessages.length > 0" class="alert alert-danger" v-html="errorMessages.join('<br/>')"></div>
            <div v-else class="default-home">
                <div class="row m-0">
                    <div class="col-12">
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                            <h5 class="title mt-2">{{ $tl("labels.routes.subscription") }}</h5>
                            <div>
                                <router-link :to="{ name: 'subscription.invoices' }" class="btn btn-sm btn-success btn-circle">
                                    {{ $tl("labels.routes.invoices") }}
                                </router-link>
                                <router-link
                                    v-if="has_right_to_update_subscription && !hasManualBilling"
                                    :to="{ name: 'subscription.licences.create' }"
                                    class="none-mobile btn btn-sm btn-success btn-circle">
                                    <feather type="plus"></feather> {{ $tl("labels.routes.addSubscription") }}
                                </router-link>
                                <router-link
                                    v-if="has_right_to_update_subscription && !hasManualBilling"
                                    :to="{ name: 'subscription.licences.create' }"
                                    class="none-desk btn btn-sm btn-success btn-circle">
                                    <feather type="plus"></feather> {{ $tl("labels.form.actions.add") }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-sm-12">
                        <div class="border-light b-radius-20 p-4 mb-3">
                            <div class="row mb-2">
                                <div class="col-12">
                                    <strong class="float-left">{{ $tl("labels.subscriptions.actual") }}</strong>
                                </div>
                            </div>
                            <div>
                                <template v-for="(licence, index) in flattenSubscriptions">
                                    <div class="border-light p-3 mb-3 position-relative" :key="licence.id">
                                        <div class="row mb-2">
                                            <div v-if="licence.status === 'past_due' || licence.status === 'unpaid'" class="col-12 text-danger mb-1">
                                                {{ $tl("infos.psp.checkPaymentMethod") }}
                                            </div>
                                            <div class="col-7">
                                                <strong>{{ getNameString(licence) }}</strong>
                                                <span
                                                    v-if="
                                                        licence.stripe_subscription !== undefined && licence.stripe_subscription.status === 'trialing'
                                                    "
                                                    >{{ $tl("labels.subscriptions.freeTrial") }}</span
                                                >
                                            </div>
                                            <div
                                                v-if="
                                                    licence.active_promo_codes &&
                                                    licence.active_promo_codes.length > 0 &&
                                                    !memoPromoCodeLicence(licence, index)
                                                "
                                                class="col-5 text-right">
                                                <s>{{ getPriceString(getPrice(licence)) }}</s>
                                                {{ getPriceString(getDiscountedPrice(licence)) }} / {{ getLabelPlan(getPlan(licence)) }}
                                                <div>
                                                    <small>{{ getDiscountedPeriod(licence) }}</small>
                                                </div>
                                            </div>
                                            <div v-else class="col-5 text-right">
                                                {{ getFormattedPrice(licence) }}
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-12 d-flex align-items-center mb-2">
                                                <select
                                                    :disabled="
                                                        !has_right_to_update_subscription ||
                                                        licence.module.type === MODULE_TYPE_CLICK_AND_COLLECT_DISPATCH.value
                                                    "
                                                    class="custom-select m-0"
                                                    v-model="licence.restaurant_id"
                                                    @change="changeLicenceRestaurant($event, licence)">
                                                    <option :value="null" :disabled="true">
                                                        {{ $tl("labels.subscriptions.assignRestaurant") }}
                                                    </option>
                                                    <template v-for="(restaurant, index) in getRestaurantsForModule(licence.module.type)">
                                                        <option
                                                            :key="index"
                                                            v-if="restaurant.isFree || licence.restaurant_id == restaurant.id"
                                                            :value="restaurant.id"
                                                            :selected="licence.restaurant_id == restaurant.id">
                                                            {{ restaurant.name }}
                                                        </option>
                                                    </template>
                                                </select>
                                            </div>
                                        </div>
                                        <template v-if="!hasManualBilling">
                                            <button
                                                v-if="!licence.cancel_at_period_end"
                                                class="btn top-right m-0 p-0"
                                                v-tooltip="getTooltip($t('labels.subscriptions.delete'))"
                                                :disabled="!has_right_to_update_subscription"
                                                @click="deleteLicence(licence)">
                                                <feather type="x" class="bg-none icon m-0 p-0" />
                                            </button>
                                            <button
                                                v-else
                                                class="btn top-right m-0 p-0 mr-1"
                                                v-tooltip="getTooltip($t('labels.subscriptions.reactivate'))"
                                                :disabled="!canReactivateLicence(licence) || !has_right_to_update_subscription"
                                                @click="reactivateLicence(licence)">
                                                <feather type="refresh-ccw" class="bg-none icon m-0 p-0" />
                                            </button>
                                        </template>
                                        <div class="row">
                                            <div class="col-12">
                                                <span class="text-danger" v-if="licence.status === 'past_due' || licence.status === 'unpaid'">{{
                                                    $t("labels.subscriptions.willBeCanceledOn", {
                                                        date: getCancelationDate(licence),
                                                    })
                                                }}</span>
                                                <span style="color: red" v-else-if="getRenewDate(licence) !== undefined">
                                                    <template v-if="licence.cancel_at_period_end">{{
                                                        $t("labels.subscriptions.endAt", {
                                                            date: getRenewDate(licence, true),
                                                        })
                                                    }}</template>
                                                    <template v-else>{{
                                                        $t("labels.subscriptions.nextRenew", {
                                                            date: getRenewDate(licence),
                                                        })
                                                    }}</template>
                                                </span>
                                                <div v-if="licence.status === 'past_due' || licence.status === 'unpaid'">
                                                    <span v-if="licence.stripe_subscription.latest_invoice.payment_intent.status === 'processing'">
                                                        {{ $tl("labels.subscriptions.pendingPayment") }}
                                                    </span>
                                                    <button
                                                        v-else
                                                        class="btn btn-sm btn-circle btn-warning float-right"
                                                        @click="payPastDueSubscription(licence.id)">
                                                        {{ $tl("labels.subscriptions.tryPay") }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <div class="border-light b-radius-20 p-4 mb-3">
                            <ShowBillingDetails />

                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-12">
                                    <stripe-customer-card-list />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4 mb-3">
                            <div class="row text-center">
                                <template v-for="(cgv, index) in getCgvs()">
                                    <template v-if="!cgv.at_least_one_subscription || atLeastOneSubscription(cgv.at_least_one_subscription)">
                                        <div class="col-sm-6 pt-2" :key="`${index}-1`">
                                            <a :href="`${cgv.file}?v=${$__BUILD_ID__}`" target="_blank">
                                                <p class="bg-light p-3">
                                                    {{ cgv.label }}
                                                </p>
                                            </a>
                                        </div>
                                        <div class="col-sm-6 pt-3" v-if="isOwner" :key="`${index}-2`">
                                            <span v-if="cgv.accepted_cgv">
                                                {{
                                                    $t("infos.cgvs.acceptedOn", {
                                                        date: displayDate(cgv.accepted_cgv.last_accepted_at, DATE_SHORT),
                                                    })
                                                }}
                                            </span>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../components/LoaderComponent.vue";
import StripeCustomerCardList from "../components/stripe/Customer/PaymentMethods/CardList.vue";
import ShowBillingDetails from "../components/Default/Subscription/ShowBillingDetails.vue";
import ModuleTypesEnum from "../mixins/enums/ModuleTypesEnum.js";
import AcceptedCgvTypesEnum from "../mixins/enums/AcceptedCgvTypesEnum.js";
import { DateTime } from "luxon";
import SubscriptionStatusEnum from "../mixins/enums/SubscriptionStatusEnum.js";
import CouponDurationTypeEnum from "../mixins/enums/CouponDurationTypeEnum.js";

export default {
    data() {
        return {
            loading: 0,
            errors: null,
            errorMessages: [],
            stripe: {
                subscriptions: null,
            },
            restaurants: [],
            accepted_cgvs: [],
        };
    },
    mixins: [ModuleTypesEnum, AcceptedCgvTypesEnum, SubscriptionStatusEnum, CouponDurationTypeEnum],
    computed: {
        isOwner() {
            return this.$store.getters["users/role"] === "owner";
        },
        hasManualBilling() {
            return this.$store.getters["users/hasManualBilling"];
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_subscription() {
            return this.rights.includes("default.subscription.update");
        },
        flattenSubscriptions() {
            var subscriptions = [];

            for (var key in this.stripe.subscriptions) {
                subscriptions = subscriptions.concat(this.stripe.subscriptions[key]);
            }

            return subscriptions.filter((s) => s.status !== "canceled");
        },
    },
    methods: {
        getCgvs() {
            return [
                {
                    label: "Conditions Générales d’Utilisation",
                    file: `/cgu_global.pdf`,
                    accepted_cgv: this.getAcceptedCgvFromType(this.ACCEPTED_CGV_TYPE_DEFAULT.value),
                },
                {
                    label: "Politique de Confidentialité des Données Personnelles",
                    singular: true,
                    file: `/datapolicy.pdf`,
                    accepted_cgv: this.getAcceptedCgvFromType(this.ACCEPTED_CGV_TYPE_DEFAULT.value),
                },
                {
                    label: `Conditions Particulières - ${this.MODULE_TYPE_BOOKING.label}`,
                    file: `/cgu_booking.pdf`,
                    accepted_cgv: this.getAcceptedCgvFromType(this.ACCEPTED_CGV_TYPE_MODULES.value, this.MODULE_TYPE_BOOKING.value),
                    at_least_one_subscription: this.MODULE_TYPE_BOOKING.value,
                },
                {
                    label: `Conditions Particulières - ${this.MODULE_TYPE_CLICK_AND_COLLECT.label}`,
                    file: `/cgu_click_and_collect.pdf`,
                    accepted_cgv: this.getAcceptedCgvFromType(this.ACCEPTED_CGV_TYPE_MODULES.value, this.MODULE_TYPE_CLICK_AND_COLLECT.value),
                    at_least_one_subscription: this.MODULE_TYPE_CLICK_AND_COLLECT.value,
                },
                {
                    label: `Conditions Particulières - ${this.MODULE_TYPE_GIFT_VOUCHERS.label}`,
                    file: `/cgu_gift_vouchers.pdf`,
                    accepted_cgv: this.getAcceptedCgvFromType(this.ACCEPTED_CGV_TYPE_MODULES.value, this.MODULE_TYPE_GIFT_VOUCHERS.value),
                    at_least_one_subscription: this.MODULE_TYPE_GIFT_VOUCHERS.value,
                },
                {
                    label: `Conditions Particulières - ${this.MODULE_TYPE_WEBSITE.label}`,
                    file: `/cgu_website.pdf`,
                    accepted_cgv: this.getAcceptedCgvFromType(this.ACCEPTED_CGV_TYPE_MODULES.value, this.MODULE_TYPE_WEBSITE.value),
                    at_least_one_subscription: this.MODULE_TYPE_WEBSITE.value,
                },
                {
                    label: `Conditions Particulières - ${this.MODULE_TYPE_CAMPAIGNS.label}`,
                    file: `/cgu_campaigns.pdf`,
                    accepted_cgv: this.getAcceptedCgvFromType(this.ACCEPTED_CGV_TYPE_MODULES.value, this.MODULE_TYPE_CAMPAIGNS.value),
                },
            ];
        },
        getAcceptedCgvFromType(type, module = null) {
            return this.accepted_cgvs.find((acceptedCgv) => (acceptedCgv.type === type ? !module || acceptedCgv.modules.includes(module) : false));
        },
        payPastDueSubscription(licenceId) {
            this.loading++;

            this.$store
                .dispatch("stripe/payPastDueSubscription", { licenceId })
                .then((response) => {
                    this.notifySuccess(response);

                    setTimeout(() => {
                        this.initData();
                    }, 1000);
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => this.loading--);
        },
        atLeastOneSubscription(module_type) {
            return this.stripe && this.stripe.subscriptions
                ? Object.values(this.stripe.subscriptions).some((subscriptions) => subscriptions.some((s) => s.module.type === module_type))
                : false;
        },
        initData() {
            this.errors = null;
            this.errorMessages = [];

            this.loadSubscriptions(true);
            this.loadRestaurants(true);

            if (this.isOwner) {
                this.loadAcceptedCgvs();
            }
        },
        getNameString(licence) {
            return this.getModuleTypeLabel(licence.module.type);
        },
        getDiscountedPeriod(licence) {
            const promoCode = licence.active_promo_codes[0];

            if (!promoCode.pivot.applied_until) {
                return "";
            }

            return this.$tl("labels.promoCode.duration", undefined, {
                date: this.displayDate(DateTime.fromSQL(promoCode.pivot.applied_until), this.DATE_SHORT),
            });
        },
        getPrice(licence) {
            let price = 0;

            if (licence.stripe_subscription !== undefined) {
                switch (licence.stripe_subscription.stripe_subscription_item.plan.id) {
                    case licence.module.stripe_monthly_sku_id:
                        price = licence.module.stripe_monthly_price;
                        break;
                    case licence.module.stripe_monthly_discounted_sku_id:
                        price = licence.module.stripe_monthly_discounted_price;
                        break;
                    case licence.module.stripe_yearly_sku_id:
                        price = licence.module.stripe_yearly_price;
                        break;
                    case licence.module.stripe_yearly_discounted_sku_id:
                        price = licence.module.stripe_yearly_discounted_price;
                        break;
                    default:
                        break;
                }
            }

            return price;
        },
        getPriceString(price) {
            if (price === 0) {
                return "";
            }

            return this.formatCurrency(price / 100);
        },
        getHistoryPlan() {
            let plan = "";
            if (licence.stripe_subscription !== undefined) {
                switch (licence.stripe_subscription.stripe_subscription_item.plan.id) {
                    case licence.module.stripe_monthly_sku_id:
                        plan = this.STRIPE_PLAN_TYPE_MONTHLY.value;
                        break;
                    case licence.module.stripe_monthly_discounted_sku_id:
                        plan = this.STRIPE_PLAN_TYPE_MONTHLY.value;
                        break;
                    case licence.module.stripe_yearly_sku_id:
                        plan = this.STRIPE_PLAN_TYPE_YEARLY.value;
                        break;
                    case licence.module.stripe_yearly_discounted_sku_id:
                        plan = this.STRIPE_PLAN_TYPE_YEARLY.value;
                        break;
                    default:
                        break;
                }
            }
            return plan;
        },
        getLabelPlan(plan) {
            return plan === this.STRIPE_PLAN_TYPE_YEARLY.value ? this.$tl("labels.years") : this.$tl("labels.month");
        },
        getPlan(licence) {
            let plan = licence.plan;

            if (plan === null) {
                plan = this.getHistoryPlan();
            }
            return plan;
        },
        getDiscountedPrice(licence) {
            const promoCode = licence.active_promo_codes[0];
            if (promoCode.amount_off !== null) {
                return this.getPrice(licence) - promoCode.amount_off * 100;
            }

            if (promoCode.percent_off !== null) {
                const percent = (promoCode.percent_off * this.getPrice(licence)) / 100;

                return this.getPrice(licence) - percent;
            }

            return this.getPrice(licence);
        },
        memoPromoCodeLicence(licence, index) {
            if (licence.active_promo_codes[0].percent_off !== null) {
                return false;
            }

            const indexFound = this.alreadyListedLicences.findIndex((listedLicence) => listedLicence.stripe_id === licence.stripe_subscription_id);

            if (indexFound !== -1) {
                return this.alreadyListedLicences[indexFound].index !== index;
            } else {
                this.alreadyListedLicences.push({ stripe_id: licence.stripe_subscription_id, index: index });
                return false;
            }
        },
        getFormattedPrice(licence) {
            return this.getPriceString(this.getPrice(licence)) + " / " + this.getLabelPlan(this.getPlan(licence));
        },
        getRenewDate(licence, subday = false) {
            const stripeObject = licence.stripe_subscription;

            let renew = undefined;

            if (stripeObject !== undefined) {
                if (stripeObject.status === "trialing") {
                    renew = this.getDateTimeFromTimestamp(stripeObject.trial_end);
                } else if (stripeObject.status === "active") {
                    renew = this.getDateTimeFromTimestamp(stripeObject.current_period_end);
                }
            }

            if (renew) {
                if (subday) {
                    renew = renew.minus({ days: 1 });
                }

                return this.displayDate(renew, this.DATE_SHORT);
            }

            return renew;
        },
        getCancelationDate(licence) {
            if (licence.status === "past_due" && licence.past_due_at) {
                return this.displayDate(DateTime.fromSQL(licence.past_due_at).plus({ days: 7 }), this.DATE_SHORT);
            }

            if (licence.status === "unpaid" && licence.unpaid_at) {
                return this.displayDate(DateTime.fromSQL(licence.unpaid_at).plus({ days: 7 }), this.DATE_SHORT);
            }

            return "";
        },
        loadSubscriptions(handled = false) {
            if (!handled) {
                this.errorMessages = [];
            }

            this.loading++;

            return this.$store
                .dispatch("stripe/fetchAllSubscriptions")
                .then((response) => {
                    this.stripe.subscriptions = response.data.data;
                })
                .catch((error) => {
                    this.errorMessages.push(this.getErrorMsgFromErrorResponse(error));
                })
                .finally(() => this.loading--);
        },
        loadRestaurants(handled = false) {
            if (!handled) {
                this.errorMessages = [];
            }

            this.loading++;

            return this.$store
                .dispatch("restaurants/fetchAllRestaurants", {
                    includes: "?include=subscriptions",
                    store: true,
                })
                .then((response) => {
                    this.restaurants = response.data.data;
                })
                .catch((error) => {
                    this.errorMessages.push(this.getErrorMsgFromErrorResponse(error));
                })
                .finally(() => this.loading--);
        },
        loadAcceptedCgvs() {
            axios
                .get("/api/accepted_cgvs")
                .then((response) => {
                    this.accepted_cgvs = response.data.data;
                })
                .catch(() => {});
        },
        getRestaurantsForModule(type) {
            return this.restaurants.map((restaurant) => {
                restaurant.isFree =
                    restaurant.subscriptions && !restaurant.subscriptions.data.some((subscription) => subscription.module.type === type);

                return restaurant;
            });
        },
        changeLicenceRestaurant(event, licence) {
            this.$store
                .dispatch("stripe/linkLicenceToRestaurant", { licenceId: licence.id, restaurantId: event.target.value })
                .then(() => {
                    this.notifySuccess(null, this.$tl("success.subscriptions.updated"));

                    this.initData();

                    this.$store.dispatch("users/fetchCurrentUser", { set: true }).catch(() => false);
                })
                .catch((error) => {
                    this.notifyError(error);
                });
        },
        deleteLicence(licence) {
            if (licence.cancel_at_period_end) {
                return;
            }

            if (this.inEnum(licence.status, this.cancelNowSubscriptionStatus)) {
                if (!confirm(this.$tl("questions.subscriptions.cancelNow"))) {
                    return;
                }
            } else if (!confirm(this.$tl("questions.subscriptions.cancel"))) {
                return;
            }

            this.loading++;

            this.$store
                .dispatch("stripe/deleteSubscription", { licenceId: licence.id })
                .then(() => {
                    this.notifySuccess(null, this.$tl("success.subscriptions.updated"));

                    this.initData();
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => this.loading--);
        },
        canReactivateLicence(licence) {
            if (!licence.main_subscription_id) {
                return true;
            }

            const mainLicence = Object.values(this.stripe.subscriptions)
                .flat()
                .find((s) => s.id === licence.main_subscription_id);

            return typeof mainLicence === "undefined" ? false : !mainLicence.cancel_at_period_end;
        },
        reactivateLicence(licence) {
            if (!this.canReactivateLicence(licence) || !licence.cancel_at_period_end || !confirm(this.$tl("questions.subscriptions.reactivate"))) {
                return;
            }

            this.loading++;

            this.$store
                .dispatch("stripe/reactivateSubscription", { licenceId: licence.id })
                .then(() => {
                    this.notifySuccess(null, this.$tl("success.subscriptions.updated"));

                    this.initData();
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => this.loading--);
        },
    },
    created() {
        this.alreadyListedLicences = [];
        this.initData();
    },
    components: {
        LoaderComponent,
        StripeCustomerCardList,
        ShowBillingDetails,
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
