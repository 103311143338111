export default {
    settings: {
        options: "Options",
        menuOptions: "Options de prestations",
        generalOptions: "Options générales",
    },
    order: "Ordre",
    loading: "Veuillez patienter...",
    today: "Aujourd'hui",
    todayShort: "Auj",
    yesterday: "Hier",
    and: "et",
    person: "Aucune personne | 1 personne | {count} personnes",
    persons: "personne(s)",
    personss: "Personnes",
    personsShort: "pers.",
    searchResult: "Résultat de votre recherche",
    now: "maintenant",
    new: "Nouveau",
    showMore: "Voir plus",
    showLess: "Voir moins",
    infos: "Informations",
    licence: "Licence",
    licences: "Licences",
    help: "Aide",
    description: "Description",
    none: "Aucun",
    detail: "Détail",
    options: "Options",
    visualIdentity: "Identité visuelle",
    contactInfos: "Informations de contact",
    mediatorContact: "Coordonnées du médiateur",
    reservation: "Réservation",
    reservationPlural: "Réservations",
    useful: "Utile",
    yearly: "Annuel",
    yearlyF: "Annuelle",
    monthly: "Mensuel",
    monthlyF: "Mensuelle",
    ht: "HT",
    ttc: "TTC",
    price: "Prix",
    priceHT: "Prix HT",
    priceTTC: "Prix TTC",
    thousandSeparator: " ",
    decimalSeparator: ",",
    taxRate: "Taux de TVA",
    euros: "euros",
    year: "an",
    years: "ans",
    month: "mois",
    total: "Total",
    day: "jour(s)",
    hour: "heure(s)",
    free: "Gratuit",
    freeTry: "Essai gratuit",
    myAccount: "Mon compte",
    personalInfos: "Informations personnelles",
    accessibleRestaurants: "Établissement accessibles",
    credit: "crédit",
    creditCost: "Coût en crédits",
    smsCredits: "Crédits SMS",
    restOfTheWorld: "Reste du monde",
    thisWeek: "Cette semaine",
    pending: "En attente",
    pax: "pax",
    name: "Nom",
    type: "Type",
    importantInfo: "Information importante",
    remainingNb: "Reste {nb}",
    clientColon: "Client :",
    privateColon: "Privé :",
    paxTooHigh: "Pax dépassé",
    changeRestaurant: "Changer d'établissement",
    backToRestaurant: "Retouner à {restaurant}",
    noResult: "Aucun résultat",
    perPerson: "par personne",
    from: "à partir de",
    saveInProgress: "Enregistrement en cours",
    recap: "Récapitulatif",
    noshows: "Non-présentations",
    unlimited: "Illimité",
    manual: "Manuelle",
    manualM: "Manuel",
    auto: "Automatique",
    startDate: "Date de début",
    endDate: "Date de fin",
    startHour: "Heure de début",
    endHour: "Heure de fin",
    to: "Jusqu'à",
    timezone: "Fuseau horaire",
    optional: "Optionnel",
    mandatory: "Obligatoire",
    optionalF: "optionnelle",
    yes: "Oui",
    no: "Non",
    colon: " :",
    replica: "Copie",
    stepX: "Étape {step}",
    dashboard: "tableau de bord",
    checkAll: "Tout cocher",
    uncheckAll: "Tout décocher",
    useDefaultParams: "Utiliser les paramètres par défaut",
    default: "défaut",
    copy: "Copier",
    identifier: "L'identifiant",
    linkIdentifier: "Le lien",
    tokenIdentifier: "Le token",
    sheet: "Feuille",
    restaurants: "Établissements",
    service: "Service",
    services: "Services",
    dates: {
        to: "au",
        publicHoliday: "férié",
    },
    smsLength: "Longueur actuelle : {smsLength}",
    smsLengthApprox: "Longueur actuelle approximative : {smsLength}",
    smsCost: "0 crédit | 1 crédit | {count} crédits",
    mergeTags: "Champs de fusion",
    rules: "Règles",
    benefits: "Avantages",
    promoCode: {
        title: "Code Promo",
        applied: "Réduction de {promoCode} appliquée",
        appliedWithDuration: "Réduction de {promoCode} appliquée jusqu'au {period} (soit une économie de {total})",
        duration: "Le code promo est appliqué jusqu'au {date}",
    },
    filters: {
        title: "Filtres",
        status: "Statut",
        statuses: "Statuts",
        columns: "Colonnes",
        compare: "Comparaison",
        comparedTo: "comparé à",
        dates: {
            from: "Du",
            to: "Au",
            start: "Date de début",
            end: "Date de fin",
            lastWeek: "La semaine dernière",
            lastMonth: "Le mois dernier",
            lastYear: "L'an dernier",
            customPeriod: "Période personnalisée",
            previousPeriod: "Période précédente",
            previousYear: "Année précédente",
            sinceRegistration: "Depuis l'inscription",
            nextPeriod: "Période suivante",
        },
    },
    pager: {
        next: "Pagination suivante",
        previous: "Pagination précédente",
        nbResultsPerPage: "Résultats par page",
        firstPage: "Première page",
        lastPage: "Dernière page",
        nextPage: "Page suivante",
        previousPage: "Page précédente",
        nbResults: "({nbResults} résultats)",
        currentPageInfo: "{currentPage} sur {nbPages} pages",
    },
    password: {
        minChars: "{count} caractère minimum | {count} caractères minimum",
        minLowerCaseChars: "{count} minuscule | {count} minuscules",
        minUpperCaseChars: "{count} majuscule | {count} majuscules",
        minNumbers: "{count} chiffre | {count} chiffres",
        minSpecialChars: "{count} caractère spécial ({charsList}) | {count} caractères spéciaux ({charsList})",
    },
    businessTypes: {
        SARL: "SARL",
        SA: "SA",
        SAS: "SAS",
        SASU: "SASU",
        EI: "EI",
        EURL: "EURL",
        Autre: "Autre",
    },
    search: {
        result: "Résultat de votre recherche",
        noResult: "Aucun résultat de recherche",
    },
    widget: {
        booking: "réservation",
        show: "Voir mon widget",
        sendInstructions: "Transmettre les instructions d'intégration",
        integrationWebsite: "Intégration du widget sur votre site web",
        defaultLang: "Langue par défaut du widget hébergé",
        shortLink: "Lien court",
        apiKey: "Clé API",
        genNewApiKey: "Générer une nouvelle clé API",
        genApiKey: "Générer une clé API",
        integrationMethod: "Méthode d'intégration",
        containerId: "id_du_conteneur",
        yourApiKey: "votre_clé_API",
        code: "Exemple de code complet pour le widget en français à insérer à l'endroit où vous souhaitez afficher le module de {type}",
    },
    invoices: {
        canceled: "Annulée",
        pending: "En attente de paiement",
        number: "Facture n°{number}",
        refunded: "(Remboursé : {amount})",
        campaignsPack: "Pack {nb} emails campagnes",
        smsCampaign: "Campagne SMS",
        smsPack: "Pack {nb} Crédits",
        download: "Télécharger les factures",
        downloadSingle: "Télécharger la facture",
    },
    subscriptions: {
        subscription: "Abonnement",
        actual: "Vos licences actuelles",
        freeTrial: "(Essai gratuit)",
        assignRestaurant: "Assigner un établissement",
        endAt: "Votre abonnement prendra fin le {date}. Vous avez encore la possibilité de relancer votre abonnement.",
        nextRenew: "Prochain renouvellement : {date}",
        willBeCanceledOn: "Sans action de votre part la licence sera annulée le {date}",
        tryPay: "Relancer le paiement",
        reactivate: "Relancer l'abonnement",
        delete: "Supprimer la licence",
        moreInfos: "Plus d'infos",
        pendingPayment: "Une tentative de paiement est en cours.",
    },
    payments: {
        payment: "Paiement",
        pendingConfirmation: "En attente de la confirmation de paiement, veuillez patienter",
        expiresOn: "Expire {date}",
        methods: {
            title: "Moyen de paiement",
            empty: "Aucun moyen de paiement enregistré.",
            add: "Ajouter un moyen de paiement",
            card: {
                payWith: "Payer avec cette carte",
            },
            bankAccount: {
                payWith: "Payer avec ce compte bancaire",
            },
            other: {
                payWith: "Payer avec un autre moyen de paiement",
            },
        },
    },
    form: {
        username: "Identifiant",
        email: "Email",
        client: "Client",
        civility: "Civilité",
        changePassword: "Modifier mon mot de passe",
        oldPassword: "Ancien mot de passe",
        newPassword: "Nouveau mot de passe",
        password: "Mot de passe",
        passwordConfirm: "Confirmez le mot de passe",
        newPasswordConfirm: "Confirmez le nouveau mot de passe",
        role: "Rôle",
        status: "Statut",
        firstname: "Prénom",
        lastname: "Nom",
        fullname: "Nom",
        tel: "Téléphone",
        name: "Nom",
        type: "Typologie",
        emailBusiness: "Email professionnel",
        phoneNumber: "Téléphone",
        portalPhoneNumber: "Téléphone portable",
        address: "Adresse",
        addressComp: "Complément d'adresse",
        postalCode: "Code postal",
        city: "Ville",
        country: "Pays",
        website: "Site internet",
        company: "Société",
        businessName: "Raison sociale",
        businessType: "Type d'entreprise",
        logo: "Logo",
        color: "Couleur",
        lang: "Langue",
        optionalStep: "Étape optionnelle",
        configEnded: "Configuration terminée",
        acceptNewsletter: "Le client accepte les newsletters",
        acceptNewsletterShort: "Accepte les newsletters",
        comment: "Commentaire",
        description: "Description",
        date: "Date",
        indicative: "Indicatif par défaut",
        title: "Titre",
        object: "Objet",
        messageContent: "Contenu du message",
        pickColumnsToDisplay: "Choisir les colonnes à afficher",
        picture: "Image",
        actions: {
            orderMenus: "Ordonner les prestations",
            orderMenusOptions: "Ordonner les options de prestations",
            orderGeneralOptions: "Ordonner les options générales",
            title: "Actions",
            back: "Retour",
            next: "Suivant",
            save: "Enregistrer",
            place: "Placer",
            finalizeRegistration: "Finaliser mon inscription",
            add: "Ajouter",
            skip: "Passer",
            close: "Fermer",
            validate: "Valider",
            download: "Télécharger",
            edit: "Éditer",
            delete: "Supprimer",
            deleteEmail: "Supprimer l'email : {nameEmail}",
            previous: "Précédent",
            buy: "Acheter",
            command: "Commander",
            export: "Exporter",
            excelExport: "Export Excel",
            search: "Rechercher",
            cancel: "Annuler",
            apply: "Appliquer",
            import: "Importer",
            duplicate: "Dupliquer",
            preview: "Pré-visualiser",
            send: "Envoyer",
            print: "Imprimer",
            continue: "Continuer",
            modify: "Modifier",
            filter: "Filtrer",
            confirm: "Confirmer",
            confirmNoCancel: "Confirmer (sans annuler)",
            ok: "OK",
            select: "Sélectionner",
            reset: "Réinitialiser",
            cancelAllBookings: "Annuler toutes les réservations",
            view: "Voir",
            accept: "Accepter",
            associate: {
                client: "Associer un client",
            },
        },
        login: {
            login: "Connexion",
            loginWith: "Se connecter avec",
            button: "Connexion",
            rememberMe: "Se souvenir de moi",
            forgotPassword: "Mot de passe oublié",
            useMyEmail: "Ou avec mon email",
        },
        register: {
            title: "Inscription",
            button: "S'inscrire",
            goToLoginPage: "J'ai déjà un compte",
            CGU1: "J'accepte les",
            CGU2: "Conditions Générales d'Utilisation du service",
            PCDP1: "Je déclare avoir pris connaissance de la",
        },
        restaurant: {
            add: "Ajouter un établissement",
            select: "Sélectionner un établissement",
            titleFont: "Police des titres (widgets)",
            name: "Nom de l'établissement",
            noTVA: "L'établissement n'est pas assujetti à la TVA",
            currency: "Devise de l'établissement",
            showToRealizeView: 'Afficher le menu "À réaliser"',
            enableSeatingPlan: "Activer le plan de salle",
            actions: {
                delete: "Supprimer l'établissement",
            },
            delete: {
                modal: {
                    header: "Suppression de l'établissement",
                    body: {
                        paragraph1: "Vous êtes sur le point de supprimer cet établissement.",
                        paragraph2:
                            "Attention, <strong>toutes les données attachées</strong> à cet établissement seront <strong>supprimées</strong> et cette action est <strong>irréversible.</strong>",
                        form: {
                            checkbox: "Je comprends que l'établissement sera supprimé définitivement.",
                            text: 'Inscrivez "{restaurantName}" pour confirmer la suppression.',
                        },
                    },
                },
            },
        },
        facturation: {
            titleShort: "Facturation",
            title: "Informations de facturation",
            titleEstablishment: "Informations de facturation de l'établissement",
            titleClient: "Informations de facturation principales du client",
            siret: "SIRET",
            nafCode: "Code NAF",
            tvaNumber: "N° de TVA",
        },
        image: {
            choose: "Choisir une image",
            chooseNew: "Choisir une nouvelle image",
            delete: "Supprimer l'image",
            dontForgetSave: "N'oubliez pas d'envoyer votre image !",
            compress: "Compresser une image",
        },
        file: {
            choose: "Choisir un fichier",
            chooseNew: "Choisir un nouveau fichier",
            view: "Voir le fichier",
            delete: "Supprimer le fichier",
            dontForgetSave: "N'oubliez pas d'envoyer votre fichier !",
        },
        psp: {
            link: "Connexion au système de paiement",
            pspCard: "Système de paiement par carte bancaire",
            editPaymentMethod: "Modifier le mode de paiement",
            paymentMethods: {
                card: {
                    title: "Carte bancaire",
                },
                sepaDebit: {
                    title: "Compte bancaire",
                    owner: "Titulaire du compte bancaire",
                    email: "Adresse email du titulaire du compte bancaire",
                },
            },
            stripe: {
                config: "Configuration de Stripe",
                linkAccount: "Connecter un compte Stripe",
                description1:
                    "Stripe est une passerelle de paiement sécurisée qui vous permet de collecter les empreintes bancaires et enregistrer des prépaiements depuis notre plateforme.",
                description2:
                    "Pour mettre en place ce service, vous n'avez pas besoin d'abonnement. Stripe se rémunère sur les opérations enregistrées sur votre compte à hauteur de 1.5% + 0.25€ par transaction pour les cartes européennes standard (soit 1.75€ pour 100€), 1,9 % + 0,25 € par transaction pour les cartes européennes premium, 2,5 % + 0,25 € par transaction pour les cartes émises au Royaume-Uni et 3,25 % + 0,25 € par transaction pour les cartes non européennes (tarifs sous réserve d'évolution).",
                description3:
                    'Pour plus d\'informations, référez-vous à la section "Cartes de crédit et de débit" <a href="https://stripe.com/fr/pricing#pricing-details" target="_blank">disponible ici</a>',
                clientID: "Votre ID client est :",
                clientEmail: "Votre email Stripe est :",
                disconnectAccount: "Déconnecter mon compte Stripe",
            },
            payplug: {
                linkAccount: "Connecter le compte PayPlug",
                register1: "Pour activer votre compte PayPlug, vous devez vous inscrire en cliquant sur",
                register2: "ce lien",
                minimumOffer:
                    'Note importante : L\'offre "Starter" n\'est pas compatible avec notre application. Merci de sélectionner l\'offre "Pro" au minimum.',
                help1: "Afin de lier votre compte PayPlug à {appName}, vous devez reporter vos clés d'API dans les champs ci-dessous.",
                help2: "Ces dernières sont accessibles",
                help3: "depuis ce lien",
                help4: "(vous devez être connecté à votre compte PayPlug).",
                publicApiKey: "Clé d'API Publique",
                privateApiKey: "Clé d'API Secrète",
                description1:
                    "PayPlug est une passerelle de paiement sécurisée qui vous permet de mettre en place le paiement en ligne depuis notre plateforme.",
                description2:
                    "Selon votre volume d'affaires, vous pourrez prétendre à une offre négociée de 65 € / mois (au lieu de 80 €) + commissions de 0.5% + 0.15€ par transaction pour les cartes européennes (soit 0.65€ pour 100€) et 2.5% + 0.25€ par transaction pour les cartes non européennes.",
                disconnectAccount: "Déconnecter mon compte PayPlug",
                notifications: {
                    help1: "Afin d'éviter l'envoi de mails en doublon, nous vous conseillons de désactiver certaines notifications automatiques sur votre compte PayPlug.",
                    help2: "Ces dernières sont accessibles",
                    help3: "depuis ce lien",
                    help4: "Les notifications à désactiver sont :",
                    notifs: ["Confirmations de paiement", "Confirmations de remboursement", "Paiements réussis", "Échecs de notifications serveurs"],
                },
            },
        },
        licences: {
            select: "Sélectionner une licence",
            assign: "Assigner une licence",
            title: "Licences",
            titleSingular: "Licence(s)",
            discountYearly: "Remise de 200 €/an pour toute licence additionnelle",
            askTraining: "Je souhaite bénéficier d'une formation (sur devis)",
            training: "Formation",
            quotationSendAfter: "Un devis vous sera communiqué ultérieurement",
            monthlyCost: "Coût par mois",
            yearlyCost: "Coût par an",
            CGU1: "Je déclare avoir pris connaissance des",
            CGU2: "conditions particulières d’utilisation du module de",
        },
        sms: {
            edit: "Configuration des SMS",
            enable: "Activer les SMS",
            notEnoughRights: "Désolé, vous ne possédez pas les droits nécessaires pour activer les sms.",
            emitterName: "Nom affiché sur le téléphone à la réception du message",
            sendToForeign: "Envoyer des sms aux numéros étrangers",
            detailsFor: "Détails du {packName}",
            packDetails: "Détails du pack",
            packName: "Nom du pack",
        },
        emails: {
            add: "Ajouter un email",
        },
        textarea: {
            nbMinChars: "Min : {count}",
            nbMaxChars: "Max : {count}",
            nbActualChars: "Actuel : {count} caractère | Actuel : {count} caractères",
        },
    },
    routes: {
        // Default
        home: "Accueil",
        account: "Profil",
        restaurants: "Établissements",
        addRestaurant: "Ajouter un établissement",
        subscription: "Abonnement",
        addSubscription: "Ajouter une licence",
        rgpd: "RGPD",
        users: "Utilisateurs",
        employees: "Employés",
        addEmployee: "Ajouter un employé",
        editEmployee: "Éditer l'utilisateur :",
        sms: "SMS",
        help: "Assistance",
        documentation: "Documentation",
        onlineHelp: "Aide en ligne",
        invoices: "Factures",
        disconnect: "Déconnexion",
        clients: "Clients",
        notificationsLogs: "Suivi Notifications | Mails",
        appUpdates: "Notes de mise à jour",
        rwg: "Réserver avec Google",
        manageAreas: "Gérer les groupes",

        // Booking
        booking: {
            dashboard: "Tableau de bord",
            globalStats: "Rapport global",
            listReservations: "Réservations",
            liveService: "En cours",
            kitchen: "À réaliser",
            payments: "Paiements",
            stats: "Rapport",
            clients: "Clients",
            settings: "Configuration",
            config: {
                settings: "Configuration",
                services: "Plages horaires",
                seatingPlan: "Plans de salle",
                notifications: "Notifications",
                integration: "Intégration",
                cgu: "CGU",
                inputParams: "Paramètres de saisie",
                interfaces: "Interfaces",
            },
            services: {
                current: "Plages horaires",
                menus: "Prestations",
                menusOptions: "Options de prestations",
                special: "Ouvertures exceptionnelles",
                closures: "Fermetures exceptionnelles",
            },
        },
    },
    notifications: {
        title: "Notifications",
        editChannel: "Personnaliser le canal de réception",
        enable: "Activer les notifications",
        enableRingtone: "Activer la sonnerie à la reception d'une notification",
        sms: "SMS",
        mail: "Mail",
        disabled: "Désactivée",
        config: "Configuration des notifications",
        preview: {
            mail: "Prévisualisation de l'email",
            sms: "Prévisualisation du sms",
        },
        deleteAll: "Supprimer toutes les notifications",
        send: {
            title: "Envoyer la notification",
            manually: "Manuellement",
            auto: "Automatiquement",
        },
        qrCode: {
            title: "QR Code",
            enableForResa: "Activer la génération d'un QR Code par réservation",
        },
        booking: {
            delayExpired: "Délai dépassé",
            footprintPaymentExpired: "Délai de prise d’empreinte bancaire / prépaiement dépassé",
            askFootprint: "Demande d'empreinte bancaire",
            askPayment: "Demande de prépaiement",
            askConfirmation: "Demande de confirmation de réservation",
            canceled: "Réservation annulée",
            canceledNoFootprint: "Réservation annulée - défaut d'empreinte bancaire",
            canceledNoPayment: "Réservation annulée - défaut de prépaiement",
            created: "Réservation en attente de validation",
            createdShort: "Réservation en attente",
            confirmed: "Réservation confirmée",
            updated: "Réservation modifiée",
            confirmedWithFootprint: "Réservation confirmée - information d'empreinte bancaire",
            noshowTotal: "No-show - prélèvement total",
            noshowPartial: "No-show - prélèvement partiel",
            noshowFree: "No-show sans prélèvement",
            overThanks: "Réservation terminée - remerciement",
            serviceOver: "Liste d'attente - plage horaire terminée",
            tableAvailable: "Liste d'attente - place disponible",
            refused: "Réservation refusée",
            reminder: "Rappel de réservation",
            reminderFootprint: "Rappel de demande d'empreinte bancaire",
            reminderPayment: "Rappel de demande de paiement",
            validated: "Nouvelle réservation",
            google_reserve: 'Réservation prise par "Réserver avec Google"',
            web: "Réservation web",
            public_api: "Réservation synchronisée par {provider}",
            seatingPlanRoomDisabled: {
                title: "Salles bientôt désactivées",
                content: "Le plan de salle suivant : {seatingPlan} n'aura bientôt plus de salles disponibles.",
                link: "Vérifier le paramétrage du plan de salle",
            },
            restauResaAllPlaced: {
                title: "Traitement terminé",
                content: "Toutes les réservations ont pu être replacées avec succès",
            },
            restauResaNotPlaced: {
                title: "Action requise",
                content:
                    "0 | Suite à une suppression de place, une réservation n’a pas pu être placée pour le {date}. Merci de la placer manuellement sur le plan de salle. | Suite à une suppression de place, {count} réservations n’ont pas pu être placées pour le {date}. Merci de les placer manuellement sur le plan de salle.",
            },
        },
        clickAndCollect: {
            newAndCreated: "Nouvelle commande / Commande en attente",
        },
        giftVouchers: {
            new: "Nouvelle vente",
        },
        general: {
            title: "Général",
            smsAlert: "Alerte solde SMS",
        },
        listNotificationsLogs: {
            title: "Liste des notifications",
            mailType: "Type de mail",
            delivered: "Délivré",
            inProgress: "En cours d'envoi",
            notDelivered: "Non délivré",
            grid: {
                columns: {
                    commons: {
                        client: "Client",
                        numero: "Numéro",
                        sentDate: "Date de l'envoi",
                        shipmentStatus: "Statut de l'envoi",
                        resendMail: "Renvoyer le mail",
                        hours: "Heure",
                    },
                    reservation: {
                        reservationDate: "Date de réservation",
                        pax: "PAX",
                    },
                    clickAndCollect: {
                        collectType: "Mode d'envoi",
                        commandDate: "Date de la commande",
                    },
                    giftVouchers: {
                        gvSaleDate: "Date",
                        product: "Produit",
                        beneficiary: "Bénéficiaire",
                    },
                },
            },
        },
    },
    modules: {
        booking: "Réservations",
        "click-and-collect": "Vente en ligne",
        "gift-vouchers": "Bons cadeaux",
        website: "Site web",
        "click-and-collect-dispatch": "Option expédition",
        campaigns: "Campagnes marketing",
        default: "Général",
        actions: {
            goTo: "Accéder",
            enable: "Activer",
        },
    },
    rgpd: {
        pcdp: "Politique de Confidentialité des Données Personnelles",
        timeToKeep: "Durées de conservation des données",
        clientNewsletter: "Inscription du client sur la liste de diffusion de la newsletter",
        clientData: "Coordonnées du client et historique des réservations / commandes",
    },
    roles: {
        manager: "Responsable",
        employee: "Employé",
        owner: "Gérant",
        room: "Salle",
        kitchen: "Cuisine",
        user_without_account: "Utilisateur sans compte",
    },
    employees: {
        empty: "Aucun employé pour cet établissement",
    },
    clients: {
        show: "Voir la fiche client",
        add: "Ajouter un client",
        edit: "Éditer la fiche client",
        editName: "Éditer le client :",
        noshow: "no-show(s)",
        alreadyExist: "Un client existe déjà avec ce numéro de téléphone.",
        searchPlaceHolder: "Nom, Téléphone...",
        accustomed: "Clients habitués",
        disableFootprintVIP: "Désactiver l'empreinte bancaire pour les clients VIP",
        disableFootprintLoyaltyLevel: "Désactiver l'empreinte bancaire pour les clients fidèles",
        emptyForRestaurant: "Encore aucun client pour cet établissement",
        count: "Aucun client | 1 client | {count} clients",
        countSearch:
            "Aucun client ne correspond à votre recherche | 1 client correspond à votre recherche | {count} clients correspondent à votre recherche",
        deleteData: "Supprimer les données du client",
        resetNoShowCount: "Réinitialiser le compte de noshow",
        resetOn: "réinitialisé le : {date}",
        history: "Historique du client",
        historyOn: "historique du 1 an | historique sur {count} ans",
        type: "Typologie de client",
        typeShort: "Typologie",
        loyalty_level: "Statut fidélité",
        vip: "Client VIP",
        risky: "Client à risque",
        isHotel: "Client Hôtel",
        editLoyaltyConfig: "Paramétrage fidélité",
        unknown: "Inconnu",
        import: {
            title: "Importer des clients",
            inProgress: "Import en cours ...",
            clientStatus: "Statut du client",
            statusLabels: {
                vip: "vip",
                accustomed: "habitué",
                michelin_inspector: "michelin",
                gault_millau_inspector: "gault_millau",
                not_reliable: "douteux",
                journalist: "journaliste",
            },
            steps: {
                1: {
                    title: "Sélection du fichier",
                    acceptedFiles: "Les formats de fichiers acceptés sont {formattedAllowedExtensions}.",
                    specificToCSV: 'Pour les fichiers ".csv", le delimiteur doit être le point-virgule (";") et posséder le format UTF-8.',
                    noHeader: "Attention : le fichier importé <strong>ne doit pas comporter d'entête</strong>.",
                    defineCol: "La signification de chaque colonne sera définie à l'étape suivante.",
                    exampleFormat: 'Exemple au format "{ext}"',
                    allowedFields: {
                        title: "Liste des champs autorisés :",
                        phoneInfo: "Attention le champ doit être au format texte",
                        phoneCountryCode: "Code Pays du numéro de téléphone",
                        phoneCountryCodeFormat: "(format : {link})",
                        clientTypeDefault: "par défaut aucune",
                        isVip: "Client VIP : 1 ou 0 (1 = oui, 0 = non), par défaut 0",
                        isRisky: "Client à risque : 1 ou 0 (1 = oui, 0 = non), par défaut 0",
                        newsletter: "Newsletter email : 1 ou 0 (1 = oui, 0 = non), par défaut 0",
                    },
                },
                2: {
                    title: "Organisation des colonnes",
                    linkColumns: "Associez les colonnes aux différents champs",
                    fillMandatoryColumns: "Merci d'indiquer toutes les colonnes obligatoires (*)",
                    emptyLine: "La ligne est vide",
                    colNb: "Colonne {nb}",
                    ignoreCol: "Ignorer cette colonne",
                    newsletter: "Newsletter email",
                },
            },
        },
        merge: {
            single: "Fusionner la fiche client",
            multiple: "Fusionner des fiches client",
            keep: "Sélectionnez la fiche client à conserver :",
        },
        status: {
            vip: "Client VIP",
            accustomed: "Client habitué",
            michelin_inspector: "Inspecteur Michelin",
            gault_millau_inspector: "Inspecteur Gault & Millau",
            not_reliable: "Douteux",
            journalist: "Journaliste",
            risky: "Client à risque",
        },
        civilities: {
            short: {
                monsieur: "M.",
                madame: "Mme",
            },
            long: {
                monsieur: "Monsieur",
                madame: "Madame",
            },
        },
        types: {
            business: "Affaire",
            tourist: "Touriste",
            single: "Individuel",
            group: "Groupe",
            journalist: "Journaliste",
            michelin_inspector: "Inspecteur Michelin",
            gault_millau_inspector: "Inspecteur Gault & Millau",
            custom: "Personnalisée",
        },
        loyalties: {
            none: "Aucun",
            1: "Bon client",
            2: "Très bon client",
            3: "Excellent client",
            4: "Client premium",
        },
        caption: {
            title: "Légende",
            details: "Informations sur la légende",
            loyalties: "Niveaux de fidélité :",
            typologies: "Typologies de clients :",
            miscellaneous: "Divers :",
        },
        loyaltyConfigs: {
            specificRestaurant: "Spécifiques à l'établissement",
            allRestaurants: "Tous établissements confondus",
            resetFrom: {
                booking:
                    "Le niveau de fidélité est annulé si le client n'a aucune réservation future à son nom et n'a pas honoré de réservation depuis",
                "click-and-collect": "Le niveau de fidélité est annulé si le client n'a aucune commande future à son nom et n'a pas commandé depuis",
                "gift-vouchers": "Le niveau de fidélité est annulé si le client n'a pas acheté de bon-cadeau depuis",
            },
        },
    },
    booking: {
        covers: "personnes",
        inputParams: {
            noConfig: "Veuillez enregistrer votre configuration avant de modifier les paramètres de saisie.",
            fields: {
                chooseMandatory: {
                    booking: "Choisir les champs obligatoires à saisir dans le formulaire de réservation du back office",
                    "click-and-collect": "Choisir les champs obligatoires à saisir dans le formulaire de bon de commande du back office",
                    "gift-vouchers": "Choisir les champs obligatoires à saisir dans le formulaire de bon cadeau du back office",
                },
            },
            tags: {
                addCustom: "Ajouter des tags personnalisés pour les commentaires de réservations",
                empty: "Vous n'avez ajouté aucun tag personnalisé",
            },
        },
        payments: {
            filterPlaceHolder: "Client, tél...",
            status: {
                not_created: "Non envoyé",
                pending: "En attente",
                canceled: "Annulé",
                refunded: "Remboursé",
                partially_refunded: "Remboursé partiellement",
                capturable: "Confirmé",
                captured: "Capturé",
                expired: "Expiré",
            },
            types: {
                footprint: "Empreinte bancaire",
                payment: "Prépaiement",
            },
            grid: {
                export: {
                    title: "liste_des_paiements",
                },
                columns: {
                    id: "N°",
                    client: "Client",
                    reservationDate: "Date de réservation",
                    type: "Type",
                    amount: "Montant",
                    fees: "Frais",
                    status: "Statut",
                    paymentDate: "Date de paiement",
                    actions: "Actions",
                },
            },
        },
        closures: {
            empty: "Aucune fermeture exceptionnelle pour cet établissement",
            services: "Plage(s) horaire",
            concernedServices: "Plages horaires concernées",
            noServiceForDate: "Aucune plage horaire disponible pour les dates sélectionnées",
            title: "Fermeture exceptionnelle",
            forToday: "Une fermeture exceptionnelle est programmée aujourd'hui.",
            forTodayWithName: "Une fermeture exceptionnelle est programmée aujourd'hui : {closureName}.",
            add: "Ajouter une fermeture",
            addLong: "Ajouter une fermeture exceptionnelle",
            edit: "Éditer la fermeture :",
            dates: "Date(s)",
        },
        openings: {
            title: "Ouverture exceptionnelle",
        },
        restaurant: {
            closed: "Établissement fermé aujourd'hui.",
            closedForSomeService: "Établissement fermé aujourd'hui pour certaines plages horaires.",
        },
        list: {
            title: "Liste",
            empty: "Vous n'avez pas de réservations aujourd'hui.",
            emptyForService: "Vous n'avez pas de réservations pour cette plage horaire.",
        },
        timeline: {
            title: "Timeline",
        },
        seatingPlan: {
            title: "Plan de salle",
            launchAlgo: "Lancer le placement automatique des réservations",
            launchAlgoShort: "Lancer le placement auto",
            emptyForService: 'Vous n\'avez pas de réservations aujourd\'hui dans "{status}", pour la plage horaire "{service}".',
            dlbClickShowResa: "Double-Click = détails de la réservation.",
            tables: "Places",
            groups: "Groupes",
            printing: "Impression",
            ignorePlacement: "Ignorer le placement",
            add: "Créer un plan de salle",
            edit: "Modifier le plan de salle",
            duplicate: "Dupliquer le plan de salle",
            delete: "Supprimer le plan de salle",
            recap: "Récapitulatif du plan de salle",
            print: "Imprimer",
            nbTables: "Nombre de tables :",
            nbSeats: "Nombre de places :",
            countSeats: "1 place | {count} places",
            groupTables: "Grouper des tables",
            selectNbPeopleByTable: "Sélectionnez le nombre de personnes à placer à la table",
            room: {
                title: "Salle",
                config: "Configuration de la salle",
                name: "Nom de la salle",
                add: "Ajouter une salle",
                disabledForService: "Cette salle est désactivée pour cette plage horaire",
                enabledForService: "Cette salle a été activée pour cette plage horaire uniquement",
                disabledInConfig: "Cette salle a été désactivée dans la configuration du plan de salle",
                toggleEnabled: "Activer / désactiver cette salle",
                enable: "Activer cette salle",
            },
            table: {
                title: "Table",
                config: "Configuration de la table",
                name: "Table {name}",
                number: "Numéro de table",
                size: "Taille de la table",
                enabledPax: "Pax activés pour la table",
                selected: "Tables sélectionnées",
                toggleEnabled: "Activer / désactiver cette table",
                group: "Grouper ces tables",
                enable: "Activer cette table",
            },
            group: {
                title: "Groupe",
                titleShort: "Gr.",
                config: "Configuration du groupe",
                name: "Nom du groupe",
                size: "Taille du groupe",
                enabledPax: "Pax activés pour le groupe",
                toggleEnabled: "Activer / désactiver ce groupe",
            },
            elements: {
                add: "Ajouter {element}",
                single: "un élément",
                structure: "Structures",
                table: "Tables",
                decoration: "Décorations",
                furniture: "Meubles",
                diverse: "Divers",
                "round-table": "une table ronde",
                "square-table": "une table carré",
                "rectangle-table": "une table rectangulaire",
                counter: "un élément rectangulaire",
                flower: "une plante",
                delimiter: "une séparation",
                aquarium: "un aquarium",
                "wardrobe-wine": "une armoire a vin",
                chimney: "une cheminée",
                kitchen: "une cuisine",
                stairs1: "un escalier",
                "stairs1-right": "un escalier",
                stairs2: "un escalier",
                "stairs2-right": "un escalier",
                stairs3: "un escalier",
                "stairs3-left": "un escalier",
                stairs4: "un escalier",
                "car-park": "un parking",
                pool: "une piscine",
                beach: "une plage",
                door1: "une porte",
                door2: "une porte",
                buffet: "un buffet",
                fridge: "un frigo",
                toilet: "des toilettes",
            },
            colors: {
                unique: "Couleurs en fonction de la réservation",
                status: "Couleurs en fonction du statut",
            },
            columns: {
                name: "Nom",
                services: "Plages horaires",
                createDate: "Date de création",
                updateDate: "Date de dernière modification",
                actions: "Actions",
            },
            switchTables: {
                title: "Intervertir les tables de deux réservations",
                select: "Sélectionnez les tables à intervertir",
            },
            tools: {
                title: "Outils",
                move: "Outil Déplacement",
                select: "Outil Rectangle de sélection",
                rotateCw: "Outil Rotation Horaire",
                rotateCcw: "Outil Rotation Anti-Horaire",
                duplicate: "Dupliquer une table",
                delete: "Outil Suppression",
                "width-plus": "Augmenter la taille",
                "width-minus": "Réduire la taille",
            },
            reservationList: {
                hide: "Masquer la liste des réservations",
                show: "Afficher la liste des réservations",
            },
            groupsAndPrint: {
                hide: "Masquer les groupes et l'onglet impression",
                show: "Afficher les groupes et l'onglet impression",
            },
        },
        menus: {
            title: "Prestations",
            add: "Ajouter une prestation",
            edit: "Éditer la prestation :",
            mandatory: "Prestation obligatoire",
            mandatoryFrom: "Prestation obligatoire à partir de",
            unique: "Prestation unique",
            uniqueFrom: "Prestation unique à partir de",
            empty: "Aucune prestation pour cet établissement",
            childrenOnly: "Prestation enfant uniquement",
            price: "Prix (en {currency})",
            description: "Description",
            externalDescription: {
                externalDescription: "Description externe",
                link: {
                    link: "Lien",
                    placeholder: "https://votre.site/votre-carte",
                },
                file: {
                    pdf: "PDF",
                    see: "Voir le fichier",
                },
                disabled: "Désactivée",
            },
            externalMenu: {
                externalMenu: "Visualisation document (ex: carte d'un restaurant)",
                link: {
                    link: "Lien",
                    placeholder: "https://votre.site/votre-carte",
                },
                file: {
                    pdf: "PDF",
                    see: "Voir le fichier",
                },
                disabled: "Désactivée",
            },
            options: {
                title: "Options de prestation",
                link: "Associer des options",
                add: "Ajouter une option",
                addMenuOption: "Ajouter une option de prestation",
                addGeneralOption: "Ajouter une option générale",
                edit: "Éditer l'option :",
                quantity: "Quantité",
                empty: "Aucune option pour cet établissement",
                type: "Type d'option",
                enabledSettings: "Conditions de disponibilité",
                enabledFrom: "L'option est activée à partir de",
                disabledFrom: "L'option est désactivée à partir de",
                types: {
                    menu: "Option de prestation",
                    general: "Option générale",
                },
                quantityTypes: {
                    unlimited: "Illimitée",
                    limited_by_pers: "Limitée au nombre de prestations",
                    mandatory_for_each: "Obligatoire pour tous",
                },
            },
        },
        generalOptions: {
            title: "Options générales",
            add: "Ajouter une option générale",
            edit: "Éditer l'option générale :",
        },
        services: {
            add: "Ajouter une plage horaire",
            name: "Nom de la plage horaire",
            edit: "Éditer la plage horaire :",
            duplicate: "Dupliquer une plage horaire",
            next: "Prochaine plage horaire",
            live: "Plage horaire en cours",
            createFirst: "Créer ma première plage horaire",
            noOneAvailable: "Aucune plage horaire n'est disponible",
            startHour: "Heure de début de la plage horaire",
            endHour: "Heure de fin de la plage horaire",
            maxPax: "Nombre de personnes maximum par plage horaire",
            enableMaxPax: "Activer la configuration du nombre de {term} par créneaux",
            coversPerSlot: "Personnes / créneaux",
            inCaseFull: "En cas de plage horaire complète",
            showRestaurantService: "Proposer au client les plages horaires des établissements suivants :",
            takeResaLive: "Prendre des réservations pendant la plage horaire",
            minsBeforeStart: "minutes avant le début de la plage horaire",
            servicesOnDate: "Plage  horaire du {date} :",
            emptyForRestaurant: "Aucune plage horaire pour cet établissement",
            inCaseFull: "En cas de plage horaire complète",
            recap: "Récapitulatif de la plage horaire",
            editDayMaxPax: "Modifier le PAX max du jour sur cette plage horaire",
            full: "Complet",
            setFull: "Passer un sercice en complet",
            closed: "fermé",
            closedPartially: "partiellement fermé",
            closedSpecial: "fermé exceptionnellement",
            surbooking: "Surbooking",
            category: "Catégorie",
            categoryName: "Nom de la catégorie",
            activeDays: "Jours actifs",
            pax: "PAX",
            emptyForRestaurant: "Aucune plage horaire pour cet établissement",
            slots: "Créneaux disponibles à la réservation",
            maxPax: "Nombre de couverts maximum par plage horaire",
            maxPaxShort: "PAX max",
            minPers: "Nombre minimum de personnes par réservation",
            maxPers: "Nombre maximum de personnes par réservation",
            resasPerSlot: "Réservations / créneaux",
            takeResas: "Prise de réservation",
            takeResaLive: "Prendre des réservations pendant la plage horaire",
            blockResas: "Bloquer les réservations",
            minsBeforeStart: "minutes avant le début de la plage horaire",
            minsBeforeSlotStarts: "minutes avant le créneau horaire choisi",
            enableOnWidget: "Autoriser la prise de réservation depuis le widget",
            cancelationDelay: "Délais d'annulation",
            hoursBeforeResa: "heure(s) avant la réservation",
            resasConfirm: "Confirmation des réservations",
            percentFillRate: "% de remplissage",
            blockAutoConfirm: "Bloquer la confirmation automatique aux clients ayant",
            allowClientsChooseRoom: "Autoriser les clients à choisir leur salle",
            lateResa: "Réservation tardive",
            resasCancelation: "Annulation des réservations",
            showRestaurantService: "Proposer au client les plages horaires des établissements suivants :",
            servicesOnDate: "Services du {date} :",
            managePerPax: "Gestion par pax",
            showOnDashboard: "Affichage TDB",
            showOnDashboardLong: "Affichage de la plage horaire sur le tableau de bord",
            enableTableRotation: "Activer la rotation des places",
            averageTimeAtTable: "Temps moyen sur place",
            enableAlgorithm: "Activer le remplissage de salle en fonction de mes places (PAX réel)",
            services: "Plages horaires",
            service: "Plages horaire",
            menusAndOptions: {
                title: "Prestations et options",
                link: "Associer des prestations",
                enableFrom: "Prestation activée à partir de",
                disableFrom: "Prestation désactivée à partir de",
                menus: "Prestation(s)",
            },
            generalOptions: {
                link: "Associer des options générales",
            },
            special: {
                title: "exceptionnel",
                add: "Ajouter une ouverture exceptionelle",
                addShort: "Ajouter une ouverture",
                edit: "Éditer l'ouverture exceptionnelle :",
                duplicate: "Dupliquer une ouverture exceptionnelle",
                dates: "Dates de l'ouverture exceptionnelle",
                datesShort: "Date(s)",
                empty: "Aucune ouverture exceptionnelle pour cet établissement",
            },
            memos: {
                title: "Mémo",
                titlePlural: "Mémos",
                type: "Saisir le texte ...",
            },
            notes: {
                add: "Ajouter une note ...",
            },
            categories: {
                brunch: "Brunch",
                midi: "Midi",
                soir: "Soir",
                autre: "Autre",
            },
            footprintAndPayment: {
                title: "Empreinte bancaire et prépaiement",
            },
            footprint: {
                enable: "Activer l'empreinte bancaire",
                amountToCapture: "Montant à prélever par personne en cas de non-présentation",
                autoCancelation:
                    "Annulation des réservations dont les informations bancaires ne sont pas reçues sous 48h (ou {shortDelay}h avant l'heure de la réservation si celle-ci est dans moins de 48h)",
                autoReminder: "Envoi rappel demande empreinte bancaire ou prépaiement",
                autoHour: "Paramétrer l'heure d'envoi du rappel avant l'échéance de la demande de prépaiement ou d'empreinte bancaire",
            },
            payment: {
                enable: "Activer le prépaiement",
                is: "Le prépaiement est :",
                amountToCapture: "Montant à prélever lors de la réservation",
            },
        },
        waitings: {
            list: {
                title: "Liste d'attente",
            },
            mailLogs: {
                type: "de place disponible",
                see: "Voir les mails de la liste d'attente",
            },
        },
        footprint: {
            released: "Empreinte libérée",
            expired: "Empreinte dépassée",
            pending: "Empreinte en attente",
            validated: "Empreinte validée",
        },
        payment: {
            refunded: "Paiement remboursé",
            released: "Paiement non prélevé",
            expired: "Paiement dépassé",
            pending: "Paiement en attente",
            validated: "Paiement validé",
        },
        reservations: {
            noshowStatus: "Passer la réservation en non-présentation",
            standardReservation: "Réservation standard",
            noshowWithoutDeduction: "Non-présentation (sans prélèvement)",
            editService: "Modifier la plage horaire",
            table: "Place",
            noTable: "Aucune place assignée",
            title: "Réservations",
            firstOne: "Première réservation",
            details: "Détails de la réservation",
            count: "Aucune réservation | 1 réservation | {count} réservations",
            nb: "Nombre de réservations",
            futureBlocked: "Réservations futures bloquées",
            nbValidated: "Aucune réservation validée | 1 réservation validée | {count} réservations validées",
            honored: "réservations honorées",
            over: "Réservations terminées",
            canceled: "Réservations annulées",
            nbHonored: "Aucune réservation honorée | 1 réservation honorée | {count} réservations honorées",
            sendDetailsToClient: "Envoyer les détails de la réservation au client",
            sendAskConfirmation: "Envoyer une demande de confirmation",
            confirmManually: "Confirmer la réservation manuellement",
            confirmationValidated: "Confirmation validée",
            date: "Date de la réservation",
            hour: "Heure de réservation",
            nbPers: "Nombre de personnes",
            nbChildren: "Nombre d'enfants",
            includingXChildren: "dont 0 enfant | dont 1 enfant | dont {count} enfants",
            noGiftVoucher: "Aucun bon cadeau",
            clientComment: "Commentaire client",
            publicComment: "Commentaire public",
            noPublicComment: "Aucun commentaire",
            privateComment: "Commentaire privé",
            duration: "Durée de la réservation",
            roomNumber: "Numéro de chambre",
            roomNumberShort: "N° chambre",
            roomPreference: "Préférence de salle",
            noFootprintNorPayment: "Cette réservation n'est soumise à aucune demande d'empreinte bancaire ou de prépaiement.",
            web: "Réservation web effectuée le {date}",
            manually: "Réservation manuelle prise le {date} par {user}",
            public_api: "Réservation synchronisée par {provider} le {date}",
            google_reserve: 'Réservation prise par "Réserver avec Google" le {date}',
            fullyPlaced: "La réservation est entièrement placée.",
            selectSlot: "Veuillez sélectionner un créneau",
            saveComment: "Enregistrer le commentaire",
            secure: "Sécuriser la réservation",
            savedBy: "Réservation prise par :",
            viewList: "Liste des réservations",
            defaultMailObj: "Annulation de votre réservation",
            placeEntireBooking: "Placer la totalité des cvts réservés",
            placeSelectedSeats: "Placer le nombre de cvts sélectionnés",
            noClientLinked: "Réservation sans nom",
            remainingPaxes: "Il vous reste {paxes} pax à placer.",
            created: {
                title: "Réservations en attente",
                empty: "Aucune réservation en attente pour cet établissement",
                confirmedPAX: "Capacitée confirmée",
                show: "Voir les réservations en attente",
            },
            export: {
                title: "Exporter des réservations",
                columns: {
                    id: "Identifiant",
                    reservation_date: "Date",
                    slot: "Créneau",
                    pax: "PAX",
                    status: "Statut",
                    room: "Salle",
                    option_bank: "Type de paiement",
                    amount_paid: "Montant disponible",
                    amount_captured: "Montant capturé",
                    payment_status: "Statut du paiement",
                    firstname: "Prénom",
                    lastname: "Nom",
                    email: "Email",
                    phone: "Téléphone",
                    gift: "Bon cadeau",
                    public_comment: "Commentaire",
                    private_comment: "Commentaire de l'établissement",
                    table: "Place(s)",
                    menus_and_options: "Prestations et options",
                    room_number: "Numéro de chambre",
                },
            },
            confirmation: {
                send: "Envoyer une confirmation",
            },
            add: {
                title: "Ajouter une réservation",
                date: "Choix de la date :",
                nbPers: "Nombre d'adultes :",
                nbChildren: "Nombre d'enfants :",
                chooseMenus: "Choix des prestations :",
                chooseGeneralOptions: "Choix des options générales :",
                client: "Informations du client :",
            },
            edit: {
                title: "Modifier la réservation",
                date: "Date",
                nbPers: "Nb d'adultes",
                nbChildren: "Nb d'enfants",
                tableNotAvailable: "Les places sélectionnées ne sont pas disponibles pour la date, le créneau et la durée sélectionnée",
                changeRestaurant: "Affecter à un autre établissement",
                table: "Choix de Place(s)",
                duration: "Durée de la réservation",
            },
            giftVoucher: {
                title: "Bon cadeau",
                empty: "Aucun bon cadeau",
                alreadyUsed: "Ce bon cadeau est déjà utilisé.",
            },
            logs: {
                title: "Historique des modifications",
                empty: "Aucune modification n'a été enregistrée pour cette réservation.",
                client: "Le client",
                systemUpdate: "L'application",
                fromRwg: "(depuis Réserver avec Google)",
                datetime: "le {date} à {hour}",
                updates: {
                    status: "a modifié le statut de la réservation :",
                    reservation_date: "a modifié la date de la réservation :",
                    slot: "a modifié l'heure de la réservation :",
                    nb_pers: "a modifié le nombre de personnes de la réservation :",
                    nb_children: "a modifié le nombre d'enfants de la réservation :",
                    comment: "a modifié le commentaire de la réservation :",
                    client: "a modifié le client associé à la réservation",
                },
            },
            mailLogs: {
                permanent_failed: "Une erreur est survenue lors de l'envoi du mail {type}",
                pending: "Le mail {type} est en cours d'envoi",
                sent: "Mail {type} envoyé le {date} à {hour}",
                sent_confirmed: "Mail {type} délivré",
                opened: "ouvert",
                types: {
                    confirmation: "de confirmation",
                    confirmation_footprint: "de confirmation (information empreinte bancaire)",
                    ask_confirmation: "de demande de confirmation",
                    canceled: "d'annulation",
                    canceled_footprint: "d'annulation (empreinte bancaire non reçue)",
                    canceled_payment: "d'annulation (prépaiement non reçu)",
                    canceled_gift_voucher: "d'annulation (bon cadeau déjà utilisé)",
                    reminder: "de rappel",
                    thanks: "de remerciement",
                    created: "de réservation en attente",
                    refused: "de réservation refusée",
                    ask_footprint: "de demande d'empreinte bancaire",
                    ask_payment: "de demande de prépaiement",
                    noshow_total: "de non-présentation (prélèvement total)",
                    noshow_partial: "de non-présentation (prélèvement partiel)",
                    noshow_free: "de non-présentation (sans prélèvement)",
                },
            },
            status: {
                pending: "Non enregistrée",
                created: "En attente",
                validated: "Validée",
                canceled: "Annulée",
                refused: "Refusée",
                over: "Terminée",
                option: "Option",
                confirmed: "Confirmée",
                noshow: "Non-présentation",
                at_table: "En cours",
                comingSoon: "À venir",
                all: "Tous",
            },
            history: {
                actions: {
                    view: "Voir la réservation",
                },
                columns: {
                    date: "Date",
                    restaurant: "Établissement",
                    slot: "Horaire",
                    status: "Statut",
                    nbPers: "Nombre de personnes",
                    pax: "PAX",
                    nbChildren: "Nombre d'enfants",
                    children: "Enfants",
                    actions: "Actions",
                },
            },
            grid: {
                columns: {
                    client: "Client",
                    company: "Société",
                    pax: "PAX",
                    hour: "Heure",
                    room_number: "Chambre",
                    status: "Statut",
                    optionBankStatus: "CB",
                    actions: "Actions",
                    creationDate: "Date de création",
                    footprintPayment: "Empreinte / Paiement",
                    confirmation: "Confirmation",
                    comments: "Commentaires",
                    commentPrivate: "Commentaire Privé",
                    commentPublic: "Commentaire Public",
                    table: "Place",
                    menusAndOptions: "Prestations et Options",
                },
                optionBanksForFiltering: {
                    noOne: "Aucun",
                    canceledRefunded: "Annulé / Remboursé",
                    expired: "Délai dépassé",
                    pending: "En attente",
                    confirmed: "Confirmé",
                },
                confirmationForFiltering: {
                    notSent: "Non envoyée",
                    pending: "En attente",
                    validated: "Validée",
                },
            },
            actions: {
                accept: "Accepter la réservation",
                acceptFootprint: "Accepter la réservation sous condition d'empreinte bancaire",
                refuse: "Refuser la réservation",
                edit: "Modifier la réservation",
                cancel: "Annuler la réservation",
                sendAskAgain: "Renvoyer la demande",
                sendReminderPayment: "Rappeler le paiement",
                scan: "Scanner une réservation",
                setAtTable: "Valider l'arrivée du client",
                save: "Enregistrer la réservation",
                setNoShow: "Déclarer une non-présentation",
            },
            footprint: {
                title: "Empreinte bancaire",
                released: "L'empreinte bancaire du client a été relâchée.",
                noOneAndResaCanceled:
                    'Aucune empreinte bancaire n\'est actuellement récupérée. Si vous repassez la réservation en "Confirmée", une demande d’empreinte bancaire lui sera renvoyée.',
                notCaptured: "L'empreinte bancaire du client n'a pas été prélevée",
                captured: "L'empreinte bancaire du client a été prélevée à hauteur de {amount}",
                confirmed: "Confirmé | Le client a validé l'empreinte bancaire le {date}",
                pendingNotSent: "En attente | Le client recevra une demande d'empreinte bancaire le {date}",
                pendingSent: "En attente | Le client a jusqu'au {date} à {hour} pour valider l'empreinte bancaire",
                expired: "Délai dépassé | Le client n'a pas validé l'empreinte bancaire dans les temps.",
                expiredAndResaCanceled: "Réservation annulée | Le client n'a pas validé l'empreinte bancaire dans les temps.",
                amount: "Montant de l'empreinte",
                showResa: {
                    title: "Vous avez choisi l'option d'empreinte bancaire pour cette réservation.",
                    noOne: "Aucune empreinte bancaire n'est actuellement récupérée. Si vous repassez la réservation en \"Confirmée\", une demande d'empreinte bancaire lui sera renvoyée.",
                    pending: "Le client n'a pas encore renseigné son empreinte bancaire.",
                    captured: "L'empreinte bancaire du client a été récupérée. Suite à un no-show, il a été débité de {amount}.",
                    over: "Aucune empreinte bancaire n'a été prélevée.",
                    capturable: "L'empreinte bancaire du client a été récupérée. Il sera facturé {amount} par personne en cas de non-présentation.",
                },
            },
            payment: {
                title: "Acompte",
                titleBis: "Prépaiement",
                refundedAndResaCanceled:
                    'Le paiement de {amount} a été remboursé le {date}. Si vous repassez la réservation en "Confirmée", une demande de prépaiement lui sera renvoyée.',
                refunded: "Le paiement de {total_amount} a été remboursé à hauteur de {refunded_amount} le {date}.",
                noOneAndResaCanceled:
                    'Aucun prépaiement n\'est actuellement récupéré. Si vous repassez la réservation en "Confirmée", une demande de prépaiement lui sera renvoyée.',
                notYetCaptured:
                    "En attente | Le client a effectué un paiement de {amount} le {date}.<br>Confirmez la réservation pour prélever le client.",
                captured: "Confirmé | Le client a effectué un paiement de {amount} le {date}.",
                pending: "Le client a jusqu'au {date} à {hour} pour procéder au paiement.",
                expired: "Délai dépassé | Le client n'a pas validé le paiement dans les temps.",
                expiredAndResaCanceled: "Réservation annulée | Le client n'a pas validé le paiement dans les temps.",
                amountToPay: "Montant à payer :",
                amountToCapture: "Montant à prélever",
                showResa: {
                    title: "Vous avez choisi l'option de prépaiement pour cette réservation.",
                    captured: "Le client a été prélevé de {amount}.",
                    capturable: "Le client a déjà réglé {amount}. Confirmez la réservation pour prélever le client.",
                    refunded:
                        'Le paiement de {amount} a été remboursé le {date}. Si vous repassez la réservation en "Confirmée", une demande de prépaiement lui sera renvoyée.',
                    refundedPartially: "Le paiement de {total_amount} a été remboursé à hauteur de {amount_refunded} le {date}.",
                    canceled:
                        'Aucun prépaiement n\'est actuellement récupéré. Si vous repassez la réservation en "Confirmée", une demande de prépaiement lui sera renvoyée.',
                    pending: "Il reste {amount} à régler au client.",
                    expired: "Le client n'a pas effectué le paiement dans les temps.",
                },
            },
            tags: {
                gluten: "Intolérant au gluten",
                lactose: "Intolérant au lactose",
                vegetarien: "Végétarien",
                vegetalien: "Végétalien",
                pmr: "PMR",
                chaise: "Chaise haute",
                poussette: "Poussette",
                anniversaire: "Anniversaire",
                allergie: "Aucune allergie",
            },
            reservationNotMatch: "La réservation ne correspond pas au service",
            dateAndHour: "Date et heure",
            canceledModal: {
                title: "Annulation de la réservation",
                ask: "Êtes-vous sûr de vouloir annuler la réservation ?",
                sendEmail: "Envoyer un email d'annulation",
            },
            refusedModal: {
                title: "Refus de la réservation",
                ask: "Êtes-vous sûr de vouloir refuser la réservation ?",
                sendEmail: "Envoyer un email de refus",
            },
            passingCustomer: {
                title: "Client de passage",
                titleShort: "Client passage",
                showCount: "Aucun client de passage | 1 client de passage | {count} clients de passage",
                dropdown: "Client de passage",
                duration: "Durée",
                add: {
                    title: "Ajouter un client de passage",
                    btn: "Client de passage",
                },
            },
        },
        pax: {
            nb: "Nombre de personnes",
        },
        stats: {
            maxPeriod: "les statistiques ne concernent que l'année dernière | les statistiques ne concernent que les {count} dernières années",
            periodOne: "Période 1 :",
            periodTwo: "Période 2 :",
            groupOne: "Groupe 1 :",
            groupTwo: "Groupe 2 :",
            noDataOnPeriod: "Aucune donnée sur cette période",
            reservations: {
                title: "Nombre de réservations",
                web: "Réservations Web",
                manual: "Réservations Manuelles",
                rwg: "Réservations Google",
                total: "Réservations Totales",
                same_day: "Jour même",
                last_week: "Semaine précédente",
                last_2_weeks: "15 derniers jours",
                last_month: "Mois dernier",
                other: "Plus d'un mois",
                next_week: "Semaine suivante",
                next_2_weeks: "15 jours suivants",
                next_month: "Mois suivant",
                day: "J",
            },
            pax: {
                title: "Nombre de personnes",
                web: "PAX réservations Web",
                manual: "PAX réservations Manuelles",
                rwg: "PAX réservations Google",
                total: "PAX réservations Totales",
            },
            waitingList: {
                title: "Liste d'attente",
                nb: "Nombre d'inscrits",
                nbLeadToResa: "Nombre d'inscrits ayant donné lieu à une réservation",
            },
            other: {
                title: "Autres informations",
                percentNoShow: "Pourcentage de noshows",
                percentNewClients: "Pourcentage de nouveaux clients",
                confirmationMailLeadToConfirmedResa: "Réservations confirmées suite à l'envoi d'un mail de confirmation",
                fillRate: "Taux de remplissage moyen",
            },
            charts: {
                percentResaPerPax: {
                    title: "Pourcentage de réservations en fonction du PAX",
                    x: "PAX",
                    y: "Réservations",
                    axisLabel: "et plus",
                },
                resaEvolution: {
                    title: "Évolution des réservations",
                    x: "Date",
                    y: "Nombre de réservations",
                },
                CAPerMenu: {
                    title: "CA réalisé par type de prestation",
                    x: "CA",
                    y: "Prestation",
                    tooltip: "Aucune prestation vendue | 1 prestation vendue | {count} prestations vendues",
                    filters: {
                        onlySold: "Afficher uniquement les prestations vendues",
                        all: "Toutes",
                    },
                },
                CAPerMenuOption: {
                    title: "CA réalisé par type d'option de prestation",
                    x: "CA",
                    y: "Option",
                    tooltip: "Aucune option vendue | 1 option vendue | {count} options vendues",
                    filters: {
                        onlySold: "Afficher uniquement les options vendues",
                        all: "Toutes",
                    },
                },
                CAPerGeneralOption: {
                    title: "CA réalisé par type d'option générale",
                    x: "CA",
                    y: "Option",
                    tooltip: "Aucune option vendue | 1 option vendue | {count} options vendues",
                    filters: {
                        onlySold: "Afficher uniquement les options vendues",
                        all: "Toutes",
                    },
                },
                CAPerEntity: {
                    filters: {
                        titleFilters: "Appliquer des filtres",
                        topSales: "Top des ventes",
                        top3: "Top 3",
                        top10: "Top 10",
                    },
                },
                percentMenusSold: {
                    title: "Pourcentage de prestations vendues",
                },
                percentMenuOptionsSold: {
                    title: "Pourcentage d'options vendues",
                },
                percentGeneralOptionsSold: {
                    title: "Pourcentage d'options vendues",
                },
                tooltip: {
                    so: "soit :",
                    reservations: "Aucune réservation | 1 réservation | {count} réservations",
                },
                bookingsPeriod: {
                    title: "Les clients venus sur la période ont réservé",
                },
                clientLoyal: {
                    title: "Niveau de fidélité client",
                },
                futureBooking: {
                    title: "Les clients ayant réservés sur la période viendront",
                },
            },
            filters: {
                serviceCategory: "Catégorie de plage horaire",
                allServiceCategories: "Toutes",
            },
            originOfBookings: {
                title: "Origine des réservations",
            },
            bookingsDelay: {
                title: "Délais de réservations",
            },
        },
        globalStats: {
            filters: {
                allEstablishments: "Tous les établissements",
                placeholder: "Sélectionnez les établissements",
                selectedEstablishments: "Sélectionné",
                deselectedEstablishments: "Désélectionné",
            },
            table: {
                headers: {
                    web: "Web",
                    manual: "Manuelles",
                    rwg: "Google",
                    total: "Totales",
                },
                cells: {
                    nameRestaurant: "Nom de l'établissement",
                    periodOne: "P1",
                    periodTwo: "P2",
                    periodOneOnTwo: "P1/P2",
                    varNb: "Var Nbre",
                    varPercent: "Var %",
                    percentBooking: "Pourcentage de reservation",
                },
            },
            reservationCountPerOrigin: {
                title: "Détails par établissements | Nombre de réservations",
            },
            reservationPaxPerOrigin: {
                title: "Détails par établissements | Nombre de personnes",
            },
            waitingsList: {
                title: "Détails par établissements | Liste d'attente",
            },
        },
        widget: {
            useDefaultConfig: "Utiliser la configuration par défaut du widget",
            hotelMode: "Mode Hôtel",
            display: {
                title: "Paramètres d'affichage",
                seatingPlanMemos: "Afficher les mémos sur le plan de salle",
                defaultBookingView: "Vue des réservations par défaut",
            },
            gTag: {
                title: "Google Tag Manager",
                id: "Renseigner l'id (ex: GTM-XXXXXXXX)",
            },
            messages: {
                title: "Informations à afficher sur le module de réservation",
                general: "Message d'information général",
                slot: "Message concernant le choix du créneau",
            },
            resaSettings: {
                title: "Caractéristiques de réservation du widget",
                nbPersMin: "Nombre minimum de personnes par réservation",
                nbPersMax: "Nombre maximum de personnes par réservation",
                enableChildren: 'Afficher le champ "Nombre d\'enfants"',
                maxPerResa: "max par réservation",
                openingPeriod: "Période d'ouverture des réservations",
            },
            roomNumbers: {
                title: "Numéros de chambre",
                edit: "Modifier la liste des numéros de chambre",
                show: "Voir la liste des numéros de chambre",
            },
            resaOptions: {
                title: "Options de réservation",
                enableCompany: 'Activer le champ "Société"',
                enableComment: 'Activer le champ "Commentaire"',
                addCommentPlaceHolder: "Afficher une indication au client dans le champ commentaire",
                setCommentMandatory: "Rendre la saisie du commentaire obligatoire",
                enableGiftVoucher: 'Activer le champ "Bon cadeau"',
                confirmResaGiftVoucher: "Confirmation des réservations avec bon cadeau",
                disableFootprintIfGiftVoucher: "Désactiver la demande d'empreinte bancaire si un bon cadeau est renseigné",
            },
            waitingList: {
                title: "Liste d'attente",
                enable: "Activer la liste d'attente",
                autoNotifTableAvailable: "Activer les notifications automatiques lorsqu'une place se libère",
                priorityClientVIP: "Priorité aux clients VIP",
                priorityClientLoyal: "Priorité aux clients fidèles",
            },
            automaticExport: {
                title: "Export automatique de la liste des réservations par mail",
                activate: "Activer l'export automatique",
                desactivate: "Désactiver l'export automatique",
                nbMinutes: "Planifier l'envoi avant la plage horaire (en minutes)",
                services: "Choisir les plages horaires concernées",
                emails: "Liste d'emails (max: 5)",
            },
            footprintAndPayment: {
                title: "Réception Empreinte bancaire / Prépaiement",
                optionBankShortDelay: 'Réservation de moins de 48h : Intervalle de temps minimum de : "x" heure(s) avant heure d\'arrivée',
            },
        },
        interfaces: {
            addInterface: "Ajouter une connexion api",
            name: "Nom",
            clientId: "Client ID",
            auth: "Type d'authentification",
            provider: "Fournisseur",
            restaurantIdTitle: "Identifiants de l'établissement",
            restaurantId: "ID",
            restaurantSecret: "Secret",
            confirmConsented: "Êtes-vous sûr de vouloir accorder l'autorisation à {name} ?",
            confirmNotConsented: "Êtes-vous sûr de vouloir révoquer l'accès à vos données à {name} ?",
            ownerPermissions: "Autoriser {name} à accéder à vos données clients et de réservations.",
            ownerPermissionsTitle: "Autorisation {name}",
        },
        customEvents: {
            title: "Événements",
            titleForDay: "Événements du jour",
            add: "Ajouter un événement",
            edit: "Éditer un événement",
            priority: "Importance",
            visibility: "Visibilité",
            priorities: {
                high: "Important",
                normal: "Normal",
                low: "Faible",
            },
            visibilities: {
                internal: "Interne",
                external: "Externe (visible depuis le widget)",
            },
        },
        waitingList: {
            title: "Liste d'attente",
            emptyList: "Aucun résultat",
            columns: {
                client: "Client",
                hour: "Heure",
                dateSendingMail: "Date d'envoi du mail",
                phone: "Téléphone",
                pax: "PAX",
                adults: "Adultes",
                children: "Enfants",
            },
            content: {
                menus: "Prestations :",
                generalOptions: "Options générales :",
            },
            buttons: {
                remindCustomerWaitingList: "Relancer les clients sur liste d'attente",
                sendAvailabilityMail: "Envoyer le mail de disponibilité",
                addToReservationList: "Ajouter à la liste des réservations",
                deleteToWaitingList: "Supprimer de la liste des réservations",
                seeMenusAndOptions: "Voir les options et les prestations",
                remindAllCustomerOnWaitList: "Relancer l'ensemble des clients sur liste d’attente",
                remindOnlyCustomerNoMail: "Relancer uniquement pour les clients n'ayant pas encore reçu de mail",
            },
            mailsStatus: {
                fail: "Échoué",
                inProgress: "En cours",
                open: "(ouvert)",
            },
        },
    },
    clickAndCollect: {
        config: {
            nbOrderMax: "Nombre de commandes maximum par plage horaires",
            pendingOrder: "Suspendre les commandes pendant la plage horaire",
            orderByServices: "commande(s) par plage horaire",
            beforeStarting: "minute(s) avant le début de la plage horaire",
        },
        waitingOrders: {
            noWaitingOrders: "Aucune commande en attente pour cet établissement",
            title: "Commandes en attente",
        },
    },
    giftVouchers: {
        products: {
            service: "Plage horaire",
        },
    },
    langs: {
        fr: "Français",
        en: "Anglais",
    },
    days: {
        monday: "lundi",
        tuesday: "mardi",
        wednesday: "mercredi",
        thursday: "jeudi",
        friday: "vendredi",
        saturday: "samedi",
        sunday: "dimanche",
    },
    admin: {
        videos: {
            title: "Vidéos de formation",
            show: "Voir la vidéo",
            add: "Ajouter une vidéo",
            edit: "Modifier une vidéo",
            empty: "Aucune vidéo",
            youtubeVideoId: "Identifiant de la vidéo",
            preview: {
                show: "Voir l'aperçu",
                hide: "Cacher l'aperçu",
            },
            columns: {
                title: "Titre",
                description: "Description",
                link: "Vidéo",
                module: "Module",
                createdAt: "Ajoutée le",
                updatedAt: "Modifiée le",
            },
        },
    },
    videos: {
        emptyForModule: "Aucune vidéo n'est disponible pour ce module",
        empty: "Aucune vidéo n'est actuellement disponible",
        show: "Voir la vidéo",
        hide: "Cacher la vidéo",
        addedOn: "Ajoutée le {date}",
    },
    lexicons: {
        lexicon: "Lexique",
        restaurant: "Restaurant",
        generic: "Générique",
        escape_game: "Escape game",
    },
    appUpdates: {
        list: "Notes de mise à jour",
        title: "Mise à jour du {date}",
        lastUpdate: "Dernière mise à jour : {date}",
        noOnePublished: "Aucune note de mise à jour n'a encore été publiée",
    },
    googleAnalytics: {
        metrics: {
            totalUsers: "Utilisateurs",
            sessions: "Sessions",
            averageSessionDuration: "Durée des sessions",
            screenPageViewsPerSession: "Pages vues",
        },
    },
    googleReserve: {
        title: "Réserver avec Google",
        propagation: "L'actualisation de la configuration peut mettre jusqu'à 72h avant de prendre effet.",
        maxPax: 'Important : Il n\'est pas possible de réserver pour plus de {pax} personnes depuis "Réserver avec Google".',
        setup: 'Mise en place de "Réserver avec Google"',
        widget: {
            enable: "Proposer cet établissement à la réservation sur Google",
        },
        service: {
            enable: "Proposer ce service à la réservation sur Google",
            enableShort: "Proposer à la réservation",
        },
        texts: {
            title: "Paramétrage des textes",
            important_note: "Remarque importante",
        },
        contract: {
            actions: {
                optIn: {
                    btn: "Accepter les Conditions Particulières",
                },
                optOut: {
                    btn: 'Désactiver "Réserver avec Google"',
                },
            },
            title: "Conditions Particulières",
            terms: "Conditions Particulières - Réserver avec Google",
            active: "Acceptées le {date}",
        },
    },
    areasEstablishments: {
        nbEstablishments: "1 établissement | {count} établissements",
        title: "Groupes d'établissements",
        noAreas: "Aucun groupe d'établissements",
        createArea: "Créer un groupe",
        deleteArea: "Supprimer le groupe",
        save: "Enregistrer",
        createAreaTitle: "Création d'un groupe d'établissement",
        areaName: "Nom du groupe",
        create: "Créer",
        editAreaName: "Éditer le nom du groupe",
        validateModification: "Valider la modification",
        cancelModification: "Annuler la modification",
    },
};
