<template>
    <div class="h-100 m-0 p-0 d-flex flex-column table-plan" :class="{ 'overflow-hidden': seatingPlanPrint.isPrinting }" style="min-height: 0">
        <ul class="nav nav-tabs nav-service" style="display: -webkit-box; margin-top: -10px">
            <template v-for="(service, index) in filteredServices">
                <li class="nav-item" v-if="isServiceDisplayable(service)" :key="index" @click="selectedServiceIndex = index">
                    <a class="nav-link" :class="{ active: index === selectedServiceIndex }" href="#">
                        {{ getServiceCategoryLabel(service)
                        }}{{ service.special === 1 ? ` - (${$tl("labels.booking.services.special.title")})` : "" }}
                        <feather
                            type="info"
                            class="text-danger"
                            v-if="hasResaNotPlaced(service)"
                            v-tooltip="
                                getTooltip(
                                    $t('labels.booking.reservations.futureBlocked') + '. ' + $t('infos.booking.reservations.placeOnSeatingPlan')
                                )
                            " />
                    </a>
                </li>
            </template>
        </ul>
        <loaderComponent v-if="isLoading || seatingPlanPrint.isPrinting" />
        <template v-else>
            <no-opened-services v-if="allServicesClosedAndNoResa" is-from-closure :closure-name="closureName" />
            <NoSeatingPlan
                v-else-if="seatingPlan === null"
                class="mt-3"
                :service="selectedService ? selectedService : {}"
                @close="$emit('go-to-resa-list')" />
            <div class="row m-0" v-else>
                <span v-tooltip="getTooltip($t('infos.booking.seatingPlan.tips'))" class="text-warning text-tooltip">
                    <feather class="mr-1" type="info" />{{ $tl("labels.importantInfo") }}
                </span>
                <span class="capacite badge badge-secondary count-resa">
                    {{ selectedService.pax }} /
                    {{ selectedService.new_pax !== null ? selectedService.new_pax : selectedService.max_pax }}
                </span>
                <div class="align-items-center align-middle bg-light col-xl-3 col-lg-4 col-6 pt-0 pl-0 pr-0">
                    <button
                        v-if="reservationsLength > 0"
                        v-tooltip="getTooltip($t('labels.booking.seatingPlan.launchAlgo'))"
                        style="height: 40px"
                        class="btn btn-sm btn-outline-secondary btn-block"
                        type="button"
                        :disabled="!has_right_to_update_reservation"
                        @click="launchAutoPlacing">
                        <feather type="refresh-cw" /> {{ $tl("labels.booking.seatingPlan.launchAlgoShort") }}
                    </button>
                </div>
                <div class="col-xl-9 col-lg-8 col-6 p-0 bg-light">
                    <seating-plan-topbar
                        v-if="openRooms.length > 0"
                        :rooms="konva.rooms"
                        :currentRoom="currentRoom"
                        :readOnly="true"
                        :selectedTables="switchTable.isSwitching ? [] : selectedTables"
                        :can-add-passing-customer="isSelectedServiceALiveService"
                        @add-reservation-on-tables="
                            $emit('add-reservation', {
                                reservation_date: date,
                                tablesId: konva.selectedTablesId.map((id) => Number.parseInt(id)),
                            })
                        "
                        @add-passing-customer-on-tables="
                            $emit('add-passing-customer', {
                                selectedDate: date,
                                serviceId: selectedService.id,
                                tablesId: konva.selectedTablesId.map((id) => Number.parseInt(id)),
                            })
                        "
                        @enable-selected-table="enableSelectedTable"
                        @disable-selected-table="disableSelectedTable"
                        @room-selected="konva.selectedRoom = $event"
                        @change-room-enabled="changeRoomEnabled" />
                </div>
            </div>
        </template>
        <div
            v-if="filteredServices.length > 0"
            class="row m-0 h-100 d-flex flex-row"
            :class="{ invisible: seatingPlanPrint.isPrinting }"
            style="min-height: 0"
            :style="isLoading || seatingPlan === null ? 'display: none !important' : ''">
            <div
                class="p-0 bg-light d-flex flex-column"
                :class="isReservationListCollapsed ? 'w-auto' : 'col-xl-3 col-lg-4 col-6 flex-grow-1'"
                style="min-height: 0"
                :style="isReservationListCollapsed ? 'max-width: 6rem;' : undefined">
                <div class="row m-0 p-1 bg-light">
                    <div class="col-12 plan-tools p-0">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <button
                                    v-if="reservationsLength > 0"
                                    class="btn mr-2"
                                    @click="toggleSwitchTable"
                                    v-tooltip="getTooltip($t('labels.booking.seatingPlan.switchTables.title'))"
                                    :class="{ active: switchTable.isSwitching }">
                                    <feather type="repeat" />
                                </button>
                            </div>
                            <feather
                                :type="isReservationListCollapsed ? 'chevrons-right' : 'chevrons-left'"
                                class="pointer"
                                @click="isReservationListCollapsed = !isReservationListCollapsed"
                                v-tooltip="
                                    getTooltip($t(`labels.booking.seatingPlan.reservationList.${isReservationListCollapsed ? 'show' : 'hide'}`))
                                " />
                        </div>
                        <template v-if="switchTable.isSwitching">
                            <span class="text-warning">{{ $tl("labels.booking.seatingPlan.switchTables.select") }}</span>
                        </template>
                    </div>
                </div>
                <div class="container-status" :class="{ 'd-none': isReservationListCollapsed }">
                    <div
                        v-for="status in statuses"
                        :key="status.value"
                        class="item-status"
                        :class="{ active: status.active }"
                        @click="changeActive(status)">
                        <ustensils-icon v-if="status.value === RESERVATION_STATUS_AT_TABLE.value" />
                        <feather v-else-if="status.featherIcon" :type="status.featherIcon" />
                        <span> {{ $tl(`labels.booking.reservations.status.${status.value}`) }} </span>
                        <span> ({{ getReservationsPax(status) }}) </span>
                    </div>
                </div>
                <div class="row m-0 pb-2" style="overflow: auto; height: 620px" :class="{ 'd-none': isReservationListCollapsed }">
                    <ul class="col-12 list-group h-100 pr-0" :class="{ 'mouse-grab': has_right_to_update_reservation }">
                        <template v-if="reservationsByStatus.length > 0">
                            <div v-for="slot in activeSlots" :key="slot.id" class="slot-container">
                                <template v-if="getReservationsForSlot(slot).length > 0">
                                    <div class="slot-item">
                                        <span>
                                            {{ slot.hour_start }}
                                        </span>
                                        <div class="slot-item-icon-container">
                                            <ustensils-icon fill="#ffffff" class="mr-1" />
                                            <span>
                                                {{ getPaxReservationsForSlot(slot) }}
                                            </span>
                                            <calendar-icon fill="#ffffff" class="ml-2 mr-1" />
                                            <span>
                                                {{ getReservationsForSlot(slot).length }}
                                            </span>
                                            <feather :type="getChevron(slot.id)" class="pointer ml-3" @click="toggleReservations(slot.id)" />
                                        </div>
                                    </div>
                                    <template v-if="!canHideReservations[slot.id]">
                                        <li
                                            v-for="(reservation, index) in getReservationsForSlot(slot)"
                                            class="list-group-item resa-item p-1"
                                            :key="index"
                                            :class="{ 'mb-2': index === reservationsLength - 1 }"
                                            :draggable="has_right_to_update_reservation"
                                            @dragstart="dragstartReservation($event, reservation.originalIndex)"
                                            @dragend="dragendReservation($event)"
                                            @dragenter="$event.preventDefault()"
                                            @dblclick.prevent.stop="showReservation(reservation)">
                                            <div class="row m-0">
                                                <div class="col-12 pl-0 pr-1">
                                                    <div class="d-flex justify-content-between w-100">
                                                        <booking-status
                                                            :key="reservation.id + reservation.status"
                                                            :reservation="reservation"
                                                            use-icon
                                                            @displayRefundPartiallyModal="displayRefundPartiallyModal(reservation.id)"
                                                            @displayNoshowModal="displayNoshowModal(reservation)"
                                                            @displayCancelModal="displayCancelModal({ newStatus: $event, data: reservation })" />
                                                        <div class="text-truncate ml-2" style="flex-grow: 1">
                                                            <div v-if="reservation.is_passing_customer">
                                                                {{ $tl("labels.booking.reservations.passingCustomer.title") }}
                                                            </div>
                                                            <div
                                                                v-else-if="
                                                                    !reservation.client ||
                                                                    (!reservation.client.firstname && !reservation.client.lastname)
                                                                ">
                                                                {{ $tl("labels.clients.unknown") }}
                                                            </div>
                                                            <inline-client v-else :client="reservation.client" :module-enum="MODULE_TYPE_BOOKING" />

                                                            <div
                                                                v-if="![null, ''].includes(reservation.comment)"
                                                                class="text-truncate"
                                                                v-tooltip="getTooltip(reservation.comment)">
                                                                <feather type="message-circle" class="feather-blue" />
                                                                {{ reservation.comment }}
                                                            </div>

                                                            <div
                                                                v-if="![null, ''].includes(reservation.restaurant_comment)"
                                                                class="text-truncate"
                                                                v-tooltip="getTooltip(reservation.restaurant_comment)">
                                                                <feather type="message-circle" class="feather-orange" />
                                                                {{ reservation.restaurant_comment }}
                                                            </div>

                                                            <manage-created-booking
                                                                v-if="reservation.status === RESERVATION_STATUS_CREATED.value"
                                                                :editable="has_right_to_update_reservation"
                                                                :reservation="reservation"
                                                                :restaurant-id="restaurant.id"
                                                                :is-payment-enabled="isPaymentEnabled"
                                                                @displayCancelModal="displayCancelModal({ newStatus: $event, data: reservation })"
                                                                class="reservation-tag small d-block" />

                                                            <div class="mt-1 tags has-addons float-left mb-0">
                                                                <span
                                                                    v-if="reservation.ignore_placement && !reservation.is_passing_customer"
                                                                    class="badge badge-danger text-uppercase"
                                                                    >{{ $tl("labels.booking.services.surbooking") }}</span
                                                                >
                                                                <template v-else>
                                                                    <span
                                                                        class="tag is-rounded mb-0"
                                                                        v-for="(table, index) in reservationTables(reservation)"
                                                                        :style="getBackgroundColor(reservation)"
                                                                        :key="index">
                                                                        {{ table.name }}
                                                                        <button
                                                                            class="delete"
                                                                            v-if="has_right_to_update_reservation"
                                                                            @dblclick.prevent.stop
                                                                            @click.stop="unlinkResaManually(table, reservation)"></button>
                                                                    </span>
                                                                </template>
                                                            </div>
                                                            <span
                                                                v-if="reservation.tables && reservation.tables.data.length > 0"
                                                                class="d-inline-block mt-2 ml-1">
                                                                {{ getReservationRoom(reservation) }}
                                                            </span>
                                                        </div>
                                                        <div class="text-right border-left-light" style="min-width: 64px">
                                                            <div>
                                                                {{ $tl("labels.pax").toUpperCase() }} :
                                                                {{ Number.parseInt(reservation.nb_pers) + Number.parseInt(reservation.nb_children) }}
                                                            </div>
                                                            <small
                                                                v-if="getReservationPaxLeft(reservation) > 0"
                                                                class="text-danger"
                                                                style="display: block; margin-top: -3px; margin-bottom: -3px">
                                                                {{ $t("labels.remainingNb", { nb: getReservationPaxLeft(reservation) }) }}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </template>
                                </template>
                            </div>
                            <small class="mb-3 pb-3">{{ $tl("labels.booking.seatingPlan.dlbClickShowResa") }}</small>
                        </template>
                        <span v-else-if="selectedService" class="d-block text-center pt-2 pr-4 pb-4 pl-4" style="font-size: 0.7rem">
                            {{
                                $tl("labels.booking.seatingPlan.emptyForService", restaurant.id, {
                                    service: selectedService.name,
                                    status: $tl(`labels.booking.reservations.status.${selectedStatus}`),
                                })
                            }}
                        </span>
                    </ul>
                </div>
            </div>
            <div class="h-100 m-0 p-0 flex-grow-1" :class="isReservationListCollapsed ? 'w-auto' : 'col-xl-9 col-lg-8 col-6'">
                <div class="row h-100 m-0 p-0 overflow-auto">
                    <div
                        class="h-100 p-0 m-0 position-relative"
                        :class="isGroupsAndPrintTabCollapsed ? 'col-12' : 'col-xl-10 col-lg-12'"
                        @contextmenu.prevent>
                        <div class="h-100 p-0 m-0" ref="seatingPlanCanvas" id="seating-plan-canvas"></div>
                    </div>

                    <seating-plan-tree-view
                        class="d-none h-100 d-xl-block"
                        :class="isGroupsAndPrintTabCollapsed ? 'w-auto' : 'col-xl-2'"
                        :currentRoom="currentRoomFormatted"
                        @droped-on-group="dropedOnGroup($event)"
                        @unhighlight="unhighlight"
                        @print="printSelectedRooms"
                        :rooms="konva.rooms"
                        @highlight-group="highlightGroup($event)"
                        @resized="onResize"
                        :disablePointerEvents="draggedReservationIndex !== null"
                        can-collapse
                        :showTables="false" />

                    <ListMemos
                        v-if="canShowServiceNotes"
                        :restaurantId="restaurantId"
                        :serviceId="filteredServices[selectedServiceIndex].id"
                        :date="date" />
                </div>
            </div>
        </div>
        <show-reservation-modal
            v-if="showReservationModal"
            :reservation_id="selected_reservation_id"
            :restaurant_id="selected_restaurant_id"
            @resa-edit="editReservationFromShowModal"
            @close="showReservationModal = false" />
        <edit-reservation-modal
            v-if="showEditReservationModal"
            :restaurant_id="selected_restaurant_id"
            :reservation_id="selected_reservation_id"
            @close="showEditReservationModal = false"
            @reservation-edited="reservationEdited" />
        <error-modal v-if="showErrorModal" :message="modalErrorMessage" @close="showErrorModal = false" />
        <pax-selection-modal
            v-if="showPaxSelectionModal"
            :tables="paxesSelection.tables"
            :dropedOnType="paxesSelection.dropedOnType"
            :dropRelatedObject="paxesSelection.dropRelatedObject"
            :selectedReservation="draggedReservation"
            :current-room-index="konva.selectedRoom"
            @close="abortPaxesSelection"
            @place-all="placeAll"
            @paxes-selected="paxesSelected" />
        <show-reservation-list-modal
            v-if="showReservationListModal"
            :emit_resa_chosen="switchTable.isSwitching"
            @resa-chosen="resaChosenForSwitch"
            @reservation-edited="reservationEditedFromList"
            @close="switchTable.isSwitching ? resaChosenForSwitch(false) : (showReservationListModal = false)"
            :reservations_list="selected_reservations_list"
            :restaurant_id="selected_restaurant_id" />
        <cancel-paid-reservation-modal
            v-if="showRefundPartiallyModal"
            :reservation_id="selected_reservation_id"
            @close="showRefundPartiallyModal = false" />
        <cancel-reservation-modal
            v-if="showCancelModal"
            :reservation-id="selected_reservation_id"
            :client="selected_reservation_client"
            :status="newCancelStatus"
            @close="showCancelModal = false" />
        <set-noshow-modal
            v-if="showSetNoshowModal"
            :restaurant_id="selected_restaurant_id"
            :reservation="selected_reservation"
            @close="showSetNoshowModal = false" />
    </div>
</template>

<script>
import { DateTime } from "luxon";
import SeatingPlanTopbar from "./SeatingPlanTopbar.vue";
import LoaderComponent from "../LoaderComponent";
import ShowReservationModal from "../Modals/reservations/ShowReservationModal";
import EditReservationModal from "../Modals/reservations/EditReservationModal";
import ShowReservationListModal from "../Modals/reservations/ShowReservationListModal.vue";
import ErrorModal from "../Modals/messages/ErrorModal.vue";
import NoSeatingPlan from "../seatingPlan/NoSeatingPlan.vue";
import SeatingPlanTreeView from "./SeatingPlanTreeView.vue";
import PaxSelectionModal from "../Modals/seatingPlan/paxSelectionModal.vue";
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum";
import SeatingPlanColorTypeEnum from "../../mixins/enums/booking/SeatingPlanColorTypeEnum";
import SeatingPlanTableTypesEnum from "../../mixins/enums/booking/SeatingPlanTableTypesEnum";
import ElementsReference from "../../mixins/helpers/booking/seating_plan/ElementsReference";
import SeatingPlanPrint from "../../mixins/helpers/booking/seating_plan/Print";
import LangsEnum from "../../mixins/enums/LangsEnum";
import ClientCivilityEnum from "../../mixins/enums/ClientCivilityEnum";
import InlineClient from "../Default/Clients/inlineClient.vue";
import ListMemos from "./memos/ListMemos.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";
import CancelReservationModal from "../Modals/reservations/CancelReservationModal.vue";
import BookingStatus from "../reservations/BookingStatus.vue";
import UstensilsIcon from "../icons/UstensilsIcon.vue";
import CalendarIcon from "../icons/CalendarIcon.vue";
import ManageCreatedBooking from "../reservations/ManageCreatedBooking.vue";
import CancelPaidReservationModal from "../Modals/reservations/CancelPaidReservationModal.vue";
import SetNoshowModal from "../Modals/reservations/SetNoshowModal.vue";
import ServicesUtils from "../../mixins/helpers/booking/ServicesUtils.js";
import axios from "axios";
import NoOpenedServices from "../Booking/NoOpenedServices.vue";

export default {
    name: "SeatingPlanPerService",
    components: {
        SeatingPlanTopbar,
        LoaderComponent,
        ShowReservationModal,
        ShowReservationListModal,
        EditReservationModal,
        ErrorModal,
        NoSeatingPlan,
        SeatingPlanTreeView,
        PaxSelectionModal,
        InlineClient,
        ListMemos,
        CancelReservationModal,
        CalendarIcon,
        UstensilsIcon,
        BookingStatus,
        ManageCreatedBooking,
        CancelPaidReservationModal,
        SetNoshowModal,
        NoOpenedServices,
    },
    mixins: [
        SeatingPlanPrint,
        ReservationStatusEnum,
        SeatingPlanColorTypeEnum,
        ElementsReference,
        SeatingPlanTableTypesEnum,
        LangsEnum,
        ClientCivilityEnum,
        ModuleTypesEnum,
        ServicesUtils,
    ],
    props: {
        services: {
            required: true,
            type: Array,
        },
        restaurant: {
            required: true,
            type: Object,
        },
        date: {
            type: DateTime,
            required: true,
        },
        addDataKeyToReservationsList: {
            type: Boolean,
            default: false,
        },
        reservationAdded: {
            type: Object,
            default: () => ({}),
        },
        reservationUpdated: {
            type: Object,
            default: () => ({}),
        },
        reservationDeleted: {
            type: Object,
            default: () => ({}),
        },
        allServicesClosedAndNoResa: {
            type: Boolean,
            default: false,
        },
        closureName: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            isLoading: true,
            switchTable: {
                isSwitching: false,
                resa1: {
                    id: null,
                    tableId: null,
                },
                resa2: {
                    id: null,
                    tableId: null,
                },
            },
            selectedServiceIndex: 0,
            isTableRotationEnabled: false,
            seatingPlan: null,
            konva: {
                containerListenersSet: false,
                mainStage: null,
                rooms: [],
                unlinkButtonlink: "/images/x-circle.svg",
                selectedRoom: -1,
                snapping: {
                    offset: 15,
                },
                style: {
                    mainColor: "#ccc",
                    selectedColor: this.$style["main-color-light"],
                    disabledColor: this.$style["btn-danger"],
                    busyColor: this.$style["btn-warning"],
                },
                highlightedTables: [],
                selectedTablesId: [],
            },
            reservationGettingPlaced: {
                reservationIndex: null,
                timer: {
                    baseDuration: 180,
                    duration: 180,
                    interval: null,
                },
            },
            showPaxSelectionModal: false,
            paxesSelection: {
                tables: [],
                dropedOnType: "",
                dropRelatedObject: null,
            },
            draggedReservationIndex: null,
            isDroping: false,
            showReservationModal: false,
            showReservationListModal: false,
            showEditReservationModal: false,
            selected_restaurant_id: null,
            selected_reservation_id: null,
            selected_reservation_client: null,
            selected_reservation: null,
            selected_reservations_list: null,
            showErrorModal: false,
            modalErrorMessage: "",
            socket: {
                listeningChannels: [],
            },
            selectedService: null,
            previousServiceAbortController: null,
            previousSeatingPlanAbortController: null,
            restaurantHasASeatingPlan: false,
            touchLastCenter: null,
            touchLastDist: 0,
            newCancelStatus: "canceled",
            showCancelModal: false,
            showRefundPartiallyModal: false,
            showSetNoshowModal: false,
            selectedStatus: "all",
            statuses: [
                {
                    value: "all",
                    active: true,
                },
                {
                    value: "created",
                    featherIcon: "pause",
                    active: false,
                },
                {
                    value: "comingSoon",
                    featherIcon: "clock",
                    active: false,
                },
                {
                    value: "at_table",
                    active: false,
                },
                {
                    value: "over",
                    featherIcon: "check",
                    active: false,
                },
            ],
            canHideReservations: {},
        };
    },
    computed: {
        isSelectedServiceALiveService() {
            if (!this.selectedService) {
                return false;
            }

            return this.isServiceLive(this.selectedService, this.date);
        },
        restaurantId() {
            return this.$route.params.restaurant_id;
        },
        widget() {
            return this.$store.getters["widgets/getWidget"];
        },
        canDisplayMemos() {
            return this.widget && this.widget.can_display_memos;
        },
        canShowServiceNotes() {
            if (!this.selectedService || !this.canDisplayMemos) {
                return false;
            }
            return this.date.startOf("day") >= this.getDateTime().minus({ hours: 3 }).startOf("day");
        },
        reservations() {
            const reservations = this.selectedService
                ? this.selectedService.reservations.data.filter((r) => {
                      return this.validReservationStatus.some((s) => s.value === r.status);
                  })
                : [];
            return reservations;
        },
        reservationsLength() {
            return this.reservations.length;
        },
        mappedReservations() {
            return this.reservations.map((reservation, index) => {
                return {
                    ...reservation,
                    originalIndex: index,
                };
            });
        },
        sortedReservations() {
            return this.mappedReservations.sort((a, b) => {
                if (a.ignore_placement != b.ignore_placement) return a.ignore_placement ? 1 : -1;

                const resaDateTimeA = this.getDateTime(a.reservation_datetime);
                const resaDateTimeB = this.getDateTime(b.reservation_datetime);

                if (resaDateTimeA < resaDateTimeB) {
                    return -1;
                }
                if (resaDateTimeA > resaDateTimeB) {
                    return 1;
                }
                return 0;
            });
        },
        reservationsByStatus() {
            return this.getSortedReservationByStatus(this.selectedStatus);
        },
        activeSlots() {
            return [...new Map(this.reservationsByStatus.map((reservation) => [reservation.slot.id, reservation.slot])).values()];
        },
        openRooms() {
            return this.konva.rooms.filter((room) => !room.closed);
        },
        filteredServices() {
            const openingCategories = this.services.filter((service) => service.special).map((service) => service.category);

            return this.services.filter((service) => {
                return this.checkIfValidService(service, openingCategories);
            });
        },
        selectedTables() {
            const result = [];

            for (const tableId of this.konva.selectedTablesId) {
                const tableFound = this.findTableById(tableId);
                if (tableFound) {
                    result.push(tableFound);
                }
            }

            return result;
        },
        currentRoom() {
            if (this.konva && this.konva.selectedRoom >= 0) {
                return this.konva.rooms[this.konva.selectedRoom];
            }

            return undefined;
        },
        draggedReservation() {
            return this.reservations[this.draggedReservationIndex];
        },
        currentRoomFormatted() {
            if (this.currentRoom) {
                return {
                    ...this.currentRoom,
                    groups: this.currentRoom.entities.filter((e) => e.type === "group"),
                    tables: this.currentRoom.entities.filter((e) => e.type === "table"),
                };
            }

            return undefined;
        },
        isReservationListCollapsed: {
            get() {
                return this.$store.getters["userPreferences/isReservationListCollapsed"];
            },
            set(newVal) {
                this.$store.dispatch("userPreferences/setIsReservationListCollapsed", newVal);

                this.onResize();
            },
        },
        isGroupsAndPrintTabCollapsed() {
            return this.$store.getters["userPreferences/isGroupsAndPrintTabCollapsed"];
        },
        isPaymentEnabled() {
            return !!(this.restaurant.stripe_client_id || (this.restaurant.payplug_public_key && this.restaurant.payplug_secret_key));
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_reservation() {
            return this.rights.includes("booking.booking.update");
        },
    },
    watch: {
        reservationAdded: {
            handler(newValue) {
                if (this.selectedService.id !== newValue.slot.service.id) {
                    return;
                }

                //TODO: change unique source of truth in the future, for now we keep it like this to reflect selectedService with updated services in parent
                //temporary fix waiting for better reactivity
                if (this.selectedService.reservations.data.findIndex((reservation) => newValue.id === reservation.id) === -1) {
                    this.selectedService.reservations.data.push(newValue);
                }

                for (const table of newValue.tables.data) {
                    const tableOnKonva = this.findTableById(table.id);

                    if (tableOnKonva !== null) {
                        //link konva and draw on konva
                        this.linkResaWithTableOnKonva(tableOnKonva, newValue, table.paxUsed, true);
                        this.konva.mainStage.batchDraw();
                    }
                }
            },
        },
        reservationUpdated: {
            handler(newValue) {
                this.updateReservation(newValue);
            },
        },
        reservationDeleted: {
            deep: true,
            handler({ reservationId, serviceId } = {}) {
                if (this.selectedService.id !== serviceId) {
                    return;
                }

                const deletedReservationIndex = this.selectedService.reservations.data.findIndex((reservation) => reservation.id === reservationId);

                if (deletedReservationIndex !== -1) {
                    this.selectedService.reservations.data.splice(deletedReservationIndex, 1);
                }
            },
        },
        draggedReservationIndex: {
            handler: function (newVal) {
                if (newVal === null) {
                    this.$nextTick(() => {
                        if (this.selectedService.is_table_rotation_enabled == true) {
                            this.unhighlight();
                        }
                    });
                }
            },
        },
        selectedServiceIndex(newVal) {
            if (this.switchTable.isSwitching) {
                this.toggleSwitchTable();
            }
            if (newVal !== null) {
                this.socketDisconnect();
                this.fetchData(true);
            }
        },
        date(newVal) {
            if (newVal) {
                this.socketDisconnect();
                this.fetchData(true);
            }
        },
        "konva.selectedRoom": {
            handler(newValue, oldValue) {
                this.drawRoom(newValue, oldValue);
            },
        },
    },
    created() {
        this.selectCurrentService();
        this.fetchData(true);
    },
    beforeDestroy() {
        this.socketDisconnect();
    },
    methods: {
        getEntityMaxPax(entity) {
            const paxTable = entity.enabledPax.length > 0 ? Math.max.apply(null, entity.enabledPax) : Number.parseInt(entity.seats);
            return !Number.isNaN(paxTable) ? paxTable : 0;
        },
        reservationTables(reservation) {
            return (reservation.tables && reservation.tables.data && this.$_.orderBy(reservation.tables.data, "name")) || [];
        },
        //FIXME: improve these function next time
        checkIfNotSameClient(oldClient, newClient) {
            if (newClient !== null && oldClient !== null) {
                return newClient.lastname !== oldClient.lastname || newClient.firstname !== oldClient.firstname;
            }
            return !(newClient === null && oldClient === null);
        },
        checkIfValidService(service, openingCategories) {
            //TODO:  Condition to do in back ?
            if (service.special) {
                return true;
            }

            if (service.is_closed && service.reservations.length === 0) {
                return false;
            }

            if (openingCategories.includes(service.category)) {
                return service.reservations.length > 0;
            }

            return true;
        },
        updateActiveSlots(canFilter) {
            this.activeSlots = canFilter ? this.selectedService.slots.data.filter((slot) => slot.pax > 0) : [];
        },
        displayNoshowModal(reservation) {
            this.selected_restaurant_id = this.restaurantId;
            this.selected_reservation = reservation;
            this.showSetNoshowModal = true;
        },
        displayRefundPartiallyModal(reservationId) {
            this.selected_reservation_id = reservationId;
            this.showRefundPartiallyModal = true;
        },
        displayCancelModal({ newStatus, data }) {
            this.selected_reservation_id = data.id;
            this.selected_reservation_client = data.client;
            this.newCancelStatus = newStatus;
            this.showCancelModal = true;
        },
        getChevron(idSlot) {
            return this.canHideReservations[idSlot] ? "chevron-down" : "chevron-up";
        },
        toggleReservations(idSlot) {
            if (!this.canHideReservations[idSlot]) {
                this.$set(this.canHideReservations, idSlot, !this.canHideReservations[idSlot]);
                return;
            }
            this.canHideReservations[idSlot] = !!!this.canHideReservations[idSlot];
        },
        getSortedReservationByStatus(status) {
            const correspondingStatuses = {
                created: [this.RESERVATION_STATUS_CREATED.value],
                comingSoon: [this.RESERVATION_STATUS_CONFIRMED.value, this.RESERVATION_STATUS_OPTION.value, this.RESERVATION_STATUS_VALIDATED.value],
                at_table: [this.RESERVATION_STATUS_AT_TABLE.value],
                over: [this.RESERVATION_STATUS_OVER.value],
            };

            if (status !== "all") {
                return this.sortedReservations.filter((booking) => {
                    return correspondingStatuses[status].includes(booking.status);
                });
            }

            return this.sortedReservations;
        },
        getReservationsPax(status) {
            const reservations = this.getSortedReservationByStatus(status.value);

            return reservations.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.total_nb_pers;
            }, 0);
        },
        getReservationsForSlot(slot) {
            return this.reservationsByStatus.filter((reservation) => reservation.slot.id === slot.id);
        },
        getPaxReservationsForSlot(slot) {
            return this.getReservationsForSlot(slot).reduce((accumulator, currentValue) => accumulator + currentValue.total_nb_pers, 0);
        },
        changeActive(statusFromTemplate) {
            if (statusFromTemplate.active === true) {
                return;
            }

            for (const status of this.statuses) {
                status.active = status.value === statusFromTemplate.value;
            }
            this.selectedStatus = statusFromTemplate.value;
        },
        selectCurrentService() {
            const now = this.getDateTime();

            if (!now.hasSame(this.date, "day")) {
                return;
            }

            let nextServiceIndex = undefined;

            const index = this.filteredServices.findIndex((service, index) => {
                const serviceDateTimeBegin = this.setHourOnDateTime(service.hour_begin);
                const serviceDateTimeEnd = this.setHourOnDateTime(service.hour_end);

                if (typeof nextServiceIndex === "undefined" && now < serviceDateTimeBegin) {
                    nextServiceIndex = index;
                }

                return serviceDateTimeBegin <= now && now <= serviceDateTimeEnd;
            });

            if (index !== -1) {
                this.selectedServiceIndex = index;
            } else {
                this.selectedServiceIndex = nextServiceIndex || 0;
            }
        },
        onResize() {
            this.$nextTick(() => {
                if (typeof this.$refs.seatingPlanCanvas !== "undefined") {
                    this.konva.mainStage.height(this.$refs.seatingPlanCanvas.offsetHeight);
                    this.konva.mainStage.width(this.$refs.seatingPlanCanvas.offsetWidth);

                    this.drawRoom(this.konva.selectedRoom);
                }
            });
        },
        drawRoom(newValue, oldValue) {
            if (newValue !== -1) {
                if (oldValue !== null && oldValue !== undefined && oldValue >= 0 && oldValue < this.konva.rooms.length) {
                    this.konva.rooms[oldValue].layer.hide();
                }

                if (newValue >= 0 && newValue < this.konva.rooms.length) {
                    const selectedRoom = this.konva.rooms[newValue];
                    selectedRoom.layer.show();
                    selectedRoom.layer.scaleX(1);
                    selectedRoom.layer.scaleY(1);

                    const paddingHeight = selectedRoom.layer.height() * 0.08;
                    const paddingWidth = selectedRoom.layer.width() * 0.08;

                    let selectedRoomClientRect = selectedRoom.layer.getClientRect();

                    if (selectedRoomClientRect.x > 0 && selectedRoom.layer.offsetX() < 0) {
                        const newOffset = selectedRoom.layer.offsetX() + selectedRoomClientRect.x;

                        selectedRoom.layer.offsetX(newOffset > 0 ? 0 : newOffset);
                    }

                    if (selectedRoomClientRect.y > 0 && selectedRoom.layer.offsetY() < 0) {
                        const newOffset = selectedRoom.layer.offsetY() + selectedRoomClientRect.y;

                        selectedRoom.layer.offsetY(newOffset > 0 ? 0 : newOffset);
                    }

                    const offsetX = selectedRoom.layer.offsetX();
                    const offsetY = selectedRoom.layer.offsetY();
                    selectedRoom.layer.offsetX(0);
                    selectedRoom.layer.offsetY(0);

                    let tablesOffsetX = null;
                    let tablesOffsetY = null;

                    selectedRoom.entities
                        .filter((e) => this.inEnum(e.type, this.ALL_TABLE_TYPES))
                        .forEach((tableObject) => {
                            const newX = tableObject.group.x() + Math.abs(offsetX);
                            const newY = tableObject.group.y() + Math.abs(offsetY);

                            if (tablesOffsetX === null || newX < tablesOffsetX) {
                                tablesOffsetX = newX;
                            }

                            if (tablesOffsetY === null || newY < tablesOffsetY) {
                                tablesOffsetY = newY;
                            }

                            tableObject.group.x(newX);
                            tableObject.group.y(newY);
                        });

                    tablesOffsetX -= paddingWidth;
                    tablesOffsetY -= paddingHeight;

                    selectedRoom.entities
                        .filter((e) => this.inEnum(e.type, this.ALL_TABLE_TYPES))
                        .forEach((tableObject) => {
                            tableObject.group.x(tableObject.group.x() - tablesOffsetX);

                            tableObject.group.y(tableObject.group.y() - tablesOffsetY);
                        });

                    let scaleX = selectedRoom.layer.width() / (selectedRoomClientRect.width + paddingWidth);
                    let scaleY = selectedRoom.layer.height() / (selectedRoomClientRect.height + paddingHeight);

                    let scale = scaleX < scaleY ? scaleX : scaleY;

                    if (scale < 1) {
                        selectedRoom.layer.scaleX(scale);
                        selectedRoom.layer.scaleY(scale);
                    }

                    this.konva.mainStage.x(0);
                    this.konva.mainStage.y(0);
                    selectedRoom.layer.x(0);
                    selectedRoom.layer.y(0);

                    this.konva.mainStage.batchDraw();
                }
            }
        },
        toggleSwitchTable() {
            if (this.switchTable.isSwitching) {
                this.switchTable.isSwitching = false;
                this.switchTable.resa1.id = null;
                this.switchTable.resa1.tableId = null;
                this.switchTable.resa2.id = null;
                this.switchTable.resa2.tableId = null;
                this.unselectAllTables();
            } else {
                this.unselectAllTables();
                this.switchTable.isSwitching = true;
            }
        },
        resaChosenForSwitch(resaId) {
            if (resaId === false) {
                this.unselectTable(this.switchTable[`resa${this.switchTable.currentResaChose}`].tableId);
                this.switchTable[`resa${this.switchTable.currentResaChose}`].tableId = null;
            } else {
                this.switchTable[`resa${this.switchTable.currentResaChose}`].id = resaId;
            }
            if (this.switchTable.resa1.id && this.switchTable.resa2.id) {
                this.trySwitchTable();
            }
            this.showReservationListModal = false;
        },
        trySwitchTable() {
            this.loading = true;

            const resa1 = this.getReservationById(this.switchTable.resa1.id);
            const resa2 = this.getReservationById(this.switchTable.resa2.id);
            if (!resa1 || !resa2) this.displayErrorInModal("Invalid reservations");
            const client1 = resa1.client !== null ? `${resa1.client.firstname || ""} ${resa1.client.lastname || ""}` : "";
            const client2 = resa2.client !== null ? `${resa2.client.firstname || ""} ${resa2.client.lastname || ""}` : "";
            if (!confirm(this.$t("questions.booking.seatingPlan.switchTables", { client1, client2 }))) {
                this.toggleSwitchTable();
                return;
            }

            axios
                .post(`/api/restaurants/${this.restaurantId}/services/${this.filteredServices[this.selectedServiceIndex].id}/switch_tables`, {
                    resaId1: this.switchTable.resa1.id,
                    resaId2: this.switchTable.resa2.id,
                })
                .then(() => {
                    this.loading = false;
                    // Data will be updated by Socket
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: this.$tl("success.booking.seatingPlan.tables.switched"),
                    });
                    this.toggleSwitchTable();
                })
                .catch((error) => {
                    this.loading = false;
                    this.displayErrorInModal(this.getErrorMsgFromErrorResponse(error));
                });
        },
        hasResaNotPlaced(s) {
            let service = s;
            let reservations = s.reservations.data || s.reservations;
            if (this.selectedService && this.selectedService.id == s.id) {
                service = this.selectedService;
                reservations = service.reservations.data;
            }
            if (!service.is_seating_plan_algorithm_enabled || !reservations || reservations.length === 0) return false;
            return reservations.some((reservation) => {
                if (!this.inEnum(reservation.status, this.validReservationStatus) || reservation.ignore_placement) return false;
                return !reservation.tables || !reservation.tables.data || reservation.tables.data.length === 0;
            });
        },
        getColorForReservation(reservation) {
            let color = "#000000";
            if (this.seatingPlan && this.seatingPlan.color_type === this.COLOR_UNIQUE.value) color = reservation.color;
            else if (this.seatingPlan && this.seatingPlan.color_type === this.COLOR_STATUS.value)
                color = this.getReservationSeatingPlanColor(reservation.status);
            return color;
        },
        getBackgroundColor(reservation) {
            const color = this.getColorForReservation(reservation);
            return `--red:${this.hexToRgb(color).r};--green:${this.hexToRgb(color).g};--blue:${this.hexToRgb(color).b};`;
        },
        isServiceDisplayable(service) {
            if (!service.deleted_at) return true;
            return service.reservations.some((r) => this.validReservationStatus.some((s) => s.value === r.status));
        },
        fetchData(loadSocket = false) {
            if (this.filteredServices.length === 0) {
                this.isLoading = false;
                return;
            }

            this.isLoading = true;
            const seatingPlanPromise = this.getSeatingPlan();
            const selectedServicePromise = this.getSelectedService();

            Promise.all([selectedServicePromise, seatingPlanPromise])
                .then((response) => {
                    //Initially set Konva => TODO: refacto to have a more consistent initialization
                    this.$set(this.konva, "rooms", []);
                    this.$set(this.konva, "selectedRoom", -1);

                    if (this.seatingPlan !== null) {
                        if (loadSocket) {
                            this.loadSockets();
                        }

                        this.constructSeatingPlan();
                        this.isLoading = false;
                        return true;
                    } else {
                        this.isLoading = false;
                        return false;
                    }
                })
                .catch((error) => {
                    if (error instanceof axios.AxiosError && error.code === axios.AxiosError.ERR_CANCELED) {
                        this.isLoading = false;
                        return false;
                    }

                    this.seatingPlan = null;
                    this.isLoading = false;

                    return false;
                })
                .then((result) => {
                    if (result) {
                        //Ensure to draw when component exist
                        this.$nextTick(() => {
                            if (typeof this.$refs.seatingPlanCanvas !== "undefined") {
                                this.konva.mainStage.height(this.$refs.seatingPlanCanvas.offsetHeight);
                                this.konva.mainStage.width(this.$refs.seatingPlanCanvas.offsetWidth);
                            }

                            this.konva.mainStage.batchDraw();
                            this.addEventsToMainStage();
                            this.konva.selectedRoom = this.konva.rooms.findIndex((r) => !r.closed);
                        });
                    }
                });
        },
        getSelectedService() {
            if (this.previousServiceAbortController) {
                this.previousServiceAbortController.abort();
                this.previousServiceAbortController = null;
            }

            if (typeof this.filteredServices[this.selectedServiceIndex] === "undefined") {
                this.selectedServiceIndex = 0;
            }

            this.previousServiceAbortController = new AbortController();

            const url =
                `/api/restaurants/${this.restaurantId}/services/${this.filteredServices[this.selectedServiceIndex].id}` +
                `?date=${this.date.toISODate()}&include=reservations,reservations.gv_validity,reservations.tables,reservations.client,reservations.slot,is_closed,slots`;

            return axios
                .get(url, {
                    signal: this.previousServiceAbortController.signal,
                })
                .then((response) => {
                    this.selectedService = response.data;
                });
        },
        getSeatingPlan() {
            if (this.previousSeatingPlanAbortController) {
                this.previousSeatingPlanAbortController.abort();
                this.previousSeatingPlanAbortController = null;
            }

            if (typeof this.filteredServices[this.selectedServiceIndex] === "undefined") {
                this.selectedServiceIndex = 0;
            }

            this.previousSeatingPlanAbortController = new AbortController();

            const url =
                `/api/restaurants/${this.restaurantId}/services/${this.filteredServices[this.selectedServiceIndex].id}/seatingPlan` +
                `?date=${this.date.toISODate()}`;

            return axios
                .get(url, {
                    signal: this.previousSeatingPlanAbortController.signal,
                })
                .then((response) => {
                    this.seatingPlan = response.data.seatingPlan;
                });
        },
        async loadSockets() {
            const channelName = `App.restaurant.${this.restaurantId}.service.${this.selectedService.id}.date.${this.date.toISODate()}`;

            Echo.private(channelName).listen(".table.updated", this.onTableUpdated);

            this.socket.listeningChannels.push(channelName);
            this.$store.commit("sockets/addChannel", channelName);
        },
        socketDisconnect() {
            this.socket.listeningChannels.forEach((channel) => {
                Echo.private(channel).stopListening(".table.updated");
            });
            this.socket.listeningChannels = [];
        },
        constructSeatingPlan() {
            let stage = null;

            /**
             *  Manage new stage
             **/

            // delete mainStage
            if (this.konva.mainStage) {
                this.konva.mainStage.destroyChildren();

                this.$set(this.konva, "rooms", []);
                this.$set(this.konva, "selectedRoom", -1);
                stage = this.konva.mainStage;
            } else {
                // create new stage
                stage = new Konva.Stage({
                    container: "seating-plan-canvas",
                    width: 0,
                    height: 0,
                    draggable: true,
                });
            }

            // create rooms
            const rooms = [];

            this.seatingPlan.rooms.forEach((room) => {
                const newRoom = this.constructRoom(room);
                stage.add(newRoom.layer);
                newRoom.layer.hide();
                rooms.push(newRoom);
            });

            this.konva.mainStage = stage;
            this.konva.rooms = rooms;
        },
        constructRoom(baseRoom = null) {
            // TODO: see to create room more easily

            // create default room;
            const room = {
                id: this.$_.uniqueId("room_"),
                serverId: null,
                layer: new Konva.Layer(),
                entities: [],
                selectionRect: new Konva.Rect({
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                    stroke: "red",
                    dash: [2, 2],
                    listening: false,
                }),
                name: `salle ${this.konva.rooms.length}`,
                lastStagePosition: {
                    x: 0,
                    y: 0,
                },
                enabled: true,
                oldOffset: {
                    x: 0,
                    y: 0,
                },
                order: this.konva.rooms.length,
                isEnabledForService: true,
                date_begin: null,
                date_end: null,
                print: false,
            };

            room.layer.add(room.selectionRect);

            // adjust room with data of room in seatingPlan
            if (baseRoom) {
                room.serverId = baseRoom.id;
                room.name = baseRoom.name;
                room.lastStagePosition.x = baseRoom.main_stage_x;
                room.lastStagePosition.y = baseRoom.main_stage_y;
                room.enabled = baseRoom.enabled === 1;
                room.entities = baseRoom.entities;
                room.order = baseRoom.order;
                room.isEnabledForService = baseRoom.isEnabledForService;
                room.date_begin = baseRoom.date_begin;
                room.date_end = baseRoom.date_end;

                //TODO: separate this in another function
                //TODO: see to improve this function
                room.entities = room.entities
                    .filter((e) => !(this.inEnum(e.type, this.ALL_TABLE_TYPES) && e.enabled != true))
                    .map((entity) => {
                        if (this.inEnum(entity.type, this.ALL_TABLE_TYPES)) {
                            var modelElement = this.findElementReference(entity.table_type, entity.seats, entity.size);

                            var table = this.createNewTable(modelElement, entity);

                            table.group.position({
                                x: entity.x,
                                y: entity.y,
                            });

                            if (entity.x < 0 && entity.x < room.layer.offsetX()) {
                                room.layer.offsetX(entity.x);
                            }

                            if (entity.y < 0 && entity.y < room.layer.offsetY()) {
                                room.layer.offsetY(entity.y);
                            }

                            this.konvaElementInit(table.group);

                            room.layer.add(table.group);
                            return table;
                        }
                        return entity;
                    });

                room.entities = room.entities
                    .filter((e) => !(e.type === "group" && e.enabled != true))
                    .map((entity) => {
                        if (entity.type === "group") {
                            entity.room = room;
                            return this.createNewGroup([], entity);
                        }
                        return entity;
                    });
            }

            // TODO: Do we have to check this in front ?
            if (
                (room.datetime_begin && this.getDateTime(room.datetime_begin) > this.date) ||
                (room.date_end && this.getDateTime(room.date_end) < this.date)
            ) {
                room.closed = true;
            }

            return room;
        },
        createNewTable(modelElement, baseTable = null) {
            const tableId = baseTable ? baseTable.id.toString() : this.$_.uniqueId("table_");

            const group = new Konva.Group({
                name: modelElement.seats ? "table" : modelElement.type,
            });

            const label = new Konva.Label({
                name: "label",
            });

            /**
             *   The main group of the table, which will have the following architecture
             * -> Table-label
             *   -> hitArea (Used to be able to click on the whole element)
             *    -> Label
             *       -> Text (Title of the table)
             *   -> Group (contains the shape of the table)
             *          -> paths[] (contains all the paths of the shape)
             * */

            const labelGroup = new Konva.Group({
                name: modelElement.seats ? "table-label" : `${modelElement.type}-label`,
                id: `${tableId}`,
            });

            var table = {
                seats: modelElement.seats,
                type: modelElement.seats ? "table" : modelElement.type,
                tableType: modelElement.type,
                id: tableId,
                serverId: null,
                group: labelGroup,
                tableGroup: group,
                name:
                    modelElement.name ||
                    (this.currentRoomFormatted ? this.currentRoomFormatted.tables.filter((t) => t.seats > 0).length.toString() : "1"),
                enabledPax: modelElement.seats ? _.range(1, modelElement.seats + 1) : 0,
                labelText: null,
                enabled: true,
                reservationLabel: null,
                unlinkButton: null,
                modelElement: modelElement,
                slots: [],
                isEnabledForService: true,
                order: this.currentRoomFormatted ? this.currentRoomFormatted.tables.length : 0,
            };

            if (baseTable) {
                table.seats = baseTable.seats;
                table.serverId = baseTable.id;
                table.name = baseTable.name;
                table.enabledPax = baseTable.enabledPax;
                table.isEnabledForService = baseTable.isEnabledForService;
                table.type = baseTable.type;
                if (table.type === this.TABLE_TYPE_TABLE.value) {
                    table.slots = baseTable.slots.map((s) => {
                        s.reservations_pivot = s.reservations_pivot.map((rp) => {
                            if (rp.reservation_id) {
                                const found = this.selectedService.reservations.data.find((r) => {
                                    return r.id == rp.reservation_id;
                                });

                                if (found) {
                                    rp.reservation = found;
                                }
                            }

                            return rp;
                        });

                        return s;
                    });
                }
                table.order = baseTable.order;
            }

            const labelText = new Konva.Text({
                name: "text",
                text: `${table.name}`,
                fontFamily: "Montserrat",
                fontSize: 12,
                align: "center",
                padding: 3,
                fill: "#414141",
                fontStyle: "400",
                listening: false,
            });

            table.labelText = labelText;

            label.add(labelText);

            modelElement.paths.forEach((path) => {
                let data = {
                    data: path,
                    listening: false,
                    name: "path",
                };
                if (modelElement.scale) {
                    data.scale = modelElement.scale;
                }
                let konvaPath = new Konva.Path(data);

                group.add(konvaPath);
            });

            if (modelElement.table) {
                var konvaPath = new Konva.Path({
                    data: modelElement.table.stroke,
                    listening: false,
                    name: "path-table-stroke",
                });

                group.add(konvaPath);
            }

            labelGroup.add(group);
            labelGroup.add(label);

            const groupClientRect = group.getClientRect();
            // The hit Area of the whole group, it will handle click events
            var hitArea = new Konva.Rect({
                fill: "rgba(0,0,0,0)",
                height: groupClientRect.height,
                width: groupClientRect.width,
                name: "hit-area",
            });

            group.add(hitArea);

            // transform and rotation
            if (baseTable) {
                if (baseTable.rotation) {
                    const groupClientRectNoTransform = group.getClientRect({ skipTransform: true });
                    const topLeft = {
                        x: groupClientRectNoTransform.x - (groupClientRectNoTransform.x + groupClientRectNoTransform.width / 2),
                        y: groupClientRectNoTransform.y - (groupClientRectNoTransform.y + groupClientRectNoTransform.height / 2),
                    };

                    const current = this.rotateAPoint(topLeft, Konva.getAngle(group.rotation()));
                    const rotated = this.rotateAPoint(topLeft, Konva.getAngle(baseTable.rotation));
                    const dx = rotated.x - current.x,
                        dy = rotated.y - current.y;

                    group.rotation(baseTable.rotation);
                    group.x(group.x() + dx);
                    group.y(group.y() + dy);
                }
            }
            const groupClientRectNoTransform = group.getClientRect({ skipTransform: true });
            const labelTextClientRectNoTransform = labelText.getClientRect({ skipTransform: true });
            labelText.getParent().position({
                x: groupClientRectNoTransform.width / 2 - labelTextClientRectNoTransform.width / 2,
                y: groupClientRectNoTransform.height / 2 - labelTextClientRectNoTransform.height / 2,
            });

            // add drag events
            group.on("dragenter", (event) => {
                if (!this.draggedReservation && table.isEnabledForService) {
                    this.unhighlight();
                    this.highlightTable(table);
                }
            });

            group.on("dragleave", (event) => {
                if (!this.draggedReservation && table.isEnabledForService) {
                    this.unhighlightTable(table);
                }
            });

            this.displayReservationsOnTable(table);

            return table;
        },
        getTableAllReservations(tableObject) {
            return tableObject.slots
                .map((s) => {
                    return s.reservations_pivot.filter((rp) => rp.reservation);
                })
                .flat()
                .filter((rp, index, self) => {
                    return rp.reservation && self.findIndex((rp2) => rp2.reservation && rp2.reservation.id === rp.reservation.id) === index;
                });
        },
        unlinkResaManually(table, reservation) {
            this.unlinkResaFromTable(this.findTableByServerId(table.id), reservation);
            this.updateReservation(reservation);
        },
        checkIfCanRedrawTable(oldResa, newResa) {
            let tableIds = [];
            if (newResa.tables && newResa.tables.data) {
                tableIds = newResa.tables.data.map((table) => table.id);
            }
            if (!oldResa.tables.data.every((table) => tableIds.includes(table.id))) {
                return true;
            }
            if (this.checkIfNotSameClient(oldResa.client, newResa.client)) {
                return true;
            }
            if (oldResa.total_nb_pers !== newResa.total_nb_pers) {
                return true;
            }
            if (oldResa.slot.id !== newResa.slot.id) {
                return true;
            }
            if (oldResa.status !== newResa.status) {
                return true;
            }
            return false;
        },
        updateReservation(newReservation) {
            const reservation = this.selectedService.reservations.data.find((resa) => resa.id === newReservation.id);

            if (typeof reservation === "undefined") {
                return;
            }

            if (!this.checkIfCanRedrawTable(reservation, newReservation)) {
                // TODO: change unique source of truth in the future, for now we keep it like this to reflect selectedService with updated services in parent
                if (newReservation.tables && newReservation.tables.data && newReservation.tables.data.length === 0) {
                    delete newReservation.tables;
                }
                Object.assign(reservation, newReservation);
                return;
            }

            // unlink tables
            if (reservation.tables && reservation.tables.data) {
                reservation.tables.data.forEach((table) => {
                    const tableObject = this.findTableByServerId(table.id);
                    if (tableObject !== null) {
                        this.unlinkResaFromTableOnKonva(tableObject, reservation);
                    }
                });
            }

            //TODO: change unique source of truth in the future, for now we keep it like this to reflect selectedService with updated services in parent
            if (newReservation.tables && newReservation.tables.data && newReservation.tables.data.length === 0) {
                delete newReservation.tables;
            }
            Object.assign(reservation, newReservation);

            // link tables
            if (reservation.tables && reservation.tables.data) {
                reservation.tables.data.forEach((table) => {
                    const tableObject = this.findTableByServerId(table.id);
                    if (tableObject !== null) {
                        this.linkResaWithTableOnKonva(tableObject, reservation, table.paxUsed, true);
                        this.konva.mainStage.batchDraw();
                    }
                });
            }
        },
        displayReservationsOnTable(tableObject) {
            let tableReservationsPivot = this.getTableAllReservations(tableObject);
            if (tableReservationsPivot.length > 0) {
                let tablePathFull = null;

                if (tableObject.reservationLabel === null) {
                    tableObject.reservationLabel = new Konva.Group({
                        name: "reservation-label",
                    });

                    tablePathFull = new Konva.Path({
                        data: tableObject.modelElement.table.full,
                        name: "path-table-full",
                    });
                    tableObject.reservationLabel.add(tablePathFull);
                    tableObject.tableGroup.add(tableObject.reservationLabel);
                } else {
                    const reservationLabelChildren = tableObject.reservationLabel.getChildren((node) => {
                        return node.name() === "path-table-full";
                    });

                    if (reservationLabelChildren.length > 0) {
                        tablePathFull = reservationLabelChildren[0];
                    } else {
                        tablePathFull = new Konva.Path({
                            data: tableObject.modelElement.table.full,
                            name: "path-table-full",
                        });
                        tableObject.reservationLabel.add(tablePathFull);
                        tableObject.tableGroup.add(tableObject.reservationLabel);
                    }

                    tableObject.reservationLabel.off("click tap mouseenter mouseleave");
                }

                let mainReservation = this.getNextReservationFromNow(tableReservationsPivot);

                if (tableReservationsPivot.length > 1) {
                    const colorList = [];
                    var resaCount = tableReservationsPivot.length - 1;

                    colorList.push(this.getColorForReservation(mainReservation));
                    tableReservationsPivot.forEach((rp) => {
                        if (rp.reservation && rp.reservation.id !== mainReservation.id) {
                            colorList.push(this.getColorForReservation(rp.reservation));
                        }
                    });

                    const percentOfAResa = 1 / (resaCount + 1);
                    const gradient = [];
                    let lastStop = -1;
                    for (const color of colorList) {
                        if (lastStop === -1) {
                            gradient.push(0);
                            lastStop = 0;
                        } else {
                            gradient.push(lastStop);
                        }
                        gradient.push(color);
                        if (lastStop < 1) {
                            let newStop = lastStop + percentOfAResa;
                            if (newStop > 1) {
                                newStop = 1;
                            }
                            gradient.push(newStop);
                            gradient.push(color);
                            lastStop = newStop;
                        }
                    }

                    if (lastStop < 1) {
                        const lastColor = gradient[gradient.length - 1];
                        gradient.push(1);
                        gradient.push(lastColor);
                    }

                    var pathRect = tablePathFull.getSelfRect();

                    tablePathFull.fillLinearGradientStartPoint({
                        x: pathRect.x,
                        y: pathRect.y,
                    });

                    tablePathFull.fillLinearGradientEndPoint({
                        x: pathRect.x + pathRect.width,
                        y: pathRect.y,
                    });

                    tablePathFull.fillLinearGradientColorStops(gradient);
                    tablePathFull.fillPriority("linear-gradient");
                } else if (tableReservationsPivot.length === 1) {
                    tablePathFull.fill(this.getColorForReservation(mainReservation));
                    tablePathFull.fillPriority("color");
                }

                const nb_pers_total = mainReservation.nb_pers + mainReservation.nb_children;

                const name = mainReservation.is_passing_customer
                    ? this.$tl("labels.booking.reservations.passingCustomer.titleShort", this.restaurantId)
                    : (mainReservation.client !== null && (mainReservation.client.lastname || mainReservation.client.firstname)) ||
                      this.$tl("labels.clients.unknown", this.restaurantId);

                const labelTextContent = `Table n°${tableObject.name}\n${name} (${nb_pers_total})\n${mainReservation.slot.hour_start}`;

                tableObject.labelText.fill("#444");
                tableObject.labelText.text(labelTextContent);
                const tableGroupClientRect = tableObject.tableGroup.getClientRect({ skipTransform: true });
                const labelTextClientRect = tableObject.labelText.getClientRect({ skipTransform: true });

                tableObject.labelText.getParent().position({
                    x: tableGroupClientRect.width / 2 - labelTextClientRect.width / 2,
                    y: tableGroupClientRect.height / 2 - labelTextClientRect.height / 2,
                });

                tableObject.reservationLabel.on("click tap", (e) => {
                    this.$set(this, "selected_restaurant_id", this.restaurantId);

                    if (this.switchTable.isSwitching) {
                        let tableLabelGroup = e.target.getParent().getParent().getParent();
                        const tableLabelGroupId = tableLabelGroup.id();
                        if (this.switchTable.resa1.tableId == tableLabelGroupId) {
                            this.switchTable.resa1.id = null;
                            this.switchTable.resa1.tableId = null;
                            this.unselectTable(tableLabelGroupId);
                        } else if (this.switchTable.resa2.tableId == tableLabelGroupId) {
                            this.switchTable.resa2.id = null;
                            this.switchTable.resa2.tableId = null;
                            this.unselectTable(tableLabelGroupId);
                        } else if (!this.switchTable.resa1.id) {
                            this.switchTable.resa1.tableId = tableLabelGroupId;
                            if (tableReservationsPivot.length === 1) {
                                this.switchTable.resa1.id = mainReservation.id;
                            } else {
                                this.switchTable.currentResaChose = 1;
                                this.$set(
                                    this,
                                    "selected_reservations_list",
                                    tableReservationsPivot.map((rp) => this.findReservationById(rp.reservation_id)).filter((r) => r)
                                );
                                this.$nextTick(() => {
                                    this.$set(this, "showReservationListModal", true);
                                });
                            }
                            this.selectTable(tableLabelGroup);
                        } else if (!this.switchTable.resa2.id) {
                            this.switchTable.resa2.tableId = tableLabelGroupId;
                            if (tableReservationsPivot.length === 1) {
                                this.switchTable.resa2.id = mainReservation.id;
                            } else {
                                this.switchTable.currentResaChose = 2;
                                this.$set(
                                    this,
                                    "selected_reservations_list",
                                    tableReservationsPivot.map((rp) => this.findReservationById(rp.reservation_id)).filter((r) => r)
                                );
                                this.$nextTick(() => {
                                    this.$set(this, "showReservationListModal", true);
                                });
                            }
                            this.selectTable(tableLabelGroup);
                        }
                        if (this.switchTable.resa1.id && this.switchTable.resa2.id) {
                            this.trySwitchTable();
                        }
                        return;
                    }

                    if (tableReservationsPivot.length === 1) {
                        if (!mainReservation.is_passing_customer) {
                            this.$set(this, "selected_reservation_id", mainReservation.id);

                            this.$nextTick(() => this.$set(this, "showReservationModal", true));
                        }
                    } else {
                        const reservationsList = tableReservationsPivot
                            .map((rp) => this.findReservationById(rp.reservation_id))
                            .filter((r) => r && !r.is_passing_customer);

                        if (reservationsList.length > 0) {
                            this.$set(this, "selected_reservations_list", reservationsList);

                            this.$nextTick(() => this.$set(this, "showReservationListModal", true));
                        }
                    }
                    e.cancelBubble = true;
                });

                tableObject.reservationLabel.on("mouseenter", () => {
                    this.konva.mainStage.container().style.cursor = "pointer";
                });

                tableObject.reservationLabel.on("mouseleave", () => {
                    this.konva.mainStage.container().style.cursor = "default";
                });
            } else {
                if (tableObject.reservationLabel !== null) {
                    tableObject.reservationLabel.destroy();
                }
                tableObject.labelText.fill("#414141");
                tableObject.labelText.text(tableObject.name);
                const tableGroupClientRect = tableObject.tableGroup.getClientRect({ skipTransform: true });
                const labelTextClientRect = tableObject.labelText.getClientRect({ skipTransform: true });
                tableObject.labelText.getParent().position({
                    x: tableGroupClientRect.width / 2 - labelTextClientRect.width / 2,
                    y: tableGroupClientRect.height / 2 - labelTextClientRect.height / 2,
                });
                tableObject.reservationLabel = null;
            }

            let colorToDisplay = this.konva.style.mainColor;

            if (!tableObject.isEnabledForService && tableObject.type === this.TABLE_TYPE_TABLE.value) {
                colorToDisplay = this.konva.style.disabledColor;
            } else if (this.isTableBusy(tableObject)) {
                colorToDisplay = this.konva.style.busyColor;
            }

            tableObject.tableGroup.getChildren((node) => {
                if (node.getClassName() === "Path") {
                    node.fill(colorToDisplay);
                }
            });
            return true;
        },
        createNewGroup(tablesId, baseGroup = null) {
            var tablesObjectArray = [];
            let totalSeats = 0;
            let name = "";

            if (baseGroup) {
                tablesObjectArray = baseGroup.tables
                    .map((table) => {
                        return this.findTableByServerId(table.id, baseGroup.room);
                    })
                    .filter((e) => e);
            } else {
                tablesId.forEach((tableId, index) => {
                    const table = this.findTableById(tableId);
                    if (table) {
                        totalSeats += table.seats;
                        tablesObjectArray.push(table);
                        if (index !== 0) {
                            name += ", ";
                        }
                        name += table.name;
                    }
                });
            }

            const groupObject = {
                tables: [...tablesObjectArray],
                id: this.$_.uniqueId("group_"),
                type: "group",
                enabledPax: baseGroup ? baseGroup.enabledPax : _.range(1, totalSeats + 1),
                name: baseGroup ? baseGroup.name : name,
                enabled: baseGroup ? baseGroup.enabled : true,
                expanded: false,
                used: false,
                slots: [],
                order: this.currentRoomFormatted ? this.currentRoomFormatted.groups.length : 0,
                isEnabledForService: true,
            };

            if (baseGroup) {
                groupObject.serverId = baseGroup.id;
                groupObject.order = baseGroup.order;
                groupObject.slots = baseGroup.slots.map((s) => {
                    s.reservations_pivot = Object.values(s.reservations_pivot).map((rp) => {
                        if (rp.reservation_id) {
                            const found = this.selectedService.reservations.data.find((r) => {
                                return r.id == rp.reservation_id;
                            });

                            if (found) {
                                rp.reservation = found;
                            }
                        }

                        return rp;
                    });

                    return s;
                });
                groupObject.used = baseGroup.tables.find((t) => {
                    return t.slots.find((s) => {
                        return s.reservations_pivot.length > 0;
                    });
                })
                    ? true
                    : false;
                if (!baseGroup.isEnabledForService) {
                    groupObject.isEnabledForService = false;
                }
            }

            return groupObject;
        },
        rotateAPoint({ x, y }, rad) {
            const rcos = Math.cos(rad);
            const rsin = Math.sin(rad);

            // Math formula of point rotation
            return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
        },
        findGroupById(id) {
            if (this.currentRoom) {
                let found = this.currentRoom.entities.find((e) => e.type === "group" && e.id === id);

                if (found) {
                    return found;
                }
            }

            return null;
        },
        findGroupsByTableId(tableId) {
            return this.konva.rooms
                .map((r) => {
                    return r.entities.filter((e) => {
                        return e.type === "group" && e.tables.find((t) => t.id === tableId);
                    });
                })
                .flat()
                .filter((i) => i !== null);
        },
        findTableById(id) {
            let foundEntity = null;
            this.konva.rooms.find((r) => {
                foundEntity = r.entities.find((e) => e.type === "table" && e.id == id);
                return foundEntity;
            });

            if (foundEntity) {
                return foundEntity;
            }
            return null;
        },
        findEntityWithoutRoom(id) {
            let table = undefined;

            for (const room of this.konva.rooms) {
                table = this.findEntity(room, id);

                if (table !== undefined) {
                    return table;
                }
            }
        },
        findEntity(room, id) {
            return room.entities.find((entity) => entity.type === "table" && entity.serverId === id);
        },
        findTableByServerId(id, selectedRoom = null) {
            if (selectedRoom === null) {
                return this.findEntityWithoutRoom(id) || null;
            }
            return this.findEntity(selectedRoom, id) || null;
        },
        findRoomById(id) {
            return this.konva.rooms.find((room) => room.id === id) || null;
        },
        konvaElementInit(element) {
            element.listening(true);
            element.on("click tap", (e) => {
                e.cancelBubble = true;
                if (this.switchTable.isSwitching) return;
                this.unselectAllTables();

                this.$nextTick(() => {
                    this.selectTable(e.target.getParent().getParent());
                });
            });

            element.on("mouseover", function () {
                document.body.style.cursor = "pointer";
            });

            element.on("mouseout", function () {
                document.body.style.cursor = "default";
            });
        },
        unselectAllTables() {
            while (this.konva.selectedTablesId.length > 0) {
                this.unselectTable(this.konva.selectedTablesId[0]);
            }
        },
        unselectTable(id) {
            const tableIndexFound = this.konva.selectedTablesId.findIndex((t) => t == id);

            if (tableIndexFound > -1) {
                const tableFound = this.selectedTables[tableIndexFound];

                if (typeof tableFound !== "undefined") {
                    var paths = tableFound.tableGroup.getChildren(function (node) {
                        return node.getClassName() === "Path";
                    });
                    let colorToDisplay = this.konva.style.mainColor;

                    if (!tableFound.isEnabledForService) {
                        colorToDisplay = this.konva.style.disabledColor;
                    } else if (
                        tableFound.slots
                            .map((s) => s.reservations_pivot)
                            .flat()
                            .find((rp) => rp.reservation_id === null)
                    ) {
                        colorToDisplay = this.konva.style.busyColor;
                    }

                    paths.forEach((path) => {
                        path.fill(colorToDisplay);
                    });
                }

                this.konva.selectedTablesId.splice(tableIndexFound, 1);

                this.currentRoom.layer.batchDraw();
            }
        },
        selectTable(tableLabelGroup) {
            const tableFound = this.konva.selectedTablesId.find((t) => t === tableLabelGroup.id());
            if (!tableFound) {
                var tables = tableLabelGroup.getChildren(function (node) {
                    return node.name() === "table";
                });
                if (tables.length == 0) return;

                var self = this;

                tables.forEach(function (table) {
                    var paths = table.getChildren(function (node) {
                        return node.getClassName() === "Path";
                    });

                    paths.forEach(function (path) {
                        path.fill(self.konva.style.selectedColor);
                    });
                });

                this.konva.selectedTablesId.push(tableLabelGroup.id());

                this.currentRoom.layer.batchDraw();
            }
        },
        addEventsToMainStage() {
            var previousShape;
            if (!this.konva.containerListenersSet) {
                this.konva.mainStage.container().addEventListener("dragover", (e) => {
                    e.preventDefault();

                    this.konva.mainStage.setPointersPositions(e);
                    const pos = this.konva.mainStage.getPointerPosition();
                    var shape = this.currentRoom.layer.getIntersection(pos);
                    if (previousShape && shape) {
                        if (previousShape !== shape) {
                            // leave from old target
                            previousShape.fire(
                                "dragleave",
                                {
                                    type: "dragleave",
                                    target: previousShape,
                                    evt: e.evt,
                                },
                                true
                            );

                            // enter new targer
                            shape.fire(
                                "dragenter",
                                {
                                    type: "dragenter",
                                    target: shape,
                                    evt: e.evt,
                                },
                                true
                            );
                            previousShape = shape;
                        } else {
                            previousShape.fire(
                                "dragover",
                                {
                                    type: "dragover",
                                    target: previousShape,
                                    evt: e.evt,
                                },
                                true
                            );
                        }
                    } else if (!previousShape && shape) {
                        previousShape = shape;
                        shape.fire(
                            "dragenter",
                            {
                                type: "dragenter",
                                target: shape,
                                evt: e.evt,
                            },
                            true
                        );
                    } else if (previousShape && !shape) {
                        previousShape.fire(
                            "dragleave",
                            {
                                type: "dragleave",
                                target: previousShape,
                                evt: e.evt,
                            },
                            true
                        );
                        previousShape = undefined;
                    }
                });

                // Listen to dropped event
                this.konva.mainStage.container().addEventListener("drop", (e) => {
                    this.isDroping = true;
                    e.preventDefault();
                    // If there is a valid dragged element
                    if (this.draggedReservationIndex !== null) {
                        this.unhighlight();
                        // set main stage pointer positions to current pointer positions
                        this.konva.mainStage.setPointersPositions(e);

                        const pointerPosition = this.konva.mainStage.getPointerPosition();
                        const tables = this.currentRoom.layer.getChildren((node) => {
                            return node.name() === "table-label";
                        });

                        let tableMatched = null;

                        let index = 0;
                        while (index < tables.length) {
                            var table = tables[index++];
                            const tableClientRect = table.getClientRect();
                            var minX = tableClientRect.x;
                            var maxX = minX + tableClientRect.width;
                            var minY = tableClientRect.y;
                            var maxY = minY + tableClientRect.height;

                            if (minX <= pointerPosition.x && pointerPosition.x <= maxX && minY <= pointerPosition.y && pointerPosition.y <= maxY) {
                                tableMatched = table;
                            }
                        }

                        if (tableMatched) {
                            const tableObjectFound = this.findTableById(tableMatched.id());
                            if (tableObjectFound.isEnabledForService) {
                                const maxPax = this.getEntityMaxPax(tableObjectFound);

                                if (this.getReservationPaxLeft(this.draggedReservation) > maxPax) {
                                    this.paxesSelection.tables = [tableObjectFound];
                                    this.paxesSelection.dropedOnType = "table";
                                    this.paxesSelection.dropRelatedObject = tableObjectFound;
                                    this.$nextTick(() => {
                                        this.showPaxSelectionModal = true;
                                    });
                                } else {
                                    this.linkResaWithTable(tableObjectFound.group, maxPax);
                                }
                            } else {
                                this.draggedReservationIndex = null;
                            }
                        } else {
                            this.draggedReservationIndex = null;
                        }
                    }

                    this.isDroping = false;
                });

                this.konva.mainStage.on("click tap", (e) => {
                    if (this.switchTable.isSwitching) return;
                    this.unselectAllTables();
                });

                this.konva.mainStage.on("wheel", (e) => {
                    e.evt.preventDefault();
                    // zoom 1% percent more or less
                    const zoomModifierRate = 1.01;
                    var oldScale = this.currentRoom.layer.scaleX();

                    var mousePointTo = {
                        x: this.konva.mainStage.getPointerPosition().x / oldScale - this.konva.mainStage.x() / oldScale,
                        y: this.konva.mainStage.getPointerPosition().y / oldScale - this.konva.mainStage.y() / oldScale,
                    };

                    var newScale = e.evt.deltaY > 0 ? oldScale * zoomModifierRate : oldScale / zoomModifierRate;

                    this.currentRoom.layer.scale({
                        x: newScale,
                        y: newScale,
                    });

                    var newPos = {
                        x: -(mousePointTo.x - this.konva.mainStage.getPointerPosition().x / newScale) * newScale,
                        y: -(mousePointTo.y - this.konva.mainStage.getPointerPosition().y / newScale) * newScale,
                    };

                    this.konva.mainStage.position(newPos);

                    this.konva.mainStage.batchDraw();
                });

                this.konva.mainStage.on("touchmove", (e) => {
                    e.evt.preventDefault();

                    const touch1 = e.evt.touches[0];
                    const touch2 = e.evt.touches[1];

                    if (touch1 && touch2) {
                        // if the stage was under Konva's drag&drop
                        // we need to stop it, and implement our own pan logic with two pointers
                        if (this.konva.mainStage.isDragging()) {
                            this.konva.mainStage.stopDrag();
                        }

                        const p1 = {
                            x: touch1.clientX,
                            y: touch1.clientY,
                        };
                        const p2 = {
                            x: touch2.clientX,
                            y: touch2.clientY,
                        };

                        if (!this.touchLastCenter) {
                            this.touchLastCenter = this.getCenter(p1, p2);
                            return;
                        }
                        const newCenter = this.getCenter(p1, p2);

                        const dist = this.getDistance(p1, p2);

                        if (!this.touchLastDist) {
                            this.touchLastDist = dist;
                        }

                        // local coordinates of center point
                        const pointTo = {
                            x: (newCenter.x - this.konva.mainStage.x()) / this.konva.mainStage.scaleX(),
                            y: (newCenter.y - this.konva.mainStage.y()) / this.konva.mainStage.scaleX(),
                        };

                        const scale = this.konva.mainStage.scaleX() * (dist / this.touchLastDist);

                        this.konva.mainStage.scaleX(scale);
                        this.konva.mainStage.scaleY(scale);

                        // calculate new position of the stage
                        const dx = newCenter.x - this.touchLastCenter.x;
                        const dy = newCenter.y - this.touchLastCenter.y;

                        const newPos = {
                            x: newCenter.x - pointTo.x * scale + dx,
                            y: newCenter.y - pointTo.y * scale + dy,
                        };

                        this.konva.mainStage.position(newPos);

                        this.touchLastDist = dist;
                        this.touchLastCenter = newCenter;
                    }
                });

                this.konva.mainStage.on("touchend", function () {
                    this.touchLastDist = 0;
                    this.touchLastCenter = null;
                });

                this.konva.containerListenersSet = true;
            }
        },
        getDistance(p1, p2) {
            return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
        },
        getCenter(p1, p2) {
            return {
                x: (p1.x + p2.x) / 2,
                y: (p1.y + p2.y) / 2,
            };
        },
        dragstartReservation(event, reservationIndex) {
            if (event && event.target && event.target.style) {
                event.target.style.opacity = 0.4;
            }
            this.draggedReservationIndex = reservationIndex;

            this.$nextTick(() => {
                if (this.selectedService.is_table_rotation_enabled == true) {
                    this.highlightAvailableTablesForResa(this.draggedReservation);
                }
            });
        },
        dragendReservation(event) {
            if (event && event.target && event.target.style) {
                event.target.style.opacity = 1;
            }

            if (!this.isDroping && this.selectedService.is_table_rotation_enabled == true) {
                this.unhighlight();
            }
        },
        getAvailableTablesForResa(reservation, entities = null) {
            const reservationStartAt = this.getReservationStartAt(reservation);
            const reservationEndsAt = this.getReservationEndAt(reservation);

            var reducer = (a, b) => {
                return a && b;
            };

            let entitiesToFilter = entities || this.currentRoom.entities;
            return entitiesToFilter.filter((t) => {
                if (t.type === this.TABLE_TYPE_TABLE.value) {
                    let isTableAvailable = false;
                    const results = t.slots
                        .map((s) => {
                            const slotStartAt = this.getSlotStartAt(s, reservation.reservation_datetime);
                            if (slotStartAt >= reservationStartAt && slotStartAt < reservationEndsAt) {
                                return t.isEnabledForService && s.reservations_pivot.length === 0;
                            }

                            return null;
                        })
                        .flat()
                        .filter((i) => i !== null);

                    if (results.length > 0) {
                        isTableAvailable = results.reduce(reducer);
                    }

                    return isTableAvailable;
                }

                return false;
            });
        },
        highlightAvailableTablesForResa(reservation) {
            var tablesToHighLight = this.getAvailableTablesForResa(reservation);

            tablesToHighLight.forEach((t) => {
                this.highlightTable(t);
            });
        },
        linkResaWithTable(table, selectedPax) {
            let reservation = this.draggedReservation;

            if (typeof reservation === "undefined") {
                return false;
            }

            const tableObjectFound = this.findTableById(table.id());

            if (!tableObjectFound || tableObjectFound.serverId === null) {
                //TODO: surely remove this in another iteration
                return new Promise((resolve, reject) => {
                    resolve(false);
                });
            }
            if (this.linkResaWithTableOnKonva(tableObjectFound, reservation, selectedPax)) {
                const url = `/api/restaurants/${this.restaurantId}/reservations/${reservation.id}/table`;
                return axios
                    .post(url, {
                        reservation_id: reservation.id,
                        seating_plan_table_id: tableObjectFound.serverId,
                        pax: selectedPax ? selectedPax : reservation.nb_pers + reservation.nb_children,
                    })
                    .then((response) => {
                        //FIXME: Useful ?
                        if (this.addDataKeyToReservationsList) {
                            this.services[this.selectedServiceIndex].reservations = this.selectedService.reservations.data;
                        } else {
                            this.services[this.selectedServiceIndex].reservations.data = this.selectedService.reservations.data;
                        }
                        return true;
                    })
                    .catch((error) => {
                        this.unlinkResaFromTableOnKonva(tableObjectFound, reservation);
                        this.displayErrorInModal(this.getErrorMsgFromErrorResponse(error));

                        return false;
                    });
            } else {
                //TODO: surely remove this in another iteration
                return new Promise((resolve, reject) => {
                    resolve(false);
                });
            }
        },
        canLinkTableOnKonva(tableObject, reservation, selectedPax) {
            let errorMsg = null;

            if (this.reservationHasTable(reservation, tableObject)) {
                errorMsg = this.$tl("errors.booking.seatingPlan.table.alreadyLinkToThisResa");
            } else if (selectedPax && this.reservationIsAlreadyPlaced(reservation)) {
                errorMsg = this.$tl("errors.booking.seatingPlan.allPaxPlaced");
            } else if (this.tableAlreadyUsedForOtherReservation(tableObject, reservation)) {
                errorMsg = this.$tl("errors.booking.seatingPlan.table.alreadyLinkToAnotherResa");
            } else if (this.reservationIsPlacedInOtherRooms(reservation, tableObject)) {
                errorMsg = this.$tl("errors.booking.seatingPlan.cantPlaceOnMultipleRooms");
            }

            if (errorMsg !== null) {
                this.displayErrorInModal(errorMsg);
                return false;
            }

            return true;
        },
        linkResaWithTableOnKonva(tableObject, reservation, selectedPax, isInit = false) {
            if (isInit || this.canLinkTableOnKonva(tableObject, reservation, selectedPax)) {
                if (!isInit) {
                    this.removeTemporaryReservationForTable(tableObject, reservation.reservation_date, reservation.slot_id, reservation.duration);
                    this.reservationAddTable(reservation, tableObject, selectedPax);
                }

                this.addReservationOnGroupsWithSameTable(reservation, tableObject);

                const resaPivot = this.formatReservationToPivot(reservation, tableObject);
                let slotsToCheck = tableObject.slots;

                if (slotsToCheck.length === 0) {
                    slotsToCheck.push({ reservations_pivot: [resaPivot] });
                } else {
                    slotsToCheck.forEach((s) => {
                        s.reservations_pivot.push(resaPivot);
                    });
                }
                this.displayReservationsOnTable(tableObject);

                if (!isInit) {
                    this.konva.mainStage.batchDraw();
                }

                return true;
            }
            return false;
        },
        removeTemporaryReservationForTable(tableObject) {
            tableObject.slots.forEach((slot) => {
                slot.reservations_pivot.filter((resaPivot) => resaPivot.reservation_id !== null);
            });
        },
        formatReservationToPivot(reservation, tableObject, linkedManually = true) {
            return {
                reservation_id: reservation.id,
                reservation,
                slot_id: reservation.slot.id,
                slot: reservation.slot,
                duration: reservation.duration,
                date: reservation.reservation_date,
                seating_plan_table_id: tableObject.serverId,
                linked_manually: linkedManually,
            };
        },
        unlinkResaFromTableOnKonva(tableObject, reservation) {
            this.removeTableFromResa(reservation, tableObject);

            tableObject.slots.forEach((s) => {
                const foundIndex = s.reservations_pivot.findIndex((rp) => {
                    return rp.reservation_id !== null && rp.reservation_id === reservation.id;
                });

                if (foundIndex > -1) {
                    s.reservations_pivot.splice(foundIndex, 1);
                }
            });

            this.removeReservationOnGroupsWithSameTable(reservation, tableObject);

            this.displayReservationsOnTable(tableObject);
            this.konva.mainStage.batchDraw();
        },
        unlinkResaFromTable(tableObject, reservation) {
            const reservationPivotFound = tableObject.slots
                .map((s) => s.reservations_pivot)
                .flat()
                .find((rp) => {
                    return rp.reservation_id !== null && rp.reservation.id === reservation.id;
                });

            let tableFound = undefined;

            if (reservation.tables && reservation.tables.data) {
                tableFound = reservation.tables.data.find((t) => {
                    return t.id === tableObject.serverId;
                });
            }

            if (reservationPivotFound && tableFound && tableObject.serverId) {
                const paxUsed = tableFound.paxUsed;
                this.unlinkResaFromTableOnKonva(tableObject, reservation);
                const url = `/api/restaurants/${this.restaurantId}/reservations/${reservationPivotFound.reservation.id}/table`;
                axios
                    .delete(url, {
                        data: {
                            reservation_id: reservation.id,
                            seating_plan_table_id: tableObject.serverId,
                        },
                    })
                    .then(() => {
                        if (this.addDataKeyToReservationsList) {
                            this.services[this.selectedServiceIndex].reservations.data = this.selectedService.reservations.data;
                        } else {
                            this.services[this.selectedServiceIndex].reservations = this.selectedService.reservations.data;
                        }
                    })
                    .catch((error) => {
                        this.displayErrorInModal(this.getErrorMsgFromErrorResponse(error));
                        this.linkResaWithTableOnKonva(tableObject, reservation, paxUsed);
                    });
            }
        },
        addReservationOnGroupsWithSameTable(givenReservation, tableObject) {
            const groups = this.findGroupsByTableId(tableObject.id);
            const startAt = this.getReservationStartAt(givenReservation);
            const endsAt = this.getReservationEndAt(givenReservation);
            const resaPivot = this.formatReservationToPivot(givenReservation, tableObject);

            for (var group of groups) {
                group.used = true;

                if (
                    this.areGroupTablesLinkedToSameResa(group, givenReservation) &&
                    group.slots
                        .map((s) => s.reservations_pivot)
                        .flat()
                        .filter((rp) => rp.reservation.id === givenReservation.id).length === 0
                ) {
                    var slotsToCheck = group.slots;

                    if (this.selectedService.is_table_rotation_enabled == true) {
                        slotsToCheck = slotsToCheck.filter((s) => {
                            const slotStartAt = this.getSlotStartAt(s, givenReservation.reservation_datetime);

                            return slotStartAt >= startAt && slotStartAt < endsAt;
                        });
                    }

                    slotsToCheck.map((s) => {
                        s.reservations_pivot.push(resaPivot);
                    });
                }
            }
        },
        removeReservationOnGroupsWithSameTable(givenReservation, tableObject) {
            const groups = this.findGroupsByTableId(tableObject.id);

            for (var group of groups) {
                group.slots.forEach((s) => {
                    const index = s.reservations_pivot.findIndex((rp) => rp.reservation && rp.reservation.id === givenReservation.id);

                    if (index > -1) {
                        s.reservations_pivot.splice(index, 1);
                    }
                });

                group.used = this.doesGroupHasUsedTables(group);
            }
        },
        reservationAddTable(givenReservation, tableObject, selectedPax = null) {
            var tableFormated = this.formatTableToResaFormat(tableObject);
            tableFormated.paxUsed = selectedPax ? selectedPax : givenReservation.nb_pers + givenReservation.nb_children;

            this.reservations.forEach((reservation) => {
                if (reservation.id === givenReservation.id && !this.reservationHasTable(reservation, tableObject)) {
                    if (reservation.tables && reservation.tables.data) {
                        reservation.tables.data.push(tableFormated);
                    } else {
                        reservation.tables = {
                            data: [tableFormated],
                        };
                    }
                }
            });
        },
        removeTableFromResa(givenReservation, tableObject) {
            this.reservations.forEach((reservation) => {
                if (reservation.id === givenReservation.id) {
                    let tableIndex = -1;

                    if (reservation.tables && reservation.tables.data) {
                        tableIndex = reservation.tables.data.findIndex((t) => t.id == tableObject.serverId);
                    }

                    if (tableIndex > -1) {
                        reservation.tables.data.splice(tableIndex, 1);
                    }
                }
            });
        },
        formatTableToResaFormat(tableObject) {
            return {
                enabled: tableObject.enabled ? 1 : 0,
                enabledPax: tableObject.enabledPax,
                paxUsed: tableObject.paxUsed,
                id: tableObject.serverId,
                name: tableObject.name,
                seats: tableObject.seats,
            };
        },
        reservationHasTable(reservation, tableObject) {
            if (typeof reservation === "undefined" || !reservation.tables || !reservation.tables.data) {
                return false;
            }

            return !this.$_.isEmpty(reservation.tables.data.find((t) => t.id == tableObject.serverId));
        },
        reservationIsAlreadyPlaced(reservation) {
            if (typeof reservation === "undefined" || !reservation.tables || !reservation.tables.data) {
                return false;
            }

            const reservationPax = Number.parseInt(reservation.nb_children) + Number.parseInt(reservation.nb_pers);
            var tablesPax = 0;
            var index = 0;

            while (index < reservation.tables.data.length) {
                const table = reservation.tables.data[index];

                tablesPax += table.paxUsed;
                index++;
            }

            return reservationPax <= tablesPax;
        },
        reservationIsPlacedInOtherRooms(reservation, tableObject) {
            if (typeof reservation === "undefined" || !reservation.tables || !reservation.tables.data) {
                return false;
            }

            if (reservation.tables.data.length > 0) {
                const reservationRoom = this.konva.rooms.find((r) => {
                    return r.entities.find((e) => e.type === "table" && e.serverId === tableObject.serverId);
                });

                const tableObjectRoom = this.konva.rooms.find((r) => {
                    return r.entities.find((e) => e.type === "table" && e.serverId === tableObject.serverId);
                });

                return tableObjectRoom.id !== reservationRoom.id;
            }

            return false;
        },
        tableAlreadyUsedForOtherReservation(tableObject, reservation) {
            if (typeof reservation === "undefined" || !reservation.tables || !reservation.tables.data) {
                return false;
            }

            const startAt = this.getReservationStartAt(reservation);
            const endsAt = this.getReservationEndAt(reservation);
            const found = this.konva.rooms
                .map((r) => r.entities)
                .flat()
                .find((e) => {
                    return e.type === "table" && e.id == tableObject.serverId;
                });

            if (found) {
                var slotsToCheck = found.slots;

                if (this.selectedService.is_table_rotation_enabled == true) {
                    slotsToCheck = slotsToCheck.filter((s) => {
                        const slotStartAt = this.getSlotStartAt(s, reservation.reservation_datetime);

                        return slotStartAt >= startAt && slotStartAt < endsAt;
                    });
                }

                if (
                    slotsToCheck.find((s) => {
                        return s.reservations_pivot.length > 0;
                    })
                ) {
                    return true;
                }
            }

            return false;
        },
        reservationEdited() {
            this.showEditReservationModal = false;
            this.$notify({
                group: "notification",
                type: "success",
                title: this.$tl("success.booking.reservations.edited"),
            });
            //for now keep it like this because of how it works
            this.socketDisconnect(); // Set back in fetchData
            this.$nextTick(() => {
                this.fetchData(true);
            });
        },
        reservationEditedFromList() {
            this.showReservationListModal = false;
            this.$notify({
                group: "notification",
                type: "success",
                title: this.$tl("success.booking.reservations.edited"),
            });
            //for now keep it like this because of how it works
            this.socketDisconnect(); // Set back in fetchData
            this.$nextTick(() => {
                this.fetchData(true);
            });
        },
        editReservationFromShowModal({ reservation_id }) {
            this.$set(this, "selected_reservation_id", reservation_id);
            this.$nextTick(() => {
                this.showReservationModal = false;
                this.showEditReservationModal = true;
            });
        },
        displayErrorInModal(message) {
            this.modalErrorMessage = message;
            this.showErrorModal = true;
        },
        getReservationRoom(reservation) {
            let tablesIds = [];

            if (reservation.tables && reservation.tables.data) {
                tablesIds = reservation.tables.data.map((t) => t.id);
            }

            const roomFound = this.konva.rooms.find((r) => {
                return r.entities.find((e) => {
                    return e.type === "table" && tablesIds.includes(e.serverId);
                });
            });

            if (roomFound) {
                return roomFound.name.charAt(0).toUpperCase() + roomFound.name.slice(1);
            }

            return "/";
        },
        highlightGroup({ group, event }) {
            group.tables.forEach(this.highlightTable);
        },
        highlightTable(table) {
            var paths = table.tableGroup.getChildren((node) => {
                return node.getClassName() === "Path";
            });

            paths.forEach((path) => {
                path.fill(this.konva.style.selectedColor);
            });

            this.konva.highlightedTables.push(table);
            this.currentRoom.layer.batchDraw();
            this.konva.mainStage.batchDraw();
        },
        unhighlight() {
            this.konva.highlightedTables.forEach(this.unhighlightTable);
        },
        unhighlightTable(table) {
            if (!this.konva.selectedTablesId.find((id) => id == table.id)) {
                var paths = table.tableGroup.getChildren((node) => {
                    return node.getClassName() === "Path";
                });

                let colorToDisplay = this.konva.style.mainColor;

                if (!table.isEnabledForService) {
                    colorToDisplay = this.konva.style.disabledColor;
                } else if (
                    table.slots
                        .map((s) => s.reservations_pivot)
                        .flat()
                        .find((rp) => rp.reservation_id === null)
                ) {
                    colorToDisplay = this.konva.style.busyColor;
                }

                paths.forEach((path) => {
                    path.fill(colorToDisplay);
                });
            }

            this.konva.highlightedTables = this.konva.highlightedTables.filter((t) => t.id !== table.id);
            this.currentRoom.layer.batchDraw();
        },
        dropedOnGroup({ group, event }) {
            var foundGroup = this.findGroupById(group.id);

            if (foundGroup) {
                if (this.getReservationPaxLeft(this.draggedReservation) > this.getEntityMaxPax(foundGroup)) {
                    this.paxesSelection.tables = group.tables;
                    this.paxesSelection.dropedOnType = "group";
                    this.paxesSelection.dropRelatedObject = foundGroup;

                    this.$nextTick(() => {
                        this.showPaxSelectionModal = true;
                    });
                } else {
                    var tableIndex = 0;
                    var promises = [];
                    while (tableIndex < group.tables.length) {
                        var table = group.tables[tableIndex];
                        promises.push(this.linkResaWithTable(table.group, null));
                        tableIndex++;
                    }

                    Promise.all(promises).then(() => {
                        this.draggedReservationIndex = null;
                    });
                }
            } else {
                this.draggedReservationIndex = null;
            }
        },
        async linkResaWithGroup(group, selectedPaxes) {
            var tableIndex = 0;

            if (selectedPaxes) {
                for (const table of group.tables) {
                    const selectedPax = selectedPaxes.filter((sp) => sp.table.id === table.id);

                    if (selectedPax.length !== 1) {
                        return;
                    }
                }

                this.reservationGettingPlaced.reservationIndex = this.draggedReservationIndex;
                while (tableIndex < group.tables.length) {
                    const table = group.tables[tableIndex];
                    const selectedPax = selectedPaxes.filter((sp) => sp.table.id === table.id);

                    await this.linkResaWithTable(table.group, selectedPax[0].paxSelected);
                    tableIndex++;
                }
            } else {
                while (tableIndex < group.tables.length) {
                    const table = group.tables[tableIndex];
                    await this.linkResaWithTable(table.group, null);
                    tableIndex++;
                }
            }

            this.draggedReservationIndex = null;
        },
        areGroupTablesLinkedToSameResa(group, reservation) {
            for (var table of group.tables) {
                if (reservation.tables && reservation.tables.data && reservation.tables.data.filter((t) => t.id === table.serverId).length < 1) {
                    return false;
                }
            }

            return true;
        },
        doesGroupHasUsedTables(group) {
            if (group.slots.map((s) => s.reservations_pivot).flat().length > 0) {
                return true;
            }

            const tablesWithReservations = group.tables.filter((t) => {
                return (
                    t.slots
                        .map((s) => s.reservations_pivot)
                        .flat()
                        .filter((rp) => rp.reservation_id !== null).length > 0
                );
            });

            return tablesWithReservations.length > 0;
        },
        paxesSelected({ selectedPaxes, dropedOnType, dropRelatedObject, selectedTables }) {
            switch (dropedOnType) {
                case "group":
                    this.linkResaWithGroup(dropRelatedObject, selectedPaxes);
                    break;
                case "table":
                    const selectedPax = selectedPaxes.filter((sp) => sp.table.id === dropRelatedObject.id);

                    if (selectedPax.length === 1) {
                        this.reservationGettingPlaced.reservationIndex = this.draggedReservationIndex;
                        this.linkResaWithTable(dropRelatedObject.group, selectedPax[0].paxSelected);
                    }
                    break;
            }

            // if there are other associated tables
            if (selectedTables.length > 0) {
                this.placeResa(selectedTables);
            }

            this.draggedReservationIndex = null;
            this.showPaxSelectionModal = false;
        },
        placeAll({ dropedOnType, dropRelatedObject }) {
            switch (dropedOnType) {
                case "group":
                    this.linkResaWithGroup(dropRelatedObject, null);
                    break;
                case "table":
                    this.linkResaWithTable(dropRelatedObject.group, null).then((response) => {
                        this.draggedReservationIndex = null;
                    });
                    break;
            }
            this.showPaxSelectionModal = false;
        },
        placeResa(selectedTables) {
            selectedTables.forEach((selectedTable) => {
                if (selectedTable.table.type === "group") {
                    selectedTable.table.tables.forEach((currentTable) => {
                        const konvaTable = this.findTableByServerId(currentTable.id);
                        this.linkResaWithTable(konvaTable.group, null);
                    });
                } else {
                    const table = this.findTableByServerId(selectedTable.table.id);
                    this.reservationGettingPlaced.reservationIndex = this.draggedReservationIndex;
                    this.linkResaWithTable(table.group, selectedTable.selectedPax);
                }
            });
        },
        abortPaxesSelection() {
            this.unhighlight();
            this.paxesSelection.tables = [];
            this.paxesSelection.dropedOnType = "";
            this.paxesSelection.dropRelatedObject = null;
            this.draggedReservationIndex = null;
            this.showPaxSelectionModal = false;
        },
        isTableBusy(tableObject) {
            //TODO: this function will be reworked to reflect the change coming in https://yproximite.atlassian.net/browse/NOSHOW-1204
            return (
                tableObject.slots
                    .map((s) => {
                        return s.reservations_pivot.filter((rp) => rp.reservation_id === null);
                    })
                    .flat().length > 0
            );
        },
        findReservationById(id) {
            return this.selectedService.reservations.data.find((r) => r.id == id);
        },
        getNextReservationFromNow(reservationPivotArray) {
            let reservationFound = null;
            let reservationFoundNowDiff = null;

            const nowDateTime = this.getDateTime();
            const reservationArray = reservationPivotArray.map((rp) => rp.reservation).filter((rp) => rp);

            for (const reservation of reservationArray) {
                if (!reservationFound) {
                    reservationFound = reservation;
                    reservationFoundNowDiff = this.getReservationStartAt(reservation).diff(nowDateTime, "minutes").minutes;
                } else {
                    var tmpNowDiff = this.getReservationStartAt(reservation).diff(nowDateTime, "minutes").minutes;
                    let swipe = false;

                    if (reservationFoundNowDiff < 0 && tmpNowDiff > 0) {
                        swipe = true;
                    } else if (reservationFoundNowDiff < 0 && tmpNowDiff < 0 && reservationFoundNowDiff < tmpNowDiff) {
                        swipe = true;
                    } else if (reservationFoundNowDiff > 0 && tmpNowDiff > 0 && reservationFoundNowDiff > tmpNowDiff) {
                        swipe = true;
                    }

                    if (swipe) {
                        reservationFound = reservation;
                        reservationFoundNowDiff = tmpNowDiff;
                    }
                }
            }

            return reservationFound;
        },
        getSlotStartAt(slot, date = this.date) {
            const slotHourArray = slot.hour_start.split(":");

            return this.getDateTime(date).startOf("day").plus({
                hours: slotHourArray[0],
                minutes: slotHourArray[1],
            });
        },
        getReservationStartAt(resa) {
            return this.getDateTime(resa.reservation_datetime);
        },
        getReservationEndAt(resa) {
            var durationArray = resa.duration.split(":");

            return this.getReservationStartAt(resa).plus({
                hours: durationArray[0],
                minutes: durationArray[1],
            });
        },
        getReservationById(resa_id) {
            for (var resa of this.reservations) if (resa.id == resa_id) return resa;
            return null;
        },
        launchAutoPlacing() {
            return axios
                .post(`/api/restaurants/${this.restaurantId}/services/${this.selectedService.id}/reservations/place?date=${this.date.toISODate()}`)
                .then(() => {
                    this.notifySuccess(null, this.$tl("success.booking.seatingPlan.resasPlaced"));
                })
                .catch(() => {
                    this.notifyError(null, this.$tl("errors.booking.seatingPlan.cantPlace"));
                });
        },
        getReservationPaxLeft(reservation) {
            var paxTotal = reservation.nb_pers + reservation.nb_children;

            if (reservation.tables && reservation.tables.data) {
                reservation.tables.data.forEach((t) => {
                    paxTotal -= t.paxUsed;
                });
            }

            return paxTotal;
        },
        showReservation(reservation) {
            this.$set(this, "selected_restaurant_id", this.restaurantId);
            this.$set(this, "selected_reservation_id", reservation.id);
            this.$nextTick(() => {
                this.$set(this, "showReservationModal", true);
            });
        },
        enableSelectedTable() {
            if (this.selectedTables.length === 1) {
                var selectedTable = this.selectedTables[0];

                selectedTable.isEnabledForService = true;
                const found = this.konva.rooms
                    .map((r) => r.entities)
                    .flat()
                    .find((e) => {
                        return e.type === "table" && e.id == selectedTable.serverId;
                    });

                if (found) {
                    found.isEnabledForService = true;
                    this.checkTableGroupsEnability(found);
                }

                axios
                    .post(`/api/restaurants/${this.restaurantId}/services/${this.selectedService.id}/tables/${selectedTable.serverId}/enable`, {
                        date: this.date.toISODate(),
                    })
                    .then(() => {
                        this.notifySuccess(null, this.$tl("success.booking.seatingPlan.tables.enabledForService", this.restaurantId));
                    })
                    .catch((error) => {
                        this.notifyError(error);

                        selectedTable.isEnabledForService = false;
                        const found = this.konva.rooms
                            .map((r) => r.entities)
                            .flat()
                            .find((e) => {
                                return e.type === "table" && e.id == selectedTable.serverId;
                            });

                        if (found) {
                            found.isEnabledForService = false;
                            this.checkTableGroupsEnability(found);
                        }
                    });
            }
        },
        disableSelectedTable() {
            if (this.selectedTables.length === 1) {
                var selectedTable = this.selectedTables[0];

                selectedTable.isEnabledForService = false;

                const found = this.konva.rooms
                    .map((r) => r.entities)
                    .flat()
                    .find((e) => e.type === "table" && e.id == selectedTable.serverId);

                if (typeof found !== "undefined") {
                    found.isEnabledForService = false;

                    this.checkTableGroupsEnability(found);
                }

                axios
                    .post(`/api/restaurants/${this.restaurantId}/services/${this.selectedService.id}/tables/${selectedTable.serverId}/disable`, {
                        date: this.date.toISODate(),
                    })
                    .then(() => {
                        this.notifySuccess(null, this.$tl("success.booking.seatingPlan.tables.disabledForService", this.restaurantId));
                    })
                    .catch((error) => {
                        this.notifyError(error);

                        selectedTable.isEnabledForService = true;

                        const found = this.konva.rooms
                            .map((r) => r.entities)
                            .flat()
                            .find((e) => e.type === "table" && e.id == selectedTable.serverId);

                        if (typeof found !== "undefined") {
                            found.isEnabledForService = true;

                            this.checkTableGroupsEnability(found);
                        }
                    });
            }
        },
        changeRoomEnabled(value) {
            this.currentRoom.isEnabledForService = value;

            axios
                .post(
                    `/api/restaurants/${this.restaurantId}/services/${this.selectedService.id}/rooms/${this.currentRoom.serverId}/${
                        value ? "enable" : "disable"
                    }`,
                    {
                        date: this.date.toISODate(),
                    }
                )
                .then(() => {
                    this.notifySuccess(
                        null,
                        this.$tl(`success.booking.seatingPlan.rooms.${value ? "enabled" : "disabled"}ForService`, this.restaurantId)
                    );
                })
                .catch((error) => {
                    this.notifyError(error);
                    this.currentRoom.isEnabledForService = !value;
                });
        },
        checkTableGroupsEnability(tableObject) {
            const groups = this.findGroupsByTableId(tableObject.id);

            groups.forEach((g) => {
                g.isEnabledForService = !g.tables.find((t) => t.isEnabledForService == false);
            });
        },
        onTableUpdated(event) {
            let room = null;

            if (event.table.room) {
                room = this.findRoomById(event.table.room.id);
            }

            const tableObject = this.findTableByServerId(event.table.id, room);
            if (tableObject) {
                tableObject.slots.forEach((s) => {
                    const slotHourStart = this.getSlotStartAt(s);

                    s.reservations_pivot = event.table.reservations_pivot.data.filter((rp) => {
                        const rpDurationArray = rp.duration.split(":");
                        const startAt = this.getDateTime(rp.datetime);
                        const endsAt = startAt.plus({
                            hours: rpDurationArray[0],
                            minutes: rpDurationArray[1],
                        });

                        return slotHourStart >= startAt && slotHourStart < endsAt;
                    });
                });
                tableObject.isEnabledForService = event.table.isEnabledForService;
                this.checkTableGroupsEnability(tableObject);

                this.displayReservationsOnTable(tableObject);
                this.konva.mainStage.batchDraw();
            }
        },
    },
};
</script>
<style>
.tag:not(body).is-rounded {
    background: rgb(var(--red), var(--green), var(--blue));
    --brightness: calc((var(--red) * 299 + var(--green) * 587 + var(--blue) * 114) / 1000);
    --color: calc((var(--brightness) - 128) * -255000);
    color: rgb(var(--color), var(--color), var(--color));
}
</style>
