<template>
    <span>
        <feather v-if="featherBinding !== null" v-bind="featherBinding" v-tooltip="featherBinding.tooltip" />
        <template v-if="showLabel">{{ getClientLoyaltyLevelLabel(clientLoyaltyLevel) }}</template>
    </span>
</template>

<script>
import ClientLoyaltyLevelEnum from "../../../mixins/enums/ClientLoyaltyLevelEnum.js";

export default {
    props: {
        clientLoyaltyLevel: {
            type: Number,
            default: 0,
        },
        showLabel: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [ClientLoyaltyLevelEnum],
    computed: {
        featherBinding() {
            if (this.clientLoyaltyLevel === 0) {
                return null;
            }

            return this.getClientLoyaltyLevelFeatherInfo(this.clientLoyaltyLevel);
        },
    },
};
</script>
