<template>
    <div>
        <tab-bar direction="horizontal" side="top" :entries="tabBarEntries" />
        <router-view />
    </div>
</template>

<script>
import TabBar from "../../components/tabBar/TabBar.vue";
import LexiconEnum from "../../mixins/enums/LexiconEnum.js";

export default {
    name: "Settings",
    mixins: [LexiconEnum],
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        restaurant() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id);
        },
        hasFeatSeatingPlan() {
            return typeof this.restaurant !== "undefined" && this.restaurant.feat_seating_plan;
        },
        tabBarEntries() {
            const entries = [
                {
                    to: { name: "booking.restaurants.settings.settings", param: { restaurant_id: this.restaurant_id } },
                    titleI18n: "labels.routes.booking.config.settings",
                    exactUrlMatching: true,
                    rights: ["booking.widget.*"],
                },
                {
                    to: { name: "booking.restaurants.settings.services", param: { restaurant_id: this.restaurant_id } },
                    titleI18n: "labels.routes.booking.config.services",
                    exactUrlMatching: false,
                    rights: ["booking.service.*", "booking.menu.*"],
                },
            ];

            if (this.hasFeatSeatingPlan) {
                entries.push({
                    to: {
                        name: "booking.restaurants.settings.seatingPlan",
                        params: { restaurant_id: this.restaurant_id },
                    },
                    titleI18n: "labels.routes.booking.config.seatingPlan",
                    exactUrlMatching: false,
                    rights: ["booking.seatingplan.*"],
                });
            }

            entries.push(
                {
                    to: {
                        name: "booking.restaurants.settings.notifications",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    titleI18n: "labels.routes.booking.config.notifications",
                    exactUrlMatching: false,
                    rights: ["booking.notification.*"],
                },
                {
                    to: { name: "booking.restaurants.settings.widget", param: { restaurant_id: this.restaurant_id } },
                    titleI18n: "labels.routes.booking.config.integration",
                    exactUrlMatching: true,
                    rights: ["booking.widget.*"],
                },
                {
                    to: { name: "booking.restaurants.settings.cgv", param: { restaurant_id: this.restaurant_id } },
                    titleI18n: "labels.routes.booking.config.cgu",
                    exactUrlMatching: true,
                },
                {
                    to: {
                        name: "booking.restaurants.settings.input_params",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    titleI18n: "labels.routes.booking.config.inputParams",
                    exactUrlMatching: true,
                },
                {
                    to: {
                        name: "booking.restaurants.settings.interfaces",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    titleI18n: "labels.routes.booking.config.interfaces",
                    exactUrlMatching: true,
                    hide: !this.isNoShow,
                }
            );

            return entries;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
    },
    components: {
        TabBar,
    },
    created() {
        if (this.$route.name === "booking.restaurants.settings") {
            let routeName = "home";

            if (this.rights.includes("booking.widget.read")) {
                routeName = "booking.restaurants.settings.settings";
            } else if (this.rights.includes("booking.service.read")) {
                routeName = "booking.restaurants.settings.services";
            } else if (this.rights.includes("booking.menu.read")) {
                routeName = "booking.restaurants.settings.menus";
            } else if (this.hasFeatSeatingPlan && this.rights.includes("booking.seatingplan.read")) {
                routeName = "booking.restaurants.settings.seatingPlan";
            } else if (this.rights.includes("booking.notification.read")) {
                routeName = "booking.restaurants.settings.notifications";
            } else if (this.rights.includes("booking.widget.read")) {
                routeName = "booking.restaurants.settings.widget";
            }

            if (this.$route.name !== routeName) {
                this.$router.push({ name: routeName });
            }
        }
    },
};
</script>
