export default {
    data() {
        return {
            EXPEDITION_TYPE_EMAIL: {
                value: "email",
                label: "Email",
            },
            EXPEDITION_TYPE_POSTAL_MAIL: {
                value: "postal_mail",
                label: "Courrier",
            },
            EXPEDITION_TYPE_UNKNOWN: {
                value: "unknown",
                label: "--",
            },
        };
    },
    methods: {
        getExpeditionTypeLabel(value) {
            const found = this.ALL_EXPEDITION_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_EXPEDITION_TYPES() {
            return [this.EXPEDITION_TYPE_EMAIL, this.EXPEDITION_TYPE_POSTAL_MAIL, this.EXPEDITION_TYPE_UNKNOWN];
        },
    },
};
