<template>
    <datepicker
        :format="customDateFormater"
        :monday-first="isUserLangFr"
        ref="datePicker"
        :highlighted="highlighted"
        :language="datePickerLanguage"
        v-model="date"
        v-on="filteredListeners"
        v-bind="$attrs">
        <feather
            v-if="showClearButton_ && date !== null"
            class="mr-1 text-danger pointer"
            style="position: absolute; top: 10px; right: 0"
            type="x"
            slot="afterDateInput"
            @click="date = null" />
    </datepicker>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { fr, en } from "vuejs-datepicker/dist/locale";
import LangsEnum from "../../mixins/enums/LangsEnum";
import { DateTime } from "luxon";

export default {
    props: {
        value: {
            type: DateTime,
            default: undefined,
        },
        canBeEmpty: {
            type: Boolean,
            default: false,
        },
        showClearButton: {
            type: Boolean,
            default: false,
        },
        timezone: {
            type: String,
            default: undefined,
        },
        format: {
            default: () => DateTime.DATE_FULL,
        },
    },
    mixins: [LangsEnum],
    methods: {
        getDatePicker() {
            return this.$refs.datePicker;
        },
        customDateFormater(date) {
            const dateTime = DateTime.fromJSDate(date);
            if (typeof this.format === "string") {
                return dateTime.toFormat(this.format);
            }
            if (typeof this.format === "object") {
                return dateTime.toLocaleString(this.format);
            }
            if (typeof this.format === "function") {
                return this.format(dateTime);
            }
            console.error(`Invalid format given in DatePicker::customDateFormater: "${this.format}"`);
            return dateTime.toLocaleString(DateTime.DATE_FULL);
        },
    },
    computed: {
        highlighted() {
            return {
                dates: [this.getDateTime().toJSDateCustom()],
            };
        },
        showClearButton_() {
            return this.showClearButton && this.canBeEmpty;
        },
        filteredListeners() {
            this.$listeners.input = undefined;
            return this.$listeners;
        },
        date: {
            get() {
                if (this.value instanceof DateTime) {
                    return this.value.toJSDateCustom();
                }
                if (this.canBeEmpty) {
                    return null;
                }
                return this.getJSDateFromDateTime();
            },
            set(date) {
                if (date === null) {
                    this.$emit("input", this.canBeEmpty ? null : this.getDateTime());
                } else {
                    this.$emit("input", this.getDateTime(date, false, this.timezone));
                }
            },
        },
        datePickerLanguage() {
            if (this.isUserLangFr) {
                return fr;
            }
            return en;
        },
    },
    components: {
        Datepicker,
    },
    created() {
        // If can not be empty, fire an event on creation to fill value
        if (!this.canBeEmpty && (typeof this.value === "undefined" || this.value === null)) {
            this.$emit("input", this.getDateTime(this.date, false, this.timezone));
        }
    },
};
</script>

<style>
.vdp-datepicker .vdp-datepicker__calendar span.cell.today:not(.highlighted):not(.selected) {
    border: 1px solid;
    border-color: transparent !important;
    background-color: transparent;
}

.vdp-datepicker .vdp-datepicker__calendar .cell.highlighted {
    border: 1px solid;
    border-color: #f3f3f3;
    background-color: #f3f3f3;
}
</style>
