<template>
    <div id="seating-plan-topbar" class="table-plan">
        <div class="w-100 d-inline-flex justify-content-between rooms bg-light">
            <ul class="nav nav-tabs">
                <draggable v-model="sortedRooms" :disabled="!sortable || !has_right_to_update_seatingplan">
                    <transition-group>
                        <template v-for="room in sortedRooms">
                            <li
                                class="nav-item"
                                v-if="!room.closed"
                                :key="room.order"
                                :class="{ active: room === currentRoom, 'list-rooms-view': readOnly }"
                                v-on:click.self="$emit('room-selected', room.baseIndex)">
                                <svg
                                    v-if="sortable"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="css-i6dzq1 feather mr-1">
                                    <line x1="3" y1="9" x2="21" y2="9"></line>
                                    <line x1="3" y1="15" x2="21" y2="15"></line>
                                </svg>
                                {{ room.name }}
                                <button
                                    v-if="!readOnly && has_right_to_update_seatingplan"
                                    class="delete ml-1"
                                    v-on:click.capture="confirmDelete(room.baseIndex)"></button>
                                <template v-if="readOnly">
                                    <feather
                                        v-if="room.enabled && !room.isEnabledForService"
                                        type="minus-circle"
                                        class="text-danger"
                                        v-tooltip="getTooltip($tl('labels.booking.seatingPlan.room.disabledForService', restaurant_id))" />
                                    <feather
                                        v-else-if="!room.enabled && !room.isEnabledForService"
                                        type="minus-circle"
                                        class="text-danger"
                                        v-tooltip="{
                                            content: $t('labels.booking.seatingPlan.room.disabledInConfig'),
                                        }" />
                                    <feather
                                        v-else-if="!room.enabled && room.isEnabledForService"
                                        type="info"
                                        class="text-warning"
                                        v-tooltip="getTooltip($tl('labels.booking.seatingPlan.room.enabledForService', restaurant_id))" />
                                </template>
                            </li>
                        </template>
                    </transition-group>
                </draggable>

                <li
                    class="nav-item"
                    @click="$emit('new-room')"
                    style="border-radius: 0 50px 50px 0"
                    v-if="!readOnly && has_right_to_update_seatingplan"
                    v-tooltip="getTooltip($t('labels.booking.seatingPlan.room.add'))">
                    <feather type="plus" />
                </li>
                <feather
                    v-if="!readOnly"
                    v-tooltip="getTooltipNotice('notices.booking.tablePlan.rooms')"
                    type="info"
                    class="ml-1 pointer tooltip-infos"
                    style="margin-top: 12px" />
            </ul>
            <div class="align-self-center" v-if="!readOnly && has_right_to_update_seatingplan">
                <div
                    v-if="currentRoom"
                    class="d-inline-block align-middle lead-switch pl-2"
                    v-tooltip="getTooltip($t('labels.booking.seatingPlan.room.toggleEnabled'))">
                    <feather
                        v-tooltip="getTooltipNoticeLexicon('notices.booking.tablePlan.disablingRoom', restaurant_id)"
                        type="info"
                        class="mr-1 pointer tooltip-infos" />
                    <input type="checkbox" class="switch align-self-center is-rounded" :checked="currentRoom.enabled" />
                    <label @click="currentRoom.enabled = !currentRoom.enabled"></label>
                </div>
                <button @click="$emit('save')" class="btn btn btn-success" style="border-radius: 50px 0 0 50px">
                    {{ $tl("labels.form.actions.save") }}
                </button>
            </div>
            <div class="align-self-center" v-if="readOnly">
                <div v-if="!$_.isEmpty(selectedTables)">
                    <button
                        v-if="canAddPassingCustomer"
                        class="btn btn-success btn-circle btn-sm mr-1"
                        @click="$emit('add-passing-customer-on-tables')">
                        <feather type="plus" /> {{ $tl("labels.booking.reservations.passingCustomer.add.btn") }}
                    </button>
                    <button class="btn btn-success btn-circle btn-sm mr-1" @click="$emit('add-reservation-on-tables')">
                        <feather type="plus" /> {{ $tl("labels.reservation") }}
                    </button>
                    <label v-if="selectedTables.length === 1" class="mb-0 w-auto container-box mr-2 h-100">
                        <input
                            type="checkbox"
                            value=""
                            v-model="selectedTables[0].isEnabledForService"
                            @click="onSelectedTableEnabledChange($event)" />
                        <span class="checkmark"></span>{{ $tl("labels.booking.seatingPlan.table.enable") }}
                    </label>
                </div>
                <div v-else-if="currentRoom">
                    <label class="mb-0 w-auto container-box mr-2 h-100">
                        <input type="checkbox" :checked="currentRoom.isEnabledForService" @click="onSelectedRoomEnabledChange($event)" />
                        <span class="checkmark"></span>{{ $tl("labels.booking.seatingPlan.room.enable") }}
                    </label>
                </div>
            </div>
        </div>
        <confirmation-modal
            v-if="showConfirmationModal"
            :eventPayload="confirmationModalPayload"
            :content="confirmationModalContent"
            @confirmed="confirmationModalCallback"
            @close="showConfirmationModal = false" />
    </div>
</template>

<script>
import ConfirmationModal from "../Modals/messages/ConfirmationModal.vue";
import draggable from "vuedraggable";

export default {
    name: "SeatingPlanTopbar",
    components: {
        ConfirmationModal,
        draggable,
    },
    props: {
        rooms: {
            type: Array,
            default: () => [],
        },
        currentRoom: {
            type: Object,
            default: () => {},
        },
        readOnly: {
            default: false,
            type: Boolean,
        },
        selectedTables: {
            default: null,
        },
        sortable: {
            default: false,
        },
        canAddPassingCustomer: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showConfirmationModal: false,
            confirmationModalPayload: null,
            confirmationModalContent: "",
            confirmationModalCallback: () => {},
        };
    },
    computed: {
        restaurant_id() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_seatingplan() {
            return this.rights.includes("booking.seatingplan.update");
        },
        sortedRooms: {
            get() {
                return this.rooms
                    .map((r, index) => {
                        r.baseIndex = index;
                        return r;
                    })
                    .sort((a, b) => {
                        if (a.order < b.order) {
                            return -1;
                        }
                        return 1;
                    });
            },
            set(value) {
                for (var index in value) {
                    const val = value[index];
                    const foundIndex = this.rooms.findIndex((r) => r.id === val.id);

                    if (foundIndex > -1) {
                        this.$emit("room-changed", {
                            index: foundIndex,
                            value: {
                                ...this.rooms[foundIndex],
                                order: Number.parseInt(index),
                            },
                        });
                    }
                }
            },
        },
    },
    methods: {
        confirmDelete(index) {
            this.confirmationModalContent = `<p class="text-center">
                    Êtes-vous sûr(e) de vouloir supprimer cette salle ?<br/>
                    <small class="text-muted text-center">
                        Pensez à enregistrer votre plan de salle pour que la suppression soit prise en compte
                    </small>
                </p>`;
            this.confirmationModalPayload = index;
            this.confirmationModalCallback = (indexPayload) => {
                this.showConfirmationModal = false;
                this.$emit("room-deleted", indexPayload);
            };
            this.showConfirmationModal = true;
        },
        onSelectedTableEnabledChange(e) {
            if (e.target.checked) {
                this.$emit("enable-selected-table");
            } else {
                this.$emit("disable-selected-table");
            }
        },
        onSelectedRoomEnabledChange(e) {
            this.$emit("change-room-enabled", e.target.checked);
        },
    },
};
</script>
