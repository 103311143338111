var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "myMemo", staticClass: "div-memo", class: { focus: _vm.focusMemo } },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center justify-content-between" },
        [
          _c(
            "button",
            {
              staticClass: "btn",
              on: {
                click: function ($event) {
                  _vm.focusMemo = !_vm.focusMemo
                },
              },
            },
            [
              _c("feather", {
                attrs: {
                  type: _vm.focusMemo ? "chevron-down" : "chevron-right",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("Editable", {
            ref: "memoTitle",
            attrs: {
              cssClass: "titleMemo",
              tag: "span",
              onkeypress: "return (this.innerText.length <= 40)",
              editable: !_vm.isDeleting,
            },
            on: { blur: _vm.saveMemo },
            model: {
              value: _vm.memo.title,
              callback: function ($$v) {
                _vm.$set(_vm.memo, "title", $$v)
              },
              expression: "memo.title",
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-memo text-white",
              attrs: { disabled: _vm.isDeleting },
              on: { click: _vm.deleteMemo },
            },
            [_c("feather", { attrs: { type: "trash-2" } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Editable", {
        ref: "memoContent",
        attrs: {
          cssClass: "contentMemo",
          tag: "p",
          onkeypress: "return (this.innerText.length <= 500)",
          editable: !_vm.isDeleting,
        },
        on: { blur: _vm.saveMemo },
        model: {
          value: _vm.memo.note,
          callback: function ($$v) {
            _vm.$set(_vm.memo, "note", $$v)
          },
          expression: "memo.note",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }