var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
    _c(
      "div",
      {
        staticClass: "row",
        class: { pointer: _vm.expandable },
        on: { click: _vm.toggleIsExpanded },
      },
      [
        _c("div", { staticClass: "col-12" }, [
          _c("strong", [_vm._v(_vm._s(_vm.$tl("labels.googleReserve.title")))]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isExpanded,
            expression: "isExpanded",
          },
        ],
        staticClass: "mt-3",
      },
      [
        _c("switch-input-component", {
          attrs: {
            disabled: _vm.isDisabled,
            "switch-tooltip": _vm.disabledReasonTooltip,
          },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _vm.forServices
                        ? [
                            _vm._v(
                              _vm._s(
                                _vm.$tl("labels.googleReserve.service.enable")
                              )
                            ),
                          ]
                        : [
                            _vm._v(
                              _vm._s(
                                _vm.$tl("labels.googleReserve.widget.enable")
                              )
                            ),
                          ],
                      _vm._v(" "),
                      _c("feather", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getTooltip(
                              _vm.$t(
                                "infos.booking.googleReserve.forRestaurantsOnly"
                              )
                            ),
                            expression:
                              "getTooltip($t('infos.booking.googleReserve.forRestaurantsOnly'))",
                          },
                        ],
                        staticClass: "ml-1 pointer text-danger",
                        attrs: { type: "alert-triangle" },
                      }),
                      _vm._v(" "),
                      _vm.forWidget
                        ? _c("feather", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.getTooltipNotice(
                                  "notices.googleReserve.enable"
                                ),
                                expression:
                                  "getTooltipNotice('notices.googleReserve.enable')",
                              },
                            ],
                            staticClass: "ml-1 pointer tooltip-infos",
                            attrs: { type: "info" },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.$tl("labels.googleReserve.propagation"))),
                  ]),
                  _c("br"),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$tl("labels.googleReserve.maxPax", null, {
                          pax: _vm.MAX_PAX,
                        })
                      )
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.settings.enabled,
            callback: function ($$v) {
              _vm.$set(_vm.settings, "enabled", $$v)
            },
            expression: "settings.enabled",
          },
        }),
        _vm._v(" "),
        _vm.forWidget
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.settings.enabled,
                    expression: "settings.enabled",
                  },
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-5 col-sm-6" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$tl("labels.googleReserve.texts.title")
                            ) +
                            "\n                    "
                        ),
                        _c("feather", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getTooltipNotice(
                                "notices.googleReserve.texts"
                              ),
                              expression:
                                "getTooltipNotice('notices.googleReserve.texts')",
                            },
                          ],
                          staticClass: "ml-1 pointer tooltip-infos",
                          attrs: { type: "info" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("choose-lang", {
                      staticClass: "col-md-7 col-sm-6",
                      staticStyle: { "z-index": "auto !important" },
                      attrs: {
                        "is-input-mode": "",
                        "should-translate-label": "",
                      },
                      model: {
                        value: _vm.currentTextLocale,
                        callback: function ($$v) {
                          _vm.currentTextLocale = $$v
                        },
                        expression: "currentTextLocale",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("show-errors", {
                  attrs: {
                    errors: _vm.formErrors,
                    "error-key": "google_reserve_texts",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row mt-2" },
                  [
                    _c("div", { staticClass: "col-md-5" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getGrLocalizedTextLabel(
                            _vm.GR_IMPORTANT_NOTE_TEXT.value
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("textarea-component", {
                      staticClass: "col-md-7",
                      attrs: {
                        rows: 3,
                        "max-nb-chars": 300,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.importantNote,
                        callback: function ($$v) {
                          _vm.importantNote = $$v
                        },
                        expression: "importantNote",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }