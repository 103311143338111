export const mutations = {
    setGaEnabled(state, ga_enabled) {
        state.ga_enabled = ga_enabled;
    },
    setWebsiteEnabled(state, website_enabled) {
        state.website_enabled = website_enabled;
    },
    setUnsavedChanges(state, unsaved_changes) {
        state.unsaved_changes = unsaved_changes;
    },
    setAllSettings(state, all_settings) {
        state.all_settings = all_settings;
    },
    setIsPublishing(state, is_publishing) {
        state.is_publishing = is_publishing;
    },
};

export default mutations;
