<template>
    <div class="pb-5">
        <LoaderComponent v-if="nbLoading > 0" />
        <div v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">{{ $tl("labels.booking.reservations.export.title", restaurantId) }}</h5>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <form class="col-12 advance-filter">
                    <div class="m-0" style="color: #666666">
                        <div class="border-light b-radius-20 p-4 mb-3">
                            <div class="row">
                                <div class="col-12 d-inline-block date-resa-cal mb-3">
                                    {{ $tl("labels.filters.dates.from", restaurantId) }}&nbsp;
                                    <DatePicker
                                        class="d-inline-block"
                                        input-class="form-control"
                                        v-model="from"
                                        @selected="
                                            setEndDateFromFromDate();
                                            fetchServices();
                                        " />
                                    &nbsp;{{ $tl("labels.filters.dates.to", restaurantId).toLowerCase() }}&nbsp;
                                    <DatePicker
                                        class="d-inline-block"
                                        input-class="form-control"
                                        :disabledDates="disabledDates"
                                        v-model="to"
                                        @selected="fetchServices" />
                                </div>
                                <div class="col-md-4 mb-2">
                                    <div>
                                        <strong class="mb-3">{{ $tl("labels.filters.statuses", restaurantId) }}</strong>
                                        <label class="container-box ml-2" style="width: auto">
                                            <input type="checkbox" :checked="areAllStatusChecked" @change="toggleAllStatusChecked" />
                                            <span class="checkmark"></span> {{ $tl(areAllStatusChecked ? "labels.uncheckAll" : "labels.checkAll") }}
                                        </label>
                                    </div>
                                    <div v-for="s in ALL_RESERVATION_STATUS_FOR_FILTER" :key="s.value">
                                        <label class="container-box" style="width: auto">
                                            <input type="checkbox" v-model="status" :value="s.value" />
                                            <span class="checkmark"></span> {{ getReservationStatusLabel(s.value, undefined, restaurantId) }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2">
                                    <div>
                                        <strong class="mb-3">{{ $tl("labels.services", restaurantId) }}</strong>
                                        <label class="container-box ml-2" style="width: auto">
                                            <input type="checkbox" :checked="areAllServicesChecked" @change="toggleAllServicesChecked" />
                                            <span class="checkmark"></span> {{ $tl(areAllServicesChecked ? "labels.uncheckAll" : "labels.checkAll") }}
                                        </label>
                                    </div>
                                    <div v-for="s in servicesForFilter" :key="s.id">
                                        <label class="container-box" style="width: auto">
                                            <input type="checkbox" v-model="services" :value="s.id" />
                                            <span class="checkmark"></span> {{ s.name }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-2">
                                    <div>
                                        <strong class="mb-3">{{ $tl("labels.filters.columns", restaurantId) }}</strong>
                                        <label class="container-box ml-2" style="width: auto">
                                            <input type="checkbox" :checked="areAllColumnsChecked" @change="toggleAllColumnsChecked" />
                                            <span class="checkmark"></span> {{ $tl(areAllColumnsChecked ? "labels.uncheckAll" : "labels.checkAll") }}
                                        </label>
                                    </div>
                                    <div v-for="c in FILTERED_RESERVATION_COLUMNS_FOR_EXPORT" :key="c.value">
                                        <label class="container-box" style="width: auto">
                                            <input type="checkbox" v-model="columns" :value="c.value" />
                                            <span class="checkmark"></span>
                                            {{ getReservationColumnForExportLabel(c.value, restaurantId) }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button type="button" @click.prevent="exportReservationsExcel" class="btn btn-sm btn-success btn-circle ml-2">
                                        <feather type="download-cloud" />
                                        {{ $tl("labels.form.actions.export", restaurantId) }} (Excel)
                                    </button>
                                    <button type="button" @click.prevent="printReservations" class="btn btn-sm btn-success btn-circle ml-2">
                                        <feather type="printer" />
                                        {{ $tl("labels.form.actions.print", restaurantId) }} (PDF)
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import DatePicker from "../../components/forms/DatePicker.vue";
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum.js";
import ReservationColumnsForExportEnum from "../../mixins/enums/booking/ReservationColumnsForExportEnum.js";

export default {
    name: "ExportReservations",
    data() {
        return {
            nbLoading: 0,
            from: this.$route.query.date ? this.getDateTime(this.$route.query.date, false) : this.getDateTime(),
            to: this.$route.query.date ? this.getDateTime(this.$route.query.date, false) : this.getDateTime(),
            status: [],
            services: [],
            servicesForFilter: [],
            servicesFromSavedFilters: [],
            columns: [],
        };
    },
    mixins: [ReservationStatusEnum, ReservationColumnsForExportEnum],
    computed: {
        areAllStatusChecked() {
            return this.ALL_RESERVATION_STATUS_FOR_FILTER.length === this.status.length;
        },
        areAllServicesChecked() {
            return this.servicesForFilter.length === this.services.length;
        },
        areAllColumnsChecked() {
            return this.FILTERED_RESERVATION_COLUMNS_FOR_EXPORT.length === this.columns.length;
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        disabledDates() {
            if (this.from) {
                return {
                    to: this.from.minus({ days: 1 }).toJSDateCustom(),
                };
            }
            return {};
        },
        ALL_RESERVATION_STATUS_FOR_FILTER() {
            return this.ALL_RESERVATION_STATUS.filter((s) => s.value !== this.RESERVATION_STATUS_PENDING.value);
        },
        FILTERED_RESERVATION_COLUMNS_FOR_EXPORT() {
            return this.getFilteredReservationColumnsForExport({ addRoomNumber: this.shouldAddRoomNumber });
        },
        shouldAddRoomNumber() {
            return this.isFeatRoomNumbersEnable || this.isHotelModeEnabled;
        },
        isFeatRoomNumbersEnable() {
            const restaurant = this.$store.getters["restaurants/findRestaurantById"](this.restaurantId);

            return restaurant ? restaurant.feat_room_numbers : false;
        },
        isHotelModeEnabled() {
            return this.$store.getters["widgets/getWidget"].hotel_mode;
        },
    },
    methods: {
        toggleAllStatusChecked() {
            if (this.areAllStatusChecked) {
                this.status = [];
            } else {
                this.status = this.arrayPluck("value", this.ALL_RESERVATION_STATUS_FOR_FILTER);
            }
        },
        toggleAllServicesChecked() {
            if (this.areAllServicesChecked) {
                this.services = [];
            } else {
                this.services = this.arrayPluck("id", this.servicesForFilter);
            }
        },
        toggleAllColumnsChecked() {
            if (this.areAllColumnsChecked) {
                this.columns = [];
            } else {
                this.columns = this.arrayPluck("value", this.FILTERED_RESERVATION_COLUMNS_FOR_EXPORT);
            }
        },
        setEndDateFromFromDate() {
            this.$nextTick(() => {
                if (this.from > this.to) {
                    this.to = this.from;
                }
            });
        },
        validateFilters(col_limit = undefined) {
            let errorMsgs = [];

            if (this.from && this.to && this.from > this.to) {
                errorMsgs.push(this.$tl("errors.common.dates.endGreaterThanStart", this.restaurantId));
            }

            if (this.status.length === 0) {
                errorMsgs.push(this.$tl("errors.booking.reservations.export.atLeastOneStatus", this.restaurantId));
            }

            if (this.services.length === 0) {
                errorMsgs.push(this.$tl("errors.booking.reservations.export.atLeastOneService", this.restaurantId));
            }

            if (this.columns.length === 0) {
                errorMsgs.push(this.$tl("errors.booking.reservations.export.atLeastOneColumn", this.restaurantId));
            } else if (typeof col_limit != "undefined" && this.columns.length > col_limit) {
                errorMsgs.push(
                    this.$t("errors.booking.reservations.export.maxColumns", {
                        limit: col_limit,
                        nb: this.columns.length,
                    })
                );
            }

            if (errorMsgs.length > 0) {
                this.notifyError(null, errorMsgs.join("\n"));

                return false;
            }

            return true;
        },
        getURLParams() {
            return (
                `&from=${this.from.toISODate()}&to=${this.to.toISODate()}&status=${this.status.join("-")}` +
                `&services=${this.services.join("-")}&columns=${this.columns.join("-")}`
            );
        },
        exportReservationsExcel() {
            if (!this.validateFilters()) {
                return;
            }

            let urlParams = this.getURLParams();

            if (confirm(this.$tl("questions.exports.saveFilters", this.restaurantId))) {
                urlParams += "&persist_filters=1";
            }

            window.open(this.addLangToURL(`/api/restaurants/${this.restaurantId}/reservations/export?${urlParams}`), "_blank");
        },
        printReservations() {
            if (!this.validateFilters(10)) {
                return;
            }

            let urlParams = this.getURLParams();

            if (confirm(this.$tl("questions.exports.saveFilters", this.restaurantId))) {
                urlParams += "&persist_filters=1";
            }

            window.open(this.addLangToURL(`/api/restaurants/${this.restaurantId}/reservations/print?${urlParams}`), "_blank");
        },
        initData() {
            this.nbLoading++;

            this.fetchFiltersFromApi().finally(() => {
                this.fetchServices().finally(() => this.nbLoading--);
            });
        },
        fetchServices() {
            this.nbLoading++;

            return new Promise((resolve) => {
                this.$nextTick(() => {
                    this.httpGet(`/api/restaurants/${this.restaurantId}/services/openOnDates?from=${this.from.toISODate()}&to=${this.to.toISODate()}`)
                        .then((response) => {
                            if (response !== false) {
                                this.servicesForFilter = response.data.data;

                                if (this.servicesFromSavedFilters.length > 0) {
                                    this.services = this.arrayPluck(
                                        "id",
                                        this.servicesForFilter.filter((service) => this.servicesFromSavedFilters.includes(service.id))
                                    );
                                } else {
                                    this.services = this.arrayPluck("id", this.servicesForFilter);
                                }
                            }
                        })
                        .finally(() => {
                            this.nbLoading--;
                            resolve();
                        });
                });
            });
        },
        fetchFiltersFromApi() {
            this.nbLoading++;

            return this.httpGet(`/api/exports/filters?export_class=App\\Exports\\ReservationExport`, { handleReject: false })
                .then((response) => {
                    if (response !== false) {
                        if (response.data.export_filters) {
                            this.syncFiltersFromApi(response.data.export_filters);
                        }
                    }
                })
                .finally(() => this.nbLoading--);
        },
        syncFiltersFromApi(filters) {
            if (filters.status) {
                this.status = filters.status;
            }

            if (filters.services) {
                this.services = filters.services;
                this.servicesFromSavedFilters = filters.services;
            }

            if (filters.columns) {
                this.columns = filters.columns;
            }
        },
        initColumns() {
            this.columns = this.arrayPluck("value", this.FILTERED_RESERVATION_COLUMNS_FOR_EXPORT);
        },
    },
    watch: {
        shouldAddRoomNumber() {
            this.initColumns();
        },
    },
    components: {
        LoaderComponent,
        DatePicker,
    },
    created() {
        this.initColumns();

        this.status = this.arrayPluck("value", this.ALL_RESERVATION_STATUS_FOR_FILTER);

        this.initData();
    },
};
</script>
