export const getters = {
    getActivated(state) {
        return state.activated;
    },
    isBuyingPack(state) {
        return state.is_buying_pack;
    },
    get(state) {
        return { ...state };
    },
};

export default getters;
