<template>
    <div class="pr-0 pb-5">
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div class="row m-0">
                    <div class="col-12">
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                            <div v-if="closure_id">
                                <h5 class="title mt-2">
                                    {{ $tl("labels.booking.closures.edit") }} <span class="value">{{ name }}</span>
                                </h5>
                            </div>
                            <div v-else>
                                <h5 class="title mt-2">{{ $tl("labels.booking.closures.addLong") }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <ShowErrors :errors="formErrors" errClass="d-block alert alert-danger" errorKey="closure_id" />
                <div v-if="formSuccess" class="alert alert-success">
                    {{ formSuccess }}
                </div>

                <LoaderComponent v-if="formLoading" />
                <form v-else id="addClosure" @submit="postForm" method="post">
                    <div class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <div class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label for="name">{{ $tl("labels.form.name") }} <small>*</small></label>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" name="name" v-model="name" id="name" maxlength="190" required class="form-control" />
                                        <ShowErrors :errors="formErrors" class="d-block" errorKey="name" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label>{{ $tl("labels.startDate") }} <small>*</small></label>
                                    </div>
                                    <div class="col-7 date-resa-cal">
                                        <DatePicker
                                            :disabled="loadingService"
                                            :disabledDates="disabledDates"
                                            v-model="date_begin"
                                            @selected="verifDatesBegin" />
                                        <ShowErrors :errors="formErrors" class="d-block" errorKey="date_begin" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label>{{ $tl("labels.endDate") }} <small>*</small></label>
                                    </div>
                                    <div class="col-7 date-resa-cal">
                                        <DatePicker
                                            :disabled="loadingService"
                                            :disabledDates="disabledDates"
                                            v-model="date_end"
                                            @selected="verifDatesEnd" />
                                        <ShowErrors :errors="formErrors" class="d-block" errorKey="date_end" />
                                    </div>
                                </div>
                                <div class="row mb-2 pt-3">
                                    <div class="col-5">
                                        <label>
                                            {{ $tl("labels.booking.closures.concernedServices", $route.params.restaurant_id) }} <small>*</small>
                                            <feather
                                                v-tooltip="
                                                    getTooltipNoticeLexicon(
                                                        'notices.booking.servicesConfig.exceptionalClosureConcernedServices',
                                                        $route.params.restaurant_id
                                                    )
                                                "
                                                type="info"
                                                class="ml-1 pointer tooltip-infos" />
                                        </label>
                                    </div>
                                    <LoaderComponent v-if="loadingService" />
                                    <div v-else class="col-7">
                                        <div v-if="services_available.length > 0">
                                            <div v-for="service in services_available" :key="service.id">
                                                <label class="container-box" style="width: 100%; text-transform: capitalize">
                                                    <input type="checkbox" name="services[]" v-model="services" :value="service.id" />
                                                    <span class="checkmark"></span>
                                                    {{ getServiceCategoryLabel(service) }} - {{ service.name }}
                                                </label>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <em>{{ $tl("labels.booking.closures.noServiceForDate", $route.params.restaurant_id) }}</em>
                                        </div>
                                        <ShowErrors :errors="formErrors" class="d-block" errorKey="services" />
                                        <ShowErrors :errors="formErrors" class="d-block" errorKey="services.*" />
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <input
                                            type="submit"
                                            name="submit"
                                            :value="$t('labels.form.actions.save')"
                                            class="btn btn-success btn-circle" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <confirm-closure-create-modal
            v-if="showConfirmModal"
            @close="showConfirmModal = false"
            @confirmed="sendForm()"
            :formErrors="formErrors"
            :formLoading="formLoading"
            :date_begin="date_begin"
            :date_end="date_end"
            :servicesId="showConfirmModalServicesId"
            :cancelData="confirmModalData" />
    </div>
</template>

<script>
import axios from "axios";
import DatePicker from "../../../../components/forms/DatePicker.vue";
import ConfirmClosureCreateModal from "../../../../components/Modals/ConfirmClosureCreateModal.vue";
import LoaderComponent from "../../../../components/LoaderComponent";
import ShowErrors from "../../../../components/errors/ShowErrors";
import LangsEnum from "../../../../mixins/enums/LangsEnum";
import { DateTime } from "luxon";

export default {
    data() {
        return {
            loading: false,
            formLoading: false,
            loadingService: false,
            error: null,
            formErrors: null,
            formSuccess: null,
            closure_id: null,
            name: null,
            date_begin_: null,
            date_end_: null,
            services: [],
            services_available: [],
            restaurant: {},
            disabledDates: {
                to: null,
            },
            showConfirmModal: false,
            showConfirmModalServicesId: "",
            confirmModalData: {
                message_object: this.$tl("labels.booking.reservations.defaultMailObj"),
                message_body: "",
                cancel_enabled: false,
                reservations_ids: "",
            },
        };
    },
    mixins: [LangsEnum],
    computed: {
        date_begin: {
            get() {
                return this.date_begin_ || this.getDateTime();
            },
            set(date) {
                this.date_begin_ = this.getDateTime(date, false);
            },
        },
        date_end: {
            get() {
                return this.date_end_ || this.getDateTime();
            },
            set(date) {
                this.date_end_ = this.getDateTime(date, false);
            },
        },
    },
    methods: {
        initData(changing_closure_id) {
            this.error = this.restaurant = null;

            this.closure_id = this.$route.params.closure_id;

            this.disabledDates.to = this.getDateTime().minus({ days: 1 }).toJSDateCustom();

            if (this.closure_id) {
                this.loading = true;

                axios
                    .get(`/api/restaurants/${this.$route.params.restaurant_id}/closures/${this.closure_id}`)
                    .then((response) => {
                        this.loading = false;
                        this.name = response.data.name;
                        this.date_begin = this.getDateTime(response.data.datetime_begin);
                        this.date_end = this.getDateTime(response.data.datetime_end);
                        this.confirmModalData.message_body = this.message;
                        let raw_services = response.data.services;
                        this.cleanServices(raw_services);

                        this.loadServices();
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.error = this.getErrorMsgFromErrorResponse(error);
                    });
            } else {
                this.loadServices();
                if (changing_closure_id) {
                    this.emptyForm();
                }
            }
        },
        loadServices() {
            this.loadingService = true;

            axios
                .get(
                    `/api/restaurants/${
                        this.$route.params.restaurant_id
                    }/services/openOnDates?from=${this.date_begin.toISODate()}&to=${this.date_end.toISODate()}`
                )
                .then((response) => {
                    this.services_available = response.data.data;
                    if (!this.closure_id) {
                        this.services = response.data.data.map((s) => s.id);
                    }
                    this.loadingService = false;
                })
                .catch((error) => {
                    this.loadingService = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        postForm(e) {
            e.preventDefault();
            this.formSuccess = this.formErrors = null;
            const servicesId = this.services.join(",");

            this.sendForm(true).then((ret) => {
                if (ret) {
                    axios
                        .get(
                            `/api/restaurants/${
                                this.$route.params.restaurant_id
                            }/reservations/filter?begin=${this.date_begin.toISODate()}&end=${this.date_end.toISODate()}&services=${servicesId}&excluded_status=pending-canceled-refused-noshow`
                        )
                        .then((response) => {
                            if (response.data.length > 0) {
                                this.showConfirmModalServicesId = servicesId;
                                this.showConfirmModal = true;
                                this.formLoading = false;
                            } else {
                                this.sendForm();
                            }
                        });
                }
            });
        },
        getFormattedData() {
            return {
                name: this.name,
                date_begin: this.date_begin.toISODate(),
                date_end: this.date_end.toISODate(),
                services: this.services,
                cancel_enabled: this.confirmModalData.cancel_enabled,
                message_object: this.confirmModalData.message_object,
                message_body: this.confirmModalData.message_body,
                reservations_ids: this.confirmModalData.reservations_ids,
            };
        },
        sendForm(controlOnly = false) {
            this.formLoading = true;
            let data = this.getFormattedData();
            data.control_only = controlOnly;

            if (this.closure_id) var promise = axios.put(`/api/restaurants/${this.$route.params.restaurant_id}/closures/${this.closure_id}`, data);
            else var promise = axios.post(`/api/restaurants/${this.$route.params.restaurant_id}/closures`, data);
            return promise
                .then((response) => {
                    if (controlOnly) return true;
                    this.formLoading = false;
                    this.showConfirmModal = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    if (this.closure_id) this.initData();
                    else {
                        this.emptyForm();
                        this.$router.push({
                            name: "booking.restaurants.settings.closures",
                            params: {
                                restaurant_id: this.$route.params.restaurant_id,
                            },
                        });
                    }
                })
                .catch((error) => {
                    this.formErrors = error.response ? error.response.data.errors : [this.getErrorMsgFromErrorResponse(error)];
                    this.formLoading = false;
                    if (controlOnly) return false;
                });
        },
        emptyForm() {
            this.name = null;
            this.date_begin = null;
            this.date_end = null;
            this.services = [];
        },
        cleanServices(services) {
            let arrayServices = [];
            for (var i = 0; i < services.length; i++) {
                arrayServices.push(services[i].id);
            }
            this.services = arrayServices;
        },
        verifDatesBegin(date) {
            if (this.getDateTime(date, false) > this.date_end) {
                this.date_end = date;
            }
            this.$nextTick(() => this.loadServices());
        },
        verifDatesEnd(date) {
            if (this.getDateTime(date, false) < this.date_begin) {
                this.date_begin = date;
            }
            this.$nextTick(() => this.loadServices());
        },
    },
    created() {
        this.initData();
    },
    components: {
        DatePicker,
        ConfirmClosureCreateModal,
        LoaderComponent,
        ShowErrors,
    },
    watch: {
        "$route.params.restaurant_id": function (id) {
            this.initData();
        },
        "$route.params.closure_id": function (id) {
            this.initData(true);
        },
    },
};
</script>
