var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Conditions particulières du module " + _vm._s(_vm.module.label)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.loading
          ? _c("LoaderComponent")
          : [
              _c("p", [
                _vm._v(
                  "\n                Pour accéder au module " +
                    _vm._s(_vm.module.label) +
                    ", vous devez accepter ses conditions particulières\n                d'utilisation.\n            "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row m-0 mt-2" }, [
                _c("label", { staticClass: "container-box w-100" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.accepted,
                        expression: "accepted",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.accepted)
                        ? _vm._i(_vm.accepted, null) > -1
                        : _vm.accepted,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.accepted,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.accepted = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.accepted = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.accepted = $$c
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                  _vm._v(
                    "\n                    Je déclare avoir pris connaissance des\n                    "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "alert-link",
                      attrs: {
                        href: `/cgu_${_vm.module.urlValue}.pdf?v=${_vm.$__BUILD_ID__}`,
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        conditions particulières d'utilisation du module " +
                          _vm._s(_vm.module.label) +
                          "\n                    "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            attrs: { disabled: _vm.loading },
            on: { click: _vm.goBack },
          },
          [_vm._v("\n            Retour\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-success btn-sm btn-circle ml-2",
            attrs: { disabled: !_vm.accepted || _vm.loading },
            on: { click: _vm.acceptCgv },
          },
          [_vm._v("\n            Valider\n        ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }