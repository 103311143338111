import axios from "axios";

function getQueryParameter(param, url = null) {
    if (url === null) url = location.href.replace(/#+.*$/, "").split("?");
    else url = url.replace(/#+$/, "").split("?");
    if (url.length < 2) return null;
    let params = url[1].split("&");
    for (let i = 0; i < params.length; i++) {
        let keyValue = params[i].split("=");
        if (keyValue[0] === param) return keyValue.length > 1 ? decodeURIComponent(keyValue[1]) : null;
    }
    return null;
}

export const actions = {
    fetchCurrentUser({ dispatch }, { set = false } = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get("/api/users/current?include=new_features,new_badges")
                .then((response) => {
                    if (set) {
                        dispatch("setUser", response.data);
                    }

                    // Ternary for legacy code
                    resolve(set ? response.data : response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchOwnerAlreadyTriedModules({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/api/users/modules/tried")
                .then((response) => {
                    commit("setOwnerAlreadyTriedModules", response.data);

                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchHasUnreadAppUpdates({ commit }, { mustReject = false } = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get("/api/app_updates/hasReadAll")
                .then((response) => {
                    commit("setHasUnreadAppUpdates", !response.data.result);

                    resolve(response);
                })
                .catch((error) => {
                    if (mustReject) {
                        reject(error);
                    } else {
                        console.error(error);

                        resolve(false);
                    }
                });
        });
    },
    markAllAppUpdatesAsRead({ commit }, { mustReject = false } = {}) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/app_updates/markAllAsRead")
                .then((response) => {
                    commit("setHasUnreadAppUpdates", false);

                    resolve(response);
                })
                .catch((error) => {
                    if (mustReject) {
                        reject(error);
                    } else {
                        console.error(error);

                        resolve(false);
                    }
                });
        });
    },
    fetchUnseenFailedMailsNotificationsLogs({ commit }) {
        return new Promise((resolve) => {
            axios
                .get("/api/notificationsMailLogs/failed/getUnseen")
                .then((response) => commit("setUnseenFailedMailsNotificationsLogs", response.data))
                .catch((error) => console.error(error))
                .finally(() => resolve(true));
        });
    },
    markFailedMailsNotificationsLogsAsSeen({ commit }, { restaurantId, moduleType }) {
        return new Promise((resolve) => {
            axios
                .post(`/api/notificationsMailLogs/failed/markAsSeen/${restaurantId}/${moduleType}`)
                .then(() => commit("markFailedMailsNotificationsLogsAsSeen", { restaurantId, moduleType }))
                .catch((error) => console.error(error))
                .finally(() => resolve(true));
        });
    },
    setNewBadges({ commit }, newBadges) {
        commit("setNewBadges", newBadges);
    },
    setLang({ commit }, newLang) {
        commit("setLang", newLang);
    },
    setUser({ commit }, user) {
        commit("setId", user.id);
        commit("setFirstName", user.firstname);
        commit("setLastName", user.lastname);
        commit("setEmail", user.email);
        commit("setTel", user.tel);
        commit("setTelCountry", user.tel_country);
        commit("setTelIsMobile", user.tel_is_mobile);
        commit("setLang", getQueryParameter("lang") || user.lang);
        commit("setAddress", user.address);
        commit("setZipCode", user.zipcode);
        commit("setCity", user.city);
        commit("setCountry", user.country);
        commit("setRole", user.role);
        commit("setOwnerId", user.owner_id);
        if (user.rights_merged) {
            commit("setRights", user.rights_merged.data);
        }
        if (user.restaurant_ids) {
            commit("setRestaurantIds", user.restaurant_ids);
        }
        commit("setCompany", user.company);
        commit("setCompanyType", user.company_type);
        commit("setCompanyTel", user.company_tel);
        commit("setCompanyEmail", user.company_email);
        commit("setCompanySiret", user.company_siret);
        commit("setCompanyCodeNaf", user.company_code_naf);
        commit("setCompanyCodeTva", user.company_code_tva);
        commit("setCompanyAddress", user.company_address);
        commit("setCompanyZipcode", user.company_zipcode);
        commit("setCompanyCity", user.company_city);
        commit("setCompanyCountry", user.company_country);
        commit("setOwnerSubscriptions", user.ownerSubscriptions);
        commit("setEnableNotificationRingtone", user.enable_notification_ringtone);
        commit("setKeepClientsOnNewsletterFor", user.keep_clients_on_newsletter_for);
        commit("setKeepClientsDetailsAndHistoryFor", user.keep_clients_details_and_history_for);
        commit("setPasswordLastChanged", user.password_last_changed);
        commit("setIsYproxUser", user.is_yprox_user);
        commit("setHasManualBilling", user.has_manual_billing);
        commit("setUnreadSeatingPlanNotifications", user.unread_seating_plan_notifications);
    },
};

export default actions;
