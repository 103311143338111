<template>
    <div class="h-100 m-0 p-0 d-flex flex-column table-plan">
        <ul class="nav nav-tabs nav-service" style="display: -webkit-box; margin-top: -10px">
            <li class="nav-item" v-for="(service, index) in filteredServices" :key="index" @click="selectedServiceIndex = service.originalIndex">
                <a class="nav-link" :class="{ active: service.originalIndex === selectedServiceIndex }" href="#">
                    {{ service.name }} - {{ getServiceCategoryLabel(service) }}
                    {{ service.special === 1 ? ` (${$tl("labels.booking.services.special.title")}) ` : "" }}
                </a>
            </li>
        </ul>
        <loaderComponent v-show="isLoading" />
        <span v-if="!isLoading && selectedService" class="capacite badge badge-secondary count-resa">
            {{ selectedService.pax }} / {{ selectedService.new_pax !== null ? selectedService.new_pax : selectedService.max_pax }}
        </span>
        <div v-if="!isLoading && selectedService && selectedService.seatingPlan" class="h-100 w-100">
            <ns-timeline
                :key="selectedService.id"
                :data-source="timelineDataSource"
                :events="timelineEvents"
                :start-hour="selectedService.hour_begin"
                :end-hour="selectedService.hour_end"
                :current-date="reservation_date"
                :cell-width="cellWidth"
                @event-clicked="onEventClicked">
                <template v-slot:cell-collapse-name="{ data }">
                    {{ data.name }}
                </template>

                <template v-slot:cell-row-name="{ data }">
                    {{ $tl("labels.booking.seatingPlan.table.title") }} {{ data.name }} |
                    <span class="text-muted">({{ data.enabledPax.join(", ") }})</span>
                </template>

                <template v-slot:cell-body="{ hour, data, uniqId }">
                    <button
                        v-if="canAddReservation(hour.toFormat('HH:mm'), data)"
                        type="button"
                        class="btn p-0"
                        @click="openDropdown(getCellCoordinates(uniqId, hour))">
                        <feather class="feather-blue" type="plus-circle" />

                        <Dropdown
                            v-if="visibledDropdowns[getCellCoordinates(uniqId, hour)]"
                            :items="dropdownItems"
                            @selected="onDropdownOptionSelected($event, hour, data)"
                            @close="closeDropdown(getCellCoordinates(uniqId, hour))" />
                    </button>
                </template>
            </ns-timeline>
        </div>
        <no-opened-services v-else-if="allServicesClosedAndNoResa" is-from-closure :closure-name="closureName" />
        <NoSeatingPlan
            v-else-if="!isLoading && selectedService && !selectedService.seatingPlan"
            class="mt-3"
            :service="selectedService"
            @close="$emit('go-to-resa-list')" />
        <show-reservation-modal
            v-if="showReservationModal"
            :reservation_id="selected_reservation_id"
            :restaurant_id="selected_restaurant_id"
            @resa-edit="displayReservationEdit"
            @close="showReservationModal = false" />
        <edit-reservation-modal
            v-if="showEditReservationModal"
            :reservation_id="selected_reservation_id"
            :restaurant_id="selected_restaurant_id"
            @reservation-edited="
                showEditReservationModal = false;
                fetchData();
            "
            @close="showEditReservationModal = false" />
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import SeatingPlanTopbar from "../seatingPlan/SeatingPlanTopbar.vue";
import ShowReservationModal from "../Modals/reservations/ShowReservationModal.vue";
import EditReservationModal from "../Modals/reservations/EditReservationModal.vue";
import LangsEnum from "../../mixins/enums/LangsEnum.js";
import NoSeatingPlan from "../seatingPlan/NoSeatingPlan.vue";
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum.js";
import NsTimeline from "../Timeline/nsTimeline.vue";
import { DateTime } from "luxon";
import Dropdown from "../forms/DropdownLight.vue";
import ServicesUtils from "../../mixins/helpers/booking/ServicesUtils.js";
import NoOpenedServices from '../Booking/NoOpenedServices.vue';

const defaultData = {
    isLoading: false,
    selectedServiceIndex: 0,
    selectedService: null,
    seatingPlan: {
        rooms: [],
    },
    sockets: {
        listeningChannels: [],
    },
    showReservationModal: false,
    showEditReservationModal: false,
    selected_restaurant_id: null,
    selected_reservation_id: null,
};

export default {
    name: "TimelinePerService",
    components: {
        EditReservationModal,
        ShowReservationModal,
        LoaderComponent,
        SeatingPlanTopbar,
        NoSeatingPlan,
        NsTimeline,
        Dropdown,
        NoOpenedServices,
    },
    data() {
        return {
            ...this.$_.cloneDeep(defaultData),
            visibledDropdowns: {},
        };
    },
    mixins: [LangsEnum, ReservationStatusEnum, ServicesUtils],
    props: {
        services: {
            type: Array,
            required: true,
        },
        restaurant: {
            type: Object,
            required: true,
        },
        reservation_date: {
            type: DateTime,
            required: true,
        },
        allServicesClosedAndNoResa: {
            type: Boolean,
            default: false,
        },
        closureName: {
            type: String,
            default: "",
        }
    },
    computed: {
        dropdownItems() {
            const items = [
                {
                    label: this.$tl("labels.booking.reservations.standardReservation"),
                    action: "addStandardReservation",
                },
            ];

            if (this.isSelectedServiceALiveService) {
                items.push({
                    label: this.$tl("labels.booking.reservations.passingCustomer.dropdown"),
                    action: "addPassingCustomer",
                });
            }

            return items;
        },
        isSelectedServiceALiveService() {
            if (!this.selectedService) {
                return false;
            }

            return this.isServiceLive(this.selectedService, this.reservation_date);
        },
        restaurantId() {
            return this.$route.params.restaurant_id;
        },
        cellWidth() {
            return this.isUserLangEn ? 72 : 50;
        },
        filteredServices() {
            const openings = this.services.filter((s) => s.special);

            const services = this.services.map((service, originalIndex) => {
                return {
                    ...service,
                    originalIndex,
                };
            });

            return services.filter((service) => {
                if (service.special) {
                    return true;
                }

                if (service.is_closed && service.reservations.length == 0) {
                    return false;
                }

                const isOverride = openings.some((opening) => opening.category === service.category);

                if (isOverride) {
                    return service.reservations.length > 0;
                }

                return true;
            });
        },
        timelineDataSource() {
            const dataSource = [];

            this.seatingPlan.rooms.forEach((room) => {
                if (room.enabled) {
                    const uniqId = `room-${room.serverId}`;
                    const tables = [];

                    room.tables.forEach((table) => {
                        if (table.enabled && table.seats) {
                            tables.push({
                                uniqId: `table-${table.serverId}`,
                                collapseBy: uniqId,
                                data: {
                                    ...table,
                                    id: table.serverId,
                                },
                            });
                        }
                    });

                    if (tables.length > 0) {
                        dataSource.push(
                            {
                                uniqId,
                                collapse: true,
                                data: {
                                    ...room,
                                    id: room.serverId,
                                },
                            },
                            ...tables
                        );
                    }
                }
            });

            return dataSource;
        },
        timelineEvents() {
            const events = [];

            this.selectedService.reservationsPivot.data.forEach((rp, index) => {
                const hasReservation = rp.reservation_id !== null;

                const event = {
                    uniqId: `${hasReservation ? rp.reservation_id : "empty"}-${index}`,
                    hasReservation,
                    isPassingCustomer: rp.reservation.is_passing_customer,
                    clickable: hasReservation && !rp.reservation.is_passing_customer,
                    reservationId: rp.reservation_id,
                    name: this.getReservationPivotLabel(rp),
                    startsAt: this.getReservationPivotStartAt(rp),
                    endsAt: this.getReservationPivotEndAt(rp),
                    belongsTo: hasReservation ? rp.reservation.tables.data.map((t) => `table-${t.id}`) : `table-${rp.seating_plan_table_id}`,
                    style: {
                        color: "white",
                        "background-color": "red",
                    },
                };

                if (hasReservation) {
                    event.style["background-color"] = rp.reservation.color;
                    event.style.color = "black";
                }

                if (events.findIndex((e) => e.uniqId === event.uniqId) === -1) {
                    events.push(event);
                }
            });

            return events;
        },
    },
    methods: {
        fetchData(setLoading = true) {
            if (this.filteredServices.length < 1) {
                return;
            }

            const url =
                `/api/restaurants/${this.restaurantId}/services/${this.services[this.selectedServiceIndex].id}` +
                `?date=${this.reservation_date.toISODate()}&include=reservationsPivot,reservationsPivot.reservation,reservationsPivot.reservation.tables` +
                `,reservationsPivot.reservation.client,reservationsPivot.slot,reservationsPivot.reservation.slot,reservationsPivot.reservation.tables.room,seatingPlan,slots` +
                ",seatingPlan.rooms,seatingPlan.rooms.tables,reservationsPivot.table,reservationsPivot.table.room";

            if (setLoading) {
                this.isLoading = true;
            }

            this.httpGet(url, { handleReject: false, mustReject: true })
                .then((response) => {
                    Object.assign(this.$data, {
                        ...this.$_.cloneDeep(defaultData),
                        selectedServiceIndex: this.selectedServiceIndex,
                    });

                    this.selectedService = response.data;

                    if (this.selectedService.seatingPlan) {
                        this.registerToSockets();

                        this.selectedService.seatingPlan.rooms.data.forEach((room) => {
                            if (room.enabled === 1) {
                                this.seatingPlan.rooms.push(this.createNewRoom(room));
                            }
                        });
                    }

                    if (setLoading) {
                        this.$nextTick(() => {
                            this.isLoading = false;
                        });
                    }
                })
                .catch(() => {
                    if (setLoading) {
                        this.isLoading = false;
                    }
                });
        },
        createNewRoom(baseRoom) {
            const room = {
                serverId: baseRoom.id,
                tables: [],
                name: baseRoom.name,
                enabled: baseRoom.enabled === 1,
            };

            baseRoom.tables.data.forEach((baseTable) => {
                if (baseTable.enabled === 1) {
                    room.tables.push(this.createNewTable(baseTable));
                }
            });

            return room;
        },
        createNewTable(baseTable) {
            const table = {
                seats: baseTable.seats,
                serverId: baseTable.id,
                name: baseTable.name,
                enabledPax: baseTable.enabledPax,
                enabled: baseTable.enabled,
            };

            return table;
        },
        getClientName(client, isPassingCustomer) {
            return isPassingCustomer
                ? this.$tl("labels.booking.reservations.passingCustomer.titleShort", this.restaurantId)
                : (client !== null && (client.lastname || client.firstname)) || this.$tl("labels.clients.unknown", this.restaurantId);
        },
        getReservationPivotLabel(rp) {
            if (rp.reservation_id) {
                const name = this.getClientName(rp.reservation.client, rp.reservation.is_passing_customer);
                const label = `${name} - (${rp.reservation.nb_pers + rp.reservation.nb_children})`;

                return label.charAt(0).toUpperCase() + label.slice(1);
            }

            return this.$tl("errors.booking.seatingPlan.table.pendingResa");
        },
        getReservationPivotStartAt(rp) {
            return this.getDateTime(rp.datetime);
        },
        getReservationPivotEndAt(rp) {
            const durationArray = rp.duration.split(":");

            return this.getReservationPivotStartAt(rp).plus({
                hours: durationArray[0],
                minutes: durationArray[1],
            });
        },
        onEventClicked({ event }) {
            if (event.hasReservation && !event.isPassingCustomer) {
                this.$set(this, "selected_reservation_id", event.reservationId);
                this.$set(this, "selected_restaurant_id", this.restaurantId);

                this.$nextTick(() => {
                    this.$set(this, "showReservationModal", true);
                });
            }
        },
        displayReservationEdit({ reservation_id, restaurant_id }) {
            this.$set(this, "selected_reservation_id", reservation_id);
            this.$set(this, "selected_restaurant_id", restaurant_id);
            this.$set(this, "showReservationModal", false);

            this.$nextTick(() => {
                this.$set(this, "showEditReservationModal", true);
            });
        },
        registerToSockets() {
            const channelName = `App.restaurant.${this.restaurant.id}.service.${this.selectedService.id}.date.${this.reservation_date.toISODate()}`;

            Echo.private(channelName)
                .listen(".reservation.updated", this.onReservationUpdated)
                .listen(".reservation.added", this.onReservationAdded)
                .listen(".reservation.deleted", this.onReservationDeleted)
                .listen(".table.updated", this.onTableUpdated);

            this.sockets.listeningChannels.push(channelName);
            this.$store.commit("sockets/addChannel", channelName);
        },
        unregisterToSockets() {
            this.sockets.listeningChannels.forEach((channel) => {
                Echo.private(channel)
                    .stopListening(".reservation.deleted")
                    .stopListening(".reservation.updated")
                    .stopListening(".reservation.added")
                    .stopListening(".table.updated");
            });
        },
        onReservationDeleted(e) {
            const deletedReservationId = Number.parseInt(e.reservation_id);
            const deletedReservationServiceId = Number.parseInt(e.service_id);

            if (!Number.isNaN(deletedReservationId) && !Number.isNaN(deletedReservationServiceId)) {
                if (this.selectedService.id !== deletedReservationServiceId) {
                    return;
                }

                const deletedReservationIndex = this.selectedService.reservationsPivot.data.findIndex(
                    (rp) => rp.reservation_id === deletedReservationId
                );

                if (deletedReservationIndex !== -1) {
                    this.selectedService.reservationsPivot.data.splice(deletedReservationIndex, 1);
                }
            }
        },
        onReservationUpdated(e) {
            const updatedReservation = e.reservation;

            if (typeof updatedReservation !== "undefined") {
                if (updatedReservation.slot.service.id === this.selectedService.id) {
                    this.selectedService.reservationsPivot.data = this.selectedService.reservationsPivot.data.filter((rp) => {
                        if (rp.reservation_id !== null && rp.reservation.id === updatedReservation.id) {
                            return false;
                        }
                        return true;
                    });

                    updatedReservation.tables.data.forEach((table) => {
                        this.selectedService.reservationsPivot.data.push({
                            reservation_id: updatedReservation.id,
                            reservation: updatedReservation,
                            slot_id: updatedReservation.slot_id,
                            slot: updatedReservation.slot,
                            seating_plan_table_id: table.id,
                            table,
                            duration: updatedReservation.duration,
                            datetime: updatedReservation.reservation_datetime,
                        });
                    });

                    this.unregisterToSockets();
                    this.fetchData(false);
                }
            }
        },
        onReservationAdded(e) {
            const addedReservation = e.reservation;

            if (typeof addedReservation !== "undefined" && addedReservation.slot && addedReservation.slot.service) {
                if (!this.validReservationStatus.some((s) => s.value === addedReservation.status)) {
                    return;
                }

                if (addedReservation.slot.service.id === this.selectedService.id) {
                    addedReservation.tables.data.forEach((table) => {
                        this.selectedService.reservationsPivot.data.push({
                            reservation_id: addedReservation.id,
                            reservation: addedReservation,
                            slot_id: addedReservation.slot_id,
                            slot: addedReservation.slot,
                            seating_plan_table_id: table.id,
                            table,
                            duration: addedReservation.duration,
                            datetime: addedReservation.reservation_datetime,
                        });

                        this.unregisterToSockets();
                        this.fetchData(false);
                    });
                }
            }
        },
        onTableUpdated(e) {
            const updatedTable = e.table;

            this.selectedService.reservationsPivot.data = this.selectedService.reservationsPivot.data.filter((rp2) => {
                if (rp2.seating_plan_table_id !== updatedTable.id || rp2.reservation_id !== null) {
                    return true;
                }

                return false;
            });

            updatedTable.reservations_pivot.data.forEach((rp) => {
                if (rp.reservation_id === null) {
                    this.selectedService.reservationsPivot.data.push(rp);
                }
            });
        },
        canAddReservation(hour, table) {
            const slotFound = this.selectedService.slots.data.find((s) => s.hour_start === hour);

            return typeof slotFound !== "undefined" && this.slotIsFreeForTable(slotFound, table);
        },
        addStandardReservation(hour, table) {
            this.addReservation(hour, table, false);
        },
        addPassingCustomer(hour, table) {
            this.addReservation(hour, table, true);
        },
        addReservation(hour, table, isPassingCustomer = false) {
            const slotFound = this.selectedService.slots.data.find((s) => s.hour_start === hour);

            if (typeof slotFound === "undefined" || !this.canAddReservation(hour, table)) {
                throw "Logic Exception";
            }

            const event = isPassingCustomer ? "add-passing-customer" : "add-reservation";

            this.$emit(event, {
                tablesId: [table.id],
                slotId: slotFound.id,
                serviceId: this.selectedService.id,
                reservation_date: this.reservation_date,
            });
        },
        slotIsFreeForTable(slot, table) {
            const reservations = this.selectedService.reservationsPivot.data.filter((rp) => {
                if (typeof rp.all_slots_used === "undefined") {
                    return false;
                }

                return (
                    rp.all_slots_used.data.find((s) => {
                        return s.hour_start === slot.hour_start;
                    }) && rp.table.id == table.id
                );
            });

            return reservations.length === 0;
        },
        selectCurrentService() {
            const now = this.getDateTime();

            if (!now.hasSame(this.reservation_date, "day")) {
                return;
            }

            let nextServiceIndex = undefined;

            const index = this.filteredServices.findIndex((service, index) => {
                const serviceDateTimeBegin = this.setHourOnDateTime(service.hour_begin);
                const serviceDateTimeEnd = this.setHourOnDateTime(service.hour_end);

                if (typeof nextServiceIndex === "undefined" && now < serviceDateTimeBegin) {
                    nextServiceIndex = index;
                }

                return serviceDateTimeBegin <= now && now <= serviceDateTimeEnd;
            });

            if (index !== -1) {
                this.selectedServiceIndex = index;
            } else {
                this.selectedServiceIndex = nextServiceIndex || 0;
            }
        },
        openDropdown(id) {
            this.$set(this.visibledDropdowns, id, true);
        },
        closeDropdown(id) {
            this.$set(this.visibledDropdowns, id, false);
        },
        onDropdownOptionSelected({ item }, hour, data) {
            this[item.action](hour.toFormat("HH:mm"), data);
        },
        getCellCoordinates(uniqId, hour) {
            return `${uniqId}-${hour.toUnixInteger()}`;
        },
    },
    watch: {
        selectedServiceIndex(newVal) {
            if (this.services.length > 0 && newVal >= 0 && newVal < this.services.length) {
                this.fetchData();
            }
        },
    },
    created() {
        this.selectCurrentService();
    },
    mounted() {
        this.fetchData();
    },
    beforeDestroy() {
        this.unregisterToSockets();
    },
};
</script>
