var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(_vm._s(_vm.$tl("labels.booking.reservations.add.title"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c("AddReservation", {
          ref: "addReservation",
          attrs: {
            restaurant_id: _vm.restaurant_id,
            slotIdToPreselect: _vm.slot_id,
            serviceIdToPreselect: _vm.service_id,
            tablesIdToPreselect: _vm.tablesIdToPreselect,
            date: _vm.date,
            defaultValues: _vm.defaultValues,
          },
          on: {
            "enable-next-button": function ($event) {
              _vm.nextButtonEnable = $event
            },
            "change-step": function ($event) {
              _vm.step = $event
            },
            saved: function ($event) {
              return _vm.$emit("reservation-saved")
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.step > 1
          ? _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary btn-sm btn-circle ml-2",
                on: {
                  click: function ($event) {
                    return _vm.$refs.addReservation.setStep(_vm.step - 1)
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.previous")) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.step === 1 || _vm.step === 3
          ? _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary btn-sm btn-circle ml-2",
                attrs: { disabled: !_vm.nextButtonEnable },
                on: {
                  click: function ($event) {
                    return _vm.$refs.addReservation.setStep(_vm.step + 1)
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.next")) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showWaitingListButton && _vm.step === 4
          ? [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-outline-secondary btn-sm btn-circle ml-2",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.addReservation.saveInWaitingList()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.booking.waitings.list.title")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-outline-secondary btn-sm btn-circle ml-2",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.addReservation.saveAsOption()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$tl("labels.booking.reservations.status.option")
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.step === 4
          ? _c(
              "button",
              {
                staticClass: "btn btn-success btn-sm btn-circle ml-2",
                on: {
                  click: function ($event) {
                    return _vm.$refs.addReservation.save()
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.save")) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }