var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.password,
          expression: "password",
        },
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: !_vm.passwordsMatch
            ? { content: "Les mots de passe ne correspondent pas" }
            : undefined,
          expression:
            "!passwordsMatch ? { content: 'Les mots de passe ne correspondent pas' } : undefined",
        },
      ],
      staticClass: "form-control",
      class: { "is-invalid": !_vm.passwordsMatch },
      attrs: { type: "password", "data-test-id": _vm.dataTestId },
      domProps: { value: _vm.password },
      on: {
        focus: function ($event) {
          _vm.inputFocus = true
        },
        blur: function ($event) {
          _vm.inputFocus = false
        },
        input: function ($event) {
          if ($event.target.composing) return
          _vm.password = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLegend_,
            expression: "showLegend_",
          },
        ],
        staticClass: "div-pass-check",
      },
      [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.passwordsMatch,
                expression: "!passwordsMatch",
              },
            ],
            staticClass: "text-danger",
          },
          [_vm._v("Les mots de passe ne correspondent pas")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.passwordsMatch && _vm.mainPassword && _vm.password,
                expression: "passwordsMatch && mainPassword && password",
              },
            ],
            staticClass: "text-success",
          },
          [_vm._v("Les mots de passe correspondent")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }