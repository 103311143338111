<template>
    <modal>
        <h5 slot="header">Moyen(s) de paiements</h5>
        <LoaderComponent v-if="loading" slot="body" />
        <div v-else slot="body">
            Afin de simplifier la facturation des abonnements, veuillez choisir le moyen de paiement à conserver sur votre compte.
            <br />
            Les moyens de paiements non selectionnés seront supprimés de votre compte

            <CardList :editable="false" v-model="cardSelected" />
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button @click="confirm" :disabled="!cardSelected" class="btn btn-success btn-sm btn-circle">Confirmer</button>
        </div>
    </modal>
</template>

<script>
import CardList from "../../../../stripe/Customer/PaymentMethods/CardList.vue";
import LoaderComponent from "../../../../LoaderComponent";

export default {
    data() {
        return {
            error: null,
            loading: false,
            cardSelected: null,
        };
    },
    methods: {
        confirm() {
            if (this.cardSelected) {
                this.error = null;
                this.loading = true;
                this.$store
                    .dispatch("stripe/newPaymentMethod", { id: this.cardSelected.id })
                    .then((response) => {
                        this.loading = false;
                        this.$emit("close");
                    })
                    .catch((error) => {
                        if (error.response && error.response.data.message) {
                            this.error = error.response.data.message;
                        } else if (error.message) {
                            this.error = error.message;
                        } else {
                            this.error = "Une erreur est survenue.";
                        }
                        this.loading = false;
                    });
            }
        },
    },
    components: {
        LoaderComponent,
        CardList,
    },
};
</script>
