var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loadings.menuOption || _vm.loadings.form
        ? _c("loader-component")
        : _vm.errors.fetchMenuOption !== null
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.errors.fetchMenuOption) + "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.loadings.menuOption &&
                !_vm.loadings.form &&
                _vm.errors.fetchMenuOption === null,
              expression:
                "!loadings.menuOption && !loadings.form && errors.fetchMenuOption === null",
            },
          ],
          ref: "form",
          attrs: { method: "post" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "border-light b-radius-20 p-4" },
                [
                  _vm.showOptionTypeChoice
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-5 pt-2" }, [
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$tl("labels.booking.menus.options.type")
                                ) +
                                " "
                            ),
                            _c("small", [_vm._v("*")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-7 radio" },
                          [
                            _vm._l(
                              _vm.ALL_MENU_OPTION_TYPES,
                              function (menuOptionType) {
                                return _c(
                                  "radio-button-component",
                                  {
                                    key: menuOptionType.value,
                                    attrs: {
                                      inputValue: menuOptionType.value,
                                      disabled:
                                        _vm.menuOptionId !== null ||
                                        !_vm.isEditable,
                                    },
                                    model: {
                                      value: _vm.menuOption.option_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.menuOption,
                                          "option_type",
                                          $$v
                                        )
                                      },
                                      expression: "menuOption.option_type",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.getMenuOptionTypeLabel(
                                            menuOptionType.value,
                                            _vm.restaurantId
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c("show-errors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors.form,
                                errorKey: "option_type",
                              },
                            }),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input-component", {
                    attrs: {
                      inputName: "name",
                      required: "",
                      disabled: !_vm.isEditable,
                      label: _vm.$tl("labels.form.name"),
                      "form-errors": _vm.errors.form,
                    },
                    model: {
                      value: _vm.menuOption.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.menuOption, "name", $$v)
                      },
                      expression: "menuOption.name",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col-5 pt-2" }, [
                      _c("label", { attrs: { for: "description" } }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$tl("labels.form.description")) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-7" },
                      [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.menuOption.description,
                              expression: "menuOption.description",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            disabled: !_vm.isEditable,
                            name: "description",
                            id: "description",
                          },
                          domProps: { value: _vm.menuOption.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.menuOption,
                                "description",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("show-errors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors.form,
                            errorKey: "description",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("input-component", {
                    attrs: {
                      inputName: "price",
                      inputType: "number",
                      step: "0.01",
                      min: "0",
                      required: "",
                      disabled: !_vm.isEditable,
                      label: _vm.$t("labels.booking.menus.price", {
                        currency: _vm.getRestaurantCurrency(_vm.restaurantId),
                      }),
                      "form-errors": _vm.errors.form,
                    },
                    model: {
                      value: _vm.menuOption.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.menuOption, "price", $$v)
                      },
                      expression: "menuOption.price",
                    },
                  }),
                  _vm._v(" "),
                  !_vm.isTvaDisabled
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-5 pt-2" }, [
                          _c("label", { attrs: { for: "tax_rate" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$tl("labels.taxRate")) + " "
                            ),
                            _c("small", [_vm._v("*")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-7" }, [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c("div", { staticClass: "col-md-4" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.menuOption.tax_rate,
                                        expression: "menuOption.tax_rate",
                                      },
                                    ],
                                    staticClass: "custom-select mb-0",
                                    attrs: {
                                      placeholder:
                                        "Veuillez sélectionner un taux",
                                      id: "tax_rate",
                                      name: "tax_rate",
                                      disabled: !_vm.isEditable,
                                      required: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.menuOption,
                                          "tax_rate",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { domProps: { value: null } },
                                      [_vm._v("--")]
                                    ),
                                    _vm._v(" "),
                                    _c("option", { domProps: { value: 5.5 } }, [
                                      _vm._v("5,5"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { domProps: { value: 10 } }, [
                                      _vm._v("10"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { domProps: { value: 20 } }, [
                                      _vm._v("20"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { domProps: { value: 8.1 } }, [
                                      _vm._v("8.1"),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-8 mt-1" }, [
                                _vm._v("%"),
                              ]),
                              _vm._v(" "),
                              _c("show-errors", {
                                staticClass: "col-12",
                                attrs: {
                                  errors: _vm.errors.form,
                                  errorKey: "tax_rate",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col-5 pt-2" }, [
                      _c("label", { attrs: { for: "quantity_type" } }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$tl("labels.booking.menus.options.quantity")
                            ) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-7" },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.menuOption.quantity_type,
                                expression: "menuOption.quantity_type",
                              },
                            ],
                            staticClass: "custom-select mb-0",
                            attrs: {
                              disabled: !_vm.isEditable,
                              id: "quantity_type",
                              name: "quantity_type",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.menuOption,
                                  "quantity_type",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(
                            _vm.ALL_OPTION_QUANTITY_TYPES,
                            function (optionQuantityType) {
                              return _c(
                                "option",
                                {
                                  key: optionQuantityType.value,
                                  domProps: { value: optionQuantityType.value },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.getOptionQuantityTypeLabel(
                                          optionQuantityType.value,
                                          _vm.restaurantId
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors.form,
                            errorKey: "quantity_type",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-5" }, [
                      _vm._v(_vm._s(_vm.$tl("labels.form.picture"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-7" },
                      [
                        _c("image-uploader", {
                          attrs: {
                            "uploaded-img-url": _vm.img.uploadedImgUrl,
                            "uploaded-img-path": _vm.img.uploadedImgPath,
                            "delete-url": _vm.deleteImageURL,
                            maxSize: 2,
                            disabled: !_vm.isEditable,
                          },
                          on: {
                            "image-selected": function ($event) {
                              _vm.img.file = $event
                            },
                            "image-deleted": _vm.onImageDeleted,
                          },
                        }),
                        _vm._v(" "),
                        _c("show-errors", {
                          staticClass: "d-block",
                          attrs: {
                            errors: _vm.errors.form,
                            errorKey: "img_file",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "border-light b-radius-20 p-4 mb-3" }, [
                _c("div", { staticClass: "row mb-3" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$tl(
                            "labels.booking.menus.options.enabledSettings"
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-5" }, [
                    _c(
                      "label",
                      {
                        staticClass: "container-box",
                        staticStyle: { width: "30px" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.menuOption.enable_min_pers,
                              expression: "menuOption.enable_min_pers",
                            },
                          ],
                          attrs: {
                            disabled: !_vm.isEditable,
                            type: "checkbox",
                            id: "enable_min_pers",
                            name: "enable_min_pers",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.menuOption.enable_min_pers
                            )
                              ? _vm._i(_vm.menuOption.enable_min_pers, null) >
                                -1
                              : _vm.menuOption.enable_min_pers,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.menuOption.enable_min_pers,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.menuOption,
                                      "enable_min_pers",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.menuOption,
                                      "enable_min_pers",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.menuOption, "enable_min_pers", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "checkmark",
                          staticStyle: { "margin-top": "-2px" },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "pt-2",
                        attrs: { for: "enable_min_pers" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$tl(
                                "labels.booking.menus.options.enabledFrom"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-7" },
                    [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.menuOption.nb_pers_min,
                              expression: "menuOption.nb_pers_min",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          staticStyle: { width: "30%" },
                          attrs: {
                            type: "number",
                            step: "1",
                            min: "0",
                            name: "nb_pers_min",
                            disabled:
                              !_vm.menuOption.enable_min_pers ||
                              !_vm.isEditable,
                            id: "nb_pers_min",
                          },
                          domProps: { value: _vm.menuOption.nb_pers_min },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.menuOption,
                                "nb_pers_min",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "m-0 p-0 ml-2" }, [
                          _vm._v(_vm._s(_vm.$tl("labels.persons"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("show-errors", {
                        staticClass: "d-block",
                        attrs: {
                          errors: _vm.errors.form,
                          errorKey: "enable_min_pers",
                        },
                      }),
                      _vm._v(" "),
                      _c("show-errors", {
                        staticClass: "d-block",
                        attrs: {
                          errors: _vm.errors.form,
                          errorKey: "nb_pers_min",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-5" }, [
                    _c(
                      "label",
                      {
                        staticClass: "container-box",
                        staticStyle: { width: "30px" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.menuOption.enable_max_pers,
                              expression: "menuOption.enable_max_pers",
                            },
                          ],
                          attrs: {
                            disabled: !_vm.has_right_to_update_menu,
                            type: "checkbox",
                            id: "enable_max_pers",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.menuOption.enable_max_pers
                            )
                              ? _vm._i(_vm.menuOption.enable_max_pers, null) >
                                -1
                              : _vm.menuOption.enable_max_pers,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.menuOption.enable_max_pers,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.menuOption,
                                      "enable_max_pers",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.menuOption,
                                      "enable_max_pers",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.menuOption, "enable_max_pers", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "checkmark",
                          staticStyle: { "margin-top": "-2px" },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "pt-2",
                        attrs: { for: "enable_max_pers" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$tl(
                                "labels.booking.menus.options.disabledFrom"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-7" },
                    [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.menuOption.nb_pers_max,
                              expression: "menuOption.nb_pers_max",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          staticStyle: { width: "30%" },
                          attrs: {
                            type: "number",
                            step: "1",
                            min: "0",
                            name: "nb_pers_max",
                            disabled:
                              !_vm.menuOption.enable_max_pers ||
                              !_vm.isEditable,
                            id: "nb_pers_max",
                          },
                          domProps: { value: _vm.menuOption.nb_pers_max },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.menuOption,
                                "nb_pers_max",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "m-0 p-0 ml-2" }, [
                          _vm._v(_vm._s(_vm.$tl("labels.persons"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("ShowErrors", {
                        staticClass: "d-block",
                        attrs: {
                          errors: _vm.errors.form,
                          errorKey: "enable_max_pers",
                        },
                      }),
                      _vm._v(" "),
                      _c("ShowErrors", {
                        staticClass: "d-block",
                        attrs: {
                          errors: _vm.errors.form,
                          errorKey: "nb_pers_max",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.showSaveButton
            ? _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("input", {
                    staticClass: "btn btn-success btn-circle",
                    attrs: {
                      type: "submit",
                      name: "submit",
                      disabled: !_vm.isEditable,
                    },
                    domProps: { value: _vm.$tl("labels.form.actions.save") },
                  }),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }