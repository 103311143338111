var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasValidIssuer
    ? _c(
        "span",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tooltip,
              expression: "tooltip",
            },
          ],
        },
        [
          _vm.isFeatherIcon
            ? _c("feather", {
                class: _vm.icon.class,
                attrs: { type: _vm.icon.feather },
              })
            : _vm.isImgIcon
            ? _c("img", {
                staticClass: "feather-20",
                class: _vm.icon.class,
                attrs: { src: _vm.icon.img },
              })
            : _vm.isHtmlIcon
            ? _c("span", {
                staticClass: "feather-20 d-inline-block",
                class: _vm.icon.class,
                domProps: { innerHTML: _vm._s(_vm.icon.html) },
              })
            : _c("span", { staticClass: "text-danger" }, [
                _vm._v("Unknown icon"),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }