<template>
    <span>
        <feather v-if="featherBinding !== null" v-bind="featherBinding" v-tooltip="featherBinding.tooltip" />
        <template v-if="showLabel">{{ getClientTypeLabel(clientType) }}</template>
    </span>
</template>

<script>
import ClientTypeEnum from "../../../mixins/enums/ClientTypeEnum.js";

export default {
    props: {
        clientType: {
            default: null,
        },
        showLabel: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [ClientTypeEnum],
    computed: {
        featherBinding() {
            if (this.clientType === null) {
                return null;
            }

            return this.getClientTypeFeatherInfo(this.clientType);
        },
    },
};
</script>
