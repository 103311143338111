export default {
    isReservationListCollapsed: false,
    isGroupsAndPrintTabCollapsed: false,
    pinPax: false,
    pinPercentOfMenuSold: false,
    pinPercentOfMenuOptionSold: false,
    pinPercentOfGeneralOptionSold: false,
    pinEvolutionNbResa: false,
    pinCaPerMenu: false,
    pinBookingsPeriod: false,
    pinClientLoyal: false,
    pinCaPerGeneralOption: false,
    pinCaPerMenuOption: false,
};
