<template>
    <div>
        <div ref="dropdown" v-tooltip="immutableTooltip">
            <div v-if="current_tag_raw === RESERVATION_STATUS_CREATED.value">
                <ejs-tooltip
                    :content="translate_('labels.booking.reservations.actions.accept')"
                    :cssClass="cssClass"
                    :animation="tooltipAnimation"
                    class="d-inline">
                    <button
                        @click.btn="changeStatus($event, false)"
                        :disabled="!editable"
                        class="btn btn-success btn-sm btn-square"
                        :data-status="RESERVATION_STATUS_VALIDATED.value"
                        :data-name="getReservationStatusLabel(RESERVATION_STATUS_VALIDATED.value, translate, restaurant_id)"
                        :data-class="getReservationStatusClass(RESERVATION_STATUS_VALIDATED.value)">
                        <feather type="check" style="pointer-events: none" />
                    </button>
                </ejs-tooltip>
                <ejs-tooltip
                    :content="translate_('labels.booking.reservations.actions.refuse')"
                    :cssClass="cssClass"
                    :animation="tooltipAnimation"
                    class="d-inline">
                    <button
                        href="javascript:"
                        v-on:click.btn="changeStatus($event, false)"
                        :disabled="!editable"
                        data-status="refused"
                        data-name="Refusée"
                        data-class="btn-default"
                        class="btn btn-danger btn-sm text-white btn-square">
                        <feather style="pointer-events: none" type="x" />
                    </button>
                </ejs-tooltip>
                <ejs-tooltip
                    :content="translate_('labels.booking.reservations.actions.acceptFootprint')"
                    :cssClass="cssClass"
                    :animation="tooltipAnimation"
                    class="d-inline">
                    <button
                        class="btn btn-warning btn-sm btn-square text-white"
                        :disabled="!editable"
                        @click.btn="askFootprint"
                        v-if="
                            isStripeEnabled &&
                            !reservation.option_bank &&
                            reservation.client &&
                            reservation.client.email &&
                            reservation.client.noshows > 0 &&
                            reservation.created_from !== RESERVATION_ISSUER_GOOGLE_RESERVE.value
                        ">
                        <feather style="pointer-events: none" type="credit-card" />
                    </button>
                </ejs-tooltip>
            </div>
            <div v-else>
                <drop-down
                    :options="tagsFormatted"
                    :selected-option="selectedTag"
                    :btn-classes-string="`btn btn-sm btn-outline-secondary btn-circle resa-status ${current_tag_class} ${current_tag_raw}`"
                    option-label="text"
                    option-value="tag"
                    @option-changed="changeStatus($event, true)" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import { TooltipPlugin } from "@syncfusion/ej2-vue-popups";
import { enableRipple } from "@syncfusion/ej2-base";
import PaymentStatusEnum from "../../mixins/enums/booking/PaymentStatusEnum";
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum";
import OptionBankEnum from "../../mixins/enums/booking/OptionBankEnum";
import ReservationIssuerEnum from "../../mixins/enums/booking/ReservationIssuerEnum.js";
import DropDown from "../forms/DropDown.vue";

enableRipple(true);
Vue.use(TooltipPlugin);

export default {
    props: {
        reservation: {
            required: true,
        },
        restaurant_id: {
            required: true,
        },
        cancellation: {
            required: true,
        },
        isStripeEnabled: {
            required: true,
        },
        update: {
            required: true,
        },
        editable: {
            default: true,
        },
        translate: {
            type: Function,
            default: undefined,
        },
    },
    components: {
        DropDown,
    },
    mixins: [PaymentStatusEnum, OptionBankEnum, ReservationStatusEnum, ReservationIssuerEnum],
    data() {
        return {
            cssClass: "customtooltip",
            tooltipAnimation: {
                open: {
                    effet: "None",
                    delay: 400,
                },
            },
            tags: [],
            current_tag: null,
            current_tag_raw: null,
            current_tag_class: null,
            loading: false,
        };
    },
    computed: {
        tagsFormatted() {
            return this.tags.map((tag) => {
                return {
                    text: this.getReservationStatusLabel(tag.value, this.translate, this.restaurant_id),
                    tag: tag.value,
                };
            });
        },
        selectedTag() {
            return { tag: this.current_tag_raw, text: this.current_tag };
        },
        immutableTooltip() {
            if (!this.reservation.immutable) {
                return undefined;
            }

            return this.getTooltip(this.$tl("infos.booking.reservations.immutable", undefined, { theme: this.themeUppercased }));
        },
    },
    methods: {
        translate_(key, params = undefined) {
            if (typeof this.translate !== "undefined") {
                return this.translate(key, params);
            }
            return this.$t(key, params);
        },
        changeStatus: function (event, fromDropDown = false) {
            if (!this.editable || this.loading) return;

            const new_status = fromDropDown ? event.tag : event.currentTarget.dataset.status;

            if (new_status === this.RESERVATION_STATUS_NOSHOW.value) {
                this.$emit("displayNoshowModal", true);
            } else if (
                new_status === this.RESERVATION_STATUS_CANCELED.value &&
                this.reservation.payment &&
                this.reservation.payment.option_bank === this.OPTION_BANK_PAYMENT.value &&
                this.reservation.payment.status === this.PAYMENT_STATUS_CAPTURED.value
            ) {
                this.$emit("displayRefundPartiallyModal", true);
            } else if (new_status === this.RESERVATION_STATUS_CANCELED.value || new_status === this.RESERVATION_STATUS_REFUSED.value) {
                this.$emit("displayCancelModal", new_status);
            } else {
                this.loading = true;

                axios
                    .put(`/api/restaurants/${this.restaurant_id}/reservations/${this.reservation.id}/status?status=${new_status}`)
                    .then((response) => {
                        this.notifySuccess(response, this.translate_("success.booking.reservations.updated"));
                    })
                    .catch((error) => {
                        this.notifyError(error, this.translate_("errors.booking.reservations.notUpdated"));
                    })
                    .finally(() => (this.loading = false));
            }
        },
        updateData() {
            // add status choice "over" if resa is after the current time
            const nowDateTime = this.getDateTime();
            const resaDateTime = this.getDateTime(this.reservation.reservation_datetime);
            const dateTimeAllowCancellation = resaDateTime.minus({ minutes: this.cancellation });
            const resaDateTimePlus1Day = resaDateTime.plus({ days: 1 });

            if (nowDateTime > resaDateTime) {
                this.tags.push(this.RESERVATION_STATUS_OVER);
            }

            // add status "no show" if client cannot cancel anymore and if resa is less than 24 hours ago
            if (
                dateTimeAllowCancellation < nowDateTime &&
                resaDateTimePlus1Day > nowDateTime &&
                this.current_tag_raw != this.RESERVATION_STATUS_OPTION.value
            ) {
                this.tags.push(this.RESERVATION_STATUS_NOSHOW);
            }
        },

        buildStatus(new_status) {
            // define current tag
            this.current_tag_raw = new_status;

            this.tags = [];

            if (!this.current_tag) {
                this.current_tag = this.getReservationStatusLabel(this.reservation.status, this.translate, this.restaurant_id);
                this.current_tag_class = this.getReservationStatusClass(this.reservation.status);
            }

            if (this.reservation.immutable) {
                return;
            }

            // build tags array
            if (this.current_tag_raw === this.RESERVATION_STATUS_CREATED.value) {
                this.tags = [this.RESERVATION_STATUS_VALIDATED, this.RESERVATION_STATUS_REFUSED, this.RESERVATION_STATUS_OPTION];
            } else if (this.current_tag_raw === this.RESERVATION_STATUS_OPTION.value) {
                this.tags = [this.RESERVATION_STATUS_VALIDATED, this.RESERVATION_STATUS_CANCELED];
            } else if (this.current_tag_raw === this.RESERVATION_STATUS_VALIDATED.value) {
                this.tags = [
                    this.RESERVATION_STATUS_CANCELED,
                    this.RESERVATION_STATUS_OPTION,
                    this.RESERVATION_STATUS_CONFIRMED,
                    this.RESERVATION_STATUS_AT_TABLE,
                ];
            } else if (this.current_tag_raw === this.RESERVATION_STATUS_CANCELED.value) {
                if (this.reservation.created_from !== this.RESERVATION_ISSUER_GOOGLE_RESERVE.value) {
                    this.tags = [this.RESERVATION_STATUS_VALIDATED, this.RESERVATION_STATUS_OPTION, this.RESERVATION_STATUS_AT_TABLE];
                }
            } else if (this.current_tag_raw === this.RESERVATION_STATUS_REFUSED.value) {
                if (this.reservation.created_from !== this.RESERVATION_ISSUER_GOOGLE_RESERVE.value) {
                    this.tags = [this.RESERVATION_STATUS_VALIDATED, this.RESERVATION_STATUS_OPTION];
                }
            } else if (this.current_tag_raw === this.RESERVATION_STATUS_OVER.value) {
                this.tags = [];
            } else if (this.current_tag_raw === this.RESERVATION_STATUS_NOSHOW.value) {
                this.tags = [];
            } else if (this.current_tag_raw === this.RESERVATION_STATUS_CONFIRMED.value) {
                this.tags = [this.RESERVATION_STATUS_CANCELED, this.RESERVATION_STATUS_AT_TABLE];
            } else if (this.current_tag_raw === this.RESERVATION_STATUS_AT_TABLE.value) {
                if (this.reservation.confirmed_by_client === 1) {
                    this.tags = [this.RESERVATION_STATUS_CONFIRMED];
                } else {
                    this.tags = [this.RESERVATION_STATUS_VALIDATED];
                }
            }

            if (!this.getDateTime().hasSame(this.getDateTime(this.reservation.reservation_datetime), "day")) {
                const atTableIndex = this.tags.findIndex((tag) => tag.value === this.RESERVATION_STATUS_AT_TABLE.value);
                if (atTableIndex !== -1) {
                    this.tags.splice(atTableIndex, 1);
                }
            }

            const excludeStatus = [
                this.RESERVATION_STATUS_REFUSED.value,
                this.RESERVATION_STATUS_OVER.value,
                this.RESERVATION_STATUS_NOSHOW.value,
                this.RESERVATION_STATUS_CANCELED.value,
            ];
            if (!excludeStatus.includes(this.current_tag_raw)) {
                this.updateData();
            }
        },
        askFootprint() {
            if (!this.editable || this.loading) return;
            this.loading = true;
            axios
                .post(`/api/restaurants/${this.restaurant_id}/reservations/${this.reservation.id}/optionBank/footprint`)
                .then(() => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: this.translate_("success.booking.footprint.sent"),
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
    },
    watch: {
        reservation: {
            immediate: true,
            handler: function () {
                this.current_tag = null;
                this.buildStatus(this.reservation.status);
            },
        },
    },
};
</script>
