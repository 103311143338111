var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { on: { submit: _vm.save } },
    [
      _vm.loading ? _c("loader-component") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
        },
        [
          _c("div", { staticClass: "mb-4 pointer" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("clear-selected-form")
                  },
                },
              },
              [
                _c("feather", {
                  staticClass: "mr-1",
                  attrs: { type: "chevron-left" },
                }),
                _vm._v(_vm._s(_vm.$tl("labels.form.actions.back")) + " "),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.formError
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v("\n            " + _vm._s(_vm.formError) + "\n        "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.ibanError
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v("\n            " + _vm._s(_vm.ibanError) + "\n        "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$tl("labels.form.psp.paymentMethods.sepaDebit.owner")
                  ) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.bankAccountOwner.name,
                    expression: "bankAccountOwner.name",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", required: "" },
                domProps: { value: _vm.bankAccountOwner.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.bankAccountOwner, "name", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$tl("labels.form.psp.paymentMethods.sepaDebit.email")
                  ) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.bankAccountOwner.email,
                    expression: "bankAccountOwner.email",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "email", required: "" },
                domProps: { value: _vm.bankAccountOwner.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.bankAccountOwner, "email", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { ref: "ibanForm" }),
          _vm._v(" "),
          _vm.showButtons
            ? _c(
                "button",
                {
                  staticClass: "btn btn-success btn-circle",
                  class: { disabled: _vm.ibanError },
                  attrs: { disabled: _vm.ibanError, type: "submit" },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$tl("labels.form.actions.save")) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }