var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    _vm._g(
      {
        ref: "contentEditable",
        tag: "component",
        class: _vm.cssClass,
        style: _vm.cssStyle,
        attrs: { contenteditable: _vm.editable },
      },
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }