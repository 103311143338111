<template>
    <div class="pb-5 sevices-report">
        <div class="row m-0">
            <div class="col-12">
                <div
                    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Rapport Financier</h5>
                </div>
            </div>
        </div>
        <div class="row m-0 pb-5">
            <div class="col-12">
                <LoaderComponent v-if="loading" />
                <div v-else>
                    <div v-if="error" class="alert alert-danger">
                        {{ error }}
                    </div>
                    <div v-if="stats !== null && has_right_to_read">
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="border-light b-radius-20 p-4">
                                    <div class="row">
                                        <div class="col-12 mb-2">
                                            Rechercher par
                                            <select v-model="filter_date" class="custom-select mb-0 w-auto ml-1 mr-2">
                                                <option value="collect">Date de retrait / livraison</option>
                                                <option value="payment">Date de paiement</option>
                                            </select>
                                            Mode de retrait
                                            <select
                                                v-model="filter_collect_type"
                                                class="custom-select mb-0 w-auto ml-1">
                                                <option :value="null">Tous</option>
                                                <option
                                                    v-for="collectType in availableCollectTypesForFiltering"
                                                    :key="collectType.value"
                                                    :value="collectType.value">
                                                    {{ collectType.label }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="row">
                                                <div class="col-12">
                                                    <select
                                                        v-model="stat_period"
                                                        name="stat_period"
                                                        class="custom-select mb-0">
                                                        <option value="today">Aujourd'hui</option>
                                                        <option value="yesterday">Hier</option>
                                                        <option value="last_week">La semaine dernière</option>
                                                        <option value="last_month">Le mois dernier</option>
                                                        <option value="last_year">L'an dernier</option>
                                                        <option value="custom_period">Période Personnalisée</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div v-if="stat_period === 'custom_period'" class="row mt-2">
                                                <div class="col-12 d-flex">
                                                    <datepicker
                                                        class="d-inline-block date-resa-cal"
                                                        :disabledDates="disabledDates"
                                                        format="dd/MM/yyyy"
                                                        input-class="form-control"
                                                        placeholder="Date de début"
                                                        style="width: 150px"
                                                        :monday-first="true"
                                                        :language="fr"
                                                        v-model="from_date"></datepicker>
                                                    <feather type="arrow-right" class="m-2" />
                                                    <datepicker
                                                        class="d-inline-block date-resa-cal"
                                                        :disabledDates="disabledDates"
                                                        format="dd/MM/yyyy"
                                                        input-class="form-control"
                                                        placeholder="Date de fin"
                                                        style="width: 150px"
                                                        :monday-first="true"
                                                        :language="fr"
                                                        v-model="to_date"></datepicker>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 text-center pt-2 pb-2">
                                            <div class="d-inline-block align-middle lead-switch ml-1">
                                                <input
                                                    type="checkbox"
                                                    class="switch align-self-center is-rounded"
                                                    v-model="stat_comparison" />
                                                <label
                                                    v-tooltip="{
                                                        delay: { show: 400, hide: 0 },
                                                        content: 'Comparaison',
                                                    }"
                                                    @click="stat_comparison = !stat_comparison"></label>
                                            </div>
                                        </div>
                                        <div v-if="stat_comparison" class="col-md-5">
                                            <div class="row">
                                                <div class="col-12">
                                                    <select
                                                        v-model="comparison_period"
                                                        class="custom-select mb-0 mr-2"
                                                        name="stat_comparison_period">
                                                        <option value="previous_period">Période précédente</option>
                                                        <option value="previous_year">Année précédente</option>
                                                        <option value="custom_period">Période Personnalisée</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div v-if="comparison_period === 'custom_period'" class="row mt-2">
                                                <div class="col-12 d-flex">
                                                    <datepicker
                                                        class="d-inline-block date-resa-cal"
                                                        :disabledDates="disabledDates"
                                                        format="dd/MM/yyyy"
                                                        input-class="form-control"
                                                        placeholder="Date de début"
                                                        style="width: 150px"
                                                        :monday-first="true"
                                                        :language="fr"
                                                        v-model="from_date_comparison"></datepicker>
                                                    <feather type="arrow-right" class="m-2" />
                                                    <datepicker
                                                        class="d-inline-block date-resa-cal"
                                                        :disabledDates="disabledDates"
                                                        format="dd/MM/yyyy"
                                                        input-class="form-control"
                                                        placeholder="Date de fin"
                                                        style="width: 150px"
                                                        :monday-first="true"
                                                        :language="fr"
                                                        v-model="to_date_comparison"></datepicker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-1">
                                        <div class="col-12">
                                            <small class="text-muted"
                                                >(les statistiques ne concernent que
                                                {{
                                                    keepClientsDetailsAndHistoryFor > 1
                                                        ? `les ${keepClientsDetailsAndHistoryFor} dernières années`
                                                        : "l'année dernière"
                                                }})</small
                                            >
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-12">
                                            <button @click="fetchData()" class="btn btn-sm btn-success btn-circle">
                                                Appliquer
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row m-0 mb-2">
                            <div class="col-12">
                                <strong class="text-success">{{ labelPeriodMain }}</strong>
                                <span v-if="displaying.stat_comparison">
                                    comparé à <strong class="text-success">{{ labelPeriodComparison }}</strong></span
                                >
                            </div>
                        </div>

                        <div class="row m-0 click-collect-report mb-2">
                            <div class="col-lg-3 col-md-6 p-1">
                                <div class="border-light p-3 b-radius-20">
                                    <span>Total des ventes</span>
                                    <div>
                                        <strong class="value">{{
                                            formatCurrency(stats.main.total_amount / 100)
                                        }}</strong>
                                    </div>
                                    <template v-if="displaying.stat_comparison">
                                        <ArrowPercentEvolutionComponent
                                            v-if="!(stats.main.total_amount == 0 && stats.comparison.total_amount == 0)"
                                            :percent="stats.evolution.total_amount" />
                                        <small class="text-muted">{{ labelPeriodComparison }}</small>
                                        <div>
                                            <span class="text-success">{{
                                                formatCurrency(stats.comparison.total_amount / 100)
                                            }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 p-1">
                                <div class="border-light p-3 b-radius-20">
                                    <span>Ventes nettes</span>
                                    <div>
                                        <strong class="value">{{
                                            formatCurrency(stats.main.total_net_amount / 100)
                                        }}</strong>
                                    </div>
                                    <template v-if="displaying.stat_comparison">
                                        <ArrowPercentEvolutionComponent
                                            v-if="
                                                !(
                                                    stats.main.total_net_amount == 0 &&
                                                    stats.comparison.total_net_amount == 0
                                                )
                                            "
                                            :percent="stats.evolution.total_net_amount" />
                                        <small class="text-muted">{{ labelPeriodComparison }}</small>
                                        <div>
                                            <span class="text-success">{{
                                                formatCurrency(stats.comparison.total_net_amount / 100)
                                            }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 p-1">
                                <div class="border-light p-3 b-radius-20">
                                    <span>Commandes</span>
                                    <div>
                                        <strong class="value">{{ stats.main.nb_sales }}</strong>
                                    </div>
                                    <template v-if="displaying.stat_comparison">
                                        <ArrowPercentEvolutionComponent
                                            v-if="!(stats.main.nb_sales == 0 && stats.comparison.nb_sales == 0)"
                                            :percent="stats.evolution.nb_sales" />
                                        <small class="text-muted">{{ labelPeriodComparison }}</small>
                                        <div>
                                            <span class="text-success">{{ stats.comparison.nb_sales }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 p-1">
                                <div class="border-light p-3 b-radius-20">
                                    <span>Panier moyen</span>
                                    <div>
                                        <strong class="value">{{ formatCurrency(stats.main.avg_amount / 100) }}</strong>
                                    </div>
                                    <template v-if="displaying.stat_comparison">
                                        <ArrowPercentEvolutionComponent
                                            v-if="!(stats.main.avg_amount == 0 && stats.comparison.avg_amount == 0)"
                                            :percent="stats.evolution.avg_amount" />
                                        <small class="text-muted">{{ labelPeriodComparison }}</small>
                                        <div>
                                            <span class="text-success">{{
                                                formatCurrency(stats.comparison.avg_amount / 100)
                                            }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="row m-0 click-collect-report" v-if="!isAdmin && !isTvaDisabled">
                            <div class="col-lg-3 col-md-6 p-1">
                                <div class="border-light p-3 b-radius-20">
                                    <span>TVA 5.5%</span>
                                    <div>
                                        <strong class="value">{{
                                            formatCurrency(stats.main.tva.five_dot_five / 100)
                                        }}</strong>
                                    </div>
                                    <template v-if="displaying.stat_comparison">
                                        <ArrowPercentEvolutionComponent
                                            v-if="
                                                !(
                                                    stats.main.tva.five_dot_five == 0 &&
                                                    stats.comparison.tva.five_dot_five == 0
                                                )
                                            "
                                            :percent="stats.evolution.tva.five_dot_five" />
                                        <small class="text-muted">{{ labelPeriodComparison }}</small>
                                        <div>
                                            <span class="text-success">{{
                                                formatCurrency(stats.comparison.tva.five_dot_five / 100)
                                            }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 p-1">
                                <div class="border-light p-3 b-radius-20">
                                    <span>TVA 10%</span>
                                    <div>
                                        <strong class="value">{{ formatCurrency(stats.main.tva.ten / 100) }}</strong>
                                    </div>
                                    <template v-if="displaying.stat_comparison">
                                        <ArrowPercentEvolutionComponent
                                            v-if="!(stats.main.tva.ten == 0 && stats.comparison.tva.ten == 0)"
                                            :percent="stats.evolution.tva.ten" />
                                        <small class="text-muted">{{ labelPeriodComparison }}</small>
                                        <div>
                                            <span class="text-success">{{
                                                formatCurrency(stats.comparison.tva.ten / 100)
                                            }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 p-1">
                                <div class="border-light p-3 b-radius-20">
                                    <span>TVA 20%</span>
                                    <div>
                                        <strong class="value">{{ formatCurrency(stats.main.tva.twenty / 100) }}</strong>
                                    </div>
                                    <template v-if="displaying.stat_comparison">
                                        <ArrowPercentEvolutionComponent
                                            v-if="!(stats.main.tva.twenty == 0 && stats.comparison.tva.twenty == 0)"
                                            :percent="stats.evolution.tva.twenty" />
                                        <small class="text-muted">{{ labelPeriodComparison }}</small>
                                        <div>
                                            <span class="text-success">{{
                                                formatCurrency(stats.comparison.tva.twenty / 100)
                                            }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 p-1">
                                <div class="border-light p-3 b-radius-20">
                                    <span>TVA totale</span>
                                    <div>
                                        <strong class="value">{{ formatCurrency(stats.main.tva.total / 100) }}</strong>
                                    </div>
                                    <template v-if="displaying.stat_comparison">
                                        <ArrowPercentEvolutionComponent
                                            v-if="!(stats.main.tva.total == 0 && stats.comparison.tva.total == 0)"
                                            :percent="stats.evolution.tva.total" />
                                        <small class="text-muted">{{ labelPeriodComparison }}</small>
                                        <div>
                                            <span class="text-success">{{
                                                formatCurrency(stats.comparison.tva.total / 100)
                                            }}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="!has_right_to_read" class="alert alert-danger">
                        Désolé, vous n'avez pas le droit d'accéder à cette page.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../components/LoaderComponent";
import ArrowPercentEvolutionComponent from "../../components/charts/ArrowPercentEvolutionComponent";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import CollectTypesEnum from "../../mixins/enums/click_and_collect/CollectTypesEnum";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum";

export default {
    data() {
        return {
            loading: false,
            error: null,
            stats: null,
            stat_comparison: false,
            stat_period: "today",
            comparison_period: "previous_period",
            filter_date: "collect",
            filter_collect_type: null,
            from_date: null,
            to_date: null,
            from_date_comparison: null,
            to_date_comparison: null,
            displaying: {
                stat_comparison: false,
                stat_period: "today",
                comparison_period: "previous_period",
                from_date: null,
                to_date: null,
                from_date_comparison: null,
                to_date_comparison: null,
            },
        };
    },
    mixins: [CollectTypesEnum, ModuleTypesEnum],
    props: {
        user: {
            required: true,
        },
    },
    computed: {
        availableCollectTypesForFiltering() {
            let collectTypes = [this.COLLECT_TYPE_COLLECT, this.COLLECT_TYPE_DELIVERY];
            if (
                this.$store.getters["restaurants/hasModule"](
                    this.MODULE_TYPE_CLICK_AND_COLLECT_DISPATCH.value,
                    this.$route.params.restaurant_id
                )
            )
                collectTypes.push(this.COLLECT_TYPE_DISPATCH);
            return collectTypes;
        },
        isTvaDisabled() {
            return this.$store.getters["restaurants/isTvaDisabled"](this.$route.params.restaurant_id);
        },
        isAdmin() {
            return this.$store.getters["users/role"] === "admin";
        },
        disabledDates() {
            return {
                to: moment().subtract(this.keepClientsDetailsAndHistoryFor, "year").toDate(),
            };
        },
        keepClientsDetailsAndHistoryFor() {
            return this.$store.getters["users/keepClientsDetailsAndHistoryFor"];
        },
        fr() {
            return fr;
        },
        labelPeriodMain() {
            switch (this.displaying.stat_period) {
                case "today":
                    return "Aujourd'hui";
                case "yesterday":
                    return "Hier";
                case "last_week":
                    return "La semaine dernière";
                case "last_month":
                    return "Le mois dernier";
                case "last_year":
                    return "L'année dernière";
                case "custom_period":
                    let period = "";
                    if (this.displaying.from_date != null) period += this.formatDateToFr(this.displaying.from_date);
                    period += " - ";
                    if (this.displaying.to_date != null) period += this.formatDateToFr(this.displaying.to_date);
                    if (period === " - ") return "Depuis l'inscription";
                    return period;
                default:
                    return "";
            }
        },
        labelPeriodComparison() {
            if (!this.displaying.stat_comparison) return "";
            let period = "";
            if (this.displaying.from_date_comparison != null)
                period += this.formatDateToFr(this.displaying.from_date_comparison);
            period += " - ";
            if (this.displaying.to_date_comparison != null)
                period += this.formatDateToFr(this.displaying.to_date_comparison);
            if (period === " - ") return "Depuis l'inscription";
            return period;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read() {
            return (
                this.rights.includes("click_and_collect.stats.read") || this.$store.getters["users/role"] === "admin"
            );
        },
    },
    methods: {
        getFromAndToDateMain() {
            let dates = {
                from_date: null,
                to_date: null,
            };
            switch (this.stat_period) {
                case "today":
                    dates.from_date = moment().format("YYYY-MM-DD");
                    dates.to_date = moment().format("YYYY-MM-DD");
                    break;
                case "yesterday":
                    dates.from_date = moment().subtract(1, "day").format("YYYY-MM-DD");
                    dates.to_date = moment().subtract(1, "days").format("YYYY-MM-DD");
                    break;
                case "last_week":
                    dates.from_date = moment().subtract(1, "week").format("YYYY-MM-DD");
                    dates.to_date = moment().format("YYYY-MM-DD");
                    break;
                case "last_month":
                    dates.from_date = moment().subtract(1, "month").format("YYYY-MM-DD");
                    dates.to_date = moment().format("YYYY-MM-DD");
                    break;
                case "last_year":
                    dates.from_date = moment().subtract(1, "year").format("YYYY-MM-DD");
                    dates.to_date = moment().format("YYYY-MM-DD");
                    break;
                case "custom_period":
                    if (this.from_date != null) dates.from_date = this.getDate(this.from_date);
                    if (this.to_date != null) dates.to_date = this.getDate(this.to_date);
                    break;
                default:
                    break;
            }
            return dates;
        },
        getFromAndToDateComparison(from_date_main, to_date_main) {
            let dates = {
                from_date: null,
                to_date: null,
            };
            switch (this.comparison_period) {
                case "custom_period":
                    if (this.from_date_comparison != null) dates.from_date = this.getDate(this.from_date_comparison);
                    if (this.to_date_comparison != null) dates.to_date = this.getDate(this.to_date_comparison);
                    break;
                case "previous_period":
                    let from_date_main_moment = moment(from_date_main);
                    let diff_in_days = moment(to_date_main).diff(from_date_main_moment, "days") + 1;
                    dates.from_date = from_date_main_moment.clone().subtract(diff_in_days, "days").format("YYYY-MM-DD");
                    dates.to_date = from_date_main_moment.subtract(1, "days").format("YYYY-MM-DD");
                    break;
                case "previous_year":
                    dates.from_date = moment(from_date_main).subtract(1, "years").format("YYYY-MM-DD");
                    dates.to_date = moment(to_date_main).subtract(1, "years").format("YYYY-MM-DD");
                default:
                    break;
            }
            return dates;
        },
        getDate(date) {
            return moment(date).format("Y-MM-DD");
        },
        formatDateToFr(date) {
            return moment(date).format("DD/MM/Y");
        },
        fetchData() {
            let dates_main = this.getFromAndToDateMain();
            if (
                (this.comparison_period === "previous_period" || this.comparison_period === "previous_year") &&
                (dates_main.from_date == null || dates_main.to_date == null) &&
                this.stat_comparison
            ) {
                this.error = "Merci de saisir une date de début et de fin";
                return;
            }

            this.loading = true;
            this.error = null;

            let url = `/api/click_and_collect/${this.$route.params.restaurant_id}/sales/stats?compare=`;
            if (this.stat_comparison) url += "1";
            if (dates_main.from_date !== null) url += "&from=" + dates_main.from_date;
            if (dates_main.to_date !== null) url += "&to=" + dates_main.to_date;
            if (this.stat_comparison) {
                var dates_comparison = this.getFromAndToDateComparison(dates_main.from_date, dates_main.to_date);
                if (dates_comparison.from_date !== null) url += "&from_comparison=" + dates_comparison.from_date;
                if (dates_comparison.to_date !== null) url += "&to_comparison=" + dates_comparison.to_date;
            }
            if (this.filter_date) {
                url += "&filter_date=" + this.filter_date;
            }
            if (this.filter_collect_type) {
                url += "&filter_collect_type=" + this.filter_collect_type;
            }

            axios
                .get(url)
                .then((response) => {
                    this.loading = false;
                    if (response.data.send_email) {
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: response.data.message,
                        });
                        return;
                    }
                    this.stats = response.data.stats;
                    this.displaying.stat_comparison = this.stat_comparison;
                    this.displaying.stat_period = this.stat_period;
                    this.displaying.comparison_period = this.comparison_period;
                    this.displaying.from_date = this.from_date;
                    this.displaying.to_date = this.to_date;
                    this.displaying.from_date_comparison = this.stat_comparison ? dates_comparison.from_date : null;
                    this.displaying.to_date_comparison = this.stat_comparison ? dates_comparison.to_date : null;
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
    },
    components: {
        LoaderComponent,
        Datepicker,
        ArrowPercentEvolutionComponent,
    },
    created() {
        if (this.has_right_to_read) this.fetchData();
    },
};
</script>
