<template>
    <div id="widgetPagePublic">
        <div class="container" style="max-width: 700px;">
            <div class="row">
                <div class="col-12">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        data: function () {
            return {

            }
        },
        created() {
        },
        methods: {

        },
        watch: {

        }
    }
</script>
