<template>
    <ul
        class="settings-menu nav nav-tabs"
        :class="[
            {
                'flex-column': direction === 'vertical',
                'flex-row': direction === 'horizontal',
            },
            side,
        ]"
        role="tablist">
        <TabBarItem
            v-for="(entry, index) in activeEntries"
            :key="index"
            :class="itemClasses"
            :entry="entry"
            :is-event-mode="isEventMode"
            :is-active="active === index"
            @set-active="active = index" />
    </ul>
</template>

<script>
import TabBarItem from "./TabBarItem.vue";

export default {
    name: "TabBar",
    data() {
        return {
            active: null,
        };
    },
    props: {
        entries: {
            default: () => [],
        },
        direction: {
            required: true,
            type: String,
            validator: function (value) {
                return ["vertical", "horizontal"].indexOf(value) !== -1;
            },
        },
        side: {
            type: String,
            required: true,
            validator: function (value) {
                return ["top", "right", "bottom", "left"].indexOf(value) !== -1;
            },
        },
        itemClasses: {
            type: String,
            required: false,
            default: "",
        },
        // Use $emit() on <span> instead of <router-link>
        isEventMode: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        activeEntries() {
            return this.entries.filter((entry) => !entry.hide);
        },
    },
    watch: {
        active(newVal) {
            this.$emit("set-active", newVal);
        },
    },
    created() {
        if (this.isEventMode && this.activeEntries.length > 0) {
            this.active = 0;
        }
    },
    components: {
        TabBarItem,
    },
};
</script>
