var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [
            _vm.menuOptionId !== null
              ? _c("div", [
                  _c("h5", { staticClass: "title mt-2" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t(`labels.booking.${_vm.translationKey}.edit`)
                        ) +
                        "\n                        "
                    ),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.menuOptionName)),
                    ]),
                  ]),
                ])
              : _c("div", [
                  _c("h5", { staticClass: "title mt-2" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$tl("labels.booking.menus.options.add")) +
                        "\n                    "
                    ),
                  ]),
                ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row m-0" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("menu-option-form", {
            ref: "form",
            attrs: {
              "restaurant-id": _vm.restaurantId,
              "menu-option-id": _vm.menuOptionId,
              "option-type": _vm.menuOptionType,
              "show-option-type-choice": "",
              "show-save-button": "",
            },
            on: {
              "fully-loaded": _vm.onFormLoaded,
              submit: _vm.onFormSubmitted,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }