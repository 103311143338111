import * as Sentry from "@sentry/vue";
import Vue from "vue";
import { PRODUCTION } from "../utils/envs.js";

const initSentry = function (routes) {
    const tracesSampleRate = APP_ENV === PRODUCTION ? 0.1 : 1.0;

    const dsn =
        THEME === "yservices"
            ? "https://c3bda1fa0b5c4d48b815155f6256e6c8@o59715.ingest.sentry.io/4504633553780744"
            : "https://09fbd29ee07b4dfbbbae524bbc2c8698@o59715.ingest.sentry.io/6228104";

    Sentry.init({
        Vue,
        dsn,
        tracesSampleRate,
        logErrors: true,
        environment: APP_ENV,
        integrations: [Sentry.browserTracingIntegration({ router: routes })],
        /*
         * Uncomment to test in local environment without actually sending event to Sentry
         * Compilation must be used with env ENABLE_SENTRY=1, such as:
         * `ENABLE_SENTRY=1 yarn watch`
         */
        beforeSend(event) {
            console.error("Sentry event catched");
            console.error(event);
            return null;
        },
    });
};

export { initSentry };
