var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("loader-component")
        : _c("div", [
            _c("h6", { staticClass: "mb-3" }, [
              _vm._v(_vm._s(_vm.$tl("labels.rules"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "label",
                  {
                    staticClass: "container-box mr-0",
                    staticStyle: { width: "initial", "margin-top": "5px" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.loyaltyConfigReset.enabled,
                          expression: "loyaltyConfigReset.enabled",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        disabled: !_vm.hasRightToEdit,
                      },
                      domProps: {
                        checked: Array.isArray(_vm.loyaltyConfigReset.enabled)
                          ? _vm._i(_vm.loyaltyConfigReset.enabled, null) > -1
                          : _vm.loyaltyConfigReset.enabled,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.loyaltyConfigReset.enabled,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.loyaltyConfigReset,
                                  "enabled",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.loyaltyConfigReset,
                                  "enabled",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.loyaltyConfigReset, "enabled", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "checkmark mr-1 mt-lg-1",
                      class: { disabled: !_vm.hasRightToEdit },
                      staticStyle: { "margin-top": "0px" },
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$tl(
                            `labels.clients.loyaltyConfigs.resetFrom.${_vm.moduleEnum.value}`
                          )
                        ) +
                        "\n                "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.loyaltyConfigReset.from,
                          expression: "loyaltyConfigReset.from",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "form-control d-inline-block ml-1 mr-1 p-1",
                      staticStyle: {
                        width: "70px",
                        height: "30px",
                        opacity: "1",
                        cursor: "initial",
                      },
                      attrs: {
                        type: "number",
                        disabled:
                          !_vm.loyaltyConfigReset.enabled ||
                          !_vm.hasRightToEdit,
                        min: 1,
                      },
                      domProps: { value: _vm.loyaltyConfigReset.from },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.loyaltyConfigReset,
                            "from",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.day")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("show-errors", {
                  staticClass: "d-block",
                  attrs: { errors: _vm.errors.form, "error-key": "enabled" },
                }),
                _vm._v(" "),
                _c("show-errors", {
                  staticClass: "d-block",
                  attrs: { errors: _vm.errors.form, "error-key": "from" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mt-1" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success btn-circle",
                  attrs: { type: "button", disabled: !_vm.hasRightToEdit },
                  on: { click: _vm.saveLoyaltyReset },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.form.actions.save")) +
                      "\n            "
                  ),
                ]
              ),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }