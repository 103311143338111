<template>
    <div class="pb-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Ventes</h5>
                    <button :disabled="!has_right_to_create" @click="updateSale" class="btn btn-sm btn-success mr-1 btn-circle">
                        <feather type="plus"></feather>
                        <span> Ajouter un bon cadeau </span>
                    </button>
                </div>
            </div>
        </div>
        <gift-vouchers-action-bar
            :advanced-filters="advancedFilters"
            @fetch-data="updateDataFromFilterEvent"
            @show-modal="canDisplayModal[$event] = true"
            @export-sales="exportSales" />
        <loader-component v-if="isLoading" />
        <div v-else class="row m-0">
            <div class="col-12">
                <ns-table
                    tableClass="table table-striped overflowx-auto table-responsive-xl"
                    :data-source="sales"
                    is-server-side
                    use-slot-headings
                    clickable
                    allow-paging
                    :pagination="pagination"
                    @update-data="updateDataFromTableEvent"
                    @row-selected="rowSelected"
                    :current-sort="queries.sort">
                    <template v-slot:head>
                        <ns-headings :headings="headings" />
                    </template>
                    <template v-slot:body="{ data }">
                        <td>
                            <button
                                class="btn btn-sm btn-square"
                                :disabled="canUpdateSaleUse(data)"
                                v-tooltip="getTooltip(getTooltipLabel(data))"
                                @click="updateUseSale(data)">
                                <feather :type="data.used ? 'check-square' : 'square'" :class="data.used ? 'feather-green' : ''" />
                            </button>
                        </td>
                        <td>{{ data.numero }}</td>
                        <td><gift-voucher-status-component :sale="data" :editable="has_right_to_update" /></td>
                        <td>{{ formatAmount(data) }}</td>
                        <td>
                            <gift-vouchers-grid-product :sale="data" />
                        </td>
                        <td>{{ formatDate(data.expires_on) }}</td>
                        <td>
                            <inline-client v-if="data.client" :client="data.client" :module-enum="MODULE_TYPE_GIFT_VOUCHERS" />
                        </td>
                        <td>{{ formatBeneficiaryName(data) }}</td>
                        <td>
                            {{ getExpeditionTypeLabel(data.expedition_type) }}
                            <feather
                                v-if="data.expedition_type === EXPEDITION_TYPE_POSTAL_MAIL.value"
                                v-tooltip="getTooltip(getDeliveryAddressWithName(data), { html: true })"
                                type="truck"
                                class="ml-1" />
                        </td>
                        <td>
                            <gift-vouchers-grid-actions
                                v-if="data.sale_type !== SALE_TYPE_IMPORTED.value"
                                :sale="data"
                                :restaurant-id="restaurantId"
                                :has-right-to-update="has_right_to_update"
                                :disabled="isDisabled"
                                :is-fragment-loading="isFragmentLoading"
                                @edit-sale="updateSale($event, data)"
                                @send-gv-details="sendGvDetailsToClient" />
                        </td>
                    </template>
                </ns-table>
            </div>
        </div>
        <show-gift-voucher-modal
            v-if="canDisplayModal.show && has_right_to_read"
            :sale_id="selectedSale.id"
            must-not-emit
            @close="canDisplayModal.show = false" />
        <add-gift-voucher-modal
            v-if="canDisplayModal.add && (has_right_to_create || has_right_to_update)"
            :sale="selectedSale"
            @saved="refreshSales('add')"
            @close="canDisplayModal.add = false" />
        <scan-gift-voucher-modal
            v-if="canDisplayModal.scan && (has_right_to_create || has_right_to_update)"
            @close="canDisplayModal.scan = false"
            @updated="scanUpdatedGvSale" />
        <import-gift-vouchers-modal v-if="canDisplayModal.import" @imported="refreshSales('import')" @close="canDisplayModal.import = false" />
    </div>
</template>

<script>
import addGiftVoucherModal from "../../components/Modals/giftVouchers/addGiftVoucherModal.vue";
import showGiftVoucherModal from "../../components/Modals/giftVouchers/showGiftVoucherModal.vue";
import ScanGiftVoucherModal from "../../components/Modals/giftVouchers/ScanGiftVoucherModal.vue";
import LoaderComponent from "../../components/LoaderComponent.vue";
import GiftVoucherStatusComponent from "../../components/giftVouchers/GiftVoucherStatusComponent";
import SaleStatusEnum from "../../mixins/enums/gift_vouchers/SaleStatusEnum";
import ExpeditionTypesEnum from "../../mixins/enums/gift_vouchers/ExpeditionTypesEnum";
import SaleTypesEnum from "../../mixins/enums/gift_vouchers/SaleTypesEnum";
import { DateTime } from "luxon";
import ImportGiftVouchersModal from "../../components/Modals/giftVouchers/ImportGiftVouchersModal";
import GiftVouchersGridProduct from "../../components/giftVouchers/GiftVouchersGridProduct.vue";
import GiftVouchersGridActions from "../../components/giftVouchers/GiftVouchersGridActions.vue";
import NsHeadings from "../../components/Datatable/NsHeadings.vue";
import NsTable from "../../components/Datatable/NsTable.vue";
import GiftVouchersActionBar from "../../components/giftVouchers/GiftVouchersActionBar.vue";
import inlineClient from "../../components/Default/Clients/inlineClient.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum";

// Need this object because of how the gv add modal works
const defaultSale = {
    id: null,
    gv_product_id: null,
    numero: null,
    status: null,
    amount: null,
    paid_at: null,
    has_beneficiary: false,
    beneficiary_firstname: null,
    beneficiary_lastname: null,
    beneficiary_email: null,
    delivery_firstname: null,
    delivery_lastname: null,
    delivery_address: null,
    delivery_city: null,
    delivery_postal_code: null,
    delivery_country: null,
    send_payment_ask: false,
    payment_type: null,
    expedition_type: "email",
    menu_quantity: 1,
    client: {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        tel: null,
    },
    expires_on: null,
    comment_private: null,
};

export default {
    data() {
        return {
            isLoading: false,
            isFragmentLoading: {},
            isDisabled: false,
            pagination: {
                count: 0,
                current_page: 1,
                per_page: 12,
                total: 0,
                total_pages: 1,
            },
            queries: {
                page: 1,
                per_page: 12,
                sort: {
                    field: null,
                    order: null,
                },
                filters: {
                    status: null,
                    expedition_type: null,
                    from: null,
                    to: null,
                },
                search: "",
            },
            canDisplayModal: {
                show: false,
                add: false,
                scan: false,
                import: false,
            },
            selectedSale: null,
            advancedFilters: [
                {
                    title: "Statut",
                    defaultLabel: "Tous",
                    filter: "status",
                    options: [],
                },
                {
                    title: "Type d'envoi",
                    defaultLabel: "--",
                    filter: "expedition_type",
                    options: [],
                },
            ],
            headings: [
                {
                    field: "gv_sales.used",
                    width: "4.3",
                    icon: {
                        name: "check",
                    },
                    allowSorting: true,
                },
                {
                    field: "gv_sales.numero",
                    width: "10.6",
                    title: { content: "N°" },
                    allowSorting: true,
                },
                {
                    field: "gv_sales.status",
                    title: { content: "Statut" },
                    width: "8.2",
                },
                {
                    field: "gv_sales.amount",
                    title: { content: "Montant" },
                    allowSorting: true,
                    width: "7.2",
                },
                {
                    field: "gv_sales.product",
                    title: { content: "Produit" },
                    width: "12",
                    allowSorting: true,
                },
                {
                    field: "gv_sales.expires_on",
                    title: { content: "Expiration" },
                    allowSorting: true,
                    width: "8.6",
                },
                {
                    field: "gv_sales.client",
                    title: { content: "Client" },
                    allowSorting: true,
                    width: "15.4",
                },
                {
                    field: "gv_sales.beneficiary",
                    title: { content: "Bénéficiaire" },
                    allowSorting: true,
                    width: "11.1",
                },
                {
                    field: "gv_sales.expedition_type",
                    title: { content: "Expédition" },
                    allowSorting: true,
                    width: "8.8",
                },
                {
                    title: { content: "Action" },
                    width: "11",
                },
            ],
        };
    },
    mixins: [SaleStatusEnum, ExpeditionTypesEnum, SaleTypesEnum, ModuleTypesEnum],
    props: {
        user: {
            required: true,
        },
    },
    computed: {
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create() {
            return this.rights.includes("gift_vouchers.sales.create");
        },
        has_right_to_update() {
            return this.rights.includes("gift_vouchers.sales.update");
        },
        has_right_to_read() {
            return this.rights.includes("gift_vouchers.sales.read");
        },
        has_right_to_export() {
            return this.rights.includes("gift_vouchers.sales.export");
        },
    },
    methods: {
        getDeliveryAddressWithName(data) {
            return `${data.delivery_firstname} ${data.delivery_lastname}<br />${this.escapeHtml(data.full_delivery_address)}`;
        },
        prepareAndGetSales() {
            this.setSearchAndFilterQueries();
            this.getSales();
        },
        getTooltipLabel(sale) {
            if (this.canUpdateSaleUse(sale)) {
                return "";
            }
            return sale.used ? "Marquer comme inutilisé" : "Marquer comme utilisé";
        },
        canUpdateSaleUse(data) {
            const toExclude = [this.SALE_CAPTURED.value, this.SALE_PAID.value];

            return !this.has_right_to_update || !toExclude.includes(data.status);
        },
        formatAmount(data) {
            return data.amount !== null
                ? this.formatCurrency(data.expedition_fees !== null ? data.amount + data.expedition_fees : data.amount)
                : "--";
        },
        formatBeneficiaryName(data) {
            return data.has_beneficiary ? `${data.beneficiary_firstname} ${data.beneficiary_lastname.toUpperCase()}` : "--";
        },
        updateDataFromTableEvent(event) {
            this.queries.sort.field = null;
            this.queries.sort.order = null;

            this.queries.page = event.page;
            this.queries.per_page = event.per_page;
            if (event.field !== null && event.order !== null) {
                this.queries.sort.field = event.field;
                this.queries.sort.order = event.order;
            }
            this.getSales();
        },
        updateDataFromFilterEvent(event) {
            this.queries.filters = { ...event.filters };
            this.queries.search = event.search;
            this.queries.page = 1;
            this.getSales();
        },
        scanUpdatedGvSale() {
            this.showScanGiftVoucherModal = false;
            this.fetchData();
        },
        rowSelected({ event, data }) {
            const tagsToExclude = ["button", "a", "svg", "rect", "path", "line"];
            const targetTagName = event && event.target && event.target.tagName ? event.target.tagName.toLowerCase() : "";

            if (!tagsToExclude.includes(targetTagName)) {
                event.stopPropagation();
                event.preventDefault();
                this.selectedSale = data;
                this.canDisplayModal.show = true;
            }
        },
        updateSale(event, data = null) {
            this.selectedSale = data === null ? { ...defaultSale } : data;
            this.canDisplayModal.add = true;
        },
        refreshSales(modal) {
            this.canDisplayModal[modal] = false;
            this.getSales();
        },
        getDate(date) {
            const toTwoDigits = (num) => (num < 10 ? "0" + num : num);
            let year = date.getFullYear();
            let month = toTwoDigits(date.getMonth() + 1);
            let day = toTwoDigits(date.getDate());
            return `${year}-${month}-${day}`;
        },
        formatDate(date) {
            return DateTime.fromISO(date).toLocaleString();
        },
        formatExpeditionTypes(ExpeditionTypes) {
            let options = [];
            for (const expeditionType of ExpeditionTypes) {
                options.push({
                    value: expeditionType,
                    label: this.getExpeditionTypeLabel(expeditionType),
                });
            }
            return options;
        },
        getSales() {
            this.isLoading = true;
            const includes = "?include=user,client,gv_product,gv_sale_product_options,gv_sale_product_options.gv_products_option";

            const queries = this.parseQueries();

            this.httpGet(`/api/gift_vouchers/restaurants/${this.restaurantId}/sales${includes}${queries}`)
                .then((response) => {
                    this.sales = response.data.sales.data;
                    this.advancedFilters[1].options = this.formatExpeditionTypes(response.data.available_expedition_types);
                    this.pagination = response.data.sales.meta.pagination;
                    if (this.pagination.per_page === this.pagination.total) {
                        this.pagination.per_page = "all";
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        parseQueries() {
            const filters = this.parseQueryFilters();
            const search = this.parseSearch();
            const sort = this.parseQuerySort();
            const page = this.parseQueryPage();

            return filters + search + sort + page;
        },
        parseQueryFilters() {
            let queryFilters = "";

            Object.keys(this.queries.filters).forEach((key) => {
                if (this.queries.filters[key] !== null) {
                    const value = this.queries.filters[key] instanceof DateTime ? this.queries.filters[key].toISODate() : this.queries.filters[key];
                    queryFilters = queryFilters.concat("&", `${key}=${value}`);
                }
            });
            return queryFilters;
        },
        parseSearch() {
            if (this.queries.search !== "" && this.queries.search !== null) {
                return "&".concat("search=", this.queries.search);
            }
            return "";
        },
        parseQuerySort() {
            if (this.queries.sort.field !== null && this.queries.sort.order !== null) {
                return "&".concat("sort=", this.queries.sort.field) + "&".concat("order=", this.queries.sort.order);
            }
            return "";
        },
        parseQueryPage() {
            return "&".concat("page=", this.queries.page) + "&".concat("per_page=", this.queries.per_page);
        },
        sendGvDetailsToClient(saleId) {
            this.isFragmentLoading[saleId] = true;
            this.isDisabled = true;
            this.httpPost(`/api/gift_vouchers/${this.restaurantId}/sales/${saleId}/sendGvDetailsToClient`).finally(() => {
                delete this.isFragmentLoading[saleId];
                this.isDisabled = false;
            });
        },
        updateUseSale(sale) {
            if (sale.used && !confirm(`Êtes vous sûr de vouloir marquer le bon cadeau suivant: ${sale.numero} comme "inutilisé" ?`)) {
                return;
            }
            this.isLoading = true;
            this.httpPut(`/api/gift_vouchers/${this.restaurantId}/sales/${sale.id}/updateUsed`, { used: !sale.used })
                .then((response) => {
                    if (response !== false) {
                        this.getSales();
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        exportSales() {
            if (!confirm("L'exportation des bons cadeaux prendra en compte les filtres sélectionnés. Souhaitez vous continuer ?")) {
                return;
            }

            const url = `/api/gift_vouchers/${this.restaurantId}/export/sales?`;
            const queries = this.parseQueries();
            window.open(`${url}${queries}`, "queries.filters._blank");
        },
    },
    components: {
        addGiftVoucherModal,
        showGiftVoucherModal,
        LoaderComponent,
        ScanGiftVoucherModal,
        GiftVoucherStatusComponent,
        ImportGiftVouchersModal,
        NsHeadings,
        NsTable,
        GiftVouchersGridProduct,
        GiftVouchersGridActions,
        GiftVouchersActionBar,
        inlineClient,
    },
    watch: {
        "$route.query.gv_sale_id": {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.showShowGiftVoucherModal = true;
                    this.sale_id = newVal;
                }
            },
        },
    },
    created() {
        this.selectedSale = defaultSale;
        this.advancedFilters[0].options = this.SALE_STATUS_FOR_FILTER_FOR_SALES;
        this.getSales();
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
