export default {
    data() {
        return {
            DAILY_STOCK: {
                label: "journalier",
                value: "daily",
            },
            GENERAL_STOCK: {
                label: "général",
                value: "general",
            },
        };
    },
    methods: {
        getStockTypeLabel(value) {
            const found = this.ALL_STOCK_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_STOCK_TYPES() {
            return [this.DAILY_STOCK, this.GENERAL_STOCK];
        },
    },
};
