<template>
    <div>
        <div class="mt-3 ml-3 d-flex justify-content-between">
            <h5>{{ $tl("labels.routes.booking.config.seatingPlan") }}</h5>
            <button class="btn btn-sm btn-circle btn-success mr-3" :disabled="!has_right_to_save_seatingplan" @click="addSeatingPlan">
                <feather type="plus" /> {{ $tl("labels.booking.seatingPlan.add") }}
            </button>
        </div>
        <div class="p-3">
            <LoaderComponent v-if="loading" />
            <table v-else class="table table-sm table-striped border-bottom table-tr-clickable">
                <thead class="border-bottom">
                    <tr>
                        <th>{{ $tl("labels.booking.seatingPlan.columns.name") }}</th>
                        <th>{{ $tl("labels.booking.seatingPlan.columns.services", restaurant_id) }}</th>
                        <th>{{ $tl("labels.booking.seatingPlan.columns.createDate") }}</th>
                        <th>{{ $tl("labels.booking.seatingPlan.columns.updateDate") }}</th>
                        <th>{{ $tl("labels.booking.seatingPlan.columns.actions") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="seatingPlan in seatingPlans" :key="seatingPlan.id">
                        <td>{{ seatingPlan.name }}</td>
                        <td>
                            <span v-if="seatingPlan.services.data.length === 0">--</span>
                            <span v-else class="d-block" v-for="service in seatingPlan.services.data" :key="service.id">
                                {{ service.name }}
                            </span>
                        </td>
                        <td>{{ displayDate(seatingPlan.created_at, DATE_SHORT) }}</td>
                        <td>{{ displayDate(seatingPlan.updated_at, DATE_SHORT) }}</td>
                        <td>
                            <button
                                class="btn btn-sm btn-success btn-square"
                                @click="editSeatingPlan(seatingPlan)"
                                v-tooltip="getTooltip($t('labels.booking.seatingPlan.edit'))">
                                <feather type="edit" />
                            </button>
                            <button
                                class="btn btn-sm btn-success btn-square"
                                @click="duplicateSeatingPlan(seatingPlan)"
                                v-tooltip="getTooltip($t('labels.booking.seatingPlan.duplicate'))"
                                :disabled="!has_right_to_save_seatingplan">
                                <feather type="copy" />
                            </button>
                            <button
                                class="btn btn-sm btn-danger btn-square"
                                @click="deleteSeatingPlan(seatingPlan)"
                                v-tooltip="
                                    getTooltip(
                                        seatingPlan.services.data.length > 0
                                            ? $tl('infos.booking.seatingPlan.delete', restaurant_id)
                                            : $t('labels.booking.seatingPlan.delete')
                                    )
                                "
                                :disabled="!has_right_to_delete_seatingplan || seatingPlan.services.data.length > 0">
                                <feather type="trash-2" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../LoaderComponent";

export default {
    data() {
        return {
            loading: 0,
            seatingPlans: [],
        };
    },
    computed: {
        restaurant_id() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_save_seatingplan() {
            return this.rights.includes("booking.seatingplan.update");
        },
        has_right_to_delete_seatingplan() {
            return this.rights.includes("booking.seatingplan.delete");
        },
    },
    methods: {
        addSeatingPlan() {
            this.$router.push({
                to: "booking.restaurants.settings.seatingPlan",
                params: {
                    seating_plan_id: "add",
                },
            });
        },
        editSeatingPlan(seatingPlan) {
            this.$router.push({
                to: "booking.restaurants.settings.seatingPlan",
                params: {
                    seating_plan_id: seatingPlan.id,
                },
            });
        },
        duplicateSeatingPlan(seatingPlan) {
            this.$router.push({
                to: "booking.restaurants.settings.seatingPlan",
                params: {
                    seating_plan_id: `duplicate-${seatingPlan.id}`,
                },
            });
        },
        fetchSeatingPlans() {
            this.loading++;
            axios
                .get(`/api/restaurants/${this.restaurant_id}/seatingPlans?include=services`)
                .then((response) => {
                    this.loading--;
                    this.seatingPlans = response.data.data;
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        deleteSeatingPlan(seatingPlan) {
            if (!confirm(this.$t("questions.booking.seatingPlan.delete", { seatingPlan: seatingPlan.name }))) return;
            this.loading++;

            axios
                .delete(`/api/restaurants/${this.restaurant_id}/seatingPlans/${seatingPlan.id}`)
                .then((response) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.fetchSeatingPlans();
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
    },
    components: {
        LoaderComponent,
    },
    created() {
        this.fetchSeatingPlans();
    },
};
</script>
