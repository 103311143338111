<template>
    <nav class="navbar navbar-dark fixed-top flex-md-nowrap p-0 bg-light">
        <div
            ref="icon"
            class="navbar-brand col-sm-12 mr-0 bg-dark d-flex flex-row justify-content-between"
            :class="{
                'col-lg-2 col-md-3 navbar-brand-not-collaped': !isSidebarCollapsed,
                'col-lg-1 col-md-1 text-center navbar-brand-collapsed': isSidebarCollapsed,
            }">
            <div :style="isSidebarCollapsed ? 'margin: 0 auto' : ''">
                <button class="btn text-white" @click="$emit('module-switcher-button-clicked')">
                    <feather type="grid" size="22" class="w-auto h-auto" />
                </button>
                <router-link :to="home">
                    <template v-if="!isSidebarCollapsed">
                        <img class="img-logo display-sid-min" src="/images/logos-saas/reservation.png" v-if="isBooking" />
                        <img class="img-logo display-sid-min" src="/images/logos-saas/vae.png" v-else-if="isClickAndCollect" />
                        <img class="img-logo display-sid-min" src="/images/logos-saas/bc.png" v-else-if="isGiftVoucher" />
                        <img class="img-logo display-sid-min" src="/images/logos-saas/sw.png" v-else-if="isWebSite" />
                        <img class="img-logo display-sid-min" src="/images/logos-saas/campagne.png" v-else-if="isCampaigns" />
                    </template>
                    <div style="height: 28px" class="img-logo-sid-min" v-else></div>
                </router-link>
            </div>

            <div class="none-desk nav-btn mx-md-5" style="right: 55px">
                <button
                    class="btn btn-sm p-0 dropdown-toggle"
                    :class="{ 'bg-light': user && user.role === 'kitchen' }"
                    type="button"
                    id="dropdownCompte"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <feather
                        type="user"
                        style="color: white"
                        class="feather-22 feather-top-bar"
                        :class="{
                            'mr-0': isSidebarCollapsed,
                        }"></feather>
                </button>
                <div v-if="user" class="dropdown-menu text-right dropdown-menu-right shadow" aria-labelledby="dropdownCompte">
                    <h6 class="dropdown-header">{{ user.firstname }} {{ user.lastname }}</h6>
                    <router-link :to="{ name: 'account' }" class="dropdown-item">
                        {{ $tl("labels.routes.account") }}
                    </router-link>
                    <div class="dropdown-divider"></div>
                    <router-link :to="{ name: 'home' }" class="dropdown-item">
                        {{ $tl("labels.routes.home") }}
                    </router-link>
                    <router-link v-if="has_right_to_read_restaurants" :to="{ name: 'restaurants' }" class="dropdown-item">
                        {{ $tl("labels.routes.restaurants") }}
                    </router-link>
                    <router-link v-if="isNoShow && has_right_to_read_subscription" :to="{ name: 'subscription' }" class="dropdown-item">
                        {{ $tl("labels.routes.subscription") }}
                    </router-link>
                    <router-link v-if="isNoShow && has_right_to_read_employees" :to="{ name: 'employees' }" class="dropdown-item">
                        {{ $tl("labels.routes.users") }}
                    </router-link>
                    <router-link v-if="has_right_to_update_sms" :to="{ name: 'sms' }" class="dropdown-item">
                        {{ $tl("labels.routes.sms") }}
                    </router-link>

                    <div class="dropdown-divider"></div>
                    <template v-if="isNoShow">
                        <a class="open-intercom dropdown-item" href="#">
                            {{ $tl("labels.routes.help") }}
                        </a>
                        <router-link :to="{ name: 'online_help' }" class="dropdown-item">
                            {{ $tl("labels.routes.onlineHelp") }}
                        </router-link>
                    </template>
                    <a v-else-if="isYservices" class="dropdown-item" href="#" @click.prevent="openHelpscoutBeacon">
                        {{ $tl("labels.routes.help") }}
                    </a>
                    <router-link class="dropdown-item" :to="{ name: 'app_updates.list' }">
                        {{ $tl("labels.routes.appUpdates") }}
                        <div v-if="hasUnreadAppUpdates" class="red-bubble ml-2"></div>
                        <new-badge class="ml-2" v-if="checkForExistingNewBadge('app_updates')" />
                    </router-link>

                    <div class="dropdown-divider"></div>
                    <a style="color: #f75b61" class="dropdown-item" href="#" @click.prevent="logout">
                        {{ $tl("labels.routes.disconnect") }}
                        <feather type="log-out" :class="{ 'mr-0': isSidebarCollapsed }" />
                    </a>
                </div>
            </div>

            <feather
                @click="$emit('mobile-menu-updated', !mobileMenuDisplayed)"
                class="d-md-none w-auto h-auto mt-1"
                :type="mobileMenuDisplayed ? 'x' : 'menu'" />
        </div>

        <!-- always here when collapsed -->
        <div class="input-group restaurant-title p-2" v-if="restaurant_id && isSidebarCollapsed">
            <select class="custom-select rounded-pill pl-3" id="inputGroupSelect01" v-model="restaurant_id" @change="selectRestaurant($event)">
                <option v-for="restaurant in restaurants" :key="restaurant.id" :value="restaurant.id">
                    {{ restaurant.name }}
                </option>
            </select>
        </div>

        <!-- always here -->
        <div class="none-mobile none-tablet">
            <router-link
                v-if="has_right_to_update_sms && sms.activated !== null"
                :to="{ name: 'sms' }"
                class="ml-2 btn btn-sm btn-circle btn-outline-secondary"
                v-tooltip="{
                    delay: { show: 500, hide: 0 },
                    content: sms.activated !== null && sms.activated === false ? '' : 'Commander des SMS',
                }">
                <span class="sms-count" v-if="sms.activated === false">{{ $tl("labels.form.sms.enable") }}</span>
                <span class="“sms-count”" v-else-if="sms.credits">{{ sms.credits }} {{ $tl("labels.smsCredits") }}</span>
            </router-link>
            <div v-else-if="sms.activated !== null" class="ml-3 btn btn-sm btn-circle btn-outline-secondary">
                <span class="sms-count" v-if="sms.activated === false">{{ $tl("labels.form.sms.enable") }}</span>
                <span class="“sms-count”" v-else-if="sms.credits">{{ sms.credits }} {{ $tl("labels.smsCredits") }}</span>
            </div>
        </div>
        <slot />

        <!-- always here -->
        <ShowNotificationsComponent
            :showNotifications="notifications.show"
            :module_type="module_type"
            @open="notifications.show = true"
            @close="notifications.show = false" />

        <div class="nav-btn mx-md-5">
            <button
                class="btn btn-sm p-0 dropdown-toggle"
                :class="{ 'bg-light': user && user.role === 'kitchen' }"
                type="button"
                id="dropdownCompte"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <feather
                    type="user"
                    class="feather-22 feather-top-bar"
                    :class="{
                        'mr-0': isSidebarCollapsed,
                    }"></feather>
            </button>
            <div v-if="user" class="dropdown-menu text-right dropdown-menu-right shadow" aria-labelledby="dropdownCompte">
                <h6 class="dropdown-header">{{ user.firstname }} {{ user.lastname }}</h6>
                <router-link :to="{ name: 'account' }" class="dropdown-item">
                    {{ $tl("labels.routes.account") }}
                </router-link>
                <div class="dropdown-divider"></div>
                <router-link :to="{ name: 'home' }" class="dropdown-item">
                    {{ $tl("labels.routes.home") }}
                </router-link>
                <router-link v-if="has_right_to_read_restaurants" :to="{ name: 'restaurants' }" class="dropdown-item">
                    {{ $tl("labels.routes.restaurants") }}
                </router-link>
                <router-link v-if="isNoShow && has_right_to_read_subscription" :to="{ name: 'subscription' }" class="dropdown-item">
                    {{ $tl("labels.routes.subscription") }}
                </router-link>
                <router-link v-if="isNoShow && has_right_to_read_employees" :to="{ name: 'employees' }" class="dropdown-item">
                    {{ $tl("labels.routes.users") }}
                </router-link>
                <router-link v-if="has_right_to_update_sms" :to="{ name: 'sms' }" class="dropdown-item">
                    {{ $tl("labels.routes.sms") }}
                </router-link>

                <div class="dropdown-divider"></div>
                <template v-if="isNoShow">
                    <a class="open-intercom dropdown-item" href="#">
                        {{ $tl("labels.routes.help") }}
                    </a>
                    <router-link :to="{ name: 'online_help' }" class="dropdown-item">
                        {{ $tl("labels.routes.onlineHelp") }}
                    </router-link>
                </template>
                <a v-else-if="isYservices" class="dropdown-item" href="#" @click.prevent="openHelpscoutBeacon">
                    {{ $tl("labels.routes.help") }}
                </a>
                <router-link class="dropdown-item" :to="{ name: 'app_updates.list' }">
                    {{ $tl("labels.routes.appUpdates") }}
                    <div v-if="hasUnreadAppUpdates" class="red-bubble ml-2"></div>
                    <new-badge class="ml-2" v-if="checkForExistingNewBadge('app_updates')" />
                </router-link>

                <div class="dropdown-divider"></div>
                <a style="color: #f75b61" class="dropdown-item" href="#" @click.prevent="logout">
                    {{ $tl("labels.routes.disconnect") }}
                    <feather type="log-out" :class="{ 'mr-0': isSidebarCollapsed }" />
                </a>
            </div>
        </div>
    </nav>
</template>

<script>
import axios from "axios";
import ShowNotificationsComponent from "../components/notifications/ShowNotificationsComponent.vue";
import newBadge from "../components/NewBadges/newBadge.vue";

export default {
    name: "topbar",
    data() {
        return {
            sms: {
                activated: null,
                credits: null,
            },
            notifications: {
                show: false,
            },
        };
    },
    props: {
        user: {
            required: true,
        },
        isSidebarCollapsed: {
            default: false,
        },
        restaurants: {
            default: () => [],
        },
        home: {
            default: () => ({ name: "home" }),
        },
        mobileMenuDisplayed: {
            default: false,
        },
        module_type: {
            required: true,
            type: String,
        },
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read_subscription() {
            return this.rights.includes("default.subscription.read");
        },
        has_right_to_read_restaurants() {
            return this.rights.includes("default.restaurants.read");
        },
        has_right_to_read_employees() {
            return this.rights.includes("default.employees.read");
        },
        has_right_to_update_sms() {
            return this.rights.includes("default.sms.update");
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        isBooking() {
            return this.$route.name.startsWith("booking") || this.$route.params.module === "booking";
        },
        isClickAndCollect() {
            return this.$route.name.startsWith("click_and_collect") || ["click_and_collect", "click-and-collect"].includes(this.$route.params.module);
        },
        isGiftVoucher() {
            return this.$route.name.startsWith("gift_voucher") || ["gift_voucher", "gift-vouchers"].includes(this.$route.params.module);
        },
        isWebSite() {
            return this.$route.name.startsWith("website") || this.$route.params.module === "website";
        },
        isCampaigns() {
            return this.$route.name.startsWith("campaigns") || this.$route.params.module === "campaigns";
        },
        hasUnreadAppUpdates() {
            return this.$store.getters["users/hasUnreadAppUpdates"];
        },
    },
    created() {
        this.fetchSms();
    },
    methods: {
        openHelpscoutBeacon() {
            if (typeof Beacon !== "undefined") {
                Beacon("open");
            }
        },
        fetchSms() {
            this.loading += 1;
            axios
                .get("/api/sms/activation")
                .then((response) => {
                    if (response.data.isActivated) {
                        this.sms.activated = true;
                        this.fetchSmsCount();
                    } else {
                        this.sms.activated = false;
                    }

                    this.loading -= 1;
                })
                .catch((err) => {
                    this.smsActivated = false;
                    this.loading -= 1;
                });
        },
        fetchSmsCount() {
            this.loading += 1;
            axios
                .get("/api/sms/getCreditCount")
                .then((response) => {
                    this.sms.credits = response.data;
                    this.loading -= 1;
                })
                .catch((error) => {
                    this.loading -= 1;
                });
        },
        selectRestaurant(event) {
            if (this.user && this.user.role === "kitchen") {
                this.$router.push({
                    name: "booking.restaurants.reservationsCuisine",
                    params: { restaurant_id: event.target.value },
                });
                return;
            }
            this.$router.push({
                name: "booking.restaurants.reservations",
                params: { restaurant_id: event.target.value },
            });
        },
        logout() {
            axios
                .post("/logout")
                .then((response) => {
                    window.location.href = response.data.redirect_url || response.headers.location || "/login";
                })
                .catch(() => {});
        },
    },
    watch: {
        mobileMenuDisplayed: {
            handler: (newVal, oldVal) => {
                if (newVal === true) {
                    $(".sidebar").css("left", "30%");
                    $("main, .nav-btn-2").css("opacity", ".3");
                } else {
                    $(".sidebar").css("left", "110%");
                    $("main, .nav-btn-2").css("opacity", "1");
                }
            },
        },
    },
    components: {
        ShowNotificationsComponent,
        newBadge,
    },
};
</script>
