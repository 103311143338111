<template>
    <div class="ns-dropdown" @blur="hideDropdown" tabindex="0" ref="dropdown">
        <ul class="ns-dropdown__container">
            <li class="ns-dropdown__item" v-for="(item, index) in items" :key="`${index}-${item.label}`" @click.stop="selectedIndex({ item, index })">
                {{ item.label }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    methods: {
        hideDropdown() {
            this.$emit("close");
        },
        selectedIndex(selectedItem) {
            this.hideDropdown();
            this.$emit("selected", selectedItem);
        },
    },
    mounted() {
        this.$nextTick(() => this.$refs.dropdown.focus());
    },
};
</script>
