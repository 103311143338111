var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _vm.error
        ? _c("span", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _vm.service
        ? [
            _c("div", { staticClass: "mb-3" }, [
              _c(
                "strong",
                {
                  staticClass: "text-muted",
                  staticStyle: { "text-transform": "capitalize" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.getServiceCategoryLabel(_vm.service)) + " | "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "text-muted" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.service.name) +
                    " - " +
                    _vm._s(_vm.service.pax) +
                    " /\n                " +
                    _vm._s(
                      _vm.service.new_pax !== null
                        ? _vm.service.new_pax
                        : _vm.service.max_pax
                    ) +
                    "\n                "
                ),
                _vm.service.pax >
                (_vm.service.new_pax !== null
                  ? _vm.service.new_pax
                  : _vm.service.max_pax)
                  ? _c("strong", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(_vm.$tl("labels.paxTooHigh"))),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.service.is_full === 1 || _vm.service.isOpen == false
                ? _c(
                    "small",
                    {
                      staticClass:
                        "text-uppercase ml-2 d-inline-block badge-pill badge-danger text-white-hover",
                    },
                    [
                      _vm._v(
                        "\n                 " +
                          _vm._s(
                            _vm.service.is_full === 1
                              ? _vm.$tl("labels.booking.services.full")
                              : _vm.$tl("labels.booking.services.closedSpecial")
                          ) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex flex-wrap btn-select-slot-container" },
              _vm._l(_vm.service.slotsWithPax.data, function (slot) {
                return _c(
                  "button",
                  {
                    key: slot.id,
                    staticClass: "btn btn-select-slot p-2 mb-2",
                    class: {
                      "btn-success": slot.id === _vm.reservation.slot_id,
                      disabled: _vm.btnSlotReadonly(slot.id),
                    },
                    attrs: { disabled: _vm.btnSlotReadonly(slot.id) },
                    on: {
                      click: function ($event) {
                        return _vm.selectSlot(slot)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "d-block",
                        style:
                          slot.id === _vm.reservation.slot_id
                            ? "color: white !important"
                            : "",
                      },
                      [_vm._v(_vm._s(slot.hour_start))]
                    ),
                    _vm._v(" "),
                    _vm.service.is_table_rotation_enabled == true
                      ? _c(
                          "small",
                          {
                            staticClass: "text-muted",
                            style:
                              slot.id === _vm.reservation.slot_id
                                ? "color: white !important"
                                : "",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(slot.pax) +
                                " /\n                    " +
                                _vm._s(
                                  _vm.service.is_slot_max_pax_enabled &&
                                    slot.is_max_pax_enabled &&
                                    slot.max_pax !== null
                                    ? slot.max_pax
                                    : _vm.service.new_pax !== null
                                    ? _vm.service.new_pax
                                    : _vm.service.max_pax
                                ) +
                                "\n                    " +
                                _vm._s(_vm.$tl("labels.personsShort")) +
                                "\n                "
                            ),
                          ]
                        )
                      : _c(
                          "small",
                          {
                            staticClass: "text-muted",
                            style:
                              slot.id === _vm.reservation.slot_id
                                ? "color: white !important"
                                : "",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(slot.pax_real) +
                                "\n                    " +
                                _vm._s(
                                  _vm.service.is_slot_max_pax_enabled &&
                                    slot.is_max_pax_enabled &&
                                    slot.max_pax !== null
                                    ? `/ ${slot.max_pax}`
                                    : ``
                                ) +
                                "\n                    " +
                                _vm._s(_vm.$tl("labels.personsShort")) +
                                "\n                "
                            ),
                          ]
                        ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }