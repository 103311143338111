var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.formErrors &&
      _vm.formErrors.errors &&
      _vm.formErrors.errors.choosen_general_options
        ? _c(
            "div",
            _vm._l(
              _vm.formErrors.errors.choosen_general_options,
              function (err) {
                return _c(
                  "div",
                  { key: err, staticClass: "invalid-feedback d-block" },
                  [_vm._v("\n            " + _vm._s(err) + "\n        ")]
                )
              }
            ),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.available_general_options, function (o, oIndex) {
        return _c("div", { key: oIndex, staticClass: "one-menu mb-1" }, [
          _c("div", { staticClass: "row text-left mb-0" }, [
            _c("div", { staticClass: "col-sm-8" }, [
              _c("label", { staticClass: "mb-1" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(o.name) +
                    _vm._s(
                      o.price
                        ? ` - ${_vm.formatCurrency(
                            o.price,
                            _vm.getRestaurantCurrency(_vm.restaurantId)
                          )}`
                        : ""
                    ) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "d-block text-muted mb-2" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(o.description) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              o.quantity_type === "mandatory_for_each"
                ? _c("div", [
                    _c(
                      "select",
                      {
                        ref: `ref_general_option_${o.id}`,
                        refInFor: true,
                        staticClass: "custom-select",
                        on: {
                          change: function ($event) {
                            return _vm.chooseGeneralOption(o, $event)
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "0" } }, [_vm._v("--")]),
                        _vm._v(" "),
                        _c("option", { domProps: { value: _vm.nb_total } }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.nb_total) +
                              "\n                        "
                          ),
                        ]),
                      ]
                    ),
                  ])
                : o.quantity_type === "limited_by_pers"
                ? _c(
                    "select",
                    {
                      ref: `ref_general_option_${o.id}`,
                      refInFor: true,
                      staticClass: "custom-select",
                      staticStyle: { height: "40px", padding: "10px 15px" },
                      on: {
                        change: function ($event) {
                          return _vm.chooseGeneralOption(o, $event)
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "0" } }, [_vm._v("0")]),
                      _vm._v(" "),
                      _vm._l(_vm.nb_total, function (option, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: index * 1 + 1 } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(index * 1 + 1) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : o.quantity_type === "unlimited"
                ? _c("input", {
                    ref: `ref_general_option_${o.id}`,
                    refInFor: true,
                    staticClass: "form-control",
                    staticStyle: { height: "40px", padding: "10px 15px" },
                    attrs: { type: "number", step: "1", min: "0" },
                    domProps: { value: _vm.getBaseValue(o) },
                    on: {
                      change: function ($event) {
                        return _vm.chooseGeneralOption(o, $event)
                      },
                    },
                  })
                : _vm._e(),
            ]),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }