var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "tile restaurant border-light",
        class: _vm.clickable && !_vm.disabled ? "clickable" : "",
        style: !_vm.clickable || _vm.disabled ? "opacity: 0.5;" : "",
        on: { click: this.accessEstablishment },
      },
      [
        _c(
          "div",
          {
            staticClass: "logo",
            class: { background: _vm.restaurant.logo_url },
            style: _vm.computedStyle,
          },
          [
            !_vm.restaurant.logo_url
              ? _c("feather", {
                  staticClass: "grey feather-40",
                  attrs: { type: "home" },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "h6",
          {
            staticClass: "restaurant-title border-top-light text-center",
            attrs: { "data-test-id": "title-restaurant_name" },
          },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.restaurant.name) + "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }