var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.errors && Object.values(_vm.errors).length > 0
              ? _c(
                  "div",
                  { staticClass: "p-3 mb-3 rounded alert-danger" },
                  [
                    _c("ShowErrors", {
                      attrs: {
                        errors: _vm.errors,
                        errorKey: "*",
                        errClass: "",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row" },
              [
                _vm.canPreviousStep
                  ? _c(
                      "OverviewComponent",
                      _vm._b(
                        { attrs: { currentStep: _vm.steps.index } },
                        "OverviewComponent",
                        {
                          isSpecial: _vm.isSpecial,
                          service: _vm.service,
                          restaurant: _vm.restaurant,
                          restaurantsToSuggest: _vm.restaurantsToSuggest,
                          menus: _vm.menus,
                          generalOptions: _vm.generalOptions,
                          hasEnabledGoogleReserve: _vm.hasEnabledGoogleReserve,
                        },
                        false
                      )
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: {
                      "col-8": _vm.canPreviousStep,
                      "col-12": !_vm.canPreviousStep,
                    },
                  },
                  [
                    _c(
                      "form",
                      {
                        attrs: { id: "add-service-form" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.postForm.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "keep-alive",
                          [
                            _c(
                              _vm.componentName,
                              _vm._b(
                                {
                                  tag: "component",
                                  attrs: { "for-services": "" },
                                  on: {
                                    "has-errors": function ($event) {
                                      _vm.$_.isArray($event)
                                        ? (_vm.errors = $event)
                                        : _vm.$_.isString($event)
                                        ? (_vm.errors = [$event])
                                        : ""
                                    },
                                    "clear-errors": function ($event) {
                                      _vm.errors = []
                                    },
                                    "fetch-menus": _vm.fetchMenus,
                                    "fetch-options": _vm.fetchOptions,
                                    "update-menus": _vm.updateMenus,
                                    "update-general-options":
                                      _vm.updateGeneralOptions,
                                    "update-external-menus":
                                      _vm.updateExternalMenus,
                                  },
                                },
                                "component",
                                {
                                  isSpecial: _vm.isSpecial,
                                  seatingPlans: _vm.seatingPlans,
                                  service: _vm.service,
                                  widget: _vm.widget,
                                  restaurant: _vm.restaurant,
                                  restaurantsToSuggest:
                                    _vm.restaurantsToSuggest,
                                  menus: _vm.menus,
                                  generalOptions: _vm.generalOptions,
                                  externalMenuFromProps: _vm.externalMenu,
                                  settings: _vm.service.googleReserveSettings,
                                },
                                false
                              )
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex justify-content-end mt-3" }, [
              _c(
                "button",
                {
                  staticClass:
                    "modal-default-button btn btn-sm btn-secondary btn-circle",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$emit("close")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.canPreviousStep
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-success btn-circle ml-2",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.goPreviousStep.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$tl("labels.form.actions.previous")) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canNextStep
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-success btn-circle ml-2",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.goNextStep.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$tl("labels.form.actions.next")) +
                          "\n            "
                      ),
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-success btn-circle ml-2",
                      attrs: { type: "submit", form: "add-service-form" },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$tl("labels.form.actions.save")) +
                          "\n            "
                      ),
                    ]
                  ),
            ]),
          ]),
      _vm._v(" "),
      _vm.showNoSeatingPlanModal
        ? _c("no-seating-plan-modal", {
            attrs: { closeButtonTitle: "Retour" },
            on: {
              close: function ($event) {
                _vm.showNoSeatingPlanModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showConfirmModal
        ? _c("confirm-opening-create-modal", {
            attrs: {
              formLoading: _vm.isLoading,
              date_begin: _vm.service.general.special.openDate,
              date_end: _vm.service.general.special.closeDate,
              servicesId: _vm.showConfirmModalServicesId,
              cancelData: _vm.confirmModalData,
            },
            on: {
              close: function ($event) {
                _vm.showConfirmModal = false
              },
              confirmed: _vm.sendForm,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }