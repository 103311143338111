var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-100" }, [
    !_vm.isBrowserIE
      ? _c(
          "div",
          { staticClass: "back-off h-100" },
          [
            _c("topbar", { attrs: { url: { name: "register.finalize" } } }),
            _vm._v(" "),
            _c("div", { staticClass: "container-fluid" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "main",
                  {
                    staticClass: "col-12 px-0 mt-5 default registration",
                    attrs: { role: "main" },
                  },
                  [
                    _c("notifications", {
                      staticStyle: { "z-index": "9999" },
                      attrs: { group: "notification" },
                    }),
                    _vm._v(" "),
                    _vm._t("default"),
                  ],
                  2
                ),
              ]),
            ]),
          ],
          1
        )
      : _c("div", { staticStyle: { "text-align": "center" } }, [
          _c("h1", [_vm._v("Navigateur non supporté")]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("br"),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n            Votre navigateur n'est pas compatible avec le logiciel NoShow, veuillez utiliser\n            "
      ),
      _c(
        "a",
        { attrs: { href: "https://www.google.com/intl/fr_fr/chrome/" } },
        [_vm._v("Google Chrome")]
      ),
      _vm._v(" ou\n            "),
      _c("a", { attrs: { href: "https://www.mozilla.org/fr/" } }, [
        _vm._v("Mozilla Firefox"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }