var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [
                _vm._v(_vm._s(_vm.$tl("labels.form.licences.title"))),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("module-products-list", {
        attrs: {
          "preselected-modules": _vm.preselectedModules,
          "modules-enabled": _vm.modulesEnabled,
          plan: _vm.plan,
          onlyOneModule: _vm.onlyOneModule,
          training: _vm.training,
          "disable-payment": _vm.disablePayment,
          "disable-training": _vm.disableTraining,
          "disable-plan": _vm.disablePlan,
          max: _vm.max,
        },
        on: {
          "set-training": function ($event) {
            _vm.training = $event
          },
          "input-plan": function ($event) {
            _vm.plan = $event
          },
        },
        model: {
          value: _vm.modules,
          callback: function ($$v) {
            _vm.modules = $$v
          },
          expression: "modules",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row m-0 mt-2 mr-3 ml-3" }, [
        _vm.showBackButton
          ? _c(
              "button",
              {
                staticClass: "btn btn-secondary btn-circle mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("back")
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.back")) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-success btn-circle",
            class: { disabled: !_vm.selectedModules.length > 0 },
            attrs: { disabled: !_vm.selectedModules.length > 0 },
            on: {
              click: function ($event) {
                return _vm.$emit("selected", {
                  selectedModules: _vm.selectedModules,
                  plan: _vm.plan,
                  training: _vm.training,
                })
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.nextLabel || _vm.$tl("labels.form.actions.next")) +
                "\n        "
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }