export default {
    data() {
        return {
            charsCountDouble: ["€", "{", "|", "}", "~", "[", "\\", "]", "^"],
            nbCharsPerCredits: 160,
            maxCreditsPerSms: 3,
            maxEmitterLength: 11,
            emojisRegex: /\p{Extended_Pictographic}/u,
        };
    },
    methods: {
        getSmsLength(content, mergeTags = null) {
            if (Array.isArray(mergeTags)) {
                content = this.replaceSmsTagWithRandomChars(content, mergeTags);
            }

            return content.split("").reduce((length, currentChar) => length + (this.charsCountDouble.includes(currentChar) ? 2 : 1), 0);
        },
        getNbCreditsNeeded(length) {
            return Math.ceil(length / this.nbCharsPerCredits);
        },
        replaceSmsTagWithRandomChars(content, mergeTags = []) {
            return mergeTags.reduce(
                (previousContent, mergeTag) => previousContent.replaceAll(mergeTag.tag, "".padEnd(mergeTag.length, "a")),
                content
            );
        },
    },
};
