<template>
    <div>
        <div class="mt-3" v-if="command.logs && command.logs.data && command.logs.data.length > 0">
            <ShowCommandLog v-for="log in command.logs.data" :key="log.id" :log="log" />
        </div>
        <span class="text-muted mt-3 d-inline-block" v-else>Aucune modification n'a été enregistrée pour cette commande.</span>
    </div>
</template>

<script>
import ShowCommandLog from "./ShowCommandLog.vue";

export default {
    props: {
        command: {
            type: Object,
            required: true,
        },
    },
    components: {
        ShowCommandLog,
    },
};
</script>
