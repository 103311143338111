export default {
    data() {
        return {
            DISCOUNT_TYPE_PERCENT_OFF: {
                label: "Réduction",
                value: "percent_off",
            },
            DISCOUNT_TYPE_AMOUNT_OFF: {
                label: "Montant",
                value: "amount_off",
            },
        };
    },
    computed: {
        ALL_DISCOUNT_TYPES() {
            return [this.DISCOUNT_TYPE_PERCENT_OFF, this.DISCOUNT_TYPE_AMOUNT_OFF];
        },
    },
};
