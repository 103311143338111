<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ promoCodeId === null ? "Ajouter" : "Éditer" }} un code promotionnel</h5>
        <div slot="body">
            <add-click-and-collect-promo-code
                ref="addClickAndCollectPromoCode"
                :promoCodeId="promoCodeId"
                @saved="$emit('saved')" />
        </div>
        <div slot="footer" class="d-flex">
            <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="close">
                Annuler
            </button>
            <button
                type="button"
                class="btn btn-sm btn-success btn-circle ml-2"
                @click="$refs.addClickAndCollectPromoCode.save()">
                Enregistrer
            </button>
        </div>
    </modal>
</template>

<script>
import addClickAndCollectPromoCode from "../../../clickAndCollect/settings/addClickAndCollectPromoCode";

export default {
    props: {
        promoCodeId: {
            default: null,
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        addClickAndCollectPromoCode,
    },
};
</script>
