var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c("h5", { staticClass: "title mt-2" }, [
                  _vm._v("Commandes à livrer"),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltip(
                          "Raffraichir la liste des commandes"
                        ),
                        expression:
                          "getTooltip('Raffraichir la liste des commandes')",
                      },
                    ],
                    staticClass:
                      "btn btn-sm btn-outline-secondary btn-square ml-2",
                    on: {
                      click: function ($event) {
                        return _vm.fetchCommands()
                      },
                    },
                  },
                  [_c("feather", { attrs: { type: "refresh-cw" } })],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-outline-secondary btn-circle",
                    on: {
                      click: function ($event) {
                        return _vm.exportCommands()
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "feather feather-upload",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 24",
                          fill: "none",
                          stroke: "currentColor",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4",
                          },
                        }),
                        _vm._v(" "),
                        _c("polyline", { attrs: { points: "17 8 12 3 7 8" } }),
                        _vm._v(" "),
                        _c("line", {
                          attrs: { x1: "12", y1: "3", x2: "12", y2: "15" },
                        }),
                      ]
                    ),
                    _vm._v(
                      "\n                        Export Excel\n                    "
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.errors.length > 0
        ? _c(
            "div",
            {
              staticClass: "alert alert-danger",
              staticStyle: { position: "relative", top: "0" },
            },
            _vm._l(_vm.errors, function (error, index) {
              return _c("span", { key: index, staticClass: "d-block" }, [
                _vm._v(_vm._s(error)),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent")
        : [
            _c(
              "div",
              { staticClass: "row m-0" },
              [
                _c("div", { staticClass: "col-12" }, [
                  _c("h2", { staticClass: "h5 d-inline-block" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-sm btn-outline-secondary radius-btn-square",
                        staticStyle: { width: "29px", "padding-left": "5px" },
                        on: {
                          click: function ($event) {
                            _vm.date = _vm
                              .moment(_vm.date)
                              .subtract(1, "day")
                              .toDate()
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass:
                              "feather feather-chevron-left sc-dnqmqq jxshSx",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "24",
                              height: "24",
                              viewBox: "0 0 24 24",
                              fill: "none",
                              stroke: "currentColor",
                              "stroke-width": "2",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "aria-hidden": "true",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: { points: "15 18 9 12 15 6" },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "date-resa ml-1 mr-1 pt-1",
                        staticStyle: { "text-transform": "uppercase" },
                        on: { click: _vm.openPicker },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.dateString) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-sm btn-outline-secondary radius-btn-square",
                        staticStyle: { width: "29px", "padding-left": "6px" },
                        on: {
                          click: function ($event) {
                            _vm.date = _vm
                              .moment(_vm.date)
                              .add(1, "day")
                              .toDate()
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass:
                              "feather feather-chevron-right sc-dnqmqq jxshSx",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "24",
                              height: "24",
                              viewBox: "0 0 24 24",
                              fill: "none",
                              stroke: "currentColor",
                              "stroke-width": "2",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "aria-hidden": "true",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: { points: "9 18 15 12 9 6" },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-sm btn-outline-secondary none-mobile btn-circle",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.date = new Date()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Aujourd'hui\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-sm btn-outline-secondary none-desk btn-circle",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.date = new Date()
                          },
                        },
                      },
                      [_vm._v("Auj")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("datepicker", {
                  ref: "theDatePicker",
                  staticClass: "btn btn-sm date-resa date-resa-cal",
                  attrs: {
                    format: "dd/MM/yyyy",
                    "monday-first": true,
                    language: _vm.fr,
                  },
                  model: {
                    value: _vm.date,
                    callback: function ($$v) {
                      _vm.date = $$v
                    },
                    expression: "date",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("CommandList", {
                      attrs: {
                        type: _vm.COLLECT_TYPE_DELIVERY.value,
                        commands: { data: _vm.commands_ },
                        columns: {
                          number: true,
                          client: true,
                          restaurant: true,
                          show_payment_status: false,
                          status: {
                            show: true,
                            readonly: false,
                            editable_status: [
                              _vm.COMMAND_READY.value,
                              _vm.COMMAND_COLLECTED.value,
                            ],
                          },
                          delivery_address: true,
                          slot: true,
                          price: true,
                          products: true,
                          actions: {
                            edit: false,
                            send: false,
                            clickable: true,
                            hide_client_btn: true,
                            mark_delivered: true,
                          },
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }