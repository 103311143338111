<template>
    <div class="d-flex">
        <table v-if="sortables" :class="tableClass">
            <thead>
                <th v-for="(column, index) in columns" :key="index" :class="{ pointer: column.sortable }" @click="onSortClick(index)">
                    {{ column.name }}<feather class="ml-1 pointer" v-if="column.sortable" :type="getSortableIcon(index)" />
                </th>
                <th v-if="actionColumn">{{ actionColumn.name }}</th>
            </thead>
            <tbody>
                <tr v-for="(row, index) in data_" :key="index">
                    <td v-for="(column, cindex) in columns" :key="`${index}-${cindex}`">
                        <a
                            v-if="column.link"
                            :style="column.style || ''"
                            :class="getCustomClass(row, column)"
                            :href="getLinkUrl(row, column.link)"
                            :target="column.link.target"
                            v-html="getFormattedData(row, column)"></a>
                        <span v-else :style="column.style || ''" :class="getCustomClass(row, column)" v-html="getFormattedData(row, column)"></span>
                    </td>
                    <td v-if="actionColumn">
                        <button
                            v-for="(btn, index) in actionColumn.buttons"
                            :key="index"
                            :disabled="typeof btn.disabled == 'function' ? btn.disabled(row) : false"
                            :class="btn.class"
                            @click="openBtnLink(btn, row)">
                            <feather :type="btn.feather" />
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <feather
            v-if="allowSortMultipleColumns"
            type="x"
            class="text-danger pointer mt-2"
            @click="resetSortables"
            v-tooltip="getTooltip('Réinitialiser le tri')" />
    </div>
</template>

<script>
import moment from "moment";

/*
Example column:
{
    name: string, // Header column name
    key: undefined|string, // data key (deep object available)
    sortable: true|false, // If column is sortable
    sortable_type: undefined|'int'|'string'|'datetime'|'custom', // data type for sort function, required if sortable = true
    sortable_custom_fct: undefined|function, // Custom sort function, required if sortable_type = 'custom'
    sort_on: undefined|'label', // To sort on formatted data ('label') instead of data
    data_format: 'price'|'price_in_cent'|'int'|'string'|'moment'|'moment_unix'|'custom', // Format data for display
    data_format_moment: undefined|'string', // Moment format (for data_format in 'moment'|'moment_unix'), default to 'DD/MM/Y'
    data_format_fct: undefined|function, // Custom data format, required if data_format = 'custom'
    get_custom_class: undefined|function, // To add a class on cell <td>
    style: undefined|string, // To add style on cell <td>
    default_sort: undefined|Object { // To add default sort on table
        counter: undefined|int, // Define priority (if multiple column sorting is allowed)
        order: undefined|'asc'|'desc', // Sort order, default to 'asc'
    },
    link: undefined|Object { // To add a link on cell
        url: string|function, // Get link url
        target: undefined|string, // <a> target
    },
}
*/

export default {
    data() {
        return {
            sortables: undefined,
            data_: undefined,
            sortCounter: 1,
        };
    },
    props: {
        tableClass: {
            default: "",
        },
        columns: {
            required: true,
            type: Array,
        },
        data: {
            required: true,
            type: Array,
        },
        allowSortMultipleColumns: {
            default: false,
            type: Boolean,
        },
        actionColumn: {
            default: null,
        },
    },
    methods: {
        openBtnLink(btn, data) {
            window.open(btn.link.url(data), btn.link.target);
        },
        resetSortables(keepIndex = undefined) {
            this.sortables.forEach(function (sortable, index) {
                if (typeof keepIndex != "undefined" && index === keepIndex) return;
                sortable.order = undefined;
                sortable.counter = undefined;
            });
            this.data_ = _.cloneDeep(this.data);
        },
        compareInt(a, b) {
            if (!a && !b) return 0;
            if (!a && b) return -1;
            if (a && !b) return 1;
            return a - b;
        },
        compareString(a, b) {
            if (!a && !b) return 0;
            if (!a && b) return -1;
            if (a && !b) return 1;
            return a.toLowerCase().localeCompare(b.toLowerCase());
        },
        compareDatetime(a, b) {
            if (!a && !b) return 0;
            if (!a && b) return -1;
            if (a && !b) return 1;
            const momentA = moment(a);
            const momentB = moment(b);
            if (momentA.isAfter(momentB)) return 1;
            if (momentA.isBefore(momentB)) return -1;
            return 0;
        },
        sort() {
            if (!this.sortables.some((s) => s.counter)) return;
            let sortables = this.sortables.filter((s) => s.counter);
            sortables.sort((a, b) => a.counter - b.counter);
            this.data_.sort((a, b) => {
                let res = 0;
                for (let i = 0; i < sortables.length; i++) {
                    if (sortables[i].column.sortable && sortables[i].order) {
                        if (sortables[i].column.sort_on === "label") {
                            var currentDataA = this.getFormattedData(a, sortables[i].column);
                            var currentDataB = this.getFormattedData(b, sortables[i].column);
                        } else {
                            var currentDataA = this.getData(a, sortables[i].column);
                            var currentDataB = this.getData(b, sortables[i].column);
                        }
                        if (sortables[i].column.sortable_type != "custom")
                            res = this[`compare${this.capitalize(sortables[i].column.sortable_type)}`](currentDataA, currentDataB);
                        else if (sortables[i].column.sortable_custom_fct) res = sortables[i].column.sortable_custom_fct(a, b);
                        if (sortables[i].order === "desc") res *= -1;
                        if (res != 0) break;
                    }
                }
                return res;
            });
        },
        onSortClick(index) {
            if (!this.columns[index].sortable) return;
            if (!this.allowSortMultipleColumns) this.resetSortables(index);
            if (this.sortables[index].order === "asc") this.sortables[index].order = "desc";
            else if (this.sortables[index].order === "desc") this.sortables[index].order = undefined;
            else this.sortables[index].order = "asc";
            if (this.sortables[index].order) this.sortables[index].counter = this.sortCounter++;
            else this.sortables[index].counter = undefined;
            this.sort();
        },
        getFormattedData(data, column) {
            const currentData = this.getData(data, column);
            switch (column.data_format) {
                case "price":
                    return this.formatCurrency(currentData);
                case "price_in_cent":
                    return this.formatCurrency(currentData / 100);
                case "moment":
                case "moment_unix":
                    if (!currentData) return "--";
                    if (column.data_format === "moment_unix") var momentCurrentData = moment.unix(currentData);
                    else var momentCurrentData = moment(currentData);
                    if (column.data_format_moment) return momentCurrentData.format(column.data_format_moment);
                    return momentCurrentData.format("DD/MM/Y");
                case "custom":
                    if (!column.data_format_fct) return currentData;
                    return column.data_format_fct(data);
                case "string":
                    if (currentData) return currentData;
                    return "--";
                default:
                    return currentData;
            }
        },
        getData(data, column) {
            if (column.data_getter) return column.data_getter(data);
            return _.get(data, column.key);
        },
        getCustomClass(data, column) {
            if (column.get_custom_class) return column.get_custom_class(data);
            return "";
        },
        getSortableIcon(index) {
            switch (this.sortables[index].order) {
                case "asc":
                    return "chevron-down";
                case "desc":
                    return "chevron-up";
                default:
                    return "chevron-right";
            }
        },
        getLinkUrl(data, link) {
            if (typeof link.url == "function") return link.url(data);
            if (typeof link.url == "string") return link.url;
            return "#";
        },
        createSortables() {
            let sortables = [];
            let counter = 1;
            const validOrders = ["asc", "desc"];
            this.columns.forEach((column) => {
                if (column.default_sort) {
                    let order = "asc";
                    if (validOrders.includes(column.default_sort.order)) order = column.default_sort.order;
                    sortables.push({
                        order: column.default_sort.order,
                        counter: column.default_sort.counter || counter++,
                        column,
                    });
                } else
                    sortables.push({
                        order: undefined,
                        counter: undefined,
                        column,
                    });
            });
            this.$set(this, "sortables", sortables);
            this.sort();
        },
    },
    created() {
        this.data_ = _.cloneDeep(this.data);
        this.createSortables();
    },
};
</script>
