<template>
    <div class="row" :class="{ 'default-home': userRole != ROLE_ADMIN.value && userRole != ROLE_COURIER.value }" style="height: auto !important">
        <div class="col-12" v-if="userRole != ROLE_ADMIN.value && userRole != ROLE_COURIER.value">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2" data-test-id="title-default_home">{{ $tl("labels.services") }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <HomeAdmin v-if="userRole === ROLE_ADMIN.value" />
            <HomeCourier v-else-if="userRole === ROLE_COURIER.value" />
            <modules-list v-else />
        </div>
    </div>
</template>

<script>
import ModulesList from "../components/Modules/ModulesList.vue";
import HomeAdmin from "./Admin/Home.vue";
import HomeCourier from "./Courier/Home.vue";
import RolesEnum from "../mixins/enums/RolesEnum";

export default {
    name: "Home",
    computed: {
        userRole() {
            return this.$store.getters["users/role"];
        },
    },
    mixins: [RolesEnum],
    components: {
        ModulesList,
        HomeAdmin,
        HomeCourier,
    },
};
</script>
