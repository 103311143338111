var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dropdown", class: { "text-center": _vm.isLoading } },
    [
      _vm.isLoading
        ? _c(
            "div",
            {
              staticClass: "spinner-grow spinner-grow-sm text-info",
              attrs: { role: "status" },
            },
            [_c("span", { staticClass: "sr-only" }, [_vm._v("Chargement...")])]
          )
        : _c("div", [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-sm btn-outline-secondary btn-circle resa-status",
                class: [
                  _vm.getSaleStatusClass(_vm.saleStatusToDisplay),
                  { "dropdown-toggle": _vm.hasActions },
                ],
                attrs: {
                  role: "button",
                  id: "dropdownMenuLink",
                  "data-toggle": "dropdown",
                  "aria-haspopup": "true",
                  "aria-expanded": "false",
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.capitalize(
                        _vm.getSaleStatusLabel(_vm.saleStatusToDisplay)
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.hasActions
              ? _c(
                  "div",
                  {
                    staticClass: "dropdown-menu shadow",
                    attrs: {
                      id: "dropDownStatus",
                      "aria-labelledby": "dropdownMenuLink",
                    },
                  },
                  _vm._l(_vm.actions, function (action) {
                    return _c("div", { key: action }, [
                      _c(
                        "button",
                        {
                          staticClass: "dropdown-item",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "btn",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              )
                                return null
                              return _vm.changeStatus(action)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.capitalize(
                                  _vm.getSaleStatusLabelInfinitif(action)
                                )
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }