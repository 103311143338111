export default {
    data() {
        return {
            PROMO_CODE_TYPE_AMOUNT: {
                label: "Montant fixe",
                value: "amount",
                unit: "€",
            },
            PROMO_CODE_TYPE_PERCENT: {
                label: "Pourcentage",
                value: "percent",
                unit: "%",
            },
        };
    },
    methods: {
        getPromoCodeTypeLabel(value) {
            const found = this.ALL_PROMO_CODE_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
        getPromoCodeTypeUnit(value) {
            const found = this.ALL_PROMO_CODE_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.unit;
            return value;
        },
    },
    computed: {
        ALL_PROMO_CODE_TYPES() {
            return [this.PROMO_CODE_TYPE_AMOUNT, this.PROMO_CODE_TYPE_PERCENT];
        },
    },
};
