<template>
    <select v-model="value_" class="custom-select w-auto m-0">
        <option v-for="currency in availableCurrencies" :key="currency.code" :value="currency.code">
            {{ capitalize(currency.name) }}
            ({{ currency.symbol }})
            <template v-if="value_ === currency.code">
                {{ formatCurrency(12.94, currency.code) }}
            </template>
        </option>
    </select>
</template>

<script>
import currencyList from "../../assets/currency-list.json";

export default {
    props: {
        value: {
            type: String,
        },
        localeIso: {
            type: String,
        },
    },
    computed: {
        locale_() {
            return this.localeIso || this.userLangISO;
        },
        value_: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        availableCurrencies() {
            return Object.entries(currencyList).map(([code, info]) => {
                return {
                    code,
                    ...info,
                };
            });
        },
    },
};
</script>
