<template>
    <div>
        <textarea :id="name" v-bind="{ disabled, name, rows }" class="form-control" v-model="content"></textarea>
        <div>
            <small v-if="minNbChars !== null">{{ $tc("labels.form.textarea.nbMinChars", minNbChars) }}</small>
            <small v-if="maxNbChars !== null">{{ $tc("labels.form.textarea.nbMaxChars", maxNbChars) }}</small>
            <small v-if="minNbChars !== null || maxNbChars !== null" :class="'d-block font-weight-bold ' + getContentCounterClass">
                {{ $tc("labels.form.textarea.nbActualChars", typeof content === "string" ? content.length : 0) }}
            </small>
        </div>
    </div>
</template>

<script>
export default {
    name: "TextareaComponent",
    props: {
        value: {
            type: String,
        },
        name: {
            type: String,
            default: undefined,
        },
        rows: {
            type: Number,
            default: undefined,
        },
        minNbChars: {
            type: Number,
            default: null,
        },
        maxNbChars: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        content: {
            get() {
                return this.value;
            },
            set(newVal) {
                this.$emit("input", newVal);
            },
        },
        getContentCounterClass() {
            if (typeof this.content !== "string") {
                return "";
            }

            return (this.minNbChars !== null && this.minNbChars > this.content.length) ||
                (this.maxNbChars !== null && this.maxNbChars < this.content.length)
                ? "text-danger"
                : "text-success";
        },
    },
};
</script>
