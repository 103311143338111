<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ productComponent.id === null ? "Ajouter" : "Éditer" }} un composant de formule</h5>
        <div slot="body">
            <add-click-and-collect-add-formule-component
                ref="addClickAndCollectAddFormuleComponent"
                @saved="$emit('saved')"
                :productComponent="productComponent" />
        </div>
        <div slot="footer" class="d-flex">
            <button
                type="button"
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                @click="$emit('close')">
                Annuler
            </button>
            <button
                type="button"
                class="btn btn-sm btn-success btn-circle ml-2"
                @click="$refs.addClickAndCollectAddFormuleComponent.save()">
                Valider
            </button>
        </div>
    </modal>
</template>

<script>
import addClickAndCollectAddFormuleComponent from "../../clickAndCollect/addClickAndCollectAddFormuleComponent";

export default {
    props: {
        productComponent: {
            type: Object,
            required: true,
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        addClickAndCollectAddFormuleComponent,
    },
};
</script>
