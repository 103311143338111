<template>
    <div>
        <div class="row m-0">
            <div class="col-12">
                <div
                    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <div>
                        <h5 class="title mt-2">Campagnes marketing</h5>
                    </div>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div class="row m-0" v-else>
            <div class="col-12">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <div>
                        Type&nbsp;
                        <select class="custom-select d-inline-block" style="width: auto" v-model="filter.type">
                            <option :value="null">Toutes</option>
                            <option value="email">Emails</option>
                            <option value="sms">SMS</option>
                        </select>
                    </div>
                    <form @submit="fetchData">
                        Entre le&nbsp;
                        <datepicker
                            class="d-inline-block"
                            format="dd/MM/yyyy"
                            input-class="form-control"
                            :monday-first="true"
                            :language="fr"
                            v-model="filter.from_date"></datepicker>
                        &nbsp;et le&nbsp;
                        <datepicker
                            class="d-inline-block"
                            format="dd/MM/yyyy"
                            input-class="form-control"
                            :monday-first="true"
                            :language="fr"
                            v-model="filter.to_date"></datepicker>
                        <button type="submit" class="btn btn-sm btn-success btn-circle ml-2">Rechercher</button>
                        <button @click="resetFilter" type="button" class="btn btn-sm btn-success btn-circle ml-2">
                            Réinitialiser les filtres
                        </button>
                    </form>
                </div>
            </div>
            <div class="col-12" v-if="invoices">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <div class="d-flex justify-content-between">
                        <div>
                            <strong class="d-block mt-1">Total : {{ invoices.length }}</strong>
                            <strong class="d-block mt-1">CA Total : {{ formatCurrency(totalAmount / 100) }}</strong>
                        </div>
                        <div>
                            <a target="_blank" :href="exportUrl"
                                ><button class="btn btn-sm btn-success btn-circle">Export excel</button></a
                            >
                        </div>
                    </div>
                </div>
                <table class="table table-sm table-striped border-bottom">
                    <thead class="border-bottom">
                        <tr>
                            <th>Compte admin</th>
                            <th>Offre</th>
                            <th>Prix HT</th>
                            <th>Date de paiement</th>
                            <th>Crédits emails marketing restants</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="invoice in invoices" :key="invoice.id">
                            <td style="text-transform: capitalize">
                                <span v-if="invoice.owner"
                                    >{{ invoice.owner.firstname }} {{ invoice.owner.lastname }}</span
                                >
                                <span v-else class="text-danger">Owner inexistant</span>
                            </td>
                            <td>
                                <span class="d-block" v-for="line in invoice.lines.data" :key="line.id">{{
                                    line.description
                                }}</span>
                            </td>
                            <td>{{ formatCurrency(getInvoiceAmount(invoice) / 100) }}</td>
                            <td>{{ moment.unix(invoice.status_transitions.paid_at).format("DD MMM Y") }}</td>
                            <td>{{ invoice.owner ? invoice.owner.nb_campaign_emails_remaining : "--" }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import LoaderComponent from "../LoaderComponent";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";

const defaultFilter = {
    from_date: null,
    to_date: null,
    type: null,
};

export default {
    data() {
        return {
            loading: false,
            filter: _.cloneDeep(defaultFilter),
            fr,
            moment,
            invoices: null,
        };
    },
    computed: {
        exportUrl() {
            let url = "/api/admin/exports/sold_campaigns?";
            if (this.filter.from_date) url += "&from_date=" + moment(this.filter.from_date).format("Y-MM-DD");
            if (this.filter.to_date) url += "&to_date=" + moment(this.filter.to_date).format("Y-MM-DD");
            if (this.filter.type) url += "&type=" + this.filter.type;
            return url;
        },
        totalAmount() {
            let amount = 0;
            if (this.invoices) this.invoices.forEach((invoice) => (amount += this.getInvoiceAmount(invoice)));
            return amount;
        },
    },
    methods: {
        getInvoiceAmount(invoice) {
            let amount = 0;
            invoice.lines.data.forEach((l) => (amount += l.amount));
            return amount;
        },
        resetFilter(e) {
            if (e) e.preventDefault();
            this.filter = _.cloneDeep(defaultFilter);
        },
        fetchData(e) {
            if (e) e.preventDefault();

            this.invoices = null;
            this.loading = true;

            let params = {};
            if (this.filter.from_date) params.from_date = moment(this.filter.from_date).format("Y-MM-DD");
            if (this.filter.to_date) params.to_date = moment(this.filter.to_date).format("Y-MM-DD");
            if (this.filter.type) params.type = this.filter.type;
            this.$store
                .dispatch("admin/fetchInvoices", { params, type: "campaigns" })
                .then((response) => {
                    this.loading = false;
                    this.invoices = Object.values(response.data.data);
                })
                .catch((error) => {
                    this.loading = false;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
    },
    components: {
        LoaderComponent,
        Datepicker,
    },
    created() {},
};
</script>
