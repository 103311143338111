var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [_vm._v("Nombre de clients")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8 value" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.smsCampaign.nb_clients) +
                " (dont " +
                _vm._s(_vm.smsCampaign.nb_foreign_clients) +
                " client(s) étrangé(s))\n                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sepa" }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [_vm._v("Prix HT")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8 value" }, [
            _vm._v(
              _vm._s(
                _vm.formatCurrency(
                  (_vm.smsCampaign.price_ht * _vm.smsCampaign.cost) / 100
                )
              )
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sepa" }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [_vm._v("Prix TTC")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8 value" }, [
            _vm._v(
              "\n                    " +
                _vm._s(
                  _vm.formatCurrency(
                    (_vm.smsCampaign.price_ht * _vm.smsCampaign.cost) / 100 +
                      (((_vm.smsCampaign.price_ht * _vm.smsCampaign.cost) /
                        100) *
                        20) /
                        100
                  )
                ) +
                "\n                "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [_vm._v("Détail de la campagne")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }