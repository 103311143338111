<template>
    <div class="col-4">
        <div class="border-light b-radius-20 p-4">
            <div class="row">
                <div class="col-12">
                    <div v-if="currentStep > 0">
                        <div class="mb-2">
                            <strong>{{ $tl("labels.booking.services.recap", restaurant_id) }}</strong>
                        </div>
                        <div class="row mb-2" v-if="service.general.category.value">
                            <div class="col-12">
                                <span style="text-transform: capitalize"
                                    >{{ $tl("labels.booking.services.category") }}{{ $tl("labels.colon") }}
                                    <span class="value">{{ service.general.category.value }}</span></span
                                >
                            </div>
                        </div>
                        <div class="row mb-2" v-if="service.general.category.value === 'autre'">
                            <div class="col-5 pt-2">{{ $tl("labels.booking.services.categoryName") }} ({{ $tl("labels.langs.fr") }})</div>
                            <div class="col-7 pt-2">
                                <span class="value">{{ service.general.category.nameFr }}</span>
                            </div>
                            <div class="col-5 pt-2">{{ $tl("labels.booking.services.categoryName") }} ({{ $tl("labels.langs.en") }})</div>
                            <div class="col-7 pt-2">
                                <span class="value">{{ service.general.category.nameEn }}</span>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="service.general.name">
                            <div class="col-12">
                                <span
                                    >{{ $tl("labels.form.name") }}{{ $tl("labels.colon") }}
                                    <span class="value">{{ service.general.name }}</span></span
                                >
                            </div>
                        </div>
                        <template v-if="isSpecial">
                            <div class="row mb-2" v-if="service.general.special.openDate">
                                <div class="col-12">
                                    <span
                                        >{{ $tl("labels.startDate") }}{{ $tl("labels.colon") }}
                                        <span class="value">{{ openDateFormatted }}</span></span
                                    >
                                </div>
                            </div>
                            <div class="row mb-2" v-if="service.general.special.closeDate">
                                <div class="col-12">
                                    <span
                                        >{{ $tl("labels.endDate") }}{{ $tl("labels.colon") }}
                                        <span class="value">{{ closeDateFormatted }}</span></span
                                    >
                                </div>
                            </div>
                        </template>
                        <div class="row mb-2" v-if="activeDays">
                            <div class="col-12">
                                <span style="text-transform: capitalize"
                                    >{{ $tl("labels.booking.services.activeDays") }}{{ $tl("labels.colon") }}<br />
                                    {{ activeDays }}
                                </span>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="service.general.openHours.start">
                            <div class="col-12">
                                <span
                                    >{{ $tl("labels.startHour") }}{{ $tl("labels.colon") }}
                                    <span class="value">{{ service.general.openHours.start }}</span></span
                                >
                            </div>
                        </div>
                        <div class="row mb-2" v-if="service.general.openHours.end">
                            <div class="col-12">
                                <span
                                    >{{ $tl("labels.endHour") }}{{ $tl("labels.colon") }}
                                    <span class="value">{{ service.general.openHours.end }}</span></span
                                >
                            </div>
                        </div>
                        <div class="row mb-2" v-if="service.general.pax.max">
                            <div class="col-12">
                                <span
                                    >{{ $tl("labels.booking.services.maxPaxShort") }}{{ $tl("labels.colon") }}
                                    <span class="value">{{ service.general.pax.max }} {{ $tl("labels.booking.covers", restaurant_id) }}</span></span
                                >
                            </div>
                        </div>
                        <div class="row mb-2" v-if="service.general.lock.enabled">
                            <div class="col-12">
                                <span
                                    >{{ $tl("labels.booking.services.lateResa") }}{{ $tl("labels.colon") }}
                                    <span class="value"
                                        >{{ service.general.lock.until }} {{ $tl("labels.booking.services.minsBeforeStart", restaurant_id) }}</span
                                    ></span
                                >
                            </div>
                        </div>
                        <div class="row mb-2" v-if="service.general.cancellation.delay">
                            <div class="col-12">
                                <span
                                    >{{ $tl("labels.booking.services.cancelationDelay") }}{{ $tl("labels.colon") }}
                                    <span class="value"
                                        >{{ service.general.cancellation.delay }} {{ $tl("labels.booking.services.hoursBeforeResa") }}</span
                                    ></span
                                >
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12">
                                <span
                                    >{{ $tl("labels.booking.services.resasConfirm") }}{{ $tl("labels.colon") }}
                                    <span class="value">{{
                                        service.general.autoconfirm.enabled === false ? $tl("labels.manual") : $tl("labels.auto")
                                    }}</span></span
                                >
                            </div>
                        </div>
                        <div class="row mb-2" v-if="service.seatingPlan.algorithm_enabled && service.seatingPlan.value">
                            <div class="col-12">
                                {{ $tl("labels.booking.seatingPlan.title") }}{{ $tl("labels.colon") }}
                                <span class="value">{{ service.seatingPlan.value.name }}</span>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="service.paxes.rotation === true">
                            <div class="col-12">
                                <span
                                    >{{ $tl("labels.booking.services.enableTableRotation", restaurant_id) }}{{ $tl("labels.colon") }}
                                    <span class="value">{{ $tl("labels.yes") }}</span></span
                                >
                            </div>
                        </div>
                        <div class="sepa"></div>
                        <strong>{{ $tl("labels.booking.services.menusAndOptions.title", restaurant_id) }}</strong>
                        <div class="mt-2">
                            <template v-if="!$_.isEmpty(service.menus.value)">
                                <div class="row mb-2">
                                    <div class="col-12">
                                        <span
                                            >{{ $tl("labels.booking.services.menusAndOptions.menus", restaurant_id) }}{{ $tl("labels.colon") }}<br />
                                            <span class="value" v-for="(name, index) in selectedMenusNames">
                                                {{ name }} {{ index < selectedMenusNames.length - 1 ? "-" : "" }}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-2" v-if="service.menus.mustBeUnique === true">
                                    <div class="col-12">
                                        <span
                                            >{{ $tl("labels.booking.menus.uniqueFrom", restaurant_id) }}{{ $tl("labels.colon") }}
                                            <span class="value">{{ service.menus.isUniqueAbovePax }} {{ $tl("labels.personss") }}</span></span
                                        >
                                    </div>
                                </div>
                            </template>
                            <div v-if="!$_.isEmpty(service.generalOptions.value)" class="row mb-2">
                                <div class="col-12">
                                    <span
                                        >{{ $tl("labels.booking.generalOptions.title") }}{{ $tl("labels.colon") }}<br />
                                        <span class="value" v-for="(name, index) in selectedOptionsNames">
                                            {{ name }} {{ index < selectedOptionsNames.length - 1 ? "-" : "" }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="sepa"></div>
                    </div>
                    <!-- End Div à afficher en étape 2 -->

                    <!-- Div à ajouter en étape 3  -->
                    <div v-if="currentStep > 1 && restaurant.has_psp">
                        <strong>{{ $tl("labels.booking.services.footprintAndPayment.title") }}</strong>
                        <div class="mt-2">
                            <div class="row mb-2" v-if="service.payment.bankImprint.enabled === true">
                                <div class="col-12">
                                    <span
                                        >{{ $tl("labels.booking.reservations.footprint.title") }}{{ $tl("labels.colon") }}
                                        <span class="value">{{ $tl("labels.yes") }}</span></span
                                    >
                                </div>
                            </div>
                            <div class="row mb-2" v-if="service.payment.bankImprint.enabledAbovePax && service.payment.bankImprint.enabled === true">
                                <div class="col-12">
                                    <span
                                        >{{ capitalize($tl("labels.from")) }}{{ $tl("labels.colon") }}
                                        <span class="value">{{ service.payment.bankImprint.enabledAbovePax }} {{ $tl("labels.persons") }}</span></span
                                    >
                                </div>
                            </div>
                            <div class="row mb-2" v-if="service.payment.bankImprint.amount && service.payment.bankImprint.enabled === true">
                                <div class="col-12">
                                    <span
                                        >{{ $tl("labels.booking.reservations.footprint.amount") }}{{ $tl("labels.colon") }}
                                        <span class="value">{{ service.payment.bankImprint.amount }} {{ $tl("labels.euros") }}</span></span
                                    >
                                </div>
                            </div>
                            <div class="row mb-2" v-if="service.payment.prepayment.enabled">
                                <div class="col-12">
                                    <span
                                        >{{ $tl("labels.booking.reservations.payment.titleBis") }}{{ $tl("labels.colon") }}
                                        <span class="value">{{
                                            service.payment.prepayment.mandatory === true ? $tl("labels.mandatory") : $tl("labels.optional")
                                        }}</span></span
                                    >
                                </div>
                            </div>
                            <div class="row mb-2" v-if="service.payment.prepayment.mandatory === true">
                                <div class="col-12">
                                    <span
                                        >{{ capitalize($tl("labels.from")) }}{{ $tl("labels.colon") }}
                                        <span class="value">{{ service.payment.prepayment.enabledAbovePax }} {{ $tl("labels.persons") }}</span></span
                                    >
                                </div>
                            </div>
                            <div class="row mb-2" v-if="service.payment.prepayment.mandatory === true">
                                <div class="col-12">
                                    <span
                                        >{{ $tl("labels.booking.reservations.payment.amountToCapture") }}{{ $tl("labels.colon") }}
                                        <span class="value">{{ service.payment.prepayment.percentage }} %</span></span
                                    >
                                </div>
                            </div>
                            <div class="row mb-2" v-if="service.payment.prepayment.enabled || service.payment.bankImprint.enabled">
                                <div class="col-12">
                                    <span
                                        >{{ $tl("labels.booking.services.resasCancelation") }}{{ $tl("labels.colon") }}
                                        <span class="value">{{
                                            service.payment.autoCancel === false ? $tl("labels.manual") : $tl("labels.auto")
                                        }}</span></span
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="sepa"></div>
                    </div>
                    <!-- End Div à ajouter en étape 3 -->

                    <!-- Div à ajouter en étape 4 -->
                    <div v-if="currentStep > 2 && hasEnabledGoogleReserve && isReserveWithGoogleEnabled()">
                        <strong>{{ $tl("labels.googleReserve.title") }}</strong>
                        <div class="row mt-2 mb-2">
                            <div class="col-12">
                                <span>
                                    {{ $tl("labels.googleReserve.service.enableShort") }}{{ $tl("labels.colon") }}
                                    <span class="value">{{ $t(`labels.${service.googleReserveSettings.enabled ? "yes" : "no"}`) }}</span>
                                </span>
                            </div>
                        </div>
                        <div class="sepa"></div>
                    </div>
                    <!-- End Div à ajouter en étape 4 -->

                    <!-- Div à ajouter en étape 5 -->
                    <div v-if="currentStep > 3">
                        <strong>{{ $tl("labels.booking.services.inCaseFull", restaurant_id) }}</strong>
                        <div class="row mt-2 mb-2">
                            <div class="col-12">
                                <span
                                    >{{ $tl("labels.booking.services.showRestaurantService", restaurant_id) }}
                                    <span class="value" v-for="(name, index) in selectedRestaurantProposalsNames" :key="name">
                                        {{ name }} {{ index < selectedRestaurantProposalsNames.length - 1 ? "-" : "" }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- End Div à ajouter en étape 5 -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Overview",
    components: {},
    props: {
        service: {
            required: true,
        },
        isSpecial: {
            default: false,
        },
        restaurant: {
            required: true,
        },
        restaurantsToSuggest: {
            required: true,
        },
        menus: {
            required: true,
        },
        generalOptions: {
            required: true,
        },
        currentStep: {
            required: true,
        },
        hasEnabledGoogleReserve: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        activeDays() {
            return this.englishDays()
                .filter((day) => this.service.general.openDays.includes(day))
                .map((day) => this.capitalize(this.$t(`labels.days.${day}`)))
                .join(" - ");
        },
        selectedMenusNames() {
            var result = [];

            for (const index in this.service.menus.value) {
                const choosenMenu = this.service.menus.value[index];
                this.menus.forEach((refMenu) => {
                    if (choosenMenu.id === refMenu.id) {
                        result.push(refMenu.name);
                    }
                });
            }

            return result;
        },
        selectedOptionsNames() {
            var result = [];

            for (const index in this.service.generalOptions.value) {
                const choosenOption = this.service.generalOptions.value[index];
                this.generalOptions.forEach((refOption) => {
                    if (choosenOption === refOption.id) {
                        result.push(refOption.name);
                    }
                });
            }

            return result;
        },
        selectedRestaurantProposalsNames() {
            var result = [];

            this.service.full.restaurantsProposals.forEach((choosenRestaurant) => {
                this.restaurantsToSuggest.forEach((refRestaurant) => {
                    if (choosenRestaurant === refRestaurant.id) {
                        result.push(refRestaurant.name);
                    }
                });
            });

            return result;
        },
        openDateFormatted() {
            return this.displayDate(this.service.general.special.openDate, this.DATE_HUGE_NO_YEAR);
        },
        closeDateFormatted() {
            return this.displayDate(this.service.general.special.closeDate, this.DATE_HUGE_NO_YEAR);
        },

        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
};
</script>
