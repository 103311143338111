<template>
    <select v-model="tz">
        <option v-for="tmpTz in sortedRawTimeZones" :key="tmpTz.name" :value="tmpTz.name">
            {{ tmpTz.name.replace(/_/g, " ") }} ({{ getOffsetInHours(tmpTz.rawOffsetInMinutes) }})
        </option>
    </select>
</template>

<script>
import { rawTimeZones } from "@vvo/tzdb";

export default {
    data() {
        return {};
    },
    props: {
        value: {
            default: "Europe/Paris",
        },
    },
    methods: {
        getOffsetInHours(offsetInMinutes) {
            if (offsetInMinutes === 0) {
                return "UTC";
            }
            return `${offsetInMinutes > 0 ? "+" : "-"}${Math.abs(Math.floor(offsetInMinutes / 60))
                .toString()
                .padStart(2, "0")}:${Math.abs(offsetInMinutes % 60)
                .toString()
                .padStart(2, "0")}`;
        },
    },
    computed: {
        sortedRawTimeZones() {
            return rawTimeZones.sort((a, b) => {
                if (a.rawOffsetInMinutes === b.rawOffsetInMinutes) {
                    return a.name.localeCompare(b.name);
                }
                return a.rawOffsetInMinutes - b.rawOffsetInMinutes;
            });
        },
        tz: {
            get() {
                return this.value;
            },
            set(newVal) {
                this.$emit("input", newVal);
            },
        },
    },
};
</script>
