<template>
    <div>
        <LoaderComponent v-if="isLoading" />

        <vue-multiselect
            v-else
            v-model="tables"
            :options="options"
            :multiple="true"
            label="name"
            track-by="name"
            group-values="entities"
            group-label="roomName"
            :group-select="false"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :show-labels="false"
            :placeholder="$tl('labels.booking.seatingPlan.tables')"
            :custom-label="customTablesLabel"
            open-direction="bottom"
            @select="tableSelected($event)"
            @remove="tableUnselected($event)"
            style="font-size: 12.8px !important">
            <template slot="noOptions">
                <span v-if="!reservation.slot.id">
                    {{ $tl("labels.booking.reservations.selectSlot") }}
                </span>
                <span v-else>
                    {{ $tl("labels.noResult") }}
                </span>
            </template>
            <template slot="option" slot-scope="props">
                <template v-if="!props.option.$isLabel">
                    <div class="float-left">
                        {{ props.option.type === "table" ? "Table" : "Groupe" }}
                        {{ props.option.name }}
                    </div>
                    <div class="float-right" v-if="props.option.enabledPax.length > 0">
                        {{ capitalize($tl("labels.pax")) }} :
                        <template v-for="(item, index) in props.option.enabledPax">
                            {{ index > 0 ? " " : "" }}{{ props.option.enabledPax.length - 1 === index ? `${item}` : `${item},` }}
                        </template>
                    </div>
                    <div class="float-right" v-else>{{ capitalize($tl("labels.pax")) }} : {{ props.option.seats }}</div>
                </template>
                <template v-else>
                    <div>{{ props.option.$groupLabel }}</div>
                </template>
            </template>
        </vue-multiselect>
    </div>
</template>
<script>
import VueMultiselect from "vue-multiselect";
import LoaderComponent from "../../../LoaderComponent.vue";

export default {
    props: {
        serviceId: {
            required: true,
            type: Number,
        },
        restaurantId: {
            required: true,
            type: Number,
        },
        reservationDate: {
            required: true,
            type: Object,
        },
        reservation: {
            required: true,
            type: Object,
        },
        selectedTable: {
            required: true,
            type: Object,
        },
        currentRemainingPaxes: {
            required: true,
            type: Number,
        },
        currentRoomIndex: {
            required: true,
            type: Number,
        },
    },
    components: {
        VueMultiselect,
        LoaderComponent,
    },
    data() {
        return {
            isLoading: false,
            tables: [],
            seatingPlan: null,
            selectedTables: [],
            toReleaseEntities: [],
            startHours: this.getStartHours(),
        };
    },
    computed: {
        reservationStartsAt() {
            return this.reservationDate.set({
                hour: this.startHours[0],
                minute: this.startHours[1],
            });
        },
        reservationEndsAt() {
            const durations = this.reservation.duration.split(":");

            return this.reservationStartsAt.plus({
                hours: durations[0],
                minutes: durations[1],
            });
        },
        paxUsed() {
            return this.tables.reduce((accumulator, entity) => accumulator + this.getMaxPax(entity), 0);
        },
        isPaxFull() {
            return this.currentRemainingPaxes <= 0;
        },
        options() {
            //Theses options are for only one room
            const result = [];

            if (this.seatingPlan && this.reservation.slot.id) {
                const room = this.seatingPlan.rooms[this.currentRoomIndex] || {};

                if (room.length === 0) {
                    return [];
                }

                if (room.isEnabledForService || room.enabled === 1) {
                    const roomResult = {
                        id: room.id,
                        roomName: room.name,
                        entities: [],
                    };

                    room.entities.forEach((entity) => {
                        const slot = entity.slots.find((currentSlot) => currentSlot.id == this.reservation.slot.id);

                        if (entity.id !== +this.selectedTable.id && entity.enabled && slot !== undefined) {
                            let entityFormated = null;

                            if (entity.type === "table") {
                                entityFormated = this.formatTable(entity, false, slot);
                            } else if (entity.type === "group") {
                                entityFormated = this.formatGroup(entity, false, slot);
                            }

                            if (entityFormated !== null) {
                                roomResult.entities.push({
                                    ...entityFormated,
                                    roomId: room.id,
                                });
                            }
                        }
                    });
                    if (roomResult.entities.length > 0) {
                        result.push(roomResult);
                    }
                }
            }
            return result;
        },
    },
    created() {
        window.addEventListener("beforeunload", this.releaseEntities);
        this.getSeatingPlan();
    },
    beforeDestroy() {
        this.releaseEntities();
        window.removeEventListener("beforeunload", this.releaseEntities);
    },
    watch: {
        paxUsed(newValue) {
            this.$emit("pax-used", newValue);
        },
        "tables.length": {
            handler() {
                this.$emit(
                    "updated-tables",
                    this.tables.map((table) => {
                        return {
                            selectedPax: this.getMaxPax(table),
                            table,
                        };
                    })
                );
            },
        },
    },
    methods: {
        // release all temporary tables which not explicitly released
        releaseEntities() {
            //TODO: this function will be reworked to reflect the change coming in https://yproximite.atlassian.net/browse/NOSHOW-1204
            this.toReleaseEntities.forEach((entity) => {
                if (entity.type === "group") {
                    this.releaseGroup(entity, false);
                } else {
                    this.releaseTable(entity, false);
                }
            });
        },
        getStartHours() {
            return this.reservation.slot.hour_start.split(":");
        },
        getMaxPax(entity) {
            return entity.enabledPax.length > 0 ? Math.max(...entity.enabledPax) : Number.parseInt(entity.seats);
        },
        getSeatingPlan() {
            this.isLoading = true;
            this.httpGet(
                `/api/restaurants/${this.restaurantId}/services/${
                    this.serviceId
                }?date=${this.reservationDate.toISODate()}&include=slots,seatingPlanFilled,paxDurations,has_seating_plan`
            )
                .then((response) => {
                    if (response !== false) {
                        this.seatingPlan = response.data.seatingPlanFilled;
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        customTablesLabel({ name, type }) {
            return `${this.$tl(`labels.booking.seatingPlan.${type}.title`)} ${name}`;
        },
        tableSelected(entity) {
            if (entity.type === "table") {
                this.reserveTable(entity);
            } else if (entity.type === "group") {
                this.reserveGroup(entity);
            }
        },
        tableUnselected(entity) {
            if (entity.type === "table") {
                this.releaseTable(entity);
            } else if (entity.type === "group") {
                this.releaseGroup(entity);
            }
        },
        reserveTable(entity) {
            axios
                .post(
                    `/api/restaurants/${this.restaurantId}/services/slots/${this.reservation.slot.id}/tables/reserved/new_resa/${
                        entity.id
                    }?date=${this.reservationDate.toISODate()}&duration=${this.reservation.duration}&pax=${this.getMaxPax(entity)}`
                )
                .then(() => {
                    this.toReleaseEntities.push(entity);
                })
                .catch(() => {
                    this.notifyError(null, this.$tl("errors.booking.seatingPlan.table.notAvailable"));
                    const index = this.tables.findIndex((e) => e.type === "table" && e.id === entity.id);
                    if (index > -1) {
                        this.tables.splice(index, 1);
                    }
                });
        },
        reserveGroup(entity) {
            axios
                .post(
                    `/api/restaurants/${this.restaurantId}/services/slots/${this.reservation.slot.id}/groups/reserved/new_resa/${
                        entity.id
                    }?date=${this.reservationDate.toISODate()}&duration=${this.reservation.duration}&pax=${this.getMaxPax(entity)}`
                )
                .then(() => {
                    this.toReleaseEntities.push(entity);
                })
                .catch(() => {
                    this.notifyError(null, this.$tl("errors.booking.seatingPlan.group.notAvailable"));

                    const index = this.tables.findIndex((e) => e.type === "group" && e.id === entity.id);

                    if (index > -1) {
                        this.tables.splice(index, 1);
                    }
                });
        },
        releaseTable(entity) {
            axios
                .delete(
                    `/api/restaurants/${this.restaurantId}/services/slots/${this.reservation.slot.id}/tables/reserved/new_resa/${
                        entity.id
                    }?date=${this.reservationDate.toISODate()}`
                )
                .then(() => {
                    this.toReleaseEntities.filter((toReleaseEntity) => !(toReleaseEntity.type === "table" && toReleaseEntity.id === entity.id));
                })
                .catch((error) => {
                    this.notifyError(error);
                    this.tables.push(entity);
                });
        },
        releaseGroup(entity) {
            axios
                .delete(
                    `/api/restaurants/${this.restaurantId}/services/slots/${this.reservation.slot.id}/groups/reserved/new_resa/${
                        entity.id
                    }?date=${this.reservationDate.toISODate()}`
                )
                .then(() => {
                    this.toReleaseEntities.filter((toReleaseEntity) => !(toReleaseEntity.type === "group" && toReleaseEntity.id === entity.id));
                })
                .catch((error) => {
                    this.notifyError(error);
                    this.tables.push(entity);
                });
        },
        formatTable(table, isDisabled, slot) {
            if (table.enabled) {
                if (isDisabled === false) {
                    for (const entity of this.tables) {
                        if (entity.type === "group") {
                            if (entity.tables.filter((t) => t.id === table.id).length > 0) {
                                isDisabled = true;
                                break;
                            }
                        }
                    }
                }

                if (isDisabled === false && this.isPaxFull) {
                    const ids = this.tables.filter((e) => e.type === "table").map((e) => e.id);

                    isDisabled = !ids.includes(table.id);
                }

                const foundEntity = this.tables.find((e) => {
                    switch (e.type) {
                        case "table":
                            return e.id === table.id;
                        case "group":
                            return e.tables.find((t) => t.id === table.id);
                    }
                });

                if (
                    !foundEntity &&
                    (!slot.entityEnabled || !this.isTableAvailableOnFutureSlots(table)) &&
                    (!this.reservation || !this.reservation.tables.data.find((t) => t.id == table.id))
                ) {
                    return null;
                }

                return {
                    ...table,
                    type: "table",
                    $isDisabled: isDisabled,
                };
            }
            return null;
        },
        isTableAvailableOnFutureSlots(table) {
            if (this.reservationStartsAt && this.reservationEndsAt) {
                const slots = table.slots.filter((slot) => {
                    if (this.reservation.slot_id !== slot.id) {
                        const slotHourStart = this.getSlotHourStart();
                        return this.reservationStartsAt <= slotHourStart && slotHourStart < this.reservationEndsAt;
                    }

                    return false;
                });

                return !slots.some((slot) => {
                    return slot.entityEnabled === false;
                });
            }

            return false;
        },
        getSlotHourStart() {
            return this.reservationDate.set({
                hour: this.startHours[0],
                minute: this.startHours[1],
            });
        },
        formatGroup(group, isDisabled, slot) {
            if (!!group.enabled === true) {
                if (isDisabled === false) {
                    let tablesIds = this.tables.filter((table) => table.type === "table").map((table) => table.id);

                    const groupsSelected = this.tables.filter((e) => e.type === "group");

                    const groupsIds = groupsSelected.map((g) => g.id);

                    if (!groupsIds.includes(group.id)) {
                        groupsSelected.forEach((g) => {
                            tablesIds = tablesIds.concat(g.tables.map((t) => t.id));
                        });

                        tablesIds = this.$_.uniq(tablesIds);

                        for (var t of group.tables) {
                            if (tablesIds.includes(t.id)) {
                                isDisabled = true;
                                break;
                            }
                        }
                    }
                }

                if (isDisabled === false && this.isPaxFull) {
                    const ids = this.tables.filter((e) => e.type === "group").map((e) => e.id);

                    isDisabled = !ids.includes(group.id);
                }

                const tablesIds = group.tables.map((t) => t.id);
                const found = this.tables.find((e) => {
                    switch (e.type) {
                        case "table":
                            return tablesIds.includes(e.id);
                        case "group":
                            return e.id === group.id;
                    }
                });
                const tmpGroup = group.tables.filter((t) => {
                    const tableSlot = t.slots.find((s) => s.id == slot.id);
                    return !tableSlot.entityEnabled || !this.isTableAvailableOnFutureSlots(t);
                });
                if (!found && tmpGroup.length > 0) {
                    return null;
                }

                return {
                    ...group,
                    type: "group",
                    $isDisabled: isDisabled,
                };
            }

            return null;
        },
    },
};
</script>
