var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Echec de la connexion à Stripe"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "border-light b-radius-20 p-4",
        attrs: { slot: "body" },
        slot: "body",
      },
      [
        _vm._v(
          "\n        Pour lier votre compte stripe à NoShow, il est nécessaire d'avoir un compte stripe vérifié et d'activer les\n        paiments par carte ainsi que les transferts vers votre compte bancaire."
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n        Actuellement, les fonctionnalités suivantes sont désactivées sur votre compte stripe :"
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("ul", [
          !(
            _vm.params &&
            _vm.params["charges_enabled"] &&
            _vm.params["charges_enabled"] === true
          )
            ? _c("li", { staticClass: "text-danger" }, [
                _vm._v("\n                - Paiements par carte\n            "),
              ])
            : _vm._e(),
          _vm._v(" "),
          !(
            _vm.params &&
            _vm.params["payouts_enabled"] &&
            _vm.params["payouts_enabled"] === true
          )
            ? _c("li", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n                - Transferts vers votre compte bancaire.\n            "
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(
          "\n\n        Pour valider votre compte et activer ces fonctionnalités, rendez-vous sur votre tableau de bord Stripe.\n        "
        ),
        _c("br"),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-sm btn-circle btn-danger mt-2",
            attrs: { href: "https://dashboard.stripe.com/", target: "_blank" },
          },
          [_vm._v("Dashboard Stripe")]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass: "modal-default-button btn btn-secondary",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v("Ok")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }