var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _vm.error
        ? _c("span", [_vm._v(_vm._s(_vm.error))])
        : _c("div", [
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "border-light b-radius-20 p-4" },
                  [
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("div", { staticClass: "col-md-5 pt-1" }, [
                        _c("label", [
                          _vm._v(" Nom "),
                          _c("small", [_vm._v("*")]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7 mb-2" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.productComponent.name,
                                expression: "productComponent.name",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.productComponent.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.productComponent,
                                  "name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            attrs: { errors: _vm.errors, errorKey: "name" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    !_vm.productComponent.is_extra_forced
                      ? _c("div", { staticClass: "row mb-2" }, [
                          _c("div", { staticClass: "col-md-5 pt-1" }, [
                            _c("label", [
                              _vm._v(" Ordre "),
                              _c("small", [_vm._v("*")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-7 mb-2" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.productComponent.order,
                                    expression: "productComponent.order",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "number", min: "1" },
                                domProps: { value: _vm.productComponent.order },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.productComponent,
                                      "order",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("ShowErrors", {
                                attrs: {
                                  errors: _vm.errors,
                                  errorKey: "order",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.productComponent.is_extra_forced
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-5" }, [
                            _c("label", [
                              _vm._v("Choix du composant "),
                              _c("small", [_vm._v("*")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-7" }, [
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("div", { staticClass: "radio" }, [
                                    _c(
                                      "label",
                                      { staticClass: "container-box" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.productComponent
                                                  .is_mandatory,
                                              expression:
                                                "productComponent.is_mandatory",
                                            },
                                          ],
                                          attrs: { type: "radio" },
                                          domProps: {
                                            value: 1,
                                            checked: _vm._q(
                                              _vm.productComponent.is_mandatory,
                                              1
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.productComponent,
                                                "is_mandatory",
                                                1
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                        _vm._v(
                                          " Obligatoire\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-12 d-inline radio mt-1" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "container-box d-inline-block pt-0 mt-2",
                                        staticStyle: { width: "initial" },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.productComponent
                                                  .is_mandatory,
                                              expression:
                                                "productComponent.is_mandatory",
                                            },
                                          ],
                                          attrs: { type: "radio" },
                                          domProps: {
                                            value: 0,
                                            checked: _vm._q(
                                              _vm.productComponent.is_mandatory,
                                              0
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.productComponent,
                                                "is_mandatory",
                                                0
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                        _vm._v(
                                          " Optionnel\n                                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  staticClass: "col-12",
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "is_mandatory",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-md-5 pt-1 justify-content-between",
                        },
                        [
                          _c("label", [
                            _vm._v(" Produits "),
                            _c("small", [_vm._v("*")]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.productComponent.cc_product_options.data.length === 0
                        ? _c(
                            "div",
                            { staticClass: "col-md-7 mb-2" },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selected_product,
                                      expression: "selected_product",
                                    },
                                  ],
                                  staticClass: "custom-select",
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selected_product = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { disabled: "" },
                                      domProps: { value: null },
                                    },
                                    [_vm._v("Choisir un produit")]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.availableProducts,
                                    function (product) {
                                      return _c(
                                        "option",
                                        {
                                          key: product.id,
                                          domProps: { value: product },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(product.name) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("ShowErrors", {
                                attrs: {
                                  errors: _vm.errors,
                                  errorKey: "cc_product_options.data",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.productComponent.cc_product_options.data.length > 0
                      ? _vm._l(
                          _vm.productComponent.cc_product_options.data,
                          function (productOption, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "row mb-3" },
                              [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "border-light p-4" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-between align-items-center",
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                productOption.cc_product.name
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: { content: "Retirer" },
                                                  expression:
                                                    "{ content: 'Retirer' }",
                                                },
                                              ],
                                              staticClass:
                                                "btn btn-sm btn-warning btn-square",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteProductOption(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("feather", {
                                                attrs: { type: "x" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "row mt-4" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-5" },
                                            [
                                              _c("label", [
                                                _vm._v("Tarif TTC "),
                                                _c("small", [_vm._v("*")]),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-md-7" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  !productOption.cc_product
                                                    .price ||
                                                  !productOption.cc_product
                                                    .tax_rate
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 text-danger mb-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Vous devez d'abord définir un prix et un taux de TVA à ce\n                                                    produit afin de pouvoir l'ajouter.\n                                                "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-12" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "radio",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "container-box",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      productOption.include,
                                                                    expression:
                                                                      "productOption.include",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  type: "radio",
                                                                  disabled:
                                                                    !productOption
                                                                      .cc_product
                                                                      .tax_rate ||
                                                                    !productOption
                                                                      .cc_product
                                                                      .price,
                                                                },
                                                                domProps: {
                                                                  value: 1,
                                                                  checked:
                                                                    _vm._q(
                                                                      productOption.include,
                                                                      1
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        productOption,
                                                                        "include",
                                                                        1
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("span", {
                                                                staticClass:
                                                                  "checkmark",
                                                              }),
                                                              _vm._v(
                                                                " Inclus dans le prix\n                                                            " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .productComponent
                                                                      .is_extra_forced
                                                                      ? "du produit"
                                                                      : "de la formule"
                                                                  ) +
                                                                  "\n                                                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 d-inline radio mt-1",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "container-box d-inline-block pt-0 mt-2",
                                                          staticStyle: {
                                                            width: "initial",
                                                          },
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  productOption.include,
                                                                expression:
                                                                  "productOption.include",
                                                              },
                                                            ],
                                                            attrs: {
                                                              type: "radio",
                                                              disabled:
                                                                !productOption
                                                                  .cc_product
                                                                  .tax_rate ||
                                                                !productOption
                                                                  .cc_product
                                                                  .price,
                                                            },
                                                            domProps: {
                                                              value: 0,
                                                              checked: _vm._q(
                                                                productOption.include,
                                                                0
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  productOption,
                                                                  "include",
                                                                  0
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("span", {
                                                            staticClass:
                                                              "checkmark",
                                                          }),
                                                          _vm._v(
                                                            " Coût additionnel\n                                                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      !productOption.include
                                                        ? [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model.number",
                                                                  value:
                                                                    productOption.price,
                                                                  expression:
                                                                    "productOption.price",
                                                                  modifiers: {
                                                                    number: true,
                                                                  },
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control d-inline mr-2",
                                                              staticStyle: {
                                                                width: "100px",
                                                              },
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  productOption.price,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      productOption,
                                                                      "price",
                                                                      _vm._n(
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    )
                                                                  },
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$forceUpdate()
                                                                },
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                                                        euros\n                                                        "
                                                            ),
                                                            _c("ShowErrors", {
                                                              staticClass:
                                                                "d-block",
                                                              attrs: {
                                                                errors:
                                                                  _vm.errors,
                                                                errorKey: `cc_product_options.data.${index}.price`,
                                                              },
                                                            }),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c("ShowErrors", {
                                                    staticClass: "col-12",
                                                    attrs: {
                                                      errors: _vm.errors,
                                                      errorKey: `cc_product_options.data.${index}.include`,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "col-12",
                                            attrs: {
                                              errors: _vm.errors,
                                              errorKey: `cc_product_options.data.${index}.cc_product_id`,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.productComponent.cc_product_options.data.length > 0
                      ? _c("div", { staticClass: "row mb-2" }, [
                          _c("div", { staticClass: "col-md-5 pt-1" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: {
                                      content: "Ajouter un autre produit",
                                    },
                                    expression:
                                      "{ content: 'Ajouter un autre produit' }",
                                  },
                                ],
                                staticClass: "pointer p-2 bg-light d-inline",
                                on: {
                                  click: function ($event) {
                                    _vm.addProduct = true
                                  },
                                },
                              },
                              [
                                _c("feather", {
                                  staticClass: "text-success",
                                  attrs: { type: "plus" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-7 mb-2" }, [
                            _vm.addProduct
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selected_product,
                                        expression: "selected_product",
                                      },
                                    ],
                                    staticClass: "custom-select",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.selected_product = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: { disabled: "" },
                                        domProps: { value: null },
                                      },
                                      [_vm._v("Choisir un produit")]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.availableProducts,
                                      function (product) {
                                        return _c(
                                          "option",
                                          {
                                            key: product.id,
                                            domProps: { value: product },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(product.name) +
                                                "\n                                "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }