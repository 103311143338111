var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c(
                            "div",
                            { staticClass: "row mb-2" },
                            [
                              _c("div", { staticClass: "col-md-5 pt-1" }, [
                                _c("label", [_vm._v("Type de zone")]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7" }, [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  _vm._l(
                                    _vm.ALL_DELIVERY_AREA_TYPES,
                                    function (areaType) {
                                      return _c(
                                        "div",
                                        {
                                          key: areaType.value,
                                          staticClass: "col-12 mb-1",
                                        },
                                        [
                                          _c("div", { staticClass: "radio" }, [
                                            _c(
                                              "label",
                                              { staticClass: "container-box" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.deliveryArea.type,
                                                      expression:
                                                        "deliveryArea.type",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "radio",
                                                    disabled: true,
                                                  },
                                                  domProps: {
                                                    value: areaType.value,
                                                    checked: _vm._q(
                                                      _vm.deliveryArea.type,
                                                      areaType.value
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.$set(
                                                        _vm.deliveryArea,
                                                        "type",
                                                        areaType.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass:
                                                    "checkmark disabled",
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(areaType.label) +
                                                    "\n                                            "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                              _vm._v(" "),
                              _c("ShowErrors", {
                                staticClass: "col-12",
                                attrs: {
                                  errors: _vm.errors,
                                  errorKey: "cc_configuration_id",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.deliveryArea.type ==
                          _vm.DELIVERY_AREA_TYPE_CITIES.value
                            ? _c("SelectCities", {
                                staticClass: "row mb-1",
                                attrs: {
                                  deliveryArea: _vm.deliveryArea,
                                  errors: _vm.errors,
                                },
                              })
                            : _vm.deliveryArea.type ==
                              _vm.DELIVERY_AREA_TYPE_CROW_FLIES.value
                            ? _c("SelectCrowFlies", {
                                staticClass: "row mb-1",
                                attrs: {
                                  deliveryArea: _vm.deliveryArea,
                                  errors: _vm.errors,
                                  displayableDeliveryDeparture:
                                    _vm.displayableDeliveryDeparture,
                                },
                              })
                            : _vm.deliveryArea.type ==
                              _vm.DELIVERY_AREA_TYPE_DRAWN.value
                            ? [
                                _c("div", { staticClass: "row mt-2" }, [
                                  _c("div", { staticClass: "col-md-5" }, [
                                    _c("label", [
                                      _vm._v("Nom de la zone "),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.deliveryArea.name,
                                            expression: "deliveryArea.name",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.deliveryArea.name,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.deliveryArea,
                                              "name",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        staticClass: "d-block",
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mt-2" }, [
                                  _c("div", { staticClass: "col-md-5" }, [
                                    _c("label", [_vm._v("Couleur de la zone")]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _c("div", {
                                      staticStyle: {
                                        height: "20px",
                                        width: "80px",
                                      },
                                      style: `background-color: ${_vm.deliveryArea.drawn_color}`,
                                    }),
                                  ]),
                                ]),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mt-2" }, [
                            _c("div", { staticClass: "col-md-5" }, [
                              _c("label", [
                                _vm._v("Tarif TTC "),
                                _c("small", [_vm._v("*")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-7" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("div", { staticClass: "radio" }, [
                                    _c(
                                      "label",
                                      { staticClass: "container-box" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.deliveryArea.is_free,
                                              expression:
                                                "deliveryArea.is_free",
                                            },
                                          ],
                                          attrs: { type: "radio" },
                                          domProps: {
                                            value: 1,
                                            checked: _vm._q(
                                              _vm.deliveryArea.is_free,
                                              1
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.deliveryArea,
                                                "is_free",
                                                1
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                        _vm._v(
                                          " Gratuite\n                                            "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-12 d-inline radio mt-1" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "container-box d-inline-block pt-0 mt-2",
                                        staticStyle: { width: "initial" },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.deliveryArea.is_free,
                                              expression:
                                                "deliveryArea.is_free",
                                            },
                                          ],
                                          attrs: { type: "radio" },
                                          domProps: {
                                            value: 0,
                                            checked: _vm._q(
                                              _vm.deliveryArea.is_free,
                                              0
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.deliveryArea,
                                                "is_free",
                                                0
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                        _vm._v(
                                          " Payante\n                                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !_vm.deliveryArea.is_free
                                      ? [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.deliveryArea.price,
                                                expression:
                                                  "deliveryArea.price",
                                              },
                                            ],
                                            staticClass:
                                              "form-control d-inline mr-2",
                                            staticStyle: { width: "100px" },
                                            attrs: { type: "number" },
                                            domProps: {
                                              value: _vm.deliveryArea.price,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.deliveryArea,
                                                  "price",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(
                                            "\n                                            euros\n                                            "
                                          ),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.errors,
                                              errorKey: "price",
                                            },
                                          }),
                                          _vm._v(" "),
                                          !_vm.isTvaDisabled
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 pt-1",
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v("Taux de TVA "),
                                                        _c("small", [
                                                          _vm._v("*"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .deliveryArea
                                                                    .tax_rate,
                                                                expression:
                                                                  "deliveryArea.tax_rate",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "custom-select mb-0 ml-2 mr-3",
                                                            staticStyle: {
                                                              width: "inherit",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  _vm.deliveryArea,
                                                                  "tax_rate",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                domProps: {
                                                                  value: null,
                                                                },
                                                              },
                                                              [_vm._v("--")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "option",
                                                              {
                                                                domProps: {
                                                                  value: 0,
                                                                },
                                                              },
                                                              [_vm._v("0")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "option",
                                                              {
                                                                domProps: {
                                                                  value: 5.5,
                                                                },
                                                              },
                                                              [_vm._v("5,5")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "option",
                                                              {
                                                                domProps: {
                                                                  value: 10,
                                                                },
                                                              },
                                                              [_vm._v("10")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "option",
                                                              {
                                                                domProps: {
                                                                  value: 20,
                                                                },
                                                              },
                                                              [_vm._v("20")]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "%\n                                                    "
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("ShowErrors", {
                                                    staticClass: "col-12",
                                                    attrs: {
                                                      errors: _vm.errors,
                                                      errorKey: "tax_rate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          !_vm.deliveryArea.is_free
                            ? _c("div", { staticClass: "row mt-3" }, [
                                _c("div", { staticClass: "col-md-5" }, [
                                  _c("label", [
                                    _vm._v(
                                      "Livraison gratuite à partir d'un certain montant"
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-7" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-inline-block lead-switch mt-2",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.freeDelivery,
                                              expression: "freeDelivery",
                                            },
                                          ],
                                          staticClass:
                                            "switch align-self-center is-rounded",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.freeDelivery
                                            )
                                              ? _vm._i(_vm.freeDelivery, null) >
                                                -1
                                              : _vm.freeDelivery,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.freeDelivery,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.freeDelivery =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.freeDelivery = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.freeDelivery = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("label", {
                                          on: {
                                            click: function ($event) {
                                              _vm.freeDelivery =
                                                !_vm.freeDelivery
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.freeDelivery
                                      ? [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.deliveryArea.free_above,
                                                expression:
                                                  "deliveryArea.free_above",
                                              },
                                            ],
                                            staticClass:
                                              "form-control d-inline mr-2",
                                            staticStyle: { width: "100px" },
                                            attrs: { type: "number" },
                                            domProps: {
                                              value:
                                                _vm.deliveryArea.free_above,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.deliveryArea,
                                                  "free_above",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(
                                            "\n                                    euros\n                                    "
                                          ),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.errors,
                                              errorKey: "free_above",
                                            },
                                          }),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mt-3" }, [
                            _c("div", { staticClass: "col-md-5" }, [
                              _c("label", [
                                _vm._v(
                                  "Montant minimum de commande pour activer cette zone"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-7" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-inline-block lead-switch mt-2",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.minOrderDelivery,
                                          expression: "minOrderDelivery",
                                        },
                                      ],
                                      staticClass:
                                        "switch align-self-center is-rounded",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.minOrderDelivery
                                        )
                                          ? _vm._i(_vm.minOrderDelivery, null) >
                                            -1
                                          : _vm.minOrderDelivery,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.minOrderDelivery,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.minOrderDelivery =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.minOrderDelivery = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.minOrderDelivery = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", {
                                      on: {
                                        click: function ($event) {
                                          _vm.minOrderDelivery =
                                            !_vm.minOrderDelivery
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.minOrderDelivery
                                  ? [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.deliveryArea.min_amount,
                                            expression:
                                              "deliveryArea.min_amount",
                                          },
                                        ],
                                        staticClass:
                                          "form-control d-inline mr-2",
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "number" },
                                        domProps: {
                                          value: _vm.deliveryArea.min_amount,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.deliveryArea,
                                              "min_amount",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(
                                        "\n                                    euros\n                                    "
                                      ),
                                      _c("ShowErrors", {
                                        staticClass: "d-block",
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "min_amount",
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mt-3" }, [
                            _c("div", { staticClass: "col-md-5" }, [
                              _c("label", [
                                _vm._v(
                                  "Créneaux de disponibilité de la zone de livraison "
                                ),
                                _c("small", [_vm._v("*")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-7" }, [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("div", { staticClass: "radio" }, [
                                      _c(
                                        "label",
                                        { staticClass: "container-box" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.deliveryArea
                                                    .use_default_delivery_slots,
                                                expression:
                                                  "deliveryArea.use_default_delivery_slots",
                                              },
                                            ],
                                            attrs: { type: "radio" },
                                            domProps: {
                                              value: 1,
                                              checked: _vm._q(
                                                _vm.deliveryArea
                                                  .use_default_delivery_slots,
                                                1
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$set(
                                                  _vm.deliveryArea,
                                                  "use_default_delivery_slots",
                                                  1
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                          _vm._v(
                                            " Utiliser les paramètres par défaut\n                                            "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-12 d-inline radio mt-1",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "container-box d-inline-block pt-0 mt-2",
                                          staticStyle: { width: "initial" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.deliveryArea
                                                    .use_default_delivery_slots,
                                                expression:
                                                  "deliveryArea.use_default_delivery_slots",
                                              },
                                            ],
                                            attrs: { type: "radio" },
                                            domProps: {
                                              value: 0,
                                              checked: _vm._q(
                                                _vm.deliveryArea
                                                  .use_default_delivery_slots,
                                                0
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$set(
                                                  _vm.deliveryArea,
                                                  "use_default_delivery_slots",
                                                  0
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                          _vm._v(
                                            " Personnaliser\n                                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    staticClass: "col-12",
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "use_default_delivery_slots",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          !_vm.deliveryArea.use_default_delivery_slots
                            ? _c("div", { staticClass: "row mt-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "offset-1 col-11 pt-2" },
                                  [
                                    _vm._l(_vm.allDays, function (day) {
                                      return _c("div", { key: day }, [
                                        _c("div", { staticClass: "mb-2" }, [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "container-box",
                                              staticStyle: {
                                                "margin-bottom": "0",
                                                width: "100%",
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.active[day],
                                                    expression: "active[day]",
                                                  },
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.active[day]
                                                  )
                                                    ? _vm._i(
                                                        _vm.active[day],
                                                        null
                                                      ) > -1
                                                    : _vm.active[day],
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a = _vm.active[day],
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.active,
                                                            day,
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.active,
                                                            day,
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.active,
                                                        day,
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.dayLabel(day, true)
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm.active[day]
                                          ? _c(
                                              "div",
                                              { staticClass: "setting-open" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "setting-hours",
                                                  },
                                                  _vm._l(
                                                    _vm.deliverySlots[day],
                                                    function (slot) {
                                                      return _c(
                                                        "span",
                                                        {
                                                          key: slot.id,
                                                          staticClass:
                                                            "d-block",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "container-box ml-4",
                                                              staticStyle: {
                                                                width:
                                                                  "initial",
                                                              },
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      slot.enabled,
                                                                    expression:
                                                                      "slot.enabled",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  type: "checkbox",
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    Array.isArray(
                                                                      slot.enabled
                                                                    )
                                                                      ? _vm._i(
                                                                          slot.enabled,
                                                                          null
                                                                        ) > -1
                                                                      : slot.enabled,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$a =
                                                                          slot.enabled,
                                                                        $$el =
                                                                          $event.target,
                                                                        $$c =
                                                                          $$el.checked
                                                                            ? true
                                                                            : false
                                                                      if (
                                                                        Array.isArray(
                                                                          $$a
                                                                        )
                                                                      ) {
                                                                        var $$v =
                                                                            null,
                                                                          $$i =
                                                                            _vm._i(
                                                                              $$a,
                                                                              $$v
                                                                            )
                                                                        if (
                                                                          $$el.checked
                                                                        ) {
                                                                          $$i <
                                                                            0 &&
                                                                            _vm.$set(
                                                                              slot,
                                                                              "enabled",
                                                                              $$a.concat(
                                                                                [
                                                                                  $$v,
                                                                                ]
                                                                              )
                                                                            )
                                                                        } else {
                                                                          $$i >
                                                                            -1 &&
                                                                            _vm.$set(
                                                                              slot,
                                                                              "enabled",
                                                                              $$a
                                                                                .slice(
                                                                                  0,
                                                                                  $$i
                                                                                )
                                                                                .concat(
                                                                                  $$a.slice(
                                                                                    $$i +
                                                                                      1
                                                                                  )
                                                                                )
                                                                            )
                                                                        }
                                                                      } else {
                                                                        _vm.$set(
                                                                          slot,
                                                                          "enabled",
                                                                          $$c
                                                                        )
                                                                      }
                                                                    },
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("span", {
                                                                staticClass:
                                                                  "checkmark",
                                                              }),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.formatHour(
                                                                      slot.hour_start
                                                                    )
                                                                  ) +
                                                                  "\n                                                    - " +
                                                                  _vm._s(
                                                                    _vm.formatHour(
                                                                      slot.hour_end
                                                                    )
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    }),
                                    _vm._v(" "),
                                    _c("ShowErrors", {
                                      staticClass: "d-block mt-1",
                                      attrs: {
                                        errors: _vm.errors,
                                        errorKey: "delivery_slots.*",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }