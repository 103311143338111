export default {
    data() {
        return {
            PUBLIC_API_CLIENT_POINTEX: {
                value: "Pointex",
                label: "Pointex",
                getIcon() {
                    return {
                        html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.31 12.31">
                           <g>
                             <g>
                               <g>
                                 <path fill="#06ade0" d="m2.17,6.16c0,1.31.64,2.48,1.62,3.21l-1.2.54-.07,1.22c-1.53-1.12-2.52-2.93-2.52-4.97C0,3.67,1.48,1.52,3.61.55l1.04.74-.04,1.19c-1.43.61-2.43,2.02-2.43,3.67Z"/>
                                 <path fill="#717070" d="m12.28,6.73c-.29,3.13-2.92,5.58-6.13,5.58-1.36,0-2.61-.44-3.63-1.19l.07-1.22,1.2-.54c.66.49,1.48.78,2.37.78,2.07,0,3.77-1.57,3.96-3.59l1.08.7,1.08-.52Z"/>
                                 <path fill="#83bb26" d="m12.31,6.16c0,.2-.01.39-.03.58l-1.08.52-1.08-.7c.01-.13.02-.26.02-.39,0-2.2-1.78-3.99-3.98-3.99-.55,0-1.07.11-1.55.31l.04-1.19-1.04-.74c.78-.35,1.64-.55,2.55-.55,3.4,0,6.16,2.75,6.16,6.16Z"/>
                               </g>
                               <circle fill="#06ade0" cx="6.21" cy="6.05" r="1.19"/>
                             </g>
                           </g>
                         </svg>`,
                    };
                },
            },
            PUBLIC_API_CLIENT_FHR: {
                value: "FHR",
                label: "Fiducial Hôtel-Restaurant",
                notice: "notices.booking.interfaces.publicApiClient.fhr",
                getIcon() {
                    return {
                        img: "/images/issuers/IconFHR.png",
                    };
                },
                isEnabled: () => {
                    if (!this.isProduction()) {
                        return true;
                    }

                    return [1, 273, 285, 298].includes(Number.parseInt(this.$route.params.restaurant_id));
                },
            },
        };
    },
    methods: {
        getPublicApiClientIcon(provider) {
            const apiClient = this.ALL_PUBLIC_API_CLIENTS.find((pac) => pac.value === provider);

            return typeof apiClient !== "undefined" ? apiClient.getIcon() : undefined;
        },
        getPublicApiClientLabel(provider) {
            const apiClient = this.ALL_PUBLIC_API_CLIENTS.find((pac) => pac.value === provider);

            return typeof apiClient !== "undefined" ? apiClient.label : provider;
        },
    },
    computed: {
        ALL_PUBLIC_API_CLIENTS() {
            return this.ALL_PUBLIC_API_CLIENTS_WITH_DISABLED.filter((publicApiClient) => {
                if (typeof publicApiClient.isEnabled === "undefined") {
                    return true;
                }

                return publicApiClient.isEnabled();
            });
        },
        ALL_PUBLIC_API_CLIENTS_WITH_DISABLED() {
            return [this.PUBLIC_API_CLIENT_POINTEX, this.PUBLIC_API_CLIENT_FHR];
        },
    },
};
