export default {
    isReservationListCollapsed(state) {
        return state.isReservationListCollapsed;
    },
    isGroupsAndPrintTabCollapsed(state) {
        return state.isGroupsAndPrintTabCollapsed;
    },
    pinPercentOfMenuSold(state) {
        return state.pinPercentOfMenuSold;
    },
    pinPercentOfMenuOptionSold(state) {
        return state.pinPercentOfMenuOptionSold;
    },
    pinPercentOfGeneralOptionSold(state) {
        return state.pinPercentOfGeneralOptionSold;
    },
    pinPax(state) {
        return state.pinPax;
    },
    pinEvolutionNbResa(state) {
        return state.pinEvolutionNbResa;
    },
    pinCaPerMenu(state) {
        return state.pinCaPerMenu;
    },
    pinBookingsPeriod(state) {
        return state.pinBookingsPeriod;
    },
    pinClientLoyal(state) {
        return state.pinClientLoyal;
    },
    pinCaPerGeneralOption(state) {
        return state.pinCaPerGeneralOption;
    },
    pinCaPerMenuOption(state) {
        return state.pinCaPerMenuOption;
    },
};
