<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("infos.sms.foreignCostTab") }}</h5>
        <div slot="body">
            <div class="row">
                <div class="col-6">
                    <table class="table table-book table-striped table-sm">
                        <thead class="border-bottom">
                            <tr>
                                <th>{{ $tl("labels.form.country") }}</th>
                                <th>{{ $tl("labels.creditCost") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="cost in costPerCountryFormattedLeft" :key="cost.countryCode">
                                <td>{{ cost.countryName }}</td>
                                <td>{{ cost.cost }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-6">
                    <table class="table table-book table-striped table-sm">
                        <thead class="border-bottom">
                            <tr>
                                <th>{{ $tl("labels.form.country") }}</th>
                                <th>{{ $tl("labels.creditCost") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="cost in costPerCountryFormattedRight" :key="cost.countryCode">
                                <td>{{ cost.countryName }}</td>
                                <td>{{ cost.cost }}</td>
                            </tr>
                            <tr>
                                <td>{{ $tl("labels.restOfTheWorld") }}</td>
                                <td>1,50</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.close") }}
            </button>
        </div>
    </modal>
</template>

<script>
import purchaseSmsPack from "../../sms/PurchaseSmsPack.vue";
import countries from "i18n-iso-countries";
import LangsEnum from "../../../mixins/enums/LangsEnum";

export default {
    data: function () {
        return {
            loading: false,
            costPerCountry: {
                FR: 1,
                DZ: 1.3,
                DE: 1.9,
                AD: 1,
                AR: 1,
                AU: 1,
                BE: 1.7,
                BR: 0.5,
                CL: 1,
                CN: 1,
                CO: 0.6,
                KR: 1.3,
                DK: 1,
                EG: 1,
                AE: 1,
                ES: 1,
                US: 0.4,
                FI: 1.2,
                GR: 1.5,
                GP: 2.15,
                GF: 2.1,
                HU: 1.1,
                IE: 1.1,
                IL: 1,
                IT: 1,
                RE: 1.9,
                LU: 0.5,
                MA: 1,
                MQ: 2.15,
                MX: 0.85,
                MC: 1.4,
                NO: 1,
                NC: 2,
                PY: 1.2,
                NL: 1.6,
                PH: 1,
                PL: 1,
                PF: 2,
                PT: 1,
                GB: 1,
                RU: 0.8,
                RS: 1,
                SE: 1,
                CH: 0.8,
                TH: 1.5,
                TN: 1.7,
                TR: 1,
                VN: 1.1,
            },
        };
    },
    mixins: [LangsEnum],
    props: {
        smsPack: {
            default: "",
        },
    },
    computed: {
        costPerCountryFormatted() {
            let costPerCountry = [];
            Object.keys(this.costPerCountry).forEach((countryCode) => {
                costPerCountry.push({
                    countryName: this.getCountryName(countryCode),
                    cost: this.formatCredits(this.costPerCountry[countryCode]),
                    countryCode,
                });
            });
            return costPerCountry.sort((a, b) => a.countryName.localeCompare(b.countryName));
        },
        costPerCountryFormattedLeft() {
            return this.costPerCountryFormatted.slice(0, Math.ceil((this.costPerCountryFormatted.length + 1) / 2));
        },
        costPerCountryFormattedRight() {
            return this.costPerCountryFormatted.slice(Math.ceil((this.costPerCountryFormatted.length + 1) / 2));
        },
    },
    methods: {
        formatCredits(nb) {
            return Number.parseFloat(nb).toFixed(2).toString().replace(".", ",");
        },
        getCountryName(countryCode) {
            return countries.getName(countryCode, this.userLang, { select: "official" });
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        purchaseSmsPack,
    },
};
</script>
