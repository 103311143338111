import { render, staticRenderFns } from "./StatsServices.vue?vue&type=template&id=19a5fb6e"
import script from "./StatsServices.vue?vue&type=script&lang=js"
export * from "./StatsServices.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('19a5fb6e')) {
      api.createRecord('19a5fb6e', component.options)
    } else {
      api.reload('19a5fb6e', component.options)
    }
    module.hot.accept("./StatsServices.vue?vue&type=template&id=19a5fb6e", function () {
      api.rerender('19a5fb6e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/Admin/StatsServices.vue"
export default component.exports