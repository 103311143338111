<template>
    <div class="p-2">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">
                        <button class="btn pl-0" @click="$router.go(-1)">
                            <feather type="chevron-left" class="feather-24" />
                        </button>
                        {{ videoId === null ? $t("labels.admin.videos.add") : $t("labels.admin.videos.edit") }}
                    </h5>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <form v-else class="row" @submit="saveVideo">
            <div class="col-12">
                <div class="border-light b-radius-20 p-4">
                    <div class="row mb-2">
                        <div class="col-md-5 pt-1">
                            <label>{{ $t("labels.admin.videos.columns.title") }} <small>*</small></label>
                        </div>
                        <div class="col-md-7">
                            <input type="text" class="form-control" v-model="video.title" maxlength="100" />
                            <ShowErrors class="d-block mt-1" :errors="errors" errorKey="title" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-5 pt-1">
                            <label>{{ $t("labels.admin.videos.columns.description") }}</label>
                        </div>
                        <div class="col-md-7">
                            <TextAreaComponentOld :txtAreaObj="video" txtAreaObjKey="description" :maxNbChar="500" />
                            <ShowErrors class="d-block" :errors="errors" errorKey="description" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-5 pt-1">
                            <label>
                                {{ $t("labels.admin.videos.youtubeVideoId") }}
                                <feather
                                    class="text-danger ml-1 mr-1"
                                    type="youtube"
                                    v-tooltip="getTooltip($t('infos.admin.videos.youtubeVideoId'))" />
                                <small>*</small>
                                <feather
                                    class="ml-1 pointer tooltip-infos"
                                    type="info"
                                    v-tooltip="getTooltipNotice('notices.videos.getYoutubeVideoId')" />
                            </label>
                        </div>
                        <div class="col-md-7">
                            <input type="text" class="form-control" v-model="video.youtube_video_id" maxlength="300" />
                            <ShowErrors class="d-block" :errors="errors" errorKey="youtube_video_id" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-5 pt-1">
                            <label> {{ $t("labels.admin.videos.columns.module") }} <small>*</small> </label>
                        </div>
                        <div class="col-md-7">
                            <select class="custom-select" v-model="video.module">
                                <option :value="null">--</option>
                                <option :value="MODULE_TYPE_DEFAULT.value">
                                    {{ getModuleTypeLabel(MODULE_TYPE_DEFAULT.value) }}
                                </option>
                                <option v-for="module in ALL_MODULES_TYPES_WITH_CAMPAIGNS" :value="module.value" :key="module.value">
                                    {{ getModuleTypeLabel(module.value) }}
                                </option>
                            </select>
                            <ShowErrors class="d-block" :errors="errors" errorKey="module" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4 offset-md-4 text-center">
                            <button
                                type="button"
                                class="btn btn-sm btn-circle btn-success"
                                :disabled="!canShowPreview"
                                @click="showPreview = !showPreview">
                                {{ showPreview ? $t("labels.admin.videos.preview.hide") : $t("labels.admin.videos.preview.show") }}
                            </button>
                        </div>
                        <div class="col-12 col-md-4 text-right">
                            <button class="btn btn-sm btn-success btn-circle mt-2 mr-1" type="submit">
                                {{ $t("labels.form.actions.save") }}
                            </button>
                        </div>
                    </div>
                    <div class="row mt-2" v-if="showPreview">
                        <ShowYoutubeVideo width="560" height="315" class="mx-auto" :youtubeVideoId="video.youtube_video_id" :title="video.title" />
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent";
import ShowErrors from "../../../components/errors/ShowErrors.vue";
import TextAreaComponentOld from "../../../components/forms/TextAreaComponentOld.vue";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum";
import ShowYoutubeVideo from "../../../components/videos/ShowYoutubeVideo.vue";

export default {
    name: "VideosList",
    data() {
        return {
            loading: false,
            errors: null,
            showPreview: false,
            video: {
                title: null,
                description: null,
                youtube_video_id: null,
                module: null,
            },
        };
    },
    mixins: [ModuleTypesEnum],
    methods: {
        fetchVideo() {
            this.loading = true;

            axios
                .get(`/api/admin/videos/${this.videoId}`)
                .then((response) => {
                    this.video = response.data;
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        saveVideo(e) {
            if (e) {
                e.preventDefault();
            }

            this.loading = true;
            this.errors = null;

            if (this.videoId === null) {
                var promise = axios.post("/api/admin/videos", this.video);
            } else {
                var promise = axios.put(`/api/admin/videos/${this.videoId}`, this.video);
            }

            promise
                .then((response) => {
                    this.notifySuccess(response);
                    this.$router.push({ name: "admin.videos.list" });
                })
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.errors) {
                        this.errors = error.response.data.errors;
                    }
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    computed: {
        videoId() {
            const videoId = this.$route.params.video_id;

            return videoId === "add" ? null : videoId;
        },
        canShowPreview() {
            return this.video.youtube_video_id !== null && this.video.youtube_video_id !== "";
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
        TextAreaComponentOld,
        ShowYoutubeVideo,
    },
    watch: {
        "video.youtube_video_id": function (newVal) {
            if (newVal === null || newVal === "") {
                this.showPreview = false;
            }
        },
    },
    created() {
        if (this.videoId !== null) {
            this.fetchVideo();
        }
    },
};
</script>
