<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Annulation de la commande {{ command.numero }}</h5>
        <div slot="body">
            <template v-if="stripePayment">
                <div class="row">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <p>
                                <strong>L'annulation d'une commande entraine son remboursement.</strong><br /><br />

                                Vous pouvez choisir de rembourser totalement le client, ou de conserver les frais de
                                transaction bancaire.<br />
                                Le paiement de cette commande a généré
                                <strong>{{ formatCurrency(stripePayment.stripe_fees / 100) }}</strong> de frais
                                Stripe.<br />
                            </p>
                            <div class="d-flex flex-start">
                                Conserver les frais Stripe
                                <div class="d-inline-block align-middle lead-switch">
                                    <label class="container-box">
                                        <input
                                            type="checkbox"
                                            class="switch align-self-center is-rounded"
                                            :checked="keep_fees.stripe" />
                                        <label @click="keep_fees.stripe = !keep_fees.stripe"></label>
                                    </label>
                                </div>
                            </div>
                            <div class="text-right">
                                Montant remboursé :
                                <strong v-if="!keep_fees.stripe" class="value">
                                    {{ formatCurrency(command.total_amount / 100) }}
                                </strong>
                                <strong v-else class="value">
                                    {{ formatCurrency(command.total_amount / 100 - stripePayment.stripe_fees / 100) }}
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div slot="footer" class="d-flex">
            <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="close">
                Annuler
            </button>
            <button type="button" class="btn btn-sm btn-success btn-circle ml-2" @click="validate">Valider</button>
        </div>
    </modal>
</template>

<script>
import PaymentStatusEnum from "../../../../mixins/enums/click_and_collect/PaymentStatusEnum";
import OnlinePaymentMethodEnum from "../../../../mixins/enums/click_and_collect/OnlinePaymentMethodEnum";

export default {
    data() {
        return {
            keep_fees: {
                stripe: false,
            },
        };
    },
    mixins: [PaymentStatusEnum, OnlinePaymentMethodEnum],
    computed: {
        stripePayment() {
            return this.command.command_payments.data.find((payment) => {
                return (
                    payment.online_payment_method == this.ONLINE_PAYMENT_METHOD_STRIPE.value &&
                    payment.status === this.PAYMENT_CAPTURED.value
                );
            });
        },
    },
    props: {
        command: {
            type: Object,
            required: true,
        },
    },
    methods: {
        validate() {
            this.$emit("validate", this.keep_fees);
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
};
</script>
