<template>
    <div class="mx-md-5 my-4 h-100">
        <select-licence
            v-if="step === 0"
            :show-back-button="showBackButton"
            :max="1"
            class="px-3"
            :preselected-modules="modules"
            @back="$emit('back')"
            @selected="
                modules = $event.selectedModules;
                plan = $event.plan;
                training = $event.training;
                step = 1;
            " />
        <pay-licences
            class="px-3"
            @back="step = 0"
            v-else-if="step === 1"
            :modules="modules"
            :plan="plan"
            :training="training"
            :redirect="{ name: 'home' }"
            :facturationEditable="true"
            :restaurant_id="restaurant_id">
        </pay-licences>
    </div>
</template>

<script>
import SelectLicence from "../../../components/Modules/Licences/Select";
import PayLicences from "../../../components/Modules/Licences/Pay";

export default {
    name: "RegistrationSelectLicence",
    data() {
        return {
            modules: [],
            step: 0,
            plan: "yearly",
            training: false,
        };
    },
    props: {
        showBackButton: {
            default: false,
        },
        restaurant_id: {
            default: null,
        },
    },
    components: {
        SelectLicence,
        PayLicences,
    },
};
</script>
