var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "default-home" },
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                  },
                  [
                    _c("h5", { staticClass: "title mt-2" }, [
                      _vm._v(_vm._s(_vm.$tl("labels.routes.employees"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-sm btn-success btn-circle",
                            class: {
                              disabled: !_vm.has_right_to_create_employee,
                            },
                            attrs: {
                              to: {
                                name: "employees.add",
                                params: { restaurant_id: _vm.restaurant_id },
                              },
                            },
                          },
                          [
                            _c("feather", { attrs: { type: "plus" } }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$tl("labels.routes.addEmployee"))
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm.error
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.error) +
                          "\n                "
                      ),
                    ])
                  : _c("div", [
                      _vm.employees && _vm.employees.length > 0
                        ? _c(
                            "table",
                            {
                              staticClass:
                                "capitalize table table-sm table-striped border-bottom",
                            },
                            [
                              _c("thead", { staticClass: "border-bottom" }, [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.$tl("labels.form.fullname"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.$tl("labels.form.email"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$tl("labels.form.role"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl("labels.form.actions.title")
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.employees, function (employee) {
                                  return _c("tr", { key: employee.id }, [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(employee.firstname) +
                                          " " +
                                          _vm._s(employee.lastname)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(employee.email) +
                                          "\n                                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "text-transform": "capitalize",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.getRoleLabel(employee.role)
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: {
                                                  delay: { show: 400, hide: 0 },
                                                  content: _vm.$t(
                                                    "labels.form.actions.edit"
                                                  ),
                                                },
                                                expression:
                                                  "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $t('labels.form.actions.edit'),\n                                        }",
                                              },
                                            ],
                                            staticClass:
                                              "btn btn-sm btn-success",
                                            class: {
                                              disabled:
                                                !_vm.has_right_to_update_employee,
                                            },
                                            attrs: {
                                              to: {
                                                name: "employees.edit",
                                                params: {
                                                  restaurant_id:
                                                    _vm.restaurant_id,
                                                  employee_id: employee.id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c("feather", {
                                              attrs: { type: "edit" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: {
                                                  delay: { show: 400, hide: 0 },
                                                  content: _vm.$t(
                                                    "labels.form.actions.delete"
                                                  ),
                                                },
                                                expression:
                                                  "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $t('labels.form.actions.delete'),\n                                        }",
                                              },
                                            ],
                                            staticClass:
                                              "btn btn-sm btn-danger",
                                            attrs: {
                                              disabled:
                                                !_vm.has_right_to_delete_employee,
                                              "data-id": employee.id,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteEmployee(
                                                  employee.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("feather", {
                                              attrs: { type: "trash-2" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]
                          )
                        : _c("div", [
                            _c("em", [
                              _vm._v(_vm._s(_vm.$tl("labels.employees.empty"))),
                            ]),
                          ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-4 float-right" }, [
                        _vm.links.prev
                          ? _c(
                              "a",
                              {
                                staticClass: "btn btn-sm btn-outline-secondary",
                                attrs: { href: "javascript:" },
                                on: {
                                  click: function ($event) {
                                    return _vm.fetchData(_vm.links.prev)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$tl("labels.form.actions.previous")
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.links.next
                          ? _c(
                              "a",
                              {
                                staticClass: "btn btn-sm btn-outline-secondary",
                                attrs: { href: "javascript:" },
                                on: {
                                  click: function ($event) {
                                    return _vm.fetchData(_vm.links.next)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$tl("labels.form.actions.next")
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }