<template>
    <ul class="p-0">
        <module-list-item
            v-for="(module, index) in list"
            :key="index"
            :module="module"
            :small="small"
            :showButtons="showButtons"
            @clicked-on-same-module="$emit('clicked-on-same-module', $event)" />
    </ul>
</template>

<script>
import ModuleListItem from "./ModuleListItem.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";

export default {
    name: "ModulesList",
    mixins: [ModuleTypesEnum],
    props: {
        showButtons: {
            default: true,
        },
        small: {
            default: false,
        },
        modulesEnabled: {
            default: () => ["booking", "campaigns", "click-and-collect", "gift-vouchers", "website"],
            validator: function (value) {
                var modulesNames = ["booking", "campaigns", "click-and-collect", "gift-vouchers", "website"];

                if (Array.isArray(value)) {
                    for (var val of value) {
                        if (modulesNames.indexOf(val) === -1) {
                            return false;
                        }
                    }
                    return true;
                } else {
                    return modulesNames.indexOf(value) === -1;
                }
            },
        },
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        subscriptions() {
            return this.$store.getters["users/getOwnerSubscriptions"].filter((s) => {
                return ["active", "trialing", "past_due", "unpaid"].includes(s.status);
            });
        },
        modules() {
            const modules = [
                {
                    value: "booking",
                    goToUrl: { name: "booking.home" },
                    activateUrl: { name: "subscription.licences.create", query: { modules: "booking" } },
                    moreUrl: { name: "booking.home" },
                    title: "Réservations",
                    enabled: true,
                    icon: "calendar",
                },
                {
                    value: "click-and-collect",
                    goToUrl: { name: "click_and_collect.home" },
                    activateUrl: { name: "subscription.licences.create", query: { modules: "click-and-collect" } },
                    moreUrl: { name: "click_and_collect.home" },
                    title: "Vente en ligne",
                    enabled: true,
                    icon: "shopping-cart",
                },
                {
                    value: "gift-vouchers",
                    goToUrl: { name: "gift_vouchers.home" },
                    activateUrl: { name: "subscription.licences.create", query: { modules: "gift-vouchers" } },
                    moreUrl: { name: "gift_vouchers.home" },
                    title: "Bons cadeaux",
                    enabled: true,
                    icon: "tag",
                },
            ];

            const website = {
                value: "website",
                goToUrl: this.isYservices ? "https://admin.yproximite.fr" : { name: "website.home" },
                activateUrl: { name: "subscription.licences.create", query: { modules: "website" } },
                moreUrl: { name: "website.home" },
                title: "Site web",
                enabled: true,
                icon: "monitor",
            };

            const campaigns = {
                value: "campaigns",
                goToUrl: { name: "campaigns.home" },
                activateUrl: { name: "campaigns.home" },
                moreUrl: { name: "campaigns.home" },
                title: "Campagnes",
                enabled: true,
                icon: "mail",
            };

            if (this.isNoShow) {
                modules.push(website, campaigns);
            } else if (this.isYservices) {
                modules.push(website);
            }

            return modules;
        },
        list() {
            if (Array.isArray(this.modulesEnabled)) {
                return this.modules
                    .map((module) => {
                        if (this.isYservices && module.value === this.MODULE_TYPE_WEBSITE.value) {
                            module.enabled = true;
                        } else {
                            module.enabled = this.subscriptions.some((subscription) => {
                                return subscription.module.type === module.value && subscription.restaurant_id !== null;
                            });
                        }

                        return module;
                    })
                    .filter((module) => {
                        if (!this.hasRights([module.value.replace(/-/g, "_") + ".*"])) {
                            return false;
                        }

                        return this.modulesEnabled.includes(module.value);
                    })
                    .sort((a, b) => {
                        if (a.enabled === false && b.enabled === true) {
                            return 1;
                        }
                        if (a.enabled === b.enabled) {
                            return 0;
                        } else {
                            return -1;
                        }
                    });
            } else {
                return [
                    this.modules.find((module) => {
                        return module.value === this.modulesEnabled;
                    }),
                ];
            }
        },
    },
    methods: {
        hasRights(rights) {
            if (rights.includes("any")) return true;
            return rights.some((right) => {
                if (this.rights.includes(right)) return true;
                let i = right.indexOf("*");
                if (i !== -1) {
                    right = right.substring(0, i).replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
                    let regexp = new RegExp(`${right}.+`, "g");
                    return this.rights.some((cright) => cright.match(regexp));
                }
                return false;
            });
        },
    },
    components: {
        ModuleListItem,
    },
};
</script>
