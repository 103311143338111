<template>
    <div>
        <div class="row p-1">
            <div class="col-12">
                <LoaderComponent v-if="loading" />
                <span class="text-danger" v-else-if="error">{{ error }}</span>
                <div class="row" v-else>
                    <span class="col-12 text-danger" v-if="hasTablesSelected">{{
                        $tl("infos.booking.reservations.add.changeSlotSelectedTables", restaurant_id)
                    }}</span>
                    <template v-if="services && services.length > 0">
                        <slotsReservations
                            class="col-12"
                            :class="{ 'mb-3': index != services.length - 1 }"
                            v-for="(service_id, index) in services"
                            :key="service_id"
                            :reservation="reservation"
                            :service_id="service_id"
                            :restaurant_id="restaurant_id"
                            :formErrors="formErrors"
                            :readOnly="readOnly || hasTablesSelected"
                            :serviceIdToPreselect="serviceIdToPreselect"
                            :slotIdToPreselect="slotIdToPreselect"
                            @slot-selected="$emit('next-step')" />
                    </template>
                    <div class="col-12 text-danger" v-else>
                        {{ $tl("labels.booking.services.noOneAvailable", restaurant_id) }}
                    </div>
                    <div class="col-12 mt-3">
                        <div class="row m-0">
                            <div
                                class="col-12 p-0 text-right"
                                v-if="restaurants_count && restaurants_count > 1 && selected_destination_restaurant === null">
                                <div
                                    class="d-inline-block"
                                    v-tooltip="
                                        hasTablesSelected
                                            ? {
                                                  content: $tl('infos.booking.reservations.edit.changeRestaurant', restaurant_id),
                                              }
                                            : undefined
                                    ">
                                    <button
                                        :disabled="hasTablesSelected"
                                        type="button"
                                        class="btn btn-sm btn-success btn-circle"
                                        @click="showSelectRestaurantModal = true">
                                        <svg
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather css-i6dzq1">
                                            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                            <polyline points="16 17 21 12 16 7"></polyline>
                                            <line x1="21" y1="12" x2="9" y2="12"></line>
                                        </svg>
                                        {{ $tl("labels.booking.reservations.edit.changeRestaurant") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="selected_destination_restaurant">
                            <div class="col-12 d-flex justify-content-between">
                                <h6 style="color: #30a4b7" class="pt-1">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather css-i6dzq1">
                                        <polyline points="15 10 20 15 15 20"></polyline>
                                        <path d="M4 4v7a4 4 0 0 0 4 4h12"></path>
                                    </svg>
                                    {{ selected_destination_restaurant.name }}
                                </h6>
                                <div>
                                    <button
                                        type="button"
                                        :disabled="hasTablesSelected"
                                        class="btn btn-sm btn-success btn-circle"
                                        @click="selectDestinationRestaurant(null)">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-arrow-left">
                                            <line x1="19" y1="12" x2="5" y2="12"></line>
                                            <polyline points="12 19 5 12 12 5"></polyline>
                                        </svg>
                                        {{ $tl("labels.backToRestaurant", { restaurant: baseRestaurant.name }) }}
                                    </button>
                                    <button
                                        type="button"
                                        :disabled="hasTablesSelected"
                                        class="btn btn-sm btn-success btn-circle"
                                        @click="showSelectRestaurantModal = true"
                                        v-if="restaurants_count && restaurants_count > 2">
                                        <svg
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather css-i6dzq1">
                                            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                            <polyline points="16 17 21 12 16 7"></polyline>
                                            <line x1="21" y1="12" x2="9" y2="12"></line>
                                        </svg>
                                        {{ $tl("labels.changeRestaurant") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <select-restaurant-modal
            :exclude_restaurant_id="restaurant_id"
            v-if="showSelectRestaurantModal"
            @close="showSelectRestaurantModal = false"
            @selected="selectDestinationRestaurant" />
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../LoaderComponent.vue";
import slotsReservations from "../slotsReservations.vue";
import SelectRestaurantModal from "../../../../components/Modals/SelectRestaurantModal.vue";

export default {
    data() {
        return {
            loading: false,
            error: null,
            services: [],
            showSelectRestaurantModal: false,
            restaurants_count: 0,
        };
    },
    props: {
        restaurant_id: {
            default: null,
        },
        reservation: {
            type: Object,
            required: true,
        },
        formErrors: {
            default: null,
        },
        readOnly: {
            default: false,
        },
        vueMultiSelect: {
            type: Object,
            required: true,
        },
        baseRestaurant: {
            type: Object,
            required: true,
        },
        selected_destination_restaurant: {
            type: Object,
            default: null,
        },
        serviceIdToPreselect: {
            type: Number,
            default: null,
        },
        slotIdToPreselect: {
            type: Number,
            default: null,
        },
    },
    computed: {
        seatingPlan() {
            let seatingPlan = null;

            if (this.reservation.service) {
                seatingPlan = this.reservation.service.seatingPlanFilled;
            }

            return seatingPlan;
        },
        hasTablesSelected() {
            if (this.seatingPlan) {
                return this.vueMultiSelect.entitiesSelected.length > 0;
            }

            return false;
        },
    },
    methods: {
        selectDestinationRestaurant(restaurant) {
            (this.showSelectRestaurantModal = false), this.$emit("select-destination-restaurant", restaurant);
        },
        loadServices(callBackError) {
            this.services = [];

            return axios
                .get(
                    `/api/restaurants/${this.restaurant_id}/servicesatdate?all=true&sorted=true&date=${this.reservation.reservation_date.toISODate()}`
                )
                .then((response) => {
                    this.services = response.data;
                })
                .catch(callBackError);
        },
        getRestaurantsCount(callBackError) {
            this.restaurants_count = 0;

            axios
                .get(`/api/booking/restaurants?all=1`)
                .then((response) => {
                    this.restaurants_count = response.data.data.length;
                })
                .catch(callBackError);
        },
        fetchData() {
            this.loading = true;
            this.error = null;
            const callBackError = (error) => {
                this.loading = false;
                this.error = this.getErrorMsgFromErrorResponse(error);
            };
            return Promise.all([this.loadServices(callBackError), this.getRestaurantsCount(callBackError)]).then(() => {
                this.loading = false;
            });
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
    },
    watch: {
        restaurant_id: {
            immediate: true,
            handler() {
                this.fetchData();
            },
        },
        "reservation.reservation_date": {
            handler() {
                this.fetchData().then(() => {
                    if (this.reservation.service)
                        if (!this.services.find((s) => s == this.reservation.service.id)) {
                            this.reservation.slot = null;
                            this.reservation.service = null;
                            this.reservation.slot_id = null;
                        }
                });
            },
        },
    },
    components: {
        LoaderComponent,
        slotsReservations,
        SelectRestaurantModal,
    },
};
</script>

<style></style>
