<template>
    <div class="pb-5 pt-5">
        <LoaderComponent v-if="isLoading" />
        <div v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">{{ $tl("labels.routes.booking.config.inputParams") }}</h5>
                    </div>
                </div>
            </div>
            <fields @update-fields="fields = $event" @config-missing="hideSaveButton = $event" />
        </div>
        <div v-if="!hideSaveButton" class="row m-0 mb-4">
            <div class="col-12">
                <button @click.prevent.stop="saveInputParams" type="button" class="btn btn-success btn-circle">
                    {{ $tl("labels.form.actions.save") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Fields from "../../Booking/Settings/InputParams/Fields.vue";
import LoaderComponent from "../../../components/LoaderComponent.vue";

export default {
    data() {
        return {
            isLoading: false,
            fields: [],
            hideSaveButton: true,
        };
    },
    computed: {
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        module() {
            return this.$route.name.split(".")[0].replaceAll("_", "-");
        },
    },
    methods: {
        saveInputParams() {
            this.isLoading = true;
            this.httpPost(`/api/restaurants/${this.restaurantId}/reservations/input-params?module=${this.module}`, {
                fields: this.fields,
            }).finally(() => (this.isLoading = false));
        },
    },
    components: {
        LoaderComponent,
        Fields,
    },
};
</script>
