<template>
    <div class="w-100 h-100 position-absolute">
        <div ref="content" class="pop-sidebar left module-switcher p-3" :class="{ show: show }">
            <div class="h-100 d-flex flex-column justify-content-between">
                <div>
                    <router-link :to="{ name: 'home' }" class="shadow-none navbar-brand mr-0 col-lg-2 col-md-3 pt-2 pb-2">
                        <img class="img-logo display-sid-min" src="/images/logo-lead-extend.png" v-if="!isDevelopement()" />
                        <img class="img-logo display-sid-min" src="/images/logo-lead-extend.png" v-else />
                    </router-link>
                    <modules-list :small="true" :showButtons="false" @clicked-on-same-module="close" />
                </div>
                <div class="ml-3">
                    <h5 class="title text-uppercase">{{ $tl("labels.myAccount") }}</h5>
                    <ul class="pl-0 mt-3">
                        <template v-for="entry in submenuEntries">
                            <li
                                v-if="(typeof entry.display === 'undefined' || entry.display === true) && hasRights(entry.rights)"
                                class="mb-2"
                                :key="getEntryTitle(entry)">
                                <router-link v-if="entry.to" :to="entry.to">
                                    {{ getEntryTitle(entry) }}
                                </router-link>
                                <template v-else-if="entry.url">
                                    <a v-if="entry.onClick" :href="entry.url" @click.prevent="entry.onClick" :class="entry.class">
                                        {{ getEntryTitle(entry) }}
                                    </a>
                                    <a v-else :href="entry.url" :target="entry.target" :class="entry.class">
                                        {{ getEntryTitle(entry) }}
                                    </a>
                                </template>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <div
            ref="icon"
            v-if="show"
            class="navbar-brand col-sm-12 mr-0 bg-dark position-absolute overlay-brand"
            :style="brandStyle"
            :class="{
                'col-lg-2 col-md-3 navbar-brand-not-collaped': !isSidebarCollapsed,
                'col-lg-1 col-md-1 text-center navbar-brand-collapsed': isSidebarCollapsed,
            }">
            <button class="btn text-white" @click="close">
                <feather type="grid" size="22" class="w-auto h-auto" />
            </button>
            <router-link v-if="selectedService" :to="selectedService.to">
                <img class="img-logo display-sid-min" :src="selectedService.icon" v-if="!isDevelopement() && !isSidebarCollapsed" />
                <img class="img-logo display-sid-min" :src="selectedService.iconDev" v-if="isDevelopement() && !isSidebarCollapsed" />

                <div style="height: 28px" class="img-logo-sid-min" v-show="isSidebarCollapsed"></div>
            </router-link>
        </div>
        <div class="overlay-blue-dark" :class="{ 'd-none': !show }"></div>
    </div>
</template>

<script>
import ModulesList from "../components/Modules/ModulesList.vue";

export default {
    name: "ModuleSwitcher",
    components: { ModulesList },
    props: {
        user: {
            required: true,
        },
        show: {
            required: true,
            type: Boolean,
        },
        beforeOpen: {
            default: () => {
                return () => {};
            },
            type: Function,
        },
        isSidebarCollapsed: {
            default: false,
        },
        serviceId: {
            required: true,
            validator: function (value) {
                return ["booking", "click-and-collect", "gift-vouchers", "website", "campaigns"];
            },
        },
    },
    data() {
        return {
            brandStyle: "",
            services: [
                {
                    id: "booking",
                    to: { name: "booking.home" },
                    icon: "/images/logos-saas/reservation.png",
                    iconDev: "/images/logos-saas/reservation.png",
                },
                {
                    id: "click-and-collect",
                    to: { name: "click_and_collect.home" },
                    icon: "/images/logos-saas/vae.png",
                    iconDev: "/images/logos-saas/vae.png",
                },
                {
                    id: "gift-vouchers",
                    to: { name: "gift_vouchers.home" },
                    icon: "/images/logos-saas/bc.png",
                    iconDev: "/images/logos-saas/bc.png",
                },
                {
                    id: "website",
                    to: { name: "website.home" },
                    icon: "/images/logos-saas/sw.png",
                    iconDev: "/images/logos-saas/sw.png",
                },
                {
                    id: "campaigns",
                    to: { name: "campaigns.home" },
                    icon: "/images/logos-saas/campagne.png",
                    iconDev: "/images/logos-saas/campagne.png",
                },
            ],
        };
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        selectedService() {
            return this.services.find((s) => s.id === this.serviceId);
        },
        submenuEntries() {
            return [
                {
                    to: { name: "home" },
                    titleI18n: "home",
                    rights: ["any"],
                },
                {
                    to: { name: "account" },
                    titleI18n: "account",
                    rights: ["any"],
                },
                {
                    to: { name: "restaurants" },
                    titleI18n: "restaurants",
                    rights: ["default.restaurants.read"],
                },
                {
                    to: { name: "subscription" },
                    titleI18n: "subscription",
                    rights: ["default.subscription.read"],
                    display: this.isNoShow,
                },
                {
                    to: { name: "employees" },
                    titleI18n: "users",
                    rights: ["default.employees.read"],
                    display: this.isNoShow,
                },
                {
                    url: "#",
                    class: "open-intercom",
                    titleI18n: "help",
                    rights: ["any"],
                    display: this.isNoShow,
                },
                {
                    url: "#",
                    onClick: () => {
                        if (typeof Beacon !== "undefined") {
                            Beacon("open");
                        }
                    },
                    titleI18n: "help",
                    rights: ["any"],
                    display: this.isYservices,
                },
                {
                    url: "http://help.noshow.io/",
                    target: "_blank",
                    titleI18n: "documentation",
                    rights: ["any"],
                    display: this.isNoShow,
                },
                {
                    url: "http://aide.y-services.fr/",
                    target: "_blank",
                    titleI18n: "documentation",
                    rights: ["any"],
                    display: this.isYservices,
                },
            ];
        },
    },
    methods: {
        getEntryTitle(entry) {
            if ("undefined" !== typeof entry.titleI18n) return this.$t(`labels.routes.${entry.titleI18n}`);
            return entry.title;
        },
        hasRights(rights) {
            if (rights.includes("any")) return true;
            return rights.some((right) => {
                if (this.rights.includes(right)) return true;
                let i = right.indexOf("*");
                if (i !== -1) {
                    right = right.substring(0, i).replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
                    let regexp = new RegExp(`${right}.+`, "g");
                    return this.rights.some((cright) => cright.match(regexp));
                }
                return false;
            });
        },
        open() {
            this.brandStyle = `left:${this.$refs.content.offsetWidth}px`;
            this.$emit("open", {
                width: this.$refs.content.offsetWidth,
                height: this.$refs.content.offsetHeight,
            });
        },
        close() {
            this.brandStyle = `left:0px`;
            this.$nextTick(() => {
                this.$emit("close");
            });
        },
    },
    watch: {
        show(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.open();
                });
            }
        },
    },
};
</script>
