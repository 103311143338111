var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        { name: "model", rawName: "v-model", value: _vm.tz, expression: "tz" },
      ],
      on: {
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.tz = $event.target.multiple ? $$selectedVal : $$selectedVal[0]
        },
      },
    },
    _vm._l(_vm.sortedRawTimeZones, function (tmpTz) {
      return _c(
        "option",
        { key: tmpTz.name, domProps: { value: tmpTz.name } },
        [
          _vm._v(
            "\n        " +
              _vm._s(tmpTz.name.replace(/_/g, " ")) +
              " (" +
              _vm._s(_vm.getOffsetInHours(tmpTz.rawOffsetInMinutes)) +
              ")\n    "
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }