<template>
    <div class="reservation-vue" style="height: calc(100vh - 124px) !important">
        <LoaderComponent v-show="loading" />
        <div v-show="!loading" class="top-nav-resa">
            <div v-show="error" class="error">
                {{ error }}
            </div>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-0 pt-2 pb-1 pl-3 pr-3 tablet-menu">
                <div>
                    <h2 class="h5 d-inline-block">
                        <router-link
                            :to="{
                                name: 'booking.restaurants.reservations',
                                params: { restaurant_id: restaurantId },
                                query: { date: reservation_date_prev, view },
                            }"
                            class="btn btn-sm btn-outline-secondary radius-btn-square"
                            style="width: 29px; padding-left: 5px">
                            <feather type="chevron-left" />
                        </router-link>
                        <span class="date-resa ml-1 mr-1 pt-1" style="text-transform: uppercase" @click="openPicker">
                            {{ displayDate(reservation_date, "cccc dd LLL") }}
                        </span>
                        <router-link
                            :to="{
                                name: 'booking.restaurants.reservations',
                                params: { restaurant_id: restaurantId },
                                query: { date: reservation_date_next, view },
                            }"
                            class="btn btn-sm btn-outline-secondary radius-btn-square"
                            style="width: 29px; padding-left: 6px">
                            <feather type="chevron-right" />
                        </router-link>

                        <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary none-mobile btn-circle"
                            @click="reservation_date = getDateTime()">
                            {{ $tl("labels.today") }}
                        </button>
                        <button type="button" class="btn btn-sm btn-outline-secondary none-desk btn-circle" @click="reservation_date = getDateTime()">
                            {{ $tl("labels.todayShort") }}
                        </button>
                    </h2>

                    <button type="button" class="btn btn-sm btn-danger btn-circle none-mobile ml-2" @click="showEventsForDay()">
                        <feather @click.stop="showEventsForDay(true)" class="ml-1" type="plus" />
                        <feather class="mx-1" type="volume-2" />
                        {{ $tl("labels.booking.customEvents.titleForDay") }} ({{ eventsForDay.length }})
                    </button>
                </div>
                <div class="d-inline-block align-middle switch-div-tablet" style="margin-top: -8px">
                    <div class="h-100 d-inline-block align-middle none-mobile" v-if="hasFeatSeatingPlan">
                        <toggle-switch :options="toggleSwitchOptions" v-model="contentToDisplay" class="p-0 m-0 d-inline-flex align-middle" />
                    </div>
                    <div class="ml-1 h-100 d-inline-block btn-toolbar none-mobile align-middle">
                        <div>
                            <input
                                class="search-resa form-control"
                                style="border-radius: 20px 0 0 20px !important"
                                type="text"
                                v-model="searchInput"
                                :placeholder="$t('labels.form.actions.search')"
                                @keyup.enter="search()" />
                            <button class="btn btn-sm btn-outline-secondary" style="border-radius: 0 20px 20px 0" @click="search()">
                                <feather type="search" />
                            </button>
                        </div>
                    </div>
                    <button
                        type="button"
                        class="btn btn-sm btn-circle btn-outline-secondary"
                        :class="{ disabled: !has_right_to_export_reservations }"
                        :disabled="!has_right_to_export_reservations"
                        @click="exportReservations">
                        <feather type="upload"></feather>
                        {{ $tl("labels.form.actions.export") }}
                    </button>
                </div>
            </div>
        </div>
        <DatePicker ref="theDatePicker" class="btn btn-sm date-resa date-resa-cal cal-fixed" v-model="reservation_date" />
        <div class="h-100 d-flex flex-column content-resa" style="margin-top: -10px" v-show="!error">
            <template v-if="!loading && services">
                <div v-if="services.length > 0" class="h-100">
                    <reservations-per-service
                        v-if="contentToDisplay === $t('labels.booking.list.title')"
                        @show-service-full="$parent.$emit('show-service-full', $event)"
                        :services="services"
                        :restaurant="restaurant"
                        :reservation_date="reservation_date"
                        :has-reservations="hasReservations"
                        :all-services-closed-and-no-resa="allServicesClosedAndNoResa"
                        :closure-name="closureName" />
                    <seating-plan-per-service
                        v-if="contentToDisplay === $t('labels.booking.seatingPlan.title')"
                        :date="reservation_date"
                        :services="services"
                        :restaurant="restaurant"
                        :reservation-added="reservationAdded"
                        :reservation-updated="reservationUpdated"
                        :reservation-deleted="reservationDeleted"
                        :all-services-closed-and-no-resa="allServicesClosedAndNoResa"
                        :closure-name="closureName"
                        @add-reservation="displayAddReservationModal"
                        @add-passing-customer="displayAddReservationModal($event, true)"
                        @go-to-resa-list="contentToDisplay = $t('labels.booking.list.title')" />
                    <timeline-per-service
                        v-if="contentToDisplay === $t('labels.booking.timeline.title')"
                        :restaurant="restaurant"
                        :services="services"
                        :reservation_date="reservation_date"
                        :all-services-closed-and-no-resa="allServicesClosedAndNoResa"
                        :closure-name="closureName"
                        @add-reservation="displayAddReservationModal"
                        @add-passing-customer="displayAddReservationModal($event, true)"
                        @go-to-resa-list="contentToDisplay = $t('labels.booking.list.title')" />
                </div>
                <no-opened-services v-else-if="services.length === 0" :is-forbbiden="isForbidden" />
            </template>
        </div>

        <add-reservation-modal
            v-if="showAddReservationModal && has_right_to_create_reservation"
            :tablesIdToPreselect="selected_tables_id"
            @reservation-saved="showAddReservationModal = false"
            :service_id="selected_service_id"
            :slot_id="selected_slot_id"
            :restaurant_id="restaurantId"
            @close="showAddReservationModal = false" />

        <add-passing-customer-modal
            v-if="showAddPassingCustomerModal && has_right_to_create_reservation"
            :tablesIdToPreselect="selected_tables_id"
            @saved="showAddPassingCustomerModal = false"
            :serviceId="selected_service_id"
            :restaurantId="restaurantId"
            :date="selectedDate"
            @close="showAddPassingCustomerModal = false" />

        <list-custom-events ref="listCustomEvents" v-bind="{ restaurantId, date: reservation_date.toISODate() }" />
    </div>
</template>

<script>
import axios from "axios";
import DatePicker from "../../components/forms/DatePicker.vue";
import LoaderComponent from "../../components/LoaderComponent.vue";
import ReservationsPerService from "../../components/reservations/ReservationsPerService.vue";
import SeatingPlanPerService from "../../components/seatingPlan/SeatingPlanPerService.vue";
import TimelinePerService from "../../components/reservations/TimelinePerService.vue";
import ToggleSwitch from "vuejs-toggle-switch";
import AddReservationModal from "../../components/Modals/reservations/AddReservationModalNew.vue";
import listCustomEvents from "../../components/customEvents/listCustomEvents.vue"; // showCustomEventsBtn can't be used here (z-index related)
import LexiconEnum from "../../mixins/enums/LexiconEnum.js";
import ReservationViewEnum from "../../mixins/enums/booking/ReservationViewEnum.js";
import AddPassingCustomerModal from "../../components/Modals/reservations/AddPassingCustomerModal.vue";
import NoOpenedServices from "../../components/Booking/NoOpenedServices.vue";

export default {
    data() {
        return {
            isForbidden: false,
            loading: false,
            error: null,
            restaurant: {},
            services: [],
            waitinglist: null,
            searchInput: "",
            selected_service_id: null,
            selected_slot_id: null,
            selected_tables_id: [],
            showReservationModal: false,
            showRemoveServiceFullModal: false,
            showEditReservationModal: false,
            showWaitingsModal: false,
            showSetNoshowModal: false,
            contentToDisplay: null, // ('Liste' | 'Plan de salle' | 'Timeline')
            toggleSwitchOptions: {
                layout: {
                    color: "#30a4b7",
                    fontWeightSelected: "normal",
                    backgroundColor: "white",
                    selectedColor: "white",
                    selectedBackgroundColor: "#30a4b7",
                    borderColor: "#30a4b7",
                },
                size: {
                    fontSize: 0.8,
                    height: 1.7,
                    padding: 0.21,
                    width: 17,
                },
                items: {
                    delay: 0.4,
                    preSelected: this.$tl("labels.booking.list.title"),
                    labels: [
                        { name: this.$tl("labels.booking.list.title") },
                        { name: this.$tl("labels.booking.seatingPlan.title") },
                        { name: this.$tl("labels.booking.timeline.title") },
                    ],
                },
            },
            showAddReservationModal: false,
            showAddPassingCustomerModal: false,
            view: null,
            selectedDate: null,
            reservationAdded: {},
            reservationUpdated: {},
            reservationDeleted: {},
        };
    },
    mixins: [LexiconEnum, ReservationViewEnum],
    computed: {
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        reservation_date_prev() {
            return this.reservation_date.minus({ days: 1 }).toISODate();
        },
        reservation_date_next() {
            return this.reservation_date.plus({ days: 1 }).toISODate();
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_reservation() {
            return this.rights.includes("booking.booking.create");
        },
        has_right_to_export_reservations() {
            return this.rights.includes("booking.booking.export");
        },
        reservation_date: {
            get() {
                if (this.$route.query.date) {
                    return this.getDateTime(this.$route.query.date, false);
                }
                return this.getDateTime();
            },
            set(newVal) {
                this.changeDate(newVal.toISODate());
            },
        },
        restaurantCustomEvents() {
            const storeRestaurant = this.$store.getters["restaurants/findRestaurantById"](this.restaurantId) || null;

            return storeRestaurant !== null ? storeRestaurant.custom_events.data : [];
        },
        eventsForDay() {
            const date = this.reservation_date.toJSDateCustom(false);

            return this.restaurantCustomEvents.filter(
                (customEvent) => this.getDateTime(customEvent.date_begin) <= date && this.getDateTime(customEvent.date_end) >= date
            );
        },
        widget() {
            return this.$store.getters["widgets/getWidget"];
        },
        queryView() {
            return this.$route.query.view;
        },
        hasFeatSeatingPlan() {
            return typeof this.restaurant !== "undefined" && this.restaurant.feat_seating_plan;
        },
        hasReservations() {
            for (const index in this.services) {
                if (this.services[index].reservations && this.services[index].reservations.filter((r) => r.status !== "pending").length > 0)
                    return true;
            }

            return false;
        },
        hasWaitings() {
            for (const index in this.services) {
                if (this.services[index].waitings_count > 0) return true;
            }

            return false;
        },
        closureName() {
            const serviceWithClosure = this.services.find((s) => s.has_closure);
            if (serviceWithClosure) return serviceWithClosure.closure_name;
            return "";
        },
        allServicesClosedAndNoResa() {
            return this.services.length > 0 && !this.hasReservations && !this.hasWaitings && !this.services.some((s) => !s.has_closure);
        },
    },
    methods: {
        displayDefaultView() {
            const label = this.widget ? this.widget.default_booking_view : this.RESERVATION_VIEW_LIST.value;

            this.contentToDisplay = this.$t(`labels.booking.${this.queryView || label}.title`);
            this.toggleSwitchOptions.items.preSelected = this.$t(`labels.booking.${this.queryView || label}.title`);
        },
        showEventsForDay(showAddModal = false) {
            this.$refs.listCustomEvents.open();

            if (showAddModal) {
                this.$nextTick(() => this.$refs.listCustomEvents.addCustomEvent());
            }
        },
        openPicker() {
            this.$refs.theDatePicker.getDatePicker().showCalendar();
        },
        exportReservations() {
            this.$router.push({
                name: "booking.restaurants.exportReservations",
                query: { date: this.reservation_date.toISODate() },
            });
        },
        fetchData() {
            this.loading = true;
            this.error = null;

            this.unregisterAllSockets();

            Promise.all([this.fetchRestaurant(), this.fetchServices()]).finally(() => (this.loading = false));
        },
        fetchRestaurant() {
            return this.httpGet(`/api/restaurants/${this.restaurantId}`, { handleReject: false, mustReject: true })
                .then((response) => {
                    this.restaurant = response.data;
                })
                .catch((error) => {
                    if (this.error === null) {
                        this.error = this.getErrorMsgFromErrorResponse(error);
                    }
                });
        },
        fetchServices() {
            this.isForbidden = false;

            const url =
                `/api/restaurants/${this.restaurantId}/reservations` +
                `?reservation_date=${this.reservation_date.toISODate()}` +
                "&include=payment,gv_validity,slot,client,tables,option_bank_score,confirmation_score,user";

            return this.httpGet(url, { handleReject: false, mustReject: true })
                .then((response) => {
                    this.$set(this, "services", response.data.data ?? []);

                    this.$nextTick(() => {
                        this.registerSocketForServices();
                    });
                })
                .catch((error) => {
                    this.isForbidden = !!(error.response && error.response.status === 403);

                    if (this.isForbidden) {
                        return;
                    }

                    if (this.error === null) {
                        this.error = this.getErrorMsgFromErrorResponse(error);
                    }
                });
        },
        search() {
            this.$router.push({
                name: "booking.reservations.search",
                query: {
                    query: this.searchInput,
                },
            });
        },
        changeDate(date) {
            if (date !== this.$route.query.date) {
                this.$router.push({
                    name: "booking.restaurants.reservations",
                    params: { restaurant_id: this.restaurantId },
                    query: { date, view: this.view },
                });
            }
        },
        unregisterAllSockets() {
            this.$store.getters["sockets/getChannels"].forEach((channelName) => {
                Echo.leave(channelName);
            });

            this.$store.commit("sockets/removeAllChannels");
        },
        registerSocketForServices() {
            this.services.forEach((service) => {
                const channelName = `App.restaurant.${this.restaurant.id}.service.${service.id}.date.${this.reservation_date.toISODate()}`;
                Echo.private(channelName)
                    .listen(".reservation.updated", this.onReservationUpdated)
                    .listen(".reservation.added", this.onReservationAdded)
                    .listen(".reservation.deleted", this.onReservationDeleted)
                    .listen(".service.full.updated", this.onServiceFullChanged)
                    .listen(".service.updated", this.onServiceUpdated)
                    .listen(".waiting_list.count.updated", this.onWaitingListCountChanged);

                this.$store.commit("sockets/addChannel", channelName);
            });
        },
        onReservationUpdated(e) {
            let updatedReservation = e.reservation;

            if (typeof updatedReservation !== "undefined") {
                this.reservationUpdated = e.reservation;
                for (let i = 0; i < this.services.length; i++) {
                    const service = this.services[i];
                    if (service.id === updatedReservation.slot.service.id) {
                        for (let j = 0; j < service.reservations.length; j++) {
                            const reservation = service.reservations[j];
                            if (reservation.id === updatedReservation.id) {
                                updatedReservation.updated = true;
                                this.$set(this.services[i].reservations, j, updatedReservation);
                                this.$nextTick(() => {
                                    setTimeout(() => {
                                        updatedReservation.updated = false;
                                        this.$set(this.services[i].reservations, j, updatedReservation);
                                    }, 2000);
                                });
                                return;
                            }
                        }
                    }
                }

                if (updatedReservation.reservation_datetime.split(" ")[0] === this.reservation_date.toISODate()) {
                    const service = this.services.find((s) => s.id === updatedReservation.slot.service.id);
                    if (service) {
                        updatedReservation.new = true;
                        var j = service.reservations.push(updatedReservation) - 1;
                        this.$nextTick(() => {
                            setTimeout(() => {
                                updatedReservation.new = false;
                                this.$set(service.reservations, j, updatedReservation);
                            }, 2000);
                        });
                    }
                }
            }
        },
        onReservationAdded(e) {
            const newReservation = e.reservation;

            if (typeof newReservation !== "undefined" && newReservation.slot && newReservation.slot.service) {
                this.reservationAdded = e.reservation;
                for (let i = 0; i < this.services.length; i++) {
                    const service = this.services[i];

                    if (service && newReservation.slot.service && service.id === newReservation.slot.service.id) {
                        if (!service.reservations.find((r) => r.id === newReservation.id)) {
                            newReservation.new = true;
                            var j = this.services[i].reservations.push(newReservation) - 1;
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    newReservation.new = false;
                                    this.$set(this.services[i].reservations, j, newReservation);
                                }, 2000);
                            });
                        }
                    }
                }
            }
        },
        onReservationDeleted(e) {
            const deletedReservationId = Number.parseInt(e.reservation_id);
            const deletedReservationServiceId = Number.parseInt(e.service_id);

            if (!Number.isNaN(deletedReservationId) && !Number.isNaN(deletedReservationServiceId)) {
                this.reservationDeleted = { reservationId: deletedReservationId, serviceId: deletedReservationServiceId };

                this.services.find((service) => {
                    if (service.id === deletedReservationServiceId) {
                        const deletedReservationIndex = service.reservations.findIndex((reservation) => reservation.id === deletedReservationId);

                        if (deletedReservationIndex !== -1) {
                            service.reservations.splice(deletedReservationIndex, 1);
                            return true;
                        }
                    }

                    return false;
                });
            }
        },
        onServiceFullChanged(e) {
            var serviceUpdated = e.service;

            for (let i = 0; i < this.services.length; i++) {
                const service = this.services[i];
                if (service.id === serviceUpdated.id) {
                    this.$set(this.services[i], "is_full", serviceUpdated.is_full);
                }
            }
        },
        onServiceUpdated(e) {
            var serviceUpdated = e.service;

            for (let i = 0; i < this.services.length; i++) {
                const service = this.services[i];
                if (service.id === serviceUpdated.id) {
                    this.$set(this.services[i], "note", serviceUpdated.note);
                }
            }
        },
        onWaitingListCountChanged(e) {
            const serviceUpdated = e.service;

            for (let i = 0; i < this.services.length; i++) {
                const service = this.services[i];
                if (service.id === serviceUpdated.id) {
                    this.$set(this.services[i], "waitings_count", serviceUpdated.count);
                }
            }
        },
        displayAddReservationModal(defaultValues, isPassingCustomer = false) {
            if (!this.$_.isEmpty(defaultValues.tablesId)) {
                this.$set(this, "selected_tables_id", defaultValues.tablesId);
            } else {
                this.$set(this, "selected_tables_id", []);
            }

            if (defaultValues.serviceId) {
                this.$set(this, "selected_service_id", defaultValues.serviceId);
                this.$set(this, "selected_slot_id", defaultValues.slotId || null);
            } else {
                this.$set(this, "selected_service_id", null);
                this.$set(this, "selected_slot_id", null);
            }

            this.$set(this, "selectedDate", defaultValues.selectedDate || defaultValues.reservation_date || null);

            this.$nextTick(() => {
                if (isPassingCustomer) {
                    this.showAddPassingCustomerModal = true;
                } else {
                    this.showAddReservationModal = true;
                }
            });
        },
    },
    created() {
        this.fetchData();
        this.displayDefaultView();
    },
    beforeDestroy() {
        this.unregisterAllSockets();
    },
    watch: {
        restaurantId() {
            this.fetchData();
        },
        "$route.query.date": function () {
            this.fetchData();
        },
        contentToDisplay(newVal, oldValue) {
            if (oldValue !== null) {
                this.view = this.getReservationViewValue(newVal);
                this.$router.push({
                    name: "booking.restaurants.reservations",
                    params: { restaurant_id: this.restaurantId },
                    query: { date: this.reservation_date.toISODate(), view: this.view },
                });
                // This behavior was already implemented
                if (this.view === this.RESERVATION_VIEW_LIST.value) {
                    this.fetchData();
                }
            }
        },
        widget: {
            deep: true,
            handler() {
                this.displayDefaultView();
            },
        },
    },
    components: {
        AddReservationModal,
        DatePicker,
        LoaderComponent,
        ReservationsPerService,
        SeatingPlanPerService,
        TimelinePerService,
        ToggleSwitch,
        listCustomEvents,
        AddPassingCustomerModal,
        NoOpenedServices,
    },
};
</script>
