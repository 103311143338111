var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isFragmentLoading[_vm.sale.id]
      ? _c(
          "div",
          {
            staticClass: "spinner-border feather-20",
            attrs: { role: "status" },
          },
          [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
        )
      : _c("div", [
          _c(
            "button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.getTooltip("Éditer"),
                  expression: "getTooltip('Éditer')",
                },
              ],
              staticClass: "btn btn-sm btn-success btn-square",
              attrs: {
                type: "button",
                disabled: !_vm.hasRightToUpdate,
                title: "Modifier le bon cadeau",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.$emit("edit-sale", _vm.sale)
                },
              },
            },
            [_c("feather", { attrs: { type: "edit" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.getTooltip("Envoyer les détails au client"),
                  expression: "getTooltip('Envoyer les détails au client')",
                },
              ],
              staticClass: "btn btn-sm btn-outline-secondary btn-square",
              attrs: {
                type: "button",
                disabled: _vm.isDisabled || !_vm.hasRightToUpdate,
                title: "Envoyer les détails du bon cadeau au client",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.$emit("send-gv-details", _vm.sale.id)
                },
              },
            },
            [_c("feather", { attrs: { type: "send" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.getTooltip(
                    "Télécharger le bon cadeau au format PDF"
                  ),
                  expression:
                    "getTooltip('Télécharger le bon cadeau au format PDF')",
                },
              ],
              staticClass: "btn btn-sm btn-outline-secondary btn-square",
              attrs: {
                href: `/api/gift_vouchers/${_vm.restaurantId}/sales/${_vm.sale.id}/gv_pdf`,
                target: "_blank",
              },
            },
            [_c("feather", { attrs: { type: "download-cloud" } })],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }