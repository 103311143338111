(function (d) {
    const l = (d["fr"] = d["fr"] || {});
    l.dictionary = Object.assign(l.dictionary || {}, {
        "(may require <kbd>Fn</kbd>)": "(peut nécessiter <kbd> Fn </kbd> )",
        "%0 of %1": "%0 sur %1",
        Accept: "Accepter",
        Accessibility: "Accessibilité",
        "Accessibility help": "Aide à l'accessibilité",
        Aquamarine: "Bleu vert",
        "Below, you can find a list of keyboard shortcuts that can be used in the editor.":
            "Ci-dessous, vous trouverez une liste de raccourcis clavier pouvant être utilisés dans l’éditeur.",
        Big: "Grand",
        Black: "Noir",
        "Block styles": "Styles de bloc",
        Blue: "Bleu",
        Bold: "Gras",
        "Bold text": "Texte en gras",
        "Break text": "Saut de ligne",
        "Bulleted List": "Liste à puces",
        "Bulleted list styles toolbar": "Barre d'outils des styles de liste à puce",
        Cancel: "Annuler",
        "Caption for image: %0": "Légende de l'image : %0",
        "Caption for the image": "Légende de l'image",
        "Centered image": "Image centrée",
        "Change image text alternative": "Changer le texte alternatif à l’image",
        "Choose heading": "Choisir l'en-tête",
        Circle: "Cercle",
        Clear: "Effacer",
        "Click to edit block": "Cliquer pour modifier le bloc",
        Close: "Fermer",
        "Close contextual balloons, dropdowns, and dialogs": "Fermer les bulles contextuelles, les listes déroulantes et les boîtes de dialogue",
        "Content editing keystrokes": "Touches d'édition de contenu",
        "Copy selected content": "Copier le contenu sélectionné",
        "Create link": "Créer un lien",
        Decimal: "Chiffres",
        "Decimal with leading zero": "Chiffres avec des zéros supplémentaire",
        "Decrease indent": "Diminuer le retrait",
        "Decrease list item indent": "Diminuer le retrait des éléments de liste",
        Default: "Par défaut",
        "Dim grey": "Gris pâle",
        Disc: "Disque",
        "Document colors": "Couleurs du document",
        Downloadable: "Fichier téléchargeable",
        "Drag to move": "Faire glisser pour déplacer",
        "Dropdown toolbar": "Barre d'outils dans un menu déroulant",
        "Edit block": "Modifier le bloc",
        "Edit link": "Modifier le lien",
        "Editor block content toolbar": "Barre d'outils du contenu du bloc éditeur",
        "Editor contextual toolbar": "Barre d'outils contextuelle de l'éditeur",
        "Editor dialog": "Boîte de dialogue de l'éditeur",
        "Editor editing area: %0": "Zone d'édition de l'éditeur : %0",
        "Editor menu bar": "Barre de menu de l'éditeur",
        "Editor toolbar": "Barre d'outils de l'éditeur",
        "Enter image caption": "Saisir la légende de l’image",
        "Execute the currently focused button. Executing buttons that interact with the editor content moves the focus back to the content.":
            "Exécuter le bouton sur lequel se trouve le focus. L'exécution de boutons qui interagissent avec le contenu de l'éditeur ramène le focus sur le contenu.",
        "Font Background Color": "Couleur d'arrière-plan",
        "Font Color": "Couleur de police",
        "Font Family": "Police",
        "Font Size": "Taille de police",
        "Full size image": "Image taille réelle",
        Green: "Vert",
        Grey: "Gris",
        Heading: "En-tête",
        "Heading 1": "Titre 1",
        "Heading 2": "Titre 2",
        "Heading 3": "Titre 3",
        "Heading 4": "Titre 4",
        "Heading 5": "Titre 5",
        "Heading 6": "Titre 6",
        "Help Contents. To close this dialog press ESC.": "Contenu de l'aide. Pour fermer cette boîte de dialogue, appuyez sur ESC.",
        HEX: "HEX",
        "HTML object": "Objet HTML",
        Huge: "Enorme",
        "Image from computer": "Image provenant d'un ordinateur",
        "Image resize list": "Liste des tailles d'images",
        "Image toolbar": "Barre d'outils des images",
        "image widget": "Objet image",
        "In line": "Aligné",
        "Increase indent": "Augmenter le retrait",
        "Increase list item indent": "Augmenter le retrait des éléments de liste",
        Insert: "Insérer",
        "Insert a hard break (a new paragraph)": "Insérer une pause (un nouveau paragraphe)",
        "Insert a new paragraph directly after a widget": "Insérer un nouveau paragraphe directement après un widget",
        "Insert a new paragraph directly before a widget": "Insérer un nouveau paragraphe directement avant un widget",
        "Insert a soft break (a <code>&lt;br&gt;</code> element)": "Insérer un saut progressif (un élément <code> <br> </code> )",
        "Insert image": "Insérer une image",
        "Insert image via URL": "Insérer une image à partir d'une URL",
        "Insert paragraph after block": "Insérer du texte après ce bloc",
        "Insert paragraph before block": "Insérer du texte avant ce bloc",
        "Invalid start index value.": "Valeur d'index de départ invalide.",
        Italic: "Italique",
        "Italic text": "Texte en italique",
        "Keystrokes that can be used in a list": "Frappes de touches pouvant être utilisées dans une liste",
        "Keystrokes that can be used when a widget is selected (for example: image, table, etc.)":
            "Frappes de touches pouvant être utilisées lorsqu'un widget est sélectionné (par exemple : image, tableau, etc.)",
        "Left aligned image": "Image alignée à gauche",
        "Light blue": "Bleu clair",
        "Light green": "Vert clair",
        "Light grey": "Gris clair",
        Link: "Lien",
        "Link image": "Lien d'image",
        "Link URL": "URL du lien",
        "List properties": "Liste des propriétés",
        "Lower-latin": "Lettres minuscules",
        "Lower–roman": "Chiffres romains en minuscules",
        MENU_BAR_MENU_EDIT: "Éditer",
        MENU_BAR_MENU_FILE: "Fichier",
        MENU_BAR_MENU_FONT: "Police de caractère",
        MENU_BAR_MENU_FORMAT: "Format",
        MENU_BAR_MENU_HELP: "Aide",
        MENU_BAR_MENU_INSERT: "Insérer",
        MENU_BAR_MENU_TEXT: "Texte",
        MENU_BAR_MENU_TOOLS: "Outils",
        MENU_BAR_MENU_VIEW: "Afficher",
        "Move focus between form fields (inputs, buttons, etc.)": "Déplacer le focus entre les champs du formulaire (saisies, boutons, etc.)",
        "Move focus in and out of an active dialog window": "Déplacer le focus vers et hors d'une fenêtre de dialogue active",
        "Move focus to the menu bar, navigate between menu bars": "Déplacer le focus sur la barre du menu, naviguer entre les barres de menu",
        "Move focus to the toolbar, navigate between toolbars": "Déplacez le focus sur la barre d'outils, naviguez entre les barres d'outils",
        "Move out of a link": "Sortir d'un lien",
        "Move the caret to allow typing directly after a widget": "Déplacez le curseur pour permettre de taper directement après un widget",
        "Move the caret to allow typing directly before a widget": "Déplacez le curseur pour permettre la saisie directement avant un widget",
        "Multiple styles": "Styles multiples",
        "Navigate through the toolbar or menu bar": "Naviguer sur la barre d'outils ou la barre de menu",
        Next: "Suivant",
        "No results found": "Aucun résultat trouvé",
        "No searchable items": "Aucun élément consultable",
        "Numbered List": "Liste numérotée",
        "Numbered list styles toolbar": "Barre d'outils des styles de liste numérotée",
        "Open in a new tab": "Ouvrir dans un nouvel onglet",
        "Open link in new tab": "Ouvrir le lien dans un nouvel onglet",
        "Open the accessibility help dialog": "Ouvrir la boîte de dialogue d'aide sur l'accessibilité",
        Orange: "Orange",
        Original: "Taille originale",
        Paragraph: "Paragraphe",
        "Paste content": "Coller le contenu",
        "Paste content as plain text": "Coller le contenu sous forme de texte brut",
        "Press %0 for help.": "Appuyez sur %0 pour obtenir de l'aide.",
        "Press Enter to type after or press Shift + Enter to type before the widget":
            "Appuyez sur la touche Entrée pour taper après ou sur Maj + Entrée pour taper avant le widget.",
        Previous: "Précedent",
        Purple: "Violet",
        Red: "Rouge",
        Redo: "Restaurer",
        "Remove color": "Enlever la couleur",
        "Replace from computer": "Remplacer depuis l'ordinateur",
        "Replace image": "Remplacer l'image",
        "Replace image from computer": "Remplacer l'image depuis l'ordinateur",
        "Resize image": "Redimensionner l'image",
        "Resize image to %0": "Redimensionner à %0",
        "Resize image to the original size": "Appliquer les dimensions d'origine",
        "Reversed order": "Ordre inversé",
        "Rich Text Editor": "Éditeur de texte enrichi",
        "Right aligned image": "Image alignée à droite",
        Save: "Enregistrer",
        "Select all": "Sélectionner tout",
        "Show more items": "Montrer plus d'éléments",
        "Side image": "Image latérale",
        Small: "Petit",
        Square: "Carré",
        "Start at": "Commencer à",
        "Start index must be greater than 0.": "L'indice de départ doit être supérieur à 0.",
        Strikethrough: "Barré",
        "Strikethrough text": "Texte barré",
        Styles: "Styles",
        "Text alternative": "Texte alternatif",
        "Text styles": "Styles de texte",
        "These keyboard shortcuts allow for quick access to content editing features.":
            "Ces raccourcis clavier permettent un accès rapide aux fonctionnalités d'édition de contenu.",
        "This link has no URL": "Ce lien n'a pas d'URL",
        Tiny: "Minuscule",
        "Toggle caption off": "Désactiver la légende",
        "Toggle caption on": "Activer la légende",
        "Toggle the circle list style": 'Activer / désactiver le style de liste "cercle"',
        "Toggle the decimal list style": 'Activer / désactiver le style de liste "chiffres"',
        "Toggle the decimal with leading zero list style": 'Activer / désactiver le style de liste "chiffres de mêmes longueurs"',
        "Toggle the disc list style": 'Activer / désactiver le style de liste "disque"',
        "Toggle the lower–latin list style": 'Activer / désactiver le style de liste "lettres minuscules"',
        "Toggle the lower–roman list style": 'Activer / désactiver le style de liste "chiffres romains en minuscules"',
        "Toggle the square list style": 'Activer / désactiver le style de liste "carré"',
        "Toggle the upper–latin list style": 'Activer / désactiver le style de liste "lettres majuscules"',
        "Toggle the upper–roman list style": 'Activer / désactiver le style de liste "chiffres romains en majuscules"',
        Turquoise: "Turquoise",
        Underline: "Souligné",
        "Underline text": "Souligner le texte",
        Undo: "Annuler",
        Unlink: "Supprimer le lien",
        Update: "Modifier",
        "Update image URL": "Modifier l'URL de l'image",
        "Upload failed": "Échec de l'envoi",
        "Upload from computer": "Télécharger depuis l'ordinateur",
        "Upload image from computer": "Télécharger une image depuis l'ordinateur",
        "Upload in progress": "Téléchargement en cours",
        "Upper-latin": "Lettres majuscules",
        "Upper-roman": "Chiffres romains en majuscules",
        "Use the following keystrokes for more efficient navigation in the CKEditor 5 user interface.":
            "Utilisez les touches suivantes pour une navigation plus efficace dans l'interface utilisateur de CKEditor 5.",
        "User interface and content navigation keystrokes": "Interface utilisateur et frappes de navigation dans le contenu",
        White: "Blanc",
        "Widget toolbar": "Barre d'outils du widget",
        "Wrap text": "Retour à la ligne",
        Yellow: "Jaune",
    });
    l.getPluralForm = function (n) {
        return n == 0 || n == 1 ? 0 : n != 0 && n % 1000000 == 0 ? 1 : 2;
    };
})(window.CKEDITOR_TRANSLATIONS || (window.CKEDITOR_TRANSLATIONS = {}));
