<template>
    <div class="payments-grid">
        <ns-table
            tableClass="table table-striped overflowx-auto mr-3"
            :data-source="payments"
            allow-paging
            clickable
            is-server-side
            :pagination="pagination"
            :toolbar-settings="toolbarSettings"
            :current-sort="queries.sort"
            @update-data="$emit('update-data', $event)"
            @row-selected="rowSelected">
            <template v-slot:toolbar>
                <slot name="toolbar"></slot>
            </template>
            <template v-slot:head>
                <ns-th field="reservation_id" class="none-mobile" width="6" allow-sorting>
                    <span v-html="$t('labels.booking.payments.grid.columns.id')"></span>
                </ns-th>
                <ns-th field="reservation_payments.client" width="6" allow-sorting>
                    <span class="none-mobile" v-html="$t('labels.booking.payments.grid.columns.client')"></span>
                    <feather class="none-desk" type="user" v-tooltip="{ content: $t('labels.booking.payments.grid.columns.client') }" />
                </ns-th>
                <ns-th field="reservation_datetime" width="14" allow-sorting>
                    <span class="none-mobile" v-html="$t('labels.booking.payments.grid.columns.reservationDate')"></span>
                    <feather class="none-desk" type="clock" v-tooltip="{ content: $t('labels.booking.payments.grid.columns.reservationDate') }" />
                </ns-th>
                <ns-th
                    field="option_bank"
                    width="9"
                    allow-sorting
                    allow-filtering
                    :filterOptions="{ type: 'choices', choices: optionBanksForFiltering }"
                    :backend-selected-choices="selectedOptionBanks">
                    <span class="none-mobile" v-html="$t('labels.booking.payments.grid.columns.type')"></span>
                    <feather class="none-desk" type="credit-card" v-tooltip="{ content: $t('labels.booking.payments.grid.columns.type') }" />
                </ns-th>
                <ns-th field="stripe_intent_amount" width="9" allow-sorting>
                    <span class="none-mobile" v-html="$t('labels.booking.payments.grid.columns.amount')"></span>
                    <feather class="none-desk" type="dollar-sign" v-tooltip="{ content: $t('labels.booking.payments.grid.columns.amount') }" />
                </ns-th>
                <ns-th field="stripe_fees" width="7" allow-sorting>
                    <span class="none-mobile" v-html="$t('labels.booking.payments.grid.columns.fees')"></span>
                    <feather class="none-desk" type="dollar-sign" v-tooltip="{ content: $t('labels.booking.payments.grid.columns.fees') }" />
                </ns-th>
                <ns-th
                    field="status"
                    width="10"
                    allow-sorting
                    allow-filtering
                    :filterOptions="{ type: 'choices', choices: paymentStatusForFiltering }"
                    :backend-selected-choices="selectedStatuses">
                    <span class="none-mobile" v-html="$t('labels.booking.payments.grid.columns.status')"></span>
                    <feather class="none-desk" type="check-circle" v-tooltip="{ content: $t('labels.booking.payments.grid.columns.status') }" />
                </ns-th>
                <ns-th field="paid_at" width="15" class="none-mobile" allow-sorting>
                    <span v-html="$t('labels.booking.payments.grid.columns.paymentDate')"></span>
                </ns-th>
                <ns-th class="none-mobile">
                    <span v-html="$t('labels.booking.payments.grid.columns.actions')"></span>
                </ns-th>
            </template>

            <template v-slot:body="{ data }">
                <td class="none-mobile">{{ data.reservation_id }}</td>
                <td>
                    <span class="none-tablet">{{ getClientCivilityLabelShort(data.reservation.client.civility) }}</span>
                    {{ data.reservation.client.firstname }}
                    <span class="text-uppercase">{{ data.reservation.client.lastname }}</span>
                </td>
                <td>{{ displayDate(data.reservation.reservation_datetime, DATETIME_SHORT) }}</td>
                <td>
                    <feather :type="getOptionBankIcon(data.option_bank)" />
                </td>
                <td>{{ getStringAmount(data) }}</td>
                <td>{{ getStringFees(data) }}</td>
                <td>
                    <span class="resa-status booking-payment-status" :class="`booking-payment-${data.status}`">
                        {{ getPaymentStatusLabel(data.status) }}
                    </span>
                </td>
                <td class="none-mobile">
                    {{ data.paid_at ? displayDate(data.paid_at, DATE_SHORT) : "--" }}
                </td>
                <td class="none-mobile">
                    <button
                        class="btn btn-sm btn-outline-secondary btn-square"
                        :disabled="!has_right_to_export_payments || !data.invoice"
                        v-tooltip="getDownloadInvoiceTooltip(data)"
                        @click="downloadInvoice(data.reservation_id)">
                        <feather type="download-cloud"></feather>
                    </button>
                </td>
            </template>
        </ns-table>
    </div>
</template>

<script>
import NsTable from "../../Datatable/NsTable.vue";
import NsTh from "../../Datatable/NsTh.vue";
import ClientCivilityEnum from "../../../mixins/enums/ClientCivilityEnum.js";
import OptionBankEnum from "../../../mixins/enums/booking/OptionBankEnum.js";
import CardPaymentSystemTypeEnum from "../../../mixins/enums/CardPaymentSystemEnum.js";
import PaymentStatusEnum from "../../../mixins/enums/booking/PaymentStatusEnum.js";

export default {
    data() {
        return {
            option_bank: {
                filterOptions: {
                    type: "choices",
                    choices: this.ALL_OPTION_BANKS,
                },
            },
        };
    },
    props: {
        payments: {
            type: Array,
            required: true,
        },
        pagination: {
            type: Object,
            required: true,
        },
        queries: {
            type: Object,
            required: true,
        },
    },
    mixins: [PaymentStatusEnum, OptionBankEnum, ClientCivilityEnum, CardPaymentSystemTypeEnum],
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        isMobile() {
            return window.innerWidth <= 560;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_export_payments() {
            return this.rights.includes("booking.payments.export");
        },
        paymentStatusForFiltering() {
            return this.ALL_PAYMENT_STATUS.filter((status) => status.value !== this.PAYMENT_STATUS_PARTIALLY_REFUNDED.value).map((e) => {
                return {
                    value: e.value,
                    label: this.getPaymentStatusLabel(e.value),
                };
            });
        },
        optionBanksForFiltering() {
            return this.ALL_OPTION_BANKS.map((e) => {
                return {
                    value: e.value,
                    label: this.getOptionBankLabel(e.value),
                };
            });
        },
        selectedStatuses() {
            if (this.queries.dynamic_filters["status"]) {
                const statuses = this.queries.dynamic_filters["status"];
                return this.paymentStatusForFiltering.filter((status) => statuses.includes(status.value));
            }

            return this.paymentStatusForFiltering;
        },
        selectedOptionBanks() {
            if (this.queries.dynamic_filters["option_bank"]) {
                const bankOptions = this.queries.dynamic_filters["option_bank"];
                return this.optionBanksForFiltering.filter((status) => bankOptions.includes(status.value));
            }

            return this.optionBanksForFiltering;
        },
        allowExport() {
            return this.has_right_to_export_payments && this.payments.length > 0;
        },
        toolbarSettings() {
            let toolbarSetings = {
                items: [],
            };

            if (this.allowExport) {
                toolbarSetings.items.push({
                    type: "ExcelExport",
                    settings: {
                        headings: [
                            this.$tl("labels.booking.payments.grid.columns.id").replace(/<[^<>]+>/gi, ""),
                            this.$tl("labels.booking.payments.grid.columns.client").replace(/<[^<>]+>/gi, ""),
                            this.$tl("labels.booking.payments.grid.columns.reservationDate").replace(/<[^<>]+>/gi, ""),
                            this.$tl("labels.booking.payments.grid.columns.type").replace(/<[^<>]+>/gi, ""),
                            this.$tl("labels.booking.payments.grid.columns.amount").replace(/<[^<>]+>/gi, ""),
                            this.$tl("labels.booking.payments.grid.columns.fees").replace(/<[^<>]+>/gi, ""),
                            this.$tl("labels.booking.payments.grid.columns.status").replace(/<[^<>]+>/gi, ""),
                            this.$tl("labels.booking.payments.grid.columns.paymentDate").replace(/<[^<>]+>/gi, ""),
                        ],
                        autoWidth: true,
                        autoFormat: true,
                        formatRow: (payment) => {
                            let stripe_intent_amount = payment.stripe_intent_amount ? this.formatCurrency(payment.stripe_intent_amount / 100) : "--";

                            if (payment.status === this.PAYMENT_STATUS_PARTIALLY_REFUNDED.value) {
                                stripe_intent_amount += ` (${this.formatCurrency(payment.amount_refunded / 100)})`;
                            }

                            return [
                                payment.reservation_id,
                                `${payment.reservation.client.firstname || ""} ${payment.reservation.client.lastname || ""}`,
                                this.displayDate(payment.reservation_datetime, this.DATETIME_SHORT),
                                this.getOptionBankLabel(payment.option_bank),
                                stripe_intent_amount,
                                payment.stripe_fees ? this.formatCurrency(payment.stripe_fees / 100) : "--",
                                this.getPaymentStatusLabel(payment.status),
                                payment.paid_at ? this.displayDate(payment.paid_at, this.DATE_SHORT) : "--",
                            ];
                        },
                        anchorClasses: "btn btn-sm btn-outline-secondary export-hover",
                        anchorStyle: "border-radius: 0px 20px 20px 0",
                    },
                });
            }

            return toolbarSetings;
        },
    },
    methods: {
        getDownloadInvoiceTooltip(data) {
            if (!data.invoice || !this.has_right_to_export_payments) {
                return undefined;
            }

            return this.getTooltip(this.$tl("labels.invoices.downloadSingle"));
        },
        getStringAmount(data) {
            if (data.stripe_intent_amount == null) {
                return "--";
            }

            const amount = this.formatCurrency(data.stripe_intent_amount / 100);

            if (data.status === this.PAYMENT_STATUS_CAPTURED.value && data.stripe_intent_amount != data.amount_captured) {
                return `${amount} (${this.formatCurrency(data.amount_captured / 100)})`;
            }

            if (data.status === this.PAYMENT_STATUS_PARTIALLY_REFUNDED.value) {
                return `${amount} (${this.formatCurrency(data.amount_refunded / 100)})`;
            }

            return `${amount}`;
        },
        getStringFees(data) {
            if (data.card_system_type === this.CARD_PAYMENT_SYSTEM_PAYPLUG.value) {
                return "NC";
            }

            if (data.stripe_fees == null) {
                return "--";
            }

            return this.formatCurrency(data.stripe_fees / 100);
        },
        downloadInvoice(reservation_id) {
            window.open(this.addLangToURL(`/api/restaurants/${this.$route.params.restaurant_id}/reservations/${reservation_id}/invoice`), "_blank");
        },
        rowSelected({ event, data }) {
            const tagsToExclude = ["button", "a", "svg", "rect", "path", "line"];

            const targetTagName = event && event.target && event.target.tagName ? event.target.tagName.toLowerCase() : "";

            if (!tagsToExclude.includes(targetTagName)) {
                this.$emit("showResa", data.reservation_id);
            }
        },
    },
    components: {
        NsTable,
        NsTh,
    },
};
</script>
