var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("strong", [
            _vm._v(_vm._s(_vm.$tl("labels.form.facturation.title"))),
          ]),
          _vm._v(" "),
          _vm.isDevelopement()
            ? _c("feather", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value:
                      "Affichées sur les factures émises par NoShow vers le compte principal (sms, emails ...)",
                    expression:
                      "'Affichées sur les factures émises par NoShow vers le compte principal (sms, emails ...)'",
                  },
                ],
                staticClass: "ml-1 pointer tooltip-infos",
                attrs: { type: "info" },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("form", { on: { submit: _vm.postForm } }, [
      _c("label", { staticStyle: { width: "100%" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-5 pt-2" }, [
            _vm._v(_vm._s(_vm.$tl("labels.form.businessName")) + " *"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.company,
                  expression: "user.company",
                },
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.hasErrors("company") },
              attrs: {
                type: "text",
                id: "company",
                name: "company",
                disabled: !_vm.editable,
                required: "required",
              },
              domProps: { value: _vm.user.company },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "company", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.hasErrors("company")
              ? _c("div", {
                  staticClass: "invalid-feedback",
                  attrs: { role: "alert" },
                  domProps: { innerHTML: _vm._s(_vm.joinErrors("company")) },
                })
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("label", { staticStyle: { width: "100%" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-5 pt-2" }, [
            _vm._v(_vm._s(_vm.$tl("labels.form.businessType")) + " *"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.company_type,
                    expression: "user.company_type",
                  },
                ],
                staticClass: "custom-select mb-0",
                class: { "is-invalid": _vm.hasErrors("company_type") },
                attrs: {
                  id: "company_type",
                  name: "company_type",
                  disabled: !_vm.editable,
                  required: "",
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.user,
                      "company_type",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(_vm.ALL_BUSINESS_TYPES, function (businessType) {
                return _c(
                  "option",
                  {
                    key: businessType.value,
                    domProps: { value: businessType.value },
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.getBusinessTypeLabel(businessType.value)) +
                        "\n                        "
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.hasErrors("company_type")
              ? _c("div", {
                  staticClass: "invalid-feedback",
                  attrs: { role: "alert" },
                  domProps: {
                    innerHTML: _vm._s(_vm.joinErrors("company_type")),
                  },
                })
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("label", { staticStyle: { width: "100%" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-5 pt-2" }, [
            _vm._v(_vm._s(_vm.$tl("labels.form.facturation.siret")) + " *"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.company_siret,
                  expression: "user.company_siret",
                },
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.hasErrors("company_siret") },
              attrs: {
                type: "text",
                minlength: "14",
                maxlength: "14",
                id: "company_siret",
                name: "company_siret",
                disabled: !_vm.editable,
                required: "",
              },
              domProps: { value: _vm.user.company_siret },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "company_siret", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.hasErrors("company_siret")
              ? _c("div", {
                  staticClass: "invalid-feedback",
                  attrs: { role: "alert" },
                  domProps: {
                    innerHTML: _vm._s(_vm.joinErrors("company_siret")),
                  },
                })
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("label", { staticStyle: { width: "100%" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-5 pt-2" }, [
            _vm._v(_vm._s(_vm.$tl("labels.form.facturation.nafCode")) + " *"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.company_code_naf,
                  expression: "user.company_code_naf",
                },
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.hasErrors("company_code_naf") },
              attrs: {
                type: "text",
                id: "company_code_naf",
                name: "company_code_naf",
                disabled: !_vm.editable,
                required: "",
              },
              domProps: { value: _vm.user.company_code_naf },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "company_code_naf", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.hasErrors("company_code_naf")
              ? _c("div", {
                  staticClass: "invalid-feedback",
                  attrs: { role: "alert" },
                  domProps: {
                    innerHTML: _vm._s(_vm.joinErrors("company_code_naf")),
                  },
                })
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("label", { staticStyle: { width: "100%" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-5 pt-2" }, [
            _vm._v(_vm._s(_vm.$tl("labels.form.facturation.tvaNumber"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.company_code_tva,
                  expression: "user.company_code_tva",
                },
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.hasErrors("company_code_tva") },
              attrs: {
                type: "text",
                id: "company_code_tva",
                name: "company_code_tva",
                disabled: !_vm.editable,
              },
              domProps: { value: _vm.user.company_code_tva },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "company_code_tva", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.hasErrors("company_code_tva")
              ? _c("div", {
                  staticClass: "invalid-feedback",
                  attrs: { role: "alert" },
                  domProps: {
                    innerHTML: _vm._s(_vm.joinErrors("company_code_tva")),
                  },
                })
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("label", { staticStyle: { width: "100%" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-5 pt-2" }, [
            _vm._v(_vm._s(_vm.$tl("labels.form.address")) + " *"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.company_address,
                  expression: "user.company_address",
                },
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.hasErrors("company_address") },
              attrs: {
                type: "text",
                id: "company_address",
                name: "company_address",
                disabled: !_vm.editable,
                required: "",
              },
              domProps: { value: _vm.user.company_address },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "company_address", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.hasErrors("company_address")
              ? _c("div", {
                  staticClass: "invalid-feedback",
                  attrs: { role: "alert" },
                  domProps: {
                    innerHTML: _vm._s(_vm.joinErrors("company_address")),
                  },
                })
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("label", { staticStyle: { width: "100%" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-5 pt-2" }, [
            _vm._v(_vm._s(_vm.$tl("labels.form.postalCode")) + " *"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.company_zipcode,
                  expression: "user.company_zipcode",
                },
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.hasErrors("company_zipcode") },
              attrs: {
                type: "text",
                id: "company_zipcode",
                name: "company_zipcode",
                disabled: !_vm.editable,
                required: "",
              },
              domProps: { value: _vm.user.company_zipcode },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "company_zipcode", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.hasErrors("company_zipcode")
              ? _c("div", {
                  staticClass: "invalid-feedback",
                  attrs: { role: "alert" },
                  domProps: {
                    innerHTML: _vm._s(_vm.joinErrors("company_zipcode")),
                  },
                })
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("label", { staticStyle: { width: "100%" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-5 pt-2" }, [
            _vm._v(_vm._s(_vm.$tl("labels.form.city")) + " *"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.company_city,
                  expression: "user.company_city",
                },
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.hasErrors("company_city") },
              attrs: {
                type: "text",
                id: "company_city",
                name: "company_city",
                disabled: !_vm.editable,
                required: "",
              },
              domProps: { value: _vm.user.company_city },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "company_city", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.hasErrors("company_city")
              ? _c("div", {
                  staticClass: "invalid-feedback",
                  attrs: { role: "alert" },
                  domProps: {
                    innerHTML: _vm._s(_vm.joinErrors("company_city")),
                  },
                })
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("label", { staticStyle: { width: "100%" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-5 pt-2" }, [
            _vm._v(_vm._s(_vm.$tl("labels.form.country")) + " *"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.company_country,
                  expression: "user.company_country",
                },
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.hasErrors("company_country") },
              attrs: {
                type: "text",
                id: "company_country",
                name: "company_country",
                disabled: !_vm.editable,
                required: "",
              },
              domProps: { value: _vm.user.company_country },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.user, "company_country", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm.hasErrors("company_country")
              ? _c("div", {
                  staticClass: "invalid-feedback",
                  attrs: { role: "alert" },
                  domProps: {
                    innerHTML: _vm._s(_vm.joinErrors("company_country")),
                  },
                })
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.showButtons
        ? _c(
            "button",
            {
              staticClass: "btn btn-success btn-circle",
              attrs: { type: "submit" },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$tl("labels.form.actions.save")) +
                  "\n        "
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }