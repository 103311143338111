var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "row bg-light text-center text-uppercase nav-campaign" },
        [
          _c(
            "div",
            {
              class:
                _vm.step === 1
                  ? "nav-campaign-item col-sm-4 pt-3 pb-3 active"
                  : "nav-campaign-item col-sm-4 pt-3 pb-3",
            },
            [_vm._v("\n            1 - Composez votre message\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class:
                _vm.step === 2
                  ? "nav-campaign-item col-sm-4 pt-3 pb-3 active"
                  : "nav-campaign-item col-sm-4 pt-3 pb-3",
            },
            [_vm._v("\n            2 - Choisissez votre cible\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class:
                _vm.step === 3
                  ? "nav-campaign-item col-sm-4 pt-3 pb-3 active"
                  : "nav-campaign-item col-sm-4 pt-3 pb-3",
            },
            [_vm._v("\n            3 - Diffuser votre campagne\n        ")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.loading ? _c("LoaderComponent") : _vm._e(),
      _vm._v(" "),
      _c(
        "keep-alive",
        [
          _vm.campaign && !_vm.loading
            ? _c(_vm.currentStepComponent, {
                ref: "currentStepComponent",
                tag: "component",
                attrs: {
                  errors: _vm.errors,
                  step: _vm.step,
                  campaign: _vm.campaign,
                  clients: _vm.clients,
                },
                on: {
                  "set-errors": function ($event) {
                    _vm.errors = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showInternationalSmsCostModal
        ? _c("InternationalSmsCostModal", {
            on: {
              close: function ($event) {
                _vm.showInternationalSmsCostModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }