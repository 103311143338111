<template>
    <modal @close="closeFromModal">
        <template v-if="isLoading">
            <LoaderComponent slot="body" />
            <div slot="footer"></div>
        </template>
        <template v-else-if="isWarningModal">
            <div slot="header">
                <h5 class="d-flex align-items-center">
                    <feather type="alert-circle" class="mr-2" />
                    {{ $tl("infos.subscriptions.modal.title") }}
                </h5>
            </div>
            <div slot="body">
                {{ $tl("infos.subscriptions.modal.body") }}
            </div>
            <div slot="footer">
                <button type="button" @click.prevent="close" class="btn btn-primary btn-circle btn-sm mr-2">
                    {{ $tl("labels.form.actions.cancel") }}
                </button>
                <button type="button" class="btn btn-success btn-circle btn-sm" @click="askForSubscription">
                    {{ $tl("labels.form.actions.confirm") }}
                </button>
            </div>
        </template>
        <template v-else>
            <div slot="header">
                <h5 class="d-flex align-items-center">
                    <feather type="check-circle" class="mr-2" />
                    {{ $tl("success.subscriptions.modalConfirm.title") }}
                </h5>
            </div>
            <div slot="body">{{ $tl("success.subscriptions.modalConfirm.body") }}</div>
            <div slot="footer" class="mx-auto">
                <button type="button" @click.prevent="close" class="btn btn-success btn-circle btn-sm mr-2">
                    {{ $tl("labels.form.actions.ok") }}
                </button>
            </div>
        </template>
    </modal>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";

export default {
    props: {
        module: {
            type: String,
            required: true,
        },
        restaurant: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            canDisplayModal: false,
            isLoading: false,
            isWarningModal: true,
            currentModule: null,
        };
    },
    components: {
        LoaderComponent,
    },
    methods: {
        closeFromModal($event) {
            if (this.isLoading === true) {
                return;
            }

            if ($event.target && $event.target.classList.contains("modal-wrapper")) {
                this.close();
            }
        },
        close() {
            this.isWarningModal = true;
            this.$emit("close");
        },
        askForSubscription() {
            this.isLoading = true;
            let url = `/api/askForSubscription/${this.module}`;

            if (this.restaurant) {
                url += `?restaurant_id=${this.restaurant.id}`;
            }

            this.httpGet(url)
                .then((response) => {
                    if (response !== false) {
                        this.isWarningModal = false;
                    }
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
