<template>
    <div>
        <tab-bar direction="horizontal" side="top" :entries="tabBarEntries" />
        <router-view />
    </div>
</template>

<script>
import TabBar from "../../components/tabBar/TabBar";

export default {
    data() {
        return {
            restaurant_id: this.$route.params.restaurant_id,
        };
    },
    computed: {
        tabBarEntries() {
            return [
                {
                    to: {
                        name: "gift_vouchers.restaurants.configuration.configuration",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Configuration",
                    exactUrlMatching: true,
                },
                {
                    to: {
                        name: "gift_vouchers.restaurants.configuration.integration",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Intégration",
                    exactUrlMatching: false,
                },
                {
                    to: {
                        name: "gift_vouchers.restaurants.configuration.cgv",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "CGV",
                    exactUrlMatching: false,
                },
                {
                    to: {
                        name: "gift_vouchers.restaurants.configuration.inputParams",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Paramètres de saisie",
                    exactUrlMatching: false,
                },
            ];
        },
    },
    props: {},
    components: {
        TabBar,
    },
    created() {},
};
</script>
