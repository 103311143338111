<template>
    <div class="pb-5 pt-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Intégration</h5>
                    <a target="_blank" :href="widgetLink">
                        <button
                            :disabled="
                                apiKey === null ||
                                !restaurant ||
                                (!restaurant.stripe_client_id && (!restaurant.payplug_public_key || !restaurant.payplug_secret_key))
                            "
                            class="btn btn-sm btn-success btn-sm btn-circle mr-1"
                            type="button">
                            Voir mon widget
                        </button>
                    </a>
                </div>
            </div>
        </div>
        <overlay-layout
            :display-overlay="!restaurant || (!restaurant.stripe_client_id && (!restaurant.payplug_public_key || !restaurant.payplug_secret_key))"
            class="m-1">
            <template v-slot:content>
                <div class="row m-0 mb-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row mb-3">
                                <div class="col-12">
                                    <strong>Informations concernant l'intégration sur votre site web</strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <p>
                                        Pour intégrer le widget vente en ligne sur votre site web, vous devez transmettre les informations ci-dessous
                                        à votre développeur.
                                    </p>
                                    <p>
                                        Nous vous recommandons d'intégrer le module directement dans votre site internet en suivant les étapes
                                        ci-dessous. Pour un affichage optimal, il est recommandé d'utiliser la largeur totale de l'écran en limitant
                                        les marges gauche et droite.
                                    </p>
                                    <p>
                                        Vous pouvez toutefois intégrer le widget sous forme de bouton qui redirige vers une page externe
                                        (copier-coller le lien accessible depuis le bouton "voir mon widget").
                                    </p>
                                    <p class="mb-0 text-danger">
                                        Merci de ne pas intégrer le widget avec un iframe car la hauteur ne peut pas s'adapter automatiquement au
                                        contenu et cela impacte l'expérience utilisateur.
                                    </p>
                                    <br />
                                    <button
                                        :disabled="!has_right_to_update_config"
                                        @click="showSendWidgetDocToDevModal = true"
                                        class="btn btn-sm btn-success btn-sm btn-circle"
                                        type="button">
                                        Transmettre les instructions d'intégration
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mb-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-12">
                                    <strong>Intégration du widget sur votre site web</strong>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-5">
                                    <label>Lien court</label>
                                </div>
                                <div class="col-md-7">
                                    <div class="d-flex align-items-center">
                                        <span class="mr-2">{{ appUrl }}/vente-en-ligne/</span>
                                        <input
                                            type="text"
                                            class="form-control"
                                            style="width: 45%"
                                            maxlength="40"
                                            v-model="shortlink"
                                            :disabled="!has_right_to_update_config" />
                                        <button
                                            :disabled="!has_right_to_update_config || isSavingShortlink"
                                            :class="{ disabled: !has_right_to_update_config || isSavingShortlink }"
                                            @click="saveShortLink"
                                            class="btn btn-sm btn-success btn-sm btn-circle ml-2"
                                            type="button">
                                            Enregistrer
                                        </button>
                                    </div>
                                    <div>
                                        <small class="text-muted"
                                            >Le lien court ne peut contenir que des lettres minuscules, des tirets et des underscores (_). Max : 40
                                            caractères</small
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="alert alert-danger col-md-12 pt-1" v-if="apiKeyError">
                                    {{ apiKeyError }}
                                </div>
                                <div class="col-md-5">
                                    <label>Clé API</label>
                                </div>
                                <div class="col-md-7">
                                    <div class="mb-3 code-source" v-if="apiKey">
                                        {{ apiKey }}
                                    </div>
                                    <button
                                        :disabled="!has_right_to_update_config"
                                        @click="askApiKey"
                                        class="btn btn-sm btn-success btn-sm btn-circle"
                                        type="button">
                                        <span v-if="!apiKey">Générer une clé API</span><span v-else>Générer une nouvelle clé API</span></button
                                    ><br />
                                    <small v-if="apiKey" class="text-danger"
                                        >L'ancienne clé sera révoquée. Pensez à changer la valeur sur votre widget.</small
                                    >
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-md-5 pt-1" v-if="apiKey !== null">
                                    <label>Méthode d'intégration</label>
                                </div>
                                <div class="col-md-7 pt-1" v-if="apiKey !== null">
                                    <p>
                                        <strong>Étape 1 :</strong> Créez un conteneur (balise 'div') à l'endroit où vous souhaitez afficher le widget
                                    </p>

                                    <div class="code-source mb-3">&lt;div id="click-and-collect"&gt;&lt;/div&gt;</div>

                                    <p><strong>Étape 2 :</strong> Ajoutez le code suivant à votre site</p>

                                    <div class="code-source mb-3">
                                        <span class="d-block" v-for="(js, index) in jsToInclude" :key="index">{{ js }}</span>
                                    </div>

                                    <p><strong>Étape 3 :</strong> Initialisez le widget à l'aide du script ci-dessous</p>

                                    <div class="code-source mb-3">{{ classFunctionName }}('click-and-collect', '{{ apiKey }}');</div>

                                    <p class="mb-0">
                                        <strong>Étape 4 (optionnelle) :</strong> Ajouter des paramètres à l'initialisation du widget<br />
                                        Des paramètres optionnels sont disponibles afin de modifier le comportement du widget :
                                    </p>
                                    <ol>
                                        <li class="list-style-inherit">
                                            "type" : Permet de bloquer le widget en vente à emporter, livraison par coursier ou expédition
                                            <ul>
                                                <li class="list-style-inherit">"collect": Vente à emporter</li>
                                                <li class="list-style-inherit">"delivery": Livraison par coursier</li>
                                                <li class="list-style-inherit">"dispatch": Expédition</li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <div class="code-source">{{ classFunctionName }}('click-and-collect', '{{ apiKey }}', { type: 'delivery' });</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FacebookIntegration class="m-0 mb-3" :module="MODULE_TYPE_CLICK_AND_COLLECT.value" />

                <InstagramIntegration class="m-0 mb-3" :module="MODULE_TYPE_CLICK_AND_COLLECT.value" />

                <GoogleLinkIntegration class="m-0 mb-3" />
            </template>
            <template v-slot:overlay>
                <div class="is-white d-flex justify-content-center align-items-center">
                    <div class="text-center">
                        <p>Veuillez connecter votre compte stripe pour accéder à la configuration du widget.</p>
                        <router-link v-if="isYservices" :to="{ name: 'restaurants' }" class="btn btn-circle btn-success">
                            Ajouter un compte Stripe
                        </router-link>
                        <router-link v-else :to="{ name: 'restaurants.edit', params: { restaurant_id } }" class="btn btn-circle btn-success">
                            Ajouter un compte Stripe
                        </router-link>
                    </div>
                </div>
            </template>
        </overlay-layout>
        <send-click-and-collect-widget-doc-to-dev-modal
            ref="sendClickAndCollectWidgetDocToDevModal"
            v-if="showSendWidgetDocToDevModal"
            @close="showSendWidgetDocToDevModal = false"
            :restaurant_id="restaurant_id" />
    </div>
</template>

<script>
import sendClickAndCollectWidgetDocToDevModal from "../../../components/Modals/clickAndCollect/sendClickAndCollectWidgetDocToDevModal";
import OverlayLayout from "../../../components/layouts/OverlayLayout";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";
import FacebookIntegration from "../../../components/widgets/FacebookIntegration.vue";
import InstagramIntegration from "../../../components/widgets/InstagramIntegration.vue";
import GoogleLinkIntegration from "../../../components/widgets/GoogleLinkIntegration.vue";

export default {
    data() {
        return {
            appUrl: APP_URL,
            loading: false,
            errors: null,
            hasKey: false,
            apiKey: null,
            apiKeyError: null,
            showSendWidgetDocToDevModal: false,
            has_dispatch_option: false,
            shortlink: "",
            isSavingShortlink: false,
        };
    },
    mixins: [ModuleTypesEnum],
    computed: {
        widgetLink() {
            if (
                !this.apiKey ||
                !this.restaurant ||
                (!this.restaurant.stripe_client_id && (!this.restaurant.payplug_public_key || !this.restaurant.payplug_secret_key))
            )
                return "#";
            if (this.shortlink) return `/vente-en-ligne/${this.shortlink}`;
            return `/click_and_collect/widget/public/${this.apiKey}/page`;
        },
        jsToInclude() {
            const jsToInclude = [`<isplease src="${APP_URL}/click_and_collect/js/widget.js"></isplease>`.replace(/isplease/g, "script")];

            if (this.has_dispatch_option) {
                jsToInclude.push(`<isplease src="https://embed.sendcloud.sc/spp/1.0.0/api.min.js"></isplease>`.replace(/isplease/g, "script"));
            }

            return jsToInclude;
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        restaurant() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id);
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_config() {
            return this.rights.includes("click_and_collect.config.update");
        },
        classFunctionName() {
            return this.isNoShow ? "window.initNoShowClickAndCollect" : "window.initYServicesClickAndCollect";
        },
    },
    created() {
        this.fetchHasApiKey();
    },
    methods: {
        fetchHasApiKey() {
            this.apiKeyError = null;

            this.$store
                .dispatch("widgets/fetchClickAndCollectApiKey", {
                    restaurant_id: this.restaurant_id,
                })
                .then((response) => {
                    this.apiKey = response.data.key;
                    this.shortlink = response.data.shortlink;
                    this.has_dispatch_option = response.data.has_dispatch_option;
                })
                .catch((error) => {
                    this.apiKeyError = this.getErrorMsgFromErrorResponse(error);
                });
        },
        askApiKey() {
            this.apiKeyError = null;

            this.$store
                .dispatch("widgets/generateClickAndCollectApiKey", {
                    restaurant_id: this.restaurant_id,
                })
                .then((response) => {
                    this.apiKey = response.data.key;
                    this.hasKey = true;
                })
                .catch((error) => {
                    this.apiKeyError = this.getErrorMsgFromErrorResponse(error);
                });
        },
        saveShortLink() {
            this.isSavingShortlink = true;

            axios
                .post(`/api/click_and_collect/${this.restaurant_id}/widget/shortlink`, {
                    shortlink: this.shortlink,
                })
                .then((response) => {
                    this.notifySuccess(response);
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => (this.isSavingShortlink = false));
        },
    },
    watch: {
        shortlink(newVal, oldVal) {
            if (!newVal || newVal.length < oldVal.length) {
                return;
            }

            if (newVal.length > 0 && !newVal.replace(oldVal, "").match(/^[a-z_\-]+$/)) {
                this.shortlink = oldVal;
            }
        },
    },
    components: {
        sendClickAndCollectWidgetDocToDevModal,
        OverlayLayout,
        FacebookIntegration,
        InstagramIntegration,
        GoogleLinkIntegration,
    },
};
</script>

<style scoped>
.list-style-inherit {
    list-style: inherit;
}

button:disabled {
    cursor: default;
}
</style>
