<template>
    <modal @close.stop="closeFromModal">
        <h5 slot="header">{{ $tl("labels.booking.menus.add", restaurantIdComputed) }}</h5>
        <div slot="body">
            <menu-form ref="form" @fully-loaded="onFormLoaded" @is-editable="isEditable = $event" :restaurant-id="restaurantIdComputed" />
        </div>
        <div slot="footer" class="d-flex">
            <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button type="button" :disabled="isLoading || !isEditable" class="btn btn-sm btn-success btn-circle ml-2" @click="save">
                {{ $tl("labels.form.actions.save") }}
            </button>
        </div>
    </modal>
</template>

<script>
import menuForm from "../../menus/menuForm.vue";

export default {
    name: "addMenuModal",
    data() {
        return {
            isLoading: true,
            isEditable: false,
        };
    },
    props: {
        restaurantId: {
            default: "",
        },
    },
    computed: {
        restaurantIdComputed() {
            return Number.parseInt(this.restaurant_id || this.$route.params.restaurant_id);
        },
    },
    methods: {
        onFormLoaded() {
            this.isLoading = false;
        },
        save() {
            this.isLoading = true;

            this.$refs.form.submit().then((success) => {
                if (success !== false) {
                    this.$emit("menu-added");
                    this.close();
                } else {
                    this.isLoading = false;
                }
            });
        },
        close() {
            this.$emit("close");
        },
        closeFromModal(e) {
            if (e && e.target && e.target.classList.contains("modal-wrapper")) {
                this.close();
            }
        },
    },
    components: {
        menuForm,
    },
};
</script>
