var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "a",
      {
        attrs: { href: "#" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            _vm.opened = !_vm.opened
          },
        },
      },
      [_vm._v(_vm._s(_vm.opened ? "Cacher les logs" : "Voir les logs"))]
    ),
    _vm._v(" "),
    _vm.opened
      ? _c(
          "ul",
          { staticClass: "mt-1" },
          _vm._l(_vm.logs, function (log) {
            return _c("li", { key: log.id }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.getActionRightLabel(log.action)) +
                  " : " +
                  _vm._s(_vm.getDisplayableUser(log)) +
                  ", le " +
                  _vm._s(_vm.displayDate(log.created_at, _vm.DATETIME_SHORT)) +
                  "\n        "
              ),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }