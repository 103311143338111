<template>
    <nav class="navbar navbar-dark fixed-top flex-md-nowrap p-0 bg-dark">
        <a href="/" style="background-color: #083e60" class="navbar-brand col-sm-12 mr-0 col-lg-2 col-md-3 navbar-brand-not-collaped">
            <img class="img-logo display-sid-min" src="/images/logos-saas/reservation.png" v-if="!isDevelopement()" />
            <img class="img-logo display-sid-min" src="/images/logos-saas/reservation.png" v-else />
        </a>

        <template>
            <div class="input-group restaurant-title restaurant-title-kitchen ml-3 p-2">
                <select class="custom-select rounded-pill pl-3" id="inputGroupSelect01" v-model="restaurant_id">
                    <option v-for="restaurant in restaurants" :key="restaurant.id" :value="restaurant.id">
                        {{ restaurant.name }}
                    </option>
                </select>
            </div>
        </template>

        <!-- profile button -->
        <div class="nav-btn mx-md-5">
            <button
                class="btn btn-sm p-0 dropdown-toggle"
                :class="{ 'text-white': user && user.role === 'kitchen' }"
                type="button"
                id="dropdownCompte"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <feather
                    type="user"
                    class="feather-22"
                    :class="{
                        'mr-0': isSidebarCollapsed,
                    }"></feather>
            </button>
            <div class="dropdown-menu text-right dropdown-menu-right shadow" aria-labelledby="dropdownCompte">
                <h6 class="dropdown-header">{{ user.firstname }} {{ user.lastname }}</h6>
                <div class="dropdown-divider"></div>
                <!-- <a class="dropdown-item" href="#">Profil</a> -->
                <router-link :to="{ name: 'account' }" class="dropdown-item"> {{ $tl("labels.routes.account") }} </router-link>
                <router-link :to="{ name: 'sms' }" class="dropdown-item" v-if="has_right_to_update_sms"> {{ $tl("labels.routes.sms") }} </router-link>

                <router-link v-if="isNoShow && has_right_to_read_subscription" :to="{ name: 'subscription' }" class="dropdown-item">
                    {{ $tl("labels.routes.subscription") }}
                </router-link>

                <div class="dropdown-divider"></div>
                <a v-if="isNoShow" class="open-intercom dropdown-item" href="#"> {{ $tl("labels.routes.help") }} </a>
                <a v-else-if="isYservices" class="dropdown-item" href="#" @click.prevent="openHelpscoutBeacon"> {{ $tl("labels.routes.help") }} </a>

                <div class="dropdown-divider"></div>
                <a style="color: #f75b61" class="dropdown-item" href="#" @click.prevent="logout">
                    {{ $tl("labels.routes.disconnect") }}
                    <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="css-i6dzq1 feather">
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                        <polyline points="16 17 21 12 16 7"></polyline>
                        <line x1="21" y1="12" x2="9" y2="12"></line>
                    </svg>
                </a>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "Topbar",
    props: {
        user: {
            required: true,
        },
        restaurants: {
            required: true,
        },
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read_subscription() {
            return this.rights.includes("default.subscription.read");
        },
        has_right_to_update_sms() {
            return this.rights.includes("default.sms.update");
        },
        restaurant_id: {
            get() {
                return this.$route.params.restaurant_id;
            },
            set: function (newValue) {
                this.$router.push({
                    to: "booking.restaurants.reservationsCuisine",
                    params: {
                        restaurant_id: newValue,
                    },
                });
            },
        },
    },
    methods: {
        openHelpscoutBeacon() {
            if (typeof Beacon !== "undefined") {
                Beacon("open");
            }
        },
        logout() {
            axios
                .post("/logout")
                .then((response) => {
                    window.location.href = response.data.redirect_url || response.headers.location || "/login";
                })
                .catch(() => {});
        },
    },
};
</script>
