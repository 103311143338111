var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.tooltipComputed,
          expression: "tooltipComputed",
        },
      ],
      class: _vm.computeClasses("label"),
    },
    [
      _c("input", {
        attrs: {
          name: _vm.inputName,
          "data-test-id": _vm.dataTestId,
          type: "radio",
          disabled: _vm.disabled,
        },
        domProps: { checked: _vm.isChecked },
        on: {
          change: function ($event) {
            _vm.valueComputed = _vm.inputValue
          },
        },
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "checkmark",
        class: { disabled: _vm.disabled },
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }