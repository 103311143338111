var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100 h-100 position-absolute" }, [
    _c(
      "div",
      {
        ref: "content",
        staticClass: "pop-sidebar left module-switcher p-3",
        class: { show: _vm.show },
      },
      [
        _c(
          "div",
          { staticClass: "h-100 d-flex flex-column justify-content-between" },
          [
            _c(
              "div",
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "shadow-none navbar-brand mr-0 col-lg-2 col-md-3 pt-2 pb-2",
                    attrs: { to: { name: "home" } },
                  },
                  [
                    !_vm.isDevelopement()
                      ? _c("img", {
                          staticClass: "img-logo display-sid-min",
                          attrs: { src: "/images/logo-lead-extend.png" },
                        })
                      : _c("img", {
                          staticClass: "img-logo display-sid-min",
                          attrs: { src: "/images/logo-lead-extend.png" },
                        }),
                  ]
                ),
                _vm._v(" "),
                _c("modules-list", {
                  attrs: { small: true, showButtons: false },
                  on: { "clicked-on-same-module": _vm.close },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-3" }, [
              _c("h5", { staticClass: "title text-uppercase" }, [
                _vm._v(_vm._s(_vm.$tl("labels.myAccount"))),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "pl-0 mt-3" },
                [
                  _vm._l(_vm.submenuEntries, function (entry) {
                    return [
                      (typeof entry.display === "undefined" ||
                        entry.display === true) &&
                      _vm.hasRights(entry.rights)
                        ? _c(
                            "li",
                            {
                              key: _vm.getEntryTitle(entry),
                              staticClass: "mb-2",
                            },
                            [
                              entry.to
                                ? _c(
                                    "router-link",
                                    { attrs: { to: entry.to } },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.getEntryTitle(entry)) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                : entry.url
                                ? [
                                    entry.onClick
                                      ? _c(
                                          "a",
                                          {
                                            class: entry.class,
                                            attrs: { href: entry.url },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return entry.onClick.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.getEntryTitle(entry)
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            class: entry.class,
                                            attrs: {
                                              href: entry.url,
                                              target: entry.target,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.getEntryTitle(entry)
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _vm.show
      ? _c(
          "div",
          {
            ref: "icon",
            staticClass:
              "navbar-brand col-sm-12 mr-0 bg-dark position-absolute overlay-brand",
            class: {
              "col-lg-2 col-md-3 navbar-brand-not-collaped":
                !_vm.isSidebarCollapsed,
              "col-lg-1 col-md-1 text-center navbar-brand-collapsed":
                _vm.isSidebarCollapsed,
            },
            style: _vm.brandStyle,
          },
          [
            _c(
              "button",
              { staticClass: "btn text-white", on: { click: _vm.close } },
              [
                _c("feather", {
                  staticClass: "w-auto h-auto",
                  attrs: { type: "grid", size: "22" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.selectedService
              ? _c("router-link", { attrs: { to: _vm.selectedService.to } }, [
                  !_vm.isDevelopement() && !_vm.isSidebarCollapsed
                    ? _c("img", {
                        staticClass: "img-logo display-sid-min",
                        attrs: { src: _vm.selectedService.icon },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isDevelopement() && !_vm.isSidebarCollapsed
                    ? _c("img", {
                        staticClass: "img-logo display-sid-min",
                        attrs: { src: _vm.selectedService.iconDev },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isSidebarCollapsed,
                        expression: "isSidebarCollapsed",
                      },
                    ],
                    staticClass: "img-logo-sid-min",
                    staticStyle: { height: "28px" },
                  }),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", {
      staticClass: "overlay-blue-dark",
      class: { "d-none": !_vm.show },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }