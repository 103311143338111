export default {
    title: "Droits",
    edit: "Personnaliser les droits",
    defaultSaas: "Général",
    labels: {
        booking: {
            booking: {
                create: "Ajouter une réservation",
                export: "Exporter les réservations",
                update: "Modifier une réservation",
                read: "Voir la liste des réservations",
            },
            notification: {
                update: "Modifier la configuration des notifications",
                read: 'Voir la page configuration "Notifications"',
            },
            seatingplan: {
                delete: "Supprimer un plan de salle",
                read: "Voir la liste des plans de salle",
                update: "Créer / Éditer un plan de salle",
            },
            payments: {
                export: "Exporter les paiements",
                read: "Voir la liste des paiements",
            },
            kitchen: {
                read: 'Voir la page "Cuisine"',
            },
            statistic: {
                read: "Voir les statistiques",
                export: "Exporter les statistiques",
            },
            menu: {
                read: "Voir la liste des menus",
                delete: "Supprimer un menu",
                create: "Ajouter un menu",
                update: "Modifier un menu",
            },
            service: {
                create: "Ajouter un service",
                delete: "Supprimer un service",
                update: "Modifier un service",
                read: "Voir la liste des services",
            },
            widget: {
                update: "Modifier la configuration générale",
                read: "Voir la page configuration générale",
            },
        },
        campaigns: {
            campaign: {
                update: "Modifier une campagne",
                read: "Voir les détails d'une campagne",
                create: "Créer une campagne",
            },
        },
        click_and_collect: {
            commands: {
                export: "Exporter les commandes",
                create: "Ajouter une commande",
                read: "Scanner et voir les détails d'une commande",
                update: "Modifier le statut d'une commande et télécharger la facture",
            },
            config: {
                read: "Voir la configuration",
                update: "Modifier la configuration",
            },
            stats: {
                read: 'Voir la page "Rapport"',
            },
            payments: {
                export: "Exporter les paiements",
                read: "Voir la liste des paiements",
            },
            products: {
                read: "Voir la liste des produits",
                delete: "Supprimer un produit, une catégorie et des options",
                create: "Ajouter un produit, une catégorie et des options",
                update: "Modifier les produits, catégories (ordre + nom) et options",
            },
        },
        default: {
            inputparams: {
                read: "Voir les paramètres de saisie",
                update: "Modifier les paramètres de saisie",
            },
            customer: {
                export: "Exporter la liste des clients",
                read: "Voir la liste des clients",
                update: "Modifier un client",
                create: "Ajouter un client",
            },
            employees: {
                delete: "Supprimer un employé",
                create: "Ajouter un employé",
                update: "Modifier un employé",
                read: "Voir la liste des employés",
            },
            sms: {
                update: "Acheter des SMS",
            },
            restaurants: {
                read: "Voir les détails d'un établissement",
                update: "Modifier un établissement",
                create: "Ajouter un établissement",
            },
            subscription: {
                update: "Modifier l'abonnement",
                read: "Voir les détails de l'abonnement",
            },
            loyalties: {
                update: "Modifier les paramétrages fidélité",
            },
            notificationlogs: {
                read: "Voir la liste des logs de notifications",
                export: "Exporter les logs de notifications",
            },
            zones: {
                read: "Voir les groupes d'établissements",
                create: "Ajouter et modifier un groupe d'établissements",
            },
        },
        gift_vouchers: {
            products: {
                create: "Ajouter un produit",
                update: "Modifier un produit",
                read: "Voir la liste des produits",
                delete: "Supprimer un produit",
            },
            sales: {
                create: "Ajouter ou importer des bons cadeaux",
                update: "Modifier un bon cadeau, envoyer les détails, télécharger la facture",
                read: "Scanner et voir les détails d'un bon cadeau",
                export: "Exporter les bons cadeaux",
            },
            payments: {
                export: "Exporter les paiements",
                read: "Voir la liste des paiements",
            },
            config: {
                read: "Voir la configuration",
                update: "Modifier la configuration",
            },
            stats: {
                read: 'Voir la page "Rapport"',
            },
        },
        website: {
            config: {
                publish: "Publier le site web",
                update: "Modifier la configuration",
                read: "Voir la configuration",
            },
            customization: {
                read: "Voir la personnalisation",
                update: "Modifier la personnalisation",
            },
            stats: {
                read: 'Voir la page "Statistiques"',
            },
        },
    },
};
