export default {
    common: {
        services: "Vous devez créer des jeux au préalable pour pouvoir modifier ce paramètre.",
    },
    booking: {
        services: {
            enableBack: "Pour réactiver un jeu, rendez-vous sur votre {dashboard}.",
            cantDelete: "Vous ne pouvez pas supprimer ce jeu car des réservations futures y sont associées",
            hasFutureReservations: "Ce jeu fait référence à plusieurs réservations à venir.",
            changeSeatingPlan: [
                "Si vous validez le changement de plan de salle sur ce jeu, les réservations futures ne seront plus liées aux places de l'ancien plan de salle, et les préférences de salle ne seront pas conservées.",
                "L'algorithme tentera de replacer vos réservations à la prochaine prise de réservation.",
                "Si toutefois des réservations ne peuvent pas être placées, la prise de réservation sera momentanément suspendue.",
                "Nous vous conseillons vivement de vérifier vos prochains jeux.",
            ],
        },
        reservations: {
            saveCauseSurbooking: "En validant cette réservation vous serez en surbooking pour ce jeu.",
        },
        seatingPlan: {
            delete: "Vous devez délier le plan de salle de tous les jeux pour le supprimer",
        },
    },
};
