var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : !_vm.website_enabled
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("Votre site web n'est pas activé"),
          ])
        : [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                  },
                  [
                    _c("h5", { staticClass: "title mt-2" }, [
                      _vm._v("Configuration du contenu"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12 mb-3" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 mb-3" }, [
                      _c("strong", [_vm._v("Éléments graphiques")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-5 mt-2" }, [
                      _c("label", [
                        _vm._v("Image de fond de la page d'accueil "),
                        _c("small", { staticClass: "text-danger" }, [
                          _vm._v("*"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-7 mb-1 mt-1" },
                      [
                        _c("ImageComponent", {
                          attrs: {
                            disabled: !_vm.has_right_to_update_customization,
                            suffixId: "home1",
                            src: _vm.website_settings.home1_img,
                            postURI: _vm.basePostURL + "home1",
                            allowedExtensions: ".jpg,.jpeg,.JPG",
                            maxSize: 10,
                            callBack: (response) => {
                              _vm.website_settings.home1_img = response.data.img
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 mb-3" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("label", { attrs: { for: "chk-history-enabled" } }, [
                        _c("strong", [_vm._v('Section "Notre Histoire"')]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-block align-middle lead-switch",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.history_enabled,
                                expression: "website_settings.history_enabled",
                              },
                            ],
                            staticClass: "switch align-self-center is-rounded",
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.website_settings.history_enabled
                              )
                                ? _vm._i(
                                    _vm.website_settings.history_enabled,
                                    null
                                  ) > -1
                                : _vm.website_settings.history_enabled,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.website_settings.history_enabled,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "history_enabled",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "history_enabled",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.website_settings,
                                    "history_enabled",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  delay: { show: 400, hide: 0 },
                                  content: "Activer / désactiver cette section",
                                },
                                expression:
                                  "{\n                                        delay: { show: 400, hide: 0 },\n                                        content: 'Activer / désactiver cette section',\n                                    }",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                _vm.website_settings.history_enabled =
                                  _vm.has_right_to_update_customization
                                    ? !_vm.website_settings.history_enabled
                                    : _vm.website_settings.history_enabled
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.website_settings.history_enabled == 1,
                          expression: "website_settings.history_enabled == 1",
                        },
                      ],
                      staticClass: "row",
                    },
                    [
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", { attrs: { for: "txt-history-title" } }, [
                          _vm._v("Titre de la section "),
                          _c("small", { staticClass: "text-danger" }, [
                            _vm._v("*"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7 mb-1" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.history_title,
                                expression: "website_settings.history_title",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              type: "text",
                              id: "txt-history-title",
                            },
                            domProps: {
                              value: _vm.website_settings.history_title,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.website_settings,
                                  "history_title",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "history_title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5 mt-2" }, [
                        _c("label", [
                          _vm._v("Image "),
                          _c("small", { staticClass: "text-danger" }, [
                            _vm._v("*"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7 mb-1 mt-1" },
                        [
                          _c("ImageComponent", {
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              src: _vm.website_settings.history_img,
                              suffixId: "history",
                              postURI: _vm.basePostURL + "history",
                              allowedExtensions: ".jpg,.jpeg,.JPG",
                              maxSize: 10,
                              callBack: (response) => {
                                _vm.website_settings.history_img =
                                  response.data.img
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", { attrs: { for: "txt-history-content" } }, [
                          _vm._v("Contenu texte "),
                          _c("small", { staticClass: "text-danger" }, [
                            _vm._v("*"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7 mb-2" },
                        [
                          _c("TextAreaComponentOld", {
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              minNbChar: 500,
                              maxNbChar: 800,
                              txtAreaId: "txt-history-content",
                              txtAreaObj: _vm.website_settings,
                              txtAreaObjKey: "history_content",
                              txtAreaRows: "6",
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "history_content",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5 mt-2" }, [
                        _c("label", [
                          _vm._v("Image d'illustration en plein écran "),
                          _c("small", { staticClass: "text-danger" }, [
                            _vm._v("*"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7 mt-1" },
                        [
                          _c("ImageComponent", {
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              src: _vm.website_settings.home2_img,
                              suffixId: "home2",
                              postURI: _vm.basePostURL + "home2",
                              allowedExtensions: ".jpg,.jpeg,.JPG",
                              maxSize: 10,
                              callBack: (response) => {
                                _vm.website_settings.home2_img =
                                  response.data.img
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 mb-3" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("label", { attrs: { for: "chk-menu-enabled" } }, [
                        _c("strong", [_vm._v('Section "Notre Offre"')]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-block align-middle lead-switch",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.menu_enabled,
                                expression: "website_settings.menu_enabled",
                              },
                            ],
                            staticClass: "switch align-self-center is-rounded",
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.website_settings.menu_enabled
                              )
                                ? _vm._i(
                                    _vm.website_settings.menu_enabled,
                                    null
                                  ) > -1
                                : _vm.website_settings.menu_enabled,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.website_settings.menu_enabled,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "menu_enabled",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "menu_enabled",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.website_settings,
                                    "menu_enabled",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  delay: { show: 400, hide: 0 },
                                  content: "Activer / désactiver cette section",
                                },
                                expression:
                                  "{\n                                        delay: { show: 400, hide: 0 },\n                                        content: 'Activer / désactiver cette section',\n                                    }",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                _vm.website_settings.menu_enabled =
                                  _vm.has_right_to_update_customization
                                    ? !_vm.website_settings.menu_enabled
                                    : _vm.website_settings.menu_enabled
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.website_settings.menu_enabled == 1,
                          expression: "website_settings.menu_enabled == 1",
                        },
                      ],
                      staticClass: "row",
                    },
                    [
                      _c("div", { staticClass: "col-md-5 pt-2" }, [
                        _c("label", { attrs: { for: "txt-menu-title" } }, [
                          _vm._v("Titre de la section "),
                          _c("small", { staticClass: "text-danger" }, [
                            _vm._v("*"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7 mb-3" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.menu_title,
                                expression: "website_settings.menu_title",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              type: "text",
                              id: "txt-menu-title",
                            },
                            domProps: {
                              value: _vm.website_settings.menu_title,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.website_settings,
                                  "menu_title",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "menu_title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", [_vm._v("Nombre de d'offres à afficher")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-7 rd-menus-nb-container mb-2 radio",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "container-box d-inline-block",
                              staticStyle: { width: "initial" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.website_settings.menus_nb,
                                    expression: "website_settings.menus_nb",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "rd-menus-nb",
                                  disabled:
                                    !_vm.has_right_to_update_customization,
                                },
                                domProps: {
                                  value: 3,
                                  checked: _vm._q(
                                    _vm.website_settings.menus_nb,
                                    3
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.website_settings,
                                      "menus_nb",
                                      3
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "checkmark",
                                class: {
                                  disabled:
                                    !_vm.has_right_to_update_customization,
                                },
                              }),
                              _vm._v("3\n                            "),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "container-box d-inline-block",
                              staticStyle: { width: "initial" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.website_settings.menus_nb,
                                    expression: "website_settings.menus_nb",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "rd-menus-n b",
                                  disabled:
                                    !_vm.has_right_to_update_customization,
                                },
                                domProps: {
                                  value: 6,
                                  checked: _vm._q(
                                    _vm.website_settings.menus_nb,
                                    6
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.website_settings,
                                      "menus_nb",
                                      6
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "checkmark",
                                class: {
                                  disabled:
                                    !_vm.has_right_to_update_customization,
                                },
                              }),
                              _vm._v("6\n                            "),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-12 mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4" },
                          [
                            _c(
                              "div",
                              { staticClass: "row" },
                              _vm._l(
                                _vm.website_settings.menus,
                                function (menu) {
                                  return _c("WebsiteMenuComponent", {
                                    key: menu.number,
                                    attrs: {
                                      "restaurant-id": _vm.restaurant_id,
                                      disabled:
                                        !_vm.has_right_to_update_customization,
                                      menu: menu,
                                      menus_nb: _vm.website_settings.menus_nb,
                                      errors: _vm.errors,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", [_vm._v("Offre PDF n°1")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7 mb-2" },
                        [
                          _c("FileUploaderComponent", {
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              src: _vm.website_settings.menu_pdf1,
                              suffixId: "fullmenu1",
                              postURI: _vm.baseURIPdf(1),
                              deleteURI: _vm.baseURIPdf(1),
                              callBackPost: (response) => {
                                _vm.website_settings.menu_pdf1 =
                                  response.data.file_name
                              },
                              callBackDelete: () => {
                                _vm.website_settings.menu_pdf1 = ""
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.website_settings.menu_pdf1 != "",
                              expression: "website_settings.menu_pdf1 != ''",
                            },
                          ],
                          staticClass: "col-md-5",
                        },
                        [
                          _c(
                            "label",
                            { attrs: { for: "txt-menupdf1-title" } },
                            [
                              _vm._v("Texte sur le bouton "),
                              _c("small", { staticClass: "text-danger" }, [
                                _vm._v("*"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.website_settings.menu_pdf1 != "",
                              expression: "website_settings.menu_pdf1 != ''",
                            },
                          ],
                          staticClass: "col-md-7 mb-1",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.menu_pdf1_title,
                                expression: "website_settings.menu_pdf1_title",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              type: "text",
                              id: "txt-menupdf1-title",
                            },
                            domProps: {
                              value: _vm.website_settings.menu_pdf1_title,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.website_settings,
                                  "menu_pdf1_title",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "menu_pdf1_title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5 mt-2" }, [
                        _c("label", [_vm._v("Offre PDF n°2")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7 mt-2 mb-2" },
                        [
                          _c("FileUploaderComponent", {
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              src: _vm.website_settings.menu_pdf2,
                              suffixId: "fullmenu2",
                              postURI: _vm.baseURIPdf(2),
                              deleteURI: _vm.baseURIPdf(2),
                              callBackPost: (response) => {
                                _vm.website_settings.menu_pdf2 =
                                  response.data.file_name
                              },
                              callBackDelete: () => {
                                _vm.website_settings.menu_pdf2 = ""
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.website_settings.menu_pdf2 != "",
                              expression: "website_settings.menu_pdf2 != ''",
                            },
                          ],
                          staticClass: "col-md-5",
                        },
                        [
                          _c(
                            "label",
                            { attrs: { for: "txt-menupdf2-title" } },
                            [
                              _vm._v("Texte sur le bouton "),
                              _c("small", { staticClass: "text-danger" }, [
                                _vm._v("*"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.website_settings.menu_pdf2 != "",
                              expression: "website_settings.menu_pdf2 != ''",
                            },
                          ],
                          staticClass: "col-md-7 mb-1",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.menu_pdf2_title,
                                expression: "website_settings.menu_pdf2_title",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              type: "text",
                              id: "txt-menupdf2-title",
                            },
                            domProps: {
                              value: _vm.website_settings.menu_pdf2_title,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.website_settings,
                                  "menu_pdf2_title",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "menu_pdf2_title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5 mt-2" }, [
                        _c("label", [_vm._v("Offre PDF n°3")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7 mt-2" },
                        [
                          _c("FileUploaderComponent", {
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              src: _vm.website_settings.menu_pdf3,
                              suffixId: "fullmenu3",
                              postURI: _vm.baseURIPdf(3),
                              deleteURI: _vm.baseURIPdf(3),
                              callBackPost: (response) => {
                                _vm.website_settings.menu_pdf3 =
                                  response.data.file_name
                              },
                              callBackDelete: () => {
                                _vm.website_settings.menu_pdf3 = ""
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.website_settings.menu_pdf3 != "",
                              expression: "website_settings.menu_pdf3 != ''",
                            },
                          ],
                          staticClass: "col-md-5",
                        },
                        [
                          _c(
                            "label",
                            { attrs: { for: "txt-menupdf3-title" } },
                            [
                              _vm._v("Texte sur le bouton "),
                              _c("small", { staticClass: "text-danger" }, [
                                _vm._v("*"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.website_settings.menu_pdf3 != "",
                              expression: "website_settings.menu_pdf3 != ''",
                            },
                          ],
                          staticClass: "col-md-7 mb-1",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.menu_pdf3_title,
                                expression: "website_settings.menu_pdf3_title",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              type: "text",
                              id: "txt-menupdf3-title",
                            },
                            domProps: {
                              value: _vm.website_settings.menu_pdf3_title,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.website_settings,
                                  "menu_pdf3_title",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "menu_pdf3_title",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 mb-3" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("label", { attrs: { for: "chk-gallery-enabled" } }, [
                        _c("strong", [_vm._v('Section "Galerie"')]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-block align-middle lead-switch",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.gallery_enabled,
                                expression: "website_settings.gallery_enabled",
                              },
                            ],
                            staticClass: "switch align-self-center is-rounded",
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.website_settings.gallery_enabled
                              )
                                ? _vm._i(
                                    _vm.website_settings.gallery_enabled,
                                    null
                                  ) > -1
                                : _vm.website_settings.gallery_enabled,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.website_settings.gallery_enabled,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "gallery_enabled",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "gallery_enabled",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.website_settings,
                                    "gallery_enabled",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  delay: { show: 400, hide: 0 },
                                  content: "Activer / désactiver cette section",
                                },
                                expression:
                                  "{\n                                        delay: { show: 400, hide: 0 },\n                                        content: 'Activer / désactiver cette section',\n                                    }",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                _vm.website_settings.gallery_enabled =
                                  _vm.has_right_to_update_customization
                                    ? !_vm.website_settings.gallery_enabled
                                    : _vm.website_settings.gallery_enabled
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.website_settings.gallery_enabled,
                          expression: "website_settings.gallery_enabled",
                        },
                      ],
                    },
                    _vm._l([1, 2, 3, 4, 5], function (currentNb) {
                      return _c("GalleryFormComponent", {
                        key: currentNb,
                        staticClass: "mb-1",
                        attrs: {
                          disabled: !_vm.has_right_to_update_customization,
                          basePostURL: _vm.basePostURL,
                          src: _vm.website_settings[`gallery${currentNb}_img`],
                          nb: currentNb,
                        },
                      })
                    }),
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 mb-3" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("label", { attrs: { for: "chk-contact-enabled" } }, [
                        _c("strong", [_vm._v('Section "Contact"')]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-block align-middle lead-switch",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.contact_enabled,
                                expression: "website_settings.contact_enabled",
                              },
                            ],
                            staticClass: "switch align-self-center is-rounded",
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.website_settings.contact_enabled
                              )
                                ? _vm._i(
                                    _vm.website_settings.contact_enabled,
                                    null
                                  ) > -1
                                : _vm.website_settings.contact_enabled,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.website_settings.contact_enabled,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "contact_enabled",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.website_settings,
                                        "contact_enabled",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.website_settings,
                                    "contact_enabled",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  delay: { show: 400, hide: 0 },
                                  content: "Activer / désactiver cette section",
                                },
                                expression:
                                  "{\n                                        delay: { show: 400, hide: 0 },\n                                        content: 'Activer / désactiver cette section',\n                                    }",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                _vm.website_settings.contact_enabled =
                                  _vm.has_right_to_update_customization
                                    ? !_vm.website_settings.contact_enabled
                                    : _vm.website_settings.contact_enabled
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.website_settings.contact_enabled == 1,
                          expression: "website_settings.contact_enabled == 1",
                        },
                      ],
                      staticClass: "row",
                    },
                    [
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", { attrs: { for: "txt-contact-title" } }, [
                          _vm._v("Titre de la section "),
                          _c("small", { staticClass: "text-danger" }, [
                            _vm._v("*"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7 mb-1" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.website_settings.contact_title,
                                expression: "website_settings.contact_title",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              type: "text",
                              id: "txt-contact-title",
                            },
                            domProps: {
                              value: _vm.website_settings.contact_title,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.website_settings,
                                  "contact_title",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "contact_title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5 mt-2" }, [
                        _c("label", [
                          _vm._v("Image "),
                          _c("small", { staticClass: "text-danger" }, [
                            _vm._v("*"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7 mb-1 mt-1" },
                        [
                          _c("ImageComponent", {
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              src: _vm.website_settings.contact_img,
                              suffixId: "contact",
                              postURI: _vm.basePostURL + "contact",
                              allowedExtensions: ".jpg,.jpeg,.JPG",
                              maxSize: 10,
                              callBack: (response) => {
                                _vm.website_settings.contact_img =
                                  response.data.img
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", { attrs: { for: "txt-contact-content" } }, [
                          _vm._v("Contenu texte "),
                          _c("small", { staticClass: "text-danger" }, [
                            _vm._v("*"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("TextAreaComponentOld", {
                            attrs: {
                              disabled: !_vm.has_right_to_update_customization,
                              minNbChar: 250,
                              maxNbChar: 500,
                              txtAreaId: "txt-contact-content",
                              txtAreaObj: _vm.website_settings,
                              txtAreaObjKey: "contact_content",
                              txtAreaRows: "6",
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "contact_content",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5 mt-2" }, [
                        _vm._v("Masquer l'adresse de l'établissement"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-7 mt-2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-inline-block align-middle lead-switch",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.website_settings.show_address,
                                  expression: "website_settings.show_address",
                                },
                              ],
                              staticClass:
                                "switch align-self-center is-rounded",
                              attrs: {
                                disabled:
                                  !_vm.has_right_to_update_customization,
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.website_settings.show_address
                                )
                                  ? _vm._i(
                                      _vm.website_settings.show_address,
                                      null
                                    ) > -1
                                  : _vm.website_settings.show_address,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.website_settings.show_address,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.website_settings,
                                          "show_address",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.website_settings,
                                          "show_address",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.website_settings,
                                      "show_address",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    delay: { show: 400, hide: 0 },
                                    content: "Afficher / masquer l'addresse",
                                  },
                                  expression:
                                    "{\n                                        delay: { show: 400, hide: 0 },\n                                        content: 'Afficher / masquer l\\'addresse',\n                                    }",
                                },
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.$set(
                                    _vm.website_settings,
                                    "show_address",
                                    _vm.has_right_to_update_customization
                                      ? !_vm.website_settings.show_address
                                      : _vm.website_settings.show_address
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5 mt-2" }, [
                        _vm._v("Masquer l'adresse email de l'établissement"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-7 mt-2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-inline-block align-middle lead-switch",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.website_settings.hide_email,
                                  expression: "website_settings.hide_email",
                                },
                              ],
                              staticClass:
                                "switch align-self-center is-rounded",
                              attrs: {
                                disabled:
                                  !_vm.has_right_to_update_customization,
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.website_settings.hide_email
                                )
                                  ? _vm._i(
                                      _vm.website_settings.hide_email,
                                      null
                                    ) > -1
                                  : _vm.website_settings.hide_email,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.website_settings.hide_email,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.website_settings,
                                          "hide_email",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.website_settings,
                                          "hide_email",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.website_settings,
                                      "hide_email",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    delay: { show: 400, hide: 0 },
                                    content: "Afficher / masquer l'email",
                                  },
                                  expression:
                                    "{\n                                        delay: { show: 400, hide: 0 },\n                                        content: 'Afficher / masquer l\\'email',\n                                    }",
                                },
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.$set(
                                    _vm.website_settings,
                                    "hide_email",
                                    _vm.has_right_to_update_customization
                                      ? !_vm.website_settings.hide_email
                                      : _vm.website_settings.hide_email
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5 mt-2" }, [
                        _vm._v(
                          "Masquer le numéro de téléphone de l'établissement"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-7 mt-2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-inline-block align-middle lead-switch",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.website_settings.hide_tel,
                                  expression: "website_settings.hide_tel",
                                },
                              ],
                              staticClass:
                                "switch align-self-center is-rounded",
                              attrs: {
                                disabled:
                                  !_vm.has_right_to_update_customization,
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.website_settings.hide_tel
                                )
                                  ? _vm._i(
                                      _vm.website_settings.hide_tel,
                                      null
                                    ) > -1
                                  : _vm.website_settings.hide_tel,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.website_settings.hide_tel,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.website_settings,
                                          "hide_tel",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.website_settings,
                                          "hide_tel",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.website_settings,
                                      "hide_tel",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    delay: { show: 400, hide: 0 },
                                    content: "Afficher / masquer le téléphone",
                                  },
                                  expression:
                                    "{\n                                        delay: { show: 400, hide: 0 },\n                                        content: 'Afficher / masquer le téléphone',\n                                    }",
                                },
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.$set(
                                    _vm.website_settings,
                                    "hide_tel",
                                    _vm.has_right_to_update_customization
                                      ? !_vm.website_settings.hide_tel
                                      : _vm.website_settings.hide_tel
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltip(
                          "Fonctionnalité actuellement bloquée par Instagram. En attente d'une solution alternative."
                        ),
                        expression:
                          "getTooltip('Fonctionnalité actuellement bloquée par Instagram. En attente d\\'une solution alternative.')",
                      },
                    ],
                    staticClass:
                      "border-light b-radius-20 p-4 feed-instagram disabled",
                  },
                  [
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-md-5" }, [
                        _c(
                          "label",
                          { attrs: { for: "chk-instagram-gallery-enabled" } },
                          [_c("strong", [_vm._v('Section "Feed Instagram"')])]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-7" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-inline-block align-middle lead-switch",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.website_settings.instagram_enabled,
                                  expression:
                                    "website_settings.instagram_enabled",
                                },
                              ],
                              staticClass:
                                "switch align-self-center is-rounded",
                              attrs: { disabled: true, type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.website_settings.instagram_enabled
                                )
                                  ? _vm._i(
                                      _vm.website_settings.instagram_enabled,
                                      null
                                    ) > -1
                                  : _vm.website_settings.instagram_enabled,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.website_settings.instagram_enabled,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.website_settings,
                                          "instagram_enabled",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.website_settings,
                                          "instagram_enabled",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.website_settings,
                                      "instagram_enabled",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label"),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.website_settings.instagram_enabled,
                            expression: "website_settings.instagram_enabled",
                          },
                        ],
                        staticClass: "row",
                      },
                      [
                        _c("div", { staticClass: "col-md-5" }, [
                          _c(
                            "label",
                            {
                              staticClass: "mb-0",
                              attrs: { for: "txt-instagram-username" },
                            },
                            [
                              _vm._v(
                                "\n                                Identifiant du compte Instagram "
                              ),
                              _c("small", { staticClass: "text-danger" }, [
                                _vm._v("*"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "small",
                            {
                              staticClass: "form-text text-warning",
                              attrs: { id: "help-instagram-username" },
                            },
                            [
                              _vm._v(
                                "\n                                Afin de pouvoir utiliser cette fonctionnalité, votre compte Instagram doit être paramétré en public.\n                            "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-7" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.website_settings.instagram_username,
                                  expression:
                                    "website_settings.instagram_username",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                disabled: true,
                                type: "text",
                                id: "txt-instagram-username",
                                "aria-describedby": "help-instagram-username",
                              },
                              domProps: {
                                value: _vm.website_settings.instagram_username,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.website_settings,
                                    "instagram_username",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("ShowErrors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors,
                                errorKey: "instagram_username",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0 mt-3" }, [
              _c("div", { staticClass: "col-12 d-inline-block" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success btn-circle mr-1",
                    attrs: {
                      type: "button",
                      disabled: !_vm.has_right_to_update_customization,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.postForm()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Enregistrer\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    '\n                    Pour rendre vos modifications visibles sur votre site internet, pensez à "'
                  ),
                  _c("strong", [_vm._v("Enregistrer et publier")]),
                  _vm._v(
                    '" en cliquant\n                    sur le bouton dans la navigation.\n                '
                  ),
                ]),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }