var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-5" },
    [
      _vm.loading
        ? _c("LoaderComponent")
        : [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-0 border-bottom cc-board",
              },
              [
                _c("h2", { staticClass: "h5" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-sm btn-outline-secondary float-left radius-btn-square",
                      staticStyle: { width: "29px", "padding-left": "5px" },
                      on: {
                        click: function ($event) {
                          _vm.offset -= 1
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass:
                            "feather feather-chevron-left sc-dnqmqq jxshSx",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "24",
                            height: "24",
                            viewBox: "0 0 24 24",
                            fill: "none",
                            stroke: "currentColor",
                            "stroke-width": "2",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "aria-hidden": "true",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: { points: "15 18 9 12 15 6" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isDay
                    ? _c(
                        "span",
                        { staticClass: "ml-1 mr-1" },
                        [
                          _c("datepicker", {
                            staticClass: "date-resa-cal float-left mt-1",
                            attrs: {
                              tabindex: "1",
                              "monday-first": true,
                              language: _vm.fr,
                              format: _vm.weekDateFormatter,
                            },
                            model: {
                              value: _vm.pickerDate,
                              callback: function ($$v) {
                                _vm.pickerDate = $$v
                              },
                              expression: "pickerDate",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isDay
                    ? _c(
                        "span",
                        { staticClass: "ml-1 mr-1" },
                        [
                          _c("datepicker", {
                            staticClass: "date-resa-cal float-left mt-1",
                            attrs: {
                              tabindex: "1",
                              "monday-first": true,
                              language: _vm.fr,
                            },
                            model: {
                              value: _vm.pickerDate,
                              callback: function ($$v) {
                                _vm.pickerDate = $$v
                              },
                              expression: "pickerDate",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-sm btn-outline-secondary float-left radius-btn-square",
                      staticStyle: { width: "29px", "padding-left": "6px" },
                      on: {
                        click: function ($event) {
                          _vm.offset += 1
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass:
                            "feather feather-chevron-right sc-dnqmqq jxshSx",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "24",
                            height: "24",
                            viewBox: "0 0 24 24",
                            fill: "none",
                            stroke: "currentColor",
                            "stroke-width": "2",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "aria-hidden": "true",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: { points: "9 18 15 12 9 6" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isDay
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-sm btn-outline-secondary btn-circle",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.offset = 0
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Cette semaine\n                "
                          ),
                        ]
                      )
                    : _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-sm btn-outline-secondary btn-circle",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.offset = 0
                            },
                          },
                        },
                        [_vm._v("Aujourd'hui")]
                      ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-sm btn-outline-secondary btn-circle mr-1",
                      staticStyle: { "margin-top": "-8px" },
                      attrs: { disabled: !_vm.has_right_to_export_commands },
                      on: {
                        click: function ($event) {
                          return _vm.exportBoard()
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "feather feather-upload",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "24",
                            height: "24",
                            viewBox: "0 0 24 24",
                            fill: "none",
                            stroke: "currentColor",
                            "stroke-width": "2",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4",
                            },
                          }),
                          _vm._v(" "),
                          _c("polyline", {
                            attrs: { points: "17 8 12 3 7 8" },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: { x1: "12", y1: "3", x2: "12", y2: "15" },
                          }),
                        ]
                      ),
                      _vm._v(
                        "\n                    Export Excel\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "btn-group",
                      staticStyle: { "margin-top": "-8px" },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-outline-secondary btn-sm btn-circle",
                          class: { active: _vm.isDay == false },
                          staticStyle: {
                            "border-radius": "50px 0 0 50px !important",
                          },
                          on: {
                            click: function ($event) {
                              _vm.isDay = false
                              _vm.offset = 0
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Vue semaine\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-outline-secondary btn-sm btn-circle",
                          class: { active: _vm.isDay == true },
                          staticStyle: {
                            "border-radius": "0 50px 50px 0 !important",
                          },
                          on: {
                            click: function ($event) {
                              _vm.isDay = true
                              _vm.offset = 0
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Vue jour\n                    "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "ejs-tooltip",
              {
                ref: "boardStockTooltip",
                attrs: {
                  opensOn: "Custom",
                  content: _vm.tooltipContent,
                  afterOpen: () => {
                    _vm.eventHub.$emit("set-main-focus")
                  },
                  target: ".cc-board-stock-for-day",
                },
              },
              [
                _c(
                  "table",
                  {
                    staticClass: "table table-sm table-striped border-bottom",
                    attrs: { tabindex: "-1" },
                  },
                  [
                    _c(
                      "thead",
                      {
                        staticClass: "border-bottom",
                        attrs: { tabindex: "-1" },
                      },
                      [
                        _c(
                          "tr",
                          { attrs: { tabindex: "-1" } },
                          [
                            _c("th", { attrs: { tabindex: "-1" } }),
                            _vm._v(" "),
                            !_vm.isDay
                              ? _vm._l(_vm.days, function (dayLabel, indexDay) {
                                  return _c(
                                    "th",
                                    {
                                      key: indexDay,
                                      staticClass: "text-center",
                                      class: {
                                        "board-current-day":
                                          _vm.offset === 0 &&
                                          indexDay === _vm.currentDay,
                                      },
                                      attrs: { tabindex: "-1" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(dayLabel) +
                                          " - " +
                                          _vm._s(
                                            _vm.formatDate(_vm.dates[indexDay])
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                })
                              : _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    class: {
                                      "board-current-day": _vm.offset === 0,
                                    },
                                    staticStyle: { width: "50%" },
                                    attrs: { tabindex: "-1" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.formatDate(_vm.range.from)) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                          ],
                          2
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      [
                        _vm._l(_vm.categories, function (category) {
                          return [
                            category.id in _vm.products
                              ? _c(
                                  "tr",
                                  {
                                    key: category.id,
                                    staticClass: "toggle-cat",
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleCategory(category.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("td", [
                                      _c(
                                        "strong",
                                        { staticClass: "text-success" },
                                        [_vm._v(_vm._s(category.name))]
                                      ),
                                      _vm._v(" "),
                                      _vm.openCategories.includes(category.id)
                                        ? _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "feather feather-chevron-down sc-dnqmqq jxshSx",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "24",
                                                height: "24",
                                                viewBox: "0 0 24 24",
                                                fill: "none",
                                                stroke: "currentColor",
                                                "stroke-width": "2",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                                "aria-hidden": "true",
                                              },
                                            },
                                            [
                                              _c("polyline", {
                                                attrs: {
                                                  points: "6 9 12 15 18 9",
                                                },
                                              }),
                                            ]
                                          )
                                        : _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "feather feather-chevron-right sc-dnqmqq jxshSx",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "24",
                                                height: "24",
                                                viewBox: "0 0 24 24",
                                                fill: "none",
                                                stroke: "currentColor",
                                                "stroke-width": "2",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                                "aria-hidden": "true",
                                              },
                                            },
                                            [
                                              _c("polyline", {
                                                attrs: {
                                                  points: "9 18 15 12 9 6",
                                                },
                                              }),
                                            ]
                                          ),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.days,
                                      function (dayLabel, indexDay) {
                                        return [
                                          indexDay === 0 || !_vm.isDay
                                            ? _c("td", {
                                                key: indexDay,
                                                class: {
                                                  "board-current-day":
                                                    _vm.offset === 0 &&
                                                    indexDay === _vm.currentDay,
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(
                              _vm.products[category.id],
                              function (product) {
                                return [
                                  _vm.openCategories.includes(category.id) &&
                                  !_vm.isDay
                                    ? _c(
                                        "tr",
                                        {
                                          key:
                                            category.id + "-" + product.info.id,
                                        },
                                        [
                                          _c("td", { staticClass: "pl-3" }, [
                                            product.info.name.length < 30 ||
                                            _vm.isDay
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(product.info.name)
                                                  ),
                                                ])
                                              : product.info.name.length >= 30
                                              ? _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: {
                                                          delay: {
                                                            show: 400,
                                                            hide: 0,
                                                          },
                                                          content:
                                                            product.info.name,
                                                        },
                                                        expression:
                                                          "{ delay: { show: 400, hide: 0 }, content: product.info.name }",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        product.info.name.substring(
                                                          0,
                                                          30
                                                        ) + "..."
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            product.info.stock_type ===
                                              _vm.GENERAL_STOCK.value &&
                                            product.info.stock !== null
                                              ? _c("span", [
                                                  _vm._v(
                                                    "(Stock : " +
                                                      _vm._s(
                                                        product.info.stock
                                                      ) +
                                                      ")"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.days,
                                            function (dayLabel, indexDay) {
                                              return _c(
                                                "td",
                                                {
                                                  key: indexDay,
                                                  staticClass: "text-center",
                                                  class: {
                                                    "board-current-day":
                                                      _vm.offset === 0 &&
                                                      indexDay ===
                                                        _vm.currentDay,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "d-block w-100 cc-board-stock-for-day",
                                                      class: {
                                                        "board-current-day":
                                                          _vm.offset === 0,
                                                      },
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openTooltip(
                                                            $event,
                                                            product[indexDay]
                                                              ? product[
                                                                  indexDay
                                                                ].id
                                                              : null,
                                                            _vm.dates[indexDay]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.getProductStockHtml(
                                                              product[indexDay],
                                                              _vm.dates[
                                                                indexDay
                                                              ]
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.openCategories.includes(category.id) &&
                                  _vm.isDay
                                    ? _c(
                                        "tr",
                                        { key: category.id + "-" + product.id },
                                        [
                                          _c("td", { staticClass: "pl-3" }, [
                                            product.name.length < 30 ||
                                            _vm.isDay
                                              ? _c("span", [
                                                  _vm._v(_vm._s(product.name)),
                                                ])
                                              : product.name.length >= 30
                                              ? _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: {
                                                          content: product.name,
                                                        },
                                                        expression:
                                                          "{ content: product.name }",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        product.name.substring(
                                                          0,
                                                          30
                                                        ) + "..."
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            product.stock_type ===
                                              _vm.GENERAL_STOCK.value &&
                                            product.stock !== null
                                              ? _c("span", [
                                                  _vm._v(
                                                    "(Stock : " +
                                                      _vm._s(product.stock) +
                                                      ")"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-center",
                                              staticStyle: { width: "50%" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block w-100 cc-board-stock-for-day",
                                                  class: {
                                                    "board-current-day":
                                                      _vm.offset === 0,
                                                  },
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openTooltip(
                                                        $event,
                                                        product
                                                          ? product.id
                                                          : null,
                                                        _vm.range.from
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.getProductStockHtml(
                                                          product,
                                                          _vm.range.from
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }