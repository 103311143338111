var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid h-100" }, [
    _c("div", { staticClass: "row h-100" }, [
      _c("div", { staticClass: "col-12 h-100" }, [_c("router-view")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }