<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Conditions particulières du module {{ module.label }}</h5>
        <div slot="body">
            <LoaderComponent v-if="loading" />
            <template v-else>
                <p>
                    Pour accéder au module {{ module.label }}, vous devez accepter ses conditions particulières
                    d'utilisation.
                </p>
                <div class="row m-0 mt-2">
                    <label class="container-box w-100">
                        <input type="checkbox" v-model="accepted" />
                        <span class="checkmark"></span>
                        Je déclare avoir pris connaissance des
                        <a class="alert-link" :href="`/cgu_${module.urlValue}.pdf?v=${$__BUILD_ID__}`" target="_blank">
                            conditions particulières d'utilisation du module {{ module.label }}
                        </a>
                    </label>
                </div>
            </template>
        </div>
        <div slot="footer" class="d-flex">
            <button
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                :disabled="loading"
                @click="goBack">
                Retour
            </button>
            <button class="btn btn-success btn-sm btn-circle ml-2" :disabled="!accepted || loading" @click="acceptCgv">
                Valider
            </button>
        </div>
    </modal>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../LoaderComponent";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum";

export default {
    data: function () {
        return {
            loading: false,
            accepted: false,
        };
    },
    props: {
        module: {
            required: true,
        },
    },
    mixins: [ModuleTypesEnum],
    methods: {
        goBack() {
            this.$router.push("/");
        },
        acceptCgv() {
            this.loading = true;

            axios
                .post(`/api/accepted_cgvs/modules/${this.module.value}`)
                .then(() => {
                    this.loading = false;
                    this.close();
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            }
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>
