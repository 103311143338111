<template>
    <td>
        <inline-client
            class="d-inline-block"
            :client="row.client"
            :module-enum="MODULE_TYPE_BOOKING"
            :public-comment="row.comment"
            :private-comment="row.restaurant_comment"
            show-noshows>
            <template v-slot:icons-start>
                <feather
                    v-if="row.gift"
                    type="tag"
                    class="feather-blue"
                    :class="{ 'text-danger': !row.gv_validity.valid }"
                    v-tooltip="getTooltip(row.gift)" />
            </template>
        </inline-client>
        <span v-if="row.client === null" class="font-italic">
            {{ $tl("labels.booking.reservations.noClientLinked") }}
        </span>
    </td>
</template>
<script>
import InlineClient from "../../Default/Clients/inlineClient.vue";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";

export default {
    props: {
        row: {
            type: Object,
            required: true,
        },
    },
    mixins: [ModuleTypesEnum],
    components: {
        InlineClient,
    },
};
</script>
