var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("LoaderComponent")
        : _vm.isConfigMissing
        ? _c("div", { staticClass: "row m-0" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$tl("labels.booking.inputParams.noConfig"))),
              ]),
            ]),
          ])
        : _c("div", { staticClass: "row m-0" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "border-light b-radius-20 p-4 mb-3" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 mb-2" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              `labels.booking.inputParams.fields.chooseMandatory.${_vm.module}`
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.fields, function (field) {
                    return _c(
                      "div",
                      { key: field.name, staticClass: "row mb-2" },
                      [
                        _c("div", { staticClass: "col-md-5 col-10" }, [
                          _c("label", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.$t(`labels.form.${field.name}`)) +
                                "\n                        "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7 col-2" }, [
                          _c("label", { staticClass: "container-box" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: field.is_mandatory,
                                  expression: "field.is_mandatory",
                                },
                              ],
                              attrs: {
                                disabled: !_vm.hasRightToUpdateInputParams,
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(field.is_mandatory)
                                  ? _vm._i(field.is_mandatory, null) > -1
                                  : field.is_mandatory,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = field.is_mandatory,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          field,
                                          "is_mandatory",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          field,
                                          "is_mandatory",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(field, "is_mandatory", $$c)
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" }),
                          ]),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }