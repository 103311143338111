var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.services.length > 0
    ? _c(
        "div",
        [
          _c("div", { staticClass: "row mb-1" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("strong", [_vm._v(_vm._s(_vm.$tl("labels.clients.history")))]),
              _vm._v(" "),
              _c("small", { staticClass: "d-block text-muted" }, [
                _vm._v(
                  "(" +
                    _vm._s(
                      _vm.$tc(
                        "labels.clients.historyOn",
                        _vm.keepClientsDetailsAndHistoryFor
                      )
                    ) +
                    ")"
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            {
              staticClass: "settings-menu nav nav-tabs flex-row top mb-2",
              staticStyle: { position: "relative", top: "0" },
            },
            _vm._l(_vm.services, function (service) {
              return _c(
                "li",
                {
                  key: service.id,
                  staticClass: "nav-item",
                  on: {
                    click: function ($event) {
                      _vm.currentService = service.id
                    },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: {
                        "router-link-active": service.id === _vm.currentService,
                      },
                      staticStyle: { color: "#6c757d" },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(service.label) +
                          "\n            "
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.currentService === 0
            ? _c("BookingHistory", {
                attrs: { reservations: _vm.reservations },
                on: {
                  "show-resa": function ($event) {
                    return _vm.$emit("show-resa", $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.currentService === 1
            ? _c("ClickAndCollectHistory", {
                attrs: { cc_commands: _vm.cc_commands },
                on: {
                  "show-cc-command": function ($event) {
                    return _vm.$emit("show-cc-command", $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.currentService === 2
            ? _c("GiftVouchersHistory", {
                attrs: { gv_sales: _vm.gv_sales },
                on: {
                  "show-gv-sale": function ($event) {
                    return _vm.$emit("show-gv-sale", $event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }