<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Ajouter une commande</h5>
        <div slot="body">
            <add-click-and-collect-sale ref="add" :showSaveButton="false" />
        </div>
        <div slot="footer" class="d-flex">
            <button
                type="button"
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                @click="$emit('close')">
                Annuler
            </button>
            <button type="button" class="btn btn-sm btn-success btn-circle ml-2" @click="post">Enregistrer</button>
        </div>
    </modal>
</template>

<script>
import addClickAndCollectSale from "../../clickAndCollect/addClickAndCollectSale";

export default {
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        post() {
            this.$refs.add.postForm().then((result) => {
                if (result) {
                    this.close();
                }
            });
        },
    },
    components: {
        addClickAndCollectSale,
    },
};
</script>
