var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mb-3" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _vm.nbLoading > 0
          ? _c("loader-component")
          : _vm.stats.main
          ? _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
              _c("div", { staticClass: "row m-0" }, [
                _c("h6", { staticClass: "mt-3 col-10" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$tl("labels.booking.stats.waitingList.title"))
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-end align-items-center lead-switch col-2",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-square",
                        class: _vm.showGraph ? "btn-success" : "btn-secondary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            _vm.showGraph = !_vm.showGraph
                          },
                        },
                      },
                      [_c("feather", { attrs: { type: "pie-chart" } })],
                      1
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row m-0 click-collect-report" }, [
                _c("div", { staticClass: "col-md-6 p-1" }, [
                  _c(
                    "div",
                    { staticClass: "border-light p-3 b-radius-20 h-100" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$tl("labels.booking.stats.waitingList.nb"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pt-2" }, [
                        _c("strong", { staticClass: "value" }, [
                          !_vm.filters.hasGroupComparison
                            ? _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$tl("labels.booking.stats.periodOne")
                                    ) +
                                    " " +
                                    _vm._s(_vm.stats.main.count_waitings) +
                                    "\n                                "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$tl("labels.booking.stats.groupOne")
                                    ) +
                                    " " +
                                    _vm._s(_vm.stats.main.count_waitings) +
                                    " "
                                ),
                              ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      (_vm.stats.comparison && _vm.filters.hasComparison) ||
                      (_vm.stats.comparison && _vm.filters.hasGroupComparison)
                        ? [
                            _c(
                              "div",
                              { staticClass: "pt-2" },
                              [
                                _c("strong", { staticClass: "text-success" }, [
                                  !_vm.filters.hasGroupComparison
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.booking.stats.periodTwo"
                                              )
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.stats.comparison
                                                .count_waitings
                                            ) +
                                            "\n                                    "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.booking.stats.groupTwo"
                                              )
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.stats.comparison
                                                .count_waitings
                                            ) +
                                            " "
                                        ),
                                      ]),
                                ]),
                                _vm._v(" "),
                                !(
                                  _vm.stats.main.count_waitings == 0 &&
                                  _vm.stats.comparison.count_waitings == 0
                                )
                                  ? _c("ArrowPercentEvolutionComponent", {
                                      staticClass: "pt-2 font-weight-bold",
                                      attrs: {
                                        difference:
                                          _vm.stats.main.count_waitings -
                                          _vm.stats.comparison.count_waitings,
                                        percent: _vm.getEvolution(
                                          _vm.stats.main.count_waitings,
                                          _vm.stats.comparison.count_waitings
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6 p-1" }, [
                  _c(
                    "div",
                    { staticClass: "border-light p-3 b-radius-20 h-100" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.stats.waitingList.nbLeadToResa"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pt-2" }, [
                        _c("strong", { staticClass: "value" }, [
                          !_vm.filters.hasGroupComparison
                            ? _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$tl("labels.booking.stats.periodOne")
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.stats.main.count_waitings_accepted
                                    ) +
                                    "\n                                "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$tl("labels.booking.stats.groupOne")
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.stats.main.count_waitings_accepted
                                    ) +
                                    " "
                                ),
                              ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(
                              "(" +
                                _vm._s(
                                  _vm.getPercent(
                                    _vm.stats.main.count_waitings_accepted,
                                    _vm.stats.main.count_waitings
                                  )
                                ) +
                                "%)"
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      (_vm.stats.comparison && _vm.filters.hasComparison) ||
                      (_vm.stats.comparison && _vm.filters.hasGroupComparison)
                        ? [
                            _c(
                              "div",
                              { staticClass: "pt-2" },
                              [
                                _c(
                                  "strong",
                                  { staticClass: "text-success" },
                                  [
                                    !_vm.filters.hasGroupComparison
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.stats.periodTwo"
                                                )
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.stats.comparison
                                                  .count_waitings_accepted
                                              ) +
                                              "\n                                    "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.stats.groupTwo"
                                                )
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.stats.comparison
                                                  .count_waitings_accepted
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]),
                                    _vm._v(" "),
                                    _vm.stats.comparison
                                      .count_waitings_accepted != 0
                                      ? [
                                          _c("span", { staticClass: "ml-2" }, [
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  _vm.getPercent(
                                                    _vm.stats.comparison
                                                      .count_waitings_accepted,
                                                    _vm.stats.comparison
                                                      .count_waitings
                                                  )
                                                ) +
                                                "%)"
                                            ),
                                          ]),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                !(
                                  _vm.stats.main.count_waitings_accepted == 0 &&
                                  _vm.stats.comparison
                                    .count_waitings_accepted == 0
                                )
                                  ? _c("ArrowPercentEvolutionComponent", {
                                      staticClass: "pt-2 font-weight-bold",
                                      attrs: {
                                        difference:
                                          _vm.stats.main
                                            .count_waitings_accepted -
                                          _vm.stats.comparison
                                            .count_waitings_accepted,
                                        percent: _vm.getEvolution(
                                          _vm.stats.main
                                            .count_waitings_accepted,
                                          _vm.stats.comparison
                                            .count_waitings_accepted
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.showGraph
                ? _c(
                    "div",
                    {
                      staticClass: "row d-flex flex-column align-items-center",
                    },
                    [
                      _c("div", { staticClass: "p-3 d-flex" }, [
                        _c("strong", { staticClass: "text-success pr-1" }, [
                          _vm._v(_vm._s(_vm.labelPeriodMain)),
                        ]),
                        _vm._v(" "),
                        _vm.stats.comparison && _vm.filters.hasComparison
                          ? _c("span", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$tl("labels.filters.comparedTo")) +
                                  "\n                        "
                              ),
                              _c("strong", { staticClass: "text-success" }, [
                                _vm._v(_vm._s(_vm.labelPeriodComparison)),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("v-chart", {
                        staticClass: "chart",
                        attrs: {
                          option: {
                            title: _vm.nbWaitingResa.title,
                            series: _vm.barChartWaitingList,
                            xAxis: _vm.nbWaitingResa.xAxis,
                            yAxis: _vm.yAxisWaitingList,
                            tooltip: _vm.tooltipWaiting,
                            grid: _vm.grid,
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }