<template>
    <div>
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <div>
                        <h5 class="title mt-2">Historique des remises</h5>
                    </div>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div class="row m-0" v-else>
            <div class="col-12">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <form @submit="fetchData">
                        <div>
                            Renouvellement&nbsp;
                            <select class="custom-select d-inline-block" style="width: auto" v-model="filter.plan">
                                <option :value="null">Tous</option>
                                <option v-for="plan in ALL_STRIPE_PLAN_TYPES" :value="plan.value" :key="plan.value">
                                    {{ getStripePlanTypeLabel(plan.value) }}
                                </option>
                            </select>
                            &nbsp;Module&nbsp;
                            <select class="custom-select d-inline-block" style="width: auto" v-model="filter.module">
                                <option :value="null">Tous</option>
                                <option :value="type.value" v-for="type in ALL_MODULES_TYPES_WITH_OPTIONS" :key="type.value">
                                    {{ type.label }}
                                </option>
                            </select>
                        </div>
                        Du&nbsp;
                        <DatePicker
                            class="d-inline-block"
                            input-class="form-control"
                            @seleted="setEndDateFromFromDate"
                            v-model="filter.from_date"
                            :canBeEmpty="true"
                            :showClearButton="true" />
                        &nbsp;au&nbsp;
                        <DatePicker
                            class="d-inline-block"
                            input-class="form-control"
                            :disabledDates="disabledToDates"
                            v-model="filter.to_date"
                            :canBeEmpty="true"
                            :showClearButton="true" />
                        <button type="submit" class="btn btn-sm btn-success btn-circle ml-2">Rechercher</button>
                        <button @click="resetFilter" type="button" class="btn btn-sm btn-success btn-circle ml-2">Réinitialiser les filtres</button>
                        <button :disabled="loadingExport" @click="excelExport" type="button" class="btn btn-sm btn-success btn-circle ml-2">
                            Export Excel
                        </button>
                    </form>
                </div>
            </div>
            <div class="col-12" v-if="subscriptionDiscounts !== null">
                <SortableTable tableClass="table table-sm table-striped border-bottom" :columns="columns" :data="subscriptionDiscounts" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import StripePlanTypesEnum from "../../mixins/enums/StripePlanTypesEnum";
import SortableTable from "../SortableTable";
import LoaderComponent from "../LoaderComponent";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum";
import DatePicker from "../forms/DatePicker.vue";

const defaultFilter = {
    from_date: null,
    to_date: null,
    plan: null,
    module: null,
};

export default {
    data() {
        return {
            loading: false,
            filter: _.cloneDeep(defaultFilter),
            subscriptionDiscounts: null,
            columns: [
                {
                    name: "Établissement",
                    key: "restaurant_name",
                    sortable: true,
                    sortable_type: "string",
                },
                {
                    name: "Date de paiement",
                    key: "paid_at",
                    sortable: true,
                    sortable_type: "datetime",
                    data_format: "moment",
                },
                {
                    name: "Début de la période",
                    key: "period_start",
                    sortable: true,
                    sortable_type: "datetime",
                    data_format: "moment",
                },
                {
                    name: "Fin de la période",
                    key: "period_end",
                    sortable: true,
                    sortable_type: "datetime",
                    data_format: "moment",
                },
                {
                    name: "Montant total",
                    key: "total_amount",
                    sortable: true,
                    sortable_type: "int",
                    data_format: "price_in_cent",
                },
                {
                    name: "Montant de la remise",
                    key: "discount_amount",
                    sortable: true,
                    sortable_type: "int",
                    data_format: "price_in_cent",
                },
                {
                    name: "Engagement",
                    sortable: true,
                    sortable_type: "string",
                    sort_on: "label",
                    data_format: "custom",
                    data_format_fct: (data) => this.getStripePlanTypeLabel(data.plan),
                },
                {
                    name: "Module",
                    sortable: true,
                    sortable_type: "string",
                    sort_on: "label",
                    data_format: "custom",
                    data_format_fct: (data) => this.getModuleTypeLabel(data.module.type),
                },
            ],
        };
    },
    mixins: [StripePlanTypesEnum, ModuleTypesEnum],
    computed: {
        disabledToDates() {
            if (this.filter.from_date) {
                return {
                    to: this.filter.from_date.minus({ days: 1 }).toJSDateCustom(),
                };
            }
            return undefined;
        },
    },
    methods: {
        excelExport(e) {
            if (e) {
                e.preventDefault();
            }

            if (!confirm("L'export prendra en compte les filtres sélectionnés. Souhaitez-vous continuer ?")) return;

            this.loadingExport = true;

            axios
                .post("/api/admin/export/discounts", this.getFilterParams())
                .then((response) => {
                    this.loadingExport = false;
                    this.notifySuccess(response);
                })
                .catch((error) => {
                    this.loadingExport = false;
                    this.notifyError(error);
                });
        },
        setEndDateFromFromDate() {
            this.$nextTick(() => {
                if (this.filter.from_date && this.fitler.to_date && this.filter.from_date > this.fitler.to_date) {
                    this.to = this.from;
                }
            });
        },
        resetFilter(e) {
            if (e) {
                e.preventDefault();
            }

            this.filter = _.cloneDeep(defaultFilter);
        },
        getFilterParams(additionalParams = {}) {
            let params = {};

            if (this.filter.from_date) {
                params.from_date = this.filter.from_date.toISODate();
            }
            if (this.filter.to_date) {
                params.to_date = this.filter.to_date.toISODate();
            }
            if (this.filter.plan) {
                params.plan = this.filter.plan;
            }
            if (this.filter.module) {
                params.module = this.filter.module;
            }

            return {
                ...params,
                ...additionalParams,
            };
        },
        fetchData(e) {
            if (e) {
                e.preventDefault();
            }

            this.loading = true;

            axios
                .get("/api/admin/subscriptions/discounts", {
                    params: this.getFilterParams({
                        include: "subscription,restaurant,module",
                    }),
                })
                .then((response) => {
                    this.loading = false;
                    this.subscriptionDiscounts = response.data.data;
                })
                .catch((error) => {
                    this.loading = false;
                    this.notifyError(error);
                });
        },
    },
    components: {
        LoaderComponent,
        SortableTable,
        DatePicker,
    },
};
</script>
