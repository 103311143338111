var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-2" },
    [
      _vm.loading
        ? _c("loader-component")
        : _vm.appUpdate !== null
        ? _c("div", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between mt-1" },
                  [
                    _c("div", [
                      _c("h5", { staticClass: "title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("labels.appUpdates.title", {
                              date: _vm.displayDate(
                                _vm.appUpdate.update_date,
                                _vm.DATE_MED
                              ),
                            })
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("labels.appUpdates.lastUpdate", {
                              date: _vm.displayDate(
                                _vm.appUpdate.updated_at,
                                _vm.DATE_MED
                              ),
                            })
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      _vm._l(_vm.appUpdate.modules, function (tmpModule) {
                        return _c(
                          "span",
                          {
                            key: tmpModule,
                            staticClass: "badge badge-success mr-1",
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.getModuleTypeLabel(tmpModule)) +
                                "\n                        "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "sepa my-2" }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", {
                staticClass: "col-12 ck-content",
                staticStyle: { "overflow-x": "auto" },
                domProps: { innerHTML: _vm._s(_vm.appUpdate.content) },
              }),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }