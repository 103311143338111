<template>
    <div :id="`wrapper-${id}`">
        <table class="table table-sm table-striped border-bottom table-tr-clickable">
            <thead class="border-bottom">
                <tr>
                    <th v-if="columns.restaurant">Établissement</th>
                    <th v-if="columns.number">N°</th>
                    <th v-if="columns.client">Client</th>
                    <th v-if="columns.status.show">Statut</th>
                    <th v-if="columns.date">Date</th>
                    <th v-if="columns.slot">
                        <span v-if="!type">Retrait / livraison</span>
                        <span v-else-if="type === COLLECT_TYPE_COLLECT.value">Retrait</span>
                        <span v-else-if="type === COLLECT_TYPE_DELIVERY.value || type === COLLECT_TYPE_DISPATCH.value">Livraison</span>
                    </th>
                    <th v-if="columns.delivery_address">Adresse</th>
                    <th v-if="columns.sendcloud_status">Sendcloud</th>
                    <th v-if="columns.dispatch_fees">Frais de livraison</th>
                    <th v-if="columns.price">Montant</th>
                    <th v-if="columns.products">Détails</th>
                    <th v-if="showActionsColumn">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="command in commands_"
                    v-on:click="showCommand(command, $event)"
                    :key="command.id"
                    :class="{
                        updated: command.updated && command.updated === true,
                        new: command.new && command.new === true,
                    }">
                    <td v-if="columns.restaurant">{{ findRestaurantById(command.restaurant_id).name }}</td>
                    <td v-if="columns.number">
                        {{ command.numero }}
                        <cc-command-payment-status-icon
                            v-if="typeof columns.show_payment_status === 'undefined' || columns.show_payment_status"
                            :ref="`paymentStatusIcon${command.id}`"
                            :command="command" />
                    </td>
                    <td v-if="columns.client">
                        <inline-client
                            v-if="command.client !== null"
                            :client="command.client"
                            :module-enum="MODULE_TYPE_CLICK_AND_COLLECT"
                            :public-comment="command.comment"
                            :private-comment="command.private_comment">
                        </inline-client>
                    </td>
                    <td v-if="columns.status.show">
                        <ClickAndCollectStatusTag
                            :readonly="columns.status.readonly"
                            :editable_status="columns.status.editable_status"
                            :restaurant_id="command.restaurant_id"
                            :command="command"
                            @status-updated="$emit('status-updated', $event)"
                            @open-pay-manually-modal="openPayManuallyModal" />
                    </td>
                    <td v-if="columns.date">
                        {{ formatDate(command.reservation_date) }}
                    </td>
                    <td v-if="columns.slot">
                        <feather
                            v-if="command.collect_type === COLLECT_TYPE_DELIVERY.value && !type"
                            type="truck"
                            class="mr-1 text-success"
                            v-tooltip="{
                                delay: { show: 400, hide: 0 },
                                content: command.delivery_address_displayable,
                            }" />
                        <span v-if="command.collect_type === COLLECT_TYPE_COLLECT.value">{{ formatHour(command.slot.hour) }}</span>
                        <span v-else-if="command.collect_type === COLLECT_TYPE_DELIVERY.value"
                            >{{ formatHour(command.slot.hour_start) }} - {{ formatHour(command.slot.hour_end) }}</span
                        >
                        <span v-else-if="command.collect_type === COLLECT_TYPE_DISPATCH.value">{{
                            getDispatchTypeLabel(command.dispatch_type)
                        }}</span>
                    </td>
                    <td v-if="columns.delivery_address">
                        {{ command.delivery_address_displayable }}
                    </td>
                    <td v-if="columns.sendcloud_status">
                        <SendcloudStatusTag v-if="command.collect_type === COLLECT_TYPE_DISPATCH.value" :command="command" />
                        <span v-else>--</span>
                    </td>
                    <td v-if="columns.dispatch_fees">
                        <span v-if="command.dispatch_fees === null">--</span>
                        <span v-else>
                            <feather
                                v-if="command.dispatch_fees_paid_by_client"
                                type="dollar-sign"
                                class="text-success"
                                v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'Payés par le client' }" />
                            <feather
                                v-else
                                type="dollar-sign"
                                class="text-danger"
                                v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'À votre charge' }" />
                            {{ formatCurrency(command.dispatch_fees / 100) }}
                        </span>
                    </td>
                    <td v-if="columns.price">{{ formatCurrency(command.total_amount / 100) }}</td>
                    <td v-if="columns.products">
                        <ul class="pl-0 mb-0">
                            <template v-for="(commandProduct, index) in command.command_products.data">
                                <li v-if="commandProduct.quantity > 0" :key="`${command.id}-${index}`">
                                    {{ commandProduct.quantity }} x {{ commandProduct.product.name }}
                                    <small class="text-warning" v-if="commandProduct.comment && !$_.isEmpty(commandProduct.comment)"
                                        >({{ commandProduct.comment }})</small
                                    >
                                    <ul class="mb-0" v-if="commandProduct.command_product_option.data.length > 0">
                                        <li
                                            v-for="(commandProductOption, index2) in commandProduct.command_product_option.data"
                                            :key="`${command.id}-${index}-${index2}`">
                                            {{ commandProductOption.product_option.cc_product.name }}
                                            <span v-if="commandProductOption.quantity > 1"> (x{{ commandProductOption.quantity }})</span>
                                        </li>
                                    </ul>
                                </li>
                            </template>
                        </ul>
                    </td>
                    <td v-if="showActionsColumn">
                        <button
                            v-if="columns.actions.download_label"
                            class="btn btn-sm btn-outline-secondary btn-square"
                            @click="downloadLabel(command)"
                            :disabled="!canDownloadLabel(command)"
                            v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'Télécharger l\'étiquette d\'envoi' }">
                            <feather type="download" />
                        </button>
                        <a
                            v-if="columns.actions.print_order_form"
                            class="btn btn-sm btn-outline-secondary btn-square"
                            :href="`/api/click_and_collect/${command.restaurant_id}/commands/${command.id}/printOrderForm`"
                            target="_blank"
                            v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'Imprimer le bon de commande' }">
                            <feather type="printer" />
                        </a>
                        <MarkDelivered v-if="columns.actions.mark_delivered" :command="command" @command-updated="$emit('command-updated', $event)" />
                    </td>
                </tr>
            </tbody>
            <show-command-modal
                :hide_client_btn="columns.actions.hide_client_btn"
                :command_id="modals.params.selected_command_id"
                :restaurant_id="modals.params.selected_restaurant_id"
                @close="modals.displayShowCommandModal = false"
                v-if="modals.displayShowCommandModal" />
        </table>
    </div>
</template>

<script>
import ClickAndCollectStatusTag from "./StatusTag.vue";
import SendcloudStatusTag from "./SendcloudStatusTag.vue";
import ShowCommandModal from "../../Modals/clickAndCollect/ShowClickAndCollectCommand.vue";
import moment from "moment";
import CcCommandPaymentStatusIcon from "./Payment/PaymentStatusIcon.vue";
import CollectTypesEnum from "../../../mixins/enums/click_and_collect/CollectTypesEnum.js";
import DispatchTypesEnum from "../../../mixins/enums/click_and_collect/DispatchTypesEnum.js";
import CommandStatusEnum from "../../../mixins/enums/click_and_collect/CommandStatusEnum.js";
import SendcloudStatusEnum from "../../../mixins/enums/click_and_collect/SendcloudStatusEnum.js";
import MarkDelivered from "./MarkDelivered.vue";
import inlineClient from "../../Default/Clients/inlineClient.vue";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";

export default {
    name: "ClickAndCollectCommandList",
    data() {
        return {
            modals: {
                params: {
                    selected_command_id: null,
                    selected_restaurant_id: null,
                },
                displayShowCommandModal: false,
            },
            scrollSet: false,
            current_page: null,
        };
    },
    mixins: [CollectTypesEnum, DispatchTypesEnum, CommandStatusEnum, SendcloudStatusEnum, ModuleTypesEnum],
    props: {
        type: {
            default: null,
        },
        commands: {
            required: true,
            type: Object,
        },
        restaurant_id: {
            default: null,
        },
        columns: {
            default: () => ({
                number: true,
                client: true,
                status: {
                    show: true,
                    readonly: false,
                },
                date: true,
                slot: true,
                price: true,
                products: true,
                actions: {
                    edit: true,
                    send: true,
                    clickable: true,
                    print_order_form: true,
                },
            }),
        },
    },
    computed: {
        id() {
            return this._uid;
        },
        commands_() {
            if (!this.type) return this.commands.data;
            return this.commands.data.filter((command) => command.collect_type === this.type);
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_commands() {
            return this.rights.includes("click_and_collect.commands.update");
        },
        showActionsColumn() {
            return Object.values(this.columns?.actions).some((a) => a);
        },
    },
    components: {
        SendcloudStatusTag,
        CcCommandPaymentStatusIcon,
        ClickAndCollectStatusTag,
        ShowCommandModal,
        MarkDelivered,
        inlineClient,
    },
    methods: {
        openPayManuallyModal(e) {
            if (this.$refs[`paymentStatusIcon${e}`] && this.$refs[`paymentStatusIcon${e}`][0].length)
                this.$refs[`paymentStatusIcon${e}`][0].payManually();
        },
        findRestaurantById(restaurant_id) {
            return this.$store.getters["restaurants/findRestaurantById"](restaurant_id);
        },
        canDownloadLabel(command) {
            if (!this.has_right_to_update_commands) return false;
            if (!command.collect_type === this.COLLECT_TYPE_DISPATCH.value) return false;
            if (!this.VALID_COMMAND_STATUS_FOR_LABEL.includes(command.status)) return false;
            if (!this.VALID_SENDCLOUD_STATUS_FOR_LABEL.includes(command.sendcloud_status)) return false;
            return true;
        },
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY");
        },
        formatHour(hour) {
            return hour.split(":").splice(0, 2).join("h");
        },
        showCommand(command, event = null) {
            if (this.columns.actions.clickable) {
                const excludedTags = ["BUTTON", "A", "SVG", "LINE", "PATH"];

                if (!event || !event.target || !excludedTags.includes(event.target.tagName.toUpperCase())) {
                    this.modals.params.selected_command_id = command.id;
                    this.modals.params.selected_restaurant_id = command.restaurant_id;
                    this.$nextTick(() => {
                        this.modals.displayShowCommandModal = true;
                    });
                }
            }
        },
        downloadLabel(command) {
            if (this.canDownloadLabel(command))
                window.open(`/api/click_and_collect/${command.restaurant_id}/commands/${command.id}/dispatch_label`, "_blank");
        },
    },
    watch: {
        commands: {
            immediate: true,
            handler(newVal, oldVal) {
                if (
                    newVal &&
                    newVal.meta &&
                    newVal.meta.pagination &&
                    newVal.meta.pagination.current_page &&
                    newVal.meta.pagination.total_pages &&
                    newVal.meta.pagination.current_page < newVal.meta.pagination.total_pages
                ) {
                    this.current_page = newVal.meta.pagination.current_page;
                    if (!this.scrollSet) {
                        this.$nextTick(() => {
                            var modal = document.getElementById(`wrapper-${this.id}`);
                            modal.onscroll = () => {
                                let bottomOfWindow = modal.scrollTop + modal.clientHeight >= modal.scrollHeight - 20; // 20 de padding pour la fluidité
                                if (bottomOfWindow) {
                                    modal.onscroll = null; // Remove listener
                                    this.scrollSet = false;
                                    this.$emit("next", this.current_page + 1);
                                }
                            };
                        });
                    }
                }
            },
        },
        "$route.query.cc_command_id": {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.modals.params.selected_command_id = newVal;
                    this.modals.params.selected_restaurant_id = this.$route.params.restaurant_id || this.$route.query.restaurant_id;
                    this.modals.displayShowCommandModal = true;
                }
            },
        },
    },
};
</script>
