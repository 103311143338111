<template>
    <div class="nav-btn-3 mx-md-5 main-notification">
        <button class="btn btn-sm" type="button" id="ShowNotification" @click="$emit('open')">
            <feather type="bell" class="feather-22 feather-top-bar"></feather>
            <span v-if="count > 99" class="badge badge-danger" style="top: -4px">+99</span>
            <span v-else class="badge badge-danger" style="top: -4px">{{ count > 0 ? count : "" }}</span>
        </button>

        <div
            ref="rootElement"
            :class="{ 'notifications-div-close': !showNotifications, 'notifications-div-open': showNotifications }"
            tabindex="0"
            class="notifications-div pb-5"
            @close="closeFromNotif"
            @blur="close">
            <div class="mb-3">
                <button class="main-notifications-close" v-on:click="close">
                    <feather type="x" />
                </button>
                <div style="margin-top: -24px; margin-right: 10px" class="text-right">
                    <strong>{{ $tl("labels.notifications.title") }}</strong>
                </div>
            </div>
            <div ref="scrollableDiv" class="w-100 h-100 pb-5" id="show-notifications-container-scroll">
                <notification-card
                    v-for="(notification, index) in notifications"
                    :key="index"
                    :notification="notification"
                    :index="index"
                    @read="markAsRead"
                    @close="close" />
            </div>

            <div class="notification-delete text-center">
                <button type="button" class="btn btn-sm btn-danger btn-circle" @click="markAllAsRead($event)" @mousedown="$event.preventDefault()">
                    {{ $tl("labels.notifications.deleteAll") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import NotificationCard from "./NotificationCard";
import NotificationRingtone from "../../../assets/notificationRingtone.mp3";

export default {
    data() {
        return {
            notifications: [],
            currentPage: 1,
            hasNextPage: false,
            count: 0,
            isLoadingNotification: false,
            socketChannel: null,
            ringtone: new Audio(NotificationRingtone),
        };
    },
    props: {
        showNotifications: {
            default: false,
        },
        module_type: {
            required: true,
            type: String,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        enableNotificationRingtone() {
            return this.$store.getters["users/getEnableNotificationRingtone"];
        },
        userId() {
            return this.$store.getters["users/id"];
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromNotif: function (e) {
            if (e && e.target && !e.target.classList.contains("notifications-div")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        focus() {
            this.$refs["rootElement"].focus();
        },
        loadNotifications(reset) {
            if (reset) {
                this.currentPage = 1;
                this.isLoadingNotification = true;
            } else this.currentPage++;

            var url = `/api/restaurants`;

            if (this.restaurant_id) {
                url = `${url}/${this.restaurant_id}`;
            }

            url = `${url}/notifications/${this.module_type}`;
            axios
                .get(`${url}?page=${this.currentPage}`)
                .then((response) => {
                    this.notifications = reset ? response.data.data : this.notifications.concat(response.data.data);
                    this.hasNextPage = response.data.last_page > this.currentPage;
                    this.isLoadingNotification = false;
                    var showNotifContainer = document.getElementById("show-notifications-container-scroll");
                    if (this.hasNextPage) {
                        showNotifContainer.onscroll = () => {
                            let bottomOfWindow =
                                showNotifContainer.scrollTop + showNotifContainer.clientHeight >= showNotifContainer.scrollHeight - 40; // 20 de padding pour la fluidité
                            if (bottomOfWindow) {
                                showNotifContainer.onscroll = null; // Remove listener
                                this.loadNotifications(false);
                            }
                        };
                    }
                    this.$nextTick().then(() => {
                        if (reset) showNotifContainer.scrollTop = 0;
                    });
                })
                .catch(() => {
                    this.isLoadingNotification = false;
                });
        },
        markAsRead({ id, index }) {
            var url = `/api/restaurants`;

            if (this.restaurant_id) {
                url = `${url}/${this.restaurant_id}`;
            }

            url = `${url}/notifications/${this.module_type}`;
            axios
                .post(`${url}/${id}/read`)
                .then(() => {
                    this.notifications.splice(index, 1);
                    this.getNotificationsCount();
                })
                .catch(() => {});
        },
        markAllAsRead(event) {
            var url = `/api/restaurants`;

            if (this.restaurant_id) {
                url = `${url}/${this.restaurant_id}`;
            }

            axios
                .post(`${url}/notifications/${this.module_type}/read`)
                .then(() => {
                    this.$set(this, "notifications", []);
                    this.loadNotifications(true);
                    this.getNotificationsCount();
                })
                .catch(() => {});
        },
        getNotificationsCount() {
            var url = `/api/restaurants`;

            if (this.restaurant_id) {
                url = `${url}/${this.restaurant_id}`;
            }

            axios
                .get(`${url}/notifications/${this.module_type}/count`)
                .then((response) => {
                    this.$set(this, "count", response.data.total);
                })
                .catch(() => {});
        },
        registerNotificationCountSocket() {
            var channelToListen = `App.${this.module_type}.user.${this.userId}.`;

            if (this.restaurant_id) {
                channelToListen += `restaurant.${this.restaurant_id}.`;
            }

            channelToListen += `notifications`;

            Echo.private(channelToListen).listen(".count", this.onCountUpdated);

            this.socketChannel = channelToListen;
        },
        unregisterNotificationCountSocket() {
            if (this.socketChannel) {
                Echo.leave(this.socketChannel);
                this.socketChannel = null;
            }
        },
        onCountUpdated(e) {
            if (this.count < e.count && this.enableNotificationRingtone) {
                try {
                    const promise = this.ringtone.play();

                    if (promise instanceof Promise) {
                        promise.catch(() => console.error("Play method has been disabled"));
                    }
                } catch (e) {
                    console.error("Play method has been disabled");
                }
            }

            this.$set(this, "count", e.count);
        },
    },
    mounted() {
        this.loadNotifications(true);
        this.registerNotificationCountSocket();
        this.getNotificationsCount();
    },
    beforeDestroy() {
        this.unregisterNotificationCountSocket();
    },
    watch: {
        showNotifications: {
            immediate: true,
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal && newVal === true) {
                    this.loadNotifications(true);
                    this.focus();
                }
            },
        },
        restaurant_id() {
            this.unregisterNotificationCountSocket();
            this.loadNotifications(true);
            this.count = 0;
            this.registerNotificationCountSocket();
            this.getNotificationsCount();
        },
        module_type() {
            this.unregisterNotificationCountSocket();
            this.registerNotificationCountSocket();
        },
        userId() {
            this.unregisterNotificationCountSocket();
            this.registerNotificationCountSocket();
        },
    },
    components: {
        NotificationCard,
    },
};
</script>

<style scoped>
#show-notifications-container-scroll {
    overflow-y: auto;
}
</style>
