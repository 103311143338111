export default {
    common: {
        irreversibleAction: "Attention, cette action est irréversible. Souhaitez-vous continuer ?",
        deleteImg: "Êtes vous sûr de vouloir supprimer cette image ?",
        deleteFile: "Êtes vous sûr de vouloir supprimer ce fichier ?",
        unsavedChanges: "Vous avez effectué des modifications que vous n'avez pas sauvegardées. Êtes vous sûr de vouloir quitter cette page ?",
    },
    subscriptions: {
        reactivate:
            "La licence ne sera pas supprimée, et vous serez automatiquement débité à la date de renouvellement. Êtes-vous sûr de vouloir continuer ?",
        cancel: "Attention, la licence sera automatiquement supprimée un jour avant la date de renouvellement. Êtes-vous sûr de vouloir continuer ?",
        cancelNow: "Attention, la licence sera supprimée immédiatement. Êtes-vous sûr de vouloir continuer ?",
    },
    widget: {
        genApiKey: "Êtes vous sûr de vouloir regénérer votre clé d'API ?",
    },
    employees: {
        delete: "Êtes-vous sûr de vouloir supprimer cet employé ?",
    },
    clients: {
        resetNoShowCount: "Êtes vous sûr de vouloir réinitialiser le compte de no-shows de ce client ?",
        merge: "Les informations de la seconde fiche client seront perdues. Êtes vous sûr de vouloir continuer ?",
    },
    admin: {
        videos: {
            delete: "Êtes-vous sûr de vouloir supprimer cette vidéo ?",
        },
    },
    booking: {
        seatingPlan: {
            delete: 'Êtes vous sûr de vouloir supprimer le plan de salle "{seatingPlan}" ?',
            switchTables: "Êtes vous sûr de vouloir intervertir les tables pour les réservations de {client1} et {client2} ?",
            memos: {
                delete: "Êtes vous sûr de vouloir supprimer ce mémo ?",
            },
        },
        reservations: {
            cancel: "Êtes-vous sûr de vouloir annuler cette réservation ?",
            footprint: {
                sendReminderPayment:
                    "Un mail de rappel ou de confirmation a déjà été envoyé il y a moins de 24h êtes vous sûr de vouloir renvoyer un mail de rappel d'empreinte bancaire ?",
                sendAgain: "Êtes-vous sûr de vouloir renvoyer la demande d'empreinte bancaire ?",
            },
            payment: {
                sendAgain: "Êtes-vous sûr de vouloir renvoyer la demande de prépaiement ?",
                sendReminderPayment:
                    "Un mail de rappel ou de confirmation a déjà été envoyé il y a moins de 24h êtes vous sûr de vouloir renvoyer un mail de rappel de prépaiement ?",
            },
        },
        payments: {
            export: "L'exportation des paiements prendra en compte les filtres sélectionnés. Souhaitez vous continuer ?",
        },
        services: {
            full: "Votre établissement est complet ?",
            delete: "Êtes-vous sûr de vouloir supprimer cette plage horaire ?",
            removeFull: "Ouvrir la plage horaire aux réservations ?",
            openServiceForDate: 'La plage horaire "{service}" sera ouverte aux réservations pour le {date}. Voulez-vous continuer ?',
            openSlotForDate:
                'Le créneau de {hour} pour la plage horaire "{service}" sera ouvert aux réservations pour le {date}. Voulez-vous continuer ?',
            special: {
                delete: "Êtes-vous sûr de vouloir supprimer cette ouverture exceptionnelle ?",
            },
        },
        closures: {
            delete: "Êtes-vous sûr de vouloir supprimer cette fermeture exceptionnelle ?",
        },
        menus: {
            delete: "Êtes-vous sûr de vouloir supprimer cette prestation ?",
            options: {
                delete: "Êtes-vous sûr de vouloir supprimer cette option ?",
            },
        },
        customEvents: {
            delete: 'Êtes-vous sûr de vouloir supprimer l\'événement "{event}" ?',
        },
        waitingList: {
            confirm: {
                delete: "Êtes-vous sûr de vouloir supprimer cette attente ?",
                pending: "Un mail est en cours d'envoi. Êtes vous sûr de vouloir en renvoyer un immédiatement ?",
                alreadySend: "Un mail a déjà été envoyé. Êtes vous sûr de vouloir en renvoyer un ?",
            },
        },
    },
    giftVouchers: {
        products: {
            delete: "Êtes vous sûr de vouloir supprimer ce produit ?",
        },
    },
    reserveWithGoogle: {
        optIn: {
            clickToAccept: "En cochant cette case, vous acceptez {agreement}",
            agreement: 'les conditions particulières du service "Réserver avec Google"',
        },
        optOut: 'En cochant cette case, vous désactivez la fonctionnalité "Réserver avec Google".',
    },
    exports: {
        saveFilters: "Souhaitez-vous sauvegarder ces filtres pour la prochaine utilisation ?",
    },
};
