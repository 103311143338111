<template>
    <div class="waitinglist">
        <LoaderComponent v-if="loadingServices === true || loadingWaitings === true" />
        <div v-show="loadingServices === false && loadingWaitings === false" class="row m-0">
            <div class="col-12 p-0">
                <div class="border-light b-radius-20 p-4" id="modal-waiting-list-container">
                    <table v-if="waitinglist && waitinglist.length > 0" class="table table-sm table-striped border-bottom mb-3">
                        <thead class="border-bottom">
                            <tr>
                                <th>{{ $tl("labels.booking.waitingList.columns.client") }}</th>
                                <th>{{ $tl("labels.booking.waitingList.columns.hour") }}</th>
                                <th>{{ $tl("labels.booking.waitingList.columns.dateSendingMail") }}</th>
                                <th>{{ $tl("labels.booking.waitingList.columns.phone") }}</th>
                                <th class="none-desk">{{ $tl("labels.booking.waitingList.columns.pax") }}</th>
                                <th class="none-mobile">{{ $tl("labels.booking.waitingList.columns.adults") }}</th>
                                <th class="none-mobile">{{ $tl("labels.booking.waitingList.columns.children") }}</th>
                                <th>{{ $tl("labels.form.actions.title") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(waiting, index) in waitinglist" :key="index">
                                <template v-if="waiting.id !== false">
                                    <td>
                                        <inline-client :client="waiting.client" :module-enum="MODULE_TYPE_BOOKING" show-noshows> </inline-client>
                                    </td>
                                    <td>
                                        <span>{{ waiting.slot_id !== null ? waiting.slot.hour_start : "" }}</span>
                                    </td>
                                    <td>
                                        <span v-if="!waiting.last_mail_log">--</span>
                                        <span v-else>
                                            <span
                                                v-if="waiting.last_mail_log.permanent_failed_at || !waiting.last_mail_log.sent_at"
                                                class="text-danger">
                                                {{ $tl("labels.booking.waitingList.mailsStatus.fail") }}
                                            </span>
                                            <span
                                                v-else-if="waiting.last_mail_log.sent_at && !waiting.last_mail_log.delivered_at"
                                                class="text-warning">
                                                {{ $tl("labels.booking.waitingList.mailsStatus.inProgress") }}
                                            </span>
                                            <span v-else-if="waiting.last_mail_log.delivered_at">
                                                {{ displayDate(waiting.last_mail_log.delivered_at, DATETIME_SHORT) }}
                                                <span v-if="waiting.last_mail_log.opened_at">
                                                    {{ $tl("labels.booking.waitingList.mailsStatus.open") }}
                                                </span>
                                            </span>
                                        </span>
                                    </td>
                                    <td>
                                        <a :href="`tel:${waiting.client.tel}`">{{ waiting.client.tel }}</a>
                                    </td>
                                    <td class="none-desk">
                                        {{ waiting.nb_pers + waiting.nb_children }}
                                    </td>
                                    <td class="none-mobile">
                                        {{ waiting.nb_pers }}
                                    </td>
                                    <td class="none-mobile">
                                        {{ waiting.nb_children }}
                                    </td>
                                    <td class="d-flex justify-content-end">
                                        <button
                                            v-if="hasMenusOrOptions(waiting)"
                                            class="btn btn-sm btn-outline-secondary btn-square ml-1"
                                            @click="toggleDetailsRow(waiting, index)"
                                            v-tooltip="getTooltip($tl('labels.booking.waitingList.buttons.seeMenusAndOptions'))">
                                            <feather v-if="!displayedMenus[waiting.id]" type="eye" />
                                            <feather v-else type="eye-off" />
                                        </button>
                                        <button
                                            v-if="!waiting.isPastService && widget && widget.data.enable_mail_auto === 0"
                                            class="btn btn-sm btn-outline-secondary btn-square ml-1"
                                            @click="sendMail(waiting.client.email, waiting)"
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: $tl('labels.booking.waitingList.buttons.sendAvailabilityMail'),
                                            }">
                                            <svg
                                                viewBox="0 0 24 24"
                                                width="24"
                                                height="24"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="css-i6dzq1 feather">
                                                <line x1="22" y1="2" x2="11" y2="13"></line>
                                                <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                            </svg>
                                        </button>
                                        <button
                                            class="btn btn-sm btn-success btn-square ml-1"
                                            @click="createReservationFromWaiting(waiting)"
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: $tl('labels.booking.waitingList.buttons.addToReservationList'),
                                            }">
                                            <svg
                                                viewBox="0 0 24 24"
                                                width="24"
                                                height="24"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather css-i6dzq1">
                                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                                <line x1="5" y1="12" x2="19" y2="12"></line>
                                            </svg>
                                        </button>
                                        <button
                                            class="btn btn-sm btn-danger btn-square none-mobile ml-1"
                                            @click="deleteWaiting(waiting.id)"
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: $tl('labels.booking.waitingList.buttons.deleteToWaitingList'),
                                            }">
                                            <svg
                                                viewBox="0 0 24 24"
                                                width="24"
                                                height="24"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather css-i6dzq1">
                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                            </svg>
                                        </button>
                                    </td>
                                </template>
                                <template v-else>
                                    <td colspan="7">
                                        <div v-if="waiting.menus">
                                            <span class="font-weight-bold">{{ $tl("labels.booking.waitingList.content.menus") }}</span>
                                            <ul>
                                                <li v-for="menu in waiting.menus" :key="menu.id" class="my-1">
                                                    <span>{{ menu.nb }}</span> x <span>{{ menu.name }}</span>
                                                    <ul v-if="hasAttribute(menu, 'options')" class="text-small" style="padding-inline-start: 20px">
                                                        <li v-for="menuOption in menu.options.data" :key="menuOption.id">
                                                            <span>{{ menuOption.nb }}</span> x <span>{{ menuOption.name }}</span>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        <div v-if="waiting.general_options">
                                            <span class="font-weight-bold">{{ $tl("labels.booking.waitingList.content.generalOptions") }}</span>
                                            <ul>
                                                <li v-for="option in waiting.general_options" :key="option.id" class="my-1">
                                                    <span>{{ option.nb }}</span> x <span>{{ option.name }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else>
                        <em>{{ $tl("labels.booking.waitingList.emptyList") }}</em>
                    </div>
                </div>
            </div>
        </div>
        <AddReservationModal
            v-if="showAddReservationModal"
            :date="reservation_date"
            :restaurant_id="restaurant_id"
            :defaultValues="addReservationDefaultValues"
            :slot_id="addReservationDefaultValues.slot_id"
            :service_id="service_id"
            :showWaitingListButton="false"
            @reservation-saved="reservationSaved"
            @close="showAddReservationModal = false" />
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import axios from "axios";
import moment from "moment";
import AddReservationModal from "../Modals/reservations/AddReservationModalNew.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";
import inlineClient from "../Default/Clients/inlineClient.vue";

export default {
    data() {
        return {
            moment,
            widget: null,
            currentPage: 1,
            hasNextPage: false,
            hasPreviousPage: false,
            selected_waiting_id: null,
            loadingServices: true,
            loadingWaitings: true,
            service: null,
            restaurant_id: this.$route.params.restaurant_id,
            waitinglist: null,
            displayedMenus: {},
            showEditWaitingModal: false,
            paginate: false,
            links: {
                next: null,
                prev: null,
            },
            error: null,
            isSearch: false,
            showAddReservationModal: false,
            addReservationDefaultValues: {},
            showModalMenu: false,
        };
    },
    mixins: [ModuleTypesEnum],
    props: {
        service_id: {
            required: true,
        },
        reservation_date: {
            required: true,
        },
    },
    created() {
        this.fetchService();
        this.fetchWidget();
        this.fetchWaitings(true);
    },
    methods: {
        deleteWaiting(waitingId) {
            if (!confirm(this.$tl("questions.booking.waitingList.confirm.delete"))) return;
            this.loading = true;
            axios
                .delete(`/api/restaurants/${this.restaurant_id}/service/${this.service_id}/waiting/${waitingId}`)
                .then((response) => {
                    this.notifySuccess(response);
                    this.fetchWaitings(true);
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        reservationSaved() {
            this.fetchWaitings(true);
            this.showAddReservationModal = false;
            this.$notify({
                group: "notification",
                type: "success",
                title: this.$tl("success.booking.waitingList.reservationSaved"),
            });
        },
        async fetchService() {
            this.isSearch = false;
            this.error = this.waitinglist = this.links.next = this.links.prev = null;
            this.loadingServices = true;
            try {
                this.service = await axios.get(`/api/restaurants/${this.restaurant_id}/services/${this.service_id}`);
            } catch (error) {
                this.notifyError(error);
            }
            this.loadingServices = false;
        },
        async fetchWidget() {
            this.isSearch = false;
            this.error = this.waitinglist = this.links.next = this.links.prev = null;
            this.loadingService = true;
            try {
                this.widget = await axios.get(`/api/restaurants/${this.restaurant_id}/widget`);
            } catch (error) {
                this.notifyError(error);
                this.loadingService = false;
            }
        },
        async fetchWaitings(reset) {
            this.isSearch = false;
            this.error = null;
            if (reset) {
                this.currentPage = 1;
                this.loadingWaitings = true;
            } else this.currentPage++;
            axios
                .get(
                    `/api/restaurants/${this.restaurant_id}/service/${this.service_id}/waitings?page=${this.currentPage}&include=menus,menus.options,general_options,client,slot,last_mail_log&reservation_date=${this.reservation_date}`
                )
                .then((response) => {
                    this.waitinglist = reset ? response.data.data : this.waitinglist.concat(response.data.data);
                    this.hasNextPage = response.data.meta.pagination.total_pages > this.currentPage;
                    var modal = document.getElementById("modal-waiting-list-container");
                    if (this.hasNextPage) {
                        modal.onscroll = () => {
                            let bottomOfWindow = modal.scrollTop + modal.clientHeight >= modal.scrollHeight - 20; // 20 de padding pour la fluidité
                            if (bottomOfWindow) {
                                modal.onscroll = null; // Remove listener
                                this.fetchWaitings(false);
                            }
                        };
                    }
                    this.$nextTick().then(() => {
                        if (reset) modal.scrollTop = 0;
                    });
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loadingWaitings = false;
                });
        },
        sendMail(email, waiting) {
            if (waiting.last_mail_log) {
                if (waiting.last_mail_log.permanent_failed_at || !waiting.last_mail_log.sent_at) {
                    // Permanent failed, can send
                } else if (waiting.last_mail_log.sent_at && !waiting.last_mail_log.delivered_at) {
                    // Pending
                    if (!confirm(this.$tl("questions.booking.waitingList.confirm.pending"))) return;
                } else if (waiting.last_mail_log.delivered_at) {
                    // Already sent
                    if (!confirm(this.$tl("questions.booking.waitingList.confirm.alreadySend"))) return;
                }
            }

            this.loadingWaitings = true;

            axios
                .get(`/api/restaurants/${this.restaurant_id}/service/waitings/${waiting.id}/mail?mail=${email}`)
                .then((response) => {
                    this.fetchWaitings(true);
                    this.notifySuccess(response);
                })
                .catch((error) => {
                    this.loadingWaitings = false;
                    this.notifyError(error);
                });
        },
        createReservationFromWaiting(waiting) {
            this.addReservationDefaultValues = {
                nb_pers: waiting.nb_pers,
                nb_children: waiting.nb_children,
                client: {
                    id: waiting.client.id,
                    civility: waiting.client.civility,
                    firstname: waiting.client.firstname,
                    lastname: waiting.client.lastname,
                    company: waiting.client.company,
                    email: waiting.client.email,
                    phone: {
                        raw: waiting.client.tel,
                        country: waiting.client.tel_country,
                    },
                },
                phone: {
                    raw: waiting.client.tel,
                    country: waiting.client.tel_country,
                },
                waiting_id: waiting.id,
                slot_id: waiting.slot_id,
                room_number: waiting.room_number,
                restaurant_comment: waiting.restaurant_comment,
                back_choosen_menus: waiting.menus.data,
                back_choosen_general_options: waiting.general_options.data,
            };
            this.showAddReservationModal = true;
        },
        hasAttribute(currentObject, attribute) {
            return !!(currentObject[attribute] && currentObject[attribute].data && currentObject[attribute].data.length > 0);
        },
        hasMenusOrOptions(waiting) {
            return this.hasAttribute(waiting, "menus") || this.hasAttribute(waiting, "general_options");
        },
        toggleDetailsRow(waiting, index) {
            if (!this.displayedMenus[waiting.id]) {
                this.displayedMenus[waiting.id] = true;

                const element = { id: false };

                if (this.hasAttribute(waiting, "menus")) {
                    element.menus = waiting.menus.data;
                }

                if (this.hasAttribute(waiting, "general_options")) {
                    element.general_options = waiting.general_options.data;
                }

                this.waitinglist.splice(index + 1, 0, element);
            } else {
                delete this.displayedMenus[waiting.id];
                this.waitinglist.splice(index + 1, 1);
            }
        },
    },
    watch: {
        "$route.params.restaurant_id": function (id) {
            this.restaurant_id = this.$route.params.restaurant_id;
            this.fetchData();
        },
        "$route.query.message": function (message) {
            if (message && message !== "") {
                this.$notify({
                    group: "notification",
                    type: "success",
                    title: message,
                });

                this.$router.push("?");
            }
        },
    },
    components: {
        AddReservationModal,
        LoaderComponent,
        inlineClient,
    },
};
</script>

<style scoped>
#modal-waiting-list-container {
    max-height: 60vh;
    overflow-y: auto;
}
</style>
