<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Licences invalides</h5>
        <div slot="body">
            <span class="mb-2">Les licences suivantes comportent des erreurs :</span>
            <br />
            <br />
            <div class="mb-2 ml-2" v-for="(groupedSubscriptions, index) in subscriptionGroupedByRestaurant" :key="index">
                <span v-if="groupedSubscriptions[0].restaurant">{{ groupedSubscriptions[0].restaurant.name }}</span>
                <span v-else>Licence(s) non associée(s)</span>
                <br />
                <span v-for="(subscription, index1) in groupedSubscriptions" class="ml-2" :key="`${index}-${index1}`">
                    - {{ getModuleTypeLabel(subscription.module.type) }}
                    <small v-if="subscription.status === 'past_due'"
                        >(En défaut de paiement depuis {{ displayDate(subscription.past_due_at, DATE_SHORT) }})</small
                    >
                    <br />
                </span>
            </div>
            <br />
            <small class="text-danger"
                >Sans action de votre part, les licences en défaut de paiement seront annulés à partir de 7 jours suivant la date de mise en défaut de
                paiement</small
            >
        </div>
        <div slot="footer" class="d-flex justify-content-center">
            <router-link @click.native="$emit('close')" :to="{ name: 'subscription' }" class="btn btn-sm btn-success btn-circle ml-2">
                Vérifier mes licences
            </router-link>
        </div>
    </modal>
</template>

<script>
import ModuleTypesEnum from "../../../../mixins/enums/ModuleTypesEnum";

export default {
    props: {
        subscriptions: {
            required: true,
        },
    },
    computed: {
        subscriptionGroupedByRestaurant() {
            return this.$_.groupBy(this.subscriptions, "restaurant_id");
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    mixins: [ModuleTypesEnum],
};
</script>
