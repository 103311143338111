<template>
    <div>
        Remboursement partiel effectué sur les produits suivants :
        <div v-for="cp in command.command_products.data" :key="cp.id">
            <span class="d-block ml-2" v-if="cp.refund && cp.refund">
                <template v-if="cp.refund.quantity > 1">{{ cp.refund.quantity }} x </template>{{ cp.product.name }} (
                {{ formatCurrency(cp.product.price * cp.refund.quantity) }} )
            </span>
            <div v-for="co in cp.command_product_option.data" :key="co.id">
                <span class="d-block ml-4" v-if="co.refund && co.refund">
                    <template v-if="co.refund.quantity > 1">{{ co.refund.quantity }} x </template
                    >{{ co.product_option.cc_product.name }} (
                    {{ formatCurrency(co.product_option.price * co.refund.quantity) }} )
                </span>
            </div>
        </div>
        <span class="d-block">Pour un total de {{ formatCurrency(total_amount_refunded) }}</span>
    </div>
</template>

<script>
export default {
    props: {
        command: {
            required: true,
            type: Object,
        },
    },
    computed: {
        total_amount_refunded() {
            let amount = 0;
            if (!this.command) return 0;
            this.command.command_products.data.forEach((cp) => {
                if (cp.refund && cp.refund) amount += cp.product.price * cp.refund.quantity;
                cp.command_product_option.data.forEach((co) => {
                    if (co.refund && co.refund) amount += co.product_option.price * co.refund.quantity;
                });
            });
            return amount;
        },
    },
};
</script>
