<template>
    <modal class="modal-pax-selection">
        <h5 slot="header" class="d-flex justify-content-between w-100">
            <template v-if="selectedReservation.is_passing_customer">
                {{ $tl("labels.booking.reservations.passingCustomer.title") }}
            </template>
            <template v-else>
                <span>
                    {{ $tl("labels.reservation") }}{{ $tl("labels.colon") }}
                    <template v-if="selectedReservation.client !== null && selectedReservation.client.fullname !== ''">{{
                        selectedReservation.client.fullname
                    }}</template>
                    <template v-else>{{ $tl("labels.clients.unknown") }}</template>
                </span>
            </template>

            <span class="text-uppercase">{{ selectedReservation.slot.hour_start }} | {{ selectedReservation.nb_pers }} {{ $tl("labels.pax") }}</span>
        </h5>
        <div slot="body">
            <div v-if="!canDisplayTable">
                <div v-for="(table, index) in tables" class="mb-3">
                    <p class="text-center">{{ $tl("labels.booking.seatingPlan.selectNbPeopleByTable") }} {{ table.name }}</p>
                    <div class="row col-12 m-0 paxes-container d-flex justify-content-between align-items-start">
                        <div class="container-input col">
                            <template v-if="table.enabledPax.length > 0">
                                <span class="radio" v-for="(pax, subIndex) in table.enabledPax" :key="subIndex">
                                    <label class="container-box">
                                        <input type="radio" :name="`table-${table.id}-pax`" v-model="selectedPaxes[index].paxSelected" :value="pax" />
                                        <span class="checkmark"></span> {{ pax }}
                                    </label>
                                </span>
                            </template>
                            <span class="radio" v-else>
                                <label class="container-box"
                                    ><input
                                        type="radio"
                                        :name="`table-${table.id}-pax`"
                                        v-model="selectedPaxes[index].paxSelected"
                                        :value="table.seats" />
                                    <span class="checkmark"></span> {{ table.seats }}
                                </label>
                            </span>
                        </div>
                        <div class="col p-0">
                            <button v-if="!canDisplayTable" type="button" class="btn btn-success w-100 btn-circle" @click="canDisplayTable = true">
                                {{ $tl("labels.booking.reservations.placeSelectedSeats") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <select-tables
                    :remaining-paxes="remainingPaxes"
                    :reservation="selectedReservation"
                    :selected-table="dropRelatedObject"
                    :current-room-index="currentRoomIndex"
                    @updated-tables="selectedTables = $event" />
            </div>
        </div>
        <div slot="footer" class="d-flex row w-100 justify-content-between px-0 mx-3">
            <div class="col d-flex">
                <button type="button" class="modal-default-button btn btn-secondary btn-circle mx-0" @click="$emit('close')">
                    {{ $tl("labels.form.actions.cancel") }}
                </button>
            </div>
            <div v-if="!canDisplayTable" class="col p-0 d-flex">
                <button type="button" class="btn btn-warning btn-circle mx-0 w-100" @click="$emit('place-all', { dropedOnType, dropRelatedObject })">
                    {{ $tl("labels.booking.reservations.placeEntireBooking") }}
                </button>
            </div>
            <button v-else type="button" class="btn btn-success btn-circle mx-0" @click="placeResa">
                {{ $tl("labels.form.actions.place") }}
            </button>
        </div>
    </modal>
</template>

<script>
import SelectTables from "../../reservations/add/Form/SelectTables.vue";

export default {
    props: {
        tables: {
            required: true,
            type: Array,
        },
        dropedOnType: {
            required: true,
            type: String,
        },
        dropRelatedObject: {
            required: true,
            type: Object,
        },
        selectedReservation: {
            required: true,
            type: Object,
        },
        currentRoomIndex: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            selectedPaxes: [],
            canDisplayTable: false,
            selectedTables: [],
        };
    },
    computed: {
        remainingPaxes() {
            return (
                this.selectedReservation.nb_pers -
                this.selectedPaxes.reduce((accumulator, selectedPaxe) => accumulator + (selectedPaxe.paxSelected || 0), 0)
            );
        },
    },
    components: {
        SelectTables,
    },
    created() {
        this.tables.forEach((t, index) => {
            this.selectedPaxes[index] = {
                table: t,
                paxSelected: t.enabledPax.length > 0 ? t.enabledPax[0] : t.seats,
            };
        });
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e = null) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                if (e) {
                    e.stopPropagation();
                }
                this.close();
            }
        },
        placeResa() {
            this.$emit("paxes-selected", {
                selectedPaxes: this.selectedPaxes,
                dropedOnType: this.dropedOnType,
                dropRelatedObject: this.dropRelatedObject,
                selectedTables: this.selectedTables,
            });
        },
    },
};
</script>
