import Clients from "../../views/Clients.vue";
import ClientsForm from "../../views/ClientsForm.vue";

import CampaignsHome from "../../views/Campaigns/Home.vue";
import CampaignsList from "../../views/Campaigns/Campaign.vue";

const routes = [];

if (THEME === "noshow") {
    routes.push(
        {
            path: "/campaigns",
            name: "campaigns.home",
            component: CampaignsHome,
        },
        {
            path: "/campaigns/restaurants/:restaurant_id/campaigns",
            name: "campaigns.restaurants.campaigns",
            component: CampaignsList,
        },
        {
            path: "/campaigns/restaurants/:restaurant_id/clients/:client_id",
            name: "campaigns.clients.form",
            component: ClientsForm,
        },
        {
            path: "/campaigns/restaurants/:restaurant_id/clients",
            name: "campaigns.clients",
            component: Clients,
        }
    );
}

export default routes;
