<template>
    <span v-if="hasValidIssuer" v-tooltip="tooltip">
        <feather v-if="isFeatherIcon" :type="icon.feather" :class="icon.class" />
        <img v-else-if="isImgIcon" :src="icon.img" class="feather-20" :class="icon.class" />
        <span v-else-if="isHtmlIcon" v-html="icon.html" class="feather-20 d-inline-block" :class="icon.class"></span>
        <span v-else class="text-danger">Unknown icon</span>
    </span>
</template>

<script>
import ReservationIssuerEnum from "../../mixins/enums/booking/ReservationIssuerEnum.js";

export default {
    props: {
        issuer: {
            type: String,
            required: true,
        },
        provider: {
            type: String,
            required: true,
        },
        createdAt: {
            type: String,
            required: true,
        },
        user: {
            type: Object,
            default: null,
        },
    },
    mixins: [ReservationIssuerEnum],
    computed: {
        issuerObject() {
            return this.ALL_RESERVATION_ISSUERS.find((issuer) => issuer.value === this.issuer);
        },
        hasValidIssuer() {
            return typeof this.issuerObject !== "undefined";
        },
        icon() {
            return this.issuerObject.getIcon(this.provider);
        },
        isFeatherIcon() {
            return typeof this.icon === "object" && typeof this.icon["feather"] !== "undefined";
        },
        isImgIcon() {
            return typeof this.icon === "object" && typeof this.icon["img"] !== "undefined";
        },
        isHtmlIcon() {
            return typeof this.icon === "object" && typeof this.icon["html"] !== "undefined";
        },
        tooltip() {
            const label = this.getReservationIssuerLabel(this.issuer, this.provider, this.createdAt, this.user);

            return typeof label !== "undefined" ? this.getTooltip(label) : undefined;
        },
    },
};
</script>
