<template>
    <div class="row">
        <div class="col-12">
            <div class="border-light b-radius-20 p-4">
                <div class="row">
                    <div class="col-5 pt-2">
                        <label for="category">{{ $tl("labels.booking.services.category") }} <small>*</small></label>
                    </div>
                    <div class="col-7">
                        <select name="category" id="category" v-model="service.general.category.value" required class="custom-select">
                            <option
                                v-for="serviceCategory in ALL_DEFAULT_SERVICE_CATEGORIES_WITH_OTHER"
                                :key="serviceCategory.value"
                                :value="serviceCategory.value">
                                {{ getDefaultServiceCategoryLabel(serviceCategory.value) }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row mb-2" v-if="service.general.category.value === 'autre'">
                    <div class="col-5 pt-2">
                        <label for="category">{{ $tl("labels.booking.services.categoryName") }} ({{ $tl("labels.langs.fr") }})<small>*</small></label>
                    </div>
                    <div class="col-7 pt-2">
                        <input
                            type="text"
                            name="custom_category_fr"
                            id="custom_category_fr"
                            v-model="service.general.category.nameFr"
                            required
                            class="form-control" />
                    </div>
                    <div class="col-5 pt-2">
                        <label for="category">{{ $tl("labels.booking.services.categoryName") }} ({{ $tl("labels.langs.en") }})<small>*</small></label>
                    </div>
                    <div class="col-7 pt-2">
                        <input
                            type="text"
                            name="custom_category_en"
                            id="custom_category_en"
                            v-model="service.general.category.nameEn"
                            required
                            class="form-control" />
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-5 pt-2">
                        <label for="name">
                            {{ isSpecial ? $tl("labels.form.name") : $tl("labels.booking.services.name", restaurant.id) }}
                            <small>*</small>
                            <feather
                                v-tooltip="getTooltipNoticeLexicon('notices.booking.servicesConfig.serviceName', restaurant.id)"
                                type="info"
                                class="ml-1 pointer tooltip-infos" />
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="name" v-model="service.general.name" id="name" required class="form-control" />
                    </div>
                </div>

                <div v-if="isSpecial">
                    <div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <strong>
                                    {{ $tl("labels.booking.services.special.dates") }}
                                </strong>
                                <feather
                                    v-tooltip="getTooltipNoticeLexicon('notices.booking.servicesConfig.exceptionalOpening', restaurant.id)"
                                    type="info"
                                    class="ml-1 pointer tooltip-infos" />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-5">
                                <label>{{ $tl("labels.startDate") }} <small>*</small></label>
                            </div>
                            <div class="col-7 date-resa-cal">
                                <DatePicker v-model="openDate" @selected="verifDatesBegin" />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-5">
                                <label>{{ $tl("labels.endDate") }} <small>*</small></label>
                            </div>
                            <div class="col-7 date-resa-cal">
                                <DatePicker v-model="closeDate" @selected="verifDatesEnd" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-5">
                        <label>
                            {{ $tl("labels.booking.services.activeDays") }} <small>*</small>
                            <feather
                                v-tooltip="getTooltipNoticeLexicon('notices.booking.servicesConfig.activeDays', restaurant.id)"
                                type="info"
                                class="ml-1 pointer tooltip-infos" />
                        </label>
                    </div>
                    <div class="col-7">
                        <label class="container-box" v-for="englishDay in englishDays()" :key="englishDay">
                            <input type="checkbox" name="days[]" v-model="service.general.openDays" :value="englishDay" />
                            <span class="checkmark"></span> {{ capitalize($t(`labels.days.${englishDay}`)) }}
                        </label>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 pt-2">
                        <label>{{ $tl("labels.booking.services.startHour", restaurant.id) }} <small>*</small></label>
                    </div>
                    <div class="col-7">
                        <vue-timepicker format="HH:mm" :minute-interval="15" v-model="serviceHourStart"></vue-timepicker>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-5 pt-2">
                        <label>{{ $tl("labels.booking.services.endHour", restaurant.id) }} <small>*</small></label>
                    </div>
                    <div class="col-7">
                        <vue-timepicker format="HH:mm" :minute-interval="15" v-model="serviceHourEnd"></vue-timepicker>
                    </div>
                </div>
                <div class="row mb-2" v-if="availableSlots.length > 1">
                    <div class="col-5">
                        <label>
                            {{ $tl("labels.booking.services.slots") }} <small>*</small>
                            <feather
                                v-tooltip="getTooltipNotice('notices.booking.servicesConfig.reservationSlots')"
                                type="info"
                                class="ml-1 pointer tooltip-infos" />
                        </label>
                    </div>
                    <div class="col-7">
                        <div style="margin-right: 13px" class="container-box" v-for="slot in availableSlots">
                            <label style="margin-bottom: 0">
                                <input
                                    @click="slotClicked($event, slot)"
                                    :value="true"
                                    :checked="!$_.isEmpty(service.general.slots[slot])"
                                    type="checkbox" /><span class="checkmark"></span> {{ slot }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-5">
                        <label class="mb-0">
                            {{ $tl("labels.booking.services.maxPax", restaurant.id) }} <small>*</small>
                            <feather
                                v-tooltip="getTooltipNoticeLexicon('notices.booking.servicesConfig.paxMaxPerService', restaurant.id)"
                                type="info"
                                class="ml-1 pointer tooltip-infos" /> </label
                        ><br />
                        <small class="text-muted">{{ $tl("infos.booking.services.maxPaxIfRotation", restaurant.id) }}</small>
                    </div>
                    <div class="col-7">
                        <div class="row">
                            <div class="col-4">
                                <input
                                    type="number"
                                    name="max_pax"
                                    v-model="service.general.pax.max"
                                    required
                                    id="max_pax"
                                    class="form-control"
                                    min="1"
                                    step="1" />
                            </div>
                            <div class="col-8 mt-2">
                                {{ $tl("labels.booking.covers", restaurant.id) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-5 pt-1">
                        <label> {{ $tl("labels.booking.services.minPers") }} <small>*</small> </label>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-12">
                                <div class="d-inline-block align-middle lead-switch pt-1">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        :checked="service.general.use_widget_min_pers" />
                                    <label @click="service.general.use_widget_min_pers = !service.general.use_widget_min_pers"></label>
                                    {{ $tl("labels.booking.widget.useDefaultConfig") }}
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2" v-if="!service.general.use_widget_min_pers">
                            <div class="col-4">
                                <input
                                    type="number"
                                    v-model="service.general.min_pers"
                                    :required="!service.general.use_widget_min_pers"
                                    class="form-control"
                                    min="1"
                                    step="1" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 pt-1">
                        <label> {{ $tl("labels.booking.services.maxPers") }} <small>*</small> </label>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-12">
                                <div class="d-inline-block align-middle lead-switch pt-1">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        :checked="service.general.use_widget_max_pers" />
                                    <label @click="service.general.use_widget_max_pers = !service.general.use_widget_max_pers"></label>
                                    {{ $tl("labels.booking.widget.useDefaultConfig") }}
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2" v-if="!service.general.use_widget_max_pers">
                            <div class="col-4">
                                <input
                                    type="number"
                                    v-model="service.general.max_pers"
                                    :required="!service.general.use_widget_max_pers"
                                    class="form-control"
                                    min="1"
                                    step="1" />
                            </div>
                        </div>
                    </div>
                </div>

                <template v-if="!$_.isEmpty(service.general.slots)">
                    <div class="row mb-2 mt-4">
                        <div class="col-md-5">
                            <i18n path="labels.booking.services.enableMaxPax" tag="label">
                                <template slot="term">
                                    <strong>{{ $tl("labels.booking.covers", restaurant.id).toLowerCase() }}</strong>
                                </template>
                            </i18n>
                        </div>
                        <div class="col-md-7">
                            <div class="d-inline-block align-middle lead-switch pt-1">
                                <input
                                    type="checkbox"
                                    class="switch align-self-center is-rounded"
                                    :checked="service.general.is_slot_max_pax_enabled" />
                                <label @click="changeIsSlotMaxPaxEnabled"></label>
                            </div>
                        </div>
                    </div>
                    <div v-if="service.general.is_slot_max_pax_enabled == true" class="row mb-2 m-0">
                        <div class="col-md-12 border-light p-4 mb-4">
                            <template v-for="slotKey in sortedSlotsKeys">
                                <div class="row mb-1" :key="slotKey">
                                    <div class="col-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                type="checkbox"
                                                class="switch align-self-center is-small is-rounded"
                                                :checked="service.general.slots[slotKey].is_max_pax_enabled" />
                                            <label @click="changeSlotMaxPaxEnabled(slotKey)"></label>
                                        </div>
                                    </div>
                                    <div class="col-1 pt-1">
                                        {{ service.general.slots[slotKey].hour_start }}
                                    </div>
                                    <div class="col-3">
                                        <input
                                            :disabled="!service.general.slots[slotKey].is_max_pax_enabled"
                                            type="number"
                                            :name="`slot_${service.general.slots[slotKey].hour_start}_pax`"
                                            v-model="service.general.slots[slotKey].max_pax"
                                            class="form-control"
                                            min="0"
                                            step="1" />
                                    </div>
                                    <div class="col-6 pt-1">
                                        {{ $tl("labels.booking.services.coversPerSlot", restaurant.id) }}
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-5">
                            <i18n path="labels.booking.services.enableMaxPax" tag="label">
                                <template slot="term">
                                    <strong>{{ $tl("labels.booking.reservations.title").toLowerCase() }}</strong>
                                </template>
                            </i18n>
                        </div>
                        <div class="col-md-7">
                            <div class="d-inline-block align-middle lead-switch pt-1">
                                <input
                                    type="checkbox"
                                    class="switch align-self-center is-rounded"
                                    :checked="service.general.is_slot_max_reservations_enabled" />
                                <label @click="changeIsSlotMaxReservationsEnabled"></label>
                            </div>
                        </div>
                    </div>
                    <div v-if="service.general.is_slot_max_reservations_enabled == true" class="row mb-2 m-0">
                        <div class="col-md-12 border-light p-4">
                            <template v-for="slotKey in sortedSlotsKeys">
                                <div class="row mb-1" :key="slotKey">
                                    <div class="col-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                type="checkbox"
                                                class="switch align-self-center is-small is-rounded"
                                                :checked="service.general.slots[slotKey].is_max_reservations_enabled" />
                                            <label @click="changeSlotMaxReservationsEnabled(slotKey)"></label>
                                        </div>
                                    </div>
                                    <div class="col-1 pt-1">
                                        {{ service.general.slots[slotKey].hour_start }}
                                    </div>
                                    <div class="col-3">
                                        <input
                                            :disabled="!service.general.slots[slotKey].is_max_reservations_enabled"
                                            type="number"
                                            :name="`slot_${service.general.slots[slotKey].hour_start}_reservation`"
                                            v-model="service.general.slots[slotKey].max_reservations"
                                            class="form-control"
                                            min="0"
                                            step="1" />
                                    </div>
                                    <div class="col-6 pt-1">
                                        {{ $tl("labels.booking.services.resasPerSlot") }}
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <div class="row mt-4 mb-4">
                    <div class="col-5">
                        <label>
                            {{ $tl("labels.booking.services.takeResas") }} <small>*</small>
                            <feather
                                v-tooltip="getTooltipNoticeLexicon('notices.booking.servicesConfig.limitReservation', restaurant.id)"
                                type="info"
                                class="ml-1 pointer tooltip-infos" />
                        </label>
                    </div>
                    <div class="col-7">
                        <div class="row">
                            <div class="col-12">
                                <div class="radio">
                                    <label class="container-box"
                                        ><input type="radio" v-model="service.general.lock.enabled" :value="false" /> <span class="checkmark"></span>
                                        {{ $tl("labels.booking.services.takeResaLive", restaurant.id) }}
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 d-inline radio mt-1">
                                <label class="container-box"
                                    ><input type="radio" v-model="service.general.lock.enabled" :value="true" /> <span class="checkmark"></span>
                                    {{ $tl("labels.booking.services.blockResas") }}
                                </label>

                                <input
                                    type="number"
                                    class="form-control d-inline"
                                    style="width: 70px"
                                    name="lock_reservation_until"
                                    v-model="service.general.lock.until"
                                    :disabled="!service.general.lock.enabled"
                                    id="lock_reservation_until" />
                                {{ $tl("labels.booking.services.minsBeforeStart", restaurant.id) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-5 pt-2">
                        <label> {{ $tl("labels.booking.services.enableOnWidget") }} <small>*</small> </label>
                    </div>
                    <div class="col-7">
                        <div class="row">
                            <div class="col-12">
                                <div class="d-inline-block align-middle lead-switch mt-1 pt-2">
                                    <input type="checkbox" class="switch align-self-center is-rounded" :checked="service.general.show_on_widget" />
                                    <label @click="service.general.show_on_widget = !service.general.show_on_widget"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-5 pt-2">
                        <label>
                            {{ $tl("labels.booking.services.cancelationDelay") }} <small>*</small>
                            <feather
                                v-tooltip="getTooltipNoticeLexicon('notices.booking.servicesConfig.cancellationPeriod', restaurant.id)"
                                type="info"
                                class="ml-1 pointer tooltip-infos" />
                        </label>
                    </div>
                    <div class="col-7">
                        <div class="row">
                            <div class="col-4">
                                <input type="number" v-model="service.general.cancellation.delay" class="form-control" />
                            </div>
                            <div class="col-8 mt-2">
                                {{ $tl("labels.booking.services.hoursBeforeResa") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 mt-4">
                    <div class="col-5 pt-2">
                        <label>
                            {{ $tl("labels.booking.services.resasConfirm") }} <small>*</small>
                            <feather
                                v-tooltip="getTooltipNotice('notices.booking.servicesConfig.reservationConfirm')"
                                type="info"
                                class="ml-1 pointer tooltip-infos" />
                        </label>
                    </div>
                    <div class="col-7 radio w-100 h-100 d-table pt-2">
                        <div class="d-table-cell align-middle">
                            <label class="container-box"
                                ><input type="radio" v-model="service.general.autoconfirm.enabled" :value="false" /> <span class="checkmark"></span>
                                {{ $tl("labels.manual") }}
                            </label>
                            <label class="container-box">
                                <input type="radio" v-model="service.general.autoconfirm.enabled" :value="true" />
                                <span class="checkmark"></span> {{ $tl("labels.auto") }}
                            </label>
                        </div>
                    </div>
                </div>
                <template v-if="service.general.autoconfirm.enabled">
                    <div class="row mb-2">
                        <div class="col-4 pt-2 offset-1">
                            <label>
                                {{ $tl("labels.to") }}
                            </label>
                        </div>
                        <div class="col-7">
                            <div class="row">
                                <div class="col-4">
                                    <input
                                        type="number"
                                        v-model="service.general.autoconfirm.upToPercentFull"
                                        class="form-control"
                                        :disabled="!service.general.autoconfirm.enabled" />
                                </div>
                                <div class="col-8 mt-2">
                                    {{ $tl("labels.booking.services.percentFillRate") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-4 pt-2 offset-1">
                            <label>
                                {{ $tl("labels.to") }}
                            </label>
                        </div>
                        <div class="col-7">
                            <div class="row">
                                <div class="col-4">
                                    <input
                                        type="number"
                                        v-model="service.general.autoconfirm.upToPax"
                                        class="form-control"
                                        :disabled="!service.general.autoconfirm.enabled" />
                                </div>
                                <div class="col-8 mt-2">
                                    {{ $tl("labels.personss") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-4 pt-2 offset-1">
                            <label>
                                {{ $tl("labels.to") }}
                            </label>
                        </div>
                        <div class="col-7">
                            <div class="row">
                                <div class="col-4">
                                    <input
                                        type="number"
                                        v-model="service.general.autoconfirm.upToMinutesBeforeStart"
                                        class="form-control"
                                        :disabled="!service.general.autoconfirm.enabled" />
                                </div>
                                <div class="col-8 mt-2">
                                    {{ $tl("labels.booking.services.minsBeforeSlotStarts") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-4 offset-1">
                            <label> {{ $tl("labels.booking.services.blockAutoConfirm") }}<br /> </label>
                        </div>
                        <div class="col-7">
                            <div class="row">
                                <div class="col-4">
                                    <input
                                        type="number"
                                        v-model.number="service.general.autoconfirm.upToNoshow"
                                        class="form-control"
                                        :disabled="!service.general.autoconfirm.enabled" />
                                </div>
                                <div class="col-8 mt-2">
                                    {{ capitalize($tl("labels.clients.noshow"))
                                    }}<small> - {{ $tl("infos.booking.services.blockAutoConfirm") }}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="row mb-2 mt-4">
                    <div class="col-5 pt-2">
                        <label>
                            {{ $tl("labels.booking.services.enableTableRotation", restaurant_id) }}
                            <feather
                                v-tooltip="getTooltipNoticeLexicon('notices.booking.servicesConfig.tableRotation', restaurant.id)"
                                type="info"
                                class="ml-1 pointer tooltip-infos" />
                        </label>
                    </div>
                    <div class="col-7 w-100 h-100 d-table pt-2">
                        <div class="d-table-cell align-middle">
                            <label class="container-box">
                                <input type="checkbox" name="enable_table_rotation" v-model="service.paxes.rotation" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div v-if="service.paxes.rotation">
                    <div class="row">
                        <div class="col-12 pt-1">
                            <label>{{ $tl("labels.booking.services.averageTimeAtTable", restaurant_id) }}</label>
                        </div>
                    </div>
                    <template v-for="pax in service.paxes.value">
                        <div class="row" v-if="pax.pax <= 10">
                            <div class="offset-3 col-4 pt-1">{{ pax.pax === 10 ? "+" : "" }}{{ pax.pax }}</div>
                            <div class="col-5 pt-1">
                                <vue-timepicker v-if="pax.pax < 10" format="HH:mm" :minute-interval="15" v-model="pax.duration"></vue-timepicker>
                                <vue-timepicker
                                    v-else
                                    @change="setPaxesHigherThanTen($event)"
                                    :minute-interval="15"
                                    v-model="pax.duration"></vue-timepicker>
                            </div>
                        </div>
                    </template>
                </div>
                <template v-if="hasFeatSeatingPlan">
                    <div class="row mb-2 mt-2">
                        <div class="col-5 pt-2">
                            <label>
                                {{ $tl("labels.booking.seatingPlan.title") }}
                            </label>
                        </div>
                        <div class="col-7 w-100 h-100 d-table pt-2">
                            <div>
                                <select
                                    class="custom-select"
                                    v-model="service.seatingPlan.value"
                                    :disabled="!restaurant.hasASeatingPlan"
                                    v-tooltip="
                                        !restaurant.hasASeatingPlan
                                            ? {
                                                  content: $t('infos.booking.services.enableSeatingPlan'),
                                              }
                                            : null
                                    ">
                                    <option :value="null">--</option>
                                    <option v-for="seatingPlan in seatingPlans" :key="seatingPlan.id" :value="seatingPlan">
                                        {{ seatingPlan.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2 mt-2" v-if="service.seatingPlan.value">
                        <div class="col-5 pt-2">
                            <label>
                                {{ $tl("labels.booking.services.enableAlgorithm", restaurant_id) }}
                                <feather
                                    v-tooltip="getTooltipNoticeLexicon('notices.booking.servicesConfig.automaticSeatingPlan', restaurant.id)"
                                    type="info"
                                    class="ml-1 pointer tooltip-infos" />
                            </label>
                        </div>
                        <div class="col-7 w-100 h-100 d-table pt-2">
                            <div class="d-table-cell align-middle">
                                <label
                                    class="container-box"
                                    v-tooltip="
                                        !restaurant.hasASeatingPlan
                                            ? {
                                                  content: $t('infos.booking.services.enableSeatingPlan'),
                                              }
                                            : null
                                    ">
                                    <input type="checkbox" v-model="service.seatingPlan.algorithm_enabled" :disabled="!restaurant.hasASeatingPlan" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2 mt-2" v-if="service.seatingPlan.algorithm_enabled">
                        <div class="col-5 pt-2">
                            <label>
                                {{ $tl("labels.booking.services.allowClientsChooseRoom") }}
                            </label>
                        </div>
                        <div class="col-7 w-100 h-100 d-table pt-2">
                            <div class="d-table-cell align-middle">
                                <label class="container-box">
                                    <input type="checkbox" v-model="service.seatingPlan.allow_clients_choose_room" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="radio" v-if="service.seatingPlan.allow_clients_choose_room">
                                <div class="d-table-cell align-middle">
                                    <label class="container-box">
                                        <input type="radio" v-model="service.seatingPlan.allow_clients_choose_room_mandatory" value="0" />
                                        <span class="checkmark"></span> {{ $tl("labels.optional") }}
                                    </label>
                                    <label class="container-box">
                                        <input type="radio" v-model="service.seatingPlan.allow_clients_choose_room_mandatory" value="1" />
                                        <span class="checkmark"></span> {{ $tl("labels.mandatory") }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="row mb-2 mt-2">
                    <div class="col-5">
                        <label>
                            {{ $tl("labels.booking.menus.externalMenu.externalMenu", restaurant.id) }}
                            <feather
                                v-tooltip="getTooltipNoticeLexicon('notices.booking.externalMenus', restaurant.id)"
                                type="info"
                                class="ml-1 pointer tooltip-infos" />
                        </label>
                    </div>
                    <div class="col-7">
                        <div class="radio">
                            <RadioButtonComponent v-model="externalMenu.type" :inputValue="EXTERNAL_MENU_FILE.value">
                                {{ $tl("labels.booking.menus.externalMenu.file.pdf") }}
                            </RadioButtonComponent>
                            <RadioButtonComponent v-model="externalMenu.type" :inputValue="EXTERNAL_MENU_LINK.value">
                                {{ $tl("labels.booking.menus.externalMenu.link.link") }}
                            </RadioButtonComponent>
                            <RadioButtonComponent v-model="externalMenu.type" :inputValue="null">
                                {{ $tl("labels.booking.menus.externalMenu.disabled") }}
                            </RadioButtonComponent>
                        </div>
                        <div v-if="externalMenu.type === EXTERNAL_MENU_FILE.value" class="my-2">
                            <FileUploaderComponent
                                :max-size="20"
                                :hasDirectUpload="false"
                                suffixId="ext-desc"
                                :props-file="externalMenu.file"
                                @upload-file="externalMenu.file = $event" />
                        </div>
                        <div v-else-if="externalMenu.type === EXTERNAL_MENU_LINK.value" class="my-2">
                            <InputComponent
                                v-model="externalMenu.content.link"
                                label=""
                                :placeholder="$t('labels.booking.menus.externalMenu.link.placeholder')"
                                labelWrapperClasses="d-none"
                                type="url" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import OverlayLayout from "../../layouts/OverlayLayout";
import { DateTime } from "luxon";
import DefaultServiceCategoriesEnum from "../../../mixins/enums/booking/DefaultServiceCategoriesEnum";
import DatePicker from "../../../components/forms/DatePicker.vue";
import RadioButtonComponent from "../../forms/RadioButtonComponent.vue";
import ExternalMenuTypeEnum from "../../../mixins/enums/booking/ExternalMenuTypeEnum.js";
import FileUploaderComponent from "../../forms/FileUploaderComponent.vue";
import InputComponent from "../../forms/InputComponent.vue";
import LexiconEnum from "../../../mixins/enums/LexiconEnum.js";

export default {
    name: "GeneralGroup",
    components: {
        VueTimepicker,
        OverlayLayout,
        DatePicker,
        RadioButtonComponent,
        FileUploaderComponent,
        InputComponent,
    },
    data() {
        return {
            canEmitUpdate: false,
            externalMenu: {
                type: null,
                content: {
                    link: "",
                    file: "",
                },
                hasFile: false,
                file: null,
            },
        };
    },
    mixins: [DefaultServiceCategoriesEnum, ExternalMenuTypeEnum, LexiconEnum],
    props: {
        seatingPlans: {
            default: function () {
                return [];
            },
        },
        isSpecial: {
            default: false,
        },
        service: {
            required: true,
        },
        widget: {
            required: true,
        },
        restaurant: {
            required: true,
        },
        externalMenuFromProps: {
            type: Object,
            required: true,
        },
    },
    created() {
        if (this.isSpecial && typeof this.service.general.special !== "undefined") {
            if (!this.service.general.special.openDate) {
                this.service.general.special.openDate = this.getDateTime();
            }
            if (!this.service.general.special.openDate) {
                this.service.general.special.openDate = this.getDateTime();
            }
        }
    },
    mounted() {
        this.initExternalMenu();
        this.canEmitUpdate = true;
    },
    computed: {
        restaurantFromStore() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurant.id);
        },
        hasFeatSeatingPlan() {
            return typeof this.restaurantFromStore !== "undefined" && this.restaurantFromStore.feat_seating_plan;
        },
        openDate: {
            get() {
                if (this.isSpecial && typeof this.service.general.special !== "undefined") {
                    return this.service.general.special.openDate;
                }
                return undefined;
            },
            set(date) {
                this.service.general.special.openDate = this.getDateTime(date, false);
            },
        },
        closeDate: {
            get() {
                if (this.isSpecial && typeof this.service.general.special !== "undefined") {
                    return this.service.general.special.closeDate;
                }
                return undefined;
            },
            set(date) {
                this.service.general.special.closeDate = this.getDateTime(date, false);
            },
        },
        sortedSlotsKeys() {
            let keys = Object.keys(this.service.general.slots);

            return keys.sort((slotA, slotB) => {
                const dateTimeSlotA = this.setHourOnDateTime(slotA);
                const dateTimeSlotB = this.setHourOnDateTime(slotB);

                return dateTimeSlotA.diff(dateTimeSlotB, "minutes").minutes;
            });
        },
        serviceHourStart: {
            get() {
                if (this.service.general.openHours.start) {
                    return {
                        HH: this.service.general.openHours.start.split(":")[0],
                        mm: this.service.general.openHours.start.split(":")[1],
                    };
                }

                return { HH: "00", mm: "00" };
            },
            set(value) {
                if (
                    value.HH &&
                    value.HH != undefined &&
                    value.HH != null &&
                    value.HH !== "HH" &&
                    value.mm &&
                    value.mm != undefined &&
                    value.mm != null &&
                    value.mm !== "mm"
                ) {
                    const hourValue = Number.parseInt(value.HH);
                    const minValue = Number.parseInt(value.mm);

                    if (!isNaN(hourValue) && !isNaN(minValue)) {
                        this.service.general.openHours.start = `${value.HH}:${value.mm}`;
                        this.disabledSlotsBasedOnAvailableSlots();
                    }
                }
            },
        },
        serviceHourEnd: {
            get() {
                if (this.service.general.openHours.end) {
                    return {
                        HH: this.service.general.openHours.end.split(":")[0],
                        mm: this.service.general.openHours.end.split(":")[1],
                    };
                }

                return { HH: "00", mm: "00" };
            },
            set(value) {
                if (
                    value.HH &&
                    value.HH != undefined &&
                    value.HH != null &&
                    value.HH !== "HH" &&
                    value.mm &&
                    value.mm != undefined &&
                    value.mm != null &&
                    value.mm !== "mm"
                ) {
                    const hourValue = Number.parseInt(value.HH);
                    const minValue = Number.parseInt(value.mm);

                    if (!isNaN(hourValue) && !isNaN(minValue)) {
                        this.service.general.openHours.end = `${value.HH}:${value.mm}`;
                        this.disabledSlotsBasedOnAvailableSlots();
                    }
                }
            },
        },
        availableSlots() {
            if (this.isHourSet(this.serviceHourStart) && this.isHourSet(this.serviceHourEnd)) {
                let minutes_interval = 15; //minutes interval
                let times = []; // time array
                let start_time = Number.parseInt(this.serviceHourStart.HH) * 60 + Number.parseInt(this.serviceHourStart.mm); // start time in minutes
                let end_time = Number.parseInt(this.serviceHourEnd.HH) * 60 + Number.parseInt(this.serviceHourEnd.mm); // end time in minutes

                //loop to increment the time and push results in array
                for (let i = 0; start_time <= end_time; i++) {
                    let hh = Math.floor(start_time / 60); // getting hours of day in 0-24 format
                    let mm = start_time % 60; // getting minutes of the hour in 0-55 format
                    times[i] = ("0" + (hh % 24)).slice(-2) + ":" + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
                    start_time = start_time + minutes_interval;
                }

                return times;
            }

            return [];
        },
        maxPax() {
            if (this.widget && this.widget.max_pax) {
                if (this.widget.enable_children && this.widget.max_children && this.widget.enable_children === 1) {
                    return this.widget.max_pax + this.widget.max_children;
                }

                return this.widget.max_pax;
            }
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    watch: {
        "service.paxes.rotation": {
            handler: function (newVal) {
                this.service.paxes.value = [];

                if (newVal === true) {
                    var defaultDuration = {
                        HH: "01",
                        mm: "00",
                    };

                    if (this.isHourSet(this.serviceHourStart) && this.isHourSet(this.serviceHourEnd)) {
                        const nowDateTime = this.getDateTime().startOf("day");
                        const hourStart = nowDateTime.plus({
                            hours: this.serviceHourStart.HH,
                            minutes: this.serviceHourStart.mm,
                        });
                        const hourEnd = nowDateTime.plus({
                            hours: this.serviceHourEnd.HH,
                            minutes: this.serviceHourEnd.mm,
                        });

                        if (hourEnd > hourStart) {
                            const { hours, minutes } = hourEnd.diff(hourStart, ["hours", "minutes"]).toObject();

                            if (hours) {
                                defaultDuration.HH = hours < 10 ? `0${hours}` : hours.toString();
                            } else {
                                defaultDuration.HH = "00";
                            }

                            if (minutes) {
                                defaultDuration.mm = minutes < 10 ? `0${minutes}` : minutes.toString();
                            } else {
                                defaultDuration.mm = "00";
                            }
                        }
                    }

                    this.$_.range(1, this.maxPax > 10 ? this.maxPax + 1 : 11).forEach((pax) => {
                        this.service.paxes.value.push({
                            duration: Object.assign({}, defaultDuration),
                            pax,
                        });
                    });
                }
            },
        },
        "service.seatingPlan.value": {
            handler(newVal) {
                if (!newVal) {
                    this.service.seatingPlan.algorithm_enabled = false;
                    this.service.seatingPlan.allow_clients_choose_room = false;
                    this.service.seatingPlan.allow_clients_choose_room_mandatory = 0;
                }
            },
        },
        externalMenu: {
            handler(val) {
                this.emitEvent("update-external-menus", val);
            },
            deep: true,
        },
    },
    methods: {
        emitEvent(eventName, val) {
            if (!this.canEmitUpdate) {
                return;
            }
            this.$emit(eventName, val);
        },
        initExternalMenu() {
            this.externalMenu = this.externalMenuFromProps;
        },
        verifDatesBegin(date) {
            if (this.getDateTime(date, false) > this.closeDate) {
                this.closeDate = date;
            }
        },
        verifDatesEnd(date) {
            if (this.getDateTime(date, false) < this.beginDate) {
                this.beginDate = date;
            }
        },
        disabledSlotsBasedOnAvailableSlots() {
            let tmpSlots = {};
            Object.keys(this.service.general.slots).forEach((hour) => {
                if (this.availableSlots.includes(hour)) tmpSlots[hour] = this.service.general.slots[hour];
            });
            this.$set(this.service.general, "slots", tmpSlots);
        },
        changeIsSlotMaxPaxEnabled() {
            var general = this.$_.cloneDeep(this.service.general);

            general.is_slot_max_pax_enabled = general.is_slot_max_pax_enabled ? 0 : 1;

            this.$set(this.service, "general", general);
        },
        changeIsSlotMaxReservationsEnabled() {
            var general = this.$_.cloneDeep(this.service.general);

            general.is_slot_max_reservations_enabled = general.is_slot_max_reservations_enabled ? 0 : 1;

            this.$set(this.service, "general", general);
        },
        changeSlotMaxReservationsEnabled(index) {
            var slots = this.$_.cloneDeep(this.service.general.slots);
            var slot = slots[index];

            slots[index].is_max_reservations_enabled = slot.is_max_reservations_enabled ? 0 : 1;

            this.$set(this.service.general, "slots", slots);
        },
        changeSlotMaxPaxEnabled(index) {
            var slots = this.$_.cloneDeep(this.service.general.slots);
            var slot = slots[index];

            slots[index].is_max_pax_enabled = slot.is_max_pax_enabled ? 0 : 1;

            this.$set(this.service.general, "slots", slots);
        },
        slotClicked(event, slot) {
            var value = event.target.checked;

            if (value) {
                this.$set(this.service.general.slots, slot, {
                    hour_start: slot,
                    is_max_pax_enabled: false,
                    max_pax: null,
                    is_max_reservations_enabled: false,
                    max_reservations: null,
                });
            } else {
                this.$set(this.service.general, "slots", this.$_.omit(this.service.general.slots, slot));
            }
        },
        setPaxesHigherThanTen(event) {
            for (const index in this.service.paxes.value) {
                const pax = this.service.paxes.value[index];
                if (pax.pax > 10) {
                    this.service.paxes.value[index].duration = event.data;
                }
            }
        },
        isHourSet(obj) {
            return (
                obj && obj.HH && obj.HH !== "HH" && obj.mm && obj.mm !== "mm" && !isNaN(Number.parseInt(obj.HH)) && !isNaN(Number.parseInt(obj.mm))
            );
        },
        clearErrors() {
            this.$emit("clear-errors");
        },
        displayError(error) {
            this.$emit("has-errors", error);
        },
        loadingStart() {
            this.$emit("loading-start");
        },
        loadingStop() {
            this.$emit("loading-stop");
        },
    },
};
</script>
