<template>
    <div>
        <div ref="dropdown" class="dropdown">
            <div id="dropdown-container">
                <span
                    @click="clickStatusTag"
                    class="btn btn-sm btn-outline-secondary btn-circle resa-status"
                    :class="currentClass">
                    {{ currentLabel }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import SendcloudStatusEnum from "../../../mixins/enums/click_and_collect/SendcloudStatusEnum";

export default {
    props: ["command"],
    mixins: [SendcloudStatusEnum],
    computed: {
        currentClass() {
            return this.getSendcloudStatusClass(this.command.sendcloud_status);
        },
        currentLabel() {
            return this.getSendcloudStatusLabel(this.command.sendcloud_status);
        },
    },
    methods: {
        clickStatusTag() {
            if (this.command.sendcloud_status === this.SENDCLOUD_ERROR_STATUS.value)
                window.open("https://panel.sendcloud.sc/#/shipping/list/orders/", "_blank");
        },
    },
};
</script>
