var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSeatingPlanAvailable
    ? _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "border-light b-radius-20 p-4 mb-3" }, [
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.$tl("labels.booking.widget.display.title"))
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-2" }, [
              _c("div", { staticClass: "col-md-5 col-10" }, [
                _c("label", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.widget.display.seatingPlanMemos"
                        )
                      ) +
                      "\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-7 col-2" }, [
                _c("label", { staticClass: "container-box" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value.can_display_memos,
                        expression: "value.can_display_memos",
                      },
                    ],
                    attrs: {
                      disabled: !_vm.hasRightToUpdateWidget,
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.value.can_display_memos)
                        ? _vm._i(_vm.value.can_display_memos, null) > -1
                        : _vm.value.can_display_memos,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.value.can_display_memos,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.value,
                                "can_display_memos",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.value,
                                "can_display_memos",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.value, "can_display_memos", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-2" }, [
              _c("div", { staticClass: "col-md-5 col-10" }, [
                _c("label", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.widget.display.defaultBookingView"
                        )
                      ) +
                      "\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-7 col-2" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value.default_booking_view,
                        expression: "value.default_booking_view",
                      },
                    ],
                    staticClass: "custom-select",
                    attrs: { disabled: !_vm.hasRightToUpdateWidget },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.value,
                          "default_booking_view",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.ALL_BO_BOOKING_VIEWS, function (option) {
                    return _c(
                      "option",
                      { key: option.value, domProps: { value: option.value } },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(`labels.booking.${option.value}.title`)
                            ) +
                            "\n                        "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }