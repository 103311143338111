<template>
    <div>
        <textarea :disabled="disabled" :id="txtAreaId" class="form-control" v-model="txtAreaObj[txtAreaObjKey]" :rows="txtAreaRows"></textarea>
        <div>
            <small v-if="minNbChar !== null">Min : {{ minNbChar }}</small>
            <small v-if="maxNbChar !== null">Max : {{ maxNbChar }}</small>
            <small v-if="minNbChar !== null || maxNbChar !== null" :class="'d-block font-weight-bold ' + getContentCounterClass"
                >Actuel : {{ txtAreaObj[txtAreaObjKey] !== null ? txtAreaObj[txtAreaObjKey].length : 0 }} caractères</small
            >
        </div>
    </div>
</template>

<script>
export default {
    name: "TextAreaComponent",
    props: {
        minNbChar: {
            type: Number,
            default: null,
        },
        maxNbChar: {
            type: Number,
            default: null,
        },
        txtAreaId: {
            type: String,
            default: undefined,
        },
        txtAreaObj: {
            type: Object,
            required: true,
        },
        txtAreaObjKey: {
            type: String,
            required: true,
        },
        txtAreaRows: {
            type: String,
            default: "6",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        getContentCounterClass() {
            if (this.txtAreaObj[this.txtAreaObjKey] === null) return "";
            if (
                (this.minNbChar !== null && this.minNbChar > this.txtAreaObj[this.txtAreaObjKey].length) ||
                (this.maxNbChar !== null && this.maxNbChar < this.txtAreaObj[this.txtAreaObjKey].length)
            )
                return "text-danger";
            else return "text-success";
        },
    },
};
</script>
