var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasRights
    ? _c(
        "li",
        { staticClass: "nav-item" },
        [
          _vm.isEventMode
            ? _c(
                "span",
                {
                  staticClass: "nav-link",
                  class: { "router-link-active": _vm.isActive },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("set-active")
                    },
                  },
                },
                [
                  _vm._v("\n        " + _vm._s(_vm.entryTitle)),
                  _vm.hasNewBadge
                    ? _c("newBadge", { staticClass: "ml-2" })
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "router-link",
                {
                  staticClass: "nav-link",
                  attrs: {
                    to: _vm.entry.to,
                    exact: _vm.entry.exactUrlMatching,
                  },
                },
                [
                  _vm._v("\n        " + _vm._s(_vm.entryTitle)),
                  _vm.hasNewBadge
                    ? _c("newBadge", { staticClass: "ml-2" })
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }