var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "border-light b-radius-20 p-4 mb-3" },
            [_c("show-sms-pack", { attrs: { smsPack: _vm.smsPack } })],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("show-facturation", {
        attrs: {
          getInvoiceData: { pack_id: this.smsPack.id },
          getInvoiceUrl: "/api/sms/invoice",
          openNewTab: false,
        },
        on: { paid: _vm.paid },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }