export const getters = {
    getWidget(state) {
        return state.widget;
    },
    getIsLoading(state) {
        return state.isLoading;
    },
};

export default getters;
