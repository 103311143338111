export default {
    data() {
        return {
            RESERVATION_STATUS_PENDING: {
                label: "Non enregistrée",
                value: "pending",
            },
            RESERVATION_STATUS_CREATED: {
                label: "En attente",
                value: "created",
                seatingPlanColor: "#ffc107",
                class: "btn-default",
                icon: {
                    feather: "pause",
                },
            },
            RESERVATION_STATUS_VALIDATED: {
                label: "Validée",
                value: "validated",
                seatingPlanColor: "#30a4b7",
                class: "btn-default",
                icon: {
                    feather: "clock",
                    class: "feather-blue",
                },
            },
            RESERVATION_STATUS_CANCELED: {
                label: "Annulée",
                value: "canceled",
                class: "btn-default",
                icon: {
                    feather: "x-circle",
                    class: "feather-red",
                },
            },
            RESERVATION_STATUS_REFUSED: {
                label: "Refusée",
                value: "refused",
                class: "btn-default",
            },
            RESERVATION_STATUS_OVER: {
                label: "Terminée",
                value: "over",
                seatingPlanColor: "#bdbdbd",
                class: "btn-default",
                icon: {
                    feather: "check",
                },
            },
            RESERVATION_STATUS_NOSHOW: {
                label: "No-show",
                value: "noshow",
                class: "btn-default",
                icon: {
                    feather: "alert-triangle",
                    class: "feather-orange",
                },
            },
            RESERVATION_STATUS_OPTION: {
                label: "Option",
                value: "option",
                seatingPlanColor: "#CBEBFD",
                class: "btn-default",
                icon: {
                    feather: "clock",
                    class: "feather-orange",
                },
            },
            RESERVATION_STATUS_AT_TABLE: {
                label: "À table",
                value: "at_table",
                seatingPlanColor: "#06C774",
                class: "btn-default",
                icon: {
                    html: `<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 448 512" class="main-color-svg">
                    <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc. !-->
                    <path
                        d="M416 0C400 0 288 32 288 176V288c0 35.3 28.7 64 64 64h32V480c0 17.7 14.3 32 32 32s32-14.3 32-32V352 240 32c0-17.7-14.3-32-32-32zM64 16C64 7.8 57.9 1 49.7 .1S34.2 4.6 32.4 12.5L2.1 148.8C.7 155.1 0 161.5 0 167.9c0 45.9 35.1 83.6 80 87.7V480c0 17.7 14.3 32 32 32s32-14.3 32-32V255.6c44.9-4.1 80-41.8 80-87.7c0-6.4-.7-12.8-2.1-19.1L191.6 12.5c-1.8-8-9.3-13.3-17.4-12.4S160 7.8 160 16V150.2c0 5.4-4.4 9.8-9.8 9.8c-5.1 0-9.3-3.9-9.8-9L127.9 14.6C127.2 6.3 120.3 0 112 0s-15.2 6.3-15.9 14.6L83.7 151c-.5 5.1-4.7 9-9.8 9c-5.4 0-9.8-4.4-9.8-9.8V16zm48.3 152l-.3 0-.3 0 .3-.7 .3 .7z" />
                </svg>`,
                },
            },
            RESERVATION_STATUS_CONFIRMED: {
                label: "Confirmée",
                value: "confirmed",
                seatingPlanColor: "#30a4b7",
                class: "btn-default",
                icon: {
                    feather: "clock",
                    class: "feather-green",
                },
            },
        };
    },
    methods: {
        getReservationStatusLabel(value, translateMethod = undefined, restaurant_id = undefined) {
            const found = this.ALL_RESERVATION_STATUS.find((w) => w.value == value);
            if (typeof found != "undefined") {
                if ("undefined" === typeof translateMethod) {
                    return this.$tl(`labels.booking.reservations.status.${value}`, restaurant_id);
                }
                return translateMethod(`labels.booking.reservations.status.${value}`);
            }
            return value;
        },
        getReservationSeatingPlanColor(value) {
            const found = this.ALL_RESERVATION_STATUS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.seatingPlanColor || "#000000";
            return value;
        },
        getReservationStatusClass(value) {
            const found = this.ALL_RESERVATION_STATUS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.class || undefined;
            return value;
        },
    },
    computed: {
        editableReservationStatus() {
            return [
                this.RESERVATION_STATUS_CREATED,
                this.RESERVATION_STATUS_OPTION,
                this.RESERVATION_STATUS_VALIDATED,
                this.RESERVATION_STATUS_CONFIRMED,
            ];
        },
        cancelableReservationStatus() {
            return [this.RESERVATION_STATUS_CREATED, this.RESERVATION_STATUS_OPTION, this.RESERVATION_STATUS_VALIDATED];
        },
        validReservationStatus() {
            return [
                this.RESERVATION_STATUS_CREATED,
                this.RESERVATION_STATUS_OPTION,
                this.RESERVATION_STATUS_VALIDATED,
                this.RESERVATION_STATUS_CONFIRMED,
                this.RESERVATION_STATUS_OVER,
                this.RESERVATION_STATUS_AT_TABLE,
            ];
        },
        ALL_RESERVATION_STATUS() {
            return [
                this.RESERVATION_STATUS_PENDING,
                this.RESERVATION_STATUS_CREATED,
                this.RESERVATION_STATUS_VALIDATED,
                this.RESERVATION_STATUS_CANCELED,
                this.RESERVATION_STATUS_REFUSED,
                this.RESERVATION_STATUS_OVER,
                this.RESERVATION_STATUS_NOSHOW,
                this.RESERVATION_STATUS_OPTION,
                this.RESERVATION_STATUS_AT_TABLE,
                this.RESERVATION_STATUS_CONFIRMED,
            ];
        },
    },
};
