var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-4" }, [
    _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm.currentStep > 0
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "mb-2" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$tl(
                            "labels.booking.services.recap",
                            _vm.restaurant_id
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.service.general.category.value
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "span",
                            { staticStyle: { "text-transform": "capitalize" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl("labels.booking.services.category")
                                ) +
                                  _vm._s(_vm.$tl("labels.colon")) +
                                  "\n                                "
                              ),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(
                                  _vm._s(_vm.service.general.category.value)
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.service.general.category.value === "autre"
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-5 pt-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$tl("labels.booking.services.categoryName")
                            ) +
                              " (" +
                              _vm._s(_vm.$tl("labels.langs.fr")) +
                              ")"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-7 pt-2" }, [
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.service.general.category.nameFr)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-5 pt-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$tl("labels.booking.services.categoryName")
                            ) +
                              " (" +
                              _vm._s(_vm.$tl("labels.langs.en")) +
                              ")"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-7 pt-2" }, [
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.service.general.category.nameEn)),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.service.general.name
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$tl("labels.form.name")) +
                                _vm._s(_vm.$tl("labels.colon")) +
                                "\n                                "
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.service.general.name)),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSpecial
                    ? [
                        _vm.service.general.special.openDate
                          ? _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$tl("labels.startDate")) +
                                      _vm._s(_vm.$tl("labels.colon")) +
                                      "\n                                    "
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(_vm._s(_vm.openDateFormatted)),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.service.general.special.closeDate
                          ? _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$tl("labels.endDate")) +
                                      _vm._s(_vm.$tl("labels.colon")) +
                                      "\n                                    "
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(_vm._s(_vm.closeDateFormatted)),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeDays
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "span",
                            { staticStyle: { "text-transform": "capitalize" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl("labels.booking.services.activeDays")
                                ) + _vm._s(_vm.$tl("labels.colon"))
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.activeDays) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.service.general.openHours.start
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$tl("labels.startHour")) +
                                _vm._s(_vm.$tl("labels.colon")) +
                                "\n                                "
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(_vm.service.general.openHours.start)
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.service.general.openHours.end
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$tl("labels.endHour")) +
                                _vm._s(_vm.$tl("labels.colon")) +
                                "\n                                "
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.service.general.openHours.end)),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.service.general.pax.max
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl("labels.booking.services.maxPaxShort")
                              ) +
                                _vm._s(_vm.$tl("labels.colon")) +
                                "\n                                "
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(_vm.service.general.pax.max) +
                                  " " +
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.covers",
                                      _vm.restaurant_id
                                    )
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.service.general.lock.enabled
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl("labels.booking.services.lateResa")
                              ) +
                                _vm._s(_vm.$tl("labels.colon")) +
                                "\n                                "
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(_vm.service.general.lock.until) +
                                  " " +
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.services.minsBeforeStart",
                                      _vm.restaurant_id
                                    )
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.service.general.cancellation.delay
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.services.cancelationDelay"
                                )
                              ) +
                                _vm._s(_vm.$tl("labels.colon")) +
                                "\n                                "
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(_vm.service.general.cancellation.delay) +
                                  " " +
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.services.hoursBeforeResa"
                                    )
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$tl("labels.booking.services.resasConfirm")
                          ) +
                            _vm._s(_vm.$tl("labels.colon")) +
                            "\n                                "
                        ),
                        _c("span", { staticClass: "value" }, [
                          _vm._v(
                            _vm._s(
                              _vm.service.general.autoconfirm.enabled === false
                                ? _vm.$tl("labels.manual")
                                : _vm.$tl("labels.auto")
                            )
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.service.seatingPlan.algorithm_enabled &&
                  _vm.service.seatingPlan.value
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$tl("labels.booking.seatingPlan.title")
                              ) +
                              _vm._s(_vm.$tl("labels.colon")) +
                              "\n                            "
                          ),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.service.seatingPlan.value.name)),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.service.paxes.rotation === true
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.services.enableTableRotation",
                                  _vm.restaurant_id
                                )
                              ) +
                                _vm._s(_vm.$tl("labels.colon")) +
                                "\n                                "
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.$tl("labels.yes"))),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "sepa" }),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.services.menusAndOptions.title",
                          _vm.restaurant_id
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      !_vm.$_.isEmpty(_vm.service.menus.value)
                        ? [
                            _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "span",
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.services.menusAndOptions.menus",
                                          _vm.restaurant_id
                                        )
                                      ) + _vm._s(_vm.$tl("labels.colon"))
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.selectedMenusNames,
                                      function (name, index) {
                                        return _c(
                                          "span",
                                          { staticClass: "value" },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(name) +
                                                " " +
                                                _vm._s(
                                                  index <
                                                    _vm.selectedMenusNames
                                                      .length -
                                                      1
                                                    ? "-"
                                                    : ""
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.service.menus.mustBeUnique === true
                              ? _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.menus.uniqueFrom",
                                            _vm.restaurant_id
                                          )
                                        ) +
                                          _vm._s(_vm.$tl("labels.colon")) +
                                          "\n                                        "
                                      ),
                                      _c("span", { staticClass: "value" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.service.menus.isUniqueAbovePax
                                          ) +
                                            " " +
                                            _vm._s(_vm.$tl("labels.personss"))
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$_.isEmpty(_vm.service.generalOptions.value)
                        ? _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "span",
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.generalOptions.title"
                                      )
                                    ) + _vm._s(_vm.$tl("labels.colon"))
                                  ),
                                  _c("br"),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.selectedOptionsNames,
                                    function (name, index) {
                                      return _c(
                                        "span",
                                        { staticClass: "value" },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(name) +
                                              " " +
                                              _vm._s(
                                                index <
                                                  _vm.selectedOptionsNames
                                                    .length -
                                                    1
                                                  ? "-"
                                                  : ""
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "sepa" }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.currentStep > 1 && _vm.restaurant.has_psp
            ? _c("div", [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$tl(
                        "labels.booking.services.footprintAndPayment.title"
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-2" }, [
                  _vm.service.payment.bankImprint.enabled === true
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.reservations.footprint.title"
                                )
                              ) +
                                _vm._s(_vm.$tl("labels.colon")) +
                                "\n                                    "
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.$tl("labels.yes"))),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.service.payment.bankImprint.enabledAbovePax &&
                  _vm.service.payment.bankImprint.enabled === true
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.capitalize(_vm.$tl("labels.from"))) +
                                _vm._s(_vm.$tl("labels.colon")) +
                                "\n                                    "
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.service.payment.bankImprint
                                    .enabledAbovePax
                                ) +
                                  " " +
                                  _vm._s(_vm.$tl("labels.persons"))
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.service.payment.bankImprint.amount &&
                  _vm.service.payment.bankImprint.enabled === true
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.reservations.footprint.amount"
                                )
                              ) +
                                _vm._s(_vm.$tl("labels.colon")) +
                                "\n                                    "
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(_vm.service.payment.bankImprint.amount) +
                                  " " +
                                  _vm._s(_vm.$tl("labels.euros"))
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.service.payment.prepayment.enabled
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.reservations.payment.titleBis"
                                )
                              ) +
                                _vm._s(_vm.$tl("labels.colon")) +
                                "\n                                    "
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.service.payment.prepayment.mandatory ===
                                    true
                                    ? _vm.$tl("labels.mandatory")
                                    : _vm.$tl("labels.optional")
                                )
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.service.payment.prepayment.mandatory === true
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.capitalize(_vm.$tl("labels.from"))) +
                                _vm._s(_vm.$tl("labels.colon")) +
                                "\n                                    "
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.service.payment.prepayment.enabledAbovePax
                                ) +
                                  " " +
                                  _vm._s(_vm.$tl("labels.persons"))
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.service.payment.prepayment.mandatory === true
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.reservations.payment.amountToCapture"
                                )
                              ) +
                                _vm._s(_vm.$tl("labels.colon")) +
                                "\n                                    "
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.service.payment.prepayment.percentage
                                ) + " %"
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.service.payment.prepayment.enabled ||
                  _vm.service.payment.bankImprint.enabled
                    ? _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.services.resasCancelation"
                                )
                              ) +
                                _vm._s(_vm.$tl("labels.colon")) +
                                "\n                                    "
                            ),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.service.payment.autoCancel === false
                                    ? _vm.$tl("labels.manual")
                                    : _vm.$tl("labels.auto")
                                )
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sepa" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.currentStep > 2 &&
          _vm.hasEnabledGoogleReserve &&
          _vm.isReserveWithGoogleEnabled()
            ? _c("div", [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$tl("labels.googleReserve.title"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-2 mb-2" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("span", [
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$tl("labels.googleReserve.service.enableShort")
                          ) +
                          _vm._s(_vm.$tl("labels.colon")) +
                          "\n                                "
                      ),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              `labels.${
                                _vm.service.googleReserveSettings.enabled
                                  ? "yes"
                                  : "no"
                              }`
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sepa" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.currentStep > 3
            ? _c("div", [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$tl(
                        "labels.booking.services.inCaseFull",
                        _vm.restaurant_id
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-2 mb-2" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "span",
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.services.showRestaurantService",
                              _vm.restaurant_id
                            )
                          ) + "\n                                "
                        ),
                        _vm._l(
                          _vm.selectedRestaurantProposalsNames,
                          function (name, index) {
                            return _c(
                              "span",
                              { key: name, staticClass: "value" },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(name) +
                                    " " +
                                    _vm._s(
                                      index <
                                        _vm.selectedRestaurantProposalsNames
                                          .length -
                                          1
                                        ? "-"
                                        : ""
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }