var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "default-home" }, [
    _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12 d-flex" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1",
          },
          [
            _c("h5", { staticClass: "title mt-2" }, [
              _vm._v(_vm._s(_vm.$tl("labels.restaurants"))),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "row m-0" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          _vm._l(_vm.restaurants, function (restaurant) {
            return _c("list-item", {
              key: restaurant.id,
              attrs: { restaurant: restaurant },
            })
          }),
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }