<template>
    <div class="col-lg-4 col-12" v-show="menu.nb <= menus_nb">
        <div class="row mb-2">
            <div class="col-12 mb-1">
                <label class="font-weight-bold"
                    ><span v-if="menu.title">{{ menu.title }}</span
                    ><span v-else>Menu {{ menu.nb }}</span> : <small class="text-danger">*</small></label
                >
            </div>
            <div class="col-md-4">
                <label :for="'txt-title' + suffixInputId">Titre <small class="text-danger">*</small></label>
            </div>
            <div class="col-md-8 mb-1">
                <input
                    :disabled="disabled"
                    maxlength="30"
                    type="text"
                    v-model="menu.title"
                    :id="'txt-title' + suffixInputId"
                    class="form-control" />
                <div class="text-danger" v-if="hasErrors('title')" v-html="joinErrors('title')"></div>
            </div>
            <div class="col-md-4">
                <label :for="'nb-price' + suffixInputId">Prix ({{ getRestaurantCurrency(restaurantId) }})</label>
            </div>
            <div class="col-md-8 mb-1">
                <input
                    :disabled="disabled"
                    type="number"
                    step="0.01"
                    min="0"
                    v-model.number="menu.price_in_cent"
                    :id="'nb-price' + suffixInputId"
                    class="form-control" />
                <div class="text-danger" v-if="hasErrors('price_in_cent')" v-html="joinErrors('price_in_cent')"></div>
            </div>
            <div class="col-md-12">
                <label :for="'txt-content' + suffixInputId">Description <small class="text-danger">*</small></label>
            </div>
            <div class="col-md-12 mb-1">
                <textarea
                    :disabled="disabled"
                    :id="'txt-content' + suffixInputId"
                    maxlength="100"
                    class="form-control"
                    v-model="menu.content"
                    rows="3"></textarea>
                <div class="text-danger" v-if="hasErrors('content')" v-html="joinErrors('content')"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WebsiteMenuComponent",
    props: {
        menu: {
            type: Object,
            required: true,
        },
        errors: {
            required: true,
        },
        menus_nb: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        restaurantId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        suffixInputId() {
            return "-menu-" + this.menu.nb;
        },
    },
    methods: {
        hasErrors(field) {
            if (this.errors && this.errors.menu) {
                for (let i = 0; i < this.errors.menu.length; i++) {
                    const error = this.errors.menu[i];
                    if (error.nb === this.menu.nb && Object.keys(error.errors).includes(field)) return true;
                }
            }
            return false;
        },
        joinErrors(field) {
            if (this.errors && this.errors.menu) {
                for (let i = 0; i < this.errors.menu.length; i++) {
                    const error = this.errors.menu[i];
                    if (error.nb === this.menu.nb && Object.keys(error.errors).includes(field))
                        return error.errors[field].join("<br/>");
                }
            }
            return "";
        },
    },
};
</script>

<style scoped>
div.col-lg-4 small.asterix-float {
    position: absolute;
    float: right;
    margin-left: 0.25rem;
}
</style>
