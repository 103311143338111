<template>
    <div class="w-100">
        <loader-component v-if="loading" />
        <div v-else-if="error">
            {{ error }}
        </div>
        <div class="default-home" v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">{{ $tl("labels.routes.rwg") }}</h5>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <restaurant-card
                    :clickable="canEditRwg(restaurant.id)"
                    v-tooltip="cantEditRwgTooltip(restaurant.id)"
                    @click="contractRwg(restaurant)"
                    v-for="restaurant in restaurants"
                    :key="restaurant.id"
                    :restaurant="restaurant"
                    class="col-md-4 mb-3" />
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import RolesEnum from "../../../mixins/enums/RolesEnum.js";
import RestaurantCard from "./RestaurantCard.vue";

export default {
    data() {
        return {
            error: null,
            loading: false,
            restaurants: [],
        };
    },
    mixins: [RolesEnum],
    computed: {
        isOwner() {
            return this.$store.getters["users/role"] === this.ROLE_OWNER.value;
        },
    },
    methods: {
        cantEditRwgTooltip(restaurantId) {
            if (this.canEditRwg(restaurantId)) {
                return undefined;
            }

            return this.getTooltip(this.$tl("infos.booking.googleReserve.forRestaurantsOnly", restaurantId), { triggers: ["click", "hover"] });
        },
        canEditRwg(restaurantId) {
            const restaurant = this.$store.getters["restaurants/findRestaurantById"](restaurantId);

            return this.isOwner && typeof restaurant !== "undefined" && restaurant.feat_reserve_with_google;
        },
        fetchRestaurants() {
            this.error = null;
            this.loading = true;
            this.restaurants = [];

            this.$store
                .dispatch("restaurants/fetchAllRestaurants")
                .then((response) => {
                    this.restaurants = response.data.data;
                    if (this.isClubMedOwnerId(this.$store.getters["users/ownerId"])) {
                        this.restaurants.sort((a, b) => a.name.localeCompare(b.name));
                    }
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => (this.loading = false));
        },
        contractRwg(restaurant) {
            if (!this.canEditRwg(restaurant.id)) {
                return;
            }

            this.$router.push({ name: "restaurants.reserve_with_google.edit", params: { restaurant_id: restaurant.id } });
        },
    },
    created() {
        if (!this.isReserveWithGoogleEnabled()) {
            console.error("Reserve with Google is not available");
            return;
        }

        this.fetchRestaurants();
    },
    components: {
        LoaderComponent,
        RestaurantCard,
    },
};
</script>
