var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    { staticClass: "modal-add-zone", on: { close: _vm.closeFromModal } },
    [
      _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$tl("labels.areasEstablishments.createAreaTitle")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "row mb-4" }, [
          _c("div", { staticClass: "col-5" }, [
            _c("label", { attrs: { for: "name" } }, [
              _vm._v(
                _vm._s(_vm.$tl("labels.areasEstablishments.areaName")) + " "
              ),
              _c("small", [_vm._v("*")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.zoneName,
                  expression: "zoneName",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", name: "name", id: "name", required: "" },
              domProps: { value: _vm.zoneName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.zoneName = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "container col-12" },
            [
              _c("restaurant-list", {
                attrs: {
                  restaurants: _vm.restaurants,
                  selectedRestaurants: _vm.selectedRestaurants,
                },
                on: {
                  "update:selected-restaurants": _vm.updateSelectedRestaurants,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-secondary btn-circle",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$emit("close")
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary btn-circle ml-2",
            attrs: { type: "button" },
            on: { click: _vm.createZone },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.areasEstablishments.create")) +
                "\n        "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }