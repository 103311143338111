<template>
    <div class="row m-0" v-if="isSeatingPlanAvailable">
        <div class="col-12">
            <div class="border-light b-radius-20 p-4 mb-3">
                <div class="row mb-3">
                    <div class="col-12">
                        <strong>{{ $tl("labels.booking.widget.display.title") }}</strong>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-5 col-10">
                        <label>
                            {{ $tl("labels.booking.widget.display.seatingPlanMemos") }}
                        </label>
                    </div>
                    <div class="col-md-7 col-2">
                        <label class="container-box">
                            <input :disabled="!hasRightToUpdateWidget" type="checkbox" v-model="value.can_display_memos" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-5 col-10">
                        <label>
                            {{ $tl("labels.booking.widget.display.defaultBookingView") }}
                        </label>
                    </div>
                    <div class="col-md-7 col-2">
                        <select :disabled="!hasRightToUpdateWidget" v-model="value.default_booking_view" class="custom-select">
                            <option v-for="option in ALL_BO_BOOKING_VIEWS" :value="option.value" :key="option.value">
                                {{ $t(`labels.booking.${option.value}.title`) }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BoBookingViewEnum from "../../../../mixins/enums/BoBookingViewEnum.js";

export default {
    mixins: [BoBookingViewEnum],
    props: {
        value: {
            type: Object,
            required: true,
        },
        isSeatingPlanAvailable: {
            type: Boolean,
            required: true,
        },
        hasRightToUpdateWidget: {
            type: Boolean,
            required: true,
        },
    },
};
</script>
