var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.restaurantsToSuggest.length > 0
    ? _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$tl(
                        "labels.booking.services.inCaseFull",
                        _vm.restaurant_id
                      )
                    )
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "menu-option mt-3" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-7" }, [
                  _c(
                    "label",
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.services.showRestaurantService",
                              _vm.restaurant_id
                            )
                          ) +
                          "\n                            "
                      ),
                      _c("feather", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getTooltipNotice(
                              "notices.booking.servicesConfig.restaurantProposal"
                            ),
                            expression:
                              "getTooltipNotice('notices.booking.servicesConfig.restaurantProposal')",
                          },
                        ],
                        staticClass: "ml-1 pointer tooltip-infos",
                        attrs: { type: "info" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-5" }, [
                  _c(
                    "div",
                    _vm._l(_vm.restaurantsToSuggest, function (restau) {
                      return _c(
                        "div",
                        { key: restau.id, staticClass: "mb-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "container-box",
                              staticStyle: {
                                "margin-bottom": "0",
                                width: "100%",
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.service.full.restaurantsProposals,
                                    expression:
                                      "service.full.restaurantsProposals",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  name: "alternative_restaurants[]",
                                },
                                domProps: {
                                  value: restau.id,
                                  checked: Array.isArray(
                                    _vm.service.full.restaurantsProposals
                                  )
                                    ? _vm._i(
                                        _vm.service.full.restaurantsProposals,
                                        restau.id
                                      ) > -1
                                    : _vm.service.full.restaurantsProposals,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.service.full.restaurantsProposals,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = restau.id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.service.full,
                                            "restaurantsProposals",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.service.full,
                                            "restaurantsProposals",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.service.full,
                                        "restaurantsProposals",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkmark" }),
                              _vm._v(
                                " " +
                                  _vm._s(restau.name) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }