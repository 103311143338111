var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100" },
    [
      !_vm.isPublic && _vm.isBrowserInvalid
        ? _c(
            "div",
            { staticClass: "h-100 d-flex align-items-center text-center" },
            [
              _c("div", { staticStyle: { margin: "auto" } }, [
                _c("h1", [_vm._v("Navigateur non supporté")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                Votre navigateur n'est pas compatible avec le logiciel " +
                      _vm._s(_vm.isNoShow ? "NoShow" : "Y-Services") +
                      ", veuillez utiliser\n                "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://www.google.com/intl/fr_fr/chrome/",
                      },
                    },
                    [_vm._v("Google Chrome")]
                  ),
                  _vm._v(" ou\n                "),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://www.mozilla.org/fr/",
                      },
                    },
                    [_vm._v("Mozilla Firefox")]
                  ),
                  _vm._v(".\n            "),
                ]),
              ]),
            ]
          )
        : _vm.isPublic || (_vm.user && _vm.restaurants)
        ? _c(
            _vm.layout,
            {
              tag: "component",
              attrs: { restaurants: _vm.restaurants, user: _vm.user },
              on: {
                "reload-router-view": function ($event) {
                  _vm.currentRouterViewKey++
                },
              },
            },
            [
              !_vm.isPublic
                ? [
                    _vm.cardError
                      ? _c("CardsAlertHeader", {
                          attrs: { type: _vm.cardError },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.subscriptionsWithIssues.length > 0
                      ? _c("SubscriptionAlertHeader")
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasUnreadSeatingPlanNotifications &&
                    _vm.layout === "booking-layout"
                      ? _vm._l(
                          _vm.unreadSeatingPlanNotifications,
                          function (notification) {
                            return _c("NoSeatingPlanRoomAlertHeader", {
                              key: notification.id,
                              attrs: {
                                "unread-seating-plan-notifications":
                                  notification,
                              },
                            })
                          }
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.loading
                      ? _c("LoaderComponent", { staticClass: "main-loader" })
                      : _c(
                          "router-view",
                          _vm._b(
                            {
                              key: _vm.currentRouterViewKey,
                              staticClass: "h-100",
                            },
                            "router-view",
                            { restaurants: _vm.restaurants, user: _vm.user },
                            false
                          )
                        ),
                    _vm._v(" "),
                    _vm.showSubscriptionsAlert
                      ? _c("SubscriptionAlert", {
                          attrs: { subscriptions: _vm.subscriptionsWithIssues },
                          on: {
                            close: function ($event) {
                              _vm.showSubscriptionsAlert = false
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showMultipleCardsAlert
                      ? _c("MultipleCardsAlert", {
                          on: {
                            close: function ($event) {
                              _vm.showMultipleCardsAlert = false
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.newFeatures, function (newFeature) {
                      return [
                        newFeature.show
                          ? _c("showNewFeatureModal", {
                              key: newFeature.id,
                              attrs: { new_feature_id: newFeature.id },
                              on: {
                                hide: function ($event) {
                                  return _vm.$set(newFeature, "show", false)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    }),
                    _vm._v(" "),
                    _vm.showAcceptCgvModal
                      ? _c("AcceptDefaultCgvModal", {
                          on: {
                            close: function ($event) {
                              _vm.showAcceptCgvModal = false
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isDevelopement()
        ? _c("div", { staticClass: "dev-mode shadow" }, [
            _vm._v("Développement"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }