var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-5 pb-5" },
    [
      _vm.isLoading
        ? _c("LoaderComponent")
        : _c(
            "div",
            [
              _c("div", { staticClass: "row m-0" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                    },
                    [
                      _c("h5", { staticClass: "title mt-2" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$tl("labels.routes.booking.config.interfaces")
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row m-0" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4 mb-3" },
                    [
                      _c("div", { staticClass: "row mb-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("strong", [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.interfaces.restaurantIdTitle"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-md-5 pt-2" }, [
                          _c("label", [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.interfaces.restaurantId"
                                  )
                                ) +
                                "\n                                "
                            ),
                            _c("small", [_vm._v("*")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c("CopyToClipboard", {
                                  attrs: {
                                    textToCopy: String(_vm.restaurantId),
                                    element: _vm.$t("labels.identifier"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-md-5 pt-2" }, [
                          _c("label", [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.interfaces.restaurantSecret"
                                  )
                                ) +
                                "\n                                "
                            ),
                            _c("small", [_vm._v("*")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c("CopyToClipboard", {
                                  attrs: {
                                    textToCopy: _vm.SHA1_RESTAURANT_ID,
                                    element: _vm.$t("labels.identifier"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.publicApiClients, function (publicApiClient) {
                return _c(
                  "div",
                  { key: publicApiClient.value, staticClass: "row m-0" },
                  [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4 mb-3" },
                        [
                          _c("div", { staticClass: "row mb-3" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("strong", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "labels.booking.interfaces.ownerPermissionsTitle",
                                          {
                                            name: _vm.getPublicApiClientLabel(
                                              publicApiClient.value
                                            ),
                                          }
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                publicApiClient.notice
                                  ? _c("feather", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltipNotice(
                                            publicApiClient.notice
                                          ),
                                          expression:
                                            "getTooltipNotice(publicApiClient.notice)",
                                        },
                                      ],
                                      staticClass: "ml-1 pointer tooltip-infos",
                                      attrs: { type: "info" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col-md-5 pt-2" }, [
                              _c("label", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "labels.booking.interfaces.ownerPermissions",
                                        {
                                          name: _vm.getPublicApiClientLabel(
                                            publicApiClient.value
                                          ),
                                        }
                                      )
                                    ) +
                                    "\n                                "
                                ),
                                _c("small", [_vm._v("*")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-7" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-inline-block align-middle lead-switch",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.isConsented[
                                            publicApiClient.value
                                          ],
                                        expression:
                                          "isConsented[publicApiClient.value]",
                                      },
                                    ],
                                    staticClass:
                                      "switch align-self-center is-rounded",
                                    attrs: {
                                      type: "checkbox",
                                      disabled: !_vm.isOwner,
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.isConsented[publicApiClient.value]
                                      )
                                        ? _vm._i(
                                            _vm.isConsented[
                                              publicApiClient.value
                                            ],
                                            null
                                          ) > -1
                                        : _vm.isConsented[
                                            publicApiClient.value
                                          ],
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.isConsented[
                                              publicApiClient.value
                                            ],
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.isConsented,
                                                publicApiClient.value,
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.isConsented,
                                                publicApiClient.value,
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.isConsented,
                                            publicApiClient.value,
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", {
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeConsentment(
                                          publicApiClient.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }