<template>
    <div v-if="title !== ``" class="notifications-card" @click="onNotifClicked" :style="isClickable ? 'cursor:pointer !important;' : ''">
        <div class="notifications-header d-flex w-100 justify-content-between" :class="customClass">
            <div class="notifications-title" v-html="title"></div>
            <div>
                <div class="notifications-time">
                    <small>{{ createdAt }}</small>
                </div>
                <div class="notifications-close" @click="$emit('read', { id: notification.id, index })">
                    <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="css-i6dzq1 feather">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </div>
            </div>
        </div>
        <div class="notifications-content">
            <div class="notifications-title" v-if="!restaurant_id" v-html="restaurantName" />
            <template v-if="notification.module_type === 'booking'">
                <template v-if="notification.type === 'App\\Notifications\\RestauResaAllPlaced'">
                    {{ $tl("labels.notifications.booking.restauResaAllPlaced.content") }}
                </template>
                <template v-else-if="notification.type === 'App\\Notifications\\Restaurant\\SeatingPlanRoomDisabled'">
                    {{
                        $tl("labels.notifications.booking.seatingPlanRoomDisabled.content", undefined, {
                            seatingPlan: notification.data.seatingPlanName,
                        })
                    }}
                    <br />
                    <a :href="`/booking/restaurants/${notification.restaurant_id}/settings/seating-plan/${notification.data.seatingPlanId}`">
                        {{ $tl("labels.notifications.booking.seatingPlanRoomDisabled.link") }}
                    </a>
                </template>
                <template v-else-if="notification.type === 'App\\Notifications\\RestauResaNotPlaced'">
                    <span v-if="nbReservations > 1">{{
                        $tcl("labels.notifications.booking.restauResaNotPlaced.content", nbReservations, restaurant_id, {
                            date: reservationsDate,
                        })
                    }}</span>
                    <router-link
                        v-else
                        :to="{
                            name: 'booking.restaurants.reservations',
                            params: { restaurant_id: $route.params.restaurant_id },
                            query: { date: notification.data.reservationsDate },
                        }">
                        <span>
                            {{
                                $tcl("labels.notifications.booking.restauResaNotPlaced.content", nbReservations, restaurant_id, {
                                    date: reservationsDate,
                                })
                            }}
                        </span>
                    </router-link>
                </template>
                <template v-else>
                    {{ full_name }} - {{ nb_pers_total }} {{ $tl("labels.persons") }}<br />
                    {{ displayDate(reservation_datetime, DATETIME_FULL) }}<br />
                    <div class="text-muted morecontent" v-if="this.notification.data.comment">
                        <span
                            ><small>{{ comment }}</small></span
                        >
                        <a
                            href="#"
                            :class="{ less: readMore.moreToggled }"
                            v-if="this.notification.data.comment.length > this.readMore.showchar"
                            class="morelink"
                            @click="toggleMore"
                            @mousedown="$event.preventDefault()">
                            {{ morelinkContent }}
                        </a>
                    </div>
                </template>
                <div
                    v-if="
                        hasMultipleReservationChannels &&
                        ['App\\Notifications\\RestauResaCreated', 'App\\Notifications\\RestauResaValidated'].includes(notification.type)
                    "
                    class="d-flex my-2">
                    <reservation-issuer-icon
                        :issuer="notification.data.created_from"
                        :provider="notification.data.provider_name"
                        :created-at="notification.data.created_at"
                        class="notification-icon" />
                    <p class="ml-1 mb-0">{{ getLabelReservationFrom(notification.data.created_from, notification.data.provider_name) }}</p>
                </div>
            </template>
            <template v-if="notification.module_type === 'click-and-collect'">
                {{ full_name }} - {{ total_amount }} <br />
                {{ reservation_date }} - {{ slot_hour || "Expédition" }}<br />
                <div class="text-muted morecontent" v-if="this.notification.data.comment">
                    <span
                        ><small>{{ comment }}</small></span
                    >
                    <a
                        href="#"
                        :class="{ less: readMore.moreToggled }"
                        v-if="this.notification.data.comment.length > this.readMore.showchar"
                        class="morelink"
                        @click="toggleMore"
                        @mousedown="$event.preventDefault()">
                        {{ morelinkContent }}
                    </a>
                </div>
            </template>
            <template v-if="notification.module_type === 'gift-vouchers'">
                {{ full_name }} - {{ amount }} <br />
                {{ paid_at }}<br />
                <div class="text-muted morecontent" v-if="this.notification.data.comment_public">
                    <span
                        ><small>{{ comment_public }}</small></span
                    >
                    <a
                        href="#"
                        :class="{ less: readMore.moreToggled }"
                        v-if="this.notification.data.comment_public.length > this.readMore.showchar"
                        class="morelink"
                        @click="toggleMore"
                        @mousedown="$event.preventDefault()">
                        {{ morelinkContent }}
                    </a>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import RolesEnum from "../../mixins/enums/RolesEnum";
import reservationIssuerIcon from "../reservations/reservationIssuerIcon.vue";
import ReservationIssuerEnum from "../../mixins/enums/booking/ReservationIssuerEnum.js";

moment.locale("fr");

export default {
    data() {
        return {};
    },
    mixins: [RolesEnum, ReservationIssuerEnum],
    props: {
        notification: {
            required: true,
        },
        index: {
            required: true,
        },
    },
    computed: {
        readMore() {
            return {
                ellipsestext: "...",
                moretext: this.$tl("labels.showMore"),
                lesstext: this.$tl("labels.showLess"),
                showchar: 40,
                moreToggled: false,
            };
        },
        isCourier() {
            return this.$store.getters["users/role"] === this.ROLE_COURIER.value;
        },
        isClickable() {
            const clickableBookingNotif = [
                "App\\Notifications\\RestauResaCreated",
                "App\\Notifications\\RestauResaValidated",
                "App\\Notifications\\RestauResaUpdated",
                "App\\Notifications\\RestauResaCanceled",
                "App\\Notifications\\RestauOptionBankCanceled",
            ];
            const clickableClickAndCollectNotif = ["App\\Notifications\\RestaurantOrderConfirmed"];
            const clickableGiftVouchersNotif = ["App\\Notifications\\RestaurantPaymentReceipt"];
            if (this.isCourier) return false;
            if (this.notification.module_type === "booking") return clickableBookingNotif.includes(this.notification.type);
            if (this.notification.module_type === "click-and-collect") {
                return clickableClickAndCollectNotif.includes(this.notification.type);
            }
            if (this.notification.module_type === "gift-vouchers") return clickableGiftVouchersNotif.includes(this.notification.type);
            return false;
        },
        customClass() {
            switch (this.notification.type) {
                case "App\\Notifications\\RestauResaCreated":
                    return "notifications-created";
                case "App\\Notifications\\RestauResaValidated":
                    return "notifications-new";
                case "App\\Notifications\\RestauResaUpdated":
                    return "notifications-updated";
                case "App\\Notifications\\RestauResaCanceled":
                    return "notifications-refused";
                case "App\\Notifications\\RestauOptionBankCanceled":
                    return "notifications-refused";
                case "App\\Notifications\\RestaurantOrderConfirmed":
                    if (this.notification.data.status == "to_accept") return "notifications-created";
                    return "notifications-new";
                case "App\\Notifications\\RestaurantPaymentReceipt":
                    return "notifications-new";
                case "App\\Notifications\\RestauResaAllPlaced":
                    return "notifications-new";
                case "App\\Notifications\\Restaurant\\SeatingPlanRoomDisabled":
                    return "notifications-refused";
                case "App\\Notifications\\RestauResaNotPlaced":
                    return "notifications-refused";
                default:
                    return "";
            }
        },
        title() {
            switch (this.notification.type) {
                case "App\\Notifications\\Restaurant\\SeatingPlanRoomDisabled":
                    return `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather">
                        <path
                            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                        <line x1="12" y1="9" x2="12" y2="13"></line>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg> ${this.$tl("labels.notifications.booking.seatingPlanRoomDisabled.title")}`;
                case "App\\Notifications\\RestauResaAllPlaced":
                    return this.$tl("labels.notifications.booking.restauResaAllPlaced.title");
                case "App\\Notifications\\RestauResaNotPlaced":
                    return this.$tl("labels.notifications.booking.restauResaNotPlaced.title");
                case "App\\Notifications\\RestauResaCreated":
                    return this.$tl("labels.notifications.booking.createdShort");
                case "App\\Notifications\\RestauResaValidated":
                    return this.$tl("labels.notifications.booking.validated");
                case "App\\Notifications\\RestauResaUpdated":
                    return this.$tl("labels.notifications.booking.updated");
                case "App\\Notifications\\RestauResaCanceled":
                    return this.$tl("labels.notifications.booking.canceled");
                case "App\\Notifications\\RestaurantOrderConfirmed":
                    if (this.notification.data.status == "to_accept") return "Commande en attente";
                    return "Nouvelle commande";
                case "App\\Notifications\\RestaurantPaymentReceipt":
                    return "Nouvelle vente";
                case "App\\Notifications\\RestauOptionBankCanceled":
                    if (this.notification.data.option_bank === "payment") {
                        return `<svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="feather css-i6dzq1"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg> ${this.$tl(
                            "labels.notifications.booking.delayExpired"
                        )}`;
                    } else if (this.notification.data.option_bank === "footprint") {
                        return `<svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="feather css-i6dzq1"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg> ${this.$tl(
                            "labels.notifications.booking.delayExpired"
                        )}`;
                    } else {
                        return ``;
                    }
                default:
                    return ``;
            }
        },
        createdAt() {
            const createdAt = this.getDateTime(this.notification.created_at);
            if (createdAt.diffNow("seconds").seconds >= -60) {
                return this.$tl("labels.now");
            }
            return createdAt.toRelative();
        },
        nb_pers_total() {
            const nb_pers = Number.parseInt(this.notification.data.nb_pers);
            const nb_children = Number.parseInt(this.notification.data.nb_children);
            return nb_pers + nb_children;
        },
        full_name() {
            let firstname = "";
            if (this.notification.data.client && this.notification.data.client.firstname) {
                firstname = this.capitalize(this.notification.data.client.firstname);
            }

            let lastname = "";
            if (this.notification.data.client && this.notification.data.client.lastname) {
                lastname = this.capitalize(this.notification.data.client.lastname);
            }

            return `${firstname} ${lastname}`;
        },
        reservation_datetime() {
            if (this.notification.data.reservation_datetime) {
                return this.getDateTime(this.notification.data.reservation_datetime);
            }

            return this.setHourOnDateTime(this.notification.data.slot.hour_start, this.getDateTime(this.notification.data.reservation_date, false));
        },
        reservation_date() {
            return moment(this.notification.data.reservation_date).format("Do MMMM YYYY");
        },
        hour_start() {
            const timeArray = this.notification.data.slot.hour_start.split(":");

            return `${timeArray[0]}h${timeArray[1]}`;
        },
        slot_hour() {
            if (!this.notification.data.slot) return "";
            if (this.notification.data.slot.hour_start && this.notification.data.slot.hour_end)
                return (
                    this.notification.data.slot.hour_start.replace(":", "h").substr(0, 5) +
                    " - " +
                    this.notification.data.slot.hour_end.replace(":", "h").substr(0, 5)
                );
            else if (this.notification.data.slot.hour) return this.notification.data.slot.hour.replace(":", "h").substr(0, 5);
            return "";
        },
        comment() {
            if (this.notification.data.comment) {
                if (this.notification.data.comment.length > this.readMore.showchar) {
                    if (this.readMore.moreToggled) {
                        return this.notification.data.comment;
                    } else {
                        return `${this.notification.data.comment.substr(0, this.readMore.showchar).trim()}...`;
                    }
                } else {
                    return this.notification.data.comment;
                }
            }

            return "";
        },
        comment_public() {
            if (this.notification.data.comment_public) {
                if (this.notification.data.comment_public.length > this.readMore.showchar) {
                    if (this.readMore.moreToggled) {
                        return this.notification.data.comment_public;
                    } else {
                        return `${this.notification.data.comment_public.substr(0, this.readMore.showchar).trim()}...`;
                    }
                } else {
                    return this.notification.data.comment_public;
                }
            }

            return "";
        },
        morelinkContent() {
            if (this.readMore.moreToggled) {
                return this.readMore.lesstext;
            } else {
                return this.readMore.moretext;
            }
        },
        total_amount() {
            return this.formatCurrency(this.notification.data.total_amount / 100);
        },
        amount() {
            return this.formatCurrency(this.notification.data.amount);
        },
        paid_at() {
            return moment(this.notification.data.paid_at).format("Do MMMM YYYY");
        },
        reservationsDate() {
            return this.notification.data.reservationsDate;
        },
        nbReservations() {
            return this.notification.data.nbReservations;
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        restaurant() {
            const id = this.restaurant_id ? this.restaurant_id : this.notification.restaurant_id;

            return this.$store.getters["restaurants/findRestaurantById"](id);
        },
        restaurantName() {
            return this.restaurant ? this.restaurant.name : "";
        },
        hasMultipleReservationChannels() {
            return this.restaurant ? this.restaurant.has_multiple_reservation_channels : true;
        },
    },
    methods: {
        toggleMore(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();

            this.readMore.moreToggled = !this.readMore.moreToggled;
            return false;
        },
        onNotifClicked(e) {
            if (!this.isClickable) return;
            const invalidClick = (elem) => elem && elem.classList.contains("notifications-close");
            const nodes = [e.target, e.target.parentNode, e.target.parentNode ? e.target.parentNode.parentNode : null];
            if (nodes.some(invalidClick)) return;
            if (
                this.notification.module_type === "booking" &&
                (!this.$route.query.reservation_id || this.$route.query.reservation_id != this.notification.data.id)
            ) {
                this.$router.push({
                    name: "booking.restaurants.reservations",
                    params: { restaurant_id: this.notification.restaurant_id },
                    query: { date: this.reservation_datetime.toISODate(), reservation_id: this.notification.data.id },
                });
                this.$emit("close");
            } else if (
                this.notification.module_type === "click-and-collect" &&
                (!this.$route.query.cc_command_id || this.$route.query.cc_command_id != this.notification.data.id)
            ) {
                if (this.notification.data.status === "to_accept") {
                    this.$router.push({
                        name: "click_and_collect.restaurants.sales_waiting_confirmation",
                        params: { restaurant_id: this.notification.restaurant_id },
                        query: {
                            date: this.notification.data.reservation_date,
                            cc_command_id: this.notification.data.id,
                        },
                    });
                } else {
                    if (this.isCourier) {
                        this.$router.push({
                            to: "/",
                            query: {
                                date: this.notification.data.reservation_date,
                                cc_command_id: this.notification.data.id,
                                restaurant_id: this.notification.data.restaurant_id,
                            },
                        });
                    } else {
                        this.$router.push({
                            name: "click_and_collect.restaurants.sales." + this.notification.data.collect_type,
                            params: { restaurant_id: this.notification.restaurant_id },
                            query: {
                                date: this.notification.data.reservation_date,
                                cc_command_id: this.notification.data.id,
                            },
                        });
                    }
                }
                this.$emit("close");
            } else if (
                this.notification.module_type === "gift-vouchers" &&
                (!this.$route.query.gv_sale_id || this.$route.query.gv_sale_id != this.notification.data.id)
            ) {
                this.$router.push({
                    name: "gift_vouchers.restaurants.sales",
                    params: { restaurant_id: this.notification.restaurant_id },
                    query: { gv_sale_id: this.notification.data.id },
                });
                this.$emit("close");
            }
        },
    },
    components: {
        reservationIssuerIcon,
    },
};
</script>

<style>
.notification-icon > span {
    width: 1rem;
    height: 1rem;
}
</style>
