var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.rights.includes("booking.booking.create")
      ? _c(
          "button",
          {
            staticClass: "btn p-0 m-1 feather-blue",
            attrs: { type: "button" },
            on: { click: _vm.openAddReservationModal },
          },
          [
            _c(
              "svg",
              {
                staticClass: "feather feather-24 feather-plus-circle",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "24",
                  height: "24",
                  viewBox: "0 0 24 24",
                  fill: "none",
                  stroke: "currentColor",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              },
              [
                _c("circle", { attrs: { cx: "12", cy: "12", r: "10" } }),
                _vm._v(" "),
                _c("line", {
                  attrs: { x1: "12", y1: "8", x2: "12", y2: "16" },
                }),
                _vm._v(" "),
                _c("line", {
                  attrs: { x1: "8", y1: "12", x2: "16", y2: "12" },
                }),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.is_full === -1
      ? _c(
          "button",
          {
            staticClass: "btn p-0 m-1 feather-red",
            attrs: { type: "button" },
            on: { click: _vm.setSlotFullModal },
          },
          [
            _c(
              "svg",
              {
                staticClass: "feather feather-24 feather-pause-circle",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "24",
                  height: "24",
                  viewBox: "0 0 24 24",
                  fill: "none",
                  stroke: "currentColor",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              },
              [
                _c("circle", { attrs: { cx: "12", cy: "12", r: "10" } }),
                _vm._v(" "),
                _c("line", {
                  attrs: { x1: "10", y1: "15", x2: "10", y2: "9" },
                }),
                _vm._v(" "),
                _c("line", {
                  attrs: { x1: "14", y1: "15", x2: "14", y2: "9" },
                }),
              ]
            ),
          ]
        )
      : _c(
          "button",
          {
            staticClass: "btn p-0 m-1 feather-blue",
            attrs: { type: "button" },
            on: { click: _vm.removeSlotFullModal },
          },
          [
            _c(
              "svg",
              {
                staticClass: "feather feather-24 feather-play-circle",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "24",
                  height: "24",
                  viewBox: "0 0 24 24",
                  fill: "none",
                  stroke: "currentColor",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              },
              [
                _c("circle", { attrs: { cx: "12", cy: "12", r: "10" } }),
                _vm._v(" "),
                _c("polygon", { attrs: { points: "10 8 16 12 10 16 10 8" } }),
              ]
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }