<template>
    <div :class="computeClasses('wrapper')">
        <div v-if="!removeLabelPart" :class="computeClasses('labelWrapper')">
            <label>
                <template v-if="label !== null">{{ label }}</template>
                <slot v-else name="label"></slot>
            </label>
            <feather v-if="notice" v-tooltip="getTooltipNotice(notice)" type="info" class="ml-1 pointer tooltip-infos" />
        </div>
        <div :class="computeClasses('inputWrapper')">
            <input
                ref="input"
                :name="inputName"
                type="checkbox"
                v-model="value_"
                :class="computeClasses('input')"
                :disabled="disabled"
                :required="required"
                :data-test-id="dataTestId_" />
            <label @click="toggleValue" class="m-0" v-tooltip="switchTooltip"></label>
            <ShowErrors v-if="inputName" class="d-block" :errors="formErrors" :errorKey="inputName" />
        </div>
    </div>
</template>

<script>
import ShowErrors from "../errors/ShowErrors.vue";

export default {
    name: "SwitchInputComponent",
    data() {
        return {
            defaultInputClasses: "switch align-self-center is-rounded",
        };
    },
    props: {
        /**
         *  - wrapperClasses: Object|Array|String
         *  - labelWrapperClasses: Object|Array|String
         *  - inputWrapperClasses: Object|Array|String
         *  - inputClasses: Object|Array|String
         */
        clickEventAlterations: {
            type: Array,
            default: () => [],
        },
        formErrors: {
            default: null,
        },
        value: {
            default: undefined,
        },
        label: {
            type: String,
            default: null,
        },
        wrapperClasses: {
            default: undefined,
        },
        labelWrapperClasses: {
            default: undefined,
        },
        inputWrapperClasses: {
            default: undefined,
        },
        inputClasses: {
            default: undefined,
        },
        inputName: {
            type: String,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        notice: {
            type: String,
            default: undefined,
        },
        dataTestId: {
            type: String,
            default: undefined,
        },
        isFormGrid: {
            type: Boolean,
            default: true,
        },
        switchTooltip: {
            default: undefined,
        },
        required: {
            type: Boolean,
            default: false,
        },
        removeLabelPart: {
            type: Boolean,
            default: false,
        },
        withoutBaseClass: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        value_: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        dataTestId_() {
            if (this.dataTestId !== undefined) {
                return this.dataTestId;
            }

            if (this.inputName !== undefined) {
                return `input-chk-${this.inputName}`;
            }

            return undefined;
        },
        defaultWrapperClasses() {
            const cssClass = this.isFormGrid ? "row mb-2" : "row col-12";
            return !this.withoutBaseClass ? cssClass : "";
        },
        defaultLabelWrapperClasses() {
            const cssClass = this.isFormGrid ? "col-md-5 pt-2" : "pt-2";
            return !this.withoutBaseClass ? cssClass : "";
        },
        defaultInputWrapperClasses() {
            const cssClass = this.isFormGrid ? "col-md-7 lead-switch" : "ml-2 lead-switch";
            return !this.withoutBaseClass ? cssClass : "lead-switch";
        },
    },
    methods: {
        toggleValue(event) {
            if (this.clickEventAlterations.length) {
                for (const clickEvent of this.clickEventAlterations) {
                    clickEvent(event);
                }
            }
            if (this.disabled) {
                return;
            }

            if (!this.value_) {
                this.value_ = true;
            } else {
                this.value_ = false;
            }
        },
        convertClassToObject(classes) {
            let newClasses = {};

            if (typeof classes === "string") {
                classes.split(" ").forEach((tmpClass) => (newClasses[tmpClass] = true));
            } else if (Array.isArray(classes)) {
                classes.forEach((tmpClass) => (newClasses[tmpClass] = true));
            } else if (typeof classes === "object") {
                newClasses = classes;
            }

            return newClasses;
        },
        computeClasses(classesType) {
            return {
                ...this.convertClassToObject(this[`default${this.capitalize(classesType)}Classes`]),
                ...this.convertClassToObject(this[`${classesType}Classes`]),
            };
        },
    },
    components: {
        ShowErrors,
    },
};
</script>
