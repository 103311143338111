<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.booking.closures.title") }}</h5>
        <div slot="body">
            <div class="row" v-if="step === 1">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <p>
                            {{ $tl("infos.booking.reservations.confirmClosureCreate") }}
                        </p>
                        <LoaderComponent v-if="loading > 0" />
                        <div v-else>
                            <div v-if="error" class="alert alert-danger">
                                {{ error }}
                            </div>
                            <div v-else>
                                <table class="table table-book table-striped table-sm">
                                    <thead class="border-bottom">
                                        <tr>
                                            <th>
                                                <feather type="user" class="none-desk feather-20" />
                                                <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.client") }}</span>
                                            </th>
                                            <th>
                                                <feather type="users" class="none-desk feather-20" />
                                                <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.pax") }}</span>
                                            </th>
                                            <th>
                                                <feather type="clock" class="none-desk feather-20" />
                                                <span class="none-mobile">{{ $tl("labels.form.date") }}</span>
                                            </th>
                                            <th>
                                                <feather type="clock" class="none-desk feather-20" />
                                                <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.hour") }}</span>
                                            </th>
                                            <th>
                                                <feather type="monitor" class="none-desk feather-20" />
                                                <span class="none-mobile">{{ $tl("labels.form.phoneNumber") }}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="resa in reservations" :key="resa.id">
                                            <td>
                                                <inline-client
                                                    v-if="resa.client !== null"
                                                    :client="resa.client"
                                                    :module-enum="MODULE_TYPE_BOOKING"
                                                    :public-comment="resa.reservation_comment"
                                                    :private-comment="resa.restaurant_comment"
                                                    :link="{ name: 'booking.clients.form', params: { client_id: resa.client.id } }">
                                                    <template v-slot:icons-start>
                                                        <feather v-if="resa.gift" type="tag" class="feather-blue" v-tooltip="getTooltip(resa.gift)" />
                                                    </template>
                                                </inline-client>
                                            </td>
                                            <td class="table-text-center">
                                                {{ resa.nb_pers * 1 + resa.nb_children * 1 }}
                                            </td>
                                            <td>
                                                {{ displayDate(resa.reservation_datetime, DateTime.DATE_SHORT) }}
                                            </td>
                                            <td>
                                                {{ resa.slot_hour_start }}
                                            </td>
                                            <td>
                                                <a v-if="resa.client !== null" :href="`tel:${resa.client.tel}`">{{ resa.client.tel }}</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="step === 2">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <form action="javascript:void()" class="container-fluid">
                            <div class="row">
                                <div class="col-md-5">
                                    <label for="txt-closure-object">{{ $tl("labels.form.object") }} *</label>
                                </div>
                                <div class="col-md-7 mb-1">
                                    <input type="text" v-model="cancelData.message_object" required id="txt-closure-object" class="form-control" />
                                    <ShowErrors :errors="formErrors" class="d-block" errorKey="message_object" />
                                </div>
                                <div class="col-md-5">
                                    <label for="txt-closure-body">{{ $tl("labels.form.messageContent") }} *</label>
                                </div>
                                <div class="col-md-7">
                                    <editor-with-merge-tags v-model="cancelData.message_body" :merge-tags="mergeTags"></editor-with-merge-tags>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="step = 1" v-if="step === 2">
                {{ $tl("labels.form.actions.back") }}
            </button>
            <button class="btn btn-success btn-sm btn-circle" @click="$emit('confirmed')" v-if="step === 1">
                {{ $tl("labels.form.actions.confirmNoCancel") }}
            </button>
            <button class="btn btn-success btn-sm btn-circle" :disabled="cancelData.cancel_enabled && formLoading" @click="continueAndCancel">
                {{ $tl(`labels.form.actions.${step === 1 ? "cancelAllBookings" : "confirm"}`) }}
            </button>
        </div>
    </modal>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import ShowErrors from "../../components/errors/ShowErrors.vue";
import { DateTime } from "luxon";
import editorWithMergeTags from "../../components/forms/editorWithMergeTags.vue";
import inlineClient from "../Default/Clients/inlineClient.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";

export default {
    data() {
        return {
            reservations: [],
            error: null,
            loading: false,
            step: 1,
            DateTime,
            mergeTags: [],
        };
    },
    props: {
        date_begin: {
            required: true,
            type: Object,
        },
        date_end: {
            required: true,
            type: Object,
        },
        servicesId: {
            required: true,
            type: String,
        },
        cancelData: {
            required: true,
            type: Object,
        },
        formLoading: {
            required: true,
            type: Boolean,
        },
        formErrors: {
            default: null,
        },
    },
    mixins: [ModuleTypesEnum],
    computed: {
        restaurantId() {
            return this.$route.params.restaurant_id;
        },
    },
    created() {
        this.fetchReservations();
        this.fetchMailMergeTags();
    },
    methods: {
        continueAndCancel() {
            if (this.step === 2) {
                this.cancelData.cancel_enabled = true;
                let reservations = [];
                this.reservations.forEach((reservation) => {
                    reservations.push(reservation.id);
                });
                this.cancelData.reservations_ids = reservations.join(",");
                this.$emit("confirmed");
                return;
            }
            this.step = 2;
        },
        fetchReservations() {
            this.loading++;
            this.error = null;

            this.httpGet(
                `/api/restaurants/${this.restaurantId}/reservations` +
                    `/filter?begin=${this.date_begin.toISODate()}&end=${this.date_end.toISODate()}` +
                    `&services=${this.servicesId}&excluded_status=pending-canceled-refused-noshow`,
                { mustReject: true }
            )
                .then((response) => {
                    this.reservations = Object.values(response.data).sort((a, b) => {
                        const resaDateTimeA = this.getDateTime(a.reservation_datetime);
                        const resaDateTimeB = this.getDateTime(b.reservation_datetime);

                        if (resaDateTimeA < resaDateTimeB) {
                            return -1;
                        }

                        if (resaDateTimeA > resaDateTimeB) {
                            return 1;
                        }

                        return 0;
                    });
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => {
                    this.loading--;
                });
        },
        fetchMailMergeTags() {
            this.loading++;

            this.httpGet(`/api/restaurants/${this.restaurantId}/configs?include=notifications_setting.mails_merge_tags`)
                .then((response) => {
                    if (response !== false) {
                        this.mergeTags = response.data.notifications_setting.mails_merge_tags.client_resa_canceled;
                    }
                })
                .finally(() => {
                    this.loading--;
                });
        },
        close() {
            this.reservations = [];
            this.error = null;
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    watch: {
        step(newVal, oldVal) {
            if (newVal === 1 && oldVal === 2) {
                this.cancelData.cancel_enabled = false;
                this.cancelData.reservations_ids = "";
            }
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
        editorWithMergeTags,
        inlineClient,
    },
};
</script>
