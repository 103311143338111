<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div class="row">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-2">
                            <div class="col-md-5 pt-1">
                                <label>Titre de la pop-up <small>*</small></label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="form-control" v-model="new_feature.title" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="title" />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5 pt-1">
                                <label>Contenu <small>*</small></label>
                            </div>
                            <div class="col-md-7">
                                <textarea class="form-control" rows="3" v-model="new_feature.content"></textarea>
                                <ShowErrors class="d-block" :errors="errors" errorKey="content" />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5 pt-1">
                                <label>Ajouter un bouton</label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input type="checkbox" class="switch align-self-center is-rounded" v-model="new_feature.add_btn" />
                                    <label @click="new_feature.add_btn = !new_feature.add_btn"></label>
                                </div>
                                <ShowErrors class="d-block" :errors="errors" errorKey="add_btn" />
                            </div>
                        </div>
                        <template v-if="new_feature.add_btn">
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Titre du bouton <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <input type="text" class="form-control" v-model="new_feature.btn_title" />
                                    <ShowErrors class="d-block" :errors="errors" errorKey="btn_title" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label class="mb-0">Lien du bouton <small>*</small></label>
                                    <small class="d-block text-muted"
                                        >Si l'id de l'établissement est requis, mettre <code>{restaurant_id}</code> à la place</small
                                    >
                                </div>
                                <div class="col-md-7">
                                    <input type="text" class="form-control" v-model="new_feature.btn_link" />
                                    <ShowErrors class="d-block" :errors="errors" errorKey="btn_link" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label class="mb-0">Module du lien</label>
                                    <small class="d-block text-muted">Pour rediriger sur le bon établissement</small>
                                </div>
                                <div class="col-md-7">
                                    <select class="custom-select" v-model="new_feature.btn_link_module">
                                        <option :value="null">--</option>
                                        <option v-for="module in ALL_MODULES_TYPES" :key="module.value" :value="module.value">
                                            {{ module.label }}
                                        </option>
                                    </select>
                                    <ShowErrors class="d-block" :errors="errors" errorKey="btn_link_module" />
                                </div>
                            </div>
                        </template>
                        <div class="row mb-2">
                            <div class="col-md-5 pt-1">
                                <label>Afficher aux owners uniquement</label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input type="checkbox" class="switch align-self-center is-rounded" v-model="new_feature.show_to_owners_only" />
                                    <label @click="new_feature.show_to_owners_only = !new_feature.show_to_owners_only"></label>
                                </div>
                                <ShowErrors class="d-block" :errors="errors" errorKey="show_to_owners_only" />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-4 pt-1">Clients qui ont une licence</div>
                            <div class="col-md-8">
                                <label
                                    class="container-box"
                                    style="width: auto !important"
                                    v-for="module in ALL_MODULES_TYPES_WITH_OPTIONS"
                                    :key="module.value">
                                    <input type="checkbox" v-model="new_feature.has_modules" :value="module.value" />
                                    <span class="checkmark"></span> {{ module.label }}
                                </label>
                                <ShowErrors class="d-block" :errors="errors" errorKey="has_modules" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="has_modules.*" />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-4 pt-1">Clients qui n'ont pas de licence</div>
                            <div class="col-md-8">
                                <label
                                    class="container-box"
                                    style="width: auto !important"
                                    v-for="module in ALL_MODULES_TYPES_WITH_OPTIONS"
                                    :key="module.value">
                                    <input type="checkbox" v-model="new_feature.doesnt_have_modules" :value="module.value" />
                                    <span class="checkmark"></span> {{ module.label }}
                                </label>
                                <ShowErrors class="d-block" :errors="errors" errorKey="doesnt_have_modules" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="doesnt_have_modules.*" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent";
import ShowErrors from "../../errors/ShowErrors";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum";

export default {
    data() {
        return {
            loading: 0,
            errors: null,
            new_feature: {
                id: null,
                title: null,
                content: null,
                add_btn: false,
                btn_title: null,
                btn_link: null,
                btn_link_module: null,
                show_to_owners_only: false,
                has_modules: [],
                doesnt_have_modules: [],
            },
        };
    },
    mixins: [ModuleTypesEnum],
    props: {
        // Edit not implemented
        // new_feature_id: {
        //     default: null,
        // },
    },
    computed: {
        builtData() {
            return {
                ...this.new_feature,
            };
        },
    },
    methods: {
        save() {
            this.loading++;
            this.errors = null;

            var promise = axios.post("/api/admin/new_features", this.builtData);
            promise
                .then((response) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.$emit("saved");
                })
                .catch((error) => {
                    this.loading--;
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
    watch: {
        loading(newVal) {
            this.$emit("set-loading", newVal);
        },
    },
};
</script>

<style>
.positition-relative {
    position: relative !important;
}
button.btn:disabled {
    cursor: default;
}
</style>
