var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(_vm._s(_vm.$tl("labels.booking.reservations.details"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c("validate-reservation", {
          attrs: {
            showValidateButton: false,
            reservation_id: _vm.reservation_id,
            restaurant_id: _vm.restaurant_id,
          },
          on: {
            "reservation-get": function ($event) {
              return _vm.setReservation($event)
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn-sm btn btn-secondary btn-circle",
            on: { click: _vm.close },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.close")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.reservation &&
        _vm.inEnum(_vm.reservation.status, _vm.editableReservationStatus)
          ? _c(
              "button",
              {
                staticClass: "btn btn-sm btn-success btn-circle",
                on: {
                  click: function ($event) {
                    return _vm.$emit("validate-resa", {
                      restaurant_id: _vm.restaurant_id,
                      reservation_id: _vm.reservation_id,
                    })
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.validate")) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }