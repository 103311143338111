var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pb-5 auto-website",
      staticStyle: { height: "initial !important" },
    },
    [
      _vm.loading || _vm.is_publishing
        ? [
            _c("LoaderComponent"),
            _vm._v(" "),
            _vm.is_publishing
              ? _c("div", { staticClass: "text-center" }, [
                  _c("h5", [
                    _vm._v(
                      "Votre site est en cours de déploiement. Cette opération peut durer plusieurs minutes."
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        : !_vm.website_enabled
        ? _c("div", { staticClass: "ml-2 alert alert-danger" }, [
            _vm._v(
              "\n        Vous devez d'abord configurer votre site avant de pouvoir le personnaliser\n    "
            ),
          ])
        : _vm.error
        ? _c("div", { staticClass: "ml-2 alert alert-danger" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : [
            _c("tab-bar", {
              attrs: {
                direction: "horizontal",
                side: "top",
                entries: _vm.tabBarEntries,
              },
            }),
            _vm._v(" "),
            _c(
              "router-view",
              _vm._b(
                { staticClass: "mt-5", on: { "fetch-data": _vm.fetchData } },
                "router-view",
                {
                  website_settings: _vm.website_settings,
                  website_enabled: _vm.website_enabled,
                },
                false
              )
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }