var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-md-5 my-4 h-100" },
    [
      _vm.step === 0
        ? _c("select-licence", {
            staticClass: "px-3",
            attrs: {
              "show-back-button": _vm.showBackButton,
              max: 1,
              "preselected-modules": _vm.modules,
            },
            on: {
              back: function ($event) {
                return _vm.$emit("back")
              },
              selected: function ($event) {
                _vm.modules = $event.selectedModules
                _vm.plan = $event.plan
                _vm.training = $event.training
                _vm.step = 1
              },
            },
          })
        : _vm.step === 1
        ? _c("pay-licences", {
            staticClass: "px-3",
            attrs: {
              modules: _vm.modules,
              plan: _vm.plan,
              training: _vm.training,
              redirect: { name: "home" },
              facturationEditable: true,
              restaurant_id: _vm.restaurant_id,
            },
            on: {
              back: function ($event) {
                _vm.step = 0
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }