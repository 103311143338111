<template>
    <div class="container-fluid text-center">
        <div class="row m-0">
            <div class="col-lg-6 offset-lg-3 pt-5 text-center">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <h3 class="mb-4">Ooops, vous êtes perdu ?</h3>
                    <router-link :to="home" class="btn btn-success btn-circle"> Tableau de bord </router-link>
                    <img class="mt-5 none-mobile" width="100%" src="/images/visuel-404.png" alt="No Show" />
                    <img class="mt-3 none-desk" width="270" src="/images/visuel-404.png" alt="No Show" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        home: {
            default: () => ({ name: "home" }),
        },
    },
};
</script>
