<template>
    <div
        class="position-absolute pl-1"
        :class="{ pointer: event.clickable }"
        @click="onEventClicked"
        :style="{ ...(event.style || {}), left, width, top }">
        <slot name="event" v-bind="{ event }">
            <div style="font-weight: 500">{{ event.name }}</div>
            <div>
                <small>{{ displayDate(event.startsAt, TIME_SIMPLE) }} - {{ displayDate(event.endsAt, TIME_SIMPLE) }}</small>
            </div>
        </slot>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import CalculatePxGap from "./CalculatePxGap.js";

export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
        startHourDateTime: {
            type: DateTime,
            required: true,
        },
        endHourDateTime: {
            type: DateTime,
            required: true,
        },
        cellWidth: {
            type: Number,
            default: 50,
        },
        minutesInterval: {
            type: Number,
            default: 15,
        },
        firstColOffset: {
            type: Number,
            default: 200,
        },
        top: {
            type: String,
        },
    },
    mixins: [CalculatePxGap],
    methods: {
        onEventClicked(e) {
            if (this.event.clickable) {
                e.stopPropagation();
                this.$emit("event-clicked");
            }
        },
    },
    computed: {
        leftPxGap() {
            return this.calculateHorizontalPxGap(
                this.startHourDateTime,
                this.endHourDateTime,
                this.event.startsAt,
                this.cellWidth,
                this.minutesInterval,
                this.firstColOffset
            );
        },
        endPxGap() {
            return this.calculateHorizontalPxGap(
                this.startHourDateTime,
                this.endHourDateTime,
                this.event.endsAt,
                this.cellWidth,
                this.minutesInterval,
                this.firstColOffset
            );
        },
        left() {
            return `${this.leftPxGap}px`;
        },
        width() {
            return `${this.endPxGap - this.leftPxGap}px`;
        },
    },
};
</script>
