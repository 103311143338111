var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Remboursement"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c("RefundProducts", {
          ref: "refundProducts",
          attrs: { command_id: _vm.command_id },
          on: {
            refunded: function ($event) {
              return _vm.$emit("refunded", $event)
            },
            loading: function ($event) {
              _vm.loading = $event
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            class: { disabled: _vm.loading },
            attrs: { disabled: _vm.loading, type: "button" },
            on: { click: _vm.close },
          },
          [_vm._v("\n            Annuler\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-success btn-circle ml-2",
            class: { disabled: _vm.loading },
            attrs: { disabled: _vm.loading, type: "button" },
            on: {
              click: function ($event) {
                return _vm.$refs.refundProducts.post()
              },
            },
          },
          [_vm._v("\n            Valider\n        ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }