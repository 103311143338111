<template>
    <div class="row m-0">
        <div class="col-12 mx-auto mb-3">
            <div class="border-light b-radius-20 p-4">
                <template v-for="(field, index) in fields">
                    <div class="row" :key="index">
                        <div class="col-6">{{ field.label }}</div>
                        <div class="col-6 value">
                            <copy-to-clipboard
                                v-if="field.useCopyComponent"
                                :text-to-copy="field.value"
                                :element="`Le ${field.label}`"
                                class="copy-container" />
                            <template v-else>
                                {{ field.value }}
                            </template>
                        </div>
                    </div>
                    <div v-if="index < fields.length - 1" class="sepa"></div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import CopyToClipboard from "../../../Buttons/CopyToClipboard.vue";
import CouponDurationTypeEnum from "../../../../mixins/enums/CouponDurationTypeEnum.js";
import StripePlanTypesEnum from "../../../../mixins/enums/StripePlanTypesEnum.js";
import ModuleTypesEnum from "../../../../mixins/enums/ModuleTypesEnum.js";

export default {
    props: {
        enableCopyComponent: {
            type: Boolean,
            default: false,
        },
        promoCode: {
            type: Object,
            default: () => {
                return {};
            },
        },
        isPreview: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        CopyToClipboard,
    },
    mixins: [CouponDurationTypeEnum, StripePlanTypesEnum, ModuleTypesEnum],
    data() {
        return {
            fields: [],
        };
    },
    computed: {
        durationKey() {
            return this.isPreview ? "duration" : "duration_in_month";
        },
    },
    watch: {
        promoCode: {
            handler(newValue) {
                if (newValue !== undefined) {
                    this.fields = this.formatTemplateObject();
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        formatTemplateObject() {
            return [
                {
                    label: "Date de création",
                    value: this.displayDate(this.promoCode.created_at, this.DATETIME_SHORT),
                },
                {
                    label: "Nom",
                    value: this.promoCode.name,
                },
                {
                    label: "Code",
                    value: this.promoCode.code,
                    useCopyComponent: this.enableCopyComponent,
                },
                {
                    label: "Description",
                    value: this.promoCode.description,
                },
                {
                    label: "Type d'abonnement",
                    value: this.getStripePlanTypeLabel(this.promoCode.plan),
                },
                {
                    label: "Module",
                    value: this.getModuleTypeLabel(this.promoCode.module),
                },
                {
                    label: "Durée",
                    value: this.displayDuration(this.promoCode[this.durationKey]),
                },
                {
                    label: "Date de début de validité",
                    value: this.displayDate(this.promoCode.validity_starts_at, this.DATE),
                },
                {
                    label: "Date de fin de validité",
                    value: this.displayDate(this.promoCode.validity_expires_at, this.DATE),
                },
                {
                    label: "Actif",
                    value: this.promoCode.is_active ? "Oui" : "Non",
                },
                {
                    label: "Réduction",
                    value: this.getReduction(),
                },
                {
                    label: "Seulement pour les nouveaux utilisateurs",
                    value: this.promoCode.only_subscribers ? "Oui" : "Non",
                },
                {
                    label: "Maximum d'utilisations",
                    value: this.promoCode.max_redemptions ?? "∞",
                },
                {
                    label: "Maximum d'utilisations par utilisateur",
                    value: this.promoCode.max_redemptions_by_users ?? "∞",
                },
            ];
        },
        getReduction() {
            if (this.promoCode.amount_off !== null) {
                return this.formatCurrency(this.promoCode.amount_off);
            }
            if (this.promoCode.percent_off !== null) {
                return `${this.promoCode.percent_off} %`;
            }
            return "";
        },
        displayDuration(durationInMonth) {
            if (this.isPreview) {
                durationInMonth = this.computeDuration();
            }

            return `${durationInMonth !== 0 ? durationInMonth : "∞"} mois`;
        },
        computeDuration() {
            let duration = this.promoCode.duration ?? 0;

            if (this.promoCode.duration_type === this.COUPON_DURATION_FOREVER.value) {
                return 0;
            }

            if (this.promoCode.duration_type === this.COUPON_DURATION_ONCE.value) {
                duration = 1;
            }

            if (this.promoCode.plan === this.STRIPE_PLAN_TYPE_YEARLY.value) {
                duration *= 12;
            }

            return duration;
        },
    },
};
</script>
