<template>
    <div>
        <div class="col-12">
            <LoaderComponent v-if="isLoading" />
            <div
                v-else
                v-tooltip="getTooltip(isDisabled ? $tl('infos.common.services', restaurant_id) : '')"
                class="border-light b-radius-20 p-4 mb-3 block"
                :class="{ disabled: isDisabled }">
                <div class="row mb-3">
                    <div class="col-12">
                        <strong>
                            {{ $tl("labels.booking.widget.automaticExport.title") }}
                        </strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-5">
                                {{ switchAutomaticExportLabel }}
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        :disabled="isDisabled"
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="currentAutomaticExport.is_enabled" />
                                    <label @click="switchActivate"></label>
                                </div>
                            </div>
                        </div>
                        <template v-if="currentAutomaticExport.is_enabled">
                            <div class="row my-2">
                                <div class="col-md-5">{{ $tl("labels.booking.widget.automaticExport.nbMinutes", restaurant_id) }}</div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <input
                                                type="number"
                                                required
                                                min="5"
                                                max="120"
                                                v-model="currentAutomaticExport.minutes"
                                                class="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-2">
                                <div class="col-md-5">{{ $tl("labels.booking.widget.automaticExport.services", restaurant_id) }}</div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <vue-multiselect
                                                v-model="currentAutomaticExport[servicesName]"
                                                :options="services"
                                                :multiple="true"
                                                label="name"
                                                track-by="id"
                                                :placeholder="$tl('labels.service', restaurant_id)"
                                                :group-select="false"
                                                :show-labels="false"
                                                open-direction="bottom">
                                            </vue-multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-2">
                                <div class="col-md-5">{{ $tl("labels.booking.widget.automaticExport.emails") }}</div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div
                                            v-for="(email, index) in currentAutomaticExport.emails"
                                            :key="index"
                                            class="col-8 d-flex align-items-center my-1">
                                            <input required type="email" v-model="currentAutomaticExport.emails[index]" class="form-control" />
                                            <button
                                                class="btn btn-sm btn-square"
                                                :class="{
                                                    'btn-danger': !isLast,
                                                    'btn-secondary': isLast,
                                                }"
                                                type="button"
                                                :disabled="isLast"
                                                v-tooltip="getTooltip($t('labels.form.actions.delete'))"
                                                @click="deleteEmail(index)">
                                                <feather type="trash-2" />
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row my-3">
                                        <div class="col-12">
                                            <button
                                                v-if="currentAutomaticExport.emails.length < LIMIT_EMAIL"
                                                type="button"
                                                class="btn btn-success btn-circle btn-sm"
                                                @click="currentAutomaticExport.emails.push('')">
                                                {{ $tl("labels.form.emails.add") }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueMultiselect from "vue-multiselect";
import LoaderComponent from "../../../../components/LoaderComponent.vue";
import ModuleTypesEnum from "../../../../mixins/enums/ModuleTypesEnum.js";
import { DateTime } from "luxon";

const LIMIT_EMAIL = 5;

export default {
    props: {
        value: {
            required: true,
            type: Object,
        },
        module: {
            required: true,
            type: String,
        },
        servicesName: {
            type: String,
            required: true,
        },
        isDelivery: {
            type: Boolean,
        },
        canFetchServices: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: true,
            isDisabled: false,
            hasErrors: true,
            services: [],
            currentAutomaticExport: {},
            LIMIT_EMAIL,
        };
    },
    mixins: [ModuleTypesEnum],
    computed: {
        restaurant_id() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        isLast() {
            return this.currentAutomaticExport.emails.length === 1;
        },
        isBooking() {
            return this.module === this.MODULE_TYPE_BOOKING.value;
        },
        baseURL() {
            return this.isBooking ? `/api/restaurants/${this.restaurant_id}` : `/api/click_and_collect/${this.restaurant_id}`;
        },
        urlGetServices() {
            let suffixURL = "/services/futures";

            if (!this.isBooking) {
                suffixURL = !this.isDelivery ? "/settings/cc_services" : "/settingsDelivery/cc_services";
            }

            return this.baseURL + suffixURL;
        },
        switchAutomaticExportLabel() {
            return !this.currentAutomaticExport.is_enabled
                ? this.$tl("labels.booking.widget.automaticExport.activate")
                : this.$tl("labels.booking.widget.automaticExport.desactivate");
        },
    },
    methods: {
        formatServices(dataServices) {
            const days = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];

            dataServices.map((service) => {
                const serviceStartHour = !this.isDelivery ? service.start_hour : service.hour_start;
                const serviceEndHour = !this.isDelivery ? service.end_hour : service.hour_end;
                const startHour = DateTime.fromISO(serviceStartHour).toFormat("HH'h'mm");
                const endHour = DateTime.fromISO(serviceEndHour).toFormat("HH'h'mm");

                service.name = `${days[service.day]} (${startHour} - ${endHour})`;
            });
        },
        getServices() {
            this.isLoading = true;

            this.httpGet(this.urlGetServices, { handleReject: false, mustReject: true })
                .then((response) => {
                    this.services = response.data.data;
                    if (!this.isBooking) {
                        this.formatServices(this.services);
                    }
                    if (this.isDisabled) {
                        this.isDisabled = false;
                    }
                })
                .catch((error) => {
                    this.isDisabled = true;
                })
                .finally(() => {
                    this.isLoading = false;

                    if (this.canFetchServices) {
                        this.$emit("services-fetched");
                    }
                });
        },
        deleteEmail(index) {
            if (confirm(this.$t("labels.form.actions.deleteEmail", { nameEmail: this.currentAutomaticExport.emails[index] }))) {
                this.currentAutomaticExport.emails.splice(index, 1);
            }
        },
        switchActivate() {
            if (!this.isDisabled) {
                this.currentAutomaticExport.is_enabled = !this.currentAutomaticExport.is_enabled;
            }
        },
    },
    created() {
        this.currentAutomaticExport = _.cloneDeep(this.value);
        if (!this.isBooking && this.currentAutomaticExport[this.servicesName].length > 0) {
            this.formatServices(this.currentAutomaticExport[this.servicesName]);
        }
        this.getServices();
    },
    watch: {
        currentAutomaticExport: {
            handler(value) {
                this.$emit("input", value);
                if (!this.isBooking && this.currentAutomaticExport[this.servicesName].length) {
                    this.formatServices(this.currentAutomaticExport[this.servicesName]);
                }
            },
            deep: true,
        },
        canFetchServices(value) {
            if (value) {
                this.getServices();
            }
        },
    },
    components: {
        VueMultiselect,
        LoaderComponent,
    },
};
</script>
