var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.hasErrors("client_id")
      ? _c(
          "div",
          _vm._l(_vm.formErrors.errors.client_id, function (err) {
            return _c("div", { staticClass: "invalid-feedback d-block" }, [
              _vm._v("\n            " + _vm._s(err) + "\n        "),
            ])
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2 radio" }, [
      _c("div", { staticClass: "col-md-4 d-none d-md-block pt-2" }, [
        _c("label", [_vm._v(_vm._s(_vm.$tl("labels.form.civility")))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-8 pt-2" }, [
        _vm.readonly
          ? _c("div", [
              _c("label", { staticClass: "container-box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clients.civility,
                      expression: "clients.civility",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "civility",
                    tabindex: "5",
                    readonly: "",
                    disabled: "true",
                  },
                  domProps: {
                    value: _vm.CLIENT_CIVILITY_MR.value,
                    checked: _vm._q(
                      _vm.clients.civility,
                      _vm.CLIENT_CIVILITY_MR.value
                    ),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(
                        _vm.clients,
                        "civility",
                        _vm.CLIENT_CIVILITY_MR.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkmark" }),
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.capitalize(
                        _vm.getClientCivilityLabelLong(
                          _vm.CLIENT_CIVILITY_MR.value
                        )
                      )
                    ) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "container-box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clients.civility,
                      expression: "clients.civility",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "civility",
                    readonly: "",
                    disabled: "true",
                    tabindex: "6",
                  },
                  domProps: {
                    value: _vm.CLIENT_CIVILITY_MME.value,
                    checked: _vm._q(
                      _vm.clients.civility,
                      _vm.CLIENT_CIVILITY_MME.value
                    ),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(
                        _vm.clients,
                        "civility",
                        _vm.CLIENT_CIVILITY_MME.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkmark" }),
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.capitalize(
                        _vm.getClientCivilityLabelLong(
                          _vm.CLIENT_CIVILITY_MME.value
                        )
                      )
                    ) +
                    "\n                "
                ),
              ]),
            ])
          : _c("div", [
              _c("label", { staticClass: "container-box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clients.civility,
                      expression: "clients.civility",
                    },
                  ],
                  attrs: { type: "radio", name: "civility", tabindex: "5" },
                  domProps: {
                    value: _vm.CLIENT_CIVILITY_MR.value,
                    checked: _vm._q(
                      _vm.clients.civility,
                      _vm.CLIENT_CIVILITY_MR.value
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.firstname.focus()
                    },
                    change: function ($event) {
                      return _vm.$set(
                        _vm.clients,
                        "civility",
                        _vm.CLIENT_CIVILITY_MR.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkmark" }),
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.capitalize(
                        _vm.getClientCivilityLabelLong(
                          _vm.CLIENT_CIVILITY_MR.value
                        )
                      )
                    ) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "container-box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clients.civility,
                      expression: "clients.civility",
                    },
                  ],
                  attrs: { type: "radio", name: "civility", tabindex: "6" },
                  domProps: {
                    value: _vm.CLIENT_CIVILITY_MME.value,
                    checked: _vm._q(
                      _vm.clients.civility,
                      _vm.CLIENT_CIVILITY_MME.value
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.firstname.focus()
                    },
                    change: function ($event) {
                      return _vm.$set(
                        _vm.clients,
                        "civility",
                        _vm.CLIENT_CIVILITY_MME.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkmark" }),
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.capitalize(
                        _vm.getClientCivilityLabelLong(
                          _vm.CLIENT_CIVILITY_MME.value
                        )
                      )
                    ) +
                    "\n                "
                ),
              ]),
            ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-md-4 d-none d-md-block pt-2" }, [
        _c("label", [_vm._v(_vm._s(_vm.$tl("labels.form.firstname")))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "dropright add-resa-search-client" }, [
          _vm.readonly
            ? _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clients.firstname,
                      expression: "clients.firstname",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.hasErrors("firstname") || _vm.hasErrors("client_id"),
                  },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$tl("labels.form.firstname"),
                    name: "firstname",
                    readonly: "",
                    autocomplete: "off",
                    tabindex: "7",
                  },
                  domProps: { value: _vm.clients.firstname },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.clients, "firstname", $event.target.value)
                      },
                      _vm.searchClientByMultiField,
                    ],
                    focus: function ($event) {
                      _vm.clients.firstnameHasFocus = true
                    },
                    blur: function ($event) {
                      _vm.clients.firstnameHasFocus = false
                    },
                  },
                }),
              ])
            : _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clients.firstname,
                      expression: "clients.firstname",
                    },
                  ],
                  ref: "firstname",
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.hasErrors("firstname") || _vm.hasErrors("client_id"),
                  },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$tl("labels.form.firstname"),
                    name: "firstname",
                    autocomplete: "off",
                    tabindex: "7",
                  },
                  domProps: { value: _vm.clients.firstname },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.clients, "firstname", $event.target.value)
                      },
                      _vm.searchClientByMultiField,
                    ],
                    focus: function ($event) {
                      _vm.clients.firstnameHasFocus = true
                    },
                    blur: function ($event) {
                      _vm.clients.firstnameHasFocus = false
                    },
                  },
                }),
              ]),
          _vm._v(" "),
          (_vm.clients.firstnameHasFocus ||
            _vm.clients.search.firstnameHasFocus) &&
          _vm.clients.search.results &&
          _vm.clients.search.results.length > 0
            ? _c(
                "div",
                {
                  staticClass: "dropdown-menu prename-field shadow show p-0",
                  on: {
                    mouseover: function ($event) {
                      _vm.clients.search.firstnameHasFocus = true
                    },
                    mouseout: function ($event) {
                      _vm.clients.search.firstnameHasFocus = false
                    },
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-group" },
                    _vm._l(_vm.clients.search.results, function (result) {
                      return _c("li", { staticClass: "list-group-item p-0" }, [
                        _c(
                          "a",
                          {
                            staticClass: "d-block p-3",
                            attrs: { href: "javascript:" },
                            on: {
                              click: function ($event) {
                                return _vm.selectClient(result)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(result.firstname) +
                                  " " +
                                  _vm._s(result.lastname)
                              ),
                            ]),
                            _vm._v(" |\n                                "),
                            _c("span", [_vm._v(_vm._s(result.tel))]),
                            _vm._v(" "),
                            _c("span", { staticClass: "email-search" }, [
                              result.company
                                ? _c("span", [
                                    _vm._v(_vm._s(result.company) + " |"),
                                  ])
                                : _vm._e(),
                              _vm._v(" " + _vm._s(result.email)),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasErrors("firstname")
            ? _c(
                "div",
                _vm._l(_vm.formErrors.errors.firstname, function (err) {
                  return _c(
                    "div",
                    { staticClass: "invalid-feedback d-block" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(err) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-md-4 d-none d-md-block pt-2" }, [
        _c("label", [_vm._v(_vm._s(_vm.$tl("labels.form.lastname")))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "dropright add-resa-search-client" }, [
          _vm.readonly
            ? _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clients.lastname,
                      expression: "clients.lastname",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.hasErrors("lastname") || _vm.hasErrors("client_id"),
                  },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$tl("labels.form.lastname"),
                    name: "lastname",
                    autocomplete: "off",
                    readonly: "",
                    tabindex: "8",
                  },
                  domProps: { value: _vm.clients.lastname },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.clients, "lastname", $event.target.value)
                      },
                      _vm.searchClientByMultiField,
                    ],
                    focus: function ($event) {
                      _vm.clients.lastnameHasFocus = true
                    },
                    blur: function ($event) {
                      _vm.clients.lastnameHasFocus = false
                    },
                  },
                }),
              ])
            : _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clients.lastname,
                      expression: "clients.lastname",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.hasErrors("lastname") || _vm.hasErrors("client_id"),
                  },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$tl("labels.form.lastname"),
                    name: "lastname",
                    autocomplete: "off",
                    tabindex: "8",
                  },
                  domProps: { value: _vm.clients.lastname },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.clients, "lastname", $event.target.value)
                      },
                      _vm.searchClientByMultiField,
                    ],
                    focus: function ($event) {
                      _vm.clients.lastnameHasFocus = true
                    },
                    blur: function ($event) {
                      _vm.clients.lastnameHasFocus = false
                    },
                  },
                }),
              ]),
          _vm._v(" "),
          (_vm.clients.lastnameHasFocus ||
            _vm.clients.search.lastnameHasFocus) &&
          _vm.clients.search.results &&
          _vm.clients.search.results.length > 0
            ? _c(
                "div",
                {
                  staticClass: "dropdown-menu name-field shadow show p-0",
                  on: {
                    mouseover: function ($event) {
                      _vm.clients.search.lastnameHasFocus = true
                    },
                    mouseout: function ($event) {
                      _vm.clients.search.lastnameHasFocus = false
                    },
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-group" },
                    _vm._l(_vm.clients.search.results, function (result) {
                      return _c("li", { staticClass: "list-group-item p-0" }, [
                        _c(
                          "a",
                          {
                            staticClass: "d-block p-3",
                            attrs: { href: "javascript:" },
                            on: {
                              click: function ($event) {
                                return _vm.selectClient(result)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(result.firstname) +
                                  " " +
                                  _vm._s(result.lastname)
                              ),
                            ]),
                            _vm._v(" |\n                                "),
                            _c("span", [_vm._v(_vm._s(result.tel))]),
                            _vm._v(" "),
                            _c("span", { staticClass: "email-search" }, [
                              result.company
                                ? _c("span", [
                                    _vm._v(_vm._s(result.company) + " |"),
                                  ])
                                : _vm._e(),
                              _vm._v(" " + _vm._s(result.email)),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasErrors("lastname")
            ? _c(
                "div",
                _vm._l(_vm.formErrors.errors.lastname, function (err) {
                  return _c(
                    "div",
                    { staticClass: "invalid-feedback d-block" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(err) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-md-4 d-none d-md-block pt-2" }, [
        _c("label", [_vm._v(_vm._s(_vm.$tl("labels.form.company")))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "dropright add-resa-search-client" }, [
          _vm.readonly
            ? _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clients.company,
                      expression: "clients.company",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.hasErrors("company") || _vm.hasErrors("client_id"),
                  },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$tl("labels.form.company"),
                    name: "lastname",
                    autocomplete: "off",
                    readonly: "",
                    tabindex: "8",
                  },
                  domProps: { value: _vm.clients.company },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.clients, "company", $event.target.value)
                      },
                      _vm.searchClientByMultiField,
                    ],
                    focus: function ($event) {
                      _vm.clients.companyHasFocus = true
                    },
                    blur: function ($event) {
                      _vm.clients.companyHasFocus = false
                    },
                  },
                }),
              ])
            : _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clients.company,
                      expression: "clients.company",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.hasErrors("company") || _vm.hasErrors("client_id"),
                  },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$tl("labels.form.business"),
                    name: "lastname",
                    autocomplete: "off",
                    tabindex: "8",
                  },
                  domProps: { value: _vm.clients.company },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.clients, "company", $event.target.value)
                      },
                      _vm.searchClientByMultiField,
                    ],
                    focus: function ($event) {
                      _vm.clients.companyHasFocus = true
                    },
                    blur: function ($event) {
                      _vm.clients.companyHasFocus = false
                    },
                  },
                }),
              ]),
          _vm._v(" "),
          (_vm.clients.companyHasFocus || _vm.clients.search.companyHasFocus) &&
          _vm.clients.search.results &&
          _vm.clients.search.results.length > 0
            ? _c(
                "div",
                {
                  staticClass: "dropdown-menu company-field shadow show p-0",
                  on: {
                    mouseover: function ($event) {
                      _vm.clients.search.companyHasFocus = true
                    },
                    mouseout: function ($event) {
                      _vm.clients.search.companyHasFocus = false
                    },
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-group" },
                    _vm._l(_vm.client.search.results, function (result) {
                      return _c("li", { staticClass: "list-group-item p-0" }, [
                        _c(
                          "a",
                          {
                            staticClass: "d-block p-3",
                            attrs: { href: "javascript:" },
                            on: {
                              click: function ($event) {
                                return _vm.selectClient(result)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(result.firstname) +
                                  " " +
                                  _vm._s(result.lastname)
                              ),
                            ]),
                            _vm._v(" |\n                                "),
                            _c("span", [_vm._v(_vm._s(result.tel))]),
                            _vm._v(" "),
                            _c("span", { staticClass: "email-search" }, [
                              result.company
                                ? _c("span", [
                                    _vm._v(_vm._s(result.company) + " |"),
                                  ])
                                : _vm._e(),
                              _vm._v(" " + _vm._s(result.email)),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasErrors("company")
            ? _c(
                "div",
                _vm._l(_vm.formErrors.errors.company, function (err) {
                  return _c(
                    "div",
                    { staticClass: "invalid-feedback d-block" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(err) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-md-4 d-none d-md-block pt-2" }, [
        _c("label", [_vm._v(_vm._s(_vm.$tl("labels.form.email")))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "dropright add-resa-search-client" }, [
          _vm.readonly
            ? _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clients.email,
                      expression: "clients.email",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.hasErrors("email") || _vm.hasErrors("client_id"),
                  },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$tl("labels.form.email"),
                    name: "email",
                    autocomplete: "off",
                    readonly: "",
                    tabindex: "9",
                  },
                  domProps: { value: _vm.clients.email },
                  on: {
                    focus: function ($event) {
                      _vm.clients.emailHasFocus = true
                    },
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.clients, "email", $event.target.value)
                      },
                      _vm.searchClientByMultiField,
                    ],
                    blur: function ($event) {
                      _vm.clients.emailHasFocus = false
                    },
                  },
                }),
              ])
            : _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clients.email,
                      expression: "clients.email",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.hasErrors("email") || _vm.hasErrors("client_id"),
                  },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$tl("labels.form.email"),
                    name: "email",
                    autocomplete: "off",
                    tabindex: "9",
                  },
                  domProps: { value: _vm.clients.email },
                  on: {
                    focus: function ($event) {
                      _vm.clients.emailHasFocus = true
                    },
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.clients, "email", $event.target.value)
                      },
                      _vm.searchClientByMultiField,
                    ],
                    blur: function ($event) {
                      _vm.clients.emailHasFocus = false
                    },
                  },
                }),
              ]),
          _vm._v(" "),
          (_vm.clients.emailHasFocus || _vm.clients.search.emailHasFocus) &&
          _vm.clients.search.results &&
          _vm.clients.search.results.length > 0
            ? _c(
                "div",
                {
                  staticClass: "dropdown-menu email-field shadow show p-0",
                  on: {
                    mouseover: function ($event) {
                      _vm.clients.search.emailHasFocus = true
                    },
                    mouseout: function ($event) {
                      _vm.clients.search.emailHasFocus = false
                    },
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-group" },
                    _vm._l(_vm.client.search.results, function (result) {
                      return _c("li", { staticClass: "list-group-item p-0" }, [
                        _c(
                          "a",
                          {
                            staticClass: "d-block p-3",
                            attrs: { href: "javascript:" },
                            on: {
                              click: function ($event) {
                                return _vm.selectClient(result)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(result.firstname) +
                                  " " +
                                  _vm._s(result.lastname)
                              ),
                            ]),
                            _vm._v(" |\n                                "),
                            _c("span", [_vm._v(_vm._s(result.tel))]),
                            _vm._v(" "),
                            _c("span", { staticClass: "email-search" }, [
                              result.company
                                ? _c("span", [
                                    _vm._v(_vm._s(result.company) + " |"),
                                  ])
                                : _vm._e(),
                              _vm._v(" " + _vm._s(result.email)),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasErrors("email")
            ? _c(
                "div",
                _vm._l(_vm.formErrors.errors.email, function (err) {
                  return _c(
                    "div",
                    { staticClass: "invalid-feedback d-block" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(err) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-4 d-none d-md-block pt-2" }, [
        _c("label", [_vm._v(_vm._s(_vm.$tl("labels.form.phoneNumber")))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "dropright add-resa-search-client" }, [
          _vm.readonly
            ? _c(
                "div",
                [
                  _c("vue-tel-input", {
                    ref: "vueTelInput",
                    class:
                      _vm.hasErrors("phoneNumber") ||
                      _vm.hasErrors("phoneCountry") ||
                      _vm.hasErrors("client_id") ||
                      (_vm.client.phone.value !== null &&
                        _vm.client.phone.isValid === false)
                        ? ["form-control", "is-invalid", "d-flex", "text-left"]
                        : ["form-control", "d-flex", "text-left"],
                    attrs: {
                      name: "telInput",
                      defaultCountry: _vm.defaultTelCountry,
                      disabled: true,
                      enabledCountryCode: true,
                      inputOptions: { tabindex: 10, autocomplete: "off" },
                      mode: "international",
                      inputClasses: "m-0 border-0",
                      placeholder: _vm.$tl("labels.form.phoneNumber"),
                    },
                    on: {
                      onValidate: _vm.setPhoneAndCountry,
                      onInput: _vm.searchClientByMultiField,
                    },
                    model: {
                      value: _vm.clients.phone.raw,
                      callback: function ($$v) {
                        _vm.$set(_vm.clients.phone, "raw", $$v)
                      },
                      expression: "clients.phone.raw",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("vue-tel-input", {
                    ref: "vueTelInput",
                    class:
                      _vm.hasErrors("phoneNumber") ||
                      _vm.hasErrors("phoneCountry") ||
                      _vm.hasErrors("client_id") ||
                      (_vm.client.phone.value !== null &&
                        _vm.client.phone.isValid === false)
                        ? ["form-control", "is-invalid", "d-flex", "text-left"]
                        : ["form-control", "d-flex", "text-left"],
                    attrs: {
                      defaultCountry: _vm.defaultTelCountry,
                      name: "telInput",
                      enabledCountryCode: true,
                      inputOptions: { tabindex: 10, autocomplete: "off" },
                      mode: "international",
                      inputClasses: "m-0 border-0",
                      placeholder: _vm.$tl("labels.form.phoneNumber"),
                    },
                    on: {
                      onValidate: _vm.setPhoneAndCountry,
                      onInput: _vm.searchClientByMultiField,
                    },
                    model: {
                      value: _vm.clients.phone.raw,
                      callback: function ($$v) {
                        _vm.$set(_vm.clients.phone, "raw", $$v)
                      },
                      expression: "clients.phone.raw",
                    },
                  }),
                ],
                1
              ),
          _vm._v(" "),
          (_vm.clients.phoneNumberHasFocus ||
            _vm.clients.search.phoneNumberHasFocus) &&
          _vm.clients.search.results &&
          _vm.clients.search.results.length > 0
            ? _c(
                "div",
                {
                  staticClass: "dropdown-menu phone-field shadow show p-0",
                  on: {
                    mouseover: function ($event) {
                      _vm.clients.search.phoneNumberHasFocus = true
                    },
                    mouseout: function ($event) {
                      _vm.clients.search.phoneNumberHasFocus = false
                    },
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-group" },
                    _vm._l(_vm.clients.search.results, function (result) {
                      return _c("li", { staticClass: "list-group-item p-0" }, [
                        _c(
                          "a",
                          {
                            staticClass: "d-block p-3",
                            attrs: { href: "javascript:" },
                            on: {
                              click: function ($event) {
                                return _vm.selectClient(result)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(result.firstname) +
                                  " " +
                                  _vm._s(result.lastname)
                              ),
                            ]),
                            _vm._v(" |\n                                "),
                            _c("span", [_vm._v(_vm._s(result.tel))]),
                            _vm._v(" "),
                            _c("span", { staticClass: "email-search" }, [
                              result.company
                                ? _c("span", [
                                    _vm._v(_vm._s(result.company) + " |"),
                                  ])
                                : _vm._e(),
                              _vm._v(" " + _vm._s(result.email)),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasErrors("phoneNumber") ||
          _vm.hasErrors("phoneCountry") ||
          _vm.hasErrors("client_id")
            ? _c("div", { staticClass: "invalid-feedback d-block" }, [
                _vm.hasErrors("phoneNumber")
                  ? _c(
                      "div",
                      _vm._l(_vm.formErrors.errors.phoneNumber, function (err) {
                        return _c(
                          "div",
                          { staticClass: "invalid-feedback d-block" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(err) +
                                "\n                        "
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm.hasErrors("phoneCountry")
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.formErrors.errors.phoneCountry,
                        function (err) {
                          return _c(
                            "div",
                            { staticClass: "invalid-feedback d-block" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(err) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.clients.phone.search.results !== null && _vm.clients.id === null
      ? _c("div", { staticClass: "add-resa-search-client" }, [
          _vm.clients.phone.search.results.length > 0
            ? _c("div", [
                _c(
                  "span",
                  { staticClass: "text-danger d-inline-block mt-2 mb-2" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.clients.alreadyExist"))
                    ),
                    _c("br"),
                    _vm._v(
                      _vm._s(_vm.$tl("infos.booking.clients.alreadyExist")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("li", { staticClass: "list-group-item p-0" }, [
                  _c(
                    "a",
                    {
                      staticClass: "d-block p-3",
                      attrs: { href: "javascript:" },
                      on: {
                        click: function ($event) {
                          return _vm.selectClient(
                            _vm.clients.phone.search.results[0]
                          )
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.clients.phone.search.results[0].firstname
                            ) +
                            "\n                        " +
                            _vm._s(
                              _vm.clients.phone.search.results[0].lastname
                            ) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v("\n                    |\n                    "),
                      _c("span", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.clients.phone.search.results[0].tel) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "email-search" }, [
                        _vm.clients.phone.search.results[0].company
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.clients.phone.search.results[0].company
                                ) + " |"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(
                          _vm._s(_vm.clients.phone.search.results[0].email)
                        ),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _c("div", [
                _c(
                  "span",
                  { staticClass: "d-inline-block mt-2 mb-2 text-muted" },
                  [
                    _vm._v(
                      _vm._s(_vm.$tl("infos.booking.clients.willBeCreated"))
                    ),
                  ]
                ),
              ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.readonly
      ? _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-success btn-circle btn-sm mt-3",
              attrs: { disabled: _vm.clients.is_deleted, type: "button" },
              on: { click: _vm.redirectToClient },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$tl("labels.clients.edit")) +
                  "\n        "
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }