var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "default-home pb-5" },
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c(
                  "div",
                  [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-space-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn d-flex pl-0",
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.go(-1)
                                  },
                                },
                              },
                              [
                                _c("feather", {
                                  staticClass: "feather-24",
                                  attrs: { type: "chevron-left" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.employee_id
                              ? _c("div", [
                                  _c("h5", { staticClass: "title mt-2" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$tl("labels.routes.editEmployee")
                                        ) +
                                        "\n                                "
                                    ),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(_vm.firstname) +
                                          " " +
                                          _vm._s(_vm.lastname)
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _c("div", [
                                  _c("h5", { staticClass: "title mt-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl("labels.routes.addEmployee")
                                      )
                                    ),
                                  ]),
                                ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.formSuccess
                      ? _c("div", { staticClass: "alert alert-success" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.formSuccess) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formLoading
                      ? _c("LoaderComponent")
                      : _c(
                          "form",
                          {
                            attrs: {
                              id: "addReservation",
                              method: "post",
                              novalidate: "",
                            },
                            on: { submit: _vm.postForm },
                          },
                          [
                            _c("div", { staticClass: "row m-0" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "border-light b-radius-20 p-4 mb-3",
                                  },
                                  [
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col-5 pt-2" }, [
                                        _c(
                                          "label",
                                          { attrs: { for: "firstname" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl("labels.form.firstname")
                                              ) + " "
                                            ),
                                            _c("small", [_vm._v("*")]),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-7" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.firstname,
                                                expression: "firstname",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              name: "firstname",
                                              id: "firstname",
                                              required: "",
                                            },
                                            domProps: { value: _vm.firstname },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.firstname =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.formErrors,
                                              errorKey: "firstname",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col-5 pt-2" }, [
                                        _c(
                                          "label",
                                          { attrs: { for: "lastname" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl("labels.form.fullname")
                                              ) + " "
                                            ),
                                            _c("small", [_vm._v("*")]),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-7" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.lastname,
                                                expression: "lastname",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              name: "lastname",
                                              id: "lastname",
                                              required: "",
                                            },
                                            domProps: { value: _vm.lastname },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.lastname =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.formErrors,
                                              errorKey: "lastname",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col-5 pt-2" }, [
                                        _c(
                                          "label",
                                          { attrs: { for: "role" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl("labels.form.role")
                                              ) + " "
                                            ),
                                            _c("small", [_vm._v("*")]),
                                            _vm._v(" "),
                                            _c("feather", {
                                              attrs: { type: "info" },
                                              on: {
                                                mouseover: function ($event) {
                                                  _vm.showInfos = true
                                                },
                                                mouseleave: function ($event) {
                                                  _vm.showInfos = false
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-7" },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.role,
                                                  expression: "role",
                                                },
                                              ],
                                              staticClass: "custom-select mb-0",
                                              attrs: {
                                                name: "role",
                                                id: "role",
                                                required: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.role = $event.target
                                                    .multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                },
                                              },
                                            },
                                            _vm._l(_vm.rolesList, function (r) {
                                              return _c(
                                                "option",
                                                {
                                                  key: r.value,
                                                  domProps: { value: r.value },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.getRoleLabel(
                                                          r.value
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            }),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.formErrors,
                                              errorKey: "role",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm.showInfos
                                      ? _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-12 mb-1" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "border-light b-radius-20 p-4",
                                                },
                                                _vm._l(
                                                  _vm.rolesList,
                                                  function (r) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: r.value,
                                                        staticClass: "row mb-2",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-lg-2 col-md-3 mb-2",
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getRoleLabel(
                                                                    r.value
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-lg-10 col-md-9",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  `infos.roles.${r.value}`
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.role !== "user_without_account"
                                      ? _c("div", { staticClass: "row mb-2" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-5 pt-2" },
                                            [
                                              _c(
                                                "label",
                                                { attrs: { for: "email" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.form.email"
                                                      )
                                                    ) + " "
                                                  ),
                                                  _c("small", [_vm._v("*")]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-7" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.email,
                                                    expression: "email",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "email",
                                                  name: "email",
                                                  id: "email",
                                                  required: "",
                                                },
                                                domProps: { value: _vm.email },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.email =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("ShowErrors", {
                                                staticClass: "d-block",
                                                attrs: {
                                                  errors: _vm.formErrors,
                                                  errorKey: "email",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.role !== "user_without_account"
                                      ? _c("div", { staticClass: "row mb-2" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-5 pt-2" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "mb-0",
                                                  attrs: { for: "tel" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.form.phoneNumber"
                                                      )
                                                    ) + " "
                                                  ),
                                                  _c("small", [_vm._v("*")]),
                                                ]
                                              ),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c(
                                                "small",
                                                { staticClass: "text-muted" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "infos.account.changePhoneNumber"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-7" },
                                            [
                                              _c("vue-tel-input", {
                                                staticClass:
                                                  "mb-2 form-control",
                                                attrs: {
                                                  defaultCountry: "FR",
                                                  name: "telInput",
                                                  enabledCountryCode: true,
                                                  wrapperClasses:
                                                    _vm.phone.isValid ===
                                                      null || _vm.phone.isValid
                                                      ? ""
                                                      : "border border-danger",
                                                  inputClasses: "m-0 border-0",
                                                  mode: "international",
                                                  placeholder: _vm.$t(
                                                    "labels.form.phoneNumber"
                                                  ),
                                                },
                                                on: {
                                                  validate:
                                                    _vm.setPhoneAndCountry,
                                                },
                                                model: {
                                                  value: _vm.phone.raw,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.phone,
                                                      "raw",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "phone.raw",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("ShowErrors", {
                                                staticClass: "d-block",
                                                attrs: {
                                                  errors: _vm.formErrors,
                                                  errorKey: "tel",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("ShowErrors", {
                                                staticClass: "d-block",
                                                attrs: {
                                                  errors: _vm.formErrors,
                                                  errorKey: "tel_country",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("ShowErrors", {
                                                staticClass: "d-block",
                                                attrs: {
                                                  errors: _vm.formErrors,
                                                  errorKey: "tel_is_mobile",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("ShowErrors", {
                                                staticClass: "d-block",
                                                attrs: {
                                                  errors: _vm.formErrors,
                                                  errorKey: "tel_is_valid",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row pt-3" }, [
                                      _c("div", { staticClass: "col-5 pt-2" }, [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.accessibleRestaurants"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-7" },
                                        [
                                          _vm._l(
                                            _vm.available_restaurants,
                                            function (restaurant) {
                                              return _c(
                                                "div",
                                                { key: restaurant.id },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "container-box",
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.restaurants,
                                                            expression:
                                                              "restaurants",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "checkbox",
                                                          name: "restaurants[]",
                                                        },
                                                        domProps: {
                                                          value: restaurant.id,
                                                          checked:
                                                            Array.isArray(
                                                              _vm.restaurants
                                                            )
                                                              ? _vm._i(
                                                                  _vm.restaurants,
                                                                  restaurant.id
                                                                ) > -1
                                                              : _vm.restaurants,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.restaurants,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v =
                                                                  restaurant.id,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  (_vm.restaurants =
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ]))
                                                              } else {
                                                                $$i > -1 &&
                                                                  (_vm.restaurants =
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      ))
                                                              }
                                                            } else {
                                                              _vm.restaurants =
                                                                $$c
                                                            }
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", {
                                                        staticClass:
                                                          "checkmark",
                                                      }),
                                                      _vm._v(
                                                        _vm._s(
                                                          restaurant.name
                                                        ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.formErrors,
                                              errorKey: "restaurants",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.formErrors,
                                              errorKey: "restaurants.*",
                                            },
                                          }),
                                        ],
                                        2
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row pt-3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 pt-2" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.availableRights !==
                                                      null || _vm.rightsLoading,
                                                  expression:
                                                    "availableRights !== null || rightsLoading",
                                                },
                                              ],
                                              staticClass:
                                                "btn btn-sm btn-success btn-circle",
                                              on: {
                                                click: function ($event) {
                                                  _vm.showCustomRights =
                                                    !_vm.showCustomRights
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.$tl("rights.edit")
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "d-block",
                                            attrs: {
                                              errors: _vm.formErrors,
                                              errorKey: "rightsIds",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm.showCustomRights
                                      ? [
                                          _c(
                                            "div",
                                            { staticClass: "row mt-4 mb-4" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.availableRights !==
                                                          null ||
                                                        _vm.rightsLoading,
                                                      expression:
                                                        "availableRights !== null || rightsLoading",
                                                    },
                                                  ],
                                                  staticClass: "col-5",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    { attrs: { for: "role" } },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "rights.title"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.availableRights !==
                                                          null ||
                                                        _vm.rightsLoading,
                                                      expression:
                                                        "availableRights !== null || rightsLoading",
                                                    },
                                                  ],
                                                  staticClass: "col-7",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-success btn-circle",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.checkAllRights(
                                                            true
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.checkAll"
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-success btn-circle",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.checkAllRights(
                                                            false
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.uncheckAll"
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.rightsLoading
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c("LoaderComponent", {
                                                    staticClass: "col-12",
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm.availableRights !== null
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-12" },
                                                    _vm._l(
                                                      Object.keys(
                                                        _vm.activeModules
                                                      ),
                                                      function (activeModule) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: activeModule,
                                                            staticClass:
                                                              "pb-3 border-top-light",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-12 mt-4 mb-2",
                                                                  },
                                                                  [
                                                                    _c("h5", [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "container-box",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-bottom":
                                                                                "0",
                                                                              width:
                                                                                "100%",
                                                                              color:
                                                                                "#30a4b7",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      _vm
                                                                                        .checkAllRightsForSaas[
                                                                                        activeModule
                                                                                      ],
                                                                                    expression:
                                                                                      "checkAllRightsForSaas[activeModule]",
                                                                                  },
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  type: "checkbox",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  checked:
                                                                                    Array.isArray(
                                                                                      _vm
                                                                                        .checkAllRightsForSaas[
                                                                                        activeModule
                                                                                      ]
                                                                                    )
                                                                                      ? _vm._i(
                                                                                          _vm
                                                                                            .checkAllRightsForSaas[
                                                                                            activeModule
                                                                                          ],
                                                                                          null
                                                                                        ) >
                                                                                        -1
                                                                                      : _vm
                                                                                          .checkAllRightsForSaas[
                                                                                          activeModule
                                                                                        ],
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  [
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      var $$a =
                                                                                          _vm
                                                                                            .checkAllRightsForSaas[
                                                                                            activeModule
                                                                                          ],
                                                                                        $$el =
                                                                                          $event.target,
                                                                                        $$c =
                                                                                          $$el.checked
                                                                                            ? true
                                                                                            : false
                                                                                      if (
                                                                                        Array.isArray(
                                                                                          $$a
                                                                                        )
                                                                                      ) {
                                                                                        var $$v =
                                                                                            null,
                                                                                          $$i =
                                                                                            _vm._i(
                                                                                              $$a,
                                                                                              $$v
                                                                                            )
                                                                                        if (
                                                                                          $$el.checked
                                                                                        ) {
                                                                                          $$i <
                                                                                            0 &&
                                                                                            _vm.$set(
                                                                                              _vm.checkAllRightsForSaas,
                                                                                              activeModule,
                                                                                              $$a.concat(
                                                                                                [
                                                                                                  $$v,
                                                                                                ]
                                                                                              )
                                                                                            )
                                                                                        } else {
                                                                                          $$i >
                                                                                            -1 &&
                                                                                            _vm.$set(
                                                                                              _vm.checkAllRightsForSaas,
                                                                                              activeModule,
                                                                                              $$a
                                                                                                .slice(
                                                                                                  0,
                                                                                                  $$i
                                                                                                )
                                                                                                .concat(
                                                                                                  $$a.slice(
                                                                                                    $$i +
                                                                                                      1
                                                                                                  )
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                      } else {
                                                                                        _vm.$set(
                                                                                          _vm.checkAllRightsForSaas,
                                                                                          activeModule,
                                                                                          $$c
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.checkAllRightsForSaasChange(
                                                                                        activeModule
                                                                                      )
                                                                                    },
                                                                                  ],
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "checkmark",
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                            " +
                                                                              _vm._s(
                                                                                activeModule ===
                                                                                  "default"
                                                                                  ? _vm.$tl(
                                                                                      "rights.defaultSaas"
                                                                                    )
                                                                                  : _vm.getModuleTypeLabel(
                                                                                      activeModule
                                                                                    )
                                                                              ) +
                                                                              "\n                                                        "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-12",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row",
                                                                      },
                                                                      _vm._l(
                                                                        _vm.getRightsForSaas(
                                                                          activeModule
                                                                        ),
                                                                        function (
                                                                          right
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: right.id,
                                                                              staticClass:
                                                                                "col-6",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "label",
                                                                                {
                                                                                  staticClass:
                                                                                    "container-box",
                                                                                  staticStyle:
                                                                                    {
                                                                                      "margin-bottom":
                                                                                        "8px",
                                                                                      width:
                                                                                        "100%",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "input",
                                                                                    {
                                                                                      directives:
                                                                                        [
                                                                                          {
                                                                                            name: "model",
                                                                                            rawName:
                                                                                              "v-model",
                                                                                            value:
                                                                                              right.active,
                                                                                            expression:
                                                                                              "right.active",
                                                                                          },
                                                                                        ],
                                                                                      attrs:
                                                                                        {
                                                                                          type: "checkbox",
                                                                                        },
                                                                                      domProps:
                                                                                        {
                                                                                          checked:
                                                                                            Array.isArray(
                                                                                              right.active
                                                                                            )
                                                                                              ? _vm._i(
                                                                                                  right.active,
                                                                                                  null
                                                                                                ) >
                                                                                                -1
                                                                                              : right.active,
                                                                                        },
                                                                                      on: {
                                                                                        change:
                                                                                          [
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              var $$a =
                                                                                                  right.active,
                                                                                                $$el =
                                                                                                  $event.target,
                                                                                                $$c =
                                                                                                  $$el.checked
                                                                                                    ? true
                                                                                                    : false
                                                                                              if (
                                                                                                Array.isArray(
                                                                                                  $$a
                                                                                                )
                                                                                              ) {
                                                                                                var $$v =
                                                                                                    null,
                                                                                                  $$i =
                                                                                                    _vm._i(
                                                                                                      $$a,
                                                                                                      $$v
                                                                                                    )
                                                                                                if (
                                                                                                  $$el.checked
                                                                                                ) {
                                                                                                  $$i <
                                                                                                    0 &&
                                                                                                    _vm.$set(
                                                                                                      right,
                                                                                                      "active",
                                                                                                      $$a.concat(
                                                                                                        [
                                                                                                          $$v,
                                                                                                        ]
                                                                                                      )
                                                                                                    )
                                                                                                } else {
                                                                                                  $$i >
                                                                                                    -1 &&
                                                                                                    _vm.$set(
                                                                                                      right,
                                                                                                      "active",
                                                                                                      $$a
                                                                                                        .slice(
                                                                                                          0,
                                                                                                          $$i
                                                                                                        )
                                                                                                        .concat(
                                                                                                          $$a.slice(
                                                                                                            $$i +
                                                                                                              1
                                                                                                          )
                                                                                                        )
                                                                                                    )
                                                                                                }
                                                                                              } else {
                                                                                                _vm.$set(
                                                                                                  right,
                                                                                                  "active",
                                                                                                  $$c
                                                                                                )
                                                                                              }
                                                                                            },
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.checkReadRightAssociated(
                                                                                                right
                                                                                              )
                                                                                            },
                                                                                          ],
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "checkmark",
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "\n                                                                " +
                                                                                      _vm._s(
                                                                                        _vm.getRightLabel(
                                                                                          right
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                                            "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm.user_notifications_setting &&
                                _vm.role !== "user_without_account"
                                  ? _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12" },
                                        [
                                          _c("EditUserNotificationsSetting", {
                                            staticClass:
                                              "border-light b-radius-20 p-4 mb-3",
                                            attrs: {
                                              user_notifications_setting:
                                                _vm.user_notifications_setting,
                                              enable_mail_notifications:
                                                _vm.enable_mail_notifications,
                                              enable_notification_ringtone:
                                                _vm.enable_notification_ringtone,
                                              tel_is_mobile: _vm.phone.isMobile,
                                            },
                                            on: {
                                              "set-property": _vm.setProperty,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mt-3" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("input", {
                                      staticClass: "btn btn-success btn-circle",
                                      attrs: {
                                        disabled: _vm.rightsLoading,
                                        type: "submit",
                                        name: "submit",
                                      },
                                      domProps: {
                                        value: _vm.$t(
                                          "labels.form.actions.save"
                                        ),
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                  ],
                  1
                ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }