var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "td",
    [
      _c("inline-client", {
        staticClass: "d-inline-block",
        attrs: {
          client: _vm.row.client,
          "module-enum": _vm.MODULE_TYPE_BOOKING,
          "public-comment": _vm.row.comment,
          "private-comment": _vm.row.restaurant_comment,
          "show-noshows": "",
        },
        scopedSlots: _vm._u([
          {
            key: "icons-start",
            fn: function () {
              return [
                _vm.row.gift
                  ? _c("feather", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.getTooltip(_vm.row.gift),
                          expression: "getTooltip(row.gift)",
                        },
                      ],
                      staticClass: "feather-blue",
                      class: { "text-danger": !_vm.row.gv_validity.valid },
                      attrs: { type: "tag" },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _vm.row.client === null
        ? _c("span", { staticClass: "font-italic" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$tl("labels.booking.reservations.noClientLinked")) +
                "\n    "
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }