var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pl-3 pr-3" },
    [
      _vm._l(_vm.servicesToDisplay, function (service, serviceIndex) {
        return _vm.servicesToDisplay.length > 0
          ? _c("div", [
              service.reservations.data && service.reservations.data.length > 0
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-wrap justify-content-between flex-md-nowrap pb-1 mb-2 border-bottom",
                      },
                      [
                        _c(
                          "h6",
                          {
                            staticClass: "pt-1",
                            staticStyle: {
                              "text-transform": "uppercase",
                              "margin-bottom": "0.3em",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "capacite badge badge-secondary" },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(service.name) +
                                    "\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            !service.has_closure
                              ? [
                                  service.is_full === 1
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn badge badge-danger",
                                          staticStyle: {
                                            color: "white !important",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showServiceFull(
                                                service.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm
                                                  .$tl(
                                                    "labels.booking.services.closed"
                                                  )
                                                  .toUpperCase()
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  service.is_full === 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn text-white badge btn-warning",
                                          on: {
                                            click: function ($event) {
                                              return _vm.showServiceFull(
                                                service.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm
                                                  .$tl(
                                                    "labels.booking.services.closedPartially"
                                                  )
                                                  .toUpperCase()
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            service.has_closure
                              ? _c(
                                  "span",
                                  { staticClass: "badge badge-danger" },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm
                                            .$tl(
                                              "labels.booking.closures.title"
                                            )
                                            .toUpperCase()
                                        ) +
                                        "\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("h6", { staticClass: "pt-2 mb-2" }, [
                          _c(
                            "span",
                            { staticClass: "capacite badge badge-secondary" },
                            [
                              _vm._v(
                                _vm._s(service.pax) +
                                  " / " +
                                  _vm._s(
                                    service.new_pax !== null
                                      ? service.new_pax
                                      : service.max_pax
                                  )
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "table",
                      {
                        staticClass:
                          "capitalize table-size-30 table table-book table-striped table-sm mb-3 border-bottom table-tr-clickable",
                      },
                      [
                        _c("thead", { staticClass: "border-bottom" }, [
                          _c("tr", [
                            _c(
                              "th",
                              [
                                _c("feather", {
                                  staticClass: "feather-20 none-desk",
                                  attrs: { type: "user" },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "none-mobile" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.reservations.grid.columns.client"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              [
                                _c("feather", {
                                  staticClass: "feather-20 none-desk",
                                  attrs: { type: "users" },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "none-mobile" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.reservations.grid.columns.pax"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              [
                                _c("feather", {
                                  staticClass: "feather-20 none-desk",
                                  attrs: { type: "clock" },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "none-mobile" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.reservations.grid.columns.hour"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("th", { staticClass: "none-mobile" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.reservations.grid.columns.table",
                                      _vm.restaurantId
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.isFeatRoomNumbersEnable ||
                            _vm.isHotelModeEnabled
                              ? _c("th", { staticClass: "none-mobile" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.reservations.grid.columns.room_number"
                                        )
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("th", { staticClass: "none-mobile" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.reservations.grid.columns.status"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "none-mobile" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.reservations.grid.columns.optionBankStatus"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "th",
                              [
                                _c("feather", {
                                  staticClass: "feather-20 none-desk",
                                  attrs: { type: "mouse-pointer" },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "none-mobile" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.reservations.grid.columns.actions"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(
                            service.reservations.data,
                            function (reservation) {
                              return _c(
                                "tr",
                                {
                                  key: `${service.id}-${reservation.id}`,
                                  class: {
                                    "resa-checked":
                                      reservation.status === "over",
                                    "resa-noshow":
                                      reservation.status === "noshow",
                                    updated:
                                      reservation.updated &&
                                      reservation.updated === true,
                                    new:
                                      reservation.new &&
                                      reservation.new === true,
                                  },
                                  attrs: { id: "resaRow" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showResa(
                                        reservation.id,
                                        $event
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "td",
                                    [
                                      _c("inline-client", {
                                        attrs: {
                                          client: reservation.client,
                                          "public-comment": reservation.comment,
                                          "private-comment":
                                            reservation.restaurant_comment,
                                          "module-enum":
                                            _vm.MODULE_TYPE_BOOKING,
                                          "show-noshows": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "icons-start",
                                              fn: function () {
                                                return [
                                                  reservation.gift
                                                    ? _c("feather", {
                                                        directives: [
                                                          {
                                                            name: "tooltip",
                                                            rawName:
                                                              "v-tooltip",
                                                            value:
                                                              _vm.getTooltip(
                                                                reservation.gift
                                                              ),
                                                            expression:
                                                              "getTooltip(reservation.gift)",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "feather-blue",
                                                        attrs: { type: "tag" },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "table-text-center" },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            reservation.nb_pers * 1 +
                                              reservation.nb_children * 1
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(reservation.slot.hour_start) +
                                        "\n                        "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "table-text-center none-mobile",
                                    },
                                    [
                                      _vm._l(
                                        reservation.tables.data,
                                        function (table, index) {
                                          return reservation.tables.data
                                            .length > 0
                                            ? [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(table.name) +
                                                    _vm._s(
                                                      index + 1 <
                                                        reservation.tables.data
                                                          .length
                                                        ? " - "
                                                        : ""
                                                    ) +
                                                    "\n                            "
                                                ),
                                              ]
                                            : reservation.num_table &&
                                              reservation.num_table !== ""
                                            ? [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      reservation.num_table
                                                    ) +
                                                    "\n                            "
                                                ),
                                              ]
                                            : _vm._e()
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _vm.isFeatRoomNumbersEnable ||
                                  _vm.isHotelModeEnabled
                                    ? _c(
                                        "td",
                                        { staticClass: "none-mobile" },
                                        [
                                          reservation.is_hotel_client
                                            ? _c("hotel-client-icon")
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(reservation.room_number) +
                                              "\n                        "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "none-mobile" },
                                    [
                                      _c("ReservationTag", {
                                        attrs: {
                                          cancellation:
                                            service.cancellation_until,
                                          update: false,
                                          reservation: reservation,
                                          restaurant_id: _vm.restaurant.id,
                                          isStripeEnabled:
                                            _vm.restaurant.stripe_client_id ||
                                            (_vm.restaurant
                                              .payplug_public_key &&
                                              _vm.restaurant
                                                .payplug_secret_key),
                                        },
                                        on: {
                                          displayNoshowModal: function (
                                            $event
                                          ) {
                                            return _vm.displaySetNoshowModal(
                                              reservation
                                            )
                                          },
                                          displayCancelModal: function (
                                            $event
                                          ) {
                                            return _vm.displayCancelModal({
                                              newStatus: $event,
                                              data: reservation,
                                            })
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "table-text-center none-mobile",
                                    },
                                    [
                                      _c("option-bank-status-icon", {
                                        attrs: { reservation: reservation },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm.inEnum(reservation.status, [
                                      _vm.RESERVATION_STATUS_VALIDATED,
                                      _vm.RESERVATION_STATUS_CONFIRMED,
                                    ])
                                      ? _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.getTooltip(
                                                  _vm.$tl(
                                                    "labels.booking.reservations.actions.setAtTable"
                                                  )
                                                ),
                                                expression:
                                                  "getTooltip($tl('labels.booking.reservations.actions.setAtTable'))",
                                              },
                                            ],
                                            staticClass:
                                              "btn btn-sm btn-success btn-square",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setResaAtTable(
                                                  reservation.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("feather", {
                                              staticClass: "feather-18",
                                              staticStyle: {
                                                "pointer-events": "none",
                                              },
                                              attrs: { type: "check" },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.inEnum(reservation.status, [
                                      _vm.RESERVATION_STATUS_OVER,
                                      _vm.RESERVATION_STATUS_AT_TABLE,
                                    ])
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-outline-secondary btn-square",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setResaValidatedOrConfirmed(
                                                  reservation
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("feather", {
                                              staticClass: "feather-18",
                                              staticStyle: {
                                                "pointer-events": "none",
                                              },
                                              attrs: { type: "x" },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.inEnum(reservation.status, [
                                      _vm.RESERVATION_STATUS_NOSHOW,
                                      _vm.RESERVATION_STATUS_AT_TABLE,
                                    ]) && reservation.display_noshow_btn
                                      ? _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.getTooltip(
                                                  _vm.$tl(
                                                    "labels.booking.reservations.actions.setNoShow",
                                                    _vm.restaurantId
                                                  )
                                                ),
                                                expression:
                                                  "getTooltip($tl('labels.booking.reservations.actions.setNoShow', restaurantId))",
                                              },
                                            ],
                                            staticClass:
                                              "btn btn-sm btn-danger btn-square",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.displaySetNoshowModal(
                                                  reservation
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("feather", {
                                              staticClass: "feather-18",
                                              staticStyle: {
                                                "pointer-events": "none",
                                              },
                                              attrs: { type: "alert-triangle" },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              service.reservations.data && service.reservations.data.length > 0
                ? _c(
                    "span",
                    { staticClass: "badge badge-secondary ml-1 mb-4" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tc(
                              "labels.booking.reservations.count",
                              service.reservations.data.length
                            )
                          ) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.services.length > 0 && !_vm.hasReservations
        ? _c("div", [
            _c("div", { staticClass: "row m-0" }, [
              _c(
                "div",
                { staticClass: "col-lg-6 offset-lg-3 pt-5 text-center" },
                [
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4 mb-3" },
                    [
                      _c("h5", [
                        _vm._v(
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.list.emptyForService",
                              _vm.restaurant.id
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "mt-5 none-mobile",
                        attrs: {
                          width: "360",
                          src: "/images/empty-table.png",
                          alt: "No Show",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "mt-3 none-desk",
                        attrs: {
                          width: "260",
                          src: "/images/empty-table.png",
                          alt: "No Show",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "text-white pb-4" }, [_vm._v(".")]),
      _vm._v(" "),
      _vm.showReservationModal
        ? _c("show-reservation-modal", {
            attrs: {
              reservation_id: _vm.selected_reservation_id,
              restaurant_id: _vm.selected_restaurant_id,
            },
            on: {
              "resa-edit": _vm.editReservationFromShowModal,
              close: function ($event) {
                _vm.showReservationModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showEditReservationModal
        ? _c("edit-reservation-modal", {
            attrs: {
              restaurant_id: _vm.selected_restaurant_id,
              reservation_id: _vm.selected_reservation_id,
            },
            on: {
              close: function ($event) {
                _vm.showEditReservationModal = false
              },
              "reservation-edited": _vm.reservationEdited,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSetNoshowModal
        ? _c("set-noshow-modal", {
            attrs: {
              restaurant_id: _vm.selected_restaurant_id,
              reservation: _vm.selected_reservation,
            },
            on: {
              close: function ($event) {
                _vm.showSetNoshowModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showInvalidReservationModal
        ? _c("invalid-reservation-modal", {
            attrs: { reservation: _vm.selected_reservation },
            on: { close: _vm.showInvalidReservationModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showCancelModal
        ? _c("cancel-reservation-modal", {
            attrs: {
              "reservation-id": _vm.selected_reservation_id,
              client: _vm.selected_reservation_client,
              status: _vm.newCancelStatus,
            },
            on: {
              close: function ($event) {
                _vm.showCancelModal = false
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }