<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Réinitialiser les mails et sms de test (3 maximum)</h5>
        <div slot="body">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-around my-2">
                        <button
                            type="button"
                            :disabled="isLoading"
                            class="modal-default-button btn btn-success btn-circle"
                            @click.prevent.stop="resetRateLimiter('send-emails-test')">
                            Réinitialiser les envois de mails
                        </button>
                        <button
                            type="button"
                            :disabled="isLoading"
                            class="modal-default-button btn btn-success btn-circle"
                            @click.prevent.stop="resetRateLimiter('send-sms-test')">
                            Réinitialiser les envois de sms
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="d-flex w-100 justify-center">
            <button type="button" class="modal-default-button btn-sm btn btn-secondary btn-circle" @click.prevent.stop="close">Fermer</button>
        </div>
    </modal>
</template>
<script>
export default {
    props: {
        userId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        resetRateLimiter(rateLimiterName) {
            this.isLoading = true;
            this.httpPut(`/api/campaigns/admin/campaigns/reset-limiter-campaigns/${this.userId}/${rateLimiterName}`).finally(
                () => (this.isLoading = false)
            );
        },
    },
};
</script>
