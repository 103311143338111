<template>
    <div>
        <div class="row">
            <div class="col">{{ $tl("labels.form.psp.pspCard") }}</div>
            <div class="col radio">
                <template v-for="cardPaymentSystem in ALL_CARD_PAYMENT_SYSTEMS">
                    <label class="container-box" :key="cardPaymentSystem.value">
                        <input type="radio" v-model="prefered_payment_system_" :value="cardPaymentSystem.value" :disabled="isLoading" />
                        <span class="checkmark"></span> {{ cardPaymentSystem.label }}
                    </label>
                </template>
            </div>
            <div class="col-sm-2" v-if="isStandAlone">
                <button type="button" class="btn btn-success btn-circle btn-sm" @click.prevent="saveSystemPayment" :disabled="isLoading">
                    {{ $tl("labels.form.actions.save") }}
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <keep-alive>
                    <component :is="cardPaymentSystemLinkComponent" :restaurant_id="restaurantId_" @done="$emit('done')"> </component>
                </keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
import StripeConnection from "./StripeConnection";
import PayPlugConnection from "./PayPlugConnection";
import CardPaymentSystemEnum from "../../../mixins/enums/CardPaymentSystemEnum";

export default {
    data() {
        return {
            prefered_payment_system__: null,
            isLoading: false,
        };
    },
    mixins: [CardPaymentSystemEnum],
    props: {
        prefered_payment_system: {
            type: String,
            required: true,
        },
        restaurantId: {
            default: null,
        },
        isStandAlone: {
            default: false,
        },
    },
    computed: {
        restaurantId_() {
            return this.restaurantId || this.$route.params.restaurant_id;
        },
        cardPaymentSystemLinkComponent() {
            if (this.inEnum(this.prefered_payment_system_, this.ALL_CARD_PAYMENT_SYSTEMS))
                return `${this.getCardPaymentSystemLabel(this.prefered_payment_system_)}Connection`;
            return undefined;
        },
        prefered_payment_system_: {
            get() {
                return this.prefered_payment_system__ || this.prefered_payment_system;
            },
            set(newVal) {
                this.$emit("set-prefered-payment-system", newVal);
                this.prefered_payment_system__ = newVal;
            },
        },
    },
    methods: {
        saveSystemPayment() {
            this.isLoading = true;
            this.httpPut(`/api/restaurants/${this.restaurantId}/preferedPaymentSystem`, {
                prefered_payment_system: this.prefered_payment_system_,
            }).finally(() => (this.isLoading = false));
        },
    },
    components: {
        StripeConnection,
        PayPlugConnection,
    },
};
</script>
