function calcNbElementsPerColumn(totalElements, nbColumns, result = []) {
    const nbElementsForColumn = Math.ceil(totalElements / nbColumns);

    result.push(nbElementsForColumn);

    if (nbColumns > 1) {
        return calcNbElementsPerColumn(totalElements - nbElementsForColumn, nbColumns - 1, result);
    }

    return result;
}

const sortElementsForGrid = function (elements, nbColumns) {
    const nbElementsPerColumn = calcNbElementsPerColumn(elements.length, nbColumns);

    const sortedElements = [];
    let i = 0;
    while (sortedElements.length < elements.length) {
        let j = 0;

        while (j < nbColumns) {
            const sum = j > 0 ? nbElementsPerColumn.slice(0, j).reduce((acc, v) => acc + v, i) : i;

            if (i < nbElementsPerColumn[j]) {
                sortedElements.push(elements[sum]);
            }

            j++;
        }

        i++;
    }

    return sortedElements;
};

export { sortElementsForGrid };
