<template>
    <div>
        <loader-component v-if="isLoading" />
        <div>
            <p class="text-center">
                <span class="d-block" v-for="(tmpMsg, index) in message" :key="index">
                    {{ tmpMsg }}
                </span>
            </p>
            <div class="d-flex align-items-center justify-content-center w-100 text-center">
                <button
                    type="button"
                    class="btn btn-secondary btn-circle d-flex align-items-center justify-content-between mr-2"
                    @click="$emit('close')">
                    <feather type="list" class="feather-list mr-1"></feather>
                    {{ $tl("labels.booking.reservations.viewList") }}
                </button>
                <button
                    type="button"
                    class="btn btn-success btn-circle d-flex align-items-center justify-content-between mr-2"
                    @click="goToSeatingPlanBuilder">
                    <feather type="plus" class="feather-plus mr-1"></feather>
                    {{ $tl("labels.booking.seatingPlan.add") }}
                </button>
                <button
                    v-if="hasASeatingPlan"
                    type="button"
                    class="btn btn-success btn-circle d-flex align-items-center justify-content-between mr-2"
                    @click="goToServiceEdit">
                    <feather type="edit" class="feather-edit mr-1"></feather>
                    {{ $tl("labels.booking.reservations.editService", $route.params.restaurant_id) }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";

export default {
    components: {
        LoaderComponent,
    },
    props: {
        service: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            hasASeatingPlan: false,
        };
    },
    computed: {
        message() {
            return this.hasASeatingPlan ? this.$tl("infos.booking.seatingPlan.notLinked") : this.$tl("infos.booking.seatingPlan.needed");
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
    },
    created() {
        this.fetchHasSeatingPlan();
    },
    methods: {
        goToSeatingPlanBuilder() {
            this.$router.push({
                name: "booking.restaurants.settings.seatingPlan",
                params: { restaurant_id: this.$route.params.restaurant_id, seating_plan_id: "add" },
            });
        },
        goToServiceEdit() {
            let name = "";

            if (this.service.special) {
                name = "booking.restaurants.settings.openings.edit";
            } else {
                name = "booking.restaurants.settings.services.edit";
            }

            this.$router.push({
                name,
                params: {
                    restaurant_id: this.$route.params.restaurant_id,
                    service_id: this.service.id,
                },
            });
        },
        fetchHasSeatingPlan() {
            this.isLoading = true;
            this.httpGet(`/api/restaurants/${this.restaurantId}/seatingPlans`, { handleReject: false })
                .then((response) => {
                    if (response !== false && response.data.data.length > 0) {
                        this.hasASeatingPlan = true;
                    }
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
