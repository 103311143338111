<template>
    <div class="row mb-3">
        <div class="col-12">
            <loader-component v-if="nbLoading > 0" />
            <div v-else-if="stats.main" class="border-light b-radius-20 p-4">
                <div class="row pointer" @click="showClientLoyal = !showClientLoyal">
                    <strong class="col-10 d-flex align-items-center">
                        {{ $tl("labels.booking.stats.charts.clientLoyal.title") }}
                    </strong>
                    <div class="d-flex justify-content-end align-items-center lead-switch col-2">
                        <button
                            class="btn btn-sm btn-square"
                            type="button"
                            :class="pinClientLoyal ? 'btn-success' : 'btn-secondary'"
                            @click.stop.prevent="pinClientLoyal = !pinClientLoyal">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="18px" height="18px" fill="currentColor">
                                <path
                                    d="M300.8 203.9L290.7 128H328c13.2 0 24-10.8 24-24V24c0-13.2-10.8-24-24-24H56C42.8 0 32 10.8 32 24v80c0 13.2 10.8 24 24 24h37.3l-10.1 75.9C34.9 231.5 0 278.4 0 335.2c0 8.8 7.2 16 16 16h160V472c0 .7 .1 1.3 .2 1.9l8 32c2 8 13.5 8.1 15.5 0l8-32c.2-.6 .2-1.3 .2-1.9V351.2h160c8.8 0 16-7.2 16-16 .1-56.8-34.8-103.7-83.1-131.3zM33.3 319.2c6.8-42.9 39.6-76.4 79.5-94.5L128 96H64V32h256v64h-64l15.3 128.8c40 18.2 72.7 51.8 79.5 94.5H33.3z" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div v-if="showClientLoyal || pinClientLoyal" class="row mt-3">
                    <div
                        class="pl-1 pl-xl-3 pr-3 pr-xl-1 pb-2 pb-xl-0"
                        :class="filters.hasComparison && stats.comparison ? 'col-xl-6 col-12' : 'col-xl-12'">
                        <div
                            class="p-1 d-flex flex-column align-items-center h-100"
                            :class="filters.hasComparison && stats.comparison ? 'border-light' : 'col-xl-12'">
                            <strong class="text-success py-3">{{ labelPeriodMain }}</strong>
                            <div v-if="stats.main && stats.main.length === 0" class="h-100 d-flex align-items-center">
                                <strong>
                                    {{ $tl("labels.booking.stats.noDataOnPeriod") }}
                                </strong>
                            </div>
                            <v-chart
                                v-else
                                class="chart"
                                :option="{
                                    title: clientLoyal.title,
                                    series: pieChartClientLoyal,
                                    tooltip: tooltipPeriod,
                                    grid: grid,
                                }">
                            </v-chart>
                        </div>
                    </div>
                    <div v-if="filters.hasComparison && stats.comparison" class="col-xl-6 col-12 pl-1 pr-3">
                        <div class="border-light b-radius-20 p-1 d-flex flex-column align-items-center h-100">
                            <strong class="text-success py-3">{{ labelPeriodComparison }}</strong>
                            <div v-if="stats.comparison && stats.comparison.length === 0" class="h-100 d-flex align-items-center">
                                <strong>
                                    {{ $tl("labels.booking.stats.noDataOnPeriod") }}
                                </strong>
                            </div>
                            <v-chart
                                v-else
                                class="chart"
                                :option="{
                                    title: clientLoyal.title,
                                    series: pieChartClientLoyalComparison,
                                    tooltip: tooltipPeriod,
                                    grid: grid,
                                }">
                            </v-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VChart from "vue-echarts";
import LoaderComponent from "../LoaderComponent.vue";
import StatsUtils from "../../mixins/helpers/booking/StatsUtils.js";

export default {
    name: "ClientLoyalComponent",
    props: {
        grid: {
            required: true,
            type: Object,
        },
        filters: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            showClientLoyal: false,
            clientLoyal: {
                title: {
                    text: this.$tl("labels.booking.stats.charts.clientLoyal.title"),
                    left: "center",
                    textStyle: {
                        fontWeight: "normal",
                    },
                },
            },
            stats: {
                main: null,
                comparison: null,
            },
        };
    },
    mixins: [StatsUtils],
    computed: {
        pinClientLoyal: {
            get() {
                return this.$store.getters["userPreferences/pinClientLoyal"];
            },
            set(newVal) {
                this.$store.dispatch("userPreferences/setPinClientLoyal", newVal);
            },
        },
        pieChartClientLoyal() {
            return [
                {
                    type: "pie",
                    data: this.seriesPieChartClientLoyal,
                    color: ["#5490ff", "#8c54ff", "#bc43e1", "#d343bc"],
                },
            ];
        },
        seriesPieChartClientLoyal() {
            const stats = this.stats.main;
            return this.mapTransToSeries(stats);
        },
        pieChartClientLoyalComparison() {
            return [
                {
                    type: "pie",
                    data: this.seriesPieChartClientLoyalComparison,
                    color: ["#5490ff", "#8c54ff", "#bc43e1", "#d343bc"],
                },
            ];
        },

        seriesPieChartClientLoyalComparison() {
            const stats = this.stats.comparison;
            return this.mapTransToSeries(stats);
        },
        tooltipPeriod() {
            return {
                trigger: "item",
                formatter: (params) => {
                    const so = this.$tl("labels.booking.stats.charts.tooltip.so");
                    const nb = params.value;
                    const reservations = this.$tcl("labels.booking.stats.charts.tooltip.reservations", nb);
                    return `<b>${params.name}</b><br />${reservations}, ${so} ${params.percent}%<br/>`;
                },
            };
        },
    },
    methods: {
        fetchStats(abortControllerSignal = null, forComparison = false) {
            const filters = this.getFiltersQueryParams(forComparison);

            return this.httpGet(`/api/stats/reservations-count-per-client-loyalty-level?${filters}`, {
                config: { signal: abortControllerSignal },
                handleReject: false,
            }).then((response) => {
                if (response !== false) {
                    this.stats[forComparison ? "comparison" : "main"] = response.data;
                }
            });
        },
        mapTransToSeries(stats) {
            const translationMap = {
                null: this.$tl("labels.clients.loyalties.none"),
                1: this.$tl("labels.clients.loyalties.1"),
                2: this.$tl("labels.clients.loyalties.2"),
                3: this.$tl("labels.clients.loyalties.3"),
                4: this.$tl("labels.clients.loyalties.4"),
            };

            return stats.map((item) => ({
                name: translationMap[item.booking_loyalty_level] || this.$tl("labels.clients.loyalties.unknown"),
                value: item.count_reservations,
            }));
        },
    },
    components: { LoaderComponent, VChart },
    watch: {
        pinClientLoyal(newVal) {
            if (!newVal) {
                this.showClientLoyal = false;
            }
        },
    },
};
</script>

<style scoped>
button.btn:disabled {
    cursor: default;
}

.chart {
    height: 450px;
}
</style>
