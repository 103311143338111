<template>
    <div class="row m-0 p-2 pt-5">
        <tab-bar class="border-right col-2 pr-0" side="left" direction="vertical" :entries="tabBarEntries" />
        <router-view class="col-10 pl-0" />
    </div>
</template>

<script>
import TabBar from "../../../components/tabBar/TabBar";

export default {
    name: "Services",
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        tabBarEntries() {
            return [
                {
                    to: { name: "booking.restaurants.settings.services", param: { restaurant_id: this.restaurant_id } },
                    title: this.$tl("labels.routes.booking.services.current", this.restaurant_id),
                    exactUrlMatching: true,
                    rights: ["booking.service.*"],
                },
                {
                    to: { name: "booking.restaurants.settings.openings", param: { restaurant_id: this.restaurant_id } },
                    title: this.$tl("labels.routes.booking.services.special"),
                    exactUrlMatching: false,
                    rights: ["booking.service.*"],
                },
                {
                    to: { name: "booking.restaurants.settings.closures", param: { restaurant_id: this.restaurant_id } },
                    title: this.$tl("labels.routes.booking.services.closures"),
                    exactUrlMatching: false,
                    rights: ["booking.service.*"],
                },
                {
                    to: { name: "booking.restaurants.settings.menus", param: { restaurant_id: this.restaurant_id } },
                    title: this.$tl("labels.routes.booking.services.menus", this.restaurant_id),
                    exactUrlMatching: false,
                    rights: ["booking.menu.*"],
                },
                {
                    to: {
                        name: "booking.restaurants.settings.menus_options",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: this.$tl("labels.routes.booking.services.menusOptions", this.restaurant_id),
                    exactUrlMatching: false,
                    rights: ["booking.menu.*"],
                },
            ];
        },
    },
    components: {
        TabBar,
    },
};
</script>
