<template>
    <div class="stats-vue p-0 padding-vue pb-5 sevices-report">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">{{ $tl("labels.routes.booking.stats") }}</h5>
                    <button
                        v-if="has_right_to_export_stats && !isAdmin"
                        type="button"
                        class="btn btn-sm btn-circle btn-outline-secondary"
                        :disabled="filters === null"
                        @click="excelExport">
                        <feather type="download-cloud" class="mr-1" />{{ $tl("labels.form.actions.excelExport") }}
                    </button>
                </div>
            </div>
        </div>
        <div class="pt-0 pr-3 pb-3 pl-3">
            <div class="row mb-3">
                <div class="col-12">
                    <booking-stats-filters ref="filters" @filters-updated="onFiltersUpdated"></booking-stats-filters>
                </div>
            </div>

            <template v-if="filters !== null">
                <div class="row m-0 mb-2">
                    <div class="col-12">
                        <strong class="text-success">{{ labelPeriodMain }}</strong>
                        <span v-if="filters.hasComparison">
                            {{ $tl("labels.filters.comparedTo") }}
                            <strong class="text-success">{{ labelPeriodComparison }}</strong>
                        </span>
                    </div>
                </div>

                <origin-booking-component :filters="filters" :grid="grid" :has-rwg="hasEnabledGoogleReserve" />
                <nb-pax-by-origin :filters="filters" :grid="grid" :has-rwg="hasEnabledGoogleReserve" />
                <waiting-list-component :filters="filters" :grid="grid" />
                <OtherStatsComponent :filters="filters" />

                <component v-for="graph in orderGraphs" :is="graph.name" :filters="filters" :grid="grid" :key="graph.name" />
            </template>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import DatePicker from "../../components/forms/DatePicker.vue";
import { DateTime } from "luxon";
import ArrowPercentEvolutionComponent from "../../components/charts/ArrowPercentEvolutionComponent";
import LangsEnum from "../../mixins/enums/LangsEnum";
import DefaultServiceCategoriesEnum from "../../mixins/enums/booking/DefaultServiceCategoriesEnum";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart, PieChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from "echarts/components";
import VChart from "vue-echarts";
import showNewBadgeDetails from "../../components/NewBadges/showNewBadgeDetails.vue";
import OriginBookingComponent from "../../components/charts/OriginBookingComponent.vue";
import NbPaxByOrigin from "../../components/charts/NbPaxByOriginComponent.vue";
import WaitingListComponent from "../../components/charts/WaitingListComponent.vue";
import OtherStatsComponent from "../../components/charts/OtherStatsComponent.vue";
import PercentResaPerPaxComponent from "../../components/charts/PercentResaPerPaxComponent.vue";
import EvolutionNbResaComponent from "../../components/charts/EvolutionNbResaComponent.vue";
import CaPerMenuComponent from "../../components/charts/CaPerMenuComponent.vue";
import CaPerGeneralOptionComponent from "../../components/charts/CaPerGeneralOptionComponent.vue";
import CaPerMenuOptionComponent from "../../components/charts/CaPerMenuOptionComponent.vue";
import PercentOfMenuSoldComponent from "../../components/charts/PercentOfMenuSoldComponent.vue";
import PercentOfMenuOptionSoldComponent from "../../components/charts/PercentOfMenuOptionSoldComponent.vue";
import PercentOfGeneralOptionSoldComponent from "../../components/charts/PercentOfGeneralOptionSoldComponent.vue";
import BookingsPeriodComponent from "../../components/charts/BookingsPeriodComponent.vue";
import ClientLoyalComponent from "../../components/charts/ClientLoyalComponent.vue";
import StatsUtils from "../../mixins/helpers/booking/StatsUtils.js";
import BookingStatsFilters from "../../components/charts/BookingStatsFilters.vue";

use([CanvasRenderer, BarChart, LineChart, PieChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);

export default {
    data() {
        return {
            DateTime,
            stat_comparison: false,
            stat_period: "today",
            comparison_period: "previous_period",
            from_date: null,
            to_date: null,
            from_date_comparison: null,
            to_date_comparison: null,
            filter_category: null,
            service_categories: [],
            filters: null,
            displaying: {
                stat_comparison: false,
                stat_period: "today",
                comparison_period: "previous_period",
                from_date: null,
                to_date: null,
                from_date_comparison: null,
                to_date_comparison: null,
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "5%",
                containLabel: true,
            },
            componentGraph: [
                {
                    name: "PercentResaPerPaxComponent",
                },
                {
                    name: "CaPerMenuComponent",
                },
                {
                    name: "PercentOfMenuSoldComponent",
                },
                {
                    name: "CaPerMenuOptionComponent",
                },
                {
                    name: "PercentOfMenuOptionSoldComponent",
                },
                {
                    name: "CaPerGeneralOptionComponent",
                },
                {
                    name: "PercentOfGeneralOptionSoldComponent",
                },
                {
                    name: "EvolutionNbResaComponent",
                },
                {
                    name: "BookingsPeriodComponent",
                },
                {
                    name: "ClientLoyalComponent",
                },
            ],
        };
    },
    mixins: [LangsEnum, DefaultServiceCategoriesEnum, StatsUtils],
    methods: {
        onFiltersUpdated(filters) {
            this.filters = filters;
        },
        excelExport() {
            if (!this.$refs.filters.emitFiltersUpdate()) {
                return;
            }

            if (this.filters.main.selectedRestaurants.length !== 1) {
                throw "Should not happen";
            }

            this.$nextTick(() => {
                let params = `&compare=${+this.filters.hasComparison}`;

                if (this.filters.main.fromDate !== null) {
                    params += `&from=${this.filters.main.fromDate.toISODate()}`;
                }
                if (this.filters.main.toDate !== null) {
                    params += `&to=${this.filters.main.toDate.toISODate()}`;
                }
                if (this.filters.serviceCategory !== null) {
                    params += `&service_category=${this.filters.serviceCategory}`;
                }
                if (this.filters.hasComparison) {
                    if (this.filters.comparison.fromDate !== null) {
                        params += `&from_comparison=${this.filters.comparison.fromDate.toISODate()}`;
                    }
                    if (this.filters.comparison.toDate !== null) {
                        params += `&to_comparison=${this.filters.comparison.toDate.toISODate()}`;
                    }
                }

                window.open(this.addLangToURL(`/api/stats/${this.filters.main.selectedRestaurants[0].id}/excel?${params}`), "_blank");
            });
        },
    },
    computed: {
        isAdmin() {
            return this.$store.getters["users/role"] === "admin";
        },
        orderGraphs() {
            const states = {
                PercentResaPerPaxComponent: this.$store.getters["userPreferences/pinPax"],
                CaPerMenuComponent: this.$store.getters["userPreferences/pinCaPerMenu"],
                PercentOfMenuSoldComponent: this.$store.getters["userPreferences/pinPercentOfMenuSold"],
                CaPerMenuOptionComponent: this.$store.getters["userPreferences/pinCaPerMenuOption"],
                PercentOfMenuOptionSoldComponent: this.$store.getters["userPreferences/pinPercentOfMenuOptionSold"],
                CaPerGeneralOptionComponent: this.$store.getters["userPreferences/pinCaPerGeneralOption"],
                PercentOfGeneralOptionSoldComponent: this.$store.getters["userPreferences/pinPercentOfGeneralOptionSold"],
                EvolutionNbResaComponent: this.$store.getters["userPreferences/pinEvolutionNbResa"],
                BookingsPeriodComponent: this.$store.getters["userPreferences/pinBookingsPeriod"],
                ClientLoyalComponent: this.$store.getters["userPreferences/pinClientLoyal"],
            };

            return this.componentGraph.sort((a, b) => -(+states[a.name] - +states[b.name]));
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_export_stats() {
            return this.rights.includes("booking.statistic.export");
        },
        hasEnabledGoogleReserve() {
            return this.$store.getters["widgets/getWidget"].enable_google_reserve || false;
        },
    },
    components: {
        BookingStatsFilters,
        showNewBadgeDetails,
        LoaderComponent,
        ArrowPercentEvolutionComponent,
        DatePicker,
        VChart,
        OriginBookingComponent,
        NbPaxByOrigin,
        WaitingListComponent,
        OtherStatsComponent,
        PercentResaPerPaxComponent,
        CaPerMenuComponent,
        CaPerGeneralOptionComponent,
        CaPerMenuOptionComponent,
        EvolutionNbResaComponent,
        PercentOfMenuSoldComponent,
        PercentOfMenuOptionSoldComponent,
        PercentOfGeneralOptionSoldComponent,
        BookingsPeriodComponent,
        ClientLoyalComponent,
    },
};
</script>

<style scoped>
button.btn:disabled {
    cursor: default;
}
</style>
