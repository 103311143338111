var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    { staticClass: "modal-add-service", on: { close: _vm.closeFromModal } },
    [
      _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.isSpecial
                ? _vm.$tl("labels.booking.services.special.add")
                : _vm.$tl("labels.booking.services.add", _vm.restaurant_id)
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _c("add-service-form", {
            attrs: { isSpecial: _vm.isSpecial },
            on: { "service-added": _vm.serviceAdded, close: _vm.close },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }