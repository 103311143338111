var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "table table-sm table-striped border-bottom" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.cc_commands, function (command) {
          return _c("tr", { key: command.id }, [
            _c("td", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.moment(command.reservation_date).format("LL")) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n                " +
                  _vm._s(command.restaurant.name) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              command.collect_type === "collect"
                ? _c("span", [
                    _vm._v(_vm._s(_vm.formatHour(command.slot.hour))),
                  ])
                : _vm._e(),
              _vm._v(" "),
              command.collect_type === "delivery"
                ? _c("span", [
                    _vm._v(_vm._s(_vm.formatHour(command.slot.hour_start))),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.getCleanName(command)) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(_vm.formatCurrency(command.total_amount / 100))),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        delay: { show: 400, hide: 0 },
                        content: "Voir la commande",
                      },
                      expression:
                        "{ delay: { show: 400, hide: 0 }, content: 'Voir la commande' }",
                    },
                  ],
                  staticClass: "btn btn-sm btn-outline-secondary btn-square",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("show-cc-command", command)
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "feather feather-eye sc-dnqmqq jxshSx",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none",
                        stroke: "currentColor",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "aria-hidden": "true",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z",
                        },
                      }),
                      _vm._v(" "),
                      _c("circle", { attrs: { cx: "12", cy: "12", r: "3" } }),
                    ]
                  ),
                ]
              ),
            ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "border-bottom" }, [
      _c("tr", [
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Établissement")]),
        _vm._v(" "),
        _c("th", [_vm._v("Horaire")]),
        _vm._v(" "),
        _c("th", [_vm._v("Statut")]),
        _vm._v(" "),
        _c("th", [_vm._v("Montant")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }