<template>
    <div>
        <div class="mt-3" v-if="sale.logs && sale.logs.data && sale.logs.data.length > 0">
            <ShowSaleLog v-for="log in sale.logs.data" :key="log.id" :log="log" />
        </div>
        <span class="text-muted mt-3 d-inline-block" v-else
            >Aucune modification n'a été enregistrée pour cette vente.</span
        >
    </div>
</template>

<script>
import ShowSaleLog from "./ShowSaleLog";

export default {
    props: {
        sale: {
            type: Object,
            required: true,
        },
    },
    components: {
        ShowSaleLog,
    },
};
</script>
