import axios from "axios";

export const actions = {
    fetchSlotsFutureCommands({}, { params, restaurant_id }) {
        return axios.get(
            `/api/click_and_collect/${restaurant_id}/settings/services/slots/commands?include=command_payment,client,slot,command_products,command_products.product,command_products.command_product_option.product_option.cc_product`,
            {
                params,
            }
        );
    },
    fetchConfiguration({}, { restaurant_id }) {
        return axios.get(`/api/click_and_collect/${restaurant_id}/settings`);
    },
};

export default actions;
