export default {
    booking: {
        externalMenus: `Cette fonctionnalité permet la consultation de la carte du restaurant par le client lors de sa réservation. Cette visualisation peut être sous forme d'un fichier PDF ou d'une page web dédiée (renseignez l'URL de cette page).`,
        dashboard:
            "Piloter mes disponibilités : vous pouvez passer une plage horaire en complet, bloquer certains créneaux ou bloquer des PAX en cliquant directement sur la case de la plage horaire concernée.",
        servicesConfig: {
            serviceName: `Ce nom s'affichera uniquement pour vous et ne sera pas visible par vos clients.<br/> Il doit vous permettre de différencier deux services, par exemple Midi semaine - Midi week-end si vous avez créé deux services distincts.`,
            activeDays: `Si vous avez des conditions de réservation qui varient entre plusieurs services, pensez à créer des services différents.<br/> Par exemple, si les créneaux de réservation ne sont pas identiques la semaine du week-end, vous devrez créer 2 services : un qui sera actif pour les jours de la semaine, un autre pour les jours du week-end.`,
            paxMaxPerService: `Cette valeur sera prise en compte pour le calcul de confirmation des réservations.<br/> Si vous activez le remplissage automatique en fonction de votre plan de salle, cette valeur sera tout de même prise en compte pour limiter le nombre de couverts du service.<br/> Si vous activez la rotation, la capacité d'accueil est considérée comme étant la capacité en temps réel (par exemple 20 couverts en même temps dans le restaurant).`,
            limitReservation: `Si vous souhaitez prendre des réservations jusqu'à l'heure de début de votre service, la valeur à indiquer est 0.`,
            cancellationPeriod: `Le délai d'annulation autorisé sera mentionné au client lorsqu'il effectue un dépôt d'empreinte bancaire.<br/> Il est informé que passé ce délai, sa réservation pourra être considérée comme un no-show.<br/> Ce délai est également pris en compte pour que le client puisse annuler sa réservation en toute autonomie grâce au bouton "modifier ma réservation" présent dans l'email de confirmation de réservation.<br/> Une fois le délai dépassé, le client ne peut plus annuler sa réservation.<br/> Enfin, cette valeur sert également pour l'envoi du rappel de réservation qui est envoyé 12h avant le délai d'annulation mentionné.`,
            tableRotation: `Si vous proposez plusieurs services, cette fonctionnalité est idéale pour avoir une vue d'ensemble de vos réservations et utiliser la vue timeline (vous aurez une visibilité sur les tables réattribuées).`,
            bankFootprint: `En cas d'activation de l'empreinte bancaire, votre client devra automatiquement passer par cette étape pour valider sa réservation.<br/> Il est important de noter qu'une empreinte bancaire n'est valable que 7 jours.<br/> Dans le cas où la réservation est effectuée pour dans moins de 6 jours, l'empreinte bancaire sera immédiatement demandée à la dernière étape du widget de réservation qui sera intégré à votre site internet.<br/> Dans le cas où la réservation est prise pour une date ultérieure aux 6 prochains jours, le client recevra un mail lui mentionnant qu'une empreinte bancaire lui sera demandée 6 jours avant sa réservation.<br/> 6 jours avant la dite réservation, le client recevra automatiquement une notification (mail ou sms selon vos paramètres) pour lui demander de procéder à la confirmation de sa réservation par le biais de son empreinte bancaire.<br/> Si vous souhaitez demander une empreinte bancaire que pour certains services, pensez à créer plusieurs services distincts.<br/> Pour pouvez également désactiver l'empreinte bancaire pour les clients habitués et VIP en allant dans l'onglet Clients > Options.<br/> Pour activer l'empreinte bancaire, vous devez impérativement avoir connecté au préalable un compte Stripe.`,
            exceptionalOpening: `Si vous êtes ouvert habituellement pendant ces dates, l'ouverture exceptionnelle prendre le dessus sur vos services habituels.<br/> Si vous n'êtes pas ouvert habituellement sur le jour concerné, la disponibilité s'affichera tout simplement sur votre module de réservation.`,
            exceptionalClosureConcernedServices: `Si vous souhaitez fermer uniquement un des services (et non l'ensemble), vous pouvez sélectionner uniquement le ou les services concernés.<br/> Cela peut-être utilisé par exemple dans le cadre d'une privatisation ou d'un changement temporaire des services proposés.`,
            uniqueMenu: `Vos clients devront impérativement choisir un seul menu pour l'ensemble de la table.<br/> Vous pouvez rendre le choix de menu unique à partir d'un certain nombre de personne, par exemple 6 personnes.<br/> Dans ce cas, pour toutes les tables de moins de 6 personnes, les clients pourront choisir différents menus.<br/> Au delà de 6, le menu sera unique.`,
            prepayment: `Vous pouvez mettre en place cette solution pour demander un paiement intégral à la réservation ou pour gérer la prise d'acomptes pour sécuriser vos réservations.<br/> Cette fonctionnalité est nécessairement liée au choix de menus.<br/> Le calcul du montant à régler par le client est basé sur les menus et options sélectionnées.<br/> Cette fonction peut être utilisée par exemple pour demander des acomptes pour les groupes de plus de X personnes.`,
            automaticSeatingPlan: `Cette fonctionnalité vous permet d'utiliser notre plan de salle intelligent et d'automatiser la génération de votre plan de salle.<br/> Le remplissage est basé sur votre configuration (PAX autorisé sur chaque table / groupes de tables / durée des réservations avec la rotation de table).`,
        },
        widget: {
            mainMessage: `Ce message s'affiche avant l'apparition du module de réservation.<br/> Il doit contenir les informations dont le client doit prendre connaissance avant d'effectuer sa réservation.<br/> Par exemple : "Chers clients, Face à l'augmentation significative des no-shows (table réservée non honorée), nous vous informons que toute réservation effectuée à partir de 6 personnes sera soumise à la prise d'une empreinte bancaire. Nous vous remercions pour votre compréhension.<br/> En cas de demande de réservation de plus de 6 personnes, merci de nous contacter par téléphone.<br/> Au plaisir de vous accueillir prochainement !"`,
            slotMessage: `Ce message s'affiche à l'étape 2 du module de réservation.<br/> Il s'agit d'une indication que vous pouvez donner à vos clients.<br/> Par exemple : "Merci de respecter votre heure d'arrivée. En cas de retard de plus de 15 minutes, votre table pourra être réattribuée".`,
            waitingList: `Lorsque votre restaurant affiche complet, le client a la possibilité de s'inscrire sur liste d'attente pour être averti si une table se libère.`,
            waitingListAutoNotif: `Si vous activez les notifications automatiques, dès lors qu'une annulation sera enregistrée, toutes les personnes sur liste d'attente et correspondant à la taille de la table disponible, seront averties.<br/> La priorité sera donnée à celui qui aura réservé en premier.`,
        },
        notifications: {
            notificationsConfig: `Pour chaque type de notification, vous pouvez paramétrer le canal d'envoi souhaité.<br/> Si vous souhaitez envoyer des notifications par SMS, vous devrez au préalable avoir activé les SMS sur votre compte.<br/> Lorsque votre quota de SMS est nul, les notifications mails prennent le relais jusqu'au rechargement de votre compte.<br/> Pour limiter au maximum les no-shows et optimiser votre remplissage, nous vous conseillons l'envoi de deux notifications : <br/>- la demande de confirmation de réservation 24h avant l'heure de la réservation <br/>- la notification d'information qu'une table est disponible pour les clients inscrits sur liste d'attente.`,
            qrCode: `Si vous sélectionnez cette option, un QR Code sera ajouté automatiquement dans le mail de confirmation envoyé au client.<br/> Vous pourrez ensuite le "Flasher" depuis le menu "En Service" pour valider l'arrivée du client et simplifier/améliorer le processus d'accueil de vos clients.`,
        },
        tablePlan: {
            disablingTable: `Si vous désactivez une table, elle n'apparaîtra plus sur la vue plan de salle.<br/> Elle ne sera également plus considérée dans les groupes de table.<br/> Il est donc primordial de faire toutes les mises à jour nécessaires avant l'enregistrement.<br/> La désactivation de la table est enregistrée pour l'ensemble des services sans limitation de durée.<br/> Si vous souhaitez bloquer une table pour un jour précis, nous vous invitons à bloquer cette table directement depuis la vue plan de salle depuis l'onglet Réservations.`,
            disablingRoom: `La désactivation de la salle est enregistrée pour l'ensemble des services sans limitation de durée.<br/> Si vous souhaitez gérer l'ouverture d'une salle en fonction de dates, vous pouvez le faire dans "Configuration de la salle", "Date de début" et "Date de fin".`,
        },
        menus: {
            externalDescription:
                "Vous avez la possibilité d'ajouter une description additionnelle à un menu sous forme d'un fichier PDF ou d'un lien internet vers une page web dédiée. Cette description est directement consultable par le client depuis le widget réservation (ex : Composition du menu, photos des différents plats...)",
        },
    },
    clickAndCollect: {
        config: {
            orderPreparationDelay: `Il s'agit du délai minimum entre lequel le client passe la commande et vient la retirer sur place.<br/> Par exemple, si vous indiquez 30 min de délai de préparation, le client pourra retirer sa commande dès midi pour une commande passée à 11h30.<br/> Sachez qu'une fonctionnalité vous permet de suspendre les nouvelles commandes pendant le service.<br/> Si vous souhaitez que vos clients commandent la veille pour le lendemain, il convient de mettre un délai de 15h à 24h.`,
            commandConfirm: `Cette fonctionnalité vous permet de prendre la main sur l'acceptation des commandes.<br/> Vous pouvez utiliser la confirmation manuelle, dans ce cas toutes les commandes devront être validées par vos soins.<br/> Si vous utilisez la fonction automatique, vous pouvez indiquer des paramètres pour prendre la main que dans certains cas de figure.<br/> Si par exemple, la confirmation est automatique jusqu'à 6 commandes par service, passées 6 commandes sur un même service, vous devrez valider les futures commandes de ce dernier manuellement.<br/> Pour être en 100% automatique, il suffit d'indiquer 0 pour chacun des paramètres.`,
        },
    },
};
