export const mutations = {
    setRestaurants(state, restaurants) {
        state.restaurants = restaurants;
    },
    updateById(state, restaurant) {
        const index = state.restaurants.findIndex((r) => r.id == restaurant.id);

        if (index > -1) {
            state.restaurants.splice(index, 1, restaurant);
        }
    },
    setUnsavedNotificationsChanges(state, unsavedNotificationsChanges) {
        state.unsavedNotificationsChanges = unsavedNotificationsChanges;
    },
};

export default mutations;
