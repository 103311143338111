var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "table table-sm table-striped border-bottom" },
    [
      _c("thead", { staticClass: "border-bottom" }, [
        _c("tr", [
          _c("th", [
            _vm._v(
              _vm._s(
                _vm.$tl("labels.booking.reservations.history.columns.date")
              )
            ),
          ]),
          _vm._v(" "),
          _c("th", [
            _vm._v(
              _vm._s(
                _vm.$tl(
                  "labels.booking.reservations.history.columns.restaurant",
                  _vm.restaurantId
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("th", [
            _vm._v(
              _vm._s(
                _vm.$tl("labels.booking.reservations.history.columns.slot")
              )
            ),
          ]),
          _vm._v(" "),
          _c("th", [
            _vm._v(
              _vm._s(
                _vm.$tl("labels.booking.reservations.history.columns.status")
              )
            ),
          ]),
          _vm._v(" "),
          _c("th", { staticClass: "none-mobile" }, [
            _vm._v(
              _vm._s(
                _vm.$tl("labels.booking.reservations.history.columns.nbPers")
              )
            ),
          ]),
          _vm._v(" "),
          _c("th", { staticClass: "none-desk" }, [
            _vm._v(
              _vm._s(_vm.$tl("labels.booking.reservations.history.columns.pax"))
            ),
          ]),
          _vm._v(" "),
          _c("th", { staticClass: "none-mobile" }, [
            _vm._v(
              _vm._s(
                _vm.$tl(
                  "labels.booking.reservations.history.columns.nbChildren"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("th", { staticClass: "none-desk" }, [
            _vm._v(
              _vm._s(
                _vm.$tl("labels.booking.reservations.history.columns.children")
              )
            ),
          ]),
          _vm._v(" "),
          _c("th", [
            _vm._v(
              _vm._s(
                _vm.$tl("labels.booking.reservations.history.columns.actions")
              )
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.reservations, function (resa) {
          return _c("tr", { key: resa.id }, [
            _c("td", [
              _vm._v(
                _vm._s(
                  _vm.displayDate(resa.reservation_datetime, _vm.DATE_FULL)
                )
              ),
            ]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(resa.restaurant.name))]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                _vm._s(
                  _vm.displayDate(
                    _vm.setHourOnDateTime(resa.slot.hour_start),
                    _vm.TIME_SIMPLE
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.getReservationStatusLabel(resa.status)) +
                    "\n                "
                ),
                resa.is_hotel_client ? _c("hotel-client-icon") : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(resa.nb_pers))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(resa.nb_children || 0))]),
            _vm._v(" "),
            _c("td", [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.getTooltip(
                        _vm.$t(
                          "labels.booking.reservations.history.actions.view"
                        )
                      ),
                      expression:
                        "getTooltip($t('labels.booking.reservations.history.actions.view'))",
                    },
                  ],
                  staticClass: "btn btn-sm btn-outline-secondary btn-square",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("show-resa", resa)
                    },
                  },
                },
                [_c("feather", { attrs: { type: "eye" } })],
                1
              ),
            ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }