<template>
    <div>
        <drop-down
            v-if="reservation.payment"
            :options="options"
            btn-classes-string="btn btn-sm drop-icon-card"
            use-slot
            @option-changed="changeStatusPayment($event)">
            <feather v-if="getPaymentIcon !== ''" :type="getPaymentIcon" :class="payment.iconClasses" v-tooltip="getTooltip(payment.tooltip)" />
        </drop-down>
    </div>
</template>

<script>
import axios from "axios";
import OptionBankEnum from "../../mixins/enums/booking/OptionBankEnum";
import PaymentStatusEnum from "../../mixins/enums/booking/PaymentStatusEnum";
import DropDown from "../forms/DropDown.vue";

export default {
    components: {
        DropDown,
    },
    mixins: [OptionBankEnum, PaymentStatusEnum],
    props: {
        reservation: {
            type: Object,
            required: true,
        },
        restaurant_id: {
            default: undefined,
        },
        translate: {
            type: Function,
            default: undefined,
        },
        timezoneForOverride: {
            type: String,
            default: null,
        },
    },
    computed: {
        payment() {
            if (
                !this.reservation.payment ||
                ![this.OPTION_BANK_FOOTPRINT.value, this.OPTION_BANK_PAYMENT.value].includes(this.reservation.payment.option_bank)
            ) {
                return {};
            }

            const paymentObject = {
                footprint: {
                    icon: "credit-card",
                    iconClasses: this.footprintIconClasses,
                    tooltip: this.footprintTooltip,
                },
                payment: {
                    icon: "dollar-sign",
                    iconClasses: this.paymentIconClasses,
                    tooltip: this.paymentTooltip,
                },
            };

            return paymentObject[this.reservation.payment.option_bank];
        },
        getPaymentIcon() {
            if (typeof this.payment.icon === "string") {
                return this.payment.icon;
            }
            return "";
        },
        options() {
            const options = [];

            const resaDateTimeSubHourHalf = this.getDateTime(this.reservation.reservation_datetime).minus({
                hours: 1,
                minutes: 30,
            });

            if (this.reservation.can_send_reminder_payment) {
                options.push({
                    label: this.$tl("labels.booking.reservations.actions.sendReminderPayment"),
                    value: "send-reminder",
                });
            }

            if (this.hasExpired) {
                options.push({ label: this.$tl("labels.booking.reservations.actions.cancel"), value: "cancel" });
                if (resaDateTimeSubHourHalf >= this.getDateTime()) {
                    options.push({
                        label: this.$tl("labels.booking.reservations.actions.sendAskAgain"),
                        value: "send-again",
                    });
                }
            }

            return options;
        },
        overrideAutoDetectedTimezoneGlobally() {
            return this.timezoneForOverride || null;
        },
        restaurant_id_() {
            if (this.restaurant_id) {
                return this.restaurant_id;
            } else if (this.$route && this.$route.params && this.$route.params.restaurant_id) {
                return this.$route.params.restaurant_id;
            } else {
                return undefined;
            }
        },
        footprintTooltip() {
            if (this.reservation.payment && this.reservation.payment.option_bank === this.OPTION_BANK_FOOTPRINT.value) {
                if (this.reservation.status === "over" && this.reservation.payment.status === this.PAYMENT_STATUS_CANCELED.value) {
                    return this.$tl("labels.booking.reservations.footprint.released");
                }
                if (this.reservation.status === "canceled" || this.reservation.status === "refused") {
                    return this.$tl("labels.booking.reservations.footprint.noOneAndResaCanceled");
                }
                if (this.reservation.status === "noshow" && this.reservation.payment.status === this.PAYMENT_STATUS_CANCELED.value) {
                    return this.$tl("labels.booking.reservations.footprint.notCaptured");
                }
                if (this.reservation.payment.status === this.PAYMENT_STATUS_CAPTURED.value) {
                    return this.$tl("labels.booking.reservations.footprint.captured", this.restaurant_id, {
                        amount: this.formatPrice(this.reservation.payment.amount_captured / 100, this.translate),
                    });
                }
                if (this.reservation.payment.status === this.PAYMENT_STATUS_CAPTURABLE.value) {
                    return this.$tl("labels.booking.reservations.footprint.confirmed", this.restaurant_id, {
                        date: this.displayDate(this.reservation.payment.paid_at, this.DATE_SHORT),
                    });
                }
                if (this.reservation.payment.status === this.PAYMENT_STATUS_NOT_CREATED.value) {
                    let resaDateTime = this.getDateTime(this.reservation.reservation_datetime).minus({
                        days: 6,
                    });
                    return this.$tl("labels.booking.reservations.footprint.pendingNotSent", this.restaurant_id, {
                        date: this.displayDate(resaDateTime, this.DATE_SHORT),
                    });
                }
                if (this.reservation.payment.status === this.PAYMENT_STATUS_PENDING.value) {
                    const limitDateTime = this.getDateTime(this.reservation.payment.stripe_intent_limit_date);

                    return this.$tl("labels.booking.reservations.footprint.pendingSent", this.restaurant_id, {
                        date: this.displayDate(limitDateTime, this.DATE_FULL),
                        hour: this.displayDate(limitDateTime, this.TIME_SIMPLE),
                    });
                }
                if (this.reservation.payment.status === this.PAYMENT_STATUS_EXPIRED.value) {
                    if (this.reservation.status === "canceled" && this.reservation.canceled_automaticaly) {
                        return this.$tl("labels.booking.reservations.footprint.expiredAndResaCanceled");
                    }
                    return this.$tl("labels.booking.reservations.footprint.expired");
                }
            }

            return "";
        },
        paymentTooltip() {
            if (this.reservation.payment.option_bank === this.OPTION_BANK_PAYMENT.value) {
                if (this.reservation.payment.status === this.PAYMENT_STATUS_REFUNDED.value) {
                    return this.$tl("labels.booking.reservations.payment.refundedAndResaCanceled", this.restaurant_id, {
                        amount: this.formatPrice(this.reservation.payment.amount_captured / 100, this.translate),
                        date: this.displayDate(this.reservation.payment.refunded_at, this.DATE_FULL),
                    });
                }
                if (this.reservation.payment.status === this.PAYMENT_STATUS_PARTIALLY_REFUNDED.value) {
                    return this.$tl("labels.booking.reservations.payment.refunded", this.restaurant_id, {
                        total_amount: this.formatPrice(this.reservation.payment.amount_captured / 100, this.translate),
                        refunded_amount: this.formatPrice(this.reservation.payment.amount_refunded / 100, this.translate),
                        date: this.displayDate(this.reservation.payment.refunded_at, this.DATE_FULL),
                    });
                }
                if (this.reservation.payment.status === this.PAYMENT_STATUS_CANCELED.value) {
                    return this.$tl("labels.booking.reservations.payment.noOneAndResaCanceled");
                }
                if (this.reservation.payment.status === this.PAYMENT_STATUS_CAPTURABLE.value) {
                    return this.$tl("labels.booking.reservations.payment.notYetCaptured", this.restaurant_id, {
                        amount: this.formatPrice(Number.parseFloat(this.reservation.payment.stripe_intent_amount) / 100, this.translate),
                        date: this.displayDate(this.reservation.payment.paid_at, this.DATE_FULL),
                    });
                }
                if (this.reservation.payment.status === this.PAYMENT_STATUS_CAPTURED.value) {
                    return this.$tl("labels.booking.reservations.payment.captured", this.restaurant_id, {
                        amount: this.formatPrice(Number.parseFloat(this.reservation.payment.amount_captured) / 100, this.translate),
                        date: this.displayDate(this.reservation.payment.captured_at, this.DATE_FULL),
                    });
                }
                if (this.reservation.payment.status === this.PAYMENT_STATUS_PENDING.value) {
                    return this.$tl("labels.booking.reservations.payment.pending", this.restaurant_id, {
                        date: this.displayDate(this.reservation.payment.stripe_intent_limit_date, this.DATE_FULL),
                        hour: this.displayDate(this.reservation.payment.stripe_intent_limit_date, this.TIME_SIMPLE),
                    });
                }
                if (this.reservation.payment.status === this.PAYMENT_STATUS_EXPIRED.value) {
                    if (this.reservation.status === "canceled" && this.reservation.canceled_automaticaly) {
                        return this.$tl("labels.booking.reservations.payment.expiredAndResaCanceled");
                    } else {
                        return this.$tl("labels.booking.reservations.payment.expired");
                    }
                }
            }

            return "";
        },
        footprintIconClasses() {
            if (this.reservation.payment && this.reservation.payment.option_bank === this.OPTION_BANK_FOOTPRINT.value) {
                if (this.reservation.status === "over" && this.reservation.payment.status === this.PAYMENT_STATUS_CANCELED.value) {
                    return "text-color";
                }
                if (this.reservation.status === "canceled" || this.reservation.status === "refused") {
                    return "text-color";
                }
                if (this.reservation.status === "noshow" && this.reservation.payment.status === this.PAYMENT_STATUS_CANCELED.value) {
                    return "text-color";
                }
                if (
                    this.reservation.payment.status === this.PAYMENT_STATUS_CAPTURABLE.value ||
                    this.reservation.payment.status === this.PAYMENT_STATUS_CAPTURED.value
                ) {
                    return "feather-blue";
                }
                if (this.reservation.payment.status === this.PAYMENT_STATUS_NOT_CREATED.value) {
                    return "feather-orange";
                }
                if (this.reservation.payment.status === this.PAYMENT_STATUS_PENDING.value) {
                    return "feather-orange";
                }
                if (this.reservation.payment.status === this.PAYMENT_STATUS_EXPIRED.value) {
                    return "feather-red";
                }
            }

            return "";
        },
        paymentIconClasses() {
            if (this.reservation.option_bank === this.OPTION_BANK_PAYMENT.value) {
                if (
                    this.reservation.payment.status === this.PAYMENT_STATUS_REFUNDED.value ||
                    this.reservation.payment.status === this.PAYMENT_STATUS_PARTIALLY_REFUNDED.value ||
                    this.reservation.payment.status === this.PAYMENT_STATUS_CANCELED.value
                ) {
                    return "text-color";
                } else if (
                    this.reservation.payment.status === this.PAYMENT_STATUS_CAPTURABLE.value ||
                    this.reservation.payment.status === this.PAYMENT_STATUS_CAPTURED.value
                ) {
                    return "feather-blue";
                } else if (this.reservation.payment.status === this.PAYMENT_STATUS_PENDING.value) {
                    return "feather-orange";
                } else if (this.reservation.payment.status === this.PAYMENT_STATUS_EXPIRED.value) {
                    return "feather-red";
                }
            }

            return "";
        },
        hasExpired() {
            return (
                this.reservation.status !== "canceled" &&
                this.reservation.payment &&
                this.reservation.payment.status === this.PAYMENT_STATUS_EXPIRED.value
            );
        },
    },
    methods: {
        changeStatusPayment(event) {
            const functions = {
                cancel: () => this.cancelReservation(),
                "send-again": () => this.sendAgain(),
                "send-reminder": () => this.sendReminderPayment(),
            };

            functions[event.value]();
        },
        cancelReservation() {
            if (confirm(this.$tl("questions.booking.reservations.cancel"))) {
                axios
                    .put(`/api/restaurants/${this.restaurant_id_}/reservations/${this.reservation.id}/status?status=canceled`, {
                        status: "canceled",
                    })
                    .then(() => {
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: this.$tl("success.booking.reservations.canceled"),
                        });
                    })
                    .catch((error) => {
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: this.getErrorMsgFromErrorResponse(error),
                        });
                    });
            }
        },
        sendReminderPayment() {
            this.httpPost(
                `/api/restaurants/${this.restaurant_id_}/reservations/${this.reservation.id}/optionBank/send-again`,
                {},
                { handleResolve: false }
            ).then((response) => {
                if (response.data.message && response.data.message === "Mail already sent") {
                    const confirmMessage = this.$tl(`questions.booking.reservations.${this.reservation.payment.option_bank}.sendReminderPayment`);
                    if (confirm(confirmMessage)) {
                        this.httpPost(`/api/restaurants/${this.restaurant_id_}/reservations/${this.reservation.id}/optionBank/send-again?force=true`);
                    }
                } else {
                    this.notifySuccess(response);
                }
            });
        },
        sendAgain() {
            if (!this.reservation.payment) return;
            if (this.reservation.payment.option_bank === this.OPTION_BANK_FOOTPRINT.value) {
                var confirmMessage = this.$tl("questions.booking.reservations.footprint.sendAgain");
            } else if (this.reservation.payment.option_bank === this.OPTION_BANK_PAYMENT.value) {
                var confirmMessage = this.$tl("questions.booking.reservations.payment.sendAgain");
            }
            if (confirm(confirmMessage)) {
                axios
                    .post(`/api/restaurants/${this.restaurant_id_}/reservations/${this.reservation.id}/optionBank/resend`)
                    .then(() => {
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: this.$tl("success.requestSent"),
                        });
                    })
                    .catch((error) => {
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: this.getErrorMsgFromErrorResponse(error),
                        });
                    });
            }
        },
    },
};
</script>
