var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [
            _vm.appUpdateId !== null
              ? _c("div", [
                  _c("h5", { staticClass: "title mt-2" }, [
                    _vm._v(
                      "\n                        Modifier la note de mise à jour du\n                        "
                    ),
                    _vm.appUpdateDate !== null
                      ? _c("span", { staticClass: "value" }, [
                          _vm._v(
                            _vm._s(
                              _vm.displayDate(_vm.appUpdateDate, _vm.DATE_SHORT)
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _c("div", [
                  _c("h5", { staticClass: "title mt-2" }, [
                    _vm._v("Créer une note de mise à jour"),
                  ]),
                ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row m-0" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("app-update-form", {
            ref: "form",
            attrs: { "app-update-id": _vm.appUpdateId, "show-save-button": "" },
            on: {
              "fully-loaded": _vm.onFormLoaded,
              submit: _vm.onFormSubmitted,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }