var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass: "show-custom-events bg-white p-2",
      class: {
        "show-custom-events-close": !_vm.isOpen,
        "show-custom-events-open": _vm.isOpen,
      },
      attrs: { tabindex: "0" },
      on: { blur: _vm.closeFromBlur },
    },
    [
      _vm.loading.fetchCustomEvents || _vm.loading.fetchNameday
        ? _c("loader-component")
        : [
            _c("div", [
              _c("div", { staticClass: "text-center" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between" },
                  [
                    _c("feather", {
                      staticClass: "pointer",
                      attrs: { type: "x" },
                      on: { click: _vm.close },
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.capitalize(
                              _vm.displayDate(_vm.date, _vm.DATE_HUGE)
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.namedayInfo.nameday !== null
                        ? _c("div", { staticClass: "text-uppercase" }, [
                            _vm._v(_vm._s(_vm.namedayInfo.nameday)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.namedayInfo.isPublicHoliday
                        ? _c(
                            "div",
                            {
                              staticClass: "badge badge-danger text-uppercase",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$tl("labels.dates.publicHoliday")
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success btn-sm rounded",
                          attrs: { type: "button" },
                          on: {
                            mousedown: function ($event) {
                              $event.preventDefault()
                            },
                            click: _vm.addCustomEvent,
                          },
                        },
                        [_c("feather", { attrs: { type: "plus" } })],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.customEvents, function (customEvent) {
              return _c("div", { key: customEvent.id, staticClass: "mt-2" }, [
                _c(
                  "div",
                  { staticClass: "bg-light rounded custom-event-card" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-between text-white rounded p-1",
                        style: `background-color: ${_vm.getCustomEventPriorityColor(
                          customEvent.priority
                        )}`,
                      },
                      [
                        _c(
                          "div",
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(customEvent.title) +
                                " - " +
                                _vm._s(
                                  _vm.displayDate(customEvent.date_begin, {
                                    ..._vm.DATE_SHORT,
                                    year: undefined,
                                  })
                                ) +
                                "\n                        "
                            ),
                            customEvent.date_begin !== customEvent.date_end
                              ? [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.$tl("labels.dates.to")) +
                                      " " +
                                      _vm._s(
                                        _vm.displayDate(customEvent.date_end, {
                                          ..._vm.DATE_SHORT,
                                          year: undefined,
                                        })
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            customEvent.visibility ===
                            _vm.CUSTOM_EVENT_VISIBILITY_EXTERNAL.value
                              ? _c("button", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.getTooltip(
                                        _vm.getCustomEventVisibilityLabel(
                                          _vm.CUSTOM_EVENT_VISIBILITY_EXTERNAL
                                            .value
                                        )
                                      ),
                                      expression:
                                        "getTooltip(getCustomEventVisibilityLabel(CUSTOM_EVENT_VISIBILITY_EXTERNAL.value))",
                                    },
                                  ],
                                  staticClass:
                                    "btn btn-default btn-sm cursor-default",
                                  attrs: { type: "button" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.customEventBellIcon),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-default btn-sm",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.loading.destroy.includes(
                                    customEvent.id
                                  ),
                                },
                                on: {
                                  mousedown: function ($event) {
                                    $event.preventDefault()
                                  },
                                  click: function ($event) {
                                    return _vm.editCustomEvent(customEvent.id)
                                  },
                                },
                              },
                              [
                                _c("feather", {
                                  staticClass: "text-white",
                                  attrs: { type: "edit" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-default btn-sm",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.loading.destroy.includes(
                                    customEvent.id
                                  ),
                                },
                                on: {
                                  mousedown: function ($event) {
                                    $event.preventDefault()
                                  },
                                  click: function ($event) {
                                    return _vm.destroyCustomEvent(customEvent)
                                  },
                                },
                              },
                              [
                                _c("feather", {
                                  staticClass: "text-white",
                                  attrs: { type: "trash-2" },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "p-1" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(customEvent.description) +
                          "\n                "
                      ),
                    ]),
                  ]
                ),
              ])
            }),
          ],
      _vm._v(" "),
      _vm.showAddCustomEventModal
        ? _c("show-add-custom-event-modal", {
            attrs: {
              restaurantId: _vm.restaurantId,
              date: _vm.date,
              customEventId: _vm.customEventEditId,
            },
            on: {
              "custom-event-added": _vm.onCustomEventAdded,
              "custom-event-edited": _vm.onCustomEventEdited,
              close: _vm.closeAddCustomEventModal,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }