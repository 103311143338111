import Vue from "vue";
import VueI18n from "vue-i18n";
import fr from "../lang/fr";
import en from "../lang/en";

Vue.use(VueI18n);

let locale = "en";
const langSupported = ["fr", "en"];
const langGlobal = navigator.language.split("-")[0];

if (langSupported.indexOf(langGlobal) >= 0) {
    locale = langGlobal;
}

let messages = {};
if (APP_ENV !== "testing") {
    messages = { fr, en };
}

const i18n = new VueI18n({
    fallbackLocale: "fr",
    locale,
    messages,
    silentTranslationWarn: false,
});

Vue.prototype.$getTranslationKey = function (key, restaurantId = undefined, locale = undefined) {
    let lexicon = THEME === "noshow" ? "restaurant" : "generic";

    const noRestaurantId = [undefined, null, 0, ""];

    if (noRestaurantId.includes(restaurantId)) {
        restaurantId = this.$route.params.restaurant_id;
    }

    if (!noRestaurantId.includes(restaurantId)) {
        lexicon = this.$store.getters["restaurants/lexicon"](restaurantId);
    }

    const tmpKey = `lexicons.${lexicon}.${key}`;

    return this.$te(tmpKey, locale) ? tmpKey : key;
};

Vue.prototype.$tl = function (key, restaurantId = undefined, values = undefined, locale = undefined) {
    return this.$t(this.$getTranslationKey(key, restaurantId, locale), values);
};

Vue.prototype.$tcl = function (key, count, restaurantId = undefined, values = undefined, locale = undefined) {
    return this.$tc(this.$getTranslationKey(key, restaurantId, locale), count, values);
};

export default i18n;
