var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Annulation de la commande " + _vm._s(_vm.command.numero)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.stripePayment
          ? [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          "L'annulation d'une commande entraine son remboursement."
                        ),
                      ]),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n\n                            Vous pouvez choisir de rembourser totalement le client, ou de conserver les frais de\n                            transaction bancaire."
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                            Le paiement de cette commande a généré\n                            "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.formatCurrency(
                              _vm.stripePayment.stripe_fees / 100
                            )
                          )
                        ),
                      ]),
                      _vm._v(" de frais\n                            Stripe."),
                      _c("br"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex flex-start" }, [
                      _vm._v(
                        "\n                            Conserver les frais Stripe\n                            "
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-block align-middle lead-switch",
                        },
                        [
                          _c("label", { staticClass: "container-box" }, [
                            _c("input", {
                              staticClass:
                                "switch align-self-center is-rounded",
                              attrs: { type: "checkbox" },
                              domProps: { checked: _vm.keep_fees.stripe },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              on: {
                                click: function ($event) {
                                  _vm.keep_fees.stripe = !_vm.keep_fees.stripe
                                },
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-right" }, [
                      _vm._v(
                        "\n                            Montant remboursé :\n                            "
                      ),
                      !_vm.keep_fees.stripe
                        ? _c("strong", { staticClass: "value" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.formatCurrency(
                                    _vm.command.total_amount / 100
                                  )
                                ) +
                                "\n                            "
                            ),
                          ])
                        : _c("strong", { staticClass: "value" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.formatCurrency(
                                    _vm.command.total_amount / 100 -
                                      _vm.stripePayment.stripe_fees / 100
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            attrs: { type: "button" },
            on: { click: _vm.close },
          },
          [_vm._v("\n            Annuler\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-success btn-circle ml-2",
            attrs: { type: "button" },
            on: { click: _vm.validate },
          },
          [_vm._v("Valider")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }