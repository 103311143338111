<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.form.restaurant.delete.modal.header") }}</h5>
        <div slot="body">
            <div class="py-3">
                <strong>{{ $tl("labels.form.restaurant.delete.modal.body.paragraph1") }}</strong>
            </div>
            <div class="pb-3" v-html="$tl('labels.form.restaurant.delete.modal.body.paragraph2')"></div>

            <label class="container-box ml-2" style="width: auto">
                <input type="checkbox" v-model="form.checkbox" />
                <span class="checkmark"></span> {{ $tl("labels.form.restaurant.delete.modal.body.form.checkbox") }}
            </label>

            <div>
                <label>{{ $tl("labels.form.restaurant.delete.modal.body.form.text", null, { restaurantName: restaurant.name }) }}</label>
                <input class="form-control mb-2" type="text" v-model="form.restaurantName" />
            </div>
        </div>

        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button type="button" :disabled="loading || disableDeleteButton" class="btn btn-sm btn-danger btn-circle ml-2" @click="deleteRestaurant">
                {{ $tl("labels.form.actions.delete") }}
            </button>
        </div>
    </modal>
</template>

<script>
export default {
    name: "DeleteRestaurantModal",
    props: {
        restaurant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            form: {
                checkbox: false,
                restaurantName: null,
            },
        };
    },
    computed: {
        disableDeleteButton() {
            return !this.form.checkbox || this.form.restaurantName !== this.restaurant.name;
        },
    },
    methods: {
        deleteRestaurant() {
            this.loading = true;
            this.httpDelete(`/api/restaurants/${this.restaurant.id}`)
                .then((response) => {
                    if (response !== false) {
                        this.$store.dispatch("users/fetchCurrentUser", { set: true }).catch(() => false);
                        this.$store.dispatch("restaurants/fetchAllRestaurants", { store: true }).catch(() => false);
                        this.$router.push({ name: "restaurants" });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        close() {
            this.form.checkbox = false;
            this.form.restaurantName = null;
            this.$emit("close");
        },
        closeFromModal(e) {
            if (e && e.target && e.target.classList.contains("modal-wrapper")) {
                this.close();
            }
        },
    },
};
</script>
