var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [
          _vm._v(
            _vm._s(_vm.$tl("labels.booking.widget.footprintAndPayment.title"))
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-3" },
      [
        _c("input-component", {
          attrs: {
            required: "",
            disabled: !_vm.isEditable,
            "input-type": "number",
            label: _vm.$tl(
              "labels.booking.widget.footprintAndPayment.optionBankShortDelay"
            ),
            min: 1,
            max: 47,
            "append-element": _vm.$tl("labels.hour"),
          },
          model: {
            value: _vm.settings.option_bank_short_delay,
            callback: function ($$v) {
              _vm.$set(_vm.settings, "option_bank_short_delay", _vm._n($$v))
            },
            expression: "settings.option_bank_short_delay",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }