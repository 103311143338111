var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticStyle: { overflow: "auto" },
      attrs: { modalContainerStyle: `overflow: visible` },
      on: { close: _vm.closeFromModal },
    },
    [
      _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Annulation de la réservation"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _vm.loading ? _c("LoaderComponent") : _vm._e(),
          _vm._v(" "),
          _vm.error
            ? _c("span", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.reservation && !_vm.loading
            ? [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _vm._v(
                        "\n                        Le client a effectué un paiement de\n                        " +
                          _vm._s(
                            _vm.formatCurrency(
                              _vm.reservation.payment.amount_captured / 100
                            )
                          ) +
                          "."
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(
                          "Vous pouvez rembourser (entièrement ou partiellement) ce client, ou annuler la réservation sans entrainer de\n                            remboursement."
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-4" }, [
                    _c("div", { staticClass: "col-md-5" }, [
                      _c("label", [
                        _vm._v("Type d'annulation "),
                        _c("small", [_vm._v("*")]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7" }, [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "radio" }, [
                              _c("label", { staticClass: "container-box" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reservation_data.cancel_type,
                                      expression:
                                        "reservation_data.cancel_type",
                                    },
                                  ],
                                  attrs: { type: "radio", value: "refund" },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.reservation_data.cancel_type,
                                      "refund"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.reservation_data,
                                        "cancel_type",
                                        "refund"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "checkmark" }),
                                _vm._v(
                                  " Avec remboursement\n                                    "
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 mt-2" }, [
                            _c("div", { staticClass: "radio" }, [
                              _c("label", { staticClass: "container-box" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reservation_data.cancel_type,
                                      expression:
                                        "reservation_data.cancel_type",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    value: "cancel_only",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.reservation_data.cancel_type,
                                      "cancel_only"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.reservation_data,
                                        "cancel_type",
                                        "cancel_only"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "checkmark" }),
                                _vm._v(
                                  " Sans remboursement\n                                    "
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "col-12",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "cancel_type",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.reservation_data.cancel_type === "refund"
                    ? _c("div", { staticClass: "row mt-4" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _c("label", [
                            _vm._v("Type de remboursement "),
                            _c("small", [_vm._v("*")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-7" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-inline-block align-middle lead-switch",
                              },
                              [
                                _vm._v(
                                  "\n                            Partiel\n                            "
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reservation_data.full_refund,
                                      expression:
                                        "reservation_data.full_refund",
                                    },
                                  ],
                                  staticClass:
                                    "switch align-self-center is-rounded",
                                  attrs: {
                                    type: "checkbox",
                                    "true-value": true,
                                    "false-value": false,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.reservation_data.full_refund
                                    )
                                      ? _vm._i(
                                          _vm.reservation_data.full_refund,
                                          null
                                        ) > -1
                                      : _vm.reservation_data.full_refund,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.reservation_data.full_refund,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.reservation_data,
                                              "full_refund",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.reservation_data,
                                              "full_refund",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.reservation_data,
                                          "full_refund",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", {
                                  on: {
                                    click: function ($event) {
                                      _vm.reservation_data.full_refund =
                                        !_vm.reservation_data.full_refund
                                    },
                                  },
                                }),
                                _vm._v(
                                  "\n                            Total\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("ShowErrors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors,
                                errorKey: "full_refund",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.reservation_data.cancel_type === "refund" &&
                  !_vm.reservation_data.full_refund
                    ? _c("div", { staticClass: "row mt-2" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _c("label", [
                            _vm._v("Montant à rembourser "),
                            _c("small", [_vm._v("*")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-7" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.reservation_data.amount_to_refund,
                                  expression:
                                    "reservation_data.amount_to_refund",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                min: 1,
                                max:
                                  _vm.reservation.payment.amount_captured / 100,
                                step: "1",
                              },
                              domProps: {
                                value: _vm.reservation_data.amount_to_refund,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.reservation_data,
                                    "amount_to_refund",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("ShowErrors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors,
                                errorKey: "amount_to_refund",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex w-100 justify-content-between",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary btn-circle btn btn-sm",
              on: { click: _vm.close },
            },
            [_vm._v("Annuler")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-success btn-sm btn-circle",
              class: { disabled: !_vm.reservation },
              attrs: { disabled: !_vm.reservation },
              on: { click: _vm.cancelReservation },
            },
            [_vm._v("\n            Valider\n        ")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }