<template>
    <div
        class="stripe-card-item d-flex flex-row border-light position-relative"
        :class="isSelected ? 'selected' : ''"
        @click="cardSelected = card">
        <div class="icon">
            <feather type="home" size="28" class="w-auto h-auto white" />
        </div>
        <div class="w-100 p-3 d-flex justify-content-between align-items-center">
            <div>
                <div class="d-inline-block">
                    <p class="m-0">
                        {{ card.sepa_debit.country }}XX XXXX XXXX XXXX XXXX XXX{{ card.sepa_debit.last4[0] }}
                        {{ card.sepa_debit.last4.substr(1) }}
                    </p>
                    <p class="m-0 align-top">
                        {{ card.billing_details.name }}
                    </p>
                </div>
                <!-- <span v-if="card.default" class="badge badge-success align-top ml-1">Défaut</span> -->
            </div>
            <div>
                <button
                    v-if="editable && has_right_to_update_subscription"
                    :disabled="!has_right_to_update_subscription"
                    @click="$emit('addNewCard')"
                    class="btn btn-circle btn-success btn-sm">
                    Modifier
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SepaDebitItem",
    props: {
        card: {
            required: true,
        },
        value: {
            default: undefined,
        },
        editable: {
            default: true,
        },
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_subscription() {
            return this.rights.includes("default.subscription.update");
        },
        cardSelected: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit("input", value);
            },
        },
        isSelected() {
            if (this.cardSelected) {
                return this.cardSelected.id === this.card.id;
            }

            return false;
        },
        expAt() {
            const month = `0${this.card.card.exp_month}`.substr(-2);
            const year = `${this.card.card.exp_year}`.substr(-2);

            return `${month}/${year}`;
        },
    },
    methods: {
        deletePaymentMethod() {
            this.$store
                .dispatch("stripe/deletePaymentMethod", { card: this.card })
                .then((response) => {
                    this.$emit("updated");
                })
                .catch((error) => {
                    var message = "Une erreur est survenue lors de la suppression du compte bancaire.";

                    if (error.response && error.response.data.message) {
                        message = error.response.data.message;
                    }
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: message,
                    });
                });
        },
        setDefault() {
            this.$emit("loading");
            this.$store
                .dispatch("stripe/setPaymentMethodAsDefault", { card: this.card })
                .then((response) => {
                    this.$emit("updated");
                })
                .catch((error) => {
                    this.$emit("updated");
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: "Une erreur est survenue.",
                    });
                });
        },
    },
};
</script>
