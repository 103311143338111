<template>
    <div class="pb-5 pt-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Ouvertures exceptionnelles</h5>
                    <button
                        :disabled="!has_right_to_update_config"
                        @click="showAddClickAndCollectOpeningModal = null"
                        class="btn btn-sm btn-success mr-1 btn-circle">
                        <feather type="plus"></feather>
                        <span>Ajouter une ouverture</span>
                    </button>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <template v-else>
            <div class="row m-0 mb-3">
                <div class="col-12">
                    <table v-if="openings.length > 0" class="capitalize table table-sm table-striped border-bottom">
                        <thead class="border-bottom">
                            <tr>
                                <th>Nom</th>
                                <th>Date(s)</th>
                                <th>Service(s)</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="opening in openings" v-bind:key="opening.id">
                                <td>
                                    {{ opening.name }}
                                </td>
                                <td>Du {{ cleanDate(opening.start_day) }} au {{ cleanDate(opening.end_day) }}</td>
                                <td>{{ opening.collect_types.map((c) => getCollectTypeLabel(c)).join(" - ") }}</td>
                                <td>
                                    <button
                                        class="btn btn-sm btn-success btn-square"
                                        v-tooltip="getTooltip('Éditer')"
                                        :disabled="!has_right_to_update_config"
                                        @click="showAddClickAndCollectOpeningModal = opening.id">
                                        <svg
                                            class="feather feather-edit sc-dnqmqq jxshSx"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            aria-hidden="true">
                                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                                        </svg>
                                    </button>
                                    <button
                                        :disabled="!has_right_to_update_config"
                                        @click="deleteOpening(opening.id)"
                                        class="btn btn-sm btn-danger btn-square"
                                        v-tooltip="getTooltip('Supprimer')">
                                        <svg
                                            class="feather feather-trash-2 sc-dnqmqq jxshSx"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            aria-hidden="true">
                                            <polyline points="3 6 5 6 21 6"></polyline>
                                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                            <line x1="10" y1="11" x2="10" y2="17"></line>
                                            <line x1="14" y1="11" x2="14" y2="17"></line>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="mb-0" v-else>Il n'y a aucune ouverture de prévue.</p>
                </div>
            </div>
        </template>
        <add-click-and-collect-opening-modal
            v-if="showAddClickAndCollectOpeningModal !== false"
            :opening_id="showAddClickAndCollectOpeningModal"
            @close="showAddClickAndCollectOpeningModal = false"
            @saved="openingSaved" />
    </div>
</template>

<script>
import axios from "axios";
import addClickAndCollectOpeningModal from "../../../components/Modals/clickAndCollect/settings/addClickAndCollectOpeningModal";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import moment from "moment";
import CollectTypesEnum from "../../../mixins/enums/click_and_collect/CollectTypesEnum";

export default {
    data() {
        return {
            loading: false,
            errors: null,
            showAddClickAndCollectOpeningModal: false,
            openings: [],
        };
    },
    mixins: [CollectTypesEnum],
    components: {
        addClickAndCollectOpeningModal,
        LoaderComponent,
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_config() {
            return this.rights.includes("click_and_collect.config.update");
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        openingSaved() {
            this.showAddClickAndCollectOpeningModal = false;
            this.fetchData();
        },
        cleanDate(date) {
            return moment(date).format("DD MMMM YYYY");
        },
        fetchData() {
            this.loading = true;
            this.errors = null;

            axios
                .get(`/api/click_and_collect/${this.restaurant_id}/openings`)
                .then((response) => {
                    this.loading = false;
                    this.openings = response.data.data;
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: error.response.data.message
                            ? error.response.data.message
                            : "Une erreur est survenue lors de la récupération des données",
                    });
                });
        },
        deleteOpening(opening_id) {
            if (!confirm("Êtes vous sûr de vouloir supprimer cette ouverture exceptionnelle ?")) return;
            this.loading = true;

            axios
                .delete(`/api/click_and_collect/${this.restaurant_id}/openings/${opening_id}`)
                .then((response) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.fetchData();
                })
                .catch((error) => {
                    this.loading = false;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
    },
    created() {
        this.fetchData();
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
