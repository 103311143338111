var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: `wrapper-${_vm.id}` } }, [
    _c(
      "table",
      {
        staticClass:
          "table table-sm table-striped border-bottom table-tr-clickable",
      },
      [
        _c("thead", { staticClass: "border-bottom" }, [
          _c("tr", [
            _vm.columns.restaurant
              ? _c("th", [_vm._v("Établissement")])
              : _vm._e(),
            _vm._v(" "),
            _vm.columns.number ? _c("th", [_vm._v("N°")]) : _vm._e(),
            _vm._v(" "),
            _vm.columns.client ? _c("th", [_vm._v("Client")]) : _vm._e(),
            _vm._v(" "),
            _vm.columns.status.show ? _c("th", [_vm._v("Statut")]) : _vm._e(),
            _vm._v(" "),
            _vm.columns.date ? _c("th", [_vm._v("Date")]) : _vm._e(),
            _vm._v(" "),
            _vm.columns.slot
              ? _c("th", [
                  !_vm.type
                    ? _c("span", [_vm._v("Retrait / livraison")])
                    : _vm.type === _vm.COLLECT_TYPE_COLLECT.value
                    ? _c("span", [_vm._v("Retrait")])
                    : _vm.type === _vm.COLLECT_TYPE_DELIVERY.value ||
                      _vm.type === _vm.COLLECT_TYPE_DISPATCH.value
                    ? _c("span", [_vm._v("Livraison")])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.columns.delivery_address
              ? _c("th", [_vm._v("Adresse")])
              : _vm._e(),
            _vm._v(" "),
            _vm.columns.sendcloud_status
              ? _c("th", [_vm._v("Sendcloud")])
              : _vm._e(),
            _vm._v(" "),
            _vm.columns.dispatch_fees
              ? _c("th", [_vm._v("Frais de livraison")])
              : _vm._e(),
            _vm._v(" "),
            _vm.columns.price ? _c("th", [_vm._v("Montant")]) : _vm._e(),
            _vm._v(" "),
            _vm.columns.products ? _c("th", [_vm._v("Détails")]) : _vm._e(),
            _vm._v(" "),
            _vm.showActionsColumn ? _c("th", [_vm._v("Actions")]) : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.commands_, function (command) {
            return _c(
              "tr",
              {
                key: command.id,
                class: {
                  updated: command.updated && command.updated === true,
                  new: command.new && command.new === true,
                },
                on: {
                  click: function ($event) {
                    return _vm.showCommand(command, $event)
                  },
                },
              },
              [
                _vm.columns.restaurant
                  ? _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.findRestaurantById(command.restaurant_id).name
                        )
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.columns.number
                  ? _c(
                      "td",
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(command.numero) +
                            "\n                    "
                        ),
                        typeof _vm.columns.show_payment_status ===
                          "undefined" || _vm.columns.show_payment_status
                          ? _c("cc-command-payment-status-icon", {
                              ref: `paymentStatusIcon${command.id}`,
                              refInFor: true,
                              attrs: { command: command },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.columns.client
                  ? _c(
                      "td",
                      [
                        command.client !== null
                          ? _c("inline-client", {
                              attrs: {
                                client: command.client,
                                "module-enum":
                                  _vm.MODULE_TYPE_CLICK_AND_COLLECT,
                                "public-comment": command.comment,
                                "private-comment": command.private_comment,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.columns.status.show
                  ? _c(
                      "td",
                      [
                        _c("ClickAndCollectStatusTag", {
                          attrs: {
                            readonly: _vm.columns.status.readonly,
                            editable_status: _vm.columns.status.editable_status,
                            restaurant_id: command.restaurant_id,
                            command: command,
                          },
                          on: {
                            "status-updated": function ($event) {
                              return _vm.$emit("status-updated", $event)
                            },
                            "open-pay-manually-modal": _vm.openPayManuallyModal,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.columns.date
                  ? _c("td", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.formatDate(command.reservation_date)) +
                          "\n                "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.columns.slot
                  ? _c(
                      "td",
                      [
                        command.collect_type ===
                          _vm.COLLECT_TYPE_DELIVERY.value && !_vm.type
                          ? _c("feather", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    delay: { show: 400, hide: 0 },
                                    content:
                                      command.delivery_address_displayable,
                                  },
                                  expression:
                                    "{\n                            delay: { show: 400, hide: 0 },\n                            content: command.delivery_address_displayable,\n                        }",
                                },
                              ],
                              staticClass: "mr-1 text-success",
                              attrs: { type: "truck" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        command.collect_type === _vm.COLLECT_TYPE_COLLECT.value
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.formatHour(command.slot.hour))),
                            ])
                          : command.collect_type ===
                            _vm.COLLECT_TYPE_DELIVERY.value
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatHour(command.slot.hour_start)
                                ) +
                                  " - " +
                                  _vm._s(_vm.formatHour(command.slot.hour_end))
                              ),
                            ])
                          : command.collect_type ===
                            _vm.COLLECT_TYPE_DISPATCH.value
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getDispatchTypeLabel(
                                    command.dispatch_type
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.columns.delivery_address
                  ? _c("td", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(command.delivery_address_displayable) +
                          "\n                "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.columns.sendcloud_status
                  ? _c(
                      "td",
                      [
                        command.collect_type === _vm.COLLECT_TYPE_DISPATCH.value
                          ? _c("SendcloudStatusTag", {
                              attrs: { command: command },
                            })
                          : _c("span", [_vm._v("--")]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.columns.dispatch_fees
                  ? _c("td", [
                      command.dispatch_fees === null
                        ? _c("span", [_vm._v("--")])
                        : _c(
                            "span",
                            [
                              command.dispatch_fees_paid_by_client
                                ? _c("feather", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          delay: { show: 400, hide: 0 },
                                          content: "Payés par le client",
                                        },
                                        expression:
                                          "{ delay: { show: 400, hide: 0 }, content: 'Payés par le client' }",
                                      },
                                    ],
                                    staticClass: "text-success",
                                    attrs: { type: "dollar-sign" },
                                  })
                                : _c("feather", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          delay: { show: 400, hide: 0 },
                                          content: "À votre charge",
                                        },
                                        expression:
                                          "{ delay: { show: 400, hide: 0 }, content: 'À votre charge' }",
                                      },
                                    ],
                                    staticClass: "text-danger",
                                    attrs: { type: "dollar-sign" },
                                  }),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      command.dispatch_fees / 100
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ],
                            1
                          ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.columns.price
                  ? _c("td", [
                      _vm._v(
                        _vm._s(_vm.formatCurrency(command.total_amount / 100))
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.columns.products
                  ? _c("td", [
                      _c(
                        "ul",
                        { staticClass: "pl-0 mb-0" },
                        [
                          _vm._l(
                            command.command_products.data,
                            function (commandProduct, index) {
                              return [
                                commandProduct.quantity > 0
                                  ? _c(
                                      "li",
                                      { key: `${command.id}-${index}` },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(commandProduct.quantity) +
                                            " x " +
                                            _vm._s(
                                              commandProduct.product.name
                                            ) +
                                            "\n                                "
                                        ),
                                        commandProduct.comment &&
                                        !_vm.$_.isEmpty(commandProduct.comment)
                                          ? _c(
                                              "small",
                                              { staticClass: "text-warning" },
                                              [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(
                                                      commandProduct.comment
                                                    ) +
                                                    ")"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        commandProduct.command_product_option
                                          .data.length > 0
                                          ? _c(
                                              "ul",
                                              { staticClass: "mb-0" },
                                              _vm._l(
                                                commandProduct
                                                  .command_product_option.data,
                                                function (
                                                  commandProductOption,
                                                  index2
                                                ) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: `${command.id}-${index}-${index2}`,
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            commandProductOption
                                                              .product_option
                                                              .cc_product.name
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                      commandProductOption.quantity >
                                                      1
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " (x" +
                                                                _vm._s(
                                                                  commandProductOption.quantity
                                                                ) +
                                                                ")"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showActionsColumn
                  ? _c(
                      "td",
                      [
                        _vm.columns.actions.download_label
                          ? _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: {
                                      delay: { show: 400, hide: 0 },
                                      content:
                                        "Télécharger l'étiquette d'envoi",
                                    },
                                    expression:
                                      "{ delay: { show: 400, hide: 0 }, content: 'Télécharger l\\'étiquette d\\'envoi' }",
                                  },
                                ],
                                staticClass:
                                  "btn btn-sm btn-outline-secondary btn-square",
                                attrs: {
                                  disabled: !_vm.canDownloadLabel(command),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadLabel(command)
                                  },
                                },
                              },
                              [_c("feather", { attrs: { type: "download" } })],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.columns.actions.print_order_form
                          ? _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: {
                                      delay: { show: 400, hide: 0 },
                                      content: "Imprimer le bon de commande",
                                    },
                                    expression:
                                      "{ delay: { show: 400, hide: 0 }, content: 'Imprimer le bon de commande' }",
                                  },
                                ],
                                staticClass:
                                  "btn btn-sm btn-outline-secondary btn-square",
                                attrs: {
                                  href: `/api/click_and_collect/${command.restaurant_id}/commands/${command.id}/printOrderForm`,
                                  target: "_blank",
                                },
                              },
                              [_c("feather", { attrs: { type: "printer" } })],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.columns.actions.mark_delivered
                          ? _c("MarkDelivered", {
                              attrs: { command: command },
                              on: {
                                "command-updated": function ($event) {
                                  return _vm.$emit("command-updated", $event)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.modals.displayShowCommandModal
          ? _c("show-command-modal", {
              attrs: {
                hide_client_btn: _vm.columns.actions.hide_client_btn,
                command_id: _vm.modals.params.selected_command_id,
                restaurant_id: _vm.modals.params.selected_restaurant_id,
              },
              on: {
                close: function ($event) {
                  _vm.modals.displayShowCommandModal = false
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }