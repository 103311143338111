<template>
    <div class="border-light b-radius-20 p-4 text-center">
        <div class="row mb-2">
            <div class="col-12">
                <div class="rounded-circle div-profil-svg">
                    <feather type="user" class="feather-22" />
                </div>
                <inline-client :client="client" :module-enum="moduleEnum" hide-name></inline-client>
            </div>
        </div>
        <div class="sepa"></div>
        <div class="row mb-0 radio">
            <div class="col-12">
                <small>{{ getClientCivilityLabelLong(client.civility) }}</small>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-uppercase font-weight-bold">{{ client.firstname }} {{ client.lastname }}</div>
        </div>
        <div class="row">
            <div class="col-12">
                {{ client.company }}
            </div>
        </div>
        <div class="sepa"></div>
        <div class="row mb-1">
            <div class="col-12">
                <a :href="`mailto:${client.email}`">{{ client.email }}</a>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a :href="`tel:${client.tel}`">
                    <div>
                        <vue-tel-input
                            defaultCountry="FR"
                            name="telInput"
                            v-model="client.tel"
                            :enabledCountryCode="true"
                            class="d-flex phone-view border-0"
                            mode="international"
                            inputClasses="m-0 border-0"
                            disabled
                            placeholder="-- -- -- -- --"></vue-tel-input>
                    </div>
                </a>
            </div>
        </div>
        <slot name="additionalInfos"></slot>
        <div class="sepa"></div>
        <template v-if="isChangeInProgress">
            <button type="button" class="btn-sm btn btn-secondary btn-circle" @click="$emit('cancel-in-progress')">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button type="button" class="btn btn-sm btn-success btn-circle" @click="$emit('update-in-progress')">
                {{ $tl("labels.form.actions.save") }}
            </button>
        </template>
        <template v-else>
            <div v-if="moduleEnum.value === MODULE_TYPE_BOOKING.value" class="badge badge-secondary text-blue-light mb-2">
                <template v-if="client.overResaCount > 0">
                    <div class="row">
                        <div class="col-12">
                            <small>{{ $tcl("labels.booking.reservations.nbHonored", client.overResaCount) }}</small>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="row">
                        <div class="col-12">
                            <small>{{ $tl("labels.booking.reservations.firstOne") }}</small>
                        </div>
                    </div>
                </template>
            </div>
            <div class="row">
                <div class="col-12">
                    <small v-html="formatComment(client.comment)"></small>
                </div>
            </div>
            <div v-if="!hideClientEditBtn" class="row mt-3">
                <div class="col-12">
                    <router-link
                        v-if="!client.is_deleted"
                        :to="{ name: `${moduleEnum.urlValue}.clients.form`, params: { client_id: client.id } }"
                        class="btn btn-sm btn-success btn-circle">
                        {{ $tl("labels.clients.show") }}
                    </router-link>
                    <button v-else type="button" class="btn btn-sm btn-success btn-circle" disabled>
                        {{ $tl("labels.clients.show") }}
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import inlineClient from "./inlineClient.vue";
import ClientCivilityEnum from "../../../mixins/enums/ClientCivilityEnum.js";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";

export default {
    props: {
        client: {
            type: Object,
            required: true,
        },
        moduleEnum: {
            type: Object,
            default: null,
        },
        hideClientEditBtn: {
            type: Boolean,
            default: false,
        },
        isChangeInProgress: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [ClientCivilityEnum, ModuleTypesEnum],
    methods: {
        formatComment(comment) {
            if (["", null].includes(comment)) {
                return "";
            }

            return this.escapeHtml(comment).replace(
                /https?:\/\/[a-zA-Z0-9_\.\-\/\?=&%:]+/g,
                (link) => `<a href="${link.replace(/\.:*$/, "")}" target="_blank">${link}</a>`
            );
        },
    },
    components: {
        inlineClient,
    },
};
</script>
