var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Licences invalides"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c("span", { staticClass: "mb-2" }, [
          _vm._v("Les licences suivantes comportent des erreurs :"),
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _vm._l(
          _vm.subscriptionGroupedByRestaurant,
          function (groupedSubscriptions, index) {
            return _c(
              "div",
              { key: index, staticClass: "mb-2 ml-2" },
              [
                groupedSubscriptions[0].restaurant
                  ? _c("span", [
                      _vm._v(_vm._s(groupedSubscriptions[0].restaurant.name)),
                    ])
                  : _c("span", [_vm._v("Licence(s) non associée(s)")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._l(groupedSubscriptions, function (subscription, index1) {
                  return _c(
                    "span",
                    { key: `${index}-${index1}`, staticClass: "ml-2" },
                    [
                      _vm._v(
                        "\n                - " +
                          _vm._s(
                            _vm.getModuleTypeLabel(subscription.module.type)
                          ) +
                          "\n                "
                      ),
                      subscription.status === "past_due"
                        ? _c("small", [
                            _vm._v(
                              "(En défaut de paiement depuis " +
                                _vm._s(
                                  _vm.displayDate(
                                    subscription.past_due_at,
                                    _vm.DATE_SHORT
                                  )
                                ) +
                                ")"
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("br"),
                    ]
                  )
                }),
              ],
              2
            )
          }
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("small", { staticClass: "text-danger" }, [
          _vm._v(
            "Sans action de votre part, les licences en défaut de paiement seront annulés à partir de 7 jours suivant la date de mise en défaut de\n            paiement"
          ),
        ]),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex justify-content-center",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "router-link",
          {
            staticClass: "btn btn-sm btn-success btn-circle ml-2",
            attrs: { to: { name: "subscription" } },
            nativeOn: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v("\n            Vérifier mes licences\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }