<template>
    <table class="table table-sm table-striped border-bottom">
        <thead class="border-bottom">
            <tr>
                <th>Date</th>
                <th>Établissement</th>
                <th>Horaire</th>
                <th>Statut</th>
                <th>Montant</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="command in cc_commands" :key="command.id">
                <td>
                    {{ moment(command.reservation_date).format("LL") }}
                </td>
                <td>
                    {{ command.restaurant.name }}
                </td>
                <td>
                    <span v-if="command.collect_type === 'collect'">{{ formatHour(command.slot.hour) }}</span>
                    <span v-if="command.collect_type === 'delivery'">{{ formatHour(command.slot.hour_start) }}</span>
                </td>
                <td>
                    {{ getCleanName(command) }}
                </td>
                <td>{{ formatCurrency(command.total_amount / 100) }}</td>
                <td>
                    <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary btn-square"
                        @click="$emit('show-cc-command', command)"
                        v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'Voir la commande' }">
                        <svg
                            class="feather feather-eye sc-dnqmqq jxshSx"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            aria-hidden="true">
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import moment from "moment";

export default {
    data() {
        return {
            moment,
            tagsLabels: [
                {
                    tag: "to_prepare",
                    infos: {
                        cleanName: "À préparer",
                        cleanNameDelivery: "À préparer",
                        class: "text-warning",
                    },
                },
                {
                    tag: "ready",
                    infos: {
                        cleanName: "Prête",
                        cleanNameDelivery: "À livrer",
                        class: "text-success",
                    },
                },
                {
                    tag: "collected",
                    infos: {
                        cleanName: "Retirée",
                        cleanNameDelivery: "Livrée",
                        class: "text-default",
                    },
                },
                {
                    tag: "canceled",
                    infos: {
                        cleanName: "Annulée",
                        cleanNameDelivery: "Annulée",
                        class: "text-danger",
                    },
                },
            ],
        };
    },
    props: {
        cc_commands: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    methods: {
        getCleanName(command) {
            const tag = this.tagsLabels.find((t) => t.tag === command.status);
            if (tag) {
                if (command.collect_type === "collect") return tag.infos.cleanName;
                else if (command.collect_type === "delivery") return tag.infos.cleanNameDelivery;
            }
            return "";
        },
        formatHour(hour) {
            return hour.split(":").splice(0, 2).join("h");
        },
    },
};
</script>
