var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loader-main-div text-center mt-4 mb-4" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", [_vm._v(_vm._s(_vm.loadingMessage))]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { height: "80px" } }, [
      _c("img", {
        staticClass: "load-bar-absolute load-bar-absolute-top",
        attrs: { src: "/images/load-bar-top.png" },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "load-bar-absolute load-bar-absolute-bot",
        attrs: { src: "/images/load-bar-bot.png" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }