import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import Model from "@ckeditor/ckeditor5-ui/src/model";
import Collection from "@ckeditor/ckeditor5-utils/src/collection";
import { addListToDropdown, createDropdown } from "@ckeditor/ckeditor5-ui/src/dropdown/utils";
import { add } from "@ckeditor/ckeditor5-utils/src/translation-service";

add("fr", {
    "Merge tags": "Champs de fusion",
});
add("en", {
    "Merge tags": "Merge fields",
});

export default class MergeTags extends Plugin {
    constructor(editor) {
        super(editor);

        editor.config.define("mergeTags", {
            tags: [],
        });
    }

    init() {
        const editor = this.editor;
        const config = editor.config.get("mergeTags");
        const t = editor.t;

        editor.ui.componentFactory.add("MergeTags", (locale) => {
            const dropdownView = createDropdown(locale);

            dropdownView.buttonView.set({
                withText: true,
                label: t("Merge tags"),
                tooltip: true,
            });

            const items = new Collection();

            config.tags.forEach((mergeTag) => {
                items.add({
                    type: "button",
                    model: new Model({
                        withText: true,
                        label: mergeTag.description,
                        tag: mergeTag.tag,
                        isBlock: mergeTag.isBlock || false,
                    }),
                });
            });

            dropdownView.on("execute", (eventInfo) => {
                const { tag, isBlock } = eventInfo.source;

                editor.model.change((writer) => {
                    if (isBlock) {
                        editor.execute("insertParagraph", {
                            position: editor.model.document.selection.getFirstPosition(),
                        });
                    }

                    editor.model.insertContent(writer.createText(tag), null, "in");
                });

                editor.editing.view.focus();
            });

            addListToDropdown(dropdownView, items);

            return dropdownView;
        });
    }
}
