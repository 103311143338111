<template>
    <select v-model="value_" class="custom-select w-auto m-0">
        <option v-for="lexicon in ALL_LEXICONS" :key="lexicon.value" :value="lexicon.value">
            {{ getLexiconLabel(lexicon.value) }}
        </option>
    </select>
</template>

<script>
import LexiconEnum from "../../mixins/enums/LexiconEnum.js";

export default {
    props: {
        value: {
            type: String,
        },
    },
    mixins: [LexiconEnum],
    computed: {
        value_: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>
