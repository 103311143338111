import * as noshow from "./noshow/variables.scss.js";
import * as yservices from "./yservices/variables.scss.js";

export default {
    install(Vue) {
        if (THEME === "yservices") {
            Vue.prototype.$style = yservices.default;
        } else {
            Vue.prototype.$style = noshow.default;
        }
    },
};
