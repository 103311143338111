export default {
    data() {
        return {
            PAYMENT_STATUS_NOT_CREATED: {
                label: "Non envoyé",
                value: "not_created",
            },
            PAYMENT_STATUS_PENDING: {
                label: "En attente",
                value: "pending",
            },
            PAYMENT_STATUS_CANCELED: {
                label: "Annulé",
                value: "canceled",
            },
            PAYMENT_STATUS_REFUNDED: {
                label: "Remboursé",
                value: "refunded",
            },
            PAYMENT_STATUS_PARTIALLY_REFUNDED: {
                label: "Remboursé partiellement",
                value: "partially_refunded",
            },
            PAYMENT_STATUS_CAPTURABLE: {
                label: "Confirmé",
                value: "capturable",
            },
            PAYMENT_STATUS_CAPTURED: {
                label: "Capturé",
                value: "captured",
            },
            PAYMENT_STATUS_EXPIRED: {
                label: "Expiré",
                value: "expired",
            },
        };
    },
    methods: {
        getPaymentStatusLabel(value, translateMethod = undefined) {
            const found = this.ALL_PAYMENT_STATUS.find((w) => w.value == value);
            if (typeof found != "undefined") {
                if ("undefined" === typeof translateMethod) {
                    return this.$t(`labels.booking.payments.status.${value}`);
                }
                return translateMethod(`labels.booking.payments.status.${value}`);
            }
            return value;
        },
    },
    computed: {
        ALL_PAYMENT_STATUS() {
            return [
                this.PAYMENT_STATUS_NOT_CREATED,
                this.PAYMENT_STATUS_PENDING,
                this.PAYMENT_STATUS_CANCELED,
                this.PAYMENT_STATUS_REFUNDED,
                this.PAYMENT_STATUS_PARTIALLY_REFUNDED,
                this.PAYMENT_STATUS_CAPTURABLE,
                this.PAYMENT_STATUS_CAPTURED,
                this.PAYMENT_STATUS_EXPIRED,
            ];
        },
    },
};
