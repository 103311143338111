var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                    _c("label", [_vm._v("Nom "), _c("small", [_vm._v("*")])]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-7" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product_option.name,
                            expression: "product_option.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.product_option.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.product_option,
                              "name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("ShowErrors", {
                        attrs: { errors: _vm.errors, errorKey: "name" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-4" }, [
                  _c("div", { staticClass: "col-md-5" }, [
                    _c("label", [
                      _vm._v("Tarif TTC "),
                      _c("small", [_vm._v("*")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-7" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "radio" }, [
                          _c("label", { staticClass: "container-box" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.product_option.include,
                                  expression: "product_option.include",
                                },
                              ],
                              attrs: { type: "radio" },
                              domProps: {
                                value: 1,
                                checked: _vm._q(_vm.product_option.include, 1),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(
                                    _vm.product_option,
                                    "include",
                                    1
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" }),
                            _vm._v(
                              " Inclus\n                                        dans le prix de " +
                                _vm._s(
                                  _vm.isYservices ? "la prestation" : "l'offre"
                                ) +
                                "\n                                    "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 d-inline radio mt-1" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "container-box d-inline-block pt-0 mt-2",
                              staticStyle: { width: "initial" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.product_option.include,
                                    expression: "product_option.include",
                                  },
                                ],
                                attrs: { type: "radio" },
                                domProps: {
                                  value: 0,
                                  checked: _vm._q(
                                    _vm.product_option.include,
                                    0
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.product_option,
                                      "include",
                                      0
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkmark" }),
                              _vm._v(
                                " Coût\n                                    additionnel\n                                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          !_vm.product_option.include
                            ? [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.product_option.price,
                                      expression: "product_option.price",
                                    },
                                  ],
                                  staticClass: "form-control d-inline mr-2",
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    type: "number",
                                    name: "lock_reservation_until",
                                  },
                                  domProps: { value: _vm.product_option.price },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.product_option,
                                        "price",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(
                                  "\n                                    euros\n                                    "
                                ),
                                _c("ShowErrors", {
                                  staticClass: "d-block",
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "include",
                                  },
                                }),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  staticClass: "d-block",
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "price",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12 pt-1" }, [
                                    _c(
                                      "label",
                                      {
                                        staticStyle: { "margin-left": "31px" },
                                      },
                                      [
                                        _vm._v("Taux de TVA "),
                                        _c("small", [_vm._v("*")]),
                                        _vm._v(" "),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.product_option.tva,
                                                expression:
                                                  "product_option.tva",
                                              },
                                            ],
                                            staticClass:
                                              "custom-select mb-0 ml-2 mr-3",
                                            staticStyle: { width: "inherit" },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.product_option,
                                                  "tva",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { domProps: { value: null } },
                                              [_vm._v("--")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { domProps: { value: 5.5 } },
                                              [_vm._v("5,5")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { domProps: { value: 10 } },
                                              [_vm._v("10")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { domProps: { value: 20 } },
                                              [_vm._v("20")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          "%\n                                                "
                                        ),
                                        _c("ShowErrors", {
                                          staticClass: "d-block",
                                          attrs: {
                                            errors: _vm.errors,
                                            errorKey: "tva",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }