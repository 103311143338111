<template>
    <div :class="{ 'overflow-hidden': seatingPlanPrint.isPrinting }">
        <LoaderComponent v-show="seatingPlanLoading || seatingPlanPrint.isPrinting" />
        <div v-show="!seatingPlanLoading" class="row h-100 m-0 p-0" :class="{ invisible: seatingPlanPrint.isPrinting }">
            <seating-plan-sidebar
                class="col-3 bg-light table-sidebar"
                @start-loading="seatingPlanLoading = true"
                @stop-loading="seatingPlanLoading = false"
                @dragged="handleDraggedElement"
                @tables-selected="selectMultipleTables"
                @duplicate="duplicateSelectedTables"
                @changeSize="changeSelectedTablesSize"
                @set-name="seatingPlanName = $event"
                @set-color-type="seatingPlanColorType = $event"
                :seatingPlanColorType="seatingPlanColorType"
                :selectedTables="selectedTables"
                :selectedTablesId="selectedTablesId"
                :selectedGroupId="selectedGroupId"
                :currentRoom="currentRoom"
                :mainStage="mainStage"
                :rooms="rooms"
                @draw-table="drawTable($event.table, $event.modelElement, $event.forceSelection || false)"
                :seatingPlanId="duplicate ? null : seatingPlanId_"
                :baseStyle="style"
                :seatingPlanName="seatingPlanName"
                @group-selected-tables="groupSelectedTables" />
            <div class="col-9 h-100 table-plan m-0 p-0 d-flex flex-column">
                <seating-plan-topbar
                    :rooms="rooms"
                    :sortable="true"
                    :currentRoom="currentRoom"
                    @room-changed="roomChanged"
                    @new-room="addNewRoom"
                    @room-selected="roomIsSelected"
                    @room-deleted="roomIsDeleted"
                    @save="saveSeatingPlan" />
                <div class="row h-100 m-0 p-0 overflow-auto">
                    <div ref="seatingPlanCanvas" id="seating-plan-canvas" class="h-100 col-9 p-0" v-on:contextmenu.prevent></div>
                    <seating-plan-tree-view
                        class="col-3 h-100"
                        :sortable="true"
                        :currentRoom="currentRoom"
                        :selectedTables="selectedTables"
                        :selectedTablesId="selectedTablesId"
                        :selectedGroupId="selectedGroupId"
                        :rooms="rooms"
                        @print="printSelectedRooms"
                        @groups-changed="groupsChanged"
                        @tables-changed="tablesChanged"
                        @unselect-all-tables="unselectAllTables"
                        @table-selected="selectTable"
                        @group-selected="selectGroup" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SeatingPlanSidebar from "./SeatingPlanSidebar.vue";
import SeatingPlanTopbar from "./SeatingPlanTopbar";
import SeatingPlanTreeView from "./SeatingPlanTreeView";
import LoaderComponent from "../LoaderComponent.vue";
import ResizeObserver from "resize-observer-polyfill";
import ElementsReference from "../../mixins/helpers/booking/seating_plan/ElementsReference";
import SeatingPlanTableTypesEnum from "../../mixins/enums/booking/SeatingPlanTableTypesEnum";
import SeatingPlanPrint from "../../mixins/helpers/booking/seating_plan/Print";

import _ from "lodash";

//Optimization for Math.round with bit shiffting
Math.round = function (value) {
    return (value + (value > 0 ? 0.5 : -0.5)) << 0;
};

export default {
    data() {
        return {
            seatingPlanLoading: true,
            seatingPlanName: this.$tl("labels.booking.seatingPlan.title"),
            seatingPlanColorType: "unique",
            rooms: [],
            mainStage: null,
            mainStageEventsSet: false,
            draggedElement: null,
            dragAndDrop: {
                oldPosition: {
                    x: null,
                    y: null,
                },
            },
            konva: {
                selectedRoom: -1,
            },
            snapping: {
                offset: 15,
            },
            style: {
                mainColor: "#ccc",
                selectedColor: this.$style["main-color-light"],
            },
            selectedTablesId: [],
            selectedGroupId: null,
            canvasResizeObserver: new ResizeObserver((entries, observer) => {
                for (const entry of entries) {
                    this.mainStage.width(entry.contentRect.width);
                    this.mainStage.height(entry.contentRect.height);
                    this.mainStage.batchDraw();
                }
            }),
        };
    },
    mixins: [ElementsReference, SeatingPlanTableTypesEnum, SeatingPlanPrint],
    props: {
        seatingPlanId: {
            default: null,
        },
    },
    computed: {
        seatingPlanId_() {
            return this.seatingPlanId ? this.seatingPlanId.toString().replace("duplicate-", "") : null;
        },
        duplicate() {
            if (this.seatingPlanId) return this.seatingPlanId.toString().startsWith("duplicate-");
            return false;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_seatingplan() {
            return this.rights.includes("booking.seatingplan.update");
        },
        currentRoom() {
            if (this.konva.selectedRoom >= 0 && this.rooms && this.rooms.length > 0 && this.konva.selectedRoom < this.rooms.length) {
                return this.rooms[this.konva.selectedRoom];
            }

            return undefined;
        },
        selectedTables() {
            var result = [];
            if (this.selectedTablesId && this.selectedTablesId.length > 0) {
                this.selectedTablesId.forEach((selectedTableId) => {
                    var table = this.findTableById(selectedTableId);

                    if (table) {
                        result.push(table);
                    }
                });
            } else if (this.selectedGroup) {
                return this.selectedGroup.tables;
            }

            return result;
        },
        selectedGroup() {
            var result = null;

            if (this.selectedGroupId) {
                result = this.findGroupById(this.selectedGroupId);
            }

            return result;
        },
    },
    methods: {
        addEventsToMainStage() {
            if (!this.mainStageEventsSet) {
                //dragover init to preventDefault
                this.mainStage.container().addEventListener("dragover", (e) => {
                    e.preventDefault();
                });

                //init drop of element inside the current layer of the main stage
                this.mainStage.container().addEventListener("drop", (e) => {
                    e.preventDefault();
                    // If there is a valid dragged element
                    if (this.draggedElement !== null) {
                        //set main stage pointer positions to current pointer positions
                        this.mainStage.setPointersPositions(e);

                        var table = this.addNewTableToCurrentRoom(this.draggedElement);

                        var group = table.group;
                        const offsetX = this.currentRoom.layer.offsetX() - this.currentRoom.layer.x();
                        const offsetY = this.currentRoom.layer.offsetY() - this.currentRoom.layer.y();

                        table.position = {
                            x:
                                this.mainStage.getPointerPosition().x / this.currentRoom.layer.scaleX() -
                                group.getClientRect().width / 2 -
                                this.mainStage.position().x / this.currentRoom.layer.scaleX() +
                                offsetX,

                            y:
                                this.mainStage.getPointerPosition().y / this.currentRoom.layer.scaleY() -
                                group.getClientRect().height / 2 -
                                this.mainStage.position().y / this.currentRoom.layer.scaleY() +
                                offsetY,
                        };

                        group.position(table.position);

                        this.currentRoom.layer.add(group);

                        this.adjustLayerOffset(group);

                        this.currentRoom.layer.batchDraw();
                        this.unselectAllTables();
                        this.selectTable(group);
                        this.currentRoom.layer.batchDraw();
                    }
                });

                this.mainStageEventsSet = true;
            }

            //Zoom management
            this.mainStage.on("wheel", (e) => {
                e.evt.preventDefault();
                //zoom 1% percent more or less
                const zoomModifierRate = 1.01;
                var oldScale = this.currentRoom.layer.scaleX();

                var mousePointTo = {
                    x: this.mainStage.getPointerPosition().x / oldScale - this.mainStage.x() / oldScale,
                    y: this.mainStage.getPointerPosition().y / oldScale - this.mainStage.y() / oldScale,
                };

                var newScale = e.evt.deltaY > 0 ? oldScale * zoomModifierRate : oldScale / zoomModifierRate;

                this.currentRoom.layer.scale({
                    x: newScale,
                    y: newScale,
                });

                var newPos = {
                    x: -(mousePointTo.x - this.mainStage.getPointerPosition().x / newScale) * newScale,
                    y: -(mousePointTo.y - this.mainStage.getPointerPosition().y / newScale) * newScale,
                };

                this.mainStage.position(newPos);

                this.mainStage.batchDraw();
            });

            this.mainStage.on("click tap", (e) => {
                this.unselectAllTables();
            });
        },
        adjustLayerOffset(shape) {
            const clientRect = shape.getClientRect();
            if (clientRect.x < 0) {
                this.currentRoom.layer.offsetX(this.currentRoom.layer.offsetX() + clientRect.x / this.currentRoom.layer.scaleX());
                this.currentRoom.layer.x(this.currentRoom.layer.x() + clientRect.x);
            }

            if (clientRect.y < 0) {
                this.currentRoom.layer.offsetY(this.currentRoom.layer.offsetY() + clientRect.y / this.currentRoom.layer.scaleY());
                this.currentRoom.layer.y(this.currentRoom.layer.y() + clientRect.y);
            }
        },
        initRooms() {
            this.rooms = [];
            let url = `/api/restaurants/${this.$route.params.restaurant_id}/seatingPlan`;
            if (this.seatingPlanId_) url += `/${this.seatingPlanId_}`;
            url += "?include=rooms,rooms.tables,rooms.groups,rooms.groups.tables";

            return axios
                .get(url)
                .then((response) => {
                    if (response.data.seatingPlan) {
                        this.loadSeatingPlan(response.data.seatingPlan);
                    } else {
                        this.initNewSeatingPlan();
                    }
                    this.seatingPlanLoading = false;
                    this.addEventsToMainStage();
                })
                .catch((e) => {
                    this.initNewSeatingPlan();
                    console.error(e);
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: "Une erreur est survenue lors du chargement. Un nouveau plan de salle a été chargé.",
                    });
                    this.seatingPlanLoading = false;
                    this.addEventsToMainStage();
                });
        },
        loadSeatingPlan(seatingPlan) {
            this.seatingPlanName = seatingPlan.name;
            if (this.duplicate) this.seatingPlanName += " (Copie)";
            this.seatingPlanColorType = seatingPlan.color_type;
            seatingPlan.rooms.data.forEach((room) => {
                var newRoom = this.createNewRoom(room);
                this.mainStage.add(newRoom.layer);
                newRoom.layer.hide();
                this.rooms.push(newRoom);
            });

            this.rooms[0].layer.batchDraw();
        },
        initNewSeatingPlan() {
            //Create first room
            const room = this.createNewRoom();
            //Push the new room to the list of room
            this.rooms.push(room);
            //Add room layer to the main stage
            this.mainStage.add(room.layer);
            // draw the first room layer to the main stage
            this.mainStage.batchDraw();
        },
        //adds a new room to rooms list & display it
        addNewRoom() {
            const newRoom = this.createNewRoom();

            if (this.currentRoom) {
                this.currentRoom.layer.hide();
            }
            this.rooms.push(newRoom);
            this.mainStage.add(newRoom.layer);
            this.konva.selectedRoom = this.rooms.length - 1;
            this.mainStage.batchDraw();
        },
        // Return a room with a layer defined and the selection rectangle
        createNewRoom(baseRoom = null) {
            var room = {
                id: this.getUniqRoomId(),
                serverId: null,
                layer: new Konva.Layer(),
                order: this.rooms.length,
                tables: [],
                groups: [],
                selectionRect: new Konva.Rect({
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                    stroke: "#414141",
                    dash: [3, 4],
                    listening: false,
                }),
                name: `${this.$tl("labels.booking.seatingPlan.room.title")} ${this.rooms.length + 1}`,
                lastStagePosition: {
                    x: 0,
                    y: 0,
                },
                enabled: true,
                datetime_begin: null,
                datetime_end: null,
                print: false,
            };

            room.layer.add(room.selectionRect);

            if (baseRoom) {
                room.serverId = baseRoom.id;
                room.name = baseRoom.name;
                room.order = baseRoom.order;
                room.lastStagePosition.x = baseRoom.main_stage_position.x;
                room.lastStagePosition.y = baseRoom.main_stage_position.y;
                room.enabled = baseRoom.enabled === 1;
                room.datetime_begin = baseRoom.datetime_begin != null ? this.getDateTime(baseRoom.datetime_begin) : null;
                room.datetime_end = baseRoom.datetime_end != null ? this.getDateTime(baseRoom.datetime_end) : null;

                baseRoom.tables.data.forEach((baseTable) => {
                    var modelElement = this.findElementReference(baseTable.type, baseTable.seats, baseTable.size);

                    if (modelElement === null) {
                        console.error("Can't find elementReference", baseTable);
                    }

                    var table = this.createNewTable(modelElement, baseTable);

                    if (baseTable.position.x < 0 && baseTable.position.x < room.layer.offsetX()) {
                        room.layer.offsetX(baseTable.position.x);
                    }

                    if (baseTable.position.y < 0 && baseTable.position.y < room.layer.offsetY()) {
                        room.layer.offsetY(baseTable.position.y);
                    }

                    room.layer.add(table.group);
                    room.tables.push(table);
                });

                baseRoom.groups.data.forEach((baseGroup) => {
                    baseGroup.room = room;
                    const group = this.createNewGroup([], baseGroup);

                    room.groups.push(group);
                });
            }

            return room;
        },
        //Generation of a new table
        // -> Create the table data and store it into the current room
        // -> Create the table visual elements and return it
        addNewTableToCurrentRoom(draggedElement) {
            var table = this.createNewTable(draggedElement);

            this.currentRoom.tables.push(table);

            return table;
        },
        drawTable(table, modelElement, forceSelection = false) {
            // The main group of the table, which will have the following architecture
            // -> Table-label
            //     -> hitArea (Used to be able to click on the whole element)
            //     -> Label
            //         -> Text (Title of the table)
            //     -> Group (contains the shape of the table)
            //           -> paths[] (contains all the paths of the shape)
            table.group = new Konva.Group({
                name: modelElement.seats ? "table-label" : `${modelElement.type}-label`,
                id: table.id,
            });

            table.tableGroup = new Konva.Group({
                name: modelElement.seats ? this.TABLE_TYPE_TABLE.value : modelElement.type,
            });

            var label = new Konva.Label({
                name: "label",
            });

            var labelText = new Konva.Text({
                name: "text",
                text: `${table.name}`,
                fontFamily: "Montserrat",
                fontSize: 16,
                padding: 3,
                fill: "#414141",
                fontStyle: "400",
            });

            table.labelText = labelText;

            label.add(labelText);

            let paths = modelElement.paths;
            if (modelElement.table && modelElement.table.stroke) {
                paths.push(modelElement.table.stroke);
            }
            paths.forEach((path) => {
                let data = {
                    data: path,
                    fill: forceSelection ? this.style.selectedColor : this.style.mainColor,
                    listening: false,
                    name: "path",
                };
                if (modelElement.scale) {
                    data.scale = modelElement.scale;
                }
                let konvaPath = new Konva.Path(data);

                table.tableGroup.add(konvaPath);
            });

            table.group.add(table.tableGroup);
            table.group.add(label);

            // The hit Area of the whole group, it will handle click events
            var hitArea = new Konva.Rect({
                fill: "rgba(0,0,0,0)",
                height: table.tableGroup.getClientRect().height,
                width: table.tableGroup.getClientRect().width,
                name: "hit-area",
            });

            table.tableGroup.add(hitArea);

            if (table.rotation) {
                const topLeft = {
                    x:
                        table.tableGroup.getClientRect({ skipTransform: true }).x -
                        (table.tableGroup.getClientRect({ skipTransform: true }).x +
                            table.tableGroup.getClientRect({ skipTransform: true }).width / 2),
                    y:
                        table.tableGroup.getClientRect({ skipTransform: true }).y -
                        (table.tableGroup.getClientRect({ skipTransform: true }).y +
                            table.tableGroup.getClientRect({ skipTransform: true }).height / 2),
                };

                const current = this.rotateAPoint(topLeft, Konva.getAngle(table.tableGroup.rotation()));
                const rotated = this.rotateAPoint(topLeft, Konva.getAngle(table.rotation));
                const dx = rotated.x - current.x,
                    dy = rotated.y - current.y;

                table.tableGroup.rotation(table.rotation);
                table.tableGroup.x(table.tableGroup.x() + dx);
                table.tableGroup.y(table.tableGroup.y() + dy);
            }

            labelText.getParent().position({
                x: table.tableGroup.getClientRect({ skipTransform: true }).width / 2 - labelText.getClientRect({ skipTransform: true }).width / 2,
                y: table.tableGroup.getClientRect({ skipTransform: true }).height / 2 - labelText.getClientRect({ skipTransform: true }).height / 2,
            });

            if (table.position) {
                table.group.position({
                    x: table.position.x,
                    y: table.position.y,
                });
            }

            this.konvaElementInit(table.group);
        },
        createNewTable(modelElement, baseTable = null, duplicate = false) {
            const tableId = baseTable ? baseTable.id.toString() : this.getUniqTableId();

            // table datas
            let tableName = modelElement.name;
            if (tableName === null || tableName === "null") tableName = "";
            if (typeof tableName === "undefined")
                tableName = this.currentRoom ? (this.currentRoom.tables.filter((t) => t.seats).length + 1).toString() : "1";
            var table = {
                seats: modelElement.seats,
                type: modelElement.type,
                size: modelElement.size,
                order: this.currentRoom ? this.currentRoom.tables.length : 0,
                id: tableId,
                serverId: null,
                name: tableName,
                enabledPax: modelElement.seats > 0 ? [modelElement.seats] : [],
                labelText: null,
                enabled: true,
                rotation: null,
            };

            if (baseTable) {
                table.seats = baseTable.seats;
                table.order = baseTable.order;
                if (!duplicate) {
                    table.serverId = baseTable.id;
                }
                table.name = baseTable.name;
                if (table.name === null || table.name === "null") table.name = "";
                table.enabledPax = baseTable.enabledPax;
                table.enabled = baseTable.enabled;
                table.rotation = baseTable.rotation;
                table.position = baseTable.position;
            }

            this.drawTable(table, modelElement);

            return table;
        },
        rotateAPoint({ x, y }, rad) {
            const rcos = Math.cos(rad);
            const rsin = Math.sin(rad);

            // Math formula of point rotation
            return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
        },
        // find the corresponding dragged element
        handleDraggedElement(element) {
            this.draggedElement = this.findElementReference(element.type, element.seats, element.size);
        },
        // init konva element
        // Set :
        //  -> selection (click)
        //  -> drag & drop (element.draggable(true))
        //  -> snapping (dragstart.event, dragmove, dragend)
        //  -> disabling the selection rectangle when the element is manipulated (mousedown, mousemove, mouseup)
        //  -> pointer changes (mouseover, mouseout)
        konvaElementInit(element) {
            var self = this;

            element.draggable(this.has_right_to_update_seatingplan);
            element.listening(true);
            element.on("mousedown", (e) => {
                self.mainStage.listening(false);
            });

            element.on("mousemove", (e) => {
                self.mainStage.listening(true);
            });

            element.on("mouseup", (e) => {
                self.mainStage.listening(true);
            });

            element.on("click tap", (e) => {
                self.mainStage.listening(true);
                e.cancelBubble = true;
                if (!e.evt.ctrlKey && !e.evt.metaKey) {
                    this.unselectAllTables();
                }

                if (this.selectedGroup) {
                    const tablesId = this.selectedGroup.tables.map((t) => t.id);
                    this.$set(this, "selectedGroupId", null);
                    this.$set(this, "selectedTablesId", tablesId);
                }
                // const selectedTablesAreTables = this.selectedTables.every(table => table.seats);
                // if (this.selectedTables.length > 0 && selectedTablesAreTables != isTable) {
                //     this.unselectAllTables();
                //     this.selectedTablesId = [];
                // }

                this.$nextTick(() => {
                    this.selectTable(e.target.getParent().getParent());
                });
            });

            element.on("dragstart", (e) => {
                if (!this.selectedTablesId.includes(e.target.id())) {
                    this.unselectAllTables();
                    this.selectTable(e.target);
                }
                this.dragAndDrop.oldPosition.x = e.target.x();
                this.dragAndDrop.oldPosition.y = e.target.y();
                e.cancelBubble = true;
                e.target.show();
                e.target.moveToTop();
                self.currentRoom.layer.batchDraw();
            });

            element.on("dragmove", function (e) {
                e.cancelBubble = true;
                document.body.style.cursor = "pointer";
                let diff = {
                    x: e.target.x() - self.dragAndDrop.oldPosition.x,
                    y: e.target.y() - self.dragAndDrop.oldPosition.y,
                };
                self.dragAndDrop.oldPosition.x = e.target.x();
                self.dragAndDrop.oldPosition.y = e.target.y();

                let table = self.findTableById(e.target.id());
                table.position = e.target.position();

                self.moveSelectedTables(e.target, diff);

                self.currentRoom.layer.find(".guid-line").destroy();

                var tables = e.target.getChildren((node) => {
                    return self.inEnum(node.name(), self.ALL_TABLE_TYPES);
                });

                if (!tables || tables.length < 1) {
                    return;
                }

                //find possible snapping lines
                var guideStops = self.getKonvaSnappingGuideStops();
                //find snapping points of current object
                var itemBounds = self.getKonvaElementSnappingEdges(e.target);
                //now find where can we snap current object
                var guides = self.getKonvaSnappingGuide(guideStops, itemBounds);

                // no snapping ? do nothing
                if (!guides.length) {
                    return;
                }

                self.drawGuidesLines(guides);

                self.forceObjectPosition(guides, e.target);

                diff = {
                    x: e.target.x() - self.dragAndDrop.oldPosition.x,
                    y: e.target.y() - self.dragAndDrop.oldPosition.y,
                };
                self.dragAndDrop.oldPosition.x = e.target.x();
                self.dragAndDrop.oldPosition.y = e.target.y();

                self.moveSelectedTables(e.target, diff);

                self.mainStage.batchDraw();
            });

            element.on("dragend", function (e) {
                self.dragAndDrop.oldPosition.x = null;
                self.dragAndDrop.oldPosition.y = null;

                self.selectedTables.forEach((item) => {
                    if (item.group) {
                        self.adjustLayerOffset(item.group);
                    }
                });

                e.cancelBubble = true;
                self.currentRoom.layer.find(".guid-line").destroy();
                self.mainStage.batchDraw();
            });

            element.on("mouseover", function () {
                document.body.style.cursor = "pointer";
            });

            element.on("mouseout", function () {
                document.body.style.cursor = "default";
            });
        },

        //get each snapping lines on the view
        // left, bottom, right, top border of the stage
        // vertical and horizontal middle of the stage
        // each of elements strokes : left, bottom, right, top
        getKonvaSnappingGuideStops() {
            const offsetX = this.currentRoom.layer.offsetX() - this.currentRoom.layer.x();
            const offsetY = this.currentRoom.layer.offsetY() - this.currentRoom.layer.y();
            // we can snap to stage borders and the center of the stage
            var vertical = [
                0 - this.mainStage.position().x / this.currentRoom.layer.scaleX() + offsetX,
                this.currentRoom.layer.width() / this.currentRoom.layer.scaleX() / 2 -
                    this.mainStage.position().x / this.currentRoom.layer.scaleX() +
                    offsetX,
                this.currentRoom.layer.width() / this.currentRoom.layer.scaleX() -
                    this.mainStage.position().x / this.currentRoom.layer.scaleX() +
                    offsetX,
            ];

            var horizontal = [
                0 - this.mainStage.position().y / this.currentRoom.layer.scaleY() + offsetY,
                this.mainStage.height() / this.currentRoom.layer.scaleY() / 2 -
                    this.mainStage.position().y / this.currentRoom.layer.scaleY() +
                    offsetY,
                this.mainStage.height() / this.currentRoom.layer.scaleY() - this.mainStage.position().y / this.currentRoom.layer.scaleY() + offsetY,
            ];

            // and we snap over edges and center of each object on the canvas
            this.currentRoom.tables.forEach((table) => {
                if (this.selectedTablesId.includes(table.group.id())) {
                    return;
                }

                var box = this.getRealClientRectWithRotationAndMainStagePosition(table.tableGroup);

                vertical.push([box.x + offsetX, box.x + box.width + offsetX, box.x + box.width / 2 + offsetX]);
                horizontal.push([box.y + offsetY, box.y + box.height + offsetY, box.y + box.height / 2 + offsetY]);
            });

            return {
                vertical: vertical.flat(),
                horizontal: horizontal.flat(),
            };
        },
        //get snapping lines of the current dragged item
        getKonvaElementSnappingEdges(element) {
            var tables = element.getChildren((node) => {
                return this.inEnum(node.name(), this.ALL_TABLE_TYPES);
            });

            if (!tables || tables.length < 1) {
                return;
            }

            var box = this.getRealClientRectWithRotationAndMainStagePosition(tables[0]);

            const offsetX = this.currentRoom.layer.offsetX() - this.currentRoom.layer.x();
            const offsetY = this.currentRoom.layer.offsetY() - this.currentRoom.layer.y();
            return {
                vertical: [
                    {
                        guide: Math.round(box.x) + offsetX,
                        offset: Math.round(element.x() - box.x),
                        snap: "start",
                    },
                    {
                        guide: Math.round(box.x + Math.round(box.width / 2)) + offsetX,
                        offset: Math.round(element.x() - box.x - Math.round(box.width / 2)),
                        snap: "center",
                    },
                    {
                        guide: Math.round(box.x + box.width) + offsetX,
                        offset: Math.round(element.x() - box.x - box.width),
                        snap: "end",
                    },
                ],
                horizontal: [
                    {
                        guide: Math.round(box.y) + offsetY,
                        offset: Math.round(element.y() - box.y),
                        snap: "start",
                    },
                    {
                        guide: Math.round(box.y + Math.round(box.height / 2)) + offsetY,
                        offset: Math.round(element.y() - box.y - Math.round(box.height / 2)),
                        snap: "center",
                    },
                    {
                        guide: Math.round(box.y + box.height) + offsetY,
                        offset: Math.round(element.y() - box.y - box.height),
                        snap: "end",
                    },
                ],
            };
        },
        getRealClientRectWithRotationAndMainStagePosition(shape) {
            var shapeClientRect = shape.getClientRect({
                skipStroke: true,
                skipShadow: true,
            });

            const scales = {
                x: this.currentRoom.layer.scaleX(),
                y: this.currentRoom.layer.scaleY(),
            };

            const MainStagePosition = {
                x: Math.round(this.mainStage.x() / scales.x),
                y: Math.round(this.mainStage.y() / scales.y),
            };

            return {
                width: shapeClientRect.width / scales.x,
                height: shapeClientRect.height / scales.y,
                x: Math.round(shapeClientRect.x / scales.x) - MainStagePosition.x,
                y: Math.round(shapeClientRect.y / scales.y) - MainStagePosition.y,
            };
        },
        // Check if the current item can be aligned with another one
        getKonvaSnappingGuide(guideStops, itemBounds) {
            var resultV = [];
            var resultH = [];

            guideStops.vertical.forEach((lineGuide) => {
                itemBounds.vertical.forEach((itemBound) => {
                    var diff = Math.abs(lineGuide - itemBound.guide);
                    // if the distance between guild line and object snap point is close we can consider this for snapping
                    if (diff < this.snapping.offset) {
                        resultV.push({
                            lineGuide: lineGuide,
                            diff: diff,
                            snap: itemBound.snap,
                            offset: itemBound.offset,
                        });
                    }
                });
            });

            guideStops.horizontal.forEach((lineGuide) => {
                itemBounds.horizontal.forEach((itemBound) => {
                    var diff = Math.abs(lineGuide - itemBound.guide);
                    if (diff < this.snapping.offset) {
                        resultH.push({
                            lineGuide: lineGuide,
                            diff: diff,
                            snap: itemBound.snap,
                            offset: itemBound.offset,
                        });
                    }
                });
            });

            var guides = [];

            // find closest snap
            var minV = resultV.sort((a, b) => a.diff - b.diff)[0];
            var minH = resultH.sort((a, b) => a.diff - b.diff)[0];
            if (minV) {
                guides.push({
                    lineGuide: minV.lineGuide,
                    offset: minV.offset,
                    orientation: "V",
                    snap: minV.snap,
                });
            }
            if (minH) {
                guides.push({
                    lineGuide: minH.lineGuide,
                    offset: minH.offset,
                    orientation: "H",
                    snap: minH.snap,
                });
            }
            return guides;
        },
        //draw the lines on which the current item can be aligned
        drawGuidesLines(guides) {
            guides.forEach((lg) => {
                if (lg.orientation === "H") {
                    var line = new Konva.Line({
                        points: [-6000, lg.lineGuide, 6000, lg.lineGuide],
                        stroke: this.style.selectedColor,
                        strokeWidth: 1 / this.currentRoom.layer.scaleX(),
                        name: "guid-line",
                        dash: [4, 6],
                    });
                    this.currentRoom.layer.add(line);
                } else if (lg.orientation === "V") {
                    var line = new Konva.Line({
                        points: [lg.lineGuide, -6000, lg.lineGuide, 6000],
                        stroke: this.style.selectedColor,
                        strokeWidth: 1 / this.currentRoom.layer.scaleY(),
                        name: "guid-line",
                        dash: [4, 6],
                    });
                    this.currentRoom.layer.add(line);
                }
            });
            this.currentRoom.layer.batchDraw();
        },
        //force the position of the current item to fit the snapping line
        forceObjectPosition(guides, element) {
            const offsetX = this.currentRoom.layer.offsetX() - this.currentRoom.layer.x();
            const offsetY = this.currentRoom.layer.offsetY() - this.currentRoom.layer.y();

            guides.forEach((lg) => {
                switch (lg.snap) {
                    case "start": {
                        switch (lg.orientation) {
                            case "V": {
                                element.x(lg.lineGuide + lg.offset - offsetX);
                                break;
                            }
                            case "H": {
                                element.y(lg.lineGuide + lg.offset - offsetY);
                                break;
                            }
                        }
                        break;
                    }
                    case "center": {
                        switch (lg.orientation) {
                            case "V": {
                                element.x(lg.lineGuide + lg.offset - offsetX);
                                break;
                            }
                            case "H": {
                                element.y(lg.lineGuide + lg.offset - offsetY);
                                break;
                            }
                        }
                    }
                    case "end": {
                        switch (lg.orientation) {
                            case "V": {
                                element.x(lg.lineGuide + lg.offset - offsetX);
                                break;
                            }
                            case "H": {
                                element.y(lg.lineGuide + lg.offset - offsetY);
                                break;
                            }
                        }
                        break;
                    }
                }
            });
        },
        //creation of unique id for the table
        getUniqTableId() {
            const prefix = "table_";
            let isUniq = false;
            var result = null;

            while (!isUniq || result === null) {
                result = _.uniqueId(prefix);
                isUniq = !this.findTableById(result);
            }

            return result;
        },
        getUniqRoomId() {
            const prefix = "room_";
            let isUniq = false;
            var result = null;

            while (!isUniq || result === null) {
                result = _.uniqueId(prefix);
                isUniq = !this.findRoomById(result);
            }

            return result;
        },
        //find table data by id
        findTableById(id) {
            for (var i = 0; i < this.currentRoom.tables.length; i++) {
                const table = this.currentRoom.tables[i];

                if (table.id === id) {
                    return table;
                }
            }

            return null;
        },
        findTableByServerId(id, room = null) {
            const roomDetails = room ? room : this.currentRoom;

            for (let i = 0; i < roomDetails.tables.length; i++) {
                const table = roomDetails.tables[i];

                if (table.serverId === id) {
                    return table;
                }
            }

            return null;
        },
        findGroupById(id) {
            for (var group of this.currentRoom.groups) {
                if (group.id === id) {
                    return group;
                }
            }

            return null;
        },
        //find table node by id
        findTableNodebyId(id) {
            return this.currentRoom.layer.find(`#${id}`);
        },
        findRoomById(id) {
            for (var i = 0; i < this.rooms.length; i++) {
                const room = this.rooms[i];

                if (room.id === id) {
                    return room;
                }
            }

            return null;
        },
        findRoomByServerId(id) {
            for (var i = 0; i < this.rooms.length; i++) {
                const room = this.rooms[i];

                if (room.serverId === id) {
                    return room;
                }
            }

            return null;
        },
        //select a table:
        // display the shape with the selected color
        // add it to the selected shapes array
        selectTable(tableLabelGroup) {
            if (!this.selectedTablesId.includes(tableLabelGroup.id())) {
                var elements = tableLabelGroup.getChildren((node) => this.inEnum(node.name(), this.ALL_TABLE_TYPES));

                this.$nextTick(() => {
                    var self = this;

                    elements.forEach(function (table) {
                        var paths = table.getChildren(function (node) {
                            return node.getClassName() === "Path";
                        });

                        paths.forEach(function (path) {
                            path.fill(self.style.selectedColor);
                        });
                    });

                    this.selectedTablesId.push(tableLabelGroup.id());

                    this.currentRoom.layer.batchDraw();
                });
            }
        },
        selectGroup(group) {
            if (group) {
                this.unselectAllTables();

                if (this.selectedGroupId && group.id !== this.selectedGroupId) {
                    this.selectedGroup.tables.forEach((table) => {
                        var tableGroups = table.group.getChildren((node) => {
                            return node.name() === this.TABLE_TYPE_TABLE.value;
                        });

                        if (tableGroups.length > 0) {
                            var paths = tableGroups[0].getChildren((node) => {
                                return node.getClassName() === "Path";
                            });

                            paths.forEach((path) => {
                                path.fill(this.style.mainColor);
                            });
                        }
                    });

                    this.currentRoom.layer.batchDraw();
                }

                if (this.selectedGroupId === null || group.id !== this.selectedGroupId) {
                    group.tables.forEach((table) => {
                        var tableGroups = table.group.getChildren((node) => {
                            return node.name() === this.TABLE_TYPE_TABLE.value;
                        });

                        if (tableGroups.length > 0) {
                            var paths = tableGroups[0].getChildren((node) => {
                                return node.getClassName() === "Path";
                            });

                            paths.forEach((path) => {
                                path.fill(this.style.selectedColor);
                            });
                        }
                    });

                    this.selectedGroupId = group.id;
                }
            } else {
                this.selectedGroupId = null;
            }
            this.currentRoom.layer.batchDraw();
        },
        //selection of multiple tables
        selectMultipleTables(tables) {
            this.$nextTick(() => {
                this.unselectAllTables();
                for (var i = 0; i < tables.length; i++) {
                    var node = this.findTableNodebyId(tables[i].id());

                    if (node && node.length > 0) {
                        this.selectTable(node[0]);
                    }
                }
            });
        },
        //unselection of a table
        // remove the selection's color
        // remove it from the selected tables array
        unselectTable(tableLabelGroup) {
            if (this.selectedTables.map((t) => t.id).includes(tableLabelGroup.id())) {
                var tables = tableLabelGroup.getChildren((node) => {
                    return this.inEnum(node.name(), this.ALL_TABLE_TYPES);
                });
                var self = this;

                tables.forEach(function (table) {
                    var paths = table.getChildren(function (node) {
                        return node.getClassName() === "Path";
                    });

                    paths.forEach(function (path) {
                        path.fill(self.style.mainColor);
                    });
                });

                this.removeTableFromSelectedArray(tableLabelGroup.id());

                this.currentRoom.layer.batchDraw();
            }
        },
        removeTableFromSelectedArray(tableId) {
            let foundIndex = null;

            for (var i = 0; i < this.selectedTablesId.length; i++) {
                if (tableId === this.selectedTablesId[i]) {
                    foundIndex = i;
                }
            }

            if (foundIndex !== null) {
                this.selectedTablesId.splice(foundIndex, 1);
            }
        },
        unselectAllTables() {
            const tablesGroup = this.currentRoom.layer.getChildren((element) => {
                return this.ALL_TABLE_TYPES_LABELS.includes(element.name());
            });

            tablesGroup.forEach(this.unselectTable);

            if (this.selectedGroupId) {
                this.selectedGroupId = null;
            }
        },
        moveSelectedTables(except, offset) {
            this.selectedTables.forEach((item) => {
                if (item.group === except) {
                    return;
                }

                item.position = {
                    x: item.group.x() + offset.x,
                    y: item.group.y() + offset.y,
                };

                item.group.position(item.position);
            });

            this.currentRoom.layer.batchDraw();
        },
        roomIsSelected(index) {
            this.unselectAllTables();
            this.konva.selectedRoom = index;
        },
        roomIsDeleted(index) {
            var roomsLengthBeforeDelete = this.rooms.length;

            this.rooms[index].layer.destroy();
            this.rooms.splice(index, 1);

            if (this.konva.selectedRoom + 1 === roomsLengthBeforeDelete) {
                this.$set(this.konva, "selectedRoom", this.konva.selectedRoom - 1);
            } else if (this.rooms.length === 0) {
                this.$set(this.konva, "selectedRoom", -1);
            } else {
                this.$set(this.konva, "selectedRoom", this.konva.selectedRoom - 1);
                this.$nextTick(() => {
                    this.$set(this.konva, "selectedRoom", this.konva.selectedRoom + 1);
                });
            }
        },
        groupSelectedTables() {
            let group = this.findGroupByTablesId(this.selectedTablesId);

            if (!group) {
                group = this.createNewGroup(this.selectedTablesId);
                this.currentRoom.groups.push(group);
            }

            this.unselectAllTables();
            this.selectGroup(group);
        },
        createNewGroup(tablesId, baseGroup = null) {
            var tablesObjectArray = [];
            let totalSeats = 0;
            let name = "";

            if (baseGroup) {
                baseGroup.tables.data.forEach((table) => {
                    const tableFound = this.findTableByServerId(table.id, baseGroup.room);

                    if (tableFound) {
                        tablesObjectArray.push(tableFound);
                    }
                });
            } else {
                tablesId.forEach((tableId, index) => {
                    const table = this.findTableById(tableId);
                    if (table && table.seats > 0) {
                        totalSeats += table.seats;
                        tablesObjectArray.push(table);
                        if (index !== 0) {
                            name += ", ";
                        }
                        name += table.name;
                    }
                });
            }

            const groupObject = {
                tables: [...tablesObjectArray],
                order: this.currentRoom ? this.currentRoom.groups.length : 0,
                id: this.getUniqGroupId(),
                enabledPax: baseGroup ? baseGroup.enabledPax : [totalSeats],
                name: baseGroup ? baseGroup.name : name,
                enabled: baseGroup ? baseGroup.enabled : true,
                expanded: false,
            };

            if (baseGroup) {
                groupObject.serverId = baseGroup.id;
                groupObject.order = baseGroup.order;
            }

            return groupObject;
        },
        getUniqGroupId() {
            const prefix = "group_";
            let isUniq = false;
            var result = null;

            while (!isUniq || result === null) {
                result = _.uniqueId(prefix);
                isUniq = !this.findGroupById(result);
            }

            return result;
        },
        findGroupById(id) {
            if (this.currentRoom) {
                for (var i = 0; i < this.currentRoom.groups.length; i++) {
                    const group = this.currentRoom.groups[i];

                    if (group.id === id) {
                        return group;
                    }
                }
            }

            return null;
        },
        findGroupByTablesId(tablesId) {
            let result = null;

            groupLoop: for (var group of this.currentRoom.groups) {
                if (group.tables.length === tablesId.length) {
                    for (var table of group.tables) {
                        if (!tablesId.includes(table.id)) {
                            continue groupLoop;
                        }
                    }

                    return group;
                }
            }

            return result;
        },
        saveSeatingPlan() {
            if (!this.currentRoom) {
                this.notifyError(null, this.$tl("errors.booking.seatingPlan.needAtLeastOneRoom"));
                return;
            }

            this.currentRoom.lastStagePosition.x = this.mainStage.x();
            this.currentRoom.lastStagePosition.y = this.mainStage.y();

            const seatingPlanFormatted = this.formatSeatingPlan();

            const groupErrors = this.getErrorsForGroups(seatingPlanFormatted);

            if (groupErrors.length > 0) {
                const roomNames = groupErrors.map((groupError) => groupError.room).join(",");
                const groupNames = groupErrors.map((groupError) => groupError.groups).join(",");
                this.notifyError(null, this.$tl("errors.booking.seatingPlan.group.paxEnabled", undefined, { groupNames, roomNames }));
                return;
            }

            this.seatingPlanLoading = true;

            this.httpPost(
                `/api/restaurants/${this.$route.params.restaurant_id}/seatingPlan`,
                { seatingPlan: this.formatSeatingPlan() },
                { handleResolve: false }
            ).then((response) => {
                if (response !== false) {
                    this.notifySuccess(null, "Le plan de salle a été enregistré avec succès");

                    if (this.duplicate || !this.seatingPlanId_) {
                        this.$router.push({
                            to: "booking.restaurants.settings.seatingPlan",
                            params: {
                                seating_plan_id: response.data.id,
                            },
                        });
                    }

                    this.$nextTick(() => {
                        this.mainStage = new Konva.Stage({
                            container: "seating-plan-canvas",
                            width: 0,
                            height: 0,
                            draggable: true,
                        });

                        this.konva.selectedRoom = -1;
                        this.selectedTablesId = [];
                        this.selectedGroupId = null;

                        this.initRooms()
                            .then(() => {
                                this.$nextTick(() => {
                                    this.konva.selectedRoom = 0;

                                    if (typeof this.$refs.seatingPlanCanvas !== "undefined") {
                                        const height = this.$refs.seatingPlanCanvas.offsetHeight;
                                        const width = this.$refs.seatingPlanCanvas.offsetWidth;

                                        this.canvasResizeObserver.observe(this.$refs.seatingPlanCanvas);
                                        this.mainStage.height(height);
                                        this.mainStage.width(width);
                                    }

                                    this.mainStage.batchDraw();
                                });
                            })
                            .then(() => {
                                this.seatingPlanLoading = false;
                            });
                    });
                } else {
                    this.seatingPlanLoading = false;
                }
            });
        },
        formatSeatingPlan() {
            var seatingPlanFormated = {
                name: this.seatingPlanName,
                color_type: this.seatingPlanColorType,
                rooms: this.rooms.map((r) => this.formatRoom(r)),
            };

            if (this.seatingPlanId_ && !this.duplicate) {
                seatingPlanFormated.id = this.seatingPlanId_;
            }

            return seatingPlanFormated;
        },
        formatRoom(room) {
            var formatedRoom = {
                name: room.name,
                main_stage_position: { ...room.lastStagePosition },
                enabled: room.enabled,
                scaleX: this.currentRoom.layer.scaleX(),
                scaleY: this.currentRoom.layer.scaleY(),
                tables: room.tables.map((t) => this.formatTable(t)),
                groups: room.groups.map((g) => this.formatGroup(g)),
                order: room.order,
                date_begin: room.datetime_begin != null ? room.datetime_begin.toISODate() : null,
                date_end: room.datetime_end != null ? room.datetime_end.toISODate() : null,
            };

            if (room.serverId && !this.duplicate) {
                formatedRoom.id = room.serverId;
            }

            return formatedRoom;
        },
        formatTable(table) {
            var tableFormated = {
                seats: table.seats,
                name: table.name,
                enabled: table.enabled,
                enabledPax: [...table.enabledPax],
                position: {
                    x: table.group.x(),
                    y: table.group.y(),
                },
                rotation: 0,
                type: table.type,
                size: table.size,
                internalId: table.id,
                order: table.order,
            };

            if (table.serverId && !this.duplicate) {
                tableFormated.id = table.serverId;
            }

            const tables = table.group.getChildren(function (node) {
                return node.name() === tableFormated.seats ? this.TABLE_TYPE_TABLE.value : tableFormated.type;
            });

            if (tables.length > 0) {
                tableFormated.rotation = tables[0].rotation();
            }

            return tableFormated;
        },
        formatGroup(group) {
            var groupFormated = {
                name: group.name,
                enabled: group.enabled,
                enabledPax: group.enabledPax,
                tables: group.tables.map((t) => this.formatTable(t)),
                order: group.order,
            };

            if (group.serverId && !this.duplicate) {
                groupFormated.id = group.serverId;
            }

            return groupFormated;
        },
        // Increase or decrease table size
        // 'higher' -> increase
        // 'smaller' -> decrease
        changeSelectedTablesSize(mode = "higher") {
            if (this.selectedTablesId && this.selectedTablesId.length > 0) {
                const tables = this.currentRoom.layer.find((node) => {
                    return this.ALL_TABLE_TYPES_LABELS.includes(node.name()) && this.selectedTablesId.includes(node.id());
                });

                tables.forEach((table) => {
                    table.destroy();
                });

                const selectedTables = this.selectedTables.filter((table) => {
                    return table.size !== undefined;
                });

                selectedTables.forEach((table) => {
                    const newTableSize = this.getNextTableSize(table.size, mode);

                    let modelElement = this.findElementReference(table.type, table.seats, newTableSize);

                    // If no element found for given size, fallback to old element size
                    if (modelElement) {
                        table.size = newTableSize;
                    } else {
                        modelElement = this.findElementReference(table.type, table.seats, table.size);
                    }

                    this.drawTable(table, modelElement, true);

                    this.currentRoom.layer.add(table.group);

                    this.adjustLayerOffset(table.group);
                });

                this.currentRoom.layer.batchDraw();
            }
        },
        duplicateSelectedTables() {
            for (var baseTable of this.selectedTables) {
                //position ?
                //model Element ?
                /*
                    seats: modelElement.seats,
                    type: modelElement.type,
                     */
                const modelElement = this.findElementReference(baseTable.type, baseTable.seats, baseTable.size);
                if (modelElement) {
                    var table = this.createNewTable(
                        modelElement,
                        {
                            id: this.getUniqTableId(),
                            name: baseTable.seats
                                ? this.currentRoom
                                    ? (this.currentRoom.tables.filter((t) => t.seats).length + 1).toString()
                                    : "1"
                                : modelElement.name,
                            seats: baseTable.seats,
                            size: baseTable.size,
                            enabledPax: baseTable.enabledPax,
                            enabled: baseTable.enabled,
                            rotation: baseTable.tableGroup.rotation(),
                            order: this.currentRoom.tables.length,
                        },
                        true
                    );

                    this.currentRoom.tables.push(table);
                    var group = table.group;

                    const clientRect = baseTable.group.getClientRect({ skipTransform: true });
                    table.position = {
                        x: baseTable.group.x() + clientRect.width + 3, // 3 for padding
                        y: baseTable.group.y(),
                    };
                    group.position(table.position);

                    this.currentRoom.layer.add(group);

                    this.adjustLayerOffset(group);

                    this.currentRoom.layer.batchDraw();
                    this.unselectAllTables();
                    this.selectTable(group);
                    this.currentRoom.layer.batchDraw();
                }
            }
        },
        roomChanged({ index, value }) {
            this.rooms.splice(index, 1, value);
        },
        groupsChanged(result) {
            for (var val of result) {
                this.currentRoom.groups.splice(val.index, 1, val.value);
            }
        },
        tablesChanged(result) {
            for (var val of result) {
                this.currentRoom.tables.splice(val.index, 1, val.value);
            }
        },
        getErrorsForGroups(seatingPlanFormatted) {
            const errorGroups = [];

            seatingPlanFormatted.rooms.forEach((room) => {
                const groupNames = room.groups.filter((group) => group.enabled && group.enabledPax.length === 0).map((group) => group.name);
                if (groupNames.length > 0) {
                    errorGroups.push({ room: room.name, groups: groupNames });
                }
            });

            return errorGroups;
        },
    },
    watch: {
        "konva.selectedRoom": {
            handler(newValue, oldValue) {
                if (newValue >= 0 && newValue < this.rooms.length) {
                    if (oldValue !== null && oldValue !== undefined && oldValue >= 0 && oldValue < this.rooms.length) {
                        this.unselectAllTables();
                        this.rooms[oldValue].layer.hide();
                    }

                    const selectedRoom = this.rooms[newValue];

                    selectedRoom.layer.show();
                    selectedRoom.layer.scaleX(1);
                    selectedRoom.layer.scaleY(1);

                    const paddingHeight = selectedRoom.layer.height() * 0.08;
                    const paddingWidth = selectedRoom.layer.width() * 0.08;

                    let selectedRoomClientRect = selectedRoom.layer.getClientRect();

                    if (selectedRoomClientRect.x > 0 && selectedRoom.layer.offsetX() < 0) {
                        const newOffset = selectedRoom.layer.offsetX() + selectedRoomClientRect.x;

                        selectedRoom.layer.offsetX(newOffset > 0 ? 0 : newOffset);
                    }

                    if (selectedRoomClientRect.y > 0 && selectedRoom.layer.offsetY() < 0) {
                        const newOffset = selectedRoom.layer.offsetY() + selectedRoomClientRect.y;

                        selectedRoom.layer.offsetY(newOffset > 0 ? 0 : newOffset);
                    }

                    const offsetX = selectedRoom.layer.offsetX();
                    const offsetY = selectedRoom.layer.offsetY();
                    selectedRoom.layer.offsetX(0);
                    selectedRoom.layer.offsetY(0);

                    let tablesOffsetX = null;
                    let tablesOffsetY = null;

                    selectedRoom.tables.forEach((tableObject) => {
                        const newX = tableObject.group.x() + Math.abs(offsetX);
                        const newY = tableObject.group.y() + Math.abs(offsetY);

                        if (tablesOffsetX === null || newX < tablesOffsetX) {
                            tablesOffsetX = newX;
                        }

                        if (tablesOffsetY === null || newY < tablesOffsetY) {
                            tablesOffsetY = newY;
                        }

                        tableObject.group.x(newX);
                        tableObject.group.y(newY);
                    });

                    tablesOffsetX -= paddingWidth;
                    tablesOffsetY -= paddingHeight;

                    selectedRoom.tables.forEach((tableObject) => {
                        tableObject.group.x(tableObject.group.x() - tablesOffsetX);

                        tableObject.group.y(tableObject.group.y() - tablesOffsetY);
                    });

                    selectedRoomClientRect = selectedRoom.layer.getClientRect();

                    let scaleX = selectedRoom.layer.width() / (selectedRoomClientRect.width + paddingWidth);
                    let scaleY = selectedRoom.layer.height() / (selectedRoomClientRect.height + paddingHeight);

                    let scale = scaleX < scaleY ? scaleX : scaleY;

                    if (scale < 1) {
                        selectedRoom.layer.scaleX(scale);
                        selectedRoom.layer.scaleY(scale);
                    }

                    this.mainStage.x(0);
                    this.mainStage.y(0);
                    selectedRoom.layer.x(0);
                    selectedRoom.layer.y(0);

                    this.mainStage.batchDraw();
                }
            },
        },
    },
    components: {
        SeatingPlanTreeView,
        SeatingPlanTopbar,
        SeatingPlanSidebar,
        LoaderComponent,
    },
    //initialization of the canvas
    mounted() {
        // Get base size to make the konva stage take all the available space
        this.mainStage = new Konva.Stage({
            container: "seating-plan-canvas",
            width: 0,
            height: 0,
            draggable: true,
        });

        this.initRooms().then(() => {
            this.$nextTick(() => {
                this.konva.selectedRoom = 0;

                if (typeof this.$refs.seatingPlanCanvas !== "undefined") {
                    const height = this.$refs.seatingPlanCanvas.offsetHeight;
                    const width = this.$refs.seatingPlanCanvas.offsetWidth;

                    this.canvasResizeObserver.observe(this.$refs.seatingPlanCanvas);
                    this.mainStage.height(height);
                    this.mainStage.width(width);
                }

                this.mainStage.batchDraw();
            });
        });
    },
};
</script>
