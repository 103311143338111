var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loadingRestaurants
    ? _c("loader-component")
    : _vm.user && _vm.user != "" && _vm.user.role !== "kitchen"
    ? _c(
        "div",
        { staticClass: "home-vue p-0 pb-5" },
        [
          _c(
            "div",
            { staticClass: "mobile-dashboard none-desk pb-4" },
            [
              _vm.loadingDatesRange
                ? _c("loader-component", { staticClass: "none-desk" })
                : _vm.restaurants.length > 0
                ? [
                    _vm._l(_vm.restaurants, function (restaurant) {
                      return [
                        _vm.restaurants.map((r) => r.id).includes(restaurant.id)
                          ? _c("div", { key: restaurant.id }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mobile-dashboard-header text-white p-3 shadow-home d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.collapseOtherRestaurants(
                                        restaurant
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mobile-dashboard-restaurant-name",
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(_vm._s(restaurant.name)),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "mr-2 pl-2 pr-1",
                                          staticStyle: { position: "relative" },
                                          attrs: {
                                            to: {
                                              name: "booking.restaurants.reservationsCreated",
                                              params: {
                                                restaurant_id: restaurant.id,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "text-white css-i6dzq1",
                                              attrs: {
                                                viewBox: "0 0 24 24",
                                                width: "24",
                                                height: "24",
                                                stroke: "currentColor",
                                                "stroke-width": "2",
                                                fill: "none",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                              },
                                            },
                                            [
                                              _c("circle", {
                                                attrs: {
                                                  cx: "12",
                                                  cy: "12",
                                                  r: "10",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("polyline", {
                                                attrs: {
                                                  points: "12 6 12 12 16 14",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.restaurants,
                                            function (restau) {
                                              return [
                                                restaurant.id === restau.id &&
                                                restau.reservations_created !==
                                                  0
                                                  ? _c(
                                                      "span",
                                                      {
                                                        key: restau.id,
                                                        staticClass:
                                                          "badge-dashboard-created badge badge-warning",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              restau.reservations_created
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "pl-1 pr-2",
                                          attrs: {
                                            to: {
                                              name: "booking.restaurants.reservations",
                                              params: {
                                                restaurant_id: restaurant.id,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "text-white css-i6dzq1",
                                              attrs: {
                                                viewBox: "0 0 24 24",
                                                width: "24",
                                                height: "24",
                                                stroke: "currentColor",
                                                "stroke-width": "2",
                                                fill: "none",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                              },
                                            },
                                            [
                                              _c("line", {
                                                attrs: {
                                                  x1: "8",
                                                  y1: "6",
                                                  x2: "21",
                                                  y2: "6",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "8",
                                                  y1: "12",
                                                  x2: "21",
                                                  y2: "12",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "8",
                                                  y1: "18",
                                                  x2: "21",
                                                  y2: "18",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "3",
                                                  y1: "6",
                                                  x2: "3.01",
                                                  y2: "6",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "3",
                                                  y1: "12",
                                                  x2: "3.01",
                                                  y2: "12",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "3",
                                                  y1: "18",
                                                  x2: "3.01",
                                                  y2: "18",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "d-none",
                                  class: {
                                    "d-block": _vm.isBoardOpened(restaurant.id),
                                  },
                                },
                                [
                                  _vm.loading > 0 ||
                                  _vm.loadingBoards[restaurant.id] === true
                                    ? _c("LoaderComponent")
                                    : [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mobile-dashboard-date bg-light d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pr-3 pb-2 pl-3",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-outline-secondary radius-btn-square",
                                                staticStyle: {
                                                  width: "29px",
                                                  "padding-left": "5px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.decrementCurrentDayIndex()
                                                  },
                                                },
                                              },
                                              [
                                                _c("feather", {
                                                  attrs: {
                                                    type: "chevron-left",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ml-1 mr-1 pt-1 text-center",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "text-transform":
                                                        "capitalize",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currentDayIndexDay
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.currentDayIndexDate
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-outline-secondary radius-btn-square",
                                                staticStyle: {
                                                  width: "29px",
                                                  "padding-left": "6px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.incrementCurrentDayIndex()
                                                  },
                                                },
                                              },
                                              [
                                                _c("feather", {
                                                  attrs: {
                                                    type: "chevron-right",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.getBoard(restaurant.id)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mobile-dashboard-date-services",
                                                staticStyle: {
                                                  cursor: "pointer",
                                                },
                                              },
                                              [
                                                _vm.getBoard(restaurant.id)
                                                  .services.length > 0 &&
                                                _vm.$_.isEmpty(
                                                  _vm.getBoard(restaurant.id)
                                                    .serviceClosureShown
                                                )
                                                  ? [
                                                      _vm._l(
                                                        _vm.getBoard(
                                                          restaurant.id
                                                        ).services,
                                                        function (service) {
                                                          return [
                                                            service.dates[
                                                              _vm
                                                                .currentDayIndex
                                                            ].is_open
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    key: service.id,
                                                                    staticClass:
                                                                      "table-line p-3 text-capitalize d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.showServiceClosures(
                                                                            _vm.getBoard(
                                                                              restaurant.id
                                                                            ),
                                                                            service
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("div", [
                                                                      _c(
                                                                        "strong",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.getServiceCategoryLabel(
                                                                                service
                                                                              )
                                                                            ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "small",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              service.name
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-weight":
                                                                              "600",
                                                                          },
                                                                      },
                                                                      [
                                                                        service
                                                                          .dates[
                                                                          _vm
                                                                            .currentDayIndex
                                                                        ]
                                                                          .is_full ===
                                                                        1
                                                                          ? _c(
                                                                              "feather",
                                                                              {
                                                                                staticClass:
                                                                                  "text-danger",
                                                                                attrs:
                                                                                  {
                                                                                    type: "minus-circle",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        service
                                                                          .dates[
                                                                          _vm
                                                                            .currentDayIndex
                                                                        ]
                                                                          .is_full ===
                                                                        0
                                                                          ? _c(
                                                                              "feather",
                                                                              {
                                                                                staticClass:
                                                                                  "text-warning",
                                                                                attrs:
                                                                                  {
                                                                                    type: "alert-circle",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "ml-2",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                    " +
                                                                                _vm._s(
                                                                                  service
                                                                                    .dates[
                                                                                    _vm
                                                                                      .currentDayIndex
                                                                                  ]
                                                                                    .pax
                                                                                ) +
                                                                                "/"
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                class:
                                                                                  {
                                                                                    "text-warning":
                                                                                      service
                                                                                        .dates[
                                                                                        _vm
                                                                                          .currentDayIndex
                                                                                      ]
                                                                                        .new_pax !==
                                                                                      null,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    service
                                                                                      .dates[
                                                                                      _vm
                                                                                        .currentDayIndex
                                                                                    ]
                                                                                      .new_pax !==
                                                                                      null
                                                                                      ? service
                                                                                          .dates[
                                                                                          _vm
                                                                                            .currentDayIndex
                                                                                        ]
                                                                                          .new_pax
                                                                                      : service
                                                                                          .dates[
                                                                                          _vm
                                                                                            .currentDayIndex
                                                                                        ]
                                                                                          .max_pax
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        }
                                                      ),
                                                    ]
                                                  : !_vm.$_.isEmpty(
                                                      _vm.getBoard(
                                                        restaurant.id
                                                      ).serviceClosureShown
                                                    )
                                                  ? _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "table-line p-2 text-capitalize d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-bottom-light",
                                                        },
                                                        [
                                                          _c("div", [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn pt-0 pr-1 pb-1 pl-1 d-inline-block",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.goServiceGoBack(
                                                                        _vm.getBoard(
                                                                          restaurant.id
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("feather", {
                                                                  staticStyle: {
                                                                    "pointer-events":
                                                                      "none",
                                                                  },
                                                                  attrs: {
                                                                    type: "chevron-left",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "strong",
                                                              {
                                                                staticClass:
                                                                  "d-inline-block pt-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getServiceCategoryLabel(
                                                                      _vm.getBoard(
                                                                        restaurant.id
                                                                      )
                                                                        .serviceClosureShown
                                                                        .service
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("small", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getBoard(
                                                                    restaurant.id
                                                                  )
                                                                    .serviceClosureShown
                                                                    .service
                                                                    .name
                                                                )
                                                              ),
                                                            ]),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("div", [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getBoard(
                                                                    restaurant.id
                                                                  )
                                                                    .serviceClosureShown
                                                                    .service.pax
                                                                ) + "/"
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  class: {
                                                                    "text-warning":
                                                                      _vm.getBoard(
                                                                        restaurant.id
                                                                      )
                                                                        .serviceClosureShown
                                                                        .service
                                                                        .new_pax !==
                                                                      null,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getBoard(
                                                                        restaurant.id
                                                                      )
                                                                        .serviceClosureShown
                                                                        .service
                                                                        .new_pax !==
                                                                        null
                                                                        ? _vm.getBoard(
                                                                            restaurant.id
                                                                          )
                                                                            .serviceClosureShown
                                                                            .service
                                                                            .new_pax
                                                                        : _vm.getBoard(
                                                                            restaurant.id
                                                                          )
                                                                            .serviceClosureShown
                                                                            .service
                                                                            .max_pax
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-inline-block align-middle pl-2",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  staticClass:
                                                                    "switch align-self-center is-rounded",
                                                                  class:
                                                                    _vm.getStatusClass(
                                                                      _vm.getBoard(
                                                                        restaurant.id
                                                                      )
                                                                        .serviceClosureShown
                                                                        .service
                                                                        .is_full
                                                                    ),
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      _vm.getBoard(
                                                                        restaurant.id
                                                                      )
                                                                        .serviceClosureShown
                                                                        .service
                                                                        .is_full !==
                                                                      1,
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("label", {
                                                                  class:
                                                                    _vm.getStatusClass(
                                                                      _vm.getBoard(
                                                                        restaurant.id
                                                                      )
                                                                        .serviceClosureShown
                                                                        .service
                                                                        .is_full
                                                                    ),
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        _vm.updateServiceAvailability(
                                                                          $event,
                                                                          _vm.getBoard(
                                                                            restaurant.id
                                                                          )
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-inline-block",
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn pl-0",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.showServicePaxes(
                                                                            _vm.getBoard(
                                                                              restaurant.id
                                                                            )
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "feather",
                                                                      {
                                                                        attrs: {
                                                                          type: "chevron-right",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      !_vm.getBoard(
                                                        restaurant.id
                                                      ).serviceClosureShown
                                                        .slotSelected &&
                                                      !_vm.getBoard(
                                                        restaurant.id
                                                      ).serviceClosureShown
                                                        .showServicePaxes
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "detail-box-slots-grid",
                                                            },
                                                            _vm._l(
                                                              _vm.getBoard(
                                                                restaurant.id
                                                              )
                                                                .serviceClosureShown
                                                                .service.slots,
                                                              function (
                                                                slot,
                                                                slotIndex
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: slotIndex,
                                                                    staticClass:
                                                                      "w-auto text-center border-bottom-light detail-box-slot",
                                                                    staticStyle:
                                                                      {
                                                                        cursor:
                                                                          "pointer",
                                                                      },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.showSlotPaxes(
                                                                            _vm.getBoard(
                                                                              restaurant.id
                                                                            ),
                                                                            slot
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                            " +
                                                                        _vm._s(
                                                                          slot.hour_start
                                                                        ) +
                                                                        "\n                                            "
                                                                    ),
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        staticClass:
                                                                          "switch is-small is-rounded",
                                                                        class:
                                                                          _vm.getStatusClass(
                                                                            slot.is_full
                                                                          ),
                                                                        attrs: {
                                                                          type: "checkbox",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            checked:
                                                                              slot.is_full !==
                                                                              1,
                                                                          },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        class:
                                                                          _vm.getStatusClass(
                                                                            slot.is_full
                                                                          ),
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              _vm.updateSlotAvailability(
                                                                                slot,
                                                                                _vm.getBoard(
                                                                                  restaurant.id
                                                                                )
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "feather",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "pointer-events":
                                                                              "none",
                                                                          },
                                                                        attrs: {
                                                                          type: "chevron-right",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.getBoard(
                                                        restaurant.id
                                                      ).serviceClosureShown
                                                        .slotSelected
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "detail-box-slots-grid",
                                                              attrs: {
                                                                tabindex: "-1",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "w-auto text-center border-bottom-light detail-box-slot",
                                                                  attrs: {
                                                                    tabindex:
                                                                      "-1",
                                                                  },
                                                                },
                                                                [
                                                                  _c("strong", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.getBoard(
                                                                          restaurant.id
                                                                        )
                                                                          .serviceClosureShown
                                                                          .slotSelected
                                                                          ? _vm.getBoard(
                                                                              restaurant.id
                                                                            )
                                                                              .serviceClosureShown
                                                                              .slotSelected
                                                                              .hour_start
                                                                          : ""
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                _vm.getAllPaxes(
                                                                  _vm.getBoard(
                                                                    restaurant.id
                                                                  )
                                                                    .serviceClosureShown
                                                                    .widget
                                                                ),
                                                                function (pax) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: pax,
                                                                      staticClass:
                                                                        "w-auto text-center border-bottom-light detail-box-slot",
                                                                      staticStyle:
                                                                        {
                                                                          cursor:
                                                                            "pointer",
                                                                        },
                                                                      attrs: {
                                                                        tabindex:
                                                                          "-1",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                            " +
                                                                          _vm._s(
                                                                            _vm.capitalize(
                                                                              _vm.$tl(
                                                                                "labels.pax"
                                                                              )
                                                                            )
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            pax
                                                                          ) +
                                                                          "\n                                            "
                                                                      ),
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          staticClass:
                                                                            "switch is-small is-rounded",
                                                                          class:
                                                                            {
                                                                              closed:
                                                                                _vm.getBoard(
                                                                                  restaurant.id
                                                                                )
                                                                                  .serviceClosureShown
                                                                                  .slotSelected &&
                                                                                _vm
                                                                                  .getBoard(
                                                                                    restaurant.id
                                                                                  )
                                                                                  .serviceClosureShown.slotSelected.paxes_full.includes(
                                                                                    pax
                                                                                  ),
                                                                              open:
                                                                                _vm.getBoard(
                                                                                  restaurant.id
                                                                                )
                                                                                  .serviceClosureShown
                                                                                  .slotSelected &&
                                                                                !_vm
                                                                                  .getBoard(
                                                                                    restaurant.id
                                                                                  )
                                                                                  .serviceClosureShown.slotSelected.paxes_full.includes(
                                                                                    pax
                                                                                  ),
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              type: "checkbox",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              checked:
                                                                                _vm.getBoard(
                                                                                  restaurant.id
                                                                                )
                                                                                  .serviceClosureShown
                                                                                  .slotSelected &&
                                                                                !_vm
                                                                                  .getBoard(
                                                                                    restaurant.id
                                                                                  )
                                                                                  .serviceClosureShown.slotSelected.paxes_full.includes(
                                                                                    pax
                                                                                  ),
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "ml-2",
                                                                          class:
                                                                            {
                                                                              closed:
                                                                                _vm.getBoard(
                                                                                  restaurant.id
                                                                                )
                                                                                  .serviceClosureShown
                                                                                  .slotSelected &&
                                                                                _vm
                                                                                  .getBoard(
                                                                                    restaurant.id
                                                                                  )
                                                                                  .serviceClosureShown.slotSelected.paxes_full.includes(
                                                                                    pax
                                                                                  ),
                                                                              open:
                                                                                _vm.getBoard(
                                                                                  restaurant.id
                                                                                )
                                                                                  .serviceClosureShown
                                                                                  .slotSelected &&
                                                                                !_vm
                                                                                  .getBoard(
                                                                                    restaurant.id
                                                                                  )
                                                                                  .serviceClosureShown.slotSelected.paxes_full.includes(
                                                                                    pax
                                                                                  ),
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              tabindex:
                                                                                "-1",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                _vm.updateSlotPaxAvailability(
                                                                                  _vm.getBoard(
                                                                                    restaurant.id
                                                                                  ),
                                                                                  pax
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.getBoard(
                                                        restaurant.id
                                                      ).serviceClosureShown
                                                        .showServicePaxes
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "detail-box-slots-grid",
                                                              attrs: {
                                                                tabindex: "-1",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.getAllPaxes(
                                                                _vm.getBoard(
                                                                  restaurant.id
                                                                )
                                                                  .serviceClosureShown
                                                                  .widget
                                                              ),
                                                              function (pax) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: pax,
                                                                    staticClass:
                                                                      "w-auto text-center border-bottom-light detail-box-slot",
                                                                    staticStyle:
                                                                      {
                                                                        cursor:
                                                                          "pointer",
                                                                      },
                                                                    attrs: {
                                                                      tabindex:
                                                                        "-1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                            " +
                                                                        _vm._s(
                                                                          _vm.capitalize(
                                                                            _vm.$tl(
                                                                              "labels.pax"
                                                                            )
                                                                          )
                                                                        ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          pax
                                                                        ) +
                                                                        "\n                                            "
                                                                    ),
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        staticClass:
                                                                          "switch is-small is-rounded",
                                                                        class:
                                                                          _vm.getStatusClass(
                                                                            _vm.getBoard(
                                                                              restaurant.id
                                                                            )
                                                                              .serviceClosureShown
                                                                              .service
                                                                              .paxes_full[
                                                                              pax
                                                                            ]
                                                                          ),
                                                                        attrs: {
                                                                          type: "checkbox",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            checked:
                                                                              _vm.getBoard(
                                                                                restaurant.id
                                                                              )
                                                                                .serviceClosureShown
                                                                                .service
                                                                                .paxes_full[
                                                                                pax
                                                                              ] ===
                                                                              -1,
                                                                          },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        class:
                                                                          _vm.getStatusClass(
                                                                            _vm.getBoard(
                                                                              restaurant.id
                                                                            )
                                                                              .serviceClosureShown
                                                                              .service
                                                                              .paxes_full[
                                                                              pax
                                                                            ]
                                                                          ),
                                                                        attrs: {
                                                                          tabindex:
                                                                            "-1",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              _vm.updateServicePaxAvailability(
                                                                                _vm.getBoard(
                                                                                  restaurant.id
                                                                                ),
                                                                                pax
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e(),
                                                    ])
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ],
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ]
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row none-mobile m-0" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                },
                [
                  _c("h5", { staticClass: "title mt-2" }, [
                    _vm._v(_vm._s(_vm.$tl("labels.routes.booking.dashboard"))),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row none-mobile m-0" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _vm.loadingRestaurants
                  ? _c("LoaderComponent")
                  : _c("div", [
                      _vm.error
                        ? _c("div", { staticClass: "error" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.error) +
                                "\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.restaurants && _vm.restaurants.length > 0
                        ? _c(
                            "div",
                            _vm._l(_vm.restaurants, function (restaurant) {
                              return _c(
                                "div",
                                { key: restaurant.id, staticClass: "mb-3" },
                                [
                                  _c("div", { staticClass: "mb-2" }, [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "card-title m-0 p-3 bg-light",
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "booking.restaurants.reservations",
                                                params: {
                                                  restaurant_id: restaurant.id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(restaurant.name) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.user &&
                                        (_vm.user.role === "admin" ||
                                          _vm.user.role === "owner" ||
                                          _vm.user.role === "manager")
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "float-right none-tablet",
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "booking.restaurants.settings.settings",
                                                        params: {
                                                          restaurant_id:
                                                            restaurant.id,
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("feather", {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value: _vm.getTooltip(
                                                            _vm.$t(
                                                              "labels.routes.booking.settings"
                                                            )
                                                          ),
                                                          expression:
                                                            "getTooltip($t('labels.routes.booking.settings'))",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "settings",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "card-body p-0 row m-0" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-xl-3 col-lg-4 col-sm-6 p-0 pr-2 mb-2",
                                          },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "media align-items-stretch bg-grad-warning white",
                                                attrs: {
                                                  to: {
                                                    name: "booking.restaurants.reservationsCreated",
                                                    params: {
                                                      restaurant_id:
                                                        restaurant.id,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "p-3 media-middle",
                                                  },
                                                  [
                                                    _c("feather", {
                                                      staticClass: "mt-2",
                                                      attrs: { type: "clock" },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "media-body pt-3 pr-3 pb-3 pl-1",
                                                  },
                                                  [
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass: "mt-2",
                                                        staticStyle: {
                                                          display: "block",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.$tl(
                                                                "labels.booking.reservations.nb"
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("h6", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.pending"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "media-right p-3 media-middle",
                                                  },
                                                  [
                                                    _c(
                                                      "h4",
                                                      { staticClass: "pt-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            restaurant.reservations_created
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          restaurant.service_categories,
                                          function (category) {
                                            return _c(
                                              "div",
                                              {
                                                key: category,
                                                staticClass:
                                                  "col-xl-3 col-lg-4 col-sm-6 p-0 pr-2 mb-2",
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "media align-items-stretch bg-grad white",
                                                    attrs: {
                                                      to: {
                                                        name: "booking.restaurants.reservations",
                                                        params: {
                                                          restaurant_id:
                                                            restaurant.id,
                                                        },
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "p-3 media-middle",
                                                      },
                                                      [
                                                        _c("feather", {
                                                          staticClass: "mt-2",
                                                          attrs: {
                                                            type: "calendar",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media-body pt-3 pr-3 pb-3 pl-1",
                                                      },
                                                      [
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass: "mt-2",
                                                            staticStyle: {
                                                              display: "block",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  _vm.$tl(
                                                                    "labels.booking.pax.nb",
                                                                    restaurant.id
                                                                  )
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("h6", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getServiceCategoryLabel(
                                                                restaurant[
                                                                  `service_category_${category}`
                                                                ]
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media-right p-3 media-middle",
                                                      },
                                                      [
                                                        _c(
                                                          "h4",
                                                          {
                                                            staticClass: "pt-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                restaurant[
                                                                  `service_category_${category}`
                                                                ]
                                                                  .nb_reservations_today
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-4 float-right" }, [
                        _vm.links.prev
                          ? _c(
                              "a",
                              {
                                staticClass: "btn btn-sm btn-outline-secondary",
                                attrs: { href: "javascript:" },
                                on: {
                                  click: function ($event) {
                                    return _vm.fetchData(_vm.links.prev)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$tl("labels.form.actions.previous")
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.links.next
                          ? _c(
                              "a",
                              {
                                staticClass: "btn btn-sm btn-outline-secondary",
                                attrs: { href: "javascript:" },
                                on: {
                                  click: function ($event) {
                                    return _vm.fetchData(_vm.links.next)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$tl("labels.form.actions.next")
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.loadingDatesRange
            ? _c("loader-component")
            : _vm.dates_range !== null
            ? _c(
                "ejs-tooltip",
                {
                  ref: "tooltip",
                  staticClass: "tooltipcontainer none-mobile p-3",
                  attrs: {
                    content: _vm.serviceTooltipContent,
                    target: ".service-tooltip-button",
                    position: "BottomCenter",
                    afterOpen: _vm.onAfterOpen,
                    opensOn: "Custom",
                  },
                },
                [
                  _vm.board && _vm.board.restaurants
                    ? _c(
                        "div",
                        { ref: "board", staticClass: "booking-board" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-2 mb-0 border-bottom",
                            },
                            [
                              _c(
                                "h2",
                                { staticClass: "h5" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-outline-secondary radius-btn-square float-left",
                                      staticStyle: {
                                        width: "29px",
                                        "padding-left": "5px",
                                      },
                                      attrs: {
                                        disabled: _vm.atLeastOneBoardLoading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.offset -= 1
                                        },
                                      },
                                    },
                                    [
                                      _c("feather", {
                                        attrs: { type: "chevron-left" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("DatePicker", {
                                    staticClass:
                                      "date-resa-cal float-left mt-1",
                                    attrs: {
                                      tabindex: "1",
                                      disabled: _vm.atLeastOneBoardLoading,
                                      format: _vm.weekDateFormatter,
                                    },
                                    model: {
                                      value: _vm.pickerDate,
                                      callback: function ($$v) {
                                        _vm.pickerDate = $$v
                                      },
                                      expression: "pickerDate",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-outline-secondary radius-btn-square float-left",
                                      staticStyle: {
                                        width: "29px",
                                        "padding-left": "6px",
                                      },
                                      attrs: {
                                        disabled: _vm.atLeastOneBoardLoading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.offset += 1
                                        },
                                      },
                                    },
                                    [
                                      _c("feather", {
                                        attrs: { type: "chevron-right" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-outline-secondary btn-circle ml-2",
                                      attrs: {
                                        type: "button",
                                        disabled: _vm.atLeastOneBoardLoading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.offset = 0
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(_vm.$tl("labels.thisWeek")) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("feather", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.getTooltipNotice(
                                          "notices.booking.dashboard"
                                        ),
                                        expression:
                                          "getTooltipNotice('notices.booking.dashboard')",
                                      },
                                    ],
                                    staticClass: "ml-1 pointer tooltip-infos",
                                    attrs: { type: "info" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "table",
                            {
                              staticClass:
                                "table-home capitalize table table-sm table-striped border-bottom mb-5 text-center table-resp-width",
                              attrs: { tabindex: "-1" },
                            },
                            [
                              _c("thead", { attrs: { tabindex: "-1" } }, [
                                _c(
                                  "tr",
                                  { attrs: { tabindex: "-1" } },
                                  [
                                    _c("th", { attrs: { tabindex: "-1" } }),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.$_.range(0, 7),
                                      function (index) {
                                        return _c(
                                          "th",
                                          {
                                            key: index,
                                            class: {
                                              "board-current-day":
                                                _vm.offset === 0 &&
                                                _vm
                                                  .getDateTime(
                                                    _vm.dates_range[index],
                                                    false,
                                                    _vm.getRestaurantTimeZone()
                                                  )
                                                  .hasSame(
                                                    _vm.getDateTime(
                                                      null,
                                                      false,
                                                      _vm.getRestaurantTimeZone()
                                                    ),
                                                    "day"
                                                  ),
                                            },
                                            attrs: { tabindex: "-1" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.capitalize(
                                                    _vm.displayDate(
                                                      _vm.dates_range[index],
                                                      "ccc - dd/LL",
                                                      false
                                                    )
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.restaurants,
                                function (restau, indexRestau) {
                                  return [
                                    _c(
                                      "tbody",
                                      {
                                        key: indexRestau,
                                        attrs: { tabindex: "-1" },
                                      },
                                      [
                                        _c(
                                          "tr",
                                          {
                                            staticClass:
                                              "bg-main-color-light text-white",
                                            attrs: { tabindex: "-1" },
                                          },
                                          [
                                            _c(
                                              "td",
                                              {
                                                staticClass: "text-left p-2",
                                                attrs: { tabindex: "-1" },
                                              },
                                              [
                                                _c("strong", [
                                                  _vm._v(_vm._s(restau.name)),
                                                ]),
                                                _vm._v(" "),
                                                _c("feather", {
                                                  staticClass: "pointer",
                                                  attrs: {
                                                    type: _vm.isBoardOpened(
                                                      restau.id
                                                    )
                                                      ? "chevron-down"
                                                      : "chevron-right",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toggleBoard(
                                                        restau.id
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.$_.range(0, 7),
                                              function (index) {
                                                return _c("td", {
                                                  key: index,
                                                  attrs: { tabindex: "-1" },
                                                })
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _vm.isBoardOpened(restau.id)
                                          ? [
                                              _vm.loadingBoards[restau.id] ===
                                                true ||
                                              typeof _vm.getBoard(restau.id) ===
                                                "undefined"
                                                ? [
                                                    _c(
                                                      "tr",
                                                      [
                                                        _c("loader-component", {
                                                          attrs: {
                                                            message: "",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          _vm.$_.range(0, 7),
                                                          function (index) {
                                                            return _c("td", {
                                                              key: index,
                                                              attrs: {
                                                                tabindex: "-1",
                                                              },
                                                            })
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                : [
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticClass:
                                                          "bg-secondary",
                                                        style: {
                                                          opacity:
                                                            _vm.isYservices
                                                              ? 0.5
                                                              : 1,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-white text-left",
                                                          },
                                                          [
                                                            _c("feather", {
                                                              staticClass:
                                                                "mx-2",
                                                              attrs: {
                                                                type: "volume-2",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$tl(
                                                                  "labels.booking.customEvents.title"
                                                                )
                                                              ) +
                                                                "\n                                    "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          _vm.$_.range(0, 7),
                                                          function (index) {
                                                            return _c(
                                                              "td",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "board-cell-nb-events",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.showEventsForDay(
                                                                        restau,
                                                                        _vm
                                                                          .dates_range[
                                                                          index
                                                                        ]
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                        " +
                                                                    _vm._s(
                                                                      _vm.getEventsForDay(
                                                                        restau,
                                                                        _vm
                                                                          .dates_range[
                                                                          index
                                                                        ]
                                                                      ).length
                                                                    ) +
                                                                    "\n                                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      _vm.getBoard(restau.id)
                                                        .services,
                                                      function (service) {
                                                        return _c(
                                                          "tr",
                                                          {
                                                            key: service.id,
                                                            attrs: {
                                                              tabindex: "-1",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-left",
                                                                attrs: {
                                                                  tabindex:
                                                                    "-1",
                                                                },
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getServiceCategoryLabel(
                                                                        service
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]),
                                                                _c("small", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      service.name
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              service.dates,
                                                              function (
                                                                date,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "td",
                                                                  {
                                                                    key: `${service.id}-${index}`,
                                                                    staticClass:
                                                                      "p-0",
                                                                  },
                                                                  [
                                                                    date.is_open
                                                                      ? _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "btn w-100 h-100 service-tooltip-button",
                                                                            style:
                                                                              date.almost_full ===
                                                                              true
                                                                                ? "color: #F75B61 !important;"
                                                                                : "color: #666;",
                                                                            attrs:
                                                                              {
                                                                                tabindex:
                                                                                  "-1",
                                                                                "data-service-id":
                                                                                  service.id,
                                                                                "data-date":
                                                                                  date.full_date,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                _vm.customOpen,
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "font-weight":
                                                                                      "600",
                                                                                    "pointer-events":
                                                                                      "none",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    _vm.customOpen,
                                                                                },
                                                                              },
                                                                              [
                                                                                date.is_full ===
                                                                                1
                                                                                  ? _c(
                                                                                      "feather",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-danger",
                                                                                        attrs:
                                                                                          {
                                                                                            type: "minus-circle",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : date.is_full ===
                                                                                    0
                                                                                  ? _c(
                                                                                      "feather",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-warning",
                                                                                        attrs:
                                                                                          {
                                                                                            type: "alert-circle",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  "\n                                                " +
                                                                                    _vm._s(
                                                                                      date.pax
                                                                                    ) +
                                                                                    "/"
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      {
                                                                                        "text-warning":
                                                                                          date.new_pax !==
                                                                                          null,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        date.new_pax !==
                                                                                          null
                                                                                          ? date.new_pax
                                                                                          : date.max_pax
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      }
                                                    ),
                                                  ],
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showAddReservationModal
            ? _c("add-reservation-modal", {
                attrs: {
                  restaurant_id: _vm.selected_restaurant_id,
                  service_id: _vm.selected_service_id,
                  slot_id: _vm.selected_slot_id,
                  date: _vm.selected_full_date,
                },
                on: {
                  close: function ($event) {
                    _vm.showAddReservationModal = false
                  },
                  "reservation-saved": _vm.reservationSaved,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showSetServiceFullModal
            ? _c("set-service-full-modal", {
                attrs: {
                  restaurant_id: _vm.selected_restaurant_id,
                  service_id: _vm.selected_service_id,
                  slot_id: _vm.selected_slot_id,
                  date: _vm.selected_full_date,
                  setFree: _vm.serviceFullModalSetFree,
                },
                on: {
                  close: function ($event) {
                    _vm.showSetServiceFullModal = false
                  },
                  "closure-saved": _vm.closureSaved,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showRemoveServiceFullModal
            ? _c("remove-service-full-modal", {
                attrs: {
                  restaurant_id: _vm.selected_restaurant_id,
                  service_id: _vm.selected_service_id,
                  slot_id: _vm.selected_slot_id,
                  date: _vm.selected_full_date,
                },
                on: {
                  close: function ($event) {
                    _vm.showRemoveServiceFullModal = false
                  },
                  "closure-removed": _vm.closureRemoved,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.listCustomEventsProps.restaurantId !== null &&
          _vm.listCustomEventsProps.date !== null
            ? _c(
                "list-custom-events",
                _vm._b(
                  { ref: "listCustomEvents" },
                  "list-custom-events",
                  _vm.listCustomEventsProps,
                  false
                )
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }