<template>
    <div>
        <div class="row">
            <div class="col-lg-12 pt-3">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <div class="row">
                        <div class="col-12">
                            <strong>Choisissez votre cible</strong>
                            <ShowErrors :errors="errors" errorKey="targets" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-3">
                            <div class="row">
                                <div class="col-md-4">Clients</div>
                                <div class="col-md-8">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        :checked="!campaign.send_to_owners_only" />
                                    Comptes admin uniquement ({{ clientsAdmin.length }})
                                    <label
                                        @click="campaign.send_to_owners_only = !campaign.send_to_owners_only"></label>
                                    Tous ({{ clients.length }})
                                    <ShowErrors class="d-block" :errors="errors" errorKey="send_to_owners_only" />
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-4 pt-1">Clients actifs uniquement</div>
                                <div class="col-md-8">
                                    <label class="container-box">
                                        <input
                                            type="checkbox"
                                            :disabled="campaign.send_to_trials_only"
                                            v-model="campaign.send_to_active_owners_only"
                                            true-value="1" />
                                        <span
                                            class="checkmark"
                                            :class="{ disabled: campaign.send_to_trials_only }"></span>
                                    </label>
                                    <ShowErrors
                                        class="d-block"
                                        :errors="errors"
                                        errorKey="send_to_active_owners_only" />
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-4 pt-1">Clients en période d'essai uniquement</div>
                                <div class="col-md-8">
                                    <label class="container-box">
                                        <input type="checkbox" v-model="campaign.send_to_trials_only" true-value="1" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <ShowErrors class="d-block" :errors="errors" errorKey="send_to_trials_only" />
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-4 pt-1">Type de renouvellement</div>
                                <div class="col-md-8">
                                    <select class="custom-select" v-model="campaign.send_to_plan">
                                        <option :value="null">Tous</option>
                                        <option
                                            :value="plan.value"
                                            v-for="plan in ALL_STRIPE_PLAN_TYPES"
                                            :key="plan.value">
                                            {{ plan.label }}
                                        </option>
                                    </select>
                                    <ShowErrors class="d-block" :errors="errors" errorKey="send_to_plan" />
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-4 pt-1">Clients qui ont une licence</div>
                                <div class="col-md-8">
                                    <label
                                        class="container-box"
                                        style="width: auto !important"
                                        v-for="module in ALL_MODULES_TYPES_WITH_OPTIONS"
                                        :key="module.value">
                                        <input type="checkbox" v-model="campaign.has_modules" :value="module.value" />
                                        <span class="checkmark"></span> {{ module.label }}
                                    </label>
                                    <ShowErrors class="d-block" :errors="errors" errorKey="has_modules" />
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-4 pt-1">Clients qui n'ont pas de licence</div>
                                <div class="col-md-8">
                                    <label
                                        class="container-box"
                                        style="width: auto !important"
                                        v-for="module in ALL_MODULES_TYPES_WITH_OPTIONS"
                                        :key="module.value">
                                        <input
                                            type="checkbox"
                                            v-model="campaign.doesnt_have_modules"
                                            :value="module.value" />
                                        <span class="checkmark"></span> {{ module.label }}
                                    </label>
                                    <ShowErrors class="d-block" :errors="errors" errorKey="doesnt_have_modules" />
                                </div>
                            </div>
                            <div class="row mt-3" v-if="campaign.type == 'sms'">
                                <div class="col-md-4 pt-1">Envoyer la campagne au numéros étrangers</div>
                                <div class="col-md-8">
                                    <label class="container-box">
                                        <input
                                            type="checkbox"
                                            v-model="campaign.send_to_foreign_numbers"
                                            true-value="1" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <ShowErrors class="d-block" :errors="errors" errorKey="send_to_foreign_numbers" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ShowErrors from "../../../errors/ShowErrors";
import StripePlanTypesEnum from "../../../../mixins/enums/StripePlanTypesEnum";
import ModuleTypesEnum from "../../../../mixins/enums/ModuleTypesEnum";

export default {
    props: {
        campaign: {
            type: Object,
            required: true,
        },
        errors: {
            default: null,
        },
        clients: {
            type: Array,
            required: true,
        },
    },
    mixins: [StripePlanTypesEnum, ModuleTypesEnum],
    computed: {
        clientsAdmin() {
            return this.clients.filter((c) => c.role == "owner");
        },
    },
    watch: {
        "campaign.send_to_trials_only": function (newVal) {
            if (newVal) this.campaign.send_to_active_owners_only = false;
        },
    },
    components: {
        ShowErrors,
    },
};
</script>
