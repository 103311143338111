var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row m-0" },
      [
        _c("div", { staticClass: "col-12 my-3" }, [
          _c("h5", { staticClass: "title mt-2 ml-2 pt-2" }, [
            _vm._v(
              _vm._s(
                _vm.$tl("labels.notifications.listNotificationsLogs.title")
              )
            ),
          ]),
        ]),
        _vm._v(" "),
        !_vm.has_right_to_read_logmails
          ? _c("div", { staticClass: "col-12 my-3" }, [
              _c("p", [
                _vm._v(_vm._s(_vm.$tl("errors.common.rights.notEnough"))),
              ]),
            ])
          : _c(
              "div",
              { staticClass: "col-12 my-3", staticStyle: { color: "#666666" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "col-12 border-light b-radius-20 py-4 px-xl-4",
                  },
                  [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 d-xl-flex justify-content-between align-items-center date-resa-cal",
                        },
                        [
                          _c("div", { staticClass: "col d-xl-flex" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center relative my-2 mr-2",
                              },
                              [
                                _c("span", { staticClass: "mr-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.$tl("labels.filters.dates.from"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("DatePicker", {
                                  staticClass: "d-inline-block",
                                  attrs: {
                                    "input-class": "form-control",
                                    format: _vm.DATE_SHORT,
                                    "can-be-empty": "",
                                    showClearButton: true,
                                    "disabled-dates": _vm.disabledDatesFrom,
                                  },
                                  model: {
                                    value: _vm.currentFilters.from,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.currentFilters, "from", $$v)
                                    },
                                    expression: "currentFilters.from",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center relative my-2 mr-2",
                              },
                              [
                                _c("span", { staticClass: "mr-1" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .$tl("labels.filters.dates.to")
                                        .toLowerCase()
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("DatePicker", {
                                  staticClass: "d-inline-block",
                                  attrs: {
                                    "input-class": "form-control",
                                    format: _vm.DATE_SHORT,
                                    "can-be-empty": "",
                                    showClearButton: true,
                                    "disabled-dates": _vm.disabledDatesTo,
                                  },
                                  model: {
                                    value: _vm.currentFilters.to,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.currentFilters, "to", $$v)
                                    },
                                    expression: "currentFilters.to",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.currentLogMailTypes.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "col my-2 d-xl-flex align-items-xl-center",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.notifications.listNotificationsLogs.mailType"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.currentFilters.notifications,
                                          expression:
                                            "currentFilters.notifications",
                                        },
                                      ],
                                      staticClass:
                                        "custom-select d-inline-block ml-2 mr-2 mb-0 w-50",
                                      staticStyle: { width: "initial" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.currentFilters,
                                            "notifications",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm.currentLogMailTypes.length > 1
                                        ? _c(
                                            "option",
                                            { domProps: { value: null } },
                                            [_vm._v("--")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.currentLogMailTypes,
                                        function (mailLog) {
                                          return _c(
                                            "option",
                                            {
                                              key: mailLog.value,
                                              domProps: {
                                                value: mailLog.value,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.notifications.mail"
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.getReservationMailLogLabel(
                                                      mailLog.value
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col my-2 d-xl-flex align-items-xl-center",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$tl("labels.filters.status")) +
                                  "\n                            "
                              ),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.currentFilters.status,
                                      expression: "currentFilters.status",
                                    },
                                  ],
                                  staticClass:
                                    "custom-select d-inline-block ml-2 mr-2 mb-0",
                                  staticStyle: { width: "initial" },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.currentFilters,
                                        "status",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { domProps: { value: null } }, [
                                    _vm._v("--"),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.ALL_LOG_MAIL_STATUS,
                                    function (logStatus) {
                                      return _c(
                                        "option",
                                        {
                                          key: logStatus.value,
                                          domProps: { value: logStatus.value },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.$tl(
                                                  `labels.notifications.listNotificationsLogs.${logStatus.keyLabel}`
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col my-2 d-xl-flex" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-success btn-circle ml-2",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleFilters(false)
                                  },
                                },
                              },
                              [
                                _c("feather", {
                                  staticClass: "icon bg-none",
                                  attrs: { type: "filter", size: "20" },
                                }),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$tl("labels.form.actions.filter")
                                    ) +
                                    "\n                            "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-secondary btn-circle ml-2",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleFilters(true)
                                  },
                                },
                              },
                              [
                                _c("feather", {
                                  staticClass: "icon bg-none",
                                  attrs: { type: "rotate-ccw", size: "20" },
                                }),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$tl("labels.form.actions.reset")
                                    ) +
                                    "\n                            "
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]
            ),
        _vm._v(" "),
        _vm.has_right_to_read_logmails
          ? _c(
              "div",
              { staticClass: "col notifications-logs-grid ns-pb-mobile" },
              [
                _vm.isLoading
                  ? _c("loader-component")
                  : [
                      _vm.moduleType === _vm.MODULE_TYPE_BOOKING.value
                        ? _c(
                            "div",
                            [
                              _c("switch-input-component", {
                                attrs: {
                                  label: _vm.$tl(
                                    "labels.booking.waitings.mailLogs.see"
                                  ),
                                  "is-form-grid": false,
                                },
                                model: {
                                  value: _vm.isWaitingsMode,
                                  callback: function ($$v) {
                                    _vm.isWaitingsMode = $$v
                                  },
                                  expression: "isWaitingsMode",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.component.main
                        ? _c("ns-table", {
                            attrs: {
                              tableClass:
                                "table table-striped overflowx-auto mr-3 table-responsive-xl",
                              "data-source": _vm.mailLogs,
                              "toolbar-settings": _vm.toolbarSettings,
                              "is-server-side": "",
                              "allow-paging": "",
                              clickable: _vm.component.modal !== null,
                              "use-slot-headings": "",
                              "use-slot-body": "",
                              pagination: _vm.pagination,
                              "current-sort": _vm.queries.sort,
                            },
                            on: {
                              "update-data": function ($event) {
                                return _vm.updateDataFromEvent($event)
                              },
                              "row-selected": _vm.rowSelected,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "head",
                                  fn: function () {
                                    return [
                                      _c(`${_vm.component.main}-headings`, {
                                        tag: "component",
                                        attrs: {
                                          "is-waitings-mode":
                                            _vm.isWaitingsMode,
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "body",
                                  fn: function ({
                                    filteredDataSource,
                                    clickable,
                                    keyColumn,
                                  }) {
                                    return [
                                      _c(`${_vm.component.main}-body`, {
                                        tag: "component",
                                        attrs: {
                                          "is-waitings-mode":
                                            _vm.isWaitingsMode,
                                          "filtered-data-source":
                                            filteredDataSource,
                                          "restaurant-id": _vm.restaurantId,
                                          clickable: clickable,
                                          "key-column": keyColumn,
                                          "is-fragment-loading":
                                            _vm.isFragmentLoading,
                                          "is-disabled": _vm.isDisabled,
                                        },
                                        on: { "send-mail": _vm.sendMail },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              746574322
                            ),
                          })
                        : _vm._e(),
                    ],
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.component.modal && _vm.showModal
          ? _c(_vm.component.modal, {
              tag: "component",
              attrs: {
                reservation_id: _vm.selectedItemId,
                command_id: _vm.selectedItemId,
                sale_id: _vm.selectedItemId,
                restaurant_id: _vm.restaurantId,
                "must-not-emit": true,
                "show-edit-button": false,
              },
              on: {
                close: function ($event) {
                  _vm.showModal = false
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }