<template>
    <div class="row m-0 div-valid-resa">
        <div class="col-lg-8 offset-lg-2 mb-3">
            <div class="bg-danger text-center text-white p-1" style="border-radius: 100px">
                La commande ne correspond pas au service
            </div>
            <show-click-and-collect-command :restaurant_id="command.restaurant_id" :command_id="command.id" />
        </div>
    </div>
</template>

<script>
import ShowClickAndCollectCommand from "./Show";

export default {
    name: "showInvalidCommand",
    components: { ShowClickAndCollectCommand },
    props: {
        command: {
            required: true,
        },
    },
};
</script>
