<template>
    <div class="credit-bank p-3 mb-3 bg-light">
        <div>
            <span>{{ sepa_debit.billing_details.name }}</span
            ><br />
            <span
                >{{ sepa_debit.sepa_debit.country }}XX XXXX XXXX XXXX XXXX XXX{{ sepa_debit.sepa_debit.last4[0] }}
                {{ sepa_debit.sepa_debit.last4.substr(1) }}</span
            >
        </div>
    </div>
</template>

<script>
export default {
    props: {
        sepa_debit: {
            type: Object,
            required: true,
        },
    },
};
</script>
