var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LoaderComponent", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          staticClass: "image-component-display pt-1 pr-1 pb-0 pl-1",
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLoadSrcEmpty,
                expression: "!isLoadSrcEmpty",
              },
            ],
            staticClass: "img-fluid",
            style: _vm.addStyle,
            attrs: { src: this.loadSrc, alt: "Image " + this.alt },
          }),
          _vm._v(" "),
          _c("div", { class: { "mt-1": !_vm.isLoadSrcEmpty } }, [
            _c(
              "label",
              {
                staticClass: "label-file mr-2",
                class: { "text-muted": _vm.disabled, "cursor-d": _vm.disabled },
                attrs: { for: _vm.formImgId },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      !_vm.isSrcEmpty
                        ? _vm.$tl("labels.form.image.chooseNew")
                        : _vm.$tl("labels.form.image.choose")
                    ) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c("input", {
              ref: "fileInput",
              staticClass: "input-file",
              attrs: {
                disabled: _vm.disabled,
                type: "file",
                id: _vm.formImgId,
                accept: _vm.allowedExtensions,
              },
              on: { change: _vm.showPreview },
            }),
            _vm._v(" "),
            _vm.upload && _vm.unsaveChanges
              ? _c("input", {
                  staticClass: "btn btn-success btn-sm btn-circle",
                  attrs: {
                    disabled: _vm.disabled || _vm.isFileTooBig,
                    type: "submit",
                    name: "submit",
                  },
                  domProps: { value: _vm.$tl("labels.form.actions.send") },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.postImage.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.deleteURI && !_vm.isSrcEmpty
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-danger btn-sm btn-circle",
                    attrs: { disabled: _vm.disabled, type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteImage.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.form.image.delete")) +
                        "\n            "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.maxSize !== null
            ? _c("div", { staticClass: "mb-2" }, [
                _vm.isFileTooBig
                  ? _c("small", { staticClass: "text-danger" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("errors.common.image.tooBig", {
                            size: _vm.fileSize,
                            maxSize: _vm.maxSize,
                          })
                        )
                      ),
                    ])
                  : _c("small", { staticClass: "text-warning d-block" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("infos.common.file.maxSize", {
                            maxSize: _vm.maxSize,
                          })
                        )
                      ),
                    ]),
                _vm._v(" "),
                _c("small", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.iloveimg.com/fr/compresser-image",
                        target: "_blank",
                      },
                    },
                    [
                      _c("feather", {
                        staticClass: "text-warning mr-1",
                        attrs: { type: "image" },
                      }),
                      _vm._v(
                        _vm._s(_vm.$tl("labels.form.image.compress")) +
                          "\n                "
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.upload && _vm.unsaveChanges && !_vm.isFileTooBig
        ? _c("span", { staticClass: "text-danger" }, [
            _c("small", [
              _vm._v(_vm._s(_vm.$tl("labels.form.image.dontForgetSave"))),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }