var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [
            _c("h5", { staticClass: "title mt-2" }, [
              _vm._v(_vm._s(this.$tl("labels.form.facturation.titleShort"))),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "border-light b-radius-20 p-4 mb-3" },
          [
            _c("stripe-customer-form", {
              staticClass: "mb-2",
              attrs: {
                errors: _vm.formErrors,
                editable: _vm.facturationEditable,
                "show-buttons": false,
              },
              model: {
                value: _vm.billingDetails,
                callback: function ($$v) {
                  _vm.billingDetails = $$v
                },
                expression: "billingDetails",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "border-light b-radius-20 p-4 mb-3" },
          [
            !_vm.only_option
              ? _c("licences-details", {
                  attrs: {
                    plan: _vm.plan,
                    training: _vm.training,
                    modules: _vm.modules,
                  },
                  on: {
                    "update-promo-code": function ($event) {
                      _vm.promoCode = $event
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("stripe-customer-card-list", {
              model: {
                value: _vm.cardSelected,
                callback: function ($$v) {
                  _vm.cardSelected = $$v
                },
                expression: "cardSelected",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row m-0 mt-2" },
          [
            _vm.cgvsError
              ? _c(
                  "div",
                  {
                    staticClass: "alert-danger alert position-relative",
                    staticStyle: { top: "0px" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$tl("errors.cgvs.servicesCp")) +
                        "\n                "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.modulesWithCgvId, function (module) {
              return _c(
                "label",
                {
                  key: module.id,
                  staticClass: "col-md-12 container-box",
                  staticStyle: { width: "100%" },
                },
                [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      id: `accept-cgv-${module.cgvId}`,
                      name: `accept-cgv-${module.cgvId}`,
                      required: "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.cgvAcceptationChanged($event, module.type)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                  _vm._v(
                    _vm._s(_vm.$tl("labels.form.licences.CGU1")) +
                      "\n                    "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "alert-link",
                      attrs: {
                        href: `/cgu_${module.cgvId}.pdf?v=${_vm.$__BUILD_ID__}`,
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$tl("labels.form.licences.CGU2")) +
                          " " +
                          _vm._s(_vm.getModuleTitle(module))
                      ),
                    ]
                  ),
                  _vm._v(".\n                "),
                ]
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary btn-circle",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.$emit("back")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$tl("labels.form.actions.back")) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.cardSelected
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success btn-circle ml-2",
                      class: { disabled: _vm.loading },
                      attrs: { type: "button", disabled: _vm.loading },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.createSubscription.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$tl("labels.form.actions.finalizeRegistration")
                          ) +
                          "\n                    "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }