<template>
    <div>
        <LoaderComponent v-if="isLoading" />
        <div v-else>
            <div v-if="errors && Object.values(errors).length > 0" class="p-3 mb-3 rounded alert-danger">
                <ShowErrors :errors="errors" errorKey="*" errClass="" />
            </div>
            <div class="row">
                <OverviewComponent
                    v-if="canPreviousStep"
                    v-bind="{ isSpecial, service, restaurant, restaurantsToSuggest, menus, generalOptions, hasEnabledGoogleReserve }"
                    :currentStep="steps.index" />
                <div :class="{ 'col-8': canPreviousStep, 'col-12': !canPreviousStep }">
                    <form id="add-service-form" @submit.prevent="postForm">
                        <keep-alive>
                            <component
                                :is="componentName"
                                @has-errors="$_.isArray($event) ? (errors = $event) : $_.isString($event) ? (errors = [$event]) : ''"
                                @clear-errors="errors = []"
                                @fetch-menus="fetchMenus"
                                @fetch-options="fetchOptions"
                                @update-menus="updateMenus"
                                @update-general-options="updateGeneralOptions"
                                @update-external-menus="updateExternalMenus"
                                for-services
                                v-bind="{
                                    isSpecial,
                                    seatingPlans,
                                    service,
                                    widget,
                                    restaurant,
                                    restaurantsToSuggest,
                                    menus,
                                    generalOptions,
                                    externalMenuFromProps: externalMenu,
                                    settings: service.googleReserveSettings,
                                }" />
                        </keep-alive>
                    </form>
                </div>
            </div>
            <div class="d-flex justify-content-end mt-3">
                <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" @click.prevent="$emit('close')">
                    {{ $tl("labels.form.actions.cancel") }}
                </button>
                <button v-if="canPreviousStep" type="button" class="btn btn-sm btn-success btn-circle ml-2" @click.prevent="goPreviousStep">
                    {{ $tl("labels.form.actions.previous") }}
                </button>
                <button v-if="canNextStep" type="button" class="btn btn-sm btn-success btn-circle ml-2" @click.prevent="goNextStep">
                    {{ $tl("labels.form.actions.next") }}
                </button>
                <button v-else type="submit" form="add-service-form" class="btn btn-sm btn-success btn-circle ml-2">
                    {{ $tl("labels.form.actions.save") }}
                </button>
            </div>
        </div>
        <no-seating-plan-modal v-if="showNoSeatingPlanModal" closeButtonTitle="Retour" @close="showNoSeatingPlanModal = false" />
        <confirm-opening-create-modal
            v-if="showConfirmModal"
            @close="showConfirmModal = false"
            @confirmed="sendForm"
            :formLoading="isLoading"
            :date_begin="service.general.special.openDate"
            :date_end="service.general.special.closeDate"
            :servicesId="showConfirmModalServicesId"
            :cancelData="confirmModalData" />
    </div>
</template>

<script>
import axios from "axios";
import GeneralGroup from "./addServiceForm/GeneralGroup.vue";
import MenuAndOptionsGroup from "./addServiceForm/MenuAndOptionsGroup.vue";
import PaymentGroup from "./addServiceForm/PaymentGroup.vue";
import MiscGroup from "./addServiceForm/MiscGroup.vue";
import LoaderComponent from "../LoaderComponent.vue";
import OverviewComponent from "./addServiceForm/Overview.vue";
import NoSeatingPlanModal from "../Modals/seatingPlan/NoSeatingPlanModal.vue";
import ShowErrors from "../errors/ShowErrors.vue";
import ConfirmOpeningCreateModal from "../Modals/openings/ConfirmOpeningCreateModal.vue";
import ExternalMenuTypeEnum from "../../mixins/enums/booking/ExternalMenuTypeEnum.js";
import googleReserve from "./googleReserve.vue";

export default {
    name: "addServiceForm",
    data() {
        return this.$_.cloneDeep(this.defaultData());
    },
    props: {
        isSpecial: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [ExternalMenuTypeEnum],
    computed: {
        hasEnabledGoogleReserve() {
            return this.$store.getters["widgets/getWidget"].enable_google_reserve || false;
        },
        isMenusEmpty() {
            return !this.service.menus.value || this.$_.isEmpty(this.service.menus.value);
        },
        isGeneralOptionsEmpty() {
            return !this.service.generalOptions.value || this.$_.isEmpty(this.service.generalOptions.value);
        },
        canNextStep() {
            return this.steps.index < this.steps.components.length - 1;
        },
        canPreviousStep() {
            return this.steps.index > 0;
        },
        componentName() {
            return this.steps.components.length > this.steps.index ? this.steps.components[this.steps.index].name : "";
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        restaurantFromStore() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id);
        },
        hasFeatSeatingPlan() {
            return typeof this.restaurantFromStore !== "undefined" && this.restaurantFromStore.feat_seating_plan;
        },
        hasPaymentSystem() {
            return this.restaurantFromStore && this.restaurantFromStore.has_psp;
        },
    },
    methods: {
        updateMenus(currentMenus) {
            this.service.menus.mandatory = currentMenus.config.isMandatory;
            this.service.menus.mandatoryAbovePax = currentMenus.config.nbPersMandatoryAbovePax;
            this.service.menus.mustBeUnique = currentMenus.config.isUnique;
            this.service.menus.isUniqueAbovePax = currentMenus.config.nbPersUniqueAbovePax;
            this.service.menus.value = currentMenus.checkedMenus;
        },
        updateGeneralOptions(checkedGeneralOptions) {
            this.service.generalOptions.value = checkedGeneralOptions;
        },
        updateExternalMenus(externalMenu) {
            this.externalMenu = externalMenu;
        },
        defaultData() {
            let data = {
                showConfirmModal: false,
                showConfirmModalServicesId: "",
                confirmModalData: {
                    message_object: this.$tl("labels.booking.reservations.defaultMailObj"),
                    message_body: "",
                    cancel_enabled: false,
                    reservations_ids: "",
                },
                seatingPlans: [],
                restaurant: {
                    id: null,
                    has_psp: this.hasPaymentSystem,
                    hasASeatingPlan: false,
                },
                widget: {
                    min_pax: null,
                    max_pax: null,
                },
                restaurantsToSuggest: [],
                menus: [],
                generalOptions: [],
                showNoSeatingPlanModal: false,
                service: {
                    general: {
                        category: {
                            value: null,
                            nameFr: null,
                            nameEn: null,
                        },
                        name: null,
                        openDays: [],
                        openHours: {
                            start: "00:00",
                            end: "00:00",
                        },
                        slots: {},
                        pax: {
                            max: null,
                            perSlot: false,
                        },
                        show_on_widget: true,
                        cancellation: {
                            delay: 24,
                        },
                        lock: {
                            enabled: false,
                            until: 60,
                        },
                        autoconfirm: {
                            enabled: false,
                            upToPercentFull: 80,
                            upToPax: 10,
                            upToMinutesBeforeStart: 60,
                            upToNoshow: 0,
                        },
                        is_slot_max_reservations_enabled: 0,
                        is_slot_max_pax_enabled: 0,
                        use_widget_min_pers: true,
                        min_pers: null,
                        use_widget_max_pers: true,
                        max_pers: null,
                    },
                    paxes: {
                        rotation: false,
                        value: [],
                    },
                    menus: {
                        value: {},
                        mustBeUnique: false,
                        isUniqueAbovePax: 8,
                        mandatory: false,
                        mandatoryAbovePax: null,
                    },
                    generalOptions: {
                        value: [],
                    },
                    payment: {
                        bankImprint: {
                            enabled: false,
                            enabledAbovePax: 1,
                            amount: 20,
                        },
                        prepayment: {
                            enabled: false,
                            mandatory: false,
                            enabledAbovePax: 5,
                            percentage: 50,
                        },
                        autoCancel: false,
                    },
                    full: {
                        restaurantsProposals: [],
                    },
                    seatingPlan: {
                        algorithm_enabled: false,
                        allow_clients_choose_room: false,
                        allow_clients_choose_room_mandatory: 0,
                        value: null,
                    },
                    googleReserveSettings: {
                        enabled: false,
                    },
                    sendReminderPayment: {
                        isAuto: false,
                        hours: 12,
                    },
                },
                errors: [],
                isLoading: true,
                isComponentLoading: false,
                steps: {
                    /*
                            Step 1: GeneralGroup,
                            Step 2: MenuAndOptionsGroup,
                            Step 3: PaymentGroup,
                            Step 4: googleReserve,
                            step 5: MiscGroup,
                        */
                    components: [
                        {
                            name: "GeneralGroup", // Step 1
                        },
                        {
                            name: "MenuAndOptionsGroup", // Step 2
                        },
                        {
                            name: "PaymentGroup", // Step 3
                        },
                    ],
                    index: 0,
                },
                externalMenu: {
                    type: null,
                    content: {
                        link: "",
                        file: "",
                    },
                    hasFile: false,
                    file: null,
                },
            };
            if (this.isSpecial) {
                data.service.general.special = {
                    openDate: this.getDateTime(),
                    closeDate: this.getDateTime(),
                };
                data.service.general.openDays = this.englishDays();
            }
            return data;
        },
        fetchData() {
            this.resetData();

            let promises = [this.fetchWidget(), this.fetchRestaurantsToSuggest(), this.fetchMenus(), this.fetchOptions()];

            if (this.hasFeatSeatingPlan) {
                promises.push(this.fetchSeatingPlan());
            }

            return Promise.all(promises);
        },
        fetchSeatingPlan() {
            return this.httpGet(`/api/restaurants/${this.$route.params.restaurant_id}/seatingPlans`).then((response) => {
                if (response !== false && response.data.data.length > 0) {
                    this.restaurant.hasASeatingPlan = true;
                    this.seatingPlans = response.data.data;
                }
            });
        },
        fetchWidget() {
            return this.httpGet(`/api/restaurants/${this.$route.params.restaurant_id}/widget`).then((response) => {
                if (response !== false) {
                    this.widget.min_pax = isNaN(Number.parseInt(response.data.min_pers)) ? null : Number.parseInt(response.data.min_pers);
                    this.widget.max_pax = isNaN(Number.parseInt(response.data.max_pers)) ? null : Number.parseInt(response.data.max_pers);
                    this.widget.enable_children = response.data.enable_children;
                    this.widget.max_children = isNaN(Number.parseInt(response.data.max_children))
                        ? null
                        : Number.parseInt(response.data.max_children);
                    if (this.widget.min_pax === null || this.widget.max_pax === null) {
                        this.errors = [this.$tl("errors.common.unknown")];
                    }
                }
            });
        },
        fetchRestaurantsToSuggest() {
            return this.httpGet(`/api/booking/restaurants?all=1&exclude_canceled=1`).then((response) => {
                if (response !== false) {
                    this.restaurantsToSuggest = this.filterRestaurantsToSuggest(response.data.data);
                }
            });
        },
        fetchMenus() {
            return this.httpGet(`/api/restaurants/${this.$route.params.restaurant_id}/menus?all=1`).then((response) => {
                if (response !== false) {
                    this.menus = response.data.data;
                }
            });
        },
        fetchOptions() {
            return this.httpGet(`/api/restaurants/${this.$route.params.restaurant_id}/general/options`).then((response) => {
                if (response !== false) {
                    this.generalOptions = response.data.data;
                }
            });
        },
        filterRestaurantsToSuggest(suggestedRestaurants) {
            return suggestedRestaurants.filter((suggestedRestaurant) => suggestedRestaurant.id !== Number.parseInt(this.restaurant_id));
        },
        goNextStep() {
            if (this.steps.index < this.steps.components.length - 1) {
                ++this.steps.index;
            }
        },
        goPreviousStep() {
            if (this.steps.index > 0) {
                --this.steps.index;
            }
        },
        getServicesOverrideByOpening() {
            return axios.post(
                `/api/restaurants/${this.$route.params.restaurant_id}/services/openings/getServicesOverride`,
                this.formatServiceForForm()
            );
        },
        postForm() {
            if (!this.isSpecial || this.showConfirmModal) return this.sendForm();
            this.errors = [];
            this.isLoading = true;
            this.getServicesOverrideByOpening()
                .then((response) => {
                    if (!response.data.services_override) {
                        return this.sendForm();
                    }
                    const servicesId = response.data.services_override.join(",");
                    axios
                        .get(
                            `/api/restaurants/${this.$route.params.restaurant_id}/reservations` +
                                `/filter?begin=${this.formatDateToEn(this.service.general.special.openDate)}&end=${this.formatDateToEn(
                                    this.service.general.special.closeDate
                                )}` +
                                `&services=${servicesId}&excluded_status=pending-canceled-refused-noshow`
                        )
                        .then((response) => {
                            if (response.data.length > 0) {
                                this.showConfirmModalServicesId = servicesId;
                                this.showConfirmModal = true;
                                this.isLoading = false;
                            } else {
                                return this.sendForm();
                            }
                        });
                })
                .catch((error) => {
                    if (error.response) {
                        this.errors = error.response.data.errors;
                    } else {
                        this.errors = [this.getErrorMsgFromErrorResponse(error)];
                    }
                    this.isLoading = false;
                });
        },
        sendForm(e) {
            if (e) {
                e.preventDefault();
            }
            this.errors = [];
            this.isLoading = true;
            this.showConfirmModal = false;

            const axiosPromise = axios.post("/api/restaurants/" + this.$route.params.restaurant_id + "/services", this.formatServiceForForm());

            axiosPromise
                .then(() => {
                    this.notifySuccess(null, this.$tl("success.booking.services.added", this.$route.params.restaurant_id));
                    this.setFormLoading(false);
                    this.$emit("service-added", true);
                    this.close();
                })
                .catch((err) => {
                    if (err.response && err.response.data && err.response.data.errors) {
                        this.setFormErrors(err.response.data.errors);
                    } else if (err.message) {
                        this.setFormErrors({ message: err.message });
                    }
                    this.setFormLoading(false);
                });

            return axiosPromise;
        },
        setFormErrors(value) {
            this.errors = value;
        },
        setFormLoading(value) {
            this.isLoading = value;
        },
        formatServiceForForm() {
            const formData = new FormData();
            let data = {
                name: this.service.general.name,
                category: this.service.general.category.value,
                custom_category_fr: this.service.general.category.nameFr,
                custom_category_en: this.service.general.category.nameEn,
                days: this.service.general.openDays,
                hour_begin: this.service.general.openHours.start,
                hour_end: this.service.general.openHours.end,
                max_pax: this.service.general.pax.max,
                lock_reservation_until_enabled: this.service.general.lock.enabled,
                lock_reservation_until: this.service.general.lock.until,
                special: this.isSpecial,
                slots: this.service.general.slots,
                menu_mandatory: this.service.menus.mandatory,
                menu_mandatory_from: this.service.menus.mandatoryAbovePax,
                menu_unique: this.service.menus.mustBeUnique,
                menu_unique_from: this.service.menus.isUniqueAbovePax,
                prepayment_enabled: this.isMenusEmpty && this.isGeneralOptionsEmpty ? false : this.service.payment.prepayment.enabled,
                prepayment_percent: this.service.payment.prepayment.percentage,
                prepayment_mandatory: this.service.payment.prepayment.mandatory,
                prepayment_min_pers: this.service.payment.prepayment.enabledAbovePax,
                menus: this.service.menus.value,
                general_options_selected: this.service.generalOptions.value,
                alternative_restaurants: this.service.full.restaurantsProposals,
                autoconfirm: this.service.general.autoconfirm.enabled,
                autoconfirm_percent: this.service.general.autoconfirm.upToPercentFull,
                autoconfirm_max_pers: this.service.general.autoconfirm.upToPax,
                autoconfirm_until: this.service.general.autoconfirm.upToMinutesBeforeStart,
                lock_nb_noshow: this.service.general.autoconfirm.upToNoshow,
                cancellation_until: this.service.general.cancellation.delay,
                enable_footprint: this.service.payment.bankImprint.enabled,
                footprint_min_pers: this.service.payment.bankImprint.enabledAbovePax,
                amount_noshow: this.service.payment.bankImprint.amount,
                option_bank_auto_cancel: this.service.payment.autoCancel,
                is_table_rotation_enabled: this.service.paxes.rotation,
                paxes_durations: this.service.paxes.value,
                enable_pax_per_slot: this.service.general.pax.perSlot,
                is_seating_plan_algorithm_enabled: this.service.seatingPlan.value ? this.service.seatingPlan.algorithm_enabled : false,
                seating_plan_id: this.service.seatingPlan.value ? this.service.seatingPlan.value.id : null,
                is_slot_max_pax_enabled: this.service.general.is_slot_max_pax_enabled,
                is_slot_max_reservations_enabled: this.service.general.is_slot_max_reservations_enabled,
                use_widget_min_pers: this.service.general.use_widget_min_pers,
                use_widget_max_pers: this.service.general.use_widget_max_pers,
                min_pers: this.service.general.min_pers,
                max_pers: this.service.general.max_pers,
                allow_clients_choose_room:
                    this.service.seatingPlan.value && this.service.seatingPlan.algorithm_enabled
                        ? this.service.seatingPlan.allow_clients_choose_room
                        : false,
                allow_clients_choose_room_mandatory:
                    this.service.seatingPlan.value && this.service.seatingPlan.algorithm_enabled && this.service.seatingPlan.allow_clients_choose_room
                        ? this.service.seatingPlan.allow_clients_choose_room_mandatory
                        : 0,
                show_on_widget: this.service.general.show_on_widget,
                reminder_payment_is_auto: this.service.sendReminderPayment.isAuto,
                external_menu: this.externalMenu.content,
                external_menu_type: this.externalMenu.type,
                enable_google_reserve: this.service.googleReserveSettings.enabled,
            };

            if (this.isSpecial) {
                data.special_date_begin = this.service.general.special.openDate.toISODate();
                data.special_date_end = this.service.general.special.closeDate.toISODate();
                data.cancel_enabled = this.confirmModalData.cancel_enabled;
                data.message_object = this.confirmModalData.message_object;
                data.message_body = this.confirmModalData.message_body;
                data.reservations_ids = this.confirmModalData.reservations_ids;
            }
            if (this.service.sendReminderPayment.isAuto) {
                data.reminder_payment_hours = this.service.sendReminderPayment.hours;
            }

            formData.append("payload", JSON.stringify(data));
            if (this.isValidFile(this.externalMenu.file) && this.externalMenu.type === this.EXTERNAL_MENU_FILE.value) {
                formData.append("file", this.externalMenu.file);
            }
            return formData;
        },
        resetData() {
            Object.assign(this.$data, this.$_.cloneDeep(this.defaultData()));
        },
        formatDateToEn(date) {
            return this.getDateTime(date).toISODate();
        },
    },
    watch: {
        "step.component": {
            handler: function (newVal, oldVal) {
                this.isComponentLoading = false;
            },
        },
        "service.seatingPlan.algorithm_enabled": {
            handler: function (newVal, oldVal) {
                if (newVal === true && !this.restaurant.hasASeatingPlan) {
                    this.$nextTick(() => {
                        this.$set(this.service.seatingPlan, "algorithm_enabled", false);
                        this.showNoSeatingPlanModal = true;
                    });
                }
            },
        },
        restaurant_id(value) {
            this.isLoading = true;

            this.fetchData().finally(() => {
                this.restaurant.id = value;
                this.isLoading = false;
            });
        },
        restaurantsToSuggest(val) {
            const lastStep = this.steps.components[this.steps.components.length - 1];

            if (this.hasEnabledGoogleReserve && this.isReserveWithGoogleEnabled() && !this.steps.components.some((c) => c.name === "googleReserve")) {
                this.steps.components.push({ name: "googleReserve" });
            }

            if (lastStep !== "MiscGroup" && this.service && this.restaurant && val && !_.isEmpty(val)) {
                this.steps.components.push({ name: "MiscGroup" });
            } else {
                if (lastStep === "MiscGroup") {
                    this.steps.components.pop();
                }
            }
        },
    },
    created() {
        this.fetchData().finally(() => {
            this.restaurant.id = this.restaurant_id;
            this.isLoading = false;
        });
    },
    components: {
        GeneralGroup,
        MenuAndOptionsGroup,
        PaymentGroup,
        MiscGroup,
        LoaderComponent,
        OverviewComponent,
        NoSeatingPlanModal,
        ShowErrors,
        ConfirmOpeningCreateModal,
        googleReserve,
    },
};
</script>
