export const mutations = {
    setWidget(state, widget) {
        state.widget = widget;
    },
    emptyWidget(state) {
        for (const key in state.widget) {
            delete state.widget[key];
        }
    },
    toggleIsLoading(state) {
        state.isLoading = !isLoading;
    },
    setIsLoading(state, isLoading) {
        state.isLoading = isLoading;
    },
};

export default mutations;
