<template>
    <div>
        <div class="row p-1">
            <div v-if="errors.seatingPlan || vueMultiSelect.isInvalid" class="alert alert-danger">
                <p v-if="errors.seatingPlan" v-html="errors.seatingPlan"></p>
                <p v-if="vueMultiSelect.isInvalid">{{ $tl("labels.booking.reservations.edit.tableNotAvailable", restaurant_id) }}</p>
            </div>
            <div class="col-12">
                <div v-if="reservation.service && reservation.service.is_table_rotation_enabled == true" class="row">
                    <div class="col-6">
                        <label
                            >{{ $tl(`labels.booking.reservations.${isPassingCustomer ? "passingCustomer" : "edit"}.duration`) }}
                            <small>*</small></label
                        >
                    </div>
                    <div class="col-6">
                        <vue-timepicker
                            format="HH:mm"
                            :minute-interval="15"
                            :language="fr"
                            v-model="reservation.duration"
                            :disabled="hasTablesSelected"
                            :lazy="true"
                            @change="reservation.is_duration_customized = true">
                        </vue-timepicker>
                        <small class="d-block text-muted">{{
                            $tl(`infos.booking.reservations.edit${isPassingCustomer ? ".passingCustomer" : ""}.duration`)
                        }}</small>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-2">
                <div class="row">
                    <div class="col-md-6">
                        {{ $tl("labels.booking.reservations.edit.table", restaurant_id) }}
                        <div
                            class="mt-2 text-muted"
                            style="font-size: 11px"
                            v-if="reservation.service && reservation.service.is_seating_plan_algorithm_enabled">
                            <LoaderComponent v-if="loading.can_place" />
                            <span v-else-if="can_place && !isPassingCustomer">
                                {{ $tl("infos.booking.reservations.add.willBePlacedAuto", restaurant_id) }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div v-if="reservation.service && nb_pers_total > 0 && reservation.service.has_seating_plan == true">
                            <LoaderComponent v-if="loading.tables" />
                            <span class="text-danger" v-else-if="errors.tables">{{ errors.tables }}</span>
                            <template v-else>
                                <vue-multiselect
                                    v-model="vueMultiSelect.entitiesSelected"
                                    :options="tablesMultiselectOptions"
                                    :multiple="true"
                                    label="name"
                                    track-by="name"
                                    group-values="entities"
                                    group-label="roomName"
                                    :group-select="false"
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    :show-labels="false"
                                    :placeholder="$tl('labels.booking.seatingPlan.tables', restaurant_id)"
                                    :custom-label="customTablesLabel"
                                    open-direction="bottom"
                                    :disabled="reservation.ignore_placement"
                                    @select="tableSelected($event)"
                                    @remove="tableUnselected($event)"
                                    style="font-size: 12.8px !important">
                                    <template slot="noOptions">
                                        <span v-if="!reservation.slot_id">
                                            {{ $tl("labels.booking.reservations.selectSlot") }}
                                        </span>
                                        <span v-else>
                                            {{ $tl("labels.noResult") }}
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <template v-if="!props.option.$isLabel">
                                            <div class="float-left">
                                                {{ props.option.type === "table" ? "Table" : "Groupe" }}
                                                {{ props.option.name }}
                                            </div>
                                            <div class="float-right" v-if="props.option.enabledPax.length > 0">
                                                {{ capitalize($tl("labels.pax")) }} :
                                                <template v-for="(item, index) in props.option.enabledPax">
                                                    {{ index > 0 ? " " : ""
                                                    }}{{ props.option.enabledPax.length - 1 === index ? `${item}` : `${item},` }}
                                                </template>
                                            </div>
                                            <div class="float-right" v-else>{{ capitalize($tl("labels.pax")) }} : {{ props.option.seats }}</div>
                                        </template>
                                        <template v-else>
                                            <div>{{ props.option.$groupLabel }}</div>
                                        </template>
                                    </template>
                                </vue-multiselect>
                                <div
                                    v-if="
                                        reservation.service &&
                                        reservation.service.is_seating_plan_algorithm_enabled &&
                                        !loading.can_place &&
                                        !can_place &&
                                        vueMultiSelect.entitiesSelected.length === 0
                                    ">
                                    <label class="container-box text-danger mt-2 mb-1 w-auto">
                                        <input type="checkbox" :checked="reservation.ignore_placement" @change="toggleIgnorePlacement" />
                                        <span class="checkmark"></span>{{ $tl("labels.booking.seatingPlan.ignorePlacement") }}
                                    </label>
                                    <div class="text-danger">
                                        <small>{{ $tl("infos.booking.seatingPlan.ignorePlacement") }}</small>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="row mb-2" v-else-if="nb_pers_total > 0 && reservation.service && reservation.service.has_seating_plan == false">
                            <div class="col-md-6 pt-2">
                                <label for="num_table">{{ $tl("labels.booking.seatingPlan.table.title") }}</label>
                            </div>
                            <div class="col-md-6">
                                <input
                                    id="num_table"
                                    type="text"
                                    v-model="reservation.num_table"
                                    class="form-control"
                                    tabindex="4"
                                    autocomplete="off"
                                    :class="{ 'is-invalid': hasErrors('num_table') }" />
                                <div v-if="hasErrors('num_table')">
                                    <div class="invalid-feedback d-block" v-for="err in formErrors.errors.num_table">
                                        {{ err }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span v-if="vueMultiSelect.paxFull && !isPassingCustomer">{{ $tl("labels.booking.reservations.fullyPlaced") }}</span>
                    </div>
                </div>
            </div>

            <div class="col-md-6 mt-2"></div>
            <div class="col-12" v-if="reservation.service && reservation.slot && nb_pers_total > 0">
                <LoaderComponent v-if="loading.menus" />
                <div class="row" v-else-if="available_menus && available_menus.length > 0">
                    <div class="col-12 mt-3 text-muted">
                        {{ $tl("labels.booking.reservations.add.chooseMenus", restaurant_id) }}
                    </div>
                    <div class="col-12 mt-2">
                        <menus-reservation
                            class="pl-1"
                            :restaurant-id="Number.parseInt(restaurant_id)"
                            :formErrors="formErrors"
                            :available_menus="available_menus"
                            :reservation="reservation"
                            :nb_total="nb_pers_total" />
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="reservation.service && reservation.slot && nb_pers_total > 0">
                <LoaderComponent v-if="loading.general_options" />
                <div class="row" v-else-if="available_general_options && available_general_options.length > 0">
                    <div class="col-12 mt-1 text-muted">
                        {{ $tl("labels.booking.reservations.add.chooseGeneralOptions") }}
                    </div>
                    <div class="col-12 mt-2">
                        <general-options-reservation
                            class="pl-1"
                            :restaurant-id="Number.parseInt(restaurant_id)"
                            :formErrors="formErrors"
                            :available_general_options="available_general_options"
                            :reservation="reservation"
                            :nb_total="nb_pers_total" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import VueMultiselect from "vue-multiselect";
import VueTimepicker from "vue2-timepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import LoaderComponent from "../../../LoaderComponent.vue";
import menusReservation from "../menusReservation.vue";
import generalOptionsReservation from "../generalOptionsReservation.vue";

export default {
    data() {
        return {
            fr,
            loading: {
                tables: false,
                menus: false,
                general_options: false,
                can_place: false,
            },
            errors: {
                tables: null,
                menus: null,
                general_options: null,
                seatingPlan: null,
            },
            available_menus: null,
            available_general_options: null,
            socket: {
                listeningChannels: {
                    services: [],
                },
            },
            checksAbortController: {
                pax: null,
                seatingPlan: null,
            },
            can_place: false,
        };
    },
    props: {
        baseReservation: {
            default: null,
        },
        vueMultiSelect: {
            type: Object,
            required: true,
        },
        restaurant_id: {
            default: null,
        },
        reservation: {
            type: Object,
            required: true,
        },
        formErrors: {
            default: null,
        },
        readOnly: {
            default: false,
        },
        tablesIdToPreselect: {
            default: () => {
                return [];
            },
        },
        isPassingCustomer: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        reservationStartsAt() {
            if (this.reservation.slot === null) {
                return this.reservation.reservation_date;
            }

            return this.reservation.reservation_date.set({
                hour: this.reservation.slot.hour_start.split(":")[0],
                minute: this.reservation.slot.hour_start.split(":")[1],
            });
        },
        reservationEndsAt() {
            if (this.reservationStartsAt && this.reservation.duration) {
                return this.reservationStartsAt.plus({
                    hours: this.reservation.duration.HH,
                    minutes: this.reservation.duration.mm,
                });
            }

            return null;
        },
        nb_pers_total() {
            return (this.reservation.nb_pers || 0) + (this.reservation.nb_children || 0);
        },
        seatingPlan() {
            return this.reservation.service ? this.reservation.service.seatingPlanFilled : null;
        },
        tablesMultiselectOptions() {
            var result = [];
            let roomIdSelected = null;

            if (this.seatingPlan && this.reservation.slot_id) {
                if (this.vueMultiSelect.entitiesSelected.length > 0) {
                    roomIdSelected = this.vueMultiSelect.entitiesSelected[0].roomId;
                }

                this.seatingPlan.rooms.forEach((room) => {
                    if (room.isEnabledForService || room.enabled === 1) {
                        var roomResult = {
                            id: room.id,
                            roomName: room.name,
                            entities: [],
                        };

                        room.entities.forEach((entity) => {
                            const slot = entity.slots.find((s) => s.id == this.reservation.slot_id);

                            if (entity.enabled && slot) {
                                let entityFormated = null;
                                let isDisabled = false;

                                if (roomIdSelected !== null && roomIdSelected !== room.id) {
                                    isDisabled = true;
                                }

                                switch (entity.type) {
                                    case "table":
                                        entityFormated = this.getTableFormattedForMultiSelect(entity, isDisabled, slot);
                                        break;
                                    case "group":
                                        entityFormated = this.getGroupFormattedForMultiSelect(entity, isDisabled, slot);
                                        break;
                                }

                                if (entityFormated) {
                                    roomResult.entities.push({
                                        ...entityFormated,
                                        roomId: room.id,
                                    });
                                }
                            }
                        });

                        if (!_.isEmpty(roomResult.entities)) {
                            result.push(roomResult);
                        }
                    }
                });
            }

            return result;
        },
        hasTablesSelected() {
            return this.seatingPlan ? this.vueMultiSelect.entitiesSelected.length > 0 : false;
        },
        tablesSelected() {
            var result = [];
            for (var entity of this.vueMultiSelect.entitiesSelected) {
                switch (entity.type) {
                    case "table":
                        result.push({
                            id: entity.id,
                            pax: this.nb_pers_total,
                        });
                        break;
                    case "group":
                        for (const tableIndex in entity.tables) {
                            const table = entity.tables[tableIndex];

                            result.push({
                                id: table.id,
                                pax: this.nb_pers_total,
                            });
                        }
                        break;
                }
            }

            return result;
        },
    },
    methods: {
        toggleIgnorePlacement() {
            this.reservation.ignore_placement = !this.reservation.ignore_placement;
            this.$emit("enable-next-button", this.reservation.ignore_placement);
        },
        setMultiSelectSelectedEntities(value) {
            const newTablesId = value
                .map((entity) => {
                    if (entity.type === "table") {
                        return entity.id;
                    }

                    return null;
                })
                .filter((e) => e);
            const oldTablesId = this.vueMultiSelect.entitiesSelected
                .map((entity) => {
                    if (entity.type === "table") {
                        return entity.id;
                    }

                    return null;
                })
                .filter((e) => e);
            const newGroupsId = value
                .map((entity) => {
                    if (entity.type === "group") {
                        return entity.id;
                    }

                    return null;
                })
                .filter((e) => e);
            const oldGroupsId = this.vueMultiSelect.entitiesSelected
                .map((entity) => {
                    if (entity.type === "group") {
                        return entity.id;
                    }

                    return null;
                })
                .filter((e) => e);

            //TODO : Make diff to add / remove
            this.vueMultiSelect.entitiesSelected.forEach((entity) => {
                if (entity.type === "table" && !newTablesId.includes(entity.id)) {
                    this.tableUnselected(entity);
                } else if (entity.type === "group" && !newGroupsId.includes(entity.id)) {
                    this.tableUnselected(entity);
                }
            });
            value.forEach((entity) => {
                if (entity.type === "table" && !oldTablesId.includes(entity.id)) {
                    this.tableUnselected(entity);
                } else if (entity.type === "group" && !oldGroupsId.includes(entity.id)) {
                    this.tableUnselected(entity);
                }
            });
            this.vueMultiSelect.entitiesSelected = value;
        },
        loadMenusData() {
            if (this.reservation.slot === null) {
                return;
            }

            this.loading.menus = true;
            this.errors.menus = null;
            this.available_menus = [];

            axios
                .get(`/api/restaurants/${this.restaurant_id}/services/${this.reservation.slot.service_id}/menus?include=menu_options`)
                .then((response) => {
                    this.loading.menus = false;
                    this.available_menus = response.data.data;
                })
                .catch((error) => {
                    this.loading.menus = false;
                    this.errors.menus = this.getErrorMsgFromErrorResponse(error);
                });
        },
        loadGeneralOptionsData() {
            if (this.reservation.slot === null) {
                return;
            }

            this.loading.general_options = true;
            this.errors.general_options = null;
            this.available_general_options = [];

            axios
                .get(`/api/restaurants/${this.restaurant_id}/services/${this.reservation.slot.service_id}/general_options`)
                .then((response) => {
                    this.loading.general_options = false;
                    this.available_general_options = response.data.data;
                })
                .catch((error) => {
                    this.loading.general_options = false;
                    this.errors.general_options = this.getErrorMsgFromErrorResponse(error);
                });
        },
        customTablesLabel({ name, type }) {
            switch (type) {
                case "table":
                    return `${this.$tl("labels.booking.seatingPlan.table.title")} ${name}`;
                case "group":
                    return `${this.$tl("labels.booking.seatingPlan.group.title")} ${name}`;
            }
        },
        getTableFormattedForMultiSelect(table, isDisabled, slot) {
            if (table.enabled) {
                if (isDisabled === false) {
                    for (const entity of this.vueMultiSelect.entitiesSelected) {
                        if (entity.type === "group") {
                            if (entity.tables.filter((t) => t.id === table.id).length > 0) {
                                isDisabled = true;
                                break;
                            }
                        }
                    }
                }

                if (isDisabled === false && this.vueMultiSelect.paxFull) {
                    const ids = this.vueMultiSelect.entitiesSelected.filter((e) => e.type === "table").map((e) => e.id);

                    isDisabled = !ids.includes(table.id);
                }

                const foundEntity = this.vueMultiSelect.entitiesSelected.find((e) => {
                    switch (e.type) {
                        case "table":
                            return e.id === table.id;
                        case "group":
                            return e.tables.find((t) => t.id === table.id);
                    }
                });

                if (
                    !foundEntity &&
                    (!slot.entityEnabled || !this.isTableAvailableOnFutureSlots(table)) &&
                    (!this.baseReservation || !this.baseReservation.tables.data.find((t) => t.id == table.id))
                ) {
                    return null;
                }

                return {
                    ...table,
                    type: "table",
                    $isDisabled: isDisabled,
                };
            }

            return null;
        },
        isTableAvailableOnFutureSlots(table) {
            if (this.reservation.service && this.reservation.slot_id && this.reservationStartsAt && this.reservationEndsAt) {
                const slots = table.slots.filter((s) => {
                    if (this.reservation.slot_id !== s.id) {
                        const slotHourStart = this.getSlotHourStart(s);
                        return this.reservationStartsAt <= slotHourStart && slotHourStart < this.reservationEndsAt;
                    }

                    return false;
                });

                return !slots.some((s) => {
                    return s.entityEnabled === false;
                });
            }

            return false;
        },
        tableSelected(entity) {
            if (entity.type === "table") {
                this.reserveTable(entity);
            } else if (entity.type === "group") {
                this.reserveGroup(entity);
            }
        },
        tableUnselected(entity, checks = true) {
            if (entity.type === "table") {
                this.releaseTable(entity, checks);
            } else if (entity.type === "group") {
                this.releaseGroup(entity, checks);
            }
        },
        isTableFromBaseReservation(table) {
            if (this.baseReservation) {
                const baseReservationTablesId = this.baseReservation.tables.data.map((t) => t.id);

                return baseReservationTablesId.includes(table.id);
            }

            return false;
        },
        isGroupFromBaseReservation(group) {
            if (this.baseReservation) {
                const baseReservationTablesId = this.baseReservation.tables.data.map((t) => t.id);

                return group.tables.filter((t) => !baseReservationTablesId.includes(t.id)).length === 0;
            }
            return false;
        },
        releaseTable(entity, checks = true) {
            if (!checks || !this.isTableFromBaseReservation(entity)) {
                axios
                    .delete(
                        `/api/restaurants/${this.restaurant_id}/services/slots/${this.reservation.slot_id}/tables/reserved/new_resa/${
                            entity.id
                        }?date=${this.reservation.reservation_date.toISODate()}`
                    )
                    .then(() => {
                        //ok socket event should be fired and will update front
                    })
                    .catch((error) => {
                        this.notifyError(error);

                        if (checks) {
                            this.vueMultiSelect.entitiesSelected.push(entity);
                        }
                    });
            }
        },
        releaseGroup(entity, check = true) {
            if (!check || !this.isGroupFromBaseReservation(entity)) {
                axios
                    .delete(
                        `/api/restaurants/${this.restaurant_id}/services/slots/${this.reservation.slot_id}/groups/reserved/new_resa/${
                            entity.id
                        }?date=${this.reservation.reservation_date.toISODate()}`
                    )
                    .then(() => {
                        //ok socket event should be fired and will update front
                    })
                    .catch((error) => {
                        this.notifyError(error);

                        this.vueMultiSelect.entitiesSelected.push(entity);
                    });
            }
        },
        reserveGroup(entity) {
            if (!this.isGroupFromBaseReservation(entity)) {
                axios
                    .post(
                        `/api/restaurants/${this.restaurant_id}/services/slots/${this.reservation.slot_id}/groups/reserved/new_resa/${
                            entity.id
                        }?date=${this.reservation.reservation_date.toISODate()}&duration=${this.reservation.duration.HH}:${
                            this.reservation.duration.mm
                        }&pax=${this.nb_pers_total}`
                    )
                    .then(() => {
                        //ok socket event should be fired and will update front
                    })
                    .catch(() => {
                        this.notifyError(null, this.$tl("errors.booking.seatingPlan.group.notAvailable"));

                        const index = this.vueMultiSelect.entitiesSelected.findIndex((e) => e.type === "group" && e.id === entity.id);

                        if (index > -1) {
                            this.vueMultiSelect.entitiesSelected.splice(index, 1);
                        }
                    });
            }
        },
        reserveTable(entity) {
            if (!this.isTableFromBaseReservation(entity)) {
                axios
                    .post(
                        `/api/restaurants/${this.restaurant_id}/services/slots/${this.reservation.slot_id}/tables/reserved/new_resa/${
                            entity.id
                        }?date=${this.reservation.reservation_date.toISODate()}&duration=${this.reservation.duration.HH}:${
                            this.reservation.duration.mm
                        }&pax=${this.nb_pers_total}`
                    )
                    .then(() => {
                        //ok socket event should be fired and will update front
                    })
                    .catch(() => {
                        this.notifyError(null, this.$tl("errors.booking.seatingPlan.table.notAvailable"));

                        const index = this.vueMultiSelect.entitiesSelected.findIndex((e) => e.type === "table" && e.id === entity.id);

                        if (index > -1) {
                            this.vueMultiSelect.entitiesSelected.splice(index, 1);
                        }
                    });
            }
        },
        getSlotHourStart(slot) {
            return this.reservation.reservation_date.set({
                hour: slot.hour_start.split(":")[0],
                minute: slot.hour_start.split(":")[1],
            });
        },
        getGroupFormattedForMultiSelect(group, isDisabled, slot) {
            if (group.enabled) {
                if (isDisabled === false) {
                    let tablesIds = this.vueMultiSelect.entitiesSelected.filter((e) => e.type === "table").map((t) => t.id);

                    const groupsSelected = this.vueMultiSelect.entitiesSelected.filter((e) => e.type === "group");

                    const groupsIds = groupsSelected.map((g) => g.id);

                    if (!groupsIds.includes(group.id)) {
                        groupsSelected.forEach((g) => {
                            tablesIds = tablesIds.concat(g.tables.map((t) => t.id));
                        });

                        tablesIds = _.uniq(tablesIds);
                        for (var t of group.tables) {
                            if (tablesIds.includes(t.id)) {
                                isDisabled = true;
                                break;
                            }
                        }
                    }
                }

                if (isDisabled === false && this.vueMultiSelect.paxFull) {
                    const ids = this.vueMultiSelect.entitiesSelected.filter((e) => e.type === "group").map((e) => e.id);

                    isDisabled = !ids.includes(group.id);
                }

                const tablesIds = group.tables.map((t) => t.id);
                const found = this.vueMultiSelect.entitiesSelected.find((e) => {
                    switch (e.type) {
                        case "table":
                            return tablesIds.includes(e.id);
                            break;
                        case "group":
                            return e.id === group.id;
                            break;
                    }
                });

                if (
                    !found &&
                    group.tables.filter((t) => {
                        const tableSlot = t.slots.find((s) => s.id == slot.id);
                        return (
                            (!tableSlot.entityEnabled || !this.isTableAvailableOnFutureSlots(t)) &&
                            (!this.baseReservation || !this.baseReservation.tables.data.find((rt) => rt.id == t.id))
                        );
                    }).length > 0
                ) {
                    return null;
                }

                return {
                    ...group,
                    type: "group",
                    $isDisabled: isDisabled,
                };
            }

            return null;
        },
        checkVueMultiSelectDisabledTables() {
            this.vueMultiSelect.isInvalid = this.vueMultiSelect.entitiesSelected.find((e) => e.$isDisabled) ? true : false;
        },
        checkVueMultiSelectPaxFull() {
            var paxUsed = 0;
            this.vueMultiSelect.entitiesSelected.forEach((entity) => {
                paxUsed += entity.enabledPax.length > 0 ? Math.max.apply(null, entity.enabledPax) : entity.seats;
            });

            this.$set(this.vueMultiSelect, "paxFull", paxUsed >= this.nb_pers_total);
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
        registerServiceSockets() {
            if (this.reservation.service && this.reservation.reservation_date && this.restaurant_id) {
                const channelName = `App.restaurant.${this.restaurant_id}.service.${
                    this.reservation.service.id
                }.date.${this.reservation.reservation_date.toISODate()}`;

                Echo.private(channelName).listen(".table.updated", this.onTableUpdate);

                this.socket.listeningChannels.services.push(channelName);
                this.$store.commit("sockets/addChannel", channelName);
            }
        },
        unregisterServiceSockets() {
            this.socket.listeningChannels.services.forEach((channelName) => {
                Echo.private(channelName).stopListening(".table.updated");
            });

            this.socket.listeningChannels.services = [];
        },
        onTableUpdate(event) {
            const table = event.table;
            const slotsIdsToDisable = table.reservations_pivot.data
                .map((rp) => {
                    return rp.all_slots_used.data.map((s) => s.id);
                })
                .flat()
                .filter((value, index, self) => self.indexOf(value) === index);

            if (this.seatingPlan) {
                const roomFound = this.seatingPlan.rooms.find((r) => r.id === table.room.id);

                if (roomFound) {
                    const tableFound = roomFound.entities.find((e) => e.type === "table" && e.id === table.id);

                    if (tableFound) {
                        tableFound.slots.forEach((s) => {
                            if (slotsIdsToDisable.includes(s.id)) {
                                s.entityEnabled = false;
                            } else {
                                s.entityEnabled = true;
                            }
                        });
                    }

                    const groupsFound = roomFound.entities.filter((e) => {
                        return e.type === "group" && e.tables.find((t) => t.id === table.id);
                    });

                    groupsFound.forEach((group) => {
                        const groupTableFound = group.tables.find((t) => t.id === table.id);

                        if (groupTableFound) {
                            groupTableFound.slots.forEach((s) => {
                                if (slotsIdsToDisable.includes(s.id)) {
                                    s.entityEnabled = false;
                                } else {
                                    s.entityEnabled = true;
                                }
                            });
                        }

                        group.slots.forEach((s, slotIndex) => {
                            if (group.tables.find((t) => !t.slots[slotIndex].entityEnabled)) {
                                s.entityEnabled = false;
                            } else {
                                s.entityEnabled = true;
                            }
                        });
                    });
                }
            }
        },
        unselectAllTables() {
            this.vueMultiSelect.entitiesSelected.forEach((entity) => {
                this.tableUnselected(entity, false);
            });
        },
        checkSeatingPlan() {
            this.errors.seatingPlan = null;

            if (this.checksAbortController.seatingPlan) {
                this.checksAbortController.seatingPlan.abort();
                this.checksAbortController.seatingPlan = null;
            }

            let url = `/api/restaurants/${this.restaurant_id}/reservations/checkseatingplan?total=${
                this.nb_pers_total
            }&date=${this.reservation.reservation_date.toISODate()}&slot_id=${this.reservation.slot_id}&duration=${this.reservation.duration.HH}:${
                this.reservation.duration.mm
            }`;

            if (this.baseReservation) {
                url += `&reservation_id=${this.baseReservation.id}`;
            }

            if (this.reservation.service_id) {
                url += `&service_id=${this.reservation.service_id}`;
            }

            this.checksAbortController.seatingPlan = new AbortController();
            this.loading.can_place = true;
            this.can_place = false;
            this.$emit("enable-next-button", false);

            axios
                .get(url, {
                    signal: this.checksAbortController.seatingPlan.signal,
                })
                .then(() => {
                    this.$emit("enable-next-button", true);
                    this.can_place = true;
                    this.errors.seatingPlan = null;
                })
                .catch((error) => {
                    if (error instanceof axios.AxiosError && error.code === axios.AxiosError.ERR_CANCELED) {
                        return;
                    }

                    this.$emit("enable-next-button", false);
                    this.can_place = false;
                })
                .finally(() => (this.loading.can_place = false));
        },
        checkMaxPax() {
            if (this.checksAbortController.pax) {
                this.checksAbortController.pax.abort();
                this.checksAbortController.pax = null;
            }

            if (this.nb_pers_total !== 0 && this.reservation.reservation_date && this.reservation.slot_id) {
                let url = `/api/restaurants/${this.restaurant_id}/reservations/checkmaxpax?total=${
                    this.nb_pers_total
                }&date=${this.reservation.reservation_date.toISODate()}&slot_id=${this.reservation.slot_id}&duration=${
                    this.reservation.duration.HH
                }:${this.reservation.duration.mm}`;

                if (this.baseReservation) {
                    url += `&reservation_id=${this.baseReservation.id}`;
                }

                if (this.reservation.service_id) {
                    url += `&service_id=${this.reservation.service_id}`;
                }

                this.checksAbortController.pax = new AbortController();

                axios
                    .get(url, {
                        signal: this.checksAbortController.pax.signal,
                    })
                    .then(() => {
                        this.formErrors = null;
                    })
                    .catch((error) => {
                        if (error instanceof axios.AxiosError && error.code === axios.AxiosError.ERR_CANCELED) {
                            return;
                        }

                        const message = this.$tl("infos.booking.reservations.saveCauseSurbooking", this.restaurant_id);
                        if (this.formErrors == null) {
                            this.formErrors = {};
                        }

                        if (this.formErrors.max_pax !== message) {
                            this.formErrors.max_pax = message;
                        }
                    });
            }
        },
        setDuration() {
            if (this.reservation.is_duration_customized === false) {
                let foundDuration = false;

                if (this.reservation.service) {
                    this.reservation.service.paxDurations.data.forEach((item) => {
                        if (item.pax === this.nb_pers_total) {
                            const durationsArray = item.duration.split(":");

                            this.$set(this.reservation, "duration", {
                                HH: `0${durationsArray[0]}`.substr(-2),
                                mm: `0${durationsArray[1]}`.substr(-2),
                            });
                            foundDuration = true;
                        }
                    });

                    if (!foundDuration) {
                        const durationsArray = this.reservation.service.duration.split(":");

                        this.$set(this.reservation, "duration", {
                            HH: `0${durationsArray[0]}`.substr(-2),
                            mm: `0${durationsArray[1]}`.substr(-2),
                        });
                    }
                }
            }
        },
    },
    watch: {
        "reservation.ignore_placement": {
            handler(newVal) {
                if (!newVal && !this.can_place) this.$emit("enable-next-button", false);
            },
        },
        "reservation.slot_id": {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    if (!this.isPassingCustomer) {
                        this.loadMenusData();
                        this.loadGeneralOptionsData();
                    }

                    if (this.$_.isEmpty(this.vueMultiSelect.entitiesSelected) && this.nb_pers_total > 0 && this.tablesIdToPreselect.length > 0) {
                        this.setMultiSelectSelectedEntities(
                            this.tablesMultiselectOptions
                                .map((room) => {
                                    return room.entities.filter((e) => {
                                        return e.type === "table" && this.tablesIdToPreselect.includes(e.id);
                                    });
                                })
                                .flat()
                                .filter((t) => t)
                        );
                    }

                    this.checkSeatingPlan();
                    this.checkMaxPax();
                }
            },
        },
        "reservation.reservation_date": {
            handler() {
                this.setMultiSelectSelectedEntities([]);
                this.unregisterServiceSockets();
                this.registerServiceSockets();
            },
        },
        "reservation.service": {
            handler(newVal) {
                this.unregisterServiceSockets();
                this.$set(this.reservation, "choosen_menus", {});
                this.$set(this.reservation, "choosen_general_options", {});

                if (this.is_duration_customized === false && newVal) {
                    this.reservation.service.paxDurations.data.forEach((item) => {
                        if (item.pax === this.nb_pers_total) {
                            const durationsArray = item.duration.split(":");

                            this.$set(this.reservation, "duration", {
                                HH: `0${durationsArray[0]}`.substr(-2),
                                mm: `0${durationsArray[1]}`.substr(-2),
                            });
                        }
                    });
                }

                if (newVal) {
                    this.registerServiceSockets();
                }

                this.setMultiSelectSelectedEntities([]);
            },
        },
        "reservation.duration": function () {
            this.checkSeatingPlan();
            this.checkMaxPax();
        },
        "reservation.nb_pers": function () {
            this.checkSeatingPlan();
            this.checkMaxPax();
            this.checkVueMultiSelectPaxFull();
            this.setDuration();
        },
        "vueMultiSelect.entitiesSelected": {
            handler(newVal) {
                this.$emit("enable-next-button", newVal.length > 0 || this.can_place);
                this.checkVueMultiSelectPaxFull();
                this.checkVueMultiSelectDisabledTables();
            },
        },
    },
    components: {
        LoaderComponent,
        VueMultiselect,
        menusReservation,
        generalOptionsReservation,
        VueTimepicker,
    },
    created() {
        this.checkVueMultiSelectPaxFull();
        this.unregisterServiceSockets();
        this.registerServiceSockets();
        this.setDuration();
    },
    beforeDestroy() {
        this.unregisterServiceSockets();
        this.unselectAllTables();
    },
};
</script>

<style></style>
