var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mb-3" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb- pt-2 mt-1",
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-success btn-circle",
                    on: {
                      click: function ($event) {
                        _vm.showTuto = !_vm.showTuto
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                            Lire les informations d'intégration\n                        "
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-2 mb-3" }, [
          _vm.showTuto
            ? _c("div", { staticClass: "col-12 border-light p-4" }, [_vm._m(1)])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-1 mb-2" }, [
          _c("div", { staticClass: "col-md-5" }, [_vm._v("Clé publique")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sendcloud_public_key_,
                  expression: "sendcloud_public_key_",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.sendcloud_public_key_ },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.sendcloud_public_key_ = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-2" }, [
          _c("div", { staticClass: "col-md-5" }, [
            _vm._v("Clé confidentielle"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sendcloud_private_key_,
                  expression: "sendcloud_private_key_",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.sendcloud_private_key_ },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.sendcloud_private_key_ = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-2" }, [
          _c("div", { staticClass: "col-md-5" }, [_vm._v("Poids fixe")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.base_weight_,
                  expression: "base_weight_",
                },
              ],
              staticClass: "form-control w-auto d-inline-block",
              attrs: { type: "number" },
              domProps: { value: _vm.base_weight_ },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.base_weight_ = $event.target.value
                },
              },
            }),
            _c("span", { staticClass: "ml-2" }, [_vm._v("gramme(s)")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-2" }, [
          _c("div", { staticClass: "col-md-5" }, [
            _vm._v("Montant minimum de commande"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.dispatch_min_amount_,
                  expression: "dispatch_min_amount_",
                },
              ],
              staticClass: "form-control w-auto d-inline-block",
              attrs: { type: "number" },
              domProps: { value: _vm.dispatch_min_amount_ },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.dispatch_min_amount_ = $event.target.value
                },
              },
            }),
            _c("span", { staticClass: "ml-2" }, [_vm._v("€")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-2" }, [
          _c("div", { staticClass: "col-md-5" }, [
            _vm._v("Expédition gratuite à partir de"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.dispatch_free_above_,
                  expression: "dispatch_free_above_",
                },
              ],
              staticClass: "form-control w-auto d-inline-block",
              attrs: { type: "number" },
              domProps: { value: _vm.dispatch_free_above_ },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.dispatch_free_above_ = $event.target.value
                },
              },
            }),
            _c("span", { staticClass: "ml-2" }, [_vm._v("€")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-2" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7 pt-1" }, [
            _c(
              "div",
              { staticClass: "d-inline-block align-middle lead-switch" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.enable_service_points_,
                      expression: "enable_service_points_",
                    },
                  ],
                  staticClass: "switch align-self-center is-rounded",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.enable_service_points_)
                      ? _vm._i(_vm.enable_service_points_, null) > -1
                      : _vm.enable_service_points_,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.enable_service_points_,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.enable_service_points_ = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.enable_service_points_ = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.enable_service_points_ = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", {
                  on: {
                    click: function ($event) {
                      _vm.enable_service_points_ = !_vm.enable_service_points_
                    },
                  },
                }),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("strong", [
        _c(
          "a",
          {
            staticClass: "text-dark",
            attrs: { href: "https://www.sendcloud.fr/", target: "_blank" },
          },
          [_vm._v("Sendcloud")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", { staticClass: "list-style-inherit m-0" }, [
      _c("li", [
        _vm._v(
          "\n                            Pour commencer, créez vous un compte sur\n                            "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://panel.sendcloud.sc/accounts/signup/",
              target: "_blank",
            },
          },
          [_c("strong", [_vm._v("Sendcloud")])]
        ),
        _vm._v(".\n                        "),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          '\n                            Une fois votre compte créé, vous devez créer une nouvelle boutique en vous rendant dans\n                            "Réglages" > "Boutiques connectées" > "Sendcloud API" > "Se connecter", ou depuis\n                            '
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://panel.sendcloud.sc/#/settings/integrations/api/add",
              target: "_blank",
            },
          },
          [_c("strong", [_vm._v("ce lien")])]
        ),
        _vm._v("\n                            :\n                            "),
        _c("ul", { staticClass: "list-style-inherit m-0" }, [
          _c("li", [_vm._v("Saisissez le nom de votre boutique")]),
          _vm._v(" "),
          _c("li", [_vm._v('Cochez la case "Feedback des Webhooks activé"')]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              '\n                                    Dans le champ "Webhook url", entrez cette adresse :\n                                    https://app.noshow.io/api/click_and_collect/sendcloud-webhook\n                                '
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              '\n                                    Cochez la case "Points Relais" (Carriers en anglais) si vous le désirez (vous\n                                    pourrez ajouter d\'autres transporteurs ultérieurement)\n                                '
            ),
          ]),
          _vm._v(" "),
          _c("li", [_vm._v('Cliquez sur "Enregistrer"')]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n                            Une fois votre boutique créée, deux clés constituées de chiffres et de lettres\n                            apparaissent à votre écran. Reportez-les dans les champs ci-dessous.\n                        "
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n                            Afin de pouvoir générer des étiquettes : .\n                            "
        ),
        _c("ul", { staticClass: "list-style-inherit m-0" }, [
          _c("li", [
            _vm._v(
              '\n                                    Vous devez ajouter l\'adresse d\'expédition des colis en vous rendant dans\n                                    "Réglages" > "Mes adresses" > "Adresse de l\'expéditeur" > "Ajouter", ou depuis\n                                    '
            ),
            _c(
              "a",
              {
                attrs: {
                  href: "https://panel.sendcloud.sc/#/settings/addresses/sender/add",
                  target: "_blank",
                },
              },
              [_c("strong", [_vm._v("ce lien")])]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "\n                                    Vous devez activer le prélèvement automatique\n                                    "
            ),
            _c(
              "a",
              {
                attrs: {
                  href: "https://panel.sendcloud.sc/#/settings/financial/payments/direct-debit",
                  target: "_blank",
                },
              },
              [_c("strong", [_vm._v("depuis ce lien.")])]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          '\n                            Optionnellement, vous pouvez activer des transporteurs supplémentaires depuis "Réglages"\n                            > "Transporteurs & Prix". Attention, quand un transporteur est activé, si vous avez\n                            activé les points relais, vous devez aussi cocher la case correspondante dans\n                            '
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://panel.sendcloud.sc/#/settings/integrations/manage",
              target: "_blank",
            },
          },
          [_c("strong", [_vm._v("l'édition de boutique")])]
        ),
        _vm._v(
          '\n                            ("Réglages" > "Boutiques connectées" > "Éditer").\n                        '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Activer la livraison en point relais")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }