var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user && _vm.user != "" && _vm.user.role !== "kitchen"
    ? _c("div", { staticClass: "home-vue p-0 pb-5" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body pl-3 pr-3 mb-3 pt-0" },
          [
            _vm.loadingNbCommands
              ? _c("LoaderComponent", { staticClass: "w-100" })
              : _vm.errorNbCommands
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errorNbCommands)),
                ])
              : [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "media align-items-stretch bg-grad-warning white mr-2",
                      attrs: {
                        to: {
                          name: "click_and_collect.restaurants.sales_waiting_confirmation",
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "p-3 media-middle" },
                        [
                          _c("feather", {
                            staticClass: "mt-2",
                            attrs: { type: "clock" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "media-body pt-3 pr-3 pb-3 pl-1" },
                        [
                          _c(
                            "small",
                            {
                              staticClass: "mt-2",
                              staticStyle: { display: "block" },
                            },
                            [_vm._v(" Nombre de commandes ")]
                          ),
                          _vm._v(" "),
                          _c("h6", [_vm._v("En attente")]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "media-right p-3 media-middle" },
                        [
                          _c("h4", { staticClass: "pt-2" }, [
                            _vm._v(
                              _vm._s(_vm.nb_commands_waiting_confirmation)
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "media align-items-stretch bg-grad white mr-2",
                      attrs: {
                        to: { name: "click_and_collect.restaurants.sales" },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "p-3 media-middle" },
                        [
                          _c("feather", {
                            staticClass: "mt-2",
                            attrs: { type: "calendar" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "media-body pt-3 pr-3 pb-3 pl-1" },
                        [
                          _c(
                            "small",
                            {
                              staticClass: "mt-2",
                              staticStyle: { display: "block" },
                            },
                            [_vm._v(" Nombre de commandes ")]
                          ),
                          _vm._v(" "),
                          _c("h6", [_vm._v("Aujourd'hui")]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "media-right p-3 media-middle" },
                        [
                          _c("h4", { staticClass: "pt-2" }, [
                            _vm._v(_vm._s(_vm.nb_commands_today)),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row m-0" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              !_vm.restaurant
                ? _c("LoaderComponent")
                : _c("div", [
                    _vm.error
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(_vm._s(_vm.error)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.restaurant
                      ? _c(
                          "div",
                          [
                            _c("click-and-collect-restaurant-dashboard", {
                              staticClass: "mb-3",
                              attrs: { restaurant: _vm.restaurant },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [_c("h5", { staticClass: "title mt-2" }, [_vm._v("Tableau de bord")])]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }