<template>
    <div>
        <loader-component v-if="loading" />
        <div v-show="!loading">
            <div class="mb-4 pointer">
                <span @click="$emit('clear-selected-form')"> <feather type="chevron-left" class="mr-1" />{{ $tl("labels.form.actions.back") }} </span>
            </div>
            <div v-if="formError" class="alert alert-danger">
                {{ formError }}
            </div>
            <div v-if="cardError" class="alert alert-danger">
                {{ cardError }}
            </div>
            <div ref="cardForm"></div>
            <button v-if="showButtons" @click="save" :disabled="cardError" class="btn btn-success btn-circle" :class="{ disabled: cardError }">
                {{ $tl("labels.form.actions.save") }}
            </button>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../LoaderComponent";
import LangsEnum from "../../../../mixins/enums/LangsEnum";

export default {
    name: "AddCard",
    data() {
        return {
            loading: false,
            cardError: null,
            formError: null,
            stripe: {
                api: null,
                elements: null,
                cardElement: null,
                setupIntent: null,
            },
        };
    },
    mixins: [LangsEnum],
    props: {
        showButtons: {
            default: true,
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.init();
        });
    },
    updated() {
        this.init();
    },
    created() {
        this.loading = true;
        this.getSetupIntent().then(() => {
            this.loading = false;
        });
    },
    methods: {
        init() {
            if (this.stripe.api === null) {
                this.stripe.api = Stripe(STRIPE_PUBLIC_API_KEY, {
                    locale: this.userLang,
                });

                this.stripe.elements = this.stripe.api.elements();

                this.cardElement = this.stripe.elements.create("card");
                this.cardElement.mount(this.$refs.cardForm);

                this.cardElement.addEventListener("change", (event) => {
                    var displayError = document.getElementById("card-errors");
                    if (event.error) {
                        this.cardError = event.error.message;
                    } else {
                        this.cardError = "";
                    }
                });
            }
        },
        save() {
            this.formError = null;
            this.loading = true;

            this.stripe.api
                .confirmCardSetup(this.stripe.setupIntent, {
                    payment_method: {
                        card: this.cardElement,
                    },
                })
                .then((result) => {
                    if (result.error) {
                        this.formError = result.error.message;
                        this.loading = false;
                    } else {
                        this.$store
                            .dispatch("stripe/newPaymentMethod", { id: result.setupIntent.payment_method })
                            .then((response) => {
                                this.$emit("updated");
                            })
                            .catch((error) => {
                                this.formError = this.getErrorMsgFromErrorResponse(error);
                                this.loading = false;
                            });
                    }
                });
        },
        getSetupIntent() {
            return this.$store
                .dispatch("stripe/getSetupIntent")
                .then((response) => {
                    this.stripe.setupIntent = response.data.setupIntent;
                })
                .catch((error) => {
                    this.formError = this.getErrorMsgFromErrorResponse(error);
                });
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>
