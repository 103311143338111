<template>
    <div :class="{ 'default-home': !isComponent }">
        <div class="row" :class="{ 'm-0': !isComponent }">
            <div v-if="error" class="alert alert-danger">{{ error }}</div>
            <div class="col-12" v-if="!isComponent">
                <div class="d-flex flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <button @click="$router.go(-1)" class="btn">
                        <feather type="chevron-left" class="feather-24" />
                    </button>
                    <h5 class="title mt-2">{{ $tl("labels.routes.invoices") }}</h5>
                </div>
            </div>
            <div class="col-12 mb-2" v-else>
                <strong class="pointer" @click="show = !show">{{ $tl("labels.routes.invoices") }}</strong>
            </div>
        </div>
        <div v-show="!isComponent || show">
            <LoaderComponent v-if="loading" />
            <template v-else-if="sortedInvoices.length > 0">
                <div class="row" :class="isComponent ? 'px-3' : 'm-0 px-5'" v-for="(invoice, index) in sortedInvoices" :key="index">
                    <InvoiceItem v-if="invoice.type == 'subscription'" class="col-12 w-100 mb-2" :invoice="invoice" />
                    <EmailCampaign v-else-if="invoice.type == 'campaign_email'" class="col-12 w-100 mb-2" :invoice="invoice" />
                    <SmsCampaign v-else-if="invoice.type == 'campaign_sms'" class="col-12 w-100 mb-2" :invoice="invoice" />
                    <SmsPack v-else-if="invoice.type == 'sms_pack'" class="col-12 w-100 mb-2" :invoice="invoice" />
                </div>
            </template>
            <div v-else>Cet utilisateur ne possède aucune facture</div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent";
import InvoiceItem from "../../../components/stripe/Subscriptions/InvoiceItem";
import EmailCampaign from "../../../components/stripe/Invoices/EmailCampaign";
import SmsCampaign from "../../../components/stripe/Invoices/SmsCampaign";
import SmsPack from "../../../components/stripe/Invoices/SmsPack";
import moment from "moment";

export default {
    data() {
        return {
            error: null,
            loading: 0,
            list: [],
            emailsCampaignList: [],
            smsCampaignList: [],
            smsPackList: [],
            show: false,
        };
    },
    props: {
        isComponent: {
            default: false,
        },
        user_id: {
            defaut: null,
        },
    },
    computed: {
        sortedInvoices() {
            let invoices = [];
            invoices = invoices.concat(
                Object.values(this.list).map((invoice) => {
                    return {
                        ...invoice,
                        type: "subscription",
                    };
                })
            );
            invoices = invoices.concat(
                Object.values(this.emailsCampaignList).map((invoice) => {
                    return {
                        ...invoice,
                        type: "campaign_email",
                    };
                })
            );
            invoices = invoices.concat(
                Object.values(this.smsCampaignList).map((invoice) => {
                    return {
                        ...invoice,
                        type: "campaign_sms",
                    };
                })
            );
            invoices = invoices.concat(
                Object.values(this.smsPackList).map((invoice) => {
                    return {
                        ...invoice,
                        type: "sms_pack",
                    };
                })
            );
            return invoices.sort((a, b) => {
                const momentA = moment(a.paid_at || a.due_date);
                const momentB = moment(b.paid_at || b.due_date);
                if (momentA.isBefore(momentB)) return 1;
                if (momentA.isAfter(momentB)) return -1;
                return 0;
            });
        },
    },
    methods: {
        initData() {
            let errorCatchFct = (error) => {
                this.loading--;
                let message = "Une erreur est survenue.";

                if (error.response && error.response.data.message) {
                    message = error.response.data.message;
                } else if (error.message) {
                    message = error.message;
                }

                this.error = message;
            };
            this.loading = 4;
            this.$store
                .dispatch("stripe/fetchInvoices", { owner_id: this.user_id, type: "subscription" })
                .then((response) => {
                    this.list = response.data.data;
                    this.loading--;
                })
                .catch(errorCatchFct);
            this.$store
                .dispatch("stripe/fetchInvoices", { owner_id: this.user_id, type: "campaignEmails" })
                .then((response) => {
                    this.emailsCampaignList = response.data.data;
                    this.loading--;
                })
                .catch(errorCatchFct);
            this.$store
                .dispatch("stripe/fetchInvoices", { owner_id: this.user_id, type: "campaignSms" })
                .then((response) => {
                    this.smsCampaignList = response.data.data;
                    this.loading--;
                })
                .catch(errorCatchFct);
            this.$store
                .dispatch("stripe/fetchInvoices", { owner_id: this.user_id, type: "smsPacks" })
                .then((response) => {
                    this.smsPackList = response.data.data;
                    this.loading--;
                })
                .catch(errorCatchFct);
        },
    },
    components: {
        LoaderComponent,
        InvoiceItem,
        EmailCampaign,
        SmsCampaign,
        SmsPack,
    },
    watch: {
        show(newVal, oldVal) {
            if (newVal && !oldVal && !this.loading) {
                this.initData();
            }
        },
    },
    created() {
        if (!this.isComponent) {
            this.initData();
        }
    },
};
</script>
