var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "border-light b-radius-20 p-4" },
                  [
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("div", { staticClass: "col-md-5 pt-1" }, [
                        _c("label", [
                          _vm._v("Titre de la pop-up "),
                          _c("small", [_vm._v("*")]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.new_feature.title,
                                expression: "new_feature.title",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.new_feature.title },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.new_feature,
                                  "title",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: { errors: _vm.errors, errorKey: "title" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("div", { staticClass: "col-md-5 pt-1" }, [
                        _c("label", [
                          _vm._v("Contenu "),
                          _c("small", [_vm._v("*")]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.new_feature.content,
                                expression: "new_feature.content",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { rows: "3" },
                            domProps: { value: _vm.new_feature.content },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.new_feature,
                                  "content",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: { errors: _vm.errors, errorKey: "content" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("div", { staticClass: "col-md-5 pt-1" }, [
                        _c("label", [_vm._v("Ajouter un bouton")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-inline-block align-middle lead-switch",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.new_feature.add_btn,
                                    expression: "new_feature.add_btn",
                                  },
                                ],
                                staticClass:
                                  "switch align-self-center is-rounded",
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.new_feature.add_btn
                                  )
                                    ? _vm._i(_vm.new_feature.add_btn, null) > -1
                                    : _vm.new_feature.add_btn,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.new_feature.add_btn,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.new_feature,
                                            "add_btn",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.new_feature,
                                            "add_btn",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.new_feature, "add_btn", $$c)
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", {
                                on: {
                                  click: function ($event) {
                                    _vm.new_feature.add_btn =
                                      !_vm.new_feature.add_btn
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: { errors: _vm.errors, errorKey: "add_btn" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.new_feature.add_btn
                      ? [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col-md-5 pt-1" }, [
                              _c("label", [
                                _vm._v("Titre du bouton "),
                                _c("small", [_vm._v("*")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-7" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.new_feature.btn_title,
                                      expression: "new_feature.btn_title",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.new_feature.btn_title,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.new_feature,
                                        "btn_title",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  staticClass: "d-block",
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "btn_title",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col-md-5 pt-1" }, [
                              _c("label", { staticClass: "mb-0" }, [
                                _vm._v("Lien du bouton "),
                                _c("small", [_vm._v("*")]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "d-block text-muted" },
                                [
                                  _vm._v(
                                    "Si l'id de l'établissement est requis, mettre "
                                  ),
                                  _c("code", [_vm._v("{restaurant_id}")]),
                                  _vm._v(" à la place"),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-7" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.new_feature.btn_link,
                                      expression: "new_feature.btn_link",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.new_feature.btn_link },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.new_feature,
                                        "btn_link",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  staticClass: "d-block",
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "btn_link",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col-md-5 pt-1" }, [
                              _c("label", { staticClass: "mb-0" }, [
                                _vm._v("Module du lien"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "d-block text-muted" },
                                [
                                  _vm._v(
                                    "Pour rediriger sur le bon établissement"
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-7" },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.new_feature.btn_link_module,
                                        expression:
                                          "new_feature.btn_link_module",
                                      },
                                    ],
                                    staticClass: "custom-select",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.new_feature,
                                          "btn_link_module",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { domProps: { value: null } },
                                      [_vm._v("--")]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.ALL_MODULES_TYPES,
                                      function (module) {
                                        return _c(
                                          "option",
                                          {
                                            key: module.value,
                                            domProps: { value: module.value },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(module.label) +
                                                "\n                                    "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  staticClass: "d-block",
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "btn_link_module",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("div", { staticClass: "col-md-5 pt-1" }, [
                        _c("label", [_vm._v("Afficher aux owners uniquement")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-inline-block align-middle lead-switch",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.new_feature.show_to_owners_only,
                                    expression:
                                      "new_feature.show_to_owners_only",
                                  },
                                ],
                                staticClass:
                                  "switch align-self-center is-rounded",
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.new_feature.show_to_owners_only
                                  )
                                    ? _vm._i(
                                        _vm.new_feature.show_to_owners_only,
                                        null
                                      ) > -1
                                    : _vm.new_feature.show_to_owners_only,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.new_feature.show_to_owners_only,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.new_feature,
                                            "show_to_owners_only",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.new_feature,
                                            "show_to_owners_only",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.new_feature,
                                        "show_to_owners_only",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", {
                                on: {
                                  click: function ($event) {
                                    _vm.new_feature.show_to_owners_only =
                                      !_vm.new_feature.show_to_owners_only
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "show_to_owners_only",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mt-3" }, [
                      _c("div", { staticClass: "col-md-4 pt-1" }, [
                        _vm._v("Clients qui ont une licence"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-8" },
                        [
                          _vm._l(
                            _vm.ALL_MODULES_TYPES_WITH_OPTIONS,
                            function (module) {
                              return _c(
                                "label",
                                {
                                  key: module.value,
                                  staticClass: "container-box",
                                  staticStyle: { width: "auto !important" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.new_feature.has_modules,
                                        expression: "new_feature.has_modules",
                                      },
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      value: module.value,
                                      checked: Array.isArray(
                                        _vm.new_feature.has_modules
                                      )
                                        ? _vm._i(
                                            _vm.new_feature.has_modules,
                                            module.value
                                          ) > -1
                                        : _vm.new_feature.has_modules,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.new_feature.has_modules,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = module.value,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.new_feature,
                                                "has_modules",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.new_feature,
                                                "has_modules",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.new_feature,
                                            "has_modules",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(module.label) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }
                          ),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "has_modules",
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "has_modules.*",
                            },
                          }),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mt-3" }, [
                      _c("div", { staticClass: "col-md-4 pt-1" }, [
                        _vm._v("Clients qui n'ont pas de licence"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-8" },
                        [
                          _vm._l(
                            _vm.ALL_MODULES_TYPES_WITH_OPTIONS,
                            function (module) {
                              return _c(
                                "label",
                                {
                                  key: module.value,
                                  staticClass: "container-box",
                                  staticStyle: { width: "auto !important" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.new_feature.doesnt_have_modules,
                                        expression:
                                          "new_feature.doesnt_have_modules",
                                      },
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      value: module.value,
                                      checked: Array.isArray(
                                        _vm.new_feature.doesnt_have_modules
                                      )
                                        ? _vm._i(
                                            _vm.new_feature.doesnt_have_modules,
                                            module.value
                                          ) > -1
                                        : _vm.new_feature.doesnt_have_modules,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.new_feature.doesnt_have_modules,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = module.value,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.new_feature,
                                                "doesnt_have_modules",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.new_feature,
                                                "doesnt_have_modules",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.new_feature,
                                            "doesnt_have_modules",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(module.label) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }
                          ),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "doesnt_have_modules",
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "doesnt_have_modules.*",
                            },
                          }),
                        ],
                        2
                      ),
                    ]),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }