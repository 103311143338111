export default {
    data() {
        return {
            TABLE_SIZE_SMALL: {
                value: "s",
            },
            TABLE_SIZE_MEDIUM: {
                value: "m",
            },
            TABLE_SIZE_LARGE: {
                value: "l",
            },
            TABLE_SIZE_EXTRA_LARGE: {
                value: "xl",
            },
        };
    },
    methods: {
        /** mode: 'higher'|'smaller' */
        getNextTableSize(currentSize, mode = "higher") {
            const currentSizeScore = this.ALL_TABLE_SIZES.findIndex((size) => size.value === currentSize);

            if (currentSizeScore === -1) {
                return currentSize;
            }

            if (mode === "higher" && currentSizeScore < this.ALL_TABLE_SIZES.length - 1) {
                return this.ALL_TABLE_SIZES[currentSizeScore + 1].value;
            }

            if (mode === "smaller" && currentSizeScore > 0) {
                return this.ALL_TABLE_SIZES[currentSizeScore - 1].value;
            }

            return currentSize;
        },
    },
    computed: {
        ALL_TABLE_SIZES() {
            return [this.TABLE_SIZE_SMALL, this.TABLE_SIZE_MEDIUM, this.TABLE_SIZE_LARGE, this.TABLE_SIZE_EXTRA_LARGE];
        },
    },
};
