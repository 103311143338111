var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "toolbar",
        {
          attrs: {
            "toolbar-settings": _vm.toolbarSettings,
            "data-source": _vm.filteredDataSource,
          },
          on: {
            "emit-event": function ($event) {
              return _vm.$emit($event.eventName, $event.data)
            },
          },
        },
        [_vm._t("toolbar")],
        2
      ),
      _vm._v(" "),
      _c(
        "table",
        { ref: `ns-table-${_vm.refSuffix}`, class: _vm.tableClass },
        [
          !_vm.useSlotHeadings
            ? _c("thead", [_vm._t("head")], 2)
            : _vm._t("head"),
          _vm._v(" "),
          !_vm.useSlotBody
            ? _c(
                "tbody",
                _vm._l(_vm.filteredDataSource, function (data, index) {
                  return _c(
                    "tr",
                    {
                      key: _vm.filteredDataSource[_vm.keyColumn] || index,
                      class: {
                        pointer: _vm.clickable,
                        clickable: _vm.clickable,
                        new: data.new,
                        updated: data.updated,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onRowSelected({ event: $event, data })
                        },
                      },
                    },
                    [_vm._t("body", null, null, { data })],
                    2
                  )
                }),
                0
              )
            : _vm._t("body", null, null, {
                filteredDataSource: _vm.filteredDataSource,
                clickable: _vm.clickable,
                keyColumn: _vm.keyColumn,
              }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.allowPaging
        ? _c(
            "Pagination",
            _vm._b(
              {
                staticClass: "mt-2",
                on: {
                  "set-current-page": function ($event) {
                    return _vm.setAttributePage($event, "currentPage")
                  },
                  "set-nb-results-per-page": function ($event) {
                    return _vm.setAttributePage($event, "nbResultsPerPage")
                  },
                },
              },
              "Pagination",
              _vm.getPaginationSettings,
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }