<template>
    <div>
        <loader-component v-if="loadings.menuOption || loadings.form" />
        <div v-else-if="errors.fetchMenuOption !== null" class="text-danger">
            {{ errors.fetchMenuOption }}
        </div>
        <form v-show="!loadings.menuOption && !loadings.form && errors.fetchMenuOption === null" ref="form" method="post" @submit.prevent="submit">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div v-if="showOptionTypeChoice" class="row mb-2">
                            <div class="col-5 pt-2">
                                <label> {{ $tl("labels.booking.menus.options.type") }} <small>*</small> </label>
                            </div>
                            <div class="col-7 radio">
                                <radio-button-component
                                    v-for="menuOptionType in ALL_MENU_OPTION_TYPES"
                                    :key="menuOptionType.value"
                                    v-model="menuOption.option_type"
                                    :inputValue="menuOptionType.value"
                                    :disabled="menuOptionId !== null || !isEditable">
                                    {{ getMenuOptionTypeLabel(menuOptionType.value, restaurantId) }}
                                </radio-button-component>
                                <show-errors class="d-block" :errors="errors.form" errorKey="option_type" />
                            </div>
                        </div>

                        <input-component
                            v-model="menuOption.name"
                            inputName="name"
                            required
                            :disabled="!isEditable"
                            :label="$tl('labels.form.name')"
                            :form-errors="errors.form" />

                        <div class="row mb-2">
                            <div class="col-5 pt-2">
                                <label for="description">
                                    {{ $tl("labels.form.description") }}
                                </label>
                            </div>
                            <div class="col-7">
                                <textarea
                                    :disabled="!isEditable"
                                    name="description"
                                    id="description"
                                    v-model="menuOption.description"
                                    class="form-control"></textarea>
                                <show-errors class="d-block" :errors="errors.form" errorKey="description" />
                            </div>
                        </div>

                        <input-component
                            v-model="menuOption.price"
                            inputName="price"
                            inputType="number"
                            step="0.01"
                            min="0"
                            required
                            :disabled="!isEditable"
                            :label="$t('labels.booking.menus.price', { currency: getRestaurantCurrency(restaurantId) })"
                            :form-errors="errors.form" />

                        <div class="row mb-2" v-if="!isTvaDisabled">
                            <div class="col-5 pt-2">
                                <label for="tax_rate"> {{ $tl("labels.taxRate") }} <small>*</small> </label>
                            </div>
                            <div class="col-7">
                                <div class="row">
                                    <div class="col-md-4">
                                        <select
                                            class="custom-select mb-0"
                                            placeholder="Veuillez sélectionner un taux"
                                            id="tax_rate"
                                            name="tax_rate"
                                            :disabled="!isEditable"
                                            v-model="menuOption.tax_rate"
                                            required>
                                            <option :value="null">--</option>
                                            <option :value="5.5">5,5</option>
                                            <option :value="10">10</option>
                                            <option :value="20">20</option>
                                            <option :value="8.1">8.1</option>
                                        </select>
                                    </div>
                                    <div class="col-md-8 mt-1">%</div>
                                    <show-errors class="col-12" :errors="errors.form" errorKey="tax_rate" />
                                </div>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-5 pt-2">
                                <label for="quantity_type">
                                    {{ $tl("labels.booking.menus.options.quantity") }}
                                </label>
                            </div>
                            <div class="col-7">
                                <select
                                    :disabled="!isEditable"
                                    id="quantity_type"
                                    name="quantity_type"
                                    v-model="menuOption.quantity_type"
                                    required
                                    class="custom-select mb-0">
                                    <option
                                        v-for="optionQuantityType in ALL_OPTION_QUANTITY_TYPES"
                                        :key="optionQuantityType.value"
                                        :value="optionQuantityType.value">
                                        {{ getOptionQuantityTypeLabel(optionQuantityType.value, restaurantId) }}
                                    </option>
                                </select>
                                <ShowErrors class="d-block" :errors="errors.form" errorKey="quantity_type" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-5">{{ $tl("labels.form.picture") }}</div>
                            <div class="col-7">
                                <image-uploader
                                    :uploaded-img-url="img.uploadedImgUrl"
                                    :uploaded-img-path="img.uploadedImgPath"
                                    :delete-url="deleteImageURL"
                                    :maxSize="2"
                                    :disabled="!isEditable"
                                    @image-selected="img.file = $event"
                                    @image-deleted="onImageDeleted" />
                                <show-errors class="d-block" :errors="errors.form" errorKey="img_file" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="row mb-3">
                            <div class="col-12">
                                <strong>{{ $tl("labels.booking.menus.options.enabledSettings") }}</strong>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-5">
                                <label class="container-box" style="width: 30px">
                                    <input
                                        :disabled="!isEditable"
                                        type="checkbox"
                                        id="enable_min_pers"
                                        name="enable_min_pers"
                                        v-model="menuOption.enable_min_pers" />
                                    <span style="margin-top: -2px" class="checkmark"></span>
                                </label>
                                <label class="pt-2" for="enable_min_pers">
                                    {{ $tl("labels.booking.menus.options.enabledFrom") }}
                                </label>
                            </div>
                            <div class="col-7">
                                <div class="d-flex align-items-center">
                                    <input
                                        style="width: 30%"
                                        type="number"
                                        step="1"
                                        min="0"
                                        name="nb_pers_min"
                                        :disabled="!menuOption.enable_min_pers || !isEditable"
                                        v-model.number="menuOption.nb_pers_min"
                                        id="nb_pers_min"
                                        class="form-control" />
                                    <p class="m-0 p-0 ml-2">{{ $tl("labels.persons") }}</p>
                                </div>
                                <show-errors class="d-block" :errors="errors.form" errorKey="enable_min_pers" />
                                <show-errors class="d-block" :errors="errors.form" errorKey="nb_pers_min" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5">
                                <label class="container-box" style="width: 30px">
                                    <input
                                        :disabled="!has_right_to_update_menu"
                                        type="checkbox"
                                        id="enable_max_pers"
                                        v-model="menuOption.enable_max_pers" />
                                    <span style="margin-top: -2px" class="checkmark"></span>
                                </label>
                                <label class="pt-2" for="enable_max_pers">
                                    {{ $tl("labels.booking.menus.options.disabledFrom") }}
                                </label>
                            </div>
                            <div class="col-7">
                                <div class="d-flex align-items-center">
                                    <input
                                        style="width: 30%"
                                        type="number"
                                        step="1"
                                        min="0"
                                        name="nb_pers_max"
                                        :disabled="!menuOption.enable_max_pers || !isEditable"
                                        v-model.number="menuOption.nb_pers_max"
                                        id="nb_pers_max"
                                        class="form-control" />
                                    <p class="m-0 p-0 ml-2">{{ $tl("labels.persons") }}</p>
                                </div>
                                <ShowErrors class="d-block" :errors="errors.form" errorKey="enable_max_pers" />
                                <ShowErrors class="d-block" :errors="errors.form" errorKey="nb_pers_max" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3" v-if="showSaveButton">
                <div class="col-12">
                    <input
                        type="submit"
                        name="submit"
                        :disabled="!isEditable"
                        :value="$tl('labels.form.actions.save')"
                        class="btn btn-success btn-circle" />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import InputComponent from "../forms/InputComponent.vue";
import RadioButtonComponent from "../forms/RadioButtonComponent.vue";
import ShowErrors from "../errors/ShowErrors";
import OptionQuantityTypesEnum from "../../mixins/enums/booking/OptionQuantityTypesEnum.js";
import MenuOptionTypeEnum from "../../mixins/enums/booking/MenuOptionTypeEnum.js";
import ImageUploader from "../forms/uploaders/Image.vue";

export default {
    data() {
        return {
            loadings: {
                menuOption: false,
                form: false,
            },
            errors: {
                fetchMenuOption: null,
                form: null,
            },
            menuOption: {
                id: null,
                name: "",
                option_type: null,
                price: null,
                quantity_type: null,
                enable_min_pers: false,
                nb_pers_min: null,
                enable_max_pers: false,
                nb_pers_max: null,
                tax_rate: null,
                description: null,
            },
            img: {
                uploadedImgUrl: null,
                uploadedImgPath: null,
                file: null,
            },
        };
    },
    mixins: [MenuOptionTypeEnum, OptionQuantityTypesEnum],
    props: {
        restaurantId: {
            type: Number,
            required: true,
        },
        optionType: {
            type: String,
            required: true,
        },
        menuOptionId: {
            type: Number,
            default: null,
        },
        showSaveButton: {
            type: Boolean,
            default: false,
        },
        showOptionTypeChoice: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        widget() {
            return this.$store.getters["widgets/getWidget"];
        },
        isTvaDisabled() {
            return this.$store.getters["restaurants/isTvaDisabled"](this.restaurantId);
        },
        isEditable() {
            return this.menuOptionId === null ? this.has_right_to_create_menu : this.has_right_to_update_menu;
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_menu() {
            return this.rights.includes("booking.menu.create");
        },
        has_right_to_update_menu() {
            return this.rights.includes("booking.menu.update");
        },
        apiEndpoint() {
            switch (this.menuOption.option_type) {
                case this.MENU_OPTION_MENU.value:
                    return `/api/restaurants/${this.restaurantId}/menus/options`;
                case this.MENU_OPTION_GENERAL.value:
                    return `/api/restaurants/${this.restaurantId}/general/options`;
                default:
                    throw `Invalid option type "${this.menuOption.option_type}"`;
            }
        },
        translationKey() {
            switch (this.menuOption.option_type) {
                case this.MENU_OPTION_MENU.value:
                    return "menus.options";
                case this.MENU_OPTION_GENERAL.value:
                    return "generalOptions";
                default:
                    return "";
            }
        },
        deleteImageURL() {
            switch (this.menuOption.option_type) {
                case this.MENU_OPTION_MENU.value:
                    return `/api/restaurants/${this.restaurantId}/menus/options/${this.menuOptionId}/img`;
                case this.MENU_OPTION_GENERAL.value:
                    return `/api/restaurants/${this.restaurantId}/general/options/${this.menuOptionId}/img`;
                default:
                    throw `Invalid option type "${this.menuOption.option_type}"`;
            }
        },
    },
    methods: {
        fetchMenuOption() {
            this.errors.fetchMenuOption = null;

            if (this.menuOptionId === null) {
                this.$emit("fully-loaded", { menuOption: this.menuOption });
                return;
            }

            this.loadings.menuOption = true;

            this.httpGet(`${this.apiEndpoint}/${this.menuOptionId}`, { mustReject: true })
                .then((response) => {
                    this.menuOption = response.data;

                    if (response.data.img !== null) {
                        this.img.uploadedImgPath = response.data.img.path;
                        this.img.uploadedImgUrl = response.data.img.public_uri;
                    }

                    this.$emit("fully-loaded", { menuOption: this.menuOption });
                })
                .catch((error) => (this.errors.fetchMenuOption = this.getErrorMsgFromErrorResponse(error)))
                .finally(() => (this.loadings.menuOption = false));
        },
        submit() {
            if (!this.isEditable) {
                return null;
            }

            this.loadings.form = true;

            let promise = null;
            let message = null;
            const formData = this.getFormData();

            if (this.menuOptionId !== null) {
                message = this.$tl(`success.booking.${this.translationKey}.edited`);
                promise = this.httpPut(`${this.apiEndpoint}/${this.menuOptionId}`, formData, {
                    handleResolve: false,
                });
            } else {
                message = this.$tl(`success.booking.${this.translationKey}.added`);
                promise = this.httpPost(this.apiEndpoint, formData, { handleResolve: false });
            }

            promise
                .then((response) => {
                    if (response !== false) {
                        this.notifySuccess(response, message);
                    }

                    return response;
                })
                .finally(() => (this.loadings.form = false));

            this.$emit("submit", { promise });
            return promise;
        },
        getFormData() {
            const formData = new FormData();

            formData.append("payload", JSON.stringify(this.menuOption));

            if (this.img.file !== null) {
                formData.append("img_file", this.img.file);
            }

            return formData;
        },
        onImageDeleted() {
            this.img.uploadedImgPath = null;
            this.img.uploadedImgUrl = null;
        },
    },
    created() {
        this.menuOption.quantity_type = this.OPTION_QUANTITY_TYPE_UNLIMITED.value;
        this.menuOption.option_type = this.inEnum(this.optionType, this.ALL_MENU_OPTION_TYPES) ? this.optionType : this.MENU_OPTION_MENU.value;

        this.fetchMenuOption();
    },
    watch: {
        isEditable: {
            immediate: true,
            handler(newVal) {
                this.$emit("is-editable", newVal);
            },
        },
        restaurantId() {
            this.fetchMenuOption();
        },
        menuOptionId() {
            this.fetchMenuOption();
        },
    },
    components: {
        LoaderComponent,
        InputComponent,
        RadioButtonComponent,
        ShowErrors,
        ImageUploader,
    },
};
</script>
