var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        ref: "draggableBtn",
        staticClass: "seating-plan-memos-btn",
        class: {
          show: _vm.showMemos && _vm.memos.length === 0,
          "show-memos": _vm.showMemos && _vm.memos.length > 0,
        },
      },
      [
        _c(
          "button",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.getTooltip(
                  _vm.$t("labels.booking.services.memos.titlePlural")
                ),
                expression:
                  "getTooltip($t('labels.booking.services.memos.titlePlural'))",
              },
            ],
            staticClass: "btn btn-success",
            on: { click: _vm.toggleShowMemos },
          },
          [
            _c("feather", {
              attrs: { type: _vm.showMemos ? "chevron-right" : "chevron-left" },
            }),
            _vm._v(" "),
            _c("feather", { attrs: { type: "message-square" } }),
            _vm._v(" "),
            _vm.memos.length > 0
              ? _c("feather", {
                  staticClass: "text-light ml-1",
                  attrs: { type: "info" },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("feather", {
              staticClass: "btn-move",
              attrs: { type: "move" },
              on: {
                mousedown: function ($event) {
                  $event.preventDefault()
                  return _vm.onMouseDown.apply(null, arguments)
                },
                touchstart: function ($event) {
                  $event.preventDefault()
                  return _vm.onTouchStart.apply(null, arguments)
                },
                touchmove: function ($event) {
                  $event.preventDefault()
                  return _vm.onTouchMove.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "draggableContainer",
        staticClass:
          "seating-plan-memos-container bg-main-color-light text-white p-1",
        class: {
          show: _vm.showMemos,
          "show-memos": _vm.showMemos && _vm.memos.length > 0,
        },
      },
      [
        _vm._l(_vm.memos, function (memo, index) {
          return _c(
            "ShowMemo",
            _vm._b(
              {
                key: memo.id,
                on: {
                  added: function ($event) {
                    return _vm.memoAdded(index, $event)
                  },
                  deleted: function ($event) {
                    return _vm.memoDeleted(index)
                  },
                },
              },
              "ShowMemo",
              {
                memo,
                serviceId: _vm.serviceId,
                restaurantId: _vm.restaurantId,
                date: _vm.date,
              },
              false
            )
          )
        }),
        _vm._v(" "),
        _c("feather", {
          staticClass: "add-new-memo float-right mr-2",
          class: { "mt-1": _vm.memos.length === 0 },
          attrs: { type: "plus" },
          on: { click: _vm.addNewMemo },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }