<template>
    <div class="border-light b-radius-20 px-4 pt-3 pb-2 text-muted">
        <div class="text-center mb-4">{{ $tl("labels.clients.caption.details") }}</div>
        <div class="d-flex">
            <div>
                {{ $tl("labels.clients.caption.loyalties") }}
                <ul class="pl-0 mt-3">
                    <li v-for="clientLoyaltyLevel in ALL_CLIENT_LOYALTIES" :key="clientLoyaltyLevel.value">
                        <show-client-loyalty-level :client-loyalty-level="clientLoyaltyLevel.value" show-label />
                    </li>
                </ul>
            </div>
            <div class="ml-3">
                {{ $tl("labels.clients.caption.typologies") }}
                <ul class="pl-0 mt-3">
                    <li v-for="clientType in CLIENT_TYPES_WITH_SPECIFIC_ICONS" :key="clientType.value">
                        <show-client-type :client-type="clientType.value" show-label />
                    </li>
                </ul>
            </div>
            <div class="ml-3">
                {{ $tl("labels.clients.caption.miscellaneous") }}
                <ul class="pl-0 mt-3">
                    <li>
                        <feather type="star" class="feather-orange" v-tooltip="getTooltip($tl('labels.clients.vip'))" />
                        {{ $tl("labels.clients.vip") }}
                    </li>
                    <li>
                        <feather type="alert-triangle" class="feather-orange" v-tooltip="getTooltip($tl('labels.clients.risky'))" />
                        {{ $tl("labels.clients.risky") }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ClientLoyaltyLevelEnum from "../../../mixins/enums/ClientLoyaltyLevelEnum.js";
import ClientTypeEnum from "../../../mixins/enums/ClientTypeEnum.js";
import showClientLoyaltyLevel from "./showClientLoyaltyLevel.vue";
import showClientType from "./showClientType.vue";

export default {
    mixins: [ClientLoyaltyLevelEnum, ClientTypeEnum],
    components: {
        showClientLoyaltyLevel,
        showClientType,
    },
};
</script>
