var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Configuration DNS —— Étape " + _vm._s(_vm.step)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.loading
          ? _c("LoaderComponent")
          : _c("div", { staticClass: "container-fluid" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 mb-3" }, [
                  _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step === 0,
                            expression: "step === 0",
                          },
                        ],
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "\n                                Avant de commencer la configuration de votre domaine, assurez-vous d'avoir la possibilité de modifier les\n                                enregistrements DNS.\n                            "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step === 1,
                            expression: "step === 1",
                          },
                        ],
                        staticClass: "row",
                      },
                      [
                        _c("div", { staticClass: "col-12" }, [
                          _c("p", [
                            _vm._v(
                              "Veuillez choisir une méthode de validation du nom de domaine :"
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 rd-verification-method-container radio",
                          },
                          [
                            _c("label", { staticClass: "container-box" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.verification_method,
                                    expression: "verification_method",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "rd-verification-method",
                                  value: "DNS_TXT",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.verification_method,
                                    "DNS_TXT"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.verification_method = "DNS_TXT"
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkmark" }),
                              _vm._v(
                                "Enregistrement TXT (recommandé)\n                                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("label", { staticClass: "container-box" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.verification_method,
                                    expression: "verification_method",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "rd-verification-method",
                                  value: "DNS_CNAME",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.verification_method,
                                    "DNS_CNAME"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.verification_method = "DNS_CNAME"
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkmark" }),
                              _vm._v(
                                "Enregistrement CNAME (peut prendre plusieurs heures)\n                                "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step === 2 && _vm.token != null,
                            expression: "step === 2 && token != null",
                          },
                        ],
                        staticClass: "row",
                      },
                      [
                        _c("div", { staticClass: "col-12" }, [
                          _vm.error
                            ? _c("div", { staticClass: "alert alert-danger" }, [
                                _vm._v(_vm._s(_vm.error)),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12" }, [
                          _c("p", [
                            _vm._v(
                              "\n                                    Accédez à la section permettant de modifier les enregistrements DNS de votre domaine."
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                                    Ajoutez un nouvel enregistrement :\n                                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-3" }, [
                          _vm._v("Sous-domaine / Host :"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-9 mb-2" }, [
                          _vm._v(
                            _vm._s(_vm.website_settings.prefix_identifier)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-3" }, [_vm._v("Type :")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-9" }, [
                          _vm._v(_vm._s(_vm.verification_method.substr(4))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-3 d-flex align-items-center" },
                          [_c("span", [_vm._v("Réponse / Answer :")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-9 align-items-center" },
                          [
                            _c("CopyToClipboard", {
                              attrs: {
                                textToCopy: _vm.token,
                                element: _vm.$tl("labels.tokenIdentifier"),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step === 3,
                            expression: "step === 3",
                          },
                        ],
                        staticClass: "row",
                      },
                      [
                        _vm.error
                          ? _c("div", { staticClass: "alert alert-danger" }, [
                              _vm._v(_vm._s(_vm.error)),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step === 4,
                            expression: "step === 4",
                          },
                        ],
                        staticClass: "row",
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "\n                                Votre site a bien été vérifié. Retournez sur la page de configuration des enregistrements DNS de votre domaine.\n                                Supprimez l'enregistrement créé dans l'étape précédente et ajoutez l'enregistrement suivant :\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-3" }, [
                          _vm._v("Sous-domaine / Host :"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-9 mb-2" }, [
                          _vm._v(
                            _vm._s(_vm.website_settings.prefix_identifier)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-3" }, [_vm._v("Type :")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-9" }, [_vm._v("CNAME")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-3 d-flex align-items-center" },
                          [_c("span", [_vm._v("Réponse / Answer :")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-9 d-flex align-items-center" },
                          [
                            _c(
                              "span",
                              { attrs: { id: "display-copy-storage-cloud" } },
                              [_vm._v("c.storage.googleapis.com")]
                            ),
                            _vm._v(" "),
                            _c("CopyToClipboard", {
                              attrs: {
                                textToCopy: "c.storage.googleapis.com",
                                element: _vm.$tl("labels.linkIdentifier"),
                                showInput: false,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn-sm btn btn-secondary btn-circle",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v("Annuler")]
        ),
        _vm._v(" "),
        _vm.step !== 0 && _vm.step !== 2 && _vm.step !== 4
          ? _c(
              "button",
              {
                staticClass: "btn-sm btn btn-secondary btn-circle",
                on: {
                  click: function ($event) {
                    _vm.step--
                  },
                },
              },
              [_vm._v("Retour")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-success btn-sm btn-circle",
            attrs: { disabled: _vm.loading },
            on: {
              click: function ($event) {
                _vm.step === 3
                  ? _vm.verifyDomain()
                  : _vm.step === 4
                  ? _vm.$emit("verified")
                  : _vm.step++
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.step === 3
                    ? "Vérifier mon domaine"
                    : _vm.step === 4
                    ? "Fermer"
                    : "Continuer"
                ) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }