<template>
    <div class="row mb-3">
        <div class="col-12">
            <loader-component v-if="nbLoading > 0" />
            <div v-else-if="stats.main" class="border-light b-radius-20 p-4">
                <div class="row m-0">
                    <h6 class="mt-3 col-10">
                        <strong>{{ $tl(`labels.booking.globalStats.${statsType.label}.title`, firstSelectedRestaurantId) }}</strong>
                    </h6>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped overflowx-auto">
                        <thead>
                            <tr>
                                <th scope="col" colspan="1" class="group-header" :class="{ 'w-25': filters.hasComparison }"></th>
                                <th scope="col" :colspan="filters.hasComparison ? 5 : 1">
                                    {{ $tl("labels.booking.globalStats.table.headers.web", firstSelectedRestaurantId) }}
                                </th>
                                <th scope="col" :colspan="filters.hasComparison ? 5 : 1">
                                    {{ $tl("labels.booking.globalStats.table.headers.manual", firstSelectedRestaurantId) }}
                                </th>
                                <th v-if="hasEnabledRwg" scope="col" :colspan="filters.hasComparison ? 5 : 1">
                                    {{ $tl("labels.booking.globalStats.table.headers.rwg", firstSelectedRestaurantId) }}
                                </th>
                                <th scope="col" :colspan="filters.hasComparison ? 5 : 1">
                                    {{ $tl("labels.booking.globalStats.table.headers.total", firstSelectedRestaurantId) }}
                                </th>
                            </tr>
                            <tr v-if="filters.hasComparison" class="group-header">
                                <td>{{ $tl("labels.booking.globalStats.table.cells.nameRestaurant", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodOne", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodTwo", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodOneOnTwo", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.varNb", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.varPercent", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodOne", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodTwo", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodOneOnTwo", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.varNb", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.varPercent", firstSelectedRestaurantId) }}</td>
                                <td v-if="hasEnabledRwg">{{ $tl("labels.booking.globalStats.table.cells.periodOne", firstSelectedRestaurantId) }}</td>
                                <td v-if="hasEnabledRwg">{{ $tl("labels.booking.globalStats.table.cells.periodTwo", firstSelectedRestaurantId) }}</td>
                                <td v-if="hasEnabledRwg">
                                    {{ $tl("labels.booking.globalStats.table.cells.periodOneOnTwo", firstSelectedRestaurantId) }}
                                </td>
                                <td v-if="hasEnabledRwg">{{ $tl("labels.booking.globalStats.table.cells.varNb", firstSelectedRestaurantId) }}</td>
                                <td v-if="hasEnabledRwg">
                                    {{ $tl("labels.booking.globalStats.table.cells.varPercent", firstSelectedRestaurantId) }}
                                </td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodOne", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodTwo", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.periodOneOnTwo", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.varNb", firstSelectedRestaurantId) }}</td>
                                <td>{{ $tl("labels.booking.globalStats.table.cells.varPercent", firstSelectedRestaurantId) }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="filters.hasGroupComparison">
                                <tr v-for="restaurant in mergedSelectedRestaurants" :key="restaurant.id">
                                    <td>{{ restaurant.name }}</td>
                                    <td>{{ restaurant.stats.web }}</td>
                                    <td>{{ restaurant.stats.manual }}</td>
                                    <td v-if="hasEnabledRwg">{{ restaurant.stats.rwg }}</td>
                                    <td>{{ restaurant.stats.total }}</td>
                                </tr>
                            </template>
                            <template v-else-if="!filters.hasComparison && !filters.hasGroupComparison">
                                <tr v-for="restaurant in filters.main.selectedRestaurants" :key="restaurant.id">
                                    <td>{{ restaurant.name }}</td>
                                    <td>{{ getMainStats(restaurant.id).web }}</td>
                                    <td>{{ getMainStats(restaurant.id).manual }}</td>
                                    <td v-if="hasEnabledRwg">{{ getMainStats(restaurant.id).rwg }}</td>
                                    <td>{{ getMainStats(restaurant.id).total }}</td>
                                </tr>
                            </template>
                            <template v-else-if="filters.hasComparison">
                                <tr v-for="restaurant in filters.main.selectedRestaurants" :key="restaurant.id">
                                    <td>{{ restaurant.name }}</td>
                                    <td>{{ getMainStats(restaurant.id).web }}</td>
                                    <td>{{ getComparisonStats(restaurant.id).web }}</td>
                                    <td
                                        :class="
                                            getEvolutionClass(getEvolution(getMainStats(restaurant.id).web, getComparisonStats(restaurant.id).web))
                                        ">
                                        <feather
                                            :type="
                                                getFeatherArrowIcon(
                                                    getEvolution(getMainStats(restaurant.id).web, getComparisonStats(restaurant.id).web)
                                                )
                                            " />
                                    </td>
                                    <td
                                        :class="
                                            getEvolutionClass(getEvolution(getMainStats(restaurant.id).web, getComparisonStats(restaurant.id).web))
                                        ">
                                        {{ getDifference(getMainStats(restaurant.id).web, getComparisonStats(restaurant.id).web) }}
                                    </td>
                                    <td
                                        :class="
                                            getEvolutionClass(getEvolution(getMainStats(restaurant.id).web, getComparisonStats(restaurant.id).web))
                                        ">
                                        {{ formatPercent(getEvolution(getMainStats(restaurant.id).web, getComparisonStats(restaurant.id).web)) }}
                                    </td>
                                    <td>{{ getMainStats(restaurant.id).manual }}</td>
                                    <td>{{ getComparisonStats(restaurant.id).manual }}</td>
                                    <td
                                        :class="
                                            getEvolutionClass(
                                                getEvolution(getMainStats(restaurant.id).manual, getComparisonStats(restaurant.id).manual)
                                            )
                                        ">
                                        <feather
                                            :type="
                                                getFeatherArrowIcon(
                                                    getEvolution(getMainStats(restaurant.id).manual, getComparisonStats(restaurant.id).manual)
                                                )
                                            " />
                                    </td>
                                    <td
                                        :class="
                                            getEvolutionClass(
                                                getEvolution(getMainStats(restaurant.id).manual, getComparisonStats(restaurant.id).manual)
                                            )
                                        ">
                                        {{ getDifference(getMainStats(restaurant.id).manual, getComparisonStats(restaurant.id).manual) }}
                                    </td>
                                    <td
                                        :class="
                                            getEvolutionClass(
                                                getEvolution(getMainStats(restaurant.id).manual, getComparisonStats(restaurant.id).manual)
                                            )
                                        ">
                                        {{
                                            formatPercent(getEvolution(getMainStats(restaurant.id).manual, getComparisonStats(restaurant.id).manual))
                                        }}
                                    </td>
                                    <td v-if="hasEnabledRwg">{{ getMainStats(restaurant.id).rwg }}</td>
                                    <td v-if="hasEnabledRwg">{{ getComparisonStats(restaurant.id).rwg }}</td>
                                    <td
                                        v-if="hasEnabledRwg"
                                        :class="
                                            getEvolutionClass(getEvolution(getMainStats(restaurant.id).rwg, getComparisonStats(restaurant.id).rwg))
                                        ">
                                        <feather
                                            :type="
                                                getFeatherArrowIcon(
                                                    getEvolution(getMainStats(restaurant.id).rwg, getComparisonStats(restaurant.id).rwg)
                                                )
                                            " />
                                    </td>
                                    <td
                                        v-if="hasEnabledRwg"
                                        :class="
                                            getEvolutionClass(getEvolution(getMainStats(restaurant.id).rwg, getComparisonStats(restaurant.id).rwg))
                                        ">
                                        {{ getDifference(getMainStats(restaurant.id).rwg, getComparisonStats(restaurant.id).rwg) }}
                                    </td>
                                    <td
                                        v-if="hasEnabledRwg"
                                        :class="
                                            getEvolutionClass(getEvolution(getMainStats(restaurant.id).rwg, getComparisonStats(restaurant.id).rwg))
                                        ">
                                        {{ formatPercent(getEvolution(getMainStats(restaurant.id).rwg, getComparisonStats(restaurant.id).rwg)) }}
                                    </td>
                                    <td>{{ getMainStats(restaurant.id).total }}</td>
                                    <td>{{ getComparisonStats(restaurant.id).total }}</td>
                                    <td
                                        :class="
                                            getEvolutionClass(
                                                getEvolution(getMainStats(restaurant.id).total, getComparisonStats(restaurant.id).total)
                                            )
                                        ">
                                        <feather
                                            :type="
                                                getFeatherArrowIcon(
                                                    getEvolution(getMainStats(restaurant.id).total, getComparisonStats(restaurant.id).total)
                                                )
                                            " />
                                    </td>
                                    <td
                                        :class="
                                            getEvolutionClass(
                                                getEvolution(getMainStats(restaurant.id).total, getComparisonStats(restaurant.id).total)
                                            )
                                        ">
                                        {{ getDifference(getMainStats(restaurant.id).total, getComparisonStats(restaurant.id).total) }}
                                    </td>
                                    <td
                                        :class="
                                            getEvolutionClass(
                                                getEvolution(getMainStats(restaurant.id).total, getComparisonStats(restaurant.id).total)
                                            )
                                        ">
                                        {{ formatPercent(getEvolution(getMainStats(restaurant.id).total, getComparisonStats(restaurant.id).total)) }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import StatsUtils from "../../mixins/helpers/booking/StatsUtils.js";
import ArrowPercentEvolutionComponent from "./ArrowPercentEvolutionComponent.vue";
import { StatsType } from "../../mixins/enums/booking/DetailsPerRestaurantEnum.js";
export default {
    props: {
        restaurantId: {
            required: false,
            type: Number,
        },
        filters: {
            required: true,
            type: Object,
        },
        statsType: {
            required: true,
            type: Object,
            validator(value) {
                return Object.values(StatsType).includes(value);
            },
        },
        hasRwg: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            isGlobalReport: false,
            stats: {
                main: null,
                comparison: null,
            },
            currentHasRwg: this.hasRwg,
        };
    },
    mixins: [StatsUtils],
    computed: {
        hasEnabledGoogleReserve() {
            return this.$store.getters["widgets/getWidget"].enable_google_reserve || false;
        },
        hasEnabledRwg() {
            return this.currentHasRwg || (this.isReserveWithGoogleEnabled() && this.hasEnabledGoogleReserve);
        },
        firstSelectedRestaurantId() {
            if (this.mergedSelectedRestaurants.length < 1) {
                return undefined;
            }

            return this.mergedSelectedRestaurants[0].id;
        },
        mergedSelectedRestaurants() {
            const mainRestaurants = this.filters.main.selectedRestaurants || [];
            const comparisonRestaurants = this.filters.comparison?.selectedRestaurants || [];

            const result = mainRestaurants.map((restaurant) => ({
                ...restaurant,
                stats: this.stats.main?.[restaurant.id] || { web: 0, manual: 0, rwg: 0, total: 0 },
            }));

            if (this.filters.hasGroupComparison) {
                const mainRestaurantIds = mainRestaurants.map((r) => r.id);
                const additionalRestaurants = comparisonRestaurants
                    .filter((restaurant) => !mainRestaurantIds.includes(restaurant.id))
                    .map((restaurant) => ({
                        ...restaurant,
                        stats: this.stats.comparison?.[restaurant.id] || { web: 0, manual: 0, rwg: 0, total: 0 },
                    }));

                result.push(...additionalRestaurants);
            }

            return result;
        },
    },
    methods: {
        fetchStats(abortControllerSignal = null, forComparison = false) {
            const filters = this.getFiltersQueryParams(forComparison);
            const endpoint = this.statsType.key;

            return this.httpGet(`/api/stats/${endpoint}?${filters}&should_group_by_restaurant_id=1`, {
                config: { signal: abortControllerSignal },
                handleReject: false,
            }).then((response) => {
                if (response !== false) {
                    this.stats[forComparison ? "comparison" : "main"] = response.data;
                }
            });
        },
        getMainStats(restaurantId) {
            return this.stats.main?.[restaurantId] || { web: 0, manual: 0, rwg: 0, total: 0 };
        },
        getComparisonStats(restaurantId) {
            return this.stats.comparison?.[restaurantId] || { web: 0, manual: 0, rwg: 0, total: 0 };
        },
    },
    components: { LoaderComponent, ArrowPercentEvolutionComponent },
    updated() {
        this.currentHasRwg = this.hasRwg;
    },
};
</script>

<style scoped>
.table th,
.table td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 8px;
}

.group-header > td {
    background-color: #f2f2f2;
}

.table th {
    background-color: #f2f2f2;
    font-weight: bold;
}
</style>
