var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.has_rights
    ? _c(
        "li",
        { staticClass: "nav-item text-white" },
        [
          _vm.entry.url !== undefined
            ? [
                _vm.entry.onClick
                  ? _c(
                      "a",
                      {
                        staticClass: "nav-link pt-2 pb-2",
                        class: _vm.entry.class,
                        attrs: { href: _vm.entry.url },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.entry.onClick.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.entryTitle) +
                            "\n        "
                        ),
                      ]
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "nav-link pt-2 pb-2",
                        class: _vm.entry.class,
                        attrs: {
                          href: _vm.entry.url,
                          target: _vm.entry.target,
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.entryTitle) +
                            "\n        "
                        ),
                      ]
                    ),
              ]
            : _c(
                "router-link",
                {
                  staticClass: "nav-link pt-2 pb-2",
                  class: _vm.entry.class,
                  attrs: { to: _vm.entry.to },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.entryTitle))]),
                  _vm._v(" "),
                  _vm.entry.to.name === "app_updates.list" &&
                  _vm.hasUnreadAppUpdates
                    ? _c("div", { staticClass: "red-bubble ml-2" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasNewBadge ? _c("newBadge") : _vm._e(),
                ],
                1
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }