export default {
    data() {
        return {
            OPTION_QUANTITY_TYPE_UNLIMITED: {
                value: "unlimited",
            },
            OPTION_QUANTITY_TYPE_LIMITED_BY_PERS: {
                value: "limited_by_pers",
            },
            OPTION_QUANTITY_TYPE_MANDATORY_FOR_EACH: {
                value: "mandatory_for_each",
            },
        };
    },
    methods: {
        getOptionQuantityTypeLabel(value, restaurantId = undefined) {
            const found = this.ALL_OPTION_QUANTITY_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") {
                return this.$tl(`labels.booking.menus.options.quantityTypes.${value}`, restaurantId);
            }
            return value;
        },
    },
    computed: {
        ALL_OPTION_QUANTITY_TYPES() {
            return [this.OPTION_QUANTITY_TYPE_UNLIMITED, this.OPTION_QUANTITY_TYPE_LIMITED_BY_PERS, this.OPTION_QUANTITY_TYPE_MANDATORY_FOR_EACH];
        },
    },
};
