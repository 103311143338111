<template>
    <div v-if="canDisplayAlert" class="d-inline-flex justify-content-center align-items-center bg-danger w-100 p-1">
        <span class="text-white ml-auto">
            {{
                $tl("labels.notifications.booking.seatingPlanRoomDisabled.content", undefined, {
                    seatingPlan: unreadSeatingPlanNotifications.data.seatingPlanName,
                })
            }}
        </span>
        <router-link
            class="text-white ml-1 is-link"
            :to="{
                name: 'booking.restaurants.settings.seatingPlan',
                params: {
                    restaurant_id: unreadSeatingPlanNotifications.restaurant_id,
                    seating_plan_id: unreadSeatingPlanNotifications.data.seatingPlanId,
                },
                q,
            }">
            {{ $tl("labels.notifications.booking.seatingPlanRoomDisabled.link") }}
        </router-link>
        <feather type="x" class="text-white pointer ml-auto" @click="markAsRead" />
    </div>
</template>

<script>
export default {
    props: {
        unreadSeatingPlanNotifications: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            canDisplayAlert: true,
        };
    },
    computed: {},
    methods: {
        markAsRead() {
            const url = `/api/restaurants/${this.unreadSeatingPlanNotifications.restaurant_id}/notifications/booking/${this.unreadSeatingPlanNotifications.id}/read`;

            this.httpPost(url, {}, { handleResolve: false, handleReject: false }).then(() => {
                this.canDisplayAlert = false;
            });
        },
    },
};
</script>
