<template>
    <div>
        <div class="row">
            <div class="col-5">
                <label for="category">Type de licence :</label>
            </div>
            <div class="col-7">
                <span v-if="isNoShow && subscription.stripe_subscription_id !== null">
                    {{ subscription.name }}, {{ getModulePlanTypeLabel(subscription.plan) }} ({{ formatCurrency(price / 100) }})
                </span>
                <span v-else>{{ subscription.name }}</span>
                <button
                    v-if="isYservices || (subscription.stripe_subscription_id === null && hasManualBilling)"
                    type="button"
                    :disabled="loading || (!isYservices && !canUpdateSubscription)"
                    class="btn btn-sm btn-danger float-right"
                    @click="cancelSubscription">
                    <feather type="trash-2" />
                </button>
            </div>
        </div>
        <div v-if="isNoShow" class="row">
            <div class="col-5">
                <label for="category">Statut :</label>
            </div>
            <div class="col-7" :class="getSubscriptionStatusLabelClass(subscription.status)">
                {{ capitalize(getSubscriptionStatusLabel(subscription.status)) }}
            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <label for="category">Établissement lié :</label>
            </div>
            <div class="col-7">
                <select v-if="isYservices" class="form-control w-auto mb-1" v-model="restaurantId" :disabled="loading">
                    <option :value="null" disabled>
                        {{ $t("labels.subscriptions.assignRestaurant") }}
                    </option>
                    <option
                        v-for="restaurant in getRestaurantsForModule(subscription.module.type)"
                        :key="restaurant.id"
                        :value="restaurant.id"
                        :disabled="!restaurant.isFree">
                        {{ restaurant.name }}
                    </option>
                </select>
                <template v-else>
                    <span v-if="restaurant" class="text-capitalize">{{ restaurant.name }}</span>
                    <span v-else class="text-capitalize">Aucun</span>
                </template>
            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <label for="category">Date de création :</label>
            </div>
            <div class="col-7">
                <span>{{ createdAt }}</span>
            </div>
        </div>
        <template v-if="isNoShow">
            <div class="row" v-if="validSubscriptionStatus.some((s) => s.value === subscription.status)">
                <div class="col-5">
                    <label for="category">Date de renouvellement :</label>
                </div>
                <div class="col-7">
                    <span>{{ renewDate }}</span>
                </div>
            </div>
        </template>
        <div
            class="row"
            v-if="subscription.status === SUBSCRIPTION_STATUS_PAST_DUE.value || subscription.status === SUBSCRIPTION_STATUS_CANCELED.value">
            <div class="col-5">
                <label for="category">Date d'annulation :</label>
            </div>
            <div class="col-7">
                <span class="text-danger">{{ cancelationDate || "Inconnue" }}</span>
            </div>
        </div>
        <div v-if="subscription.logs.length > 0" class="row">
            <div class="col-12">
                <logs :logs="subscription.logs"></logs>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import ModulePlanTypesEnum from "../../mixins/enums/ModulePlanTypesEnum";
import SubscriptionStatusEnum from "../../mixins/enums/SubscriptionStatusEnum";
import Logs from "./subscriptions/Logs.vue";

export default {
    data() {
        return {
            loading: false,
            moment,
            restaurantId_: undefined,
        };
    },
    mixins: [ModulePlanTypesEnum, SubscriptionStatusEnum],
    props: {
        subscription: {
            required: true,
            type: Object,
        },
        restaurants: {
            required: true,
            type: Array,
        },
        hasManualBilling: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        canUpdateSubscription() {
            return this.rights.includes("admin.subscription.update");
        },
        restaurant() {
            return this.restaurants.find((r) => r.id === this.subscription.restaurant_id);
        },
        restaurantId: {
            get() {
                return typeof this.restaurantId_ === "undefined" ? this.subscription.restaurant_id : this.restaurantId_;
            },
            set(newVal) {
                this.restaurantId_ = newVal;
                this.assignSubscription(newVal);
            },
        },
        price() {
            if (typeof this.subscription.stripe_subscription !== "undefined" && this.subscription.stripe_subscription !== null) {
                switch (this.subscription.stripe_subscription.stripe_subscription_item.plan.id) {
                    case this.subscription.module.stripe_monthly_sku_id:
                        return this.subscription.module.stripe_monthly_price;
                    case this.subscription.module.stripe_monthly_discounted_sku_id:
                        return this.subscription.module.stripe_monthly_discounted_price;
                    case this.subscription.module.stripe_yearly_sku_id:
                        return this.subscription.module.stripe_yearly_price;
                    case this.subscription.module.stripe_yearly_discounted_sku_id:
                        return this.subscription.module.stripe_yearly_discounted_price;
                }
            }

            return 0;
        },
        createdAt() {
            return moment(this.subscription.created_at).format("DD MMMM Y");
        },
        renewDate() {
            const stripeObject = this.subscription.stripe_subscription;

            if (typeof stripeObject === "undefined" || stripeObject === null) {
                return "Non disponible pour les facturations manuelles";
            }

            if (stripeObject.status === this.SUBSCRIPTION_STATUS_TRIALING.value) {
                return moment.unix(stripeObject.trial_end).format("DD MMMM Y");
            }

            return moment.unix(stripeObject.current_period_end).format("DD MMMM Y");
        },
        cancelationDate() {
            if (this.subscription.past_due_at && this.subscription.status === this.SUBSCRIPTION_STATUS_PAST_DUE.value) {
                return moment(this.subscription.past_due_at).add(7, "days").format("DD MMMM Y");
            }
            if (this.subscription.unpaid_at && this.subscription.status === this.SUBSCRIPTION_STATUS_UNPAID.value) {
                return moment(this.subscription.unpaid_at).add(7, "days").format("DD MMMM Y");
            }
            if (this.subscription.status === this.SUBSCRIPTION_STATUS_CANCELED.value && this.subscription.canceled) {
                return moment(this.subscription.canceled).format("DD MMMM Y");
            }

            return "";
        },
    },
    methods: {
        cancelSubscription() {
            if (
                !confirm(
                    "Attention, en cas de suppression, les données de réservations sont conservées 30 jours. Passé ce délai, elles seront définitivement supprimées."
                )
            ) {
                return;
            }

            this.loading = true;

            this.httpDelete(`/api/admin/owners/${this.subscription.owner_id}/subscriptions/${this.subscription.id}`)
                .then((response) => {
                    if (response !== false) {
                        this.$emit("subscription-canceled");
                    }
                })
                .finally(() => (this.loading = false));
        },
        assignSubscription(restaurantId) {
            const currentRestaurantId = this.subscription.restaurant_id;

            this.loading = true;

            this.httpPut(`/api/admin/owners/${this.subscription.owner_id}/subscriptions/${this.subscription.id}/assign/${restaurantId}`)
                .then((response) => {
                    if (response !== false) {
                        this.$emit("subscription-assigned");
                    } else {
                        this.restaurantId_ = currentRestaurantId;
                    }
                })
                .finally(() => (this.loading = false));
        },
        getRestaurantsForModule(type) {
            return this.restaurants.filter((restaurant) => {
                restaurant.isFree = false;

                if (
                    restaurant.subscriptions &&
                    restaurant.subscriptions.data &&
                    !restaurant.subscriptions.data.some((subscription) => subscription.module.type === type)
                ) {
                    restaurant.isFree = true;
                }

                return restaurant;
            });
        },
    },
    components: { Logs },
};
</script>
