<template>
    <div>
        <p>
            {{ $t("labels.clients.import.steps.1.acceptedFiles", { formattedAllowedExtensions }) }}<br />
            <template v-if="allowedExtensions.includes('.csv')"> {{ $tl("labels.clients.import.steps.1.specificToCSV") }}<br /> </template>
            <span v-html="$tl('labels.clients.import.steps.1.noHeader')"></span><br />
            {{ $tl("labels.clients.import.steps.1.defineCol") }}
        </p>
        <p>Liste des champs autorisés :</p>
        <ul>
            <li class="list-style-type-circle">
                {{ $tl("labels.form.civility") }} ("{{ $tl("labels.clients.civilities.long.monsieur").toLowerCase() }}" ou "{{
                    $tl("labels.clients.civilities.long.madame").toLowerCase()
                }}")
            </li>
            <li class="list-style-type-circle">{{ $tl("labels.form.lastname") }} <span class="text-danger">*</span></li>
            <li class="list-style-type-circle">{{ $tl("labels.form.firstname") }} <span class="text-danger">*</span></li>
            <li class="list-style-type-circle">{{ $tl("labels.form.company") }}</li>
            <li class="list-style-type-circle">{{ $tl("labels.form.email") }}</li>
            <li class="list-style-type-circle">
                {{ $tl("labels.form.phoneNumber") }}
                <span class="text-danger">*</span>
                ({{ $tl("labels.clients.import.steps.1.allowedFields.phoneInfo") }})
            </li>
            <li class="list-style-type-circle">
                {{ $tl("labels.clients.import.steps.1.allowedFields.phoneCountryCode") }}
                <i18n path="labels.clients.import.steps.1.allowedFields.phoneCountryCodeFormat" tag="span">
                    <template slot="link">
                        <a target="_blank" href="http://www.atlas-monde.net/codes-iso/">ISO 3166-1 alpha-2</a>
                    </template>
                </i18n>
            </li>
            <li class="list-style-type-circle">
                {{ $tl("labels.clients.type") }} ({{ $tl("labels.clients.import.steps.1.allowedFields.clientTypeDefault") }})
            </li>
            <li class="list-style-type-circle">{{ $tl("labels.clients.import.steps.1.allowedFields.isVip") }}</li>
            <li class="list-style-type-circle">{{ $tl("labels.clients.import.steps.1.allowedFields.isRisky") }}</li>
            <li class="list-style-type-circle">{{ $tl("labels.clients.import.steps.1.allowedFields.newsletter") }}</li>
            <li class="list-style-type-circle">{{ $tl("labels.booking.reservations.clientComment") }}</li>
        </ul>
        <p><span class="text-danger">*</span> {{ $tl("labels.form.mandatoryFields") }}</p>
        <p>
            {{
                $t("infos.clients.import.noPhoneNumberCountryCodeNote", {
                    phoneCountryCode: $tl("labels.clients.import.steps.1.allowedFields.phoneCountryCode"),
                })
            }}<br />
            {{ $tl("infos.clients.import.phoneNumberCountryCodeConsiderFrench") }}
        </p>
        <div>
            <a v-for="ext in allowedExtensions" :key="ext" :href="`/assets/clients${ext}`" :download="`clients${ext}`" class="d-block">
                <small>{{ $t("labels.clients.import.steps.1.exampleFormat", { ext }) }}</small>
            </a>
        </div>
        <input id="fileInput" @change="fileInputChanged" type="file" :accept="allowedExtensions" />
        <div>
            <div v-if="errors.form !== null" class="alert alert-danger d-block position-relative">
                <p v-for="(error, i) in errors.forms" :key="i">{{ error }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errors: {
                form: null,
            },
        };
    },
    props: {
        file: {
            default: undefined,
        },
        allowedExtensions: {
            default: () => {
                return [".csv", ".xls"];
            },
        },
    },
    computed: {
        formattedAllowedExtensions() {
            let str = "";
            let i = 0;
            while (i < this.allowedExtensions.length) {
                str += `"${this.allowedExtensions[i]}"`;
                if (i < this.allowedExtensions.length - 2) str += ", ";
                else if (i < this.allowedExtensions.length - 1) str += " " + this.$tl("labels.and") + " ";
                else str += "";
                i++;
            }
            return str;
        },
    },
    methods: {
        fileInputChanged(event) {
            if (event.target.files && event.target.files.length > 0) {
                this.emitSetData("file", event.target.files[0]);
            }
        },
        validateFile() {
            if (!this.file) return;

            this.emitSetData("loading", true);

            const formData = new FormData();
            formData.append("file", this.file);

            return new Promise((resolve) => {
                this.httpPost(`/api/restaurants/${this.$route.params.restaurant_id}/clients/import/validateFile`, formData, {
                    handleResolve: false,
                    config: {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                })
                    .then((response) => {
                        if (response !== false) {
                            this.emitSetData("firstLines", response.data.first_lines);
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    })
                    .finally(() => this.emitSetData("loading", false));
            });
        },
        emitSetData(key, value) {
            this.$emit("set-data", {
                key,
                value,
            });
        },
    },
};
</script>
