var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.nbLoading > 0
        ? _c("LoaderComponent")
        : !_vm.errors.hasFatal
        ? _c(
            "keep-alive",
            [
              _c(
                _vm.currentStepComponent,
                _vm._b(
                  {
                    ref: "currentStepComponent",
                    tag: "component",
                    staticClass: "border-light p-3",
                    attrs: {
                      formErrors: _vm.errors.form,
                      restaurant_id: _vm.restaurantId,
                      "is-passing-customer": "",
                    },
                    on: { "next-step": _vm.nextStep },
                  },
                  "component",
                  {
                    reservation: _vm.reservation,
                    restaurant: _vm.restaurant,
                    tablesIdToPreselect: _vm.tablesIdToPreselect,
                    vueMultiSelect: _vm.vueMultiSelect,
                  },
                  false
                )
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }