<template>
    <div class="w-100">
        <div class="row m-0 mt-1">
            <div class="col-12">
                <div class="d-flex align-items-center">
                    <button class="btn" @click="$router.push({ name: 'online_help' })">
                        <feather type="chevron-left" class="mr-2 feather-24 pointer" />
                    </button>
                    <h5 class="title mt-2">
                        {{ $tl("labels.routes.onlineHelp") }} - {{ getModuleTypeLabel(module) }}
                        <ShowNewBadgeDetails class="ml-2" badge_uuid="a644c6f5-8bc3-48e8-be08-124398dfa9e1" />
                    </h5>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <LoaderComponent class="mx-auto" v-if="loading" />
            <template v-else-if="videosList.length > 0">
                <div class="w-75 mx-auto border-light mb-2 p-2" v-for="video in videosList" :key="video.id">
                    <h6 class="m-0">{{ video.title }}</h6>
                    <p v-if="video.description" class="m-0 mt-1" v-html="formatDescription(video.description)"></p>
                    <div class="w-100 text-right font-italic">
                        <small>{{ $t("labels.videos.addedOn", { date: displayDate(video.created_at) }) }}</small>
                    </div>
                    <button class="btn btn-success btn-sm btn-circle d-block mx-auto mt-1" @click="video.show = !video.show">
                        {{ video.show ? $tl("labels.videos.hide") : $tl("labels.videos.show") }}
                    </button>
                    <div class="row mt-2" v-if="video.show">
                        <ShowYoutubeVideo class="mx-auto" :youtubeVideoId="video.youtube_video_id" :title="video.title" />
                    </div>
                </div>
            </template>
            <div v-else class="col-12 font-italic mt-1 ml-4">{{ $tl("labels.videos.emptyForModule") }}</div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum";
import ShowYoutubeVideo from "../../../components/videos/ShowYoutubeVideo.vue";
import ShowNewBadgeDetails from "../../../components/NewBadges/showNewBadgeDetails.vue";

export default {
    data() {
        return {
            loading: false,
            videosList: [],
        };
    },
    mixins: [ModuleTypesEnum],
    methods: {
        fetchVideos() {
            this.loading = true;

            axios
                .get(`/api/videos?module=${this.module}`)
                .then((response) => {
                    this.videosList = response.data.data.map((video) => {
                        return {
                            ...video,
                            show: false,
                        };
                    });
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        formatDescription(description) {
            if (description === null || description === undefined) {
                return undefined;
            }
            return this.escapeHtml(description).replace(/\n/g, "<br/>");
        },
    },
    computed: {
        module() {
            return this.$route.params.module;
        },
    },
    components: {
        LoaderComponent,
        ShowYoutubeVideo,
        ShowNewBadgeDetails,
    },
    created() {
        this.fetchVideos();
    },
};
</script>
