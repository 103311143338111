var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mb-3" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _vm.nbLoading > 0
          ? _c("loader-component")
          : _vm.stats.main
          ? _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
              _c(
                "div",
                {
                  staticClass: "row pointer",
                  on: {
                    click: function ($event) {
                      _vm.showCaPerEntity = !_vm.showCaPerEntity
                    },
                  },
                },
                [
                  _c(
                    "strong",
                    { staticClass: "col-10 d-flex align-items-center" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$tl(
                              `labels.booking.stats.charts.${_vm.entity.translationKeyCA}.title`
                            )
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-end align-items-center col-2",
                    },
                    [
                      !_vm.filters.hasComparison
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-sm btn-square btn-secondary mr-2",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.toggleFilters.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_c("feather", { attrs: { type: "filter" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-square",
                          class: _vm.pinCaPerEntity
                            ? "btn-success"
                            : "btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.pinCaPerEntity = !_vm.pinCaPerEntity
                            },
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 384 512",
                                width: "18px",
                                height: "18px",
                                fill: "currentColor",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M300.8 203.9L290.7 128H328c13.2 0 24-10.8 24-24V24c0-13.2-10.8-24-24-24H56C42.8 0 32 10.8 32 24v80c0 13.2 10.8 24 24 24h37.3l-10.1 75.9C34.9 231.5 0 278.4 0 335.2c0 8.8 7.2 16 16 16h160V472c0 .7 .1 1.3 .2 1.9l8 32c2 8 13.5 8.1 15.5 0l8-32c.2-.6 .2-1.3 .2-1.9V351.2h160c8.8 0 16-7.2 16-16 .1-56.8-34.8-103.7-83.1-131.3zM33.3 319.2c6.8-42.9 39.6-76.4 79.5-94.5L128 96H64V32h256v64h-64l15.3 128.8c40 18.2 72.7 51.8 79.5 94.5H33.3z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isFilter
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex p-4 border-light b-radius-20 mt-3 flex-column",
                    },
                    [
                      _c("div", { staticClass: "col-12 pb-2 pl-0" }, [
                        _c("strong", { staticClass: "text-success" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$tl(
                                "labels.booking.stats.charts.CAPerEntity.filters.titleFilters"
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 d-flex pt-3" }, [
                        _c("div", { staticClass: "col-3 p-0" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$tl(
                                  `labels.booking.stats.charts.${_vm.entity.translationKeyCA}.filters.onlySold`
                                )
                              ) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-9 p-0" }, [
                          _c("label", { staticClass: "container-box" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.entitiesFilters.onlySold,
                                  expression: "entitiesFilters.onlySold",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.entitiesFilters.onlySold
                                )
                                  ? _vm._i(_vm.entitiesFilters.onlySold, null) >
                                    -1
                                  : _vm.entitiesFilters.onlySold,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.entitiesFilters.onlySold,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.entitiesFilters,
                                          "onlySold",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.entitiesFilters,
                                          "onlySold",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.entitiesFilters,
                                      "onlySold",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" }),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.entitiesFilters.onlySold
                        ? _c("div", { staticClass: "col-12 d-flex pt-3" }, [
                            _c("div", { staticClass: "col-3 p-0" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.stats.charts.CAPerEntity.filters.topSales"
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-9 p-0 radio" },
                              [
                                _c(
                                  "radio-button-component",
                                  {
                                    attrs: {
                                      "input-value": "all",
                                      "input-name": "topSales",
                                    },
                                    model: {
                                      value: _vm.entitiesFilters.topSold,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.entitiesFilters,
                                          "topSold",
                                          $$v
                                        )
                                      },
                                      expression: "entitiesFilters.topSold",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$tl(
                                            `labels.booking.stats.charts.${_vm.entity.translationKeyCA}.filters.all`
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "radio-button-component",
                                  {
                                    attrs: {
                                      "input-value": 3,
                                      "input-name": "topSales",
                                    },
                                    model: {
                                      value: _vm.entitiesFilters.topSold,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.entitiesFilters,
                                          "topSold",
                                          $$v
                                        )
                                      },
                                      expression: "entitiesFilters.topSold",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.stats.charts.CAPerEntity.filters.top3"
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "radio-button-component",
                                  {
                                    attrs: {
                                      "input-value": 10,
                                      "input-name": "topSales",
                                    },
                                    model: {
                                      value: _vm.entitiesFilters.topSold,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.entitiesFilters,
                                          "topSold",
                                          $$v
                                        )
                                      },
                                      expression: "entitiesFilters.topSold",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.stats.charts.CAPerEntity.filters.top10"
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-3" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-success btn-circle",
                            on: { click: _vm.applyFilters },
                          },
                          [_vm._v(_vm._s(_vm.$tl("labels.form.actions.apply")))]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showCaPerEntity || _vm.pinCaPerEntity
                ? _c(
                    "div",
                    {
                      staticClass: "row d-flex flex-column align-items-center",
                    },
                    [
                      _c("div", { staticClass: "p-3 d-flex" }, [
                        _c("strong", { staticClass: "text-success pr-1" }, [
                          _vm._v(_vm._s(_vm.labelPeriodMain)),
                        ]),
                        _vm._v(" "),
                        _vm.stats.comparison && _vm.filters.hasComparison
                          ? _c("span", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$tl("labels.filters.comparedTo")) +
                                  "\n                        "
                              ),
                              _c("strong", { staticClass: "text-success" }, [
                                _vm._v(_vm._s(_vm.labelPeriodComparison)),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      !_vm.noEntitiesSold ||
                      (_vm.noEntitiesSold &&
                        _vm.filters.hasComparison &&
                        _vm.stats.comparison !== null &&
                        !_vm.noEntitiesSoldComparison)
                        ? _c("VChart", {
                            staticClass: "chart",
                            attrs: {
                              option: {
                                title: _vm.caPerEntity.title,
                                series: _vm.seriesDataBarCa,
                                xAxis: _vm.caPerEntity.xAxis,
                                yAxis: _vm.caPerEntity.yAxis,
                                tooltip: _vm.tooltipCaPerMenu,
                                grid: _vm.grid,
                              },
                            },
                          })
                        : _c(
                            "div",
                            { staticClass: "h-100 d-flex align-items-center" },
                            [
                              _c("strong", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.stats.noDataOnPeriod"
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]),
                            ]
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }