var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5 pt-5" },
    [
      _vm.isLoading
        ? _c("LoaderComponent")
        : _c(
            "div",
            [
              _c("div", { staticClass: "row m-0" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                    },
                    [
                      _c("h5", { staticClass: "title mt-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$tl("labels.routes.booking.config.inputParams")
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("fields", {
                on: {
                  "update-fields": function ($event) {
                    _vm.fields = $event
                  },
                  "config-missing": function ($event) {
                    _vm.hideSaveButton = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("custom-tags", {
                on: {
                  "update-custom-tags": function ($event) {
                    _vm.customTags = $event
                  },
                },
              }),
            ],
            1
          ),
      _vm._v(" "),
      !_vm.hideSaveButton
        ? _c("div", { staticClass: "row m-0 mb-4" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success btn-circle",
                  attrs: { disabled: _vm.isLoading, type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.saveInputParams.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.form.actions.save")) +
                      "\n            "
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }