<template>
    <div>
        <pay-licences
            class="px-3"
            @back="$router.go(-1)"
            :modules="modules"
            :only_option="true"
            :redirect="{ name: 'subscription' }"
            :plan="plan" />
    </div>
</template>

<script>
import PayLicences from "../../../components/Modules/Licences/Pay";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum";

export default {
    data() {
        return {
            error: null,
        };
    },
    mixins: [ModuleTypesEnum],
    computed: {
        subscription_id() {
            return this.$route.params.subscription_id;
        },
        plan() {
            return this.$route.query.plan;
        },
        modules() {
            return [
                {
                    title: this.MODULE_TYPE_CLICK_AND_COLLECT.label,
                    option_type: this.MODULE_TYPE_CLICK_AND_COLLECT_DISPATCH.value,
                    type: this.MODULE_TYPE_CLICK_AND_COLLECT.value,
                    subscription_id: this.subscription_id,
                    stripe_monthly_price: 3000,
                    stripe_yearly_price: 30000,
                },
            ];
        },
    },
    components: {
        PayLicences,
    },
};
</script>
