<template>
    <li v-if="show">
        <button
            @click="moduleClicked"
            class="w-100 btn d-flex flex-row module hoverable my-3 mx-0 border-0 p-0 align-items-stretch"
            :style="showButtons ? 'cursor: default !important;' : ''"
            :class="{ disabled: !module.enabled, small }">
            <div class="icon-wrapper text-center" :class="{ disabled: !module.enabled, small }">
                <feather class="icon w-auto h-auto" :type="module.icon" size="28" />
            </div>
            <div class="w-100 border-top-light border-bottom-light border-right-light py-4 d-flex flex-row justify-content-between">
                <h5 class="my-auto ml-3">
                    {{ getModuleTypeLabel(module.value) }}
                    <newBadge class="ml-2" v-if="hasNewBadge" />
                </h5>
                <span class="float-right my-auto mr-3" v-if="showButtons">
                    <a v-if="isYproxModule" :href="module.goToUrl" target="_blank" class="btn btn-primary btn-circle" :class="{ 'btn-sm': isNoShow }">
                        {{ $tl("labels.modules.actions.goTo") }}
                    </a>
                    <template v-else>
                        <router-link v-if="module.enabled" :to="module.goToUrl" class="btn btn-primary btn-circle" :class="{ 'btn-sm': isNoShow }">
                            {{ $tl("labels.modules.actions.goTo") }}
                        </router-link>
                        <template v-else>
                            <router-link v-if="isNoShow" :to="module.activateUrl" class="btn btn-primary btn-circle btn-sm mr-2">
                                {{ $tl("labels.modules.actions.enable") }}
                            </router-link>
                            <template v-else>
                                <button type="button" class="btn btn-more-infos btn-circle mr-2" @click.prevent="canDisplayModal = true">
                                    {{ $tl("labels.subscriptions.moreInfos") }}
                                </button>
                            </template>
                        </template>
                    </template>
                </span>
            </div>
        </button>
        <ask-for-subscription v-if="canDisplayModal" :module="module.value" @close="canDisplayModal = false" />
    </li>
</template>
<script>
import newBadge from "../NewBadges/newBadge";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum";
import AskForSubscription from "../Modals/subscriptions/AskForSubscription.vue";

export default {
    name: "ModuleListItem",
    props: {
        module: {
            required: true,
        },
        showButtons: {
            default: true,
            type: Boolean,
        },
        small: {
            default: false,
        },
    },
    data() {
        return {
            canDisplayModal: false,
        };
    },
    components: {
        AskForSubscription,
    },
    mixins: [ModuleTypesEnum],
    computed: {
        show() {
            if (this.isYservices && !this.module.enabled && !this.showButtons) {
                return false;
            }

            return true;
        },
        isYproxModule() {
            return this.isYservices && this.module.value === this.MODULE_TYPE_WEBSITE.value;
        },
        hasNewBadge() {
            return this.checkForExistingNewBadge(this.module.value.replace(/-/g, "_"));
        },
    },
    methods: {
        moduleClicked() {
            if (this.showButtons) {
                return;
            }

            const url = this.module.enabled ? this.module.goToUrl : this.module.activateUrl;

            if (typeof url === "string") {
                window.open(url, "_blank");

                this.$emit("clicked-on-same-module");

                return;
            }

            if (url.name === this.$route.name) {
                this.$emit("clicked-on-same-module");
            } else {
                const desiredModule = (url.name || "").split(".")[0];
                const currentModule = (this.$route.name || "").split(".")[0];

                this.$router.push(url);

                if (desiredModule === currentModule) {
                    this.$emit("clicked-on-same-module");
                }
            }
        },
    },
    components: { newBadge, AskForSubscription },
};
</script>
