var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-5" }, [
    _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [
            _c("h5", { staticClass: "title mt-2" }, [
              _vm._v(_vm._s(_vm.$tl("labels.routes.clients"))),
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "btn btn-sm btn-success btn-circle",
                class: { disabled: !_vm.has_right_to_create_customer },
                attrs: {
                  to: {
                    name: `${_vm.moduleEnum.urlValue}.clients.form`,
                    params: {
                      restaurant_id: _vm.restaurant_id,
                      client_id: "create",
                    },
                  },
                },
              },
              [
                _c("feather", { attrs: { type: "plus" } }),
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$tl("labels.clients.add")) +
                    "\n                "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row m-0 pb-5" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.loading
            ? _c("LoaderComponent")
            : _c("div", [
                _vm.error
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.error) +
                          "\n                "
                      ),
                    ])
                  : _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-2",
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm.showLoyaltyConfig
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-outline-secondary btn-circle none-mobile mr-1",
                                      staticStyle: { "margin-top": "-5px" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showOptions = !_vm.showOptions
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.clients.editLoyaltyConfig"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("show-new-badge-details", {
                                staticClass: "mr-1",
                                attrs: {
                                  badge_uuid:
                                    "cda2e56e-e425-4342-9c0f-65b650b5dae8",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "d-inline-block",
                                  staticStyle: { "margin-top": "4px" },
                                },
                                [
                                  _c(
                                    "form",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.autoComplete.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-control search-client d-inline pr-1",
                                          staticStyle: {
                                            "border-radius":
                                              "20px 0 0 20px !important",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.autoloadSearch,
                                                expression: "autoloadSearch",
                                              },
                                            ],
                                            staticClass: "search-input",
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "labels.clients.searchPlaceHolder"
                                              ),
                                              type: "text",
                                            },
                                            domProps: {
                                              value: _vm.autoloadSearch,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.autoloadSearch =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "p-0 m-0 clear-search-button",
                                              staticStyle: {
                                                background: "none",
                                                border: "none",
                                              },
                                              attrs: { type: "button" },
                                              on: { click: _vm.clearSearch },
                                            },
                                            [
                                              _c("feather", {
                                                attrs: { type: "x" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-outline-secondary search-client-btn",
                                          staticStyle: {
                                            "border-radius": "0 20px 20px 0",
                                          },
                                          attrs: { type: "submit" },
                                        },
                                        [
                                          _c("feather", {
                                            attrs: { type: "search" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("clients-caption", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showClientsCaption,
                                    expression: "showClientsCaption",
                                  },
                                ],
                                staticClass:
                                  "position-absolute bg-white none-tablet",
                                staticStyle: {
                                  "z-index": "100",
                                  right: "320px",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "none-tablet btn btn-sm btn-circle mr-2",
                                  class: _vm.showClientsCaption
                                    ? "btn-success"
                                    : "btn-outline-secondary",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showClientsCaption =
                                        !_vm.showClientsCaption
                                    },
                                  },
                                },
                                [
                                  _c("feather", {
                                    staticClass: "feather-orange",
                                    attrs: { type: "help-circle" },
                                  }),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$tl("labels.clients.caption.title")
                                      ) +
                                      "\n                            "
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "btn-group" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-outline-secondary mb-0 none-mobile",
                                    class: {
                                      disabled:
                                        !_vm.has_right_to_import_and_export_customer,
                                    },
                                    staticStyle: {
                                      "border-radius": "20px 0 0 20px",
                                    },
                                    attrs: {
                                      type: "button",
                                      disabled:
                                        !_vm.has_right_to_import_and_export_customer,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.showImportModal = true
                                      },
                                    },
                                  },
                                  [
                                    _c("feather", {
                                      attrs: { type: "download" },
                                    }),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$tl("labels.form.actions.import")
                                        ) +
                                        "\n                                "
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-outline-secondary none-mobile",
                                    class: {
                                      disabled:
                                        !_vm.has_right_to_import_and_export_customer,
                                    },
                                    staticStyle: {
                                      "border-radius": "0 20px 20px 0",
                                    },
                                    attrs: {
                                      target: "_blank",
                                      href: _vm.has_right_to_import_and_export_customer
                                        ? `/api/restaurants/${_vm.restaurant_id}/clients/export?lang=${_vm.userLang}`
                                        : "",
                                    },
                                  },
                                  [
                                    _c("feather", {
                                      attrs: { type: "upload" },
                                    }),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$tl("labels.form.actions.export")
                                        ) +
                                        "\n                                "
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.showImportModal
                                ? _c("ImportClientsModal", {
                                    on: {
                                      close: _vm.closeImportModal,
                                      sent: function ($event) {
                                        _vm.showImportModal = false
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.showLoyaltyConfig && _vm.showOptions
                        ? _c(
                            "div",
                            { staticClass: "border-light b-radius-20" },
                            [
                              _c("edit-clients-loyalty-configs", {
                                attrs: {
                                  "module-enum": _vm.moduleEnum,
                                  "restaurant-id": _vm.restaurant_id,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.clients && _vm.clients.length > 0
                        ? _c(
                            "table",
                            {
                              staticClass:
                                "capitalize table table-sm table-striped border-bottom",
                            },
                            [
                              _c("thead", { staticClass: "border-bottom" }, [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.$tl("labels.form.client"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "none-mobile" }, [
                                    _vm._v(
                                      _vm._s(_vm.$tl("labels.form.email"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.$tl("labels.form.phoneNumber"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl("labels.form.actions.title")
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.clients, function (client) {
                                  return _c("tr", { key: client.id }, [
                                    _c(
                                      "td",
                                      [
                                        _c("inline-client", {
                                          attrs: {
                                            client: client,
                                            "module-enum": _vm.moduleEnum,
                                            "show-noshows": "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "none-mobile" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(client.email) +
                                          "\n                                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: `tel:${client.tel}` },
                                        },
                                        [_vm._v(_vm._s(client.tel))]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.getTooltip(
                                                  _vm.$t(
                                                    "labels.form.actions.edit"
                                                  )
                                                ),
                                                expression:
                                                  "getTooltip($t('labels.form.actions.edit'))",
                                              },
                                            ],
                                            staticClass:
                                              "btn btn-sm btn-success btn-square",
                                            class: {
                                              disabled:
                                                !_vm.has_right_to_update_customer &&
                                                !_vm.has_right_to_read_customer,
                                            },
                                            attrs: {
                                              to: {
                                                name: `${_vm.moduleEnum.urlValue}.clients.form`,
                                                params: {
                                                  client_id: client.id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c("feather", {
                                              attrs: { type: "edit" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]
                          )
                        : _c("div", [
                            !_vm.isSearch
                              ? _c("em", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.clients.emptyForRestaurant"
                                      )
                                    )
                                  ),
                                ])
                              : _c("em", [
                                  _vm._v(_vm._s(_vm.$tl("labels.noResult"))),
                                ]),
                          ]),
                      _vm._v(" "),
                      _vm.clients && _vm.clientsCountTotal > 0 && !_vm.isSearch
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "badge badge-secondary ml-1 mr-2 float-left",
                              staticStyle: { "margin-top": "21px" },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$tc(
                                      "labels.clients.count",
                                      _vm.clientsCountTotal
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.clients && _vm.clients.length > 0 && _vm.isSearch
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "badge badge-secondary ml-1 mr-2 float-left",
                              staticStyle: { "margin-top": "21px" },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$tc(
                                      "labels.clients.countSearch",
                                      _vm.clients.length
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isSearch
                        ? _c("div", { staticClass: "mt-3" }, [
                            _vm.links.prev
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-outline-secondary btn-circle",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.fetchData(_vm.links.prev)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.form.actions.previous"
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.links.next
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-outline-secondary btn-circle",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.fetchData(_vm.links.next)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$tl("labels.form.actions.next")
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]),
              ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }