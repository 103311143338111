import errors from "./errors.js";
import infos from "./infos.js";
import labels from "./labels.js";
import notices from "./notices.js";
import questions from "./questions.js";
import success from "./success.js";

export default {
    errors,
    infos,
    labels,
    notices,
    questions,
    success,
};
