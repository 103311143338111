<template>
    <div>
        <LoaderComponent v-show="loading" />
        <form @submit.prevent="uploadImage" v-show="!loading" class="image-component-display pt-1 pr-1 pb-0 pl-1">
            <img v-show="currentLoadedImg !== null" :src="currentLoadedImg" class="img-fluid" :style="imgStyle" />
            <div :class="{ 'mt-1': currentLoadedImg !== null }">
                <label :for="imgInputId" class="label-file mr-2" :class="{ 'text-muted': disabled, 'cursor-d': disabled }">
                    {{ imagePath !== null ? $tl("labels.form.image.chooseNew") : $tl("labels.form.image.choose") }}
                </label>
                <input
                    ref="imageInput"
                    :id="imgInputId"
                    :disabled="disabled"
                    type="file"
                    class="input-file"
                    @change="showPreview"
                    :accept="allowedExtensions" />
                <input
                    :disabled="disabled || isFileTooBig"
                    v-if="standalone && localImg !== null"
                    type="submit"
                    name="submit"
                    :value="$tl('labels.form.actions.send')"
                    class="btn btn-success btn-sm btn-circle" />
                <button
                    v-if="deleteUri !== null && imagePath !== null && localImg !== null"
                    class="btn btn-outline-secondary btn-sm btn-circle"
                    type="button"
                    @click.prevent.stop="resetInput">
                    {{ $tl("labels.form.actions.cancel") }}
                </button>
                <button
                    :disabled="disabled"
                    v-if="deleteUri !== null && imagePath !== null && localImg === null"
                    class="btn btn-danger btn-sm btn-circle"
                    type="button"
                    @click.prevent.stop="deleteImage">
                    {{ $tl("labels.form.image.delete") }}
                </button>
            </div>
            <div class="mb-2" v-if="maxSize !== null">
                <small v-if="isFileTooBig" class="text-danger">{{ $t("errors.common.image.tooBig", { size: currentFileSize, maxSize }) }}</small>
                <small v-else class="text-warning d-block">{{ $t("infos.common.file.maxSize", { maxSize }) }}</small>
                <small>
                    <a href="https://www.iloveimg.com/fr/compresser-image" target="_blank">
                        <feather type="image" class="text-warning mr-1" />{{ $tl("labels.form.image.compress") }}
                    </a>
                </small>
            </div>
        </form>
        <span class="text-danger" v-if="standalone && localImg !== null && !isFileTooBig">
            <small>{{ $tl("labels.form.image.dontForgetSave") }}</small>
        </span>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";

export default {
    data() {
        return {
            loading: false,
            localImg: null,
            currentFileSize: null,
        };
    },
    props: {
        publicImageUri: {
            type: String,
            default: null,
        },
        imagePath: {
            type: String,
            default: null,
        },
        uploadUri: {
            type: String,
            required: true,
        },
        deleteUri: {
            // Set only if image is deletable
            type: String,
            default: null,
        },
        imgStyle: {
            default: "",
        },
        allowedExtensions: {
            type: String,
            default: ".png,.jpg,.jpeg,.JPG",
        },
        standalone: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        maxSize: {
            // In MB
            type: Number,
            default: null,
        },
    },
    computed: {
        imgInputId() {
            return "img-input-uploader-" + Math.floor(Math.random() * 99999).toString();
        },
        currentLoadedImg() {
            return this.localImg || this.publicImageUri;
        },
        isFileTooBig() {
            return this.maxSize === null || this.currentFileSize === null ? false : this.currentFileSize > this.maxSize;
        },
    },
    methods: {
        showPreview(event) {
            if (event.target.files.length < 1) {
                return;
            }

            const file = event.target.files[0];

            this.localImg = URL.createObjectURL(file);
            this.currentFileSize = Number.parseFloat(file.size / 1048576);

            if (this.currentFileSize % 1 !== 1) {
                this.currentFileSize = this.currentFileSize.toFixed(1);
            }

            if (!this.standalone) {
                this.$emit("file-selected", file);
            }
        },
        resetInput() {
            this.localImg = null;
            this.$refs.imageInput.value = null;
        },
        uploadImage() {
            if (this.localImg === null) {
                return;
            }

            this.loading = true;

            const formData = new FormData();

            formData.append("img", this.$refs.imageInput.files[0]);

            if (this.imagePath !== null) {
                formData.append("old_img", this.imagePath);
            }

            this.httpPost(this.uploadUri, formData, { config: { "Content-Type": "multipart/form-data" } })
                .then((response) => {
                    if (response !== false) {
                        this.localImg = null;

                        this.$emit("image-uploaded", response.data.img);
                    }
                })
                .finally(() => this.$nextTick(() => (this.loading = false)));
        },
        // Note: be careful when using the "delete image" feature. You MUST add checks to avoid deleting images without permission
        deleteImage() {
            if (this.imagePath === null || !confirm(this.$tl("questions.common.deleteImg"))) {
                return;
            }

            this.loading = true;

            this.httpPut(this.deleteUri, { img: this.imagePath })
                .then((response) => {
                    if (response !== false) {
                        this.$emit("image-deleted");
                    }
                })
                .finally(() => this.$nextTick(() => (this.loading = false)));
        },
    },
    watch: {
        localImg(newVal) {
            this.$emit("unsaved-changes", newVal !== null);
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>

<style scoped>
.image-component-display > img.img-fluid {
    max-width: 200px;
    max-height: 120px;
}

.image-component-display input[type="submit"]:disabled {
    cursor: default;
}

.cursor-d,
.cursor-d:hover {
    cursor: default !important;
}
</style>
