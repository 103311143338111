export default {
    data() {
        return {
            RESERVATION_VIEW_LIST: {
                label: this.$tl("labels.booking.list.title"),
                value: "list",
            },
            RESERVATION_VIEW_SEATING_PLAN: {
                label: this.$tl("labels.booking.seatingPlan.title"),
                value: "seatingPlan",
            },
            RESERVATION_VIEW_TIMELINE: {
                label: this.$tl("labels.booking.timeline.title"),
                value: "timeline",
            },
        };
    },
    computed: {
        ALL_RESERVATION_VIEWS() {
            return [this.RESERVATION_VIEW_LIST, this.RESERVATION_VIEW_SEATING_PLAN, this.RESERVATION_VIEW_TIMELINE];
        },
    },
    methods: {
        getReservationViewValue(label) {
            const value = this.ALL_RESERVATION_VIEWS.find((view) => view.label === label);

            return value !== undefined ? value.value : null;
        },
    },
};
