<template>
    <div class="nav-btn-2 mx-md-5" v-if="restaurant_id && user && user.role !== 'kitchen'">
        <div class="btn-group">
            <button
                :title="$t('labels.booking.reservations.add.title')"
                class="btn btn-sm btn-success btn-nav-mob"
                :disabled="!has_right_to_create_reservation"
                @click="showAddReservationModal = true">
                <feather type="plus" :class="{ 'mr-0': isSidebarCollapsed }" />
                {{ $tl("labels.reservation") }}
            </button>
            <router-link
                :to="{
                    name: 'booking.restaurants.reservationsCreated',
                    params: { restaurant_id: $route.params.restaurant_id },
                }"
                class="btn btn-sm btn-warning btn-bot-resp btn-nav-mob"
                :class="{ disabled: !has_right_to_read_reservation }"
                :title="$t('labels.booking.reservations.created.show')">
                <feather type="clock" :class="{ 'mr-0': isSidebarCollapsed }" />
                {{ $tl("labels.pending") }}
            </router-link>
            <button
                :title="$t('labels.booking.services.setFull')"
                id="show-modal"
                @click="showSetServiceFullModal = true"
                :disabled="!has_right_to_update_service"
                class="btn btn-sm btn-danger btn-nav-mob">
                <feather type="pause" :class="{ 'mr-0': isSidebarCollapsed }" />
                {{ $tl("labels.booking.services.full") }}
            </button>
        </div>
        <add-reservation-modal-new
            v-if="showAddReservationModal"
            :restaurant_id="restaurant_id"
            @redirected="showAddReservationModal = false"
            @close="showAddReservationModal = false"
            @reservation-saved="reservationSaved" />
        <set-service-full-modal
            v-if="showSetServiceFullModal"
            :restaurant_id="restaurant_id"
            :prefill="false"
            :service_id="selected_service_id"
            @close="showSetServiceFullModal = false"
            @closure-saved="closureSaved" />
    </div>
</template>

<script>
import AddReservationModalNew from "../../components/Modals/reservations/AddReservationModalNew";
import SetServiceFullModal from "../../components/Modals/services/setServiceFullModal";

export default {
    name: "BookingTopbarActions",
    data() {
        return {
            selected_service_id: null,
            showSetServiceFullModal: false,
            showAddReservationModal: false,
        };
    },
    props: {
        user: {
            required: true,
        },
        isSidebarCollapsed: {
            default: false,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_reservation() {
            return this.rights.includes("booking.booking.create");
        },
        has_right_to_read_reservation() {
            return this.rights.includes("booking.booking.read");
        },
        has_right_to_update_service() {
            return this.rights.includes("booking.service.update");
        },
    },
    methods: {
        reservationSaved() {
            this.showAddReservationModal = false;
            this.$notify({
                group: "notification",
                type: "success",
                title: "La réservation a été enregistrée avec succès.",
            });
        },
        closureSaved() {
            this.showSetServiceFullModal = false;
            this.selected_service_id = null;
            this.$notify({
                group: "notification",
                type: "success",
                title: "Le service a été modifié avec succès",
            });
        },
    },
    components: {
        AddReservationModalNew,
        SetServiceFullModal,
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
