<template>
    <div>
        <loader-component v-if="isLoading"></loader-component>
        <div v-show="!isLoading" class="image-component-display pt-1 pr-1 pb-0 pl-1">
            <img v-if="currentLoadedImgUrl !== null" :src="currentLoadedImgUrl" class="img-fluid mb-1" />

            <div>
                <label :for="INPUT_ID" class="label-file mr-2" :class="disabled ? ['text-muted', 'cursor-d'] : []">
                    {{ input.file !== null || uploadedImgUrl !== null ? $tl("labels.form.image.chooseNew") : $tl("labels.form.image.choose") }}
                </label>
                <input
                    :id="INPUT_ID"
                    class="input-file"
                    type="file"
                    ref="inputFile"
                    :disabled="disabled"
                    @change="onInputChanged"
                    :accept="allowedExtensions" />
                <button v-if="input.file !== null" type="button" class="btn btn-circle btn-sm btn-danger" @click.prevent="resetInput">
                    {{ $tl("labels.form.actions.cancel") }}
                </button>
                <button
                    v-else-if="uploadedImgPath !== null && deleteUrl !== null"
                    type="button"
                    class="btn btn-circle btn-sm btn-danger"
                    @click.prevent="deleteImage">
                    {{ $tl("labels.form.actions.delete") }}
                </button>
            </div>

            <div v-if="maxSize !== null">
                <small v-if="isFileTooLarge" class="text-danger">{{
                    $tl("errors.common.image.tooBig", undefined, { size: input.fileSizeMB, maxSize })
                }}</small>
                <small v-else class="text-warning">{{ $tl("infos.common.file.maxSize", undefined, { maxSize }) }}</small>
                <br />
                <small>
                    <a href="https://www.iloveimg.com/fr/compresser-image" target="_blank">
                        <feather type="image" class="text-warning mr-1" />{{ $tl("labels.form.image.compress") }}
                    </a>
                </small>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";

// Warning: Once mounted, the component must NOT be unmounted before form's total submission, as it is not allowed to fill file input manually.
// Use a v-show instead of a v-if to keep the component mounted while loader is active.
export default {
    components: { LoaderComponent },
    data() {
        return {
            isLoading: false,
            input: {
                file: null,
                fileSizeMB: null,
                localUrl: null,
            },
        };
    },
    props: {
        // Public URL to show image preview
        uploadedImgUrl: {
            type: String,
            default: null,
        },
        // Image path as stored on the model. Use for delete only
        uploadedImgPath: {
            type: String,
            default: null,
        },
        // Delete URL. If not specified, image is not deletable
        deleteUrl: {
            type: String,
            default: null,
        },
        // Image extensions
        allowedExtensions: {
            type: String,
            default: ".png,.jpg,.jpeg,.JPG",
        },
        // Disable input
        disabled: {
            type: Boolean,
            default: false,
        },
        // Max image size, in MB
        maxSize: {
            type: Number,
            default: null,
        },
    },
    computed: {
        currentLoadedImgUrl() {
            return this.input.localUrl ?? this.uploadedImgUrl;
        },
        isFileTooLarge() {
            if (this.maxSize === null || this.input.fileSizeMB === null) {
                return false;
            }

            return this.input.fileSizeMB > this.maxSize;
        },
    },
    methods: {
        onInputChanged(event) {
            if (event.target.files.length < 1) {
                this.resetInput();
                return;
            }

            this.selectFile(event.target.files[0]);
        },
        selectFile(file) {
            this.input.file = file;
            this.input.fileSizeMB = Number.parseFloat(file.size / 1048576);
            this.input.localUrl = URL.createObjectURL(this.input.file);

            if (this.input.fileSizeMB % 1 !== 1) {
                this.input.fileSizeMB = this.input.fileSizeMB.toFixed(1);
            }

            this.$emit("image-selected", this.input.file);
        },
        resetInput() {
            this.input.file = null;
            this.input.fileSizeMB = null;
            this.input.localUrl = null;
            this.$refs.inputFile.value = null;
            this.$emit("image-selected", null);
        },
        deleteImage() {
            if (this.uploadedImgPath === null || !confirm(this.$tl("questions.common.deleteImg"))) {
                return;
            }

            this.isLoading = true;

            this.httpPut(this.deleteUrl, { img: this.uploadedImgPath })
                .then((response) => {
                    if (response !== false) {
                        this.$emit("image-deleted");
                    }
                })
                .finally(() => this.$nextTick(() => (this.isLoading = false)));
        },
    },
    created() {
        this.INPUT_ID = "img-input-uploader-" + Math.floor(Math.random() * 99999).toString();
    },
};
</script>

<style scoped>
.image-component-display > img.img-fluid {
    max-width: 200px;
    max-height: 120px;
}
</style>
