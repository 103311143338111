var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-5 pt-5" }, [
    _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [
            _c("h5", { staticClass: "title mt-2" }, [_vm._v("Produits")]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex align-items-center btn-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success btn-circle",
                  staticStyle: { "border-radius": "20px 0 0 20px !important" },
                  attrs: {
                    disabled:
                      _vm.config === null || !_vm.has_right_to_create_product,
                  },
                  on: { click: _vm.addProduct },
                },
                [
                  _c("feather", { attrs: { type: "plus" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Ajouter un produit")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-outline-secondary btn-circle",
                  staticStyle: { "border-radius": "0 20px 20px 0 !important" },
                  attrs: {
                    disabled:
                      _vm.config === null || !_vm.has_right_to_create_product,
                  },
                  on: { click: _vm.addFormule },
                },
                [
                  _c("feather", { attrs: { type: "plus" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Ajouter une formule")]),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row m-0 pb-3" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.loading
            ? _c("LoaderComponent")
            : _c("div", [
                _vm.error
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(_vm._s(_vm.error)),
                    ])
                  : _vm.config !== null
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-3",
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "d-inline-block",
                                  staticStyle: { "margin-top": "4px" },
                                },
                                [
                                  _c(
                                    "form",
                                    { on: { submit: _vm.fetchData } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-control search-client d-inline pr-1",
                                          staticStyle: {
                                            "border-radius":
                                              "20px 0 0 20px !important",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.filter,
                                                expression: "filter",
                                              },
                                            ],
                                            staticClass: "search-input",
                                            attrs: {
                                              placeholder:
                                                "Nom, Description...",
                                              type: "text",
                                            },
                                            domProps: { value: _vm.filter },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.filter = $event.target.value
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "p-0 m-0 clear-search-button",
                                              staticStyle: {
                                                background: "none",
                                                border: "none",
                                              },
                                              attrs: { type: "button" },
                                              on: { click: _vm.resetFilter },
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "feather feather-x",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "24",
                                                    height: "24",
                                                    viewBox: "0 0 24 24",
                                                    fill: "none",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                  },
                                                },
                                                [
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "18",
                                                      y1: "6",
                                                      x2: "6",
                                                      y2: "18",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "6",
                                                      y1: "6",
                                                      x2: "18",
                                                      y2: "18",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-outline-secondary search-client-btn",
                                          staticStyle: {
                                            "border-radius": "0 20px 20px 0",
                                          },
                                          attrs: { type: "submit" },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "feather feather-search",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "24",
                                                height: "20",
                                                viewBox: "0 0 24 24",
                                                fill: "none",
                                                stroke: "currentColor",
                                                "stroke-width": "2",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                              },
                                            },
                                            [
                                              _c("circle", {
                                                attrs: {
                                                  cx: "11",
                                                  cy: "11",
                                                  r: "8",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "21",
                                                  y1: "21",
                                                  x2: "16.65",
                                                  y2: "16.65",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-sm table-striped border-bottom",
                          },
                          [
                            _c("thead", { staticClass: "border-bottom" }, [
                              _c("tr", [
                                _c("th", [_vm._v("Nom")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Statut")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Catégorie")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Prix TTC")]),
                                _vm._v(" "),
                                !_vm.isTvaDisabled
                                  ? _c("th", [_vm._v("TVA")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("th", [_vm._v("Stock")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Préparation")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Disponibilité")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Créneaux")]),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  [
                                    _c("feather", {
                                      staticClass: "mr-1",
                                      attrs: { type: "shopping-bag" },
                                    }),
                                    _vm._v(" "),
                                    _c("feather", {
                                      staticClass: "mr-1",
                                      attrs: { type: "truck" },
                                    }),
                                    _vm._v(" "),
                                    _c("feather", { attrs: { type: "box" } }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("th", [_vm._v("Actions")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(_vm.products, function (product) {
                                return _c("tr", { key: product.id }, [
                                  _c("td", [
                                    product.name.length < 30
                                      ? _c("span", [
                                          _vm._v(_vm._s(product.name)),
                                        ])
                                      : product.name.length >= 30
                                      ? _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.getTooltip(
                                                  product.name
                                                ),
                                                expression:
                                                  "getTooltip(product.name)",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                product.name.substring(0, 30) +
                                                  "..."
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-inline-block align-middle lead-switch",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: product.active,
                                              expression: "product.active",
                                            },
                                          ],
                                          staticClass:
                                            "switch align-self-center is-rounded",
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_product,
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              product.active
                                            )
                                              ? _vm._i(product.active, null) >
                                                -1
                                              : product.active,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = product.active,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      product,
                                                      "active",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      product,
                                                      "active",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(product, "active", $$c)
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("label", {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.getTooltip("Actif"),
                                              expression: "getTooltip('Actif')",
                                            },
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.editActiveProduct(
                                                product
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(product.category_label)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        product.price !== null
                                          ? _vm.formatCurrency(product.price)
                                          : "--"
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  !_vm.isTvaDisabled
                                    ? _c("td", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              product.tax_rate &&
                                                product.type == "single"
                                                ? `${product.tax_rate}%`
                                                : "--"
                                            ) +
                                            "\n                                "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("td", [
                                    product.stock === null
                                      ? _c(
                                          "svg",
                                          {
                                            staticClass: "css-i6dzq1 feather",
                                            attrs: {
                                              viewBox: "0 0 24 24",
                                              width: "24",
                                              height: "24",
                                              stroke: "currentColor",
                                              "stroke-width": "2",
                                              fill: "none",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                            },
                                          },
                                          [
                                            _c("circle", {
                                              attrs: {
                                                cx: "7",
                                                cy: "11.5",
                                                r: "4.5",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("circle", {
                                              attrs: {
                                                cx: "17",
                                                cy: "11.5",
                                                r: "4.5",
                                              },
                                            }),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(_vm._s(product.stock)),
                                        ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      product.single_type === "menu"
                                        ? _c("span", [_vm._v("--")])
                                        : [
                                            _vm.displayCollectParams(product)
                                              ? [
                                                  product.preparation_time !==
                                                  null
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            product.preparation_time
                                                          ) +
                                                            "\n                                                " +
                                                            _vm._s(
                                                              _vm.preparationTimeUnitLabel(
                                                                product.preparation_time_unit,
                                                                product.preparation_time
                                                              )
                                                            )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.configPreparationTime
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.displayCollectParams(product) &&
                                            _vm.displayDeliveryParams(product)
                                              ? _c("span", [_vm._v(" - ")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.displayDeliveryParams(product)
                                              ? [
                                                  product.delivery_preparation_time !==
                                                  null
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            product.delivery_preparation_time
                                                          ) +
                                                            "\n                                                " +
                                                            _vm._s(
                                                              _vm.preparationTimeUnitLabel(
                                                                product.delivery_preparation_time_unit,
                                                                product.delivery_preparation_time
                                                              )
                                                            )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.configDeliveryPreparationTime
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              : _vm._e(),
                                          ],
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      product.single_type === "menu"
                                        ? _c("span", [_vm._v("--")])
                                        : [
                                            _vm.displayCollectParams(product)
                                              ? [
                                                  product.available_from !==
                                                    null &&
                                                  product.available_to !== null
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.dateToFr(
                                                                product.available_from
                                                              )
                                                            ) +
                                                            " -\n                                                " +
                                                            _vm._s(
                                                              _vm.dateToFr(
                                                                product.available_to
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ])
                                                    : _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "css-i6dzq1 feather",
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 24 24",
                                                            width: "24",
                                                            height: "24",
                                                            stroke:
                                                              "currentColor",
                                                            "stroke-width": "2",
                                                            fill: "none",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        },
                                                        [
                                                          _c("circle", {
                                                            attrs: {
                                                              cx: "7",
                                                              cy: "11.5",
                                                              r: "4.5",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("circle", {
                                                            attrs: {
                                                              cx: "17",
                                                              cy: "11.5",
                                                              r: "4.5",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.displayCollectParams(product) &&
                                            _vm.displayDeliveryParams(product)
                                              ? _c("br")
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.displayDeliveryParams(product)
                                              ? [
                                                  product.delivery_available_from !==
                                                    null &&
                                                  product.delivery_available_to !==
                                                    null
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.dateToFr(
                                                                product.delivery_available_from
                                                              )
                                                            ) +
                                                            " -\n                                                " +
                                                            _vm._s(
                                                              _vm.dateToFr(
                                                                product.delivery_available_to
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ])
                                                    : _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "css-i6dzq1 feather",
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 24 24",
                                                            width: "24",
                                                            height: "24",
                                                            stroke:
                                                              "currentColor",
                                                            "stroke-width": "2",
                                                            fill: "none",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        },
                                                        [
                                                          _c("circle", {
                                                            attrs: {
                                                              cx: "7",
                                                              cy: "11.5",
                                                              r: "4.5",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("circle", {
                                                            attrs: {
                                                              cx: "17",
                                                              cy: "11.5",
                                                              r: "4.5",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              : _vm._e(),
                                          ],
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      product.single_type === "menu"
                                        ? _c("span", [_vm._v("--")])
                                        : [
                                            _vm.displayCollectParams(product)
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        product.use_default_slots ==
                                                          true
                                                          ? "Tous"
                                                          : "Limité"
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.displayCollectParams(product) &&
                                            _vm.displayDeliveryParams(product)
                                              ? _c("span", [_vm._v(" / ")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.displayDeliveryParams(product)
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        product.use_default_delivery_slots ==
                                                          true
                                                          ? "Tous"
                                                          : "Limité"
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ],
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _vm.config.is_click_and_collect_enabled
                                        ? _c("feather", {
                                            class: {
                                              "mr-1":
                                                _vm.config
                                                  .is_delivery_enabled ||
                                                _vm.config.is_dispatch_enabled,
                                              "text-success":
                                                product.is_enabled_for_click_and_collect,
                                              "text-danger":
                                                !product.is_enabled_for_click_and_collect,
                                            },
                                            attrs: { type: "shopping-bag" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.config.is_delivery_enabled
                                        ? _c("feather", {
                                            class: {
                                              "mr-1":
                                                _vm.config.is_dispatch_enabled,
                                              "text-success":
                                                product.is_enabled_for_delivery,
                                              "text-danger":
                                                !product.is_enabled_for_delivery,
                                            },
                                            attrs: { type: "truck" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.config.is_dispatch_enabled
                                        ? _c("feather", {
                                            class: {
                                              "text-success":
                                                product.is_enabled_for_dispatch,
                                              "text-danger":
                                                !product.is_enabled_for_dispatch,
                                            },
                                            attrs: { type: "box" },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.getTooltip("Éditer"),
                                            expression: "getTooltip('Éditer')",
                                          },
                                        ],
                                        staticClass:
                                          "btn btn-sm btn-success btn-square",
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_update_product,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editProduct(product)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "feather feather-edit sc-dnqmqq jxshSx",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "24",
                                              height: "24",
                                              viewBox: "0 0 24 24",
                                              fill: "none",
                                              stroke: "currentColor",
                                              "stroke-width": "2",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                              "aria-hidden": "true",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.getTooltip("Dupliquer"),
                                            expression:
                                              "getTooltip('Dupliquer')",
                                          },
                                        ],
                                        staticClass:
                                          "btn btn-sm btn-success btn-square",
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_create_product,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.duplicateProduct(product)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "css-i6dzq1 feather",
                                            attrs: {
                                              viewBox: "0 0 24 24",
                                              width: "24",
                                              height: "24",
                                              stroke: "currentColor",
                                              "stroke-width": "2",
                                              fill: "none",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                            },
                                          },
                                          [
                                            _c("rect", {
                                              attrs: {
                                                x: "9",
                                                y: "9",
                                                width: "13",
                                                height: "13",
                                                rx: "2",
                                                ry: "2",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.getTooltip(
                                              product.has_future_commands
                                                ? "Ce produit ne peut être supprimer car il est inclus dans des commandes futures. Vous pouvez néanmoins désactiver ce produit en attendant de pouvoir le supprimer."
                                                : "Supprimer"
                                            ),
                                            expression:
                                              "\n                                            getTooltip(\n                                                product.has_future_commands\n                                                    ? 'Ce produit ne peut être supprimer car il est inclus dans des commandes futures. Vous pouvez néanmoins désactiver ce produit en attendant de pouvoir le supprimer.'\n                                                    : 'Supprimer'\n                                            )\n                                        ",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-danger btn-square",
                                            attrs: {
                                              disabled:
                                                !_vm.has_right_to_delete_product ||
                                                product.has_future_commands,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteProduct(
                                                  product.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "feather feather-trash-2 sc-dnqmqq jxshSx",
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  width: "24",
                                                  height: "24",
                                                  viewBox: "0 0 24 24",
                                                  fill: "none",
                                                  stroke: "currentColor",
                                                  "stroke-width": "2",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                  "aria-hidden": "true",
                                                },
                                              },
                                              [
                                                _c("polyline", {
                                                  attrs: {
                                                    points: "3 6 5 6 21 6",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("path", {
                                                  attrs: {
                                                    d: "M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("line", {
                                                  attrs: {
                                                    x1: "10",
                                                    y1: "11",
                                                    x2: "10",
                                                    y2: "17",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("line", {
                                                  attrs: {
                                                    x1: "14",
                                                    y1: "11",
                                                    x2: "14",
                                                    y2: "17",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.showAddClickAndCollectProductModal
                          ? _c("add-click-and-collect-product-modal", {
                              attrs: {
                                product: _vm.product,
                                duplicate: _vm.isDuplicating,
                                config: _vm.config,
                              },
                              on: {
                                close: function ($event) {
                                  _vm.showAddClickAndCollectProductModal = false
                                },
                                saved: _vm.productSaved,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    !_vm.loading && _vm.error !== null && _vm.has_right_to_update_product
      ? _c("div", { staticClass: "row m-0 mb-3" }, [
          _c("div", { staticClass: "col-12 text-muted" }, [
            _vm._v(
              "\n            Vous avez la possibilité d'ordonner vos produits en cliquant sur \"Catégories\" puis sur l'icone\n            "
            ),
            _c(
              "svg",
              {
                staticClass: "css-i6dzq1 feather",
                attrs: {
                  viewBox: "0 0 24 24",
                  width: "24",
                  height: "24",
                  stroke: "currentColor",
                  "stroke-width": "2",
                  fill: "none",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              },
              [
                _c("polyline", { attrs: { points: "9 5 12 2 15 5" } }),
                _vm._v(" "),
                _c("polyline", { attrs: { points: "15 19 12 22 9 19" } }),
                _vm._v(" "),
                _c("line", {
                  attrs: { x1: "12", y1: "2", x2: "12", y2: "22" },
                }),
              ]
            ),
            _vm._v(".\n        "),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }