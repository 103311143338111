<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Acheter une campagne SMS</h5>
        <div slot="body">
            <purchase-sms-campaign
                :smsCampaign="smsCampaign"
                @close="close"
                @paid="$emit('paid', $event)"
                @disable-close-button="closeButtonEnable = false" />
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button
                class="modal-default-button btn-sm btn btn-secondary btn-circle"
                @click="close"
                :disabled="!closeButtonEnable">
                Fermer
            </button>
        </div>
    </modal>
</template>

<script>
import PurchaseSmsCampaign from "../../campaign/PurchaseSmsCampaign";

export default {
    name: "PurchaseSmsCampaignModal",
    data: function () {
        return {
            loading: false,
            smsCampaign: {
                campaign_id: this.campaign_id,
                price_ht: 8, // in cent
            },
            closeButtonEnable: true,
        };
    },
    props: {
        campaign_id: {
            type: Number,
            required: true,
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        PurchaseSmsCampaign,
    },
};
</script>
