var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "h-100 m-0 p-0 d-flex flex-column table-plan",
      class: { "overflow-hidden": _vm.seatingPlanPrint.isPrinting },
      staticStyle: { "min-height": "0" },
    },
    [
      _c(
        "ul",
        {
          staticClass: "nav nav-tabs nav-service",
          staticStyle: { display: "-webkit-box", "margin-top": "-10px" },
        },
        [
          _vm._l(_vm.filteredServices, function (service, index) {
            return [
              _vm.isServiceDisplayable(service)
                ? _c(
                    "li",
                    {
                      key: index,
                      staticClass: "nav-item",
                      on: {
                        click: function ($event) {
                          _vm.selectedServiceIndex = index
                        },
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          class: { active: index === _vm.selectedServiceIndex },
                          attrs: { href: "#" },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.getServiceCategoryLabel(service)) +
                              _vm._s(
                                service.special === 1
                                  ? ` - (${_vm.$tl(
                                      "labels.booking.services.special.title"
                                    )})`
                                  : ""
                              ) +
                              "\n                    "
                          ),
                          _vm.hasResaNotPlaced(service)
                            ? _c("feather", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: {
                                      content:
                                        _vm.$t(
                                          "labels.booking.reservations.futureBlocked"
                                        ) +
                                        ". " +
                                        _vm.$t(
                                          "infos.booking.reservations.placeOnSeatingPlan"
                                        ),
                                    },
                                    expression:
                                      "{\n                            content: $t('labels.booking.reservations.futureBlocked') + '. ' + $t('infos.booking.reservations.placeOnSeatingPlan'),\n                        }",
                                  },
                                ],
                                staticClass: "text-danger",
                                attrs: { type: "info" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isLoading || _vm.seatingPlanPrint.isPrinting
        ? _c("loaderComponent")
        : [
            _vm.allServicesClosedAndNoResa
              ? _c("no-opened-services", {
                  attrs: {
                    "is-from-closure": "",
                    "closure-name": _vm.closureName,
                  },
                })
              : _vm.seatingPlan === null
              ? _c("NoSeatingPlan", {
                  staticClass: "mt-3",
                  attrs: {
                    service: _vm.selectedService ? _vm.selectedService : {},
                  },
                  on: {
                    close: function ($event) {
                      return _vm.$emit("go-to-resa-list")
                    },
                  },
                })
              : _c("div", { staticClass: "row m-0" }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            delay: { show: 400, hide: 0 },
                            content: _vm.$t("infos.booking.seatingPlan.tips"),
                          },
                          expression:
                            "{ delay: { show: 400, hide: 0 }, content: $t('infos.booking.seatingPlan.tips') }",
                        },
                      ],
                      staticClass: "text-warning text-tooltip",
                    },
                    [
                      _c("feather", {
                        staticClass: "mr-1",
                        attrs: { type: "info" },
                      }),
                      _vm._v(
                        _vm._s(_vm.$tl("labels.importantInfo")) +
                          "\n            "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "capacite badge badge-secondary count-resa",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.selectedService.pax) +
                          " /\n                " +
                          _vm._s(
                            _vm.selectedService.new_pax !== null
                              ? _vm.selectedService.new_pax
                              : _vm.selectedService.max_pax
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "align-items-center align-middle bg-light col-xl-3 col-lg-4 col-6 pt-0 pl-0 pr-0",
                    },
                    [
                      _vm.reservationsLength > 0
                        ? _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    delay: { show: 400, hide: 0 },
                                    content: _vm.$t(
                                      "labels.booking.seatingPlan.launchAlgo"
                                    ),
                                  },
                                  expression:
                                    "{\n                        delay: { show: 400, hide: 0 },\n                        content: $t('labels.booking.seatingPlan.launchAlgo'),\n                    }",
                                },
                              ],
                              staticClass:
                                "btn btn-sm btn-outline-secondary btn-block",
                              staticStyle: { height: "40px" },
                              attrs: {
                                type: "button",
                                disabled: !_vm.has_right_to_update_reservation,
                              },
                              on: { click: _vm.launchAutoPlacing },
                            },
                            [
                              _c("feather", { attrs: { type: "refresh-cw" } }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.seatingPlan.launchAlgoShort"
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-xl-9 col-lg-8 col-6 p-0 bg-light" },
                    [
                      _vm.openRooms.length > 0
                        ? _c("seating-plan-topbar", {
                            attrs: {
                              rooms: _vm.konva.rooms,
                              currentRoom: _vm.currentRoom,
                              readOnly: true,
                              selectedTables: _vm.switchTable.isSwitching
                                ? []
                                : _vm.selectedTables,
                              "can-add-passing-customer":
                                _vm.isSelectedServiceALiveService,
                            },
                            on: {
                              "add-reservation-on-tables": function ($event) {
                                _vm.$emit("add-reservation", {
                                  reservation_date: _vm.date,
                                  tablesId: _vm.konva.selectedTablesId.map(
                                    (id) => Number.parseInt(id)
                                  ),
                                })
                              },
                              "add-passing-customer-on-tables": function (
                                $event
                              ) {
                                _vm.$emit("add-passing-customer", {
                                  selectedDate: _vm.date,
                                  serviceId: _vm.selectedService.id,
                                  tablesId: _vm.konva.selectedTablesId.map(
                                    (id) => Number.parseInt(id)
                                  ),
                                })
                              },
                              "enable-selected-table": _vm.enableSelectedTable,
                              "disable-selected-table":
                                _vm.disableSelectedTable,
                              "room-selected": function ($event) {
                                _vm.konva.selectedRoom = $event
                              },
                              "change-room-enabled": _vm.changeRoomEnabled,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
          ],
      _vm._v(" "),
      _vm.filteredServices.length > 0
        ? _c(
            "div",
            {
              staticClass: "row m-0 h-100 d-flex flex-row",
              class: { invisible: _vm.seatingPlanPrint.isPrinting },
              staticStyle: { "min-height": "0" },
              style:
                _vm.isLoading || _vm.seatingPlan === null
                  ? "display: none !important"
                  : "",
            },
            [
              _c(
                "div",
                {
                  staticClass: "p-0 bg-light d-flex flex-column",
                  class: _vm.isReservationListCollapsed
                    ? "w-auto"
                    : "col-xl-3 col-lg-4 col-6 flex-grow-1",
                  staticStyle: { "min-height": "0" },
                  style: _vm.isReservationListCollapsed
                    ? "max-width: 6rem;"
                    : undefined,
                },
                [
                  _c("div", { staticClass: "row m-0 p-1 bg-light" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 plan-tools p-0" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-between",
                          },
                          [
                            _c("div", [
                              _vm.reservationsLength > 0
                                ? _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: {
                                            content: _vm.$t(
                                              "labels.booking.seatingPlan.switchTables.title"
                                            ),
                                          },
                                          expression:
                                            "{ content: $t('labels.booking.seatingPlan.switchTables.title') }",
                                        },
                                      ],
                                      staticClass: "btn mr-2",
                                      class: {
                                        active: _vm.switchTable.isSwitching,
                                      },
                                      on: { click: _vm.toggleSwitchTable },
                                    },
                                    [
                                      _c("feather", {
                                        attrs: { type: "repeat" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("feather", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.getTooltip(
                                    _vm.$t(
                                      `labels.booking.seatingPlan.reservationList.${
                                        _vm.isReservationListCollapsed
                                          ? "show"
                                          : "hide"
                                      }`
                                    )
                                  ),
                                  expression:
                                    "\n                                getTooltip($t(`labels.booking.seatingPlan.reservationList.${isReservationListCollapsed ? 'show' : 'hide'}`))\n                            ",
                                },
                              ],
                              staticClass: "pointer",
                              attrs: {
                                type: _vm.isReservationListCollapsed
                                  ? "chevrons-right"
                                  : "chevrons-left",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.isReservationListCollapsed =
                                    !_vm.isReservationListCollapsed
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.switchTable.isSwitching
                          ? [
                              _c("span", { staticClass: "text-warning" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.seatingPlan.switchTables.select"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "container-status",
                      class: { "d-none": _vm.isReservationListCollapsed },
                    },
                    _vm._l(_vm.statuses, function (status) {
                      return _c(
                        "div",
                        {
                          key: status.value,
                          staticClass: "item-status",
                          class: { active: status.active },
                          on: {
                            click: function ($event) {
                              return _vm.changeActive(status)
                            },
                          },
                        },
                        [
                          status.value === _vm.RESERVATION_STATUS_AT_TABLE.value
                            ? _c("ustensils-icon")
                            : status.featherIcon
                            ? _c("feather", {
                                attrs: { type: status.featherIcon },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$tl(
                                    `labels.booking.reservations.status.${status.value}`
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              " (" +
                                _vm._s(_vm.getReservationsPax(status)) +
                                ") "
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "row m-0 pb-2",
                      class: { "d-none": _vm.isReservationListCollapsed },
                      staticStyle: { overflow: "auto", height: "620px" },
                    },
                    [
                      _c(
                        "ul",
                        {
                          staticClass: "col-12 list-group h-100 pr-0",
                          class: {
                            "mouse-grab": _vm.has_right_to_update_reservation,
                          },
                        },
                        [
                          _vm.reservationsByStatus.length > 0
                            ? [
                                _vm._l(_vm.activeSlots, function (slot) {
                                  return _c(
                                    "div",
                                    {
                                      key: slot.id,
                                      staticClass: "slot-container",
                                    },
                                    [
                                      _vm.getReservationsForSlot(slot).length >
                                      0
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "slot-item" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(slot.hour_start) +
                                                      "\n                                    "
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "slot-item-icon-container",
                                                  },
                                                  [
                                                    _c("ustensils-icon", {
                                                      staticClass: "mr-1",
                                                      attrs: {
                                                        fill: "#ffffff",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.getPaxReservationsForSlot(
                                                              slot
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("calendar-icon", {
                                                      staticClass: "ml-2 mr-1",
                                                      attrs: {
                                                        fill: "#ffffff",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.getReservationsForSlot(
                                                              slot
                                                            ).length
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("feather", {
                                                      staticClass:
                                                        "pointer ml-3",
                                                      attrs: {
                                                        type: _vm.getChevron(
                                                          slot.id
                                                        ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleReservations(
                                                            slot.id
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            !_vm.canHideReservations[slot.id]
                                              ? _vm._l(
                                                  _vm.getReservationsForSlot(
                                                    slot
                                                  ),
                                                  function (
                                                    reservation,
                                                    index
                                                  ) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "list-group-item resa-item p-1",
                                                        class: {
                                                          "mb-2":
                                                            index ===
                                                            _vm.reservationsLength -
                                                              1,
                                                        },
                                                        attrs: {
                                                          draggable:
                                                            _vm.has_right_to_update_reservation,
                                                        },
                                                        on: {
                                                          dragstart: function (
                                                            $event
                                                          ) {
                                                            return _vm.dragstartReservation(
                                                              $event,
                                                              reservation.originalIndex
                                                            )
                                                          },
                                                          dragend: function (
                                                            $event
                                                          ) {
                                                            return _vm.dragendReservation(
                                                              $event
                                                            )
                                                          },
                                                          dragenter: function (
                                                            $event
                                                          ) {
                                                            return $event.preventDefault()
                                                          },
                                                          dblclick: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            $event.stopPropagation()
                                                            return _vm.showReservation(
                                                              reservation
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row m-0",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-12 pl-0 pr-1",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-content-between w-100",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "booking-status",
                                                                      {
                                                                        key:
                                                                          reservation.id +
                                                                          reservation.status,
                                                                        attrs: {
                                                                          reservation:
                                                                            reservation,
                                                                          "use-icon":
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          displayRefundPartiallyModal:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.displayRefundPartiallyModal(
                                                                                reservation.id
                                                                              )
                                                                            },
                                                                          displayNoshowModal:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.displayNoshowModal(
                                                                                reservation
                                                                              )
                                                                            },
                                                                          displayCancelModal:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.displayCancelModal(
                                                                                {
                                                                                  newStatus:
                                                                                    $event,
                                                                                  data: reservation,
                                                                                }
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-truncate ml-2",
                                                                        staticStyle:
                                                                          {
                                                                            "flex-grow":
                                                                              "1",
                                                                          },
                                                                      },
                                                                      [
                                                                        reservation.is_passing_customer
                                                                          ? _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                            " +
                                                                                    _vm._s(
                                                                                      _vm.$tl(
                                                                                        "labels.booking.reservations.passingCustomer.title"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                        "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : !reservation.client ||
                                                                            (!reservation
                                                                              .client
                                                                              .firstname &&
                                                                              !reservation
                                                                                .client
                                                                                .lastname)
                                                                          ? _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                            " +
                                                                                    _vm._s(
                                                                                      _vm.$tl(
                                                                                        "labels.clients.unknown"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                        "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "inline-client",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    client:
                                                                                      reservation.client,
                                                                                    "module-enum":
                                                                                      _vm.MODULE_TYPE_BOOKING,
                                                                                  },
                                                                              }
                                                                            ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        ![
                                                                          null,
                                                                          "",
                                                                        ].includes(
                                                                          reservation.comment
                                                                        )
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "tooltip",
                                                                                      rawName:
                                                                                        "v-tooltip",
                                                                                      value:
                                                                                        _vm.getTooltip(
                                                                                          reservation.comment
                                                                                        ),
                                                                                      expression:
                                                                                        "getTooltip(reservation.comment)",
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "text-truncate",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "feather",
                                                                                  {
                                                                                    staticClass:
                                                                                      "feather-blue",
                                                                                    attrs:
                                                                                      {
                                                                                        type: "message-circle",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n                                                            " +
                                                                                    _vm._s(
                                                                                      reservation.comment
                                                                                    ) +
                                                                                    "\n                                                        "
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        ![
                                                                          null,
                                                                          "",
                                                                        ].includes(
                                                                          reservation.restaurant_comment
                                                                        )
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "tooltip",
                                                                                      rawName:
                                                                                        "v-tooltip",
                                                                                      value:
                                                                                        _vm.getTooltip(
                                                                                          reservation.restaurant_comment
                                                                                        ),
                                                                                      expression:
                                                                                        "getTooltip(reservation.restaurant_comment)",
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "text-truncate",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "feather",
                                                                                  {
                                                                                    staticClass:
                                                                                      "feather-orange",
                                                                                    attrs:
                                                                                      {
                                                                                        type: "message-circle",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n                                                            " +
                                                                                    _vm._s(
                                                                                      reservation.restaurant_comment
                                                                                    ) +
                                                                                    "\n                                                        "
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        reservation.status ===
                                                                        _vm
                                                                          .RESERVATION_STATUS_CREATED
                                                                          .value
                                                                          ? _c(
                                                                              "manage-created-booking",
                                                                              {
                                                                                staticClass:
                                                                                  "reservation-tag small d-block",
                                                                                attrs:
                                                                                  {
                                                                                    editable:
                                                                                      _vm.has_right_to_update_reservation,
                                                                                    reservation:
                                                                                      reservation,
                                                                                    "restaurant-id":
                                                                                      _vm
                                                                                        .restaurant
                                                                                        .id,
                                                                                    "is-payment-enabled":
                                                                                      _vm.isPaymentEnabled,
                                                                                  },
                                                                                on: {
                                                                                  displayCancelModal:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.displayCancelModal(
                                                                                        {
                                                                                          newStatus:
                                                                                            $event,
                                                                                          data: reservation,
                                                                                        }
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "mt-1 tags has-addons float-left mb-0",
                                                                          },
                                                                          [
                                                                            reservation.ignore_placement &&
                                                                            !reservation.is_passing_customer
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "badge badge-danger text-uppercase",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$tl(
                                                                                          "labels.booking.services.surbooking"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._l(
                                                                                  _vm.reservationTables(
                                                                                    reservation
                                                                                  ),
                                                                                  function (
                                                                                    table,
                                                                                    index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "span",
                                                                                      {
                                                                                        key: index,
                                                                                        staticClass:
                                                                                          "tag is-rounded mb-0",
                                                                                        style:
                                                                                          _vm.getBackgroundColor(
                                                                                            reservation
                                                                                          ),
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                    " +
                                                                                            _vm._s(
                                                                                              table.name
                                                                                            ) +
                                                                                            "\n                                                                    "
                                                                                        ),
                                                                                        _vm.has_right_to_update_reservation
                                                                                          ? _c(
                                                                                              "button",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "delete",
                                                                                                on: {
                                                                                                  dblclick:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      $event.preventDefault()
                                                                                                      $event.stopPropagation()
                                                                                                    },
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      $event.stopPropagation()
                                                                                                      return _vm.unlinkResaManually(
                                                                                                        table,
                                                                                                        reservation
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              }
                                                                                            )
                                                                                          : _vm._e(),
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                          ],
                                                                          2
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        reservation.tables &&
                                                                        reservation
                                                                          .tables
                                                                          .data
                                                                          .length >
                                                                          0
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "d-inline-block mt-2 ml-1",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                            " +
                                                                                    _vm._s(
                                                                                      _vm.getReservationRoom(
                                                                                        reservation
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                        "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-right border-left-light",
                                                                        staticStyle:
                                                                          {
                                                                            "min-width":
                                                                              "64px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                            " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .$tl(
                                                                                      "labels.pax"
                                                                                    )
                                                                                    .toUpperCase()
                                                                                ) +
                                                                                " :\n                                                            " +
                                                                                _vm._s(
                                                                                  Number.parseInt(
                                                                                    reservation.nb_pers
                                                                                  ) +
                                                                                    Number.parseInt(
                                                                                      reservation.nb_children
                                                                                    )
                                                                                ) +
                                                                                "\n                                                        "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm.getReservationPaxLeft(
                                                                          reservation
                                                                        ) > 0
                                                                          ? _c(
                                                                              "small",
                                                                              {
                                                                                staticClass:
                                                                                  "text-danger",
                                                                                staticStyle:
                                                                                  {
                                                                                    display:
                                                                                      "block",
                                                                                    "margin-top":
                                                                                      "-3px",
                                                                                    "margin-bottom":
                                                                                      "-3px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                            " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "labels.remainingNb",
                                                                                        {
                                                                                          nb: _vm.getReservationPaxLeft(
                                                                                            reservation
                                                                                          ),
                                                                                        }
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                        "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                }),
                                _vm._v(" "),
                                _c("small", { staticClass: "mb-3 pb-3" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.seatingPlan.dlbClickShowResa"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            : _vm.selectedService
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "d-block text-center pt-2 pr-4 pb-4 pl-4",
                                  staticStyle: { "font-size": "0.7rem" },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.seatingPlan.emptyForService",
                                          _vm.restaurant.id,
                                          {
                                            service: _vm.selectedService.name,
                                            status: _vm.$tl(
                                              `labels.booking.reservations.status.${_vm.selectedStatus}`
                                            ),
                                          }
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "h-100 m-0 p-0 flex-grow-1",
                  class: _vm.isReservationListCollapsed
                    ? "w-auto"
                    : "col-xl-9 col-lg-8 col-6",
                },
                [
                  _c(
                    "div",
                    { staticClass: "row h-100 m-0 p-0 overflow-auto" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "h-100 p-0 m-0 position-relative",
                          class: _vm.isGroupsAndPrintTabCollapsed
                            ? "col-12"
                            : "col-xl-10 col-lg-12",
                          on: {
                            contextmenu: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", {
                            ref: "seatingPlanCanvas",
                            staticClass: "h-100 p-0 m-0",
                            attrs: { id: "seating-plan-canvas" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("seating-plan-tree-view", {
                        staticClass: "d-none h-100 d-xl-block",
                        class: _vm.isGroupsAndPrintTabCollapsed
                          ? "w-auto"
                          : "col-xl-2",
                        attrs: {
                          currentRoom: _vm.currentRoomFormatted,
                          rooms: _vm.konva.rooms,
                          disablePointerEvents:
                            _vm.draggedReservationIndex !== null,
                          "can-collapse": "",
                          showTables: false,
                        },
                        on: {
                          "droped-on-group": function ($event) {
                            return _vm.dropedOnGroup($event)
                          },
                          unhighlight: _vm.unhighlight,
                          print: _vm.printSelectedRooms,
                          "highlight-group": function ($event) {
                            return _vm.highlightGroup($event)
                          },
                          resized: _vm.onResize,
                        },
                      }),
                      _vm._v(" "),
                      _vm.canShowServiceNotes
                        ? _c("ListMemos", {
                            attrs: {
                              restaurantId: _vm.restaurantId,
                              serviceId:
                                _vm.filteredServices[_vm.selectedServiceIndex]
                                  .id,
                              date: _vm.date,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showReservationModal
        ? _c("show-reservation-modal", {
            attrs: {
              reservation_id: _vm.selected_reservation_id,
              restaurant_id: _vm.selected_restaurant_id,
            },
            on: {
              "resa-edit": _vm.editReservationFromShowModal,
              close: function ($event) {
                _vm.showReservationModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showEditReservationModal
        ? _c("edit-reservation-modal", {
            attrs: {
              restaurant_id: _vm.selected_restaurant_id,
              reservation_id: _vm.selected_reservation_id,
            },
            on: {
              close: function ($event) {
                _vm.showEditReservationModal = false
              },
              "reservation-edited": _vm.reservationEdited,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showErrorModal
        ? _c("error-modal", {
            attrs: { message: _vm.modalErrorMessage },
            on: {
              close: function ($event) {
                _vm.showErrorModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPaxSelectionModal
        ? _c("pax-selection-modal", {
            attrs: {
              tables: _vm.paxesSelection.tables,
              dropedOnType: _vm.paxesSelection.dropedOnType,
              dropRelatedObject: _vm.paxesSelection.dropRelatedObject,
              selectedReservation: _vm.draggedReservation,
              "current-room-index": _vm.konva.selectedRoom,
            },
            on: {
              close: _vm.abortPaxesSelection,
              "place-all": _vm.placeAll,
              "paxes-selected": _vm.paxesSelected,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showReservationListModal
        ? _c("show-reservation-list-modal", {
            attrs: {
              emit_resa_chosen: _vm.switchTable.isSwitching,
              reservations_list: _vm.selected_reservations_list,
              restaurant_id: _vm.selected_restaurant_id,
            },
            on: {
              "resa-chosen": _vm.resaChosenForSwitch,
              "reservation-edited": _vm.reservationEditedFromList,
              close: function ($event) {
                _vm.switchTable.isSwitching
                  ? _vm.resaChosenForSwitch(false)
                  : (_vm.showReservationListModal = false)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showRefundPartiallyModal
        ? _c("cancel-paid-reservation-modal", {
            attrs: { reservation_id: _vm.selected_reservation_id },
            on: {
              close: function ($event) {
                _vm.showRefundPartiallyModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showCancelModal
        ? _c("cancel-reservation-modal", {
            attrs: {
              "reservation-id": _vm.selected_reservation_id,
              client: _vm.selected_reservation_client,
              status: _vm.newCancelStatus,
            },
            on: {
              close: function ($event) {
                _vm.showCancelModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSetNoshowModal
        ? _c("set-noshow-modal", {
            attrs: {
              restaurant_id: _vm.selected_restaurant_id,
              reservation: _vm.selected_reservation,
            },
            on: {
              close: function ($event) {
                _vm.showSetNoshowModal = false
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }