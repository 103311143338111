<template>
    <div>
        <loader-component v-if="isLoading" />
        <template v-else-if="loyaltyConfigs !== null">
            <div class="row">
                <div class="col-lg-6">
                    <h6>{{ $tl("labels.rules") }}</h6>
                    <div v-for="(loyaltyConfig, index) in loyaltyConfigs" :key="loyaltyConfig.level">
                        <div>
                            <label class="container-box mr-0" style="width: initial; margin-top: 5px">
                                <input type="checkbox" :disabled="!hasRightToEdit" v-model="loyaltyConfig.enabled" />
                                <span style="margin-top: 0px" class="checkmark mr-1" :class="{ disabled: !hasRightToEdit }"></span>
                                {{ getClientLoyaltyLevelLabel(loyaltyConfig.level) }}
                            </label>
                            <div class="d-inline-block" v-if="loyaltyConfig.enabled">
                                {{ $tl("labels.from") }}
                                <input
                                    type="number"
                                    class="form-control d-inline-block ml-1 mr-1 p-1"
                                    :disabled="!hasRightToEdit"
                                    style="width: 50px; height: 30px"
                                    v-model.number="loyaltyConfig.starts_from"
                                    :min="2" />
                                {{ startsFromLabel }}
                            </div>
                        </div>
                        <show-errors class="d-block" :errors="errors.form" :error-key="`loyaltyConfigs.${index}.level`"></show-errors>
                        <show-errors class="d-block" :errors="errors.form" :error-key="`loyaltyConfigs.${index}.starts_from`"></show-errors>
                    </div>
                </div>
                <div v-if="moduleEnum.value === MODULE_TYPE_BOOKING.value" class="col-lg-6">
                    <h6>{{ $tl("labels.benefits") }}</h6>
                    <div>
                        <div>
                            <label class="container-box mr-0" style="width: initial">
                                <input type="checkbox" :disabled="!hasRightToEdit" v-model="booking.disable_footprint_for_vip" />
                                <span style="margin-top: 0px" class="checkmark mr-1" :class="{ disabled: !hasRightToEdit }"></span>
                                {{ $tl("labels.clients.disableFootprintVIP") }}
                            </label>
                        </div>
                        <show-errors class="d-block" :errors="errors.form" error-key="disable_footprint_for_vip"></show-errors>
                    </div>
                    <div>
                        <div>
                            <label class="container-box mr-0" style="width: initial">
                                <input
                                    type="checkbox"
                                    :disabled="activeLoyaltyLevels.length < 1 || !hasRightToEdit"
                                    v-model="disableFootprintOnLoyaltyLevel" />
                                <span style="margin-top: 0px" class="checkmark mr-1" :class="{ disabled: !hasRightToEdit }"></span>
                                {{ $tl("labels.clients.disableFootprintLoyaltyLevel") }}
                            </label>
                            <div class="d-inline-block" v-if="disableFootprintOnLoyaltyLevel">
                                {{ $tl("labels.from") }}
                                <select
                                    class="d-inline-block custom-select ml-1 mb-0 w-auto"
                                    :disabled="!hasRightToEdit"
                                    v-model="booking.disable_footprint_from_loyalty_level">
                                    <option v-for="level in ALL_CLIENT_LOYALTIES" :key="level.value" :value="level.value">
                                        {{ getClientLoyaltyLevelLabel(level.value) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <show-errors class="d-block" :errors="errors.form" error-key="disable_footprint_from_loyalty_level"></show-errors>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button type="button" class="btn btn-sm btn-success btn-circle" :disabled="!hasRightToEdit" @click="saveLoyaltyConfigs">
                        {{ $tl("labels.form.actions.save") }}
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../../../LoaderComponent.vue";
import ModuleTypesEnum from "../../../../mixins/enums/ModuleTypesEnum.js";
import ClientLoyaltyLevelEnum from "../../../../mixins/enums/ClientLoyaltyLevelEnum.js";
import ShowErrors from "../../../errors/ShowErrors.vue";

export default {
    data() {
        return {
            isLoading: false,
            loyaltyConfigs: null,
            booking: {
                disable_footprint_for_vip: false,
                disable_footprint_from_loyalty_level: null,
            },
            errors: {
                form: null,
            },
        };
    },
    props: {
        moduleEnum: {
            type: Object,
            required: true,
        },
        restaurantId: {
            type: Number,
            required: true,
        },
    },
    mixins: [ModuleTypesEnum, ClientLoyaltyLevelEnum],
    computed: {
        disableFootprintOnLoyaltyLevel: {
            get() {
                return this.booking.disable_footprint_from_loyalty_level !== null;
            },
            set(newVal) {
                this.booking.disable_footprint_from_loyalty_level = newVal ? this.CLIENT_LOYALTY_LEVEL_FIRST.value : null;
            },
        },
        startsFromLabel() {
            switch (this.moduleEnum.value) {
                case this.MODULE_TYPE_BOOKING.value:
                    return this.$tl("labels.booking.reservations.honored");
                case this.MODULE_TYPE_CLICK_AND_COLLECT.value:
                    return "commandes effectuées";
                case this.MODULE_TYPE_GIFT_VOUCHERS.value:
                    return "achats effectués";
                default:
                    return "";
            }
        },
        activeLoyaltyLevels() {
            if (this.loyaltyConfigs === null) {
                return [];
            }

            return this.loyaltyConfigs.filter((lc) => lc.enabled).map((lc) => lc.level);
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        hasRightToEdit() {
            return this.rights.includes("default.loyalties.update");
        },
    },
    methods: {
        fetchLoyaltyConfigs() {
            this.isLoading = true;

            this.httpGet(`/api/restaurants/${this.restaurantId}/clients/loyaltyConfigs/${this.moduleEnum.value}`)
                .then((response) => {
                    if (response !== false) {
                        this.loyaltyConfigs = [];

                        if (typeof response.data[this.moduleEnum.value] !== "undefined") {
                            Object.assign(this[this.moduleEnum.value], response.data[this.moduleEnum.value]);
                        }

                        this.ALL_CLIENT_LOYALTIES.forEach((clientLoyaltyLevel) => {
                            const loyaltyConfig = response.data.loyaltyConfigs.data.find((lc) => lc.level === clientLoyaltyLevel.value);

                            if (typeof loyaltyConfig !== "undefined") {
                                this.loyaltyConfigs.push({
                                    ...loyaltyConfig,
                                    enabled: true,
                                });
                            } else {
                                this.loyaltyConfigs.push({
                                    level: clientLoyaltyLevel.value,
                                    starts_from: null,
                                    enabled: false,
                                });
                            }
                        });
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        getFormattedData() {
            const data = {
                loyaltyConfigs: this.loyaltyConfigs.filter((loyaltyConfig) => loyaltyConfig.enabled),
            };

            if (this.moduleEnum.value === this.MODULE_TYPE_BOOKING.value) {
                data["booking"] = this.booking;
            }

            return data;
        },
        saveLoyaltyConfigs() {
            this.isLoading = true;
            this.errors.form = null;

            this.httpPut(`/api/restaurants/${this.restaurantId}/clients/loyaltyConfigs/${this.moduleEnum.value}`, this.getFormattedData()).finally(
                () => (this.isLoading = false)
            );
        },
    },
    created() {
        this.fetchLoyaltyConfigs();
    },
    watch: {
        activeLoyaltyLevels(newVal) {
            if (newVal.length < 1) {
                this.booking.disable_footprint_loyalty_level = false;
                this.booking.disable_footprint_from_loyalty_level = null;
            }
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
};
</script>
