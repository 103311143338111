var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.sale.gv_product
        ? [
            _vm.sale.gv_product.type === "menu"
              ? _c("span", { staticClass: "d-block" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.sale.menu_quantity) +
                      " x " +
                      _vm._s(_vm.sale.gv_product.name) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.sale.gv_product.type === "amount"
              ? _c("span", { staticClass: "d-block" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.sale.gv_product.name) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.sale.gv_sale_product_options.data, function (option) {
              return _c(
                "span",
                { key: option.id, staticClass: "d-block ml-3" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(option.quantity) +
                      " x " +
                      _vm._s(option.gv_products_option.name) +
                      "\n        "
                  ),
                ]
              )
            }),
          ]
        : _c("span", { staticClass: "d-block" }, [
            _vm._v(_vm._s(_vm.sale.imported_product_name || "--")),
          ]),
      _vm._v(" "),
      _vm.sale.expedition_type === _vm.EXPEDITION_TYPE_POSTAL_MAIL.value
        ? _c("span", { staticClass: "d-block" }, [_vm._v("1 x Livraison")])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }