<template>
    <div class="h-100">
        <div class="h-100 d-flex align-items-center text-center" v-if="!isPublic && isBrowserInvalid">
            <div style="margin: auto">
                <h1>Navigateur non supporté</h1>
                <p>
                    Votre navigateur n'est pas compatible avec le logiciel {{ isNoShow ? "NoShow" : "Y-Services" }}, veuillez utiliser
                    <a target="_blank" href="https://www.google.com/intl/fr_fr/chrome/">Google Chrome</a> ou
                    <a target="_blank" href="https://www.mozilla.org/fr/">Mozilla Firefox</a>.
                </p>
            </div>
        </div>
        <component
            :is="layout"
            v-else-if="isPublic || (user && restaurants)"
            :restaurants="restaurants"
            :user="user"
            @reload-router-view="currentRouterViewKey++">
            <template v-if="!isPublic">
                <CardsAlertHeader v-if="cardError" :type="cardError" />
                <SubscriptionAlertHeader v-if="subscriptionsWithIssues.length > 0" />
                <template v-if="hasUnreadSeatingPlanNotifications && layout === 'booking-layout'">
                    <NoSeatingPlanRoomAlertHeader
                        v-for="notification in unreadSeatingPlanNotifications"
                        :key="notification.id"
                        :unread-seating-plan-notifications="notification" />
                </template>
                <LoaderComponent class="main-loader" v-if="loading" />
                <router-view :key="currentRouterViewKey" v-else class="h-100" v-bind="{ restaurants, user }" />
                <SubscriptionAlert :subscriptions="subscriptionsWithIssues" @close="showSubscriptionsAlert = false" v-if="showSubscriptionsAlert" />
                <MultipleCardsAlert @close="showMultipleCardsAlert = false" v-if="showMultipleCardsAlert" />
                <template v-for="newFeature in newFeatures">
                    <showNewFeatureModal
                        v-if="newFeature.show"
                        :new_feature_id="newFeature.id"
                        :key="newFeature.id"
                        @hide="$set(newFeature, 'show', false)" />
                </template>
                <AcceptDefaultCgvModal v-if="showAcceptCgvModal" @close="showAcceptCgvModal = false" />
            </template>
        </component>
        <div class="dev-mode shadow" v-if="isDevelopement()">Développement</div>
    </div>
</template>

<script>
const default_layout = "default";

import LoaderComponent from "./components/LoaderComponent.vue";
import SubscriptionAlert from "./components/Modals/Stripe/Subscriptions/Alert.vue";
import SubscriptionAlertHeader from "./components/stripe/Subscriptions/AlertHeader.vue";
import NoSeatingPlanRoomAlertHeader from "./components/alerts/Header/NoSeatingPlanRooms.vue";
import CardsAlertHeader from "./components/stripe/Customer/PaymentMethods/AlertHeader.vue";
import MultipleCardsAlert from "./components/Modals/Stripe/Customer/PaymentMethods/MultipleCardsAlert.vue";
import showNewFeatureModal from "./components/Modals/NewFeatures/showNewFeatureModal";
import RolesEnum from "./mixins/enums/RolesEnum";
import AcceptDefaultCgvModal from "./components/Modals/CGU/AcceptDefaultCgvModal";
import LangsEnum from "./mixins/enums/LangsEnum";
import BrowserUtils from "./mixins/helpers/BrowserUtils.js";

export default {
    data() {
        return {
            loading: false,
            showAcceptCgvModal: false,
            newFeatures: [],
            currentRouterViewKey: 0,
            user: null,
            isBrowserInvalid: false,
            service: "booking",
            subscriptionsWithIssues: [],
            cardError: null,
            showSubscriptionsAlert: false,
            showMultipleCardsAlert: false,
            services: [
                {
                    layout: "errors",
                    prefix: "/errors",
                    public: true,
                },
                {
                    layout: "booking-widget-page",
                    prefix: "/widget/page",
                    public: true,
                },
                {
                    layout: "booking-widget-page",
                    prefix: "/widget/reservation",
                    public: true,
                },
                {
                    layout: "booking-widget",
                    prefix: "/widget",
                    public: true,
                },
                {
                    layout: "booking-widget",
                    prefix: "/booking/public/widget",
                    public: true,
                },
                {
                    layout: "booking-widget-page",
                    prefix: "/booking/public/widget/page",
                    public: true,
                },
                {
                    layout: "booking-widget-page",
                    prefix: "/public",
                    public: true,
                },
                {
                    layout: "booking",
                    prefix: "/booking",
                    public: false,
                },
                {
                    layout: "click-and-collect",
                    prefix: "/click-and-collect",
                    public: false,
                },
                {
                    layout: "gift-vouchers",
                    prefix: "/gift-vouchers",
                    public: false,
                },
                {
                    layout: "website",
                    prefix: "/website",
                    public: false,
                },
                {
                    layout: "campaigns",
                    prefix: "/campaigns",
                    public: false,
                },
            ],
        };
    },
    mixins: [RolesEnum, LangsEnum, BrowserUtils],
    computed: {
        unreadSeatingPlanNotifications() {
            return this.$store.getters["users/unreadSeatingPlanNotifications"] || [];
        },
        hasUnreadSeatingPlanNotifications() {
            return this.unreadSeatingPlanNotifications.length > 0;
        },
        cardId() {
            return this.$store.getters["stripe/cardId"];
        },
        userRole() {
            return this.$store.getters["users/role"];
        },
        layout() {
            if (this.$route.meta && this.$route.meta.layout) {
                return `${this.$route.meta.layout}-layout`;
            }

            for (var service of this.services) {
                if (this.$route.path.startsWith(service.prefix)) {
                    return `${service.layout}-layout`;
                }
            }

            return "default-layout";
        },
        isPublic() {
            if (this.$route.meta && this.$route.meta.public) {
                return this.$route.meta.public;
            }

            for (var service of this.services) {
                if (this.$route.path.startsWith(service.prefix)) {
                    return service.public;
                }
            }

            return false;
        },
        restaurants() {
            return this.$store.getters["restaurants/restaurants"];
        },
    },
    created() {
        var promises = [];

        this.setUserGlobalLang();

        if (!this.$route.name.startsWith("public")) {
            this.isBrowserInvalid = !this.isUserAgentValid();

            this.loading = true;

            promises.push(this.fetchRestaurants());
            promises.push(this.fetchUser());
            promises.push(this.fetchSmsInfos());
            promises.push(this.fetchHasAcceptedDefaultCgvs());

            Promise.all(promises)
                .then(() => {
                    this.$store.dispatch("users/fetchHasUnreadAppUpdates");
                    this.$store.dispatch("users/fetchUnseenFailedMailsNotificationsLogs");

                    if (this.userRole !== this.ROLE_ADMIN.value && this.userRole !== this.ROLE_COURIER.value) {
                        this.checkCards();
                        this.checkSubscriptions();
                    }
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    methods: {
        fetchHasAcceptedDefaultCgvs() {
            return axios.get("/api/accepted_cgvs/default").then((response) => {
                this.showAcceptCgvModal = !response.data.result;
            });
        },
        fetchRestaurants() {
            return this.$store.dispatch("restaurants/fetchAllRestaurants", { store: true });
        },
        fetchSmsInfos() {
            return this.$store.dispatch("sms/fetchSmsInfos");
        },
        fetchUser() {
            return this.$store.dispatch("users/fetchCurrentUser").then((response) => {
                this.user = response.data;

                if (response.data.new_features && response.data.new_features.data) {
                    this.newFeatures = response.data.new_features.data.map((new_feature) => {
                        return {
                            ...new_feature,
                            show: true,
                        };
                    });
                }

                this.$store.dispatch("users/setUser", this.user);

                if (response.data.new_badges && response.data.new_badges.data) {
                    this.$store.dispatch("users/setNewBadges", response.data.new_badges.data);
                }

                this.setUserGlobalLang();

                return true;
            });
        },
        checkCards() {
            this.cardError = null;

            return this.$store
                .dispatch("stripe/fetchCustomerAllPaymentMethods")
                .then((result) => {
                    if (result.data.data.length > 1) {
                        this.showMultipleCardsAlert = true;
                    } else if (result.data.data.length === 1) {
                        const card = result.data.data[0].card;

                        if (card) {
                            const cardExpiresOn = this.getDateTime(`${card.exp_year}-${card.exp_month}-01`, false).startOf("month");
                            const nowStartOfMonth = this.getDateTime().startOf("month");

                            if (nowStartOfMonth >= cardExpiresOn) {
                                this.cardError = "card_expired";
                            } else if (nowStartOfMonth.plus({ months: 1 }) >= cardExpiresOn) {
                                this.cardError = "card_expires_soon";
                            }
                        }
                    }
                })
                .catch((error) => {
                    this.notifyError(error);
                });
        },
        checkSubscriptions() {
            this.subscriptionsWithIssues = this.$store.getters["users/getOwnerSubscriptions"].filter((subscription) => {
                return subscription.status === "past_due" || subscription.status === "unpaid";
            });

            if (this.subscriptionsWithIssues.length > 0) {
                this.showSubscriptionsAlert = true;
            }
        },
    },
    components: {
        LoaderComponent,
        SubscriptionAlert,
        SubscriptionAlertHeader,
        MultipleCardsAlert,
        CardsAlertHeader,
        showNewFeatureModal,
        AcceptDefaultCgvModal,
        NoSeatingPlanRoomAlertHeader,
    },
    watch: {
        cardId() {
            this.checkCards();
        },
    },
};
</script>
