<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="alert alert-danger" v-else-if="!website_enabled">Votre site web n'est pas activé</div>
        <template v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">Configuration du référencement</h5>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <strong>Référencement</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5 mb-2">
                                <label for="txt-website-title" class="mb-0">Title <small class="text-danger">*</small></label>
                                <small id="help-website-title" class="form-text text-muted"
                                    >Le "title" correspond au titre de votre site web tel qu'il sera affiché sur les moteurs de recherche.</small
                                >
                            </div>
                            <div class="col-md-7 mb-3">
                                <input
                                    :disabled="!has_right_to_update_config"
                                    type="text"
                                    maxlength="60"
                                    v-model="website_settings.title"
                                    class="form-control"
                                    id="txt-website-title"
                                    aria-describedby="help-website-title" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="title" />
                            </div>
                            <div class="col-md-5">
                                <label for="txt-website-meta-description" class="mb-0">Meta Description <small class="text-danger">*</small></label>
                                <small id="help-website-meta-description" class="form-text text-muted"
                                    >La "meta description" est la phrase décrivant votre activité qui sera affichée juste en dessous du "title" sur
                                    les moteurs de recherche.</small
                                >
                            </div>
                            <div class="col-md-7">
                                <TextAreaComponentOld
                                    :disabled="!has_right_to_update_config"
                                    :minNbChar="120"
                                    :maxNbChar="160"
                                    txtAreaId="txt-contact-content"
                                    :txtAreaObj="website_settings"
                                    txtAreaObjKey="meta_description"
                                    txtAreaRows="2" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="meta_description" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0 mt-3">
                <div class="col-12 d-inline-block">
                    <button type="button" class="btn btn-success btn-circle mr-1" @click="postForm()" :disabled="!has_right_to_update_config">
                        Enregistrer
                    </button>
                    <small
                        >Pour rendre vos modifications visibles sur votre site internet, pensez à "<strong>Enregistrer et publier</strong>" en
                        cliquant sur le bouton dans la navigation.</small
                    >
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent";
import ShowErrors from "../../../components/errors/ShowErrors";
import TextAreaComponentOld from "../../../components/forms/TextAreaComponentOld";

export default {
    data() {
        return {
            loading: 0,
            errors: null,
        };
    },
    props: {
        website_settings: {
            type: Object,
            required: true,
        },
        website_enabled: {
            required: true,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read_config() {
            return this.rights.includes("website.config.read");
        },
        has_right_to_update_config() {
            return this.rights.includes("website.config.update");
        },
        has_right_to_publish_config() {
            return this.rights.includes("website.config.publish");
        },
    },
    methods: {
        postForm(doNotFetch = false) {
            this.loading++;
            this.errors = null;

            return this.$store
                .dispatch("websites/updateWebsiteSettings", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        ...this.website_settings,
                        show_address: this.website_settings.show_address ? false : true,
                        section: "seo",
                    },
                })
                .then((response) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    if (!doNotFetch) this.$emit("fetch-data");
                    return true;
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                    if (error.response && error.response.data.errors && Object.keys(error.response.data.errors).length > 0)
                        this.errors = error.response.data.errors;
                    return false;
                });
        },
    },
    components: {
        ShowErrors,
        LoaderComponent,
        TextAreaComponentOld,
    },
};
</script>
