var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "row bg-light text-center text-uppercase nav-campaign" },
        [
          _c(
            "div",
            {
              staticClass: "nav-campaign-item col-sm-4 pt-3 pb-3",
              class: { active: _vm.step === 1 },
            },
            [_vm._v("1 - Composez votre message")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "nav-campaign-item col-sm-4 pt-3 pb-3",
              class: { active: _vm.step === 2 },
            },
            [_vm._v("2 - Choisissez votre cible")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "nav-campaign-item col-sm-4 pt-3 pb-3",
              class: { active: _vm.step === 3 },
            },
            [_vm._v("3 - Diffuser votre campagne")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent")
        : [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step === 1,
                    expression: "step === 1",
                  },
                ],
                staticClass: "row",
              },
              [
                _c("div", { staticClass: "col-lg-12 pt-3" }, [
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4 mb-3" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-4 mb-2" }, [
                          _c("strong", [_vm._v("Nom de la campagne")]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-8" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.campaign.name,
                                  expression: "campaign.name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.campaign.name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.campaign,
                                    "name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-errors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors,
                                "error-key": "name",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mt-3 switch-type" }, [
                        _c("div", { staticClass: "col-md-4 mb-2" }, [
                          _c("strong", [_vm._v("Type de campagne")]),
                        ]),
                        _vm._v(" "),
                        _vm.campaign.paid_at === null
                          ? _c(
                              "div",
                              { staticClass: "col-md-8" },
                              [
                                _c("input", {
                                  staticClass:
                                    "switch align-self-center is-rounded",
                                  attrs: { type: "checkbox" },
                                  domProps: { checked: _vm.smsEnabled },
                                }),
                                _vm._v("\n                            Email "),
                                _c("label", {
                                  on: {
                                    click: function ($event) {
                                      _vm.smsEnabled = !_vm.smsEnabled
                                    },
                                  },
                                }),
                                _vm._v(" SMS\n                            "),
                                _c("show-errors", {
                                  staticClass: "d-block",
                                  attrs: {
                                    errors: _vm.errors,
                                    "error-key": "type",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("div", { staticClass: "col-md-8" }, [
                              _vm._v("SMS"),
                            ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step === 1,
                    expression: "step === 1",
                  },
                ],
                staticClass: "row",
              },
              [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4 mb-3" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("strong", [_vm._v("Composez votre message")]),
                        ]),
                      ]),
                      _vm._v(" "),
                      !_vm.smsEnabled
                        ? [
                            _c("div", { staticClass: "row mt-3" }, [
                              _c("div", { staticClass: "col-md-4 pt-2 mb-2" }, [
                                _vm._v("Objet *"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-8" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.campaign.mail_object,
                                        expression: "campaign.mail_object",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.campaign.mail_object,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.campaign,
                                          "mail_object",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors,
                                      "error-key": "mail_object",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-2" }, [
                              _c("div", { staticClass: "col-md-4 pt-2 mb-2" }, [
                                _c("span", { staticClass: "d-block mb-1" }, [
                                  _vm._v("Message *"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "badge badge-secondary mb-1",
                                    staticStyle: { border: "none" },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addToMessage("[FIRSTNAME]")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Insérer le prénom du client\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "badge badge-secondary mb-1",
                                    staticStyle: { border: "none" },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addToMessage("[LASTNAME]")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Insérer le nom du client\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-8" },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.campaign.content,
                                        expression: "campaign.content",
                                      },
                                    ],
                                    ref: "txtEmailBody",
                                    staticClass: "form-control",
                                    staticStyle: { "font-size": "0.75rem" },
                                    attrs: { rows: "8" },
                                    domProps: { value: _vm.campaign.content },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.campaign,
                                          "content",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors,
                                      "error-key": "content",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-2" }, [
                              _c("div", { staticClass: "col-md-4 mb-2" }, [
                                _vm._v("Image"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-8" },
                                [
                                  _c("ImageComponent", {
                                    attrs: {
                                      src: _vm.campaign.img_path,
                                      suffixId: "campaign",
                                      postURI: _vm.imgPostUri,
                                      maxSize: 10,
                                      callBack: (response) => {
                                        this.campaign.img_path =
                                          response.data.img_path
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-2" }, [
                              _c("div", { staticClass: "col-md-4 pt-2 mb-2" }, [
                                _vm._v("Lien au clique sur l'image"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-8" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.campaign.img_link,
                                        expression: "campaign.img_link",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.campaign.img_link },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.campaign,
                                          "img_link",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors,
                                      "error-key": "img_link",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : [
                            _c("div", { staticClass: "row mt-3" }, [
                              _c("div", { staticClass: "col-md-5" }, [
                                _c("label", { staticClass: "mb-1" }, [
                                  _vm._v(
                                    "Nom affiché au client, à la réception : *"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("small", { staticClass: "d-block" }, [
                                  _vm._v("11 caractères maximum"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.campaign.sms_expeditor,
                                        expression: "campaign.sms_expeditor",
                                      },
                                    ],
                                    staticClass: "form-control mb-2",
                                    class: {
                                      "is-invalid":
                                        !_vm.campaign.sms_expeditor.match(
                                          /^[a-z]+$/i
                                        ),
                                    },
                                    attrs: { type: "text", maxlength: "11" },
                                    domProps: {
                                      value: _vm.campaign.sms_expeditor,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.campaign,
                                          "sms_expeditor",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    staticClass: "d-block pl-2",
                                    attrs: {
                                      errors: _vm.errors,
                                      "error-key": "sms_expeditor",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "limiter text-muted pl-2" },
                                    [
                                      _vm._v(
                                        "Il vous reste " +
                                          _vm._s(_vm.sms_emitter_name_count) +
                                          " caractère" +
                                          _vm._s(
                                            _vm.sms_emitter_name_count > 1
                                              ? "s"
                                              : ""
                                          )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "small",
                                    { staticClass: "text-danger d-block pl-2" },
                                    [
                                      _vm._v(
                                        "Attention : seule les lettres minuscules et majuscules sont autorisées, les caractères spéciaux, chiffres,\n                                    espaces, ou lettres accentuées ne sont pas acceptés par notre prestataire d'envoi de SMS."
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-3" }, [
                              _c("div", { staticClass: "col-md-5 pt-2 mb-2" }, [
                                _vm._v("Message *"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.campaign.content,
                                        expression: "campaign.content",
                                      },
                                    ],
                                    staticClass: "form-control mb-2",
                                    attrs: {
                                      disabled: _vm.campaign.paid_at !== null,
                                      rows: "4",
                                      maxlength: "469",
                                    },
                                    domProps: { value: _vm.campaign.content },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.campaign,
                                          "content",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.doesSmsContainEmojis
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-danger font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "errors.common.sms.emojisNotAllowed"
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "limiter text-muted pl-2" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.sms_message_count) +
                                          " caractère" +
                                          _vm._s(
                                            _vm.sms_message_count > 1 ? "s" : ""
                                          )
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                                /\n                                "
                                  ),
                                  _vm.sms_message_count <= 149
                                    ? _c(
                                        "span",
                                        { staticClass: "text-muted" },
                                        [_vm._v("1 crédit")]
                                      )
                                    : _vm.sms_message_count <= 309
                                    ? _c(
                                        "span",
                                        { staticClass: "text-muted" },
                                        [_vm._v("2 crédits")]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "text-muted" },
                                        [_vm._v("3 crédits")]
                                      ),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors,
                                      "error-key": "content",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                    ],
                    2
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step === 2,
                    expression: "step === 2",
                  },
                ],
                staticClass: "row",
              },
              [
                _c("div", { staticClass: "col-lg-12 pt-3" }, [
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4 mb-3" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("strong", [_vm._v("Choisissez votre cible")]),
                            _vm._v(" "),
                            _c("show-errors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors,
                                "error-key": "targets",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.campaign.paid_at !== null
                        ? _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12 text-danger" }, [
                              _vm._v(
                                "Désolé, vous ne pouvez pas modifiez vos cibles une fois la campagne payée"
                              ),
                            ]),
                          ])
                        : _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-5 mt-3" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _c("div", { staticClass: "col-9 pt-1" }, [
                                      _vm._v(
                                        "Tous mes clients (" +
                                          _vm._s(_vm.clients.all) +
                                          ")"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-3" }, [
                                      _c(
                                        "label",
                                        { staticClass: "container-box" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.hasCheckedAllFilters,
                                                expression:
                                                  "hasCheckedAllFilters",
                                              },
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.hasCheckedAllFilters
                                              )
                                                ? _vm._i(
                                                    _vm.hasCheckedAllFilters,
                                                    null
                                                  ) > -1
                                                : _vm.hasCheckedAllFilters,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.hasCheckedAllFilters,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.hasCheckedAllFilters =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.hasCheckedAllFilters =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.hasCheckedAllFilters = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("show-errors", {
                                      staticClass: "col-12",
                                      attrs: {
                                        errors: _vm.errors,
                                        "error-key": "client_types",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "row mt-3" },
                                  [
                                    _c("div", { staticClass: "col-9 pt-1" }, [
                                      _vm._v(
                                        "Inclure les clients VIP (" +
                                          _vm._s(_vm.clients.vip) +
                                          ")"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-3" }, [
                                      _c(
                                        "label",
                                        { staticClass: "container-box" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.campaign.include_vip,
                                                expression:
                                                  "campaign.include_vip",
                                              },
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.campaign.include_vip
                                              )
                                                ? _vm._i(
                                                    _vm.campaign.include_vip,
                                                    null
                                                  ) > -1
                                                : _vm.campaign.include_vip,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.campaign.include_vip,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.campaign,
                                                        "include_vip",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.campaign,
                                                        "include_vip",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.campaign,
                                                    "include_vip",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("show-errors", {
                                      staticClass: "col-12",
                                      attrs: {
                                        errors: _vm.errors,
                                        "error-key": "include_vip",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "row mt-3" },
                                  [
                                    _c("div", { staticClass: "col-9 pt-1" }, [
                                      _vm._v(
                                        "Inclure les clients à risque (" +
                                          _vm._s(_vm.clients.risky) +
                                          ")"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-3" }, [
                                      _c(
                                        "label",
                                        { staticClass: "container-box" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.campaign.include_risky,
                                                expression:
                                                  "campaign.include_risky",
                                              },
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.campaign.include_risky
                                              )
                                                ? _vm._i(
                                                    _vm.campaign.include_risky,
                                                    null
                                                  ) > -1
                                                : _vm.campaign.include_risky,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.campaign.include_risky,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.campaign,
                                                        "include_risky",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.campaign,
                                                        "include_risky",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.campaign,
                                                    "include_risky",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("show-errors", {
                                      staticClass: "col-12",
                                      attrs: {
                                        errors: _vm.errors,
                                        "error-key": "include_vip",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.availableClientTypes,
                                  function (clientType, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: clientType,
                                        staticClass: "row mt-3",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col-9 pt-1" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  clientType === "no_status"
                                                    ? "Aucune typologie attribuée"
                                                    : _vm.getClientTypeLabel(
                                                        clientType
                                                      )
                                                ) +
                                                " (" +
                                                _vm._s(
                                                  _vm.clients.types[clientType]
                                                ) +
                                                ")\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-3" }, [
                                          _c(
                                            "label",
                                            { staticClass: "container-box" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.campaign.client_types,
                                                    expression:
                                                      "campaign.client_types",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  name: "client_types[]",
                                                },
                                                domProps: {
                                                  value: clientType,
                                                  checked: Array.isArray(
                                                    _vm.campaign.client_types
                                                  )
                                                    ? _vm._i(
                                                        _vm.campaign
                                                          .client_types,
                                                        clientType
                                                      ) > -1
                                                    : _vm.campaign.client_types,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.campaign
                                                          .client_types,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = clientType,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.campaign,
                                                            "client_types",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.campaign,
                                                            "client_types",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.campaign,
                                                        "client_types",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("show-errors", {
                                          staticClass: "col-12",
                                          attrs: {
                                            errors: _vm.errors,
                                            "error-key": `client_types.${index}`,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mt-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _c("vue-multiselect", {
                                        attrs: {
                                          options: _vm.availableLoyaltyLevels,
                                          multiple: true,
                                          label: "label",
                                          "track-by": "value",
                                          placeholder: "Niveau de fidélité",
                                          searchable: false,
                                          "close-on-select": false,
                                          "show-labels": false,
                                          "open-direction": "bottom",
                                        },
                                        model: {
                                          value: _vm.selectedLoyaltyLevels,
                                          callback: function ($$v) {
                                            _vm.selectedLoyaltyLevels = $$v
                                          },
                                          expression: "selectedLoyaltyLevels",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-7 mt-3" },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-9 pt-1" }, [
                                    _vm._v("Filtres avancés"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-3" }, [
                                    _c("input", {
                                      staticClass:
                                        "switch align-self-center is-rounded",
                                      attrs: { type: "checkbox" },
                                      domProps: { checked: _vm.advanceFilters },
                                    }),
                                    _vm._v(" "),
                                    _c("label", {
                                      on: {
                                        click: function ($event) {
                                          _vm.advanceFilters =
                                            !_vm.advanceFilters
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.advanceFilters
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "row mt-3" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-9 pt-1" },
                                            [_vm._v("Ayant une réservation")]
                                          ),
                                          _vm._v(" "),
                                          _c("checkbox-input-component", {
                                            staticClass: "col-3",
                                            attrs: {
                                              "error-key": "at_least_one_resa",
                                            },
                                            model: {
                                              value:
                                                _vm.campaign.at_least_one_resa,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.campaign,
                                                  "at_least_one_resa",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "campaign.at_least_one_resa",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.campaign.at_least_one_resa
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "row mt-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-9 pt-1" },
                                                  [_vm._v("Réservation passée")]
                                                ),
                                                _vm._v(" "),
                                                _c("checkbox-input-component", {
                                                  staticClass: "col-3",
                                                  attrs: {
                                                    "error-key":
                                                      "at_least_one_past_resa",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.campaign
                                                        .at_least_one_past_resa,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.campaign,
                                                        "at_least_one_past_resa",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "campaign.at_least_one_past_resa",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "row mt-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-9 pt-1" },
                                                  [_vm._v("Réservation future")]
                                                ),
                                                _vm._v(" "),
                                                _c("checkbox-input-component", {
                                                  staticClass: "col-3",
                                                  attrs: {
                                                    "error-key":
                                                      "at_least_one_future_resa",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.campaign
                                                        .at_least_one_future_resa,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.campaign,
                                                        "at_least_one_future_resa",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "campaign.at_least_one_future_resa",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "row mt-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-9 pt-1" },
                                                  [
                                                    _vm._v(
                                                      "Réservation dans l'établissement " +
                                                        _vm._s(
                                                          _vm.restaurantName
                                                        ) +
                                                        " uniquement"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("checkbox-input-component", {
                                                  staticClass: "col-3",
                                                  attrs: {
                                                    "error-key":
                                                      "at_least_one_resa_in_restaurant",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.campaign
                                                        .at_least_one_resa_in_restaurant,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.campaign,
                                                        "at_least_one_resa_in_restaurant",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "campaign.at_least_one_resa_in_restaurant",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.smsEnabled
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "row mt-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-9" },
                                                  [
                                                    _c(
                                                      "label",
                                                      { staticClass: "mb-1" },
                                                      [
                                                        _vm._v(
                                                          "Envoyer la campagne aux numéros étrangers"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass: "d-block",
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.showInternationalSmsCostModal = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("small", [
                                                          _vm._v(
                                                            "Coût en crédits des sms à l'étranger"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-3" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "container-box",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.campaign
                                                                  .send_to_foreign_numbers,
                                                              expression:
                                                                "campaign.send_to_foreign_numbers",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "checkbox",
                                                            "true-value": "1",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              Array.isArray(
                                                                _vm.campaign
                                                                  .send_to_foreign_numbers
                                                              )
                                                                ? _vm._i(
                                                                    _vm.campaign
                                                                      .send_to_foreign_numbers,
                                                                    null
                                                                  ) > -1
                                                                : _vm._q(
                                                                    _vm.campaign
                                                                      .send_to_foreign_numbers,
                                                                    "1"
                                                                  ),
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm.campaign
                                                                    .send_to_foreign_numbers,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? "1"
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.campaign,
                                                                      "send_to_foreign_numbers",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.campaign,
                                                                      "send_to_foreign_numbers",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.campaign,
                                                                  "send_to_foreign_numbers",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", {
                                                          staticClass:
                                                            "checkmark",
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("show-errors", {
                                                      staticClass: "d-block",
                                                      attrs: {
                                                        errors: _vm.errors,
                                                        "error-key":
                                                          "send_to_foreign_numbers",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step === 3,
                    expression: "step === 3",
                  },
                ],
                staticClass: "row",
              },
              [
                _c("div", { staticClass: "col-lg-12 pt-3" }, [
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4 mb-3" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("strong", [_vm._v("Diffuser votre campagne")]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("div", { staticClass: "row mb-2 radio mt-3" }, [
                              _c("div", { staticClass: "col-12 pt-2" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "container-box",
                                    staticStyle: {
                                      display: "block",
                                      width: "100%",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.campaign.save_type,
                                          expression: "campaign.save_type",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "civility",
                                        value: "send",
                                        tabindex: "5",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.campaign.save_type,
                                          "send"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.campaign,
                                            "save_type",
                                            "send"
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "checkmark" }),
                                    _vm._v(
                                      " Envoyer maintenant\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "container-box",
                                    staticStyle: {
                                      display: "block",
                                      width: "100%",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.campaign.save_type,
                                          expression: "campaign.save_type",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "civility",
                                        value: "save",
                                        tabindex: "6",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.campaign.save_type,
                                          "save"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.campaign,
                                            "save_type",
                                            "save"
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "checkmark" }),
                                    _vm._v(
                                      " Enregistrer sans envoyer\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "container-box",
                                    staticStyle: {
                                      display: "block",
                                      width: "100%",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.campaign.save_type,
                                          expression: "campaign.save_type",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        name: "civility",
                                        value: "prog",
                                        tabindex: "6",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.campaign.save_type,
                                          "prog"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.campaign,
                                            "save_type",
                                            "prog"
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "checkmark" }),
                                    _vm._v(
                                      " Programmer l'envoi\n                                    "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.campaign.save_type === "prog"
                              ? [
                                  _c("div", { staticClass: "row mt-3" }, [
                                    _c("div", { staticClass: "col-md-5" }, [
                                      _vm._v("Date"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-7" },
                                      [
                                        _c("datepicker", {
                                          attrs: {
                                            format: "dd/MM/yyyy",
                                            "disabled-dates": _vm.disabledDates,
                                            "calendar-class":
                                              "positition-relative mt-2",
                                            "input-class": "w-auto",
                                            "monday-first": true,
                                            language: _vm.fr,
                                          },
                                          model: {
                                            value: _vm.willBeSendAtDate,
                                            callback: function ($$v) {
                                              _vm.willBeSendAtDate = $$v
                                            },
                                            expression: "willBeSendAtDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row mt-2" }, [
                                    _c("div", { staticClass: "col-md-5" }, [
                                      _vm._v("Heure"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-7" },
                                      [
                                        _c("vue-timepicker", {
                                          attrs: {
                                            format: "HH:mm",
                                            "minute-interval": 15,
                                            "hide-disabled-items": true,
                                            "hour-range": _vm.hourRange,
                                            lazy: true,
                                          },
                                          on: { change: _vm.dateOrHourChanged },
                                          model: {
                                            value: _vm.willBeSendAtHour,
                                            callback: function ($$v) {
                                              _vm.willBeSendAtHour = $$v
                                            },
                                            expression: "willBeSendAtHour",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-6 mt-3" },
                          [
                            _vm.smsEnabled
                              ? [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-12 mb-1" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-success btn-circle btn-sm",
                                          attrs: {
                                            disabled:
                                              _vm.campaign.paid_at !== null ||
                                              !_vm.has_right_to_buy_sms ||
                                              _vm.campaign.is_paying == 1,
                                            type: "button",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showPurchaseSmsCampaignModalFct()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Payer la campagne\n                                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.campaign.is_paying
                                        ? _c(
                                            "small",
                                            {
                                              staticClass:
                                                "d-block text-warning",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            Une tentative de paiement est en cours.\n                                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _vm._v(
                                        "Envoyer un SMS de test (" +
                                          _vm._s(
                                            _vm.campaign.nb_sms_test_remaining
                                          ) +
                                          " restant(s))"
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row mt-2" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.campaign.tel_test,
                                            expression: "campaign.tel_test",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "phone",
                                          name: "tel",
                                          placeholder:
                                            "Votre numéro de teléphone",
                                        },
                                        domProps: {
                                          value: _vm.campaign.tel_test,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.campaign,
                                              "tel_test",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _vm.campaign.nb_sms_test_remaining >= 1
                                    ? _c("div", { staticClass: "row mt-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 text-danger" },
                                          [
                                            _vm._v(
                                              "Merci de saisir un numéro français et de ne pas répondre au SMS"
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row mt-2" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-success btn-circle",
                                          attrs: {
                                            disabled:
                                              _vm.campaign
                                                .nb_sms_test_remaining < 1,
                                            type: "button",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit("sendSmsTest")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Envoyer\n                                        "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              : [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-8 mb-1" }, [
                                      _vm._v("Nombre d'emails disponibles :"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        class:
                                          _vm.credits.nb_clients >
                                          _vm.credits
                                            .nb_campaign_emails_remaining
                                            ? "col-4 text-danger"
                                            : "col-4",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.credits
                                                .nb_campaign_emails_remaining
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-8 mb-1" }, [
                                      _vm._v("Nombre de cibles potentielles :"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-4" }, [
                                      _vm._v(_vm._s(_vm.credits.nb_clients)),
                                    ]),
                                    _vm._v(" "),
                                    _vm.credits.nb_clients >
                                    _vm.credits.nb_campaign_emails_remaining
                                      ? _c("div", { staticClass: "col-12" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-success btn-circle btn-sm",
                                              attrs: {
                                                type: "button",
                                                disabled:
                                                  !_vm.has_right_to_buy_sms ||
                                                  _vm.is_paying_emails_campaign ==
                                                    1,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.showPurchaseEmailsCampaignModal = true
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            Acheter des crédits emails\n                                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.is_paying_emails_campaign
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "d-block text-warning mt-1",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            Une tentative d'achat d'un pack d'emails de campagne est en cours\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row mt-2" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _vm._v("Envoyer un EMAIL de test"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row mt-2" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.campaign.email_test,
                                            expression: "campaign.email_test",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "email",
                                          name: "email",
                                          placeholder: "Votre email",
                                        },
                                        domProps: {
                                          value: _vm.campaign.email_test,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.campaign,
                                              "email_test",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row mt-2" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-success btn-circle",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit("sendEmailTest")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Envoyer\n                                        "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ],
                            _vm._v(" "),
                            _vm.campaign.save_type === "prog"
                              ? _c("show-errors", {
                                  staticClass: "d-block mt-4",
                                  attrs: {
                                    errors: _vm.errors,
                                    "error-key": "will_be_send_at",
                                  },
                                })
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.smsEnabled
                        ? _c("div", { staticClass: "row mt-2" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("small", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                                Pour information, les envois de campagnes vers des numéros français ne sont pas autorisés le dimanche, les jours\n                                fériés et la nuit entre 22h et 8h\n                            "
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            ),
          ],
      _vm._v(" "),
      _vm.showInternationalSmsCostModal
        ? _c("InternationalSmsCostModal", {
            on: {
              close: function ($event) {
                _vm.showInternationalSmsCostModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPurchaseEmailsCampaignModal
        ? _c("PurchaseEmailsCampaignPackModal", {
            on: {
              close: function ($event) {
                _vm.showPurchaseEmailsCampaignModal = false
              },
              paid: _vm.emailsCampaignPackPaid,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPurchaseSmsCampaignModal
        ? _c("PurchaseSmsCampaignModal", {
            attrs: { campaign_id: _vm.campaign.id },
            on: {
              close: function ($event) {
                _vm.showPurchaseSmsCampaignModal = false
              },
              paid: _vm.smsCampaignPaid,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }