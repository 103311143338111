<template>
    <div class="row mb-3">
        <div class="col-12">
            <div class="border-light b-radius-20 p-4">
                <div class="row">
                    <div
                        class="col-12 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb- pt-2 mt-1">
                        <div>
                            <strong
                                ><a href="https://www.sendcloud.fr/" class="text-dark" target="_blank">Sendcloud</a>
                            </strong>
                        </div>
                        <div>
                            <button @click="showTuto = !showTuto" class="btn btn-sm btn-success btn-circle">
                                Lire les informations d'intégration
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row mt-2 mb-3">
                    <!-- <div class="col-12 pointer" @click="showTuto = !showTuto">Voir le tutoriel d'intégration</div> -->
                    <div class="col-12 border-light p-4" v-if="showTuto">
                        <ol class="list-style-inherit m-0">
                            <li>
                                Pour commencer, créez vous un compte sur
                                <a href="https://panel.sendcloud.sc/accounts/signup/" target="_blank"
                                    ><strong>Sendcloud</strong></a
                                >.
                            </li>
                            <li>
                                Une fois votre compte créé, vous devez créer une nouvelle boutique en vous rendant dans
                                "Réglages" > "Boutiques connectées" > "Sendcloud API" > "Se connecter", ou depuis
                                <a href="https://panel.sendcloud.sc/#/settings/integrations/api/add" target="_blank"
                                    ><strong>ce lien</strong></a
                                >
                                :
                                <ul class="list-style-inherit m-0">
                                    <li>Saisissez le nom de votre boutique</li>
                                    <li>Cochez la case "Feedback des Webhooks activé"</li>
                                    <li>
                                        Dans le champ "Webhook url", entrez cette adresse :
                                        https://app.noshow.io/api/click_and_collect/sendcloud-webhook
                                    </li>
                                    <li>
                                        Cochez la case "Points Relais" (Carriers en anglais) si vous le désirez (vous
                                        pourrez ajouter d'autres transporteurs ultérieurement)
                                    </li>
                                    <li>Cliquez sur "Enregistrer"</li>
                                </ul>
                            </li>
                            <li>
                                Une fois votre boutique créée, deux clés constituées de chiffres et de lettres
                                apparaissent à votre écran. Reportez-les dans les champs ci-dessous.
                            </li>
                            <li>
                                Afin de pouvoir générer des étiquettes : .
                                <ul class="list-style-inherit m-0">
                                    <li>
                                        Vous devez ajouter l'adresse d'expédition des colis en vous rendant dans
                                        "Réglages" > "Mes adresses" > "Adresse de l'expéditeur" > "Ajouter", ou depuis
                                        <a
                                            href="https://panel.sendcloud.sc/#/settings/addresses/sender/add"
                                            target="_blank"
                                            ><strong>ce lien</strong></a
                                        >
                                    </li>
                                    <li>
                                        Vous devez activer le prélèvement automatique
                                        <a
                                            href="https://panel.sendcloud.sc/#/settings/financial/payments/direct-debit"
                                            target="_blank"
                                            ><strong>depuis ce lien.</strong></a
                                        >
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Optionnellement, vous pouvez activer des transporteurs supplémentaires depuis "Réglages"
                                > "Transporteurs & Prix". Attention, quand un transporteur est activé, si vous avez
                                activé les points relais, vous devez aussi cocher la case correspondante dans
                                <a href="https://panel.sendcloud.sc/#/settings/integrations/manage" target="_blank"
                                    ><strong>l'édition de boutique</strong></a
                                >
                                ("Réglages" > "Boutiques connectées" > "Éditer").
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="row mt-1 mb-2">
                    <div class="col-md-5">Clé publique</div>
                    <div class="col-md-7">
                        <input type="text" v-model="sendcloud_public_key_" class="form-control" />
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-5">Clé confidentielle</div>
                    <div class="col-md-7">
                        <input type="text" v-model="sendcloud_private_key_" class="form-control" />
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-5">Poids fixe</div>
                    <div class="col-md-7">
                        <input type="number" v-model="base_weight_" class="form-control w-auto d-inline-block" /><span
                            class="ml-2"
                            >gramme(s)</span
                        >
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-5">Montant minimum de commande</div>
                    <div class="col-md-7">
                        <input
                            type="number"
                            v-model="dispatch_min_amount_"
                            class="form-control w-auto d-inline-block" /><span class="ml-2">€</span>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-5">Expédition gratuite à partir de</div>
                    <div class="col-md-7">
                        <input
                            type="number"
                            v-model="dispatch_free_above_"
                            class="form-control w-auto d-inline-block" /><span class="ml-2">€</span>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-5 pt-1">
                        <label>Activer la livraison en point relais</label>
                    </div>
                    <div class="col-md-7 pt-1">
                        <div class="d-inline-block align-middle lead-switch">
                            <input
                                type="checkbox"
                                class="switch align-self-center is-rounded"
                                v-model="enable_service_points_" />
                            <label @click="enable_service_points_ = !enable_service_points_"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showTuto: false,
        };
    },
    props: {
        sendcloud_public_key: {
            default: null,
        },
        sendcloud_private_key: {
            default: null,
        },
        base_weight: {
            default: null,
        },
        dispatch_min_amount: {
            default: null,
        },
        dispatch_free_above: {
            default: null,
        },
        enable_service_points: {
            default: false,
        },
    },
    computed: {
        sendcloud_public_key_: {
            get() {
                return this.sendcloud_public_key;
            },
            set(newVal) {
                this.$emit("sendcloud-public-key", newVal);
            },
        },
        sendcloud_private_key_: {
            get() {
                return this.sendcloud_private_key;
            },
            set(newVal) {
                this.$emit("sendcloud-private-key", newVal);
            },
        },
        base_weight_: {
            get() {
                return this.base_weight;
            },
            set(newVal) {
                this.$emit("base-weight", newVal);
            },
        },
        dispatch_min_amount_: {
            get() {
                return this.dispatch_min_amount ? this.dispatch_min_amount / 100 : this.dispatch_min_amount;
            },
            set(newVal) {
                this.$emit("dispatch-min-amount", newVal ? newVal * 100 : newVal);
            },
        },
        dispatch_free_above_: {
            get() {
                return this.dispatch_free_above ? this.dispatch_free_above / 100 : this.dispatch_free_above;
            },
            set(newVal) {
                this.$emit("dispatch-free-above", newVal ? newVal * 100 : newVal);
            },
        },
        enable_service_points_: {
            get() {
                return this.enable_service_points;
            },
            set(newVal) {
                this.$emit("enable-service-points", newVal);
            },
        },
    },
    methods: {},
};
</script>

<style scoped>
ol.list-style-inherit > li,
ul.list-style-inherit > li {
    list-style: inherit !important;
}
</style>
