<template>
    <div class="row m-0">
        <tab-bar
            class="border-right col-xl-2 pr-0"
            is-event-mode
            side="left"
            direction="vertical"
            :entries="tabBarEntries"
            @set-active="active = $event" />
        <div class="sepa d-xl-none"></div>
        <component class="col-xl-10 p-3" :is="activeComponent" :module-enum="moduleEnum" :restaurant-id="restaurantId" />
    </div>
</template>

<script>
import TabBar from "../../tabBar/TabBar.vue";
import restaurantSpecificRules from "./loyaltyConfigs/restaurantSpecificRules.vue";
import allRules from "./loyaltyConfigs/allRules.vue";

export default {
    data() {
        return {
            active: null,
        };
    },
    props: {
        moduleEnum: {
            type: Object,
            required: true,
        },
        restaurantId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        activeComponent() {
            return this.active !== null && this.tabBarEntries.length > this.active ? this.tabBarEntries[this.active].component : undefined;
        },
        tabBarEntries() {
            return [
                {
                    title: this.$tl("labels.clients.loyaltyConfigs.specificRestaurant"),
                    component: restaurantSpecificRules,
                },
                {
                    title: this.$tl("labels.clients.loyaltyConfigs.allRestaurants"),
                    component: allRules,
                },
            ];
        },
    },
    components: {
        TabBar,
        restaurantSpecificRules,
        allRules,
    },
};
</script>

<style scoped>
.mobile-sepa {
    display: none;
}

@media screen and (max-width: 480px) {
    .mobile-sepa {
        display: block;
    }
}
</style>
