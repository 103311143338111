<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="row" v-else>
            <div class="col-12">
                <div class="border-light b-radius-20 p-4">
                    <div class="row mb-2">
                        <div class="col-5 pt-2">
                            <label for="name">Nom <small>*</small></label>
                        </div>
                        <div class="col-7">
                            <input type="text" v-model="closure.name" required class="form-control" />
                            <show-errors class="d-block" :errors="errors" errorKey="name" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-5 pt-2">
                            <label>Date de début <small>*</small></label>
                        </div>
                        <div class="col-7 date-resa-cal">
                            <datepicker
                                tabindex="1"
                                :monday-first="true"
                                v-model="closure.start_day"
                                :language="fr"
                                :disabledDates="disabledFromDates" />
                            <show-errors class="d-block" :errors="errors" errorKey="start_day" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-5 pt-2">
                            <label>Date de fin <small>*</small></label>
                        </div>
                        <div class="col-7 date-resa-cal">
                            <datepicker
                                tabindex="1"
                                :monday-first="true"
                                v-model="closure.end_day"
                                :language="fr"
                                :disabledDates="disabledToDates" />
                            <show-errors class="d-block" :errors="errors" errorKey="end_day" />
                        </div>
                    </div>
                    <div class="row mt-2 mb-2">
                        <div class="col-5">
                            <label> Services concernés <small>*</small> </label>
                        </div>
                        <div class="col-7">
                            <div v-if="availableCollectTypes.length > 0">
                                <show-errors class="d-block" :errors="errors" errorKey="collect_types" />
                                <show-errors class="d-block" :errors="errors" errorKey="collect_types.*" />
                                <div v-for="collectType in availableCollectTypes" :key="collectType">
                                    <label class="container-box" style="width: 100%; text-transform: capitalize">
                                        <input
                                            type="checkbox"
                                            name="collect_types[]"
                                            v-model="closure.collect_types"
                                            :value="collectType" />
                                        <span class="checkmark"></span> {{ getCollectTypeLabel(collectType) }}
                                    </label>
                                    <template v-if="closure.collect_types.includes(collectType)">
                                        <div class="row" v-if="collectType === COLLECT_TYPE_COLLECT.value">
                                            <div class="col-md-4">
                                                <label>Créneaux <small>*</small></label>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="radio">
                                                            <label class="container-box">
                                                                <input
                                                                    type="radio"
                                                                    v-model="closure.all_click_and_collect_slots"
                                                                    :value="1" />
                                                                <span class="checkmark"></span> Tous
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 d-inline mt-1">
                                                        <div class="radio">
                                                            <label
                                                                class="container-box d-inline-block pt-0 mt-2"
                                                                style="width: initial">
                                                                <input
                                                                    type="radio"
                                                                    v-model="closure.all_click_and_collect_slots"
                                                                    :value="0" />
                                                                <span class="checkmark"></span> Personnaliser
                                                            </label>
                                                        </div>
                                                        <show-errors
                                                            class="d-block"
                                                            :errors="errors"
                                                            errorKey="collect_slots.data" />
                                                        <div
                                                            v-if="!closure.all_click_and_collect_slots"
                                                            class="row mt-3">
                                                            <div class="col-12" v-for="day in allDays" :key="day">
                                                                <div class="mb-2">
                                                                    <label
                                                                        class="container-box"
                                                                        style="width: 100%; text-transform: capitalize">
                                                                        <input
                                                                            type="checkbox"
                                                                            name="collect_days_active[]"
                                                                            v-model="closureFrontData.collectDaysActive"
                                                                            :value="day" />
                                                                        <span class="checkmark"></span>
                                                                        {{ dayLabel(day) }}
                                                                    </label>
                                                                </div>
                                                                <div
                                                                    class="setting-open"
                                                                    v-if="
                                                                        closureFrontData.collectDaysActive.includes(day)
                                                                    ">
                                                                    <div class="setting-hours">
                                                                        <span>
                                                                            <div
                                                                                v-for="service in getServicesForDay(
                                                                                    day
                                                                                )"
                                                                                :key="service.id">
                                                                                <input
                                                                                    type="text"
                                                                                    class="form-control d-inline-block"
                                                                                    style="max-width: 60px"
                                                                                    :disabled="true"
                                                                                    :value="service.start_hour" />
                                                                                <input
                                                                                    type="text"
                                                                                    class="form-control d-inline-block"
                                                                                    style="max-width: 60px"
                                                                                    :disabled="true"
                                                                                    :value="service.end_hour" />
                                                                                <label
                                                                                    v-for="slot in service.slots"
                                                                                    :key="slot.id"
                                                                                    class="container-box ml-4"
                                                                                    style="width: initial">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name="collect_slots[]"
                                                                                        v-model="
                                                                                            closure.collect_slots.data
                                                                                        "
                                                                                        :value="slot.id" />
                                                                                    <span class="checkmark"></span>
                                                                                    {{
                                                                                        slot.hour
                                                                                            .replace(":", "h")
                                                                                            .substr(0, 5)
                                                                                    }}
                                                                                </label>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-else-if="collectType === COLLECT_TYPE_DELIVERY.value">
                                            <div class="col-md-5">
                                                <label>Créneaux <small>*</small></label>
                                            </div>
                                            <div class="col-md-7">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="radio">
                                                            <label class="container-box">
                                                                <input
                                                                    type="radio"
                                                                    v-model="closure.all_delivery_slots"
                                                                    :value="1" />
                                                                <span class="checkmark"></span> Tous
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 d-inline mt-1">
                                                        <div class="radio">
                                                            <label
                                                                class="container-box d-inline-block pt-0 mt-2"
                                                                style="width: initial">
                                                                <input
                                                                    type="radio"
                                                                    v-model="closure.all_delivery_slots"
                                                                    :value="0" />
                                                                <span class="checkmark"></span> Personnaliser
                                                            </label>
                                                        </div>
                                                        <show-errors
                                                            class="d-block"
                                                            :errors="errors"
                                                            errorKey="delivery_slots.data" />
                                                        <div v-if="!closure.all_delivery_slots">
                                                            <div class="col-12" v-for="day in allDays" :key="day">
                                                                <div class="mb-2">
                                                                    <label
                                                                        class="container-box"
                                                                        style="width: 100%; text-transform: capitalize">
                                                                        <input
                                                                            type="checkbox"
                                                                            name="delivery_days_active[]"
                                                                            v-model="
                                                                                closureFrontData.deliveryDaysActive
                                                                            "
                                                                            :value="day" />
                                                                        <span class="checkmark"></span>
                                                                        {{ dayLabel(day) }}
                                                                    </label>
                                                                </div>
                                                                <div
                                                                    class="setting-open"
                                                                    v-if="
                                                                        closureFrontData.deliveryDaysActive.includes(
                                                                            day
                                                                        )
                                                                    ">
                                                                    <div class="setting-hours">
                                                                        <span
                                                                            v-for="slot in getDeliverySlotsForDay(day)"
                                                                            :key="slot.id"
                                                                            class="d-block">
                                                                            <label
                                                                                class="container-box ml-4"
                                                                                style="width: initial">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="delivery_slots[]"
                                                                                    v-model="
                                                                                        closure.delivery_slots.data
                                                                                    "
                                                                                    :value="slot.id" />
                                                                                <span class="checkmark"></span>&nbsp;{{
                                                                                    slot.hour_start
                                                                                        .substr(0, 5)
                                                                                        .replace(":", "h")
                                                                                }}
                                                                                -
                                                                                {{
                                                                                    slot.hour_end
                                                                                        .substr(0, 5)
                                                                                        .replace(":", "h")
                                                                                }}
                                                                            </label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-else-if="collectType === COLLECT_TYPE_DISPATCH.value">
                                            <div class="row mb-3">
                                                <div class="col-md-4">
                                                    <label>Message à afficher sur le widget <small>*</small></label>
                                                </div>
                                                <div class="col-md-8">
                                                    <textarea
                                                        class="form-control"
                                                        v-model="closure.dispatch_message"></textarea>
                                                    <show-errors
                                                        class="d-block"
                                                        :errors="errors"
                                                        errorKey="dispatch_message" />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label
                                                        >Désactiver les commandes sur cette période
                                                        <small>*</small></label
                                                    >
                                                </div>
                                                <div class="col-md-8">
                                                    <div class="d-inline-block align-middle lead-switch">
                                                        <input
                                                            type="checkbox"
                                                            class="switch align-self-center is-rounded"
                                                            v-model="closure.disable_dispatch_widget" />
                                                        <label
                                                            @click="
                                                                closure.disable_dispatch_widget =
                                                                    !closure.disable_dispatch_widget
                                                            "></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </div>
                            <div v-else>
                                <em>Aucun service n'est disponible. Merci de vérifier votre configuration.</em>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../components/LoaderComponent";
import ShowErrors from "../../components/errors/ShowErrors";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import CollectTypesEnum from "../../mixins/enums/click_and_collect/CollectTypesEnum";

export default {
    data() {
        return {
            loading: 0,
            errors: null,
            allDays: [1, 2, 3, 4, 5, 6, 0],
            closure: {
                name: null,
                start_day: null,
                end_day: null,
                collect_types: [],
                all_click_and_collect_slots: 1,
                all_delivery_slots: 1,
                disable_dispatch_widget: true,
                dispatch_message: null,
                collect_slots: {
                    data: [],
                },
                delivery_slots: {
                    data: [],
                },
            },
            closureFrontData: {
                collectDaysActive: [],
                deliveryDaysActive: [],
            },
            fr,
            config: null,
        };
    },
    mixins: [CollectTypesEnum],
    props: {
        closure_id: {
            default: null,
        },
    },
    computed: {
        availableCollectTypes() {
            let availableCollectTypes = [];
            if (!this.config) return availableCollectTypes;
            if (this.config.is_click_and_collect_enabled) availableCollectTypes.push(this.COLLECT_TYPE_COLLECT.value);
            if (this.config.is_delivery_enabled) availableCollectTypes.push(this.COLLECT_TYPE_DELIVERY.value);
            if (this.config.is_dispatch_enabled) availableCollectTypes.push(this.COLLECT_TYPE_DISPATCH.value);
            return availableCollectTypes;
        },
        disabledFromDates() {
            if (!this.closure.end_day) return {};
            return {
                from: this.closure.end_day,
            };
        },
        disabledToDates() {
            if (!this.closure.start_day) return {};
            return {
                to: this.closure.start_day,
            };
        },
        builtData() {
            let data = _.cloneDeep(this.closure);
            data.start_day = data.start_day ? moment(data.start_day).format("Y-MM-DD") : null;
            data.end_day = data.end_day ? moment(data.end_day).format("Y-MM-DD") : null;
            data.collect_slots.data = data.collect_slots.data.filter((slotId) => {
                const found = this.config.services.find((service) => service.slots.some((slot) => slot.id === slotId));
                return this.closureFrontData.collectDaysActive.includes(found.day);
            });
            data.delivery_slots.data = data.delivery_slots.data.filter((slotId) => {
                const found = this.config.delivery_slots.data.find((slot) => slot.id === slotId);
                return this.closureFrontData.deliveryDaysActive.includes(found.day);
            });
            return data;
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        getServicesForDay(day) {
            return this.config.services
                .filter((s) => s.day === day)
                .sort((a, b) => {
                    const aStartMoment = moment(a.start_hour, "HH:mm");
                    const bStartMoment = moment(a.start_hour, "HH:mm");
                    return aStartMoment.diff(bStartMoment, "minute");
                });
        },
        getDeliverySlotsForDay(day) {
            return this.config.delivery_slots.data.filter((s) => s.day === day);
        },
        assignResponseToClosure(data) {
            this.closure = data;
            this.closure.start_day = data.start_day ? moment(data.start_day).toDate() : null;
            this.closure.end_day = data.end_day ? moment(data.end_day).toDate() : null;
            this.closure.collect_slots.data.forEach((slot) => {
                if (!this.closureFrontData.collectDaysActive.includes(slot.cc_service_without_slots.day))
                    this.closureFrontData.collectDaysActive.push(slot.cc_service_without_slots.day);
            });
            this.closure.collect_slots.data = this.closure.collect_slots.data.map((s) => s.id);
            this.closure.delivery_slots.data.forEach((slot) => {
                if (!this.closureFrontData.deliveryDaysActive.includes(slot.day))
                    this.closureFrontData.deliveryDaysActive.push(slot.day);
            });
            this.closure.delivery_slots.data = this.closure.delivery_slots.data.map((s) => s.id);
        },
        fetchClosure() {
            if (!this.closure_id) return;
            this.loading++;

            axios
                .get(
                    `/api/click_and_collect/${this.restaurant_id}/closures/${this.closure_id}?include=collect_slots.cc_service_without_slots,delivery_slots`
                )
                .then((response) => {
                    this.loading--;
                    this.assignResponseToClosure(response.data);
                })
                .catch((error) => {
                    this.loading--;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
        fetchConfig() {
            this.loading++;

            axios
                .get(`/api/click_and_collect/${this.restaurant_id}/settings?include=delivery_slots`)
                .then((response) => {
                    this.loading--;
                    this.config = response.data;
                    if (this.config.services) {
                        this.config.services.forEach((service) => {
                            service.slots.sort((a, b) => {
                                const aStartMoment = moment(a.hour, "HH:mm");
                                const bStartMoment = moment(b.hour, "HH:mm");
                                return aStartMoment.diff(bStartMoment, "minute");
                            });
                        });
                    }
                })
                .catch((error) => {
                    this.loading--;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
        fetchData() {
            this.fetchClosure();
            this.fetchConfig();
        },
        save() {
            this.loading++;
            this.errors = null;

            if (this.closure_id)
                var promise = axios.put(
                    `/api/click_and_collect/${this.restaurant_id}/closures/${this.closure_id}`,
                    this.builtData
                );
            else var promise = axios.post(`/api/click_and_collect/${this.restaurant_id}/closures`, this.builtData);
            promise
                .then((response) => {
                    this.loading--;
                    this.$emit("saved");
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: this.closure_id ? "La fermeture a bien été modifiée" : "La fermeture a bien été créée",
                    });
                })
                .catch((error) => {
                    this.loading--;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
    },
    created() {
        this.fetchData();
    },
    components: {
        LoaderComponent,
        ShowErrors,
        Datepicker,
    },
};
</script>
