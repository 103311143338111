var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _vm.reservation
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("div", [
                            _c("p", { staticClass: "mb-2" }, [
                              _vm._v(
                                "\n                                Date de la réservation :\n                                " +
                                  _vm._s(
                                    _vm.displayDate(
                                      _vm.reservation.reservation_datetime,
                                      _vm.DATE_SHORT
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.reservation.client
                              ? _c("p", { staticClass: "mb-2" }, [
                                  _vm._v(
                                    "\n                                Client : " +
                                      _vm._s(_vm.reservation.client.firstname) +
                                      " " +
                                      _vm._s(_vm.reservation.client.lastname) +
                                      "\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", { staticClass: "mb-2" }, [
                              _vm._v(
                                "Montant réglé (si prépaiement) : " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      _vm.stripeIntentAmount / 100
                                    )
                                  )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.services.footprint.amountToCapture",
                                      _vm.selected_restaurant_id
                                    )
                                  ) +
                                  _vm._s(_vm.$tl("labels.colon")) +
                                  "\n                                " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      _vm.reservation.amount_if_noshow / 100
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "Montant total de l'empreinte : " +
                                  _vm._s(
                                    _vm.formatCurrency(
                                      _vm.stripeIntentAmount / 100
                                    )
                                  )
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.isStripeIntentAmountOverReservationSize
                              ? _c("p", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                                Attention le nombre de personnes à été modifié pour cette réservation. L'empreinte bancaire est disponible pour\n                                " +
                                      _vm._s(
                                        _vm.stripeIntentAmount /
                                          _vm.reservation.amount_if_noshow
                                      ) +
                                      " personnes\n                            "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _vm.showSaveButton
                            ? _c("div", [
                                _vm.reservation.client && _vm.stripeIntentAmount
                                  ? _c("div", { staticClass: "dropdown" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-danger btn-circle btn mt-4 dropdown-toggle",
                                          attrs: {
                                            type: "button",
                                            id: "dropdownMenu2",
                                            "data-toggle": "dropdown",
                                            "aria-haspopup": "true",
                                            "aria-expanded": "false",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Prélever\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "dropdown-menu",
                                          attrs: {
                                            "aria-labelledby": "dropdownMenu2",
                                          },
                                        },
                                        _vm._l(
                                          _vm.reservation.nb_pers +
                                            _vm.reservation.nb_children,
                                          function (n) {
                                            return _c(
                                              "button",
                                              {
                                                key: n,
                                                staticClass: "dropdown-item",
                                                attrs: {
                                                  type: "button",
                                                  disabled:
                                                    n *
                                                      _vm.reservation
                                                        .amount_if_noshow >
                                                    _vm.stripeIntentAmount,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.takeNoShow(n)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(n) +
                                                    " personne(s) (" +
                                                    _vm._s(
                                                      (n *
                                                        _vm.reservation
                                                          .amount_if_noshow) /
                                                        100
                                                    ) +
                                                    "€)\n                                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn btn-warning btn-circle text-white mt-4",
                                    on: { click: _vm.markNoShow },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.reservations.noshowWithoutDeduction",
                                            _vm.selected_restaurant_id
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }