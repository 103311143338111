export default {
    common: {
        needStripeAccount: "Pour activer ces options, veuillez connecter un compte stripe.",
        needPaymentAccount: "Pour activer ces options, veuillez connecter un système de paiement.",
        unsavedChanges: "Vous avez des modifications non sauvegardées",
        file: {
            maxSize: "Taille maximale acceptée : {maxSize} Mo",
        },
        services: "Vous devez créer des plages horaires au préalable pour pouvoir modifier ce paramètre.",
    },
    admin: {
        videos: {
            youtubeVideoId: "Insérez l'identifiant de la vidéo YouTube",
        },
    },
    clients: {
        immutable:
            "Client non modifiable depuis {theme} (Client Hôtel). Les modifications doivent s'effectuer depuis la réservation Hôtel du client.",
        import: {
            noPhoneNumberCountryCodeNote:
                "Note : Si le \"{phoneCountryCode}\" n'est pas défini, nous tenterons de le déduire automatiquement en fonction de l'indicatif.",
            phoneNumberCountryCodeConsiderFrench:
                "Si cela est impossible, le numéro de téléphone sera automatiquement considéré comme un numéro français.",
        },
    },
    widget: {
        oldKeyRevoked: "L'ancienne clé sera révoquée. Pensez à changer la valeur sur votre widget.",
        shortLink: "Le lien court ne peut contenir que des lettres minuscules, des tirets et des underscores (_). Max : {max} caractères",
        infosIntegrationWebsite: {
            title: "Informations concernant l'intégration sur votre site web",
            content1: "Pour intégrer le widget {type} sur votre site web, vous devez transmettre les informations ci-dessous à votre développeur.",
            content2:
                'Nous vous recommandons d\'intégrer le module directement dans votre site internet en suivant les étapes ci-dessous. Vous pouvez toutefois intégrer le widget sous forme de bouton qui redirige vers une page externe (copier-coller le lien accessible depuis le bouton "Voir mon widget").',
            content3:
                "Merci de ne pas intégrer le widget avec un iframe car la hauteur ne peut pas s'adapter automatiquement au contenu et cela impacte l'expérience utilisateur.",
        },
        stepsIntegration: {
            1: "Créez un conteneur (balise 'div') à l'endroit où vous souhaitez afficher le widget",
            2: "Ajoutez le code suivant à votre site",
            3: "Initialisez le widget à l'aide du script ci-dessous",
            4: "Ajouter des paramètres à l'initialisation du widget",
            5: "Faire remonter les réservations sur Google Tag Manager",
        },
        optionalParams: "Des paramètres optionnels sont disponibles afin de modifier le comportement du widget :",
        gTagManager: {
            gTagPrerequisite: "Prérequis : avoir un compte GA4 et un compte Google Tag Manager",
            gTagIdExample: "VOTRE_GTAG_MANAGER_ID",
            gTagFirst:
                "Si le widget est intégré directement au sein de votre site internet, l'intégration du gtag manager est à faire de votre côté :",
            gTagSecond:
                "Si vous utilisez votre widget en mode hébergé (en d'autres termes, si vous redirigez vos utilisateurs sur le site app.noshow.io pour la prise de réservation), il suffira de rentrer l'id G-TAG-MANAGER dans votre back-office NoShow (qui est sous la forme \"GTM-XXXXXXX\") et l'intégration se fera automatiquement.",
            gTagInfo: "Ensuite, pour bénéficier des évènements remontés :",
            gTagStep1: 'Dans votre espace G-TAG MANAGER, créez un nouveau "déclencheur"',
            gTagStep2: 'Dans le type de déclencheur, choisissez "évènement personnalisé"',
            gTagStep3: 'Le nom de l\'événement à entrer pour les réservations validées est "booking-validated"',
            gTagStep4: "Ensuite créez une nouvelle balise Google Analytics : Evènement GA4",
            gTagStep5: "Renseignez l'id de mesure de votre compte GA4",
            gTagStep6: "Renseignez le nom de l'évenèment qui sera remonté sur votre tableau de bord GA4",
            gTagStep7:
                "Si un message apparaît indiquant que la balise GA4 n'a pas été reconnu, il faudra créer une balise Google, dans laquelle vous renseignerez l'ID de mesure et associerez à la balise GA4",
            gTagStep8: "Associez le déclencheur précédemment créé avec la balise GA4",
            gTagStep9: "N'oubliez pas d'envoyer et de publier vos changements, pour que vos modifications soient prises en compte.",
        },
        lang: "Permet d'intégrer le widget avec une langue spécifique",
        subtitle: "Méthode d'intégration",

        facebookIntegration: {
            title: "Intégration d'un lien vers votre widget - Facebook",
            step1: "Récupérer le lien vers votre widget",
            content1:
                'Ce lien se trouve juste au-dessus (lien court). Vous pouvez aussi cliquer sur le bouton "voir mon widget" en haut de la page et récupérer l\'URL.',
            step2: "Sur votre page Facebook",
            content2: "Prendre exemple sur le GIF ci-dessous.",
            content3:
                "Le bouton change en «Modifier Commander». Vous pouvez modifier et cliquer sur le lien pour vérifier que la redirection fonctionne",
            content4:
                "À noter: l’interface de Facebook évolue il est donc possible que les noms des boutons ou onglets changent, cependant la méthode d’intégration reste la même.",
            menu: "Menu",
            page: "Page",
            relatedPage: "Page correspondante",
            addButton: "Ajouter un bouton d'action",
            threePoints: "Bouton [...]",
            optionChoice: "Choisissez une option",
            pasteLink: "Coller le lien de votre widget",
        },
        instagramIntegration: {
            title: "Intégration d'un lien vers votre widget - Instagram",
            step1: "Récupérer le lien vers votre widget",
            content1:
                'Ce lien se trouve juste au-dessus (lien court). Vous pouvez aussi cliquer sur le bouton "voir mon widget" en haut de la page et récupérer l\'URL.',
            step2: "Sur votre compte Instagram",
            content2: 'Cliquer sur le logo "Mon compte"',
            content3: ' "Modifier profil"',
            content4: 'Dans "Site Web" ou dans "Liens", coller le lien de votre widget puis valider en haut à droite',
        },
        googleLinkIntegration: {
            title: "Intégration d'un lien vers votre widget - Google",
            step1: "Récupérer le lien vers votre widget",
            content1:
                'Ce lien se trouve juste au-dessus (lien court). Vous pouvez aussi cliquer sur le bouton "voir mon widget" en haut de la page et récupérer l\'URL.',
            step2: "Votre entreprise est référencée dans Google",
            step3: "Je recherche mon établissement",
            step4: 'Je sélectionne l\'option "Reservation"',
            content2: "Coller le lien vers votre widget puis enregistrer",
            alt: {
                step2: "Votre entreprise est référencée sur Google",
                step3: "Recherche de votre entreprise",
                step4: "Je sélectionne réservation puis enregistrer",
            },
        },
    },
    account: {
        changePhoneNumber:
            "En cas de modification du numéro de téléphone, la personnalisation du canal de récéption (plus bas dans cette page) sera réinitialisée.",
    },
    form: {
        maxChars: "{nb} caractères maximum",
        remainingChars: "Il vous reste {nb} caractère(s)",
        sms: {
            foreignCost: "Coût en crédits des sms à l'étranger",
        },
    },
    psp: {
        checkPaymentMethod: "Vérifiez votre moyen de paiement !",
    },
    cgvs: {
        acceptedOn: "Acceptée(s) le {date}",
    },
    rgpd: {
        legalTimeToKeep1:
            "Les durées légales de conservation des données sont fixées à 3 ans maximum dans le cadre d'une utilisation à des fins de prospection, et à 10 ans dans le cadre de contrats conclus par voie électronique.",
        legalTimeToKeep2: "Il vous appartient de faire les sauvegardes nécessaires et d'archiver les données devant être conservées.",
    },
    roles: {
        manager:
            "Le compte responsable donne accès à toutes manipulations sur le logiciel, hors souscription à l’abonnement NoShow et achats des packs de SMS.",
        kitchen:
            "Le compte cuisine est destiné à être le compte accessible depuis une tablette mise à disposition en cuisine. Elle permet d'accéder à la liste des réservations, prestations choisies par les clients lors de la réservation, commentaires clients et notes internes (allergènes, intolérances...).",
        employee:
            "Le compte employé est destiné à être le compte accessible depuis la tablette de l'établissement, et auquel tous vos employés peuvent avoir accès. Les droits de ce compte sont limités, l’accès à l’onglet configuration ou à l’export de votre base de données clients est exclu.",
        user_without_account:
            "L'ajout d’un utilisateur sans compte vous permet de créer un accès à la liste déroulante des personnes pouvant ajouter ou éditer des réservations. Aucun compte ne sera créé à l’ajout d’un utilisateur.",
    },
    sms: {
        foreignCost: "Coût en crédits des sms à l'étranger",
        foreignCostTab: "Tableaux des coûts en crédits des SMS à l'étranger",
        freeWhenEnabled: "À l'activation, {nb} Crédits SMS vous seront offerts.",
        configNotifs: "Une fois les sms activés, n'oubliez pas de configurer vos notifications dans les paramètres de votre établissement.",
        useNeedActivation: "Pour utiliser les SMS, veillez à activer la fonctionnalité au préalable !",
        pendingSmsPackOrder: "Une tentative d'achat de pack SMS est déjà en cours.",
        stats: {
            title: "Question statistiques",
            openingRate: {
                title: "Taux d'ouverture :",
                value: "{percentSms}% pour le SMS VS {percentEmail}% pour l'email",
            },
            readTimeDelay: {
                title: "Délais de lecture :",
                value: "{timeSms} minutes pour le SMS VS {timeEmail} minutes pour l'email",
            },
            spam: {
                title: "Spam :",
                value: "{percentSms}% pour le SMS VS {percentEmail}% pour l'email",
            },
        },
    },
    booking: {
        clients: {
            willBeCreated: "Le client sera créé à l'enregistrement de la réservation.",
            alreadyExist: "Si vous enregistrez la réservation, celui-ci sera mis à jour.",
        },
        reservations: {
            immutable:
                "Réservation non modifiable depuis {theme} (Client Hôtel). Les modifications doivent s'effectuer depuis la réservation Hôtel du client.",
            placeOnSeatingPlan: "Veuillez placer toutes les réservations sur votre plan de salle pour débloquer la prise de réservation.",
            saveCauseSurbooking: "En validant cette réservation vous serez en surbooking pour cette plage horaire.",
            couldNotBePlaced:
                "Cette réservation ne pourra pas être placée sur le plan de salle si vous la validez.<br>Veuillez placer votre réservation sur le plan de salle avant d'enregistrer.",
            onlyXDisplayed: "Seules les {viewLimit} premières réservations en attente sont affichées dans ce tableau récapitulatif.",
            viewMoreCreated:
                "Pour visualiser les prochaines réservations, veuillez accepter ou refuser au préalable les réservations ci-dessous et réactualisez la page.",
            onlyLastXPayments: "Seuls les {count} derniers paiements sont affichés.",
            hasBeenCanceled: "Attention, cette réservation a été annulée",
            alreadyScanned: "Attention, réservation déjà scannée",
            confirmDeleteSlots:
                "Des réservations futures existent sur des créneaux ou des jours supprimés. Si vous le souhaitez, vous pouvez annuler automatiquement toutes les réservations. Cette action entrainera le remboursement des prépaiements et l'annulation des empreintes bancaires. Dans ce cas, vous pourrez personnaliser le mail envoyé à vos clients à l'étape suivante.",
            confirmClosureCreate:
                "Des réservations existent aux dates spécifiées. Si vous le souhaitez, vous pouvez annuler automatiquement toutes les réservations. Cette action entrainera le remboursement des prépaiements et l'annulation des empreintes bancaires. Dans ce cas, vous pourrez personnaliser le mail envoyé à vos clients à l'étape suivante.",
            requireActionIfNoCancel:
                "Si vous souhaitez conserver les réservations, pensez à modifier chacune des réservations en les affectant au nouveau service. Sans cette action, vous pourriez rencontrer des problématiques de surbooking ou de plan de salle.",
            menus: {
                unique: "Une prestation unique est demandée pour l'ensemble de la réservation",
            },
            add: {
                changeSlotSelectedTables: "Merci de délier les places afin de pouvoir choisir un autre créneau",
                willBePlacedAuto:
                    "La réservation sera placée automatiquement sur votre plan de salle, choisissez une place uniquement si vous souhaitez attribuer une place spécifique à votre client.",
            },
            edit: {
                date: "Attention, modifier la date supprimera automatiquement la place sélectionnée.",
                nbPers: "Attention, modifier le nombre d'adultes supprimera automatiquement la place sélectionnée.",
                nbChildren: "Attention, modifier le nombre d'enfants supprimera automatiquement la place sélectionnée.",
                slot: "Attention, modifier le créneau de réservation supprimera automatiquement la place sélectionnée.",
                changeRestaurant: "Attention, veuillez supprimer la place sélectionnée pour déplacer la réservation vers un autre établissement.",
                cantChangeRestaurant:
                    "Il est impossible de transférer dans un autre établissement une réservation pour laquelle un choix de prélèvement a été renseigné.",
                duration: "Pour modifier la durée de la réservation, vous devez d'abord délier la place sélectionnée.",
                passingCustomer: {
                    duration: "Pour modifier la durée, vous devez d'abord délier la table sélectionnée.",
                },
            },
            payment: {
                willBeSendOnSave: "Le demande d'acompte sera transmise au client dès l'enregistrement de la réservation.",
                needClientEmail: "Pour sécuriser la réservation avec un acompte, un email client valide doit être renseigné.",
                needClientPhoneNumber: "Pour sécuriser la réservation avec un acompte, un téléphone client valide doit être renseigné.",
            },
            footprint: {
                willBeSendOnSave: "La demande d'empreinte bancaire sera transmise au client dès l'enregistrement de la réservation.",
                willBeSendOnDate: "La demande d'empreinte bancaire sera transmise au client le {date}.",
                changeDate:
                    "Attention, cette réservation est protégée par une empreinte bancaire. Si vous changez la date de la réservation, l'empreinte bancaire actuelle sera annulée et une nouvelle demande sera envoyée au client.",
                needClientEmail: "Pour sécuriser la réservation avec une empreinte bancaire, un email client valide doit être renseigné.",
                needClientPhoneNumber: "Pour sécuriser la réservation avec une empreinte bancaire, un téléphone client valide doit être renseigné.",
            },
        },
        seatingPlan: {
            tips: "Afin d'améliorer la performance de l'algorithme de remplissage automatique, nous avons apporté de multiples modifications. Certaines réservations peuvent vous sembler mal placées lorsque votre établissement n'est pas complet, mais le placement va s'affiner au fur et à mesure des prises de réservations. Il est important d'agir le moins possible manuellement sur le placement.",
            ignorePlacement:
                "Cette réservation sera ignorée du plan de salle, l'algorithme ne la prendra pas en compte. Si vous ignorez le placement, nous vous invitons à faire preuve de vigilance pour éviter tout risque de surbooking.",
            delete: "Vous devez délier le plan de salle de tous les services pour le supprimer",
            futureResasOnDeletedTables:
                "Vous avez une/des réservation(s) future(s) sur la/les table(s) que vous souhaitez supprimer. Nous essayerons néanmoins de la/les replacer si vous enregistrer la configuration et nous vous informerons du résultat.",
            needed: ["Pour accéder à cette fonctionnalité", "veuillez au préalable définir un plan de salle."],
            notLinked: ["Pour accéder à cette fonctionnalité", "veuillez au préalable lier un plan de salle."],
        },
        services: {
            maxPaxIfRotation: "Si la rotation des places est activée, il s'agit du nombre de personnes maximum en temps réel",
            enableBack: "Pour réactiver cette plage horaire, rendez-vous sur votre {dashboard}.",
            cantDelete: "Vous ne pouvez pas supprimer cette plage horaire car des réservations futures y sont associées",
            hasFutureReservations: "Cette plage horaire fait référence à plusieurs réservations à venir.",
            blockAutoConfirm: "0 pour ne pas bloquer",
            enableSeatingPlan: "Pour activer cette option, merci de paramétrer au préalable votre plan de salle",
            changeSeatingPlan: [
                "Si vous validez le changement de plan de salle sur cette plage horaire, les réservations futures ne seront plus liées aux tables de l'ancien plan de salle, et les préférences de salle ne seront pas conservées.",
                "L'algorithme tentera de replacer vos réservations à la prochaine prise de réservation.",
                "Si toutefois des réservations ne peuvent pas être placées, la prise de réservation sera momentanément suspendue.",
                "Nous vous conseillons vivement de vérifier vos prochaines plages horaires.",
            ],
        },
        menus: {
            cantDelete: "Vous ne pouvez pas supprimer ce menu car des réservations futures y sont associées.",
            options: {
                cantDelete: "Vous ne pouvez pas supprimer cette option car des réservations futures y sont associées.",
            },
        },
        notifications: {
            send24HoursBeforeResa: "La notification sera envoyée 24h avant le début de la réservation.",
        },
        googleReserve: {
            mustHaveGoogleAddress:
                "Pour activer la fonctionnalité \"Réserver avec Google\", vous devez au préalable saisir l'adresse de votre établissement au nouveau format depuis l'édition d'établissement.",
            forRestaurantsOnly:
                "Fonctionnalité à destination uniquement des restaurateurs pour la réservation de table. Fonctionnalité non disponible pour les autres activités. Si vous pensez être éligible, merci de contacter le support client.",
            mustOptIn:
                'Le propriétaire du compte doit d\'abord accepter les termes du contrat "Réserver avec Google" pour accéder à cette fonctionnalité.',
        },
    },
    subscriptions: {
        modal: {
            title: "Demande d'informations",
            body: "Vous êtes sur le point de faire une demande d’informations sur ce service. Votre chargé de compte prendra contact avec vous à ce sujet.",
        },
    },
    register: {
        welcome1: "Bienvenue sur la page d'inscription NoShow, le logiciel dédié aux restaurateurs !",
        welcome2: "Créez votre compte et accédez au module de votre choix (réservation en ligne, Click & Collect, bons cadeaux) en quelques minutes.",
    },
    restaurants: {
        newAddressComponent:
            "Un nouveau système d'autocomplétion a été mis en place. Merci de saisir votre adresse postale en choisissant un élément dans la liste déroulante.",
    },
};
