var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100" }, [
    _c("div", { staticClass: "row m-0 mt-1" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              on: {
                click: function ($event) {
                  return _vm.$router.push({ name: "online_help" })
                },
              },
            },
            [
              _c("feather", {
                staticClass: "mr-2 feather-24 pointer",
                attrs: { type: "chevron-left" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "h5",
            { staticClass: "title mt-2" },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$tl("labels.routes.onlineHelp")) +
                  " - " +
                  _vm._s(_vm.getModuleTypeLabel(_vm.module)) +
                  "\n                    "
              ),
              _c("ShowNewBadgeDetails", {
                staticClass: "ml-2",
                attrs: { badge_uuid: "a644c6f5-8bc3-48e8-be08-124398dfa9e1" },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row m-0" },
      [
        _vm.loading
          ? _c("LoaderComponent", { staticClass: "mx-auto" })
          : _vm.videosList.length > 0
          ? _vm._l(_vm.videosList, function (video) {
              return _c(
                "div",
                {
                  key: video.id,
                  staticClass: "w-75 mx-auto border-light mb-2 p-2",
                },
                [
                  _c("h6", { staticClass: "m-0" }, [
                    _vm._v(_vm._s(video.title)),
                  ]),
                  _vm._v(" "),
                  video.description
                    ? _c("p", {
                        staticClass: "m-0 mt-1",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatDescription(video.description)
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-100 text-right font-italic" }, [
                    _c("small", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("labels.videos.addedOn", {
                            date: _vm.displayDate(video.created_at),
                          })
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-success btn-sm btn-circle d-block mx-auto mt-1",
                      on: {
                        click: function ($event) {
                          video.show = !video.show
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            video.show
                              ? _vm.$tl("labels.videos.hide")
                              : _vm.$tl("labels.videos.show")
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  video.show
                    ? _c(
                        "div",
                        { staticClass: "row mt-2" },
                        [
                          _c("ShowYoutubeVideo", {
                            staticClass: "mx-auto",
                            attrs: {
                              youtubeVideoId: video.youtube_video_id,
                              title: video.title,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            })
          : _c("div", { staticClass: "col-12 font-italic mt-1 ml-4" }, [
              _vm._v(_vm._s(_vm.$tl("labels.videos.emptyForModule"))),
            ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }