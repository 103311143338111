<template>
    <label :class="computeClasses('label')" v-tooltip="tooltipComputed">
        <input
            :name="inputName"
            :data-test-id="dataTestId"
            type="radio"
            :checked="isChecked"
            :disabled="disabled"
            @change="valueComputed = inputValue" />
        <span class="checkmark" :class="{ disabled }"></span><slot></slot>
    </label>
</template>

<script>
export default {
    name: "RadioButtonComponent",
    data() {
        return {
            defaultLabelClasses: "container-box",
        };
    },
    props: {
        labelClass: {
            type: [Object, Array, String],
            default: undefined,
        },
        value: {
            default: undefined,
        },
        dataTestId: {
            type: String,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        inputValue: {
            default: undefined,
        },
        inputName: {
            type: String,
        },
        tooltip: {
            type: [String, Object],
        },
        strict: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        isChecked() {
            return this.strict ? this.valueComputed === this.inputValue : this.valueComputed == this.inputValue;
        },
        valueComputed: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        tooltipComputed() {
            if (typeof this.tooltip === "string") {
                return {
                    delay: { show: 400, hide: 0 },
                    content: this.tooltip,
                };
            }

            return this.tooltip;
        },
    },
    methods: {
        convertClassToObject(classes) {
            let newClasses = {};

            if (typeof classes === "string") {
                classes.split(" ").forEach((tmpClass) => (newClasses[tmpClass] = true));
            } else if (Array.isArray(classes)) {
                classes.forEach((tmpClass) => (newClasses[tmpClass] = true));
            } else if (typeof classes === "object") {
                newClasses = classes;
            }

            return newClasses;
        },
        computeClasses(classesType) {
            return {
                ...this.convertClassToObject(this[`default${this.capitalize(classesType)}Classes`]),
                ...this.convertClassToObject(this[`${classesType}Classes`]),
            };
        },
    },
};
</script>
