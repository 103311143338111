<template>
    <div>
        <input v-if="value === null" type="text" class="form-control" v-model="search.input" :disabled="disabled" />
        <div v-else class="d-flex">
            <input type="text" class="form-control" disabled :value="displayableAddress" />
            <feather
                type="x"
                class="text-danger position-absolute"
                :class="{ pointer: disabled }"
                style="top: 10px; right: 18px"
                @click.stop="resetAddress" />
        </div>
        <div v-if="errors.search !== null" class="text-danger">{{ errors.search }}</div>
        <div v-else-if="search.hits.length > 0" class="dropdown-menu prename-field shadow show p-0" style="top: auto; left: auto">
            <loader-component v-if="loading.placeDetails" />
            <ul v-else class="list-group">
                <li v-for="hit in search.hits" :key="hit.description" class="list-group-item p-0">
                    <a class="d-block p-3" href="javascript:" @click="selectAddress(hit.place_id)">
                        <span>{{ hit.description }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";

export default {
    data() {
        return {
            sessionToken: null,
            loading: {
                placeDetails: false,
            },
            errors: {
                search: null,
            },
            search: {
                input: null,
                hits: [],
            },
        };
    },
    props: {
        /**
         * {
         *  latitude: ?float,
         *  longitude: ?float,
         *  locality: ?string,
         *  country: string,
         *  region: ?string,
         *  street_address: string,
         *  postal_code: ?string,
         *  place_id: string,
         *  formatted_address: ?string,
         * }
         */
        value: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * To search globally on Google Places API, without applying the "address" type filter.
         * @See Google Places' [doc](https://developers.google.com/maps/documentation/places/web-service/details)
         */
        searchAddressType: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        updateAddressFields(fields) {
            this.address = {
                ...this.address,
                ...fields,
            };
        },
        getDisplayableAddress(address) {
            if (![undefined, null, ""].includes(address.formatted_address)) {
                return address.formatted_address;
            }

            let displayableAddress = `${address.street_address}`;

            if (address.postal_code !== null) {
                displayableAddress += ` ${address.postal_code}`;
            }

            if (address.locality !== null) {
                displayableAddress += ` ${address.locality}`;
            }

            if (address.region !== null) {
                displayableAddress += `, ${address.region}`;
            }

            return `${displayableAddress}, ${address.country}`;
        },
        searchAddress(search) {
            if (this.disabled || this.search.input !== search) {
                return;
            }

            if (this.sessionToken === null) {
                this.sessionToken = this.generateRandomString();
            }

            this.errors.search = null;

            const endpoint = this.searchAddressType ? "address" : "all";
            this.httpGet(`/api/google/places/search/${endpoint}?query=${this.search.input}&sessionToken=${this.sessionToken}`).then((response) => {
                if (response !== false) {
                    if (response.data.data.length > 0) {
                        this.search.hits = response.data.data;
                        return;
                    }

                    this.errors.search = this.$tl("errors.common.address.noHits");
                }

                this.search.hits = [];
            });
        },
        selectAddress(place_id) {
            if (this.disabled || this.loading.placeDetails) {
                return;
            }

            this.loading.placeDetails = true;

            this.httpGet(`/api/google/places/details/${place_id}?sessionToken=${this.sessionToken}`)
                .then((response) => {
                    if (response !== false) {
                        this.updateAddressFields({
                            latitude: response.data.location.lat,
                            longitude: response.data.location.lng,
                            locality: response.data.city,
                            country: response.data.country,
                            street_address: response.data.address,
                            postal_code: response.data.postalCode,
                            place_id,
                            formatted_address: response.data.formatted_address,
                        });

                        this.search.input = null;
                        this.search.hits = [];

                        this.sessionToken = null;
                    }
                })
                .finally(() => (this.loading.placeDetails = false));
        },
        resetAddress() {
            if (this.disabled) {
                return;
            }

            this.address = null;
        },
    },
    computed: {
        address: {
            get() {
                return (
                    this.value ?? {
                        latitude: null,
                        longitude: null,
                        locality: null,
                        country: null,
                        region: null,
                        street_address: null,
                        postal_code: null,
                        place_id: null,
                        formatted_address: null,
                    }
                );
            },
            set(newVal) {
                this.$emit("input", newVal);
            },
        },
        displayableAddress() {
            if (this.value === null) {
                return null;
            }

            return this.getDisplayableAddress(this.address);
        },
    },
    watch: {
        "search.input": function (newVal) {
            if (newVal === null || newVal.length < 3) {
                this.search.hits = [];
                this.errors.search = null;
                this.sessionToken = null;
                return;
            }

            setTimeout(() => {
                this.searchAddress(newVal);
            }, 500);
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>
