var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.passwordFieldType === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          staticClass: "form-control d-inline-block",
          class: { "is-invalid": !_vm.isPasswordSecured },
          staticStyle: { width: "82%" },
          attrs: { "data-test-id": _vm.dataTestId, type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.password)
              ? _vm._i(_vm.password, null) > -1
              : _vm.password,
          },
          on: {
            focus: function ($event) {
              _vm.inputFocus = true
            },
            blur: function ($event) {
              _vm.inputFocus = false
            },
            change: function ($event) {
              var $$a = _vm.password,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.password = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.password = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.password = $$c
              }
            },
          },
        })
      : _vm.passwordFieldType === "radio"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          staticClass: "form-control d-inline-block",
          class: { "is-invalid": !_vm.isPasswordSecured },
          staticStyle: { width: "82%" },
          attrs: { "data-test-id": _vm.dataTestId, type: "radio" },
          domProps: { checked: _vm._q(_vm.password, null) },
          on: {
            focus: function ($event) {
              _vm.inputFocus = true
            },
            blur: function ($event) {
              _vm.inputFocus = false
            },
            change: function ($event) {
              _vm.password = null
            },
          },
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          staticClass: "form-control d-inline-block",
          class: { "is-invalid": !_vm.isPasswordSecured },
          staticStyle: { width: "82%" },
          attrs: {
            "data-test-id": _vm.dataTestId,
            type: _vm.passwordFieldType,
          },
          domProps: { value: _vm.password },
          on: {
            focus: function ($event) {
              _vm.inputFocus = true
            },
            blur: function ($event) {
              _vm.inputFocus = false
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.password = $event.target.value
            },
          },
        }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass:
          "d-inline-block float-right btn btn-sm btn-outline-secondary",
        staticStyle: { width: "16%", height: "46px" },
        attrs: { type: "button" },
        on: { click: _vm.switchVisibility },
      },
      [
        _c("feather", {
          staticClass: "feather-24",
          attrs: { type: _vm.featherIcon },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLegend_,
            expression: "showLegend_",
          },
        ],
        staticClass: "div-pass-check",
      },
      [
        _c(
          "div",
          { class: _vm.atLeast8Chars ? "text-success" : "text-danger" },
          [
            _c("feather", {
              attrs: { type: _vm.atLeast8Chars ? "check" : "x" },
            }),
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tc("labels.password.minChars", 8)) +
                "\n        "
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.hasMin ? "text-success" : "text-danger" },
          [
            _c("feather", { attrs: { type: _vm.hasMin ? "check" : "x" } }),
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tc("labels.password.minLowerCaseChars", 1)) +
                "\n        "
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.hasMaj ? "text-success" : "text-danger" },
          [
            _c("feather", { attrs: { type: _vm.hasMaj ? "check" : "x" } }),
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tc("labels.password.minUpperCaseChars", 1)) +
                "\n        "
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.hasNumber ? "text-success" : "text-danger" },
          [
            _c("feather", { attrs: { type: _vm.hasNumber ? "check" : "x" } }),
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tc("labels.password.minNumbers", 1)) +
                "\n        "
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.hasSpecialChar ? "text-success" : "text-danger" },
          [
            _c("feather", {
              attrs: { type: _vm.hasSpecialChar ? "check" : "x" },
            }),
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$tc("labels.password.minSpecialChars", 1, {
                    charsList: "?!}{^][@#&%*",
                  })
                ) +
                "\n        "
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }