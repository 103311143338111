var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasErrors("choosen_menus")
        ? _c(
            "div",
            _vm._l(_vm.formErrors.errors.choosen_menus, function (err) {
              return _c("div", { staticClass: "invalid-feedback d-block" }, [
                _vm._v("\n            " + _vm._s(err) + "\n        "),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.are_menus_unique
        ? _c("div", { staticClass: "mb-3 text-danger" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$tl(
                    "infos.booking.reservations.menus.unique",
                    _vm.restaurantId
                  )
                ) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.available_menu, function (m, mIndex) {
        return _c("div", { staticClass: "one-menu mb-3" }, [
          _c("div", { staticClass: "row text-left mb-0" }, [
            _c("div", { staticClass: "col-sm-8" }, [
              _c("label", { staticClass: "mb-1" }, [
                _vm._v(
                  _vm._s(m.name) +
                    " - " +
                    _vm._s(
                      _vm.formatCurrency(
                        m.price,
                        _vm.getRestaurantCurrency(_vm.restaurantId)
                      )
                    )
                ),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "d-block text-muted mb-2" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(m.description) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "select",
                {
                  ref: `ref_menu_${m.id}`,
                  refInFor: true,
                  staticClass: "custom-select",
                  class: { "is-invalid": _vm.hasErrors("choosen_menus") },
                  staticStyle: { height: "44px" },
                  attrs: { disabled: _vm.readonly },
                  on: {
                    change: function ($event) {
                      return _vm.chooseMenu(m, $event)
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: 0 } }, [_vm._v(_vm._s(0))]),
                  _vm._v(" "),
                  _vm._l(_vm.menusLeftToChoose(m.id), function (n, index) {
                    return !_vm.are_menus_unique
                      ? _c("option", { domProps: { value: index + 1 } }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(index + 1) +
                              "\n                    "
                          ),
                        ])
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm.are_menus_unique && _vm.menusLeftToChoose(m.id) > 0
                    ? _c(
                        "option",
                        { domProps: { value: _vm.menusLeftToChoose(m.id) } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.menusLeftToChoose(m.id)) +
                              "\n                    "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.choosen_menu[`id_${m.id}`] !== undefined &&
          _vm.choosen_menu[`id_${m.id}`].value > 0 &&
          m.menu_options.data.length > 0
            ? _c(
                "div",
                {
                  staticClass:
                    "p-3 border-right-light border-bottom-light border-left-light",
                },
                _vm._l(m.menu_options.data, function (option) {
                  return _c("div", { staticClass: "row text-left mb-0" }, [
                    _c("div", { staticClass: "col-8" }, [
                      _c("label", { staticClass: "mb-0" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(option.name) +
                            " - " +
                            _vm._s(
                              _vm.formatCurrency(
                                option.price,
                                _vm.getRestaurantCurrency(_vm.restaurantId)
                              )
                            ) +
                            "\n                        "
                        ),
                        option.quantity_type === "mandatory_for_each"
                          ? _c("small", { staticClass: "text-muted" }, [
                              _vm._v(
                                " (" +
                                  _vm._s(_vm.$tl("labels.perPerson")) +
                                  ") "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("small", { staticClass: "d-block text-muted" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(option.description) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      option.quantity_type === "mandatory_for_each"
                        ? _c("div", [
                            _c(
                              "select",
                              {
                                ref: `ref_option_${m.id}${option.id}`,
                                refInFor: true,
                                staticClass: "custom-select",
                                attrs: { disabled: _vm.readonly },
                                on: {
                                  change: function ($event) {
                                    return _vm.chooseMenuOption(
                                      m,
                                      option,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "0" } }, [
                                  _vm._v("--"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  {
                                    domProps: {
                                      value:
                                        _vm.choosen_menu[`id_${m.id}`].value,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.choosen_menu[`id_${m.id}`].value
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : option.quantity_type === "limited_by_pers"
                        ? _c(
                            "select",
                            {
                              ref: `ref_option_${m.id}${option.id}`,
                              refInFor: true,
                              staticClass: "custom-select",
                              attrs: { disabled: _vm.readonly },
                              on: {
                                change: function ($event) {
                                  return _vm.chooseMenuOption(m, option, $event)
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "0" } }, [
                                _vm._v("0"),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.choosen_menu[`id_${m.id}`].value * 1,
                                function (menu, index) {
                                  return _c(
                                    "option",
                                    { domProps: { value: index * 1 + 1 } },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(index * 1 + 1) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : option.quantity_type === "unlimited"
                        ? _c("input", {
                            ref: `ref_option_${m.id}${option.id}`,
                            refInFor: true,
                            staticClass: "form-control mb-2",
                            attrs: {
                              type: "number",
                              disabled: _vm.readonly,
                              step: "1",
                              min: "0",
                              value: "0",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.chooseMenuOption(m, option, $event)
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ])
                }),
                0
              )
            : _vm._e(),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }