var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-2" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [
                _vm._v("Liste des clients"),
                _vm.isArchive ? _c("span", [_vm._v(" inactifs")]) : _vm._e(),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent")
        : _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center mb-2",
                },
                [
                  _c("div", [
                    _c("div", { staticStyle: { "margin-top": "4px" } }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.fetchOwnersList.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-control search-client d-inline pr-1",
                              staticStyle: {
                                "border-radius": "20px 0 0 20px !important",
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filter,
                                    expression: "filter",
                                  },
                                ],
                                staticClass: "search-input",
                                attrs: {
                                  placeholder: "Établissement, nom...",
                                  type: "text",
                                },
                                domProps: { value: _vm.filter },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.filter = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "p-0 m-0 clear-search-button",
                                  staticStyle: {
                                    background: "none",
                                    border: "none",
                                  },
                                  attrs: { type: "button" },
                                  on: { click: _vm.resetFilter },
                                },
                                [_c("feather", { attrs: { type: "x" } })],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-sm btn-outline-secondary search-client-btn",
                              staticStyle: { "border-radius": "0 20px 20px 0" },
                              attrs: { type: "submit" },
                            },
                            [_c("feather", { attrs: { type: "search" } })],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm.isYservices
                        ? _c(
                            "router-link",
                            {
                              staticClass: "btn btn-sm btn-circle btn-success",
                              attrs: { to: { name: "admin.owners.create" } },
                            },
                            [
                              _c("feather", { attrs: { type: "plus" } }),
                              _vm._v(
                                "\n                    Créer un nouveau client\n                "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-sm btn-circle btn-success",
                          attrs: {
                            href: "/api/admin/exports/owners",
                            target: "_blank",
                          },
                        },
                        [_vm._v("Exporter les clients")]
                      ),
                      _vm._v(" "),
                      !_vm.isArchive
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-circle btn-success",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.showImportFiducialClientsIdsModal = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    Synchroniser les id IWS\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("switch-input-component", {
                attrs: { "is-form-grid": false },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [_vm._v("Inclure les comptes internes")]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.includeInternalAccounts,
                  callback: function ($$v) {
                    _vm.includeInternalAccounts = $$v
                  },
                  expression: "includeInternalAccounts",
                },
              }),
              _vm._v(" "),
              _c("ns-table", {
                attrs: {
                  tableClass: "table table-sm table-striped border-bottom",
                  "allow-sorting": "",
                  "data-source": _vm.ownersList,
                },
                scopedSlots: _vm._u([
                  {
                    key: "head",
                    fn: function () {
                      return [
                        _c(
                          "ns-th",
                          {
                            attrs: {
                              field: "created_at",
                              "allow-sorting": "",
                              "sort-comparer": _vm.dateTimeCompareFn,
                            },
                          },
                          [_vm._v(" Date d'inscription ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ns-th",
                          {
                            attrs: {
                              field: "fullname",
                              "allow-sorting": "",
                              "sort-comparer": _vm.fullNameCompareFn,
                            },
                          },
                          [_vm._v(" Prénom / Nom ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ns-th",
                          {
                            attrs: {
                              field: "fiducial_client_id",
                              "allow-sorting": "",
                            },
                          },
                          [_vm._v(" IWS ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ns-th",
                          { attrs: { field: "email", "allow-sorting": "" } },
                          [_vm._v(" Email ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ns-th",
                          { attrs: { field: "tel", "allow-sorting": "" } },
                          [_vm._v(" Téléphone ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ns-th",
                          {
                            attrs: {
                              field: "restaurants.data[0].id",
                              "allow-sorting": "",
                            },
                          },
                          [_vm._v(" ID ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ns-th",
                          {
                            attrs: {
                              field: "restaurants.data[0].name",
                              "allow-sorting": "",
                            },
                          },
                          [_vm._v(" Établissement(s) ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ns-th",
                          {
                            attrs: {
                              field: "restaurants.data[0].zipcode",
                              "allow-sorting": "",
                            },
                          },
                          [_vm._v(" Code postal ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ns-th",
                          {
                            attrs: {
                              field: "restaurants.data[0].city",
                              "allow-sorting": "",
                            },
                          },
                          [_vm._v(" Ville ")]
                        ),
                        _vm._v(" "),
                        _c("ns-th", [_vm._v(" Actions ")]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function ({ data }) {
                      return [
                        _c("td", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.displayDate(data.created_at, _vm.DATE_SHORT)
                              ) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticStyle: { "text-transform": "capitalize" } },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(data.firstname) +
                                " " +
                                _vm._s(data.lastname) +
                                "\n                    "
                            ),
                            data.enabled_by_admin
                              ? _c("feather", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.getTooltip(
                                        "Client activé manuellement par un administrateur"
                                      ),
                                      expression:
                                        "getTooltip('Client activé manuellement par un administrateur')",
                                    },
                                  ],
                                  staticClass: "ml-1 text-danger",
                                  attrs: { type: "alert-triangle" },
                                })
                              : data.deleted_at !== null
                              ? _c("feather", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.getTooltip(
                                        "Client perdu (les données associées ont été supprimées)"
                                      ),
                                      expression:
                                        "getTooltip('Client perdu (les données associées ont été supprimées)')",
                                    },
                                  ],
                                  staticClass: "ml-1 text-danger",
                                  attrs: { type: "archive" },
                                })
                              : !data.has_active_subscription
                              ? _c("feather", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.getTooltip("Client inactif"),
                                      expression:
                                        "getTooltip('Client inactif')",
                                    },
                                  ],
                                  staticClass: "ml-1 text-warning",
                                  attrs: { type: "alert-triangle" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(data.fiducial_client_id) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(data.email) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(data.tel) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", {
                          staticClass: "text-right",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.joinWithBr(
                                _vm.arrayPluck("id", data.restaurants.data)
                              )
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("td", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.joinWithBr(
                                _vm.arrayPluck("name", data.restaurants.data)
                              )
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("td", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.joinWithBr(
                                _vm.arrayPluck(
                                  "postal_code",
                                  data.restaurants.data.map(
                                    _vm.getRestaurantAddress
                                  )
                                )
                              )
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("td", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.joinWithBr(
                                _vm.arrayPluck(
                                  "locality",
                                  data.restaurants.data.map(
                                    _vm.getRestaurantAddress
                                  )
                                )
                              )
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticStyle: { width: "76px" } },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value:
                                      data.deleted_at !== null
                                        ? undefined
                                        : _vm.getTooltip(
                                            `Se connecter en tant que '${data.email}'`
                                          ),
                                    expression:
                                      "data.deleted_at !== null ? undefined : getTooltip(`Se connecter en tant que '${data.email}'`)",
                                  },
                                ],
                                staticClass:
                                  "btn btn-sm btn-outline-secondary btn-square",
                                attrs: {
                                  disabled: data.deleted_at !== null,
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectOwner(data)
                                  },
                                },
                              },
                              [_c("feather", { attrs: { type: "log-in" } })],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.getTooltip(
                                      `Voir les details du compte '${data.email}'`
                                    ),
                                    expression:
                                      "getTooltip(`Voir les details du compte '${data.email}'`)",
                                  },
                                ],
                                staticClass:
                                  "btn btn-sm btn-outline-secondary btn-square",
                                class: { disabled: data.deleted_at !== null },
                                attrs: {
                                  to: {
                                    name: "admin.ownerDetails",
                                    params: { owner_id: data.id },
                                  },
                                },
                              },
                              [_c("feather", { attrs: { type: "eye" } })],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
      _vm._v(" "),
      _vm.showImportFiducialClientsIdsModal
        ? _c("import-fiducial-clients-ids", {
            on: {
              close: function ($event) {
                _vm.showImportFiducialClientsIdsModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }