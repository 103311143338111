<template>
    <div class="dropdown dropdown-lang align-content-end">
        <button
            style="text-transform: capitalize; border-color: #ededed"
            data-test-id="btn-change_lang"
            :disabled="disabled"
            class="btn btn-sm btn-outline-secondary dropdown-toggle"
            type="button"
            id="dropdownMenu2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            {{ shouldTranslateLabel ? $tl(`labels.langs.${userLang}`) : getLangLabel(userLang) }}
        </button>
        <div
            class="dropdown-menu dropdown-menu-right shadow"
            style="transform: translate3d(-69px, 29px, 0px); min-width: 100px"
            aria-labelledby="dropdownMenu2">
            <button
                class="dropdown-item d-flex pl-1"
                type="button"
                v-for="lang in ALL_LANGS"
                :key="lang.value"
                :data-test-id="`btn-change_lang-${lang.value}`"
                :disabled="disabled"
                @click="setUserLang(lang.value)">
                <country-flag class="dropdown-item" :country="lang.countryFlag" />
                <span class="name-lang"
                    >{{ shouldTranslateLabel ? $tl(`labels.langs.${lang.value}`) : lang.dropdownLabel }}<slot v-bind="{ lang }"></slot
                ></span>
            </button>
        </div>
    </div>
</template>

<script>
import LangsEnum from "../../mixins/enums/LangsEnum.js";
import CountryFlag from "vue-country-flag";

export default {
    mixins: [LangsEnum],
    props: {
        isInputMode: {
            type: Boolean,
            default: false,
        },
        shouldTranslateLabel: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
        },
    },
    computed: {
        userLang() {
            let lang = null;
            if (this.isInputMode) {
                lang = this.value;
            } else {
                lang = this.$store.getters["users/lang"] || navigator.language || navigator.userLanguage;
            }

            if ("string" === typeof lang) {
                lang = lang.substring(0, 2);
            }

            if (this.isLangValid(lang)) {
                return lang;
            }

            return this.LANG_FR.value;
        },
    },
    methods: {
        setUserLang(newLang) {
            if (this.isInputMode) {
                this.$emit("input", newLang);
            } else {
                this.setUserGlobalLang(newLang);
                this.$emit("lang-changed", newLang);
            }
        },
    },
    components: {
        CountryFlag,
    },
};
</script>

<style scoped>
.dropdown-lang {
    z-index: 99999 !important;
}

.dropdown-lang button {
    font-weight: 400 !important;
    padding: 5px !important;
}

.dropdown-lang button:focus {
    box-shadow: none !important;
}

.dropdown-menu .dropdown-item {
    color: #6c757d !important;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item:focus {
    background-color: #f7f7f7 !important;
    border-color: #f7f7f7 !important;
    color: #6c757d !important;
}

.name-lang {
    display: inline-block;
    margin-left: 10px !important;
    margin-top: 6px !important;
}
</style>
