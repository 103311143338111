<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.routes.rwg") }}</h5>
        <div slot="body">
            <loader-component v-if="isLoading" />
            <form v-else id="rwg-opt-in-form" class="row" @submit.prevent="optIn">
                <div class="col-12">
                    <switch-input-component v-model="isChecked" :is-form-grid="false" required>
                        <template #label>
                            <i18n path="questions.reserveWithGoogle.optIn.clickToAccept" tag="span">
                                <template #agreement>
                                    <a href="https://storage.googleapis.com/noshow-data/noshow/cgu_reserve_with_google.pdf" target="_blank">
                                        {{ $tl("questions.reserveWithGoogle.optIn.agreement", restaurantId) }}
                                    </a>
                                </template>
                            </i18n>
                        </template>
                    </switch-input-component>
                </div>
            </form>
        </div>
        <div slot="footer" class="d-flex">
            <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" :disabled="isLoading" @click="close">
                {{ $tl("labels.form.actions.close", restaurantId) }}
            </button>
            <button type="submit" form="rwg-opt-in-form" class="btn btn-sm btn-success btn-circle ml-2" :disabled="isLoading">
                {{ $tl("labels.form.actions.accept", restaurantId) }}
            </button>
        </div>
    </modal>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";
import SwitchInputComponent from "../../forms/SwitchInputComponent.vue";

export default {
    data() {
        return {
            isLoading: false,
            isChecked: false,
        };
    },
    props: {
        restaurantId: {
            type: Number,
            required: true,
        },
    },
    methods: {
        optIn() {
            this.isLoading = true;

            this.httpPost(`/api/reserve_with_google/${this.restaurantId}/opt_in`, { checked: this.isChecked }).then((response) => {
                if (response !== false) {
                    this.$emit("opted-in");
                } else {
                    this.isLoading = false;
                }
            });
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (!e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else if (!this.isLoading) {
                this.close();
            }
        },
    },
    components: {
        LoaderComponent,
        SwitchInputComponent,
    },
};
</script>
