<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Liste des numéros de chambre</h5>
        <div slot="body">
            <LoaderComponent v-if="loading" />
            <div class="row" v-else-if="roomNumbers">
                <div class="col-12 d-flex">
                    <input v-model="search" class="form-control" placeholder="Filtrer" type="text" />
                    <button
                        type="button"
                        @click="fetchRoomNumbers"
                        class="p-0 m-0 clear-search-button"
                        style="background: none; border: none">
                        <feather type="x" />
                    </button>
                </div>
                <div class="col-12 mt-2">
                    <span class="d-block" v-for="roomNumber in roomNumbersFiltered" :key="roomNumber.id">
                        {{ roomNumber.number }}
                    </span>
                </div>
            </div>
        </div>
        <div slot="footer" class="d-flex">
            <button class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="close">Fermer</button>
        </div>
    </modal>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";

export default {
    data() {
        return {
            loading: false,
            roomNumbers: null,
            search: "",
        };
    },
    computed: {
        roomNumbersFiltered() {
            const roomNumbers = this.roomNumbers || [];
            if (!this.search) return roomNumbers;
            return roomNumbers.filter((number) => number.number.toLowerCase().includes(this.search.toLowerCase()));
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        fetchRoomNumbers() {
            this.loading = true;
            this.roomNumbers = null;
            this.search = "";

            axios
                .get(`/api/restaurants/${this.restaurant_id}/widget/room_numbers`)
                .then((response) => {
                    this.loading = false;
                    this.roomNumbers = response.data.data;
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (!e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        LoaderComponent,
    },
    created() {
        this.fetchRoomNumbers();
    },
};
</script>
