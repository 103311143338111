<template>
    <div>
        <vue-tel-input
            :name="inputName"
            @validate="setPhoneAndCountry"
            v-model="phone.raw"
            :defaultCountry="defaultCountryComputed"
            :enabledCountryCode="enabledCountryCode"
            :class="{ 'form-control': true, 'is-invalid': telIsValid === false }"
            inputClasses="m-0 border-0"
            :disabled="disabled"
            :mode="inputMode"
            :data-test-id="dataTestId_"
            style="border-color: #f0f0f0 !important"
            :placeholder="placeholder"
            :required="required">
        </vue-tel-input>
        <ShowErrors v-if="inputName !== undefined && inputName !== null" class="d-block" :errors="formErrors" :errorKey="inputName" />
        <ShowErrors v-if="inputName !== undefined && inputName !== null" class="d-block" :errors="formErrors" :errorKey="`${inputName}Country`" />
    </div>
</template>

<script>
import ShowErrors from "../errors/ShowErrors.vue";

export default {
    name: "TelInputComponent",
    data() {
        return {
            phone: {
                isValid: null,
                isMobile: null,
                country: null,
                raw: null,
            },
        };
    },
    props: {
        formErrors: {
            default: undefined,
        },
        value: {
            type: String,
            default: null,
        },
        enabledCountryCode: {
            type: Boolean,
            default: true,
        },
        inputName: {
            type: String,
            default: undefined,
        },
        defaultCountry: {
            type: String,
            default: "",
        },
        inputMode: {
            type: String,
            default: "international",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "-- -- -- -- --",
        },
        dataTestId: {
            type: String,
            default: undefined,
        },
        restaurantId: {
            type: Number,
            default: undefined,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        dataTestId_() {
            if (this.dataTestId !== undefined) {
                return this.dataTestId;
            }

            if (this.inputName !== undefined) {
                return `input-tel-${this.inputName}`;
            }

            return undefined;
        },
        tel: {
            get() {
                return this.value;
            },
            set(newVal) {
                this.$emit("input", newVal);
            },
        },
        telCountry: {
            get() {
                return this.phone.country;
            },
            set(newVal) {
                this.phone.country = newVal;
                this.$emit("set-tel-country", newVal);
            },
        },
        telIsMobile: {
            get() {
                return this.phone.isMobile;
            },
            set(newVal) {
                this.phone.isMobile = newVal;
                this.$emit("set-tel-is-mobile", newVal);
            },
        },
        telIsValid: {
            get() {
                return this.phone.isValid;
            },
            set(newVal) {
                this.phone.isValid = newVal;
                this.$emit("set-tel-is-valid", newVal);
            },
        },
        defaultCountryComputed() {
            if (this.defaultCountry !== "") {
                return this.defaultCountry;
            }

            const restaurantId = this.restaurantId || this.$route.params.restaurant_id;

            if (restaurantId) {
                const restaurant = this.$store.getters["restaurants/findRestaurantById"](restaurantId);

                if (restaurant?.default_tel_country) {
                    return restaurant.default_tel_country;
                }
            }

            return "FR";
        },
    },
    methods: {
        resetInput() {
            this.phone.raw = "";
            this.resetPhone(true);
        },
        resetPhone(resetIsValid = false) {
            this.tel = null;
            this.telCountry = null;
            this.telIsMobile = null;

            if (resetIsValid) {
                this.telIsValid = null;
            }
        },
        setPhoneAndCountry(inputPhoneObject) {
            if (inputPhoneObject.number && inputPhoneObject.number.international && (inputPhoneObject.regionCode || inputPhoneObject.country)) {
                this.telIsValid = inputPhoneObject.isValid === true;

                if (this.telIsValid) {
                    this.tel = inputPhoneObject.number.international;
                    this.telIsMobile = inputPhoneObject.type === "mobile";
                    this.telCountry = inputPhoneObject.regionCode || inputPhoneObject.country.iso2;
                } else {
                    this.resetPhone();
                }
            } else {
                this.resetPhone(true);
            }
        },
    },
    components: {
        ShowErrors,
    },
    created() {
        this.phone.raw = this.tel;
    },
};
</script>
