var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.has_rights &&
    (typeof _vm.entry.show === "undefined" || _vm.entry.show) &&
    (typeof _vm.entry.shouldBeShownForRestaurant === "undefined" ||
      _vm.entry.shouldBeShownForRestaurant(_vm.restaurantId))
    ? _c(
        "li",
        {
          staticClass: "nav-item btn-en-service",
          class: { "text-center": _vm.isCollapsed },
        },
        [
          _c(
            _vm.entry.disabled ? "span" : "router-link",
            {
              tag: "router-link",
              staticClass: "nav-link",
              style: _vm.entry.disabled
                ? "opacity:0.5 !important;pointer-events:none !important;"
                : "",
              attrs: {
                to: _vm.entry.to,
                "active-class": "active",
                exact: _vm.entry.exactUrlMatching,
              },
            },
            [
              _c("feather", {
                staticClass: "w-auto h-auto",
                class: _vm.isCollapsed ? "feather-mr-0 mr-0" : "",
                attrs: {
                  type: _vm.entry.icon,
                  size: _vm.isCollapsed ? "26" : "22",
                },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isCollapsed,
                      expression: "!isCollapsed",
                    },
                  ],
                  staticClass: "display-sid-min",
                },
                [_vm._v(_vm._s(_vm.entryTitle))]
              ),
              _vm._v(" "),
              _vm.hasUnseenFailedMailsNotificationsLogs
                ? _c("feather", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltip(
                          _vm.$tl(
                            "errors.common.notificationsLogs.hasUnseenFailed",
                            _vm.restaurantId
                          )
                        ),
                        expression:
                          "getTooltip($tl('errors.common.notificationsLogs.hasUnseenFailed', restaurantId))",
                      },
                    ],
                    staticClass: "text-danger",
                    staticStyle: { width: "18px", height: "18px" },
                    attrs: { type: "alert-triangle" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasNewBadge && !_vm.isCollapsed ? _c("newBadge") : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }