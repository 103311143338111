export default {
    data() {
        return {
            STRIPE_PLAN_TYPE_MONTHLY: {
                label: "Mensuel",
                value: "monthly",
            },
            STRIPE_PLAN_TYPE_YEARLY: {
                label: "Annuel",
                value: "yearly",
            },
        };
    },
    methods: {
        getStripePlanTypeLabel(value) {
            const found = this.ALL_STRIPE_PLAN_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return this.$t(`labels.${value}`);
            return value;
        },
    },
    computed: {
        ALL_STRIPE_PLAN_TYPES() {
            return [this.STRIPE_PLAN_TYPE_MONTHLY, this.STRIPE_PLAN_TYPE_YEARLY];
        },
    },
};
