var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading > 0
        ? _c("LoaderComponent")
        : _c(
            "div",
            [
              _c(
                "div",
                [
                  _c("gift-vouchers-category-component", {
                    attrs: {
                      categories: _vm.categories,
                      "current-category": _vm.currentCategory,
                    },
                    on: {
                      "category-changed": function ($event) {
                        _vm.currentCategory = $event
                      },
                    },
                    model: {
                      value: _vm.customCategoryName,
                      callback: function ($$v) {
                        _vm.customCategoryName = $$v
                      },
                      expression: "customCategoryName",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-5" }, [
                              _c("label", [_vm._v("Type de produit")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-7" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-around align-items-center lead-switch switch-choice",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.product_type,
                                        expression: "product_type",
                                      },
                                    ],
                                    staticClass:
                                      "switch align-self-center is-rounded",
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(_vm.product_type)
                                        ? _vm._i(_vm.product_type, null) > -1
                                        : _vm.product_type,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.product_type,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.product_type = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.product_type = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.product_type = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.isYservices ? "Prestation" : "Offre"
                                      ) +
                                      " "
                                  ),
                                  _c("label", {
                                    on: {
                                      click: function ($event) {
                                        _vm.product_type = !_vm.product_type
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    "Chèque cadeau\n                                "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  !_vm.product_type
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "border-light b-radius-20 p-4" },
                            [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [
                                    _vm._v(
                                      "Nom de " +
                                        _vm._s(
                                          _vm.isYservices
                                            ? "la prestation"
                                            : "l'offre"
                                        ) +
                                        " "
                                    ),
                                    _c("small", [_vm._v("*")]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-7" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.product.name,
                                          expression: "product.name",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { maxlength: "70", type: "text" },
                                      domProps: { value: _vm.product.name },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.product,
                                            "name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("small", { staticClass: "text-muted" }, [
                                      _vm._v("Max : 70 caractères"),
                                    ]),
                                    _vm._v(" "),
                                    _c("ShowErrors", {
                                      attrs: {
                                        errors: _vm.errors.form,
                                        errorKey: "name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c(
                                    "label",
                                    { attrs: { for: "txt-description" } },
                                    [
                                      _vm._v("Description "),
                                      _c("small", [_vm._v("*")]),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-7" },
                                  [
                                    _c("TextAreaComponentOld", {
                                      attrs: {
                                        maxNbChar: 150,
                                        txtAreaId: "txt-description",
                                        txtAreaObj: _vm.product,
                                        txtAreaObjKey: "description",
                                        txtAreaRows: "3",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("ShowErrors", {
                                      attrs: {
                                        errors: _vm.errors.form,
                                        errorKey: "description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [
                                    _vm._v("Image "),
                                    _c("small", [_vm._v("(recommandé)")]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-7" },
                                  [
                                    _c("image-uploader", {
                                      attrs: {
                                        "public-image-uri":
                                          _vm.product.img !== null
                                            ? _vm.product.img.public_uri
                                            : null,
                                        "image-path":
                                          _vm.product.img !== null
                                            ? _vm.product.img.path
                                            : null,
                                        "upload-uri": _vm.imgPostUri,
                                        "delete-uri": _vm.imgPostUri,
                                        maxSize: 10,
                                      },
                                      on: {
                                        "image-uploaded": function ($event) {
                                          _vm.product.img = $event
                                        },
                                        "image-deleted": function ($event) {
                                          _vm.product.img = null
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [
                                    _vm._v("Prix TTC"),
                                    _c("small", [_vm._v("*")]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7" }, [
                                  _c(
                                    "div",
                                    { staticClass: "row" },
                                    [
                                      _c("div", { staticClass: "col-md-4" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.product.price,
                                              expression: "product.price",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "number" },
                                          domProps: {
                                            value: _vm.product.price,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.product,
                                                "price",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-8 mt-2" },
                                        [_vm._v("euros")]
                                      ),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        staticClass: "col-12",
                                        attrs: {
                                          errors: _vm.errors.form,
                                          errorKey: "price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [
                                    _vm._v("Nombre de personne(s) "),
                                    _c("small", [_vm._v("*")]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7" }, [
                                  _c(
                                    "div",
                                    { staticClass: "row" },
                                    [
                                      _c("div", { staticClass: "col-md-4" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.product.nb_pers,
                                              expression: "product.nb_pers",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "number" },
                                          domProps: {
                                            value: _vm.product.nb_pers,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.product,
                                                "nb_pers",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-8 mt-2" },
                                        [_vm._v("personne(s)")]
                                      ),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        staticClass: "col-12",
                                        attrs: {
                                          errors: _vm.errors.form,
                                          errorKey: "nb_pers",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              !_vm.isTvaDisabled
                                ? _c("div", { staticClass: "row mb-2" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-5 pt-1" },
                                      [
                                        _c("label", [
                                          _vm._v("TVA "),
                                          _c("small", [_vm._v("*")]),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-md-7" }, [
                                      _c(
                                        "div",
                                        { staticClass: "row" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-4" },
                                            [
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.product.tva,
                                                      expression: "product.tva",
                                                    },
                                                  ],
                                                  staticClass: "custom-select",
                                                  on: {
                                                    change: function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        _vm.product,
                                                        "tva",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    {
                                                      domProps: { value: null },
                                                    },
                                                    [_vm._v("--")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    {
                                                      domProps: { value: 5.5 },
                                                    },
                                                    [_vm._v("5.5")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    { domProps: { value: 10 } },
                                                    [_vm._v("10")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    { domProps: { value: 20 } },
                                                    [_vm._v("20")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-md-8 mt-2" },
                                            [_vm._v("%")]
                                          ),
                                          _vm._v(" "),
                                          _c("ShowErrors", {
                                            staticClass: "col-12",
                                            attrs: {
                                              errors: _vm.errors.form,
                                              errorKey: "tva",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [
                                    _vm._v("Validité "),
                                    _c("small", [_vm._v("*")]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7" }, [
                                  _c(
                                    "div",
                                    { staticClass: "row" },
                                    [
                                      _c("div", { staticClass: "col-md-4" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.product.validity,
                                              expression: "product.validity",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "number" },
                                          domProps: {
                                            value: _vm.product.validity,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.product,
                                                "validity",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-8 mt-2" },
                                        [_vm._v("jours")]
                                      ),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        staticClass: "col-12",
                                        attrs: {
                                          errors: _vm.errors.form,
                                          errorKey: "validity",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.hasBooking
                                ? _c("div", { staticClass: "row mb-2" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-5 pt-1" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.giftVouchers.products.service",
                                                _vm.restaurantId
                                              )
                                            )
                                          ),
                                          _c("small", [_vm._v(" *")]),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-7 pt-2" },
                                      [
                                        _c("ShowErrors", {
                                          staticClass: "mb-2",
                                          attrs: {
                                            errors: _vm.errors.form,
                                            errorKey: "services",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "mb-2" }, [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "container-box",
                                              staticStyle: {
                                                "margin-bottom": "0",
                                                width: "100%",
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.all_services,
                                                    expression: "all_services",
                                                  },
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.all_services
                                                  )
                                                    ? _vm._i(
                                                        _vm.all_services,
                                                        null
                                                      ) > -1
                                                    : _vm.all_services,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a = _vm.all_services,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          (_vm.all_services =
                                                            $$a.concat([$$v]))
                                                      } else {
                                                        $$i > -1 &&
                                                          (_vm.all_services =
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                      }
                                                    } else {
                                                      _vm.all_services = $$c
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                              _vm._v(
                                                " Tous\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.services,
                                          function (service) {
                                            return _c(
                                              "div",
                                              {
                                                key: service.id,
                                                staticClass: "mb-2",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "container-box",
                                                    staticStyle: {
                                                      "margin-bottom": "0",
                                                      width: "100%",
                                                    },
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            service.checked,
                                                          expression:
                                                            "service.checked",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "checkbox",
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          service.checked
                                                        )
                                                          ? _vm._i(
                                                              service.checked,
                                                              null
                                                            ) > -1
                                                          : service.checked,
                                                      },
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            var $$a =
                                                                service.checked,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    service,
                                                                    "checked",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    service,
                                                                    "checked",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                service,
                                                                "checked",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                          function ($event) {
                                                            return _vm.serviceChecked(
                                                              service
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      staticClass: "checkmark",
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(service.name) +
                                                        " (" +
                                                        _vm._s(
                                                          service.category
                                                        ) +
                                                        ")\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [
                                    _vm._v("Conditions d'utilisation"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-7" },
                                  [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.product.terms_of_use,
                                          expression: "product.terms_of_use",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { rows: "3" },
                                      domProps: {
                                        value: _vm.product.terms_of_use,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.product,
                                            "terms_of_use",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("ShowErrors", {
                                      attrs: {
                                        errors: _vm.errors.form,
                                        errorKey: "terms_of_use",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            {
                              staticClass: "border-light b-radius-20 mt-3 p-4",
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [_vm._v("Options")]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-7" },
                                  [
                                    _vm._l(
                                      _vm.gv_products_options,
                                      function (gv_products_option) {
                                        return _c(
                                          "div",
                                          {
                                            key: gv_products_option.id,
                                            staticClass: "row mb-1",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mb-2 pt-1" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "container-box",
                                                        staticStyle: {
                                                          "margin-bottom": "0",
                                                          width: "100%",
                                                        },
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                gv_products_option.active,
                                                              expression:
                                                                "gv_products_option.active",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "checkbox",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              Array.isArray(
                                                                gv_products_option.active
                                                              )
                                                                ? _vm._i(
                                                                    gv_products_option.active,
                                                                    null
                                                                  ) > -1
                                                                : gv_products_option.active,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  gv_products_option.active,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      gv_products_option,
                                                                      "active",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      gv_products_option,
                                                                      "active",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  gv_products_option,
                                                                  "active",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", {
                                                          staticClass:
                                                            "checkmark",
                                                        }),
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              gv_products_option.name
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-6 pt-1 text-right",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              gv_products_option.include
                                                                ? "Inclus"
                                                                : "+ " +
                                                                    _vm.formatCurrency(
                                                                      gv_products_option.price
                                                                    )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-6" },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "tooltip",
                                                                rawName:
                                                                  "v-tooltip",
                                                                value: {
                                                                  delay: {
                                                                    show: 400,
                                                                    hide: 0,
                                                                  },
                                                                  content:
                                                                    "Éditer",
                                                                },
                                                                expression:
                                                                  "{\n                                                        delay: { show: 400, hide: 0 },\n                                                        content: 'Éditer',\n                                                    }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "btn btn-sm btn-success btn-square",
                                                            attrs: {
                                                              title:
                                                                "Modifier la réservation",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editProductOption(
                                                                  gv_products_option
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "feather feather-edit sc-dnqmqq jxshSx",
                                                                attrs: {
                                                                  xmlns:
                                                                    "http://www.w3.org/2000/svg",
                                                                  width: "24",
                                                                  height: "24",
                                                                  viewBox:
                                                                    "0 0 24 24",
                                                                  fill: "none",
                                                                  stroke:
                                                                    "currentColor",
                                                                  "stroke-width":
                                                                    "2",
                                                                  "stroke-linecap":
                                                                    "round",
                                                                  "stroke-linejoin":
                                                                    "round",
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              },
                                                              [
                                                                _c("path", {
                                                                  attrs: {
                                                                    d: "M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("path", {
                                                                  attrs: {
                                                                    d: "M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "tooltip",
                                                                rawName:
                                                                  "v-tooltip",
                                                                value: {
                                                                  delay: {
                                                                    show: 400,
                                                                    hide: 0,
                                                                  },
                                                                  content:
                                                                    "Supprimer",
                                                                },
                                                                expression:
                                                                  "{\n                                                        delay: { show: 400, hide: 0 },\n                                                        content: 'Supprimer',\n                                                    }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "btn btn-sm btn-danger btn-square",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteProductOption(
                                                                  gv_products_option
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "feather feather-trash-2 sc-dnqmqq jxshSx",
                                                                attrs: {
                                                                  xmlns:
                                                                    "http://www.w3.org/2000/svg",
                                                                  width: "24",
                                                                  height: "24",
                                                                  viewBox:
                                                                    "0 0 24 24",
                                                                  fill: "none",
                                                                  stroke:
                                                                    "currentColor",
                                                                  "stroke-width":
                                                                    "2",
                                                                  "stroke-linecap":
                                                                    "round",
                                                                  "stroke-linejoin":
                                                                    "round",
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              },
                                                              [
                                                                _c("polyline", {
                                                                  attrs: {
                                                                    points:
                                                                      "3 6 5 6 21 6",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("path", {
                                                                  attrs: {
                                                                    d: "M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "10",
                                                                    y1: "11",
                                                                    x2: "10",
                                                                    y2: "17",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "14",
                                                                    y1: "11",
                                                                    x2: "14",
                                                                    y2: "17",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c("ShowErrors", {
                                      staticClass: "d-block",
                                      attrs: {
                                        errors: _vm.errors.form,
                                        errorKey: "product_options",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-success btn-circle mt-2",
                                        on: {
                                          click: function ($event) {
                                            return _vm.addProductOption()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "feather feather-plus sc-dnqmqq jxshSx",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "24",
                                              height: "24",
                                              viewBox: "0 0 24 24",
                                              fill: "none",
                                              stroke: "currentColor",
                                              "stroke-width": "2",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                              "aria-hidden": "true",
                                            },
                                          },
                                          [
                                            _c("line", {
                                              attrs: {
                                                x1: "12",
                                                y1: "5",
                                                x2: "12",
                                                y2: "19",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("line", {
                                              attrs: {
                                                x1: "5",
                                                y1: "12",
                                                x2: "19",
                                                y2: "12",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Option")]),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "border-light b-radius-20 p-4" },
                            [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [
                                    _vm._v("Nom du chèque cadeau "),
                                    _c("small", [_vm._v("*")]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-7" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.product.name,
                                          expression: "product.name",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { maxlength: "70", type: "text" },
                                      domProps: { value: _vm.product.name },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.product,
                                            "name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("small", { staticClass: "text-muted" }, [
                                      _vm._v("Max : 70 caractères"),
                                    ]),
                                    _vm._v(" "),
                                    _c("ShowErrors", {
                                      attrs: {
                                        errors: _vm.errors.form,
                                        errorKey: "name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c(
                                    "label",
                                    {
                                      attrs: { for: "txt-description-amount" },
                                    },
                                    [
                                      _vm._v("Description "),
                                      _c("small", [_vm._v("*")]),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-7" },
                                  [
                                    _c("TextAreaComponentOld", {
                                      attrs: {
                                        maxNbChar: 150,
                                        txtAreaId: "txt-description-amount",
                                        txtAreaObj: _vm.product,
                                        txtAreaObjKey: "description",
                                        txtAreaRows: "3",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("ShowErrors", {
                                      attrs: {
                                        errors: _vm.errors.form,
                                        errorKey: "description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [
                                    _vm._v("Image "),
                                    _c("small", [_vm._v("(recommandé)")]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-7" },
                                  [
                                    _c("image-uploader", {
                                      attrs: {
                                        "public-image-uri":
                                          _vm.product.img !== null
                                            ? _vm.product.img.public_uri
                                            : null,
                                        "image-path":
                                          _vm.product.img !== null
                                            ? _vm.product.img.path
                                            : null,
                                        "upload-uri": _vm.imgPostUri,
                                        "delete-uri": _vm.imgPostUri,
                                        maxSize: 10,
                                      },
                                      on: {
                                        "image-uploaded": function ($event) {
                                          _vm.product.img = $event
                                        },
                                        "image-deleted": function ($event) {
                                          _vm.product.img = null
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [_vm._v("Montants prédéfinis")]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-6" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.amount_to_add,
                                            expression: "amount_to_add",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "number" },
                                        domProps: { value: _vm.amount_to_add },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.amount_to_add =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.amountToAdd
                                        ? _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errors.amountToAdd)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-6 pt-1" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-success btn-circle ml-2",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addAmount()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "feather feather-plus sc-dnqmqq jxshSx",
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "24",
                                                height: "24",
                                                viewBox: "0 0 24 24",
                                                fill: "none",
                                                stroke: "currentColor",
                                                "stroke-width": "2",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                                "aria-hidden": "true",
                                              },
                                            },
                                            [
                                              _c("line", {
                                                attrs: {
                                                  x1: "12",
                                                  y1: "5",
                                                  x2: "12",
                                                  y2: "19",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "5",
                                                  y1: "12",
                                                  x2: "19",
                                                  y2: "12",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Ajouter")]),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "row" },
                                    [
                                      _c("div", { staticClass: "col-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "mt-2 tags" },
                                          _vm._l(
                                            _vm.product.predefined_amounts,
                                            function (amount) {
                                              return _c(
                                                "span",
                                                {
                                                  key: amount,
                                                  staticClass: "tag is-rounded",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(amount) +
                                                      " €\n                                                "
                                                  ),
                                                  _c("button", {
                                                    staticClass: "delete",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delAmount(
                                                          amount
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        staticClass: "col-12",
                                        attrs: {
                                          errors: _vm.errors.form,
                                          errorKey: "predefined_amounts",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [
                                    _vm._v(
                                      "Autoriser les montants personnalisés"
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-7 pt-1" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-inline-block align-middle lead-switch",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.product
                                                  .allow_custom_amounts,
                                              expression:
                                                "product.allow_custom_amounts",
                                            },
                                          ],
                                          staticClass:
                                            "switch align-self-center is-rounded",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.product.allow_custom_amounts
                                            )
                                              ? _vm._i(
                                                  _vm.product
                                                    .allow_custom_amounts,
                                                  null
                                                ) > -1
                                              : _vm.product
                                                  .allow_custom_amounts,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.product
                                                    .allow_custom_amounts,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.product,
                                                      "allow_custom_amounts",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.product,
                                                      "allow_custom_amounts",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.product,
                                                  "allow_custom_amounts",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("label", {
                                          on: {
                                            click: function ($event) {
                                              _vm.product.allow_custom_amounts =
                                                !_vm.product
                                                  .allow_custom_amounts
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("ShowErrors", {
                                      attrs: {
                                        errors: _vm.errors.form,
                                        errorKey: "allow_custom_amounts",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.product.allow_custom_amounts
                                ? [
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-5 pt-1" },
                                        [
                                          _c("label", [
                                            _vm._v("Montant minimum "),
                                            _c("small", [_vm._v("*")]),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-md-7" }, [
                                        _c(
                                          "div",
                                          { staticClass: "row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-4" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.product.min_amount,
                                                      expression:
                                                        "product.min_amount",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: { type: "number" },
                                                  domProps: {
                                                    value:
                                                      _vm.product.min_amount,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.product,
                                                        "min_amount",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-8 mt-2" },
                                              [_vm._v("euros")]
                                            ),
                                            _vm._v(" "),
                                            _c("ShowErrors", {
                                              staticClass: "col-12",
                                              attrs: {
                                                errors: _vm.errors.form,
                                                errorKey: "min_amount",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-5 pt-1" },
                                        [
                                          _c("label", [
                                            _vm._v("Montant maximum "),
                                            _c("small", [_vm._v("*")]),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-md-7" }, [
                                        _c(
                                          "div",
                                          { staticClass: "row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-4" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.product.max_amount,
                                                      expression:
                                                        "product.max_amount",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: { type: "number" },
                                                  domProps: {
                                                    value:
                                                      _vm.product.max_amount,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.product,
                                                        "max_amount",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-8 mt-2" },
                                              [_vm._v("euros")]
                                            ),
                                            _vm._v(" "),
                                            _c("ShowErrors", {
                                              staticClass: "col-12",
                                              attrs: {
                                                errors: _vm.errors.form,
                                                errorKey: "max_amount",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [
                                    _vm._v("Validité "),
                                    _c("small", [_vm._v("*")]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7" }, [
                                  _c(
                                    "div",
                                    { staticClass: "row" },
                                    [
                                      _c("div", { staticClass: "col-md-4" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.product.validity,
                                              expression: "product.validity",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "number" },
                                          domProps: {
                                            value: _vm.product.validity,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.product,
                                                "validity",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-8 mt-2" },
                                        [_vm._v("jours")]
                                      ),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        staticClass: "col-12",
                                        attrs: {
                                          errors: _vm.errors.form,
                                          errorKey: "validity",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.hasBooking
                                ? _c("div", { staticClass: "row mb-2" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-5 pt-1" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.giftVouchers.products.service",
                                                _vm.restaurantId
                                              )
                                            ) + " "
                                          ),
                                          _c("small", [_vm._v(" *")]),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-7 pt-2" },
                                      [
                                        _c("ShowErrors", {
                                          staticClass: "mb-2",
                                          attrs: {
                                            errors: _vm.errors.form,
                                            errorKey: "services",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "mb-2" }, [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "container-box",
                                              staticStyle: {
                                                "margin-bottom": "0",
                                                width: "100%",
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.all_services,
                                                    expression: "all_services",
                                                  },
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.all_services
                                                  )
                                                    ? _vm._i(
                                                        _vm.all_services,
                                                        null
                                                      ) > -1
                                                    : _vm.all_services,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a = _vm.all_services,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          (_vm.all_services =
                                                            $$a.concat([$$v]))
                                                      } else {
                                                        $$i > -1 &&
                                                          (_vm.all_services =
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                      }
                                                    } else {
                                                      _vm.all_services = $$c
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                              _vm._v(
                                                " Tous\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.services,
                                          function (service) {
                                            return _c(
                                              "div",
                                              {
                                                key: service.id,
                                                staticClass: "mb-2",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "container-box",
                                                    staticStyle: {
                                                      "margin-bottom": "0",
                                                      width: "100%",
                                                    },
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            service.checked,
                                                          expression:
                                                            "service.checked",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "checkbox",
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          service.checked
                                                        )
                                                          ? _vm._i(
                                                              service.checked,
                                                              null
                                                            ) > -1
                                                          : service.checked,
                                                      },
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            var $$a =
                                                                service.checked,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    service,
                                                                    "checked",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    service,
                                                                    "checked",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                service,
                                                                "checked",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                          function ($event) {
                                                            return _vm.serviceChecked(
                                                              service
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      staticClass: "checkmark",
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(service.name) +
                                                        " (" +
                                                        _vm._s(
                                                          service.category
                                                        ) +
                                                        ")\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasClickAndCollect &&
                              (_vm.collect_enabled ||
                                _vm.delivery_enabled ||
                                _vm.dispatch_enabled)
                                ? _c("div", { staticClass: "row mb-2" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-5 pt-1" },
                                      [
                                        _c("label", [
                                          _vm._v("Module Vente en ligne"),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-7 pt-2" },
                                      [
                                        _vm.collect_enabled
                                          ? _c("div", { staticClass: "mb-2" }, [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "container-box",
                                                  staticStyle: {
                                                    "margin-bottom": "0",
                                                    width: "100%",
                                                  },
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.product
                                                            .collect_enabled,
                                                        expression:
                                                          "product.collect_enabled",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.product
                                                          .collect_enabled
                                                      )
                                                        ? _vm._i(
                                                            _vm.product
                                                              .collect_enabled,
                                                            null
                                                          ) > -1
                                                        : _vm.product
                                                            .collect_enabled,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.product
                                                              .collect_enabled,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm.product,
                                                                "collect_enabled",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm.product,
                                                                "collect_enabled",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm.product,
                                                            "collect_enabled",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", {
                                                    staticClass: "checkmark",
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.COLLECT_TYPE_COLLECT
                                                          .label
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("ShowErrors", {
                                          staticClass: "mb-2",
                                          attrs: {
                                            errors: _vm.errors.form,
                                            errorKey: "collect_enabled",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm.delivery_enabled
                                          ? _c("div", { staticClass: "mb-2" }, [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "container-box",
                                                  staticStyle: {
                                                    "margin-bottom": "0",
                                                    width: "100%",
                                                  },
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.product
                                                            .delivery_enabled,
                                                        expression:
                                                          "product.delivery_enabled",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.product
                                                          .delivery_enabled
                                                      )
                                                        ? _vm._i(
                                                            _vm.product
                                                              .delivery_enabled,
                                                            null
                                                          ) > -1
                                                        : _vm.product
                                                            .delivery_enabled,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.product
                                                              .delivery_enabled,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm.product,
                                                                "delivery_enabled",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm.product,
                                                                "delivery_enabled",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm.product,
                                                            "delivery_enabled",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", {
                                                    staticClass: "checkmark",
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .COLLECT_TYPE_DELIVERY
                                                          .label
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("ShowErrors", {
                                          staticClass: "mb-2",
                                          attrs: {
                                            errors: _vm.errors.form,
                                            errorKey: "delivery_enabled",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm.dispatch_enabled
                                          ? _c("div", { staticClass: "mb-2" }, [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "container-box",
                                                  staticStyle: {
                                                    "margin-bottom": "0",
                                                    width: "100%",
                                                  },
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.product
                                                            .dispatch_enabled,
                                                        expression:
                                                          "product.dispatch_enabled",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.product
                                                          .dispatch_enabled
                                                      )
                                                        ? _vm._i(
                                                            _vm.product
                                                              .dispatch_enabled,
                                                            null
                                                          ) > -1
                                                        : _vm.product
                                                            .dispatch_enabled,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.product
                                                              .dispatch_enabled,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm.product,
                                                                "dispatch_enabled",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm.product,
                                                                "dispatch_enabled",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm.product,
                                                            "dispatch_enabled",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", {
                                                    staticClass: "checkmark",
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .COLLECT_TYPE_DISPATCH
                                                          .label
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("ShowErrors", {
                                          staticClass: "mb-2",
                                          attrs: {
                                            errors: _vm.errors.form,
                                            errorKey: "dispatch_enabled",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [
                                    _vm._v("Conditions d'utilisation"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-7" },
                                  [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.product.terms_of_use,
                                          expression: "product.terms_of_use",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { rows: "3" },
                                      domProps: {
                                        value: _vm.product.terms_of_use,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.product,
                                            "terms_of_use",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("ShowErrors", {
                                      attrs: {
                                        errors: _vm.errors.form,
                                        errorKey: "terms_of_use",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          ),
                        ]),
                      ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showAddProductOptionModal
                ? _c("add-product-option-modal", {
                    attrs: { product_option: _vm.product_option },
                    on: {
                      saved: _vm.productOptionSaved,
                      close: function ($event) {
                        _vm.showAddProductOptionModal = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }