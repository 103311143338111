<template>
    <div>
        <div style="margin-top: 4px" class="d-inline-block">
            <form @submit.prevent="startSearch">
                <div style="border-radius: 20px 0 0 20px !important" class="form-control search-client d-inline pr-1">
                    <input
                        class="search-input"
                        ref="searchInput"
                        :placeholder="$tl('labels.clients.searchPlaceHolder')"
                        type="text"
                        v-model="search" />
                    <button type="button" @click="clearSearch" class="p-0 m-0 clear-search-button" style="background: none; border: none">
                        <feather type="x"></feather>
                    </button>
                </div>
                <button
                    type="submit"
                    :disabled="loading"
                    :class="{ disabled: loading }"
                    class="btn btn-sm btn-outline-secondary search-client-btn"
                    style="border-radius: 0 20px 20px 0">
                    <feather type="search"></feather>
                </button>
            </form>
        </div>
        <div class="row m-0 mt-1">
            <div class="col-12">
                <LoaderComponent v-if="loading" />
                <template v-else>
                    <table v-if="clients_.length > 0" class="capitalize table table-sm table-striped border-bottom">
                        <thead class="border-bottom">
                            <tr>
                                <th>{{ $tl("labels.form.client") }}</th>
                                <th>{{ $tl("labels.form.email") }}</th>
                                <th>{{ $tl("labels.form.phoneNumber") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="client in clients_" :key="client.id" class="clickable" @click="onClientSelected(client)">
                                <td>
                                    <inline-client :client="client" show-noshows></inline-client>
                                </td>
                                <td>{{ client.email }}</td>
                                <td>{{ client.tel }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <span v-else class="font-italic">{{ $tl("labels.noResult") }}</span>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";
import InlineClient from "./inlineClient.vue";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";

export default {
    data() {
        return {
            search: "",
            loading: false,
            clients: [],
        };
    },
    props: {
        avoidClientId: {
            default: null,
        },
    },
    mixins: [ModuleTypesEnum],
    computed: {
        restaurant_id() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        clients_() {
            return this.clients.filter((client) => client.id !== this.avoidClientId);
        },
    },
    methods: {
        onClientSelected(client) {
            this.$emit("client-selected", client);
        },
        clearSearch() {
            this.search = "";
            this.clients = [];
        },
        startSearch() {
            if (this.loading || !this.search) {
                return;
            }

            this.loading = true;

            this.httpGet(`/api/restaurants/${this.restaurant_id}/clients/search?search=${this.search}`)
                .then((response) => {
                    if (response !== false) {
                        this.clients = Object.values(response.data);
                    }
                })
                .finally(() => (this.loading = false));
        },
    },
    components: {
        LoaderComponent,
        InlineClient,
    },
    mounted() {
        if (this.$refs.searchInput) {
            this.$refs.searchInput.focus();
        }
    },
};
</script>
