<template>
    <div>
        <tab-bar direction="horizontal" side="top" :entries="tabBarEntries" />
        <router-view />
    </div>
</template>

<script>
import TabBar from "../../components/tabBar/TabBar.vue";

export default {
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        tabBarEntries() {
            return [
                {
                    to: {
                        name: "gift_vouchers.restaurants.products.products",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Produits",
                    exactUrlMatching: true,
                },
                {
                    to: {
                        name: "gift_vouchers.restaurants.categories",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Catégories",
                    exactUrlMatching: false,
                },
            ];
        },
    },
    components: {
        TabBar,
    },
};
</script>
