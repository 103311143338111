export default {
    data() {
        return {
            CARD_PAYMENT_SYSTEM_STRIPE: {
                label: "Stripe",
                value: "stripe",
            },
            CARD_PAYMENT_SYSTEM_PAYPLUG: {
                label: "PayPlug",
                value: "payplug",
            },
        };
    },
    methods: {
        getCardPaymentSystemLabel(value) {
            const found = this.ALL_CARD_PAYMENT_SYSTEMS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_CARD_PAYMENT_SYSTEMS() {
            return [this.CARD_PAYMENT_SYSTEM_STRIPE, this.CARD_PAYMENT_SYSTEM_PAYPLUG];
        },
    },
};
