var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { ref: "dropdown", staticClass: "dropdown" }, [
      _c("div", { attrs: { id: "dropdown-container" } }, [
        _c(
          "span",
          {
            staticClass:
              "btn btn-sm btn-outline-secondary btn-circle resa-status",
            class: _vm.currentClass,
            on: { click: _vm.clickStatusTag },
          },
          [
            _vm._v(
              "\n                " + _vm._s(_vm.currentLabel) + "\n            "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }