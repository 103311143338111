//Default views
import Home from "../../views/Home.vue";

// App vues
import AuthRegister from "../../views/Auth/Register.vue";
import AuthLogin from "../../views/Auth/Login.vue";
import AuthResetPassword from "../../views/Auth/ResetPassword.vue";

import RestaurantsCreate from "../../views/Default/Restaurants/Create.vue";

// All services
import RGPD from "../../views/Default/RGPD/RGPD.vue";
import MyAccount from "../../views/MyAccount.vue";
import RegistrationFinalize from "../../views/Auth/Register/Finalize.vue";
import LicencesErrors from "../../views/Default/Errors/Licences.vue";
import ListSmsPacks from "../../views/ListSmsPacks.vue";
import NotificationsLogs from "../../views/Default/Notifications/NotificationsLogs.vue";
import AppUpdatesList from "../../views/Default/AppUpdates/AppUpdatesList.vue";
import ShowAppUpdate from "../../views/Default/AppUpdates/ShowAppUpdate.vue";

import noshow from "./noshow.js";
import yservices from "./yservices.js";
import ManageZones from "../../views/Default/Restaurants/ManageZones.vue";

const routes = [
    // Auth
    {
        path: "/register",
        name: "public.auth.register",
        meta: {
            layout: "auth",
            public: true,
        },
        component: AuthRegister,
    },
    {
        path: "/login",
        name: "public.auth.login",
        meta: {
            layout: "auth",
            public: true,
        },
        component: AuthLogin,
    },
    {
        path: "/password/reset/:token",
        name: "public.auth.password_reset",
        meta: {
            layout: "passwordreset",
            public: true,
        },
        component: AuthResetPassword,
    },
    {
        path: "/register/finalize",
        name: "register.finalize",
        meta: {
            layout: "registration",
        },
        component: RegistrationFinalize,
    },

    // Back Office
    {
        path: "/",
        name: "home",
        component: Home,
    },
    {
        path: "/restaurants/add",
        name: "restaurants.add",
        component: RestaurantsCreate,
    },
    {
        path: "/restaurants/manage_zones",
        name: "restaurants.manage_zones",
        component: ManageZones,
    },
    {
        path: "/restaurant/:restaurant_id",
        name: "restaurants.edit",
        component: RestaurantsCreate,
    },
    {
        path: "/mon-compte",
        name: "account",
        component: MyAccount,
    },
    {
        path: "/rgpd",
        name: "rgpd",
        component: RGPD,
    },
    {
        path: "/errors/licences",
        name: "errors.licences",
        component: LicencesErrors,
        meta: {
            public: true,
        },
    },
    {
        path: "/sms",
        name: "sms",
        component: ListSmsPacks,
    },
    {
        path: "/:module/restaurants/:restaurant_id/notifications_logs",
        name: "notifications.logs",
        component: NotificationsLogs,
    },
    {
        path: "/app_updates",
        name: "app_updates.list",
        component: AppUpdatesList,
    },
    {
        path: "/app_updates/:app_update_id",
        name: "app_updates.show",
        component: ShowAppUpdate,
    },
];

if (THEME === "noshow") {
    routes.push(...noshow);
} else if (THEME === "yservices") {
    routes.push(...yservices);
}

export default routes;
