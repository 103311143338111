<template>
    <div class="stripe-card-item d-flex flex-row border-light position-relative" :class="isSelected ? 'selected' : ''" @click="cardSelected = card">
        <div class="icon">
            <feather type="credit-card" size="28" class="w-auto h-auto" />
        </div>
        <div class="w-100 p-3 d-flex justify-content-between align-items-center">
            <div>
                <div class="d-inline-block">
                    <p class="m-0">XXXX XXXX XXXX {{ card.card["last4"] }}</p>
                    <p class="m-0 align-top ml-1">
                        {{ expAt }}
                    </p>
                </div>
            </div>
            <div>
                <button
                    v-if="editable && has_right_to_update_subscription"
                    :disabled="!has_right_to_update_subscription"
                    @click="$emit('addNewCard')"
                    class="btn btn-circle btn-primary btn-sm">
                    {{ $tl("labels.form.actions.modify") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardItem",
    props: {
        card: {
            required: true,
        },
        value: {
            default: undefined,
        },
        editable: {
            default: true,
        },
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_subscription() {
            return this.rights.includes("default.subscription.update");
        },
        cardSelected: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit("input", value);
            },
        },
        isSelected() {
            if (this.cardSelected) {
                return this.cardSelected.id === this.card.id;
            }

            return false;
        },
        expAt() {
            const month = `0${this.card.card.exp_month}`.substr(-2);
            const year = `${this.card.card.exp_year}`.substr(-2);

            return `${month}/${year}`;
        },
    },
    methods: {
        deletePaymentMethod() {
            this.$store
                .dispatch("stripe/deletePaymentMethod", { card: this.card })
                .then(() => {
                    this.$emit("updated");
                })
                .catch((error) => {
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error, this.$tl("errors.paymentMethods.cantDelete")),
                    });
                });
        },
        setDefault() {
            this.$emit("loading");
            this.$store
                .dispatch("stripe/setPaymentMethodAsDefault", { card: this.card })
                .then(() => {
                    this.$emit("updated");
                })
                .catch((error) => {
                    this.$emit("updated");
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
    },
};
</script>
