var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [_vm._v("Nom du pack")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8 value" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.emailsCampaignPack.name) +
                "\n                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sepa" }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [_vm._v("Description")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8 value" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.emailsCampaignPack.description) +
                "\n                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sepa" }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [_vm._v("Prix TTC")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8 value" }, [
            _vm._v(_vm._s(_vm.emailsCampaignPack.price / 100) + " €"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sepa" }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [_vm._v("Prix HT")]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8 value" }, [
            _vm._v(_vm._s(_vm.emailsCampaignPack.price_ht / 100) + " €"),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [_vm._v("Détail du pack")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }