var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column justify-content-center" },
    [
      _c(
        "button",
        {
          staticClass:
            "btn btn-sm btn-outline-secondary dropdown-toggle d-md-none",
          staticStyle: { "min-width": "300px" },
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              _vm.toggleDisplay = !_vm.toggleDisplay
            },
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$tl("labels.form.pickColumnsToDisplay")) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-md-block",
          class: { "d-none": _vm.toggleDisplay },
          staticStyle: { "min-width": "300px" },
        },
        [
          _c("vue-multiselect", {
            attrs: {
              disabled: _vm.isLoading,
              multiple: true,
              options: _vm.options,
              "max-height": 300,
              placeholder: _vm.$tl("labels.form.pickColumnsToDisplay"),
              searchable: false,
              "close-on-select": false,
              "show-labels": false,
              label: "label",
              "track-by": "value",
              "open-direction": "bottom",
            },
            on: { input: _vm.updateColumns },
            model: {
              value: _vm.selectedColumns,
              callback: function ($$v) {
                _vm.selectedColumns = $$v
              },
              expression: "selectedColumns",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }