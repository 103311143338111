export default {
    data() {
        return {
            GA_METRIC_TOTAL_USERS: {
                value: "totalUsers",
            },
            GA_METRIC_SESSIONS: {
                value: "sessions",
            },
            GA_METRIC_AVERAGE_SESSION_DURATION: {
                value: "averageSessionDuration",
            },
            GA_METRIC_SCREEN_PAGE_VIEWS_PER_SESSION: {
                value: "screenPageViewsPerSession",
            },
        };
    },
    methods: {
        getGaMetricLabel(value) {
            const found = this.ALL_GA_METRICS.find((type) => type.value === value);

            return typeof found !== "undefined" ? this.$t(`labels.googleAnalytics.metrics.${value}`) : value;
        },
    },
    computed: {
        ALL_GA_METRICS() {
            return [
                this.GA_METRIC_TOTAL_USERS,
                this.GA_METRIC_SESSIONS,
                this.GA_METRIC_AVERAGE_SESSION_DURATION,
                this.GA_METRIC_SCREEN_PAGE_VIEWS_PER_SESSION,
            ];
        },
    },
};
