export default {
    common: {
        unknown: "Une erreur est survenue",
        checkAllFields: "Merci de vérifier tous les champs",
        widgetDisabled: "Le widget est désactivé pour cet établissement.",
        copy: {
            default: "{element} n'a pas pu être copié",
            notSecure: "Le navigateur n'est pas dans un contexte sécurisé, impossible de copier {element}",
            notActive: "Vous n'avez pas la permission de copier merci d'activer cette fonctionnalité sur votre navigateur",
        },
        rights: {
            notEnough: "Désolé, vous n'avez pas les droits nécessaires pour accéder à cette page.",
            cantFetch: "Une erreur est survenue lors de la récupération des droits",
            notEnoughForGlobalReport: "Vous ne disposez pas de droits suffisants pour consulter le rapport global.",
        },
        dates: {
            selectStartAndEnd: "Merci de saisir une date de début et de fin",
            endGreaterThanStart: "Merci de saisir une date de début supérieure ou égale à la date de fin.",
        },
        image: {
            tooBig: "La taille de l'image sélectionnée ({size} Mo) est supérieure à la taille maximale ({maxSize} Mo)",
        },
        file: {
            tooBig: "La taille du fichier ({fileSize} Mo) est supérieure à la taille maximale ({maxSize} Mo)",
        },
        sms: {
            exceedMaxLength: "La longueur actuelle du sms est supérieure à la limite autorisée. L'envoi risque d'échouer.",
            emojisNotAllowed: "Les emojis ne sont pas autorisés dans les sms.",
        },
        address: {
            noHits: "Cette adresse postale n'a pas été trouvée.",
        },
        notificationsLogs: {
            hasUnseenFailed: "Mail(s) non délivré(s)",
        },
    },
    clients: {
        import: "Une erreur est survenue lors de l'import",
    },
    paymentMethods: {
        cantDelete: "Une erreur est survenue lors de la suppression de la carte.",
    },
    psp: {
        sepaDebit: {
            owner: "Veuillez indiquer le nom du titulaire du compte bancaire",
            email: "Veuillez indiquer l'adresse email du titulaire du compte bancaire",
        },
    },
    cgvs: {
        servicesCp: "Veuillez accepter les conditions particulières d'utilisation de chacun des services",
    },
    commands: {
        add: {
            no_slots: "Aucun créneau disponible pour ce jour.",
        },
    },
    address: {
        no_match: "Aucune correspondance",
        no_one_selected: "Merci de saisir une addresse dans la liste",
        no_deliverable: "Aucune zone de livraison ne couvre cette adresse",
    },
    browser: {
        invalid: {
            title: "Navigateur non supporté",
            infos: `Votre navigateur n'est pas compatible avec le logiciel NoShow, veuillez utiliser <a href="https://www.google.com/intl/fr_fr/chrome/">Google Chrome</a> ou <a href="https://www.mozilla.org/fr/">Mozilla Firefox</a>.`,
        },
    },
    booking: {
        reservations: {
            notUpdated: "La réservation n'a pas pu être modifiée.",
            noHotelRoomFound: "Aucune chambre n'a été trouvée",
            invalidPax: "Merci de saisir un PAX valide",
            cantPlace:
                "Cette réservation ne pourra pas être placée sur le plan de salle si vous la validez. Merci de sélectionner une place dans la liste",
            export: {
                atLeastOneStatus: "Merci de sélectionner au moins un statut",
                atLeastOneService: "Merci de sélectionner au moins une plage horaire",
                atLeastOneColumn: "Merci de sélectionner au moins une colonne",
                maxColumns: "Merci de sélectionner au maximum {limit} colonnes (actuel : {nb}).",
            },
        },
        seatingPlan: {
            checkFutureResas: "Une erreur est survenue lors de la vérification des réservations futures",
            cantPlace: "Le placement automatique n'a pas fonctionné.",
            allPaxPlaced: "Toutes les personnes ont été placées pour cette réservation",
            cantPlaceOnMultipleRooms: "Il n'est pas possible de placer une réservation sur plusieurs salles",
            needAtLeastOneRoom: "Vous devez créer au moins une salle pour enregistrer le plan de salle",
            featureDisabledForRestaurant: "Cet établissement ne supporte pas la fonctionnalité Plan de salle",
            table: {
                notAvailable: "La table n'est pas disponible",
                pendingResa: "Table en cours de réservation.",
                alreadyLinkToThisResa: "La table est déjà liée à cette réservation",
                alreadyLinkToAnotherResa: "Cette table est déjà liée à une réservation",
            },
            group: {
                notAvailable: "Le groupe n'est pas disponible",
                paxEnabled: "Il n'y a pas de pax actif pour le(s) groupe(s) : {groupNames} dans les salles suivantes : {roomNames}",
            },
        },
    },
};
