var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [
          _vm._v(
            _vm._s(
              _vm.$t("labels.form.sms.detailsFor", {
                packName: _vm.$t("labels.invoices.smsPack", {
                  nb: _vm.smsPack.nb_sms,
                }),
              })
            )
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$tl("labels.form.sms.packName")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8 value" }, [
            _vm._v(
              "\n                    " +
                _vm._s(
                  _vm.$t("labels.invoices.smsPack", { nb: _vm.smsPack.nb_sms })
                ) +
                "\n                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sepa" }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$tl("labels.priceTTC")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8 value" }, [
            _vm._v(_vm._s(_vm.formatCurrency(_vm.smsPack.price / 100))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sepa" }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$tl("labels.priceHT")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8 value" }, [
            _vm._v(_vm._s(_vm.formatCurrency(_vm.smsPack.price_ht / 100))),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }