var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "form-signin",
      attrs: { "data-test-id": "form-login" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.login.apply(null, arguments)
        },
      },
    },
    [
      _c("img", {
        staticClass: "mb-5",
        attrs: { width: "200", src: "/images/logo-lead.png", alt: "No Show" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center mb-3",
        },
        [
          _c(
            "h5",
            {
              staticClass: "m-0",
              attrs: { "data-test-id": "title-login_page" },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.$tl(
                      `labels.form.login.${
                        _vm.isYservices ? "loginWith" : "login"
                      }`
                    )
                  ) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("ChooseLang"),
        ],
        1
      ),
      _vm._v(" "),
      _vm.loading ? _c("loader-component") : _vm._e(),
      _vm._v(" "),
      _vm.errorMessage
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? [
            _vm.isYservices
              ? [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-muted my-3 text-center d-flex align-items-center",
                    },
                    [
                      _c("hr", { staticStyle: { width: "20%" } }),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$tl("labels.form.login.useMyEmail")) +
                          "\n                "
                      ),
                      _c("hr", { staticStyle: { width: "20%" } }),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("label", { staticClass: "sr-only" }, [
              _vm._v(_vm._s(_vm.$tl("labels.form.username"))),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "sr-only" }, [
              _vm._v(_vm._s(_vm.$tl("labels.form.password"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group mb-1" }, [
              _c("div", { staticClass: "input-group-prepend" }, [
                _c(
                  "span",
                  { staticClass: "input-group-text" },
                  [
                    _c("feather", {
                      staticClass: "text-success",
                      attrs: { type: "user" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.hasErrors("email") },
                attrs: {
                  id: "email",
                  "data-test-id": "input-txt-email",
                  type: "email",
                  name: "email",
                  placeholder: _vm.$t("labels.form.email"),
                  required: "",
                  autofocus: "",
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.hasErrors("email")
                ? _c("div", {
                    staticClass: "invalid-feedback",
                    attrs: { "data-test-id": "errors-email", role: "alert" },
                    domProps: { innerHTML: _vm._s(_vm.joinErrors("email")) },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group mb-3" }, [
              _c("div", { staticClass: "input-group-prepend" }, [
                _c(
                  "span",
                  { staticClass: "input-group-text" },
                  [
                    _c("feather", {
                      staticClass: "text-success",
                      attrs: { type: "lock" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.hasErrors("password") },
                attrs: {
                  id: "password",
                  type: "password",
                  "data-test-id": "input-txt-password",
                  name: "password",
                  placeholder: _vm.$t("labels.form.password"),
                  required: "",
                },
                domProps: { value: _vm.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.hasErrors("password")
                ? _c("div", {
                    staticClass: "invalid-feedback",
                    attrs: { role: "alert" },
                    domProps: { innerHTML: _vm._s(_vm.joinErrors("password")) },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "checkbox mb-3" }, [
              _c(
                "label",
                {
                  staticClass: "container-box",
                  staticStyle: { width: "100%" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.remember,
                        expression: "remember",
                      },
                    ],
                    staticClass: "mr-2",
                    attrs: {
                      "data-test-id": "input-chk-remember",
                      type: "checkbox",
                      name: "remember",
                      id: "remember",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.remember)
                        ? _vm._i(_vm.remember, null) > -1
                        : _vm.remember,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.remember,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.remember = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.remember = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.remember = $$c
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$tl("labels.form.login.rememberMe")) +
                      "\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-success btn-circle login-submit",
                attrs: { "data-test-id": "btn-login", type: "submit" },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.login.button")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("footer", { staticClass: "content-footer mt-4" }, [
              _c("nav", { staticClass: "footer-navigation mb-4" }, [
                _c(
                  "a",
                  {
                    staticClass: "footer-navigation-item",
                    attrs: { href: "/password/reset" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$tl("labels.form.login.forgotPassword")) +
                        "\n                "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(" ©" + _vm._s(_vm.displayDate(null, "y")) + " "),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "d-block bg-white text-center py-2",
        attrs: { href: "https://admin.yproximite.fr/y-services/autologin" },
      },
      [
        _c("img", {
          attrs: { src: "/images/logo-yprox.png", alt: "Log with Yproximité" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }