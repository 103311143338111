export default {
    data() {
        return {
            CLIENT_LOYALTY_LEVEL_FIRST: {
                value: 1,
            },
            CLIENT_LOYALTY_LEVEL_SECOND: {
                value: 2,
                featherClass: "feather-blue",
            },
            CLIENT_LOYALTY_LEVEL_THIRD: {
                value: 3,
                featherClass: "feather-orange",
            },
            CLIENT_LOYALTY_LEVEL_FOURTH: {
                value: 4,
                featherClass: "feather-red",
            },
        };
    },
    methods: {
        getClientLoyaltyLevelLabel(value) {
            const found = this.ALL_CLIENT_LOYALTIES.find((loyalty_level) => loyalty_level.value === value);

            return typeof found !== "undefined" ? this.$t(`labels.clients.loyalties.${value}`) : value;
        },
        getClientLoyaltyLevelFeatherInfo(value) {
            const found = this.ALL_CLIENT_LOYALTIES.find((loyalty_level) => loyalty_level.value === value);

            if (typeof found === "undefined") {
                return null;
            }

            return {
                type: "heart",
                class: found.featherClass,
                tooltip: this.getTooltip(this.getClientLoyaltyLevelLabel(value)),
            };
        },
    },
    computed: {
        ALL_CLIENT_LOYALTIES() {
            return [
                this.CLIENT_LOYALTY_LEVEL_FIRST,
                this.CLIENT_LOYALTY_LEVEL_SECOND,
                this.CLIENT_LOYALTY_LEVEL_THIRD,
                this.CLIENT_LOYALTY_LEVEL_FOURTH,
            ];
        },
    },
};
