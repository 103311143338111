var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "border-left-light p-0 m-0 bg-light overflow-auto",
      staticStyle: { "border-top": "none !important" },
      style: _vm.isGroupsAndPrintTabCollapsed
        ? "position: absolute; right: 0;"
        : undefined,
    },
    [
      _vm.canCollapse
        ? _c("feather", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.getTooltip(
                  _vm.$t(
                    `labels.booking.seatingPlan.groupsAndPrint.${
                      _vm.isGroupsAndPrintTabCollapsed ? "show" : "hide"
                    }`
                  )
                ),
                expression:
                  "getTooltip($t(`labels.booking.seatingPlan.groupsAndPrint.${isGroupsAndPrintTabCollapsed ? 'show' : 'hide'}`))",
              },
            ],
            staticClass: "pointer",
            attrs: {
              type: _vm.isGroupsAndPrintTabCollapsed
                ? "chevrons-left"
                : "chevrons-right",
            },
            on: {
              click: function ($event) {
                _vm.isGroupsAndPrintTabCollapsed =
                  !_vm.isGroupsAndPrintTabCollapsed
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: { "d-none": _vm.isGroupsAndPrintTabCollapsed } }, [
        _vm.showTables
          ? _c(
              "div",
              {
                staticClass: "accordion",
                staticStyle: { "flex-direction": "unset !important" },
                attrs: { id: "tablesAccordion" },
              },
              [
                _c("div", { staticClass: "card border-0" }, [
                  _c(
                    "div",
                    {
                      staticClass: "card-header p-0",
                      attrs: { id: "tablesHeader" },
                    },
                    [
                      _c("h2", { staticClass: "mb-0" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn w-100 h-100 text-left",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#tablesList",
                              "aria-expanded": "true",
                              "aria-controls": "tablesList",
                            },
                            on: {
                              click: function ($event) {
                                _vm.isTableListExpanded =
                                  !_vm.isTableListExpanded
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl("labels.booking.seatingPlan.tables")
                                ) +
                                "\n                            "
                            ),
                            _c("feather", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.getTooltipNotice(
                                    "notices.booking.tablePlan.tables"
                                  ),
                                  expression:
                                    "getTooltipNotice('notices.booking.tablePlan.tables')",
                                },
                              ],
                              staticClass: "ml-1 pointer tooltip-infos",
                              attrs: { type: "info" },
                            }),
                            _vm._v(" "),
                            _vm.isTableListExpanded
                              ? _c("feather", {
                                  attrs: { type: "chevron-down" },
                                })
                              : _c("feather", {
                                  attrs: { type: "chevron-right" },
                                }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      {
                        ref: `tableList`,
                        staticClass: "collapse show border-0",
                        attrs: {
                          id: "tablesList",
                          "aria-labelledby": "tablesHeader",
                          "data-parent": "#tablesAccordion",
                        },
                      },
                      [
                        _vm.currentRoom && _vm.has_right_to_update_seatingplan
                          ? _c(
                              "draggable",
                              {
                                staticClass: "list-group border-0",
                                attrs: { disabled: !_vm.sortable },
                                model: {
                                  value: _vm.sortedTables,
                                  callback: function ($$v) {
                                    _vm.sortedTables = $$v
                                  },
                                  expression: "sortedTables",
                                },
                              },
                              [
                                _vm._l(
                                  _vm.sortedTables,
                                  function (table, index) {
                                    return [
                                      table.seats > 0
                                        ? _c(
                                            "li",
                                            {
                                              key: index,
                                              staticClass: "list-group-item",
                                              class: {
                                                selected:
                                                  _vm.selectedTablesId.includes(
                                                    table.id
                                                  ),
                                              },
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.tableSelected(
                                                    $event,
                                                    table
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "h-100 w-100" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "float-left",
                                                    },
                                                    [
                                                      _vm.sortable
                                                        ? _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "css-i6dzq1 feather mr-1",
                                                              staticStyle: {
                                                                color:
                                                                  "#aaaaaa",
                                                              },
                                                              attrs: {
                                                                viewBox:
                                                                  "0 0 24 24",
                                                                width: "24",
                                                                height: "24",
                                                                stroke:
                                                                  "currentColor",
                                                                "stroke-width":
                                                                  "2",
                                                                fill: "none",
                                                                "stroke-linecap":
                                                                  "round",
                                                                "stroke-linejoin":
                                                                  "round",
                                                              },
                                                            },
                                                            [
                                                              _c("line", {
                                                                attrs: {
                                                                  x1: "3",
                                                                  y1: "9",
                                                                  x2: "21",
                                                                  y2: "9",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("line", {
                                                                attrs: {
                                                                  x1: "3",
                                                                  y1: "15",
                                                                  x2: "21",
                                                                  y2: "15",
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            table.seats
                                                              ? _vm.$tl(
                                                                  "labels.booking.seatingPlan.table.title"
                                                                )
                                                              : ""
                                                          ) +
                                                          "\n                                            " +
                                                          _vm._s(table.name) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  table.seats
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "float-right",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            " +
                                                              _vm._s(
                                                                _vm.capitalize(
                                                                  _vm.$tl(
                                                                    "labels.pax"
                                                                  )
                                                                )
                                                              ) +
                                                              " :\n                                            " +
                                                              _vm._s(
                                                                table.enabledPax
                                                                  .length > 0
                                                                  ? table.enabledPax.join(
                                                                      " ,"
                                                                    )
                                                                  : table.seats
                                                              ) +
                                                              "\n                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm.currentRoom &&
                            !_vm.has_right_to_update_seatingplan
                          ? _c(
                              "div",
                              { staticClass: "list-group border-0" },
                              [
                                _vm._l(
                                  _vm.sortedTables,
                                  function (table, index) {
                                    return [
                                      table.seats > 0
                                        ? _c(
                                            "li",
                                            {
                                              key: index,
                                              staticClass: "list-group-item",
                                              class: {
                                                selected:
                                                  _vm.selectedTablesId.includes(
                                                    table.id
                                                  ),
                                              },
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.tableSelected(
                                                    $event,
                                                    table
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "h-100 w-100" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "float-left",
                                                    },
                                                    [
                                                      _vm.sortable &&
                                                      _vm.has_right_to_update_seatingplan
                                                        ? _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "css-i6dzq1 feather mr-1",
                                                              staticStyle: {
                                                                color:
                                                                  "#aaaaaa",
                                                              },
                                                              attrs: {
                                                                viewBox:
                                                                  "0 0 24 24",
                                                                width: "24",
                                                                height: "24",
                                                                stroke:
                                                                  "currentColor",
                                                                "stroke-width":
                                                                  "2",
                                                                fill: "none",
                                                                "stroke-linecap":
                                                                  "round",
                                                                "stroke-linejoin":
                                                                  "round",
                                                              },
                                                            },
                                                            [
                                                              _c("line", {
                                                                attrs: {
                                                                  x1: "3",
                                                                  y1: "9",
                                                                  x2: "21",
                                                                  y2: "9",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("line", {
                                                                attrs: {
                                                                  x1: "3",
                                                                  y1: "15",
                                                                  x2: "21",
                                                                  y2: "15",
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.booking.seatingPlan.table.title"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(table.name) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "float-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.capitalize(
                                                              _vm.$tl(
                                                                "labels.pax"
                                                              )
                                                            )
                                                          ) +
                                                          " :\n                                            " +
                                                          _vm._s(
                                                            table.enabledPax
                                                              .length > 0
                                                              ? table.enabledPax.join(
                                                                  " ,"
                                                                )
                                                              : table.seats
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showGroups
          ? _c(
              "div",
              { staticClass: "accordion", attrs: { id: "groupsAccordion" } },
              [
                _c("div", { staticClass: "card border-0" }, [
                  _c(
                    "div",
                    {
                      staticClass: "card-header p-1",
                      attrs: { id: "groupsHeader" },
                    },
                    [
                      _c("h2", { staticClass: "mb-0" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn w-100 h-100 text-left",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#groupsList",
                              "aria-expanded": "true",
                              "aria-controls": "groupsList",
                            },
                            on: {
                              click: function ($event) {
                                _vm.isGroupsListExpanded =
                                  !_vm.isGroupsListExpanded
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl("labels.booking.seatingPlan.groups")
                                ) +
                                "\n                            "
                            ),
                            _vm.sortable
                              ? _c("feather", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.getTooltipNotice(
                                        "notices.booking.tablePlan.groups"
                                      ),
                                      expression:
                                        "getTooltipNotice('notices.booking.tablePlan.groups')",
                                    },
                                  ],
                                  staticClass: "ml-1 pointer tooltip-infos",
                                  attrs: { type: "info" },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isGroupsListExpanded
                              ? _c("feather", {
                                  attrs: { type: "chevron-down" },
                                })
                              : _c("feather", {
                                  attrs: { type: "chevron-right" },
                                }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: `groupsList`,
                      staticClass: "collapse show border-0",
                      attrs: {
                        id: "groupsList",
                        "aria-labelledby": "groupsHeader",
                        "data-parent": "#groupsAccordion",
                      },
                    },
                    [
                      _vm.currentRoom
                        ? _c(
                            "ul",
                            { staticClass: "list-group border-0" },
                            [
                              _c(
                                "draggable",
                                {
                                  attrs: {
                                    disabled:
                                      !_vm.sortable ||
                                      !_vm.has_right_to_update_seatingplan,
                                  },
                                  model: {
                                    value: _vm.sortedGroups,
                                    callback: function ($$v) {
                                      _vm.sortedGroups = $$v
                                    },
                                    expression: "sortedGroups",
                                  },
                                },
                                _vm._l(
                                  _vm.sortedGroups,
                                  function (group, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: index,
                                        staticClass: "list-group-item p-0",
                                        class: {
                                          selected:
                                            _vm.selectedGroupId === group.id,
                                        },
                                        staticStyle: { cursor: "pointer" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "accordion",
                                            class: _vm.getGroupClasses(group),
                                            style: _vm.getGroupStyle(group),
                                            attrs: {
                                              id: `group-${group.id}-accordion`,
                                            },
                                            on: {
                                              mouseenter: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.onGroupMouseEnter(
                                                  $event,
                                                  group
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.onGroupMouseLeave(
                                                  $event,
                                                  group
                                                )
                                              },
                                              dragenter: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.onGroupMouseEnter(
                                                  $event,
                                                  group
                                                )
                                              },
                                              dragleave: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.onGroupMouseLeave(
                                                  $event,
                                                  group
                                                )
                                              },
                                              dragover: function ($event) {
                                                $event.preventDefault()
                                              },
                                              drop: function ($event) {
                                                return _vm.onDropOnGroup(
                                                  $event,
                                                  group
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card border-0" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card-header p-1",
                                                    attrs: {
                                                      id: `group-${group.id}-header`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "d-inline",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.groupSelected(
                                                                  $event,
                                                                  group
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm.sortable &&
                                                            _vm.has_right_to_update_seatingplan
                                                              ? _c(
                                                                  "svg",
                                                                  {
                                                                    staticClass:
                                                                      "css-i6dzq1 feather mr-1",
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#aaaaaa",
                                                                      },
                                                                    attrs: {
                                                                      viewBox:
                                                                        "0 0 24 24",
                                                                      width:
                                                                        "24",
                                                                      height:
                                                                        "24",
                                                                      stroke:
                                                                        "currentColor",
                                                                      "stroke-width":
                                                                        "2",
                                                                      fill: "none",
                                                                      "stroke-linecap":
                                                                        "round",
                                                                      "stroke-linejoin":
                                                                        "round",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("line", {
                                                                      attrs: {
                                                                        x1: "3",
                                                                        y1: "9",
                                                                        x2: "21",
                                                                        y2: "9",
                                                                      },
                                                                    }),
                                                                    _vm._v(" "),
                                                                    _c("line", {
                                                                      attrs: {
                                                                        x1: "3",
                                                                        y1: "15",
                                                                        x2: "21",
                                                                        y2: "15",
                                                                      },
                                                                    }),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$tl(
                                                                    "labels.booking.seatingPlan.group.titleShort"
                                                                  )
                                                                ) +
                                                                "\n                                                    " +
                                                                _vm._s(
                                                                  group.name
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass: "btn",
                                                            attrs: {
                                                              type: "button",
                                                              "data-toggle":
                                                                "collapse",
                                                              "data-target": `#group-${group.id}-list`,
                                                              "aria-expanded":
                                                                "true",
                                                              "aria-controls": `group-${group.id}-list`,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                group.expanded =
                                                                  !group.expanded
                                                              },
                                                            },
                                                          },
                                                          [
                                                            group.expanded
                                                              ? _c("feather", {
                                                                  attrs: {
                                                                    type: "chevron-down",
                                                                  },
                                                                })
                                                              : _c("feather", {
                                                                  attrs: {
                                                                    type: "chevron-right",
                                                                  },
                                                                }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "collapse border-0",
                                                    attrs: {
                                                      id: `group-${group.id}-list`,
                                                      "aria-labelledby": `group-${group.id}-header`,
                                                      "data-parent": `#group-${group.id}-accordion`,
                                                    },
                                                  },
                                                  [
                                                    _vm.currentRoom
                                                      ? _c(
                                                          "ul",
                                                          {
                                                            staticClass:
                                                              "list-group border-0",
                                                          },
                                                          _vm._l(
                                                            group.tables,
                                                            function (
                                                              table,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "li",
                                                                {
                                                                  key: index,
                                                                  staticClass:
                                                                    "list-group-item",
                                                                  staticStyle: {
                                                                    cursor:
                                                                      "pointer",
                                                                  },
                                                                  style:
                                                                    _vm.getTableStyle(
                                                                      table
                                                                    ),
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "h-100 w-100",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "float-left",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                            " +
                                                                              _vm._s(
                                                                                _vm.$tl(
                                                                                  "labels.booking.seatingPlan.table.title"
                                                                                )
                                                                              ) +
                                                                              "\n                                                            " +
                                                                              _vm._s(
                                                                                table.name
                                                                              ) +
                                                                              "\n                                                        "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "float-right",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                            " +
                                                                              _vm._s(
                                                                                _vm.$tc(
                                                                                  "labels.booking.seatingPlan.countSeats",
                                                                                  table.seats
                                                                                )
                                                                              ) +
                                                                              "\n                                                        "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showRooms && _vm.rooms.length > 0
          ? _c(
              "div",
              {
                staticClass: "accordion",
                staticStyle: { "flex-direction": "unset !important" },
                attrs: { id: "roomsAccordion" },
              },
              [
                _c("div", { staticClass: "card border-0" }, [
                  _c(
                    "div",
                    {
                      staticClass: "card-header p-0",
                      attrs: { id: "roomsHeader" },
                    },
                    [
                      _c("h2", { staticClass: "mb-0" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn w-100 h-100 text-left",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#roomsList",
                              "aria-expanded": "true",
                              "aria-controls": "roomsList",
                            },
                            on: {
                              click: function ($event) {
                                _vm.isRoomsListExpanded =
                                  !_vm.isRoomsListExpanded
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl("labels.booking.seatingPlan.printing")
                                ) +
                                "\n                            "
                            ),
                            _vm.isRoomsListExpanded
                              ? _c("feather", {
                                  attrs: { type: "chevron-down" },
                                })
                              : _c("feather", {
                                  attrs: { type: "chevron-right" },
                                }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      {
                        ref: `roomsList`,
                        staticClass: "collapse border-0",
                        attrs: {
                          id: "roomsList",
                          "aria-labelledby": "roomsHeader",
                          "data-parent": "#roomsAccordion",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "list-group border-0" },
                          [
                            _vm._l(_vm.rooms, function (room, index) {
                              return [
                                _c(
                                  "li",
                                  {
                                    key: index,
                                    staticClass: "list-group-item",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "pointer",
                                            on: {
                                              click: function ($event) {
                                                _vm.rooms[index].print =
                                                  !_vm.rooms[index].print
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(_vm.rooms[index].name) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "container-box m-0" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.rooms[index].print,
                                                  expression:
                                                    "rooms[index].print",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.rooms[index].print
                                                )
                                                  ? _vm._i(
                                                      _vm.rooms[index].print,
                                                      null
                                                    ) > -1
                                                  : _vm.rooms[index].print,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.rooms[index].print,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.rooms[index],
                                                          "print",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.rooms[index],
                                                          "print",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.rooms[index],
                                                      "print",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "checkmark",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "mx-auto mt-2" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-sm btn-circle btn-success",
                                  attrs: {
                                    disabled:
                                      !_vm.atLeastOneRoomSelectedForPrint ||
                                      _vm.isPrinting,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.printSeatingPlan()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.$tl("labels.form.actions.print")
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }