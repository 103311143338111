var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "dropdown",
      staticClass: "position-relative",
      attrs: { tabindex: "0" },
    },
    [
      _c(
        "button",
        {
          class: _vm.btnClassesString,
          attrs: { type: "button", disabled: _vm.isLoading },
          on: {
            "!click": function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              _vm.canShowOptions = !_vm.canShowOptions
            },
            "!dblclick": function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
            },
            blur: _vm.hideDropDown,
          },
        },
        [
          _vm.useSlot
            ? _vm._t("default")
            : _vm.useLabel
            ? _c("span", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.selectedOption[_vm.optionLabel] || _vm.title || ""
                    ) +
                    "\n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.useIcon && _vm.checkIcon(_vm.selectedOption, "feather")
            ? _c("feather", {
                staticClass: "feather-20",
                class: _vm.selectedOption[_vm.optionIcon].class || "",
                attrs: { type: _vm.selectedOption[_vm.optionIcon].feather },
              })
            : _vm.useIcon && _vm.checkIcon(_vm.selectedOption, "html")
            ? _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.selectedOption[_vm.optionIcon].html),
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.canDisplayChevronDown
            ? _c("feather", { attrs: { type: "chevron-down" } })
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.canShowOptions
        ? _c(
            "div",
            { staticClass: "dropdown-container" },
            [
              _vm._l(_vm.currentOptions, function (option) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-item current-dropdown-item font-size-2",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.changeOption(option)
                        },
                      },
                    },
                    [
                      _vm.useLabel
                        ? _c("span", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(option[_vm.optionLabel]) +
                                "\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.useIcon && _vm.checkIcon(option, "feather")
                        ? _c("feather", {
                            staticClass: "feather-20 mr-2",
                            class: option[_vm.optionIcon].class || "",
                            attrs: { type: option[_vm.optionIcon].feather },
                          })
                        : _vm.useIcon && _vm.checkIcon(option, "html")
                        ? _c("span", {
                            staticClass: "d-inline-block mr-2",
                            domProps: {
                              innerHTML: _vm._s(option[_vm.optionIcon].html),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.useLabelForIcon
                        ? _c("span", [_vm._v(_vm._s(option[_vm.optionLabel]))])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }