<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <show-sms-pack :smsPack="smsPack"> </show-sms-pack>
                </div>
            </div>
        </div>
        <show-facturation
            :getInvoiceData="{ pack_id: this.smsPack.id }"
            getInvoiceUrl="/api/sms/invoice"
            :openNewTab="false"
            @paid="paid">
        </show-facturation>
    </div>
</template>

<script>
import ShowSmsPack from "../sms/ShowSmsPack";
import showFacturation from "../payment/Facturation";

export default {
    name: "PurchaseSmsPack",
    props: {
        smsPack: {
            default: "",
        },
    },
    components: {
        ShowSmsPack,
        showFacturation,
    },
    methods: {
        paid() {
            setTimeout(() => {
                document.location.reload(true);
            }, 2000);
        },
    },
};
</script>
