var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "p-0" },
    _vm._l(_vm.list, function (module, index) {
      return _c("module-list-item", {
        key: index,
        attrs: {
          module: module,
          small: _vm.small,
          showButtons: _vm.showButtons,
        },
        on: {
          "clicked-on-same-module": function ($event) {
            return _vm.$emit("clicked-on-same-module", $event)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }