var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.menu.nb <= _vm.menus_nb,
          expression: "menu.nb <= menus_nb",
        },
      ],
      staticClass: "col-lg-4 col-12",
    },
    [
      _c("div", { staticClass: "row mb-2" }, [
        _c("div", { staticClass: "col-12 mb-1" }, [
          _c("label", { staticClass: "font-weight-bold" }, [
            _vm.menu.title
              ? _c("span", [_vm._v(_vm._s(_vm.menu.title))])
              : _c("span", [_vm._v("Menu " + _vm._s(_vm.menu.nb))]),
            _vm._v(" : "),
            _c("small", { staticClass: "text-danger" }, [_vm._v("*")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4" }, [
          _c("label", { attrs: { for: "txt-title" + _vm.suffixInputId } }, [
            _vm._v("Titre "),
            _c("small", { staticClass: "text-danger" }, [_vm._v("*")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-8 mb-1" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.menu.title,
                expression: "menu.title",
              },
            ],
            staticClass: "form-control",
            attrs: {
              disabled: _vm.disabled,
              maxlength: "30",
              type: "text",
              id: "txt-title" + _vm.suffixInputId,
            },
            domProps: { value: _vm.menu.title },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.menu, "title", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.hasErrors("title")
            ? _c("div", {
                staticClass: "text-danger",
                domProps: { innerHTML: _vm._s(_vm.joinErrors("title")) },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4" }, [
          _c("label", { attrs: { for: "nb-price" + _vm.suffixInputId } }, [
            _vm._v(
              "Prix (" +
                _vm._s(_vm.getRestaurantCurrency(_vm.restaurantId)) +
                ")"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-8 mb-1" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.menu.price_in_cent,
                expression: "menu.price_in_cent",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control",
            attrs: {
              disabled: _vm.disabled,
              type: "number",
              step: "0.01",
              min: "0",
              id: "nb-price" + _vm.suffixInputId,
            },
            domProps: { value: _vm.menu.price_in_cent },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.menu, "price_in_cent", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
          _vm._v(" "),
          _vm.hasErrors("price_in_cent")
            ? _c("div", {
                staticClass: "text-danger",
                domProps: {
                  innerHTML: _vm._s(_vm.joinErrors("price_in_cent")),
                },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-12" }, [
          _c("label", { attrs: { for: "txt-content" + _vm.suffixInputId } }, [
            _vm._v("Description "),
            _c("small", { staticClass: "text-danger" }, [_vm._v("*")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-12 mb-1" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.menu.content,
                expression: "menu.content",
              },
            ],
            staticClass: "form-control",
            attrs: {
              disabled: _vm.disabled,
              id: "txt-content" + _vm.suffixInputId,
              maxlength: "100",
              rows: "3",
            },
            domProps: { value: _vm.menu.content },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.menu, "content", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.hasErrors("content")
            ? _c("div", {
                staticClass: "text-danger",
                domProps: { innerHTML: _vm._s(_vm.joinErrors("content")) },
              })
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }