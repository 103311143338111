export default {
    data() {
        return {
            COUPON_DURATION_FOREVER: {
                label: "Illimité",
                value: "forever",
            },
            COUPON_DURATION_ONCE: {
                label: "Première échéance",
                value: "once",
            },
            COUPON_DURATION_REPEATING: {
                label: "Nombre",
                value: "repeating",
            },
        };
    },
    computed: {
        ALL_COUPON_DURATION_TYPES() {
            return [this.COUPON_DURATION_FOREVER, this.COUPON_DURATION_ONCE, this.COUPON_DURATION_REPEATING];
        },
    },
};
