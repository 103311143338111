<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else-if="new_feature">
            <div class="row">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <p v-html="content"></p>
                        <div class="text-center" v-if="new_feature.add_btn">
                            <a class="btn btn-sm btn-success btn-circle" :href="btnLink" target="_blank">
                                {{ new_feature.btn_title }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent";

export default {
    data() {
        return {
            loading: 0,
            new_feature: null,
        };
    },
    props: {
        new_feature_id: {
            type: Number,
            required: true,
        },
        markAsRead: {
            default: true,
        },
    },
    computed: {
        btnLink() {
            if (!this.new_feature || !this.new_feature.btn_link) return "#";
            let restaurant_id = null;
            const role = this.$store.getters["users/role"];
            if (this.new_feature.btn_link_module && role != "admin") {
                const restaurants = this.$store.getters["restaurants/getRestaurantsByService"](
                    this.new_feature.btn_link_module
                );
                if (restaurants.length > 0) restaurant_id = restaurants[0].id;
            } else if (role == "admin") {
                restaurant_id = 1;
            }
            if (restaurant_id) return this.new_feature.btn_link.replace(/\{restaurant_id\}/, restaurant_id);
            return this.new_feature.btn_link;
        },
        content() {
            if (!this.new_feature) return "";
            return this.new_feature.content.replaceAll("\n", "<br/>");
        },
    },
    methods: {
        markFeatureAsRead() {
            axios
                .put(`/api/new_features/${this.new_feature_id}/read`)
                .then(() => {})
                .catch(() => {});
        },
        fetchNewFeature() {
            this.loading++;

            axios
                .get(`/api/new_features/${this.new_feature_id}`, this.builtData)
                .then((response) => {
                    this.loading--;
                    this.new_feature = response.data;
                    if (this.markAsRead) this.markFeatureAsRead();
                    this.$emit("set-title", this.new_feature.title);
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
    },
    components: {
        LoaderComponent,
    },
    created() {
        this.fetchNewFeature();
    },
};
</script>
