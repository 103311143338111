var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(
        _vm._s(_vm.$tl("questions.booking.services.full", _vm.restaurant_id))
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c("set-service-full", {
          ref: "addServiceFull",
          attrs: {
            restaurant_id: _vm.restaurant_id,
            service_id: _vm.service_id,
            slot_id: _vm.slot_id,
            date: _vm.date,
            setFree: _vm.setFree,
            readonly: _vm.readonly,
            prefill: _vm.prefill,
            showSaveButton: false,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-success btn-sm btn-circle ml-2",
            on: { click: _vm.save },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.save")) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }