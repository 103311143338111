export default {
    booking: {
        reservations: {
            cantPlace: "This reservation cannot be placed on the seating plan if you validate it. Please select a table from the list",
            export: {
                atLeastOneService: "Please select at least one service",
            },
        },
    },
};
