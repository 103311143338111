import { render, staticRenderFns } from "./addGeneralOptionModal.vue?vue&type=template&id=6575de66"
import script from "./addGeneralOptionModal.vue?vue&type=script&lang=js"
export * from "./addGeneralOptionModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6575de66')) {
      api.createRecord('6575de66', component.options)
    } else {
      api.reload('6575de66', component.options)
    }
    module.hot.accept("./addGeneralOptionModal.vue?vue&type=template&id=6575de66", function () {
      api.rerender('6575de66', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/Modals/generalOptions/addGeneralOptionModal.vue"
export default component.exports