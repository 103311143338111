var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5" },
    [
      _vm.nbLoading > 0
        ? _c("LoaderComponent")
        : [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1",
                  },
                  [
                    _vm.isCreating
                      ? _c("h5", { staticClass: "title mt-2" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$tl("labels.clients.add")) +
                              "\n                    "
                          ),
                        ])
                      : _c("h5", { staticClass: "title mt-2" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$tl("labels.clients.editName")) +
                              "\n                        "
                          ),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(_vm.client.firstname) +
                                " " +
                                _vm._s(_vm.client.lastname)
                            ),
                          ]),
                        ]),
                  ]
                ),
                _vm._v(" "),
                _vm.client.immutable
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm.$tl("infos.clients.immutable", undefined, {
                              theme: _vm.themeUppercased,
                            })
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _vm.formLoading
              ? _c("LoaderComponent", {
                  attrs: { message: _vm.$t("labels.saveInProgress") },
                })
              : _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.postForm.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row m-0 mt-1" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4 mb-3" },
                          [
                            _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col-md-5" }, [
                                _c("label", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$tl("labels.form.civility")) +
                                      " "
                                  ),
                                  _vm.mandatoryFields.civility
                                    ? _c("small", [_vm._v("*")])
                                    : _vm._e(),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7 radio" },
                                [
                                  _vm._l(
                                    _vm.ALL_CLIENT_CIVILITIES,
                                    function (clientCivility) {
                                      return _c(
                                        "label",
                                        {
                                          key: clientCivility.value,
                                          staticClass: "container-box",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.client.civility,
                                                expression: "client.civility",
                                              },
                                            ],
                                            attrs: {
                                              type: "radio",
                                              name: "civility",
                                              disabled: !_vm.isEditable,
                                              "true-value":
                                                clientCivility.value,
                                            },
                                            domProps: {
                                              value: clientCivility.value,
                                              checked: _vm._q(
                                                _vm.client.civility,
                                                clientCivility.value
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$set(
                                                  _vm.client,
                                                  "civility",
                                                  clientCivility.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.getClientCivilityLabelLong(
                                                  clientCivility.value
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors.form,
                                      errorKey: "civility",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("InputComponent", {
                              attrs: {
                                required: _vm.mandatoryFields.firstname,
                                label: _vm.$t("labels.form.firstname"),
                                disabled: !_vm.isEditable,
                                inputName: "firstname",
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.client.firstname,
                                callback: function ($$v) {
                                  _vm.$set(_vm.client, "firstname", $$v)
                                },
                                expression: "client.firstname",
                              },
                            }),
                            _vm._v(" "),
                            _c("InputComponent", {
                              attrs: {
                                required:
                                  _vm.mandatoryFields.lastname ||
                                  _vm.defaultRequired,
                                label: _vm.$t("labels.form.lastname"),
                                disabled: !_vm.isEditable,
                                inputName: "lastname",
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.client.lastname,
                                callback: function ($$v) {
                                  _vm.$set(_vm.client, "lastname", $$v)
                                },
                                expression: "client.lastname",
                              },
                            }),
                            _vm._v(" "),
                            _c("InputComponent", {
                              attrs: {
                                required: _vm.mandatoryFields.company,
                                label: _vm.$t("labels.form.company"),
                                disabled: !_vm.isEditable,
                                inputName: "company",
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.client.company,
                                callback: function ($$v) {
                                  _vm.$set(_vm.client, "company", $$v)
                                },
                                expression: "client.company",
                              },
                            }),
                            _vm._v(" "),
                            _c("InputComponent", {
                              attrs: {
                                required: _vm.mandatoryFields.email,
                                label: _vm.$t("labels.form.email"),
                                disabled: !_vm.isEditable,
                                inputName: "email",
                                "form-errors": _vm.errors.form,
                                inputType: "email",
                              },
                              model: {
                                value: _vm.client.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.client, "email", $$v)
                                },
                                expression: "client.email",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-3" }, [
                              _c("div", { staticClass: "col-md-5" }, [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(_vm.$tl("labels.form.phoneNumber")) +
                                      " "
                                  ),
                                  _vm.mandatoryFields.tel
                                    ? _c("small", [_vm._v("*")])
                                    : _vm._e(),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("TelInputComponent", {
                                    attrs: {
                                      required: _vm.mandatoryFields.tel,
                                      disabled: !_vm.isEditable,
                                      inputName: "tel",
                                      "form-errors": _vm.errors.form,
                                    },
                                    on: {
                                      "set-tel-country": function ($event) {
                                        _vm.client.tel_country = $event
                                      },
                                    },
                                    model: {
                                      value: _vm.client.tel,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.client, "tel", $$v)
                                      },
                                      expression: "client.tel",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-3" }, [
                              _c("div", { staticClass: "col-md-5" }, [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(_vm.$tl("labels.form.lang")) + " "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("ChooseLang", {
                                    staticClass: "d-inline-block",
                                    staticStyle: {
                                      "z-index": "auto !important",
                                    },
                                    attrs: {
                                      disabled: !_vm.isEditable,
                                      "is-input-mode": "",
                                      "should-translate-label": "",
                                    },
                                    model: {
                                      value: _vm.client.locale,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.client, "locale", $$v)
                                      },
                                      expression: "client.locale",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col-md-5 pt-2" }, [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(_vm.$tl("labels.form.comment"))
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.client.comment,
                                        expression: "client.comment",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      disabled: !_vm.isEditable,
                                      name: "comment",
                                      rows: "4",
                                    },
                                    domProps: { value: _vm.client.comment },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.client,
                                          "comment",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors.form,
                                      errorKey: "comment",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            !_vm.isCreating && _vm.hasBooking
                              ? _c("div", { staticClass: "row mb-1" }, [
                                  _c("div", { staticClass: "col-md-5" }, [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("labels.clients.loyalty_level")
                                        ) +
                                          " (" +
                                          _vm._s(
                                            _vm.$t(
                                              `labels.modules.${_vm.MODULE_TYPE_BOOKING.value}`
                                            )
                                          ) +
                                          ")"
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.client[
                                            `${_vm.MODULE_TYPE_BOOKING.urlValue}_loyalty_level`
                                          ] !== null
                                            ? _vm.getClientLoyaltyLevelLabel(
                                                _vm.client[
                                                  `${_vm.MODULE_TYPE_BOOKING.urlValue}_loyalty_level`
                                                ]
                                              )
                                            : _vm.$tl("labels.none")
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isCreating && _vm.hasClickAndCollect
                              ? _c("div", { staticClass: "row mb-1" }, [
                                  _c("div", { staticClass: "col-md-5" }, [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("labels.clients.loyalty_level")
                                        ) +
                                          " (" +
                                          _vm._s(
                                            _vm.$t(
                                              `labels.modules.${_vm.MODULE_TYPE_CLICK_AND_COLLECT.value}`
                                            )
                                          ) +
                                          ")"
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.client[
                                            `${_vm.MODULE_TYPE_CLICK_AND_COLLECT.urlValue}_loyalty_level`
                                          ] !== null
                                            ? _vm.getClientLoyaltyLevelLabel(
                                                _vm.client[
                                                  `${_vm.MODULE_TYPE_CLICK_AND_COLLECT.urlValue}_loyalty_level`
                                                ]
                                              )
                                            : _vm.$tl("labels.none")
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isCreating && _vm.hasGiftVouchers
                              ? _c("div", { staticClass: "row mb-1" }, [
                                  _c("div", { staticClass: "col-md-5" }, [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("labels.clients.loyalty_level")
                                        ) +
                                          " (" +
                                          _vm._s(
                                            _vm.$t(
                                              `labels.modules.${_vm.MODULE_TYPE_GIFT_VOUCHERS.value}`
                                            )
                                          ) +
                                          ")"
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.client[
                                            `${_vm.MODULE_TYPE_GIFT_VOUCHERS.urlValue}_loyalty_level`
                                          ] !== null
                                            ? _vm.getClientLoyaltyLevelLabel(
                                                _vm.client[
                                                  `${_vm.MODULE_TYPE_GIFT_VOUCHERS.urlValue}_loyalty_level`
                                                ]
                                              )
                                            : _vm.$tl("labels.none")
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-1" }, [
                              _c("div", { staticClass: "col-md-5 pt-1" }, [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(_vm.$tl("labels.clients.type")) + " "
                                  ),
                                  _vm.mandatoryFields.type
                                    ? _c("small", [_vm._v("*")])
                                    : _vm._e(),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("select-client-type", {
                                    attrs: {
                                      readonly: !_vm.isEditable,
                                      "restaurant-id": _vm.restaurantId,
                                      required: _vm.mandatoryFields.type,
                                    },
                                    model: {
                                      value: _vm.client.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.client, "type", $$v)
                                      },
                                      expression: "client.type",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors.form,
                                      errorKey: "type",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("switch-input-component", {
                              attrs: {
                                label: _vm.$t("labels.clients.vip"),
                                inputName: "is_vip",
                                disabled: !_vm.isEditable,
                                "wrapper-classes": { "mb-2": false },
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.client.is_vip,
                                callback: function ($$v) {
                                  _vm.$set(_vm.client, "is_vip", $$v)
                                },
                                expression: "client.is_vip",
                              },
                            }),
                            _vm._v(" "),
                            _c("SwitchInputComponent", {
                              attrs: {
                                label: _vm.$t("labels.clients.risky"),
                                inputName: "is_risky",
                                disabled: !_vm.isEditable,
                                "wrapper-classes": { "mb-2": false },
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.client.is_risky,
                                callback: function ($$v) {
                                  _vm.$set(_vm.client, "is_risky", $$v)
                                },
                                expression: "client.is_risky",
                              },
                            }),
                            _vm._v(" "),
                            _c("SwitchInputComponent", {
                              attrs: {
                                label: _vm.$t(
                                  "labels.form.acceptNewsletterShort"
                                ),
                                inputName: "newsletter",
                                disabled: !_vm.isEditable,
                                "wrapper-classes": { "mb-2": false },
                                "form-errors": _vm.errors.form,
                              },
                              model: {
                                value: _vm.client.newsletter,
                                callback: function ($$v) {
                                  _vm.$set(_vm.client, "newsletter", $$v)
                                },
                                expression: "client.newsletter",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-3" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between",
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-success btn-circle d-inline-block",
                                            attrs: {
                                              disabled: !_vm.isEditable,
                                              type: "submit",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.actions.save"
                                                  )
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.isEditing
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-success btn-circle ml-2",
                                                attrs: {
                                                  type: "button",
                                                  disabled: !_vm.isEditable,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showMergeClientModal = true
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.clients.merge.single"
                                                      )
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "btn btn-secondary btn-circle ml-2",
                                            attrs: {
                                              to: {
                                                name: `${_vm.saas}.clients`,
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.actions.cancel"
                                                  )
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.isOwner && _vm.isEditing
                                      ? _c("div", [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-danger btn-circle ml-2",
                                              attrs: {
                                                disabled: !_vm.isEditable,
                                                type: "button",
                                              },
                                              on: {
                                                click: _vm.deleteClientData,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.clients.deleteData"
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
            _vm._v(" "),
            _vm.isEditing
              ? [
                  _c("div", { staticClass: "row m-0 mt-3" }, [
                    _vm.hasBooking
                      ? _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            {
                              staticClass: "border-light b-radius-20 p-4 mb-3",
                            },
                            [
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$tl("labels.recap"))),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "media align-items-stretch border-light mt-2 mb-1",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "bg-light-grey p-3 text-blue-light media-middle",
                                        },
                                        [
                                          _c("feather", {
                                            attrs: { type: "check" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "media-body p-3" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "mt-1 mb-1" },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.reservations.over"
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "media-right p-3 media-middle border-left-light",
                                        },
                                        [
                                          _c(
                                            "h5",
                                            { staticClass: "mt-1 mb-0" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.client.nb_reservations
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "media align-items-stretch border-light mt-2 mb-1",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "bg-light-grey text-blue-light p-3 media-middle",
                                        },
                                        [
                                          _c("feather", {
                                            attrs: { type: "x" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "media-body p-3" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "mt-1 mb-1" },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.reservations.canceled"
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "media-right p-3 media-middle border-left-light",
                                        },
                                        [
                                          _c(
                                            "h5",
                                            { staticClass: "mt-1 mb-0" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.client.nb_cancellations
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "media align-items-stretch border-light mt-2 mb-1",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "bg-light-grey text-blue-light p-3 media-middle",
                                        },
                                        [
                                          _c("feather", {
                                            attrs: { type: "alert-triangle" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "media-body p-3 d-flex justify-content-between",
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "mt-1 mb-1" },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.noshows",
                                                      this.restaurantId
                                                    )
                                                  ) +
                                                  "\n                                            "
                                              ),
                                              _vm.client.reset_noshow_count
                                                ? _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-muted",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                (" +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "labels.clients.resetOn",
                                                              {
                                                                date: _vm.displayDate(
                                                                  _vm.client
                                                                    .reset_noshow_count,
                                                                  _vm.DATE_SHORT
                                                                ),
                                                              }
                                                            )
                                                          ) +
                                                          ")\n                                            "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.client.nb_noshows == 0 ||
                                          !_vm.isEditable
                                            ? _c("feather", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.getTooltip(
                                                      _vm.$t(
                                                        "labels.clients.resetNoShowCount"
                                                      )
                                                    ),
                                                    expression:
                                                      "getTooltip($t('labels.clients.resetNoShowCount'))",
                                                  },
                                                ],
                                                staticClass:
                                                  "mt-1 feather-20 text-muted",
                                                staticStyle: {
                                                  cursor: "not-allowed",
                                                },
                                                attrs: { type: "rotate-cw" },
                                              })
                                            : _c("feather", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.getTooltip(
                                                      _vm.$t(
                                                        "labels.clients.resetNoShowCount"
                                                      )
                                                    ),
                                                    expression:
                                                      "getTooltip($t('labels.clients.resetNoShowCount'))",
                                                  },
                                                ],
                                                staticClass:
                                                  "mt-1 text-danger feather-20 pointer",
                                                attrs: { type: "rotate-cw" },
                                                on: {
                                                  click: _vm.resetNoShowCount,
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "media-right p-3 media-middle border-left-light",
                                        },
                                        [
                                          _c(
                                            "h5",
                                            { staticClass: "mt-1 mb-0" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.client.nb_noshows)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row m-0" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("ClientHistory", {
                          staticClass: "border-light b-radius-20 p-4 mb-3",
                          attrs: {
                            reservations: _vm.client.reservations_history,
                            cc_commands: _vm.client.cc_commands_history,
                            gv_sales: _vm.client.gv_sales_history,
                          },
                          on: {
                            "show-resa": _vm.showResa,
                            "show-cc-command": _vm.showCcCommand,
                            "show-gv-sale": _vm.showGvSale,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              : _vm._e(),
          ],
      _vm._v(" "),
      _vm.showReservationModal
        ? _c("show-reservation-modal", {
            attrs: {
              reservation_id: _vm.selected_reservation_id,
              restaurant_id: _vm.selected_restaurant_id,
              showEditButton: _vm.resaCanBeEdited,
            },
            on: {
              "resa-edit": _vm.editReservationFromShowModal,
              close: function ($event) {
                _vm.showReservationModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showEditReservationModal
        ? _c("edit-reservation-modal", {
            attrs: {
              restaurant_id: _vm.selected_restaurant_id,
              reservation_id: _vm.selected_reservation_id,
            },
            on: {
              close: function ($event) {
                _vm.showEditReservationModal = false
              },
              "reservation-edited": _vm.reservationEdited,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showClickAndCollectModal
        ? _c("ShowClickAndCollectCommand", {
            attrs: {
              command_id: _vm.selected_cc_command_id,
              restaurant_id: _vm.selected_restaurant_id,
            },
            on: {
              close: function ($event) {
                _vm.showClickAndCollectModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showGiftVouchersModal
        ? _c("showGiftVoucherModal", {
            attrs: {
              sale: _vm.selected_gv_sale,
              restaurant_id: _vm.selected_restaurant_id,
            },
            on: {
              close: function ($event) {
                _vm.showGiftVouchersModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.client && _vm.showMergeClientModal
        ? _c("MergeClientModal", {
            attrs: { client: _vm.client },
            on: {
              close: function ($event) {
                _vm.showMergeClientModal = false
              },
              "clients-merged": _vm.onClientsMerged,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }