var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mb-3" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _vm.nbLoading > 0
          ? _c("loader-component")
          : _vm.stats.main
          ? _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
              _c("h6", { staticClass: "row ml-0 mt-3 col-12" }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$tl("labels.booking.stats.other.title"))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row m-0 click-collect-report mb-4" }, [
                _c("div", { staticClass: "col-md-6 p-1" }, [
                  _c(
                    "div",
                    { staticClass: "border-light p-3 b-radius-20" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$tl("labels.booking.stats.other.percentNoShow")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pt-2" }, [
                        _c("strong", { staticClass: "value" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$tl("labels.booking.stats.periodOne")
                              ) +
                              " " +
                              _vm._s(
                                _vm.formatPercent(_vm.stats.main.noshow_percent)
                              ) +
                              "%\n                            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.stats.comparison && _vm.filters.hasComparison
                        ? [
                            _c(
                              "div",
                              { staticClass: "pt-2" },
                              [
                                _c("strong", { staticClass: "text-success" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.stats.periodTwo"
                                        )
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.formatPercent(
                                          _vm.stats.comparison.noshow_percent
                                        )
                                      ) +
                                      "%\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                !(
                                  _vm.formatPercent(
                                    _vm.stats.main.noshow_percent
                                  ) == 0 &&
                                  _vm.formatPercent(
                                    _vm.stats.comparison.noshow_percent
                                  ) == 0
                                )
                                  ? _c("ArrowPercentEvolutionComponent", {
                                      staticClass: "pt-2 font-weight-bold",
                                      attrs: {
                                        difference:
                                          _vm.stats.main.noshow_percent -
                                          _vm.stats.comparison.noshow_percent,
                                        percent: _vm.getEvolution(
                                          _vm.formatPercent(
                                            _vm.stats.main.noshow_percent
                                          ),
                                          _vm.formatPercent(
                                            _vm.stats.comparison.noshow_percent
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6 p-1" }, [
                  _c(
                    "div",
                    { staticClass: "border-light p-3 b-radius-20" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.stats.other.percentNewClients"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pt-2" }, [
                        _c("strong", { staticClass: "value" }, [
                          _vm._v(
                            _vm._s(_vm.$tl("labels.booking.stats.periodOne")) +
                              " " +
                              _vm._s(
                                _vm.formatPercent(_vm.stats.main.new_clients)
                              ) +
                              "%"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.stats.comparison && _vm.filters.hasComparison
                        ? [
                            _c(
                              "div",
                              { staticClass: "pt-2" },
                              [
                                _c("strong", { staticClass: "text-success" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.stats.periodTwo"
                                        )
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.formatPercent(
                                          _vm.stats.comparison.new_clients
                                        )
                                      ) +
                                      "%\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                !(
                                  _vm.formatPercent(
                                    _vm.stats.main.new_clients
                                  ) == 0 &&
                                  _vm.formatPercent(
                                    _vm.stats.comparison.new_clients
                                  ) == 0
                                )
                                  ? _c("ArrowPercentEvolutionComponent", {
                                      staticClass: "pt-2 font-weight-bold",
                                      attrs: {
                                        difference:
                                          _vm.stats.main.new_clients -
                                          _vm.stats.comparison.new_clients,
                                        percent: _vm.getEvolution(
                                          _vm.formatPercent(
                                            _vm.stats.main.new_clients
                                          ),
                                          _vm.formatPercent(
                                            _vm.stats.comparison.new_clients
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6 p-1" }, [
                  _c(
                    "div",
                    { staticClass: "border-light p-3 b-radius-20" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.stats.other.confirmationMailLeadToConfirmedResa"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pt-2" }, [
                        _c(
                          "strong",
                          { staticClass: "value" },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$tl("labels.booking.stats.periodOne")
                                ) +
                                "\n                                " +
                                _vm._s(_vm.stats.main.count_confirmed) +
                                " / " +
                                _vm._s(
                                  _vm.stats.main.count_needed_confirmation
                                ) +
                                "\n                                "
                            ),
                            _vm.stats.main.count_needed_confirmation != 0
                              ? [
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          _vm.formatPercent(
                                            _vm.stats.main.percent
                                          )
                                        ) +
                                        "%)"
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.stats.comparison && _vm.filters.hasComparison
                        ? [
                            _c("div", { staticClass: "pt-2" }, [
                              _c(
                                "strong",
                                { staticClass: "text-success" },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.stats.periodTwo"
                                        )
                                      ) +
                                      "\n                                    " +
                                      _vm._s(
                                        _vm.stats.comparison.count_confirmed
                                      ) +
                                      " / " +
                                      _vm._s(
                                        _vm.stats.comparison
                                          .count_needed_confirmation
                                      ) +
                                      "\n                                    "
                                  ),
                                  _vm.stats.comparison
                                    .count_needed_confirmation != 0
                                    ? [
                                        _c("span", { staticClass: "ml-2" }, [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                _vm.formatPercent(
                                                  _vm.stats.comparison.percent
                                                )
                                              ) +
                                              "%)"
                                          ),
                                        ]),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !(
                                    _vm.formatPercent(_vm.stats.main.percent) ==
                                      0 &&
                                    _vm.formatPercent(
                                      _vm.stats.comparison.percent
                                    ) == 0
                                  )
                                    ? _c("ArrowPercentEvolutionComponent", {
                                        staticClass: "pt-2 font-weight-bold",
                                        attrs: {
                                          percent: _vm.getEvolution(
                                            _vm.formatPercent(
                                              _vm.stats.main.percent
                                            ),
                                            _vm.formatPercent(
                                              _vm.stats.comparison.percent
                                            )
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6 p-1" }, [
                  _c(
                    "div",
                    { staticClass: "border-light p-3 b-radius-20" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$tl("labels.booking.stats.other.fillRate"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pt-2" }, [
                        _c("strong", { staticClass: "value" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$tl("labels.booking.stats.periodOne")
                              ) +
                              " " +
                              _vm._s(
                                _vm.formatPercent(_vm.stats.main.fill_rate)
                              ) +
                              "%\n                            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.stats.comparison && _vm.filters.hasComparison
                        ? [
                            _c(
                              "div",
                              { staticClass: "pt-2" },
                              [
                                _c("strong", { staticClass: "text-success" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.stats.periodTwo"
                                        )
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.formatPercent(
                                          _vm.stats.comparison.fill_rate
                                        )
                                      ) +
                                      "%\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                !(
                                  _vm.formatPercent(_vm.stats.main.fill_rate) ==
                                    0 &&
                                  _vm.formatPercent(
                                    _vm.stats.comparison.fill_rate
                                  ) == 0
                                )
                                  ? _c("ArrowPercentEvolutionComponent", {
                                      staticClass: "pt-2 font-weight-bold",
                                      attrs: {
                                        difference:
                                          _vm.stats.main.fill_rate -
                                          _vm.stats.comparison.fill_rate,
                                        percent: _vm.getEvolution(
                                          _vm.formatPercent(
                                            _vm.stats.main.fill_rate
                                          ),
                                          _vm.formatPercent(
                                            _vm.stats.comparison.fill_rate
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }