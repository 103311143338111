<template>
    <div class="pb-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">{{ $tl("labels.clickAndCollect.waitingOrders.title") }}</h5>
                </div>
            </div>
        </div>
        <div class="row m-0 pb-5">
            <div class="col-12">
                <LoaderComponent v-if="loading > 0" />
                <div v-else>
                    <div v-if="error" class="alert alert-danger">
                        {{ error }}
                    </div>
                    <div v-else>
                        <div v-if="commands.data.length < 1">
                            <em>{{ $tl("labels.clickAndCollect.waitingOrders.noWaitingOrders", restaurant_id) }}</em>
                        </div>
                        <command-list
                            v-else
                            :restaurant_id="restaurant_id"
                            :commands="commands"
                            :columns="{
                                number: true,
                                client: true,
                                status: {
                                    show: true,
                                    readonly: false,
                                },
                                date: true,
                                slot: true,
                                price: true,
                                products: true,
                                actions: {
                                    edit: true,
                                    send: true,
                                    clickable: true,
                                },
                            }" />
                    </div>
                </div>
            </div>
        </div>
        <show-command-modal
            :command_id="modals.params.selected_command_id"
            :restaurant_id="modals.params.selected_restaurant_id"
            @close="modals.displayShowCommandModal = false"
            v-if="modals.displayShowCommandModal" />
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../components/LoaderComponent.vue";
import moment from "moment";
import ShowCommandModal from "../../components/Modals/clickAndCollect/ShowClickAndCollectCommand";
import CommandList from "../../components/clickAndCollect/Command/CommandList";

export default {
    data() {
        return {
            loading: 0,
            error: null,
            commands_: {
                data: [],
            },
            modals: {
                params: {
                    selected_command_id: null,
                    selected_restaurant_id: null,
                },
                displayShowCommandModal: false,
            },
            socket: {
                channel: null,
            },
        };
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        commands() {
            return {
                ...this.commands_,
                data: this.commands_.data.filter((c) => c.status === "to_accept"),
            };
        },
    },
    created() {
        // this.registerSocketsForDate();
        this.fetchData();
    },
    beforeDestroy() {
        // this.unregisterAllSockets();
    },
    methods: {
        fetchData() {
            this.loading++;
            this.error = null;
            this.$store
                .dispatch("ccCommands/fetchCommandsWaitingConfirmation", {
                    restaurant_id: this.restaurant_id,
                })
                .then((response) => {
                    this.commands_ = response.data;
                    this.loading--;
                })
                .catch((error) => {
                    if (error.response && error.response.data.message) {
                        this.error = error.response.data.message;
                    } else if (error.message) {
                        this.error = error.message;
                    } else {
                        this.error = "Une erreur est survenue.";
                    }
                    this.loading--;
                });
        },
        showCommand(command, event = null) {
            const excludedTags = ["BUTTON", "A"];

            if (!event || !event.target || !excludedTags.includes(event.target.tagName)) {
                this.modals.params.selected_command_id = command.id;
                this.modals.params.selected_restaurant_id = command.restaurant_id;
                this.$nextTick(() => {
                    this.modals.displayShowCommandModal = true;
                });
            }
        },
    },
    watch: {
        restaurant_id(newVal, oldVal) {
            this.unregisterSockets();
            this.registerSocketsForDate();
            this.fetchData();
        },
    },
    components: {
        LoaderComponent,
        ShowCommandModal,
        CommandList,
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
