var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-5 mt-2" }, [
      _vm._v("Image " + _vm._s(_vm.nb) + " "),
      _c("small", { staticClass: "text-danger" }, [_vm._v("*")]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-7 mt-1" },
      [
        _c("ImageComponent", {
          attrs: {
            maxSize: 10,
            disabled: _vm.disabled,
            alt: "galerie",
            src: _vm.src,
            suffixId: "gallery" + _vm.nb,
            addStyle: "max-width:200px;",
            postURI: _vm.basePostURL + "gallery" + _vm.nb,
            allowedExtensions: _vm.allowedExtensions,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }