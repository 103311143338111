var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.restaurant_id && _vm.user
    ? _c(
        "div",
        { staticClass: "nav-btn-2 mx-md-5" },
        [
          _c("div", { staticClass: "btn-group" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-success btn-nav-mob btn-circle",
                attrs: {
                  title: "Ajouter une commande",
                  disabled: !_vm.has_right_to_create_commands,
                },
                on: { click: _vm.showAddSaleModal },
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "feather feather-plus sc-dnqmqq jxshSx",
                    class: {
                      "mr-0": _vm.isSidebarCollapsed,
                    },
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      fill: "none",
                      stroke: "currentColor",
                      "stroke-width": "2",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "aria-hidden": "true",
                    },
                  },
                  [
                    _c("line", {
                      attrs: { x1: "12", y1: "5", x2: "12", y2: "19" },
                    }),
                    _vm._v(" "),
                    _c("line", {
                      attrs: { x1: "5", y1: "12", x2: "19", y2: "12" },
                    }),
                  ]
                ),
                _vm._v("\n            Ajouter une commande\n        "),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.showAddClickAndCollectSaleModal
            ? _c("add-click-and-collect-sale-modal", {
                on: {
                  close: function ($event) {
                    _vm.showAddClickAndCollectSaleModal = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }