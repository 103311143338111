<template>
    <div class="w-100">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <div class="d-flex align-items-center">
                        <h5 class="title mt-2">Commandes à livrer</h5>
                        <button
                            class="btn btn-sm btn-outline-secondary btn-square ml-2"
                            v-tooltip="getTooltip('Raffraichir la liste des commandes')"
                            @click="fetchCommands()">
                            <feather type="refresh-cw" />
                        </button>
                    </div>
                    <div>
                        <button @click="exportCommands()" class="btn btn-sm btn-outline-secondary btn-circle">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-upload">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="17 8 12 3 7 8"></polyline>
                                <line x1="12" y1="3" x2="12" y2="15"></line>
                            </svg>
                            Export Excel
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="alert alert-danger" style="position: relative; top: 0" v-if="errors.length > 0">
            <span class="d-block" v-for="(error, index) in errors" :key="index">{{ error }}</span>
        </div>
        <LoaderComponent v-if="loading" />
        <template v-else>
            <div class="row m-0">
                <div class="col-12">
                    <h2 class="h5 d-inline-block">
                        <button
                            @click="date = moment(date).subtract(1, 'day').toDate()"
                            class="btn btn-sm btn-outline-secondary radius-btn-square"
                            style="width: 29px; padding-left: 5px">
                            <svg
                                class="feather feather-chevron-left sc-dnqmqq jxshSx"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                aria-hidden="true">
                                <polyline points="15 18 9 12 15 6"></polyline>
                            </svg>
                        </button>

                        <span class="date-resa ml-1 mr-1 pt-1" style="text-transform: uppercase" @click="openPicker">
                            {{ dateString }}
                        </span>

                        <button
                            @click="date = moment(date).add(1, 'day').toDate()"
                            class="btn btn-sm btn-outline-secondary radius-btn-square"
                            style="width: 29px; padding-left: 6px">
                            <svg
                                class="feather feather-chevron-right sc-dnqmqq jxshSx"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                aria-hidden="true">
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </button>

                        <button type="button" class="btn btn-sm btn-outline-secondary none-mobile btn-circle" @click="date = new Date()">
                            Aujourd'hui
                        </button>
                        <button type="button" class="btn btn-sm btn-outline-secondary none-desk btn-circle" @click="date = new Date()">Auj</button>
                    </h2>
                </div>
                <datepicker
                    class="btn btn-sm date-resa date-resa-cal"
                    ref="theDatePicker"
                    format="dd/MM/yyyy"
                    :monday-first="true"
                    :language="fr"
                    v-model="date"></datepicker>
                <div class="col-12">
                    <CommandList
                        :type="COLLECT_TYPE_DELIVERY.value"
                        :commands="{ data: commands_ }"
                        :columns="{
                            number: true,
                            client: true,
                            restaurant: true,
                            show_payment_status: false,
                            status: {
                                show: true,
                                readonly: false,
                                editable_status: [COMMAND_READY.value, COMMAND_COLLECTED.value],
                            },
                            delivery_address: true,
                            slot: true,
                            price: true,
                            products: true,
                            actions: {
                                edit: false,
                                send: false,
                                clickable: true,
                                hide_client_btn: true,
                                mark_delivered: true,
                            },
                        }" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import moment from "moment";
import LoaderComponent from "../../components/LoaderComponent";
import CommandList from "../../components/clickAndCollect/Command/CommandList";
import CollectTypesEnum from "../../mixins/enums/click_and_collect/CollectTypesEnum";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import CommandStatusEnum from "../../mixins/enums/click_and_collect/CommandStatusEnum";

export default {
    data() {
        return {
            loading: false,
            commands: [],
            errors: [],
            fr,
            moment,
            socket: {
                channels: [],
            },
        };
    },
    mixins: [CollectTypesEnum, CommandStatusEnum],
    computed: {
        restaurantIds() {
            return this.$store.getters["users/restaurantIds"];
        },
        dateString() {
            return `${this.getDayName(this.date)} ${this.cleanDate(this.date)}`;
        },
        date: {
            get() {
                if (this.$route.query.date) return moment(this.$route.query.date).toDate();
                return new Date();
            },
            set(newVal) {
                this.$router.push({
                    name: "home",
                    query: {
                        date: moment(newVal).format("Y-MM-DD"),
                    },
                });
            },
        },
        commands_() {
            const statusToExclude = [
                this.COMMAND_PENDING.value,
                this.COMMAND_TO_ACCEPT.value,
                this.COMMAND_DISPATCHED.value,
                this.COMMAND_CANCELED.value,
            ];
            let commands = this.commands.filter((c) => !statusToExclude.includes(c.status));
            let scores = {};
            scores[this.COMMAND_TO_PREPARE.value] = 0;
            scores[this.COMMAND_READY.value] = 0;
            scores[this.COMMAND_COLLECTED.value] = 1;
            return commands.sort((a, b) => {
                const momentA = moment(`${a.reservation_date} ${a.slot.hour_start}`);
                const momentB = moment(`${b.reservation_date} ${b.slot.hour_start}`);
                if (scores[a.status] != scores[b.status]) return scores[a.status] - scores[b.status];
                if (momentA.isAfter(momentB)) return 1;
                if (momentA.isBefore(momentB)) return -1;
                return a.restaurant_id - b.restaurant_id;
            });
        },
    },
    methods: {
        exportCommands() {
            const restaurantIds = this.restaurantIds.join("-");
            let api_url = `/api/click_and_collect/courier/export/commands?date=${moment(this.date).format(
                "Y-MM-DD"
            )}&restaurants_ids=${restaurantIds}`;
            window.open(api_url, "_blank");
        },
        fetchCommands(restaurant_id = null) {
            this.loading = true;
            this.errors = [];
            this.commands = [];

            let promises = [];
            if (restaurant_id === null)
                this.restaurantIds.forEach((id) => {
                    promises.push(this.fetchCommandsForRestaurant(id));
                });
            else promises.push(this.fetchCommandsForRestaurant(restaurant_id));
            Promise.all(promises).then(() => (this.loading = false));
        },
        fetchCommandsForRestaurant(restaurant_id) {
            const date = moment(this.date).format("Y-MM-DD");

            return this.$store
                .dispatch("ccCommands/fetchCommandsAtDate", {
                    params: {
                        date,
                        type: this.COLLECT_TYPE_DELIVERY.value,
                    },
                    restaurant_id,
                })
                .then((response) => {
                    this.commands = this.commands.concat(response.data.data);
                })
                .catch((error) => {
                    this.errors.push(this.getErrorMsgFromErrorResponse(error));
                });
        },
        getDayName(date) {
            var new_date = moment(date).toDate();
            var options = { weekday: "long" };
            return new_date.toLocaleDateString("fr-FR", options);
        },
        cleanDate(date) {
            var new_date = moment(date).toDate();
            var options = { month: "long", day: "numeric" };
            return new_date.toLocaleDateString("fr-FR", options);
        },
        openPicker() {
            this.$refs.theDatePicker.showCalendar();
        },
        registerSocketsForDate() {
            this.restaurantIds.forEach((id) => {
                this.socket.channels.push(`App.restaurant.${id}.clickandcollect.date.${moment(this.date).format("Y-MM-DD")}`);
            });
            this.socket.channels.forEach((channel) => {
                Echo.private(channel)
                    .listen(".command.created", this.onCommandCreated)
                    .listen(".command.updated", this.onCommandUpdated)
                    .listen(".command.deleted", this.onCommandDeleted);
                this.$store.commit("sockets/addChannel", channel);
            });
        },
        unregisterSockets() {
            this.socket.channels.forEach((channel) => {
                Echo.leave(channel);
                this.$store.commit("sockets/removeChannel", channel);
            });
            this.socket.channels = [];
        },
        unregisterAllSockets() {
            this.$store.getters["sockets/getChannels"].forEach((channel) => {
                Echo.leave(channel);
            });

            this.$store.commit("sockets/removeAllChannels");
            this.socket.channels = [];
        },
        onCommandCreated(e) {
            const newCommand = e.command;
            newCommand.new = true;
            const index = this.commands.push(newCommand) - 1;
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        newCommand.new = false;
                        this.$set(this.commands, index, newCommand);
                    });
                });
            });
        },
        onCommandUpdated(e) {
            const updatedCommand = e.command;
            const index = this.commands.findIndex((c) => c.id == updatedCommand.id);
            const oldCommand = this.commands[index];
            if (oldCommand.status === "pending" && oldCommand.status !== updatedCommand.status) {
                updatedCommand.new = true;
            } else {
                updatedCommand.updated = true;
            }
            this.$set(this.commands, index, updatedCommand);
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        if (updatedCommand.new === true) {
                            updatedCommand.new = false;
                        } else {
                            updatedCommand.updated = false;
                        }
                        this.$set(this.commands, index, updatedCommand);
                    });
                });
            });
        },
        onCommandDeleted(e) {
            const deletedCommand = e.command;
            const index = this.commands.findIndex((c) => c.id == deletedCommand.id);

            if (index > -1) {
                var oldCommand = this.commands[index];

                oldCommand.deleted = true;

                this.$set(this.commands, index, oldCommand);

                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            oldCommand.deleted = false;

                            this.commands.splice(index, 1);
                        });
                    });
                });
            }
        },
    },
    watch: {
        date() {
            this.unregisterSockets();
            this.registerSocketsForDate();
            this.fetchCommands();
        },
    },
    components: {
        LoaderComponent,
        CommandList,
        Datepicker,
    },
    created() {
        this.fetchCommands();
        this.registerSocketsForDate();
    },
    beforeDestroy() {
        this.unregisterAllSockets();
    },
};
</script>
