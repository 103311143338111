export default {
    booking: {
        seatingPlan: {
            rooms: {
                enabledForService: "Room enabled for game",
                disabledForService: "Room disabled for game",
            },
            tables: {
                enabledForService: "Table enabled for game",
                disabledForService: "Table disabled for game",
            },
        },
        services: {
            added: "The game has been added.",
            duplicated: "The game has been duplicated",
            deleted: "The game has been deleted",
            nowFull: 'The selected games are now displayed as "full".',
            removeFull: "The selected game is no longer full",
        },
    },
};
