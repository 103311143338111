<template>
    <div class="h-100">
        <div v-if="!isBrowserIE" class="back-off h-100">
            <topbar
                :style="moduleSwitcher.width > 0 ? `margin-left: ${moduleSwitcher.width}px !important` : ''"
                :home="{ name: 'website.home' }"
                :isSidebarCollapsed="isSidebarCollapsed"
                :restaurants="restaurants"
                :user="user"
                module_type="website"
                @module-switcher-button-clicked="moduleSwitcher.show = !moduleSwitcher.show"
                :mobileMenuDisplayed="mobileMenuDisplayed"
                @mobile-menu-updated="mobileMenuDisplayed = $event">
                <WebsiteTopbarActions :isSidebarCollapsed="isSidebarCollapsed" :user="user" />
            </topbar>

            <div class="container-fluid h-100" :style="moduleSwitcher.show == true ? 'overflow-x: hidden;' : ''">
                <div class="row h-100">
                    <module-switcher
                        serviceId="website"
                        :isSidebarCollapsed="isSidebarCollapsed"
                        :user="user"
                        @open="onModuleSwitcherOpen"
                        @close="closeModuleSwitcher"
                        :show="moduleSwitcher.show" />
                    <sidebar
                        :style="moduleSwitcher.width > 0 ? `margin-left: ${moduleSwitcher.width}px !important` : ''"
                        :user="user"
                        :restaurants="restaurants"
                        :isSidebarCollapsed="isSidebarCollapsed"
                        :entries="sidebarEntries"
                        :restaurantRouteName="`website.restaurants.settings`"
                        @sidebar-collapse-changed="isSidebarCollapsed = $event"
                        @close-menu="mobileMenuDisplayed = false"
                        @reload-router-view="$emit('reload-router-view')">
                    </sidebar>

                    <main
                        role="main"
                        class="pl-0 pr-0 h-100 main"
                        :style="moduleSwitcher.width > 0 ? `margin-left: ${moduleSwitcher.width + 240}px !important` : ''"
                        :class="{
                            'col-md-12 col-lg-12': user && user.role === 'kitchen',
                            'offset-md-3 offset-lg-2 col-md-9 col-lg-10': user && user.role !== 'kitchen' && !isSidebarCollapsed,
                            'offset-md-1 offset-lg-1 col-lg-11 col-md-11': isSidebarCollapsed,
                        }">
                        <notifications style="z-index: 9999" group="notification" />

                        <slot />
                    </main>
                </div>
            </div>
            <AcceptCgvModal :module="MODULE_TYPE_WEBSITE" v-if="showAcceptCgvModal" @close="showAcceptCgvModal = false" />
        </div>
        <div v-else style="text-align: center">
            <h1>Navigateur non supporté</h1>
            <p v-html="$t('errors.browser.invalid.infos')"></p>
            <br />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Sidebar from "../Sidebar.vue";
import Topbar from "../Topbar.vue";
import ModuleSwitcher from "../ModuleSwitcher";
import WebsiteTopbarActions from "./TopbarActions";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum";
import AcceptCgvModal from "../../components/Modals/CGU/AcceptCgvModal";

export default {
    props: {
        user: {
            required: true,
        },
    },
    mixins: [ModuleTypesEnum],
    data: function () {
        return {
            showAcceptCgvModal: false,
            website_settings: null,
            submenu: false,
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
            selected_service_id: null,
            servicesNavbar: false,
            creditCountSms: null,
            smsActivated: null,
            isSidebarCollapsed: this.isSidebarCollapsedTablet(),
            moduleSwitcher: {
                show: false,
                height: 0,
                width: 0,
            },
            mobileMenuDisplayed: false,
        };
    },
    computed: {
        ga_enabled() {
            return this.$store.getters["websites/ga_enabled"];
        },
        website_enabled() {
            return this.$store.getters["websites/website_enabled"];
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read_customization() {
            return this.rights.includes("website.customization.read");
        },
        has_right_to_read_config() {
            return this.rights.includes("website.config.read");
        },
        sidebarEntries() {
            return [
                {
                    to: { name: "website.restaurants.customization" },
                    title: "Personnalisation",
                    icon: "edit",
                    exactUrlMatching: false,
                    rights: ["website.customization.*"],
                    disabled: !this.website_enabled,
                    askConfirm: () => this.$store.getters["websites/unsaved_changes"],
                },
                {
                    to: { name: "website.restaurants.stats" },
                    title: "Statistiques",
                    icon: "bar-chart-2",
                    exactUrlMatching: false,
                    rights: ["website.stats.*"],
                    disabled: !this.website_enabled || !this.website_settings || !this.website_settings.already_published || !this.ga_enabled,
                    askConfirm: () => this.$store.getters["websites/unsaved_changes"],
                },
                {
                    to: { name: "website.restaurants.settings" },
                    title: "Configuration",
                    icon: "settings",
                    exactUrlMatching: false,
                    rights: ["website.config.*"],
                    askConfirm: () => this.$store.getters["websites/unsaved_changes"],
                },
            ];
        },
        restaurants() {
            return this.$store.getters["restaurants/getRestaurantsByService"]("website").filter((r) => {
                return this.$store.getters["users/restaurantIds"].includes(r.id);
            });
        },
        isOwner() {
            return this.$store.getters["users/role"] === "owner";
        },
    },
    methods: {
        isSidebarCollapsedTablet() {
            if (window.innerWidth <= 1024 && window.innerWidth > 767) {
                return true;
            } else {
                return false;
            }
        },
        onModuleSwitcherOpen({ width, height }) {
            this.moduleSwitcher.width = width;
            this.moduleSwitcher.height = height;
        },
        closeModuleSwitcher() {
            this.moduleSwitcher.width = 0;
            this.moduleSwitcher.height = 0;
            this.moduleSwitcher.show = false;
        },
        logout() {
            axios
                .post("/logout")
                .then((response) => {
                    window.location.href = response.data.redirect_url || response.headers.location || "/login";
                })
                .catch(() => {});
        },
        selectRestaurant(event) {
            this.$router.push({ name: "website.restaurants.settings", params: { restaurant_id: event.target.value } });
        },
        fetchWebsiteEnabled() {
            this.$store
                .dispatch("websites/getWebsiteSettings", { restaurant_id: this.restaurant_id })
                .then((response) => {
                    this.website_settings = response.data.website_settings;
                })
                .catch((error) => {
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        fetchHasAcceptedCgvs() {
            this.loading = true;

            return axios
                .get(`/api/accepted_cgvs/modules/${this.MODULE_TYPE_WEBSITE.value}`)
                .then((response) => {
                    this.loading = false;
                    this.showAcceptCgvModal = !response.data.result;
                })
                .catch(() => (this.loading = false));
        },
    },
    components: {
        Sidebar,
        Topbar,
        ModuleSwitcher,
        WebsiteTopbarActions,
        AcceptCgvModal,
    },
    created() {
        if (this.isOwner) this.fetchHasAcceptedCgvs();
        if (this.restaurant_id) this.fetchWebsiteEnabled();
        let routeName = this.$route.name;
        if (routeName === "website.restaurants.settings")
            routeName = this.has_right_to_read_config ? "website.restaurants.settings.domain" : "website.restaurants.customization";
        if (routeName === "website.restaurants.customization")
            routeName = this.has_right_to_read_customization ? "website.restaurants.customization.content" : "website.restaurants.stats";
        if (routeName != this.$route.name) this.$router.push({ name: routeName });
    },
    watch: {
        restaurant_id(newVal) {
            if (newVal) this.fetchWebsiteEnabled();
        },
        "$route.name": function (newVal) {
            let routeName = newVal;
            if (routeName === "website.restaurants.settings")
                routeName = this.has_right_to_read_config ? "website.restaurants.settings.domain" : "website.restaurants.customization";
            if (routeName === "website.restaurants.customization")
                routeName = this.has_right_to_read_customization ? "website.restaurants.customization.content" : "website.restaurants.stats";
            if (routeName != newVal) this.$router.push({ name: routeName });
        },
    },
};
</script>
