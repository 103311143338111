export default {
    data() {
        return {
            USER_TYPE_USER: {
                value: "user",
                label: "Utilisateur",
            },
            USER_TYPE_MACHINE: {
                value: "machine",
                label: "Système",
            },
        };
    },
    computed: {
        ALL_USER_TYPES() {
            return [this.USER_TYPE_MACHINE, this.USER_TYPE_USER];
        },
    },
};
