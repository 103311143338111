export default {
    data() {
        return {
            SALE_MAIL_LOG_CONFIRMATION: {
                label: "confirmation",
                value: "confirmation",
            },
        };
    },
    methods: {
        getSaleMailLogLabel(value) {
            const found = this.ALL_GV_MAIL_LOGS_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_GV_MAIL_LOGS_TYPES() {
            return [this.SALE_MAIL_LOG_CONFIRMATION];
        },
    },
};
