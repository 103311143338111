export default {
    booking: {
        seatingPlan: {
            rooms: {
                enabledForService: "La salle a été activée pour ce jeu",
                disabledForService: "La salle a été désactivée pour ce jeu",
            },
            tables: {
                enabledForService: "La place a été activée pour ce jeu",
                disabledForService: "La place a été désactivée pour ce jeu",
            },
            services: {
                added: "Le jeu a bien été ajouté.",
                duplicated: "Le jeu a bien été dupliqué",
                deleted: "Le jeu a bien été supprimé",
                nowFull: 'Les jeux séléctionnés sont désormais affichés "complet"',
                removeFull: "Le jeu sélectionné n'est plus complet",
            },
        },
    },
};
