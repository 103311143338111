<template>
    <div class="pb-5 auto-website" style="height: initial !important">
        <LoaderComponent v-if="loading" />
        <div class="ml-2 alert alert-danger" v-else-if="!website_enabled || !ga_enabled">
            Vous devez d'abord publier votre site pour voir les statistiques
        </div>
        <div class="ml-2 alert alert-danger" v-else-if="error">{{ error }}</div>
        <template v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">Statistiques - Google Analytics</h5>
                    </div>
                </div>
            </div>

            <div class="row m-0 mb-3">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex">
                                    <select v-model="filter.from" class="custom-select mb-0 w-auto">
                                        <option :value="period" v-for="period in Object.keys(this.periods)" :key="period">
                                            {{ periods[period] }}
                                        </option>
                                    </select>
                                    <button @click="fetchStats()" class="ml-3 btn btn-sm btn-success btn-circle">Appliquer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row m-0" v-if="stats && stats.main">
                <div class="col-12">
                    <div class="row m-0 mb-2">
                        <div class="col-12">
                            <strong class="text-success">{{ displayingFilterDate }}</strong>
                        </div>
                    </div>
                    <div class="row m-0 mb-2">
                        <div class="col-lg-3 col-md-6 p-1">
                            <div class="border-light p-3 b-radius-20">
                                <span>{{ getGaMetricLabel(GA_METRIC_TOTAL_USERS.value) }}</span>
                                <div>
                                    <strong class="value">
                                        {{ stats.main[GA_METRIC_TOTAL_USERS.value] }}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 p-1">
                            <div class="border-light p-3 b-radius-20">
                                <span>{{ getGaMetricLabel(GA_METRIC_SESSIONS.value) }}</span>
                                <div>
                                    <strong class="value">
                                        {{ stats.main[GA_METRIC_SESSIONS.value] }}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 p-1">
                            <div class="border-light p-3 b-radius-20">
                                <span>{{ getGaMetricLabel(GA_METRIC_AVERAGE_SESSION_DURATION.value) }}</span>
                                <div>
                                    <strong class="value">
                                        {{ stats.main[GA_METRIC_AVERAGE_SESSION_DURATION.value] }}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 p-1">
                            <div class="border-light p-3 b-radius-20">
                                <span>{{ getGaMetricLabel(GA_METRIC_SCREEN_PAGE_VIEWS_PER_SESSION.value) }}</span>
                                <div>
                                    <strong class="value">
                                        {{ stats.main[GA_METRIC_SCREEN_PAGE_VIEWS_PER_SESSION.value] }}
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import GoogleAnalyticsMetricEnum from "../../mixins/enums/GoogleAnalyticsMetricEnum.js";

export default {
    data() {
        return {
            loading: 0,
            error: null,
            errorStats: null,
            stats: null,
            website_settings: null,
            filter: {
                from: "7daysAgo",
                to: "today",
            },
            displaying: {
                filter: {
                    from: "7daysAgo",
                    to: "today",
                },
            },
            periods: {
                today: "Aujourd'hui",
                yesterday: "Hier",
                "7daysAgo": "La semaine dernière",
                "31daysAgo": "Le mois dernier",
                "365daysAgo": "L'an dernier",
            },
        };
    },
    mixins: [GoogleAnalyticsMetricEnum],
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        website_enabled() {
            return this.$store.getters["websites/website_enabled"];
        },
        ga_enabled() {
            return this.$store.getters["websites/ga_enabled"];
        },
        displayingFilterDate() {
            if (this.periods[this.displaying.filter.from]) return this.periods[this.displaying.filter.from];
            return this.displaying.filter.from;
        },
    },
    methods: {
        formatSessionDuration(value) {
            let hours = null;
            let minutes = null;
            let seconds = null;
            if (value / 3600 > 1) {
                hours = Math.ceil(value / 3600);
                value %= 3600;
            }
            if (value / 60 > 1) {
                minutes = Math.ceil(value / 60);
                value %= 60;
            }
            seconds = value;
            let formatted = "";
            if (hours) formatted += `${hours}h `;
            if (minutes) formatted += `${minutes}min `;
            if (seconds) formatted += `${seconds}sec`;
            return formatted.trim();
        },
        fetchStats() {
            this.loading++;

            this.$store
                .dispatch("websites/getStats", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        from: this.filter.from,
                        to: this.filter.to,
                    },
                })
                .then((response) => {
                    this.loading--;
                    this.stats = response.data.stats;
                    this.displaying.filter = this.$_.cloneDeep(this.filter);
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
    },
    watch: {
        "filter.from": function (newVal) {
            if (newVal === "yesterday") this.filter.to = "yesterday";
            else this.filter.to = "today";
        },
    },
    components: {
        LoaderComponent,
    },
    created() {
        this.fetchStats();
    },
};
</script>
