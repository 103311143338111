<template>
    <div>
        <div v-if="isFragmentLoading[sale.id]" class="spinner-border feather-20" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div v-else>
            <button
                type="button"
                @click.prevent.stop="$emit('edit-sale', sale)"
                class="btn btn-sm btn-success btn-square"
                :disabled="!hasRightToUpdate"
                title="Modifier le bon cadeau"
                v-tooltip="getTooltip('Éditer')">
                <feather type="edit" />
            </button>
            <button
                type="button"
                @click.prevent.stop="$emit('send-gv-details', sale.id)"
                class="btn btn-sm btn-outline-secondary btn-square"
                :disabled="isDisabled || !hasRightToUpdate"
                title="Envoyer les détails du bon cadeau au client"
                v-tooltip="getTooltip('Envoyer les détails au client')">
                <feather type="send" />
            </button>
            <a
                :href="`/api/gift_vouchers/${restaurantId}/sales/${sale.id}/gv_pdf`"
                target="_blank"
                class="btn btn-sm btn-outline-secondary btn-square"
                v-tooltip="getTooltip('Télécharger le bon cadeau au format PDF')">
                <feather type="download-cloud" />
            </a>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        restaurantId: {
            type: Number,
            required: true,
        },
        sale: {
            type: Object,
            required: true,
        },
        hasRightToUpdate: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isFragmentLoading: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
};
</script>
