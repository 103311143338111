import OwnersList from "../../views/Admin/OwnersList.vue";
import OwnerDetails from "../../views/Admin/OwnerDetails.vue";
import StatsServices from "../../views/Admin/StatsServices.vue";
import AdminNewFeatures from "../../views/Admin/NewFeatures.vue";
import AdminAppUpdatesList from "../../views/Admin/AppUpdates/AppUpdatesList.vue";
import AppUpdateForm from "../../views/Admin/AppUpdates/AppUpdateForm.vue";
import SubscriptionsCreate from "../../views/Admin/Yservices/SubscriptionsCreate.vue";

import noshow from "./noshow.js";
import yservices from "./yservices.js";

const routes = [
    {
        path: "/admin/owners",
        name: "admin.owners",
        component: OwnersList,
    },
    {
        path: "/admin/archive",
        name: "admin.archive",
        component: OwnersList,
    },
    {
        path: "/admin/owner_details/:owner_id",
        name: "admin.ownerDetails",
        component: OwnerDetails,
    },
    {
        path: "/admin/stats_services",
        name: "admin.stats_services",
        component: StatsServices,
    },
    {
        path: "/admin/new_features",
        name: "admin.new_features",
        component: AdminNewFeatures,
    },
    {
        path: "/admin/app_updates",
        name: "admin.app_updates.list",
        component: AdminAppUpdatesList,
    },
    {
        path: "/admin/app_updates/add",
        name: "admin.app_updates.add",
        component: AppUpdateForm,
    },
    {
        path: "/admin/app_updates/:app_update_id",
        name: "admin.app_updates.edit",
        component: AppUpdateForm,
    },
    {
        path: "/admin/owner_details/:owner_id/licences/create",
        name: "admin.owners.licences.create",
        component: SubscriptionsCreate,
    },
];

if (THEME === "noshow") {
    routes.push(...noshow);
} else if (THEME === "yservices") {
    routes.push(...yservices);
}

export default routes;
