var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.current_tag_raw == _vm.COMMAND_TO_ACCEPT.value
      ? _c("div", [
          _c(
            "button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: { content: "Accepter la commande" },
                  expression: "{ content: 'Accepter la commande' }",
                },
              ],
              staticClass: "btn btn-success btn-sm btn-square",
              attrs: {
                "data-status": _vm.COMMAND_TO_PREPARE.value,
                "data-name": "À préparer",
                "data-class": "text-warning",
                disabled: _vm.readonly || _vm.loading,
              },
              on: { click: _vm.changeStatus },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "feather feather-check sc-dnqmqq jxshSx",
                  staticStyle: { "pointer-events": "none" },
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    stroke: "currentColor",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "aria-hidden": "true",
                  },
                },
                [_c("polyline", { attrs: { points: "20 6 9 17 4 12" } })]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: { content: "Refuser la commande" },
                  expression: "{ content: 'Refuser la commande' }",
                },
              ],
              staticClass: "btn btn-danger btn-sm text-white btn-square",
              attrs: {
                disabled: _vm.readonly || _vm.loading,
                "data-status": _vm.COMMAND_CANCELED.value,
                "data-name": "Annulée",
                "data-class": "text-danger",
              },
              on: { click: _vm.changeStatus },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "feather feather-x sc-dnqmqq jxshSx",
                  staticStyle: { "pointer-events": "none" },
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    stroke: "currentColor",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "aria-hidden": "true",
                  },
                },
                [
                  _c("line", {
                    attrs: { x1: "18", y1: "6", x2: "6", y2: "18" },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    attrs: { x1: "6", y1: "6", x2: "18", y2: "18" },
                  }),
                ]
              ),
            ]
          ),
        ])
      : _c("div", { ref: "dropdown", staticClass: "dropdown" }, [
          _c("div", { attrs: { id: "dropdown-container" } }, [
            !_vm.readonly
              ? _c(
                  "a",
                  {
                    ref: "dropDown",
                    staticClass:
                      "btn btn-sm btn-outline-secondary btn-circle resa-status",
                    class: [
                      _vm.current_tag_class,
                      { "dropdown-toggle": _vm.tags && _vm.tags.length > 0 },
                      _vm.current_tag_raw,
                    ],
                    attrs: {
                      href: "#",
                      role: "button",
                      id: "dropdownMenuLink",
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": "false",
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.current_tag) +
                        "\n            "
                    ),
                  ]
                )
              : _c(
                  "span",
                  {
                    staticClass:
                      "btn btn-sm btn-outline-secondary btn-circle resa-status",
                    class: [_vm.current_tag_class, _vm.current_tag_raw],
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.current_tag) +
                        "\n            "
                    ),
                  ]
                ),
            _vm._v(" "),
            _vm.tags && _vm.tags.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "dropdown-menu shadow",
                    attrs: {
                      id: "dropDownStatus",
                      "aria-labelledby": "dropdownMenuLink",
                    },
                  },
                  _vm._l(_vm.tags, function (value) {
                    return _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "dropdown-item",
                          attrs: {
                            href: "javascript:",
                            disabled: _vm.loading,
                            "data-status": value.tag,
                            "data-name": _vm.getCleanName(value.infos),
                            "data-class": value.infos.class,
                          },
                          on: {
                            click: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "btn",
                                  undefined,
                                  $event.key,
                                  undefined
                                )
                              )
                                return null
                              return _vm.changeStatus.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.getCleanName(value.infos)) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }