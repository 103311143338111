var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "textarea",
      _vm._b(
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.content,
              expression: "content",
            },
          ],
          staticClass: "form-control",
          attrs: { id: _vm.name },
          domProps: { value: _vm.content },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.content = $event.target.value
            },
          },
        },
        "textarea",
        { disabled: _vm.disabled, name: _vm.name, rows: _vm.rows },
        false
      )
    ),
    _vm._v(" "),
    _c("div", [
      _vm.minNbChars !== null
        ? _c("small", [
            _vm._v(
              _vm._s(_vm.$tc("labels.form.textarea.nbMinChars", _vm.minNbChars))
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.maxNbChars !== null
        ? _c("small", [
            _vm._v(
              _vm._s(_vm.$tc("labels.form.textarea.nbMaxChars", _vm.maxNbChars))
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.minNbChars !== null || _vm.maxNbChars !== null
        ? _c(
            "small",
            { class: "d-block font-weight-bold " + _vm.getContentCounterClass },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.$tc(
                      "labels.form.textarea.nbActualChars",
                      typeof _vm.content === "string" ? _vm.content.length : 0
                    )
                  ) +
                  "\n        "
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }