<template>
    <div>
        <LoaderComponent v-if="loading > 0" />
        <div v-else-if="loading === 0" class="row">
            <div v-if="error">{{ error }}</div>
            <div v-else class="col-sm-6">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <div class="row mb-3">
                        <div class="col-12">
                            <strong>{{ $tl("labels.form.facturation.title") }}</strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-5">
                                    {{ $tl("labels.form.businessName") }}
                                </div>
                                <div class="col-7 value">
                                    {{ currentUser.company }}
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-5">{{ $tl("labels.form.firstname") }} / {{ $tl("labels.form.lastname") }}</div>
                                <div class="col-7 value">
                                    {{ `${currentUser.firstname} ${currentUser.lastname}` }}
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-5">
                                    {{ $tl("labels.form.address") }}
                                </div>
                                <div class="col-7 value">
                                    {{ `${currentUser.company_address}, ${currentUser.company_zipcode}, ${currentUser.company_city}` }}
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-5">
                                    {{ $tl("labels.form.email") }}
                                </div>
                                <div class="col-7 value">
                                    {{ currentUser.email }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <div class="row mb-2">
                        <div class="col-12">
                            <strong>{{ $tl("labels.payments.payment") }}</strong>
                        </div>
                    </div>
                    <div>
                        <div class="row" v-if="!isPaid">
                            <div v-if="keepDefaultSource" class="col-12">
                                <ShowCard v-if="userDefaultCard" class="mt-3" :card="userDefaultCard.card" />
                                <ShowSepaDebit v-else-if="userDefaultSepaDebit" class="mt-3" :sepa_debit="userDefaultSepaDebit" />
                                <template v-if="!stripeLoading">
                                    <div>
                                        <button class="btn btn-sm btn-success btn-circle" @click="pay">
                                            {{ payWithDefaultSourceLabel }}
                                        </button>
                                        <button class="d-block btn btn-sm mt-2 text-muted" @click="keepDefaultSource = false">
                                            {{ $tl("labels.payments.methods.other.payWith") }}
                                        </button>
                                    </div>
                                </template>
                                <div class="loading" v-else style="margin-top: 30px">
                                    <div class="spinner-grow text-blue-light" role="status"></div>
                                    <span>{{ $tl("labels.payments.pendingConfirmation") }}</span>
                                </div>
                            </div>
                            <div v-show="!keepDefaultSource" class="col-12">
                                <!-- placeholder for Elements -->
                                <div id="card-element"></div>
                                <div v-if="!stripeLoading" id="cardElementWrapper"></div>
                                <div class="d-inline-block" v-if="!stripeLoading">
                                    <button @click="pay" id="card-button" class="btn btn-sm btn-success btn-circle">
                                        {{ $tl("labels.form.actions.command") }}
                                    </button>
                                    <button
                                        v-if="hasAtLeastOneCard"
                                        @click="keepDefaultSource = true"
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary btn-circle ml-2">
                                        {{ $tl("labels.form.actions.back") }}
                                    </button>
                                </div>
                                <div class="loading" v-else style="margin-top: 30px">
                                    <div class="spinner-grow text-blue-light" role="status"></div>
                                    <span>{{ $tl("labels.payments.pendingConfirmation") }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="success-checkmark">
                                <div class="check-icon">
                                    <span class="icon-line line-tip"></span>
                                    <span class="icon-line line-long"></span>
                                    <div class="icon-circle"></div>
                                    <div class="icon-fix"></div>
                                </div>
                            </div>
                            <p v-if="hasPaidWithCard">{{ $tl("success.payments.paidWithReceipt") }}</p>
                            <p v-else>{{ $tl("success.payments.pendingWithReceipt") }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../LoaderComponent.vue";
import ShowCard from "./ShowCard.vue";
import ShowSepaDebit from "./ShowSepaDebit.vue";
import LangsEnum from "../../mixins/enums/LangsEnum";

export default {
    name: "Facturation",
    data: function () {
        return {
            error: null,
            currentUser: null,
            invoice: null,
            stripeGenerated: false,
            elements: null,
            cardElement: null,
            cardholderName: null,
            cardButton: null,
            stripe: null,
            clientSecret: null,
            loading: 0,
            stripeLoading: false,
            isPaid: false,
            keepDefaultSource: false,
            hasPaidWithCard: false,
            hasAtLeastOneCard: false,
        };
    },
    mixins: [LangsEnum],
    computed: {
        payWithDefaultSourceLabel() {
            if (this.userDefaultSepaDebit) return this.$tl("labels.payments.methods.bankAccount.payWith");
            if (this.userDefaultCard) return this.$tl("labels.payments.methods.card.payWith");
            return "";
        },
        userDefaultSepaDebit() {
            return this.currentUser.stripe_customer.sources.length > 0 && this.currentUser.stripe_customer.sources[0].sepa_debit
                ? this.currentUser.stripe_customer.sources[0]
                : null;
        },
        userDefaultCard() {
            return this.currentUser.stripe_customer.sources.length > 0 && this.currentUser.stripe_customer.sources[0].card
                ? this.currentUser.stripe_customer.sources[0]
                : null;
        },
    },
    created() {
        this.stripe = Stripe(STRIPE_PUBLIC_API_KEY, {
            locale: this.userLang,
        });
        this.getCurrentUser();
    },
    updated() {
        if (!this.cardElement && !this.keepDefaultSource) {
            this.elements = this.stripe.elements();
            this.cardElement = this.elements.create("card");
            this.cardholderName = document.getElementById("cardholder-name");
            this.cardButton = document.getElementById("card-button");
            this.cardElement.mount("#card-element");
        }
    },
    props: {
        getInvoiceUrl: {
            type: String,
            required: true,
        },
        getInvoiceData: {
            type: Object,
            default: () => {
                return {};
            },
        },
        openNewTab: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        updatePaymentInfo() {
            this.$emit("close");

            if (this.openNewTab) {
                window.open(this.$router.resolve({ name: "account" }).href, "_blank");
            } else {
                this.$router.push({ name: "account" });
            }
        },
        pay() {
            this.stripeLoading = true;
            this.hasPaidWithCard = false;
            let payment = null;
            this.getInvoice().then((result) => {
                if (!result) {
                    this.stripeLoading = false;
                    return;
                }

                if (!this.keepDefaultSource) {
                    this.hasPaidWithCard = true;
                    payment = this.stripe.confirmCardPayment(this.invoice.payment_intent.client_secret, {
                        payment_method: {
                            card: this.cardElement,
                        },
                        save_payment_method: this.isYservices && !this.hasAtLeastOneCard,
                    });
                } else {
                    if (this.userDefaultCard) {
                        this.hasPaidWithCard = true;
                        payment = this.stripe.confirmCardPayment(this.invoice.payment_intent.client_secret, {
                            payment_method: this.userDefaultCard.id,
                        });
                    } else if (this.userDefaultSepaDebit) {
                        payment = this.stripe.confirmSepaDebitPayment(this.invoice.payment_intent.client_secret, {
                            payment_method: this.userDefaultSepaDebit.id,
                        });
                    }
                }

                if (payment !== null) {
                    payment.then((result) => {
                        if (result.error) {
                            this.notifyError(null, result.error.message);
                            this.stripeLoading = false;
                        } else {
                            this.isPaid = true;
                            this.notifySuccess(
                                null,
                                this.hasPaidWithCard ? this.$tl("success.payments.paidWithReceipt") : this.$tl("success.payments.pendingWithReceipt")
                            );
                            this.$emit("paid", {
                                hasPaidWithCard: this.hasPaidWithCard,
                                stripe_invoice_id: this.invoice.id,
                            });
                        }
                    });
                }
            });
        },
        getCurrentUser() {
            this.loading++;
            let api_url = "/api/users/current?with_cards=1";
            return axios
                .get(api_url)
                .then((response) => {
                    this.currentUser = response.data;
                    if (this.currentUser.stripe_customer.sources.length > 0) {
                        this.keepDefaultSource = true;
                        this.hasAtLeastOneCard = true;
                    }
                    this.$nextTick(() => {
                        this.loading--;
                    });
                })
                .catch((error) => {
                    this.loading--;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        getInvoice() {
            return axios
                .post(this.getInvoiceUrl, this.getInvoiceData)
                .then((response) => {
                    this.invoice = response.data;
                    return true;
                })
                .catch((error) => {
                    this.notifyError(error);
                    return false;
                });
        },
    },
    components: {
        LoaderComponent,
        ShowCard,
        ShowSepaDebit,
    },
};
</script>
