<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div>
                <div v-if="formErrors" class="alert alert-danger">
                    <p v-for="(error, index) in formErrors" :key="index">
                        {{ error }}
                    </p>
                </div>
                <LoaderComponent v-if="formLoading" />
                <div v-else>
                    <div class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <div class="row">
                                    <div class="col-12 mb-2">
                                        <strong>{{ $tl("labels.booking.inputParams.tags.addCustom") }}</strong>
                                    </div>
                                    <form class="col-12" @submit.prevent.stop="addCurrentTag">
                                        <div class="row mb-2">
                                            <div class="col-6">
                                                <input type="text" class="form-control" v-model="currentTag" />
                                            </div>
                                            <div class="col-6">
                                                <button class="btn btn-sm btn-circle btn-success" type="submit">
                                                    <feather type="plus" /> {{ $tl("labels.form.actions.add") }}
                                                </button>
                                            </div>
                                        </div>
                                        <p class="text-muted m-0" v-if="custom_tags.length === 0">
                                            {{ $tl("labels.booking.inputParams.tags.empty") }}
                                        </p>
                                        <span class="tag is-rounded mb-1" v-for="(tag, index) in custom_tags" :key="index">
                                            {{ getReservationTagLabelFromFrLabel(tag) || tag }}
                                            <button class="delete" type="button" @click="custom_tags.splice(index, 1)"></button>
                                        </span>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../../components/LoaderComponent.vue";
import ReservationsTagsEnum from "../../../../mixins/enums/booking/ReservationTagsEnum";

export default {
    data() {
        return {
            loading: false,
            formLoading: false,
            error: null,
            formErrors: null,
            custom_tags: [],
            currentTag: null,
        };
    },
    mixins: [ReservationsTagsEnum],
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    watch: {
        custom_tags: {
            handler(value) {
                this.$emit("update-custom-tags", value);
            },
            deep: true,
        },
    },
    methods: {
        addCurrentTag() {
            if (this.currentTag) {
                this.custom_tags.push(this.currentTag);
            }
            this.currentTag = null;
        },
        fetchCustomTags() {
            this.error = null;
            this.loading = true;

            axios
                .get(`/api/restaurants/${this.restaurant_id}?include=custom_tags`)
                .then((response) => {
                    this.loading = false;
                    this.custom_tags = response.data.custom_tags;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
    },
    created() {
        this.fetchCustomTags();
    },
    components: {
        LoaderComponent,
    },
};
</script>
