var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : [
            _c("label", { staticClass: "mb-3" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.$tl("labels.booking.reservations.privateComment"))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col-12 tags-com-resa" }, [
                _c(
                  "div",
                  _vm._l(_vm.ALL_RESERVATION_TAGS, function (tag, index) {
                    return _c(
                      "span",
                      {
                        key: index,
                        staticClass: "badge badge-secondary p-1 pl-2 pr-2",
                        class: { "cursor-disabled": _vm.readonly },
                        on: {
                          click: function ($event) {
                            _vm.readonly
                              ? true
                              : _vm.addComment(
                                  _vm.getReservationTagLabel(tag.value)
                                )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.getReservationTagLabel(tag.value)) +
                            "\n                    "
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.restaurant_comment,
                      expression: "restaurant_comment",
                    },
                  ],
                  staticClass: "form-control mt-1",
                  attrs: {
                    name: "restaurant_comment",
                    tabindex: "11",
                    autocomplete: "off",
                    disabled: _vm.readonly,
                    rows: "4",
                  },
                  domProps: { value: _vm.restaurant_comment },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.restaurant_comment = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.hasErrors("restaurant_comment")
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.formErrors.errors.restaurant_comment,
                        function (err) {
                          return _c(
                            "div",
                            { staticClass: "invalid-feedback d-block" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(err) +
                                  "\n                    "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.reservation_id
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-circle btn-success mt-2",
                        attrs: { disabled: _vm.readonly, type: "button" },
                        on: { click: _vm.saveRestaurantComment },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$tl("labels.booking.reservations.saveComment")
                            ) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "sepa" }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-5 pt-2" }, [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$tl("labels.booking.reservations.giftVoucher.title")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-7" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.giftCode,
                      expression: "giftCode",
                    },
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.hasErrors("gift") },
                  attrs: {
                    type: "text",
                    name: "gift",
                    autocomplete: "off",
                    disabled: _vm.readonly,
                    tabindex: "12",
                  },
                  domProps: { value: _vm.giftCode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.giftCode = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.hasErrors("gift")
                  ? _c(
                      "div",
                      _vm._l(_vm.formErrors.errors.gift, function (err) {
                        return _c(
                          "div",
                          { staticClass: "invalid-feedback d-block" },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(err) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _vm.isHotelModeEnabled
              ? [
                  _c("div", { staticClass: "sepa" }),
                  _vm._v(" "),
                  _c("switch-input-component", {
                    attrs: {
                      disabled: _vm.readonly,
                      wrapperClasses: { "mb-2": false },
                      labelWrapperClasses: "pt-2",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$tl("labels.clients.isHotel")) +
                                  "\n                "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3617112788
                    ),
                    model: {
                      value: _vm.is_hotel_client,
                      callback: function ($$v) {
                        _vm.is_hotel_client = $$v
                      },
                      expression: "is_hotel_client",
                    },
                  }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.isFeatRoomNumbersEnable || _vm.is_hotel_client
              ? [
                  !_vm.isHotelModeEnabled
                    ? _c("div", { staticClass: "sepa" })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-5 pt-2" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.reservations.roomNumberShort"
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.room_number,
                            expression: "room_number",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.room_number },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.room_number = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.room_number !== null &&
                      _vm.room_number.length > 0 &&
                      _vm.roomNumbersForAutocomplete.length > 0 &&
                      !_vm.isRoomNumberValid
                        ? _c(
                            "div",
                            { staticClass: "room-dropdown" },
                            _vm._l(
                              _vm.roomNumbersForAutocomplete,
                              function (room) {
                                return _c(
                                  "span",
                                  {
                                    key: room.id,
                                    staticClass: "d-block pointer",
                                    class: { "cursor-disabled": _vm.readonly },
                                    on: {
                                      click: function ($event) {
                                        _vm.readonly
                                          ? false
                                          : (_vm.room_number = room.number)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(room.number) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.roomNumbers.length > 0 &&
                      !_vm.isRoomNumberValid &&
                      _vm.room_number !== null &&
                      _vm.room_number.length > 0 &&
                      _vm.roomNumbersForAutocomplete.length === 0
                        ? _c("span", { staticClass: "text-danger d-block" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$tl(
                                    "errors.booking.reservations.noHotelRoomFound"
                                  )
                                ) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }