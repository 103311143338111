var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nav-btn-3 mx-md-5 main-notification" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-sm",
        attrs: { type: "button", id: "ShowNotification" },
        on: {
          click: function ($event) {
            return _vm.$emit("open")
          },
        },
      },
      [
        _c("feather", {
          staticClass: "feather-22 feather-top-bar",
          attrs: { type: "bell" },
        }),
        _vm._v(" "),
        _vm.count > 99
          ? _c(
              "span",
              {
                staticClass: "badge badge-danger",
                staticStyle: { top: "-4px" },
              },
              [_vm._v("+99")]
            )
          : _c(
              "span",
              {
                staticClass: "badge badge-danger",
                staticStyle: { top: "-4px" },
              },
              [_vm._v(_vm._s(_vm.count > 0 ? _vm.count : ""))]
            ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "rootElement",
        staticClass: "notifications-div pb-5",
        class: {
          "notifications-div-close": !_vm.showNotifications,
          "notifications-div-open": _vm.showNotifications,
        },
        attrs: { tabindex: "0" },
        on: { close: _vm.closeFromNotif, blur: _vm.close },
      },
      [
        _c("div", { staticClass: "mb-3" }, [
          _c(
            "button",
            {
              staticClass: "main-notifications-close",
              on: { click: _vm.close },
            },
            [_c("feather", { attrs: { type: "x" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-right",
              staticStyle: { "margin-top": "-24px", "margin-right": "10px" },
            },
            [
              _c("strong", [
                _vm._v(_vm._s(_vm.$tl("labels.notifications.title"))),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "scrollableDiv",
            staticClass: "w-100 h-100 pb-5",
            attrs: { id: "show-notifications-container-scroll" },
          },
          _vm._l(_vm.notifications, function (notification, index) {
            return _c("notification-card", {
              key: index,
              attrs: { notification: notification, index: index },
              on: { read: _vm.markAsRead, close: _vm.close },
            })
          }),
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "notification-delete text-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-danger btn-circle",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.markAllAsRead($event)
                },
                mousedown: function ($event) {
                  return $event.preventDefault()
                },
              },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$tl("labels.notifications.deleteAll")) +
                  "\n            "
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }