<template>
    <div class="pb-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                    <h5 class="title main">Ma carte</h5>
                    <router-link :to="{ name: 'subscription' }" class="btn btn-sm btn-success btn-circle">
                        Retour à mon abonnement
                    </router-link>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-12">
                <div class="border-light b-radius-20 px-4 py-2">
                    <CardList />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardList from "../components/stripe/Customer/PaymentMethods/CardList";

export default {
    data() {
        return {};
    },
    methods: {},
    components: {
        CardList,
    },
};
</script>
