<template>
    <div>
        <loader-component v-if="isLoading" />
        <div v-else class="row">
            <div v-if="subscriptions.length === 0" class="col-12">
                <span>Ce code promo n'a pas encore été utilisé</span>
            </div>
            <div v-else class="col-12">
                <ns-table table-class="table table-striped w-100" :data-source="subscriptions" :toolbar-settings="toolbarSettings">
                    <template #head>
                        <ns-th field="owner.lastname" allow-sorting>Utilisateur</ns-th>
                        <ns-th field="owner.email" allow-sorting>Email</ns-th>
                        <ns-th field="created_at" allow-sorting>Date d'utilisation</ns-th>
                    </template>
                    <template #body="{ data }">
                        <td>{{ getUserFullName(data.owner) }}</td>
                        <td>{{ getUserEmail(data.owner) }}</td>
                        <td>{{ displayDate(data.created_at, DATE_FULL) }}</td>
                    </template>
                </ns-table>
            </div>
        </div>
    </div>
</template>
<script>
import LoaderComponent from "../../../LoaderComponent.vue";
import NsTable from "../../../Datatable/NsTable.vue";
import NsTh from "../../../Datatable/NsTh.vue";

export default {
    props: {
        promoCode: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        LoaderComponent,
        NsTable,
        NsTh,
    },
    data() {
        return {
            isLoading: false,
            subscriptions: [],
        };
    },
    created() {
        this.getUsers();
    },
    methods: {
        getUserFullName(user) {
            if (user === null) {
                return "";
            }

            return `${user.firstname || ""} ${user.lastname || ""}`;
        },
        getUserEmail(user) {
            if (user === null) {
                return "";
            }

            return user.email;
        },
        getUsers() {
            this.isLoading = true;
            this.httpGet(`/api/admin/promo_codes/${this.promoCode.id}/used-users`)
                .then((response) => {
                    if (response !== false) {
                        this.subscriptions = response.data;
                    }
                })
                .finally(() => (this.isLoading = false));
        },
    },
    computed: {
        toolbarSettings() {
            return {
                items: [
                    {
                        type: "ExcelExport",
                        settings: {
                            fromTable: true,
                            autoWidth: true,
                            fileName: `export_utilisations_code_promo_${this.promoCode.code}.xlsx`,
                        },
                    },
                ],
            };
        },
    },
};
</script>
