<template>
    <div class="my-4">
        <h5>Durée &amp; Validité</h5>
        <div class="radio row my-3">
            <div class="col-md-5 pt-2">
                <label>Durée de la réduction sur l'abonnement</label>
            </div>
            <div class="col-md-7 pt-2">
                <radio-button-component
                    v-for="duration in ALL_COUPON_DURATION_TYPES"
                    :key="duration.value"
                    :input-value="duration.value"
                    v-model="form.duration_type">
                    {{ getDurationLabel(duration) }}
                </radio-button-component>
            </div>
        </div>

        <input-component
            v-if="form.duration_type === COUPON_DURATION_REPEATING.value"
            input-name="duration"
            input-type="number"
            :label="`Précisez le nombre ${labelPlan}`"
            required
            :form-errors="errors.form"
            v-model="form.duration" />

        <div class="row mb-2">
            <div class="col-5 pt-2">
                <label>Date de début de validité <small>*</small></label>
            </div>
            <div class="col-7 pt-2 date-resa-cal">
                <date-picker class="d-inline-block mr-2" v-model="form.rawStartValidity" :disabled-dates="disabledDatesStart" />
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-5 pt-2">
                <label>Date de fin de validité <small>*</small></label>
            </div>
            <div class="col-7 pt-2 date-resa-cal">
                <date-picker class="d-inline-block mr-2" v-model="form.rawEndValidity" :disabled-dates="disabledDatesEnd" />
            </div>
        </div>
    </div>
</template>
<script>
import RadioButtonComponent from "../../../forms/RadioButtonComponent.vue";
import InputComponent from "../../../forms/InputComponent.vue";
import DatePicker from "../../../forms/DatePicker.vue";
import { DateTime } from "luxon";
import CouponDurationTypeEnum from "../../../../mixins/enums/CouponDurationTypeEnum.js";
import StripePlanTypesEnum from "../../../../mixins/enums/StripePlanTypesEnum";

export default {
    props: {
        parentForm: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: true,
        },
    },
    components: {
        RadioButtonComponent,
        DatePicker,
        InputComponent,
    },
    mixins: [CouponDurationTypeEnum, StripePlanTypesEnum],
    data() {
        return {
            form: {
                duration_type: null,
                duration: null,
                validity_starts_at: null,
                validity_expires_at: null,
                rawStartValidity: DateTime.now(),
                rawEndValidity: DateTime.now().plus({ day: 1 }),
            },
        };
    },
    computed: {
        formattedStartValidity() {
            return this.form.rawStartValidity.toISODate();
        },
        formattedEndValidity() {
            return this.form.rawEndValidity.toISODate();
        },
        disabledDatesStart() {
            const disabledDates = { to: new Date() };

            if (this.form.rawEndValidity !== null) {
                disabledDates.from = this.form.rawEndValidity.toJSDateCustom();
            }

            return disabledDates;
        },
        disabledDatesEnd() {
            const disabledDates = { to: new Date() };

            if (this.form.rawStartValidity !== null) {
                disabledDates.to = this.form.rawStartValidity.toJSDateCustom();
            }

            return disabledDates;
        },
        labelPlan() {
            return this.parentForm.plan === this.STRIPE_PLAN_TYPE_YEARLY.value ? " d'années" : " de mois";
        },
    },
    created() {
        this.form.duration_type = this.COUPON_DURATION_FOREVER.value;
    },
    watch: {
        form: {
            handler(newValue) {
                newValue.validity_starts_at = this.formattedStartValidity;
                newValue.validity_expires_at = this.formattedEndValidity;
                this.$emit("update-form", newValue);
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        getDurationLabel(duration) {
            if (duration.value === this.COUPON_DURATION_REPEATING.value) {
                return `${duration.label} ${this.labelPlan}`;
            }
            return duration.label;
        },
    },
};
</script>
