<template>
    <div>
        <iframe
            :src="`${YOUTUBE_EMBED_BASE_URL}/${youtubeVideoId}`"
            :title="title"
            frameborder="0"
            :class="cssClass"
            :style="customStyle"
            :width="width"
            :height="height"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
        </iframe>
    </div>
</template>

<script>
export default {
    props: {
        youtubeVideoId: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: undefined,
        },
        cssClass: {
            type: String,
            default: undefined,
        },
        customStyle: {
            type: String,
            default: undefined,
        },
        width: {
            default: undefined,
        },
        height: {
            default: undefined,
        },
    },
};
</script>
