export default {
    data() {
        return {
            CUSTOM_EVENT_PRIORITY_HIGH: {
                value: "high",
                color: "#F75B61",
            },
            CUSTOM_EVENT_PRIORITY_NORMAL: {
                value: "normal",
                color: "#FFC107",
            },
            CUSTOM_EVENT_PRIORITY_LOW: {
                value: "low",
                color: "#30A4B7",
            },
        };
    },
    methods: {
        getCustomEventPriorityLabel(value) {
            const found = this.ALL_CUSTOM_EVENT_PRIORITIES.find((p) => p.value === value);

            return typeof found !== "undefined" ? this.$t(`labels.booking.customEvents.priorities.${value}`) : value;
        },
        getCustomEventPriorityColor(value) {
            const found = this.ALL_CUSTOM_EVENT_PRIORITIES.find((p) => p.value === value);

            return typeof found !== "undefined" ? found.color : value;
        },
    },
    computed: {
        ALL_CUSTOM_EVENT_PRIORITIES() {
            return [this.CUSTOM_EVENT_PRIORITY_HIGH, this.CUSTOM_EVENT_PRIORITY_NORMAL, this.CUSTOM_EVENT_PRIORITY_LOW];
        },
    },
};
