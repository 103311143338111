<template>
    <div class="pr-0 pb-5">
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div class="row m-0">
                    <div class="col-12">
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                            <h5 class="title mt-2">{{ $tl("labels.routes.booking.services.menus", restaurant_id) }}</h5>
                            <div>
                                <template v-if="has_right_to_update_menu">
                                    <button v-if="!isOrdering" @click="startOrdering()" type="button" class="btn btn-sm btn-success mr-1 btn-circle">
                                        <svg
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="css-i6dzq1 feather">
                                            <polyline points="9 5 12 2 15 5"></polyline>
                                            <polyline points="15 19 12 22 9 19"></polyline>
                                            <line x1="12" y1="2" x2="12" y2="22"></line>
                                        </svg>
                                        {{ $tl("labels.form.actions.orderMenus", restaurant_id) }}
                                    </button>
                                    <template v-else>
                                        <button
                                            :disabled="loading"
                                            @click="saveOrdering()"
                                            type="button"
                                            class="btn btn-sm btn-success mr-1 btn-circle">
                                            {{ $tl("labels.form.actions.save") }}
                                        </button>
                                        <button
                                            :disabled="loading"
                                            @click="cancelOrdering()"
                                            type="button"
                                            class="btn btn-sm btn-outline-secondary mr-1 btn-circle">
                                            {{ $tl("labels.form.actions.cancel") }}
                                        </button>
                                    </template>
                                </template>
                                <router-link
                                    :to="{ name: 'booking.restaurants.settings.menus.add', params: { restaurant_id } }"
                                    class="btn btn-sm btn-success btn-circle"
                                    :class="{ disabled: !has_right_to_create_menu }">
                                    <feather type="plus" />
                                    <span>{{ $tl("labels.booking.menus.add", restaurant_id) }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="formErrors" class="alert alert-danger">
                    <p v-for="error in formErrors" :key="error">
                        {{ error }}
                    </p>
                </div>
                <div v-if="formSuccess" class="alert alert-success">
                    {{ formSuccess }}
                </div>
                <div class="row m-0">
                    <div class="col-12">
                        <table v-if="menus.data && menus.data.length > 0" class="table table-sm table-striped border-bottom">
                            <thead class="border-bottom">
                                <tr>
                                    <th v-if="isOrdering">{{ $tl("labels.order") }}</th>
                                    <th>{{ $tl("labels.form.name") }}</th>
                                    <th>{{ $tl("labels.price") }}</th>
                                    <th class="size-desc-menu">{{ $tl("labels.booking.menus.description") }}</th>
                                    <th>{{ $tl("labels.form.actions.title") }}</th>
                                </tr>
                            </thead>
                            <draggable :disabled="!isOrdering" tag="tbody" v-model="menus.data" draggable=".menus">
                                <tr v-for="menu in menus.data" :key="menu.id" class="menus">
                                    <td v-if="isOrdering">
                                        <svg
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="css-i6dzq1 feather"
                                            style="cursor: grab; color: rgb(170, 170, 170)">
                                            <line x1="3" y1="9" x2="21" y2="9"></line>
                                            <line x1="3" y1="15" x2="21" y2="15"></line>
                                        </svg>
                                    </td>
                                    <td>{{ menu.name }}</td>
                                    <td>
                                        {{ menu.price === 0 ? $tl("labels.free") : formatCurrency(menu.price, getRestaurantCurrency(restaurant_id)) }}
                                    </td>
                                    <td>{{ menu.description }}</td>
                                    <td>
                                        <router-link
                                            :to="{
                                                name: 'booking.restaurants.settings.menus.edit',
                                                params: { restaurant_id: menu.restaurant_id, menu_id: menu.id },
                                            }"
                                            class="btn btn-sm btn-success btn-square"
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: $t('labels.form.actions.edit'),
                                            }">
                                            <feather type="edit" />
                                        </router-link>
                                        <button
                                            v-if="!menu.has_future_reservations"
                                            v-on:click="deleteMenu(menu.id)"
                                            :data-id="menu.id"
                                            type="button"
                                            class="btn btn-sm btn-danger btn-square"
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: $t('labels.form.actions.delete'),
                                            }"
                                            :disabled="!has_right_to_delete_menu">
                                            <feather type="trash-2" />
                                        </button>
                                        <button
                                            v-if="menu.has_future_reservations"
                                            class="btn btn-sm btn-secondary btn-square"
                                            type="button"
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: $t('infos.booking.menus.cantDelete'),
                                            }"
                                            disabled>
                                            <feather type="trash-2" />
                                        </button>
                                    </td>
                                </tr>
                            </draggable>
                        </table>
                        <div v-else>
                            <em>{{ $tl("labels.booking.menus.empty", restaurant_id) }}</em>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../../components/LoaderComponent.vue";
import draggable from "vuedraggable";

export default {
    data() {
        return {
            loading: false,
            error: null,
            formLoading: null,
            formSuccess: null,
            formErrors: null,
            menus: null,
            restaurant: {
                id: null,
                menus: null,
            },
            isOrdering: false,
            savedMenus: null,
        };
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_menu() {
            return this.rights.includes("booking.menu.create");
        },
        has_right_to_delete_menu() {
            return this.rights.includes("booking.menu.delete");
        },
        has_right_to_update_menu() {
            return this.rights.includes("booking.menu.update");
        },
    },
    methods: {
        fetchData() {
            this.error = this.restaurant = null;
            this.loading = true;

            axios
                .get("/api/restaurants/" + this.restaurant_id + "/menus?all=1")
                .then((response) => {
                    this.menus = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        deleteMenu(menuId) {
            if (!confirm(this.$tl("questions.booking.menus.delete", this.restaurant_id))) {
                return;
            }

            this.loading = true;

            this.httpDelete(`/api/restaurants/${this.restaurant_id}/menus/${menuId}`, { handleResolve: false }).then((response) => {
                if (response !== false) {
                    this.notifySuccess(null, this.$tl("success.booking.menus.deleted", this.restaurant_id));

                    this.fetchData();
                } else {
                    this.loading = false;
                }
            });
        },
        startOrdering() {
            this.savedMenus = _.cloneDeep(this.menus);
            this.isOrdering = true;
        },
        cancelOrdering() {
            this.products = this.savedProducts;
            this.savedProducts = null;
            this.isOrdering = false;
        },
        saveOrdering() {
            this.loading = true;

            const menus = this.menus.data.map((menu, index) => {
                return {
                    id: menu.id,
                    order: index + 1,
                };
            });

            this.httpPost(`/api/restaurants/${this.restaurant_id}/menus/order`, { menus })
                .then((response) => {
                    if (response !== false) {
                        this.isOrdering = false;
                        this.savedMenus = null;
                        this.menus = response.data;
                    }
                })
                .finally(() => (this.loading = false));
        },
    },
    created() {
        this.fetchData();
    },
    watch: {
        "$route.params.restaurant_id": function (id) {
            this.fetchData();
        },
    },
    components: {
        LoaderComponent,
        draggable,
    },
};
</script>
