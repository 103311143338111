<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ sale.id !== null ? "Modifier" : "Ajouter" }} un bon cadeau</h5>
        <div slot="body">
            <add-gift-voucher ref="addGiftVoucherComponent" :sale="sale" @saved="$emit('saved')" />
        </div>
        <div slot="footer" class="d-flex">
            <button
                type="button"
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                @click="$emit('close')">
                Annuler
            </button>
            <button
                type="button"
                class="btn btn-sm btn-success btn-circle ml-2"
                @click="$refs.addGiftVoucherComponent.save()">
                Enregistrer
            </button>
        </div>
    </modal>
</template>

<script>
import addGiftVoucher from "../../giftVouchers/addGiftVoucher.vue";

export default {
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    props: {
        sale: {
            type: Object,
            required: true,
        },
    },
    components: {
        addGiftVoucher,
    },
};
</script>
