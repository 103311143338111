var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(
        _vm._s(_vm.$tl("labels.booking.reservations.passingCustomer.add.title"))
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c("add-passing-customer", {
          ref: "addPassingCustomer",
          attrs: {
            "restaurant-id": _vm.restaurantId,
            "service-id": _vm.serviceId,
            tablesIdToPreselect: _vm.tablesIdToPreselect,
            date: _vm.date,
            defaultValues: _vm.defaultValues,
          },
          on: {
            "enable-next-button": function ($event) {
              _vm.isNextButtonEnabled = $event
            },
            "set-step": function ($event) {
              _vm.step = $event
            },
            saved: function ($event) {
              return _vm.$emit("saved")
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.close.apply(null, arguments)
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$tl("labels.form.actions.cancel", _vm.restaurantId)
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.step > 1
          ? _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary btn-sm btn-circle ml-2",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$refs.addPassingCustomer.previousStep.apply(
                      null,
                      arguments
                    )
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$tl("labels.form.actions.previous", _vm.restaurantId)
                    ) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.step < 3
          ? _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary btn-sm btn-circle ml-2",
                attrs: { disabled: !_vm.isNextButtonEnabled },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$refs.addPassingCustomer.nextStep.apply(
                      null,
                      arguments
                    )
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$tl("labels.form.actions.next", _vm.restaurantId)
                    ) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.step === 3
          ? _c(
              "button",
              {
                staticClass: "btn btn-success btn-sm btn-circle ml-2",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$refs.addPassingCustomer.save.apply(
                      null,
                      arguments
                    )
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$tl("labels.form.actions.save", _vm.restaurantId)
                    ) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }