<template>
    <div class="blur-set" ref="mainElement" tabindex="-1" @blur="eventHubEmitBlur">
        <div class="main-detail-box" tabindex="-1">
            <loader-component v-if="loading" :i18nAvailable="false" />
            <template v-else>
                <div
                    class="d-flex m-0 detail-box-header text-uppercase bg-dark justify-content-center align-items-center"
                    :class="{ 'bg-grad': isYservices }"
                    tabindex="-1">
                    <div tabindex="-1" class="align-self-center">
                        <div class="d-flex justify-content-center align-items-center" tabindex="-1">
                            <button
                                v-show="slotSelected"
                                type="button"
                                class="blur-set btn p-1 text-white align-self-center"
                                tabindex="-1"
                                @blur="eventHubEmitBlur"
                                @click="selectSlot(null, $event)">
                                <feather type="chevron-left" style="pointer-events: none" />
                            </button>
                            <button
                                v-show="showServicePaxes"
                                type="button"
                                class="blur-set btn p-1 text-white align-self-center"
                                tabindex="-1"
                                @blur="eventHubEmitBlur"
                                @click="displayServicePaxes(false)">
                                <feather type="chevron-left" style="pointer-events: none" />
                            </button>
                            <strong class="align-self-center pt-1" tabindex="-1">
                                {{ service.restaurant.name }}
                            </strong>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center flex-grow-1" tabindex="-1">
                        <div class="text-right flex-grow-1 align-self-center mr-3" tabindex="-1">
                            <span tabindex="-1">{{ dateHumanFormat }}</span
                            ><br />
                            <span tabindex="-1">{{ service.name }}</span>
                        </div>
                        <div v-show="!slotSelected && !showServicePaxes" tabindex="-1">
                            <div class="d-inline-block align-middle">
                                <input
                                    type="checkbox"
                                    class="switch align-self-center is-rounded"
                                    :class="getStatusClass(service.is_full)"
                                    :checked="service.is_full !== 1" />
                                <label :class="getStatusClass(service.is_full)" @click="updateServiceAvailability" tabindex="-1"></label>
                            </div>
                            <div class="d-inline-block">
                                <button
                                    type="button"
                                    class="blur-set btn pl-0 pt-0 pb-1 pr-1 text-white align-self-center"
                                    tabindex="-1"
                                    @blur="eventHubEmitBlur"
                                    @click="displayServicePaxes(true)">
                                    <feather type="chevron-right" :class="{ 'text-white': isYservices }" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="detail-box-content" tabindex="-1">
                    <div
                        v-show="!slotSelected && !showServicePaxes"
                        class="detail-box-pax text-center text-white"
                        :style="{ backgroundImage: paxGradient }"
                        tabindex="-1">
                        <template v-if="!isEditingNewPax">
                            <strong tabindex="-1"> {{ service.pax }}/{{ service.new_pax !== null ? service.new_pax : service.max_pax }} </strong>
                            <button
                                v-if="service.new_pax !== null"
                                class="btn"
                                type="button"
                                style="padding-left: 0"
                                @click="editNewPax(null)"
                                v-tooltip="{
                                    delay: { show: 400, hide: 0 },
                                    content: translate_('labels.useDefaultParams'),
                                }">
                                <feather class="text-white" type="x" />
                            </button>
                            <button
                                class="btn"
                                type="button"
                                @click="startEditNewPax"
                                v-tooltip="getTooltip(translate_lexicon_('labels.booking.services.editDayMaxPax', service.restaurant.id))">
                                <feather class="text-white" style="margin-bottom: 3px" type="edit" />
                            </button>
                        </template>
                        <template v-else>
                            <strong tabindex="-1">
                                <input
                                    type="number"
                                    min="1"
                                    class="form-control d-inline-block"
                                    style="border-radius: 20px !important; border: none !important; width: 80px"
                                    v-model="currentNewPax" />
                            </strong>
                            <button
                                class="btn"
                                type="button"
                                style="padding-left: 0"
                                @click="isEditingNewPax = false"
                                v-tooltip="{
                                    delay: { show: 400, hide: 0 },
                                    content: translate_('labels.form.actions.cancel'),
                                }">
                                <feather class="text-white" type="x" />
                            </button>
                            <button
                                class="btn btn-outline-secondary btn-sm btn-square"
                                type="button"
                                @click="editNewPax(currentNewPax)"
                                v-tooltip="{
                                    delay: { show: 400, hide: 0 },
                                    content: translate_('labels.form.actions.save'),
                                }">
                                <feather class="text-white" type="save" />
                            </button>
                        </template>
                    </div>
                    <div v-show="!slotSelected && !showServicePaxes" class="detail-box-slots-grid" tabindex="-1">
                        <div
                            class="w-auto text-center border-bottom-light detail-box-slot"
                            v-for="(slot, index) in service.slots"
                            :key="index"
                            style="cursor: pointer"
                            tabindex="-1"
                            @focus="$refs.mainElement.focus()"
                            @click="selectSlot(index)">
                            {{ displayDate(setHourOnDateTime(slot.hour_start), TIME_SIMPLE) }}
                            <input
                                type="checkbox"
                                class="switch is-small is-rounded"
                                :class="getFullStatusClass(slot)"
                                :checked="slot.is_full !== 1" />
                            <label class="ml-2" tabindex="-1" :class="getFullStatusClass(slot)" @click.stop="updateSlotAvailability(slot, index)">
                            </label>
                            <feather type="chevron-right" style="pointer-events: none" />
                        </div>
                    </div>
                    <div class="detail-box-slots-grid" tabindex="-1" v-show="slotSelected">
                        <span class="w-auto text-center border-bottom-light detail-box-slot" tabindex="-1">
                            <strong>{{ slotSelected ? displayDate(setHourOnDateTime(slotSelected.hour_start), TIME_SIMPLE) : "" }}</strong>
                        </span>
                        <div
                            class="w-auto text-center border-bottom-light detail-box-slot"
                            style="cursor: pointer"
                            v-for="pax in allPaxes"
                            :key="pax"
                            tabindex="-1">
                            {{ capitalize(translate_("labels.pax")) }} {{ pax }}
                            <input
                                type="checkbox"
                                class="switch is-small is-rounded"
                                :class="{
                                    closed: slotSelected && slotSelected.paxes_full.includes(pax),
                                    open: slotSelected && !slotSelected.paxes_full.includes(pax),
                                }"
                                :checked="slotSelected && !slotSelected.paxes_full.includes(pax)" />
                            <label
                                class="ml-2"
                                :class="{
                                    closed: slotSelected && slotSelected.paxes_full.includes(pax),
                                    open: slotSelected && !slotSelected.paxes_full.includes(pax),
                                }"
                                @click.stop="updatePaxAvailability(pax, slotSelected, slotIndexSelected)"
                                tabindex="-1">
                            </label>
                        </div>
                    </div>
                    <div class="detail-box-slots-grid" tabindex="-1" v-show="showServicePaxes">
                        <div
                            class="w-auto text-center border-bottom-light detail-box-slot"
                            v-for="pax in allPaxes"
                            :key="pax"
                            style="cursor: pointer"
                            tabindex="-1">
                            {{ capitalize(translate_("labels.pax")) }} {{ pax }}
                            <input
                                type="checkbox"
                                class="switch is-small is-rounded"
                                :class="getStatusClass(service.paxes_full[pax])"
                                :checked="service.paxes_full[pax] === -1" />
                            <label
                                class="ml-2"
                                tabindex="-1"
                                :class="getStatusClass(service.paxes_full[pax])"
                                @click.stop="updateServicePaxAvailability(pax)">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="detail-box-footer d-flex" tabindex="-1">
                    <button
                        class="detail-box-add detail-box-btn btn"
                        tabindex="-1"
                        @click="
                            eventHub.$emit('add-resa', {
                                restaurant_id: service.restaurant.id,
                                service_id: service.id,
                                slot_id: null,
                                full_date: date,
                            })
                        ">
                        <feather type="plus" style="margin-bottom: 2px; pointer-events: none" tabindex="-1" />
                        {{ translate_("labels.reservation") }}
                    </button>
                    <button
                        class="detail-box-list detail-box-btn btn"
                        tabindex="-1"
                        @click="
                            eventHub.$emit('list-resa', {
                                restaurant_id: service.restaurant.id,
                                service_id: service.id,
                                full_date: date,
                            })
                        ">
                        <feather type="list" style="margin-bottom: 2px; pointer-events: none" tabindex="-1" />
                        {{ translate_("labels.reservationPlural") }}
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";

export default {
    data() {
        return {
            loading: false,
            service: null,
            serviceId: null,
            date: null,
            eventHub: null,
            widget: null,
            slotIndexSelected: null,
            blurEnabled: true,
            showServicePaxes: false,
            isEditingNewPax: false,
            currentNewPax: null,
            translate: null,
            translate_lexicon: null,
        };
    },
    computed: {
        dateHumanFormat() {
            if (this.date) {
                return this.displayDate(this.date, this.DATE_SHORT_DAY_NO_YEAR, false);
            }
            return "";
        },
        minPax() {
            if (this.widget.min_pers) {
                return this.widget.min_pers;
            }

            return 0;
        },
        maxPax() {
            if (this.widget.max_pers) {
                if (this.widget.enable_children && this.widget.max_children && this.widget.enable_children === 1) {
                    return this.widget.max_pers + this.widget.max_children;
                }

                return this.widget.max_pers;
            }

            return 0;
        },
        allPaxes() {
            return this.$_.range(this.minPax, this.maxPax + 1);
        },
        slotSelected() {
            if (this.slotIndexSelected !== null && this.slotIndexSelected >= 0 && this.slotIndexSelected < this.service.slots.length) {
                return this.service.slots[this.slotIndexSelected];
            }

            return null;
        },
        paxGradient() {
            let result = "linear-gradient(90deg, #ffc107 0%, #bbbbbb 0%)";

            const max_pax = this.service.new_pax !== null ? this.service.new_pax : this.service.max_pax;
            if (this.service.pax !== null && max_pax !== null) {
                let color = "#30A4B7";

                let percentFull = (this.service.pax / max_pax) * 100;

                if (percentFull >= 80) {
                    color = "#F75B61";
                } else if (percentFull >= 50) {
                    color = "#FFC107";
                }

                if (percentFull >= 100) {
                    result = `linear-gradient(90deg, ${color} 100%, #bbbbbb 100%)`;
                } else {
                    percentFull = Math.round(percentFull);
                    result = `linear-gradient(90deg, ${color} ${percentFull}%, #bbbbbb ${percentFull}%)`;
                }
            }

            return result;
        },
    },
    mounted() {
        this.currentNewPax = null;
        this.isEditingNewPax = false;
        if (this.service && this.date && this.widget && this.eventHub) {
            this.eventHub.$on("set-focus", () => {
                this.$refs.mainElement.focus();
            });
        }
    },
    created() {
        this.loading = true;
        this.getServiceDetailsForDate(this.serviceId, this.date).then((service) => {
            if (service) {
                this.getWidgetByServiceId(this.serviceId).then((widget) => {
                    if (widget) {
                        this.service = service;
                        this.widget = widget;
                    }
                    this.$nextTick(() => {
                        this.loading = false;
                    });
                });
            } else this.loading = false;
        });
    },
    methods: {
        translate_(key, params = {}) {
            return this.translate !== null ? this.translate(key, params) : key;
        },
        translate_lexicon_(key, restaurantId, params = {}) {
            return this.translate_lexicon !== null ? this.translate_lexicon(key, restaurantId, params) : key;
        },
        startEditNewPax() {
            this.currentNewPax = this.service.new_pax !== null ? this.service.new_pax : this.service.max_pax;
            this.isEditingNewPax = true;
            this.$refs.mainElement.focus();
        },
        editNewPax(new_pax) {
            this.isEditingNewPax = false;
            this.loading = true;
            this.blurEnabled = false;

            axios
                .post(`/api/restaurants/${this.service.restaurant.id}/services/${this.service.id}/new_pax`, {
                    date: this.date,
                    new_pax,
                })
                .then((response) => {
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.$refs.mainElement.focus();
                    this.eventHub.$emit("service-max-pax-changed", {
                        restaurantId: this.service.restaurant.id,
                        serviceId: this.service.id,
                        value: new_pax,
                        date: this.date,
                    });
                    this.eventHub.$emit("refresh", this.$el);
                    this.$nextTick(() => {
                        this.blurEnabled = true;
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        getServiceDetailsForDate(serviceId, date) {
            return axios
                .get(`/api/board/services/${serviceId}?date=${date}`)
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    } else {
                        return null;
                    }
                })
                .catch((error) => {
                    //error occured
                    return null;
                });
        },
        getWidgetByServiceId(serviceId) {
            return axios
                .get(`/api/services/${serviceId}/widget`)
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    } else {
                        return null;
                    }
                })
                .catch((error) => {
                    // error occured
                    return null;
                });
        },
        getFullStatusClass(item) {
            switch (item.is_full) {
                case 1:
                    return "closed";
                case 0:
                    return "half-closed";
                default:
                    return "open";
            }
        },
        getStatusClass(value) {
            switch (value) {
                case 1:
                    return "closed";
                case 0:
                    return "half-closed";
                default:
                    return "open";
            }
        },
        eventHubEmitBlur(e) {
            if (this.blurEnabled) {
                if (this.eventHub && !this.isDescendantOfTooltip(e.relatedTarget)) {
                    this.selectSlot(null);
                    this.displayServicePaxes(false);
                    this.eventHub.$emit("blur");
                } else if (
                    e.relatedTarget !== null &&
                    this.isDescendantOfTooltip(e.relatedTarget) &&
                    !e.relatedTarget.classList.contains("blur-set")
                ) {
                    e.relatedTarget.onblur = this.eventHubEmitBlur;
                }
            } else {
                this.$refs.mainElement.focus();
            }
        },
        isDescendantOfTooltip(child) {
            if (child == null) {
                return false;
            }

            var node = child.parentNode;

            while (node !== null) {
                if (node.classList && node.classList.contains("e-tooltip-wrap")) {
                    return true;
                }
                node = node.parentNode;
            }

            return false;
        },
        isDescendant(parent, child) {
            if (child == null) {
                return false;
            }

            if (node == child) {
                return true;
            }

            var node = child.parentNode;

            while (node != null) {
                if (node == parent) {
                    return true;
                }
                node = node.parentNode;
            }
            return false;
        },
        selectSlot(index, event = null) {
            this.blurEnabled = false;
            this.$nextTick(() => {
                this.slotIndexSelected = index;
                this.$nextTick(() => {
                    this.eventHub.$emit("refresh", this.$el);
                    this.$nextTick(() => {
                        this.blurEnabled = true;
                    });
                });
            });
        },
        updateServiceAvailability() {
            if (this.service.is_full > 0) {
                this.openService();
            } else {
                this.closeService();
            }
        },
        updateSlotAvailability(slot, index) {
            if (slot.is_full > 0) {
                this.openSlot(slot, index);
            } else {
                this.closeSlot(slot, index);
            }
        },
        updatePaxAvailability(pax, slot, index) {
            if (slot.paxes_full.includes(pax)) {
                this.openPax(pax, slot, index);
            } else {
                this.closePax(pax, slot, index);
            }
        },
        updateServicePaxAvailability(pax) {
            if (this.service.paxes_full[pax] === -1) {
                this.closeServicePax(pax);
            } else {
                this.openServicePax(pax);
            }
        },
        openService() {
            const url = `/api/restaurants/${this.service.restaurant.id}/services/${this.service.id}/full?date=${this.date}`;

            return axios
                .delete(url)
                .then((response) => {
                    this.service.is_full = -1;
                    this.service.slots.forEach((slot) => {
                        slot.is_full = -1;
                        slot.paxes_full = [];
                    });
                    for (var pax in this.service.paxes_full) {
                        this.service.paxes_full[pax] = -1;
                    }
                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        closeService() {
            const url = `/api/restaurants/${this.service.restaurant.id}/services/${this.service.id}/full?date=${this.date}`;

            return axios
                .post(url)
                .then((response) => {
                    this.service.is_full = 1;
                    this.service.slots.forEach((slot) => {
                        slot.is_full = 1;
                        slot.paxes_full = this.$_.cloneDeep(this.allPaxes);
                    });
                    for (var pax in this.service.paxes_full) {
                        this.service.paxes_full[pax] = 1;
                    }
                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        openSlot(slot, index) {
            const url = `/api/restaurants/${this.service.restaurant.id}/slots/${slot.id}/full?date=${this.date}`;

            return axios
                .delete(url)
                .then((response) => {
                    this.service.slots[index].is_full = 0;
                    this.service.slots[index].paxes_full = [];

                    this.checkServiceFullStatus();
                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        closeSlot(slot, index) {
            const url = `/api/restaurants/${this.service.restaurant.id}/slots/${slot.id}/full?date=${this.date}`;

            return axios
                .post(url)
                .then((response) => {
                    this.service.slots[index].is_full = 1;
                    this.service.slots[index].paxes_full = this.$_.cloneDeep(this.allPaxes);

                    this.checkServiceFullStatus();

                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        openPax(pax, slot, index) {
            const url = `/api/restaurants/${this.service.restaurant.id}/slots/${slot.id}/full/${pax}?date=${this.date}`;

            return axios
                .delete(url)
                .then((response) => {
                    this.service.slots[index].paxes_full = this.service.slots[index].paxes_full.filter((p) => p !== pax);

                    if (this.service.slots[index].paxes_full.length === 0) {
                        this.service.slots[index].is_full = -1;
                    } else {
                        this.service.slots[index].is_full = 0;
                    }

                    this.checkServiceFullStatus();

                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        closePax(pax, slot, index) {
            const url = `/api/restaurants/${this.service.restaurant.id}/slots/${slot.id}/full/${pax}?date=${this.date}`;

            return axios
                .post(url)
                .then((response) => {
                    this.service.slots[index].paxes_full.push(pax);

                    if (this.service.slots[index].paxes_full.length === this.allPaxes.length) {
                        this.service.slots[index].is_full = 1;
                    } else {
                        this.service.slots[index].is_full = 0;
                    }

                    this.checkServiceFullStatus();

                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        openServicePax(pax) {
            const url = `/api/restaurants/${this.service.restaurant.id}/services/${this.service.id}/full/${pax}?date=${this.date}`;

            return axios
                .delete(url)
                .then((response) => {
                    this.service.paxes_full[pax] = -1;

                    for (var slot of this.service.slots) {
                        slot.paxes_full = slot.paxes_full.filter((p) => p !== pax);
                        if (slot.paxes_full.length === 0) {
                            slot.is_full = -1;
                        } else {
                            slot.is_full = 0;
                        }
                    }

                    this.checkServiceFullStatus();

                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        closeServicePax(pax) {
            const url = `/api/restaurants/${this.service.restaurant.id}/services/${this.service.id}/full/${pax}?date=${this.date}`;

            return axios
                .post(url)
                .then((response) => {
                    this.service.paxes_full[pax] = 1;

                    for (var slot of this.service.slots) {
                        if (!slot.paxes_full.includes(pax)) {
                            slot.paxes_full.push(pax);
                        }

                        if (slot.paxes_full.length === this.allPaxes.length) {
                            slot.is_full = 1;
                        } else if (slot.paxes_full.length === 0) {
                            slot.is_full = -1;
                        } else {
                            slot.is_full = 0;
                        }
                    }

                    this.checkServiceFullStatus();

                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        manageError(error) {
            let errorMessage = null;

            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            } else {
                errorMessage = "Une erreur est survenue.";
            }

            this.$notify({
                group: "notification",
                type: "error",
                title: errorMessage,
            });
        },
        checkServiceFullStatus() {
            let atLeastASlotOpen = false;
            let atLeastASlotClosed = false;
            var slotPaxesClosed = {};

            this.service.slots.forEach((slot) => {
                if (slot.paxes_full.length === this.allPaxes.length) {
                    slot.is_full = 1;
                    atLeastASlotClosed = true;
                } else if (slot.paxes_full.length > 0) {
                    slot.is_full = 0;
                    atLeastASlotClosed = true;
                    atLeastASlotOpen = true;
                } else {
                    slot.is_full = -1;
                    atLeastASlotOpen = true;
                }

                slot.paxes_full.forEach((pax) => {
                    if (slotPaxesClosed[pax]) {
                        slotPaxesClosed[pax]++;
                    } else if (!slotPaxesClosed[pax]) {
                        slotPaxesClosed[pax] = 1;
                    }
                });
            });

            for (var pax_full in this.service.paxes_full) {
                if (!slotPaxesClosed[pax_full]) {
                    this.service.paxes_full[pax_full] = -1;
                } else if (slotPaxesClosed[pax_full] === this.service.slots.length) {
                    this.service.paxes_full[pax_full] = 1;
                } else if (slotPaxesClosed[pax_full]) {
                    this.service.paxes_full[pax_full] = 0;
                }
            }

            if (atLeastASlotClosed && !atLeastASlotOpen) {
                this.service.is_full = 1;
            } else if (atLeastASlotClosed && atLeastASlotOpen) {
                this.service.is_full = 0;
            } else {
                this.service.is_full = -1;
            }
        },
        displayServicePaxes(value) {
            this.blurEnabled = false;
            this.$nextTick(() => {
                this.showServicePaxes = value;
                this.$nextTick(() => {
                    this.eventHub.$emit("refresh", this.$el);
                    this.$nextTick(() => {
                        this.blurEnabled = true;
                    });
                });
            });
        },
    },
    watch: {
        "service.is_full": function (newValue, oldValue) {
            this.eventHub.$emit("service-full-changed", {
                restaurantId: this.service.restaurant.id,
                serviceId: this.service.id,
                value: newValue,
                date: this.date,
            });
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>
