<template>
    <div @click="$emit('click', $event)" v-tooltip="tooltip" class="red-bubble" style="margin: 0 3px !important"></div>
</template>

<script>
export default {
    props: {
        tooltip: {
            default: undefined,
        },
    },
};
</script>
