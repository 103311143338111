<template>
    <modal>
        <h5 slot="header">{{ $tl("labels.booking.reservations.edit.title") }}</h5>
        <div slot="body">
            <main-reservation
                ref="mainReservation"
                :restaurant_id="restaurant_id"
                :reservation_id="reservation_id"
                @enable-save-button="saveButtonEnabled = $event"
                :showSaveButton="false" />
        </div>
        <div slot="footer" class="d-flex">
            <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="$emit('close')">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button type="button" class="btn btn-sm btn-success btn-circle ml-2" @click="save" :disabled="!saveButtonEnabled">
                {{ $tl("labels.form.actions.save") }}
            </button>
        </div>
    </modal>
</template>

<script>
import mainReservation from "../../reservations/mainReservation";

export default {
    data() {
        return {
            saveButtonEnabled: true,
        };
    },
    props: {
        restaurant_id: {
            default: "",
        },
        reservation_id: {
            default: "",
        },
    },
    methods: {
        save() {
            this.$refs.mainReservation.setFormErrors(null);
            this.$refs.mainReservation.setFormLoading(true);

            const result = this.$refs.mainReservation.saveResa();

            if (result) {
                result
                    .then(() => {
                        this.$emit("reservation-edited");
                    })
                    .catch((err) => {
                        this.$refs.mainReservation.setFormLoading(false);

                        if (err.response && err.response.data && err.response.data) {
                            this.$refs.mainReservation.setFormErrors(err.response.data);
                        } else {
                            this.$refs.mainReservation.setFormErrors({
                                message: this.getErrorMsgFromErrorResponse(err),
                            });
                        }
                    });
            } else {
                this.$refs.mainReservation.setFormLoading(false);
            }
        },
        close() {
            this.$emit("close");
        },
    },
    components: {
        mainReservation,
    },
};
</script>
