<template>
    <div ref="myMemo" class="div-memo" :class="{ focus: focusMemo }">
        <div class="d-flex align-items-center justify-content-between">
            <button class="btn" @click="focusMemo = !focusMemo">
                <feather :type="focusMemo ? 'chevron-down' : 'chevron-right'" />
            </button>
            <Editable
                ref="memoTitle"
                cssClass="titleMemo"
                tag="span"
                onkeypress="return (this.innerText.length <= 40)"
                v-model="memo.title"
                @blur="saveMemo"
                :editable="!isDeleting" />
            <button class="btn btn-memo text-white" @click="deleteMemo" :disabled="isDeleting">
                <feather type="trash-2" />
            </button>
        </div>
        <Editable
            ref="memoContent"
            cssClass="contentMemo"
            tag="p"
            onkeypress="return (this.innerText.length <= 500)"
            v-model="memo.note"
            @blur="saveMemo"
            :editable="!isDeleting" />
    </div>
</template>

<script>
import { DateTime } from "luxon";
import ServiceNoteTypesEnum from "../../../mixins/enums/booking/ServiceNoteTypesEnum";
import Editable from "../../forms/Editable.vue";

export default {
    data() {
        return {
            focusMemo: false,
            isDeleting: false,
        };
    },
    mixins: [ServiceNoteTypesEnum],
    props: {
        memo: {
            type: Object,
            required: true,
        },
        restaurantId: {
            required: true,
        },
        serviceId: {
            type: Number,
            required: true,
        },
        date: {
            type: DateTime,
            required: true,
        },
    },
    methods: {
        saveMemo() {
            if (this.memo.title === "") {
                this.$refs.memoTitle.setContent((this.memo.title = this.$tl("labels.booking.services.memos.title")));
            }

            const bodyData = {
                reservation_date: this.date.toISODate(),
                title: this.memo.title,
                note: this.memo.note,
                type: this.SERVICE_NOTE_TYPE_SEATING_PLAN.value,
            };

            if (this.memo.id !== null && this.memo.id !== undefined) {
                var promise = axios.put(`/api/restaurants/${this.restaurantId}/services/memos/${this.serviceId}/${this.memo.id}`, bodyData);
            } else {
                var promise = axios.post(`/api/restaurants/${this.restaurantId}/services/memos/${this.serviceId}`, bodyData);
            }

            promise
                .then((response) => {
                    if (this.memo.id === null) {
                        this.$emit("added", response.data.id);
                    }
                })
                .catch((error) => {
                    this.notifyError(error);
                });
        },
        deleteMemo() {
            if (!confirm(this.$tl("questions.booking.seatingPlan.memos.delete"))) {
                return;
            }

            if (this.memo.id === null) {
                this.$emit("deleted");
                return;
            }

            this.isDeleting = true;

            axios
                .delete(`/api/restaurants/${this.restaurantId}/services/memos/${this.serviceId}/${this.memo.id}`)
                .then(() => {
                    this.isDeleting = false;

                    this.$emit("deleted");
                })
                .catch(() => {
                    this.isDeleting = false;

                    this.notifyError(error);
                });
        },
    },
    components: {
        Editable,
    },
};
</script>
