<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-if="loadingPublish" class="text-center">
            <h5>Votre site est en cours de déploiement. Cette opération peut durer plusieurs minutes.</h5>
        </div>
        <template v-if="!loading">
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">Configuration du domaine</h5>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12 mb-3" v-if="website_enabled">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <horizontal-stepper ref="stepper" :steps="steps" :currentStep="{ index: step - 1 }"></horizontal-stepper>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-2">
                            <div class="col-md-5">
                                <label for="chk-history-enabled"><strong>Configuration du site web</strong></label>
                            </div>
                            <div class="col-md-7 mb-3">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        :disabled="!has_right_to_update_config"
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        :checked="website_enabled" />
                                    <label v-tooltip="getTooltip('Activer / désactiver le site web')" @click="toggleWebsiteActivation"></label>
                                </div>
                            </div>
                            <div class="col-md-5" v-if="website_enabled">
                                <label for="txt-website-domain-name" class="mb-0">Nom de domaine <small class="text-danger">*</small></label>
                                <small id="help-website-domain-name" class="form-text text-warning"
                                    >Votre nom de domaine <strong>ne doit pas</strong> être précédé de 'www' !</small
                                >
                            </div>
                            <div class="col-md-7 mb-1" v-if="website_enabled">
                                <input
                                    :disabled="!has_right_to_update_config || !stepsAllowingDomainNameEdit.includes(website_settings.deploy_step)"
                                    type="text"
                                    maxlength="180"
                                    v-model="website_settings.domain_name"
                                    class="form-control"
                                    id="txt-website-domain-name"
                                    aria-describedby="help-website-domain-name"
                                    placeholder="monsite.ext" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="domain_name" />
                            </div>
                        </div>
                        <div class="row btn-website-container mt-3" v-if="website_enabled">
                            <div class="col-12 d-flex justify-content-between">
                                <button
                                    type="button"
                                    @click="previewWebsite"
                                    class="btn btn-outline-secondary btn-sm btn-circle"
                                    :disabled="!has_right_to_read_config">
                                    Pré-visualiser le site
                                </button>
                                <button
                                    type="button"
                                    @click="showConfigDnsModal = true"
                                    class="btn btn-success btn-sm btn-circle"
                                    :disabled="!has_right_to_publish_config || website_settings.deploy_step == SETTINGS_STEP_WEBSITE_SETTINGS.value">
                                    Configurer mon domaine
                                </button>
                                <button
                                    type="button"
                                    @click="publish"
                                    class="btn btn-success btn-sm btn-circle"
                                    :disabled="!has_right_to_publish_config || (step < 4 && !website_settings.already_puslished)">
                                    Publier mon site
                                </button>
                                <button
                                    type="button"
                                    @click="viewWebsite"
                                    class="btn btn-success btn-sm btn-circle"
                                    :disabled="step < 5 && !website_settings.already_puslished">
                                    Voir mon site
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="website_enabled" class="row m-0 mt-3">
                <div class="col-12 d-inline-block">
                    <button type="button" class="btn btn-success btn-circle mr-1" @click="postForm()" :disabled="!has_right_to_update_config">
                        Enregistrer
                    </button>
                    <small
                        >Pour rendre vos modifications visibles sur votre site internet, pensez à "<strong>Enregistrer et publier</strong>" en
                        cliquant sur le bouton dans la navigation.</small
                    >
                </div>
            </div>
        </template>
        <config-dns-modal
            v-if="showConfigDnsModal"
            :website_settings="website_settings"
            @close="showConfigDnsModal = false"
            @verified="showConfigDnsModal = false" />
    </div>
</template>

<script>
import HorizontalStepper from "vue-stepper";
import LoaderComponent from "../../../components/LoaderComponent";
import SettingsStepsEnum from "../../../mixins/enums/website/SettingsStepsEnum";
import ShowErrors from "../../../components/errors/ShowErrors";
import ConfigDnsModal from "../../../components/Modals/ConfigDnsModal";

export default {
    data() {
        return {
            loading: 0,
            loadingPublish: false,
            showConfigDnsModal: false,
            errors: null,
            steps: [
                {
                    icon: "build",
                    name: "first",
                    title: "Construction du site",
                    completed: false,
                },
                {
                    icon: "settings",
                    name: "second",
                    title: "Paramètrage du DNS",
                    completed: false,
                },
                {
                    icon: "dns",
                    name: "third",
                    title: "Vérification du DNS",
                    completed: false,
                },
                {
                    icon: "cloud_upload",
                    name: "fourth",
                    title: "Publication du site",
                    completed: false,
                },
                {
                    icon: "done",
                    name: "five",
                    title: "Visitez votre site !",
                    completed: false,
                },
            ],
        };
    },
    mixins: [SettingsStepsEnum],
    props: {
        website_settings: {
            type: Object,
            required: true,
        },
        website_enabled: {
            required: true,
        },
    },
    computed: {
        step() {
            if (!this.website_settings || !this.website_settings.deploy_step) return 1;
            switch (this.website_settings.deploy_step) {
                case this.SETTINGS_STEP_WEBSITE_SETTINGS.value:
                    return 1;
                case this.SETTINGS_STEP_GET_TOKEN.value:
                    return 2;
                case this.SETTINGS_STEP_VERIFY.value:
                    return 3;
                case this.SETTINGS_STEP_PUBLISH.value:
                    return 4;
                case this.SETTINGS_STEP_PUBLISHED.value:
                    return 5;
            }
            return 1;
        },
        stepsAllowingDomainNameEdit() {
            return [this.SETTINGS_STEP_WEBSITE_SETTINGS.value, this.SETTINGS_STEP_GET_TOKEN.value, this.SETTINGS_STEP_VERIFY.value];
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read_config() {
            return this.rights.includes("website.config.read");
        },
        has_right_to_update_config() {
            return this.rights.includes("website.config.update");
        },
        has_right_to_publish_config() {
            return this.rights.includes("website.config.publish");
        },
    },
    methods: {
        viewWebsite() {
            window.open(`http://${this.website_settings.prefix_identifier}${this.website_settings.domain_name}`);
        },
        previewWebsite() {
            if (
                this.has_right_to_update_config &&
                !confirm("Avant de prévisualiser votre site web, assurez-vous d'avoir enregistré toutes les images et les réglages du site.")
            )
                return;
            window.open(`/api/website/${this.restaurant_id}/website/preview`);
        },
        toggleWebsiteActivation() {
            if (!this.has_right_to_update_config) return;
            this.loading++;

            axios
                .put(`/api/website/${this.restaurant_id}/website/activation`, {
                    website_enabled: !this.website_enabled,
                })
                .then((response) => {
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.$emit("fetch-data");
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        postForm(doNotFetch = false) {
            this.loading++;
            this.errors = null;

            return this.$store
                .dispatch("websites/updateWebsiteSettings", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        ...this.website_settings,
                        show_address: this.website_settings.show_address ? false : true,
                        section: "domain",
                    },
                })
                .then((response) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    if (!doNotFetch) this.$emit("fetch-data");
                    return true;
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                    if (error.response && error.response.data.errors && Object.keys(error.response.data.errors).length > 0)
                        this.errors = error.response.data.errors;
                    return false;
                });
        },
        publish() {
            this.loading++;
            this.loadingPublish = true;

            this.$store
                .dispatch("websites/publish", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        ...this.website_settings,
                        show_address: this.website_settings.show_address ? false : true,
                    },
                })
                .then((response) => {
                    this.website_settings.deploy_step = this.SETTINGS_STEP_PUBLISHED.value;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    setInterval(() => {
                        window.location.reload();
                    }, 500);
                })
                .catch((error) => {
                    this.loading--;
                    this.loadingPublish = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
    },
    components: {
        HorizontalStepper,
        ShowErrors,
        ConfigDnsModal,
        LoaderComponent,
    },
    mounted() {
        if (this.$refs.stepper) this.$refs.stepper.activateStep(this.step - 1);
    },
    watch: {
        loading(newVal) {
            if (newVal === false || newVal === 0)
                this.$nextTick().then(() => {
                    if (this.$refs.stepper) this.$refs.stepper.activateStep(this.step - 1);
                });
        },
    },
};
</script>
