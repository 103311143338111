<template>
    <span :class="errClass" v-if="hasErrors" v-html="joinErrors"></span>
</template>

<script>
export default {
    name: "ShowErrors",
    props: {
        errors: {
            default: undefined,
        },
        errorKey: {
            type: String,
            required: true,
        },
        errClass: {
            type: String,
            default: "text-danger",
        },
    },
    methods: {
        getErrorsRec(object) {
            if (typeof object === "object") {
                return Object.values(object)
                    .map((obj) => {
                        return this.getErrorsRec(obj);
                    })
                    .flat();
            }
            return object;
        },
    },
    computed: {
        errorsKeys() {
            if (!this.errors) return [];
            let i = this.errorKey.indexOf("*");
            if (i !== -1) {
                let key = this.errorKey.substring(0, i).replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
                let regexp = new RegExp(`${key}.+`, "g");
                return Object.keys(this.errors).filter((ckey) => ckey.match(regexp));
            }
            return this.errors[this.errorKey] ? [this.errorKey] : [];
        },
        hasErrors() {
            return this.errorsKeys.length > 0;
        },
        joinErrors() {
            let errors = [];
            this.errorsKeys.forEach((key) => {
                errors = errors.concat(this.getErrorsRec(this.errors[key]));
            });
            return errors.join("<br/>");
        },
    },
};
</script>
