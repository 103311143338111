var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _vm.loading
        ? _c("loader-component")
        : _vm.error
        ? _c("div", [_vm._v("\n        " + _vm._s(_vm.error) + "\n    ")])
        : _c("div", { staticClass: "default-home" }, [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column align-items-center flex-md-row pb-2 pt-2 mt-1 mb-1",
                  },
                  [
                    _c("h5", { staticClass: "title mt-2" }, [
                      _vm._v(_vm._s(_vm.$tl("labels.routes.restaurants"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column flex-md-row align-items-center ml-md-auto",
                      },
                      [
                        _vm.has_rights_to_create_zones
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "btn btn-sm btn-success btn-circle my-1 mx-md-1",
                                attrs: {
                                  to: { name: "restaurants.manage_zones" },
                                },
                              },
                              [
                                _c("feather", { attrs: { type: "plus" } }),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$tl("labels.routes.manageAreas")
                                    ) +
                                    "\n                        "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "btn btn-sm btn-success btn-circle my-1 mx-md-1",
                            class: {
                              disabled: !_vm.has_right_to_create_restaurant,
                            },
                            attrs: { to: { name: "restaurants.add" } },
                          },
                          [
                            _c("feather", { attrs: { type: "plus" } }),
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.$tl("labels.routes.addRestaurant")) +
                                "\n                        "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row m-0" },
              _vm._l(_vm.restaurants, function (restaurant, index) {
                return _c("restaurant-card", {
                  key: index,
                  staticClass: "col-md-4 mb-3",
                  attrs: {
                    restaurant: restaurant,
                    disabled: !_vm.canAccessRestaurant(restaurant.id),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.editRestaurant($event.id)
                    },
                  },
                })
              }),
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }