var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12 p-5" },
      [
        _c("ChooseCardPaymentSystem", {
          staticClass: "border-light b-radius-20 p-4",
          attrs: {
            prefered_payment_system: _vm.restaurant.prefered_payment_system,
            restaurantId: _vm.restaurant_id,
          },
          on: {
            "set-prefered-payment-system": function ($event) {
              _vm.restaurant.prefered_payment_system = $event
            },
            done: function ($event) {
              return _vm.$emit("done")
            },
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "row m-0 mt-3" }, [
          _c("p", { staticClass: "w-100" }, [
            _vm._v(_vm._s(_vm.$tl("labels.form.optionalStep"))),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary btn-circle mr-2",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("back")
                },
              },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$tl("labels.form.actions.back")) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary btn-circle",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("done")
                },
              },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$tl("labels.form.actions.next")) +
                  "\n            "
              ),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }