export default {
    data() {
        return {
            PAYMENT_METHOD_CASH: {
                label: "Espèces",
                value: "cash",
            },
            PAYMENT_METHOD_CHECK: {
                label: "Chèque",
                value: "check",
            },
            PAYMENT_METHOD_CARD: {
                label: "Carte bancaire",
                value: "card",
            },
            PAYMENT_METHOD_MEAL_VOUCHER: {
                label: "Titre restaurant",
                value: "meal_voucher",
            },
        };
    },
    methods: {
        getPaymentMethodLabel(value) {
            const valueSplit = value.split("-");
            let labels = [];
            valueSplit.forEach((v) => {
                const found = this.ALL_PAYMENT_METHOD.find((w) => w.value == v);
                if (typeof found != "undefined") labels.push(found.label);
                else labels.push(v);
            });
            return labels.join(" - ");
        },
    },
    computed: {
        ALL_PAYMENT_METHOD() {
            return [
                this.PAYMENT_METHOD_CASH,
                this.PAYMENT_METHOD_CHECK,
                this.PAYMENT_METHOD_CARD,
                this.PAYMENT_METHOD_MEAL_VOUCHER,
            ];
        },
    },
};
