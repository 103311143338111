var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-2" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [
                _vm._v("Notes de mise à jour"),
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-sm btn-success btn-circle",
                  attrs: { to: { name: "admin.app_updates.add" } },
                },
                [
                  _c("feather", { attrs: { type: "plus" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Créer une note de mise à jour")]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent")
        : _c(
            "div",
            [
              _vm.appUpdatesList.length > 0
                ? _c("ns-table", {
                    attrs: {
                      tableClass: "table table-striped",
                      "allow-sorting": "",
                      "data-source": _vm.appUpdatesList,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "head",
                          fn: function () {
                            return [
                              _c(
                                "ns-th",
                                {
                                  attrs: {
                                    field: "update_date",
                                    width: "9",
                                    "allow-sorting": "",
                                  },
                                },
                                [_vm._v("Date de la MàJ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "ns-th",
                                {
                                  attrs: { field: "description", width: "15" },
                                },
                                [_vm._v("Description")]
                              ),
                              _vm._v(" "),
                              _c("ns-th", { attrs: { field: "modules" } }, [
                                _vm._v("Modules concernés"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "ns-th",
                                {
                                  attrs: {
                                    field: "updated_at",
                                    width: "6",
                                    "allow-sorting": "",
                                  },
                                },
                                [_vm._v("Modifié le")]
                              ),
                              _vm._v(" "),
                              _c(
                                "ns-th",
                                {
                                  attrs: {
                                    field: "published_at",
                                    width: "6",
                                    "allow-sorting": "",
                                  },
                                },
                                [_vm._v("Publié le")]
                              ),
                              _vm._v(" "),
                              _c("ns-th", { attrs: { width: "12" } }, [
                                _vm._v("Actions"),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "body",
                          fn: function ({ data: appUpdate }) {
                            return [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.displayDate(
                                      appUpdate.update_date,
                                      _vm.DATE_SHORT
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(appUpdate.description))]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  appUpdate.modules.length === 0
                                    ? _c(
                                        "span",
                                        { staticClass: "font-italic" },
                                        [_vm._v("Non renseignés")]
                                      )
                                    : _vm._l(
                                        appUpdate.modules,
                                        function (tmpModule) {
                                          return _c(
                                            "span",
                                            {
                                              key: tmpModule,
                                              staticClass:
                                                "badge badge-success mr-1",
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm.getModuleTypeLabel(
                                                      tmpModule
                                                    )
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.displayDate(
                                      appUpdate.updated_at,
                                      _vm.DATE_SHORT
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                appUpdate.published_at === null
                                  ? _c("span", { staticClass: "font-italic" }, [
                                      _vm._v("Brouillon"),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.displayDate(
                                            appUpdate.published_at,
                                            _vm.DATE_SHORT
                                          )
                                        )
                                      ),
                                    ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltip(
                                            _vm.$t("labels.form.actions.view")
                                          ),
                                          expression:
                                            "getTooltip($t('labels.form.actions.view'))",
                                        },
                                      ],
                                      staticClass:
                                        "btn btn-sm btn-square btn-outline-secondary",
                                      attrs: {
                                        to: {
                                          name: "app_updates.show",
                                          params: {
                                            app_update_id: appUpdate.id,
                                          },
                                        },
                                      },
                                    },
                                    [_c("feather", { attrs: { type: "eye" } })],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltip(
                                            _vm.$t("labels.form.actions.edit")
                                          ),
                                          expression:
                                            "getTooltip($t('labels.form.actions.edit'))",
                                        },
                                      ],
                                      staticClass:
                                        "btn btn-sm btn-square btn-success",
                                      attrs: {
                                        to: {
                                          name: "admin.app_updates.edit",
                                          params: {
                                            app_update_id: appUpdate.id,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _c("feather", {
                                        attrs: { type: "edit" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value:
                                            appUpdate.published_at === null
                                              ? _vm.getTooltip("Publier")
                                              : _vm.getTooltip(
                                                  "Cette note de mise à jour a déjà été publiée"
                                                ),
                                          expression:
                                            "\n                            appUpdate.published_at === null ? getTooltip('Publier') : getTooltip('Cette note de mise à jour a déjà été publiée')\n                        ",
                                        },
                                      ],
                                      staticClass:
                                        "btn btn-sm btn-square btn-success",
                                      attrs: {
                                        type: "button",
                                        disabled:
                                          appUpdate.published_at !== null,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.publishAppUpdate(appUpdate)
                                        },
                                      },
                                    },
                                    [
                                      _c("feather", {
                                        attrs: { type: "upload-cloud" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltip(
                                            _vm.$t("labels.form.actions.delete")
                                          ),
                                          expression:
                                            "getTooltip($t('labels.form.actions.delete'))",
                                        },
                                      ],
                                      staticClass:
                                        "btn btn-sm btn-square btn-danger",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteAppUpdate(appUpdate)
                                        },
                                      },
                                    },
                                    [
                                      _c("feather", {
                                        attrs: { type: "trash-2" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      522242415
                    ),
                  })
                : _c("p", { staticClass: "font-italic" }, [
                    _vm._v("Aucune note de mise à jour n'a encore été créée"),
                  ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }