<template>
    <div>
        <LoaderComponent v-if="nbLoading > 0" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-12">
                                    <strong>Produit</strong>
                                </div>
                            </div>
                            <div class="row mb-2 mt-3">
                                <div class="col-md-5 pt-1">
                                    <label>Choix du produit <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <select :disabled="sale.id !== null" v-model="sale.gv_product_id" class="custom-select mb-0">
                                        <option :value="null">--</option>
                                        <option
                                            v-for="available_product in available_products"
                                            :key="available_product.id"
                                            :value="available_product.id">
                                            {{ available_product.name }}
                                        </option>
                                    </select>
                                    <ShowErrors :errors="errors" errorKey="gv_product_id" />
                                </div>
                            </div>
                            <template v-if="selected_product != null && selected_product.type === 'menu'">
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Quantité <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <input :disabled="sale.id !== null" type="number" v-model="sale.menu_quantity" class="form-control" />
                                        <ShowErrors :errors="errors" errorKey="menu_quantity" />
                                    </div>
                                </div>
                                <div class="row mb-2" v-if="selected_product.products_options.data.length > 0">
                                    <div class="col-md-5 pt-1">
                                        <label>Options de {{ isYservices ? "la prestation" : "l'offre" }}</label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row" v-for="products_option in selected_product.products_options.data" :key="products_option.id">
                                            <div class="col-3">
                                                <select
                                                    :disabled="sale.id !== null"
                                                    @change="recompute++"
                                                    class="custom-select"
                                                    v-model="products_option.quantity">
                                                    <option
                                                        v-for="select_value in productsOptionsSelectValues"
                                                        :key="select_value"
                                                        :value="select_value">
                                                        {{ select_value }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-9 pt-2">
                                                {{ products_option.name }} -
                                                {{ products_option.include ? "Inclus" : formatCurrency(products_option.price) }}
                                            </div>
                                            <ShowErrors class="col-12" :errors="errors" :errorKey="`products_options.${products_option.id}.id`" />
                                            <ShowErrors
                                                class="col-12"
                                                :errors="errors"
                                                :errorKey="`products_options.${products_option.id}.quantity`" />
                                        </div>
                                        <ShowErrors :errors="errors" :errorKey="'products_options'" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Prix</label>
                                    </div>
                                    <div class="col-md-7 pt-1">{{ formatCurrency(total_menu_price) }}</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5 pt-1">
                                        <label>Date d'expiration<small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <datepicker
                                            format="dd/MM/yyyy"
                                            class="date-resa-cal"
                                            :disabled="sale.id === null"
                                            :disabled-dates="disabledDates"
                                            calendar-class="positition-relative mt-2"
                                            input-class="w-auto"
                                            :monday-first="true"
                                            :language="fr"
                                            v-model="expires_on"></datepicker>
                                        <ShowErrors :errors="errors" errorKey="expires_on" />
                                    </div>
                                </div>
                            </template>
                            <template v-if="selected_product != null && selected_product.type === 'amount'">
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Montant <small>*</small></label>
                                    </div>
                                    <div class="col-md-7" v-if="selected_product.allow_custom_amounts">
                                        <input
                                            :disabled="sale.id !== null"
                                            type="number"
                                            class="form-control"
                                            v-model="amount_custom"
                                            :min="selected_product.min_amount"
                                            :max="selected_product.max_amount" />
                                        <ShowErrors :errors="errors" errorKey="amount" />
                                    </div>
                                    <div class="col-md-7" v-else>
                                        <select :disabled="sale.id !== null" class="custom-select mb-0" v-model="amount_select">
                                            <option :value="null">--</option>
                                            <option v-for="amount in selected_product.predefined_amounts" :key="amount" :value="amount">
                                                {{ amount }}€
                                            </option>
                                        </select>
                                        <ShowErrors :errors="errors" errorKey="amount" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5 pt-1">
                                        <label>Date d'expiration<small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <datepicker
                                            format="dd/MM/yyyy"
                                            class="date-resa-cal"
                                            :disabled="sale.id === null"
                                            :disabled-dates="disabledDates"
                                            calendar-class="positition-relative mt-2"
                                            input-class="w-auto"
                                            :monday-first="true"
                                            :language="fr"
                                            v-model="expires_on"></datepicker>
                                        <ShowErrors :errors="errors" errorKey="expires_on" />
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-12">
                                    <strong>Paiement</strong>
                                </div>
                            </div>
                            <div class="row mb-2 mt-3">
                                <div class="col-md-5 pt-1">
                                    <label>Type de paiement <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="d-inline-block align-middle lead-switch switch-choice">
                                        <input
                                            :disabled="sale.id !== null"
                                            type="checkbox"
                                            class="switch align-self-center is-rounded"
                                            id="chk-send_payment_ask"
                                            v-model="sale.send_payment_ask" />
                                        Manuel <label for="chk-send_payment_ask"></label>Envoyer une demande de paiement
                                    </div>
                                    <ShowErrors :errors="errors" errorKey="send_payment_ask" />
                                </div>
                            </div>
                            <template v-if="!sale.send_payment_ask">
                                <div class="row mb-2 mt-3">
                                    <div class="col-md-5 pt-1">
                                        <label>Moyen de paiement <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <select :disabled="sale.id !== null" v-model="sale.payment_type" class="custom-select mb-0">
                                            <option :value="null">--</option>
                                            <option
                                                v-for="available_payment_type in available_payment_types"
                                                :key="available_payment_type"
                                                :value="available_payment_type">
                                                {{ getPaymentTypeLabel(available_payment_type) }}
                                            </option>
                                        </select>
                                        <ShowErrors :errors="errors" errorKey="payment_type" />
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-12">
                                    <strong>Expédition</strong>
                                </div>
                            </div>
                            <div class="row mb-2 mt-3">
                                <div class="col-md-5 pt-1">
                                    <label>Mode d'envoi <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="d-inline-block align-middle lead-switch switch-choice">
                                        <input
                                            :disabled="sale.id !== null || !expedition_postal_mail_available"
                                            type="checkbox"
                                            class="switch align-self-center is-rounded"
                                            id="chk-gift_expedition_type"
                                            v-model="gift_expedition_type" />
                                        Mail <label for="chk-gift_expedition_type"></label>Courrier
                                    </div>
                                    <ShowErrors :errors="errors" errorKey="expedition_type" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Destinataire <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="d-inline-block align-middle lead-switch switch-choice">
                                        <input
                                            :disabled="sale.id !== null"
                                            type="checkbox"
                                            class="switch align-self-center is-rounded"
                                            id="chk-has_beneficiary"
                                            v-model="sale.has_beneficiary" />
                                        Client <label for="chk-has_beneficiary"></label>Bénéficiaire
                                    </div>
                                    <ShowErrors :errors="errors" errorKey="has_beneficiary" />
                                </div>
                            </div>
                            <template v-if="sale.has_beneficiary">
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <strong>Informations du bénéficiaire</strong>
                                    </div>
                                </div>
                                <div class="row mb-2 mt-3">
                                    <div class="col-md-5 pt-1">
                                        <label>Prénom <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <input type="text" class="form-control" v-model="sale.beneficiary_firstname" />
                                        <ShowErrors :errors="errors" errorKey="beneficiary_firstname" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Nom <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <input type="text" class="form-control" v-model="sale.beneficiary_lastname" />
                                        <ShowErrors :errors="errors" errorKey="beneficiary_lastname" />
                                    </div>
                                </div>
                                <div v-if="!gift_expedition_type" class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-7">
                                        <input type="email" class="form-control" v-model="sale.beneficiary_email" />
                                        <small v-if="!gift_expedition_type" class="d-block text-warning"
                                            >Si vous ne renseignez pas le mail du bénéficiaire, le bon cadeau ne sera pas transmis
                                            automatiquement.</small
                                        >
                                        <ShowErrors :errors="errors" errorKey="beneficiary_email" />
                                    </div>
                                </div>
                            </template>
                            <template v-if="gift_expedition_type">
                                <div class="row my-2">
                                    <div class="col-12">
                                        <strong>Adresse d'expédition</strong>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Prénom <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <input type="text" class="form-control" v-model="sale.delivery_firstname" />
                                        <ShowErrors :errors="errors" errorKey="delivery_firstname" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Nom <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <input type="text" class="form-control" v-model="sale.delivery_lastname" />
                                        <ShowErrors :errors="errors" errorKey="delivery_lastname" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Adresse <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <input type="email" class="form-control" v-model="sale.delivery_address" />
                                        <ShowErrors :errors="errors" errorKey="delivery_address" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Code postal <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <input type="email" class="form-control" v-model="sale.delivery_postal_code" />
                                        <ShowErrors :errors="errors" errorKey="delivery_postal_code" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Ville <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <input type="email" class="form-control" v-model="sale.delivery_city" />
                                        <ShowErrors :errors="errors" errorKey="delivery_city" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Pays <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <input
                                            :disabled="sale.id !== null"
                                            value="France"
                                            type="email"
                                            class="form-control"
                                            v-model="sale.delivery_country" />
                                        <ShowErrors :errors="errors" errorKey="delivery_country" />
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-12">
                                    <strong>Client</strong>
                                </div>
                            </div>
                            <div class="row mt-3 mb-2 radio">
                                <div class="col-md-4 d-none d-md-block pt-2">
                                    <label>Civilité <small v-if="mandatoryFields.civility">*</small></label>
                                </div>
                                <div class="col-md-8 pt-2">
                                    <label class="container-box">
                                        <input
                                            type="radio"
                                            name="civility"
                                            value="monsieur"
                                            tabindex="5"
                                            v-model="client.civility"
                                            :disabled="client.is_deleted" />
                                        <span class="checkmark"></span> Monsieur
                                    </label>
                                    <!-- <div class="none-desk" style="height: 8px"></div> -->
                                    <label class="container-box">
                                        <input
                                            type="radio"
                                            name="civility"
                                            value="madame"
                                            tabindex="6"
                                            v-model="client.civility"
                                            :disabled="client.is_deleted" />
                                        <span class="checkmark"></span> Madame
                                    </label>
                                    <ShowErrors class="d-block" :errors="errors" errorKey="client.civility" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-4 d-none d-md-block pt-2">
                                    <label>Prénom <small v-if="mandatoryFields.firstname">*</small></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="dropdown add-resa-search-client">
                                        <input
                                            type="text"
                                            :disabled="client.is_deleted"
                                            placeholder="Prénom"
                                            name="firstname"
                                            v-model="client.firstname"
                                            autocomplete="off"
                                            class="form-control"
                                            tabindex="7"
                                            @input="searchClientByMultiField"
                                            @focus="client.firstnameHasFocus = true"
                                            @blur="client.firstnameHasFocus = false" />
                                        <div
                                            @mouseover="client.search.firstnameHasFocus = true"
                                            @mouseout="client.search.firstnameHasFocus = false"
                                            class="dropdown-menu prename-field shadow show p-0"
                                            v-if="
                                                (client.firstnameHasFocus || client.search.firstnameHasFocus) &&
                                                client.search.results &&
                                                client.search.results.length > 0
                                            ">
                                            <ul class="list-group">
                                                <li class="list-group-item p-0" v-for="result in client.search.results">
                                                    <div class="pointer p-3" @click="selectClient(result)">
                                                        <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                                        <span>{{ result.tel }}</span>
                                                        <span class="email-search">
                                                            <span v-if="result.company"> {{ result.company }} | </span>
                                                            {{ result.email }}
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <ShowErrors :errors="errors" errorKey="client.firstname" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-4 d-none d-md-block pt-2">
                                    <label>Nom <small v-if="mandatoryFields.lastname">*</small></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="dropdown add-resa-search-client">
                                        <input
                                            type="text"
                                            :disabled="client.is_deleted"
                                            placeholder="Nom"
                                            name="lastname"
                                            v-model="client.lastname"
                                            autocomplete="off"
                                            class="form-control"
                                            tabindex="8"
                                            @input="searchClientByMultiField"
                                            @focus="client.lastnameHasFocus = true"
                                            @blur="client.lastnameHasFocus = false" />
                                        <div
                                            @mouseover="client.search.lastnameHasFocus = true"
                                            @mouseout="client.search.lastnameHasFocus = false"
                                            class="dropdown-menu name-field shadow show p-0"
                                            v-if="
                                                (client.lastnameHasFocus || client.search.lastnameHasFocus) &&
                                                client.search.results &&
                                                client.search.results.length > 0
                                            ">
                                            <ul class="list-group">
                                                <li class="list-group-item p-0" v-for="result in client.search.results">
                                                    <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                                        <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                                        <span>{{ result.tel }}</span>
                                                        <span class="email-search">
                                                            <span v-if="result.company">{{ result.company }} |</span>
                                                            {{ result.email }}
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <ShowErrors :errors="errors" errorKey="client.lastname" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-4 d-none d-md-block pt-2">
                                    <label>Société <small v-if="mandatoryFields.company">*</small></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="dropdown add-resa-search-client">
                                        <input
                                            type="text"
                                            :disabled="client.is_deleted"
                                            placeholder="Société"
                                            name="company"
                                            v-model="client.company"
                                            autocomplete="off"
                                            class="form-control"
                                            tabindex="8"
                                            @input="searchClientByMultiField"
                                            @focus="client.companyHasFocus = true"
                                            @blur="client.companyHasFocus = false" />
                                        <div
                                            @mouseover="client.search.companyHasFocus = true"
                                            @mouseout="client.search.companyHasFocus = false"
                                            class="dropdown-menu name-field shadow show p-0"
                                            v-if="
                                                (client.companyHasFocus || client.search.companyHasFocus) &&
                                                client.search.results &&
                                                client.search.results.length > 0
                                            ">
                                            <ul class="list-group">
                                                <li class="list-group-item p-0" v-for="result in client.search.results">
                                                    <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                                        <span>{{ result.firstname }} {{ result.company }}</span> |
                                                        <span>{{ result.tel }}</span>
                                                        <span class="email-search"
                                                            ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                                        >
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <ShowErrors :errors="errors" errorKey="client.company" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-4 d-none d-md-block pt-2">
                                    <label>Email <small v-if="mandatoryFields.email">*</small></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="dropdown add-resa-search-client">
                                        <input
                                            type="text"
                                            :disabled="client.is_deleted"
                                            placeholder="Email"
                                            name="email"
                                            v-model="client.email"
                                            autocomplete="off"
                                            class="form-control"
                                            tabindex="9"
                                            @focus="client.emailHasFocus = true"
                                            @input="searchClientByMultiField"
                                            @blur="client.emailHasFocus = false" />
                                        <div
                                            @mouseover="client.search.emailHasFocus = true"
                                            @mouseout="client.search.emailHasFocus = false"
                                            class="dropdown-menu email-field shadow show p-0"
                                            v-if="
                                                (client.emailHasFocus || client.search.emailHasFocus) &&
                                                client.search.results &&
                                                client.search.results.length > 0
                                            ">
                                            <ul class="list-group">
                                                <li class="list-group-item p-0" v-for="result in client.search.results">
                                                    <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                                        <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                                        <span>{{ result.tel }}</span>
                                                        <span class="email-search"
                                                            ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                                        >
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <ShowErrors :errors="errors" errorKey="client.email" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-4 d-none d-md-block pt-2">
                                    <label>Téléphone <small v-if="mandatoryFields.tel">*</small></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="dropdown add-resa-search-client">
                                        <vue-tel-input
                                            :defaultCountry="defaultTelCountry"
                                            name="telInput"
                                            :disabled="client.is_deleted"
                                            @onValidate="setPhoneAndCountry"
                                            @onInput="searchClientByMultiField"
                                            ref="vueTelInput"
                                            v-model="client.phone.raw"
                                            :enabledCountryCode="true"
                                            :inputOptions="{ tabindex: 10, autocomplete: 'off' }"
                                            class="form-control d-flex text-left"
                                            mode="international"
                                            inputClasses="m-0 border-0"
                                            placeholder="Téléphone"></vue-tel-input>
                                        <div
                                            @mouseover="client.search.phoneNumberHasFocus = true"
                                            @mouseout="client.search.phoneNumberHasFocus = false"
                                            class="dropdown-menu phone-field shadow show p-0"
                                            v-if="
                                                (client.phoneNumberHasFocus || client.search.phoneNumberHasFocus) &&
                                                client.search.results &&
                                                client.search.results.length > 0
                                            ">
                                            <ul class="list-group">
                                                <li class="list-group-item p-0" v-for="result in client.search.results">
                                                    <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                                        <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                                        <span>{{ result.tel }}</span>
                                                        <span class="email-search"
                                                            ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                                        >
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <ShowErrors :errors="errors" errorKey="client.tel" />
                                    <ShowErrors :errors="errors" errorKey="client.tel_country" />
                                    <ShowErrors :errors="errors" errorKey="client.id" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-4 d-none d-md-block pt-2">
                                    <label>{{ $tl("labels.clients.type") }} <small v-if="mandatoryFields.type">*</small></label>
                                </div>
                                <div class="col-md-8">
                                    <select-client-type :restaurant-id="restaurantId" v-model="client.type" />
                                    <ShowErrors :errors="errors" errorKey="client.type" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 pt-1">
                                    <label>{{ $tl("labels.clients.vip") }}</label>
                                </div>
                                <div class="col-8 lead-switch">
                                    <input type="checkbox" class="switch align-self-center is-rounded" v-model="client.is_vip" />
                                    <label @click="client.is_vip = !client.is_vip"></label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 pt-1">
                                    <label class="none-mobile">{{ $tl("labels.form.acceptNewsletter") }}</label>
                                    <label class="none-desk">{{ $tl("labels.form.acceptNewsletterShort") }}</label>
                                </div>
                                <div class="col-8 lead-switch">
                                    <input type="checkbox" class="switch align-self-center is-rounded" v-model="client.newsletter" />
                                    <label @click="client.newsletter = !client.newsletter"></label>
                                </div>
                            </div>
                            <div class="add-resa-search-client" v-if="client.phone.search.results !== null && client.id === null">
                                <div v-if="client.phone.search.results.length > 0">
                                    <span class="text-danger d-inline-block mt-2 mb-2">
                                        Un client existe déjà avec ce numéro de téléphone.<br />
                                        Si vous enregistrez le bon cadeau, celui-ci sera mis à jour.
                                    </span>
                                    <li class="list-group-item p-0">
                                        <a class="d-block p-3" href="javascript:" @click="selectClient(client.phone.search.results[0])">
                                            <span>
                                                {{ client.phone.search.results[0].firstname }}
                                                {{ client.phone.search.results[0].lastname }}
                                            </span>
                                            | <span>{{ client.phone.search.results[0].tel }}</span>
                                            <span class="email-search"
                                                ><span v-if="client.phone.search.results[0].company"
                                                    >{{ client.phone.search.results[0].company }} |</span
                                                >{{ client.phone.search.results[0].email }}</span
                                            >
                                        </a>
                                    </li>
                                </div>
                                <div v-else>
                                    <span class="d-inline-block mt-2 mb-2 text-muted">Le client sera créé à l'enregistrement du bon cadeau.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-12">
                                    <strong>Commentaire privé</strong>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <textarea class="form-control" v-model="sale.comment_private" rows="3"></textarea>
                                    <ShowErrors :errors="errors" errorKey="comment_private" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent";
import ShowErrors from "../errors/ShowErrors";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import PaymentTypesEnum from "../../mixins/enums/gift_vouchers/PaymentTypesEnum";
import selectClientType from "../forms/clients/selectClientType.vue";

export default {
    data() {
        return {
            nbLoading: 0,
            error: null,
            errors: null,
            gift_expedition_type: this.sale.expedition_type === "postal_mail",
            expedition_postal_mail_available: false,
            available_products: [],
            available_payment_types: [],
            selected_product: null,
            amount_custom: null,
            amount_select: null,
            expires_on: new Date(this.sale.expires_on),
            client: {
                id: null,
                civility: null,
                firstname: null,
                lastname: null,
                company: null,
                email: null,
                phone: {
                    raw: null,
                    isValid: null,
                    country: null,
                    value: null,
                    search: {
                        results: null,
                    },
                },
                type: null,
                is_vip: false,
                newsletter: false,
                firstnameHasFocus: false,
                lastnameHasFocus: false,
                companyHasFocus: false,
                emailHasFocus: false,
                phoneNumberHasFocus: false,
                search: {
                    results: null,
                    errors: null,
                    isLoading: false,
                    firstnameHasFocus: false,
                    lastnameHasFocus: false,
                    companyHasfocus: false,
                    emailHasFocus: false,
                    phoneNumberHasFocus: false,
                },
            },
            dispatch_areas_available: null,
            recompute: 0,
            mandatoryFields: {
                civility: false,
                firstname: false,
                lastname: false,
                company: false,
                email: false,
                type: false,
                tel: false,
            },
        };
    },
    mixins: [PaymentTypesEnum],
    props: {
        sale: {
            type: Object,
            required: true,
        },
    },
    computed: {
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        defaultTelCountry() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurantId).default_tel_country;
        },
        total_menu_price() {
            this.recompute;
            let price = this.selected_product.price * this.sale.menu_quantity;
            this.selected_product.products_options.data.forEach((products_option) => {
                if (products_option.quantity > 0 && !products_option.include) price += products_option.quantity * products_option.price;
            });
            return price;
        },
        productsOptionsSelectValues() {
            let i = 0;
            let values = [];

            if (this.selected_product.type === "menu") while (i <= this.selected_product.nb_pers * this.sale.menu_quantity) values.push(i++);
            return values;
        },
        builtData() {
            let data = {};
            Object.assign(data, this.sale);
            data.expedition_type = this.gift_expedition_type ? "postal_mail" : "email";
            if (this.selected_product !== null && this.selected_product.type === "amount")
                data.amount = this.selected_product.allow_custom_amounts ? this.amount_custom : this.amount_select;
            if (data.amount !== null) data.amount = parseFloat(data.amount); // NOT in cent
            data.expires_on = moment(this.expires_on).format("Y-MM-DD");
            // Client
            data.client = {
                id: this.client.id,
                firstname: this.client.firstname,
                lastname: this.client.lastname,
                civility: this.client.civility,
                company: this.client.company,
                tel: this.client.phone.value ? this.client.phone.value.international : null,
                tel_country: this.client.phone.country,
                email: this.client.email,
                type: this.client.type,
                is_vip: this.client.is_vip,
                newsletter: this.client.newsletter,
            };
            data.products_options = {};
            if (this.selected_product && this.selected_product.type === "menu") {
                this.selected_product.products_options.data.forEach((products_option) => {
                    if (products_option.quantity > 0) data.products_options[products_option.id] = products_option;
                });
            }
            return data;
        },
        disabledDates() {
            if (!this.selected_product || this.sale.id === null) return {};
            return {
                to: new Date(moment(this.sale.expires_on).format("Y-MM-DD")),
            };
        },
        fr() {
            return fr;
        },
    },
    methods: {
        fetchFields() {
            this.nbLoading++;
            this.httpGet(`/api/restaurants/${this.restaurantId}/reservations/input-params/fields?module=gift-vouchers`, {
                handleReject: false,
            })
                .then((response) => {
                    if (response !== false) {
                        response.data.forEach((field) => {
                            this.mandatoryFields[field.field_name] = field.pivot.is_mandatory;
                        });
                    }
                })
                .finally(() => this.nbLoading--);
        },
        getProductById(id) {
            for (let i = 0; i < this.available_products.length; i++) {
                if (this.available_products[i].id === id) {
                    return this.available_products[i];
                }
            }

            return null;
        },
        selectClient: function (client) {
            this.$set(
                this,
                "client",
                Object.assign(this.client, {
                    id: client.id,
                    civility: client.civility,
                    firstname: client.firstname,
                    lastname: client.lastname,
                    company: client.company,
                    email: client.email,
                    phone: {
                        raw: client.tel,
                        isValid: null,
                        country: client.tel_country,
                        value: {
                            international: client.tel,
                        },
                        search: {
                            results: null,
                        },
                    },
                    type: client.type,
                    is_vip: client.is_vip,
                    newsletter: client.newsletter,
                    is_deleted: client.is_deleted,
                })
            );
            this.client.search.companyHasFocus = false;
            this.client.search.firstnameHasFocus = false;
            this.client.search.lastnameHasFocus = false;
            this.client.search.emailHasFocus = false;
            this.client.search.phoneNumberHasFocus = false;
        },
        onVueTelInputFocus() {
            this.client.phoneNumberHasFocus = true;
        },
        onVueTelInputBlur() {
            this.client.phoneNumberHasFocus = false;
        },
        setPhoneAndCountry(phoneObject) {
            if (phoneObject.number && phoneObject.isValid !== undefined && phoneObject.country) {
                this.client.phone.isValid = phoneObject.isValid;

                if (this.client.phone.isValid) {
                    this.client.phone.value = phoneObject.number;

                    if (phoneObject.regionCode && phoneObject.regionCode !== "") {
                        this.client.phone.country = phoneObject.regionCode;
                    } else {
                        this.client.phone.country = phoneObject.country.iso2;
                    }

                    if (!this.client.search.results || this.client.search.results.length < 1) {
                        axios
                            .get(`/api/restaurants/${this.restaurantId}/clients/search/phone`, {
                                params: {
                                    phoneNumber: this.client.phone.value.international,
                                    phoneCountry: this.client.phone.country,
                                },
                            })
                            .then((response) => {
                                this.client.phone.search.results = response.data.data;
                            })
                            .catch((error) => {});
                    }
                } else {
                    this.client.phone.search.results = null;
                }
            } else {
                this.client.phone.isValid = false;
                this.client.phone.search.results = null;
            }
        },
        searchClientByMultiField() {
            this.client.search.errors = [];
            this.client.search.isLoading = true;
            var params = {};

            if (this.client.firstname && this.client.firstname !== "") {
                params.firstname = this.client.firstname;
            }

            if (this.client.lastname && this.client.lastname !== "") {
                params.lastname = this.client.lastname;
            }

            if (this.client.email && this.client.email !== "") {
                params.email = this.client.email;
            }

            if (this.client.phone.raw && this.client.phone.raw !== "") {
                params.phone_number = this.client.phone.raw;
            }

            if (this.client.company && this.client.company !== "") {
                params.company = this.client.company;
            }

            axios
                .get(`/api/restaurants/${this.restaurantId}/clients/search/multi`, {
                    params,
                })
                .then((response) => {
                    this.client.search.results = response.data.data;
                    this.client.search.isLoading = false;

                    if (!this.client.search.results || (this.client.search.results.length === 0 && this.client.phone.isValid)) {
                        axios
                            .get(`/api/restaurants/${this.restaurantId}/clients/search/phone`, {
                                params: {
                                    phoneNumber: this.client.phone.value.international,
                                    phoneCountry: this.client.phone.country,
                                },
                            })
                            .then((response) => {
                                this.client.phone.search.results = response.data.data;
                            })
                            .catch((error) => {});
                    }
                })
                .catch((err) => {});
        },
        getSaleProductOptionQuantity(id) {
            for (let i = 0; i < this.sale.gv_sale_product_options.data.length; i++) {
                const element = this.sale.gv_sale_product_options.data[i];
                if (element.gv_products_option_id === id) return element.quantity;
            }
            return 0;
        },
        fetchData() {
            this.nbLoading++;
            this.error = null;
            this.available_products = [];
            this.available_payment_types = [];

            let url = `/api/gift_vouchers/${this.restaurantId}/sales/addData?include=products_options`;

            if (this.sale.id !== null) {
                url += `&gv_sale_id=${this.sale.id}`;
            }

            axios
                .get(url)
                .then((response) => {
                    this.available_products = response.data.products;
                    this.available_payment_types = response.data.payment_types;
                    this.expedition_postal_mail_available = response.data.expedition_postal_mail_available;
                    this.dispatch_areas_available = response.data.dispatch_areas_available;
                    if (this.sale.id !== null) {
                        this.selected_product = this.getProductById(this.sale.gv_product_id);
                        if (this.selected_product !== null) {
                            if (this.selected_product.type === "menu")
                                this.selected_product.products_options.data.forEach((products_option) => {
                                    products_option.quantity = this.getSaleProductOptionQuantity(products_option.id);
                                });
                            if (this.selected_product.allow_custom_amounts) this.amount_custom = this.sale.amount;
                            else this.amount_select = this.sale.amount;
                        }
                    }
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => {
                    this.nbLoading--;
                });
        },
        save() {
            this.nbLoading++;
            this.errors = null;

            if (this.sale.id === null) {
                var promise = axios.post(`/api/gift_vouchers/${this.restaurantId}/sales`, this.builtData);
            } else {
                var promise = axios.put(`/api/gift_vouchers/${this.restaurantId}/sales/${this.sale.id}`, this.builtData);
            }
            promise
                .then((response) => {
                    this.notifySuccess(response);

                    this.$emit("saved");
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        this.errors = error.response.data.errors;
                    }

                    this.notifyError(error);
                })
                .finally(() => {
                    this.nbLoading--;
                });
        },
    },
    watch: {
        "sale.gv_product_id": function (newVal) {
            this.selected_product = this.getProductById(newVal);
            if (this.selected_product === null) return;
            for (let i = 0; i < this.selected_product.products_options.data.length; i++) this.selected_product.products_options.data[i].quantity = 0;
            let today = new Date();
            today.setDate(today.getDate() + this.selected_product.validity);
            this.expires_on = today;
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
        Datepicker,
        selectClientType,
    },
    created() {
        this.fetchData();
        this.fetchFields();
        if (this.sale.id !== null && this.sale.client !== null) {
            this.selectClient(this.sale.client);
        }
    },
    updated() {
        if (this.$refs.vueTelInput) {
            this.$refs.vueTelInput.$el.classList.remove("vue-tel-input");
            this.$refs.vueTelInput.$refs.input.addEventListener("focus", this.onVueTelInputFocus);
            this.$refs.vueTelInput.$refs.input.addEventListener("blur", this.onVueTelInputBlur);
        }
    },
};
</script>
