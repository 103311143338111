<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="alert alert-danger" v-else-if="!cc_notification">
            {{ $tl("errors.common.unknown") }}
        </div>
        <div class="row mb-3" v-else>
            <div class="col-12">
                <div class="border-light b-radius-20 p-4">
                    <div class="row">
                        <div class="col-12">
                            <strong>Notifications</strong>
                        </div>
                    </div>
                    <div class="mt-3">
                        <template v-for="(notificationData, index) in notificationsData">
                            <EditNotification
                                :key="index"
                                class="mb-4"
                                :label="notificationData.label"
                                :mail-preview-id="notificationData.previewId"
                                :sms-preview-id="notificationData.previewId"
                                :is-sms-mode-available="cc_notification.sms_activated"
                                :disabled="!has_right_to_update_config"
                                :can-be-disabled="notificationData.canBeDisabled"
                                v-bind="getPropertiesBindingForEditComponent(notificationData.key)"
                                v-on="getEventsBindingForEditComponent(notificationData.key)"
                                :formErrors="errors"
                                :error-key="notificationData.key"
                                saas="click_and_collect">
                            </EditNotification>

                            <div v-if="index < notificationsData.length - 1" :key="`${index}-sepa`" class="sepa"></div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="showSaveButton">
            <div class="col-12">
                <button type="button" class="btn btn-success btn-circle" :disabled="!has_right_to_update_config" @click="postForm">
                    {{ $tl("labels.actions.save") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";
import EditNotification from "../../notifications/EditNotification.vue";
import CollectTypesEnum from "../../../mixins/enums/click_and_collect/CollectTypesEnum.js";

export default {
    data() {
        return {
            loading: false,
            errors: null,
            cc_notification: null,
        };
    },
    mixins: [CollectTypesEnum],
    props: {
        /**
         * {
         *   label: String,
         *   previewId: ?String,
         *   key: String,
         * }
         */
        notificationsData: {
            type: Array,
            required: true,
        },
        collectType: {
            type: String,
            required: true,
        },
        showSaveButton: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_config() {
            return this.rights.includes("click_and_collect.config.update");
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        getPropertiesBindingForEditComponent(key) {
            return {
                mode: this.cc_notification[key].mode,
                "sms-content": this.cc_notification[key].sms_content,
                "sms-merge-tags": this.cc_notification.sms_merge_tags[key],
                "mail-content": this.cc_notification[key].mail_content,
                "mails-merge-tags": this.cc_notification.mails_merge_tags[key],
            };
        },
        getEventsBindingForEditComponent(key) {
            return {
                "set-mode": (e) => (this.cc_notification[key].mode = e),
                "set-sms-content": (e) => (this.cc_notification[key].sms_content = e),
                "set-mail-content": (e) => (this.cc_notification[key].mail_content = e),
            };
        },
        postForm() {
            this.errors = null;

            const promise = axios.put(`/api/click_and_collect/${this.restaurant_id}/notifications`, this.getFormattedData());

            if (!this.showSaveButton) {
                return promise
                    .then(() => {
                        return true;
                    })
                    .catch((error) => {
                        if (error.response && error.response.data) {
                            this.errors = error.response.data.errors;
                        }

                        return this.getErrorMsgFromErrorResponse(error);
                    });
            }

            this.loading = true;

            promise
                .then((response) => {
                    this.notifySuccess(response);
                })
                .catch((error) => {
                    this.notifyError(error);

                    if (error.response && error.response.data) {
                        this.errors = error.response.data.errors;
                    }
                })
                .finally(() => (this.loading = false));
        },
        fetchData() {
            this.loading = true;

            axios
                .get(`/api/click_and_collect/${this.restaurant_id}/notifications?include=mails_merge_tags,sms_merge_tags`)
                .then((response) => {
                    this.cc_notification = response.data;
                })
                .catch((error) => {
                    this.notifyError(error);

                    if (error.response && error.response.data) {
                        this.errors = error.response.data.errors;
                    }
                })
                .finally(() => (this.loading = false));
        },
        getFormattedData() {
            const data = {
                type: this.collectType,
            };

            this.notificationsData.forEach((notificationData) => {
                Object.keys(this.cc_notification[notificationData.key]).forEach((key) => {
                    data[`${notificationData.key}_${key}`] = this.cc_notification[notificationData.key][key];
                });
            });

            return data;
        },
    },
    created() {
        this.fetchData();
    },
    components: {
        LoaderComponent,
        EditNotification,
    },
};
</script>
