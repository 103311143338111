export default {
    data() {
        return {
            ACCEPTED_CGV_TYPE_DEFAULT: {
                value: "default",
                label: "Conditions générales d'utilisation<br/>Politique de confidentialité",
            },
            ACCEPTED_CGV_TYPE_MODULES: {
                value: "modules",
                label: "Conditions particulières",
            },
        };
    },
    methods: {
        getAcceptedCgvTypeLabel(value) {
            const found = this.ALL_ACCEPTED_CGV_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_ACCEPTED_CGV_TYPES() {
            return [this.ACCEPTED_CGV_TYPE_DEFAULT, this.ACCEPTED_CGV_TYPE_MODULES];
        },
    },
};
