var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-12 pointer",
              on: {
                click: function ($event) {
                  _vm.showIntegration = !_vm.showIntegration
                },
              },
            },
            [
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.$tl("infos.widget.googleLinkIntegration.title"))
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.showIntegration
          ? _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-5" }, [
                    _c("p", [_vm._v(_vm._s(_vm.$tl("infos.widget.subtitle")))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7" }, [
                    _c("div", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.$t("labels.stepX", { step: 1 })) +
                            _vm._s(_vm.$tl("labels.colon"))
                        ),
                      ]),
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t("infos.widget.googleLinkIntegration.step1")
                          ) +
                          "\n                                "
                      ),
                      _c("p", { staticClass: "mt-1 mb-3" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "infos.widget.googleLinkIntegration.content1"
                            )
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-3 d-flex flex-column" }, [
                      _c("p", { staticClass: "mt-1 mb-3" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.$t("labels.stepX", { step: 2 })) +
                              _vm._s(_vm.$tl("labels.colon"))
                          ),
                        ]),
                        _vm._v(
                          "\n                                    " +
                            _vm._s(
                              _vm.$t("infos.widget.googleLinkIntegration.step2")
                            ) +
                            "\n                                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "w-50 mb-3",
                        attrs: {
                          src: "/images/google_link_step2.png",
                          alt: _vm.$t(
                            "infos.widget.googleLinkIntegration.alt.step2"
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-3 d-flex flex-column" }, [
                      _c("p", { staticClass: "mt-1 mb-3" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.$t("labels.stepX", { step: 3 })) +
                              _vm._s(_vm.$tl("labels.colon"))
                          ),
                        ]),
                        _vm._v(
                          "\n                                    " +
                            _vm._s(
                              _vm.$t("infos.widget.googleLinkIntegration.step3")
                            ) +
                            "\n                                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "w-50 mb-3",
                        attrs: {
                          src: "/images/google_link_step3.png",
                          alt: _vm.$t(
                            "infos.widget.googleLinkIntegration.alt.step3"
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-3 d-flex flex-column" }, [
                      _c("p", { staticClass: "mt-1 mb-3" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.$t("labels.stepX", { step: 4 })) +
                              _vm._s(_vm.$tl("labels.colon"))
                          ),
                        ]),
                        _vm._v(
                          "\n                                    " +
                            _vm._s(
                              _vm.$t("infos.widget.googleLinkIntegration.step4")
                            ) +
                            "\n                                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "w-50 mb-2",
                        attrs: {
                          src: "/images/google_link_step4.png",
                          alt: _vm.$t(
                            "infos.widget.googleLinkIntegration.alt.step4"
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "mt-1 mb-3" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "infos.widget.googleLinkIntegration.content2"
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }