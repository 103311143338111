var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("pay-licences", {
        staticClass: "px-3",
        attrs: {
          modules: _vm.modules,
          only_option: true,
          redirect: { name: "subscription" },
          plan: _vm.plan,
        },
        on: {
          back: function ($event) {
            return _vm.$router.go(-1)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }