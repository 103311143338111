<template>
    <div class="row">
        <div class="col-12">
            <div class="border-light b-radius-20 p-4">
                <div class="row">
                    <div class="col-12">
                        <strong>{{ $tl("labels.booking.services.footprintAndPayment.title") }}</strong>
                    </div>
                </div>
                <div v-if="!hasPaymentSystem" class="pt-2">
                    <span class="text-danger">{{ $tl("infos.common.needStripeAccount") }}</span>
                </div>
                <div v-else class="menu-option mt-3">
                    <overlay-layout :display-overlay="!hasPaymentSystem">
                        <template v-slot:content>
                            <div class="row border-bottom mb-2">
                                <div class="col-12">
                                    <div class="row mb-2">
                                        <div class="col-5">
                                            <label>
                                                {{ $tl("labels.booking.services.footprint.enable") }}
                                                <feather
                                                    v-tooltip.right="
                                                        getTooltipNoticeLexicon(
                                                            'notices.booking.servicesConfig.bankFootprint',
                                                            restaurant_id,
                                                            ['click'],
                                                            true,
                                                            'tooltip-custom-width'
                                                        )
                                                    "
                                                    type="info"
                                                    class="ml-1 pointer tooltip-infos" />
                                            </label>
                                        </div>
                                        <div class="col-7">
                                            <label class="container-box">
                                                <input type="checkbox" v-model="service.payment.bankImprint.enabled" :value="true" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <template v-if="service.payment.bankImprint.enabled">
                                        <div class="row mb-2">
                                            <div class="col-5 pt-2">
                                                <label>{{ capitalize($tl("labels.from")) }}</label>
                                            </div>
                                            <div class="col-7">
                                                <div class="row">
                                                    <div class="col-5">
                                                        <input
                                                            type="text"
                                                            v-model="service.payment.bankImprint.enabledAbovePax"
                                                            v-bind:disabled="!service.payment.bankImprint.enabled"
                                                            class="form-control"
                                                            v-bind:class="{
                                                                disabled: !service.payment.bankImprint.enabled,
                                                            }" />
                                                    </div>
                                                    <div class="col-7 mt-2">
                                                        {{ $tl("labels.persons") }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-5">
                                                <label>
                                                    {{ $tl("labels.booking.services.footprint.amountToCapture", restaurant_id) }}
                                                </label>
                                            </div>
                                            <div class="col-7">
                                                <div class="row">
                                                    <div class="col-5">
                                                        <input
                                                            type="text"
                                                            v-model="service.payment.bankImprint.amount"
                                                            v-bind:disabled="!service.payment.bankImprint.enabled"
                                                            class="form-control"
                                                            v-bind:class="{
                                                                disabled: !service.payment.bankImprint.enabled,
                                                            }" />
                                                    </div>
                                                    <div class="col-7 mt-2">
                                                        {{ $tl("labels.euros") }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <div
                                    v-if="
                                        (service.menus.value && !$_.isEmpty(service.menus.value)) ||
                                        (service.generalOptions.value && !$_.isEmpty(service.generalOptions.value))
                                    "
                                    class="sepa"></div>
                                <div class="col-12 mb-2 mt-2">
                                    <template
                                        v-if="
                                            (service.menus.value && !$_.isEmpty(service.menus.value)) ||
                                            (service.generalOptions.value && !$_.isEmpty(service.generalOptions.value))
                                        ">
                                        <div class="row mb-2">
                                            <div class="col-5">
                                                <label>
                                                    {{ $tl("labels.booking.services.payment.enable") }}
                                                    <feather
                                                        v-tooltip="
                                                            getTooltipNoticeLexicon('notices.booking.servicesConfig.prepayment', restaurant.id)
                                                        "
                                                        type="info"
                                                        class="ml-1 pointer tooltip-infos" />
                                                </label>
                                            </div>
                                            <div class="col-7">
                                                <label class="container-box" style="width: 100%">
                                                    <input
                                                        type="checkbox"
                                                        name="prepayment_enabled"
                                                        v-model="service.payment.prepayment.enabled"
                                                        :value="true" />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <template v-if="service.payment.prepayment.enabled">
                                                <div class="col-5 pt-2">
                                                    <label>{{ $tl("labels.booking.services.payment.is") }} </label>
                                                </div>
                                                <div class="col-6 d-table w-100 h-100 pt-2">
                                                    <div class="d-table-cell align-middle">
                                                        <div class="slot_btn_select radio">
                                                            <label class="container-box">
                                                                <input
                                                                    v-bind:disabled="!service.payment.prepayment.enabled"
                                                                    v-bind:class="{
                                                                        disabled: !service.payment.prepayment.enabled,
                                                                    }"
                                                                    type="radio"
                                                                    v-model="service.payment.prepayment.mandatory"
                                                                    :value="true" />
                                                                <span class="checkmark"></span>
                                                                {{ $tl("labels.mandatory") }}
                                                            </label>
                                                            <label class="container-box">
                                                                <input
                                                                    v-bind:disabled="!service.payment.prepayment.enabled"
                                                                    v-bind:class="{
                                                                        disabled: !service.payment.prepayment.enabled,
                                                                    }"
                                                                    type="radio"
                                                                    v-model="service.payment.prepayment.mandatory"
                                                                    :value="false" />
                                                                <span class="checkmark"></span>
                                                                {{ $tl("labels.optional") }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <template v-if="service.payment.prepayment.enabled">
                                            <div v-if="service.payment.prepayment.mandatory !== null" class="row mb-2">
                                                <div class="col-5">
                                                    <label>{{ $tl("labels.booking.services.payment.amountToCapture") }}</label>
                                                </div>
                                                <div class="col-7">
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <input
                                                                type="number"
                                                                name="prepayment_percent"
                                                                v-model="service.payment.prepayment.percentage"
                                                                v-bind:disabled="!service.payment.prepayment.enabled"
                                                                class="form-control"
                                                                v-bind:class="{
                                                                    disabled: !service.payment.prepayment.enabled,
                                                                }"
                                                                min="1"
                                                                step="1" />
                                                        </div>
                                                        <div class="col-7 mt-2">%</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="service.payment.prepayment.mandatory" class="row mb-2">
                                                <div class="col-5 pt-2">
                                                    <label>{{ capitalize($tl("labels.from")) }}</label>
                                                </div>
                                                <div class="col-7">
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <input
                                                                type="text"
                                                                v-model="service.payment.prepayment.enabledAbovePax"
                                                                v-bind:disabled="
                                                                    !service.payment.prepayment.enabled || !service.payment.prepayment.mandatory
                                                                "
                                                                class="form-control"
                                                                v-bind:class="{
                                                                    disabled:
                                                                        !service.payment.prepayment.enabled || !service.payment.prepayment.mandatory,
                                                                }" />
                                                        </div>
                                                        <div class="col-7 mt-2">
                                                            {{ $tl("labels.personss") }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </div>

                            <div class="row mt-3 radio">
                                <div class="col-12 row">
                                    <div class="col-6 pt-2">
                                        <label>
                                            {{
                                                $tl("labels.booking.services.footprint.autoCancelation", restaurant_id, {
                                                    shortDelay: optionBankShortDelay,
                                                })
                                            }}
                                            <feather
                                                v-tooltip="getTooltipNotice('notices.booking.servicesConfig.bookingCancellationWithoutFootprint')"
                                                type="info"
                                                class="ml-1 pointer tooltip-infos" />
                                        </label>
                                    </div>
                                    <div class="col-6 d-table w-100 h-100 pt-2">
                                        <div class="d-table-cell align-middle">
                                            <label class="container-box">
                                                <input type="radio" name="paymentAutoCancel" v-model="service.payment.autoCancel" :value="false" />
                                                <span class="checkmark"></span> {{ $tl("labels.manual") }}
                                            </label>

                                            <label class="container-box">
                                                <input type="radio" name="paymentAutoCancel" v-model="service.payment.autoCancel" :value="true" />
                                                <span class="checkmark"></span> {{ $tl("labels.auto") }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <send-reminder-payment v-model="service.sendReminderPayment" :cancel-auto="service.payment.autoCancel" />
                        </template>
                        <template v-slot:overlay>
                            <div class="is-white text-center d-table">
                                <h6 class="d-table-cell align-middle">{{ $tl("infos.common.needStripeAccount") }}</h6>
                            </div>
                        </template>
                    </overlay-layout>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OverlayLayout from "../../layouts/OverlayLayout.vue";
import SendReminderPayment from "../../../views/Booking/Settings/Services/Forms/SendReminderPayment.vue";

export default {
    name: "PaymentGroup",
    components: {
        OverlayLayout,
        SendReminderPayment,
    },
    props: {
        service: {
            required: true,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        restaurant() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id);
        },
        hasPaymentSystem() {
            return this.restaurant && this.restaurant.has_psp;
        },
        optionBankShortDelay() {
            return this.$store.getters["widgets/getWidget"].option_bank_short_delay || 1;
        },
    },
    methods: {
        displayError(error) {
            this.$emit("has-errors", error);
        },
    },
};
</script>
