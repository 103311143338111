<template>
    <div>
        <div class="">
            <div class="row m-0">
                <div class="col-12">
                    <div v-if="command && command.status === 'canceled'" class="bg-danger text-center text-white p-1" style="border-radius: 100px">
                        Attention cette commande a été annulée
                    </div>
                    <div v-if="command && command.status === 'collected'" class="bg-danger text-center text-white p-1" style="border-radius: 100px">
                        Attention, commande déjà scannée
                    </div>
                    <show-click-and-collect-command
                        :command_id="command_id"
                        :restaurant_id="restaurant_id"
                        @command-fetched="(command = $event), $emit('command-get', $event)" />
                </div>
            </div>
        </div>
        <div v-if="showValidateButton">
            <button
                :disabled="!command || !['to_prepare', 'ready'].includes(command.status)"
                :class="{ disabled: !command || !['to_prepare', 'ready'].includes(command.status) }"
                class="btn btn-success btn-sm btn-circle"
                @click="validate">
                Valider
            </button>
        </div>
    </div>
</template>

<script>
import { fr } from "vuejs-datepicker/dist/locale";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import ShowClickAndCollectCommand from "./Show";

moment.locale("fr");

export default {
    name: "showCommand",
    components: { ShowClickAndCollectCommand },
    data() {
        return {
            loading: false,
            error: null,
            command: null,
            fr: fr,
            _: _,
            moment: moment,
        };
    },
    props: {
        command_id: {
            default: null,
        },
        restaurant_id: {
            default: null,
        },
        showValidateButton: {
            default: true,
        },
    },
    methods: {
        formatCivility(civility) {
            switch (civility) {
                case "monsieur":
                    return "Monsieur";
                case "madame":
                    return "Madame";
                default:
                    return "";
            }
        },
        validate() {
            if (this.command) {
                this.$store
                    .dispatch("ccCommands/updateStatus", {
                        restaurant_id: this.restaurant_id,
                        command_id: this.command_id,
                        status: "collected",
                    })
                    .then((response) => {
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: "Commande modifiée",
                        });
                    })
                    .catch((error) => {
                        this.$notify({
                            group: "notification",
                            type: "warn",
                            title: "La commande n'a pas pu être modifiée.",
                        });
                    });
            }
        },
    },
};
</script>
