<template>
    <div class="row m-0">
        <div class="col-12">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-2 container-bar">
                <div class="d-flex">
                    <div class="form-inline">
                        <div class="input-group position-relative">
                            <input
                                type="text"
                                class="form-control semi-rounded-left"
                                placeholder="Nom, Numéro..."
                                v-model="inputSearch"
                                style="border-radius: 20px 0 0 20px !important" />
                            <span class="reset-search-icon" @click="resetSearch">
                                <feather type="x" />
                            </span>
                            <div class="input-group-append">
                                <button type="button" class="btn btn-sm btn-outline-secondary semi-rounded-right" @click="search">
                                    <feather type="search" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        @click.stop.prevent="showAdvancedSearch = !showAdvancedSearch"
                        class="btn btn-sm ml-1 btn-circle btn-outline-secondary"
                        :class="{
                            active: showAdvancedSearch,
                        }">
                        <feather type="filter" />
                        Filtres
                        <span v-if="countFilter > 0" class="badge badge-light">{{ countFilter }}</span>
                    </button>
                </div>
                <div v-if="isPayment">
                    <div class="btn-group">
                        <button
                            :disabled="!has_right_to_export"
                            @click="$emit('export-payments')"
                            class="btn btn-sm btn-outline-secondary btn-circle none-mobile"
                            style="border-radius: 50px 0px 0px 50px !important">
                            <feather type="upload" />
                            Exporter
                        </button>
                        <button
                            :disabled="!has_right_to_export"
                            @click="$emit('dl-payments')"
                            class="btn btn-sm btn-outline-secondary btn-circle none-mobile"
                            style="border-radius: 0 50px 50px 0 !important">
                            <feather type="upload" />
                            Télécharger
                        </button>
                    </div>
                </div>
                <div v-else>
                    <div class="btn-group">
                        <button
                            :disabled="!has_right_to_create"
                            @click="$emit('show-modal', 'import')"
                            class="btn btn-sm btn-outline-secondary mb-0 none-mobile"
                            style="border-radius: 20px 0 0 20px !important">
                            <feather type="download" />
                            Importer
                        </button>
                        <button
                            :disabled="!has_right_to_export"
                            @click="$emit('export-sales')"
                            class="btn btn-sm btn-outline-secondary btn-circle none-mobile"
                            style="border-radius: 0 20px 20px 0 !important">
                            <feather type="upload" />
                            Exporter
                        </button>
                    </div>
                    <button
                        :disabled="!has_right_to_read && !has_right_to_update"
                        type="button"
                        class="btn radius-btn-square btn-success scan-btn ml-1"
                        style="padding-top: 3px; padding-left: 4px"
                        @click="$emit('show-modal', 'scan')"
                        v-tooltip="getTooltip('Scanner un bon cadeau')">
                        <span class="text-white">
                            <feather type="aperture" class="feather feather-19" />
                        </span>
                    </button>
                </div>
            </div>
            <div>
                <transition name="slide-fade">
                    <gift-vouchers-advanced-filters v-if="showAdvancedSearch" :advanced-filters="advancedFilters" v-model="filters" />
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
import GiftVouchersAdvancedFilters from "./GiftVouchersAdvancedFilters.vue";

export default {
    props: {
        advancedFilters: {
            type: Array,
            default: () => {
                return [];
            },
        },
        isPayment: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            inputSearch: null,
            showAdvancedSearch: false,
            filters: {
                status: null,
                expedition_type: null,
                payment_type: null,
                from: null,
                to: null,
            },
        };
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read() {
            return this.rights.includes("gift_vouchers.sales.read");
        },
        has_right_to_create() {
            return this.rights.includes("gift_vouchers.sales.create");
        },
        has_right_to_update() {
            return this.rights.includes("gift_vouchers.sales.update");
        },
        has_right_to_export() {
            return this.rights.includes("gift_vouchers.sales.export");
        },
        countFilter() {
            let count = 0;
            Object.values(this.filters).forEach((element) => {
                if (element !== null) {
                    count++;
                }
            });
            return count;
        },
    },
    methods: {
        resetSearch() {
            this.inputSearch = "";
            this.search();
        },
        search() {
            this.emitFetchData({ search: this.inputSearch, filters: this.filters });
        },
        emitFetchData(value) {
            this.$emit("fetch-data", value);
        },
    },
    components: { GiftVouchersAdvancedFilters },
    watch: {
        filters: {
            handler(value) {
                this.emitFetchData({ search: this.inputSearch, filters: value });
            },
            deep: true,
        },
    },
};
</script>
<style>
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.4s ease-in-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}

.mx-40-px {
    margin-left: 20px;
    margin-right: 20px;
}

.semi-rounded-left {
    border-radius: 20px 0 0 20px !important;
}

.semi-rounded-right {
    border-radius: 0 20px 20px 0 !important;
}

.reset-search-icon {
    position: absolute;
    right: 36px;
    top: 7px;
    z-index: 100;
    cursor: pointer;
}

.container-bar {
    position: relative;
    z-index: 100;
}
</style>
