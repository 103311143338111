var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c("div", [
                  _vm.formErrors
                    ? _c(
                        "div",
                        { staticClass: "alert alert-danger" },
                        _vm._l(_vm.formErrors, function (error, index) {
                          return _c("p", { key: index }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(error) +
                                "\n                "
                            ),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formSuccess
                    ? _c("div", { staticClass: "alert alert-success" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.formSuccess) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("img", {
                            staticClass: "img-connect-serv",
                            attrs: { src: "/images/Stripe-Logo-blue.png" },
                          }),
                          _vm._v(" "),
                          _vm.can_edit_stripe
                            ? _c("div", [
                                _c("div", [
                                  _vm.restaurant.stripe_client_id
                                    ? _c(
                                        "div",
                                        { staticClass: "mb-3 id-stripe" },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.form.psp.stripe.clientID"
                                                )
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.restaurant.stripe_client_id
                                              )
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.form.psp.stripe.clientEmail"
                                                )
                                              ) +
                                              "\n                                    " +
                                              _vm._s(
                                                _vm.restaurant
                                                  .stripe_client_email
                                              )
                                          ),
                                          _c("br"),
                                        ]
                                      )
                                    : _vm.stripe_connect_url
                                    ? _c(
                                        "div",
                                        { staticClass: "mb-3" },
                                        [
                                          _c("stripe-connect-button", {
                                            attrs: {
                                              restaurant_id: _vm.restaurant_id,
                                            },
                                            on: {
                                              error: _vm.connectError,
                                              success: _vm.connectSuccess,
                                              "capabilities-error":
                                                _vm.connectCapabilitiesError,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "notice-text m-0" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.form.psp.stripe.description1"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "notice-text m-0" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.form.psp.stripe.description2"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", {
                                    staticClass: "notice-text",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$tl(
                                          "labels.form.psp.stripe.description3"
                                        )
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.restaurant.stripe_client_id
                                    ? _c("div", { staticClass: "mt-4" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-warning btn-circle text-white",
                                            attrs: {
                                              disabled:
                                                !_vm.has_right_to_update_restaurant,
                                            },
                                            on: { click: _vm.logoutStripe },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.psp.stripe.disconnectAccount"
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]),
                ]),
          ]),
      _vm._v(" "),
      _vm.showCapabilitiesErrorModal
        ? _c("capabilities-error-modal", {
            attrs: { params: _vm.connectErrorParams },
            on: {
              close: function ($event) {
                _vm.showCapabilitiesErrorModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }