export const state = {
    id: null,
    firstName: "",
    lastName: "",
    email: "",
    tel: "",
    tel_country: "",
    tel_is_mobile: false,
    lang: "fr",
    address: "",
    zipcode: "",
    city: "",
    country: "",
    rights: [],
    formattedRights: [],
    role: null,
    owner_id: null,
    company: "",
    companyType: "",
    companyTel: "",
    companyEmail: "",
    companySiret: "",
    companyCodeNaf: "",
    companyCodeTva: "",
    companyAddress: "",
    companyZipcode: "",
    companyCity: "",
    companyCountry: "",
    ownerSubscriptions: [],
    ownerAlreadyTriedModules: [],
    enableNotificationRingtone: false,
    keepClientsOnNewsletterFor: 3,
    keepClientsDetailsAndHistoryFor: 10,
    passwordLastChanged: null,
    newBadges: [],
    restaurant_ids: [],
    hasUnreadAppUpdates: false,
    isYproxUser: false,
    hasManualBilling: false,
    unseenFailedMailsNotificationsLogs: {},
    unreadSeatingPlanNotifications: [],
};

export default state;
