var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "border-light b-radius-20 px-4 pt-3 pb-2 text-muted" },
    [
      _c("div", { staticClass: "text-center mb-4" }, [
        _vm._v(_vm._s(_vm.$tl("labels.clients.caption.details"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _c("div", [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$tl("labels.clients.caption.loyalties")) +
              "\n            "
          ),
          _c(
            "ul",
            { staticClass: "pl-0 mt-3" },
            _vm._l(_vm.ALL_CLIENT_LOYALTIES, function (clientLoyaltyLevel) {
              return _c(
                "li",
                { key: clientLoyaltyLevel.value },
                [
                  _c("show-client-loyalty-level", {
                    attrs: {
                      "client-loyalty-level": clientLoyaltyLevel.value,
                      "show-label": "",
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ml-3" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$tl("labels.clients.caption.typologies")) +
              "\n            "
          ),
          _c(
            "ul",
            { staticClass: "pl-0 mt-3" },
            _vm._l(_vm.CLIENT_TYPES_WITH_SPECIFIC_ICONS, function (clientType) {
              return _c(
                "li",
                { key: clientType.value },
                [
                  _c("show-client-type", {
                    attrs: {
                      "client-type": clientType.value,
                      "show-label": "",
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ml-3" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$tl("labels.clients.caption.miscellaneous")) +
              "\n            "
          ),
          _c("ul", { staticClass: "pl-0 mt-3" }, [
            _c(
              "li",
              [
                _c("feather", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.getTooltip(_vm.$tl("labels.clients.vip")),
                      expression: "getTooltip($tl('labels.clients.vip'))",
                    },
                  ],
                  staticClass: "feather-orange",
                  attrs: { type: "star" },
                }),
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$tl("labels.clients.vip")) +
                    "\n                "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c("feather", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.getTooltip(_vm.$tl("labels.clients.risky")),
                      expression: "getTooltip($tl('labels.clients.risky'))",
                    },
                  ],
                  staticClass: "feather-orange",
                  attrs: { type: "alert-triangle" },
                }),
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$tl("labels.clients.risky")) +
                    "\n                "
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }