export default {
    loading: `Chargement en cours`,
    lang: `Cette langue sera associée à votre compte. Elle sera utilisée dans le back-office, mais n'influera pas sur vos widgets.`,
    defaultTelCountry: `Cet indicatif sera celui sélectionné par défaut lors de la saisie d'un numéro de téléphone dans votre back-office, mais également sur vos widgets.`,
    currency:
        'Ce paramètre est modifiable sous réserve qu\'aucun système de E-paiement (Stripe, Pay plug..) n\'est activé dans NOSHOW. La devise paramétrée est visible dans les modules "Réservations" et "Site web".',
    videos: {
        getYoutubeVideoId: `Pour obtenir l'identifiant de la vidéo youtube, rendez-vous sur la vidéo, puis dans l'URL, récupérez la valeur de "v". Exemple : dans "https://www.youtube.com/watch?v=eDUWbZH4jLE", l'identifiant de la vidéo est "eDUWbZH4jLE"`,
    },
    admin: {
        archive: {
            enable: `Si vous activez temporairement ce compte, le mot de passe de l'utilisateur sera réinitialisé afin d'empêcher une potentielle connexion. Des licences temporaires seront également créées afin de permettre l'accès à tous les services de l'application.`,
            disable: `En désactivant le compte, les licences temporaires préalablement créées seront définitivement supprimées. Il sera néanmoins possible de ré-activer temporairement ultérieurement.`,
        },
    },
    restaurants: {
        addressComp:
            "Le complément d'adresse sera ajouté à la fin de l'adresse de votre établissement. Il sert d'indication supplémentaire pour vos clients (ex : \"La Part-Dieu - porte britannia\")",
    },
    booking: {
        dashboard: `Piloter mes disponibilités : vous pouvez passer un service en complet, bloquer certains créneaux ou bloquer des PAX en cliquant directement sur la case du service concerné.`,
        externalMenus: `Cette fonctionnalité permet la consultation d'un document (ex : la carte du restaurant) par le client lors de sa réservation. Cette visualisation peut être sous forme d'un fichier PDF ou d'une page web dédiée (renseignez l'URL de cette page)`,
        restaurant: {
            email: `Adresse email utilisée si un de vos clients veut répondre à une notification de réservation.`,
        },
        servicesConfig: {
            serviceName: `Ce nom s'affichera uniquement pour vous et ne sera pas visible par vos clients.<br/> Il doit vous permettre de différencier deux plages horaires, par exemple pour un restaurant : Midi semaine - Midi week-end si vous avez créé deux services / plages horaires distincts.`,
            activeDays: `Si vous avez des conditions de réservation qui varient entre plusieurs plages horaires, pensez à créer des plages horaires différentes.<br/>Par exemple, si les créneaux de réservation ne sont pas identiques la semaine du week-end, vous devrez créer 2 plages horaires : une qui sera active pour les jours de la semaine, une autre pour les jours du week-end.`,
            reservationSlots: `Les créneaux cochés seront ceux qui seront proposés à vos clients pour la réservation.`,
            paxMaxPerService: `Cette valeur sera prise en compte pour le calcul de confirmation des réservations.<br/> Si vous activez le remplissage automatique en fonction de votre plan de salle, cette valeur sera tout de même prise en compte pour limiter le nombre de personnes de la plage horaire.<br/> Si vous activez la rotation, la capacité d'accueil est considérée comme étant la capacité en temps réel (par exemple pour un restaurant : 20 couverts en même temps).`,
            limitReservation: `Si vous souhaitez prendre des réservations jusqu'à l'heure de début de votre plage horaire, la valeur à indiquer est 0.`,
            cancellationPeriod: `Le délai d'annulation autorisé sera mentionné au client lorsqu'il effectue un dépôt d'empreinte bancaire.<br/> Il est informé que passé ce délai, sa réservation pourra être considérée comme une non-présentation.<br/> Ce délai est également pris en compte pour que le client puisse annuler sa réservation en toute autonomie grâce au bouton "modifier ma réservation" présent dans l'email de confirmation de réservation.<br/> Une fois le délai dépassé, le client ne peut plus annuler sa réservation.<br/> Enfin, cette valeur sert également pour l'envoi du rappel de réservation qui est envoyé 12h avant le délai d'annulation mentionné.`,
            reservationConfirm: `Cette fonctionnalité vous permet de prendre la main sur l'acceptation des réservations.<br/> Vous pouvez utiliser la confirmation manuelle, dans ce cas toutes les réservations devront être validées par vos soins.<br/> Si vous utilisez la fonction automatique, vous pouvez indiquer des paramètres pour prendre la main que dans certains cas de figure.<br/> Si par exemple, la confirmation des réservations est automatique jusqu'à 6 personnes, pour toutes les réservations de plus de 6 personnes, vous devrez les valider manuellement.<br/> Pour être en 100% automatique, il suffit de paramétrer la valeur max pour chacun des paramètres.`,
            tableRotation: `Si vous proposez plusieurs plages horaires, cette fonctionnalité est idéale pour avoir une vue d'ensemble de vos réservations et utiliser la vue timeline (vous aurez une visibilité sur les places réattribuées).`,
            automaticSeatingPlan: `Cette fonctionnalité vous permet d'utiliser notre plan de salle intelligent et d'automatiser la génération de votre plan de salle.<br/> Le remplissage est basé sur votre configuration (PAX autorisé sur chaque place / groupes de places / durée des réservations avec la rotation de place).`,
            mandatoryMenu: `Vos clients devront impérativement compléter cette étape pour valider la réservation.`,
            uniqueMenu: `Vos clients devront impérativement choisir une seule prestation pour l'ensemble de la réservation.<br/> Vous pouvez rendre le choix de prestation unique à partir d'un certain nombre de personne, par exemple 6 personnes.<br/> Dans ce cas, pour toutes les réservations de moins de 6 personnes, les clients pourront choisir différentes prestations.<br/> Au delà de 6, la prestation sera unique.`,
            bankFootprint: `En cas d'activation de l'empreinte bancaire, votre client devra automatiquement passer par cette étape pour valider sa réservation.<br/> Il est important de noter qu'une empreinte bancaire n'est valable que 7 jours.<br/> Dans le cas où la réservation est effectuée pour dans moins de 6 jours, l'empreinte bancaire sera immédiatement demandée à la dernière étape du widget de réservation qui sera intégré à votre site internet.<br/> Dans le cas où la réservation est prise pour une date ultérieure aux 6 prochains jours, le client recevra un mail lui mentionnant qu'une empreinte bancaire lui sera demandée 6 jours avant sa réservation.<br/> 6 jours avant la dite réservation, le client recevra automatiquement une notification (mail ou sms selon vos paramètres) pour lui demander de procéder à la confirmation de sa réservation par le biais de son empreinte bancaire.<br/> Si vous souhaitez demander une empreinte bancaire que pour certaines plages horaires, pensez à créer plusieurs plages horaires distinctes.<br/> Pour pouvez également désactiver l'empreinte bancaire pour les clients habitués et VIP en allant dans l'onglet Clients > Options.<br/> Pour activer l'empreinte bancaire, vous devez impérativement avoir connecté au préalable un compte Stripe (ou Payplug).`,
            prepayment: `Vous pouvez mettre en place cette solution pour demander un paiement intégral à la réservation ou pour gérer la prise d'acomptes pour sécuriser vos réservations.<br/> Cette fonctionnalité est nécessairement liée au choix de prestations.<br/> Le calcul du montant à régler par le client est basé sur les prestations et options sélectionnées.<br/> Cette fonction peut être utilisée par exemple pour demander des acomptes pour les groupes de plus de X personnes.`,
            bookingCancellationWithoutFootprint: `Pour les demandes d'acompte initiées manuellement depuis le backoffice ou pour les demandes d'empreintes envoyées par mail ou sms, les clients bénéficient d'un délai de 48h pour renseigner leurs informations bancaires.<br/> Passé ce délai, vous pouvez programmer l'annulation automatique des réservations concernées.<br/> Dans le cas contraire, une notification vous sera transmise pour vous indiquer le défaut de paiement.<br/> Vous pourrez alors choisir de renvoyer une demande au client ou d'annuler sa réservation manuellement.`,
            restaurantProposal: `Si votre établissement affiche complet et que vous n'avez plus de disponibilités, il sera proposé au client de réserver dans un autre de vos établissements si vous l'avez affilié.<br/> Le client ne verra apparaître la suggestion que si l'autre établissement a de la disponibilité.`,
            exceptionalOpening: `Si vous êtes ouvert habituellement pendant ces dates, l'ouverture exceptionnelle prendre le dessus sur vos plage horaires habituelles.<br/> Si vous n'êtes pas ouvert habituellement sur le jour concerné, la disponibilité s'affichera tout simplement sur votre module de réservation.`,
            exceptionalClosureConcernedServices: `Si vous souhaitez fermer uniquement une des plage horaires (et non l'ensemble), vous pouvez sélectionner uniquement la ou les plage horaires concernée(s).<br/> Cela peut-être utilisé par exemple dans le cadre d'une privatisation ou d'un changement temporaire des plages horaires proposées.`,
            sendReminderPayment: `Si la confirmation d'une réservation est soumise à la demande d'une empreinte bancaire ou d'un prépaiement, alors l'activation de cette fonctionnalité permettra l'envoi d'un rappel (envoi manuel ou automatique) X heures avant l'échéance de cette demande.`,
        },
        tablePlan: {
            itemAdd: `Si vous avez une grande table d'hôte pouvant se découper en plusieurs tables, pensez à ajouter plusieurs tables (par exemple de 2) et paramétrer ensuite des groupes de tables pour ajouter toutes les configurations possibles.<br/> Si dans ce groupe de table, une table fait office de séparation et ne peut pas être attribuée, il faut configurer une table à 0, et la considérer seulement dans un groupement de tables).`,
            tables: `Vous pouvez définir un ordre de remplissage prioritaire en ordonnant les tables (cliquer sur = et déplacer).<br/> Pensez à bien enregistrer vos modifications.`,
            groups: `Pour paramétrer un groupe de table, il faut sélectionner plusieurs tables à l'aide de l'outil "rectangle de sélection" ou à l'aide du raccourci clavier "Ctrl", et de cliquer sur Grouper ces tables.<br/> Il est important de bien définir tous les groupements de tables possibles pour que l'algorithme de remplissage automatique soit le plus performant possible et gère parfaitement vos prises de réservations.<br/> Vous pouvez également définir un ordre de remplissage prioritaire en ordonnant les groupes de tables (cliquer sur = et déplacer). Pensez à bien enregistrer vos modifications.`,
            rooms: `Vous pouvez définir un ordre de remplissage prioritaire en ordonnant les salles (cliquer sur = et déplacer).<br/> Pensez à bien enregistrer vos modifications.`,
            disablingTable: `Si vous désactivez une table, elle n'apparaîtra plus sur la vue plan de salle.<br/> Elle ne sera également plus considérée dans les groupes de table.<br/> Il est donc primordial de faire toutes les mises à jour nécessaires avant l'enregistrement.<br/> La désactivation de la table est enregistrée pour l'ensemble des plage horaires sans limitation de durée.<br/> Si vous souhaitez bloquer une table pour un jour précis, nous vous invitons à bloquer cette table directement depuis la vue plan de salle depuis l'onglet Réservations.`,
            disablingRoom: `La désactivation de la salle est enregistrée pour l'ensemble des plages horaires sans limitation de durée.<br/> Si vous souhaitez gérer l'ouverture d'une salle en fonction de dates, vous pouvez le faire dans "Configuration de la salle", "Date de début" et "Date de fin".`,
        },
        notifications: {
            phoneName: `Le nombre de caractères autorisés est de 11 caractères maximum.<br/> C'est une norme standard sur laquelle nous ne pouvons pas agir.<br/> Si le nom de votre établissement dépasse les 11 caractères, nous vous conseillons de trouver un nom court évocateur.<br/>Attention : seule les lettres minuscules et majuscules sont autorisées, les caractères spéciaux, chiffres, espaces, ou lettres accentuées ne sont pas acceptés par notre prestataire d’envoi de SMS.`,
            strangerSms: `Si vous n'activez pas l'envoi des sms aux numéros étrangers, les notifications seront transmises à défaut par email aux clients dont le numéro ne contient pas l'indicatif +33 (FR).`,
            notificationsConfig: `Pour chaque type de notification, vous pouvez paramétrer le canal d'envoi souhaité.<br/> Si vous souhaitez envoyer des notifications par SMS, vous devrez au préalable avoir activé les SMS sur votre compte.<br/> Lorsque votre quota de SMS est nul, les notifications mails prennent le relais jusqu'au rechargement de votre compte.<br/> Pour limiter au maximum les non-présentations et optimiser votre remplissage, nous vous conseillons l'envoi de deux notifications : <br/>- la demande de confirmation de réservation 24h avant l'heure de la réservation <br/>- la notification d'information qu'une place est disponible pour les clients inscrits sur liste d'attente.`,
            qrCode: `Si vous sélectionnez cette option, un QR Code sera ajouté automatiquement dans le mail de confirmation envoyé au client.<br/> Vous pourrez ensuite le "Flasher" depuis le menu "En cours" pour valider l'arrivée du client et simplifier/améliorer le processus d'accueil de vos clients.`,
        },
        widget: {
            mainMessage: `Ce message s'affiche avant l'apparition du module de réservation.<br/> Il doit contenir les informations dont le client doit prendre connaissance avant d'effectuer sa réservation.<br/> Par exemple : "Chers clients, face à l'augmentation significative des réservations non-honorées, nous vous informons que toute réservation effectuée à partir de 6 personnes sera soumise à la prise d'une empreinte bancaire. Nous vous remercions pour votre compréhension.<br/> En cas de demande de réservation de plus de 6 personnes, merci de nous contacter par téléphone.<br/> Au plaisir de vous accueillir prochainement !"`,
            slotMessage: `Ce message s'affiche à l'étape 2 du module de réservation.<br/> Il s'agit d'une indication que vous pouvez donner à vos clients.<br/> Par exemple : "Merci de respecter votre heure d'arrivée. En cas de retard de plus de 15 minutes, votre réservation pourra être réattribuée".`,
            commentField: `Si vous ne souhaitez pas que le client puisse ajouter un commentaire lors de sa réservation, vous devez impérativement désactiver ce champ. Note : si vous utilisez la fonctionnalité "Réserver avec Google", le champ sera quand même affiché sur le widget Google.`,
            commentInfo: `Vous pouvez indiquer au client les informations qui vous sont indispensables pour traiter la réservation.<br/> Par exemple : "Merci de nous indiquer vos éventuelles allergies ou régime alimentaire."`,
            commentRequired: `Si le champ commentaire est obligatoire, le client ne pourra passer à l'étape suivante sans avoir renseigné ce champ.`,
            giftVoucherField: `Lorsque vous activez le champ Bon cadeau, le client peut saisir une référence pour vous avertir qu'il est détenteur du bon cadeau.<br/> Dans ce cas la réservation ne sera pas validée automatiquement, et passera en attente pour vous laisser la possibilité de vérifier la validité du bon cadeau avant l'acceptation de la réservation.<br/> À savoir que la saisie d'une référence par le client désactive automatiquement le prépaiement.<br/> Si vous utilisez notre système de vente de bons et chèques cadeaux, la vérification est automatique.`,
            giftVoucherConfirm: `Test`,
            footprintGift: `Test`,
            waitingList: `Lorsque votre établissement affiche complet, le client a la possibilité de s'inscrire sur liste d'attente pour être averti si une place se libère.`,
            waitingListAutoNotif: `Si vous activez les notifications automatiques, dès lors qu'une annulation sera enregistrée, toutes les personnes sur liste d'attente dont le nombre correspond aux places disponibles, seront averties.<br/> La priorité sera donnée à celui qui aura réservé en premier.`,
            waitingListPriority: `Une notification sera envoyée en priorité aux clients VIP, puis aux clients en fonction de leur niveau de fidélité.<br/> Si le créneau n'est pas réservé, les autres clients inscrits sur la liste d'attente recevront à leur tour la notification.`,
            hotelMode:
                "En activant le mode hôtel, vous avez la possibilité d'indiquer lors de l'ajout d'une réservation que le client séjourne actuellement dans votre hôtel. Vous pourrez également ajouter son numéro de chambre, et ce dernier apparaîtra dans toutes les listes de réservations.",
            gTag: "Veuillez au préalable suivre les instructions à l'étape 5 de l'onglet intégration pour que l'id soit pris en compte",
        },
        menus: {
            externalDescription:
                "Vous avez la possibilité d'ajouter une description additionnelle à une prestation sous forme d'un fichier PDF ou d'un lien internet vers une page web dédiée. Cette description est directement consultable par le client depuis le widget réservation (exemple pour un restaurant: composition du menu, photos des différents plats...)",
        },
        customEvents: {
            visibility:
                "Si vous sélectionnez une visibilité \"Externe\", alors le titre de l'événement et sa description seront affichés sur votre widget de réservation en plus d'être visibles dans le back-office.",
        },
        interfaces: {
            publicApiClient: {
                fhr: "Pour activer l'interfaçage avec Fiducial Hôtel-Restaurant, vos tables doivent respecter la norme : table unique et nom de table compris entre 1 et 999 pour chaque salle",
            },
        },
        clients: {
            cannotBeNotified: "Le client ne peut pas recevoir de notifications",
            cannotBeUnNotifiedGoogle: "La réservation a été effectuée depuis Google, l'envoi des notifications est donc géré par Google",
        },
    },

    clickAndCollect: {
        config: {
            orderPreparationDelay: `Il s'agit du délai minimum entre lequel le client passe la commande et vient la retirer sur place.<br/> Par exemple, si vous indiquez 30 min de délai de préparation, le client pourra retirer sa commande dès midi pour une commande passée à 11h30.<br/> Sachez qu'une fonctionnalité vous permet de suspendre les nouvelles commandes pendant la plage horaire.<br/> Si vous souhaitez que vos clients commandent la veille pour le lendemain, il convient de mettre un délai de 15h à 24h.`,
            commandConfirm: `Cette fonctionnalité vous permet de prendre la main sur l'acceptation des commandes.<br/> Vous pouvez utiliser la confirmation manuelle, dans ce cas toutes les commandes devront être validées par vos soins.<br/> Si vous utilisez la fonction automatique, vous pouvez indiquer des paramètres pour prendre la main que dans certains cas de figure.<br/> Si par exemple, la confirmation est automatique jusqu'à 6 commandes par plage horaire, passées 6 commandes sur une même plage horaire, vous devrez valider les futures commandes de cette dernière manuellement.<br/> Pour être en 100% automatique, il suffit d'indiquer 0 pour chacun des paramètres.`,
        },
        configDelivery: {
            orderPreparationDelay: `Il s'agit du délai minimum entre lequel le client passe la commande et le début du créneau de livraison.<br/> Par exemple, si vous indiquez 30 min de délai de préparation, le client pourra commander jusqu'à 30 minutes avant le début de la livraison.<br/> Sachez qu'une fonctionnalité vous permet de suspendre les nouvelles commandes pendant les créneaux de livraison.<br/> Si vous souhaitez que vos clients commandent la veille pour le lendemain, il convient de mettre un délai de 15h à 24h.`,
            commandConfirm: `Cette fonctionnalité vous permet de prendre la main sur l'acceptation des commandes.<br/> Vous pouvez utiliser la confirmation manuelle, dans ce cas toutes les commandes devront être validées par vos soins.<br/> Si vous utilisez la fonction automatique, vous pouvez indiquer des paramètres pour prendre la main que dans certains cas de figure.<br/> Si par exemple, la confirmation est automatique jusqu'à 6 commandes par créneau de livraison, passées 6 commandes sur un même créneau, vous devrez valider les futures commandes de ce dernier manuellement.<br/> Pour être en 100% automatique, il suffit d'indiquer 0 pour chacun des paramètres.`,
        },
        categories: {
            isDisplayPerCategory:
                "Affiche uniquement les produits de la catégorie sélectionnée au lieu de la liste totale des produits.<br/>Ce mode d'affichage est particulièrement adapté si vous disposez de nombreux produits et de quelques catégories.",
            useBurgerMenu:
                "La liste des catégories est accessible à partir d'un menu déroulant.<br/>Ce mode d'affichage est particulièrement adapté si vous disposez de très nombreuses catégories de produits.",
        },
    },

    giftVoucher: {},

    webSite: {},

    googleReserve: {
        texts: "Ces textes seront affichés sur le module de réservation de Google. Vous devez saisir un nom et une description pour le service dans au moins une langue, mais nous vous recommandons d'en remplir un maximum afin d'améliorer l'expérience utilisateur. Note : le rendu est susceptible de légèrement varier lors de l'affichage sur Google.",
        enable: 'En activant "Réserver avec Google", vous autorisez Google à afficher un bouton "Réserver une table" sur leur moteur de recherche, dans le détail de votre établissement.<br/>Vous pourrez ensuite, pour chaque service, l\'ouvrir ou non à la réservation sur Google.<br/>Tous les paramétrages effectués dans votre back-office seront répercutés sur "Réserver avec Google", et les réservations effectuées depuis Google remonteront dans votre liste de réservations habituelles.<br/>Note : vous devez posséder une fiche "Google my Business" pour que la liaison soit effective.',
    },
};
