var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-100" }, [
    _c("div", { domProps: { innerHTML: _vm._s(_vm.customCss) } }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "w-100 h-100 position-relative overflow-auto" },
      [
        _vm.isToday
          ? _c("hour-cursor", {
              attrs: {
                "minutes-interval": _vm.minutesInterval,
                "start-date-time": _vm.hoursRange[0],
                "end-date-time": _vm.hoursRange[_vm.hoursRange.length - 1],
                "cell-width": _vm.realCellWidth,
                left: _vm.firstColOffset,
                "header-height": _vm.headerHeight,
                "cursor-height": _vm.tableHeight - _vm.headerHeight,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.dataSourceToShow, function (data, index) {
          return _vm._l(data.events, function (event) {
            return _c("show-event", {
              key: `${event.uniqId}-${data.uniqId}`,
              attrs: {
                event: event,
                "start-hour-date-time": _vm.hoursRange[0],
                "end-hour-date-time": _vm.hoursRange[_vm.hoursRange.length - 1],
                "cell-width": _vm.realCellWidth,
                "minutes-interval": _vm.minutesInterval,
                top: `${_vm.headerHeight + _vm.rowHeight * index}px`,
                "first-col-offset": _vm.firstColOffset,
              },
              on: {
                "event-clicked": function ($event) {
                  return _vm.$emit("event-clicked", { event })
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "event",
                    fn: function ({ event }) {
                      return [_vm._t("event", null, null, { event })]
                    },
                  },
                ],
                null,
                true
              ),
            })
          })
        }),
        _vm._v(" "),
        _c("table", { ref: "table", staticClass: "table timeline" }, [
          _c("thead", { ref: "tableHead" }, [
            _c(
              "tr",
              { staticClass: "text-center" },
              [
                _c(
                  "th",
                  {
                    ref: "tableFirstCol",
                    staticClass: "border align-middle",
                    staticStyle: { width: "200px" },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-danger font-weight-normal text-capitalize",
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.currentDate.toLocaleString(
                                _vm.DATE_MED_DAY_NO_YEAR
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.hoursRange, function (hour) {
                  return _c(
                    "th",
                    {
                      key: hour.toSeconds(),
                      ref: "tableCellHour",
                      refInFor: true,
                      staticClass: "header-cell-hour",
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(hour.toLocaleString(_vm.TIME_SIMPLE)) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm._l(_vm.dataSourceToShow, function (data) {
                return [
                  data.collapse
                    ? _c(
                        "tr",
                        {
                          key: `collapse-${data.uniqId}`,
                          ref: "rows",
                          refInFor: true,
                          staticClass: "bg-light",
                        },
                        [
                          _c(
                            "td",
                            { staticClass: "border pl-2" },
                            [
                              _c("feather", {
                                staticClass: "pointer mr-1",
                                attrs: {
                                  type: _vm.openedTr.includes(data.uniqId)
                                    ? "chevron-down"
                                    : "chevron-right",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleTr(data.uniqId)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm._t("cell-collapse-name", null, null, {
                                data: data.data,
                                uniqId: data.uniqId,
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.hoursRange, function (hour) {
                            return _c("td", {
                              key: hour.toSeconds(),
                              staticClass: "border",
                            })
                          }),
                        ],
                        2
                      )
                    : _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                typeof data.collapseBy === "undefined" ||
                                _vm.openedTr.includes(data.collapseBy),
                              expression:
                                "typeof data.collapseBy === 'undefined' || openedTr.includes(data.collapseBy)",
                            },
                          ],
                          key: `row-${data.uniqId}`,
                          ref: "rows",
                          refInFor: true,
                        },
                        [
                          _c(
                            "td",
                            { staticClass: "border text-right pr-3" },
                            [
                              _vm._t("cell-row-name", null, null, {
                                data: data.data,
                                uniqId: data.uniqId,
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.hoursRange, function (hour) {
                            return _c(
                              "td",
                              {
                                key: hour.toSeconds(),
                                staticClass:
                                  "border cell-highlight text-center",
                                class: {
                                  "bg-light": hour > _vm.endHourDateTime,
                                },
                              },
                              [
                                _vm._t("cell-body", null, null, {
                                  hour,
                                  data: data.data,
                                  uniqId: data.uniqId,
                                }),
                              ],
                              2
                            )
                          }),
                        ],
                        2
                      ),
                ]
              }),
            ],
            2
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }