<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Synchroniser les identifiants clients Fiducial</h5>
        <div slot="body">
            <loader-component v-if="isLoading"></loader-component>
            <form v-show="!isLoading" id="sync-fiducial-clients-ids-form" @submit.prevent="startImport">
                <div class="row mb-2">
                    <div class="col-md-5">Fichier *</div>
                    <div class="col-md-7">
                        <file-uploader-component
                            suffix-id="excel-import"
                            :has-direct-upload="false"
                            allowed-extensions=".xls,.xlsx"
                            :errors="errors.form"
                            @upload-file="form.file = $event"></file-uploader-component>
                    </div>
                </div>
                <input-component v-model="form.fiducialClientIdColumn" :form-errors="errors.form" input-name="fiducialClientIdColumn">
                    <template #label>
                        Nom de la colonne "Identifiant client Fiducial"<br />
                        <small>(défaut : "CodeClient")</small>
                    </template>
                </input-component>
                <input-component v-model="form.emailColumn" :form-errors="errors.form" input-name="emailColumn">
                    <template #label>
                        Nom de la colonne "Email client"<br />
                        <small>(défaut : "mail")</small>
                    </template>
                </input-component>
                <switch-input-component v-model="form.shouldCommit" :form-errors="errors.form" input-name="shouldCommit">
                    <template #label>Sauvegarder les changements</template>
                </switch-input-component>
                <switch-input-component v-model="form.shouldOverrideExistingIds" :form-errors="errors.form" input-name="shouldCommit">
                    <template #label>Override les identifiants existants</template>
                </switch-input-component>
            </form>
        </div>
        <div slot="footer" class="d-flex">
            <button type="button" class="modal-default-button btn-sm btn btn-secondary btn-circle" :disabled="isLoading" @click="close">
                Annuler
            </button>
            <button form="sync-fiducial-clients-ids-form" class="btn btn-success btn-sm btn-circle ml-2" :disabled="isLoading">Synchroniser</button>
        </div>
    </modal>
</template>

<script>
import LoaderComponent from "../../../LoaderComponent.vue";
import ShowErrors from "../../../errors/ShowErrors.vue";
import InputComponent from "../../../forms/InputComponent.vue";
import FileUploaderComponent from "../../../forms/FileUploaderComponent.vue";
import SwitchInputComponent from "../../../forms/SwitchInputComponent.vue";

export default {
    data() {
        return {
            isLoading: false,
            errors: {
                form: null,
            },
            form: {
                file: null,
                fiducialClientIdColumn: null,
                emailColumn: null,
                shouldCommit: true,
                shouldOverrideExistingIds: false,
            },
        };
    },
    methods: {
        getFormData() {
            const formData = new FormData();

            if (![null, ""].includes(this.form.fiducialClientIdColumn)) {
                formData.append("fiducialClientIdColumn", this.form.fiducialClientIdColumn);
            }

            if (![null, ""].includes(this.form.emailColumn)) {
                formData.append("emailColumn", this.form.emailColumn);
            }

            if (this.form.file !== null) {
                formData.append("file", this.form.file);
            }

            formData.append("shouldCommit", +this.form.shouldCommit);

            formData.append("shouldOverrideExistingIds", +this.form.shouldOverrideExistingIds);

            return formData;
        },
        startImport() {
            this.isLoading = true;

            this.errors.form = null;

            this.httpPost("/api/admin/sync-fiducial-client-ids", this.getFormData(), {
                config: {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            })
                .then((response) => {
                    if (response !== false) {
                        this.close();
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        SwitchInputComponent,
        LoaderComponent,
        ShowErrors,
        InputComponent,
        FileUploaderComponent,
    },
};
</script>
