<template>
    <div>
        <LoaderComponent v-if="loading" />
        <template v-else>
            <label class="mb-3">
                <strong>{{ $tl("labels.booking.reservations.privateComment") }}</strong>
            </label>
            <div class="row mb-3">
                <div class="col-12 tags-com-resa">
                    <div>
                        <span
                            v-for="(tag, index) in ALL_RESERVATION_TAGS"
                            :key="index"
                            class="badge badge-secondary p-1 pl-2 pr-2"
                            :class="{ 'cursor-disabled': readonly }"
                            @click="readonly ? true : addComment(getReservationTagLabel(tag.value))">
                            {{ getReservationTagLabel(tag.value) }}
                        </span>
                    </div>
                    <textarea
                        name="restaurant_comment"
                        tabindex="11"
                        autocomplete="off"
                        :disabled="readonly"
                        v-model="restaurant_comment"
                        class="form-control mt-1"
                        rows="4">
                    </textarea>
                    <div v-if="hasErrors('restaurant_comment')">
                        <div class="invalid-feedback d-block" v-for="err in formErrors.errors.restaurant_comment">
                            {{ err }}
                        </div>
                    </div>
                    <button
                        v-if="reservation_id"
                        :disabled="readonly"
                        class="btn btn-sm btn-circle btn-success mt-2"
                        type="button"
                        @click="saveRestaurantComment">
                        {{ $tl("labels.booking.reservations.saveComment") }}
                    </button>
                </div>
            </div>
            <div class="sepa"></div>
            <div class="row">
                <div class="col-md-5 pt-2">
                    <label>{{ $tl("labels.booking.reservations.giftVoucher.title") }}</label>
                </div>
                <div class="col-md-7">
                    <input
                        type="text"
                        name="gift"
                        autocomplete="off"
                        :disabled="readonly"
                        v-model="giftCode"
                        tabindex="12"
                        class="form-control"
                        :class="{ 'is-invalid': hasErrors('gift') }" />
                    <div v-if="hasErrors('gift')">
                        <div class="invalid-feedback d-block" v-for="err in formErrors.errors.gift">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="isHotelModeEnabled">
                <div class="sepa"></div>
                <switch-input-component :disabled="readonly" :wrapperClasses="{ 'mb-2': false }" labelWrapperClasses="pt-2" v-model="is_hotel_client">
                    <template v-slot:label>
                        {{ $tl("labels.clients.isHotel") }}
                    </template>
                </switch-input-component>
            </template>
            <template v-if="isFeatRoomNumbersEnable || is_hotel_client">
                <div v-if="!isHotelModeEnabled" class="sepa"></div>
                <div class="row">
                    <div class="col-md-5 pt-2">
                        {{ $tl("labels.booking.reservations.roomNumberShort") }}
                    </div>
                    <div class="col-md-7">
                        <input type="text" class="form-control" v-model="room_number" />
                        <div
                            v-if="room_number !== null && room_number.length > 0 && roomNumbersForAutocomplete.length > 0 && !isRoomNumberValid"
                            class="room-dropdown">
                            <span
                                class="d-block pointer"
                                :class="{ 'cursor-disabled': readonly }"
                                v-for="room in roomNumbersForAutocomplete"
                                :key="room.id"
                                @click="readonly ? false : (room_number = room.number)">
                                {{ room.number }}
                            </span>
                        </div>
                        <span
                            class="text-danger d-block"
                            v-if="
                                roomNumbers.length > 0 &&
                                !isRoomNumberValid &&
                                room_number !== null &&
                                room_number.length > 0 &&
                                roomNumbersForAutocomplete.length === 0
                            ">
                            {{ $tl("errors.booking.reservations.noHotelRoomFound") }}
                        </span>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import { fr } from "vuejs-datepicker/dist/locale";
import _ from "lodash";
import LoaderComponent from "../LoaderComponent.vue";
import ReservationTagsEnum from "../../mixins/enums/booking/ReservationTagsEnum.js";
import SwitchInputComponent from "../forms/SwitchInputComponent.vue";

export default {
    name: "commentaryReservations",
    data() {
        return this.$_.cloneDeep(
            this.$_.defaultsDeep(this.defaultValues, {
                loading: false,
                formLoading: false,
                stripeLoading: false,
                error: null,
                seatingPlanError: null,
                formSuccess: null,
                fr: fr,
                slotsError: null,
                menusError: null,
                giftCodeData: null,
                defaultTags: ["gluten", "lactose", "vegetarien", "vegetalien", "pmr", "chaise", "poussette", "anniversaire", "allergie"],
                roomNumbers: [],
            })
        );
    },
    mixins: [ReservationTagsEnum],
    props: {
        value: {
            default: "",
        },
        formErrors: {
            default: null,
        },
        gift: {
            default: "",
        },
        room_number_: {
            default: null,
        },
        is_hotel_client_: {
            default: false,
        },
        reservation_id: {
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        saveRestaurantComment() {
            this.loading = true;

            axios
                .post(`/api/restaurants/${this.restaurant_id}/reservations/${this.reservation_id}/restaurantComment`, {
                    restaurant_comment: this.restaurant_comment,
                })
                .then((response) => {
                    this.notifySuccess(response);
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => (this.loading = false));
        },
        addComment(text) {
            if (this.restaurant_comment) {
                this.restaurant_comment += `\n${text}`;
            } else {
                this.restaurant_comment = text;
            }
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
        fetchRoomNumbers() {
            this.loading = true;
            this.roomNumbers = [];

            this.httpGet(`/api/restaurants/${this.restaurant_id}/roomNumbers`)
                .then((response) => {
                    if (response !== false) {
                        this.roomNumbers = response.data.data;
                    }
                })
                .finally(() => (this.loading = false));
        },
    },
    computed: {
        isRoomNumberValid() {
            const firstRoomNumberInList = this.roomNumbersForAutocomplete.length > 0 ? this.roomNumbersForAutocomplete[0] : null;
            return firstRoomNumberInList && this.room_number !== null && this.room_number === firstRoomNumberInList.number;
        },
        roomNumbersForAutocomplete() {
            if (this.room_number !== null && this.room_number.length > 0)
                return this.roomNumbers.filter((room) => room.number.toLowerCase().includes(this.room_number.toLowerCase()));
            return [];
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        isFeatRoomNumbersEnable() {
            const restaurant = this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id);

            return restaurant ? restaurant.feat_room_numbers : false;
        },
        isHotelModeEnabled() {
            return this.$store.getters["widgets/getWidget"].hotel_mode;
        },
        room_number: {
            get() {
                return this.room_number_;
            },
            set(newVal) {
                this.$emit("set-room-number", newVal);
            },
        },
        is_hotel_client: {
            get() {
                return this.is_hotel_client_;
            },
            set(newVal) {
                this.$emit("set-is-hotel-client", newVal);
            },
        },
        giftCode: {
            get() {
                return this.giftCodeData !== null ? this.giftCodeData : this.gift;
            },
            set(newVal) {
                this.giftCodeData = newVal;
            },
        },
        restaurant_comment: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit("input", value);
            },
        },
    },
    watch: {
        giftCodeData: {
            handler: function (value) {
                this.$emit("add-gift-code", value);
            },
        },
        restaurant_id: {
            immediate: true,
            handler() {
                if (this.isFeatRoomNumbersEnable) {
                    this.fetchRoomNumbers();
                }
            },
        },
        is_hotel_client(newVal) {
            if (!newVal) {
                this.room_number = null;
            }
        },
    },
    components: {
        LoaderComponent,
        SwitchInputComponent,
    },
};
</script>
