var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-100" }, [
    !_vm.isBrowserIE
      ? _c(
          "div",
          { staticClass: "back-off h-100" },
          [
            _vm.user && _vm.user.role !== "kitchen"
              ? _c(
                  "topbar",
                  {
                    style:
                      _vm.moduleSwitcher.width > 0
                        ? `margin-left: ${_vm.moduleSwitcher.width}px !important`
                        : "",
                    attrs: {
                      isSidebarCollapsed: _vm.isSidebarCollapsed,
                      restaurants: _vm.restaurants,
                      user: _vm.user,
                      home: { name: "booking.home" },
                      module_type: "booking",
                      mobileMenuDisplayed: _vm.mobileMenuDisplayed,
                    },
                    on: {
                      "module-switcher-button-clicked": function ($event) {
                        _vm.moduleSwitcher.show = !_vm.moduleSwitcher.show
                      },
                      "mobile-menu-updated": function ($event) {
                        _vm.mobileMenuDisplayed = $event
                      },
                    },
                  },
                  [
                    _c("booking-topbar-actions", {
                      attrs: {
                        isSidebarCollapsed: _vm.isSidebarCollapsed,
                        user: _vm.user,
                      },
                    }),
                  ],
                  1
                )
              : _c("kitchen-account-topbar", {
                  attrs: { restaurants: _vm.restaurants, user: _vm.user },
                }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "container-fluid h-100",
                style:
                  _vm.moduleSwitcher.show == true ? "overflow-x: hidden;" : "",
              },
              [
                _c(
                  "div",
                  { staticClass: "row h-100" },
                  [
                    _c("module-switcher", {
                      attrs: {
                        serviceId: "booking",
                        isSidebarCollapsed: _vm.isSidebarCollapsed,
                        user: _vm.user,
                        show: _vm.moduleSwitcher.show,
                      },
                      on: {
                        open: _vm.onModuleSwitcherOpen,
                        close: _vm.closeModuleSwitcher,
                      },
                    }),
                    _vm._v(" "),
                    _vm.user && _vm.user.role !== "kitchen"
                      ? _c("sidebar", {
                          style:
                            _vm.moduleSwitcher.width > 0
                              ? `margin-left: ${_vm.moduleSwitcher.width}px !important`
                              : "",
                          attrs: {
                            user: _vm.user,
                            restaurants: _vm.restaurants,
                            isSidebarCollapsed: _vm.isSidebarCollapsed,
                            entries: _vm.sidebarEntries,
                            restaurantRouteName: `booking.restaurants.reservations`,
                            "force-show": _vm.restaurants.length > 1,
                          },
                          on: {
                            "sidebar-collapse-changed": function ($event) {
                              _vm.isSidebarCollapsed = $event
                            },
                            "close-menu": function ($event) {
                              _vm.mobileMenuDisplayed = false
                            },
                            "reload-router-view": function ($event) {
                              return _vm.$emit("reload-router-view")
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "main",
                      {
                        staticClass: "pl-0 pr-0 h-100 main",
                        class: {
                          "col-md-12 col-lg-12":
                            _vm.user && _vm.user.role === "kitchen",
                          "offset-md-3 offset-lg-2 col-md-9 col-lg-10":
                            _vm.user &&
                            _vm.user.role !== "kitchen" &&
                            !_vm.isSidebarCollapsed,
                          "offset-md-1 offset-lg-1 col-lg-11 col-md-11":
                            _vm.isSidebarCollapsed,
                        },
                        style:
                          _vm.moduleSwitcher.width > 0
                            ? `margin-left: ${
                                _vm.moduleSwitcher.width + 240
                              }px !important`
                            : "",
                        attrs: { role: "main" },
                      },
                      [
                        _c("notifications", {
                          staticStyle: { "z-index": "9999" },
                          attrs: { group: "notification" },
                        }),
                        _vm._v(" "),
                        _vm._t("default"),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm.showAcceptCgvModal
              ? _c("AcceptCgvModal", {
                  attrs: { module: _vm.MODULE_TYPE_BOOKING },
                  on: {
                    close: function ($event) {
                      _vm.showAcceptCgvModal = false
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _c("div", { staticStyle: { "text-align": "center" } }, [
          _c("h1", [_vm._v(_vm._s(_vm.$tl("errors.browser.invalid.title")))]),
          _vm._v(" "),
          _c("p", {
            domProps: {
              innerHTML: _vm._s(_vm.$t("errors.browser.invalid.infos")),
            },
          }),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }