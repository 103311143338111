var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.mailLog.permanent_failed_at || !_vm.mailLog.sent_at
      ? _c("small", { staticClass: "text-danger" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.$t(
                  "labels.booking.reservations.mailLogs.permanent_failed",
                  {
                    type: _vm.getReservationMailLogLabel(
                      _vm.mailLog.type,
                      _vm.restaurant_id
                    ),
                  }
                )
              ) +
              "\n    "
          ),
        ])
      : _vm.mailLog.sent_at && !_vm.mailLog.delivered_at
      ? _c("small", { staticClass: "text-warning" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.$t("labels.booking.reservations.mailLogs.pending", {
                  type: _vm.getReservationMailLogLabel(
                    _vm.mailLog.type,
                    _vm.restaurant_id
                  ),
                })
              ) +
              "\n    "
          ),
        ])
      : _vm.mailLog.delivered_at
      ? _c("small", [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.$t("labels.booking.reservations.mailLogs.sent", {
                  type: _vm.getReservationMailLogLabel(
                    _vm.mailLog.type,
                    _vm.restaurant_id
                  ),
                  date: _vm.displayDate(
                    _vm.mailLog.delivered_at,
                    _vm.DATE_SHORT
                  ),
                  hour: _vm.displayDate(
                    _vm.mailLog.delivered_at,
                    _vm.TIME_SIMPLE
                  ),
                })
              )
          ),
          _vm.mailLog.opened_at
            ? _c("span", [
                _vm._v(
                  " (" +
                    _vm._s(
                      _vm.$tl("labels.booking.reservations.mailLogs.opened")
                    ) +
                    ")"
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }