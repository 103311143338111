var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loadings.appUpdate || _vm.loadings.form
        ? _c("loader-component")
        : _vm.errors.fetchAppUpdate !== null
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v("\n        " + _vm._s(_vm.errors.fetchAppUpdate) + "\n    "),
          ])
        : _c(
            "form",
            {
              ref: "form",
              attrs: { method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit()
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("div", { staticClass: "col-md-4 pt-1" }, [
                        _c("label", { attrs: { for: "update_date" } }, [
                          _vm._v("Date de la mise à jour "),
                          _c("small", [_vm._v("*")]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-8 date-resa-cal top-initial" },
                        [
                          _c("date-picker", {
                            attrs: { name: "update_date" },
                            model: {
                              value: _vm.appUpdate.update_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.appUpdate, "update_date", $$v)
                              },
                              expression: "appUpdate.update_date",
                            },
                          }),
                          _vm._v(" "),
                          _c("show-errors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors.form,
                              errorKey: "update_date",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("div", { staticClass: "col-md-4 pt-2" }, [
                        _c("label", { attrs: { for: "description" } }, [
                          _vm._v("Description "),
                          _c("small", [_vm._v("*")]),
                        ]),
                        _c("br"),
                        _vm._v(" "),
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v(
                            "Cette description sera affichée sur la page d'accueil des notes de mises à jour."
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-8" },
                        [
                          _c("textarea-component", {
                            attrs: {
                              name: "description",
                              rows: 3,
                              "min-nb-chars": 20,
                              "max-nb-chars": 250,
                            },
                            model: {
                              value: _vm.appUpdate.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.appUpdate, "description", $$v)
                              },
                              expression: "appUpdate.description",
                            },
                          }),
                          _vm._v(" "),
                          _c("show-errors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors.form,
                              errorKey: "description",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("div", { staticClass: "col-md-4 pt-2" }, [
                        _c("label", { attrs: { for: "modules" } }, [
                          _vm._v("Modules concernés"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-8" },
                        [
                          _c("vue-multiselect", {
                            attrs: {
                              options: _vm.availableModules,
                              "max-height": 300,
                              multiple: true,
                              label: "label",
                              "track-by": "value",
                              placeholder: "Modules concernés",
                              searchable: false,
                              "close-on-select": false,
                              "show-labels": false,
                              "open-direction": "bottom",
                            },
                            model: {
                              value: _vm.selectedModules,
                              callback: function ($$v) {
                                _vm.selectedModules = $$v
                              },
                              expression: "selectedModules",
                            },
                          }),
                          _vm._v(" "),
                          _c("show-errors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors.form,
                              errorKey: "modules",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-4 pt-2" }, [
                        _c("label", { attrs: { for: "content" } }, [
                          _vm._v("Contenu "),
                          _c("small", [_vm._v("*")]),
                        ]),
                        _c("br"),
                        _vm._v(" "),
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v(
                            "\n                                Le contenu sera affiché dans la page de détails de la note de mise à jour."
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                                Vous pouvez organiser la page avec des titres, des paragraphes, ajouter des liens, des images ... "
                          ),
                        ]),
                        _c("br"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-8" },
                        [
                          _c("editor-with-merge-tags", {
                            attrs: {
                              "allow-fonts": "",
                              "allow-lists": "",
                              "allow-images": "",
                              "allow-info-boxes": "",
                            },
                            model: {
                              value: _vm.appUpdate.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.appUpdate, "content", $$v)
                              },
                              expression: "appUpdate.content",
                            },
                          }),
                          _vm._v(" "),
                          _c("show-errors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors.form,
                              errorKey: "content",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.showSaveButton
                    ? _c("div", { staticClass: "row mt-3 pb-2" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col-12",
                            class:
                              _vm.appUpdate.published_at === null
                                ? ["d-flex", "justify-content-between"]
                                : "text-right",
                          },
                          [
                            _vm.appUpdate.published_at === null
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success btn-circle",
                                    attrs: { type: "submit" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Enregistrer comme brouillon\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-circle",
                                class: _vm.appUpdate.published_at
                                  ? "btn-success"
                                  : "btn-warning",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.submit(true)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.appUpdate.published_at === null
                                        ? "Enregistrer et publier"
                                        : "Enregistrer et republier"
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }