const MENU = {
    value: "menu",
    pinKeyCA: "pinCaPerMenu",
    pinKeyPercent: "pinPercentOfMenuSold",
    translationKeyCA: "CAPerMenu",
    translationKeyPercent: "percentMenusSold",
    endpoint: "sold-menus",
};

const MENU_OPTION = {
    value: "menu_option",
    pinKeyCA: "pinCaPerMenuOption",
    pinKeyPercent: "pinPercentOfMenuOptionSold",
    translationKeyCA: "CAPerMenuOption",
    translationKeyPercent: "percentMenuOptionsSold",
    endpoint: "sold-menu-options",
};

const GENERAL_OPTION = {
    value: "general_option",
    pinKeyCA: "pinCaPerGeneralOption",
    pinKeyPercent: "pinPercentOfGeneralOptionSold",
    translationKeyCA: "CAPerGeneralOption",
    translationKeyPercent: "percentGeneralOptionsSold",
    endpoint: "sold-general-options",
};

const ALL_ENTITIES = [MENU, MENU_OPTION, GENERAL_OPTION];

export { MENU, MENU_OPTION, GENERAL_OPTION, ALL_ENTITIES };
