var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.restaurant_id && _vm.user
    ? _c("div", { staticClass: "nav-btn-2 mx-md-5" }, [
        _c("div", { staticClass: "btn-group" }, [
          _c(
            "button",
            {
              staticClass:
                "btn btn-sm btn-outline-secondary btn-nav-mob btn-circle btn-pre-site",
              attrs: {
                type: "button",
                disabled: !_vm.website_enabled || !_vm.has_right_to_read_config,
              },
              on: { click: _vm.previewWebsite },
            },
            [_vm._v("\n            Pré-visualiser le site\n        ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-success btn-nav-mob btn-circle ml-2",
              attrs: {
                type: "button",
                disabled:
                  !_vm.has_right_to_publish_config ||
                  (_vm.step < 4 && !_vm.already_puslished) ||
                  _vm.isPublishing,
              },
              on: { click: _vm.saveAndPublish },
            },
            [_vm._v("\n            Enregistrer et publier\n        ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-success btn-nav-mob btn-circle ml-2",
              attrs: {
                type: "button",
                disabled: _vm.step < 5 && !_vm.already_puslished,
              },
              on: { click: _vm.viewWebsite },
            },
            [_vm._v("\n            Voir mon site\n        ")]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }