<template>
    <div>
        <div v-if="hasErrors('client_id')">
            <div class="invalid-feedback d-block" v-for="err in formErrors.errors.client_id">
                {{ err }}
            </div>
        </div>
        <div class="row mb-2 radio">
            <div class="col-md-4 d-none d-md-block pt-2">
                <label>{{ $tl("labels.form.civility") }}</label>
            </div>
            <div class="col-md-8 pt-2">
                <div v-if="readonly">
                    <label class="container-box">
                        <input
                            type="radio"
                            name="civility"
                            :value="CLIENT_CIVILITY_MR.value"
                            tabindex="5"
                            readonly
                            disabled="true"
                            v-model="clients.civility" />
                        <span class="checkmark"></span>
                        {{ capitalize(getClientCivilityLabelLong(CLIENT_CIVILITY_MR.value)) }}
                    </label>
                    <label class="container-box">
                        <input
                            type="radio"
                            name="civility"
                            readonly
                            :value="CLIENT_CIVILITY_MME.value"
                            disabled="true"
                            tabindex="6"
                            v-model="clients.civility" />
                        <span class="checkmark"></span>
                        {{ capitalize(getClientCivilityLabelLong(CLIENT_CIVILITY_MME.value)) }}
                    </label>
                </div>
                <div v-else>
                    <label class="container-box">
                        <input
                            type="radio"
                            name="civility"
                            :value="CLIENT_CIVILITY_MR.value"
                            tabindex="5"
                            v-model="clients.civility"
                            @click="$refs.firstname.focus()" />
                        <span class="checkmark"></span>
                        {{ capitalize(getClientCivilityLabelLong(CLIENT_CIVILITY_MR.value)) }}
                    </label>
                    <label class="container-box">
                        <input
                            type="radio"
                            name="civility"
                            :value="CLIENT_CIVILITY_MME.value"
                            tabindex="6"
                            v-model="clients.civility"
                            @click="$refs.firstname.focus()" />
                        <span class="checkmark"></span>
                        {{ capitalize(getClientCivilityLabelLong(CLIENT_CIVILITY_MME.value)) }}
                    </label>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4 d-none d-md-block pt-2">
                <label>{{ $tl("labels.form.firstname") }}</label>
            </div>
            <div class="col-md-8">
                <div class="dropright add-resa-search-client">
                    <div v-if="readonly">
                        <input
                            type="text"
                            :placeholder="$tl('labels.form.firstname')"
                            :class="{ 'is-invalid': hasErrors('firstname') || hasErrors('client_id') }"
                            name="firstname"
                            v-model="clients.firstname"
                            readonly
                            autocomplete="off"
                            class="form-control"
                            tabindex="7"
                            @input="searchClientByMultiField"
                            @focus="clients.firstnameHasFocus = true"
                            @blur="clients.firstnameHasFocus = false" />
                    </div>
                    <div v-else>
                        <input
                            type="text"
                            :placeholder="$tl('labels.form.firstname')"
                            :class="{ 'is-invalid': hasErrors('firstname') || hasErrors('client_id') }"
                            name="firstname"
                            ref="firstname"
                            v-model="clients.firstname"
                            autocomplete="off"
                            class="form-control"
                            tabindex="7"
                            @input="searchClientByMultiField"
                            @focus="clients.firstnameHasFocus = true"
                            @blur="clients.firstnameHasFocus = false" />
                    </div>
                    <div
                        @mouseover="clients.search.firstnameHasFocus = true"
                        @mouseout="clients.search.firstnameHasFocus = false"
                        class="dropdown-menu prename-field shadow show p-0"
                        v-if="
                            (clients.firstnameHasFocus || clients.search.firstnameHasFocus) &&
                            clients.search.results &&
                            clients.search.results.length > 0
                        ">
                        <ul class="list-group">
                            <li class="list-group-item p-0" v-for="result in clients.search.results">
                                <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                    <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                    <span>{{ result.tel }}</span>
                                    <span class="email-search"
                                        ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                    >
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="hasErrors('firstname')">
                        <div class="invalid-feedback d-block" v-for="err in formErrors.errors.firstname">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4 d-none d-md-block pt-2">
                <label>{{ $tl("labels.form.lastname") }}</label>
            </div>
            <div class="col-md-8">
                <div class="dropright add-resa-search-client">
                    <div v-if="readonly">
                        <input
                            type="text"
                            :placeholder="$tl('labels.form.lastname')"
                            name="lastname"
                            :class="{ 'is-invalid': hasErrors('lastname') || hasErrors('client_id') }"
                            v-model="clients.lastname"
                            autocomplete="off"
                            readonly
                            class="form-control"
                            tabindex="8"
                            @input="searchClientByMultiField"
                            @focus="clients.lastnameHasFocus = true"
                            @blur="clients.lastnameHasFocus = false" />
                    </div>
                    <div v-else>
                        <input
                            type="text"
                            :placeholder="$tl('labels.form.lastname')"
                            name="lastname"
                            :class="{ 'is-invalid': hasErrors('lastname') || hasErrors('client_id') }"
                            v-model="clients.lastname"
                            autocomplete="off"
                            class="form-control"
                            tabindex="8"
                            @input="searchClientByMultiField"
                            @focus="clients.lastnameHasFocus = true"
                            @blur="clients.lastnameHasFocus = false" />
                    </div>
                    <div
                        @mouseover="clients.search.lastnameHasFocus = true"
                        @mouseout="clients.search.lastnameHasFocus = false"
                        class="dropdown-menu name-field shadow show p-0"
                        v-if="
                            (clients.lastnameHasFocus || clients.search.lastnameHasFocus) &&
                            clients.search.results &&
                            clients.search.results.length > 0
                        ">
                        <ul class="list-group">
                            <li class="list-group-item p-0" v-for="result in clients.search.results">
                                <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                    <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                    <span>{{ result.tel }}</span>
                                    <span class="email-search"
                                        ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                    >
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="hasErrors('lastname')">
                        <div class="invalid-feedback d-block" v-for="err in formErrors.errors.lastname">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4 d-none d-md-block pt-2">
                <label>{{ $tl("labels.form.company") }}</label>
            </div>
            <div class="col-md-8">
                <div class="dropright add-resa-search-client">
                    <div v-if="readonly">
                        <input
                            type="text"
                            :placeholder="$tl('labels.form.company')"
                            name="lastname"
                            :class="{ 'is-invalid': hasErrors('company') || hasErrors('client_id') }"
                            v-model="clients.company"
                            autocomplete="off"
                            readonly
                            class="form-control"
                            tabindex="8"
                            @input="searchClientByMultiField"
                            @focus="clients.companyHasFocus = true"
                            @blur="clients.companyHasFocus = false" />
                    </div>
                    <div v-else>
                        <input
                            type="text"
                            :placeholder="$tl('labels.form.business')"
                            name="lastname"
                            :class="{ 'is-invalid': hasErrors('company') || hasErrors('client_id') }"
                            v-model="clients.company"
                            autocomplete="off"
                            class="form-control"
                            tabindex="8"
                            @input="searchClientByMultiField"
                            @focus="clients.companyHasFocus = true"
                            @blur="clients.companyHasFocus = false" />
                    </div>
                    <div
                        @mouseover="clients.search.companyHasFocus = true"
                        @mouseout="clients.search.companyHasFocus = false"
                        class="dropdown-menu company-field shadow show p-0"
                        v-if="
                            (clients.companyHasFocus || clients.search.companyHasFocus) && clients.search.results && clients.search.results.length > 0
                        ">
                        <ul class="list-group">
                            <li class="list-group-item p-0" v-for="result in client.search.results">
                                <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                    <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                    <span>{{ result.tel }}</span>
                                    <span class="email-search"
                                        ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                    >
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="hasErrors('company')">
                        <div class="invalid-feedback d-block" v-for="err in formErrors.errors.company">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4 d-none d-md-block pt-2">
                <label>{{ $tl("labels.form.email") }}</label>
            </div>
            <div class="col-md-8">
                <div class="dropright add-resa-search-client">
                    <div v-if="readonly">
                        <input
                            type="text"
                            :placeholder="$tl('labels.form.email')"
                            name="email"
                            v-model="clients.email"
                            :class="{ 'is-invalid': hasErrors('email') || hasErrors('client_id') }"
                            autocomplete="off"
                            readonly
                            class="form-control"
                            tabindex="9"
                            @focus="clients.emailHasFocus = true"
                            @input="searchClientByMultiField"
                            @blur="clients.emailHasFocus = false" />
                    </div>
                    <div v-else>
                        <input
                            type="text"
                            :placeholder="$tl('labels.form.email')"
                            name="email"
                            v-model="clients.email"
                            :class="{ 'is-invalid': hasErrors('email') || hasErrors('client_id') }"
                            autocomplete="off"
                            class="form-control"
                            tabindex="9"
                            @focus="clients.emailHasFocus = true"
                            @input="searchClientByMultiField"
                            @blur="clients.emailHasFocus = false" />
                    </div>
                    <div
                        @mouseover="clients.search.emailHasFocus = true"
                        @mouseout="clients.search.emailHasFocus = false"
                        class="dropdown-menu email-field shadow show p-0"
                        v-if="(clients.emailHasFocus || clients.search.emailHasFocus) && clients.search.results && clients.search.results.length > 0">
                        <ul class="list-group">
                            <li class="list-group-item p-0" v-for="result in client.search.results">
                                <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                    <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                    <span>{{ result.tel }}</span>
                                    <span class="email-search"
                                        ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                    >
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="hasErrors('email')">
                        <div class="invalid-feedback d-block" v-for="err in formErrors.errors.email">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 d-none d-md-block pt-2">
                <label>{{ $tl("labels.form.phoneNumber") }}</label>
            </div>
            <div class="col-md-8">
                <div class="dropright add-resa-search-client">
                    <div v-if="readonly">
                        <vue-tel-input
                            name="telInput"
                            :defaultCountry="defaultTelCountry"
                            @onValidate="setPhoneAndCountry"
                            @onInput="searchClientByMultiField"
                            ref="vueTelInput"
                            :class="
                                hasErrors('phoneNumber') ||
                                hasErrors('phoneCountry') ||
                                hasErrors('client_id') ||
                                (client.phone.value !== null && client.phone.isValid === false)
                                    ? ['form-control', 'is-invalid', 'd-flex', 'text-left']
                                    : ['form-control', 'd-flex', 'text-left']
                            "
                            :disabled="true"
                            v-model="clients.phone.raw"
                            :enabledCountryCode="true"
                            :inputOptions="{ tabindex: 10, autocomplete: 'off' }"
                            mode="international"
                            inputClasses="m-0 border-0"
                            :placeholder="$tl('labels.form.phoneNumber')"></vue-tel-input>
                    </div>
                    <div v-else>
                        <vue-tel-input
                            :defaultCountry="defaultTelCountry"
                            name="telInput"
                            @onValidate="setPhoneAndCountry"
                            @onInput="searchClientByMultiField"
                            ref="vueTelInput"
                            :class="
                                hasErrors('phoneNumber') ||
                                hasErrors('phoneCountry') ||
                                hasErrors('client_id') ||
                                (client.phone.value !== null && client.phone.isValid === false)
                                    ? ['form-control', 'is-invalid', 'd-flex', 'text-left']
                                    : ['form-control', 'd-flex', 'text-left']
                            "
                            v-model="clients.phone.raw"
                            :enabledCountryCode="true"
                            :inputOptions="{ tabindex: 10, autocomplete: 'off' }"
                            mode="international"
                            inputClasses="m-0 border-0"
                            :placeholder="$tl('labels.form.phoneNumber')"></vue-tel-input>
                    </div>
                    <div
                        @mouseover="clients.search.phoneNumberHasFocus = true"
                        @mouseout="clients.search.phoneNumberHasFocus = false"
                        class="dropdown-menu phone-field shadow show p-0"
                        v-if="
                            (clients.phoneNumberHasFocus || clients.search.phoneNumberHasFocus) &&
                            clients.search.results &&
                            clients.search.results.length > 0
                        ">
                        <ul class="list-group">
                            <li class="list-group-item p-0" v-for="result in clients.search.results">
                                <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                    <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                    <span>{{ result.tel }}</span>
                                    <span class="email-search"
                                        ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                    >
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="hasErrors('phoneNumber') || hasErrors('phoneCountry') || hasErrors('client_id')" class="invalid-feedback d-block">
                        <div v-if="hasErrors('phoneNumber')">
                            <div class="invalid-feedback d-block" v-for="err in formErrors.errors.phoneNumber">
                                {{ err }}
                            </div>
                        </div>
                        <div v-else-if="hasErrors('phoneCountry')">
                            <div class="invalid-feedback d-block" v-for="err in formErrors.errors.phoneCountry">
                                {{ err }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="add-resa-search-client" v-if="clients.phone.search.results !== null && clients.id === null">
            <div v-if="clients.phone.search.results.length > 0">
                <span class="text-danger d-inline-block mt-2 mb-2">
                    {{ $tl("labels.clients.alreadyExist") }}<br />{{ $tl("infos.booking.clients.alreadyExist") }}
                </span>
                <li class="list-group-item p-0">
                    <a class="d-block p-3" href="javascript:" @click="selectClient(clients.phone.search.results[0])">
                        <span>
                            {{ clients.phone.search.results[0].firstname }}
                            {{ clients.phone.search.results[0].lastname }}
                        </span>
                        |
                        <span>
                            {{ clients.phone.search.results[0].tel }}
                        </span>
                        <span class="email-search"
                            ><span v-if="clients.phone.search.results[0].company">{{ clients.phone.search.results[0].company }} |</span
                            >{{ clients.phone.search.results[0].email }}</span
                        >
                    </a>
                </li>
            </div>
            <div v-else>
                <span class="d-inline-block mt-2 mb-2 text-muted">{{ $tl("infos.booking.clients.willBeCreated") }}</span>
            </div>
        </div>
        <div v-if="readonly">
            <button @click="redirectToClient" :disabled="clients.is_deleted" type="button" class="btn btn-success btn-circle btn-sm mt-3">
                {{ $tl("labels.clients.edit") }}
            </button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import ClientCivilityEnum from "../../mixins/enums/ClientCivilityEnum";

export default {
    name: "clientsReservations",
    data() {
        return this.$_.cloneDeep(
            this.$_.defaultsDeep(this.defaultValues, {
                loading: true,
                clients: this.client || {
                    id: null,
                    civility: null,
                    firstname: null,
                    lastname: null,
                    company: null,
                    email: null,
                    phone: {
                        raw: null,
                        isValid: null,
                        country: null,
                        value: null,
                        search: {
                            results: null,
                        },
                    },
                    firstnameHasFocus: false,
                    lastnameHasFocus: false,
                    companyHasFocus: false,
                    emailHasFocus: false,
                    phoneNumberHasFocus: false,
                    search: {
                        results: null,
                        errors: null,
                        isLoading: false,
                        firstnameHasFocus: false,
                        lastnameHasFocus: false,
                        companyHasfocus: false,
                        emailHasFocus: false,
                        phoneNumberHasFocus: false,
                    },
                },
            })
        );
    },
    mixins: [ClientCivilityEnum],
    props: {
        service: {
            default: null,
        },
        restaurant_id: {
            default: null,
        },
        client: {
            default: null,
        },
        readonly: {
            default: false,
        },
        formErrors: {
            default: () => {},
        },
    },
    computed: {
        defaultTelCountry() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id).default_tel_country;
        },
    },
    mounted() {
        this.loading = false;
    },
    updated() {
        if (this.$refs.vueTelInput) {
            this.$refs.vueTelInput.$el.classList.remove("vue-tel-input");
            this.$refs.vueTelInput.$refs.input.addEventListener("focus", this.onVueTelInputFocus);
            this.$refs.vueTelInput.$refs.input.addEventListener("blur", this.onVueTelInputBlur);
        }
    },
    methods: {
        emptyForm() {
            this.nb_pers = null;
            this.nb_children = null;
            this.client_id = null;
            this.civility = null;
            this.firstname = null;
            this.lastname = null;
            this.email = null;
            this.phone.raw = null;
            this.restaurant_comment = null;
            this.gift = null;
            this.num_table = null;
            this.slot_id = null;
            this.menus_sent = [];
            this.choosen_menus = {};
            this.available_menus = [];
            this.old_nb_pers = null;
            this.slot_id = null;
        },
        redirectToClient() {
            this.$router.push({
                name: "booking.clients.form",
                params: {
                    client_id: this.clients.id,
                },
            });
        },
        searchClientByMultiField() {
            this.clients.search.errors = [];
            this.clients.search.isLoading = true;
            var params = {};

            if (this.clients.firstname && this.clients.firstname !== "") {
                params.firstname = this.clients.firstname;
            }

            if (this.clients.lastname && this.clients.lastname !== "") {
                params.lastname = this.clients.lastname;
            }

            if (this.clients.email && this.clients.email !== "") {
                params.email = this.clients.email;
            }

            if (this.clients.phone.raw && this.clients.phone.raw !== "") {
                params.phone_number = this.clients.phone.raw;
            }

            if (this.clients.company && this.clients.company !== "") {
                params.company = this.clients.company;
            }

            axios
                .get(`/api/restaurants/${this.restaurant_id}/clients/search/multi`, {
                    params,
                })
                .then((response) => {
                    this.clients.search.results = response.data.data;
                    this.clients.search.isLoading = false;

                    if (!this.clients.search.results || (this.clients.search.results.length === 0 && this.clients.phone.isValid)) {
                        axios
                            .get(`/api/restaurants/${this.restaurant_id}/clients/search/phone`, {
                                params: {
                                    phoneNumber: this.clients.phone.value.international,
                                    phoneCountry: this.clients.phone.country,
                                },
                            })
                            .then((response) => {
                                this.clients.phone.search.results = response.data.data;
                            })
                            .catch((error) => {});
                    }
                })
                .catch((err) => {});
        },
        setPhoneAndCountry(phoneObject) {
            if (phoneObject.number && phoneObject.isValid !== undefined && phoneObject.country) {
                this.clients.phone.isValid = phoneObject.isValid;

                if (this.clients.phone.isValid) {
                    this.clients.phone.value = phoneObject.number;

                    if (phoneObject.regionCode && phoneObject.regionCode !== "") {
                        this.clients.phone.country = phoneObject.regionCode;
                    } else {
                        this.clients.phone.country = phoneObject.country.iso2;
                    }

                    if (!this.clients.search.results || this.clients.search.results.length < 1) {
                        axios
                            .get(`/api/restaurants/${this.restaurant_id}/clients/search/phone`, {
                                params: {
                                    phoneNumber: this.clients.phone.value.international,
                                    phoneCountry: this.clients.phone.country,
                                },
                            })
                            .then((response) => {
                                this.clients.phone.search.results = response.data.data;
                            })
                            .catch((error) => {});
                    }
                } else {
                    this.clients.phone.search.results = null;
                }
            } else {
                this.clients.phone.isValid = false;
                this.clients.phone.search.results = null;
            }
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
        selectClient: function (client) {
            this.$set(
                this,
                "client",
                Object.assign(this.clients, {
                    id: client.id,
                    civility: client.civility,
                    firstname: client.firstname,
                    lastname: client.lastname,
                    company: client.company,
                    email: client.email,
                    phone: {
                        raw: client.tel,
                        isValid: null,
                        country: client.tel_country,
                        value: null,
                        search: {
                            results: null,
                        },
                    },
                })
            );
            this.clients.search.companyHasFocus = false;
            this.clients.search.firstnameHasFocus = false;
            this.clients.search.lastnameHasFocus = false;
            this.clients.search.emailHasFocus = false;
            this.clients.search.phoneNumberHasFocus = false;
        },
        onVueTelInputFocus() {
            this.client.phoneNumberHasFocus = true;
        },
        onVueTelInputBlur() {
            this.client.phoneNumberHasFocus = false;
        },
    },
    watch: {
        clients: {
            handler: function (value, oldVal) {
                this.$emit("update-client", value);
            },
            deep: true,
        },
    },
};
</script>
