import { render, staticRenderFns } from "./ClickAndCollectInputParams.vue?vue&type=template&id=af6d3704"
import script from "./ClickAndCollectInputParams.vue?vue&type=script&lang=js"
export * from "./ClickAndCollectInputParams.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('af6d3704')) {
      api.createRecord('af6d3704', component.options)
    } else {
      api.reload('af6d3704', component.options)
    }
    module.hot.accept("./ClickAndCollectInputParams.vue?vue&type=template&id=af6d3704", function () {
      api.rerender('af6d3704', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/ClickAndCollect/Settings/ClickAndCollectInputParams.vue"
export default component.exports