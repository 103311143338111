export default {
    data() {
        return {
            SALE_PAID: {
                value: "paid",
                class: "text-success",
                label: "payé",
                labelInfinitif: "payer",
            },
            SALE_CANCELED: {
                value: "canceled",
                class: "text-danger",
                label: "annulé",
                labelInfinitif: "annuler",
            },
            SALE_PENDING: {
                value: "pending",
                class: "text-warning",
                label: "en attente",
                labelInfinitif: "en attente",
            },
            SALE_REFUND: {
                value: "refund",
                class: "text-warning",
                label: "remboursé",
                labelInfinitif: "rembourser",
            },
            SALE_CAPTURABLE: {
                value: "capturable",
                class: "text-success",
                label: "payé",
                labelInfinitif: "payer",
            },
            SALE_CAPTURED: {
                value: "captured",
                class: "text-success",
                label: "payé",
                labelInfinitif: "payer",
            },
            SALE_EXPIRED: {
                value: "expired",
                class: "text-danger",
                label: "expiré",
                labelInfinitif: "expirer",
            },
            SALE_USED: {
                value: "used",
                label: "utilisé",
            },
            SALE_UNUSED: {
                value: "unused",
                label: "non utilisé",
            },
        };
    },
    methods: {
        getSaleStatusLabel(value) {
            const found = this.ALL_SALE_STATUS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
        getSaleStatusLabelInfinitif(value) {
            const found = this.ALL_SALE_STATUS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.labelInfinitif;
            return value;
        },
        getSaleStatusClass(value) {
            const found = this.ALL_SALE_STATUS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.class;
            return value;
        },
    },
    computed: {
        ALL_SALE_STATUS() {
            return [
                this.SALE_CANCELED,
                this.SALE_PAID,
                this.SALE_PENDING,
                this.SALE_REFUND,
                this.SALE_CAPTURABLE,
                this.SALE_CAPTURED,
                this.SALE_EXPIRED,
                this.SALE_USED,
                this.SALE_UNUSED,
            ];
        },
        SALE_STATUS_FOR_FILTER() {
            return [this.SALE_CANCELED, this.SALE_PAID, this.SALE_REFUND, this.SALE_EXPIRED];
        },
        SALE_STATUS_FOR_FILTER_FOR_SALES() {
            return [this.SALE_USED, this.SALE_UNUSED, this.SALE_EXPIRED];
        },
    },
};
