export default {
    methods: {
        calculateHorizontalPxGap(startDateTime, endDateTime, currentDateTime, cellWidth, minutesPerCell, initialGap = 0) {
            if (startDateTime > currentDateTime || endDateTime < currentDateTime || minutesPerCell === 0) {
                return null;
            }

            const interval = currentDateTime.diff(startDateTime).as("minutes");

            return Math.ceil(initialGap + (interval * cellWidth) / minutesPerCell);
        },
    },
};
