<template>
    <div class="reservation-vue" style="height: calc(100vh - 130px) !important">
        <LoaderComponent v-if="loading" />
        <div class="h-100" v-else>
            <div v-if="error" class="error">
                <em>{{ error }}</em
                ><br />
                <router-link
                    v-if="errorCode === 'noServiceFound'"
                    :to="{
                        name: 'booking.restaurants.settings.services.add',
                        params: { restaurant_id: $route.params.restaurant_id },
                    }"
                    class="btn btn-sm btn-success btn-circle"
                    style="margin-top: 15px">
                    {{ $tl("labels.booking.services.createFirst", restaurant_id) }}
                </router-link>
            </div>
            <div class="h-100" v-else>
                <div class="row m-0 h-100">
                    <div class="col-12 h-100 p-0">
                        <div class="h-100" v-if="restaurant">
                            <div class="d-inline align-middle w-100">
                                <span v-if="!isLive && isInFuture" class="text-uppercase text-center align-middle d-block off-air">
                                    {{ $tl("labels.booking.services.next", restaurant_id) }}
                                </span>

                                <span v-if="isLive" class="text-uppercase text-center align-middle d-block on-air">
                                    {{ $tl("labels.booking.services.live", restaurant_id) }}
                                </span>
                            </div>

                            <div class="d-flex flex-wrap justify-content-between flex-md-nowrap mb-2 pt-2 pl-3 pr-3">
                                <div class="d-flex align-items-center">
                                    <h6 style="text-transform: uppercase" class="pt-1">
                                        {{ displayDate(meta.date, DATE_MED_DAY_NO_YEAR) }}
                                        <span v-if="services.length === 1" class="capacite badge badge-secondary">
                                            {{ getServiceCategoryLabel(meta) }}
                                        </span>
                                    </h6>

                                    <show-custom-events-btn class="none-mobile ml-2" :restaurantId="restaurant.id" :date="meta.date">
                                    </show-custom-events-btn>
                                </div>
                                <div class="d-inline-block align-middle">
                                    <div class="d-inline-block align-middle mr-2 none-mobile" v-if="hasFeatSeatingPlan">
                                        <toggle-switch
                                            :options="toggleSwitchOptions"
                                            v-model="contentToDisplay"
                                            class="p-0 m-0 d-inline-flex align-middle" />
                                    </div>

                                    <button
                                        v-if="restaurant.notifications_setting.enable_qr_code == true"
                                        type="button"
                                        class="btn radius-btn-square btn-success scan-btn"
                                        style="padding-top: 3px; padding-left: 4px"
                                        @click="showScanReservationModal = true"
                                        v-tooltip="getTooltip($t('labels.booking.reservations.actions.scan'))">
                                        <feather type="aperture" class="text-white" />
                                    </button>
                                </div>
                            </div>
                            <div class="h-100 d-flex flex-column">
                                <div v-if="services.length > 0" class="h-100">
                                    <live-reservations-per-service
                                        v-if="contentToDisplay === $t('labels.booking.list.title')"
                                        @show-service-full="$parent.$emit('show-service-full', $event)"
                                        :services="services"
                                        :restaurant="restaurant"
                                        @reservation-updated="onReservationUpdated({ reservation: $event })" />
                                    <seating-plan-per-service
                                        v-if="contentToDisplay === $t('labels.booking.seatingPlan.title')"
                                        @add-reservation="displayAddReservationModal"
                                        @add-passing-customer="displayAddReservationModal($event, true)"
                                        :date="meta.date"
                                        :services="services"
                                        :restaurant="restaurant"
                                        :reservation-added="reservationAdded"
                                        :reservation-updated="reservationUpdated"
                                        :addDataKeyToReservationsList="true"
                                        @go-to-resa-list="contentToDisplay = $t('labels.booking.list.title')" />
                                    <timeline-per-service
                                        v-if="contentToDisplay === $t('labels.booking.timeline.title')"
                                        @add-reservation="displayAddReservationModal"
                                        @add-passing-customer="displayAddReservationModal($event, true)"
                                        :services="services"
                                        :restaurant="restaurant"
                                        :reservation_date="meta.date"
                                        @go-to-resa-list="contentToDisplay = $t('labels.booking.list.title')" />
                                </div>
                                <div v-else>
                                    <em>{{ $tl("labels.booking.restaurant.closedForSomeService", restaurant_id) }}</em>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <scan-reservation-modal
            v-if="showScanReservationModal"
            :reservations="reservations"
            ref="scanResaModal"
            @close="showScanReservationModal = false"
            @validate-resa="setResaOver($event)" />

        <add-reservation-modal
            v-if="showAddReservationModal && has_right_to_create_reservation"
            :tablesIdToPreselect="selected_tables_id"
            @reservation-saved="showAddReservationModal = false"
            :service_id="selected_service_id"
            :slot_id="selected_slot_id"
            :date="selected_reservation_date"
            :restaurant_id="selected_restaurant_id"
            @close="showAddReservationModal = false" />

        <add-passing-customer-modal
            v-if="showAddPassingCustomerModal && has_right_to_create_reservation"
            :tablesIdToPreselect="selected_tables_id"
            @saved="showAddPassingCustomerModal = false"
            :serviceId="selected_service_id"
            :restaurantId="Number.parseInt(selected_restaurant_id)"
            :date="selected_reservation_date"
            @close="showAddPassingCustomerModal = false" />
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../components/LoaderComponent.vue";
import LiveReservationsPerService from "../../components/reservations/LiveReservationsPerService.vue";
import SeatingPlanPerService from "../../components/seatingPlan/SeatingPlanPerService.vue";
import TimelinePerService from "../../components/reservations/TimelinePerService.vue";
import ToggleSwitch from "vuejs-toggle-switch";
import ScanReservationModal from "../../components/Modals/reservations/ScanReservationModal.vue";
import AddReservationModal from "../../components/Modals/reservations/AddReservationModalNew.vue";
import LangsEnum from "../../mixins/enums/LangsEnum.js";
import LexiconEnum from "../../mixins/enums/LexiconEnum.js";
import showCustomEventsBtn from "../../components/customEvents/showCustomEventsBtn.vue";
import AddPassingCustomerModal from "../../components/Modals/reservations/AddPassingCustomerModal.vue";

export default {
    data() {
        return {
            loading: false,
            error: null,
            errorCode: null,
            restaurant: {},
            services: [],
            selected_reservation_id: null,
            selected_reservation: null,
            selected_restaurant_id: null,
            selected_service_id: null,
            selected_service_ref: null,
            selected_reservation_date: null,
            selected_tables_id: [],
            selected_slot_id: null,
            nb_max_pers: null,
            amount_if_noshow: null,
            showReservationModal: false,
            showRemoveServiceFullModal: false,
            showEditReservationModal: false,
            showSetNoshowModal: false,
            showScanReservationModal: false,
            reservation: null,
            isDisplay: false,
            isDisplayBtn: true,
            meta: {
                date: null,
                startAt: null,
                endsAt: null,
                category: null,
            },
            toggleSwitchOptions: {
                layout: {
                    color: "#30a4b7",
                    fontWeightSelected: "normal",
                    backgroundColor: "white",
                    selectedColor: "white",
                    selectedBackgroundColor: "#30a4b7",
                    borderColor: "#30a4b7",
                },
                size: {
                    fontSize: 0.8,
                    height: 1.8,
                    padding: 0.25,
                    width: 20,
                },
                items: {
                    delay: 0.4,
                    preSelected: this.$tl("labels.booking.list.title"),
                    labels: [
                        { name: this.$tl("labels.booking.list.title") },
                        { name: this.$tl("labels.booking.seatingPlan.title") },
                        { name: this.$tl("labels.booking.timeline.title") },
                    ],
                },
            },
            contentToDisplay: null, // ('Liste' | 'Plan de salle' | 'Timeline')
            showAddReservationModal: false,
            reservationAdded: {},
            reservationUpdated: {},
            showAddPassingCustomerModal: false,
        };
    },
    mixins: [LangsEnum, LexiconEnum],
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_reservation() {
            return this.rights.includes("booking.booking.create");
        },
        reservations() {
            let result = [];

            for (const service of this.services) {
                result = result.concat(service.reservations.data);
            }

            return result;
        },
        hasReservations() {
            for (const index in this.services) {
                if (this.services[index].reservations.data && this.services[index].reservations.data.length > 0) {
                    return true;
                }
            }

            return false;
        },
        isLive() {
            const now = this.getDateTime();

            return this.meta.startAt <= now && now <= this.meta.endsAt;
        },
        isInFuture() {
            const now = this.getDateTime();

            return now < this.meta.startAt;
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        widget() {
            return this.$store.getters["widgets/getWidget"];
        },
        hasFeatSeatingPlan() {
            return this.restaurant.feat_seating_plan || false;
        },
    },
    methods: {
        displayDefaultView() {
            const label = this.widget ? this.widget.default_booking_view : "list";
            this.contentToDisplay = this.$t(`labels.booking.${label}.title`);
            this.toggleSwitchOptions.items.preSelected = this.$t(`labels.booking.${label}.title`);
        },
        fetchData() {
            this.loading = true;
            this.unregisterAllSockets();
            return this.fetchRestaurant();
        },
        fetchRestaurant() {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/restaurants/${this.restaurant_id}?include=notifications_setting`)
                    .then((response) => {
                        this.restaurant = response.data;
                        this.fetchServices()
                            .then((resolved) => {
                                resolve(resolved);
                            })
                            .catch((rejected) => {
                                reject(rejected);
                            });
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.error = this.getErrorMsgFromErrorResponse(error);
                        reject(error);
                    });
            });
        },
        fetchServices() {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `/api/restaurants/${this.restaurant_id}/services/live?include=reservations.payment,reservations.client,reservations.slot,reservations.tables`
                    )
                    .then((response) => {
                        this.$set(this, "services", response.data.data ?? []);
                        this.$set(this.meta, "date", this.getDateTime(response.data.meta.date, false));
                        this.$set(this.meta, "startAt", this.getDateTime(response.data.meta.start_at));
                        this.$set(this.meta, "endsAt", this.getDateTime(response.data.meta.ends_at));
                        this.$set(this.meta, "category", response.data.meta.category);
                        this.$nextTick(() => {
                            this.registerSocketForServices();
                        });
                        this.loading = false;
                        resolve(response.data.data);
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.error = this.getErrorMsgFromErrorResponse(error);
                        if (error.response && error.response.data) {
                            if (error.response.data.code) {
                                this.errorCode = error.response.data.code;
                            } else {
                                this.errorCode = error.code;
                            }
                        }
                        reject(error);
                    });
            });
        },
        unregisterAllSockets() {
            this.$store.getters["sockets/getChannels"].forEach((channelName) => {
                Echo.leave(channelName);
            });

            this.$store.commit("sockets/removeAllChannels");
        },
        registerSocketForServices() {
            this.services.forEach((service) => {
                const channelName = `App.restaurant.${this.restaurant.id}.service.${service.id}.date.${this.meta.date.toISODate()}`;

                Echo.private(channelName)
                    .listen(".reservation.deleted", this.onReservationDeleted)
                    .listen(".reservation.updated", this.onReservationUpdated)
                    .listen(".reservation.added", this.onReservationAdded)
                    .listen(".service.full.updated", this.onServiceFullChanged);

                this.$store.commit("sockets/addChannel", channelName);
            });
        },
        onReservationDeleted(e) {
            const deletedReservationId = Number.parseInt(e.reservation_id);
            const deletedReservationServiceId = Number.parseInt(e.service_id);

            if (!Number.isNaN(deletedReservationId) && !Number.isNaN(deletedReservationServiceId)) {
                this.services.find((service) => {
                    if (service.id === deletedReservationServiceId && Array.isArray(service.reservations.data)) {
                        const deletedReservationIndex = service.reservations.data.findIndex((reservation) => reservation.id === deletedReservationId);

                        if (deletedReservationIndex !== -1) {
                            service.reservations.data.splice(deletedReservationIndex, 1);
                            return true;
                        }
                    }

                    return false;
                });
            }
        },
        onReservationUpdated(e) {
            let updatedReservation = e.reservation;
            if (typeof updatedReservation !== "undefined") {
                this.reservationUpdated = e.reservation;
                for (let i = 0; i < this.services.length; i++) {
                    let service = this.services[i];

                    if (service.id === updatedReservation.slot.service.id) {
                        if (typeof service.reservations.data === "undefined") {
                            service.reservations.data = [];
                        }

                        for (let j = 0; j < service.reservations.data.length; j++) {
                            const reservation = service.reservations.data[j];

                            if (reservation.id === updatedReservation.id) {
                                updatedReservation.updated = true;
                                this.$set(this.services[i].reservations.data, j, updatedReservation);
                                this.$nextTick(() => {
                                    setTimeout(() => {
                                        updatedReservation.updated = false;
                                        this.$set(this.services[i].reservations.data, j, updatedReservation);
                                    }, 500);
                                });
                                return;
                            }
                        }
                    }
                }

                if (updatedReservation.reservation_date === this.reservation_date) {
                    let service = this.services.find((s) => s.id === updatedReservation.slot.service.id);

                    if (service) {
                        updatedReservation.new = true;

                        if (typeof service.reservations.data === "undefined") {
                            service.reservations.data = [];
                        }

                        var j = service.reservations.data.push(updatedReservation) - 1;

                        this.$nextTick(() => {
                            setTimeout(() => {
                                updatedReservation.new = false;
                                this.$set(service.reservations.data, j, updatedReservation);
                            }, 2000);
                        });
                    }
                }
            }
        },
        onReservationAdded(e) {
            const newReservation = e.reservation;

            if (typeof newReservation !== "undefined" && newReservation.slot && newReservation.slot.service) {
                this.reservationAdded = e.reservation;
                for (let i = 0; i < this.services.length; i++) {
                    const service = this.services[i];
                    if (service.id === newReservation.slot.service.id) {
                        if (!service.reservations.data.find((r) => r.id === newReservation.id)) {
                            newReservation.new = true;
                            let j = this.services[i].reservations.data.findIndex((r) => r.id == newReservation.id);
                            if (j === -1) {
                                j = this.services[i].reservations.data.push(newReservation) - 1;
                            }
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    newReservation.new = false;
                                    this.$set(this.services[i].reservations.data, j, newReservation);
                                }, 500);
                            });
                        }
                    }
                }
            }
        },
        onServiceFullChanged(e) {
            var serviceUpdated = e.service;

            for (let i = 0; i < this.services.length; i++) {
                const service = this.services[i];
                if (service.id === serviceUpdated.id) {
                    this.$set(this.services[i], "is_full", serviceUpdated.is_full);
                }
            }
        },
        setResaOver({ reservation_id, restaurant_id }) {
            axios
                .put(`/api/restaurants/${restaurant_id}/reservations/${reservation_id}/status?status=over`)
                .then(() => {
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: this.$tl("success.booking.reservations.updated"),
                    });
                })
                .catch((error) => {
                    this.$notify({
                        group: "notification",
                        type: "warn",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                })
                .then(() => {
                    if (this.$refs.scanResaModal) {
                        this.$refs.scanResaModal.closeValidatePopUp();
                    }
                });
        },
        displayAddReservationModal(defaultValues, isPassingCustomer = false) {
            if (!this.$_.isEmpty(defaultValues.tablesId)) {
                this.$set(this, "selected_tables_id", defaultValues.tablesId);
            } else {
                this.$set(this, "selected_tables_id", []);
            }

            if (defaultValues.serviceId) {
                this.$set(this, "selected_service_id", defaultValues.serviceId);
                this.$set(this, "selected_slot_id", defaultValues.slotId || null);
            } else {
                this.$set(this, "selected_service_id", null);
                this.$set(this, "selected_slot_id", null);
            }

            this.selected_reservation_date = defaultValues.selectedDate || defaultValues.reservation_date || null;

            this.$nextTick(() => {
                if (isPassingCustomer) {
                    this.showAddPassingCustomerModal = true;
                } else {
                    this.showAddReservationModal = true;
                }
            });
        },
    },
    created() {
        this.selected_restaurant_id = this.restaurant_id;
        this.fetchData();
        this.displayDefaultView();
    },
    beforeDestroy() {
        this.unregisterAllSockets();
    },
    watch: {
        "$route.params.restaurant_id": function (id) {
            this.selected_restaurant_id = this.restaurant_id;
            this.fetchData();
        },
        "$route.query.date": function (id) {
            this.fetchData();
        },
    },
    components: {
        LoaderComponent,
        LiveReservationsPerService,
        SeatingPlanPerService,
        ToggleSwitch,
        TimelinePerService,
        ScanReservationModal,
        AddReservationModal,
        showCustomEventsBtn,
        AddPassingCustomerModal,
    },
};
</script>
