export default {
    data() {
        return {
            ONLINE_PAYMENT_METHOD_STRIPE: {
                label: "Carte bancaire",
                value: "stripe",
                img: "Stripe-no-pad.png",
            },
            ONLINE_PAYMENT_METHOD_EDENRED: {
                label: "Carte TR",
                value: "edenred",
                img: "edenred-squarelogo.png",
            },
            ONLINE_PAYMENT_METHOD_PAYPLUG: {
                label: "Carte bancaire",
                value: "payplug",
                img: "PayPlug-logo-small.png",
            },
        };
    },
    methods: {
        getOnlinePaymentMethodLabel(value) {
            const found = this.ALL_ONLINE_PAYMENT_METHODS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
        getOnlinePaymentMethodImg(value) {
            const found = this.ALL_ONLINE_PAYMENT_METHODS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.img || null;
            return null;
        },
    },
    computed: {
        ALL_ONLINE_PAYMENT_METHODS() {
            return [
                this.ONLINE_PAYMENT_METHOD_STRIPE,
                this.ONLINE_PAYMENT_METHOD_EDENRED,
                this.ONLINE_PAYMENT_METHOD_PAYPLUG,
            ];
        },
    },
};
