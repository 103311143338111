var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "credit-bank p-3 mb-3 bg-light" }, [
    _c("div", [
      _c("span", [_vm._v(_vm._s(_vm.sepa_debit.billing_details.name))]),
      _c("br"),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          _vm._s(_vm.sepa_debit.sepa_debit.country) +
            "XX XXXX XXXX XXXX XXXX XXX" +
            _vm._s(_vm.sepa_debit.sepa_debit.last4[0]) +
            "\n            " +
            _vm._s(_vm.sepa_debit.sepa_debit.last4.substr(1))
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }