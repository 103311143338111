<template>
    <div class="container-fluid h-100 login">
        <div v-if="!isBrowserIE" class="row m-0 h-100 w-100">
            <!-- <nav class="col-md-4 col-lg-4 d-md-block bg-dark sidebar shadow"></nav> -->
            <main class="col-12 ml-sm-auto px-md-5 h-100 d-flex">
                <router-view />
            </main>
        </div>
        <div v-else style="text-align: center">
            <h1>Navigateur non supporté</h1>
            <p>
                Votre navigateur n'est pas compatible avec le logiciel NoShow, veuillez utiliser
                <a href="https://www.google.com/intl/fr_fr/chrome/">Google Chrome</a> ou
                <a href="https://www.mozilla.org/fr/">Mozilla Firefox</a>
            </p>
            <br />
        </div>
    </div>
</template>

<script>
export default {
    name: "PasswordresetLayout",
};
</script>
