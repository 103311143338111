<template>
    <div class="pb-5 auto-website" style="height: initial !important">
        <template v-if="loading || is_publishing">
            <LoaderComponent />
            <div v-if="is_publishing" class="text-center">
                <h5>Votre site est en cours de déploiement. Cette opération peut durer plusieurs minutes.</h5>
            </div>
        </template>
        <div class="ml-2 alert alert-danger" v-else-if="error">{{ error }}</div>
        <template v-else>
            <tab-bar direction="horizontal" side="top" :entries="tabBarEntries" />
            <router-view class="mt-5" v-bind="{ website_settings, website_enabled }" @fetch-data="fetchData" />
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
import TabBar from "../../components/tabBar/TabBar";

export default {
    data() {
        return {
            loading: 0,
            error: null,
            website_settings: null,
        };
    },
    computed: {
        is_publishing() {
            return this.$store.getters["websites/is_publishing"];
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        website_enabled() {
            return this.$store.getters["websites/website_enabled"];
        },
        tabBarEntries() {
            let tabBarEntries = [
                {
                    to: { name: "website.restaurants.settings.domain", param: { restaurant_id: this.restaurant_id } },
                    title: "Domaine",
                    exactUrlMatching: false,
                },
            ];
            if (!this.website_enabled || !this.website_settings.domain_name) return tabBarEntries;
            return tabBarEntries.concat([
                {
                    to: { name: "website.restaurants.settings.seo", param: { restaurant_id: this.restaurant_id } },
                    title: "Référencement",
                    exactUrlMatching: false,
                },
                {
                    to: {
                        name: "website.restaurants.settings.style_guide",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Charte graphique",
                    exactUrlMatching: false,
                },
                {
                    to: {
                        name: "website.restaurants.settings.legal_mentions",
                        param: { restaurant_id: this.restaurant_id },
                    },
                    title: "Mentions légales",
                    exactUrlMatching: false,
                },
            ]);
        },
    },
    methods: {
        fetchData() {
            this.loading++;
            this.error = null;
            this.website_settings = null;

            this.$store
                .dispatch("websites/getWebsiteSettings", { restaurant_id: this.restaurant_id })
                .then((response) => {
                    this.loading--;
                    this.website_settings = response.data.website_settings;
                    if (this.website_settings) {
                        if (this.website_settings.menus.length == 0)
                            for (let i = 1; i <= 3; i++) this.website_settings.menus.push(this.initNewMenu(i));
                        this.website_settings.show_address = this.website_settings.show_address ? false : true;
                    }
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        initNewMenu(nb) {
            return {
                id: null,
                nb: nb,
                title: "",
                price_in_cent: null,
                content: "",
            };
        },
    },
    watch: {
        website_settings: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal && oldVal) this.$store.dispatch("websites/setUnsavedChanges", true);
                this.$store.dispatch("websites/setAllSettings", newVal);
            },
        },
    },
    components: {
        LoaderComponent,
        TabBar,
    },
    created() {
        this.fetchData();
    },
};
</script>
