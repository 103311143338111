<template>
    <div>
        <LoaderComponent v-if="isLoading" />
        <div v-else-if="isConfigMissing" class="row m-0">
            <div class="col-12">
                <span>{{ $tl("labels.booking.inputParams.noConfig") }}</span>
            </div>
        </div>
        <div v-else class="row m-0">
            <div class="col-12">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <strong>{{ $t(`labels.booking.inputParams.fields.chooseMandatory.${module}`) }}</strong>
                        </div>
                    </div>
                    <div v-for="field in fields" :key="field.name" class="row mb-2">
                        <div class="col-md-5 col-10">
                            <label>
                                {{ $t(`labels.form.${field.name}`) }}
                            </label>
                        </div>
                        <div class="col-md-7 col-2">
                            <label class="container-box">
                                <input :disabled="!hasRightToUpdateInputParams" type="checkbox" v-model="field.is_mandatory" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoaderComponent from "../../../../components/LoaderComponent.vue";

export default {
    components: {
        LoaderComponent,
    },
    data() {
        return {
            isLoading: false,
            isConfigMissing: false,
            fields: [],
        };
    },
    computed: {
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        hasRightToUpdateInputParams() {
            return this.rights.includes("default.inputparams.update");
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        module() {
            return this.$route.name.split(".")[0].replaceAll("_", "-");
        },
    },
    created() {
        this.getFields();
    },
    watch: {
        fields: {
            handler(value) {
                this.$emit("update-fields", value);
            },
            deep: true,
        },
    },
    methods: {
        getFields() {
            this.isLoading = true;
            this.httpGet(`/api/restaurants/${this.restaurantId}/reservations/input-params/fields?module=${this.module}`, {
                handleReject: false,
                mustReject: true,
            })
                .then((response) => {
                    this.fields = response.data.map((field) => {
                        return {
                            id: field.id,
                            name: field.field_name,
                            is_mandatory: field.pivot.is_mandatory,
                        };
                    });
                    this.$emit("config-missing", this.isConfigMissing);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.code === "config-missing") {
                        this.isConfigMissing = true;
                    } else {
                        this.notifyError(error);
                    }
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
