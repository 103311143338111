var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      on: {
        close: function ($event) {
          $event.stopPropagation()
          return _vm.closeFromModal($event)
        },
      },
    },
    [
      _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Ajouter une catégorie"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _vm.isLoading
            ? _c("LoaderComponent")
            : _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", [
                          _vm._v("Nom "),
                          _c("small", [_vm._v("*")]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.name,
                                expression: "name",
                              },
                            ],
                            attrs: { type: "text", required: "" },
                            domProps: { value: _vm.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.name = $event.target.value
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("show-errors", {
                            staticClass: "d-block",
                            attrs: {
                              errors: _vm.errors.form,
                              errorKey: "name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "button",
            {
              staticClass:
                "modal-default-button btn btn-sm btn-secondary btn-circle",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Annuler")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-success btn-circle ml-2",
              attrs: { type: "button" },
              on: { click: _vm.saveCategory },
            },
            [_vm._v("Enregistrer")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }