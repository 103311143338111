<template>
    <PercentOfEntitySoldComponent v-bind="$props" :entity="MENU_OPTION" />
</template>

<script>
import PercentOfEntitySoldComponent from "./PercentOfEntitySoldComponent.vue";
import { MENU_OPTION } from "../../utils/stats/revenueEntities.js";

export default {
    props: {
        grid: {
            required: true,
            type: Object,
        },
        filters: {
            required: true,
            type: Object,
        },
    },
    computed: {
        MENU_OPTION() {
            return MENU_OPTION;
        },
    },
    components: {
        PercentOfEntitySoldComponent,
    },
};
</script>
