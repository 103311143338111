var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticStyle: { overflow: "auto" },
      attrs: { modalContainerStyle: "overflow: visible" },
      on: { close: _vm.closeFromModal },
    },
    [
      _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(
          _vm._s(
            _vm.$tl(`labels.booking.reservations.${_vm.status}Modal.title`)
          )
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _vm.isLoading
            ? _c("loader-component")
            : [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$tl(
                        `labels.booking.reservations.${_vm.status}Modal.ask`
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("switch-input-component", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.getTooltip(_vm.getLabel),
                      expression: "getTooltip(getLabel)",
                    },
                  ],
                  attrs: { disabled: _vm.forceSilent || _vm.forceVerbose },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  `labels.booking.reservations.${_vm.status}Modal.sendEmail`
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.canSendMail,
                    callback: function ($$v) {
                      _vm.canSendMail = $$v
                    },
                    expression: "canSendMail",
                  },
                }),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex w-100 justify-content-between",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary btn-circle btn btn-sm",
              attrs: { type: "button", disabled: _vm.isLoading },
              on: { click: _vm.close },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-success btn-sm btn-circle",
              attrs: { type: "button", disabled: _vm.isLoading },
              on: { click: _vm.cancelReservation },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$tl("labels.form.actions.validate")) +
                  "\n        "
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }