var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("LoaderComponent")
    : _c("div", [
        _vm.error
          ? _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v("\n        " + _vm._s(_vm.error) + "\n    "),
            ])
          : _vm.reservation
          ? _c("div", { staticClass: "div-valid-resa" }, [
              _c("div", { staticClass: "row m-0" }, [
                _c("div", { staticClass: "col-lg-8 offset-lg-2 mb-3" }, [
                  _vm.reservation.status ===
                  _vm.RESERVATION_STATUS_CANCELED.value
                    ? _c(
                        "div",
                        {
                          staticClass: "bg-danger text-center text-white p-1",
                          staticStyle: { "border-radius": "100px" },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$tl(
                                  "infos.booking.reservations.hasBeenCanceled"
                                )
                              ) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.reservation.status === _vm.RESERVATION_STATUS_OVER.value
                    ? _c(
                        "div",
                        {
                          staticClass: "bg-danger text-center text-white p-1",
                          staticStyle: { "border-radius": "100px" },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$tl(
                                  "infos.booking.reservations.alreadyScanned"
                                )
                              ) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4 text-center" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.reservations.dateAndHour"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.displayDate(
                                    _vm.reservation.reservation_datetime,
                                    _vm.DATE_MED
                                  )
                                ) +
                                " -\n                                " +
                                _vm._s(
                                  _vm.displayDate(
                                    _vm.reservation.reservation_datetime,
                                    _vm.TIME_SIMPLE
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sepa" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$tl(
                                "labels.booking.reservations.grid.columns.client"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 value" },
                          [
                            _c("inline-client", {
                              attrs: {
                                client: _vm.reservation.client,
                                "module-enum": _vm.MODULE_TYPE_BOOKING,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.reservation.tables.data.length > 0 ||
                      _vm.reservation.num_table
                        ? [
                            _c("div", { staticClass: "sepa" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.reservations.grid.columns.table",
                                      _vm.restaurant_id
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-12 value" },
                                [
                                  _vm._l(
                                    _vm.reservation.tables.data,
                                    function (table, index) {
                                      return [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(table.name) +
                                            _vm._s(
                                              index + 1 <
                                                _vm.reservation.tables.data
                                                  .length
                                                ? " - "
                                                : ""
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm.reservation.num_table
                                    ? [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(_vm.reservation.num_table) +
                                            "\n                                "
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "sepa" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.reservations.edit.nbPers"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.reservation.nb_pers) +
                                "\n                            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.reservations.edit.nbChildren"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.reservation.nb_children) +
                                "\n                            "
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showValidateButton
          ? _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-success btn-sm btn-circle",
                  class: {
                    disabled:
                      !_vm.reservation ||
                      !_vm.inEnum(
                        _vm.reservation.status,
                        _vm.editableReservationStatus
                      ) ||
                      _vm.isValidating,
                  },
                  attrs: {
                    disabled:
                      !_vm.reservation ||
                      !_vm.inEnum(
                        _vm.reservation.status,
                        _vm.editableReservationStatus
                      ) ||
                      _vm.isValidating,
                  },
                  on: { click: _vm.validate },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$tl("labels.form.actions.validate")) +
                      "\n        "
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }