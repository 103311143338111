<template>
    <div class="pb-5 pt-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Intégration</h5>
                    <a target="_blank" :href="widgetLink">
                        <button
                            :disabled="
                                loading ||
                                !apiKey ||
                                !restaurant ||
                                (!restaurant.stripe_client_id && (!restaurant.payplug_public_key || !restaurant.payplug_secret_key))
                            "
                            class="btn btn-sm btn-success btn-sm btn-circle mr-1"
                            type="button">
                            Voir mon widget
                        </button>
                    </a>
                </div>
            </div>
        </div>
        <overlay-layout
            :display-overlay="!restaurant || (!restaurant.stripe_client_id && (!restaurant.payplug_public_key || !restaurant.payplug_secret_key))"
            class="m-1">
            <template v-slot:content>
                <LoaderComponent v-if="loading" />
                <div v-else-if="error" class="alert alert-danger">
                    {{ error }}
                </div>
                <div v-else-if="has_right_to_read">
                    <div class="row m-0 mb-3">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <strong>Informations concernant l'intégration sur votre site web</strong>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <p>
                                            Pour intégrer le widget bons cadeaux sur votre site web, vous devez transmettre les informations
                                            ci-dessous à votre développeur.
                                        </p>
                                        <p>
                                            Nous vous recommandons d'intégrer le module directement dans votre site internet en suivant les étapes
                                            ci-dessous. Pour un affichage optimal, il est recommandé d'utiliser la largeur totale de l'écran en
                                            limitant les marges gauche et droite.
                                        </p>
                                        <p>
                                            Vous pouvez toutefois intégrer le widget sous forme de bouton qui redirige vers une page externe
                                            (copier-coller le lien accessible depuis le bouton "voir mon widget").
                                        </p>
                                        <p class="mb-0 text-danger">
                                            Merci de ne pas intégrer le widget avec un iframe car la hauteur ne peut pas s'adapter automatiquement au
                                            contenu et cela impacte l'expérience utilisateur.
                                        </p>
                                        <br />
                                        <button
                                            @click="showSendWidgetDocToDevModal = true"
                                            :disabled="
                                                !has_right_to_update ||
                                                (!restaurant.stripe_client_id && (!restaurant.payplug_public_key || !restaurant.payplug_secret_key))
                                            "
                                            :class="{
                                                disabled:
                                                    !has_right_to_update ||
                                                    (!restaurant.stripe_client_id &&
                                                        (!restaurant.payplug_public_key || !restaurant.payplug_secret_key)),
                                            }"
                                            class="btn btn-sm btn-success btn-sm btn-circle"
                                            type="button">
                                            Transmettre les instructions d'intégration
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 mb-3">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <div class="row">
                                    <div class="col-12">
                                        <strong>Intégration du widget sur votre site web</strong>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-5">
                                        <label>Lien court</label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="d-flex align-items-center">
                                            <span class="mr-2">{{ appUrl }}/bon-cadeau/</span>
                                            <input
                                                type="text"
                                                class="form-control"
                                                style="width: 45%"
                                                maxlength="40"
                                                v-model="config.shortlink"
                                                :disabled="!has_right_to_update" />
                                            <button
                                                :disabled="!has_right_to_update || isSavingShortlink"
                                                :class="{ disabled: !has_right_to_update || isSavingShortlink }"
                                                @click="saveShortLink"
                                                class="btn btn-sm btn-success btn-sm btn-circle ml-2"
                                                type="button">
                                                Enregistrer
                                            </button>
                                        </div>
                                        <div>
                                            <small class="text-muted"
                                                >Le lien court ne peut contenir que des lettres minuscules, des tirets et des underscores (_). Max :
                                                40 caractères</small
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-5">
                                        <label>Clé API</label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="mb-3 code-source" v-if="apiKey">
                                            {{ apiKey }}
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-5 pt-1" v-if="apiKey !== null">
                                        <label>Méthode d'intégration</label>
                                    </div>
                                    <div class="col-md-7 pt-1" v-if="apiKey !== null">
                                        <p>
                                            <strong>Étape 1 :</strong> Créez un conteneur (balise 'div') à l'endroit où vous souhaitez afficher le
                                            widget
                                        </p>

                                        <div class="code-source mb-3">&lt;div id="gift-vouchers"&gt;&lt;/div&gt;</div>

                                        <p><strong>Étape 2 :</strong> Ajoutez le code suivant à votre site</p>

                                        <div class="code-source mb-3">
                                            <span class="d-block" v-for="(js, index) in jsToInclude" :key="index">{{ js }}</span>
                                        </div>

                                        <p><strong>Étape 3 :</strong> Initialisez le widget à l'aide du script ci-dessous</p>

                                        <div class="code-source">{{ classFunctionName }}('gift-vouchers', '{{ apiKey }}');</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <FacebookIntegration class="m-0 mb-3" :module="MODULE_TYPE_GIFT_VOUCHERS.value" />

                    <InstagramIntegration class="m-0 mb-3" :module="MODULE_TYPE_GIFT_VOUCHERS.value" />

                    <GoogleLinkIntegration class="m-0 mb-3" />

                    <SendWidgetDocToDevModal
                        :url="sendWidgetDocToDevUrl"
                        v-if="showSendWidgetDocToDevModal"
                        @close="showSendWidgetDocToDevModal = false" />
                </div>
                <div v-else class="alert alert-danger">Désolé, vous n'avez pas le droit d'accéder à cette page.</div>
            </template>
            <template v-slot:overlay>
                <div class="is-white d-flex justify-content-center align-items-center">
                    <div class="text-center">
                        <p>Veuillez connecter votre compte stripe pour accéder à la configuration du widget.</p>
                        <router-link v-if="isYservices" :to="{ name: 'restaurants' }" class="btn btn-circle btn-success">
                            Ajouter un compte Stripe
                        </router-link>
                        <router-link v-else :to="{ name: 'restaurants.edit', params: { restaurant_id } }" class="btn btn-circle btn-success">
                            Ajouter un compte Stripe
                        </router-link>
                    </div>
                </div>
            </template>
        </overlay-layout>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import OverlayLayout from "../../../components/layouts/OverlayLayout";
import SendWidgetDocToDevModal from "../../../components/Modals/SendWidgetDocToDevModal";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";
import FacebookIntegration from "../../../components/widgets/FacebookIntegration.vue";
import InstagramIntegration from "../../../components/widgets/InstagramIntegration.vue";
import GoogleLinkIntegration from "../../../components/widgets/GoogleLinkIntegration.vue";

export default {
    data() {
        return {
            appUrl: APP_URL,
            loading: false,
            error: null,
            errors: null,
            config: null,
            showSendWidgetDocToDevModal: false,
            isSavingShortlink: false,
        };
    },
    mixins: [ModuleTypesEnum],
    computed: {
        widgetLink() {
            if (
                this.loading ||
                !this.apiKey ||
                !this.restaurant ||
                (!this.restaurant.stripe_client_id && (!this.restaurant.payplug_public_key || !this.restaurant.payplug_secret_key))
            )
                return "#";
            if (this.config.shortlink) return `/bon-cadeau/${this.config.shortlink}`;
            return `/gift_vouchers/widget/public/${this.apiKey}/page/new`;
        },
        sendWidgetDocToDevUrl() {
            return `/api/gift_vouchers/${this.restaurant_id}/widget/doc`;
        },
        apiKey() {
            return this.config && this.config.token ? this.config.token : null;
        },
        jsToInclude() {
            return [
                '<isplease src="https://js.stripe.com/v3/"></isplease>'.replace(/isplease/g, "script"),
                '<isplease src="https://api.payplug.com/js/1/form.latest.js"></isplease>'.replace(/isplease/g, "script"),
                `<isplease src="${APP_URL}/gift_vouchers/js/widget.js"></isplease>`.replace(/isplease/g, "script"),
            ];
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        restaurant() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id);
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read() {
            return this.rights.includes("gift_vouchers.config.read");
        },
        has_right_to_update() {
            return this.rights.includes("gift_vouchers.config.update");
        },
        classFunctionName() {
            return this.isNoShow ? "window.initNoShowGiftVouchers" : "window.initYServicesGiftVouchers";
        },
    },
    methods: {
        fetchData() {
            this.loading = true;
            this.error = null;
            this.config = null;

            axios
                .get(`/api/gift_vouchers/${this.restaurant_id}/config`)
                .then((response) => {
                    this.config = response.data.config;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => (this.loading = false));
        },
        saveShortLink() {
            this.isSavingShortlink = true;

            axios
                .post(`/api/gift_vouchers/${this.restaurant_id}/widget/shortlink`, {
                    shortlink: this.config.shortlink,
                })
                .then((response) => {
                    this.notifySuccess(response);
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => (this.isSavingShortlink = false));
        },
    },
    components: {
        LoaderComponent,
        OverlayLayout,
        SendWidgetDocToDevModal,
        FacebookIntegration,
        InstagramIntegration,
        GoogleLinkIntegration,
    },
    watch: {
        "config.shortlink": function (newVal, oldVal) {
            if (!newVal || !oldVal || newVal.length < oldVal.length) {
                return;
            }

            if (newVal.length > 0 && !newVal.replace(oldVal, "").match(/^[a-z_\-]+$/)) {
                this.config.shortlink = oldVal;
            }
        },
    },
    created() {
        if (this.has_right_to_read) {
            this.fetchData();
        }
    },
};
</script>

<style scoped>
button.btn:disabled {
    cursor: default;
}
</style>
