<template>
    <div>
        <div v-if="formErrors && formErrors.errors && formErrors.errors.choosen_general_options">
            <div class="invalid-feedback d-block" v-for="err in formErrors.errors.choosen_general_options" :key="err">
                {{ err }}
            </div>
        </div>
        <div class="one-menu mb-1" v-for="(o, oIndex) in available_general_options" :key="oIndex">
            <div class="row text-left mb-0">
                <div class="col-sm-8">
                    <label class="mb-1">
                        {{ o.name }}{{ o.price ? ` - ${formatCurrency(o.price, getRestaurantCurrency(restaurantId))}` : "" }}
                    </label>
                    <small class="d-block text-muted mb-2">
                        {{ o.description }}
                    </small>
                </div>
                <div class="col-sm-4">
                    <div v-if="o.quantity_type === 'mandatory_for_each'">
                        <select :ref="`ref_general_option_${o.id}`" class="custom-select" @change="chooseGeneralOption(o, $event)">
                            <option value="0">--</option>
                            <option :value="nb_total">
                                {{ nb_total }}
                            </option>
                        </select>
                    </div>

                    <select
                        style="height: 40px; padding: 10px 15px"
                        v-else-if="o.quantity_type === 'limited_by_pers'"
                        class="custom-select"
                        :ref="`ref_general_option_${o.id}`"
                        @change="chooseGeneralOption(o, $event)">
                        <option value="0">0</option>
                        <option v-for="(option, index) in nb_total" :key="index" :value="index * 1 + 1">
                            {{ index * 1 + 1 }}
                        </option>
                    </select>

                    <input
                        style="height: 40px; padding: 10px 15px"
                        class="form-control"
                        type="number"
                        step="1"
                        min="0"
                        :ref="`ref_general_option_${o.id}`"
                        :value="getBaseValue(o)"
                        v-else-if="o.quantity_type === 'unlimited'"
                        @change="chooseGeneralOption(o, $event)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";

export default {
    data() {
        return {};
    },
    props: {
        available_general_options: {
            required: true,
        },
        reservation: {
            type: Object,
            required: true,
        },
        nb_total: {
            default: 0,
        },
        formErrors: {
            default: null,
        },
        restaurantId: {
            type: Number,
            required: true,
        },
    },
    methods: {
        getBaseValue(option) {
            if (this.reservation.back_choosen_general_options instanceof Array) {
                const optFound = this.reservation.back_choosen_general_options.find((opt) => opt.id === option.id);
                if (optFound !== undefined) {
                    if (optFound.nb > 0) {
                        this.$set(this.reservation.choosen_general_options, `id_${optFound.id}`, {
                            id: optFound.id,
                            value: optFound.nb,
                            price: optFound.price,
                        });
                    }
                    return optFound.nb;
                }
            }
            return 0;
        },
        chooseGeneralOption(option, event) {
            let value = Number.parseInt(event.target.value);
            let tmp = this.reservation.choosen_general_options;

            if (isNaN(value) || value < 0) {
                event.target.value = 0;
                value = 0;
            }

            if (value > 0) {
                let newVal = undefined;
                tmp[`id_${option.id}`] !== undefined
                    ? ((newVal = this.reservation.choosen_general_options[`id_${option.id}`]), (newVal.value = value))
                    : (newVal = { id: option.id, value, price: option.price });
                this.$set(this.reservation.choosen_general_options, `id_${option.id}`, newVal);
            } else {
                this.$set(this.reservation, "choosen_general_options", _.omit(tmp, `id_${option.id}`));
            }
        },
    },
};
</script>
