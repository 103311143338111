var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("LoaderComponent")
        : _c(
            "vue-multiselect",
            {
              staticStyle: { "font-size": "12.8px !important" },
              attrs: {
                options: _vm.options,
                multiple: true,
                label: "name",
                "track-by": "name",
                "group-values": "entities",
                "group-label": "roomName",
                "group-select": false,
                "close-on-select": false,
                "clear-on-select": false,
                "preserve-search": true,
                "show-labels": false,
                placeholder: _vm.$tl("labels.booking.seatingPlan.tables"),
                "custom-label": _vm.customTablesLabel,
                "open-direction": "bottom",
              },
              on: {
                select: function ($event) {
                  return _vm.tableSelected($event)
                },
                remove: function ($event) {
                  return _vm.tableUnselected($event)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "option",
                  fn: function (props) {
                    return [
                      !props.option.$isLabel
                        ? [
                            _c("div", { staticClass: "float-left" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    props.option.type === "table"
                                      ? "Table"
                                      : "Groupe"
                                  ) +
                                  "\n                    " +
                                  _vm._s(props.option.name) +
                                  "\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            props.option.enabledPax.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "float-right" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.capitalize(_vm.$tl("labels.pax"))
                                        ) +
                                        " :\n                    "
                                    ),
                                    _vm._l(
                                      props.option.enabledPax,
                                      function (item, index) {
                                        return [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(index > 0 ? " " : "") +
                                              _vm._s(
                                                props.option.enabledPax.length -
                                                  1 ===
                                                  index
                                                  ? `${item}`
                                                  : `${item},`
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _c("div", { staticClass: "float-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.capitalize(_vm.$tl("labels.pax"))
                                    ) +
                                      " : " +
                                      _vm._s(props.option.seats)
                                  ),
                                ]),
                          ]
                        : [
                            _c("div", [
                              _vm._v(_vm._s(props.option.$groupLabel)),
                            ]),
                          ],
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.tables,
                callback: function ($$v) {
                  _vm.tables = $$v
                },
                expression: "tables",
              },
            },
            [
              _c("template", { slot: "noOptions" }, [
                !_vm.reservation.slot.id
                  ? _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$tl("labels.booking.reservations.selectSlot")
                          ) +
                          "\n            "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$tl("labels.noResult")) +
                          "\n            "
                      ),
                    ]),
              ]),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }