import { DateTime } from "luxon";
import PublicApiClientEnum from "../PublicApi/ClientEnum.js";

export default {
    data() {
        return {
            RESERVATION_ISSUER_BO: {
                value: "backoffice",
                getIcon: () => ({
                    html: `<svg fill="currentColor" stroke="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 12">
                            <g><path d="m12,.75H1.5c-.41,0-.75.34-.75.75v4.5h12V1.5c0-.41-.34-.75-.75-.75Zm1.5,5.25v2.25c0,.83-.67,1.5-1.5,1.5h-3.49l.25,1.5h1.37c.21,0,.38.17.38.38s-.17.38-.38.38H3.38c-.21,0-.38-.17-.38-.38s.17-.38.38-.38h1.37l.25-1.5H1.5c-.83,0-1.5-.67-1.5-1.5V1.5C0,.67.67,0,1.5,0h10.5c.83,0,1.5.67,1.5,1.5v4.5ZM.75,6.75v1.5c0,.41.34.75.75.75h10.5c.41,0,.75-.34.75-.75v-1.5H.75Zm4.76,4.5h2.49l-.25-1.5h-1.99s-.25,1.5-.25,1.5Z"/></g>
                        </svg>`,
                    class: "text-success",
                }),
            },
            RESERVATION_ISSUER_WIDGET_MOBILE: {
                value: "widget_mobile",
                getIcon: () => ({
                    html: `<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
                            <g><path d="m6,11.25c.39,0,.95-.34,1.45-1.34.23-.46.43-1.02.56-1.66H3.98c.14.63.33,1.19.56,1.66.5,1.01,1.06,1.34,1.45,1.34Zm-2.15-3.75h4.3c.07-.47.1-.98.1-1.5s-.04-1.03-.1-1.5H3.85c-.07.47-.1.98-.1,1.5s.04,1.03.1,1.5Zm.13-3.75h4.03c-.14-.63-.33-1.19-.56-1.66-.5-1.01-1.06-1.34-1.45-1.34s-.95.34-1.45,1.34c-.23.46-.43,1.02-.56,1.66Zm4.92.75c.06.48.09.98.09,1.5s-.03,1.02-.09,1.5h2.13c.14-.48.22-.98.22-1.5s-.08-1.02-.22-1.5h-2.13Zm1.84-.75c-.61-1.28-1.71-2.27-3.07-2.73.49.66.88,1.61,1.11,2.73h1.96,0Zm-7.53,0c.23-1.12.61-2.06,1.11-2.73-1.36.45-2.47,1.45-3.07,2.73h1.96Zm-2.25.75c-.14.48-.22.98-.22,1.5s.08,1.02.22,1.5h2.12c-.06-.48-.09-.98-.09-1.5s.03-1.02.09-1.5H.97h0Zm6.71,6.48c1.35-.46,2.46-1.45,3.07-2.73h-1.96c-.23,1.12-.61,2.06-1.11,2.73Zm-3.35,0c-.49-.66-.88-1.61-1.11-2.73h-1.96c.61,1.28,1.71,2.27,3.07,2.73Zm1.68,1.02c-3.31,0-6-2.69-6-6S2.69,0,6,0s6,2.69,6,6-2.69,6-6,6Z"/></g>
                        </svg>`,
                    class: "text-success",
                }),
            },
            RESERVATION_ISSUER_WIDGET_DESKTOP: {
                value: "widget_desktop",
                getIcon: () => this.RESERVATION_ISSUER_WIDGET_MOBILE.getIcon(),
            },
            RESERVATION_ISSUER_PUBLIC_API: {
                value: "public_api",
                getIcon: (provider) => this.getPublicApiClientIcon(provider),
            },
            RESERVATION_ISSUER_GOOGLE_RESERVE: {
                value: "google_reserve",
                getIcon: () => ({
                    html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.81 11.09">
                    <g>
                      <g>
                        <path fill="#517bbd" d="m10.43,8.17c.42-.97.41-1.97.35-2.98,0-.12-.05-.15-.17-.15-1.6,0-3.2,0-4.8,0h-.14v1.54h3.57c.03.22-.04.41-.1.6-.12.38-.25.75-.51,1.06-.13.15-.27.29-.41.42l1.22.95c.41-.41.74-.88.99-1.43Z"/>
                        <path fill="#31a853" d="m6.59,9.47c-.34.07-.69.06-1.04.07-.76.02-1.45-.21-2.07-.63-.69-.46-1.19-1.07-1.48-1.76l-1.3,1c.33.6.76,1.14,1.31,1.6,1.27,1.07,2.75,1.46,4.38,1.29.98-.1,1.86-.43,2.62-1.06.15-.13.3-.26.43-.39l-1.22-.95c-.46.41-1.01.7-1.64.82Z"/>
                        <path fill="#fbbc0a" d="m1.76,4.7c.06-.31.16-.6.29-.87l-1.3-1.01c-.31.53-.53,1.11-.65,1.75-.04.21-.07.43-.1.64v.69c.03.21.06.42.1.63.11.59.32,1.13.59,1.63l1.3-1c-.31-.74-.4-1.58-.24-2.45Z"/>
                        <path fill="#e84336" d="m8.15,2.41c.08.06.17.12.25.18.35-.36.68-.71,1.02-1.06-.01-.02-.02-.03-.03-.04-.03-.03-.07-.07-.1-.1C8.05.28,6.59-.15,4.94.04c-1.07.13-2.03.54-2.86,1.22-.55.45-.99.97-1.33,1.55l1.3,1.01c.21-.45.49-.85.86-1.2.92-.88,2.03-1.19,3.28-1.07.68.07,1.29.35,1.84.76.04.03.09.06.13.09"/>
                      </g>
                    </g>
                  </svg>`,
                }),
            },
        };
    },
    mixins: [PublicApiClientEnum],
    methods: {
        isWidgetReservationIssuer(issuer) {
            return typeof this.widgetReservationIssuers.find((w) => w.value === issuer) !== "undefined";
        },
        getReservationIssuerLabel(issuer, provider, createdAt, user) {
            if (this.isWidgetReservationIssuer(issuer)) {
                return this.$t("labels.booking.reservations.web", { date: this.displayDate(createdAt, DateTime.DATETIME_SHORT) });
            }

            switch (issuer) {
                case this.RESERVATION_ISSUER_BO.value:
                    return this.$t("labels.booking.reservations.manually", {
                        date: this.displayDate(createdAt, DateTime.DATETIME_SHORT),
                        user: `${user.firstname} ${user.lastname}`,
                    });
                case this.RESERVATION_ISSUER_PUBLIC_API.value:
                    return this.$t("labels.booking.reservations.public_api", {
                        provider: this.getPublicApiClientLabel(provider),
                        date: this.displayDate(createdAt, DateTime.DATETIME_SHORT),
                    });
                case this.RESERVATION_ISSUER_GOOGLE_RESERVE.value:
                    return this.$t("labels.booking.reservations.google_reserve", {
                        date: this.displayDate(createdAt, DateTime.DATETIME_SHORT),
                    });
            }

            return undefined;
        },
        getLabelReservationFrom(issuer, provider) {
            if (this.isWidgetReservationIssuer(issuer)) {
                return this.$t("labels.notifications.booking.web");
            }
            switch (issuer) {
                case this.RESERVATION_ISSUER_PUBLIC_API.value:
                    return this.$t("labels.notifications.booking.public_api", {
                        provider: this.getPublicApiClientLabel(provider),
                    });
                case this.RESERVATION_ISSUER_GOOGLE_RESERVE.value:
                    return this.$t("labels.notifications.booking.google_reserve");
            }

            return undefined;
        },
    },
    computed: {
        widgetReservationIssuers() {
            return [this.RESERVATION_ISSUER_WIDGET_MOBILE, this.RESERVATION_ISSUER_WIDGET_DESKTOP];
        },
        ALL_RESERVATION_ISSUERS() {
            return [
                this.RESERVATION_ISSUER_BO,
                this.RESERVATION_ISSUER_WIDGET_MOBILE,
                this.RESERVATION_ISSUER_WIDGET_DESKTOP,
                this.RESERVATION_ISSUER_PUBLIC_API,
                this.RESERVATION_ISSUER_GOOGLE_RESERVE,
            ];
        },
    },
};
