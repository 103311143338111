<template>
    <modal @close="closeFromModal">
        <h5 slot="header">
            {{ product.id !== null && !duplicate ? "Éditer" : "Ajouter" }}
            {{ product.type === "menu" ? "une formule" : "un produit" }}
        </h5>
        <div slot="body">
            <add-click-and-collect-product
                ref="addClickAndCollectProductComponent"
                :product="product"
                :duplicate="duplicate"
                :config="config"
                @saved="$emit('saved')" />
        </div>
        <div slot="footer" class="d-flex">
            <button
                type="button"
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                @click="$emit('close')">
                Annuler
            </button>
            <button
                type="button"
                class="btn btn-sm btn-success btn-circle ml-2"
                @click="$refs.addClickAndCollectProductComponent.save()">
                Enregistrer
            </button>
        </div>
    </modal>
</template>

<script>
import addClickAndCollectProduct from "../../clickAndCollect/addClickAndCollectProduct";

export default {
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        duplicate: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            required: true,
        },
    },
    components: {
        addClickAndCollectProduct,
    },
};
</script>
