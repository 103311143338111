<template>
    <div>
        <LoaderComponent v-if="nbLoading > 0" />
        <keep-alive v-else-if="!errors.hasFatal">
            <component
                class="border-light p-3"
                ref="currentStepComponent"
                :is="currentStepComponent"
                v-bind="{ reservation, restaurant, tablesIdToPreselect, vueMultiSelect }"
                :formErrors="errors.form"
                :restaurant_id="restaurantId"
                is-passing-customer
                @next-step="nextStep" />
        </keep-alive>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import LoaderComponent from "../../LoaderComponent.vue";
import Step1 from "./steps/Pax.vue";
import Step2 from "./steps/TablesAndMenus.vue";

export default {
    data() {
        return {
            nbLoading: 0,
            errors: {
                hasFatal: false,
                form: null,
            },
            step: 1,
            reservation: {
                reservation_date: this.date,
                nb_pers: null,
                nb_children: 0,
                slot_id: null,
                slot: null,
                service: null,
                service_id: this.serviceId,
                user_id: null,
                duration: {
                    HH: "01",
                    mm: "00",
                },
                is_duration_customized: false,
                ignore_placement: false,
            },
            currentService: null,
            vueMultiSelect: {
                entitiesSelected: [],
                paxFull: false,
            },
        };
    },
    props: {
        restaurantId: {
            type: Number,
            required: true,
        },
        serviceId: {
            type: Number,
            required: true,
        },
        date: {
            type: DateTime,
            required: true,
        },
        defaultValues: {
            type: Object,
            default: null,
        },
        tablesIdToPreselect: {
            default: () => [],
        },
    },
    computed: {
        restaurant() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurantId);
        },
        invalidPax() {
            return this.step > 0 && ([0, null].includes(this.reservation.nb_pers) || this.nb_pers_total < 1);
        },
        noTableSelected() {
            return (
                this.reservation.service &&
                this.reservation.service.is_seating_plan_algorithm_enabled &&
                this.vueMultiSelect.entitiesSelected.length < 1
            );
        },
        currentStepComponent() {
            return this.step > 0 && this.step < 3 ? `Step${this.step}` : undefined;
        },
        nb_pers_total() {
            return (this.reservation.nb_pers || 0) + (this.reservation.nb_children || 0);
        },
        tablesSelected() {
            const result = [];

            for (var entity of this.vueMultiSelect.entitiesSelected) {
                switch (entity.type) {
                    case "table":
                        result.push({
                            id: entity.id,
                            pax: this.nb_pers_total,
                        });
                        break;
                    case "group":
                        for (const tableIndex in entity.tables) {
                            const table = entity.tables[tableIndex];

                            result.push({
                                id: table.id,
                                pax: this.nb_pers_total,
                            });
                        }
                        break;
                }
            }

            return result;
        },
    },
    methods: {
        previousStep() {
            if (this.step < 2) {
                return;
            }

            this.$emit("set-step", --this.step);
        },
        nextStep() {
            if (this.step > 2) {
                return;
            }

            if (this.step === 1 && this.invalidPax) {
                return this.notifyError(null, this.$tl("errors.booking.reservations.invalidPax", this.restaurantId));
            }

            if (this.step === 2) {
                if (this.noTableSelected) {
                    return this.notifyError(null, this.$tl("errors.booking.reservations.cantPlace", this.restaurantId));
                }

                this.save();
                return;
            }

            this.$emit("set-step", ++this.step);
        },
        fetchService() {
            this.nbLoading++;

            this.httpGet(`/api/restaurants/${this.restaurantId}/services/${this.reservation.service_id}`, {
                config: {
                    params: {
                        include: "slotsWithPax,isOpen,slots,seatingPlanFilled,paxDurations,has_seating_plan",
                        addSlot: this.reservation.slot_id,
                        date: this.reservation.reservation_date.toISODate(),
                    },
                },
            })
                .then((response) => {
                    if (response !== false) {
                        this.reservation.service = response.data;
                    } else {
                        this.errors.hasFatal = true;
                    }
                })
                .finally(() => this.nbLoading--);
        },
        fetchPassingCustomerSlot() {
            this.nbLoading++;

            this.httpGet(`/api/restaurants/${this.restaurantId}/services/${this.reservation.service_id}/passingCustomerSlot`)
                .then((response) => {
                    if (response !== false) {
                        this.reservation.slot_id = response.data.id;
                        this.reservation.slot = response.data;

                        this.fetchService();
                    } else {
                        this.errors.hasFatal = true;
                    }
                })
                .finally(() => this.nbLoading--);
        },
        save() {
            this.nbLoading++;
            this.errors.form = null;

            this.httpPost(`/api/restaurants/${this.restaurantId}/services/${this.reservation.service_id}/passingCustomer`, {
                ...this.reservation,
                tables: this.tablesSelected,
            })
                .then((response) => {
                    if (response !== false) {
                        this.$emit("saved");
                    }
                })
                .finally(() => this.nbLoading--);
        },
    },
    watch: {
        nbLoading(newVal) {
            this.$emit("enable-next-button", newVal === 0 && !this.errors.hasFatal);
        },
    },
    components: {
        LoaderComponent,
        Step1,
        Step2,
    },
    created() {
        this.fetchPassingCustomerSlot();

        if (this.defaultValues) {
            _.merge(this.reservation, this.defaultValues);
        }

        this.$emit("set-step", this.step);
    },
};
</script>
