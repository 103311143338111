<template>
    <div class="h-100">
        <div v-if="!isBrowserIE" class="back-off h-100">
            <topbar :url="{ name: 'register.finalize' }" />
            <div class="container-fluid">
                <div class="row">
                    <main role="main" class="col-12 px-0 mt-5 default registration">
                        <notifications style="z-index: 9999" group="notification" />
                        <slot />
                    </main>
                </div>
            </div>
        </div>
        <div v-else style="text-align: center">
            <h1>Navigateur non supporté</h1>
            <p>
                Votre navigateur n'est pas compatible avec le logiciel NoShow, veuillez utiliser
                <a href="https://www.google.com/intl/fr_fr/chrome/">Google Chrome</a> ou
                <a href="https://www.mozilla.org/fr/">Mozilla Firefox</a>
            </p>
            <br />
        </div>
    </div>
</template>

<script>
// load components
import Topbar from "../Default/Topbar.vue";

export default {
    name: "DefaultLayout",
    computed: {},
    components: {
        Topbar,
    },
};
</script>
