var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pr-0 pb-5" },
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c(
            "div",
            [
              _vm.error
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                  ])
                : _c("div", [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                          },
                          [
                            _c("div", [
                              _c("h5", {
                                staticClass: "title mt-2",
                                domProps: { innerHTML: _vm._s(_vm.headerText) },
                              }),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.formErrors
                      ? _c(
                          "div",
                          { staticClass: "alert alert-danger" },
                          _vm._l(_vm.formErrors, function (error, index) {
                            return _c("p", {
                              key: index,
                              domProps: {
                                innerHTML: _vm._s(
                                  Array.isArray(error)
                                    ? error.join("<br/>")
                                    : error
                                ),
                              },
                            })
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formSuccess
                      ? _c("div", { staticClass: "alert alert-success" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.formSuccess) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        attrs: { id: "addService", method: "post" },
                        on: { submit: _vm.postForm },
                      },
                      [
                        _vm.formLoading
                          ? _c("LoaderComponent")
                          : [
                              _c("div", { staticClass: "row m-0" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "border-light b-radius-20 p-4 mb-3",
                                    },
                                    [
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c("div", { staticClass: "col-5" }, [
                                          _c(
                                            "label",
                                            { attrs: { for: "name" } },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.$tl("labels.form.name")
                                                  ) +
                                                  " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                              _vm._v(" "),
                                              _c("feather", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.getTooltipNotice(
                                                      "notices.booking.servicesConfig.serviceName"
                                                    ),
                                                    expression:
                                                      "getTooltipNotice('notices.booking.servicesConfig.serviceName')",
                                                  },
                                                ],
                                                staticClass:
                                                  "ml-1 pointer tooltip-infos",
                                                attrs: { type: "info" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-7" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.name,
                                                expression: "name",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              disabled:
                                                !_vm.has_right_to_update_service,
                                              type: "text",
                                              name: "name",
                                              id: "name",
                                              required: "",
                                            },
                                            domProps: { value: _vm.name },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.name = $event.target.value
                                              },
                                            },
                                          }),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-4" }, [
                                        _c("div", { staticClass: "col-5" }, [
                                          _c(
                                            "label",
                                            { attrs: { for: "category" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.booking.services.category"
                                                  )
                                                ) + " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-7" }, [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.category_vmodel,
                                                  expression: "category_vmodel",
                                                },
                                              ],
                                              staticClass: "custom-select",
                                              attrs: {
                                                disabled:
                                                  !_vm.has_right_to_update_service,
                                                name: "category",
                                                id: "category",
                                                required: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.category_vmodel = $event
                                                    .target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                },
                                              },
                                            },
                                            _vm._l(
                                              _vm.ALL_DEFAULT_SERVICE_CATEGORIES_WITH_OTHER,
                                              function (serviceCategory) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: serviceCategory.value,
                                                    domProps: {
                                                      value:
                                                        serviceCategory.value,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm.getDefaultServiceCategoryLabel(
                                                            serviceCategory.value
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _vm.category_vmodel === "autre"
                                        ? _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-5 pt-2" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "category",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.services.categoryName"
                                                          )
                                                        ) +
                                                          " (" +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.langs.fr"
                                                            )
                                                          ) +
                                                          ")"
                                                      ),
                                                      _c("small", [
                                                        _vm._v("*"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-7 pt-2" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.custom_category_fr,
                                                        expression:
                                                          "custom_category_fr",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      disabled:
                                                        !_vm.has_right_to_update_service,
                                                      type: "text",
                                                      name: "custom_category_fr",
                                                      id: "custom_category_fr",
                                                      required: "",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.custom_category_fr,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.custom_category_fr =
                                                          $event.target.value
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-5 pt-2" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "category",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.services.categoryName"
                                                          )
                                                        ) +
                                                          " (" +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.langs.en"
                                                            )
                                                          ) +
                                                          ")"
                                                      ),
                                                      _c("small", [
                                                        _vm._v("*"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-7 pt-2" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.custom_category_en,
                                                        expression:
                                                          "custom_category_en",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      disabled:
                                                        !_vm.has_right_to_update_service,
                                                      type: "text",
                                                      name: "custom_category_en",
                                                      id: "custom_category_en",
                                                      required: "",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.custom_category_en,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.custom_category_en =
                                                          $event.target.value
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "row mb-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      "\n                                                    " +
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.services.special.dates"
                                                          )
                                                        ) +
                                                        "\n                                                "
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("feather", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value:
                                                          _vm.getTooltipNoticeLexicon(
                                                            "notices.booking.servicesConfig.exceptionalOpening",
                                                            _vm.restaurant_id
                                                          ),
                                                        expression:
                                                          "\n                                                        getTooltipNoticeLexicon(\n                                                            'notices.booking.servicesConfig.exceptionalOpening',\n                                                            restaurant_id\n                                                        )\n                                                    ",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "ml-1 pointer tooltip-infos",
                                                    attrs: { type: "info" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-5" },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.startDate"
                                                        )
                                                      ) + " "
                                                    ),
                                                    _c("small", [_vm._v("*")]),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-7 date-resa-cal",
                                                },
                                                [
                                                  _c("DatePicker", {
                                                    class: {
                                                      disabled:
                                                        !_vm.has_right_to_update_service,
                                                    },
                                                    attrs: {
                                                      readonly:
                                                        !_vm.has_right_to_update_service,
                                                      disabled:
                                                        !_vm.has_right_to_update_service,
                                                    },
                                                    on: {
                                                      selected:
                                                        _vm.verifDatesBegin,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.special_date_begin,
                                                      callback: function ($$v) {
                                                        _vm.special_date_begin =
                                                          $$v
                                                      },
                                                      expression:
                                                        "special_date_begin",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-5" },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.endDate"
                                                        )
                                                      ) + " "
                                                    ),
                                                    _c("small", [_vm._v("*")]),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-7 date-resa-cal",
                                                },
                                                [
                                                  _c("DatePicker", {
                                                    class: {
                                                      disabled:
                                                        !_vm.has_right_to_update_service,
                                                    },
                                                    attrs: {
                                                      readonly:
                                                        !_vm.has_right_to_update_service,
                                                      disabled:
                                                        !_vm.has_right_to_update_service,
                                                    },
                                                    on: {
                                                      selected:
                                                        _vm.verifDatesEnd,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.special_date_end,
                                                      callback: function ($$v) {
                                                        _vm.special_date_end =
                                                          $$v
                                                      },
                                                      expression:
                                                        "special_date_end",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c("div", { staticClass: "col-5" }, [
                                          _c(
                                            "label",
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.services.activeDays"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                              _vm._v(" "),
                                              _c("feather", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.getTooltipNotice(
                                                      "notices.booking.servicesConfig.activeDays"
                                                    ),
                                                    expression:
                                                      "getTooltipNotice('notices.booking.servicesConfig.activeDays')",
                                                  },
                                                ],
                                                staticClass:
                                                  "ml-1 pointer tooltip-infos",
                                                attrs: { type: "info" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-7" },
                                          _vm._l(
                                            _vm.englishDays(),
                                            function (englishDay) {
                                              return _c(
                                                "label",
                                                {
                                                  key: englishDay,
                                                  staticClass: "container-box",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.days,
                                                        expression: "days",
                                                      },
                                                    ],
                                                    class: {
                                                      disabled:
                                                        !_vm.has_right_to_update_service,
                                                    },
                                                    attrs: {
                                                      type: "checkbox",
                                                      name: "days[]",
                                                      readonly:
                                                        !_vm.has_right_to_update_service,
                                                      disabled:
                                                        !_vm.has_right_to_update_service,
                                                    },
                                                    domProps: {
                                                      value: englishDay,
                                                      checked: Array.isArray(
                                                        _vm.days
                                                      )
                                                        ? _vm._i(
                                                            _vm.days,
                                                            englishDay
                                                          ) > -1
                                                        : _vm.days,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a = _vm.days,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = englishDay,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.days =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.days = $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                ))
                                                          }
                                                        } else {
                                                          _vm.days = $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", {
                                                    staticClass: "checkmark",
                                                    class: {
                                                      disabled:
                                                        !_vm.has_right_to_update_service,
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        !_vm.has_right_to_update_service,
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          `labels.days.${englishDay}`
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-1" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-5 pt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl("labels.startHour")
                                                ) + " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-7" },
                                          [
                                            _c("vue-timepicker", {
                                              class: {
                                                disabled:
                                                  !_vm.has_right_to_update_service,
                                              },
                                              attrs: {
                                                format: "HH:mm",
                                                "minute-interval": 15,
                                                disabled:
                                                  !_vm.has_right_to_update_service,
                                              },
                                              on: {
                                                change: _vm.getPossibleSlots,
                                              },
                                              model: {
                                                value: _vm.hour_begin,
                                                callback: function ($$v) {
                                                  _vm.hour_begin = $$v
                                                },
                                                expression: "hour_begin",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-5 pt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl("labels.endHour")
                                                ) + " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-7" },
                                          [
                                            _c("vue-timepicker", {
                                              class: {
                                                disabled:
                                                  !_vm.has_right_to_update_service,
                                              },
                                              attrs: {
                                                format: "HH:mm",
                                                "minute-interval": 15,
                                                disabled:
                                                  !_vm.has_right_to_update_service,
                                              },
                                              on: {
                                                change: _vm.getPossibleSlots,
                                              },
                                              model: {
                                                value: _vm.hour_end,
                                                callback: function ($$v) {
                                                  _vm.hour_end = $$v
                                                },
                                                expression: "hour_end",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm.possible_slots.length > 0
                                        ? _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-5" },
                                                [
                                                  _c(
                                                    "label",
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.booking.services.slots"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("small", [
                                                        _vm._v("*"),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("feather", {
                                                        directives: [
                                                          {
                                                            name: "tooltip",
                                                            rawName:
                                                              "v-tooltip",
                                                            value:
                                                              _vm.getTooltipNotice(
                                                                "notices.booking.servicesConfig.reservationSlots"
                                                              ),
                                                            expression:
                                                              "getTooltipNotice('notices.booking.servicesConfig.reservationSlots')",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "ml-1 pointer tooltip-infos",
                                                        attrs: { type: "info" },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-7" },
                                                _vm._l(
                                                  _vm.possible_slots,
                                                  function (s, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "container-box",
                                                        staticStyle: {
                                                          "margin-right":
                                                            "13px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticStyle: {
                                                              "margin-bottom":
                                                                "0",
                                                            },
                                                          },
                                                          [
                                                            _c("input", {
                                                              class: {
                                                                disabled:
                                                                  !_vm.has_right_to_update_service,
                                                              },
                                                              attrs: {
                                                                type: "checkbox",
                                                                readonly:
                                                                  !_vm.has_right_to_update_service,
                                                                disabled:
                                                                  !_vm.has_right_to_update_service,
                                                              },
                                                              domProps: {
                                                                value: true,
                                                                checked:
                                                                  !_vm.$_.isEmpty(
                                                                    _vm.slots[s]
                                                                  ) &&
                                                                  _vm.slots[s]
                                                                    .enabled,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.slotClicked(
                                                                      $event,
                                                                      s
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("span", {
                                                              staticClass:
                                                                "checkmark",
                                                              class: {
                                                                disabled:
                                                                  !_vm.has_right_to_update_service,
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  !_vm.has_right_to_update_service,
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(s) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c("div", { staticClass: "col-5" }, [
                                          _c(
                                            "label",
                                            { staticClass: "mb-0" },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.services.maxPax"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                              _vm._v(" "),
                                              _c("feather", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.getTooltipNotice(
                                                      "notices.booking.servicesConfig.paxMaxPerService"
                                                    ),
                                                    expression:
                                                      "getTooltipNotice('notices.booking.servicesConfig.paxMaxPerService')",
                                                  },
                                                ],
                                                staticClass:
                                                  "ml-1 pointer tooltip-infos",
                                                attrs: { type: "info" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("br"),
                                          _vm._v(" "),
                                          _c(
                                            "small",
                                            { staticClass: "text-muted" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl(
                                                    "infos.booking.services.maxPaxIfRotation"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-7" }, [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-4" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.max_pax,
                                                      expression: "max_pax",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    disabled:
                                                      !_vm.has_right_to_update_service,
                                                    type: "number",
                                                    name: "max_pax",
                                                    required: "",
                                                    id: "max_pax",
                                                    min: "1",
                                                    step: "1",
                                                  },
                                                  domProps: {
                                                    value: _vm.max_pax,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.max_pax =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-5 pt-1" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.services.minPers"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-md-7" }, [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-inline-block align-middle lead-switch pt-1",
                                                  },
                                                  [
                                                    _c("input", {
                                                      staticClass:
                                                        "switch align-self-center is-rounded",
                                                      attrs: {
                                                        type: "checkbox",
                                                        disabled:
                                                          !_vm.has_right_to_update_service,
                                                      },
                                                      domProps: {
                                                        checked:
                                                          _vm.use_widget_min_pers,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("label", {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.use_widget_min_pers =
                                                            _vm.has_right_to_update_service
                                                              ? !_vm.use_widget_min_pers
                                                              : _vm.use_widget_min_pers
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n                                                    " +
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.widget.useDefaultConfig"
                                                          )
                                                        ) +
                                                        "\n                                                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          !_vm.use_widget_min_pers
                                            ? _c(
                                                "div",
                                                { staticClass: "row mt-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-4" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: _vm.min_pers,
                                                            expression:
                                                              "min_pers",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          type: "number",
                                                          required:
                                                            !_vm.use_widget_min_pers,
                                                          disabled:
                                                            !_vm.has_right_to_update_service,
                                                          min: "1",
                                                          step: "1",
                                                        },
                                                        domProps: {
                                                          value: _vm.min_pers,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.min_pers =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-1" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-5 pt-1" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.services.maxPers"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-md-7" }, [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-inline-block align-middle lead-switch pt-1",
                                                  },
                                                  [
                                                    _c("input", {
                                                      staticClass:
                                                        "switch align-self-center is-rounded",
                                                      attrs: {
                                                        type: "checkbox",
                                                        disabled:
                                                          !_vm.has_right_to_update_service,
                                                      },
                                                      domProps: {
                                                        checked:
                                                          _vm.use_widget_max_pers,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("label", {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.use_widget_max_pers =
                                                            _vm.has_right_to_update_service
                                                              ? !_vm.use_widget_max_pers
                                                              : _vm.use_widget_max_pers
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n                                                    " +
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.widget.useDefaultConfig"
                                                          )
                                                        ) +
                                                        "\n                                                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          !_vm.use_widget_max_pers
                                            ? _c(
                                                "div",
                                                { staticClass: "row mt-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-4" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: _vm.max_pers,
                                                            expression:
                                                              "max_pers",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          type: "number",
                                                          required:
                                                            !_vm.use_widget_max_pers,
                                                          disabled:
                                                            !_vm.has_right_to_update_service,
                                                          min: "1",
                                                          step: "1",
                                                        },
                                                        domProps: {
                                                          value: _vm.max_pers,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.max_pers =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      !_vm.$_.isEmpty(_vm.slots)
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2 mt-4" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-5" },
                                                  [
                                                    _c(
                                                      "i18n",
                                                      {
                                                        attrs: {
                                                          path: "labels.booking.services.enableMaxPax",
                                                          tag: "label",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "term" },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .$tl(
                                                                      "labels.booking.covers"
                                                                    )
                                                                    .toLowerCase()
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-7" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-inline-block align-middle lead-switch pt-1",
                                                      },
                                                      [
                                                        _c("input", {
                                                          staticClass:
                                                            "switch align-self-center is-rounded",
                                                          attrs: {
                                                            type: "checkbox",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              _vm.is_slot_max_pax_enabled,
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("label", {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.is_slot_max_pax_enabled =
                                                                !_vm.is_slot_max_pax_enabled
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.is_slot_max_pax_enabled == true
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "row mb-2 m-0",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-md-12 border-light p-4 mb-4",
                                                      },
                                                      [
                                                        _vm._l(
                                                          _vm.sortedSlotsKeys,
                                                          function (slotKey) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  key: slotKey,
                                                                  staticClass:
                                                                    "row mb-1",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-1",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-inline-block align-middle lead-switch",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              staticClass:
                                                                                "switch align-self-center is-small is-rounded",
                                                                              attrs:
                                                                                {
                                                                                  type: "checkbox",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  checked:
                                                                                    _vm
                                                                                      .slots[
                                                                                      slotKey
                                                                                    ]
                                                                                      .is_max_pax_enabled,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.changeSlotMaxPaxEnabled(
                                                                                      slotKey
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-1 pt-1",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                        " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .slots[
                                                                              slotKey
                                                                            ]
                                                                              .hour_start
                                                                          ) +
                                                                          "\n                                                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-3",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm
                                                                                    .slots[
                                                                                    slotKey
                                                                                  ]
                                                                                    .max_pax,
                                                                                expression:
                                                                                  "slots[slotKey].max_pax",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                !_vm.has_right_to_update_service ||
                                                                                !_vm
                                                                                  .slots[
                                                                                  slotKey
                                                                                ]
                                                                                  .is_max_pax_enabled,
                                                                              type: "number",
                                                                              name: `slot_${_vm.slots[slotKey].hour_start}_pax`,
                                                                              min: "0",
                                                                              step: "1",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .slots[
                                                                                  slotKey
                                                                                ]
                                                                                  .max_pax,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .slots[
                                                                                    slotKey
                                                                                  ],
                                                                                  "max_pax",
                                                                                  $event
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-6 pt-1",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                        " +
                                                                          _vm._s(
                                                                            _vm.$tl(
                                                                              "labels.booking.services.coversPerSlot"
                                                                            )
                                                                          ) +
                                                                          "\n                                                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-5" },
                                                  [
                                                    _c(
                                                      "i18n",
                                                      {
                                                        attrs: {
                                                          path: "labels.booking.services.enableMaxPax",
                                                          tag: "label",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "term" },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .$tl(
                                                                      "labels.booking.reservations.title"
                                                                    )
                                                                    .toLowerCase()
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-7" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-inline-block align-middle lead-switch pt-1",
                                                      },
                                                      [
                                                        _c("input", {
                                                          staticClass:
                                                            "switch align-self-center is-rounded",
                                                          attrs: {
                                                            type: "checkbox",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              _vm.is_slot_max_reservations_enabled,
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("label", {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.is_slot_max_reservations_enabled =
                                                                !_vm.is_slot_max_reservations_enabled
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.is_slot_max_reservations_enabled ==
                                            true
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "row mb-2 m-0",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-md-12 border-light p-4",
                                                      },
                                                      [
                                                        _vm._l(
                                                          _vm.sortedSlotsKeys,
                                                          function (slotKey) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  key: slotKey,
                                                                  staticClass:
                                                                    "row mb-1",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-1",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-inline-block align-middle lead-switch",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              staticClass:
                                                                                "switch align-self-center is-small is-rounded",
                                                                              attrs:
                                                                                {
                                                                                  type: "checkbox",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  checked:
                                                                                    _vm
                                                                                      .slots[
                                                                                      slotKey
                                                                                    ]
                                                                                      .is_max_reservations_enabled,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.changeSlotMaxReservationsEnabled(
                                                                                      slotKey
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-1 pt-1",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                        " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .slots[
                                                                              slotKey
                                                                            ]
                                                                              .hour_start
                                                                          ) +
                                                                          "\n                                                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-3",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm
                                                                                    .slots[
                                                                                    slotKey
                                                                                  ]
                                                                                    .max_reservations,
                                                                                expression:
                                                                                  "slots[slotKey].max_reservations",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "form-control",
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                !_vm.has_right_to_update_service ||
                                                                                !_vm
                                                                                  .slots[
                                                                                  slotKey
                                                                                ]
                                                                                  .is_max_reservations_enabled,
                                                                              type: "number",
                                                                              name: `slot_${_vm.slots[slotKey].hour_start}_reservation`,
                                                                              min: "0",
                                                                              step: "1",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .slots[
                                                                                  slotKey
                                                                                ]
                                                                                  .max_reservations,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .slots[
                                                                                    slotKey
                                                                                  ],
                                                                                  "max_reservations",
                                                                                  $event
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-6 pt-1",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                        " +
                                                                          _vm._s(
                                                                            _vm.$tl(
                                                                              "labels.booking.services.resasPerSlot"
                                                                            )
                                                                          ) +
                                                                          "\n                                                    "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "row mt-4 mb-4" },
                                        [
                                          _c("div", { staticClass: "col-5" }, [
                                            _c(
                                              "label",
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.booking.services.takeResas"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("small", [_vm._v("*")]),
                                                _vm._v(" "),
                                                _c("feather", {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.getTooltipNotice(
                                                          "notices.booking.servicesConfig.limitReservation"
                                                        ),
                                                      expression:
                                                        "getTooltipNotice('notices.booking.servicesConfig.limitReservation')",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "ml-1 pointer tooltip-infos",
                                                  attrs: { type: "info" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-7" }, [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "radio" },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "container-box",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.lock_reservation_until_enabled,
                                                                expression:
                                                                  "lock_reservation_until_enabled",
                                                              },
                                                            ],
                                                            attrs: {
                                                              type: "radio",
                                                              disabled:
                                                                !_vm.has_right_to_update_service,
                                                            },
                                                            domProps: {
                                                              value: false,
                                                              checked: _vm._q(
                                                                _vm.lock_reservation_until_enabled,
                                                                false
                                                              ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                _vm.lock_reservation_until_enabled = false
                                                              },
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("span", {
                                                            staticClass:
                                                              "checkmark",
                                                          }),
                                                          _vm._v(
                                                            "\n                                                        " +
                                                              _vm._s(
                                                                _vm.$tl(
                                                                  "labels.booking.services.takeResaLive",
                                                                  _vm.restaurant_id
                                                                )
                                                              ) +
                                                              "\n                                                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 d-inline radio mt-1",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "container-box",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.lock_reservation_until_enabled,
                                                            expression:
                                                              "lock_reservation_until_enabled",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "radio",
                                                          disabled:
                                                            !_vm.has_right_to_update_service,
                                                        },
                                                        domProps: {
                                                          value: true,
                                                          checked: _vm._q(
                                                            _vm.lock_reservation_until_enabled,
                                                            true
                                                          ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            _vm.lock_reservation_until_enabled = true
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", {
                                                        staticClass:
                                                          "checkmark",
                                                      }),
                                                      _vm._v(
                                                        "\n                                                    " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.booking.services.blockResas"
                                                            )
                                                          ) +
                                                          "\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.lock_reservation_until,
                                                        expression:
                                                          "lock_reservation_until",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-control d-inline",
                                                    staticStyle: {
                                                      width: "70px",
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      name: "lock_reservation_until",
                                                      disabled:
                                                        !_vm.lock_reservation_until_enabled ||
                                                        !_vm.has_right_to_update_service,
                                                      id: "lock_reservation_until",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.lock_reservation_until,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.lock_reservation_until =
                                                          $event.target.value
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.booking.services.minsBeforeStart",
                                                          _vm.restaurant_id
                                                        )
                                                      ) +
                                                      "\n                                            "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-5 pt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.services.enableOnWidget"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-7" }, [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-inline-block align-middle lead-switch mt-1 pt-2",
                                                  },
                                                  [
                                                    _c("input", {
                                                      staticClass:
                                                        "switch align-self-center is-rounded",
                                                      attrs: {
                                                        type: "checkbox",
                                                        disabled:
                                                          !_vm.has_right_to_update_service,
                                                      },
                                                      domProps: {
                                                        checked:
                                                          _vm.show_on_widget,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("label", {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.show_on_widget =
                                                            _vm.has_right_to_update_service
                                                              ? !_vm.show_on_widget
                                                              : _vm.show_on_widget
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-5 pt-2" },
                                          [
                                            _c(
                                              "label",
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.booking.services.cancelationDelay"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("small", [_vm._v("*")]),
                                                _vm._v(" "),
                                                _c("feather", {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.getTooltipNotice(
                                                          "notices.booking.servicesConfig.cancellationPeriod"
                                                        ),
                                                      expression:
                                                        "getTooltipNotice('notices.booking.servicesConfig.cancellationPeriod')",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "ml-1 pointer tooltip-infos",
                                                  attrs: { type: "info" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-7" }, [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-4" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.cancellation_until,
                                                      expression:
                                                        "cancellation_until",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    disabled:
                                                      !_vm.has_right_to_update_service,
                                                    type: "number",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.cancellation_until,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.cancellation_until =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-8 mt-2" },
                                              [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.booking.services.hoursBeforeResa"
                                                      )
                                                    ) +
                                                    "\n                                            "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "row mb-2 mt-4" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-5 pt-2" },
                                            [
                                              _c(
                                                "label",
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.booking.services.resasConfirm"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("small", [_vm._v("*")]),
                                                  _vm._v(" "),
                                                  _c("feather", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value:
                                                          _vm.getTooltipNotice(
                                                            "notices.booking.servicesConfig.reservationConfirm"
                                                          ),
                                                        expression:
                                                          "getTooltipNotice('notices.booking.servicesConfig.reservationConfirm')",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "ml-1 pointer tooltip-infos",
                                                    attrs: { type: "info" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-7 radio w-100 h-100 d-table pt-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-table-cell align-middle",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "container-box",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.autoconfirm,
                                                            expression:
                                                              "autoconfirm",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled:
                                                            !_vm.has_right_to_update_service,
                                                          type: "radio",
                                                          "true-value": "0",
                                                          value: "0",
                                                        },
                                                        domProps: {
                                                          checked: _vm._q(
                                                            _vm.autoconfirm,
                                                            "0"
                                                          ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            _vm.autoconfirm =
                                                              "0"
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", {
                                                        staticClass:
                                                          "checkmark",
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.manual"
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "container-box",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.autoconfirm,
                                                            expression:
                                                              "autoconfirm",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled:
                                                            !_vm.has_right_to_update_service,
                                                          type: "radio",
                                                          "true-value": "1",
                                                          value: "1",
                                                        },
                                                        domProps: {
                                                          checked: _vm._q(
                                                            _vm.autoconfirm,
                                                            "1"
                                                          ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            _vm.autoconfirm =
                                                              "1"
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", {
                                                        staticClass:
                                                          "checkmark",
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.auto"
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.isAutoconfirm
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-4 pt-2 offset-1",
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.$tl("labels.to")
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-7" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-4",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.autoconfirm_percent,
                                                                  expression:
                                                                    "autoconfirm_percent",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "number",
                                                                disabled:
                                                                  _vm.autoconfirm ==
                                                                    0 ||
                                                                  !_vm.has_right_to_update_service,
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.autoconfirm_percent,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.autoconfirm_percent =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-8 mt-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$tl(
                                                                    "labels.booking.services.percentFillRate"
                                                                  )
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-4 pt-2 offset-1",
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.$tl("labels.to")
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-7" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-4",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.autoconfirm_max_pers,
                                                                  expression:
                                                                    "autoconfirm_max_pers",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "number",
                                                                disabled:
                                                                  _vm.autoconfirm ==
                                                                    0 ||
                                                                  !_vm.has_right_to_update_service,
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.autoconfirm_max_pers,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.autoconfirm_max_pers =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-8 mt-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$tl(
                                                                    "labels.personss"
                                                                  )
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-4 pt-2 offset-1",
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.$tl("labels.to")
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-7" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-4",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.autoconfirm_until,
                                                                  expression:
                                                                    "autoconfirm_until",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "number",
                                                                disabled:
                                                                  _vm.autoconfirm ==
                                                                    0 ||
                                                                  !_vm.has_right_to_update_service,
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.autoconfirm_until,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.autoconfirm_until =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-8 mt-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$tl(
                                                                    "labels.booking.services.minsBeforeSlotStarts"
                                                                  )
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-4 offset-1",
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.booking.services.blockAutoConfirm"
                                                            )
                                                          )
                                                      ),
                                                      _c("br"),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-7" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-4",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.lock_nb_noshow,
                                                                  expression:
                                                                    "lock_nb_noshow",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "number",
                                                                disabled:
                                                                  _vm.autoconfirm ==
                                                                    0 ||
                                                                  !_vm.has_right_to_update_service,
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.lock_nb_noshow,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.lock_nb_noshow =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-8 mt-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.capitalize(
                                                                    _vm.$tl(
                                                                      "labels.clients.noshow"
                                                                    )
                                                                  )
                                                                )
                                                            ),
                                                            _c("small", [
                                                              _vm._v(
                                                                " - " +
                                                                  _vm._s(
                                                                    _vm.$tl(
                                                                      "infos.booking.services.blockAutoConfirm"
                                                                    )
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "row mb-2 mt-4" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-5 pt-2" },
                                            [
                                              _c(
                                                "label",
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.booking.services.enableTableRotation",
                                                          _vm.restaurant_id
                                                        )
                                                      ) +
                                                      "\n                                            "
                                                  ),
                                                  _c("feather", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value:
                                                          _vm.getTooltipNoticeLexicon(
                                                            "notices.booking.servicesConfig.tableRotation",
                                                            _vm.restaurant_id
                                                          ),
                                                        expression:
                                                          "getTooltipNoticeLexicon('notices.booking.servicesConfig.tableRotation', restaurant_id)",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "ml-1 pointer tooltip-infos",
                                                    attrs: { type: "info" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-7 w-100 h-100 d-table pt-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-table-cell align-middle",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "container-box",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.paxes
                                                                .rotation,
                                                            expression:
                                                              "paxes.rotation",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled:
                                                            !_vm.has_right_to_update_service,
                                                          type: "checkbox",
                                                          name: "enable_table_rotation",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.paxes.rotation
                                                            )
                                                              ? _vm._i(
                                                                  _vm.paxes
                                                                    .rotation,
                                                                  null
                                                                ) > -1
                                                              : _vm.paxes
                                                                  .rotation,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.paxes
                                                                  .rotation,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm.paxes,
                                                                    "rotation",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm.paxes,
                                                                    "rotation",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.paxes,
                                                                "rotation",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", {
                                                        staticClass:
                                                          "checkmark",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.paxes.rotation
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 pt-1",
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.booking.services.averageTimeAtTable"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.paxes.value,
                                                function (pax, index) {
                                                  return [
                                                    pax.pax <= 10
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: index,
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "offset-3 col-4 pt-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    pax.pax ===
                                                                      10
                                                                      ? "+"
                                                                      : ""
                                                                  ) +
                                                                    _vm._s(
                                                                      pax.pax
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-5 pt-1",
                                                              },
                                                              [
                                                                pax.pax < 10
                                                                  ? _c(
                                                                      "vue-timepicker",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            !_vm.has_right_to_update_service,
                                                                          format:
                                                                            "HH:mm",
                                                                          "minute-interval": 15,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            pax.duration,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                pax,
                                                                                "duration",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "pax.duration",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "vue-timepicker",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            !_vm.has_right_to_update_service,
                                                                          "minute-interval": 15,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.setPaxesHigherThanTen(
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            pax.duration,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                pax,
                                                                                "duration",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "pax.duration",
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasFeatSeatingPlan
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2 mt-2" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-5 pt-2" },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.booking.seatingPlan.title"
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-7 w-100 h-100 d-table pt-2",
                                                  },
                                                  [
                                                    _c("div", [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.seatingPlan
                                                                  .value,
                                                              expression:
                                                                "seatingPlan.value",
                                                            },
                                                            {
                                                              name: "tooltip",
                                                              rawName:
                                                                "v-tooltip",
                                                              value: !this
                                                                .hasSeatingPlan
                                                                ? {
                                                                    content:
                                                                      _vm.$t(
                                                                        "infos.booking.services.enableSeatingPlan"
                                                                      ),
                                                                  }
                                                                : null,
                                                              expression:
                                                                "\n                                                        !this.hasSeatingPlan\n                                                            ? {\n                                                                  content: $t('infos.booking.services.enableSeatingPlan'),\n                                                              }\n                                                            : null\n                                                    ",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "custom-select",
                                                          attrs: {
                                                            disabled:
                                                              !this
                                                                .hasSeatingPlan,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                _vm.seatingPlan,
                                                                "value",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              domProps: {
                                                                value: null,
                                                              },
                                                            },
                                                            [_vm._v("--")]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm._l(
                                                            _vm.seatingPlans,
                                                            function (
                                                              seatingPlan
                                                            ) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key: seatingPlan.id,
                                                                  domProps: {
                                                                    value:
                                                                      seatingPlan,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                        " +
                                                                      _vm._s(
                                                                        seatingPlan.name
                                                                      ) +
                                                                      "\n                                                    "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _vm.seatingPlan
                                                      .algorithm_enabled &&
                                                    _vm.seatingPlan.hasChanged
                                                      ? _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "d-block text-muted",
                                                          },
                                                          _vm._l(
                                                            _vm.$tl(
                                                              "infos.booking.services.changeSeatingPlan",
                                                              _vm.restaurant_id
                                                            ),
                                                            function (
                                                              tmpMsg,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "span",
                                                                { key: index },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      tmpMsg
                                                                    )
                                                                  ),
                                                                  _c("br"),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.seatingPlan.value
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row mb-2 mt-2",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-5 pt-2",
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  _vm.$tl(
                                                                    "labels.booking.services.enableAlgorithm",
                                                                    _vm.restaurant_id
                                                                  )
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                            _c("feather", {
                                                              directives: [
                                                                {
                                                                  name: "tooltip",
                                                                  rawName:
                                                                    "v-tooltip",
                                                                  value:
                                                                    _vm.getTooltipNoticeLexicon(
                                                                      "notices.booking.servicesConfig.automaticSeatingPlan",
                                                                      _vm.restaurant_id
                                                                    ),
                                                                  expression:
                                                                    "\n                                                        getTooltipNoticeLexicon(\n                                                            'notices.booking.servicesConfig.automaticSeatingPlan',\n                                                            restaurant_id\n                                                        )\n                                                    ",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "ml-1 pointer tooltip-infos",
                                                              attrs: {
                                                                type: "info",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-7 w-100 h-100 d-table pt-2",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-table-cell align-middle",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "tooltip",
                                                                    rawName:
                                                                      "v-tooltip",
                                                                    value: !this
                                                                      .hasSeatingPlan
                                                                      ? {
                                                                          content:
                                                                            _vm.$t(
                                                                              "infos.booking.services.enableSeatingPlan"
                                                                            ),
                                                                        }
                                                                      : null,
                                                                    expression:
                                                                      "\n                                                        !this.hasSeatingPlan\n                                                            ? {\n                                                                  content: $t('infos.booking.services.enableSeatingPlan'),\n                                                              }\n                                                            : null\n                                                    ",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "container-box",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .seatingPlan
                                                                          .algorithm_enabled,
                                                                      expression:
                                                                        "seatingPlan.algorithm_enabled",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                    disabled:
                                                                      !this
                                                                        .hasSeatingPlan ||
                                                                      !_vm.has_right_to_update_service,
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm
                                                                          .seatingPlan
                                                                          .algorithm_enabled
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm
                                                                              .seatingPlan
                                                                              .algorithm_enabled,
                                                                            null
                                                                          ) > -1
                                                                        : _vm
                                                                            .seatingPlan
                                                                            .algorithm_enabled,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm
                                                                              .seatingPlan
                                                                              .algorithm_enabled,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              _vm.$set(
                                                                                _vm.seatingPlan,
                                                                                "algorithm_enabled",
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                )
                                                                              )
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              _vm.$set(
                                                                                _vm.seatingPlan,
                                                                                "algorithm_enabled",
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                              )
                                                                          }
                                                                        } else {
                                                                          _vm.$set(
                                                                            _vm.seatingPlan,
                                                                            "algorithm_enabled",
                                                                            $$c
                                                                          )
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("span", {
                                                                  staticClass:
                                                                    "checkmark",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.seatingPlan.algorithm_enabled
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row mb-2 mt-2",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-5 pt-2",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(
                                                                _vm.$tl(
                                                                  "labels.booking.services.allowClientsChooseRoom"
                                                                )
                                                              ) +
                                                              "\n                                            "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-7 w-100 h-100 d-table pt-2",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-table-cell align-middle",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "container-box",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .seatingPlan
                                                                          .allow_clients_choose_room,
                                                                      expression:
                                                                        "seatingPlan.allow_clients_choose_room",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm
                                                                          .seatingPlan
                                                                          .allow_clients_choose_room
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm
                                                                              .seatingPlan
                                                                              .allow_clients_choose_room,
                                                                            null
                                                                          ) > -1
                                                                        : _vm
                                                                            .seatingPlan
                                                                            .allow_clients_choose_room,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm
                                                                              .seatingPlan
                                                                              .allow_clients_choose_room,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              _vm.$set(
                                                                                _vm.seatingPlan,
                                                                                "allow_clients_choose_room",
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                )
                                                                              )
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              _vm.$set(
                                                                                _vm.seatingPlan,
                                                                                "allow_clients_choose_room",
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                              )
                                                                          }
                                                                        } else {
                                                                          _vm.$set(
                                                                            _vm.seatingPlan,
                                                                            "allow_clients_choose_room",
                                                                            $$c
                                                                          )
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("span", {
                                                                  staticClass:
                                                                    "checkmark",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.seatingPlan
                                                          .allow_clients_choose_room
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "radio",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-table-cell align-middle",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "container-box",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .seatingPlan
                                                                                      .allow_clients_choose_room_mandatory,
                                                                                  expression:
                                                                                    "seatingPlan.allow_clients_choose_room_mandatory",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  !_vm.has_right_to_update_service,
                                                                                type: "radio",
                                                                                value:
                                                                                  "0",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                checked:
                                                                                  _vm._q(
                                                                                    _vm
                                                                                      .seatingPlan
                                                                                      .allow_clients_choose_room_mandatory,
                                                                                    "0"
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.$set(
                                                                                    _vm.seatingPlan,
                                                                                    "allow_clients_choose_room_mandatory",
                                                                                    "0"
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "checkmark",
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$tl(
                                                                                "labels.optional"
                                                                              )
                                                                            ) +
                                                                            "\n                                                    "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "container-box",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .seatingPlan
                                                                                      .allow_clients_choose_room_mandatory,
                                                                                  expression:
                                                                                    "seatingPlan.allow_clients_choose_room_mandatory",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  !_vm.has_right_to_update_service,
                                                                                type: "radio",
                                                                                value:
                                                                                  "1",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                checked:
                                                                                  _vm._q(
                                                                                    _vm
                                                                                      .seatingPlan
                                                                                      .allow_clients_choose_room_mandatory,
                                                                                    "1"
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.$set(
                                                                                    _vm.seatingPlan,
                                                                                    "allow_clients_choose_room_mandatory",
                                                                                    "1"
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "checkmark",
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$tl(
                                                                                "labels.mandatory"
                                                                              )
                                                                            ) +
                                                                            "\n                                                    "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "row mb-2 mt-2" },
                                        [
                                          _c("div", { staticClass: "col-5" }, [
                                            _c(
                                              "label",
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.booking.menus.externalMenu.externalMenu",
                                                        _vm.restaurant_id
                                                      )
                                                    ) +
                                                    "\n                                            "
                                                ),
                                                _c("feather", {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.getTooltipNotice(
                                                          "notices.booking.externalMenus"
                                                        ),
                                                      expression:
                                                        "getTooltipNotice('notices.booking.externalMenus')",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "ml-1 pointer tooltip-infos",
                                                  attrs: { type: "info" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-7" }, [
                                            _c(
                                              "div",
                                              { staticClass: "radio" },
                                              [
                                                _c(
                                                  "RadioButtonComponent",
                                                  {
                                                    attrs: {
                                                      inputValue:
                                                        _vm.EXTERNAL_MENU_FILE
                                                          .value,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.externalMenu.type,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.externalMenu,
                                                          "type",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "externalMenu.type",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.menus.externalMenu.file.pdf"
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "RadioButtonComponent",
                                                  {
                                                    attrs: {
                                                      inputValue:
                                                        _vm.EXTERNAL_MENU_LINK
                                                          .value,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.externalMenu.type,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.externalMenu,
                                                          "type",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "externalMenu.type",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.menus.externalMenu.link.link"
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "RadioButtonComponent",
                                                  {
                                                    attrs: { inputValue: null },
                                                    model: {
                                                      value:
                                                        _vm.externalMenu.type,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.externalMenu,
                                                          "type",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "externalMenu.type",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.menus.externalMenu.disabled"
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.externalMenu.type ===
                                            _vm.EXTERNAL_MENU_FILE.value
                                              ? _c(
                                                  "div",
                                                  { staticClass: "my-2" },
                                                  [
                                                    _c(
                                                      "FileUploaderComponent",
                                                      {
                                                        attrs: {
                                                          hasDirectUpload: false,
                                                          suffixId: "ext-desc",
                                                          src: _vm.externalMenu
                                                            .pathFile,
                                                          deleteURI:
                                                            _vm.deleteURIFile,
                                                          "max-size": 20,
                                                          "props-file":
                                                            _vm.externalMenu
                                                              .rawFile,
                                                          "call-back-delete":
                                                            () => {
                                                              _vm.externalMenu.pathFile =
                                                                ""
                                                              _vm.externalMenu.rawFile =
                                                                null
                                                              _vm.externalMenu.content.file =
                                                                ""
                                                            },
                                                        },
                                                        on: {
                                                          "upload-file":
                                                            function ($event) {
                                                              _vm.externalMenu.rawFile =
                                                                $event
                                                            },
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.externalMenu.type ===
                                            _vm.EXTERNAL_MENU_LINK.value
                                              ? _c(
                                                  "div",
                                                  { staticClass: "my-2" },
                                                  [
                                                    _c("InputComponent", {
                                                      attrs: {
                                                        label: "",
                                                        placeholder: _vm.$t(
                                                          "labels.booking.menus.externalMenu.link.placeholder"
                                                        ),
                                                        labelWrapperClasses:
                                                          "d-none",
                                                        type: "url",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.externalMenu
                                                            .content.link,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.externalMenu
                                                              .content,
                                                            "link",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "externalMenu.content.link",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row m-0" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "border-light b-radius-20 p-4 mb-3",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "row",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              _vm.showMenuOption =
                                                !_vm.showMenuOption
                                            },
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.booking.services.menusAndOptions.title",
                                                    _vm.$route.params
                                                      .restaurant_id
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.showMenuOption
                                        ? _c(
                                            "div",
                                            { staticClass: "menu-option mt-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "row mb-3 pb-3",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-3" },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.booking.services.menusAndOptions.link",
                                                              _vm.$route.params
                                                                .restaurant_id
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-9" },
                                                    [
                                                      _vm.available_menus
                                                        .length > 0
                                                        ? _c(
                                                            "div",
                                                            _vm._l(
                                                              _vm.available_menus,
                                                              function (menu) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: menu.id,
                                                                    staticClass:
                                                                      "mb-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "container-box",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-bottom":
                                                                              "0",
                                                                            width:
                                                                              "100%",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "checkbox",
                                                                                disabled:
                                                                                  !_vm.has_right_to_update_service,
                                                                                name: "menus[]",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                checked:
                                                                                  _vm
                                                                                    .menus[
                                                                                    `id_${menu.id}`
                                                                                  ] &&
                                                                                  _vm
                                                                                    .menus[
                                                                                    `id_${menu.id}`
                                                                                  ]
                                                                                    .selected,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.changedMenu(
                                                                                    $event,
                                                                                    menu
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "checkmark",
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              menu.name
                                                                            ) +
                                                                            "\n                                                    "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _vm.menus[
                                                                      `id_${menu.id}`
                                                                    ] &&
                                                                    _vm.menus[
                                                                      `id_${menu.id}`
                                                                    ].selected
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "mt-1",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "row mb-0",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-5 offset-1",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "label",
                                                                                      {
                                                                                        staticClass:
                                                                                          "container-box",
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "30px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "input",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "model",
                                                                                                  rawName:
                                                                                                    "v-model",
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .menus[
                                                                                                      `id_${menu.id}`
                                                                                                    ]
                                                                                                      .enable_nb_min_pers,
                                                                                                  expression:
                                                                                                    "menus[`id_${menu.id}`].enable_nb_min_pers",
                                                                                                },
                                                                                              ],
                                                                                            attrs:
                                                                                              {
                                                                                                disabled:
                                                                                                  !_vm.has_right_to_update_service,
                                                                                                type: "checkbox",
                                                                                                "true-value":
                                                                                                  "1",
                                                                                              },
                                                                                            domProps:
                                                                                              {
                                                                                                checked:
                                                                                                  Array.isArray(
                                                                                                    _vm
                                                                                                      .menus[
                                                                                                      `id_${menu.id}`
                                                                                                    ]
                                                                                                      .enable_nb_min_pers
                                                                                                  )
                                                                                                    ? _vm._i(
                                                                                                        _vm
                                                                                                          .menus[
                                                                                                          `id_${menu.id}`
                                                                                                        ]
                                                                                                          .enable_nb_min_pers,
                                                                                                        null
                                                                                                      ) >
                                                                                                      -1
                                                                                                    : _vm._q(
                                                                                                        _vm
                                                                                                          .menus[
                                                                                                          `id_${menu.id}`
                                                                                                        ]
                                                                                                          .enable_nb_min_pers,
                                                                                                        "1"
                                                                                                      ),
                                                                                              },
                                                                                            on: {
                                                                                              change:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  var $$a =
                                                                                                      _vm
                                                                                                        .menus[
                                                                                                        `id_${menu.id}`
                                                                                                      ]
                                                                                                        .enable_nb_min_pers,
                                                                                                    $$el =
                                                                                                      $event.target,
                                                                                                    $$c =
                                                                                                      $$el.checked
                                                                                                        ? "1"
                                                                                                        : false
                                                                                                  if (
                                                                                                    Array.isArray(
                                                                                                      $$a
                                                                                                    )
                                                                                                  ) {
                                                                                                    var $$v =
                                                                                                        null,
                                                                                                      $$i =
                                                                                                        _vm._i(
                                                                                                          $$a,
                                                                                                          $$v
                                                                                                        )
                                                                                                    if (
                                                                                                      $$el.checked
                                                                                                    ) {
                                                                                                      $$i <
                                                                                                        0 &&
                                                                                                        _vm.$set(
                                                                                                          _vm
                                                                                                            .menus[
                                                                                                            `id_${menu.id}`
                                                                                                          ],
                                                                                                          "enable_nb_min_pers",
                                                                                                          $$a.concat(
                                                                                                            [
                                                                                                              $$v,
                                                                                                            ]
                                                                                                          )
                                                                                                        )
                                                                                                    } else {
                                                                                                      $$i >
                                                                                                        -1 &&
                                                                                                        _vm.$set(
                                                                                                          _vm
                                                                                                            .menus[
                                                                                                            `id_${menu.id}`
                                                                                                          ],
                                                                                                          "enable_nb_min_pers",
                                                                                                          $$a
                                                                                                            .slice(
                                                                                                              0,
                                                                                                              $$i
                                                                                                            )
                                                                                                            .concat(
                                                                                                              $$a.slice(
                                                                                                                $$i +
                                                                                                                  1
                                                                                                              )
                                                                                                            )
                                                                                                        )
                                                                                                    }
                                                                                                  } else {
                                                                                                    _vm.$set(
                                                                                                      _vm
                                                                                                        .menus[
                                                                                                        `id_${menu.id}`
                                                                                                      ],
                                                                                                      "enable_nb_min_pers",
                                                                                                      $$c
                                                                                                    )
                                                                                                  }
                                                                                                },
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "checkmark",
                                                                                            staticStyle:
                                                                                              {
                                                                                                "margin-top":
                                                                                                  "-2px",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "label",
                                                                                      {
                                                                                        staticClass:
                                                                                          "pt-2",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$tl(
                                                                                              "labels.booking.services.menusAndOptions.enableFrom",
                                                                                              _vm
                                                                                                .$route
                                                                                                .params
                                                                                                .restaurant_id
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-6 d-flex align-items-center",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "input",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "model",
                                                                                              rawName:
                                                                                                "v-model",
                                                                                              value:
                                                                                                _vm
                                                                                                  .menus[
                                                                                                  `id_${menu.id}`
                                                                                                ]
                                                                                                  .nb_min_pers,
                                                                                              expression:
                                                                                                "menus[`id_${menu.id}`].nb_min_pers",
                                                                                            },
                                                                                          ],
                                                                                        staticClass:
                                                                                          "form-control",
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "30%",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            type: "number",
                                                                                            step: "1",
                                                                                            min: "0",
                                                                                            name: "nb_pers_min",
                                                                                            id: "nb_pers_min",
                                                                                            disabled:
                                                                                              !_vm
                                                                                                .menus[
                                                                                                `id_${menu.id}`
                                                                                              ]
                                                                                                .enable_nb_min_pers ||
                                                                                              !_vm.has_right_to_update_service,
                                                                                          },
                                                                                        domProps:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .menus[
                                                                                                `id_${menu.id}`
                                                                                              ]
                                                                                                .nb_min_pers,
                                                                                          },
                                                                                        on: {
                                                                                          input:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              if (
                                                                                                $event
                                                                                                  .target
                                                                                                  .composing
                                                                                              )
                                                                                                return
                                                                                              _vm.$set(
                                                                                                _vm
                                                                                                  .menus[
                                                                                                  `id_${menu.id}`
                                                                                                ],
                                                                                                "nb_min_pers",
                                                                                                $event
                                                                                                  .target
                                                                                                  .value
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticClass:
                                                                                          "m-0 p-0 ml-2",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                    " +
                                                                                            _vm._s(
                                                                                              _vm.capitalize(
                                                                                                _vm.$tl(
                                                                                                  "labels.persons"
                                                                                                )
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                                                "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "row mb-3",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-5 offset-1",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "label",
                                                                                      {
                                                                                        staticClass:
                                                                                          "container-box",
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "30px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "input",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "model",
                                                                                                  rawName:
                                                                                                    "v-model",
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .menus[
                                                                                                      `id_${menu.id}`
                                                                                                    ]
                                                                                                      .enable_nb_max_pers,
                                                                                                  expression:
                                                                                                    "menus[`id_${menu.id}`].enable_nb_max_pers",
                                                                                                },
                                                                                              ],
                                                                                            attrs:
                                                                                              {
                                                                                                disabled:
                                                                                                  !_vm.has_right_to_update_service,
                                                                                                type: "checkbox",
                                                                                                "true-value":
                                                                                                  "1",
                                                                                              },
                                                                                            domProps:
                                                                                              {
                                                                                                checked:
                                                                                                  Array.isArray(
                                                                                                    _vm
                                                                                                      .menus[
                                                                                                      `id_${menu.id}`
                                                                                                    ]
                                                                                                      .enable_nb_max_pers
                                                                                                  )
                                                                                                    ? _vm._i(
                                                                                                        _vm
                                                                                                          .menus[
                                                                                                          `id_${menu.id}`
                                                                                                        ]
                                                                                                          .enable_nb_max_pers,
                                                                                                        null
                                                                                                      ) >
                                                                                                      -1
                                                                                                    : _vm._q(
                                                                                                        _vm
                                                                                                          .menus[
                                                                                                          `id_${menu.id}`
                                                                                                        ]
                                                                                                          .enable_nb_max_pers,
                                                                                                        "1"
                                                                                                      ),
                                                                                              },
                                                                                            on: {
                                                                                              change:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  var $$a =
                                                                                                      _vm
                                                                                                        .menus[
                                                                                                        `id_${menu.id}`
                                                                                                      ]
                                                                                                        .enable_nb_max_pers,
                                                                                                    $$el =
                                                                                                      $event.target,
                                                                                                    $$c =
                                                                                                      $$el.checked
                                                                                                        ? "1"
                                                                                                        : false
                                                                                                  if (
                                                                                                    Array.isArray(
                                                                                                      $$a
                                                                                                    )
                                                                                                  ) {
                                                                                                    var $$v =
                                                                                                        null,
                                                                                                      $$i =
                                                                                                        _vm._i(
                                                                                                          $$a,
                                                                                                          $$v
                                                                                                        )
                                                                                                    if (
                                                                                                      $$el.checked
                                                                                                    ) {
                                                                                                      $$i <
                                                                                                        0 &&
                                                                                                        _vm.$set(
                                                                                                          _vm
                                                                                                            .menus[
                                                                                                            `id_${menu.id}`
                                                                                                          ],
                                                                                                          "enable_nb_max_pers",
                                                                                                          $$a.concat(
                                                                                                            [
                                                                                                              $$v,
                                                                                                            ]
                                                                                                          )
                                                                                                        )
                                                                                                    } else {
                                                                                                      $$i >
                                                                                                        -1 &&
                                                                                                        _vm.$set(
                                                                                                          _vm
                                                                                                            .menus[
                                                                                                            `id_${menu.id}`
                                                                                                          ],
                                                                                                          "enable_nb_max_pers",
                                                                                                          $$a
                                                                                                            .slice(
                                                                                                              0,
                                                                                                              $$i
                                                                                                            )
                                                                                                            .concat(
                                                                                                              $$a.slice(
                                                                                                                $$i +
                                                                                                                  1
                                                                                                              )
                                                                                                            )
                                                                                                        )
                                                                                                    }
                                                                                                  } else {
                                                                                                    _vm.$set(
                                                                                                      _vm
                                                                                                        .menus[
                                                                                                        `id_${menu.id}`
                                                                                                      ],
                                                                                                      "enable_nb_max_pers",
                                                                                                      $$c
                                                                                                    )
                                                                                                  }
                                                                                                },
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "checkmark",
                                                                                            staticStyle:
                                                                                              {
                                                                                                "margin-top":
                                                                                                  "-2px",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "label",
                                                                                      {
                                                                                        staticClass:
                                                                                          "pt-2",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$tl(
                                                                                              "labels.booking.services.menusAndOptions.disableFrom",
                                                                                              _vm
                                                                                                .$route
                                                                                                .params
                                                                                                .restaurant_id
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-6 d-flex align-items-center",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "input",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "model",
                                                                                              rawName:
                                                                                                "v-model",
                                                                                              value:
                                                                                                _vm
                                                                                                  .menus[
                                                                                                  `id_${menu.id}`
                                                                                                ]
                                                                                                  .nb_max_pers,
                                                                                              expression:
                                                                                                "menus[`id_${menu.id}`].nb_max_pers",
                                                                                            },
                                                                                          ],
                                                                                        staticClass:
                                                                                          "form-control",
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "30%",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            type: "number",
                                                                                            step: "1",
                                                                                            min: "0",
                                                                                            name: "nb_pers_max",
                                                                                            id: "nb_pers_max",
                                                                                            disabled:
                                                                                              !_vm
                                                                                                .menus[
                                                                                                `id_${menu.id}`
                                                                                              ]
                                                                                                .enable_nb_max_pers ||
                                                                                              !_vm.has_right_to_update_service,
                                                                                          },
                                                                                        domProps:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .menus[
                                                                                                `id_${menu.id}`
                                                                                              ]
                                                                                                .nb_max_pers,
                                                                                          },
                                                                                        on: {
                                                                                          input:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              if (
                                                                                                $event
                                                                                                  .target
                                                                                                  .composing
                                                                                              )
                                                                                                return
                                                                                              _vm.$set(
                                                                                                _vm
                                                                                                  .menus[
                                                                                                  `id_${menu.id}`
                                                                                                ],
                                                                                                "nb_max_pers",
                                                                                                $event
                                                                                                  .target
                                                                                                  .value
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticClass:
                                                                                          "m-0 p-0 ml-2",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                    " +
                                                                                            _vm._s(
                                                                                              _vm.capitalize(
                                                                                                _vm.$tl(
                                                                                                  "labels.persons"
                                                                                                )
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                                                "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "mt-4 mb-4",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-success btn-circle btn-sm",
                                                              class: {
                                                                disabled:
                                                                  !_vm.has_right_to_create_menu,
                                                              },
                                                              attrs: {
                                                                type: "button",
                                                                disabled:
                                                                  !_vm.has_right_to_create_menu,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.showAddMenuModal = true
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                    " +
                                                                  _vm._s(
                                                                    _vm.$tl(
                                                                      "labels.booking.menus.add",
                                                                      _vm.restaurant_id
                                                                    )
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              !_vm.isMenusEmpty
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "row mb-0",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-5",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              [
                                                                _vm._v(
                                                                  "\n                                                    " +
                                                                    _vm._s(
                                                                      _vm.$tl(
                                                                        "labels.booking.menus.mandatory",
                                                                        _vm.restaurant_id
                                                                      )
                                                                    ) +
                                                                    "\n                                                    "
                                                                ),
                                                                _c("feather", {
                                                                  directives: [
                                                                    {
                                                                      name: "tooltip",
                                                                      rawName:
                                                                        "v-tooltip",
                                                                      value:
                                                                        _vm.getTooltipNotice(
                                                                          "notices.booking.servicesConfig.mandatoryMenu"
                                                                        ),
                                                                      expression:
                                                                        "getTooltipNotice('notices.booking.servicesConfig.mandatoryMenu')",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "ml-1 pointer tooltip-infos",
                                                                  attrs: {
                                                                    type: "info",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-7",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "container-box",
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.menu_mandatory,
                                                                      expression:
                                                                        "menu_mandatory",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                    name: "menu_unique",
                                                                    disabled:
                                                                      _vm.isMenusEmpty ||
                                                                      !_vm.has_right_to_update_service,
                                                                    readonly:
                                                                      _vm.isMenusEmpty ||
                                                                      !_vm.has_right_to_update_service,
                                                                  },
                                                                  domProps: {
                                                                    value: true,
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm.menu_mandatory
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm.menu_mandatory,
                                                                            true
                                                                          ) > -1
                                                                        : _vm.menu_mandatory,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm.menu_mandatory,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v = true,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              (_vm.menu_mandatory =
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                ))
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              (_vm.menu_mandatory =
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  ))
                                                                          }
                                                                        } else {
                                                                          _vm.menu_mandatory =
                                                                            $$c
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("span", {
                                                                  staticClass:
                                                                    "checkmark",
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$tl(
                                                                        "labels.yes"
                                                                      )
                                                                    ) +
                                                                    "\n                                                "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.menu_mandatory
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row mb-4",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-5 pt-2",
                                                              },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$tl(
                                                                        "labels.booking.menus.mandatoryFrom",
                                                                        _vm.restaurant_id
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-7",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-4",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm.menu_mandatory_from,
                                                                                  expression:
                                                                                    "menu_mandatory_from",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "form-control",
                                                                            attrs:
                                                                              {
                                                                                type: "number",
                                                                                name: "menu_mandatory_from",
                                                                                min: "1",
                                                                                step: "1",
                                                                                disabled:
                                                                                  _vm.isMenusEmpty ||
                                                                                  !_vm.has_right_to_update_service ||
                                                                                  !_vm.menu_mandatory,
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  _vm.menu_mandatory_from,
                                                                              },
                                                                            on: {
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  )
                                                                                    return
                                                                                  _vm.menu_mandatory_from =
                                                                                    $event.target.value
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-8 mt-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                        " +
                                                                            _vm._s(
                                                                              _vm.$tl(
                                                                                "labels.personss"
                                                                              )
                                                                            ) +
                                                                            "\n                                                    "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "row mb-0",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-5",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              [
                                                                _vm._v(
                                                                  "\n                                                    " +
                                                                    _vm._s(
                                                                      _vm.$tl(
                                                                        "labels.booking.menus.unique",
                                                                        _vm.restaurant_id
                                                                      )
                                                                    ) +
                                                                    "\n                                                    "
                                                                ),
                                                                _c("feather", {
                                                                  directives: [
                                                                    {
                                                                      name: "tooltip",
                                                                      rawName:
                                                                        "v-tooltip",
                                                                      value:
                                                                        _vm.getTooltipNoticeLexicon(
                                                                          "notices.booking.servicesConfig.uniqueMenu",
                                                                          _vm.restaurantId
                                                                        ),
                                                                      expression:
                                                                        "\n                                                            getTooltipNoticeLexicon('notices.booking.servicesConfig.uniqueMenu', restaurantId)\n                                                        ",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "ml-1 pointer tooltip-infos",
                                                                  attrs: {
                                                                    type: "info",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-7",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "container-box",
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.menu_unique,
                                                                      expression:
                                                                        "menu_unique",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                    name: "menu_unique",
                                                                    "true-value":
                                                                      "1",
                                                                    disabled:
                                                                      _vm.isMenusEmpty ||
                                                                      !_vm.has_right_to_update_service,
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm.menu_unique
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm.menu_unique,
                                                                            null
                                                                          ) > -1
                                                                        : _vm._q(
                                                                            _vm.menu_unique,
                                                                            "1"
                                                                          ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm.menu_unique,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? "1"
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              (_vm.menu_unique =
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                ))
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              (_vm.menu_unique =
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  ))
                                                                          }
                                                                        } else {
                                                                          _vm.menu_unique =
                                                                            $$c
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("span", {
                                                                  staticClass:
                                                                    "checkmark",
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$tl(
                                                                        "labels.yes"
                                                                      )
                                                                    ) +
                                                                    "\n                                                "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.menu_unique
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row mb-4",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-5 pt-2",
                                                              },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$tl(
                                                                        "labels.booking.menus.uniqueFrom",
                                                                        _vm.restaurant_id
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-7",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-4",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm.menu_unique_from,
                                                                                  expression:
                                                                                    "menu_unique_from",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "form-control",
                                                                            attrs:
                                                                              {
                                                                                type: "number",
                                                                                name: "menu_unique_from",
                                                                                min: "1",
                                                                                step: "1",
                                                                                disabled:
                                                                                  _vm.isMenusEmpty ||
                                                                                  !_vm.has_right_to_update_service ||
                                                                                  !_vm.menu_unique,
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  _vm.menu_unique_from,
                                                                              },
                                                                            on: {
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  )
                                                                                    return
                                                                                  _vm.menu_unique_from =
                                                                                    $event.target.value
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-8 mt-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                        " +
                                                                            _vm._s(
                                                                              _vm.$tl(
                                                                                "labels.personss"
                                                                              )
                                                                            ) +
                                                                            "\n                                                    "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "sepa",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "row mt-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-3" },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.booking.services.generalOptions.link"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-9" },
                                                    [
                                                      _vm.general_options
                                                        .length > 0
                                                        ? _c(
                                                            "div",
                                                            _vm._l(
                                                              _vm.general_options,
                                                              function (
                                                                option,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: index,
                                                                    staticClass:
                                                                      "mb-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "container-box",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-bottom":
                                                                              "0",
                                                                            width:
                                                                              "100%",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm.general_options_selected,
                                                                                  expression:
                                                                                    "general_options_selected",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  !_vm.has_right_to_update_service,
                                                                                type: "checkbox",
                                                                                name: "general_options_selected[]",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  option.id,
                                                                                checked:
                                                                                  Array.isArray(
                                                                                    _vm.general_options_selected
                                                                                  )
                                                                                    ? _vm._i(
                                                                                        _vm.general_options_selected,
                                                                                        option.id
                                                                                      ) >
                                                                                      -1
                                                                                    : _vm.general_options_selected,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      _vm.general_options_selected,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c =
                                                                                      $$el.checked
                                                                                        ? true
                                                                                        : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v =
                                                                                        option.id,
                                                                                      $$i =
                                                                                        _vm._i(
                                                                                          $$a,
                                                                                          $$v
                                                                                        )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        (_vm.general_options_selected =
                                                                                          $$a.concat(
                                                                                            [
                                                                                              $$v,
                                                                                            ]
                                                                                          ))
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        (_vm.general_options_selected =
                                                                                          $$a
                                                                                            .slice(
                                                                                              0,
                                                                                              $$i
                                                                                            )
                                                                                            .concat(
                                                                                              $$a.slice(
                                                                                                $$i +
                                                                                                  1
                                                                                              )
                                                                                            ))
                                                                                    }
                                                                                  } else {
                                                                                    _vm.general_options_selected =
                                                                                      $$c
                                                                                  }
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "checkmark",
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              option.name
                                                                            ) +
                                                                            "\n                                                    "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "mt-4 mb-4",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-success btn-circle btn-sm",
                                                              class: {
                                                                disabled:
                                                                  !_vm.has_right_to_create_menu,
                                                              },
                                                              attrs: {
                                                                type: "button",
                                                                disabled:
                                                                  !_vm.has_right_to_create_menu,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.showAddGeneralOptionModal = true
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                    " +
                                                                  _vm._s(
                                                                    _vm.$tl(
                                                                      "labels.booking.generalOptions.add"
                                                                    )
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row m-0" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "border-light b-radius-20 p-4 mb-3",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "row",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              _vm.showEbPaiement =
                                                !_vm.showEbPaiement
                                            },
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.booking.services.footprintAndPayment.title"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.showEbPaiement
                                        ? _c(
                                            "div",
                                            { staticClass: "menu-option mt-3" },
                                            [
                                              _c("overlay-layout", {
                                                attrs: {
                                                  "display-overlay":
                                                    !_vm.hasPaymentSystem,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "content",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row border-bottom mb-2",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "row mb-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-5",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                " +
                                                                                  _vm._s(
                                                                                    _vm.$tl(
                                                                                      "labels.booking.services.footprint.enable"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                                                                "
                                                                              ),
                                                                              _c(
                                                                                "feather",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "tooltip",
                                                                                        rawName:
                                                                                          "v-tooltip.right",
                                                                                        value:
                                                                                          _vm.getTooltipNoticeLexicon(
                                                                                            "notices.booking.servicesConfig.bankFootprint",
                                                                                            _vm.restaurant_id,
                                                                                            "click",
                                                                                            true,
                                                                                            "tooltip-custom-width"
                                                                                          ),
                                                                                        expression:
                                                                                          "\n                                                                        getTooltipNoticeLexicon(\n                                                                            'notices.booking.servicesConfig.bankFootprint',\n                                                                            restaurant_id,\n                                                                            'click',\n                                                                            true,\n                                                                            'tooltip-custom-width'\n                                                                        )\n                                                                    ",
                                                                                        modifiers:
                                                                                          {
                                                                                            right: true,
                                                                                          },
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "ml-1 pointer tooltip-infos",
                                                                                  attrs:
                                                                                    {
                                                                                      type: "info",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-7",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              staticClass:
                                                                                "container-box",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          _vm.enable_footprint,
                                                                                        expression:
                                                                                          "enable_footprint",
                                                                                      },
                                                                                    ],
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        !_vm.has_right_to_update_service,
                                                                                      type: "checkbox",
                                                                                      "true-value":
                                                                                        "1",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      checked:
                                                                                        Array.isArray(
                                                                                          _vm.enable_footprint
                                                                                        )
                                                                                          ? _vm._i(
                                                                                              _vm.enable_footprint,
                                                                                              null
                                                                                            ) >
                                                                                            -1
                                                                                          : _vm._q(
                                                                                              _vm.enable_footprint,
                                                                                              "1"
                                                                                            ),
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        var $$a =
                                                                                            _vm.enable_footprint,
                                                                                          $$el =
                                                                                            $event.target,
                                                                                          $$c =
                                                                                            $$el.checked
                                                                                              ? "1"
                                                                                              : false
                                                                                        if (
                                                                                          Array.isArray(
                                                                                            $$a
                                                                                          )
                                                                                        ) {
                                                                                          var $$v =
                                                                                              null,
                                                                                            $$i =
                                                                                              _vm._i(
                                                                                                $$a,
                                                                                                $$v
                                                                                              )
                                                                                          if (
                                                                                            $$el.checked
                                                                                          ) {
                                                                                            $$i <
                                                                                              0 &&
                                                                                              (_vm.enable_footprint =
                                                                                                $$a.concat(
                                                                                                  [
                                                                                                    $$v,
                                                                                                  ]
                                                                                                ))
                                                                                          } else {
                                                                                            $$i >
                                                                                              -1 &&
                                                                                              (_vm.enable_footprint =
                                                                                                $$a
                                                                                                  .slice(
                                                                                                    0,
                                                                                                    $$i
                                                                                                  )
                                                                                                  .concat(
                                                                                                    $$a.slice(
                                                                                                      $$i +
                                                                                                        1
                                                                                                    )
                                                                                                  ))
                                                                                          }
                                                                                        } else {
                                                                                          _vm.enable_footprint =
                                                                                            $$c
                                                                                        }
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "checkmark",
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm.enable_footprint
                                                                    ? [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "row mb-2",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-5 pt-2",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.capitalize(
                                                                                          _vm.$tl(
                                                                                            "labels.from"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-7",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "row",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "col-5",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "input",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "model",
                                                                                                  rawName:
                                                                                                    "v-model",
                                                                                                  value:
                                                                                                    _vm.footprint_min_pers,
                                                                                                  expression:
                                                                                                    "footprint_min_pers",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "form-control",
                                                                                            class:
                                                                                              {
                                                                                                disabled:
                                                                                                  !_vm.enable_footprint ||
                                                                                                  !_vm.has_right_to_update_service,
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                type: "text",
                                                                                                disabled:
                                                                                                  !_vm.enable_footprint ||
                                                                                                  !_vm.has_right_to_update_service,
                                                                                              },
                                                                                            domProps:
                                                                                              {
                                                                                                value:
                                                                                                  _vm.footprint_min_pers,
                                                                                              },
                                                                                            on: {
                                                                                              input:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  if (
                                                                                                    $event
                                                                                                      .target
                                                                                                      .composing
                                                                                                  )
                                                                                                    return
                                                                                                  _vm.footprint_min_pers =
                                                                                                    $event.target.value
                                                                                                },
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "col-7 mt-2",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                        " +
                                                                                            _vm._s(
                                                                                              _vm.$tl(
                                                                                                "labels.persons"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                                                    "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "row mb-2",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-5",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                    " +
                                                                                        _vm._s(
                                                                                          _vm.$tl(
                                                                                            "labels.booking.services.footprint.amountToCapture",
                                                                                            _vm.restaurant_id
                                                                                          )
                                                                                        ) +
                                                                                        "\n                                                                "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-7",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "row",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "col-5",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "input",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "model",
                                                                                                  rawName:
                                                                                                    "v-model",
                                                                                                  value:
                                                                                                    _vm.amount_noshow,
                                                                                                  expression:
                                                                                                    "amount_noshow",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "form-control",
                                                                                            class:
                                                                                              {
                                                                                                disabled:
                                                                                                  !_vm.enable_footprint ||
                                                                                                  !_vm.has_right_to_update_service,
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                type: "text",
                                                                                                disabled:
                                                                                                  !_vm.enable_footprint ||
                                                                                                  !_vm.has_right_to_update_service,
                                                                                              },
                                                                                            domProps:
                                                                                              {
                                                                                                value:
                                                                                                  _vm.amount_noshow,
                                                                                              },
                                                                                            on: {
                                                                                              input:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  if (
                                                                                                    $event
                                                                                                      .target
                                                                                                      .composing
                                                                                                  )
                                                                                                    return
                                                                                                  _vm.amount_noshow =
                                                                                                    $event.target.value
                                                                                                },
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "col-7 mt-2",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                        " +
                                                                                            _vm._s(
                                                                                              _vm.$tl(
                                                                                                "labels.euros"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                                                    "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    : _vm._e(),
                                                                ],
                                                                2
                                                              ),
                                                              _vm._v(" "),
                                                              (_vm.hasPaymentSystem &&
                                                                !_vm.isMenusEmpty) ||
                                                              !_vm.isGeneralOptionsEmpty
                                                                ? _c("div", {
                                                                    staticClass:
                                                                      "sepa",
                                                                  })
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12 mb-2 mt-2",
                                                                },
                                                                [
                                                                  (_vm.hasPaymentSystem &&
                                                                    !_vm.isMenusEmpty) ||
                                                                  !_vm.isGeneralOptionsEmpty
                                                                    ? [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "row mb-2",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-5",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                    " +
                                                                                        _vm._s(
                                                                                          _vm.$tl(
                                                                                            "labels.booking.services.payment.enable"
                                                                                          )
                                                                                        ) +
                                                                                        "\n                                                                    "
                                                                                    ),
                                                                                    _c(
                                                                                      "feather",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "tooltip",
                                                                                              rawName:
                                                                                                "v-tooltip",
                                                                                              value:
                                                                                                _vm.getTooltipNoticeLexicon(
                                                                                                  "notices.booking.servicesConfig.prepayment",
                                                                                                  _vm.restaurant_id
                                                                                                ),
                                                                                              expression:
                                                                                                "\n                                                                            getTooltipNoticeLexicon(\n                                                                                'notices.booking.servicesConfig.prepayment',\n                                                                                restaurant_id\n                                                                            )\n                                                                        ",
                                                                                            },
                                                                                          ],
                                                                                        staticClass:
                                                                                          "ml-1 pointer tooltip-infos",
                                                                                        attrs:
                                                                                          {
                                                                                            type: "info",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-7",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    staticClass:
                                                                                      "container-box",
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "100%",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "input",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "model",
                                                                                              rawName:
                                                                                                "v-model",
                                                                                              value:
                                                                                                _vm.prepayment_enabled,
                                                                                              expression:
                                                                                                "prepayment_enabled",
                                                                                            },
                                                                                          ],
                                                                                        attrs:
                                                                                          {
                                                                                            disabled:
                                                                                              !_vm.has_right_to_update_service,
                                                                                            type: "checkbox",
                                                                                            name: "prepayment_enabled",
                                                                                            "true-value":
                                                                                              "1",
                                                                                          },
                                                                                        domProps:
                                                                                          {
                                                                                            checked:
                                                                                              Array.isArray(
                                                                                                _vm.prepayment_enabled
                                                                                              )
                                                                                                ? _vm._i(
                                                                                                    _vm.prepayment_enabled,
                                                                                                    null
                                                                                                  ) >
                                                                                                  -1
                                                                                                : _vm._q(
                                                                                                    _vm.prepayment_enabled,
                                                                                                    "1"
                                                                                                  ),
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            _vm.checkIfStripeEnabled,
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              var $$a =
                                                                                                  _vm.prepayment_enabled,
                                                                                                $$el =
                                                                                                  $event.target,
                                                                                                $$c =
                                                                                                  $$el.checked
                                                                                                    ? "1"
                                                                                                    : false
                                                                                              if (
                                                                                                Array.isArray(
                                                                                                  $$a
                                                                                                )
                                                                                              ) {
                                                                                                var $$v =
                                                                                                    null,
                                                                                                  $$i =
                                                                                                    _vm._i(
                                                                                                      $$a,
                                                                                                      $$v
                                                                                                    )
                                                                                                if (
                                                                                                  $$el.checked
                                                                                                ) {
                                                                                                  $$i <
                                                                                                    0 &&
                                                                                                    (_vm.prepayment_enabled =
                                                                                                      $$a.concat(
                                                                                                        [
                                                                                                          $$v,
                                                                                                        ]
                                                                                                      ))
                                                                                                } else {
                                                                                                  $$i >
                                                                                                    -1 &&
                                                                                                    (_vm.prepayment_enabled =
                                                                                                      $$a
                                                                                                        .slice(
                                                                                                          0,
                                                                                                          $$i
                                                                                                        )
                                                                                                        .concat(
                                                                                                          $$a.slice(
                                                                                                            $$i +
                                                                                                              1
                                                                                                          )
                                                                                                        ))
                                                                                                }
                                                                                              } else {
                                                                                                _vm.prepayment_enabled =
                                                                                                  $$c
                                                                                              }
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _vm.showStripeConnectModal &&
                                                                                    _vm.has_right_to_update_subscription
                                                                                      ? _c(
                                                                                          "stripe-connect-modal",
                                                                                          {
                                                                                            on: {
                                                                                              close:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  _vm.showStripeConnectModal = false
                                                                                                },
                                                                                              error:
                                                                                                _vm.stripeConnectModalError,
                                                                                              success:
                                                                                                _vm.stripeConnectModalSuccess,
                                                                                            },
                                                                                          }
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "checkmark",
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.prepayment_enabled
                                                                              ? [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-5",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "label",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.$tl(
                                                                                                "labels.booking.services.payment.is"
                                                                                              )
                                                                                            ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-6 d-table w-100 h-100 pt-2",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-table-cell align-middle",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "slot_btn_select radio",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "label",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "container-box",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "input",
                                                                                                    {
                                                                                                      directives:
                                                                                                        [
                                                                                                          {
                                                                                                            name: "model",
                                                                                                            rawName:
                                                                                                              "v-model",
                                                                                                            value:
                                                                                                              _vm.prepayment_mandatory,
                                                                                                            expression:
                                                                                                              "prepayment_mandatory",
                                                                                                          },
                                                                                                        ],
                                                                                                      class:
                                                                                                        {
                                                                                                          disabled:
                                                                                                            !_vm.prepayment_enabled ||
                                                                                                            !_vm.has_right_to_update_service,
                                                                                                        },
                                                                                                      attrs:
                                                                                                        {
                                                                                                          disabled:
                                                                                                            !_vm.prepayment_enabled ||
                                                                                                            !_vm.has_right_to_update_service,
                                                                                                          type: "radio",
                                                                                                          value:
                                                                                                            "1",
                                                                                                        },
                                                                                                      domProps:
                                                                                                        {
                                                                                                          checked:
                                                                                                            _vm._q(
                                                                                                              _vm.prepayment_mandatory,
                                                                                                              "1"
                                                                                                            ),
                                                                                                        },
                                                                                                      on: {
                                                                                                        change:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            _vm.prepayment_mandatory =
                                                                                                              "1"
                                                                                                          },
                                                                                                      },
                                                                                                    }
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "checkmark",
                                                                                                    }
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    "\n                                                                                " +
                                                                                                      _vm._s(
                                                                                                        _vm.$tl(
                                                                                                          "labels.mandatory"
                                                                                                        )
                                                                                                      ) +
                                                                                                      "\n                                                                            "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "label",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "container-box",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "input",
                                                                                                    {
                                                                                                      directives:
                                                                                                        [
                                                                                                          {
                                                                                                            name: "model",
                                                                                                            rawName:
                                                                                                              "v-model",
                                                                                                            value:
                                                                                                              _vm.prepayment_mandatory,
                                                                                                            expression:
                                                                                                              "prepayment_mandatory",
                                                                                                          },
                                                                                                        ],
                                                                                                      class:
                                                                                                        {
                                                                                                          disabled:
                                                                                                            !_vm.prepayment_enabled ||
                                                                                                            !_vm.has_right_to_update_service,
                                                                                                        },
                                                                                                      attrs:
                                                                                                        {
                                                                                                          disabled:
                                                                                                            !_vm.prepayment_enabled ||
                                                                                                            !_vm.has_right_to_update_service,
                                                                                                          type: "radio",
                                                                                                          value:
                                                                                                            "0",
                                                                                                        },
                                                                                                      domProps:
                                                                                                        {
                                                                                                          checked:
                                                                                                            _vm._q(
                                                                                                              _vm.prepayment_mandatory,
                                                                                                              "0"
                                                                                                            ),
                                                                                                        },
                                                                                                      on: {
                                                                                                        change:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            _vm.prepayment_mandatory =
                                                                                                              "0"
                                                                                                          },
                                                                                                      },
                                                                                                    }
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "checkmark",
                                                                                                    }
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    "\n                                                                                " +
                                                                                                      _vm._s(
                                                                                                        _vm.$tl(
                                                                                                          "labels.optional"
                                                                                                        )
                                                                                                      ) +
                                                                                                      "\n                                                                            "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              : _vm._e(),
                                                                          ],
                                                                          2
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm.prepayment_enabled
                                                                          ? [
                                                                              _vm.prepayment_mandatory !==
                                                                              null
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "row mb-2",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "col-5",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "label",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.$tl(
                                                                                                    "labels.booking.services.payment.amountToCapture"
                                                                                                  )
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "col-7",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "row",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "col-5",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "input",
                                                                                                    {
                                                                                                      directives:
                                                                                                        [
                                                                                                          {
                                                                                                            name: "model",
                                                                                                            rawName:
                                                                                                              "v-model",
                                                                                                            value:
                                                                                                              _vm.prepayment_percent,
                                                                                                            expression:
                                                                                                              "prepayment_percent",
                                                                                                          },
                                                                                                        ],
                                                                                                      staticClass:
                                                                                                        "form-control",
                                                                                                      class:
                                                                                                        {
                                                                                                          disabled:
                                                                                                            !_vm.prepayment_enabled ||
                                                                                                            !_vm.has_right_to_update_service,
                                                                                                        },
                                                                                                      attrs:
                                                                                                        {
                                                                                                          type: "number",
                                                                                                          name: "prepayment_percent",
                                                                                                          disabled:
                                                                                                            !_vm.prepayment_enabled ||
                                                                                                            !_vm.has_right_to_update_service,
                                                                                                          min: "1",
                                                                                                          step: "1",
                                                                                                        },
                                                                                                      domProps:
                                                                                                        {
                                                                                                          value:
                                                                                                            _vm.prepayment_percent,
                                                                                                        },
                                                                                                      on: {
                                                                                                        input:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            if (
                                                                                                              $event
                                                                                                                .target
                                                                                                                .composing
                                                                                                            )
                                                                                                              return
                                                                                                            _vm.prepayment_percent =
                                                                                                              $event.target.value
                                                                                                          },
                                                                                                      },
                                                                                                    }
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "col-7 mt-2",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "%"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _vm.prepayment_mandatory ==
                                                                              1
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "row mb-2",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "col-5 pt-2",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "label",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.capitalize(
                                                                                                    _vm.$tl(
                                                                                                      "labels.from"
                                                                                                    )
                                                                                                  )
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "col-7",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "row",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "col-5",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "input",
                                                                                                    {
                                                                                                      directives:
                                                                                                        [
                                                                                                          {
                                                                                                            name: "model",
                                                                                                            rawName:
                                                                                                              "v-model",
                                                                                                            value:
                                                                                                              _vm.prepayment_min_pers,
                                                                                                            expression:
                                                                                                              "prepayment_min_pers",
                                                                                                          },
                                                                                                        ],
                                                                                                      staticClass:
                                                                                                        "form-control",
                                                                                                      class:
                                                                                                        {
                                                                                                          disabled:
                                                                                                            !_vm.prepayment_mandatory ||
                                                                                                            !_vm.has_right_to_update_service,
                                                                                                        },
                                                                                                      attrs:
                                                                                                        {
                                                                                                          type: "text",
                                                                                                          disabled:
                                                                                                            !_vm.prepayment_mandatory ||
                                                                                                            !_vm.has_right_to_update_service,
                                                                                                        },
                                                                                                      domProps:
                                                                                                        {
                                                                                                          value:
                                                                                                            _vm.prepayment_min_pers,
                                                                                                        },
                                                                                                      on: {
                                                                                                        input:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            if (
                                                                                                              $event
                                                                                                                .target
                                                                                                                .composing
                                                                                                            )
                                                                                                              return
                                                                                                            _vm.prepayment_min_pers =
                                                                                                              $event.target.value
                                                                                                          },
                                                                                                      },
                                                                                                    }
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "col-7 mt-2",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                                                            " +
                                                                                                      _vm._s(
                                                                                                        _vm.$tl(
                                                                                                          "labels.personss"
                                                                                                        )
                                                                                                      ) +
                                                                                                      "\n                                                                        "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          : _vm._e(),
                                                                      ]
                                                                    : _vm._e(),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row mt-3 radio",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12 row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-6 pt-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                            " +
                                                                              _vm._s(
                                                                                _vm.$tl(
                                                                                  "labels.booking.services.footprint.autoCancelation",
                                                                                  _vm.restaurant_id,
                                                                                  {
                                                                                    shortDelay:
                                                                                      _vm.optionBankShortDelay,
                                                                                  }
                                                                                )
                                                                              ) +
                                                                              "\n                                                            "
                                                                          ),
                                                                          _c(
                                                                            "feather",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "tooltip",
                                                                                    rawName:
                                                                                      "v-tooltip",
                                                                                    value:
                                                                                      _vm.getTooltipNotice(
                                                                                        "notices.booking.servicesConfig.bookingCancellationWithoutFootprint"
                                                                                      ),
                                                                                    expression:
                                                                                      "\n                                                                    getTooltipNotice(\n                                                                        'notices.booking.servicesConfig.bookingCancellationWithoutFootprint'\n                                                                    )\n                                                                ",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "ml-1 pointer tooltip-infos",
                                                                              attrs:
                                                                                {
                                                                                  type: "info",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-6 d-table w-100 h-100 pt-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-table-cell align-middle",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              staticClass:
                                                                                "container-box",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          _vm.option_bank_auto_cancel,
                                                                                        expression:
                                                                                          "option_bank_auto_cancel",
                                                                                      },
                                                                                    ],
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        !_vm.has_right_to_update_service,
                                                                                      type: "radio",
                                                                                      value:
                                                                                        "0",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      checked:
                                                                                        _vm._q(
                                                                                          _vm.option_bank_auto_cancel,
                                                                                          "0"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.option_bank_auto_cancel =
                                                                                          "0"
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "checkmark",
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " Manuelle\n                                                            "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              staticClass:
                                                                                "container-box",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          _vm.option_bank_auto_cancel,
                                                                                        expression:
                                                                                          "option_bank_auto_cancel",
                                                                                      },
                                                                                    ],
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        !_vm.has_right_to_update_service,
                                                                                      type: "radio",
                                                                                      value:
                                                                                        "1",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      checked:
                                                                                        _vm._q(
                                                                                          _vm.option_bank_auto_cancel,
                                                                                          "1"
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.option_bank_auto_cancel =
                                                                                          "1"
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "checkmark",
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " Automatique\n                                                            "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "send-reminder-payment",
                                                            {
                                                              attrs: {
                                                                "cancel-auto":
                                                                  _vm.option_bank_auto_cancel,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.sendReminderPayment,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.sendReminderPayment =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "sendReminderPayment",
                                                              },
                                                            }
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                    {
                                                      key: "overlay",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "is-white text-center d-table",
                                                            },
                                                            [
                                                              _c(
                                                                "h6",
                                                                {
                                                                  staticClass:
                                                                    "d-table-cell align-middle",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                    " +
                                                                      _vm._s(
                                                                        _vm.$tl(
                                                                          "infos.common.needStripeAccount"
                                                                        )
                                                                      ) +
                                                                      "\n                                                "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1528324957
                                                ),
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.hasEnabledGoogleReserve &&
                              _vm.isReserveWithGoogleEnabled()
                                ? _c("div", { staticClass: "row m-0 mb-3" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12" },
                                      [
                                        _c("google-reserve", {
                                          attrs: {
                                            settings: _vm.googleReserveSettings,
                                            expandable: "",
                                            "for-services": "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.restaurants.length > 0
                                ? _c("div", { staticClass: "row m-0" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "border-light b-radius-20 p-4 mb-3",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "row",
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.showIfComplet =
                                                    !_vm.showIfComplet
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.booking.services.inCaseFull",
                                                          _vm.restaurant_id
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.showIfComplet
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "menu-option mt-3",
                                                },
                                                [
                                                  _vm.restaurants.length > 0
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "row" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-6",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                " +
                                                                      _vm._s(
                                                                        _vm.$tl(
                                                                          "labels.booking.services.showRestaurantService",
                                                                          _vm.restaurant_id
                                                                        )
                                                                      ) +
                                                                      "\n                                                "
                                                                  ),
                                                                  _c(
                                                                    "feather",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "tooltip",
                                                                            rawName:
                                                                              "v-tooltip",
                                                                            value:
                                                                              _vm.getTooltipNotice(
                                                                                "notices.booking.servicesConfig.restaurantProposal"
                                                                              ),
                                                                            expression:
                                                                              "getTooltipNotice('notices.booking.servicesConfig.restaurantProposal')",
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "ml-1 pointer tooltip-infos",
                                                                      attrs: {
                                                                        type: "info",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-6",
                                                            },
                                                            [
                                                              _vm.restaurants
                                                                .length > 0
                                                                ? _c(
                                                                    "div",
                                                                    _vm._l(
                                                                      _vm.restaurants,
                                                                      function (
                                                                        restau
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key: restau.id,
                                                                            staticClass:
                                                                              "mb-2",
                                                                          },
                                                                          [
                                                                            restau.id !==
                                                                            _vm
                                                                              .$route
                                                                              .params
                                                                              .restaurant_id
                                                                              ? _c(
                                                                                  "label",
                                                                                  {
                                                                                    staticClass:
                                                                                      "container-box",
                                                                                    staticStyle:
                                                                                      {
                                                                                        "margin-bottom":
                                                                                          "0",
                                                                                        width:
                                                                                          "100%",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "input",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "model",
                                                                                              rawName:
                                                                                                "v-model",
                                                                                              value:
                                                                                                _vm.alternative_restaurants,
                                                                                              expression:
                                                                                                "alternative_restaurants",
                                                                                            },
                                                                                          ],
                                                                                        attrs:
                                                                                          {
                                                                                            disabled:
                                                                                              !_vm.has_right_to_update_service,
                                                                                            type: "checkbox",
                                                                                            name: "alternative_restaurants[]",
                                                                                          },
                                                                                        domProps:
                                                                                          {
                                                                                            value:
                                                                                              restau.id,
                                                                                            checked:
                                                                                              Array.isArray(
                                                                                                _vm.alternative_restaurants
                                                                                              )
                                                                                                ? _vm._i(
                                                                                                    _vm.alternative_restaurants,
                                                                                                    restau.id
                                                                                                  ) >
                                                                                                  -1
                                                                                                : _vm.alternative_restaurants,
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              var $$a =
                                                                                                  _vm.alternative_restaurants,
                                                                                                $$el =
                                                                                                  $event.target,
                                                                                                $$c =
                                                                                                  $$el.checked
                                                                                                    ? true
                                                                                                    : false
                                                                                              if (
                                                                                                Array.isArray(
                                                                                                  $$a
                                                                                                )
                                                                                              ) {
                                                                                                var $$v =
                                                                                                    restau.id,
                                                                                                  $$i =
                                                                                                    _vm._i(
                                                                                                      $$a,
                                                                                                      $$v
                                                                                                    )
                                                                                                if (
                                                                                                  $$el.checked
                                                                                                ) {
                                                                                                  $$i <
                                                                                                    0 &&
                                                                                                    (_vm.alternative_restaurants =
                                                                                                      $$a.concat(
                                                                                                        [
                                                                                                          $$v,
                                                                                                        ]
                                                                                                      ))
                                                                                                } else {
                                                                                                  $$i >
                                                                                                    -1 &&
                                                                                                    (_vm.alternative_restaurants =
                                                                                                      $$a
                                                                                                        .slice(
                                                                                                          0,
                                                                                                          $$i
                                                                                                        )
                                                                                                        .concat(
                                                                                                          $$a.slice(
                                                                                                            $$i +
                                                                                                              1
                                                                                                          )
                                                                                                        ))
                                                                                                }
                                                                                              } else {
                                                                                                _vm.alternative_restaurants =
                                                                                                  $$c
                                                                                              }
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "checkmark",
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          restau.name
                                                                                        ) +
                                                                                        "\n                                                    "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "row m-0" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("input", {
                                    staticClass: "btn btn-success btn-circle",
                                    attrs: {
                                      disabled:
                                        !_vm.has_right_to_update_service ||
                                        _vm.formLoading,
                                      type: "submit",
                                      name: "submit",
                                    },
                                    domProps: {
                                      value: _vm.$t("labels.form.actions.save"),
                                    },
                                  }),
                                ]),
                              ]),
                            ],
                      ],
                      2
                    ),
                  ]),
              _vm._v(" "),
              _vm.showAddMenuModal
                ? _c("addMenuModal", {
                    on: {
                      close: function ($event) {
                        _vm.showAddMenuModal = false
                      },
                      "menu-added": function ($event) {
                        return _vm.loadMenus(false, true)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showAddGeneralOptionModal
                ? _c("add-menu-option-modal", {
                    attrs: { "option-type": _vm.MENU_OPTION_GENERAL.value },
                    on: {
                      close: function ($event) {
                        _vm.showAddGeneralOptionModal = false
                      },
                      "menu-option-added": function ($event) {
                        return _vm.loadGeneralOptions(true)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
      _vm._v(" "),
      _vm.showNoSeatingPlanModal
        ? _c("no-seating-plan-modal", {
            attrs: { closeButtonTitle: "Retour" },
            on: {
              close: function ($event) {
                _vm.showNoSeatingPlanModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showConfirmDeleteSlotsModal
        ? _c("ComfirmDeleteSlotsModal", {
            attrs: {
              headerText: _vm.headerText,
              reservations: _vm.futuresReservations,
              loading: _vm.formLoading,
            },
            on: {
              "confirmed-without-cancel": function ($event) {
                return _vm.sendForm(null, true)
              },
              confirmed: _vm.cancelReservations,
              close: function ($event) {
                _vm.showConfirmDeleteSlotsModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showConfirmModal
        ? _c("confirm-opening-create-modal", {
            attrs: {
              formLoading: _vm.formLoading,
              date_begin: _vm.special_date_begin,
              date_end: _vm.special_date_end,
              servicesId: _vm.showConfirmModalServicesId,
              cancelData: _vm.confirmModalData,
            },
            on: {
              close: function ($event) {
                _vm.showConfirmModal = false
              },
              confirmed: function ($event) {
                return _vm.sendForm(null, false)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }