export default {
    data() {
        return {
            SALE_TYPE_BO: {
                value: "bo",
                label: "manuel",
            },
            SALE_TYPE_WIDGET: {
                value: "widget",
                label: "client",
            },
            SALE_TYPE_IMPORTED: {
                value: "imported",
                label: "importé",
            },
        };
    },
    methods: {
        getSaleTypeLabel(value) {
            const found = this.ALL_SALE_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_SALE_TYPES() {
            return [this.SALE_TYPE_BO, this.SALE_TYPE_WIDGET, this.SALE_TYPE_IMPORTED];
        },
    },
};
