var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    directives: [
      {
        name: "tooltip",
        rawName: "v-tooltip",
        value: _vm.tooltip,
        expression: "tooltip",
      },
    ],
    staticClass: "red-bubble",
    staticStyle: { margin: "0 3px !important" },
    on: {
      click: function ($event) {
        return _vm.$emit("click", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }