var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1 ml-2",
        },
        [
          _c("h6", { staticClass: "title mt-2" }, [
            _vm._v(_vm._s(_vm.restaurant.name)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 mb-3" }, [
            _c("strong", [_vm._v(_vm._s(_vm.$tl("labels.licences")))]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          _vm._l(_vm.ALL_MODULES_TYPES_YSERVICES, function (MODULE_TYPE) {
            return _c(
              "div",
              {
                key: MODULE_TYPE.value,
                staticClass: "col p-2 d-flex flex-column align-items-center",
              },
              [
                _c("strong", { staticClass: "d-block mb-2" }, [
                  _vm._v(_vm._s(_vm.getModuleTypeLabel(MODULE_TYPE.value))),
                ]),
                _vm._v(" "),
                _vm.hasSubscription(MODULE_TYPE.value, _vm.restaurant)
                  ? _c(
                      "router-link",
                      {
                        staticClass: "btn btn-primary btn-circle btn-sm",
                        attrs: {
                          to: {
                            name: _vm.getHomeUrlForModule(MODULE_TYPE),
                            params: { restaurant_id: _vm.restaurant.id },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$tl("labels.modules.actions.goTo")) +
                            "\n                "
                        ),
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-more-infos btn-circle btn-sm mr-2",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.openModal(MODULE_TYPE.value)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$tl("labels.subscriptions.moreInfos")) +
                            "\n                "
                        ),
                      ]
                    ),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm.isSmsActivated
        ? _c("edit-sms-settings", {
            attrs: {
              "is-stand-alone": true,
              restaurant: _vm.restaurant,
              formErrors: _vm.formErrors,
            },
            on: {
              "update-errors": function ($event) {
                _vm.formErrors = $event
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.restaurant.prefered_payment_system
        ? _c("ChooseCardPaymentSystem", {
            staticClass: "border-light b-radius-20 p-4 mt-3",
            attrs: {
              "is-stand-alone": true,
              restaurantId: _vm.restaurant.id,
              prefered_payment_system: _vm.restaurant.prefered_payment_system,
            },
            on: {
              "set-prefered-payment-system": function ($event) {
                _vm.restaurant.prefered_payment_system = $event
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.canDisplayModal
        ? _c("ask-for-subscription", {
            attrs: { module: _vm.currentModule, restaurant: _vm.restaurant },
            on: {
              close: function ($event) {
                _vm.canDisplayModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }