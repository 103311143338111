import ReservationStatusEnum from "./ReservationStatusEnum";

export default {
    data() {
        return {
            RESERVATION_COLUMN_FOR_EXPORT_ID: {
                value: "id",
                label: "Identifiant",
            },
            RESERVATION_COLUMN_FOR_EXPORT_RESERVATION_DATE: {
                value: "reservation_date",
                label: "Date",
            },
            RESERVATION_COLUMN_FOR_EXPORT_SLOT: {
                value: "slot",
                label: "Créneau",
            },
            RESERVATION_COLUMN_FOR_EXPORT_PAX: {
                value: "pax",
                label: "PAX",
            },
            RESERVATION_COLUMN_FOR_EXPORT_STATUS: {
                value: "status",
                label: "Statut",
            },
            RESERVATION_COLUMN_FOR_EXPORT_ROOM: {
                value: "room",
                label: "Salle",
            },
            RESERVATION_COLUMN_FOR_EXPORT_TABLE: {
                value: "table",
                label: "Table(s)",
            },
            RESERVATION_COLUMN_FOR_EXPORT_ROOM_NUMBER: {
                value: "room_number",
                label: "Numéro de chambre",
            },
            RESERVATION_COLUMN_FOR_EXPORT_OPTION_BANK: {
                value: "option_bank",
                label: "Type de paiement",
            },
            RESERVATION_COLUMN_FOR_EXPORT_AMOUNT_PAID: {
                value: "amount_paid",
                label: "Montant disponible",
            },
            RESERVATION_COLUMN_FOR_EXPORT_AMOUNT_CAPTURED: {
                value: "amount_captured",
                label: "Montant capturé",
            },
            RESERVATION_COLUMN_FOR_EXPORT_PAYMENT_STATUS: {
                value: "payment_status",
                label: "Statut du paiement",
            },
            RESERVATION_COLUMN_FOR_EXPORT_FIRSTNAME: {
                value: "firstname",
                label: "Prénom",
            },
            RESERVATION_COLUMN_FOR_EXPORT_LASTNAME: {
                value: "lastname",
                label: "Nom",
            },
            RESERVATION_COLUMN_FOR_EXPORT_EMAIL: {
                value: "email",
                label: "Email",
            },
            RESERVATION_COLUMN_FOR_EXPORT_PHONE: {
                value: "phone",
                label: "Téléphone",
            },
            RESERVATION_COLUMN_FOR_EXPORT_GIFT: {
                value: "gift",
                label: "Bon cadeau",
            },
            RESERVATION_COLUMN_FOR_EXPORT_PUBLIC_COMMENT: {
                value: "public_comment",
                label: "Commentaire",
            },
            RESERVATION_COLUMN_FOR_EXPORT_PRIVATE_COMMENT: {
                value: "private_comment",
                label: "Commentaire de l'établissement",
            },
            RESERVATION_COLUMN_FOR_EXPORT_MENUS_AND_OPTIONS: {
                value: "menus_and_options",
                label: "Menus et options",
            },
        };
    },
    mixins: [ReservationStatusEnum],
    methods: {
        getReservationColumnForExportLabel(value, restaurantId = undefined) {
            const found = this.ALL_RESERVATION_COLUMNS_FOR_EXPORT.find((w) => w.value == value);
            if (typeof found != "undefined") {
                return this.$tl(`labels.booking.reservations.export.columns.${value}`, restaurantId);
            }
            return value;
        },
        getFilteredReservationColumnsForExport({ addRoomNumber = false } = {}) {
            let columns = this.ALL_RESERVATION_COLUMNS_FOR_EXPORT;

            if (!addRoomNumber) {
                columns = columns.filter((column) => column.value !== this.RESERVATION_COLUMN_FOR_EXPORT_ROOM_NUMBER.value);
            }

            return columns;
        },
    },
    computed: {
        ALL_RESERVATION_COLUMNS_FOR_EXPORT() {
            return [
                this.RESERVATION_COLUMN_FOR_EXPORT_ID,
                this.RESERVATION_COLUMN_FOR_EXPORT_RESERVATION_DATE,
                this.RESERVATION_COLUMN_FOR_EXPORT_SLOT,
                this.RESERVATION_COLUMN_FOR_EXPORT_PAX,
                this.RESERVATION_COLUMN_FOR_EXPORT_STATUS,
                this.RESERVATION_COLUMN_FOR_EXPORT_ROOM,
                this.RESERVATION_COLUMN_FOR_EXPORT_TABLE,
                this.RESERVATION_COLUMN_FOR_EXPORT_ROOM_NUMBER,
                this.RESERVATION_COLUMN_FOR_EXPORT_OPTION_BANK,
                this.RESERVATION_COLUMN_FOR_EXPORT_AMOUNT_PAID,
                this.RESERVATION_COLUMN_FOR_EXPORT_AMOUNT_CAPTURED,
                this.RESERVATION_COLUMN_FOR_EXPORT_PAYMENT_STATUS,
                this.RESERVATION_COLUMN_FOR_EXPORT_LASTNAME,
                this.RESERVATION_COLUMN_FOR_EXPORT_FIRSTNAME,
                this.RESERVATION_COLUMN_FOR_EXPORT_PHONE,
                this.RESERVATION_COLUMN_FOR_EXPORT_EMAIL,
                this.RESERVATION_COLUMN_FOR_EXPORT_GIFT,
                this.RESERVATION_COLUMN_FOR_EXPORT_PUBLIC_COMMENT,
                this.RESERVATION_COLUMN_FOR_EXPORT_PRIVATE_COMMENT,
                this.RESERVATION_COLUMN_FOR_EXPORT_MENUS_AND_OPTIONS,
            ];
        },
    },
};
