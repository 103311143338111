var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "payments-grid" },
    [
      _c("ns-table", {
        attrs: {
          tableClass: "table table-striped overflowx-auto mr-3",
          "data-source": _vm.payments,
          "allow-paging": "",
          clickable: "",
          "is-server-side": "",
          pagination: _vm.pagination,
          "toolbar-settings": _vm.toolbarSettings,
          "current-sort": _vm.queries.sort,
        },
        on: {
          "update-data": function ($event) {
            return _vm.$emit("update-data", $event)
          },
          "row-selected": _vm.rowSelected,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "toolbar",
              fn: function () {
                return [_vm._t("toolbar")]
              },
              proxy: true,
            },
            {
              key: "head",
              fn: function () {
                return [
                  _c(
                    "ns-th",
                    {
                      staticClass: "none-mobile",
                      attrs: {
                        field: "reservation_id",
                        width: "6",
                        "allow-sorting": "",
                      },
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("labels.booking.payments.grid.columns.id")
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ns-th",
                    {
                      attrs: {
                        field: "reservation_payments.client",
                        width: "6",
                        "allow-sorting": "",
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "none-mobile",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "labels.booking.payments.grid.columns.client"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("feather", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getTooltip(
                              _vm.$t(
                                "labels.booking.payments.grid.columns.client"
                              )
                            ),
                            expression:
                              "getTooltip($t('labels.booking.payments.grid.columns.client'))",
                          },
                        ],
                        staticClass: "none-desk",
                        attrs: { type: "user" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ns-th",
                    {
                      attrs: {
                        field: "reservation_datetime",
                        width: "14",
                        "allow-sorting": "",
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "none-mobile",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "labels.booking.payments.grid.columns.reservationDate"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("feather", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getTooltip(
                              _vm.$t(
                                "labels.booking.payments.grid.columns.reservationDate"
                              )
                            ),
                            expression:
                              "getTooltip($t('labels.booking.payments.grid.columns.reservationDate'))",
                          },
                        ],
                        staticClass: "none-desk",
                        attrs: { type: "clock" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ns-th",
                    {
                      attrs: {
                        field: "option_bank",
                        width: "9",
                        "allow-sorting": "",
                        "allow-filtering": "",
                        filterOptions: {
                          type: "choices",
                          choices: _vm.optionBanksForFiltering,
                        },
                        "backend-selected-choices": _vm.selectedOptionBanks,
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "none-mobile",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("labels.booking.payments.grid.columns.type")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("feather", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getTooltip(
                              _vm.$t(
                                "labels.booking.payments.grid.columns.type"
                              )
                            ),
                            expression:
                              "getTooltip($t('labels.booking.payments.grid.columns.type'))",
                          },
                        ],
                        staticClass: "none-desk",
                        attrs: { type: "credit-card" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ns-th",
                    {
                      attrs: {
                        field: "stripe_intent_amount",
                        width: "9",
                        "allow-sorting": "",
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "none-mobile",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "labels.booking.payments.grid.columns.amount"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("feather", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getTooltip(
                              _vm.$t(
                                "labels.booking.payments.grid.columns.amount"
                              )
                            ),
                            expression:
                              "getTooltip($t('labels.booking.payments.grid.columns.amount'))",
                          },
                        ],
                        staticClass: "none-desk",
                        attrs: { type: "dollar-sign" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ns-th",
                    {
                      attrs: {
                        field: "stripe_fees",
                        width: "7",
                        "allow-sorting": "",
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "none-mobile",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("labels.booking.payments.grid.columns.fees")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("feather", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getTooltip(
                              _vm.$t(
                                "labels.booking.payments.grid.columns.fees"
                              )
                            ),
                            expression:
                              "getTooltip($t('labels.booking.payments.grid.columns.fees'))",
                          },
                        ],
                        staticClass: "none-desk",
                        attrs: { type: "dollar-sign" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ns-th",
                    {
                      attrs: {
                        field: "status",
                        width: "10",
                        "allow-sorting": "",
                        "allow-filtering": "",
                        filterOptions: {
                          type: "choices",
                          choices: _vm.paymentStatusForFiltering,
                        },
                        "backend-selected-choices": _vm.selectedStatuses,
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "none-mobile",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "labels.booking.payments.grid.columns.status"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("feather", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getTooltip(
                              _vm.$t(
                                "labels.booking.payments.grid.columns.status"
                              )
                            ),
                            expression:
                              "getTooltip($t('labels.booking.payments.grid.columns.status'))",
                          },
                        ],
                        staticClass: "none-desk",
                        attrs: { type: "check-circle" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ns-th",
                    {
                      staticClass: "none-mobile",
                      attrs: {
                        field: "paid_at",
                        width: "15",
                        "allow-sorting": "",
                      },
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "labels.booking.payments.grid.columns.paymentDate"
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("ns-th", { staticClass: "none-mobile" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("labels.booking.payments.grid.columns.actions")
                        ),
                      },
                    }),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function ({ data }) {
                return [
                  _c("td", { staticClass: "none-mobile" }, [
                    _vm._v(_vm._s(data.reservation_id)),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", { staticClass: "none-tablet" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getClientCivilityLabelShort(
                            data.reservation.client.civility
                          )
                        )
                      ),
                    ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(data.reservation.client.firstname) +
                        "\n                "
                    ),
                    _c("span", { staticClass: "text-uppercase" }, [
                      _vm._v(_vm._s(data.reservation.client.lastname)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.displayDate(
                          data.reservation.reservation_datetime,
                          _vm.DATETIME_SHORT
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("feather", {
                        attrs: {
                          type: _vm.getOptionBankIcon(data.option_bank),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.getStringAmount(data)))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.getStringFees(data)))]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "span",
                      {
                        staticClass: "resa-status booking-payment-status",
                        class: `booking-payment-${data.status}`,
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.getPaymentStatusLabel(data.status)) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "none-mobile" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          data.paid_at
                            ? _vm.displayDate(data.paid_at, _vm.DATE_SHORT)
                            : "--"
                        ) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "none-mobile" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getDownloadInvoiceTooltip(data),
                            expression: "getDownloadInvoiceTooltip(data)",
                          },
                        ],
                        staticClass:
                          "btn btn-sm btn-outline-secondary btn-square",
                        attrs: {
                          disabled:
                            !_vm.has_right_to_export_payments || !data.invoice,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.downloadInvoice(data.reservation_id)
                          },
                        },
                      },
                      [_c("feather", { attrs: { type: "download-cloud" } })],
                      1
                    ),
                  ]),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }