export const mutations = {
    setId(state, id) {
        state.id = id;
    },
    setFirstName(state, firstName) {
        state.firstName = firstName;
    },
    setLastName(state, lastName) {
        state.lastName = lastName;
    },
    setEmail(state, email) {
        state.email = email;
    },
    setTel(state, tel) {
        state.tel = tel;
    },
    setTelCountry(state, tel_country) {
        state.tel_country = tel_country;
    },
    setTelIsMobile(state, tel_is_mobile) {
        state.tel_is_mobile = tel_is_mobile;
    },
    setLang(state, lang) {
        state.lang = lang;
    },
    setAddress(state, address) {
        state.address = address;
    },
    setZipCode(state, zipcode) {
        state.zipcode = zipcode;
    },
    setCity(state, city) {
        state.city = city;
    },
    setCountry(state, country) {
        state.country = country;
    },
    setRights(state, rights) {
        state.rights = rights;
        state.rights.forEach((value, index) => {
            state.formattedRights.push(value.format);
        });
    },
    setRole(state, role) {
        state.role = role;
    },
    setOwnerId(state, owner_id) {
        state.owner_id = owner_id;
    },
    setCompany(state, company) {
        state.company = company;
    },
    setCompanyType(state, companyType) {
        state.companyType = companyType;
    },
    setCompanyTel(state, companyTel) {
        state.companyTel = companyTel;
    },
    setCompanyEmail(state, companyEmail) {
        state.companyEmail = companyEmail;
    },
    setCompanySiret(state, companySiret) {
        state.companySiret = companySiret;
    },
    setCompanyCodeNaf(state, companyCodeNaf) {
        state.companyCodeNaf = companyCodeNaf;
    },
    setCompanyCodeTva(state, companyCodeTva) {
        state.companyCodeTva = companyCodeTva;
    },
    setCompanyAddress(state, companyAddress) {
        state.companyAddress = companyAddress;
    },
    setCompanyZipcode(state, companyZipcode) {
        state.companyZipcode = companyZipcode;
    },
    setCompanyCity(state, companyCity) {
        state.companyCity = companyCity;
    },
    setCompanyCountry(state, companyCountry) {
        state.companyCountry = companyCountry;
    },
    setOwnerSubscriptions(state, ownerSubscriptions) {
        state.ownerSubscriptions = ownerSubscriptions;
    },
    setOwnerAlreadyTriedModules(state, ownerAlreadyTriedModules) {
        state.ownerAlreadyTriedModules = ownerAlreadyTriedModules;
    },
    setEnableNotificationRingtone(state, enableNotificationRingtone) {
        state.enableNotificationRingtone = enableNotificationRingtone;
    },
    setKeepClientsOnNewsletterFor(state, keepClientsOnNewsletterFor) {
        state.keepClientsOnNewsletterFor = keepClientsOnNewsletterFor;
    },
    setKeepClientsDetailsAndHistoryFor(state, keepClientsDetailsAndHistoryFor) {
        state.keepClientsDetailsAndHistoryFor = keepClientsDetailsAndHistoryFor;
    },
    setPasswordLastChanged(state, passwordLastChanged) {
        state.passwordLastChanged = passwordLastChanged;
    },
    setNewBadges(state, newBadges) {
        state.newBadges = newBadges;
    },
    setRestaurantIds(state, restaurant_ids) {
        state.restaurant_ids = restaurant_ids;
    },
    setHasUnreadAppUpdates(state, hasUnreadAppUpdates) {
        state.hasUnreadAppUpdates = hasUnreadAppUpdates;
    },
    setIsYproxUser(state, isYproxUser) {
        state.isYproxUser = isYproxUser;
    },
    setHasManualBilling(state, hasManualBilling) {
        state.hasManualBilling = hasManualBilling;
    },
    setUnseenFailedMailsNotificationsLogs(state, unseenFailedMailsNotificationsLogs) {
        state.unseenFailedMailsNotificationsLogs = unseenFailedMailsNotificationsLogs;
    },
    markFailedMailsNotificationsLogsAsSeen(state, { restaurantId, moduleType }) {
        state.unseenFailedMailsNotificationsLogs[restaurantId][moduleType] = false;
    },
    setUnreadSeatingPlanNotifications(state, unreadSeatingPlanNotifications) {
        state.unreadSeatingPlanNotifications = unreadSeatingPlanNotifications;
    },
};

export default mutations;
