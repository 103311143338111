<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="row" v-else>
            <div class="col-12">
                <div class="border-light b-radius-20 p-4">
                    <div class="row mb-2">
                        <div class="col-5 pt-2">
                            <label for="name">Nom <small>*</small></label>
                        </div>
                        <div class="col-7">
                            <input type="text" v-model="opening.name" required class="form-control" />
                            <show-errors class="d-block" :errors="errors" errorKey="name" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-5 pt-2">
                            <label>Date de début <small>*</small></label>
                        </div>
                        <div class="col-7 date-resa-cal">
                            <datepicker
                                tabindex="1"
                                :monday-first="true"
                                v-model="opening.start_day"
                                :language="fr"
                                :disabledDates="disabledFromDates" />
                            <show-errors class="d-block" :errors="errors" errorKey="start_day" />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-5 pt-2">
                            <label>Date de fin <small>*</small></label>
                        </div>
                        <div class="col-7 date-resa-cal">
                            <datepicker
                                tabindex="1"
                                :monday-first="true"
                                v-model="opening.end_day"
                                :language="fr"
                                :disabledDates="disabledToDates" />
                            <show-errors class="d-block" :errors="errors" errorKey="end_day" />
                        </div>
                    </div>
                    <div class="row mt-2 mb-2">
                        <div class="col-5">
                            <label> Services concernés <small>*</small> </label>
                        </div>
                        <div class="col-7">
                            <div v-if="availableCollectTypes.length > 0">
                                <show-errors class="d-block" :errors="errors" errorKey="collect_types" />
                                <show-errors class="d-block" :errors="errors" errorKey="collect_types.*" />
                                <div v-for="collectType in availableCollectTypes" :key="collectType">
                                    <label class="container-box" style="width: 100%; text-transform: capitalize">
                                        <input
                                            type="checkbox"
                                            name="collect_types[]"
                                            v-model="opening.collect_types"
                                            :value="collectType" />
                                        <span class="checkmark"></span> {{ getCollectTypeLabel(collectType) }}
                                    </label>
                                    <template v-if="opening.collect_types.includes(collectType)">
                                        <div class="row ml-2" v-if="collectType === COLLECT_TYPE_COLLECT.value">
                                            <show-errors :errors="errors" class="col-12" errorKey="cc_services.data" />
                                            <div class="col-12" v-for="day in allDays" :key="day">
                                                <div v-show="daysActive.includes(day)">
                                                    <div class="mb-2">
                                                        <label
                                                            class="container-box"
                                                            style="width: 100%; text-transform: capitalize">
                                                            <input
                                                                type="checkbox"
                                                                name="collect_days_active[]"
                                                                v-model="openingFrontData.collectDaysActive"
                                                                :value="day" />
                                                            <span class="checkmark"></span> {{ dayLabel(day) }}
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="setting-open"
                                                        v-if="openingFrontData.collectDaysActive.includes(day)">
                                                        <div class="setting-hours">
                                                            <span>
                                                                <div
                                                                    v-for="(service, index) in getCollectServicesForDay(
                                                                        day
                                                                    )"
                                                                    :key="index">
                                                                    <vue-timepicker
                                                                        format="HH:mm"
                                                                        :minute-interval="15"
                                                                        @input="calculCollectSlotRange(service)"
                                                                        v-model="service.start_hour" />
                                                                    <vue-timepicker
                                                                        format="HH:mm"
                                                                        :minute-interval="15"
                                                                        v-model="service.end_hour"
                                                                        hide-disabled-hours
                                                                        @input="calculCollectSlotRange(service)"
                                                                        :hour-range="
                                                                            calculHourRange(service.start_hour)
                                                                        " />
                                                                    <label
                                                                        class="container-box ml-3"
                                                                        style="
                                                                            width: initial;
                                                                            margin-right: 0;
                                                                            padding-left: 28px;
                                                                        "
                                                                        v-for="(slot, index) in service.slots"
                                                                        :key="index">
                                                                        <input type="checkbox" v-model="slot.enabled" />
                                                                        <span class="checkmark"></span>
                                                                        {{ formatHour(slot.hour) }}
                                                                    </label>
                                                                    <feather
                                                                        type="x"
                                                                        class="text-danger pointer"
                                                                        @click="deleteCollectService(day, index)" />
                                                                </div>
                                                                <feather
                                                                    type="plus"
                                                                    class="pointer"
                                                                    @click="addCollectService(day)" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ml-2" v-else-if="collectType === COLLECT_TYPE_DELIVERY.value">
                                            <show-errors
                                                :errors="errors"
                                                class="col-12"
                                                errorKey="delivery_slots.data" />
                                            <div class="col-12" v-for="day in allDays" :key="day">
                                                <div v-show="daysActive.includes(day)">
                                                    <div class="mb-2">
                                                        <label
                                                            class="container-box"
                                                            style="width: 100%; text-transform: capitalize">
                                                            <input
                                                                type="checkbox"
                                                                name="delivery_days_active[]"
                                                                v-model="openingFrontData.deliveryDaysActive"
                                                                :value="day" />
                                                            <span class="checkmark"></span> {{ dayLabel(day) }}
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="setting-open"
                                                        v-if="openingFrontData.deliveryDaysActive.includes(day)">
                                                        <div class="setting-hours">
                                                            <span>
                                                                <div
                                                                    v-for="(slot, index) in getDeliverySlotsForDay(day)"
                                                                    :key="index">
                                                                    <vue-timepicker
                                                                        format="HH:mm"
                                                                        :minute-interval="15"
                                                                        v-model="slot.hour_start" />
                                                                    <vue-timepicker
                                                                        format="HH:mm"
                                                                        :minute-interval="15"
                                                                        v-model="slot.hour_end"
                                                                        hide-disabled-hours
                                                                        :hour-range="
                                                                            calculHourRange(slot.hour_start)
                                                                        " />
                                                                    <feather
                                                                        type="x"
                                                                        class="text-danger pointer"
                                                                        @click="deleteDeliverySlot(day, index)" />
                                                                </div>
                                                                <feather
                                                                    type="plus"
                                                                    class="pointer"
                                                                    @click="addDeliverySlot(day)" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div v-else>
                                <em>Aucun service n'est disponible. Merci de vérifier votre configuration.</em>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent";
import ShowErrors from "../../../components/errors/ShowErrors";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import CollectTypesEnum from "../../../mixins/enums/click_and_collect/CollectTypesEnum";
import VueTimepicker from "vue2-timepicker";

export default {
    data() {
        return {
            loading: 0,
            errors: null,
            allDays: [1, 2, 3, 4, 5, 6, 0],
            opening: {
                name: null,
                start_day: null,
                end_day: null,
                collect_types: [],
                cc_services: {
                    data: [],
                },
                delivery_slots: {
                    data: [],
                },
            },
            openingFrontData: {
                collectDaysActive: [],
                deliveryDaysActive: [],
            },
            fr,
            config: null,
        };
    },
    mixins: [CollectTypesEnum],
    props: {
        opening_id: {
            default: null,
        },
    },
    computed: {
        daysActive() {
            if (!this.opening.start_day || !this.opening.end_day) return [];
            let startDay = moment(this.opening.start_day);
            let endDay = moment(this.opening.end_day);
            let days = [];
            while (startDay.isSameOrBefore(endDay)) {
                if (days.length >= 7) break;
                days.push(startDay.day());
                startDay.add(1, "days");
            }
            return days;
        },
        availableCollectTypes() {
            let availableCollectTypes = [];
            if (!this.config) return availableCollectTypes;
            if (this.config.is_click_and_collect_enabled) availableCollectTypes.push(this.COLLECT_TYPE_COLLECT.value);
            if (this.config.is_delivery_enabled) availableCollectTypes.push(this.COLLECT_TYPE_DELIVERY.value);
            return availableCollectTypes;
        },
        disabledFromDates() {
            if (!this.opening.end_day) return {};
            return {
                from: this.opening.end_day,
            };
        },
        disabledToDates() {
            if (!this.opening.start_day) return {};
            return {
                to: this.opening.start_day,
            };
        },
        builtData() {
            let data = _.cloneDeep(this.opening);
            data.start_day = data.start_day ? moment(data.start_day).format("Y-MM-DD") : null;
            data.end_day = data.end_day ? moment(data.end_day).format("Y-MM-DD") : null;
            data.cc_services.data = data.cc_services.data
                .filter((service) => this.openingFrontData.collectDaysActive.includes(service.day))
                .map((service) => {
                    return {
                        ...service,
                        start_hour: `${service.start_hour.HH}:${service.start_hour.mm}`,
                        end_hour: `${service.end_hour.HH}:${service.end_hour.mm}`,
                    };
                });
            data.delivery_slots.data = data.delivery_slots.data
                .filter((slot) => this.openingFrontData.deliveryDaysActive.includes(slot.day))
                .map((slot) => {
                    return {
                        ...slot,
                        hour_start: `${slot.hour_start.HH}:${slot.hour_start.mm}`,
                        hour_end: `${slot.hour_end.HH}:${slot.hour_end.mm}`,
                    };
                });
            return data;
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        getDeliverySlotsForDay(day) {
            return this.opening.delivery_slots.data.filter((slot) => slot.day === day);
        },
        deleteCollectService(day, index) {
            let indexToRemove = null;
            let currentIndex = 0;
            this.opening.cc_services.data.forEach((elem, i) => {
                if (elem.day === day) {
                    if (currentIndex === index) indexToRemove = i;
                    currentIndex++;
                }
            });
            if (indexToRemove !== null) this.opening.cc_services.data.splice(indexToRemove, 1);
        },
        deleteDeliverySlot(day, index) {
            let indexToRemove = null;
            let currentIndex = 0;
            this.opening.delivery_slots.data.forEach((elem, i) => {
                if (elem.day === day) {
                    if (currentIndex === index) indexToRemove = i;
                    currentIndex++;
                }
            });
            if (indexToRemove !== null) this.opening.delivery_slots.data.splice(indexToRemove, 1);
        },
        calculCollectSlotRange(service) {
            let startMoment =
                service.start_hour.HH && service.start_hour.mm
                    ? moment(`${service.start_hour.HH}:${service.start_hour.mm}`, "HH:mm")
                    : null;
            let endMoment =
                service.end_hour.HH && service.end_hour.mm
                    ? moment(`${service.end_hour.HH}:${service.end_hour.mm}`, "HH:mm")
                    : null;
            if (!startMoment || !endMoment || startMoment.isAfter(endMoment)) {
                service.slots = [];
                return;
            }
            service.slots = service.slots.filter((slot) => {
                let slotHour = moment(slot.hour, "HH:mm");
                return !(slotHour.isAfter(endMoment) || slotHour.isBefore(startMoment));
            });
            while (startMoment.isSameOrBefore(endMoment)) {
                let found = service.slots.find((slot) => moment(slot.hour, "HH:mm").isSame(startMoment));
                if (!found)
                    service.slots.push({
                        enabled: true,
                        hour: startMoment.format("HH:mm"),
                    });
                startMoment.add(15, "minutes");
            }
            service.slots.sort((a, b) => moment(a.hour, "HH:mm").diff(moment(b.hour, "HH:mm"), "minute"));
        },
        addCollectService(day) {
            this.opening.cc_services.data.push({
                day,
                slots: [],
                start_hour: {
                    HH: null,
                    mm: null,
                },
                end_hour: {
                    HH: null,
                    mm: null,
                },
            });
        },
        addDeliverySlot(day) {
            this.opening.delivery_slots.data.push({
                day,
                hour_start: {
                    HH: null,
                    mm: null,
                },
                hour_end: {
                    HH: null,
                    mm: null,
                },
            });
        },
        calculHourRange(start_hour) {
            var start = parseInt(start_hour.HH);
            return [[start, 23]];
        },
        getCollectServicesForDay(day) {
            return this.opening.cc_services.data.filter((s) => s.day === day);
        },
        assignResponseToOpening(data) {
            this.opening = data;
            this.opening.start_day = data.start_day ? moment(data.start_day).toDate() : null;
            this.opening.end_day = data.end_day ? moment(data.end_day).toDate() : null;
            this.opening.cc_services.data.forEach((service) => {
                const startHourSplit = service.start_hour.split(":");
                const endHourSplit = service.end_hour.split(":");
                service.start_hour = {
                    HH: startHourSplit[0],
                    mm: startHourSplit[1],
                };
                service.end_hour = {
                    HH: endHourSplit[0],
                    mm: endHourSplit[1],
                };
                if (!this.openingFrontData.collectDaysActive.includes(service.day))
                    this.openingFrontData.collectDaysActive.push(service.day);
            });
            this.opening.delivery_slots.data.forEach((slot) => {
                const startHourSplit = slot.hour_start.split(":");
                const endHourSplit = slot.hour_end.split(":");
                slot.hour_start = {
                    HH: startHourSplit[0],
                    mm: startHourSplit[1],
                };
                slot.hour_end = {
                    HH: endHourSplit[0],
                    mm: endHourSplit[1],
                };
                if (!this.openingFrontData.deliveryDaysActive.includes(slot.day))
                    this.openingFrontData.deliveryDaysActive.push(slot.day);
            });
        },
        fetchOpening() {
            if (!this.opening_id) return;
            this.loading++;

            axios
                .get(
                    `/api/click_and_collect/${this.restaurant_id}/openings/${this.opening_id}?include=cc_services,delivery_slots`
                )
                .then((response) => {
                    this.loading--;
                    this.assignResponseToOpening(response.data);
                })
                .catch((error) => {
                    this.loading--;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
        fetchConfig() {
            this.loading++;

            axios
                .get(`/api/click_and_collect/${this.restaurant_id}/settings`)
                .then((response) => {
                    this.loading--;
                    this.config = response.data;
                })
                .catch((error) => {
                    this.loading--;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
        fetchData() {
            this.fetchOpening();
            this.fetchConfig();
        },
        save() {
            this.loading++;
            this.errors = null;

            if (this.opening_id)
                var promise = axios.put(
                    `/api/click_and_collect/${this.restaurant_id}/openings/${this.opening_id}`,
                    this.builtData
                );
            else var promise = axios.post(`/api/click_and_collect/${this.restaurant_id}/openings`, this.builtData);
            promise
                .then((response) => {
                    this.loading--;
                    this.$emit("saved");
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: this.opening_id
                            ? "L'ouverture exceptionnelle a bien été modifiée"
                            : "L'ouverture exceptionnelle a bien été créée",
                    });
                })
                .catch((error) => {
                    this.loading--;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
        uncheckDisabledDays() {
            this.openingFrontData.collectDaysActive = this.openingFrontData.collectDaysActive.filter((day) =>
                this.daysActive.includes(day)
            );
            this.openingFrontData.deliveryDaysActive = this.openingFrontData.deliveryDaysActive.filter((day) =>
                this.daysActive.includes(day)
            );
        },
    },
    watch: {
        "opening.start_day": function () {
            this.uncheckDisabledDays();
        },
        "opening.end_day": function () {
            this.uncheckDisabledDays();
        },
    },
    created() {
        this.fetchData();
    },
    components: {
        LoaderComponent,
        ShowErrors,
        Datepicker,
        VueTimepicker,
    },
};
</script>
