<template>
    <div>
        <div>
            <ChooseLang class="float-right mr-3 mt-4" />
            <horizontal-stepper ref="stepper" :steps="stepList" :currentStep="{ index: step }"> </horizontal-stepper>
        </div>
        <component
            :restaurant_id="restaurant.id"
            :restaurant="restaurant"
            :showBackButton="true"
            @done="step += 1"
            @back="step -= 1"
            :is="currentComponent" />
    </div>
</template>

<script>
import AddRestaurant from "./AddRestaurant.vue";
import LinkStripe from "./LinkStripe.vue";
import SelectLicence from "./SelectLicence.vue";
import HorizontalStepper from "vue-stepper";
import CardPaymentSystemEnum from "../../../mixins/enums/CardPaymentSystemEnum";
import ChooseLang from "../../../components/Default/ChooseLang.vue";

export default {
    components: {
        AddRestaurant,
        LinkStripe,
        SelectLicence,
        HorizontalStepper,
        ChooseLang,
    },
    mixins: [CardPaymentSystemEnum],
    data() {
        return {
            step: 0,
            componentsList: ["AddRestaurant", "LinkStripe", "SelectLicence"],
            restaurant: {
                tva_disabled: false,
            },
        };
    },
    watch: {
        step(newVal, oldVal) {
            this.$nextTick().then(() => {
                this.$refs.stepper.activateStep(newVal);
            });
        },
    },
    created() {
        const restaurants = this.$store.getters["restaurants/restaurants"];
        if (restaurants.length > 0) {
            this.restaurant = this.$_.cloneDeep(restaurants[0]);
        } else {
            this.restaurant = {
                id: null,
                name: "",
                logo: {
                    url: "",
                    file: null,
                },
                address: "",
                further_address_details: "",
                zipcode: "",
                city: "",
                country: "",
                tel: "",
                website: "",
                timezone: "Europe/Paris",
                widget_color: "#b4975a",
                widget_font_title: "'Open Sans', sans-serif",
                prefered_payment_system: this.CARD_PAYMENT_SYSTEM_STRIPE.value,
                currency: "EUR",
                lexicon: "restaurant",
                google_maps_address: null,
            };
        }
    },
    computed: {
        currentComponent() {
            return this.componentsList[this.step];
        },
        stepList() {
            return [
                {
                    icon: "store",
                    name: "Etablissement",
                    title: this.$tl("labels.form.restaurant.add"),
                    completed: false,
                },
                {
                    icon: "payment",
                    name: "Stripe",
                    title: this.$tl("labels.form.psp.link"),
                    completed: false,
                },
                {
                    icon: "shopping_cart",
                    name: "Licence",
                    title: this.$tl("labels.form.licences.select"),
                    completed: false,
                },
            ];
        },
    },
};
</script>
