<template>
    <div class="w-100">
        <loader-component v-if="loading" />
        <div v-else-if="error">
            {{ error }}
        </div>
        <div class="default-home" v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex flex-column align-items-center flex-md-row pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">{{ $tl("labels.routes.restaurants") }}</h5>
                        <div class="d-flex flex-column flex-md-row align-items-center ml-md-auto">
                            <router-link
                                v-if="has_rights_to_create_zones"
                                :to="{ name: 'restaurants.manage_zones' }"
                                class="btn btn-sm btn-success btn-circle my-1 mx-md-1">
                                <feather type="plus"></feather>
                                {{ $tl("labels.routes.manageAreas") }}
                            </router-link>
                            <router-link
                                :to="{ name: 'restaurants.add' }"
                                class="btn btn-sm btn-success btn-circle my-1 mx-md-1"
                                :class="{ disabled: !has_right_to_create_restaurant }">
                                <feather type="plus"></feather>
                                {{ $tl("labels.routes.addRestaurant") }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <restaurant-card
                    v-for="(restaurant, index) in restaurants"
                    :key="index"
                    :restaurant="restaurant"
                    :disabled="!canAccessRestaurant(restaurant.id)"
                    @click="editRestaurant($event.id)"
                    class="col-md-4 mb-3" />
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import RestaurantCard from "./RestaurantCard.vue";

export default {
    name: "ListRestaurants",
    data() {
        return {
            error: null,
            loading: false,
            restaurants: [],
        };
    },
    created() {
        this.fetchRestaurants();
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read_restaurant() {
            return this.rights.includes("default.restaurants.read");
        },
        has_right_to_create_restaurant() {
            return this.rights.includes("default.restaurants.create");
        },
        userRestaurantsIds() {
            return this.$store.getters["users/restaurantIds"];
        },
        restaurantIds() {
            return this.restaurants.map((restaurant) => restaurant.id);
        },
        has_rights_to_create_zones() {
            return (
                this.rights.includes("default.zones.create") &&
                !this.restaurantIds.some((restaurantId) => !this.userRestaurantsIds.includes(restaurantId))
            );
        },
    },
    methods: {
        fetchRestaurants() {
            this.error = null;
            this.loading = true;
            this.restaurants = [];

            this.$store
                .dispatch("restaurants/fetchAllRestaurants")
                .then((response) => {
                    this.restaurants = response.data.data;
                    if (this.isClubMedOwnerId(this.$store.getters["users/ownerId"])) {
                        this.restaurants.sort((a, b) => a.name.localeCompare(b.name));
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    if (error.response && error.response.data.message) {
                        this.error = error.response.data.message;
                    } else {
                        this.error = error.message;
                    }
                    this.loading = false;
                });
        },
        editRestaurant(restaurant_id) {
            if (this.has_right_to_read_restaurant) this.$router.push({ name: "restaurants.edit", params: { restaurant_id } });
        },
        canAccessRestaurant(restaurantId) {
            return this.has_right_to_read_restaurant && this.userRestaurantsIds.includes(restaurantId);
        },
    },
    components: {
        LoaderComponent,
        RestaurantCard,
    },
};
</script>
