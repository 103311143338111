var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : !_vm.website_enabled
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v("Votre site web n'est pas activé"),
          ])
        : [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                  },
                  [
                    _c("h5", { staticClass: "title mt-2" }, [
                      _vm._v("Configuration du référencement"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("strong", [_vm._v("Mentions Légales")]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "btn btn-sm btn-circle btn-success float-right",
                          attrs: {
                            target: "_blank",
                            href: `/api/website/${_vm.restaurant_id}/default_legal_mentions`,
                          },
                        },
                        [
                          _vm._v(
                            "\n                                Voir les mentions légales\n                            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("p", [
                        _vm._v(
                          "\n                                Nous mettons à votre disposition des Mentions Légales standards pour vous permettre\n                                de mettre en place site web rapidement et dans les meilleures conditions. Nous vous\n                                invitons à en prendre connaissance attentivement.\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n                                Nous vous conseillons toutefois d'intégrer vos propres Mentions Légales en important\n                                votre fichier ci-dessous.\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("small", { staticClass: "mb-0 text-warning" }, [
                        _vm._v(
                          "Sans action de votre part, les mentions par défaut seront appliquées."
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-7" },
                      [
                        _c("FileUploaderComponent", {
                          attrs: {
                            disabled: !_vm.has_right_to_update_config,
                            src: _vm.website_settings.legal_mention_pdf,
                            suffixId: "legal_mention_pdf",
                            postURI: _vm.baseURILegalMention,
                            deleteURI: _vm.baseURILegalMention,
                            callBackPost: (response) => {
                              this.website_settings.legal_mention_pdf =
                                response.data.file_name
                            },
                            callBackDelete: () => {
                              this.website_settings.legal_mention_pdf = null
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }