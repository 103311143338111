var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-inline-block", staticStyle: { "margin-top": "4px" } },
      [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.startSearch.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "form-control search-client d-inline pr-1",
                staticStyle: { "border-radius": "20px 0 0 20px !important" },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search",
                    },
                  ],
                  ref: "searchInput",
                  staticClass: "search-input",
                  attrs: {
                    placeholder: _vm.$tl("labels.clients.searchPlaceHolder"),
                    type: "text",
                  },
                  domProps: { value: _vm.search },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.search = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "p-0 m-0 clear-search-button",
                    staticStyle: { background: "none", border: "none" },
                    attrs: { type: "button" },
                    on: { click: _vm.clearSearch },
                  },
                  [_c("feather", { attrs: { type: "x" } })],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "btn btn-sm btn-outline-secondary search-client-btn",
                class: { disabled: _vm.loading },
                staticStyle: { "border-radius": "0 20px 20px 0" },
                attrs: { type: "submit", disabled: _vm.loading },
              },
              [_c("feather", { attrs: { type: "search" } })],
              1
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row m-0 mt-1" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.loading
            ? _c("LoaderComponent")
            : [
                _vm.clients_.length > 0
                  ? _c(
                      "table",
                      {
                        staticClass:
                          "capitalize table table-sm table-striped border-bottom",
                      },
                      [
                        _c("thead", { staticClass: "border-bottom" }, [
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.$tl("labels.form.client"))),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$tl("labels.form.email"))),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.$tl("labels.form.phoneNumber"))
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.clients_, function (client) {
                            return _c(
                              "tr",
                              {
                                key: client.id,
                                staticClass: "clickable",
                                on: {
                                  click: function ($event) {
                                    return _vm.onClientSelected(client)
                                  },
                                },
                              },
                              [
                                _c(
                                  "td",
                                  [
                                    _c("inline-client", {
                                      attrs: {
                                        client: client,
                                        "show-noshows": "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(client.email))]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(client.tel))]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  : _c("span", { staticClass: "font-italic" }, [
                      _vm._v(_vm._s(_vm.$tl("labels.noResult"))),
                    ]),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }