<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.form.psp.editPaymentMethod") }}</h5>
        <div slot="body" class="">
            <div v-if="step === 1" class="w-100 radio">
                <label class="container-box border-light b-radius-20 p-4 mb-3" style="display: block; width: 100%; padding-left: 50px !important">
                    <input type="radio" name="payment_method_type" value="AddCard" v-model="component" />
                    <span style="top: 24px; left: 20px" class="checkmark"></span>
                    {{ $tl("labels.form.psp.paymentMethods.card.title") }}
                </label>
                <label class="container-box border-light b-radius-20 p-4" style="display: block; width: 100%; padding-left: 50px !important">
                    <input type="radio" name="payment_method_type" value="AddSepaDebit" v-model="component" />
                    <span style="top: 24px; left: 20px" class="checkmark"></span>
                    {{ $tl("labels.form.psp.paymentMethods.sepaDebit.title") }}
                </label>
            </div>
            <template v-else-if="step === 2">
                <component :is="component" ref="addCard" :showButtons="false" @updated="onUpdated" @clear-selected-form="step = 1" />
            </template>
        </div>
        <div slot="footer" class="d-flex">
            <button class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="$emit('close')">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button class="btn btn-sm btn-success btn-circle ml-2" @click="next" :disabled="!component">
                {{ step === 1 ? $tl("labels.form.actions.next") : $tl("labels.form.actions.save") }}
            </button>
        </div>
    </modal>
</template>

<script>
import AddCard from "../../../../stripe/Customer/PaymentMethods/AddCard.vue";
import AddSepaDebit from "../../../../stripe/Customer/PaymentMethods/AddSepaDebit.vue";

export default {
    name: "AddCardModal",
    data() {
        return {
            step: 1,
            component: null,
        };
    },
    props: {
        addCardOnly: {
            default: true,
        },
    },
    methods: {
        onUpdated() {
            this.$emit("updated");
            this.close();
        },
        next() {
            if (this.step === 1) this.step = 2;
            else this.save();
        },
        save() {
            this.$refs.addCard.save();
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        AddCard,
        AddSepaDebit,
    },
    created() {
        if (this.addCardOnly) this.component = "AddCard";
    },
};
</script>
