<template>
    <div class="padding-vue">
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div class="d-flex">
                <span class="btn btn-sm btn-outline-secondary back-button radius-btn-square" @click="redirectToReservations()">
                    <feather type="arrow-left" />
                </span>
                <h6 style="width: 82%" class="text-center">{{ $tl("labels.search.result") }}</h6>
            </div>
            <div v-if="error" class="error">
                {{ error }}
            </div>
            <div v-else>
                <div class="float-right" style="margin-top: -45px">
                    <input
                        style="border-radius: 20px 0 0 20px !important"
                        class="search-resa form-control"
                        type="text"
                        v-model="searchInput"
                        placeholder="Rechercher"
                        @keyup.enter="search()" />
                    <button style="border-radius: 0 20px 20px 0" class="btn btn-sm btn-outline-secondary" @click="search()">
                        <feather type="search" />
                    </button>
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div v-if="restaurant">
                            <div v-if="'undefined' === typeof restaurant.reservations_per_date || restaurant.reservations_per_date * 1 === 0">
                                <em>{{ $tl("labels.search.noResult") }}</em>
                            </div>
                            <div v-else>
                                <div v-for="(resa, date_resa) in restaurant.reservations_per_date" :key="date_resa">
                                    <h5 class="mb-3" style="color: #083e60">
                                        <strong>{{ displayDate(date_resa, DATE_FULL_NO_YEAR, false) }}</strong>
                                    </h5>

                                    <div v-for="(reservation, reservationIndex) in resa" :key="reservationIndex">
                                        <div class="d-flex flex-wrap justify-content-between flex-md-nowrap pb-2 mb-3 border-bottom">
                                            <h6 style="text-transform: uppercase">
                                                {{ getDateTime(date_resa).weekdayLong }}
                                                <span class="capacite badge badge-secondary">{{ reservation.service_name }}</span>
                                            </h6>
                                            <h6>
                                                <span class="capacite badge badge-secondary">
                                                    {{ reservation.nb_pax }} / {{ reservation.max_pax }}</span
                                                >
                                            </h6>
                                        </div>

                                        <div class="border-bottom mb-5">
                                            <table class="table table-striped table-sm table-tr-clickable">
                                                <thead class="border-bottom">
                                                    <tr>
                                                        <th>
                                                            {{ $tl("labels.booking.reservations.grid.columns.client") }}
                                                        </th>
                                                        <th>
                                                            {{ $tl("labels.booking.reservations.grid.columns.pax") }}
                                                        </th>
                                                        <th>
                                                            {{ $tl("labels.booking.reservations.grid.columns.hour") }}
                                                        </th>
                                                        <th class="none-mobile">
                                                            {{ $tl("labels.booking.reservations.grid.columns.table", restaurant_id) }}
                                                        </th>
                                                        <th>
                                                            {{ $tl("labels.booking.reservations.grid.columns.status") }}
                                                        </th>
                                                        <th>
                                                            {{ $tl("labels.booking.reservations.grid.columns.actions") }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(resa, resaIndex) in reservation.reservations"
                                                        :key="resa.id"
                                                        @click="showResa(resa.id, $event)">
                                                        <td>
                                                            <inline-client
                                                                :client="resa.client"
                                                                show-noshows
                                                                :link="{
                                                                    name: 'booking.clients.form',
                                                                    params: { client_id: resa.client_id },
                                                                }"></inline-client>
                                                        </td>
                                                        <td>{{ resa.nb_pers * 1 + resa.nb_children * 1 }}</td>
                                                        <td>{{ resa.slot.hour_start }}</td>
                                                        <td class="none-mobile">{{ resa.num_table }}</td>
                                                        <td>
                                                            <ReservationTag
                                                                :update="true"
                                                                :cancellation="resa.service_cancellation_until"
                                                                :reservation="resa"
                                                                :restaurant_id="restaurant.id"
                                                                :isStripeEnabled="
                                                                    restaurant.stripe_client_id ||
                                                                    (restaurant.payplug_public_key && restaurant.payplug_secret_key)
                                                                "
                                                                @reservation-updated="updatedReservation"
                                                                :identifier="{
                                                                    date_resa,
                                                                    reservationIndex,
                                                                    resaIndex,
                                                                    id: resa.id,
                                                                }"
                                                                @displayNoshowModal="displayNoshowModal(resa)"
                                                                @displayCancelModal="displayCancelModal({ newStatus: $event, data: resa })" />
                                                        </td>
                                                        <td>
                                                            <button
                                                                v-if="resa.status !== 'over'"
                                                                class="btn btn-sm btn-success btn-square"
                                                                title="Modifier la réservation"
                                                                @click="editReservation($event, resa.reservation_id)"
                                                                v-tooltip="{
                                                                    delay: { show: 400, hide: 0 },
                                                                    content: 'Éditer',
                                                                }">
                                                                <feather type="edit" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <set-noshow-modal
                v-if="showSetNoshowModal"
                :restaurant_id="selected_restaurant_id"
                :reservation="selected_reservation"
                @close="showSetNoshowModal = false" />
            <show-reservation-modal
                v-if="showReservationModal"
                :reservation_id="selected_reservation_id"
                :restaurant_id="restaurant.id"
                @resa-edit="editReservationFromShowModal"
                @close="showReservationModal = false" />
            <edit-reservation-modal
                v-if="showEditReservationModal"
                :restaurant_id="restaurant.id"
                :reservation_id="selected_reservation_id"
                @close="showEditReservationModal = false"
                @reservation-edited="reservationEdited" />
            <cancel-reservation-modal
                v-if="showCancelModal"
                :reservation-id="selected_reservation_id"
                :client="selected_reservation_client"
                :status="newCancelStatus"
                @close="showCancelModal = false" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ReservationTag from "../../components/reservations/ReservationTagComponent";
import SetNoshowModal from "../../components/Modals/reservations/SetNoshowModal.vue";
import LoaderComponent from "../../components/LoaderComponent.vue";
import ShowReservationModal from "../../components/Modals/reservations/ShowReservationModal";
import EditReservationModal from "../../components/Modals/reservations/EditReservationModal";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";
import InlineClient from "../../components/Default/Clients/inlineClient.vue";
import CancelReservationModal from "../../components/Modals/reservations/CancelReservationModal.vue";

export default {
    data() {
        return {
            loading: false,
            error: null,
            showSetNoshowModal: false,
            showReservationModal: false,
            showRemoveServiceFullModal: false,
            showEditReservationModal: false,
            selected_reservation_id: null,
            selected_reservation_client: null,
            selected_restaurant_id: null,
            selected_reservation: null,
            restaurant: {},
            searchInput: this.$route.query.query,
            newCancelStatus: "canceled",
            showCancelModal: false,
        };
    },
    mixins: [ModuleTypesEnum],
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        displayCancelModal({ newStatus, data }) {
            this.selected_reservation_id = data.id;
            this.selected_reservation_client = data.client;
            this.newCancelStatus = newStatus;
            this.showCancelModal = true;
        },
        fetchData() {
            this.error = this.restaurant = null;
            this.loading = true;

            axios
                .get(`/api/restaurants/${this.restaurant_id}/reservations/search?query=${this.searchInput}`)
                .then((response) => {
                    this.loading = false;
                    this.restaurant = response.data;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        search() {
            if (this.searchInput !== this.$route.query.query) {
                this.$router.push({
                    name: "booking.reservations.search",
                    query: {
                        query: this.searchInput,
                    },
                });
            }
            this.fetchData();
        },
        displayNoshowModal(reservation) {
            this.$set(this, "selected_restaurant_id", this.restaurant_id);
            this.$set(this, "selected_reservation", reservation);

            this.$nextTick(() => {
                this.$set(this, "showSetNoshowModal", true);
            });
        },
        showResa(id, event) {
            const excludedTags = ["BUTTON", "A"];

            if (!event || !event.target || !excludedTags.includes(event.target.tagName)) {
                this.$set(this, "selected_reservation_id", id);
                this.$nextTick(() => {
                    this.showReservationModal = true;
                });
            }
        },
        redirectToReservations() {
            this.$router.push({
                name: "booking.restaurants.reservations",
                params: { restaurant_id: this.restaurant_id },
            });
        },
        updatedReservation({ date_resa, reservationIndex, resaIndex, id }) {
            axios
                .get(`/api/restaurants/${this.restaurant_id}/reservations/${id}?include=slot,client`)
                .then((response) => {
                    this.$set(this.restaurant.reservations_per_date[date_resa][reservationIndex].reservations, resaIndex, response.data);
                })
                .catch((error) => {
                    this.$notify({
                        group: "notification",
                        type: "warn",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        editReservationFromShowModal({ reservation_id }) {
            this.$set(this, "selected_reservation_id", reservation_id);
            this.$nextTick(() => {
                this.showReservationModal = false;
                this.showEditReservationModal = true;
            });
        },
        reservationEdited() {
            this.fetchData();
            this.showEditReservationModal = false;
            this.$notify({
                group: "notification",
                type: "success",
                title: this.$tl("success.booking.reservations.updatedLong"),
            });
        },
        editReservation(event, id) {
            event.preventDefault();
            event.stopPropagation();

            this.$set(this, "selected_reservation_id", id);
            this.$nextTick(() => {
                this.showEditReservationModal = true;
            });
        },
    },
    created() {
        this.fetchData();
    },
    watch: {
        restaurant_id() {
            this.fetchData();
        },
    },
    components: {
        ReservationTag,
        LoaderComponent,
        ShowReservationModal,
        EditReservationModal,
        SetNoshowModal,
        InlineClient,
        CancelReservationModal,
    },
};
</script>
