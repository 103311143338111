var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5 pt-5" },
    [
      _c("div", [
        _vm.error
          ? _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
            ])
          : _c(
              "div",
              [
                _c("div", { staticClass: "row m-0" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                      },
                      [
                        _c("h5", { staticClass: "title mt-2" }, [
                          _vm._v(
                            _vm._s(_vm.$tl("labels.routes.booking.settings"))
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.formErrors
                  ? _c(
                      "div",
                      { staticClass: "alert alert-danger" },
                      _vm._l(_vm.formErrors, function (error, index) {
                        return _c("p", { key: index }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(error) +
                              "\n                "
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isWidgetLoading || _vm.isFormLoading
                  ? _c("LoaderComponent")
                  : _c(
                      "form",
                      {
                        attrs: { id: "addWidget", method: "post" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.updateWidget.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("automatic-export-component", {
                          staticClass: "row m-0",
                          attrs: {
                            module: _vm.MODULE_TYPE_BOOKING.value,
                            "services-name": "booking_services",
                          },
                          model: {
                            value: _vm.currentWidget.automatic_export,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentWidget,
                                "automatic_export",
                                $$v
                              )
                            },
                            expression: "currentWidget.automatic_export",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row m-0" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-light b-radius-20 p-4 mb-3",
                              },
                              [
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.widget.messages.title"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-5 col-10" },
                                    [
                                      _c(
                                        "label",
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.widget.messages.general"
                                                )
                                              ) +
                                              "\n                                        "
                                          ),
                                          _c("feather", {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value:
                                                  _vm.getTooltipNoticeLexicon(
                                                    "notices.booking.widget.mainMessage",
                                                    _vm.restaurant_id
                                                  ),
                                                expression:
                                                  "getTooltipNoticeLexicon('notices.booking.widget.mainMessage', restaurant_id)",
                                              },
                                            ],
                                            staticClass:
                                              "ml-1 pointer tooltip-infos",
                                            attrs: { type: "info" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7 col-2" }, [
                                    _c(
                                      "label",
                                      { staticClass: "container-box" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.currentWidget
                                                  .widget_messages
                                                  .cover_page_message_activated,
                                              expression:
                                                "currentWidget.widget_messages.cover_page_message_activated",
                                            },
                                          ],
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_widget,
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.currentWidget.widget_messages
                                                .cover_page_message_activated
                                            )
                                              ? _vm._i(
                                                  _vm.currentWidget
                                                    .widget_messages
                                                    .cover_page_message_activated,
                                                  null
                                                ) > -1
                                              : _vm.currentWidget
                                                  .widget_messages
                                                  .cover_page_message_activated,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.currentWidget
                                                    .widget_messages
                                                    .cover_page_message_activated,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.currentWidget
                                                        .widget_messages,
                                                      "cover_page_message_activated",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.currentWidget
                                                        .widget_messages,
                                                      "cover_page_message_activated",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.currentWidget
                                                    .widget_messages,
                                                  "cover_page_message_activated",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.currentWidget.widget_messages
                                  .cover_page_message_activated
                                  ? [
                                      _c("div", { staticClass: "row mb-1" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-md-7 offset-md-5",
                                          },
                                          [
                                            _c("div", [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.currentWidget
                                                        .widget_messages
                                                        .cover_page_message_content,
                                                    expression:
                                                      "currentWidget.widget_messages.cover_page_message_content",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  disabled:
                                                    !_vm.has_right_to_update_widget,
                                                  maxlength: "1500",
                                                  placeholder:
                                                    _vm.$t("labels.langs.fr"),
                                                  rows: "3",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.currentWidget
                                                      .widget_messages
                                                      .cover_page_message_content,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.currentWidget
                                                        .widget_messages,
                                                      "cover_page_message_content",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-3" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-md-7 offset-md-5",
                                          },
                                          [
                                            _c("div", [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.currentWidget
                                                        .widget_messages
                                                        .cover_page_message_content_en,
                                                    expression:
                                                      "currentWidget.widget_messages.cover_page_message_content_en",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  disabled:
                                                    !_vm.has_right_to_update_widget,
                                                  maxlength: "1500",
                                                  placeholder:
                                                    _vm.$t("labels.langs.en"),
                                                  rows: "3",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.currentWidget
                                                      .widget_messages
                                                      .cover_page_message_content_en,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.currentWidget
                                                        .widget_messages,
                                                      "cover_page_message_content_en",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-1" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-5 col-10" },
                                    [
                                      _c(
                                        "label",
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.widget.messages.slot"
                                                )
                                              ) +
                                              "\n                                        "
                                          ),
                                          _c("feather", {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value:
                                                  _vm.getTooltipNoticeLexicon(
                                                    "notices.booking.widget.slotMessage",
                                                    _vm.restaurant_id
                                                  ),
                                                expression:
                                                  "getTooltipNoticeLexicon('notices.booking.widget.slotMessage', restaurant_id)",
                                              },
                                            ],
                                            staticClass:
                                              "ml-1 pointer tooltip-infos",
                                            attrs: { type: "info" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7 col-2" }, [
                                    _c(
                                      "label",
                                      { staticClass: "container-box" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.currentWidget
                                                  .widget_messages
                                                  .slot_message_activated,
                                              expression:
                                                "currentWidget.widget_messages.slot_message_activated",
                                            },
                                          ],
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_widget,
                                            type: "checkbox",
                                            "true-value": "1",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.currentWidget.widget_messages
                                                .slot_message_activated
                                            )
                                              ? _vm._i(
                                                  _vm.currentWidget
                                                    .widget_messages
                                                    .slot_message_activated,
                                                  null
                                                ) > -1
                                              : _vm._q(
                                                  _vm.currentWidget
                                                    .widget_messages
                                                    .slot_message_activated,
                                                  "1"
                                                ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.currentWidget
                                                    .widget_messages
                                                    .slot_message_activated,
                                                $$el = $event.target,
                                                $$c = $$el.checked ? "1" : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.currentWidget
                                                        .widget_messages,
                                                      "slot_message_activated",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.currentWidget
                                                        .widget_messages,
                                                      "slot_message_activated",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.currentWidget
                                                    .widget_messages,
                                                  "slot_message_activated",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.currentWidget.widget_messages
                                  .slot_message_activated
                                  ? [
                                      _c("div", { staticClass: "row mb-1" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-md-7 offset-md-5",
                                          },
                                          [
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.currentWidget
                                                      .widget_messages
                                                      .slot_message_content,
                                                  expression:
                                                    "currentWidget.widget_messages.slot_message_content",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                disabled:
                                                  !_vm.has_right_to_update_widget,
                                                maxlength: "220",
                                                placeholder:
                                                  _vm.$t("labels.langs.fr"),
                                                rows: "3",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.currentWidget
                                                    .widget_messages
                                                    .slot_message_content,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.currentWidget
                                                      .widget_messages,
                                                    "slot_message_content",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-md-7 offset-md-5",
                                          },
                                          [
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.currentWidget
                                                      .widget_messages
                                                      .slot_message_content_en,
                                                  expression:
                                                    "currentWidget.widget_messages.slot_message_content_en",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                disabled:
                                                  !_vm.has_right_to_update_widget,
                                                maxlength: "220",
                                                placeholder:
                                                  _vm.$t("labels.langs.en"),
                                                rows: "3",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.currentWidget
                                                    .widget_messages
                                                    .slot_message_content_en,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.currentWidget
                                                      .widget_messages,
                                                    "slot_message_content_en",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row m-0" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-light b-radius-20 p-4 mb-3",
                              },
                              [
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.widget.resaSettings.title"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-2" }, [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.widget.resaSettings.nbPersMin"
                                          )
                                        ) + " "
                                      ),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-md-3" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.currentWidget.min_pers,
                                              expression:
                                                "currentWidget.min_pers",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_widget,
                                            type: "number",
                                          },
                                          domProps: {
                                            value: _vm.currentWidget.min_pers,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.currentWidget,
                                                "min_pers",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-2" }, [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.widget.resaSettings.nbPersMax"
                                          )
                                        ) + " "
                                      ),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-md-3" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.currentWidget.max_pers,
                                              expression:
                                                "currentWidget.max_pers",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_widget,
                                            type: "number",
                                          },
                                          domProps: {
                                            value: _vm.currentWidget.max_pers,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.currentWidget,
                                                "max_pers",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-2" }, [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.widget.resaSettings.enableChildren"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-1 pt-2" }, [
                                        _c(
                                          "label",
                                          { staticClass: "container-box" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.currentWidget
                                                      .enable_children,
                                                  expression:
                                                    "currentWidget.enable_children",
                                                },
                                              ],
                                              attrs: {
                                                disabled:
                                                  !_vm.has_right_to_update_widget,
                                                type: "checkbox",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.currentWidget
                                                    .enable_children
                                                )
                                                  ? _vm._i(
                                                      _vm.currentWidget
                                                        .enable_children,
                                                      null
                                                    ) > -1
                                                  : _vm.currentWidget
                                                      .enable_children,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.currentWidget
                                                        .enable_children,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.currentWidget,
                                                          "enable_children",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.currentWidget,
                                                          "enable_children",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.currentWidget,
                                                      "enable_children",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "checkmark",
                                            }),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm.currentWidget.enable_children
                                        ? _c(
                                            "div",
                                            { staticClass: "col-md-4 pt-2" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.widget.resaSettings.maxPerResa"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.currentWidget.enable_children
                                        ? _c(
                                            "div",
                                            { staticClass: "col-md-7" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.currentWidget
                                                        .max_children,
                                                    expression:
                                                      "currentWidget.max_children",
                                                  },
                                                ],
                                                staticClass:
                                                  "form-control d-inline-block",
                                                class: {
                                                  disabled:
                                                    _vm.currentWidget
                                                      .unlimited_nb_children,
                                                },
                                                staticStyle: { width: "auto" },
                                                attrs: {
                                                  disabled:
                                                    !_vm.has_right_to_update_widget ||
                                                    _vm.currentWidget
                                                      .unlimited_nb_children,
                                                  type: "text",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.currentWidget
                                                      .max_children,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.currentWidget,
                                                      "max_children",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "container-box ml-2",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.currentWidget
                                                            .unlimited_nb_children,
                                                        expression:
                                                          "currentWidget.unlimited_nb_children",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        !_vm.has_right_to_update_widget,
                                                      type: "checkbox",
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.currentWidget
                                                          .unlimited_nb_children
                                                      )
                                                        ? _vm._i(
                                                            _vm.currentWidget
                                                              .unlimited_nb_children,
                                                            null
                                                          ) > -1
                                                        : _vm.currentWidget
                                                            .unlimited_nb_children,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.currentWidget
                                                              .unlimited_nb_children,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm.currentWidget,
                                                                "unlimited_nb_children",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm.currentWidget,
                                                                "unlimited_nb_children",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm.currentWidget,
                                                            "unlimited_nb_children",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", {
                                                    staticClass: "checkmark",
                                                  }),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.unlimited"
                                                      )
                                                    ) +
                                                      "\n                                            "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-2" }, [
                                    _c("label", [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.booking.widget.resaSettings.openingPeriod"
                                            )
                                          ) +
                                          "\n                                        "
                                      ),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-3 col-6" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.openingPeriod
                                                    .maxReservationHours,
                                                expression:
                                                  "openingPeriod.maxReservationHours",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              disabled:
                                                !_vm.has_right_to_update_widget,
                                              type: "text",
                                            },
                                            domProps: {
                                              value:
                                                _vm.openingPeriod
                                                  .maxReservationHours,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.openingPeriod,
                                                  "maxReservationHours",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-9 col-6" },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.openingPeriod
                                                      .maxReservationUnit,
                                                  expression:
                                                    "openingPeriod.maxReservationUnit",
                                                },
                                              ],
                                              staticClass: "custom-select",
                                              attrs: {
                                                disabled:
                                                  !_vm.has_right_to_update_widget,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.openingPeriod,
                                                    "maxReservationUnit",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "hours" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tl("labels.hour")
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "days" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tl("labels.day")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.isFeatRoomNumbersEnable
                                  ? _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-5 pt-2" },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.booking.widget.roomNumbers.title"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c("small", [_vm._v("*")]),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-7 mt-1" },
                                        [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-success btn-circle btn-sm",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showImportRoomNumbersModal = true
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.widget.roomNumbers.edit"
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-success btn-circle btn-sm",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showShowRoomNumbersModal = true
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          _vm.$tl(
                                                            "labels.booking.widget.roomNumbers.show"
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("setting-part-form-gtag", {
                          model: {
                            value: _vm.currentWidget.gtag_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentWidget, "gtag_id", $$v)
                            },
                            expression: "currentWidget.gtag_id",
                          },
                        }),
                        _vm._v(" "),
                        _vm.isReserveWithGoogleEnabled()
                          ? _c("div", { staticClass: "row m-0" }, [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("google-reserve", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      settings:
                                        _vm.currentWidget.googleReserveSettings,
                                      "restaurant-id": _vm.restaurant_id,
                                      "for-widget": "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "row m-0" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-light b-radius-20 p-4 mb-3",
                              },
                              [
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.widget.resaOptions.title"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                !_vm.isFeatRoomNumbersEnable
                                  ? _c("div", { staticClass: "row mb-2" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-5 col-10" },
                                        [
                                          _c(
                                            "label",
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.widget.hotelMode"
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              ),
                                              _c("feather", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.getTooltipNotice(
                                                      "notices.booking.widget.hotelMode"
                                                    ),
                                                    expression:
                                                      "getTooltipNotice('notices.booking.widget.hotelMode')",
                                                  },
                                                ],
                                                staticClass:
                                                  "ml-1 pointer tooltip-infos",
                                                attrs: { type: "info" },
                                              }),
                                              _vm._v(" "),
                                              _c("show-new-badge-details", {
                                                attrs: {
                                                  badge_uuid:
                                                    "af5163fc-9b76-43f0-af01-75f9e97de030",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-7 col-2" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "container-box" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.currentWidget
                                                        .hotel_mode,
                                                    expression:
                                                      "currentWidget.hotel_mode",
                                                  },
                                                ],
                                                attrs: {
                                                  disabled:
                                                    !_vm.has_right_to_update_widget,
                                                  type: "checkbox",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.currentWidget.hotel_mode
                                                  )
                                                    ? _vm._i(
                                                        _vm.currentWidget
                                                          .hotel_mode,
                                                        null
                                                      ) > -1
                                                    : _vm.currentWidget
                                                        .hotel_mode,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.currentWidget
                                                          .hotel_mode,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.currentWidget,
                                                            "hotel_mode",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.currentWidget,
                                                            "hotel_mode",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.currentWidget,
                                                        "hotel_mode",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-5 col-10" },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.booking.widget.resaOptions.enableCompany"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7 col-2" }, [
                                    _c(
                                      "label",
                                      { staticClass: "container-box" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.currentWidget
                                                  .enable_society,
                                              expression:
                                                "currentWidget.enable_society",
                                            },
                                          ],
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_widget,
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.currentWidget.enable_society
                                            )
                                              ? _vm._i(
                                                  _vm.currentWidget
                                                    .enable_society,
                                                  null
                                                ) > -1
                                              : _vm.currentWidget
                                                  .enable_society,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.currentWidget
                                                    .enable_society,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.currentWidget,
                                                      "enable_society",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.currentWidget,
                                                      "enable_society",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.currentWidget,
                                                  "enable_society",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-5 col-10" },
                                    [
                                      _c(
                                        "label",
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.widget.resaOptions.enableComment"
                                                )
                                              ) +
                                              "\n                                        "
                                          ),
                                          _c("feather", {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.getTooltipNotice(
                                                  "notices.booking.widget.commentField"
                                                ),
                                                expression:
                                                  "getTooltipNotice('notices.booking.widget.commentField')",
                                              },
                                            ],
                                            staticClass:
                                              "ml-1 pointer tooltip-infos",
                                            attrs: { type: "info" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7 col-2" }, [
                                    _c(
                                      "label",
                                      { staticClass: "container-box" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.currentWidget
                                                  .comment_enabled,
                                              expression:
                                                "currentWidget.comment_enabled",
                                            },
                                          ],
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_widget,
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.currentWidget.comment_enabled
                                            )
                                              ? _vm._i(
                                                  _vm.currentWidget
                                                    .comment_enabled,
                                                  null
                                                ) > -1
                                              : _vm.currentWidget
                                                  .comment_enabled,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.currentWidget
                                                    .comment_enabled,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.currentWidget,
                                                      "comment_enabled",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.currentWidget,
                                                      "comment_enabled",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.currentWidget,
                                                  "comment_enabled",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.currentWidget.comment_enabled
                                  ? [
                                      _c("div", { staticClass: "row mb-3" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-5 pt-2" },
                                          [
                                            _c(
                                              "label",
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.booking.widget.resaOptions.addCommentPlaceHolder"
                                                      )
                                                    ) +
                                                    "\n                                            "
                                                ),
                                                _c("feather", {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.getTooltipNotice(
                                                          "notices.booking.widget.commentInfo"
                                                        ),
                                                      expression:
                                                        "getTooltipNotice('notices.booking.widget.commentInfo')",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "ml-1 pointer tooltip-infos",
                                                  attrs: { type: "info" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-md-7" }, [
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.currentWidget
                                                    .comment_label,
                                                expression:
                                                  "currentWidget.comment_label",
                                              },
                                            ],
                                            staticClass: "form-control mb-1",
                                            class: {
                                              disabled:
                                                !_vm.currentWidget
                                                  .comment_enabled,
                                            },
                                            attrs: {
                                              placeholder:
                                                _vm.$t("labels.langs.fr"),
                                              rows: "3",
                                              disabled:
                                                !_vm.currentWidget
                                                  .comment_enabled ||
                                                !_vm.has_right_to_update_widget,
                                            },
                                            domProps: {
                                              value:
                                                _vm.currentWidget.comment_label,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.currentWidget,
                                                  "comment_label",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.currentWidget
                                                    .comment_label_en,
                                                expression:
                                                  "currentWidget.comment_label_en",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              disabled:
                                                !_vm.currentWidget
                                                  .comment_enabled,
                                            },
                                            attrs: {
                                              placeholder:
                                                _vm.$t("labels.langs.en"),
                                              rows: "3",
                                              disabled:
                                                !_vm.currentWidget
                                                  .comment_enabled ||
                                                !_vm.has_right_to_update_widget,
                                            },
                                            domProps: {
                                              value:
                                                _vm.currentWidget
                                                  .comment_label_en,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.currentWidget,
                                                  "comment_label_en",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-3" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-5 col-10" },
                                          [
                                            _c(
                                              "label",
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.booking.widget.resaOptions.setCommentMandatory"
                                                      )
                                                    ) +
                                                    "\n                                            "
                                                ),
                                                _c("feather", {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.getTooltipNotice(
                                                          "notices.booking.widget.commentRequired"
                                                        ),
                                                      expression:
                                                        "getTooltipNotice('notices.booking.widget.commentRequired')",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "ml-1 pointer tooltip-infos",
                                                  attrs: { type: "info" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-7 col-2" },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "container-box" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.currentWidget
                                                          .comment_mandatory,
                                                      expression:
                                                        "currentWidget.comment_mandatory",
                                                    },
                                                  ],
                                                  class: {
                                                    disabled:
                                                      !_vm.currentWidget
                                                        .comment_enabled,
                                                  },
                                                  attrs: {
                                                    type: "checkbox",
                                                    disabled:
                                                      !_vm.currentWidget
                                                        .comment_enabled ||
                                                      !_vm.has_right_to_update_widget,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.currentWidget
                                                        .comment_mandatory
                                                    )
                                                      ? _vm._i(
                                                          _vm.currentWidget
                                                            .comment_mandatory,
                                                          null
                                                        ) > -1
                                                      : _vm.currentWidget
                                                          .comment_mandatory,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.currentWidget
                                                            .comment_mandatory,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.currentWidget,
                                                              "comment_mandatory",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.currentWidget,
                                                              "comment_mandatory",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.currentWidget,
                                                          "comment_mandatory",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-5 col-10" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "mb-0" },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.widget.resaOptions.enableGiftVoucher"
                                                )
                                              ) +
                                              "\n                                        "
                                          ),
                                          _c("feather", {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.getTooltipNotice(
                                                  "notices.booking.widget.giftVoucherField"
                                                ),
                                                expression:
                                                  "getTooltipNotice('notices.booking.widget.giftVoucherField')",
                                              },
                                            ],
                                            staticClass:
                                              "ml-1 pointer tooltip-infos",
                                            attrs: { type: "info" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7 col-2" }, [
                                    _c(
                                      "label",
                                      { staticClass: "container-box" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.currentWidget.enable_gift,
                                              expression:
                                                "currentWidget.enable_gift",
                                            },
                                          ],
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_widget,
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.currentWidget.enable_gift
                                            )
                                              ? _vm._i(
                                                  _vm.currentWidget.enable_gift,
                                                  null
                                                ) > -1
                                              : _vm.currentWidget.enable_gift,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.currentWidget.enable_gift,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.currentWidget,
                                                      "enable_gift",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.currentWidget,
                                                      "enable_gift",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.currentWidget,
                                                  "enable_gift",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.currentWidget.enable_gift
                                  ? _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col-5 pt-2" }, [
                                        _c("label", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.widget.resaOptions.confirmResaGiftVoucher"
                                                )
                                              ) +
                                              "\n                                        "
                                          ),
                                          _c("small", [_vm._v("*")]),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-7 radio w-100 h-100 d-table pt-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-table-cell align-middle",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "container-box",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.giftVoucherConfirmMethod,
                                                        expression:
                                                          "giftVoucherConfirmMethod",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        !_vm.has_right_to_update_widget,
                                                      type: "radio",
                                                      value: "manual",
                                                    },
                                                    domProps: {
                                                      checked: _vm._q(
                                                        _vm.giftVoucherConfirmMethod,
                                                        "manual"
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.giftVoucherConfirmMethod =
                                                          "manual"
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", {
                                                    staticClass: "checkmark",
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$tl("labels.manual")
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "container-box",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.giftVoucherConfirmMethod,
                                                        expression:
                                                          "giftVoucherConfirmMethod",
                                                      },
                                                    ],
                                                    attrs: {
                                                      disabled:
                                                        !_vm.has_right_to_update_widget,
                                                      type: "radio",
                                                      value: "auto",
                                                    },
                                                    domProps: {
                                                      checked: _vm._q(
                                                        _vm.giftVoucherConfirmMethod,
                                                        "auto"
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.giftVoucherConfirmMethod =
                                                          "auto"
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", {
                                                    staticClass: "checkmark",
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$tl("labels.auto")
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.currentWidget.enable_gift
                                  ? _c("div", { staticClass: "row mb-2" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-5 col-10" },
                                        [
                                          _c("label", { staticClass: "mb-0" }, [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.booking.widget.resaOptions.disableFootprintIfGiftVoucher"
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-7 col-2" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "container-box" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.currentWidget
                                                        .gift_voucher_footprint,
                                                    expression:
                                                      "currentWidget.gift_voucher_footprint",
                                                  },
                                                ],
                                                attrs: {
                                                  disabled:
                                                    !_vm.has_right_to_update_widget,
                                                  type: "checkbox",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.currentWidget
                                                      .gift_voucher_footprint
                                                  )
                                                    ? _vm._i(
                                                        _vm.currentWidget
                                                          .gift_voucher_footprint,
                                                        null
                                                      ) > -1
                                                    : _vm.currentWidget
                                                        .gift_voucher_footprint,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.currentWidget
                                                          .gift_voucher_footprint,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.currentWidget,
                                                            "gift_voucher_footprint",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.currentWidget,
                                                            "gift_voucher_footprint",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.currentWidget,
                                                        "gift_voucher_footprint",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row m-0 mb-3" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("option-bank-settings", {
                                attrs: {
                                  "is-editable": _vm.has_right_to_update_widget,
                                  settings: _vm.currentWidget,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row m-0" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-light b-radius-20 p-4 mb-3",
                              },
                              [
                                _c("div", { staticClass: "row mb-3" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.widget.waitingList.title"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-5 col-10" },
                                    [
                                      _c(
                                        "label",
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.widget.waitingList.enable"
                                                )
                                              ) +
                                              "\n                                        "
                                          ),
                                          _c("feather", {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value:
                                                  _vm.getTooltipNoticeLexicon(
                                                    "notices.booking.widget.waitingList",
                                                    _vm.restaurant_id
                                                  ),
                                                expression:
                                                  "getTooltipNoticeLexicon('notices.booking.widget.waitingList', restaurant_id)",
                                              },
                                            ],
                                            staticClass:
                                              "ml-1 pointer tooltip-infos",
                                            attrs: { type: "info" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7 col-2" }, [
                                    _c(
                                      "label",
                                      { staticClass: "container-box" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.currentWidget
                                                  .enable_waitings,
                                              expression:
                                                "currentWidget.enable_waitings",
                                            },
                                          ],
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_widget,
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.currentWidget.enable_waitings
                                            )
                                              ? _vm._i(
                                                  _vm.currentWidget
                                                    .enable_waitings,
                                                  null
                                                ) > -1
                                              : _vm.currentWidget
                                                  .enable_waitings,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.currentWidget
                                                    .enable_waitings,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.currentWidget,
                                                      "enable_waitings",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.currentWidget,
                                                      "enable_waitings",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.currentWidget,
                                                  "enable_waitings",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.currentWidget.enable_waitings
                                  ? [
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-5 col-10" },
                                          [
                                            _c(
                                              "label",
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.booking.widget.waitingList.autoNotifTableAvailable"
                                                      )
                                                    ) +
                                                    "\n                                            "
                                                ),
                                                _c("feather", {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.getTooltipNoticeLexicon(
                                                          "notices.booking.widget.waitingListAutoNotif",
                                                          _vm.restaurant_id
                                                        ),
                                                      expression:
                                                        "getTooltipNoticeLexicon('notices.booking.widget.waitingListAutoNotif', restaurant_id)",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "ml-1 pointer tooltip-infos",
                                                  attrs: { type: "info" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-7 col-2" },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "container-box" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.currentWidget
                                                          .enable_mail_auto,
                                                      expression:
                                                        "currentWidget.enable_mail_auto",
                                                    },
                                                  ],
                                                  class: {
                                                    disabled:
                                                      !_vm.currentWidget
                                                        .enable_waitings,
                                                  },
                                                  attrs: {
                                                    type: "checkbox",
                                                    disabled:
                                                      !_vm.currentWidget
                                                        .enable_waitings ||
                                                      !_vm.has_right_to_update_widget,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.currentWidget
                                                        .enable_mail_auto
                                                    )
                                                      ? _vm._i(
                                                          _vm.currentWidget
                                                            .enable_mail_auto,
                                                          null
                                                        ) > -1
                                                      : _vm.currentWidget
                                                          .enable_mail_auto,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.currentWidget
                                                            .enable_mail_auto,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.currentWidget,
                                                              "enable_mail_auto",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.currentWidget,
                                                              "enable_mail_auto",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.currentWidget,
                                                          "enable_mail_auto",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm.currentWidget.enable_waitings &&
                                      _vm.currentWidget.enable_mail_auto
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-md-5 col-10",
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.$tl(
                                                                "labels.booking.widget.waitingList.priorityClientVIP"
                                                              )
                                                            ) +
                                                            "\n                                                "
                                                        ),
                                                        _c("feather", {
                                                          directives: [
                                                            {
                                                              name: "tooltip",
                                                              rawName:
                                                                "v-tooltip",
                                                              value:
                                                                _vm.getTooltipNotice(
                                                                  "notices.booking.widget.waitingListPriority"
                                                                ),
                                                              expression:
                                                                "getTooltipNotice('notices.booking.widget.waitingListPriority')",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "ml-1 pointer tooltip-infos",
                                                          attrs: {
                                                            type: "info",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-md-7 col-2",
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "container-box",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .currentWidget
                                                                  .enable_waiting_list_priority_for_vip,
                                                              expression:
                                                                "currentWidget.enable_waiting_list_priority_for_vip",
                                                            },
                                                          ],
                                                          class: {
                                                            disabled:
                                                              !_vm.has_right_to_update_widget,
                                                          },
                                                          attrs: {
                                                            type: "checkbox",
                                                            disabled:
                                                              !_vm.has_right_to_update_widget,
                                                          },
                                                          domProps: {
                                                            checked:
                                                              Array.isArray(
                                                                _vm
                                                                  .currentWidget
                                                                  .enable_waiting_list_priority_for_vip
                                                              )
                                                                ? _vm._i(
                                                                    _vm
                                                                      .currentWidget
                                                                      .enable_waiting_list_priority_for_vip,
                                                                    null
                                                                  ) > -1
                                                                : _vm
                                                                    .currentWidget
                                                                    .enable_waiting_list_priority_for_vip,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm
                                                                    .currentWidget
                                                                    .enable_waiting_list_priority_for_vip,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.currentWidget,
                                                                      "enable_waiting_list_priority_for_vip",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.currentWidget,
                                                                      "enable_waiting_list_priority_for_vip",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.currentWidget,
                                                                  "enable_waiting_list_priority_for_vip",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", {
                                                          staticClass:
                                                            "checkmark",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-md-5 col-10",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.booking.widget.waitingList.priorityClientLoyal"
                                                            )
                                                          ) +
                                                          "\n                                                "
                                                      ),
                                                      _c("feather", {
                                                        directives: [
                                                          {
                                                            name: "tooltip",
                                                            rawName:
                                                              "v-tooltip",
                                                            value:
                                                              _vm.getTooltipNotice(
                                                                "notices.booking.widget.waitingListPriority"
                                                              ),
                                                            expression:
                                                              "getTooltipNotice('notices.booking.widget.waitingListPriority')",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "ml-1 pointer tooltip-infos",
                                                        attrs: { type: "info" },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-md-7 col-2",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "container-box",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.enableWaitingListPriorityFromLoyaltyLevel,
                                                            expression:
                                                              "enableWaitingListPriorityFromLoyaltyLevel",
                                                          },
                                                        ],
                                                        class: {
                                                          disabled:
                                                            !_vm.currentWidget
                                                              .enable_waitings,
                                                        },
                                                        attrs: {
                                                          type: "checkbox",
                                                          disabled:
                                                            !_vm.currentWidget
                                                              .enable_waitings ||
                                                            !_vm.has_right_to_update_widget,
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.enableWaitingListPriorityFromLoyaltyLevel
                                                            )
                                                              ? _vm._i(
                                                                  _vm.enableWaitingListPriorityFromLoyaltyLevel,
                                                                  null
                                                                ) > -1
                                                              : _vm.enableWaitingListPriorityFromLoyaltyLevel,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.enableWaitingListPriorityFromLoyaltyLevel,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  (_vm.enableWaitingListPriorityFromLoyaltyLevel =
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ]))
                                                              } else {
                                                                $$i > -1 &&
                                                                  (_vm.enableWaitingListPriorityFromLoyaltyLevel =
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      ))
                                                              }
                                                            } else {
                                                              _vm.enableWaitingListPriorityFromLoyaltyLevel =
                                                                $$c
                                                            }
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", {
                                                        staticClass:
                                                          "checkmark",
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.enableWaitingListPriorityFromLoyaltyLevel
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-inline-block",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(
                                                                _vm.$tl(
                                                                  "labels.from"
                                                                )
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .currentWidget
                                                                      .enable_waiting_list_priority_from_loyalty_level,
                                                                  expression:
                                                                    "currentWidget.enable_waiting_list_priority_from_loyalty_level",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "d-inline-block custom-select ml-1 mb-0 w-auto",
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm.currentWidget,
                                                                      "enable_waiting_list_priority_from_loyalty_level",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.ALL_CLIENT_LOYALTIES,
                                                              function (level) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: level.value,
                                                                    domProps: {
                                                                      value:
                                                                        level.value,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                        " +
                                                                        _vm._s(
                                                                          _vm.getClientLoyaltyLevelLabel(
                                                                            level.value
                                                                          )
                                                                        ) +
                                                                        "\n                                                    "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("setting-part-form-display", {
                          attrs: {
                            "is-seating-plan-available": _vm.hasFeatSeatingPlan,
                            "has-right-to-update-widget":
                              _vm.has_right_to_update_widget,
                          },
                          model: {
                            value: _vm.currentWidget,
                            callback: function ($$v) {
                              _vm.currentWidget = $$v
                            },
                            expression: "currentWidget",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row m-0 mb-4" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("input", {
                              staticClass: "btn btn-success btn-circle",
                              attrs: {
                                disabled: !_vm.has_right_to_update_widget,
                                type: "submit",
                                name: "submit",
                              },
                              domProps: {
                                value: _vm.$t("labels.form.actions.save"),
                              },
                            }),
                          ]),
                        ]),
                      ],
                      1
                    ),
              ],
              1
            ),
      ]),
      _vm._v(" "),
      _vm.showImportRoomNumbersModal
        ? _c("ImportRoomNumbersModal", {
            on: {
              close: function ($event) {
                _vm.showImportRoomNumbersModal = false
              },
              "import-done": function ($event) {
                _vm.showImportRoomNumbersModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showShowRoomNumbersModal
        ? _c("ShowRoomNumbersModal", {
            on: {
              close: function ($event) {
                _vm.showShowRoomNumbersModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }