var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Liste des numéros de chambre"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.loading
          ? _c("LoaderComponent")
          : _vm.roomNumbers
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 d-flex" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "Filtrer", type: "text" },
                  domProps: { value: _vm.search },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.search = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "p-0 m-0 clear-search-button",
                    staticStyle: { background: "none", border: "none" },
                    attrs: { type: "button" },
                    on: { click: _vm.fetchRoomNumbers },
                  },
                  [_c("feather", { attrs: { type: "x" } })],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 mt-2" },
                _vm._l(_vm.roomNumbersFiltered, function (roomNumber) {
                  return _c(
                    "span",
                    { key: roomNumber.id, staticClass: "d-block" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(roomNumber.number) +
                          "\n                "
                      ),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            on: { click: _vm.close },
          },
          [_vm._v("Fermer")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }