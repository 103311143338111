<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="alert alert-danger" v-else-if="!website_enabled">Votre site web n'est pas activé</div>
        <template v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div
                        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">Configuration de la charte graphique</h5>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4 bg-grad text-center">
                        <div class="row">
                            <div class="col-12 mb-3 text-white">
                                <strong class="mb-3">Imformation importante</strong>
                            </div>
                            <div class="col-12 text-white">
                                <span class="text-warning"
                                    >Taille d'image maximale acceptée : 5000 px / 10 Mo.<br />
                                    <a
                                        class="text-white btn btn-sm btn-outline-secondary btn-circle mt-3"
                                        href="https://www.iloveimg.com/fr/compresser-image"
                                        target="_blank"
                                        ><feather type="image" class="text-warning mr-1" />Compresser une image</a
                                    ></span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <strong>Éléments graphiques</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5 pt-2">
                                <label>Police des titres <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-2 d-flex align-items-center">
                                <select
                                    :disabled="!has_right_to_update_config"
                                    class="custom-select m-0"
                                    v-model="website_settings.font_title"
                                    style="width: 50%">
                                    <option v-for="font in availableFontsTitle" :value="font.value" :key="font.name">
                                        {{ font.name }}
                                    </option>
                                </select>
                                <span
                                    class="ml-2 font-previsu"
                                    :style="'font-weight: 600; font-family: ' + website_settings.font_title"
                                    >Notre Histoire</span
                                >
                            </div>
                            <div class="col-md-5 pt-2">
                                <label>Police du corps de texte <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-2 d-flex align-items-center">
                                <select
                                    :disabled="!has_right_to_update_config"
                                    class="custom-select m-0"
                                    v-model="website_settings.font_body"
                                    style="width: 50%">
                                    <option v-for="font in availableFontsBody" :value="font.value" :key="font.name">
                                        {{ font.name }}
                                    </option>
                                </select>
                                <span class="ml-2 font-previsu" :style="'font-family: ' + website_settings.font_body"
                                    >Corps de texte</span
                                >
                            </div>
                            <div class="col-md-5 pt-2">
                                <label>Police de la barre de navigation <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-2 d-flex align-items-center">
                                <select
                                    :disabled="!has_right_to_update_config"
                                    class="custom-select m-0"
                                    v-model="website_settings.font_nav"
                                    style="width: 50%">
                                    <option v-for="font in availableFontsNav" :value="font.value" :key="font.name">
                                        {{ font.name }}
                                    </option>
                                </select>
                                <span class="ml-2 font-previsu" :style="'font-family: ' + website_settings.font_nav"
                                    >Gallerie</span
                                >
                            </div>
                            <div class="col-md-5 mt-2">
                                <label class="mb-0"
                                    >Logo de la barre de navigation <small class="text-danger">*</small></label
                                ><br />
                                <small class="text-muted">Noir ou couleur</small>
                            </div>
                            <div class="col-md-7 mb-1 mt-1">
                                <ImageComponent
                                    :disabled="!has_right_to_update_config"
                                    suffixId="logo_nav"
                                    :src="website_settings.logo_nav_img"
                                    :postURI="basePostURL + 'logo_nav'"
                                    :callBack="
                                        (response) => {
                                            website_settings.logo_nav_img = response.data.img;
                                        }
                                    "
                                    :maxSize="10" />
                            </div>
                            <div class="col-md-5 mt-2">
                                <label class="mb-0">Logo principal <small class="text-danger">*</small></label
                                ><br />
                                <small class="text-muted">De préférence blanc</small>
                            </div>
                            <div class="col-md-7 mb-1 mt-1">
                                <ImageComponent
                                    :disabled="!has_right_to_update_config"
                                    suffixId="logo_banner"
                                    :src="website_settings.logo_banner_img"
                                    :postURI="basePostURL + 'logo_banner'"
                                    :callBack="
                                        (response) => {
                                            website_settings.logo_banner_img = response.data.img;
                                        }
                                    "
                                    :maxSize="10" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0 mt-3">
                <div class="col-12 d-inline-block">
                    <button
                        type="button"
                        class="btn btn-success btn-circle mr-1"
                        @click="postForm()"
                        :disabled="!has_right_to_update_config">
                        Enregistrer
                    </button>
                    <small
                        >Pour rendre vos modifications visibles sur votre site internet, pensez à "<strong
                            >Enregistrer et publier</strong
                        >" en cliquant sur le bouton dans la navigation.</small
                    >
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent";
import ShowErrors from "../../../components/errors/ShowErrors";
import ImageComponent from "../../../components/forms/ImageComponent";

export default {
    data() {
        return {
            loading: 0,
            errors: null,
        };
    },
    props: {
        website_settings: {
            type: Object,
            required: true,
        },
        website_enabled: {
            required: true,
        },
    },
    computed: {
        basePostURL() {
            return `/api/website/${this.restaurant_id}/website/img/`;
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read_config() {
            return this.rights.includes("website.config.read");
        },
        has_right_to_update_config() {
            return this.rights.includes("website.config.update");
        },
        has_right_to_publish_config() {
            return this.rights.includes("website.config.publish");
        },
        availableFontsTitle() {
            return [
                {
                    name: "Montserrat Alternates",
                    value: "'Montserrat Alternates', sans-serif",
                },
                {
                    name: "Roboto",
                    value: "'Roboto', sans-serif",
                },
                {
                    name: "Open Sans",
                    value: "'Open Sans', sans-serif",
                },
                {
                    name: "Poppins",
                    value: "'Poppins', sans-serif",
                },
                {
                    name: "Oswald",
                    value: "'Oswald', sans-serif",
                },
                {
                    name: "Playfair Display",
                    value: "'Playfair Display', sans-serif",
                },
                {
                    name: "DM Serif Display",
                    value: "'DM Serif Display', sans-serif",
                },
                {
                    name: "Lato",
                    value: "'Lato', sans-serif",
                },
                {
                    name: "Raleway",
                    value: "'Raleway', sans-serif",
                },
            ];
        },
        availableFontsBody() {
            return [
                {
                    name: "Montserrat Alternates",
                    value: "'Montserrat Alternates', sans-serif",
                },
                {
                    name: "Roboto",
                    value: "'Roboto', sans-serif",
                },
                {
                    name: "Open Sans",
                    value: "'Open Sans', sans-serif",
                },
                {
                    name: "Poppins",
                    value: "'Poppins', sans-serif",
                },
                {
                    name: "Oswald",
                    value: "'Oswald', sans-serif",
                },
                {
                    name: "Playfair Display",
                    value: "'Playfair Display', sans-serif",
                },
                {
                    name: "DM Serif Display",
                    value: "'DM Serif Display', sans-serif",
                },
                {
                    name: "Lato",
                    value: "'Lato', sans-serif",
                },
                {
                    name: "Raleway",
                    value: "'Raleway', sans-serif",
                },
            ];
        },
        availableFontsNav() {
            return [
                {
                    name: "Montserrat Alternates",
                    value: "'Montserrat Alternates', sans-serif",
                },
                {
                    name: "Roboto",
                    value: "'Roboto', sans-serif",
                },
                {
                    name: "Open Sans",
                    value: "'Open Sans', sans-serif",
                },
                {
                    name: "Poppins",
                    value: "'Poppins', sans-serif",
                },
                {
                    name: "Oswald",
                    value: "'Oswald', sans-serif",
                },
                {
                    name: "Playfair Display",
                    value: "'Playfair Display', sans-serif",
                },
                {
                    name: "DM Serif Display",
                    value: "'DM Serif Display', sans-serif",
                },
                {
                    name: "Lato",
                    value: "'Lato', sans-serif",
                },
                {
                    name: "Raleway",
                    value: "'Raleway', sans-serif",
                },
            ];
        },
    },
    methods: {
        postForm(doNotFetch = false) {
            this.loading++;
            this.errors = null;

            return this.$store
                .dispatch("websites/updateWebsiteSettings", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        ...this.website_settings,
                        show_address: this.website_settings.show_address ? false : true,
                    },
                })
                .then((response) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    if (!doNotFetch) this.$emit("fetch-data");
                    return true;
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    return false;
                });
        },
    },
    components: {
        ShowErrors,
        LoaderComponent,
        ImageComponent,
    },
};
</script>
