<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else-if="campaign !== null">
            <div class="div-profil">
                <div class="row">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-md-5">Nom de la campagne</div>
                                <div class="col-md-7 value">{{ campaign.name }}</div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-md-5">Type de campagne</div>
                                <div class="col-md-7 value">
                                    {{ campaignType }}
                                </div>
                            </div>
                            <template v-if="campaign.type === 'sms'">
                                <div class="sepa"></div>
                                <div class="row">
                                    <div class="col-md-5">Statut</div>
                                    <div class="col-md-7 value">
                                        <span :class="campaign.paid_at !== null ? 'text-success' : 'text-warning'">{{
                                            campaign.paid_at !== null ? "Payée" : "En attente de paiement"
                                        }}</span>
                                    </div>
                                </div>
                                <template v-if="campaign.paid_at !== null">
                                    <div class="sepa"></div>
                                    <div class="row">
                                        <div class="col-md-5">Coût</div>
                                        <div class="col-md-7 value">
                                            {{ campaign.amount_paid ? formatCurrency(campaign.amount_paid / 100) : "Indisponible" }}
                                        </div>
                                    </div>
                                </template>
                                <div class="sepa"></div>
                                <div class="row">
                                    <div class="col-md-5">Expéditeur</div>
                                    <div class="col-md-7 value">{{ campaign.sms_expeditor }}</div>
                                </div>
                            </template>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-md-5">Inclure les clients VIP</div>
                                <div class="col-md-7 value">
                                    {{ campaign.include_vip ? "Oui" : "Non" }}
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-md-5">Inclure les clients à risque</div>
                                <div class="col-md-7 value">
                                    {{ campaign.include_risky ? "Oui" : "Non" }}
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-md-5">Fidélité client</div>
                                <div class="col-md-7 value">
                                    <span v-if="campaign.loyalty_levels.length === 0">--</span>
                                    <span v-else>
                                        {{
                                            campaign.loyalty_levels
                                                .map((loyaltyLevel) => (loyaltyLevel === null ? "Aucune" : getClientLoyaltyLevelLabel(loyaltyLevel)))
                                                .join(", ")
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-md-5">Typologie client</div>
                                <div class="col-md-7 value">
                                    <span v-if="campaign.client_types.length === 0">--</span>
                                    <span v-else>
                                        {{
                                            campaign.client_types
                                                .map((clientType) => (clientType === "no_status" ? "Aucune" : getClientTypeLabel(clientType)))
                                                .join(", ")
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-md-5">Filtres</div>
                                <div class="col-md-7 value" v-html="filters"></div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-md-5">Date et heure d'envoi</div>
                                <div class="col-md-7 value">{{ campaign.sent_at }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row" v-if="campaign.type === 'email'">
                                <div class="col-5">Objet</div>
                                <div class="col-7 value">{{ campaign.mail_object }}</div>
                            </div>
                            <div class="sepa" v-if="campaign.type === 'email'"></div>
                            <div class="row">
                                <div class="col-12">Message</div>
                                <div class="col-12 mt-2">
                                    <small class="value" v-html="campaign_content"></small>
                                </div>
                            </div>
                            <div class="sepa" v-if="campaign.img_path && campaign.type === 'email'"></div>
                            <div class="row" v-if="campaign.img_path && campaign.type === 'email'" style="max-height: 20vh">
                                <div class="col-5">Image</div>
                                <div class="col-7">
                                    <img
                                        style="max-width: 100%; max-height: 20vh"
                                        :src="campaign.img_path"
                                        alt="Image de campagne"
                                        tile="Image de la campagne" />
                                </div>
                            </div>
                            <div class="sepa" v-if="campaign.img_link && campaign.type === 'email'"></div>
                            <div class="row" v-if="campaign.img_link && campaign.type === 'email'">
                                <div class="col-5">Lien de l'image</div>
                                <div class="col-7">
                                    <a
                                        target="_blank"
                                        :href="campaign.img_link.startsWith('http') ? campaign.img_link : 'http://' + campaign.img_link"
                                        class="value"
                                        >{{ campaign.img_link }}</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3" v-if="campaign.sent_at !== null">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row m-0 border-bottom">
                                <table class="table table-striped table-sm">
                                    <thead class="border-bottom">
                                        <th>Clients</th>
                                        <th>Envoyés</th>
                                        <th>Ouvertures</th>
                                        <th>Clics</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Tous</td>
                                            <td>{{ campaign.nb_sent }}</td>
                                            <td>
                                                <span v-if="campaign.type === CAMPAIGN_TYPE_SMS.value">Indisponible</span>
                                                <span v-else>
                                                    {{ campaign.nb_opened }}
                                                    <span v-if="campaign.nb_opened > 0 && campaign.nb_sent > 0">
                                                        ({{ formatPercent((campaign.nb_opened / campaign.nb_sent) * 100) }}%)
                                                    </span>
                                                </span>
                                            </td>
                                            <td>
                                                <div v-if="campaign.type === CAMPAIGN_TYPE_SMS.value">Indisponible</div>
                                                <div v-else>
                                                    {{ campaign.nb_clicked }}
                                                    <span v-if="campaign.nb_clicked > 0 && campaign.nb_opened > 0">
                                                        ({{ formatPercent((campaign.nb_clicked / campaign.nb_opened) * 100) }}%)
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-for="{ key, label } in statsRows" :key="key">
                                            <td>{{ label }}</td>
                                            <td>{{ campaign.stats[key].sent }}</td>
                                            <td>
                                                <div v-if="campaign.type === CAMPAIGN_TYPE_SMS.value">Indisponible</div>
                                                <div v-else>
                                                    {{ campaign.stats[key].opened }}
                                                    <span v-if="campaign.stats[key].opened > 0 && campaign.stats[key].sent > 0">
                                                        ({{ formatPercent((campaign.stats[key].opened / campaign.stats[key].sent) * 100) }}%)
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="campaign.type === CAMPAIGN_TYPE_SMS.value">Indisponible</div>
                                                <div v-else>
                                                    {{ campaign.stats[key].opened }}
                                                    <span v-if="campaign.stats[key].clicked > 0 && campaign.stats[key].opened > 0">
                                                        ({{ formatPercent((campaign.stats[key].clicked / campaign.stats[key].opened) * 100) }}%)
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import CampaignTypesEnum from "../../mixins/enums/campaigns/CampaignTypesEnum.js";
import ClientLoyaltyLevelEnum from "../../mixins/enums/ClientLoyaltyLevelEnum.js";
import ClientTypeEnum from "../../mixins/enums/ClientTypeEnum.js";

export default {
    name: "ShowCampaign",
    data() {
        return {
            loading: false,
            campaign: null,
        };
    },
    mixins: [CampaignTypesEnum, ClientLoyaltyLevelEnum, ClientTypeEnum],
    components: {
        LoaderComponent,
    },
    props: {
        campaign_id: {
            required: true,
        },
        clients: {
            type: Object,
            required: true,
        },
    },
    computed: {
        statsRows() {
            if (this.campaign === null) {
                return [];
            }

            const rows = [];

            if (typeof this.campaign.stats.vip !== "undefined") {
                rows.push({ key: "vip", label: "Clients VIP" });
            }

            if (typeof this.campaign.stats.loyal !== "undefined") {
                rows.push({ key: "loyal", label: "Clients fidèles" });
            }

            if (typeof this.campaign.stats.typologies !== "undefined") {
                rows.push({ key: "typologies", label: "Clients avec typologie" });
            }

            if (typeof this.campaign.stats.risky !== "undefined") {
                rows.push({ key: "risky", label: "Clients à risque" });
            }

            return rows;
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        campaignType() {
            return this.campaign === null ? this.getCampaignTypeLabel(this.campaign.type) : "";
        },
        campaign_content() {
            return this.escapeHtml(this.campaign.content)
                .replace(/\n{2,}/g, "<br><br>")
                .replace(/\n/g, "<br>");
        },
        filters() {
            var filters = [];

            if (this.campaign.at_least_one_past_resa) {
                filters.push("Au moins une réservation");
            }

            if (this.campaign.at_least_one_future_resa) {
                filters.push("Au moins une réservation future");
            }

            if (this.campaign.type === this.CAMPAIGN_TYPE_SMS.value) {
                filters.push(this.campaign.send_to_foreign_numbers ? "Numéros français et étrangers" : "Numéro français uniquement");
            }

            return filters.length > 1 ? filters.join("<br />") : "Aucun";
        },
    },
    methods: {
        formatPercent(percent) {
            if ((percent * 100) % 100 === 0) {
                return percent;
            }

            if ((percent * 100) % 10 === 0) {
                return percent.toFixed(1);
            }

            return percent.toFixed(2);
        },
        fetchData() {
            this.loading = true;
            this.campaign = null;

            this.httpGet(`/api/campaigns/restaurants/${this.restaurantId}/campaigns/${this.campaign_id}`)
                .then((response) => {
                    if (response !== false) {
                        this.campaign = response.data;
                    }
                })
                .finally(() => (this.loading = false));
        },
    },
    created() {
        this.fetchData();
    },
};
</script>
