var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mb-3" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _vm.nbLoading > 0
          ? _c("loader-component")
          : _vm.stats.main
          ? _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
              _c("div", { staticClass: "row m-0" }, [
                _c("h6", { staticClass: "mt-3 col-10" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.globalStats.waitingsList.title",
                          _vm.firstSelectedRestaurantId
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive" }, [
                _c(
                  "table",
                  { staticClass: "table table-striped overflowx-auto" },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", {
                          staticClass: "group-header",
                          class: { "w-25": _vm.filters.hasComparison },
                          attrs: { scope: "col", colspan: "1" },
                        }),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            attrs: {
                              scope: "col",
                              colspan: _vm.filters.hasComparison ? 5 : 1,
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.stats.waitingList.nb",
                                    _vm.firstSelectedRestaurantId
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            attrs: {
                              scope: "col",
                              colspan: _vm.filters.hasComparison ? 5 : 1,
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.stats.waitingList.nbLeadToResa",
                                    _vm.firstSelectedRestaurantId
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            attrs: {
                              scope: "col",
                              colspan: _vm.filters.hasComparison ? 5 : 1,
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.globalStats.table.cells.percentBooking",
                                    _vm.firstSelectedRestaurantId
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.filters.hasComparison
                        ? _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.globalStats.table.cells.nameRestaurant",
                                    _vm.firstSelectedRestaurantId
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.globalStats.table.cells.periodOne",
                                    _vm.firstSelectedRestaurantId
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.globalStats.table.cells.periodTwo",
                                    _vm.firstSelectedRestaurantId
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.globalStats.table.cells.periodOneOnTwo",
                                    _vm.firstSelectedRestaurantId
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.globalStats.table.cells.varNb",
                                    _vm.firstSelectedRestaurantId
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.globalStats.table.cells.varPercent",
                                    _vm.firstSelectedRestaurantId
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.globalStats.table.cells.periodOne",
                                    _vm.firstSelectedRestaurantId
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.globalStats.table.cells.periodTwo",
                                    _vm.firstSelectedRestaurantId
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.globalStats.table.cells.periodOneOnTwo",
                                    _vm.firstSelectedRestaurantId
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.globalStats.table.cells.varNb",
                                    _vm.firstSelectedRestaurantId
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.globalStats.table.cells.varPercent",
                                    _vm.firstSelectedRestaurantId
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.globalStats.table.cells.periodOne",
                                    _vm.firstSelectedRestaurantId
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.globalStats.table.cells.periodTwo",
                                    _vm.firstSelectedRestaurantId
                                  )
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      [
                        !_vm.filters.hasComparison &&
                        !_vm.filters.hasGroupComparison
                          ? _vm._l(
                              _vm.filters.main.selectedRestaurants,
                              function (restaurant) {
                                return _c("tr", { key: restaurant.id }, [
                                  _c("td", [_vm._v(_vm._s(restaurant.name))]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getMainStats(restaurant.id)
                                          .count_waitings
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getMainStats(restaurant.id)
                                          .count_waitings_accepted
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.getPercent(
                                            _vm.getMainStats(restaurant.id)
                                              .count_waitings_accepted,
                                            _vm.getMainStats(restaurant.id)
                                              .count_waitings
                                          )
                                        ) +
                                        "%\n                                "
                                    ),
                                  ]),
                                ])
                              }
                            )
                          : _vm.filters.hasGroupComparison
                          ? _vm._l(
                              _vm.mergedSelectedRestaurants,
                              function (restaurant) {
                                return _c("tr", { key: restaurant.id }, [
                                  _c("td", [_vm._v(_vm._s(restaurant.name))]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(restaurant.stats.count_waitings)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        restaurant.stats.count_waitings_accepted
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.getPercent(
                                            _vm.getMainStats(restaurant.id)
                                              .count_waitings,
                                            _vm.getMainStats(restaurant.id)
                                              .count_waitings_accepted
                                          )
                                        ) +
                                        "%\n                                "
                                    ),
                                  ]),
                                ])
                              }
                            )
                          : _vm.filters.hasComparison
                          ? _vm._l(
                              _vm.filters.main.selectedRestaurants,
                              function (restaurant) {
                                return _c("tr", { key: restaurant.id }, [
                                  _c("td", [_vm._v(_vm._s(restaurant.name))]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getMainStats(restaurant.id)
                                          .count_waitings
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getComparisonStats(restaurant.id)
                                          .count_waitings
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: _vm.getEvolutionClass(
                                        _vm.getEvolution(
                                          _vm.getMainStats(restaurant.id)
                                            .count_waitings,
                                          _vm.getComparisonStats(restaurant.id)
                                            .count_waitings
                                        )
                                      ),
                                    },
                                    [
                                      _c("feather", {
                                        attrs: {
                                          type: _vm.getFeatherArrowIcon(
                                            _vm.getEvolution(
                                              _vm.getMainStats(restaurant.id)
                                                .count_waitings,
                                              _vm.getComparisonStats(
                                                restaurant.id
                                              ).count_waitings
                                            )
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: _vm.getEvolutionClass(
                                        _vm.getEvolution(
                                          _vm.getMainStats(restaurant.id)
                                            .count_waitings,
                                          _vm.getComparisonStats(restaurant.id)
                                            .count_waitings
                                        )
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.getDifference(
                                              _vm.getMainStats(restaurant.id)
                                                .count_waitings,
                                              _vm.getComparisonStats(
                                                restaurant.id
                                              ).count_waitings
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: _vm.getEvolutionClass(
                                        _vm.getEvolution(
                                          _vm.getMainStats(restaurant.id)
                                            .count_waitings,
                                          _vm.getComparisonStats(restaurant.id)
                                            .count_waitings
                                        )
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.formatPercent(
                                              _vm.getEvolution(
                                                _vm.getMainStats(restaurant.id)
                                                  .count_waitings,
                                                _vm.getComparisonStats(
                                                  restaurant.id
                                                ).count_waitings
                                              )
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getMainStats(restaurant.id)
                                          .count_waitings_accepted
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getComparisonStats(restaurant.id)
                                          .count_waitings_accepted
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: _vm.getEvolutionClass(
                                        _vm.getEvolution(
                                          _vm.getMainStats(restaurant.id)
                                            .count_waitings_accepted,
                                          _vm.getComparisonStats(restaurant.id)
                                            .count_waitings_accepted
                                        )
                                      ),
                                    },
                                    [
                                      _c("feather", {
                                        attrs: {
                                          type: _vm.getFeatherArrowIcon(
                                            _vm.getEvolution(
                                              _vm.getMainStats(restaurant.id)
                                                .count_waitings_accepted,
                                              _vm.getComparisonStats(
                                                restaurant.id
                                              ).count_waitings_accepted
                                            )
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: _vm.getEvolutionClass(
                                        _vm.getEvolution(
                                          _vm.getMainStats(restaurant.id)
                                            .count_waitings_accepted,
                                          _vm.getComparisonStats(restaurant.id)
                                            .count_waitings_accepted
                                        )
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.getDifference(
                                              _vm.getMainStats(restaurant.id)
                                                .count_waitings_accepted,
                                              _vm.getComparisonStats(
                                                restaurant.id
                                              ).count_waitings_accepted
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: _vm.getEvolutionClass(
                                        _vm.getEvolution(
                                          _vm.getMainStats(restaurant.id)
                                            .count_waitings_accepted,
                                          _vm.getComparisonStats(restaurant.id)
                                            .count_waitings_accepted
                                        )
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.formatPercent(
                                              _vm.getEvolution(
                                                _vm.getMainStats(restaurant.id)
                                                  .count_waitings_accepted,
                                                _vm.getComparisonStats(
                                                  restaurant.id
                                                ).count_waitings_accepted
                                              )
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.getPercent(
                                            _vm.getMainStats(restaurant.id)
                                              .count_waitings_accepted,
                                            _vm.getMainStats(restaurant.id)
                                              .count_waitings
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.getPercent(
                                            _vm.getComparisonStats(
                                              restaurant.id
                                            ).count_waitings_accepted,
                                            _vm.getComparisonStats(
                                              restaurant.id
                                            ).count_waitings
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                ])
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }