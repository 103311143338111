import axios from "axios";
import { Settings } from "luxon";

export default {
    data() {
        return {
            LANG_FR: {
                label: "Français",
                value: "fr",
                valueSyncFusion: "fr-FR",
                valueISO: "fr_FR",
                countryFlag: "fr",
                dropdownLabel: "Français",
            },
            LANG_EN: {
                label: "English",
                value: "en",
                valueSyncFusion: "en-US",
                valueISO: "en_US",
                countryFlag: "gb",
                dropdownLabel: "English",
            },
        };
    },
    methods: {
        getModuleTitle(module) {
            if (this.isUserLangFr) return module.title;
            return module[`title_${this.userLang}`];
        },
        getLangLabel(value) {
            const found = this.ALL_LANGS.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
        isLangValid(value) {
            return this.ALL_LANGS.some((l) => l.value === value);
        },
        setUserGlobalLang(newLang) {
            // Use to overwrite user default language
            if (this.$route.query.lang) {
                newLang = this.$route.query.lang;
            }
            if ("undefined" === typeof newLang) {
                newLang = this.$store.getters["users/lang"] || this.LANG_FR.value;
            }
            axios.interceptors.request.use((config) => {
                config.params = config.params || {};
                config.params["lang"] = newLang;
                return config;
            });
            Settings.defaultLocale = newLang;
            this.$store.dispatch("users/setLang", newLang);
            this.$i18n.locale = newLang;
        },
        getServiceCategoryLabel(service) {
            const defaultServicesCategories = ["brunch", "midi", "soir"];
            if (defaultServicesCategories.includes(service.category)) {
                return this.$t(`labels.booking.services.categories.${service.category}`);
            }
            return this.isUserLangFr ? service.category : service.category_en;
        },
        addLangToURL(url) {
            if (url.indexOf("?") === -1) return `${url}?lang=${this.userLang}`;
            return `${url}&lang=${this.userLang}`;
        },
    },
    computed: {
        ALL_LANGS() {
            return [this.LANG_FR, this.LANG_EN];
        },
        userLang() {
            return this.$store.getters["users/lang"];
        },
        userLangSyncFusion() {
            const found = this.ALL_LANGS.find((w) => w.value === this.userLang);

            return typeof found !== "undefined" ? found.valueSyncFusion : this.userLang;
        },
        userLangISO() {
            const found = this.ALL_LANGS.find((w) => w.value === this.userLang);

            return typeof found !== "undefined" ? found.valueISO : this.userLang;
        },
        isUserLangFr() {
            return this.userLang === this.LANG_FR.value;
        },
        isUserLangEn() {
            return this.userLang === this.LANG_EN.value;
        },
    },
};
