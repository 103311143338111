var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "mt-3 ml-3 d-flex justify-content-between" }, [
      _c("h5", [
        _vm._v(_vm._s(_vm.$tl("labels.routes.booking.config.seatingPlan"))),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-circle btn-success mr-3",
          attrs: { disabled: !_vm.has_right_to_save_seatingplan },
          on: { click: _vm.addSeatingPlan },
        },
        [
          _c("feather", { attrs: { type: "plus" } }),
          _vm._v(
            " " +
              _vm._s(_vm.$tl("labels.booking.seatingPlan.add")) +
              "\n        "
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "p-3" },
      [
        _vm.loading
          ? _c("LoaderComponent")
          : _c(
              "table",
              {
                staticClass:
                  "table table-sm table-striped border-bottom table-tr-clickable",
              },
              [
                _c("thead", { staticClass: "border-bottom" }, [
                  _c("tr", [
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$tl("labels.booking.seatingPlan.columns.name")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$tl(
                            "labels.booking.seatingPlan.columns.services",
                            _vm.restaurant_id
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$tl(
                            "labels.booking.seatingPlan.columns.createDate"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$tl(
                            "labels.booking.seatingPlan.columns.updateDate"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$tl("labels.booking.seatingPlan.columns.actions")
                        )
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.seatingPlans, function (seatingPlan) {
                    return _c("tr", { key: seatingPlan.id }, [
                      _c("td", [_vm._v(_vm._s(seatingPlan.name))]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          seatingPlan.services.data.length === 0
                            ? _c("span", [_vm._v("--")])
                            : _vm._l(
                                seatingPlan.services.data,
                                function (service) {
                                  return _c(
                                    "span",
                                    { key: service.id, staticClass: "d-block" },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(service.name) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                }
                              ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.displayDate(
                              seatingPlan.created_at,
                              _vm.DATE_SHORT
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.displayDate(
                              seatingPlan.updated_at,
                              _vm.DATE_SHORT
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: _vm.$t(
                                    "labels.booking.seatingPlan.edit"
                                  ),
                                },
                                expression:
                                  "{ content: $t('labels.booking.seatingPlan.edit') }",
                              },
                            ],
                            staticClass: "btn btn-sm btn-success btn-square",
                            on: {
                              click: function ($event) {
                                return _vm.editSeatingPlan(seatingPlan)
                              },
                            },
                          },
                          [_c("feather", { attrs: { type: "edit" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: _vm.$t(
                                    "labels.booking.seatingPlan.duplicate"
                                  ),
                                },
                                expression:
                                  "{ content: $t('labels.booking.seatingPlan.duplicate') }",
                              },
                            ],
                            staticClass: "btn btn-sm btn-success btn-square",
                            attrs: {
                              disabled: !_vm.has_right_to_save_seatingplan,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.duplicateSeatingPlan(seatingPlan)
                              },
                            },
                          },
                          [_c("feather", { attrs: { type: "copy" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content:
                                    seatingPlan.services.data.length > 0
                                      ? _vm.$tl(
                                          "infos.booking.seatingPlan.delete",
                                          _vm.restaurant_id
                                        )
                                      : _vm.$t(
                                          "labels.booking.seatingPlan.delete"
                                        ),
                                },
                                expression:
                                  "{\n                                content:\n                                    seatingPlan.services.data.length > 0\n                                        ? $tl('infos.booking.seatingPlan.delete', restaurant_id)\n                                        : $t('labels.booking.seatingPlan.delete'),\n                            }",
                              },
                            ],
                            staticClass: "btn btn-sm btn-danger btn-square",
                            attrs: {
                              disabled:
                                !_vm.has_right_to_delete_seatingplan ||
                                seatingPlan.services.data.length > 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteSeatingPlan(seatingPlan)
                              },
                            },
                          },
                          [_c("feather", { attrs: { type: "trash-2" } })],
                          1
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }