<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="alert alert-danger" v-else-if="!website_enabled">Votre site web n'est pas activé</div>
        <template v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">Configuration du contenu</h5>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <strong>Éléments graphiques</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5 mt-2">
                                <label>Image de fond de la page d'accueil <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-1 mt-1">
                                <ImageComponent
                                    :disabled="!has_right_to_update_customization"
                                    suffixId="home1"
                                    :src="website_settings.home1_img"
                                    :postURI="basePostURL + 'home1'"
                                    allowedExtensions=".jpg,.jpeg,.JPG"
                                    :maxSize="10"
                                    :callBack="
                                        (response) => {
                                            website_settings.home1_img = response.data.img;
                                        }
                                    " />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-3">
                            <div class="col-md-5">
                                <label for="chk-history-enabled"><strong>Section "Notre Histoire"</strong></label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        :disabled="!has_right_to_update_customization"
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="website_settings.history_enabled" />
                                    <label
                                        v-tooltip="{
                                            delay: { show: 400, hide: 0 },
                                            content: 'Activer / désactiver cette section',
                                        }"
                                        @click="
                                            website_settings.history_enabled = has_right_to_update_customization
                                                ? !website_settings.history_enabled
                                                : website_settings.history_enabled
                                        "></label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="website_settings.history_enabled == 1">
                            <div class="col-md-5">
                                <label for="txt-history-title">Titre de la section <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-1">
                                <input
                                    :disabled="!has_right_to_update_customization"
                                    type="text"
                                    class="form-control"
                                    v-model="website_settings.history_title"
                                    id="txt-history-title" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="history_title" />
                            </div>
                            <div class="col-md-5 mt-2">
                                <label>Image <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-1 mt-1">
                                <ImageComponent
                                    :disabled="!has_right_to_update_customization"
                                    :src="website_settings.history_img"
                                    suffixId="history"
                                    :postURI="basePostURL + 'history'"
                                    allowedExtensions=".jpg,.jpeg,.JPG"
                                    :maxSize="10"
                                    :callBack="
                                        (response) => {
                                            website_settings.history_img = response.data.img;
                                        }
                                    " />
                            </div>
                            <div class="col-md-5">
                                <label for="txt-history-content">Contenu texte <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-2">
                                <TextAreaComponentOld
                                    :disabled="!has_right_to_update_customization"
                                    :minNbChar="500"
                                    :maxNbChar="800"
                                    txtAreaId="txt-history-content"
                                    :txtAreaObj="website_settings"
                                    txtAreaObjKey="history_content"
                                    txtAreaRows="6" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="history_content" />
                            </div>
                            <div class="col-md-5 mt-2">
                                <label>Image d'illustration en plein écran <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mt-1">
                                <ImageComponent
                                    :disabled="!has_right_to_update_customization"
                                    :src="website_settings.home2_img"
                                    suffixId="home2"
                                    :postURI="basePostURL + 'home2'"
                                    allowedExtensions=".jpg,.jpeg,.JPG"
                                    :maxSize="10"
                                    :callBack="
                                        (response) => {
                                            website_settings.home2_img = response.data.img;
                                        }
                                    " />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-3">
                            <div class="col-md-5">
                                <label for="chk-menu-enabled"><strong>Section "Notre Offre"</strong></label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        :disabled="!has_right_to_update_customization"
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="website_settings.menu_enabled" />
                                    <label
                                        v-tooltip="{
                                            delay: { show: 400, hide: 0 },
                                            content: 'Activer / désactiver cette section',
                                        }"
                                        @click="
                                            website_settings.menu_enabled = has_right_to_update_customization
                                                ? !website_settings.menu_enabled
                                                : website_settings.menu_enabled
                                        "></label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="website_settings.menu_enabled == 1">
                            <div class="col-md-5 pt-2">
                                <label for="txt-menu-title">Titre de la section <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-3">
                                <input
                                    :disabled="!has_right_to_update_customization"
                                    type="text"
                                    class="form-control"
                                    v-model="website_settings.menu_title"
                                    id="txt-menu-title" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="menu_title" />
                            </div>
                            <div class="col-md-5">
                                <label>Nombre de d'offres à afficher</label>
                            </div>
                            <div class="col-md-7 rd-menus-nb-container mb-2 radio">
                                <label class="container-box d-inline-block" style="width: initial">
                                    <input
                                        type="radio"
                                        name="rd-menus-nb"
                                        v-model="website_settings.menus_nb"
                                        :disabled="!has_right_to_update_customization"
                                        :value="3" />
                                    <span class="checkmark" :class="{ disabled: !has_right_to_update_customization }"></span>3
                                </label>
                                <label class="container-box d-inline-block" style="width: initial">
                                    <input
                                        type="radio"
                                        name="rd-menus-n b"
                                        v-model="website_settings.menus_nb"
                                        :disabled="!has_right_to_update_customization"
                                        :value="6" />
                                    <span class="checkmark" :class="{ disabled: !has_right_to_update_customization }"></span>6
                                </label>
                            </div>
                            <div class="col-md-12 mb-4">
                                <div class="border-light b-radius-20 p-4">
                                    <div class="row">
                                        <WebsiteMenuComponent
                                            :restaurant-id="restaurant_id"
                                            :disabled="!has_right_to_update_customization"
                                            v-for="menu in website_settings.menus"
                                            :key="menu.number"
                                            :menu="menu"
                                            :menus_nb="website_settings.menus_nb"
                                            :errors="errors" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <label>Offre PDF n°1</label>
                            </div>
                            <div class="col-md-7 mb-2">
                                <FileUploaderComponent
                                    :disabled="!has_right_to_update_customization"
                                    :src="website_settings.menu_pdf1"
                                    suffixId="fullmenu1"
                                    :postURI="baseURIPdf(1)"
                                    :deleteURI="baseURIPdf(1)"
                                    :callBackPost="
                                        (response) => {
                                            website_settings.menu_pdf1 = response.data.file_name;
                                        }
                                    "
                                    :callBackDelete="
                                        () => {
                                            website_settings.menu_pdf1 = '';
                                        }
                                    " />
                            </div>
                            <div class="col-md-5" v-show="website_settings.menu_pdf1 != ''">
                                <label for="txt-menupdf1-title">Texte sur le bouton <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-1" v-show="website_settings.menu_pdf1 != ''">
                                <input
                                    :disabled="!has_right_to_update_customization"
                                    type="text"
                                    class="form-control"
                                    id="txt-menupdf1-title"
                                    v-model="website_settings.menu_pdf1_title" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="menu_pdf1_title" />
                            </div>
                            <div class="col-md-5 mt-2">
                                <label>Offre PDF n°2</label>
                            </div>
                            <div class="col-md-7 mt-2 mb-2">
                                <FileUploaderComponent
                                    :disabled="!has_right_to_update_customization"
                                    :src="website_settings.menu_pdf2"
                                    suffixId="fullmenu2"
                                    :postURI="baseURIPdf(2)"
                                    :deleteURI="baseURIPdf(2)"
                                    :callBackPost="
                                        (response) => {
                                            website_settings.menu_pdf2 = response.data.file_name;
                                        }
                                    "
                                    :callBackDelete="
                                        () => {
                                            website_settings.menu_pdf2 = '';
                                        }
                                    " />
                            </div>
                            <div class="col-md-5" v-show="website_settings.menu_pdf2 != ''">
                                <label for="txt-menupdf2-title">Texte sur le bouton <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-1" v-show="website_settings.menu_pdf2 != ''">
                                <input
                                    :disabled="!has_right_to_update_customization"
                                    type="text"
                                    class="form-control"
                                    id="txt-menupdf2-title"
                                    v-model="website_settings.menu_pdf2_title" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="menu_pdf2_title" />
                            </div>
                            <div class="col-md-5 mt-2">
                                <label>Offre PDF n°3</label>
                            </div>
                            <div class="col-md-7 mt-2">
                                <FileUploaderComponent
                                    :disabled="!has_right_to_update_customization"
                                    :src="website_settings.menu_pdf3"
                                    suffixId="fullmenu3"
                                    :postURI="baseURIPdf(3)"
                                    :deleteURI="baseURIPdf(3)"
                                    :callBackPost="
                                        (response) => {
                                            website_settings.menu_pdf3 = response.data.file_name;
                                        }
                                    "
                                    :callBackDelete="
                                        () => {
                                            website_settings.menu_pdf3 = '';
                                        }
                                    " />
                            </div>
                            <div class="col-md-5" v-show="website_settings.menu_pdf3 != ''">
                                <label for="txt-menupdf3-title">Texte sur le bouton <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-1" v-show="website_settings.menu_pdf3 != ''">
                                <input
                                    :disabled="!has_right_to_update_customization"
                                    type="text"
                                    class="form-control"
                                    id="txt-menupdf3-title"
                                    v-model="website_settings.menu_pdf3_title" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="menu_pdf3_title" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-3">
                            <div class="col-md-5">
                                <label for="chk-gallery-enabled"><strong>Section "Galerie"</strong></label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        :disabled="!has_right_to_update_customization"
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="website_settings.gallery_enabled" />
                                    <label
                                        v-tooltip="{
                                            delay: { show: 400, hide: 0 },
                                            content: 'Activer / désactiver cette section',
                                        }"
                                        @click="
                                            website_settings.gallery_enabled = has_right_to_update_customization
                                                ? !website_settings.gallery_enabled
                                                : website_settings.gallery_enabled
                                        "></label>
                                </div>
                            </div>
                        </div>
                        <div v-show="website_settings.gallery_enabled">
                            <GalleryFormComponent
                                :disabled="!has_right_to_update_customization"
                                class="mb-1"
                                v-for="currentNb in [1, 2, 3, 4, 5]"
                                :key="currentNb"
                                :basePostURL="basePostURL"
                                :src="website_settings[`gallery${currentNb}_img`]"
                                :nb="currentNb" />
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-3">
                            <div class="col-md-5">
                                <label for="chk-contact-enabled"><strong>Section "Contact"</strong></label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        :disabled="!has_right_to_update_customization"
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="website_settings.contact_enabled" />
                                    <label
                                        v-tooltip="{
                                            delay: { show: 400, hide: 0 },
                                            content: 'Activer / désactiver cette section',
                                        }"
                                        @click="
                                            website_settings.contact_enabled = has_right_to_update_customization
                                                ? !website_settings.contact_enabled
                                                : website_settings.contact_enabled
                                        "></label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="website_settings.contact_enabled == 1">
                            <div class="col-md-5">
                                <label for="txt-contact-title">Titre de la section <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-1">
                                <input
                                    :disabled="!has_right_to_update_customization"
                                    type="text"
                                    class="form-control"
                                    v-model="website_settings.contact_title"
                                    id="txt-contact-title" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="contact_title" />
                            </div>
                            <div class="col-md-5 mt-2">
                                <label>Image <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7 mb-1 mt-1">
                                <ImageComponent
                                    :disabled="!has_right_to_update_customization"
                                    :src="website_settings.contact_img"
                                    suffixId="contact"
                                    :postURI="basePostURL + 'contact'"
                                    allowedExtensions=".jpg,.jpeg,.JPG"
                                    :maxSize="10"
                                    :callBack="
                                        (response) => {
                                            website_settings.contact_img = response.data.img;
                                        }
                                    " />
                            </div>
                            <div class="col-md-5">
                                <label for="txt-contact-content">Contenu texte <small class="text-danger">*</small></label>
                            </div>
                            <div class="col-md-7">
                                <TextAreaComponentOld
                                    :disabled="!has_right_to_update_customization"
                                    :minNbChar="250"
                                    :maxNbChar="500"
                                    txtAreaId="txt-contact-content"
                                    :txtAreaObj="website_settings"
                                    txtAreaObjKey="contact_content"
                                    txtAreaRows="6" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="contact_content" />
                            </div>
                            <div class="col-md-5 mt-2">Masquer l'adresse de l'établissement</div>
                            <div class="col-md-7 mt-2">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        :disabled="!has_right_to_update_customization"
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="website_settings.show_address" />
                                    <label
                                        v-tooltip="{
                                            delay: { show: 400, hide: 0 },
                                            content: 'Afficher / masquer l\'addresse',
                                        }"
                                        @click="
                                            $set(
                                                website_settings,
                                                'show_address',
                                                has_right_to_update_customization ? !website_settings.show_address : website_settings.show_address
                                            )
                                        "></label>
                                </div>
                            </div>
                            <div class="col-md-5 mt-2">Masquer l'adresse email de l'établissement</div>
                            <div class="col-md-7 mt-2">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        :disabled="!has_right_to_update_customization"
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="website_settings.hide_email" />
                                    <label
                                        v-tooltip="{
                                            delay: { show: 400, hide: 0 },
                                            content: 'Afficher / masquer l\'email',
                                        }"
                                        @click="
                                            $set(
                                                website_settings,
                                                'hide_email',
                                                has_right_to_update_customization ? !website_settings.hide_email : website_settings.hide_email
                                            )
                                        "></label>
                                </div>
                            </div>
                            <div class="col-md-5 mt-2">Masquer le numéro de téléphone de l'établissement</div>
                            <div class="col-md-7 mt-2">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        :disabled="!has_right_to_update_customization"
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="website_settings.hide_tel" />
                                    <label
                                        v-tooltip="{
                                            delay: { show: 400, hide: 0 },
                                            content: 'Afficher / masquer le téléphone',
                                        }"
                                        @click="
                                            $set(
                                                website_settings,
                                                'hide_tel',
                                                has_right_to_update_customization ? !website_settings.hide_tel : website_settings.hide_tel
                                            )
                                        "></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div
                        class="border-light b-radius-20 p-4 feed-instagram disabled"
                        v-tooltip="getTooltip('Fonctionnalité actuellement bloquée par Instagram. En attente d\'une solution alternative.')">
                        <div class="row mb-3">
                            <div class="col-md-5">
                                <label for="chk-instagram-gallery-enabled"><strong>Section "Feed Instagram"</strong></label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        :disabled="true"
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        v-model="website_settings.instagram_enabled" />
                                    <label></label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="website_settings.instagram_enabled">
                            <div class="col-md-5">
                                <label for="txt-instagram-username" class="mb-0">
                                    Identifiant du compte Instagram <small class="text-danger">*</small>
                                </label>
                                <small id="help-instagram-username" class="form-text text-warning">
                                    Afin de pouvoir utiliser cette fonctionnalité, votre compte Instagram doit être paramétré en public.
                                </small>
                            </div>
                            <div class="col-md-7">
                                <input
                                    :disabled="true"
                                    class="form-control"
                                    type="text"
                                    v-model="website_settings.instagram_username"
                                    id="txt-instagram-username"
                                    aria-describedby="help-instagram-username" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="instagram_username" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0 mt-3">
                <div class="col-12 d-inline-block">
                    <button type="button" class="btn btn-success btn-circle mr-1" @click="postForm()" :disabled="!has_right_to_update_customization">
                        Enregistrer
                    </button>
                    <small>
                        Pour rendre vos modifications visibles sur votre site internet, pensez à "<strong>Enregistrer et publier</strong>" en cliquant
                        sur le bouton dans la navigation.
                    </small>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent";
import ShowErrors from "../../../components/errors/ShowErrors";
import WebsiteMenuComponent from "../../../components/forms/WebsiteMenuComponent";
import GalleryFormComponent from "../../../components/forms/GalleryFormComponent";
import TextAreaComponentOld from "../../../components/forms/TextAreaComponentOld";
import FileUploaderComponent from "../../../components/forms/FileUploaderComponent";
import ImageComponent from "../../../components/forms/ImageComponent";

export default {
    data() {
        return {
            loading: 0,
            errors: null,
        };
    },
    props: {
        website_settings: {
            type: Object,
            required: true,
        },
        website_enabled: {
            required: true,
        },
    },
    computed: {
        basePostURL() {
            return `/api/website/${this.restaurant_id}/website/img/`;
        },
        restaurant_id() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_customization() {
            return this.rights.includes("website.customization.update");
        },
    },
    methods: {
        baseURIPdf(nb) {
            return `/api/website/${this.restaurant_id}/website/menuPdf/${nb}`;
        },
        postForm(doNotFetch = false) {
            this.loading++;
            this.errors = null;

            return this.$store
                .dispatch("websites/updateWebsiteSettings", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        ...this.website_settings,
                        show_address: this.website_settings.show_address ? false : true,
                    },
                })
                .then((response) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    if (!doNotFetch) this.$emit("fetch-data");
                    return true;
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    return false;
                });
        },
        initNewMenu(nb) {
            return {
                id: null,
                nb: nb,
                title: "",
                price_in_cent: null,
                content: "",
            };
        },
    },
    watch: {
        "website_settings.menus_nb": function () {
            for (let i = this.website_settings.menus.length + 1; i <= this.website_settings.menus_nb; i++) {
                this.website_settings.menus.push(this.initNewMenu(i));
            }
        },
    },
    components: {
        ShowErrors,
        LoaderComponent,
        WebsiteMenuComponent,
        GalleryFormComponent,
        TextAreaComponentOld,
        FileUploaderComponent,
        ImageComponent,
    },
};
</script>
