export const mutations = {
    setActivated(state, value) {
        state.activated = value;
    },
    setIsBuyingPack(state, value) {
        state.is_buying_pack = value;
    },
    set(state, value) {
        state.activated = value.activated;
        state.is_buying_pack = value.is_buying_pack;
    },
};

export default mutations;
