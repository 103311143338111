var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row m-0" },
      [
        _vm._l(_vm.links, function (link, index) {
          return [
            link.if === undefined ||
            (typeof link.if === "function" && link.if()) ||
            link.if === true
              ? _c("home-card", {
                  key: index,
                  staticClass: "col-md-2 mb-3",
                  attrs: { link: link },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: link.routeName })
                    },
                  },
                })
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [
            _c(
              "h5",
              {
                staticClass: "title mt-2",
                attrs: { "data-test-id": "title-admin_panel" },
              },
              [_vm._v("Panel Admin")]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }