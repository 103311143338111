<template>
    <div class="h-100">
        <div v-if="!isBrowserIE" class="back-off h-100">
            <topbar
                :style="moduleSwitcher.width > 0 ? `margin-left: ${moduleSwitcher.width}px !important` : ''"
                :home="{ name: 'gift_vouchers.home' }"
                :isSidebarCollapsed="isSidebarCollapsed"
                :restaurants="restaurants"
                :user="user"
                module_type="gift-vouchers"
                @module-switcher-button-clicked="moduleSwitcher.show = !moduleSwitcher.show"
                :mobileMenuDisplayed="mobileMenuDisplayed"
                @mobile-menu-updated="mobileMenuDisplayed = $event">
            </topbar>

            <div class="container-fluid h-100" :style="moduleSwitcher.show == true ? 'overflow-x: hidden;' : ''">
                <div class="row h-100">
                    <module-switcher
                        serviceId="gift-vouchers"
                        :isSidebarCollapsed="isSidebarCollapsed"
                        :user="user"
                        @open="onModuleSwitcherOpen"
                        @close="closeModuleSwitcher"
                        :show="moduleSwitcher.show" />
                    <sidebar
                        :style="moduleSwitcher.width > 0 ? `margin-left: ${moduleSwitcher.width}px !important` : ''"
                        :user="user"
                        :restaurants="restaurants"
                        :isSidebarCollapsed="isSidebarCollapsed"
                        :entries="sidebarEntries"
                        :restaurantRouteName="`gift_vouchers.restaurants.sales`"
                        @sidebar-collapse-changed="isSidebarCollapsed = $event"
                        @close-menu="mobileMenuDisplayed = false"
                        @reload-router-view="$emit('reload-router-view')">
                    </sidebar>

                    <main
                        role="main"
                        class="pl-0 pr-0 h-100 main"
                        :style="moduleSwitcher.width > 0 ? `margin-left: ${moduleSwitcher.width + 240}px !important` : ''"
                        :class="{
                            'col-md-12 col-lg-12': user && user.role === 'kitchen',
                            'offset-md-3 offset-lg-2 col-md-9 col-lg-10': user && user.role !== 'kitchen' && !isSidebarCollapsed,
                            'offset-md-1 offset-lg-1 col-lg-11 col-md-11': isSidebarCollapsed,
                        }">
                        <notifications style="z-index: 9999" group="notification" />

                        <slot />
                    </main>
                </div>
            </div>
            <AcceptCgvModal :module="MODULE_TYPE_GIFT_VOUCHERS" v-if="showAcceptCgvModal" @close="showAcceptCgvModal = false" />
        </div>
        <div v-else style="text-align: center">
            <h1>Navigateur non supporté</h1>
            <p v-html="$t('errors.browser.invalid.infos')"></p>
            <br />
        </div>
    </div>
</template>

<script>
import axios from "axios";
// load components
import Sidebar from "../Sidebar.vue";
import Topbar from "../Topbar.vue";
import ModuleSwitcher from "../ModuleSwitcher";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum";
import AcceptCgvModal from "../../components/Modals/CGU/AcceptCgvModal";

export default {
    props: {
        user: {
            required: true,
        },
    },
    mixins: [ModuleTypesEnum],
    data: function () {
        return {
            showAcceptCgvModal: false,
            submenu: false,
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
            selected_service_id: null,
            servicesNavbar: false,
            creditCountSms: null,
            smsActivated: null,
            isSidebarCollapsed: this.isSidebarCollapsedTablet(),
            moduleSwitcher: {
                show: false,
                height: 0,
                width: 0,
            },
            mobileMenuDisplayed: false,
        };
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        sidebarEntries() {
            return [
                {
                    to: { name: "gift_vouchers.restaurants.sales" },
                    title: "Ventes",
                    icon: "shopping-cart",
                    exactUrlMatching: false,
                    rights: ["gift_vouchers.sales.*"],
                },
                {
                    to: { name: "gift_vouchers.restaurants.payments" },
                    title: "Paiements",
                    icon: "dollar-sign",
                    exactUrlMatching: false,
                    rights: ["gift_vouchers.payments.*"],
                },
                {
                    to: { name: "gift_vouchers.restaurants.report" },
                    title: "Rapport",
                    icon: "bar-chart-2",
                    exactUrlMatching: false,
                    rights: ["gift_vouchers.stats.read"],
                },
                {
                    to: { name: "gift_vouchers.clients" },
                    title: "Clients",
                    icon: "users",
                    exactUrlMatching: false,
                    rights: ["default.customer.*"],
                },
                {
                    to: { name: "gift_vouchers.restaurants.products.products" },
                    title: "Produits",
                    icon: "package",
                    exactUrlMatching: false,
                    rights: ["gift_vouchers.products.*"],
                },
                {
                    to: { name: "gift_vouchers.restaurants.configuration.configuration" },
                    title: "Configuration",
                    icon: "settings",
                    exactUrlMatching: false,
                    rights: ["gift_vouchers.config.*"],
                },
                {
                    to: {
                        name: "notifications.logs",
                        params: { module: this.MODULE_TYPE_GIFT_VOUCHERS.value },
                    },
                    titleI18n: "notificationsLogs",
                    icon: "bell",
                    exactUrlMatching: true,
                    rights: ["default.notificationlogs.*"],
                },
            ];
        },
        restaurants() {
            return this.$store.getters["restaurants/getRestaurantsByService"]("gift-vouchers").filter((r) => {
                return this.$store.getters["users/restaurantIds"].includes(r.id);
            });
        },
        isOwner() {
            return this.$store.getters["users/role"] === "owner";
        },
    },
    created() {
        if (this.isOwner) this.fetchHasAcceptedCgvs();
    },
    methods: {
        isSidebarCollapsedTablet() {
            if (window.innerWidth <= 1024 && window.innerWidth > 767) {
                return true;
            } else {
                return false;
            }
        },
        onModuleSwitcherOpen({ width, height }) {
            this.moduleSwitcher.width = width;
            this.moduleSwitcher.height = height;
        },
        closeModuleSwitcher() {
            this.moduleSwitcher.width = 0;
            this.moduleSwitcher.height = 0;
            this.moduleSwitcher.show = false;
        },
        logout() {
            axios
                .post("/logout")
                .then((response) => {
                    window.location.href = response.data.redirect_url || response.headers.location || "/login";
                })
                .catch(() => {});
        },
        selectRestaurant(event) {
            if (this.user && this.user.role === "kitchen") {
                this.$router.push({
                    name: "booking.restaurants.reservationsCuisine",
                    params: { restaurant_id: event.target.value },
                });
                return;
            }
            this.$router.push({
                name: "booking.restaurants.reservations",
                params: { restaurant_id: event.target.value },
            });
        },
        fetchHasAcceptedCgvs() {
            this.loading = true;

            return axios
                .get(`/api/accepted_cgvs/modules/${this.MODULE_TYPE_GIFT_VOUCHERS.value}`)
                .then((response) => {
                    this.loading = false;
                    this.showAcceptCgvModal = !response.data.result;
                })
                .catch(() => (this.loading = false));
        },
    },
    components: {
        Sidebar,
        Topbar,
        ModuleSwitcher,
        AcceptCgvModal,
    },
};
</script>
