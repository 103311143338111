var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(_vm._s(_vm.$tl("labels.clients.merge.multiple"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c("SearchAndSelectClient", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.step === 1,
              expression: "step === 1",
            },
          ],
          attrs: { avoidClientId: _vm.client.id },
          on: { "client-selected": _vm.onClientSelected },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading,
                expression: "!loading",
              },
            ],
          },
          [
            _vm.step === 2
              ? _c("SelectClientForMerging", {
                  attrs: { client1: _vm.client, client2: _vm.selectedClient },
                  on: { "client-selected": _vm.onFinalClientSelected },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.loading ? _c("LoaderComponent") : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            on: { click: _vm.close },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.step > 1
          ? _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary btn-sm btn-circle ml-2",
                on: { click: _vm.previousStep },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.back")) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.step > 1
          ? _c(
              "button",
              {
                staticClass: "btn btn-success btn-sm btn-circle ml-2",
                class: {
                  disabled: !_vm.finalSelectedClient || !_vm.clientToErase,
                },
                attrs: {
                  disabled: !_vm.finalSelectedClient || !_vm.clientToErase,
                },
                on: { click: _vm.mergeClients },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.validate")) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }