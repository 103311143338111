<template>
    <div class="advance-filter">
        <div class="m-0" style="color: #666666">
            <div class="border-light b-radius-20 p-4 mb-3">
                <div class="row">
                    <div class="col-12 d-flex align-items-center date-resa-cal">
                        <div v-for="advancedFilter in advancedFilters" class="mx-1">
                            <span>{{ advancedFilter.title }}</span>
                            <select class="custom-select d-inline-block ml-2 mr-2 mb-0" style="width: initial" v-model="value[advancedFilter.filter]">
                                <option v-if="advancedFilter.defaultLabel !== null" :value="null">{{ advancedFilter.defaultLabel }}</option>
                                <option v-for="option in advancedFilter.options" :key="option.value" :value="option.value">
                                    {{ capitalize(option.label) }}
                                </option>
                            </select>
                        </div>
                        <div class="mx-1">
                            <span>Du</span>
                            <datepicker class="d-inline-block" can-be-empty show-clear-button v-model="value.from" />
                        </div>
                        <div class="mx-1">
                            <span>Au</span>
                            <datepicker class="d-inline-block" can-be-empty show-clear-button v-model="value.to" />
                        </div>
                        <button type="button" @click.prevent.stop="resetFilter()" class="btn btn-sm btn-secondary btn-circle ml-2">
                            Réinitialiser
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SaleStatusEnum from "../../mixins/enums/gift_vouchers/SaleStatusEnum.js";
import ExpeditionTypesEnum from "../../mixins/enums/gift_vouchers/ExpeditionTypesEnum.js";
import Datepicker from "../../components/forms/DatePicker.vue";

export default {
    props: {
        advancedFilters: {
            type: Array,
            default: () => {
                return [];
            },
        },
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            filter: {
                status: null,
                expedition_type: null,
                from: null,
                to: null,
            },
        };
    },
    methods: {
        resetFilter() {
            this.$emit("input", { status: null, expedition_type: null, from: null, to: null });
        },
    },
    mixins: [SaleStatusEnum, ExpeditionTypesEnum],
    components: {
        Datepicker,
    },
};
</script>
