<template>
    <div class="border-light b-radius-20 p-4">
        <div class="row">
            <div class="col-12">
                <strong>{{ $tl("labels.booking.widget.footprintAndPayment.title") }}</strong>
            </div>
        </div>
        <div class="mt-3">
            <input-component
                v-model.number="settings.option_bank_short_delay"
                required
                :disabled="!isEditable"
                input-type="number"
                :label="$tl('labels.booking.widget.footprintAndPayment.optionBankShortDelay')"
                :min="1"
                :max="47"
                :append-element="$tl('labels.hour')"></input-component>
        </div>
    </div>
</template>

<script>
import InputComponent from "../forms/InputComponent.vue";

export default {
    props: {
        settings: {
            type: Object,
            required: true,
        },
        isEditable: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        InputComponent,
    },
};
</script>
