var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mb-3" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-inline-block align-middle lead-switch switch-choice",
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentCategoryComponent,
                        expression: "currentCategoryComponent",
                      },
                    ],
                    staticClass: "custom-select",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.currentCategoryComponent = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _vm._l(_vm.categories, function (category) {
                      return _c(
                        "option",
                        { key: category.id, domProps: { value: category.id } },
                        [_vm._v(_vm._s(category.name))]
                      )
                    }),
                    _vm._v(" "),
                    _c("option", { domProps: { value: -1 } }, [
                      _vm._v("Ajouter catégorie"),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.currentCategoryComponent === -1
                  ? _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("input", $event.target.value)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5" }, [
      _c("label", [_vm._v("Catégorie")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }