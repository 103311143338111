<template>
    <div class="p-2" v-if="canReadPromoCode">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Liste des codes promos</h5>
                </div>
            </div>
        </div>
        <div>
            <!-- filters -->
            <div
                class="col-12 my-3 border-light b-radius-20 py-4 px-xl-4 d-xl-flex justify-content-between align-items-center date-resa-cal"
                style="color: #666666">
                <div class="row w-100 mx-auto">
                    <div class="col d-xl-flex">
                        <div class="d-flex align-items-center relative my-2 mr-2">
                            <span class="mr-1">Date de création du</span>
                            <DatePicker
                                class="d-inline-block"
                                input-class="form-control"
                                :format="DATE_SHORT"
                                can-be-empty
                                :showClearButton="true"
                                v-model="currentFilters.from"
                                :disabled-dates="disabledDatesFrom" />
                        </div>
                        <div class="d-flex align-items-center relative my-2 mr-2">
                            <span class="mr-1">{{ $tl("labels.filters.dates.to").toLowerCase() }}</span>
                            <DatePicker
                                class="d-inline-block"
                                input-class="form-control"
                                :format="DATE_SHORT"
                                can-be-empty
                                :showClearButton="true"
                                v-model="currentFilters.to"
                                :disabled-dates="disabledDatesTo" />
                        </div>
                    </div>
                    <div class="col my-2 d-xl-flex align-items-xl-center">
                        Type d'abonnement
                        <select v-model="currentFilters.plan" class="custom-select d-inline-block ml-2 mr-2 mb-0 w-50" style="width: initial">
                            <option :value="null">Tous</option>
                            <option v-for="stripePlan in ALL_STRIPE_PLAN_TYPES" :key="stripePlan.value" :value="stripePlan.value">
                                {{ stripePlan.label }}
                            </option>
                        </select>
                    </div>
                    <div class="col my-2 d-xl-flex align-items-xl-center">
                        Module
                        <select v-model="currentFilters.module" class="custom-select d-inline-block ml-2 mr-2 mb-0" style="width: initial">
                            <option :value="null">Tous</option>
                            <option v-for="module in ALL_MODULES_TYPES" :key="module.value" :value="module.value">
                                {{ module.label }}
                            </option>
                        </select>
                    </div>
                    <div class="col my-2 d-xl-flex">
                        <button class="btn btn-sm btn-success btn-circle ml-2" type="button" @click="toggleFilters(false)">
                            <feather class="icon bg-none" type="filter" size="20" />
                            {{ $tl("labels.form.actions.filter") }}
                        </button>
                        <button class="btn btn-sm btn-secondary btn-circle ml-2" type="button" @click="toggleFilters(true)">
                            <feather class="icon bg-none" type="rotate-ccw" size="20" />
                            {{ $tl("labels.form.actions.reset") }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-2">
                <switch-input-component
                    v-model="canIncludeTrashed"
                    without-base-class
                    wrapper-classes="d-flex justify-space-between align-items-center"
                    label-wrapper-classes="mx-2"
                    input-wrapper-classes="mx-2">
                    <template #label>Inclure les codes promos archivés</template>
                </switch-input-component>

                <router-link v-if="canUpdatePromoCode" class="btn btn-sm btn-circle btn-success" :to="{ name: 'admin.promo_codes.create' }">
                    <feather type="plus" />
                    Créer un code promo
                </router-link>
            </div>

            <loader-component v-if="isLoading" />
            <ns-table
                v-else
                tableClass="table table-sm table-striped border-bottom"
                clickable
                :data-source="promoCodes"
                @row-selected="showPromoCode($event.data)"
                allow-paging
                :pagination="pagination"
                is-server-side
                @update-data="updateDataFromEvent($event)"
                :current-sort="queries.sort">
                <template #head>
                    <ns-th field="created_at" allow-sorting> Date de création </ns-th>
                    <ns-th field="name" allow-sorting> Nom </ns-th>
                    <ns-th field="code" allow-sorting> Code </ns-th>
                    <ns-th field="reduction"> Réduction </ns-th>
                    <ns-th field="module" allow-sorting> Module </ns-th>
                    <ns-th field="discount_type" allow-sorting> Type d'abonnement </ns-th>
                    <ns-th field="validity_expires_at" allow-sorting> Expire le </ns-th>
                    <ns-th field="is_active" allow-sorting> Actif </ns-th>
                    <ns-th> Actions </ns-th>
                </template>
                <template #body="{ data }">
                    <td>{{ formatDate(data.created_at) }}</td>
                    <td>{{ data.name }}</td>
                    <td><copy-to-clipboard :text-to-copy="data.code" element="Le code" class="copy-container" /></td>
                    <td>{{ getReduction(data) }}</td>
                    <td>{{ getModuleTypeLabel(data.module) }}</td>
                    <td>{{ getStripePlanTypeLabel(data.plan) }}</td>
                    <td>{{ formatDate(data.validity_expires_at, false) }}</td>
                    <td style="text-align: center">
                        <LoaderComponent v-if="isFragmentLoading[data.id]" />
                        <span v-else-if="data.deleted_at !== null" class="d-inline-block text-warning my-3">Archivé</span>
                        <switch-input-component
                            v-else
                            :disabled="!canUpdatePromoCode || canDisableButton"
                            remove-label-part
                            :value="data.is_active"
                            :click-event-alterations="[(event) => event.stopPropagation(), (event) => event.preventDefault()]"
                            input-wrapper-classes="mx-auto"
                            @input="updatePromoCode(data)" />
                    </td>
                    <td style="width: 76px">
                        <button
                            @click.stop.prevent="showPromoCode(data)"
                            type="button"
                            class="btn btn-sm btn-outline-secondary btn-square"
                            v-tooltip="getTooltip(`Voir les details du code promo '${data.code}'`)">
                            <feather type="eye" />
                        </button>
                        <button
                            v-if="data.deleted_at !== null"
                            v-tooltip="getTooltip('Le code promo est déjà archivé')"
                            type="button"
                            class="btn btn-sm btn-secondary btn-square"
                            disabled>
                            <feather type="trash" />
                        </button>
                        <button
                            v-else-if="canUpdatePromoCode"
                            @click.stop.prevent="deletePromoCode(data)"
                            type="button"
                            v-tooltip="getTooltip('Le code promo ne pourra plus être utilisé mais restera consultable')"
                            class="btn btn-sm btn-danger btn-square"
                            :disabled="data.deleted_at !== null || canDisableButton">
                            <feather type="archive" />
                        </button>
                    </td>
                </template>
            </ns-table>
        </div>
        <PromoCodeShow v-if="canShowPromoCodeModal" :promo-code="selectedData" @close="canShowPromoCodeModal = false" />
    </div>
</template>
<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import NsTable from "../../../components/Datatable/NsTable.vue";
import NsTh from "../../../components/Datatable/NsTh.vue";
import SwitchInputComponent from "../../../components/forms/SwitchInputComponent.vue";
import { DateTime } from "luxon";
import PromoCodeShow from "./PromoCodeShow.vue";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum";
import StripePlanTypesEnum from "../../../mixins/enums/StripePlanTypesEnum";
import DatePicker from "../../../components/forms/DatePicker.vue";
import CopyToClipboard from "../../../components/Buttons/CopyToClipboard.vue";

export default {
    components: {
        LoaderComponent,
        NsTable,
        NsTh,
        SwitchInputComponent,
        PromoCodeShow,
        DatePicker,
        CopyToClipboard,
    },
    mixins: [ModuleTypesEnum, StripePlanTypesEnum],
    data() {
        return {
            isLoading: false,
            isFragmentLoading: [],
            canDisableButton: false,
            promoCodes: [],
            selectedData: null,
            canShowPromoCodeModal: false,
            canIncludeTrashed: false,
            queries: {
                page: 1,
                per_page: 12,
                sort: {
                    field: null,
                    order: null,
                },
                filters: {},
            },
            pagination: {
                count: 0,
                current_page: 1,
                per_page: 12,
                total: 0,
                total_pages: 1,
            },
            currentFilters: {
                module: null,
                plan: null,
                from: null,
                to: null,
                with_trashed: false,
            },
        };
    },
    computed: {
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        canUpdatePromoCode() {
            return this.rights.includes("admin.promocodes.create");
        },
        canReadPromoCode() {
            return this.rights.includes("admin.promocodes.read");
        },
        disabledDatesFrom() {
            const disabledDates = { from: new Date() };

            if (this.currentFilters.to !== null) {
                disabledDates.from = this.currentFilters.to.toJSDateCustom();
            }

            return disabledDates;
        },
        disabledDatesTo() {
            const disabledDates = { from: new Date() };

            if (this.currentFilters.from !== null) {
                disabledDates.to = this.currentFilters.from.toJSDateCustom();
            }

            return disabledDates;
        },
    },
    created() {
        this.getPromoCodes();
    },
    watch: {
        canIncludeTrashed(newValue) {
            this.currentFilters.with_trashed = newValue;
            this.queries.filters.with_trashed = newValue;
            this.getPromoCodes();
        },
    },
    methods: {
        toggleFilters(shouldReset = false) {
            if (shouldReset) {
                this.resetFilters();
            }
            this.setFilters();
            this.getPromoCodes();
        },
        setFilters() {
            this.queries.filters = { ...this.currentFilters };
            this.queries.page = 1;
        },
        resetFilters() {
            this.currentFilters.from = null;
            this.currentFilters.to = null;
            this.currentFilters.module = null;
            this.currentFilters.plan = null;
            this.currentFilters.with_trashed = null;
        },
        formatDate(date, isDatetime = true) {
            return this.displayDate(DateTime.fromISO(date), isDatetime ? this.DATETIME_SHORT : this.DATE_SHORT);
        },
        setPagination({ per_page, total, current_page }) {
            this.pagination.per_page = per_page;
            this.pagination.total = total;
            this.pagination.current_page = current_page;
            this.pagination.total_pages = Math.ceil(total / per_page);

            if (![5, 10, 12, 20].includes(this.pagination.per_page)) {
                this.pagination.per_page = "all";
            }
        },
        getPromoCodes() {
            this.isLoading = true;

            const queries = this.parseQueries();

            this.httpGet(`/api/admin/promo_codes/?${queries}`)
                .then((response) => {
                    if (response !== false) {
                        this.promoCodes = response.data.data;
                        this.setPagination(response.data);
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        parseQueries() {
            const filters = this.parseQueryFilters();
            const sort = this.parseQuerySort();
            const page = this.parseQueryPage();

            return filters + sort + page;
        },
        parseQueryFilters() {
            let queryFilters = "";

            Object.keys(this.queries.filters).forEach((key) => {
                if (this.queries.filters[key] !== null) {
                    const value = this.queries.filters[key] instanceof DateTime ? this.queries.filters[key].toISODate() : this.queries.filters[key];
                    queryFilters = queryFilters.concat("&", `${key}=${value}`);
                }
            });

            return queryFilters;
        },
        parseQuerySort() {
            if (this.queries.sort.field !== null && this.queries.sort.order !== null) {
                return "&".concat("sort=", this.queries.sort.field) + "&".concat("order=", this.queries.sort.order);
            }

            return "";
        },
        parseQueryPage() {
            return "&".concat("page=", this.queries.page) + "&".concat("per_page=", this.queries.per_page);
        },
        updateDataFromEvent(event) {
            this.queries.sort.field = null;
            this.queries.sort.order = null;

            this.queries.page = event.page;
            this.queries.per_page = event.per_page;

            if (event.field !== null && event.order !== null) {
                this.queries.sort.field = event.field;
                this.queries.sort.order = event.order;
            }

            this.getPromoCodes();
        },
        updatePromoCode(data) {
            this.canDisableButton = true;
            let isActive = !data.is_active;

            this.httpPut(`/api/admin/promo_codes/${data.id}`, { is_active: isActive })
                .then((response) => {
                    if (response === false) {
                        isActive = data.is_active;
                    }
                })
                .finally(() => {
                    data.is_active = isActive;
                    this.canDisableButton = false;
                });
        },
        deletePromoCode(data) {
            this.canDisableButton = true;

            if (confirm(`Voulez vous archiver le code promo suivant : ${data.name}`)) {
                this.httpDelete(`/api/admin/promo_codes/${data.id}`)
                    .then((response) => {
                        if (response !== false) {
                            this.getPromoCodes();
                        }
                    })
                    .finally(() => (this.canDisableButton = false));
            }
        },
        showPromoCode(data) {
            this.selectedData = data;
            this.canShowPromoCodeModal = true;
        },
        getReduction(data) {
            if (data.amount_off !== null) {
                return this.formatCurrency(data.amount_off);
            }
            if (data.percent_off !== null) {
                return `${data.percent_off} %`;
            }
            return "";
        },
    },
};
</script>
<style scoped>
.copy-container {
    max-width: 300px;
}
</style>
