export default {
    data() {
        return {
            PAYMENT_TYPE_CB: {
                value: "cb",
                label: "Carte bancaire",
            },
            PAYMENT_TYPE_CHEQUE: {
                value: "cheque",
                label: "Chèque",
            },
            PAYMENT_TYPE_CASH: {
                value: "cash",
                label: "Espèces",
            },
            PAYMENT_TYPE_BANK_TRANSFER: {
                value: "bank_transfer",
                label: "Virement bancaire",
            },
            PAYMENT_TYPE_UNKOWN: {
                value: "unknown",
                label: "--",
            },
            PAYMENT_TYPE_GIFT: {
                value: "gift",
                label: "Offert",
            },
        };
    },
    methods: {
        getPaymentTypeLabel(value) {
            const found = this.ALL_PAYMENT_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_PAYMENT_TYPES() {
            return [
                this.PAYMENT_TYPE_CB,
                this.PAYMENT_TYPE_CHEQUE,
                this.PAYMENT_TYPE_CASH,
                this.PAYMENT_TYPE_BANK_TRANSFER,
                this.PAYMENT_TYPE_UNKOWN,
                this.PAYMENT_TYPE_GIFT,
            ];
        },
        PAYMENT_TYPES_FOR_FILTERING() {
            return [
                {
                    value: "cb_online",
                    label: this.PAYMENT_TYPE_CB.label + " (en ligne)",
                },
                {
                    value: this.PAYMENT_TYPE_CB.value,
                    label: this.PAYMENT_TYPE_CB.label + " (sur place)",
                },
                this.PAYMENT_TYPE_CHEQUE,
                this.PAYMENT_TYPE_CASH,
                this.PAYMENT_TYPE_BANK_TRANSFER,
                this.PAYMENT_TYPE_GIFT,
            ];
        },
    },
};
