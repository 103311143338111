var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-2" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn pl-0",
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [
                    _c("feather", {
                      staticClass: "feather-24",
                      attrs: { type: "chevron-left" },
                    }),
                  ],
                  1
                ),
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.videoId === null
                        ? _vm.$t("labels.admin.videos.add")
                        : _vm.$t("labels.admin.videos.edit")
                    ) +
                    "\n                "
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent")
        : _c("form", { staticClass: "row", on: { submit: _vm.saveVideo } }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                    _c("label", [
                      _vm._v(
                        _vm._s(_vm.$t("labels.admin.videos.columns.title")) +
                          " "
                      ),
                      _c("small", [_vm._v("*")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-7" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.video.title,
                            expression: "video.title",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "100" },
                        domProps: { value: _vm.video.title },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.video, "title", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("ShowErrors", {
                        staticClass: "d-block mt-1",
                        attrs: { errors: _vm.errors, errorKey: "title" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("labels.admin.videos.columns.description")
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-7" },
                    [
                      _c("TextAreaComponentOld", {
                        attrs: {
                          txtAreaObj: _vm.video,
                          txtAreaObjKey: "description",
                          maxNbChar: 500,
                        },
                      }),
                      _vm._v(" "),
                      _c("ShowErrors", {
                        staticClass: "d-block",
                        attrs: { errors: _vm.errors, errorKey: "description" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                    _c(
                      "label",
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t("labels.admin.videos.youtubeVideoId")
                            ) +
                            "\n                            "
                        ),
                        _c("feather", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: _vm.$t(
                                  "infos.admin.videos.youtubeVideoId"
                                ),
                              },
                              expression:
                                "{ content: $t('infos.admin.videos.youtubeVideoId') }",
                            },
                          ],
                          staticClass: "text-danger ml-1 mr-1",
                          attrs: { type: "youtube" },
                        }),
                        _vm._v(" "),
                        _c("small", [_vm._v("*")]),
                        _vm._v(" "),
                        _c("feather", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getTooltipNotice(
                                "notices.videos.getYoutubeVideoId"
                              ),
                              expression:
                                "getTooltipNotice('notices.videos.getYoutubeVideoId')",
                            },
                          ],
                          staticClass: "ml-1 pointer tooltip-infos",
                          attrs: { type: "info" },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-7" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.video.youtube_video_id,
                            expression: "video.youtube_video_id",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", maxlength: "300" },
                        domProps: { value: _vm.video.youtube_video_id },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.video,
                              "youtube_video_id",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("ShowErrors", {
                        staticClass: "d-block",
                        attrs: {
                          errors: _vm.errors,
                          errorKey: "youtube_video_id",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-md-5 pt-1" }, [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("labels.admin.videos.columns.module")) +
                          " "
                      ),
                      _c("small", [_vm._v("*")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-7" },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.video.module,
                              expression: "video.module",
                            },
                          ],
                          staticClass: "custom-select",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.video,
                                "module",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("--"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "option",
                            {
                              domProps: {
                                value: _vm.MODULE_TYPE_DEFAULT.value,
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.getModuleTypeLabel(
                                      _vm.MODULE_TYPE_DEFAULT.value
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.ALL_MODULES_TYPES_WITH_CAMPAIGNS,
                            function (module) {
                              return _c(
                                "option",
                                {
                                  key: module.value,
                                  domProps: { value: module.value },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.getModuleTypeLabel(module.value)
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("ShowErrors", {
                        staticClass: "d-block",
                        attrs: { errors: _vm.errors, errorKey: "module" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-4 offset-md-4 text-center" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-circle btn-success",
                          attrs: {
                            type: "button",
                            disabled: !_vm.canShowPreview,
                          },
                          on: {
                            click: function ($event) {
                              _vm.showPreview = !_vm.showPreview
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.showPreview
                                  ? _vm.$t("labels.admin.videos.preview.hide")
                                  : _vm.$t("labels.admin.videos.preview.show")
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-md-4 text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-sm btn-success btn-circle mt-2 mr-1",
                        attrs: { type: "submit" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("labels.form.actions.save")) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.showPreview
                  ? _c(
                      "div",
                      { staticClass: "row mt-2" },
                      [
                        _c("ShowYoutubeVideo", {
                          staticClass: "mx-auto",
                          attrs: {
                            width: "560",
                            height: "315",
                            youtubeVideoId: _vm.video.youtube_video_id,
                            title: _vm.video.title,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }