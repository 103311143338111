var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("loader-component", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading && _vm.error,
              expression: "!loading && error",
            },
          ],
        },
        [_vm._v("\n        " + _vm._s(_vm.error) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading && !_vm.error,
              expression: "!loading && !error",
            },
          ],
          staticClass: "default-home",
        },
        [
          _c("div", { staticClass: "row m-0" }, [
            _c("div", { staticClass: "col-12 mb-3" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column align-items-center flex-md-row",
                },
                [
                  _c("h5", { staticClass: "title mt-2" }, [
                    _vm._v(_vm._s(_vm.$tl("labels.areasEstablishments.title"))),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.zones.length < 1
            ? _c("div", { staticClass: "border-light b-radius-20 p-3 mb-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.areasEstablishments.noAreas")) +
                      "\n            "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.zones, function (zone) {
            return _c("zone-tab-form", {
              key: zone.id,
              attrs: {
                zone: zone,
                restaurants: _vm.restaurants,
                "associated-restaurants":
                  zone.restaurants && zone.restaurants.length > 0
                    ? zone.restaurants.map((r) => r.id)
                    : [],
              },
              on: {
                "delete-zone": _vm.deleteZone,
                "edit-zone": _vm.updateZone,
                update: function ($event) {
                  zone.name = $event
                },
              },
            })
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-success btn-circle ml-1",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  _vm.showAddZoneModal = true
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$tl("labels.areasEstablishments.createArea")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.showAddZoneModal
            ? _c("add-zone-modal", {
                attrs: { restaurants: _vm.restaurants },
                on: {
                  close: function ($event) {
                    _vm.showAddZoneModal = false
                  },
                  "create-zone": _vm.addZone,
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }