var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "mainElement",
      staticClass: "blur-set",
      attrs: { tabindex: "-1" },
      on: { blur: _vm.eventHubEmitBlur },
    },
    [
      _c(
        "div",
        { staticClass: "main-detail-box", attrs: { tabindex: "-1" } },
        [
          _vm.loading
            ? _c("loader-component", { attrs: { i18nAvailable: false } })
            : [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex m-0 detail-box-header text-uppercase bg-dark justify-content-center align-items-center",
                    class: { "bg-grad": _vm.isYservices },
                    attrs: { tabindex: "-1" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "align-self-center",
                        attrs: { tabindex: "-1" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center align-items-center",
                            attrs: { tabindex: "-1" },
                          },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.slotSelected,
                                    expression: "slotSelected",
                                  },
                                ],
                                staticClass:
                                  "blur-set btn p-1 text-white align-self-center",
                                attrs: { type: "button", tabindex: "-1" },
                                on: {
                                  blur: _vm.eventHubEmitBlur,
                                  click: function ($event) {
                                    return _vm.selectSlot(null, $event)
                                  },
                                },
                              },
                              [
                                _c("feather", {
                                  staticStyle: { "pointer-events": "none" },
                                  attrs: { type: "chevron-left" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showServicePaxes,
                                    expression: "showServicePaxes",
                                  },
                                ],
                                staticClass:
                                  "blur-set btn p-1 text-white align-self-center",
                                attrs: { type: "button", tabindex: "-1" },
                                on: {
                                  blur: _vm.eventHubEmitBlur,
                                  click: function ($event) {
                                    return _vm.displayServicePaxes(false)
                                  },
                                },
                              },
                              [
                                _c("feather", {
                                  staticStyle: { "pointer-events": "none" },
                                  attrs: { type: "chevron-left" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "strong",
                              {
                                staticClass: "align-self-center pt-1",
                                attrs: { tabindex: "-1" },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.service.restaurant.name) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center align-items-center flex-grow-1",
                        attrs: { tabindex: "-1" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-right flex-grow-1 align-self-center mr-3",
                            attrs: { tabindex: "-1" },
                          },
                          [
                            _c("span", { attrs: { tabindex: "-1" } }, [
                              _vm._v(_vm._s(_vm.dateHumanFormat)),
                            ]),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { attrs: { tabindex: "-1" } }, [
                              _vm._v(_vm._s(_vm.service.name)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.slotSelected && !_vm.showServicePaxes,
                                expression:
                                  "!slotSelected && !showServicePaxes",
                              },
                            ],
                            attrs: { tabindex: "-1" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-inline-block align-middle" },
                              [
                                _c("input", {
                                  staticClass:
                                    "switch align-self-center is-rounded",
                                  class: _vm.getStatusClass(
                                    _vm.service.is_full
                                  ),
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: _vm.service.is_full !== 1,
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", {
                                  class: _vm.getStatusClass(
                                    _vm.service.is_full
                                  ),
                                  attrs: { tabindex: "-1" },
                                  on: { click: _vm.updateServiceAvailability },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "d-inline-block" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "blur-set btn pl-0 pt-0 pb-1 pr-1 text-white align-self-center",
                                  attrs: { type: "button", tabindex: "-1" },
                                  on: {
                                    blur: _vm.eventHubEmitBlur,
                                    click: function ($event) {
                                      return _vm.displayServicePaxes(true)
                                    },
                                  },
                                },
                                [
                                  _c("feather", {
                                    class: { "text-white": _vm.isYservices },
                                    attrs: { type: "chevron-right" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-box-content",
                    attrs: { tabindex: "-1" },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.slotSelected && !_vm.showServicePaxes,
                            expression: "!slotSelected && !showServicePaxes",
                          },
                        ],
                        staticClass: "detail-box-pax text-center text-white",
                        style: { backgroundImage: _vm.paxGradient },
                        attrs: { tabindex: "-1" },
                      },
                      [
                        !_vm.isEditingNewPax
                          ? [
                              _c("strong", { attrs: { tabindex: "-1" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.service.pax) +
                                    "/" +
                                    _vm._s(
                                      _vm.service.new_pax !== null
                                        ? _vm.service.new_pax
                                        : _vm.service.max_pax
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.service.new_pax !== null
                                ? _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltip(
                                            _vm.translate_(
                                              "labels.useDefaultParams"
                                            )
                                          ),
                                          expression:
                                            "getTooltip(translate_('labels.useDefaultParams'))",
                                        },
                                      ],
                                      staticClass: "btn",
                                      staticStyle: { "padding-left": "0" },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editNewPax(null)
                                        },
                                      },
                                    },
                                    [
                                      _c("feather", {
                                        staticClass: "text-white",
                                        attrs: { type: "x" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.getTooltip(
                                        _vm.translate_lexicon_(
                                          "labels.booking.services.editDayMaxPax",
                                          _vm.service.restaurant.id
                                        )
                                      ),
                                      expression:
                                        "getTooltip(translate_lexicon_('labels.booking.services.editDayMaxPax', service.restaurant.id))",
                                    },
                                  ],
                                  staticClass: "btn",
                                  attrs: { type: "button" },
                                  on: { click: _vm.startEditNewPax },
                                },
                                [
                                  _c("feather", {
                                    staticClass: "text-white",
                                    staticStyle: { "margin-bottom": "3px" },
                                    attrs: { type: "edit" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c("strong", { attrs: { tabindex: "-1" } }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.currentNewPax,
                                      expression: "currentNewPax",
                                    },
                                  ],
                                  staticClass: "form-control d-inline-block",
                                  staticStyle: {
                                    "border-radius": "20px !important",
                                    border: "none !important",
                                    width: "80px",
                                  },
                                  attrs: { type: "number", min: "1" },
                                  domProps: { value: _vm.currentNewPax },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.currentNewPax = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.getTooltip(
                                        _vm.translate_(
                                          "labels.form.actions.cancel"
                                        )
                                      ),
                                      expression:
                                        "getTooltip(translate_('labels.form.actions.cancel'))",
                                    },
                                  ],
                                  staticClass: "btn",
                                  staticStyle: { "padding-left": "0" },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.isEditingNewPax = false
                                    },
                                  },
                                },
                                [
                                  _c("feather", {
                                    staticClass: "text-white",
                                    attrs: { type: "x" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.getTooltip(
                                        _vm.translate_(
                                          "labels.form.actions.save"
                                        )
                                      ),
                                      expression:
                                        "getTooltip(translate_('labels.form.actions.save'))",
                                    },
                                  ],
                                  staticClass:
                                    "btn btn-outline-secondary btn-sm btn-square",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editNewPax(_vm.currentNewPax)
                                    },
                                  },
                                },
                                [
                                  _c("feather", {
                                    staticClass: "text-white",
                                    attrs: { type: "save" },
                                  }),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.slotSelected && !_vm.showServicePaxes,
                            expression: "!slotSelected && !showServicePaxes",
                          },
                        ],
                        staticClass: "detail-box-slots-grid",
                        attrs: { tabindex: "-1" },
                      },
                      _vm._l(_vm.service.slots, function (slot, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "w-auto text-center border-bottom-light detail-box-slot",
                            staticStyle: { cursor: "pointer" },
                            attrs: { tabindex: "-1" },
                            on: {
                              focus: function ($event) {
                                return _vm.$refs.mainElement.focus()
                              },
                              click: function ($event) {
                                return _vm.selectSlot(index)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.displayDate(
                                    _vm.setHourOnDateTime(slot.hour_start),
                                    _vm.TIME_SIMPLE
                                  )
                                ) +
                                "\n                        "
                            ),
                            _c("input", {
                              staticClass: "switch is-small is-rounded",
                              class: _vm.getFullStatusClass(slot),
                              attrs: { type: "checkbox" },
                              domProps: { checked: slot.is_full !== 1 },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "ml-2",
                              class: _vm.getFullStatusClass(slot),
                              attrs: { tabindex: "-1" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.updateSlotAvailability(slot, index)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("feather", {
                              staticStyle: { "pointer-events": "none" },
                              attrs: { type: "chevron-right" },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.slotSelected,
                            expression: "slotSelected",
                          },
                        ],
                        staticClass: "detail-box-slots-grid",
                        attrs: { tabindex: "-1" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "w-auto text-center border-bottom-light detail-box-slot",
                            attrs: { tabindex: "-1" },
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.slotSelected
                                    ? _vm.displayDate(
                                        _vm.setHourOnDateTime(
                                          _vm.slotSelected.hour_start
                                        ),
                                        _vm.TIME_SIMPLE
                                      )
                                    : ""
                                )
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.allPaxes, function (pax) {
                          return _c(
                            "div",
                            {
                              key: pax,
                              staticClass:
                                "w-auto text-center border-bottom-light detail-box-slot",
                              staticStyle: { cursor: "pointer" },
                              attrs: { tabindex: "-1" },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.capitalize(_vm.translate_("labels.pax"))
                                  ) +
                                  " " +
                                  _vm._s(pax) +
                                  "\n                        "
                              ),
                              _c("input", {
                                staticClass: "switch is-small is-rounded",
                                class: {
                                  closed:
                                    _vm.slotSelected &&
                                    _vm.slotSelected.paxes_full.includes(pax),
                                  open:
                                    _vm.slotSelected &&
                                    !_vm.slotSelected.paxes_full.includes(pax),
                                },
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked:
                                    _vm.slotSelected &&
                                    !_vm.slotSelected.paxes_full.includes(pax),
                                },
                              }),
                              _vm._v(" "),
                              _c("label", {
                                staticClass: "ml-2",
                                class: {
                                  closed:
                                    _vm.slotSelected &&
                                    _vm.slotSelected.paxes_full.includes(pax),
                                  open:
                                    _vm.slotSelected &&
                                    !_vm.slotSelected.paxes_full.includes(pax),
                                },
                                attrs: { tabindex: "-1" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.updatePaxAvailability(
                                      pax,
                                      _vm.slotSelected,
                                      _vm.slotIndexSelected
                                    )
                                  },
                                },
                              }),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showServicePaxes,
                            expression: "showServicePaxes",
                          },
                        ],
                        staticClass: "detail-box-slots-grid",
                        attrs: { tabindex: "-1" },
                      },
                      _vm._l(_vm.allPaxes, function (pax) {
                        return _c(
                          "div",
                          {
                            key: pax,
                            staticClass:
                              "w-auto text-center border-bottom-light detail-box-slot",
                            staticStyle: { cursor: "pointer" },
                            attrs: { tabindex: "-1" },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.capitalize(_vm.translate_("labels.pax"))
                                ) +
                                " " +
                                _vm._s(pax) +
                                "\n                        "
                            ),
                            _c("input", {
                              staticClass: "switch is-small is-rounded",
                              class: _vm.getStatusClass(
                                _vm.service.paxes_full[pax]
                              ),
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: _vm.service.paxes_full[pax] === -1,
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "ml-2",
                              class: _vm.getStatusClass(
                                _vm.service.paxes_full[pax]
                              ),
                              attrs: { tabindex: "-1" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.updateServicePaxAvailability(pax)
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-box-footer d-flex",
                    attrs: { tabindex: "-1" },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "detail-box-add detail-box-btn btn",
                        attrs: { tabindex: "-1" },
                        on: {
                          click: function ($event) {
                            return _vm.eventHub.$emit("add-resa", {
                              restaurant_id: _vm.service.restaurant.id,
                              service_id: _vm.service.id,
                              slot_id: null,
                              full_date: _vm.date,
                            })
                          },
                        },
                      },
                      [
                        _c("feather", {
                          staticStyle: {
                            "margin-bottom": "2px",
                            "pointer-events": "none",
                          },
                          attrs: { type: "plus", tabindex: "-1" },
                        }),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.translate_("labels.reservation")) +
                            "\n                "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "detail-box-list detail-box-btn btn",
                        attrs: { tabindex: "-1" },
                        on: {
                          click: function ($event) {
                            return _vm.eventHub.$emit("list-resa", {
                              restaurant_id: _vm.service.restaurant.id,
                              service_id: _vm.service.id,
                              full_date: _vm.date,
                            })
                          },
                        },
                      },
                      [
                        _c("feather", {
                          staticStyle: {
                            "margin-bottom": "2px",
                            "pointer-events": "none",
                          },
                          attrs: { type: "list", tabindex: "-1" },
                        }),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.translate_("labels.reservationPlural")) +
                            "\n                "
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }