var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticStyle: { overflow: "auto" },
      attrs: { modalContainerStyle: `overflow: visible` },
      on: { close: _vm.closeFromModal },
    },
    [
      _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(
          _vm._s(
            _vm.$tl(
              "labels.booking.reservations.noshowStatus",
              _vm.restaurant_id
            )
          )
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _c("set-noshow", {
            ref: `setNoshow`,
            attrs: {
              restaurant_id: _vm.restaurant_id,
              reservation_id: _vm.reservation.id,
              showSaveButton: false,
            },
            on: { noShowTaken: _vm.noShowTaken },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex w-100 justify-content-between",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "button",
            {
              staticClass: "ml-5 btn btn-secondary btn-circle btn btn-sm",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Annuler")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex mr-5" }, [
            _vm.reservation.client && _vm.reservation.chargable_if_no_show
              ? _c(
                  "div",
                  {
                    ref: `dropdown-main`,
                    staticClass: "dropdown",
                    attrs: { tabindex: "-1" },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn-sm btn-danger btn-circle btn dropdown-toggle",
                        attrs: {
                          type: "button",
                          id: "dropdownMenu2",
                          "data-toggle": "dropdown",
                          "aria-haspopup": "true",
                          "aria-expanded": "false",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Prélever\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dropdown-menu",
                        attrs: { "aria-labelledby": "dropdownMenu2" },
                      },
                      [
                        _vm.reservation.payment.card_system_type ===
                        _vm.CARD_PAYMENT_SYSTEM_STRIPE.value
                          ? _vm._l(_vm.totalNbPers, function (n) {
                              return _c(
                                "button",
                                {
                                  key: n,
                                  staticClass: "dropdown-item",
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      n * _vm.reservation.amount_if_noshow >
                                      _vm.reservation.stripe_intent_amount,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.takeNoShow(n)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(n) +
                                      " personne(s) (" +
                                      _vm._s(
                                        (n * _vm.reservation.amount_if_noshow) /
                                          100
                                      ) +
                                      "€)\n                        "
                                  ),
                                ]
                              )
                            })
                          : _c(
                              "button",
                              {
                                staticClass: "dropdown-item",
                                attrs: {
                                  type: "button",
                                  disabled:
                                    _vm.totalNbPers *
                                      _vm.reservation.amount_if_noshow >
                                    _vm.reservation.stripe_intent_amount,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.takeNoShow(_vm.totalNbPers)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.totalNbPers) +
                                    " personne(s) (" +
                                    _vm._s(
                                      (_vm.totalNbPers *
                                        _vm.reservation.amount_if_noshow) /
                                        100
                                    ) +
                                    "€)\n                    "
                                ),
                              ]
                            ),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "ml-2 btn btn-sm btn-warning btn-circle text-white",
                on: { click: _vm.markNoShow },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$tl(
                        "labels.booking.reservations.noshowWithoutDeduction",
                        _vm.restaurant_id
                      )
                    ) +
                    "\n            "
                ),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }