<template>
    <div>
        <div
            class="tile restaurant border-light"
            :class="clickable && !disabled ? 'clickable' : ''"
            @click="this.accessEstablishment"
            :style="!clickable || disabled ? 'opacity: 0.5;' : ''">
            <div class="logo" :class="{ background: restaurant.logo_url }" :style="computedStyle">
                <feather v-if="!restaurant.logo_url" type="home" class="grey feather-40" />
            </div>
            <h6 class="restaurant-title border-top-light text-center" data-test-id="title-restaurant_name">
                {{ restaurant.name }}
            </h6>
        </div>
    </div>
</template>

<script>
export default {
    name: "RestaurantCard",
    props: {
        restaurant: {
            required: true,
        },
        clickable: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        computedStyle() {
            if (this.restaurant.logo_url) {
                return `background-image: url("${this.restaurant.logo_url}");`;
            }

            return ``;
        },
    },
    methods: {
        accessEstablishment() {
            if (this.disabled) {
                return;
            }
            this.$emit("click", this.restaurant);
        },
    },
};
</script>
