<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.form.restaurant.select") }}</h5>
        <div slot="body">
            <LoaderComponent v-if="loading" />
            <div v-else>
                <div v-if="error" class="alert alert-danger">
                    {{ error }}
                </div>
                <div v-else>
                    <template v-for="(restau, index) in restaurants">
                        <div v-if="restau.id != exclude_restaurant_id" class="radio mt-2" :key="index">
                            <label class="container-box">
                                <input type="radio" v-model="selectedRestaurant" :value="index" />
                                <span class="checkmark"></span> {{ restau.name }}
                            </label>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn btn-secondary btn-sm btn-circle" @click="close">Fermer</button>
            <button
                class="btn btn-success btn-sm btn-circle"
                @click="$emit('selected', restaurants[selectedRestaurant])"
                :disabled="selectedRestaurant === null">
                {{ $tl("labels.form.actions.select") }}
            </button>
        </div>
    </modal>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
export default {
    data() {
        return {
            selectedRestaurant: null,
            restaurants: [],
            error: null,
            loading: false,
        };
    },
    props: {
        exclude_restaurant_id: {
            default: null,
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading = true;
            axios
                .get(`/api/booking/restaurants?all=1&omit=${this.$route.params.restaurant_id}`)
                .then((response) => {
                    this.restaurants = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.error = error.response.data.message || error.message;
                    this.loading = false;
                });
        },
        close(e) {
            e.preventDefault();
            e.stopPropagation();
            this.restaurants = [];
            this.error = null;
            this.selectedRestaurant = null;
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (!e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close(e);
            }
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>
