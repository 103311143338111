var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading ? _c("LoaderComponent") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
            _c(
              "div",
              { staticClass: "row mb-2" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "col-7" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mail,
                        expression: "mail",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      name: "mail",
                      id: "mail",
                      required: "",
                    },
                    domProps: { value: _vm.mail },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.mail = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("show-errors", {
                  attrs: { errors: _vm.errors, errorKey: "mail" },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5 pt-2" }, [
      _c("label", { attrs: { for: "name" } }, [
        _vm._v("Email "),
        _c("small", [_vm._v("*")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }