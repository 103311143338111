var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal-rounded-no-header", { on: { close: _vm.closeFromModal } }, [
    _c("div", {
      attrs: { slot: "body" },
      domProps: { innerHTML: _vm._s(_vm.content) },
      slot: "body",
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v("\n            Annuler\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-success btn-circle ml-2",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("confirmed", _vm.eventPayload)
              },
            },
          },
          [_vm._v("\n            Confirmer\n        ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }