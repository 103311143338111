var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("promo-code-fields", {
    attrs: { "promo-code": _vm.parentForm, "is-preview": "" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }