<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.widget.sendInstructions") }}</h5>
        <div slot="body">
            <SendWidgetDocToDev :url="url" ref="sendWidgetDocToDev" @sent="sent" @loading="loading = $event" />
        </div>
        <div slot="footer" class="d-flex">
            <button
                type="button"
                @click="close"
                :disabled="loading"
                :class="{ disabled: loading }"
                class="modal-default-button btn btn-sm btn-secondary btn-circle">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button
                type="button"
                @click="$refs.sendWidgetDocToDev.send()"
                :disabled="loading"
                :class="{ disabled: loading }"
                class="btn btn-sm btn-success btn-circle ml-2">
                {{ $tl("labels.form.actions.send") }}
            </button>
        </div>
    </modal>
</template>

<script>
import SendWidgetDocToDev from "../widgets/SendWidgetDocToDev";

export default {
    data() {
        return {
            loading: false,
        };
    },
    props: {
        url: {
            type: String,
            required: true,
        },
    },
    methods: {
        sent(data) {
            if (data.message)
                this.$notify({
                    group: "notification",
                    type: "success",
                    title: data.message,
                });
            this.close();
        },
        close() {
            this.$emit("close");
        },
        closeFromModal(e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        SendWidgetDocToDev,
    },
};
</script>
