<template>
    <div class="row m-0">
        <div class="col-12">
            <div class="border-light b-radius-20 p-4 mb-3 block">
                <div class="row mb-3">
                    <div class="col-12">
                        <strong> {{ $tl("labels.booking.widget.gTag.title") }} </strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row my-2">
                            <div class="col-md-5">
                                <label>
                                    {{ $tl("labels.booking.widget.gTag.id") }}
                                    <feather
                                        v-tooltip="getTooltipNotice('notices.booking.widget.gTag')"
                                        type="info"
                                        class="ml-1 pointer tooltip-infos" />
                                </label>
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-3">
                                        <input type="text" :value="value || ''" @input="$emit('input', $event.target.value)" class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: String,
            default: null,
        },
    },
};
</script>
