export default {
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal(e) {
            if (!e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
};
