<template>
    <div>
        <p>{{ $tl("labels.clients.import.steps.2.linkColumns") }}</p>
        <p class="text-danger" v-if="missingRequiredColumn">
            {{ $tl("labels.clients.import.steps.2.fillMandatoryColumns") }}
        </p>
        <ShowErrors class="alert alert-danger position-relative" style="top: 0" errClass="d-block" :errors="errors.form" errorKey="*" />
        <p class="text-danger" v-if="firstLine.length === 0">{{ $tl("labels.clients.import.steps.2.emptyLine") }}</p>
        <div class="row m-0" v-else v-for="(lineColumn, index) in firstLine" :key="index">
            <div class="col-6">
                {{ $t("labels.clients.import.steps.2.colNb", { nb: index + 1 }) }}<br />
                <small class="text-muted">{{ getExamples(index) }}</small>
            </div>
            <div class="col-6">
                <select class="custom-select" @change="columnAssociationChanged(index, $event)">
                    <option :value="null">{{ $tl("labels.clients.import.steps.2.ignoreCol") }}</option>
                    <option v-for="column in columns" :key="column.value" :value="column.value" :disabled="isColumnAlreadyUsed(column.value, index)">
                        {{ column.required ? column.label + " *" : column.label }}
                    </option>
                </select>
            </div>
            <div class="sepa"></div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ShowErrors from "../../../errors/ShowErrors";

export default {
    data() {
        return {
            errors: {
                form: null,
            },
            missingRequiredColumn: false,
            columnsAssociation: {},
            columns: [
                {
                    label: this.$tl("labels.form.civility"),
                    value: "civility",
                },
                {
                    label: this.$tl("labels.form.lastname"),
                    value: "lastname",
                    required: true,
                },
                {
                    label: this.$tl("labels.form.firstname"),
                    value: "firstname",
                    required: true,
                },
                {
                    label: this.$tl("labels.form.company"),
                    value: "company",
                },
                {
                    label: this.$tl("labels.form.email"),
                    value: "email",
                },
                {
                    label: this.$tl("labels.form.phoneNumber"),
                    value: "tel",
                    required: true,
                },
                {
                    label: this.$tl("labels.clients.import.steps.1.allowedFields.phoneCountryCode"),
                    value: "tel_country",
                },
                {
                    label: this.$tl("labels.clients.type"),
                    value: "type",
                },
                {
                    label: this.$tl("labels.clients.vip"),
                    value: "is_vip",
                },
                {
                    label: this.$tl("labels.clients.risky"),
                    value: "is_risky",
                },
                {
                    label: this.$tl("labels.clients.import.steps.2.newsletter"),
                    value: "newsletter",
                },
                {
                    label: this.$tl("labels.booking.reservations.clientComment"),
                    value: "comment",
                },
            ],
        };
    },
    props: {
        firstLines: {
            type: Array,
            default: () => [],
        },
        file: {
            default: undefined,
        },
    },
    computed: {
        firstLine() {
            return this.firstLines.length > 0 ? this.firstLines[0] : [];
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
    },
    methods: {
        getFormattedColumnsAssociations() {
            const data = [];

            Object.keys(this.columnsAssociation).forEach((key) => {
                if (this.columnsAssociation[key]) {
                    data.push({
                        index: key.replace("col-", ""),
                        column: this.columnsAssociation[key],
                    });
                }
            });

            return data;
        },
        hasMissingRequiredColumn() {
            const requiredColumns = this.columns.filter((c) => c.required);

            if (requiredColumns.length == 0) {
                return false;
            }

            return requiredColumns.some((column) => !Object.values(this.columnsAssociation).find((c) => c === column.value));
        },
        columnAssociationChanged(index, e) {
            this.$set(this.columnsAssociation, `col-${index}`, e.target.value);
        },
        isColumnAlreadyUsed(column, ignore_index) {
            return Object.keys(this.columnsAssociation).some((key) => key !== `col-${ignore_index}` && this.columnsAssociation[key] === column);
        },
        resetAssociations() {
            this.$set(this, "columnsAssociation", {});
            this.$set(this.errors, "form", null);
        },
        getExamples(index) {
            const examples = this.firstLines
                .map((line) => (line.length > index ? line[index] : null))
                .filter((v) => v !== "" && v !== null)
                .flat()
                .join(", ");

            return examples ? `(${examples})` : null;
        },
        post() {
            if (this.hasMissingRequiredColumn()) {
                this.missingRequiredColumn = true;
                return;
            }

            this.missingRequiredColumn = false;
            this.errors.form = null;

            this.emitSetData("loadingImport", true);

            const formData = new FormData();
            formData.append("file", this.file);
            formData.append("columns_associations", JSON.stringify(this.getFormattedColumnsAssociations()));

            this.httpPost(`/api/restaurants/${this.restaurantId}/clients/import/importFile`, formData, {
                config: {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            }).then((response) => {
                if (response !== false) {
                    this.$emit("import-done");
                } else {
                    this.emitSetData("loadingImport", false);

                    if (this.errors.form === null) {
                        this.errors.form = [this.$tl("errors.clients.import")];
                    }
                }
            });
        },
        emitSetData(key, value) {
            this.$emit("set-data", {
                key,
                value,
            });
        },
    },
    components: {
        ShowErrors,
    },
};
</script>
