var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading ? _c("loader-component") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading,
              expression: "!isLoading",
            },
          ],
          staticClass: "image-component-display pt-1 pr-1 pb-0 pl-1",
        },
        [
          _vm.currentLoadedImgUrl !== null
            ? _c("img", {
                staticClass: "img-fluid mb-1",
                attrs: { src: _vm.currentLoadedImgUrl },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", [
            _c(
              "label",
              {
                staticClass: "label-file mr-2",
                class: _vm.disabled ? ["text-muted", "cursor-d"] : [],
                attrs: { for: _vm.INPUT_ID },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.input.file !== null || _vm.uploadedImgUrl !== null
                        ? _vm.$tl("labels.form.image.chooseNew")
                        : _vm.$tl("labels.form.image.choose")
                    ) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c("input", {
              ref: "inputFile",
              staticClass: "input-file",
              attrs: {
                id: _vm.INPUT_ID,
                type: "file",
                disabled: _vm.disabled,
                accept: _vm.allowedExtensions,
              },
              on: { change: _vm.onInputChanged },
            }),
            _vm._v(" "),
            _vm.input.file !== null
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-circle btn-sm btn-danger",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.resetInput.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                        "\n            "
                    ),
                  ]
                )
              : _vm.uploadedImgPath !== null && _vm.deleteUrl !== null
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-circle btn-sm btn-danger",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.deleteImage.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.form.actions.delete")) +
                        "\n            "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.maxSize !== null
            ? _c("div", [
                _vm.isFileTooLarge
                  ? _c("small", { staticClass: "text-danger" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$tl("errors.common.image.tooBig", undefined, {
                            size: _vm.input.fileSizeMB,
                            maxSize: _vm.maxSize,
                          })
                        )
                      ),
                    ])
                  : _c("small", { staticClass: "text-warning" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$tl("infos.common.file.maxSize", undefined, {
                            maxSize: _vm.maxSize,
                          })
                        )
                      ),
                    ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("small", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.iloveimg.com/fr/compresser-image",
                        target: "_blank",
                      },
                    },
                    [
                      _c("feather", {
                        staticClass: "text-warning mr-1",
                        attrs: { type: "image" },
                      }),
                      _vm._v(
                        _vm._s(_vm.$tl("labels.form.image.compress")) +
                          "\n                "
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }