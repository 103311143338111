var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$tl(
                      "labels.booking.services.menusAndOptions.title",
                      _vm.restaurantId
                    )
                  )
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "menu-option mt-3" },
            [
              _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.services.menusAndOptions.link",
                          _vm.restaurantId
                        )
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "offset-1 col-11 mt-2" }, [
                  _vm.menus.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.menus, function (menu) {
                          return _c(
                            "div",
                            { key: menu.id, staticClass: "mb-2" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "container-box",
                                  staticStyle: {
                                    "margin-bottom": "0",
                                    width: "100%",
                                  },
                                },
                                [
                                  _c("input", {
                                    attrs: { type: "checkbox", name: "menu" },
                                    domProps: {
                                      checked:
                                        _vm.currentMenus.checkedMenus[menu.id],
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changedMenu($event, menu.id)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(menu.name) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.currentMenus.checkedMenus[menu.id]
                                ? _c("div", { staticClass: "mt-1" }, [
                                    _c("div", { staticClass: "row mb-0" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-5 offset-1" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "container-box",
                                              staticStyle: { width: "30px" },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.currentMenus
                                                        .checkedMenus[menu.id]
                                                        .enable_nb_min_pers,
                                                    expression:
                                                      "currentMenus.checkedMenus[menu.id].enable_nb_min_pers",
                                                  },
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  value: true,
                                                  checked: Array.isArray(
                                                    _vm.currentMenus
                                                      .checkedMenus[menu.id]
                                                      .enable_nb_min_pers
                                                  )
                                                    ? _vm._i(
                                                        _vm.currentMenus
                                                          .checkedMenus[menu.id]
                                                          .enable_nb_min_pers,
                                                        true
                                                      ) > -1
                                                    : _vm.currentMenus
                                                        .checkedMenus[menu.id]
                                                        .enable_nb_min_pers,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.currentMenus
                                                          .checkedMenus[menu.id]
                                                          .enable_nb_min_pers,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = true,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.currentMenus
                                                              .checkedMenus[
                                                              menu.id
                                                            ],
                                                            "enable_nb_min_pers",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.currentMenus
                                                              .checkedMenus[
                                                              menu.id
                                                            ],
                                                            "enable_nb_min_pers",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.currentMenus
                                                          .checkedMenus[
                                                          menu.id
                                                        ],
                                                        "enable_nb_min_pers",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                                staticStyle: {
                                                  "margin-top": "-2px",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("label", { staticClass: "pt-2" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.services.menusAndOptions.enableFrom",
                                                  _vm.restaurantId
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-6 d-flex align-items-center",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.currentMenus.checkedMenus[
                                                    menu.id
                                                  ].nb_min_pers,
                                                expression:
                                                  "currentMenus.checkedMenus[menu.id].nb_min_pers",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            staticStyle: { width: "30%" },
                                            attrs: {
                                              type: "number",
                                              step: "1",
                                              min: "0",
                                              name: "nb_pers_min",
                                              id: "nb_pers_min",
                                              disabled:
                                                !_vm.currentMenus.checkedMenus[
                                                  menu.id
                                                ].enable_nb_min_pers,
                                            },
                                            domProps: {
                                              value:
                                                _vm.currentMenus.checkedMenus[
                                                  menu.id
                                                ].nb_min_pers,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.currentMenus.checkedMenus[
                                                    menu.id
                                                  ],
                                                  "nb_min_pers",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            { staticClass: "m-0 p-0 ml-2" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.capitalize(
                                                    _vm.$tl("labels.persons")
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row mb-3" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-5 offset-1" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "container-box",
                                              staticStyle: { width: "30px" },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.currentMenus
                                                        .checkedMenus[menu.id]
                                                        .enable_nb_max_pers,
                                                    expression:
                                                      "currentMenus.checkedMenus[menu.id].enable_nb_max_pers",
                                                  },
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  value: true,
                                                  checked: Array.isArray(
                                                    _vm.currentMenus
                                                      .checkedMenus[menu.id]
                                                      .enable_nb_max_pers
                                                  )
                                                    ? _vm._i(
                                                        _vm.currentMenus
                                                          .checkedMenus[menu.id]
                                                          .enable_nb_max_pers,
                                                        true
                                                      ) > -1
                                                    : _vm.currentMenus
                                                        .checkedMenus[menu.id]
                                                        .enable_nb_max_pers,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.currentMenus
                                                          .checkedMenus[menu.id]
                                                          .enable_nb_max_pers,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = true,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.currentMenus
                                                              .checkedMenus[
                                                              menu.id
                                                            ],
                                                            "enable_nb_max_pers",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.currentMenus
                                                              .checkedMenus[
                                                              menu.id
                                                            ],
                                                            "enable_nb_max_pers",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.currentMenus
                                                          .checkedMenus[
                                                          menu.id
                                                        ],
                                                        "enable_nb_max_pers",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                                staticStyle: {
                                                  "margin-top": "-2px",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("label", { staticClass: "pt-2" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.services.menusAndOptions.disableFrom",
                                                  _vm.restaurantId
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-6 d-flex align-items-center",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.currentMenus.checkedMenus[
                                                    menu.id
                                                  ].nb_max_pers,
                                                expression:
                                                  "currentMenus.checkedMenus[menu.id].nb_max_pers",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            staticStyle: { width: "30%" },
                                            attrs: {
                                              type: "number",
                                              step: "1",
                                              min: "0",
                                              name: "nb_pers_max",
                                              id: "nb_pers_max",
                                              disabled:
                                                !_vm.currentMenus.checkedMenus[
                                                  menu.id
                                                ].enable_nb_max_pers,
                                            },
                                            domProps: {
                                              value:
                                                _vm.currentMenus.checkedMenus[
                                                  menu.id
                                                ].nb_max_pers,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.currentMenus.checkedMenus[
                                                    menu.id
                                                  ],
                                                  "nb_max_pers",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            { staticClass: "m-0 p-0 ml-2" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.capitalize(
                                                    _vm.$tl("labels.persons")
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-4 mb-4" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success btn-circle btn-sm",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.showAddMenuModal = true
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$tl(
                                "labels.booking.menus.add",
                                _vm.restaurantId
                              )
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              !_vm.$_.isEmpty(_vm.currentMenus.checkedMenus)
                ? [
                    _c("div", { staticClass: "row mb-0" }, [
                      _c("div", { staticClass: "col-5" }, [
                        _c(
                          "label",
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.menus.mandatory",
                                    _vm.restaurantId
                                  )
                                ) +
                                "\n                                "
                            ),
                            _c("feather", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.getTooltipNotice(
                                    "notices.booking.servicesConfig.mandatoryMenu"
                                  ),
                                  expression:
                                    "getTooltipNotice('notices.booking.servicesConfig.mandatoryMenu')",
                                },
                              ],
                              staticClass: "ml-1 pointer tooltip-infos",
                              attrs: { type: "info" },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-7" }, [
                        _c(
                          "label",
                          {
                            staticClass: "container-box",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentMenus.isMandatory,
                                  expression: "currentMenus.isMandatory",
                                },
                              ],
                              attrs: { type: "checkbox", name: "menu_unique" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentMenus.isMandatory
                                )
                                  ? _vm._i(_vm.currentMenus.isMandatory, null) >
                                    -1
                                  : _vm.currentMenus.isMandatory,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.currentMenus.isMandatory,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentMenus,
                                          "isMandatory",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentMenus,
                                          "isMandatory",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentMenus,
                                      "isMandatory",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$tl("labels.yes")) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.currentMenus.isMandatory
                      ? _c("div", { staticClass: "row mb-4" }, [
                          _c("div", { staticClass: "col-5 pt-2" }, [
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.menus.mandatoryFrom",
                                    _vm.restaurantId
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-7" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-4" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.currentMenus
                                          .nbPersMandatoryAbovePax,
                                      expression:
                                        "currentMenus.nbPersMandatoryAbovePax",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    name: "menu_unique_from",
                                    min: "1",
                                    step: "1",
                                  },
                                  domProps: {
                                    value:
                                      _vm.currentMenus.nbPersMandatoryAbovePax,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.currentMenus,
                                        "nbPersMandatoryAbovePax",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-8 mt-2" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$tl("labels.personss")) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-0" }, [
                      _c("div", { staticClass: "col-5" }, [
                        _c(
                          "label",
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.menus.unique",
                                    _vm.restaurantId
                                  )
                                ) +
                                "\n                                "
                            ),
                            _c("feather", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.getTooltipNoticeLexicon(
                                    "notices.booking.servicesConfig.uniqueMenu",
                                    _vm.restaurantId
                                  ),
                                  expression:
                                    "getTooltipNoticeLexicon('notices.booking.servicesConfig.uniqueMenu', restaurantId)",
                                },
                              ],
                              staticClass: "ml-1 pointer tooltip-infos",
                              attrs: { type: "info" },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-7" }, [
                        _c(
                          "label",
                          {
                            staticClass: "container-box",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentMenus.config.isUnique,
                                  expression: "currentMenus.config.isUnique",
                                },
                              ],
                              attrs: { type: "checkbox", name: "menu_unique" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.currentMenus.config.isUnique
                                )
                                  ? _vm._i(
                                      _vm.currentMenus.config.isUnique,
                                      null
                                    ) > -1
                                  : _vm.currentMenus.config.isUnique,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.currentMenus.config.isUnique,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentMenus.config,
                                          "isUnique",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentMenus.config,
                                          "isUnique",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentMenus.config,
                                      "isUnique",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$tl("labels.yes")) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.currentMenus.config.isUnique
                      ? _c("div", { staticClass: "row mb-4" }, [
                          _c("div", { staticClass: "col-5 pt-2" }, [
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.menus.uniqueFrom",
                                    _vm.restaurantId
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-7" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-4" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.currentMenus.config
                                          .nbPersUniqueAbovePax,
                                      expression:
                                        "currentMenus.config.nbPersUniqueAbovePax",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    name: "menu_unique_from",
                                    min: "1",
                                    step: "1",
                                  },
                                  domProps: {
                                    value:
                                      _vm.currentMenus.config
                                        .nbPersUniqueAbovePax,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.currentMenus.config,
                                        "nbPersUniqueAbovePax",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-8 mt-2" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$tl("labels.personss")) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "sepa" }),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-4" }, [
                _c("div", { staticClass: "col-3" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$tl("labels.booking.services.generalOptions.link")
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-9" }, [
                  _vm.generalOptions.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.generalOptions, function (option) {
                          return _c(
                            "div",
                            { key: option.id, staticClass: "mb-2" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "container-box",
                                  staticStyle: {
                                    "margin-bottom": "0",
                                    width: "100%",
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.checkedGeneralOptions,
                                        expression: "checkedGeneralOptions",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      name: "general_options_selected[]",
                                    },
                                    domProps: {
                                      value: option.id,
                                      checked: Array.isArray(
                                        _vm.checkedGeneralOptions
                                      )
                                        ? _vm._i(
                                            _vm.checkedGeneralOptions,
                                            option.id
                                          ) > -1
                                        : _vm.checkedGeneralOptions,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.checkedGeneralOptions,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = option.id,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.checkedGeneralOptions =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.checkedGeneralOptions = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.checkedGeneralOptions = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(option.name) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-4 mb-4" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success btn-circle btn-sm",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.showAddGeneralOptionModal = true
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$tl("labels.booking.generalOptions.add")
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.showAddMenuModal
        ? _c("add-menu-modal", {
            on: {
              "menu-added": function ($event) {
                return _vm.$emit("fetch-menus")
              },
              close: function ($event) {
                _vm.showAddMenuModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddGeneralOptionModal
        ? _c("add-menu-option-modal", {
            attrs: { "option-type": _vm.MENU_OPTION_GENERAL.value },
            on: {
              "menu-option-added": function ($event) {
                return _vm.$emit("fetch-options")
              },
              close: function ($event) {
                _vm.showAddGeneralOptionModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }