<template>
    <div class="pt-3">
        <LoaderComponent v-if="loading" />
        <template v-else>
            <div class="row">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="row mb-3">
                            <div class="col-12">
                                <strong>{{ $tl("labels.contactInfos") }}</strong>
                            </div>
                        </div>

                        <InputComponent
                            v-model="contact.company"
                            :disabled="disabled"
                            required
                            :label="$tl('labels.form.businessName')"
                            input-name="company"
                            :form-errors="formErrors" />

                        <InputComponent
                            v-model="contact.siret"
                            :disabled="disabled"
                            required
                            :label="$tl('labels.form.facturation.siret')"
                            input-name="company_siret"
                            :form-errors="formErrors" />

                        <InputComponent
                            v-model="contact.address"
                            :disabled="disabled"
                            required
                            :label="$tl('labels.form.address')"
                            input-name="company_address"
                            :form-errors="formErrors" />

                        <InputComponent
                            v-model="contact.zipcode"
                            :disabled="disabled"
                            required
                            :label="$tl('labels.form.postalCode')"
                            input-name="company_zipcode"
                            :form-errors="formErrors" />

                        <InputComponent
                            v-model="contact.city"
                            :disabled="disabled"
                            required
                            :label="$tl('labels.form.city')"
                            input-name="company_city"
                            :form-errors="formErrors" />

                        <InputComponent
                            v-model="contact.country"
                            :disabled="disabled"
                            required
                            :label="$tl('labels.form.country')"
                            input-name="company_country"
                            :form-errors="formErrors" />

                        <div class="row mb-2">
                            <div class="col-md-5 pt-2">
                                <label>{{ $tl("labels.form.phoneNumber") }} <small>*</small></label>
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-12">
                                        <TelInputComponent
                                            v-model="contact.tel"
                                            :disabled="disabled"
                                            input-name="company_tel"
                                            :placeholder="$tl('labels.form.phoneNumber')"
                                            :form-errors="formErrors" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <InputComponent
                            v-model="contact.email"
                            :disabled="disabled"
                            required
                            inputType="email"
                            :label="$tl('labels.form.email')"
                            input-name="company_email"
                            :form-errors="formErrors" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="row mb-3">
                            <div class="col-12">
                                <strong>{{ $tl("labels.mediatorContact") }}</strong>
                            </div>
                        </div>

                        <InputComponent
                            v-model="mediator.name"
                            :disabled="disabled"
                            required
                            :label="$tl('labels.form.fullname')"
                            input-name="mediator"
                            :form-errors="formErrors" />

                        <InputComponent
                            v-model="mediator.address"
                            :disabled="disabled"
                            required
                            :label="$tl('labels.form.address')"
                            input-name="mediator_address"
                            :form-errors="formErrors" />

                        <InputComponent
                            v-model="mediator.zipcode"
                            :disabled="disabled"
                            required
                            :label="$tl('labels.form.postalCode')"
                            input-name="mediator_zipcode"
                            :form-errors="formErrors" />

                        <InputComponent
                            v-model="mediator.city"
                            :disabled="disabled"
                            required
                            :label="$tl('labels.form.city')"
                            input-name="mediator_city"
                            :form-errors="formErrors" />

                        <InputComponent
                            v-model="mediator.country"
                            :disabled="disabled"
                            required
                            :label="$tl('labels.form.country')"
                            input-name="mediator_country"
                            :form-errors="formErrors" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="row mb-3">
                            <div class="col-12">
                                <strong>{{ $tl("labels.form.facturation.titleEstablishment") }}</strong>
                                <feather
                                    v-if="isDevelopement()"
                                    v-tooltip="'Affichées sur les factures émises par l\'établissement'"
                                    type="info"
                                    class="ml-1 pointer tooltip-infos" />
                            </div>
                        </div>

                        <InputComponent
                            v-model="facturation.siret"
                            :disabled="disabled"
                            required
                            minlength="14"
                            maxlength="14"
                            :label="$tl('labels.form.facturation.siret')"
                            input-name="siret"
                            :form-errors="formErrors" />

                        <InputComponent
                            v-model="facturation.code_naf"
                            :disabled="disabled"
                            required
                            :label="$tl('labels.form.facturation.nafCode')"
                            input-name="code_naf"
                            :form-errors="formErrors" />

                        <InputComponent
                            v-if="!isTvaDisabled"
                            v-model="facturation.code_tva"
                            :disabled="disabled"
                            required
                            :label="$tl('labels.form.facturation.tvaNumber')"
                            input-name="code_tva"
                            :form-errors="formErrors" />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import ShowErrors from "../../../components/errors/ShowErrors.vue";
import FileUploaderComponent from "../../../components/forms/FileUploaderComponent.vue";
import InputComponent from "../../../components/forms/InputComponent.vue";
import TelInputComponent from "../../forms/TelInputComponent.vue";
import RolesEnum from "../../../mixins/enums/RolesEnum.js";

export default {
    data() {
        return {
            loading: false,
            contact: {
                company: "",
                siret: "",
                address: "",
                zipcode: "",
                city: "",
                country: "",
                tel: "",
                email: "",
            },
            mediator: {
                name: "",
                address: "",
                zipcode: "",
                city: "",
                country: "",
            },
            facturation: {
                code_tva: "",
                siret: "",
                code_naf: "",
            },
        };
    },
    mixins: [ModuleTypesEnum, RolesEnum],
    props: {
        formErrors: {
            default: null,
        },
        isTvaDisabledProp: {
            default: undefined,
        },
        restaurant_id: {
            default: null,
        },
        ownerId: {
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isTvaDisabled() {
            return typeof this.isTvaDisabledProp !== "undefined"
                ? this.isTvaDisabledProp
                : this.$store.getters["restaurants/isTvaDisabled"](this.restaurant_id);
        },
        storeCgv() {
            return this.$store.getters["restaurants/getCGV"](this.restaurant_id);
        },
        storeContact() {
            return this.storeCgv !== null ? this.storeCgv.contact : null;
        },
        storeMediator() {
            return this.storeCgv !== null ? this.storeCgv.mediator : null;
        },
        storeFacturation() {
            return this.storeCgv !== null ? this.storeCgv.facturation : null;
        },
        userRole() {
            return this.$store.getters["users/role"];
        },
    },
    methods: {
        prefillAllFromApi() {
            this.loading = true;

            this.httpGet(`/api/admin/owners/${this.ownerId}`)
                .then((response) => {
                    if (response !== false) {
                        this.contact.company = response.data.company || "";
                        this.contact.siret = response.data.company_siret || "";
                        this.contact.address = response.data.company_address || "";
                        this.contact.zipcode = response.data.company_zipcode || "";
                        this.contact.city = response.data.company_city || "";
                        this.contact.country = response.data.company_country || "";
                        this.contact.tel = response.data.company_tel || response.data.tel || "";
                        this.contact.email = response.data.company_email || response.data.email || "";
                        this.mediator.name = `${response.data.firstname} ${response.data.lastname}`;
                        this.mediator.address = response.data.company_address || "";
                        this.mediator.zipcode = response.data.company_zipcode || "";
                        this.mediator.city = response.data.company_city || "";
                        this.mediator.country = response.data.company_country || "";
                        this.facturation.code_naf = response.data.company_code_naf || "";
                        this.facturation.code_tva = response.data.company_code_tva || "";
                        this.facturation.siret = response.data.company_siret || "";
                    }
                })
                .finally(() => (this.loading = false));
        },
        prefillContact() {
            if (this.storeContact !== null) {
                this.contact = this.$_.cloneDeep(this.storeContact);
            } else {
                this.contact.company = this.$store.getters["users/company"];
                this.contact.siret = this.$store.getters["users/companySiret"];
                this.contact.address = this.$store.getters["users/companyAddress"];
                this.contact.zipcode = this.$store.getters["users/companyZipcode"];
                this.contact.city = this.$store.getters["users/companyCity"];
                this.contact.country = this.$store.getters["users/companyCountry"];
                this.contact.tel = this.$store.getters["users/companyTel"] || this.$store.getters["users/tel"];
                this.contact.email = this.$store.getters["users/companyEmail"] || this.$store.getters["users/email"];
            }
        },
        prefillMediator() {
            if (this.storeMediator !== null) {
                this.mediator = this.$_.cloneDeep(this.storeMediator);
            } else {
                this.mediator.name = `${this.$store.getters["users/firstName"]} ${this.$store.getters["users/lastName"]}`;
                this.mediator.address = this.$store.getters["users/companyAddress"];
                this.mediator.zipcode = this.$store.getters["users/companyZipcode"];
                this.mediator.city = this.$store.getters["users/companyCity"];
                this.mediator.country = this.$store.getters["users/companyCountry"];
            }
        },
        prefillFacturation() {
            if (this.storeFacturation !== null) {
                this.facturation = this.$_.cloneDeep(this.storeFacturation);
            } else {
                this.facturation.siret = this.$store.getters["users/companySiret"];
                this.facturation.code_naf = this.$store.getters["users/companyCodeNaf"];
                this.facturation.code_tva = this.$store.getters["users/companyCodeTva"];
            }
        },
        getParams() {
            return {
                company: this.contact.company || "",
                company_siret: this.contact.siret || "",
                company_address: this.contact.address || "",
                company_zipcode: this.contact.zipcode || "",
                company_city: this.contact.city || "",
                company_country: this.contact.country || "",
                company_tel: this.contact.tel || "",
                company_email: this.contact.email || "",
                mediator: this.mediator.name || "",
                mediator_address: this.mediator.address || "",
                mediator_zipcode: this.mediator.zipcode || "",
                mediator_city: this.mediator.city || "",
                mediator_country: this.mediator.country || "",
                code_naf: this.facturation.code_naf || "",
                code_tva: this.facturation.code_tva || "",
                siret: this.facturation.siret || "",
            };
        },
        setParam(obj, key, value) {
            this.$set(this[obj], key, value);
        },
    },
    created() {
        if (this.ownerId !== null && this.userRole === this.ROLE_ADMIN.value) {
            this.prefillAllFromApi();
        } else {
            this.prefillContact();
            this.prefillMediator();
            this.prefillFacturation();
        }
    },
    components: {
        LoaderComponent,
        ShowErrors,
        FileUploaderComponent,
        InputComponent,
        TelInputComponent,
    },
};
</script>
