export default {
    data() {
        return {
            RESERVATION_MAIL_LOG_CONFIRMATION: {
                label: "de confirmation",
                value: "confirmation",
            },
            RESERVATION_MAIL_LOG_CONFIRMATION_FOOTPRINT: {
                label: "de confirmation (information empreinte bancaire)",
                value: "confirmation_footprint",
            },
            RESERVATION_MAIL_LOG_ASK_CONFIRMATION: {
                label: "de demande de confirmation",
                value: "ask_confirmation",
            },
            RESERVATION_MAIL_LOG_CANCELED: {
                label: "d'annulation",
                value: "canceled",
            },
            RESERVATION_MAIL_LOG_CANCELED_FOOTPRINT: {
                label: "d'annulation (empreinte bancaire non reçue)",
                value: "canceled_footprint",
            },
            RESERVATION_MAIL_LOG_CANCELED_PAYMENT: {
                label: "d'annulation (prépaiement non reçu)",
                value: "canceled_payment",
            },
            RESERVATION_MAIL_LOG_CANCELED_GIFT_VOUCHER: {
                label: "d'annulation",
                value: "canceled_gift_voucher",
            },
            RESERVATION_MAIL_LOG_REMINDER: {
                label: "de rappel",
                value: "reminder",
            },
            RESERVATION_MAIL_LOG_THANKS: {
                label: "de remerciement",
                value: "thanks",
            },
            RESERVATION_MAIL_LOG_CREATED: {
                label: "de réservation en attente",
                value: "created",
            },
            RESERVATION_MAIL_LOG_REFUSED: {
                label: "de réservation refusée",
                value: "refused",
            },
            RESERVATION_MAIL_LOG_ASK_FOOTPRINT: {
                label: "de demande d'empreinte bancaire",
                value: "ask_footprint",
            },
            RESERVATION_MAIL_LOG_ASK_PAYMENT: {
                label: "de demande de prépaiement",
                value: "ask_payment",
            },
            RESERVATION_MAIL_LOG_NOSHOW_TOTAL: {
                label: "de no-show (prélèvement total)",
                value: "noshow_total",
            },
            RESERVATION_MAIL_LOG_NOSHOW_PARTIAL: {
                label: "de no-show (prélèvement partiel)",
                value: "noshow_partial",
            },
            RESERVATION_MAIL_LOG_NOSHOW_FREE: {
                label: "de no-show (sans prélèvement)",
                value: "noshow_free",
            },
        };
    },
    methods: {
        getReservationMailLogLabel(value, restaurant_id = undefined) {
            const found = this.ALL_MAIL_LOGS_TYPES.find((w) => w.value === value);

            return typeof found !== "undefined" ? this.$tl(`labels.booking.reservations.mailLogs.types.${value}`, restaurant_id) : value;
        },
    },
    computed: {
        ALL_MAIL_LOGS_TYPES() {
            return [
                this.RESERVATION_MAIL_LOG_CONFIRMATION,
                this.RESERVATION_MAIL_LOG_CONFIRMATION_FOOTPRINT,
                this.RESERVATION_MAIL_LOG_ASK_CONFIRMATION,
                this.RESERVATION_MAIL_LOG_CANCELED,
                this.RESERVATION_MAIL_LOG_CANCELED_FOOTPRINT,
                this.RESERVATION_MAIL_LOG_CANCELED_PAYMENT,
                this.RESERVATION_MAIL_LOG_CANCELED_GIFT_VOUCHER,
                this.RESERVATION_MAIL_LOG_REMINDER,
                this.RESERVATION_MAIL_LOG_THANKS,
                this.RESERVATION_MAIL_LOG_CREATED,
                this.RESERVATION_MAIL_LOG_REFUSED,
                this.RESERVATION_MAIL_LOG_ASK_FOOTPRINT,
                this.RESERVATION_MAIL_LOG_ASK_PAYMENT,
                this.RESERVATION_MAIL_LOG_NOSHOW_TOTAL,
                this.RESERVATION_MAIL_LOG_NOSHOW_PARTIAL,
                this.RESERVATION_MAIL_LOG_NOSHOW_FREE,
            ];
        },
    },
};
