var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Importer des numéros de chambre"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.loading || _vm.loadingImport
          ? _c("LoaderComponent", {
              attrs: {
                message: _vm.loadingImport ? "Import en cours ..." : undefined,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading && !_vm.loadingImport,
                expression: "!loading && !loadingImport",
              },
            ],
            staticClass: "row",
          },
          [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("p", [
                  _vm._v(
                    "\n                    Les formats de fichiers acceptés sont " +
                      _vm._s(_vm.formattedAllowedExtensions) +
                      "."
                  ),
                  _c("br"),
                  _vm._v(
                    '\n                    Pour les fichiers ".csv", le delimiteur doit être le point-virgule (";") et posséder le format\n                    UTF-8.'
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                    Attention : le fichier importé "
                  ),
                  _c("strong", [_vm._v("ne doit pas comporter d'entête")]),
                  _vm._v(".\n                "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.allowedExtensions, function (ext) {
                    return _c(
                      "a",
                      {
                        key: ext,
                        staticClass: "d-block",
                        attrs: {
                          href: `/assets/room_numbers${ext}`,
                          download: `room_numbers${ext}`,
                        },
                      },
                      [
                        _c("small", [
                          _vm._v('Exemple au format "' + _vm._s(ext) + '"'),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    id: "fileInput",
                    type: "file",
                    accept: _vm.allowedExtensions,
                  },
                  on: { change: _vm.fileInputChanged },
                }),
                _vm._v(" "),
                _c("ShowErrors", {
                  staticClass: "alert alert-danger position-relative",
                  staticStyle: { top: "0" },
                  attrs: {
                    errClass: "d-block",
                    errors: _vm.errors,
                    errorKey: "*",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            on: { click: _vm.close },
          },
          [_vm._v("Fermer")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-success btn-circle ml-2",
            class: { disabled: _vm.btnDisabled },
            attrs: { disabled: _vm.btnDisabled },
            on: { click: _vm.importFile },
          },
          [_vm._v("\n            Importer\n        ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }