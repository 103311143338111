var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-inline-block" },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-danger btn-circle",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.showEventsForDay()
            },
          },
        },
        [
          _c("feather", {
            staticClass: "ml-1",
            attrs: { type: "plus" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.showEventsForDay(true)
              },
            },
          }),
          _vm._v(" "),
          _c("feather", { staticClass: "mx-1", attrs: { type: "volume-2" } }),
          _vm._v(
            "\n        " +
              _vm._s(_vm.$tl("labels.booking.customEvents.titleForDay")) +
              " (" +
              _vm._s(_vm.eventsForDay.length) +
              ")\n    "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "list-custom-events",
        _vm._b(
          { ref: "listCustomEvents" },
          "list-custom-events",
          { restaurantId: _vm.restaurantId, date: _vm.date.toISODate() },
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }