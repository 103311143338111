<template>
    <div class="d-inline">
        <div
            class="d-inline-block text-center dropdown show"
            v-tooltip="{ delay: { show: 400, hide: 0 }, content: tooltip }">
            <button
                type="button"
                aria-haspopup="true"
                aria-expanded="false"
                class="btn btn-sm drop-icon-card"
                :class="{ 'dropdown-toggle': !hasExpired }"
                :data-toggle="!hasExpired ? 'dropdown' : ''"
                :disabled="hasExpired">
                <feather type="dollar-sign" :class="iconClasses"> </feather>
            </button>
            <div class="dropdown-menu shadow">
                <button
                    href="javascript:"
                    class="dropdown-item"
                    @click="sendAgain"
                    v-if="
                        command.type === COMMAND_TYPE_BO.value &&
                        command.bo_payment_type === BO_PAYMENT_TYPE_ONLINE.value
                    ">
                    Renvoyer la demande
                </button>
                <button
                    href="javascript:"
                    class="dropdown-item"
                    @click="payManually"
                    v-if="command.type === COMMAND_TYPE_BO.value">
                    Marquer comme payée sur place
                </button>
            </div>
        </div>
        <cc-command-pay-manually-modal
            v-if="showPayManuallyModal"
            :is_online="command.bo_payment_type == BO_PAYMENT_TYPE_ONLINE.value"
            :errors="errorsModal"
            ref="payManuallyModal"
            @close="showPayManuallyModal = false"
            @pay="payCommand($event, true)" />
    </div>
</template>

<script>
import moment from "moment";
import CcCommandPayManuallyModal from "../../../Modals/clickAndCollect/Commands/PayManuallyModal";
import CommandStatusEnum from "../../../../mixins/enums/click_and_collect/CommandStatusEnum";
import PaymentStatusEnum from "../../../../mixins/enums/click_and_collect/PaymentStatusEnum";
import BoPaymentTypeEnum from "../../../../mixins/enums/click_and_collect/BoPaymentTypeEnum";
import CommandTypeEnum from "../../../../mixins/enums/click_and_collect/CommandTypeEnum";

moment.locale("fr");

export default {
    name: "CcCommandPaymentStatusIcon",
    components: { CcCommandPayManuallyModal },
    props: {
        command: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showPayManuallyModal: false,
            errorsModal: null,
        };
    },
    mixins: [PaymentStatusEnum, BoPaymentTypeEnum, CommandTypeEnum, CommandStatusEnum],
    computed: {
        tooltip() {
            if (this.command.type == "widget" || this.command.bo_payment_type === this.BO_PAYMENT_TYPE_ONLINE.value) {
                switch (this.command.payment_status) {
                    case this.PAYMENT_PENDING.value:
                        return "En attente de paiement.";
                    case this.PAYMENT_PAID.value:
                    case this.PAYMENT_CAPTURED.value:
                    case this.PAYMENT_CAPTURABLE.value:
                        return "Paiement effectué.";
                    case this.PAYMENT_REFUNDED.value:
                        return "Paiement remboursé.";
                    case this.PAYMENT_CANCELED.value:
                        return "Paiement annulé.";
                    case this.PAYMENT_PARTIALLY_REFUNDED.value:
                        return "Paiement remboursé partiellement.";
                    default:
                        return "";
                }
            } else {
                switch (this.command.payment_status) {
                    case this.PAYMENT_PENDING.value:
                        return "À payer sur place";
                    case this.PAYMENT_PAID.value:
                    case this.PAYMENT_CAPTURED.value:
                    case this.PAYMENT_CAPTURABLE.value:
                        return "Payée sur place";
                    case this.PAYMENT_REFUNDED.value:
                        return "Remboursé";
                    case this.PAYMENT_CANCELED.value:
                        return "Paiement annulé.";
                    default:
                        return "";
                }
            }
        },
        iconClasses() {
            switch (this.command.payment_status) {
                case this.PAYMENT_PENDING.value:
                    return "text-warning";
                case this.PAYMENT_PAID.value:
                case this.PAYMENT_CAPTURED.value:
                case this.PAYMENT_CAPTURABLE.value:
                    return "text-success";
                case this.PAYMENT_REFUNDED.value:
                case this.PAYMENT_PARTIALLY_REFUNDED.value:
                case this.PAYMENT_CANCELED.value:
                    return "text-secondary";
                default:
                    break;
            }
        },
        hasExpired() {
            let commandStatus = [this.COMMAND_CANCELED.value];
            let paymentStatus = [
                this.PAYMENT_PAID.value,
                this.PAYMENT_CAPTURABLE.value,
                this.PAYMENT_CAPTURED.value,
                this.PAYMENT_REFUNDED.value,
                this.PAYMENT_CANCELED.value,
                this.PAYMENT_PARTIALLY_REFUNDED.value,
            ];
            if (
                commandStatus.includes(this.command.status) ||
                (this.command.command_payments.data.length > 0 &&
                    this.command.is_paid &&
                    this.command.command_payments.data.every((payment) => paymentStatus.includes(payment.status)))
            )
                return true;

            return false;
        },
    },
    methods: {
        sendAgain() {
            var confirmMessage = "Êtes-vous sûr de vouloir renvoyer la demande de paiement ?";

            if (confirm(confirmMessage)) {
                this.$store
                    .dispatch("ccCommands/sendPaymentAgain", {
                        restaurant_id: this.command.restaurant_id,
                        command_id: this.command.id,
                    })
                    .then((response) => {
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: "Demande renvoyée",
                        });
                    })
                    .catch((error) => {
                        this.$notify({
                            group: "notification",
                            type: "warn",
                            title: "La demande n'a pas pu être renvoyée",
                        });
                    });
            }
        },
        payCommand(methods, from_modal = false) {
            if (from_modal) this.$refs.payManuallyModal.startProcessing();
            let formattedMethods = methods.map((method) => {
                let obj = {
                    ...method,
                };
                if (method.amount) obj.amount = Number.parseFloat(method.amount).toFixed(2) * 100;
                return obj;
            });
            this.$store
                .dispatch("ccCommands/payManually", {
                    restaurant_id: this.command.restaurant_id,
                    command_id: this.command.id,
                    params: { methods: formattedMethods },
                })
                .then((response) => {
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: "La commande a été payée.",
                    });
                    this.showPayManuallyModal = false;
                })
                .catch((error) => {
                    if (from_modal) this.$refs.payManuallyModal.stopProcessing();
                    let errorMessage = null;

                    if (error.response && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else if (error.message) {
                        errorMessage = error.message;
                    } else {
                        errorMessage = "Une erreur est survenue.";
                    }

                    if (error.response) this.errorsModal = error.response.data.errors;

                    this.$notify({
                        group: "notification",
                        type: "warn",
                        title: errorMessage,
                    });
                });
        },
        payManually() {
            if (
                this.command.bo_payment_type == this.BO_PAYMENT_TYPE_ONLINE.value ||
                !this.command.command_payments.data[0].method
            ) {
                this.showPayManuallyModal = true;
            } else {
                this.payCommand(this.command.command_payments.data[0].method);
            }
        },
    },
};
</script>
