var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.sale.logs && _vm.sale.logs.data && _vm.sale.logs.data.length > 0
      ? _c(
          "div",
          { staticClass: "mt-3" },
          _vm._l(_vm.sale.logs.data, function (log) {
            return _c("ShowSaleLog", { key: log.id, attrs: { log: log } })
          }),
          1
        )
      : _c("span", { staticClass: "text-muted mt-3 d-inline-block" }, [
          _vm._v("Aucune modification n'a été enregistrée pour cette vente."),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }