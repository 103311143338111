<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.clients.merge.multiple") }}</h5>
        <div slot="body">
            <search-and-select-client @client-selected="$emit('selected-client', $event)" />
        </div>
    </modal>
</template>
<script>
import SearchAndSelectClient from "../../Default/Clients/SearchAndSelectClient";

export default {
    components: {
        SearchAndSelectClient,
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        selectClient(event) {
            this.$emit("associate-client", event);
        },
    },
};
</script>
