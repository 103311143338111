<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="row">
            <div class="col-12">
                <div class="border-light b-radius-20 p-4">
                    <div class="row mb-2">
                        <div class="col-5 pt-2">
                            <label for="name">Email <small>*</small></label>
                        </div>
                        <div class="col-7">
                            <input type="email" v-model="mail" name="mail" id="mail" required class="form-control" />
                        </div>
                        <show-errors :errors="errors" errorKey="mail" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ShowErrors from "../../components/errors/ShowErrors";
import LoaderComponent from "../../components/LoaderComponent";

export default {
    data() {
        return {
            loading: false,
        };
    },
    props: {
        pmail: {
            default: null,
        },
        errors: {
            required: true,
        },
    },
    computed: {
        mail: {
            get() {
                return this.pmail;
            },
            set(value) {
                this.$emit("update-mail", value);
            },
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
};
</script>
