<template>
    <div>
        <div v-if="error" class="alert alert-danger">
            {{ error }}
        </div>
        <div v-else>
            <div v-if="formErrors && formErrors.message" class="alert alert-danger">
                <p>{{ $tl("errors.common.unknown") }}</p>
            </div>
            <div v-if="formErrors && formErrors.max_pax" class="alert alert-danger">
                <p>{{ formErrors.max_pax }}</p>
            </div>
            <div v-if="seatingPlanError || vueMultiSelect.isInvalid" class="alert alert-danger">
                <p v-if="seatingPlanError" v-html="seatingPlanError"></p>
                <p v-if="vueMultiSelect.isInvalid">{{ $tl("labels.booking.reservations.edit.tableNotAvailable", restaurant_id) }}</p>
            </div>
            <div v-if="formSuccess" class="alert alert-success">
                {{ formSuccess }}
            </div>
            <div v-if="isImmutable" class="mb-2">
                <i>{{ $tl("infos.booking.reservations.immutable", undefined, { theme: themeUppercased }) }}</i>
            </div>

            <LoaderComponent v-if="formLoading" />
            <form v-else id="editReservation" @submit="postForm" method="post" autocomplete="off">
                <div class="row m-0">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <LoaderComponent v-if="loading.services" />
                            <div v-else>
                                <services-reservations
                                    ref="servicesReservations"
                                    @update-reservation-date="handleReservationDate"
                                    @update-nb-pers="handleNbPers"
                                    @update-nb-children="handleNbChildren"
                                    :formErrors="formErrors"
                                    :nb_pers="nb_pers"
                                    :nb_children="nb_children"
                                    :restaurant_id="selected_restaurant_id"
                                    :reservation_date="reservation_date"
                                    :readOnly="loading.service || loading.tables || isImmutable"
                                    :hasTablesSelected="hasTablesSelected"
                                    @unlink-all-tables="unlinkAllTables" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mt-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <LoaderComponent v-if="loading.slots" />
                            <div v-else>
                                <div v-if="openServicesError">
                                    <div class="invalid-feedback d-block">
                                        {{ openServicesError }}
                                    </div>
                                </div>
                                <div class="row" v-if="openServices && openServices.length > 0">
                                    <div class="col-12">
                                        <ul v-if="openServices && openServices.length > 0" style="margin-left: -40px">
                                            <li class="list-slot-resa" style="float: left; width: 32%" v-for="service in openServices">
                                                <slots-reservations
                                                    :reservation_date="reservation_date"
                                                    :readOnly="loading.service || loading.tables || isImmutable"
                                                    :hasTablesSelected="hasTablesSelected"
                                                    ref="slotsReservations"
                                                    @update-slot-id="handleSlotId"
                                                    :formErrors="formErrors"
                                                    :service_id="service"
                                                    :restaurant_id="selected_restaurant_id"
                                                    :slot_id="slot_id"
                                                    :preselected_service_id="old_service_id"
                                                    :preselected_slot_hour="old_slot_hour"
                                                    @unlink-all-tables="unlinkAllTables" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-12 mt-3">
                                    <div class="row m-0">
                                        <div
                                            class="col-12 p-0 text-right"
                                            v-if="restaurants_count && restaurants_count > 1 && selected_destination_restaurant === null">
                                            <div
                                                class="d-inline-block"
                                                v-tooltip="
                                                    hasTablesSelected
                                                        ? {
                                                              content: $tl('infos.booking.reservations.edit.changeRestaurant'),
                                                          }
                                                        : undefined
                                                ">
                                                <button
                                                    :disabled="option_bank || hasTablesSelected || isImmutable"
                                                    type="button"
                                                    class="btn btn-sm btn-success btn-circle"
                                                    @click="showSelectRestaurantModal = true">
                                                    <svg
                                                        viewBox="0 0 24 24"
                                                        width="24"
                                                        height="24"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        class="feather css-i6dzq1">
                                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                                        <polyline points="16 17 21 12 16 7"></polyline>
                                                        <line x1="21" y1="12" x2="9" y2="12"></line>
                                                    </svg>
                                                    {{ $tl("labels.booking.reservations.edit.changeRestaurant", restaurant_id) }}
                                                </button>
                                            </div>
                                            <small v-if="option_bank" class="d-block text-danger">
                                                {{ $tl("infos.booking.reservations.edit.cantChangeRestaurant") }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="row" v-if="selected_destination_restaurant">
                                        <div class="col-12 d-flex justify-content-between">
                                            <h6 style="color: #30a4b7" class="pt-1">
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather css-i6dzq1">
                                                    <polyline points="15 10 20 15 15 20"></polyline>
                                                    <path d="M4 4v7a4 4 0 0 0 4 4h12"></path>
                                                </svg>
                                                {{ selected_destination_restaurant.name }}
                                            </h6>
                                            <div>
                                                <button
                                                    type="button"
                                                    :disabled="hasTablesSelected || isImmutable"
                                                    class="btn btn-sm btn-success btn-circle"
                                                    @click="goBackToBaseRestaurant()">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        class="feather feather-arrow-left">
                                                        <line x1="19" y1="12" x2="5" y2="12"></line>
                                                        <polyline points="12 19 5 12 12 5"></polyline>
                                                    </svg>
                                                    {{
                                                        $t("labels.backToRestaurant", {
                                                            restaurant: baseRestaurant.name,
                                                        })
                                                    }}
                                                </button>
                                                <button
                                                    type="button"
                                                    :disabled="hasTablesSelected || isImmutable"
                                                    class="btn btn-sm btn-success btn-circle"
                                                    @click="showSelectRestaurantModal = true"
                                                    v-if="restaurants_count && restaurants_count > 2">
                                                    <svg
                                                        viewBox="0 0 24 24"
                                                        width="24"
                                                        height="24"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        class="feather css-i6dzq1">
                                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                                        <polyline points="16 17 21 12 16 7"></polyline>
                                                        <line x1="21" y1="12" x2="9" y2="12"></line>
                                                    </svg>
                                                    {{ $tl("labels.changeRestaurant") }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mt-3" v-if="nb_pers_total > 0 && slotSelected">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <LoaderComponent v-if="loading.service" />
                            <div v-else>
                                <div>
                                    <div v-if="service && service.is_table_rotation_enabled == true" class="row mb-2">
                                        <div class="col-6 pt-1">
                                            <label>{{ $tl("labels.booking.reservations.edit.duration") }} <small>*</small></label>
                                        </div>
                                        <div class="col-6">
                                            <vue-timepicker
                                                format="HH:mm"
                                                :minute-interval="15"
                                                v-model="duration"
                                                :disabled="hasTablesSelected || isImmutable"
                                                :lazy="true"
                                                @change="is_duration_customized = true">
                                            </vue-timepicker>
                                        </div>
                                    </div>
                                    <div v-if="service && nb_pers_total > 0 && service.has_seating_plan == true">
                                        <LoaderComponent v-if="loading.tables" />
                                        <div v-else>
                                            <div class="row mt-2 mb-2" v-if="vueMultiSelect.paxFull">
                                                <div class="offset-6 col-md-6">
                                                    <span>{{ $tl("labels.booking.reservations.fullyPlaced") }}</span>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 pt-2">
                                                    <label>{{ $tl("labels.booking.reservations.table", restaurant_id) }}</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <vue-multiselect
                                                        v-model="vueMultiSelect.entitiesSelected"
                                                        :options="tablesMultiselectOptions"
                                                        :multiple="true"
                                                        label="name"
                                                        track-by="name"
                                                        group-values="entities"
                                                        group-label="roomName"
                                                        :group-select="false"
                                                        :close-on-select="false"
                                                        :clear-on-select="false"
                                                        :preserve-search="true"
                                                        :show-labels="false"
                                                        placeholder="Tables"
                                                        :custom-label="customTablesLabel"
                                                        open-direction="bottom"
                                                        :disabled="ignore_placement || isImmutable"
                                                        @select="tableSelected($event)"
                                                        @remove="tableUnselected($event)"
                                                        style="font-size: 12.8px !important">
                                                        <template slot="noOptions">
                                                            <span v-if="!slotSelected">
                                                                {{ $tl("labels.booking.reservations.selectSlot") }}
                                                            </span>
                                                            <span v-else>
                                                                {{ $tl("labels.noResult") }}
                                                            </span>
                                                        </template>
                                                        <template slot="option" slot-scope="props">
                                                            <template v-if="!props.option.$isLabel">
                                                                <div class="float-left">
                                                                    {{
                                                                        props.option.type === "table"
                                                                            ? $tl("labels.booking.seatingPlan.table.title")
                                                                            : $tl("labels.booking.seatingPlan.group.title")
                                                                    }}
                                                                    {{ props.option.name }}
                                                                </div>
                                                                <div class="float-right" v-if="props.option.enabledPax.length > 0">
                                                                    {{ capitalize($tl("labels.pax")) }} :
                                                                    <template v-for="(item, index) in props.option.enabledPax">
                                                                        {{ index > 0 ? " " : ""
                                                                        }}{{ props.option.enabledPax.length - 1 === index ? `${item}` : `${item},` }}
                                                                    </template>
                                                                </div>
                                                                <div class="float-right" v-else>
                                                                    {{ capitalize($tl("labels.pax")) }} :
                                                                    {{ props.option.seats }}
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                <div>{{ props.option.$groupLabel }}</div>
                                                            </template>
                                                        </template>
                                                    </vue-multiselect>
                                                    <div
                                                        v-if="
                                                            service &&
                                                            service.is_seating_plan_algorithm_enabled &&
                                                            !loadingCanPlace &&
                                                            !can_place &&
                                                            vueMultiSelect.entitiesSelected.length === 0
                                                        ">
                                                        <label class="container-box text-danger mt-2 mb-1 w-auto">
                                                            <input type="checkbox" :checked="ignore_placement" @change="toggleIgnorePlacement" />
                                                            <span class="checkmark"></span>{{ $tl("labels.booking.seatingPlan.ignorePlacement") }}
                                                        </label>
                                                        <div class="text-danger">
                                                            <small>{{ $tl("infos.booking.seatingPlan.ignorePlacement") }}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-2" v-else-if="nb_pers_total > 0 && service && service.has_seating_plan == false">
                                        <div class="col-md-6 pt-2">
                                            <label for="num_table">{{ $tl("labels.booking.seatingPlan.table.title") }}</label>
                                        </div>
                                        <div class="col-md-6">
                                            <input
                                                id="num_table"
                                                type="text"
                                                v-model="num_table"
                                                class="form-control"
                                                tabindex="4"
                                                autocomplete="off"
                                                :class="{ 'is-invalid': hasErrors('num_table') }" />
                                            <div v-if="hasErrors('num_table')">
                                                <div class="invalid-feedback d-block" v-for="err in formErrors.errors.num_table">
                                                    {{ err }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mt-3" v-if="serviceIdSelected && slotSelected && nb_pers > 0 && available_menus.length > 0">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <LoaderComponent v-if="loading.menus || available_menus_trashed === null" />
                            <div v-else>
                                <menus-reservation
                                    ref="menusReservations"
                                    @update-menus="handleMenusChoosen"
                                    :restaurant-id="selected_restaurant_id"
                                    :formErrors="formErrors"
                                    :available_menus="available_menus"
                                    :available_menus_trashed="available_menus_trashed"
                                    :choosen_menus="choosen_menus"
                                    :are_menus_unique="areMenusUnique"
                                    :readonly="isImmutable"
                                    :nb_total="nb_pers * 1 + nb_children * 1" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mt-3" v-if="slotSelected && available_general_options && available_general_options.length > 0 && nb_pers > 0">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <LoaderComponent v-if="loading.general_options" />
                            <div v-else>
                                <general-options-reservation
                                    ref="generalOptionsReservation"
                                    :restaurant-id="selected_restaurant_id"
                                    :available_general_options="available_general_options"
                                    :available_general_options_trashed="available_general_options_trashed"
                                    :readonly="isImmutable"
                                    v-model="choosen_general_options"
                                    :nb_pers_total="nb_pers_total" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mt-3">
                    <div class="col-md-7 mt-3">
                        <div class="border-light b-radius-20 p-4" :class="associateClasses">
                            <LoaderComponent v-if="loading.clients" />
                            <div v-else>
                                <clients-reservations
                                    v-if="client.id !== null"
                                    ref="clientsReservations"
                                    :formErrors="formErrors"
                                    :restaurant_id="selected_restaurant_id"
                                    :client="client"
                                    :readonly="true" />
                                <button
                                    v-else
                                    :disabled="isImmutable"
                                    type="button"
                                    class="btn btn-sm btn-success btn-circle"
                                    @click="canOpenSearchClientModal = true">
                                    {{ $t("labels.form.actions.associate.client") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 mt-3">
                        <div class="border-light b-radius-20 p-4">
                            <LoaderComponent v-if="loading.comments" />
                            <div v-else>
                                <commentary-reservations
                                    ref="commentaryReservations"
                                    v-model="restaurantComment"
                                    :gift="giftCode"
                                    @add-gift-code="handleAddGiftCode"
                                    :room_number_="room_number"
                                    :is_hotel_client_="is_hotel_client"
                                    @set-room-number="room_number = $event"
                                    @set-is-hotel-client="is_hotel_client = $event"
                                    :readonly="isImmutable"
                                    :formErrors="formErrors"
                                    :reservation_id="reservation_id" />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="comment && comment !== ''" class="row m-0 mt-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <label class="mb-1">
                                <strong>{{ $tl("labels.booking.reservations.clientComment") }}</strong>
                            </label>
                            <div class="row radio" style="position: relative">
                                <div class="col-12">
                                    {{ comment }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="canAddOptionBank" class="row m-0 mt-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <LoaderComponent v-if="loading.paiement" />
                            <div v-else>
                                <div class="row radio" style="position: relative">
                                    <div class="col-md-3 mb-2">
                                        {{ $tl("labels.booking.reservations.secure") }}
                                    </div>
                                    <div class="col-md-9">
                                        <label class="container-box">
                                            <input type="radio" v-model="option_bank" :value="null" />
                                            <span class="checkmark"></span> {{ $tl("labels.none") }}
                                        </label>
                                        <div class="none-desk" style="height: 8px"></div>
                                        <label
                                            class="container-box"
                                            :class="{ 'cursor-disabled': isFootprintAvailable !== true }"
                                            v-tooltip="isFootprintAvailable !== true ? { content: isFootprintAvailable } : undefined">
                                            <input
                                                type="radio"
                                                v-model="option_bank"
                                                :value="OPTION_BANK_FOOTPRINT.value"
                                                :disabled="isFootprintAvailable !== true || isImmutable" />
                                            <span class="checkmark" :class="{ disabled: isFootprintAvailable !== true }"></span>
                                            {{ $tl("labels.booking.reservations.footprint.title") }}
                                        </label>
                                        <div class="none-desk" style="height: 8px"></div>
                                        <label
                                            class="container-box"
                                            :class="{ 'cursor-disabled': isPaymentAvailable !== true }"
                                            v-tooltip="isPaymentAvailable !== true ? { content: isPaymentAvailable } : undefined">
                                            <input
                                                type="radio"
                                                v-model="option_bank"
                                                :value="OPTION_BANK_PAYMENT.value"
                                                :disabled="isPaymentAvailable !== true || isImmutable" />
                                            <span class="checkmark" :class="{ disabled: isPaymentAvailable !== true }"></span>
                                            {{ $tl("labels.booking.reservations.payment.title") }}
                                        </label>
                                    </div>
                                </div>
                                <div class="row mt-3" v-if="option_bank === OPTION_BANK_PAYMENT.value">
                                    <div class="col-md-3">
                                        <label>{{ $tl("labels.booking.reservations.payment.amountToPay") }}</label>
                                    </div>
                                    <div class="col-md-9">
                                        <input
                                            type="number"
                                            v-model="amount_to_pay"
                                            class="form-control"
                                            :disabled="!client || !client.email || isImmutable" />
                                        <small class="d-block text-danger mt-2">
                                            {{ $tl("infos.booking.reservations.payment.willBeSendOnSave") }}
                                        </small>
                                    </div>
                                </div>
                                <div v-else-if="option_bank === OPTION_BANK_FOOTPRINT.value && footprint_date_send" class="offset-md-3">
                                    <small class="d-block alert-info text-info-noshow mt-2 p-2">
                                        <template v-if="footprint_date_send === 'NOW'">
                                            {{ $tl("infos.booking.reservations.footprint.willBeSendOnSave") }}
                                        </template>
                                        <template v-else>
                                            {{
                                                $t("infos.booking.reservations.footprint.willBeSendOnDate", {
                                                    date: footprint_date_send,
                                                })
                                            }}
                                        </template>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row m-0 mt-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <LoaderComponent v-if="loading.paiement" />
                            <div v-else class="row radio" style="position: relative">
                                <div class="col-12">
                                    <div v-if="option_bank === OPTION_BANK_PAYMENT.value">
                                        {{ $tl("labels.booking.reservations.payment.showResa.title") }}
                                        <div v-if="baseReservation.payment.status === PAYMENT_STATUS_CAPTURED.value">
                                            {{
                                                $t("labels.booking.reservations.payment.showResa.captured", {
                                                    amount: formatCurrency(baseReservation.payment.amount_captured / 100),
                                                })
                                            }}
                                        </div>
                                        <div v-else-if="baseReservation.payment.status === PAYMENT_STATUS_CAPTURABLE.value">
                                            {{
                                                $t("labels.booking.reservations.payment.showResa.capturable", {
                                                    amount: formatCurrency(baseReservation.payment.stripe_intent_amount / 100),
                                                })
                                            }}
                                        </div>
                                        <div v-else-if="baseReservation.payment.status === PAYMENT_STATUS_REFUNDED.value">
                                            {{
                                                $t("labels.booking.reservations.payment.showResa.refunded", {
                                                    amount: formatCurrency(baseReservation.payment.stripe_intent_amount / 100),
                                                    date: displayDate(baseReservation.payment.refunded_at),
                                                })
                                            }}
                                        </div>
                                        <div v-else-if="baseReservation.payment.status === PAYMENT_STATUS_PARTIALLY_REFUNDED.value">
                                            {{
                                                $t("labels.booking.reservations.payment.showResa.refundedPartially", {
                                                    total_amount: formatCurrency(baseReservation.payment.stripe_intent_amount / 100),
                                                    amount_refunded: formatCurrency(baseReservation.payment.amount_refunded / 100),
                                                    date: displayDate(baseReservation.payment.refunded_at),
                                                })
                                            }}
                                        </div>
                                        <div v-else-if="baseReservation.payment.status === PAYMENT_STATUS_CANCELED.value">
                                            {{ $tl("labels.booking.reservations.payment.showResa.canceled") }}
                                        </div>
                                        <div v-else-if="baseReservation.payment.status === PAYMENT_STATUS_PENDING.value">
                                            {{
                                                $t("labels.booking.reservations.payment.showResa.pending", {
                                                    amount: formatCurrency(baseReservation.payment.stripe_intent_amount / 100),
                                                })
                                            }}
                                        </div>
                                        <div v-else-if="baseReservation.payment.status === PAYMENT_STATUS_EXPIRED.value">
                                            {{ $tl("labels.booking.reservations.payment.showResa.expired") }}
                                        </div>
                                    </div>
                                    <div v-else-if="option_bank === OPTION_BANK_FOOTPRINT.value">
                                        {{ $tl("labels.booking.reservations.footprint.showResa.title") }}
                                        <div v-if="baseReservation.status === 'canceled' || baseReservation.status === 'refused'">
                                            {{ $tl("labels.booking.reservations.footprint.showResa.noOne") }}
                                        </div>
                                        <div v-else-if="baseReservation.payment.status === PAYMENT_STATUS_PENDING.value">
                                            {{ $tl("labels.booking.reservations.footprint.showResa.pending") }}
                                        </div>
                                        <div
                                            v-else-if="
                                                baseReservation.payment.status === PAYMENT_STATUS_CAPTURABLE.value ||
                                                baseReservation.payment.status === PAYMENT_STATUS_CAPTURED.value
                                            ">
                                            <div v-if="baseReservation.status === 'noshow'">
                                                {{
                                                    $t("labels.booking.reservations.footprint.showResa.captured", {
                                                        amount: formatCurrency(baseReservation.payment.amount_captured / 100),
                                                    })
                                                }}
                                            </div>
                                            <div v-else-if="baseReservation.status === 'over'">
                                                {{ $tl("labels.booking.reservations.footprint.showResa.over") }}
                                            </div>
                                            <div v-else>
                                                {{
                                                    $t("labels.booking.reservations.footprint.showResa.capturable", {
                                                        amount: formatCurrency(baseReservation.amount_if_noshow / 100),
                                                    })
                                                }}
                                            </div>
                                        </div>
                                        <div v-else-if="baseReservation.payment.status === PAYMENT_STATUS_EXPIRED.value">
                                            {{ $tl("labels.booking.reservations.footprint.showResa.expired") }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        {{ $tl("labels.booking.reservations.noFootprintNorPayment") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mt-3" v-if="baseReservation && baseReservation.user">
                    <div class="col-12">
                        <label class="d-block text-right">
                            <i
                                >{{ $tl("labels.booking.reservations.savedBy") }}
                                {{ `${baseReservation.user.firstname} ${baseReservation.user.lastname}` }}</i
                            >
                        </label>
                    </div>
                </div>
                <div v-if="showSaveButton" class="row mb-2">
                    <div class="col-12">
                        <input
                            :disabled="formLoading || isImmutable"
                            type="submit"
                            name="submit"
                            :value="$tl('labels.booking.reservations.actions.save')"
                            class="btn btn-success btn-circle" />
                    </div>
                    <button :disabled="formLoading || isImmutable" class="btn btn-success btn-circle" @click="saveInWaitingList">
                        {{ $tl("labels.booking.waitings.list.title") }}
                    </button>
                </div>
            </form>
        </div>
        <select-restaurant-modal
            ref="SelectRestaurantModal"
            v-if="showSelectRestaurantModal"
            @close="showSelectRestaurantModal = false"
            @selected="destinationRestaurantSelected" />
        <wrapper-search-client-modal v-if="canOpenSearchClientModal" @close="canOpenSearchClientModal = false" @selected-client="associateClient" />
    </div>
</template>

<script>
import servicesReservations from "./servicesReservations";
import SelectRestaurantModal from "../Modals/SelectRestaurantModal";
import slotsReservations from "./slotsReservations";
import VueTimepicker from "vue2-timepicker";
import commentaryReservations from "./commentaryReservations";
import clientsReservations from "./clientsReservations";
import menusReservation from "./menusReservation";
import axios from "axios";
import _ from "lodash";
import VueMultiselect from "vue-multiselect";
import GeneralOptionsReservation from "./GeneralOptionsReservation";
import LoaderComponent from "../../components/LoaderComponent.vue";
import PaymentStatusEnum from "../../mixins/enums/booking/PaymentStatusEnum";
import OptionBankEnum from "../../mixins/enums/booking/OptionBankEnum";
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum";
import WrapperSearchClientModal from "../Modals/clients/WrapperSearchClientModal.vue";

export default {
    name: "mainReservation",
    data() {
        return this.$_.cloneDeep(
            this.$_.defaultsDeep(this.defaultValues, {
                loading: {
                    slots: true,
                    services: true,
                    service: true,
                    menus: true,
                    tables: false,
                    paiement: true,
                    employees: true,
                    clients: true,
                    comments: true,
                    confirmation: true,
                    general_options: true,
                },
                restaurants_count: 0,
                selected_destination_restaurant: null,
                selected_destination_restaurant_slot_id: null,
                formLoading: false,
                formErrors: null,
                seatingPlanError: null,
                formSuccess: null,
                error: null,
                showSelectRestaurantModal: false,
                baseReservation: null,
                comment: null,
                nb_pers: 0,
                vueMultiSelect: {
                    entitiesSelected: [],
                    paxFull: false,
                },
                nb_children: 0,
                openServices: null,
                openServicesError: null,
                old_slot_hour: null,
                slot_hour: null,
                slot_id: null,
                old_service_id: null,
                serviceIdSelected: null,
                service: null,
                //seatingPlan: null,
                reservation_date: null,
                rotation_enabled: false,
                baseRestaurant: null,
                restaurant: null,
                duration: {
                    HH: "01",
                    mm: "00",
                },
                available_menus: [],
                available_menus_trashed: null,
                choosen_menus: {},
                areMenusUnique: false,
                available_general_options: [],
                available_general_options_trashed: [],
                choosen_general_options: {},
                client: {
                    id: null,
                    civility: null,
                    firstname: null,
                    lastname: null,
                    company: null,
                    email: null,
                    phone: {
                        raw: null,
                        isValid: null,
                        country: null,
                        value: null,
                        search: {
                            results: null,
                        },
                    },
                    locale: null,
                    type: null,
                    is_vip: false,
                    firstnameHasFocus: false,
                    lastnameHasFocus: false,
                    companyHasFocus: false,
                    emailHasFocus: false,
                    phoneNumberHasFocus: false,
                    search: {
                        results: null,
                        errors: null,
                        isLoading: false,
                        firstnameHasFocus: false,
                        lastnameHasFocus: false,
                        companyHasfocus: false,
                        emailHasFocus: false,
                        phoneNumberHasFocus: false,
                    },
                },
                restaurantComment: "",
                amount_to_pay: 0,
                send_confirmation: true,
                send_confirmation_disabled: false,
                giftCode: "",
                option_bank: null,
                employee_accounts: [],
                user_id: null,
                num_table: null,
                waiting_id: null,
                is_duration_customized: false,
                amount_if_noshow: 0,
                status: null,
                user: null,
                socket: {
                    listeningChannels: {
                        services: [],
                    },
                },
                checksAbortController: {
                    pax: null,
                    seatingPlan: null,
                },
                room_number: null,
                can_place: false,
                ignore_placement: false,
                loadingCanPlace: false,
                reservationDateUpdated: false,
                is_hotel_client: false,
                canOpenSearchClientModal: false,
                isAssociatingInProgress: false,
            })
        );
    },
    mixins: [ReservationStatusEnum, PaymentStatusEnum, OptionBankEnum],
    components: {
        servicesReservations,
        slotsReservations,
        commentaryReservations,
        VueTimepicker,
        clientsReservations,
        menusReservation,
        VueMultiselect,
        SelectRestaurantModal,
        GeneralOptionsReservation,
        LoaderComponent,
        WrapperSearchClientModal,
    },
    props: {
        restaurant_id: {
            default: null,
        },
        defaultValues: {
            default: () => {
                return {};
            },
        },
        date: {
            default: null,
        },
        reservation_id: {
            required: true,
        },
        showSaveButton: {
            default: true,
        },
        tablesIdToPreselect: {
            default: () => {
                return [];
            },
        },
        serviceToPreselect: {
            default: null,
        },
        slotToPreselect: {
            default: null,
        },
    },
    mounted() {
        this.loading.slots = true;
        this.loading.clients = true;
        this.loadRestaurant();
        this.getRestaurantsCount();
        this.loadReservation().then(() => {
            this.$nextTick(() => {
                this.loadServices(this.reservation_date);
                this.getRestaurantsEmployeeAccounts();
                this.loading.clients = false;
                this.loadMenusData();
                this.loadGeneralOptionsData();
                this.loadService(this.serviceIdSelected).then(() => {
                    // this.loadSeatingPlanData().then(() => {
                    this.$nextTick(() => {
                        this.vueMultiSelect.entitiesSelected = this.formatTablesForMultiSelect(this.baseReservation.tables.data);
                        this.checkVueMultiSelectPaxFull();
                        this.loading.slots = false;
                        this.loading.tables = false;
                        this.loading.services = false;
                        this.loading.employees = false;
                        this.loading.confirmation = false;
                        this.loading.paiement = false;
                        this.loading.comments = false;
                        this.loading.general_options = false;
                    });
                    // });
                });
            });
        });
    },
    beforeDestroy() {
        this.unregisterServiceSockets();
        this.unselectAllTables();
        this.emptyForm();
    },
    methods: {
        setClient(client) {
            this.client.id = client.id;
            this.client.civility = client.civility;
            this.client.firstname = client.firstname;
            this.client.lastname = client.lastname;
            this.client.company = client.company;
            this.client.email = client.email;
            this.client.phone.raw = client.tel;
            this.client.phone.country = client.tel_country;
            this.client.is_deleted = client.is_deleted;
        },
        toggleIgnorePlacement() {
            this.ignore_placement = !this.ignore_placement;
            this.$emit("enable-save-button", this.ignore_placement);
        },
        unlinkAllTables() {
            if (this.vueMultiSelect && this.vueMultiSelect.entitiesSelected) {
                this.vueMultiSelect.entitiesSelected.forEach((entity) => this.tableUnselected(entity, true, true));
            }
        },
        setFormLoading(value) {
            this.formLoading = value;
        },
        getRestaurantsCount() {
            axios
                .get(`/api/booking/restaurants?all=1`)
                .then((response) => {
                    this.restaurants_count = response.data.data.length;
                })
                .catch((error) => {});
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
        setFormErrors(value) {
            this.formErrors = value;
        },
        loadRestaurant() {
            return axios
                .get(`/api/restaurants/${this.selected_restaurant_id}?include=closures,payment_notif_settings`)
                .then((response) => {
                    if (response.data.id == this.restaurant_id) {
                        this.baseRestaurant = response.data;
                    }

                    this.restaurant = response.data;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        emptyForm() {
            this.autoloadResults = null;
            this.autoloadResultsType = null;
            this.autoloadSearch = null;
            this.autoloadTel = false;
            this.nb_pers = null;
            this.nb_children = null;
            this.$set(this, "client", {
                id: null,
                civility: null,
                firstname: null,
                lastname: null,
                email: null,
                phone: {
                    raw: null,
                    isValid: null,
                    country: null,
                    value: null,
                    search: {
                        results: null,
                    },
                },
                firstnameHasFocus: false,
                lastnameHasFocus: false,
                emailHasFocus: false,
                phoneNumberHasFocus: false,
                search: {
                    results: null,
                    errors: null,
                    isLoading: false,
                    firstnameHasFocus: false,
                    lastnameHasFocus: false,
                    emailHasFocus: false,
                    phoneNumberHasFocus: false,
                },
            });
            this.phoneSearch = {
                raw: null,
                isValid: null,
                country: null,
                value: null,
                errors: null,
                results: null,
                isLoading: false,
            };
            this.restaurant_comment = null;
            this.gift = null;
            this.num_table = null;
            this.slot_id = null;
            this.selected_destination_restaurant_slot_id = null;
            this.menus_sent = [];
            this.choosen_menus = {};
            this.choosen_general_options = {};
            this.available_menus = [];
            this.old_nb_pers = null;
        },
        checkVueMultiSelectDisabledTables() {
            this.vueMultiSelect.isInvalid = this.vueMultiSelect.entitiesSelected.find((e) => e.$isDisabled) ? true : false;
        },
        launchChecks() {
            this.checkSeatingPlan();
            this.checkMaxPax();
        },
        checkSeatingPlan() {
            this.ignore_placement = false;
            this.seatingPlanError = null;
            if (this.checksAbortController.seatingPlan) {
                this.checksAbortController.seatingPlan.abort();
                this.checksAbortController.seatingPlan = null;
            }
            const nb_total = (this.nb_pers ? this.nb_pers : 0) + (this.nb_children ? this.nb_children : 0);
            const slot_id = this.selected_destination_restaurant ? this.selected_destination_restaurant_slot_id : this.slot_id;

            if (nb_total !== 0 && this.reservation_date && this.slot_id) {
                var query =
                    `/api/restaurants/${this.selected_restaurant_id}/reservations/checkseatingplan` +
                    `?total=${this.nb_pers_total}&date=${this.reservation_date.toISODate()}&slot_id=${slot_id}` +
                    `&duration=${this.duration.HH}:${this.duration.mm}` +
                    `&reservation_id=${this.reservation_id}`;
                this.checksAbortController.seatingPlan = new AbortController();
                this.loadingCanPlace = true;
                this.can_place = false;
                this.$emit("enable-save-button", false);
                axios
                    .get(query, {
                        signal: this.checksAbortController.seatingPlan.signal,
                    })
                    .then(() => {
                        this.$emit("enable-save-button", true);
                        this.loadingCanPlace = false;
                        this.can_place = true;
                    })
                    .catch((error) => {
                        this.loadingCanPlace = false;

                        if (error instanceof axios.AxiosError && error.code === axios.AxiosError.ERR_CANCELED) {
                            return;
                        }

                        this.$emit("enable-save-button", false);
                        this.can_place = false;
                    });
            }
        },
        setMultiSelectSelectedEntities(value) {
            const newTablesId = value
                .map((entity) => {
                    if (entity.type === "table") {
                        return entity.id;
                    }

                    return null;
                })
                .filter((e) => e);
            const oldTablesId = this.vueMultiSelect.entitiesSelected
                .map((entity) => {
                    if (entity.type === "table") {
                        return entity.id;
                    }

                    return null;
                })
                .filter((e) => e);
            const newGroupsId = value
                .map((entity) => {
                    if (entity.type === "group") {
                        return entity.id;
                    }

                    return null;
                })
                .filter((e) => e);
            const oldGroupsId = this.vueMultiSelect.entitiesSelected
                .map((entity) => {
                    if (entity.type === "group") {
                        return entity.id;
                    }

                    return null;
                })
                .filter((e) => e);

            //TODO : Make diff to add / remove
            this.vueMultiSelect.entitiesSelected.forEach((entity) => {
                if (entity.type === "table" && !newTablesId.includes(entity.id)) {
                    this.tableUnselected(entity);
                } else if (entity.type === "group" && !newGroupsId.includes(entity.id)) {
                    this.tableUnselected(entity);
                }
            });
            value.forEach((entity) => {
                if (entity.type === "table" && !oldTablesId.includes(entity.id)) {
                    this.tableUnselected(entity);
                } else if (entity.type === "group" && !oldGroupsId.includes(entity.id)) {
                    this.tableUnselected(entity);
                }
            });
            this.vueMultiSelect.entitiesSelected = value;
        },
        checkMaxPax() {
            if (this.checksAbortController.pax) {
                this.checksAbortController.pax.abort();
                this.checksAbortController.pax = null;
            }

            const nb_total = (this.nb_pers ? this.nb_pers : 0) + (this.nb_children ? this.nb_children : 0);
            const slot_id = this.selected_destination_restaurant ? this.selected_destination_restaurant_slot_id : this.slot_id;
            if (nb_total !== 0 && this.reservation_date && this.slot_id) {
                var query =
                    `/api/restaurants/${this.selected_restaurant_id}/reservations/checkmaxpax` +
                    `?total=${this.nb_pers_total}&date=${this.reservation_date.toISODate()}&slot_id=${slot_id}` +
                    `&duration=${this.duration.HH}:${this.duration.mm}` +
                    `&reservation_id=${this.reservation_id}`;
                this.checksAbortController.pax = new AbortController();
                axios
                    .get(query, {
                        signal: this.checksAbortController.pax.signal,
                    })
                    .then(() => {
                        this.formErrors = null;
                    })
                    .catch((error) => {
                        if (error instanceof axios.AxiosError && error.code === axios.AxiosError.ERR_CANCELED) {
                            return;
                        }

                        const message = this.$tl("infos.booking.reservations.saveCauseSurbooking", this.selected_restaurant_id);
                        if (this.formErrors == null) this.formErrors = {};
                        if (this.formErrors.max_pax !== message) {
                            this.formErrors.max_pax = message;
                        }
                    });
            }
        },
        loadReservation() {
            return axios
                .get(
                    `/api/restaurants/${this.selected_restaurant_id}/reservations/${this.reservation_id}?include=slot,client,slot.service,menus,menus.options,general_options,tables,tables.room,user,payment`
                )
                .then((response) => {
                    this.baseReservation = response.data;

                    this.reservation_date = this.getDateTime(this.baseReservation.reservation_datetime);
                    this.nb_pers = this.baseReservation.nb_pers;
                    this.nb_children = this.baseReservation.nb_children;
                    if (this.baseReservation.client !== null) {
                        this.setClient(this.baseReservation.client);
                    }
                    this.restaurantComment = this.baseReservation.restaurant_comment;
                    this.comment = this.baseReservation.comment;
                    this.giftCode = this.baseReservation.gift;
                    this.num_table = this.baseReservation.num_table;

                    this.amount_if_noshow = this.baseReservation.amount_if_noshow;
                    this.amount_to_pay = this.baseReservation.amount_to_pay || 0;

                    this.slot_id = this.baseReservation.slot.id;
                    this.serviceIdSelected = this.baseReservation.slot.service_id;
                    this.option_bank = this.baseReservation.option_bank;
                    this.status = this.baseReservation.status;
                    const durationArray = this.baseReservation.duration.split(":");
                    this.duration.HH = durationArray[0];
                    this.duration.mm = durationArray[1];
                    this.is_duration_customized = this.baseReservation.is_duration_customized === 1 ? true : false;
                    this.user = this.baseReservation.user;
                    this.room_number = this.baseReservation.room_number;
                    this.is_hotel_client = this.baseReservation.is_hotel_client;

                    if (!this.selected_destination_restaurant) {
                        this.choosen_menus = this.formatBaseMenus(this.baseReservation.menus.data);
                        this.choosen_general_options = this.formatBaseGeneralOptions(this.baseReservation.general_options.data);
                    }
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        formatBaseMenus(menus) {
            var result = {};

            menus.forEach((menu) => {
                result[`id_${menu.id}`] = {
                    id: menu.id,
                    value: menu.nb,
                    options: {},
                };

                menu.options.data.forEach((option) => {
                    result[`id_${menu.id}`].options[`id_${option.id}`] = {
                        id: option.id,
                        value: option.nb,
                    };
                });
            });

            return result;
        },
        formatBaseGeneralOptions(generalOptions) {
            var result = {};

            generalOptions.forEach((option) => {
                result[`id_${option.id}`] = {
                    id: option.id,
                    value: option.nb,
                };
            });

            return result;
        },
        formatTablesForMultiSelect(tables) {
            if (tables.length > 0) {
                const tablesId = tables.map((t) => t.id);
                const roomId = tables[0].room.id;
                const room = this.tablesMultiselectOptions.find((tmo) => tmo.id === roomId);

                if (room) {
                    return room.entities.filter((t) => tablesId.includes(t.id));
                }
            }

            return [];
        },
        loadService(service_id) {
            this.loading.service = true;
            return axios
                .get(
                    `/api/restaurants/${
                        this.selected_restaurant_id
                    }/services/${service_id}?date=${this.reservation_date.toISODate()}&include=slots,seatingPlanFilled,paxDurations,has_seating_plan`
                )
                .then((response) => {
                    const durationsArray = response.data.duration.split(":");

                    if (this.is_duration_customized === false) {
                        this.$set(this, "duration", {
                            HH: `0${durationsArray[0]}`.substr(-2),
                            mm: `0${durationsArray[1]}`.substr(-2),
                        });
                    }

                    this.service = response.data;
                    this.loading.service = false;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                    this.loading.service = false;
                });
        },
        loadServices(date) {
            this.selected_destination_restaurant_slot_id = null;
            this.service = null;

            if (date) {
                this.reservation_date = date;
            }
            this.openServices = null;
            this.openServicesError = null;
            return axios
                .get(`/api/restaurants/${this.selected_restaurant_id}/servicesatdate?sorted=1&all=true&date=${this.reservation_date.toISODate()}`)
                .then((response) => {
                    this.openServices = response.data;
                    this.loading.slots = false;
                })
                .catch((error) => {
                    this.openServicesError = this.getErrorMsgFromErrorResponse(error);

                    this.loading.slots = false;
                });
        },
        loadSeatingPlanData() {
            this.loading.tables = true;
            const slot_id = this.selected_destination_restaurant ? this.selected_destination_restaurant_slot_id : this.slot_id;
            return axios
                .get(
                    `/api/restaurants/${this.selected_restaurant_id}/services/slots/${slot_id}/seatingPlan?date=${this.reservation_date.toISODate()}`
                )
                .then((response) => {
                    if (!this.$_.isEmpty(response.data.seatingPlan) && response.data.seatingPlan !== null) {
                        this.seatingPlan = response.data.seatingPlan;
                    } else {
                    }

                    this.$nextTick(() => {
                        this.loading.tables = false;
                    });
                })
                .catch(() => {
                    this.$nextTick(() => {
                        this.loading.tables = false;
                    });
                });
        },
        loadMenusData() {
            this.loading.menus = true;
            this.available_menus = [];

            this.loadMenusDataTrashed();
            return axios
                .get(`/api/restaurants/${this.selected_restaurant_id}/services/${this.serviceIdSelected}/menus?include=menu_options`)
                .then((response) => {
                    this.available_menus = response.data.data;
                    this.loading.menus = false;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                    this.loading.menus = false;
                });
        },
        loadMenusDataTrashed() {
            this.loading.menus = true;
            this.available_menus_trashed = null;

            return axios
                .get(`/api/restaurants/${this.selected_restaurant_id}/services/${this.serviceIdSelected}/menus?include=menu_options&with_trashed=1`)
                .then((response) => {
                    this.available_menus_trashed = response.data.data;
                    this.loading.menus = false;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                    this.loading.menus = false;
                });
        },
        loadGeneralOptionsData(service_id) {
            this.loading.general_options = true;
            this.available_general_options = [];

            this.loadGeneralOptionsDataTrashed(service_id);
            var api_call = `/api/restaurants/${this.selected_restaurant_id}/services/${this.serviceIdSelected}/general_options`;
            return axios
                .get(api_call)
                .then((response) => {
                    this.available_general_options = response.data.data;
                    this.loading.general_options = false;
                })
                .catch(() => {});
        },
        loadGeneralOptionsDataTrashed(service_id) {
            this.loading.general_options = true;
            this.available_general_options = [];

            var api_call = `/api/restaurants/${this.selected_restaurant_id}/services/${this.serviceIdSelected}/general_options?with_trashed=1`;
            return axios
                .get(api_call)
                .then((response) => {
                    this.available_general_options_trashed = response.data.data;
                    this.loading.general_options = false;
                })
                .catch(() => {});
        },
        getRestaurantsEmployeeAccounts() {
            axios
                .get(`/api/restaurants/${this.selected_restaurant_id}/users?role=user_without_account`)
                .then((response) => {
                    this.employee_accounts = response.data.data;
                })
                .catch(() => {});
        },
        handleMenusChoosen(value) {
            this.choosen_menus = value;
        },
        handleSlotId({ slot_hour, slot_id, service_id }) {
            //this.loading.tables = true;
            var serviceChanged = false;

            if (this.selected_destination_restaurant) {
                this.selected_destination_restaurant_slot_id = slot_id;
            } else {
                this.slot_id = slot_id;
                this.slot_hour = slot_hour;
            }
            if (service_id && this.serviceIdSelected !== service_id) {
                this.serviceIdSelected = service_id;
                serviceChanged = true;
            }

            this.$nextTick(() => {
                if (service_id) {
                    const treatment = () => {
                        this.$nextTick(() => {
                            if (this.baseReservation) {
                                this.checkVueMultiSelectPaxFull();
                            } else {
                                if (
                                    this.$_.isEmpty(this.vueMultiSelect.entitiesSelected) &&
                                    this.nb_pers_total > 0 &&
                                    this.tablesIdToPreselect.length > 0
                                ) {
                                    this.setMultiSelectSelectedEntities(
                                        this.tablesMultiselectOptions
                                            .map((room) => {
                                                return room.entities.filter((e) => {
                                                    return e.type === "table" && this.tablesIdToPreselect.includes(e.id);
                                                });
                                            })
                                            .flat()
                                            .filter((t) => t)
                                    );
                                }
                            }
                        });
                    };

                    if (serviceChanged || this.reservationDateUpdated) {
                        this.reservationDateUpdated = false;
                        this.loadService(service_id).then(treatment);
                    } else {
                        treatment();
                    }

                    this.loadMenusData();
                    this.loadGeneralOptionsData();
                    this.launchChecks();
                } else if (this.$_.isEmpty(this.vueMultiSelect.entitiesSelected) && this.nb_pers_total > 0 && this.tablesIdToPreselect.length > 0) {
                    this.setMultiSelectSelectedEntities(
                        this.tablesMultiselectOptions
                            .map((room) => {
                                return room.entities.filter((e) => {
                                    return e.type === "table" && this.tablesIdToPreselect.includes(e.id);
                                });
                            })
                            .flat()
                            .filter((t) => t)
                    );
                    this.launchChecks();
                }
            });
        },
        handleReservationDate(date) {
            this.old_service_id = this.serviceIdSelected;
            this.old_slot_hour = this.slot_hour;
            this.reservation_date = date;
            this.loading.slots = true;
            this.reservationDateUpdated = true;
            this.loadServices(date);
        },
        handleNbPers(nb) {
            this.nb_pers = nb;
            this.launchChecks();
        },
        handleNbChildren(nb) {
            this.nb_children = nb;
            this.launchChecks();
        },
        handleUpdateClient(client) {
            this.client = client;
        },
        handleAddCommentary(text) {
            this.restaurantComment = text;
        },
        handleAddGiftCode(event) {
            this.giftCode = event;
        },
        saveResa() {
            if (this.service && this.service.has_seating_plan == true) {
                if (this.service && this.service.is_seating_plan_algorithm_enabled == true && !this.hasTablesSelected && this.seatingPlanError) {
                    this.seatingPlanError = this.$tl("infos.bookings.reservations.couldNotBePlaced");
                    return null;
                } else if (this.vueMultiSelect.isInvalid) {
                    return null;
                }
            }

            return this.editResa();
        },
        saveInWaitingList: function (e) {
            this.formLoading = true;
            this.formErrors = null;

            let api_waiting = `/api/restaurants/${this.selected_restaurant_id}/service/waiting`;

            const axiosPromiseWaitings = axios.post(api_waiting, {
                client_id: this.client ? this.client.id : null,
                civility: this.client ? this.client.civility : null,
                firstname: this.client ? this.client.firstname : null,
                lastname: this.client ? this.client.lastname : null,
                company: this.client ? this.client.company : null,
                email: this.client ? this.client.email : null,
                phoneNumber: this.client && this.client.phone.value ? this.client.phone.value.international : null,
                phoneCountry: this.client && this.client.phone ? this.client.phone.country : null,
                reservation_date: this.reservation_date.toISODate(),
                nb_pers: this.nb_pers,
                nb_children: this.nb_children,
                slot_id: this.selected_destination_restaurant ? this.selected_destination_restaurant_slot_id : this.slot_id,
                restaurant_id: this.selected_restaurant_id,
            });

            if (this.showSaveButton) {
                axiosPromiseWaitings
                    .then((response) => {
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: response.data.message,
                        });
                        this.formLoading = false;
                    })
                    .catch((error) => {
                        this.formErrors = error.response ? error.response.data : [];
                        this.formLoading = false;
                    });
            } else {
                this.formLoading = false;
                return axiosPromiseWaitings;
            }
        },
        destinationRestaurantSelected(restaurant) {
            this.selected_destination_restaurant = restaurant;
            this.selected_destination_restaurant_slot_id = null;
            this.loading.slots = true;
            this.$set(this, "choosen_menus", {});
            this.$set(this, "choosen_general_options", {});
            this.loadReservation();
            this.service = null;
            this.serviceIdSelected = null;
            this.loadRestaurant();
            this.getRestaurantsCount();
            this.loadServices(this.reservation_date);
            this.getRestaurantsEmployeeAccounts();
            this.loading.slots = false;
            this.loading.menus = false;
            this.loading.tables = false;
            this.loading.services = false;
            this.loading.employees = false;
            this.loading.confirmation = false;
            this.loading.paiement = false;
            this.loading.comments = false;
            this.loading.clients = false;
            this.loading.general_options = false;
            this.showSelectRestaurantModal = false;
        },
        goBackToBaseRestaurant() {
            this.selected_destination_restaurant = null;
            this.selected_destination_restaurant_slot_id = null;
            this.loading.slots = true;
            this.loadRestaurant();
            this.getRestaurantsCount();
            this.getRestaurantsEmployeeAccounts();
            this.loadReservation().then(() => {
                this.$nextTick(() => {
                    this.loadMenusData();
                    this.loadGeneralOptionsData();
                    this.loadServices(this.reservation_date);
                    this.loadService(this.serviceIdSelected).then(() => {
                        this.$nextTick(() => {
                            this.$nextTick(() => {
                                this.checkVueMultiSelectPaxFull();
                            });
                        });
                    });
                });
            });
            this.loading.slots = false;
            this.loading.menus = false;
            this.loading.tables = true;
            this.loading.services = false;
            this.loading.employees = false;
            this.loading.confirmation = false;
            this.loading.paiement = false;
            this.loading.comments = false;
            this.loading.clients = false;
            this.loading.general_options = false;
        },
        editResa() {
            const slot_id = this.selected_destination_restaurant ? this.selected_destination_restaurant_slot_id : this.slot_id;

            if (
                this.baseReservation.option_bank === this.OPTION_BANK_FOOTPRINT.value &&
                this.baseReservation.payment.status != this.PAYMENT_STATUS_NOT_CREATED.value
            ) {
                const intentCreatedAt = this.getDateTime(this.baseReservation.payment.stripe_intent_created_at);
                if (
                    this.reservation_date.diff(intentCreatedAt, "days").days > 5 &&
                    !confirm(this.$tl("infos.booking.reservations.footprint.changeDate"))
                ) {
                    this.formLoading = false;
                    return;
                }
            }

            const axiosPromise = axios.put(`/api/restaurants/${this.selected_restaurant_id}/reservations/${this.reservation_id}`, {
                reservation_date: this.reservation_date.toISODate(),
                nb_pers: this.nb_pers,
                nb_children: this.nb_children,
                client_id: this.client ? this.client.id : null,
                restaurant_comment: this.restaurantComment,
                gift: this.giftCode,
                num_table: this.num_table,
                choosen_menus: this.choosen_menus,
                choosen_general_options: this.choosen_general_options,
                slot_id: slot_id,
                restaurant_id: this.selected_restaurant_id,
                duration: this.duration,
                is_duration_customized: this.is_duration_customized,
                tables: this.tablesSelected,
                option_bank: this.option_bank,
                amount_to_pay: this.amount_to_pay,
                room_number: this.room_number,
                is_hotel_client: this.is_hotel_client,
                ignore_placement: this.ignore_placement,
            });

            if (this.showSaveButton) {
                axiosPromise
                    .then((response) => {
                        // display success message
                        this.formLoading = false;
                        //this.formSuccess = response.data.message;
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: this.$tl("success.booking.reservations.updatedLong"),
                        });
                        // empty form fields

                        // redirect to reservations list
                        this.$router.push({
                            name: "booking.restaurants.reservations",
                            params: {
                                restaurant_id: this.$route.params.restaurant_id,
                            },
                        });
                    })
                    .catch((error) => {
                        if (error.response && error.response.data) {
                            this.formErrors = error.response.data;
                        } else {
                            this.formErrors = { message: error.message };
                        }
                        this.formLoading = false;
                    });
            } else {
                return axiosPromise;
            }
        },
        createResa() {
            const axiosPromise = axios.post(`/api/restaurants/${this.selected_restaurant_id}/reservations/add`, {
                reservation_date: this.reservation_date.toISODate(),
                nb_pers: this.nb_pers,
                nb_children: this.nb_children,
                client_id: this.client ? this.client.id : null,
                civility: this.client ? this.client.civility : null,
                firstname: this.client ? this.client.firstname : null,
                lastname: this.client ? this.client.lastname : null,
                company: this.client ? this.client.company : null,
                email: this.client ? this.client.email : null,
                phoneNumber: this.client && this.client.phone.value ? this.client.phone.value.international : null,
                phoneCountry: this.client && this.client.phone ? this.client.phone.country : null,
                restaurant_comment: this.restaurantComment,
                gift: this.giftCode,
                num_table: this.num_table,
                choosen_menus: this.choosen_menus,
                choosen_general_options: this.choosen_general_options,
                slot_id: this.selected_destination_restaurant ? this.selected_destination_restaurant_slot_id : this.slot_id,
                stripeToken: false,
                option_bank: this.option_bank,
                amount_to_pay: this.amount_to_pay,
                restaurant_id: this.selected_restaurant_id,
                waiting_id: this.waiting_id,
                send_confirmation: this.send_confirmation,
                duration: this.duration,
                user_id: this.user_id,
                is_duration_customized: this.is_duration_customized,
                tables: this.tablesSelected,
            });

            if (this.showSaveButton) {
                axiosPromise
                    .then((response) => {
                        // display success message
                        //this.formSuccess = response.data.message;
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: response.data.message,
                        });
                        // empty form fields

                        // redirect to reservations list
                        this.$router.push({
                            name: "booking.restaurants.reservations",
                            params: {
                                restaurant_id: this.$route.params.restaurant_id,
                            },
                        });
                    })
                    .catch((error) => {
                        if (error.response && error.response.data) {
                            this.formErrors = error.response.data;
                        } else {
                            this.formErrors = { message: error.message };
                        }
                        this.formLoading = false;
                    });
            } else {
                return axiosPromise;
            }
        },
        unselectAllTables() {
            this.vueMultiSelect.entitiesSelected.forEach((entity) => {
                this.tableUnselected(entity, false);
            });
        },
        tableUnselected(entity, checks = true, remove = false) {
            if (entity.type === "table") {
                this.releaseTable(entity, checks, remove);
            } else if (entity.type === "group") {
                this.releaseGroup(entity, checks, remove);
            }
        },
        tableSelected(entity) {
            if (entity.type === "table") {
                this.reserveTable(entity);
            } else if (entity.type === "group") {
                this.reserveGroup(entity);
            }
        },
        reserveGroup(entity) {
            var slot_id = this.selected_destination_restaurant ? this.selected_destination_restaurant_slot_id : this.slot_id;
            if (!this.isGroupFromBaseReservation(entity)) {
                const duration = `${this.duration.HH}:${this.duration.mm}`;
                const url =
                    `/api/restaurants/${this.selected_restaurant_id}/services/slots/${slot_id}` +
                    `/groups/reserved/${entity.id}` +
                    `?date=${this.reservation_date.toISODate()}&duration=${duration}&pax=${this.nb_pers_total}`;

                axios
                    .post(url)
                    .then(() => {
                        //ok socket event should be fired and will update front
                    })
                    .catch(() => {
                        this.$notify({
                            group: "notification",
                            type: "Error",
                            title: this.$tl("errors.booking.seatingPlan.group.notAvailable"),
                        });

                        const index = this.vueMultiSelect.entitiesSelected.findIndex((e) => e.type === "group" && e.id === entity.id);

                        if (index > -1) {
                            this.vueMultiSelect.entitiesSelected.splice(index, 1);
                        }
                    });
            }
        },
        registerServiceSockets() {
            if (this.service && this.reservation_date && this.selected_restaurant_id) {
                const channelName =
                    `App.restaurant.${this.selected_restaurant_id}.service.` + `${this.service.id}.date.${this.reservation_date.toISODate()}`;

                Echo.private(channelName).listen(".table.updated", this.onTableUpdate);

                this.socket.listeningChannels.services.push(channelName);
                this.$store.commit("sockets/addChannel", channelName);
            }
        },
        unregisterServiceSockets() {
            this.socket.listeningChannels.services.forEach((channelName) => {
                Echo.private(channelName).stopListening(".table.updated");
            });

            this.socket.listeningChannels.services = [];
        },
        onTableUpdate(event) {
            const table = event.table;
            const slotsIdsToDisable = table.reservations_pivot.data
                .map((rp) => {
                    return rp.all_slots_used.data.map((s) => s.id);
                })
                .flat()
                .filter((value, index, self) => self.indexOf(value) === index);

            if (this.seatingPlan) {
                const roomFound = this.seatingPlan.rooms.find((r) => r.id === table.room.id);

                if (roomFound) {
                    const tableFound = roomFound.entities.find((e) => e.type === "table" && e.id === table.id);

                    if (tableFound) {
                        tableFound.slots.forEach((s) => {
                            if (slotsIdsToDisable.includes(s.id)) {
                                s.entityEnabled = false;
                            } else {
                                s.entityEnabled = true;
                            }
                        });
                    }

                    const groupsFound = roomFound.entities.filter((e) => {
                        return e.type === "group" && e.tables.find((t) => t.id === table.id);
                    });

                    groupsFound.forEach((group) => {
                        const groupTableFound = group.tables.find((t) => t.id === table.id);

                        if (groupTableFound) {
                            groupTableFound.slots.forEach((s) => {
                                if (slotsIdsToDisable.includes(s.id)) {
                                    s.entityEnabled = false;
                                } else {
                                    s.entityEnabled = true;
                                }
                            });
                        }

                        group.slots.forEach((s, slotIndex) => {
                            if (group.tables.find((t) => !t.slots[slotIndex].entityEnabled)) {
                                s.entityEnabled = false;
                            } else {
                                s.entityEnabled = true;
                            }
                        });
                    });
                }
            }
        },
        releaseGroup(entity, check = true, remove = false) {
            const slot_id = this.selected_destination_restaurant ? this.selected_destination_restaurant_slot_id : this.slot_id;
            if (!check || !this.isGroupFromBaseReservation(entity)) {
                const url =
                    `/api/restaurants/${this.selected_restaurant_id}/services/slots/${slot_id}` +
                    `/groups/reserved/${entity.id}?date=${this.reservation_date.toISODate()}`;

                axios
                    .delete(url)
                    .then((response) => {
                        //ok socket event should be fired and will update front
                    })
                    .catch((error) => {
                        this.$notify({
                            group: "notification",
                            type: "Error",
                            title: this.getErrorMsgFromErrorResponse(error),
                        });

                        this.vueMultiSelect.entitiesSelected.push(entity);
                    });
            } else if (remove) {
                this.vueMultiSelect.entitiesSelected = this.vueMultiSelect.entitiesSelected.filter((e) => e.type != entity.type && e.id != entity.id);
            }
        },
        reserveTable(entity) {
            const slot_id = this.selected_destination_restaurant ? this.selected_destination_restaurant_slot_id : this.slot_id;
            if (!this.isTableFromBaseReservation(entity)) {
                const duration = `${this.duration.HH}:${this.duration.mm}`;
                const url =
                    `/api/restaurants/${this.selected_restaurant_id}/services/slots/${slot_id}` +
                    `/tables/reserved/${entity.id}` +
                    `?date=${this.reservation_date.toISODate()}&duration=${duration}&pax=${this.nb_pers_total}`;
                axios
                    .post(url)
                    .then(() => {
                        //ok socket event should be fired and will update front
                    })
                    .catch(() => {
                        this.$notify({
                            group: "notification",
                            type: "Error",
                            title: this.$tl("errors.booking.seatingPlan.table.notAvailable"),
                        });

                        const index = this.vueMultiSelect.entitiesSelected.findIndex((e) => e.type === "table" && e.id === entity.id);

                        if (index > -1) {
                            this.vueMultiSelect.entitiesSelected.splice(index, 1);
                        }
                    });
            }
        },
        postForm: function (e) {
            e.preventDefault();
            this.formLoading = true;
            this.formErrors = null;
            this.saveResa();
        },
        releaseTable(entity, checks = true, remove = false) {
            const slot_id = this.selected_destination_restaurant ? this.selected_destination_restaurant_slot_id : this.slot_id;

            if (!checks || !this.isTableFromBaseReservation(entity)) {
                const url =
                    `/api/restaurants/${this.selected_restaurant_id}/services/slots/${slot_id}` +
                    `/tables/reserved/${entity.id}?date=${this.reservation_date.toISODate()}`;

                axios
                    .delete(url)
                    .then(() => {
                        //ok socket event should be fired and will update front
                    })
                    .catch((error) => {
                        this.$notify({
                            group: "notification",
                            type: "Error",
                            title: this.getErrorMsgFromErrorResponse(error),
                        });

                        if (checks) {
                            this.vueMultiSelect.entitiesSelected.push(entity);
                        }
                    });
            } else if (remove) {
                this.vueMultiSelect.entitiesSelected = this.vueMultiSelect.entitiesSelected.filter((e) => e.type != entity.type && e.id != entity.id);
            }
        },
        isTableFromBaseReservation(table) {
            if (this.baseReservation) {
                const baseReservationTablesId = this.baseReservation.tables.data.map((t) => t.id);

                return baseReservationTablesId.includes(table.id);
            }

            return false;
        },
        isGroupFromBaseReservation(group) {
            if (this.baseReservation) {
                const baseReservationTablesId = this.baseReservation.tables.data.map((t) => t.id);

                return group.tables.filter((t) => !baseReservationTablesId.includes(t.id)).length === 0;
            }
            return false;
        },
        customTablesLabel({ name, type }) {
            switch (type) {
                case "table":
                    return `${this.$tl("labels.booking.seatingPlan.table.title")} ${name}`;
                case "group":
                    return `${this.$tl("labels.booking.seatingPlan.group.title")} ${name}`;
            }
        },
        getTableFormattedForMultiSelect(table, isDisabled, slot) {
            if (table.enabled) {
                if (isDisabled === false) {
                    for (const entity of this.vueMultiSelect.entitiesSelected) {
                        if (entity.type === "group") {
                            if (entity.tables.filter((t) => t.id === table.id).length > 0) {
                                isDisabled = true;
                                break;
                            }
                        }
                    }
                }

                if (isDisabled === false && this.vueMultiSelect.paxFull) {
                    const ids = this.vueMultiSelect.entitiesSelected.filter((e) => e.type === "table").map((e) => e.id);

                    isDisabled = !ids.includes(table.id);
                }

                const foundEntity = this.vueMultiSelect.entitiesSelected.find((e) => {
                    switch (e.type) {
                        case "table":
                            return e.id === table.id;
                        case "group":
                            return e.tables.find((t) => t.id === table.id);
                    }
                });

                if (
                    !foundEntity &&
                    (!slot.entityEnabled || !this.isTableAvailableOnFutureSlots(table)) &&
                    (!this.baseReservation || !this.baseReservation.tables.data.find((t) => t.id == table.id))
                ) {
                    return null;
                }

                return {
                    ...table,
                    type: "table",
                    $isDisabled: isDisabled,
                };
            }

            return null;
        },
        isTableAvailableOnFutureSlots(table) {
            if (this.service && this.slotSelected && this.reservationStartsAt && this.reservationEndsAt) {
                const slots = table.slots.filter((s) => {
                    if (this.slotSelected !== s.id) {
                        const slotHourStart = this.getSlotHourStart(s);
                        return this.reservationStartsAt <= slotHourStart && slotHourStart < this.reservationEndsAt;
                    }

                    return false;
                });

                return !slots.some((s) => {
                    return s.entityEnabled === false;
                });
            }

            return false;
        },
        getGroupFormattedForMultiSelect(group, isDisabled, slot) {
            if (group.enabled) {
                if (isDisabled === false) {
                    let tablesIds = this.vueMultiSelect.entitiesSelected.filter((e) => e.type === "table").map((t) => t.id);

                    const groupsSelected = this.vueMultiSelect.entitiesSelected.filter((e) => e.type === "group");

                    const groupsIds = groupsSelected.map((g) => g.id);

                    if (!groupsIds.includes(group.id)) {
                        groupsSelected.forEach((g) => {
                            tablesIds = tablesIds.concat(g.tables.map((t) => t.id));
                        });

                        tablesIds = this.$_.uniq(tablesIds);
                        for (var t of group.tables) {
                            if (tablesIds.includes(t.id)) {
                                isDisabled = true;
                                break;
                            }
                        }
                    }
                }

                if (isDisabled === false && this.vueMultiSelect.paxFull) {
                    const ids = this.vueMultiSelect.entitiesSelected.filter((e) => e.type === "group").map((e) => e.id);

                    isDisabled = !ids.includes(group.id);
                }

                const tablesIds = group.tables.map((t) => t.id);
                const found = this.vueMultiSelect.entitiesSelected.find((e) => {
                    switch (e.type) {
                        case "table":
                            return tablesIds.includes(e.id);
                            break;
                        case "group":
                            return e.id === group.id;
                            break;
                    }
                });

                if (
                    !found &&
                    group.tables.filter((t) => {
                        const tableSlot = t.slots.find((s) => s.id == slot.id);
                        return (
                            (!tableSlot.entityEnabled || !this.isTableAvailableOnFutureSlots(t)) &&
                            (!this.baseReservation || !this.baseReservation.tables.data.find((rt) => rt.id == t.id))
                        );
                    }).length > 0
                ) {
                    return null;
                }

                return {
                    ...group,
                    type: "group",
                    $isDisabled: isDisabled,
                };
            }

            return null;
        },
        setSelectedMenusToNbPers(nb) {
            nb = nb && isNaN(Number.parseInt(nb)) ? 0 : Number.parseInt(nb);

            if (nb === 0) {
                this.$set(this, "choosen_menus", {});
            } else {
                for (const menuIndex in this.choosen_menus) {
                    const menu = this.choosen_menus[menuIndex];
                    const menuVal = isNaN(Number.parseInt(menu.value)) ? 0 : Number.parseInt(menu.value);

                    if (menuVal > nb) {
                        this.$set(this.choosen_menus[menuIndex], "value", nb);
                        this.$nextTick(() => {
                            if (typeof this.$refs[`ref_menu_${menu.id}`] !== "undefined") {
                                this.$refs[`ref_menu_${menu.id}`][0].selectedIndex = nb;
                                for (const optIndex in menu.options) {
                                    const option = menu.options[optIndex];
                                    const optionValue = isNaN(Number.parseInt(option.value)) ? 0 : Number.parseInt(option.value);

                                    if (optionValue > nb) {
                                        this.$set(this.choosen_menus[menuIndex].options[optIndex], "value", nb);
                                        this.$nextTick(() => {
                                            this.setOptionInput(option, menu, nb);
                                        });
                                    }
                                }
                            }
                        });
                    }
                }
            }
        },
        checkVueMultiSelectPaxFull() {
            var paxUsed = 0;
            this.vueMultiSelect.entitiesSelected.forEach((entity) => {
                paxUsed += entity.enabledPax.length > 0 ? Math.max.apply(null, entity.enabledPax) : entity.seats;
            });

            if (paxUsed >= this.nb_pers_total) {
                this.$set(this.vueMultiSelect, "paxFull", true);
            } else {
                this.$set(this.vueMultiSelect, "paxFull", false);
            }
        },
        getSlotHourStart(slot) {
            const slotArray = slot.hour_start.split(":");

            return this.reservation_date.startOf("day").plus({
                hours: slotArray[0],
                minutes: slotArray[1],
            });
        },
        associateClient(event) {
            this.setClient(event);
            this.canOpenSearchClientModal = false;
        },
    },
    watch: {
        ignore_placement(newVal) {
            if (!newVal && !this.can_place) this.$emit("enable-save-button", false);
        },
        reservation_date: {
            handler: function (newVal) {
                this.setMultiSelectSelectedEntities([]);
                this.unregisterServiceSockets();
                this.registerServiceSockets();
            },
        },
        "$route.query.date": {
            immediate: true,
            handler: function (newVal) {
                if (
                    this.$route.matched.some(({ name }) => {
                        return name === "booking.restaurants.reservations";
                    })
                ) {
                    if (!newVal) {
                        this.reservation_date = this.getDateTime();
                    } else {
                        this.reservation_date = this.getDateTime(newVal, false);
                    }
                }
            },
        },
        nb_pers: {
            handler: function (newVal) {
                this.checkVueMultiSelectPaxFull();
            },
        },
        nb_children: {
            handler: function (newVal) {
                this.checkVueMultiSelectPaxFull();
            },
        },
        nb_pers_total: {
            handler: function (newVal, oldVal) {
                if (newVal) {
                    this.setSelectedMenusToNbPers(this.nb_pers_total);
                    if (this.is_duration_customized === false) {
                        let foundDuration = false;

                        if (this.service) {
                            this.service.paxDurations.data.forEach((item) => {
                                if (item.pax === this.nb_pers_total) {
                                    const durationsArray = item.duration.split(":");

                                    this.$set(this, "duration", {
                                        HH: `0${durationsArray[0]}`.substr(-2),
                                        mm: `0${durationsArray[1]}`.substr(-2),
                                    });
                                    foundDuration = true;
                                }
                            });

                            if (!foundDuration) {
                                const durationsArray = this.service.duration.split(":");

                                this.$set(this, "duration", {
                                    HH: `0${durationsArray[0]}`.substr(-2),
                                    mm: `0${durationsArray[1]}`.substr(-2),
                                });
                            }
                        }
                    }
                }

                this.$nextTick(() => {
                    if (newVal > 0) {
                        if (this.$_.isEmpty(this.vueMultiSelect.entitiesSelected) && this.nb_pers_total > 0 && this.tablesIdToPreselect.length > 0) {
                            this.setMultiSelectSelectedEntities(
                                this.tablesMultiselectOptions
                                    .map((room) => {
                                        return room.entities.filter((e) => {
                                            return e.type === "table" && this.tablesIdToPreselect.includes(e.id);
                                        });
                                    })
                                    .flat()
                                    .filter((t) => t)
                            );
                        }
                    }
                });
            },
        },
        service: {
            handler: function (newVal) {
                this.unregisterServiceSockets();
                if (this.is_duration_customized === false && newVal) {
                    this.service.paxDurations.data.forEach((item) => {
                        if (item.pax === this.nb_pers_total) {
                            const durationsArray = item.duration.split(":");

                            this.$set(this, "duration", {
                                HH: `0${durationsArray[0]}`.substr(-2),
                                mm: `0${durationsArray[1]}`.substr(-2),
                            });
                        }
                    });
                }
                if (newVal) {
                    this.registerServiceSockets();
                }

                this.setMultiSelectSelectedEntities([]);
            },
        },
        option_bank: {
            handler: function (newVal) {
                if (newVal === this.OPTION_BANK_PAYMENT.value || newVal === this.OPTION_BANK_FOOTPRINT.value) {
                    this.send_confirmation_disabled = true;
                    this.send_confirmation = true;
                } else {
                    this.send_confirmation_disabled = false;
                }
            },
        },
        choosen_menus: {
            handler: function (newVal) {
                if (this.service) {
                    const prepayment_percent = this.service.prepayment_percent || 100;

                    this.amount_to_pay = this.menusTotalPrice * (prepayment_percent / 100);
                }
            },
            deep: true,
        },
        choosen_general_options: {
            handler: function (newVal) {
                if (this.service) {
                    const prepayment_percent = this.service.prepayment_percent || 100;

                    this.amount_to_pay = this.menusTotalPrice * (prepayment_percent / 100);
                }
            },
        },
        areMenusUnique: {
            handler: function (newVal) {
                if (newVal === true) {
                    for (var key in this.choosen_menus) {
                        const menu = this.choosen_menus[key];

                        this.$refs[`ref_menu_${menu.id}`][0].value = 0;
                    }

                    this.$set(this, "choosen_menus", {});
                }
            },
        },
        duration: {
            handler: function (newVal, oldVal) {
                if (newVal && oldVal && oldVal.HH !== newVal.HH && oldVal.mm !== newVal.mm) {
                    this.launchChecks();
                }
            },
        },
        "vueMultiSelect.entitiesSelected": {
            handler: function (newVal) {
                if (newVal.length > 0 || this.can_place) this.$emit("enable-save-button", true);
                else if (!this.can_place) this.$emit("enable-save-button", false);
                this.checkVueMultiSelectPaxFull();
                this.checkVueMultiSelectDisabledTables();
            },
        },
    },
    computed: {
        associateClasses() {
            return !this.hasClient ? "h-100 d-flex justify-content-center align-items-center" : "";
        },
        canAddOptionBank() {
            if (!this.reservation_date || !this.slot_hour) return false;
            let resaDate = this.getSlotHourStart({ hour_start: this.slot_hour });
            if (resaDate.minus({ hours: 1, minutes: 30 }) < this.getDateTime()) return false;
            if (
                this.baseReservation != null &&
                this.baseReservation.option_bank === null &&
                this.baseReservation.status === this.RESERVATION_STATUS_VALIDATED.value
            )
                return true;
            return false;
        },
        isFootprintAvailable() {
            if (!this.restaurant) return "";
            if (this.restaurant.payment_notif_settings.ask_footprint_by === "mail" && (!this.client || !this.client.email))
                return this.$tl("infos.booking.reservations.footprint.needClientEmail");
            if (this.restaurant.payment_notif_settings.ask_footprint_by === "sms" && (!this.client || !this.client.phone || !this.client.phone.raw))
                return this.$tl("infos.booking.reservations.footprint.phoneNumber");
            return true;
        },
        isPaymentAvailable() {
            if (!this.restaurant) return "";
            if (this.restaurant.payment_notif_settings.ask_payment_by === "mail" && (!this.client || !this.client.email))
                return this.$tl("infos.booking.reservations.payment.needClientEmail");
            if (this.restaurant.payment_notif_settings.ask_payment_by === "sms" && (!this.client || !this.client.phone || !this.client.phone.raw))
                return this.$tl("infos.booking.reservations.payment.phoneNumber");
            return true;
        },
        slotSelected() {
            if (this.selected_destination_restaurant) {
                return this.selected_destination_restaurant_slot_id;
            }

            return this.slot_id;
        },
        selected_restaurant_id() {
            if (this.selected_destination_restaurant) {
                return this.selected_destination_restaurant.id;
            }

            return Number.parseInt(this.restaurant_id);
        },
        nb_pers_total() {
            let nb_pers = 0;
            let nb_children = 0;
            let tmp = 0;

            if (!isNaN((tmp = parseInt(this.nb_pers)))) {
                nb_pers = tmp;
            }

            if (!isNaN((tmp = parseInt(this.nb_children)))) {
                nb_children = tmp;
            }

            return nb_pers + nb_children;
        },
        seatingPlan() {
            let seatingPlan = null;

            if (this.service) {
                seatingPlan = this.service.seatingPlanFilled;
            }

            return seatingPlan;
        },
        tablesMultiselectOptions() {
            var result = [];
            let roomIdSelected = null;

            if (this.seatingPlan && this.slotSelected) {
                if (this.vueMultiSelect.entitiesSelected.length > 0) {
                    roomIdSelected = this.vueMultiSelect.entitiesSelected[0].roomId;
                }

                this.seatingPlan.rooms.forEach((room) => {
                    if (room.enabled === 1) {
                        var roomResult = {
                            id: room.id,
                            roomName: room.name,
                            entities: [],
                        };

                        room.entities.forEach((entity) => {
                            const slot = entity.slots.find((s) => s.id == this.slotSelected);

                            if (entity.enabled && slot) {
                                let entityFormated = null;
                                let isDisabled = false;

                                if (roomIdSelected !== null && roomIdSelected !== room.id) {
                                    isDisabled = true;
                                }

                                switch (entity.type) {
                                    case "table":
                                        entityFormated = this.getTableFormattedForMultiSelect(entity, isDisabled, slot);
                                        break;
                                    case "group":
                                        entityFormated = this.getGroupFormattedForMultiSelect(entity, isDisabled, slot);
                                        break;
                                }

                                if (entityFormated) {
                                    roomResult.entities.push({
                                        ...entityFormated,
                                        roomId: room.id,
                                    });
                                }
                            }
                        });

                        if (!this.$_.isEmpty(roomResult.entities)) {
                            result.push(roomResult);
                        }
                    }
                });
            }

            return result;
        },
        hasTablesSelected() {
            if (this.seatingPlan) {
                return this.vueMultiSelect.entitiesSelected.length > 0;
            }

            return false;
        },
        tablesSelected() {
            var result = [];
            for (var entity of this.vueMultiSelect.entitiesSelected) {
                switch (entity.type) {
                    case "table":
                        result.push({
                            id: entity.id,
                            pax: this.nb_pers_total,
                        });
                        break;
                    case "group":
                        for (const tableIndex in entity.tables) {
                            const table = entity.tables[tableIndex];

                            result.push({
                                id: table.id,
                                pax: this.nb_pers_total,
                            });
                        }
                        break;
                }
            }

            return result;
        },
        footprint_date_send() {
            if (this.reservation_date) {
                const nowStartOfDay = this.getDateTime().startOf("day");
                const resaStartOfDay = this.reservation_date.startOf("day");

                if (resaStartOfDay.diff(nowStartOfDay, "days").days <= 6) {
                    return "NOW";
                } else {
                    return this.displayDate(this.reservation_date.minus({ days: 6 }), this.DATE_SHORT);
                }
            }
            return null;
        },
        reservationStartsAt() {
            let datetime = null;

            if (this.service && this.slotSelected) {
                const slot = this.service.slots.data.find((s) => {
                    return s.id == this.slotSelected;
                });

                if (slot) {
                    datetime = this.getSlotHourStart(slot);
                }
            }

            return datetime;
        },
        reservationEndsAt() {
            if (this.reservationStartsAt && this.duration) {
                return this.reservationStartsAt.plus({
                    hours: this.duration.HH,
                    minutes: this.duration.mm,
                });
            }

            return null;
        },
        isImmutable() {
            return this.baseReservation && this.baseReservation.immutable;
        },
    },
};
</script>

<style scoped>
.cursor-disabled {
    cursor: not-allowed !important;
}
</style>
