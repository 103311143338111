export default {
    data() {
        return {
            BO_PAYMENT_TYPE_ONLINE: {
                label: "En ligne",
                value: "online",
            },
            BO_PAYMENT_TYPE_MANUAL: {
                label: "Manuel",
                value: "manual",
            },
        };
    },
    methods: {
        getBoPaymentTypeLabel(value) {
            const found = this.ALL_BO_PAYMENT_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_BO_PAYMENT_TYPES() {
            return [this.BO_PAYMENT_TYPE_ONLINE, this.BO_PAYMENT_TYPE_MANUAL];
        },
    },
};
