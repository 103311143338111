<template>
    <div>
        <div class="pointer" @click="show = !show">
            <strong>{{ categoryName }}</strong>
            <feather :type="show ? 'chevron-down' : 'chevron-right'" />
        </div>
        <div v-show="show" class="p-1">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
        };
    },
    props: {
        categoryName: {
            type: String,
            required: true,
        },
    },
};
</script>
