var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.available_general_options, function (o, oIndex) {
        return _c("div", { staticClass: "one-menu mb-3" }, [
          _c("div", { staticClass: "row text-left mb-0" }, [
            _c("div", { staticClass: "col-sm-8" }, [
              _c("label", { staticClass: "mb-1" }, [
                _vm._v(
                  _vm._s(o.name) +
                    " - " +
                    _vm._s(
                      _vm.formatCurrency(
                        o.price,
                        _vm.getRestaurantCurrency(_vm.restaurantId)
                      )
                    )
                ),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "d-block text-muted mb-2" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(o.description) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              o.quantity_type === "mandatory_for_each"
                ? _c("div", [
                    _c(
                      "select",
                      {
                        ref: `ref_general_option_${o.id}`,
                        refInFor: true,
                        staticClass: "custom-select",
                        attrs: { disabled: _vm.readonly },
                        on: {
                          change: function ($event) {
                            return _vm.chooseGeneralOption(o, $event)
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "0" } }, [_vm._v("--")]),
                        _vm._v(" "),
                        _c(
                          "option",
                          { domProps: { value: _vm.nb_pers_total } },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.nb_pers_total) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : o.quantity_type === "limited_by_pers"
                ? _c(
                    "select",
                    {
                      ref: `ref_general_option_${o.id}`,
                      refInFor: true,
                      staticClass: "custom-select",
                      staticStyle: { height: "40px", padding: "10px 15px" },
                      attrs: { disabled: _vm.readonly },
                      on: {
                        change: function ($event) {
                          return _vm.chooseGeneralOption(o, $event)
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "0" } }, [_vm._v("0")]),
                      _vm._v(" "),
                      _vm._l(_vm.nb_pers_total, function (option, index) {
                        return _c(
                          "option",
                          { domProps: { value: index * 1 + 1 } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(index * 1 + 1) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : o.quantity_type === "unlimited"
                ? _c("input", {
                    ref: `ref_general_option_${o.id}`,
                    refInFor: true,
                    staticClass: "form-control",
                    staticStyle: { height: "40px", padding: "10px 15px" },
                    attrs: {
                      type: "number",
                      step: "1",
                      disabled: _vm.readonly,
                      min: "0",
                      value: "0",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.chooseGeneralOption(o, $event)
                      },
                    },
                  })
                : _vm._e(),
            ]),
          ]),
        ])
      }),
      _vm._v(" "),
      _vm.available_general_options_trashed
        ? [
            _c("div", { staticClass: "sepa" }),
            _vm._v(" "),
            _vm._l(_vm.deleted_general_options, function (o, oIndex) {
              return _c("div", { staticClass: "one-menu mb-3" }, [
                _c("div", { staticClass: "row text-left mb-0" }, [
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("label", { staticClass: "mb-1" }, [
                      _vm._v(
                        _vm._s(
                          _vm.available_general_options_trashed.find(
                            (mt) => o.id === mt.id
                          ).name
                        ) +
                          " -\n                        " +
                          _vm._s(
                            _vm.formatCurrency(
                              _vm.available_general_options_trashed.find(
                                (mt) => o.id === mt.id
                              ).price,
                              _vm.getRestaurantCurrency(_vm.restaurantId)
                            )
                          )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("small", { staticClass: "d-block text-muted mb-2" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.available_general_options_trashed.find(
                              (mt) => o.id === mt.id
                            ).description
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _vm.available_general_options_trashed.find(
                      (mt) => o.id === mt.id
                    ).quantity_type === "mandatory_for_each"
                      ? _c("div", [
                          _c(
                            "select",
                            {
                              ref: `ref_general_option_${o.id}`,
                              refInFor: true,
                              staticClass: "custom-select",
                              attrs: { disabled: _vm.readonly },
                              on: {
                                change: function ($event) {
                                  return _vm.chooseGeneralOption(o, $event)
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "0" } }, [
                                _vm._v("--"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "option",
                                { domProps: { value: _vm.nb_pers_total } },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.nb_pers_total) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm.available_general_options_trashed.find(
                          (mt) => o.id === mt.id
                        ).quantity_type === "limited_by_pers"
                      ? _c(
                          "select",
                          {
                            ref: `ref_general_option_${o.id}`,
                            refInFor: true,
                            staticClass: "custom-select",
                            staticStyle: {
                              height: "40px",
                              padding: "10px 15px",
                            },
                            attrs: { disabled: _vm.readonly },
                            on: {
                              change: function ($event) {
                                return _vm.chooseGeneralOption(o, $event)
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "0" } }, [
                              _vm._v("0"),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.nb_pers_total, function (option, index) {
                              return _c(
                                "option",
                                { domProps: { value: index * 1 + 1 } },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(index * 1 + 1) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      : _vm.available_general_options_trashed.find(
                          (mt) => o.id === mt.id
                        ).quantity_type === "unlimited"
                      ? _c("input", {
                          ref: `ref_general_option_${o.id}`,
                          refInFor: true,
                          staticClass: "form-control",
                          staticStyle: { height: "40px", padding: "10px 15px" },
                          attrs: {
                            type: "number",
                            step: "1",
                            min: "0",
                            disabled: _vm.readonly,
                            value: "0",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.chooseGeneralOption(o, $event)
                            },
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ])
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }