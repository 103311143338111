<template>
    <div class="mb-4">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1 ml-2">
            <h6 class="title mt-2">{{ restaurant.name }}</h6>
        </div>

        <div class="border-light b-radius-20 p-4">
            <div class="row">
                <div class="col-12 mb-3">
                    <strong>{{ $tl("labels.licences") }}</strong>
                </div>
            </div>
            <div class="row">
                <div
                    class="col p-2 d-flex flex-column align-items-center"
                    v-for="MODULE_TYPE in ALL_MODULES_TYPES_YSERVICES"
                    :key="MODULE_TYPE.value">
                    <strong class="d-block mb-2">{{ getModuleTypeLabel(MODULE_TYPE.value) }}</strong>
                    <router-link
                        v-if="hasSubscription(MODULE_TYPE.value, restaurant)"
                        :to="{ name: getHomeUrlForModule(MODULE_TYPE), params: { restaurant_id: restaurant.id } }"
                        class="btn btn-primary btn-circle btn-sm">
                        {{ $tl("labels.modules.actions.goTo") }}
                    </router-link>
                    <button v-else type="button" class="btn btn-more-infos btn-circle btn-sm mr-2" @click="openModal(MODULE_TYPE.value)">
                        {{ $tl("labels.subscriptions.moreInfos") }}
                    </button>
                </div>
            </div>
        </div>
        <edit-sms-settings
            v-if="isSmsActivated"
            :is-stand-alone="true"
            :restaurant="restaurant"
            :formErrors="formErrors"
            @update-errors="formErrors = $event" />
        <ChooseCardPaymentSystem
            v-if="restaurant.prefered_payment_system"
            class="border-light b-radius-20 p-4 mt-3"
            :is-stand-alone="true"
            :restaurantId="restaurant.id"
            :prefered_payment_system="restaurant.prefered_payment_system"
            @set-prefered-payment-system="restaurant.prefered_payment_system = $event" />
        <ask-for-subscription v-if="canDisplayModal" :module="currentModule" :restaurant="restaurant" @close="canDisplayModal = false" />
    </div>
</template>
<script>
import ModuleTypesEnum from "../../../../mixins/enums/ModuleTypesEnum.js";
import AskForSubscription from "../../../../components/Modals/subscriptions/AskForSubscription.vue";
import ChooseCardPaymentSystem from "../../../../components/Default/Restaurants/ChooseCardPaymentSystem.vue";
import EditSmsSettings from "../../../../components/sms/EditSmsSettings.vue";

export default {
    props: {
        restaurant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            canDisplayModal: false,
            currentModule: null,
            formErrors: null,
        };
    },
    mixins: [ModuleTypesEnum],
    computed: {
        isSmsActivated() {
            return this.$store.getters["sms/getActivated"];
        },
    },
    components: {
        AskForSubscription,
        ChooseCardPaymentSystem,
        EditSmsSettings,
    },
    methods: {
        getHomeUrlForModule(moduleType) {
            if (moduleType.value === this.MODULE_TYPE_BOOKING.value) {
                return "booking.restaurants.reservations";
            }

            return `${moduleType.urlValue}.home`;
        },
        hasSubscription(module, restaurant) {
            if (restaurant.subscriptions && restaurant.subscriptions.data) {
                return restaurant.subscriptions.data.some((subscription) => subscription.module && subscription.module.type === module);
            }
            return false;
        },
        openModal(module) {
            this.currentModule = module;
            this.canDisplayModal = true;
        },
    },
};
</script>
