var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5 campaign" },
    [
      _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [_vm._v("Campagnes")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success btn-circle",
                  on: { click: _vm.addCampaign },
                },
                [
                  _c("feather", { attrs: { type: "plus" } }),
                  _vm._v(
                    "\n                    Créer une campagne\n                "
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent")
        : _c(
            "div",
            [
              _c("div", { staticClass: "row m-0 pt-2" }, [
                _vm.campaigns.length > 0
                  ? _c("div", { staticClass: "container-fluid" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 mt-2" }, [
                          _c("div", { staticClass: "border-bottom" }, [
                            _c(
                              "table",
                              {
                                staticClass:
                                  "capitalize table table-book table-striped table-sm",
                              },
                              [
                                _c("thead", { staticClass: "border-bottom" }, [
                                  _c("tr", [
                                    _c("th", [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "feather feather-user sc-dnqmqq jxshSx none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "24",
                                            height: "24",
                                            viewBox: "0 0 24 24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            "stroke-width": "2",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "aria-hidden": "true",
                                            "data-reactid": "1326",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("circle", {
                                            attrs: {
                                              cx: "12",
                                              cy: "7",
                                              r: "4",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "none-mobile" },
                                        [_vm._v("Nom")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "feather feather-user sc-dnqmqq jxshSx none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "24",
                                            height: "24",
                                            viewBox: "0 0 24 24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            "stroke-width": "2",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "aria-hidden": "true",
                                            "data-reactid": "1326",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("circle", {
                                            attrs: {
                                              cx: "12",
                                              cy: "7",
                                              r: "4",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "none-mobile" },
                                        [_vm._v("Type")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "feather feather-user sc-dnqmqq jxshSx none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "24",
                                            height: "24",
                                            viewBox: "0 0 24 24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            "stroke-width": "2",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "aria-hidden": "true",
                                            "data-reactid": "1326",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("circle", {
                                            attrs: {
                                              cx: "12",
                                              cy: "7",
                                              r: "4",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "none-mobile" },
                                        [_vm._v("Statut")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "feather feather-user sc-dnqmqq jxshSx none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "24",
                                            height: "24",
                                            viewBox: "0 0 24 24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            "stroke-width": "2",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "aria-hidden": "true",
                                            "data-reactid": "1326",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("circle", {
                                            attrs: {
                                              cx: "12",
                                              cy: "7",
                                              r: "4",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "none-mobile" },
                                        [_vm._v("Date d'envoi")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "feather feather-user sc-dnqmqq jxshSx none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "24",
                                            height: "24",
                                            viewBox: "0 0 24 24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            "stroke-width": "2",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "aria-hidden": "true",
                                            "data-reactid": "1326",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("circle", {
                                            attrs: {
                                              cx: "12",
                                              cy: "7",
                                              r: "4",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "none-mobile" },
                                        [_vm._v("Nb Envois")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "feather feather-user sc-dnqmqq jxshSx none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "24",
                                            height: "24",
                                            viewBox: "0 0 24 24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            "stroke-width": "2",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "aria-hidden": "true",
                                            "data-reactid": "1326",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("circle", {
                                            attrs: {
                                              cx: "12",
                                              cy: "7",
                                              r: "4",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "none-mobile" },
                                        [_vm._v("Nb Ouvertures")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "feather feather-user sc-dnqmqq jxshSx none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "24",
                                            height: "24",
                                            viewBox: "0 0 24 24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            "stroke-width": "2",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "aria-hidden": "true",
                                            "data-reactid": "1326",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("circle", {
                                            attrs: {
                                              cx: "12",
                                              cy: "7",
                                              r: "4",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "none-mobile" },
                                        [_vm._v("Nb Clics")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "none-mobile" }, [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "feather feather-user sc-dnqmqq jxshSx none-desk",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                          },
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "24",
                                            height: "24",
                                            viewBox: "0 0 24 24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            "stroke-width": "2",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "aria-hidden": "true",
                                            "data-reactid": "1326",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("circle", {
                                            attrs: {
                                              cx: "12",
                                              cy: "7",
                                              r: "4",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "none-mobile" },
                                        [_vm._v("Actions")]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.campaigns, function (campaign) {
                                    return _c("tr", { key: campaign.id }, [
                                      _c("td", [_vm._v(_vm._s(campaign.name))]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getCampaignTypeLabel(
                                              campaign.type
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { class: _vm.getStatusClass(campaign) },
                                        [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                _vm.getStatusLabel(campaign)
                                              ) +
                                              "\n                                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              campaign.sent_at != null
                                                ? _vm
                                                    .moment(campaign.sent_at)
                                                    .format("LLL")
                                                : "-"
                                            ) +
                                            "\n                                        "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        campaign.is_sending
                                          ? _c("span", [
                                              _vm._v(
                                                "En cours d'envoi (" +
                                                  _vm._s(
                                                    campaign.nb_sent || 0
                                                  ) +
                                                  ")"
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  campaign.sent_at != null
                                                    ? campaign.nb_sent
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        campaign.type ==
                                        _vm.CAMPAIGN_TYPE_SMS.value
                                          ? _c("span", [_vm._v("Indisponible")])
                                          : campaign.sent_at != null
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(campaign.nb_opened)
                                              ),
                                            ])
                                          : _c("span", [_vm._v("-")]),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        campaign.type ==
                                        _vm.CAMPAIGN_TYPE_SMS.value
                                          ? _c("span", [_vm._v("Indisponible")])
                                          : campaign.sent_at != null
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(campaign.nb_clicked)
                                              ),
                                            ])
                                          : _c("span", [_vm._v("-")]),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "none-mobile" }, [
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: {
                                                  content: "Voir les détails",
                                                },
                                                expression:
                                                  "{ content: 'Voir les détails' }",
                                              },
                                            ],
                                            staticClass:
                                              "btn btn-sm btn-outline-secondary btn-square none-tablet",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showCampaign(
                                                  campaign.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("feather", {
                                              attrs: { type: "eye" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        !campaign.sent_at
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: {
                                                      content: "Éditer",
                                                    },
                                                    expression:
                                                      "{ content: 'Éditer' }",
                                                  },
                                                ],
                                                staticClass:
                                                  "btn btn-sm btn-success btn-square",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editCampaign(
                                                      campaign.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("feather", {
                                                  attrs: { type: "edit" },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _c(
                      "div",
                      { staticClass: "col-lg-6 offset-lg-3 pt-5 text-center" },
                      [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4 mb-3" },
                          [
                            _c("h4", { staticClass: "mb-4" }, [
                              _vm._v("Vous n'avez pas encore créé de campagne"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success btn-circle",
                                on: {
                                  click: function ($event) {
                                    return _vm.addCampaign()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Créer ma première campagne\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "mt-5 none-mobile",
                              attrs: {
                                width: "450",
                                src: "/images/no-campaign.png",
                                alt: "No Show",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "mt-3 none-desk",
                              attrs: {
                                width: "300",
                                src: "/images/no-campaign.png",
                                alt: "No Show",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
              ]),
              _vm._v(" "),
              _vm.showCampaignAddModal
                ? _c("add-campaign-modal", {
                    ref: "addCampaignModal",
                    attrs: { campaign_id: _vm.campaignId },
                    on: {
                      close: function ($event) {
                        _vm.showCampaignAddModal = false
                      },
                      saved: _vm.campaignSaved,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showCampaignShowModal
                ? _c("show-campaign-modal", {
                    attrs: { campaign_id: _vm.campaignId },
                    on: {
                      close: function ($event) {
                        _vm.showCampaignShowModal = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }