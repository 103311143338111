var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.command.logs &&
    _vm.command.logs.data &&
    _vm.command.logs.data.length > 0
      ? _c(
          "div",
          { staticClass: "mt-3" },
          _vm._l(_vm.command.logs.data, function (log) {
            return _c("ShowCommandLog", { key: log.id, attrs: { log: log } })
          }),
          1
        )
      : _c("span", { staticClass: "text-muted mt-3 d-inline-block" }, [
          _vm._v(
            "Aucune modification n'a été enregistrée pour cette commande."
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }