<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ title }}</h5>
        <div slot="body">
            <showNewFeature :markAsRead="markAsRead" :new_feature_id="new_feature_id" @set-title="title = $event" />
        </div>
        <div slot="footer" class="d-flex">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">Fermer</button>
        </div>
    </modal>
</template>

<script>
import showNewFeature from "../../NewFeatures/showNewFeature";

export default {
    data() {
        return {
            loading: 0,
            title: "",
        };
    },
    props: {
        new_feature_id: {
            default: null,
        },
        markAsRead: {
            default: true,
        },
    },
    methods: {
        close() {
            this.$emit("hide");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        showNewFeature,
    },
};
</script>
