<template>
    <modal @close="closeFromModal" style="overflow: auto" :modalContainerStyle="`overflow: visible`">
        <h5 slot="header">Annulation de la réservation</h5>
        <div slot="body">
            <LoaderComponent v-if="loading" />
            <span class="text-danger" v-if="error">{{ error }}</span>
            <template v-if="reservation && !loading">
                <div class="border-light b-radius-20 p-4">
                    <div class="row">
                        <div class="col-12">
                            Le client a effectué un paiement de
                            {{ formatCurrency(reservation.payment.amount_captured / 100) }}.<br />
                            <small class="text-muted"
                                >Vous pouvez rembourser (entièrement ou partiellement) ce client, ou annuler la réservation sans entrainer de
                                remboursement.</small
                            >
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-5">
                            <label>Type d'annulation <small>*</small></label>
                        </div>
                        <div class="col-md-7">
                            <div class="row">
                                <div class="col-12">
                                    <div class="radio">
                                        <label class="container-box">
                                            <input type="radio" v-model="reservation_data.cancel_type" value="refund" />
                                            <span class="checkmark"></span> Avec remboursement
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 mt-2">
                                    <div class="radio">
                                        <label class="container-box">
                                            <input type="radio" v-model="reservation_data.cancel_type" value="cancel_only" />
                                            <span class="checkmark"></span> Sans remboursement
                                        </label>
                                    </div>
                                </div>
                                <ShowErrors class="col-12" :errors="errors" errorKey="cancel_type" />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4" v-if="reservation_data.cancel_type === 'refund'">
                        <div class="col-md-5">
                            <label>Type de remboursement <small>*</small></label>
                        </div>
                        <div class="col-md-7">
                            <div class="d-inline-block align-middle lead-switch">
                                Partiel
                                <input
                                    type="checkbox"
                                    class="switch align-self-center is-rounded"
                                    :true-value="true"
                                    :false-value="false"
                                    v-model="reservation_data.full_refund" />
                                <label @click="reservation_data.full_refund = !reservation_data.full_refund"></label>
                                Total
                            </div>
                            <ShowErrors class="d-block" :errors="errors" errorKey="full_refund" />
                        </div>
                    </div>
                    <div class="row mt-2" v-if="reservation_data.cancel_type === 'refund' && !reservation_data.full_refund">
                        <div class="col-md-5">
                            <label>Montant à rembourser <small>*</small></label>
                        </div>
                        <div class="col-md-7">
                            <input
                                type="number"
                                :min="1"
                                :max="reservation.payment.amount_captured / 100"
                                step="1"
                                class="form-control"
                                v-model="reservation_data.amount_to_refund" />
                            <ShowErrors class="d-block" :errors="errors" errorKey="amount_to_refund" />
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="btn btn-secondary btn-circle btn btn-sm" @click="close">Annuler</button>
            <button :disabled="!reservation" :class="{ disabled: !reservation }" class="btn btn-success btn-sm btn-circle" @click="cancelReservation">
                Valider
            </button>
        </div>
    </modal>
</template>

<script>
import LoaderComponent from "../../LoaderComponent";
import axios from "axios";
import ShowErrors from "../../errors/ShowErrors";

export default {
    data() {
        return {
            loading: false,
            error: null,
            errors: null,
            reservation: null,
            reservation_data: {
                cancel_type: "refund",
                full_refund: true,
                amount_to_refund: null,
            },
        };
    },
    props: {
        reservation_id: {
            type: Number,
            required: true,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        builtData() {
            return {
                ...this.reservation_data,
            };
        },
    },
    methods: {
        fetchReservation() {
            this.loading = true;
            this.error = null;

            axios
                .get(`/api/restaurants/${this.restaurant_id}/reservations/${this.reservation_id}?include=payment`)
                .then((response) => {
                    this.loading = false;
                    this.reservation = response.data;
                    this.reservation_data.amount_to_refund = this.reservation.payment.amount_captured / 100;
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                    else this.error = this.$tl("errors.common.unknown");
                });
        },
        cancelReservation() {
            this.loading = true;
            this.error = null;
            this.errors = null;

            axios
                .put(`/api/restaurants/${this.restaurant_id}/reservations/${this.reservation_id}/status?status=canceled`, this.builtData)
                .then((response) => {
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: "La réservation a bien été annulée",
                    });
                    this.close();
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                    else this.error = this.$tl("errors.common.unknown");
                });
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
    created() {
        this.fetchReservation();
    },
};
</script>
