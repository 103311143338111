<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ campaign.id !== null ? "Éditer" : "Créer" }} une campagne</h5>
        <div slot="body">
            <LoaderComponent v-if="loading" />
            <campaign-add
                v-else
                ref="campaignAdd"
                :step="step"
                :errors="errors.form"
                :campaign="campaign"
                :clients="clients"
                :credits="credits"
                :is_paying_emails_campaign="is_paying_emails_campaign"
                @setIsPayingEmailsCampaign="$emit('setIsPayingEmailsCampaign', $event)"
                @sendEmailTest="sendEmailTest"
                @sendSmsTest="sendSmsTest"
                @purchaseSmsCampaign="purchaseSmsCampaign()" />
        </div>
        <div slot="footer" class="d-flex">
            <button type="button" class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">Fermer</button>
            <button type="button" class="btn btn-success btn-sm btn-circle ml-2" v-if="step > 1" @click="step--">Étape précédente</button>
            <button type="button" class="btn btn-success btn-sm btn-circle ml-2" v-if="step < 3" @click="step++">Étape suivante</button>
            <button
                type="button"
                class="btn btn-success btn-sm btn-circle ml-2"
                v-if="step !== 3 || campaign.save_type !== 'send'"
                @click="save(false)">
                Enregistrer
            </button>
            <button
                type="button"
                class="btn btn-success btn-sm btn-circle ml-2"
                v-if="step === 3 && campaign.save_type === 'send'"
                @click="save(true)">
                Envoyer
            </button>
        </div>
    </modal>
</template>

<script>
import axios from "axios";
import CampaignAdd from "../../campaign/CampaignAdd";
import LoaderComponent from "../../LoaderComponent";

export default {
    data: function () {
        return {
            loading: false,
            errors: {
                form: null,
            },
            step: 1,
            credits: {
                nb_clients: 0,
                nb_campaign_emails_remaining: 0,
            },
        };
    },
    props: {
        restaurantId: {
            type: Number,
            required: true,
        },
        is_paying_emails_campaign: {
            default: false,
        },
        campaign: {
            type: Object,
            required: true,
        },
        clients: {
            type: Object,
            required: true,
        },
    },
    watch: {
        step(newVal) {
            if (newVal === 3 && this.campaign.type === "email") {
                this.checkEnoughCredits();
            }
        },
    },
    methods: {
        purchaseSmsCampaign() {
            this.save()
                .then((response) => {
                    if (response !== false) {
                        this.$refs.campaignAdd.showPurchaseSmsCampaignModal = true;
                    }
                })
                .catch(() => {});
        },
        checkEnoughCredits() {
            this.loading = true;
            this.errors.form = null;

            this.httpPost(`/api/campaigns/restaurants/${this.restaurantId}/campaigns/emailsCredits`, this.campaign, { handleResolve: false })
                .then((response) => {
                    if (response !== false) {
                        this.credits.nb_clients = response.data.nb_clients;
                        this.credits.nb_campaign_emails_remaining = response.data.nb_campaign_emails_remaining;
                    }
                })
                .finally(() => (this.loading = false));
        },
        sendSmsTest() {
            this.save()
                .then((response) => {
                    if (response !== false) {
                        this.loading = true;
                        this.errors.form = null;

                        axios
                            .post(`/api/campaigns/restaurants/${this.restaurantId}/campaigns/sendSmsTest/${this.campaign.id}`, {
                                tel_test: this.campaign.tel_test,
                                sms_expeditor: this.campaign.sms_expeditor,
                                content: this.campaign.content,
                            })
                            .then((response) => {
                                this.loading = false;
                                this.campaign.nb_sms_test_remaining--;
                                this.notifySuccess(response);
                            })
                            .catch((error) => {
                                this.loading = false;
                                this.notifyError(error);
                                this.errors.form =
                                    error.response && error.response.data && error.response.data.errors ? error.response.data.errors : null;
                            });
                    }
                })
                .catch(() => {});
        },
        sendEmailTest() {
            this.loading = true;
            this.errors.form = null;

            this.httpPost(`/api/campaigns/restaurants/${this.restaurantId}/campaigns/sendEmailTest`, {
                email_test: this.campaign.email_test,
                mail_object: this.campaign.mail_object,
                content: this.campaign.content,
                img_path: this.campaign.img_path ? this.fileNameOnly(this.campaign.img_path) : null,
                img_link: this.campaign.img_link,
            }).finally(() => (this.loading = false));
        },
        getFormattedData() {
            return {
                name: this.campaign.name,
                type: this.campaign.type,
                mail_object: this.campaign.mail_object,
                sms_expeditor: this.campaign.sms_expeditor,
                content: this.campaign.content,
                img_path: this.campaign.img_path ? this.fileNameOnly(this.campaign.img_path) : null,
                img_link: this.campaign.img_link,
                at_least_one_resa: this.campaign.at_least_one_resa,
                at_least_one_past_resa: this.campaign.at_least_one_past_resa,
                at_least_one_future_resa: this.campaign.at_least_one_future_resa,
                at_least_one_resa_in_restaurant: this.campaign.at_least_one_resa_in_restaurant,
                send_to_foreign_numbers: this.campaign.send_to_foreign_numbers,
                will_be_send_at: this.campaign.will_be_send_at,
                save_type: this.campaign.save_type,
                include_vip: this.campaign.include_vip,
                include_risky: this.campaign.include_risky,
                client_types: this.campaign.client_types,
                loyalty_levels: this.campaign.loyalty_levels,
            };
        },
        save(forceSending) {
            this.loading = true;
            this.errors.form = null;

            if (this.campaign.save_type === "send" && !forceSending) {
                this.campaign.save_type = "save";
            }

            // Create new campaign
            if (this.campaign.id === null) {
                return axios
                    .post(`/api/campaigns/restaurants/${this.restaurantId}/campaigns`, this.getFormattedData())
                    .then((response) => {
                        this.loading = false;
                        this.notifySuccess(response);
                        this.campaign.id = response.data.id;
                        if (this.campaign.type !== "sms" || this.campaign.save_type === "send") this.$emit("updated");
                        return true;
                    })
                    .catch((error) => {
                        this.loading = false;

                        if (error.response && error.response.data && error.response.data.id) {
                            this.campaign.id = error.response.data.id;
                        }

                        this.notifyError(error);
                        this.errors.form = error.response && error.response.data && error.response.data.errors ? error.response.data.errors : null;

                        return false;
                    });
            } else {
                return axios
                    .put(`/api/campaigns/restaurants/${this.restaurantId}/campaigns/${this.campaign.id}`, this.getFormattedData())
                    .then((response) => {
                        this.loading = false;
                        this.notifySuccess(response);
                        if (this.campaign.type !== "sms" || this.campaign.save_type === "send") this.$emit("updated");
                        return true;
                    })
                    .catch((error) => {
                        this.loading = false;

                        if (error.response && error.response.data && error.response.data.id) {
                            this.campaign.id = error.response.data.id;
                        }

                        this.notifyError(error);
                        this.errors.form = error.response && error.response.data && error.response.data.errors ? error.response.data.errors : null;

                        return false;
                    });
            }
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    mounted() {
        if (this.campaign.id === null) {
            this.$refs.campaignAdd.checkAllFilters();
        }
    },
    components: {
        CampaignAdd,
        LoaderComponent,
    },
};
</script>
