<template>
    <table class="table table-sm table-striped border-bottom">
        <thead class="border-bottom">
            <tr>
                <th>Date</th>
                <th>Établissement</th>
                <th>N°</th>
                <th>Utilisé</th>
                <th>Statut</th>
                <th>Montant</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="sale in gv_sales" :key="sale.id">
                <td>
                    {{ moment(sale.created_at).format("LL") }}
                </td>
                <td>
                    {{ sale.restaurant.name }}
                </td>
                <td>
                    {{ sale.numero }}
                </td>
                <td>
                    <span class="text-success" v-if="sale.used">Oui</span>
                    <span class="text-warning" v-else>Non</span>
                </td>
                <td class="text-capitalize">
                    {{ getSaleStatusLabel(sale.status) }}
                </td>
                <td>
                    {{ sale.amount ? formatCurrency(sale.amount) : "--" }}
                </td>
                <td>
                    <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary btn-square"
                        @click="$emit('show-gv-sale', sale)"
                        v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'Voir le bon cadeau' }">
                        <svg
                            class="feather feather-eye sc-dnqmqq jxshSx"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            aria-hidden="true">
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import moment from "moment";
import SaleStatusEnum from "../../mixins/enums/gift_vouchers/SaleStatusEnum.js";

export default {
    data() {
        return {
            moment,
        };
    },
    mixins: [SaleStatusEnum],
    props: {
        gv_sales: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
};
</script>
