var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100 d-flex justify-content-end mb-4" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._l(_vm.toolbarItems, function (item) {
        return _c(item.type, {
          key: item.type,
          tag: "component",
          attrs: { settings: item.settings, "data-source": _vm.dataSource },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }