<template>
    <div class="pt-5 pb-5">
        <LoaderComponent v-if="isLoading" />
        <div v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">
                            {{ $tl("labels.routes.booking.config.interfaces") }}
                        </h5>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="row mb-3">
                            <div class="col-12">
                                <strong>
                                    {{ $tl("labels.booking.interfaces.restaurantIdTitle") }}
                                </strong>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5 pt-2">
                                <label>
                                    {{ $tl("labels.booking.interfaces.restaurantId") }}
                                    <small>*</small>
                                </label>
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-6">
                                        <CopyToClipboard :textToCopy="String(restaurantId)" :element="$t('labels.identifier')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5 pt-2">
                                <label>
                                    {{ $tl("labels.booking.interfaces.restaurantSecret") }}
                                    <small>*</small>
                                </label>
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-6">
                                        <CopyToClipboard :textToCopy="SHA1_RESTAURANT_ID" :element="$t('labels.identifier')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0" v-for="publicApiClient in publicApiClients" :key="publicApiClient.value">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <div class="row mb-3">
                            <div class="col-12">
                                <strong>
                                    {{
                                        $t("labels.booking.interfaces.ownerPermissionsTitle", {
                                            name: getPublicApiClientLabel(publicApiClient.value),
                                        })
                                    }}
                                </strong>
                                <feather
                                    v-if="publicApiClient.notice"
                                    v-tooltip="getTooltipNotice(publicApiClient.notice)"
                                    type="info"
                                    class="ml-1 pointer tooltip-infos" />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5 pt-2">
                                <label>
                                    {{ $t("labels.booking.interfaces.ownerPermissions", { name: getPublicApiClientLabel(publicApiClient.value) }) }}
                                    <small>*</small>
                                </label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        :disabled="!isOwner"
                                        v-model="isConsented[publicApiClient.value]" />
                                    <label @click="changeConsentment(publicApiClient.value)"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import CopyToClipboard from "../../../components/Buttons/CopyToClipboard.vue";
import PublicApiClientEnum from "../../../mixins/enums/PublicApi/ClientEnum.js";
import RolesEnum from "../../../mixins/enums/RolesEnum.js";
import { sha1 } from "js-sha1";

export default {
    data() {
        return {
            isLoading: false,
            publicApiClients: [],
            isConsented: {},
        };
    },
    computed: {
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        userRole() {
            return this.$store.getters["users/role"];
        },
        isOwner() {
            return this.userRole === this.ROLE_OWNER.value;
        },
    },
    mixins: [PublicApiClientEnum, RolesEnum],
    created() {
        this.publicApiClients = this.ALL_PUBLIC_API_CLIENTS;

        this.SHA1_RESTAURANT_ID = sha1(this.restaurantId.toString());

        this.getAllOauthClients();
    },
    methods: {
        getAllOauthClients() {
            this.isLoading = true;

            this.httpGet(`/api/restaurants/${this.restaurantId}/oauth_clients/`)
                .then((response) => {
                    if (response !== false) {
                        for (const data of response.data) {
                            this.isConsented[data.name] = true;
                        }
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        updateConsent(name) {
            this.isLoading = true;

            this.httpPost(`/api/restaurants/${this.restaurantId}/oauth_clients/${name}/consented`)
                .then((response) => {
                    if (response !== false) {
                        this.isConsented[name] = !this.isConsented[name];
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        changeConsentment(name) {
            if (!this.isOwner) {
                return;
            }

            const text = this.isConsented[name] ? "confirmNotConsented" : "confirmConsented";

            if (confirm(this.$t(`labels.booking.interfaces.${text}`, { name: this.getPublicApiClientLabel(name) }))) {
                this.updateConsent(name);
            }
        },
    },
    components: {
        LoaderComponent,
        CopyToClipboard,
    },
};
</script>
