var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _vm.campaign !== null
        ? _c("div", [
            _c("div", { staticClass: "div-profil" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _vm._v("Nom de la campagne"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7 value" }, [
                          _vm._v(_vm._s(_vm.campaign.name)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sepa" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _vm._v("Type de campagne"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7 value" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.campaignType) +
                              "\n                            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.campaign.type === "sms"
                        ? [
                            _c("div", { staticClass: "sepa" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-5" }, [
                                _vm._v("Statut"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7 value" }, [
                                _c(
                                  "span",
                                  {
                                    class:
                                      _vm.campaign.paid_at !== null
                                        ? "text-success"
                                        : "text-warning",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.campaign.paid_at !== null
                                          ? "Payée"
                                          : "En attente de paiement"
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.campaign.paid_at !== null
                              ? [
                                  _c("div", { staticClass: "sepa" }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-5" }, [
                                      _vm._v("Coût"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-7 value" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.campaign.amount_paid
                                                ? _vm.formatCurrency(
                                                    _vm.campaign.amount_paid /
                                                      100
                                                  )
                                                : "Indisponible"
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "sepa" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-5" }, [
                                _vm._v("Expéditeur"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7 value" }, [
                                _vm._v(_vm._s(_vm.campaign.sms_expeditor)),
                              ]),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "sepa" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _vm._v("Inclure les clients VIP"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7 value" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.campaign.include_vip ? "Oui" : "Non") +
                              "\n                            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sepa" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _vm._v("Inclure les clients à risque"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7 value" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.campaign.include_risky ? "Oui" : "Non"
                              ) +
                              "\n                            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sepa" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _vm._v("Fidélité client"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7 value" }, [
                          _vm.campaign.loyalty_levels.length === 0
                            ? _c("span", [_vm._v("--")])
                            : _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.campaign.loyalty_levels
                                        .map((loyaltyLevel) =>
                                          loyaltyLevel === null
                                            ? "Aucune"
                                            : _vm.getClientLoyaltyLevelLabel(
                                                loyaltyLevel
                                              )
                                        )
                                        .join(", ")
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sepa" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _vm._v("Typologie client"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7 value" }, [
                          _vm.campaign.client_types.length === 0
                            ? _c("span", [_vm._v("--")])
                            : _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.campaign.client_types
                                        .map((clientType) =>
                                          clientType === "no_status"
                                            ? "Aucune"
                                            : _vm.getClientTypeLabel(clientType)
                                        )
                                        .join(", ")
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sepa" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _vm._v("Filtres"),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "col-md-7 value",
                          domProps: { innerHTML: _vm._s(_vm.filters) },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sepa" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-5" }, [
                          _vm._v("Date et heure d'envoi"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7 value" }, [
                          _vm._v(_vm._s(_vm.campaign.sent_at)),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                    _vm.campaign.type === "email"
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-5" }, [
                            _vm._v("Objet"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-7 value" }, [
                            _vm._v(_vm._s(_vm.campaign.mail_object)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.campaign.type === "email"
                      ? _c("div", { staticClass: "sepa" })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [_vm._v("Message")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 mt-2" }, [
                        _c("small", {
                          staticClass: "value",
                          domProps: { innerHTML: _vm._s(_vm.campaign_content) },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.campaign.img_path && _vm.campaign.type === "email"
                      ? _c("div", { staticClass: "sepa" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.campaign.img_path && _vm.campaign.type === "email"
                      ? _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { "max-height": "20vh" },
                          },
                          [
                            _c("div", { staticClass: "col-5" }, [
                              _vm._v("Image"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-7" }, [
                              _c("img", {
                                staticStyle: {
                                  "max-width": "100%",
                                  "max-height": "20vh",
                                },
                                attrs: {
                                  src: _vm.campaign.img_path,
                                  alt: "Image de campagne",
                                  tile: "Image de la campagne",
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.campaign.img_link && _vm.campaign.type === "email"
                      ? _c("div", { staticClass: "sepa" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.campaign.img_link && _vm.campaign.type === "email"
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-5" }, [
                            _vm._v("Lien de l'image"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-7" }, [
                            _c(
                              "a",
                              {
                                staticClass: "value",
                                attrs: {
                                  target: "_blank",
                                  href: _vm.campaign.img_link.startsWith("http")
                                    ? _vm.campaign.img_link
                                    : "http://" + _vm.campaign.img_link,
                                },
                              },
                              [_vm._v(_vm._s(_vm.campaign.img_link))]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.campaign.sent_at !== null
                ? _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("div", { staticClass: "row m-0 border-bottom" }, [
                            _c(
                              "table",
                              { staticClass: "table table-striped table-sm" },
                              [
                                _c("thead", { staticClass: "border-bottom" }, [
                                  _c("th", [_vm._v("Clients")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Envoyés")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Ouvertures")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Clics")]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  [
                                    _c("tr", [
                                      _c("td", [_vm._v("Tous")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(_vm.campaign.nb_sent)),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm.campaign.type ===
                                        _vm.CAMPAIGN_TYPE_SMS.value
                                          ? _c("span", [_vm._v("Indisponible")])
                                          : _c("span", [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.campaign.nb_opened
                                                  ) +
                                                  "\n                                                "
                                              ),
                                              _vm.campaign.nb_opened > 0 &&
                                              _vm.campaign.nb_sent > 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                                                    (" +
                                                        _vm._s(
                                                          _vm.formatPercent(
                                                            (_vm.campaign
                                                              .nb_opened /
                                                              _vm.campaign
                                                                .nb_sent) *
                                                              100
                                                          )
                                                        ) +
                                                        "%)\n                                                "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm.campaign.type ===
                                        _vm.CAMPAIGN_TYPE_SMS.value
                                          ? _c("div", [_vm._v("Indisponible")])
                                          : _c("div", [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.campaign.nb_clicked
                                                  ) +
                                                  "\n                                                "
                                              ),
                                              _vm.campaign.nb_clicked > 0 &&
                                              _vm.campaign.nb_opened > 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                                                    (" +
                                                        _vm._s(
                                                          _vm.formatPercent(
                                                            (_vm.campaign
                                                              .nb_clicked /
                                                              _vm.campaign
                                                                .nb_opened) *
                                                              100
                                                          )
                                                        ) +
                                                        "%)\n                                                "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.statsRows,
                                      function ({ key, label }) {
                                        return _c("tr", { key: key }, [
                                          _c("td", [_vm._v(_vm._s(label))]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.campaign.stats[key].sent
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm.campaign.type ===
                                            _vm.CAMPAIGN_TYPE_SMS.value
                                              ? _c("div", [
                                                  _vm._v("Indisponible"),
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(
                                                        _vm.campaign.stats[key]
                                                          .opened
                                                      ) +
                                                      "\n                                                "
                                                  ),
                                                  _vm.campaign.stats[key]
                                                    .opened > 0 &&
                                                  _vm.campaign.stats[key].sent >
                                                    0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                                    (" +
                                                            _vm._s(
                                                              _vm.formatPercent(
                                                                (_vm.campaign
                                                                  .stats[key]
                                                                  .opened /
                                                                  _vm.campaign
                                                                    .stats[key]
                                                                    .sent) *
                                                                  100
                                                              )
                                                            ) +
                                                            "%)\n                                                "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm.campaign.type ===
                                            _vm.CAMPAIGN_TYPE_SMS.value
                                              ? _c("div", [
                                                  _vm._v("Indisponible"),
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(
                                                        _vm.campaign.stats[key]
                                                          .opened
                                                      ) +
                                                      "\n                                                "
                                                  ),
                                                  _vm.campaign.stats[key]
                                                    .clicked > 0 &&
                                                  _vm.campaign.stats[key]
                                                    .opened > 0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                                                    (" +
                                                            _vm._s(
                                                              _vm.formatPercent(
                                                                (_vm.campaign
                                                                  .stats[key]
                                                                  .clicked /
                                                                  _vm.campaign
                                                                    .stats[key]
                                                                    .opened) *
                                                                  100
                                                              )
                                                            ) +
                                                            "%)\n                                                "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]),
                                          ]),
                                        ])
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }