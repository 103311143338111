var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5" },
    [
      _vm.loading
        ? _c("loader-component")
        : _c("div", { staticClass: "default-home" }, [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c("div", [
                  _c("div", [
                    _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4 mb-3" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12 mb-3" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.$tl("labels.visualIdentity"))
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-5 mb-3" }, [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$tl("labels.form.logo"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-7" },
                                [
                                  _c("ImageComponent", {
                                    attrs: {
                                      src: _vm.restaurant.logo.url,
                                      disabled:
                                        !_vm.has_right_to_update_restaurant,
                                      upload: false,
                                      maxSize: 10,
                                      suffixId: "logo",
                                    },
                                    on: {
                                      "new-file": function ($event) {
                                        _vm.restaurant.logo.file = $event
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.hasErrors("logo")
                                    ? _c("div", {
                                        staticClass: "text-danger",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.joinErrors("logo")
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-3" }, [
                              _c("div", { staticClass: "col-md-5 pt-2" }, [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$tl("labels.form.color"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _vm.has_right_to_update_restaurant
                                    ? _c("chrome-picker", {
                                        model: {
                                          value: _vm.restaurant.widget_color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.restaurant,
                                              "widget_color",
                                              $$v
                                            )
                                          },
                                          expression: "restaurant.widget_color",
                                        },
                                      })
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "mt-2 text-center",
                                          style: `width: 60px; background-color: ${_vm.restaurant.widget_color}`,
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.restaurant.widget_color
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-5 pt-2" }, [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.form.restaurant.titleFont"
                                      )
                                    ) + " "
                                  ),
                                  _c("small", [_vm._v("*")]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-7 d-flex align-items-center",
                                },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.restaurant.widget_font_title,
                                          expression:
                                            "restaurant.widget_font_title",
                                        },
                                      ],
                                      staticClass: "custom-select w-auto m-0",
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.restaurant,
                                            "widget_font_title",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.availableFontsTitle,
                                      function (font) {
                                        return _c(
                                          "option",
                                          {
                                            key: font.value,
                                            domProps: { value: font.value },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(font.name) +
                                                "\n                                        "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "ml-2 font-previsu",
                                      style:
                                        "font-family: " +
                                        _vm.restaurant.widget_font_title,
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.restaurant.name ||
                                            _vm.$tl("labels.reservation")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      attrs: { id: "editRestaurant", method: "post" },
                      on: { submit: _vm.postForm },
                    },
                    [
                      _c("div", { staticClass: "row m-0" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c(
                              "div",
                              { staticClass: "border-light b-radius-20 p-4" },
                              [
                                _vm.formLoading
                                  ? _c("loader-component")
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 mb-3" },
                                    [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.$tl("labels.infos"))),
                                      ]),
                                      _c("feather", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: {
                                              content:
                                                "La modification des informations de l'établissement modifie également les informations de contact et les coordonnées du médiateur",
                                            },
                                            expression:
                                              "{\n                                            content:\n                                                'La modification des informations de l\\'établissement modifie également les informations de contact et les coordonnées du médiateur',\n                                        }",
                                          },
                                        ],
                                        staticClass: "text-warning ml-1",
                                        attrs: { type: "info" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-5 pt-2" }, [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl("labels.form.restaurant.name")
                                        ) + " "
                                      ),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-7" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.restaurant.name,
                                          expression: "restaurant.name",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        disabled:
                                          !_vm.has_right_to_update_restaurant,
                                        type: "text",
                                        required: "",
                                      },
                                      domProps: { value: _vm.restaurant.name },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.restaurant,
                                              "name",
                                              $event.target.value
                                            )
                                          },
                                          _vm.fillCgvDataOnRestaurantInfosEdit,
                                        ],
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.hasErrors("name")
                                      ? _c("div", {
                                          staticClass: "text-danger",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.joinErrors("name")
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-md-5 pt-2" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.$tl("labels.form.address")) +
                                        " "
                                    ),
                                    _c("small", [_vm._v("*")]),
                                    _vm._v(" "),
                                    !_vm.hasFillGoogleMapsAddress &&
                                    _vm.restaurant.id
                                      ? _c(
                                          "small",
                                          {
                                            staticClass:
                                              "text-warn d-block mt-2",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "infos.restaurants.newAddressComponent"
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c("postal-address-autocomplete", {
                                        attrs: { "search-address-type": false },
                                        on: {
                                          input:
                                            _vm.fillCgvDataOnRestaurantInfosEdit,
                                        },
                                        model: {
                                          value:
                                            _vm.restaurant.google_maps_address,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.restaurant,
                                              "google_maps_address",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "restaurant.google_maps_address",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.hasErrors("google_maps_address")
                                        ? _c("div", {
                                            staticClass: "text-danger",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.joinErrors(
                                                  "google_maps_address"
                                                )
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("input-component", {
                                  attrs: {
                                    label: _vm.$t("labels.form.addressComp"),
                                    notice: "notices.restaurants.addressComp",
                                    inputName: "further_address_details",
                                    "form-errors": _vm.formErrors,
                                  },
                                  model: {
                                    value:
                                      _vm.restaurant.further_address_details,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.restaurant,
                                        "further_address_details",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "restaurant.further_address_details",
                                  },
                                }),
                                _vm._v(" "),
                                !_vm.hasFillGoogleMapsAddress &&
                                _vm.restaurant.id
                                  ? [
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-5 pt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl("labels.form.address")
                                                ) + " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-7" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.restaurant.address,
                                                expression:
                                                  "restaurant.address",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              disabled: "",
                                              type: "text",
                                              required: "",
                                            },
                                            domProps: {
                                              value: _vm.restaurant.address,
                                            },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.restaurant,
                                                    "address",
                                                    $event.target.value
                                                  )
                                                },
                                                _vm.fillCgvDataOnRestaurantInfosEdit,
                                              ],
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.hasErrors("address")
                                            ? _c("div", {
                                                staticClass: "text-danger",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.joinErrors("address")
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-5 pt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.addressComp"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-7" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.restaurant
                                                    .further_address_details,
                                                expression:
                                                  "restaurant.further_address_details",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              disabled: "",
                                              type: "text",
                                            },
                                            domProps: {
                                              value:
                                                _vm.restaurant
                                                  .further_address_details,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.restaurant,
                                                  "further_address_details",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.hasErrors(
                                            "further_address_details"
                                          )
                                            ? _c("div", {
                                                staticClass: "text-danger",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.joinErrors(
                                                      "further_address_details"
                                                    )
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-5 pt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.postalCode"
                                                  )
                                                ) + " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-7" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.restaurant.zipcode,
                                                expression:
                                                  "restaurant.zipcode",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              disabled: "",
                                              type: "text",
                                              maxlength: "5",
                                              required: "",
                                            },
                                            domProps: {
                                              value: _vm.restaurant.zipcode,
                                            },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.restaurant,
                                                    "zipcode",
                                                    $event.target.value
                                                  )
                                                },
                                                _vm.fillCgvDataOnRestaurantInfosEdit,
                                              ],
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.hasErrors("zipcode")
                                            ? _c("div", {
                                                staticClass: "text-danger",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.joinErrors("zipcode")
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-5 pt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl("labels.form.city")
                                                ) + " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-7" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.restaurant.city,
                                                expression: "restaurant.city",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              disabled: "",
                                              type: "text",
                                              required: "",
                                            },
                                            domProps: {
                                              value: _vm.restaurant.city,
                                            },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.restaurant,
                                                    "city",
                                                    $event.target.value
                                                  )
                                                },
                                                _vm.fillCgvDataOnRestaurantInfosEdit,
                                              ],
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.hasErrors("city")
                                            ? _c("div", {
                                                staticClass: "text-danger",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.joinErrors("city")
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-5 pt-2" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tl("labels.form.country")
                                                ) + " "
                                              ),
                                              _c("small", [_vm._v("*")]),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-7" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.restaurant.country,
                                                expression:
                                                  "restaurant.country",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              disabled: "",
                                              type: "text",
                                              required: "",
                                            },
                                            domProps: {
                                              value: _vm.restaurant.country,
                                            },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.restaurant,
                                                    "country",
                                                    $event.target.value
                                                  )
                                                },
                                                _vm.fillCgvDataOnRestaurantInfosEdit,
                                              ],
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.hasErrors("country")
                                            ? _c("div", {
                                                staticClass: "text-danger",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.joinErrors("country")
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ]),
                                      ]),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-5 pt-2" }, [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl("labels.form.phoneNumber")
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-7" },
                                    [
                                      _c("vue-tel-input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          defaultCountry: "FR",
                                          name: "telInput",
                                          disabled:
                                            !_vm.has_right_to_update_restaurant,
                                          enabledCountryCode: true,
                                          wrapperClasses:
                                            _vm.phone.isValid === null ||
                                            _vm.phone.isValid
                                              ? ""
                                              : "border border-danger",
                                          inputClasses: "m-0 border-0",
                                          mode: "international",
                                          placeholder: _vm.$t(
                                            "labels.form.phoneNumber"
                                          ),
                                        },
                                        on: {
                                          validate: _vm.setPhoneAndCountry,
                                        },
                                        model: {
                                          value: _vm.phone.raw,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.phone, "raw", $$v)
                                          },
                                          expression: "phone.raw",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.hasErrors("tel")
                                        ? _c("div", {
                                            staticClass: "text-danger",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.joinErrors("tel")
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-5 pt-2" }, [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(_vm.$tl("labels.form.website"))
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-7" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.restaurant.website,
                                          expression: "restaurant.website",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        disabled:
                                          !_vm.has_right_to_update_restaurant,
                                        type: "text",
                                        placeholder: "www.monsite.com",
                                      },
                                      domProps: {
                                        value: _vm.restaurant.website,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.restaurant,
                                            "website",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.hasErrors("website")
                                      ? _c("div", {
                                          staticClass: "text-danger",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.joinErrors("website")
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row pt-1 mb-1" }, [
                                  _c("div", { staticClass: "col-5" }, [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.form.restaurant.noTVA"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-7" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "container-box",
                                        staticStyle: { width: "initial" },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.restaurant.tva_disabled,
                                              expression:
                                                "restaurant.tva_disabled",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            "true-value": "1",
                                            disabled:
                                              !_vm.has_right_to_update_restaurant,
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.restaurant.tva_disabled
                                            )
                                              ? _vm._i(
                                                  _vm.restaurant.tva_disabled,
                                                  null
                                                ) > -1
                                              : _vm._q(
                                                  _vm.restaurant.tva_disabled,
                                                  "1"
                                                ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.restaurant.tva_disabled,
                                                $$el = $event.target,
                                                $$c = $$el.checked ? "1" : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.restaurant,
                                                      "tva_disabled",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.restaurant,
                                                      "tva_disabled",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.restaurant,
                                                  "tva_disabled",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                          class: {
                                            disabled:
                                              !_vm.has_right_to_update_restaurant,
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-5 pt-2" }, [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(_vm.$tl("labels.timezone")) + " "
                                      ),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7" },
                                    [
                                      _c("TimeZonePicker", {
                                        staticClass: "custom-select w-auto m-0",
                                        model: {
                                          value: _vm.restaurant.timezone,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.restaurant,
                                              "timezone",
                                              $$v
                                            )
                                          },
                                          expression: "restaurant.timezone",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        staticClass: "d-block",
                                        attrs: {
                                          errors: _vm.formErrors,
                                          errorKey: "timezone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2 mt-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-5 pt-2" },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tl("labels.form.indicative")
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("feather", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.getTooltipNotice(
                                              "notices.defaultTelCountry"
                                            ),
                                            expression:
                                              "getTooltipNotice('notices.defaultTelCountry')",
                                          },
                                        ],
                                        staticClass:
                                          "ml-1 pointer tooltip-infos",
                                        attrs: { type: "info" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { "padding-left": "15px" } },
                                    [
                                      _c("vue-tel-input", {
                                        staticClass: "form-control p-0",
                                        attrs: {
                                          defaultCountry:
                                            _vm.restaurant.default_tel_country,
                                          inputClasses: "d-none",
                                        },
                                        on: {
                                          "country-changed": (e) =>
                                            (_vm.restaurant.default_tel_country =
                                              e.iso2),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mb-2 mt-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-5 pt-2" },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.form.restaurant.currency"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("feather", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value:
                                              _vm.getTooltipNotice(
                                                "notices.currency"
                                              ),
                                            expression:
                                              "getTooltipNotice('notices.currency')",
                                          },
                                        ],
                                        staticClass:
                                          "ml-1 pointer tooltip-infos",
                                        attrs: { type: "info" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-md-7 d-flex align-items-center",
                                    },
                                    [
                                      _c("choose-currency", {
                                        attrs: {
                                          disabled: _vm.restaurant.has_psp,
                                        },
                                        model: {
                                          value: _vm.restaurant.currency,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.restaurant,
                                              "currency",
                                              $$v
                                            )
                                          },
                                          expression: "restaurant.currency",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.isYservices
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "row mb-2 mt-2" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-5 pt-2" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.lexicons.lexicon"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-7 d-flex align-items-center",
                                            },
                                            [
                                              _c("choose-lexicon", {
                                                model: {
                                                  value: _vm.restaurant.lexicon,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.restaurant,
                                                      "lexicon",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "restaurant.lexicon",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.restaurant.lexicon !==
                                      _vm.LEXICON_RESTAURANT.value
                                        ? _c("switch-input-component", {
                                            attrs: {
                                              label: _vm.$t(
                                                "labels.form.restaurant.showToRealizeView"
                                              ),
                                            },
                                            model: {
                                              value:
                                                _vm.restaurant
                                                  .show_to_realize_view,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.restaurant,
                                                  "show_to_realize_view",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "restaurant.show_to_realize_view",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.restaurant.lexicon !==
                                      _vm.LEXICON_RESTAURANT.value
                                        ? _c("switch-input-component", {
                                            attrs: {
                                              label: _vm.$t(
                                                "labels.form.restaurant.enableSeatingPlan"
                                              ),
                                            },
                                            model: {
                                              value:
                                                _vm.restaurant
                                                  .feat_seating_plan,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.restaurant,
                                                  "feat_seating_plan",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "restaurant.feat_seating_plan",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("Cgv", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.formLoading,
                                  expression: "!formLoading",
                                },
                              ],
                              ref: "cgvComponent",
                              attrs: {
                                restaurant_id: _vm.restaurant.id || null,
                                formErrors: _vm.formErrors,
                                isTvaDisabledProp: _vm.restaurant.tva_disabled,
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-3" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success btn-circle",
                                    attrs: {
                                      disabled:
                                        !_vm.has_right_to_update_restaurant,
                                      type: "submit",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$tl("labels.form.actions.save")
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }