var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lead-switch d-flex align-items-center" },
    [
      _vm._t("pre-label"),
      _vm._v(" "),
      _c("input", {
        staticClass: "switch d-none is-rounded",
        attrs: { type: "checkbox", id: _vm.inputId, disabled: _vm.disabled },
        domProps: { checked: _vm.value },
        on: {
          change: function ($event) {
            return _vm.$emit("input", $event.target.checked)
          },
        },
      }),
      _vm._v(" "),
      _c("label", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.tooltip,
            expression: "tooltip",
          },
        ],
        staticClass: "m-2",
        attrs: { for: _vm.inputId },
      }),
      _vm._v(" "),
      _vm._t("post-label"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }