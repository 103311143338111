export default {
    data() {
        return {
            BUSINESS_TYPE_SARL: {
                value: "SARL",
            },
            BUSINESS_TYPE_SA: {
                value: "SA",
            },
            BUSINESS_TYPE_SAS: {
                value: "SAS",
            },
            BUSINESS_TYPE_SASU: {
                value: "SASU",
            },
            BUSINESS_TYPE_EI: {
                value: "EI",
            },
            BUSINESS_TYPE_EURL: {
                value: "EURL",
            },
            BUSINESS_TYPE_OTHER: {
                value: "Autre",
            },
        };
    },
    methods: {
        getBusinessTypeLabel(value) {
            const found = this.ALL_BUSINESS_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") {
                return this.$t(`labels.businessTypes.${value}`);
            }
            return value;
        },
    },
    computed: {
        ALL_BUSINESS_TYPES() {
            return [
                this.BUSINESS_TYPE_SARL,
                this.BUSINESS_TYPE_SA,
                this.BUSINESS_TYPE_SAS,
                this.BUSINESS_TYPE_SASU,
                this.BUSINESS_TYPE_EI,
                this.BUSINESS_TYPE_EURL,
                this.BUSINESS_TYPE_OTHER,
            ];
        },
    },
};
