<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else class="row m-0">
            <form @submit.prevent="saveOwner" class="col-12">
                <div class="row m-0">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-12">
                                <div class="border-light b-radius-20 p-4 mb-3">
                                    <div class="row mb-2">
                                        <div class="col-12">
                                            <strong>{{ $t("labels.personalInfos") }}</strong>
                                        </div>
                                    </div>

                                    <input-component
                                        v-model="ownerDetails.firstname"
                                        input-name="firstname"
                                        required
                                        :label="$t('labels.form.firstname')"
                                        :form-errors="errors.form" />

                                    <input-component
                                        v-model="ownerDetails.lastname"
                                        input-name="lastname"
                                        required
                                        :label="$t('labels.form.lastname')"
                                        :form-errors="errors.form" />

                                    <input-component
                                        v-model="ownerDetails.email"
                                        input-name="email"
                                        input-type="email"
                                        required
                                        :label="$t('labels.form.email')"
                                        :form-errors="errors.form" />

                                    <div class="row mb-3">
                                        <div class="col-5">
                                            <label class="mb-0" for="tel"> {{ $t("labels.form.phoneNumber") }} <small>*</small> </label>
                                            <br />
                                            <small class="text-muted">
                                                {{ $t("infos.account.changePhoneNumber") }}
                                            </small>
                                        </div>
                                        <div class="col-md-7">
                                            <tel-input-component
                                                v-model="ownerDetails.tel"
                                                inputName="tel"
                                                :placeholder="$t('labels.form.phoneNumber')"
                                                @set-tel-country="ownerDetails.tel_country = $event"
                                                @set-tel-is-mobile="ownerDetails.tel_is_mobile = $event"
                                                :formErrors="errors.form" />
                                        </div>
                                    </div>

                                    <input-component
                                        v-if="isNoShow"
                                        v-model="ownerDetails.fiducial_client_id"
                                        input-name="fiducial_client_id"
                                        label="N° client Fiducial"
                                        :form-errors="errors.form" />

                                    <input-component
                                        v-if="isYservices"
                                        v-model="ownerDetails.yprox_user_id"
                                        input-name="yprox_user_id"
                                        label="Identifiant du compte Yproximité"
                                        label-classes="mb-0"
                                        :form-errors="errors.form">
                                        <template v-slot:after-label>
                                            <br />
                                            <small class="text-muted">
                                                Identifiant du compte Yproximité du client pour permettre l'autologin. Attention : si le client ne
                                                possède pas de compte Yproximité, il faut impérativement laisser ce champ vide.
                                            </small>
                                        </template>
                                    </input-component>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="border-light b-radius-20 p-4 mb-3">
                                    <div class="row mb-2">
                                        <div class="col-12">
                                            <strong>{{ $t("labels.form.facturation.titleClient") }}</strong>
                                        </div>
                                    </div>

                                    <input-component
                                        v-model="ownerDetails.company"
                                        input-name="company"
                                        required
                                        :label="$t('labels.form.businessName')"
                                        :form-errors="errors.form" />

                                    <div class="row mb-2">
                                        <div class="col-5 pt-2">
                                            <label>{{ $t("labels.form.businessType") }} <small>*</small></label>
                                        </div>
                                        <div class="col-7">
                                            <select
                                                class="custom-select mb-0"
                                                id="company_type"
                                                name="companyType"
                                                v-model="ownerDetails.company_type"
                                                required>
                                                <option
                                                    v-for="businessType in ALL_BUSINESS_TYPES"
                                                    :key="businessType.value"
                                                    :value="businessType.value">
                                                    {{ getBusinessTypeLabel(businessType.value) }}
                                                </option>
                                            </select>
                                            <ShowErrors :errors="errors.form" errorKey="company_type" />
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-5 pt-2">
                                            <label>{{ $t("labels.form.facturation.siret") }} <small>*</small></label>
                                        </div>
                                        <div class="col-7">
                                            <input
                                                minlength="14"
                                                maxlength="14"
                                                type="text"
                                                name="companySiret"
                                                v-model="ownerDetails.company_siret"
                                                required
                                                class="form-control" />
                                            <ShowErrors :errors="errors.form" errorKey="company_siret" />
                                        </div>
                                    </div>

                                    <input-component
                                        v-model="ownerDetails.company_code_naf"
                                        input-name="company_code_naf"
                                        required
                                        :label="$t('labels.form.facturation.nafCode')"
                                        :form-errors="errors.form" />

                                    <input-component
                                        v-model="ownerDetails.company_code_tva"
                                        input-name="company_code_tva"
                                        required
                                        :label="$t('labels.form.facturation.tvaNumber')"
                                        :form-errors="errors.form" />

                                    <input-component
                                        v-model="ownerDetails.company_address"
                                        input-name="company_address"
                                        required
                                        :label="$t('labels.form.address')"
                                        :form-errors="errors.form" />

                                    <input-component
                                        v-model="ownerDetails.company_zipcode"
                                        input-name="company_zipcode"
                                        required
                                        :label="$t('labels.form.postalCode')"
                                        :form-errors="errors.form" />

                                    <input-component
                                        v-model="ownerDetails.company_city"
                                        input-name="company_city"
                                        required
                                        :label="$t('labels.form.city')"
                                        :form-errors="errors.form" />

                                    <input-component
                                        v-model="ownerDetails.company_country"
                                        input-name="company_country"
                                        required
                                        :label="$t('labels.form.country')"
                                        :form-errors="errors.form" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between px-3">
                    <button v-if="!hideCancelBtn" class="btn btn-sm btn-success btn-circle" type="button" @click="cancel">
                        {{ $t("labels.form.actions.cancel") }}
                    </button>
                    <button class="btn btn-sm btn-success btn-circle" type="submit">
                        {{ $t("labels.form.actions.save") }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent";
import ShowErrors from "../../errors/ShowErrors";
import BusinessTypesEnum from "../../../mixins/enums/BusinessTypesEnum";
import InputComponent from "../../forms/InputComponent.vue";
import TelInputComponent from "../../forms/TelInputComponent.vue";

export default {
    data() {
        return {
            loading: false,
            errors: {
                form: null,
            },
            ownerDetails: {
                id: null,
                firstname: null,
                lastname: null,
                email: null,
                tel: null,
                tel_country: null,
                tel_is_mobile: false,
                company: null,
                company_type: null,
                company_siret: null,
                company_code_naf: null,
                company_code_tva: null,
                company_address: null,
                company_zipcode: null,
                company_city: null,
                company_country: null,
                fiducial_client_id: null,
                yprox_user_id: null,
            },
        };
    },
    props: {
        ownerId: {
            default: null,
        },
        hideCancelBtn: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [BusinessTypesEnum],
    methods: {
        saveOwner() {
            this.loading = true;
            this.errors.form = null;

            let promise = null;
            if (this.ownerId !== null) {
                promise = this.httpPut(`/api/admin/owners/${this.ownerId}`, this.ownerDetails);
            } else {
                promise = this.httpPost("/api/admin/owners", this.ownerDetails);
            }

            promise
                .then((response) => {
                    if (response !== false) {
                        this.$emit(this.ownerId === null ? "added" : "edited", response);
                    }
                })
                .finally(() => (this.loading = false));
        },
        fetchOwnerDetails() {
            this.loading = true;

            this.httpGet(`/api/admin/owners/${this.ownerId}`)
                .then((response) => {
                    if (response !== false) {
                        _.assign(this.ownerDetails, response.data);
                    }
                })
                .finally(() => (this.loading = false));
        },
        cancel() {
            if (this.ownerId !== null) {
                this.$emit("cancel-edit");
            } else {
                this.$router.go(-1);
            }
        },
    },
    created() {
        if (this.ownerId !== null) {
            this.fetchOwnerDetails();
        }
    },
    components: {
        LoaderComponent,
        ShowErrors,
        InputComponent,
        TelInputComponent,
    },
};
</script>
