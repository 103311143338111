var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-2" },
    [
      _vm.loading ? _c("loader-component") : _vm._e(),
      _vm._v(" "),
      _c("show-errors", {
        attrs: { errors: _vm.errors.form, "error-key": "*" },
      }),
      _vm._v(" "),
      _c("select-licence", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading",
          },
        ],
        staticClass: "px-3",
        attrs: {
          "modules-enabled": _vm.modulesEnabled,
          "preselected-modules": _vm.modules,
          "show-back-button": "",
          "disable-payment": "",
          "disable-training": "",
          "disable-plan": "",
          "next-label": "Créer les licences",
        },
        on: {
          back: function ($event) {
            return _vm.$router.go(-1)
          },
          selected: _vm.createLicences,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }