<template>
    <div>
        <div class="mt-3" v-if="reservation.logs && reservation.logs.data && reservation.logs.data.length > 0">
            <ShowReservationLog v-for="log in reservation.logs.data" :key="log.id" :log="log" />
        </div>
        <span class="text-muted mt-3 d-inline-block" v-else>{{ $tl("labels.booking.reservations.logs.empty") }}</span>
    </div>
</template>

<script>
import ShowReservationLog from "./ShowReservationLog";

export default {
    props: {
        reservation: {
            type: Object,
            required: true,
        },
    },
    components: {
        ShowReservationLog,
    },
};
</script>
