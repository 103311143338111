var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-plan", attrs: { id: "seating-plan-topbar" } },
    [
      _c(
        "div",
        {
          staticClass:
            "w-100 d-inline-flex justify-content-between rooms bg-light",
        },
        [
          _c(
            "ul",
            { staticClass: "nav nav-tabs" },
            [
              _c(
                "draggable",
                {
                  attrs: {
                    disabled:
                      !_vm.sortable || !_vm.has_right_to_update_seatingplan,
                  },
                  model: {
                    value: _vm.sortedRooms,
                    callback: function ($$v) {
                      _vm.sortedRooms = $$v
                    },
                    expression: "sortedRooms",
                  },
                },
                [
                  _c(
                    "transition-group",
                    [
                      _vm._l(_vm.sortedRooms, function (room) {
                        return [
                          !room.closed
                            ? _c(
                                "li",
                                {
                                  key: room.order,
                                  staticClass: "nav-item",
                                  class: {
                                    active: room === _vm.currentRoom,
                                    "list-rooms-view": _vm.readOnly,
                                  },
                                  on: {
                                    click: function ($event) {
                                      if (
                                        $event.target !== $event.currentTarget
                                      )
                                        return null
                                      return _vm.$emit(
                                        "room-selected",
                                        room.baseIndex
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.sortable
                                    ? _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "css-i6dzq1 feather mr-1",
                                          attrs: {
                                            viewBox: "0 0 24 24",
                                            width: "24",
                                            height: "24",
                                            stroke: "currentColor",
                                            "stroke-width": "2",
                                            fill: "none",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                          },
                                        },
                                        [
                                          _c("line", {
                                            attrs: {
                                              x1: "3",
                                              y1: "9",
                                              x2: "21",
                                              y2: "9",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("line", {
                                            attrs: {
                                              x1: "3",
                                              y1: "15",
                                              x2: "21",
                                              y2: "15",
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(room.name) +
                                      "\n                            "
                                  ),
                                  !_vm.readOnly &&
                                  _vm.has_right_to_update_seatingplan
                                    ? _c("button", {
                                        staticClass: "delete ml-1",
                                        on: {
                                          "!click": function ($event) {
                                            return _vm.confirmDelete(
                                              room.baseIndex
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.readOnly
                                    ? [
                                        room.enabled &&
                                        !room.isEnabledForService
                                          ? _c("feather", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: _vm.getTooltip(
                                                    _vm.$tl(
                                                      "labels.booking.seatingPlan.room.disabledForService",
                                                      _vm.restaurant_id
                                                    )
                                                  ),
                                                  expression:
                                                    "getTooltip($tl('labels.booking.seatingPlan.room.disabledForService', restaurant_id))",
                                                },
                                              ],
                                              staticClass: "text-danger",
                                              attrs: { type: "minus-circle" },
                                            })
                                          : !room.enabled &&
                                            !room.isEnabledForService
                                          ? _c("feather", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: _vm.getTooltip(
                                                    _vm.$t(
                                                      "labels.booking.seatingPlan.room.disabledInConfig"
                                                    )
                                                  ),
                                                  expression:
                                                    "getTooltip($t('labels.booking.seatingPlan.room.disabledInConfig'))",
                                                },
                                              ],
                                              staticClass: "text-danger",
                                              attrs: { type: "minus-circle" },
                                            })
                                          : !room.enabled &&
                                            room.isEnabledForService
                                          ? _c("feather", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: _vm.getTooltip(
                                                    _vm.$tl(
                                                      "labels.booking.seatingPlan.room.enabledForService",
                                                      _vm.restaurant_id
                                                    )
                                                  ),
                                                  expression:
                                                    "getTooltip($tl('labels.booking.seatingPlan.room.enabledForService', restaurant_id))",
                                                },
                                              ],
                                              staticClass: "text-warning",
                                              attrs: { type: "info" },
                                            })
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.readOnly && _vm.has_right_to_update_seatingplan
                ? _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.getTooltip(
                            _vm.$t("labels.booking.seatingPlan.room.add")
                          ),
                          expression:
                            "getTooltip($t('labels.booking.seatingPlan.room.add'))",
                        },
                      ],
                      staticClass: "nav-item",
                      staticStyle: { "border-radius": "0 50px 50px 0" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("new-room")
                        },
                      },
                    },
                    [_c("feather", { attrs: { type: "plus" } })],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.readOnly
                ? _c("feather", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltipNotice(
                          "notices.booking.tablePlan.rooms"
                        ),
                        expression:
                          "getTooltipNotice('notices.booking.tablePlan.rooms')",
                      },
                    ],
                    staticClass: "ml-1 pointer tooltip-infos",
                    staticStyle: { "margin-top": "12px" },
                    attrs: { type: "info" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.readOnly && _vm.has_right_to_update_seatingplan
            ? _c("div", { staticClass: "align-self-center" }, [
                _vm.currentRoom
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getTooltip(
                              _vm.$t(
                                "labels.booking.seatingPlan.room.toggleEnabled"
                              )
                            ),
                            expression:
                              "getTooltip($t('labels.booking.seatingPlan.room.toggleEnabled'))",
                          },
                        ],
                        staticClass:
                          "d-inline-block align-middle lead-switch pl-2",
                      },
                      [
                        _c("feather", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getTooltipNoticeLexicon(
                                "notices.booking.tablePlan.disablingRoom",
                                _vm.restaurant_id
                              ),
                              expression:
                                "getTooltipNoticeLexicon('notices.booking.tablePlan.disablingRoom', restaurant_id)",
                            },
                          ],
                          staticClass: "mr-1 pointer tooltip-infos",
                          attrs: { type: "info" },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "switch align-self-center is-rounded",
                          attrs: { type: "checkbox" },
                          domProps: { checked: _vm.currentRoom.enabled },
                        }),
                        _vm._v(" "),
                        _c("label", {
                          on: {
                            click: function ($event) {
                              _vm.currentRoom.enabled = !_vm.currentRoom.enabled
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn btn-success",
                    staticStyle: { "border-radius": "50px 0 0 50px" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("save")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.form.actions.save")) +
                        "\n            "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.readOnly
            ? _c("div", { staticClass: "align-self-center" }, [
                !_vm.$_.isEmpty(_vm.selectedTables)
                  ? _c("div", [
                      _vm.canAddPassingCustomer
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-success btn-circle btn-sm mr-1",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit(
                                    "add-passing-customer-on-tables"
                                  )
                                },
                              },
                            },
                            [
                              _c("feather", { attrs: { type: "plus" } }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.reservations.passingCustomer.add.btn"
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success btn-circle btn-sm mr-1",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("add-reservation-on-tables")
                            },
                          },
                        },
                        [
                          _c("feather", { attrs: { type: "plus" } }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$tl("labels.reservation")) +
                              "\n                "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.selectedTables.length === 1
                        ? _c(
                            "label",
                            {
                              staticClass:
                                "mb-0 w-auto container-box mr-2 h-100",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.selectedTables[0].isEnabledForService,
                                    expression:
                                      "selectedTables[0].isEnabledForService",
                                  },
                                ],
                                attrs: { type: "checkbox", value: "" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.selectedTables[0].isEnabledForService
                                  )
                                    ? _vm._i(
                                        _vm.selectedTables[0]
                                          .isEnabledForService,
                                        ""
                                      ) > -1
                                    : _vm.selectedTables[0].isEnabledForService,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onSelectedTableEnabledChange(
                                      $event
                                    )
                                  },
                                  change: function ($event) {
                                    var $$a =
                                        _vm.selectedTables[0]
                                          .isEnabledForService,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.selectedTables[0],
                                            "isEnabledForService",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.selectedTables[0],
                                            "isEnabledForService",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.selectedTables[0],
                                        "isEnabledForService",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkmark" }),
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.seatingPlan.table.enable"
                                  )
                                ) + "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm.currentRoom
                  ? _c("div", [
                      _c(
                        "label",
                        { staticClass: "mb-0 w-auto container-box mr-2 h-100" },
                        [
                          _c("input", {
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: _vm.currentRoom.isEnabledForService,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onSelectedRoomEnabledChange($event)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "checkmark" }),
                          _vm._v(
                            _vm._s(
                              _vm.$tl("labels.booking.seatingPlan.room.enable")
                            ) + "\n                "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showConfirmationModal
        ? _c("confirmation-modal", {
            attrs: {
              eventPayload: _vm.confirmationModalPayload,
              content: _vm.confirmationModalContent,
            },
            on: {
              confirmed: _vm.confirmationModalCallback,
              close: function ($event) {
                _vm.showConfirmationModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }