var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row m-0" },
    [
      _c("tab-bar", {
        staticClass: "border-right col-xl-2 pr-0",
        attrs: {
          "is-event-mode": "",
          side: "left",
          direction: "vertical",
          entries: _vm.tabBarEntries,
        },
        on: {
          "set-active": function ($event) {
            _vm.active = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "sepa d-xl-none" }),
      _vm._v(" "),
      _c(_vm.activeComponent, {
        tag: "component",
        staticClass: "col-xl-10 p-3",
        attrs: {
          "module-enum": _vm.moduleEnum,
          "restaurant-id": _vm.restaurantId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }