var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "border-light p-4 b-radius-20" },
    [
      _vm.nbLoading > 0
        ? _c("loader-component")
        : [
            _c("div", { staticClass: "row" }, [
              !_vm.isGlobalReport
                ? _c("div", { staticClass: "col-12 mb-3" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$tl(
                            "labels.booking.stats.filters.serviceCategory",
                            _vm.firstSelectedRestaurantId
                          )
                        ) +
                        "\n                "
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.serviceCategory,
                            expression: "filters.serviceCategory",
                          },
                        ],
                        staticClass:
                          "custom-select d-inline-block ml-2 mr-2 mb-0",
                        staticStyle: { width: "initial" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.filters,
                              "serviceCategory",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { domProps: { value: null } }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.stats.filters.allServiceCategories",
                                  _vm.firstSelectedRestaurantId
                                )
                              ) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.servicesCategories, function (category) {
                          return _c(
                            "option",
                            {
                              key: category.fr,
                              domProps: { value: category.fr },
                            },
                            [
                              _vm.inEnum(
                                category.fr,
                                _vm.ALL_DEFAULT_SERVICE_CATEGORIES
                              )
                                ? [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.getDefaultServiceCategoryLabel(
                                            category.fr
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.isUserLangFr
                                            ? category.fr
                                            : category.en
                                        ) +
                                        "\n                        "
                                    ),
                                  ],
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isGlobalReport
                ? _c("div", { staticClass: "col-md-12 mb-3" }, [
                    _c("div", { staticClass: "row mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-5" },
                        [
                          _c("strong", { staticClass: "text-success" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.stats.groupOne",
                                  _vm.firstSelectedRestaurantId
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.main.selectedZone,
                                  expression: "filters.main.selectedZone",
                                },
                              ],
                              staticClass: "custom-select mb-1",
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.filters.main,
                                      "selectedZone",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  _vm.updateRestaurantsMain,
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "all" } }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.booking.globalStats.filters.allEstablishments",
                                        _vm.firstSelectedRestaurantId
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.zones, function (zone) {
                                return _c(
                                  "option",
                                  {
                                    key: zone.id,
                                    domProps: { value: zone.id },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(zone.name) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("vue-multiselect", {
                            staticClass: "mt-1",
                            staticStyle: { "font-size": "12.8px !important" },
                            attrs: {
                              options: _vm.allRestaurants,
                              multiple: true,
                              "close-on-select": false,
                              selectLabel: "",
                              selectedLabel: _vm.$tl(
                                "labels.booking.globalStats.filters.selectedEstablishments",
                                _vm.firstSelectedRestaurantId
                              ),
                              deselectLabel: _vm.$tl(
                                "labels.booking.globalStats.filters.deselectedEstablishments",
                                _vm.firstSelectedRestaurantId
                              ),
                              label: "name",
                              "track-by": "id",
                              placeholder: _vm.$tl(
                                "labels.booking.globalStats.filters.placeholder",
                                _vm.firstSelectedRestaurantId
                              ),
                            },
                            model: {
                              value: _vm.filters.main.selectedRestaurants,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.filters.main,
                                  "selectedRestaurants",
                                  $$v
                                )
                              },
                              expression: "filters.main.selectedRestaurants",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-2 text-center pt-2 pb-2" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-inline-block align-middle lead-switch ml-1",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.hasGroupComparison,
                                    expression: "filters.hasGroupComparison",
                                  },
                                ],
                                staticClass:
                                  "switch align-self-center is-rounded",
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.filters.hasGroupComparison
                                  )
                                    ? _vm._i(
                                        _vm.filters.hasGroupComparison,
                                        null
                                      ) > -1
                                    : _vm.filters.hasGroupComparison,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.filters.hasGroupComparison,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.filters,
                                            "hasGroupComparison",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.filters,
                                            "hasGroupComparison",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.filters,
                                        "hasGroupComparison",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.getTooltip(
                                      _vm.$tl(
                                        "labels.filters.compare",
                                        _vm.firstSelectedRestaurantId
                                      )
                                    ),
                                    expression:
                                      "getTooltip($tl('labels.filters.compare', firstSelectedRestaurantId))",
                                  },
                                ],
                                on: { click: _vm.toggleGroupComparison },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.filters.hasGroupComparison
                        ? _c(
                            "div",
                            { staticClass: "col-md-5" },
                            [
                              _c("strong", { staticClass: "text-success" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.stats.groupTwo",
                                      _vm.firstSelectedRestaurantId
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.filters.comparison.selectedZone,
                                      expression:
                                        "filters.comparison.selectedZone",
                                    },
                                  ],
                                  staticClass: "custom-select mb-1",
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.filters.comparison,
                                          "selectedZone",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      _vm.updateRestaurantsComparison,
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "all" } }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.globalStats.filters.allEstablishments",
                                            _vm.firstSelectedRestaurantId
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.zones, function (zone) {
                                    return _c(
                                      "option",
                                      {
                                        key: zone.id,
                                        domProps: { value: zone.id },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(zone.name) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("vue-multiselect", {
                                staticClass: "mt-1",
                                staticStyle: {
                                  "font-size": "12.8px !important",
                                },
                                attrs: {
                                  options: _vm.allRestaurants,
                                  multiple: true,
                                  "close-on-select": false,
                                  selectLabel: "",
                                  selectedLabel: _vm.$tl(
                                    "labels.booking.globalStats.filters.selectedEstablishments",
                                    _vm.firstSelectedRestaurantId
                                  ),
                                  deselectLabel: _vm.$tl(
                                    "labels.booking.globalStats.filters.deselectedEstablishments",
                                    _vm.firstSelectedRestaurantId
                                  ),
                                  label: "name",
                                  "track-by": "id",
                                  placeholder: _vm.$tl(
                                    "labels.booking.globalStats.filters.placeholder",
                                    _vm.firstSelectedRestaurantId
                                  ),
                                },
                                model: {
                                  value:
                                    _vm.filters.comparison.selectedRestaurants,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filters.comparison,
                                      "selectedRestaurants",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "filters.comparison.selectedRestaurants",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-5" }, [
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("strong", { staticClass: "text-success" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.stats.periodOne",
                              _vm.firstSelectedRestaurantId
                            )
                          ) +
                          "\n                        "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.main.currentPeriod,
                            expression: "filters.main.currentPeriod",
                          },
                        ],
                        staticClass: "custom-select mb-0",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.filters.main,
                              "currentPeriod",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "today" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$tl(
                                "labels.today",
                                _vm.firstSelectedRestaurantId
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "yesterday" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$tl(
                                "labels.yesterday",
                                _vm.firstSelectedRestaurantId
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "last_week" } }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.filters.dates.lastWeek",
                                  _vm.firstSelectedRestaurantId
                                )
                              ) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "last_month" } }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.filters.dates.lastMonth",
                                  _vm.firstSelectedRestaurantId
                                )
                              ) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "last_year" } }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.filters.dates.lastYear",
                                  _vm.firstSelectedRestaurantId
                                )
                              ) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "custom_period" } }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.filters.dates.customPeriod",
                                  _vm.firstSelectedRestaurantId
                                )
                              ) +
                              "\n                            "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 d-flex" },
                    [
                      _c("DatePicker", {
                        staticClass: "d-inline-block date-resa-cal",
                        style: `width: 125px`,
                        attrs: {
                          "input-class": "form-control",
                          format: _vm.DATE_SHORT_WITH_WEEKDAY,
                          disabledDates: _vm.disabledDates,
                          placeholder: _vm.$tl(
                            "labels.filters.dates.start",
                            _vm.firstSelectedRestaurantId
                          ),
                          disabled: _vm.areMainDatesInputsDisabled,
                        },
                        model: {
                          value: _vm.filters.main.fromDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters.main, "fromDate", $$v)
                          },
                          expression: "filters.main.fromDate",
                        },
                      }),
                      _vm._v(" "),
                      _vm.shouldShowMainToDate
                        ? [
                            _c("feather", {
                              staticClass: "m-2",
                              attrs: { type: "arrow-right" },
                            }),
                            _vm._v(" "),
                            _c("DatePicker", {
                              staticClass: "d-inline-block date-resa-cal",
                              style: `width: 125px`,
                              attrs: {
                                "input-class": "form-control",
                                format: _vm.DATE_SHORT_WITH_WEEKDAY,
                                disabledDates: _vm.disabledDates,
                                placeholder: _vm.$tl(
                                  "labels.filters.dates.end",
                                  _vm.firstSelectedRestaurantId
                                ),
                                disabled: _vm.areMainDatesInputsDisabled,
                              },
                              model: {
                                value: _vm.filters.main.toDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters.main, "toDate", $$v)
                                },
                                expression: "filters.main.toDate",
                              },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-2 text-center pt-2 pb-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-inline-block align-middle lead-switch ml-1",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.hasComparison,
                          expression: "filters.hasComparison",
                        },
                      ],
                      staticClass: "switch align-self-center is-rounded",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.filters.hasComparison)
                          ? _vm._i(_vm.filters.hasComparison, null) > -1
                          : _vm.filters.hasComparison,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.filters.hasComparison,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.filters,
                                  "hasComparison",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.filters,
                                  "hasComparison",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.filters, "hasComparison", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.getTooltip(
                            _vm.$tl(
                              "labels.filters.compare",
                              _vm.firstSelectedRestaurantId
                            )
                          ),
                          expression:
                            "getTooltip($tl('labels.filters.compare', firstSelectedRestaurantId))",
                        },
                      ],
                      on: {
                        click: function ($event) {
                          _vm.filters.hasComparison = !_vm.filters.hasComparison
                        },
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.filters.hasComparison
                ? _c("div", { staticClass: "col-md-5" }, [
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("strong", { staticClass: "text-success" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.stats.periodTwo",
                                  _vm.firstSelectedRestaurantId
                                )
                              ) +
                              "\n                        "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.comparison.currentPeriod,
                                expression: "filters.comparison.currentPeriod",
                              },
                            ],
                            staticClass: "custom-select mb-0 mr-2",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.filters.comparison,
                                  "currentPeriod",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              { attrs: { value: "previous_period" } },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.filters.dates.previousPeriod",
                                        _vm.firstSelectedRestaurantId
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "previous_year" } },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.filters.dates.previousYear",
                                        _vm.firstSelectedRestaurantId
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "next_period" } }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.filters.dates.nextPeriod",
                                      _vm.firstSelectedRestaurantId
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "custom_period" } },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.filters.dates.customPeriod",
                                        _vm.firstSelectedRestaurantId
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 d-flex" },
                        [
                          _c("DatePicker", {
                            staticClass: "d-inline-block date-resa-cal",
                            style: `width: 125px`,
                            attrs: {
                              "input-class": "form-control",
                              format: _vm.DATE_SHORT_WITH_WEEKDAY,
                              disabledDates: _vm.disabledDates,
                              placeholder: _vm.$tl(
                                "labels.filters.dates.start",
                                _vm.firstSelectedRestaurantId
                              ),
                              disabled: _vm.areComparisonDatesInputsDisabled,
                            },
                            model: {
                              value: _vm.filters.comparison.fromDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.filters.comparison,
                                  "fromDate",
                                  $$v
                                )
                              },
                              expression: "filters.comparison.fromDate",
                            },
                          }),
                          _vm._v(" "),
                          _vm.shouldShowComparisonToDate
                            ? [
                                _c("feather", {
                                  staticClass: "m-2",
                                  attrs: { type: "arrow-right" },
                                }),
                                _vm._v(" "),
                                _c("DatePicker", {
                                  staticClass: "d-inline-block date-resa-cal",
                                  attrs: {
                                    "input-class": "form-control",
                                    format: _vm.DATE_SHORT_WITH_WEEKDAY,
                                    disabledDates: _vm.disabledDates,
                                    placeholder: _vm.$tl(
                                      "labels.filters.dates.end",
                                      _vm.firstSelectedRestaurantId
                                    ),
                                    disabled:
                                      _vm.areComparisonDatesInputsDisabled,
                                  },
                                  model: {
                                    value: _vm.filters.comparison.toDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filters.comparison,
                                        "toDate",
                                        $$v
                                      )
                                    },
                                    expression: "filters.comparison.toDate",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-1" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(
                    "(" +
                      _vm._s(
                        _vm.$tc(
                          "labels.booking.stats.maxPeriod",
                          _vm.keepClientsDetailsAndHistoryFor
                        )
                      ) +
                      ")"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-2" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-success btn-circle",
                    on: { click: _vm.emitFiltersUpdate },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$tl(
                            "labels.form.actions.apply",
                            _vm.firstSelectedRestaurantId
                          )
                        ) +
                        "\n                "
                    ),
                  ]
                ),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }