var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(
        _vm._s(_vm.campaign.id !== null ? "Éditer" : "Créer") + " une campagne"
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.loading
          ? _c("LoaderComponent")
          : _c("campaign-add", {
              ref: "campaignAdd",
              attrs: {
                step: _vm.step,
                errors: _vm.errors.form,
                campaign: _vm.campaign,
                clients: _vm.clients,
                credits: _vm.credits,
                is_paying_emails_campaign: _vm.is_paying_emails_campaign,
              },
              on: {
                setIsPayingEmailsCampaign: function ($event) {
                  return _vm.$emit("setIsPayingEmailsCampaign", $event)
                },
                sendEmailTest: _vm.sendEmailTest,
                sendSmsTest: _vm.sendSmsTest,
                purchaseSmsCampaign: function ($event) {
                  return _vm.purchaseSmsCampaign()
                },
              },
            }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn-sm btn btn-secondary btn-circle",
            attrs: { type: "button" },
            on: { click: _vm.close },
          },
          [_vm._v("Fermer")]
        ),
        _vm._v(" "),
        _vm.step > 1
          ? _c(
              "button",
              {
                staticClass: "btn btn-success btn-sm btn-circle ml-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.step--
                  },
                },
              },
              [_vm._v("Étape précédente")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.step < 3
          ? _c(
              "button",
              {
                staticClass: "btn btn-success btn-sm btn-circle ml-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.step++
                  },
                },
              },
              [_vm._v("Étape suivante")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.step !== 3 || _vm.campaign.save_type !== "send"
          ? _c(
              "button",
              {
                staticClass: "btn btn-success btn-sm btn-circle ml-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.save(false)
                  },
                },
              },
              [_vm._v("\n            Enregistrer\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.step === 3 && _vm.campaign.save_type === "send"
          ? _c(
              "button",
              {
                staticClass: "btn btn-success btn-sm btn-circle ml-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.save(true)
                  },
                },
              },
              [_vm._v("\n            Envoyer\n        ")]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }