var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading ? _c("LoaderComponent") : _vm._e(),
      _vm._v(" "),
      _vm.smsCampaign.cost > 0
        ? _c(
            "div",
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4 mb-3" },
                    [
                      _c("show-sms-campaign-to-buy", {
                        attrs: { smsCampaign: _vm.smsCampaign },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("Facturation", {
                attrs: {
                  getInvoiceUrl: `/api/campaigns/smsCampaigns/invoice/${this.$route.params.restaurant_id}/${this.smsCampaign.campaign_id}`,
                },
                on: {
                  close: function ($event) {
                    return _vm.$emit("close")
                  },
                  paid: _vm.paid,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }