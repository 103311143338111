var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-5" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row m-0 pb-5" },
      [
        _vm.has_right_to_read
          ? _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("gift-vouchers-action-bar", {
                  attrs: {
                    "is-payment": "",
                    "advanced-filters": _vm.advancedFilters,
                  },
                  on: {
                    "fetch-data": _vm.updateDataFromFilterEvent,
                    "export-payments": _vm.exportPayments,
                    "dl-payments": _vm.downloadPayments,
                  },
                }),
                _vm._v(" "),
                _vm.isLoading
                  ? _c("LoaderComponent")
                  : _c("ns-table", {
                      attrs: {
                        tableClass:
                          "table table-striped overflowx-auto table-responsive-xl",
                        "data-source": _vm.sales,
                        "is-server-side": "",
                        clickable: "",
                        "allow-paging": "",
                        pagination: _vm.pagination,
                        "current-sort": _vm.queries.sort,
                      },
                      on: {
                        "update-data": _vm.updateDataFromTableEvent,
                        "row-selected": _vm.rowSelected,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "head",
                            fn: function () {
                              return [
                                _c(
                                  "ns-th",
                                  {
                                    attrs: {
                                      field: "gv_sales.numero",
                                      "allow-sorting": "",
                                    },
                                  },
                                  [_vm._v("N°")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ns-th",
                                  {
                                    attrs: {
                                      field: "gv_sales.amount",
                                      "allow-sorting": "",
                                    },
                                  },
                                  [_vm._v("Montant")]
                                ),
                                _vm._v(" "),
                                _c("ns-th", { attrs: { field: "status" } }, [
                                  _vm._v("Statut"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "ns-th",
                                  {
                                    attrs: {
                                      field: "gv_sales.client",
                                      "allow-sorting": "",
                                    },
                                  },
                                  [_vm._v("Client")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ns-th",
                                  {
                                    attrs: {
                                      field: "gv_sales.paid_at",
                                      "allow-sorting": "",
                                    },
                                  },
                                  [_vm._v("Date")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ns-th",
                                  {
                                    attrs: {
                                      field: "gv_sales.payment_type",
                                      "allow-sorting": "",
                                    },
                                  },
                                  [
                                    _c("small", [_vm._v("Moyen de")]),
                                    _vm._v(" paiement"),
                                  ]
                                ),
                                _vm._v(" "),
                                !_vm.isTvaDisabled
                                  ? _c(
                                      "ns-th",
                                      {
                                        attrs: {
                                          field: "gv_sales.tva.five_dot_five",
                                        },
                                      },
                                      [
                                        _c("small", [_vm._v("TVA")]),
                                        _vm._v(" 5.5%"),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isTvaDisabled
                                  ? _c(
                                      "ns-th",
                                      { attrs: { field: "gv_sales.tva.ten" } },
                                      [
                                        _c("small", [_vm._v("TVA")]),
                                        _vm._v(" 10%"),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isTvaDisabled
                                  ? _c(
                                      "ns-th",
                                      {
                                        attrs: { field: "gv_sales.tva.twenty" },
                                      },
                                      [
                                        _c("small", [_vm._v("TVA")]),
                                        _vm._v(" 20%"),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "ns-th",
                                  {
                                    attrs: {
                                      field: "gv_sales.stripe_fees",
                                      "allow-sorting": "",
                                    },
                                  },
                                  [_vm._v("Frais")]
                                ),
                                _vm._v(" "),
                                _c("ns-th", [_vm._v("Actions")]),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "body",
                            fn: function ({ data }) {
                              return [
                                _c("td", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(data.numero) +
                                      "\n                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.formatCurrency(
                                            data.expedition_fees !== null
                                              ? data.amount +
                                                  data.expedition_fees
                                              : data.amount
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                    data.is_group_payment
                                      ? _c("feather", {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value:
                                                _vm.getTooltip(
                                                  "Paiment de groupe"
                                                ),
                                              expression:
                                                "getTooltip('Paiment de groupe')",
                                            },
                                          ],
                                          staticClass: "ml-2",
                                          attrs: { type: "users" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("gift-voucher-status-component", {
                                      attrs: {
                                        sale: data,
                                        editable: _vm.has_right_to_update_sales,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    data.client
                                      ? _c("inline-client", {
                                          attrs: {
                                            client: data.client,
                                            "module-enum":
                                              _vm.MODULE_TYPE_GIFT_VOUCHERS,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        data.paid_at !== null
                                          ? _vm.formatDate(data.paid_at)
                                          : "--"
                                      ) +
                                      "\n                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.getPaymentTypeLabel(
                                          data.payment_type
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                  _vm.hasPaymentType(data)
                                    ? _c("img", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value:
                                              _vm.getTooltip(
                                                "Paiement en ligne"
                                              ),
                                            expression:
                                              "getTooltip('Paiement en ligne')",
                                          },
                                        ],
                                        staticStyle: {
                                          width: "14px",
                                          "margin-left": "2px",
                                          "margin-bottom": "2px",
                                        },
                                        attrs: { src: _vm.imageURL(data) },
                                      })
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                !_vm.isTvaDisabled
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            data.tva &&
                                              data.tva.five_dot_five &&
                                              data.status !== "refund"
                                              ? _vm.formatCurrency(
                                                  data.tva.five_dot_five / 100
                                                )
                                              : "--"
                                          ) +
                                          "\n                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isTvaDisabled
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            data.tva &&
                                              data.tva.ten &&
                                              data.status !== "refund"
                                              ? _vm.formatCurrency(
                                                  data.tva.ten / 100
                                                )
                                              : "--"
                                          ) +
                                          "\n                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isTvaDisabled
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            data.tva &&
                                              data.tva.twenty &&
                                              data.status !== "refund"
                                              ? _vm.formatCurrency(
                                                  data.tva.twenty / 100
                                                )
                                              : "--"
                                          ) +
                                          "\n                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("td", [
                                  data.card_system_type ===
                                    _vm.CARD_PAYMENT_SYSTEM_PAYPLUG.value &&
                                  data.payplug_payment_id
                                    ? _c("span", [_vm._v("NC")])
                                    : _c("span", [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              data.stripe_fees !== null
                                                ? _vm.formatCurrency(
                                                    data.stripe_fees
                                                  )
                                                : "--"
                                            ) +
                                            "\n                        "
                                        ),
                                      ]),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltip(
                                            "Télécharger la facture"
                                          ),
                                          expression:
                                            "getTooltip('Télécharger la facture')",
                                        },
                                      ],
                                      staticClass:
                                        "btn btn-sm btn-outline-secondary btn-square",
                                      attrs: {
                                        disabled:
                                          !_vm.has_right_to_update_sales ||
                                          !data.invoice ||
                                          (!data.stripe_payment_intent_id &&
                                            !data.payplug_payment_id) ||
                                          !data.paid_at ||
                                          data.status == "pending",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.downloadInvoice(data.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("feather", {
                                        attrs: { type: "download-cloud" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2964190027
                      ),
                    }),
              ],
              1
            )
          : _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v(
                "Désolé, vous n'avez pas le droit d'accéder à cette page."
              ),
            ]),
        _vm._v(" "),
        _vm.canDisplayModal && _vm.has_right_to_read_sales
          ? _c("show-gift-voucher-modal", {
              attrs: { sale_id: _vm.selectedSale.id, "must-not-emit": "" },
              on: {
                close: function ($event) {
                  _vm.canDisplayModal = false
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [_c("h5", { staticClass: "title mt-2" }, [_vm._v("Paiements")])]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }