import { render, staticRenderFns } from "./CustomTags.vue?vue&type=template&id=3807b730"
import script from "./CustomTags.vue?vue&type=script&lang=js"
export * from "./CustomTags.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3807b730')) {
      api.createRecord('3807b730', component.options)
    } else {
      api.reload('3807b730', component.options)
    }
    module.hot.accept("./CustomTags.vue?vue&type=template&id=3807b730", function () {
      api.rerender('3807b730', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/Booking/Settings/InputParams/CustomTags.vue"
export default component.exports