var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(_vm._s(_vm.$tl("labels.notifications.preview.sms"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.loading
          ? _c("LoaderComponent")
          : _c(
              "div",
              [
                _vm.error
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.error) +
                          "\n            "
                      ),
                    ])
                  : [
                      _c("textarea", {
                        staticClass: "form-control",
                        attrs: { rows: "4", disabled: "" },
                        domProps: { value: _vm.resultSmsContent },
                      }),
                      _vm._v(" "),
                      _vm.nbCreditsNeeded > 3
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$tl("errors.common.sms.exceedMaxLength")
                                ) +
                                "\n                "
                            ),
                          ])
                        : _c(
                            "span",
                            {
                              class: {
                                "text-success": _vm.nbCreditsNeeded < 2,
                                "text-warning": _vm.nbCreditsNeeded === 2,
                                "text-danger": _vm.nbCreditsNeeded > 2,
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t("labels.smsLength", {
                                      smsLength: _vm.smsLength,
                                    })
                                  ) +
                                  " (" +
                                  _vm._s(
                                    _vm.$tc(
                                      "labels.smsCost",
                                      _vm.nbCreditsNeeded
                                    )
                                  ) +
                                  ")\n                "
                              ),
                            ]
                          ),
                    ],
              ],
              2
            ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass: "modal-default-button btn btn-secondary btn-circle",
            attrs: { type: "button" },
            on: { click: _vm.close },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.close")) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }