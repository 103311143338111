import Vue from "vue";
import VueRouter from "vue-router";
import Notifications from "vue-notification";

import admin from "./admin";
import booking from "./booking";
import click_and_collect from "./click_and_collect";
import gift_vouchers from "./gift_vouchers";
import campaigns from "./campaigns";
import websites from "./websites";
import common from "./common";

import Page404 from "../pages/404.vue";

Vue.use(VueRouter);
Vue.use(Notifications);

const router = new VueRouter({
    mode: "history",
    routes: [
        ...admin,
        ...booking,
        ...click_and_collect,
        ...gift_vouchers,
        ...campaigns,
        ...websites,
        ...common,
        {
            path: "/*",
            name: "notfound",
            component: Page404,
        },
    ],
});

router.beforeEach((to, from, next) => {
    const fromName = from ? from.name : null;
    const toName = to ? to.name : null;

    if (!fromName || !toName) {
        return next();
    }

    const fromNameExploded = fromName.split(".");
    const toNameExploded = toName.split(".");

    if (fromNameExploded[0] === "website" && router.app.$store.getters["websites/unsaved_changes"]) {
        if (
            toNameExploded[0] === "website" &&
            toNameExploded.length > 2 &&
            fromNameExploded.length > 2 &&
            toNameExploded[2] === fromNameExploded[2]
        ) {
            return next();
        }

        if (confirm(router.app.$tl("questions.common.unsavedChanges"))) {
            router.app.$store.dispatch("websites/setUnsavedChanges", false);
            next();
        }

        return;
    }

    if (
        fromName === "booking.restaurants.settings.notifications" &&
        toName !== "booking.restaurants.settings.notifications" &&
        router.app.$store.getters["restaurants/unsavedNotificationsChanges"]
    ) {
        if (confirm(router.app.$tl("questions.common.unsavedChanges"))) {
            router.app.$store.dispatch("restaurants/setUnsavedNotificationsChanges", false);
            next();
        }

        return;
    }

    return next();
});

router.beforeResolve((to, from, next) => {
    const to_restaurant_id = Object.keys(to.params).length > 0 && to.params.restaurant_id ? to.params.restaurant_id : 0;

    if (to_restaurant_id && (to.name || "").startsWith("booking")) {
        router.app.$store.dispatch("widgets/getCurrentWidget", { restaurant_id: to_restaurant_id });
    }

    return next();
});

export default router;
