export default {
    data() {
        return {
            WIDGET_LANG_FR: {
                label: "Français",
                value: "fr",
                countryFlag: "fr",
                dropdownLabel: "Fr",
            },
            WIDGET_LANG_EN: {
                label: "Anglais",
                value: "en",
                countryFlag: "gb",
                dropdownLabel: "En",
            },
        };
    },
    methods: {
        getWidgetLangLabel(value) {
            const found = this.ALL_WIDGET_LANGS.find((w) => w.value == value);
            if (typeof found != "undefined") {
                return this.$t(`labels.langs.${value}`);
            }
            return value;
        },
        isWidgetLangValid(value) {
            return this.ALL_WIDGET_LANGS.some((l) => l.value === value);
        },
    },
    computed: {
        ALL_WIDGET_LANGS() {
            return [this.WIDGET_LANG_FR, this.WIDGET_LANG_EN];
        },
    },
};
