<template>
    <div class="pb-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">{{ $tl("labels.routes.clients") }}</h5>
                    <router-link
                        :to="{ name: `${moduleEnum.urlValue}.clients.form`, params: { restaurant_id, client_id: 'create' } }"
                        class="btn btn-sm btn-success btn-circle"
                        :class="{ disabled: !has_right_to_create_customer }">
                        <feather type="plus"></feather>
                        {{ $tl("labels.clients.add") }}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="row m-0 pb-5">
            <div class="col-12">
                <LoaderComponent v-if="loading" />
                <div v-else>
                    <div v-if="error" class="alert alert-danger">
                        {{ error }}
                    </div>
                    <div v-else>
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-2">
                            <div>
                                <button
                                    v-if="showLoyaltyConfig"
                                    style="margin-top: -5px"
                                    class="btn btn-sm btn-outline-secondary btn-circle none-mobile mr-1"
                                    @click="showOptions = !showOptions">
                                    {{ $tl("labels.clients.editLoyaltyConfig") }}
                                </button>
                                <show-new-badge-details class="mr-1" badge_uuid="cda2e56e-e425-4342-9c0f-65b650b5dae8" />
                                <div style="margin-top: 4px" class="d-inline-block">
                                    <form @submit.prevent="autoComplete">
                                        <div style="border-radius: 20px 0 0 20px !important" class="form-control search-client d-inline pr-1">
                                            <input
                                                class="search-input"
                                                :placeholder="$t('labels.clients.searchPlaceHolder')"
                                                type="text"
                                                v-model="autoloadSearch" />
                                            <button
                                                type="button"
                                                @click="clearSearch"
                                                class="p-0 m-0 clear-search-button"
                                                style="background: none; border: none">
                                                <feather type="x"></feather>
                                            </button>
                                        </div>
                                        <button
                                            type="submit"
                                            class="btn btn-sm btn-outline-secondary search-client-btn"
                                            style="border-radius: 0 20px 20px 0">
                                            <feather type="search"></feather>
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div>
                                <clients-caption
                                    class="position-absolute bg-white none-tablet"
                                    style="z-index: 100; right: 320px"
                                    v-show="showClientsCaption" />
                                <button
                                    type="button"
                                    class="none-tablet btn btn-sm btn-circle mr-2"
                                    :class="showClientsCaption ? 'btn-success' : 'btn-outline-secondary'"
                                    @click="showClientsCaption = !showClientsCaption">
                                    <feather type="help-circle" class="feather-orange" />
                                    {{ $tl("labels.clients.caption.title") }}
                                </button>
                                <div class="btn-group">
                                    <button
                                        type="button"
                                        :class="{ disabled: !has_right_to_import_and_export_customer }"
                                        :disabled="!has_right_to_import_and_export_customer"
                                        @click="showImportModal = true"
                                        class="btn btn-sm btn-outline-secondary mb-0 none-mobile"
                                        style="border-radius: 20px 0 0 20px">
                                        <feather type="download"></feather>
                                        {{ $tl("labels.form.actions.import") }}
                                    </button>
                                    <a
                                        :class="{ disabled: !has_right_to_import_and_export_customer }"
                                        target="_blank"
                                        :href="
                                            has_right_to_import_and_export_customer
                                                ? `/api/restaurants/${restaurant_id}/clients/export?lang=${userLang}`
                                                : ''
                                        "
                                        class="btn btn-sm btn-outline-secondary none-mobile"
                                        style="border-radius: 0 20px 20px 0">
                                        <feather type="upload"></feather>
                                        {{ $tl("labels.form.actions.export") }}
                                    </a>
                                </div>
                                <ImportClientsModal v-if="showImportModal" @close="closeImportModal" @sent="showImportModal = false">
                                </ImportClientsModal>
                            </div>
                        </div>

                        <div v-if="showLoyaltyConfig && showOptions" class="border-light b-radius-20">
                            <edit-clients-loyalty-configs :module-enum="moduleEnum" :restaurant-id="restaurant_id" />
                        </div>

                        <table v-if="clients && clients.length > 0" class="capitalize table table-sm table-striped border-bottom">
                            <thead class="border-bottom">
                                <tr>
                                    <th>{{ $tl("labels.form.client") }}</th>
                                    <th class="none-mobile">{{ $tl("labels.form.email") }}</th>
                                    <th>{{ $tl("labels.form.phoneNumber") }}</th>
                                    <th>{{ $tl("labels.form.actions.title") }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="client in clients" :key="client.id">
                                    <td>
                                        <inline-client :client="client" :module-enum="moduleEnum" show-noshows></inline-client>
                                    </td>
                                    <td class="none-mobile">
                                        {{ client.email }}
                                    </td>
                                    <td>
                                        <a :href="`tel:${client.tel}`">{{ client.tel }}</a>
                                    </td>
                                    <td>
                                        <router-link
                                            :to="{ name: `${moduleEnum.urlValue}.clients.form`, params: { client_id: client.id } }"
                                            class="btn btn-sm btn-success btn-square"
                                            :class="{
                                                disabled: !has_right_to_update_customer && !has_right_to_read_customer,
                                            }"
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: $t('labels.form.actions.edit'),
                                            }">
                                            <feather type="edit" />
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <em v-if="!isSearch">{{ $tl("labels.clients.emptyForRestaurant") }}</em>
                            <em v-else>{{ $tl("labels.noResult") }}</em>
                        </div>
                        <span
                            v-if="clients && clientsCountTotal > 0 && !isSearch"
                            class="badge badge-secondary ml-1 mr-2 float-left"
                            style="margin-top: 21px">
                            {{ $tc("labels.clients.count", clientsCountTotal) }}
                        </span>
                        <span
                            v-if="clients && clients.length > 0 && isSearch"
                            class="badge badge-secondary ml-1 mr-2 float-left"
                            style="margin-top: 21px">
                            {{ $tc("labels.clients.countSearch", clients.length) }}
                        </span>
                        <div v-if="!isSearch" class="mt-3">
                            <button
                                v-if="links.prev"
                                type="button"
                                class="btn btn-sm btn-outline-secondary btn-circle"
                                @click="fetchData(links.prev)">
                                {{ $tl("labels.form.actions.previous") }}
                            </button>
                            <button
                                v-if="links.next"
                                type="button"
                                class="btn btn-sm btn-outline-secondary btn-circle"
                                @click="fetchData(links.next)">
                                {{ $tl("labels.form.actions.next") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModuleTypesEnum from "../mixins/enums/ModuleTypesEnum.js";
import ImportClientsModal from "../components/Modals/clients/ImportClientsModal.vue";
import LoaderComponent from "../components/LoaderComponent.vue";
import RolesEnum from "../mixins/enums/RolesEnum";
import InlineClient from "../components/Default/Clients/inlineClient.vue";
import EditClientsLoyaltyConfigs from "../components/Default/Clients/editClientsLoyaltyConfigs.vue";
import clientsCaption from "../components/Default/Clients/clientsCaption.vue";
import showNewBadgeDetails from "../components/NewBadges/showNewBadgeDetails.vue";

export default {
    data() {
        return {
            loading: false,
            formLoading: false,
            clients: null,
            clientsCountTotal: 0,
            paginate: false,
            links: {
                next: null,
                prev: null,
            },
            error: null,
            showImportModal: false,
            showOptions: false,
            autoloadSearch: null,
            isSearch: false,
            clientFormLoading: false,
            showClientsCaption: false,
        };
    },
    mixins: [RolesEnum, ModuleTypesEnum],
    props: {
        user: {
            required: true,
        },
    },
    computed: {
        restaurant_id() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        moduleEnum() {
            const urlValue = this.$route.name.split(".")[0];
            return this.ALL_MODULES_TYPES_ALL.find((moduleEnum) => moduleEnum.urlValue === urlValue);
        },
        showLoyaltyConfig() {
            return this.inEnum(this.moduleEnum.value, [this.MODULE_TYPE_BOOKING, this.MODULE_TYPE_CLICK_AND_COLLECT, this.MODULE_TYPE_GIFT_VOUCHERS]);
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_customer() {
            return this.rights.includes("default.customer.create");
        },
        has_right_to_update_customer() {
            return this.rights.includes("default.customer.update");
        },
        has_right_to_import_and_export_customer() {
            return this.rights.includes("default.customer.export");
        },
    },
    methods: {
        fetchData(api_url) {
            this.isSearch = false;
            this.error = this.clients = this.links.next = this.links.prev = null;
            this.loading = true;

            if (!api_url) {
                api_url = `/api/restaurants/${this.restaurant_id}/clients`;
            }

            this.httpGet(api_url)
                .then((response) => {
                    if (response !== false) {
                        this.clients = response.data.data;
                        this.clientsCountTotal = response.data.meta.total;

                        if (response.data.links) {
                            this.paginate = true;
                            if (response.data.links.next) {
                                this.links.next = response.data.links.next;
                            }
                            if (response.data.links.prev) {
                                this.links.prev = response.data.links.prev;
                            }
                        }
                    }
                })
                .finally(() => (this.loading = false));
        },
        autoComplete() {
            this.isSearch = true;
            this.loading = true;

            this.httpGet(`/api/restaurants/${this.restaurant_id}/clients/search?search=${this.autoloadSearch}`)
                .then((response) => {
                    if (response !== false) {
                        this.clients = Object.values(response.data);
                    }
                })
                .finally(() => (this.loading = false));
        },
        clearSearch() {
            this.autoloadSearch = null;
            this.fetchData();
        },
        closeImportModal() {
            this.showImportModal = false;
            this.fetchData();
        },
    },
    watch: {
        restaurant_id() {
            this.fetchData();
        },
        "$route.query.message": {
            immediate: true,
            handler(message) {
                if (message && message !== "") {
                    this.notifySuccess(null, message);

                    this.$router.push("?");
                }
            },
        },
    },
    components: {
        ImportClientsModal,
        LoaderComponent,
        InlineClient,
        EditClientsLoyaltyConfigs,
        clientsCaption,
        showNewBadgeDetails,
    },
    created() {
        this.fetchData();
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
