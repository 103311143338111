export default {
    data() {
        return {
            BO_BOOKING_VIEW_LIST: {
                value: "list",
            },
            BO_BOOKING_VIEW_SEATING_PLAN: {
                value: "seatingPlan",
            },
            BO_BOOKING_VIEW_TIMELINE: {
                value: "timeline",
            },
        };
    },
    computed: {
        ALL_BO_BOOKING_VIEWS() {
            return [this.BO_BOOKING_VIEW_LIST, this.BO_BOOKING_VIEW_SEATING_PLAN, this.BO_BOOKING_VIEW_TIMELINE];
        },
    },
};
