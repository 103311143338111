<template>
    <div class="credit-card p-4 text-right">
        <div class="card-chip">
            <div class="card-line"></div>
            <div class="card-line"></div>
            <div class="card-line"></div>
        </div>
        <div>
            <span>{{ card.brand }}</span>
            <span>XXXX XXXX XXXX {{ card.last4 }}</span>
            <span>{{
                $t("labels.payments.expiresOn", {
                    date: card.exp_month.toString().padStart(2, "0") + "/" + card.exp_year,
                })
            }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        card: {
            type: Object,
            required: true,
        },
    },
};
</script>
