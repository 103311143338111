var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(_vm._s(_vm.$tl("labels.form.sms.enable"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-center", attrs: { slot: "body" }, slot: "body" },
      [
        _c(
          "div",
          [
            _c("p", [_vm._v(_vm._s(_vm.$tl("infos.sms.useNeedActivation")))]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "btn btn-sm btn-circle btn-success",
                attrs: { to: { name: "sms" } },
              },
              [_vm._v(_vm._s(_vm.$tl("labels.form.sms.enable")))]
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn-sm btn btn-secondary btn-circle",
            attrs: { type: "button" },
            on: { click: _vm.close },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.close")) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }