<template>
    <div>
        <div v-if="current_tag_raw == COMMAND_TO_ACCEPT.value">
            <button
                @click="changeStatus"
                :data-status="COMMAND_TO_PREPARE.value"
                data-name="À préparer"
                data-class="text-warning"
                v-tooltip="getTooltip('Accepter la commande')"
                :disabled="readonly || loading"
                class="btn btn-success btn-sm btn-square">
                <svg
                    style="pointer-events: none"
                    class="feather feather-check sc-dnqmqq jxshSx"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    aria-hidden="true">
                    <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
            </button>
            <button
                @click="changeStatus"
                :disabled="readonly || loading"
                v-tooltip="getTooltip('Refuser la commande')"
                :data-status="COMMAND_CANCELED.value"
                data-name="Annulée"
                data-class="text-danger"
                class="btn btn-danger btn-sm text-white btn-square">
                <svg
                    style="pointer-events: none"
                    class="feather feather-x sc-dnqmqq jxshSx"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    aria-hidden="true">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </button>
        </div>
        <div v-else ref="dropdown" class="dropdown">
            <div id="dropdown-container">
                <a
                    v-if="!readonly"
                    class="btn btn-sm btn-outline-secondary btn-circle resa-status"
                    :class="[current_tag_class, { 'dropdown-toggle': tags && tags.length > 0 }, current_tag_raw]"
                    href="#"
                    role="button"
                    ref="dropDown"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    {{ current_tag }}
                </a>
                <span v-else class="btn btn-sm btn-outline-secondary btn-circle resa-status" :class="[current_tag_class, current_tag_raw]">
                    {{ current_tag }}
                </span>

                <div v-if="tags && tags.length > 0" id="dropDownStatus" class="dropdown-menu shadow" aria-labelledby="dropdownMenuLink">
                    <div v-for="value in tags">
                        <button
                            href="javascript:"
                            :disabled="loading"
                            v-on:click.btn="changeStatus"
                            :data-status="value.tag"
                            :data-name="getCleanName(value.infos)"
                            :data-class="value.infos.class"
                            class="dropdown-item">
                            {{ getCleanName(value.infos) }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import PaymentStatusEnum from "../../../mixins/enums/click_and_collect/PaymentStatusEnum";
import CommandStatusEnum from "../../../mixins/enums/click_and_collect/CommandStatusEnum";
import BoPaymentTypeEnum from "../../../mixins/enums/click_and_collect/BoPaymentTypeEnum";
import CollectTypesEnum from "../../../mixins/enums/click_and_collect/CollectTypesEnum";

export default {
    props: ["command", "restaurant_id", "readonly", "editable_status"],
    mixins: [PaymentStatusEnum, CollectTypesEnum, CommandStatusEnum, BoPaymentTypeEnum],
    data() {
        return {
            loading: false,
            moment,
            tagsLabels: [
                {
                    tag: "to_prepare",
                    infos: {
                        cleanName: "À préparer",
                        cleanNameDelivery: "À préparer",
                        cleanNameDispatch: "À préparer",
                        class: "text-warning",
                    },
                },
                {
                    tag: "ready",
                    infos: {
                        cleanName: "Prête",
                        cleanNameDelivery: "À livrer",
                        cleanNameDispatch: "À livrer",
                        class: "text-success",
                    },
                },
                {
                    tag: "dispatched",
                    infos: {
                        cleanName: "",
                        cleanNameDelivery: "",
                        cleanNameDispatch: "Expédiée",
                        class: "text-success",
                    },
                },
                {
                    tag: "collected",
                    infos: {
                        cleanName: "Retirée",
                        cleanNameDelivery: "Livrée",
                        cleanNameDispatch: "Livrée",
                        class: "text-default",
                    },
                },
                {
                    tag: "canceled",
                    infos: {
                        cleanName: "Annulée",
                        cleanNameDelivery: "Annulée",
                        cleanNameDispatch: "Annulée",
                        class: "text-danger",
                    },
                },
            ],
            tags: [],
            current_tag: null,
            current_tag_raw: null,
            current_tag_class: null,
        };
    },
    methods: {
        getCleanName(tagInfos) {
            switch (this.command.collect_type) {
                case this.COLLECT_TYPE_COLLECT.value:
                    return tagInfos.cleanName;
                case this.COLLECT_TYPE_DELIVERY.value:
                    return tagInfos.cleanNameDelivery;
                case this.COLLECT_TYPE_DISPATCH.value:
                    return tagInfos.cleanNameDispatch;
                default:
                    return "";
            }
        },
        changeStatus: function (event) {
            event.preventDefault();
            event.stopPropagation();

            var new_status = event.currentTarget.dataset.status;
            var new_cleanName = event.currentTarget.dataset.name;
            var new_tag_class = event.currentTarget.dataset.class;

            if (new_status === "canceled") {
                if (
                    this.command.status === this.COMMAND_TO_ACCEPT.value ||
                    confirm("Êtes-vous sûr de vouloir annuler cette réservation ? (Cette action entraîne le remboursement de la commande)")
                ) {
                    this.loading = true;
                    this.$store
                        .dispatch("ccCommands/updateStatus", {
                            restaurant_id: this.restaurant_id,
                            command_id: this.command.id,
                            status: new_status,
                        })
                        .then(() => {
                            this.loading = false;
                            // display success message
                            this.$notify({
                                group: "notification",
                                type: "success",
                                title: "Commande modifiée.",
                            });
                            this.current_tag_raw = new_status;
                            this.current_tag = new_cleanName;
                            this.current_tag_class = new_tag_class;
                            this.buildStatus(new_status);
                            $(this.$refs.dropDown).dropdown("toggle");
                            this.$emit("status-updated", { command_id: this.command.id, status: new_status });
                        })
                        .catch((error) => {
                            this.loading = false;
                            let message = "La commande n'a pas pu être modifiée.";

                            if (error.response && !this.$_.isEmpty(error.response.data.message)) {
                                message = error.response.data.message;
                            } else if (error.message && !this.$_.isEmpty(error.message)) {
                                message = error.message;
                            }

                            this.$notify({
                                group: "notification",
                                type: "warn",
                                title: message,
                            });
                        });
                }
            } else {
                this.loading = true;
                this.$store
                    .dispatch("ccCommands/updateStatus", {
                        restaurant_id: this.restaurant_id,
                        command_id: this.command.id,
                        status: new_status,
                    })
                    .then(() => {
                        this.loading = false;
                        // display success message
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: "Commande modifiée.",
                        });
                        this.current_tag = new_cleanName;
                        this.current_tag_class = new_tag_class;
                        this.buildStatus(new_status);
                        if (
                            new_status === this.COMMAND_COLLECTED.value &&
                            this.command.bo_payment_type == this.BO_PAYMENT_TYPE_MANUAL.value &&
                            this.command.payment_status != this.PAYMENT_PAID.value
                        ) {
                            this.$emit("open-pay-manually-modal", this.command.id);
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        let message = "La commande n'a pas pu être modifiée.";

                        if (error.response && !this.$_.isEmpty(error.response.data.message)) {
                            message = error.response.data.message;
                        } else if (error.message && !this.$_.isEmpty(error.message)) {
                            message = error.message;
                        }

                        this.$notify({
                            group: "notification",
                            type: "warn",
                            title: message,
                        });
                    });
            }
        },
        buildStatus(new_status) {
            // define current tag
            this.current_tag_raw = new_status;

            let validTags = [];

            // build tags array
            if (this.command.collect_type === this.COLLECT_TYPE_DISPATCH.value) {
                if (this.current_tag_raw === this.COMMAND_TO_PREPARE.value) validTags = [this.COMMAND_READY.value];
            } else {
                if (this.current_tag_raw === this.COMMAND_TO_PREPARE.value) {
                    validTags = [this.COMMAND_READY.value, this.COMMAND_DISPATCHED.value, this.COMMAND_COLLECTED.value];
                } else if (this.current_tag_raw === this.COMMAND_READY.value) {
                    validTags = [this.COMMAND_DISPATCHED.value, this.COMMAND_COLLECTED.value];
                }
            }

            this.tags = this.tagsLabels.filter((tb) => validTags.includes(tb.tag));

            // update current status name and class
            if (!this.current_tag) {
                for (var i = 0; i < this.tagsLabels.length; i++) {
                    if (this.command.status === this.tagsLabels[i].tag) {
                        this.current_tag = this.getCleanName(this.tagsLabels[i].infos);
                        this.current_tag_class = this.tagsLabels[i].infos.class;
                        break;
                    }
                }
            }

            if (Array.isArray(this.editable_status)) this.tags = this.tags.filter((tag) => this.editable_status.includes(tag.tag));
        },
    },
    mounted() {
        if (this.command.status !== this.COMMAND_TO_ACCEPT.value) $(this.$refs.dropDown).dropdown();
    },
    created() {
        this.buildStatus(this.command.status);
    },
    watch: {
        command: {
            immediate: true,
            handler() {
                this.current_tag = null;
                this.buildStatus(this.command.status);
            },
        },
    },
};
</script>
