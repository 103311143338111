var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._v(
        "\n    Remboursement partiel effectué sur les produits suivants :\n    "
      ),
      _vm._l(_vm.command.command_products.data, function (cp) {
        return _c(
          "div",
          { key: cp.id },
          [
            cp.refund && cp.refund
              ? _c(
                  "span",
                  { staticClass: "d-block ml-2" },
                  [
                    cp.refund.quantity > 1
                      ? [_vm._v(_vm._s(cp.refund.quantity) + " x ")]
                      : _vm._e(),
                    _vm._v(
                      _vm._s(cp.product.name) +
                        " (\n            " +
                        _vm._s(
                          _vm.formatCurrency(
                            cp.product.price * cp.refund.quantity
                          )
                        ) +
                        " )\n        "
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(cp.command_product_option.data, function (co) {
              return _c("div", { key: co.id }, [
                co.refund && co.refund
                  ? _c(
                      "span",
                      { staticClass: "d-block ml-4" },
                      [
                        co.refund.quantity > 1
                          ? [_vm._v(_vm._s(co.refund.quantity) + " x ")]
                          : _vm._e(),
                        _vm._v(
                          _vm._s(co.product_option.cc_product.name) +
                            " (\n                " +
                            _vm._s(
                              _vm.formatCurrency(
                                co.product_option.price * co.refund.quantity
                              )
                            ) +
                            " )\n            "
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ])
            }),
          ],
          2
        )
      }),
      _vm._v(" "),
      _c("span", { staticClass: "d-block" }, [
        _vm._v(
          "Pour un total de " +
            _vm._s(_vm.formatCurrency(_vm.total_amount_refunded))
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }