var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      class: _vm.anchorClasses,
      style: _vm.anchorStyle,
      attrs: { download: _vm.fileName, href: "#" },
      on: { click: _vm.excelExport },
    },
    [
      _c("excel-download-icon", {
        attrs: { "icon-classes": _vm.iconClasses, iconStyle: _vm.iconStyle },
      }),
      _vm._v(
        "\n    " + _vm._s(_vm.$tl("labels.form.actions.excelExport")) + "\n"
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }