<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Détails du bon cadeau</h5>
        <div slot="body">
            <show-gift-voucher :sale="sale" :sale_id="sale_id || sale.id" :must-not-emit="mustNotEmit" @set-sale="$emit('set-sale', $event)" />
        </div>
        <div slot="footer" class="d-flex">
            <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="$emit('close')">Fermer</button>
        </div>
    </modal>
</template>

<script>
import showGiftVoucher from "../../giftVouchers/showGiftVoucher.vue";

export default {
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    props: {
        mustNotEmit: {
            type: Boolean,
            default: false,
        },
        sale: {
            type: Object,
            default: null,
        },
        sale_id: {
            default: null,
        },
    },
    components: {
        showGiftVoucher,
    },
};
</script>
