export default {
    data() {
        return {
            CLIENT_CIVILITY_MR: {
                value: "monsieur",
            },
            CLIENT_CIVILITY_MME: {
                value: "madame",
            },
        };
    },
    methods: {
        getClientCivilityLabelShort(value, translateMethod = undefined) {
            const found = this.ALL_CLIENT_CIVILITIES.find((w) => w.value == value);
            if (typeof found != "undefined") {
                if ("undefined" === typeof translateMethod) {
                    return this.$t(`labels.clients.civilities.short.${value}`);
                }
                return translateMethod(`labels.clients.civilities.short.${value}`);
            }
            return value;
        },
        getClientCivilityLabelLong(value, translateMethod = undefined) {
            const found = this.ALL_CLIENT_CIVILITIES.find((w) => w.value == value);
            if (typeof found != "undefined") {
                if ("undefined" === typeof translateMethod) {
                    return this.$t(`labels.clients.civilities.long.${value}`);
                }
                return translateMethod(`labels.clients.civilities.long.${value}`);
            }
            return value;
        },
    },
    computed: {
        ALL_CLIENT_CIVILITIES() {
            return [this.CLIENT_CIVILITY_MR, this.CLIENT_CIVILITY_MME];
        },
    },
};
