var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "stripe-card-item d-flex flex-row border-light position-relative",
      class: _vm.isSelected ? "selected" : "",
      on: {
        click: function ($event) {
          _vm.cardSelected = _vm.card
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "icon" },
        [
          _c("feather", {
            staticClass: "w-auto h-auto",
            attrs: { type: "credit-card", size: "28" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "w-100 p-3 d-flex justify-content-between align-items-center",
        },
        [
          _c("div", [
            _c("div", { staticClass: "d-inline-block" }, [
              _c("p", { staticClass: "m-0" }, [
                _vm._v("XXXX XXXX XXXX " + _vm._s(_vm.card.card["last4"])),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "m-0 align-top ml-1" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.expAt) +
                    "\n                "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm.editable && _vm.has_right_to_update_subscription
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-circle btn-primary btn-sm",
                    attrs: { disabled: !_vm.has_right_to_update_subscription },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("addNewCard")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.form.actions.modify")) +
                        "\n            "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }