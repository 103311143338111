<template>
    <div class="pb-5 pt-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">{{ $tl("labels.routes.booking.config.integration") }}</h5>
                    <a target="_blank" :href="widgetLink">
                        <button :disabled="!token" class="btn btn-sm btn-success btn-sm btn-circle mr-1" type="button">
                            {{ $tl("labels.widget.show") }}
                        </button>
                    </a>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div class="row m-0 mb-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row mb-3">
                                <div class="col-12">
                                    <strong>{{ $tl("infos.widget.infosIntegrationWebsite.title") }}</strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <p>
                                        {{
                                            $t("infos.widget.infosIntegrationWebsite.content1", {
                                                type: $tl("labels.widget.booking"),
                                            })
                                        }}
                                    </p>
                                    <p>{{ $t("infos.widget.infosIntegrationWebsite.content2") }}</p>
                                    <p class="mb-0 text-danger">
                                        {{ $t("infos.widget.infosIntegrationWebsite.content3") }}
                                    </p>
                                    <br />
                                    <button
                                        @click="showSendWidgetDocToDevModal = true"
                                        :disabled="!has_right_to_update_widget"
                                        :class="{ disabled: !has_right_to_update_widget }"
                                        class="btn btn-sm btn-success btn-sm btn-circle"
                                        type="button">
                                        {{ $tl("labels.widget.sendInstructions") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mb-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-12">
                                    <strong>{{ $tl("labels.widget.integrationWebsite") }}</strong>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-5">
                                    <label>{{ $tl("labels.widget.defaultLang") }}</label>
                                </div>
                                <div class="col-md-7">
                                    <select class="custom-select" style="width: auto" :disabled="isSavingDefaultLang" v-model="default_lang">
                                        <option :value="lang.value" v-for="lang in ALL_WIDGET_LANGS" :key="lang.value">
                                            {{ getWidgetLangLabel(lang.value) }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-5">
                                    <label>{{ $tl("labels.widget.shortLink") }}</label>
                                </div>
                                <div class="col-md-7">
                                    <div class="d-flex align-items-center">
                                        <span class="mr-2">{{ appUrl }}/reservation/</span>
                                        <input
                                            type="text"
                                            class="form-control"
                                            style="width: 45%"
                                            maxlength="40"
                                            v-model="shortlink"
                                            :disabled="!has_right_to_update_widget" />
                                        <button
                                            :disabled="!has_right_to_update_widget || isSavingShortlink"
                                            :class="{ disabled: !has_right_to_update_widget || isSavingShortlink }"
                                            @click="saveShortLink"
                                            class="btn btn-sm btn-success btn-sm btn-circle ml-2"
                                            type="button">
                                            {{ $tl("labels.form.actions.save") }}
                                        </button>
                                    </div>
                                    <div>
                                        <small class="text-muted">{{ $t("infos.widget.shortLink", { max: 40 }) }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-5">
                                    <label>{{ $tl("labels.widget.apiKey") }}</label>
                                </div>
                                <div class="col-md-7">
                                    <div class="mb-3 code-source" v-if="token">
                                        {{ token }}
                                    </div>
                                    <button
                                        :disabled="!has_right_to_update_widget"
                                        :class="{ disabled: !has_right_to_update_widget }"
                                        @click="generateNewApiKey"
                                        class="btn btn-sm btn-success btn-sm btn-circle"
                                        type="button">
                                        <span v-if="!token">{{ $tl("labels.widget.genApiKey") }}</span
                                        ><span v-else>{{ $tl("labels.widget.genNewApiKey") }}</span></button
                                    ><br />
                                    <small v-if="token" class="text-danger">{{ $tl("infos.widget.oldKeyRevoked") }}</small>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-md-5 pt-1">
                                    <label>{{ $tl("labels.widget.integrationMethod") }}</label>
                                </div>
                                <div class="col-md-7 pt-1">
                                    <p>
                                        <strong>{{ $t("labels.stepX", { step: 1 }) }}{{ $tl("labels.colon") }}</strong>
                                        {{ $t("infos.widget.stepsIntegration.1") }}
                                    </p>

                                    <div class="code-source mb-3">&lt;div id="{{ $tl("labels.widget.containerId") }}"&gt;&lt;/div&gt;</div>

                                    <p>
                                        <strong>{{ $t("labels.stepX", { step: 2 }) }}{{ $tl("labels.colon") }}</strong>
                                        {{ $t("infos.widget.stepsIntegration.2") }}
                                    </p>

                                    <div class="code-source mb-3">
                                        <span class="d-block" v-for="(js, index) in jsToInclude" :key="index">{{ js }}</span>
                                    </div>

                                    <p>
                                        <strong>{{ $t("labels.stepX", { step: 3 }) }}{{ $tl("labels.colon") }}</strong>
                                        {{ $t("infos.widget.stepsIntegration.3") }}
                                    </p>

                                    <div class="code-source mb-3">
                                        {{ classFunctionName }}('{{ $tl("labels.widget.containerId") }}', '{{ $tl("labels.widget.yourApiKey") }}');
                                    </div>

                                    <p class="mb-0">
                                        <strong
                                            >{{ $t("labels.stepX", { step: 4 }) }} ({{ $tl("labels.optionalF") }}){{ $tl("labels.colon") }}</strong
                                        >
                                        {{ $t("infos.widget.stepsIntegration.4") }}<br />
                                        {{ $tl("infos.widget.optionalParams") }}
                                    </p>
                                    <ol>
                                        <li class="list-style-inherit">
                                            "lang"{{ $tl("labels.colon") }} {{ $tl("infos.widget.lang") }}
                                            <ul>
                                                <li class="list-style-inherit" v-for="availableLang in ALL_WIDGET_LANGS" :key="availableLang.value">
                                                    "{{ availableLang.value }}"{{ $tl("labels.colon") }}
                                                    {{ getWidgetLangLabel(availableLang.value) }}
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <div class="code-source mb-3">
                                        {{ classFunctionName }}('{{ $tl("labels.widget.containerId") }}', '{{ $tl("labels.widget.yourApiKey") }}', {
                                        lang: '{{ WIDGET_LANG_EN.value }}' });
                                    </div>
                                    <p class="mb-0">
                                        <strong
                                            >{{ $t("labels.stepX", { step: 5 }) }} ({{ $tl("labels.optionalF") }}){{ $tl("labels.colon") }}</strong
                                        >
                                        {{ $t("infos.widget.stepsIntegration.5") }} <br /><br />
                                        <span class="text-warning">{{ $tl("infos.widget.gTagManager.gTagPrerequisite") }}</span>
                                        <br />
                                        {{ $tl("infos.widget.gTagManager.gTagFirst") }}<br />
                                    </p>
                                    <div class="code-source mb-3">
                                        &lt;!-- Google Tag Manager --&gt;<br />
                                        &lt;script&gt;<br />
                                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var
                                        f=d.getElementsByTagName(s)[0],
                                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})
                                        (window,document,'script','dataLayer', '<span class="text-danger">{{
                                            $tl("infos.widget.gTagManager.gTagIdExample")
                                        }}</span
                                        >');<br />
                                        &lt;/script&gt;<br />
                                        &lt;!-- End Google Tag Manager --&gt;
                                    </div>
                                    <p class="mb-0">
                                        {{ $tl("infos.widget.gTagManager.gTagSecond") }} <br /><br />
                                        {{ $tl("infos.widget.gTagManager.gTagInfo") }}
                                    </p>
                                    <ul>
                                        <li class="list-style-inherit">
                                            {{ $t("infos.widget.gTagManager.gTagStep1") }}
                                        </li>
                                        <li class="list-style-inherit">
                                            {{ $t("infos.widget.gTagManager.gTagStep2") }}
                                        </li>
                                        <li class="list-style-inherit">
                                            {{ $t("infos.widget.gTagManager.gTagStep3") }}
                                        </li>
                                        <li class="list-style-inherit">
                                            {{ $t("infos.widget.gTagManager.gTagStep4") }}
                                        </li>
                                        <li class="list-style-inherit">
                                            {{ $t("infos.widget.gTagManager.gTagStep5") }}
                                        </li>
                                        <li class="list-style-inherit">
                                            {{ $t("infos.widget.gTagManager.gTagStep6") }}
                                        </li>
                                        <li class="list-style-inherit">
                                            {{ $t("infos.widget.gTagManager.gTagStep7") }}
                                        </li>
                                        <li class="list-style-inherit">
                                            {{ $t("infos.widget.gTagManager.gTagStep8") }}
                                        </li>
                                        <li class="list-style-inherit">
                                            {{ $t("infos.widget.gTagManager.gTagStep9") }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row mt-3" v-if="token !== null">
                                <div class="col-md-12 pt-1">
                                    <p>
                                        <strong>{{ $t("labels.widget.code", { type: $tl("labels.widget.booking") }) }}</strong>
                                    </p>
                                    <div class="code-source mb-3">
                                        &lt;div id="{{ idName }}"&gt;&lt;/div&gt;
                                        <span class="d-block" v-for="(js, index) in jsToInclude" :key="index">{{ js }}</span>
                                        &lt;script&gt;{{ classFunctionName }}('{{ idName }}', '{{ token }}', { lang: '{{ WIDGET_LANG_FR.value }}'
                                        });&lt;/script&gt;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FacebookIntegration class="m-0 mb-3" :module="MODULE_TYPE_BOOKING.value" />

                <InstagramIntegration class="m-0 mb-3" :module="MODULE_TYPE_BOOKING.value" />

                <GoogleLinkIntegration class="m-0 mb-3" />
            </div>

            <SendWidgetDocToDevModal :url="sendWidgetDocToDevUrl" v-if="showSendWidgetDocToDevModal" @close="showSendWidgetDocToDevModal = false" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import SendWidgetDocToDevModal from "../../../components/Modals/SendWidgetDocToDevModal";
import OverlayLayout from "../../../components/layouts/OverlayLayout";
import WidgetLangsEnum from "../../../mixins/enums/booking/WidgetLangsEnum";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";
import FacebookIntegration from "../../../components/widgets/FacebookIntegration.vue";
import InstagramIntegration from "../../../components/widgets/InstagramIntegration.vue";
import GoogleLinkIntegration from "../../../components/widgets/GoogleLinkIntegration.vue";

export default {
    data() {
        return {
            appUrl: APP_URL,
            loading: false,
            formLoading: false,
            error: null,
            widget_id: null,
            token: "",
            expandWidget: false,
            showSendWidgetDocToDevModal: false,
            shortlink: "",
            isSavingShortlink: false,
            isSavingDefaultLang: false,
            default_lang: null,
            showFbIntegration: false,
            showInstaIntegration: false,
        };
    },
    mixins: [WidgetLangsEnum, ModuleTypesEnum],
    computed: {
        widgetLink() {
            if (!this.token) return "#";
            if (this.shortlink) return `/reservation/${this.shortlink}`;
            return `/booking/widget/public/${this.token}/page`;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_widget() {
            return this.rights.includes("booking.widget.update");
        },
        sendWidgetDocToDevUrl() {
            return `/api/restaurants/${this.restaurant_id}/widget/doc`;
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        jsToInclude() {
            return [
                '<isplease src="https://js.stripe.com/v3/"></isplease>'.replace(/isplease/g, "script"),
                '<isplease src="https://api.payplug.com/js/1/form.latest.js"></isplease>'.replace(/isplease/g, "script"),
                `<isplease src="${APP_URL}/booking/js/widget.js"></isplease>`.replace(/isplease/g, "script"),
            ];
        },
        classFunctionName() {
            return this.isNoShow ? "window.initNoShowBooking" : "window.initYServicesBooking";
        },
        idName() {
            return this.isNoShow ? "noshow-reservations" : "yservices-reservations";
        },
    },
    methods: {
        initData() {
            this.error = null;
            this.loading = true;

            axios
                .get(`/api/restaurants/${this.restaurant_id}/widget`)
                .then((response) => {
                    this.widget_id = response.data.id;
                    this.token = response.data.token;
                    this.shortlink = response.data.shortlink || "";
                    this.default_lang = response.data.default_lang;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => (this.loading = false));
        },
        generateNewApiKey() {
            if (this.token && !confirm(this.$tl("questions.widget.genApiKey"))) {
                return;
            }

            this.loading = true;

            axios
                .post(`/api/restaurants/${this.restaurant_id}/widget/api_key`, {
                    widget_id: this.widget_id,
                })
                .then((response) => {
                    this.token = response.data.token;

                    this.notifySuccess(response);
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => (this.loading = false));
        },
        saveShortLink() {
            this.isSavingShortlink = true;

            axios
                .post(`/api/restaurants/${this.restaurant_id}/widget/shortlink`, {
                    shortlink: this.shortlink,
                })
                .then((response) => {
                    this.notifySuccess(response);
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => (this.isSavingShortlink = false));
        },
        saveDefaultLang(newVal, oldVal) {
            this.isSavingDefaultLang = true;

            axios
                .post(`/api/restaurants/${this.restaurant_id}/widget/default_lang`, {
                    default_lang: newVal,
                })
                .then((response) => {
                    this.isSavingDefaultLang = false;

                    this.notifySuccess(response);
                })
                .catch((error) => {
                    this.default_lang = oldVal;

                    this.$nextTick(() => (this.isSavingDefaultLang = false));

                    this.notifyError(error);
                });
        },
    },
    created() {
        this.initData();
    },
    watch: {
        shortlink(newVal, oldVal) {
            if (!newVal || newVal.length < oldVal.length) return;
            if (newVal.length > 0 && !newVal.replace(oldVal, "").match(/^[a-z_\-]+$/)) this.shortlink = oldVal;
        },
        default_lang(newVal, oldVal) {
            if (oldVal && !this.isSavingDefaultLang && newVal != oldVal) this.saveDefaultLang(newVal, oldVal);
        },
    },
    components: {
        LoaderComponent,
        SendWidgetDocToDevModal,
        OverlayLayout,
        FacebookIntegration,
        InstagramIntegration,
        GoogleLinkIntegration,
    },
};
</script>

<style scoped>
.list-style-inherit {
    list-style: inherit;
}
</style>
