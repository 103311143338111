<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ opening_id ? "Éditer" : "Ajouter" }} une ouverture exceptionnelle</h5>
        <div slot="body">
            <add-click-and-collect-opening
                :opening_id="opening_id"
                ref="addClickAndCollectOpening"
                @saved="$emit('saved', $event)" />
        </div>
        <div slot="footer" class="d-flex">
            <button type="button" class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="close">
                Annuler
            </button>
            <button
                type="button"
                class="btn btn-sm btn-success btn-circle ml-2"
                @click="$refs.addClickAndCollectOpening.save()">
                Enregistrer
            </button>
        </div>
    </modal>
</template>

<script>
import addClickAndCollectOpening from "../../../clickAndCollect/settings/addClickAndCollectOpening";

export default {
    props: {
        opening_id: {
            default: null,
        },
    },
    methods: {
        close(e) {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        addClickAndCollectOpening,
    },
};
</script>
