var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _vm.ownerDetails && _vm.subscriptions
        ? _c("div", [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-3 mb-2",
                  },
                  [
                    _c("div", [
                      _c("h5", { staticClass: "title mt-2" }, [
                        _vm._v(
                          "\n                            Détails du compte "
                        ),
                        _c("span", { staticClass: "text-success" }, [
                          _vm._v(_vm._s(_vm.ownerDetails.email)),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  !_vm.isEditingOwner
                    ? _c("ShowOwnerDetails", {
                        staticClass: "border-light b-radius-20 p-4 mb-3",
                        attrs: { ownerDetails: _vm.ownerDetails },
                        on: {
                          "edit-owner": function ($event) {
                            _vm.isEditingOwner = true
                          },
                          "owner-deleted": function ($event) {
                            return _vm.$router.push("/")
                          },
                        },
                      })
                    : _c("EditOwner", {
                        staticClass: "border-light b-radius-20 p-4 mb-3",
                        attrs: { ownerId: _vm.owner_id },
                        on: {
                          "cancel-edit": function ($event) {
                            _vm.isEditingOwner = false
                          },
                          edited: function ($event) {
                            _vm.isEditingOwner = false
                            _vm.fetchOwnerDetails()
                          },
                        },
                      }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "border-light b-radius-20 p-4 mb-3" },
                  [
                    _c(
                      "div",
                      { staticClass: "row m-0" },
                      [
                        _c("div", { staticClass: "col-12 mb-3" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("div", [
                                _c("strong", [_vm._v("Licences")]),
                                _vm._v(" "),
                                _vm.subscriptions.length > 0
                                  ? _c("span", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(_vm.subscriptions.length) +
                                          ")"
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.ownerDetails.has_manual_billing
                                  ? _c(
                                      "small",
                                      {
                                        staticClass: "d-block text-muted mt-1",
                                      },
                                      [
                                        _c("feather", {
                                          staticClass: "text-warning",
                                          attrs: { type: "alert-triangle" },
                                        }),
                                        _vm._v(
                                          "\n                                        Facturation manuelle\n                                    "
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "d-flex" }, [
                                _vm.isYservices ||
                                _vm.ownerDetails.has_manual_billing
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-circle btn-success",
                                            class: {
                                              disabled:
                                                !_vm.isYservices &&
                                                !_vm.canUpdateSubscription,
                                            },
                                            attrs: {
                                              to: {
                                                name: "admin.owners.licences.create",
                                              },
                                            },
                                          },
                                          [
                                            _c("feather", {
                                              attrs: { type: "plus" },
                                            }),
                                            _vm._v(
                                              "\n                                            Ajouter des licences\n                                        "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.ownerDetails.has_active_subscription
                                  ? _c(
                                      "div",
                                      { staticClass: "ml-2 text-warning" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "mb-1" },
                                          [
                                            _vm.ownerDetails.enabled_by_admin
                                              ? [
                                                  _c("feather", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value:
                                                          _vm.getTooltipNotice(
                                                            "notices.admin.archive.disable"
                                                          ),
                                                        expression:
                                                          "getTooltipNotice('notices.admin.archive.disable')",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "pointer tooltip-infos",
                                                    attrs: { type: "info" },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-success btn-circle",
                                                      attrs: {
                                                        type: "button",
                                                        disabled:
                                                          _vm.isTogglingArchiveAccount,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.disableArchiveAccount,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    Désactiver le compte\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : [
                                                  _c("feather", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value:
                                                          _vm.getTooltipNotice(
                                                            "notices.admin.archive.enable"
                                                          ),
                                                        expression:
                                                          "getTooltipNotice('notices.admin.archive.enable')",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "pointer tooltip-infos",
                                                    attrs: { type: "info" },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-success btn-circle",
                                                      attrs: {
                                                        type: "button",
                                                        disabled:
                                                          _vm.isTogglingArchiveAccount,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.enableArchiveAccount,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    Ré-activer temporairement le compte\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          [
                                            _c("feather", {
                                              staticClass: "text-warning mr-1",
                                              attrs: { type: "alert-triangle" },
                                            }),
                                            _vm._v(
                                              "\n                                            Ce restaurateur ne possède aucune licence active\n                                        "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.subscriptions.length === 0
                          ? _c("div", { staticClass: "col-12 text-danger" }, [
                              _vm._v(
                                "Ce restaurateur ne possède aucune licence"
                              ),
                            ])
                          : _vm._l(_vm.subscriptions, function (subscription) {
                              return _c(
                                "div",
                                {
                                  key: subscription.id,
                                  staticClass: "col-12 mb-3",
                                },
                                [
                                  _c("ShowSubscriptionDetails", {
                                    staticClass: "border-light b-radius-20 p-4",
                                    attrs: {
                                      subscription: subscription,
                                      restaurants:
                                        _vm.ownerDetails.restaurants.data,
                                      "has-manual-billing":
                                        _vm.ownerDetails.has_manual_billing,
                                    },
                                    on: {
                                      "subscription-canceled":
                                        _vm.onSubscriptionCanceled,
                                      "subscription-assigned":
                                        _vm.onSubscriptionAssigned,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.isNoShow
              ? _c("div", { staticClass: "row m-0" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("Invoices", {
                        staticClass: "border-light b-radius-20 p-4 mb-3",
                        attrs: {
                          isComponent: true,
                          user_id: _vm.ownerDetails.id,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("ShowOwnerSmsList", {
                    staticClass: "border-light b-radius-20 p-4 mb-3",
                    attrs: { ownerDetails: _vm.ownerDetails },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("ShowOwnerRestaurants", {
                    staticClass: "border-light b-radius-20 p-4 mb-3",
                    attrs: {
                      ownerDetails: _vm.ownerDetails,
                      subscriptions: _vm.subscriptions,
                    },
                    on: { "restaurant-deleted": _vm.fetchOwnerDetails },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.isNoShow
              ? _c("div", { staticClass: "row m-0" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("ShowOwnerEmployees", {
                        staticClass: "border-light b-radius-20 p-4 mb-3",
                        attrs: { employees: _vm.ownerDetails.employees.data },
                        on: { "employee-deleted": _vm.fetchOwnerDetails },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isNoShow
              ? _c("div", { staticClass: "row m-0" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("ShowOwnerAcceptedCgvs", {
                        staticClass: "border-light b-radius-20 p-4 mb-3",
                        attrs: { cgvs: _vm.ownerDetails.accepted_cgvs.data },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }