var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mb-3" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _vm.nbLoading > 0
          ? _c("loader-component")
          : _vm.stats.main
          ? _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
              _c("div", { staticClass: "row m-0" }, [
                _c("h6", { staticClass: "mt-3 col-10" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$tl("labels.booking.stats.pax.title"))),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-end align-items-center lead-switch col-2",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-square",
                        class: _vm.showGraph ? "btn-success" : "btn-secondary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            _vm.showGraph = !_vm.showGraph
                          },
                        },
                      },
                      [_c("feather", { attrs: { type: "pie-chart" } })],
                      1
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row m-0 click-collect-report" }, [
                _c("div", { class: _vm.nbResaAndPaxGridClasses }, [
                  _c(
                    "div",
                    { staticClass: "border-light p-3 b-radius-20 h-100" },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$tl("labels.booking.stats.pax.web"))),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pt-2" }, [
                        _c(
                          "strong",
                          { staticClass: "value" },
                          [
                            !_vm.filters.hasGroupComparison
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.stats.periodOne"
                                        )
                                      ) +
                                      " " +
                                      _vm._s(_vm.stats.main.web) +
                                      " "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$tl("labels.booking.stats.groupOne")
                                      ) +
                                      " " +
                                      _vm._s(_vm.stats.main.web) +
                                      " "
                                  ),
                                ]),
                            _vm._v(" "),
                            _vm.stats.main.web != 0
                              ? [
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          _vm.formatPercent(
                                            _vm.stats.main.web_percent
                                          )
                                        ) +
                                        "%)"
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      (_vm.stats &&
                        _vm.stats.comparison &&
                        _vm.filters.hasComparison) ||
                      (_vm.stats.comparison && _vm.filters.hasGroupComparison)
                        ? [
                            _c(
                              "div",
                              { staticClass: "pt-2" },
                              [
                                _c(
                                  "strong",
                                  { staticClass: "text-success" },
                                  [
                                    !_vm.filters.hasGroupComparison
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.stats.periodTwo"
                                                )
                                              ) +
                                              " " +
                                              _vm._s(_vm.stats.comparison.web) +
                                              "\n                                    "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.stats.groupTwo"
                                                )
                                              ) +
                                              " " +
                                              _vm._s(_vm.stats.comparison.web) +
                                              " "
                                          ),
                                        ]),
                                    _vm._v(" "),
                                    _vm.stats.comparison.web != 0
                                      ? [
                                          _c("span", { staticClass: "ml-2" }, [
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  _vm.formatPercent(
                                                    _vm.stats.comparison
                                                      .web_percent
                                                  )
                                                ) +
                                                "%)"
                                            ),
                                          ]),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                !(
                                  _vm.stats.main.web == 0 &&
                                  _vm.stats.comparison.web == 0
                                )
                                  ? _c("ArrowPercentEvolutionComponent", {
                                      staticClass: "pt-2 font-weight-bold",
                                      attrs: {
                                        difference:
                                          _vm.stats.main.web -
                                          _vm.stats.comparison.web,
                                        percent: _vm.getEvolution(
                                          _vm.stats.main.web,
                                          _vm.stats.comparison.web
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.nbResaAndPaxGridClasses }, [
                  _c(
                    "div",
                    { staticClass: "border-light p-3 b-radius-20 h-100" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$tl("labels.booking.stats.pax.manual"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pt-2" }, [
                        _c(
                          "strong",
                          { staticClass: "value" },
                          [
                            !_vm.filters.hasGroupComparison
                              ? _c("span", [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.stats.periodOne"
                                        )
                                      ) +
                                      " " +
                                      _vm._s(_vm.stats.main.manual) +
                                      "\n                                "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$tl("labels.booking.stats.groupOne")
                                      ) +
                                      " " +
                                      _vm._s(_vm.stats.main.manual) +
                                      " "
                                  ),
                                ]),
                            _vm._v(" "),
                            _vm.stats.main.manual != 0
                              ? [
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          _vm.formatPercent(
                                            _vm.stats.main.manual_percent
                                          )
                                        ) +
                                        "%)"
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      (_vm.stats &&
                        _vm.stats.comparison &&
                        _vm.filters.hasComparison) ||
                      (_vm.stats.comparison && _vm.filters.hasGroupComparison)
                        ? [
                            _c(
                              "div",
                              { staticClass: "pt-2" },
                              [
                                _c(
                                  "strong",
                                  { staticClass: "text-success" },
                                  [
                                    !_vm.filters.hasGroupComparison
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.stats.periodTwo"
                                                )
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.stats.comparison.manual
                                              ) +
                                              "\n                                    "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.stats.groupTwo"
                                                )
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.stats.comparison.manual
                                              ) +
                                              " "
                                          ),
                                        ]),
                                    _vm._v(" "),
                                    _vm.stats.comparison.manual != 0
                                      ? [
                                          _c("span", { staticClass: "ml-2" }, [
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  _vm.formatPercent(
                                                    _vm.stats.comparison
                                                      .manual_percent
                                                  )
                                                ) +
                                                "%)"
                                            ),
                                          ]),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                !(
                                  _vm.stats.main.manual == 0 &&
                                  _vm.stats.comparison.manual == 0
                                )
                                  ? _c("ArrowPercentEvolutionComponent", {
                                      staticClass: "pt-2 font-weight-bold",
                                      attrs: {
                                        difference:
                                          _vm.stats.main.manual -
                                          _vm.stats.comparison.manual,
                                        percent: _vm.getEvolution(
                                          _vm.stats.main.manual,
                                          _vm.stats.comparison.manual
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _vm.hasEnabledRwg
                  ? _c("div", { class: _vm.nbResaAndPaxGridClasses }, [
                      _c(
                        "div",
                        { staticClass: "border-light p-3 b-radius-20 h-100" },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$tl("labels.booking.stats.pax.rwg"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "pt-2" }, [
                            _c(
                              "strong",
                              { staticClass: "value" },
                              [
                                !_vm.filters.hasGroupComparison
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.booking.stats.periodOne"
                                            )
                                          ) +
                                          " " +
                                          _vm._s(_vm.stats.main.rwg) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.booking.stats.groupOne"
                                            )
                                          ) +
                                          " " +
                                          _vm._s(_vm.stats.main.rwg) +
                                          " "
                                      ),
                                    ]),
                                _vm._v(" "),
                                _vm.stats.main.rwg != 0
                                  ? [
                                      _c("span", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.formatPercent(
                                                _vm.stats.main.rwg_percent
                                              )
                                            ) +
                                            "%)"
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          (_vm.stats &&
                            _vm.stats.comparison &&
                            _vm.filters.hasComparison) ||
                          (_vm.stats.comparison &&
                            _vm.filters.hasGroupComparison)
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "pt-2" },
                                  [
                                    _c(
                                      "strong",
                                      { staticClass: "text-success" },
                                      [
                                        !_vm.filters.hasGroupComparison
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.stats.periodTwo"
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.stats.comparison.rwg
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.stats.groupTwo"
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.stats.comparison.rwg
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                        _vm._v(" "),
                                        _vm.stats.comparison.rwg != 0
                                          ? [
                                              _c(
                                                "span",
                                                { staticClass: "ml-2" },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm.formatPercent(
                                                          _vm.stats.comparison
                                                            .rwg_percent
                                                        )
                                                      ) +
                                                      "%)"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    !(
                                      _vm.stats.main.rwg == 0 &&
                                      _vm.stats.comparison.rwg == 0
                                    )
                                      ? _c("ArrowPercentEvolutionComponent", {
                                          staticClass: "pt-2 font-weight-bold",
                                          attrs: {
                                            difference:
                                              _vm.stats.main.rwg -
                                              _vm.stats.comparison.rwg,
                                            percent: _vm.getEvolution(
                                              _vm.stats.main.rwg,
                                              _vm.stats.comparison.rwg
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { class: _vm.nbResaAndPaxGridClasses }, [
                  _c(
                    "div",
                    { staticClass: "border-light p-3 b-radius-20 h-100" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$tl("labels.booking.stats.pax.total"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pt-2" }, [
                        _c("strong", { staticClass: "value" }, [
                          !_vm.filters.hasGroupComparison
                            ? _c("span", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$tl("labels.booking.stats.periodOne")
                                    ) +
                                    " " +
                                    _vm._s(_vm.stats.main.total) +
                                    "\n                                "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$tl("labels.booking.stats.groupOne")
                                    ) +
                                    " " +
                                    _vm._s(_vm.stats.main.total) +
                                    " "
                                ),
                              ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      (_vm.stats &&
                        _vm.stats.comparison &&
                        _vm.filters.hasComparison) ||
                      (_vm.stats.comparison && _vm.filters.hasGroupComparison)
                        ? [
                            _c("div", { staticClass: "pt-2" }, [
                              _c("strong", { staticClass: "text-success" }, [
                                !_vm.filters.hasGroupComparison
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.booking.stats.periodTwo"
                                            )
                                          ) +
                                          " " +
                                          _vm._s(_vm.stats.comparison.total) +
                                          "\n                                    "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.booking.stats.groupTwo"
                                            )
                                          ) +
                                          " " +
                                          _vm._s(_vm.stats.comparison.total) +
                                          " "
                                      ),
                                    ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.stats.main.total !== 0 ||
                            _vm.stats.comparison.total !== 0
                              ? _c("ArrowPercentEvolutionComponent", {
                                  staticClass: "pt-2 font-weight-bold",
                                  attrs: {
                                    difference:
                                      _vm.stats.main.total -
                                      _vm.stats.comparison.total,
                                    percent: _vm.getEvolution(
                                      _vm.stats.main.total,
                                      _vm.stats.comparison.total
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.showGraph
                ? _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "pl-1 pl-xl-3 pr-3 pr-xl-1 pb-2 pb-xl-0",
                        class:
                          _vm.filters.hasComparison && _vm.stats.comparison
                            ? "col-xl-6 col-12"
                            : "col-xl-12",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "p-1 d-flex flex-column align-items-center h-100",
                            class:
                              _vm.filters.hasComparison && _vm.stats.comparison
                                ? "border-light"
                                : "col-xl-12",
                          },
                          [
                            _c("strong", { staticClass: "text-success py-3" }, [
                              _vm._v(_vm._s(_vm.labelPeriodMain)),
                            ]),
                            _vm._v(" "),
                            _vm.stats.main.total === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "h-100 d-flex align-items-center",
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.booking.stats.noDataOnPeriod"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]),
                                  ]
                                )
                              : _c("v-chart", {
                                  staticClass: "chart",
                                  attrs: {
                                    option: {
                                      title: _vm.nbPaxByOrigin.title,
                                      series: _vm.pieChartNbPaxByOrigin,
                                      tooltip: _vm.tooltipOrigin,
                                      grid: _vm.grid,
                                    },
                                  },
                                }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.filters.hasComparison && _vm.stats.comparison
                      ? _c(
                          "div",
                          { staticClass: "col-xl-6 col-12 pl-1 pr-3" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-light b-radius-20 p-1 d-flex flex-column align-items-center h-100",
                              },
                              [
                                _c(
                                  "strong",
                                  { staticClass: "text-success py-3" },
                                  [_vm._v(_vm._s(_vm.labelPeriodComparison))]
                                ),
                                _vm._v(" "),
                                _vm.stats.comparison.total === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "h-100 d-flex align-items-center",
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.stats.noDataOnPeriod"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _c("v-chart", {
                                      staticClass: "chart",
                                      attrs: {
                                        option: {
                                          title: _vm.nbPaxByOrigin.title,
                                          series:
                                            _vm.pieChartNbPaxByOriginComparison,
                                          tooltip: _vm.tooltipOrigin,
                                          grid: _vm.grid,
                                        },
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }