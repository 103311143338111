var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-12 mt-2" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("p", [
          _vm._v(
            _vm._s(
              _vm.$tl(
                "labels.booking.reservations.remainingPaxes",
                _vm.restaurantId,
                { paxes: _vm.currentRemainingPaxes }
              )
            )
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.$tl("labels.booking.reservations.edit.table")) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "div",
          [
            _c("multi-select-tables", {
              attrs: {
                "reservation-date": _vm.reservationDate,
                "service-id": _vm.reservation.slot.service_id,
                "restaurant-id": _vm.restaurantId,
                reservation: _vm.reservation,
                "selected-table": _vm.selectedTable,
                "current-room-index": _vm.currentRoomIndex,
                "current-remaining-paxes": _vm.currentRemainingPaxes,
              },
              on: {
                "pax-used": function ($event) {
                  _vm.paxUsed = $event
                },
                "updated-tables": function ($event) {
                  return _vm.$emit("updated-tables", $event)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }