<template>
    <div>
        <loader-component v-if="loadings.customEvent || loadings.form" />
        <div v-else-if="errors.fetchCustomEvent !== null" class="text-danger">
            {{ errors.fetchCustomEvent }}
        </div>
        <form v-else ref="form" method="post" @submit.prevent="submit">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <input-component
                            v-model="customEvent.title"
                            input-name="title"
                            required
                            :disabled="!isEditable"
                            :label="$tl('labels.form.title')"
                            :form-errors="errors.form" />

                        <div class="row mb-2">
                            <div class="col-5 pt-2">
                                <label for="description">
                                    {{ $tl("labels.form.description") }}
                                </label>
                            </div>
                            <div class="col-7">
                                <textarea
                                    :disabled="!isEditable"
                                    name="description"
                                    id="description"
                                    v-model="customEvent.description"
                                    class="form-control"></textarea>
                                <show-errors class="d-block" :errors="errors.form" errorKey="description" />
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-5 pt-2">
                                <label>{{ $tl("labels.startDate") }} <small>*</small></label>
                            </div>
                            <div class="col-7 date-resa-cal">
                                <date-picker :disabled="!isEditable" v-model="customEvent.date_begin" />
                                <show-errors :errors="errors.form" class="d-block" errorKey="date_begin" />
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-5 pt-2">
                                <label>{{ $tl("labels.endDate") }} <small>*</small></label>
                            </div>
                            <div class="col-7 date-resa-cal">
                                <date-picker :disabled="!isEditable" v-model="customEvent.date_end" />
                                <show-errors :errors="errors.form" class="d-block" errorKey="date_end" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-5 pt-2">
                                <label for="priority">
                                    {{ $tl("labels.booking.customEvents.priority") }}
                                </label>
                            </div>
                            <div class="col-7">
                                <div class="d-flex align-items-center">
                                    <span
                                        class="d-block mr-1"
                                        :style="`width: 10px; height: 10px; background-color: ${getCustomEventPriorityColor(
                                            customEvent.priority
                                        )}`"></span>

                                    <select
                                        :disabled="!isEditable"
                                        id="priority"
                                        name="priority"
                                        v-model="customEvent.priority"
                                        required
                                        class="custom-select mb-0">
                                        <option
                                            v-for="customEventPriority in ALL_CUSTOM_EVENT_PRIORITIES"
                                            :key="customEventPriority.value"
                                            :value="customEventPriority.value">
                                            {{ getCustomEventPriorityLabel(customEventPriority.value) }}
                                        </option>
                                    </select>
                                </div>
                                <show-errors class="d-block" :errors="errors.form" errorKey="priority" />
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-5 pt-2">
                                <label for="visibility">
                                    {{ $tl("labels.booking.customEvents.visibility") }}
                                    <feather
                                        v-tooltip="getTooltipNotice('notices.booking.customEvents.visibility')"
                                        type="info"
                                        class="ml-1 pointer tooltip-infos" />
                                </label>
                            </div>
                            <div class="col-7">
                                <div class="d-flex align-items-center">
                                    <select
                                        :disabled="!isEditable"
                                        id="visibility"
                                        name="visibility"
                                        v-model="customEvent.visibility"
                                        required
                                        class="custom-select mb-0">
                                        <option
                                            v-for="customEventVisibility in ALL_CUSTOM_EVENT_VISIBILITIES"
                                            :key="customEventVisibility.value"
                                            :value="customEventVisibility.value">
                                            {{ getCustomEventVisibilityLabel(customEventVisibility.value) }}
                                        </option>
                                    </select>
                                </div>
                                <show-errors class="d-block" :errors="errors.form" errorKey="visibility" />
                            </div>
                        </div>

                        <div class="row mb-2 mt-3">
                            <div class="col-5">
                                <label>{{ $tl("labels.restaurants") }}</label>
                            </div>
                            <div class="col-7">
                                <div>
                                    <div class="mb-2" v-for="restaurant in availableRestaurants" :key="restaurant.id">
                                        <label class="container-box" style="margin-bottom: 0; width: 100%">
                                            <input
                                                type="checkbox"
                                                name="restaurants[]"
                                                v-model="customEvent.restaurants"
                                                :disabled="!isEditable"
                                                :value="restaurant.id" />
                                            <span class="checkmark"></span> {{ restaurant.name }}
                                        </label>
                                    </div>
                                </div>
                                <show-errors class="d-block" :errors="errors.form" errorKey="restaurants" />
                                <show-errors class="d-block" :errors="errors.form" errorKey="restaurants.*" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3" v-if="showSaveButton">
                <div class="col-12">
                    <input
                        type="submit"
                        name="submit"
                        :disabled="!isEditable"
                        :value="$tl('labels.form.actions.save')"
                        class="btn btn-success btn-circle" />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import InputComponent from "../forms/InputComponent.vue";
import ShowErrors from "../errors/ShowErrors.vue";
import CustomEventPriorityEnum from "../../mixins/enums/booking/CustomEventPriorityEnum.js";
import CustomEventVisibilityEnum from "../../mixins/enums/booking/CustomEventVisibilityEnum.js";
import DatePicker from "../forms/DatePicker.vue";

export default {
    data() {
        return {
            loadings: {
                customEvent: false,
                form: false,
            },
            errors: {
                fetchCustomEvent: null,
                form: null,
            },
            customEvent: {
                id: null,
                title: "",
                description: "",
                date_begin: null,
                date_end: null,
                priority: null,
                restaurants: [],
            },
        };
    },
    mixins: [CustomEventPriorityEnum, CustomEventVisibilityEnum],
    props: {
        restaurantId: {
            type: Number,
            required: true,
        },
        customEventId: {
            type: Number,
            default: null,
        },
        date: {
            type: String,
            default: null,
        },
        showSaveButton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isEditable() {
            return true;
        },
        availableRestaurants() {
            return this.$store.getters["restaurants/restaurants"];
        },
    },
    methods: {
        fetchCustomEvent() {
            this.errors.fetchCustomEvent = null;

            if (this.customEventId === null) {
                this.$emit("fully-loaded", { customEvent: this.customEvent });
                return;
            }

            this.loadings.customEvent = true;

            this.httpGet(`/api/restaurants/${this.restaurantId}/customEvents/${this.customEventId}`, { mustReject: true })
                .then((response) => {
                    Object.assign(this.customEvent, response.data);

                    this.customEvent.date_begin = this.getDateTime(response.data.date_begin);
                    this.customEvent.date_end = this.getDateTime(response.data.date_end);

                    this.$emit("fully-loaded", { customEvent: this.customEvent });
                })
                .catch((error) => (this.errors.fetchCustomEvent = this.getErrorMsgFromErrorResponse(error)))
                .finally(() => (this.loadings.customEvent = false));
        },
        submit() {
            if (!this.isEditable) {
                return null;
            }

            this.loadings.form = true;

            let promise = null;
            const formattedData = this.getFormattedData();

            if (this.customEventId !== null) {
                promise = this.httpPut(`/api/restaurants/${this.restaurantId}/customEvents/${this.customEventId}`, formattedData, {
                    defaultMessage: this.$tl("success.booking.customEvents.edited"),
                });
            } else {
                promise = this.httpPost(`/api/restaurants/${this.restaurantId}/customEvents`, formattedData, {
                    defaultMessage: this.$tl("success.booking.customEvents.added"),
                });
            }

            promise.finally(() => (this.loadings.form = false));

            this.$emit("submit", { promise });

            return promise;
        },
        getFormattedData() {
            const obj = Object.assign({}, this.customEvent);

            obj.date_begin = obj.date_begin.toISODate();
            obj.date_end = obj.date_end.toISODate();

            return obj;
        },
    },
    created() {
        if (this.customEventId === null) {
            if (this.date !== null) {
                this.customEvent.date_begin = this.getDateTime(this.date);
                this.customEvent.date_end = this.getDateTime(this.date);
            }

            this.customEvent.priority = this.CUSTOM_EVENT_PRIORITY_NORMAL.value;
            this.customEvent.visibility = this.CUSTOM_EVENT_VISIBILITY_INTERNAL.value;
            this.customEvent.restaurants.push(this.restaurantId);
        }

        this.fetchCustomEvent();
    },
    watch: {
        isEditable: {
            immediate: true,
            handler(newVal) {
                this.$emit("is-editable", newVal);
            },
        },
        restaurantId() {
            this.fetchCustomEvent();
        },
        customEventId() {
            this.fetchCustomEvent();
        },
    },
    components: {
        LoaderComponent,
        InputComponent,
        ShowErrors,
        DatePicker,
    },
};
</script>
