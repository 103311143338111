<template>
    <div>
        <LoaderComponent v-if="loading > 0" />
        <div v-else>
            <div class="row mb-3" v-if="product.type === 'single'">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-md-5">
                                <label>Type de produit</label>
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="radio">
                                            <label class="container-box">
                                                <input type="radio" v-model="product.single_type" value="single" />
                                                <span class="checkmark"></span> Produit simple
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-1">
                                        <div class="radio">
                                            <label class="container-box">
                                                <input type="radio" v-model="product.single_type" value="menu" />
                                                <span class="checkmark"></span> Produit de formule
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-1">
                                        <div class="radio">
                                            <label class="container-box">
                                                <input type="radio" v-model="product.single_type" value="both" />
                                                <span class="checkmark"></span> Les deux
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-2">
                            <div class="col-md-5 pt-1">
                                <label>Nom <small>*</small></label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" maxlength="70" class="form-control" v-model="product.name" />
                                <small class="text-muted"
                                    >Actuel : {{ product.name ? product.name.length : 0 }} caractère{{
                                        product.name && product.name.length > 1 ? "s" : ""
                                    }}</small
                                >
                                <small class="text-muted">Max : 70 caractères</small>
                                <ShowErrors class="d-block" :errors="errors" errorKey="name" />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5 pt-1">
                                <label>Description</label>
                            </div>
                            <div class="col-md-7">
                                <textarea class="form-control" rows="3" v-model="product.description"></textarea>
                                <ShowErrors :errors="errors" errorKey="description" />
                            </div>
                        </div>
                        <div class="row mb-2 badge-allergens" v-if="product.type !== 'menu'">
                            <div class="col-md-5 pt-1">
                                <label>Allergènes</label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch pt-1 mb-2">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        :true-value="true"
                                        :false-value="false"
                                        v-model="product.enable_allergens" />
                                    <label @click="product.enable_allergens = !product.enable_allergens"></label>
                                </div>
                                <template v-if="product.enable_allergens">
                                    <div>
                                        <span
                                            class="badge badge-secondary p-1 pl-2 pr-2 m-1"
                                            v-bind:class="[isAllergenActive('Gluten') ? 'active-badge' : '']"
                                            @click="toggleAllergen('Gluten')">
                                            Gluten
                                        </span>
                                        <span
                                            class="badge badge-secondary p-1 pl-2 pr-2 m-1"
                                            v-bind:class="[isAllergenActive('Crustacé') ? 'active-badge' : '']"
                                            @click="toggleAllergen('Crustacé')">
                                            Crustacé
                                        </span>
                                        <span
                                            class="badge badge-secondary p-1 pl-2 pr-2 m-1"
                                            v-bind:class="[isAllergenActive('Oeuf') ? 'active-badge' : '']"
                                            @click="toggleAllergen('Oeuf')">
                                            Oeuf
                                        </span>
                                        <span
                                            class="badge badge-secondary p-1 pl-2 pr-2 m-1"
                                            v-bind:class="[isAllergenActive('Poisson') ? 'active-badge' : '']"
                                            @click="toggleAllergen('Poisson')">
                                            Poisson
                                        </span>
                                        <span
                                            class="badge badge-secondary p-1 pl-2 pr-2 m-1"
                                            v-bind:class="[isAllergenActive('Arachide') ? 'active-badge' : '']"
                                            @click="toggleAllergen('Arachide')">
                                            Arachide
                                        </span>
                                        <span
                                            class="badge badge-secondary p-1 pl-2 pr-2 m-1"
                                            v-bind:class="[isAllergenActive('Soja') ? 'active-badge' : '']"
                                            @click="toggleAllergen('Soja')">
                                            Soja
                                        </span>
                                        <span
                                            class="badge badge-secondary p-1 pl-2 pr-2 m-1"
                                            v-bind:class="[isAllergenActive('Lait') ? 'active-badge' : '']"
                                            @click="toggleAllergen('Lait')">
                                            Lait
                                        </span>
                                        <span
                                            class="badge badge-secondary p-1 pl-2 pr-2 m-1"
                                            v-bind:class="[isAllergenActive('Fruit à coque') ? 'active-badge' : '']"
                                            @click="toggleAllergen('Fruit à coque')">
                                            Fruit à coque
                                        </span>
                                        <span
                                            class="badge badge-secondary p-1 pl-2 pr-2 m-1"
                                            v-bind:class="[isAllergenActive('Céleri') ? 'active-badge' : '']"
                                            @click="toggleAllergen('Céleri')">
                                            Céleri
                                        </span>
                                        <span
                                            class="badge badge-secondary p-1 pl-2 pr-2 m-1"
                                            v-bind:class="[isAllergenActive('Moutarde') ? 'active-badge' : '']"
                                            @click="toggleAllergen('Moutarde')">
                                            Moutarde
                                        </span>
                                        <span
                                            class="badge badge-secondary p-1 pl-2 pr-2 m-1"
                                            v-bind:class="[isAllergenActive('Sésame') ? 'active-badge' : '']"
                                            @click="toggleAllergen('Sésame')">
                                            Sésame
                                        </span>
                                        <span
                                            class="badge badge-secondary p-1 pl-2 pr-2 m-1"
                                            v-bind:class="[isAllergenActive('Sulfite') ? 'active-badge' : '']"
                                            @click="toggleAllergen('Sulfite')">
                                            Sulfite
                                        </span>
                                        <span
                                            class="badge badge-secondary p-1 pl-2 pr-2 m-1"
                                            v-bind:class="[isAllergenActive('Lupin') ? 'active-badge' : '']"
                                            @click="toggleAllergen('Lupin')">
                                            Lupin
                                        </span>
                                        <span
                                            class="badge badge-secondary p-1 pl-2 pr-2 m-1"
                                            v-bind:class="[isAllergenActive('Mollusque') ? 'active-badge' : '']"
                                            @click="toggleAllergen('Mollusque')">
                                            Mollusque
                                        </span>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="product.single_type !== 'menu'">
                            <div class="col-md-5 pt-1">
                                <label>Catégorie *</label>
                            </div>
                            <div class="col-md-7">
                                <select class="custom-select" v-model="product.cc_products_category_id">
                                    <option :value="null">--</option>
                                    <option v-for="category in products_categories" :key="category.id" :value="category.id">
                                        {{ category.name }}
                                    </option>
                                    <option :value="-1">Personnalisée</option>
                                </select>
                                <input
                                    v-if="product.cc_products_category_id === -1"
                                    type="text"
                                    v-model="custom_category_name"
                                    class="form-control" />
                                <ShowErrors :errors="errors" errorKey="cc_products_category_id" />
                                <ShowErrors :errors="errors" errorKey="custom_category_name" />
                            </div>
                        </div>
                        <div class="row mb-2" v-if="product.single_type !== 'menu'">
                            <div class="col-md-5 pt-1">
                                <label class="mb-0">Image <small>(recommandé)</small></label
                                ><br />
                            </div>
                            <div class="col-md-7">
                                <image-uploader
                                    :public-image-uri="product.img !== null ? product.img.public_uri : null"
                                    :image-path="product.img !== null ? product.img.path : null"
                                    :upload-uri="imgPostUri"
                                    :delete-uri="imgPostUri"
                                    @image-uploaded="product.img = $event"
                                    @image-deleted="product.img = null"
                                    :maxSize="10" />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5 pt-1">
                                <label>Prix de vente TTC <small>*</small></label
                                ><br />
                                <small class="text-warning" v-if="product.single_type == 'menu' && !isTvaDisabled"
                                    >Dans le cas d'un produit de formule, le prix de vente TTC est le prix théorique du produit qui sera utilisé pour
                                    le calcul de la ventilation de TVA. Ce prix ne sera jamais affiché pour le client.</small
                                >
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-4">
                                        <input type="number" class="form-control" v-model="product.price" />
                                    </div>
                                    <div class="col-md-8 mt-1">euros</div>
                                    <ShowErrors class="col-12" :errors="errors" errorKey="price" />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="product.single_type !== 'menu'">
                            <div class="col-md-5 pt-1">
                                <label>Prix de vente TTC (promotionnel) <small>*</small></label>
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                :true-value="true"
                                                :false-value="false"
                                                v-model="product.enable_price_offer" />
                                            <label @click="product.enable_price_offer = !product.enable_price_offer"></label>
                                        </div>
                                    </div>
                                    <template v-if="product.enable_price_offer">
                                        <div class="col-12 mt-1">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <input type="number" class="form-control" v-model="product.price_offer" />
                                                </div>
                                                <div class="col-md-8 mt-1">euros</div>
                                                <ShowErrors class="col-12" :errors="errors" errorKey="price_offer" />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="date-resa-cal">
                                                <span class="d-block">à partir du</span>
                                                <datepicker
                                                    format="dd/MM/yyyy"
                                                    calendar-class="positition-relative mt-2"
                                                    :disabled-dates="disabledDates"
                                                    class="w-auto d-inline-block"
                                                    :monday-first="true"
                                                    :language="fr"
                                                    v-model="product.offer_starts_on">
                                                </datepicker>
                                                <feather type="x" class="text-danger pointer" @click="product.offer_starts_on = null" />
                                                <span class="d-block">&nbsp;jusqu'au&nbsp;</span>
                                                <datepicker
                                                    format="dd/MM/yyyy"
                                                    calendar-class="positition-relative mt-2"
                                                    :disabled-dates="disabledDates"
                                                    class="w-auto d-inline-block"
                                                    :monday-first="true"
                                                    :language="fr"
                                                    v-model="product.offer_ends_on">
                                                </datepicker>
                                                <feather type="x" class="text-danger pointer" @click="product.offer_ends_on = null" />
                                            </div>
                                        </div>
                                        <ShowErrors class="col-12" :errors="errors" errorKey="offer_starts_on" />
                                        <ShowErrors class="col-12" :errors="errors" errorKey="offer_ends_on" />
                                    </template>
                                    <ShowErrors class="col-12" :errors="errors" errorKey="enable_price_offer" />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="!isTvaDisabled">
                            <div class="col-md-5 pt-1">
                                <label class="mb-0">Taux de TVA <small>*</small></label
                                ><br />
                                <small
                                    ><a href="https://www.service-public.fr/professionnels-entreprises/vosdroits/F23567" target="_blank"
                                        ><feather type="info" class="text-warning mr-1" />Législation TVA</a
                                    ></small
                                >
                            </div>
                            <div class="col-md-7">
                                <div class="row" v-if="product.type === 'single'">
                                    <div class="col-md-4">
                                        <select class="custom-select mb-0" placeholder="Veuillez sélectionner un taux" v-model="product.tax_rate">
                                            <option :value="null">--</option>
                                            <option :value="5.5">5,5</option>
                                            <option :value="10">10</option>
                                            <option :value="20">20</option>
                                        </select>
                                    </div>
                                    <div class="col-md-8 mt-1">%</div>
                                    <ShowErrors class="col-12" :errors="errors" errorKey="tax_rate" />
                                </div>
                                <span class="text-warning d-block mt-1" v-else
                                    >Le taux de TVA de la formule sera automatiquement calculé en fonction des produits qui la composent.</span
                                >
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5 pt-1">
                                <label>Stock <small>*</small></label>
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-4">
                                        <select
                                            class="custom-select mb-0"
                                            style="width: initial"
                                            v-model="product.stock_type"
                                            :disabled="product_stock_nolimit"
                                            :class="{ disabled: product_stock_nolimit }">
                                            <option :value="DAILY_STOCK.value">{{ DAILY_STOCK.label }}</option>
                                            <option :value="GENERAL_STOCK.value">{{ GENERAL_STOCK.label }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <input :disabled="product_stock_nolimit" type="number" class="form-control" v-model="product.stock" />
                                    </div>
                                    <div class="col-md-4 mt-1">
                                        <label class="container-box" style="width: initial">
                                            <input type="checkbox" v-model="product_stock_nolimit" />
                                            <span class="checkmark"></span> Illimité
                                        </label>
                                    </div>
                                    <ShowErrors class="col-12" :errors="errors" errorKey="stock" />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="config.is_click_and_collect_enabled">
                            <div class="col-md-5 pt-1">
                                <label>Disponible en point de retrait <small>*</small></label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        :true-value="true"
                                        :false-value="false"
                                        v-model="product.is_enabled_for_click_and_collect" />
                                    <label @click="product.is_enabled_for_click_and_collect = !product.is_enabled_for_click_and_collect"></label>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="config.is_delivery_enabled">
                            <div class="col-md-5 pt-1">
                                <label>Disponible à la livraison <small>*</small></label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        :true-value="true"
                                        :false-value="false"
                                        v-model="product.is_enabled_for_delivery" />
                                    <label @click="product.is_enabled_for_delivery = !product.is_enabled_for_delivery"></label>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="config.is_dispatch_enabled">
                            <div class="col-md-5 pt-1">
                                <label>Disponible à l'expédition <small>*</small></label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        :true-value="true"
                                        :false-value="false"
                                        v-model="product.is_enabled_for_dispatch" />
                                    <label @click="product.is_enabled_for_dispatch = !product.is_enabled_for_dispatch"></label>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="product.is_enabled_for_dispatch">
                            <div class="col-md-5">
                                <label>Poids du produit <small>*</small></label>
                            </div>
                            <div class="col-md-7">
                                <div class="row" v-if="product.type === 'single'">
                                    <div class="col-md-4">
                                        <input type="number" class="form-control" v-model="product.weight" />
                                    </div>
                                    <div class="col-md-8 mt-1">grammes</div>
                                    <ShowErrors class="col-12" :errors="errors" errorKey="weight" />
                                </div>
                                <span class="text-warning d-block mt-1" v-else
                                    >Le poids de la formule sera automatiquement calculé en fonction du poids des produits qui la composent.</span
                                >
                            </div>
                        </div>
                        <div class="row mb-2" v-if="product.type === 'single' && hasMealVouchersEnabled && isNoShow">
                            <div class="col-md-5">
                                <label>Payable en titres-restaurant <small>*</small></label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        type="checkbox"
                                        class="switch align-self-center is-rounded"
                                        :true-value="true"
                                        :false-value="false"
                                        v-model="product.payable_with_mealvouchers" />
                                    <label @click="product.payable_with_mealvouchers = !product.payable_with_mealvouchers"></label>
                                </div>
                                <ShowErrors class="d-block" :errors="errors" errorKey="payable_with_mealvouchers" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="row mb-3"
                v-if="product.single_type !== 'menu' && product.is_enabled_for_click_and_collect && config.is_click_and_collect_enabled">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row" style="cursor: pointer" @click="showCollectParams = !showCollectParams">
                            <div class="col-12">
                                <strong>Options de retrait</strong>
                            </div>
                        </div>
                        <template v-if="showCollectParams">
                            <div class="row mt-3 mb-2">
                                <div class="col-md-5">
                                    <label>Délai de préparation du produit <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="radio">
                                                <label class="container-box"
                                                    ><input type="radio" v-model="productPreparationTime" :value="false" />
                                                    <span class="checkmark"></span> Utiliser les paramètres par défaut
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 d-inline radio mt-1">
                                            <label class="container-box d-inline-block pt-0 mt-2" style="width: initial"
                                                ><input type="radio" v-model="productPreparationTime" :value="true" />
                                                <span class="checkmark"></span> Personnaliser
                                            </label>
                                            <template v-if="productPreparationTime">
                                                <input
                                                    type="number"
                                                    class="form-control d-inline-block ml-2 mr-2"
                                                    v-model="product.preparation_time"
                                                    style="width: 120px" />
                                                <select class="custom-select mb-0" style="width: initial" v-model="product.preparation_time_unit">
                                                    <option value="mins">Minutes</option>
                                                    <option value="hours">Heures</option>
                                                    <option value="days">Jours</option>
                                                </select>
                                            </template>
                                        </div>
                                        <ShowErrors class="col-12" :errors="errors" errorKey="preparation_time" />
                                        <ShowErrors class="col-12" :errors="errors" errorKey="preparation_time_unit" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3 mb-2">
                                <div class="col-md-5">
                                    <label>Dates de disponibilité du produit <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="radio">
                                                <label class="container-box"
                                                    ><input type="radio" v-model="productAvailabilityDate" :value="false" />
                                                    <span class="checkmark"></span> Illimitée
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 d-inline radio mt-1">
                                            <label class="container-box d-inline-block pt-0 mt-2" style="width: initial"
                                                ><input type="radio" v-model="productAvailabilityDate" :value="true" />
                                                <span class="checkmark"></span> Limitée
                                            </label>
                                            <template v-if="productAvailabilityDate">
                                                <div class="date-resa-cal">
                                                    du
                                                    <datepicker
                                                        format="dd/MM/yyyy"
                                                        calendar-class="positition-relative mt-2"
                                                        :disabled-dates="disabledDates"
                                                        input-class="w-auto d-inline-block"
                                                        :monday-first="true"
                                                        :language="fr"
                                                        v-model="product.available_from">
                                                    </datepicker>
                                                    &nbsp;au&nbsp;
                                                    <datepicker
                                                        format="dd/MM/yyyy"
                                                        calendar-class="positition-relative mt-2"
                                                        :disabled-dates="disabledDates"
                                                        input-class="w-auto d-inline-block"
                                                        :monday-first="true"
                                                        :language="fr"
                                                        v-model="product.available_to">
                                                    </datepicker>
                                                </div>
                                                <ShowErrors class="col-12" :errors="errors" errorKey="available_from" />
                                                <ShowErrors class="col-12" :errors="errors" errorKey="available_to" />
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-5">
                                    <label>Créneaux de disponibilité du produit <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="radio">
                                                <label class="container-box"
                                                    ><input type="radio" v-model="defaultSlots" :value="true" />
                                                    <span class="checkmark"></span> Utiliser les paramètres par défaut
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 d-inline radio mt-1">
                                            <label class="container-box d-inline-block pt-0 mt-2" style="width: initial"
                                                ><input type="radio" v-model="defaultSlots" :value="false" />
                                                <span class="checkmark"></span> Personnaliser
                                            </label>
                                        </div>
                                        <ShowErrors class="col-12" :errors="errors" errorKey="use_default_slots" />
                                    </div>
                                </div>
                            </div>
                            <template v-if="!defaultSlots">
                                <div class="row mt-3">
                                    <div class="offset-1 col-11 pt-2">
                                        <div v-for="day in allDays" :key="day">
                                            <div class="mb-2">
                                                <label class="container-box" style="margin-bottom: 0; width: 100%">
                                                    <input type="checkbox" v-model="active[day]" />
                                                    <span class="checkmark"></span> {{ dayLabel(day, true) }}
                                                </label>
                                            </div>
                                            <div class="setting-open" v-if="active[day]">
                                                <div class="setting-hours">
                                                    <span>
                                                        <div v-for="service in services[day]" :key="service.id">
                                                            <input
                                                                type="text"
                                                                class="form-control d-inline-block"
                                                                style="max-width: 60px"
                                                                :disabled="true"
                                                                :value="service.start_hour" />
                                                            <input
                                                                type="text"
                                                                class="form-control d-inline-block"
                                                                style="max-width: 60px"
                                                                :disabled="true"
                                                                :value="service.end_hour" />
                                                            <label
                                                                v-for="slot in service.slots"
                                                                :key="slot.id"
                                                                class="container-box ml-4"
                                                                style="width: initial">
                                                                <input type="checkbox" v-model="slot.enabled" :true-value="1" />
                                                                <span class="checkmark"></span>
                                                                {{ slot.hour.replace(":", "h").substr(0, 5) }}
                                                            </label>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row mb-3" v-if="product.single_type !== 'menu' && product.is_enabled_for_delivery && config.is_delivery_enabled">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row" style="cursor: pointer" @click="showDeliveryParams = !showDeliveryParams">
                            <div class="col-12">
                                <strong>Options de livraison</strong>
                            </div>
                        </div>
                        <template v-if="showDeliveryParams">
                            <div class="row mt-3 mb-2">
                                <div class="col-md-5">
                                    <label>Délai de préparation du produit <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="radio">
                                                <label class="container-box"
                                                    ><input type="radio" v-model="productDeliveryPreparationTime" :value="false" />
                                                    <span class="checkmark"></span> Utiliser les paramètres par défaut
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 d-inline radio mt-1">
                                            <label class="container-box d-inline-block pt-0 mt-2" style="width: initial"
                                                ><input type="radio" v-model="productDeliveryPreparationTime" :value="true" />
                                                <span class="checkmark"></span> Personnaliser
                                            </label>
                                            <template v-if="productDeliveryPreparationTime">
                                                <input
                                                    type="number"
                                                    class="form-control d-inline-block ml-2 mr-2"
                                                    v-model="product.delivery_preparation_time"
                                                    style="width: 120px" />
                                                <select
                                                    class="custom-select mb-0"
                                                    style="width: initial"
                                                    v-model="product.delivery_preparation_time_unit">
                                                    <option value="mins">Minutes</option>
                                                    <option value="hours">Heures</option>
                                                    <option value="days">Jours</option>
                                                </select>
                                            </template>
                                        </div>
                                        <ShowErrors class="col-12" :errors="errors" errorKey="delivery_preparation_time" />
                                        <ShowErrors class="col-12" :errors="errors" errorKey="delivery_preparation_time_unit" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3 mb-2">
                                <div class="col-md-5">
                                    <label>Dates de disponibilité du produit <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="radio">
                                                <label class="container-box"
                                                    ><input type="radio" v-model="productDeliveryAvailabilityDate" :value="false" />
                                                    <span class="checkmark"></span> Illimitée
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 d-inline radio mt-1">
                                            <label class="container-box d-inline-block pt-0 mt-2" style="width: initial"
                                                ><input type="radio" v-model="productDeliveryAvailabilityDate" :value="true" />
                                                <span class="checkmark"></span> Limitée
                                            </label>
                                            <template v-if="productDeliveryAvailabilityDate">
                                                <div class="date-resa-cal">
                                                    du
                                                    <datepicker
                                                        format="dd/MM/yyyy"
                                                        calendar-class="positition-relative mt-2"
                                                        :disabled-dates="disabledDates"
                                                        input-class="w-auto d-inline-block"
                                                        :monday-first="true"
                                                        :language="fr"
                                                        v-model="product.delivery_available_from">
                                                    </datepicker>
                                                    &nbsp;au&nbsp;
                                                    <datepicker
                                                        format="dd/MM/yyyy"
                                                        calendar-class="positition-relative mt-2"
                                                        :disabled-dates="disabledDates"
                                                        input-class="w-auto d-inline-block"
                                                        :monday-first="true"
                                                        :language="fr"
                                                        v-model="product.delivery_available_to">
                                                    </datepicker>
                                                </div>
                                                <ShowErrors class="col-12" :errors="errors" errorKey="delivery_available_from" />
                                                <ShowErrors class="col-12" :errors="errors" errorKey="delivery_available_to" />
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-5">
                                    <label>Créneaux de disponibilité du produit <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="radio">
                                                <label class="container-box"
                                                    ><input type="radio" v-model="deliveryDefaultSlots" :value="true" />
                                                    <span class="checkmark"></span> Utiliser les paramètres par défaut
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 d-inline radio mt-1">
                                            <label class="container-box d-inline-block pt-0 mt-2" style="width: initial"
                                                ><input type="radio" v-model="deliveryDefaultSlots" :value="false" />
                                                <span class="checkmark"></span> Personnaliser
                                            </label>
                                        </div>
                                        <ShowErrors class="col-12" :errors="errors" errorKey="use_default_delivery_slots" />
                                    </div>
                                </div>
                            </div>
                            <template v-if="!deliveryDefaultSlots">
                                <div class="row mt-3">
                                    <div class="offset-1 col-11 pt-2">
                                        <div v-for="day in allDays" :key="day">
                                            <div class="mb-2">
                                                <label class="container-box" style="margin-bottom: 0; width: 100%">
                                                    <input type="checkbox" v-model="deliveryActive[day]" />
                                                    <span class="checkmark"></span> {{ dayLabel(day, true) }}
                                                </label>
                                            </div>
                                            <div class="setting-open" v-if="deliveryActive[day]">
                                                <div class="setting-hours">
                                                    <span v-for="slot in deliverySlots[day]" :key="slot.id" class="d-block">
                                                        <label class="container-box ml-4" style="width: initial">
                                                            <input type="checkbox" v-model="slot.enabled" />
                                                            <span class="checkmark"></span>&nbsp;{{
                                                                slot.hour_start.substr(0, 5).replace(":", "h")
                                                            }}
                                                            - {{ slot.hour_end.substr(0, 5).replace(":", "h") }}
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>

            <template>
                <div class="row mb-3" v-for="(productComponent, index) in product.cc_product_components.data" :key="productComponent.id">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-12 pt-1 d-flex justify-content-between align-items-center">
                                    <div>
                                        <span class="d-block">{{ productComponent.name }}</span>
                                        <small class="text-muted" v-if="productComponent.is_mandatory">Choix obligatoire pour le client</small>
                                        <small class="text-muted" v-else>Optionnel</small>
                                    </div>
                                    <div>
                                        <button
                                            class="btn btn-sm btn-success btn-square"
                                            title="Modifier la réservation"
                                            @click="updateProductComponent(index, productComponent)"
                                            v-tooltip="getTooltip('Éditer')">
                                            <feather type="edit" />
                                        </button>
                                        <button
                                            class="btn btn-sm btn-danger btn-square"
                                            @click="deleteProductComponent(index)"
                                            v-tooltip="getTooltip('Supprimer')">
                                            <feather type="trash-2" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <table class="table table-sm table-striped border-bottom mt-2">
                                <thead class="border-bottom">
                                    <tr>
                                        <th>Nom</th>
                                        <th>Tarif</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(productOption, indexOption) in productComponent.cc_product_options.data" :key="`${indexOption}`">
                                        <td>{{ productOption.cc_product.name }}</td>
                                        <td>
                                            {{ productOption.include ? "Inclus" : formatCurrency(productOption.price) }}
                                        </td>
                                        <td>
                                            <button
                                                class="btn btn-sm btn-warning btn-square"
                                                @click="deleteProductComponentOption(productComponent, indexOption)"
                                                v-tooltip="getTooltip('Retirer')">
                                                <feather type="x" />
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <ShowErrors class="d-block" :errors="errors" :errorKey="`product_components.${index}.*`" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button v-if="product.type === 'menu'" @click="addProductComponent" class="btn btn-sm btn-success btn-circle mt-1">
                            <feather type="plus" />
                            Ajouter un composant de formule
                        </button>
                        <button
                            v-else-if="product.type === 'single' && product.cc_product_components.data.length === 0"
                            @click="addProductComponentExtras"
                            class="btn btn-sm btn-success btn-circle mt-1">
                            <feather type="plus" />
                            Ajouter des options
                        </button>
                    </div>
                </div>
            </template>

            <add-click-and-collect-add-formule-component-modal
                v-if="showAddClickAndCollectAddFormuleComponentModal"
                @close="showAddClickAndCollectAddFormuleComponentModal = false"
                @saved="productComponentSaved"
                :productComponent="productComponent" />
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
import ImageUploader from "../../components/forms/ImageUploaderComponent.vue";
import ShowErrors from "../errors/ShowErrors";
import addClickAndCollectAddFormuleComponentModal from "../../components/Modals/clickAndCollect/addClickAndCollectAddFormuleComponentModal";
import VueTimepicker from "vue2-timepicker";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import axios from "axios";
import moment from "moment";
import StockTypeEnum from "../../mixins/enums/click_and_collect/StockTypeEnum";

function defaultOption() {
    return {
        id: null,
        name: null,
        include: 1,
        price: null,
        tax_rate: null,
    };
}

function defaultComponent(is_extra_forced = false) {
    return {
        id: null,
        name: null,
        order: null,
        is_mandatory: 1,
        is_extra_forced,
        cc_product_options: {
            data: [],
        },
    };
}

export default {
    data() {
        return {
            fr,
            loading: 0,
            errors: null,
            productPreparationTime: this.product.preparation_time !== null,
            productDeliveryPreparationTime: this.product.delivery_preparation_time !== null,
            productAvailabilityDate: this.product.available_from !== null && this.product.available_to !== null,
            productDeliveryAvailabilityDate: this.product.delivery_available_from !== null && this.product.delivery_available_to !== null,
            showAddFormuleAddModal: false,
            showAddClickAndCollectAddFormuleComponentModal: false,
            product_stock_nolimit: this.product.stock === null,
            defaultSlots: this.product.use_default_slots ? true : false,
            deliveryDefaultSlots: this.product.use_default_delivery_slots ? true : false,
            option: null,
            type: null,
            custom_category_name: null,
            services: null,
            deliverySlots: null,
            products_categories: null,
            active: {
                0: false,
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
            },
            deliveryActive: {
                0: false,
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
            },
            product_type: false,
            productComponent: null,
            productComponentIndex: null,
            showCollectParams: true,
            showDeliveryParams: true,
            allDays: [1, 2, 3, 4, 5, 6, 0],
        };
    },
    mixins: [StockTypeEnum],
    props: {
        product: {
            type: Object,
            required: true,
        },
        duplicate: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            required: true,
        },
    },
    computed: {
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        restaurant() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurantId);
        },
        hasMealVouchersEnabled() {
            if (!this.restaurant) return false;
            return this.restaurant.edenred_enabled;
        },
        isTvaDisabled() {
            return this.$store.getters["restaurants/isTvaDisabled"](this.restaurantId);
        },
        disabledDates() {
            return {};
        },
        imgPostUri() {
            let uri = `/api/click_and_collect/${this.restaurantId}/products/img`;

            if (this.product.id !== null) {
                uri += `/${this.product.id}`;
            }

            return uri;
        },
    },
    methods: {
        isAllergenActive(allergen) {
            return this.product.allergens.includes(allergen);
        },
        toggleAllergen(allergen) {
            if (this.product.allergens.includes(allergen)) this.product.allergens.splice(this.product.allergens.indexOf(allergen), 1);
            else this.product.allergens.push(allergen);
        },
        dateObjToIsoDate(date) {
            if (!date) return null;
            return moment(date).format("Y-MM-DD");
        },
        getNextOrder() {
            let order = 0;
            this.product.cc_product_components.data.forEach((component) => {
                if (component.order > order) order = component.order;
            });
            return parseInt(order) + 1;
        },
        addProductComponent() {
            this.productComponent = defaultComponent();
            this.productComponent.order = this.getNextOrder();
            this.productComponentIndex = null;
            this.showAddClickAndCollectAddFormuleComponentModal = true;
        },
        addProductComponentExtras() {
            this.productComponent = defaultComponent(true);
            this.productComponent.name = "Suppléments";
            this.productComponent.order = 1;
            this.productComponent.is_mandatory = 0;
            this.productComponentIndex = null;
            this.showAddClickAndCollectAddFormuleComponentModal = true;
        },
        updateProductComponent(index, productComponent) {
            this.productComponent = _.cloneDeep(productComponent);
            this.productComponentIndex = index;
            this.showAddClickAndCollectAddFormuleComponentModal = true;
        },
        productComponentSaved() {
            this.showAddClickAndCollectAddFormuleComponentModal = false;
            if (this.productComponentIndex === null) this.product.cc_product_components.data.push(this.productComponent);
            else this.product.cc_product_components.data.splice(this.productComponentIndex, 1, this.productComponent);
            this.product.cc_product_components.data.sort((a, b) => a.order - b.order);
        },
        deleteProductComponent(index) {
            if (!confirm("Êtes vous sûr de vouloir supprimer ce composant de menu ?")) return;
            this.product.cc_product_components.data.splice(index, 1);
        },
        deleteProductComponentOption(productComponent, index) {
            productComponent.cc_product_options.data.splice(index, 1);
        },
        getFormattedData() {
            const data = _.cloneDeep(this.product);

            Object.assign(data, {
                unlimited_stock: this.product_stock_nolimit,
                use_default_preparation_time: !this.productPreparationTime,
                available_always: !this.productAvailabilityDate,
                delivery_use_default_preparation_time: !this.productDeliveryPreparationTime,
                delivery_available_always: !this.productDeliveryAvailabilityDate,
                custom_category_name: this.custom_category_name,
                img: data.img !== null ? data.img.path : null,
            });

            data.product_components = [];
            data.cc_product_components.data.forEach((productComponent) => {
                for (let i = 0; i < productComponent.cc_product_options.data.length; i++) {
                    if (productComponent.cc_product_options.data[i].price !== null && !isNaN(productComponent.cc_product_options.data[i].price))
                        productComponent.cc_product_options.data[i].price = productComponent.cc_product_options.data[i].price * 100;
                }
                data.product_components.push(productComponent);
            });

            data.use_default_slots = this.defaultSlots;
            data.use_default_delivery_slots = this.deliveryDefaultSlots;
            data.price = data.price === null ? null : data.price * 100;
            data.price_offer = data.price_offer === null ? null : data.price_offer * 100;
            data.available_from = this.dateObjToIsoDate(data.available_from);
            data.available_to = this.dateObjToIsoDate(data.available_to);
            data.delivery_available_from = this.dateObjToIsoDate(data.delivery_available_from);
            data.delivery_available_to = this.dateObjToIsoDate(data.delivery_available_to);
            data.offer_starts_on = data.offer_starts_on ? moment(data.offer_starts_on).format("Y-MM-DD") : null;
            data.offer_ends_on = data.offer_ends_on ? moment(data.offer_ends_on).format("Y-MM-DD") : null;

            if (!data.use_default_slots) {
                data.cc_services = this.services;
                data.services_active = this.active;
            }

            if (!data.use_default_delivery_slots) {
                data.cc_delivery_slots = [];
                Object.keys(this.deliverySlots).forEach((day) => {
                    if (this.deliveryActive[day]) {
                        this.deliverySlots[day].forEach((slot) => {
                            if (slot.enabled)
                                data.cc_delivery_slots.push({
                                    day,
                                    id: slot.id,
                                });
                        });
                    }
                });
            }

            return data;
        },
        save() {
            this.loading++;
            this.errors = null;

            if (this.product.id === null) {
                axios
                    .post(`/api/click_and_collect/${this.restaurantId}/products`, this.getFormattedData())
                    .then((response) => {
                        this.loading--;
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: response.data.message,
                        });
                        this.$emit("saved");
                    })
                    .catch((error) => {
                        this.loading--;
                        this.errors = error.response.data.errors;
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: error.response.data.message ? error.response.data.message : "Une erreur est survenue",
                        });
                    });
            } else {
                axios
                    .put(`/api/click_and_collect/${this.restaurantId}/products/${this.product.id}`, this.getFormattedData())
                    .then((response) => {
                        this.loading--;
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: response.data.message,
                        });
                        this.$emit("saved");
                    })
                    .catch((error) => {
                        this.loading--;
                        this.errors = error.response.data.errors;
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: error.response.data.message ? error.response.data.message : "Une erreur est survenue",
                        });
                    });
            }
        },
        fetchProduct() {
            this.loading++;

            return axios
                .get(`/api/click_and_collect/${this.restaurantId}/products/spec/${this.product.id}`)
                .then((response) => {
                    this.loading--;

                    _.assignIn(this.product, response.data);

                    if (this.duplicate) {
                        this.product.id = null;
                        this.product.img = null;
                    }

                    return true;
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: error.response.data.message ? error.response.data.message : "Une erreur est survenue",
                    });
                    return false;
                });
        },
        fetchProductsCategories() {
            this.loading++;

            axios
                .get(`/api/click_and_collect/${this.restaurantId}/products/categories`)
                .then((response) => {
                    this.loading--;
                    this.products_categories = response.data.data;
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: error.response.data.message ? error.response.data.message : "Une erreur est survenue",
                    });
                });
        },
        fetchAllServices() {
            this.loading++;

            axios
                .get(`/api/click_and_collect/${this.restaurantId}/products/services`)
                .then((response) => {
                    this.services = {};
                    this.allDays.forEach((day) => {
                        this.services[day] = response.data.cc_services.filter((service) => day === service.day);
                        if (this.product.id !== null || this.duplicate)
                            for (let j = 0; j < this.services[day].length; j++)
                                for (let i = 0; i < this.services[day][j].slots.length; i++)
                                    this.services[day][j].slots[i].enabled = this.product.cc_product_slots.data.some(
                                        (slot) => slot.cc_slot_id === this.services[day][j].slots[i].id
                                    )
                                        ? 1
                                        : false;
                        this.active[day] = this.services[day].some((service) => service.slots.some((slot) => slot.enabled));
                    });
                    this.deliverySlots = {};
                    this.allDays.forEach((day) => {
                        this.deliverySlots[day] = response.data.cc_delivery_slots.filter((s) => day === s.day);
                        for (let j = 0; j < this.deliverySlots[day].length; j++)
                            this.deliverySlots[day][j].enabled = this.product.cc_product_delivery_slots.data.some(
                                (s) => s.id === this.deliverySlots[day][j].id
                            );
                        this.deliveryActive[day] = this.deliverySlots[day].some((s) => s.enabled);
                    });
                    setTimeout(() => {
                        this.loading--;
                    }, 50);
                })
                .catch((error) => {
                    this.loading--;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
    },
    watch: {
        product_stock_nolimit(newVal) {
            if (newVal) this.product.stock = null;
        },
    },
    components: {
        LoaderComponent,
        ImageUploader,
        VueTimepicker,
        ShowErrors,
        Datepicker,
        addClickAndCollectAddFormuleComponentModal,
    },
    created() {
        this.fetchProductsCategories();
        if (this.product.id) {
            this.fetchProduct().then((result) => {
                if (result) this.fetchAllServices();
            });
        } else {
            this.fetchAllServices();
        }
    },
};
</script>
