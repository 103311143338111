<template>
    <div>
        <loader-component v-if="isLoading" />
        <div v-else class="row">
            <div v-if="users.length === 0" class="col-12">
                <span>Ce code promo n'est pas restreint à un certain nombre d'utilisateurs</span>
            </div>
            <div v-else class="col-12">
                <div v-for="user in users" :key="user.id" class="row my-2">
                    <div class="col-12">
                        {{ displayUser(user) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoaderComponent from "../../../LoaderComponent.vue";

export default {
    props: {
        promoCode: {
            type: Object,
            required: true,
        },
    },
    components: {
        LoaderComponent,
    },
    data() {
        return {
            isLoading: false,
            users: [],
        };
    },
    created() {
        this.getRestrictedUsers();
    },
    methods: {
        displayUser(user) {
            let fullName = "";

            if (user !== null) {
                fullName = `${user.firstname || ""} ${user.lastname || ""}`;
            }

            const separator = fullName.length > 0 ? " | " : "";

            return fullName.concat(separator, user.email || "");
        },
        getRestrictedUsers() {
            this.isLoading = true;
            this.httpGet(`/api/admin/promo_codes/${this.promoCode.id}/restricted-users`)
                .then((response) => {
                    if (response !== false) {
                        this.users = response.data;
                    }
                })
                .finally(() => (this.isLoading = false));
        },
    },
};
</script>
