var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("LoaderComponent")
    : _c("div", [
        _vm.error
          ? _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v("\n        " + _vm._s(_vm.error) + "\n    "),
            ])
          : _vm.command
          ? _c(
              "div",
              { staticClass: "div-profil" },
              [
                _c("div", { staticClass: "row m-0" }, [
                  _c("div", { staticClass: "col-md-7 mb-3" }, [
                    _c(
                      "div",
                      { staticClass: "border-light b-radius-20 p-4" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v("Numéro de commande"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 value" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.command.numero) +
                                "\n                        "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sepa" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v("Date de la commande"),
                          ]),
                          _vm._v(" "),
                          !_vm.isEditing
                            ? _c("div", { staticClass: "col-6 value" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm
                                        .moment(_vm.command.reservation_date)
                                        .format("LL")
                                    ) +
                                    "\n                            "
                                ),
                                _vm.command.collect_type !=
                                  _vm.COLLECT_TYPE_DISPATCH.value &&
                                _vm.command.status != _vm.COMMAND_CANCELED.value
                                  ? _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.getTooltip("Modifier"),
                                            expression:
                                              "getTooltip('Modifier')",
                                          },
                                        ],
                                        staticClass: "btn btn-sm",
                                        staticStyle: { float: "right" },
                                        attrs: {
                                          type: "button",
                                          disabled: !_vm.has_right_to_update,
                                          title:
                                            "Modifier la date de la commande",
                                        },
                                        on: {
                                          click: _vm.editCommandDateAndSlot,
                                        },
                                      },
                                      [
                                        _c("feather", {
                                          staticStyle: { color: "#666666" },
                                          attrs: { type: "edit" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _c(
                                "div",
                                { staticClass: "col-6 date-resa-cal" },
                                [
                                  _c("datepicker", {
                                    attrs: {
                                      tabindex: "1",
                                      "monday-first": true,
                                      format: _vm.customDateFormater,
                                      disabledDates: _vm.disabledDates,
                                      language: _vm.fr,
                                    },
                                    model: {
                                      value: _vm.commandDateEditComputed,
                                      callback: function ($$v) {
                                        _vm.commandDateEditComputed = $$v
                                      },
                                      expression: "commandDateEditComputed",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]),
                        _vm._v(" "),
                        _vm.command.collect_type !==
                        _vm.COLLECT_TYPE_DISPATCH.value
                          ? [
                              _c("div", { staticClass: "sepa" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v("Créneau"),
                                ]),
                                _vm._v(" "),
                                !_vm.isEditing
                                  ? _c(
                                      "div",
                                      { staticClass: "col-6 value" },
                                      [
                                        _vm.command.collect_type ===
                                        _vm.COLLECT_TYPE_DELIVERY.value
                                          ? _c("feather", {
                                              staticClass: "mr-1 text-success",
                                              attrs: { type: "truck" },
                                            })
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.slot_hour_computed) +
                                            "\n                            "
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("div", { staticClass: "col-6 value" }, [
                                      _vm.slots
                                        ? _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.edition.slot,
                                                  expression: "edition.slot",
                                                },
                                              ],
                                              staticClass: "custom-select",
                                              staticStyle: {
                                                "margin-top": "-2px !important",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.edition,
                                                    "slot",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            _vm._l(_vm.slots, function (slot) {
                                              return _c(
                                                "option",
                                                {
                                                  key: slot.id,
                                                  domProps: { value: slot },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.command
                                                          .collect_type ===
                                                          "collect"
                                                          ? _vm.formatHour(
                                                              slot.hour
                                                            )
                                                          : `${_vm.formatHour(
                                                              slot.hour_start
                                                            )} - ${_vm.formatHour(
                                                              slot.hour_end
                                                            )}`
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              )
                                            }),
                                            0
                                          )
                                        : _vm._e(),
                                    ]),
                                _vm._v(" "),
                                _vm.isEditing
                                  ? _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-between",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-circle btn-success",
                                              on: {
                                                click: function ($event) {
                                                  _vm.isEditing = false
                                                },
                                              },
                                            },
                                            [_vm._v("Annuler")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-circle btn-success",
                                              on: { click: _vm.putEdit },
                                            },
                                            [_vm._v("Enregistrer")]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.command.collect_type ===
                        _vm.COLLECT_TYPE_DELIVERY.value
                          ? [
                              _c("div", { staticClass: "sepa" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v("Livraison"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 value" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.deliveryAmount) +
                                      "\n                            "
                                  ),
                                ]),
                              ]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.command.collect_type ===
                        _vm.COLLECT_TYPE_DISPATCH.value
                          ? [
                              _c("div", { staticClass: "sepa" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v("Expédition"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 value" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.command.dispatch_shipping_method_name
                                    ) +
                                      " - " +
                                      _vm._s(_vm.dispatchFees)
                                  ),
                                ]),
                              ]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "sepa" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v("Statut"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-6 value" },
                            [
                              _c("ClickAndCollectStatusTag", {
                                attrs: {
                                  restaurant_id: _vm.restaurant_id,
                                  command: _vm.command,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sepa" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v("Montant"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 value" }, [
                            _vm._v(
                              _vm._s(
                                _vm.formatCurrency(
                                  _vm.command.total_amount / 100
                                )
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sepa" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v("Code promotionnel"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 value" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.command.promo_code
                                    ? _vm.getPromoCodeDisplayable(
                                        _vm.command.promo_code
                                      )
                                    : "--"
                                ) +
                                "\n                        "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.hasGiftVouchers
                          ? [
                              _c("div", { staticClass: "sepa" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6" }, [
                                  _vm._v("Chèque cadeau"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 value" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.command.gv_sale
                                          ? _vm.getGvSaleDisplayable(
                                              _vm.command.gv_sale
                                            )
                                          : "--"
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                              ]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "sepa" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v(
                              "Moyen de paiement" +
                                _vm._s(
                                  _vm.paidAndRefundedCommandPayments.length > 1
                                    ? "s"
                                    : ""
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-6 value" },
                            [
                              _vm.paidAndRefundedCommandPayments.length > 0
                                ? _vm._l(
                                    _vm.paidAndRefundedCommandPayments,
                                    function (payment) {
                                      return _c(
                                        "span",
                                        {
                                          key: payment.id,
                                          staticClass: "d-block",
                                        },
                                        [
                                          payment.is_online
                                            ? [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.getOnlinePaymentMethodLabel(
                                                        payment.online_payment_method
                                                      )
                                                    ) +
                                                    "\n                                        "
                                                ),
                                                _vm.getOnlinePaymentMethodImg(
                                                  payment.online_payment_method
                                                )
                                                  ? _c("img", {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value:
                                                            _vm.getTooltip(
                                                              "Paiement en ligne"
                                                            ),
                                                          expression:
                                                            "getTooltip('Paiement en ligne')",
                                                        },
                                                      ],
                                                      staticStyle: {
                                                        width: "14px",
                                                        "margin-left": "2px",
                                                        "margin-bottom": "2px",
                                                      },
                                                      attrs: {
                                                        src: `/images/${_vm.getOnlinePaymentMethodImg(
                                                          payment.online_payment_method
                                                        )}`,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            : [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.getPaymentMethodLabel(
                                                        payment.method
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ],
                                        ],
                                        2
                                      )
                                    }
                                  )
                                : _c("span", [_vm._v("Non renseigné")]),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sepa" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _vm._v("Statut du paiement"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 value" }, [
                            _c(
                              "span",
                              {
                                class: _vm.getPaymentStatusClass(
                                  _vm.command.payment_status
                                ),
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.getPaymentStatusLabel(
                                      _vm.command.payment_status
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sepa" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mb-1" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _vm._v("Commentaire Public"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("blockquote", [
                              _c("p", { staticClass: "value" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$_.isEmpty(_vm.command.comment)
                                        ? "Aucun commentaire"
                                        : _vm.command.comment
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.hasPartialRefund || _vm.canRefundPartially
                          ? [
                              _c("div", { staticClass: "sepa" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _vm.canRefundPartially
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-between",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-success btn-circle",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showRefundProductsModal = true
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Remboursement\n                                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.canCancelCommand
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-danger btn-circle",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.cancelCommand()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        Annuler la commande\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm.hasPartialRefund
                                      ? _c("ShowRefundedProducts", {
                                          attrs: { command: _vm.command },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          : _vm.canCancelCommand
                          ? [
                              _c("div", { staticClass: "sepa" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-danger btn-circle",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancelCommand()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Annuler la commande\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-5 mb-3 d-flex flex-column" },
                    [
                      _vm.isClientLoading
                        ? _c("loader-component")
                        : [
                            _vm.command && _vm.command.client !== null
                              ? _c("client-card", {
                                  attrs: {
                                    client: _vm.command.client,
                                    "module-enum":
                                      _vm.MODULE_TYPE_CLICK_AND_COLLECT,
                                    "is-change-in-progress":
                                      _vm.isAssociatingInProgress,
                                    "hide-client-edit-btn": _vm.hide_client_btn,
                                  },
                                  on: {
                                    "cancel-in-progress": _vm.emptyClient,
                                    "update-in-progress":
                                      _vm.associateClientBack,
                                  },
                                })
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-center align-items-center h-100",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-success btn-circle",
                                        on: {
                                          click: function ($event) {
                                            _vm.canOpenSearchClientModal = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.$t(
                                                "labels.form.actions.associate.client"
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                          ],
                      _vm._v(" "),
                      _vm.command.collect_type ===
                        _vm.COLLECT_TYPE_DELIVERY.value ||
                      _vm.command.dispatch_type === _vm.DISPATCH_TYPE_HOME.value
                        ? _c("div", { staticClass: "p-4 bg-grad text-white" }, [
                            _c("div", { staticClass: "row m-0" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c("label", { staticClass: "mb-1" }, [
                                  _c("strong", [
                                    _vm._v("Adresse de livraison"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.clientFullName)
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "d-block" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.command
                                            .delivery_address_displayable
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-circle b-block mt-3",
                                        staticStyle: {
                                          "background-color": "white",
                                        },
                                        attrs: {
                                          href:
                                            "https://www.google.com/maps/place/" +
                                            _vm.command
                                              .delivery_address_displayable,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("S'y rendre")]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row m-0" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "border-light b-radius-20 p-4 mb-3" },
                      [
                        _c(
                          "ul",
                          { staticClass: "pl-0 mb-0" },
                          [
                            _vm._l(
                              _vm.command.command_products.data,
                              function (commandProduct, index) {
                                return [
                                  _c(
                                    "li",
                                    { key: `${_vm.command.id}-${index}` },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(commandProduct.quantity) +
                                          " x " +
                                          _vm._s(commandProduct.product.name) +
                                          "\n                                "
                                      ),
                                      commandProduct.comment &&
                                      !_vm.$_.isEmpty(commandProduct.comment)
                                        ? _c(
                                            "small",
                                            { staticClass: "text-warning" },
                                            [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(
                                                    commandProduct.comment
                                                  ) +
                                                  ")"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      commandProduct.command_product_option.data
                                        .length > 0
                                        ? _c(
                                            "ul",
                                            { staticClass: "mb-0" },
                                            _vm._l(
                                              commandProduct
                                                .command_product_option.data,
                                              function (
                                                commandProductOption,
                                                index2
                                              ) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: `${_vm.command.id}-${index}-${index2}`,
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          commandProductOption
                                                            .product_option
                                                            .cc_product.name
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                    commandProductOption.quantity >
                                                    1
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " (x" +
                                                              _vm._s(
                                                                commandProductOption.quantity
                                                              ) +
                                                              ")"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.command.private_comment
                  ? _c("div", { staticClass: "row m-0" }, [
                      _c("div", { staticClass: "col-12 mb-3" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4" },
                          [
                            _c("label", { staticClass: "mb-1" }, [
                              _c("strong", [_vm._v("Commentaire Privé")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.command.private_comment) +
                                    "\n                        "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row m-0" }, [
                  _c("div", { staticClass: "col-12 mb-3" }, [
                    _c(
                      "div",
                      { staticClass: "border-light b-radius-20 p-4" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                _vm.showLog = !_vm.showLog
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "col-12" }, [
                              _c("strong", { staticClass: "mb-0" }, [
                                _vm._v("Historique des modifications"),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.showLog
                          ? [
                              _c("ShowCommandLogsList", {
                                attrs: { command: _vm.command },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.commandTypeLabel
                  ? _c("div", { staticClass: "row m-0" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 text-right pr-4" },
                        [
                          _c("div", [
                            _c("i", [
                              _vm._v(
                                "Commande " +
                                  _vm._s(_vm.commandTypeLabel) +
                                  " le " +
                                  _vm._s(
                                    _vm
                                      .moment(_vm.command.created_at)
                                      .format("LLL")
                                  )
                              ),
                              _vm.commandUserDisplayable
                                ? _c("span", [
                                    _vm._v(
                                      " par " +
                                        _vm._s(_vm.commandUserDisplayable)
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.command.mail_logs.data,
                            function (mailLog) {
                              return _c("div", { key: mailLog.id }, [
                                mailLog.permanent_failed_at || !mailLog.sent_at
                                  ? _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [
                                        _vm._v(
                                          "Une erreur est survenue lors de l'envoi du mail de " +
                                            _vm._s(
                                              _vm.getCommandMailLogLabel(
                                                mailLog.type
                                              )
                                            )
                                        ),
                                      ]
                                    )
                                  : mailLog.sent_at && !mailLog.delivered_at
                                  ? _c(
                                      "small",
                                      { staticClass: "text-warning" },
                                      [
                                        _vm._v(
                                          "Le mail de " +
                                            _vm._s(
                                              _vm.getCommandMailLogLabel(
                                                mailLog.type
                                              )
                                            ) +
                                            " est en cours d'envoi"
                                        ),
                                      ]
                                    )
                                  : mailLog.delivered_at
                                  ? _c("small", [
                                      _vm._v(
                                        "Mail de " +
                                          _vm._s(
                                            _vm.getCommandMailLogLabel(
                                              mailLog.type
                                            )
                                          ) +
                                          " envoyé le " +
                                          _vm._s(
                                            _vm
                                              .moment(mailLog.delivered_at)
                                              .format("DD/MM/Y[ à ]HH:mm")
                                          )
                                      ),
                                      mailLog.opened_at
                                        ? _c("span", [_vm._v(" (ouvert)")])
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ])
                            }
                          ),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showRefundProductsModal
                  ? _c("RefundProductsModal", {
                      attrs: { command_id: _vm.command_id },
                      on: {
                        refunded: _vm.productsRefunded,
                        close: function ($event) {
                          _vm.showRefundProductsModal = false
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showAskKeepFeesModal
                  ? _c("AskKeepFeesModal", {
                      attrs: { command: _vm.command },
                      on: {
                        validate: _vm.cancelCommand,
                        close: function ($event) {
                          _vm.showAskKeepFeesModal = false
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.canOpenSearchClientModal
                  ? _c("wrapper-search-client-modal", {
                      on: {
                        close: function ($event) {
                          _vm.canOpenSearchClientModal = false
                        },
                        "selected-client": _vm.associateClient,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }