export const getters = {
    ga_enabled(state) {
        return state.ga_enabled;
    },
    website_enabled(state) {
        return state.website_enabled;
    },
    unsaved_changes(state) {
        return state.unsaved_changes;
    },
    all_settings(state) {
        return state.all_settings;
    },
    is_publishing(state) {
        return state.is_publishing;
    },
};

export default getters;
