<template>
    <div class="row mb-3">
        <div class="col-12">
            <loader-component v-if="nbLoading > 0" />
            <div v-else-if="stats.main" class="border-light b-radius-20 p-4">
                <div class="row pointer" @click="showPercentOfEntitySold = !showPercentOfEntitySold">
                    <strong class="col-10 d-flex align-items-center">
                        {{ $tl(`labels.booking.stats.charts.${entity.translationKeyPercent}.title`) }}
                    </strong>
                    <div class="d-flex justify-content-end align-items-center lead-switch col-2">
                        <button
                            class="btn btn-sm btn-square"
                            type="button"
                            :class="pinPercentOfEntitySold ? 'btn-success' : 'btn-secondary'"
                            @click.stop.prevent="pinPercentOfEntitySold = !pinPercentOfEntitySold">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="18px" height="18px" fill="currentColor">
                                <path
                                    d="M300.8 203.9L290.7 128H328c13.2 0 24-10.8 24-24V24c0-13.2-10.8-24-24-24H56C42.8 0 32 10.8 32 24v80c0 13.2 10.8 24 24 24h37.3l-10.1 75.9C34.9 231.5 0 278.4 0 335.2c0 8.8 7.2 16 16 16h160V472c0 .7 .1 1.3 .2 1.9l8 32c2 8 13.5 8.1 15.5 0l8-32c.2-.6 .2-1.3 .2-1.9V351.2h160c8.8 0 16-7.2 16-16 .1-56.8-34.8-103.7-83.1-131.3zM33.3 319.2c6.8-42.9 39.6-76.4 79.5-94.5L128 96H64V32h256v64h-64l15.3 128.8c40 18.2 72.7 51.8 79.5 94.5H33.3z" />
                            </svg>
                        </button>
                    </div>
                </div>

                <div v-if="showPercentOfEntitySold || pinPercentOfEntitySold" class="row mt-3">
                    <div
                        class="pl-1 pl-xl-3 pr-3 pr-xl-1 pb-2 pb-xl-0"
                        :class="filters.hasComparison && stats.comparison ? 'col-xl-6 col-12' : 'col-xl-12'">
                        <div
                            class="p-1 d-flex flex-column align-items-center h-100"
                            :class="filters.hasComparison && stats.comparison ? 'border-light' : 'col-xl-12'">
                            <strong class="text-success py-3">{{ labelPeriodMain }}</strong>
                            <div v-if="noEntitiesSold" class="h-100 d-flex align-items-center">
                                <strong>
                                    {{ $tl("labels.booking.stats.noDataOnPeriod") }}
                                </strong>
                            </div>
                            <VChart
                                v-else
                                class="chart"
                                :option="{
                                    title: percentOfEntitySold.title,
                                    series: seriesDataPie,
                                    legend: chartLegend.legend,
                                    tooltip: tooltipPercentOfEntitySold,
                                    grid: grid,
                                }">
                            </VChart>
                        </div>
                    </div>
                    <div v-if="stats.comparison && filters.hasComparison" class="col-xl-6 col-12 pl-1 pr-3">
                        <div class="border-light b-radius-20 p-1 d-flex flex-column align-items-center h-100">
                            <strong class="text-success py-3">{{ labelPeriodComparison }}</strong>
                            <div v-if="noEntitiesSoldComparison" class="h-100 d-flex align-items-center">
                                <strong>
                                    {{ $tl("labels.booking.stats.noDataOnPeriod") }}
                                </strong>
                            </div>
                            <VChart
                                v-else
                                class="chart"
                                :option="{
                                    title: percentOfEntitySold.title,
                                    series: seriesDataPieComparison,
                                    legend: chartLegend.legend,
                                    tooltip: tooltipPercentOfEntitySold,
                                    grid: grid,
                                }">
                            </VChart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VChart from "vue-echarts";
import StatsUtils from "../../mixins/helpers/booking/StatsUtils.js";
import LoaderComponent from "../LoaderComponent.vue";
import { ALL_ENTITIES } from "../../utils/stats/revenueEntities.js";

/**
 * Generic component for :
 *  - App\Models\Menu
 *  - App\Models\MenuOption
 *  - App\Models\GeneralOption
 */
export default {
    name: "PercentOfEntitySoldComponent",
    props: {
        grid: {
            required: true,
            type: Object,
        },
        filters: {
            required: true,
            type: Object,
        },
        entity: {
            required: true,
            type: Object,
            validator(entity) {
                return ALL_ENTITIES.includes(entity);
            },
        },
    },
    mixins: [StatsUtils],
    data() {
        return {
            nbLoading: 0,
            showPercentOfEntitySold: false,
            percentOfEntitySold: {
                title: {
                    text: this.$tl(`labels.booking.stats.charts.${this.entity.translationKeyPercent}.title`),
                    left: "center",
                    textStyle: {
                        fontWeight: "normal",
                    },
                },
            },
            chartLegend: {
                legend: {
                    show: true,
                    bottom: "bottom",
                    formatter: (value) => {
                        let entity = this.stats.main.find((item) => this.getItemKey(item) === value);
                        if (entity === undefined) {
                            entity = this.stats.comparison.find((item) => this.getItemKey(item) === value);
                        }
                        return entity !== undefined ? entity.name : value;
                    },
                },
            },
            predefinedColors: ["#5490ff", "#8c54ff", "#bc43e1"],
            dynamicColorRange: ["#d343bc", "#e1438c", "#ff5490", "#ff8c54", "#ffc154", "#8cff54", "#54ff8c", "#43e1bc", "#43bce1"],
            stats: {
                main: null,
                comparison: null,
            },
        };
    },
    computed: {
        pinPercentOfEntitySold: {
            get() {
                return this.$store.getters[`userPreferences/${this.entity.pinKeyPercent}`];
            },
            set(newVal) {
                this.$store.dispatch(`userPreferences/set${this.capitalize(this.entity.pinKeyPercent)}`, newVal);
            },
        },
        seriesDataPie() {
            const colors = this.getColorsForChart(this.seriesDataPieSoldEntities);
            return [
                {
                    type: "pie",
                    radius: ["40%", "70%"],
                    avoidLabelOverlap: false,
                    label: {
                        show: true,
                        position: "inside",
                        formatter: function (params) {
                            return params.value === 0 ? "" : `${params.value}%`;
                        },
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 40,
                            fontWeight: "bold",
                        },
                    },
                    labelLine: {
                        show: true,
                    },
                    data: this.seriesDataPieSoldEntities.map((item, index) => ({
                        ...item,
                        itemStyle: {
                            color: colors[index],
                        },
                    })),
                },
            ];
        },
        seriesDataPieComparison() {
            const colors = this.getColorsForChart(this.seriesDataPieSoldEntitiesComparison);
            return [
                {
                    type: "pie",
                    radius: ["40%", "70%"],
                    avoidLabelOverlap: false,
                    label: {
                        show: true,
                        position: "inside",
                        formatter: function (params) {
                            return params.value === 0 ? "" : `${params.value}%`;
                        },
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 40,
                            fontWeight: "bold",
                        },
                    },
                    labelLine: {
                        show: true,
                    },
                    data: this.seriesDataPieSoldEntitiesComparison.map((item, index) => ({
                        ...item,
                        itemStyle: {
                            color: colors[index],
                        },
                    })),
                },
            ];
        },
        seriesDataPieSoldEntities() {
            return this.percentOfEntitySoldForPieChart(this.stats.main).map((entity) => ({
                id: this.getItemKey(entity),
                name: entity.id,
                value: entity.percent,
                nb: entity.nb,
            }));
        },
        seriesDataPieSoldEntitiesComparison() {
            return this.percentOfEntitySoldForPieChart(this.stats.comparison).map((entity) => ({
                id: this.getItemKey(entity),
                name: entity.id,
                value: entity.percent,
                nb: entity.nb,
            }));
        },
        tooltipPercentOfEntitySold() {
            return {
                trigger: "item",
                formatter: (params) => {
                    const so = this.$tl("labels.booking.stats.charts.tooltip.so");
                    let entity = this.stats.main.find((entity) => this.getItemKey(entity) === params.data.name);

                    if (entity === undefined) {
                        entity = this.stats.comparison.find((entity) => this.getItemKey(entity) === params.data.name);
                    }
                    const entityName = entity !== undefined ? entity.name : params.data.name;
                    return `<b>${entityName}</b><br/>${params.data.nb}, ${so} ${params.percent}%<br/>`;
                },
            };
        },
        noEntitiesSold() {
            return this.stats.main?.every((entity) => !entity.count_sold);
        },
        noEntitiesSoldComparison() {
            return this.stats.comparison?.every((entity) => !entity.count_sold);
        },
    },
    methods: {
        fetchStats(abortControllerSignal = null, forComparison = false) {
            const filters = this.getFiltersQueryParams(forComparison);

            return this.httpGet(`/api/stats/${this.entity.endpoint}?${filters}`, {
                config: { signal: abortControllerSignal },
                handleReject: false,
            }).then((response) => {
                if (response !== false) {
                    this.stats[forComparison ? "comparison" : "main"] = response.data;
                }
            });
        },
        percentOfEntitySoldForPieChart(stats) {
            let data = [];
            let nb_all = 0;
            stats.forEach((entity) => {
                nb_all += entity.count_sold ?? 0;
            });
            stats.forEach((entity) => {
                data.push({
                    id: this.getItemKey(entity),
                    name: entity.name,
                    nb: entity.count_sold ?? 0,
                    percent: Number(this.formatPercent((entity.count_sold * 100) / nb_all)),
                });
            });
            return data;
        },
        formatPercent(percent) {
            return Math.round(percent);
        },
        generateDynamicColors(count) {
            const dynamicColors = [];
            const baseColors = this.dynamicColorRange;
            for (let i = 0; i < count; i++) {
                dynamicColors.push(baseColors[i % baseColors.length]);
            }
            return dynamicColors;
        },
        getColorsForChart(data) {
            const staticColors = this.predefinedColors.slice(0, data.length);
            const remainingColors =
                data.length > this.predefinedColors.length ? this.generateDynamicColors(data.length - this.predefinedColors.length) : [];
            return [...staticColors, ...remainingColors];
        },
    },
    components: { LoaderComponent, VChart },
    watch: {
        pinPercentOfEntitySold(newVal) {
            if (!newVal) {
                this.showPercentOfEntitySold = false;
            }
        },
    },
};
</script>

<style scoped>
button.btn:disabled {
    cursor: default;
}

.chart {
    height: 450px;
}
</style>
