var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row m-0 justify-content-center" },
      [
        _vm.loading
          ? _c("loader-component")
          : [
              _vm.error
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.error) +
                        "\n            "
                    ),
                  ])
                : _vm._l(_vm.modules, function (module, index) {
                    return [
                      _vm.displayModule(module)
                        ? _c("module-product", {
                            key: index,
                            staticClass: "col-lg-3 col-md-3 mb-3",
                            attrs: {
                              onlyOneModule:
                                _vm.modules.length > 1 && _vm.onlyOneModule,
                              max: _vm.max,
                              allModules: _vm.modules,
                              module: module,
                              plan: _vm.planComputed,
                              "disable-payment": _vm.disablePayment,
                              "disable-plan": _vm.disablePlan,
                            },
                            on: {
                              count_option_dispatch: function ($event) {
                                module.count_option_dispatch = $event
                              },
                              "input-plan": function ($event) {
                                _vm.planComputed = $event
                              },
                            },
                            model: {
                              value: module.count,
                              callback: function ($$v) {
                                _vm.$set(module, "count", $$v)
                              },
                              expression: "module.count",
                            },
                          })
                        : _vm._e(),
                    ]
                  }),
            ],
      ],
      2
    ),
    _vm._v(" "),
    !_vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          !_vm.disablePayment && _vm.plan == "yearly"
            ? _c("div", [
                _vm._v(_vm._s(_vm.$tl("labels.form.licences.discountYearly"))),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.disableTraining
            ? _c("div", { staticClass: "mt-2" }, [
                _c(
                  "label",
                  {
                    staticClass: "container-box w-auto",
                    staticStyle: { "padding-left": "20px !important" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.training_,
                          expression: "training_",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.training_)
                          ? _vm._i(_vm.training_, null) > -1
                          : _vm.training_,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.training_,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.training_ = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.training_ = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.training_ = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkmark" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-2" }, [
                      _vm._v(
                        _vm._s(_vm.$tl("labels.form.licences.askTraining"))
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }