export default {
    common: {
        services: "You need to create games first to change this setting.",
    },
    booking: {
        services: {
            enableBack: "To re-enable a game, go to your {dashboard}.",
            cantDelete: "You cannot delete this game because future reservations are associated with it",
            hasFutureReservations: "This game refers to several upcoming reservations.",
            changeSeatingPlan: [
                "If you confirm the change of seating plan on this game, future reservations will no longer be linked to the seats of the old seating plan, and the seating preferences will not be kept.",
                "The algorithm will attempt to move your reservations to the next reservation period.",
                "If, however, reservations cannot be placed, taking reservations will be temporarily suspended.",
                "We strongly advise you to check your upcoming games.",
            ],
        },
    },
};
