export default {
    data() {
        return {
            DELIVERY_AREA_TYPE_CITIES: {
                label: "Sélection par villes",
                value: "cities",
            },
            DELIVERY_AREA_TYPE_CROW_FLIES: {
                label: "Distance à vol d'oiseau",
                value: "crow_flies",
            },
            DELIVERY_AREA_TYPE_DRAWN: {
                label: "Dessin sur la carte",
                value: "drawn",
            },
        };
    },
    methods: {
        getDeliveryAreaTypeLabel(value) {
            const found = this.ALL_DELIVERY_AREA_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_DELIVERY_AREA_TYPES() {
            return [this.DELIVERY_AREA_TYPE_CITIES, this.DELIVERY_AREA_TYPE_CROW_FLIES, this.DELIVERY_AREA_TYPE_DRAWN];
        },
    },
};
