<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else-if="campaign">
            <div class="div-profil">
                <div class="row">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-md-5">Nom de la campagne</div>
                                <div class="col-md-7 value">{{ campaign.name }}</div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-md-5">Type de campagne</div>
                                <div class="col-md-7 value">
                                    {{ getCampaignTypeLabel(campaign.type) }}
                                </div>
                            </div>
                            <template v-if="campaign.type === CAMPAIGN_TYPE_SMS.value">
                                <div class="sepa"></div>
                                <div class="row">
                                    <div class="col-md-5">Expéditeur</div>
                                    <div class="col-md-7 value">{{ campaign.sms_expeditor }}</div>
                                </div>
                            </template>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-md-5">Filtres</div>
                                <div class="col-md-7 value" v-html="filters"></div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-md-5">Statut</div>
                                <div class="col-md-7 value">
                                    <span :class="{ 'text-muted': !campaign.sent_at && !campaign.will_be_send_at }">{{
                                        campaign.sent_at
                                            ? "Envoyée"
                                            : campaign.will_be_send_at
                                            ? "Programmée"
                                            : "Brouillon"
                                    }}</span>
                                </div>
                            </div>
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-md-5">Date et heure d'envoi</div>
                                <div class="col-md-7 value">
                                    {{
                                        campaign.sent_at
                                            ? moment(campaign.sent_at).format("LLL")
                                            : campaign.will_be_send_at
                                            ? moment(campaign.will_be_send_at).format("LLL")
                                            : "-"
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row" v-if="campaign.type === CAMPAIGN_TYPE_EMAIL.value">
                                <div class="col-5">Objet</div>
                                <div class="col-7 value">{{ campaign.mail_object }}</div>
                            </div>
                            <div class="sepa" v-if="campaign.type === CAMPAIGN_TYPE_EMAIL.value"></div>
                            <div class="row">
                                <div class="col-12">Message</div>
                                <div class="col-12 mt-2">
                                    <small class="value" v-html="campaign_content"></small>
                                </div>
                            </div>
                            <div
                                class="sepa"
                                v-if="campaign.img_path && campaign.type === CAMPAIGN_TYPE_EMAIL.value"></div>
                            <div
                                class="row"
                                v-if="campaign.img_path && campaign.type === CAMPAIGN_TYPE_EMAIL.value"
                                style="max-height: 20vh">
                                <div class="col-5">Image</div>
                                <div class="col-7">
                                    <img
                                        style="max-width: 100%; max-height: 20vh"
                                        :src="campaign.img_path"
                                        alt="Image de campagne"
                                        tile="Image de la campagne" />
                                </div>
                            </div>
                            <div
                                class="sepa"
                                v-if="campaign.img_link && campaign.type === CAMPAIGN_TYPE_EMAIL.value"></div>
                            <div class="row" v-if="campaign.img_link && campaign.type === CAMPAIGN_TYPE_EMAIL.value">
                                <div class="col-5">Lien de l'image</div>
                                <div class="col-7">
                                    <a
                                        target="_blank"
                                        :href="
                                            campaign.img_link.startsWith('http')
                                                ? campaign.img_link
                                                : 'http://' + campaign.img_link
                                        "
                                        class="value"
                                        >{{ campaign.img_link }}</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3" v-if="campaign.sent_at">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row m-0 border-bottom">
                                <table class="table table-striped table-sm">
                                    <thead class="border-bottom">
                                        <th>Utilisateurs</th>
                                        <th>Envoyés</th>
                                        <th>Ouvertures</th>
                                        <th>Clics</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ campaign.send_to_owners_only ? "Compte admin" : "Tous" }}</td>
                                            <td>{{ allDeliveries.length }}</td>
                                            <td>
                                                <div v-if="campaign.type === CAMPAIGN_TYPE_SMS.value">Indisponible</div>
                                                <div v-else-if="getOpenedDeliveries(allDeliveries).length === 0">0</div>
                                                <div v-else>
                                                    {{ getOpenedDeliveries(allDeliveries).length }} ({{
                                                        formatPercent(
                                                            (getOpenedDeliveries(allDeliveries).length /
                                                                allDeliveries.length) *
                                                                100
                                                        )
                                                    }}%)
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="campaign.type === CAMPAIGN_TYPE_SMS.value">Indisponible</div>
                                                <div v-else-if="getClickedDeliveries(allDeliveries).length === 0">
                                                    0
                                                </div>
                                                <div v-else>
                                                    {{ getClickedDeliveries(allDeliveries).length }} ({{
                                                        formatPercent(
                                                            (getClickedDeliveries(allDeliveries).length /
                                                                getOpenedDeliveries(allDeliveries).length) *
                                                                100
                                                        )
                                                    }}%)
                                                </div>
                                            </td>
                                        </tr>
                                        <template v-if="!campaign.send_to_owners_only">
                                            <tr v-for="role in ROLES_FOR_ADMIN_CAMPAIGN" :key="role.value">
                                                <td>{{ role.label }}</td>
                                                <td>{{ getDeliveriesForRole(role.value).length }}</td>
                                                <td>
                                                    <div v-if="campaign.type === CAMPAIGN_TYPE_SMS.value">
                                                        Indisponible
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            getOpenedDeliveries(getDeliveriesForRole(role.value))
                                                                .length === 0
                                                        ">
                                                        0
                                                    </div>
                                                    <div v-else>
                                                        {{
                                                            getOpenedDeliveries(getDeliveriesForRole(role.value)).length
                                                        }}
                                                        ({{
                                                            formatPercent(
                                                                (getOpenedDeliveries(getDeliveriesForRole(role.value))
                                                                    .length /
                                                                    getDeliveriesForRole(role.value).length) *
                                                                    100
                                                            )
                                                        }}%)
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="campaign.type === CAMPAIGN_TYPE_SMS.value">
                                                        Indisponible
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            getClickedDeliveries(getDeliveriesForRole(role.value))
                                                                .length === 0
                                                        ">
                                                        0
                                                    </div>
                                                    <div v-else>
                                                        {{
                                                            getClickedDeliveries(getDeliveriesForRole(role.value))
                                                                .length
                                                        }}
                                                        ({{
                                                            formatPercent(
                                                                (getClickedDeliveries(getDeliveriesForRole(role.value))
                                                                    .length /
                                                                    getOpenedDeliveries(
                                                                        getDeliveriesForRole(role.value)
                                                                    ).length) *
                                                                    100
                                                            )
                                                        }}%)
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../LoaderComponent";
import CampaignTypesEnum from "../../../mixins/enums/campaigns/CampaignTypesEnum";
import StripePlanTypesEnum from "../../../mixins/enums/StripePlanTypesEnum";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum";
import RolesEnum from "../../../mixins/enums/RolesEnum";
import moment from "moment";

export default {
    name: "ShowCampaign",
    data() {
        return {
            loading: false,
            campaign: null,
            clients: [],
            moment,
        };
    },
    mixins: [CampaignTypesEnum, StripePlanTypesEnum, ModuleTypesEnum, RolesEnum],
    props: {
        campaign_id: {
            required: true,
        },
    },
    computed: {
        allDeliveries() {
            return this.campaign.deliveries.data;
        },
        campaign_content() {
            return this.campaign.content.replace(/\n{2,}/g, "<br><br>").replace(/\n/g, "<br>");
        },
        targets() {
            var targets = [];
            if (this.campaign.targets === "*") return "Tous mes clients";
            this.campaign.targets.forEach((target) => {
                if (this.clients[target] > 0) targets.push(this.getTargetLabel(target));
            });
            return targets.join(", ");
        },
        filters() {
            var filters = [];
            if (this.campaign.send_to_owners_only) filters.push("Comptes admin uniquement");
            if (this.campaign.send_to_active_owners_only) filters.push("Clients actifs uniquement");
            if (this.campaign.send_to_trials_only) filters.push("Clients en période d'essai uniquement");
            if (this.campaign.send_to_plan)
                filters.push("Type de renouvellement : " + this.getStripePlanTypeLabel(this.campaign.send_to_plan));
            else filters.push("Type de renouvellement : Tous");
            if (this.campaign.has_modules.length > 0) {
                const formattedModules = this.campaign.has_modules.map((m) => this.getModuleTypeLabel(m)).join(", ");
                filters.push("Clients qui ont au moins un module : " + formattedModules);
            }
            if (this.campaign.doesnt_have_modules.length > 0) {
                const formattedModules = this.campaign.doesnt_have_modules
                    .map((m) => this.getModuleTypeLabel(m))
                    .join(", ");
                filters.push("Clients qui n'ont pas un module : " + formattedModules);
            }
            if (this.campaign.type === this.CAMPAIGN_TYPE_SMS.value)
                filters.push(
                    this.campaign.send_to_foreign_numbers
                        ? "Numéros français et étrangers"
                        : "Numéro français uniquement"
                );

            return filters.length > 1 ? filters.join("<br />") : "Aucun";
        },
    },
    methods: {
        getOpenedDeliveries(deliveries) {
            return deliveries.filter((d) => d.opened);
        },
        getClickedDeliveries(deliveries) {
            return deliveries.filter((d) => d.clicked);
        },
        getDeliveriesForRole(role) {
            return this.campaign.deliveries.data.filter((delivery) => {
                if (!delivery.user) return false;
                return delivery.user.role == role;
            });
        },
        formatPercent(percent) {
            if ((percent * 100) % 100 === 0) return percent;
            if ((percent * 100) % 10 === 0) return percent.toFixed(1);
            return percent.toFixed(2);
        },
        fetchData() {
            this.loading = true;
            this.campaign = null;

            axios
                .get(`/api/campaigns/admin/campaigns/${this.campaign_id}?include=deliveries.user`)
                .then((response) => {
                    this.campaign = response.data.campaign;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
    },
    components: {
        LoaderComponent,
    },
    created() {
        this.fetchData();
    },
};
</script>
