<template>
    <span class="text-success" v-tooltip="getTooltip($tl('labels.clients.isHotel'))">
        <svg stroke-width="0" fill="currentColor" viewBox="0 0 52 52" v-bind="{ width, height }" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M48,34a.88.88,0,0,0,0-.28L43,16.83V8.42A2.43,2.43,0,0,0,40.58,6H11.42A2.43,2.43,0,0,0,9,8.42v8.41L4,33.72A.88.88,0,0,0,4,34H4v3.91A4,4,0,0,0,7.84,42H11v3a1,1,0,0,0,2,0V42H39v3a1,1,0,0,0,2,0V42h3.16A4,4,0,0,0,48,37.91V34ZM11.42,8H40.58a.42.42,0,0,1,.42.42V15H39V13a2,2,0,0,0-2-2H29a2,2,0,0,0-2,2v2H25V13a2,2,0,0,0-2-2H15a2,2,0,0,0-2,2v2H11V8.42A.42.42,0,0,1,11.42,8ZM37,17H29V13h8ZM23,17H15V13h8ZM11,17h2a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2h2a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2h2l4.69,16H6.34ZM44.16,40H7.84A2,2,0,0,1,6,37.91V35H46v2.91A2,2,0,0,1,44.16,40Z"></path>
        </svg>
    </span>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: "20px",
        },
        height: {
            type: String,
            default: "20px",
        },
    },
};
</script>
