export default {
    data() {
        return {
            ACTION_RIGHT_CREATE: {
                value: "create",
                label: "Création",
            },
            ACTION_RIGHT_DELETE: {
                value: "delete",
                label: "Suppression",
            },
        };
    },
    methods: {
        getActionRightLabel(value) {
            const found = this.ALL_ACTION_RIGHT_TYPES.find((w) => w.value === value);

            return found !== undefined ? found.label : value;
        },
    },
    computed: {
        ALL_ACTION_RIGHT_TYPES() {
            return [this.ACTION_RIGHT_CREATE, this.ACTION_RIGHT_DELETE];
        },
    },
};
