<template>
    <LoaderComponent v-if="loading" />
    <div v-else>
        <div v-if="error" class="alert alert-danger">
            {{ error }}
        </div>
        <div v-else-if="reservation" class="div-profil">
            <div v-if="reservation.immutable" class="p-4">
                <i>{{ $tl("infos.booking.reservations.immutable", undefined, { theme: themeUppercased }) }}</i>
            </div>
            <div class="row m-0">
                <div class="col-md-7 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-6">
                                {{ $tl("labels.booking.reservations.date") }}
                            </div>
                            <div class="col-6 value">
                                {{ displayDate(reservation.reservation_datetime) }}
                            </div>
                        </div>
                        <div class="sepa"></div>
                        <div class="row">
                            <div class="col-6">
                                {{ $tl("labels.booking.reservations.hour") }}
                            </div>
                            <div class="col-6 value">
                                {{ displayDate(setHourOnDateTime(reservation.slot.hour_start), TIME_SIMPLE) }}
                            </div>
                        </div>
                        <div class="sepa" v-if="reservation.rotation_enabled"></div>
                        <div class="row" v-if="reservation.rotation_enabled">
                            <div class="col-6">
                                {{ $tl("labels.booking.reservations.duration") }}
                            </div>
                            <div class="col-6 value">
                                {{ reservation.duration.substr(0, 5) }}
                            </div>
                        </div>
                        <div class="sepa"></div>
                        <div class="row">
                            <div class="col-6">
                                {{ $tl("labels.booking.reservations.nbPers") }}
                            </div>
                            <div class="col-6 value">
                                {{ reservation.nb_pers }}
                            </div>
                        </div>
                        <div class="sepa"></div>
                        <div class="row">
                            <div class="col-6">
                                {{ $tl("labels.booking.reservations.nbChildren") }}
                            </div>
                            <div class="col-6 value">
                                {{ reservation.nb_children }}
                            </div>
                        </div>
                        <div class="sepa"></div>
                        <div class="row">
                            <div class="col-6">
                                {{ $tl("labels.booking.reservations.table", restaurant_id) }}
                            </div>
                            <div class="col-6 value">
                                <template v-if="reservation.tables.data.length > 0">
                                    <template v-for="(table, index) in reservation.tables.data">
                                        {{ table.name }}{{ index + 1 < reservation.tables.data.length ? " - " : "" }}
                                    </template>
                                </template>
                                <template v-else-if="reservation.num_table && reservation.num_table !== ''">
                                    {{ reservation.num_table }}
                                </template>
                                <template v-else>
                                    {{ $tl("labels.booking.reservations.noTable", restaurant_id) }}
                                </template>
                            </div>
                        </div>
                        <template v-if="isFeatRoomNumbersEnable || isHotelModeEnabled">
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">{{ $tl("labels.booking.reservations.roomNumber") }}</div>
                                <div class="col-6 value">
                                    <hotel-client-icon v-if="reservation.is_hotel_client" />
                                    {{ reservation.room_number }}
                                </div>
                            </div>
                        </template>
                        <template v-if="reservation.prefered_room_name">
                            <div class="sepa"></div>
                            <div class="row">
                                <div class="col-6">{{ $tl("labels.booking.reservations.roomPreference") }}</div>
                                <div class="col-6 value">{{ reservation.prefered_room_name }}</div>
                            </div>
                        </template>
                        <div class="sepa"></div>
                        <div class="row">
                            <div class="col-6">
                                {{ $tl("labels.booking.reservations.giftVoucher.title") }}
                            </div>
                            <div class="col-6">
                                <span class="value" v-if="$_.isEmpty(reservation.gift)">{{
                                    $tl("labels.booking.reservations.giftVoucher.empty")
                                }}</span>
                                <span class="value" v-if="reservation.gv_sale_id !== null">
                                    <a class="value" href="#" @click="showShowGiftVoucherModal = true">{{ reservation.gift }}</a>
                                </span>
                                <span class="value text-danger" v-if="reservation.gv_sale_id === null && reservation.gv_validity.valid === false">
                                    {{ reservation.gift }}<br />
                                    <small
                                        class="text-danger pointer"
                                        style="text-decoration: underline"
                                        @click="showReservation = reservation.gv_validity.reservation_id"
                                        >{{ $tl("labels.booking.reservations.giftVoucher.alreadyUsed") }}</small
                                    >
                                </span>
                                <span class="value" v-else-if="reservation.gv_sale_id === null && reservation.gift != '' && reservation.gift != null">
                                    {{ reservation.gift }}
                                </span>
                            </div>
                        </div>
                        <div class="sepa"></div>
                        <div class="row mb-1">
                            <div class="col-12">
                                {{ $tl("labels.booking.reservations.publicComment") }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <blockquote>
                                    <p v-if="$_.isEmpty(reservation.comment)" class="value">
                                        {{ $tl("labels.booking.reservations.noPublicComment") }}
                                    </p>
                                    <p v-else class="value" v-html="formattedComment(reservation.comment)"></p>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 mb-3">
                    <LoaderComponent v-if="isClientLoading" />
                    <template v-else>
                        <client-card
                            v-if="hasClient"
                            :client="reservation.client"
                            :module-enum="MODULE_TYPE_BOOKING"
                            :is-change-in-progress="isAssociatingInProgress"
                            @cancel-in-progress="emptyClient"
                            @update-in-progress="associateClientBack" />
                        <div v-else class="d-flex justify-content-center align-items-center h-100">
                            <button
                                :disabled="reservation.immutable"
                                class="btn btn-sm btn-success btn-circle"
                                @click="canOpenSearchClientModal = true">
                                {{ $t("labels.form.actions.associate.client") }}
                            </button>
                        </div>
                    </template>
                </div>
            </div>

            <div class="row m-0">
                <div class="col-12 mb-3" v-if="reservation.restaurant_comment && reservation.restaurant_comment !== ''">
                    <div class="border-light b-radius-20 p-4">
                        <label class="mb-1">
                            <strong>{{ $tl("labels.booking.reservations.privateComment") }}</strong>
                        </label>
                        <div class="row">
                            <div class="col-12" v-html="formattedComment(reservation.restaurant_comment)"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row m-0">
                <div class="col-12 mb-3" v-if="reservation.menus && reservation.menus.data && reservation.menus.data.length > 0">
                    <div class="border-light b-radius-20 p-4">
                        <div class="mb-2" v-for="m in reservation.menus.data">
                            <div class="row text-left">
                                <div class="col-7">
                                    <label class="mb-0">{{ m.name }} - {{ formatCurrency(m.price, getRestaurantCurrency(restaurant_id_)) }}</label>
                                    <small class="d-block text-muted">
                                        {{ m.description }}
                                    </small>
                                </div>
                                <div class="col-5">
                                    {{ m.nb }}
                                </div>
                            </div>
                            <div class="row text-left" v-for="opt in m.options.data">
                                <div class="d-list-item col-6 offset-1">
                                    <label class="mb-0"
                                        >{{ opt.name }} - {{ formatCurrency(opt.price, getRestaurantCurrency(restaurant_id_)) }}</label
                                    >
                                    <small class="d-block text-muted">
                                        {{ opt.description }}
                                    </small>
                                </div>
                                <div class="col-5">
                                    {{ opt.nb }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row m-0">
                <div
                    class="col-12 mb-3"
                    v-if="reservation.general_options && reservation.general_options.data && reservation.general_options.data.length > 0">
                    <div class="border-light b-radius-20 p-4">
                        <div v-for="o in reservation.general_options.data">
                            <div class="row text-left">
                                <div class="col-7">
                                    <label class="mb-0">{{ o.name }} - {{ formatCurrency(o.price, getRestaurantCurrency(restaurant_id_)) }}</label>
                                    <small class="d-block text-muted">
                                        {{ o.description }}
                                    </small>
                                </div>
                                <div class="col-5">
                                    {{ o.nb }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row m-0">
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <template v-if="reservation.payment">
                            <div v-if="reservation.payment.option_bank === OPTION_BANK_PAYMENT.value">
                                {{ $tl("labels.booking.reservations.payment.showResa.title") }}
                                <div v-if="reservation.payment.status === PAYMENT_STATUS_CAPTURED.value">
                                    {{
                                        $t("labels.booking.reservations.payment.showResa.captured", {
                                            amount: formatCurrency(reservation.payment.amount_captured / 100),
                                        })
                                    }}
                                </div>
                                <div v-else-if="reservation.payment.status === PAYMENT_STATUS_CAPTURABLE.value">
                                    {{
                                        $t("labels.booking.reservations.payment.showResa.capturable", {
                                            amount: formatCurrency(reservation.payment.stripe_intent_amount / 100),
                                        })
                                    }}
                                </div>
                                <div v-else-if="reservation.payment.status === PAYMENT_STATUS_REFUNDED.value">
                                    {{
                                        $t("labels.booking.reservations.payment.showResa.refunded", {
                                            amount: formatCurrency(reservation.payment.stripe_intent_amount / 100),
                                            date: displayDate(reservation.payment.refunded_at),
                                        })
                                    }}
                                </div>
                                <div v-else-if="reservation.payment.status === PAYMENT_STATUS_PARTIALLY_REFUNDED.value">
                                    {{
                                        $t("labels.booking.reservations.payment.showResa.refundedPartially", {
                                            total_amount: formatCurrency(reservation.payment.stripe_intent_amount / 100),
                                            amount_refunded: formatCurrency(reservation.payment.amount_refunded / 100),
                                            date: displayDate(reservation.payment.refunded_at),
                                        })
                                    }}
                                </div>
                                <div v-else-if="reservation.payment.status === PAYMENT_STATUS_CANCELED.value">
                                    {{ $tl("labels.booking.reservations.payment.showResa.canceled") }}
                                </div>
                                <div v-else-if="reservation.payment.status === PAYMENT_STATUS_PENDING.value">
                                    {{
                                        $t("labels.booking.reservations.payment.showResa.pending", {
                                            amount: formatCurrency(reservation.payment.stripe_intent_amount / 100),
                                        })
                                    }}
                                </div>
                                <div v-else-if="reservation.payment.status === PAYMENT_STATUS_EXPIRED.value">
                                    {{ $tl("labels.booking.reservations.payment.showResa.expired") }}
                                </div>
                            </div>
                            <div v-else-if="reservation.payment.option_bank === OPTION_BANK_FOOTPRINT.value">
                                {{ $tl("labels.booking.reservations.footprint.showResa.title") }}
                                <div v-if="reservation.status === 'canceled' || reservation.status === 'refused'">
                                    {{ $tl("labels.booking.reservations.footprint.showResa.noOne") }}
                                </div>
                                <div v-else-if="reservation.payment.status === PAYMENT_STATUS_PENDING.value">
                                    {{ $tl("labels.booking.reservations.footprint.showResa.pending") }}
                                </div>
                                <div
                                    v-else-if="
                                        reservation.payment.status === PAYMENT_STATUS_CAPTURABLE.value ||
                                        reservation.payment.status === PAYMENT_STATUS_CAPTURED.value
                                    ">
                                    <div v-if="reservation.status === 'noshow'">
                                        {{
                                            $t("labels.booking.reservations.footprint.showResa.captured", {
                                                amount: formatCurrency(reservation.payment.amount_captured / 100),
                                            })
                                        }}
                                    </div>
                                    <div v-else-if="reservation.status === 'over'">
                                        {{ $tl("labels.booking.reservations.footprint.showResa.over") }}
                                    </div>
                                    <div v-else>
                                        {{
                                            $tl("labels.booking.reservations.footprint.showResa.capturable", restaurant_id, {
                                                amount: formatCurrency(reservation.amount_if_noshow / 100),
                                            })
                                        }}
                                    </div>
                                </div>
                                <div v-else-if="reservation.payment.status === PAYMENT_STATUS_EXPIRED.value">
                                    {{ $tl("labels.booking.reservations.footprint.showResa.expired") }}
                                </div>
                            </div>
                        </template>
                        <div v-else>
                            {{ $tl("labels.booking.reservations.noFootprintNorPayment") }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row m-0">
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row" style="cursor: pointer" @click="showLog = !showLog">
                            <div class="col-12">
                                <strong class="mb-0">{{ $tl("labels.booking.reservations.logs.title") }}</strong>
                            </div>
                        </div>
                        <template v-if="showLog">
                            <ShowReservationLogsList :reservation="reservation" />
                        </template>
                    </div>
                </div>
            </div>

            <div class="row m-0">
                <div class="col-12 text-right pr-4">
                    <div>
                        <small>{{
                            getReservationIssuerLabel(reservation.created_from, reservation.provider_name, reservation.created_at, reservation.user)
                        }}</small>
                    </div>
                    <ShowReservationMailLogsList :reservation="reservation" />
                </div>
            </div>
        </div>

        <show-gift-voucher-modal v-if="showShowGiftVoucherModal" :sale="reservation.gv_sale" @close="showShowGiftVoucherModal = false" />
        <show-reservation-modal
            v-if="showReservation !== false"
            :reservation_id="showReservation"
            :restaurant_id="restaurant_id_"
            @close="showReservation = false" />
        <wrapper-search-client-modal v-if="canOpenSearchClientModal" @close="canOpenSearchClientModal = false" @selected-client="associateClient" />
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import axios from "axios";
import _ from "lodash";
import showGiftVoucherModal from "../Modals/giftVouchers/showGiftVoucherModal.vue";
import PaymentStatusEnum from "../../mixins/enums/booking/PaymentStatusEnum.js";
import OptionBankEnum from "../../mixins/enums/booking/OptionBankEnum.js";
import ReservationIssuerEnum from "../../mixins/enums/booking/ReservationIssuerEnum.js";
import ClientCivilityEnum from "../../mixins/enums/ClientCivilityEnum.js";
import ShowReservationLogsList from "./logs/ShowReservationLogsList";
import ShowReservationMailLogsList from "./logs/ShowReservationMailLogsList";
import { DateTime } from "luxon";
import InlineClient from "../Default/Clients/inlineClient.vue";
import ClientCard from "../Default/Clients/clientCard.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";
import HotelClientIcon from "./hotelClientIcon.vue";
import ReservationIssuer from "../../mixins/enums/booking/ReservationIssuerEnum.js";
import WrapperSearchClientModal from "../Modals/clients/WrapperSearchClientModal.vue";

export default {
    name: "ShowReservation",
    data() {
        return {
            isClientLoading: false,
            loading: false,
            error: null,
            reservation: null,
            showReservation: false,
            DateTime,
            _,
            phone: {
                raw: null,
                isValid: null,
                country: null,
                value: null,
            },
            showShowGiftVoucherModal: false,
            showLog: false,
            canOpenSearchClientModal: false,
            isAssociatingInProgress: false,
        };
    },
    mixins: [PaymentStatusEnum, OptionBankEnum, ClientCivilityEnum, ReservationIssuerEnum, ModuleTypesEnum, ReservationIssuer],
    props: {
        reservation_id: {
            default: null,
        },
        restaurant_id: {
            default: null,
        },
    },
    computed: {
        restaurant_id_() {
            return this.restaurant_id || this.$route.params.restaurant_id;
        },
        isFeatRoomNumbersEnable() {
            const restaurant = this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id_);

            return restaurant ? restaurant.feat_room_numbers : false;
        },
        isHotelModeEnabled() {
            return this.$store.getters["widgets/getWidget"].hotel_mode;
        },
        hasClient() {
            return this.reservation !== null && this.reservation.client !== null;
        },
    },
    methods: {
        escapeHtml(text) {
            const map = {
                "&": "&amp;",
                "<": "&lt;",
                ">": "&gt;",
                '"': "&quot;",
                "'": "&#039;",
            };
            return text.replace(/[&<>"']/g, (m) => map[m]);
        },
        formattedComment(comment) {
            if (!comment) return "";
            return this.escapeHtml(comment).replace(
                /https?:\/\/[a-zA-Z0-9_\.\-\/\?=&%:]+/g,
                (link) => `<a href="${link.replace(/\.:*$/, "")}" target="_blank">${link}</a>`
            );
        },
        fetchData() {
            this.error = null;

            if (this.restaurant_id_ === null || this.restaurant_id_ === "" || this.reservation_id === null || this.reservation_id === "") {
                return;
            }

            this.loading = true;
            axios
                .get(
                    `/api/restaurants/${this.restaurant_id_}/reservations/${this.reservation_id}?include=payment,gv_validity,client.overResaCount,slot.service,menus.options,general_options,tables,user,gv_sale.gv_product,gv_sale.client,gv_sale.gv_sale_product_options.gv_products_option,rotation_enabled,logs.user,logs.slot,mail_logs`
                )
                .then((response) => {
                    this.$set(this, "reservation", response.data);
                    this.loading = false;
                })
                .catch((err) => {
                    this.error = this.getErrorMsgFromErrorResponse(err);
                    this.loading = false;
                });
        },
        setPhoneAndCountry(phoneObject) {
            if (phoneObject.number && phoneObject.isValid !== undefined && phoneObject.country) {
                this.phone.isValid = phoneObject.isValid;

                if (this.phone.isValid) {
                    this.phone.value = phoneObject.number;
                    this.phone.country = phoneObject.country.iso2;
                }
            } else {
                this.phone.isValid = false;
            }
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
        associateClientBack() {
            this.isClientLoading = true;
            this.httpPut(
                `/api/restaurants/${this.restaurant_id_}/reservations/${this.reservation_id}/clients/${this.reservation.client.id}/associate`
            )
                .then((response) => {
                    if (response !== false) {
                        this.$set(this.reservation.client, "overResaCount", response.data.overResaCount);
                        this.isAssociatingInProgress = false;
                    }
                })
                .finally(() => (this.isClientLoading = false));
        },
        emptyClient() {
            this.reservation.client = null;
            this.isAssociatingInProgress = false;
        },
        associateClient(event) {
            this.reservation.client = event;
            this.isAssociatingInProgress = true;
            this.canOpenSearchClientModal = false;
        },
    },
    updated() {
        if (this.$refs.vueTelInput) {
            this.$refs.vueTelInput.$el.classList.remove("vue-tel-input");
        }
    },
    watch: {
        restaurant_id_: {
            handler: function () {
                this.fetchData();
            },
        },
        reservation_id: {
            handler: function () {
                this.fetchData();
            },
        },
    },
    components: {
        showGiftVoucherModal,
        LoaderComponent,
        ShowReservationModal: () => import("../Modals/reservations/ShowReservationModal"),
        ShowReservationLogsList,
        ShowReservationMailLogsList,
        InlineClient,
        ClientCard,
        HotelClientIcon,
        WrapperSearchClientModal,
    },
    created() {
        this.fetchData();
    },
};
</script>
