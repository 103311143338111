<template>
    <div class="row">
        <div class="col-12">
            <div class="border-light b-radius-20 p-4">
                <div class="row">
                    <div class="col-12 pointer" @click="showIntegration = !showIntegration">
                        <strong>{{ $tl("infos.widget.googleLinkIntegration.title") }}</strong>
                    </div>
                </div>
                <div v-if="showIntegration" class="row mt-3">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-5">
                                <p>{{ $tl("infos.widget.subtitle") }}</p>
                            </div>
                            <div class="col-7">
                                <div>
                                    <strong>{{ $t("labels.stepX", { step: 1 }) }}{{ $tl("labels.colon") }}</strong>
                                    {{ $t("infos.widget.googleLinkIntegration.step1") }}
                                    <p class="mt-1 mb-3">{{ $t("infos.widget.googleLinkIntegration.content1") }}</p>
                                </div>

                                <div class="mb-3 d-flex flex-column">
                                    <p class="mt-1 mb-3">
                                        <strong>{{ $t("labels.stepX", { step: 2 }) }}{{ $tl("labels.colon") }}</strong>
                                        {{ $t("infos.widget.googleLinkIntegration.step2") }}
                                    </p>
                                    <img
                                        class="w-50 mb-3"
                                        src="/images/google_link_step2.png"
                                        :alt="$t('infos.widget.googleLinkIntegration.alt.step2')" />
                                </div>

                                <div class="mb-3 d-flex flex-column">
                                    <p class="mt-1 mb-3">
                                        <strong>{{ $t("labels.stepX", { step: 3 }) }}{{ $tl("labels.colon") }}</strong>
                                        {{ $t("infos.widget.googleLinkIntegration.step3") }}
                                    </p>
                                    <img
                                        class="w-50 mb-3"
                                        src="/images/google_link_step3.png"
                                        :alt="$t('infos.widget.googleLinkIntegration.alt.step3')" />
                                </div>

                                <div class="mb-3 d-flex flex-column">
                                    <p class="mt-1 mb-3">
                                        <strong>{{ $t("labels.stepX", { step: 4 }) }}{{ $tl("labels.colon") }}</strong>
                                        {{ $t("infos.widget.googleLinkIntegration.step4") }}
                                    </p>
                                    <img
                                        class="w-50 mb-2"
                                        src="/images/google_link_step4.png"
                                        :alt="$t('infos.widget.googleLinkIntegration.alt.step4')" />
                                    <p class="mt-1 mb-3">{{ $t("infos.widget.googleLinkIntegration.content2") }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showIntegration: false,
        };
    },
};
</script>
