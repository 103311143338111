var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("i", { class: _vm.iconClasses, style: _vm.iconStyle }, [
    _c(
      "svg",
      {
        staticClass: "feather feather__content",
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: "0 0 300 300",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      },
      [
        _c("path", {
          attrs: {
            d: "M146.03,181.6c9.55-17.71,18.83-35.57,28.5-53.95-8.49,0-16.39,.04-24.28-.03-1.67-.01-2.26,.91-2.83,2.21-6.13,13.98-13.81,27.29-18.46,42.04l-16.48-40.86c-.98-2.47-2.14-3.54-5-3.45-8.12,.24-16.26,.09-25.12,.09,9.52,18.67,18.56,36.65,27.88,54.49,1.76,3.36,1.56,5.74-.29,8.98-9.49,16.66-18.71,33.48-27.96,50.28-.75,1.37-2.12,2.59-1.71,4.66,7.3,0,14.6-.3,21.86,.11,4.21,.24,6.01-1.39,7.61-5.06,5.85-13.43,12.26-26.34,18.28-40.8l18.84,43.71c.47,1.04,1.03,1.99,2.52,1.98,8.52-.05,17.03-.03,26.21-.03-10.14-19.02-19.75-37.3-29.67-55.41-1.83-3.34-1.66-5.7,.1-8.96Zm-12.71,108.3c-2.04-2.07-4.06-2.86-6.94-2.85-29.88,.1-59.75,0-89.63,.13-3.6,.02-4.99-.52-4.98-4.68,.15-82.04,.14-164.07,0-246.11,0-3.85,1.06-4.53,4.65-4.51,34.19,.14,68.38,.16,102.57-.02,3.82-.02,4.56,1.01,4.53,4.64-.18,23.58,.01,47.16-.19,70.74-.03,3.81,.99,4.57,4.63,4.54,23.57-.18,47.14,0,70.7-.19,3.79-.03,4.58,.96,4.55,4.62-.18,23.75-.02,47.49-.17,71.24-.02,3.3,.88,4.14,4.1,4.05,7.96-.23,15.94-.25,23.9,0,3.32,.11,4.05-.91,4.04-4.11-.12-29.39-.11-58.79-.01-88.18,0-2.71-.81-4.58-2.71-6.48-29.87-29.76-59.69-59.57-89.44-89.46C160.66,1.02,158.46,0,155.19,0,114.52,.11,73.86,.06,33.19,.08,13.38,.08,0,13.56,0,33.53c0,84.03,0,168.06,0,252.09,0,19.89,13.45,33.38,33.26,33.39,41.16,.01,82.33,0,123.49,0h5.79c-10.43-10.35-19.91-19.64-29.22-29.1Zm126.24-2.7c-3.6,.05-4.7-.67-4.65-4.52,.24-18.25,.02-36.5,.19-54.75,.03-3.47-.45-4.92-4.45-4.72-7.77,.39-15.59,.33-23.37,.02-3.66-.15-4.25,1.1-4.22,4.44,.17,18.41-.03,36.83,.17,55.25,.04,3.55-.93,4.35-4.37,4.29-13.1-.22-26.2-.11-39.3-.04-1.14,0-2.62-.79-3.57,.87,19.61,19.6,39.2,39.17,58.75,58.79,1.23,1.23,3.03,2.07,3.33,4.09h1.99c-.1-1.19,.92-1.66,1.57-2.32,19.61-19.65,39.23-39.28,58.87-58.9,.66-.66,1.12-1.67,2.32-1.58v-1c-14.42,0-28.85-.13-43.27,.09Z",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }