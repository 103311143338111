<template>
    <div v-if="firstMailLog">
        <ShowReservationMailLog :mailLog="firstMailLog" />
        <div v-if="showMore">
            <ShowReservationMailLog v-for="log in otherMailLogs" :key="log.id" :mailLog="log" />
        </div>
        <small class="text-muted pointer" v-if="otherMailLogs.length > 0" @click="showMore = !showMore">
            {{ showMore ? $tl("labels.showLess") : $tl("labels.showMore") }}
        </small>
    </div>
</template>

<script>
import ShowReservationMailLog from "./ShowReservationMailLog";

export default {
    data() {
        return {
            showMore: false,
        };
    },
    props: {
        reservation: {
            type: Object,
            required: true,
        },
    },
    components: {
        ShowReservationMailLog,
    },
    computed: {
        firstMailLog() {
            if (this.reservation.mail_logs && this.reservation.mail_logs.data && this.reservation.mail_logs.data.length > 0)
                return this.reservation.mail_logs.data[0];
            return null;
        },
        otherMailLogs() {
            if (this.firstMailLog) return this.reservation.mail_logs.data.slice(1);
            return [];
        },
    },
};
</script>
