<template>
    <div>
        <drop-down
            :options="isCreatedBooking ? [] : currentStatuses"
            :use-icon="useIcon"
            use-label-for-icon
            :use-label="false"
            :selected-option="selectedOption"
            btn-classes-string="btn btn-sm btn-outline-secondary btn-circle booking-status"
            @option-changed="changeOption" />
    </div>
</template>
<script>
import DropDown from "../forms/DropDown.vue";
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum.js";
import PaymentStatusEnum from "../../mixins/enums/booking/PaymentStatusEnum.js";
import OptionBankEnum from "../../mixins/enums/booking/OptionBankEnum.js";
import ReservationIssuerEnum from "../../mixins/enums/booking/ReservationIssuerEnum.js";

export default {
    props: {
        reservation: {
            type: Object,
            required: true,
        },
        useIcon: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        DropDown,
    },
    mixins: [PaymentStatusEnum, OptionBankEnum, ReservationStatusEnum, ReservationIssuerEnum],
    data() {
        return {
            statuses: [],
            newStatus: "",
            now: this.getDateTime(),
            resaDateTime: this.getDateTime(this.reservation.reservation_datetime),
            selectedOption: {},
            isLoading: false,
        };
    },
    computed: {
        currentStatus() {
            return this.newStatus || this.reservation.status;
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        isValidPayment() {
            return !!(
                this.reservation.payment &&
                this.reservation.payment.option_bank === this.OPTION_BANK_PAYMENT.value &&
                this.reservation.payment.status === this.PAYMENT_STATUS_CAPTURED.value
            );
        },
        isCreatedBooking() {
            return this.reservation.status === this.RESERVATION_STATUS_CREATED.value;
        },
        currentStatuses() {
            return this.statuses.map((status) => {
                return {
                    ...status,
                    label: this.getReservationStatusLabel(status.value),
                };
            });
        },
    },
    created() {
        this.selectedOption = this.ALL_RESERVATION_STATUS.find((status) => this.reservation.status === status.value);
        this.defineStatuses();
    },
    methods: {
        canPushOverStatus() {
            if (this.nowDateTime > this.resaDateTime) {
                this.statuses.push(this.RESERVATION_STATUS_OVER);
            }
        },
        canPushNoshowStatus() {
            const dateTimeAllowCancellation = this.resaDateTime.minus({ minutes: this.cancellation });
            const resaDateTimePlus1Day = this.resaDateTime.plus({ days: 1 });

            if (
                dateTimeAllowCancellation < this.nowDateTime &&
                resaDateTimePlus1Day > this.nowDateTime &&
                this.current_tag_raw != this.RESERVATION_STATUS_OPTION.value
            ) {
                this.statuses.push(this.RESERVATION_STATUS_NOSHOW);
            }
        },
        addNoshowAndOverStatus() {
            const excludeStatus = [
                this.RESERVATION_STATUS_REFUSED.value,
                this.RESERVATION_STATUS_CANCELED.value,
                this.RESERVATION_STATUS_OVER.value,
                this.RESERVATION_STATUS_NOSHOW.value,
            ];
            if (!excludeStatus.includes(this.currentStatus)) {
                if (this.canPushOverStatus()) {
                    this.statuses.push(this.RESERVATION_STATUS_OVER);
                }
                if (this.canPushNoshowStatus) {
                    this.statuses.push(this.RESERVATION_STATUS_NOSHOW);
                }
            }
        },
        defineStatuses() {
            this.statuses = [];

            const statusesOption = {
                created: [this.RESERVATION_STATUS_VALIDATED, this.RESERVATION_STATUS_REFUSED, this.RESERVATION_STATUS_OPTION],
                option: [this.RESERVATION_STATUS_VALIDATED, this.RESERVATION_STATUS_CANCELED],
                validated: [
                    this.RESERVATION_STATUS_CANCELED,
                    this.RESERVATION_STATUS_OPTION,
                    this.RESERVATION_STATUS_CONFIRMED,
                    this.RESERVATION_STATUS_AT_TABLE,
                ],
                canceled:
                    this.reservation.created_from !== this.RESERVATION_ISSUER_GOOGLE_RESERVE.value
                        ? [this.RESERVATION_STATUS_VALIDATED, this.RESERVATION_STATUS_OPTION, this.RESERVATION_STATUS_AT_TABLE]
                        : [],
                refused:
                    this.reservation.created_from !== this.RESERVATION_ISSUER_GOOGLE_RESERVE.value
                        ? [this.RESERVATION_STATUS_VALIDATED, this.RESERVATION_STATUS_OPTION]
                        : [],
                confirmed: [this.RESERVATION_STATUS_CANCELED, this.RESERVATION_STATUS_AT_TABLE],
                at_table: this.reservation.confirmed_by_client ? [this.RESERVATION_STATUS_CONFIRMED] : [this.RESERVATION_STATUS_VALIDATED],
            };

            if (Object.keys(statusesOption).includes(this.currentStatus)) {
                this.statuses = statusesOption[this.currentStatus];
            }

            if (!this.getDateTime().hasSame(this.getDateTime(this.reservation.reservation_datetime), "day")) {
                const atTableIndex = this.statuses.findIndex((tag) => tag.value === this.RESERVATION_STATUS_AT_TABLE.value);
                if (atTableIndex !== -1) {
                    this.statuses.splice(atTableIndex, 1);
                }
            }

            this.addNoshowAndOverStatus();
        },
        emitDisplayModal(dataDisplayModal) {
            let retData = dataDisplayModal;

            if (retData.status === this.RESERVATION_STATUS_CANCELED.value) {
                retData = this.isValidPayment
                    ? { eventName: "displayRefundPartiallyModal", data: true }
                    : { eventName: "displayCancelModal", data: retData.status };
            }
            this.$emit(retData.eventName, retData.data);
        },
        findDisplayModalStatus(status) {
            const dataDisplayModal = [
                {
                    status: this.RESERVATION_STATUS_NOSHOW.value,
                    data: true,
                    eventName: "displayNoshowModal",
                },
                {
                    status: this.RESERVATION_STATUS_REFUSED.value,
                    data: status,
                    eventName: "displayCancelModal",
                },
                {
                    status: this.RESERVATION_STATUS_CANCELED.value,
                },
            ];
            return dataDisplayModal.find((data) => status === data.status);
        },
        changeOption(event) {
            this.isLoading = true;

            const dataDisplayModal = this.findDisplayModalStatus(event.value);

            if (typeof dataDisplayModal !== "undefined") {
                this.emitDisplayModal(dataDisplayModal);
                return;
            }
            this.httpPut(
                `/api/restaurants/${this.restaurantId}/reservations/${this.reservation.id}/status?status=${event.value}`,
                {},
                {
                    defaultMessage: this.$t("success.booking.reservations.updated"),
                    defaultErrorMessage: this.$t("errors.booking.reservations.notUpdated"),
                }
            ).finally(() => (this.isLoading = false));
        },
    },
};
</script>
