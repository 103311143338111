<template>
    <loader-component v-if="loadingRestaurants" />
    <div class="home-vue p-0 pb-5" v-else-if="user && user != '' && user.role !== 'kitchen'">
        <!-- BOARD MOBILE -->
        <div class="mobile-dashboard none-desk pb-4">
            <loader-component class="none-desk" v-if="loadingDatesRange" />
            <template v-else-if="restaurants.length > 0">
                <template v-for="restaurant in restaurants">
                    <div v-if="restaurants.map((r) => r.id).includes(restaurant.id)" :key="restaurant.id">
                        <div
                            class="mobile-dashboard-header text-white p-3 shadow-home d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pointer"
                            @click="collapseOtherRestaurants(restaurant)">
                            <div class="mobile-dashboard-restaurant-name">
                                <strong>{{ restaurant.name }}</strong>
                            </div>
                            <div>
                                <router-link
                                    class="mr-2 pl-2 pr-1"
                                    style="position: relative"
                                    :to="{
                                        name: 'booking.restaurants.reservationsCreated',
                                        params: { restaurant_id: restaurant.id },
                                    }">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="text-white css-i6dzq1">
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <polyline points="12 6 12 12 16 14"></polyline>
                                    </svg>
                                    <template v-for="restau in restaurants">
                                        <span
                                            v-if="restaurant.id === restau.id && restau.reservations_created !== 0"
                                            class="badge-dashboard-created badge badge-warning"
                                            :key="restau.id">
                                            {{ restau.reservations_created }}
                                        </span>
                                    </template>
                                </router-link>
                                <router-link
                                    class="pl-1 pr-2"
                                    :to="{
                                        name: 'booking.restaurants.reservations',
                                        params: { restaurant_id: restaurant.id },
                                    }">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="text-white css-i6dzq1">
                                        <line x1="8" y1="6" x2="21" y2="6"></line>
                                        <line x1="8" y1="12" x2="21" y2="12"></line>
                                        <line x1="8" y1="18" x2="21" y2="18"></line>
                                        <line x1="3" y1="6" x2="3.01" y2="6"></line>
                                        <line x1="3" y1="12" x2="3.01" y2="12"></line>
                                        <line x1="3" y1="18" x2="3.01" y2="18"></line>
                                    </svg>
                                </router-link>
                            </div>
                        </div>
                        <div class="d-none" :class="{ 'd-block': isBoardOpened(restaurant.id) }">
                            <LoaderComponent v-if="loading > 0 || loadingBoards[restaurant.id] === true" />
                            <template v-else>
                                <div
                                    class="mobile-dashboard-date bg-light d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pr-3 pb-2 pl-3">
                                    <button
                                        class="btn btn-sm btn-outline-secondary radius-btn-square"
                                        style="width: 29px; padding-left: 5px"
                                        v-on:click="decrementCurrentDayIndex()">
                                        <feather type="chevron-left" />
                                    </button>

                                    <span class="ml-1 mr-1 pt-1 text-center">
                                        <span style="text-transform: capitalize">{{ currentDayIndexDay }}</span
                                        ><br />
                                        {{ currentDayIndexDate }}
                                    </span>

                                    <button
                                        class="btn btn-sm btn-outline-secondary radius-btn-square"
                                        style="width: 29px; padding-left: 6px"
                                        v-on:click="incrementCurrentDayIndex()">
                                        <feather type="chevron-right" />
                                    </button>
                                </div>
                                <div class="mobile-dashboard-date-services" style="cursor: pointer" v-if="getBoard(restaurant.id)">
                                    <template
                                        v-if="getBoard(restaurant.id).services.length > 0 && $_.isEmpty(getBoard(restaurant.id).serviceClosureShown)">
                                        <template v-for="service in getBoard(restaurant.id).services">
                                            <div
                                                v-if="service.dates[currentDayIndex].is_open"
                                                :key="service.id"
                                                @click="showServiceClosures(getBoard(restaurant.id), service)"
                                                class="table-line p-3 text-capitalize d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                                                <div>
                                                    <strong>{{ getServiceCategoryLabel(service) }} </strong><small>{{ service.name }}</small>
                                                </div>
                                                <div style="font-weight: 600">
                                                    <feather
                                                        v-if="service.dates[currentDayIndex].is_full === 1"
                                                        type="minus-circle"
                                                        class="text-danger" />
                                                    <feather
                                                        v-if="service.dates[currentDayIndex].is_full === 0"
                                                        type="alert-circle"
                                                        class="text-warning" />
                                                    <span class="ml-2">
                                                        {{ service.dates[currentDayIndex].pax }}/<span
                                                            :class="{
                                                                'text-warning': service.dates[currentDayIndex].new_pax !== null,
                                                            }"
                                                            >{{
                                                                service.dates[currentDayIndex].new_pax !== null
                                                                    ? service.dates[currentDayIndex].new_pax
                                                                    : service.dates[currentDayIndex].max_pax
                                                            }}</span
                                                        >
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                    <div v-else-if="!$_.isEmpty(getBoard(restaurant.id).serviceClosureShown)">
                                        <div
                                            class="table-line p-2 text-capitalize d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-bottom-light">
                                            <div>
                                                <button
                                                    type="button"
                                                    class="btn pt-0 pr-1 pb-1 pl-1 d-inline-block"
                                                    @click="goServiceGoBack(getBoard(restaurant.id))">
                                                    <feather type="chevron-left" style="pointer-events: none" />
                                                </button>
                                                <strong class="d-inline-block pt-1">{{
                                                    getServiceCategoryLabel(getBoard(restaurant.id).serviceClosureShown.service)
                                                }}</strong>
                                                <small>{{ getBoard(restaurant.id).serviceClosureShown.service.name }}</small>
                                            </div>
                                            <div>
                                                <span
                                                    >{{ getBoard(restaurant.id).serviceClosureShown.service.pax }}/<span
                                                        :class="{
                                                            'text-warning': getBoard(restaurant.id).serviceClosureShown.service.new_pax !== null,
                                                        }"
                                                        >{{
                                                            getBoard(restaurant.id).serviceClosureShown.service.new_pax !== null
                                                                ? getBoard(restaurant.id).serviceClosureShown.service.new_pax
                                                                : getBoard(restaurant.id).serviceClosureShown.service.max_pax
                                                        }}</span
                                                    ></span
                                                >
                                                <div class="d-inline-block align-middle pl-2">
                                                    <input
                                                        type="checkbox"
                                                        class="switch align-self-center is-rounded"
                                                        :class="getStatusClass(getBoard(restaurant.id).serviceClosureShown.service.is_full)"
                                                        :checked="getBoard(restaurant.id).serviceClosureShown.service.is_full !== 1" />
                                                    <label
                                                        :class="getStatusClass(getBoard(restaurant.id).serviceClosureShown.service.is_full)"
                                                        @click.stop="updateServiceAvailability($event, getBoard(restaurant.id))"></label>
                                                    <!--@click="updateServiceAvailability"-->
                                                </div>
                                                <div class="d-inline-block">
                                                    <button class="btn pl-0" @click="showServicePaxes(getBoard(restaurant.id))">
                                                        <feather type="chevron-right" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="detail-box-slots-grid"
                                            v-if="
                                                !getBoard(restaurant.id).serviceClosureShown.slotSelected &&
                                                !getBoard(restaurant.id).serviceClosureShown.showServicePaxes
                                            ">
                                            <div
                                                class="w-auto text-center border-bottom-light detail-box-slot"
                                                v-for="(slot, slotIndex) in getBoard(restaurant.id).serviceClosureShown.service.slots"
                                                :key="slotIndex"
                                                @click="showSlotPaxes(getBoard(restaurant.id), slot)"
                                                style="cursor: pointer">
                                                {{ slot.hour_start }}
                                                <input
                                                    type="checkbox"
                                                    class="switch is-small is-rounded"
                                                    :class="getStatusClass(slot.is_full)"
                                                    :checked="slot.is_full !== 1" />
                                                <label
                                                    class="ml-2"
                                                    :class="getStatusClass(slot.is_full)"
                                                    @click.stop="updateSlotAvailability(slot, getBoard(restaurant.id))">
                                                </label>
                                                <feather type="chevron-right" style="pointer-events: none" />
                                            </div>
                                        </div>
                                        <div
                                            class="detail-box-slots-grid"
                                            tabindex="-1"
                                            v-if="getBoard(restaurant.id).serviceClosureShown.slotSelected">
                                            <span class="w-auto text-center border-bottom-light detail-box-slot" tabindex="-1">
                                                <strong>{{
                                                    getBoard(restaurant.id).serviceClosureShown.slotSelected
                                                        ? getBoard(restaurant.id).serviceClosureShown.slotSelected.hour_start
                                                        : ""
                                                }}</strong>
                                            </span>
                                            <div
                                                class="w-auto text-center border-bottom-light detail-box-slot"
                                                v-for="pax in getAllPaxes(getBoard(restaurant.id).serviceClosureShown.widget)"
                                                :key="pax"
                                                style="cursor: pointer"
                                                tabindex="-1">
                                                {{ capitalize($tl("labels.pax")) }} {{ pax }}
                                                <input
                                                    type="checkbox"
                                                    class="switch is-small is-rounded"
                                                    :class="{
                                                        closed:
                                                            getBoard(restaurant.id).serviceClosureShown.slotSelected &&
                                                            getBoard(restaurant.id).serviceClosureShown.slotSelected.paxes_full.includes(pax),
                                                        open:
                                                            getBoard(restaurant.id).serviceClosureShown.slotSelected &&
                                                            !getBoard(restaurant.id).serviceClosureShown.slotSelected.paxes_full.includes(pax),
                                                    }"
                                                    :checked="
                                                        getBoard(restaurant.id).serviceClosureShown.slotSelected &&
                                                        !getBoard(restaurant.id).serviceClosureShown.slotSelected.paxes_full.includes(pax)
                                                    " />
                                                <label
                                                    class="ml-2"
                                                    :class="{
                                                        closed:
                                                            getBoard(restaurant.id).serviceClosureShown.slotSelected &&
                                                            getBoard(restaurant.id).serviceClosureShown.slotSelected.paxes_full.includes(pax),
                                                        open:
                                                            getBoard(restaurant.id).serviceClosureShown.slotSelected &&
                                                            !getBoard(restaurant.id).serviceClosureShown.slotSelected.paxes_full.includes(pax),
                                                    }"
                                                    @click.stop="updateSlotPaxAvailability(getBoard(restaurant.id), pax)"
                                                    tabindex="-1"></label>
                                            </div>
                                        </div>
                                        <div
                                            class="detail-box-slots-grid"
                                            tabindex="-1"
                                            v-if="getBoard(restaurant.id).serviceClosureShown.showServicePaxes">
                                            <div
                                                class="w-auto text-center border-bottom-light detail-box-slot"
                                                v-for="pax in getAllPaxes(getBoard(restaurant.id).serviceClosureShown.widget)"
                                                :key="pax"
                                                style="cursor: pointer"
                                                tabindex="-1">
                                                {{ capitalize($tl("labels.pax")) }} {{ pax }}
                                                <input
                                                    type="checkbox"
                                                    class="switch is-small is-rounded"
                                                    :class="getStatusClass(getBoard(restaurant.id).serviceClosureShown.service.paxes_full[pax])"
                                                    :checked="getBoard(restaurant.id).serviceClosureShown.service.paxes_full[pax] === -1" />
                                                <label
                                                    class="ml-2"
                                                    :class="getStatusClass(getBoard(restaurant.id).serviceClosureShown.service.paxes_full[pax])"
                                                    @click.stop="updateServicePaxAvailability(getBoard(restaurant.id), pax)"
                                                    tabindex="-1"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </template>
        </div>

        <div class="row none-mobile m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">{{ $tl("labels.routes.booking.dashboard") }}</h5>
                </div>
            </div>
        </div>

        <div class="row none-mobile m-0">
            <div class="col-12">
                <LoaderComponent v-if="loadingRestaurants" />
                <div v-else>
                    <div v-if="error" class="error">
                        {{ error }}
                    </div>

                    <div v-if="restaurants && restaurants.length > 0">
                        <div v-for="restaurant in restaurants" class="mb-3" :key="restaurant.id">
                            <div class="mb-2">
                                <h5 class="card-title m-0 p-3 bg-light">
                                    <router-link
                                        :to="{
                                            name: 'booking.restaurants.reservations',
                                            params: { restaurant_id: restaurant.id },
                                        }">
                                        {{ restaurant.name }}
                                    </router-link>
                                    <span
                                        v-if="user && (user.role === 'admin' || user.role === 'owner' || user.role === 'manager')"
                                        class="float-right none-tablet">
                                        <router-link
                                            :to="{
                                                name: 'booking.restaurants.settings.settings',
                                                params: { restaurant_id: restaurant.id },
                                            }">
                                            <feather type="settings" v-tooltip="getTooltip($t('labels.routes.booking.settings'))"></feather>
                                        </router-link>
                                    </span>
                                </h5>
                                <div class="card-body p-0 row m-0">
                                    <div class="col-xl-3 col-lg-4 col-sm-6 p-0 pr-2 mb-2">
                                        <router-link
                                            class="media align-items-stretch bg-grad-warning white"
                                            :to="{
                                                name: 'booking.restaurants.reservationsCreated',
                                                params: { restaurant_id: restaurant.id },
                                            }">
                                            <div class="p-3 media-middle">
                                                <feather type="clock" class="mt-2"></feather>
                                            </div>
                                            <div class="media-body pt-3 pr-3 pb-3 pl-1">
                                                <small class="mt-2" style="display: block">
                                                    {{ $tl("labels.booking.reservations.nb") }}
                                                </small>
                                                <h6>{{ $tl("labels.pending") }}</h6>
                                            </div>
                                            <div class="media-right p-3 media-middle">
                                                <h4 class="pt-2">{{ restaurant.reservations_created }}</h4>
                                            </div>
                                        </router-link>
                                    </div>

                                    <div
                                        v-for="category in restaurant.service_categories"
                                        :key="category"
                                        class="col-xl-3 col-lg-4 col-sm-6 p-0 pr-2 mb-2">
                                        <router-link
                                            class="media align-items-stretch bg-grad white"
                                            :to="{
                                                name: 'booking.restaurants.reservations',
                                                params: { restaurant_id: restaurant.id },
                                            }">
                                            <div class="p-3 media-middle">
                                                <feather type="calendar" class="mt-2"></feather>
                                            </div>
                                            <div class="media-body pt-3 pr-3 pb-3 pl-1">
                                                <small class="mt-2" style="display: block">
                                                    {{ $tl("labels.booking.pax.nb", restaurant.id) }}
                                                </small>
                                                <h6>{{ getServiceCategoryLabel(restaurant[`service_category_${category}`]) }}</h6>
                                            </div>
                                            <div class="media-right p-3 media-middle">
                                                <h4 class="pt-2">{{ restaurant[`service_category_${category}`].nb_reservations_today }}</h4>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 float-right">
                        <a class="btn btn-sm btn-outline-secondary" v-if="links.prev" href="javascript:" @click="fetchData(links.prev)">
                            {{ $tl("labels.form.actions.previous") }}
                        </a>
                        <a class="btn btn-sm btn-outline-secondary" v-if="links.next" href="javascript:" @click="fetchData(links.next)">
                            {{ $tl("labels.form.actions.next") }}
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- BOARD COMPUTER -->
        <loader-component v-if="loadingDatesRange" />
        <ejs-tooltip
            v-else-if="dates_range !== null"
            ref="tooltip"
            class="tooltipcontainer none-mobile p-3"
            :content="serviceTooltipContent"
            target=".service-tooltip-button"
            position="BottomCenter"
            :afterOpen="onAfterOpen"
            opensOn="Custom">
            <div v-if="board && board.restaurants" ref="board" class="booking-board">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-2 mb-0 border-bottom">
                    <h2 class="h5">
                        <button
                            class="btn btn-sm btn-outline-secondary radius-btn-square float-left"
                            style="width: 29px; padding-left: 5px"
                            :disabled="atLeastOneBoardLoading"
                            @click="offset -= 1">
                            <feather type="chevron-left" />
                        </button>

                        <DatePicker
                            class="date-resa-cal float-left mt-1"
                            tabindex="1"
                            :disabled="atLeastOneBoardLoading"
                            v-model="pickerDate"
                            :format="weekDateFormatter" />
                        <button
                            class="btn btn-sm btn-outline-secondary radius-btn-square float-left"
                            style="width: 29px; padding-left: 6px"
                            :disabled="atLeastOneBoardLoading"
                            @click="offset += 1">
                            <feather type="chevron-right" />
                        </button>

                        <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary btn-circle ml-2"
                            :disabled="atLeastOneBoardLoading"
                            @click="offset = 0">
                            {{ $tl("labels.thisWeek") }}
                        </button>
                        <feather v-tooltip="getTooltipNotice('notices.booking.dashboard')" type="info" class="ml-1 pointer tooltip-infos" />
                    </h2>
                </div>

                <table tabindex="-1" class="table-home capitalize table table-sm table-striped border-bottom mb-5 text-center table-resp-width">
                    <thead tabindex="-1">
                        <tr tabindex="-1">
                            <th tabindex="-1"></th>
                            <th
                                v-for="index in $_.range(0, 7)"
                                :key="index"
                                tabindex="-1"
                                :class="{
                                    'board-current-day':
                                        offset === 0 &&
                                        getDateTime(dates_range[index], false, getRestaurantTimeZone()).hasSame(
                                            getDateTime(null, false, getRestaurantTimeZone()),
                                            'day'
                                        ),
                                }">
                                {{ capitalize(displayDate(dates_range[index], "ccc - dd/LL", false)) }}
                            </th>
                        </tr>
                    </thead>
                    <template v-for="(restau, indexRestau) in restaurants">
                        <tbody tabindex="-1" :key="indexRestau">
                            <tr tabindex="-1" class="bg-main-color-light text-white">
                                <td tabindex="-1" class="text-left p-2">
                                    <strong>{{ restau.name }}</strong>
                                    <feather
                                        class="pointer"
                                        :type="isBoardOpened(restau.id) ? 'chevron-down' : 'chevron-right'"
                                        @click="toggleBoard(restau.id)" />
                                </td>
                                <td v-for="index in $_.range(0, 7)" :key="index" tabindex="-1"></td>
                            </tr>
                            <template v-if="isBoardOpened(restau.id)">
                                <template v-if="loadingBoards[restau.id] === true || typeof getBoard(restau.id) === 'undefined'">
                                    <tr>
                                        <loader-component message="" />
                                        <td v-for="index in $_.range(0, 7)" :key="index" tabindex="-1"></td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr class="bg-secondary" :style="{ opacity: isYservices ? 0.5 : 1 }">
                                        <td class="text-white text-left">
                                            <feather class="mx-2" type="volume-2" />{{ $tl("labels.booking.customEvents.title") }}
                                        </td>
                                        <td
                                            v-for="index in $_.range(0, 7)"
                                            :key="index"
                                            class="board-cell-nb-events"
                                            @click="showEventsForDay(restau, dates_range[index])">
                                            {{ getEventsForDay(restau, dates_range[index]).length }}
                                        </td>
                                    </tr>
                                    <tr tabindex="-1" v-for="service in getBoard(restau.id).services" :key="service.id">
                                        <td tabindex="-1" class="text-left">
                                            <strong>{{ getServiceCategoryLabel(service) }} </strong><small>{{ service.name }}</small>
                                        </td>
                                        <td v-for="(date, index) in service.dates" :key="`${service.id}-${index}`" class="p-0">
                                            <button
                                                v-if="date.is_open"
                                                class="btn w-100 h-100 service-tooltip-button"
                                                tabindex="-1"
                                                :data-service-id="service.id"
                                                :data-date="date.full_date"
                                                v-on:click="customOpen"
                                                :style="date.almost_full === true ? 'color: #F75B61 !important;' : 'color: #666;'">
                                                <div style="font-weight: 600; pointer-events: none" v-on:click="customOpen">
                                                    <feather v-if="date.is_full === 1" type="minus-circle" class="text-danger" />
                                                    <feather v-else-if="date.is_full === 0" type="alert-circle" class="text-warning" />
                                                    {{ date.pax }}/<span :class="{ 'text-warning': date.new_pax !== null }">{{
                                                        date.new_pax !== null ? date.new_pax : date.max_pax
                                                    }}</span>
                                                </div>
                                            </button>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </template>
                </table>
            </div>
        </ejs-tooltip>
        <add-reservation-modal
            v-if="showAddReservationModal"
            :restaurant_id="selected_restaurant_id"
            :service_id="selected_service_id"
            :slot_id="selected_slot_id"
            :date="selected_full_date"
            @close="showAddReservationModal = false"
            @reservation-saved="reservationSaved" />
        <set-service-full-modal
            v-if="showSetServiceFullModal"
            :restaurant_id="selected_restaurant_id"
            :service_id="selected_service_id"
            :slot_id="selected_slot_id"
            :date="selected_full_date"
            :setFree="serviceFullModalSetFree"
            @close="showSetServiceFullModal = false"
            @closure-saved="closureSaved" />
        <remove-service-full-modal
            v-if="showRemoveServiceFullModal"
            :restaurant_id="selected_restaurant_id"
            :service_id="selected_service_id"
            :slot_id="selected_slot_id"
            :date="selected_full_date"
            @close="showRemoveServiceFullModal = false"
            @closure-removed="closureRemoved" />
        <list-custom-events
            v-if="listCustomEventsProps.restaurantId !== null && listCustomEventsProps.date !== null"
            ref="listCustomEvents"
            v-bind="listCustomEventsProps" />
    </div>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css";
</style>

<script>
import axios from "axios";
import ServiceDayPopover from "../../components/services/ServiceDayPopover.vue";
import SlotDayPopover from "../../components/services/SlotDayPopover.vue";
import AddReservationModal from "../../components/Modals/reservations/AddReservationModalNew.vue";
import SetServiceFullModal from "../../components/Modals/services/setServiceFullModal.vue";
import RemoveServiceFullModal from "../../components/Modals/services/removeServiceFullModal.vue";
import LoaderComponent from "../../components/LoaderComponent.vue";
import Vue from "vue";
import { TooltipPlugin } from "@syncfusion/ej2-vue-popups";
import DatePicker from "../../components/forms/DatePicker.vue";
import LangsEnum from "../../mixins/enums/LangsEnum";
import listCustomEvents from "../../components/customEvents/listCustomEvents.vue";

Vue.use(TooltipPlugin);

export default {
    data() {
        return {
            loading: 0,
            loadingRestaurants: false,
            paginate: false,
            links: {
                next: null,
                prev: null,
            },
            error: null,
            board: {
                restaurants: [],
                opened: [],
            },
            offset: 0,
            currentDayIndex: 0,
            showAddReservationModal: false,
            selected_restaurant_id: null,
            selected_service_id: null,
            selected_slot_id: null,
            selected_full_date: null,
            showSetServiceFullModal: false,
            showRemoveServiceFullModal: false,
            socket: {
                restaurants: {
                    listeningChannels: [],
                },
                services: {
                    listeningChannels: [],
                },
            },
            tooltip: {
                service: null,
                date: null,
            },
            eventHub: new Vue(),
            targetInTooltip: null,
            tooltipService: null,
            tooltipWidget: null,
            tooltipDate: null,
            tooltipServiceId: null,
            showDetails: false,
            pickerDate: this.getDateTime(null, false, this.getRestaurantTimeZone()),
            restaurants: [],
            loadingDatesRange: false,
            dates_range: null,
            loadingBoards: {},
            listCustomEventsProps: {
                restaurantId: null,
                date: null,
            },
        };
    },
    mixins: [LangsEnum],
    props: {
        user: {
            required: true,
        },
    },
    created() {
        this.eventHub.$on("blur", this.closeTooltip);
        this.eventHub.$on("refresh", this.refreshTooltip);
        this.eventHub.$on("service-max-pax-changed", this.serviceMaxPaxUpdatedFromTooltip);
        this.eventHub.$on("service-full-changed", this.serviceFullUpdatedFromTooltip);
        this.eventHub.$on("add-resa", this.addResa);
        this.eventHub.$on("list-resa", this.listResa);
        this.fetchRestaurants();
        this.fetchDatesRange();
    },
    beforeDestroy() {
        this.unregisterAllSockets();
    },
    updated() {
        $('[data-toggle="popover"]').popover();
    },
    computed: {
        atLeastOneBoardLoading() {
            return Object.values(this.loadingBoards).length > 0 && Object.values(this.loadingBoards).some((value) => value === true);
        },
        currentDayIndexDay() {
            if (this.dates_range === null) {
                return "";
            }
            return this.displayDate(this.dates_range[this.currentDayIndex], "cccc", false);
        },
        currentDayIndexDate() {
            if (this.dates_range === null) {
                return "";
            }
            return this.displayDate(this.dates_range[this.currentDayIndex], null, false);
        },
        serviceTooltipContent() {
            return () => {
                var component = ServiceDayPopover;
                const baseData = component.data();
                const self = this;

                component.data = () => {
                    return Object.assign(baseData, {
                        eventHub: this.eventHub,
                        date: this.tooltipDate,
                        serviceId: this.tooltipServiceId,
                        translate: function (value, params = {}) {
                            return self.$t(value, params);
                        },
                        translate_lexicon: function (value, restaurantId, params = {}) {
                            return self.$tl(value, restaurantId, params);
                        },
                    });
                };

                return {
                    template: component,
                };
            };
        },
        userRestaurantsIds() {
            return this.$store.getters["users/restaurantIds"];
        },
    },
    methods: {
        getRestaurantTimeZone() {
            if (this.restaurants && this.restaurants.length > 0) {
                return this.restaurants[0].timezone;
            }
            return this.getAutoDetectedTimezone();
        },
        weekDateFormatter(date) {
            return date.startOf("week").toFormat("dd/LL") + " - " + date.endOf("week").toFormat("dd/LL");
        },
        getEventsForDay(restaurant, date) {
            const dateObj = this.getDateTime(date).toJSDateCustom(false);

            const storeRestaurant = this.$store.getters["restaurants/findRestaurantById"](restaurant.id);

            if (typeof storeRestaurant === "undefined") {
                return [];
            }

            return storeRestaurant.custom_events.data.filter(
                (ce) => this.getDateTime(ce.date_begin) <= dateObj && this.getDateTime(ce.date_end) >= dateObj
            );
        },
        showEventsForDay(restaurant, date) {
            this.listCustomEventsProps.restaurantId = restaurant.id;
            this.listCustomEventsProps.date = date;

            this.$nextTick(() => this.$refs.listCustomEvents.open());
        },
        fetchRestaurants() {
            this.error = null;
            this.loadingRestaurants = true;
            return axios
                .get("/api/booking/restaurants?exclude_canceled=1&include=resa_count")
                .then((response) => {
                    this.restaurants = response.data.data.filter((r) => this.userRestaurantsIds.includes(r.id));
                    if (this.isClubMedOwnerId(this.$store.getters["users/ownerId"])) {
                        this.restaurants.sort((a, b) => a.name.localeCompare(b.name));
                    }
                    this.redirectToFirstRestaurant();
                    // Only one restaurant => open it
                    if (response.data.data.length === 1) {
                        this.openBoard(response.data.data[0].id);
                    }
                    this.loadingRestaurants = false;
                })
                .catch((error) => {
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });

                    this.loadingRestaurants = false;
                });
        },
        fetchDatesRange() {
            this.loadingDatesRange = true;

            return axios
                .get(`/api/board/dates_range?offset=${this.offset}`)
                .then((response) => {
                    this.dates_range = response.data;
                    if (this.offset === 0) {
                        this.setBoardCurrentDayIndex();
                    }
                    return true;
                })
                .catch((error) => {
                    this.notifyError(error);
                    return false;
                })
                .finally(() => {
                    this.loadingDatesRange = false;
                });
        },
        fetchBoardForRestaurant(restaurant_id) {
            this.$set(this.loadingBoards, restaurant_id, true);
            this.unregisterServicesSockets(restaurant_id);

            return axios
                .get(`/api/board/${restaurant_id}?offset=${this.offset}`)
                .then((response) => {
                    this.$set(this.loadingBoards, restaurant_id, false);
                    const index = this.board.restaurants.findIndex((restaurant) => restaurant.id === restaurant_id);
                    if (index !== -1) {
                        this.board.restaurants.splice(index, 1);
                    }
                    this.board.restaurants.push(response.data);
                    this.board.restaurants.forEach((restaurant) => {
                        restaurant.serviceClosureShown = {};
                    });
                    if (this.isClubMedOwnerId(this.$store.getters["users/ownerId"])) {
                        this.board.restaurants.sort((a, b) => a.name.localeCompare(b.name));
                    }
                    this.registerServicesSockets(restaurant_id);
                    return true;
                })
                .catch((error) => {
                    this.$set(this.loadingBoards, restaurant_id, false);
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                    return false;
                });
        },
        setBoardCurrentDayIndex() {
            const today = this.getDateTime(null, false, this.getRestaurantTimeZone()).toISODate();

            for (var index in this.dates_range) {
                if (this.dates_range[index] === today) {
                    this.currentDayIndex = index;
                    return;
                }
            }
        },
        scrollToBoard() {
            if (this.$refs.board) {
                this.$refs.board.scrollIntoView();
            }
        },
        listResa({ restaurant_id, service_id, full_date }) {
            this.selected_restaurant_id = restaurant_id;
            this.selected_service_id = service_id;
            this.selected_full_date = full_date;

            this.$router.push({
                name: "booking.restaurants.reservations",
                params: {
                    restaurant_id: this.selected_restaurant_id,
                },
                query: {
                    date: this.selected_full_date,
                },
            });
        },
        addResa({ restaurant_id, service_id, slot_id, full_date }) {
            this.selected_restaurant_id = restaurant_id;
            this.selected_service_id = service_id;
            if (slot_id) {
                this.selected_slot_id = slot_id;
            }
            this.selected_full_date = full_date;

            this.$nextTick(() => {
                this.showAddReservationModal = true;
            });
        },
        setServiceFull({ restaurant_id, service_id, full_date }) {
            this.selected_restaurant_id = restaurant_id;
            this.selected_service_id = service_id;
            this.selected_slot_id = null;
            this.selected_full_date = full_date;
            this.serviceFullModalSetFree = false;

            this.$nextTick(() => {
                this.$refs[`${restaurant_id}${service_id}${full_date}`][0].$_hide();
                this.showSetServiceFullModal = true;
            });
        },
        setSlotFull({ restaurant_id, service_id, slot_id, full_date }) {
            this.selected_restaurant_id = restaurant_id;
            this.selected_service_id = service_id;
            this.selected_slot_id = slot_id;
            this.selected_full_date = full_date;
            this.serviceFullModalSetFree = false;

            this.$nextTick(() => {
                this.$refs[`${restaurant_id}${service_id}${slot_id}${full_date}`][0].$_hide();
                this.showSetServiceFullModal = true;
            });
        },
        removeServiceFull({ restaurant_id, service_id, full_date }) {
            this.selected_restaurant_id = restaurant_id;
            this.selected_service_id = service_id;
            this.selected_slot_id = null;
            this.selected_full_date = full_date;
            this.serviceFullModalSetFree = true;

            this.$nextTick(() => {
                this.$refs[`${restaurant_id}${service_id}${full_date}`][0].$_hide();
                this.showSetServiceFullModal = true;
            });
        },
        removeSlotFull({ restaurant_id, service_id, slot_id, full_date }) {
            this.selected_restaurant_id = restaurant_id;
            this.selected_service_id = service_id;
            this.selected_slot_id = slot_id;
            this.selected_full_date = full_date;
            this.serviceFullModalSetFree = true;

            this.$nextTick(() => {
                this.$refs[`${restaurant_id}${service_id}${slot_id}${full_date}`][0].$_hide();
                this.showSetServiceFullModal = true;
            });
        },
        reservationSaved() {
            this.fetchBoardForRestaurant(this.selected_restaurant_id)
                .then(() => {
                    this.scrollToBoard();
                })
                .catch(() => {});
            this.showAddReservationModal = false;
            this.$notify({
                group: "notification",
                type: "success",
                title: "La réservation a été enregistrée avec succès.",
            });
        },
        closureSaved() {
            this.fetchBoardForRestaurant(this.selected_restaurant_id)
                .then(() => {
                    this.scrollToBoard();
                })
                .catch(() => {});
            this.showSetServiceFullModal = false;
            this.$notify({
                group: "notification",
                type: "success",
                title: "Le service a été modifié",
            });
        },
        closureRemoved() {
            this.fetchBoardForRestaurant(this.selected_restaurant_id)
                .then(() => {
                    this.scrollToBoard();
                })
                .catch(() => {});
            this.showRemoveServiceFullModal = false;
            this.$notify({
                group: "notification",
                type: "success",
                title: "Le service est désormais ouvert aux réservations.",
            });
        },
        registerRestaurantsSockets(restaurant_id = undefined) {
            const today = this.getDateTime(null, false, this.getRestaurantTimeZone()).toISODate();

            this.restaurants.forEach((restaurant) => {
                if (typeof restaurant_id === "undefined" || restaurant.id === restaurant_id) {
                    const channelName = `App.restaurant.${restaurant.id}.date.${today}`;
                    Echo.private(channelName).listen(".restaurant.updated", this.onRestaurantUpdated);

                    this.socket.restaurants.listeningChannels.push(channelName);
                    this.$store.commit("sockets/addChannel", channelName);
                }
            });
        },
        unregisterRestaurantsSockets() {
            this.socket.restaurants.listeningChannels.forEach((channelName) => {
                Echo.private(channelName).stopListening(".restaurant.updated");
            });

            this.socket.restaurants.listeningChannels = [];
        },
        registerServicesSockets(restaurant_id = undefined) {
            this.board.restaurants.forEach((restaurant) => {
                if (typeof restaurant_id === "undefined" || restaurant.id === restaurant_id) {
                    restaurant.services.forEach((service) => {
                        service.dates.forEach((date) => {
                            const channelName = `App.restaurant.${restaurant.id}.service.${service.id}.date.${date.full_date}`;

                            Echo.private(channelName).listen(".service.updated", this.onServiceUpdated);

                            this.socket.services.listeningChannels.push(channelName);
                            this.$store.commit("sockets/addChannel", channelName);
                        });
                    });
                }
            });
        },
        unregisterServicesSockets() {
            this.socket.services.listeningChannels.forEach((channelName) => {
                Echo.private(channelName).stopListening(".service.updated");
            });

            this.socket.services.listeningChannels = [];
        },
        unregisterAllSockets() {
            this.$store.getters["sockets/getChannels"].forEach((channelName) => {
                Echo.leave(channelName);
            });

            this.$store.commit("sockets/removeAllChannels");
        },
        onRestaurantUpdated(e) {
            const updatedRestaurant = e.restaurant;

            for (var i = 0; i < this.restaurants.length; i++) {
                const restaurant = this.restaurants[i];

                if (restaurant.id === updatedRestaurant.id) {
                    this.$set(this.restaurants, i, updatedRestaurant);
                    break;
                }
            }
        },
        onServiceUpdated(e) {
            const updatedService = e.service;

            for (var i = 0; i < this.board.restaurants.length; i++) {
                const restaurant = this.board.restaurants[i];

                for (var j = 0; j < restaurant.services.length; j++) {
                    const service = restaurant.services[j];

                    for (var k = 0; k < service.dates.length; k++) {
                        const date = service.dates[k];

                        if (service.id === updatedService.id && date.full_date === updatedService.full_date) {
                            this.$set(this.board.restaurants[i].services[j].dates, k, updatedService);
                        }
                    }
                }
            }
        },
        getServiceDetailsForDate(serviceId, date) {
            return axios
                .get(`/api/board/services/${serviceId}?date=${date}`)
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    } else {
                        return null;
                    }
                })
                .catch((error) => {
                    //error occured
                    return null;
                });
        },
        customOpen(args) {
            const serviceId = args.target.dataset.serviceId;
            const date = args.target.dataset.date;
            if (serviceId && date) {
                this.tooltipServiceId = serviceId;
                this.tooltipDate = date;
                this.targetInTooltip = args.target;
                this.$refs.tooltip.open(args.target);
            }
        },
        closeTooltip() {
            this.$nextTick(() => {
                this.targetInTooltip = null;
                if (this.$refs.tooltip) {
                    this.$refs.tooltip.close();
                }
            });
        },
        onAfterOpen() {
            this.eventHub.$emit("set-focus");
        },
        refreshTooltip() {
            if (this.targetInTooltip) {
                this.$refs.tooltip.refresh(this.targetInTooltip);
            }
        },
        getWidgetByServiceId(serviceId) {
            return axios
                .get(`/api/services/${serviceId}/widget`)
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    } else {
                        return null;
                    }
                })
                .catch((error) => {
                    // error occured
                    return null;
                });
        },
        serviceMaxPaxUpdatedFromTooltip({ restaurantId, serviceId, value, date }) {
            this.board.restaurants.forEach((restaurant) => {
                if (restaurant.id === restaurantId) {
                    restaurant.services.forEach((service) => {
                        if (service.id === serviceId) {
                            service.dates.forEach((serviceDate) => {
                                if (serviceDate.full_date === date) {
                                    serviceDate.new_pax = value;
                                }
                            });
                        }
                    });
                }
            });
        },
        serviceFullUpdatedFromTooltip({ restaurantId, serviceId, value, date }) {
            this.board.restaurants.forEach((restaurant) => {
                if (restaurant.id === restaurantId) {
                    restaurant.services.forEach((service) => {
                        if (service.id === serviceId) {
                            service.dates.forEach((serviceDate) => {
                                if (serviceDate.full_date === date) {
                                    serviceDate.is_full = value;
                                }
                            });
                        }
                    });
                }
            });
        },
        collapseOtherRestaurants(restaurant) {
            this.closeAllBoards();
            this.openBoard(restaurant.id)
                .then(() => {
                    this.scrollToBoard();
                })
                .catch((err) => {});
        },
        incrementCurrentDayIndex() {
            for (var restau of this.board.restaurants) {
                restau.serviceClosureShown = {};
            }

            if (this.currentDayIndex < this.dates_range.length - 1) {
                this.currentDayIndex++;
            } else {
                this.offset++;
                this.$nextTick(() => {
                    this.currentDayIndex = 0;
                });
            }
        },
        decrementCurrentDayIndex() {
            for (var restau of this.board.restaurants) {
                restau.serviceClosureShown = {};
            }

            if (this.currentDayIndex > 0) {
                this.currentDayIndex--;
            } else {
                this.offset--;
                this.$nextTick(() => {
                    this.currentDayIndex = 6;
                });
            }
        },
        showServiceClosures(restaurant, serviceGiven) {
            this.getServiceDetailsForDate(serviceGiven.id, serviceGiven.dates[this.currentDayIndex].full_date).then((service) => {
                if (service) {
                    this.getWidgetByServiceId(serviceGiven.id).then((widget) => {
                        if (widget) {
                            for (var key in this.board.restaurants) {
                                if (this.board.restaurants[key].id === restaurant.id) {
                                    this.$set(this.board.restaurants, key, {
                                        ...restaurant,
                                        serviceClosureShown: {
                                            service,
                                            widget,
                                            slotSelected: null,
                                            showServicePaxes: false,
                                        },
                                    });
                                }
                            }
                        }
                    });
                }
            });
        },
        goServiceGoBack(restaurant) {
            if (!this.$_.isEmpty(restaurant.serviceClosureShown)) {
                if (restaurant.serviceClosureShown.showServicePaxes || restaurant.serviceClosureShown.slotSelected) {
                    restaurant.serviceClosureShown.showServicePaxes = false;
                    restaurant.serviceClosureShown.slotSelected = null;
                } else {
                    restaurant.serviceClosureShown = {};
                }
            }
        },
        getStatusClass(value) {
            switch (value) {
                case 1:
                    return "closed";
                case 0:
                    return "half-closed";
                default:
                    return "open";
            }
        },
        showServicePaxes(restaurant) {
            if (!this.$_.isEmpty(restaurant.serviceClosureShown)) {
                restaurant.serviceClosureShown.showServicePaxes = true;
            }
        },
        showSlotPaxes(restaurant, slot) {
            if (!this.$_.isEmpty(restaurant.serviceClosureShown)) {
                restaurant.serviceClosureShown.slotSelected = slot;
            }
        },
        getAllPaxes(widget) {
            const minPax = widget.min_pers ? widget.min_pers : 0;
            const maxPax = (widget.max_pers ? widget.max_pers : 0) + (widget.max_children ? widget.max_children : 0);

            return this.$_.range(minPax, maxPax + 1);
        },
        updateServiceAvailability(event, restaurant) {
            if (!this.$_.isEmpty(restaurant.serviceClosureShown)) {
                if (restaurant.serviceClosureShown.service.is_full > 0) {
                    this.openService(restaurant, this.dates_range[this.currentDayIndex]);
                } else {
                    this.closeService(restaurant, this.dates_range[this.currentDayIndex], this.getAllPaxes(restaurant.serviceClosureShown.widget));
                }
            }
        },
        updateSlotAvailability(slot, restaurant) {
            if (slot.is_full > 0) {
                this.openSlot(slot, restaurant, this.dates_range[this.currentDayIndex], this.getAllPaxes(restaurant.serviceClosureShown.widget));
            } else {
                this.closeSlot(slot, restaurant, this.dates_range[this.currentDayIndex], this.getAllPaxes(restaurant.serviceClosureShown.widget));
            }
        },
        updateSlotPaxAvailability(restaurant, pax) {
            if (!this.$_.isEmpty(restaurant.serviceClosureShown)) {
                if (restaurant.serviceClosureShown.slotSelected.paxes_full.includes(pax)) {
                    this.openSlotPax(
                        restaurant.serviceClosureShown.slotSelected,
                        restaurant,
                        pax,
                        this.dates_range[this.currentDayIndex],
                        this.getAllPaxes(restaurant.serviceClosureShown.widget)
                    );
                } else {
                    this.closeSlotPax(
                        restaurant.serviceClosureShown.slotSelected,
                        restaurant,
                        pax,
                        this.dates_range[this.currentDayIndex],
                        this.getAllPaxes(restaurant.serviceClosureShown.widget)
                    );
                }
            }
        },
        updateServicePaxAvailability(restaurant, pax) {
            if (!this.$_.isEmpty(restaurant.serviceClosureShown)) {
                if (restaurant.serviceClosureShown.service.paxes_full[pax] === -1) {
                    this.closeServicePax(
                        restaurant,
                        pax,
                        this.dates_range[this.currentDayIndex],
                        this.getAllPaxes(restaurant.serviceClosureShown.widget)
                    );
                } else {
                    this.openServicePax(
                        restaurant,
                        pax,
                        this.dates_range[this.currentDayIndex],
                        this.getAllPaxes(restaurant.serviceClosureShown.widget)
                    );
                }
            }
        },
        openService(restaurant, date) {
            var service = restaurant.serviceClosureShown.service;
            const url = `/api/restaurants/${service.restaurant.id}/services/${service.id}/full?date=${date}`;

            return axios
                .delete(url)
                .then((response) => {
                    service.is_full = -1;
                    service.slots.forEach((slot) => {
                        slot.is_full = -1;
                        slot.paxes_full = [];
                    });
                    for (var pax in service.paxes_full) {
                        service.paxes_full[pax] = -1;
                    }

                    var servicesFiltered = restaurant.services.filter((s) => s.id === service.id);

                    if (servicesFiltered.length > 0) {
                        servicesFiltered[0].dates[this.currentDayIndex].is_full = service.is_full;
                    }
                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        closeService(restaurant, date, allPaxes) {
            var service = restaurant.serviceClosureShown.service;
            const url = `/api/restaurants/${service.restaurant.id}/services/${service.id}/full?date=${date}`;

            return axios
                .post(url)
                .then((response) => {
                    service.is_full = 1;
                    service.slots.forEach((slot) => {
                        slot.is_full = 1;
                        slot.paxes_full = allPaxes;
                    });
                    for (var pax in service.paxes_full) {
                        service.paxes_full[pax] = 1;
                    }

                    var servicesFiltered = restaurant.services.filter((s) => s.id === service.id);

                    if (servicesFiltered.length > 0) {
                        servicesFiltered[0].dates[this.currentDayIndex].is_full = service.is_full;
                    }
                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        openSlot(slot, restaurant, date, allPaxes) {
            var service = restaurant.serviceClosureShown.service;
            const url = `/api/restaurants/${service.restaurant.id}/slots/${slot.id}/full?date=${date}`;

            return axios
                .delete(url)
                .then((response) => {
                    slot.is_full = 0;
                    slot.paxes_full = [];

                    this.checkServiceFullStatus(service, allPaxes);
                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        closeSlot(slot, restaurant, date, allPaxes) {
            var service = restaurant.serviceClosureShown.service;
            const url = `/api/restaurants/${service.restaurant.id}/slots/${slot.id}/full?date=${date}`;

            return axios
                .post(url)
                .then((response) => {
                    slot.is_full = 1;
                    slot.paxes_full = allPaxes;

                    this.checkServiceFullStatus(restaurant, allPaxes);

                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        openSlotPax(slot, restaurant, pax, date, allPaxes) {
            var service = restaurant.serviceClosureShown.service;
            const url = `/api/restaurants/${service.restaurant.id}/slots/${slot.id}/full/${pax}?date=${date}`;

            return axios
                .delete(url)
                .then((response) => {
                    slot.paxes_full = slot.paxes_full.filter((p) => p !== pax);

                    if (slot.paxes_full.length === 0) {
                        slot.is_full = -1;
                    } else {
                        slot.is_full = 0;
                    }

                    this.checkServiceFullStatus(restaurant, allPaxes);

                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        closeSlotPax(slot, restaurant, pax, date, allPaxes) {
            var service = restaurant.serviceClosureShown.service;
            const url = `/api/restaurants/${service.restaurant.id}/slots/${slot.id}/full/${pax}?date=${date}`;

            return axios
                .post(url)
                .then((response) => {
                    slot.paxes_full.push(pax);

                    if (slot.paxes_full.length === allPaxes.length) {
                        slot.is_full = 1;
                    } else {
                        slot.is_full = 0;
                    }

                    this.checkServiceFullStatus(restaurant, allPaxes);

                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        openServicePax(restaurant, pax, date, allPaxes) {
            var service = restaurant.serviceClosureShown.service;
            const url = `/api/restaurants/${service.restaurant.id}/services/${service.id}/full/${pax}?date=${date}`;

            return axios
                .delete(url)
                .then((response) => {
                    service.paxes_full[pax] = -1;

                    for (var slot of service.slots) {
                        slot.paxes_full = slot.paxes_full.filter((p) => p !== pax);
                        if (slot.paxes_full.length === 0) {
                            slot.is_full = -1;
                        } else {
                            slot.is_full = 0;
                        }
                    }

                    this.checkServiceFullStatus(restaurant, allPaxes);

                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        closeServicePax(restaurant, pax, date, allPaxes) {
            var service = restaurant.serviceClosureShown.service;
            const url = `/api/restaurants/${service.restaurant.id}/services/${service.id}/full/${pax}?date=${date}`;

            return axios
                .post(url)
                .then((response) => {
                    service.paxes_full[pax] = 1;

                    for (var slot of service.slots) {
                        if (!slot.paxes_full.includes(pax)) {
                            slot.paxes_full.push(pax);
                        }

                        if (slot.paxes_full.length === allPaxes.length) {
                            slot.is_full = 1;
                        } else if (slot.paxes_full.length === 0) {
                            slot.is_full = -1;
                        } else {
                            slot.is_full = 0;
                        }
                    }

                    this.checkServiceFullStatus(restaurant, allPaxes);

                    return true;
                })
                .catch((error) => {
                    this.manageError(error);

                    return false;
                });
        },
        manageError(error) {
            let errorMessage = null;

            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            } else {
                errorMessage = "Une erreur est survenue.";
            }

            this.$notify({
                group: "notification",
                type: "error",
                title: errorMessage,
            });
        },
        checkServiceFullStatus(restaurant, allPaxes) {
            var service = restaurant.serviceClosureShown.service;
            let atLeastASlotOpen = false;
            let atLeastASlotClosed = false;
            var slotPaxesClosed = {};

            service.slots.forEach((slot) => {
                if (slot.paxes_full.length === allPaxes.length) {
                    slot.is_full = 1;
                    atLeastASlotClosed = true;
                } else if (slot.paxes_full.length > 0) {
                    slot.is_full = 0;
                    atLeastASlotClosed = true;
                    atLeastASlotOpen = true;
                } else {
                    slot.is_full = -1;
                    atLeastASlotOpen = true;
                }

                slot.paxes_full.forEach((pax) => {
                    if (slotPaxesClosed[pax]) {
                        slotPaxesClosed[pax]++;
                    } else if (!slotPaxesClosed[pax]) {
                        slotPaxesClosed[pax] = 1;
                    }
                });
            });

            for (var pax_full in service.paxes_full) {
                if (!slotPaxesClosed[pax_full]) {
                    service.paxes_full[pax_full] = -1;
                } else if (slotPaxesClosed[pax_full] === service.slots.length) {
                    service.paxes_full[pax_full] = 1;
                } else if (slotPaxesClosed[pax_full]) {
                    service.paxes_full[pax_full] = 0;
                }
            }

            if (atLeastASlotClosed && !atLeastASlotOpen) {
                service.is_full = 1;
            } else if (atLeastASlotClosed && atLeastASlotOpen) {
                service.is_full = 0;
            } else {
                service.is_full = -1;
            }

            var servicesFiltered = restaurant.services.filter((s) => s.id === service.id);

            if (servicesFiltered.length > 0) {
                servicesFiltered[0].dates[this.currentDayIndex].is_full = service.is_full;
            }
        },
        redirectToFirstRestaurant() {
            if (this.user != "" && typeof this.restaurants !== "undefined" && this.restaurants !== null) {
                if (this.user.role === "kitchen" && this.restaurants.length > 0)
                    this.$router.push(`/booking/restaurants/${this.restaurants[0].id}/reservations-cuisine`);
                else if (this.user.role === "kitchen") this.$router.push("/mon-compte");
            }
        },
        isBoardOpened(restaurant_id) {
            return this.board.opened.length > 0 && this.board.opened.some((id) => id === restaurant_id);
        },
        toggleBoard(restaurant_id) {
            if (this.isBoardOpened(restaurant_id)) {
                this.closeBoard(restaurant_id);
            } else {
                this.openBoard(restaurant_id);
            }
        },
        openBoard(restaurant_id) {
            this.board.opened.push(restaurant_id);

            return this.fetchBoardForRestaurant(restaurant_id).then((response) => {
                if (response === false) {
                    this.closeBoard(restaurant_id);
                }
            });
        },
        closeAllBoards() {
            this.board.opened = [];
        },
        closeBoard(restaurant_id) {
            const index = this.board.opened.indexOf(restaurant_id);

            if (index !== -1) {
                this.board.opened.splice(index, 1);
            }
        },
        getBoard(restaurant_id) {
            return this.board.restaurants.find((restau) => restau.id === restaurant_id);
        },
        fetchAllOpenedBoards() {
            let promises = [];
            this.board.opened.forEach((restau_id) => {
                promises.push(this.fetchBoardForRestaurant(restau_id));
            });
            return Promise.all(promises);
        },
    },
    watch: {
        offset(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchDatesRange()
                    .then(() => {
                        this.pickerDate = this.getDateTime(null, false, this.getRestaurantTimeZone()).plus({ weeks: newVal }).startOf("week");

                        this.fetchAllOpenedBoards()
                            .then(() => {
                                this.scrollToBoard();
                            })
                            .catch(() => {});
                    })
                    .catch(() => {});
            }
        },
        restaurants: {
            deep: true,
            handler() {
                this.unregisterRestaurantsSockets();
                this.registerRestaurantsSockets();
            },
        },
        user: {
            immediate: true,
            handler() {
                this.redirectToFirstRestaurant();
            },
        },
        pickerDate(newVal) {
            const nowStartOfWeek = this.getDateTime(null, false, this.getRestaurantTimeZone()).startOf("week");

            this.offset = Math.floor(newVal.startOf("week").diff(nowStartOfWeek, "weeks").weeks);
        },
    },
    components: {
        RemoveServiceFullModal,
        ServiceDayPopover,
        SlotDayPopover,
        SetServiceFullModal,
        AddReservationModal,
        LoaderComponent,
        DatePicker,
        listCustomEvents,
    },
};
</script>
