<template>
    <div>
        <div class="row p-1">
            <div class="col-12 text-muted">
                {{ $tl("labels.booking.reservations.add.nbPers") }}
            </div>
            <div class="col-12 mt-2">
                <div class="row">
                    <div class="col-12 d-flex justify-content-between">
                        <button
                            class="btn btn-select-pax"
                            :class="{ 'btn-success': n === reservation.nb_pers }"
                            @click="clickPax(n)"
                            v-for="n in paxes1"
                            :key="n">
                            {{ n }}
                        </button>
                    </div>
                    <div class="col-12 d-flex justify-content-between mt-3">
                        <button
                            class="btn btn-select-pax"
                            :class="{ 'btn-success': n === reservation.nb_pers }"
                            @click="clickPax(n)"
                            v-for="n in paxes2"
                            :key="n">
                            {{ n }}
                        </button>
                    </div>
                    <div class="col-12 d-flex justify-content-between mt-3">
                        <button
                            class="btn btn-select-pax"
                            :class="{ 'btn-success': n === reservation.nb_pers }"
                            @click="clickPax(n)"
                            v-for="n in paxes3"
                            :key="n">
                            {{ n }}
                        </button>
                        <button v-if="!showMorePax" class="btn btn-select-pax" @click="clickPax('+14')">+14</button>
                        <input
                            v-else
                            ref="input-more-max"
                            v-model.number="reservation.nb_pers"
                            type="number"
                            step="1"
                            class="form-control btn-select-pax text-center" />
                    </div>
                </div>
            </div>
            <div class="col-12 text-muted d-flex align-items-center mt-3">
                {{ $tl("labels.booking.reservations.add.nbChildren") }}
                <input v-model.number="reservation.nb_children" type="number" step="1" class="form-control ml-2 w-auto" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showMorePax: this.reservation.nb_pers !== null && this.reservation.nb_pers > 14,
            paxes1: [1, 2, 3, 4, 5],
            paxes2: [6, 7, 8, 9, 10],
            paxes3: [11, 12, 13, 14],
        };
    },
    props: {
        reservation: {
            type: Object,
            required: true,
        },
    },
    methods: {
        clickPax(pax) {
            this.showMorePax = false;

            if (pax === "+14") {
                this.showMorePax = true;

                this.$nextTick(() => this.$refs["input-more-max"].focus());
            } else {
                this.reservation.nb_pers = pax;

                this.$emit("next-step");
            }
        },
    },
    created() {
        if (this.reservation.nb_pers !== null) {
            this.$emit("next-step");
        }
    },
};
</script>

<style scoped>
.btn-select-pax {
    border: 2px solid #ededed !important;
    height: 59px;
    width: 120px;
}
button.btn-select-pax:hover {
    background-color: #ededed;
}
</style>
