var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Importer des bons cadeaux"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.formLoading
          ? _c("LoaderComponent")
          : _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", [
                    _c("p", [
                      _vm._v(
                        "\n                            Le fichier importé doit être au format CSV UTF-8. Il doit comporter 6 colonnes dans\n                            l'ordre suivant, sans entête :\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("ul", [
                      _c("li", { staticClass: "list-style-type-circle" }, [
                        _vm._v(
                          "\n                                1ère colonne "
                        ),
                        _c("span", { staticStyle: { color: "#f75b61" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          " : Référence du bon cadeau (10\n                                caractères maximum)\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "list-style-type-circle" }, [
                        _vm._v(
                          "\n                                2ème colonne "
                        ),
                        _c("span", { staticStyle: { color: "#f75b61" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          " : Date d'expiration du bon cadeau\n                                (au format JJ/MM/AAAA)\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "list-style-type-circle" }, [
                        _vm._v("3ème colonne : Nom du produit"),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "list-style-type-circle" }, [
                        _vm._v("4ème colonne : Prix TTC"),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "list-style-type-circle" }, [
                        _vm._v("5ème colonne : Nom du client"),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "list-style-type-circle" }, [
                        _vm._v("6ème colonne : Prénom du client"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "#f75b61" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(" Champs obligatoires\n                    "),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "a",
                      {
                        staticClass: "d-block",
                        attrs: { href: "/assets/bons_cadeaux.csv" },
                      },
                      [_c("small", [_vm._v("Exemple")])]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      attrs: { id: "fileInput", type: "file" },
                      on: { change: _vm.fileInputChanged },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.formErrors
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "alert alert-danger d-block position-relative",
                          },
                          _vm._l(_vm.formErrors, function (error) {
                            return _c("p", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(error) +
                                  "\n                            "
                              ),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v("\n            Fermer\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-success btn-circle ml-2",
            class: { disabled: this.file === undefined },
            attrs: { disabled: this.file === undefined },
            on: { click: _vm.sendForm },
          },
          [_vm._v("\n            Importer\n        ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }