export default {
    data() {
        return {
            COLOR_UNIQUE: {
                value: "unique",
            },
            COLOR_STATUS: {
                value: "status",
            },
        };
    },
    methods: {
        getColorTypeLabel(value) {
            const found = this.ALL_COLOR_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") {
                return this.$t(`labels.booking.seatingPlan.colors.${value}`);
            }
            return value;
        },
    },
    computed: {
        ALL_COLOR_TYPES() {
            return [this.COLOR_UNIQUE, this.COLOR_STATUS];
        },
    },
};
