var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mt-3 radio" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6 pt-2" }, [
          _c(
            "label",
            [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.$tl("labels.booking.services.footprint.autoReminder")
                  ) +
                  "\n                    "
              ),
              _c("feather", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.getTooltipNotice(
                      "notices.booking.servicesConfig.sendReminderPayment"
                    ),
                    expression:
                      "getTooltipNotice('notices.booking.servicesConfig.sendReminderPayment')",
                  },
                ],
                staticClass: "ml-1 pointer tooltip-infos",
                attrs: { type: "info" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 d-table w-100 h-100 pt-2 pl-0" }, [
          _c("div", { staticClass: "d-table-cell align-middle" }, [
            _c("label", { staticClass: "container-box" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentIsAuto,
                    expression: "currentIsAuto",
                  },
                ],
                attrs: { type: "radio" },
                domProps: {
                  value: false,
                  checked: _vm._q(_vm.currentIsAuto, false),
                },
                on: {
                  change: function ($event) {
                    _vm.currentIsAuto = false
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "checkmark" }),
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.$tl("labels.manualM")) +
                  "\n                    "
              ),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "container-box" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentIsAuto,
                    expression: "currentIsAuto",
                  },
                ],
                attrs: { type: "radio" },
                domProps: {
                  value: true,
                  checked: _vm._q(_vm.currentIsAuto, true),
                },
                on: {
                  change: function ($event) {
                    _vm.currentIsAuto = true
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "checkmark" }),
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.$tl("labels.auto")) +
                  "\n                    "
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.currentIsAuto
        ? _c("div", { staticClass: "row my-2" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("label", [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$tl("labels.booking.services.footprint.autoHour")
                    ) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6 pl-0" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-5" }, [
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      min: _vm.minHours,
                      max: "24",
                      required: "",
                    },
                    domProps: { value: _vm.modelValue.hours },
                    on: {
                      input: function ($event) {
                        return _vm.update("hours", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-7 mt-2" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$tl("labels.hour")) +
                      "\n                    "
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }