<template>
    <LoaderComponent v-if="loading" />
    <div v-else-if="error" class="alert alert-danger">
        {{ error }}
    </div>
    <button
        type="button"
        :disabled="!has_right_to_update_restaurant"
        v-else-if="can_edit_stripe && stripe_connect_url !== null"
        @click.prevent="authenticate"
        class="btn btn-success btn-sm btn-circle">
        {{ $tl("labels.form.psp.stripe.linkAccount") }}
    </button>
</template>

<script>
import defaultOptions from "../../utils/custom-vue-authenticate/options.js";
import StorageFactory from "../../utils/custom-vue-authenticate/storage.js";
import { objectExtend } from "../../utils/custom-vue-authenticate/utils.js";
import OAuth2 from "../../utils/oauth/oauth2";
import LoaderComponent from "../LoaderComponent";
import axios from "axios";
import Vue from "vue";

export default {
    components: {
        LoaderComponent,
    },
    data() {
        return {
            loading: false,
            stripe_connect_url: null,
            can_edit_stripe: false,
            error: null,
            restaurant: null,
        };
    },
    props: {
        restaurant_id: {
            default: null,
        },
    },
    created() {
        this.fetchData();
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_restaurant() {
            return this.rights.includes("default.restaurants.update");
        },
        restaurantId() {
            if (this.restaurant_id) {
                return this.restaurant_id;
            } else {
                return this.$route.params.restaurant_id;
            }
        },
    },
    methods: {
        fetchData() {
            this.error = this.restaurant = null;
            this.loading = true;

            axios
                .get(`/api/restaurants/${this.restaurantId}/stripe/control`)
                .then(() => {
                    this.can_edit_stripe = true;

                    // load stripe data
                    axios
                        .get(`/api/restaurants/${this.restaurantId}/stripe`)
                        .then((response) => {
                            this.restaurant = response.data;
                            if (!this.restaurant.stripe_client_id) {
                                axios
                                    .get(`/api/restaurants/${this.restaurantId}/stripe/connectUrl`)
                                    .then((response) => {
                                        this.stripe_connect_url = response.data;
                                    })
                                    .catch((error) => {
                                        this.error = this.getErrorMsgFromErrorResponse(error);
                                    })
                                    .finally(() => (this.loading = false));
                            } else {
                                this.loading = false;
                            }
                        })
                        .catch((error) => {
                            this.error = this.getErrorMsgFromErrorResponse(error);
                            this.loading = false;
                        });
                })
                .catch(() => {
                    this.can_edit_stripe = false;
                    this.loading = false;
                });
        },
        authenticate() {
            const options = objectExtend(defaultOptions, {
                providers: {
                    stripe: {
                        authorizationEndpoint: this.stripe_connect_url,
                        oauthType: "2.0",
                        redirectUriToWait: `${window.location.origin}/stripe-connect-redirection/done`,
                    },
                },
            });

            delete options.client_id;
            delete options.response_type;

            const storage = StorageFactory(options);

            const providerInstance = new OAuth2(Vue.prototype.$http, storage, options.providers.stripe, options);

            providerInstance.init(null).then((response) => {
                this.$nextTick(() => {
                    if (!response || !response.status || response.status === "error") {
                        if (response && response.error && response.error === "capabilities" && response.error_params) {
                            try {
                                const error_params = JSON.parse(response.error_params);
                                this.$emit("capabilities-error", error_params);
                            } catch (e) {
                                this.$emit("error", "La connexion à Stripe a échouée, veuillez essayer ultérieurement.");
                            }
                        }
                        this.$emit("error", "La connexion à Stripe a échouée, veuillez essayer ultérieurement.");
                    } else {
                        this.$emit("success");
                    }
                });
            });
        },
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
