<template>
    <div>
        <button
            type="button"
            v-tooltip="getTooltip($t('labels.booking.reservations.actions.accept'))"
            @click.prevent.stop="changeStatus(true)"
            :disabled="isDisabled"
            class="btn btn-success btn-sm btn-square">
            <feather type="check" style="pointer-events: none" />
        </button>
        <button
            type="button"
            v-tooltip="getTooltip($t('labels.booking.reservations.actions.refuse'))"
            @click.prevent.stop="changeStatus(false)"
            :disabled="isDisabled"
            class="btn btn-danger btn-sm text-white btn-square">
            <feather style="pointer-events: none" type="x" />
        </button>
        <button
            v-if="canAskFootprint"
            type="button"
            v-tooltip="getTooltip($t('labels.booking.reservations.actions.acceptFootprint'))"
            class="btn btn-warning btn-sm btn-square text-white"
            :disabled="isDisabled"
            @click.prevent.stop="askFootprint">
            <feather style="pointer-events: none" type="credit-card" />
        </button>
    </div>
</template>
<script>
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum";

export default {
    props: {
        editable: {
            type: Boolean,
            required: true,
        },
        restaurantId: {
            type: Number,
            required: true,
        },
        reservation: {
            type: Object,
            required: true,
        },
        isPaymentEnabled: {
            type: Boolean,
            required: true,
        },
    },
    mixins: [ReservationStatusEnum],
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        isDisabled() {
            return !this.editable || this.isLoading;
        },
        canAskFootprint() {
            return (
                this.isPaymentEnabled &&
                !this.reservation.option_bank &&
                this.reservation.client &&
                this.reservation.client.email &&
                this.reservation.client.noshows > 0
            );
        },
    },
    methods: {
        changeStatus(accepted) {
            if (!accepted) {
                this.$emit("displayCancelModal", this.RESERVATION_STATUS_REFUSED.value);
            } else {
                this.isLoading = true;

                this.httpPut(
                    `/api/restaurants/${this.restaurantId}/reservations/${this.reservation.id}/status?status=${this.RESERVATION_STATUS_VALIDATED.value}`,
                    {},
                    {
                        defaultMessage: this.$t("success.booking.reservations.updated"),
                        defaultErrorMessage: this.$t("errors.booking.reservations.notUpdated"),
                    }
                ).finally(() => (this.isLoading = false));
            }
        },
        askFootprint() {
            this.isLoading = true;

            this.httpPost(
                `/api/restaurants/${this.restaurantId}/reservations/${this.reservation.id}/optionBank/footprint`,
                {},
                { defaultMessage: this.$t("success.booking.footprint.sent") }
            ).finally(() => (this.isLoading = false));
        },
    },
};
</script>
