<template>
    <modal-rounded-no-header @close="closeFromModal">
        <div slot="body" v-html="content"></div>
        <div slot="footer" class="d-flex">
            <button
                type="button"
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                @click="$emit('close')">
                Annuler
            </button>
            <button
                type="button"
                class="btn btn-sm btn-success btn-circle ml-2"
                @click="$emit('confirmed', eventPayload)">
                Confirmer
            </button>
        </div>
    </modal-rounded-no-header>
</template>

<script>
import ModalRoundedNoHeader from "../base/ModalRoundedNoHeader.vue";

export default {
    props: {
        content: {
            type: String,
            required: true,
        },
        eventPayload: {
            required: true,
        },
    },
    components: {
        ModalRoundedNoHeader,
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e = null) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                if (e) {
                    e.stopPropagation();
                }
                this.close();
            }
        },
    },
};
</script>
