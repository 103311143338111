<template>
    <div class="border-light b-radius-20 p-4">
        <div class="row" :class="{ pointer: expandable }" @click="toggleIsExpanded">
            <div class="col-12">
                <strong>{{ $tl("labels.googleReserve.title") }}</strong>
            </div>
        </div>
        <div v-show="isExpanded" class="mt-3">
            <switch-input-component v-model="settings.enabled" :disabled="isDisabled" :switch-tooltip="disabledReasonTooltip">
                <template v-slot:label>
                    <div>
                        <template v-if="forServices">{{ $tl("labels.googleReserve.service.enable") }}</template>
                        <template v-else>{{ $tl("labels.googleReserve.widget.enable") }}</template>
                        <feather
                            v-tooltip="getTooltip($t('infos.booking.googleReserve.forRestaurantsOnly'))"
                            type="alert-triangle"
                            class="ml-1 pointer text-danger" />
                        <feather
                            v-if="forWidget"
                            v-tooltip="getTooltipNotice('notices.googleReserve.enable')"
                            type="info"
                            class="ml-1 pointer tooltip-infos" />
                    </div>
                    <small class="text-muted">{{ $tl("labels.googleReserve.propagation") }}</small
                    ><br />
                    <small class="text-muted">{{ $tl("labels.googleReserve.maxPax", null, { pax: MAX_PAX }) }}</small>
                </template>
            </switch-input-component>

            <div v-if="forWidget" v-show="settings.enabled">
                <div class="row">
                    <div class="col-md-5 col-sm-6">
                        {{ $tl("labels.googleReserve.texts.title") }}
                        <feather v-tooltip="getTooltipNotice('notices.googleReserve.texts')" type="info" class="ml-1 pointer tooltip-infos" />
                    </div>

                    <choose-lang
                        class="col-md-7 col-sm-6"
                        style="z-index: auto !important"
                        v-model="currentTextLocale"
                        is-input-mode
                        should-translate-label>
                    </choose-lang>
                </div>

                <show-errors :errors="formErrors" error-key="google_reserve_texts" />

                <div class="row mt-2">
                    <div class="col-md-5">{{ getGrLocalizedTextLabel(GR_IMPORTANT_NOTE_TEXT.value) }}</div>
                    <textarea-component
                        class="col-md-7"
                        v-model="importantNote"
                        :rows="3"
                        :max-nb-chars="300"
                        :disabled="isDisabled"></textarea-component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SwitchInputComponent from "../forms/SwitchInputComponent.vue";
import InputComponent from "../forms/InputComponent.vue";
import ChooseLang from "../Default/ChooseLang.vue";
import LangsEnum from "../../mixins/enums/LangsEnum.js";
import GoogleReserveLocalizedTextsEnum from "../../mixins/enums/GoogleReserveLocalizedTextsEnum.js";
import editorWithMergeTags from "../forms/editorWithMergeTags.vue";
import ShowErrors from "../errors/ShowErrors.vue";
import TextareaComponent from "../forms/TextareaComponent.vue";

export default {
    data() {
        return {
            expanded: false,
            currentTextLocale: null,
            currentTexts: [],
            hasOptedIn: null,
        };
    },
    mixins: [LangsEnum, GoogleReserveLocalizedTextsEnum],
    props: {
        settings: {
            type: Object,
            required: true,
        },
        formErrors: {
            type: Array,
            default: null,
        },
        expandable: {
            type: Boolean,
            default: false,
        },
        forWidget: {
            type: Boolean,
            default: false,
        },
        forServices: {
            type: Boolean,
            default: false,
        },
        restaurantId: {
            type: Number,
            default: null,
        },
    },
    computed: {
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_widget() {
            return this.rights.includes("booking.widget.update");
        },
        currentRestaurantId() {
            return this.restaurantId || Number.parseInt(this.$route.params.restaurant_id);
        },
        currentRestaurant() {
            return this.$store.getters["restaurants/findRestaurantById"](this.currentRestaurantId);
        },
        hasValidGoogleMapsAddress() {
            return typeof this.currentRestaurant !== "undefined" && this.currentRestaurant.google_maps_address !== null;
        },
        hasFeatReserveWithGoogle() {
            return typeof this.currentRestaurant !== "undefined" && this.currentRestaurant.feat_reserve_with_google;
        },
        isDisabled() {
            if (!this.forWidget) {
                return false;
            }

            return !this.has_right_to_update_widget || !this.hasValidGoogleMapsAddress || !this.hasFeatReserveWithGoogle || !this.hasOptedIn;
        },
        disabledReasonTooltip() {
            if (!this.isDisabled) {
                return undefined;
            }

            if (!this.hasFeatReserveWithGoogle) {
                return this.getTooltip(this.$tl("infos.booking.googleReserve.forRestaurantsOnly", this.currentRestaurantId));
            }

            if (!this.hasValidGoogleMapsAddress) {
                return this.getTooltip(this.$tl("infos.booking.googleReserve.mustHaveGoogleAddress", this.currentRestaurantId));
            }

            if (this.hasOptedIn === false) {
                return this.getTooltip(this.$tl("infos.booking.googleReserve.mustOptIn", this.currentRestaurantId));
            }

            return undefined;
        },
        isExpanded: {
            get() {
                return this.expandable ? this.expanded : true;
            },
            set(newVal) {
                this.expanded = newVal;
            },
        },
        importantNote: {
            get() {
                const text = this.getText(this.currentTextLocale, this.GR_IMPORTANT_NOTE_TEXT.value);

                return text !== undefined ? text.value : null;
            },
            set(newVal) {
                this.getText(this.currentTextLocale, this.GR_IMPORTANT_NOTE_TEXT.value).value = newVal;
            },
        },
    },
    methods: {
        getDefaultText(locale, type) {
            return {
                id: null,
                locale,
                type,
                value: "",
            };
        },
        getText(locale, type, texts = undefined) {
            return (texts || this.currentTexts).find((text) => text.locale === locale && text.type === type);
        },
        toggleIsExpanded() {
            if (!this.expandable) {
                return;
            }

            this.isExpanded = !this.isExpanded;
        },
        initData() {
            this.fetchHasOptedIn();

            if (this.forWidget) {
                this.currentTextLocale =
                    this.settings.texts.length < 1 || this.settings.texts.some((text) => text.locale === this.userLang)
                        ? this.userLang
                        : this.settings.texts[0].locale;

                this.ALL_LANGS.forEach((langEnum) => {
                    this.ALL_GR_LOCALIZED_TEXTS.forEach((localizedTextEnum) => {
                        const found = this.getText(langEnum.value, localizedTextEnum.value, this.settings.texts);

                        if (typeof found === "undefined") {
                            this.currentTexts.push(this.getDefaultText(langEnum.value, localizedTextEnum.value));
                        } else {
                            this.currentTexts.push(found);
                        }
                    });
                });
            }
        },
        fetchHasOptedIn() {
            this.httpGet(`/api/reserve_with_google/${this.currentRestaurantId}/has_opted_in`).then((response) => {
                if (response !== false) {
                    this.hasOptedIn = response.data.has_opted_in;
                }
            });
        },
    },
    created() {
        this.initData();

        this.MAX_PAX = 15;
    },
    watch: {
        currentTexts: {
            deep: true,
            handler(newVal) {
                this.settings.texts = newVal.filter((text) => text.value !== "");
            },
        },
    },
    components: {
        TextareaComponent,
        SwitchInputComponent,
        InputComponent,
        ChooseLang,
        editorWithMergeTags,
        ShowErrors,
    },
};
</script>
