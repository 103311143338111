export default {
    booking: {
        closures: {
            services: "Game(s)",
            concernedServices: "Games involved",
            noServiceForDate: "No games available for the dates selected",
        },
        services: {
            add: "Add a game",
            name: "Game name",
            edit: "Edit the game:",
            duplicate: "Duplicate a game",
            next: "Next game",
            live: "Game in progress",
            createFirst: "Create my first game",
            noOneAvailable: "No game is available",
            startHour: "Game start time",
            endHour: "Game end time",
            inCaseFull: "When the game is full",
            takeResaLive: "Take reservations during the game",
            minsBeforeStart: "minutes before the start of the game",
            servicesOnDate: "Gamees on {date}:",
            showRestaurantService: "Offer the customer games at the following establishment:",
            recap: "Gamee summary",
            editDayMaxPax: "Edit game max pax for given day",
            emptyForRestaurant: "No game for this establishment",
            showOnDashboardLong: "Show game on dashboard",
        },
        reservations: {
            editService: "Edit game",
        },
        stats: {
            filters: {
                serviceCategory: "Game category",
            },
        },
        widget: {
            automaticExport: {
                nbMinutes: "Schedule export before game (in minutes)",
                services: "Choose linked games",
            },
        },
        restaurant: {
            closedForSomeService: "Establishment closed today for some games.",
        },
        list: {
            emptyForService: "You have no reservations for this game.",
        },
        seatingPlan: {
            emptyForService: 'You have no reservations today for the "{service}" game.',
            room: {
                disabledForService: "This room is disabled for this game",
                enabledForService: "This room is enabled for this game only",
            },
            columns: {
                services: "Games",
            },
        },
    },
    routes: {
        booking: {
            config: {
                services: "Games",
            },
            services: {
                current: "Current games",
            },
        },
    },
    notitifications: {
        booking: {
            serviceOver: "Waiting list - game over",
        },
    },
};
