export default {
    data() {
        return {
            COLLECT_TYPE_COLLECT: {
                value: "collect",
                label: "Retrait",
            },
            COLLECT_TYPE_DELIVERY: {
                value: "delivery",
                label: "Livraison",
            },
            COLLECT_TYPE_DISPATCH: {
                value: "dispatch",
                label: "Expédition",
            },
        };
    },
    methods: {
        getCollectTypeLabel(value) {
            const found = this.ALL_COLLECT_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_COLLECT_TYPES() {
            return [this.COLLECT_TYPE_COLLECT, this.COLLECT_TYPE_DELIVERY, this.COLLECT_TYPE_DISPATCH];
        },
    },
};
