<template>
    <div v-if="services.length > 0">
        <div class="row mb-1">
            <div class="col-12">
                <strong>{{ $tl("labels.clients.history") }}</strong>
                <small class="d-block text-muted">({{ $tc("labels.clients.historyOn", keepClientsDetailsAndHistoryFor) }})</small>
            </div>
        </div>
        <ul class="settings-menu nav nav-tabs flex-row top mb-2" style="position: relative; top: 0">
            <li class="nav-item" v-for="service in services" :key="service.id" @click="currentService = service.id">
                <a class="nav-link" :class="{ 'router-link-active': service.id === currentService }" style="color: #6c757d">
                    {{ service.label }}
                </a>
            </li>
        </ul>
        <BookingHistory v-if="currentService === 0" :reservations="reservations" @show-resa="$emit('show-resa', $event)" />
        <ClickAndCollectHistory v-if="currentService === 1" :cc_commands="cc_commands" @show-cc-command="$emit('show-cc-command', $event)" />
        <GiftVouchersHistory v-if="currentService === 2" :gv_sales="gv_sales" @show-gv-sale="$emit('show-gv-sale', $event)" />
    </div>
</template>

<script>
import BookingHistory from "./ClientHistory/BookingHistory.vue";
import ClickAndCollectHistory from "./ClientHistory/ClickAndCollectHistory.vue";
import GiftVouchersHistory from "./ClientHistory/GiftVouchersHistory.vue";
import ModuleTypesEnum from "../mixins/enums/ModuleTypesEnum.js";

export default {
    data() {
        return {
            currentService: 0,
        };
    },
    mixins: [ModuleTypesEnum],
    props: {
        reservations: {
            type: Array,
            default: () => {
                return [];
            },
        },
        cc_commands: {
            type: Array,
            default: () => {
                return [];
            },
        },
        gv_sales: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    computed: {
        services() {
            return [
                {
                    id: 0,
                    label: this.getModuleTypeLabel(this.MODULE_TYPE_BOOKING.value),
                    dataKey: "reservations",
                },
                {
                    id: 1,
                    label: this.getModuleTypeLabel(this.MODULE_TYPE_CLICK_AND_COLLECT.value),
                    dataKey: "cc_commands",
                },
                {
                    id: 2,
                    label: this.getModuleTypeLabel(this.MODULE_TYPE_GIFT_VOUCHERS.value),
                    dataKey: "gv_sales",
                },
            ].filter((service) => this[service.dataKey] && this[service.dataKey].length > 0);
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        keepClientsDetailsAndHistoryFor() {
            return this.$store.getters["users/keepClientsDetailsAndHistoryFor"];
        },
    },
    created() {
        if (this.reservations.length > 0) {
            this.currentService = 0;
        } else if (this.cc_commands.length > 0) {
            this.currentService = 1;
        } else if (this.gv_sales.length > 0) {
            this.currentService = 2;
        }
    },
    components: {
        BookingHistory,
        ClickAndCollectHistory,
        GiftVouchersHistory,
    },
};
</script>
