<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.clients.import.title") }} - {{ $t("labels.stepX", { step }) }} - {{ stepName }}</h5>
        <div slot="body">
            <LoaderComponent v-if="loading || loadingImport" :message="loadingImport ? $tl('labels.clients.import.inProgress') : undefined" />
            <div class="row" v-show="!loading && !loadingImport">
                <div class="col-12">
                    <Step1 v-show="step === 1" class="border-light b-radius-20 p-4" ref="step1Component" :file="file" @set-data="setData" />
                    <Step2
                        v-show="step === 2"
                        class="border-light b-radius-20 p-4"
                        ref="step2Component"
                        :firstLines="firstLines"
                        :file="file"
                        @set-data="setData"
                        @import-done="close" />
                </div>
            </div>
        </div>
        <div slot="footer" class="d-flex">
            <button class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.close") }}
            </button>
            <button
                class="btn btn-sm btn-secondary btn-circle"
                v-if="step > 1"
                :class="{ disabled: btnDisabled }"
                :disabled="btnDisabled"
                @click="previousStep">
                {{ $tl("labels.form.actions.previous") }}
            </button>
            <button class="btn btn-sm btn-success btn-circle ml-2" :class="{ disabled: btnDisabled }" :disabled="btnDisabled" @click="nextStep">
                {{ step < 2 ? $tl("labels.form.actions.next") : $tl("labels.form.actions.import") }}
            </button>
        </div>
    </modal>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import Step1 from "./ImportSteps/FileInfo.vue";
import Step2 from "./ImportSteps/SelectColumns.vue";

export default {
    data() {
        return {
            loading: false,
            loadingImport: false,
            step: 1,
            file: undefined,
            firstLines: [],
        };
    },
    computed: {
        btnDisabled() {
            return this.loading || !this.file;
        },
        stepName() {
            const key = `labels.clients.import.steps.${this.step}.title`;

            if (this.$te(key)) {
                return this.$tl(key);
            }

            return this.$tl("errors.common.unknown");
        },
    },
    methods: {
        setData(event) {
            if (event.key) this.$set(this, event.key, event.value);
        },
        previousStep() {
            if (this.step === 2) {
                this.firstLines = [];
                this.$refs.step2Component.resetAssociations();
                this.step--;
            }
        },
        nextStep() {
            if (this.step === 1) {
                this.$refs.step1Component.validateFile().then((result) => {
                    if (result) {
                        this.step++;
                    }
                });
            } else if (this.step === 2) {
                this.$refs.step2Component.post();
            }
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (!e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        LoaderComponent,
        Step1,
        Step2,
    },
};
</script>
