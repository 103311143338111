<template>
    <div class="container col-12">
        <div v-if="restaurants.length <= sizeForAlphabeticGroups" class="d-flex flex-column flex-wrap flex-md-row">
            <label
                v-for="restaurant in sortElementsForGrid(sortedRestaurants, 3)"
                :key="restaurant.id"
                class="container-box selected__establishment col-4 mr-0">
                <input
                    type="checkbox"
                    :value="restaurant.id"
                    :checked="localSelectedRestaurants.includes(restaurant.id)"
                    @change="toggleRestaurant(restaurant.id)" />
                <span class="checkmark"></span>
                {{ restaurant.name }}
            </label>
        </div>
        <div v-else v-for="(restaurants, range) in alphabeticGroups" :key="range">
            <h5 class="text-success mb-4 font-weight-bold">{{ range }}</h5>
            <label class="container-box selected__establishment col-4" v-for="restaurant in sortElementsForGrid(restaurants, 3)" :key="restaurant.id">
                <input
                    type="checkbox"
                    :value="restaurant.id"
                    :checked="localSelectedRestaurants.includes(restaurant.id)"
                    @change="toggleRestaurant(restaurant.id)" />
                <span class="checkmark"></span>
                {{ restaurant.name }}
            </label>
            <div class="sepa"></div>
        </div>
    </div>
</template>

<script>
import { sortElementsForGrid } from "../../../../utils/calcNbElementsPerColumn.js";

export default {
    name: "RestaurantList",
    props: {
        restaurants: {
            type: Array,
            required: true,
        },
        selectedRestaurants: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            localSelectedRestaurants: [...this.selectedRestaurants],
            sizeForAlphabeticGroups: 10,
        };
    },
    computed: {
        sortedRestaurants() {
            return [...this.restaurants].sort((a, b) => a.name.localeCompare(b.name));
        },
        alphabeticGroups() {
            const sortedRestaurants = this.sortedRestaurants;

            const groups = {};
            const letterCount = {};
            const letterRestaurants = {};

            sortedRestaurants.forEach((restaurant) => {
                const firstLetter = restaurant.name[0].toUpperCase();

                if (letterCount[firstLetter]) {
                    letterCount[firstLetter] += 1;
                } else {
                    letterCount[firstLetter] = 1;
                }

                if (!letterRestaurants[firstLetter]) {
                    letterRestaurants[firstLetter] = [];
                }
                letterRestaurants[firstLetter].push(restaurant);
            });

            let maxGroupSize = Math.max(...Object.values(letterCount));

            let currentGroup = [];
            let currentGroupSize = 0;
            const sortedLetters = Object.keys(letterCount).sort();

            sortedLetters.forEach((letter) => {
                const restaurants = letterRestaurants[letter];

                if (currentGroupSize + restaurants.length <= maxGroupSize) {
                    currentGroup = currentGroup.concat(restaurants);
                    currentGroupSize += restaurants.length;
                } else {
                    let startLetter = currentGroup[0].name[0].toUpperCase();
                    let endLetter = currentGroup[currentGroup.length - 1].name[0].toUpperCase();

                    let range = startLetter === endLetter ? startLetter : startLetter + "-" + endLetter;

                    groups[range] = currentGroup;

                    currentGroup = restaurants;
                    currentGroupSize = restaurants.length;
                }
            });

            if (currentGroup.length > 0) {
                let startLetter = currentGroup[0].name[0].toUpperCase();
                let endLetter = currentGroup[currentGroup.length - 1].name[0].toUpperCase();

                let range = startLetter === endLetter ? startLetter : startLetter + "-" + endLetter;

                groups[range] = currentGroup;
            }

            return groups;
        },
    },
    methods: {
        toggleRestaurant(restaurantId) {
            const index = this.localSelectedRestaurants.indexOf(restaurantId);

            if (index === -1) {
                this.localSelectedRestaurants.push(restaurantId);
            } else {
                this.localSelectedRestaurants.splice(index, 1);
            }

            this.$emit("update:selected-restaurants", [...this.localSelectedRestaurants]);
        },
        sortElementsForGrid(x, y) {
            return sortElementsForGrid(x, y);
        },
    },
    watch: {
        selectedRestaurants(newVal) {
            this.localSelectedRestaurants = [...newVal];
        },
    },
};
</script>

<style lang="scss">
.selected__establishment {
    width: 30%;
}

@media screen and (max-width: 767px) {
    .selected__establishment {
        width: 100%;
        max-width: 100%;
    }
}
</style>
