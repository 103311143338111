var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "border-light d-flex justify-content-between p-3 align-items-center",
    },
    [
      _c("div", [
        _c("span", [_vm._v(_vm._s(_vm.paidAt))]),
        _vm._v(" "),
        _c(
          "h5",
          { staticClass: "mb-0", staticStyle: { "line-height": "1.6" } },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t("labels.invoices.number", {
                    number: _vm.invoice.number,
                  })
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", [
          _vm._v(_vm._s(_vm.formatCurrency(_vm.invoice.amount / 100))),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            _vm._s(_vm.$t("labels.invoices.smsPack", { nb: _vm.packSize }))
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "a",
          {
            staticClass: "btn btn-sm btn-circle btn-success",
            attrs: { target: "_blank", href: _vm.downloadLink },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.download")) +
                "\n        "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }