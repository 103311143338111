<template>
    <!--WARN: tabindex is required to allow blur event working -->
    <div
        ref="wrapper"
        class="show-custom-events bg-white p-2"
        tabindex="0"
        :class="{ 'show-custom-events-close': !isOpen, 'show-custom-events-open': isOpen }"
        @blur="closeFromBlur">
        <loader-component v-if="loading.fetchCustomEvents || loading.fetchNameday"></loader-component>
        <template v-else>
            <div>
                <div class="text-center">
                    <div class="d-flex justify-content-between">
                        <feather class="pointer" type="x" @click="close" />
                        <div>
                            <div>{{ capitalize(displayDate(date, DATE_HUGE)) }}</div>
                            <div v-if="namedayInfo.nameday !== null" class="text-uppercase">{{ namedayInfo.nameday }}</div>
                            <div v-if="namedayInfo.isPublicHoliday" class="badge badge-danger text-uppercase">
                                {{ $tl("labels.dates.publicHoliday") }}
                            </div>
                        </div>
                        <div>
                            <button type="button" class="btn btn-success btn-sm rounded" @mousedown.prevent @click="addCustomEvent">
                                <feather type="plus" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2" v-for="customEvent in customEvents" :key="customEvent.id">
                <div class="bg-light rounded custom-event-card">
                    <div
                        class="d-flex align-items-center justify-content-between text-white rounded p-1"
                        :style="`background-color: ${getCustomEventPriorityColor(customEvent.priority)}`">
                        <div>
                            {{ customEvent.title }} - {{ displayDate(customEvent.date_begin, { ...DATE_SHORT, year: undefined }) }}
                            <template v-if="customEvent.date_begin !== customEvent.date_end">
                                {{ $tl("labels.dates.to") }} {{ displayDate(customEvent.date_end, { ...DATE_SHORT, year: undefined }) }}
                            </template>
                        </div>
                        <div class="d-flex align-items-center">
                            <button
                                v-if="customEvent.visibility === CUSTOM_EVENT_VISIBILITY_EXTERNAL.value"
                                type="button"
                                class="btn btn-default btn-sm cursor-default"
                                v-html="customEventBellIcon"
                                v-tooltip="getTooltip(getCustomEventVisibilityLabel(CUSTOM_EVENT_VISIBILITY_EXTERNAL.value))"></button>
                            <button
                                type="button"
                                class="btn btn-default btn-sm"
                                :disabled="loading.destroy.includes(customEvent.id)"
                                @mousedown.prevent
                                @click="editCustomEvent(customEvent.id)">
                                <feather class="text-white" type="edit" />
                            </button>
                            <button
                                type="button"
                                class="btn btn-default btn-sm"
                                :disabled="loading.destroy.includes(customEvent.id)"
                                @mousedown.prevent
                                @click="destroyCustomEvent(customEvent)">
                                <feather class="text-white" type="trash-2" />
                            </button>
                        </div>
                    </div>
                    <div class="p-1">
                        {{ customEvent.description }}
                    </div>
                </div>
            </div>
        </template>

        <show-add-custom-event-modal
            v-if="showAddCustomEventModal"
            :restaurantId="restaurantId"
            :date="date"
            :customEventId="customEventEditId"
            @custom-event-added="onCustomEventAdded"
            @custom-event-edited="onCustomEventEdited"
            @close="closeAddCustomEventModal" />
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import CustomEventPriorityEnum from "../../mixins/enums/booking/CustomEventPriorityEnum.js";
import CustomEventVisibilityEnum from "../../mixins/enums/booking/CustomEventVisibilityEnum.js";
import showAddCustomEventModal from "../Modals/customEvents/customEventFormModal.vue";
import getCustomEventBell from "../../icons/customEventBell.js";

export default {
    data() {
        return {
            loading: {
                fetchCustomEvents: false,
                fetchNameday: false,
                destroy: [],
            },
            customEvents: [],
            namedayInfo: {
                nameday: null,
                isPublicHoliday: false,
            },
            showAddCustomEventModal: false,
            customEventEditId: undefined,
            isOpen: false,
            disableBlurClose: false,
        };
    },
    mixins: [CustomEventPriorityEnum, CustomEventVisibilityEnum],
    props: {
        restaurantId: {
            type: Number,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
    },
    computed: {
        customEventBellIcon() {
            return getCustomEventBell({ fill: "#FFF", width: "14px", height: "14px" });
        },
    },
    methods: {
        onCustomEventAdded() {
            this.$store.dispatch("restaurants/fetchAllRestaurants", { store: true });

            this.fetchCustomEvents();
        },
        onCustomEventEdited() {
            this.$store.dispatch("restaurants/fetchAllRestaurants", { store: true });

            this.fetchCustomEvents();
        },
        closeAddCustomEventModal() {
            this.showAddCustomEventModal = false;

            this.$refs.wrapper.focus();
        },
        addCustomEvent() {
            this.customEventEditId = undefined;

            this.$nextTick(() => (this.showAddCustomEventModal = true));
        },
        editCustomEvent(customEventId) {
            this.customEventEditId = customEventId;

            this.$nextTick(() => (this.showAddCustomEventModal = true));
        },
        fetchData() {
            if (this.restaurantId !== null && this.date !== null) {
                this.fetchCustomEvents();
                this.fetchNameday();
            }
        },
        fetchCustomEvents() {
            this.loading.fetchCustomEvents = true;

            this.httpGet(`/api/restaurants/${this.restaurantId}/customEvents?date=${this.date}`)
                .then((response) => {
                    if (response !== false) {
                        this.customEvents = response.data.data;
                    }
                })
                .finally(() => (this.loading.fetchCustomEvents = false));
        },
        fetchNameday() {
            this.loading.fetchNameday = true;

            this.httpGet(`/api/restaurants/${this.restaurantId}/nameday?date=${this.date}`, { handleReject: false })
                .then((response) => {
                    if (response !== false) {
                        this.namedayInfo = response.data;
                    }
                })
                .finally(() => (this.loading.fetchNameday = false));
        },
        destroyCustomEvent(customEvent) {
            this.disableBlurClose = true;

            if (!confirm(this.$t("questions.booking.customEvents.delete", { event: customEvent.title }))) {
                setTimeout(() => {
                    this.$nextTick(() => {
                        this.$refs.wrapper.focus();
                        this.$nextTick(() => {
                            this.disableBlurClose = false;
                        });
                    });
                }, 100);

                return;
            }

            this.loading.destroy.push(customEvent.id);

            this.httpDelete(`/api/restaurants/${this.restaurantId}/customEvents/${customEvent.id}`, {
                defaultMessage: this.$tl("success.booking.customEvents.deleted"),
            })
                .then((response) => {
                    if (response !== false) {
                        const index = this.customEvents.findIndex((ce) => ce.id === customEvent.id);

                        if (index !== -1) {
                            this.customEvents.splice(index, 1);
                        }

                        this.$store.dispatch("restaurants/fetchAllRestaurants", { store: true });
                    }
                })
                .finally(() => {
                    const index = this.loading.destroy.findIndex((id) => id === customEvent.id);

                    if (index !== -1) {
                        this.loading.destroy.splice(index, 1);
                    }

                    this.$nextTick(() => {
                        this.disableBlurClose = false;
                        this.$refs.wrapper.focus();
                    });
                });
        },
        closeFromBlur() {
            if (!this.showAddCustomEventModal && !this.disableBlurClose) {
                this.close();
            }
        },
        close() {
            this.isOpen = false;
        },
        open() {
            this.isOpen = true;

            this.fetchData();

            this.$refs.wrapper.focus();
        },
    },
    components: {
        LoaderComponent,
        showAddCustomEventModal,
    },
};
</script>

<style scoped>
.show-custom-events {
    position: fixed;
    width: 360px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1050;
    overflow-y: auto;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.13);
}

.show-custom-events:focus {
    outline: none !important;
}

.show-custom-events.show-custom-events-close {
    right: -360px;
    transition-property: right;
    transition-duration: 0.5s;
}

.show-custom-events.show-custom-events-open {
    right: 0;
    transition-property: right;
    transition-duration: 0.5s;
}

.show-custom-events .custom-event-card {
    height: 10rem;
    overflow: auto;
}
</style>
