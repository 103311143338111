<template>
    <div>
        <div class="row m-0">
            <div class="col-12 my-3">
                <h5 class="title mt-2 ml-2 pt-2">{{ $tl("labels.notifications.listNotificationsLogs.title") }}</h5>
            </div>
            <div v-if="!has_right_to_read_logmails" class="col-12 my-3">
                <p>{{ $tl("errors.common.rights.notEnough") }}</p>
            </div>
            <div v-else class="col-12 my-3" style="color: #666666">
                <div class="col-12 border-light b-radius-20 py-4 px-xl-4">
                    <div class="col-12">
                        <div class="col-12 d-xl-flex justify-content-between align-items-center date-resa-cal">
                            <div class="col d-xl-flex">
                                <div class="d-flex align-items-center relative my-2 mr-2">
                                    <span class="mr-1">{{ $tl("labels.filters.dates.from") }}</span>
                                    <DatePicker
                                        class="d-inline-block"
                                        input-class="form-control"
                                        :format="DATE_SHORT"
                                        can-be-empty
                                        :showClearButton="true"
                                        v-model="currentFilters.from"
                                        :disabled-dates="disabledDatesFrom" />
                                </div>
                                <div class="d-flex align-items-center relative my-2 mr-2">
                                    <span class="mr-1">{{ $tl("labels.filters.dates.to").toLowerCase() }}</span>
                                    <DatePicker
                                        class="d-inline-block"
                                        input-class="form-control"
                                        :format="DATE_SHORT"
                                        can-be-empty
                                        :showClearButton="true"
                                        v-model="currentFilters.to"
                                        :disabled-dates="disabledDatesTo" />
                                </div>
                            </div>

                            <div v-if="currentLogMailTypes.length > 0" class="col my-2 d-xl-flex align-items-xl-center">
                                {{ $tl("labels.notifications.listNotificationsLogs.mailType") }}
                                <select
                                    v-model="currentFilters.notifications"
                                    class="custom-select d-inline-block ml-2 mr-2 mb-0 w-50"
                                    style="width: initial">
                                    <option v-if="currentLogMailTypes.length > 1" :value="null">--</option>
                                    <option v-for="mailLog in currentLogMailTypes" :key="mailLog.value" :value="mailLog.value">
                                        {{ $tl("labels.notifications.mail") }} {{ getReservationMailLogLabel(mailLog.value) }}
                                    </option>
                                </select>
                            </div>
                            <div class="col my-2 d-xl-flex align-items-xl-center">
                                {{ $tl("labels.filters.status") }}
                                <select v-model="currentFilters.status" class="custom-select d-inline-block ml-2 mr-2 mb-0" style="width: initial">
                                    <option :value="null">--</option>
                                    <option v-for="logStatus in ALL_LOG_MAIL_STATUS" :key="logStatus.value" :value="logStatus.value">
                                        {{ $tl(`labels.notifications.listNotificationsLogs.${logStatus.keyLabel}`) }}
                                    </option>
                                </select>
                            </div>
                            <div class="col my-2 d-xl-flex">
                                <button class="btn btn-sm btn-success btn-circle ml-2" type="button" @click="toggleFilters(false)">
                                    <feather class="icon bg-none" type="filter" size="20" />
                                    {{ $tl("labels.form.actions.filter") }}
                                </button>
                                <button class="btn btn-sm btn-secondary btn-circle ml-2" type="button" @click="toggleFilters(true)">
                                    <feather class="icon bg-none" type="rotate-ccw" size="20" />
                                    {{ $tl("labels.form.actions.reset") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="has_right_to_read_logmails" class="col notifications-logs-grid ns-pb-mobile">
                <loader-component v-if="isLoading" />
                <template v-else>
                    <div v-if="moduleType === MODULE_TYPE_BOOKING.value">
                        <switch-input-component :label="$tl('labels.booking.waitings.mailLogs.see')" v-model="isWaitingsMode" :is-form-grid="false" />
                    </div>
                    <ns-table
                        v-if="component.main"
                        tableClass="table table-striped overflowx-auto mr-3 table-responsive-xl"
                        :data-source="mailLogs"
                        :toolbar-settings="toolbarSettings"
                        is-server-side
                        allow-paging
                        :clickable="component.modal !== null"
                        use-slot-headings
                        use-slot-body
                        :pagination="pagination"
                        @update-data="updateDataFromEvent($event)"
                        @row-selected="rowSelected"
                        :current-sort="queries.sort">
                        <template v-slot:head>
                            <component :is="`${component.main}-headings`" :is-waitings-mode="isWaitingsMode" />
                        </template>

                        <template v-slot:body="{ filteredDataSource, clickable, keyColumn }">
                            <component
                                :is="`${component.main}-body`"
                                :is-waitings-mode="isWaitingsMode"
                                :filtered-data-source="filteredDataSource"
                                :restaurant-id="restaurantId"
                                :clickable="clickable"
                                :key-column="keyColumn"
                                :is-fragment-loading="isFragmentLoading"
                                :is-disabled="isDisabled"
                                @send-mail="sendMail" />
                        </template>
                    </ns-table>
                </template>
            </div>
            <component
                v-if="component.modal && showModal"
                :is="component.modal"
                :reservation_id="selectedItemId"
                :command_id="selectedItemId"
                :sale_id="selectedItemId"
                :restaurant_id="restaurantId"
                :must-not-emit="true"
                :show-edit-button="false"
                @close="showModal = false" />
        </div>
    </div>
</template>
<script>
import DatePicker from "../../../components/forms/DatePicker.vue";
import NsTable from "../../../components/Datatable/NsTable.vue";
import NsTh from "../../../components/Datatable/NsTh.vue";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import ShowReservationModal from "../../../components/Modals/reservations/ShowReservationModal.vue";
import ShowCommandModal from "../../../components/Modals/clickAndCollect/ShowClickAndCollectCommand.vue";
import showGiftVoucherModal from "../../../components/Modals/giftVouchers/showGiftVoucherModal.vue";
import ReservationMailLogEnum from "../../../mixins/enums/booking/ReservationMailLogEnum";
import LogMailStatusEnum from "../../../mixins/enums/LogMailStatusEnum";
import ReservationMailLogsHeadings from "../../../components/notifications/datatable/headings/reservations/ReservationMailLogsHeadings.vue";
import ReservationMailLogsBody from "../../../components/notifications/datatable/body/reservations/ReservationMailLogsBody.vue";
import CcCommandMailLogsHeadings from "../../../components/notifications/datatable/headings/click-and-collect/CcCommandMailLogsHeadings.vue";
import CcCommandMailLogsBody from "../../../components/notifications/datatable/body/click-and-collect/CcCommandMailLogsBody.vue";
import GvSaleMailLogsHeadings from "../../../components/notifications/datatable/headings/gift-vouchers/GvSaleMailLogsHeadings.vue";
import GvSaleMailLogsBody from "../../../components/notifications/datatable/body/gift-vouchers/GvSaleMailLogsBody.vue";
import { DateTime } from "luxon";
import SaleMailLogEnum from "../../../mixins/enums/gift_vouchers/SaleMailLogEnum";
import CommandMailLogEnum from "../../../mixins/enums/click_and_collect/CommandMailLogEnum";
import ClientCivilityEnum from "../../../mixins/enums/ClientCivilityEnum";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";
import CollectTypesEnum from "../../../mixins/enums/click_and_collect/CollectTypesEnum.js";
import SwitchInputComponent from "../../../components/forms/SwitchInputComponent.vue";

export default {
    data() {
        return {
            isFragmentLoading: {},
            isLoading: false,
            isDisabled: false,
            showModal: false,
            selectedItemId: null,
            attribute: "",
            component: {
                main: null,
                modal: null,
            },
            queries: {
                page: 1,
                per_page: 12,
                sort: {
                    field: null,
                    order: null,
                },
                filters: {
                    notifications: null,
                    status: null,
                    from: null,
                    to: null,
                },
            },
            currentFilters: {
                notifications: null,
                status: null,
                from: null,
                to: null,
            },
            pagination: {
                count: 0,
                current_page: 1,
                per_page: 12,
                total: 0,
                total_pages: 1,
            },
            mailLogs: [],
            url: {
                prefix: "api",
                module: "reservations",
                includeValues: "reservation.client,reservation.slot",
            },
            isWaitingsMode: false,
        };
    },
    mixins: [ReservationMailLogEnum, LogMailStatusEnum, SaleMailLogEnum, CommandMailLogEnum, ClientCivilityEnum, ModuleTypesEnum, CollectTypesEnum],
    computed: {
        currentLogMailTypes() {
            if (this.attribute === "cc_command") {
                return this.ALL_CC_MAIL_LOGS_TYPES;
            }

            if (this.attribute === "gv_sale") {
                return this.ALL_GV_MAIL_LOGS_TYPES;
            }

            if (this.attribute === "reservation") {
                return this.ALL_MAIL_LOGS_TYPES;
            }

            return [];
        },
        baseURL() {
            return `/${this.url.prefix}/restaurants/${this.restaurantId}/${this.attribute}s`;
        },
        fetchURL() {
            return `${this.baseURL}/mailLogs?include=${this.url.includeValues}`;
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        disabledDatesFrom() {
            const disabledDates = { from: new Date() };

            if (this.currentFilters.to !== null) {
                disabledDates.from = this.currentFilters.to.toJSDateCustom();
            }

            return disabledDates;
        },
        disabledDatesTo() {
            const disabledDates = { from: new Date() };

            if (this.currentFilters.from !== null) {
                disabledDates.to = this.currentFilters.from.toJSDateCustom();
            }

            return disabledDates;
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        has_right_to_export_logmails() {
            return this.rights.includes("default.notificationlogs.export");
        },
        has_right_to_read_logmails() {
            return this.rights.includes("default.notificationlogs.read");
        },
        allowExport() {
            return this.has_right_to_export_logmails && this.mailLogs.length > 0;
        },
        moduleType() {
            return this.$route.params.module;
        },
        toolbarSettings() {
            let toolbarSetings = {
                items: [],
            };

            if (this.allowExport) {
                toolbarSetings.items.push({
                    type: "ExcelExport",
                    settings: {
                        headings: this.getHeadings(),
                        autoWidth: true,
                        autoFormat: true,
                        formatRow: (data) => {
                            let statusLogs = "";
                            if (data.permanent_failed_at || !data.sent_at) {
                                statusLogs = this.$tl("labels.booking.reservations.mailLogs.permanent_failed", this.restaurantId, {
                                    type: this.getReservationMailLogLabel(
                                        this.isWaitingsMode ? this.$tl("labels.booking.waitings.mailLogs.type") : data.type,
                                        this.restaurantId
                                    ),
                                });
                            } else if (data.sent_at && !data.delivered_at) {
                                statusLogs = this.$tl("labels.booking.reservations.mailLogs.pending", this.restaurantId, {
                                    type: this.getReservationMailLogLabel(
                                        this.isWaitingsMode ? this.$tl("labels.booking.waitings.mailLogs.type") : data.type,
                                        this.restaurantId
                                    ),
                                });
                            } else if (data.delivered_at) {
                                statusLogs = this.$tl("labels.booking.reservations.mailLogs.sent_confirmed", this.restaurantId, {
                                    type: this.getReservationMailLogLabel(
                                        this.isWaitingsMode ? this.$tl("labels.booking.waitings.mailLogs.type") : data.type,
                                        this.restaurantId
                                    ),
                                });
                            }
                            return [
                                this.displayClient(data[this.attribute].client),
                                ...this.prepareDataToExport(data),
                                this.displayDate(data.created_at, this.DATE_SHORT),
                                statusLogs,
                            ];
                        },
                        anchorClasses: "btn btn-sm btn-outline-secondary export-hover",
                        anchorStyle: "border-radius: 20px",
                    },
                });
            }

            return toolbarSetings;
        },
    },
    methods: {
        initComponent() {
            const components = {
                reservation: ["reservation-mail-logs", "show-reservation-modal"],
                waiting: ["reservation-mail-logs", null],
                cc_command: ["cc-command-mail-logs", "show-command-modal"],
                gv_sale: ["gv-sale-mail-logs", "show-gift-voucher-modal"],
            };
            this.component.main = components[this.attribute][0];
            this.component.modal = components[this.attribute][1];
        },
        prepareDataToExport(data) {
            if (["reservation", "waiting".includes(this.attribute)]) {
                return [
                    this.displayDate(data[this.attribute].reservation_datetime, this.DATETIME_SHORT),
                    this.formatHour(data[this.attribute].slot.hour_start),
                    data[this.attribute].total_nb_pers,
                ];
            }

            if (this.attribute === "cc_command") {
                return [
                    data.cc_command.numero,
                    this.getCollectTypeLabel(data.cc_command.collect_type),
                    this.displayDate(data.cc_command.reservation_date, this.DATE_SHORT),
                    this.displayCcHour(data.cc_command.slot, data.cc_command.collect_type),
                ];
            }

            if (this.attribute === "gv_sale") {
                return [
                    data.gv_sale.numero,
                    this.displayDate(DateTime.fromSQL(data.gv_sale.created_at), this.DATE_SHORT),
                    this.displayProduct(data.gv_sale),
                    data.gv_sale.has_beneficiary ? `${data.gv_sale.beneficiary_firstname} ${data.gv_sale.beneficiary_lastname.toUpperCase()}` : "--",
                ];
            }

            throw `Invalid attribute "${this.attribute}"`;
        },
        displayClient(client) {
            if (client !== null) {
                const civility = this.getClientCivilityLabelShort(client.civility);

                return `${civility} ${client.firstname ?? ""} ${(client.lastname ?? "").toUpperCase()}`;
            }

            return "";
        },
        formatHour(hour) {
            return this.displayDate(this.setHourOnDateTime(hour), this.TIME_SIMPLE);
        },
        displayCcHour(slot, type) {
            if (type === this.COLLECT_TYPE_COLLECT.value) {
                return this.formatHour(slot.hour);
            }

            if (type === this.COLLECT_TYPE_DELIVERY.value) {
                return this.formatHour(slot.hour_start) + " - " + this.formatHour(slot.hour_end);
            }

            return "--";
        },
        displayProduct(gvSale) {
            let prefix = "";

            if (gvSale.gv_product === null) {
                return "--";
            }

            if (gvSale.gv_product.type === "menu") {
                prefix = `${gvSale.menu_quantity} x`;
            }

            return prefix.concat(" ", gvSale.gv_product.name);
        },
        attributeLabels(keyWords) {
            const labels = [];

            for (const keyWord of keyWords) {
                labels.push(this.$tl(`labels.notifications.listNotificationsLogs.grid.columns.${keyWord}`).replace(/<[^<>]+>/gi, ""));
            }

            return labels;
        },
        getHeadings() {
            const columns = {
                reservation: [
                    "commons.client",
                    "reservation.reservationDate",
                    "commons.hours",
                    "reservation.pax",
                    "commons.sentDate",
                    "commons.shipmentStatus",
                ],
                cc_command: [
                    "commons.client",
                    "commons.numero",
                    "clickAndCollect.collectType",
                    "clickAndCollect.commandDate",
                    "commons.hours",
                    "commons.sentDate",
                    "commons.shipmentStatus",
                ],
                gv_sale: [
                    "commons.client",
                    "commons.numero",
                    "giftVouchers.gvSaleDate",
                    "giftVouchers.product",
                    "giftVouchers.beneficiary",
                    "commons.sentDate",
                    "commons.shipmentStatus",
                ],
            };

            columns.waiting = columns.reservation;

            return this.attributeLabels(columns[this.attribute]);
        },
        setURL() {
            this.url.prefix = "api";

            if (this.moduleType === this.MODULE_TYPE_BOOKING.value) {
                if (this.isWaitingsMode) {
                    this.attribute = "waiting";
                } else {
                    this.attribute = "reservation";
                }
                this.url.includeValues = `${this.attribute}.client,${this.attribute}.slot`;
                this.url.module = "reservations";
            } else if (this.moduleType === this.MODULE_TYPE_CLICK_AND_COLLECT.value) {
                this.url.includeValues = "cc_command.client,cc_command.slot";
                this.url.prefix += `/${this.MODULE_TYPE_CLICK_AND_COLLECT.urlValue}`;
                this.url.module = this.MODULE_TYPE_CLICK_AND_COLLECT.value;
                this.attribute = "cc_command";
            } else if (this.moduleType === this.MODULE_TYPE_GIFT_VOUCHERS.value) {
                this.url.includeValues = "gv_sale.client,gv_sale.gv_product";
                this.url.prefix += `/${this.MODULE_TYPE_GIFT_VOUCHERS.urlValue}`;
                this.url.module = this.MODULE_TYPE_GIFT_VOUCHERS.value;
                this.attribute = "gv_sale";
            }
        },
        sendMail(data) {
            if (!data.should_be_send_again) {
                return;
            }

            this.isDisabled = true;
            this.$set(this.isFragmentLoading, data.id, true);
            this.httpPost(`${this.baseURL}/${data[this.attribute].id}/mailLogs/${data.id}/send-again`).finally(() => {
                this.isDisabled = false;
                this.isFragmentLoading[data.id] = false;
            });
        },
        rowSelected({ event, data }) {
            const tagsToExclude = ["button", "a", "svg", "rect", "path", "line"];

            const targetTagName = event && event.target && event.target.tagName ? event.target.tagName.toLowerCase() : "";

            if (!tagsToExclude.includes(targetTagName)) {
                this.selectedItemId = data[this.attribute].id;
                this.showModal = true;
            }
        },
        updateDataFromEvent(event) {
            this.queries.sort.field = null;
            this.queries.sort.order = null;

            this.queries.page = event.page;
            this.queries.per_page = event.per_page;

            if (event.field !== null && event.order !== null) {
                this.queries.sort.field = event.field;
                this.queries.sort.order = event.order;
            }

            this.fetchMailLogs();
        },
        toggleFilters(shouldReset = false) {
            if (shouldReset) {
                this.resetFilters();
            }
            this.setFilters();
            this.fetchMailLogs();
        },
        setFilters() {
            this.queries.filters = { ...this.currentFilters };
            this.queries.page = 1;
        },
        resetFilters() {
            this.currentFilters.from = null;
            this.currentFilters.to = null;
            this.currentFilters.status = null;
            this.currentFilters.notifications = null;
        },
        fetchMailLogs() {
            this.isLoading = true;
            const queries = this.parseQueries();

            this.httpGet(`${this.fetchURL}${queries}`)
                .then((response) => {
                    if (response !== false) {
                        this.mailLogs = response.data.data;
                        this.pagination = response.data.meta.pagination;
                        if (![5, 10, 12, 20].includes(this.pagination.per_page)) {
                            this.pagination.per_page = "all";
                        }

                        if (this.$store.getters["users/hasUnseenFailedMailsNotificationsLogs"](this.restaurantId, this.moduleType)) {
                            this.$store.dispatch("users/markFailedMailsNotificationsLogsAsSeen", {
                                restaurantId: this.restaurantId,
                                moduleType: this.moduleType,
                            });
                        }
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        parseQueries() {
            const filters = this.parseQueryFilters();
            const sort = this.parseQuerySort();
            const page = this.parseQueryPage();

            return filters + sort + page;
        },
        parseQueryFilters() {
            let queryFilters = "";

            Object.keys(this.queries.filters).forEach((key) => {
                if (this.queries.filters[key] !== null) {
                    const value = this.queries.filters[key] instanceof DateTime ? this.queries.filters[key].toISODate() : this.queries.filters[key];
                    queryFilters = queryFilters.concat("&", `${key}=${value}`);
                }
            });

            return queryFilters;
        },
        parseQuerySort() {
            if (this.queries.sort.field !== null && this.queries.sort.order !== null) {
                return "&".concat("sort=", this.queries.sort.field) + "&".concat("order=", this.queries.sort.order);
            }

            return "";
        },
        parseQueryPage() {
            return "&".concat("page=", this.queries.page) + "&".concat("per_page=", this.queries.per_page);
        },
    },
    created() {
        this.setURL();
    },
    components: {
        DatePicker,
        NsTable,
        NsTh,
        LoaderComponent,
        ShowReservationModal,
        ShowCommandModal,
        showGiftVoucherModal,
        ReservationMailLogsHeadings,
        ReservationMailLogsBody,
        CcCommandMailLogsHeadings,
        CcCommandMailLogsBody,
        GvSaleMailLogsHeadings,
        GvSaleMailLogsBody,
        SwitchInputComponent,
    },
    watch: {
        "currentLogMailTypes.length": function (value) {
            if (value === 1) {
                this.currentFilters.notifications = this.currentLogMailTypes[0].value;
            }
        },
        attribute() {
            this.initComponent();
            this.fetchMailLogs();
        },
        isWaitingsMode() {
            this.setURL();
        },
    },
};
</script>
