<template>
    <transition appear v-on:after-enter="afterEnter" name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper d-flex" @click="$emit('close', $event)">
                <div class="modal-container rounded-modal-container" :ref="`modal-container`" :style="modalContainerStyle">
                    <div class="modal-body">
                        <slot name="body"></slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer">
                            <button class="modal-default-button btn btn-secondary btn-circle" @click="$emit('close')">Fermer</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default {
    props: {
        modalContainerStyle: {
            default: "",
        },
    },
    methods: {
        afterEnter() {
            const body = document.getElementsByTagName("BODY")[0];

            if (document.getElementsByClassName("modal-container").length <= 1) {
                if (!body.classList.contains("w-100")) {
                    body.classList.add("w-100");
                }
            }

            if (typeof $el !== "undefined") {
                disableBodyScroll(this.$refs["modal-container"].$el);
            }
        },
    },
    beforeDestroy() {
        const body = document.getElementsByTagName("BODY")[0];

        if (document.getElementsByClassName("modal-container").length <= 1) {
            if (body.classList.contains("w-100")) {
                body.classList.remove("w-100");
            }
        }

        if (typeof $el !== "undefined") {
            enableBodyScroll(this.$refs["modal-container"].$el);
        }
    },
};
</script>
