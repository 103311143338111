var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "border-light b-radius-20 p-4 mt-3" }, [
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-12 mb-3" }, [
              _c("strong", [_vm._v(_vm._s(_vm.$tl("labels.form.sms.edit")))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "label",
                { staticClass: "mb-1" },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$tl("labels.form.sms.emitterName")) +
                      "\n                        "
                  ),
                  _c("feather", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltipNotice(
                          "notices.booking.notifications.phoneName"
                        ),
                        expression:
                          "getTooltipNotice('notices.booking.notifications.phoneName')",
                      },
                    ],
                    staticClass: "ml-1 pointer tooltip-infos",
                    attrs: { type: "info" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("small", { staticClass: "d-block" }, [
                _vm._v(_vm._s(_vm.$t("infos.form.maxChars", { nb: 11 }))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-8" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.restaurant.notifications_setting.sms_emitter_name,
                    expression:
                      "restaurant.notifications_setting.sms_emitter_name",
                  },
                ],
                staticClass: "form-control mb-2",
                class: {
                  "is-invalid":
                    !_vm.restaurant.notifications_setting.sms_emitter_name.match(
                      /^[a-z]+$/i
                    ),
                },
                attrs: {
                  disabled: !_vm.has_right_to_update_restaurant,
                  type: "text",
                  maxlength: "11",
                },
                domProps: {
                  value: _vm.restaurant.notifications_setting.sms_emitter_name,
                },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.restaurant.notifications_setting,
                      "sms_emitter_name",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "limiter text-muted pl-2" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("infos.form.remainingChars", {
                      nb: _vm.sms_emitter_name_count,
                    })
                  )
                ),
              ]),
              _vm._v(" "),
              _vm.hasErrors("sms_emitter_name")
                ? _c("div", {
                    staticClass: "text-danger",
                    domProps: {
                      innerHTML: _vm._s(_vm.joinErrors("sms_emitter_name")),
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4 col-10 mt-3" }, [
              _c(
                "label",
                { staticClass: "mb-1" },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$tl("labels.form.sms.sendToForeign")) +
                      "\n                        "
                  ),
                  _c("feather", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltipNotice(
                          "notices.booking.notifications.strangerSms"
                        ),
                        expression:
                          "getTooltipNotice('notices.booking.notifications.strangerSms')",
                      },
                    ],
                    staticClass: "ml-1 pointer tooltip-infos",
                    attrs: { type: "info" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "d-block",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      _vm.showInternationalSmsCostModal = true
                    },
                  },
                },
                [
                  _c("small", [
                    _vm._v(_vm._s(_vm.$tl("infos.form.sms.foreignCost"))),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-8 col-2 mt-3" }, [
              _c("label", { staticClass: "container-box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.restaurant.notifications_setting
                          .enable_international_sms,
                      expression:
                        "restaurant.notifications_setting.enable_international_sms",
                    },
                  ],
                  attrs: {
                    disabled: !_vm.has_right_to_update_restaurant,
                    type: "checkbox",
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.restaurant.notifications_setting
                        .enable_international_sms
                    )
                      ? _vm._i(
                          _vm.restaurant.notifications_setting
                            .enable_international_sms,
                          null
                        ) > -1
                      : _vm.restaurant.notifications_setting
                          .enable_international_sms,
                  },
                  on: {
                    change: function ($event) {
                      var $$a =
                          _vm.restaurant.notifications_setting
                            .enable_international_sms,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.restaurant.notifications_setting,
                              "enable_international_sms",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.restaurant.notifications_setting,
                              "enable_international_sms",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.restaurant.notifications_setting,
                          "enable_international_sms",
                          $$c
                        )
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkmark" }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.isStandAlone
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success btn-circle btn-sm",
                      attrs: { type: "button", disabled: _vm.isLoading },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.updateConfigSms.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$tl("labels.form.actions.save")) +
                          "\n                    "
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.showInternationalSmsCostModal
        ? _c("InternationalSmsCostModal", {
            on: {
              close: function ($event) {
                _vm.showInternationalSmsCostModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }