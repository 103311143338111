import ReservationStatusEnum from "./ReservationStatusEnum";

export default {
    data() {
        return {
            RESERVATION_COLUMN_STATUS: {
                label: "le statut",
                value: "status",
                valueLabel: (log, restaurantId = undefined) => this.capitalize(this.getReservationStatusLabel(log.status, undefined, restaurantId)),
                icon: "user",
            },
            RESERVATION_COLUMN_RESERVATION_DATE: {
                label: "la date",
                value: "reservation_date",
                valueLabel: (log) => this.displayDate(log.reservation_datetime, this.DATE_SHORT),
                icon: "calendar",
            },
            RESERVATION_COLUMN_SLOT: {
                label: "l'heure",
                value: "slot",
                valueLabel: (log) => this.displayDate(log.reservation_datetime, this.TIME_SIMPLE),
                icon: "clock",
            },
            RESERVATION_COLUMN_NB_PERS: {
                label: "le nombre de personnes",
                value: "nb_pers",
                valueLabel: (log) => log.nb_pers,
                icon: "users",
            },
            RESERVATION_COLUMN_NB_CHILDREN: {
                label: "le nombre d'enfants",
                value: "nb_children",
                valueLabel: (log) => log.nb_children,
                icon: "users",
            },
            RESERVATION_COLUMN_COMMENT: {
                label: "le commentaire",
                value: "comment",
                valueLabel: (log) => log.comment,
                icon: "message-square",
            },
            RESERVATION_COLUMN_CLIENT: {
                label: "le client",
                value: "client",
                icon: "user",
            },
        };
    },
    mixins: [ReservationStatusEnum],
    methods: {
        getReservationColumnLabel(value) {
            const found = this.ALL_RESERVATION_COLUMNS.find((w) => w.value == value);

            return typeof found !== "undefined" ? this.$t(`labels.booking.reservations.logs.updates.${value}`) : value;
        },
        getReservationColumnValueLabel(value, log, restaurantId) {
            const found = this.ALL_RESERVATION_COLUMNS.find((w) => w.value == value);

            if (typeof found !== "undefined") {
                return typeof found.valueLabel !== "undefined" ? found.valueLabel(log, restaurantId) : undefined;
            }

            return value;
        },
        getReservationColumnIcon(value) {
            const found = this.ALL_RESERVATION_COLUMNS.find((w) => w.value == value);

            return typeof found !== "undefined" ? found.icon : "x";
        },
    },
    computed: {
        ALL_RESERVATION_COLUMNS() {
            return [
                this.RESERVATION_COLUMN_STATUS,
                this.RESERVATION_COLUMN_RESERVATION_DATE,
                this.RESERVATION_COLUMN_SLOT,
                this.RESERVATION_COLUMN_NB_PERS,
                this.RESERVATION_COLUMN_NB_CHILDREN,
                this.RESERVATION_COLUMN_COMMENT,
                this.RESERVATION_COLUMN_CLIENT,
            ];
        },
    },
};
