export default {
    data() {
        return {
            MODULE_PLAN_TYPE_MONTHLY: {
                label: "mensuelle",
                value: "monthly",
            },
            MODULE_PLAN_TYPE_YEARLY: {
                label: "annuelle",
                value: "yearly",
            },
        };
    },
    methods: {
        getModulePlanTypeLabel(value) {
            const found = this.ALL_MODULE_PLAN_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return this.$t(`labels.${value}`).toLowerCase();
            return value;
        },
    },
    computed: {
        ALL_MODULE_PLAN_TYPES() {
            return [this.MODULE_PLAN_TYPE_MONTHLY, this.MODULE_PLAN_TYPE_YEARLY];
        },
    },
};
