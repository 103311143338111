<template>
    <div class="pr-0 pb-5">
        <LoaderComponent v-if="nbLoading > 0" />
        <div v-else>
            <div v-if="errors.length > 0" class="alert alert-danger">
                <span class="d-block" v-for="(err, i) in errors" :key="i">{{ err }}</span>
            </div>
            <div v-else>
                <div class="row m-0">
                    <div class="col-12">
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                            <h5 class="title mt-2">{{ $tl("labels.settings.options") }}</h5>
                            <router-link
                                :to="{
                                    name: 'booking.restaurants.settings.menus_options.add',
                                    params: { restaurant_id },
                                }"
                                class="btn btn-sm btn-success btn-circle"
                                :class="{ disabled: !has_right_to_create_menu }">
                                <feather type="plus"></feather>
                                <span>{{ $tl("labels.booking.menus.options.add") }}</span>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col-12">
                        <div class="my-2 d-flex justify-content-between">
                            <h6 class="title mt-2">{{ $tl("labels.settings.menuOptions", restaurant_id) }}</h6>
                            <template v-if="has_right_to_update_menu">
                                <button
                                    v-if="!isOrdering"
                                    @click="startOrdering(MENU_OPTION_MENU.value)"
                                    type="button"
                                    class="btn btn-sm btn-success mr-1 btn-circle">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="css-i6dzq1 feather">
                                        <polyline points="9 5 12 2 15 5"></polyline>
                                        <polyline points="15 19 12 22 9 19"></polyline>
                                        <line x1="12" y1="2" x2="12" y2="22"></line>
                                    </svg>
                                    {{ $tl("labels.form.actions.orderMenusOptions", restaurant_id) }}
                                </button>
                                <template v-else>
                                    <div>
                                        <button
                                            :disabled="nbLoading > 0"
                                            @click="saveOrdering(MENU_OPTION_MENU.value)"
                                            type="button"
                                            class="btn btn-sm btn-success mr-1 btn-circle">
                                            {{ $tl("labels.form.actions.save") }}
                                        </button>
                                        <button
                                            :disabled="nbLoading > 0"
                                            @click="cancelOrdering(MENU_OPTION_MENU.value)"
                                            type="button"
                                            class="btn btn-sm btn-outline-secondary mr-1 btn-circle">
                                            {{ $tl("labels.form.actions.cancel") }}
                                        </button>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <table v-if="options && options.length > 0" class="table table-sm table-striped border-bottom">
                            <thead class="border-bottom">
                                <tr>
                                    <th v-if="isOrdering" class="w-p-10">{{ $tl("labels.order") }}</th>
                                    <th class="w-p-10">{{ $tl("labels.form.name") }}</th>
                                    <th class="w-p-10">{{ $tl("labels.price") }}</th>
                                    <th class="w-p-40">{{ $tl("labels.form.description") }}</th>
                                    <th class="w-p-20">{{ $tl("labels.booking.menus.options.quantity") }}</th>
                                    <th class="w-p-10">{{ $tl("labels.form.actions.title") }}</th>
                                </tr>
                            </thead>
                            <draggable :disabled="!isOrdering" tag="tbody" v-model="options" draggable=".menus-options">
                                <tr v-for="(option, i) in options" :key="i" class="menus-options">
                                    <td v-if="isOrdering">
                                        <svg
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="css-i6dzq1 feather"
                                            style="cursor: grab; color: rgb(170, 170, 170)">
                                            <line x1="3" y1="9" x2="21" y2="9"></line>
                                            <line x1="3" y1="15" x2="21" y2="15"></line>
                                        </svg>
                                    </td>
                                    <td>{{ option.name }}</td>
                                    <td>
                                        {{
                                            option.price === 0
                                                ? $tl("labels.free")
                                                : `${formatCurrency(option.price, getRestaurantCurrency(restaurant_id))}`
                                        }}
                                    </td>
                                    <td>{{ option.description }}</td>
                                    <td>{{ getOptionQuantityTypeLabel(option.quantity_type, restaurant_id) }}</td>
                                    <td>
                                        <router-link
                                            :to="getEditRouteForOption(option)"
                                            class="btn btn-sm btn-success btn-square"
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: $t('labels.form.actions.edit'),
                                            }">
                                            <feather type="edit" />
                                        </router-link>
                                        <button
                                            v-if="!option.has_future_reservations"
                                            @click="deleteOption(option)"
                                            type="button"
                                            class="btn btn-sm btn-danger btn-square"
                                            :disabled="!has_right_to_delete_menu"
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: $t('labels.form.actions.delete'),
                                            }">
                                            <feather type="trash-2" />
                                        </button>
                                        <button
                                            v-if="option.has_future_reservations"
                                            type="button"
                                            class="btn btn-sm btn-secondary btn-square"
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: $t('infos.booking.menus.options.cantDelete'),
                                            }">
                                            <feather type="trash-2" />
                                        </button>
                                    </td>
                                </tr>
                            </draggable>
                        </table>
                        <div v-else>
                            <em>{{ $tl("labels.booking.menus.options.empty") }}</em>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col-12">
                        <div class="my-2 d-flex justify-content-between">
                            <h6 class="title mt-2">{{ $tl("labels.settings.generalOptions") }}</h6>
                            <template v-if="has_right_to_update_menu">
                                <button
                                    v-if="!isOrderingGeneral"
                                    @click="startOrdering(MENU_OPTION_GENERAL.value)"
                                    type="button"
                                    class="btn btn-sm btn-success mr-1 btn-circle">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="css-i6dzq1 feather">
                                        <polyline points="9 5 12 2 15 5"></polyline>
                                        <polyline points="15 19 12 22 9 19"></polyline>
                                        <line x1="12" y1="2" x2="12" y2="22"></line>
                                    </svg>
                                    {{ $tl("labels.form.actions.orderGeneralOptions") }}
                                </button>
                                <template v-else>
                                    <div>
                                        <button
                                            :disabled="nbLoading > 0"
                                            @click="saveOrdering(MENU_OPTION_GENERAL.value)"
                                            type="button"
                                            class="btn btn-sm btn-success mr-1 btn-circle">
                                            {{ $tl("labels.form.actions.save") }}
                                        </button>
                                        <button
                                            :disabled="nbLoading > 0"
                                            @click="cancelOrdering(MENU_OPTION_GENERAL.value)"
                                            type="button"
                                            class="btn btn-sm btn-outline-secondary mr-1 btn-circle">
                                            {{ $tl("labels.form.actions.cancel") }}
                                        </button>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <table v-if="generalOptions && generalOptions.length > 0" class="table table-sm table-striped border-bottom">
                            <thead class="border-bottom">
                                <tr>
                                    <th class="w-p-10" v-if="isOrderingGeneral">{{ $tl("labels.order") }}</th>
                                    <th class="w-p-10">{{ $tl("labels.form.name") }}</th>
                                    <th class="w-p-10">{{ $tl("labels.price") }}</th>
                                    <th class="w-p-40">{{ $tl("labels.form.description") }}</th>
                                    <th class="w-p-20">{{ $tl("labels.booking.menus.options.quantity") }}</th>
                                    <th class="w-p-10">{{ $tl("labels.form.actions.title") }}</th>
                                </tr>
                            </thead>
                            <draggable :disabled="!isOrderingGeneral" tag="tbody" v-model="generalOptions" draggable=".menus-options">
                                <tr v-for="(option, i) in generalOptions" :key="i" class="menus-options">
                                    <td v-if="isOrderingGeneral">
                                        <svg
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="css-i6dzq1 feather"
                                            style="cursor: grab; color: rgb(170, 170, 170)">
                                            <line x1="3" y1="9" x2="21" y2="9"></line>
                                            <line x1="3" y1="15" x2="21" y2="15"></line>
                                        </svg>
                                    </td>
                                    <td>{{ option.name }}</td>
                                    <td>
                                        {{
                                            option.price === 0
                                                ? $tl("labels.free")
                                                : formatCurrency(option.price, getRestaurantCurrency(restaurant_id))
                                        }}
                                    </td>
                                    <td>{{ option.description }}</td>
                                    <td>{{ getOptionQuantityTypeLabel(option.quantity_type, restaurant_id) }}</td>
                                    <td>
                                        <router-link
                                            :to="getEditRouteForOption(option)"
                                            class="btn btn-sm btn-success btn-square"
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: $t('labels.form.actions.edit'),
                                            }">
                                            <feather type="edit" />
                                        </router-link>
                                        <button
                                            v-if="!option.has_future_reservations"
                                            @click="deleteOption(option)"
                                            type="button"
                                            class="btn btn-sm btn-danger btn-square"
                                            :disabled="!has_right_to_delete_menu"
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: $t('labels.form.actions.delete'),
                                            }">
                                            <feather type="trash-2" />
                                        </button>
                                        <button
                                            v-if="option.has_future_reservations"
                                            type="button"
                                            class="btn btn-sm btn-secondary btn-square"
                                            v-tooltip="{
                                                delay: { show: 400, hide: 0 },
                                                content: $t('infos.booking.menus.options.cantDelete'),
                                            }">
                                            <feather type="trash-2" />
                                        </button>
                                    </td>
                                </tr>
                            </draggable>
                        </table>
                        <div v-else>
                            <em>{{ $tl("labels.booking.menus.options.empty") }}</em>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../../components/LoaderComponent.vue";
import MenuOptionTypeEnum from "../../../../mixins/enums/booking/MenuOptionTypeEnum";
import OptionQuantityTypesEnum from "../../../../mixins/enums/booking/OptionQuantityTypesEnum";
import draggable from "vuedraggable";

export default {
    data() {
        return {
            nbLoading: 1,
            options: undefined,
            generalOptions: null,
            errors: [],
            isOrdering: false,
            isOrderingGeneral: false,
            savedOptions: null,
            savedGeneralOptions: null,
        };
    },
    mixins: [MenuOptionTypeEnum, OptionQuantityTypesEnum],
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_menu() {
            return this.rights.includes("booking.menu.create");
        },
        has_right_to_update_menu() {
            return this.rights.includes("booking.menu.update");
        },
        has_right_to_delete_menu() {
            return this.rights.includes("booking.menu.delete");
        },
    },
    methods: {
        getEditRouteForOption(option) {
            if (option.option_type === this.MENU_OPTION_MENU.value)
                return {
                    name: "booking.restaurants.settings.menus_options.edit",
                    params: { menu_option_id: option.id },
                };
            if (option.option_type === this.MENU_OPTION_GENERAL.value)
                return {
                    name: "booking.restaurants.settings.general_options.edit",
                    params: { general_option_id: option.id },
                };
            return undefined;
        },
        getEndpointForOption(option) {
            if (option.option_type === this.MENU_OPTION_MENU.value) {
                return "menus/options";
            }

            if (option.option_type === this.MENU_OPTION_GENERAL.value) {
                return "general/options";
            }

            console.error("Invalid api endpoint, option_type: ", option.option_type);

            return "";
        },
        fetchData() {
            const menu = this.MENU_OPTION_MENU.value;
            const general = this.MENU_OPTION_GENERAL.value;

            this.errors = [];
            this.nbLoading = this.ALL_MENU_OPTION_TYPES.length;
            this.fetchOptions(menu, this.getEndpointForOption({ option_type: menu })).then((isFetched) => this.nbLoading--);
            this.fetchOptions(general, this.getEndpointForOption({ option_type: general })).then((isFetched) => this.nbLoading--);
        },
        fetchOptions(optionType, endpoint) {
            return axios
                .get(`/api/restaurants/${this.restaurant_id}/${endpoint}?include=has_future_reservations`)
                .then((response) => {
                    if (optionType === this.MENU_OPTION_MENU.value) {
                        this.options = response.data.data;
                    } else if (optionType === this.MENU_OPTION_GENERAL.value) {
                        this.generalOptions = response.data.data;
                    }
                    return true;
                })
                .catch((error) => {
                    this.notifyError(error);
                    return false;
                });
        },
        deleteOption(option) {
            if (!confirm(this.$tl("questions.booking.menus.options.delete"))) return;
            const endpoint = this.getEndpointForOption(option);
            this.nbLoading++;
            axios
                .delete(`/api/restaurants/${this.restaurant_id}/${endpoint}/${option.id}`)
                .then(() => {
                    this.fetchOptions(option.option_type, endpoint).then((isFetched) => {
                        this.nbLoading--;
                        if (isFetched) {
                            this.notifySuccess(null, this.$tl("success.booking.menus.options.deleted"));
                        }
                    });
                })
                .catch((error) => {
                    this.nbLoading--;
                    this.notifyError(error);
                });
        },
        startOrdering(optionType) {
            if (optionType === this.MENU_OPTION_MENU.value) {
                this.savedOptions = _.cloneDeep(this.options);
                this.isOrdering = true;
            } else if (optionType === this.MENU_OPTION_GENERAL.value) {
                this.savedGeneralOptions = _.cloneDeep(this.generalOptions);
                this.isOrderingGeneral = true;
            }
        },
        cancelOrdering(optionType) {
            if (optionType === this.MENU_OPTION_MENU.value) {
                this.options = this.savedOptions;
                this.savedOptions = null;
                this.isOrdering = false;
            } else if (optionType === this.MENU_OPTION_GENERAL.value) {
                this.generalOptions = this.savedGeneralOptions;
                this.savedGeneralOptions = null;
                this.isOrderingGeneral = false;
            }
        },
        saveOrdering(optionType) {
            this.nbLoading++;

            const endpoint = this.getEndpointForOption({ option_type: optionType });
            let currentOptions = [];
            if (optionType === this.MENU_OPTION_MENU.value) {
                currentOptions = this.options;
            } else if (optionType === this.MENU_OPTION_GENERAL.value) {
                currentOptions = this.generalOptions;
            }

            const options = currentOptions.map((currentOption, index) => {
                return {
                    id: currentOption.id,
                    order: index + 1,
                };
            });
            axios
                .post(`/api/restaurants/${this.restaurant_id}/${endpoint}/order`, {
                    options,
                })
                .then((response) => {
                    if (optionType === this.MENU_OPTION_MENU.value) {
                        this.isOrdering = false;
                        this.savedOptions = null;
                    } else if (optionType === this.MENU_OPTION_GENERAL.value) {
                        this.isOrderingGeneral = false;
                        this.savedGeneralOptions = null;
                    }
                    this.fetchOptions(optionType, endpoint).then((isFetched) => {
                        this.nbLoading--;
                        if (isFetched) {
                            this.notifySuccess(response);
                        }
                    });
                })
                .catch((error) => {
                    this.nbLoading--;
                    this.notifyError(error);
                });
        },
    },
    created() {
        this.fetchData();
    },
    components: {
        LoaderComponent,
        draggable,
    },
};
</script>
