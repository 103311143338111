var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-4" }, [
      _c("div", { staticClass: "row mb-2" }, [
        _c("div", { staticClass: "col-md-4 pt-1" }, [
          _c("label", [
            _vm._v(
              _vm._s(_vm.$tl("labels.booking.reservations.edit.date")) + " "
            ),
            _c("small", [_vm._v("*")]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col-md-8 date-resa-cal",
            staticStyle: { "font-size": "0.75rem" },
          },
          [
            _c("DatePicker", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.hasTablesSelected
                    ? {
                        content: _vm.$tl(
                          "infos.booking.reservations.edit.date",
                          _vm.restaurant_id
                        ),
                      }
                    : undefined,
                  expression:
                    "\n                        hasTablesSelected\n                            ? {\n                                  content: $tl('infos.booking.reservations.edit.date', restaurant_id),\n                              }\n                            : undefined\n                    ",
                },
              ],
              class: { "is-invalid": _vm.hasErrors("reservation_date") },
              attrs: {
                disabledDates: _vm.disabledDates,
                tabindex: "1",
                disabled: _vm.readOnly,
                format: "ccc DDD",
                highlighted: _vm.highlighted,
              },
              on: {
                input: function ($event) {
                  _vm.hasTablesSelected
                    ? _vm.$emit("unlink-all-tables")
                    : undefined
                },
              },
              model: {
                value: _vm.reservation_dat,
                callback: function ($$v) {
                  _vm.reservation_dat = $$v
                },
                expression: "reservation_dat",
              },
            }),
            _vm._v(" "),
            _vm.hasErrors("reservation_date")
              ? _c(
                  "div",
                  _vm._l(
                    _vm.formErrors.errors.reservation_date,
                    function (err) {
                      return _c(
                        "div",
                        { staticClass: "invalid-feedback d-block" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(err) +
                              "\n                    "
                          ),
                        ]
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-4" }, [
      _c("div", { staticClass: "row mb-2" }, [
        _c("div", { staticClass: "col-md-7 pt-1" }, [
          _c("label", { attrs: { for: "nb_pers" } }, [
            _vm._v(
              _vm._s(_vm.$tl("labels.booking.reservations.edit.nbPers")) + " "
            ),
            _c("small", [_vm._v("*")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-5" }, [
          _c("input", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.hasTablesSelected
                  ? {
                      content: _vm.$tl(
                        "infos.booking.reservations.edit.nbPers",
                        _vm.restaurant_id
                      ),
                    }
                  : undefined,
                expression:
                  "\n                        hasTablesSelected\n                            ? {\n                                  content: $tl('infos.booking.reservations.edit.nbPers', restaurant_id),\n                              }\n                            : undefined\n                    ",
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.nb_per,
                expression: "nb_per",
              },
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.hasErrors("nb_pers") },
            attrs: {
              id: "nb_pers",
              disabled: _vm.readOnly,
              type: "number",
              min: "1",
              max: "1500",
              autocomplete: "off",
              tabindex: "2",
              required: "",
            },
            domProps: { value: _vm.nb_per },
            on: {
              focus: function ($event) {
                _vm.hasTablesSelected
                  ? _vm.$emit("unlink-all-tables")
                  : undefined
              },
              change: function ($event) {
                _vm.hasTablesSelected
                  ? _vm.$emit("unlink-all-tables")
                  : undefined
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.nb_per = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.hasErrors("nb_pers")
            ? _c(
                "div",
                _vm._l(_vm.formErrors.errors.nb_pers, function (err) {
                  return _c(
                    "div",
                    { staticClass: "invalid-feedback d-block" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(err) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-4" }, [
      _c("div", { staticClass: "row mb-2" }, [
        _c("div", { staticClass: "col-md-7 pt-1" }, [
          _c("label", { attrs: { for: "nb_children" } }, [
            _vm._v(
              _vm._s(_vm.$tl("labels.booking.reservations.edit.nbChildren"))
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-5" }, [
          _c("input", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.hasTablesSelected
                  ? {
                      content: _vm.$tl(
                        "infos.booking.reservations.edit.nbChildren",
                        _vm.restaurant_id
                      ),
                    }
                  : undefined,
                expression:
                  "\n                        hasTablesSelected\n                            ? {\n                                  content: $tl('infos.booking.reservations.edit.nbChildren', restaurant_id),\n                              }\n                            : undefined\n                    ",
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.nb_child,
                expression: "nb_child",
              },
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.hasErrors("nb_children") },
            attrs: {
              id: "nb_children",
              disabled: _vm.readOnly,
              type: "number",
              min: "0",
              autocomplete: "off",
              tabindex: "3",
              max: "1500",
            },
            domProps: { value: _vm.nb_child },
            on: {
              focus: function ($event) {
                _vm.hasTablesSelected
                  ? _vm.$emit("unlink-all-tables")
                  : undefined
              },
              change: function ($event) {
                _vm.hasTablesSelected
                  ? _vm.$emit("unlink-all-tables")
                  : undefined
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.nb_child = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.hasErrors("nb_children")
            ? _c(
                "div",
                _vm._l(_vm.formErrors.errors.nb_children, function (err) {
                  return _c(
                    "div",
                    { staticClass: "invalid-feedback d-block" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(err) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }