import StatsSubscriptions from "../../views/Admin/StatsSubscriptions.vue";
import AdminInvoices from "../../views/Admin/Invoices.vue";
import AdminCampaigns from "../../views/Admin/Campaigns/Campaign.vue";
import VideosList from "../../views/Admin/Videos/VideosList.vue";
import Video from "../../views/Admin/Videos/Video.vue";
import PromoCodeList from "../../views/Admin/PromoCodes/PromoCodeList.vue";
import PromoCodeCreate from "../../views/Admin/PromoCodes/PromoCodeCreate.vue";

export default [
    {
        path: "/admin/stats_subscriptions",
        name: "admin.stats_subscriptions",
        component: StatsSubscriptions,
    },
    {
        path: "/admin/campaigns",
        name: "admin.campaigns",
        component: AdminCampaigns,
    },
    {
        path: "/admin/invoices",
        name: "admin.invoices",
        component: AdminInvoices,
    },
    {
        path: "/admin/videos",
        name: "admin.videos.list",
        component: VideosList,
    },
    {
        path: "/admin/videos/:video_id",
        name: "admin.videos.edit",
        component: Video,
    },
    {
        path: "/admin/promo_codes",
        name: "admin.promo_codes.list",
        component: PromoCodeList,
    },
    {
        path: "/admin/promo_codes/create",
        name: "admin.promo_codes.create",
        component: PromoCodeCreate,
    },
];
