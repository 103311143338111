<template>
    <li
        v-if="
            has_rights &&
            (typeof entry.show === 'undefined' || entry.show) &&
            (typeof entry.shouldBeShownForRestaurant === 'undefined' || entry.shouldBeShownForRestaurant(restaurantId))
        "
        class="nav-item btn-en-service"
        :class="{ 'text-center': isCollapsed }">
        <router-link
            :is="entry.disabled ? 'span' : 'router-link'"
            :to="entry.to"
            :style="entry.disabled ? 'opacity:0.5 !important;pointer-events:none !important;' : ''"
            class="nav-link"
            active-class="active"
            :exact="entry.exactUrlMatching">
            <feather :type="entry.icon" :size="isCollapsed ? '26' : '22'" :class="isCollapsed ? 'feather-mr-0 mr-0' : ''" class="w-auto h-auto" />
            <span v-show="!isCollapsed" class="display-sid-min">{{ entryTitle }}</span>
            <feather
                v-if="hasUnseenFailedMailsNotificationsLogs"
                type="alert-triangle"
                class="text-danger"
                style="width: 18px; height: 18px"
                v-tooltip="getTooltip($tl('errors.common.notificationsLogs.hasUnseenFailed', restaurantId))" />
            <newBadge v-if="hasNewBadge && !isCollapsed" />
        </router-link>
    </li>
</template>

<script>
import newBadge from "../components/NewBadges/newBadge.vue";

export default {
    props: {
        entry: {
            required: true,
        },
        user: {
            default: null,
        },
        isCollapsed: {
            default: false,
        },
    },
    computed: {
        moduleType() {
            return this.$route.path.replace(/^\/+/, "").split("/")[0];
        },
        hasUnseenFailedMailsNotificationsLogs() {
            return (
                this.entry.to &&
                this.entry.to.name === "notifications.logs" &&
                this.$store.getters["users/hasUnseenFailedMailsNotificationsLogs"](this.restaurantId, this.moduleType)
            );
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        entryTitle() {
            const baseKey = "labels.routes";

            if (typeof this.entry.titleI18nLexicon !== "undefined") {
                return this.$tl(`${baseKey}.${this.entry.titleI18nLexicon}`, this.restaurantId);
            }

            if (typeof this.entry.titleI18n !== "undefined") {
                return this.$t(`${baseKey}.${this.entry.titleI18n}`);
            }

            return this.entry.title;
        },
        hasNewBadge() {
            return this.entry && this.entry.to && this.entry.to.name ? this.checkForExistingNewBadge(this.entry.to.name) : false;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_rights() {
            if (this.entry.rights.includes("any")) return true;
            return this.entry.rights.some((right) => {
                if (this.rights.includes(right)) return true;
                let i = right.indexOf("*");
                if (i !== -1) {
                    right = right.substring(0, i).replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
                    let regexp = new RegExp(`${right}.+`, "g");
                    return this.rights.some((cright) => cright.match(regexp));
                }
                return false;
            });
        },
    },
    components: { newBadge },
};
</script>
