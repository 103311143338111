<template>
    <tbody>
        <tr
            v-for="(data, index) in filteredDataSource"
            :key="filteredDataSource[keyColumn] || index"
            @click.prevent.stop="$parent.$emit('row-selected', { event: $event, data })"
            :class="{
                pointer: clickable,
                clickable,
                new: data.new,
                updated: data.updated,
            }">
            <td>
                <inline-client :client="data.cc_command.client" :module-enum="MODULE_TYPE_CLICK_AND_COLLECT" />
            </td>
            <td>
                {{ data.cc_command.numero }}
            </td>
            <td>
                <span v-if="data.cc_command.collect_type === 'collect'"> Retrait </span>
                <span v-else-if="data.cc_command.collect_type === 'delivery'"> Livraison </span>
                <span v-else> Expédition </span>
            </td>
            <td>
                {{ displayDate(data.cc_command.reservation_date, DATE_SHORT) }}
            </td>
            <td>
                {{ displayHour(data.cc_command.slot, data.cc_command.collect_type) }}
            </td>

            <td>
                {{ displayDate(data.created_at, DATE_SHORT) }}
            </td>
            <td>
                <span v-if="data.status === LOG_MAIL_NOT_DELIVERED.value" class="text-danger">
                    {{
                        $t("labels.booking.reservations.mailLogs.permanent_failed", {
                            type: getReservationMailLogLabel(data.type, restaurantId),
                        })
                    }}
                </span>
                <span v-else-if="data.status === LOG_MAIL_PENDING.value" class="text-warning">
                    {{ $t("labels.booking.reservations.mailLogs.pending", { type: getReservationMailLogLabel(data.type, restaurantId) }) }}
                </span>
                <span v-else-if="data.status === LOG_MAIL_DELIVERED.value">
                    {{
                        $t("labels.booking.reservations.mailLogs.sent_confirmed", {
                            type: getReservationMailLogLabel(data.type, restaurantId),
                        })
                    }}
                </span>
            </td>
            <td>
                <div v-if="isFragmentLoading[data.id]" class="spinner-border feather-20" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <button
                    v-else
                    type="button"
                    class="btn btn-sm btn-outline-secondary btn-square"
                    :disabled="isDisabled || !data.should_be_send_again"
                    @click.prevent.stop="$emit('send-mail', data)">
                    <feather type="send" />
                </button>
            </td>
        </tr>
    </tbody>
</template>
<script>
import ReservationMailLogEnum from "../../../../../mixins/enums/booking/ReservationMailLogEnum.js";
import ClientCivilityEnum from "../../../../../mixins/enums/ClientCivilityEnum.js";
import LogMailStatusEnum from "../../../../../mixins/enums/LogMailStatusEnum.js";
import ModuleTypesEnum from "../../../../../mixins/enums/ModuleTypesEnum.js";
import inlineClient from "../../../../Default/Clients/inlineClient.vue";

export default {
    props: {
        filteredDataSource: {
            type: Array,
            required: true,
        },
        clickable: {
            type: Boolean,
            required: true,
        },
        restaurantId: {
            type: Number,
            required: true,
        },
        isFragmentLoading: {
            type: Object,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            required: true,
        },
        keyColumn: {
            type: String,
            required: true,
        },
    },
    mixins: [ReservationMailLogEnum, ClientCivilityEnum, LogMailStatusEnum, ModuleTypesEnum],
    components: {
        inlineClient,
    },
    methods: {
        formatHour(hour) {
            return this.displayDate(this.setHourOnDateTime(hour), this.TIME_SIMPLE);
        },
        displayHour(slot, type) {
            if (slot === null) {
                return "--";
            }
            if (type === "collect") {
                return this.formatHour(slot.hour);
            }
            if (type === "delivery") {
                return this.formatHour(slot.hour_start) + " - " + this.formatHour(slot.hour_end);
            }
        },
    },
};
</script>
