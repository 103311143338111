var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mb-3" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _vm.nbLoading > 0
          ? _c("loader-component")
          : _vm.stats.main
          ? _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
              _c(
                "div",
                {
                  staticClass: "row pointer",
                  on: {
                    click: function ($event) {
                      _vm.showBookingsPeriod = !_vm.showBookingsPeriod
                    },
                  },
                },
                [
                  _c(
                    "strong",
                    { staticClass: "col-10 d-flex align-items-center" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$tl("labels.booking.stats.bookingsDelay.title")
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-end align-items-center lead-switch col-2",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-square",
                          class: _vm.pinBookingsPeriod
                            ? "btn-success"
                            : "btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.pinBookingsPeriod = !_vm.pinBookingsPeriod
                            },
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 384 512",
                                width: "18px",
                                height: "18px",
                                fill: "currentColor",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M300.8 203.9L290.7 128H328c13.2 0 24-10.8 24-24V24c0-13.2-10.8-24-24-24H56C42.8 0 32 10.8 32 24v80c0 13.2 10.8 24 24 24h37.3l-10.1 75.9C34.9 231.5 0 278.4 0 335.2c0 8.8 7.2 16 16 16h160V472c0 .7 .1 1.3 .2 1.9l8 32c2 8 13.5 8.1 15.5 0l8-32c.2-.6 .2-1.3 .2-1.9V351.2h160c8.8 0 16-7.2 16-16 .1-56.8-34.8-103.7-83.1-131.3zM33.3 319.2c6.8-42.9 39.6-76.4 79.5-94.5L128 96H64V32h256v64h-64l15.3 128.8c40 18.2 72.7 51.8 79.5 94.5H33.3z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.showBookingsPeriod || _vm.pinBookingsPeriod
                ? _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "pl-1 pl-xl-3 pr-3 pr-xl-1 pb-2",
                        class:
                          _vm.filters.hasComparison && _vm.stats.comparison
                            ? "col-xl-6 col-12"
                            : "col-xl-12",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "p-1 d-flex flex-column align-items-center h-100",
                            class:
                              _vm.filters.hasComparison && _vm.stats.comparison
                                ? "border-light"
                                : "col-xl-12",
                          },
                          [
                            _c("strong", { staticClass: "text-success py-3" }, [
                              _vm._v(_vm._s(_vm.labelPeriodMain)),
                            ]),
                            _vm._v(" "),
                            _vm.stats.main && _vm.stats.main.length === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "h-100 d-flex align-items-center",
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.booking.stats.noDataOnPeriod"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]),
                                  ]
                                )
                              : _c("v-chart", {
                                  staticClass: "chart",
                                  attrs: {
                                    option: {
                                      title: _vm.bookingsPeriod.title,
                                      series: _vm.pieChartDelayResaOnDate,
                                      tooltip: _vm.tooltipPeriod,
                                      grid: _vm.grid,
                                    },
                                  },
                                }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.stats.comparison && _vm.filters.hasComparison
                      ? _c(
                          "div",
                          { staticClass: "col-xl-6 col-12 pl-1 pr-3 pb-2" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-light b-radius-20 p-1 d-flex flex-column align-items-center h-100",
                              },
                              [
                                _c(
                                  "strong",
                                  { staticClass: "text-success py-3" },
                                  [_vm._v(_vm._s(_vm.labelPeriodComparison))]
                                ),
                                _vm._v(" "),
                                _vm.stats.comparison &&
                                _vm.stats.comparison.length === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "h-100 d-flex align-items-center",
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.stats.noDataOnPeriod"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("v-chart", {
                                  staticClass: "chart",
                                  attrs: {
                                    option: {
                                      title: _vm.bookingsPeriod.title,
                                      series:
                                        _vm.pieChartDelayResaOnDateComparison,
                                      tooltip: _vm.tooltipPeriod,
                                      grid: _vm.grid,
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "pl-1 pl-xl-3 pr-3 pr-xl-1 pb-2 pb-xl-0",
                        class:
                          _vm.filters.hasComparison && _vm.stats.comparison
                            ? "col-xl-6 col-12"
                            : "col-xl-12",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "p-1 d-flex flex-column align-items-center h-100",
                            class:
                              _vm.filters.hasComparison && _vm.stats.comparison
                                ? "border-light"
                                : "col-xl-12",
                          },
                          [
                            _c("strong", { staticClass: "text-success py-3" }, [
                              _vm._v(_vm._s(_vm.labelPeriodMain)),
                            ]),
                            _vm._v(" "),
                            _vm.statsCreation.main &&
                            _vm.statsCreation.main.length === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "h-100 d-flex align-items-center",
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.booking.stats.noDataOnPeriod"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]),
                                  ]
                                )
                              : _c("v-chart", {
                                  staticClass: "chart",
                                  attrs: {
                                    option: {
                                      title: _vm.futureBooking.title,
                                      series: _vm.pieChartDelayOnCreationDate,
                                      tooltip: _vm.tooltipPeriod,
                                      grid: _vm.grid,
                                    },
                                  },
                                }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.stats.comparison && _vm.filters.hasComparison
                      ? _c(
                          "div",
                          { staticClass: "col-xl-6 col-12 pl-1 pr-3" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-light b-radius-20 p-1 d-flex flex-column align-items-center h-100",
                              },
                              [
                                _c(
                                  "strong",
                                  { staticClass: "text-success py-3" },
                                  [_vm._v(_vm._s(_vm.labelPeriodComparison))]
                                ),
                                _vm._v(" "),
                                _vm.statsCreation.comparison &&
                                _vm.statsCreation.comparison.length === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "h-100 d-flex align-items-center",
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.stats.noDataOnPeriod"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _c("v-chart", {
                                      staticClass: "chart",
                                      attrs: {
                                        option: {
                                          title: _vm.futureBooking.title,
                                          series:
                                            _vm.pieChartDelayOnCreationDateComparison,
                                          tooltip: _vm.tooltipPeriod,
                                          grid: _vm.grid,
                                        },
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }