<template>
    <div>
        <loader-component v-if="loadings.appUpdate || loadings.form" />
        <div v-else-if="errors.fetchAppUpdate !== null" class="text-danger">
            {{ errors.fetchAppUpdate }}
        </div>
        <form v-else ref="form" method="post" @submit.prevent="submit()">
            <div class="row">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-2">
                            <div class="col-md-4 pt-1">
                                <label for="update_date">Date de la mise à jour <small>*</small></label>
                            </div>
                            <div class="col-md-8 date-resa-cal top-initial">
                                <date-picker v-model="appUpdate.update_date" name="update_date" />
                                <show-errors class="d-block" :errors="errors.form" errorKey="update_date" />
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-md-4 pt-2">
                                <label for="description">Description <small>*</small></label
                                ><br />
                                <small class="text-muted">Cette description sera affichée sur la page d'accueil des notes de mises à jour.</small>
                            </div>
                            <div class="col-md-8">
                                <textarea-component
                                    v-model="appUpdate.description"
                                    name="description"
                                    :rows="3"
                                    :min-nb-chars="20"
                                    :max-nb-chars="250" />
                                <show-errors class="d-block" :errors="errors.form" errorKey="description" />
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-md-4 pt-2">
                                <label for="modules">Modules concernés</label>
                            </div>
                            <div class="col-md-8">
                                <vue-multiselect
                                    v-model="selectedModules"
                                    :options="availableModules"
                                    :max-height="300"
                                    :multiple="true"
                                    label="label"
                                    track-by="value"
                                    placeholder="Modules concernés"
                                    :searchable="false"
                                    :close-on-select="false"
                                    :show-labels="false"
                                    open-direction="bottom">
                                </vue-multiselect>
                                <show-errors class="d-block" :errors="errors.form" errorKey="modules" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4 pt-2">
                                <label for="content">Contenu <small>*</small></label
                                ><br />
                                <small class="text-muted">
                                    Le contenu sera affiché dans la page de détails de la note de mise à jour.<br />
                                    Vous pouvez organiser la page avec des titres, des paragraphes, ajouter des liens, des images ... </small
                                ><br />
                                <!-- TODO: Not yet implemented on display -->
                                <!-- <small class="text-muted">
                                    Tout comme dans la création de "Nouveautés", si l'id de l'établissement est requis dans un lien, mettre
                                    <code>{restaurant_id}</code> à la place.
                                </small> -->
                            </div>
                            <div class="col-md-8">
                                <editor-with-merge-tags
                                    v-model="appUpdate.content"
                                    allow-fonts
                                    allow-lists
                                    allow-images
                                    allow-info-boxes></editor-with-merge-tags>
                                <show-errors class="d-block" :errors="errors.form" errorKey="content" />
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3 pb-2" v-if="showSaveButton">
                        <div class="col-12" :class="appUpdate.published_at === null ? ['d-flex', 'justify-content-between'] : 'text-right'">
                            <button v-if="appUpdate.published_at === null" type="submit" class="btn btn-success btn-circle">
                                Enregistrer comme brouillon
                            </button>

                            <button
                                type="button"
                                class="btn btn-circle"
                                :class="appUpdate.published_at ? 'btn-success' : 'btn-warning'"
                                @click="submit(true)">
                                {{ appUpdate.published_at === null ? "Enregistrer et publier" : "Enregistrer et republier" }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";
import ShowErrors from "../../errors/ShowErrors.vue";
import DatePicker from "../../forms/DatePicker.vue";
import TextareaComponent from "../../forms/TextareaComponent.vue";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";
import VueMultiselect from "vue-multiselect";
import editorWithMergeTags from "../../forms/editorWithMergeTags.vue";

export default {
    data() {
        return {
            loadings: {
                appUpdate: false,
                form: false,
            },
            errors: {
                fetchAppUpdate: null,
                form: null,
            },
            appUpdate: {
                id: null,
                update_date: null,
                description: "",
                content: "<p></p>",
                modules: [],
                published_at: null,
            },
        };
    },
    mixins: [ModuleTypesEnum],
    props: {
        appUpdateId: {
            type: Number,
            default: null,
        },
        showSaveButton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        availableModules() {
            return this.ALL_MODULES_TYPES_ALL.map((tmpModule) => ({ label: this.getModuleTypeLabel(tmpModule.value), value: tmpModule.value }));
        },
        selectedModules: {
            get() {
                return this.availableModules.filter((availableModules) =>
                    this.appUpdate.modules.some((tmpModule) => tmpModule === availableModules.value)
                );
            },
            set(newVal) {
                const getEnumIndex = (value) => this.ALL_MODULES_TYPES_ALL.findIndex((e) => e.value === value);

                this.appUpdate.modules = this.arrayPluck("value", newVal).sort((a, b) => getEnumIndex(a) - getEnumIndex(b));
            },
        },
    },
    methods: {
        fetchAppUpdate() {
            this.errors.fetchAppUpdate = null;

            if (this.appUpdateId === null) {
                this.$emit("fully-loaded", { appUpdate: this.appUpdate });
                return;
            }

            this.loadings.appUpdate = true;

            this.httpGet(`/api/app_updates/${this.appUpdateId}`, { mustReject: true })
                .then((response) => {
                    Object.assign(this.appUpdate, response.data);

                    this.appUpdate.update_date = this.getDateTime(this.appUpdate.update_date);

                    this.$emit("fully-loaded", { appUpdate: this.appUpdate });
                })
                .catch((error) => (this.errors.fetchAppUpdate = this.getErrorMsgFromErrorResponse(error)))
                .finally(() => (this.loadings.appUpdate = false));
        },
        submit(publish_now = false) {
            this.loadings.form = true;
            this.errors.form = null;

            let promise = null;
            if (this.appUpdateId !== null) {
                promise = this.httpPut(`/api/admin/app_updates/${this.appUpdateId}`, this.getFormattedData(publish_now));
            } else {
                promise = this.httpPost(`/api/admin/app_updates`, this.getFormattedData(publish_now));
            }

            promise.finally(() => {
                this.loadings.form = false;
            });

            this.$emit("submit", { promise });
            return promise;
        },
        getFormattedData(publish_now) {
            const obj = Object.assign({ publish_now }, this.appUpdate);

            obj.update_date = obj.update_date.toISODate();

            return obj;
        },
    },
    created() {
        this.fetchAppUpdate();
    },
    watch: {
        appUpdateId() {
            this.fetchAppUpdate();
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
        DatePicker,
        TextareaComponent,
        VueMultiselect,
        editorWithMergeTags,
    },
};
</script>
