export default {
    data() {
        return {
            SERVICE_CATEGORY_BRUNCH: {
                value: "brunch",
            },
            SERVICE_CATEGORY_MIDI: {
                value: "midi",
            },
            SERVICE_CATEGORY_SOIR: {
                value: "soir",
            },
            SERVICE_CATEGORY_AUTRE: {
                value: "autre",
            },
        };
    },
    methods: {
        getDefaultServiceCategoryLabel(value) {
            const found = this.ALL_DEFAULT_SERVICE_CATEGORIES_WITH_OTHER.find((w) => w.value == value);
            if (typeof found != "undefined") {
                return this.$t(`labels.booking.services.categories.${value}`);
            }
            return value;
        },
    },
    computed: {
        ALL_DEFAULT_SERVICE_CATEGORIES() {
            return [this.SERVICE_CATEGORY_BRUNCH, this.SERVICE_CATEGORY_MIDI, this.SERVICE_CATEGORY_SOIR];
        },
        ALL_DEFAULT_SERVICE_CATEGORIES_WITH_OTHER() {
            return [
                this.SERVICE_CATEGORY_BRUNCH,
                this.SERVICE_CATEGORY_MIDI,
                this.SERVICE_CATEGORY_SOIR,
                this.SERVICE_CATEGORY_AUTRE,
            ];
        },
    },
};
