<template>
    <div>
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <div v-if="menuId !== null">
                        <h5 class="title mt-2">
                            {{ $tl("labels.booking.menus.edit", restaurantId) }} <span class="value">{{ menuName }}</span>
                        </h5>
                    </div>
                    <div v-else>
                        <h5 class="title mt-2">{{ $tl("labels.booking.menus.add", restaurantId) }}</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="row m-0">
            <div class="col-12">
                <menu-form
                    ref="form"
                    @fully-loaded="onFormLoaded"
                    @submit="onFormSubmitted"
                    :restaurant-id="restaurantId"
                    :menu-id="menuId"
                    show-save-button />
            </div>
        </div>
    </div>
</template>

<script>
import menuForm from "../../../../components/menus/menuForm.vue";

export default {
    data() {
        return {
            menuName: null,
        };
    },
    computed: {
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        menuId() {
            return typeof this.$route.params.menu_id !== "undefined" ? Number.parseInt(this.$route.params.menu_id) : null;
        },
    },
    methods: {
        onFormLoaded({ menu }) {
            this.menuName = menu.name;
        },
        onFormSubmitted({ promise }) {
            promise.then((success) => {
                if (success !== false) {
                    this.$router.push({
                        name: "booking.restaurants.settings.menus",
                        params: { restaurant_id: this.restaurantId },
                    });
                }
            });
        },
    },
    components: {
        menuForm,
    },
};
</script>
