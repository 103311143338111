<template>
    <div>
        <div class="row p-1">
            <div class="col-12">
                {{ $tl("labels.booking.reservations.add.date") }}
            </div>
            <div class="col-12 mt-2">
                <LoaderComponent v-if="loading" />
                <span class="text-danger" v-else-if="error">{{ error }}</span>
                <DatePicker
                    v-else
                    :disabledDates="disabledDates"
                    tabindex="1"
                    :highlighted="highlighted"
                    format="ccc DDD"
                    calendarClass="w-auto"
                    :inline="true"
                    class="date-resa-cal p-1"
                    :class="{ 'is-invalid': hasErrors('reservation_date') }"
                    v-model="reservation.reservation_date"
                    @selected="dateSelected" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../LoaderComponent.vue";
import DatePicker from "../../../forms/DatePicker.vue";

export default {
    data() {
        return {
            loading: false,
            error: null,
            days: [],
            ranges: [],
            disabledDates: {
                customPredictor: this.datepickerAvailabilityPredictor,
            },
            highlighted: {
                includeDisabled: false,
            },
        };
    },
    props: {
        restaurant_id: {
            default: null,
        },
        reservation: {
            type: Object,
            required: true,
        },
        formErrors: {
            default: null,
        },
    },
    methods: {
        dateSelected() {
            this.$emit("next-step");
        },
        loadOpeningDays() {
            this.loading = true;
            this.error = null;

            axios
                .get(`/api/restaurants/${this.restaurant_id}/opening`)
                .then((response) => {
                    this.loading = false;
                    this.days = response.data.days ?? [];
                    this.ranges = response.data.ranges;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        datepickerAvailabilityPredictor(date) {
            if (this.days.includes(date.getDay())) {
                return false;
            }

            for (var range of this.ranges) {
                var from = this.getDateTime(range.from, false);
                var to = this.getDateTime(range.to, false);
                var dateLuxon = this.getDateTime(date, false);

                if (from <= dateLuxon && dateLuxon <= to && range.days.includes(date.getDay())) {
                    return false;
                }
            }

            return true;
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
    },
    watch: {
        restaurant_id: {
            immediate: true,
            handler() {
                this.loadOpeningDays();
            },
        },
        date(newVal) {
            this.reservation.reservation_date = this.getDateTime(newVal, false);
            this.$emit("next-step");
        },
    },
    components: {
        DatePicker,
        LoaderComponent,
    },
};
</script>

<style>
.date-resa-cal .vdp-datepicker__calendar {
    box-shadow: none !important;
}
</style>
