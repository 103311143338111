export default {
    data() {
        return {
            SAVE_TYPE_SAVE: {
                value: "save",
                label: "Enregistrer sans envoyer",
            },
            SAVE_TYPE_SEND: {
                value: "send",
                label: "Envoyer maintenant",
            },
            SAVE_TYPE_PROG: {
                value: "prog",
                label: "Programmer l'envoi",
            },
        };
    },
    methods: {
        getSaveTypeLabel(value) {
            const found = this.ALL_SAVE_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_SAVE_TYPES() {
            return [this.SAVE_TYPE_SAVE, this.SAVE_TYPE_SEND, this.SAVE_TYPE_PROG];
        },
    },
};
