<template>
    <tbody>
        <tr
            v-for="(data, index) in filteredDataSource"
            :key="filteredDataSource[keyColumn] || index"
            @click.prevent.stop="$parent.$emit('row-selected', { event: $event, data })"
            :class="{
                pointer: clickable,
                clickable,
                new: data.new,
                updated: data.updated,
            }">
            <td>
                <inline-client :client="data.gv_sale.client" :module-enum="MODULE_TYPE_GIFT_VOUCHERS" />
            </td>
            <td>
                {{ data.gv_sale.numero }}
            </td>
            <td>
                {{ displayDate(DateTime.fromSQL(data.gv_sale.created_at), DATE_SHORT) }}
            </td>
            <td>
                {{ displayProduct(data.gv_sale) }}
            </td>
            <td>
                <span v-if="data.gv_sale.has_beneficiary">
                    {{ data.gv_sale.beneficiary_firstname }}
                    <span class="text-uppercase">{{ data.gv_sale.beneficiary_lastname }}</span>
                </span>
                <span v-else> -- </span>
            </td>
            <td>
                <span v-if="data.status === LOG_MAIL_NOT_DELIVERED.value" class="text-danger">
                    {{
                        $t("labels.booking.reservations.mailLogs.permanent_failed", {
                            type: getReservationMailLogLabel(data.type, restaurantId),
                        })
                    }}
                </span>
                <span v-else-if="data.status === LOG_MAIL_PENDING.value" class="text-warning">
                    {{ $t("labels.booking.reservations.mailLogs.pending", { type: getReservationMailLogLabel(data.type, restaurantId) }) }}
                </span>
                <span v-else-if="data.status === LOG_MAIL_DELIVERED.value">
                    {{
                        $t("labels.booking.reservations.mailLogs.sent_confirmed", {
                            type: getReservationMailLogLabel(data.type, restaurantId),
                        })
                    }}
                </span>
            </td>
            <td>
                <div v-if="isFragmentLoading[data.id]" class="spinner-border feather-20" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <button
                    v-else
                    type="button"
                    class="btn btn-sm btn-outline-secondary btn-square"
                    :disabled="isDisabled || !data.should_be_send_again"
                    @click.prevent.stop="$emit('send-mail', data)">
                    <feather type="send" />
                </button>
            </td>
        </tr>
    </tbody>
</template>
<script>
import ReservationMailLogEnum from "../../../../../mixins/enums/booking/ReservationMailLogEnum.js";
import ClientCivilityEnum from "../../../../../mixins/enums/ClientCivilityEnum.js";
import LogMailStatusEnum from "../../../../../mixins/enums/LogMailStatusEnum.js";
import ModuleTypesEnum from "../../../../../mixins/enums/ModuleTypesEnum.js";
import inlineClient from "../../../../Default/Clients/inlineClient.vue";
import { DateTime } from "luxon";

export default {
    props: {
        filteredDataSource: {
            type: Array,
            required: true,
        },
        clickable: {
            type: Boolean,
            required: true,
        },
        restaurantId: {
            type: Number,
            required: true,
        },
        isFragmentLoading: {
            type: Object,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            required: true,
        },
        keyColumn: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            DateTime,
        };
    },
    components: {
        inlineClient,
    },
    mixins: [ReservationMailLogEnum, ClientCivilityEnum, LogMailStatusEnum, ModuleTypesEnum],
    methods: {
        displayProduct(gvSale) {
            let prefix = "";

            if (gvSale.gv_product === null) {
                return "--";
            }
            if (gvSale.gv_product.type === "menu") {
                prefix = `${gvSale.menu_quantity} x`;
            }

            return prefix.concat(" ", gvSale.gv_product.name);
        },
    },
};
</script>
