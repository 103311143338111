<template>
    <div class="pb-5">
        <LoaderComponent v-if="nbLoading > 0" />
        <template v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1">
                        <h5 v-if="isCreating" class="title mt-2">
                            {{ $tl("labels.clients.add") }}
                        </h5>
                        <h5 v-else class="title mt-2">
                            {{ $tl("labels.clients.editName") }}
                            <span class="value">{{ client.firstname }} {{ client.lastname }}</span>
                        </h5>
                    </div>
                    <div v-if="client.immutable" class="mb-1">
                        <i>{{ $tl("infos.clients.immutable", undefined, { theme: themeUppercased }) }}</i>
                    </div>
                </div>
            </div>
            <LoaderComponent v-if="formLoading" :message="$t('labels.saveInProgress')" />
            <form v-else @submit.prevent="postForm">
                <div class="row m-0 mt-1">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4 mb-3">
                            <div class="row mb-2">
                                <div class="col-md-5">
                                    <label> {{ $tl("labels.form.civility") }} <small v-if="mandatoryFields.civility">*</small> </label>
                                </div>
                                <div class="col-md-7 radio">
                                    <label class="container-box" v-for="clientCivility in ALL_CLIENT_CIVILITIES" :key="clientCivility.value">
                                        <input
                                            type="radio"
                                            name="civility"
                                            :value="clientCivility.value"
                                            v-model="client.civility"
                                            :disabled="!isEditable"
                                            :true-value="clientCivility.value" />
                                        <span class="checkmark"></span>
                                        {{ getClientCivilityLabelLong(clientCivility.value) }}
                                    </label>
                                    <ShowErrors class="d-block" :errors="errors.form" errorKey="civility" />
                                </div>
                            </div>

                            <InputComponent
                                v-model="client.firstname"
                                :required="mandatoryFields.firstname"
                                :label="$t('labels.form.firstname')"
                                :disabled="!isEditable"
                                inputName="firstname"
                                :form-errors="errors.form" />

                            <InputComponent
                                v-model="client.lastname"
                                :required="mandatoryFields.lastname || defaultRequired"
                                :label="$t('labels.form.lastname')"
                                :disabled="!isEditable"
                                inputName="lastname"
                                :form-errors="errors.form" />

                            <InputComponent
                                v-model="client.company"
                                :required="mandatoryFields.company"
                                :label="$t('labels.form.company')"
                                :disabled="!isEditable"
                                inputName="company"
                                :form-errors="errors.form" />

                            <InputComponent
                                v-model="client.email"
                                :required="mandatoryFields.email"
                                :label="$t('labels.form.email')"
                                :disabled="!isEditable"
                                inputName="email"
                                :form-errors="errors.form"
                                inputType="email" />

                            <div class="row mb-3">
                                <div class="col-md-5">
                                    <label>{{ $tl("labels.form.phoneNumber") }} <small v-if="mandatoryFields.tel">*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <TelInputComponent
                                        :required="mandatoryFields.tel"
                                        v-model="client.tel"
                                        :disabled="!isEditable"
                                        inputName="tel"
                                        @set-tel-country="client.tel_country = $event"
                                        :form-errors="errors.form" />
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-5">
                                    <label>{{ $tl("labels.form.lang") }} </label>
                                </div>
                                <div class="col-md-7">
                                    <ChooseLang
                                        v-model="client.locale"
                                        class="d-inline-block"
                                        :disabled="!isEditable"
                                        style="z-index: auto !important"
                                        is-input-mode
                                        should-translate-label />
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-md-5 pt-2">
                                    <label>{{ $tl("labels.form.comment") }}</label>
                                </div>
                                <div class="col-md-7">
                                    <textarea
                                        :disabled="!isEditable"
                                        name="comment"
                                        v-model="client.comment"
                                        class="form-control"
                                        rows="4"></textarea>
                                    <ShowErrors class="d-block" :errors="errors.form" errorKey="comment" />
                                </div>
                            </div>

                            <div v-if="!isCreating && hasBooking" class="row mb-1">
                                <div class="col-md-5">
                                    <label>{{ $t("labels.clients.loyalty_level") }} ({{ $t(`labels.modules.${MODULE_TYPE_BOOKING.value}`) }})</label>
                                </div>
                                <div class="col-md-7">
                                    {{
                                        client[`${MODULE_TYPE_BOOKING.urlValue}_loyalty_level`] !== null
                                            ? getClientLoyaltyLevelLabel(client[`${MODULE_TYPE_BOOKING.urlValue}_loyalty_level`])
                                            : $tl("labels.none")
                                    }}
                                </div>
                            </div>

                            <div v-if="!isCreating && hasClickAndCollect" class="row mb-1">
                                <div class="col-md-5">
                                    <label
                                        >{{ $t("labels.clients.loyalty_level") }} ({{
                                            $t(`labels.modules.${MODULE_TYPE_CLICK_AND_COLLECT.value}`)
                                        }})</label
                                    >
                                </div>
                                <div class="col-md-7">
                                    {{
                                        client[`${MODULE_TYPE_CLICK_AND_COLLECT.urlValue}_loyalty_level`] !== null
                                            ? getClientLoyaltyLevelLabel(client[`${MODULE_TYPE_CLICK_AND_COLLECT.urlValue}_loyalty_level`])
                                            : $tl("labels.none")
                                    }}
                                </div>
                            </div>

                            <div v-if="!isCreating && hasGiftVouchers" class="row mb-1">
                                <div class="col-md-5">
                                    <label
                                        >{{ $t("labels.clients.loyalty_level") }} ({{
                                            $t(`labels.modules.${MODULE_TYPE_GIFT_VOUCHERS.value}`)
                                        }})</label
                                    >
                                </div>
                                <div class="col-md-7">
                                    {{
                                        client[`${MODULE_TYPE_GIFT_VOUCHERS.urlValue}_loyalty_level`] !== null
                                            ? getClientLoyaltyLevelLabel(client[`${MODULE_TYPE_GIFT_VOUCHERS.urlValue}_loyalty_level`])
                                            : $tl("labels.none")
                                    }}
                                </div>
                            </div>

                            <div class="row mb-1">
                                <div class="col-md-5 pt-1">
                                    <label>{{ $tl("labels.clients.type") }} <small v-if="mandatoryFields.type">*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <select-client-type
                                        :readonly="!isEditable"
                                        :restaurant-id="restaurantId"
                                        v-model="client.type"
                                        :required="mandatoryFields.type" />
                                    <ShowErrors class="d-block" :errors="errors.form" errorKey="type" />
                                </div>
                            </div>

                            <switch-input-component
                                v-model="client.is_vip"
                                :label="$t('labels.clients.vip')"
                                inputName="is_vip"
                                :disabled="!isEditable"
                                :wrapper-classes="{ 'mb-2': false }"
                                :form-errors="errors.form" />

                            <SwitchInputComponent
                                v-model="client.is_risky"
                                :label="$t('labels.clients.risky')"
                                inputName="is_risky"
                                :disabled="!isEditable"
                                :wrapper-classes="{ 'mb-2': false }"
                                :form-errors="errors.form" />

                            <SwitchInputComponent
                                v-model="client.newsletter"
                                :label="$t('labels.form.acceptNewsletterShort')"
                                inputName="newsletter"
                                :disabled="!isEditable"
                                :wrapper-classes="{ 'mb-2': false }"
                                :form-errors="errors.form" />

                            <div class="row mt-3">
                                <div class="col-12">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <button :disabled="!isEditable" type="submit" class="btn btn-success btn-circle d-inline-block">
                                                {{ $tl("labels.form.actions.save") }}
                                            </button>
                                            <button
                                                v-if="isEditing"
                                                type="button"
                                                class="btn btn-success btn-circle ml-2"
                                                :disabled="!isEditable"
                                                @click="showMergeClientModal = true">
                                                {{ $tl("labels.clients.merge.single") }}
                                            </button>
                                            <router-link :to="{ name: `${saas}.clients` }" class="btn btn-secondary btn-circle ml-2">
                                                {{ $tl("labels.form.actions.cancel") }}
                                            </router-link>
                                        </div>
                                        <div v-if="isOwner && isEditing">
                                            <button
                                                :disabled="!isEditable"
                                                type="button"
                                                class="btn btn-danger btn-circle ml-2"
                                                @click="deleteClientData">
                                                {{ $tl("labels.clients.deleteData") }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!-- Client History -->
            <template v-if="isEditing">
                <div class="row m-0 mt-3">
                    <div class="col-12" v-if="hasBooking">
                        <div class="border-light b-radius-20 p-4 mb-3">
                            <div class="row mb-2">
                                <div class="col-12">
                                    <strong>{{ $tl("labels.recap") }}</strong>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="media align-items-stretch border-light mt-2 mb-1">
                                        <div class="bg-light-grey p-3 text-blue-light media-middle">
                                            <feather type="check" />
                                        </div>
                                        <div class="media-body p-3">
                                            <p class="mt-1 mb-1">
                                                {{ $tl("labels.booking.reservations.over") }}
                                            </p>
                                        </div>
                                        <div class="media-right p-3 media-middle border-left-light">
                                            <h5 class="mt-1 mb-0">{{ client.nb_reservations }}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="media align-items-stretch border-light mt-2 mb-1">
                                        <div class="bg-light-grey text-blue-light p-3 media-middle">
                                            <feather type="x" />
                                        </div>
                                        <div class="media-body p-3">
                                            <p class="mt-1 mb-1">
                                                {{ $tl("labels.booking.reservations.canceled") }}
                                            </p>
                                        </div>
                                        <div class="media-right p-3 media-middle border-left-light">
                                            <h5 class="mt-1 mb-0">{{ client.nb_cancellations }}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="media align-items-stretch border-light mt-2 mb-1">
                                        <div class="bg-light-grey text-blue-light p-3 media-middle">
                                            <feather type="alert-triangle" />
                                        </div>
                                        <div class="media-body p-3 d-flex justify-content-between">
                                            <p class="mt-1 mb-1">
                                                {{ $tl("labels.noshows", this.restaurantId) }}
                                                <small class="text-muted" v-if="client.reset_noshow_count">
                                                    ({{
                                                        $t("labels.clients.resetOn", {
                                                            date: displayDate(client.reset_noshow_count, DATE_SHORT),
                                                        })
                                                    }})
                                                </small>
                                            </p>
                                            <feather
                                                v-if="client.nb_noshows == 0 || !isEditable"
                                                type="rotate-cw"
                                                class="mt-1 feather-20 text-muted"
                                                style="cursor: not-allowed"
                                                v-tooltip="getTooltip($t('labels.clients.resetNoShowCount'))" />
                                            <feather
                                                v-else
                                                type="rotate-cw"
                                                class="mt-1 text-danger feather-20 pointer"
                                                @click="resetNoShowCount"
                                                v-tooltip="getTooltip($t('labels.clients.resetNoShowCount'))" />
                                        </div>
                                        <div class="media-right p-3 media-middle border-left-light">
                                            <h5 class="mt-1 mb-0">{{ client.nb_noshows }}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-12">
                        <ClientHistory
                            class="border-light b-radius-20 p-4 mb-3"
                            :reservations="client.reservations_history"
                            :cc_commands="client.cc_commands_history"
                            :gv_sales="client.gv_sales_history"
                            @show-resa="showResa"
                            @show-cc-command="showCcCommand"
                            @show-gv-sale="showGvSale" />
                    </div>
                </div>
            </template>
        </template>

        <show-reservation-modal
            v-if="showReservationModal"
            :reservation_id="selected_reservation_id"
            :restaurant_id="selected_restaurant_id"
            :showEditButton="resaCanBeEdited"
            @resa-edit="editReservationFromShowModal"
            @close="showReservationModal = false" />

        <edit-reservation-modal
            v-if="showEditReservationModal"
            :restaurant_id="selected_restaurant_id"
            :reservation_id="selected_reservation_id"
            @close="showEditReservationModal = false"
            @reservation-edited="reservationEdited" />

        <ShowClickAndCollectCommand
            v-if="showClickAndCollectModal"
            :command_id="selected_cc_command_id"
            :restaurant_id="selected_restaurant_id"
            @close="showClickAndCollectModal = false" />

        <showGiftVoucherModal
            v-if="showGiftVouchersModal"
            :sale="selected_gv_sale"
            :restaurant_id="selected_restaurant_id"
            @close="showGiftVouchersModal = false" />

        <MergeClientModal
            v-if="client && showMergeClientModal"
            :client="client"
            @close="showMergeClientModal = false"
            @clients-merged="onClientsMerged" />
    </div>
</template>

<script>
import LoaderComponent from "../components/LoaderComponent.vue";
import InputComponent from "../components/forms/InputComponent.vue";
import SwitchInputComponent from "../components/forms/SwitchInputComponent.vue";
import TelInputComponent from "../components/forms/TelInputComponent.vue";
import ShowErrors from "../components/errors/ShowErrors.vue";
import ClientCivilityEnum from "../mixins/enums/ClientCivilityEnum.js";
import ModuleTypesEnum from "../mixins/enums/ModuleTypesEnum.js";
import RolesEnum from "../mixins/enums/RolesEnum.js";
import ReservationStatusEnum from "../mixins/enums/booking/ReservationStatusEnum.js";
import ShowReservationModal from "../components/Modals/reservations/ShowReservationModal.vue";
import EditReservationModal from "../components/Modals/reservations/EditReservationModal.vue";
import ClientHistory from "./ClientHistory.vue";
import ShowClickAndCollectCommand from "../components/Modals/clickAndCollect/ShowClickAndCollectCommand.vue";
import showGiftVoucherModal from "../components/Modals/giftVouchers/showGiftVoucherModal.vue";
import MergeClientModal from "../components/Modals/clients/MergeClientModal.vue";
import ChooseLang from "../components/Default/ChooseLang.vue";
import LangsEnum from "../mixins/enums/LangsEnum.js";
import selectClientType from "../components/forms/clients/selectClientType.vue";
import ClientLoyaltyLevelEnum from "../mixins/enums/ClientLoyaltyLevelEnum.js";
import ClientTypeEnum from "../mixins/enums/ClientTypeEnum.js";

export default {
    name: "ClientsForm",
    data() {
        return {
            nbLoading: 0,
            formLoading: false,
            errors: {
                form: null,
            },

            client: {
                id: null,
                civility: null,
                firstname: null,
                lastname: null,
                company: null,
                email: null,
                newsletter: true,
                tel: null,
                tel_country: null,
                comment: null,
                locale: null,
                is_vip: false,
                is_risky: false,
                type: null,
                immutable: false,
            },

            showMergeClientModal: false,

            showReservationModal: false,
            showEditReservationModal: false,
            selected_reservation_id: null,
            selected_restaurant_id: null,
            resaCanBeEdited: null,

            showClickAndCollectModal: false,
            selected_cc_command_id: null,

            showGiftVouchersModal: false,
            selected_gv_sale: null,

            mandatoryFields: {
                civility: false,
                firstname: false,
                lastname: false,
                company: false,
                email: false,
                type: false,
                tel: false,
            },
            defaultRequired: false,
        };
    },
    mixins: [ClientCivilityEnum, ModuleTypesEnum, RolesEnum, ReservationStatusEnum, LangsEnum, ClientLoyaltyLevelEnum, ClientTypeEnum],
    computed: {
        isOwner() {
            return this.$store.getters["users/role"] === this.ROLE_OWNER.value;
        },
        isCreating() {
            return this.clientId === null;
        },
        isEditing() {
            return this.clientId !== null;
        },
        clientId() {
            return this.$route.params.client_id !== "create" ? this.$route.params.client_id : null;
        },
        hasBooking() {
            return this.$store.getters["restaurants/hasModule"](this.MODULE_TYPE_BOOKING.value, this.restaurantId);
        },
        hasClickAndCollect() {
            return this.$store.getters["restaurants/hasModule"](this.MODULE_TYPE_CLICK_AND_COLLECT.value, this.restaurantId);
        },
        hasGiftVouchers() {
            return this.$store.getters["restaurants/hasModule"](this.MODULE_TYPE_GIFT_VOUCHERS.value, this.restaurantId);
        },
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        saas() {
            return this.$route.name.split(".")[0];
        },
        module() {
            return this.saas.replaceAll("_", "-");
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        isEditable() {
            if (this.client.immutable) {
                return false;
            }

            if (!this.isEditing && !this.rights.includes("default.customer.create")) {
                return false;
            }

            if (this.isEditing && !this.rights.includes("default.customer.update")) {
                return false;
            }

            return true;
        },
    },
    methods: {
        fetchFields() {
            this.nbLoading++;
            this.httpGet(`/api/restaurants/${this.restaurantId}/reservations/input-params/fields?module=${this.module}`, {
                handleReject: false,
            })
                .then((response) => {
                    if (response !== false) {
                        response.data.forEach((field) => {
                            this.mandatoryFields[field.field_name] = field.pivot.is_mandatory;
                        });
                        if (!Object.values(this.mandatoryFields).some((field) => field === true)) {
                            this.defaultRequired = true;
                        }
                    }
                })
                .finally(() => this.nbLoading--);
        },
        fetchClient() {
            this.nbLoading++;

            this.httpGet(
                `/api/restaurants/${this.restaurantId}/clients/${this.clientId}?include=slot,restaurant,gv_product,gv_sale_product_options.gv_products_option`
            )
                .then((response) => {
                    if (response !== false) {
                        _.assign(this.client, response.data);
                    }
                })
                .finally(() => this.nbLoading--);
        },
        postForm() {
            this.formLoading = true;
            this.errors.form = null;

            if (this.isEditing) {
                var promise = this.httpPut(`/api/restaurants/${this.restaurantId}/clients/${this.clientId}?module=${this.saas}`, this.client);
            } else {
                var promise = this.httpPost(`/api/restaurants/${this.restaurantId}/clients/create?module=${this.saas}`, this.client);
            }

            promise
                .then((response) => {
                    if (response !== false) {
                        if (this.isCreating) {
                            this.redirectToClientsList();
                        }
                    }
                })
                .finally(() => (this.formLoading = false));
        },
        resetNoShowCount() {
            if (!this.isEditing || !confirm(this.$tl("questions.clients.resetNoShowCount"))) {
                return;
            }

            this.loading = true;

            this.httpPost(`/api/restaurants/${this.$route.params.restaurant_id}/clients_reset_noshow/${this.clientId}`).then((response) => {
                if (response !== false) {
                    this.fetchClient();
                } else {
                    this.loading = false;
                }
            });
        },
        deleteClientData() {
            if (!this.isEditing || !confirm(this.$tl("questions.common.irreversibleAction"))) {
                return;
            }

            this.loading = true;

            this.httpDelete(`/api/rgpd/clients/${this.clientId}`).then((response) => {
                if (response !== false) {
                    this.redirectToClientsList();
                } else {
                    this.loading = false;
                }
            });
        },
        onClientsMerged() {
            this.showMergeClientModal = false;

            this.redirectToClientsList();
        },
        redirectToClientsList() {
            this.$router.push({
                name: `${this.saas}.clients`,
                params: {
                    restaurant_id: this.restaurantId,
                },
            });
        },
        showResa(resa) {
            this.$set(this, "selected_reservation_id", resa.id);
            this.$set(this, "selected_restaurant_id", resa.restaurant.id);
            this.$set(this, "resaCanBeEdited", this.editableReservationStatus.includes("resa.status"));

            this.showReservationModal = true;
        },
        showCcCommand(command) {
            this.$set(this, "selected_cc_command_id", command.id);
            this.$set(this, "selected_restaurant_id", command.restaurant.id);

            this.showClickAndCollectModal = true;
        },
        showGvSale(sale) {
            sale.client = this.client;

            this.$set(this, "selected_gv_sale", sale);
            this.$set(this, "selected_restaurant_id", sale.restaurant.id);

            this.showGiftVouchersModal = true;
        },
        editReservationFromShowModal({ reservation_id }) {
            this.$set(this, "selected_reservation_id", reservation_id);

            this.$nextTick(() => {
                this.showReservationModal = false;
                this.showEditReservationModal = true;
            });
        },
        reservationEdited() {
            this.showEditReservationModal = false;
            this.notifySuccess(null, this.$tl("success.booking.reservations.edited"));
            this.fetchClient();
        },
    },
    watch: {
        restaurantId() {
            this.fetchClient();
        },
        clientId() {
            this.fetchClient();
        },
    },
    components: {
        LoaderComponent,
        InputComponent,
        SwitchInputComponent,
        TelInputComponent,
        ShowErrors,
        ShowReservationModal,
        EditReservationModal,
        ClientHistory,
        ShowClickAndCollectCommand,
        showGiftVoucherModal,
        MergeClientModal,
        ChooseLang,
        selectClientType,
    },
    created() {
        this.fetchFields();
        if (this.isEditing) {
            this.fetchClient();
        } else {
            this.client.locale = this.userLang;
        }
    },
};
</script>
