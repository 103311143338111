<template>
    <div>
        <div class="row mb-3">
            <div class="col-12">
                <strong>{{
                    $t("labels.form.sms.detailsFor", {
                        packName: $t("labels.invoices.smsPack", { nb: smsPack.nb_sms }),
                    })
                }}</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        {{ $tl("labels.form.sms.packName") }}
                    </div>
                    <div class="col-8 value">
                        {{ $t("labels.invoices.smsPack", { nb: smsPack.nb_sms }) }}
                    </div>
                </div>
                <div class="sepa"></div>
                <div class="row">
                    <div class="col-4">
                        {{ $tl("labels.priceTTC") }}
                    </div>
                    <div class="col-8 value">{{ formatCurrency(smsPack.price / 100) }}</div>
                </div>
                <div class="sepa"></div>
                <div class="row">
                    <div class="col-4">
                        {{ $tl("labels.priceHT") }}
                    </div>
                    <div class="col-8 value">{{ formatCurrency(smsPack.price_ht / 100) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ShowSmsPack",
    props: {
        smsPack: {
            default: "",
        },
    },
    methods: {},
};
</script>
