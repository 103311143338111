var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row p-1" }, [
      _vm.errors.seatingPlan || _vm.vueMultiSelect.isInvalid
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm.errors.seatingPlan
              ? _c("p", {
                  domProps: { innerHTML: _vm._s(_vm.errors.seatingPlan) },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.vueMultiSelect.isInvalid
              ? _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$tl(
                        "labels.booking.reservations.edit.tableNotAvailable",
                        _vm.restaurant_id
                      )
                    )
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-12" }, [
        _vm.reservation.service &&
        _vm.reservation.service.is_table_rotation_enabled == true
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$tl(
                        `labels.booking.reservations.${
                          _vm.isPassingCustomer ? "passingCustomer" : "edit"
                        }.duration`
                      )
                    ) + "\n                        "
                  ),
                  _c("small", [_vm._v("*")]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("vue-timepicker", {
                    attrs: {
                      format: "HH:mm",
                      "minute-interval": 15,
                      language: _vm.fr,
                      disabled: _vm.hasTablesSelected,
                      lazy: true,
                    },
                    on: {
                      change: function ($event) {
                        _vm.reservation.is_duration_customized = true
                      },
                    },
                    model: {
                      value: _vm.reservation.duration,
                      callback: function ($$v) {
                        _vm.$set(_vm.reservation, "duration", $$v)
                      },
                      expression: "reservation.duration",
                    },
                  }),
                  _vm._v(" "),
                  _c("small", { staticClass: "d-block text-muted" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$tl(
                          `infos.booking.reservations.edit${
                            _vm.isPassingCustomer ? ".passingCustomer" : ""
                          }.duration`
                        )
                      )
                    ),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 mt-2" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _vm._v(
              "\n                    " +
                _vm._s(
                  _vm.$tl(
                    "labels.booking.reservations.edit.table",
                    _vm.restaurant_id
                  )
                ) +
                "\n                    "
            ),
            _vm.reservation.service &&
            _vm.reservation.service.is_seating_plan_algorithm_enabled
              ? _c(
                  "div",
                  {
                    staticClass: "mt-2 text-muted",
                    staticStyle: { "font-size": "11px" },
                  },
                  [
                    _vm.loading.can_place
                      ? _c("LoaderComponent")
                      : _vm.can_place && !_vm.isPassingCustomer
                      ? _c("span", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$tl(
                                  "infos.booking.reservations.add.willBePlacedAuto",
                                  _vm.restaurant_id
                                )
                              ) +
                              "\n                        "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6" }, [
            _vm.reservation.service &&
            _vm.nb_pers_total > 0 &&
            _vm.reservation.service.has_seating_plan == true
              ? _c(
                  "div",
                  [
                    _vm.loading.tables
                      ? _c("LoaderComponent")
                      : _vm.errors.tables
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.errors.tables)),
                        ])
                      : [
                          _c(
                            "vue-multiselect",
                            {
                              staticStyle: { "font-size": "12.8px !important" },
                              attrs: {
                                options: _vm.tablesMultiselectOptions,
                                multiple: true,
                                label: "name",
                                "track-by": "name",
                                "group-values": "entities",
                                "group-label": "roomName",
                                "group-select": false,
                                "close-on-select": false,
                                "clear-on-select": false,
                                "preserve-search": true,
                                "show-labels": false,
                                placeholder: _vm.$tl(
                                  "labels.booking.seatingPlan.tables",
                                  _vm.restaurant_id
                                ),
                                "custom-label": _vm.customTablesLabel,
                                "open-direction": "bottom",
                                disabled: _vm.reservation.ignore_placement,
                              },
                              on: {
                                select: function ($event) {
                                  return _vm.tableSelected($event)
                                },
                                remove: function ($event) {
                                  return _vm.tableUnselected($event)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "option",
                                    fn: function (props) {
                                      return [
                                        !props.option.$isLabel
                                          ? [
                                              _c(
                                                "div",
                                                { staticClass: "float-left" },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        props.option.type ===
                                                          "table"
                                                          ? "Table"
                                                          : "Groupe"
                                                      ) +
                                                      "\n                                            " +
                                                      _vm._s(
                                                        props.option.name
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              props.option.enabledPax.length > 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "float-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.capitalize(
                                                              _vm.$tl(
                                                                "labels.pax"
                                                              )
                                                            )
                                                          ) +
                                                          " :\n                                            "
                                                      ),
                                                      _vm._l(
                                                        props.option.enabledPax,
                                                        function (item, index) {
                                                          return [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  index > 0
                                                                    ? " "
                                                                    : ""
                                                                ) +
                                                                _vm._s(
                                                                  props.option
                                                                    .enabledPax
                                                                    .length -
                                                                    1 ===
                                                                    index
                                                                    ? `${item}`
                                                                    : `${item},`
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "float-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.capitalize(
                                                            _vm.$tl(
                                                              "labels.pax"
                                                            )
                                                          )
                                                        ) +
                                                          " : " +
                                                          _vm._s(
                                                            props.option.seats
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          : [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.option.$groupLabel
                                                  )
                                                ),
                                              ]),
                                            ],
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2983399174
                              ),
                              model: {
                                value: _vm.vueMultiSelect.entitiesSelected,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.vueMultiSelect,
                                    "entitiesSelected",
                                    $$v
                                  )
                                },
                                expression: "vueMultiSelect.entitiesSelected",
                              },
                            },
                            [
                              _c("template", { slot: "noOptions" }, [
                                !_vm.reservation.slot_id
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.booking.reservations.selectSlot"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(_vm.$tl("labels.noResult")) +
                                          "\n                                    "
                                      ),
                                    ]),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.reservation.service &&
                          _vm.reservation.service
                            .is_seating_plan_algorithm_enabled &&
                          !_vm.loading.can_place &&
                          !_vm.can_place &&
                          _vm.vueMultiSelect.entitiesSelected.length === 0
                            ? _c("div", [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "container-box text-danger mt-2 mb-1 w-auto",
                                  },
                                  [
                                    _c("input", {
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked:
                                          _vm.reservation.ignore_placement,
                                      },
                                      on: { change: _vm.toggleIgnorePlacement },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "checkmark" }),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.seatingPlan.ignorePlacement"
                                        )
                                      ) + "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "text-danger" }, [
                                  _c("small", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "infos.booking.seatingPlan.ignorePlacement"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                  ],
                  2
                )
              : _vm.nb_pers_total > 0 &&
                _vm.reservation.service &&
                _vm.reservation.service.has_seating_plan == false
              ? _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-md-6 pt-2" }, [
                    _c("label", { attrs: { for: "num_table" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$tl("labels.booking.seatingPlan.table.title")
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reservation.num_table,
                          expression: "reservation.num_table",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.hasErrors("num_table") },
                      attrs: {
                        id: "num_table",
                        type: "text",
                        tabindex: "4",
                        autocomplete: "off",
                      },
                      domProps: { value: _vm.reservation.num_table },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.reservation,
                            "num_table",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.hasErrors("num_table")
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.formErrors.errors.num_table,
                            function (err) {
                              return _c(
                                "div",
                                { staticClass: "invalid-feedback d-block" },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(err) +
                                      "\n                                "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.vueMultiSelect.paxFull && !_vm.isPassingCustomer
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.$tl("labels.booking.reservations.fullyPlaced"))
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 mt-2" }),
      _vm._v(" "),
      _vm.reservation.service && _vm.reservation.slot && _vm.nb_pers_total > 0
        ? _c(
            "div",
            { staticClass: "col-12" },
            [
              _vm.loading.menus
                ? _c("LoaderComponent")
                : _vm.available_menus && _vm.available_menus.length > 0
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 mt-3 text-muted" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.reservations.add.chooseMenus",
                              _vm.restaurant_id
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 mt-2" },
                      [
                        _c("menus-reservation", {
                          staticClass: "pl-1",
                          attrs: {
                            "restaurant-id": Number.parseInt(_vm.restaurant_id),
                            formErrors: _vm.formErrors,
                            available_menus: _vm.available_menus,
                            reservation: _vm.reservation,
                            nb_total: _vm.nb_pers_total,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.reservation.service && _vm.reservation.slot && _vm.nb_pers_total > 0
        ? _c(
            "div",
            { staticClass: "col-12" },
            [
              _vm.loading.general_options
                ? _c("LoaderComponent")
                : _vm.available_general_options &&
                  _vm.available_general_options.length > 0
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 mt-1 text-muted" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.reservations.add.chooseGeneralOptions"
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 mt-2" },
                      [
                        _c("general-options-reservation", {
                          staticClass: "pl-1",
                          attrs: {
                            "restaurant-id": Number.parseInt(_vm.restaurant_id),
                            formErrors: _vm.formErrors,
                            available_general_options:
                              _vm.available_general_options,
                            reservation: _vm.reservation,
                            nb_total: _vm.nb_pers_total,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }