<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Zone</label>
                                </div>
                                <div class="col-md-7">
                                    <select class="custom-select" v-model="area.area">
                                        <option :value="area.id === null ? null : area.area">
                                            {{ area.id === null ? "--" : getAreaLabel(area.area) }}
                                        </option>
                                        <option
                                            v-for="available_area in available_areas"
                                            :key="available_area"
                                            :value="available_area">
                                            {{ getAreaLabel(available_area) }}
                                        </option>
                                    </select>
                                    <ShowErrors :errors="errors" errorKey="area" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Frais d'expédition TTC</label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="number" class="form-control" v-model="area.fees" />
                                        </div>
                                        <div class="col-md-8 mt-2">euros</div>
                                        <ShowErrors class="col-12" :errors="errors" errorKey="fees" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5 pt-1">
                                    <label>Délai d'envoi</label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="number" class="form-control" v-model="area.shipping_time" />
                                        </div>
                                        <div class="col-md-8 mt-2">jours</div>
                                        <ShowErrors class="col-12" :errors="errors" errorKey="shipping_time" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent";
import ShowErrors from "../errors/ShowErrors";

export default {
    data() {
        return {
            loading: false,
            error: null,
            errors: null,
            available_areas: null,
        };
    },
    props: {
        area: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getAreaLabel(area) {
            switch (area) {
                case "fr":
                    return "France";
                case "int":
                    return "International";
                default:
                    return "";
            }
        },
        fetchAreas() {
            this.loading = true;
            this.error = null;
            this.available_areas = null;

            axios
                .get(`/api/gift_vouchers/${this.$route.params.restaurant_id}/config/availableDispatchAreas`)
                .then((response) => {
                    this.available_areas = response.data.available_areas;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = error.response.data.message
                        ? error.response.data.message
                        : "Une erreur est survenue lors de la récupération des services";
                });
        },
        save() {
            this.loading = true;
            this.errors = null;

            if (this.area.id === null) {
                axios
                    .post(`/api/gift_vouchers/${this.$route.params.restaurant_id}/config/dispatchAreas`, this.builtData)
                    .then((response) => {
                        this.loading = false;
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: response.data.message,
                        });
                        this.$emit("saved");
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.errors = error.response.data.errors;
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: error.response.data.message
                                ? error.response.data.message
                                : "Une erreur est survenue",
                        });
                    });
            } else {
                axios
                    .put(
                        `/api/gift_vouchers/${this.$route.params.restaurant_id}/config/dispatchAreas/${this.area.id}`,
                        this.builtData
                    )
                    .then((response) => {
                        this.loading = false;
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: response.data.message,
                        });
                        this.$emit("saved");
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.errors = error.response.data.errors;
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: error.response.data.message
                                ? error.response.data.message
                                : "Une erreur est survenue",
                        });
                    });
            }
        },
    },
    computed: {
        builtData() {
            let data = {};
            Object.assign(data, this.area);
            return data;
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
    created() {
        this.fetchAreas();
    },
};
</script>
