<template>
    <div>
        <div class="row bg-light text-center text-uppercase nav-campaign">
            <div
                :class="
                    step === 1 ? 'nav-campaign-item col-sm-4 pt-3 pb-3 active' : 'nav-campaign-item col-sm-4 pt-3 pb-3'
                ">
                1 - Composez votre message
            </div>
            <div
                :class="
                    step === 2 ? 'nav-campaign-item col-sm-4 pt-3 pb-3 active' : 'nav-campaign-item col-sm-4 pt-3 pb-3'
                ">
                2 - Choisissez votre cible
            </div>
            <div
                :class="
                    step === 3 ? 'nav-campaign-item col-sm-4 pt-3 pb-3 active' : 'nav-campaign-item col-sm-4 pt-3 pb-3'
                ">
                3 - Diffuser votre campagne
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <keep-alive>
            <component
                v-if="campaign && !loading"
                :is="currentStepComponent"
                ref="currentStepComponent"
                :errors="errors"
                @set-errors="errors = $event"
                :step="step"
                :campaign="campaign"
                :clients="clients" />
        </keep-alive>

        <InternationalSmsCostModal
            v-if="showInternationalSmsCostModal"
            @close="showInternationalSmsCostModal = false" />
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent";
import Step1 from "./Steps/Step1ComposeMessage";
import Step2 from "./Steps/Step2ChooseTargets";
import Step3 from "./Steps/Step3Send";
import CampaignTypesEnum from "../../../mixins/enums/campaigns/CampaignTypesEnum";
import SaveTypesEnum from "../../../mixins/enums/campaigns/SaveTypesEnum";

export default {
    data() {
        return {
            loading: false,
            errors: null,
            campaign: null,
            clients: [],
            showInternationalSmsCostModal: false,
        };
    },
    mixins: [CampaignTypesEnum, SaveTypesEnum],
    props: {
        step: {
            type: Number,
            required: true,
        },
        campaign_id: {
            default: null,
        },
    },
    computed: {
        currentStepComponent() {
            if (this.step <= 3 && this.step > 0) return `Step${this.step}`;
        },
        builtData() {
            let data = {
                ...this.campaign,
            };
            data.img_path = data.img_path ? this.fileNameOnly(data.img_path) : null;
            return data;
        },
    },
    methods: {
        defaultCampaign() {
            return {
                id: null,
                name: "",
                type: this.CAMPAIGN_TYPE_EMAIL.value,
                mail_object: null,
                sms_expeditor: "",
                content: "",
                img_path: null,
                img_link: null,
                will_be_send_at: null,
                sent_at: null,
                send_to_foreign_numbers: false,
                send_to_owners_only: 1,
                send_to_trials_only: false,
                send_to_active_owners_only: 1,
                send_to_plan: null,
                has_modules: [],
                doesnt_have_modules: [],
                save_type: this.SAVE_TYPE_SEND.value,
            };
        },
        initData() {
            this.campaign = this.$_.cloneDeep(this.defaultCampaign());
            const campaignId = this.campaign_id || "none";

            this.loading = true;
            axios
                .get(`/api/campaigns/admin/campaigns/${campaignId}`)
                .then((response) => {
                    this.loading = false;
                    if (this.campaign_id) {
                        this.$_.assign(this.campaign, this.$_.cloneDeep(response.data.campaign));
                        if (response.data.campaign.will_be_send_at) this.campaign.save_type = this.SAVE_TYPE_PROG.value;
                    }
                    this.clients = response.data.clients.data;
                })
                .catch((error) => {
                    this.loading = false;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
        save(save_type = undefined) {
            this.loading = true;
            this.errors = null;

            if (save_type) this.campaign.save_type = save_type;
            if (this.campaign.id)
                var promise = axios.put(`/api/campaigns/admin/campaigns/${this.campaign.id}`, this.builtData);
            else var promise = axios.post("/api/campaigns/admin/campaigns", this.builtData);
            promise
                .then((response) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.$emit("saved");
                })
                .catch((error) => {
                    this.loading = false;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
    },
    components: {
        LoaderComponent,
        Step1,
        Step2,
        Step3,
    },
    watch: {
        "campaign.save_type": {
            immediate: true,
            handler(newVal) {
                this.$emit("set-save-type", newVal);
            },
        },
        loading(newVal) {
            this.$emit("set-loading", newVal);
        },
        step(newVal) {
            if (newVal === 3) {
                this.$nextTick(() => this.$refs.currentStepComponent.fetchTargets());
            }
        },
    },
    created() {
        this.initData();
    },
};
</script>

<style>
.positition-relative {
    position: relative !important;
}
button.btn:disabled {
    cursor: default;
}
</style>
