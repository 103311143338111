var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(_vm._s(_vm.$tl("infos.sms.foreignCostTab"))),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "body" }, slot: "body" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _c(
            "table",
            { staticClass: "table table-book table-striped table-sm" },
            [
              _c("thead", { staticClass: "border-bottom" }, [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$tl("labels.form.country")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$tl("labels.creditCost")))]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.costPerCountryFormattedLeft, function (cost) {
                  return _c("tr", { key: cost.countryCode }, [
                    _c("td", [_vm._v(_vm._s(cost.countryName))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(cost.cost))]),
                  ])
                }),
                0
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c(
            "table",
            { staticClass: "table table-book table-striped table-sm" },
            [
              _c("thead", { staticClass: "border-bottom" }, [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$tl("labels.form.country")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$tl("labels.creditCost")))]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm._l(_vm.costPerCountryFormattedRight, function (cost) {
                    return _c("tr", { key: cost.countryCode }, [
                      _c("td", [_vm._v(_vm._s(cost.countryName))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(cost.cost))]),
                    ])
                  }),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$tl("labels.restOfTheWorld"))),
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("1,50")]),
                  ]),
                ],
                2
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn-sm btn btn-secondary btn-circle",
            on: { click: _vm.close },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.close")) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }