var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading ? _c("LoaderComponent") : _vm._e(),
      _vm._v(" "),
      _vm.loadingPublish
        ? _c("div", { staticClass: "text-center" }, [
            _c("h5", [
              _vm._v(
                "Votre site est en cours de déploiement. Cette opération peut durer plusieurs minutes."
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0" }, [
              _vm.website_enabled
                ? _c("div", { staticClass: "col-12 mb-3" }, [
                    _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 mb-2" },
                          [
                            _c("horizontal-stepper", {
                              ref: "stepper",
                              attrs: {
                                steps: _vm.steps,
                                currentStep: { index: _vm.step - 1 },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 mb-3" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row mb-2" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7 mb-3" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-block align-middle lead-switch",
                        },
                        [
                          _c("input", {
                            staticClass: "switch align-self-center is-rounded",
                            attrs: {
                              disabled: !_vm.has_right_to_update_config,
                              type: "checkbox",
                            },
                            domProps: { checked: _vm.website_enabled },
                          }),
                          _vm._v(" "),
                          _c("label", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.getTooltip(
                                  "Activer / désactiver le site web"
                                ),
                                expression:
                                  "getTooltip('Activer / désactiver le site web')",
                              },
                            ],
                            on: { click: _vm.toggleWebsiteActivation },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.website_enabled
                      ? _c("div", { staticClass: "col-md-5" }, [
                          _vm._m(2),
                          _vm._v(" "),
                          _vm._m(3),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.website_enabled
                      ? _c(
                          "div",
                          { staticClass: "col-md-7 mb-1" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.website_settings.domain_name,
                                  expression: "website_settings.domain_name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                disabled:
                                  !_vm.has_right_to_update_config ||
                                  !_vm.stepsAllowingDomainNameEdit.includes(
                                    _vm.website_settings.deploy_step
                                  ),
                                type: "text",
                                maxlength: "180",
                                id: "txt-website-domain-name",
                                "aria-describedby": "help-website-domain-name",
                                placeholder: "monsite.ext",
                              },
                              domProps: {
                                value: _vm.website_settings.domain_name,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.website_settings,
                                    "domain_name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("ShowErrors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors,
                                errorKey: "domain_name",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.website_enabled
                    ? _c(
                        "div",
                        { staticClass: "row btn-website-container mt-3" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-between",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-outline-secondary btn-sm btn-circle",
                                  attrs: {
                                    type: "button",
                                    disabled: !_vm.has_right_to_read_config,
                                  },
                                  on: { click: _vm.previewWebsite },
                                },
                                [
                                  _vm._v(
                                    "\n                                Pré-visualiser le site\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-success btn-sm btn-circle",
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      !_vm.has_right_to_publish_config ||
                                      _vm.website_settings.deploy_step ==
                                        _vm.SETTINGS_STEP_WEBSITE_SETTINGS
                                          .value,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showConfigDnsModal = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Configurer mon domaine\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-success btn-sm btn-circle",
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      !_vm.has_right_to_publish_config ||
                                      (_vm.step < 4 &&
                                        !_vm.website_settings
                                          .already_puslished),
                                  },
                                  on: { click: _vm.publish },
                                },
                                [
                                  _vm._v(
                                    "\n                                Publier mon site\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-success btn-sm btn-circle",
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      _vm.step < 5 &&
                                      !_vm.website_settings.already_puslished,
                                  },
                                  on: { click: _vm.viewWebsite },
                                },
                                [
                                  _vm._v(
                                    "\n                                Voir mon site\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.website_enabled
              ? _c("div", { staticClass: "row m-0 mt-3" }, [
                  _c("div", { staticClass: "col-12 d-inline-block" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success btn-circle mr-1",
                        attrs: {
                          type: "button",
                          disabled: !_vm.has_right_to_update_config,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.postForm()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Enregistrer\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(4),
                  ]),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showConfigDnsModal
        ? _c("config-dns-modal", {
            attrs: { website_settings: _vm.website_settings },
            on: {
              close: function ($event) {
                _vm.showConfigDnsModal = false
              },
              verified: function ($event) {
                _vm.showConfigDnsModal = false
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [
            _c("h5", { staticClass: "title mt-2" }, [
              _vm._v("Configuration du domaine"),
            ]),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5" }, [
      _c("label", { attrs: { for: "chk-history-enabled" } }, [
        _c("strong", [_vm._v("Configuration du site web")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "mb-0", attrs: { for: "txt-website-domain-name" } },
      [
        _vm._v("Nom de domaine "),
        _c("small", { staticClass: "text-danger" }, [_vm._v("*")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "small",
      {
        staticClass: "form-text text-warning",
        attrs: { id: "help-website-domain-name" },
      },
      [
        _vm._v("Votre nom de domaine "),
        _c("strong", [_vm._v("ne doit pas")]),
        _vm._v(" être précédé de 'www' !"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", [
      _vm._v(
        'Pour rendre vos modifications visibles sur votre site internet, pensez à "'
      ),
      _c("strong", [_vm._v("Enregistrer et publier")]),
      _vm._v(
        '" en\n                    cliquant sur le bouton dans la navigation.'
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }