var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "tile module-product px-0 border-light h-100" }, [
      _c("div", { staticClass: "hoverable position-relative" }, [
        _c("h6", { staticClass: "title py-4 border-bottom-light" }, [
          _vm._v(_vm._s(_vm.getModuleTitle(_vm.module))),
        ]),
        _vm._v(" "),
        _vm.onlyOneModule && _vm.count > 0
          ? _c(
              "button",
              {
                staticClass: "btn top-right p-0 m-1",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.count = 0
                  },
                },
              },
              [
                _c("feather", {
                  staticClass: "icon w-auto h-auto bg-none",
                  attrs: { type: "x", size: "20" },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "p-3" },
          [
            !_vm.disablePlan
              ? _c("toggle-switch", {
                  staticClass: "w-100 mb-3",
                  attrs: {
                    group: `${_vm.module.type}-${_vm.module.id}`,
                    options: _vm.toggleSwitchOptions,
                  },
                  model: {
                    value: _vm.planLabel,
                    callback: function ($$v) {
                      _vm.planLabel = $$v
                    },
                    expression: "planLabel",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _vm.max !== 1
                  ? [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.count,
                              expression: "count",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "custom-select mb-1 mr-2",
                          staticStyle: { width: "inherit" },
                          attrs: {
                            id: `licence-count-${_vm.module.type}-${_vm.module.id}`,
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return _vm._n(val)
                                })
                              _vm.count = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(
                          _vm.$_.range(_vm.max ? _vm.max + 1 : 6),
                          function (value, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: value } },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(value) +
                                    "\n                            "
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$tl("labels.form.licences.titleSingular"))
                        ),
                      ]),
                    ]
                  : _c(
                      "label",
                      {
                        staticClass: "container-box w-auto",
                        staticStyle: { "padding-left": "20px !important" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.count,
                              expression: "count",
                              modifiers: { number: true },
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: `licence-count-${_vm.module.type}-${_vm.module.id}`,
                          },
                          domProps: {
                            value: 1,
                            checked: Array.isArray(_vm.count)
                              ? _vm._i(_vm.count, 1) > -1
                              : _vm.count,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.count,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = _vm._n(1),
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.count = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.count = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.count = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkmark" }),
                      ]
                    ),
              ],
              2
            ),
            _vm._v(" "),
            !_vm.disablePayment
              ? _c("h5", { staticClass: "value" }, [
                  _vm.discountedPrice
                    ? _c("span", [
                        _c("s", [
                          _vm._v(
                            _vm._s((_vm.priceNoReduction / 100).toFixed(2)) +
                              "\n                            "
                          ),
                          _c("small", [
                            _vm._v(
                              _vm._s(_vm.$tl("labels.ht")) +
                                " / " +
                                _vm._s(_vm.$tl(`labels.${_vm.keyForLabels}`))
                            ),
                          ]),
                        ]),
                        _c("br"),
                      ])
                    : _vm._e(),
                  _vm._v(
                    _vm._s((_vm.price / 100).toFixed(2)) +
                      "\n                    "
                  ),
                  _c("small", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$tl("labels.ht")) +
                        " / " +
                        _vm._s(_vm.$tl(`labels.${_vm.keyForLabels}`))
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.module.description
              ? _c("p", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.module.description) +
                      "\n                "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.count > 0 && !_vm.disablePayment
          ? _c("h5", { staticClass: "mb-0 pt-3 pb-3 border-top-light" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$tl("labels.total")) +
                  " : " +
                  _vm._s((_vm.priceComputed / 100).toFixed(2)) +
                  "€\n                "
              ),
              _c("small", [
                _vm._v(
                  _vm._s(_vm.$tl("labels.ht")) +
                    " / " +
                    _vm._s(_vm.$tl(`labels.${_vm.keyForLabels}`))
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }