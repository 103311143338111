<template>
    <div>
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <div>
                        <h5 class="title mt-2">Chiffre d'affaires par licence</h5>
                    </div>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div class="row m-0" v-else>
            <div class="col-12">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <form @submit="fetchData">
                        <div>
                            Renouvellement&nbsp;
                            <select class="custom-select d-inline-block" style="width: auto" v-model="filter.plan">
                                <option :value="null">Tous</option>
                                <option value="yearly">Annuel</option>
                                <option value="monthly">Mensuel</option>
                            </select>
                            &nbsp;Module&nbsp;
                            <select class="custom-select d-inline-block" style="width: auto" v-model="filter.module">
                                <option :value="null">Tous</option>
                                <option :value="type.value" v-for="type in ALL_MODULES_TYPES_WITH_OPTIONS" :key="type.value">
                                    {{ type.label }}
                                </option>
                            </select>
                        </div>
                        Du&nbsp;
                        <datepicker
                            class="d-inline-block"
                            format="dd/MM/yyyy"
                            input-class="form-control"
                            :monday-first="true"
                            :language="fr"
                            v-model="filter.from_date"></datepicker>
                        &nbsp;au&nbsp;
                        <datepicker
                            class="d-inline-block"
                            format="dd/MM/yyyy"
                            input-class="form-control"
                            :monday-first="true"
                            :language="fr"
                            v-model="filter.to_date"></datepicker>
                        <button type="submit" class="btn btn-sm btn-success btn-circle ml-2">Rechercher</button>
                        <button @click="resetFilter" type="button" class="btn btn-sm btn-success btn-circle ml-2">Réinitialiser les filtres</button>
                        <button @click="exportSubscriptionsPerMonth" type="button" class="btn btn-sm btn-success btn-circle ml-2">
                            Exporter les abonnements par mois
                        </button>
                    </form>
                </div>
            </div>
            <div class="col-12" v-if="subscriptions">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <div class="d-flex justify-content-between">
                        <div>
                            <strong class="d-block mt-1">Chiffre d'affaires total : {{ formatCurrency(totalCA / 100) }}</strong>
                            <span class="d-block mt-1">Nombre de clients : {{ nbDistinctOwnerWithActiveLicences }}</span>
                            <span class="d-block mt-1">Nombre d'abonnements actifs : {{ nbActiveLicences }}</span>
                        </div>
                        <a target="_blank" :href="exportUrl"><button class="btn btn-sm btn-success btn-circle">Export excel</button></a>
                    </div>
                </div>
                <SortableTable tableClass="table table-sm table-striped border-bottom" :columns="columns" :data="formattedData" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import LoaderComponent from "../LoaderComponent";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum";
import StripePlanTypesEnum from "../../mixins/enums/StripePlanTypesEnum";
import SubscriptionStatusEnum from "../../mixins/enums/SubscriptionStatusEnum";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import SortableTable from "../SortableTable";

const defaultFilter = {
    from_date: null,
    to_date: null,
    plan: null,
    module: null,
};

export default {
    data() {
        return {
            loading: 0,
            filter: _.cloneDeep(defaultFilter),
            fr,
            moment,
            subscriptions: null,
            columns: [
                {
                    name: "Compte admin",
                    style: "text-transform: capitalize;",
                    sortable: true,
                    sortable_type: "string",
                    sort_on: "label",
                    data_format: "custom",
                    data_format_fct: (data) => (data.owner ? `${data.owner.firstname} ${data.owner.lastname}` : "Owner inexistant"),
                    get_custom_class: (data) => (data.owner ? "" : "text-danger"),
                },
                {
                    name: "Établissements",
                    sortable: true,
                    sortable_type: "string",
                    sort_on: "label",
                    data_format: "custom",
                    data_format_fct: (data) =>
                        data.subscriptions
                            .map((s) => (s.restaurant ? s.restaurant.name : null))
                            .filter((r) => r)
                            .join("<br/>"),
                },
                {
                    name: "Module",
                    sortable: true,
                    sortable_type: "string",
                    sort_on: "label",
                    data_format: "custom",
                    data_format_fct: (data) => this.getModuleTypeLabel(data.module_type),
                },
                {
                    name: "Engagement",
                    sortable: true,
                    sortable_type: "string",
                    sort_on: "label",
                    data_format: "custom",
                    data_format_fct: (data) => this.getStripePlanTypeLabel(data.subscriptions[0].plan),
                },
                {
                    name: "Licences actives",
                    sortable: true,
                    sortable_type: "int",
                    data_format: "int",
                    data_getter: (data) => data.subscriptions.filter((s) => this.inEnum(s.status, this.validSubscriptionStatus)).length,
                },
                {
                    name: "Souscription",
                    data_getter: (data) =>
                        data.module_type === this.MODULE_TYPE_CLICK_AND_COLLECT_DISPATCH.value || data.stripe_subscription === null
                            ? moment(data.subscriptions[0].created_at).unix()
                            : data.stripe_subscription.created,
                    sortable: true,
                    sortable_type: "datetime",
                    data_format: "moment_unix",
                    data_format_moment: "DD MMM Y",
                    default_sort: {
                        order: "desc",
                    },
                },
                {
                    name: "Renouvellement",
                    data_getter: (data) =>
                        data.subscriptions.some((s) => this.inEnum(s.status, this.validSubscriptionStatus)) && data.stripe_subscription !== null
                            ? data.stripe_subscription.current_period_end
                            : null,
                    sortable: true,
                    sortable_type: "datetime",
                    data_format: "moment_unix",
                    data_format_moment: "DD MMM Y",
                },
                {
                    name: "CA",
                    data_getter: (data) => this.getCAForSubscription(data),
                    sortable: true,
                    sortable_type: "int",
                    data_format: "price_in_cent",
                },
                {
                    name: "Stripe",
                    key: "stripe_subscription.id",
                    sortable: false,
                    data_format: "string",
                    link: {
                        url: (data) => data.stripe_subscription && `${this.stripeSubscriptionsUrl}/${data.stripe_subscription.id}`,
                        target: "_blank",
                    },
                },
            ],
        };
    },
    mixins: [ModuleTypesEnum, StripePlanTypesEnum, SubscriptionStatusEnum],
    computed: {
        stripeSubscriptionsUrl() {
            if (this.isDevelopement()) return "https://dashboard.stripe.com/test/subscriptions";
            return "https://dashboard.stripe.com/subscriptions";
        },
        nbDistinctOwnerWithActiveLicences() {
            let ownerIds = this.formattedData
                .filter((s) => s.subscriptions.some((s) => this.inEnum(s.status, this.validSubscriptionStatus)))
                .map((s) => (s.owner ? s.owner.id : null))
                .filter((id) => id);
            return ownerIds.filter((id, index) => ownerIds.indexOf(id) === index).length;
        },
        nbActiveLicences() {
            let nbActiveLicences = 0;
            this.formattedData.forEach((line) => {
                nbActiveLicences += line.subscriptions.filter((s) => this.inEnum(s.status, this.validSubscriptionStatus)).length;
            });
            return nbActiveLicences;
        },
        formattedData() {
            if (!this.subscriptions) return [];
            let data = [];
            Object.values(this.subscriptions).forEach((subscription) => {
                Object.keys(subscription.modules).forEach((module_type) => {
                    data.push({
                        owner: subscription.owner,
                        module_type,
                        subscriptions: subscription.modules[module_type].subscriptions.data,
                        invoices: subscription.modules[module_type].invoices,
                        stripe_subscription: subscription.stripe_subscription,
                    });
                });
            });
            return data;
        },
        totalCA() {
            let amount = 0;
            this.formattedData.forEach((data) => (amount += this.getCAForSubscription(data)));
            return amount;
        },
        exportUrl() {
            let url = "/api/admin/exports/subscriptions_ca?";
            if (this.filter.from_date) url += "&from_date=" + moment(this.filter.from_date).format("Y-MM-DD");
            if (this.filter.to_date) url += "&to_date=" + moment(this.filter.to_date).format("Y-MM-DD");
            if (this.filter.plan) url += "&plan=" + this.filter.plan;
            if (this.filter.module) url += "&module=" + this.filter.module;
            return url;
        },
    },
    methods: {
        getCAForSubscription(data) {
            let amount = 0;
            if (data.invoices)
                Object.values(data.invoices)
                    .flat(1)
                    .forEach((line) => {
                        amount += line.amount;
                    });
            return amount;
        },
        resetFilter(e) {
            if (e) e.preventDefault();
            this.filter = _.cloneDeep(defaultFilter);
        },
        fetchData(e) {
            if (e) e.preventDefault();

            let params = {
                include: "restaurant",
                with_stripe_subscription: true,
            };
            if (this.filter.from_date) params.from_date = moment(this.filter.from_date).format("Y-MM-DD");
            if (this.filter.to_date) params.to_date = moment(this.filter.to_date).format("Y-MM-DD");
            if (this.filter.plan) params.plan = this.filter.plan;
            if (this.filter.module) params.module = this.filter.module;

            this.loading++;
            this.$store
                .dispatch("admin/fetchSubscriptionsWithStripeInvoices", { params })
                .then((response) => {
                    this.loading--;
                    this.subscriptions = response.data.data;
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        exportSubscriptionsPerMonth() {
            if (!confirm("L'export prendra en compte les mois sélectionnés. Souhaitez-vous continuer ?")) return;
            this.loading++;

            let params = {};
            if (this.filter.from_date) params.from_date = moment(this.filter.from_date).format("Y-MM-DD");
            if (this.filter.to_date) params.to_date = moment(this.filter.to_date).format("Y-MM-DD");
            this.$store
                .dispatch("admin/exportSubscriptionsCAPerMonth", { params })
                .then((response) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
    },
    components: {
        LoaderComponent,
        Datepicker,
        SortableTable,
    },
};
</script>
