var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.hasErrors("client_id")
      ? _c(
          "div",
          _vm._l(_vm.formErrors.errors.client_id, function (err) {
            return _c(
              "div",
              { key: err, staticClass: "invalid-feedback d-block" },
              [_vm._v("\n            " + _vm._s(err) + "\n        ")]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2 radio" }, [
      _c("div", { staticClass: "col-md-4 d-none d-md-block pt-2" }, [
        _c("label", [
          _vm._v(_vm._s(_vm.$tl("labels.form.civility")) + " "),
          _vm.mandatoryFields.civility ? _c("small", [_vm._v("*")]) : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-8 pt-2" }, [
        _c(
          "div",
          _vm._l(_vm.ALL_CLIENT_CIVILITIES, function (civility, index) {
            return _c(
              "label",
              { key: civility.value, staticClass: "container-box" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.reservation.client.civility,
                      expression: "reservation.client.civility",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "civility",
                    tabindex: 5 + index,
                    readonly: _vm.readOnly,
                    disabled: _vm.readOnly,
                  },
                  domProps: {
                    value: civility.value,
                    checked: _vm._q(
                      _vm.reservation.client.civility,
                      civility.value
                    ),
                  },
                  on: {
                    click: _vm.onCivilityChoosen,
                    change: function ($event) {
                      return _vm.$set(
                        _vm.reservation.client,
                        "civility",
                        civility.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkmark" }),
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.capitalize(
                        _vm.getClientCivilityLabelLong(civility.value)
                      )
                    ) +
                    "\n                "
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.hasErrors("civility")
          ? _c(
              "div",
              _vm._l(_vm.formErrors.errors.civility, function (err) {
                return _c(
                  "div",
                  { key: err, staticClass: "invalid-feedback d-block" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(err) +
                        "\n                "
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-md-4 d-none d-md-block pt-2" }, [
        _c("label", [
          _vm._v(_vm._s(_vm.$tl("labels.form.firstname"))),
          _vm.mandatoryFields.firstname ? _c("small", [_vm._v("*")]) : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "dropright add-resa-search-client" }, [
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reservation.client.firstname,
                  expression: "reservation.client.firstname",
                },
              ],
              ref: "firstname",
              staticClass: "form-control",
              class: {
                "is-invalid":
                  _vm.hasErrors("firstname") || _vm.hasErrors("client_id"),
              },
              attrs: {
                type: "text",
                placeholder: _vm.$tl("labels.form.firstname"),
                name: "firstname",
                readonly: _vm.readOnly,
                autocomplete: "off",
                tabindex: "7",
              },
              domProps: { value: _vm.reservation.client.firstname },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.reservation.client,
                      "firstname",
                      $event.target.value
                    )
                  },
                  _vm.onTextInput,
                ],
                focus: function ($event) {
                  _vm.reservation.client.firstnameHasFocus = true
                },
                blur: function ($event) {
                  _vm.reservation.client.firstnameHasFocus = false
                },
              },
            }),
          ]),
          _vm._v(" "),
          (_vm.reservation.client.firstnameHasFocus ||
            _vm.reservation.client.search.firstnameHasFocus) &&
          _vm.reservation.client.search.results &&
          _vm.reservation.client.search.results.length > 0
            ? _c(
                "div",
                {
                  staticClass: "dropdown-menu prename-field shadow show p-0",
                  on: {
                    mouseover: function ($event) {
                      _vm.reservation.client.search.firstnameHasFocus = true
                    },
                    mouseout: function ($event) {
                      _vm.reservation.client.search.firstnameHasFocus = false
                    },
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-group" },
                    _vm._l(
                      _vm.reservation.client.search.results,
                      function (result, i) {
                        return _c(
                          "li",
                          { key: i, staticClass: "list-group-item p-0" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "d-block p-3",
                                attrs: { href: "javascript:" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectClient(result)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(result.firstname) +
                                      " " +
                                      _vm._s(result.lastname)
                                  ),
                                ]),
                                _vm._v(" |\n                                "),
                                _c("span", [_vm._v(_vm._s(result.tel))]),
                                _vm._v(" "),
                                _c("span", { staticClass: "email-search" }, [
                                  result.company
                                    ? _c("span", [
                                        _vm._v(_vm._s(result.company) + " |"),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(result.email)),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasErrors("firstname")
            ? _c(
                "div",
                _vm._l(_vm.formErrors.errors.firstname, function (err) {
                  return _c(
                    "div",
                    { key: err, staticClass: "invalid-feedback d-block" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(err) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-md-4 d-none d-md-block pt-2" }, [
        _c("label", [
          _vm._v(_vm._s(_vm.$tl("labels.form.lastname")) + " "),
          _vm.mandatoryFields.lastname ? _c("small", [_vm._v("*")]) : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "dropright add-resa-search-client" }, [
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reservation.client.lastname,
                  expression: "reservation.client.lastname",
                },
              ],
              ref: "lastname",
              staticClass: "form-control",
              class: {
                "is-invalid":
                  _vm.hasErrors("lastname") || _vm.hasErrors("client_id"),
              },
              attrs: {
                type: "text",
                placeholder: _vm.$tl("labels.form.lastname"),
                name: "lastname",
                readonly: _vm.readOnly,
                autocomplete: "off",
                tabindex: "7",
              },
              domProps: { value: _vm.reservation.client.lastname },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.reservation.client,
                      "lastname",
                      $event.target.value
                    )
                  },
                  _vm.onTextInput,
                ],
                focus: function ($event) {
                  _vm.reservation.client.lastnameHasFocus = true
                },
                blur: function ($event) {
                  _vm.reservation.client.lastnameHasFocus = false
                },
              },
            }),
          ]),
          _vm._v(" "),
          (_vm.reservation.client.lastnameHasFocus ||
            _vm.reservation.client.search.lastnameHasFocus) &&
          _vm.reservation.client.search.results &&
          _vm.reservation.client.search.results.length > 0
            ? _c(
                "div",
                {
                  staticClass: "dropdown-menu name-field shadow show p-0",
                  on: {
                    mouseover: function ($event) {
                      _vm.reservation.client.search.lastnameHasFocus = true
                    },
                    mouseout: function ($event) {
                      _vm.reservation.client.search.lastnameHasFocus = false
                    },
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-group" },
                    _vm._l(
                      _vm.reservation.client.search.results,
                      function (result, i) {
                        return _c(
                          "li",
                          { key: i, staticClass: "list-group-item p-0" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "d-block p-3",
                                attrs: { href: "javascript:" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectClient(result)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(result.firstname) +
                                      " " +
                                      _vm._s(result.lastname)
                                  ),
                                ]),
                                _vm._v(" |\n                                "),
                                _c("span", [_vm._v(_vm._s(result.tel))]),
                                _vm._v(" "),
                                _c("span", { staticClass: "email-search" }, [
                                  result.company
                                    ? _c("span", [
                                        _vm._v(_vm._s(result.company) + " |"),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(result.email)),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasErrors("lastname")
            ? _c(
                "div",
                _vm._l(_vm.formErrors.errors.lastname, function (err) {
                  return _c(
                    "div",
                    { key: err, staticClass: "invalid-feedback d-block" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(err) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-md-4 d-none d-md-block pt-2" }, [
        _c("label", [
          _vm._v(_vm._s(_vm.$tl("labels.form.company")) + " "),
          _vm.mandatoryFields.company ? _c("small", [_vm._v("*")]) : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "dropright add-resa-search-client" }, [
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reservation.client.company,
                  expression: "reservation.client.company",
                },
              ],
              ref: "company",
              staticClass: "form-control",
              class: {
                "is-invalid":
                  _vm.hasErrors("company") || _vm.hasErrors("client_id"),
              },
              attrs: {
                type: "text",
                placeholder: _vm.$tl("labels.form.company"),
                name: "company",
                readonly: _vm.readOnly,
                autocomplete: "off",
                tabindex: "7",
              },
              domProps: { value: _vm.reservation.client.company },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.reservation.client,
                      "company",
                      $event.target.value
                    )
                  },
                  _vm.onTextInput,
                ],
                focus: function ($event) {
                  _vm.reservation.client.companyHasFocus = true
                },
                blur: function ($event) {
                  _vm.reservation.client.companyHasFocus = false
                },
              },
            }),
          ]),
          _vm._v(" "),
          (_vm.reservation.client.companyHasFocus ||
            _vm.reservation.client.search.companyHasFocus) &&
          _vm.reservation.client.search.results &&
          _vm.reservation.client.search.results.length > 0
            ? _c(
                "div",
                {
                  staticClass: "dropdown-menu company-field shadow show p-0",
                  on: {
                    mouseover: function ($event) {
                      _vm.reservation.client.search.companyHasFocus = true
                    },
                    mouseout: function ($event) {
                      _vm.reservation.client.search.companyHasFocus = false
                    },
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-group" },
                    _vm._l(
                      _vm.reservation.client.search.results,
                      function (result, i) {
                        return _c(
                          "li",
                          { key: i, staticClass: "list-group-item p-0" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "d-block p-3",
                                attrs: { href: "javascript:" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectClient(result)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(result.firstname) +
                                      " " +
                                      _vm._s(result.lastname)
                                  ),
                                ]),
                                _vm._v(" |\n                                "),
                                _c("span", [_vm._v(_vm._s(result.tel))]),
                                _vm._v(" "),
                                _c("span", { staticClass: "email-search" }, [
                                  result.company
                                    ? _c("span", [
                                        _vm._v(_vm._s(result.company) + " |"),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(result.email)),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasErrors("company")
            ? _c(
                "div",
                _vm._l(_vm.formErrors.errors.company, function (err) {
                  return _c(
                    "div",
                    { key: err, staticClass: "invalid-feedback d-block" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(err) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-md-4 d-none d-md-block pt-2" }, [
        _c("label", [
          _vm._v(_vm._s(_vm.$tl("labels.form.email")) + " "),
          _vm.mandatoryFields.email ? _c("small", [_vm._v("*")]) : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "dropright add-resa-search-client" }, [
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reservation.client.email,
                  expression: "reservation.client.email",
                },
              ],
              ref: "email",
              staticClass: "form-control",
              class: {
                "is-invalid":
                  _vm.hasErrors("email") || _vm.hasErrors("client_id"),
              },
              attrs: {
                type: "text",
                placeholder: _vm.$tl("labels.form.email"),
                name: "email",
                readonly: _vm.readOnly,
                autocomplete: "off",
                tabindex: "7",
              },
              domProps: { value: _vm.reservation.client.email },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.reservation.client,
                      "email",
                      $event.target.value
                    )
                  },
                  _vm.onTextInput,
                ],
                focus: function ($event) {
                  _vm.reservation.client.emailHasFocus = true
                },
                blur: function ($event) {
                  _vm.reservation.client.emailHasFocus = false
                },
              },
            }),
          ]),
          _vm._v(" "),
          (_vm.reservation.client.emailHasFocus ||
            _vm.reservation.client.search.emailHasFocus) &&
          _vm.reservation.client.search.results &&
          _vm.reservation.client.search.results.length > 0
            ? _c(
                "div",
                {
                  staticClass: "dropdown-menu email-field shadow show p-0",
                  on: {
                    mouseover: function ($event) {
                      _vm.reservation.client.search.emailHasFocus = true
                    },
                    mouseout: function ($event) {
                      _vm.reservation.client.search.emailHasFocus = false
                    },
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-group" },
                    _vm._l(
                      _vm.reservation.client.search.results,
                      function (result, i) {
                        return _c(
                          "li",
                          { key: i, staticClass: "list-group-item p-0" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "d-block p-3",
                                attrs: { href: "javascript:" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectClient(result)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(result.firstname) +
                                      " " +
                                      _vm._s(result.lastname)
                                  ),
                                ]),
                                _vm._v(" |\n                                "),
                                _c("span", [_vm._v(_vm._s(result.tel))]),
                                _vm._v(" "),
                                _c("span", { staticClass: "email-search" }, [
                                  result.company
                                    ? _c("span", [
                                        _vm._v(_vm._s(result.company) + " |"),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(result.email)),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasErrors("email")
            ? _c(
                "div",
                _vm._l(_vm.formErrors.errors.email, function (err) {
                  return _c(
                    "div",
                    { key: err, staticClass: "invalid-feedback d-block" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(err) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-md-4 d-none d-md-block pt-2" }, [
        _c("label", [
          _vm._v(_vm._s(_vm.$tl("labels.form.phoneNumber")) + " "),
          _vm.mandatoryFields.tel ? _c("small", [_vm._v("*")]) : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "dropright add-resa-search-client" }, [
          _vm.readOnly
            ? _c(
                "div",
                [
                  _c("vue-tel-input", {
                    ref: "vueTelInput",
                    class:
                      _vm.hasErrors("phoneNumber") ||
                      _vm.hasErrors("phoneCountry") ||
                      _vm.hasErrors("client_id") ||
                      (_vm.reservation.client.phone.value !== null &&
                        _vm.reservation.client.phone.isValid === false)
                        ? ["form-control", "is-invalid", "d-flex", "text-left"]
                        : ["form-control", "d-flex", "text-left"],
                    staticStyle: { overflow: "hidden" },
                    attrs: {
                      name: "telInput",
                      defaultCountry: _vm.defaultTelCountry,
                      disabled: true,
                      enabledCountryCode: true,
                      inputOptions: { tabindex: 10, autocomplete: "off" },
                      mode: "international",
                      inputClasses: "m-0 border-0",
                      placeholder: _vm.$tl("labels.form.phoneNumber"),
                    },
                    model: {
                      value: _vm.reservation.client.phone.raw,
                      callback: function ($$v) {
                        _vm.$set(_vm.reservation.client.phone, "raw", $$v)
                      },
                      expression: "reservation.client.phone.raw",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("vue-tel-input", {
                    ref: "vueTelInput",
                    class:
                      _vm.hasErrors("phoneNumber") ||
                      _vm.hasErrors("phoneCountry") ||
                      _vm.hasErrors("client_id") ||
                      (_vm.reservation.client.phone.value !== null &&
                        _vm.reservation.client.phone.isValid === false)
                        ? ["form-control", "is-invalid", "d-flex", "text-left"]
                        : ["form-control", "d-flex", "text-left"],
                    attrs: {
                      name: "telInput",
                      defaultCountry: _vm.defaultTelCountry,
                      enabledCountryCode: true,
                      inputOptions: { tabindex: 10, autocomplete: "off" },
                      mode: "international",
                      inputClasses: "m-0 border-0",
                      placeholder: _vm.$tl("labels.form.phoneNumber"),
                    },
                    on: {
                      onValidate: _vm.setPhoneAndCountry,
                      onInput: _vm.searchClientByMultiField,
                    },
                    model: {
                      value: _vm.reservation.client.phone.raw,
                      callback: function ($$v) {
                        _vm.$set(_vm.reservation.client.phone, "raw", $$v)
                      },
                      expression: "reservation.client.phone.raw",
                    },
                  }),
                ],
                1
              ),
          _vm._v(" "),
          (_vm.reservation.client.phoneNumberHasFocus ||
            _vm.reservation.client.search.phoneNumberHasFocus) &&
          _vm.reservation.client.search.results &&
          _vm.reservation.client.search.results.length > 0
            ? _c(
                "div",
                {
                  staticClass: "dropdown-menu phone-field shadow show p-0",
                  on: {
                    mouseover: function ($event) {
                      _vm.reservation.client.search.phoneNumberHasFocus = true
                    },
                    mouseout: function ($event) {
                      _vm.reservation.client.search.phoneNumberHasFocus = false
                    },
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-group" },
                    _vm._l(
                      _vm.reservation.client.search.results,
                      function (result, i) {
                        return _c(
                          "li",
                          { key: i, staticClass: "list-group-item p-0" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "d-block p-3",
                                attrs: { href: "javascript:" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectClient(result)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(result.firstname) +
                                      " " +
                                      _vm._s(result.lastname)
                                  ),
                                ]),
                                _vm._v(" |\n                                "),
                                _c("span", [_vm._v(_vm._s(result.tel))]),
                                _vm._v(" "),
                                _c("span", { staticClass: "email-search" }, [
                                  result.company
                                    ? _c("span", [
                                        _vm._v(_vm._s(result.company) + " |"),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(result.email)),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasErrors("phoneNumber") ||
          _vm.hasErrors("phoneCountry") ||
          _vm.hasErrors("client_id")
            ? _c("div", { staticClass: "invalid-feedback d-block" }, [
                _vm.hasErrors("phoneNumber")
                  ? _c(
                      "div",
                      _vm._l(_vm.formErrors.errors.phoneNumber, function (err) {
                        return _c(
                          "div",
                          { key: err, staticClass: "invalid-feedback d-block" },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(err) +
                                "\n                        "
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm.hasErrors("phoneCountry")
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.formErrors.errors.phoneCountry,
                        function (err) {
                          return _c(
                            "div",
                            {
                              key: err,
                              staticClass: "invalid-feedback d-block",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(err) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-md-4 d-none d-md-block pt-2" }, [
        _c("label", [_vm._v(_vm._s(_vm.$tl("labels.form.lang")))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-8" },
        [
          _c("ChooseLang", {
            staticClass: "d-inline-block",
            staticStyle: { "z-index": "auto !important" },
            attrs: {
              disabled: _vm.readOnly,
              "is-input-mode": "",
              "should-translate-label": "",
            },
            model: {
              value: _vm.reservation.client.locale,
              callback: function ($$v) {
                _vm.$set(_vm.reservation.client, "locale", $$v)
              },
              expression: "reservation.client.locale",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-md-4 d-none d-md-block pt-2" }, [
        _c("label", [
          _vm._v(_vm._s(_vm.$tl("labels.clients.typeShort")) + " "),
          _vm.mandatoryFields.type ? _c("small", [_vm._v("*")]) : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-8" },
        [
          _c("select-client-type", {
            attrs: {
              readonly: _vm.readOnly,
              "restaurant-id": _vm.restaurantId,
              "small-screen": "",
            },
            model: {
              value: _vm.reservation.client.type,
              callback: function ($$v) {
                _vm.$set(_vm.reservation.client, "type", $$v)
              },
              expression: "reservation.client.type",
            },
          }),
          _vm._v(" "),
          _vm.hasErrors("type")
            ? _c(
                "div",
                _vm._l(_vm.formErrors.errors.type, function (err) {
                  return _c(
                    "div",
                    { key: err, staticClass: "invalid-feedback d-block" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(err) +
                          "\n                "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-9" }, [
        _c("label", [_vm._v(_vm._s(_vm.$tl("labels.clients.vip")))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3 text-right lead-switch" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.reservation.client.is_vip,
              expression: "reservation.client.is_vip",
            },
          ],
          staticClass: "switch align-self-center is-rounded",
          attrs: { type: "checkbox", disabled: _vm.readOnly },
          domProps: {
            checked: Array.isArray(_vm.reservation.client.is_vip)
              ? _vm._i(_vm.reservation.client.is_vip, null) > -1
              : _vm.reservation.client.is_vip,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.reservation.client.is_vip,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.reservation.client,
                      "is_vip",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.reservation.client,
                      "is_vip",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.reservation.client, "is_vip", $$c)
              }
            },
          },
        }),
        _vm._v(" "),
        _c("label", {
          on: {
            click: function ($event) {
              _vm.reservation.client.is_vip = _vm.readOnly
                ? _vm.reservation.client.is_vip
                : !_vm.reservation.client.is_vip
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-9" }, [
        _c("label", { staticClass: "none-mobile" }, [
          _vm._v(_vm._s(_vm.$tl("labels.form.acceptNewsletter"))),
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "none-desk" }, [
          _vm._v(_vm._s(_vm.$tl("labels.form.acceptNewsletterShort"))),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3 text-right lead-switch" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.reservation.client.newsletter,
              expression: "reservation.client.newsletter",
            },
          ],
          staticClass: "switch align-self-center is-rounded",
          attrs: { type: "checkbox", disabled: _vm.readOnly },
          domProps: {
            checked: Array.isArray(_vm.reservation.client.newsletter)
              ? _vm._i(_vm.reservation.client.newsletter, null) > -1
              : _vm.reservation.client.newsletter,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.reservation.client.newsletter,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.reservation.client,
                      "newsletter",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.reservation.client,
                      "newsletter",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.reservation.client, "newsletter", $$c)
              }
            },
          },
        }),
        _vm._v(" "),
        _c("label", {
          on: {
            click: function ($event) {
              _vm.reservation.client.newsletter = _vm.readOnly
                ? _vm.reservation.client.newsletter
                : !_vm.reservation.client.newsletter
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _vm.reservation.client.phone.search.results !== null &&
    _vm.reservation.client.id === null
      ? _c("div", { staticClass: "add-resa-search-client" }, [
          _vm.reservation.client.phone.search.results.length > 0
            ? _c("div", [
                _c(
                  "span",
                  { staticClass: "text-danger d-inline-block mt-2 mb-2" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.clients.alreadyExist"))
                    ),
                    _c("br"),
                    _vm._v(
                      _vm._s(_vm.$tl("infos.booking.clients.alreadyExist")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("li", { staticClass: "list-group-item p-0" }, [
                  _c(
                    "a",
                    {
                      staticClass: "d-block p-3",
                      attrs: { href: "javascript:" },
                      on: {
                        click: function ($event) {
                          return _vm.selectClient(
                            _vm.reservation.client.phone.search.results[0]
                          )
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.reservation.client.phone.search.results[0]
                                .firstname
                            ) +
                            "\n                        " +
                            _vm._s(
                              _vm.reservation.client.phone.search.results[0]
                                .lastname
                            ) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v("\n                    |\n                    "),
                      _c("span", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.reservation.client.phone.search.results[0].tel
                            ) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "email-search" }, [
                        _vm.reservation.client.phone.search.results[0].company
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.reservation.client.phone.search.results[0]
                                    .company
                                ) + " |"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(
                          _vm._s(
                            _vm.reservation.client.phone.search.results[0].email
                          )
                        ),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _c("div", [
                _c(
                  "span",
                  { staticClass: "d-inline-block mt-2 mb-2 text-muted" },
                  [
                    _vm._v(
                      _vm._s(_vm.$tl("infos.booking.clients.willBeCreated"))
                    ),
                  ]
                ),
              ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.readOnly && !_vm.hideEditButton
      ? _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-success btn-circle btn-sm mt-3",
              attrs: { type: "button" },
              on: { click: _vm.redirectToClient },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$tl("labels.client.edit")) +
                  "\n        "
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }