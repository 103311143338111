var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { staticClass: "form-register", on: { submit: _vm.post } },
    [
      _c("div", { staticClass: "text-center" }, [
        _c("img", {
          staticClass: "mb-5",
          attrs: { width: "200", src: "/images/logo-lead.png", alt: "No Show" },
        }),
        _vm._v(" "),
        _c("h5", { staticClass: "mb-4" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.welcome ? "Créer mon mot de passe" : "Mot de passe perdu"
              ) +
              "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent")
        : [
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-md-4 col-form-label text-md-right",
                  attrs: { for: "email" },
                },
                [_vm._v("Votre adresse mail")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("input", {
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.hasErrors("email") },
                    attrs: {
                      id: "email",
                      type: "email",
                      name: "email",
                      required: "",
                      disabled: "",
                    },
                    domProps: { value: _vm.email_ },
                  }),
                  _vm._v(" "),
                  _c("ShowErrors", {
                    attrs: { errors: _vm.errors, errorKey: "email" },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-md-4 col-form-label text-md-right",
                  attrs: { for: "password" },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.welcome ? "Mot de passe" : "Nouveau mot de passe"
                      ) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("PasswordInputComponent", {
                    model: {
                      value: _vm.user.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "password", $$v)
                      },
                      expression: "user.password",
                    },
                  }),
                  _vm._v(" "),
                  _c("ShowErrors", {
                    attrs: { errors: _vm.errors, errorKey: "password" },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-md-4 col-form-label text-md-right",
                  attrs: { for: "password-confirm" },
                },
                [_vm._v("Confirmez le mot de passe")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("PasswordConfirmationInputComponent", {
                    attrs: { mainPassword: _vm.user.password },
                    model: {
                      value: _vm.user.password_confirmation,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "password_confirmation", $$v)
                      },
                      expression: "user.password_confirmation",
                    },
                  }),
                  _vm._v(" "),
                  _c("ShowErrors", {
                    attrs: {
                      errors: _vm.errors,
                      errorKey: "password_confirmation",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row mb-0" }, [
              _c("div", { staticClass: "col-md-6 offset-md-4" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-success", attrs: { type: "submit" } },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.welcome
                            ? "Créer mon mot de passe"
                            : "Réinitialiser mon mot de passe"
                        ) +
                        "\n                "
                    ),
                  ]
                ),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }