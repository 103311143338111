var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "waitinglist" },
    [
      _vm.loadingServices === true || _vm.loadingWaitings === true
        ? _c("LoaderComponent")
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.loadingServices === false && _vm.loadingWaitings === false,
              expression:
                "loadingServices === false && loadingWaitings === false",
            },
          ],
          staticClass: "row m-0",
        },
        [
          _c("div", { staticClass: "col-12 p-0" }, [
            _c(
              "div",
              {
                staticClass: "border-light b-radius-20 p-4",
                attrs: { id: "modal-waiting-list-container" },
              },
              [
                _vm.waitinglist && _vm.waitinglist.length > 0
                  ? _c(
                      "table",
                      {
                        staticClass:
                          "table table-sm table-striped border-bottom mb-3",
                      },
                      [
                        _c("thead", { staticClass: "border-bottom" }, [
                          _c("tr", [
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.waitingList.columns.client"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.waitingList.columns.hour"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.waitingList.columns.dateSendingMail"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.waitingList.columns.phone"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "none-desk" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.waitingList.columns.pax"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "none-mobile" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.waitingList.columns.adults"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "none-mobile" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.waitingList.columns.children"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.$tl("labels.form.actions.title"))
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.waitinglist, function (waiting, index) {
                            return _c(
                              "tr",
                              { key: index },
                              [
                                waiting.id !== false
                                  ? [
                                      _c(
                                        "td",
                                        [
                                          _c("inline-client", {
                                            attrs: {
                                              client: waiting.client,
                                              "module-enum":
                                                _vm.MODULE_TYPE_BOOKING,
                                              "show-noshows": "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              waiting.slot_id !== null
                                                ? waiting.slot.hour_start
                                                : ""
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        !waiting.last_mail_log
                                          ? _c("span", [_vm._v("--")])
                                          : _c("span", [
                                              waiting.last_mail_log
                                                .permanent_failed_at ||
                                              !waiting.last_mail_log.sent_at
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.booking.waitingList.mailsStatus.fail"
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  )
                                                : waiting.last_mail_log
                                                    .sent_at &&
                                                  !waiting.last_mail_log
                                                    .delivered_at
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-warning",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.$tl(
                                                              "labels.booking.waitingList.mailsStatus.inProgress"
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  )
                                                : waiting.last_mail_log
                                                    .delivered_at
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.displayDate(
                                                            waiting
                                                              .last_mail_log
                                                              .delivered_at,
                                                            _vm.DATETIME_SHORT
                                                          )
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                    waiting.last_mail_log
                                                      .opened_at
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(
                                                                _vm.$tl(
                                                                  "labels.booking.waitingList.mailsStatus.open"
                                                                )
                                                              ) +
                                                              "\n                                            "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ])
                                                : _vm._e(),
                                            ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: `tel:${waiting.client.tel}`,
                                            },
                                          },
                                          [_vm._v(_vm._s(waiting.client.tel))]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "none-desk" }, [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              waiting.nb_pers +
                                                waiting.nb_children
                                            ) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "none-mobile" }, [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(waiting.nb_pers) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "none-mobile" }, [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(waiting.nb_children) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "d-flex justify-content-end",
                                        },
                                        [
                                          _vm.hasMenusOrOptions(waiting)
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.getTooltip(
                                                        _vm.$tl(
                                                          "labels.booking.waitingList.buttons.seeMenusAndOptions"
                                                        )
                                                      ),
                                                      expression:
                                                        "getTooltip($tl('labels.booking.waitingList.buttons.seeMenusAndOptions'))",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-sm btn-outline-secondary btn-square ml-1",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toggleDetailsRow(
                                                        waiting,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  !_vm.displayedMenus[
                                                    waiting.id
                                                  ]
                                                    ? _c("feather", {
                                                        attrs: { type: "eye" },
                                                      })
                                                    : _c("feather", {
                                                        attrs: {
                                                          type: "eye-off",
                                                        },
                                                      }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !waiting.isPastService &&
                                          _vm.widget &&
                                          _vm.widget.data.enable_mail_auto === 0
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: {
                                                        delay: {
                                                          show: 400,
                                                          hide: 0,
                                                        },
                                                        content: _vm.$tl(
                                                          "labels.booking.waitingList.buttons.sendAvailabilityMail"
                                                        ),
                                                      },
                                                      expression:
                                                        "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $tl('labels.booking.waitingList.buttons.sendAvailabilityMail'),\n                                        }",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-sm btn-outline-secondary btn-square ml-1",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.sendMail(
                                                        waiting.client.email,
                                                        waiting
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "css-i6dzq1 feather",
                                                      attrs: {
                                                        viewBox: "0 0 24 24",
                                                        width: "24",
                                                        height: "24",
                                                        stroke: "currentColor",
                                                        "stroke-width": "2",
                                                        fill: "none",
                                                        "stroke-linecap":
                                                          "round",
                                                        "stroke-linejoin":
                                                          "round",
                                                      },
                                                    },
                                                    [
                                                      _c("line", {
                                                        attrs: {
                                                          x1: "22",
                                                          y1: "2",
                                                          x2: "11",
                                                          y2: "13",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("polygon", {
                                                        attrs: {
                                                          points:
                                                            "22 2 15 22 11 13 2 9 22 2",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: {
                                                    delay: {
                                                      show: 400,
                                                      hide: 0,
                                                    },
                                                    content: _vm.$tl(
                                                      "labels.booking.waitingList.buttons.addToReservationList"
                                                    ),
                                                  },
                                                  expression:
                                                    "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $tl('labels.booking.waitingList.buttons.addToReservationList'),\n                                        }",
                                                },
                                              ],
                                              staticClass:
                                                "btn btn-sm btn-success btn-square ml-1",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.createReservationFromWaiting(
                                                    waiting
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "feather css-i6dzq1",
                                                  attrs: {
                                                    viewBox: "0 0 24 24",
                                                    width: "24",
                                                    height: "24",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    fill: "none",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                  },
                                                },
                                                [
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "12",
                                                      y1: "5",
                                                      x2: "12",
                                                      y2: "19",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "5",
                                                      y1: "12",
                                                      x2: "19",
                                                      y2: "12",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: {
                                                    delay: {
                                                      show: 400,
                                                      hide: 0,
                                                    },
                                                    content: _vm.$tl(
                                                      "labels.booking.waitingList.buttons.deleteToWaitingList"
                                                    ),
                                                  },
                                                  expression:
                                                    "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $tl('labels.booking.waitingList.buttons.deleteToWaitingList'),\n                                        }",
                                                },
                                              ],
                                              staticClass:
                                                "btn btn-sm btn-danger btn-square none-mobile ml-1",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteWaiting(
                                                    waiting.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "feather css-i6dzq1",
                                                  attrs: {
                                                    viewBox: "0 0 24 24",
                                                    width: "24",
                                                    height: "24",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    fill: "none",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                  },
                                                },
                                                [
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "18",
                                                      y1: "6",
                                                      x2: "6",
                                                      y2: "18",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "6",
                                                      y1: "6",
                                                      x2: "18",
                                                      y2: "18",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : [
                                      _c("td", { attrs: { colspan: "7" } }, [
                                        waiting.menus
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.booking.waitingList.content.menus"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "ul",
                                                _vm._l(
                                                  waiting.menus,
                                                  function (menu) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: menu.id,
                                                        staticClass: "my-1",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(menu.nb)
                                                          ),
                                                        ]),
                                                        _vm._v(" x "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(menu.name)
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _vm.hasAttribute(
                                                          menu,
                                                          "options"
                                                        )
                                                          ? _c(
                                                              "ul",
                                                              {
                                                                staticClass:
                                                                  "text-small",
                                                                staticStyle: {
                                                                  "padding-inline-start":
                                                                    "20px",
                                                                },
                                                              },
                                                              _vm._l(
                                                                menu.options
                                                                  .data,
                                                                function (
                                                                  menuOption
                                                                ) {
                                                                  return _c(
                                                                    "li",
                                                                    {
                                                                      key: menuOption.id,
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              menuOption.nb
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " x "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              menuOption.name
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        waiting.general_options
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tl(
                                                        "labels.booking.waitingList.content.generalOptions"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "ul",
                                                _vm._l(
                                                  waiting.general_options,
                                                  function (option) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: option.id,
                                                        staticClass: "my-1",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(option.nb)
                                                          ),
                                                        ]),
                                                        _vm._v(" x "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(option.name)
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                    ],
                              ],
                              2
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  : _c("div", [
                      _c("em", [
                        _vm._v(
                          _vm._s(
                            _vm.$tl("labels.booking.waitingList.emptyList")
                          )
                        ),
                      ]),
                    ]),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.showAddReservationModal
        ? _c("AddReservationModal", {
            attrs: {
              date: _vm.reservation_date,
              restaurant_id: _vm.restaurant_id,
              defaultValues: _vm.addReservationDefaultValues,
              slot_id: _vm.addReservationDefaultValues.slot_id,
              service_id: _vm.service_id,
              showWaitingListButton: false,
            },
            on: {
              "reservation-saved": _vm.reservationSaved,
              close: function ($event) {
                _vm.showAddReservationModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }