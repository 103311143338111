var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "rootElement",
      staticClass: "blur-set main-detail-box p-3",
      attrs: { tabindex: "-1" },
      on: { blur: _vm.onBlur },
    },
    [
      _vm.loading
        ? _c("LoaderComponent", { attrs: { i18nAvailable: false } })
        : _vm.fatalError
        ? _c("span", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.fatalError)),
          ])
        : _vm.product
        ? _c("form", { on: { submit: _vm.postNewStock } }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-12 d-flex justify-content-between align-items-center border-bottom-light pb-2",
                },
                [
                  _c("strong", { staticStyle: { "font-size": "0.9rem" } }, [
                    _vm._v(_vm._s(_vm.product.name)),
                  ]),
                  _vm._v(" "),
                  !_vm.isGeneralStock
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.moment(_vm.date).format("DD/MM"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col-md-5" }, [
                _c("label", [_vm._v("Rupture de stock")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-7" },
                [
                  _c(
                    "div",
                    { staticClass: "d-inline-block align-middle lead-switch" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product_data.out_of_stock,
                            expression: "product_data.out_of_stock",
                          },
                        ],
                        staticClass: "switch align-self-center is-rounded",
                        class: {
                          disabled:
                            _vm.product_data.out_of_stock && _vm.isGeneralStock,
                        },
                        attrs: {
                          type: "checkbox",
                          "true-value": true,
                          "false-value": false,
                          disabled:
                            _vm.product_data.out_of_stock && _vm.isGeneralStock,
                        },
                        domProps: {
                          checked: Array.isArray(_vm.product_data.out_of_stock)
                            ? _vm._i(_vm.product_data.out_of_stock, null) > -1
                            : _vm.product_data.out_of_stock,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.product_data.out_of_stock,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.product_data,
                                    "out_of_stock",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.product_data,
                                    "out_of_stock",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.product_data, "out_of_stock", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { on: { click: _vm.toggleOutOfStock } }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("ShowErrors", {
                    staticClass: "d-block",
                    attrs: { errors: _vm.errors, errorKey: "out_of_stock" },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            !_vm.isGeneralStock
              ? _c("div", { staticClass: "row mt-2" }, [
                  _c("div", { staticClass: "col-md-5 pt-2" }, [
                    _c("label", [_vm._v("Modifier le stock")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-7" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product_data.new_stock,
                            expression: "product_data.new_stock",
                          },
                        ],
                        staticClass: "form-control d-inline",
                        class: {
                          disabled: _vm.product_data.out_of_stock,
                          "cursor-not-allowed": _vm.product_data.out_of_stock,
                        },
                        staticStyle: { width: "60px", "padding-right": "5px" },
                        attrs: {
                          type: "number",
                          min: "1",
                          step: "1",
                          disabled: _vm.product_data.out_of_stock,
                        },
                        domProps: { value: _vm.product_data.new_stock },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.product_data,
                              "new_stock",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      !_vm.product_data.out_of_stock &&
                      _vm.product_data.new_stock &&
                      _vm.product_data.id
                        ? _c("feather", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  delay: { show: 400, hide: 0 },
                                  content: "Utiliser le stock par défaut",
                                },
                                expression:
                                  "{ delay: { show: 400, hide: 0 }, content: 'Utiliser le stock par défaut' }",
                              },
                            ],
                            staticClass:
                              "text-danger feather-20 d-inline-block pointer",
                            attrs: { type: "x" },
                            on: { click: _vm.resetNewStock },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("ShowErrors", {
                        staticClass: "d-block",
                        attrs: { errors: _vm.errors, errorKey: "new_stock" },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.isGeneralStock
              ? _c("div", { staticClass: "row mt-2" }, [
                  _c("div", { staticClass: "col-12 text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-circle btn-sm btn-success",
                        class: { disabled: _vm.product_data.out_of_stock },
                        attrs: {
                          type: "submit",
                          disabled: _vm.product_data.out_of_stock,
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Enregistrer\n                "
                        ),
                      ]
                    ),
                  ]),
                ])
              : _c("div", { staticClass: "row mt-2" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(
                        "La gestion du stock s'effectue sur la fiche produit"
                      ),
                    ]),
                  ]),
                ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }