import Menus from "../../../views/Booking/Settings/Services/Menus.vue";
import MenuForm from "../../../views/Booking/Settings/Services/MenuForm.vue";
import MenuOptions from "../../../views/Booking/Settings/Services/MenuOptions.vue";
import MenuOptionForm from "../../../views/Booking/Settings/Services/MenuOptionForm.vue";
import Settings from "../../../views/Booking/Settings.vue";
import WidgetIntegration from "../../../views/Booking/Settings/WidgetIntegration.vue";
import BookingSettings from "../../../views/Booking/Settings/Settings.vue";
import ServicesSettings from "../../../views/Booking/Settings/Services.vue";
import SeatingPlanSettings from "../../../views/Booking/Settings/SeatingPlan.vue";
import NotificationsSettings from "../../../views/Booking/Settings/Notifications.vue";
import BookingCgv from "../../../views/Booking/Settings/cgv.vue";
import BookingInputParams from "../../../views/Booking/Settings/InputParams.vue";
import Services from "../../../views/Booking/Settings/Services/Services.vue";
import ServicesAdd from "../../../views/Booking/Settings/Services/ServicesAdd.vue";
import Openings from "../../../views/Booking/Settings/Services/Openings.vue";
import OpeningsAdd from "../../../views/Booking/Settings/Services/OpeningsAdd.vue";
import Closures from "../../../views/Booking/Settings/Services/Closures.vue";
import ClosureAdd from "../../../views/Booking/Settings/Services/ClosuresAdd.vue";

import noshow from "./noshow.js";

const settings = [
    {
        path: "/booking/restaurants/:restaurant_id/settings",
        name: "booking.restaurants.settings",
        component: Settings,
        children: [
            { path: "widget", name: "booking.restaurants.settings.widget", component: WidgetIntegration },
            { path: "settings", name: "booking.restaurants.settings.settings", component: BookingSettings },
            {
                path: "services",
                component: ServicesSettings,
                children: [
                    { path: "", name: "booking.restaurants.settings.services", component: Services },
                    { path: "openings", name: "booking.restaurants.settings.openings", component: Openings },
                    { path: "closures", name: "booking.restaurants.settings.closures", component: Closures },
                    { path: "menus", name: "booking.restaurants.settings.menus", component: Menus },
                    {
                        path: "menus-options",
                        name: "booking.restaurants.settings.menus_options",
                        component: MenuOptions,
                    },
                    {
                        path: "openings/add",
                        name: "booking.restaurants.settings.openings.add",
                        component: OpeningsAdd,
                    },
                    {
                        path: "openings/:service_id",
                        name: "booking.restaurants.settings.openings.edit",
                        component: OpeningsAdd,
                    },
                    {
                        path: "closures/add",
                        name: "booking.restaurants.settings.closures.add",
                        component: ClosureAdd,
                    },
                    {
                        path: "closures/:closure_id",
                        name: "booking.restaurants.settings.closures.edit",
                        component: ClosureAdd,
                    },
                    {
                        path: "menus/add",
                        name: "booking.restaurants.settings.menus.add",
                        component: MenuForm,
                    },
                    {
                        path: "menus/:menu_id",
                        name: "booking.restaurants.settings.menus.edit",
                        component: MenuForm,
                    },
                    {
                        path: "menus-options/add",
                        name: "booking.restaurants.settings.menus_options.add",
                        component: MenuOptionForm,
                    },
                    {
                        path: "menus-options/:menu_option_id",
                        name: "booking.restaurants.settings.menus_options.edit",
                        component: MenuOptionForm,
                    },
                    {
                        path: "general-options/add",
                        name: "booking.restaurants.settings.general_options.add",
                        component: MenuOptionForm,
                    },
                    {
                        path: "general-options/:general_option_id",
                        name: "booking.restaurants.settings.general_options.edit",
                        component: MenuOptionForm,
                    },
                    { path: "add", name: "booking.restaurants.settings.services.add", component: ServicesAdd },
                    {
                        path: ":service_id",
                        name: "booking.restaurants.settings.services.edit",
                        component: ServicesAdd,
                    },
                ],
            },
            {
                path: "seating-plan/:seating_plan_id?",
                name: "booking.restaurants.settings.seatingPlan",
                component: SeatingPlanSettings,
            },
            {
                path: "notifications",
                name: "booking.restaurants.settings.notifications",
                component: NotificationsSettings,
            },
            { path: "cgv", name: "booking.restaurants.settings.cgv", component: BookingCgv },
            { path: "input-params", name: "booking.restaurants.settings.input_params", component: BookingInputParams },
        ],
    },
];

if (THEME === "noshow") {
    settings[0].children.push(...noshow);
}

export default settings;
