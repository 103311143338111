var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "default-home" },
      [
        _c("div", { staticClass: "row m-0" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
              },
              [
                _c("h5", { staticClass: "title mt-2" }, [
                  _vm._v(_vm._s(_vm.$tl("labels.routes.rgpd"))),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.loading
          ? _c("LoaderComponent")
          : [
              _c("div", { staticClass: "row m-0" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4 mb-3" },
                    [
                      _c("div", { staticClass: "row mb-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("strong", { staticClass: "float-left" }, [
                            _vm._v(_vm._s(_vm.$tl("labels.rgpd.timeToKeep"))),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-md-8 pt-2" }, [
                          _vm._v(
                            _vm._s(_vm.$tl("labels.rgpd.clientNewsletter"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-4" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.rgpd.keep_clients_on_newsletter_for,
                                  expression:
                                    "rgpd.keep_clients_on_newsletter_for",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                step: "1",
                                min: "1",
                                max: "3",
                              },
                              domProps: {
                                value: _vm.rgpd.keep_clients_on_newsletter_for,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.rgpd,
                                    "keep_clients_on_newsletter_for",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("ShowErrors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors,
                                errorKey: "keep_clients_on_newsletter_for",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-md-8 pt-2" }, [
                          _vm._v(_vm._s(_vm.$tl("labels.rgpd.clientData"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-4" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.rgpd
                                      .keep_clients_details_and_history_for,
                                  expression:
                                    "rgpd.keep_clients_details_and_history_for",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                step: "1",
                                min: "1",
                                max: "10",
                              },
                              domProps: {
                                value:
                                  _vm.rgpd.keep_clients_details_and_history_for,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.rgpd,
                                    "keep_clients_details_and_history_for",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("ShowErrors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors,
                                errorKey:
                                  "keep_clients_details_and_history_for",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mt-3" }, [
                        _c("div", { staticClass: "col-12 text-muted" }, [
                          _c("small", [
                            _vm._v(
                              _vm._s(_vm.$t("infos.rgpd.legalTimeToKeep1"))
                            ),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.$t("infos.rgpd.legalTimeToKeep2"))
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mt-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success btn-circle",
                              on: { click: _vm.save },
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$tl("labels.form.actions.save")) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row m-0" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4 mb-3" },
                    [
                      _c("div", { staticClass: "row mb-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("strong", { staticClass: "float-left" }, [
                            _vm._v(_vm._s(_vm.$tl("labels.rgpd.pcdp"))),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row text-center" }, [
                        _c("div", { staticClass: "col-12 pt-2" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `/default_data_policy`,
                                target: "_blank",
                              },
                            },
                            [
                              _c("p", { staticClass: "bg-light p-3" }, [
                                _vm._v(_vm._s(_vm.$tl("labels.rgpd.pcdp"))),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }