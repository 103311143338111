<template>
    <div>
        <label class="container-box">
            <input type="checkbox" v-model="currentValue" />
            <span class="checkmark"></span>
        </label>
        <show-errors class="d-block" :errors="errors" :error-key="errorKey" />
    </div>
</template>

<script>
import ShowErrors from "../errors/ShowErrors.vue";

export default {
    name: "CheckboxInputComponent",
    components: { ShowErrors },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        errors: {
            default: undefined,
        },
        errorKey: {
            type: String,
            default: "",
            required: false,
        },
    },
    computed: {
        currentValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>
