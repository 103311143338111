export default {
    data() {
        return {
            TABLE_DETAILS_TYPE_ROUND_TABLE: {
                ref: "roundTableElement",
                value: "round-table",
            },
            TABLE_DETAILS_TYPE_SQUARE_TABLE: {
                ref: "squareTableElement",
                value: "square-table",
            },
            TABLE_DETAILS_TYPE_RECTANGLE_TABLE: {
                ref: "rectangleTableElement",
                value: "rectangle-table",
            },
            TABLE_DETAILS_TYPE_COUNTER: {
                ref: "counterElement",
                value: "counter",
            },
            TABLE_DETAILS_TYPE_FLOWER: {
                ref: "flowerElement",
                value: "flower",
            },
            TABLE_DETAILS_TYPE_DELIMITER: {
                ref: "delimiterElement",
                value: "delimiter",
            },
            TABLE_DETAILS_TYPE_AQUARIUM: {
                ref: "aquariumElement",
                value: "aquarium",
            },
            TABLE_DETAILS_TYPE_WARDROBE_WINE: {
                ref: "wardrobeWineElement",
                value: "wardrobe-wine",
            },
            TABLE_DETAILS_TYPE_CHIMNEY: {
                ref: "chimneyElement",
                value: "chimney",
            },
            TABLE_DETAILS_TYPE_KITCHEN: {
                ref: "kitchenElement",
                value: "kitchen",
            },
            TABLE_DETAILS_TYPE_STAIRS1: {
                ref: "stairs1Element",
                value: "stairs1",
            },
            TABLE_DETAILS_TYPE_STAIRS1_RIGHT: {
                ref: "stairs1RightElement",
                value: "stairs1-right",
            },
            TABLE_DETAILS_TYPE_STAIRS2: {
                ref: "stairs2Element",
                value: "stairs2",
            },
            TABLE_DETAILS_TYPE_STAIRS2_RIGHT: {
                ref: "stairs2RightElement",
                value: "stairs2-right",
            },
            TABLE_DETAILS_TYPE_STAIRS3: {
                ref: "stairs3Element",
                value: "stairs3",
            },
            TABLE_DETAILS_TYPE_STAIRS3_LEFT: {
                ref: "stairs3LeftElement",
                value: "stairs3-left",
            },
            TABLE_DETAILS_TYPE_STAIRS4: {
                ref: "stairs4Element",
                value: "stairs4",
            },
            TABLE_DETAILS_TYPE_CAR_PARK: {
                ref: "carParkElement",
                value: "car-park",
            },
            TABLE_DETAILS_TYPE_TOILET: {
                ref: "toiletElement",
                value: "toilet",
            },
            TABLE_DETAILS_TYPE_POOL: {
                ref: "poolElement",
                value: "pool",
            },
            TABLE_DETAILS_TYPE_BEACH: {
                ref: "beachElement",
                value: "beach",
            },
            TABLE_DETAILS_TYPE_DOOR1: {
                ref: "door1Element",
                value: "door1",
            },
            TABLE_DETAILS_TYPE_DOOR2: {
                ref: "door2Element",
                value: "door2",
            },
            TABLE_DETAILS_TYPE_BUFFET: {
                ref: "buffetElement",
                value: "buffet",
            },
            TABLE_DETAILS_TYPE_FRIDGE: {
                ref: "fridgeElement",
                value: "fridge",
            },
        };
    },
    methods: {
        getTableDetailsTypeLabel(value) {
            const found = this.ALL_TABLE_DETAILS_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") {
                return this.$t(`labels.booking.seatingPlan.elements.${value}`);
            }
            console.error("No SeatingPlanTableDetailsType for given value", value);
            return value;
        },
    },
    computed: {
        ALL_TABLE_DETAILS_TYPES() {
            return [
                // Tables
                this.TABLE_DETAILS_TYPE_ROUND_TABLE,
                this.TABLE_DETAILS_TYPE_SQUARE_TABLE,
                this.TABLE_DETAILS_TYPE_RECTANGLE_TABLE,

                // Decorations
                this.TABLE_DETAILS_TYPE_COUNTER,
                this.TABLE_DETAILS_TYPE_FLOWER,
                this.TABLE_DETAILS_TYPE_DELIMITER,
                this.TABLE_DETAILS_TYPE_AQUARIUM,
                this.TABLE_DETAILS_TYPE_WARDROBE_WINE,
                this.TABLE_DETAILS_TYPE_CHIMNEY,
                this.TABLE_DETAILS_TYPE_KITCHEN,
                this.TABLE_DETAILS_TYPE_STAIRS1,
                this.TABLE_DETAILS_TYPE_STAIRS1_RIGHT,
                this.TABLE_DETAILS_TYPE_STAIRS2,
                this.TABLE_DETAILS_TYPE_STAIRS2_RIGHT,
                this.TABLE_DETAILS_TYPE_STAIRS3,
                this.TABLE_DETAILS_TYPE_STAIRS3_LEFT,
                this.TABLE_DETAILS_TYPE_STAIRS4,
                this.TABLE_DETAILS_TYPE_CAR_PARK,
                this.TABLE_DETAILS_TYPE_TOILET,
                this.TABLE_DETAILS_TYPE_POOL,
                this.TABLE_DETAILS_TYPE_BEACH,
                this.TABLE_DETAILS_TYPE_DOOR1,
                this.TABLE_DETAILS_TYPE_DOOR2,
                this.TABLE_DETAILS_TYPE_BUFFET,
                this.TABLE_DETAILS_TYPE_FRIDGE,
            ];
        },
    },
};
