var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "error" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c(
                  "div",
                  [
                    _vm.formErrors && _vm.formErrors.message
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$tl("errors.common.unknown")) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formSuccess
                      ? _c("div", { staticClass: "alert alert-success" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.formSuccess) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formLoading
                      ? _c("LoaderComponent", {
                          attrs: { message: _vm.$t("labels.saveInProgress") },
                        })
                      : _c(
                          "form",
                          {
                            attrs: { method: "post" },
                            on: { submit: _vm.postForm },
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "border-light b-radius-20 p-4",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-row mb-2" },
                                      [
                                        _c("div", { staticClass: "col-4" }, [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl("labels.form.date")
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-4 col-8 date-resa-cal",
                                          },
                                          [
                                            _c("DatePicker", {
                                              class: {
                                                "is-invalid":
                                                  _vm.hasErrors("date"),
                                              },
                                              attrs: { disabled: _vm.readonly },
                                              model: {
                                                value: _vm.date_selected,
                                                callback: function ($$v) {
                                                  _vm.date_selected = $$v
                                                },
                                                expression: "date_selected",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _vm.hasErrors("date")
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    _vm.formErrors.errors.date,
                                                    function (err, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticClass:
                                                            "invalid-feedback d-block",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            " +
                                                              _vm._s(err) +
                                                              "\n                                        "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !_vm.service_id
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "row mt-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12 mb-2" },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.booking.services.servicesOnDate",
                                                          _vm.restaurant_id,
                                                          {
                                                            date: _vm.displayDate(
                                                              _vm.date_selected
                                                            ),
                                                          }
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _vm.hasErrors("services")
                                                    ? _c(
                                                        "div",
                                                        _vm._l(
                                                          _vm.formErrors.errors
                                                            .services,
                                                          function (
                                                            err,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "invalid-feedback d-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                " +
                                                                    _vm._s(
                                                                      err
                                                                    ) +
                                                                    "\n                                            "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _vm._l(
                                                    _vm.services_available,
                                                    function (service) {
                                                      return [
                                                        !_vm.$_.isEmpty(
                                                          _vm.services
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                key: service.id,
                                                              },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "container-box",
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                        "text-transform":
                                                                          "capitalize",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm
                                                                                  .services[
                                                                                  service
                                                                                    .id
                                                                                ]
                                                                                  .value,
                                                                              expression:
                                                                                "services[service.id].value",
                                                                            },
                                                                          ],
                                                                        attrs: {
                                                                          type: "checkbox",
                                                                          name: "services[]",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value: true,
                                                                            checked:
                                                                              Array.isArray(
                                                                                _vm
                                                                                  .services[
                                                                                  service
                                                                                    .id
                                                                                ]
                                                                                  .value
                                                                              )
                                                                                ? _vm._i(
                                                                                    _vm
                                                                                      .services[
                                                                                      service
                                                                                        .id
                                                                                    ]
                                                                                      .value,
                                                                                    true
                                                                                  ) >
                                                                                  -1
                                                                                : _vm
                                                                                    .services[
                                                                                    service
                                                                                      .id
                                                                                  ]
                                                                                    .value,
                                                                          },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.setSelectedService(
                                                                                $event,
                                                                                service
                                                                              )
                                                                            },
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              var $$a =
                                                                                  _vm
                                                                                    .services[
                                                                                    service
                                                                                      .id
                                                                                  ]
                                                                                    .value,
                                                                                $$el =
                                                                                  $event.target,
                                                                                $$c =
                                                                                  $$el.checked
                                                                                    ? true
                                                                                    : false
                                                                              if (
                                                                                Array.isArray(
                                                                                  $$a
                                                                                )
                                                                              ) {
                                                                                var $$v = true,
                                                                                  $$i =
                                                                                    _vm._i(
                                                                                      $$a,
                                                                                      $$v
                                                                                    )
                                                                                if (
                                                                                  $$el.checked
                                                                                ) {
                                                                                  $$i <
                                                                                    0 &&
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .services[
                                                                                        service
                                                                                          .id
                                                                                      ],
                                                                                      "value",
                                                                                      $$a.concat(
                                                                                        [
                                                                                          $$v,
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                } else {
                                                                                  $$i >
                                                                                    -1 &&
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .services[
                                                                                        service
                                                                                          .id
                                                                                      ],
                                                                                      "value",
                                                                                      $$a
                                                                                        .slice(
                                                                                          0,
                                                                                          $$i
                                                                                        )
                                                                                        .concat(
                                                                                          $$a.slice(
                                                                                            $$i +
                                                                                              1
                                                                                          )
                                                                                        )
                                                                                    )
                                                                                }
                                                                              } else {
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .services[
                                                                                    service
                                                                                      .id
                                                                                  ],
                                                                                  "value",
                                                                                  $$c
                                                                                )
                                                                              }
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "checkmark",
                                                                      attrs: {
                                                                        disabled:
                                                                          _vm.readonly,
                                                                      },
                                                                    }),
                                                                    _vm._v(
                                                                      "\n                                                    " +
                                                                        _vm._s(
                                                                          _vm.getServiceCategoryLabel(
                                                                            service
                                                                          )
                                                                        ) +
                                                                        " - " +
                                                                        _vm._s(
                                                                          service.name
                                                                        ) +
                                                                        "\n                                                "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "offset-1 col-11",
                                                                  },
                                                                  [
                                                                    _vm._l(
                                                                      service.slots,
                                                                      function (
                                                                        slot
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              key: slot.id,
                                                                              staticClass:
                                                                                "row mb-1",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "col-5",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "label",
                                                                                    {
                                                                                      staticClass:
                                                                                        "container-box",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "text-transform":
                                                                                            "capitalize",
                                                                                          width:
                                                                                            "30%",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "input",
                                                                                        {
                                                                                          directives:
                                                                                            [
                                                                                              {
                                                                                                name: "model",
                                                                                                rawName:
                                                                                                  "v-model",
                                                                                                value:
                                                                                                  _vm
                                                                                                    .services[
                                                                                                    service
                                                                                                      .id
                                                                                                  ]
                                                                                                    .slots[
                                                                                                    slot
                                                                                                      .id
                                                                                                  ]
                                                                                                    .value,
                                                                                                expression:
                                                                                                  "services[service.id].slots[slot.id].value",
                                                                                              },
                                                                                            ],
                                                                                          attrs:
                                                                                            {
                                                                                              type: "checkbox",
                                                                                              name: `services-slots-${service.id}[]`,
                                                                                            },
                                                                                          domProps:
                                                                                            {
                                                                                              value: true,
                                                                                              checked:
                                                                                                Array.isArray(
                                                                                                  _vm
                                                                                                    .services[
                                                                                                    service
                                                                                                      .id
                                                                                                  ]
                                                                                                    .slots[
                                                                                                    slot
                                                                                                      .id
                                                                                                  ]
                                                                                                    .value
                                                                                                )
                                                                                                  ? _vm._i(
                                                                                                      _vm
                                                                                                        .services[
                                                                                                        service
                                                                                                          .id
                                                                                                      ]
                                                                                                        .slots[
                                                                                                        slot
                                                                                                          .id
                                                                                                      ]
                                                                                                        .value,
                                                                                                      true
                                                                                                    ) >
                                                                                                    -1
                                                                                                  : _vm
                                                                                                      .services[
                                                                                                      service
                                                                                                        .id
                                                                                                    ]
                                                                                                      .slots[
                                                                                                      slot
                                                                                                        .id
                                                                                                    ]
                                                                                                      .value,
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.setSelectedServiceSlot(
                                                                                                  $event,
                                                                                                  service,
                                                                                                  slot
                                                                                                )
                                                                                              },
                                                                                            change:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                var $$a =
                                                                                                    _vm
                                                                                                      .services[
                                                                                                      service
                                                                                                        .id
                                                                                                    ]
                                                                                                      .slots[
                                                                                                      slot
                                                                                                        .id
                                                                                                    ]
                                                                                                      .value,
                                                                                                  $$el =
                                                                                                    $event.target,
                                                                                                  $$c =
                                                                                                    $$el.checked
                                                                                                      ? true
                                                                                                      : false
                                                                                                if (
                                                                                                  Array.isArray(
                                                                                                    $$a
                                                                                                  )
                                                                                                ) {
                                                                                                  var $$v = true,
                                                                                                    $$i =
                                                                                                      _vm._i(
                                                                                                        $$a,
                                                                                                        $$v
                                                                                                      )
                                                                                                  if (
                                                                                                    $$el.checked
                                                                                                  ) {
                                                                                                    $$i <
                                                                                                      0 &&
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .services[
                                                                                                          service
                                                                                                            .id
                                                                                                        ]
                                                                                                          .slots[
                                                                                                          slot
                                                                                                            .id
                                                                                                        ],
                                                                                                        "value",
                                                                                                        $$a.concat(
                                                                                                          [
                                                                                                            $$v,
                                                                                                          ]
                                                                                                        )
                                                                                                      )
                                                                                                  } else {
                                                                                                    $$i >
                                                                                                      -1 &&
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .services[
                                                                                                          service
                                                                                                            .id
                                                                                                        ]
                                                                                                          .slots[
                                                                                                          slot
                                                                                                            .id
                                                                                                        ],
                                                                                                        "value",
                                                                                                        $$a
                                                                                                          .slice(
                                                                                                            0,
                                                                                                            $$i
                                                                                                          )
                                                                                                          .concat(
                                                                                                            $$a.slice(
                                                                                                              $$i +
                                                                                                                1
                                                                                                            )
                                                                                                          )
                                                                                                      )
                                                                                                  }
                                                                                                } else {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .services[
                                                                                                      service
                                                                                                        .id
                                                                                                    ]
                                                                                                      .slots[
                                                                                                      slot
                                                                                                        .id
                                                                                                    ],
                                                                                                    "value",
                                                                                                    $$c
                                                                                                  )
                                                                                                }
                                                                                              },
                                                                                          },
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "checkmark",
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        "\n                                                                    " +
                                                                                          _vm._s(
                                                                                            slot.hour_start
                                                                                          ) +
                                                                                          "\n                                                                "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "col-7",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "button",
                                                                                    {
                                                                                      staticClass:
                                                                                        "btn btn-outline-secondary btn-circle btn-sm",
                                                                                      attrs:
                                                                                        {
                                                                                          type: "button",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.services[
                                                                                              service.id
                                                                                            ].slots[
                                                                                              slot.id
                                                                                            ].displayPaxes = true
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                                    " +
                                                                                          _vm._s(
                                                                                            _vm.$tl(
                                                                                              "labels.booking.services.managePerPax"
                                                                                            )
                                                                                          ) +
                                                                                          "\n                                                                "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "show",
                                                                                        rawName:
                                                                                          "v-show",
                                                                                        value:
                                                                                          _vm
                                                                                            .services[
                                                                                            service
                                                                                              .id
                                                                                          ]
                                                                                            .slots[
                                                                                            slot
                                                                                              .id
                                                                                          ]
                                                                                            .displayPaxes ===
                                                                                          true,
                                                                                        expression:
                                                                                          "services[service.id].slots[slot.id].displayPaxes === true",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "offset-1 col-11 p-0",
                                                                                },
                                                                                _vm._l(
                                                                                  _vm.$_.range(
                                                                                    _vm.minPax,
                                                                                    _vm.maxPax +
                                                                                      1
                                                                                  ),
                                                                                  function (
                                                                                    value
                                                                                  ) {
                                                                                    return _c(
                                                                                      "label",
                                                                                      {
                                                                                        key: value,
                                                                                        staticClass:
                                                                                          "container-box",
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "80px",
                                                                                            "text-transform":
                                                                                              "capitalize",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "input",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "model",
                                                                                                  rawName:
                                                                                                    "v-model",
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .services[
                                                                                                      service
                                                                                                        .id
                                                                                                    ]
                                                                                                      .slots[
                                                                                                      slot
                                                                                                        .id
                                                                                                    ]
                                                                                                      .paxes,
                                                                                                  expression:
                                                                                                    "services[service.id].slots[slot.id].paxes",
                                                                                                },
                                                                                              ],
                                                                                            attrs:
                                                                                              {
                                                                                                type: "checkbox",
                                                                                                name: `slot-paxes-${slot.id}[]`,
                                                                                              },
                                                                                            domProps:
                                                                                              {
                                                                                                value:
                                                                                                  value,
                                                                                                checked:
                                                                                                  Array.isArray(
                                                                                                    _vm
                                                                                                      .services[
                                                                                                      service
                                                                                                        .id
                                                                                                    ]
                                                                                                      .slots[
                                                                                                      slot
                                                                                                        .id
                                                                                                    ]
                                                                                                      .paxes
                                                                                                  )
                                                                                                    ? _vm._i(
                                                                                                        _vm
                                                                                                          .services[
                                                                                                          service
                                                                                                            .id
                                                                                                        ]
                                                                                                          .slots[
                                                                                                          slot
                                                                                                            .id
                                                                                                        ]
                                                                                                          .paxes,
                                                                                                        value
                                                                                                      ) >
                                                                                                      -1
                                                                                                    : _vm
                                                                                                        .services[
                                                                                                        service
                                                                                                          .id
                                                                                                      ]
                                                                                                        .slots[
                                                                                                        slot
                                                                                                          .id
                                                                                                      ]
                                                                                                        .paxes,
                                                                                              },
                                                                                            on: {
                                                                                              change:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  var $$a =
                                                                                                      _vm
                                                                                                        .services[
                                                                                                        service
                                                                                                          .id
                                                                                                      ]
                                                                                                        .slots[
                                                                                                        slot
                                                                                                          .id
                                                                                                      ]
                                                                                                        .paxes,
                                                                                                    $$el =
                                                                                                      $event.target,
                                                                                                    $$c =
                                                                                                      $$el.checked
                                                                                                        ? true
                                                                                                        : false
                                                                                                  if (
                                                                                                    Array.isArray(
                                                                                                      $$a
                                                                                                    )
                                                                                                  ) {
                                                                                                    var $$v =
                                                                                                        value,
                                                                                                      $$i =
                                                                                                        _vm._i(
                                                                                                          $$a,
                                                                                                          $$v
                                                                                                        )
                                                                                                    if (
                                                                                                      $$el.checked
                                                                                                    ) {
                                                                                                      $$i <
                                                                                                        0 &&
                                                                                                        _vm.$set(
                                                                                                          _vm
                                                                                                            .services[
                                                                                                            service
                                                                                                              .id
                                                                                                          ]
                                                                                                            .slots[
                                                                                                            slot
                                                                                                              .id
                                                                                                          ],
                                                                                                          "paxes",
                                                                                                          $$a.concat(
                                                                                                            [
                                                                                                              $$v,
                                                                                                            ]
                                                                                                          )
                                                                                                        )
                                                                                                    } else {
                                                                                                      $$i >
                                                                                                        -1 &&
                                                                                                        _vm.$set(
                                                                                                          _vm
                                                                                                            .services[
                                                                                                            service
                                                                                                              .id
                                                                                                          ]
                                                                                                            .slots[
                                                                                                            slot
                                                                                                              .id
                                                                                                          ],
                                                                                                          "paxes",
                                                                                                          $$a
                                                                                                            .slice(
                                                                                                              0,
                                                                                                              $$i
                                                                                                            )
                                                                                                            .concat(
                                                                                                              $$a.slice(
                                                                                                                $$i +
                                                                                                                  1
                                                                                                              )
                                                                                                            )
                                                                                                        )
                                                                                                    }
                                                                                                  } else {
                                                                                                    _vm.$set(
                                                                                                      _vm
                                                                                                        .services[
                                                                                                        service
                                                                                                          .id
                                                                                                      ]
                                                                                                        .slots[
                                                                                                        slot
                                                                                                          .id
                                                                                                      ],
                                                                                                      "paxes",
                                                                                                      $$c
                                                                                                    )
                                                                                                  }
                                                                                                },
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "checkmark",
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          "\n                                                                    " +
                                                                                            _vm._s(
                                                                                              _vm.capitalize(
                                                                                                _vm.$tl(
                                                                                                  "labels.pax"
                                                                                                )
                                                                                              )
                                                                                            ) +
                                                                                            " " +
                                                                                            _vm._s(
                                                                                              value
                                                                                            ) +
                                                                                            "\n                                                                "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                0
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm.service_id
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "row mt-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12 mb-2" },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tl(
                                                          "labels.booking.services.servicesOnDate",
                                                          _vm.restaurant_id,
                                                          {
                                                            date: _vm.displayDate(
                                                              _vm.date_selected
                                                            ),
                                                          }
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _vm.hasErrors("services")
                                                    ? _c(
                                                        "div",
                                                        _vm._l(
                                                          _vm.formErrors.errors
                                                            .services,
                                                          function (
                                                            err,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "invalid-feedback d-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                " +
                                                                    _vm._s(
                                                                      err
                                                                    ) +
                                                                    "\n                                            "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _vm._l(
                                                    _vm.services_available,
                                                    function (service) {
                                                      return [
                                                        !_vm.$_.isEmpty(
                                                          _vm.services
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                key: service.id,
                                                              },
                                                              [
                                                                _vm.service_id ===
                                                                service.id
                                                                  ? _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "container-box",
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100%",
                                                                            "text-transform":
                                                                              "capitalize",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .services[
                                                                                      service
                                                                                        .id
                                                                                    ]
                                                                                      .value,
                                                                                  expression:
                                                                                    "services[service.id].value",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                type: "checkbox",
                                                                                name: "services[]",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                value: true,
                                                                                checked:
                                                                                  Array.isArray(
                                                                                    _vm
                                                                                      .services[
                                                                                      service
                                                                                        .id
                                                                                    ]
                                                                                      .value
                                                                                  )
                                                                                    ? _vm._i(
                                                                                        _vm
                                                                                          .services[
                                                                                          service
                                                                                            .id
                                                                                        ]
                                                                                          .value,
                                                                                        true
                                                                                      ) >
                                                                                      -1
                                                                                    : _vm
                                                                                        .services[
                                                                                        service
                                                                                          .id
                                                                                      ]
                                                                                        .value,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setSelectedService(
                                                                                    $event,
                                                                                    service
                                                                                  )
                                                                                },
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      _vm
                                                                                        .services[
                                                                                        service
                                                                                          .id
                                                                                      ]
                                                                                        .value,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c =
                                                                                      $$el.checked
                                                                                        ? true
                                                                                        : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v = true,
                                                                                      $$i =
                                                                                        _vm._i(
                                                                                          $$a,
                                                                                          $$v
                                                                                        )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .services[
                                                                                            service
                                                                                              .id
                                                                                          ],
                                                                                          "value",
                                                                                          $$a.concat(
                                                                                            [
                                                                                              $$v,
                                                                                            ]
                                                                                          )
                                                                                        )
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .services[
                                                                                            service
                                                                                              .id
                                                                                          ],
                                                                                          "value",
                                                                                          $$a
                                                                                            .slice(
                                                                                              0,
                                                                                              $$i
                                                                                            )
                                                                                            .concat(
                                                                                              $$a.slice(
                                                                                                $$i +
                                                                                                  1
                                                                                              )
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                  } else {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .services[
                                                                                        service
                                                                                          .id
                                                                                      ],
                                                                                      "value",
                                                                                      $$c
                                                                                    )
                                                                                  }
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "checkmark",
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          "\n                                                    " +
                                                                            _vm._s(
                                                                              _vm.getServiceCategoryLabel(
                                                                                service
                                                                              )
                                                                            ) +
                                                                            " - " +
                                                                            _vm._s(
                                                                              service.name
                                                                            ) +
                                                                            "\n                                                "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm.service_id ===
                                                                service.id
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "offset-1 col-11",
                                                                      },
                                                                      [
                                                                        _vm._l(
                                                                          service.slots,
                                                                          function (
                                                                            slot
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  key: slot.id,
                                                                                  staticClass:
                                                                                    "row mb-1",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-12",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "label",
                                                                                        {
                                                                                          staticClass:
                                                                                            "container-box",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "text-transform":
                                                                                                "capitalize",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "input",
                                                                                            {
                                                                                              directives:
                                                                                                [
                                                                                                  {
                                                                                                    name: "model",
                                                                                                    rawName:
                                                                                                      "v-model",
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .services[
                                                                                                        service
                                                                                                          .id
                                                                                                      ]
                                                                                                        .slots[
                                                                                                        slot
                                                                                                          .id
                                                                                                      ]
                                                                                                        .value,
                                                                                                    expression:
                                                                                                      "services[service.id].slots[slot.id].value",
                                                                                                  },
                                                                                                ],
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "checkbox",
                                                                                                  name: `services-slots-${service.id}[]`,
                                                                                                },
                                                                                              domProps:
                                                                                                {
                                                                                                  value: true,
                                                                                                  checked:
                                                                                                    Array.isArray(
                                                                                                      _vm
                                                                                                        .services[
                                                                                                        service
                                                                                                          .id
                                                                                                      ]
                                                                                                        .slots[
                                                                                                        slot
                                                                                                          .id
                                                                                                      ]
                                                                                                        .value
                                                                                                    )
                                                                                                      ? _vm._i(
                                                                                                          _vm
                                                                                                            .services[
                                                                                                            service
                                                                                                              .id
                                                                                                          ]
                                                                                                            .slots[
                                                                                                            slot
                                                                                                              .id
                                                                                                          ]
                                                                                                            .value,
                                                                                                          true
                                                                                                        ) >
                                                                                                        -1
                                                                                                      : _vm
                                                                                                          .services[
                                                                                                          service
                                                                                                            .id
                                                                                                        ]
                                                                                                          .slots[
                                                                                                          slot
                                                                                                            .id
                                                                                                        ]
                                                                                                          .value,
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.setSelectedServiceSlot(
                                                                                                      $event,
                                                                                                      service,
                                                                                                      slot
                                                                                                    )
                                                                                                  },
                                                                                                change:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    var $$a =
                                                                                                        _vm
                                                                                                          .services[
                                                                                                          service
                                                                                                            .id
                                                                                                        ]
                                                                                                          .slots[
                                                                                                          slot
                                                                                                            .id
                                                                                                        ]
                                                                                                          .value,
                                                                                                      $$el =
                                                                                                        $event.target,
                                                                                                      $$c =
                                                                                                        $$el.checked
                                                                                                          ? true
                                                                                                          : false
                                                                                                    if (
                                                                                                      Array.isArray(
                                                                                                        $$a
                                                                                                      )
                                                                                                    ) {
                                                                                                      var $$v = true,
                                                                                                        $$i =
                                                                                                          _vm._i(
                                                                                                            $$a,
                                                                                                            $$v
                                                                                                          )
                                                                                                      if (
                                                                                                        $$el.checked
                                                                                                      ) {
                                                                                                        $$i <
                                                                                                          0 &&
                                                                                                          _vm.$set(
                                                                                                            _vm
                                                                                                              .services[
                                                                                                              service
                                                                                                                .id
                                                                                                            ]
                                                                                                              .slots[
                                                                                                              slot
                                                                                                                .id
                                                                                                            ],
                                                                                                            "value",
                                                                                                            $$a.concat(
                                                                                                              [
                                                                                                                $$v,
                                                                                                              ]
                                                                                                            )
                                                                                                          )
                                                                                                      } else {
                                                                                                        $$i >
                                                                                                          -1 &&
                                                                                                          _vm.$set(
                                                                                                            _vm
                                                                                                              .services[
                                                                                                              service
                                                                                                                .id
                                                                                                            ]
                                                                                                              .slots[
                                                                                                              slot
                                                                                                                .id
                                                                                                            ],
                                                                                                            "value",
                                                                                                            $$a
                                                                                                              .slice(
                                                                                                                0,
                                                                                                                $$i
                                                                                                              )
                                                                                                              .concat(
                                                                                                                $$a.slice(
                                                                                                                  $$i +
                                                                                                                    1
                                                                                                                )
                                                                                                              )
                                                                                                          )
                                                                                                      }
                                                                                                    } else {
                                                                                                      _vm.$set(
                                                                                                        _vm
                                                                                                          .services[
                                                                                                          service
                                                                                                            .id
                                                                                                        ]
                                                                                                          .slots[
                                                                                                          slot
                                                                                                            .id
                                                                                                        ],
                                                                                                        "value",
                                                                                                        $$c
                                                                                                      )
                                                                                                    }
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "checkmark",
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            "\n                                                                    " +
                                                                                              _vm._s(
                                                                                                slot.hour_start
                                                                                              ) +
                                                                                              "\n                                                                "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "button",
                                                                                        {
                                                                                          staticClass:
                                                                                            "btn btn-outline-secondary btn-circle btn-sm",
                                                                                          attrs:
                                                                                            {
                                                                                              type: "button",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                _vm.services[
                                                                                                  service.id
                                                                                                ].slots[
                                                                                                  slot.id
                                                                                                ].displayPaxes = true
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                    " +
                                                                                              _vm._s(
                                                                                                _vm.$tl(
                                                                                                  "labels.booking.services.managePerPax"
                                                                                                )
                                                                                              ) +
                                                                                              "\n                                                                "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      directives:
                                                                                        [
                                                                                          {
                                                                                            name: "show",
                                                                                            rawName:
                                                                                              "v-show",
                                                                                            value:
                                                                                              _vm
                                                                                                .services[
                                                                                                service
                                                                                                  .id
                                                                                              ]
                                                                                                .slots[
                                                                                                slot
                                                                                                  .id
                                                                                              ]
                                                                                                .displayPaxes ===
                                                                                              true,
                                                                                            expression:
                                                                                              "services[service.id].slots[slot.id].displayPaxes === true",
                                                                                          },
                                                                                        ],
                                                                                      staticClass:
                                                                                        "offset-1 col-11 p-0",
                                                                                    },
                                                                                    _vm._l(
                                                                                      _vm.$_.range(
                                                                                        _vm.minPax,
                                                                                        _vm.maxPax +
                                                                                          1
                                                                                      ),
                                                                                      function (
                                                                                        value
                                                                                      ) {
                                                                                        return _c(
                                                                                          "label",
                                                                                          {
                                                                                            key: value,
                                                                                            staticClass:
                                                                                              "container-box",
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "80px",
                                                                                                "text-transform":
                                                                                                  "capitalize",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "input",
                                                                                              {
                                                                                                directives:
                                                                                                  [
                                                                                                    {
                                                                                                      name: "model",
                                                                                                      rawName:
                                                                                                        "v-model",
                                                                                                      value:
                                                                                                        _vm
                                                                                                          .services[
                                                                                                          service
                                                                                                            .id
                                                                                                        ]
                                                                                                          .slots[
                                                                                                          slot
                                                                                                            .id
                                                                                                        ]
                                                                                                          .paxes,
                                                                                                      expression:
                                                                                                        "services[service.id].slots[slot.id].paxes",
                                                                                                    },
                                                                                                  ],
                                                                                                attrs:
                                                                                                  {
                                                                                                    type: "checkbox",
                                                                                                    name: `slot-paxes-${slot.id}[]`,
                                                                                                  },
                                                                                                domProps:
                                                                                                  {
                                                                                                    value:
                                                                                                      value,
                                                                                                    checked:
                                                                                                      Array.isArray(
                                                                                                        _vm
                                                                                                          .services[
                                                                                                          service
                                                                                                            .id
                                                                                                        ]
                                                                                                          .slots[
                                                                                                          slot
                                                                                                            .id
                                                                                                        ]
                                                                                                          .paxes
                                                                                                      )
                                                                                                        ? _vm._i(
                                                                                                            _vm
                                                                                                              .services[
                                                                                                              service
                                                                                                                .id
                                                                                                            ]
                                                                                                              .slots[
                                                                                                              slot
                                                                                                                .id
                                                                                                            ]
                                                                                                              .paxes,
                                                                                                            value
                                                                                                          ) >
                                                                                                          -1
                                                                                                        : _vm
                                                                                                            .services[
                                                                                                            service
                                                                                                              .id
                                                                                                          ]
                                                                                                            .slots[
                                                                                                            slot
                                                                                                              .id
                                                                                                          ]
                                                                                                            .paxes,
                                                                                                  },
                                                                                                on: {
                                                                                                  change:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      var $$a =
                                                                                                          _vm
                                                                                                            .services[
                                                                                                            service
                                                                                                              .id
                                                                                                          ]
                                                                                                            .slots[
                                                                                                            slot
                                                                                                              .id
                                                                                                          ]
                                                                                                            .paxes,
                                                                                                        $$el =
                                                                                                          $event.target,
                                                                                                        $$c =
                                                                                                          $$el.checked
                                                                                                            ? true
                                                                                                            : false
                                                                                                      if (
                                                                                                        Array.isArray(
                                                                                                          $$a
                                                                                                        )
                                                                                                      ) {
                                                                                                        var $$v =
                                                                                                            value,
                                                                                                          $$i =
                                                                                                            _vm._i(
                                                                                                              $$a,
                                                                                                              $$v
                                                                                                            )
                                                                                                        if (
                                                                                                          $$el.checked
                                                                                                        ) {
                                                                                                          $$i <
                                                                                                            0 &&
                                                                                                            _vm.$set(
                                                                                                              _vm
                                                                                                                .services[
                                                                                                                service
                                                                                                                  .id
                                                                                                              ]
                                                                                                                .slots[
                                                                                                                slot
                                                                                                                  .id
                                                                                                              ],
                                                                                                              "paxes",
                                                                                                              $$a.concat(
                                                                                                                [
                                                                                                                  $$v,
                                                                                                                ]
                                                                                                              )
                                                                                                            )
                                                                                                        } else {
                                                                                                          $$i >
                                                                                                            -1 &&
                                                                                                            _vm.$set(
                                                                                                              _vm
                                                                                                                .services[
                                                                                                                service
                                                                                                                  .id
                                                                                                              ]
                                                                                                                .slots[
                                                                                                                slot
                                                                                                                  .id
                                                                                                              ],
                                                                                                              "paxes",
                                                                                                              $$a
                                                                                                                .slice(
                                                                                                                  0,
                                                                                                                  $$i
                                                                                                                )
                                                                                                                .concat(
                                                                                                                  $$a.slice(
                                                                                                                    $$i +
                                                                                                                      1
                                                                                                                  )
                                                                                                                )
                                                                                                            )
                                                                                                        }
                                                                                                      } else {
                                                                                                        _vm.$set(
                                                                                                          _vm
                                                                                                            .services[
                                                                                                            service
                                                                                                              .id
                                                                                                          ]
                                                                                                            .slots[
                                                                                                            slot
                                                                                                              .id
                                                                                                          ],
                                                                                                          "paxes",
                                                                                                          $$c
                                                                                                        )
                                                                                                      }
                                                                                                    },
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "checkmark",
                                                                                              }
                                                                                            ),
                                                                                            _vm._v(
                                                                                              "\n                                                                    " +
                                                                                                _vm._s(
                                                                                                  _vm.capitalize(
                                                                                                    _vm.$tl(
                                                                                                      "labels.pax"
                                                                                                    )
                                                                                                  )
                                                                                                ) +
                                                                                                " " +
                                                                                                _vm._s(
                                                                                                  value
                                                                                                ) +
                                                                                                "\n                                                                "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                    0
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          }
                                                                        ),
                                                                      ],
                                                                      2
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "sepa" }),
                                    _vm._v(" "),
                                    _vm.showSaveButton
                                      ? _c(
                                          "div",
                                          { staticClass: "form-row mb-2 mt-4" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-12" },
                                              [
                                                _c("input", {
                                                  staticClass:
                                                    "btn btn-success btn-circle btn-sm",
                                                  attrs: {
                                                    type: "submit",
                                                    name: "submit",
                                                  },
                                                  domProps: {
                                                    value: _vm.$t(
                                                      "labels.form.actions.save"
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "i18n",
                                      {
                                        staticClass: "text-muted",
                                        attrs: {
                                          path: _vm.$getTranslationKey(
                                            "infos.booking.services.enableBack",
                                            _vm.restaurant_id
                                          ),
                                          tag: "small",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "dashboard" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: { name: "booking.home" },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tl("labels.dashboard")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        ),
                  ],
                  1
                ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }