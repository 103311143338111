<template>
    <div>
        <LoaderComponent v-if="loading" />
        <span class="text-danger" v-else-if="error">{{ error }}</span>
        <div v-else>
            <div class="row mb-3">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-2">
                            <div class="col-md-5 pt-1">
                                <label>
                                    Nom
                                    <small>*</small>
                                </label>
                            </div>
                            <div class="col-md-7 mb-2">
                                <input type="text" class="form-control" v-model="category.name" />
                                <ShowErrors :errors="errors" errorKey="name" />
                            </div>
                            <div class="col-md-5 pt-1">
                                <label :class="{ 'text-muted': !category.is_stock_per_slot_editable }"
                                    >Stock / Créneau</label
                                >
                            </div>
                            <div class="col-md-7 mb-2">
                                <div class="row">
                                    <div class="col-md-4">
                                        <input
                                            :disabled="stock_per_slot_nolimit || !category.is_stock_per_slot_editable"
                                            type="number"
                                            class="form-control"
                                            v-model="category.stock_per_slot" />
                                    </div>
                                    <div class="col-md-8 mt-1">
                                        <label class="container-box" style="width: initial">
                                            <input
                                                :disabled="!category.is_stock_per_slot_editable"
                                                type="checkbox"
                                                v-model="stock_per_slot_nolimit" />
                                            <span class="checkmark"></span>
                                            <span :class="{ 'text-muted': !category.is_stock_per_slot_editable }"
                                                >Illimité</span
                                            >
                                        </label>
                                    </div>
                                    <ShowErrors class="col-12" :errors="errors" errorKey="stock_per_slot" />
                                </div>
                            </div>
                            <div class="col-md-5 pt-1">
                                <label>Stock / Service</label>
                            </div>
                            <div class="col-md-7 mb-2">
                                <div class="row">
                                    <div class="col-md-4">
                                        <input
                                            :disabled="stock_per_service_nolimit"
                                            type="number"
                                            class="form-control"
                                            v-model="category.stock_per_service" />
                                    </div>
                                    <div class="col-md-8 mt-1">
                                        <label class="container-box" style="width: initial">
                                            <input type="checkbox" v-model="stock_per_service_nolimit" />
                                            <span class="checkmark"></span> Illimité
                                        </label>
                                    </div>
                                    <ShowErrors class="col-12" :errors="errors" errorKey="stock_per_service" />
                                </div>
                            </div>
                            <div class="col-md-5 pt-1">
                                <label>Stock / Jour</label>
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-4">
                                        <input
                                            :disabled="stock_per_day_nolimit"
                                            type="number"
                                            class="form-control"
                                            v-model="category.stock_per_day" />
                                    </div>
                                    <div class="col-md-8 mt-1">
                                        <label class="container-box" style="width: initial">
                                            <input type="checkbox" v-model="stock_per_day_nolimit" />
                                            <span class="checkmark"></span> Illimité
                                        </label>
                                    </div>
                                    <ShowErrors class="col-12" :errors="errors" errorKey="stock_per_day" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent";
import ShowErrors from "../../errors/ShowErrors";
import axios from "axios";

export default {
    data() {
        return {
            loading: false,
            error: null,
            errors: null,
            stock_per_slot_nolimit: this.category.stock_per_slot === null,
            stock_per_service_nolimit: this.category.stock_per_service === null,
            stock_per_day_nolimit: this.category.stock_per_day === null,
        };
    },
    props: {
        category: {
            type: Object,
            required: true,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        builtData() {
            let data = {
                stock_per_slot_nolimit: this.stock_per_slot_nolimit,
                stock_per_service_nolimit: this.stock_per_service_nolimit,
                stock_per_day_nolimit: this.stock_per_day_nolimit,
            };
            Object.assign(data, this.category);
            return data;
        },
    },
    methods: {
        fetchCreateData() {
            if (this.category.id !== null) return;
            this.loading = true;

            axios
                .get(`/api/click_and_collect/${this.restaurant_id}/products/categoriesCreateDate`)
                .then((response) => {
                    this.loading = false;
                    this.category.is_stock_per_slot_editable = response.data.is_stock_per_slot_editable;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = "Une erreur est survenue";
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                });
        },
        save() {
            this.loading = true;
            this.errors = null;

            if (this.category.id === null) {
                axios
                    .post(`/api/click_and_collect/${this.restaurant_id}/products/categories`, this.builtData)
                    .then((response) => {
                        this.loading = false;
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: response.data.message,
                        });
                        this.$emit("saved");
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.errors = error.response.data.errors;
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: error.response.data.message
                                ? error.response.data.message
                                : "Une erreur est survenue",
                        });
                    });
            } else {
                axios
                    .put(
                        `/api/click_and_collect/${this.restaurant_id}/products/categories/${this.category.id}`,
                        this.builtData
                    )
                    .then((response) => {
                        this.loading = false;
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: response.data.message,
                        });
                        this.$emit("saved");
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.errors = error.response.data.errors;
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: error.response.data.message
                                ? error.response.data.message
                                : "Une erreur est survenue",
                        });
                    });
            }
        },
    },
    watch: {
        stock_per_slot_nolimit(newVal) {
            if (newVal) this.category.stock_per_slot = null;
        },
        stock_per_service_nolimit(newVal) {
            if (newVal) this.category.stock_per_service = null;
        },
        stock_per_day_nolimit(newVal) {
            if (newVal) this.category.stock_per_day = null;
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
    created() {
        this.fetchCreateData();
    },
};
</script>
