<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.booking.waitingList.buttons.remindCustomerWaitingList") }}</h5>
        <div slot="body" class="">
            <LoaderComponent v-if="loading" />
            <div class="w-100 radio" v-else>
                <label class="container-box border-light b-radius-20 p-4 mb-3 d-block w-100" style="padding-left: 50px !important">
                    <input type="radio" name="notify_all_waitings" :value="true" v-model="notifyAllWaitings" />
                    <span style="top: 24px; left: 20px" class="checkmark"></span>
                    {{ $tl("labels.booking.waitingList.buttons.remindAllCustomerOnWaitList") }}
                </label>
                <label class="container-box border-light b-radius-20 p-4 d-block w-100" style="padding-left: 50px !important">
                    <input type="radio" name="notify_all_waitings" :value="false" v-model="notifyAllWaitings" />
                    <span style="top: 24px; left: 20px" class="checkmark"></span>
                    {{ $tl("labels.booking.waitingList.buttons.remindOnlyCustomerNoMail") }}
                </label>
            </div>
        </div>
        <div slot="footer" class="d-flex">
            <button class="modal-default-button btn btn-sm btn-secondary btn-circle" @click="$emit('close')">
                {{ $tl("labels.form.actions.cancel") }}
            </button>
            <button class="btn btn-sm btn-success btn-circle ml-2" @click="post" :disabled="notifyAllWaitings === null">
                {{ $tl("labels.form.actions.validate") }}
            </button>
        </div>
    </modal>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";

export default {
    name: "NotifyWaitingsModal",
    data() {
        return {
            loading: false,
            notifyAllWaitings: null,
        };
    },
    props: {
        restaurant_id: {
            required: true,
        },
        service_id: {
            required: true,
        },
        reservation_date: {
            required: true,
        },
    },
    methods: {
        post() {
            this.loading = true;

            return axios
                .post(`/api/restaurants/${this.restaurant_id}/service/${this.service_id}/waitings/mail`, {
                    all_waitings: this.notifyAllWaitings,
                    reservation_date: this.reservation_date,
                })
                .then((response) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    this.$emit("sent");
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>
