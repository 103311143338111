export default {
    data() {
        return {
            ROLE_OWNER: {
                label: "Compte admin",
                value: "owner",
            },
            ROLE_MANAGER: {
                label: "Responsable",
                value: "manager",
            },
            ROLE_EMPLOYEE: {
                label: "Employé",
                value: "employee",
            },
            ROLE_KITCHEN: {
                label: "Cuisine",
                value: "kitchen",
            },
            ROLE_USER_WITHOUT_ACCOUNT: {
                label: "Utilisateur sans compte",
                value: "user_without_account",
            },
            ROLE_COURIER: {
                label: "Coursier",
                value: "courier",
            },
            ROLE_ADMIN: {
                value: "admin",
            },
        };
    },
    methods: {
        getRoleLabel(value) {
            const found = this.ALL_ROLES_WITH_COURIER.find((w) => w.value == value);

            return typeof found !== "undefined" ? this.$t(`labels.roles.${value}`) : value;
        },
    },
    computed: {
        ALL_ROLES() {
            return [
                this.ROLE_OWNER,
                this.ROLE_MANAGER,
                this.ROLE_EMPLOYEE,
                this.ROLE_KITCHEN,
                this.ROLE_USER_WITHOUT_ACCOUNT,
            ];
        },
        ALL_ROLES_WITH_COURIER() {
            return [
                this.ROLE_OWNER,
                this.ROLE_MANAGER,
                this.ROLE_EMPLOYEE,
                this.ROLE_KITCHEN,
                this.ROLE_USER_WITHOUT_ACCOUNT,
                this.ROLE_COURIER,
            ];
        },
        ROLES_FOR_ADMIN_CAMPAIGN() {
            return [this.ROLE_OWNER, this.ROLE_MANAGER, this.ROLE_EMPLOYEE, this.ROLE_KITCHEN];
        },
    },
};
