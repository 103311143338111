var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12 pt-3" }, [
              _c("div", { staticClass: "border-light b-radius-20 p-4 mb-3" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("strong", [_vm._v("Diffuser votre campagne")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("div", { staticClass: "row mb-2 radio mt-3" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 pt-2" },
                          [
                            _vm._l(_vm.ALL_SAVE_TYPES, function (save_type) {
                              return _c(
                                "label",
                                {
                                  key: save_type.value,
                                  staticClass: "container-box",
                                  staticStyle: {
                                    display: "block",
                                    width: "100%",
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.campaign.save_type,
                                        expression: "campaign.save_type",
                                      },
                                    ],
                                    attrs: { type: "radio" },
                                    domProps: {
                                      value: save_type.value,
                                      checked: _vm._q(
                                        _vm.campaign.save_type,
                                        save_type.value
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.campaign,
                                          "save_type",
                                          save_type.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(save_type.label) +
                                      "\n                                "
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c("ShowErrors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors,
                                errorKey: "save_type",
                              },
                            }),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.campaign.save_type === _vm.SAVE_TYPE_PROG.value
                        ? [
                            _c("div", { staticClass: "row mt-3" }, [
                              _c("div", { staticClass: "col-md-5" }, [
                                _vm._v("Date"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("datepicker", {
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      "disabled-dates": _vm.disabledDates,
                                      "calendar-class":
                                        "positition-relative mt-2",
                                      "input-class": "w-auto",
                                      "monday-first": true,
                                      language: _vm.fr,
                                    },
                                    model: {
                                      value: _vm.willBeSendAtDate,
                                      callback: function ($$v) {
                                        _vm.willBeSendAtDate = $$v
                                      },
                                      expression: "willBeSendAtDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-2" }, [
                              _c("div", { staticClass: "col-md-5" }, [
                                _vm._v("Heure"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("vue-timepicker", {
                                    attrs: {
                                      format: "HH:mm",
                                      "minute-interval": 15,
                                      "hide-disabled-items": true,
                                      "hour-range": _vm.hourRange,
                                      lazy: true,
                                    },
                                    model: {
                                      value: _vm.willBeSendAtHour,
                                      callback: function ($$v) {
                                        _vm.willBeSendAtHour = $$v
                                      },
                                      expression: "willBeSendAtHour",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("ShowErrors", {
                                  staticClass: "col-12",
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "will_be_send_at",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-6 mt-3" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-8 mb-1" }, [
                          _vm._v("Nombre de cibles potentielles :"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-4" }, [
                          _vm._v(_vm._s(_vm.targets.length)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12 mt-1 mb-1" },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-success btn-circle mb-1",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.showTargetsDetails =
                                      !_vm.showTargetsDetails
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    Détails\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.showTargetsDetails
                              ? _vm._l(_vm.targets, function (target) {
                                  return _c(
                                    "small",
                                    {
                                      key: target.id,
                                      staticClass: "d-block text-muted",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.getUserDisplayable(target)
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  )
                                })
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.campaign.type == _vm.CAMPAIGN_TYPE_SMS.value
                        ? [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _vm._v("Envoyer un SMS de test"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-2" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.tel_test,
                                      expression: "tel_test",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "phone",
                                    name: "tel",
                                    disabled: _vm.isSendingTestSms,
                                    placeholder: "Votre numéro de teléphone",
                                  },
                                  domProps: { value: _vm.tel_test },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.tel_test = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-2" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-success btn-circle",
                                    attrs: {
                                      disabled:
                                        _vm.isSendingTestSms || !_vm.tel_test,
                                      type: "button",
                                    },
                                    on: { click: _vm.sendSmsTest },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        Envoyer\n                                    "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        : [
                            _c("div", { staticClass: "row mt-1" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _vm._v("Envoyer un EMAIL de test"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-2" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.email_test,
                                      expression: "email_test",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "email",
                                    name: "email",
                                    disabled: _vm.isSendingTestEmail,
                                    placeholder: "Votre email",
                                  },
                                  domProps: { value: _vm.email_test },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.email_test = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-2" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-success btn-circle",
                                    attrs: {
                                      disabled:
                                        _vm.isSendingTestEmail ||
                                        !_vm.email_test,
                                      type: "button",
                                    },
                                    on: { click: _vm.sendEmailTest },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        Envoyer\n                                    "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ],
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _vm.campaign.type == _vm.CAMPAIGN_TYPE_SMS.value
                  ? _c("div", { staticClass: "row mt-2" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("small", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                            Pour information, les envois de campagnes vers des numéros français ne sont pas\n                            autorisés le dimanche, les jours fériés et la nuit entre 22h et 8h\n                        "
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }