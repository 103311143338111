var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5 campaign" },
    [
      _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [_vm._v("Campagnes")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success btn-circle",
                  attrs: { disabled: !_vm.has_right_to_create_campaigns },
                  on: { click: _vm.addCampaign },
                },
                [
                  _c("feather", { attrs: { type: "plus" } }),
                  _vm._v(
                    "\n                    Créer une campagne\n                "
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", [
            !_vm.loading
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-outline-secondary btn-circle",
                    attrs: {
                      type: "button",
                      disabled: _vm.is_paying_emails_campaign == 1,
                    },
                    on: {
                      click: function ($event) {
                        _vm.showPurchaseEmailsCampaignPackModal =
                          _vm.has_right_to_buy_sms
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.nb_campaign_emails_remaining) +
                        " crédits emails\n                "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.is_paying_emails_campaign
              ? _c("small", { staticClass: "d-block text-warning" }, [
                  _vm._v(
                    "\n                    Une tentative d'achat d'un pack d'emails de campagne est en cours\n                "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent")
        : _c(
            "div",
            [
              _c("div", { staticClass: "row m-0 pt-2" }, [
                _vm.campaigns.length > 0
                  ? _c("div", { staticClass: "container-fluid" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 mt-2" }, [
                          _c("div", { staticClass: "border-bottom" }, [
                            _c(
                              "table",
                              {
                                staticClass:
                                  "capitalize table table-book table-striped table-sm",
                              },
                              [
                                _c("thead", { staticClass: "border-bottom" }, [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      [
                                        _c("feather", {
                                          staticClass: "none-desk feather-20",
                                          attrs: { type: "user" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [_vm._v("Nom")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      [
                                        _c("feather", {
                                          staticClass: "none-desk feather-20",
                                          attrs: { type: "user" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [_vm._v("Type")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      [
                                        _c("feather", {
                                          staticClass: "none-desk feather-20",
                                          attrs: { type: "user" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [_vm._v("Statut")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      [
                                        _c("feather", {
                                          staticClass: "none-desk feather-20",
                                          attrs: { type: "user" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [_vm._v("Date d'envoi")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      [
                                        _c("feather", {
                                          staticClass: "none-desk feather-20",
                                          attrs: { type: "user" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [_vm._v("Nb Envois")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      [
                                        _c("feather", {
                                          staticClass: "none-desk feather-20",
                                          attrs: { type: "user" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [_vm._v("Nb Ouvertures")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      [
                                        _c("feather", {
                                          staticClass: "none-desk feather-20",
                                          attrs: { type: "user" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "none-mobile" },
                                          [_vm._v("Nb Clics")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "none-mobile" }, [
                                      _c(
                                        "span",
                                        { staticClass: "none-mobile" },
                                        [_vm._v("Actions")]
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.campaigns, function (campaign) {
                                    return _c("tr", { key: campaign.id }, [
                                      _c("td", [_vm._v(_vm._s(campaign.name))]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            campaign.type === "sms"
                                              ? "SMS"
                                              : "Email"
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { class: _vm.getStatusClass(campaign) },
                                        [
                                          (campaign.will_be_send_at != null &&
                                            campaign.sent_at == null &&
                                            campaign.type === "email" &&
                                            campaign.nb_clients >
                                              _vm.nb_campaign_emails_remaining) ||
                                          (campaign.paid_at === null &&
                                            campaign.type === "sms")
                                            ? _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: {
                                                        content:
                                                          campaign.paid_at ===
                                                            null &&
                                                          campaign.type ===
                                                            "sms"
                                                            ? "Vous n'avez pas encore payé cette campagne"
                                                            : "Nombre de crédits emails insuffisants",
                                                      },
                                                      expression:
                                                        "{\n                                                    content:\n                                                        campaign.paid_at === null && campaign.type === 'sms'\n                                                            ? 'Vous n\\'avez pas encore payé cette campagne'\n                                                            : 'Nombre de crédits emails insuffisants',\n                                                }",
                                                    },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(
                                                        _vm.getStatusLabel(
                                                          campaign
                                                        )
                                                      ) +
                                                      "\n                                            "
                                                  ),
                                                ]
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getStatusLabel(campaign)
                                                  )
                                                ),
                                              ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            campaign.sent_at != null
                                              ? campaign.sent_at
                                              : "-"
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        campaign.is_sending
                                          ? _c("span", [
                                              _vm._v(
                                                "En cours d'envoi (" +
                                                  _vm._s(
                                                    campaign.nb_sent || 0
                                                  ) +
                                                  ")"
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  campaign.sent_at != null
                                                    ? campaign.nb_sent
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        campaign.type == "sms"
                                          ? _c("span", [_vm._v("Indisponible")])
                                          : campaign.sent_at != null
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(campaign.nb_opened)
                                              ),
                                            ])
                                          : _c("span", [_vm._v("-")]),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        campaign.type == "sms"
                                          ? _c("span", [_vm._v("Indisponible")])
                                          : campaign.sent_at != null
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(campaign.nb_clicked)
                                              ),
                                            ])
                                          : _c("span", [_vm._v("-")]),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "none-mobile" }, [
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: {
                                                  content: "Voir les détails",
                                                },
                                                expression:
                                                  "{ content: 'Voir les détails' }",
                                              },
                                            ],
                                            staticClass:
                                              "btn btn-sm btn-outline-secondary btn-square none-tablet",
                                            attrs: {
                                              type: "button",
                                              title: "Voir la campagne",
                                              "data-toggle": "modal",
                                              "data-target": "#view-resa",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showCampaign(
                                                  campaign.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("feather", {
                                              attrs: { type: "eye" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        campaign.sent_at === null
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: {
                                                      content: "Éditer",
                                                    },
                                                    expression:
                                                      "{ content: 'Éditer' }",
                                                  },
                                                ],
                                                staticClass:
                                                  "btn btn-sm btn-success btn-square",
                                                attrs: {
                                                  title: "Modifier la campagne",
                                                  disabled:
                                                    !_vm.has_right_to_update_campaigns,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editCampaign(
                                                      campaign
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("feather", {
                                                  attrs: { type: "edit" },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _c(
                      "div",
                      { staticClass: "col-lg-6 offset-lg-3 pt-5 text-center" },
                      [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4 mb-3" },
                          [
                            _c("h4", { staticClass: "mb-4" }, [
                              _vm._v("Vous n'avez pas encore créé de campagne"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success btn-circle",
                                attrs: {
                                  disabled: !_vm.has_right_to_create_campaigns,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addCampaign()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Créer ma première campagne\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "mt-5 none-mobile",
                              attrs: {
                                width: "450",
                                src: "/images/no-campaign.png",
                                alt: "No Show",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "mt-3 none-desk",
                              attrs: {
                                width: "300",
                                src: "/images/no-campaign.png",
                                alt: "No Show",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
              ]),
              _vm._v(" "),
              _vm.showCampaignAddModal
                ? _c("add-campaign-modal", {
                    attrs: {
                      "restaurant-id": _vm.restaurantId,
                      campaign: _vm.selectedCampaign,
                      clients: _vm.clients,
                      is_paying_emails_campaign: _vm.is_paying_emails_campaign,
                    },
                    on: {
                      setIsPayingEmailsCampaign: function ($event) {
                        _vm.is_paying_emails_campaign = $event
                      },
                      close: _vm.campaignsUpdated,
                      updated: _vm.campaignsUpdated,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showCampaignShowModal
                ? _c("show-campaign-modal", {
                    attrs: {
                      campaign_id: _vm.showCampaignId,
                      clients: _vm.clients,
                    },
                    on: {
                      close: function ($event) {
                        _vm.showCampaignShowModal = false
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showPurchaseEmailsCampaignPackModal
                ? _c("PurchaseEmailsCampaignPackModal", {
                    on: {
                      close: function ($event) {
                        _vm.showPurchaseEmailsCampaignPackModal = false
                      },
                      paid: _vm.onEmailsPackPaid,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }