<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Echec de la connexion à Stripe</h5>
        <div slot="body" class="border-light b-radius-20 p-4">
            Pour lier votre compte stripe à NoShow, il est nécessaire d'avoir un compte stripe vérifié et d'activer les
            paiments par carte ainsi que les transferts vers votre compte bancaire.<br />
            <br />
            Actuellement, les fonctionnalités suivantes sont désactivées sur votre compte stripe :<br />
            <br />
            <ul>
                <li
                    class="text-danger"
                    v-if="!(params && params['charges_enabled'] && params['charges_enabled'] === true)">
                    - Paiements par carte
                </li>
                <li
                    class="text-danger"
                    v-if="!(params && params['payouts_enabled'] && params['payouts_enabled'] === true)">
                    - Transferts vers votre compte bancaire.
                </li>
            </ul>

            Pour valider votre compte et activer ces fonctionnalités, rendez-vous sur votre tableau de bord Stripe.
            <br />
            <a class="btn btn-sm btn-circle btn-danger mt-2" href="https://dashboard.stripe.com/" target="_blank"
                >Dashboard Stripe</a
            >
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn btn-secondary" @click="$emit('close')">Ok</button>
        </div>
    </modal>
</template>

<script>
export default {
    props: {
        params: {
            required: true,
        },
    },
    methods: {
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        close() {
            this.$emit("close");
        },
    },
};
</script>
