<template>
    <div>
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">{{ $tl("labels.form.licences.title") }}</h5>
                </div>
            </div>
        </div>
        <module-products-list
            :preselected-modules="preselectedModules"
            :modules-enabled="modulesEnabled"
            :plan="plan"
            :onlyOneModule="onlyOneModule"
            :training="training"
            :disable-payment="disablePayment"
            :disable-training="disableTraining"
            :disable-plan="disablePlan"
            :max="max"
            @set-training="training = $event"
            @input-plan="plan = $event"
            v-model="modules" />
        <div class="row m-0 mt-2 mr-3 ml-3">
            <button v-if="showBackButton" class="btn btn-secondary btn-circle mr-2" type="button" @click="$emit('back')">
                {{ $tl("labels.form.actions.back") }}
            </button>
            <button
                @click="$emit('selected', { selectedModules, plan, training })"
                class="btn btn-success btn-circle"
                :disabled="!selectedModules.length > 0"
                :class="{ disabled: !selectedModules.length > 0 }">
                {{ nextLabel || $tl("labels.form.actions.next") }}
            </button>
        </div>
    </div>
</template>

<script>
import ModuleProductsList from "../ModuleProductsList.vue";

export default {
    name: "SelectLicence",
    data() {
        return {
            modules: [],
            training: false,
            plan: "yearly",
        };
    },
    props: {
        preselectedModules: {
            default: () => [],
        },
        modulesEnabled: {
            default: null,
        },
        onlyOneModule: {
            default: false,
        },
        showBackButton: {
            type: Boolean,
            default: false,
        },
        max: {
            default: null,
        },
        nextLabel: {
            type: String,
        },
        disablePayment: {
            type: Boolean,
            default: false,
        },
        disableTraining: {
            type: Boolean,
            default: false,
        },
        disablePlan: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        selectedModules() {
            return this.modules.filter((module) => {
                return module && module.count && module.count > 0;
            });
        },
    },
    components: {
        ModuleProductsList,
    },
};
</script>
