var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5 pt-5" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.loading ? _c("LoaderComponent") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          staticClass: "row m-0",
        },
        [
          _vm.error
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7 pt-1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-inline-block align-middle lead-switch",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.pickunEnable,
                                    expression: "pickunEnable",
                                  },
                                ],
                                staticClass:
                                  "switch align-self-center is-rounded",
                                attrs: {
                                  disabled: !_vm.has_right_to_update_config,
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.pickunEnable)
                                    ? _vm._i(_vm.pickunEnable, null) > -1
                                    : _vm.pickunEnable,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.pickunEnable,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.pickunEnable = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.pickunEnable = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.pickunEnable = $$c
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", {
                                on: {
                                  click: function ($event) {
                                    _vm.pickunEnable =
                                      _vm.has_right_to_update_config
                                        ? !_vm.pickunEnable
                                        : _vm.pickunEnable
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.pickunEnable
                        ? [
                            _c("div", { staticClass: "row mt-3 mb-2" }, [
                              _c("div", { staticClass: "col-12 pt-2" }, [
                                _c("div", { staticClass: "mb-2" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "container-box",
                                      staticStyle: {
                                        "margin-bottom": "0",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                        },
                                        domProps: {
                                          checked: _vm.services.lundi.isOpen,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.setDayIsOpen(
                                              _vm.services.lundi,
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        class: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                        },
                                      }),
                                      _vm._v(
                                        "\n                                            Lundi\n                                        "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.services.lundi.isOpen == true
                                  ? _c("div", { staticClass: "setting-open" }, [
                                      _c(
                                        "div",
                                        { staticClass: "setting-hours" },
                                        [
                                          _c(
                                            "span",
                                            [
                                              _vm._l(
                                                _vm.services.lundi.services,
                                                function (service, index) {
                                                  return [
                                                    !service.deleted
                                                      ? _c(
                                                          "div",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.has_right_to_update_config,
                                                                  format:
                                                                    "HH:mm",
                                                                  "minute-interval": 15,
                                                                  value:
                                                                    service.start_hour,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.calculSlot(
                                                                        "lundi",
                                                                        index,
                                                                        $event
                                                                      )
                                                                    },
                                                                  close:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.timePickerClosed(
                                                                        "lundi"
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.has_right_to_update_config,
                                                                  format:
                                                                    "HH:mm",
                                                                  "minute-interval": 15,
                                                                  value:
                                                                    service.end_hour,
                                                                  "hour-range":
                                                                    _vm.calculHourRange(
                                                                      service.start_hour
                                                                    ),
                                                                  "hide-disabled-hours":
                                                                    "",
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.calculSlot(
                                                                        "lundi",
                                                                        index,
                                                                        null,
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              service.slots,
                                                              function (
                                                                slot,
                                                                index
                                                              ) {
                                                                return [
                                                                  slot.displayed
                                                                    ? _c(
                                                                        "label",
                                                                        {
                                                                          key: index,
                                                                          staticClass:
                                                                            "container-box ml-3",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "initial",
                                                                              "margin-right":
                                                                                "0",
                                                                              "padding-left":
                                                                                "28px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      slot.enabled,
                                                                                    expression:
                                                                                      "slot.enabled",
                                                                                  },
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  type: "checkbox",
                                                                                  disabled:
                                                                                    !_vm.has_right_to_update_config,
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  checked:
                                                                                    Array.isArray(
                                                                                      slot.enabled
                                                                                    )
                                                                                      ? _vm._i(
                                                                                          slot.enabled,
                                                                                          null
                                                                                        ) >
                                                                                        -1
                                                                                      : slot.enabled,
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    var $$a =
                                                                                        slot.enabled,
                                                                                      $$el =
                                                                                        $event.target,
                                                                                      $$c =
                                                                                        $$el.checked
                                                                                          ? true
                                                                                          : false
                                                                                    if (
                                                                                      Array.isArray(
                                                                                        $$a
                                                                                      )
                                                                                    ) {
                                                                                      var $$v =
                                                                                          null,
                                                                                        $$i =
                                                                                          _vm._i(
                                                                                            $$a,
                                                                                            $$v
                                                                                          )
                                                                                      if (
                                                                                        $$el.checked
                                                                                      ) {
                                                                                        $$i <
                                                                                          0 &&
                                                                                          _vm.$set(
                                                                                            slot,
                                                                                            "enabled",
                                                                                            $$a.concat(
                                                                                              [
                                                                                                $$v,
                                                                                              ]
                                                                                            )
                                                                                          )
                                                                                      } else {
                                                                                        $$i >
                                                                                          -1 &&
                                                                                          _vm.$set(
                                                                                            slot,
                                                                                            "enabled",
                                                                                            $$a
                                                                                              .slice(
                                                                                                0,
                                                                                                $$i
                                                                                              )
                                                                                              .concat(
                                                                                                $$a.slice(
                                                                                                  $$i +
                                                                                                    1
                                                                                                )
                                                                                              )
                                                                                          )
                                                                                      }
                                                                                    } else {
                                                                                      _vm.$set(
                                                                                        slot,
                                                                                        "enabled",
                                                                                        $$c
                                                                                      )
                                                                                    }
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "checkmark",
                                                                              class:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.has_right_to_update_config,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                                " +
                                                                              _vm._s(
                                                                                _vm.formatHour(
                                                                                  slot.hour
                                                                                )
                                                                              ) +
                                                                              "\n                                                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "feather css-i6dzq1 pt-1",
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                                attrs: {
                                                                  viewBox:
                                                                    "0 0 24 24",
                                                                  width: "24",
                                                                  height: "24",
                                                                  stroke:
                                                                    "currentColor",
                                                                  "stroke-width":
                                                                    "2",
                                                                  fill: "none",
                                                                  "stroke-linecap":
                                                                    "round",
                                                                  "stroke-linejoin":
                                                                    "round",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeOpening(
                                                                        "lundi",
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "18",
                                                                    y1: "6",
                                                                    x2: "6",
                                                                    y2: "18",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "6",
                                                                    y1: "6",
                                                                    x2: "18",
                                                                    y2: "18",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "feather feather-plus sc-dnqmqq jxshSx",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "24",
                                                    height: "24",
                                                    viewBox: "0 0 24 24",
                                                    fill: "none",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "aria-hidden": "true",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addOpening(
                                                        "lundi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "12",
                                                      y1: "5",
                                                      x2: "12",
                                                      y2: "19",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "5",
                                                      y1: "12",
                                                      x2: "19",
                                                      y2: "12",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "mb-2" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "container-box",
                                      staticStyle: {
                                        "margin-bottom": "0",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                        },
                                        domProps: {
                                          checked: _vm.services.mardi.isOpen,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.setDayIsOpen(
                                              _vm.services.mardi,
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        class: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                        },
                                      }),
                                      _vm._v(
                                        "\n                                            Mardi\n                                        "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.services.mardi.isOpen == true
                                  ? _c("div", { staticClass: "setting-open" }, [
                                      _c(
                                        "div",
                                        { staticClass: "setting-hours" },
                                        [
                                          _c(
                                            "span",
                                            [
                                              _vm._l(
                                                _vm.services.mardi.services,
                                                function (service, index) {
                                                  return [
                                                    !service.deleted
                                                      ? _c(
                                                          "div",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.has_right_to_update_config,
                                                                  format:
                                                                    "HH:mm",
                                                                  "minute-interval": 15,
                                                                  value:
                                                                    service.start_hour,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.calculSlot(
                                                                        "mardi",
                                                                        index,
                                                                        $event
                                                                      )
                                                                    },
                                                                  close:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.timePickerClosed(
                                                                        "mardi"
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.has_right_to_update_config,
                                                                  format:
                                                                    "HH:mm",
                                                                  "minute-interval": 15,
                                                                  "hour-range":
                                                                    _vm.calculHourRange(
                                                                      service.start_hour
                                                                    ),
                                                                  "hide-disabled-hours":
                                                                    "",
                                                                  value:
                                                                    service.end_hour,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.calculSlot(
                                                                        "mardi",
                                                                        index,
                                                                        null,
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              service.slots,
                                                              function (
                                                                slot,
                                                                index
                                                              ) {
                                                                return [
                                                                  slot.displayed
                                                                    ? _c(
                                                                        "label",
                                                                        {
                                                                          key: index,
                                                                          staticClass:
                                                                            "container-box ml-3",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "initial",
                                                                              "margin-right":
                                                                                "0",
                                                                              "padding-left":
                                                                                "28px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      slot.enabled,
                                                                                    expression:
                                                                                      "slot.enabled",
                                                                                  },
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.has_right_to_update_config,
                                                                                  type: "checkbox",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  checked:
                                                                                    Array.isArray(
                                                                                      slot.enabled
                                                                                    )
                                                                                      ? _vm._i(
                                                                                          slot.enabled,
                                                                                          null
                                                                                        ) >
                                                                                        -1
                                                                                      : slot.enabled,
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    var $$a =
                                                                                        slot.enabled,
                                                                                      $$el =
                                                                                        $event.target,
                                                                                      $$c =
                                                                                        $$el.checked
                                                                                          ? true
                                                                                          : false
                                                                                    if (
                                                                                      Array.isArray(
                                                                                        $$a
                                                                                      )
                                                                                    ) {
                                                                                      var $$v =
                                                                                          null,
                                                                                        $$i =
                                                                                          _vm._i(
                                                                                            $$a,
                                                                                            $$v
                                                                                          )
                                                                                      if (
                                                                                        $$el.checked
                                                                                      ) {
                                                                                        $$i <
                                                                                          0 &&
                                                                                          _vm.$set(
                                                                                            slot,
                                                                                            "enabled",
                                                                                            $$a.concat(
                                                                                              [
                                                                                                $$v,
                                                                                              ]
                                                                                            )
                                                                                          )
                                                                                      } else {
                                                                                        $$i >
                                                                                          -1 &&
                                                                                          _vm.$set(
                                                                                            slot,
                                                                                            "enabled",
                                                                                            $$a
                                                                                              .slice(
                                                                                                0,
                                                                                                $$i
                                                                                              )
                                                                                              .concat(
                                                                                                $$a.slice(
                                                                                                  $$i +
                                                                                                    1
                                                                                                )
                                                                                              )
                                                                                          )
                                                                                      }
                                                                                    } else {
                                                                                      _vm.$set(
                                                                                        slot,
                                                                                        "enabled",
                                                                                        $$c
                                                                                      )
                                                                                    }
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "checkmark",
                                                                              class:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.has_right_to_update_config,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                                " +
                                                                              _vm._s(
                                                                                _vm.formatHour(
                                                                                  slot.hour
                                                                                )
                                                                              ) +
                                                                              "\n                                                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "feather css-i6dzq1 pt-1",
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                                attrs: {
                                                                  viewBox:
                                                                    "0 0 24 24",
                                                                  width: "24",
                                                                  height: "24",
                                                                  stroke:
                                                                    "currentColor",
                                                                  "stroke-width":
                                                                    "2",
                                                                  fill: "none",
                                                                  "stroke-linecap":
                                                                    "round",
                                                                  "stroke-linejoin":
                                                                    "round",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeOpening(
                                                                        "mardi",
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "18",
                                                                    y1: "6",
                                                                    x2: "6",
                                                                    y2: "18",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "6",
                                                                    y1: "6",
                                                                    x2: "18",
                                                                    y2: "18",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "feather feather-plus sc-dnqmqq jxshSx",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "24",
                                                    height: "24",
                                                    viewBox: "0 0 24 24",
                                                    fill: "none",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "aria-hidden": "true",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addOpening(
                                                        "mardi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "12",
                                                      y1: "5",
                                                      x2: "12",
                                                      y2: "19",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "5",
                                                      y1: "12",
                                                      x2: "19",
                                                      y2: "12",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "mb-2" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "container-box",
                                      staticStyle: {
                                        "margin-bottom": "0",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: _vm.services.mercredi.isOpen,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.setDayIsOpen(
                                              _vm.services.mercredi,
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        class: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                        },
                                      }),
                                      _vm._v(
                                        "\n                                            Mercredi\n                                        "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.services.mercredi.isOpen == true
                                  ? _c("div", { staticClass: "setting-open" }, [
                                      _c(
                                        "div",
                                        { staticClass: "setting-hours" },
                                        [
                                          _c(
                                            "span",
                                            [
                                              _vm._l(
                                                _vm.services.mercredi.services,
                                                function (service, index) {
                                                  return [
                                                    !service.deleted
                                                      ? _c(
                                                          "div",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.has_right_to_update_config,
                                                                  format:
                                                                    "HH:mm",
                                                                  "minute-interval": 15,
                                                                  value:
                                                                    service.start_hour,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.calculSlot(
                                                                        "mercredi",
                                                                        index,
                                                                        $event
                                                                      )
                                                                    },
                                                                  close:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.timePickerClosed(
                                                                        "mercredi"
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.has_right_to_update_config,
                                                                  format:
                                                                    "HH:mm",
                                                                  "minute-interval": 15,
                                                                  "hour-range":
                                                                    _vm.calculHourRange(
                                                                      service.start_hour
                                                                    ),
                                                                  "hide-disabled-hours":
                                                                    "",
                                                                  value:
                                                                    service.end_hour,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.calculSlot(
                                                                        "mercredi",
                                                                        index,
                                                                        null,
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              service.slots,
                                                              function (
                                                                slot,
                                                                index
                                                              ) {
                                                                return [
                                                                  slot.displayed
                                                                    ? _c(
                                                                        "label",
                                                                        {
                                                                          key: index,
                                                                          staticClass:
                                                                            "container-box ml-3",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "initial",
                                                                              "margin-right":
                                                                                "0",
                                                                              "padding-left":
                                                                                "28px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      slot.enabled,
                                                                                    expression:
                                                                                      "slot.enabled",
                                                                                  },
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.has_right_to_update_config,
                                                                                  type: "checkbox",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  checked:
                                                                                    Array.isArray(
                                                                                      slot.enabled
                                                                                    )
                                                                                      ? _vm._i(
                                                                                          slot.enabled,
                                                                                          null
                                                                                        ) >
                                                                                        -1
                                                                                      : slot.enabled,
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    var $$a =
                                                                                        slot.enabled,
                                                                                      $$el =
                                                                                        $event.target,
                                                                                      $$c =
                                                                                        $$el.checked
                                                                                          ? true
                                                                                          : false
                                                                                    if (
                                                                                      Array.isArray(
                                                                                        $$a
                                                                                      )
                                                                                    ) {
                                                                                      var $$v =
                                                                                          null,
                                                                                        $$i =
                                                                                          _vm._i(
                                                                                            $$a,
                                                                                            $$v
                                                                                          )
                                                                                      if (
                                                                                        $$el.checked
                                                                                      ) {
                                                                                        $$i <
                                                                                          0 &&
                                                                                          _vm.$set(
                                                                                            slot,
                                                                                            "enabled",
                                                                                            $$a.concat(
                                                                                              [
                                                                                                $$v,
                                                                                              ]
                                                                                            )
                                                                                          )
                                                                                      } else {
                                                                                        $$i >
                                                                                          -1 &&
                                                                                          _vm.$set(
                                                                                            slot,
                                                                                            "enabled",
                                                                                            $$a
                                                                                              .slice(
                                                                                                0,
                                                                                                $$i
                                                                                              )
                                                                                              .concat(
                                                                                                $$a.slice(
                                                                                                  $$i +
                                                                                                    1
                                                                                                )
                                                                                              )
                                                                                          )
                                                                                      }
                                                                                    } else {
                                                                                      _vm.$set(
                                                                                        slot,
                                                                                        "enabled",
                                                                                        $$c
                                                                                      )
                                                                                    }
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "checkmark",
                                                                              class:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.has_right_to_update_config,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                                " +
                                                                              _vm._s(
                                                                                _vm.formatHour(
                                                                                  slot.hour
                                                                                )
                                                                              ) +
                                                                              "\n                                                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "feather css-i6dzq1 pt-1",
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                                attrs: {
                                                                  viewBox:
                                                                    "0 0 24 24",
                                                                  width: "24",
                                                                  height: "24",
                                                                  stroke:
                                                                    "currentColor",
                                                                  "stroke-width":
                                                                    "2",
                                                                  fill: "none",
                                                                  "stroke-linecap":
                                                                    "round",
                                                                  "stroke-linejoin":
                                                                    "round",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeOpening(
                                                                        "mercredi",
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "18",
                                                                    y1: "6",
                                                                    x2: "6",
                                                                    y2: "18",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "6",
                                                                    y1: "6",
                                                                    x2: "18",
                                                                    y2: "18",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "feather feather-plus sc-dnqmqq jxshSx",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "24",
                                                    height: "24",
                                                    viewBox: "0 0 24 24",
                                                    fill: "none",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "aria-hidden": "true",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addOpening(
                                                        "mercredi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "12",
                                                      y1: "5",
                                                      x2: "12",
                                                      y2: "19",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "5",
                                                      y1: "12",
                                                      x2: "19",
                                                      y2: "12",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "mb-2" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "container-box",
                                      staticStyle: {
                                        "margin-bottom": "0",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                        },
                                        domProps: {
                                          checked: _vm.services.jeudi.isOpen,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.setDayIsOpen(
                                              _vm.services.jeudi,
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        class: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                        },
                                      }),
                                      _vm._v(
                                        "\n                                            Jeudi\n                                        "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.services.jeudi.isOpen == true
                                  ? _c("div", { staticClass: "setting-open" }, [
                                      _c(
                                        "div",
                                        { staticClass: "setting-hours" },
                                        [
                                          _c(
                                            "span",
                                            [
                                              _vm._l(
                                                _vm.services.jeudi.services,
                                                function (service, index) {
                                                  return [
                                                    !service.deleted
                                                      ? _c(
                                                          "div",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.has_right_to_update_config,
                                                                  format:
                                                                    "HH:mm",
                                                                  "minute-interval": 15,
                                                                  value:
                                                                    service.start_hour,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.calculSlot(
                                                                        "jeudi",
                                                                        index,
                                                                        $event
                                                                      )
                                                                    },
                                                                  close:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.timePickerClosed(
                                                                        "jeudi"
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.has_right_to_update_config,
                                                                  format:
                                                                    "HH:mm",
                                                                  "minute-interval": 15,
                                                                  "hour-range":
                                                                    _vm.calculHourRange(
                                                                      service.start_hour
                                                                    ),
                                                                  "hide-disabled-hours":
                                                                    "",
                                                                  value:
                                                                    service.end_hour,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.calculSlot(
                                                                        "jeudi",
                                                                        index,
                                                                        null,
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              service.slots,
                                                              function (
                                                                slot,
                                                                index
                                                              ) {
                                                                return [
                                                                  slot.displayed
                                                                    ? _c(
                                                                        "label",
                                                                        {
                                                                          key: index,
                                                                          staticClass:
                                                                            "container-box ml-3",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "initial",
                                                                              "margin-right":
                                                                                "0",
                                                                              "padding-left":
                                                                                "28px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      slot.enabled,
                                                                                    expression:
                                                                                      "slot.enabled",
                                                                                  },
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.has_right_to_update_config,
                                                                                  type: "checkbox",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  checked:
                                                                                    Array.isArray(
                                                                                      slot.enabled
                                                                                    )
                                                                                      ? _vm._i(
                                                                                          slot.enabled,
                                                                                          null
                                                                                        ) >
                                                                                        -1
                                                                                      : slot.enabled,
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    var $$a =
                                                                                        slot.enabled,
                                                                                      $$el =
                                                                                        $event.target,
                                                                                      $$c =
                                                                                        $$el.checked
                                                                                          ? true
                                                                                          : false
                                                                                    if (
                                                                                      Array.isArray(
                                                                                        $$a
                                                                                      )
                                                                                    ) {
                                                                                      var $$v =
                                                                                          null,
                                                                                        $$i =
                                                                                          _vm._i(
                                                                                            $$a,
                                                                                            $$v
                                                                                          )
                                                                                      if (
                                                                                        $$el.checked
                                                                                      ) {
                                                                                        $$i <
                                                                                          0 &&
                                                                                          _vm.$set(
                                                                                            slot,
                                                                                            "enabled",
                                                                                            $$a.concat(
                                                                                              [
                                                                                                $$v,
                                                                                              ]
                                                                                            )
                                                                                          )
                                                                                      } else {
                                                                                        $$i >
                                                                                          -1 &&
                                                                                          _vm.$set(
                                                                                            slot,
                                                                                            "enabled",
                                                                                            $$a
                                                                                              .slice(
                                                                                                0,
                                                                                                $$i
                                                                                              )
                                                                                              .concat(
                                                                                                $$a.slice(
                                                                                                  $$i +
                                                                                                    1
                                                                                                )
                                                                                              )
                                                                                          )
                                                                                      }
                                                                                    } else {
                                                                                      _vm.$set(
                                                                                        slot,
                                                                                        "enabled",
                                                                                        $$c
                                                                                      )
                                                                                    }
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "checkmark",
                                                                              class:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.has_right_to_update_config,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                                " +
                                                                              _vm._s(
                                                                                _vm.formatHour(
                                                                                  slot.hour
                                                                                )
                                                                              ) +
                                                                              "\n                                                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "feather css-i6dzq1 pt-1",
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                                attrs: {
                                                                  viewBox:
                                                                    "0 0 24 24",
                                                                  width: "24",
                                                                  height: "24",
                                                                  stroke:
                                                                    "currentColor",
                                                                  "stroke-width":
                                                                    "2",
                                                                  fill: "none",
                                                                  "stroke-linecap":
                                                                    "round",
                                                                  "stroke-linejoin":
                                                                    "round",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeOpening(
                                                                        "jeudi",
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "18",
                                                                    y1: "6",
                                                                    x2: "6",
                                                                    y2: "18",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "6",
                                                                    y1: "6",
                                                                    x2: "18",
                                                                    y2: "18",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "feather feather-plus sc-dnqmqq jxshSx",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "24",
                                                    height: "24",
                                                    viewBox: "0 0 24 24",
                                                    fill: "none",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "aria-hidden": "true",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addOpening(
                                                        "jeudi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "12",
                                                      y1: "5",
                                                      x2: "12",
                                                      y2: "19",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "5",
                                                      y1: "12",
                                                      x2: "19",
                                                      y2: "12",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "mb-2" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "container-box",
                                      staticStyle: {
                                        "margin-bottom": "0",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                        },
                                        domProps: {
                                          checked: _vm.services.vendredi.isOpen,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.setDayIsOpen(
                                              _vm.services.vendredi,
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        class: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                        },
                                      }),
                                      _vm._v(
                                        "\n                                            Vendredi\n                                        "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.services.vendredi.isOpen == true
                                  ? _c("div", { staticClass: "setting-open" }, [
                                      _c(
                                        "div",
                                        { staticClass: "setting-hours" },
                                        [
                                          _c(
                                            "span",
                                            [
                                              _vm._l(
                                                _vm.services.vendredi.services,
                                                function (service, index) {
                                                  return [
                                                    !service.deleted
                                                      ? _c(
                                                          "div",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.has_right_to_update_config,
                                                                  format:
                                                                    "HH:mm",
                                                                  "minute-interval": 15,
                                                                  value:
                                                                    service.start_hour,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.calculSlot(
                                                                        "vendredi",
                                                                        index,
                                                                        $event
                                                                      )
                                                                    },
                                                                  close:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.timePickerClosed(
                                                                        "vendredi"
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.has_right_to_update_config,
                                                                  format:
                                                                    "HH:mm",
                                                                  "minute-interval": 15,
                                                                  "hour-range":
                                                                    _vm.calculHourRange(
                                                                      service.start_hour
                                                                    ),
                                                                  "hide-disabled-hours":
                                                                    "",
                                                                  value:
                                                                    service.end_hour,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.calculSlot(
                                                                        "vendredi",
                                                                        index,
                                                                        null,
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              service.slots,
                                                              function (
                                                                slot,
                                                                index
                                                              ) {
                                                                return [
                                                                  slot.displayed
                                                                    ? _c(
                                                                        "label",
                                                                        {
                                                                          key: index,
                                                                          staticClass:
                                                                            "container-box ml-3",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "initial",
                                                                              "margin-right":
                                                                                "0",
                                                                              "padding-left":
                                                                                "28px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      slot.enabled,
                                                                                    expression:
                                                                                      "slot.enabled",
                                                                                  },
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.has_right_to_update_config,
                                                                                  type: "checkbox",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  checked:
                                                                                    Array.isArray(
                                                                                      slot.enabled
                                                                                    )
                                                                                      ? _vm._i(
                                                                                          slot.enabled,
                                                                                          null
                                                                                        ) >
                                                                                        -1
                                                                                      : slot.enabled,
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    var $$a =
                                                                                        slot.enabled,
                                                                                      $$el =
                                                                                        $event.target,
                                                                                      $$c =
                                                                                        $$el.checked
                                                                                          ? true
                                                                                          : false
                                                                                    if (
                                                                                      Array.isArray(
                                                                                        $$a
                                                                                      )
                                                                                    ) {
                                                                                      var $$v =
                                                                                          null,
                                                                                        $$i =
                                                                                          _vm._i(
                                                                                            $$a,
                                                                                            $$v
                                                                                          )
                                                                                      if (
                                                                                        $$el.checked
                                                                                      ) {
                                                                                        $$i <
                                                                                          0 &&
                                                                                          _vm.$set(
                                                                                            slot,
                                                                                            "enabled",
                                                                                            $$a.concat(
                                                                                              [
                                                                                                $$v,
                                                                                              ]
                                                                                            )
                                                                                          )
                                                                                      } else {
                                                                                        $$i >
                                                                                          -1 &&
                                                                                          _vm.$set(
                                                                                            slot,
                                                                                            "enabled",
                                                                                            $$a
                                                                                              .slice(
                                                                                                0,
                                                                                                $$i
                                                                                              )
                                                                                              .concat(
                                                                                                $$a.slice(
                                                                                                  $$i +
                                                                                                    1
                                                                                                )
                                                                                              )
                                                                                          )
                                                                                      }
                                                                                    } else {
                                                                                      _vm.$set(
                                                                                        slot,
                                                                                        "enabled",
                                                                                        $$c
                                                                                      )
                                                                                    }
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "checkmark",
                                                                              class:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.has_right_to_update_config,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                                " +
                                                                              _vm._s(
                                                                                _vm.formatHour(
                                                                                  slot.hour
                                                                                )
                                                                              ) +
                                                                              "\n                                                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "feather css-i6dzq1 pt-1",
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                                attrs: {
                                                                  viewBox:
                                                                    "0 0 24 24",
                                                                  width: "24",
                                                                  height: "24",
                                                                  stroke:
                                                                    "currentColor",
                                                                  "stroke-width":
                                                                    "2",
                                                                  fill: "none",
                                                                  "stroke-linecap":
                                                                    "round",
                                                                  "stroke-linejoin":
                                                                    "round",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeOpening(
                                                                        "vendredi",
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "18",
                                                                    y1: "6",
                                                                    x2: "6",
                                                                    y2: "18",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "6",
                                                                    y1: "6",
                                                                    x2: "18",
                                                                    y2: "18",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "feather feather-plus sc-dnqmqq jxshSx",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "24",
                                                    height: "24",
                                                    viewBox: "0 0 24 24",
                                                    fill: "none",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "aria-hidden": "true",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addOpening(
                                                        "vendredi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "12",
                                                      y1: "5",
                                                      x2: "12",
                                                      y2: "19",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "5",
                                                      y1: "12",
                                                      x2: "19",
                                                      y2: "12",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "mb-2" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "container-box",
                                      staticStyle: {
                                        "margin-bottom": "0",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                        },
                                        domProps: {
                                          checked: _vm.services.samedi.isOpen,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.setDayIsOpen(
                                              _vm.services.samedi,
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        class: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                        },
                                      }),
                                      _vm._v(
                                        "\n                                            Samedi\n                                        "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.services.samedi.isOpen == true
                                  ? _c("div", { staticClass: "setting-open" }, [
                                      _c(
                                        "div",
                                        { staticClass: "setting-hours" },
                                        [
                                          _c(
                                            "span",
                                            [
                                              _vm._l(
                                                _vm.services.samedi.services,
                                                function (service, index) {
                                                  return [
                                                    !service.deleted
                                                      ? _c(
                                                          "div",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.has_right_to_update_config,
                                                                  format:
                                                                    "HH:mm",
                                                                  "minute-interval": 15,
                                                                  value:
                                                                    service.start_hour,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.calculSlot(
                                                                        "samedi",
                                                                        index,
                                                                        $event
                                                                      )
                                                                    },
                                                                  close:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.timePickerClosed(
                                                                        "samedi"
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.has_right_to_update_config,
                                                                  format:
                                                                    "HH:mm",
                                                                  "hour-range":
                                                                    _vm.calculHourRange(
                                                                      service.start_hour
                                                                    ),
                                                                  "hide-disabled-hours":
                                                                    "",
                                                                  "minute-interval": 15,
                                                                  value:
                                                                    service.end_hour,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.calculSlot(
                                                                        "samedi",
                                                                        index,
                                                                        null,
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              service.slots,
                                                              function (
                                                                slot,
                                                                index
                                                              ) {
                                                                return [
                                                                  slot.displayed
                                                                    ? _c(
                                                                        "label",
                                                                        {
                                                                          key: index,
                                                                          staticClass:
                                                                            "container-box ml-3",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "initial",
                                                                              "margin-right":
                                                                                "0",
                                                                              "padding-left":
                                                                                "28px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      slot.enabled,
                                                                                    expression:
                                                                                      "slot.enabled",
                                                                                  },
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.has_right_to_update_config,
                                                                                  type: "checkbox",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  checked:
                                                                                    Array.isArray(
                                                                                      slot.enabled
                                                                                    )
                                                                                      ? _vm._i(
                                                                                          slot.enabled,
                                                                                          null
                                                                                        ) >
                                                                                        -1
                                                                                      : slot.enabled,
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    var $$a =
                                                                                        slot.enabled,
                                                                                      $$el =
                                                                                        $event.target,
                                                                                      $$c =
                                                                                        $$el.checked
                                                                                          ? true
                                                                                          : false
                                                                                    if (
                                                                                      Array.isArray(
                                                                                        $$a
                                                                                      )
                                                                                    ) {
                                                                                      var $$v =
                                                                                          null,
                                                                                        $$i =
                                                                                          _vm._i(
                                                                                            $$a,
                                                                                            $$v
                                                                                          )
                                                                                      if (
                                                                                        $$el.checked
                                                                                      ) {
                                                                                        $$i <
                                                                                          0 &&
                                                                                          _vm.$set(
                                                                                            slot,
                                                                                            "enabled",
                                                                                            $$a.concat(
                                                                                              [
                                                                                                $$v,
                                                                                              ]
                                                                                            )
                                                                                          )
                                                                                      } else {
                                                                                        $$i >
                                                                                          -1 &&
                                                                                          _vm.$set(
                                                                                            slot,
                                                                                            "enabled",
                                                                                            $$a
                                                                                              .slice(
                                                                                                0,
                                                                                                $$i
                                                                                              )
                                                                                              .concat(
                                                                                                $$a.slice(
                                                                                                  $$i +
                                                                                                    1
                                                                                                )
                                                                                              )
                                                                                          )
                                                                                      }
                                                                                    } else {
                                                                                      _vm.$set(
                                                                                        slot,
                                                                                        "enabled",
                                                                                        $$c
                                                                                      )
                                                                                    }
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "checkmark",
                                                                              class:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.has_right_to_update_config,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                                " +
                                                                              _vm._s(
                                                                                _vm.formatHour(
                                                                                  slot.hour
                                                                                )
                                                                              ) +
                                                                              "\n                                                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "feather css-i6dzq1 pt-1",
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                                attrs: {
                                                                  viewBox:
                                                                    "0 0 24 24",
                                                                  width: "24",
                                                                  height: "24",
                                                                  stroke:
                                                                    "currentColor",
                                                                  "stroke-width":
                                                                    "2",
                                                                  fill: "none",
                                                                  "stroke-linecap":
                                                                    "round",
                                                                  "stroke-linejoin":
                                                                    "round",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeOpening(
                                                                        "samedi",
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "18",
                                                                    y1: "6",
                                                                    x2: "6",
                                                                    y2: "18",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "6",
                                                                    y1: "6",
                                                                    x2: "18",
                                                                    y2: "18",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "feather feather-plus sc-dnqmqq jxshSx",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "24",
                                                    height: "24",
                                                    viewBox: "0 0 24 24",
                                                    fill: "none",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "aria-hidden": "true",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addOpening(
                                                        "samedi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "12",
                                                      y1: "5",
                                                      x2: "12",
                                                      y2: "19",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "5",
                                                      y1: "12",
                                                      x2: "19",
                                                      y2: "12",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "mb-2" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "container-box",
                                      staticStyle: {
                                        "margin-bottom": "0",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                        },
                                        domProps: {
                                          checked: _vm.services.dimanche.isOpen,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.setDayIsOpen(
                                              _vm.services.dimanche,
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "checkmark",
                                        class: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                        },
                                      }),
                                      _vm._v(
                                        "\n                                            Dimanche\n                                        "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.services.dimanche.isOpen == true
                                  ? _c("div", { staticClass: "setting-open" }, [
                                      _c(
                                        "div",
                                        { staticClass: "setting-hours" },
                                        [
                                          _c(
                                            "span",
                                            [
                                              _vm._l(
                                                _vm.services.dimanche.services,
                                                function (service, index) {
                                                  return [
                                                    !service.deleted
                                                      ? _c(
                                                          "div",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.has_right_to_update_config,
                                                                  format:
                                                                    "HH:mm",
                                                                  "minute-interval": 15,
                                                                  value:
                                                                    service.start_hour,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.calculSlot(
                                                                        "dimanche",
                                                                        index,
                                                                        $event
                                                                      )
                                                                    },
                                                                  close:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.timePickerClosed(
                                                                        "dimanche"
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "vue-timepicker",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.has_right_to_update_config,
                                                                  format:
                                                                    "HH:mm",
                                                                  "hour-range":
                                                                    _vm.calculHourRange(
                                                                      service.start_hour
                                                                    ),
                                                                  "hide-disabled-hours":
                                                                    "",
                                                                  "minute-interval": 15,
                                                                  value:
                                                                    service.end_hour,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.calculSlot(
                                                                        "dimanche",
                                                                        index,
                                                                        null,
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              service.slots,
                                                              function (
                                                                slot,
                                                                index
                                                              ) {
                                                                return [
                                                                  slot.displayed
                                                                    ? _c(
                                                                        "label",
                                                                        {
                                                                          key: index,
                                                                          staticClass:
                                                                            "container-box ml-3",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "initial",
                                                                              "margin-right":
                                                                                "0",
                                                                              "padding-left":
                                                                                "28px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      slot.enabled,
                                                                                    expression:
                                                                                      "slot.enabled",
                                                                                  },
                                                                                ],
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.has_right_to_update_config,
                                                                                  type: "checkbox",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  checked:
                                                                                    Array.isArray(
                                                                                      slot.enabled
                                                                                    )
                                                                                      ? _vm._i(
                                                                                          slot.enabled,
                                                                                          null
                                                                                        ) >
                                                                                        -1
                                                                                      : slot.enabled,
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    var $$a =
                                                                                        slot.enabled,
                                                                                      $$el =
                                                                                        $event.target,
                                                                                      $$c =
                                                                                        $$el.checked
                                                                                          ? true
                                                                                          : false
                                                                                    if (
                                                                                      Array.isArray(
                                                                                        $$a
                                                                                      )
                                                                                    ) {
                                                                                      var $$v =
                                                                                          null,
                                                                                        $$i =
                                                                                          _vm._i(
                                                                                            $$a,
                                                                                            $$v
                                                                                          )
                                                                                      if (
                                                                                        $$el.checked
                                                                                      ) {
                                                                                        $$i <
                                                                                          0 &&
                                                                                          _vm.$set(
                                                                                            slot,
                                                                                            "enabled",
                                                                                            $$a.concat(
                                                                                              [
                                                                                                $$v,
                                                                                              ]
                                                                                            )
                                                                                          )
                                                                                      } else {
                                                                                        $$i >
                                                                                          -1 &&
                                                                                          _vm.$set(
                                                                                            slot,
                                                                                            "enabled",
                                                                                            $$a
                                                                                              .slice(
                                                                                                0,
                                                                                                $$i
                                                                                              )
                                                                                              .concat(
                                                                                                $$a.slice(
                                                                                                  $$i +
                                                                                                    1
                                                                                                )
                                                                                              )
                                                                                          )
                                                                                      }
                                                                                    } else {
                                                                                      _vm.$set(
                                                                                        slot,
                                                                                        "enabled",
                                                                                        $$c
                                                                                      )
                                                                                    }
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "checkmark",
                                                                              class:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.has_right_to_update_config,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                                                                " +
                                                                              _vm._s(
                                                                                _vm.formatHour(
                                                                                  slot.hour
                                                                                )
                                                                              ) +
                                                                              "\n                                                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticClass:
                                                                  "feather css-i6dzq1 pt-1",
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                                attrs: {
                                                                  viewBox:
                                                                    "0 0 24 24",
                                                                  width: "24",
                                                                  height: "24",
                                                                  stroke:
                                                                    "currentColor",
                                                                  "stroke-width":
                                                                    "2",
                                                                  fill: "none",
                                                                  "stroke-linecap":
                                                                    "round",
                                                                  "stroke-linejoin":
                                                                    "round",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeOpening(
                                                                        "dimanche",
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "18",
                                                                    y1: "6",
                                                                    x2: "6",
                                                                    y2: "18",
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("line", {
                                                                  attrs: {
                                                                    x1: "6",
                                                                    y1: "6",
                                                                    x2: "18",
                                                                    y2: "18",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "feather feather-plus sc-dnqmqq jxshSx",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "24",
                                                    height: "24",
                                                    viewBox: "0 0 24 24",
                                                    fill: "none",
                                                    stroke: "currentColor",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "aria-hidden": "true",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addOpening(
                                                        "dimanche"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "12",
                                                      y1: "5",
                                                      x2: "12",
                                                      y2: "19",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("line", {
                                                    attrs: {
                                                      x1: "5",
                                                      y1: "12",
                                                      x2: "19",
                                                      y2: "12",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("show-errors", {
                        attrs: { errors: _vm.errors, errorKey: "open_days" },
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.pickunEnable
                ? [
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4" },
                          [
                            _vm._m(2),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-3 mb-2" }, [
                              _c("div", { staticClass: "col-md-5 pt-1" }, [
                                _c(
                                  "label",
                                  [
                                    _vm._v(
                                      "\n                                        Délai de préparation de commande "
                                    ),
                                    _c("small", [_vm._v("*")]),
                                    _vm._v(" "),
                                    _c("feather", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltipNoticeLexicon(
                                            "notices.clickAndCollect.config.orderPreparationDelay",
                                            _vm.restaurant_id
                                          ),
                                          expression:
                                            "\n                                                getTooltipNoticeLexicon('notices.clickAndCollect.config.orderPreparationDelay', restaurant_id)\n                                            ",
                                        },
                                      ],
                                      staticClass: "ml-1 pointer tooltip-infos",
                                      attrs: { type: "info" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.preparation_time,
                                            expression: "preparation_time",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                          type: "number",
                                          min: "1",
                                        },
                                        domProps: {
                                          value: _vm.preparation_time,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.preparation_time =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-md-8" }, [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.preparation_time_format,
                                              expression:
                                                "preparation_time_format",
                                            },
                                          ],
                                          staticClass: "custom-select mb-0",
                                          staticStyle: { width: "initial" },
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_config,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.preparation_time_format =
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                            },
                                          },
                                        },
                                        [
                                          _c("option", [_vm._v("minutes")]),
                                          _vm._v(" "),
                                          _c("option", [_vm._v("heures")]),
                                          _vm._v(" "),
                                          _c("option", [_vm._v("jours")]),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "preparation_time",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-2" }, [
                              _vm._m(3),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.open_period_days,
                                            expression: "open_period_days",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                          type: "number",
                                          min: "0",
                                        },
                                        domProps: {
                                          value: _vm.open_period_days,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.open_period_days =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-8 mt-1" },
                                      [_vm._v("jours")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "opening_period",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col-md-5 pt-1" }, [
                                _c("label", [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.clickAndCollect.config.nbOrderMax",
                                          _vm.restaurant_id
                                        )
                                      ) +
                                      "\n                                        "
                                  ),
                                  _c("small", [_vm._v("*")]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.cmd_per_service,
                                            expression: "cmd_per_service",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          disabled:
                                            _vm.serviceMaxOrder ||
                                            !_vm.has_right_to_update_config,
                                          min: "1",
                                          type: "number",
                                        },
                                        domProps: {
                                          value: _vm.cmd_per_service,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.cmd_per_service =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-8 mt-1" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "container-box",
                                            staticStyle: { width: "initial" },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.serviceMaxOrder,
                                                  expression: "serviceMaxOrder",
                                                },
                                              ],
                                              attrs: {
                                                disabled:
                                                  !_vm.has_right_to_update_config,
                                                type: "checkbox",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.serviceMaxOrder
                                                )
                                                  ? _vm._i(
                                                      _vm.serviceMaxOrder,
                                                      null
                                                    ) > -1
                                                  : _vm.serviceMaxOrder,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.serviceMaxOrder,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.serviceMaxOrder =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.serviceMaxOrder =
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                    }
                                                  } else {
                                                    _vm.serviceMaxOrder = $$c
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "checkmark",
                                              class: {
                                                disabled:
                                                  !_vm.has_right_to_update_config,
                                              },
                                            }),
                                            _vm._v(
                                              "Illimité\n                                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "orders_max_per_service",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-2" }, [
                              _vm._m(4),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.cmd_per_slot,
                                            expression: "cmd_per_slot",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          disabled:
                                            _vm.slotMaxOrder ||
                                            !_vm.has_right_to_update_config,
                                          type: "number",
                                          min: "1",
                                        },
                                        domProps: { value: _vm.cmd_per_slot },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.cmd_per_slot =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-8 mt-1" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "container-box",
                                            staticStyle: { width: "initial" },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.slotMaxOrder,
                                                  expression: "slotMaxOrder",
                                                },
                                              ],
                                              attrs: {
                                                disabled:
                                                  !_vm.has_right_to_update_config,
                                                type: "checkbox",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.slotMaxOrder
                                                )
                                                  ? _vm._i(
                                                      _vm.slotMaxOrder,
                                                      null
                                                    ) > -1
                                                  : _vm.slotMaxOrder,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.slotMaxOrder,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.slotMaxOrder =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.slotMaxOrder = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.slotMaxOrder = $$c
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "checkmark",
                                              class: {
                                                disabled:
                                                  !_vm.has_right_to_update_config,
                                              },
                                            }),
                                            _vm._v(
                                              "\n                                                Illimité\n                                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "orders_max_per_slot",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row mb-2" },
                              [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.clickAndCollect.config.pendingOrder",
                                          _vm.restaurant_id
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7 pt-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-inline-block align-middle lead-switch",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.disable_live_commands,
                                            expression: "disable_live_commands",
                                          },
                                        ],
                                        staticClass:
                                          "switch align-self-center is-rounded",
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.disable_live_commands
                                          )
                                            ? _vm._i(
                                                _vm.disable_live_commands,
                                                null
                                              ) > -1
                                            : _vm.disable_live_commands,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.disable_live_commands,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.disable_live_commands =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.disable_live_commands =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.disable_live_commands = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        on: {
                                          click: function ($event) {
                                            _vm.disable_live_commands =
                                              _vm.has_right_to_update_config
                                                ? !_vm.disable_live_commands
                                                : _vm.disable_live_commands
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("show-errors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "show_out_of_stock_products",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col-5 pt-2" }, [
                                _c(
                                  "label",
                                  [
                                    _vm._v(
                                      "\n                                        Confirmation des commandes "
                                    ),
                                    _c("small", [_vm._v("*")]),
                                    _vm._v(" "),
                                    _c("feather", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltipNoticeLexicon(
                                            "notices.clickAndCollect.config.commandConfirm",
                                            _vm.restaurant_id
                                          ),
                                          expression:
                                            "getTooltipNoticeLexicon('notices.clickAndCollect.config.commandConfirm', restaurant_id)",
                                        },
                                      ],
                                      staticClass: "ml-1 pointer tooltip-infos",
                                      attrs: { type: "info" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-7 radio w-100 h-100 d-table pt-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-table-cell align-middle",
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "container-box" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.autoconfirm.autoconfirm,
                                                expression:
                                                  "autoconfirm.autoconfirm",
                                              },
                                            ],
                                            attrs: {
                                              type: "radio",
                                              disabled:
                                                !_vm.has_right_to_update_config,
                                            },
                                            domProps: {
                                              value: 0,
                                              checked: _vm._q(
                                                _vm.autoconfirm.autoconfirm,
                                                0
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$set(
                                                  _vm.autoconfirm,
                                                  "autoconfirm",
                                                  0
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                          _vm._v(
                                            " Manuelle\n                                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { staticClass: "container-box" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.autoconfirm.autoconfirm,
                                                expression:
                                                  "autoconfirm.autoconfirm",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                !_vm.has_right_to_update_config,
                                              type: "radio",
                                            },
                                            domProps: {
                                              value: 1,
                                              checked: _vm._q(
                                                _vm.autoconfirm.autoconfirm,
                                                1
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$set(
                                                  _vm.autoconfirm,
                                                  "autoconfirm",
                                                  1
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                          _vm._v(
                                            " Automatique\n                                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "autoconfirm",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.autoconfirm.autoconfirm
                              ? [
                                  _c("div", { staticClass: "row mb-2" }, [
                                    _vm._m(5),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-7" }, [
                                      _c(
                                        "div",
                                        { staticClass: "row" },
                                        [
                                          _c("div", { staticClass: "col-4" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.autoconfirm
                                                      .autoconfirm_command,
                                                  expression:
                                                    "autoconfirm.autoconfirm_command",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "number",
                                                disabled:
                                                  !_vm.has_right_to_update_config,
                                              },
                                              domProps: {
                                                value:
                                                  _vm.autoconfirm
                                                    .autoconfirm_command,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.autoconfirm,
                                                    "autoconfirm_command",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-8 mt-2" },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.clickAndCollect.config.orderByServices",
                                                      _vm.restaurant_id
                                                    )
                                                  )
                                              ),
                                              _c("small", [
                                                _vm._v(
                                                  " - 0 pour ne pas bloquer"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("show-errors", {
                                            staticClass: "col-12",
                                            attrs: {
                                              errors: _vm.errors,
                                              errorKey: "autoconfirm_command",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row mb-2" }, [
                                    _vm._m(6),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-7" }, [
                                      _c(
                                        "div",
                                        { staticClass: "row" },
                                        [
                                          _c("div", { staticClass: "col-4" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.autoconfirm
                                                      .autoconfirm_until,
                                                  expression:
                                                    "autoconfirm.autoconfirm_until",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "number",
                                                disabled:
                                                  !_vm.has_right_to_update_config,
                                              },
                                              domProps: {
                                                value:
                                                  _vm.autoconfirm
                                                    .autoconfirm_until,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.autoconfirm,
                                                    "autoconfirm_until",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-8 mt-2" },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.clickAndCollect.config.beforeStarting",
                                                      _vm.restaurant_id
                                                    )
                                                  )
                                              ),
                                              _c("small", [
                                                _vm._v(
                                                  " - 0 pour ne pas bloquer"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("show-errors", {
                                            staticClass: "col-12",
                                            attrs: {
                                              errors: _vm.errors,
                                              errorKey: "autoconfirm_until",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row mb-2" },
                              [
                                _vm._m(7),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7 pt-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-inline-block align-middle lead-switch",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.show_product_out,
                                            expression: "show_product_out",
                                          },
                                        ],
                                        staticClass:
                                          "switch align-self-center is-rounded",
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.show_product_out
                                          )
                                            ? _vm._i(
                                                _vm.show_product_out,
                                                null
                                              ) > -1
                                            : _vm.show_product_out,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.show_product_out,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.show_product_out =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.show_product_out = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.show_product_out = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        on: {
                                          click: function ($event) {
                                            _vm.show_product_out =
                                              _vm.has_right_to_update_config
                                                ? !_vm.show_product_out
                                                : _vm.show_product_out
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("show-errors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "show_out_of_stock_products",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row mb-2" },
                              [
                                _vm._m(8),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7 pt-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-inline-block align-middle lead-switch",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.show_product_disable,
                                            expression: "show_product_disable",
                                          },
                                        ],
                                        staticClass:
                                          "switch align-self-center is-rounded",
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.show_product_disable
                                          )
                                            ? _vm._i(
                                                _vm.show_product_disable,
                                                null
                                              ) > -1
                                            : _vm.show_product_disable,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.show_product_disable,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.show_product_disable =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.show_product_disable =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.show_product_disable = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        on: {
                                          click: function ($event) {
                                            _vm.show_product_disable =
                                              _vm.has_right_to_update_config
                                                ? !_vm.show_product_disable
                                                : _vm.show_product_disable
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("show-errors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "show_inactive_products",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4" },
                          [
                            _vm._m(9),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-3" }, [
                              _vm._m(10),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7 pt-1" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-inline-block align-middle lead-switch",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.group_payments,
                                          expression: "group_payments",
                                        },
                                      ],
                                      staticClass:
                                        "switch align-self-center is-rounded",
                                      attrs: {
                                        disabled:
                                          !_vm.has_right_to_update_config,
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.group_payments
                                        )
                                          ? _vm._i(_vm.group_payments, null) >
                                            -1
                                          : _vm.group_payments,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.group_payments,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.group_payments =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.group_payments = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.group_payments = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", {
                                      on: {
                                        click: function ($event) {
                                          _vm.group_payments =
                                            _vm.has_right_to_update_config
                                              ? !_vm.group_payments
                                              : _vm.group_payments
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.hasMealVouchersEnabled && _vm.isNoShow
                              ? _c("div", { staticClass: "row" }, [
                                  _vm._m(11),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7 pt-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-inline-block align-middle lead-switch",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.collect_mealvouchers_enabled,
                                              expression:
                                                "collect_mealvouchers_enabled",
                                            },
                                          ],
                                          staticClass:
                                            "switch align-self-center is-rounded",
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_config,
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.collect_mealvouchers_enabled
                                            )
                                              ? _vm._i(
                                                  _vm.collect_mealvouchers_enabled,
                                                  null
                                                ) > -1
                                              : _vm.collect_mealvouchers_enabled,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.collect_mealvouchers_enabled,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.collect_mealvouchers_enabled =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.collect_mealvouchers_enabled =
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                }
                                              } else {
                                                _vm.collect_mealvouchers_enabled =
                                                  $$c
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("label", {
                                          on: {
                                            click: function ($event) {
                                              _vm.collect_mealvouchers_enabled =
                                                _vm.has_right_to_update_config
                                                  ? !_vm.collect_mealvouchers_enabled
                                                  : _vm.collect_mealvouchers_enabled
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _vm._m(12),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7 pt-1" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-inline-block align-middle lead-switch",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.collect_connect_to_gv,
                                          expression: "collect_connect_to_gv",
                                        },
                                      ],
                                      staticClass:
                                        "switch align-self-center is-rounded",
                                      attrs: {
                                        disabled:
                                          !_vm.has_right_to_update_config ||
                                          !_vm.hasGvSubscription,
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.collect_connect_to_gv
                                        )
                                          ? _vm._i(
                                              _vm.collect_connect_to_gv,
                                              null
                                            ) > -1
                                          : _vm.collect_connect_to_gv,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.collect_connect_to_gv,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.collect_connect_to_gv =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.collect_connect_to_gv = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.collect_connect_to_gv = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", {
                                      on: {
                                        click: function ($event) {
                                          _vm.collect_connect_to_gv =
                                            _vm.has_right_to_update_config &&
                                            _vm.hasGvSubscription
                                              ? !_vm.collect_connect_to_gv
                                              : _vm.collect_connect_to_gv
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4" },
                          [
                            _vm._m(13),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-3" }, [
                              _vm._m(14),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7" }, [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.legal_information,
                                      expression: "legal_information",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    disabled: !_vm.has_right_to_update_config,
                                    maxlength: "190",
                                    rows: "3",
                                  },
                                  domProps: { value: _vm.legal_information },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.legal_information =
                                        $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("small", { staticClass: "text-muted" }, [
                                  _vm._v("Max : 190 caractères"),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("automatic-export-component", {
                      staticClass: "row mb-3",
                      attrs: {
                        module: _vm.MODULE_TYPE_CLICK_AND_COLLECT.value,
                        "services-name": "collect_services",
                        "can-fetch-services": _vm.canFetchServices,
                      },
                      on: {
                        "services-fetched": function ($event) {
                          _vm.canFetchServices = false
                        },
                      },
                      model: {
                        value: _vm.automaticExport,
                        callback: function ($$v) {
                          _vm.automaticExport = $$v
                        },
                        expression: "automaticExport",
                      },
                    }),
                    _vm._v(" "),
                    _c("editClickAndCollectNotifications", {
                      ref: "editClickAndCollectNotifications",
                      staticClass: "mt-2",
                      attrs: {
                        "show-save-button": false,
                        "notifications-data": _vm.notificationsData,
                        "collect-type": _vm.COLLECT_TYPE_COLLECT.value,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("input", {
                    staticClass: "btn btn-success btn-circle",
                    class: { disabled: _vm.loading },
                    attrs: {
                      type: "submit",
                      name: "submit",
                      value: "Enregistrer",
                      disabled: _vm.loading || !_vm.has_right_to_update_config,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.post()
                      },
                    },
                  }),
                ]),
              ]),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showHasFutureCommandsModal
        ? _c("alert-slot-deletion-modal", {
            attrs: {
              commands: _vm.futureCommands,
              restaurant_id: _vm.restaurant_id,
            },
            on: {
              close: function ($event) {
                _vm.showHasFutureCommandsModal = false
                _vm.futureCommands = []
              },
              next: function ($event) {
                return _vm.loadNextFutureCommands($event)
              },
              save: function ($event) {
                _vm.showHasFutureCommandsModal = false
                _vm.putForm(false)
              },
              "cancel-and-save": function ($event) {
                _vm.showHasFutureCommandsModal = false
                _vm.putForm(true)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [_c("h5", { staticClass: "title mt-2" }, [_vm._v("Retrait")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5" }, [
      _c("strong", [_vm._v("Créneaux de retrait")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [_vm._v("Paramètres généraux")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [
        _vm._v(" Période d'ouverture des commandes "),
        _c("small", [_vm._v("*")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [
        _vm._v(
          "\n                                        Nombre de commandes maximum par créneau de retrait\n                                        "
        ),
        _c("small", [_vm._v("*")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 pt-2 offset-1" }, [
      _c("label", [_vm._v(" Jusqu'à ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 pt-2 offset-1" }, [
      _c("label", [_vm._v(" Jusqu'à ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Afficher les produits en rupture")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Afficher les produits inactifs")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [_vm._v("Paiements")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Autoriser les paiements de groupe")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Autoriser les paiements en titres-restaurant")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Liaison au module Bons Cadeaux")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [_vm._v("Conditions de retrait")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Informations relatives aux conditions de retrait")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }