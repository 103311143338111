var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Réservations"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c("show-reservations-list", {
          attrs: {
            emit_resa_chosen: _vm.emit_resa_chosen,
            reservations_list: _vm.reservations_list,
            restaurant_id: _vm.restaurant_id,
          },
          on: {
            "reservation-edited": function ($event) {
              return _vm.$emit("reservation-edited", $event)
            },
            "resa-chosen": function ($event) {
              return _vm.$emit("resa-chosen", $event)
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn-sm btn btn-secondary btn-circle",
            on: { click: _vm.close },
          },
          [_vm._v("Fermer")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }