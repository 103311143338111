export default {
    noshows: "No-shows",
    booking: {
        covers: "Couverts",
        pax: {
            nb: "Nombre de couverts",
        },
        menus: {
            title: "Menus",
            add: "Ajouter un menu",
            edit: "Éditer le menu :",
            mandatory: "Menu obligatoire",
            mandatoryFrom: "Menu obligatoire à partir de",
            unique: "Menu unique",
            uniqueFrom: "Menu unique à partir de",
            empty: "Aucun menu pour cet établissement",
            childrenOnly: "Menu enfant uniquement",
            externalMenu: {
                externalMenu: "Visualisation carte du restaurant",
            },
            options: {
                title: "Options de menu",
                addMenuOption: "Ajouter une option de menu",
                types: {
                    menu: "Option de menu",
                },
                quantityTypes: {
                    limited_by_pers: "Limitée au nombre de menus",
                },
            },
        },
        closures: {
            empty: "Aucune fermeture exceptionnelle pour cet établissement",
            services: "Service(s)",
            concernedServices: "Services concernés",
            noServiceForDate: "Aucun service disponible pour les dates sélectionnées",
        },
        services: {
            add: "Ajouter un service",
            name: "Nom du service",
            edit: "Éditer le service :",
            duplicate: "Dupliquer un service",
            next: "Prochain service",
            live: "Service en cours",
            createFirst: "Créer mon premier service",
            noOneAvailable: "Aucun service n'est disponible",
            startHour: "Heure de début du service",
            endHour: "Heure de fin du service",
            coversPerSlot: "Couverts / créneaux",
            inCaseFull: "En cas de service complet",
            recap: "Récapitulatif du service",
            editDayMaxPax: "Modifier le PAX max du jour sur ce service",
            showOnDashboardLong: "Affichage du service sur le tableau de bord",
            enableTableRotation: "Activer la rotation des tables",
            emptyForRestaurant: "Aucun service pour cet établissement",
            takeResaLive: "Prendre des réservations pendant le service",
            minsBeforeStart: "minutes avant le début du service",
            showRestaurantService: "Proposer au client les services des établissements suivants :",
            averageTimeAtTable: "Temps moyen à table",
            enableAlgorithm: "Activer le remplissage de salle en fonction de mes tables (PAX réel)",
            maxPax: "Nombre de couverts maximum par service",
            menusAndOptions: {
                title: "Menus et options",
                link: "Associer des menus",
                enableFrom: "Menu activé à partir de",
                disableFrom: "Menu désactivé à partir de",
                menus: "Menu(s)",
            },
            footprint: {
                amountToCapture: "Montant à prélever par personne en cas de no-show",
            },
        },
        reservations: {
            noshowStatus: "Passer la réservation en no-show",
            noshowWithoutDeduction: "No-show (sans prélèvement)",
            editService: "Modifier le service",
            table: "Table",
            noTable: "Aucune table assignée",
            status: {
                noshow: "No-show",
                at_table: "À table",
            },
            edit: {
                tableNotAvailable: "Les tables sélectionnées ne sont pas disponibles pour la date, le slot et la durée sélectionnée",
                table: "Choix de Table(s)",
            },
            footprint: {
                showResa: {
                    capturable: "L'empreinte bancaire du client a été récupérée. Il sera facturé {amount} par personne en cas de no-show.",
                    expired: "Le client n'a pas renseigné son empreinte bancaire dans les temps.",
                },
            },
            mailLogs: {
                types: {
                    noshow_total: "de no-show (prélèvement total)",
                    noshow_partial: "de no-show (prélèvement partiel)",
                    noshow_free: "de no-show (sans prélèvement)",
                },
            },
            export: {
                columns: {
                    table: "Table(s)",
                    menus_and_options: "Menus et options",
                },
            },
            add: {
                chooseMenus: "Choix des menus :",
            },
            grid: {
                columns: {
                    table: "Table",
                    menusAndOptions: "Menus et Options",
                },
            },
            actions: {
                setNoShow: "Déclarer un no-show",
            },
        },
        stats: {
            filters: {
                serviceCategory: "Catégorie de service",
            },
            pax: {
                title: "Nombre de couverts",
            },
            charts: {
                CAPerMenu: {
                    title: "CA réalisé par type de menu",
                    y: "Menu",
                    tooltip: "Aucun menu vendu | 1 menu vendu | {count} menus vendus",
                    filters: {
                        onlySold: "Afficher uniquement les menus vendus",
                        all: "Tous",
                    },
                },
                CAPerMenuOption: {
                    title: "CA réalisé par type d'option de menu",
                    y: "Option",
                    filters: {
                        onlySold: "Afficher uniquement les options vendues",
                        all: "Toutes",
                    },
                },
                CAPerGeneralOption: {
                    title: "CA réalisé par type d'option générale",
                    y: "Option",
                    filters: {
                        onlySold: "Afficher uniquement les options vendues",
                        all: "Toutes",
                    },
                },
                percentMenusSold: {
                    title: "Pourcentage de menus vendus",
                },
                percentMenuOptionsSold: {
                    title: "Pourcentage d'options de menus vendues",
                },
                percentGeneralOptionsSold: {
                    title: "Pourcentage d'options générales vendues",
                },
            },
        },
        globalStats: {
            reservationPaxPerOrigin: {
                title: "Détails par établissements | Nombre de couverts",
            },
        },
        widget: {
            automaticExport: {
                nbMinutes: "Planifier l'envoi avant le service (en minutes)",
                services: "Choisir les services concernés",
            },
        },
        restaurant: {
            closedForSomeService: "Établissement fermé aujourd'hui pour certains services.",
        },
        list: {
            emptyForService: "Vous n'avez pas de réservations pour ce service.",
        },
        seatingPlan: {
            emptyForService: 'Vous n\'avez pas de réservations dans "{status}" aujourd\'hui, pour le service "{service}".',
            tables: "Tables",
            room: {
                disabledForService: "Cette salle est désactivée pour ce service",
                enabledForService: "Cette salle a été activée pour ce service uniquement",
            },
            columns: {
                services: "Services",
            },
        },
        waitings: {
            mailLogs: {
                type: "de table disponible",
            },
        },
        waitingList: {
            content: {
                menus: "Menus :",
            },
            buttons: {
                seeMenusAndOptions: "Voir les menus et les options",
            },
        },
    },
    routes: {
        booking: {
            liveService: "En service",
            kitchen: "En cuisine",
            config: {
                services: "Services",
            },
            services: {
                current: "Services courants",
                menus: "Menus",
                menusOptions: "Options de menus",
            },
        },
    },
    clickAndCollect: {
        config: {
            nbOrderMax: "Nombre de commandes maximum par service",
            pendingOrder: "Suspendre les commandes pendant le service",
            orderByServices: "commande(s) par service",
            beforeStarting: "minute(s) avant le début du service",
        },
    },
    giftVouchers: {
        products: {
            service: "Service",
        },
    },
    notifications: {
        booking: {
            serviceOver: "Liste d'attente - service terminé",
            tableAvailable: "Liste d'attente - table disponible",
            restauResaNotPlaced: {
                content:
                    "0 | Suite à une suppression de table, une réservation n’a pas pu être placée pour le {date}. Merci de la placer manuellement sur le plan de salle. | Suite à une suppression de table, {count} réservations n’ont pas pu être placées pour le {date}. Merci de les placer manuellement sur le plan de salle.",
            },
        },
    },
    settings: {
        menuOptions: "Options de menus",
    },
    form: {
        actions: {
            orderMenus: "Ordonner les menus",
            orderMenusOptions: "Ordonner les options de menus",
        },
    },
};
