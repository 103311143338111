<template>
    <div>
        <input
            :type="passwordFieldType"
            v-model="password"
            :data-test-id="dataTestId"
            @focus="inputFocus = true"
            @blur="inputFocus = false"
            class="form-control d-inline-block"
            style="width: 82%"
            :class="{ 'is-invalid': !isPasswordSecured }" />
        <button
            type="button"
            style="width: 16%; height: 46px"
            class="d-inline-block float-right btn btn-sm btn-outline-secondary"
            @click="switchVisibility">
            <feather class="feather-24" :type="featherIcon" />
        </button>
        <div class="div-pass-check" v-show="showLegend_">
            <div :class="atLeast8Chars ? 'text-success' : 'text-danger'">
                <feather :type="atLeast8Chars ? 'check' : 'x'" />
                {{ $tc("labels.password.minChars", 8) }}
            </div>
            <div :class="hasMin ? 'text-success' : 'text-danger'">
                <feather :type="hasMin ? 'check' : 'x'" />
                {{ $tc("labels.password.minLowerCaseChars", 1) }}
            </div>
            <div :class="hasMaj ? 'text-success' : 'text-danger'">
                <feather :type="hasMaj ? 'check' : 'x'" />
                {{ $tc("labels.password.minUpperCaseChars", 1) }}
            </div>
            <div :class="hasNumber ? 'text-success' : 'text-danger'">
                <feather :type="hasNumber ? 'check' : 'x'" />
                {{ $tc("labels.password.minNumbers", 1) }}
            </div>
            <div :class="hasSpecialChar ? 'text-success' : 'text-danger'">
                <feather :type="hasSpecialChar ? 'check' : 'x'" />
                {{ $tc("labels.password.minSpecialChars", 1, { charsList: "?!}{^][@#&%*" }) }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            inputFocus: false,
            passwordFieldType: "password",
            featherIcon: "eye",
        };
    },
    props: {
        value: {
            default: null,
        },
        showLegend: {
            default: false,
        },
        hideLegend: {
            default: false,
        },
        dataTestId: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        isPasswordSecured() {
            if (!this.password) return true;
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[?{}[\]\^!@#&%\*])(?=.{8,})/.test(this.password);
        },
        atLeast8Chars() {
            if (!this.password) return false;
            return /^.{8,}$/.test(this.password);
        },
        hasMin() {
            if (!this.password) return false;
            return /^.*[a-z]+.*$/.test(this.password);
        },
        hasMaj() {
            if (!this.password) return false;
            return /^.*[A-Z]+.*$/.test(this.password);
        },
        hasNumber() {
            if (!this.password) return false;
            return /^.*[0-9]+.*$/.test(this.password);
        },
        hasSpecialChar() {
            if (!this.password) return false;
            return /^.*[?{}[\]\^!@#&%\*]+.*$/.test(this.password);
        },
        showLegend_() {
            return !this.hideLegend && (this.showLegend || this.inputFocus);
        },
        password: {
            get() {
                return this.value;
            },
            set(newVal) {
                this.$emit("input", newVal);
            },
        },
    },
    methods: {
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
            this.featherIcon = this.featherIcon === "eye" ? "eye-off" : "eye";
        },
    },
};
</script>
