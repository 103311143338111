<template>
    <div class="default-home">
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">{{ $tl("labels.routes.employees") }}</h5>
                        <div>
                            <router-link
                                :class="{ disabled: !has_right_to_create_employee }"
                                :to="{ name: 'employees.add', params: { restaurant_id: restaurant_id } }"
                                class="btn btn-sm btn-success btn-circle">
                                <feather type="plus"></feather>
                                <span>{{ $tl("labels.routes.addEmployee") }}</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12">
                    <div v-if="error" class="alert alert-danger">
                        {{ error }}
                    </div>
                    <div v-else>
                        <table v-if="employees && employees.length > 0" class="capitalize table table-sm table-striped border-bottom">
                            <thead class="border-bottom">
                                <tr>
                                    <th>{{ $tl("labels.form.fullname") }}</th>
                                    <th>{{ $tl("labels.form.email") }}</th>
                                    <th>{{ $tl("labels.form.role") }}</th>
                                    <th>{{ $tl("labels.form.actions.title") }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="employee in employees" :key="employee.id">
                                    <td>{{ employee.firstname }} {{ employee.lastname }}</td>
                                    <td>
                                        {{ employee.email }}
                                    </td>
                                    <td style="text-transform: capitalize">
                                        {{ getRoleLabel(employee.role) }}
                                    </td>
                                    <td>
                                        <router-link
                                            :class="{ disabled: !has_right_to_update_employee }"
                                            :to="{
                                                name: 'employees.edit',
                                                params: { restaurant_id: restaurant_id, employee_id: employee.id },
                                            }"
                                            class="btn btn-sm btn-success"
                                            v-tooltip="getTooltip($t('labels.form.actions.edit'))">
                                            <feather type="edit" />
                                        </router-link>
                                        <button
                                            :disabled="!has_right_to_delete_employee"
                                            @click="deleteEmployee(employee.id)"
                                            :data-id="employee.id"
                                            class="btn btn-sm btn-danger"
                                            v-tooltip="getTooltip($t('labels.form.actions.delete'))">
                                            <feather type="trash-2" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else>
                            <em>{{ $tl("labels.employees.empty") }}</em>
                        </div>
                        <div class="mt-4 float-right">
                            <a class="btn btn-sm btn-outline-secondary" v-if="links.prev" href="javascript:" @click="fetchData(links.prev)">
                                {{ $tl("labels.form.actions.previous") }}
                            </a>
                            <a class="btn btn-sm btn-outline-secondary" v-if="links.next" href="javascript:" @click="fetchData(links.next)">
                                {{ $tl("labels.form.actions.next") }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent";
import RolesEnum from "../../../mixins/enums/RolesEnum";

export default {
    data() {
        return {
            loading: false,
            restaurant_id: this.$route.params.restaurant_id,
            employees: null,
            paginate: false,
            links: {
                next: null,
                prev: null,
            },
            error: null,
        };
    },
    mixins: [RolesEnum],
    created() {
        this.fetchData();
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_employee() {
            return this.rights.includes("default.employees.create");
        },
        has_right_to_update_employee() {
            return this.rights.includes("default.employees.update");
        },
        has_right_to_delete_employee() {
            return this.rights.includes("default.employees.delete");
        },
    },
    methods: {
        fetchData(api_url) {
            this.error = this.employees = this.links.next = this.links.prev = null;
            this.loading = true;
            if (!api_url) {
                api_url = "/api/employees";
            }
            axios
                .get(api_url)
                .then((response) => {
                    this.employees = response.data.data;
                    if (response.data.links) {
                        this.paginate = true;
                        if (response.data.links.next) {
                            this.links.next = response.data.links.next;
                        }
                        if (response.data.links.prev) {
                            this.links.prev = response.data.links.prev;
                        }
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        deleteEmployee(delete_employee_id) {
            if (!confirm(this.$tl("questions.employees.delete"))) {
                return;
            }
            this.loading = true;

            axios
                .delete(`/api/employees/${delete_employee_id}`)
                .then(() => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: this.$tl("success.employees.deleted"),
                    });
                    this.fetchData();
                })
                .catch((error) => {
                    this.loading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
    },
    watch: {
        "$route.params.restaurant_id": function () {
            this.restaurant_id = this.$route.params.restaurant_id;
            this.fetchData();
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>

<style scoped>
button:disabled {
    cursor: default;
}
</style>
