<template>
    <div>
        <div class="row mt-2" v-for="column in log.changed_columns" :key="column">
            <div class="col-md-9">
                <feather class="mr-2" :type="getReservationColumnIcon(column)" />
                <strong>{{ userDisplayable }}</strong> {{ getReservationColumnLabel(column) }}
                <strong v-if="typeof getReservationColumnValueLabel(column, log, restaurantId) !== 'undefined'">
                    {{ getReservationColumnValueLabel(column, log, restaurantId) }}
                </strong>
            </div>
            <div class="col-md-3 text-right">
                <small>{{ createdAt }}</small>
            </div>
        </div>
    </div>
</template>

<script>
import ReservationColumnsEnum from "../../../mixins/enums/booking/ReservationColumnsEnum.js";
import ClientEnum from "../../../mixins/enums/PublicApi/ClientEnum.js";

export default {
    data() {
        return {};
    },
    mixins: [ReservationColumnsEnum, ClientEnum],
    props: {
        log: {
            required: true,
            type: Object,
        },
    },
    computed: {
        restaurantId() {
            return this.$route.params.restaurant_id;
        },
        userDisplayable() {
            if (this.log.provider_name !== null) {
                return this.getPublicApiClientLabel(this.log.provider_name);
            }

            if (this.log.user !== null) {
                return `${this.log.user.firstname} ${this.log.user.lastname}`;
            }

            if (this.log.is_system_update) {
                return this.$tl("labels.booking.reservations.logs.systemUpdate");
            }

            let label = this.$tl("labels.booking.reservations.logs.client");

            if (this.log.from_rwg) {
                label += ` ${this.$tl("labels.booking.reservations.logs.fromRwg")}`;
            }

            return label;
        },
        createdAt() {
            return this.$t("labels.booking.reservations.logs.datetime", {
                date: this.displayDate(this.log.created_at, this.DATE_SHORT),
                hour: this.displayDate(this.log.created_at, this.TIME_SIMPLE),
            });
        },
    },
};
</script>
