var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(_vm._s(_vm.$tl("labels.form.restaurant.delete.modal.header"))),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "body" }, slot: "body" }, [
      _c("div", { staticClass: "py-3" }, [
        _c("strong", [
          _vm._v(
            _vm._s(
              _vm.$tl("labels.form.restaurant.delete.modal.body.paragraph1")
            )
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "pb-3",
        domProps: {
          innerHTML: _vm._s(
            _vm.$tl("labels.form.restaurant.delete.modal.body.paragraph2")
          ),
        },
      }),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "container-box ml-2", staticStyle: { width: "auto" } },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.checkbox,
                expression: "form.checkbox",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.form.checkbox)
                ? _vm._i(_vm.form.checkbox, null) > -1
                : _vm.form.checkbox,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.form.checkbox,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(_vm.form, "checkbox", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.form,
                        "checkbox",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.form, "checkbox", $$c)
                }
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "checkmark" }),
          _vm._v(
            " " +
              _vm._s(
                _vm.$tl(
                  "labels.form.restaurant.delete.modal.body.form.checkbox"
                )
              ) +
              "\n        "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", [
        _c("label", [
          _vm._v(
            _vm._s(
              _vm.$tl(
                "labels.form.restaurant.delete.modal.body.form.text",
                null,
                { restaurantName: _vm.restaurant.name }
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.restaurantName,
              expression: "form.restaurantName",
            },
          ],
          staticClass: "form-control mb-2",
          attrs: { type: "text" },
          domProps: { value: _vm.form.restaurantName },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "restaurantName", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            attrs: { type: "button" },
            on: { click: _vm.close },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-danger btn-circle ml-2",
            attrs: {
              type: "button",
              disabled: _vm.loading || _vm.disableDeleteButton,
            },
            on: { click: _vm.deleteRestaurant },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.delete")) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }