<template>
    <div class="default-home pb-5">
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div class="row m-0">
                    <div class="col-12">
                        <div class="d-flex justify-space-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                            <button @click="$router.go(-1)" class="btn d-flex pl-0">
                                <feather type="chevron-left" class="feather-24" />
                            </button>
                            <div v-if="employee_id">
                                <h5 class="title mt-2">
                                    {{ $tl("labels.routes.editEmployee") }}
                                    <span class="value">{{ firstname }} {{ lastname }}</span>
                                </h5>
                            </div>
                            <div v-else>
                                <h5 class="title mt-2">{{ $tl("labels.routes.addEmployee") }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="formSuccess" class="alert alert-success">
                    {{ formSuccess }}
                </div>

                <LoaderComponent v-if="formLoading" />
                <form v-else id="addReservation" @submit="postForm" method="post" novalidate>
                    <div class="row m-0">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4 mb-3">
                                <div class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label for="firstname">{{ $tl("labels.form.firstname") }} <small>*</small></label>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" name="firstname" v-model="firstname" id="firstname" required class="form-control" />
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="firstname" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label for="lastname">{{ $tl("labels.form.fullname") }} <small>*</small></label>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" name="lastname" v-model="lastname" id="lastname" required class="form-control" />
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="lastname" />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-5 pt-2">
                                        <label for="role"
                                            >{{ $tl("labels.form.role") }} <small>*</small>
                                            <feather type="info" @mouseover="showInfos = true" @mouseleave="showInfos = false" />
                                        </label>
                                    </div>
                                    <div class="col-7">
                                        <select name="role" id="role" v-model="role" required class="custom-select mb-0">
                                            <option v-for="r in rolesList" :key="r.value" :value="r.value">
                                                {{ getRoleLabel(r.value) }}
                                            </option>
                                        </select>
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="role" />
                                    </div>
                                </div>
                                <div class="row" v-if="showInfos">
                                    <div class="col-12 mb-1">
                                        <div class="border-light b-radius-20 p-4">
                                            <div class="row mb-2" v-for="r in rolesList" :key="r.value">
                                                <div class="col-lg-2 col-md-3 mb-2">
                                                    <strong>{{ getRoleLabel(r.value) }}</strong>
                                                </div>
                                                <div class="col-lg-10 col-md-9">{{ $t(`infos.roles.${r.value}`) }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2" v-if="role !== 'user_without_account'">
                                    <div class="col-5 pt-2">
                                        <label for="email">{{ $tl("labels.form.email") }} <small>*</small></label>
                                    </div>
                                    <div class="col-7">
                                        <input type="email" name="email" v-model="email" id="email" required class="form-control" />
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="email" />
                                    </div>
                                </div>
                                <div class="row mb-2" v-if="role !== 'user_without_account'">
                                    <div class="col-5 pt-2">
                                        <label class="mb-0" for="tel">{{ $tl("labels.form.phoneNumber") }} <small>*</small></label
                                        ><br />
                                        <small class="text-muted">{{ $tl("infos.account.changePhoneNumber") }}</small>
                                    </div>
                                    <div class="col-7">
                                        <vue-tel-input
                                            defaultCountry="FR"
                                            name="telInput"
                                            class="mb-2 form-control"
                                            @validate="setPhoneAndCountry"
                                            v-model="phone.raw"
                                            :enabledCountryCode="true"
                                            :wrapperClasses="phone.isValid === null || phone.isValid ? '' : 'border border-danger'"
                                            inputClasses="m-0 border-0"
                                            mode="international"
                                            :placeholder="$t('labels.form.phoneNumber')"></vue-tel-input>
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="tel" />
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="tel_country" />
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="tel_is_mobile" />
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="tel_is_valid" />
                                    </div>
                                </div>
                                <div class="row pt-3">
                                    <div class="col-5 pt-2">
                                        <label>{{ $tl("labels.accessibleRestaurants") }}</label>
                                    </div>
                                    <div class="col-7">
                                        <div v-for="restaurant in available_restaurants" :key="restaurant.id">
                                            <label class="container-box" style="width: 100%">
                                                <input type="checkbox" name="restaurants[]" v-model="restaurants" :value="restaurant.id" />
                                                <span class="checkmark"></span>{{ restaurant.name }}
                                            </label>
                                        </div>
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="restaurants" />
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="restaurants.*" />
                                    </div>
                                </div>
                                <div class="row pt-3">
                                    <div class="col-12 pt-2">
                                        <div
                                            v-show="availableRights !== null || rightsLoading"
                                            @click="showCustomRights = !showCustomRights"
                                            class="btn btn-sm btn-success btn-circle">
                                            {{ $tl("rights.edit") }}
                                        </div>
                                        <ShowErrors class="d-block" :errors="formErrors" errorKey="rightsIds" />
                                    </div>
                                </div>

                                <template v-if="showCustomRights">
                                    <div class="row mt-4 mb-4">
                                        <div class="col-5" v-show="availableRights !== null || rightsLoading">
                                            <label for="role"
                                                ><strong>{{ $tl("rights.title") }}</strong></label
                                            >
                                        </div>
                                        <div class="col-7" v-show="availableRights !== null || rightsLoading">
                                            <button type="button" class="btn btn-sm btn-success btn-circle" @click="checkAllRights(true)">
                                                {{ $tl("labels.checkAll") }}
                                            </button>
                                            <button type="button" class="btn btn-sm btn-success btn-circle" @click="checkAllRights(false)">
                                                {{ $tl("labels.uncheckAll") }}
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row" v-if="rightsLoading">
                                        <LoaderComponent class="col-12" />
                                    </div>
                                    <div class="row" v-else-if="availableRights !== null">
                                        <div class="col-12">
                                            <div class="pb-3 border-top-light" v-for="activeModule in Object.keys(activeModules)" :key="activeModule">
                                                <div class="row">
                                                    <div class="col-12 mt-4 mb-2">
                                                        <h5>
                                                            <label class="container-box" style="margin-bottom: 0; width: 100%; color: #30a4b7">
                                                                <input
                                                                    type="checkbox"
                                                                    v-model="checkAllRightsForSaas[activeModule]"
                                                                    @change="checkAllRightsForSaasChange(activeModule)" />
                                                                <span class="checkmark"></span>
                                                                {{
                                                                    activeModule === "default"
                                                                        ? $tl("rights.defaultSaas")
                                                                        : getModuleTypeLabel(activeModule)
                                                                }}
                                                            </label>
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div class="col-6" v-for="right in getRightsForSaas(activeModule)" :key="right.id">
                                                                <label class="container-box" style="margin-bottom: 8px; width: 100%">
                                                                    <input
                                                                        type="checkbox"
                                                                        v-model="right.active"
                                                                        @change="checkReadRightAssociated(right)" />
                                                                    <span class="checkmark"></span>
                                                                    {{ getRightLabel(right) }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="row" v-if="user_notifications_setting && role !== 'user_without_account'">
                                <div class="col-12">
                                    <EditUserNotificationsSetting
                                        class="border-light b-radius-20 p-4 mb-3"
                                        :user_notifications_setting="user_notifications_setting"
                                        :enable_mail_notifications="enable_mail_notifications"
                                        :enable_notification_ringtone="enable_notification_ringtone"
                                        :tel_is_mobile="phone.isMobile"
                                        @set-property="setProperty" />
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <input
                                        :disabled="rightsLoading"
                                        type="submit"
                                        name="submit"
                                        :value="$t('labels.form.actions.save')"
                                        class="btn btn-success btn-circle" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import EditUserNotificationsSetting from "../../../components/Default/Users/EditUserNotificationsSetting.vue";
import ShowErrors from "../../../components/errors/ShowErrors.vue";
import RolesEnum from "../../../mixins/enums/RolesEnum.js";
import ModuleTypesEnum from "../../../mixins/enums/ModuleTypesEnum.js";
import { sortElementsForGrid } from "../../../utils/calcNbElementsPerColumn.js";

export default {
    data() {
        return {
            loading: false,
            formLoading: false,
            error: null,
            formErrors: null,
            formSuccess: null,
            employee_id: null,
            firstname: null,
            lastname: null,
            enable_mail_notifications: 0,
            enable_notification_ringtone: 0,
            email: null,
            tel: null,
            role: null,
            showInfos: false,
            restaurants: [],
            rightsLoading: false,
            availableRights: null,
            showCustomRights: false,
            checkAllRightsForSaas: {
                default: false,
                booking: false,
                "click-and-collect": false,
                "gift-vouchers": false,
                website: false,
                campaigns: false,
            },
            user_notifications_setting: {
                restau_option_bank_canceled: "mail",
                restau_resa_canceled: "mail",
                restau_resa_created: "mail",
                restau_resa_validated: "mail",
                restau_resa_updated: "mail",
                restau_sms_credits_alert: "mail",
                restaurant_payment_receipt: "mail",
                restaurant_order_confirmed: "mail",
            },
            phone: {
                raw: null,
                isValid: null,
                country: null,
                value: null,
                isMobile: false,
                search: {
                    results: null,
                },
            },
        };
    },
    mixins: [RolesEnum, ModuleTypesEnum],
    computed: {
        hasBooking() {
            return this.$store.getters["restaurants/getRestaurantsByService"]("booking").length > 0;
        },
        rolesList() {
            let roles = [this.ROLE_MANAGER, this.ROLE_EMPLOYEE];
            if (this.hasBooking) {
                roles.push(this.ROLE_KITCHEN);
                roles.push(this.ROLE_USER_WITHOUT_ACCOUNT);
            }
            return roles;
        },
        activeModules() {
            let activeModules = this.$store.getters["users/activeModules"];
            let modulesOrdered = {
                default: this.$tl("rights.defaultSaas"),
            };
            // Set order
            Object.keys(this.checkAllRightsForSaas).forEach((saas) => {
                if (Object.keys(activeModules).includes(saas)) modulesOrdered[saas] = activeModules[saas];
            });
            return modulesOrdered;
        },
        builtData() {
            let rightsIds = [];
            if (this.availableRights)
                this.availableRights.forEach((right) => {
                    if (right.active) rightsIds.push(right.id);
                });
            return {
                firstname: this.firstname,
                lastname: this.lastname,
                email: this.email,
                tel: this.phone.raw,
                tel_country: this.phone.country,
                tel_is_mobile: this.phone.isMobile,
                tel_is_valid: this.phone.isValid,
                role: this.role,
                enable_mail_notifications: this.enable_mail_notifications,
                enable_notification_ringtone: this.enable_notification_ringtone,
                restaurants: this.restaurants,
                rightsIds,
                user_notifications_setting: this.user_notifications_setting,
            };
        },
        available_restaurants() {
            return this.$store.getters["restaurants/restaurants"];
        },
    },
    methods: {
        getRightLabel(right) {
            return this.$t(`rights.labels.${right.saas}.${right.category}.${right.action}`);
        },
        setPhoneAndCountry(phoneObject) {
            if (phoneObject.number && phoneObject.isValid !== undefined && phoneObject.country) {
                this.phone.isValid = phoneObject.isValid;

                if (this.phone.isValid) {
                    this.phone.value = phoneObject.number;
                    this.phone.isMobile = phoneObject.type === "mobile";

                    if (phoneObject.regionCode && phoneObject.regionCode !== "") {
                        this.phone.country = phoneObject.regionCode;
                    } else {
                        this.phone.country = phoneObject.country.iso2;
                    }
                } else {
                    this.phone.isMobile = false;
                }
            } else {
                this.phone.isValid = false;
                this.phone.isMobile = false;
            }
        },
        setProperty(e) {
            this.$set(this, e.key, e.value);
        },
        hasAllRights(saas) {
            return !this.availableRights.some((r) => r.saas === saas.replace(/-/g, "_") && r.active);
        },
        checkAllRightsForSaasChange(saas) {
            for (let i = 0; i < this.availableRights.length; i++)
                if (this.availableRights[i].saas === saas.replace(/-/g, "_")) this.availableRights[i].active = this.checkAllRightsForSaas[saas];
        },
        checkAllRights(val) {
            for (let i = 0; i < this.availableRights.length; i++) this.availableRights[i].active = val;
            Object.keys(this.checkAllRightsForSaas).forEach((saas) => {
                this.checkAllRightsForSaas[saas] = val;
            });
        },
        customSortForRights(x, y) {
            // Put 'Acheter des SMS' just after subscription
            if (x.saas !== y.saas) return x.saas.localeCompare(y.saas);
            if (x.category === "sms" && y.category !== "subscription") return -1;
            if (x.category === "sms" && y.category === "subscription") return 1;
            if (y.category === "sms" && x.category !== "subscription") return 1;
            if (y.category === "sms" && x.category === "subscription") return -1;
            if (x.category !== y.category) return x.category.localeCompare(y.category);
            let rightsOrder = ["read", "create", "update", "delete", "export", "publish"];
            return rightsOrder.indexOf(x.action) - rightsOrder.indexOf(y.action);
        },
        getRightsForSaas(saas) {
            saas = saas.replace(/-/g, "_");
            let rights = this.availableRights.filter((right) => right.saas === saas);
            const nbColumns = 2;

            rights.sort(this.customSortForRights);

            return sortElementsForGrid(rights, nbColumns);
        },
        fetchRightsForRole(forceRole) {
            if (!this.role) return;
            this.rightsLoading = true;
            this.availableRights = null;

            let url = "/api/employees/rights?";
            if (this.employee_id && this.employee_id !== "add" && !forceRole) {
                url += "id=" + this.employee_id;
            } else {
                url += "role=" + this.role;
            }

            axios
                .get(url)
                .then((response) => {
                    this.rightsLoading = false;
                    this.availableRights = response.data.data;
                })
                .catch((error) => {
                    this.rightsLoading = false;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error, this.$tl("errors.common.rights.cantFetch")),
                    });
                });
        },
        initData(changing_employee_id) {
            this.error = this.restaurant = null;
            this.loading = true;

            this.employee_id = this.$route.params.employee_id === "add" ? null : this.$route.params.employee_id;

            // load available restaurants
            /*axios
                    .get('/api/booking/restaurants?employees=1')
                    .then(response => {
                        this.available_restaurants = response.data.data;*/

            // if we are editing an employee
            if (this.employee_id && this.employee_id !== "add") {
                var api_employee = "/api/employees/" + this.employee_id + "?include=user_notifications_setting";
                axios
                    .get(api_employee)
                    .then((response) => {
                        this.loading = false;
                        this.firstname = response.data.firstname;
                        this.lastname = response.data.lastname;
                        this.email = response.data.email;
                        this.tel = response.data.tel;
                        this.role = response.data.role;
                        this.enable_notification_ringtone = response.data.enable_notification_ringtone;
                        this.enable_mail_notifications = response.data.enable_mail_notifications;
                        this.user_notifications_setting = response.data.user_notifications_setting;
                        //this.restaurants = response.data.restaurants;
                        let raw_restaurants = response.data.restaurants;
                        this.phone.raw = response.data.tel;
                        this.cleanRestaurants(raw_restaurants);
                    })
                    .catch((error) => {
                        this.error = error.response.data.message || error.message;
                        if (this.error === "Unauthenticated.") {
                            document.location.reload(true);
                        } else {
                            this.loading = false;
                        }
                    });
            } else {
                this.loading = false;
                // if user come from a editing reservation view
                if (changing_employee_id) {
                    // empty form fields
                    this.emptyForm();
                }
            }

            /*}).catch(error => {
                        this.error = error.response.data.message || error.message;
                        if( this.error === 'Unauthenticated.' ) {
                            document.location.reload(true);
                        } else {
                            this.loading = false;
                        }
                    });*/
        },
        postForm: function (e) {
            e.preventDefault();
            this.formLoading = true;
            this.formSuccess = null;
            this.formErrors = null;

            // if we are editing an amployee
            if (this.employee_id) {
                axios
                    .put("/api/employees/" + this.employee_id, this.builtData)
                    .then((response) => {
                        // display success message
                        this.formLoading = false;
                        //this.formSuccess = response.data.message;
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: response.data.message,
                        });

                        this.$router.push({
                            name: "employees",
                            params: {
                                restaurant_id: this.$route.params.restaurant_id,
                            },
                        });

                        // reload data
                        this.initData();
                    })
                    .catch((error) => {
                        this.formErrors = error.response.data.errors;
                        this.formLoading = false;
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: error.response.data.message,
                        });
                    });
            } else {
                // we are adding a new employee
                axios
                    .post("/api/employees", this.builtData)
                    .then((response) => {
                        // display success message
                        this.formLoading = false;
                        //this.formSuccess = response.data.message;
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: response.data.message,
                        });
                        // empty form fields
                        this.emptyForm();
                        // redirect to edit page
                        this.$router.push({
                            name: "employees",
                            params: {
                                restaurant_id: this.$route.params.restaurant_id,
                            },
                        });
                    })
                    .catch((error) => {
                        this.formErrors = error.response.data.errors;
                        this.formLoading = false;
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: error.response.data.message,
                        });
                    });
            }
        },
        emptyForm() {
            this.firstname = null;
            this.lastname = null;
            this.email = null;
            this.tel = null;
            this.restaurants = null;
        },
        cleanRestaurants: function (restaurants) {
            let arrayRestaurants = [];
            for (var i = 0; i < restaurants.length; i++) {
                arrayRestaurants.push(restaurants[i].id);
            }
            this.restaurants = arrayRestaurants;
        },
        checkReadRightAssociated(right) {
            if (right.action !== "read") {
                let rights = this.availableRights.filter((r) => r.saas === right.saas && r.category === right.category && r.action === "read");
                if (rights.length > 0) rights[0].active = true;
            } else {
                let rights = this.availableRights.filter((r) => r.saas === right.saas && r.category === right.category && r.action !== "read");
                for (let i = 0; i < rights.length; i++) rights[i].active = false;
            }
        },
    },
    watch: {
        "$route.params.restaurant_id": function (id) {
            this.initData();
        },
        "$route.params.employee_id": function (id) {
            this.initData(true);
        },
        role(newVal, oldVal) {
            this.availableRights = null;
            if (newVal !== "user_without_account") this.fetchRightsForRole(oldVal !== null);
        },
    },
    components: {
        LoaderComponent,
        EditUserNotificationsSetting,
        ShowErrors,
    },
    created() {
        this.initData();
    },
};
</script>
