<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="border-light b-radius-20 p-4">
                    <LoaderComponent v-if="loading" />
                    <div class="alert alert-danger" v-else-if="error">{{ error }}</div>
                    <template v-else-if="command && to_refund">
                        <ShowErrors class="d-block" :errors="errors.form" :errorKey="`to_refund`" />
                        <strong style="color: #414141">Choix des produits à rembourser</strong>
                        <div class="mt-4" v-for="product in command.command_products.data" :key="product.id">
                            <label class="container-box" style="width: initial">
                                <input type="checkbox" v-model="to_refund[product.id].refund" />
                                <span class="checkmark"></span>
                                <span v-if="product.quantity > 1">{{ product.quantity }} x </span>{{ product.product.name }}
                            </label>
                            <input
                                type="number"
                                step="1"
                                class="form-control d-inline-block"
                                style="max-width: 100px"
                                v-if="to_refund[product.id].refund && product.quantity > 1"
                                v-model.number="to_refund[product.id].quantity"
                                :min="1"
                                :max="product.quantity" />
                            <span v-if="to_refund[product.id].refund">
                                ( {{ formatCurrency(product.product.price * to_refund[product.id].quantity) }} )
                            </span>
                            <ShowErrors class="d-block" :errors="errors.form" :errorKey="`to_refund.${product.id}.cc_command_product_id`" />
                            <ShowErrors class="d-block" :errors="errors.form" :errorKey="`to_refund.${product.id}.quantity`" />
                            <div class="ml-4" v-if="product.command_product_option.data.length > 0">
                                <div v-for="option in product.command_product_option.data" :key="option.id">
                                    <label class="container-box" style="width: initial; margin-top: 7px">
                                        <input type="checkbox" :checked="to_refund[product.id].refund" disabled />
                                        <span class="checkmark disabled"></span>
                                        <span v-if="option.quantity > 1">{{ option.quantity }} x </span>{{ option.product_option.cc_product.name }}
                                    </label>
                                    <input
                                        type="number"
                                        class="form-control d-inline-block"
                                        style="max-width: 100px"
                                        v-if="to_refund[product.id].refund && option.quantity * product.quantity > 1"
                                        :value="to_refund[product.id].quantity * option.quantity"
                                        disabled />
                                    <span v-if="to_refund[product.id].refund">
                                        (
                                        {{ formatCurrency(option.product_option.price * to_refund[product.id].quantity * option.quantity) }}
                                        )
                                    </span>
                                    <ShowErrors class="d-block" :errors="errors.form" :errorKey="`to_refund.${product.id}.options.${option.id}.*`" />
                                </div>
                            </div>
                        </div>
                        <div class="sep"></div>
                        <div class="mt-1">
                            <div class="row">
                                <div class="col-12 text-right pr-4">
                                    <strong class="value">Total : {{ formatCurrency(total_refund_amount) }}</strong>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";
import ShowErrors from "../../errors/ShowErrors.vue";

export default {
    data() {
        return {
            loading: false,
            error: null,
            command: null,
            to_refund: null,
            errors: {
                form: null,
            },
        };
    },
    props: {
        command_id: {
            type: Number,
            required: true,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        total_refund_amount() {
            let amount = 0;
            this.command.command_products.data.forEach((cp) => {
                if (this.to_refund[cp.id].refund && this.to_refund[cp.id].quantity >= 1) {
                    amount += this.to_refund[cp.id].quantity * cp.product.price;
                    cp.command_product_option.data.forEach((co) => {
                        amount += this.to_refund[cp.id].quantity * co.quantity * co.product_option.price;
                    });
                }
            });
            return amount;
        },
        allProductsChecked() {
            return !this.command.command_products.data.some((cp) => {
                if (!this.to_refund[cp.id].refund || this.to_refund[cp.id].quantity != cp.quantity) return true;
                return false;
            });
        },
        toRefundForPost() {
            let builtData = _.cloneDeep(this.to_refund);
            this.command.command_products.data.forEach((cp) => {
                if (this.to_refund[cp.id].refund && this.to_refund[cp.id].quantity >= 1) {
                    cp.command_product_option.data.forEach((co) => {
                        builtData[cp.id].options[co.id] = {
                            refund: true,
                            cc_command_product_option_id: co.id,
                            quantity: co.quantity * this.to_refund[cp.id].quantity,
                        };
                    });
                }
            });
            return builtData;
        },
    },
    methods: {
        fetchCommand() {
            this.loading = true;
            this.error = null;

            this.$store
                .dispatch("ccCommands/fetchCommandById", {
                    command_id: this.command_id,
                    restaurant_id: this.restaurant_id,
                })
                .then((response) => {
                    let to_refund = {};
                    response.data.command_products.data.forEach((cp) => {
                        to_refund[cp.id] = {
                            refund: false,
                            cc_command_product_id: cp.id,
                            quantity: cp.quantity,
                            options: {},
                        };
                        cp.command_product_option.data.forEach((co) => {
                            to_refund[cp.id].options[co.id] = {
                                refund: false,
                                cc_command_product_option_id: co.id,
                                quantity: co.quantity * cp.quantity,
                            };
                        });
                    });
                    this.$set(this, "command", response.data);
                    this.$set(this, "to_refund", to_refund);
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => (this.loading = false));
        },
        post() {
            this.loading = true;
            this.errors.form = null;
            this.$emit("loading", true);

            this.httpPost(
                `/api/click_and_collect/${this.restaurant_id}/commands/${this.command_id}/refundPartially`,
                {
                    to_refund: this.toRefundForPost,
                    cancel_command: this.allProductsChecked,
                },
                { defaultErrorMessage: "La commande n'a pas pu être remboursée." }
            ).then((response) => {
                if (response !== false) {
                    this.$emit("refunded");
                } else {
                    this.$emit("loading", false);
                }
            });
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
    created() {
        this.fetchCommand();
    },
};
</script>
