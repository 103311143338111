// Old Widget
import BookingPublicWidget from "../../views/Booking/Public/Widget/PublicWidget.vue";
import BookingPublicPageWidget from "../../views/Booking/Public/Widget/PublicPageWidget.vue";

import Clients from "../../views/Clients.vue";
import ClientsForm from "../../views/ClientsForm.vue";
import BookingHome from "../../views/Booking/Home.vue";
import Reservations from "../../views/Booking/Reservations.vue";
import ReservationsCreated from "../../views/Booking/ReservationsCreated.vue";
import ExportReservations from "../../views/Booking/ExportReservations.vue";
import ReservationsSearch from "../../views/Booking/ReservationsSearch.vue";
import RestaurantsStats from "../../views/Booking/RestaurantsStats.vue";
import Payments from "../../views/Booking/Payments.vue";
import WaitingList from "../../components/waitinglist/WaitingList.vue";
import LiveService from "../../views/Booking/LiveService.vue";
import ServiceKitchenInfo from "../../views/Booking/ServiceKitchenInfo.vue";

import settings from "./settings/index.js";
import GlobalStats from "../../views/Booking/GlobalStats.vue";

const routes = [
    // Old Widget
    {
        path: "/widget/page/:token",
        name: "public.page.widget.old",
        component: BookingPublicPageWidget,
        meta: {
            public: true,
        },
    },
    {
        path: "/widget/:token",
        name: "public.widget",
        component: BookingPublicWidget,
        meta: {
            public: true,
        },
    },
    {
        path: "/booking/public/widget/page/:token",
        name: "public.page.widget",
        component: BookingPublicPageWidget,
        meta: {
            public: true,
        },
    },
    {
        path: "/booking/public/widget/:token",
        name: "public.widget.2",
        component: BookingPublicWidget,
        meta: {
            public: true,
        },
    },

    // Back Office
    {
        path: "/booking/home",
        name: "booking.home",
        component: BookingHome,
    },
    {
        path: "/booking/stats",
        name: "booking.globalStats",
        component: GlobalStats,
    },
    {
        path: "/booking/restaurants/:restaurant_id/reservations/search",
        name: "booking.reservations.search",
        component: ReservationsSearch,
    },
    {
        path: "/booking/restaurants/:restaurant_id/reservationsCreated",
        name: "booking.restaurants.reservationsCreated",
        component: ReservationsCreated,
    },
    {
        path: "/booking/restaurants/:restaurant_id/exportReservations",
        name: "booking.restaurants.exportReservations",
        component: ExportReservations,
    },
    {
        path: "/booking/restaurants/:restaurant_id/reservations",
        name: "booking.restaurants.reservations",
        component: Reservations,
    },
    {
        path: "/booking/restaurants/:restaurant_id/payments",
        name: "booking.restaurants.payments",
        component: Payments,
    },
    {
        path: "/booking/restaurants/:restaurant_id/reservations-cuisine",
        name: "booking.restaurants.reservationsCuisine",
        component: ServiceKitchenInfo,
    },
    {
        path: "/booking/restaurants/:restaurant_id/stats",
        name: "booking.restaurants.stats",
        component: RestaurantsStats,
    },
    {
        path: "/booking/restaurants/:restaurant_id/waitinglist",
        name: "booking.restaurants.waitinglist",
        component: WaitingList,
    },
    {
        path: "/booking/restaurants/:restaurant_id/live-service",
        name: "booking.restaurants.liveservice",
        component: LiveService,
    },
    {
        path: "/booking/restaurants/:restaurant_id/clients/:client_id",
        name: "booking.clients.form",
        component: ClientsForm,
    },
    {
        path: "/booking/restaurants/:restaurant_id/clients",
        name: "booking.clients",
        component: Clients,
    },
];

routes.push(...settings);

export default routes;
