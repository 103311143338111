<template>
    <div v-if="has_right_to_read_subscription">
        <div class="row mb-2">
            <div class="col-12">
                <strong>{{ $tl("labels.form.facturation.title") }}</strong>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <span class="d-block">{{ billingDetails.company }}</span>
                <span class="d-block">{{ getBusinessTypeLabel(billingDetails.companyType) }}</span>
                <span class="d-block">{{ billingDetails.companySiret }}</span>
                <span class="d-block">{{ billingDetails.companyAddress }}</span>
                <span class="d-block">{{ billingDetails.companyZipcode }}</span>
                <span class="d-block">{{ billingDetails.companyCity }}</span>
                <span class="d-block">{{ billingDetails.companyCountry }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import BusinessTypesEnum from "../../../mixins/enums/BusinessTypesEnum";

export default {
    name: "ShowBillingDetails",
    mixins: [BusinessTypesEnum],
    computed: {
        billingDetails() {
            return {
                company: this.$store.getters["users/company"],
                companyType: this.$store.getters["users/companyType"],
                companySiret: this.$store.getters["users/companySiret"],
                companyAddress: this.$store.getters["users/companyAddress"],
                companyZipcode: this.$store.getters["users/companyZipcode"],
                companyCity: this.$store.getters["users/companyCity"],
                companyCountry: this.$store.getters["users/companyCountry"],
            };
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_read_subscription() {
            return this.rights.includes("default.subscription.read");
        },
    },
};
</script>
