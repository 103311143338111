// If you use this mixin, we assume you have a data property fetched from BookingStatsFilters, which looks like :
// filters: {
//     hasComparison: false,
//     serviceCategory: null,
//     main: {
//         currentPeriod: "today", // One of ["today"|"yesterday"|"last_week"|"last_month"|"custom_period"]
//         fromDate: this.getDateTime(),
//         toDate: this.getDateTime(),
//     },
//     comparison: {
//         currentPeriod: "previous_period", // One of ["previous_period"|"previous_year"|"next_period"|"custom_period"]
//         fromDate: this.getDateTime().minus({ days: 1 }),
//         toDate: this.getDateTime().minus({ days: 1 }),
//     },
// },
// AND you may implement a fetchStats(abortControllerSignal = null, forComparison = false) method, which takes an AbortController signal as first parameter and returns a Promise
export default {
    data() {
        return {
            nbLoading: 0,
            abortController: null,
        };
    },
    methods: {
        fetchData() {
            if (typeof this.fetchStats === "undefined") {
                return;
            }

            if (this.abortController !== null) {
                this.abortController.abort();
            }

            ++this.nbLoading;

            this.abortController = new AbortController();

            const promises = [];

            promises.push(this.fetchStats(this.abortController.signal));

            if (this.filters.hasComparison || this.filters.hasGroupComparison) {
                promises.push(this.fetchStats(this.abortController.signal, true));
            }

            Promise.all(promises).finally(() => --this.nbLoading);
        },
        getRestaurantsFromFilters(isForComparison) {
            return this.filters.hasGroupComparison && isForComparison
                ? this.filters.comparison.selectedRestaurants
                : this.filters.main.selectedRestaurants;
        },
        getDatesFromFilters(isForComparison) {
            return !this.filters.hasGroupComparison && isForComparison
                ? [this.filters.comparison.fromDate, this.filters.comparison.toDate]
                : [this.filters.main.fromDate, this.filters.main.toDate];
        },
        getFiltersQueryParams(isForComparison = false) {
            let queryParams = "";
            if (this.filters.serviceCategory !== null) {
                queryParams += `&service_category=${this.filters.serviceCategory}`;
            }
            const restaurants = this.getRestaurantsFromFilters(isForComparison);
            const dates = this.getDatesFromFilters(isForComparison);
            restaurants.forEach((restaurant) => {
                queryParams += `&restaurants[]=${restaurant.id}`;
            });
            if (dates.fromDate !== null) {
                queryParams += `&from_date=${dates[0].toISODate()}`;
            }
            if (dates.toDate !== null) {
                queryParams += `&to_date=${dates[1].toISODate()}`;
            }

            return queryParams;
        },
        getLabelForCustomPeriod(forComparison = false) {
            const filterObj = forComparison ? this.filters.comparison : this.filters.main;

            let period = "";

            if (filterObj.fromDate !== null) {
                period += this.displayDate(filterObj.fromDate, this.DATE_SHORT_WITH_WEEKDAY);
            }

            period += " - ";

            if (filterObj.toDate != null) {
                period += this.displayDate(filterObj.toDate, this.DATE_SHORT_WITH_WEEKDAY);
            }

            return period;
        },
        getEvolution(mainValue, comparisonValue) {
            if (mainValue == 0 && comparisonValue == 0) {
                return 0;
            }

            if (comparisonValue == 0) {
                return 100;
            }
            return (mainValue * 100) / comparisonValue - 100;
        },
        getPercent(mainValue, comparisonValue) {
            if (comparisonValue == 0) {
                return 0;
            }
            return Math.round((mainValue / comparisonValue) * 100);
        },
        getDifference(mainValue, comparisonValue) {
            return mainValue - comparisonValue;
        },
        getFeatherArrowIcon(percent) {
            if (percent < 0) {
                return "arrow-down-right";
            }
            if (percent > 0) {
                return "arrow-up-right";
            }
            return "arrow-right";
        },
        getEvolutionClass(percent) {
            if (percent < 0) {
                return "text-danger";
            }
            if (percent > 0) {
                return "text-success";
            }
            return "text-muted";
        },
        formatPercent(percent) {
            return Math.round(percent);
        },
        getItemKey(item) {
            return `${item.group_id}-${item.name}-${item.price}`;
        },
    },
    computed: {
        labelPeriodMain() {
            switch (this.filters.main.currentPeriod) {
                case "today":
                    return this.$tl("labels.today");
                case "yesterday":
                    return this.$tl("labels.yesterday");
                case "last_week":
                    return this.$tl("labels.filters.dates.lastWeek");
                case "last_month":
                    return this.$tl("labels.filters.dates.lastMonth");
                case "last_year":
                    return this.$tl("labels.filters.dates.lastYear");
                case "custom_period":
                    return this.getLabelForCustomPeriod();
                default:
                    throw "Should not happen";
            }
        },
        labelPeriodComparison() {
            if (!this.filters.hasComparison) {
                return "";
            }

            return this.getLabelForCustomPeriod(true);
        },
    },
    created() {
        this.fetchData();
    },
    watch: {
        filters: {
            handler() {
                this.fetchData();
            },
            deep: true,
        },
    },
};
