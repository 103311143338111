var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5 pt-5" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.loading ? _c("LoaderComponent") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          staticClass: "row m-0",
        },
        [
          _vm.error
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-7 pt-1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-inline-block align-middle lead-switch",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.deliveryEnable,
                                    expression: "deliveryEnable",
                                  },
                                ],
                                staticClass:
                                  "switch align-self-center is-rounded",
                                attrs: {
                                  disabled: !_vm.has_right_to_update_config,
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.deliveryEnable)
                                    ? _vm._i(_vm.deliveryEnable, null) > -1
                                    : _vm.deliveryEnable,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.deliveryEnable,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.deliveryEnable = $$a.concat([
                                            $$v,
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.deliveryEnable = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.deliveryEnable = $$c
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", {
                                on: {
                                  click: function ($event) {
                                    _vm.deliveryEnable =
                                      _vm.has_right_to_update_config
                                        ? !_vm.deliveryEnable
                                        : _vm.deliveryEnable
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.deliveryEnable
                        ? [
                            _c("div", { staticClass: "row mt-4" }, [
                              _vm._m(2),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7 pt-2" },
                                [
                                  _c("ShowErrors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "delivery_slots",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "delivery_slots.*",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.weekDays, function (day) {
                                    return _c("div", { key: day }, [
                                      _c("div", { staticClass: "mb-2" }, [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "container-box",
                                            staticStyle: {
                                              "margin-bottom": "0",
                                              width: "100%",
                                            },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.deliverySlots[day]
                                                      .active,
                                                  expression:
                                                    "deliverySlots[day].active",
                                                },
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                disabled:
                                                  !_vm.has_right_to_update_config,
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.deliverySlots[day].active
                                                )
                                                  ? _vm._i(
                                                      _vm.deliverySlots[day]
                                                        .active,
                                                      null
                                                    ) > -1
                                                  : _vm.deliverySlots[day]
                                                      .active,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.deliverySlots[day]
                                                        .active,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.deliverySlots[
                                                            day
                                                          ],
                                                          "active",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.deliverySlots[
                                                            day
                                                          ],
                                                          "active",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.deliverySlots[day],
                                                      "active",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "checkmark",
                                              class: {
                                                disabled:
                                                  !_vm.has_right_to_update_config,
                                              },
                                            }),
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  _vm.dayLabel(day, true)
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm.deliverySlots[day].active
                                        ? _c(
                                            "div",
                                            { staticClass: "setting-open" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "setting-hours",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    [
                                                      _vm._l(
                                                        _vm.deliverySlots[day]
                                                          .slots,
                                                        function (slot, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "mb-1",
                                                            },
                                                            [
                                                              slot.id === null
                                                                ? [
                                                                    _c(
                                                                      "vue-timepicker",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            !_vm.has_right_to_update_config,
                                                                          format:
                                                                            "HH:mm",
                                                                          "minute-interval": 15,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            slot.hour_start,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                slot,
                                                                                "hour_start",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "slot.hour_start",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "vue-timepicker",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            !_vm.has_right_to_update_config,
                                                                          format:
                                                                            "HH:mm",
                                                                          "minute-interval": 15,
                                                                          "hour-range":
                                                                            _vm.calculHourRange(
                                                                              slot.hour_start
                                                                            ),
                                                                          "hide-disabled-hours":
                                                                            "",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            slot.hour_end,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                slot,
                                                                                "hour_end",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "slot.hour_end",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                : [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.formatHour(
                                                                            slot.hour_start
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]),
                                                                    _vm._v(
                                                                      " -\n                                                            "
                                                                    ),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.formatHour(
                                                                            slot.hour_end
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ],
                                                              _vm._v(" "),
                                                              _c(
                                                                "svg",
                                                                {
                                                                  staticClass:
                                                                    "feather css-i6dzq1 pt-1",
                                                                  staticStyle: {
                                                                    color:
                                                                      "red",
                                                                  },
                                                                  attrs: {
                                                                    viewBox:
                                                                      "0 0 24 24",
                                                                    width: "24",
                                                                    height:
                                                                      "24",
                                                                    stroke:
                                                                      "currentColor",
                                                                    "stroke-width":
                                                                      "2",
                                                                    fill: "none",
                                                                    "stroke-linecap":
                                                                      "round",
                                                                    "stroke-linejoin":
                                                                      "round",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteDeliverySlot(
                                                                          day,
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("line", {
                                                                    attrs: {
                                                                      x1: "18",
                                                                      y1: "6",
                                                                      x2: "6",
                                                                      y2: "18",
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c("line", {
                                                                    attrs: {
                                                                      x1: "6",
                                                                      y1: "6",
                                                                      x2: "18",
                                                                      y2: "18",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        }
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.has_right_to_update_config
                                                        ? _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "feather feather-plus sc-dnqmqq jxshSx",
                                                              attrs: {
                                                                xmlns:
                                                                  "http://www.w3.org/2000/svg",
                                                                width: "24",
                                                                height: "24",
                                                                viewBox:
                                                                  "0 0 24 24",
                                                                fill: "none",
                                                                stroke:
                                                                  "currentColor",
                                                                "stroke-width":
                                                                  "2",
                                                                "stroke-linecap":
                                                                  "round",
                                                                "stroke-linejoin":
                                                                  "round",
                                                                "aria-hidden":
                                                                  "true",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.addDeliverySlot(
                                                                      day
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("line", {
                                                                attrs: {
                                                                  x1: "12",
                                                                  y1: "5",
                                                                  x2: "12",
                                                                  y2: "19",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("line", {
                                                                attrs: {
                                                                  x1: "5",
                                                                  y1: "12",
                                                                  x2: "19",
                                                                  y2: "12",
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  }),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-4" }, [
                              _vm._m(3),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7" }, [
                                this.cc_configuration_id !== null
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-success btn-circle",
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                        },
                                        on: { click: _vm.addDeliveryArea },
                                      },
                                      [
                                        _c("feather", {
                                          attrs: { type: "plus" },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            " Ajouter une zone de livraison"
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "Merci d'enregistrer une première fois votre configuration afin de pouvoir ajouter des zones de\n                                        livraison"
                                      ),
                                    ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-1" }, [
                              _vm._m(4),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7 pt-1" },
                                [
                                  !_vm.displayableDeliveryDeparture
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.currentAddress,
                                            expression: "currentAddress",
                                          },
                                        ],
                                        staticClass: "form-control mb-2",
                                        attrs: { type: "text" },
                                        domProps: { value: _vm.currentAddress },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.currentAddress =
                                              $event.target.value
                                          },
                                        },
                                      })
                                    : _c(
                                        "div",
                                        { staticClass: "d-flex" },
                                        [
                                          _c("input", {
                                            staticClass: "form-control mb-2",
                                            attrs: {
                                              disabled: true,
                                              type: "text",
                                            },
                                            domProps: {
                                              value:
                                                _vm.displayableDeliveryDeparture,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("feather", {
                                            staticClass:
                                              "text-danger mt-2 ml-1 pointer",
                                            attrs: { type: "x" },
                                            on: { click: _vm.resetDeparture },
                                          }),
                                        ],
                                        1
                                      ),
                                  _vm._v(" "),
                                  _vm.errorAddress
                                    ? _c(
                                        "span",
                                        { staticClass: "d-block text-danger" },
                                        [_vm._v(_vm._s(_vm.errorAddress))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.loadingPlaceDetails
                                    ? _c("LoaderComponent")
                                    : _vm.availableAddresses.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dropdown-menu prename-field shadow show p-0",
                                        },
                                        [
                                          _c(
                                            "ul",
                                            { staticClass: "list-group" },
                                            _vm._l(
                                              _vm.availableAddresses,
                                              function (address, index) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "list-group-item p-0",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "d-block p-3",
                                                        attrs: {
                                                          href: "javascript:",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addAddress(
                                                              address
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              address.description
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "delivery_departure_address",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey:
                                        "delivery_departure_postal_code",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "delivery_departure_city",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "delivery_departure_country",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "delivery_departure_lat",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "delivery_departure_lng",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _vm._m(5),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7 pt-1" }, [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  _vm._l(
                                    _vm.ALL_DELIVERY_AREA_TYPES,
                                    function (areaType) {
                                      return _c(
                                        "div",
                                        {
                                          key: areaType.value,
                                          staticClass: "col-12 mb-1",
                                        },
                                        [
                                          _c("div", { staticClass: "radio" }, [
                                            _c(
                                              "label",
                                              {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value:
                                                      areaType.value ==
                                                        _vm
                                                          .DELIVERY_AREA_TYPE_CITIES
                                                          .value ||
                                                      _vm.displayableDeliveryDeparture
                                                        ? undefined
                                                        : {
                                                            content: `Merci de saisir l'adresse du point de départ avant de configurer les zones '${areaType.label.toLowerCase()}'`,
                                                          },
                                                    expression:
                                                      "\n                                                        areaType.value == DELIVERY_AREA_TYPE_CITIES.value || displayableDeliveryDeparture\n                                                            ? undefined\n                                                            : {\n                                                                  content: `Merci de saisir l'adresse du point de départ avant de configurer les zones '${areaType.label.toLowerCase()}'`,\n                                                              }\n                                                    ",
                                                  },
                                                ],
                                                staticClass: "container-box",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.delivery_areas_type,
                                                      expression:
                                                        "delivery_areas_type",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "radio",
                                                    disabled:
                                                      !_vm.has_right_to_update_config ||
                                                      (areaType.value !=
                                                        _vm
                                                          .DELIVERY_AREA_TYPE_CITIES
                                                          .value &&
                                                        !_vm.displayableDeliveryDeparture),
                                                  },
                                                  domProps: {
                                                    value: areaType.value,
                                                    checked: _vm._q(
                                                      _vm.delivery_areas_type,
                                                      areaType.value
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.delivery_areas_type =
                                                        areaType.value
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(areaType.label) +
                                                    "\n                                                "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.cc_configuration_id !== null
                              ? _c("div", { staticClass: "row mt-1 mb-3" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "border-light b-radius-20 p-4 mt-4",
                                      },
                                      [
                                        _vm.delivery_areas_type ==
                                        _vm.DELIVERY_AREA_TYPE_DRAWN.value
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-success btn-circle mb-1",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showEditClickAndCollectDrawDeliveryAreas = true
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Editer les zones de livraison\n                                        "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.deliveryAreas_.length > 0
                                          ? [
                                              _vm.delivery_areas_type ==
                                              _vm.DELIVERY_AREA_TYPE_CROW_FLIES
                                                .value
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-success btn-circle mb-1",
                                                      on: {
                                                        click: _vm.viewOnGMaps,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Voir les zones de livraison sur une carte\n                                            "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "table",
                                                {
                                                  staticClass:
                                                    "table table-sm table-striped border-bottom",
                                                },
                                                [
                                                  _c(
                                                    "thead",
                                                    {
                                                      staticClass:
                                                        "border-bottom",
                                                    },
                                                    [
                                                      _c("tr", [
                                                        _c(
                                                          "th",
                                                          {
                                                            style:
                                                              _vm.delivery_areas_type !=
                                                              _vm
                                                                .DELIVERY_AREA_TYPE_DRAWN
                                                                .value
                                                                ? "width: 40% !important;"
                                                                : "",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                            Zone\n                                                        "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.delivery_areas_type ==
                                                        _vm
                                                          .DELIVERY_AREA_TYPE_DRAWN
                                                          .value
                                                          ? _c("th", [
                                                              _vm._v("Couleur"),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c("th", [
                                                          _vm._v("Prix (TTC)"),
                                                        ]),
                                                        _vm._v(" "),
                                                        !_vm.isTvaDisabled
                                                          ? _c("th", [
                                                              _vm._v("TVA"),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c("th", [
                                                          _vm._v(
                                                            "Livraison gratuite"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("th", [
                                                          _vm._v(
                                                            "Montant minimum"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("th", [
                                                          _vm._v("Créneaux"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("th", [
                                                          _vm._v("Actions"),
                                                        ]),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      _vm.deliveryAreas_,
                                                      function (area) {
                                                        return _c(
                                                          "tr",
                                                          { key: area.id },
                                                          [
                                                            _c(
                                                              "td",
                                                              {
                                                                style:
                                                                  _vm.delivery_areas_type !=
                                                                  _vm
                                                                    .DELIVERY_AREA_TYPE_DRAWN
                                                                    .value
                                                                    ? "width: 40% !important;"
                                                                    : "",
                                                              },
                                                              [
                                                                area.type ==
                                                                _vm
                                                                  .DELIVERY_AREA_TYPE_CITIES
                                                                  .value
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          area.cities.data
                                                                            .map(
                                                                              (
                                                                                c
                                                                              ) =>
                                                                                `${c.name} (${c.postcode})`
                                                                            )
                                                                            .join(
                                                                              " - "
                                                                            )
                                                                        )
                                                                      ),
                                                                    ])
                                                                  : area.type ==
                                                                    _vm
                                                                      .DELIVERY_AREA_TYPE_CROW_FLIES
                                                                      .value
                                                                  ? _c("span", [
                                                                      area.distance_min_km
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "d-block",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Entre " +
                                                                                  _vm._s(
                                                                                    area.distance_min_km
                                                                                  ) +
                                                                                  " km et " +
                                                                                  _vm._s(
                                                                                    area.distance_max_km
                                                                                  ) +
                                                                                  " km du point\n                                                                    de départ de livraison"
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "d-block",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "À moins de " +
                                                                                  _vm._s(
                                                                                    area.distance_max_km
                                                                                  ) +
                                                                                  " km du point de départ de\n                                                                    livraison"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ])
                                                                  : area.type ==
                                                                    _vm
                                                                      .DELIVERY_AREA_TYPE_DRAWN
                                                                      .value
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          area.name
                                                                        )
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            area.type ==
                                                            _vm
                                                              .DELIVERY_AREA_TYPE_DRAWN
                                                              .value
                                                              ? _c("td", [
                                                                  _c("div", {
                                                                    staticStyle:
                                                                      {
                                                                        height:
                                                                          "20px",
                                                                        width:
                                                                          "80px",
                                                                      },
                                                                    style: `background-color: ${area.drawn_color}`,
                                                                  }),
                                                                ])
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                "\n                                                            " +
                                                                  _vm._s(
                                                                    area.price !==
                                                                      null
                                                                      ? _vm.formatCurrency(
                                                                          area.price
                                                                        )
                                                                      : "Gratuite"
                                                                  ) +
                                                                  "\n                                                        "
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            !_vm.isTvaDisabled
                                                              ? _c("td", [
                                                                  _vm._v(
                                                                    "\n                                                            " +
                                                                      _vm._s(
                                                                        area.tax_rate !==
                                                                          null
                                                                          ? `${area.tax_rate} %`
                                                                          : "--"
                                                                      ) +
                                                                      "\n                                                        "
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                "\n                                                            " +
                                                                  _vm._s(
                                                                    area.free_above !==
                                                                      null
                                                                      ? _vm.formatCurrency(
                                                                          area.free_above
                                                                        )
                                                                      : "--"
                                                                  ) +
                                                                  "\n                                                        "
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                "\n                                                            " +
                                                                  _vm._s(
                                                                    area.min_amount !==
                                                                      null
                                                                      ? _vm.formatCurrency(
                                                                          area.min_amount
                                                                        )
                                                                      : "--"
                                                                  ) +
                                                                  "\n                                                        "
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                "\n                                                            " +
                                                                  _vm._s(
                                                                    area.use_default_delivery_slots
                                                                      ? "Tous"
                                                                      : "Limité"
                                                                  ) +
                                                                  "\n                                                        "
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              area.type !=
                                                              _vm
                                                                .DELIVERY_AREA_TYPE_DRAWN
                                                                .value
                                                                ? _c(
                                                                    "button",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "tooltip",
                                                                            rawName:
                                                                              "v-tooltip",
                                                                            value:
                                                                              _vm.getTooltip(
                                                                                "Éditer"
                                                                              ),
                                                                            expression:
                                                                              "getTooltip('Éditer')",
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "btn btn-sm btn-success btn-square",
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm.has_right_to_update_config,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.editDeliveryArea(
                                                                              area
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "feather",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "edit",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "button",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "tooltip",
                                                                      rawName:
                                                                        "v-tooltip",
                                                                      value:
                                                                        _vm.getTooltip(
                                                                          "Supprimer"
                                                                        ),
                                                                      expression:
                                                                        "getTooltip('Supprimer')",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "btn btn-sm btn-danger btn-square",
                                                                  attrs: {
                                                                    disabled:
                                                                      !_vm.has_right_to_update_config,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteDeliveryArea(
                                                                          area.id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "feather",
                                                                    {
                                                                      attrs: {
                                                                        type: "trash-2",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _c(
                                              "em",
                                              { staticClass: "d-block" },
                                              [
                                                _vm._v(
                                                  "Vous n'avez ajouté aucune zone de livraison"
                                                ),
                                              ]
                                            ),
                                      ],
                                      2
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.deliveryEnable
                ? [
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4" },
                          [
                            _vm._m(6),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-3 mb-2" }, [
                              _c("div", { staticClass: "col-md-5 pt-1" }, [
                                _c(
                                  "label",
                                  [
                                    _vm._v(
                                      "\n                                        Délai de préparation de commande "
                                    ),
                                    _c("small", [_vm._v("*")]),
                                    _vm._v(" "),
                                    _c("feather", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltipNotice(
                                            "notices.clickAndCollect.configDelivery.orderPreparationDelay"
                                          ),
                                          expression:
                                            "getTooltipNotice('notices.clickAndCollect.configDelivery.orderPreparationDelay')",
                                        },
                                      ],
                                      staticClass: "ml-1 pointer tooltip-infos",
                                      attrs: { type: "info" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.deliveryPreparationTime,
                                            expression:
                                              "deliveryPreparationTime",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                          type: "number",
                                          min: "1",
                                        },
                                        domProps: {
                                          value: _vm.deliveryPreparationTime,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.deliveryPreparationTime =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-md-8" }, [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.deliveryPreparationTimeUnit,
                                              expression:
                                                "deliveryPreparationTimeUnit",
                                            },
                                          ],
                                          staticClass: "custom-select mb-0",
                                          staticStyle: { width: "initial" },
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_config,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.deliveryPreparationTimeUnit =
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "mins" } },
                                            [_vm._v("Minutes")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "hours" } },
                                            [_vm._v("Heures")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "days" } },
                                            [_vm._v("Jours")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "delivery_preparation_time",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey:
                                        "delivery_preparation_time_unit",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-2" }, [
                              _vm._m(7),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.open_period_days,
                                            expression: "open_period_days",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                          type: "number",
                                          min: "0",
                                        },
                                        domProps: {
                                          value: _vm.open_period_days,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.open_period_days =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-8 mt-1" },
                                      [_vm._v("jours")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "delivery_opening_period",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-2" }, [
                              _vm._m(8),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-7" },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.cmd_per_slot,
                                            expression: "cmd_per_slot",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          disabled:
                                            _vm.slotMaxOrder ||
                                            !_vm.has_right_to_update_config,
                                          type: "number",
                                          min: "1",
                                        },
                                        domProps: { value: _vm.cmd_per_slot },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.cmd_per_slot =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-8 mt-1" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "container-box",
                                            staticStyle: { width: "initial" },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.slotMaxOrder,
                                                  expression: "slotMaxOrder",
                                                },
                                              ],
                                              attrs: {
                                                disabled:
                                                  !_vm.has_right_to_update_config,
                                                type: "checkbox",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.slotMaxOrder
                                                )
                                                  ? _vm._i(
                                                      _vm.slotMaxOrder,
                                                      null
                                                    ) > -1
                                                  : _vm.slotMaxOrder,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.slotMaxOrder,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.slotMaxOrder =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.slotMaxOrder = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.slotMaxOrder = $$c
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "checkmark",
                                              class: {
                                                disabled:
                                                  !_vm.has_right_to_update_config,
                                              },
                                            }),
                                            _vm._v(
                                              "\n                                                Illimité\n                                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "orders_max_per_delivery_slot",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col-5 pt-2" }, [
                                _c(
                                  "label",
                                  [
                                    _vm._v(
                                      "\n                                        Confirmation des commandes "
                                    ),
                                    _c("small", [_vm._v("*")]),
                                    _vm._v(" "),
                                    _c("feather", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltipNotice(
                                            "notices.clickAndCollect.configDelivery.commandConfirm"
                                          ),
                                          expression:
                                            "getTooltipNotice('notices.clickAndCollect.configDelivery.commandConfirm')",
                                        },
                                      ],
                                      staticClass: "ml-1 pointer tooltip-infos",
                                      attrs: { type: "info" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-7 radio w-100 h-100 d-table pt-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-table-cell align-middle",
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "container-box" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.autoconfirm
                                                    .autoconfirm_delivery,
                                                expression:
                                                  "autoconfirm.autoconfirm_delivery",
                                              },
                                            ],
                                            attrs: {
                                              type: "radio",
                                              disabled:
                                                !_vm.has_right_to_update_config,
                                            },
                                            domProps: {
                                              value: 0,
                                              checked: _vm._q(
                                                _vm.autoconfirm
                                                  .autoconfirm_delivery,
                                                0
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$set(
                                                  _vm.autoconfirm,
                                                  "autoconfirm_delivery",
                                                  0
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                          _vm._v(
                                            " Manuelle\n                                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { staticClass: "container-box" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.autoconfirm
                                                    .autoconfirm_delivery,
                                                expression:
                                                  "autoconfirm.autoconfirm_delivery",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                !_vm.has_right_to_update_config,
                                              type: "radio",
                                            },
                                            domProps: {
                                              value: 1,
                                              checked: _vm._q(
                                                _vm.autoconfirm
                                                  .autoconfirm_delivery,
                                                1
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$set(
                                                  _vm.autoconfirm,
                                                  "autoconfirm_delivery",
                                                  1
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                          _vm._v(
                                            " Automatique\n                                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    staticClass: "d-block",
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "autoconfirm_delivery",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.autoconfirm.autoconfirm_delivery
                              ? [
                                  _c("div", { staticClass: "row mb-2" }, [
                                    _vm._m(9),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-7" }, [
                                      _c(
                                        "div",
                                        { staticClass: "row" },
                                        [
                                          _c("div", { staticClass: "col-4" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.autoconfirm
                                                      .autoconfirm_delivery_command,
                                                  expression:
                                                    "autoconfirm.autoconfirm_delivery_command",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "number",
                                                disabled:
                                                  !_vm.has_right_to_update_config,
                                              },
                                              domProps: {
                                                value:
                                                  _vm.autoconfirm
                                                    .autoconfirm_delivery_command,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.autoconfirm,
                                                    "autoconfirm_delivery_command",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                          _vm._v(" "),
                                          _vm._m(10),
                                          _vm._v(" "),
                                          _c("show-errors", {
                                            staticClass: "col-12",
                                            attrs: {
                                              errors: _vm.errors,
                                              errorKey:
                                                "autoconfirm_delivery_command",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row mb-2" }, [
                                    _vm._m(11),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-7" }, [
                                      _c(
                                        "div",
                                        { staticClass: "row" },
                                        [
                                          _c("div", { staticClass: "col-4" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.autoconfirm
                                                      .autoconfirm_delivery_until,
                                                  expression:
                                                    "autoconfirm.autoconfirm_delivery_until",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "number",
                                                disabled:
                                                  !_vm.has_right_to_update_config,
                                              },
                                              domProps: {
                                                value:
                                                  _vm.autoconfirm
                                                    .autoconfirm_delivery_until,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.autoconfirm,
                                                    "autoconfirm_delivery_until",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                          _vm._v(" "),
                                          _vm._m(12),
                                          _vm._v(" "),
                                          _c("show-errors", {
                                            staticClass: "col-12",
                                            attrs: {
                                              errors: _vm.errors,
                                              errorKey:
                                                "autoconfirm_delivery_until",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row mb-2" },
                              [
                                _vm._m(13),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7 pt-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-inline-block align-middle lead-switch",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.show_product_out,
                                            expression: "show_product_out",
                                          },
                                        ],
                                        staticClass:
                                          "switch align-self-center is-rounded",
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.show_product_out
                                          )
                                            ? _vm._i(
                                                _vm.show_product_out,
                                                null
                                              ) > -1
                                            : _vm.show_product_out,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.show_product_out,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.show_product_out =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.show_product_out = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.show_product_out = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        on: {
                                          click: function ($event) {
                                            _vm.show_product_out =
                                              _vm.has_right_to_update_config
                                                ? !_vm.show_product_out
                                                : _vm.show_product_out
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("show-errors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey:
                                      "delivery_show_out_of_stock_products",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row mb-2" },
                              [
                                _vm._m(14),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7 pt-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-inline-block align-middle lead-switch",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.show_product_disable,
                                            expression: "show_product_disable",
                                          },
                                        ],
                                        staticClass:
                                          "switch align-self-center is-rounded",
                                        attrs: {
                                          disabled:
                                            !_vm.has_right_to_update_config,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.show_product_disable
                                          )
                                            ? _vm._i(
                                                _vm.show_product_disable,
                                                null
                                              ) > -1
                                            : _vm.show_product_disable,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.show_product_disable,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.show_product_disable =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.show_product_disable =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.show_product_disable = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        on: {
                                          click: function ($event) {
                                            _vm.show_product_disable =
                                              _vm.has_right_to_update_config
                                                ? !_vm.show_product_disable
                                                : _vm.show_product_disable
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("show-errors", {
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "delivery_show_inactive_products",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4" },
                          [
                            _vm._m(15),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-3" }, [
                              _vm._m(16),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7 pt-1" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-inline-block align-middle lead-switch",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.group_payments,
                                          expression: "group_payments",
                                        },
                                      ],
                                      staticClass:
                                        "switch align-self-center is-rounded",
                                      attrs: {
                                        disabled:
                                          !_vm.has_right_to_update_config,
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.group_payments
                                        )
                                          ? _vm._i(_vm.group_payments, null) >
                                            -1
                                          : _vm.group_payments,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.group_payments,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.group_payments =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.group_payments = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.group_payments = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", {
                                      on: {
                                        click: function ($event) {
                                          _vm.group_payments =
                                            _vm.has_right_to_update_config
                                              ? !_vm.group_payments
                                              : _vm.group_payments
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.hasMealVouchersEnabled && _vm.isNoShow
                              ? _c("div", { staticClass: "row" }, [
                                  _vm._m(17),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7 pt-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-inline-block align-middle lead-switch",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.delivery_mealvouchers_enabled,
                                              expression:
                                                "delivery_mealvouchers_enabled",
                                            },
                                          ],
                                          staticClass:
                                            "switch align-self-center is-rounded",
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_config,
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.delivery_mealvouchers_enabled
                                            )
                                              ? _vm._i(
                                                  _vm.delivery_mealvouchers_enabled,
                                                  null
                                                ) > -1
                                              : _vm.delivery_mealvouchers_enabled,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.delivery_mealvouchers_enabled,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.delivery_mealvouchers_enabled =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.delivery_mealvouchers_enabled =
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                }
                                              } else {
                                                _vm.delivery_mealvouchers_enabled =
                                                  $$c
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("label", {
                                          on: {
                                            click: function ($event) {
                                              _vm.delivery_mealvouchers_enabled =
                                                _vm.has_right_to_update_config
                                                  ? !_vm.delivery_mealvouchers_enabled
                                                  : _vm.collect_mealvouchers_enabled
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _vm._m(18),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7 pt-1" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-inline-block align-middle lead-switch",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.delivery_connect_to_gv,
                                          expression: "delivery_connect_to_gv",
                                        },
                                      ],
                                      staticClass:
                                        "switch align-self-center is-rounded",
                                      attrs: {
                                        disabled:
                                          !_vm.has_right_to_update_config ||
                                          !_vm.hasGvSubscription,
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.delivery_connect_to_gv
                                        )
                                          ? _vm._i(
                                              _vm.delivery_connect_to_gv,
                                              null
                                            ) > -1
                                          : _vm.delivery_connect_to_gv,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.delivery_connect_to_gv,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.delivery_connect_to_gv =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.delivery_connect_to_gv =
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.delivery_connect_to_gv = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", {
                                      on: {
                                        click: function ($event) {
                                          _vm.delivery_connect_to_gv =
                                            _vm.has_right_to_update_config &&
                                            _vm.hasGvSubscription
                                              ? !_vm.delivery_connect_to_gv
                                              : _vm.delivery_connect_to_gv
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "border-light b-radius-20 p-4" },
                          [
                            _vm._m(19),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-3" }, [
                              _vm._m(20),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-7" }, [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.delivery_legal_information,
                                      expression: "delivery_legal_information",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    disabled: !_vm.has_right_to_update_config,
                                    maxlength: "190",
                                    rows: "3",
                                  },
                                  domProps: {
                                    value: _vm.delivery_legal_information,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.delivery_legal_information =
                                        $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("small", { staticClass: "text-muted" }, [
                                  _vm._v("Max : 190 caractères"),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("automatic-export-component", {
                      staticClass: "row mb-3",
                      attrs: {
                        module: _vm.MODULE_TYPE_CLICK_AND_COLLECT.value,
                        "services-name": "delivery_services",
                        "is-delivery": true,
                        "can-fetch-services": _vm.canFetchServices,
                      },
                      on: {
                        "services-fetched": function ($event) {
                          _vm.canFetchServices = false
                        },
                      },
                      model: {
                        value: _vm.automaticExport,
                        callback: function ($$v) {
                          _vm.automaticExport = $$v
                        },
                        expression: "automaticExport",
                      },
                    }),
                    _vm._v(" "),
                    _c("editClickAndCollectNotifications", {
                      ref: "editClickAndCollectNotifications",
                      staticClass: "mt-2",
                      attrs: {
                        "show-save-button": false,
                        "notifications-data": _vm.notificationsData,
                        "collect-type": _vm.COLLECT_TYPE_DELIVERY.value,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("input", {
                    staticClass: "btn btn-success btn-circle",
                    class: { disabled: _vm.loading },
                    attrs: {
                      type: "submit",
                      name: "submit",
                      value: "Enregistrer",
                      disabled: _vm.loading || !_vm.has_right_to_update_config,
                    },
                    on: { click: _vm.post },
                  }),
                ]),
              ]),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showAddClickAndCollectDeliveryModal
        ? _c("add-click-and-collect-delivery-modal", {
            attrs: { deliveryArea: _vm.deliveryArea },
            on: {
              close: function ($event) {
                _vm.showAddClickAndCollectDeliveryModal = false
              },
              saved: _vm.deliveryAreaSaved,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showViewDeliveryAreasOnGMapsModal
        ? _c("viewDeliveryAreasOnGMapsModal", {
            attrs: { config: _vm.$data },
            on: {
              close: function ($event) {
                _vm.showViewDeliveryAreasOnGMapsModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showEditClickAndCollectDrawDeliveryAreas
        ? _c("editClickAndCollectDrawDeliveryAreas", {
            attrs: {
              config: _vm.$data,
              editable: _vm.has_right_to_update_config,
              isTvaDisabled: _vm.isTvaDisabled,
              getDefaultDeliveryArea: _vm.getDefaultDeliveryArea,
            },
            on: {
              saved: _vm.deliveryAreaSaved,
              close: function ($event) {
                _vm.showEditClickAndCollectDrawDeliveryAreas = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [_c("h5", { staticClass: "title mt-2" }, [_vm._v("Livraison")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5" }, [
      _c("strong", [_vm._v("Livraison")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5" }, [
      _c("label", [_vm._v("Plages horaires de livraison")]),
      _vm._v(" "),
      _c("small", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Zone de livraison "), _c("small", [_vm._v("*")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Adresse du point de départ de livraison")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Paramétrage des zones de livraison")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [_vm._v("Paramètres généraux")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [
        _vm._v(" Période d'ouverture des commandes "),
        _c("small", [_vm._v("*")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [
        _vm._v(
          "\n                                        Nombre de commandes maximum par créneau de livraison\n                                        "
        ),
        _c("small", [_vm._v("*")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 pt-2 offset-1" }, [
      _c("label", [_vm._v(" Jusqu'à ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-8 mt-2" }, [
      _vm._v(
        "\n                                                commande(s) par créneau de livraison"
      ),
      _c("small", [_vm._v(" - 0 pour ne pas bloquer")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 pt-2 offset-1" }, [
      _c("label", [_vm._v(" Jusqu'à ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-8 mt-2" }, [
      _vm._v(
        "\n                                                minute(s) avant le début du créneau de livraison"
      ),
      _c("small", [_vm._v(" - 0 pour ne pas bloquer")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Afficher les produits en rupture")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Afficher les produits inactifs")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [_vm._v("Paiements")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Autoriser les paiements de groupe")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Autoriser les paiements en titres-restaurant")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [_vm._v("Liaison au module Bons Cadeaux")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", [_vm._v("Conditions de livraison")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-5 pt-1" }, [
      _c("label", [
        _vm._v("Informations relatives aux conditions de livraison"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }