var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-4" }, [
          _c("label", { staticClass: "mb-3" }, [_vm._v(_vm._s(_vm.label))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-8" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "div",
              { staticClass: "radio" },
              [
                _c(
                  "radio-button",
                  {
                    attrs: {
                      inputValue: _vm.NOTIFICATION_TYPE_SMS.value,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.modeComputed,
                      callback: function ($$v) {
                        _vm.modeComputed = $$v
                      },
                      expression: "modeComputed",
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$tl("labels.notifications.sms")) +
                        "\n                        "
                    ),
                    _vm.hasSmsUnsavedChange
                      ? _c("div", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getTooltip(
                                _vm.$tl("infos.common.unsavedChanges")
                              ),
                              expression:
                                "getTooltip($tl('infos.common.unsavedChanges'))",
                            },
                          ],
                          staticClass: "red-bubble cursor-default",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "radio-button",
                  {
                    attrs: {
                      inputValue: _vm.NOTIFICATION_TYPE_EMAIL.value,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.modeComputed,
                      callback: function ($$v) {
                        _vm.modeComputed = $$v
                      },
                      expression: "modeComputed",
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$tl("labels.notifications.mail")) +
                        "\n                        "
                    ),
                    _vm.hasMailUnsavedChange
                      ? _c("div", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getTooltip(
                                _vm.$tl("infos.common.unsavedChanges")
                              ),
                              expression:
                                "getTooltip($tl('infos.common.unsavedChanges'))",
                            },
                          ],
                          staticClass: "red-bubble cursor-default",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.canBeDisabled
                  ? _c(
                      "radio-button",
                      {
                        attrs: {
                          inputValue: _vm.NOTIFICATION_TYPE_DISABLED.value,
                          label: _vm.$tl("labels.notifications.disabled"),
                          disabled: _vm.disabled,
                        },
                        model: {
                          value: _vm.modeComputed,
                          callback: function ($$v) {
                            _vm.modeComputed = $$v
                          },
                          expression: "modeComputed",
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$tl("labels.notifications.disabled")) +
                            "\n                    "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.hasMailPreview &&
            _vm.modeComputed === _vm.NOTIFICATION_TYPE_EMAIL.value
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-outline-secondary btn-circle",
                    staticStyle: { "margin-top": "-4px" },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.showPreviewEmailModal = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$tl("labels.form.actions.preview")) +
                        "\n                "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasSmsPreview &&
            _vm.modeComputed === _vm.NOTIFICATION_TYPE_SMS.value
              ? _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.doesSmsContainEmojis
                          ? _vm.getTooltip(
                              _vm.$tl("errors.common.sms.emojisNotAllowed")
                            )
                          : undefined,
                        expression:
                          "doesSmsContainEmojis ? getTooltip($tl('errors.common.sms.emojisNotAllowed')) : undefined",
                      },
                    ],
                    staticClass: "btn btn-sm btn-outline-secondary btn-circle",
                    staticStyle: { "margin-top": "-4px" },
                    attrs: {
                      type: "button",
                      disabled: _vm.doesSmsContainEmojis,
                    },
                    on: {
                      click: function ($event) {
                        _vm.showPreviewSmsModal = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$tl("labels.form.actions.preview")) +
                        "\n                "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-1" }, [
        _c(
          "div",
          { staticClass: "col-md-8 offset-md-4" },
          [
            _c("editor-with-merge-tags", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.modeComputed === _vm.NOTIFICATION_TYPE_EMAIL.value,
                  expression: "modeComputed === NOTIFICATION_TYPE_EMAIL.value",
                },
              ],
              attrs: {
                disabled: _vm.disabled || !_vm.isMailContentEditable,
                "content-locale": _vm.contentLocale,
                "merge-tags": _vm.mailsMergeTags,
              },
              model: {
                value: _vm.mailContentComputed,
                callback: function ($$v) {
                  _vm.mailContentComputed = $$v
                },
                expression: "mailContentComputed",
              },
            }),
            _vm._v(" "),
            _c(
              "editor-with-merge-tags",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.modeComputed === _vm.NOTIFICATION_TYPE_SMS.value,
                    expression: "modeComputed === NOTIFICATION_TYPE_SMS.value",
                  },
                ],
                attrs: {
                  "strip-tags": "",
                  maxlength: _vm.maxCreditsPerSms * _vm.nbCharsPerCredits,
                  disabled: _vm.disabled || !_vm.isSmsContentEditable,
                  "content-locale": _vm.contentLocale,
                  "merge-tags": _vm.smsMergeTags,
                },
                model: {
                  value: _vm.smsContentComputed,
                  callback: function ($$v) {
                    _vm.smsContentComputed = $$v
                  },
                  expression: "smsContentComputed",
                },
              },
              [
                _vm.doesSmsContainEmojis
                  ? _c("div", { staticClass: "text-danger font-weight-bold" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$tl("errors.common.sms.emojisNotAllowed")
                          ) +
                          "\n                "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.nbCreditsNeeded > 3
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$tl("errors.common.sms.exceedMaxLength")) +
                          "\n                "
                      ),
                    ])
                  : _c(
                      "div",
                      {
                        class: {
                          "text-success": _vm.nbCreditsNeeded < 2,
                          "text-warning": _vm.nbCreditsNeeded === 2,
                          "text-danger": _vm.nbCreditsNeeded > 2,
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t("labels.smsLengthApprox", {
                                smsLength: _vm.smsLength,
                              })
                            ) +
                            " (" +
                            _vm._s(
                              _vm.$tc("labels.smsCost", _vm.nbCreditsNeeded)
                            ) +
                            ")\n                "
                        ),
                      ]
                    ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.errorKey !== undefined
        ? _c("ShowErrors", {
            staticClass: "d-block",
            attrs: { errors: _vm.formErrors, errorKey: `${_vm.errorKey}_mode` },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.errorKey !== undefined
        ? _c("ShowErrors", {
            staticClass: "d-block",
            attrs: {
              errors: _vm.formErrors,
              errorKey: `${_vm.errorKey}_sms_content`,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.errorKey !== undefined
        ? _c("ShowErrors", {
            staticClass: "d-block",
            attrs: {
              errors: _vm.formErrors,
              errorKey: `${_vm.errorKey}_mail_content`,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasMailPreview && _vm.showPreviewEmailModal
        ? _c("preview-email-modal", {
            attrs: {
              saas: _vm.saas,
              mailType: _vm.mailPreviewId,
              locale: _vm.contentLocale,
              "custom-content": _vm.mailContentComputed,
            },
            on: {
              close: function ($event) {
                _vm.showPreviewEmailModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasSmsPreview && _vm.showPreviewSmsModal && !_vm.doesSmsContainEmojis
        ? _c("preview-sms-modal", {
            attrs: {
              saas: _vm.saas,
              smsType: _vm.smsPreviewId,
              locale: _vm.contentLocale,
              "custom-content": _vm.smsContentComputed,
            },
            on: {
              close: function ($event) {
                _vm.showPreviewSmsModal = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSmsNotActivatedAlertModal
        ? _c("sms-not-activated-alert-modal", {
            on: { close: _vm.onSmsNotActivatedAlertModalClosed },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }