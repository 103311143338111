var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(
        _vm._s(
          _vm.$tl(
            "labels.booking.waitingList.buttons.remindCustomerWaitingList"
          )
        )
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.loading
          ? _c("LoaderComponent")
          : _c("div", { staticClass: "w-100 radio" }, [
              _c(
                "label",
                {
                  staticClass:
                    "container-box border-light b-radius-20 p-4 mb-3 d-block w-100",
                  staticStyle: { "padding-left": "50px !important" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.notifyAllWaitings,
                        expression: "notifyAllWaitings",
                      },
                    ],
                    attrs: { type: "radio", name: "notify_all_waitings" },
                    domProps: {
                      value: true,
                      checked: _vm._q(_vm.notifyAllWaitings, true),
                    },
                    on: {
                      change: function ($event) {
                        _vm.notifyAllWaitings = true
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "checkmark",
                    staticStyle: { top: "24px", left: "20px" },
                  }),
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.waitingList.buttons.remindAllCustomerOnWaitList"
                        )
                      ) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "container-box border-light b-radius-20 p-4 d-block w-100",
                  staticStyle: { "padding-left": "50px !important" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.notifyAllWaitings,
                        expression: "notifyAllWaitings",
                      },
                    ],
                    attrs: { type: "radio", name: "notify_all_waitings" },
                    domProps: {
                      value: false,
                      checked: _vm._q(_vm.notifyAllWaitings, false),
                    },
                    on: {
                      change: function ($event) {
                        _vm.notifyAllWaitings = false
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "checkmark",
                    staticStyle: { top: "24px", left: "20px" },
                  }),
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.waitingList.buttons.remindOnlyCustomerNoMail"
                        )
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-success btn-circle ml-2",
            attrs: { disabled: _vm.notifyAllWaitings === null },
            on: { click: _vm.post },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.validate")) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }