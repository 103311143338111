var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(_vm._s(_vm.$tl("labels.routes.rwg"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.isLoading
          ? _c("loader-component")
          : _c(
              "form",
              {
                staticClass: "row",
                attrs: { id: "rwg-opt-out-form" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.optOut.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("switch-input-component", {
                      attrs: { "is-form-grid": false, required: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$tl(
                                      "questions.reserveWithGoogle.optOut",
                                      _vm.restaurantId
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.isChecked,
                        callback: function ($$v) {
                          _vm.isChecked = $$v
                        },
                        expression: "isChecked",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn btn-sm btn-secondary btn-circle",
            attrs: { type: "button", disabled: _vm.isLoading },
            on: { click: _vm.close },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.close", _vm.restaurantId)) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-success btn-circle ml-2",
            attrs: {
              type: "submit",
              form: "rwg-opt-out-form",
              disabled: _vm.isLoading,
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$tl("labels.form.actions.accept", _vm.restaurantId)
                ) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }