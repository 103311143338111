export default {
    setIsReservationListCollapsed({ commit }, isReservationListCollapsed) {
        commit("setIsReservationListCollapsed", isReservationListCollapsed);
    },
    setIsGroupsAndPrintTabCollapsed({ commit }, isGroupsAndPrintTabCollapsed) {
        commit("setIsGroupsAndPrintTabCollapsed", isGroupsAndPrintTabCollapsed);
    },
    setPinPercentOfMenuSold({ commit }, pinPercentOfMenuSold) {
        commit("setPinPercentOfMenuSold", pinPercentOfMenuSold);
    },
    setPinPercentOfMenuOptionSold({ commit }, pinPercentOfMenuOptionSold) {
        commit("setPinPercentOfMenuOptionSold", pinPercentOfMenuOptionSold);
    },
    setPinPercentOfGeneralOptionSold({ commit }, pinPercentOfGeneralOptionSold) {
        commit("setPinPercentOfGeneralOptionSold", pinPercentOfGeneralOptionSold);
    },
    setPinPax({ commit }, pinPax) {
        commit("setPinPax", pinPax);
    },
    setPinEvolutionNbResa({ commit }, pinEvolutionNbResa) {
        commit("setPinEvolutionNbResa", pinEvolutionNbResa);
    },
    setPinCaPerMenu({ commit }, pinCaPerMenu) {
        commit("setPinCaPerMenu", pinCaPerMenu);
    },
    setPinBookingsPeriod({ commit }, pinBookingsPeriod) {
        commit("setPinBookingsPeriod", pinBookingsPeriod);
    },
    setPinClientLoyal({ commit }, pinClientLoyal) {
        commit("setPinClientLoyal", pinClientLoyal);
    },
    setPinCaPerGeneralOption({ commit }, pinCaPerGeneralOption) {
        commit("setPinCaPerGeneralOption", pinCaPerGeneralOption);
    },
    setPinCaPerMenuOption({ commit }, pinCaPerMenuOption) {
        commit("setPinCaPerMenuOption", pinCaPerMenuOption);
    },
};
