<template>
    <div>
        <select-licence
            v-if="step === 0"
            :onlyOneModule="true"
            class="px-3"
            :modules-enabled="modulesEnabled"
            :preselected-modules="modules"
            :training="training"
            @selected="
                modules = $event.selectedModules;
                plan = $event.plan;
                (training = $event.training), (step = 1);
            " />
        <pay-licences
            class="px-3"
            @back="step = 0"
            v-else-if="step === 1"
            :modules="modules"
            :training="training"
            :redirect="{ name: 'subscription' }"
            :plan="plan" />
    </div>
</template>

<script>
import SelectLicence from "../../../components/Modules/Licences/Select";
import PayLicences from "../../../components/Modules/Licences/Pay";

export default {
    data() {
        return {
            training: false,
            modules: [],
            plan: "yearly",
            step: 0,
        };
    },
    computed: {
        modulesEnabled() {
            if (this.$route.query.modules) {
                const validatedModules = this.$route.query.modules.split(",").filter((module) => {
                    return this.$store.getters["modules/getModulesLabels"].includes(module);
                });

                return validatedModules.length > 0 ? validatedModules : null;
            }

            return null;
        },
    },
    components: {
        SelectLicence,
        PayLicences,
    },
};
</script>
