<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Détails de la réservation</h5>
        <div slot="body">
            <invalid-reservation :reservation="reservation" />
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">Fermer</button>
        </div>
    </modal>
</template>

<script>
import InvalidReservation from "../../reservations/InvalidReservation.vue";

export default {
    props: {
        reservation: {
            required: true,
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        InvalidReservation,
    },
};
</script>
