var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading > 0
        ? _c("LoaderComponent")
        : _c(
            "div",
            [
              _vm.product.type === "single"
                ? _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-5" }, [
                              _c("label", [_vm._v("Type de produit")]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-7" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("div", { staticClass: "radio" }, [
                                    _c(
                                      "label",
                                      { staticClass: "container-box" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.product.single_type,
                                              expression: "product.single_type",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            value: "single",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.product.single_type,
                                              "single"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.product,
                                                "single_type",
                                                "single"
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                        _vm._v(
                                          " Produit simple\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 mt-1" }, [
                                  _c("div", { staticClass: "radio" }, [
                                    _c(
                                      "label",
                                      { staticClass: "container-box" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.product.single_type,
                                              expression: "product.single_type",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            value: "menu",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.product.single_type,
                                              "menu"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.product,
                                                "single_type",
                                                "menu"
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                        _vm._v(
                                          " Produit de formule\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 mt-1" }, [
                                  _c("div", { staticClass: "radio" }, [
                                    _c(
                                      "label",
                                      { staticClass: "container-box" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.product.single_type,
                                              expression: "product.single_type",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            value: "both",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.product.single_type,
                                              "both"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.product,
                                                "single_type",
                                                "both"
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                        _vm._v(
                                          " Les deux\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("div", { staticClass: "col-md-5 pt-1" }, [
                        _c("label", [
                          _vm._v("Nom "),
                          _c("small", [_vm._v("*")]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.product.name,
                                expression: "product.name",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", maxlength: "70" },
                            domProps: { value: _vm.product.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.product,
                                  "name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v(
                              "Actuel : " +
                                _vm._s(
                                  _vm.product.name ? _vm.product.name.length : 0
                                ) +
                                " caractère" +
                                _vm._s(
                                  _vm.product.name &&
                                    _vm.product.name.length > 1
                                    ? "s"
                                    : ""
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("Max : 70 caractères"),
                          ]),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "d-block",
                            attrs: { errors: _vm.errors, errorKey: "name" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("div", { staticClass: "col-md-5 pt-1" }, [
                        _c("label", [_vm._v("Description")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.product.description,
                                expression: "product.description",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { rows: "3" },
                            domProps: { value: _vm.product.description },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.product,
                                  "description",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "description",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.product.type !== "menu"
                      ? _c("div", { staticClass: "row mb-2 badge-allergens" }, [
                          _c("div", { staticClass: "col-md-5 pt-1" }, [
                            _c("label", [_vm._v("Allergènes")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-7" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-inline-block align-middle lead-switch pt-1 mb-2",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.product.enable_allergens,
                                        expression: "product.enable_allergens",
                                      },
                                    ],
                                    staticClass:
                                      "switch align-self-center is-rounded",
                                    attrs: {
                                      type: "checkbox",
                                      "true-value": true,
                                      "false-value": false,
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.product.enable_allergens
                                      )
                                        ? _vm._i(
                                            _vm.product.enable_allergens,
                                            null
                                          ) > -1
                                        : _vm.product.enable_allergens,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.product.enable_allergens,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.product,
                                                "enable_allergens",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.product,
                                                "enable_allergens",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.product,
                                            "enable_allergens",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", {
                                    on: {
                                      click: function ($event) {
                                        _vm.product.enable_allergens =
                                          !_vm.product.enable_allergens
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.product.enable_allergens
                                ? [
                                    _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-secondary p-1 pl-2 pr-2 m-1",
                                          class: [
                                            _vm.isAllergenActive("Gluten")
                                              ? "active-badge"
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAllergen(
                                                "Gluten"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Gluten\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-secondary p-1 pl-2 pr-2 m-1",
                                          class: [
                                            _vm.isAllergenActive("Crustacé")
                                              ? "active-badge"
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAllergen(
                                                "Crustacé"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Crustacé\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-secondary p-1 pl-2 pr-2 m-1",
                                          class: [
                                            _vm.isAllergenActive("Oeuf")
                                              ? "active-badge"
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAllergen("Oeuf")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Oeuf\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-secondary p-1 pl-2 pr-2 m-1",
                                          class: [
                                            _vm.isAllergenActive("Poisson")
                                              ? "active-badge"
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAllergen(
                                                "Poisson"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Poisson\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-secondary p-1 pl-2 pr-2 m-1",
                                          class: [
                                            _vm.isAllergenActive("Arachide")
                                              ? "active-badge"
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAllergen(
                                                "Arachide"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Arachide\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-secondary p-1 pl-2 pr-2 m-1",
                                          class: [
                                            _vm.isAllergenActive("Soja")
                                              ? "active-badge"
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAllergen("Soja")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Soja\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-secondary p-1 pl-2 pr-2 m-1",
                                          class: [
                                            _vm.isAllergenActive("Lait")
                                              ? "active-badge"
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAllergen("Lait")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Lait\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-secondary p-1 pl-2 pr-2 m-1",
                                          class: [
                                            _vm.isAllergenActive(
                                              "Fruit à coque"
                                            )
                                              ? "active-badge"
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAllergen(
                                                "Fruit à coque"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Fruit à coque\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-secondary p-1 pl-2 pr-2 m-1",
                                          class: [
                                            _vm.isAllergenActive("Céleri")
                                              ? "active-badge"
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAllergen(
                                                "Céleri"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Céleri\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-secondary p-1 pl-2 pr-2 m-1",
                                          class: [
                                            _vm.isAllergenActive("Moutarde")
                                              ? "active-badge"
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAllergen(
                                                "Moutarde"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Moutarde\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-secondary p-1 pl-2 pr-2 m-1",
                                          class: [
                                            _vm.isAllergenActive("Sésame")
                                              ? "active-badge"
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAllergen(
                                                "Sésame"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Sésame\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-secondary p-1 pl-2 pr-2 m-1",
                                          class: [
                                            _vm.isAllergenActive("Sulfite")
                                              ? "active-badge"
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAllergen(
                                                "Sulfite"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Sulfite\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-secondary p-1 pl-2 pr-2 m-1",
                                          class: [
                                            _vm.isAllergenActive("Lupin")
                                              ? "active-badge"
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAllergen("Lupin")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Lupin\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-secondary p-1 pl-2 pr-2 m-1",
                                          class: [
                                            _vm.isAllergenActive("Mollusque")
                                              ? "active-badge"
                                              : "",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleAllergen(
                                                "Mollusque"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Mollusque\n                                    "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.product.single_type !== "menu"
                      ? _c("div", { staticClass: "row mb-2" }, [
                          _c("div", { staticClass: "col-md-5 pt-1" }, [
                            _c("label", [_vm._v("Catégorie *")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-7" },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.product.cc_products_category_id,
                                      expression:
                                        "product.cc_products_category_id",
                                    },
                                  ],
                                  staticClass: "custom-select",
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.product,
                                        "cc_products_category_id",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { domProps: { value: null } }, [
                                    _vm._v("--"),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.products_categories,
                                    function (category) {
                                      return _c(
                                        "option",
                                        {
                                          key: category.id,
                                          domProps: { value: category.id },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(category.name) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c("option", { domProps: { value: -1 } }, [
                                    _vm._v("Personnalisée"),
                                  ]),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.product.cc_products_category_id === -1
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.custom_category_name,
                                        expression: "custom_category_name",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.custom_category_name,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.custom_category_name =
                                          $event.target.value
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("ShowErrors", {
                                attrs: {
                                  errors: _vm.errors,
                                  errorKey: "cc_products_category_id",
                                },
                              }),
                              _vm._v(" "),
                              _c("ShowErrors", {
                                attrs: {
                                  errors: _vm.errors,
                                  errorKey: "custom_category_name",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.product.single_type !== "menu"
                      ? _c("div", { staticClass: "row mb-2" }, [
                          _c("div", { staticClass: "col-md-5 pt-1" }, [
                            _c("label", { staticClass: "mb-0" }, [
                              _vm._v("Image "),
                              _c("small", [_vm._v("(recommandé)")]),
                            ]),
                            _c("br"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-7" },
                            [
                              _c("image-uploader", {
                                attrs: {
                                  "public-image-uri":
                                    _vm.product.img !== null
                                      ? _vm.product.img.public_uri
                                      : null,
                                  "image-path":
                                    _vm.product.img !== null
                                      ? _vm.product.img.path
                                      : null,
                                  "upload-uri": _vm.imgPostUri,
                                  "delete-uri": _vm.imgPostUri,
                                  maxSize: 10,
                                },
                                on: {
                                  "image-uploaded": function ($event) {
                                    _vm.product.img = $event
                                  },
                                  "image-deleted": function ($event) {
                                    _vm.product.img = null
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("div", { staticClass: "col-md-5 pt-1" }, [
                        _c("label", [
                          _vm._v("Prix de vente TTC "),
                          _c("small", [_vm._v("*")]),
                        ]),
                        _c("br"),
                        _vm._v(" "),
                        _vm.product.single_type == "menu" && !_vm.isTvaDisabled
                          ? _c("small", { staticClass: "text-warning" }, [
                              _vm._v(
                                "Dans le cas d'un produit de formule, le prix de vente TTC est le prix théorique du produit qui sera utilisé pour\n                                le calcul de la ventilation de TVA. Ce prix ne sera jamais affiché pour le client."
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-7" }, [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.product.price,
                                    expression: "product.price",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "number" },
                                domProps: { value: _vm.product.price },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.product,
                                      "price",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-8 mt-1" }, [
                              _vm._v("euros"),
                            ]),
                            _vm._v(" "),
                            _c("ShowErrors", {
                              staticClass: "col-12",
                              attrs: { errors: _vm.errors, errorKey: "price" },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.product.single_type !== "menu"
                      ? _c("div", { staticClass: "row mb-2" }, [
                          _c("div", { staticClass: "col-md-5 pt-1" }, [
                            _c("label", [
                              _vm._v("Prix de vente TTC (promotionnel) "),
                              _c("small", [_vm._v("*")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-7" }, [
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-inline-block align-middle lead-switch",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.product.enable_price_offer,
                                            expression:
                                              "product.enable_price_offer",
                                          },
                                        ],
                                        staticClass:
                                          "switch align-self-center is-rounded",
                                        attrs: {
                                          type: "checkbox",
                                          "true-value": true,
                                          "false-value": false,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.product.enable_price_offer
                                          )
                                            ? _vm._i(
                                                _vm.product.enable_price_offer,
                                                null
                                              ) > -1
                                            : _vm.product.enable_price_offer,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.product.enable_price_offer,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.product,
                                                    "enable_price_offer",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.product,
                                                    "enable_price_offer",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.product,
                                                "enable_price_offer",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        on: {
                                          click: function ($event) {
                                            _vm.product.enable_price_offer =
                                              !_vm.product.enable_price_offer
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.product.enable_price_offer
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 mt-1" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-md-4" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.product
                                                            .price_offer,
                                                        expression:
                                                          "product.price_offer",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: { type: "number" },
                                                    domProps: {
                                                      value:
                                                        _vm.product.price_offer,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.product,
                                                          "price_offer",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-md-8 mt-1",
                                                },
                                                [_vm._v("euros")]
                                              ),
                                              _vm._v(" "),
                                              _c("ShowErrors", {
                                                staticClass: "col-12",
                                                attrs: {
                                                  errors: _vm.errors,
                                                  errorKey: "price_offer",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-12" }, [
                                        _c(
                                          "div",
                                          { staticClass: "date-resa-cal" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "d-block" },
                                              [_vm._v("à partir du")]
                                            ),
                                            _vm._v(" "),
                                            _c("datepicker", {
                                              staticClass:
                                                "w-auto d-inline-block",
                                              attrs: {
                                                format: "dd/MM/yyyy",
                                                "calendar-class":
                                                  "positition-relative mt-2",
                                                "disabled-dates":
                                                  _vm.disabledDates,
                                                "monday-first": true,
                                                language: _vm.fr,
                                              },
                                              model: {
                                                value:
                                                  _vm.product.offer_starts_on,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.product,
                                                    "offer_starts_on",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "product.offer_starts_on",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("feather", {
                                              staticClass:
                                                "text-danger pointer",
                                              attrs: { type: "x" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.product.offer_starts_on =
                                                    null
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "d-block" },
                                              [_vm._v(" jusqu'au ")]
                                            ),
                                            _vm._v(" "),
                                            _c("datepicker", {
                                              staticClass:
                                                "w-auto d-inline-block",
                                              attrs: {
                                                format: "dd/MM/yyyy",
                                                "calendar-class":
                                                  "positition-relative mt-2",
                                                "disabled-dates":
                                                  _vm.disabledDates,
                                                "monday-first": true,
                                                language: _vm.fr,
                                              },
                                              model: {
                                                value:
                                                  _vm.product.offer_ends_on,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.product,
                                                    "offer_ends_on",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "product.offer_ends_on",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("feather", {
                                              staticClass:
                                                "text-danger pointer",
                                              attrs: { type: "x" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.product.offer_ends_on =
                                                    null
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        staticClass: "col-12",
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "offer_starts_on",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        staticClass: "col-12",
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "offer_ends_on",
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  staticClass: "col-12",
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "enable_price_offer",
                                  },
                                }),
                              ],
                              2
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isTvaDisabled
                      ? _c("div", { staticClass: "row mb-2" }, [
                          _c("div", { staticClass: "col-md-5 pt-1" }, [
                            _c("label", { staticClass: "mb-0" }, [
                              _vm._v("Taux de TVA "),
                              _c("small", [_vm._v("*")]),
                            ]),
                            _c("br"),
                            _vm._v(" "),
                            _c("small", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://www.service-public.fr/professionnels-entreprises/vosdroits/F23567",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c("feather", {
                                    staticClass: "text-warning mr-1",
                                    attrs: { type: "info" },
                                  }),
                                  _vm._v("Législation TVA"),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-7" }, [
                            _vm.product.type === "single"
                              ? _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.product.tax_rate,
                                              expression: "product.tax_rate",
                                            },
                                          ],
                                          staticClass: "custom-select mb-0",
                                          attrs: {
                                            placeholder:
                                              "Veuillez sélectionner un taux",
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.product,
                                                "tax_rate",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { domProps: { value: null } },
                                            [_vm._v("--")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { domProps: { value: 5.5 } },
                                            [_vm._v("5,5")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { domProps: { value: 10 } },
                                            [_vm._v("10")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { domProps: { value: 20 } },
                                            [_vm._v("20")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-8 mt-1" },
                                      [_vm._v("%")]
                                    ),
                                    _vm._v(" "),
                                    _c("ShowErrors", {
                                      staticClass: "col-12",
                                      attrs: {
                                        errors: _vm.errors,
                                        errorKey: "tax_rate",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  { staticClass: "text-warning d-block mt-1" },
                                  [
                                    _vm._v(
                                      "Le taux de TVA de la formule sera automatiquement calculé en fonction des produits qui la composent."
                                    ),
                                  ]
                                ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mb-2" }, [
                      _c("div", { staticClass: "col-md-5 pt-1" }, [
                        _c("label", [
                          _vm._v("Stock "),
                          _c("small", [_vm._v("*")]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-7" }, [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("div", { staticClass: "col-md-4" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.product.stock_type,
                                      expression: "product.stock_type",
                                    },
                                  ],
                                  staticClass: "custom-select mb-0",
                                  class: {
                                    disabled: _vm.product_stock_nolimit,
                                  },
                                  staticStyle: { width: "initial" },
                                  attrs: {
                                    disabled: _vm.product_stock_nolimit,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.product,
                                        "stock_type",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      domProps: {
                                        value: _vm.DAILY_STOCK.value,
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.DAILY_STOCK.label))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    {
                                      domProps: {
                                        value: _vm.GENERAL_STOCK.value,
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.GENERAL_STOCK.label))]
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-4" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.product.stock,
                                    expression: "product.stock",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  disabled: _vm.product_stock_nolimit,
                                  type: "number",
                                },
                                domProps: { value: _vm.product.stock },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.product,
                                      "stock",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-4 mt-1" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "container-box",
                                  staticStyle: { width: "initial" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.product_stock_nolimit,
                                        expression: "product_stock_nolimit",
                                      },
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.product_stock_nolimit
                                      )
                                        ? _vm._i(
                                            _vm.product_stock_nolimit,
                                            null
                                          ) > -1
                                        : _vm.product_stock_nolimit,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.product_stock_nolimit,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.product_stock_nolimit =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.product_stock_nolimit = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.product_stock_nolimit = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" }),
                                  _vm._v(
                                    " Illimité\n                                    "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("ShowErrors", {
                              staticClass: "col-12",
                              attrs: { errors: _vm.errors, errorKey: "stock" },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.config.is_click_and_collect_enabled
                      ? _c("div", { staticClass: "row mb-2" }, [
                          _c("div", { staticClass: "col-md-5 pt-1" }, [
                            _c("label", [
                              _vm._v("Disponible en point de retrait "),
                              _c("small", [_vm._v("*")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-7" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-inline-block align-middle lead-switch",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.product
                                          .is_enabled_for_click_and_collect,
                                      expression:
                                        "product.is_enabled_for_click_and_collect",
                                    },
                                  ],
                                  staticClass:
                                    "switch align-self-center is-rounded",
                                  attrs: {
                                    type: "checkbox",
                                    "true-value": true,
                                    "false-value": false,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.product
                                        .is_enabled_for_click_and_collect
                                    )
                                      ? _vm._i(
                                          _vm.product
                                            .is_enabled_for_click_and_collect,
                                          null
                                        ) > -1
                                      : _vm.product
                                          .is_enabled_for_click_and_collect,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.product
                                            .is_enabled_for_click_and_collect,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.product,
                                              "is_enabled_for_click_and_collect",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.product,
                                              "is_enabled_for_click_and_collect",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.product,
                                          "is_enabled_for_click_and_collect",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", {
                                  on: {
                                    click: function ($event) {
                                      _vm.product.is_enabled_for_click_and_collect =
                                        !_vm.product
                                          .is_enabled_for_click_and_collect
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.config.is_delivery_enabled
                      ? _c("div", { staticClass: "row mb-2" }, [
                          _c("div", { staticClass: "col-md-5 pt-1" }, [
                            _c("label", [
                              _vm._v("Disponible à la livraison "),
                              _c("small", [_vm._v("*")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-7" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-inline-block align-middle lead-switch",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.product.is_enabled_for_delivery,
                                      expression:
                                        "product.is_enabled_for_delivery",
                                    },
                                  ],
                                  staticClass:
                                    "switch align-self-center is-rounded",
                                  attrs: {
                                    type: "checkbox",
                                    "true-value": true,
                                    "false-value": false,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.product.is_enabled_for_delivery
                                    )
                                      ? _vm._i(
                                          _vm.product.is_enabled_for_delivery,
                                          null
                                        ) > -1
                                      : _vm.product.is_enabled_for_delivery,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.product.is_enabled_for_delivery,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.product,
                                              "is_enabled_for_delivery",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.product,
                                              "is_enabled_for_delivery",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.product,
                                          "is_enabled_for_delivery",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", {
                                  on: {
                                    click: function ($event) {
                                      _vm.product.is_enabled_for_delivery =
                                        !_vm.product.is_enabled_for_delivery
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.config.is_dispatch_enabled
                      ? _c("div", { staticClass: "row mb-2" }, [
                          _c("div", { staticClass: "col-md-5 pt-1" }, [
                            _c("label", [
                              _vm._v("Disponible à l'expédition "),
                              _c("small", [_vm._v("*")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-7" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-inline-block align-middle lead-switch",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.product.is_enabled_for_dispatch,
                                      expression:
                                        "product.is_enabled_for_dispatch",
                                    },
                                  ],
                                  staticClass:
                                    "switch align-self-center is-rounded",
                                  attrs: {
                                    type: "checkbox",
                                    "true-value": true,
                                    "false-value": false,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.product.is_enabled_for_dispatch
                                    )
                                      ? _vm._i(
                                          _vm.product.is_enabled_for_dispatch,
                                          null
                                        ) > -1
                                      : _vm.product.is_enabled_for_dispatch,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.product.is_enabled_for_dispatch,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.product,
                                              "is_enabled_for_dispatch",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.product,
                                              "is_enabled_for_dispatch",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.product,
                                          "is_enabled_for_dispatch",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", {
                                  on: {
                                    click: function ($event) {
                                      _vm.product.is_enabled_for_dispatch =
                                        !_vm.product.is_enabled_for_dispatch
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.product.is_enabled_for_dispatch
                      ? _c("div", { staticClass: "row mb-2" }, [
                          _c("div", { staticClass: "col-md-5" }, [
                            _c("label", [
                              _vm._v("Poids du produit "),
                              _c("small", [_vm._v("*")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-7" }, [
                            _vm.product.type === "single"
                              ? _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _c("div", { staticClass: "col-md-4" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.product.weight,
                                            expression: "product.weight",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "number" },
                                        domProps: { value: _vm.product.weight },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.product,
                                              "weight",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-8 mt-1" },
                                      [_vm._v("grammes")]
                                    ),
                                    _vm._v(" "),
                                    _c("ShowErrors", {
                                      staticClass: "col-12",
                                      attrs: {
                                        errors: _vm.errors,
                                        errorKey: "weight",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  { staticClass: "text-warning d-block mt-1" },
                                  [
                                    _vm._v(
                                      "Le poids de la formule sera automatiquement calculé en fonction du poids des produits qui la composent."
                                    ),
                                  ]
                                ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.product.type === "single" &&
                    _vm.hasMealVouchersEnabled &&
                    _vm.isNoShow
                      ? _c("div", { staticClass: "row mb-2" }, [
                          _c("div", { staticClass: "col-md-5" }, [
                            _c("label", [
                              _vm._v("Payable en titres-restaurant "),
                              _c("small", [_vm._v("*")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-7" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-inline-block align-middle lead-switch",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.product.payable_with_mealvouchers,
                                        expression:
                                          "product.payable_with_mealvouchers",
                                      },
                                    ],
                                    staticClass:
                                      "switch align-self-center is-rounded",
                                    attrs: {
                                      type: "checkbox",
                                      "true-value": true,
                                      "false-value": false,
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.product.payable_with_mealvouchers
                                      )
                                        ? _vm._i(
                                            _vm.product
                                              .payable_with_mealvouchers,
                                            null
                                          ) > -1
                                        : _vm.product.payable_with_mealvouchers,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.product
                                              .payable_with_mealvouchers,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.product,
                                                "payable_with_mealvouchers",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.product,
                                                "payable_with_mealvouchers",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.product,
                                            "payable_with_mealvouchers",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", {
                                    on: {
                                      click: function ($event) {
                                        _vm.product.payable_with_mealvouchers =
                                          !_vm.product.payable_with_mealvouchers
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("ShowErrors", {
                                staticClass: "d-block",
                                attrs: {
                                  errors: _vm.errors,
                                  errorKey: "payable_with_mealvouchers",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.product.single_type !== "menu" &&
              _vm.product.is_enabled_for_click_and_collect &&
              _vm.config.is_click_and_collect_enabled
                ? _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  _vm.showCollectParams = !_vm.showCollectParams
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "col-12" }, [
                                _c("strong", [_vm._v("Options de retrait")]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.showCollectParams
                            ? [
                                _c("div", { staticClass: "row mt-3 mb-2" }, [
                                  _c("div", { staticClass: "col-md-5" }, [
                                    _c("label", [
                                      _vm._v(
                                        "Délai de préparation du produit "
                                      ),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c("div", { staticClass: "col-12" }, [
                                          _c("div", { staticClass: "radio" }, [
                                            _c(
                                              "label",
                                              { staticClass: "container-box" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.productPreparationTime,
                                                      expression:
                                                        "productPreparationTime",
                                                    },
                                                  ],
                                                  attrs: { type: "radio" },
                                                  domProps: {
                                                    value: false,
                                                    checked: _vm._q(
                                                      _vm.productPreparationTime,
                                                      false
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.productPreparationTime = false
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                                _vm._v(
                                                  " Utiliser les paramètres par défaut\n                                            "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 d-inline radio mt-1",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "container-box d-inline-block pt-0 mt-2",
                                                staticStyle: {
                                                  width: "initial",
                                                },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.productPreparationTime,
                                                      expression:
                                                        "productPreparationTime",
                                                    },
                                                  ],
                                                  attrs: { type: "radio" },
                                                  domProps: {
                                                    value: true,
                                                    checked: _vm._q(
                                                      _vm.productPreparationTime,
                                                      true
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.productPreparationTime = true
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                                _vm._v(
                                                  " Personnaliser\n                                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.productPreparationTime
                                              ? [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.product
                                                            .preparation_time,
                                                        expression:
                                                          "product.preparation_time",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-control d-inline-block ml-2 mr-2",
                                                    staticStyle: {
                                                      width: "120px",
                                                    },
                                                    attrs: { type: "number" },
                                                    domProps: {
                                                      value:
                                                        _vm.product
                                                          .preparation_time,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.product,
                                                          "preparation_time",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.product
                                                              .preparation_time_unit,
                                                          expression:
                                                            "product.preparation_time_unit",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "custom-select mb-0",
                                                      staticStyle: {
                                                        width: "initial",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.$set(
                                                            _vm.product,
                                                            "preparation_time_unit",
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "mins",
                                                          },
                                                        },
                                                        [_vm._v("Minutes")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "hours",
                                                          },
                                                        },
                                                        [_vm._v("Heures")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "days",
                                                          },
                                                        },
                                                        [_vm._v("Jours")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c("ShowErrors", {
                                          staticClass: "col-12",
                                          attrs: {
                                            errors: _vm.errors,
                                            errorKey: "preparation_time",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("ShowErrors", {
                                          staticClass: "col-12",
                                          attrs: {
                                            errors: _vm.errors,
                                            errorKey: "preparation_time_unit",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mt-3 mb-2" }, [
                                  _c("div", { staticClass: "col-md-5" }, [
                                    _c("label", [
                                      _vm._v(
                                        "Dates de disponibilité du produit "
                                      ),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-12" }, [
                                        _c("div", { staticClass: "radio" }, [
                                          _c(
                                            "label",
                                            { staticClass: "container-box" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.productAvailabilityDate,
                                                    expression:
                                                      "productAvailabilityDate",
                                                  },
                                                ],
                                                attrs: { type: "radio" },
                                                domProps: {
                                                  value: false,
                                                  checked: _vm._q(
                                                    _vm.productAvailabilityDate,
                                                    false
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.productAvailabilityDate = false
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                              _vm._v(
                                                " Illimitée\n                                            "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 d-inline radio mt-1",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "container-box d-inline-block pt-0 mt-2",
                                              staticStyle: { width: "initial" },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.productAvailabilityDate,
                                                    expression:
                                                      "productAvailabilityDate",
                                                  },
                                                ],
                                                attrs: { type: "radio" },
                                                domProps: {
                                                  value: true,
                                                  checked: _vm._q(
                                                    _vm.productAvailabilityDate,
                                                    true
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.productAvailabilityDate = true
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                              _vm._v(
                                                " Limitée\n                                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.productAvailabilityDate
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "date-resa-cal",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                du\n                                                "
                                                    ),
                                                    _c("datepicker", {
                                                      attrs: {
                                                        format: "dd/MM/yyyy",
                                                        "calendar-class":
                                                          "positition-relative mt-2",
                                                        "disabled-dates":
                                                          _vm.disabledDates,
                                                        "input-class":
                                                          "w-auto d-inline-block",
                                                        "monday-first": true,
                                                        language: _vm.fr,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.product
                                                            .available_from,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.product,
                                                            "available_from",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "product.available_from",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n                                                 au \n                                                "
                                                    ),
                                                    _c("datepicker", {
                                                      attrs: {
                                                        format: "dd/MM/yyyy",
                                                        "calendar-class":
                                                          "positition-relative mt-2",
                                                        "disabled-dates":
                                                          _vm.disabledDates,
                                                        "input-class":
                                                          "w-auto d-inline-block",
                                                        "monday-first": true,
                                                        language: _vm.fr,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.product
                                                            .available_to,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.product,
                                                            "available_to",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "product.available_to",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("ShowErrors", {
                                                  staticClass: "col-12",
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    errorKey: "available_from",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("ShowErrors", {
                                                  staticClass: "col-12",
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    errorKey: "available_to",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mt-3" }, [
                                  _c("div", { staticClass: "col-md-5" }, [
                                    _c("label", [
                                      _vm._v(
                                        "Créneaux de disponibilité du produit "
                                      ),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c("div", { staticClass: "col-12" }, [
                                          _c("div", { staticClass: "radio" }, [
                                            _c(
                                              "label",
                                              { staticClass: "container-box" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.defaultSlots,
                                                      expression:
                                                        "defaultSlots",
                                                    },
                                                  ],
                                                  attrs: { type: "radio" },
                                                  domProps: {
                                                    value: true,
                                                    checked: _vm._q(
                                                      _vm.defaultSlots,
                                                      true
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.defaultSlots = true
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                                _vm._v(
                                                  " Utiliser les paramètres par défaut\n                                            "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 d-inline radio mt-1",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "container-box d-inline-block pt-0 mt-2",
                                                staticStyle: {
                                                  width: "initial",
                                                },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.defaultSlots,
                                                      expression:
                                                        "defaultSlots",
                                                    },
                                                  ],
                                                  attrs: { type: "radio" },
                                                  domProps: {
                                                    value: false,
                                                    checked: _vm._q(
                                                      _vm.defaultSlots,
                                                      false
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.defaultSlots = false
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                                _vm._v(
                                                  " Personnaliser\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("ShowErrors", {
                                          staticClass: "col-12",
                                          attrs: {
                                            errors: _vm.errors,
                                            errorKey: "use_default_slots",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                !_vm.defaultSlots
                                  ? [
                                      _c("div", { staticClass: "row mt-3" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "offset-1 col-11 pt-2",
                                          },
                                          _vm._l(_vm.allDays, function (day) {
                                            return _c("div", { key: day }, [
                                              _c(
                                                "div",
                                                { staticClass: "mb-2" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "container-box",
                                                      staticStyle: {
                                                        "margin-bottom": "0",
                                                        width: "100%",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.active[day],
                                                            expression:
                                                              "active[day]",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "checkbox",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.active[day]
                                                            )
                                                              ? _vm._i(
                                                                  _vm.active[
                                                                    day
                                                                  ],
                                                                  null
                                                                ) > -1
                                                              : _vm.active[day],
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.active[day],
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm.active,
                                                                    day,
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm.active,
                                                                    day,
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.active,
                                                                day,
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", {
                                                        staticClass:
                                                          "checkmark",
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.dayLabel(
                                                              day,
                                                              true
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.active[day]
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "setting-open",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "setting-hours",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            _vm._l(
                                                              _vm.services[day],
                                                              function (
                                                                service
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: service.id,
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        staticClass:
                                                                          "form-control d-inline-block",
                                                                        staticStyle:
                                                                          {
                                                                            "max-width":
                                                                              "60px",
                                                                          },
                                                                        attrs: {
                                                                          type: "text",
                                                                          disabled: true,
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              service.start_hour,
                                                                          },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        staticClass:
                                                                          "form-control d-inline-block",
                                                                        staticStyle:
                                                                          {
                                                                            "max-width":
                                                                              "60px",
                                                                          },
                                                                        attrs: {
                                                                          type: "text",
                                                                          disabled: true,
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              service.end_hour,
                                                                          },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _vm._l(
                                                                      service.slots,
                                                                      function (
                                                                        slot
                                                                      ) {
                                                                        return _c(
                                                                          "label",
                                                                          {
                                                                            key: slot.id,
                                                                            staticClass:
                                                                              "container-box ml-4",
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "initial",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model",
                                                                                      value:
                                                                                        slot.enabled,
                                                                                      expression:
                                                                                        "slot.enabled",
                                                                                    },
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    type: "checkbox",
                                                                                    "true-value": 1,
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    checked:
                                                                                      Array.isArray(
                                                                                        slot.enabled
                                                                                      )
                                                                                        ? _vm._i(
                                                                                            slot.enabled,
                                                                                            null
                                                                                          ) >
                                                                                          -1
                                                                                        : _vm._q(
                                                                                            slot.enabled,
                                                                                            1
                                                                                          ),
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      var $$a =
                                                                                          slot.enabled,
                                                                                        $$el =
                                                                                          $event.target,
                                                                                        $$c =
                                                                                          $$el.checked
                                                                                            ? 1
                                                                                            : false
                                                                                      if (
                                                                                        Array.isArray(
                                                                                          $$a
                                                                                        )
                                                                                      ) {
                                                                                        var $$v =
                                                                                            null,
                                                                                          $$i =
                                                                                            _vm._i(
                                                                                              $$a,
                                                                                              $$v
                                                                                            )
                                                                                        if (
                                                                                          $$el.checked
                                                                                        ) {
                                                                                          $$i <
                                                                                            0 &&
                                                                                            _vm.$set(
                                                                                              slot,
                                                                                              "enabled",
                                                                                              $$a.concat(
                                                                                                [
                                                                                                  $$v,
                                                                                                ]
                                                                                              )
                                                                                            )
                                                                                        } else {
                                                                                          $$i >
                                                                                            -1 &&
                                                                                            _vm.$set(
                                                                                              slot,
                                                                                              "enabled",
                                                                                              $$a
                                                                                                .slice(
                                                                                                  0,
                                                                                                  $$i
                                                                                                )
                                                                                                .concat(
                                                                                                  $$a.slice(
                                                                                                    $$i +
                                                                                                      1
                                                                                                  )
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                      } else {
                                                                                        _vm.$set(
                                                                                          slot,
                                                                                          "enabled",
                                                                                          $$c
                                                                                        )
                                                                                      }
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "checkmark",
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              "\n                                                            " +
                                                                                _vm._s(
                                                                                  slot.hour
                                                                                    .replace(
                                                                                      ":",
                                                                                      "h"
                                                                                    )
                                                                                    .substr(
                                                                                      0,
                                                                                      5
                                                                                    )
                                                                                ) +
                                                                                "\n                                                        "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ])
                                          }),
                                          0
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.product.single_type !== "menu" &&
              _vm.product.is_enabled_for_delivery &&
              _vm.config.is_delivery_enabled
                ? _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  _vm.showDeliveryParams =
                                    !_vm.showDeliveryParams
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "col-12" }, [
                                _c("strong", [_vm._v("Options de livraison")]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.showDeliveryParams
                            ? [
                                _c("div", { staticClass: "row mt-3 mb-2" }, [
                                  _c("div", { staticClass: "col-md-5" }, [
                                    _c("label", [
                                      _vm._v(
                                        "Délai de préparation du produit "
                                      ),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c("div", { staticClass: "col-12" }, [
                                          _c("div", { staticClass: "radio" }, [
                                            _c(
                                              "label",
                                              { staticClass: "container-box" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.productDeliveryPreparationTime,
                                                      expression:
                                                        "productDeliveryPreparationTime",
                                                    },
                                                  ],
                                                  attrs: { type: "radio" },
                                                  domProps: {
                                                    value: false,
                                                    checked: _vm._q(
                                                      _vm.productDeliveryPreparationTime,
                                                      false
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.productDeliveryPreparationTime = false
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                                _vm._v(
                                                  " Utiliser les paramètres par défaut\n                                            "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 d-inline radio mt-1",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "container-box d-inline-block pt-0 mt-2",
                                                staticStyle: {
                                                  width: "initial",
                                                },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.productDeliveryPreparationTime,
                                                      expression:
                                                        "productDeliveryPreparationTime",
                                                    },
                                                  ],
                                                  attrs: { type: "radio" },
                                                  domProps: {
                                                    value: true,
                                                    checked: _vm._q(
                                                      _vm.productDeliveryPreparationTime,
                                                      true
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.productDeliveryPreparationTime = true
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                                _vm._v(
                                                  " Personnaliser\n                                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.productDeliveryPreparationTime
                                              ? [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.product
                                                            .delivery_preparation_time,
                                                        expression:
                                                          "product.delivery_preparation_time",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-control d-inline-block ml-2 mr-2",
                                                    staticStyle: {
                                                      width: "120px",
                                                    },
                                                    attrs: { type: "number" },
                                                    domProps: {
                                                      value:
                                                        _vm.product
                                                          .delivery_preparation_time,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.product,
                                                          "delivery_preparation_time",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.product
                                                              .delivery_preparation_time_unit,
                                                          expression:
                                                            "product.delivery_preparation_time_unit",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "custom-select mb-0",
                                                      staticStyle: {
                                                        width: "initial",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.$set(
                                                            _vm.product,
                                                            "delivery_preparation_time_unit",
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "mins",
                                                          },
                                                        },
                                                        [_vm._v("Minutes")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "hours",
                                                          },
                                                        },
                                                        [_vm._v("Heures")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "days",
                                                          },
                                                        },
                                                        [_vm._v("Jours")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c("ShowErrors", {
                                          staticClass: "col-12",
                                          attrs: {
                                            errors: _vm.errors,
                                            errorKey:
                                              "delivery_preparation_time",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("ShowErrors", {
                                          staticClass: "col-12",
                                          attrs: {
                                            errors: _vm.errors,
                                            errorKey:
                                              "delivery_preparation_time_unit",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mt-3 mb-2" }, [
                                  _c("div", { staticClass: "col-md-5" }, [
                                    _c("label", [
                                      _vm._v(
                                        "Dates de disponibilité du produit "
                                      ),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-12" }, [
                                        _c("div", { staticClass: "radio" }, [
                                          _c(
                                            "label",
                                            { staticClass: "container-box" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.productDeliveryAvailabilityDate,
                                                    expression:
                                                      "productDeliveryAvailabilityDate",
                                                  },
                                                ],
                                                attrs: { type: "radio" },
                                                domProps: {
                                                  value: false,
                                                  checked: _vm._q(
                                                    _vm.productDeliveryAvailabilityDate,
                                                    false
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.productDeliveryAvailabilityDate = false
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                              _vm._v(
                                                " Illimitée\n                                            "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 d-inline radio mt-1",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "container-box d-inline-block pt-0 mt-2",
                                              staticStyle: { width: "initial" },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.productDeliveryAvailabilityDate,
                                                    expression:
                                                      "productDeliveryAvailabilityDate",
                                                  },
                                                ],
                                                attrs: { type: "radio" },
                                                domProps: {
                                                  value: true,
                                                  checked: _vm._q(
                                                    _vm.productDeliveryAvailabilityDate,
                                                    true
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.productDeliveryAvailabilityDate = true
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                              _vm._v(
                                                " Limitée\n                                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.productDeliveryAvailabilityDate
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "date-resa-cal",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                du\n                                                "
                                                    ),
                                                    _c("datepicker", {
                                                      attrs: {
                                                        format: "dd/MM/yyyy",
                                                        "calendar-class":
                                                          "positition-relative mt-2",
                                                        "disabled-dates":
                                                          _vm.disabledDates,
                                                        "input-class":
                                                          "w-auto d-inline-block",
                                                        "monday-first": true,
                                                        language: _vm.fr,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.product
                                                            .delivery_available_from,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.product,
                                                            "delivery_available_from",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "product.delivery_available_from",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n                                                 au \n                                                "
                                                    ),
                                                    _c("datepicker", {
                                                      attrs: {
                                                        format: "dd/MM/yyyy",
                                                        "calendar-class":
                                                          "positition-relative mt-2",
                                                        "disabled-dates":
                                                          _vm.disabledDates,
                                                        "input-class":
                                                          "w-auto d-inline-block",
                                                        "monday-first": true,
                                                        language: _vm.fr,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.product
                                                            .delivery_available_to,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.product,
                                                            "delivery_available_to",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "product.delivery_available_to",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("ShowErrors", {
                                                  staticClass: "col-12",
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    errorKey:
                                                      "delivery_available_from",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("ShowErrors", {
                                                  staticClass: "col-12",
                                                  attrs: {
                                                    errors: _vm.errors,
                                                    errorKey:
                                                      "delivery_available_to",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mt-3" }, [
                                  _c("div", { staticClass: "col-md-5" }, [
                                    _c("label", [
                                      _vm._v(
                                        "Créneaux de disponibilité du produit "
                                      ),
                                      _c("small", [_vm._v("*")]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-7" }, [
                                    _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c("div", { staticClass: "col-12" }, [
                                          _c("div", { staticClass: "radio" }, [
                                            _c(
                                              "label",
                                              { staticClass: "container-box" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.deliveryDefaultSlots,
                                                      expression:
                                                        "deliveryDefaultSlots",
                                                    },
                                                  ],
                                                  attrs: { type: "radio" },
                                                  domProps: {
                                                    value: true,
                                                    checked: _vm._q(
                                                      _vm.deliveryDefaultSlots,
                                                      true
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.deliveryDefaultSlots = true
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                                _vm._v(
                                                  " Utiliser les paramètres par défaut\n                                            "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 d-inline radio mt-1",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "container-box d-inline-block pt-0 mt-2",
                                                staticStyle: {
                                                  width: "initial",
                                                },
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.deliveryDefaultSlots,
                                                      expression:
                                                        "deliveryDefaultSlots",
                                                    },
                                                  ],
                                                  attrs: { type: "radio" },
                                                  domProps: {
                                                    value: false,
                                                    checked: _vm._q(
                                                      _vm.deliveryDefaultSlots,
                                                      false
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.deliveryDefaultSlots = false
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                                _vm._v(
                                                  " Personnaliser\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("ShowErrors", {
                                          staticClass: "col-12",
                                          attrs: {
                                            errors: _vm.errors,
                                            errorKey:
                                              "use_default_delivery_slots",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                !_vm.deliveryDefaultSlots
                                  ? [
                                      _c("div", { staticClass: "row mt-3" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "offset-1 col-11 pt-2",
                                          },
                                          _vm._l(_vm.allDays, function (day) {
                                            return _c("div", { key: day }, [
                                              _c(
                                                "div",
                                                { staticClass: "mb-2" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "container-box",
                                                      staticStyle: {
                                                        "margin-bottom": "0",
                                                        width: "100%",
                                                      },
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .deliveryActive[
                                                                day
                                                              ],
                                                            expression:
                                                              "deliveryActive[day]",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "checkbox",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm
                                                                .deliveryActive[
                                                                day
                                                              ]
                                                            )
                                                              ? _vm._i(
                                                                  _vm
                                                                    .deliveryActive[
                                                                    day
                                                                  ],
                                                                  null
                                                                ) > -1
                                                              : _vm
                                                                  .deliveryActive[
                                                                  day
                                                                ],
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm
                                                                  .deliveryActive[
                                                                  day
                                                                ],
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm.deliveryActive,
                                                                    day,
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm.deliveryActive,
                                                                    day,
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.deliveryActive,
                                                                day,
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", {
                                                        staticClass:
                                                          "checkmark",
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.dayLabel(
                                                              day,
                                                              true
                                                            )
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.deliveryActive[day]
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "setting-open",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "setting-hours",
                                                        },
                                                        _vm._l(
                                                          _vm.deliverySlots[
                                                            day
                                                          ],
                                                          function (slot) {
                                                            return _c(
                                                              "span",
                                                              {
                                                                key: slot.id,
                                                                staticClass:
                                                                  "d-block",
                                                              },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "container-box ml-4",
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "initial",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                slot.enabled,
                                                                              expression:
                                                                                "slot.enabled",
                                                                            },
                                                                          ],
                                                                        attrs: {
                                                                          type: "checkbox",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            checked:
                                                                              Array.isArray(
                                                                                slot.enabled
                                                                              )
                                                                                ? _vm._i(
                                                                                    slot.enabled,
                                                                                    null
                                                                                  ) >
                                                                                  -1
                                                                                : slot.enabled,
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              var $$a =
                                                                                  slot.enabled,
                                                                                $$el =
                                                                                  $event.target,
                                                                                $$c =
                                                                                  $$el.checked
                                                                                    ? true
                                                                                    : false
                                                                              if (
                                                                                Array.isArray(
                                                                                  $$a
                                                                                )
                                                                              ) {
                                                                                var $$v =
                                                                                    null,
                                                                                  $$i =
                                                                                    _vm._i(
                                                                                      $$a,
                                                                                      $$v
                                                                                    )
                                                                                if (
                                                                                  $$el.checked
                                                                                ) {
                                                                                  $$i <
                                                                                    0 &&
                                                                                    _vm.$set(
                                                                                      slot,
                                                                                      "enabled",
                                                                                      $$a.concat(
                                                                                        [
                                                                                          $$v,
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                } else {
                                                                                  $$i >
                                                                                    -1 &&
                                                                                    _vm.$set(
                                                                                      slot,
                                                                                      "enabled",
                                                                                      $$a
                                                                                        .slice(
                                                                                          0,
                                                                                          $$i
                                                                                        )
                                                                                        .concat(
                                                                                          $$a.slice(
                                                                                            $$i +
                                                                                              1
                                                                                          )
                                                                                        )
                                                                                    )
                                                                                }
                                                                              } else {
                                                                                _vm.$set(
                                                                                  slot,
                                                                                  "enabled",
                                                                                  $$c
                                                                                )
                                                                              }
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "checkmark",
                                                                    }),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          slot.hour_start
                                                                            .substr(
                                                                              0,
                                                                              5
                                                                            )
                                                                            .replace(
                                                                              ":",
                                                                              "h"
                                                                            )
                                                                        ) +
                                                                        "\n                                                        - " +
                                                                        _vm._s(
                                                                          slot.hour_end
                                                                            .substr(
                                                                              0,
                                                                              5
                                                                            )
                                                                            .replace(
                                                                              ":",
                                                                              "h"
                                                                            )
                                                                        ) +
                                                                        "\n                                                    "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ])
                                          }),
                                          0
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              [
                _vm._l(
                  _vm.product.cc_product_components.data,
                  function (productComponent, index) {
                    return _c(
                      "div",
                      { key: productComponent.id, staticClass: "row mb-3" },
                      [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "border-light b-radius-20 p-4" },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 pt-1 d-flex justify-content-between align-items-center",
                                  },
                                  [
                                    _c("div", [
                                      _c("span", { staticClass: "d-block" }, [
                                        _vm._v(_vm._s(productComponent.name)),
                                      ]),
                                      _vm._v(" "),
                                      productComponent.is_mandatory
                                        ? _c(
                                            "small",
                                            { staticClass: "text-muted" },
                                            [
                                              _vm._v(
                                                "Choix obligatoire pour le client"
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "small",
                                            { staticClass: "text-muted" },
                                            [_vm._v("Optionnel")]
                                          ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.getTooltip("Éditer"),
                                              expression:
                                                "getTooltip('Éditer')",
                                            },
                                          ],
                                          staticClass:
                                            "btn btn-sm btn-success btn-square",
                                          attrs: {
                                            title: "Modifier la réservation",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateProductComponent(
                                                index,
                                                productComponent
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("feather", {
                                            attrs: { type: "edit" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value:
                                                _vm.getTooltip("Supprimer"),
                                              expression:
                                                "getTooltip('Supprimer')",
                                            },
                                          ],
                                          staticClass:
                                            "btn btn-sm btn-danger btn-square",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteProductComponent(
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("feather", {
                                            attrs: { type: "trash-2" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-sm table-striped border-bottom mt-2",
                                },
                                [
                                  _c(
                                    "thead",
                                    { staticClass: "border-bottom" },
                                    [
                                      _c("tr", [
                                        _c("th", [_vm._v("Nom")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Tarif")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Action")]),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      productComponent.cc_product_options.data,
                                      function (productOption, indexOption) {
                                        return _c(
                                          "tr",
                                          { key: `${indexOption}` },
                                          [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  productOption.cc_product.name
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    productOption.include
                                                      ? "Inclus"
                                                      : _vm.formatCurrency(
                                                          productOption.price
                                                        )
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.getTooltip(
                                                          "Retirer"
                                                        ),
                                                      expression:
                                                        "getTooltip('Retirer')",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-sm btn-warning btn-square",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteProductComponentOption(
                                                        productComponent,
                                                        indexOption
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("feather", {
                                                    attrs: { type: "x" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("ShowErrors", {
                                staticClass: "d-block",
                                attrs: {
                                  errors: _vm.errors,
                                  errorKey: `product_components.${index}.*`,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _vm.product.type === "menu"
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-sm btn-success btn-circle mt-1",
                            on: { click: _vm.addProductComponent },
                          },
                          [
                            _c("feather", { attrs: { type: "plus" } }),
                            _vm._v(
                              "\n                        Ajouter un composant de formule\n                    "
                            ),
                          ],
                          1
                        )
                      : _vm.product.type === "single" &&
                        _vm.product.cc_product_components.data.length === 0
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-sm btn-success btn-circle mt-1",
                            on: { click: _vm.addProductComponentExtras },
                          },
                          [
                            _c("feather", { attrs: { type: "plus" } }),
                            _vm._v(
                              "\n                        Ajouter des options\n                    "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
              ],
              _vm._v(" "),
              _vm.showAddClickAndCollectAddFormuleComponentModal
                ? _c("add-click-and-collect-add-formule-component-modal", {
                    attrs: { productComponent: _vm.productComponent },
                    on: {
                      close: function ($event) {
                        _vm.showAddClickAndCollectAddFormuleComponentModal = false
                      },
                      saved: _vm.productComponentSaved,
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }