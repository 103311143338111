var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loadings.customEvent || _vm.loadings.form
        ? _c("loader-component")
        : _vm.errors.fetchCustomEvent !== null
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.errors.fetchCustomEvent) + "\n    "
            ),
          ])
        : _c(
            "form",
            {
              ref: "form",
              attrs: { method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "border-light b-radius-20 p-4" },
                    [
                      _c("input-component", {
                        attrs: {
                          "input-name": "title",
                          required: "",
                          disabled: !_vm.isEditable,
                          label: _vm.$tl("labels.form.title"),
                          "form-errors": _vm.errors.form,
                        },
                        model: {
                          value: _vm.customEvent.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.customEvent, "title", $$v)
                          },
                          expression: "customEvent.title",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-5 pt-2" }, [
                          _c("label", { attrs: { for: "description" } }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$tl("labels.form.description")) +
                                "\n                            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-7" },
                          [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.customEvent.description,
                                  expression: "customEvent.description",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                disabled: !_vm.isEditable,
                                name: "description",
                                id: "description",
                              },
                              domProps: { value: _vm.customEvent.description },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.customEvent,
                                    "description",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-errors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors.form,
                                errorKey: "description",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-5 pt-2" }, [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$tl("labels.startDate")) + " "),
                            _c("small", [_vm._v("*")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-7 date-resa-cal" },
                          [
                            _c("date-picker", {
                              attrs: { disabled: !_vm.isEditable },
                              model: {
                                value: _vm.customEvent.date_begin,
                                callback: function ($$v) {
                                  _vm.$set(_vm.customEvent, "date_begin", $$v)
                                },
                                expression: "customEvent.date_begin",
                              },
                            }),
                            _vm._v(" "),
                            _c("show-errors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors.form,
                                errorKey: "date_begin",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col-5 pt-2" }, [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$tl("labels.endDate")) + " "),
                            _c("small", [_vm._v("*")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-7 date-resa-cal" },
                          [
                            _c("date-picker", {
                              attrs: { disabled: !_vm.isEditable },
                              model: {
                                value: _vm.customEvent.date_end,
                                callback: function ($$v) {
                                  _vm.$set(_vm.customEvent, "date_end", $$v)
                                },
                                expression: "customEvent.date_end",
                              },
                            }),
                            _vm._v(" "),
                            _c("show-errors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors.form,
                                errorKey: "date_end",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-5 pt-2" }, [
                          _c("label", { attrs: { for: "priority" } }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.customEvents.priority"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-7" },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("span", {
                                  staticClass: "d-block mr-1",
                                  style: `width: 10px; height: 10px; background-color: ${_vm.getCustomEventPriorityColor(
                                    _vm.customEvent.priority
                                  )}`,
                                }),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.customEvent.priority,
                                        expression: "customEvent.priority",
                                      },
                                    ],
                                    staticClass: "custom-select mb-0",
                                    attrs: {
                                      disabled: !_vm.isEditable,
                                      id: "priority",
                                      name: "priority",
                                      required: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.customEvent,
                                          "priority",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.ALL_CUSTOM_EVENT_PRIORITIES,
                                    function (customEventPriority) {
                                      return _c(
                                        "option",
                                        {
                                          key: customEventPriority.value,
                                          domProps: {
                                            value: customEventPriority.value,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.getCustomEventPriorityLabel(
                                                  customEventPriority.value
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("show-errors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors.form,
                                errorKey: "priority",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mt-2" }, [
                        _c("div", { staticClass: "col-5 pt-2" }, [
                          _c(
                            "label",
                            { attrs: { for: "visibility" } },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.booking.customEvents.visibility"
                                    )
                                  ) +
                                  "\n                                "
                              ),
                              _c("feather", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.getTooltipNotice(
                                      "notices.booking.customEvents.visibility"
                                    ),
                                    expression:
                                      "getTooltipNotice('notices.booking.customEvents.visibility')",
                                  },
                                ],
                                staticClass: "ml-1 pointer tooltip-infos",
                                attrs: { type: "info" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-7" },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.customEvent.visibility,
                                        expression: "customEvent.visibility",
                                      },
                                    ],
                                    staticClass: "custom-select mb-0",
                                    attrs: {
                                      disabled: !_vm.isEditable,
                                      id: "visibility",
                                      name: "visibility",
                                      required: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.customEvent,
                                          "visibility",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.ALL_CUSTOM_EVENT_VISIBILITIES,
                                    function (customEventVisibility) {
                                      return _c(
                                        "option",
                                        {
                                          key: customEventVisibility.value,
                                          domProps: {
                                            value: customEventVisibility.value,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.getCustomEventVisibilityLabel(
                                                  customEventVisibility.value
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("show-errors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors.form,
                                errorKey: "visibility",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mb-2 mt-3" }, [
                        _c("div", { staticClass: "col-5" }, [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$tl("labels.restaurants"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-7" },
                          [
                            _c(
                              "div",
                              _vm._l(
                                _vm.availableRestaurants,
                                function (restaurant) {
                                  return _c(
                                    "div",
                                    { key: restaurant.id, staticClass: "mb-2" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "container-box",
                                          staticStyle: {
                                            "margin-bottom": "0",
                                            width: "100%",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.customEvent.restaurants,
                                                expression:
                                                  "customEvent.restaurants",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              name: "restaurants[]",
                                              disabled: !_vm.isEditable,
                                            },
                                            domProps: {
                                              value: restaurant.id,
                                              checked: Array.isArray(
                                                _vm.customEvent.restaurants
                                              )
                                                ? _vm._i(
                                                    _vm.customEvent.restaurants,
                                                    restaurant.id
                                                  ) > -1
                                                : _vm.customEvent.restaurants,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.customEvent.restaurants,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = restaurant.id,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.customEvent,
                                                        "restaurants",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.customEvent,
                                                        "restaurants",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.customEvent,
                                                    "restaurants",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(restaurant.name) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c("show-errors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors.form,
                                errorKey: "restaurants",
                              },
                            }),
                            _vm._v(" "),
                            _c("show-errors", {
                              staticClass: "d-block",
                              attrs: {
                                errors: _vm.errors.form,
                                errorKey: "restaurants.*",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.showSaveButton
                ? _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("input", {
                        staticClass: "btn btn-success btn-circle",
                        attrs: {
                          type: "submit",
                          name: "submit",
                          disabled: !_vm.isEditable,
                        },
                        domProps: {
                          value: _vm.$tl("labels.form.actions.save"),
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }