export default {
    data() {
        return {
            SERVICE_NOTE_TYPE_RESERVATIONS_LIST: {
                value: "reservations_list",
            },
            SERVICE_NOTE_TYPE_SEATING_PLAN: {
                value: "seating_plan",
            },
        };
    },
    computed: {
        ALL_SERVICE_NOTE_TYPES() {
            return [this.SERVICE_NOTE_TYPE_RESERVATIONS_LIST, this.SERVICE_NOTE_TYPE_SEATING_PLAN];
        },
    },
};
