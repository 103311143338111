var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid text-center" }, [
    _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-lg-6 offset-lg-3 pt-5 text-center" }, [
        _c(
          "div",
          { staticClass: "border-light b-radius-20 p-4 mb-3" },
          [
            _c("h3", { staticClass: "mb-4" }, [
              _vm._v("Ooops, vous êtes perdu ?"),
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "btn btn-success btn-circle",
                attrs: { to: _vm.home },
              },
              [_vm._v(" Tableau de bord ")]
            ),
            _vm._v(" "),
            _c("img", {
              staticClass: "mt-5 none-mobile",
              attrs: {
                width: "100%",
                src: "/images/visuel-404.png",
                alt: "No Show",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "mt-3 none-desk",
              attrs: {
                width: "270",
                src: "/images/visuel-404.png",
                alt: "No Show",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }