var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Synchroniser les identifiants clients Fiducial"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.isLoading ? _c("loader-component") : _vm._e(),
        _vm._v(" "),
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLoading,
                expression: "!isLoading",
              },
            ],
            attrs: { id: "sync-fiducial-clients-ids-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.startImport.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "row mb-2" }, [
              _c("div", { staticClass: "col-md-5" }, [_vm._v("Fichier *")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-7" },
                [
                  _c("file-uploader-component", {
                    attrs: {
                      "suffix-id": "excel-import",
                      "has-direct-upload": false,
                      "allowed-extensions": ".xls,.xlsx",
                      errors: _vm.errors.form,
                    },
                    on: {
                      "upload-file": function ($event) {
                        _vm.form.file = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("input-component", {
              attrs: {
                "form-errors": _vm.errors.form,
                "input-name": "fiducialClientIdColumn",
              },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(
                        '\n                    Nom de la colonne "Identifiant client Fiducial"'
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", [_vm._v('(défaut : "CodeClient")')]),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.form.fiducialClientIdColumn,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "fiducialClientIdColumn", $$v)
                },
                expression: "form.fiducialClientIdColumn",
              },
            }),
            _vm._v(" "),
            _c("input-component", {
              attrs: {
                "form-errors": _vm.errors.form,
                "input-name": "emailColumn",
              },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(
                        '\n                    Nom de la colonne "Email client"'
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", [_vm._v('(défaut : "mail")')]),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.form.emailColumn,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "emailColumn", $$v)
                },
                expression: "form.emailColumn",
              },
            }),
            _vm._v(" "),
            _c("switch-input-component", {
              attrs: {
                "form-errors": _vm.errors.form,
                "input-name": "shouldCommit",
              },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [_vm._v("Sauvegarder les changements")]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.form.shouldCommit,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "shouldCommit", $$v)
                },
                expression: "form.shouldCommit",
              },
            }),
            _vm._v(" "),
            _c("switch-input-component", {
              attrs: {
                "form-errors": _vm.errors.form,
                "input-name": "shouldCommit",
              },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [_vm._v("Override les identifiants existants")]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.form.shouldOverrideExistingIds,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "shouldOverrideExistingIds", $$v)
                },
                expression: "form.shouldOverrideExistingIds",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn-sm btn btn-secondary btn-circle",
            attrs: { type: "button", disabled: _vm.isLoading },
            on: { click: _vm.close },
          },
          [_vm._v("\n            Annuler\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-success btn-sm btn-circle ml-2",
            attrs: {
              form: "sync-fiducial-clients-ids-form",
              disabled: _vm.isLoading,
            },
          },
          [_vm._v("Synchroniser")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }