<template>
    <div>
        <div class="bg-danger text-center text-white p-1 mb-2" style="border-radius: 100px">
            {{ invalid_reason }}
        </div>
        <show-gift-voucher :sale="gv_sale" />
    </div>
</template>

<script>
import showGiftVoucher from "../giftVouchers/showGiftVoucher";

export default {
    props: {
        gv_sale: {
            type: Object,
            required: true,
        },
        invalid_reason: {
            type: String,
            required: true,
        },
    },
    components: {
        showGiftVoucher,
    },
};
</script>
