var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("iframe", {
      class: _vm.cssClass,
      style: _vm.customStyle,
      attrs: {
        src: `${_vm.YOUTUBE_EMBED_BASE_URL}/${_vm.youtubeVideoId}`,
        title: _vm.title,
        frameborder: "0",
        width: _vm.width,
        height: _vm.height,
        allow:
          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
        allowfullscreen: "",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }