var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-5 pt-5" }, [
    _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-1 pt-2 my-1",
          },
          [
            _c("h5", { staticClass: "title mt-2" }, [_vm._v("Catégories")]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-success mr-1 btn-circle",
                attrs: { disabled: !_vm.has_right_to_create_categories },
                on: { click: _vm.addCategory },
              },
              [
                _c("feather", { attrs: { type: "plus" } }),
                _vm._v(" "),
                _c("span", [_vm._v("Ajouter une catégorie")]),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row m-0" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.loading
            ? _c("LoaderComponent")
            : _c("div", [
                _vm.error
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(_vm._s(_vm.error)),
                    ])
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "mb-1" },
                        [
                          _c("switch-input-component", {
                            attrs: {
                              disabled:
                                _vm.isTogglingIsDisplayPerCategory ||
                                !_vm.has_right_to_update_config,
                              "is-form-grid": false,
                              value: _vm.display_per_category,
                            },
                            on: { input: _vm.toggleIsDisplayPerCategory },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                                Activer l'affichage des produits par catégorie\n                                "
                                    ),
                                    _c("feather", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltipNotice(
                                            "notices.clickAndCollect.categories.isDisplayPerCategory"
                                          ),
                                          expression:
                                            "getTooltipNotice('notices.clickAndCollect.categories.isDisplayPerCategory')",
                                        },
                                      ],
                                      staticClass: "ml-1 pointer tooltip-infos",
                                      attrs: { type: "info" },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-1" },
                        [
                          _c("switch-input-component", {
                            attrs: {
                              disabled:
                                _vm.isTogglingUseBurgerMenu ||
                                !_vm.has_right_to_update_config,
                              "is-form-grid": false,
                              value: _vm.use_burger_menu,
                            },
                            on: { input: _vm.toggleUseBurgerMenu },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                                Activer l'affichage des catégories par menu déroulant\n                                "
                                    ),
                                    _c("feather", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.getTooltipNotice(
                                            "notices.clickAndCollect.categories.useBurgerMenu"
                                          ),
                                          expression:
                                            "getTooltipNotice('notices.clickAndCollect.categories.useBurgerMenu')",
                                        },
                                      ],
                                      staticClass: "ml-1 pointer tooltip-infos",
                                      attrs: { type: "info" },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "table",
                            {
                              staticClass:
                                "capitalize table table-sm table-striped border-bottom",
                            },
                            [
                              _c("thead", { staticClass: "border-bottom" }, [
                                _c("tr", [
                                  _c("th", [_vm._v("Ordre")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Nom")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Stock / Créneau")]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      "Stock / Service - Plage de livraison"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Stock / Jour")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Actions")]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "draggable",
                                {
                                  attrs: {
                                    disabled:
                                      !_vm.has_right_to_update_categories ||
                                      _vm.selectedCategory,
                                    tag: "tbody",
                                    draggable: ".categories",
                                  },
                                  model: {
                                    value: _vm.categories,
                                    callback: function ($$v) {
                                      _vm.categories = $$v
                                    },
                                    expression: "categories",
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.categories,
                                    function (printCategory) {
                                      return [
                                        _vm.selectedCategory ==
                                          printCategory.id ||
                                        !_vm.selectedCategory
                                          ? _c(
                                              "tr",
                                              {
                                                key: printCategory.id,
                                                staticClass: "categories",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      width: "70px",
                                                    },
                                                  },
                                                  [
                                                    !_vm.selectedCategory
                                                      ? _c(
                                                          "svg",
                                                          {
                                                            staticClass:
                                                              "css-i6dzq1 feather mr-1",
                                                            staticStyle: {
                                                              cursor: "grab",
                                                              color:
                                                                "rgb(170, 170, 170)",
                                                            },
                                                            attrs: {
                                                              viewBox:
                                                                "0 0 24 24",
                                                              width: "24",
                                                              height: "24",
                                                              stroke:
                                                                "currentColor",
                                                              "stroke-width":
                                                                "2",
                                                              fill: "none",
                                                              "stroke-linecap":
                                                                "round",
                                                              "stroke-linejoin":
                                                                "round",
                                                            },
                                                          },
                                                          [
                                                            _c("line", {
                                                              attrs: {
                                                                x1: "3",
                                                                y1: "9",
                                                                x2: "21",
                                                                y2: "9",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("line", {
                                                              attrs: {
                                                                x1: "3",
                                                                y1: "15",
                                                                x2: "21",
                                                                y2: "15",
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn pl-0",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.selectedCategory
                                                                  ? (_vm.selectedCategory =
                                                                      null)
                                                                  : (_vm.selectedCategory =
                                                                      printCategory.id)
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("feather", {
                                                              staticClass:
                                                                "feather-24",
                                                              attrs: {
                                                                type: "chevron-left",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm.selectedCategory
                                                    ? _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            printCategory.name
                                                          )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            printCategory.name
                                                          )
                                                        ),
                                                      ]),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  !printCategory.is_stock_per_slot_editable
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-muted",
                                                        },
                                                        [_vm._v("--")]
                                                      )
                                                    : printCategory.stock_per_slot ===
                                                      null
                                                    ? _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "css-i6dzq1 feather",
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 24 24",
                                                            width: "24",
                                                            height: "24",
                                                            stroke:
                                                              "currentColor",
                                                            "stroke-width": "2",
                                                            fill: "none",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        },
                                                        [
                                                          _c("circle", {
                                                            attrs: {
                                                              cx: "7",
                                                              cy: "11.5",
                                                              r: "4.5",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("circle", {
                                                            attrs: {
                                                              cx: "17",
                                                              cy: "11.5",
                                                              r: "4.5",
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            printCategory.stock_per_slot
                                                          )
                                                        ),
                                                      ]),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  printCategory.stock_per_service ===
                                                  null
                                                    ? _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "css-i6dzq1 feather",
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 24 24",
                                                            width: "24",
                                                            height: "24",
                                                            stroke:
                                                              "currentColor",
                                                            "stroke-width": "2",
                                                            fill: "none",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        },
                                                        [
                                                          _c("circle", {
                                                            attrs: {
                                                              cx: "7",
                                                              cy: "11.5",
                                                              r: "4.5",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("circle", {
                                                            attrs: {
                                                              cx: "17",
                                                              cy: "11.5",
                                                              r: "4.5",
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            printCategory.stock_per_service
                                                          )
                                                        ),
                                                      ]),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  printCategory.stock_per_day ===
                                                  null
                                                    ? _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "css-i6dzq1 feather",
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 24 24",
                                                            width: "24",
                                                            height: "24",
                                                            stroke:
                                                              "currentColor",
                                                            "stroke-width": "2",
                                                            fill: "none",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        },
                                                        [
                                                          _c("circle", {
                                                            attrs: {
                                                              cx: "7",
                                                              cy: "11.5",
                                                              r: "4.5",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("circle", {
                                                            attrs: {
                                                              cx: "17",
                                                              cy: "11.5",
                                                              r: "4.5",
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            printCategory.stock_per_day
                                                          )
                                                        ),
                                                      ]),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value:
                                                            _vm.getTooltip(
                                                              "Éditer"
                                                            ),
                                                          expression:
                                                            "getTooltip('Éditer')",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn btn-sm btn-success btn-square",
                                                      attrs: {
                                                        disabled:
                                                          !_vm.has_right_to_update_categories,
                                                        title:
                                                          "Modifier la réservation",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editCategory(
                                                            printCategory
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("feather", {
                                                        attrs: { type: "edit" },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value:
                                                            _vm.getTooltip(
                                                              "Supprimer"
                                                            ),
                                                          expression:
                                                            "getTooltip('Supprimer')",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn btn-sm btn-danger btn-square",
                                                      attrs: {
                                                        disabled:
                                                          !_vm.has_right_to_delete_categories,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteCategory(
                                                            printCategory.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("feather", {
                                                        attrs: {
                                                          type: "trash-2",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  !_vm.selectedCategory
                                                    ? _c(
                                                        "button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "tooltip",
                                                              rawName:
                                                                "v-tooltip",
                                                              value:
                                                                _vm.getTooltip(
                                                                  "Ordonner les produits de cette catégorie"
                                                                ),
                                                              expression:
                                                                "getTooltip('Ordonner les produits de cette catégorie')",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "btn btn-sm btn-outline-secondary btn-square",
                                                          attrs: {
                                                            disabled:
                                                              !_vm.has_right_to_update_categories,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.selectedCategory
                                                                ? (_vm.selectedCategory =
                                                                    null)
                                                                : (_vm.selectedCategory =
                                                                    printCategory.id)
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "css-i6dzq1 feather",
                                                              attrs: {
                                                                viewBox:
                                                                  "0 0 24 24",
                                                                width: "24",
                                                                height: "24",
                                                                stroke:
                                                                  "currentColor",
                                                                "stroke-width":
                                                                  "2",
                                                                fill: "none",
                                                                "stroke-linecap":
                                                                  "round",
                                                                "stroke-linejoin":
                                                                  "round",
                                                              },
                                                            },
                                                            [
                                                              _c("polyline", {
                                                                attrs: {
                                                                  points:
                                                                    "9 5 12 2 15 5",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("polyline", {
                                                                attrs: {
                                                                  points:
                                                                    "15 19 12 22 9 19",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("line", {
                                                                attrs: {
                                                                  x1: "12",
                                                                  y1: "2",
                                                                  x2: "12",
                                                                  y2: "22",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.selectedCategory !== null
                                ? [
                                    _vm.loadingProduct
                                      ? _c("LoaderComponent")
                                      : _c(
                                          "draggable",
                                          {
                                            attrs: {
                                              tag: "tbody",
                                              draggable:
                                                _vm.has_right_to_update_categories
                                                  ? ".products"
                                                  : "",
                                            },
                                            model: {
                                              value: _vm.products,
                                              callback: function ($$v) {
                                                _vm.products = $$v
                                              },
                                              expression: "products",
                                            },
                                          },
                                          _vm._l(
                                            _vm.products,
                                            function (printProduct) {
                                              return _c(
                                                "tr",
                                                {
                                                  key: printProduct.id,
                                                  staticClass: "products",
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        cursor: "grab",
                                                        width: "70px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "css-i6dzq1 feather mr-1",
                                                          staticStyle: {
                                                            color:
                                                              "rgb(170, 170, 170)",
                                                          },
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 24 24",
                                                            width: "24",
                                                            height: "24",
                                                            stroke:
                                                              "currentColor",
                                                            "stroke-width": "2",
                                                            fill: "none",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        },
                                                        [
                                                          _c("line", {
                                                            attrs: {
                                                              x1: "3",
                                                              y1: "9",
                                                              x2: "21",
                                                              y2: "9",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("line", {
                                                            attrs: {
                                                              x1: "3",
                                                              y1: "15",
                                                              x2: "21",
                                                              y2: "15",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(printProduct.name)
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.showAddClickAndCollectCategoryModal
                            ? _c("add-click-and-collect-category-modal", {
                                attrs: { category: _vm.category },
                                on: {
                                  close: function ($event) {
                                    _vm.showAddClickAndCollectCategoryModal = false
                                  },
                                  saved: _vm.categorySaved,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
              ]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    !_vm.loading && _vm.has_right_to_update_categories
      ? _c("div", { staticClass: "row m-0 mb-3" }, [
          _c(
            "div",
            { staticClass: "col-12 text-muted" },
            [
              _vm._v("\n            Vous avez la possibilité d'ordonner vos "),
              !_vm.selectedCategory
                ? [_vm._v("catégories")]
                : [_vm._v("produits")],
              _vm._v(" en cliquant sur l'icone\n            "),
              _c(
                "svg",
                {
                  staticClass: "css-i6dzq1 feather mr-1",
                  staticStyle: { color: "rgb(170, 170, 170)" },
                  attrs: {
                    viewBox: "0 0 24 24",
                    width: "24",
                    height: "24",
                    stroke: "currentColor",
                    "stroke-width": "2",
                    fill: "none",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                  },
                },
                [
                  _c("line", {
                    attrs: { x1: "3", y1: "9", x2: "21", y2: "9" },
                  }),
                  _vm._v(" "),
                  _c("line", {
                    attrs: { x1: "3", y1: "15", x2: "21", y2: "15" },
                  }),
                ]
              ),
              _vm._v(
                "\n            et en déplaçant la ligne. Pensez à enregistrer vos changements.\n        "
              ),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.loading
      ? _c("div", { staticClass: "row m-0" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("input", {
              staticClass: "btn btn-success btn-circle",
              attrs: {
                disabled: !_vm.has_right_to_update_categories,
                type: "submit",
                name: "submit",
                value: "Enregistrer",
              },
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            }),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }