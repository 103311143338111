var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    [
      _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Moyen(s) de paiements"),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent", { attrs: { slot: "body" }, slot: "body" })
        : _c(
            "div",
            { attrs: { slot: "body" }, slot: "body" },
            [
              _vm._v(
                "\n        Afin de simplifier la facturation des abonnements, veuillez choisir le moyen de paiement à conserver sur votre compte.\n        "
              ),
              _c("br"),
              _vm._v(
                "\n        Les moyens de paiements non selectionnés seront supprimés de votre compte\n\n        "
              ),
              _c("CardList", {
                attrs: { editable: false },
                model: {
                  value: _vm.cardSelected,
                  callback: function ($$v) {
                    _vm.cardSelected = $$v
                  },
                  expression: "cardSelected",
                },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex w-100 justify-content-between",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-success btn-sm btn-circle",
              attrs: { disabled: !_vm.cardSelected },
              on: { click: _vm.confirm },
            },
            [_vm._v("Confirmer")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }