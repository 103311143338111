<template>
    <div class="reservation-vue">
        <loader-component v-if="loading" />
        <div v-if="!loading">
            <div v-show="error" class="error">
                {{ error }}
            </div>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-0 pt-3 pl-3 pr-3">
                <div>
                    <h2 class="h5 d-inline-block">
                        <router-link
                            :to="{
                                name: 'booking.restaurants.reservationsCuisine',
                                params: { restaurant_id: restaurantId },
                                query: { date: reservation_date_prev },
                            }"
                            class="btn btn-sm btn-outline-secondary radius-btn-square"
                            style="width: 29px; padding-left: 5px">
                            <feather type="chevron-left" />
                        </router-link>
                        <DatePicker
                            class="d-inline-block date-resa-cal ml-1 mr-1"
                            input-class="date-resa-cal-input text-uppercase text-center"
                            format="cccc dd LLL"
                            v-model="reservation_date" />
                        <router-link
                            :to="{
                                name: 'booking.restaurants.reservationsCuisine',
                                params: { restaurant_id: restaurantId },
                                query: { date: reservation_date_next },
                            }"
                            class="btn btn-sm btn-outline-secondary radius-btn-square"
                            style="width: 29px; padding-left: 6px">
                            <feather type="chevron-right" />
                        </router-link>
                        <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary none-mobile btn-circle"
                            @click="reservation_date = getDateTime()">
                            {{ $tl("labels.today") }}
                        </button>
                        <button type="button" class="btn btn-sm btn-outline-secondary none-desk btn-circle" @click="reservation_date = getDateTime()">
                            {{ $tl("labels.todayShort") }}
                        </button>
                    </h2>

                    <show-custom-events-btn class="none-mobile ml-2" :restaurantId="restaurantId" :date="reservation_date"> </show-custom-events-btn>
                </div>
                <div>
                    <span class="badge badge-success">
                        <strong>{{ $tl("labels.booking.menus.title", restaurantId) }}</strong>
                    </span>
                    <span class="badge badge-warning">
                        <strong>{{ $tl("labels.booking.menus.options.title", restaurantId) }}</strong>
                    </span>
                    <span class="badge badge-danger">
                        <strong>{{ $tl("labels.booking.generalOptions.title") }}</strong>
                    </span>
                </div>
                <button
                    style="margin-top: -8px"
                    type="button"
                    class="btn btn-sm btn-circle btn-outline-secondary"
                    :class="{ disabled: !has_right_to_export_reservations }"
                    :disabled="!has_right_to_export_reservations"
                    @click="exportReservations">
                    <feather type="upload"></feather>
                    {{ $tl("labels.form.actions.export") }}
                </button>
            </div>
            <div class="h-100 d-flex flex-column" style="margin-top: -10px" v-show="!error">
                <template v-if="services">
                    <div v-if="services.length > 0" class="h-100">
                        <reservations-per-service-for-kitchen
                            @show-service-full="$parent.$emit('show-service-full', $event)"
                            :services="services"
                            :restaurant="restaurant"
                            :reservation_date="reservation_date" />
                    </div>
                    <div class="pl-3" v-else-if="services.length === 0">
                        <div class="row m-0">
                            <div class="col-lg-6 offset-lg-3 pt-5 text-center">
                                <div class="border-light b-radius-20 p-4 mb-3">
                                    <h5 v-if="!isForbidden">{{ $tl("labels.booking.restaurant.closed") }}</h5>
                                    <h5 v-else>{{ $tl("errors.common.rights.notEnough") }}</h5>
                                    <img class="mt-4 none-mobile" width="340" src="/images/no-resa.png" alt="No Show" />
                                    <img class="mt-3 none-desk" width="250" src="/images/no-resa.png" alt="No Show" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import ReservationsPerServiceForKitchen from "../../components/reservations/ReservationsPerServiceForKitchen.vue";
import DatePicker from "../../components/forms/DatePicker.vue";
import showCustomEventsBtn from "../../components/customEvents/showCustomEventsBtn.vue";

export default {
    name: "ServiceKitchenInfo",
    components: { LoaderComponent, ReservationsPerServiceForKitchen, DatePicker, showCustomEventsBtn },
    props: ["restaurants", "user"],
    data() {
        return {
            loading: true,
            error: null,
            services: [],
            restaurant: {},
            isForbidden: false,
        };
    },
    computed: {
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        reservation_date_prev() {
            return this.reservation_date.minus({ days: 1 }).toISODate();
        },
        reservation_date_next() {
            return this.reservation_date.plus({ days: 1 }).toISODate();
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_export_reservations() {
            return this.rights.includes("booking.booking.export");
        },
        reservation_date: {
            get() {
                if (this.$route.query.date) {
                    return this.getDateTime(this.$route.query.date, false);
                }
                return this.getDateTime();
            },
            set(newVal) {
                this.changeDate(newVal.toISODate());
            },
        },
    },
    methods: {
        exportReservations() {
            this.$router.push({
                name: "booking.restaurants.exportReservations",
                query: { date: this.reservation_date.toISODate() },
            });
        },
        fetchData() {
            this.loading = true;
            this.unregisterAllSockets();
            return this.fetchRestaurant();
        },
        fetchRestaurant() {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/restaurants/${this.$route.params.restaurant_id}`)
                    .then((response) => {
                        this.restaurant = response.data;
                        this.fetchServices()
                            .then((resolved) => {
                                resolve(resolved);
                            })
                            .catch((rejected) => {
                                reject(rejected);
                            });
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.error = this.getErrorMsgFromErrorResponse(error);
                        reject(error);
                    });
            });
        },
        fetchServices() {
            return new Promise((resolve, reject) => {
                this.isForbidden = false;

                axios
                    .get(
                        `/api/restaurants/${
                            this.$route.params.restaurant_id
                        }/reservations?reservation_date=${this.reservation_date.toISODate()}&include=slot,client,menus,menus.options,general_options,tables`
                    )
                    .then((response) => {
                        this.$set(this, "services", response.data.data ?? []);
                        this.$nextTick(() => {
                            this.registerSocketForServices();
                        });
                        this.loading = false;
                        resolve(response.data.data);
                    })
                    .catch((error) => {
                        this.isForbidden = error.response && error.response.status === 403;
                        this.loading = false;
                        if (this.isForbidden) {
                            reject(error);
                            return;
                        }
                        this.error = this.getErrorMsgFromErrorResponse(error);
                        this.loading = false;
                        reject(error);
                    });
            });
        },
        changeDate(date) {
            if (date !== this.$route.query.date) {
                this.$router.push({
                    name: "booking.restaurants.reservationsCuisine",
                    params: { restaurant_id: this.$route.params.restaurant_id },
                    query: { date },
                });
            }
        },
        unregisterAllSockets() {
            this.$store.getters["sockets/getChannels"].forEach((channelName) => {
                Echo.leave(channelName);
            });
            this.$store.commit("sockets/removeAllChannels");
        },
        registerSocketForServices() {
            this.services.forEach((service) => {
                const channelName = `App.restaurant.${this.restaurant.id}.service.${service.id}.date.${this.reservation_date.toISODate()}`;

                Echo.private(channelName)
                    .listen(".reservation.deleted", this.onReservationDeleted)
                    .listen(".reservation.updated", this.onReservationUpdated)
                    .listen(".reservation.added", this.onReservationAdded)
                    .listen(".service.full.updated", this.onServiceFullChanged);

                this.$store.commit("sockets/addChannel", channelName);
            });
        },
        onReservationDeleted(e) {
            const deletedReservationId = Number.parseInt(e.reservation_id);
            const deletedReservationServiceId = Number.parseInt(e.service_id);

            if (!Number.isNaN(deletedReservationId) && !Number.isNaN(deletedReservationServiceId)) {
                this.reservationDeleted = { reservationId: deletedReservationId, serviceId: deletedReservationServiceId };

                this.services.find((service) => {
                    if (service.id === deletedReservationServiceId) {
                        const deletedReservationIndex = service.reservations.findIndex((reservation) => reservation.id === deletedReservationId);

                        if (deletedReservationIndex !== -1) {
                            service.reservations.splice(deletedReservationIndex, 1);
                            return true;
                        }
                    }

                    return false;
                });
            }
        },
        onReservationUpdated(e) {
            let updatedReservation = e.reservation;

            if (typeof updatedReservation !== "undefined") {
                for (let i = 0; i < this.services.length; i++) {
                    const service = this.services[i];
                    if (service.id === updatedReservation.slot.service.id) {
                        for (let j = 0; j < service.reservations.length; j++) {
                            const reservation = service.reservations[j];
                            if (reservation.id === updatedReservation.id) {
                                updatedReservation.updated = true;
                                this.$set(this.services[i].reservations, j, updatedReservation);
                                this.$nextTick(() => {
                                    setTimeout(() => {
                                        updatedReservation.updated = false;
                                        this.$set(this.services[i].reservations, j, updatedReservation);
                                    }, 1000);
                                });
                            }
                        }
                    }
                }
            }
        },
        onReservationAdded(e) {
            const newReservation = e.reservation;

            if (typeof newReservation !== "undefined" && newReservation.slot && newReservation.slot.service) {
                for (let i = 0; i < this.services.length; i++) {
                    const service = this.services[i];
                    if (service.id === newReservation.slot.service.id) {
                        newReservation.new = true;
                        var j = this.services[i].reservations.push(newReservation) - 1;
                        this.$nextTick(() => {
                            this.$nextTick(() => {
                                this.$nextTick(() => {
                                    newReservation.new = false;
                                    this.$set(this.services[i].reservations, j, newReservation);
                                });
                            });
                        });
                    }
                }
            }
        },
        onServiceFullChanged(e) {
            var serviceUpdated = e.service;

            for (let i = 0; i < this.services.length; i++) {
                const service = this.services[i];
                if (service.id === serviceUpdated.id) {
                    this.$set(this.services[i], "is_full", serviceUpdated.is_full);
                }
            }
        },
    },
    watch: {
        "$route.params.restaurant_id": function (id) {
            this.fetchData();
        },
        "$route.query.date": function (id) {
            this.fetchData();
        },
    },
    created() {
        this.fetchData();
    },
    beforeDestroy() {
        this.unregisterAllSockets();
    },
};
</script>
