<template>
    <div>
        <loader-component v-if="loading" />
        <div v-else class="mb-1" :class="{ 'd-flex align-items-center': !smallScreen }">
            <select id="client_type" name="type" v-model="clientType" class="custom-select m-0" :required="required" :disabled="readonly">
                <option :value="null">--</option>
                <option v-for="clientType in ALL_CLIENT_TYPES" :key="clientType.value" :value="clientType.value">
                    {{ getClientTypeLabel(clientType.value) }}
                </option>
                <option v-for="clientType in additionalClientTypes" :key="clientType" :value="clientType">
                    {{ clientType }}
                </option>
                <option :value="this.CLIENT_TYPE_CUSTOM.value">
                    {{ $tl("labels.clients.types.custom") }}
                </option>
            </select>
            <input
                v-if="clientType === this.CLIENT_TYPE_CUSTOM.value"
                class="form-control"
                :class="{ 'ml-1': !smallScreen, 'mt-1': smallScreen }"
                type="text"
                name="client_type_custom"
                v-model="clientTypeCustom" />
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent.vue";
import ClientTypesEnum from "../../../mixins/enums/ClientTypeEnum.js";

export default {
    data() {
        return {
            loading: false,
            additionalClientTypes: [],
        };
    },
    props: {
        value: {
            default: null,
        },
        restaurantId: {
            type: Number,
            required: true,
        },
        smallScreen: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [ClientTypesEnum],
    computed: {
        clientType: {
            get() {
                if (this.inEnum(this.value, this.ALL_CLIENT_TYPES) || this.additionalClientTypes.concat([null, ""]).includes(this.value)) {
                    return this.value;
                }

                return this.CLIENT_TYPE_CUSTOM.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        clientTypeCustom: {
            get() {
                if (this.clientType === this.CLIENT_TYPE_CUSTOM.value && this.value !== this.CLIENT_TYPE_CUSTOM.value) {
                    return this.value;
                }

                return null;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        clientTypes() {
            return this.ALL_CLIENT_TYPES.map((type) => type.value).concat(this.additionalClientTypes);
        },
    },
    methods: {
        fetchAdditionalClientTypes() {
            this.loading = true;

            this.httpGet(`/api/restaurants/${this.restaurantId}/clients/additionalTypes`)
                .then((response) => {
                    if (response !== false) {
                        this.additionalClientTypes = response.data.types;
                    }
                })
                .finally(() => (this.loading = false));
        },
    },
    created() {
        this.fetchAdditionalClientTypes();
    },
    components: {
        LoaderComponent,
    },
};
</script>
