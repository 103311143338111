<template>
    <div class="pb-5 pt-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Catégories</h5>
                    <button v-if="has_right_to_create_categories" @click="showAddCategoryModal = true" class="btn btn-sm btn-success mr-1 btn-circle">
                        <feather type="plus"></feather>
                        <span>Ajouter une catégorie</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-12">
                <LoaderComponent v-if="isLoading" />
                <div v-else>
                    <div v-if="error" class="alert alert-danger">{{ error }}</div>
                    <div v-else>
                        <div class="mb-3">
                            <table class="capitalize table table-sm table-striped border-bottom">
                                <thead class="border-bottom">
                                    <tr>
                                        <th>Ordre</th>
                                        <th>Nom</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <transition-group
                                    is="draggable"
                                    tag="tbody"
                                    v-model="categories"
                                    :disabled="!has_right_to_update_categories || selectedCategory !== null"
                                    :name="!isDragged ? 'flip-list' : null"
                                    @start="isDragged = true"
                                    @end="isDragged = false">
                                    <template v-for="category in categories">
                                        <tr
                                            :key="category.id"
                                            class="categories"
                                            v-if="selectedCategory === null || selectedCategory === category.id">
                                            <td style="width: 100px">
                                                <svg
                                                    v-if="selectedCategory === null"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="css-i6dzq1 feather mr-1"
                                                    style="cursor: grab; color: rgb(170, 170, 170)">
                                                    <line x1="3" y1="9" x2="21" y2="9"></line>
                                                    <line x1="3" y1="15" x2="21" y2="15"></line>
                                                </svg>
                                                <button v-else @click="selectedCategory = null" class="btn pl-0">
                                                    <feather type="chevron-left" class="feather-24" />
                                                </button>
                                            </td>
                                            <td>
                                                <strong v-if="selectedCategory !== null">{{ category.name }}</strong>
                                                <span v-else>{{ category.name }}</span>
                                            </td>
                                            <td style="width: 300px">
                                                <button
                                                    v-if="has_right_to_update_categories"
                                                    class="btn btn-sm btn-success btn-square"
                                                    title="Modifier la réservation"
                                                    @click="prepareEditCategory(category)"
                                                    v-tooltip="getTooltip('Éditer')">
                                                    <feather type="edit" />
                                                </button>
                                                <button
                                                    v-if="has_right_to_delete_categories && category.can_delete"
                                                    class="btn btn-sm btn-danger btn-square"
                                                    @click="deleteCategory(category.id)"
                                                    v-tooltip="getTooltip('Supprimer')">
                                                    <feather type="trash-2" />
                                                </button>
                                                <button
                                                    v-else
                                                    class="btn btn-sm btn-secondary btn-square"
                                                    v-tooltip="
                                                        getTooltip('Vous ne pouvez pas supprimer cette catégorie, car c\'est la catégorie par défaut')
                                                    ">
                                                    <feather type="trash-2" />
                                                </button>
                                                <button
                                                    v-if="
                                                        selectedCategory === null &&
                                                        has_right_to_update_categories &&
                                                        config &&
                                                        config.display_categories
                                                    "
                                                    class="btn btn-sm btn-outline-secondary btn-square"
                                                    @click="displayOneCategory(category.id)"
                                                    v-tooltip="getTooltip('Ordonner les produits de cette catégorie')">
                                                    <svg
                                                        viewBox="0 0 24 24"
                                                        width="24"
                                                        height="24"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        class="css-i6dzq1 feather">
                                                        <polyline points="9 5 12 2 15 5"></polyline>
                                                        <polyline points="15 19 12 22 9 19"></polyline>
                                                        <line x1="12" y1="2" x2="12" y2="22"></line>
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </template>
                                </transition-group>
                                <template v-if="selectedCategory !== null">
                                    <LoaderComponent v-if="isLoadingProduct" />
                                    <draggable tag="tbody" v-else v-model="products" :draggable="has_right_to_update_categories ? '.products' : ''">
                                        <tr class="products" v-for="product in products" :key="product.id">
                                            <td style="cursor: grab; width: 70px">
                                                <svg
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="css-i6dzq1 feather mr-1"
                                                    style="color: rgb(170, 170, 170)">
                                                    <line x1="3" y1="9" x2="21" y2="9"></line>
                                                    <line x1="3" y1="15" x2="21" y2="15"></line>
                                                </svg>
                                            </td>
                                            <td>{{ product.name }}</td>
                                        </tr>
                                    </draggable>
                                </template>
                            </table>
                            <add-gift-vouchers-category-modal
                                v-if="showAddCategoryModal"
                                :current-category="currentCategory"
                                @category-added="addCategory"
                                @category-edited="editCategory"
                                @close="showAddCategoryModal = false" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-0 mb-3" v-if="!isLoading && has_right_to_update_categories">
            <div class="col-12 text-muted">
                Vous avez la possibilité d'ordonner vos
                <template v-if="selectedCategory === null">catégories</template>
                <template v-else>produits</template> en cliquant sur l'icone
                <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="css-i6dzq1 feather mr-1"
                    style="color: rgb(170, 170, 170)">
                    <line x1="3" y1="9" x2="21" y2="9"></line>
                    <line x1="3" y1="15" x2="21" y2="15"></line>
                </svg>
                et en déplaçant la ligne. Pensez à enregistrer vos changements.
            </div>
        </div>

        <div class="row m-0" v-if="!isLoading">
            <div class="col-12">
                <button :disabled="!has_right_to_update_categories" @click="OrderItems" type="button" class="btn btn-success btn-circle">
                    Enregistrer
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent.vue";
import addGiftVouchersCategoryModal from "../../../components/Modals/giftVouchers/addGiftVouchersCategoryModal.vue";
import draggable from "vuedraggable";

export default {
    data() {
        return {
            isLoading: false,
            isDragged: false,
            error: null,
            categories: null,
            category: null,
            selectedCategory: null,
            products: null,
            isLoadingProduct: false,
            showAddCategoryModal: false,
            currentCategory: null,
            config: null,
        };
    },
    computed: {
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_create_categories() {
            return this.rights.includes("gift_vouchers.products.create");
        },
        has_right_to_update_categories() {
            return this.rights.includes("gift_vouchers.products.update");
        },
        has_right_to_delete_categories() {
            return this.rights.includes("gift_vouchers.products.delete");
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        baseApiUrl() {
            return `/api/gift_vouchers/${this.restaurant_id}/gvCategories`;
        },
        postOrdersUrl() {
            return this.baseApiUrl.concat(this.selectedCategory !== null ? `/${this.selectedCategory}/products/order` : "/order");
        },
        orders() {
            return this.selectedCategory !== null ? this.products : this.categories;
        },
    },
    methods: {
        getConfig() {
            this.loading = true;

            this.httpGet(`/api/gift_vouchers/${this.restaurant_id}/config`)
                .then((response) => {
                    if (response !== false) {
                        this.config = response.data.config;
                    }
                })
                .finally(() => (this.loading = false));
        },
        addCategory(event) {
            this.categories.push(event);
        },
        prepareEditCategory(category) {
            this.showAddCategoryModal = true;
            this.currentCategory = category;
        },
        editCategory(event) {
            const index = this.categories.findIndex((category) => category.id === event.id);

            this.categories[index] = event;
        },
        deleteCategory(categoryId) {
            if (!confirm("Êtes vous sûr de vouloir supprimer cette catégorie ?")) {
                return;
            }

            this.isLoading = true;

            this.httpDelete(`${this.baseApiUrl}/${categoryId}`)
                .then((response) => {
                    if (response !== false) {
                        this.selectedCategory = null;
                        this.categories = this.categories.filter((category) => category.id !== categoryId);
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        getCategories() {
            this.isLoading = true;

            this.httpGet(this.baseApiUrl)
                .then((response) => {
                    if (response !== false) {
                        this.categories = response.data.data;
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        getProducts() {
            this.isLoadingProduct = true;

            this.httpGet(`${this.baseApiUrl}/${this.selectedCategory}?include=gvProducts`)
                .then((response) => {
                    if (response !== false) {
                        this.products = response.data.gvProducts.data;
                    }
                })
                .finally(() => (this.isLoadingProduct = false));
        },
        displayOneCategory(categoryId) {
            this.selectedCategory = categoryId;
            this.getProducts();
        },
        OrderItems() {
            if (!this.has_right_to_update_categories) {
                return;
            }

            this.isLoading = true;

            this.httpPost(this.postOrdersUrl, { orders: this.orders }, { handleReject: false })
                .then((response) => {})
                .catch((error) => this.notifyError(error))
                .finally(() => (this.isLoading = false));
        },
    },
    components: {
        LoaderComponent,
        addGiftVouchersCategoryModal,
        draggable,
    },
    created() {
        this.getConfig();
        this.getCategories();
    },
};
</script>
<style>
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
</style>
