<template>
    <div class="h-100">
        <div v-if="!isBrowserIE" class="back-off h-100">
            <topbar />
            <div class="container-fluid">
                <div class="row">
                    <main role="main" class="default mr-sm-auto mt-5" :class="isAdmin || isCourier ? 'col-12' : 'col-lg-8 pl-0 pr-0'">
                        <notifications style="z-index: 9999" group="notification" />
                        <slot />
                    </main>
                    <sidebar
                        v-if="!isAdmin && !isCourier"
                        class="col-lg-4"
                        style="margin-top: 60px"
                        :user="user"
                        :entries="sidebarEntries"
                        @reload-router-view="$emit('reload-router-view')" />
                </div>
            </div>
        </div>
        <div v-else style="text-align: center">
            <h1>{{ $tl("errors.browser.invalid.title") }}</h1>
            <p v-html="$t('errors.browser.invalid.infos')"></p>
        </div>
    </div>
</template>

<script>
import Topbar from "./Topbar.vue";
import Sidebar from "./Sidebar.vue";
import RolesEnum from "../../mixins/enums/RolesEnum";

export default {
    name: "DefaultLayout",
    props: {
        user: {
            required: true,
        },
    },
    mixins: [RolesEnum],
    computed: {
        isAdmin() {
            return this.$store.getters["users/role"] === this.ROLE_ADMIN.value;
        },
        isCourier() {
            return this.$store.getters["users/role"] === this.ROLE_COURIER.value;
        },
        isOwner() {
            return this.$store.getters["users/role"] === this.ROLE_OWNER.value;
        },
        sidebarEntries() {
            return [
                {
                    to: { name: "home" },
                    titleI18n: "home",
                    rights: ["any"],
                },
                {
                    to: { name: "account" },
                    titleI18n: "account",
                    rights: ["any"],
                },
                {
                    to: { name: "restaurants" },
                    titleI18n: "restaurants",
                    rights: ["default.restaurants.read"],
                },
                {
                    to: { name: "subscription" },
                    titleI18n: "subscription",
                    rights: ["default.subscription.read"],
                    display: this.isNoShow,
                },
                {
                    to: { name: "rgpd" },
                    titleI18n: "rgpd",
                    rights: ["any"],
                    display: this.isOwner,
                },
                {
                    to: { name: "employees" },
                    titleI18n: "users",
                    rights: ["default.employees.read"],
                    display: this.isNoShow,
                },
                {
                    to: { name: "sms" },
                    titleI18n: "sms",
                    rights: ["default.sms.update"],
                },
                {
                    url: "#",
                    class: "open-intercom",
                    titleI18n: "help",
                    rights: ["any"],
                    display: this.isNoShow,
                },
                {
                    url: "#",
                    onClick: () => {
                        if (typeof Beacon !== "undefined") {
                            Beacon("open");
                        }
                    },
                    titleI18n: "help",
                    rights: ["any"],
                    display: this.isYservices,
                },
                {
                    url: "http://help.noshow.io/",
                    target: "_blank",
                    titleI18n: "documentation",
                    rights: ["any"],
                    display: this.isNoShow,
                },
                {
                    url: "https://aide.y-services.fr/",
                    target: "_blank",
                    titleI18n: "documentation",
                    rights: ["any"],
                    display: this.isYservices,
                },
                {
                    to: { name: "online_help" },
                    titleI18n: "onlineHelp",
                    rights: ["any"],
                    display: this.isNoShow,
                },
                {
                    to: { name: "app_updates.list" },
                    titleI18n: "appUpdates",
                    rights: ["any"],
                },
                {
                    to: { name: "restaurants.reserve_with_google" },
                    titleI18n: "rwg",
                    rights: ["any"],
                    display: this.isReserveWithGoogleEnabled() && this.isOwner,
                },
            ];
        },
    },
    data: function () {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        };
    },
    components: {
        Topbar,
        Sidebar,
    },
};
</script>
