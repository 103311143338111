<template>
    <NsTable tableClass="table table-striped" allow-sorting :data-source="moduleSubscriptions">
        <template v-slot:head>
            <ns-th field="type" allow-sorting>Module</ns-th>
            <ns-th class="text-right" field="total_clients" allow-sorting>Nombre de clients</ns-th>
            <ns-th class="text-right" field="total_subscriptions" allow-sorting>Nombre d'abonnements</ns-th>
            <ns-th class="text-right" field="monthly_subscriptions" allow-sorting>Dont abonnements mensuels</ns-th>
            <ns-th class="text-right" field="yearly_subscriptions" allow-sorting>Dont abonnements annuels</ns-th>
        </template>
        <template v-slot:body="{ data: moduleSubscription }">
            <td>{{ getModuleTypeLabel(moduleSubscription.type) }}</td>
            <td class="text-right">{{ moduleSubscription.total_clients }}</td>
            <td class="text-right">{{ moduleSubscription.total_subscriptions }}</td>
            <td class="text-right">{{ moduleSubscription.monthly_subscriptions }}</td>
            <td class="text-right">{{ moduleSubscription.yearly_subscriptions }}</td>
        </template>
    </NsTable>
</template>

<script>
import NsTable from "../Datatable/NsTable.vue";
import NsTh from "../Datatable/NsTh.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum";

export default {
    components: {
        NsTh,
        NsTable,
    },
    mixins: [ModuleTypesEnum],
    data() {
        return {
            moduleSubscriptions: [],
        };
    },
    created() {
        this.httpGet("/api/admin/subscriptions/all-by-module").then((response) => {
            this.moduleSubscriptions = response.data;
        });
    },
};
</script>
