var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _vm.service.isOpen
        ? _c("div", [
            _c("strong", { staticStyle: { "text-transform": "capitalize" } }, [
              _vm._v(_vm._s(_vm.getServiceCategoryLabel(_vm.service))),
            ]),
            _vm._v(" "),
            _vm.service.is_full === 1 || _vm.service.isOpen == false
              ? _c(
                  "small",
                  {
                    staticClass:
                      "text-uppercase ml-2 d-inline-block badge-pill badge-danger text-white-hover",
                  },
                  [
                    _vm._v(
                      "\n             " +
                        _vm._s(
                          _vm.service.is_full === 1
                            ? _vm.$tl("labels.booking.services.full")
                            : _vm.$tl("labels.booking.services.closedSpecial")
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            _c("br"),
            _vm._v(" "),
            _c("small", [_vm._v(_vm._s(_vm.service.name))]),
            _vm._v(
              " - " +
                _vm._s(_vm.service.pax) +
                " / " +
                _vm._s(
                  _vm.service.new_pax !== null
                    ? _vm.service.new_pax
                    : _vm.service.max_pax
                )
            ),
            _c("br"),
            _vm._v(" "),
            _vm.service.pax >
            (_vm.service.new_pax !== null
              ? _vm.service.new_pax
              : _vm.service.max_pax)
              ? _c("strong", { staticStyle: { color: "red" } }, [
                  _vm._v(_vm._s(_vm.$tl("labels.paxTooHigh"))),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "radio mt-3 mb-2",
                staticStyle: { "padding-left": "0" },
              },
              _vm._l(_vm.service.slotsWithPax.data, function (slot) {
                return _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.hasTablesSelected
                          ? {
                              content: _vm.$tl(
                                "infos.booking.reservations.edit.slot",
                                _vm.restaurant_id
                              ),
                            }
                          : undefined,
                        expression:
                          "\n                    hasTablesSelected\n                        ? {\n                              content: $tl('infos.booking.reservations.edit.slot', restaurant_id),\n                          }\n                        : undefined\n                ",
                      },
                    ],
                    key: slot.id,
                    staticClass: "mb-2",
                    on: {
                      click: function ($event) {
                        _vm.hasTablesSelected
                          ? _vm.$emit("unlink-all-tables")
                          : undefined
                      },
                    },
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "container-box",
                        class: { "cursor-disabled": _vm.readOnly },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.slotId,
                              expression: "slotId",
                            },
                          ],
                          attrs: {
                            disabled: _vm.readOnly,
                            type: "radio",
                            name: "slot",
                          },
                          domProps: {
                            value: slot.id,
                            checked: _vm._q(_vm.slotId, slot.id),
                          },
                          on: {
                            change: function ($event) {
                              _vm.slotId = slot.id
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "checkmark",
                          class: { "cursor-disabled": _vm.readOnly },
                          style: _vm.readOnly ? "opacity: 0.5;" : "",
                        }),
                        _vm._v(
                          "\n                    " + _vm._s(slot.hour_start)
                        ),
                        _vm.service.is_table_rotation_enabled == true
                          ? _c("span", { staticClass: "none-mobile" }, [
                              _vm._v(
                                "\n                        - " +
                                  _vm._s(slot.pax) +
                                  " /\n                        " +
                                  _vm._s(
                                    _vm.service.is_slot_max_pax_enabled &&
                                      slot.is_max_pax_enabled &&
                                      slot.max_pax
                                      ? slot.max_pax
                                      : _vm.service.new_pax !== null
                                      ? _vm.service.new_pax
                                      : _vm.service.max_pax
                                  ) +
                                  "\n                        " +
                                  _vm._s(_vm.$tl("labels.personsShort"))
                              ),
                            ])
                          : _c("span", { staticClass: "none-mobile" }, [
                              _vm._v(
                                "\n                        - " +
                                  _vm._s(slot.pax_real) +
                                  "\n                        " +
                                  _vm._s(
                                    _vm.service.is_slot_max_pax_enabled &&
                                      slot.is_max_pax_enabled &&
                                      slot.max_pax
                                      ? `/ ${slot.max_pax}`
                                      : ``
                                  ) +
                                  "\n                        " +
                                  _vm._s(_vm.$tl("labels.personsShort"))
                              ),
                            ]),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }