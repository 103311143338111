var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _vm.error
        ? _c("span", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _c("div", [
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col-md-5 pt-1" }, [
                      _c("label", [
                        _vm._v(
                          "\n                                Nom\n                                "
                        ),
                        _c("small", [_vm._v("*")]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-7 mb-2" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.category.name,
                              expression: "category.name",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.category.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.category,
                                "name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("ShowErrors", {
                          attrs: { errors: _vm.errors, errorKey: "name" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-5 pt-1" }, [
                      _c(
                        "label",
                        {
                          class: {
                            "text-muted":
                              !_vm.category.is_stock_per_slot_editable,
                          },
                        },
                        [_vm._v("Stock / Créneau")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7 mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.category.stock_per_slot,
                                  expression: "category.stock_per_slot",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                disabled:
                                  _vm.stock_per_slot_nolimit ||
                                  !_vm.category.is_stock_per_slot_editable,
                                type: "number",
                              },
                              domProps: { value: _vm.category.stock_per_slot },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.category,
                                    "stock_per_slot",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-8 mt-1" }, [
                            _c(
                              "label",
                              {
                                staticClass: "container-box",
                                staticStyle: { width: "initial" },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.stock_per_slot_nolimit,
                                      expression: "stock_per_slot_nolimit",
                                    },
                                  ],
                                  attrs: {
                                    disabled:
                                      !_vm.category.is_stock_per_slot_editable,
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.stock_per_slot_nolimit
                                    )
                                      ? _vm._i(
                                          _vm.stock_per_slot_nolimit,
                                          null
                                        ) > -1
                                      : _vm.stock_per_slot_nolimit,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.stock_per_slot_nolimit,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.stock_per_slot_nolimit =
                                              $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.stock_per_slot_nolimit = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.stock_per_slot_nolimit = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "checkmark" }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "text-muted":
                                        !_vm.category
                                          .is_stock_per_slot_editable,
                                    },
                                  },
                                  [_vm._v("Illimité")]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "col-12",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "stock_per_slot",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-5 pt-1" }, [
                      _c("label", [_vm._v("Stock / Service")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7 mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.category.stock_per_service,
                                  expression: "category.stock_per_service",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                disabled: _vm.stock_per_service_nolimit,
                                type: "number",
                              },
                              domProps: {
                                value: _vm.category.stock_per_service,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.category,
                                    "stock_per_service",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-8 mt-1" }, [
                            _c(
                              "label",
                              {
                                staticClass: "container-box",
                                staticStyle: { width: "initial" },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.stock_per_service_nolimit,
                                      expression: "stock_per_service_nolimit",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.stock_per_service_nolimit
                                    )
                                      ? _vm._i(
                                          _vm.stock_per_service_nolimit,
                                          null
                                        ) > -1
                                      : _vm.stock_per_service_nolimit,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.stock_per_service_nolimit,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.stock_per_service_nolimit =
                                              $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.stock_per_service_nolimit = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.stock_per_service_nolimit = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "checkmark" }),
                                _vm._v(
                                  " Illimité\n                                    "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "col-12",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "stock_per_service",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-5 pt-1" }, [
                      _c("label", [_vm._v("Stock / Jour")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-7" }, [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.category.stock_per_day,
                                  expression: "category.stock_per_day",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                disabled: _vm.stock_per_day_nolimit,
                                type: "number",
                              },
                              domProps: { value: _vm.category.stock_per_day },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.category,
                                    "stock_per_day",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-8 mt-1" }, [
                            _c(
                              "label",
                              {
                                staticClass: "container-box",
                                staticStyle: { width: "initial" },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.stock_per_day_nolimit,
                                      expression: "stock_per_day_nolimit",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.stock_per_day_nolimit
                                    )
                                      ? _vm._i(
                                          _vm.stock_per_day_nolimit,
                                          null
                                        ) > -1
                                      : _vm.stock_per_day_nolimit,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.stock_per_day_nolimit,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.stock_per_day_nolimit =
                                              $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.stock_per_day_nolimit = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.stock_per_day_nolimit = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "checkmark" }),
                                _vm._v(
                                  " Illimité\n                                    "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("ShowErrors", {
                            staticClass: "col-12",
                            attrs: {
                              errors: _vm.errors,
                              errorKey: "stock_per_day",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }