var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row m-0 pb-3" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm.loading > 0
              ? _c("LoaderComponent")
              : _c("div", [
                  _vm.error
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.error) +
                            "\n                "
                        ),
                      ])
                    : _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between mb-2",
                            },
                            [
                              _c("h5", [_vm._v("Commandes à expédier")]),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-sm btn-outline-secondary btn-circle",
                                  attrs: {
                                    disabled: !_vm.has_right_to_export_commands,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.exportCommands()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "feather feather-upload",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        fill: "none",
                                        stroke: "currentColor",
                                        "stroke-width": "2",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("polyline", {
                                        attrs: { points: "17 8 12 3 7 8" },
                                      }),
                                      _vm._v(" "),
                                      _c("line", {
                                        attrs: {
                                          x1: "12",
                                          y1: "3",
                                          x2: "12",
                                          y2: "15",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                            Export Excel\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("command-list", {
                            attrs: {
                              restaurant_id: _vm.restaurant_id,
                              commands: _vm.commands,
                              type: _vm.COLLECT_TYPE_DISPATCH.value,
                              columns: {
                                number: true,
                                client: true,
                                status: {
                                  show: true,
                                  readonly: false,
                                },
                                date: true,
                                slot: true,
                                sendcloud_status: true,
                                dispatch_fees: true,
                                price: true,
                                products: true,
                                actions: {
                                  edit: false,
                                  send: true,
                                  clickable: true,
                                  download_label: true,
                                  print_order_form: true,
                                },
                              },
                            },
                            on: {
                              "status-updated": function ($event) {
                                return _vm.fetchData(_vm.links.current)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.links.total !== null
        ? _c(
            "span",
            { staticClass: "badge badge-secondary ml-3 mr-2 float-left mt-1" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.links.total) +
                  " commande" +
                  _vm._s(_vm.links.total > 1 ? "s" : "") +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.links.previous || _vm.links.next
        ? _c("div", { staticClass: "ml-3" }, [
            _vm.links.previous
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-outline-secondary btn-circle",
                    on: {
                      click: function ($event) {
                        return _vm.fetchData(_vm.links.previous)
                      },
                    },
                  },
                  [_vm._v("\n            Précédent\n        ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.links.next
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-outline-secondary btn-circle",
                    on: {
                      click: function ($event) {
                        return _vm.fetchData(_vm.links.next)
                      },
                    },
                  },
                  [_vm._v("\n            Suivant\n        ")]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.modals.displayShowCommandModal
        ? _c("show-command-modal", {
            attrs: {
              command_id: _vm.modals.params.selected_command_id,
              restaurant_id: _vm.modals.params.selected_restaurant_id,
            },
            on: {
              close: function ($event) {
                _vm.modals.displayShowCommandModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [_c("h5", { staticClass: "title mt-2" }, [_vm._v("Ventes")])]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }