var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5 pt-5" },
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : [
            _c("div", { staticClass: "row m-0" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                  },
                  [_c("h5", { staticClass: "title mt-2" }, [_vm._v("CGV")])]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row m-0 mb-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("strong", [
                        _vm._v(
                          "Informations concernant les Conditions Générales de Vente"
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.cc_api_key
                                ? undefined
                                : {
                                    content:
                                      "Vous devez générer votre clé d'API pour voir les CGV par défaut",
                                  },
                              expression:
                                "\n                                    cc_api_key\n                                        ? undefined\n                                        : {\n                                              content: 'Vous devez générer votre clé d\\'API pour voir les CGV par défaut',\n                                          }\n                                ",
                            },
                          ],
                          staticClass:
                            "btn btn-sm btn-circle btn-success float-right",
                          class: { disabled: !_vm.cc_api_key },
                          staticStyle: { "pointer-events": "inherit" },
                          attrs: { target: "_blank", href: _vm.cgvUrl },
                        },
                        [
                          _vm._v(
                            "\n                                Voir les CGV par défaut\n                            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("p", [
                          _vm._v(
                            "\n                                Nous mettons à votre disposition des Conditions Générales de Vente standards pour vous permettre de mettre en\n                                place le système de vente à emporter rapidement et dans les meilleures conditions. Nous vous invitons à en prendre\n                                connaissance attentivement.\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n                                Afin de vous informer au mieux et de comprendre les enjeux de bonnes CGV, nous vous invitons à lire la\n                                documentation officielle\n                                "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: "https://www.francenum.gouv.fr/guides-et-conseils/developpement-commercial/site-e-commerce/rediger-des-conditions-generales-de",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                    en cliquant ici.\n                                "
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n                                Vous pouvez donc rédiger vous-même vos CGV, vous faire assister par votre avocat ou contacter nos services\n                                compétents dans la rédaction de CGV adaptées a vos besoins :\n                                "
                          ),
                          _c(
                            "a",
                            {
                              attrs: { href: "mailto:contact@yproximite.com" },
                            },
                            [_vm._v("contact@yproximite.com.")]
                          ),
                        ]),
                        _vm._v(" "),
                        !_vm.isDeliveryEnabled
                          ? [
                              _c("p", [
                                _vm._v(
                                  "\n                                    Nous vous conseillons toutefois d'intégrer vos propres Conditions Générales de Vente en important votre\n                                    fichier ci-dessous.\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "mb-0 text-danger" }, [
                                _vm._v(
                                  "Sans action de votre part, les conditions par défaut seront appliquées."
                                ),
                              ]),
                            ]
                          : _c(
                              "p",
                              {
                                staticClass:
                                  "mb-0 text-danger font-weight-bold",
                              },
                              [
                                _vm._v(
                                  "\n                                Veillez à intégrer vos propres Conditions Générales de Vente en lien avec la livraison.\n                            "
                                ),
                              ]
                            ),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-7 mt-2" },
                      [
                        _c("FileUploaderComponent", {
                          attrs: {
                            disabled: !_vm.has_right_to_update_config,
                            src: _vm.cgv,
                            suffixId: "cgv",
                            postURI: _vm.baseURIPdf,
                            deleteURI: _vm.baseURIPdf,
                            callBackPost: (response) => {
                              this.cgv = response.data.file_name
                            },
                            callBackDelete: () => {
                              this.cgv = null
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }