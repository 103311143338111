export default {
    data() {
        return {
            CLIENT_TYPE_BUSINESS: {
                value: "business",
            },
            CLIENT_TYPE_TOURIST: {
                value: "tourist",
            },
            CLIENT_TYPE_SINGLE: {
                value: "single",
            },
            CLIENT_TYPE_GROUP: {
                value: "group",
            },
            CLIENT_TYPE_JOURNALIST: {
                value: "journalist",
                featherIcon: "pen-tool",
                featherClass: "feather-blue",
            },
            CLIENT_TYPE_MICHELIN_INSPECTOR: {
                value: "michelin_inspector",
                featherIcon: "award",
                featherClass: "feather-blue",
            },
            CLIENT_TYPE_GAULT_MILLAU_INSPECTOR: {
                value: "gault_millau_inspector",
                featherIcon: "award",
                featherClass: "feather-blue",
            },
            CLIENT_TYPE_CUSTOM: {
                value: "custom",
            },
        };
    },
    methods: {
        getClientTypeLabel(value) {
            const found = this.ALL_CLIENT_TYPES.find((type) => type.value === value);

            return typeof found !== "undefined" ? this.$t(`labels.clients.types.${value}`) : value;
        },
        getClientTypeFeatherInfo(value) {
            const found = this.ALL_CLIENT_TYPES.find((type) => type.value === value);

            var type = "info";
            var featherClass = "feather-blue";

            if (typeof found !== "undefined") {
                type = found.featherIcon || type;
                featherClass = found.featherClass || featherClass;
            }

            return {
                type,
                class: featherClass,
                tooltip: this.getTooltip(this.getClientTypeLabel(value)),
            };
        },
    },
    computed: {
        ALL_CLIENT_TYPES() {
            return [
                this.CLIENT_TYPE_BUSINESS,
                this.CLIENT_TYPE_TOURIST,
                this.CLIENT_TYPE_SINGLE,
                this.CLIENT_TYPE_GROUP,
                this.CLIENT_TYPE_JOURNALIST,
                this.CLIENT_TYPE_MICHELIN_INSPECTOR,
                this.CLIENT_TYPE_GAULT_MILLAU_INSPECTOR,
            ];
        },
        CLIENT_TYPES_WITH_SPECIFIC_ICONS() {
            return this.ALL_CLIENT_TYPES.filter((clientType) => typeof clientType.featherIcon !== "undefined");
        },
    },
};
