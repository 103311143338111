<template>
    <div ref="rootElement" class="blur-set main-detail-box p-3" tabindex="-1" @blur="onBlur">
        <LoaderComponent :i18nAvailable="false" v-if="loading" />
        <span v-else-if="fatalError" class="text-danger">{{ fatalError }}</span>
        <form v-else-if="product" @submit="postNewStock">
            <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center border-bottom-light pb-2">
                    <strong style="font-size: 0.9rem">{{ product.name }}</strong>
                    <span v-if="!isGeneralStock">{{ moment(date).format("DD/MM") }}</span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-5">
                    <label>Rupture de stock</label>
                </div>
                <div class="col-md-7">
                    <div class="d-inline-block align-middle lead-switch">
                        <input
                            type="checkbox"
                            class="switch align-self-center is-rounded"
                            v-model="product_data.out_of_stock"
                            :true-value="true"
                            :false-value="false"
                            :disabled="product_data.out_of_stock && isGeneralStock"
                            :class="{ disabled: product_data.out_of_stock && isGeneralStock }" />
                        <label @click="toggleOutOfStock"></label>
                    </div>
                    <ShowErrors class="d-block" :errors="errors" errorKey="out_of_stock" />
                </div>
            </div>
            <div class="row mt-2" v-if="!isGeneralStock">
                <div class="col-md-5 pt-2">
                    <label>Modifier le stock</label>
                </div>
                <div class="col-md-7">
                    <input
                        type="number"
                        class="form-control d-inline"
                        style="width: 60px; padding-right: 5px"
                        v-model="product_data.new_stock"
                        min="1"
                        step="1"
                        :disabled="product_data.out_of_stock"
                        :class="{
                            disabled: product_data.out_of_stock,
                            'cursor-not-allowed': product_data.out_of_stock,
                        }" />
                    <feather
                        v-if="!product_data.out_of_stock && product_data.new_stock && product_data.id"
                        type="x"
                        class="text-danger feather-20 d-inline-block pointer"
                        v-tooltip="{ delay: { show: 400, hide: 0 }, content: 'Utiliser le stock par défaut' }"
                        @click="resetNewStock" />
                    <ShowErrors class="d-block" :errors="errors" errorKey="new_stock" />
                </div>
            </div>
            <div class="row mt-2" v-if="!isGeneralStock">
                <div class="col-12 text-right">
                    <button
                        class="btn btn-circle btn-sm btn-success"
                        type="submit"
                        :disabled="product_data.out_of_stock"
                        :class="{ disabled: product_data.out_of_stock }">
                        Enregistrer
                    </button>
                </div>
            </div>
            <div class="row mt-2" v-else>
                <div class="col-12">
                    <small class="text-muted">La gestion du stock s'effectue sur la fiche produit</small>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import moment from "moment";
import LoaderComponent from "../../LoaderComponent";
import ShowErrors from "../../errors/ShowErrors";
import StockTypeEnum from "../../../mixins/enums/click_and_collect/StockTypeEnum";

export default {
    data() {
        return {
            moment,
            loading: false,
            fatalError: null,
            errors: null,
            product: null,
            product_data: {
                id: null,
                out_of_stock: false,
                new_stock: null,
                date: null,
                group_id: null,
            },
        };
    },
    mixins: [StockTypeEnum],
    props: {
        product_id: {
            type: Number,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
        restaurant_id: {
            type: Number,
            required: true,
        },
        eventHub: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isGeneralStock() {
            return this.product && this.product.stock !== null && this.product.stock_type === this.GENERAL_STOCK.value;
        },
    },
    methods: {
        toggleOutOfStock() {
            if (this.product_data.out_of_stock && this.isGeneralStock) return;
            this.product_data.out_of_stock = !this.product_data.out_of_stock;
            this.postOutOfStock();
        },
        isDescendantOfTooltip(child) {
            if (child == null) {
                return false;
            }

            var node = child.parentNode;

            while (node !== null) {
                if (node.classList && node.classList.contains("e-tooltip-wrap")) {
                    return true;
                }
                node = node.parentNode;
            }

            return false;
        },
        onBlur(e) {
            if (this.eventHub && !this.isDescendantOfTooltip(e.relatedTarget)) {
                this.eventHub.$emit("blur");
            } else if (
                e.relatedTarget !== null &&
                this.isDescendantOfTooltip(e.relatedTarget) &&
                !e.relatedTarget.classList.contains("blur-set")
            ) {
                e.relatedTarget.onblur = this.onBlur;
            }
        },
        fetchData() {
            this.loading = true;
            this.fatalError = null;

            axios
                .get(`/api/click_and_collect/${this.restaurant_id}/products/spec/${this.product_id}`, {
                    params: {
                        date: this.date,
                        include: "unavailability",
                    },
                })
                .then((response) => {
                    this.loading = false;
                    this.product = response.data;
                    if (this.product.unavailability) {
                        Object.assign(this.product_data, this.product.unavailability);
                    } else if (this.product.stock_type === this.GENERAL_STOCK.value && this.product.stock === 0)
                        this.product_data.out_of_stock = true;
                    this.product_data.group_id = this.product.group_id;
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.data.message) this.fatalError = error.response.data.message;
                    else if (error.message) this.fatalError = error.message;
                    else this.fatalError = "Une erreur est survenue";
                });
        },
        postNewStock(e) {
            if (e) e.preventDefault();
            this.post(`/api/click_and_collect/${this.restaurant_id}/products/new_stock/${this.product_id}`);
        },
        postOutOfStock() {
            this.post(`/api/click_and_collect/${this.restaurant_id}/products/out_of_stock/${this.product_id}`);
        },
        resetNewStock() {
            this.post(`/api/click_and_collect/${this.restaurant_id}/products/reset_new_stock/${this.product_id}`);
        },
        post(url) {
            this.loading = true;
            this.errors = null;

            axios
                .post(url, {
                    ...this.product_data,
                })
                .then((response) => {
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    if (response.data.pu_id) this.product_data.id = response.data.pu_id;
                    this.eventHub.$emit("product-unavailability-saved", this.product_data);
                })
                .catch((error) => {
                    this.loading = false;
                    let errorMsg = "Une erreur est survenue";
                    if (error.response && error.response.data.message) errorMsg = error.response.data.message;
                    else if (error.message) errorMsg = error.message;
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: errorMsg,
                    });
                });
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
    created() {
        this.product_data.date = this.date;
        this.fetchData();
    },
    mounted() {
        if (this.eventHub)
            this.eventHub.$on("set-main-focus", () => {
                this.$refs.rootElement.focus();
            });
    },
};
</script>
