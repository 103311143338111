var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "default-sidebar sticky" }, [
    _c(
      "ul",
      { staticClass: "text-white mt-5 ml-4" },
      [
        _c(
          "h5",
          {
            staticClass: "title main text-uppercase mb-4 text-white",
            staticStyle: { "padding-left": "1rem" },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.myAccount")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.entries, function (entry, index) {
          return [
            typeof entry.display === "undefined" || entry.display === true
              ? _c("SidebarNavItem", {
                  key: index,
                  attrs: { entry: entry, user: _vm.user },
                })
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }