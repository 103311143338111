<template>
    <div class="row">
        <div class="col-12">
            <div class="border-light b-radius-20 p-4 mt-3">
                <div class="row mb-3">
                    <div class="col-12 mb-3">
                        <strong>{{ $tl("labels.form.sms.edit") }}</strong>
                    </div>
                    <div class="col-md-4">
                        <label class="mb-1">
                            {{ $tl("labels.form.sms.emitterName") }}
                            <feather
                                v-tooltip="getTooltipNotice('notices.booking.notifications.phoneName')"
                                type="info"
                                class="ml-1 pointer tooltip-infos" />
                        </label>
                        <small class="d-block">{{ $t("infos.form.maxChars", { nb: 11 }) }}</small>
                    </div>
                    <div class="col-md-8">
                        <input
                            :class="{
                                'is-invalid': !restaurant.notifications_setting.sms_emitter_name.match(/^[a-z]+$/i),
                            }"
                            :disabled="!has_right_to_update_restaurant"
                            type="text"
                            v-model="restaurant.notifications_setting.sms_emitter_name"
                            class="form-control mb-2"
                            maxlength="11" />
                        <span class="limiter text-muted pl-2">{{ $t("infos.form.remainingChars", { nb: sms_emitter_name_count }) }}</span>
                        <div class="text-danger" v-if="hasErrors('sms_emitter_name')" v-html="joinErrors('sms_emitter_name')"></div>
                    </div>
                    <div class="col-md-4 col-10 mt-3">
                        <label class="mb-1">
                            {{ $tl("labels.form.sms.sendToForeign") }}
                            <feather
                                v-tooltip="getTooltipNotice('notices.booking.notifications.strangerSms')"
                                type="info"
                                class="ml-1 pointer tooltip-infos" />
                        </label>
                        <a href="#" class="d-block" @click="showInternationalSmsCostModal = true"
                            ><small>{{ $tl("infos.form.sms.foreignCost") }}</small></a
                        >
                    </div>
                    <div class="col-md-8 col-2 mt-3">
                        <label class="container-box">
                            <input
                                :disabled="!has_right_to_update_restaurant"
                                type="checkbox"
                                v-model="restaurant.notifications_setting.enable_international_sms" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div v-if="isStandAlone" class="row">
                    <div class="col-sm-2">
                        <button type="button" class="btn btn-success btn-circle btn-sm" @click.prevent.stop="updateConfigSms" :disabled="isLoading">
                            {{ $tl("labels.form.actions.save") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <InternationalSmsCostModal v-if="showInternationalSmsCostModal" @close="showInternationalSmsCostModal = false" />
    </div>
</template>

<script>
import InternationalSmsCostModal from "../Modals/sms/InternationalSmsCostModal.vue";
import SmsUtils from "../../mixins/helpers/SmsUtils.js";
import RolesEnum from "../../mixins/enums/RolesEnum.js";

export default {
    data() {
        return {
            isLoading: false,
            showInternationalSmsCostModal: false,
        };
    },
    props: {
        restaurant: {
            type: Object,
            required: true,
        },
        isStandAlone: {
            type: Boolean,
            default: false,
        },
        formErrors: {
            default: null,
        },
    },
    mixins: [RolesEnum, SmsUtils],
    computed: {
        userRole() {
            return this.$store.getters["users/role"];
        },
        isAdmin() {
            return this.userRole === this.ROLE_ADMIN.value;
        },
        rights() {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_restaurant() {
            return this.isAdmin || this.rights.includes("default.restaurants.update");
        },
        sms_emitter_name_count() {
            return this.maxEmitterLength - this.restaurant.notifications_setting.sms_emitter_name.length;
        },
    },
    methods: {
        hasErrors(key) {
            return this.formErrors !== null && this.formErrors[key] && this.formErrors[key].length > 0;
        },
        joinErrors(key) {
            return this.formErrors[key].join("<br/>");
        },
        updateConfigSms() {
            const formData = {
                sms_emitter_name: this.restaurant.notifications_setting.sms_emitter_name,
                enable_international_sms: this.restaurant.notifications_setting.enable_international_sms,
            };
            this.isLoading = true;
            this.httpPost(`/api/restaurants/${this.restaurant.id}/sms_settings`, formData, { handleReject: false })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        this.$emit("update-errors", error.response.data.errors);
                    }
                })
                .finally(() => (this.isLoading = false));
        },
    },
    components: {
        InternationalSmsCostModal,
    },
    watch: {
        "restaurant.notifications_setting.sms_emitter_name": function (newVal, oldVal) {
            if (this.formErrors !== null) {
                this.$emit("update-errors", null);
            }
            if (!oldVal || !newVal || newVal.length < oldVal.length) {
                return;
            }
            if (newVal.length > 0 && !newVal.replace(oldVal, "").match(/^[a-z]+$/i)) {
                this.restaurant.notifications_setting.sms_emitter_name = oldVal;
            }
        },
    },
};
</script>
