var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c(
        "div",
        { staticClass: "border-light b-radius-20 p-4" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-5 pt-2" }, [
              _c("label", { attrs: { for: "category" } }, [
                _vm._v(
                  _vm._s(_vm.$tl("labels.booking.services.category")) + " "
                ),
                _c("small", [_vm._v("*")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-7" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.service.general.category.value,
                      expression: "service.general.category.value",
                    },
                  ],
                  staticClass: "custom-select",
                  attrs: { name: "category", id: "category", required: "" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.service.general.category,
                        "value",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(
                  _vm.ALL_DEFAULT_SERVICE_CATEGORIES_WITH_OTHER,
                  function (serviceCategory) {
                    return _c(
                      "option",
                      {
                        key: serviceCategory.value,
                        domProps: { value: serviceCategory.value },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.getDefaultServiceCategoryLabel(
                                serviceCategory.value
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    )
                  }
                ),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.service.general.category.value === "autre"
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-5 pt-2" }, [
                  _c("label", { attrs: { for: "category" } }, [
                    _vm._v(
                      _vm._s(_vm.$tl("labels.booking.services.categoryName")) +
                        " (" +
                        _vm._s(_vm.$tl("labels.langs.fr")) +
                        ")"
                    ),
                    _c("small", [_vm._v("*")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-7 pt-2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.service.general.category.nameFr,
                        expression: "service.general.category.nameFr",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "custom_category_fr",
                      id: "custom_category_fr",
                      required: "",
                    },
                    domProps: { value: _vm.service.general.category.nameFr },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.service.general.category,
                          "nameFr",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-5 pt-2" }, [
                  _c("label", { attrs: { for: "category" } }, [
                    _vm._v(
                      _vm._s(_vm.$tl("labels.booking.services.categoryName")) +
                        " (" +
                        _vm._s(_vm.$tl("labels.langs.en")) +
                        ")"
                    ),
                    _c("small", [_vm._v("*")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-7 pt-2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.service.general.category.nameEn,
                        expression: "service.general.category.nameEn",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "custom_category_en",
                      id: "custom_category_en",
                      required: "",
                    },
                    domProps: { value: _vm.service.general.category.nameEn },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.service.general.category,
                          "nameEn",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-5 pt-2" }, [
              _c(
                "label",
                { attrs: { for: "name" } },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.isSpecial
                          ? _vm.$tl("labels.form.name")
                          : _vm.$tl(
                              "labels.booking.services.name",
                              _vm.restaurant.id
                            )
                      ) +
                      "\n                        "
                  ),
                  _c("small", [_vm._v("*")]),
                  _vm._v(" "),
                  _c("feather", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltipNoticeLexicon(
                          "notices.booking.servicesConfig.serviceName",
                          _vm.restaurant.id
                        ),
                        expression:
                          "getTooltipNoticeLexicon('notices.booking.servicesConfig.serviceName', restaurant.id)",
                      },
                    ],
                    staticClass: "ml-1 pointer tooltip-infos",
                    attrs: { type: "info" },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-7" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.service.general.name,
                    expression: "service.general.name",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: "name", id: "name", required: "" },
                domProps: { value: _vm.service.general.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.service.general, "name", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _vm.isSpecial
            ? _c("div", [
                _c("div", [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("strong", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$tl("labels.booking.services.special.dates")
                              ) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("feather", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getTooltipNoticeLexicon(
                                "notices.booking.servicesConfig.exceptionalOpening",
                                _vm.restaurant.id
                              ),
                              expression:
                                "getTooltipNoticeLexicon('notices.booking.servicesConfig.exceptionalOpening', restaurant.id)",
                            },
                          ],
                          staticClass: "ml-1 pointer tooltip-infos",
                          attrs: { type: "info" },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col-5" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$tl("labels.startDate")) + " "),
                        _c("small", [_vm._v("*")]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-7 date-resa-cal" },
                      [
                        _c("DatePicker", {
                          on: { selected: _vm.verifDatesBegin },
                          model: {
                            value: _vm.openDate,
                            callback: function ($$v) {
                              _vm.openDate = $$v
                            },
                            expression: "openDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col-5" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$tl("labels.endDate")) + " "),
                        _c("small", [_vm._v("*")]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-7 date-resa-cal" },
                      [
                        _c("DatePicker", {
                          on: { selected: _vm.verifDatesEnd },
                          model: {
                            value: _vm.closeDate,
                            callback: function ($$v) {
                              _vm.closeDate = $$v
                            },
                            expression: "closeDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-5" }, [
              _c(
                "label",
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$tl("labels.booking.services.activeDays")) +
                      " "
                  ),
                  _c("small", [_vm._v("*")]),
                  _vm._v(" "),
                  _c("feather", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltipNoticeLexicon(
                          "notices.booking.servicesConfig.activeDays",
                          _vm.restaurant.id
                        ),
                        expression:
                          "getTooltipNoticeLexicon('notices.booking.servicesConfig.activeDays', restaurant.id)",
                      },
                    ],
                    staticClass: "ml-1 pointer tooltip-infos",
                    attrs: { type: "info" },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-7" },
              _vm._l(_vm.englishDays(), function (englishDay) {
                return _c(
                  "label",
                  { key: englishDay, staticClass: "container-box" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.service.general.openDays,
                          expression: "service.general.openDays",
                        },
                      ],
                      attrs: { type: "checkbox", name: "days[]" },
                      domProps: {
                        value: englishDay,
                        checked: Array.isArray(_vm.service.general.openDays)
                          ? _vm._i(_vm.service.general.openDays, englishDay) >
                            -1
                          : _vm.service.general.openDays,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.service.general.openDays,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = englishDay,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.service.general,
                                  "openDays",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.service.general,
                                  "openDays",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.service.general, "openDays", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkmark" }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.capitalize(_vm.$t(`labels.days.${englishDay}`))
                        ) +
                        "\n                    "
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-1" }, [
            _c("div", { staticClass: "col-5 pt-2" }, [
              _c("label", [
                _vm._v(
                  _vm._s(
                    _vm.$tl(
                      "labels.booking.services.startHour",
                      _vm.restaurant.id
                    )
                  ) + " "
                ),
                _c("small", [_vm._v("*")]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-7" },
              [
                _c("vue-timepicker", {
                  attrs: { format: "HH:mm", "minute-interval": 15 },
                  model: {
                    value: _vm.serviceHourStart,
                    callback: function ($$v) {
                      _vm.serviceHourStart = $$v
                    },
                    expression: "serviceHourStart",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-5 pt-2" }, [
              _c("label", [
                _vm._v(
                  _vm._s(
                    _vm.$tl(
                      "labels.booking.services.endHour",
                      _vm.restaurant.id
                    )
                  ) + " "
                ),
                _c("small", [_vm._v("*")]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-7" },
              [
                _c("vue-timepicker", {
                  attrs: { format: "HH:mm", "minute-interval": 15 },
                  model: {
                    value: _vm.serviceHourEnd,
                    callback: function ($$v) {
                      _vm.serviceHourEnd = $$v
                    },
                    expression: "serviceHourEnd",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.availableSlots.length > 1
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-5" }, [
                  _c(
                    "label",
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$tl("labels.booking.services.slots")) +
                          " "
                      ),
                      _c("small", [_vm._v("*")]),
                      _vm._v(" "),
                      _c("feather", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getTooltipNotice(
                              "notices.booking.servicesConfig.reservationSlots"
                            ),
                            expression:
                              "getTooltipNotice('notices.booking.servicesConfig.reservationSlots')",
                          },
                        ],
                        staticClass: "ml-1 pointer tooltip-infos",
                        attrs: { type: "info" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-7" },
                  _vm._l(_vm.availableSlots, function (slot) {
                    return _c(
                      "div",
                      {
                        staticClass: "container-box",
                        staticStyle: { "margin-right": "13px" },
                      },
                      [
                        _c("label", { staticStyle: { "margin-bottom": "0" } }, [
                          _c("input", {
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: true,
                              checked: !_vm.$_.isEmpty(
                                _vm.service.general.slots[slot]
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.slotClicked($event, slot)
                              },
                            },
                          }),
                          _c("span", { staticClass: "checkmark" }),
                          _vm._v(
                            " " + _vm._s(slot) + "\n                        "
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-5" }, [
              _c(
                "label",
                { staticClass: "mb-0" },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.services.maxPax",
                          _vm.restaurant.id
                        )
                      ) +
                      " "
                  ),
                  _c("small", [_vm._v("*")]),
                  _vm._v(" "),
                  _c("feather", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltipNoticeLexicon(
                          "notices.booking.servicesConfig.paxMaxPerService",
                          _vm.restaurant.id
                        ),
                        expression:
                          "getTooltipNoticeLexicon('notices.booking.servicesConfig.paxMaxPerService', restaurant.id)",
                      },
                    ],
                    staticClass: "ml-1 pointer tooltip-infos",
                    attrs: { type: "info" },
                  }),
                ],
                1
              ),
              _c("br"),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(
                  _vm._s(
                    _vm.$tl(
                      "infos.booking.services.maxPaxIfRotation",
                      _vm.restaurant.id
                    )
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-7" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.service.general.pax.max,
                        expression: "service.general.pax.max",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      name: "max_pax",
                      required: "",
                      id: "max_pax",
                      min: "1",
                      step: "1",
                    },
                    domProps: { value: _vm.service.general.pax.max },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.service.general.pax,
                          "max",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-8 mt-2" }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(
                        _vm.$tl("labels.booking.covers", _vm.restaurant.id)
                      ) +
                      "\n                        "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-5 pt-1" }, [
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$tl("labels.booking.services.minPers")) + " "
                ),
                _c("small", [_vm._v("*")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-7" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-inline-block align-middle lead-switch pt-1",
                    },
                    [
                      _c("input", {
                        staticClass: "switch align-self-center is-rounded",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: _vm.service.general.use_widget_min_pers,
                        },
                      }),
                      _vm._v(" "),
                      _c("label", {
                        on: {
                          click: function ($event) {
                            _vm.service.general.use_widget_min_pers =
                              !_vm.service.general.use_widget_min_pers
                          },
                        },
                      }),
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$tl("labels.booking.widget.useDefaultConfig")
                          ) +
                          "\n                            "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              !_vm.service.general.use_widget_min_pers
                ? _c("div", { staticClass: "row mt-2" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.service.general.min_pers,
                            expression: "service.general.min_pers",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          required: !_vm.service.general.use_widget_min_pers,
                          min: "1",
                          step: "1",
                        },
                        domProps: { value: _vm.service.general.min_pers },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.service.general,
                              "min_pers",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-1" }, [
            _c("div", { staticClass: "col-5 pt-1" }, [
              _c("label", [
                _vm._v(
                  " " + _vm._s(_vm.$tl("labels.booking.services.maxPers")) + " "
                ),
                _c("small", [_vm._v("*")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-7" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-inline-block align-middle lead-switch pt-1",
                    },
                    [
                      _c("input", {
                        staticClass: "switch align-self-center is-rounded",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: _vm.service.general.use_widget_max_pers,
                        },
                      }),
                      _vm._v(" "),
                      _c("label", {
                        on: {
                          click: function ($event) {
                            _vm.service.general.use_widget_max_pers =
                              !_vm.service.general.use_widget_max_pers
                          },
                        },
                      }),
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$tl("labels.booking.widget.useDefaultConfig")
                          ) +
                          "\n                            "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              !_vm.service.general.use_widget_max_pers
                ? _c("div", { staticClass: "row mt-2" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.service.general.max_pers,
                            expression: "service.general.max_pers",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          required: !_vm.service.general.use_widget_max_pers,
                          min: "1",
                          step: "1",
                        },
                        domProps: { value: _vm.service.general.max_pers },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.service.general,
                              "max_pers",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          !_vm.$_.isEmpty(_vm.service.general.slots)
            ? [
                _c("div", { staticClass: "row mb-2 mt-4" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-5" },
                    [
                      _c(
                        "i18n",
                        {
                          attrs: {
                            path: "labels.booking.services.enableMaxPax",
                            tag: "label",
                          },
                        },
                        [
                          _c("template", { slot: "term" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .$tl(
                                      "labels.booking.covers",
                                      _vm.restaurant.id
                                    )
                                    .toLowerCase()
                                )
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-7" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-inline-block align-middle lead-switch pt-1",
                      },
                      [
                        _c("input", {
                          staticClass: "switch align-self-center is-rounded",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked:
                              _vm.service.general.is_slot_max_pax_enabled,
                          },
                        }),
                        _vm._v(" "),
                        _c("label", {
                          on: { click: _vm.changeIsSlotMaxPaxEnabled },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.service.general.is_slot_max_pax_enabled == true
                  ? _c("div", { staticClass: "row mb-2 m-0" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12 border-light p-4 mb-4" },
                        [
                          _vm._l(_vm.sortedSlotsKeys, function (slotKey) {
                            return [
                              _c(
                                "div",
                                { key: slotKey, staticClass: "row mb-1" },
                                [
                                  _c("div", { staticClass: "col-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-inline-block align-middle lead-switch",
                                      },
                                      [
                                        _c("input", {
                                          staticClass:
                                            "switch align-self-center is-small is-rounded",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked:
                                              _vm.service.general.slots[slotKey]
                                                .is_max_pax_enabled,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("label", {
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeSlotMaxPaxEnabled(
                                                slotKey
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-1 pt-1" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.service.general.slots[slotKey]
                                            .hour_start
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-3" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.service.general.slots[slotKey]
                                              .max_pax,
                                          expression:
                                            "service.general.slots[slotKey].max_pax",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        disabled:
                                          !_vm.service.general.slots[slotKey]
                                            .is_max_pax_enabled,
                                        type: "number",
                                        name: `slot_${_vm.service.general.slots[slotKey].hour_start}_pax`,
                                        min: "0",
                                        step: "1",
                                      },
                                      domProps: {
                                        value:
                                          _vm.service.general.slots[slotKey]
                                            .max_pax,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.service.general.slots[slotKey],
                                            "max_pax",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-6 pt-1" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.services.coversPerSlot",
                                            _vm.restaurant.id
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-5" },
                    [
                      _c(
                        "i18n",
                        {
                          attrs: {
                            path: "labels.booking.services.enableMaxPax",
                            tag: "label",
                          },
                        },
                        [
                          _c("template", { slot: "term" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .$tl("labels.booking.reservations.title")
                                    .toLowerCase()
                                )
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-7" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-inline-block align-middle lead-switch pt-1",
                      },
                      [
                        _c("input", {
                          staticClass: "switch align-self-center is-rounded",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked:
                              _vm.service.general
                                .is_slot_max_reservations_enabled,
                          },
                        }),
                        _vm._v(" "),
                        _c("label", {
                          on: { click: _vm.changeIsSlotMaxReservationsEnabled },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.service.general.is_slot_max_reservations_enabled == true
                  ? _c("div", { staticClass: "row mb-2 m-0" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12 border-light p-4" },
                        [
                          _vm._l(_vm.sortedSlotsKeys, function (slotKey) {
                            return [
                              _c(
                                "div",
                                { key: slotKey, staticClass: "row mb-1" },
                                [
                                  _c("div", { staticClass: "col-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-inline-block align-middle lead-switch",
                                      },
                                      [
                                        _c("input", {
                                          staticClass:
                                            "switch align-self-center is-small is-rounded",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked:
                                              _vm.service.general.slots[slotKey]
                                                .is_max_reservations_enabled,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("label", {
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeSlotMaxReservationsEnabled(
                                                slotKey
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-1 pt-1" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.service.general.slots[slotKey]
                                            .hour_start
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-3" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.service.general.slots[slotKey]
                                              .max_reservations,
                                          expression:
                                            "service.general.slots[slotKey].max_reservations",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        disabled:
                                          !_vm.service.general.slots[slotKey]
                                            .is_max_reservations_enabled,
                                        type: "number",
                                        name: `slot_${_vm.service.general.slots[slotKey].hour_start}_reservation`,
                                        min: "0",
                                        step: "1",
                                      },
                                      domProps: {
                                        value:
                                          _vm.service.general.slots[slotKey]
                                            .max_reservations,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.service.general.slots[slotKey],
                                            "max_reservations",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-6 pt-1" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.booking.services.resasPerSlot"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-4 mb-4" }, [
            _c("div", { staticClass: "col-5" }, [
              _c(
                "label",
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$tl("labels.booking.services.takeResas")) +
                      " "
                  ),
                  _c("small", [_vm._v("*")]),
                  _vm._v(" "),
                  _c("feather", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltipNoticeLexicon(
                          "notices.booking.servicesConfig.limitReservation",
                          _vm.restaurant.id
                        ),
                        expression:
                          "getTooltipNoticeLexicon('notices.booking.servicesConfig.limitReservation', restaurant.id)",
                      },
                    ],
                    staticClass: "ml-1 pointer tooltip-infos",
                    attrs: { type: "info" },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-7" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "radio" }, [
                    _c("label", { staticClass: "container-box" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.service.general.lock.enabled,
                            expression: "service.general.lock.enabled",
                          },
                        ],
                        attrs: { type: "radio" },
                        domProps: {
                          value: false,
                          checked: _vm._q(
                            _vm.service.general.lock.enabled,
                            false
                          ),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(
                              _vm.service.general.lock,
                              "enabled",
                              false
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "checkmark" }),
                      _vm._v(
                        "\n                                    " +
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.services.takeResaLive",
                              _vm.restaurant.id
                            )
                          ) +
                          "\n                                "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 d-inline radio mt-1" }, [
                  _c("label", { staticClass: "container-box" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.service.general.lock.enabled,
                          expression: "service.general.lock.enabled",
                        },
                      ],
                      attrs: { type: "radio" },
                      domProps: {
                        value: true,
                        checked: _vm._q(_vm.service.general.lock.enabled, true),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.service.general.lock,
                            "enabled",
                            true
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkmark" }),
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.$tl("labels.booking.services.blockResas")) +
                        "\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.service.general.lock.until,
                        expression: "service.general.lock.until",
                      },
                    ],
                    staticClass: "form-control d-inline",
                    staticStyle: { width: "70px" },
                    attrs: {
                      type: "number",
                      name: "lock_reservation_until",
                      disabled: !_vm.service.general.lock.enabled,
                      id: "lock_reservation_until",
                    },
                    domProps: { value: _vm.service.general.lock.until },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.service.general.lock,
                          "until",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(
                    "\n                            " +
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.services.minsBeforeStart",
                          _vm.restaurant.id
                        )
                      ) +
                      "\n                        "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-5 pt-2" }, [
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$tl("labels.booking.services.enableOnWidget")) +
                    " "
                ),
                _c("small", [_vm._v("*")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-7" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-inline-block align-middle lead-switch mt-1 pt-2",
                    },
                    [
                      _c("input", {
                        staticClass: "switch align-self-center is-rounded",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: _vm.service.general.show_on_widget,
                        },
                      }),
                      _vm._v(" "),
                      _c("label", {
                        on: {
                          click: function ($event) {
                            _vm.service.general.show_on_widget =
                              !_vm.service.general.show_on_widget
                          },
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-5 pt-2" }, [
              _c(
                "label",
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$tl("labels.booking.services.cancelationDelay")
                      ) +
                      " "
                  ),
                  _c("small", [_vm._v("*")]),
                  _vm._v(" "),
                  _c("feather", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltipNoticeLexicon(
                          "notices.booking.servicesConfig.cancellationPeriod",
                          _vm.restaurant.id
                        ),
                        expression:
                          "getTooltipNoticeLexicon('notices.booking.servicesConfig.cancellationPeriod', restaurant.id)",
                      },
                    ],
                    staticClass: "ml-1 pointer tooltip-infos",
                    attrs: { type: "info" },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-7" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.service.general.cancellation.delay,
                        expression: "service.general.cancellation.delay",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "number" },
                    domProps: { value: _vm.service.general.cancellation.delay },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.service.general.cancellation,
                          "delay",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-8 mt-2" }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(
                        _vm.$tl("labels.booking.services.hoursBeforeResa")
                      ) +
                      "\n                        "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-2 mt-4" }, [
            _c("div", { staticClass: "col-5 pt-2" }, [
              _c(
                "label",
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$tl("labels.booking.services.resasConfirm")) +
                      " "
                  ),
                  _c("small", [_vm._v("*")]),
                  _vm._v(" "),
                  _c("feather", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltipNotice(
                          "notices.booking.servicesConfig.reservationConfirm"
                        ),
                        expression:
                          "getTooltipNotice('notices.booking.servicesConfig.reservationConfirm')",
                      },
                    ],
                    staticClass: "ml-1 pointer tooltip-infos",
                    attrs: { type: "info" },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-7 radio w-100 h-100 d-table pt-2" }, [
              _c("div", { staticClass: "d-table-cell align-middle" }, [
                _c("label", { staticClass: "container-box" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.service.general.autoconfirm.enabled,
                        expression: "service.general.autoconfirm.enabled",
                      },
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: false,
                      checked: _vm._q(
                        _vm.service.general.autoconfirm.enabled,
                        false
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.service.general.autoconfirm,
                          "enabled",
                          false
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.$tl("labels.manual")) +
                      "\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "container-box" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.service.general.autoconfirm.enabled,
                        expression: "service.general.autoconfirm.enabled",
                      },
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: true,
                      checked: _vm._q(
                        _vm.service.general.autoconfirm.enabled,
                        true
                      ),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(
                          _vm.service.general.autoconfirm,
                          "enabled",
                          true
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$tl("labels.auto")) +
                      "\n                        "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.service.general.autoconfirm.enabled
            ? [
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-4 pt-2 offset-1" }, [
                    _c("label", [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$tl("labels.to")) +
                          "\n                        "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.service.general.autoconfirm.upToPercentFull,
                              expression:
                                "service.general.autoconfirm.upToPercentFull",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            disabled: !_vm.service.general.autoconfirm.enabled,
                          },
                          domProps: {
                            value:
                              _vm.service.general.autoconfirm.upToPercentFull,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.service.general.autoconfirm,
                                "upToPercentFull",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-8 mt-2" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$tl("labels.booking.services.percentFillRate")
                            ) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-4 pt-2 offset-1" }, [
                    _c("label", [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$tl("labels.to")) +
                          "\n                        "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.service.general.autoconfirm.upToPax,
                              expression: "service.general.autoconfirm.upToPax",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            disabled: !_vm.service.general.autoconfirm.enabled,
                          },
                          domProps: {
                            value: _vm.service.general.autoconfirm.upToPax,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.service.general.autoconfirm,
                                "upToPax",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-8 mt-2" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$tl("labels.personss")) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-4 pt-2 offset-1" }, [
                    _c("label", [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$tl("labels.to")) +
                          "\n                        "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.service.general.autoconfirm
                                  .upToMinutesBeforeStart,
                              expression:
                                "service.general.autoconfirm.upToMinutesBeforeStart",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            disabled: !_vm.service.general.autoconfirm.enabled,
                          },
                          domProps: {
                            value:
                              _vm.service.general.autoconfirm
                                .upToMinutesBeforeStart,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.service.general.autoconfirm,
                                "upToMinutesBeforeStart",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-8 mt-2" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$tl(
                                "labels.booking.services.minsBeforeSlotStarts"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-4 offset-1" }, [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$tl("labels.booking.services.blockAutoConfirm")
                          )
                      ),
                      _c("br"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.service.general.autoconfirm.upToNoshow,
                              expression:
                                "service.general.autoconfirm.upToNoshow",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            disabled: !_vm.service.general.autoconfirm.enabled,
                          },
                          domProps: {
                            value: _vm.service.general.autoconfirm.upToNoshow,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.service.general.autoconfirm,
                                "upToNoshow",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-8 mt-2" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.capitalize(_vm.$tl("labels.clients.noshow"))
                            )
                        ),
                        _c("small", [
                          _vm._v(
                            " - " +
                              _vm._s(
                                _vm.$tl(
                                  "infos.booking.services.blockAutoConfirm"
                                )
                              )
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-2 mt-4" }, [
            _c("div", { staticClass: "col-5 pt-2" }, [
              _c(
                "label",
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.services.enableTableRotation",
                          _vm.restaurant_id
                        )
                      ) +
                      "\n                        "
                  ),
                  _c("feather", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltipNoticeLexicon(
                          "notices.booking.servicesConfig.tableRotation",
                          _vm.restaurant.id
                        ),
                        expression:
                          "getTooltipNoticeLexicon('notices.booking.servicesConfig.tableRotation', restaurant.id)",
                      },
                    ],
                    staticClass: "ml-1 pointer tooltip-infos",
                    attrs: { type: "info" },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-7 w-100 h-100 d-table pt-2" }, [
              _c("div", { staticClass: "d-table-cell align-middle" }, [
                _c("label", { staticClass: "container-box" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.service.paxes.rotation,
                        expression: "service.paxes.rotation",
                      },
                    ],
                    attrs: { type: "checkbox", name: "enable_table_rotation" },
                    domProps: {
                      checked: Array.isArray(_vm.service.paxes.rotation)
                        ? _vm._i(_vm.service.paxes.rotation, null) > -1
                        : _vm.service.paxes.rotation,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.service.paxes.rotation,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.service.paxes,
                                "rotation",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.service.paxes,
                                "rotation",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.service.paxes, "rotation", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.service.paxes.rotation
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 pt-1" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.services.averageTimeAtTable",
                              _vm.restaurant_id
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.service.paxes.value, function (pax) {
                    return [
                      pax.pax <= 10
                        ? _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "offset-3 col-4 pt-1" }, [
                              _vm._v(
                                _vm._s(pax.pax === 10 ? "+" : "") +
                                  _vm._s(pax.pax)
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-5 pt-1" },
                              [
                                pax.pax < 10
                                  ? _c("vue-timepicker", {
                                      attrs: {
                                        format: "HH:mm",
                                        "minute-interval": 15,
                                      },
                                      model: {
                                        value: pax.duration,
                                        callback: function ($$v) {
                                          _vm.$set(pax, "duration", $$v)
                                        },
                                        expression: "pax.duration",
                                      },
                                    })
                                  : _c("vue-timepicker", {
                                      attrs: { "minute-interval": 15 },
                                      on: {
                                        change: function ($event) {
                                          return _vm.setPaxesHigherThanTen(
                                            $event
                                          )
                                        },
                                      },
                                      model: {
                                        value: pax.duration,
                                        callback: function ($$v) {
                                          _vm.$set(pax, "duration", $$v)
                                        },
                                        expression: "pax.duration",
                                      },
                                    }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasFeatSeatingPlan
            ? [
                _c("div", { staticClass: "row mb-2 mt-2" }, [
                  _c("div", { staticClass: "col-5 pt-2" }, [
                    _c("label", [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$tl("labels.booking.seatingPlan.title")) +
                          "\n                        "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-7 w-100 h-100 d-table pt-2" }, [
                    _c("div", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.service.seatingPlan.value,
                              expression: "service.seatingPlan.value",
                            },
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: !_vm.restaurant.hasASeatingPlan
                                ? {
                                    content: _vm.$t(
                                      "infos.booking.services.enableSeatingPlan"
                                    ),
                                  }
                                : null,
                              expression:
                                "\n                                    !restaurant.hasASeatingPlan\n                                        ? {\n                                              content: $t('infos.booking.services.enableSeatingPlan'),\n                                          }\n                                        : null\n                                ",
                            },
                          ],
                          staticClass: "custom-select",
                          attrs: { disabled: !_vm.restaurant.hasASeatingPlan },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.service.seatingPlan,
                                "value",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("--"),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.seatingPlans, function (seatingPlan) {
                            return _c(
                              "option",
                              {
                                key: seatingPlan.id,
                                domProps: { value: seatingPlan },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(seatingPlan.name) +
                                    "\n                                "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm.service.seatingPlan.value
                  ? _c("div", { staticClass: "row mb-2 mt-2" }, [
                      _c("div", { staticClass: "col-5 pt-2" }, [
                        _c(
                          "label",
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.services.enableAlgorithm",
                                    _vm.restaurant_id
                                  )
                                ) +
                                "\n                            "
                            ),
                            _c("feather", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.getTooltipNoticeLexicon(
                                    "notices.booking.servicesConfig.automaticSeatingPlan",
                                    _vm.restaurant.id
                                  ),
                                  expression:
                                    "getTooltipNoticeLexicon('notices.booking.servicesConfig.automaticSeatingPlan', restaurant.id)",
                                },
                              ],
                              staticClass: "ml-1 pointer tooltip-infos",
                              attrs: { type: "info" },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-7 w-100 h-100 d-table pt-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-table-cell align-middle" },
                            [
                              _c(
                                "label",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: !_vm.restaurant.hasASeatingPlan
                                        ? {
                                            content: _vm.$t(
                                              "infos.booking.services.enableSeatingPlan"
                                            ),
                                          }
                                        : null,
                                      expression:
                                        "\n                                    !restaurant.hasASeatingPlan\n                                        ? {\n                                              content: $t('infos.booking.services.enableSeatingPlan'),\n                                          }\n                                        : null\n                                ",
                                    },
                                  ],
                                  staticClass: "container-box",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.service.seatingPlan
                                            .algorithm_enabled,
                                        expression:
                                          "service.seatingPlan.algorithm_enabled",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      disabled: !_vm.restaurant.hasASeatingPlan,
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.service.seatingPlan
                                          .algorithm_enabled
                                      )
                                        ? _vm._i(
                                            _vm.service.seatingPlan
                                              .algorithm_enabled,
                                            null
                                          ) > -1
                                        : _vm.service.seatingPlan
                                            .algorithm_enabled,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.service.seatingPlan
                                              .algorithm_enabled,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.service.seatingPlan,
                                                "algorithm_enabled",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.service.seatingPlan,
                                                "algorithm_enabled",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.service.seatingPlan,
                                            "algorithm_enabled",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "checkmark" }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.service.seatingPlan.algorithm_enabled
                  ? _c("div", { staticClass: "row mb-2 mt-2" }, [
                      _c("div", { staticClass: "col-5 pt-2" }, [
                        _c("label", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.services.allowClientsChooseRoom"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-7 w-100 h-100 d-table pt-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-table-cell align-middle" },
                            [
                              _c("label", { staticClass: "container-box" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.service.seatingPlan
                                          .allow_clients_choose_room,
                                      expression:
                                        "service.seatingPlan.allow_clients_choose_room",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.service.seatingPlan
                                        .allow_clients_choose_room
                                    )
                                      ? _vm._i(
                                          _vm.service.seatingPlan
                                            .allow_clients_choose_room,
                                          null
                                        ) > -1
                                      : _vm.service.seatingPlan
                                          .allow_clients_choose_room,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.service.seatingPlan
                                            .allow_clients_choose_room,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.service.seatingPlan,
                                              "allow_clients_choose_room",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.service.seatingPlan,
                                              "allow_clients_choose_room",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.service.seatingPlan,
                                          "allow_clients_choose_room",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "checkmark" }),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.service.seatingPlan.allow_clients_choose_room
                            ? _c("div", { staticClass: "radio" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-table-cell align-middle" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "container-box" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.service.seatingPlan
                                                  .allow_clients_choose_room_mandatory,
                                              expression:
                                                "service.seatingPlan.allow_clients_choose_room_mandatory",
                                            },
                                          ],
                                          attrs: { type: "radio", value: "0" },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.service.seatingPlan
                                                .allow_clients_choose_room_mandatory,
                                              "0"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.service.seatingPlan,
                                                "allow_clients_choose_room_mandatory",
                                                "0"
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$tl("labels.optional")) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      { staticClass: "container-box" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.service.seatingPlan
                                                  .allow_clients_choose_room_mandatory,
                                              expression:
                                                "service.seatingPlan.allow_clients_choose_room_mandatory",
                                            },
                                          ],
                                          attrs: { type: "radio", value: "1" },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.service.seatingPlan
                                                .allow_clients_choose_room_mandatory,
                                              "1"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.service.seatingPlan,
                                                "allow_clients_choose_room_mandatory",
                                                "1"
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$tl("labels.mandatory")
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-2 mt-2" }, [
            _c("div", { staticClass: "col-5" }, [
              _c(
                "label",
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$tl(
                          "labels.booking.menus.externalMenu.externalMenu",
                          _vm.restaurant.id
                        )
                      ) +
                      "\n                        "
                  ),
                  _c("feather", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.getTooltipNoticeLexicon(
                          "notices.booking.externalMenus",
                          _vm.restaurant.id
                        ),
                        expression:
                          "getTooltipNoticeLexicon('notices.booking.externalMenus', restaurant.id)",
                      },
                    ],
                    staticClass: "ml-1 pointer tooltip-infos",
                    attrs: { type: "info" },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-7" }, [
              _c(
                "div",
                { staticClass: "radio" },
                [
                  _c(
                    "RadioButtonComponent",
                    {
                      attrs: { inputValue: _vm.EXTERNAL_MENU_FILE.value },
                      model: {
                        value: _vm.externalMenu.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.externalMenu, "type", $$v)
                        },
                        expression: "externalMenu.type",
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.menus.externalMenu.file.pdf"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "RadioButtonComponent",
                    {
                      attrs: { inputValue: _vm.EXTERNAL_MENU_LINK.value },
                      model: {
                        value: _vm.externalMenu.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.externalMenu, "type", $$v)
                        },
                        expression: "externalMenu.type",
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.menus.externalMenu.link.link"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "RadioButtonComponent",
                    {
                      attrs: { inputValue: null },
                      model: {
                        value: _vm.externalMenu.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.externalMenu, "type", $$v)
                        },
                        expression: "externalMenu.type",
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$tl(
                              "labels.booking.menus.externalMenu.disabled"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.externalMenu.type === _vm.EXTERNAL_MENU_FILE.value
                ? _c(
                    "div",
                    { staticClass: "my-2" },
                    [
                      _c("FileUploaderComponent", {
                        attrs: {
                          "max-size": 20,
                          hasDirectUpload: false,
                          suffixId: "ext-desc",
                          "props-file": _vm.externalMenu.file,
                        },
                        on: {
                          "upload-file": function ($event) {
                            _vm.externalMenu.file = $event
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm.externalMenu.type === _vm.EXTERNAL_MENU_LINK.value
                ? _c(
                    "div",
                    { staticClass: "my-2" },
                    [
                      _c("InputComponent", {
                        attrs: {
                          label: "",
                          placeholder: _vm.$t(
                            "labels.booking.menus.externalMenu.link.placeholder"
                          ),
                          labelWrapperClasses: "d-none",
                          type: "url",
                        },
                        model: {
                          value: _vm.externalMenu.content.link,
                          callback: function ($$v) {
                            _vm.$set(_vm.externalMenu.content, "link", $$v)
                          },
                          expression: "externalMenu.content.link",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }