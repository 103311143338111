<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div class="alert alert-danger" v-else-if="!website_enabled">Votre site web n'est pas activé</div>
        <template v-else>
            <div class="row m-0">
                <div class="col-12">
                    <div
                        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                        <h5 class="title mt-2">Configuration des modules connectés</h5>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-md-5">
                                <label><strong>Module "Réservation"</strong></label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        type="checkbox"
                                        :disabled="
                                            !has_right_to_update_customization || !website_settings.has_booking_module
                                        "
                                        class="switch align-self-center is-rounded"
                                        v-model="website_settings.module_booking_enabled" />
                                    <label
                                        v-tooltip="{
                                            delay: { show: 400, hide: 0 },
                                            content: 'Activer / désactiver le widget de réservation',
                                        }"
                                        @click="
                                            website_settings.module_booking_enabled =
                                                website_settings.has_booking_module && has_right_to_update_customization
                                                    ? !website_settings.module_booking_enabled
                                                    : website_settings.module_booking_enabled
                                        "></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row mb-2">
                            <div class="col-md-5">
                                <label><strong>Module "Vente en ligne"</strong></label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        type="checkbox"
                                        :disabled="
                                            !has_right_to_update_customization ||
                                            !website_settings.has_click_and_collect_module ||
                                            !website_settings.has_click_and_collect_api_key
                                        "
                                        class="switch align-self-center is-rounded"
                                        v-model="website_settings.module_click_and_collect_enabled" />
                                    <label
                                        v-tooltip="{
                                            delay: { show: 400, hide: 0 },
                                            content: 'Activer / désactiver le widget de vente en ligne',
                                        }"
                                        @click="
                                            website_settings.module_click_and_collect_enabled =
                                                website_settings.has_click_and_collect_module &&
                                                website_settings.has_click_and_collect_api_key &&
                                                has_right_to_update_customization
                                                    ? !website_settings.module_click_and_collect_enabled
                                                    : website_settings.module_click_and_collect_enabled
                                        ">
                                    </label>
                                </div>
                                <small
                                    class="d-block text-danger mt-1"
                                    v-if="
                                        website_settings.has_click_and_collect_module &&
                                        !website_settings.has_click_and_collect_api_key
                                    ">
                                    Vous devez au préalable
                                    <router-link
                                        v-if="has_right_to_update_cc_config"
                                        target="_blank"
                                        :to="{
                                            name: 'click_and_collect.restaurants.configuration.widget',
                                            params: { restaurant_id },
                                        }"
                                        >générer une clé d'api</router-link
                                    ><span v-else>générer une clé d'api</span> afin de pouvoir activer le widget sur
                                    votre site web.
                                </small>
                            </div>
                        </div>
                        <div class="row" v-if="website_settings.module_click_and_collect_enabled">
                            <div class="col-md-5">
                                <label for="txt-menupdf3-title"
                                    >Texte sur le bouton <small class="text-danger">*</small></label
                                >
                            </div>
                            <div class="col-md-7">
                                <input
                                    :disabled="!has_right_to_update_customization"
                                    type="text"
                                    class="form-control"
                                    id="txt-menupdf3-title"
                                    v-model="website_settings.cc_btn_label"
                                    maxlength="40" />
                                <ShowErrors class="d-block" :errors="errors" errorKey="cc_btn_label" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4">
                        <div class="row">
                            <div class="col-md-5">
                                <label><strong>Module "Bons Cadeaux"</strong></label>
                            </div>
                            <div class="col-md-7">
                                <div class="d-inline-block align-middle lead-switch">
                                    <input
                                        type="checkbox"
                                        :disabled="
                                            !has_right_to_update_customization ||
                                            !website_settings.has_gift_vouchers_module ||
                                            !website_settings.has_gift_vouchers_api_key
                                        "
                                        class="switch align-self-center is-rounded"
                                        v-model="website_settings.module_gift_vouchers_enabled" />
                                    <label
                                        v-tooltip="{
                                            delay: { show: 400, hide: 0 },
                                            content: 'Activer / désactiver le widget des bons cadeaux',
                                        }"
                                        @click="
                                            website_settings.module_gift_vouchers_enabled =
                                                website_settings.has_gift_vouchers_module &&
                                                website_settings.has_gift_vouchers_api_key &&
                                                has_right_to_update_customization
                                                    ? !website_settings.module_gift_vouchers_enabled
                                                    : website_settings.module_gift_vouchers_enabled
                                        ">
                                    </label>
                                </div>
                                <small
                                    class="d-block text-danger mt-1"
                                    v-if="
                                        website_settings.has_gift_vouchers_module &&
                                        !website_settings.has_gift_vouchers_api_key
                                    ">
                                    Vous devez au préalable
                                    <router-link
                                        v-if="has_right_to_update_gv_config"
                                        target="_blank"
                                        :to="{
                                            name: 'gift_vouchers.restaurants.configuration.widget',
                                            params: { restaurant_id },
                                        }"
                                        >générer une clé d'api</router-link
                                    ><span v-else>générer une clé d'api</span> afin de pouvoir activer le widget sur
                                    votre site web.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0 mt-3">
                <div class="col-12 d-inline-block">
                    <button
                        type="button"
                        class="btn btn-success btn-circle mr-1"
                        @click="postForm()"
                        :disabled="!has_right_to_update_customization">
                        Enregistrer
                    </button>
                    <small
                        >Pour rendre vos modifications visibles sur votre site internet, pensez à "<strong
                            >Enregistrer et publier</strong
                        >" en cliquant sur le bouton dans la navigation.</small
                    >
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../../../components/LoaderComponent";
import ShowErrors from "../../../components/errors/ShowErrors";

export default {
    data() {
        return {
            loading: 0,
            errors: null,
        };
    },
    props: {
        website_settings: {
            type: Object,
            required: true,
        },
        website_enabled: {
            required: true,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_customization() {
            return this.rights.includes("website.customization.update");
        },
        has_right_to_update_cc_config() {
            return this.rights.includes("click_and_collect.config.update");
        },
        has_right_to_update_gv_config() {
            return this.rights.includes("gift_vouchers.config.update");
        },
    },
    methods: {
        postForm(doNotFetch = false) {
            this.loading++;
            this.errors = null;

            return this.$store
                .dispatch("websites/updateWebsiteSettings", {
                    restaurant_id: this.restaurant_id,
                    params: {
                        ...this.website_settings,
                        show_address: this.website_settings.show_address ? false : true,
                    },
                })
                .then((response) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "success",
                        title: response.data.message,
                    });
                    if (!doNotFetch) this.$emit("fetch-data");
                    return true;
                })
                .catch((error) => {
                    this.loading--;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    return false;
                });
        },
    },
    components: {
        ShowErrors,
        LoaderComponent,
    },
};
</script>
