<template>
    <div class="border-light p-4 b-radius-20">
        <loader-component v-if="nbLoading > 0"></loader-component>
        <template v-else>
            <div class="row">
                <div v-if="!isGlobalReport" class="col-12 mb-3">
                    {{ $tl("labels.booking.stats.filters.serviceCategory", firstSelectedRestaurantId) }}
                    <select v-model="filters.serviceCategory" class="custom-select d-inline-block ml-2 mr-2 mb-0" style="width: initial">
                        <option :value="null">
                            {{ $tl("labels.booking.stats.filters.allServiceCategories", firstSelectedRestaurantId) }}
                        </option>
                        <option v-for="category in servicesCategories" :value="category.fr" :key="category.fr">
                            <template v-if="inEnum(category.fr, ALL_DEFAULT_SERVICE_CATEGORIES)">
                                {{ getDefaultServiceCategoryLabel(category.fr) }}
                            </template>
                            <template v-else>
                                {{ isUserLangFr ? category.fr : category.en }}
                            </template>
                        </option>
                    </select>
                </div>
                <div v-if="isGlobalReport" class="col-md-12 mb-3">
                    <div class="row mb-2">
                        <div class="col-md-5">
                            <strong class="text-success">{{ $tl("labels.booking.stats.groupOne", firstSelectedRestaurantId) }}</strong>
                            <select v-model="filters.main.selectedZone" @change="updateRestaurantsMain" class="custom-select mb-1">
                                <option value="all">
                                    {{ $tl("labels.booking.globalStats.filters.allEstablishments", firstSelectedRestaurantId) }}
                                </option>
                                <option v-for="zone in zones" :key="zone.id" :value="zone.id">
                                    {{ zone.name }}
                                </option>
                            </select>
                            <vue-multiselect
                                v-model="filters.main.selectedRestaurants"
                                :options="allRestaurants"
                                :multiple="true"
                                :close-on-select="false"
                                selectLabel=""
                                :selectedLabel="$tl('labels.booking.globalStats.filters.selectedEstablishments', firstSelectedRestaurantId)"
                                :deselectLabel="$tl('labels.booking.globalStats.filters.deselectedEstablishments', firstSelectedRestaurantId)"
                                label="name"
                                track-by="id"
                                :placeholder="$tl('labels.booking.globalStats.filters.placeholder', firstSelectedRestaurantId)"
                                class="mt-1"
                                style="font-size: 12.8px !important">
                            </vue-multiselect>
                        </div>
                        <div class="col-md-2 text-center pt-2 pb-2">
                            <div class="d-inline-block align-middle lead-switch ml-1">
                                <input type="checkbox" class="switch align-self-center is-rounded" v-model="filters.hasGroupComparison" />
                                <label
                                    v-tooltip="getTooltip($tl('labels.filters.compare', firstSelectedRestaurantId))"
                                    @click="toggleGroupComparison"></label>
                            </div>
                        </div>
                        <div v-if="filters.hasGroupComparison" class="col-md-5">
                            <strong class="text-success">{{ $tl("labels.booking.stats.groupTwo", firstSelectedRestaurantId) }}</strong>
                            <select v-model="filters.comparison.selectedZone" @change="updateRestaurantsComparison" class="custom-select mb-1">
                                <option value="all">
                                    {{ $tl("labels.booking.globalStats.filters.allEstablishments", firstSelectedRestaurantId) }}
                                </option>
                                <option v-for="zone in zones" :key="zone.id" :value="zone.id">
                                    {{ zone.name }}
                                </option>
                            </select>
                            <vue-multiselect
                                v-model="filters.comparison.selectedRestaurants"
                                :options="allRestaurants"
                                :multiple="true"
                                :close-on-select="false"
                                selectLabel=""
                                :selectedLabel="$tl('labels.booking.globalStats.filters.selectedEstablishments', firstSelectedRestaurantId)"
                                :deselectLabel="$tl('labels.booking.globalStats.filters.deselectedEstablishments', firstSelectedRestaurantId)"
                                label="name"
                                track-by="id"
                                :placeholder="$tl('labels.booking.globalStats.filters.placeholder', firstSelectedRestaurantId)"
                                class="mt-1"
                                style="font-size: 12.8px !important">
                            </vue-multiselect>
                        </div>
                    </div>
                </div>

                <div class="col-md-5">
                    <div class="row mb-2">
                        <div class="col-12">
                            <strong class="text-success">
                                {{ $tl("labels.booking.stats.periodOne", firstSelectedRestaurantId) }}
                            </strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <select v-model="filters.main.currentPeriod" class="custom-select mb-0">
                                <option value="today">{{ $tl("labels.today", firstSelectedRestaurantId) }}</option>
                                <option value="yesterday">{{ $tl("labels.yesterday", firstSelectedRestaurantId) }}</option>
                                <option value="last_week">
                                    {{ $tl("labels.filters.dates.lastWeek", firstSelectedRestaurantId) }}
                                </option>
                                <option value="last_month">
                                    {{ $tl("labels.filters.dates.lastMonth", firstSelectedRestaurantId) }}
                                </option>
                                <option value="last_year">
                                    {{ $tl("labels.filters.dates.lastYear", firstSelectedRestaurantId) }}
                                </option>
                                <option value="custom_period">
                                    {{ $tl("labels.filters.dates.customPeriod", firstSelectedRestaurantId) }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12 d-flex">
                            <DatePicker
                                class="d-inline-block date-resa-cal"
                                input-class="form-control"
                                :style="`width: 125px`"
                                :format="DATE_SHORT_WITH_WEEKDAY"
                                :disabledDates="disabledDates"
                                v-model="filters.main.fromDate"
                                :placeholder="$tl('labels.filters.dates.start', firstSelectedRestaurantId)"
                                :disabled="areMainDatesInputsDisabled" />
                            <template v-if="shouldShowMainToDate">
                                <feather type="arrow-right" class="m-2" />
                                <DatePicker
                                    class="d-inline-block date-resa-cal"
                                    input-class="form-control"
                                    :style="`width: 125px`"
                                    :format="DATE_SHORT_WITH_WEEKDAY"
                                    :disabledDates="disabledDates"
                                    v-model="filters.main.toDate"
                                    :placeholder="$tl('labels.filters.dates.end', firstSelectedRestaurantId)"
                                    :disabled="areMainDatesInputsDisabled" />
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 text-center pt-2 pb-2">
                    <div class="d-inline-block align-middle lead-switch ml-1">
                        <input type="checkbox" class="switch align-self-center is-rounded" v-model="filters.hasComparison" />
                        <label
                            v-tooltip="getTooltip($tl('labels.filters.compare', firstSelectedRestaurantId))"
                            @click="filters.hasComparison = !filters.hasComparison"></label>
                    </div>
                </div>
                <div v-if="filters.hasComparison" class="col-md-5">
                    <div class="row mb-2">
                        <div class="col-12">
                            <strong class="text-success">
                                {{ $tl("labels.booking.stats.periodTwo", firstSelectedRestaurantId) }}
                            </strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <select v-model="filters.comparison.currentPeriod" class="custom-select mb-0 mr-2">
                                <option value="previous_period">
                                    {{ $tl("labels.filters.dates.previousPeriod", firstSelectedRestaurantId) }}
                                </option>
                                <option value="previous_year">
                                    {{ $tl("labels.filters.dates.previousYear", firstSelectedRestaurantId) }}
                                </option>
                                <option value="next_period">
                                    {{ $tl("labels.filters.dates.nextPeriod", firstSelectedRestaurantId) }}
                                </option>
                                <option value="custom_period">
                                    {{ $tl("labels.filters.dates.customPeriod", firstSelectedRestaurantId) }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12 d-flex">
                            <DatePicker
                                class="d-inline-block date-resa-cal"
                                input-class="form-control"
                                :style="`width: 125px`"
                                :format="DATE_SHORT_WITH_WEEKDAY"
                                :disabledDates="disabledDates"
                                v-model="filters.comparison.fromDate"
                                :placeholder="$tl('labels.filters.dates.start', firstSelectedRestaurantId)"
                                :disabled="areComparisonDatesInputsDisabled" />
                            <template v-if="shouldShowComparisonToDate">
                                <feather type="arrow-right" class="m-2" />
                                <DatePicker
                                    class="d-inline-block date-resa-cal"
                                    input-class="form-control"
                                    :format="DATE_SHORT_WITH_WEEKDAY"
                                    :disabledDates="disabledDates"
                                    v-model="filters.comparison.toDate"
                                    :placeholder="$tl('labels.filters.dates.end', firstSelectedRestaurantId)"
                                    :disabled="areComparisonDatesInputsDisabled" />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-12">
                    <small class="text-muted">({{ $tc("labels.booking.stats.maxPeriod", keepClientsDetailsAndHistoryFor) }})</small>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <button @click="emitFiltersUpdate" class="btn btn-sm btn-success btn-circle">
                        {{ $tl("labels.form.actions.apply", firstSelectedRestaurantId) }}
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import LoaderComponent from "../LoaderComponent.vue";
import DatePicker from "../forms/DatePicker.vue";
import DefaultServiceCategoriesEnum from "../../mixins/enums/booking/DefaultServiceCategoriesEnum.js";

export default {
    props: {
        isGlobalReport: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            nbLoading: 0,
            servicesCategories: [{ fr: "brunch" }, { fr: "midi" }, { fr: "soir" }],
            filters: {
                hasComparison: false,
                hasGroupComparison: false,
                serviceCategory: null,
                main: {
                    currentPeriod: "today", // One of ["today"|"yesterday"|"last_week"|"last_month"|"custom_period"]
                    fromDate: this.getDateTime(),
                    toDate: this.getDateTime(),
                    selectedRestaurants: [],
                    selectedZone: "all",
                },
                comparison: {
                    currentPeriod: "previous_period", // One of ["previous_period"|"previous_year"|"next_period"|"custom_period"]
                    fromDate: this.getDateTime().minus({ days: 1 }),
                    toDate: this.getDateTime().minus({ days: 1 }),
                    selectedRestaurants: [],
                    selectedZone: "all",
                },
            },
            allRestaurants: [],
            zones: [],
        };
    },
    mixins: [DefaultServiceCategoriesEnum],
    computed: {
        ownerId() {
            return this.$store.getters["users/ownerId"];
        },
        restaurantId() {
            if (typeof this.$route.params.restaurant_id === "undefined") {
                return null;
            }

            return Number.parseInt(this.$route.params.restaurant_id);
        },
        keepClientsDetailsAndHistoryFor() {
            return this.$store.getters["users/keepClientsDetailsAndHistoryFor"];
        },
        disabledDates() {
            return {
                to: this.getDateTime().minus({ years: this.keepClientsDetailsAndHistoryFor }).toJSDateCustom(),
            };
        },
        shouldShowMainToDate() {
            return !["today", "yesterday"].includes(this.filters.main.currentPeriod);
        },
        areMainDatesInputsDisabled() {
            return this.filters.main.currentPeriod !== "custom_period";
        },
        shouldShowComparisonToDate() {
            if (this.filters.comparison.currentPeriod === "custom_period") {
                return true;
            }

            return !["today", "yesterday"].includes(this.filters.main.currentPeriod);
        },
        areComparisonDatesInputsDisabled() {
            return this.filters.comparison.currentPeriod !== "custom_period";
        },
        hasRwg() {
            let comparisonStats = [];
            if (this.filters.hasGroupComparison) {
                comparisonStats = this.filters.comparison.selectedRestaurants;
            }
            return [...new Set([...this.filters.main.selectedRestaurants, ...comparisonStats])].some((restaurant) => !!restaurant.has_enabled_rwg);
        },
        firstSelectedRestaurantId() {
            if (this.zones.length < 1) {
                return undefined;
            }

            return this.zones[0].restaurants[0].id;
        },
        isAdmin() {
            return this.$store.getters["users/role"] === "admin";
        },
    },
    methods: {
        fetchZones() {
            this.nbLoading++;
            this.zones = [];

            return this.httpGet(`/api/owners/${this.ownerId}/zones`)
                .then((response) => {
                    if (response !== false) {
                        this.zones = response.data.data;
                    }
                })
                .finally(() => {
                    this.nbLoading--;
                });
        },
        updateMainDatesFromMainPeriod() {
            switch (this.filters.main.currentPeriod) {
                case "today":
                    this.filters.main.fromDate = this.getDateTime().startOf("day");
                    this.filters.main.toDate = this.getDateTime().startOf("day");
                    break;
                case "yesterday":
                    this.filters.main.fromDate = this.getDateTime().startOf("day").minus({ days: 1 });
                    this.filters.main.toDate = this.getDateTime().startOf("day").minus({ days: 1 });
                    break;
                case "last_week":
                    this.filters.main.fromDate = this.getDateTime().minus({ weeks: 1 }).startOf("week").set({ weekday: 1 });
                    this.filters.main.toDate = this.filters.main.fromDate.plus({ days: 6 }).endOf("day");
                    break;
                case "last_month":
                    this.filters.main.fromDate = this.getDateTime().minus({ months: 1 }).startOf("month");
                    this.filters.main.toDate = this.getDateTime().minus({ months: 1 }).endOf("month");
                    break;
                case "last_year":
                    this.filters.main.fromDate = this.getDateTime().minus({ years: 1 }).startOf("year");
                    this.filters.main.toDate = this.getDateTime().minus({ years: 1 }).endOf("year");
                    break;
            }
        },
        updateComparisonDatesFromMainPeriod() {
            if (this.filters.main.fromDate === null || this.filters.main.toDate === null) {
                return;
            }

            switch (this.filters.comparison.currentPeriod) {
                case "previous_period":
                    if (this.filters.main.currentPeriod === "last_week") {
                        this.filters.comparison.fromDate = this.filters.main.fromDate.minus({ weeks: 1 }).startOf("week").set({ weekday: 1 });
                        this.filters.comparison.toDate = this.filters.comparison.fromDate.plus({ days: 6 }).endOf("day");
                    } else if (this.filters.main.currentPeriod === "last_month") {
                        this.filters.comparison.fromDate = this.filters.main.fromDate.minus({ months: 1 }).startOf("month");
                        this.filters.comparison.toDate = this.filters.main.fromDate.minus({ months: 1 }).endOf("month");
                    } else {
                        const diffInDays = this.filters.main.toDate.diff(this.filters.main.fromDate, "days").days + 1;
                        this.filters.comparison.fromDate = this.filters.main.fromDate.minus({ days: diffInDays });
                        this.filters.comparison.toDate = this.filters.main.fromDate.minus({ days: 1 });
                    }
                    break;

                case "previous_year":
                    this.filters.comparison.fromDate = this.filters.main.fromDate.minus({ years: 1 });
                    this.filters.comparison.toDate = this.filters.main.toDate.minus({ years: 1 });
                    break;

                case "next_period":
                    if (this.filters.main.currentPeriod === "last_week") {
                        this.filters.comparison.fromDate = this.filters.main.fromDate.plus({ weeks: 1 }).startOf("week").set({ weekday: 1 });
                        this.filters.comparison.toDate = this.filters.comparison.fromDate.plus({ days: 6 }).endOf("day");
                    } else if (this.filters.main.currentPeriod === "last_month") {
                        this.filters.comparison.fromDate = this.filters.main.fromDate.plus({ months: 1 }).startOf("month");
                        this.filters.comparison.toDate = this.filters.main.fromDate.plus({ months: 1 }).endOf("month");
                    } else {
                        const daysDifference = this.filters.main.toDate.diff(this.filters.main.fromDate, "days").days + 1;
                        this.filters.comparison.fromDate = this.filters.main.toDate.plus({ days: 1 });
                        this.filters.comparison.toDate = this.filters.main.toDate.plus({ days: daysDifference });
                    }
                    break;
            }
        },
        validateFilters() {
            if (this.filters.main.fromDate !== null && this.filters.main.toDate !== null && this.filters.main.fromDate > this.filters.main.toDate) {
                this.notifyError(this.$tl("errors.common.dates.endGreaterThanStart", this.firstSelectedRestaurantId));
                return false;
            }

            if (
                this.filters.hasComparison &&
                this.filters.comparison.fromDate !== null &&
                this.filters.comparison.toDate !== null &&
                this.filters.comparison.fromDate > this.filters.comparison.toDate
            ) {
                this.notifyError(this.$tl("errors.common.dates.endGreaterThanStart", this.firstSelectedRestaurantId));
                return false;
            }

            return true;
        },
        emitFiltersUpdate() {
            if (!this.validateFilters()) {
                return false;
            }

            this.$emit("filters-updated", this.$_.cloneDeep(this.filters));
            return true;
        },
        fetchServicesCategories() {
            if (this.restaurantId === null) {
                return Promise.resolve();
            }

            ++this.nbLoading;

            return this.httpGet(`/api/stats/services-categories/${this.restaurantId}`)
                .then((response) => {
                    if (response !== false) {
                        this.servicesCategories = response.data.servicesCategories;
                    }
                })
                .finally(() => --this.nbLoading);
        },
        fetchRestaurants() {
            this.nbLoading++;

            return this.$store
                .dispatch("restaurants/fetchAllRestaurants")
                .then((response) => {
                    this.allRestaurants = response.data.data;
                    if (this.isClubMedOwnerId(this.ownerId)) {
                        this.allRestaurants.sort((a, b) => a.name.localeCompare(b.name));
                    }
                })
                .catch((error) => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.nbLoading--;
                });
        },
        updateRestaurantsMain() {
            if (this.filters.main.selectedZone === "all") {
                this.filters.main.selectedRestaurants = [...this.allRestaurants];
            } else {
                const zone = this.zones.find((z) => z.id === this.filters.main.selectedZone);
                if (zone && zone.restaurants) {
                    this.filters.main.selectedRestaurants = [...zone.restaurants];
                } else {
                    this.filters.main.selectedRestaurants = [...this.allRestaurants];
                }
            }
        },
        updateRestaurantsComparison() {
            if (this.filters.comparison.selectedZone === "all") {
                this.filters.comparison.selectedRestaurants = [...this.allRestaurants];
            } else {
                const zone = this.zones.find((z) => z.id === this.filters.comparison.selectedZone);
                if (zone && zone.restaurants) {
                    this.filters.comparison.selectedRestaurants = [...zone.restaurants];
                } else {
                    this.filters.comparison.selectedRestaurants = [...this.allRestaurants];
                }
            }
        },
        toggleGroupComparison() {
            this.filters.hasGroupComparison = !this.filters.hasGroupComparison;
            if (this.filters.hasGroupComparison) {
                this.filters.hasComparison = false;
            }
        },
    },
    watch: {
        hasRwg: {
            handler(newVal) {
                this.$emit("update-rwg", newVal);
            },
            immediate: true,
        },
        "filters.main.currentPeriod": function () {
            this.updateMainDatesFromMainPeriod();

            this.$nextTick(() => this.updateComparisonDatesFromMainPeriod());
        },
        "filters.comparison.currentPeriod": function () {
            this.updateComparisonDatesFromMainPeriod();
        },
        "filters.main.fromDate": function () {
            if (this.filters.main.currentPeriod !== "custom_period") {
                return;
            }

            this.updateComparisonDatesFromMainPeriod();
        },
        "filters.main.toDate": function () {
            if (this.filters.main.currentPeriod !== "custom_period") {
                return;
            }

            this.updateComparisonDatesFromMainPeriod();
        },
        "filters.hasComparison"(newVal) {
            if (newVal) {
                this.filters.hasGroupComparison = false;
            }
        },
    },
    created() {
        const promises = [this.fetchServicesCategories()];

        if (this.isGlobalReport) {
            promises.push(this.fetchZones(), this.fetchRestaurants());
        } else if (!this.isAdmin) {
            if (this.restaurantId === null) {
                throw "Should not happen: no restaurant_id found";
            } else {
                this.filters.main.selectedRestaurants = [{ id: this.restaurantId }];
            }
        }

        Promise.all(promises).finally(() => {
            if (this.isGlobalReport) {
                this.updateRestaurantsMain();
                this.updateRestaurantsComparison();
            }

            this.$nextTick(() => this.emitFiltersUpdate());
        });
    },
    components: { LoaderComponent, DatePicker, VueMultiselect },
};
</script>
