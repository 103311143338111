<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row mb-2">
                                <div class="col-md-5 pt-1">
                                    <label>Type de zone</label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div
                                            class="col-12 mb-1"
                                            v-for="areaType in ALL_DELIVERY_AREA_TYPES"
                                            :key="areaType.value">
                                            <div class="radio">
                                                <label class="container-box">
                                                    <input
                                                        type="radio"
                                                        v-model="deliveryArea.type"
                                                        :value="areaType.value"
                                                        :disabled="true" />
                                                    <span class="checkmark disabled"></span> {{ areaType.label }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ShowErrors class="col-12" :errors="errors" errorKey="cc_configuration_id" />
                            </div>
                            <SelectCities
                                class="row mb-1"
                                v-if="deliveryArea.type == DELIVERY_AREA_TYPE_CITIES.value"
                                :deliveryArea="deliveryArea"
                                :errors="errors" />
                            <SelectCrowFlies
                                class="row mb-1"
                                v-else-if="deliveryArea.type == DELIVERY_AREA_TYPE_CROW_FLIES.value"
                                :deliveryArea="deliveryArea"
                                :errors="errors"
                                :displayableDeliveryDeparture="displayableDeliveryDeparture" />
                            <template v-else-if="deliveryArea.type == DELIVERY_AREA_TYPE_DRAWN.value">
                                <div class="row mt-2">
                                    <div class="col-md-5">
                                        <label>Nom de la zone <small>*</small></label>
                                    </div>
                                    <div class="col-md-7">
                                        <input type="text" class="form-control" v-model="deliveryArea.name" />
                                        <ShowErrors class="d-block" :errors="errors" errorKey="name" />
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-5">
                                        <label>Couleur de la zone</label>
                                    </div>
                                    <div class="col-md-7">
                                        <div
                                            style="height: 20px; width: 80px"
                                            :style="`background-color: ${deliveryArea.drawn_color}`"></div>
                                    </div>
                                </div>
                            </template>
                            <div class="row mt-2">
                                <div class="col-md-5">
                                    <label>Tarif TTC <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="radio">
                                                <label class="container-box"
                                                    ><input type="radio" v-model="deliveryArea.is_free" :value="1" />
                                                    <span class="checkmark"></span> Gratuite
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 d-inline radio mt-1">
                                            <label class="container-box d-inline-block pt-0 mt-2" style="width: initial"
                                                ><input type="radio" v-model="deliveryArea.is_free" :value="0" />
                                                <span class="checkmark"></span> Payante
                                            </label>
                                            <template v-if="!deliveryArea.is_free">
                                                <input
                                                    type="number"
                                                    class="form-control d-inline mr-2"
                                                    style="width: 100px"
                                                    v-model="deliveryArea.price" />
                                                euros
                                                <ShowErrors class="d-block" :errors="errors" errorKey="price" />
                                                <div class="row" v-if="!isTvaDisabled">
                                                    <div class="col-12 pt-1">
                                                        <label
                                                            >Taux de TVA <small>*</small>
                                                            <select
                                                                class="custom-select mb-0 ml-2 mr-3"
                                                                style="width: inherit"
                                                                v-model="deliveryArea.tax_rate">
                                                                <option :value="null">--</option>
                                                                <option :value="0">0</option>
                                                                <option :value="5.5">5,5</option>
                                                                <option :value="10">10</option>
                                                                <option :value="20">20</option></select
                                                            >%
                                                        </label>
                                                    </div>
                                                    <ShowErrors class="col-12" :errors="errors" errorKey="tax_rate" />
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!deliveryArea.is_free" class="row mt-3">
                                <div class="col-md-5">
                                    <label>Livraison gratuite à partir d'un certain montant</label>
                                </div>
                                <div class="col-md-7">
                                    <div class="d-inline-block lead-switch mt-2">
                                        <input
                                            type="checkbox"
                                            class="switch align-self-center is-rounded"
                                            v-model="freeDelivery" />
                                        <label @click="freeDelivery = !freeDelivery"></label>
                                    </div>
                                    <template v-if="freeDelivery">
                                        <input
                                            type="number"
                                            class="form-control d-inline mr-2"
                                            style="width: 100px"
                                            v-model="deliveryArea.free_above" />
                                        euros
                                        <ShowErrors class="d-block" :errors="errors" errorKey="free_above" />
                                    </template>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-5">
                                    <label>Montant minimum de commande pour activer cette zone</label>
                                </div>
                                <div class="col-md-7">
                                    <div class="d-inline-block lead-switch mt-2">
                                        <input
                                            type="checkbox"
                                            class="switch align-self-center is-rounded"
                                            v-model="minOrderDelivery" />
                                        <label @click="minOrderDelivery = !minOrderDelivery"></label>
                                    </div>
                                    <template v-if="minOrderDelivery">
                                        <input
                                            type="number"
                                            class="form-control d-inline mr-2"
                                            style="width: 100px"
                                            v-model="deliveryArea.min_amount" />
                                        euros
                                        <ShowErrors class="d-block" :errors="errors" errorKey="min_amount" />
                                    </template>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-5">
                                    <label>Créneaux de disponibilité de la zone de livraison <small>*</small></label>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="radio">
                                                <label class="container-box">
                                                    <input
                                                        type="radio"
                                                        v-model="deliveryArea.use_default_delivery_slots"
                                                        :value="1" />
                                                    <span class="checkmark"></span> Utiliser les paramètres par défaut
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12 d-inline radio mt-1">
                                            <label
                                                class="container-box d-inline-block pt-0 mt-2"
                                                style="width: initial">
                                                <input
                                                    type="radio"
                                                    v-model="deliveryArea.use_default_delivery_slots"
                                                    :value="0" />
                                                <span class="checkmark"></span> Personnaliser
                                            </label>
                                        </div>
                                        <ShowErrors
                                            class="col-12"
                                            :errors="errors"
                                            errorKey="use_default_delivery_slots" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" v-if="!deliveryArea.use_default_delivery_slots">
                                <div class="offset-1 col-11 pt-2">
                                    <div v-for="day in allDays" :key="day">
                                        <div class="mb-2">
                                            <label class="container-box" style="margin-bottom: 0; width: 100%">
                                                <input type="checkbox" v-model="active[day]" />
                                                <span class="checkmark"></span> {{ dayLabel(day, true) }}
                                            </label>
                                        </div>
                                        <div class="setting-open" v-if="active[day]">
                                            <div class="setting-hours">
                                                <span v-for="slot in deliverySlots[day]" :key="slot.id" class="d-block">
                                                    <label class="container-box ml-4" style="width: initial">
                                                        <input type="checkbox" v-model="slot.enabled" />
                                                        <span class="checkmark"></span>&nbsp;{{
                                                            formatHour(slot.hour_start)
                                                        }}
                                                        - {{ formatHour(slot.hour_end) }}
                                                    </label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <ShowErrors class="d-block mt-1" :errors="errors" errorKey="delivery_slots.*" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../LoaderComponent";
import ShowErrors from "../../errors/ShowErrors";
import DeliveryAreaTypesEnum from "../../../mixins/enums/click_and_collect/DeliveryAreaTypesEnum";
import SelectCities from "./deliveryAreas/SelectCities";
import SelectCrowFlies from "./deliveryAreas/SelectCrowFlies";

export default {
    data() {
        return {
            loading: 0,
            error: null,
            errors: null,
            available_areas: null,
            freeDelivery: this.deliveryArea.free_above !== null,
            minOrderDelivery: this.deliveryArea.min_amount !== null,
            deliverySlots: null,
            allDays: [1, 2, 3, 4, 5, 6, 0],
            active: {
                0: false,
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
            },
            config: null,
        };
    },
    mixins: [DeliveryAreaTypesEnum],
    props: {
        deliveryArea: {
            type: Object,
            required: true,
        },
        do_not_save: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        displayableDeliveryDeparture() {
            if (!this.config) return null;
            if (
                this.config.delivery_departure_address &&
                this.config.delivery_departure_city &&
                this.config.delivery_departure_postal_code &&
                this.config.delivery_departure_country &&
                this.config.delivery_departure_lat &&
                this.config.delivery_departure_lng
            )
                return `${this.config.delivery_departure_address}, ${this.config.delivery_departure_postal_code} ${this.config.delivery_departure_city}`;
            return null;
        },
        isTvaDisabled() {
            return this.$store.getters["restaurants/isTvaDisabled"](this.restaurant_id);
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
        builtData() {
            let data = _.cloneDeep(this.deliveryArea);
            data.price = data.price == null ? null : data.price * 100;
            data.free_above = data.free_above == null ? null : data.free_above * 100;
            data.min_amount = data.min_amount == null ? null : data.min_amount * 100;
            data.free_delivery = this.freeDelivery;
            data.min_order = this.minOrderDelivery;
            data.delivery_slots.data = [];
            Object.keys(this.deliverySlots).forEach((day) => {
                if (this.active[day]) {
                    this.deliverySlots[day].forEach((slot) => {
                        if (slot.enabled) data.delivery_slots.data.push({ id: slot.id });
                    });
                }
            });
            data.do_not_save = this.do_not_save;
            return data;
        },
    },
    methods: {
        save() {
            this.loading++;
            this.errors = null;

            if (this.deliveryArea.id === null)
                var promise = axios.post(`/api/click_and_collect/${this.restaurant_id}/deliveryAreas`, this.builtData);
            else
                var promise = axios.put(
                    `/api/click_and_collect/${this.restaurant_id}/deliveryAreas/${this.deliveryArea.id}`,
                    this.builtData
                );
            promise
                .then(() => {
                    this.$emit("saved", this.builtData);
                })
                .catch((error) => {
                    this.loading--;
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    this.$notify({
                        group: "notification",
                        type: "error",
                        title: this.getErrorMsgFromErrorResponse(error),
                    });
                });
        },
        fetchDeliverySlots() {
            this.loading++;

            axios
                .get(`/api/click_and_collect/${this.restaurant_id}/settings?include=delivery_slots`)
                .then((response) => {
                    this.loading--;
                    this.deliverySlots = {};
                    this.config = response.data;
                    this.allDays.forEach((day) => {
                        this.deliverySlots[day] = response.data.delivery_slots.data.filter((s) => day === s.day);
                        for (let j = 0; j < this.deliverySlots[day].length; j++)
                            this.deliverySlots[day][j].enabled = this.deliveryArea.delivery_slots.data.some(
                                (s) => s.cc_delivery_slot_id === this.deliverySlots[day][j].id
                            );
                        this.active[day] = this.deliverySlots[day].some((s) => s.enabled);
                    });
                })
                .catch((error) => {
                    this.loading--;
                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
        SelectCities,
        SelectCrowFlies,
    },
    watch: {
        "deliveryArea.is_free": function (newVal) {
            if (newVal) {
                this.freeDelivery = false;
            }
        },
    },
    created() {
        this.fetchDeliverySlots();
    },
};
</script>
