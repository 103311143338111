<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Attention</h5>
        <div slot="body">
            Des commandes en cours existent pour les créneaux que vous souhaitez supprimer.
            <command-list
                :commands="commands"
                :restaurant_id="restaurant_id"
                :columns="{
                    number: true,
                    client: true,
                    status: {
                        show: true,
                        readonly: true,
                    },
                    date: true,
                    slot: true,
                    price: true,
                    products: true,
                    actions: false,
                }"
                @next="$emit('next', $event)"
                @previous="$emit('previous', $event)"
                class="modal-table mt-3" />
        </div>
        <div slot="footer" class="d-flex">
            <button
                type="button"
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                @click="$emit('close')">
                Annuler
            </button>
            <button type="button" class="btn btn-sm btn-success btn-circle ml-2" @click="$emit('cancel-and-save')">
                Annuler et rembourser
            </button>
            <button type="button" class="btn btn-sm btn-success btn-circle ml-2" @click="$emit('save')">
                Enregistrer
            </button>
        </div>
    </modal>
</template>

<script>
import CommandList from "../../../clickAndCollect/Command/CommandList";

export default {
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    props: {
        commands: {
            required: true,
        },
        restaurant_id: {
            required: true,
        },
    },
    components: {
        CommandList,
    },
};
</script>
