var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.title !== ``
    ? _c(
        "div",
        {
          staticClass: "notifications-card",
          style: _vm.isClickable ? "cursor:pointer !important;" : "",
          on: { click: _vm.onNotifClicked },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "notifications-header d-flex w-100 justify-content-between",
              class: _vm.customClass,
            },
            [
              _c("div", {
                staticClass: "notifications-title",
                domProps: { innerHTML: _vm._s(_vm.title) },
              }),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "notifications-time" }, [
                  _c("small", [_vm._v(_vm._s(_vm.createdAt))]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "notifications-close",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("read", {
                          id: _vm.notification.id,
                          index: _vm.index,
                        })
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "css-i6dzq1 feather",
                        attrs: {
                          viewBox: "0 0 24 24",
                          width: "24",
                          height: "24",
                          stroke: "currentColor",
                          "stroke-width": "2",
                          fill: "none",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      },
                      [
                        _c("line", {
                          attrs: { x1: "18", y1: "6", x2: "6", y2: "18" },
                        }),
                        _vm._v(" "),
                        _c("line", {
                          attrs: { x1: "6", y1: "6", x2: "18", y2: "18" },
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "notifications-content" },
            [
              !_vm.restaurant_id
                ? _c("div", {
                    staticClass: "notifications-title",
                    domProps: { innerHTML: _vm._s(_vm.restaurantName) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.notification.module_type === "booking"
                ? [
                    _vm.notification.type ===
                    "App\\Notifications\\RestauResaAllPlaced"
                      ? [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.notifications.booking.restauResaAllPlaced.content"
                                )
                              ) +
                              "\n            "
                          ),
                        ]
                      : _vm.notification.type ===
                        "App\\Notifications\\Restaurant\\SeatingPlanRoomDisabled"
                      ? [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.notifications.booking.seatingPlanRoomDisabled.content",
                                  undefined,
                                  {
                                    seatingPlan:
                                      _vm.notification.data.seatingPlanName,
                                  }
                                )
                              ) +
                              "\n                "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `/booking/restaurants/${_vm.notification.restaurant_id}/settings/seating-plan/${_vm.notification.data.seatingPlanId}`,
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$tl(
                                      "labels.notifications.booking.seatingPlanRoomDisabled.link"
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]
                      : _vm.notification.type ===
                        "App\\Notifications\\RestauResaNotPlaced"
                      ? [
                          _vm.nbReservations > 1
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tcl(
                                      "labels.notifications.booking.restauResaNotPlaced.content",
                                      _vm.nbReservations,
                                      _vm.restaurant_id,
                                      {
                                        date: _vm.reservationsDate,
                                      }
                                    )
                                  )
                                ),
                              ])
                            : _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "booking.restaurants.reservations",
                                      params: {
                                        restaurant_id:
                                          _vm.$route.params.restaurant_id,
                                      },
                                      query: {
                                        date: _vm.notification.data
                                          .reservationsDate,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.$tcl(
                                            "labels.notifications.booking.restauResaNotPlaced.content",
                                            _vm.nbReservations,
                                            _vm.restaurant_id,
                                            {
                                              date: _vm.reservationsDate,
                                            }
                                          )
                                        ) +
                                        "\n                    "
                                    ),
                                  ]),
                                ]
                              ),
                        ]
                      : [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.full_name) +
                              " - " +
                              _vm._s(_vm.nb_pers_total) +
                              " " +
                              _vm._s(_vm.$tl("labels.persons"))
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.displayDate(
                                  _vm.reservation_datetime,
                                  _vm.DATETIME_FULL
                                )
                              )
                          ),
                          _c("br"),
                          _vm._v(" "),
                          this.notification.data.comment
                            ? _c(
                                "div",
                                { staticClass: "text-muted morecontent" },
                                [
                                  _c("span", [
                                    _c("small", [_vm._v(_vm._s(_vm.comment))]),
                                  ]),
                                  _vm._v(" "),
                                  this.notification.data.comment.length >
                                  this.readMore.showchar
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "morelink",
                                          class: {
                                            less: _vm.readMore.moreToggled,
                                          },
                                          attrs: { href: "#" },
                                          on: {
                                            click: _vm.toggleMore,
                                            mousedown: function ($event) {
                                              return $event.preventDefault()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(_vm.morelinkContent) +
                                              "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ],
                    _vm._v(" "),
                    _vm.hasMultipleReservationChannels &&
                    [
                      "App\\Notifications\\RestauResaCreated",
                      "App\\Notifications\\RestauResaValidated",
                    ].includes(_vm.notification.type)
                      ? _c(
                          "div",
                          { staticClass: "d-flex my-2" },
                          [
                            _c("reservation-issuer-icon", {
                              staticClass: "notification-icon",
                              attrs: {
                                issuer: _vm.notification.data.created_from,
                                provider: _vm.notification.data.provider_name,
                                "created-at": _vm.notification.data.created_at,
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "ml-1 mb-0" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getLabelReservationFrom(
                                    _vm.notification.data.created_from,
                                    _vm.notification.data.provider_name
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.notification.module_type === "click-and-collect"
                ? [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.full_name) +
                        " - " +
                        _vm._s(_vm.total_amount) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.reservation_date) +
                        " - " +
                        _vm._s(_vm.slot_hour || "Expédition")
                    ),
                    _c("br"),
                    _vm._v(" "),
                    this.notification.data.comment
                      ? _c("div", { staticClass: "text-muted morecontent" }, [
                          _c("span", [
                            _c("small", [_vm._v(_vm._s(_vm.comment))]),
                          ]),
                          _vm._v(" "),
                          this.notification.data.comment.length >
                          this.readMore.showchar
                            ? _c(
                                "a",
                                {
                                  staticClass: "morelink",
                                  class: { less: _vm.readMore.moreToggled },
                                  attrs: { href: "#" },
                                  on: {
                                    click: _vm.toggleMore,
                                    mousedown: function ($event) {
                                      return $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.morelinkContent) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.notification.module_type === "gift-vouchers"
                ? [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.full_name) +
                        " - " +
                        _vm._s(_vm.amount) +
                        " "
                    ),
                    _c("br"),
                    _vm._v("\n            " + _vm._s(_vm.paid_at)),
                    _c("br"),
                    _vm._v(" "),
                    this.notification.data.comment_public
                      ? _c("div", { staticClass: "text-muted morecontent" }, [
                          _c("span", [
                            _c("small", [_vm._v(_vm._s(_vm.comment_public))]),
                          ]),
                          _vm._v(" "),
                          this.notification.data.comment_public.length >
                          this.readMore.showchar
                            ? _c(
                                "a",
                                {
                                  staticClass: "morelink",
                                  class: { less: _vm.readMore.moreToggled },
                                  attrs: { href: "#" },
                                  on: {
                                    click: _vm.toggleMore,
                                    mousedown: function ($event) {
                                      return $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.morelinkContent) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }