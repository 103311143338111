import axios from "axios";

export const actions = {
    login({}, data) {
        return axios.post("/login", data);
    },
    register({}, { user }) {
        return axios.post("/register", user);
    },
    resetPassword({}, user) {
        return axios.post("/password/reset", user);
    },
};

export default actions;
