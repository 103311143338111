export default {
    booking: {
        reservations: {
            cantPlace:
                "Cette réservation ne pourra pas être placée sur le plan de salle si vous la validez. Merci de sélectionner une table dans la liste",
            export: {
                atLeastOneService: "Merci de sélectionner au moins un service",
            },
        },
    },
};
