export default {
    booking: {
        services: {
            delete: "Are you sure you want to delete this service?",
            full: "Is your restaurant full?",
            removeFull: "Open the service to reservations?",
            openServiceForDate: 'The "{service}" service will be open for reservations for {date}. Do you want to continue?',
            openSlotForDate: 'The {hour} slot for the "{service}" service will be open for reservations for {date}. Do you want to continue?',
        },
        menus: {
            delete: "Are you sure you want to delete this menu?",
        },
    },
};
