export const getters = {
    id: (state, getters, rootState) => {
        return state.id;
    },
    firstName: (state, getters, rootState) => {
        return state.firstName;
    },
    lastName: (state, getters, rootState) => {
        return state.lastName;
    },
    email: (state, getters, rootState) => {
        return state.email;
    },
    tel: (state, getters, rootState) => {
        return state.tel;
    },
    tel_country: (state, getters, rootState) => {
        return state.tel_country;
    },
    tel_is_mobile: (state, getters, rootState) => {
        return state.tel_is_mobile;
    },
    lang(state) {
        return state.lang;
    },
    address: (state, getters, rootState) => {
        return state.address;
    },
    zipcode: (state, getters, rootState) => {
        return state.zipcode;
    },
    city: (state, getters, rootState) => {
        return state.city;
    },
    country: (state, getters, rootState) => {
        return state.country;
    },
    rights: (state, getters, rootState) => {
        return state.rights;
    },
    formattedRights: (state, getters, rootState) => {
        return state.formattedRights;
    },
    role: (state, getters, rootState) => {
        return state.role;
    },
    ownerId: (state) => {
        return state.owner_id;
    },
    company: (state, getters, rootState) => {
        return state.company;
    },
    companyType: (state, getters, rootState) => {
        return state.companyType;
    },
    companyTel: (state, getters, rootState) => {
        return state.companyTel;
    },
    companyEmail: (state, getters, rootState) => {
        return state.companyEmail;
    },
    companySiret: (state, getters, rootState) => {
        return state.companySiret;
    },
    companyCodeNaf: (state, getters, rootState) => {
        return state.companyCodeNaf;
    },
    companyCodeTva: (state, getters, rootState) => {
        return state.companyCodeTva;
    },
    companyAddress: (state, getters, rootState) => {
        return state.companyAddress;
    },
    companyZipcode: (state, getters, rootState) => {
        return state.companyZipcode;
    },
    companyCity: (state, getters, rootState) => {
        return state.companyCity;
    },
    companyCountry: (state, getters, rootState) => {
        return state.companyCountry;
    },
    getOwnerSubscriptions: (state, getters, rootState) => {
        return typeof state.ownerSubscriptions === "object" ? Object.values(state.ownerSubscriptions) : state.ownerSubscriptions;
    },
    getOwnerAlreadyTriedModules: (state, getters, rootState) => {
        return state.ownerAlreadyTriedModules;
    },
    getEnableNotificationRingtone: (state, getters, rootState) => {
        return state.enableNotificationRingtone;
    },
    activeModules: (state, getters, rootState) => {
        let tmp = {};
        const subscriptions = typeof state.ownerSubscriptions === "object" ? Object.values(state.ownerSubscriptions) : state.ownerSubscriptions;
        subscriptions.forEach((subscription) => {
            tmp[subscription.module.type] = subscription.module.title;
        });
        return tmp;
    },
    getLicencesByModuleType: (state, getters, rootState) => {
        const subscriptions = typeof state.ownerSubscriptions === "object" ? Object.values(state.ownerSubscriptions) : state.ownerSubscriptions;
        return (moduleType) => {
            return subscriptions.filter((subscription) => {
                return subscription.module.type === moduleType;
            });
        };
    },
    keepClientsOnNewsletterFor(state) {
        return state.keepClientsOnNewsletterFor;
    },
    keepClientsDetailsAndHistoryFor(state) {
        return state.keepClientsDetailsAndHistoryFor;
    },
    passwordLastChanged(state) {
        return state.passwordLastChanged;
    },
    newBadges: (state) => {
        return state.newBadges;
    },
    restaurantIds(state) {
        return state.restaurant_ids;
    },
    hasUnreadAppUpdates(state) {
        return state.hasUnreadAppUpdates;
    },
    isYproxUser(state) {
        return state.isYproxUser;
    },
    hasManualBilling(state) {
        return state.hasManualBilling;
    },
    hasUnseenFailedMailsNotificationsLogs(state) {
        return (restaurantId, moduleType) =>
            (state.unseenFailedMailsNotificationsLogs[restaurantId] && state.unseenFailedMailsNotificationsLogs[restaurantId][moduleType]) || false;
    },
    unreadSeatingPlanNotifications(state) {
        return state.unreadSeatingPlanNotifications;
    },
    hasRight(state) {
        return (right) => state.formattedRights.includes(right);
    },
};

export default getters;
