<template>
    <div class="row" v-if="restaurantsToSuggest.length > 0">
        <div class="col-12">
            <div class="border-light b-radius-20 p-4">
                <div class="row">
                    <div class="col-12">
                        <strong>{{ $tl("labels.booking.services.inCaseFull", restaurant_id) }}</strong>
                    </div>
                </div>
                <div class="menu-option mt-3">
                    <div class="row">
                        <div class="col-7">
                            <label>
                                {{ $tl("labels.booking.services.showRestaurantService", restaurant_id) }}
                                <feather
                                    v-tooltip="getTooltipNotice('notices.booking.servicesConfig.restaurantProposal')"
                                    type="info"
                                    class="ml-1 pointer tooltip-infos" />
                            </label>
                        </div>
                        <div class="col-5">
                            <div>
                                <div class="mb-2" v-for="restau in restaurantsToSuggest" :key="restau.id">
                                    <label class="container-box" style="margin-bottom: 0; width: 100%">
                                        <input
                                            type="checkbox"
                                            name="alternative_restaurants[]"
                                            v-model="service.full.restaurantsProposals"
                                            :value="restau.id" />
                                        <span class="checkmark"></span> {{ restau.name }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Misc",
    components: {},
    data() {
        return {};
    },
    props: {
        service: {
            required: true,
        },
        restaurant: {
            required: true,
        },
        restaurantsToSuggest: {
            required: true,
        },
    },
    mounted() {},
    created() {},
    methods: {
        displayError(error) {
            this.$emit("has-errors", error);
        },
        loadingStart() {
            this.$emit("loading-start");
        },
        loadingStop() {
            this.$emit("loading-stop");
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
};
</script>
