export default {
    booking: {
        dashboard:
            "Manage my availability: you can switch a game to full, block specific slots or block PAX by clicking directly on the box for the game concerned.",
        servicesConfig: {
            serviceName:
                "This name will only be displayed for you and will not be visible to your customers.<br/> It should allow you to differentiate between two games, e.g. Weekday afternoon - Weekend afternoon if you have created two separate games.",
            activeDays:
                "If you have booking conditions that vary between several games, consider creating different games.<br/> For example, if the booking slots are not identical on weekdays and weekends, you will need to create two games: one that will be active for weekdays, another for weekend days.",
            limitReservation: "If you wish to take reservations until the time your game starts, the value to be entered is 0.",
            tableRotation:
                "If you offer more than one game, this feature is ideal for providing an overview of your reservations and for using the timeline view (you will be able to see the reallocated seats).",
            exceptionalClosureConcernedServices:
                "If you wish to close only one of the games (and not all of them), you can select only the game(s) concerned.<br/> This can be used, for example, in the context of a private booking or a temporary change of the games offered.",
        },
    },
};
