var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5 pt-5" },
    [
      _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [
                _vm._v(
                  _vm._s(_vm.$tl("labels.routes.booking.config.integration"))
                ),
              ]),
              _vm._v(" "),
              _c("a", { attrs: { target: "_blank", href: _vm.widgetLink } }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-sm btn-success btn-sm btn-circle mr-1",
                    attrs: { disabled: !_vm.token, type: "button" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$tl("labels.widget.show")) +
                        "\n                    "
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent")
        : _c(
            "div",
            [
              _vm.error
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                  ])
                : _c(
                    "div",
                    [
                      _c("div", { staticClass: "row m-0 mb-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "border-light b-radius-20 p-4" },
                            [
                              _c("div", { staticClass: "row mb-3" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "infos.widget.infosIntegrationWebsite.title"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("p", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "infos.widget.infosIntegrationWebsite.content1",
                                            {
                                              type: _vm.$tl(
                                                "labels.widget.booking"
                                              ),
                                            }
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "infos.widget.infosIntegrationWebsite.content2"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "mb-0 text-danger" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "infos.widget.infosIntegrationWebsite.content3"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-success btn-sm btn-circle",
                                      class: {
                                        disabled:
                                          !_vm.has_right_to_update_widget,
                                      },
                                      attrs: {
                                        disabled:
                                          !_vm.has_right_to_update_widget,
                                        type: "button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showSendWidgetDocToDevModal = true
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.widget.sendInstructions"
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row m-0 mb-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "border-light b-radius-20 p-4" },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.widget.integrationWebsite"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mt-3" }, [
                                _c("div", { staticClass: "col-md-5" }, [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl("labels.widget.defaultLang")
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.default_lang,
                                          expression: "default_lang",
                                        },
                                      ],
                                      staticClass: "custom-select",
                                      staticStyle: { width: "auto" },
                                      attrs: {
                                        disabled: _vm.isSavingDefaultLang,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.default_lang = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                      },
                                    },
                                    _vm._l(
                                      _vm.ALL_WIDGET_LANGS,
                                      function (lang) {
                                        return _c(
                                          "option",
                                          {
                                            key: lang.value,
                                            domProps: { value: lang.value },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.getWidgetLangLabel(
                                                    lang.value
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mt-3" }, [
                                _c("div", { staticClass: "col-md-5" }, [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.$tl("labels.widget.shortLink"))
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c("span", { staticClass: "mr-2" }, [
                                        _vm._v(
                                          _vm._s(_vm.appUrl) + "/reservation/"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.shortlink,
                                            expression: "shortlink",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        staticStyle: { width: "45%" },
                                        attrs: {
                                          type: "text",
                                          maxlength: "40",
                                          disabled:
                                            !_vm.has_right_to_update_widget,
                                        },
                                        domProps: { value: _vm.shortlink },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.shortlink = $event.target.value
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-success btn-sm btn-circle ml-2",
                                          class: {
                                            disabled:
                                              !_vm.has_right_to_update_widget ||
                                              _vm.isSavingShortlink,
                                          },
                                          attrs: {
                                            disabled:
                                              !_vm.has_right_to_update_widget ||
                                              _vm.isSavingShortlink,
                                            type: "button",
                                          },
                                          on: { click: _vm.saveShortLink },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.form.actions.save"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("small", { staticClass: "text-muted" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("infos.widget.shortLink", {
                                            max: 40,
                                          })
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mt-3" }, [
                                _c("div", { staticClass: "col-md-5" }, [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.$tl("labels.widget.apiKey"))
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7" }, [
                                  _vm.token
                                    ? _c(
                                        "div",
                                        { staticClass: "mb-3 code-source" },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(_vm.token) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-success btn-sm btn-circle",
                                      class: {
                                        disabled:
                                          !_vm.has_right_to_update_widget,
                                      },
                                      attrs: {
                                        disabled:
                                          !_vm.has_right_to_update_widget,
                                        type: "button",
                                      },
                                      on: { click: _vm.generateNewApiKey },
                                    },
                                    [
                                      !_vm.token
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.widget.genApiKey"
                                                )
                                              )
                                            ),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.widget.genNewApiKey"
                                                )
                                              )
                                            ),
                                          ]),
                                    ]
                                  ),
                                  _c("br"),
                                  _vm._v(" "),
                                  _vm.token
                                    ? _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$tl(
                                                "infos.widget.oldKeyRevoked"
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mt-3" }, [
                                _c("div", { staticClass: "col-md-5 pt-1" }, [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.widget.integrationMethod"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-7 pt-1" }, [
                                  _c("p", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("labels.stepX", { step: 1 })
                                        ) + _vm._s(_vm.$tl("labels.colon"))
                                      ),
                                    ]),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "infos.widget.stepsIntegration.1"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "code-source mb-3" },
                                    [
                                      _vm._v(
                                        '<div id="' +
                                          _vm._s(
                                            _vm.$tl("labels.widget.containerId")
                                          ) +
                                          '"></div>'
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("labels.stepX", { step: 2 })
                                        ) + _vm._s(_vm.$tl("labels.colon"))
                                      ),
                                    ]),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "infos.widget.stepsIntegration.2"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "code-source mb-3" },
                                    _vm._l(
                                      _vm.jsToInclude,
                                      function (js, index) {
                                        return _c(
                                          "span",
                                          {
                                            key: index,
                                            staticClass: "d-block",
                                          },
                                          [_vm._v(_vm._s(js))]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("p", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("labels.stepX", { step: 3 })
                                        ) + _vm._s(_vm.$tl("labels.colon"))
                                      ),
                                    ]),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "infos.widget.stepsIntegration.3"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "code-source mb-3" },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.classFunctionName) +
                                          "('" +
                                          _vm._s(
                                            _vm.$tl("labels.widget.containerId")
                                          ) +
                                          "', '" +
                                          _vm._s(
                                            _vm.$tl("labels.widget.yourApiKey")
                                          ) +
                                          "');\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "mb-0" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("labels.stepX", { step: 4 })
                                        ) +
                                          " (" +
                                          _vm._s(_vm.$tl("labels.optionalF")) +
                                          ")" +
                                          _vm._s(_vm.$tl("labels.colon"))
                                      ),
                                    ]),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "infos.widget.stepsIntegration.4"
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$tl("infos.widget.optionalParams")
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("ol", [
                                    _c(
                                      "li",
                                      { staticClass: "list-style-inherit" },
                                      [
                                        _vm._v(
                                          '\n                                        "lang"' +
                                            _vm._s(_vm.$tl("labels.colon")) +
                                            " " +
                                            _vm._s(
                                              _vm.$tl("infos.widget.lang")
                                            ) +
                                            "\n                                        "
                                        ),
                                        _c(
                                          "ul",
                                          _vm._l(
                                            _vm.ALL_WIDGET_LANGS,
                                            function (availableLang) {
                                              return _c(
                                                "li",
                                                {
                                                  key: availableLang.value,
                                                  staticClass:
                                                    "list-style-inherit",
                                                },
                                                [
                                                  _vm._v(
                                                    '\n                                                "' +
                                                      _vm._s(
                                                        availableLang.value
                                                      ) +
                                                      '"' +
                                                      _vm._s(
                                                        _vm.$tl("labels.colon")
                                                      ) +
                                                      "\n                                                " +
                                                      _vm._s(
                                                        _vm.getWidgetLangLabel(
                                                          availableLang.value
                                                        )
                                                      ) +
                                                      "\n                                            "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "code-source mb-3" },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.classFunctionName) +
                                          "('" +
                                          _vm._s(
                                            _vm.$tl("labels.widget.containerId")
                                          ) +
                                          "', '" +
                                          _vm._s(
                                            _vm.$tl("labels.widget.yourApiKey")
                                          ) +
                                          "', {\n                                    lang: '" +
                                          _vm._s(_vm.WIDGET_LANG_EN.value) +
                                          "' });\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "mb-0" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("labels.stepX", { step: 5 })
                                        ) +
                                          " (" +
                                          _vm._s(_vm.$tl("labels.optionalF")) +
                                          ")" +
                                          _vm._s(_vm.$tl("labels.colon"))
                                      ),
                                    ]),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "infos.widget.stepsIntegration.5"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "text-warning" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tl(
                                              "infos.widget.gTagManager.gTagPrerequisite"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$tl(
                                            "infos.widget.gTagManager.gTagFirst"
                                          )
                                        )
                                    ),
                                    _c("br"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "code-source mb-3" },
                                    [
                                      _vm._v(
                                        "\n                                    <!-- Google Tag Manager -->"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                                    <script>"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var\n                                    f=d.getElementsByTagName(s)[0],\n                                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})\n                                    (window,document,'script','dataLayer', '"
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$tl(
                                                "infos.widget.gTagManager.gTagIdExample"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v("');"),
                                      _c("br"),
                                      _vm._v(
                                        "\n                                    </script>"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                                    <!-- End Google Tag Manager -->\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$tl(
                                            "infos.widget.gTagManager.gTagSecond"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$tl(
                                            "infos.widget.gTagManager.gTagInfo"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("ul", [
                                    _c(
                                      "li",
                                      { staticClass: "list-style-inherit" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "infos.widget.gTagManager.gTagStep1"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { staticClass: "list-style-inherit" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "infos.widget.gTagManager.gTagStep2"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { staticClass: "list-style-inherit" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "infos.widget.gTagManager.gTagStep3"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { staticClass: "list-style-inherit" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "infos.widget.gTagManager.gTagStep4"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { staticClass: "list-style-inherit" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "infos.widget.gTagManager.gTagStep5"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { staticClass: "list-style-inherit" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "infos.widget.gTagManager.gTagStep6"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { staticClass: "list-style-inherit" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "infos.widget.gTagManager.gTagStep7"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { staticClass: "list-style-inherit" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "infos.widget.gTagManager.gTagStep8"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { staticClass: "list-style-inherit" },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "infos.widget.gTagManager.gTagStep9"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.token !== null
                                ? _c("div", { staticClass: "row mt-3" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-12 pt-1" },
                                      [
                                        _c("p", [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("labels.widget.code", {
                                                  type: _vm.$tl(
                                                    "labels.widget.booking"
                                                  ),
                                                })
                                              )
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "code-source mb-3" },
                                          [
                                            _vm._v(
                                              '\n                                    <div id="' +
                                                _vm._s(_vm.idName) +
                                                '"></div>\n                                    '
                                            ),
                                            _vm._l(
                                              _vm.jsToInclude,
                                              function (js, index) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: index,
                                                    staticClass: "d-block",
                                                  },
                                                  [_vm._v(_vm._s(js))]
                                                )
                                              }
                                            ),
                                            _vm._v(
                                              "\n                                    <script>" +
                                                _vm._s(_vm.classFunctionName) +
                                                "('" +
                                                _vm._s(_vm.idName) +
                                                "', '" +
                                                _vm._s(_vm.token) +
                                                "', { lang: '" +
                                                _vm._s(
                                                  _vm.WIDGET_LANG_FR.value
                                                ) +
                                                "'\n                                    });</script>\n                                "
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("FacebookIntegration", {
                        staticClass: "m-0 mb-3",
                        attrs: { module: _vm.MODULE_TYPE_BOOKING.value },
                      }),
                      _vm._v(" "),
                      _c("InstagramIntegration", {
                        staticClass: "m-0 mb-3",
                        attrs: { module: _vm.MODULE_TYPE_BOOKING.value },
                      }),
                      _vm._v(" "),
                      _c("GoogleLinkIntegration", { staticClass: "m-0 mb-3" }),
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.showSendWidgetDocToDevModal
                ? _c("SendWidgetDocToDevModal", {
                    attrs: { url: _vm.sendWidgetDocToDevUrl },
                    on: {
                      close: function ($event) {
                        _vm.showSendWidgetDocToDevModal = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }