import "./bootstrap";
import Es6Promise from "es6-promise";
import "unfetch/polyfill";

import "babel-polyfill";
import Vue from "vue";
import VueFeather from "vue-feather";
import CKEditor from "@ckeditor/ckeditor5-vue2";

import Vuex from "vuex";
import store from "./store/store";
//config
import Package from "../../package.json";

// Route
import Routes from "./routes";

// App
import App from "./App.vue";

import { polyfill as MobileDragAndDropPolyfill } from "mobile-drag-drop";
import { scrollBehaviourDragImageTranslateOverride } from "mobile-drag-drop/scroll-behaviour";

const ua = window.navigator.userAgent.toLowerCase();
const isiPad = ua.indexOf("ipad") > -1 || (ua.indexOf("macintosh") > -1 && "ontouchend" in document);

const usePolyfill = MobileDragAndDropPolyfill({
    holdToDrag: 80,
    forceApply: isiPad, // force apply for ipad
    dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride || isiPad,
});

if (usePolyfill) {
    document.addEventListener("dragenter", (event) => event.preventDefault());
    window.addEventListener("touchmove", () => {}, { passive: false });
}

// Layout
import AuthLayout from "./layouts/Auth/Default.vue";
import RegistrationLayout from "./layouts/Auth/Registration.vue";
import DefaultLayout from "./layouts/Default/Default.vue";
import PasswordresetLayout from "./layouts/Auth/Passwordreset.vue";

import BookingLayout from "./layouts/Booking/Default.vue";
import BookingWidget from "./layouts/Booking/Public/Widget/Widget.vue";
import BookingWidgetPage from "./layouts/Booking/Public/Widget/Widgetpage.vue";

import ClickAndCollectLayout from "./layouts/ClickAndCollect/Default.vue";

import GiftVouchersLayout from "./layouts/GiftVouchers/Default.vue";

import WebsiteLayout from "./layouts/Website/Default.vue";

import CampaignsLayout from "./layouts/Campaigns/Default.vue";

import ErrorsLayout from "./layouts/Errors/Default.vue";

// plugin
import VueTelInput from "vue-tel-input";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import i18n from "./plugins/i18n";
import lodash from "./plugins/lodashAsAPlugin";

// Global Enums
import LangsEnum from "./mixins/enums/LangsEnum.js";

// Global Mixins
import UtilsHelper from "./mixins/helpers/Utils.js";
import DateUtilsHelper from "./mixins/helpers/DateUtils.js";
import EnvUtils from "./mixins/helpers/EnvUtils.js";

// Theme
import theme from "./themes/loader.js";

//Modals
import Modal from "./components/Modals/base/Modal.vue";
import ModalNoMarginNoPadding from "./components/Modals/base/ModalNoMarginNoPadding.vue";
import ErrorsInterceptor from "./axios/Interceptors/ErrorsInterceptor";

import Konva from "konva";

import countries from "i18n-iso-countries";
import { DEVELOPMENT, PRODUCTION } from "./utils/envs";
import { initSentry } from "./plugins/sentry.js";

Es6Promise.polyfill();

// Register languages for i18n-iso-countries
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

Vue.prototype.$__BUILD_ID__ = BUILD_ID;
Vue.use(lodash);
Vue.use(VueTelInput);
Vue.use(FloatingVue);
Vue.use(VueFeather);
Vue.use(CKEditor);
Vue.use(theme);

Vue.component("auth-layout", AuthLayout);
Vue.component("registration-layout", RegistrationLayout);

Vue.component("booking-layout", BookingLayout);
Vue.component("booking-widget-layout", BookingWidget);
Vue.component("booking-widget-page-layout", BookingWidgetPage);

Vue.component("click-and-collect-layout", ClickAndCollectLayout);

Vue.component("gift-vouchers-layout", GiftVouchersLayout);

Vue.component("website-layout", WebsiteLayout);

Vue.component("campaigns-layout", CampaignsLayout);

Vue.component("default-layout", DefaultLayout);

Vue.component("passwordreset-layout", PasswordresetLayout);

Vue.component("errors-layout", ErrorsLayout);

Vue.component("modal", Modal);

Vue.component("modal-m0-p0", ModalNoMarginNoPadding);

Vue.mixin(LangsEnum);

Vue.mixin(UtilsHelper);
Vue.mixin(DateUtilsHelper);
Vue.mixin(EnvUtils);

Vue.directive("visible", (el, bind) => {
    el.style.visibility = !!bind.value ? "visible" : "hidden";
});

ErrorsInterceptor();

// App
const app = new Vue({
    el: "#app",
    i18n,
    router: Routes,
    store,
    render: (h) => h(App),
});

if (ENABLE_SENTRY) {
    initSentry(Routes);
}
