<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.form.sms.enable") }}</h5>
        <div slot="body" class="text-center">
            <div>
                <p>{{ $tl("infos.sms.useNeedActivation") }}</p>
                <router-link :to="{ name: 'sms' }" class="btn btn-sm btn-circle btn-success">{{ $tl("labels.form.sms.enable") }}</router-link>
            </div>
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button type="button" class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.close") }}
            </button>
        </div>
    </modal>
</template>

<script>
import purchaseSmsPack from "../../sms/PurchaseSmsPack.vue";

export default {
    data: function () {
        return {
            loading: false,
        };
    },
    props: {
        smsPack: {
            default: "",
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        purchaseSmsPack,
    },
};
</script>
