<template>
    <tbody>
        <tr
            v-for="(data, index) in filteredDataSource"
            :key="filteredDataSource[keyColumn] || index"
            @click.prevent.stop="$parent.$emit('row-selected', { event: $event, data })"
            :class="{
                pointer: clickable,
                clickable,
                new: data.new,
                updated: data.updated,
            }">
            <td>
                <inline-client :client="data[attribute].client" :module-enum="MODULE_TYPE_BOOKING" />
            </td>
            <td>
                {{ displayDate(data[attribute].reservation_datetime, DATE_SHORT) }}
            </td>
            <td>
                {{ displayHour(data[attribute].slot.hour_start) }}
            </td>
            <td>
                <span
                    v-if="data[attribute].nb_children"
                    v-tooltip="getTooltip(displayPax(data[attribute].total_nb_pers, data[attribute].nb_children))">
                    {{ data[attribute].total_nb_pers }}({{ data[attribute].nb_children }})
                </span>
                <span v-else>
                    {{ data[attribute].total_nb_pers }}
                </span>
            </td>
            <td>
                {{ displayDate(data.created_at, DATE_SHORT) }}
            </td>
            <td>
                <span v-if="data.status === LOG_MAIL_NOT_DELIVERED.value" class="text-danger">
                    {{
                        $tl("labels.booking.reservations.mailLogs.permanent_failed", restaurantId, {
                            type: getMailLogLabel(data.type),
                        })
                    }}
                </span>
                <span v-else-if="data.status === LOG_MAIL_PENDING.value" class="text-warning">
                    {{ $tl("labels.booking.reservations.mailLogs.pending", restaurantId, { type: getMailLogLabel(data.type) }) }}
                </span>
                <span v-else-if="data.status === LOG_MAIL_DELIVERED.value">
                    {{
                        $tl("labels.booking.reservations.mailLogs.sent_confirmed", restaurantId, {
                            type: getMailLogLabel(data.type),
                        })
                    }}
                </span>
            </td>
            <td>
                <div v-if="isFragmentLoading[data.id]" class="spinner-border feather-20" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <button
                    v-else
                    type="button"
                    class="btn btn-sm btn-outline-secondary btn-square"
                    :disabled="isDisabled || !data.should_be_send_again"
                    @click.prevent.stop="$emit('send-mail', data)">
                    <feather type="send" />
                </button>
            </td>
        </tr>
    </tbody>
</template>
<script>
import ReservationMailLogEnum from "../../../../../mixins/enums/booking/ReservationMailLogEnum.js";
import ClientCivilityEnum from "../../../../../mixins/enums/ClientCivilityEnum.js";
import LogMailStatusEnum from "../../../../../mixins/enums/LogMailStatusEnum.js";
import ModuleTypesEnum from "../../../../../mixins/enums/ModuleTypesEnum.js";
import inlineClient from "../../../../Default/Clients/inlineClient.vue";

export default {
    props: {
        filteredDataSource: {
            type: Array,
            required: true,
        },
        clickable: {
            type: Boolean,
            required: true,
        },
        restaurantId: {
            type: Number,
            required: true,
        },
        isFragmentLoading: {
            type: Object,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            required: true,
        },
        keyColumn: {
            type: String,
            required: true,
        },
        isWaitingsMode: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        inlineClient,
    },
    mixins: [ReservationMailLogEnum, ClientCivilityEnum, LogMailStatusEnum, ModuleTypesEnum],
    methods: {
        displayHour(hour) {
            return this.displayDate(this.setHourOnDateTime(hour), this.TIME_SIMPLE);
        },
        displayPax(nbPers, nbChild) {
            return `${this.$tc("labels.person", nbPers)} (${this.$tc("labels.booking.reservations.includingXChildren", nbChild)})`;
        },
        getMailLogLabel(type) {
            return this.getReservationMailLogLabel(this.isWaitingsMode ? this.$tl("labels.booking.waitings.mailLogs.type") : type, this.restaurantId);
        },
    },
    computed: {
        attribute() {
            return this.isWaitingsMode ? "waiting" : "reservation";
        },
    },
};
</script>
