<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-show="!loading" :style="`height: ${this.height};`" :id="mapId"></div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent";

window.checkAndAttachMapScript = function (callback, apiKey) {
    if (!!window.google) {
        callback();
        return true;
    }

    window.mapApiInitialized = callback;
    let script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=drawing,geometry&callback=mapApiInitialized`;
    document.body.appendChild(script);
};

export default {
    data() {
        return {
            loading: false,
            map: null,
        };
    },
    props: {
        showLoader: {
            type: Boolean,
            default: true,
        },
        stopLoader: {
            type: Boolean,
            default: true,
        },
        immediate: {
            type: Boolean,
            default: true,
        },
        mapCenter: {
            type: Object,
            default: null,
        },
        mapZoom: {
            type: Number,
            default: 8,
        },
        height: {
            type: String,
            default: "60vh",
        },
        mapId: {
            type: String,
            default: "gmap",
        },
    },
    computed: {
        mapCenter_() {
            if (this.mapCenter) {
                return this.mapCenter;
            }

            return {
                // Center at Lyon by default
                lat: 45.762246,
                lng: 4.835634,
            };
        },
    },
    methods: {
        init() {
            window.checkAndAttachMapScript(this.onScriptLoaded, GOOGLE_MAPS_API_KEY);
        },
        onScriptLoaded() {
            this.map = new google.maps.Map(document.getElementById(this.mapId), {
                center: this.mapCenter_,
                zoom: this.mapZoom,
            });
            if (this.stopLoader) this.loading = false;
            this.$emit("initialized");
        },
        getMap() {
            return this.map;
        },
        getMapLibrary() {
            return google.maps;
        },
        getLatLngObj(lat, lng) {
            return new google.maps.LatLng({ lat, lng });
        },
        setLoading(loading) {
            this.loading = loading;
        },
        getCirclePoints(center, radius, clockwise, numPoints = 42) {
            const centerForFct = new google.maps.LatLng(center.lat, center.lng);
            var points = [];
            for (var i = 0; i < numPoints; ++i) {
                var angle = (i * 360) / numPoints;
                if (!clockwise) angle = 360 - angle;
                var p = google.maps.geometry.spherical.computeOffset(centerForFct, radius, angle);
                points.push(p);
            }
            points.push(points[0]);
            return points;
        },
        drawPolygon(polygonOptions) {
            return new google.maps.Polygon({
                ...polygonOptions,
                map: this.map,
            });
        },
        drawCircle(circleOptions) {
            return new google.maps.Circle({
                ...circleOptions,
                map: this.map,
            });
        },
        addInfoWindow(shape, content, event = "click", size = null) {
            var infowindow = new google.maps.InfoWindow({
                size: size || new google.maps.Size(150, 50),
            });
            google.maps.event.addListener(shape, event, (e) => {
                infowindow.setContent(content);
                infowindow.setPosition(e.latLng);
                infowindow.open(this.map);
            });
        },
        addMarker(params) {
            return new google.maps.Marker({
                ...params,
                map: this.map,
            });
        },
    },
    components: {
        LoaderComponent,
    },
    created() {
        if (this.showLoader) {
            this.loading = true;
        }
    },
    mounted() {
        if (this.immediate) {
            this.init();
        }
    },
};
</script>
