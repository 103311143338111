var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "main", staticClass: "position-relative" }, [
    _c("div", { ref: "content" }, [_vm._t("content")], 2),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "overlay",
        staticClass: "position-absolute overlay-bg",
        class: { "d-none": !_vm.displayOverlay },
        staticStyle: { top: "0", left: "0", "z-index": "500" },
        style: {
          height: `${_vm.clientHeight}px`,
          width: `${_vm.clientWidth}px`,
        },
      },
      [_vm._t("overlay")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }