var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("loader-component")
        : _c(
            "div",
            {
              staticClass: "mb-1",
              class: { "d-flex align-items-center": !_vm.smallScreen },
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.clientType,
                      expression: "clientType",
                    },
                  ],
                  staticClass: "custom-select m-0",
                  attrs: {
                    id: "client_type",
                    name: "type",
                    required: _vm.required,
                    disabled: _vm.readonly,
                  },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.clientType = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: null } }, [_vm._v("--")]),
                  _vm._v(" "),
                  _vm._l(_vm.ALL_CLIENT_TYPES, function (clientType) {
                    return _c(
                      "option",
                      {
                        key: clientType.value,
                        domProps: { value: clientType.value },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.getClientTypeLabel(clientType.value)) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.additionalClientTypes, function (clientType) {
                    return _c(
                      "option",
                      { key: clientType, domProps: { value: clientType } },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(clientType) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "option",
                    { domProps: { value: this.CLIENT_TYPE_CUSTOM.value } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$tl("labels.clients.types.custom")) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _vm.clientType === this.CLIENT_TYPE_CUSTOM.value
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.clientTypeCustom,
                        expression: "clientTypeCustom",
                      },
                    ],
                    staticClass: "form-control",
                    class: {
                      "ml-1": !_vm.smallScreen,
                      "mt-1": _vm.smallScreen,
                    },
                    attrs: { type: "text", name: "client_type_custom" },
                    domProps: { value: _vm.clientTypeCustom },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.clientTypeCustom = $event.target.value
                      },
                    },
                  })
                : _vm._e(),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }