import axios from "axios";

export const actions = {
    fetchSmsInfos({ commit }, { store = true } = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get("/api/sms")
                .then((response) => {
                    if (store) {
                        commit("set", response.data.data);
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

export default actions;
