<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Ajouter une zone d'expédition</h5>
        <div slot="body">
            <add-shipping-area ref="addShippingAreaComponent" :area="area" @saved="$emit('saved')" />
        </div>
        <div slot="footer" class="d-flex">
            <button
                type="button"
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                @click="$emit('close')">
                Annuler
            </button>
            <button
                type="button"
                class="btn btn-sm btn-success btn-circle ml-2"
                @click="$refs.addShippingAreaComponent.save()">
                Enregistrer
            </button>
        </div>
    </modal>
</template>

<script>
import addShippingArea from "../../giftVouchers/addShippingArea.vue";

export default {
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    props: {
        area: {
            type: Object,
            required: true,
        },
    },
    components: {
        addShippingArea,
    },
};
</script>
