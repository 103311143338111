<template>
    <div class="border-light d-flex justify-content-between p-3 align-items-center">
        <div>
            <span v-if="isPaid">{{ paidAt }}</span>
            <span v-else-if="invoice.voided" class="text-danger">{{ $tl("labels.invoices.canceled") }}</span>
            <span v-else>{{ $tl("labels.invoices.pending") }}</span>
            <h5 class="mb-0" style="line-height: 1.6">
                {{ $t("labels.invoices.number", { number: invoice.number }) }}
            </h5>
            <div>
                {{ formatCurrency(invoice.amount / 100)
                }}<span v-if="invoice.amount_refunded">
                    {{ $t("labels.invoices.refunded", { amount: formatCurrency(invoice.amount_refunded / 100) }) }}</span
                >
            </div>
            <span>{{ $tl("labels.subscriptions.subscription") }} {{ planLabel.toLowerCase() }}</span>
        </div>
        <div>
            <a target="_blank" class="btn btn-sm btn-circle btn-success" :href="downloadLink">
                {{ $tl("labels.form.actions.download") }}
            </a>
        </div>
    </div>
</template>

<script>
import StripePlanTypesEnum from "../../../mixins/enums/StripePlanTypesEnum";
import moment from "moment";

export default {
    props: {
        invoice: {
            required: true,
        },
    },
    computed: {
        downloadLink() {
            return `/api/stripe/invoices/${this.invoice.id}`;
        },
        plan() {
            if (this.invoice.noshow_subscription) {
                return this.invoice.noshow_subscription.plan;
            }

            return "";
        },
        planLabel() {
            return this.getStripePlanTypeLabel(this.plan);
        },
        isPaid() {
            return this.invoice.paid_at;
        },
        paidAt() {
            const momentDate = moment.unix(this.invoice.paid_at);
            return momentDate.format("DD MMMM Y");
        },
    },
    mixins: [StripePlanTypesEnum],
};
</script>
