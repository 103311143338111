var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("loader-component")
        : _c("div", { staticClass: "row" }, [
            _vm.subscriptions.length === 0
              ? _c("div", { staticClass: "col-12" }, [
                  _c("span", [
                    _vm._v("Ce code promo n'a pas encore été utilisé"),
                  ]),
                ])
              : _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("ns-table", {
                      attrs: {
                        "table-class": "table table-striped w-100",
                        "data-source": _vm.subscriptions,
                        "toolbar-settings": _vm.toolbarSettings,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "head",
                          fn: function () {
                            return [
                              _c(
                                "ns-th",
                                {
                                  attrs: {
                                    field: "owner.lastname",
                                    "allow-sorting": "",
                                  },
                                },
                                [_vm._v("Utilisateur")]
                              ),
                              _vm._v(" "),
                              _c(
                                "ns-th",
                                {
                                  attrs: {
                                    field: "owner.email",
                                    "allow-sorting": "",
                                  },
                                },
                                [_vm._v("Email")]
                              ),
                              _vm._v(" "),
                              _c(
                                "ns-th",
                                {
                                  attrs: {
                                    field: "created_at",
                                    "allow-sorting": "",
                                  },
                                },
                                [_vm._v("Date d'utilisation")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "body",
                          fn: function ({ data }) {
                            return [
                              _c("td", [
                                _vm._v(_vm._s(_vm.getUserFullName(data.owner))),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.getUserEmail(data.owner))),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.displayDate(
                                      data.created_at,
                                      _vm.DATE_FULL
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }