<template>
    <li class="nav-item" v-if="hasRights">
        <span v-if="isEventMode" class="nav-link" :class="{ 'router-link-active': isActive }" @click="$emit('set-active')">
            {{ entryTitle }}<newBadge v-if="hasNewBadge" class="ml-2" />
        </span>
        <router-link v-else :to="entry.to" class="nav-link" :exact="entry.exactUrlMatching">
            {{ entryTitle }}<newBadge v-if="hasNewBadge" class="ml-2" />
        </router-link>
    </li>
</template>

<script>
import newBadge from "../NewBadges/newBadge";

export default {
    props: {
        entry: {
            required: true,
        },
        isEventMode: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        restaurantId() {
            return this.$route.params.restaurant_id || undefined;
        },
        entryTitle() {
            return typeof this.entry.titleI18n !== "undefined" ? this.$tl(this.entry.titleI18n, this.restaurantId) : this.entry.title;
        },
        hasNewBadge() {
            return this.entry && this.entry.to && this.entry.to.name ? this.checkForExistingNewBadge(this.entry.to.name) : false;
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        hasRights() {
            if (!Object.keys(this.entry).includes("rights")) return true;
            if (this.entry.rights.includes("any")) return true;
            return this.entry.rights.some((right) => {
                if (this.rights.includes(right)) return true;
                let i = right.indexOf("*");
                if (i !== -1) {
                    right = right.substring(0, i).replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
                    let regexp = new RegExp(`${right}.+`, "g");
                    return this.rights.some((cright) => cright.match(regexp));
                }
                return false;
            });
        },
    },
    components: { newBadge },
};
</script>
