var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pr-0 pb-5" },
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c("div", [
                  _c("div", { staticClass: "row m-0" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                        },
                        [
                          _c("h5", { staticClass: "title mt-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$tl(
                                  "labels.routes.booking.services.menus",
                                  _vm.restaurant_id
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm.has_right_to_update_menu
                                ? [
                                    !_vm.isOrdering
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-success mr-1 btn-circle",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.startOrdering()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "css-i6dzq1 feather",
                                                attrs: {
                                                  viewBox: "0 0 24 24",
                                                  width: "24",
                                                  height: "24",
                                                  stroke: "currentColor",
                                                  "stroke-width": "2",
                                                  fill: "none",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                },
                                              },
                                              [
                                                _c("polyline", {
                                                  attrs: {
                                                    points: "9 5 12 2 15 5",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("polyline", {
                                                  attrs: {
                                                    points: "15 19 12 22 9 19",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("line", {
                                                  attrs: {
                                                    x1: "12",
                                                    y1: "2",
                                                    x2: "12",
                                                    y2: "22",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.actions.orderMenus",
                                                    _vm.restaurant_id
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        )
                                      : [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-success mr-1 btn-circle",
                                              attrs: {
                                                disabled: _vm.loading,
                                                type: "button",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveOrdering()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.form.actions.save"
                                                    )
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-outline-secondary mr-1 btn-circle",
                                              attrs: {
                                                disabled: _vm.loading,
                                                type: "button",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cancelOrdering()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.form.actions.cancel"
                                                    )
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-sm btn-success btn-circle",
                                  class: {
                                    disabled: !_vm.has_right_to_create_menu,
                                  },
                                  attrs: {
                                    to: {
                                      name: "booking.restaurants.settings.menus.add",
                                      params: {
                                        restaurant_id: _vm.restaurant_id,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("feather", { attrs: { type: "plus" } }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.menus.add",
                                          _vm.restaurant_id
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.formErrors
                    ? _c(
                        "div",
                        { staticClass: "alert alert-danger" },
                        _vm._l(_vm.formErrors, function (error) {
                          return _c("p", { key: error }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(error) +
                                "\n                "
                            ),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formSuccess
                    ? _c("div", { staticClass: "alert alert-success" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.formSuccess) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row m-0" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _vm.menus.data && _vm.menus.data.length > 0
                        ? _c(
                            "table",
                            {
                              staticClass:
                                "table table-sm table-striped border-bottom",
                            },
                            [
                              _c("thead", { staticClass: "border-bottom" }, [
                                _c("tr", [
                                  _vm.isOrdering
                                    ? _c("th", [
                                        _vm._v(_vm._s(_vm.$tl("labels.order"))),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$tl("labels.form.name"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$tl("labels.price"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "size-desc-menu" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl(
                                          "labels.booking.menus.description"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tl("labels.form.actions.title")
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "draggable",
                                {
                                  attrs: {
                                    disabled: !_vm.isOrdering,
                                    tag: "tbody",
                                    draggable: ".menus",
                                  },
                                  model: {
                                    value: _vm.menus.data,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.menus, "data", $$v)
                                    },
                                    expression: "menus.data",
                                  },
                                },
                                _vm._l(_vm.menus.data, function (menu) {
                                  return _c(
                                    "tr",
                                    { key: menu.id, staticClass: "menus" },
                                    [
                                      _vm.isOrdering
                                        ? _c("td", [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "css-i6dzq1 feather",
                                                staticStyle: {
                                                  cursor: "grab",
                                                  color: "rgb(170, 170, 170)",
                                                },
                                                attrs: {
                                                  viewBox: "0 0 24 24",
                                                  width: "24",
                                                  height: "24",
                                                  stroke: "currentColor",
                                                  "stroke-width": "2",
                                                  fill: "none",
                                                  "stroke-linecap": "round",
                                                  "stroke-linejoin": "round",
                                                },
                                              },
                                              [
                                                _c("line", {
                                                  attrs: {
                                                    x1: "3",
                                                    y1: "9",
                                                    x2: "21",
                                                    y2: "9",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("line", {
                                                  attrs: {
                                                    x1: "3",
                                                    y1: "15",
                                                    x2: "21",
                                                    y2: "15",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(_vm._s(menu.name))]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              menu.price === 0
                                                ? _vm.$tl("labels.free")
                                                : _vm.formatCurrency(
                                                    menu.price,
                                                    _vm.getRestaurantCurrency(
                                                      _vm.restaurant_id
                                                    )
                                                  )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(menu.description)),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: {
                                                    delay: {
                                                      show: 400,
                                                      hide: 0,
                                                    },
                                                    content: _vm.$t(
                                                      "labels.form.actions.edit"
                                                    ),
                                                  },
                                                  expression:
                                                    "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $t('labels.form.actions.edit'),\n                                        }",
                                                },
                                              ],
                                              staticClass:
                                                "btn btn-sm btn-success btn-square",
                                              attrs: {
                                                to: {
                                                  name: "booking.restaurants.settings.menus.edit",
                                                  params: {
                                                    restaurant_id:
                                                      menu.restaurant_id,
                                                    menu_id: menu.id,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _c("feather", {
                                                attrs: { type: "edit" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          !menu.has_future_reservations
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: {
                                                        delay: {
                                                          show: 400,
                                                          hide: 0,
                                                        },
                                                        content: _vm.$t(
                                                          "labels.form.actions.delete"
                                                        ),
                                                      },
                                                      expression:
                                                        "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $t('labels.form.actions.delete'),\n                                        }",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-sm btn-danger btn-square",
                                                  attrs: {
                                                    "data-id": menu.id,
                                                    type: "button",
                                                    disabled:
                                                      !_vm.has_right_to_delete_menu,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteMenu(
                                                        menu.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("feather", {
                                                    attrs: { type: "trash-2" },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          menu.has_future_reservations
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: {
                                                        delay: {
                                                          show: 400,
                                                          hide: 0,
                                                        },
                                                        content: _vm.$t(
                                                          "infos.booking.menus.cantDelete"
                                                        ),
                                                      },
                                                      expression:
                                                        "{\n                                            delay: { show: 400, hide: 0 },\n                                            content: $t('infos.booking.menus.cantDelete'),\n                                        }",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-sm btn-secondary btn-square",
                                                  attrs: {
                                                    type: "button",
                                                    disabled: "",
                                                  },
                                                },
                                                [
                                                  _c("feather", {
                                                    attrs: { type: "trash-2" },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("em", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tl(
                                    "labels.booking.menus.empty",
                                    _vm.restaurant_id
                                  )
                                )
                              ),
                            ]),
                          ]),
                    ]),
                  ]),
                ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }