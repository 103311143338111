var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _vm.new_feature
        ? _c("div", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "border-light b-radius-20 p-4" }, [
                  _c("p", { domProps: { innerHTML: _vm._s(_vm.content) } }),
                  _vm._v(" "),
                  _vm.new_feature.add_btn
                    ? _c("div", { staticClass: "text-center" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-sm btn-success btn-circle",
                            attrs: { href: _vm.btnLink, target: "_blank" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.new_feature.btn_title) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }