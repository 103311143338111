var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row p-1" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm.loading
              ? _c("LoaderComponent")
              : _vm.error
              ? _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.error)),
                ])
              : _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _vm.hasTablesSelected
                      ? _c("span", { staticClass: "col-12 text-danger" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$tl(
                                "infos.booking.reservations.add.changeSlotSelectedTables",
                                _vm.restaurant_id
                              )
                            )
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.services && _vm.services.length > 0
                      ? _vm._l(_vm.services, function (service_id, index) {
                          return _c("slotsReservations", {
                            key: service_id,
                            staticClass: "col-12",
                            class: { "mb-3": index != _vm.services.length - 1 },
                            attrs: {
                              reservation: _vm.reservation,
                              service_id: service_id,
                              restaurant_id: _vm.restaurant_id,
                              formErrors: _vm.formErrors,
                              readOnly: _vm.readOnly || _vm.hasTablesSelected,
                              serviceIdToPreselect: _vm.serviceIdToPreselect,
                              slotIdToPreselect: _vm.slotIdToPreselect,
                            },
                            on: {
                              "slot-selected": function ($event) {
                                return _vm.$emit("next-step")
                              },
                            },
                          })
                        })
                      : _c("div", { staticClass: "col-12 text-danger" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$tl(
                                  "labels.booking.services.noOneAvailable",
                                  _vm.restaurant_id
                                )
                              ) +
                              "\n                "
                          ),
                        ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 mt-3" }, [
                      _c("div", { staticClass: "row m-0" }, [
                        _vm.restaurants_count &&
                        _vm.restaurants_count > 1 &&
                        _vm.selected_destination_restaurant === null
                          ? _c(
                              "div",
                              { staticClass: "col-12 p-0 text-right" },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.hasTablesSelected
                                          ? {
                                              content: _vm.$tl(
                                                "infos.booking.reservations.edit.changeRestaurant",
                                                _vm.restaurant_id
                                              ),
                                            }
                                          : undefined,
                                        expression:
                                          "\n                                    hasTablesSelected\n                                        ? {\n                                              content: $tl('infos.booking.reservations.edit.changeRestaurant', restaurant_id),\n                                          }\n                                        : undefined\n                                ",
                                      },
                                    ],
                                    staticClass: "d-inline-block",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-success btn-circle",
                                        attrs: {
                                          disabled: _vm.hasTablesSelected,
                                          type: "button",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.showSelectRestaurantModal = true
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "feather css-i6dzq1",
                                            attrs: {
                                              viewBox: "0 0 24 24",
                                              width: "24",
                                              height: "24",
                                              stroke: "currentColor",
                                              "stroke-width": "2",
                                              fill: "none",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("polyline", {
                                              attrs: {
                                                points: "16 17 21 12 16 7",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("line", {
                                              attrs: {
                                                x1: "21",
                                                y1: "12",
                                                x2: "9",
                                                y2: "12",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.$tl(
                                                "labels.booking.reservations.edit.changeRestaurant"
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm.selected_destination_restaurant
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 d-flex justify-content-between",
                              },
                              [
                                _c(
                                  "h6",
                                  {
                                    staticClass: "pt-1",
                                    staticStyle: { color: "#30a4b7" },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "feather css-i6dzq1",
                                        attrs: {
                                          viewBox: "0 0 24 24",
                                          width: "24",
                                          height: "24",
                                          stroke: "currentColor",
                                          "stroke-width": "2",
                                          fill: "none",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                        },
                                      },
                                      [
                                        _c("polyline", {
                                          attrs: {
                                            points: "15 10 20 15 15 20",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("path", {
                                          attrs: {
                                            d: "M4 4v7a4 4 0 0 0 4 4h12",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.selected_destination_restaurant
                                            .name
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-success btn-circle",
                                      attrs: {
                                        type: "button",
                                        disabled: _vm.hasTablesSelected,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectDestinationRestaurant(
                                            null
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "feather feather-arrow-left",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "24",
                                            height: "24",
                                            viewBox: "0 0 24 24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            "stroke-width": "2",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                          },
                                        },
                                        [
                                          _c("line", {
                                            attrs: {
                                              x1: "19",
                                              y1: "12",
                                              x2: "5",
                                              y2: "12",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("polyline", {
                                            attrs: {
                                              points: "12 19 5 12 12 5",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.$tl("labels.backToRestaurant", {
                                              restaurant:
                                                _vm.baseRestaurant.name,
                                            })
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.restaurants_count &&
                                  _vm.restaurants_count > 2
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-success btn-circle",
                                          attrs: {
                                            type: "button",
                                            disabled: _vm.hasTablesSelected,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showSelectRestaurantModal = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass: "feather css-i6dzq1",
                                              attrs: {
                                                viewBox: "0 0 24 24",
                                                width: "24",
                                                height: "24",
                                                stroke: "currentColor",
                                                "stroke-width": "2",
                                                fill: "none",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d: "M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("polyline", {
                                                attrs: {
                                                  points: "16 17 21 12 16 7",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("line", {
                                                attrs: {
                                                  x1: "21",
                                                  y1: "12",
                                                  x2: "9",
                                                  y2: "12",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.changeRestaurant"
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  2
                ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.showSelectRestaurantModal
        ? _c("select-restaurant-modal", {
            attrs: { exclude_restaurant_id: _vm.restaurant_id },
            on: {
              close: function ($event) {
                _vm.showSelectRestaurantModal = false
              },
              selected: _vm.selectDestinationRestaurant,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }