<template class="overflow-auto">
    <modal @close="closeFromModal">
        <h5 slot="header">{{ $tl("labels.notifications.preview.sms") }}</h5>
        <div slot="body">
            <LoaderComponent v-if="loading" />
            <div v-else>
                <div v-if="error" class="alert alert-danger">
                    {{ error }}
                </div>
                <template v-else>
                    <textarea class="form-control" rows="4" disabled :value="resultSmsContent"></textarea>
                    <span v-if="nbCreditsNeeded > 3" class="text-danger">
                        {{ $tl("errors.common.sms.exceedMaxLength") }}
                    </span>
                    <span
                        v-else
                        :class="{ 'text-success': nbCreditsNeeded < 2, 'text-warning': nbCreditsNeeded === 2, 'text-danger': nbCreditsNeeded > 2 }">
                        {{ $t("labels.smsLength", { smsLength }) }} ({{ $tc("labels.smsCost", nbCreditsNeeded) }})
                    </span>
                </template>
            </div>
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button type="button" class="modal-default-button btn btn-secondary btn-circle" @click="close">
                {{ $tl("labels.form.actions.close") }}
            </button>
        </div>
    </modal>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../LoaderComponent.vue";
import ModalUtils from "../../../mixins/helpers/ModalUtils.js";
import SmsUtils from "../../../mixins/helpers/SmsUtils.js";

export default {
    data() {
        return {
            resultSmsContent: "",
            error: null,
            loading: false,
        };
    },
    mixins: [SmsUtils, ModalUtils],
    props: {
        smsType: {
            type: String,
            required: true,
        },
        saas: {
            default: "restaurants",
        },
        customContent: {
            type: String,
        },
        locale: {
            type: String,
        },
    },
    computed: {
        smsLength() {
            return this.getSmsLength(this.resultSmsContent);
        },
        nbCreditsNeeded() {
            return this.getNbCreditsNeeded(this.smsLength);
        },
    },
    methods: {
        fetchData() {
            this.loading = true;

            axios
                .post(`/api/${this.saas}/${this.$route.params.restaurant_id}/configs/sms/${this.smsType}`, {
                    locale: this.locale,
                    content: this.customContent,
                })
                .then((response) => {
                    this.resultSmsContent = response.data.message;
                })
                .catch((error) => {
                    this.error = this.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    created() {
        this.fetchData();
    },
    components: {
        LoaderComponent,
    },
};
</script>
