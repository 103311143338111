<template>
    <span v-if="badge" @click.stop="onBadgeClicked" class="badge badge-danger pointer ml-1 mr-1" v-tooltip="tooltip">{{ $tl("labels.new") }}</span>
</template>

<script>
import LangsEnum from "../../mixins/enums/LangsEnum";

export default {
    data() {
        return {
            isOpen: false,
            read: false,
        };
    },
    mixins: [LangsEnum],
    props: {
        badge_uuid: {
            type: String,
            required: true,
        },
    },
    computed: {
        allBadges() {
            return this.$store.getters["users/newBadges"];
        },
        badge() {
            return this.allBadges.find((badge) => badge.uuid === this.badge_uuid);
        },
        tooltip() {
            if (!this.badge) {
                return undefined;
            }

            const tooltip = this.badge[`tooltip_${this.userLang}`];
            if (!tooltip) {
                return undefined;
            }

            return {
                content: tooltip,
                trigger: "manual",
                show: this.isOpen,
            };
        },
    },
    methods: {
        onBadgeClicked() {
            this.isOpen = !this.isOpen;
            if (!this.read && this.isOpen) this.markAsRead();
        },
        markAsRead() {
            this.viewed = true;
            axios
                .put(`/api/new_badges/${this.badge.id}/read`)
                .then(() => {})
                .catch(() => {});
        },
    },
    beforeDestroy() {
        if (this.viewed)
            this.$store.dispatch(
                "users/setNewBadges",
                this.allBadges.filter((badge) => badge.id != this.badge.id)
            );
    },
};
</script>
