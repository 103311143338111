var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-2" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [
                _vm._v(_vm._s(_vm.$t("labels.admin.videos.title"))),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success btn-circle",
                  on: { click: _vm.addVideo },
                },
                [
                  _c("feather", {
                    staticClass: "mr-2",
                    attrs: { type: "plus" },
                  }),
                  _vm._v(
                    _vm._s(_vm.$t("labels.admin.videos.add")) +
                      "\n                "
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.videosList.length > 0
              ? _c(
                  "table",
                  { staticClass: "table table-sm table-striped border-bottom" },
                  [
                    _c("thead", { staticClass: "border-bottom" }, [
                      _c("tr", [
                        _c("th", [
                          _vm._v(
                            _vm._s(_vm.$t("labels.admin.videos.columns.title"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("labels.admin.videos.columns.description")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            _vm._s(_vm.$t("labels.admin.videos.columns.link"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            _vm._s(_vm.$t("labels.admin.videos.columns.module"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("labels.admin.videos.columns.createdAt")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("labels.admin.videos.columns.updatedAt")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("labels.form.actions.title"))),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.videosList, function (video) {
                        return _c("tr", { key: video.id }, [
                          _c("td", [_vm._v(_vm._s(video.title))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(_vm.getVideoDescription(video))),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: `${_vm.YOUTUBE_EMBED_BASE_URL}/${video.youtube_video_id}`,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("labels.admin.videos.show"))
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.getModuleTypeLabel(video.module))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.displayDate(
                                  video.created_at,
                                  _vm.DATE_SHORT
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.displayDate(
                                  video.updated_at,
                                  _vm.DATE_SHORT
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.getTooltip(
                                      _vm.$t("labels.form.actions.edit")
                                    ),
                                    expression:
                                      "getTooltip($t('labels.form.actions.edit'))",
                                  },
                                ],
                                staticClass:
                                  "btn btn-sm btn-square btn-success",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editVideo(video)
                                  },
                                },
                              },
                              [_c("feather", { attrs: { type: "edit" } })],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.getTooltip(
                                      _vm.$t("labels.form.actions.delete")
                                    ),
                                    expression:
                                      "getTooltip($t('labels.form.actions.delete'))",
                                  },
                                ],
                                staticClass: "btn btn-sm btn-square btn-danger",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteVideo(video)
                                  },
                                },
                              },
                              [_c("feather", { attrs: { type: "trash-2" } })],
                              1
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                )
              : _c("p", { staticClass: "font-italic" }, [
                  _vm._v(_vm._s(_vm.$t("labels.admin.videos.empty"))),
                ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }