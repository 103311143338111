var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(_vm._s(_vm.$tl("labels.booking.openings.title"))),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "body" }, slot: "body" }, [
      _vm.step === 1
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "border-light b-radius-20 p-4 mb-3" },
                [
                  _c("p", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$tl(
                            "infos.booking.reservations.confirmClosureCreate"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-warning" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$tl(
                            "infos.booking.reservations.requireActionIfNoCancel"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.loading > 0
                    ? _c("LoaderComponent")
                    : _c("div", [
                        _vm.error
                          ? _c("div", { staticClass: "alert alert-danger" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.error) +
                                  "\n                        "
                              ),
                            ])
                          : _c("div", [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-book table-striped table-sm",
                                },
                                [
                                  _c(
                                    "thead",
                                    { staticClass: "border-bottom" },
                                    [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          [
                                            _c("feather", {
                                              staticClass:
                                                "none-desk feather-20",
                                              attrs: { type: "user" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "none-mobile" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.reservations.grid.columns.client"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          [
                                            _c("feather", {
                                              staticClass:
                                                "none-desk feather-20",
                                              attrs: { type: "users" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "none-mobile" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.reservations.grid.columns.pax"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          [
                                            _c("feather", {
                                              staticClass:
                                                "none-desk feather-20",
                                              attrs: { type: "clock" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "none-mobile" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tl("labels.form.date")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          [
                                            _c("feather", {
                                              staticClass:
                                                "none-desk feather-20",
                                              attrs: { type: "clock" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "none-mobile" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.booking.reservations.grid.columns.hour"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          [
                                            _c("feather", {
                                              staticClass:
                                                "none-desk feather-20",
                                              attrs: { type: "monitor" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "none-mobile" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.form.phoneNumber"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.reservations, function (resa) {
                                      return _c("tr", { key: resa.id }, [
                                        _c(
                                          "td",
                                          [
                                            resa.client !== null
                                              ? _c("inline-client", {
                                                  attrs: {
                                                    client: resa.client,
                                                    "module-enum":
                                                      _vm.MODULE_TYPE_BOOKING,
                                                    "public-comment":
                                                      resa.reservation_comment,
                                                    "private-comment":
                                                      resa.restaurant_comment,
                                                    link: {
                                                      name: "booking.clients.form",
                                                      params: {
                                                        client_id:
                                                          resa.client.id,
                                                      },
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "icons-start",
                                                        fn: function () {
                                                          return [
                                                            resa.gift
                                                              ? _c("feather", {
                                                                  directives: [
                                                                    {
                                                                      name: "tooltip",
                                                                      rawName:
                                                                        "v-tooltip",
                                                                      value:
                                                                        _vm.getTooltip(
                                                                          resa.gift
                                                                        ),
                                                                      expression:
                                                                        "getTooltip(resa.gift)",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "feather-blue",
                                                                  attrs: {
                                                                    type: "tag",
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "table-text-center" },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  resa.nb_pers * 1 +
                                                    resa.nb_children * 1
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                _vm.displayDate(
                                                  resa.reservation_datetime,
                                                  _vm.DATE_SHORT
                                                )
                                              ) +
                                              "\n                                        "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(resa.slot_hour_start) +
                                              "\n                                        "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          resa.client !== null
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: `tel:${resa.client.tel}`,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(resa.client.tel)
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ]),
                      ]),
                ],
                1
              ),
            ]),
          ])
        : _vm.step === 2
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "border-light b-radius-20 p-4 mb-3" }, [
                _c(
                  "form",
                  {
                    staticClass: "container-fluid",
                    attrs: { action: "javascript:void()" },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", { attrs: { for: "txt-closure-object" } }, [
                          _vm._v(_vm._s(_vm.$tl("labels.form.object")) + " *"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-7 mb-1" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cancelData.message_object,
                              expression: "cancelData.message_object",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            required: "",
                            id: "txt-closure-object",
                          },
                          domProps: { value: _vm.cancelData.message_object },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.cancelData,
                                "message_object",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5" }, [
                        _c("label", { attrs: { for: "txt-closure-body" } }, [
                          _vm._v(
                            _vm._s(_vm.$tl("labels.form.messageContent")) + " *"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-7" },
                        [
                          _c("editor-with-merge-tags", {
                            attrs: { "merge-tags": _vm.mergeTags },
                            model: {
                              value: _vm.cancelData.message_body,
                              callback: function ($$v) {
                                _vm.$set(_vm.cancelData, "message_body", $$v)
                              },
                              expression: "cancelData.message_body",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn-sm btn btn-secondary btn-circle",
            on: { click: _vm.close },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.cancel")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.step === 2
          ? _c(
              "button",
              {
                staticClass:
                  "modal-default-button btn-sm btn btn-secondary btn-circle",
                on: {
                  click: function ($event) {
                    _vm.step = 1
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.back")) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.step === 1
          ? _c(
              "button",
              {
                staticClass: "btn btn-success btn-sm btn-circle",
                on: {
                  click: function ($event) {
                    return _vm.$emit("confirmed")
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.confirmNoCancel")) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-success btn-sm btn-circle",
            attrs: {
              disabled: _vm.cancelData.cancel_enabled && _vm.formLoading,
            },
            on: { click: _vm.continueAndCancel },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$tl(
                    `labels.form.actions.${
                      _vm.step === 1 ? "cancelAllBookings" : "confirm"
                    }`
                  )
                ) +
                "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }