var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vue-tel-input", {
        class: { "form-control": true, "is-invalid": _vm.telIsValid === false },
        staticStyle: { "border-color": "#f0f0f0 !important" },
        attrs: {
          name: _vm.inputName,
          defaultCountry: _vm.defaultCountryComputed,
          enabledCountryCode: _vm.enabledCountryCode,
          inputClasses: "m-0 border-0",
          disabled: _vm.disabled,
          mode: _vm.inputMode,
          "data-test-id": _vm.dataTestId_,
          placeholder: _vm.placeholder,
          required: _vm.required,
        },
        on: { validate: _vm.setPhoneAndCountry },
        model: {
          value: _vm.phone.raw,
          callback: function ($$v) {
            _vm.$set(_vm.phone, "raw", $$v)
          },
          expression: "phone.raw",
        },
      }),
      _vm._v(" "),
      _vm.inputName !== undefined && _vm.inputName !== null
        ? _c("ShowErrors", {
            staticClass: "d-block",
            attrs: { errors: _vm.formErrors, errorKey: _vm.inputName },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.inputName !== undefined && _vm.inputName !== null
        ? _c("ShowErrors", {
            staticClass: "d-block",
            attrs: {
              errors: _vm.formErrors,
              errorKey: `${_vm.inputName}Country`,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }