<template>
    <div class="row m-0">
        <div class="col-lg-6 offset-lg-3 pt-5 text-center">
            <div class="border-light b-radius-20 p-4 mb-3">
                <h5 v-if="!isForbidden">{{ label }}</h5>
                <h5 v-else>{{ $tl("errors.common.rights.notEnough") }}</h5>
                <img class="mt-4 none-mobile" width="340" src="/images/no-resa.png" alt="No Show" />
                <img class="mt-3 none-desk" width="250" src="/images/no-resa.png" alt="No Show" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isForbidden: {
            type: Boolean,
            default: false,
        },
        isFromClosure: {
            type: Boolean,
            default: false,
        },
        closureName: {
            type: String,
            default: "",
        },
    },
    computed: {
        labelFromClosure() {
            return this.closureName !== ""
                ? this.$tl("labels.booking.closures.forTodayWithName", undefined, { closureName: this.closureName })
                : this.$tl("labels.booking.closures.forToday");
        },
        label() {
            return this.isFromClosure ? this.labelFromClosure : this.$tl("labels.booking.restaurant.closed");
        },
    },
};
</script>
