var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-5 pt-5" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row m-0 pb-5" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.loading
            ? _c("LoaderComponent")
            : _c(
                "div",
                [
                  _vm.error
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.error) +
                            "\n                "
                        ),
                      ])
                    : _vm.has_right_to_read
                    ? _c("div", [
                        _c("div", { staticClass: "row mb-3" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              { staticClass: "border-light b-radius-20 p-4" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showShippingConfig =
                                          !_vm.showShippingConfig
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c("strong", [
                                        _vm._v("Modes d'expédition"),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.showShippingConfig
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "row mt-3 mb-2" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-5 pt-1" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "Activer l'option envoi par courrier postal"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-md-7 pt-1" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-inline-block align-middle lead-switch",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.config
                                                            .postal_mail,
                                                        expression:
                                                          "config.postal_mail",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "switch align-self-center is-rounded",
                                                    attrs: {
                                                      type: "checkbox",
                                                      disabled:
                                                        !_vm.has_right_to_update,
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.config.postal_mail
                                                      )
                                                        ? _vm._i(
                                                            _vm.config
                                                              .postal_mail,
                                                            null
                                                          ) > -1
                                                        : _vm.config
                                                            .postal_mail,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.config
                                                              .postal_mail,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm.config,
                                                                "postal_mail",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm.config,
                                                                "postal_mail",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm.config,
                                                            "postal_mail",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("label", {
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.config.postal_mail =
                                                          _vm.has_right_to_update
                                                            ? !_vm.config
                                                                .postal_mail
                                                            : _vm.config
                                                                .postal_mail
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("ShowErrors", {
                                                attrs: {
                                                  errors: _vm.errors,
                                                  errorKey: "postal_mail",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.config.postal_mail
                                        ? [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-md-5 pt-1",
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v("Zone d'expédition"),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-7" },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-success btn-circle",
                                                      attrs: {
                                                        disabled:
                                                          _vm.nb_areas_remaining <
                                                            1 ||
                                                          !_vm.has_right_to_update,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addDispatchArea()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "feather feather-plus sc-dnqmqq jxshSx",
                                                          attrs: {
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                            width: "24",
                                                            height: "24",
                                                            viewBox:
                                                              "0 0 24 24",
                                                            fill: "none",
                                                            stroke:
                                                              "currentColor",
                                                            "stroke-width": "2",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                            "aria-hidden":
                                                              "true",
                                                          },
                                                        },
                                                        [
                                                          _c("line", {
                                                            attrs: {
                                                              x1: "12",
                                                              y1: "5",
                                                              x2: "12",
                                                              y2: "19",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("line", {
                                                            attrs: {
                                                              x1: "5",
                                                              y1: "12",
                                                              x2: "19",
                                                              y2: "12",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "Ajouter une zone d'expédition"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _vm.config.dispatch_areas.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row mt-3 mb-3",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "border-light b-radius-20 p-4",
                                                          },
                                                          [
                                                            _c(
                                                              "table",
                                                              {
                                                                staticClass:
                                                                  "capitalize table table-sm table-striped border-bottom",
                                                              },
                                                              [
                                                                _c(
                                                                  "thead",
                                                                  {
                                                                    staticClass:
                                                                      "border-bottom",
                                                                  },
                                                                  [
                                                                    _c("tr", [
                                                                      _c("th", [
                                                                        _vm._v(
                                                                          "Zone"
                                                                        ),
                                                                      ]),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("th", [
                                                                        _vm._v(
                                                                          "Frais d'expédition"
                                                                        ),
                                                                      ]),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("th", [
                                                                        _vm._v(
                                                                          "Délai d'envoi"
                                                                        ),
                                                                      ]),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("th", [
                                                                        _vm._v(
                                                                          "Actions"
                                                                        ),
                                                                      ]),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "tbody",
                                                                  _vm._l(
                                                                    _vm.config
                                                                      .dispatch_areas,
                                                                    function (
                                                                      area
                                                                    ) {
                                                                      return _c(
                                                                        "tr",
                                                                        {
                                                                          key: area.id,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.getAreaLabel(
                                                                                    area.area
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  area.fees
                                                                                ) +
                                                                                  " €"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  area.shipping_time
                                                                                ) +
                                                                                  " jours"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "button",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "tooltip",
                                                                                        rawName:
                                                                                          "v-tooltip",
                                                                                        value:
                                                                                          _vm.getTooltip(
                                                                                            "Éditer"
                                                                                          ),
                                                                                        expression:
                                                                                          "getTooltip('Éditer')",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "btn btn-sm btn-success btn-square",
                                                                                  attrs:
                                                                                    {
                                                                                      title:
                                                                                        "Modifier la réservation",
                                                                                      disabled:
                                                                                        !_vm.has_right_to_update,
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.editDispatchArea(
                                                                                          area
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "svg",
                                                                                    {
                                                                                      staticClass:
                                                                                        "feather feather-edit sc-dnqmqq jxshSx",
                                                                                      attrs:
                                                                                        {
                                                                                          xmlns:
                                                                                            "http://www.w3.org/2000/svg",
                                                                                          width:
                                                                                            "24",
                                                                                          height:
                                                                                            "24",
                                                                                          viewBox:
                                                                                            "0 0 24 24",
                                                                                          fill: "none",
                                                                                          stroke:
                                                                                            "currentColor",
                                                                                          "stroke-width":
                                                                                            "2",
                                                                                          "stroke-linecap":
                                                                                            "round",
                                                                                          "stroke-linejoin":
                                                                                            "round",
                                                                                          "aria-hidden":
                                                                                            "true",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "path",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              d: "M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "path",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              d: "M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "button",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "tooltip",
                                                                                        rawName:
                                                                                          "v-tooltip",
                                                                                        value:
                                                                                          _vm.getTooltip(
                                                                                            "Supprimer"
                                                                                          ),
                                                                                        expression:
                                                                                          "getTooltip('Supprimer')",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "btn btn-sm btn-danger btn-square",
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        !_vm.has_right_to_update,
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.deleteDispatchArea(
                                                                                          area.id
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "svg",
                                                                                    {
                                                                                      staticClass:
                                                                                        "feather feather-trash-2 sc-dnqmqq jxshSx",
                                                                                      attrs:
                                                                                        {
                                                                                          xmlns:
                                                                                            "http://www.w3.org/2000/svg",
                                                                                          width:
                                                                                            "24",
                                                                                          height:
                                                                                            "24",
                                                                                          viewBox:
                                                                                            "0 0 24 24",
                                                                                          fill: "none",
                                                                                          stroke:
                                                                                            "currentColor",
                                                                                          "stroke-width":
                                                                                            "2",
                                                                                          "stroke-linecap":
                                                                                            "round",
                                                                                          "stroke-linejoin":
                                                                                            "round",
                                                                                          "aria-hidden":
                                                                                            "true",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "polyline",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              points:
                                                                                                "3 6 5 6 21 6",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "path",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              d: "M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "line",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              x1: "10",
                                                                                              y1: "11",
                                                                                              x2: "10",
                                                                                              y2: "17",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "line",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              x1: "14",
                                                                                              y1: "11",
                                                                                              x2: "14",
                                                                                              y2: "17",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row text-center mt-2",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _vm._v(
                                                          "Vous n'avez ajouté aucune zone d'expédition"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mb-3" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              { staticClass: "border-light b-radius-20 p-4" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showPaymentConfig =
                                          !_vm.showPaymentConfig
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c("strong", [_vm._v("Paiements")]),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.showPaymentConfig
                                  ? [
                                      _c("div", { staticClass: "row mt-3" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-5 pt-1" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                "Autoriser les paiements de groupe"
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-7 pt-1" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-inline-block align-middle lead-switch",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.config
                                                          .group_payment,
                                                      expression:
                                                        "config.group_payment",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "switch align-self-center is-rounded",
                                                  attrs: {
                                                    type: "checkbox",
                                                    disabled:
                                                      !_vm.has_right_to_update,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.config.group_payment
                                                    )
                                                      ? _vm._i(
                                                          _vm.config
                                                            .group_payment,
                                                          null
                                                        ) > -1
                                                      : _vm.config
                                                          .group_payment,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.config
                                                            .group_payment,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.config,
                                                              "group_payment",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.config,
                                                              "group_payment",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.config,
                                                          "group_payment",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", {
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.config.group_payment =
                                                        _vm.has_right_to_update
                                                          ? !_vm.config
                                                              .group_payment
                                                          : _vm.config
                                                              .group_payment
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("ShowErrors", {
                                              attrs: {
                                                errors: _vm.errors,
                                                errorKey: "group_payment",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mb-3" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              { staticClass: "border-light b-radius-20 p-4" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showPaymentConfig =
                                          !_vm.showPaymentConfig
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c("strong", [_vm._v("Widget")]),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.showPaymentConfig
                                  ? [
                                      _c("div", { staticClass: "row mt-3" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-5 pt-1" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                "Afficher les offres et bons cadeaux par catégorie"
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-7 pt-1" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-inline-block align-middle lead-switch",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.config
                                                          .display_categories,
                                                      expression:
                                                        "config.display_categories",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "switch align-self-center is-rounded",
                                                  attrs: {
                                                    type: "checkbox",
                                                    disabled:
                                                      !_vm.has_right_to_update,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.config
                                                        .display_categories
                                                    )
                                                      ? _vm._i(
                                                          _vm.config
                                                            .display_categories,
                                                          null
                                                        ) > -1
                                                      : _vm.config
                                                          .display_categories,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.config
                                                            .display_categories,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.config,
                                                              "display_categories",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.config,
                                                              "display_categories",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.config,
                                                          "display_categories",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", {
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.config.display_categories =
                                                        _vm.has_right_to_update
                                                          ? !_vm.config
                                                              .display_categories
                                                          : _vm.config
                                                              .display_categories
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("ShowErrors", {
                                              attrs: {
                                                errors: _vm.errors,
                                                errorKey: "display_categories",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success btn-circle",
                                attrs: {
                                  disabled: !_vm.has_right_to_update,
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.save()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                Enregistrer\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ])
                    : _c("div", { staticClass: "alert alert-danger" }, [
                        _vm._v(
                          "Désolé, vous n'avez pas le droit d'accéder à cette page."
                        ),
                      ]),
                  _vm._v(" "),
                  _vm.showAddShippingAreaModal
                    ? _c("add-shipping-area-modal", {
                        attrs: { area: _vm.area },
                        on: {
                          saved: function ($event) {
                            return _vm.dispatchAreaSaved()
                          },
                          close: function ($event) {
                            _vm.showAddShippingAreaModal = false
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row m-0" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
          },
          [_c("h5", { staticClass: "title mt-2" }, [_vm._v("Configuration")])]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }