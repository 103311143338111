var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _vm.loading
        ? _c("LoaderComponent")
        : _c("div", [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("\n            " + _vm._s(_vm.error) + "\n        "),
                ])
              : _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c(
                        "div",
                        { staticClass: "border-light b-radius-20 p-4" },
                        [
                          _c("img", {
                            staticClass: "img-connect-serv",
                            attrs: { src: "/images/logo-payplug.png" },
                          }),
                          _vm._v(" "),
                          !_vm.is_connected
                            ? _c(
                                "div",
                                { staticClass: "mt-4" },
                                [
                                  !_vm.is_connected
                                    ? [
                                        _c("p", { staticClass: "value" }, [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.form.psp.payplug.register1"
                                                )
                                              ) +
                                              "\n                                    "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: `https://portal.payplug.com/signup?sponsor=${_vm.sponsorId}`,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.$tl(
                                                      "labels.form.psp.payplug.register2"
                                                    )
                                                  ) +
                                                  ".\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-warning font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$tl(
                                                    "labels.form.psp.payplug.minimumOffer"
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$t(
                                            "labels.form.psp.payplug.help1",
                                            { appName: _vm.appName }
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.form.psp.payplug.help2"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "https://portal.payplug.com/#/configuration/api",
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.form.psp.payplug.help3"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.form.psp.payplug.help4"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                ],
                                2
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "mb-3 mt-4",
                                  staticStyle: {
                                    border: "2px solid #ffc107",
                                    padding: "20px",
                                  },
                                },
                                [
                                  _c("h5", [
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.$tl("labels.useful"))),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "m-0" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.form.psp.payplug.notifications.help1"
                                          )
                                        )
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.form.psp.payplug.notifications.help2"
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "https://portal.payplug.com/#/configuration/notifications",
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$tl(
                                              "labels.form.psp.payplug.notifications.help3"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v("."),
                                    _c("br"),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.form.psp.payplug.notifications.help4"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "m-0" },
                                    _vm._l(
                                      _vm.$tl(
                                        "labels.form.psp.payplug.notifications.notifs"
                                      ),
                                      function (notif, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: index,
                                            staticStyle: {
                                              "list-style": "inherit",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(notif) +
                                                "\n                                "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-3 id-stripe" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-6 pt-1" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.form.psp.payplug.publicApiKey"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.restaurant.payplug_public_key,
                                        expression:
                                          "restaurant.payplug_public_key",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      disabled:
                                        _vm.is_connected ||
                                        !_vm.can_edit_payplug_,
                                    },
                                    attrs: {
                                      type: "text",
                                      disabled:
                                        _vm.is_connected ||
                                        !_vm.can_edit_payplug_,
                                    },
                                    domProps: {
                                      value: _vm.restaurant.payplug_public_key,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.restaurant,
                                          "payplug_public_key",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    attrs: {
                                      errors: _vm.formErrors,
                                      errorKey: "payplug_public_key",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-2" }, [
                              _c("div", { staticClass: "col-md-6 pt-1" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$tl(
                                        "labels.form.psp.payplug.privateApiKey"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.restaurant.payplug_secret_key,
                                        expression:
                                          "restaurant.payplug_secret_key",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      disabled:
                                        _vm.is_connected ||
                                        !_vm.can_edit_payplug_,
                                    },
                                    attrs: {
                                      type: "text",
                                      disabled:
                                        _vm.is_connected ||
                                        !_vm.can_edit_payplug_,
                                    },
                                    domProps: {
                                      value: _vm.restaurant.payplug_secret_key,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.restaurant,
                                          "payplug_secret_key",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("ShowErrors", {
                                    attrs: {
                                      errors: _vm.formErrors,
                                      errorKey: "payplug_secret_key",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "notice-text" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.form.psp.payplug.description1"
                                  )
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$tl(
                                    "labels.form.psp.payplug.description2"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.can_edit_payplug_ && !_vm.is_connected
                            ? _c("div", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-success btn-sm btn-circle mt-3",
                                    attrs: { type: "button" },
                                    on: { click: _vm.connectPayPlugAccount },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.form.psp.payplug.linkAccount"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm.can_edit_payplug_ && _vm.is_connected
                            ? _c("div", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-warning btn-circle text-white mt-3",
                                    attrs: { type: "button" },
                                    on: { click: _vm.disconnectPayPlugAccount },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$tl(
                                            "labels.form.psp.payplug.disconnectAccount"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]),
                ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }