<template>
    <div class="row mb-3">
        <div class="col-12">
            <loader-component v-if="nbLoading > 0" />
            <div v-else-if="stats.main" class="border-light b-radius-20 p-4">
                <h6 class="row ml-0 mt-3 col-12">
                    <strong>{{ $tl("labels.booking.stats.other.title") }}</strong>
                </h6>
                <div class="row m-0 click-collect-report mb-4">
                    <div class="col-md-6 p-1">
                        <div class="border-light p-3 b-radius-20">
                            <span>{{ $tl("labels.booking.stats.other.percentNoShow") }}</span>
                            <div class="pt-2">
                                <strong class="value">
                                    {{ $tl("labels.booking.stats.periodOne") }} {{ formatPercent(stats.main.noshow_percent) }}%
                                </strong>
                            </div>
                            <template v-if="stats.comparison && filters.hasComparison">
                                <div class="pt-2">
                                    <strong class="text-success">
                                        {{ $tl("labels.booking.stats.periodTwo") }} {{ formatPercent(stats.comparison.noshow_percent) }}%
                                    </strong>
                                    <ArrowPercentEvolutionComponent
                                        v-if="!(formatPercent(stats.main.noshow_percent) == 0 && formatPercent(stats.comparison.noshow_percent) == 0)"
                                        :difference="stats.main.noshow_percent - stats.comparison.noshow_percent"
                                        :percent="
                                            getEvolution(formatPercent(stats.main.noshow_percent), formatPercent(stats.comparison.noshow_percent))
                                        "
                                        class="pt-2 font-weight-bold" />
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="col-md-6 p-1">
                        <div class="border-light p-3 b-radius-20">
                            <span>{{ $tl("labels.booking.stats.other.percentNewClients") }}</span>
                            <div class="pt-2">
                                <strong class="value"
                                    >{{ $tl("labels.booking.stats.periodOne") }} {{ formatPercent(stats.main.new_clients) }}%</strong
                                >
                            </div>
                            <template v-if="stats.comparison && filters.hasComparison">
                                <div class="pt-2">
                                    <strong class="text-success">
                                        {{ $tl("labels.booking.stats.periodTwo") }} {{ formatPercent(stats.comparison.new_clients) }}%
                                    </strong>
                                    <ArrowPercentEvolutionComponent
                                        v-if="!(formatPercent(stats.main.new_clients) == 0 && formatPercent(stats.comparison.new_clients) == 0)"
                                        :difference="stats.main.new_clients - stats.comparison.new_clients"
                                        :percent="getEvolution(formatPercent(stats.main.new_clients), formatPercent(stats.comparison.new_clients))"
                                        class="pt-2 font-weight-bold" />
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="col-md-6 p-1">
                        <div class="border-light p-3 b-radius-20">
                            <span>{{ $tl("labels.booking.stats.other.confirmationMailLeadToConfirmedResa") }}</span>
                            <div class="pt-2">
                                <strong class="value">
                                    {{ $tl("labels.booking.stats.periodOne") }}
                                    {{ stats.main.count_confirmed }} / {{ stats.main.count_needed_confirmation }}
                                    <template v-if="stats.main.count_needed_confirmation != 0">
                                        <span class="ml-2">({{ formatPercent(stats.main.percent) }}%)</span>
                                    </template>
                                </strong>
                            </div>
                            <template v-if="stats.comparison && filters.hasComparison">
                                <div class="pt-2">
                                    <strong class="text-success">
                                        {{ $tl("labels.booking.stats.periodTwo") }}
                                        {{ stats.comparison.count_confirmed }} / {{ stats.comparison.count_needed_confirmation }}
                                        <template v-if="stats.comparison.count_needed_confirmation != 0">
                                            <span class="ml-2">({{ formatPercent(stats.comparison.percent) }}%)</span>
                                        </template>
                                        <ArrowPercentEvolutionComponent
                                            v-if="!(formatPercent(stats.main.percent) == 0 && formatPercent(stats.comparison.percent) == 0)"
                                            :percent="getEvolution(formatPercent(stats.main.percent), formatPercent(stats.comparison.percent))"
                                            class="pt-2 font-weight-bold" />
                                    </strong>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="col-md-6 p-1">
                        <div class="border-light p-3 b-radius-20">
                            <span>{{ $tl("labels.booking.stats.other.fillRate") }}</span>
                            <div class="pt-2">
                                <strong class="value">
                                    {{ $tl("labels.booking.stats.periodOne") }} {{ formatPercent(stats.main.fill_rate) }}%
                                </strong>
                            </div>
                            <template v-if="stats.comparison && filters.hasComparison">
                                <div class="pt-2">
                                    <strong class="text-success">
                                        {{ $tl("labels.booking.stats.periodTwo") }} {{ formatPercent(stats.comparison.fill_rate) }}%
                                    </strong>
                                    <ArrowPercentEvolutionComponent
                                        v-if="!(formatPercent(stats.main.fill_rate) == 0 && formatPercent(stats.comparison.fill_rate) == 0)"
                                        :difference="stats.main.fill_rate - stats.comparison.fill_rate"
                                        :percent="getEvolution(formatPercent(stats.main.fill_rate), formatPercent(stats.comparison.fill_rate))"
                                        class="pt-2 font-weight-bold" />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArrowPercentEvolutionComponent from "./ArrowPercentEvolutionComponent.vue";
import LoaderComponent from "../LoaderComponent.vue";
import StatsUtils from "../../mixins/helpers/booking/StatsUtils.js";

export default {
    props: {
        filters: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            stats: {
                main: null,
                comparison: null,
            },
        };
    },
    mixins: [StatsUtils],
    methods: {
        fetchStats(abortControllerSignal = null, forComparison = false) {
            const filters = this.getFiltersQueryParams(forComparison);

            return this.httpGet(`/api/stats/others-stats?${filters}`, {
                config: { signal: abortControllerSignal },
                handleReject: false,
            }).then((response) => {
                if (response !== false) {
                    this.stats[forComparison ? "comparison" : "main"] = response.data;
                }
            });
        },
    },
    components: {
        LoaderComponent,
        ArrowPercentEvolutionComponent,
    },
};
</script>
