var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm._v(_vm._s(_vm.$tl("labels.form.psp.pspCard"))),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col radio" },
        [
          _vm._l(_vm.ALL_CARD_PAYMENT_SYSTEMS, function (cardPaymentSystem) {
            return [
              _c(
                "label",
                { key: cardPaymentSystem.value, staticClass: "container-box" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.prefered_payment_system_,
                        expression: "prefered_payment_system_",
                      },
                    ],
                    attrs: { type: "radio", disabled: _vm.isLoading },
                    domProps: {
                      value: cardPaymentSystem.value,
                      checked: _vm._q(
                        _vm.prefered_payment_system_,
                        cardPaymentSystem.value
                      ),
                    },
                    on: {
                      change: function ($event) {
                        _vm.prefered_payment_system_ = cardPaymentSystem.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                  _vm._v(
                    " " + _vm._s(cardPaymentSystem.label) + "\n                "
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isStandAlone
        ? _c("div", { staticClass: "col-sm-2" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success btn-circle btn-sm",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.saveSystemPayment.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$tl("labels.form.actions.save")) +
                    "\n            "
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "keep-alive",
            [
              _c(_vm.cardPaymentSystemLinkComponent, {
                tag: "component",
                attrs: { restaurant_id: _vm.restaurantId_ },
                on: {
                  done: function ($event) {
                    return _vm.$emit("done")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }