var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5 pt-5" },
    [
      _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [
                _vm._v("Fermetures exceptionnelles"),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success mr-1 btn-circle",
                  attrs: { disabled: !_vm.has_right_to_update_config },
                  on: {
                    click: function ($event) {
                      _vm.showAddClickAndCollectClosureModal = null
                    },
                  },
                },
                [
                  _c("feather", { attrs: { type: "plus" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Ajouter une fermeture")]),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("LoaderComponent")
        : [
            _c("div", { staticClass: "row m-0 mb-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm.closures.length > 0
                  ? _c(
                      "table",
                      {
                        staticClass:
                          "capitalize table table-sm table-striped border-bottom",
                      },
                      [
                        _c("thead", { staticClass: "border-bottom" }, [
                          _c("tr", [
                            _c("th", [_vm._v("Nom")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Date(s)")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Service(s)")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Actions")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.closures, function (closure) {
                            return _c("tr", { key: closure.id }, [
                              _c("td", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(closure.name) +
                                    "\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "Du " +
                                    _vm._s(_vm.cleanDate(closure.start_day)) +
                                    " au " +
                                    _vm._s(_vm.cleanDate(closure.end_day))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    closure.collect_types
                                      .map((c) => _vm.getCollectTypeLabel(c))
                                      .join(" - ")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          delay: { show: 400, hide: 0 },
                                          content: "Éditer",
                                        },
                                        expression:
                                          "{ delay: { show: 400, hide: 0 }, content: 'Éditer' }",
                                      },
                                    ],
                                    staticClass:
                                      "btn btn-sm btn-success btn-square",
                                    attrs: {
                                      disabled: !_vm.has_right_to_update_config,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.showAddClickAndCollectClosureModal =
                                          closure.id
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "feather feather-edit sc-dnqmqq jxshSx",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "24",
                                          height: "24",
                                          viewBox: "0 0 24 24",
                                          fill: "none",
                                          stroke: "currentColor",
                                          "stroke-width": "2",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "aria-hidden": "true",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("path", {
                                          attrs: {
                                            d: "M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: {
                                          delay: { show: 400, hide: 0 },
                                          content: "Supprimer",
                                        },
                                        expression:
                                          "{ delay: { show: 400, hide: 0 }, content: 'Supprimer' }",
                                      },
                                    ],
                                    staticClass:
                                      "btn btn-sm btn-danger btn-square",
                                    attrs: {
                                      disabled: !_vm.has_right_to_update_config,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteClosure(closure.id)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "feather feather-trash-2 sc-dnqmqq jxshSx",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "24",
                                          height: "24",
                                          viewBox: "0 0 24 24",
                                          fill: "none",
                                          stroke: "currentColor",
                                          "stroke-width": "2",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "aria-hidden": "true",
                                        },
                                      },
                                      [
                                        _c("polyline", {
                                          attrs: { points: "3 6 5 6 21 6" },
                                        }),
                                        _vm._v(" "),
                                        _c("path", {
                                          attrs: {
                                            d: "M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("line", {
                                          attrs: {
                                            x1: "10",
                                            y1: "11",
                                            x2: "10",
                                            y2: "17",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("line", {
                                          attrs: {
                                            x1: "14",
                                            y1: "11",
                                            x2: "14",
                                            y2: "17",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]
                    )
                  : _c("p", { staticClass: "mb-0" }, [
                      _vm._v("Il n'y a aucune fermeture de prévue."),
                    ]),
              ]),
            ]),
          ],
      _vm._v(" "),
      _vm.showAddClickAndCollectClosureModal !== false
        ? _c("add-click-and-collect-closure-modal", {
            attrs: { closure_id: _vm.showAddClickAndCollectClosureModal },
            on: {
              close: function ($event) {
                _vm.showAddClickAndCollectClosureModal = false
              },
              saved: _vm.closureSaved,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }