var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "form-register align-middle",
      attrs: { "data-test-id": "form-register" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.register.apply(null, arguments)
        },
      },
    },
    [
      _c("div", [
        _c("img", {
          staticClass: "mb-4 mt-2",
          attrs: { width: "200", src: "/images/logo-lead.png", alt: "No Show" },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex justify-content-between" },
          [
            _c("h5", { attrs: { "data-test-id": "title-register_page" } }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$tl("labels.form.register.title")) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("ChooseLang", {
              ref: "chooseLang",
              on: {
                "lang-changed": function ($event) {
                  _vm.user.lang = $event
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isNoShow
          ? _c("div", [
              _vm._v(
                "\n            " + _vm._s(_vm.$t("infos.register.welcome1"))
              ),
              _c("br"),
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("infos.register.welcome2")) +
                  "\n        "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("loader-component")
        : _c("div", { staticClass: "mt-4" }, [
            _vm.errorMessage
              ? _c("div", { staticClass: "alert-danger alert" }, [
                  _vm._v(
                    "\n            " + _vm._s(_vm.errorMessage) + "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-group mb-1 row" }, [
              _c(
                "label",
                {
                  staticClass: "col-md-6 col-form-label",
                  attrs: { for: "firstname" },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.form.firstname")) +
                      " "
                  ),
                  _c("small", [_vm._v("*")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.firstname,
                        expression: "user.firstname",
                      },
                    ],
                    staticClass: "mt-2 form-control",
                    class: { "is-invalid": _vm.hasErrors("firstname") },
                    attrs: {
                      id: "firstname",
                      type: "text",
                      "data-test-id": "input-txt-firstname",
                      name: "firstname",
                      required: "",
                      autofocus: "",
                    },
                    domProps: { value: _vm.user.firstname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.user, "firstname", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasErrors("firstname")
                    ? _c("div", {
                        staticClass: "invalid-feedback",
                        attrs: { role: "alert" },
                        domProps: {
                          innerHTML: _vm._s(_vm.joinErrors("firstname")),
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "col-md-6 col-form-label",
                  attrs: { for: "lastname" },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.form.lastname")) +
                      " "
                  ),
                  _c("small", [_vm._v("*")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.lastname,
                        expression: "user.lastname",
                      },
                    ],
                    staticClass: "mt-2 form-control",
                    class: { "is-invalid": _vm.hasErrors("lastname") },
                    attrs: {
                      id: "lastname",
                      type: "text",
                      "data-test-id": "input-txt-lastname",
                      name: "lastname",
                      required: "",
                    },
                    domProps: { value: _vm.user.lastname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.user, "lastname", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasErrors("lastname")
                    ? _c("div", {
                        staticClass: "invalid-feedback",
                        attrs: { role: "alert" },
                        domProps: {
                          innerHTML: _vm._s(_vm.joinErrors("lastname")),
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group mb-1 row" }, [
              _c(
                "label",
                {
                  staticClass: "col-md-6 col-form-label",
                  attrs: { for: "email" },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.form.emailBusiness")) +
                      " "
                  ),
                  _c("small", [_vm._v("*")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.email,
                        expression: "user.email",
                      },
                    ],
                    staticClass: "mt-2 form-control",
                    class: { "is-invalid": _vm.hasErrors("email") },
                    attrs: {
                      id: "email",
                      type: "email",
                      "data-test-id": "input-txt-email",
                      name: "email",
                      required: "",
                    },
                    domProps: { value: _vm.user.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.user, "email", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasErrors("email")
                    ? _c("div", {
                        staticClass: "invalid-feedback",
                        attrs: {
                          "data-test-id": "txt-error-email",
                          role: "alert",
                        },
                        domProps: {
                          innerHTML: _vm._s(_vm.joinErrors("email")),
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "col-md-6 col-form-label",
                  attrs: { for: "tel" },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.form.portalPhoneNumber")) +
                      " "
                  ),
                  _c("small", [_vm._v("*")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.tel,
                        expression: "user.tel",
                      },
                    ],
                    staticClass: "mt-2 form-control",
                    class: { "is-invalid": _vm.hasErrors("tel") },
                    attrs: {
                      id: "tel",
                      type: "text",
                      "data-test-id": "input-txt-tel",
                      name: "tel",
                      required: "",
                    },
                    domProps: { value: _vm.user.tel },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.user, "tel", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasErrors("tel")
                    ? _c("div", {
                        staticClass: "invalid-feedback",
                        attrs: { role: "alert" },
                        domProps: { innerHTML: _vm._s(_vm.joinErrors("tel")) },
                      })
                    : _vm._e(),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group mb-1 row" }, [
              _c(
                "label",
                {
                  staticClass: "col-md-6 col-form-label",
                  attrs: { for: "password" },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.form.password")) +
                      " "
                  ),
                  _c("small", [_vm._v("*")]),
                  _vm._v(" "),
                  _c("PasswordInputComponent", {
                    staticClass: "mt-2",
                    attrs: { dataTestId: "input-txt-password" },
                    model: {
                      value: _vm.user.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "password", $$v)
                      },
                      expression: "user.password",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasErrors("password")
                    ? _c("div", {
                        staticClass: "invalid-feedback",
                        attrs: { role: "alert" },
                        domProps: {
                          innerHTML: _vm._s(_vm.joinErrors("password")),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "col-md-6 col-form-label",
                  attrs: { for: "password-confirm" },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tl("labels.form.passwordConfirm")) +
                      " "
                  ),
                  _c("small", [_vm._v("*")]),
                  _vm._v(" "),
                  _c("PasswordConfirmationInputComponent", {
                    staticClass: "mt-2",
                    attrs: {
                      dataTestId: "input-txt-password_confirm",
                      mainPassword: _vm.user.password,
                    },
                    model: {
                      value: _vm.user.password_confirmation,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "password_confirmation", $$v)
                      },
                      expression: "user.password_confirmation",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasErrors("password-confirm")
                    ? _c("div", {
                        staticClass: "invalid-feedback",
                        attrs: { role: "alert" },
                        domProps: {
                          innerHTML: _vm._s(_vm.joinErrors("password-confirm")),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group pl-3 mb-2 mt-3 row" }, [
              _c(
                "label",
                {
                  staticClass: "col-md-12 container-box",
                  class: { "is-invalid": _vm.hasErrors("accept_cgv") },
                  staticStyle: { width: "100%" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.accept_cgv,
                        expression: "user.accept_cgv",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "accept_cgv",
                      "data-test-id": "input-chk-accept_cgv",
                      name: "accept_cgv",
                      required: "",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.user.accept_cgv)
                        ? _vm._i(_vm.user.accept_cgv, null) > -1
                        : _vm.user.accept_cgv,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.user.accept_cgv,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.user,
                                "accept_cgv",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.user,
                                "accept_cgv",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.user, "accept_cgv", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("labels.form.register.CGU1")) +
                      "\n                "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "alert-link",
                      attrs: {
                        href: `/cgu_global.pdf?v=${_vm.$__BUILD_ID__}`,
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("labels.form.register.CGU2")))]
                  ),
                  _vm._v(" "),
                  _c("small", [_vm._v("*")]),
                  _vm._v(" "),
                  _vm.hasErrors("accept_cgv")
                    ? _c("div", {
                        staticClass: "invalid-feedback",
                        attrs: { role: "alert" },
                        domProps: {
                          innerHTML: _vm._s(_vm.joinErrors("accept_cgv")),
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group pl-3 mb-2 mt-3 row" }, [
              _c(
                "label",
                {
                  staticClass: "col-md-12 container-box",
                  class: {
                    "is-invalid": _vm.hasErrors("accept_data_processing"),
                  },
                  staticStyle: { width: "100%" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.accept_data_processing,
                        expression: "user.accept_data_processing",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "accept_data_processing",
                      "data-test-id": "input-chk-accept_data_processing",
                      name: "accept_data_processing",
                      required: "",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.user.accept_data_processing)
                        ? _vm._i(_vm.user.accept_data_processing, null) > -1
                        : _vm.user.accept_data_processing,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.user.accept_data_processing,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.user,
                                "accept_data_processing",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.user,
                                "accept_data_processing",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.user, "accept_data_processing", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                  _vm._v(
                    _vm._s(_vm.$t("labels.form.register.PCDP1")) +
                      "\n                "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "alert-link",
                      attrs: {
                        href: `/datapolicy.pdf?v=${_vm.$__BUILD_ID__}`,
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$tl("labels.rgpd.pcdp")))]
                  ),
                  _vm._v(".\n                "),
                  _c("small", [_vm._v("*")]),
                  _vm._v(" "),
                  _vm.hasErrors("accept_data_processing")
                    ? _c("div", {
                        staticClass: "invalid-feedback",
                        attrs: { role: "alert" },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.joinErrors("accept_data_processing")
                          ),
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success btn-circle",
                      attrs: { "data-test-id": "btn-register", type: "submit" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$tl("labels.form.register.button")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        "data-test-id": "lnk-login_page",
                        to: { name: "public.auth.login" },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$tl("labels.form.register.goToLoginPage")
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }