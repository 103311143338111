<template>
    <modal @close="closeFromModal">
        <h5 slot="header">{{ deliveryArea.id === null ? "Ajouter" : "Éditer" }} une zone de livraison</h5>
        <div slot="body">
            <add-click-and-collect-delivery
                ref="addClickAndCollectDelivery"
                :do_not_save="do_not_save"
                @saved="$emit('saved', $event)"
                :deliveryArea="deliveryArea" />
        </div>
        <div slot="footer" class="d-flex">
            <button
                type="button"
                class="modal-default-button btn btn-sm btn-secondary btn-circle"
                @click="$emit('close')">
                Annuler
            </button>
            <button
                type="button"
                class="btn btn-sm btn-success btn-circle ml-2"
                @click="$refs.addClickAndCollectDelivery.save()">
                {{ do_not_save ? "Valider" : "Enregistrer" }}
            </button>
        </div>
    </modal>
</template>

<script>
import addClickAndCollectDelivery from "../../../clickAndCollect/settings/addClickAndCollectDelivery";

export default {
    props: {
        deliveryArea: {
            type: Object,
            required: true,
        },
        do_not_save: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    components: {
        addClickAndCollectDelivery,
    },
};
</script>
