<template>
    <div class="border-light b-radius-20 p-3 m-1 mb-3">
        <div class="row pointer" @click="showListRestaurants = !showListRestaurants">
            <div class="col-6 col-md-9 d-flex align-items-center">
                <strong v-if="!isEditingName">{{ zone.name }}</strong>
                <input
                    v-else-if="showListRestaurants"
                    class="form-control col-9"
                    type="text"
                    name="name"
                    id="name"
                    required
                    v-model="zone.name"
                    @click.stop />
            </div>

            <div class="col-6 col-md-3 d-flex justify-content-end align-items-center">
                <template v-if="showListRestaurants">
                    <button
                        v-if="!isEditingName"
                        type="button"
                        class="btn btn-sm btn-secondary btn-square"
                        v-tooltip="getTooltip($tl('labels.areasEstablishments.editAreaName'))"
                        @click.stop="isEditingName = true">
                        <feather type="edit" />
                    </button>
                    <div v-else>
                        <button
                            type="button"
                            class="btn btn-sm btn-danger btn-square"
                            v-tooltip="getTooltip($tl('labels.areasEstablishments.cancelModification'))"
                            @click="this.isEditingName = false">
                            <feather type="x" />
                        </button>

                        <button
                            type="button"
                            class="btn btn-sm btn-success btn-square"
                            v-tooltip="getTooltip($tl('labels.areasEstablishments.validateModification'))"
                            @click.stop="saveZone">
                            <feather type="check" />
                        </button>
                    </div>
                </template>
                <p v-else class="mb-0 text-muted">
                    {{ $tcl("labels.areasEstablishments.nbEstablishments", selectedRestaurants.length) }}
                </p>
            </div>
        </div>
        <div v-if="showListRestaurants" class="row mt-4">
            <restaurant-list
                :restaurants="restaurants"
                :selectedRestaurants="selectedRestaurants"
                @update:selected-restaurants="updateSelectedRestaurants" />
            <div class="col-12 d-flex justify-content-end mt-4">
                <button type="button" class="btn btn-sm btn-danger btn-circle" @click="deleteZone">
                    {{ $tl("labels.areasEstablishments.deleteArea") }}
                </button>
                <button class="btn btn-sm btn-primary btn-circle ml-2" @click="updateZone">
                    {{ $tl("labels.areasEstablishments.save") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import RestaurantList from "./RestaurantList.vue";

export default {
    name: "ZoneTabForm",
    props: {
        restaurants: {
            type: Array,
            required: true,
        },
        associatedRestaurants: {
            type: Array,
            default: () => [],
        },
        zone: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            selectedRestaurants: [...this.associatedRestaurants],
            showListRestaurants: false,
            isEditingName: false,
        };
    },
    methods: {
        deleteZone() {
            this.$emit("delete-zone", this.zone.id);
        },
        saveZone() {
            this.isEditingName = false;
            this.updateZone();
        },
        updateZone() {
            this.$emit("edit-zone", {
                id: this.zone.id,
                name: this.zone.name,
                restaurants: this.selectedRestaurants,
            });
        },
        updateSelectedRestaurants(restaurants) {
            this.selectedRestaurants = restaurants;
        },
    },
    watch: {
        showListRestaurants: {
            handler(newVal) {
                if (!newVal) {
                    this.isEditingName = false;
                }
            },
            deep: true,
        },
    },
    components: {
        RestaurantList,
    },
};
</script>
