var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "navbar navbar-dark fixed-top flex-md-nowrap p-0 bg-dark" },
    [
      _c(
        "a",
        {
          staticClass:
            "navbar-brand col-sm-12 mr-0 col-lg-2 col-md-3 navbar-brand-not-collaped",
          staticStyle: { "background-color": "#083e60" },
          attrs: { href: "/" },
        },
        [
          !_vm.isDevelopement()
            ? _c("img", {
                staticClass: "img-logo display-sid-min",
                attrs: { src: "/images/logos-saas/reservation.png" },
              })
            : _c("img", {
                staticClass: "img-logo display-sid-min",
                attrs: { src: "/images/logos-saas/reservation.png" },
              }),
        ]
      ),
      _vm._v(" "),
      [
        _c(
          "div",
          {
            staticClass:
              "input-group restaurant-title restaurant-title-kitchen ml-3 p-2",
          },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restaurant_id,
                    expression: "restaurant_id",
                  },
                ],
                staticClass: "custom-select rounded-pill pl-3",
                attrs: { id: "inputGroupSelect01" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.restaurant_id = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.restaurants, function (restaurant) {
                return _c(
                  "option",
                  { key: restaurant.id, domProps: { value: restaurant.id } },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(restaurant.name) +
                        "\n                "
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        ),
      ],
      _vm._v(" "),
      _c("div", { staticClass: "nav-btn mx-md-5" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm p-0 dropdown-toggle",
            class: { "text-white": _vm.user && _vm.user.role === "kitchen" },
            attrs: {
              type: "button",
              id: "dropdownCompte",
              "data-toggle": "dropdown",
              "aria-haspopup": "true",
              "aria-expanded": "false",
            },
          },
          [
            _c("feather", {
              staticClass: "feather-22",
              class: {
                "mr-0": _vm.isSidebarCollapsed,
              },
              attrs: { type: "user" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "dropdown-menu text-right dropdown-menu-right shadow",
            attrs: { "aria-labelledby": "dropdownCompte" },
          },
          [
            _c("h6", { staticClass: "dropdown-header" }, [
              _vm._v(
                _vm._s(_vm.user.firstname) + " " + _vm._s(_vm.user.lastname)
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown-divider" }),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "dropdown-item",
                attrs: { to: { name: "account" } },
              },
              [_vm._v(" " + _vm._s(_vm.$tl("labels.routes.account")) + " ")]
            ),
            _vm._v(" "),
            _vm.has_right_to_update_sms
              ? _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "sms" } },
                  },
                  [_vm._v(" " + _vm._s(_vm.$tl("labels.routes.sms")) + " ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isNoShow && _vm.has_right_to_read_subscription
              ? _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: { to: { name: "subscription" } },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.routes.subscription")) +
                        "\n            "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown-divider" }),
            _vm._v(" "),
            _vm.isNoShow
              ? _c(
                  "a",
                  {
                    staticClass: "open-intercom dropdown-item",
                    attrs: { href: "#" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$tl("labels.routes.help")) + " ")]
                )
              : _vm.isYservices
              ? _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.openHelpscoutBeacon.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$tl("labels.routes.help")) + " ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown-divider" }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                staticStyle: { color: "#f75b61" },
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.logout.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$tl("labels.routes.disconnect")) +
                    "\n                "
                ),
                _c(
                  "svg",
                  {
                    staticClass: "css-i6dzq1 feather",
                    attrs: {
                      viewBox: "0 0 24 24",
                      width: "24",
                      height: "24",
                      stroke: "currentColor",
                      "stroke-width": "2",
                      fill: "none",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  },
                  [
                    _c("path", {
                      attrs: { d: "M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" },
                    }),
                    _vm._v(" "),
                    _c("polyline", { attrs: { points: "16 17 21 12 16 7" } }),
                    _vm._v(" "),
                    _c("line", {
                      attrs: { x1: "21", y1: "12", x2: "9", y2: "12" },
                    }),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }