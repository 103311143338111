import labels from "./labels.json";
import rights from "./rights.json";
import errors from "./errors.json";
import success from "./success.json";
import notices from "./notices.json";
import infos from "./infos.json";
import questions from "./questions.json";
import lexicons from "./lexicons";

export default {
    labels,
    rights,
    errors,
    success,
    notices,
    infos,
    questions,
    lexicons,
};
