<template>
    <div class="p-2">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Liste des clients<span v-if="isArchive"> inactifs</span></h5>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <div class="d-flex justify-content-between align-items-center mb-2">
                <div>
                    <div style="margin-top: 4px">
                        <form @submit.prevent="fetchOwnersList">
                            <div style="border-radius: 20px 0 0 20px !important" class="form-control search-client d-inline pr-1">
                                <input v-model="filter" class="search-input" placeholder="Établissement, nom..." type="text" />
                                <button @click="resetFilter" type="button" class="p-0 m-0 clear-search-button" style="background: none; border: none">
                                    <feather type="x" />
                                </button>
                            </div>
                            <button type="submit" class="btn btn-sm btn-outline-secondary search-client-btn" style="border-radius: 0 20px 20px 0">
                                <feather type="search" />
                            </button>
                        </form>
                    </div>
                </div>

                <div>
                    <router-link v-if="isYservices" class="btn btn-sm btn-circle btn-success" :to="{ name: 'admin.owners.create' }">
                        <feather type="plus" />
                        Créer un nouveau client
                    </router-link>
                    <a class="btn btn-sm btn-circle btn-success" href="/api/admin/exports/owners" target="_blank">Exporter les clients</a>
                    <button
                        v-if="!isArchive"
                        type="button"
                        class="btn btn-sm btn-circle btn-success"
                        @click="showImportFiducialClientsIdsModal = true">
                        Synchroniser les id IWS
                    </button>
                </div>
            </div>

            <switch-input-component v-model="includeInternalAccounts" :is-form-grid="false">
                <template #label>Inclure les comptes internes</template>
            </switch-input-component>

            <ns-table tableClass="table table-sm table-striped border-bottom" allow-sorting :data-source="ownersList">
                <template #head>
                    <ns-th field="created_at" allow-sorting :sort-comparer="dateTimeCompareFn"> Date d'inscription </ns-th>
                    <ns-th field="fullname" allow-sorting :sort-comparer="fullNameCompareFn"> Prénom / Nom </ns-th>
                    <ns-th field="fiducial_client_id" allow-sorting> IWS </ns-th>
                    <ns-th field="email" allow-sorting> Email </ns-th>
                    <ns-th field="tel" allow-sorting> Téléphone </ns-th>
                    <ns-th field="restaurants.data[0].id" allow-sorting> ID </ns-th>
                    <ns-th field="restaurants.data[0].name" allow-sorting> Établissement(s) </ns-th>
                    <ns-th field="restaurants.data[0].zipcode" allow-sorting> Code postal </ns-th>
                    <ns-th field="restaurants.data[0].city" allow-sorting> Ville </ns-th>
                    <ns-th> Actions </ns-th>
                </template>
                <template #body="{ data }">
                    <td>
                        {{ displayDate(data.created_at, DATE_SHORT) }}
                    </td>
                    <td style="text-transform: capitalize">
                        {{ data.firstname }} {{ data.lastname }}
                        <feather
                            v-if="data.enabled_by_admin"
                            type="alert-triangle"
                            class="ml-1 text-danger"
                            v-tooltip="getTooltip('Client activé manuellement par un administrateur')" />
                        <feather
                            v-else-if="data.deleted_at !== null"
                            type="archive"
                            class="ml-1 text-danger"
                            v-tooltip="getTooltip('Client perdu (les données associées ont été supprimées)')" />
                        <feather
                            v-else-if="!data.has_active_subscription"
                            type="alert-triangle"
                            class="ml-1 text-warning"
                            v-tooltip="getTooltip('Client inactif')" />
                    </td>
                    <td>
                        {{ data.fiducial_client_id }}
                    </td>
                    <td>
                        {{ data.email }}
                    </td>
                    <td>
                        {{ data.tel }}
                    </td>
                    <td v-html="joinWithBr(arrayPluck('id', data.restaurants.data))" class="text-right"></td>
                    <td v-html="joinWithBr(arrayPluck('name', data.restaurants.data))"></td>
                    <td v-html="joinWithBr(arrayPluck('postal_code', data.restaurants.data.map(getRestaurantAddress)))"></td>
                    <td v-html="joinWithBr(arrayPluck('locality', data.restaurants.data.map(getRestaurantAddress)))"></td>
                    <td style="width: 76px">
                        <button
                            @click="selectOwner(data)"
                            :disabled="data.deleted_at !== null"
                            type="button"
                            class="btn btn-sm btn-outline-secondary btn-square"
                            v-tooltip="data.deleted_at !== null ? undefined : getTooltip(`Se connecter en tant que '${data.email}'`)">
                            <feather type="log-in" />
                        </button>
                        <router-link
                            :to="{ name: 'admin.ownerDetails', params: { owner_id: data.id } }"
                            class="btn btn-sm btn-outline-secondary btn-square"
                            :class="{ disabled: data.deleted_at !== null }"
                            v-tooltip="getTooltip(`Voir les details du compte '${data.email}'`)">
                            <feather type="eye" />
                        </router-link>
                    </td>
                </template>
            </ns-table>
        </div>

        <import-fiducial-clients-ids
            v-if="showImportFiducialClientsIdsModal"
            @close="showImportFiducialClientsIdsModal = false"></import-fiducial-clients-ids>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent.vue";
import SwitchInputComponent from "../../components/forms/SwitchInputComponent.vue";
import NsTable from "../../components/Datatable/NsTable.vue";
import NsTh from "../../components/Datatable/NsTh.vue";
import ImportFiducialClientsIds from "../../components/Modals/Admin/Clients/ImportFiducialClientsIds.vue";

export default {
    name: "OwnersList",
    data() {
        return {
            loading: false,
            ownersList: [],
            includeInternalAccounts: true,
            showImportFiducialClientsIdsModal: false,
        };
    },
    computed: {
        filter: {
            get() {
                return this.$route.query.filter || "";
            },
            set(filter) {
                if (filter !== this.filter) {
                    this.$router.push({ name: this.$route.name, query: { filter } });
                }
            },
        },
        isArchive() {
            return this.$route.name === "admin.archive";
        },
    },
    methods: {
        getRestaurantAddress(restaurant) {
            return (
                restaurant.google_maps_address ?? {
                    postal_code: restaurant.zipcode,
                    locality: restaurant.city,
                }
            );
        },
        fullNameCompareFn(a, b) {
            const lastnameResult = (a.lastname || "").localeCompare(b.lastname || "");

            if (lastnameResult !== 0) {
                return lastnameResult;
            }

            return (a.firstname || "").localeCompare(b.firstname || "");
        },
        dateTimeCompareFn(a, b) {
            return this.getDateTime(b.created_at).diff(this.getDateTime(a.created_at), "minutes").minutes;
        },
        resetFilter() {
            this.filter = "";
            this.fetchOwnersList();
        },
        fetchOwnersList() {
            this.loading = true;
            this.ownersList = [];

            this.httpGet(
                `/api/admin/owners?include=restaurants.google_maps_address,has_active_subscription&filter=${this.filter}&is_archive=${this.isArchive}&include_internal_accounts=${this.includeInternalAccounts}`
            )
                .then((response) => {
                    if (response !== false) {
                        this.ownersList = response.data.data;
                    }
                })
                .finally(() => (this.loading = false));
        },
        logAs(owner) {
            this.loading = true;

            this.httpPost(`/api/admin/log_as/${owner.id}`, {}, { handleResolve: false }).then((response) => {
                if (response !== false) {
                    document.location.href = "/";
                } else {
                    this.loading = false;
                }
            });
        },
        selectOwner(owner) {
            if (owner.deleted_at !== null) {
                return;
            }

            if (!confirm(`Souhaitez vous vous connecter avec l'idendifiant "${owner.email}" ?`)) {
                return;
            }

            this.logAs(owner);
        },
        joinWithBr(arr) {
            return arr.map((value) => (value !== null ? this.escapeHtml(value.toString()) : "")).join("<br/>");
        },
    },
    watch: {
        includeInternalAccounts() {
            this.fetchOwnersList();
        },
    },
    components: {
        LoaderComponent,
        SwitchInputComponent,
        NsTable,
        NsTh,
        ImportFiducialClientsIds,
    },
    created() {
        this.fetchOwnersList();
    },
};
</script>
