<template>
    <div class="pb-5 pt-5">
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">Expédition</h5>
                </div>
            </div>
        </div>
        <LoaderComponent v-if="loading" />
        <div v-show="!loading" class="row m-0">
            <div class="alert alert-danger" v-if="error">{{ error }}</div>
            <div class="col-12">
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="border-light b-radius-20 p-4">
                            <div class="row">
                                <div class="col-md-5">
                                    <strong>Expédition</strong>
                                </div>
                                <div class="col-md-7 pt-1">
                                    <div class="d-inline-block align-middle lead-switch">
                                        <input
                                            :disabled="!has_right_to_update_config"
                                            type="checkbox"
                                            class="switch align-self-center is-rounded"
                                            v-model="dispatchEnabled" />
                                        <label @click="dispatchEnabled = has_right_to_update_config ? !dispatchEnabled : dispatchEnabled"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template v-if="dispatchEnabled">
                    <editClickAndCollectSendcloudSettings
                        :sendcloud_public_key="sendcloud_public_key"
                        @sendcloud-public-key="sendcloud_public_key = $event"
                        :sendcloud_private_key="sendcloud_private_key"
                        @sendcloud-private-key="sendcloud_private_key = $event"
                        :base_weight="base_weight"
                        @base-weight="base_weight = $event"
                        :dispatch_min_amount="dispatch_min_amount"
                        @dispatch-min-amount="dispatch_min_amount = $event"
                        :dispatch_free_above="dispatch_free_above"
                        @dispatch-free-above="dispatch_free_above = $event"
                        :enable_service_points="enable_service_points"
                        @enable-service-points="enable_service_points = $event" />
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <div class="row">
                                    <div class="col-12">
                                        <strong>Paramètres généraux</strong>
                                    </div>
                                </div>
                                <div class="row mt-3 mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Afficher les produits en rupture</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="show_product_out" />
                                            <label
                                                @click="show_product_out = has_right_to_update_config ? !show_product_out : show_product_out"></label>
                                        </div>
                                    </div>
                                    <show-errors :errors="errors" errorKey="delivery_show_out_of_stock_products" />
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5 pt-1">
                                        <label>Afficher les produits inactifs</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="show_product_disable" />
                                            <label
                                                @click="
                                                    show_product_disable = has_right_to_update_config ? !show_product_disable : show_product_disable
                                                "></label>
                                        </div>
                                    </div>
                                    <show-errors :errors="errors" errorKey="delivery_show_inactive_products" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <div class="row">
                                    <div class="col-12">
                                        <strong>Paiements</strong>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-5 pt-1">
                                        <label>Autoriser les paiements de groupe</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="group_payments" />
                                            <label @click="group_payments = has_right_to_update_config ? !group_payments : group_payments"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="hasMealVouchersEnabled && isNoShow">
                                    <div class="col-md-5 pt-1">
                                        <label>Autoriser les paiements en titres-restaurant</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="dispatch_mealvouchers_enabled" />
                                            <label
                                                @click="
                                                    dispatch_mealvouchers_enabled = has_right_to_update_config
                                                        ? !dispatch_mealvouchers_enabled
                                                        : collect_mealvouchers_enabled
                                                "></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5 pt-1">
                                        <label>Liaison au module Bons Cadeaux</label>
                                    </div>
                                    <div class="col-md-7 pt-1">
                                        <div class="d-inline-block align-middle lead-switch">
                                            <input
                                                :disabled="!has_right_to_update_config || !hasGvSubscription"
                                                type="checkbox"
                                                class="switch align-self-center is-rounded"
                                                v-model="dispatch_connect_to_gv" />
                                            <label
                                                @click="
                                                    dispatch_connect_to_gv =
                                                        has_right_to_update_config && hasGvSubscription
                                                            ? !dispatch_connect_to_gv
                                                            : dispatch_connect_to_gv
                                                "></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="border-light b-radius-20 p-4">
                                <div class="row">
                                    <div class="col-12">
                                        <strong>Message personnalisé</strong>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-5 pt-1">
                                        <label>Informations complémentaires à ajouter au mail de confirmation de commande</label>
                                    </div>
                                    <div class="col-md-7">
                                        <textarea
                                            :disabled="!has_right_to_update_config"
                                            class="form-control"
                                            maxlength="190"
                                            rows="3"
                                            v-model="dispatch_legal_information"></textarea>
                                        <small class="text-muted">Max : 190 caractères</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <editClickAndCollectNotifications
                        class="mt-2"
                        ref="editClickAndCollectNotifications"
                        :show-save-button="false"
                        :notifications-data="notificationsData"
                        :collect-type="COLLECT_TYPE_DISPATCH.value" />
                </template>
                <div class="row">
                    <div class="col-12">
                        <input
                            @click="post"
                            type="submit"
                            name="submit"
                            value="Enregistrer"
                            class="btn btn-success btn-circle"
                            :class="{ disabled: loading }"
                            :disabled="loading || !has_right_to_update_config" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../../../components/LoaderComponent.vue";
import VueTimepicker from "vue2-timepicker";
import ShowErrors from "../../../components/errors/ShowErrors.vue";
import editClickAndCollectNotifications from "../../../components/clickAndCollect/settings/editClickAndCollectNotifications.vue";
import editClickAndCollectSendcloudSettings from "../../../components/clickAndCollect/settings/editClickAndCollectSendcloudSettings.vue";
import CollectTypesEnum from "../../../mixins/enums/click_and_collect/CollectTypesEnum.js";

export default {
    data() {
        return {
            loading: false,
            errors: null,
            error: null,
            cc_configuration_id: null,
            dispatch_legal_information: "",
            show_product_out: false,
            show_product_disable: false,
            group_payments: false,
            dispatch_mealvouchers_enabled: false,
            dispatchEnabled: false,
            sendcloud_public_key: null,
            sendcloud_private_key: null,
            base_weight: null,
            dispatch_min_amount: null,
            dispatch_free_above: null,
            enable_service_points: null,
            has_licence: true,
            dispatch_connect_to_gv: false,
            notificationsData: [
                {
                    label: "Confirmation de commande",
                    previewId: "order-confirmed-dispatch",
                    key: "dispatch_order_confirmation",
                },
            ],
        };
    },
    mixins: [CollectTypesEnum],
    computed: {
        restaurant() {
            return this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id);
        },
        hasMealVouchersEnabled() {
            if (!this.restaurant) return false;
            return this.restaurant.edenred_enabled;
        },
        hasGvSubscription() {
            return this.$store.getters["restaurants/hasModule"]("gift-vouchers", this.restaurant_id);
        },
        rights: function () {
            return this.$store.getters["users/formattedRights"];
        },
        has_right_to_update_config() {
            return this.rights.includes("click_and_collect.config.update");
        },
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        fetchData() {
            this.loading = true;
            this.errors = null;
            this.error = null;

            axios
                .get(`/api/click_and_collect/${this.restaurant_id}/settings?type=dispatch`)
                .then((response) => {
                    this.loading = false;

                    this.dispatch_legal_information = response.data.dispatch_legal_information;

                    this.show_product_out = response.data.dispatch_show_out_of_stock_products;
                    this.show_product_disable = response.data.dispatch_show_inactive_products;
                    this.group_payments = response.data.dispatch_group_payment;
                    this.dispatch_mealvouchers_enabled = response.data.dispatch_mealvouchers_enabled;

                    this.dispatchEnabled = response.data.is_dispatch_enabled;
                    this.cc_configuration_id = response.data.id;

                    this.sendcloud_public_key = response.data.sendcloud_public_key;
                    this.sendcloud_private_key = response.data.sendcloud_private_key;
                    this.base_weight = response.data.base_weight;
                    this.dispatch_min_amount = response.data.dispatch_min_amount;
                    this.dispatch_free_above = response.data.dispatch_free_above;
                    this.enable_service_points = response.data.enable_service_points;

                    if (this.hasGvSubscription) {
                        this.dispatch_connect_to_gv = response.data.dispatch_connect_to_gv;
                    }
                })
                .catch((error) => {
                    this.loading = false;

                    if (error.response && error.response.data && error.response.data.not_a_fatal_error === true) {
                        return;
                    }

                    this.error = this.getErrorMsgFromErrorResponse(error);
                });
        },
        post() {
            this.loading = true;
            const callBack = (result) => {
                if (result === true) {
                    if (this.cc_configuration_id) {
                        this.putForm();
                    } else {
                        this.postForm();
                    }
                } else {
                    this.loading = false;

                    this.notifyError(null, result || "Une erreur est survenue");
                }
            };

            if (this.dispatchEnabled) {
                this.$refs.editClickAndCollectNotifications.postForm().then(callBack);
            } else {
                callBack(true);
            }
        },
        postForm() {
            this.errors = null;
            this.loading = true;

            return axios
                .post(`/api/click_and_collect/${this.restaurant_id}/settingsDispatch`, this.getFormattedData())
                .then(() => {
                    this.fetchData();

                    this.notifySuccess(null, "La configuration a été enregistrée avec succès.");
                })
                .catch((error) => {
                    this.loading = false;

                    this.notifyError(error);

                    if (error.response && error.response.data) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
        putForm() {
            this.loading = true;
            this.errors = null;

            return axios
                .put(`/api/click_and_collect/${this.restaurant_id}/settingsDispatch/${this.cc_configuration_id}`, this.getFormattedData())
                .then(() => {
                    this.notifySuccess(null, "La configuration a été enregistrée avec succès.");

                    this.fetchData();
                })
                .catch((error) => {
                    this.notifyError(error);

                    if (error.response && error.response.data) {
                        this.errors = error.response.data.errors;
                    }
                })
                .finally(() => (this.loading = false));
        },
        getFormattedData() {
            let data = {
                restaurant_id: this.restaurant_id,
                dispatch_show_out_of_stock_products: this.show_product_out,
                dispatch_legal_information: this.dispatch_legal_information,
                dispatch_show_inactive_products: this.show_product_disable,
                dispatch_group_payment: this.group_payments,
                dispatch_mealvouchers_enabled: this.dispatch_mealvouchers_enabled,
                is_dispatch_enabled: this.dispatchEnabled,
                sendcloud_public_key: this.sendcloud_public_key,
                sendcloud_private_key: this.sendcloud_private_key,
                base_weight: this.base_weight,
                dispatch_min_amount: this.dispatch_min_amount,
                dispatch_free_above: this.dispatch_free_above,
                enable_service_points: this.enable_service_points,
                dispatch_connect_to_gv: this.dispatch_connect_to_gv,
            };

            if (this.cc_configuration_id) {
                data.cc_configuration_id = this.cc_configuration_id;
            }

            return data;
        },
    },
    components: {
        LoaderComponent,
        VueTimepicker,
        ShowErrors,
        editClickAndCollectNotifications,
        editClickAndCollectSendcloudSettings,
    },
    created() {
        this.fetchData();
    },
};
</script>

<style scoped>
input[type="submit"]:disabled {
    cursor: default;
}
</style>
