var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reservation-vue" },
    [
      _vm.loading ? _c("loader-component") : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c("div", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.error,
                    expression: "error",
                  },
                ],
                staticClass: "error",
              },
              [_vm._v("\n            " + _vm._s(_vm.error) + "\n        ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-0 pt-3 pl-3 pr-3",
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "h2",
                      { staticClass: "h5 d-inline-block" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "btn btn-sm btn-outline-secondary radius-btn-square",
                            staticStyle: {
                              width: "29px",
                              "padding-left": "5px",
                            },
                            attrs: {
                              to: {
                                name: "booking.restaurants.reservationsCuisine",
                                params: { restaurant_id: _vm.restaurantId },
                                query: { date: _vm.reservation_date_prev },
                              },
                            },
                          },
                          [_c("feather", { attrs: { type: "chevron-left" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c("DatePicker", {
                          staticClass: "d-inline-block date-resa-cal ml-1 mr-1",
                          attrs: {
                            "input-class":
                              "date-resa-cal-input text-uppercase text-center",
                            format: "cccc dd LLL",
                          },
                          model: {
                            value: _vm.reservation_date,
                            callback: function ($$v) {
                              _vm.reservation_date = $$v
                            },
                            expression: "reservation_date",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "btn btn-sm btn-outline-secondary radius-btn-square",
                            staticStyle: {
                              width: "29px",
                              "padding-left": "6px",
                            },
                            attrs: {
                              to: {
                                name: "booking.restaurants.reservationsCuisine",
                                params: { restaurant_id: _vm.restaurantId },
                                query: { date: _vm.reservation_date_next },
                              },
                            },
                          },
                          [_c("feather", { attrs: { type: "chevron-right" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-sm btn-outline-secondary none-mobile btn-circle",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.reservation_date = _vm.getDateTime()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$tl("labels.today")) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-sm btn-outline-secondary none-desk btn-circle",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.reservation_date = _vm.getDateTime()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$tl("labels.todayShort")) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("show-custom-events-btn", {
                      staticClass: "none-mobile ml-2",
                      attrs: {
                        restaurantId: _vm.restaurantId,
                        date: _vm.reservation_date,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c("span", { staticClass: "badge badge-success" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$tl(
                            "labels.booking.menus.title",
                            _vm.restaurantId
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "badge badge-warning" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$tl(
                            "labels.booking.menus.options.title",
                            _vm.restaurantId
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "badge badge-danger" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$tl("labels.booking.generalOptions.title"))
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-circle btn-outline-secondary",
                    class: { disabled: !_vm.has_right_to_export_reservations },
                    staticStyle: { "margin-top": "-8px" },
                    attrs: {
                      type: "button",
                      disabled: !_vm.has_right_to_export_reservations,
                    },
                    on: { click: _vm.exportReservations },
                  },
                  [
                    _c("feather", { attrs: { type: "upload" } }),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tl("labels.form.actions.export")) +
                        "\n            "
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.error,
                    expression: "!error",
                  },
                ],
                staticClass: "h-100 d-flex flex-column",
                staticStyle: { "margin-top": "-10px" },
              },
              [
                _vm.services
                  ? [
                      _vm.services.length > 0
                        ? _c(
                            "div",
                            { staticClass: "h-100" },
                            [
                              _c("reservations-per-service-for-kitchen", {
                                attrs: {
                                  services: _vm.services,
                                  restaurant: _vm.restaurant,
                                  reservation_date: _vm.reservation_date,
                                },
                                on: {
                                  "show-service-full": function ($event) {
                                    return _vm.$parent.$emit(
                                      "show-service-full",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.services.length === 0
                        ? _c("div", { staticClass: "pl-3" }, [
                            _c("div", { staticClass: "row m-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-lg-6 offset-lg-3 pt-5 text-center",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "border-light b-radius-20 p-4 mb-3",
                                    },
                                    [
                                      !_vm.isForbidden
                                        ? _c("h5", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "labels.booking.restaurant.closed"
                                                )
                                              )
                                            ),
                                          ])
                                        : _c("h5", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tl(
                                                  "errors.common.rights.notEnough"
                                                )
                                              )
                                            ),
                                          ]),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticClass: "mt-4 none-mobile",
                                        attrs: {
                                          width: "340",
                                          src: "/images/no-resa.png",
                                          alt: "No Show",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticClass: "mt-3 none-desk",
                                        attrs: {
                                          width: "250",
                                          src: "/images/no-resa.png",
                                          alt: "No Show",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }