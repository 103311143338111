<template>
    <div>
        <LoaderComponent v-if="loading" />
        <div v-else class="row">
            <div class="col-12">
                <div class="border-light b-radius-20 p-4">
                    <div class="row">
                        <div class="col-md-5 pt-1">
                            <label>Nom <small>*</small></label>
                        </div>
                        <div class="col-md-7">
                            <input type="text" class="form-control" v-model="product_option.name" />
                            <ShowErrors :errors="errors" errorKey="name" />
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-md-5">
                            <label>Tarif TTC <small>*</small></label>
                        </div>
                        <div class="col-md-7">
                            <div class="row">
                                <div class="col-12">
                                    <div class="radio">
                                        <label class="container-box"
                                            ><input type="radio" v-model="product_option.include" :value="1" /> <span class="checkmark"></span> Inclus
                                            dans le prix de {{ isYservices ? "la prestation" : "l'offre" }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 d-inline radio mt-1">
                                    <label class="container-box d-inline-block pt-0 mt-2" style="width: initial"
                                        ><input type="radio" v-model="product_option.include" :value="0" /> <span class="checkmark"></span> Coût
                                        additionnel
                                    </label>
                                    <template v-if="!product_option.include">
                                        <input
                                            type="number"
                                            class="form-control d-inline mr-2"
                                            style="width: 100px"
                                            name="lock_reservation_until"
                                            v-model="product_option.price" />
                                        euros
                                        <ShowErrors class="d-block" :errors="errors" errorKey="include" />
                                        <ShowErrors class="d-block" :errors="errors" errorKey="price" />
                                        <div class="row">
                                            <div class="col-12 pt-1">
                                                <label style="margin-left: 31px"
                                                    >Taux de TVA <small>*</small>
                                                    <select class="custom-select mb-0 ml-2 mr-3" style="width: inherit" v-model="product_option.tva">
                                                        <option :value="null">--</option>
                                                        <option :value="5.5">5,5</option>
                                                        <option :value="10">10</option>
                                                        <option :value="20">20</option></select
                                                    >%
                                                    <ShowErrors class="d-block" :errors="errors" errorKey="tva" />
                                                </label>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../../components/LoaderComponent";
import ShowErrors from "../errors/ShowErrors";

export default {
    data() {
        return {
            loading: false,
            errors: null,
        };
    },
    props: {
        product_option: {
            type: Object,
            required: true,
        },
    },
    computed: {
        builtData() {
            let data = {};
            Object.assign(data, this.product_option);
            data.price = parseInt(parseFloat(data.price) * 100); // Price in cent
            return data;
        },
    },
    methods: {
        save() {
            this.loading = true;
            this.errors = null;
            this.error_amount_to_add = null;

            if (this.product_option.id === null) {
                axios
                    .post(`/api/gift_vouchers/${this.$route.params.restaurant_id}/productsOptions`, this.builtData)
                    .then((response) => {
                        this.loading = false;
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: response.data.message,
                        });
                        this.$emit("saved");
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.errors = error.response.data.errors;
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: error.response.data.message ? error.response.data.message : "Une erreur est survenue",
                        });
                    });
            } else {
                axios
                    .put(`/api/gift_vouchers/${this.$route.params.restaurant_id}/productsOptions/${this.product_option.id}`, this.builtData)
                    .then((response) => {
                        this.loading = false;
                        this.$notify({
                            group: "notification",
                            type: "success",
                            title: response.data.message,
                        });
                        this.$emit("saved", response.data.new_option_id);
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.errors = error.response.data.errors;
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: error.response.data.message ? error.response.data.message : "Une erreur est survenue",
                        });
                    });
            }
        },
    },
    components: {
        LoaderComponent,
        ShowErrors,
    },
};
</script>
