<template>
    <div class="my-2">
        <h5>Informations</h5>
        <input-component v-model="form.name" required input-name="name" input-type="text" label="Nom du code promo" :form-errors="errors.form" />
        <input-component
            v-model="form.code"
            input-name="code"
            input-type="text"
            label="Code"
            notice="Le code doit être unique parmi les codes promos actifs et non archivés. Si le champ est laissé vide, le code sera généré automatiquement"
            :form-errors="errors.form" />
        <div class="row my-3">
            <div class="col-md-5 pt-2">
                <label for="description">Description du code promo</label>
            </div>
            <div class="col-md-7 pt-2">
                <text-area-component input-name="description" :rows="3" v-model="form.description" />
            </div>
        </div>
        <div class="row my-3">
            <div class="col-md-5 pt-2">
                <label for="plan">Plan *</label>
            </div>
            <div class="col-md-7 pt-2">
                <select id="plan" name="plan" class="form-control" v-model="form.plan">
                    <option v-for="stripePlan in this.ALL_STRIPE_PLAN_TYPES" :key="stripePlan.value" :value="stripePlan.value">
                        {{ stripePlan.label }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row my-3">
            <div class="col-md-5 pt-2">
                <label for="module">Module *</label>
            </div>
            <div class="col-md-7 pt-2">
                <select id="module" name="module" class="form-control" v-model="form.module">
                    <option v-for="moduleType in this.ALL_MODULES_TYPES" :key="moduleType.value" :value="moduleType.value">
                        {{ moduleType.label }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>
<script>
import StripePlanTypesEnum from "../../../../mixins/enums/StripePlanTypesEnum.js";
import ModuleTypesEnum from "../../../../mixins/enums/ModuleTypesEnum.js";
import InputComponent from "../../../forms/InputComponent.vue";
import TextAreaComponent from "../../../forms/TextareaComponent.vue";

export default {
    props: {
        errors: Object,
        required: true,
    },
    mixins: [StripePlanTypesEnum, ModuleTypesEnum],
    components: {
        InputComponent,
        TextAreaComponent,
    },
    data() {
        return {
            form: {
                name: "",
                description: "",
                plan: null,
                module: null,
                code: null,
            },
        };
    },
    created() {
        this.form.plan = this.STRIPE_PLAN_TYPE_MONTHLY.value;
        this.form.module = this.MODULE_TYPE_BOOKING.value;
    },
    watch: {
        form: {
            handler(newValue) {
                if (this.form.code) {
                    this.form.code = this.form.code.toUpperCase();
                }
                this.$emit("update-form", newValue);
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>
