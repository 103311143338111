export default {
    data() {
        return {
            CAMPAIGN_TYPE_SMS: {
                value: "sms",
                label: "SMS",
            },
            CAMPAIGN_TYPE_EMAIL: {
                value: "email",
                label: "Email",
            },
        };
    },
    methods: {
        getCampaignTypeLabel(value) {
            const found = this.ALL_CAMPAIGN_TYPES.find((w) => w.value == value);
            if (typeof found != "undefined") return found.label;
            return value;
        },
    },
    computed: {
        ALL_CAMPAIGN_TYPES() {
            return [this.CAMPAIGN_TYPE_SMS, this.CAMPAIGN_TYPE_EMAIL];
        },
    },
};
