var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.cursorPosition !== null
    ? _c(
        "div",
        {
          staticClass:
            "position-absolute d-flex flex-column align-items-center justify-content-center",
          style: `left: ${_vm.cursorPosition}px; top: ${_vm.headerHeight}px; height: ${_vm.cursorHeight}px; z-index: 10;`,
        },
        [
          _c(
            "span",
            {
              staticClass: "position-absolute text-danger",
              style: `top: -${_vm.fontSize}px`,
            },
            [_vm._v(_vm._s(_vm.displayDate(null, _vm.TIME_SIMPLE)))]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "border-left border-danger h-100" }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }