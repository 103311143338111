<template>
    <div>
        <div class="row mb-3">
            <div class="col-12">
                <strong>Détail de la campagne</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-4">Nombre de clients</div>
                    <div class="col-8 value">
                        {{ smsCampaign.nb_clients }} (dont {{ smsCampaign.nb_foreign_clients }} client(s) étrangé(s))
                    </div>
                </div>
                <div class="sepa"></div>
                <div class="row">
                    <div class="col-4">Prix HT</div>
                    <div class="col-8 value">{{ formatCurrency((smsCampaign.price_ht * smsCampaign.cost) / 100) }}</div>
                </div>
                <div class="sepa"></div>
                <div class="row">
                    <div class="col-4">Prix TTC</div>
                    <div class="col-8 value">
                        {{
                            formatCurrency(
                                (smsCampaign.price_ht * smsCampaign.cost) / 100 +
                                    (((smsCampaign.price_ht * smsCampaign.cost) / 100) * 20) / 100
                            )
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ShowSmsCampaignToBuy",
    props: {
        smsCampaign: {
            type: Object,
            required: true,
        },
    },
};
</script>
