<template>
    <a :download="fileName" href="#" :class="anchorClasses" :style="anchorStyle" @click="excelExport">
        <excel-download-icon :icon-classes="iconClasses" :iconStyle="iconStyle" />
        {{ $tl("labels.form.actions.excelExport") }}
    </a>
</template>

<script>
import * as XLSX from "xlsx";
import excelDownloadIcon from "../../../icons/excelDownload.vue";

/**
 * settings: {
 *  ?headings: array, // add headings to export
 *  ?autoWidth: boolean, // auto calc cell width, defaut to false
 *  ?fromTable: boolean, // retrieve data from <tbody> element, and headings from <thead> element, default to false
 *  ?formatRow: function, // (format a single row)
 *  ?fileName: string, // default to "export.{format}",
 *  ?format: enum("xlsx", "xls", "csv"), // default to "xlsx"
 *  ?sheetName: string, // default to "Feuille 1"
 *  ?removeColumns: array, // used only if {fromTable} is set to true
 *  ?anchorClasses: any, // apply given classes to <a> tag, default to "ml-2 btn btn-sm btn-outline-secondary"
 *  ?anchorStyle: any, // apply style to <a> tag, default to none
 * }
 */
export default {
    data() {
        return {
            isLoading: false,
        };
    },
    props: {
        settings: {
            type: Object,
            default: () => ({}),
        },
        dataSource: {
            type: Array,
            required: true,
        },
    },
    computed: {
        fileName() {
            return this.settings.fileName || `export.${this.format}`;
        },
        format() {
            return this.settings.format || "xlsx";
        },
        sheetName() {
            return this.settings.sheetName || `${this.$tl("labels.sheet")} 1`;
        },
        removeColumns() {
            return this.settings.removeColumns || [];
        },
        hasHeadings() {
            return Array.isArray(this.settings.headings);
        },
        anchorClasses() {
            return this.settings.anchorClasses || "ml-2 btn btn-sm btn-outline-secondary";
        },
        anchorStyle() {
            return this.settings.anchorStyle;
        },
        iconClasses() {
            return this.settings.iconClasses || "feather";
        },
        iconStyle() {
            return this.settings.iconStyle;
        },
    },
    methods: {
        excelExport(e) {
            let dataSource = [];

            if (this.settings.fromTable) {
                dataSource = this.tableToDataSource(this.$parent.getTable());
            } else {
                dataSource = this.$parent
                    .getDataSource()
                    .map(typeof this.settings.formatRow === "function" ? this.settings.formatRow : Object.values);
            }

            if (this.hasHeadings) {
                dataSource.unshift(this.settings.headings);
            } else if (this.settings.fromTable) {
                const headings = this.tableToHeadings(this.$parent.getTable());

                if (headings !== null) {
                    dataSource.unshift(headings);
                }
            }

            const workbook = {
                SheetNames: [this.sheetName],
                Sheets: {},
            };

            let worksheet = XLSX.utils.aoa_to_sheet(dataSource, {
                sheet: this.sheetName,
            });
            workbook.Sheets[this.sheetName] = worksheet;

            if (this.settings.autoWidth === true) {
                worksheet["!cols"] = dataSource[0].map((a, i) => ({
                    wch: Math.max(...dataSource.map((a2) => (a2[i] ? a2[i].toString().length : 0))),
                }));
            }

            const output = XLSX.write(workbook, {
                bookType: this.format,
                bookSST: true,
                type: "binary",
                compression: true,
            });
            const blob = new Blob([this.string2ArrayBuffer(output)], { type: "application/octet-stream" });

            e.target.href = window.URL.createObjectURL(blob);

            return blob;
        },
        string2ArrayBuffer(s) {
            let buf = new ArrayBuffer(s.length);
            let view = new Uint8Array(buf);
            for (let i = 0; i !== s.length; ++i) {
                view[i] = s.charCodeAt(i) & 0xff;
            }
            return buf;
        },
        tableToDataSource(tableElement) {
            const tbody = tableElement.querySelector("tbody");

            if (tbody === null) {
                throw "Tag <tbody> has not been found on table";
            }

            return [...tbody.children].map((tr) => this.filterRemovedColumns(tr.children));
        },
        filterRemovedColumns(nodeList) {
            const filtered = [];

            nodeList.forEach((element, index) => {
                if (!this.removeColumns.includes(index)) {
                    filtered.push(element.innerText);
                }
            });

            return filtered;
        },
        tableToHeadings(tableElement) {
            const thead = tableElement.querySelector("thead");

            if (thead === null) {
                return null;
            }

            return this.filterRemovedColumns(thead.children);
        },
    },
    components: {
        excelDownloadIcon,
    },
};
</script>
