<template>
    <div>
        <div v-if="hasErrors('client_id')">
            <div class="invalid-feedback d-block" v-for="err in formErrors.errors.client_id" :key="err">
                {{ err }}
            </div>
        </div>
        <div class="row mb-2 radio">
            <div class="col-md-4 d-none d-md-block pt-2">
                <label>{{ $tl("labels.form.civility") }} <small v-if="mandatoryFields.civility">*</small></label>
            </div>
            <div class="col-md-8 pt-2">
                <div>
                    <label class="container-box" v-for="(civility, index) in ALL_CLIENT_CIVILITIES" :key="civility.value">
                        <input
                            type="radio"
                            name="civility"
                            :value="civility.value"
                            :tabindex="5 + index"
                            :readonly="readOnly"
                            :disabled="readOnly"
                            v-model="reservation.client.civility"
                            @click="onCivilityChoosen" />
                        <span class="checkmark"></span>
                        {{ capitalize(getClientCivilityLabelLong(civility.value)) }}
                    </label>
                </div>
                <div v-if="hasErrors('civility')">
                    <div class="invalid-feedback d-block" v-for="err in formErrors.errors.civility" :key="err">
                        {{ err }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4 d-none d-md-block pt-2">
                <label>{{ $tl("labels.form.firstname") }}<small v-if="mandatoryFields.firstname">*</small></label>
            </div>
            <div class="col-md-8">
                <div class="dropright add-resa-search-client">
                    <div>
                        <input
                            type="text"
                            :placeholder="$tl('labels.form.firstname')"
                            :class="{ 'is-invalid': hasErrors('firstname') || hasErrors('client_id') }"
                            name="firstname"
                            ref="firstname"
                            v-model="reservation.client.firstname"
                            :readonly="readOnly"
                            autocomplete="off"
                            class="form-control"
                            tabindex="7"
                            @input="onTextInput"
                            @focus="reservation.client.firstnameHasFocus = true"
                            @blur="reservation.client.firstnameHasFocus = false" />
                    </div>
                    <div
                        @mouseover="reservation.client.search.firstnameHasFocus = true"
                        @mouseout="reservation.client.search.firstnameHasFocus = false"
                        class="dropdown-menu prename-field shadow show p-0"
                        v-if="
                            (reservation.client.firstnameHasFocus || reservation.client.search.firstnameHasFocus) &&
                            reservation.client.search.results &&
                            reservation.client.search.results.length > 0
                        ">
                        <ul class="list-group">
                            <li class="list-group-item p-0" v-for="(result, i) in reservation.client.search.results" :key="i">
                                <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                    <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                    <span>{{ result.tel }}</span>
                                    <span class="email-search"
                                        ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                    >
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="hasErrors('firstname')">
                        <div class="invalid-feedback d-block" v-for="err in formErrors.errors.firstname" :key="err">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4 d-none d-md-block pt-2">
                <label>{{ $tl("labels.form.lastname") }} <small v-if="mandatoryFields.lastname">*</small></label>
            </div>
            <div class="col-md-8">
                <div class="dropright add-resa-search-client">
                    <div>
                        <input
                            type="text"
                            :placeholder="$tl('labels.form.lastname')"
                            :class="{ 'is-invalid': hasErrors('lastname') || hasErrors('client_id') }"
                            name="lastname"
                            ref="lastname"
                            v-model="reservation.client.lastname"
                            :readonly="readOnly"
                            autocomplete="off"
                            class="form-control"
                            tabindex="7"
                            @input="onTextInput"
                            @focus="reservation.client.lastnameHasFocus = true"
                            @blur="reservation.client.lastnameHasFocus = false" />
                    </div>
                    <div
                        @mouseover="reservation.client.search.lastnameHasFocus = true"
                        @mouseout="reservation.client.search.lastnameHasFocus = false"
                        class="dropdown-menu name-field shadow show p-0"
                        v-if="
                            (reservation.client.lastnameHasFocus || reservation.client.search.lastnameHasFocus) &&
                            reservation.client.search.results &&
                            reservation.client.search.results.length > 0
                        ">
                        <ul class="list-group">
                            <li class="list-group-item p-0" v-for="(result, i) in reservation.client.search.results" :key="i">
                                <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                    <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                    <span>{{ result.tel }}</span>
                                    <span class="email-search"
                                        ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                    >
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="hasErrors('lastname')">
                        <div class="invalid-feedback d-block" v-for="err in formErrors.errors.lastname" :key="err">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4 d-none d-md-block pt-2">
                <label>{{ $tl("labels.form.company") }} <small v-if="mandatoryFields.company">*</small></label>
            </div>
            <div class="col-md-8">
                <div class="dropright add-resa-search-client">
                    <div>
                        <input
                            type="text"
                            :placeholder="$tl('labels.form.company')"
                            :class="{ 'is-invalid': hasErrors('company') || hasErrors('client_id') }"
                            name="company"
                            ref="company"
                            v-model="reservation.client.company"
                            :readonly="readOnly"
                            autocomplete="off"
                            class="form-control"
                            tabindex="7"
                            @input="onTextInput"
                            @focus="reservation.client.companyHasFocus = true"
                            @blur="reservation.client.companyHasFocus = false" />
                    </div>
                    <div
                        @mouseover="reservation.client.search.companyHasFocus = true"
                        @mouseout="reservation.client.search.companyHasFocus = false"
                        class="dropdown-menu company-field shadow show p-0"
                        v-if="
                            (reservation.client.companyHasFocus || reservation.client.search.companyHasFocus) &&
                            reservation.client.search.results &&
                            reservation.client.search.results.length > 0
                        ">
                        <ul class="list-group">
                            <li class="list-group-item p-0" v-for="(result, i) in reservation.client.search.results" :key="i">
                                <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                    <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                    <span>{{ result.tel }}</span>
                                    <span class="email-search"
                                        ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                    >
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="hasErrors('company')">
                        <div class="invalid-feedback d-block" v-for="err in formErrors.errors.company" :key="err">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4 d-none d-md-block pt-2">
                <label>{{ $tl("labels.form.email") }} <small v-if="mandatoryFields.email">*</small></label>
            </div>
            <div class="col-md-8">
                <div class="dropright add-resa-search-client">
                    <div>
                        <input
                            type="text"
                            :placeholder="$tl('labels.form.email')"
                            :class="{ 'is-invalid': hasErrors('email') || hasErrors('client_id') }"
                            name="email"
                            ref="email"
                            v-model="reservation.client.email"
                            :readonly="readOnly"
                            autocomplete="off"
                            class="form-control"
                            tabindex="7"
                            @input="onTextInput"
                            @focus="reservation.client.emailHasFocus = true"
                            @blur="reservation.client.emailHasFocus = false" />
                    </div>
                    <div
                        @mouseover="reservation.client.search.emailHasFocus = true"
                        @mouseout="reservation.client.search.emailHasFocus = false"
                        class="dropdown-menu email-field shadow show p-0"
                        v-if="
                            (reservation.client.emailHasFocus || reservation.client.search.emailHasFocus) &&
                            reservation.client.search.results &&
                            reservation.client.search.results.length > 0
                        ">
                        <ul class="list-group">
                            <li class="list-group-item p-0" v-for="(result, i) in reservation.client.search.results" :key="i">
                                <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                    <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                    <span>{{ result.tel }}</span>
                                    <span class="email-search"
                                        ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                    >
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="hasErrors('email')">
                        <div class="invalid-feedback d-block" v-for="err in formErrors.errors.email" :key="err">
                            {{ err }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4 d-none d-md-block pt-2">
                <label>{{ $tl("labels.form.phoneNumber") }} <small v-if="mandatoryFields.tel">*</small></label>
            </div>
            <div class="col-md-8">
                <div class="dropright add-resa-search-client">
                    <div v-if="readOnly">
                        <vue-tel-input
                            name="telInput"
                            :defaultCountry="defaultTelCountry"
                            ref="vueTelInput"
                            :class="
                                hasErrors('phoneNumber') ||
                                hasErrors('phoneCountry') ||
                                hasErrors('client_id') ||
                                (reservation.client.phone.value !== null && reservation.client.phone.isValid === false)
                                    ? ['form-control', 'is-invalid', 'd-flex', 'text-left']
                                    : ['form-control', 'd-flex', 'text-left']
                            "
                            :disabled="true"
                            v-model="reservation.client.phone.raw"
                            :enabledCountryCode="true"
                            :inputOptions="{ tabindex: 10, autocomplete: 'off' }"
                            mode="international"
                            inputClasses="m-0 border-0"
                            style="overflow: hidden"
                            :placeholder="$tl('labels.form.phoneNumber')"></vue-tel-input>
                    </div>
                    <div v-else>
                        <vue-tel-input
                            name="telInput"
                            :defaultCountry="defaultTelCountry"
                            @onValidate="setPhoneAndCountry"
                            @onInput="searchClientByMultiField"
                            ref="vueTelInput"
                            :class="
                                hasErrors('phoneNumber') ||
                                hasErrors('phoneCountry') ||
                                hasErrors('client_id') ||
                                (reservation.client.phone.value !== null && reservation.client.phone.isValid === false)
                                    ? ['form-control', 'is-invalid', 'd-flex', 'text-left']
                                    : ['form-control', 'd-flex', 'text-left']
                            "
                            v-model="reservation.client.phone.raw"
                            :enabledCountryCode="true"
                            :inputOptions="{ tabindex: 10, autocomplete: 'off' }"
                            mode="international"
                            inputClasses="m-0 border-0"
                            :placeholder="$tl('labels.form.phoneNumber')"></vue-tel-input>
                    </div>
                    <div
                        @mouseover="reservation.client.search.phoneNumberHasFocus = true"
                        @mouseout="reservation.client.search.phoneNumberHasFocus = false"
                        class="dropdown-menu phone-field shadow show p-0"
                        v-if="
                            (reservation.client.phoneNumberHasFocus || reservation.client.search.phoneNumberHasFocus) &&
                            reservation.client.search.results &&
                            reservation.client.search.results.length > 0
                        ">
                        <ul class="list-group">
                            <li class="list-group-item p-0" v-for="(result, i) in reservation.client.search.results" :key="i">
                                <a class="d-block p-3" href="javascript:" @click="selectClient(result)">
                                    <span>{{ result.firstname }} {{ result.lastname }}</span> |
                                    <span>{{ result.tel }}</span>
                                    <span class="email-search"
                                        ><span v-if="result.company">{{ result.company }} |</span> {{ result.email }}</span
                                    >
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="hasErrors('phoneNumber') || hasErrors('phoneCountry') || hasErrors('client_id')" class="invalid-feedback d-block">
                        <div v-if="hasErrors('phoneNumber')">
                            <div class="invalid-feedback d-block" v-for="err in formErrors.errors.phoneNumber" :key="err">
                                {{ err }}
                            </div>
                        </div>
                        <div v-else-if="hasErrors('phoneCountry')">
                            <div class="invalid-feedback d-block" v-for="err in formErrors.errors.phoneCountry" :key="err">
                                {{ err }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4 d-none d-md-block pt-2">
                <label>{{ $tl("labels.form.lang") }}</label>
            </div>
            <div class="col-md-8">
                <ChooseLang
                    v-model="reservation.client.locale"
                    class="d-inline-block"
                    style="z-index: auto !important"
                    :disabled="readOnly"
                    is-input-mode
                    should-translate-label />
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4 d-none d-md-block pt-2">
                <label>{{ $tl("labels.clients.typeShort") }} <small v-if="mandatoryFields.type">*</small></label>
            </div>
            <div class="col-md-8">
                <select-client-type :readonly="readOnly" :restaurant-id="restaurantId" v-model="reservation.client.type" small-screen />
                <div v-if="hasErrors('type')">
                    <div class="invalid-feedback d-block" v-for="err in formErrors.errors.type" :key="err">
                        {{ err }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-9">
                <label>{{ $tl("labels.clients.vip") }}</label>
            </div>
            <div class="col-3 text-right lead-switch">
                <input type="checkbox" class="switch align-self-center is-rounded" :disabled="readOnly" v-model="reservation.client.is_vip" />
                <label @click="reservation.client.is_vip = readOnly ? reservation.client.is_vip : !reservation.client.is_vip"></label>
            </div>
        </div>
        <div class="row">
            <div class="col-9">
                <label class="none-mobile">{{ $tl("labels.form.acceptNewsletter") }}</label>
                <label class="none-desk">{{ $tl("labels.form.acceptNewsletterShort") }}</label>
            </div>
            <div class="col-3 text-right lead-switch">
                <input type="checkbox" class="switch align-self-center is-rounded" :disabled="readOnly" v-model="reservation.client.newsletter" />
                <label @click="reservation.client.newsletter = readOnly ? reservation.client.newsletter : !reservation.client.newsletter"></label>
            </div>
        </div>
        <div class="add-resa-search-client" v-if="reservation.client.phone.search.results !== null && reservation.client.id === null">
            <div v-if="reservation.client.phone.search.results.length > 0">
                <span class="text-danger d-inline-block mt-2 mb-2">
                    {{ $tl("labels.clients.alreadyExist") }}<br />{{ $tl("infos.booking.clients.alreadyExist") }}
                </span>
                <li class="list-group-item p-0">
                    <a class="d-block p-3" href="javascript:" @click="selectClient(reservation.client.phone.search.results[0])">
                        <span>
                            {{ reservation.client.phone.search.results[0].firstname }}
                            {{ reservation.client.phone.search.results[0].lastname }}
                        </span>
                        |
                        <span>
                            {{ reservation.client.phone.search.results[0].tel }}
                        </span>
                        <span class="email-search"
                            ><span v-if="reservation.client.phone.search.results[0].company"
                                >{{ reservation.client.phone.search.results[0].company }} |</span
                            >{{ reservation.client.phone.search.results[0].email }}</span
                        >
                    </a>
                </li>
            </div>
            <div v-else>
                <span class="d-inline-block mt-2 mb-2 text-muted">{{ $tl("infos.booking.clients.willBeCreated") }}</span>
            </div>
        </div>
        <div v-if="readOnly && !hideEditButton">
            <button @click="redirectToClient" type="button" class="btn btn-success btn-circle btn-sm mt-3">
                {{ $tl("labels.client.edit") }}
            </button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import ClientCivilityEnum from "../../../mixins/enums/ClientCivilityEnum.js";
import ChooseLang from "../../../components/Default/ChooseLang.vue";
import SelectClientType from "../../forms/clients/selectClientType.vue";

export default {
    name: "clientsReservations",
    mixins: [ClientCivilityEnum],
    props: {
        restaurant_id: {
            default: null,
        },
        reservation: {
            type: Object,
            required: true,
        },
        formErrors: {
            default: null,
        },
        readOnly: {
            default: false,
        },
        hideEditButton: {
            default: false,
        },
    },
    data() {
        return {
            mandatoryFields: {
                civility: false,
                firstname: false,
                lastname: false,
                company: false,
                email: false,
                type: false,
                tel: false,
            },
        };
    },
    updated() {
        if (this.$refs.vueTelInput) {
            this.$refs.vueTelInput.$el.classList.remove("vue-tel-input");
            this.$refs.vueTelInput.$refs.input.addEventListener("focus", this.onVueTelInputFocus);
            this.$refs.vueTelInput.$refs.input.addEventListener("blur", this.onVueTelInputBlur);
        }
    },
    computed: {
        restaurantId() {
            const restaurantId = this.restaurant_id || this.$route.params.restaurant_id;

            return Number.parseInt(restaurantId);
        },
        defaultTelCountry() {
            if (this.restaurant_id !== null) {
                return this.$store.getters["restaurants/findRestaurantById"](this.restaurant_id).default_tel_country;
            }

            return undefined;
        },
    },
    created() {
        this.fetchFields();
    },
    methods: {
        fetchFields() {
            this.isLoading = true;
            this.httpGet(`/api/restaurants/${this.restaurantId}/reservations/input-params/fields?module=booking`, {
                handleReject: false,
            })
                .then((response) => {
                    if (response !== false) {
                        response.data.forEach((field) => {
                            this.mandatoryFields[field.field_name] = field.pivot.is_mandatory;
                        });
                    }
                })
                .finally(() => (this.isLoading = false));
        },
        onCivilityChoosen() {
            if (this.readOnly || !this.$refs.firstname) {
                return;
            }

            this.$refs.firstname.focus();
        },
        onTextInput() {
            if (this.readOnly) {
                return;
            }

            this.searchClientByMultiField();
        },
        redirectToClient() {
            this.$router.push({
                name: "booking.clients.form",
                params: {
                    client_id: this.reservation.client.id,
                },
            });
        },
        searchClientByMultiField() {
            this.reservation.client.search.errors = [];
            this.reservation.client.search.isLoading = true;
            var params = {};

            if (this.reservation.client.firstname && this.reservation.client.firstname !== "") {
                params.firstname = this.reservation.client.firstname;
            }

            if (this.reservation.client.lastname && this.reservation.client.lastname !== "") {
                params.lastname = this.reservation.client.lastname;
            }

            if (this.reservation.client.email && this.reservation.client.email !== "") {
                params.email = this.reservation.client.email;
            }

            if (this.reservation.client.phone.raw && this.reservation.client.phone.raw !== "") {
                params.phone_number = this.reservation.client.phone.raw;
            }

            if (this.reservation.client.company && this.reservation.client.company !== "") {
                params.company = this.reservation.client.company;
            }

            axios
                .get(`/api/restaurants/${this.restaurant_id}/clients/search/multi`, {
                    params,
                })
                .then((response) => {
                    this.reservation.client.search.results = response.data.data;
                    this.reservation.client.search.isLoading = false;

                    if (
                        !this.reservation.client.search.results ||
                        (this.reservation.client.search.results.length === 0 && this.reservation.client.phone.isValid)
                    ) {
                        axios
                            .get(`/api/restaurants/${this.restaurant_id}/clients/search/phone`, {
                                params: {
                                    phoneNumber: this.reservation.client.phone.value.international,
                                    phoneCountry: this.reservation.client.phone.country,
                                },
                            })
                            .then((response) => {
                                this.reservation.client.phone.search.results = response.data.data;
                            })
                            .catch(() => {});
                    }
                })
                .catch((err) => {});
        },
        setPhoneAndCountry(phoneObject) {
            if (phoneObject.number && phoneObject.isValid !== undefined && phoneObject.country) {
                this.reservation.client.phone.isValid = phoneObject.isValid;

                if (this.reservation.client.phone.isValid) {
                    this.reservation.client.phone.value = phoneObject.number;

                    if (phoneObject.regionCode && phoneObject.regionCode !== "") {
                        this.reservation.client.phone.country = phoneObject.regionCode;
                    } else {
                        this.reservation.client.phone.country = phoneObject.country.iso2;
                    }

                    if (!this.reservation.client.search.results || this.reservation.client.search.results.length < 1) {
                        axios
                            .get(`/api/restaurants/${this.restaurant_id}/clients/search/phone`, {
                                params: {
                                    phoneNumber: this.reservation.client.phone.value.international,
                                    phoneCountry: this.reservation.client.phone.country,
                                },
                            })
                            .then((response) => {
                                this.reservation.client.phone.search.results = response.data.data;
                            })
                            .catch((error) => {});
                    }
                } else {
                    this.reservation.client.phone.search.results = null;
                }
            } else {
                this.reservation.client.phone.isValid = false;
                this.reservation.client.phone.search.results = null;
            }
        },
        hasErrors(name) {
            return this.formErrors && this.formErrors.errors && !_.isEmpty(this.formErrors.errors[name]);
        },
        selectClient: function (client) {
            this.$set(
                this.reservation,
                "client",
                Object.assign(this.reservation.client, {
                    id: client.id,
                    civility: client.civility,
                    firstname: client.firstname,
                    lastname: client.lastname,
                    company: client.company,
                    email: client.email,
                    newsletter: client.newsletter,
                    phone: {
                        raw: client.tel,
                        isValid: null,
                        country: client.tel_country,
                        value: null,
                        search: {
                            results: null,
                        },
                    },
                    locale: client.locale,
                    type: client.type,
                    is_vip: client.is_vip,
                })
            );
            this.reservation.client.search.companyHasFocus = false;
            this.reservation.client.search.firstnameHasFocus = false;
            this.reservation.client.search.lastnameHasFocus = false;
            this.reservation.client.search.emailHasFocus = false;
            this.reservation.client.search.phoneNumberHasFocus = false;
        },
    },
    components: {
        ChooseLang,
        SelectClientType,
    },
};
</script>
