var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Scanner un bon cadeau"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.loading
          ? _c("LoaderComponent")
          : _c("div", [
              _vm.error
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _c("p", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.error) +
                          "\n                "
                      ),
                    ]),
                  ])
                : _c(
                    "div",
                    [
                      !_vm.showValidateGiftVoucherModal
                        ? _c("qrcode-stream", {
                            on: { decode: _vm.findGvSale, init: _vm.onInit },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showValidateGiftVoucherModal
                        ? _c("validate-gift-voucher-modal", {
                            attrs: { gv_sale: _vm.gv_sale },
                            on: {
                              close: function ($event) {
                                _vm.showValidateGiftVoucherModal = false
                              },
                              updated: function ($event) {
                                return _vm.$emit("updated")
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showInvalidGiftVoucherModal
                        ? _c("invalid-gift-voucher-modal", {
                            attrs: {
                              gv_sale: _vm.gv_sale,
                              invalid_reason: _vm.invalid_reason,
                            },
                            on: {
                              close: function ($event) {
                                _vm.showInvalidGiftVoucherModal = false
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn-sm btn btn-secondary btn-circle",
            on: { click: _vm.close },
          },
          [_vm._v("Fermer")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }