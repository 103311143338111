<template>
    <div>
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
        <LoaderComponent v-if="loading" />
        <div v-if="smsCampaign.cost > 0">
            <div class="row">
                <div class="col-12">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <show-sms-campaign-to-buy :smsCampaign="smsCampaign"> </show-sms-campaign-to-buy>
                    </div>
                </div>
            </div>
            <Facturation
                :getInvoiceUrl="`/api/campaigns/smsCampaigns/invoice/${this.$route.params.restaurant_id}/${this.smsCampaign.campaign_id}`"
                @close="$emit('close')"
                @paid="paid" />
        </div>
    </div>
</template>

<script>
import ShowSmsCampaignToBuy from "../campaign/ShowSmsCampaignToBuy";
import Facturation from "../payment/Facturation";
import LoaderComponent from "../LoaderComponent";
import axios from "axios";

export default {
    name: "PurchaseSmsCampaign",
    data() {
        return {
            loading: false,
            error: null,
        };
    },
    props: {
        smsCampaign: {
            type: Object,
            required: true,
        },
    },
    methods: {
        paid(e) {
            this.$emit("disable-close-button");
            const thenFct = () => {
                setTimeout(() => {
                    this.$emit("paid", e);
                }, 3000);
            };
            if (!e.hasPaidWithCard)
                axios
                    .post(
                        `/api/campaigns/isPayingSms/${this.$route.params.restaurant_id}/${this.smsCampaign.campaign_id}`,
                        {
                            stripe_invoice_id: e.stripe_invoice_id,
                        }
                    )
                    .then(thenFct)
                    .catch(thenFct);
            else thenFct();
        },
        fetchData() {
            this.loading = true;
            this.error = null;
            this.quantity = null;

            axios
                .get(
                    `/api/campaigns/smsCampaigns/cost/${this.$route.params.restaurant_id}/${this.smsCampaign.campaign_id}`
                )
                .then((response) => {
                    this.loading = false;
                    this.smsCampaign.cost = response.data.cost;
                    this.smsCampaign.nb_clients = response.data.nb_clients;
                    this.smsCampaign.nb_foreign_clients = response.data.nb_foreign_clients;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = error.response.data.message ? error.response.data.message : "Une erreur est survenue";
                });
        },
    },
    components: {
        ShowSmsCampaignToBuy,
        Facturation,
        LoaderComponent,
    },
    created() {
        this.fetchData();
    },
};
</script>
