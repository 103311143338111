var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-2" },
    [
      _c("switch-input-component", {
        attrs: { label: "Code promo actif", "input-name": "is_active" },
        model: {
          value: _vm.form.is_active,
          callback: function ($$v) {
            _vm.$set(_vm.form, "is_active", $$v)
          },
          expression: "form.is_active",
        },
      }),
      _vm._v(" "),
      _c("switch-input-component", {
        attrs: {
          label: "Clients n'ayant jamais souscrit à ce module",
          "input-name": "only_subscribers",
        },
        model: {
          value: _vm.form.only_subscribers,
          callback: function ($$v) {
            _vm.$set(_vm.form, "only_subscribers", $$v)
          },
          expression: "form.only_subscribers",
        },
      }),
      _vm._v(" "),
      _c("switch-input-component", {
        attrs: {
          label: "Associer le code promo à un ou plusieurs clients existants",
          "input-name": "hasUsers",
        },
        model: {
          value: _vm.hasUsers,
          callback: function ($$v) {
            _vm.hasUsers = $$v
          },
          expression: "hasUsers",
        },
      }),
      _vm._v(" "),
      _vm.isLoading
        ? _c("loader-component")
        : _vm.hasUsers
        ? _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("vue-multiselect", {
                attrs: {
                  options: _vm.users,
                  multiple: true,
                  label: "label",
                  "track-by": "value",
                  placeholder: "Sélectionnez un utilisateur",
                  "close-on-select": false,
                  "show-labels": false,
                  "open-direction": "bottom",
                },
                model: {
                  value: _vm.form.users,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "users", $$v)
                  },
                  expression: "form.users",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }