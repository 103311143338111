var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row mb-2" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("strong", [_vm._v("Responsable")]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.getTooltip(
                        "Réinitialiser les tests d'envois des mails ou des sms"
                      ),
                      expression:
                        "getTooltip('Réinitialiser les tests d\\'envois des mails ou des sms')",
                    },
                  ],
                  staticClass: "btn btn-secondary btn-square btn-sm mr-2",
                  attrs: { disabled: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.canShowResetLimiterModal = true
                    },
                  },
                },
                [_c("feather", { attrs: { type: "refresh-ccw" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.getTooltip(_vm.$t("labels.form.actions.edit")),
                      expression: "getTooltip($t('labels.form.actions.edit'))",
                    },
                  ],
                  staticClass: "btn btn-success btn-square btn-sm mr-2",
                  attrs: { disabled: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("edit-owner")
                    },
                  },
                },
                [_c("feather", { attrs: { type: "edit" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.getTooltip(
                        _vm.$t("labels.form.actions.delete")
                      ),
                      expression:
                        "getTooltip($t('labels.form.actions.delete'))",
                    },
                  ],
                  staticClass: "btn btn-danger btn-square btn-sm",
                  attrs: { disabled: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.deleteOwner()
                    },
                  },
                },
                [_c("feather", { attrs: { type: "trash-2" } })],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col-7" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.capitalize(_vm.ownerDetails.firstname)) +
              "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "col-7" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.capitalize(_vm.ownerDetails.lastname)) +
              "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticClass: "col-7" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.ownerDetails.email) + "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm._m(3),
        _vm._v(" "),
        _c("div", { staticClass: "col-7" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.ownerDetails.tel) + "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.isNoShow
        ? _c("div", { staticClass: "row" }, [
            _vm._m(4),
            _vm._v(" "),
            _c("div", { staticClass: "col-7" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.ownerDetails.fiducial_client_id || "Non renseigné"
                  ) +
                  "\n        "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isYservices
        ? _c("div", { staticClass: "row" }, [
            _vm._m(5),
            _vm._v(" "),
            _c("div", { staticClass: "col-7" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.ownerDetails.yprox_user_id || "Non renseigné") +
                  "\n        "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm._m(6),
        _vm._v(" "),
        _c("div", { staticClass: "col-7" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.moment(_vm.ownerDetails.created_at).format("DD MMMM Y")
              ) +
              "\n            "
          ),
          _vm.isYservices
            ? _c(
                "button",
                {
                  staticClass: "btn btn-success btn-sm btn-circle ml-2",
                  attrs: { disabled: _vm.loading },
                  on: { click: _vm.sendResetPasswordLink },
                },
                [
                  _vm._v(
                    "\n                Envoyer un mail de réinitialisation du mot de passe\n            "
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      !_vm.ownerDetails.had_active_subscription
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 text-warning" },
              [
                _c("feather", {
                  staticClass: "text-warning mr-1",
                  attrs: { type: "alert-triangle" },
                }),
                _vm._v("Ce restaurateur n'a jamais payé de licence\n        "),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canShowResetLimiterModal
        ? _c("reset-rate-limiter-modal", {
            attrs: { userId: _vm.ownerDetails.id },
            on: {
              close: function ($event) {
                _vm.canShowResetLimiterModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [
      _c("label", { attrs: { for: "category" } }, [_vm._v("Prénom :")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [
      _c("label", { attrs: { for: "category" } }, [_vm._v("Nom :")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [
      _c("label", { attrs: { for: "category" } }, [_vm._v("Email :")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [
      _c("label", { attrs: { for: "category" } }, [_vm._v("Téléphone :")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [
      _c("label", { attrs: { for: "category" } }, [
        _vm._v("N° client Fiducial :"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [
      _c("label", { attrs: { for: "category" } }, [
        _vm._v("Identifiant du compte Yproximité :"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-5" }, [
      _c("label", { attrs: { for: "category" } }, [
        _vm._v("Date d'inscription :"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }