var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reservations-grid" },
    [
      _c("ns-table", {
        attrs: {
          tableClass: "table table-responsive-lg capitalize table-striped",
          "data-source": _vm.reservations_,
          clickable: "",
          "use-slot-headings": "",
          "toolbar-settings": _vm.toolbarSettings,
        },
        on: {
          "update-columns": function ($event) {
            _vm.columns = $event
          },
          "row-selected": _vm.rowSelected,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "toolbar",
              fn: function () {
                return [_vm._t("toolbar")]
              },
              proxy: true,
            },
            {
              key: "head",
              fn: function () {
                return [
                  _c("ns-headings", {
                    attrs: {
                      headings: _vm.headings,
                      "excluded-columns": _vm.excludedColumns,
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function ({ data }) {
                return [
                  !_vm.excludedColumns.includes(
                    _vm.COLUMN_RESERVATION_DATE.value
                  )
                    ? _c(
                        "td",
                        { staticClass: "none-mobile" },
                        [
                          _c("feather", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.getTooltip(
                                  _vm.displayDate(
                                    data.created_at,
                                    _vm.DATETIME_MED
                                  )
                                ),
                                expression:
                                  "getTooltip(displayDate(data.created_at, DATETIME_MED))",
                              },
                            ],
                            attrs: { type: "clock" },
                          }),
                          _vm._v(" "),
                          _c("reservation-issuer-icon", {
                            staticClass: "ml-1",
                            attrs: {
                              issuer: data.created_from,
                              provider: data.provider_name,
                              "created-at": data.created_at,
                              user: data.user,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("td", [_c("column-client", { attrs: { row: data } })], 1),
                  _vm._v(" "),
                  !_vm.excludedColumns.includes(
                    _vm.COLUMN_RESERVATION_COMPANY.value
                  )
                    ? _c("td", [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              data.client && data.client.company
                                ? data.client.company
                                : ""
                            ) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("td", [
                    data.nb_children
                      ? _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.getTooltip(
                                  _vm.displayPax(
                                    data.total_nb_pers,
                                    data.nb_children
                                  )
                                ),
                                expression:
                                  "getTooltip(displayPax(data.total_nb_pers, data.nb_children))",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(data.total_nb_pers) +
                                "(" +
                                _vm._s(data.nb_children) +
                                ")\n                "
                            ),
                          ]
                        )
                      : _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(data.total_nb_pers) +
                              "\n                "
                          ),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.displayHour(data.slot.hour_start)) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.excludedColumns.includes(
                    _vm.COLUMN_RESERVATION_TABLE.value
                  )
                    ? _c("td", { staticClass: "none-mobile" }, [
                        _c(
                          "div",
                          { staticClass: "table-text-center" },
                          [
                            data.tables.data.length > 0
                              ? _vm._l(
                                  data.tables.data,
                                  function (table, index) {
                                    return _c("span", { key: index }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(table.name) +
                                          _vm._s(
                                            index + 1 < data.tables.data.length
                                              ? " - "
                                              : ""
                                          ) +
                                          "\n                        "
                                      ),
                                    ])
                                  }
                                )
                              : data.num_table && data.num_table !== ""
                              ? [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(data.num_table) +
                                      "\n                    "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.isFeatRoomNumbersEnable || _vm.isHotelModeEnabled) &&
                  !_vm.excludedColumns.includes(
                    _vm.COLUMN_RESERVATION_ROOM.value
                  )
                    ? _c(
                        "td",
                        { staticClass: "none-mobile" },
                        [
                          data.is_hotel_client
                            ? _c("hotel-client-icon")
                            : _vm._e(),
                          _vm._v(
                            "\n                " +
                              _vm._s(data.room_number) +
                              "\n            "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("reservation-tag", {
                        attrs: {
                          editable: _vm.has_right_to_update_reservation,
                          cancellation: _vm.service.cancellation_until,
                          update: false,
                          reservation: data,
                          restaurant_id: _vm.restaurant.id,
                          isStripeEnabled:
                            _vm.restaurant.stripe_client_id ||
                            (_vm.restaurant.payplug_public_key &&
                              _vm.restaurant.payplug_secret_key),
                        },
                        on: {
                          displayNoshowModal: function ($event) {
                            return _vm.emitEvent("displayNoshowModal", data)
                          },
                          displayCancelModal: function ($event) {
                            return _vm.emitEvent("displayCancelModal", {
                              newStatus: $event,
                              data,
                            })
                          },
                          displayRefundPartiallyModal: function ($event) {
                            return _vm.emitEvent(
                              "displayRefundPartiallyModal",
                              data
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.excludedColumns.includes(
                    _vm.COLUMN_RESERVATION_PAYMENT.value
                  )
                    ? _c("td", [
                        _c(
                          "div",
                          { staticClass: "table-text-center" },
                          [
                            _c("option-bank-status-icon", {
                              attrs: {
                                restaurant_id: data.restaurant_id,
                                timezoneForOverride:
                                  _vm.getAutoDetectedTimezone(
                                    data.restaurant_id
                                  ),
                                reservation: data,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("td", { staticClass: "none-mobile d-flex" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getTooltip(
                              _vm.$tl("labels.form.actions.edit")
                            ),
                            expression:
                              "getTooltip($tl('labels.form.actions.edit'))",
                          },
                        ],
                        staticClass: "btn btn-sm btn-success btn-square ml-1",
                        attrs: {
                          type: "button",
                          disabled: !_vm.has_right_to_update_reservation,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.emitEvent(
                              "editReservation",
                              data.id,
                              $event
                            )
                          },
                        },
                      },
                      [_c("feather", { attrs: { type: "edit" } })],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.avoidStatusForDetails.includes(data.status)
                      ? _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.getTooltip(
                                  _vm.$tl(
                                    "labels.booking.reservations.sendDetailsToClient"
                                  )
                                ),
                                expression:
                                  "getTooltip($tl('labels.booking.reservations.sendDetailsToClient'))",
                              },
                            ],
                            staticClass:
                              "btn btn-sm btn-outline-secondary btn-square ml-1",
                            attrs: {
                              type: "button",
                              disabled: !_vm.has_right_to_update_reservation,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.emitEvent(
                                  "sendEmail",
                                  data.id,
                                  $event
                                )
                              },
                            },
                          },
                          [_c("feather", { attrs: { type: "send" } })],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  !_vm.excludedColumns.includes(
                    _vm.COLUMN_RESERVATION_CONFIRMATION.value
                  )
                    ? _c(
                        "td",
                        { staticClass: "none-mobile" },
                        [
                          data.confirmed_by_client !== null
                            ? [
                                data.confirmed_by_client === 1
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm btn-square",
                                        attrs: {
                                          type: "button",
                                          disabled: true,
                                        },
                                      },
                                      [
                                        _c("feather", {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.getTooltip(
                                                _vm.$tl(
                                                  "labels.booking.reservations.confirmationValidated"
                                                )
                                              ),
                                              expression:
                                                "getTooltip($tl('labels.booking.reservations.confirmationValidated'))",
                                            },
                                          ],
                                          staticClass: "feather-green",
                                          attrs: { type: "shield" },
                                        }),
                                      ],
                                      1
                                    )
                                  : data.client !== null
                                  ? _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.getTooltip(
                                              _vm.$tl(
                                                "labels.booking.reservations.confirmManually"
                                              )
                                            ),
                                            expression:
                                              "getTooltip($tl('labels.booking.reservations.confirmManually'))",
                                          },
                                        ],
                                        staticClass:
                                          "btn btn-sm btn-outline-secondary btn-square",
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            !_vm.has_right_to_update_reservation ||
                                            _vm.isLoading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.confirmResaForClient(
                                              data
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("feather", {
                                          staticClass: "feather-orange",
                                          attrs: { type: "shield-off" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canAskConfirmation(data)
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-sm btn-outline-secondary btn-square",
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      !_vm.has_right_to_update_reservation ||
                                      _vm.isLoading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.askConfirmation(data)
                                    },
                                  },
                                },
                                [
                                  _c("feather", {
                                    staticClass: "feather-black",
                                    attrs: { type: "shield" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }