import axios from "axios";

export const actions = {
    fetchCommandByToken({}, { token }) {
        return (axios
            .get(`/api/click_and_collect/p/commands/${token}?include=client,slot,restaurant`));
    },
};

export default actions;
