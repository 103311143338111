import { DateTime } from "luxon";

export default {
    methods: {
        isServiceLive(service, selectedDate = undefined) {
            const now = this.getDateTime();

            if (selectedDate instanceof DateTime && !selectedDate.hasSame(now, "day")) {
                return false;
            }

            const hourBegin = this.selectedService.hour_begin.split(":");
            const hourEnd = this.selectedService.hour_end.split(":");

            if (service.special) {
                const specialDatetimeBegin = this.getDateTime(service.special_datetime_begin).startOf("day");
                const specialDatetimeEnd = this.getDateTime(service.special_datetime_end).endOf("day");

                if (specialDatetimeBegin > now || now >= specialDatetimeEnd) {
                    return false;
                }
            }

            const serviceStartsAt = now.set({ hour: hourBegin[0], minute: hourBegin[1] });
            const serviceEndsAt = now.set({ hour: hourEnd[0], minute: hourEnd[1] });

            if (serviceStartsAt > now || now >= serviceEndsAt) {
                return false;
            }

            return true;
        },
    },
};
