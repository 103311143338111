export default {
    data() {
        return {
            COMMAND_TYPE_BO: {
                value: "bo",
            },
            COMMAND_TYPE_WIDGET: {
                value: "widget",
            },
        };
    },
    methods: {},
    computed: {
        ALL_COMMAND_TYPES() {
            return [this.COMMAND_TYPE_BO, this.COMMAND_TYPE_WIDGET];
        },
    },
};
