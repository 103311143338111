export default {
    data() {
        return {
            RESERVATION_TAG_GLUTEN: {
                frLabel: "Intolérant au gluten",
                value: "gluten",
            },
            RESERVATION_TAG_LACTOSE: {
                frLabel: "Intolérant au lactose",
                value: "lactose",
            },
            RESERVATION_TAG_VEGETARIEN: {
                frLabel: "Végétarien",
                value: "vegetarien",
            },
            RESERVATION_TAG_VEGETALIEN: {
                frLabel: "Végétalien",
                value: "vegetalien",
            },
            RESERVATION_TAG_PMR: {
                frLabel: "PMR",
                value: "pmr",
            },
            RESERVATION_TAG_CHAISE: {
                frLabel: "Chaise haute",
                value: "chaise",
            },
            RESERVATION_TAG_POUSSETTE: {
                frLabel: "Poussette",
                value: "poussette",
            },
            RESERVATION_TAG_ANNIVERSAIRE: {
                frLabel: "Anniversaire",
                value: "anniversaire",
            },
            RESERVATION_TAG_ALLERGIE: {
                frLabel: "Aucune allergie",
                value: "allergie",
            },
        };
    },
    methods: {
        getReservationTagLabel(value) {
            const found = this.ALL_RESERVATION_TAGS.find((w) => w.value == value);
            if (typeof found != "undefined") {
                return this.$t(`labels.booking.reservations.tags.${value}`);
            }
            return value;
        },
        getReservationTagLabelFromFrLabel(frLabel) {
            const found = this.ALL_RESERVATION_TAGS.find((w) => w.frLabel == frLabel);
            if (typeof found != "undefined") {
                return this.$t(`labels.booking.reservations.tags.${found.value}`);
            }
            return undefined;
        },
    },
    computed: {
        ALL_RESERVATION_TAGS() {
            return [
                this.RESERVATION_TAG_GLUTEN,
                this.RESERVATION_TAG_LACTOSE,
                this.RESERVATION_TAG_VEGETARIEN,
                this.RESERVATION_TAG_VEGETALIEN,
                this.RESERVATION_TAG_PMR,
                this.RESERVATION_TAG_CHAISE,
                this.RESERVATION_TAG_POUSSETTE,
                this.RESERVATION_TAG_ANNIVERSAIRE,
                this.RESERVATION_TAG_ALLERGIE,
            ];
        },
    },
};
