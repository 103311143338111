var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasErrors("choosen_menus")
        ? _c(
            "div",
            _vm._l(
              _vm.formErrors.errors.choosen_menus,
              function (error, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "invalid-feedback d-block" },
                  [_vm._v("\n            " + _vm._s(error) + "\n        ")]
                )
              }
            ),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.parsedMenus, function (menu) {
        return _c("div", { key: menu.id, staticClass: "one-menu mb-1" }, [
          _c("div", { staticClass: "row text-left mb-0" }, [
            _c("div", { staticClass: "col-sm-8" }, [
              _c("label", { staticClass: "mb-1" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(menu.name) +
                    _vm._s(
                      menu.price
                        ? ` - ${_vm.formatCurrency(
                            menu.price,
                            _vm.getRestaurantCurrency(_vm.restaurantId)
                          )}`
                        : ""
                    ) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "d-block text-muted mb-2" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(menu.description) +
                    "\n                "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "select",
                {
                  ref: `ref_menu_${menu.id}`,
                  refInFor: true,
                  staticClass: "custom-select",
                  class: { "is-invalid": _vm.hasErrors("choosen_menus") },
                  staticStyle: { height: "44px" },
                  domProps: { value: menu.quantity },
                  on: {
                    change: function ($event) {
                      return _vm.chooseMenu(menu, $event)
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: 0 } }, [_vm._v("0")]),
                  _vm._v(" "),
                  _vm._l(_vm.menusLeftToChoose(menu.quantity), function (nb) {
                    return _c("option", { key: nb, domProps: { value: nb } }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(nb) +
                          "\n                    "
                      ),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          menu.quantity > 0 && menu.options.length > 0
            ? _c(
                "div",
                {
                  staticClass:
                    "p-3 border-right-light border-bottom-light border-left-light",
                },
                _vm._l(menu.options, function (option) {
                  return _c(
                    "div",
                    { key: option.id, staticClass: "row text-left mb-0" },
                    [
                      _c("div", { staticClass: "col-8" }, [
                        _c("label", { staticClass: "mb-0" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(option.name) +
                              _vm._s(
                                option.price
                                  ? ` - ${_vm.formatCurrency(
                                      option.price,
                                      _vm.getRestaurantCurrency(
                                        _vm.restaurantId
                                      )
                                    )}`
                                  : ""
                              ) +
                              "\n                        "
                          ),
                          option.quantity_type === "mandatory_for_each"
                            ? _c("small", { staticClass: "text-muted" }, [
                                _vm._v(
                                  " (" +
                                    _vm._s(_vm.$tl("labels.perPerson")) +
                                    ") "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("small", { staticClass: "d-block text-muted" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(option.description) +
                              "\n                    "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-4" }, [
                        option.quantity_type === "mandatory_for_each"
                          ? _c("div", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: option.quantity,
                                      expression: "option.quantity",
                                    },
                                  ],
                                  ref: `ref_option_${menu.id}${option.id}`,
                                  refInFor: true,
                                  staticClass: "custom-select",
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        option,
                                        "quantity",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "0" } }, [
                                    _vm._v("--"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { domProps: { value: menu.quantity } },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(menu.quantity) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          : option.quantity_type === "limited_by_pers"
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: option.quantity,
                                    expression: "option.quantity",
                                  },
                                ],
                                ref: `ref_option_${menu.id}${option.id}`,
                                refInFor: true,
                                staticClass: "custom-select",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      option,
                                      "quantity",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "0" } }, [
                                  _vm._v("0"),
                                ]),
                                _vm._v(" "),
                                _vm._l(menu.quantity, function (nbOption) {
                                  return _c(
                                    "option",
                                    {
                                      key: nbOption,
                                      domProps: { value: nbOption },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(nbOption) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            )
                          : option.quantity_type === "unlimited"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: option.quantity,
                                  expression: "option.quantity",
                                },
                              ],
                              ref: `ref_option_${menu.id}${option.id}`,
                              refInFor: true,
                              staticClass: "form-control mb-2",
                              attrs: { type: "number", step: "1", min: "0" },
                              domProps: { value: option.quantity },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    option,
                                    "quantity",
                                    $event.target.value
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }