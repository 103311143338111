import { DateTime } from "luxon";

DateTime.prototype.toJSDateCustom = function (withTime = true) {
    if (withTime) {
        return new Date(this.year, this.month - 1, this.day, this.hour, this.minute);
    } else {
        return new Date(this.year, this.month - 1, this.day);
    }
};

/*
(Infos)

export const DATETIME_HUGE_WITH_SECONDS = {
  year: n,
  month: l,
  day: n,
  weekday: l,
  hour: n,
  minute: n,
  second: n,
  timeZoneName: l,
};

*/

const n = "numeric";
const s = "short";
const l = "long";

export default {
    data() {
        // Define custom presets for formatting
        return {
            DATETIME_DEBUG: DateTime.DATETIME_FULL,
            TIME_SIMPLE: DateTime.TIME_SIMPLE, // FR: hh:mm
            DATE_SHORT: DateTime.DATE_SHORT, // FR: DD/MM/YYYY
            DATE_SHORT_WITH_WEEKDAY: {
                ...DateTime.DATE_SHORT, // FR: Lun DD/MM/YYYY
                weekday: s,
            },
            DATE_SHORT_DAY_NO_YEAR: {
                // FR: Day MM
                ...DateTime.DATE_SHORT,
                weekday: l,
                year: undefined,
            },
            DATE_MED: DateTime.DATE_MED, // FR: DD Mo. YYYY
            DATE_MED_DAY_NO_YEAR: {
                // FR: Day DD Mo.
                ...DateTime.DATE_MED,
                weekday: l,
                year: undefined,
            },
            DATE_FULL: DateTime.DATE_FULL, // FR: DD Month YYYY
            DATE_FULL_NO_YEAR: {
                // FR: DD Month
                ...DateTime.DATE_FULL,
                year: undefined,
            },
            DATE_HUGE: DateTime.DATE_HUGE, // FR: Day DD Month YYYY
            DATE_HUGE_NO_YEAR: {
                // Day DD Month
                ...DateTime.DATE_HUGE,
                year: undefined,
            },
            DATETIME_SHORT: DateTime.DATETIME_SHORT, // FR: DATE_SHORT à TIME_SIMPLE
            DATETIME_MED: DateTime.DATETIME_MED, // FR: DATE_MED à TIME_SIMPLE
            DATETIME_FULL: {
                // FR: DATE_FULL à TIME_SIMPLE
                ...DateTime.DATETIME_FULL,
                timeZoneName: undefined,
            },
            DATETIME_HUGE: {
                // FR: DATE_HUGE à TIME_SIMPLE
                ...DateTime.DATETIME_HUGE,
                timeZoneName: undefined,
            },
            DATETIME_SHORT_DAY: {
                // FR: Day DATETIME_SHORT
                ...DateTime.DATETIME_SHORT,
                weekday: l,
            },
        };
    },
    methods: {
        setHourOnDateTime(hour, dateTime = undefined) {
            if (typeof dateTime === "undefined") {
                dateTime = this.getDateTime().startOf("day");
            }
            if (typeof hour === "undefined" || hour === null) {
                hour = "00:00";
            }
            const hourSplit = hour.split(":");
            if (hourSplit.length < 2) {
                return dateTime;
            }
            return dateTime.startOf("day").plus({
                hours: hourSplit[0],
                minutes: hourSplit[1],
            });
        },
        areDatesSame(date1, date2, unit = "day", keepOriginalTimeZone = false) {
            return this.getDateTime(date1, keepOriginalTimeZone).hasSame(this.getDateTime(date2, keepOriginalTimeZone), unit);
        },
        displayDate(date = undefined, format = undefined, keepOriginalTimeZone = true, timeZone = undefined) {
            if (typeof format === "undefined" || format === null) {
                format = this.DATE_FULL;
            }
            if (typeof format === "string") {
                return this.getDateTime(date, keepOriginalTimeZone, timeZone).toFormat(format);
            }
            if (typeof format === "object") {
                return this.getDateTime(date, keepOriginalTimeZone, timeZone).toLocaleString(format);
            }
            console.error(`Invalid format given in DateUtils::displayDate: "${format}", typeof "${typeof format}"`);
            return date;
        },
        getDateTimeFromTimestamp(timestamp, fromMillis = false, changeTz = true, tz = undefined) {
            var dateTime = DateTime.fromMillis(fromMillis ? timestamp : timestamp * 1000);

            if (changeTz) {
                dateTime = dateTime.setZone(tz || this.getAutoDetectedTimezone());
            }

            return dateTime;
        },
        getDateTime(date = undefined, keepOriginalTimeZone = true, timeZone = undefined) {
            const tz = timeZone || this.getAutoDetectedTimezone();
            if (typeof date === "undefined" || date === null) {
                date = DateTime.now();
            } else if (!(date instanceof DateTime)) {
                if (typeof date === "string") {
                    if (keepOriginalTimeZone) {
                        date = DateTime.fromISO(date);
                    } else {
                        date = DateTime.fromISO(date.split("T")[0], {
                            zone: tz,
                        });
                    }
                } else if (date instanceof Date) {
                    if (keepOriginalTimeZone) {
                        date = DateTime.fromJSDate(date);
                    } else {
                        date = DateTime.fromObject(
                            {
                                day: date.getDate(),
                                month: date.getMonth() + 1,
                                year: date.getFullYear(),
                            },
                            {
                                zone: tz,
                            }
                        );
                    }
                } else {
                    console.error(`Could not transform date to luxon DateTime : "${date}", typeof "${typeof date}`);
                    return date;
                }
            }
            return date.setZone(tz);
        },
        getJSDateFromDateTime(date = undefined) {
            if (typeof date === "undefined" || date === null) {
                date = this.getDateTime();
            }
            if (!(date instanceof DateTime)) {
                console.error(`Could not transform date to JS Date object : "${date}", typeof "${typeof date}`);
                return date;
            }
            return date.toJSDateCustom();
        },
        formatHour(hour) {
            const hourSplit = hour.split(":");
            if (hourSplit.length < 2) {
                return hour;
            }
            return hourSplit[0] + "h" + hourSplit[1];
        },
        dayLabel(day, capitalize = false) {
            if (day >= 0 && day <= 6) {
                const dayForEnglishDaysArray = day > 0 ? day - 1 : 6;
                const dayLabel = this.$t(`labels.days.${this.englishDays()[dayForEnglishDaysArray]}`);
                if (!capitalize) {
                    return dayLabel;
                }
                return this.capitalize(dayLabel);
            }
            return day;
        },
        englishDays() {
            return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
        },
        getAutoDetectedTimezone(restaurant_id = null) {
            if (
                typeof this.overrideAutoDetectedTimezoneGlobally !== "undefined" &&
                this.overrideAutoDetectedTimezoneGlobally !== null &&
                this.overrideAutoDetectedTimezoneGlobally !== ""
            ) {
                return this.overrideAutoDetectedTimezoneGlobally;
            }
            if (restaurant_id === null && this.$route && this.$route.params && this.$route.params.restaurant_id) {
                restaurant_id = this.$route.params.restaurant_id;
            }
            if (restaurant_id !== null) {
                const restaurant = this.$store.getters["restaurants/findRestaurantById"](this.$route.params.restaurant_id);
                if (typeof restaurant !== "undefined") {
                    return restaurant.timezone;
                }
            }
            return undefined;
        },
    },
};
