var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c("h5", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v(_vm._s(_vm.modalTitle)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.loading ? _c("LoaderComponent") : _vm._e(),
        _vm._v(" "),
        _vm.restaurant.id
          ? _c(_vm.currentComponent, {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading",
                },
              ],
              tag: "component",
              attrs: {
                restaurant_id: _vm.restaurant.id,
                restaurant: _vm.restaurant,
                formErrors: _vm.formErrors,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex w-100 justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "modal-default-button btn-sm btn btn-secondary btn-circle",
            attrs: { "data-test-id": "btn-close_modal" },
            on: { click: _vm.close },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$tl("labels.form.actions.close")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.step < 2
          ? _c(
              "button",
              {
                staticClass: "btn-sm btn btn-secondary btn-circle",
                on: {
                  click: function ($event) {
                    return _vm.nextStep(false)
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.skip")) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.step < 2
          ? _c(
              "button",
              {
                staticClass: "btn-sm btn btn-secondary btn-circle",
                on: {
                  click: function ($event) {
                    return _vm.nextStep()
                  },
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$tl("labels.form.actions.validate")) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }