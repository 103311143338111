<template>
    <div>
        <button
            type="button"
            class="btn p-0 m-1 feather-blue"
            v-if="rights.includes('booking.booking.create')"
            @click="openAddReservationModal">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-24 feather-plus-circle">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="16"></line>
                <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
        </button>
        <button v-if="is_full === -1" type="button" class="btn p-0 m-1 feather-red" @click="setSlotFullModal">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-24 feather-pause-circle">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="10" y1="15" x2="10" y2="9"></line>
                <line x1="14" y1="15" x2="14" y2="9"></line>
            </svg>
        </button>
        <button v-else type="button" class="btn p-0 m-1 feather-blue" @click="removeSlotFullModal">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-24 feather-play-circle">
                <circle cx="12" cy="12" r="10"></circle>
                <polygon points="10 8 16 12 10 16 10 8"></polygon>
            </svg>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        restaurant_id: {
            required: true,
        },
        service_id: {
            required: true,
        },
        slot_id: {
            required: true,
        },
        full_date: {
            required: true,
        },
        is_full: {
            required: true,
        },
    },
    methods: {
        openAddReservationModal() {
            this.$emit("resa-add", {
                restaurant_id: this.restaurant_id,
                service_id: this.service_id,
                slot_id: this.slot_id,
                full_date: this.full_date,
            });
        },
        setSlotFullModal() {
            this.$emit("slot-full", {
                restaurant_id: this.restaurant_id,
                service_id: this.service_id,
                slot_id: this.slot_id,
                full_date: this.full_date,
            });
        },
        removeSlotFullModal() {
            this.$emit("slot-free", {
                restaurant_id: this.restaurant_id,
                service_id: this.service_id,
                slot_id: this.slot_id,
                full_date: this.full_date,
            });
        },
    },
    computed: {
        rights: function () {
            return this.$store.getters("users/formattedRights");
        },
    },
};
</script>
