<template>
    <div
        v-if="cursorPosition !== null"
        class="position-absolute d-flex flex-column align-items-center justify-content-center"
        :style="`left: ${cursorPosition}px; top: ${headerHeight}px; height: ${cursorHeight}px; z-index: 10;`">
        <span class="position-absolute text-danger" :style="`top: -${fontSize}px`">{{ displayDate(null, TIME_SIMPLE) }}</span>
        <span class="border-left border-danger h-100"></span>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import CalculatePxGap from "./CalculatePxGap.js";

export default {
    data() {
        return {
            triggerComputedUpdate: false,
            triggerComputedUpdateInterval: undefined,
        };
    },
    mixins: [CalculatePxGap],
    props: {
        minutesInterval: {
            type: Number,
            required: true,
        },
        startDateTime: {
            type: DateTime,
            required: true,
        },
        endDateTime: {
            type: DateTime,
            required: true,
        },
        left: {
            type: Number,
            default: 200,
        },
        cellWidth: {
            type: Number,
            default: 50,
        },
        headerHeight: {
            type: Number,
            default: 36,
        },
        fontSize: {
            type: Number,
            default: 16,
        },
        cursorHeight: {
            type: Number,
            default: 36,
        },
    },
    computed: {
        cursorPosition() {
            this.triggerComputedUpdate;

            return this.calculateHorizontalPxGap(
                this.startDateTime,
                this.endDateTime,
                this.getDateTime(),
                this.cellWidth,
                this.minutesInterval,
                this.left
            );
        },
    },
    mounted() {
        this.triggerComputedUpdateInterval = setInterval(() => (this.triggerComputedUpdate = !this.triggerComputedUpdate), 1000);
    },
    beforeDestroy() {
        if (typeof this.triggerComputedUpdateInterval !== "undefined") {
            clearInterval(this.triggerComputedUpdateInterval);

            this.triggerComputedUpdateInterval = undefined;
        }
    },
};
</script>
