export const mutations = {
    addChannel(state, channel) {
        state.channels.push(channel);
        state.channels = state.channels.flat();
    },
    removeChannel(state, channel) {
        var index = state.channels.findIndex((c) => c === channel);

        if (index > -1) {
            state.channels.splice(index, 1);
        }
    },
    removeAllChannels(state) {
        state.channels = [];
    },
};

export default mutations;
