export default {
    data() {
        return {
            CRUD_TYPE_READ: {
                value: "read",
                label: "Lire",
            },
            CRUD_TYPE_CREATE: {
                value: "create",
                label: "Créer",
            },
            CRUD_TYPE_UPDATE: {
                value: "update",
                label: "Modifier",
            },
            CRUD_TYPE_SOFT_DELETE: {
                value: "softdelete",
                label: "Archiver",
            },
            CRUD_TYPE_DELETE: {
                value: "delete",
                label: "Supprimer",
            },
        };
    },
    computed: {
        ALL_CRUD_TYPES() {
            return [this.CRUD_TYPE_READ, this.CRUD_TYPE_UPDATE, this.CRUD_TYPE_CREATE, this.CRUD_TYPE_DELETE];
        },
    },
};
