<template>
    <div class="row mb-3">
        <div class="col-12">
            <loader-component v-if="nbLoading > 0" />
            <div v-else-if="stats.main" class="border-light b-radius-20 p-4">
                <div class="row pointer" @click="showBookingsPeriod = !showBookingsPeriod">
                    <strong class="col-10 d-flex align-items-center">
                        {{ $tl("labels.booking.stats.bookingsDelay.title") }}
                    </strong>
                    <div class="d-flex justify-content-end align-items-center lead-switch col-2">
                        <button
                            class="btn btn-sm btn-square"
                            type="button"
                            :class="pinBookingsPeriod ? 'btn-success' : 'btn-secondary'"
                            @click.stop.prevent="pinBookingsPeriod = !pinBookingsPeriod">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="18px" height="18px" fill="currentColor">
                                <path
                                    d="M300.8 203.9L290.7 128H328c13.2 0 24-10.8 24-24V24c0-13.2-10.8-24-24-24H56C42.8 0 32 10.8 32 24v80c0 13.2 10.8 24 24 24h37.3l-10.1 75.9C34.9 231.5 0 278.4 0 335.2c0 8.8 7.2 16 16 16h160V472c0 .7 .1 1.3 .2 1.9l8 32c2 8 13.5 8.1 15.5 0l8-32c.2-.6 .2-1.3 .2-1.9V351.2h160c8.8 0 16-7.2 16-16 .1-56.8-34.8-103.7-83.1-131.3zM33.3 319.2c6.8-42.9 39.6-76.4 79.5-94.5L128 96H64V32h256v64h-64l15.3 128.8c40 18.2 72.7 51.8 79.5 94.5H33.3z" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div v-if="showBookingsPeriod || pinBookingsPeriod" class="row mt-3">
                    <div class="pl-1 pl-xl-3 pr-3 pr-xl-1 pb-2" :class="filters.hasComparison && stats.comparison ? 'col-xl-6 col-12' : 'col-xl-12'">
                        <div
                            class="p-1 d-flex flex-column align-items-center h-100"
                            :class="filters.hasComparison && stats.comparison ? 'border-light' : 'col-xl-12'">
                            <strong class="text-success py-3">{{ labelPeriodMain }}</strong>
                            <div v-if="stats.main && stats.main.length === 0" class="h-100 d-flex align-items-center">
                                <strong>
                                    {{ $tl("labels.booking.stats.noDataOnPeriod") }}
                                </strong>
                            </div>
                            <v-chart
                                v-else
                                class="chart"
                                :option="{
                                    title: bookingsPeriod.title,
                                    series: pieChartDelayResaOnDate,
                                    tooltip: tooltipPeriod,
                                    grid: grid,
                                }">
                            </v-chart>
                        </div>
                    </div>
                    <div v-if="stats.comparison && filters.hasComparison" class="col-xl-6 col-12 pl-1 pr-3 pb-2">
                        <div class="border-light b-radius-20 p-1 d-flex flex-column align-items-center h-100">
                            <strong class="text-success py-3">{{ labelPeriodComparison }}</strong>
                            <div v-if="stats.comparison && stats.comparison.length === 0" class="h-100 d-flex align-items-center">
                                <strong>
                                    {{ $tl("labels.booking.stats.noDataOnPeriod") }}
                                </strong>
                            </div>
                            <v-chart
                                class="chart"
                                :option="{
                                    title: bookingsPeriod.title,
                                    series: pieChartDelayResaOnDateComparison,
                                    tooltip: tooltipPeriod,
                                    grid: grid,
                                }">
                            </v-chart>
                        </div>
                    </div>
                    <div
                        class="pl-1 pl-xl-3 pr-3 pr-xl-1 pb-2 pb-xl-0"
                        :class="filters.hasComparison && stats.comparison ? 'col-xl-6 col-12' : 'col-xl-12'">
                        <div
                            class="p-1 d-flex flex-column align-items-center h-100"
                            :class="filters.hasComparison && stats.comparison ? 'border-light' : 'col-xl-12'">
                            <strong class="text-success py-3">{{ labelPeriodMain }}</strong>
                            <div v-if="statsCreation.main && statsCreation.main.length === 0" class="h-100 d-flex align-items-center">
                                <strong>
                                    {{ $tl("labels.booking.stats.noDataOnPeriod") }}
                                </strong>
                            </div>
                            <v-chart
                                v-else
                                class="chart"
                                :option="{
                                    title: futureBooking.title,
                                    series: pieChartDelayOnCreationDate,
                                    tooltip: tooltipPeriod,
                                    grid: grid,
                                }">
                            </v-chart>
                        </div>
                    </div>
                    <div v-if="stats.comparison && filters.hasComparison" class="col-xl-6 col-12 pl-1 pr-3">
                        <div class="border-light b-radius-20 p-1 d-flex flex-column align-items-center h-100">
                            <strong class="text-success py-3">{{ labelPeriodComparison }}</strong>
                            <div v-if="statsCreation.comparison && statsCreation.comparison.length === 0" class="h-100 d-flex align-items-center">
                                <strong>
                                    {{ $tl("labels.booking.stats.noDataOnPeriod") }}
                                </strong>
                            </div>
                            <v-chart
                                v-else
                                class="chart"
                                :option="{
                                    title: futureBooking.title,
                                    series: pieChartDelayOnCreationDateComparison,
                                    tooltip: tooltipPeriod,
                                    grid: grid,
                                }">
                            </v-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VChart from "vue-echarts";
import StatsUtils from "../../mixins/helpers/booking/StatsUtils.js";
import LoaderComponent from "../LoaderComponent.vue";

export default {
    name: "BookingsPeriodComponent",
    props: {
        grid: {
            required: true,
            type: Object,
        },
        filters: {
            required: true,
            type: Object,
        },
    },
    mixins: [StatsUtils],
    data() {
        return {
            showBookingsPeriod: false,
            bookingsPeriod: {
                title: {
                    text: this.$tl("labels.booking.stats.charts.bookingsPeriod.title"),
                    left: "center",
                    textStyle: {
                        fontWeight: "normal",
                    },
                },
            },
            futureBooking: {
                title: {
                    text: this.$tl("labels.booking.stats.charts.futureBooking.title"),
                    left: "center",
                    textStyle: {
                        fontWeight: "normal",
                    },
                },
            },
            stats: {
                main: null,
                comparison: null,
            },
            statsCreation: {
                main: null,
                comparison: null,
            },
        };
    },
    computed: {
        pinBookingsPeriod: {
            get() {
                return this.$store.getters["userPreferences/pinBookingsPeriod"];
            },
            set(newVal) {
                this.$store.dispatch("userPreferences/setPinBookingsPeriod", newVal);
            },
        },
        pieChartDelayResaOnDate() {
            return [
                {
                    type: "pie",
                    data: this.seriesPieChartDelayResaOnDate,
                    color: ["#5490ff", "#8c54ff", "#bc43e1", "#d343bc", "#e1438c"],
                },
            ];
        },
        seriesPieChartDelayResaOnDate() {
            const stats = this.stats.main;
            return this.mapTransToSeries(stats);
        },
        pieChartDelayResaOnDateComparison() {
            return [
                {
                    type: "pie",
                    data: this.seriesPieChartDelayResaOnDateComparison,
                    color: ["#5490ff", "#8c54ff", "#bc43e1", "#d343bc", "#e1438c"],
                },
            ];
        },
        seriesPieChartDelayResaOnDateComparison() {
            const stats = this.stats.comparison;
            return this.mapTransToSeries(stats);
        },
        pieChartDelayOnCreationDate() {
            return [
                {
                    type: "pie",
                    data: this.seriesPieChartDelayOnCreationDate,
                    color: ["#5490ff", "#8c54ff", "#bc43e1", "#d343bc", "#e1438c"],
                },
            ];
        },
        seriesPieChartDelayOnCreationDate() {
            const stats = this.statsCreation.main;
            return this.mapTransToSeriesCreation(stats);
        },

        pieChartDelayOnCreationDateComparison() {
            return [
                {
                    type: "pie",
                    data: this.seriesPieChartDelayOnCreationDateComparison,
                    color: ["#5490ff", "#8c54ff", "#bc43e1", "#d343bc", "#e1438c"],
                },
            ];
        },
        seriesPieChartDelayOnCreationDateComparison() {
            const stats = this.statsCreation.comparison;
            return this.mapTransToSeriesCreation(stats);
        },
        tooltipPeriod() {
            return {
                trigger: "item",
                formatter: (params) => {
                    const so = this.$tl("labels.booking.stats.charts.tooltip.so");
                    const nb = params.value;
                    const reservations = this.$tcl("labels.booking.stats.charts.tooltip.reservations", nb);
                    return `<b>${params.name}</b><br />${reservations}, ${so} ${params.percent}%<br/>`;
                },
            };
        },
    },
    methods: {
        fetchStats(abortControllerSignal = null, forComparison = false) {
            return new Promise((resolve) => {
                const promises = [];

                promises.push(this.fetchStatsReservation(abortControllerSignal, forComparison));
                promises.push(this.fetchStatsCreation(abortControllerSignal, forComparison));

                Promise.all(promises).finally(() => resolve());
            });
        },
        fetchStatsReservation(abortControllerSignal = null, forComparison = false) {
            const filters = this.getFiltersQueryParams(forComparison);

            return this.httpGet(`/api/stats/reservations-delay-on-reservation-date?${filters}`, {
                config: { signal: abortControllerSignal },
                handleReject: false,
            }).then((response) => {
                if (response !== false) {
                    this.stats[forComparison ? "comparison" : "main"] = response.data;
                }
            });
        },
        fetchStatsCreation(abortControllerSignal = null, forComparison = false) {
            const filters = this.getFiltersQueryParams(forComparison);

            return this.httpGet(`/api/stats/reservations-delay-on-creation-date?${filters}`, {
                config: { signal: abortControllerSignal },
                handleReject: false,
            }).then((response) => {
                if (response !== false) {
                    this.statsCreation[forComparison ? "comparison" : "main"] = response.data;
                }
            });
        },
        mapTransToSeries(stats) {
            const translationMap = {
                same_day: this.$tl("labels.booking.stats.reservations.same_day"),
                last_week: this.$tl("labels.booking.stats.reservations.last_week"),
                last_2_weeks: this.$tl("labels.booking.stats.reservations.last_2_weeks"),
                last_month: this.$tl("labels.booking.stats.reservations.last_month"),
                other: this.$tl("labels.booking.stats.reservations.other"),
            };

            return stats.map((item) => ({
                name: translationMap[item.reservation_delay],
                value: item.count_reservations,
            }));
        },
        mapTransToSeriesCreation(stats) {
            const translationMap = {
                same_day: this.$tl("labels.booking.stats.reservations.same_day"),
                last_week: this.$tl("labels.booking.stats.reservations.next_week"),
                last_2_weeks: this.$tl("labels.booking.stats.reservations.next_2_weeks"),
                last_month: this.$tl("labels.booking.stats.reservations.next_month"),
                other: this.$tl("labels.booking.stats.reservations.other"),
            };

            return stats.map((item) => ({
                name: translationMap[item.reservation_delay],
                value: item.count_reservations,
            }));
        },
    },
    components: { LoaderComponent, VChart },
    watch: {
        pinBookingsPeriod(newVal) {
            if (!newVal) {
                this.showBookingsPeriod = false;
            }
        },
    },
};
</script>

<style scoped>
button.btn:disabled {
    cursor: default;
}

.chart {
    height: 450px;
}
</style>
