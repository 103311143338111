<template>
    <div class="pl-3 pr-3">
        <div v-if="servicesToDisplay.length > 0" v-for="(service, serviceIndex) in servicesToDisplay">
            <div v-if="service.reservations.data && service.reservations.data.length > 0">
                <div class="d-flex flex-wrap justify-content-between flex-md-nowrap pb-1 mb-2 border-bottom">
                    <h6 style="text-transform: uppercase; margin-bottom: 0.3em" class="pt-1">
                        <span class="capacite badge badge-secondary">
                            {{ service.name }}
                        </span>
                        <template v-if="!service.has_closure">
                            <button
                                v-if="service.is_full === 1"
                                class="btn badge badge-danger"
                                style="color: white !important"
                                @click="showServiceFull(service.id)">
                                {{ $tl("labels.booking.services.closed").toUpperCase() }}
                            </button>
                            <button v-if="service.is_full === 0" class="btn text-white badge btn-warning" @click="showServiceFull(service.id)">
                                {{ $tl("labels.booking.services.closedPartially").toUpperCase() }}
                            </button>
                        </template>
                        <span v-if="service.has_closure" class="badge badge-danger">
                            {{ $tl("labels.booking.closures.title").toUpperCase() }}
                        </span>
                    </h6>
                    <h6 class="pt-2 mb-2">
                        <span class="capacite badge badge-secondary"
                            >{{ service.pax }} / {{ service.new_pax !== null ? service.new_pax : service.max_pax }}</span
                        >
                    </h6>
                </div>
                <table class="capitalize table-size-30 table table-book table-striped table-sm mb-3 border-bottom table-tr-clickable">
                    <thead class="border-bottom">
                        <tr>
                            <th>
                                <feather type="user" class="feather-20 none-desk" />
                                <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.client") }}</span>
                            </th>
                            <th>
                                <feather type="users" class="feather-20 none-desk" />
                                <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.pax") }}</span>
                            </th>
                            <th>
                                <feather type="clock" class="feather-20 none-desk" />
                                <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.hour") }}</span>
                            </th>
                            <th class="none-mobile">
                                <span>{{ $tl("labels.booking.reservations.grid.columns.table", restaurantId) }}</span>
                            </th>
                            <th v-if="isFeatRoomNumbersEnable || isHotelModeEnabled" class="none-mobile">
                                <span>{{ $tl("labels.booking.reservations.grid.columns.room_number") }}</span>
                            </th>
                            <th class="none-mobile">
                                <span>{{ $tl("labels.booking.reservations.grid.columns.status") }}</span>
                            </th>
                            <th class="none-mobile">
                                <span>{{ $tl("labels.booking.reservations.grid.columns.optionBankStatus") }}</span>
                            </th>
                            <th>
                                <feather type="mouse-pointer" class="feather-20 none-desk" />
                                <span class="none-mobile">{{ $tl("labels.booking.reservations.grid.columns.actions") }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            id="resaRow"
                            v-for="reservation in service.reservations.data"
                            :key="`${service.id}-${reservation.id}`"
                            v-on:click="showResa(reservation.id, $event)"
                            :class="{
                                'resa-checked': reservation.status === 'over',
                                'resa-noshow': reservation.status === 'noshow',
                                updated: reservation.updated && reservation.updated === true,
                                new: reservation.new && reservation.new === true,
                            }">
                            <td>
                                <inline-client
                                    :client="reservation.client"
                                    :public-comment="reservation.comment"
                                    :private-comment="reservation.restaurant_comment"
                                    :module-enum="MODULE_TYPE_BOOKING"
                                    show-noshows>
                                    <template v-slot:icons-start>
                                        <feather v-if="reservation.gift" type="tag" class="feather-blue" v-tooltip="getTooltip(reservation.gift)" />
                                    </template>
                                </inline-client>
                            </td>
                            <td class="table-text-center">
                                {{ reservation.nb_pers * 1 + reservation.nb_children * 1 }}
                            </td>
                            <td>
                                {{ reservation.slot.hour_start }}
                            </td>
                            <td class="table-text-center none-mobile">
                                <template v-if="reservation.tables.data.length > 0" v-for="(table, index) in reservation.tables.data">
                                    {{ table.name }}{{ index + 1 < reservation.tables.data.length ? " - " : "" }}
                                </template>
                                <template v-else-if="reservation.num_table && reservation.num_table !== ''">
                                    {{ reservation.num_table }}
                                </template>
                            </td>
                            <td v-if="isFeatRoomNumbersEnable || isHotelModeEnabled" class="none-mobile">
                                <hotel-client-icon v-if="reservation.is_hotel_client" />
                                {{ reservation.room_number }}
                            </td>
                            <td class="none-mobile">
                                <ReservationTag
                                    :cancellation="service.cancellation_until"
                                    :update="false"
                                    :reservation="reservation"
                                    :restaurant_id="restaurant.id"
                                    :isStripeEnabled="restaurant.stripe_client_id || (restaurant.payplug_public_key && restaurant.payplug_secret_key)"
                                    @displayNoshowModal="displaySetNoshowModal(reservation)"
                                    @displayCancelModal="displayCancelModal({ newStatus: $event, data: reservation })" />
                            </td>
                            <td class="table-text-center none-mobile">
                                <option-bank-status-icon :reservation="reservation" />
                            </td>
                            <td>
                                <button
                                    v-if="inEnum(reservation.status, [RESERVATION_STATUS_VALIDATED, RESERVATION_STATUS_CONFIRMED])"
                                    type="button"
                                    class="btn btn-sm btn-success btn-square"
                                    @click="setResaAtTable(reservation.id)"
                                    v-tooltip="getTooltip($tl('labels.booking.reservations.actions.setAtTable'))">
                                    <feather type="check" class="feather-18" style="pointer-events: none" />
                                </button>
                                <button
                                    v-if="inEnum(reservation.status, [RESERVATION_STATUS_OVER, RESERVATION_STATUS_AT_TABLE])"
                                    type="button"
                                    class="btn btn-sm btn-outline-secondary btn-square"
                                    @click="setResaValidatedOrConfirmed(reservation)">
                                    <feather type="x" class="feather-18" style="pointer-events: none" />
                                </button>
                                <button
                                    v-if="
                                        !inEnum(reservation.status, [RESERVATION_STATUS_NOSHOW, RESERVATION_STATUS_AT_TABLE]) &&
                                        reservation.display_noshow_btn
                                    "
                                    type="button"
                                    class="btn btn-sm btn-danger btn-square"
                                    @click="displaySetNoshowModal(reservation)"
                                    v-tooltip="getTooltip($tl('labels.booking.reservations.actions.setNoShow', restaurantId))">
                                    <feather type="alert-triangle" class="feather-18" style="pointer-events: none" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <span v-if="service.reservations.data && service.reservations.data.length > 0" class="badge badge-secondary ml-1 mb-4">
                {{ $tc("labels.booking.reservations.count", service.reservations.data.length) }}
            </span>
        </div>
        <div v-if="services.length > 0 && !hasReservations">
            <div class="row m-0">
                <div class="col-lg-6 offset-lg-3 pt-5 text-center">
                    <div class="border-light b-radius-20 p-4 mb-3">
                        <h5>{{ $tl("labels.booking.list.emptyForService", restaurant.id) }}</h5>
                        <img class="mt-5 none-mobile" width="360" src="/images/empty-table.png" alt="No Show" />
                        <img class="mt-3 none-desk" width="260" src="/images/empty-table.png" alt="No Show" />
                    </div>
                </div>
            </div>
        </div>
        <div class="text-white pb-4">.</div>
        <show-reservation-modal
            v-if="showReservationModal"
            :reservation_id="selected_reservation_id"
            :restaurant_id="selected_restaurant_id"
            @resa-edit="editReservationFromShowModal"
            @close="showReservationModal = false" />
        <edit-reservation-modal
            v-if="showEditReservationModal"
            :restaurant_id="selected_restaurant_id"
            :reservation_id="selected_reservation_id"
            @close="showEditReservationModal = false"
            @reservation-edited="reservationEdited" />
        <set-noshow-modal
            v-if="showSetNoshowModal"
            :restaurant_id="selected_restaurant_id"
            :reservation="selected_reservation"
            @close="showSetNoshowModal = false" />
        <invalid-reservation-modal v-if="showInvalidReservationModal" :reservation="selected_reservation" @close="showInvalidReservationModal" />
        <cancel-reservation-modal
            v-if="showCancelModal"
            :reservation-id="selected_reservation_id"
            :client="selected_reservation_client"
            :status="newCancelStatus"
            @close="showCancelModal = false" />
    </div>
</template>

<script>
import ReservationTag from "../reservations/ReservationTagComponent";
import OptionBankStatusIcon from "../stripe/OptionBankStatusIcon";
import ShowReservationModal from "../Modals/reservations/ShowReservationModal";
import EditReservationModal from "../Modals/reservations/EditReservationModal";
import SetNoshowModal from "../Modals/reservations/SetNoshowModal.vue";
import InvalidReservationModal from "../Modals/reservations/InvalidReservationModal";
import ReservationStatusEnum from "../../mixins/enums/booking/ReservationStatusEnum";
import LangsEnum from "../../mixins/enums/LangsEnum";
import InlineClient from "../Default/Clients/inlineClient.vue";
import ModuleTypesEnum from "../../mixins/enums/ModuleTypesEnum.js";
import HotelClientIcon from "./hotelClientIcon.vue";
import CancelReservationModal from "../Modals/reservations/CancelReservationModal.vue";

export default {
    props: {
        services: {
            required: true,
            type: Array,
        },
        restaurant: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            showReservationModal: false,
            showEditReservationModal: false,
            showInvalidReservationModal: false,
            showSetNoshowModal: false,
            selected_reservation_id: null,
            selected_reservation_client: null,
            selected_restaurant_id: null,
            selected_reservation: null,
            newCancelStatus: "canceled",
            showCancelModal: false,
        };
    },
    computed: {
        restaurantId() {
            return Number.parseInt(this.$route.params.restaurant_id);
        },
        isFeatRoomNumbersEnable() {
            return this.restaurant.feat_room_numbers;
        },
        isHotelModeEnabled() {
            return this.$store.getters["widgets/getWidget"].hotel_mode;
        },
        hasReservations() {
            for (const index in this.services) {
                if (this.services[index].reservations.data && this.services[index].reservations.data.length > 0) return true;
            }

            return false;
        },
        servicesToDisplay() {
            var result = [];

            this.services.forEach((service) => {
                result.push(this.computeService(service));
            });

            return result;
        },
    },
    methods: {
        displayCancelModal({ newStatus, data }) {
            this.selected_reservation_id = data.id;
            this.selected_reservation_client = data.client;
            this.newCancelStatus = newStatus;
            this.showCancelModal = true;
        },
        showServiceFull(id) {
            this.$parent.$emit("show-service-full", {
                service_id: id,
            });
        },
        reservationEdited() {
            this.$parent.fetchData();
            this.showEditReservationModal = false;
            this.notifySuccess(null, this.$tl("success.booking.reservations.edited"));
        },
        editReservationFromShowModal({ reservation_id }) {
            this.$set(this, "selected_reservation_id", reservation_id);
            this.$set(this, "selected_restaurant_id", this.restaurantId);
            this.$nextTick(() => {
                this.showReservationModal = false;
                this.showEditReservationModal = true;
            });
        },
        showResa(id, event) {
            const excludedTags = ["BUTTON", "A"];

            if (!event || !event.target || !excludedTags.includes(event.target.tagName)) {
                this.$set(this, "selected_restaurant_id", this.restaurantId);
                this.$set(this, "selected_reservation_id", id);
                this.$nextTick(() => {
                    this.showReservationModal = true;
                });
            }
        },
        setResaAtTable(reservation_id) {
            return axios
                .put(
                    `/api/restaurants/${this.restaurantId}/reservations/${reservation_id}/status?status=${this.RESERVATION_STATUS_AT_TABLE.value}&include=client,slot,tables,slot.service`
                )
                .then((response) => {
                    this.$emit("reservation-updated", response.data.data);
                    this.notifySuccess(null, this.$tl("success.booking.reservations.updated"));
                })
                .catch((error) => {
                    this.notifyError(error);
                });
        },
        setResaValidatedOrConfirmed(reservation) {
            let status = this.RESERVATION_STATUS_VALIDATED.value;
            if (reservation.confirmed_by_client === 1) status = this.RESERVATION_STATUS_CONFIRMED.value;
            axios
                .put(
                    `/api/restaurants/${this.restaurantId}/reservations/${reservation.id}/status?status=${status}&include=client,slot,tables,slot.service`
                )
                .then((response) => {
                    this.$emit("reservation-updated", response.data.data);
                    this.notifySuccess(null, this.$tl("success.booking.reservations.updated"));
                })
                .catch((error) => {
                    this.notifyError(error);
                });
        },
        displaySetNoshowModal(reservation) {
            this.$set(this, "selected_restaurant_id", this.restaurantId);
            this.$set(this, "selected_reservation", reservation);

            this.$nextTick(() => {
                this.$set(this, "showSetNoshowModal", true);
            });
        },
        computeService(service) {
            var result = {
                id: service.id,
                name: service.name,
                category: service.category,
                category_en: service.category_en,
                days: service.days,
                hour_begin: service.hour_begin,
                hour_end: service.hour_end,
                max_pax: service.max_pax,
                new_pax: service.new_pax,
                lock_reservation_until: service.lock_reservation_until,
                special: service.special,
                special_date_begin: service.special_date_begin,
                special_date_end: service.special_date_end,
                menu_unique: service.menu_unique,
                menu_unique_from: service.menu_unique_from,
                prepayment_mandatory: service.prepayment_mandatory,
                prepayment_percent: service.prepayment_percent,
                cancellation_until: service.cancellation_until,
                is_full: service.is_full,
                has_closure: service.has_closure,
                reservations: [],
                pax: 0,
            };

            var { reservations, pax } = this.computeReservations(service.reservations.data);

            result.reservations.data = reservations;
            result.pax = pax;

            return result;
        },
        computeReservations(reservations) {
            var result = [];
            var pax = 0;
            const avoidStatusForPax = [
                this.RESERVATION_STATUS_CANCELED,
                this.RESERVATION_STATUS_NOSHOW,
                this.RESERVATION_STATUS_REFUSED,
                this.RESERVATION_STATUS_PENDING,
            ];
            const acceptedStatus = [
                this.RESERVATION_STATUS_VALIDATED,
                this.RESERVATION_STATUS_CONFIRMED,
                this.RESERVATION_STATUS_OVER,
                this.RESERVATION_STATUS_NOSHOW,
                this.RESERVATION_STATUS_AT_TABLE,
            ];

            reservations.forEach((reservation) => {
                if (this.inEnum(reservation.status, acceptedStatus) && !reservation.is_passing_customer) {
                    result.push(reservation);
                }

                if (!this.inEnum(reservation.status, avoidStatusForPax)) {
                    pax += reservation.nb_children;
                    pax += reservation.nb_pers;
                }
            });

            result.sort(this.sortReservations);

            return { reservations: result, pax };
        },
        sortReservations(reservationA, reservationB) {
            const resaDateTimeA = this.getDateTime(reservationA.reservation_datetime);
            const resaDateTimeB = this.getDateTime(reservationB.reservation_datetime);

            if (resaDateTimeA < resaDateTimeB) {
                return -1;
            }

            if (resaDateTimeA > resaDateTimeB) {
                return 1;
            }

            return 0;
        },
        showInvalidResa(reservation) {
            this.$set(this, "selected_reservation", reservation);
            this.$nextTick(() => {
                this.$set(this, "showInvalidReservationModal", true);
            });
        },
    },
    components: {
        InvalidReservationModal,
        ReservationTag,
        OptionBankStatusIcon,
        ShowReservationModal,
        EditReservationModal,
        SetNoshowModal,
        InlineClient,
        HotelClientIcon,
        CancelReservationModal,
    },
    mixins: [ReservationStatusEnum, LangsEnum, ModuleTypesEnum],
};
</script>
