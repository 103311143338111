var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nbLoading > 0
    ? _c("loader-component")
    : _c(
        "div",
        { staticClass: "home-vue p-2" },
        [
          _c("div", { staticClass: "row none-mobile m-0" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
                },
                [
                  _c("h5", { staticClass: "title mt-2" }, [
                    _vm._v(_vm._s(_vm.$t("labels.routes.booking.globalStats"))),
                  ]),
                  _vm._v(" "),
                  _vm.hasRightToExportStats && !_vm.isAdmin
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-sm btn-circle btn-outline-secondary",
                          staticStyle: { border: "1px solid #30a4b7" },
                          attrs: {
                            type: "button",
                            disabled: _vm.filters === null,
                          },
                          on: { click: _vm.excelExport },
                        },
                        [
                          _c("feather", {
                            staticClass: "mr-1",
                            attrs: { type: "download-cloud" },
                          }),
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t("labels.form.actions.excelExport")
                              ) +
                              "\n                "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          !_vm.hasRights
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("errors.common.rights.notEnoughForGlobalReport")
                    ) +
                    "\n    "
                ),
              ])
            : [
                _c(
                  "div",
                  { staticClass: "pt-0 pr-3 pb-3 pl-3" },
                  [
                    _c("div", { staticClass: "row mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("booking-stats-filters", {
                            ref: "filters",
                            attrs: { "is-global-report": true },
                            on: {
                              "filters-updated": _vm.onFiltersUpdated,
                              "update-rwg": _vm.checkRwg,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.filters !== null
                      ? _c("origin-booking-component", {
                          attrs: {
                            filters: _vm.filters,
                            "has-rwg": _vm.hasRwg,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.filters !== null
                      ? _c("details-per-restaurants", {
                          attrs: {
                            filters: _vm.filters,
                            "has-rwg": _vm.hasRwg,
                            statsType: _vm.StatsType.RESERVATIONS_COUNT,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.filters !== null
                      ? _c("nb-pax-by-origin-component", {
                          attrs: {
                            filters: _vm.filters,
                            "has-rwg": _vm.hasRwg,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.filters !== null
                      ? _c("details-per-restaurants", {
                          attrs: {
                            filters: _vm.filters,
                            "has-rwg": _vm.hasRwg,
                            statsType: _vm.StatsType.RESERVATIONS_PAX,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.filters !== null
                      ? _c("waiting-list-component", {
                          attrs: { filters: _vm.filters },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.filters !== null
                      ? _c("details-per-restaurants-waiting-list", {
                          attrs: { filters: _vm.filters },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }