<template>
    <modal @close="closeFromModal">
        <h5 slot="header">Scanner une commande</h5>
        <div slot="body">
            <div v-if="error" class="alert alert-danger">
                <p>
                    {{ error }}
                </p>
            </div>
            <qrcode-stream v-if="showValidateCommandModal === false" @decode="commandFound" @init="onInit" />
            <validate-command-modal
                v-if="showValidateCommandModal === true"
                :command_id="command_id"
                :restaurant_id="restaurant_id"
                @close="showValidateCommandModal = false"
                @validate-command="
                    $emit('validate-command', $event);
                    showValidateCommandModal = false;
                " />
            <invalid-command-modal
                v-if="showInvalidCommandModal === true"
                :command="command"
                @close="showInvalidCommandModal = false" />
        </div>
        <div slot="footer" class="d-flex w-100 justify-content-between">
            <button class="modal-default-button btn-sm btn btn-secondary btn-circle" @click="close">Fermer</button>
        </div>
    </modal>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import InvalidCommandModal from "./Scan/InvalidCommandModal.vue";
import ValidateCommandModal from "./Scan/ValidateCommandModal.vue";

export default {
    name: "ClickAndCollectScanCommand",
    data() {
        return {
            error: null,
            showInvalidCommandModal: false,
            showValidateCommandModal: false,
            command_id: null,
            command: null,
        };
    },
    props: {
        commands: {
            required: true,
        },
    },
    computed: {
        restaurant_id() {
            return this.$route.params.restaurant_id;
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        commandFound(decoded) {
            if (!this.isLoading) {
                this.isLoading = true;
                this.error = null;
                this.findCommandByToken(decoded).then(({ command, valid }) => {
                    if (command) {
                        if (valid) {
                            this.command_id = command.id;
                            this.$nextTick(() => {
                                this.showValidateCommandModal = true;
                            });
                        } else {
                            this.command = command;
                            this.$nextTick(() => {
                                this.showInvalidCommandModal = true;
                            });
                        }
                    } else {
                        this.error = "La réservation n'existe pas";
                    }

                    this.isLoading = false;
                });
            }
        },
        onInit(promise) {
            promise.catch((error) => {
                if (error.name === "NotAllowedError") {
                    this.error = "Veuillez autoriser l'accès a la caméra.";
                } else if (error.name === "NotFoundError") {
                    this.error = "Aucune caméra disponible.";
                } else if (error.name === "NotSupportedError" || error.name === "InsecureContextError") {
                    this.error = "Veuillez utiliser cette fonctionnalité dans un contexte sécurisé (HTTPS).";
                } else if (error.name === "NotReadableError") {
                    this.error = "La caméra est déjà utilisée.";
                } else if (error.name === "OverconstrainedError") {
                    this.error = "Les caméra disponibles ne sont pas compatibles";
                } else if (error.name === "StreamApiNotSupportedError") {
                    this.error = "Votre navigateur ne supporte pas cette fonctionnalité";
                }
            });
        },
        async findCommandByToken(token) {
            for (var command of this.commands) {
                if (command.token === token) {
                    return {
                        valid: true,
                        command,
                    };
                }
            }

            try {
                const response = await this.$store.dispatch("ccCommands/fetchByToken", {
                    restaurant_id: this.restaurant_id,
                    token,
                });

                return {
                    valid: false,
                    command: response.data.data,
                };
            } catch (error) {
                return {
                    valid: false,
                    command: null,
                };
            }
        },
        closeValidatePopUp() {
            this.showValidateCommandModal = false;
        },
    },
    components: {
        QrcodeStream,
        ValidateCommandModal,
        InvalidCommandModal,
    },
};
</script>
