import axios from "axios";
import moment from "moment";

export const actions = {
    fetchSubscriptionsWithStripe({}, { params, no_group_by }) {
        if (typeof no_group_by == "undefined") no_group_by = 1;
        return axios.get("/api/stripe/licences", {
            params: {
                ...params,
                no_group_by,
            },
        });
    },
    fetchCanceledSubscriptions({}, { params, include }) {
        if (typeof include == "undefined") include = "module,owner.restaurants";
        return axios.get("/api/admin/subscriptions/canceled", {
            params: {
                ...params,
                include,
            },
        });
    },
    fetchActiveSubscriptions({}, { params, include }) {
        if (typeof include == "undefined") include = "module,owner,restaurant";
        return axios.get("/api/admin/subscriptions/active", {
            params: {
                ...params,
                include,
            },
        });
    },
    fetchInvoices({}, { params, type }) {
        return axios.get(`/api/admin/invoices/${type}`, {
            params,
        });
    },
    deleteOwner({}, { owner_id }) {
        return axios.delete(`/api/admin/owners/${owner_id}`);
    },
    deleteEmployee({}, { employee_id }) {
        return axios.delete(`/api/admin/employees/${employee_id}`);
    },
    exportSubscriptionsCA({}, { params }) {
        return axios.get("/api/admin/exports/subscriptions_ca", { params });
    },
    fetchSubscriptionsWithStripeInvoices({}, { params }) {
        return axios.get("/api/admin/subscriptions/all", {
            params,
        });
    },
    exportSubscriptionsCAPerMonth({}, { params }) {
        return axios.get("/api/admin/exports/subscriptions_ca_per_month", { params });
    },
};

export default actions;
