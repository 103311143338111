var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5" },
    [
      _c("div", { staticClass: "row m-0" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1",
            },
            [
              _c("h5", { staticClass: "title mt-2" }, [_vm._v("Ventes")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success mr-1 btn-circle",
                  attrs: { disabled: !_vm.has_right_to_create },
                  on: { click: _vm.updateSale },
                },
                [
                  _c("feather", { attrs: { type: "plus" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v(" Ajouter un bon cadeau ")]),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("gift-vouchers-action-bar", {
        attrs: { "advanced-filters": _vm.advancedFilters },
        on: {
          "fetch-data": _vm.updateDataFromFilterEvent,
          "show-modal": function ($event) {
            _vm.canDisplayModal[$event] = true
          },
          "export-sales": _vm.exportSales,
        },
      }),
      _vm._v(" "),
      _vm.isLoading
        ? _c("loader-component")
        : _c("div", { staticClass: "row m-0" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("ns-table", {
                  attrs: {
                    tableClass:
                      "table table-striped overflowx-auto table-responsive-xl",
                    "data-source": _vm.sales,
                    "is-server-side": "",
                    "use-slot-headings": "",
                    clickable: "",
                    "allow-paging": "",
                    pagination: _vm.pagination,
                    "current-sort": _vm.queries.sort,
                  },
                  on: {
                    "update-data": _vm.updateDataFromTableEvent,
                    "row-selected": _vm.rowSelected,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "head",
                      fn: function () {
                        return [
                          _c("ns-headings", {
                            attrs: { headings: _vm.headings },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function ({ data }) {
                        return [
                          _c("td", [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.getTooltip(
                                      _vm.getTooltipLabel(data)
                                    ),
                                    expression:
                                      "getTooltip(getTooltipLabel(data))",
                                  },
                                ],
                                staticClass: "btn btn-sm btn-square",
                                attrs: { disabled: _vm.canUpdateSaleUse(data) },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateUseSale(data)
                                  },
                                },
                              },
                              [
                                _c("feather", {
                                  class: data.used ? "feather-green" : "",
                                  attrs: {
                                    type: data.used ? "check-square" : "square",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(data.numero))]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c("gift-voucher-status-component", {
                                attrs: {
                                  sale: data,
                                  editable: _vm.has_right_to_update,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(_vm.formatAmount(data)))]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c("gift-vouchers-grid-product", {
                                attrs: { sale: data },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(_vm.formatDate(data.expires_on))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              data.client
                                ? _c("inline-client", {
                                    attrs: {
                                      client: data.client,
                                      "module-enum":
                                        _vm.MODULE_TYPE_GIFT_VOUCHERS,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(_vm.formatBeneficiaryName(data))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.getExpeditionTypeLabel(
                                      data.expedition_type
                                    )
                                  ) +
                                  "\n                        "
                              ),
                              data.expedition_type ===
                              _vm.EXPEDITION_TYPE_POSTAL_MAIL.value
                                ? _c("feather", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.getTooltip(
                                          _vm.getDeliveryAddressWithName(data),
                                          { html: true }
                                        ),
                                        expression:
                                          "getTooltip(getDeliveryAddressWithName(data), { html: true })",
                                      },
                                    ],
                                    staticClass: "ml-1",
                                    attrs: { type: "truck" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              data.sale_type !== _vm.SALE_TYPE_IMPORTED.value
                                ? _c("gift-vouchers-grid-actions", {
                                    attrs: {
                                      sale: data,
                                      "restaurant-id": _vm.restaurantId,
                                      "has-right-to-update":
                                        _vm.has_right_to_update,
                                      disabled: _vm.isDisabled,
                                      "is-fragment-loading":
                                        _vm.isFragmentLoading,
                                    },
                                    on: {
                                      "edit-sale": function ($event) {
                                        return _vm.updateSale($event, data)
                                      },
                                      "send-gv-details":
                                        _vm.sendGvDetailsToClient,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
      _vm._v(" "),
      _vm.canDisplayModal.show && _vm.has_right_to_read
        ? _c("show-gift-voucher-modal", {
            attrs: { sale_id: _vm.selectedSale.id, "must-not-emit": "" },
            on: {
              close: function ($event) {
                _vm.canDisplayModal.show = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.canDisplayModal.add &&
      (_vm.has_right_to_create || _vm.has_right_to_update)
        ? _c("add-gift-voucher-modal", {
            attrs: { sale: _vm.selectedSale },
            on: {
              saved: function ($event) {
                return _vm.refreshSales("add")
              },
              close: function ($event) {
                _vm.canDisplayModal.add = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.canDisplayModal.scan &&
      (_vm.has_right_to_create || _vm.has_right_to_update)
        ? _c("scan-gift-voucher-modal", {
            on: {
              close: function ($event) {
                _vm.canDisplayModal.scan = false
              },
              updated: _vm.scanUpdatedGvSale,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.canDisplayModal.import
        ? _c("import-gift-vouchers-modal", {
            on: {
              imported: function ($event) {
                return _vm.refreshSales("import")
              },
              close: function ($event) {
                _vm.canDisplayModal.import = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }