var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      staticClass: "settings-menu nav nav-tabs",
      class: [
        {
          "flex-column": _vm.direction === "vertical",
          "flex-row": _vm.direction === "horizontal",
        },
        _vm.side,
      ],
      attrs: { role: "tablist" },
    },
    _vm._l(_vm.activeEntries, function (entry, index) {
      return _c("TabBarItem", {
        key: index,
        class: _vm.itemClasses,
        attrs: {
          entry: entry,
          "is-event-mode": _vm.isEventMode,
          "is-active": _vm.active === index,
        },
        on: {
          "set-active": function ($event) {
            _vm.active = index
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }