<template>
    <div>
        <div class="row m-0">
            <div class="col-12">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 pt-2 mt-1 mb-1">
                    <h5 class="title mt-2">{{ this.$tl("labels.form.facturation.titleShort") }}</h5>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-6">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <stripe-customer-form
                        class="mb-2"
                        :errors="formErrors"
                        v-model="billingDetails"
                        :editable="facturationEditable"
                        :show-buttons="false" />
                </div>
            </div>
            <div class="col-6">
                <div class="border-light b-radius-20 p-4 mb-3">
                    <licences-details
                        v-if="!only_option"
                        :plan="plan"
                        :training="training"
                        :modules="modules"
                        @update-promo-code="promoCode = $event" />
                    <stripe-customer-card-list v-model="cardSelected" />
                </div>
                <div class="row m-0 mt-2">
                    <div v-if="cgvsError" class="alert-danger alert position-relative" style="top: 0px">
                        {{ $tl("errors.cgvs.servicesCp") }}
                    </div>
                    <label v-for="module in modulesWithCgvId" :key="module.id" class="col-md-12 container-box" style="width: 100%">
                        <input
                            type="checkbox"
                            :id="`accept-cgv-${module.cgvId}`"
                            :name="`accept-cgv-${module.cgvId}`"
                            @change="cgvAcceptationChanged($event, module.type)"
                            required />
                        <!--v-model="user.accept_data_processing"-->

                        <span class="checkmark"></span>{{ $tl("labels.form.licences.CGU1") }}
                        <a class="alert-link" :href="`/cgu_${module.cgvId}.pdf?v=${$__BUILD_ID__}`" target="_blank"
                            >{{ $tl("labels.form.licences.CGU2") }} {{ getModuleTitle(module) }}</a
                        >.
                    </label>

                    <div class="col-12 text-right">
                        <button type="button" @click.prevent.stop="$emit('back')" class="btn btn-secondary btn-circle">
                            {{ $tl("labels.form.actions.back") }}
                        </button>
                        <button
                            v-if="cardSelected"
                            type="button"
                            class="btn btn-success btn-circle ml-2"
                            :class="{ disabled: loading }"
                            :disabled="loading"
                            @click.prevent.stop="createSubscription">
                            {{ $tl("labels.form.actions.finalizeRegistration") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StripeCustomerForm from "../../stripe/Customer/Form.vue";
import StripeCustomerCardList from "../../stripe/Customer/PaymentMethods/CardList";
import LicencesDetails from "./Details.vue";
import LangsEnum from "../../../mixins/enums/LangsEnum";

export default {
    name: "PayLicence",
    data() {
        return {
            loading: false,
            cardSelected: null,
            formErrors: null,
            errorMessage: null,
            promoCode: null,
            billingDetails: {
                company: "",
                company_type: "",
                company_siret: "",
                company_address: "",
                company_zipcode: "",
                company_city: "",
                company_country: "",
                company_code_naf: "",
                company_code_tva: "",
            },
            stripe: {
                api: null,
            },
            cgvs: {},
            cgvsError: false,
        };
    },
    mixins: [LangsEnum],
    props: {
        modules: {
            required: true,
        },
        plan: {
            required: true,
        },
        redirect: {
            default: null,
        },
        restaurant_id: {
            default: null,
        },
        only_option: {
            default: false,
        },
        training: {
            default: false,
        },
        facturationEditable: {
            default: false,
        },
    },
    components: {
        LicencesDetails,
        StripeCustomerForm,
        StripeCustomerCardList,
    },
    created() {
        this.initStripe();
    },
    updated() {
        this.initStripe();
    },
    computed: {
        modulesWithCgvId() {
            return this.modules.map((m) => {
                return {
                    ...m,
                    cgvId: m.type.replace(/-/g, "_"),
                };
            });
        },
        builtData() {
            if (!this.only_option)
                return {
                    billing_details: this.billingDetails,
                    modules: this.modules.map((module) => {
                        return {
                            id: module.id,
                            plan: this.plan,
                            count: module.count,
                            count_option_dispatch: module.count_option_dispatch || null,
                        };
                    }),
                    restaurant_id: this.restaurant_id,
                    training: this.training,
                    promocode: this.promoCode,
                };
            const module = this.modules[0];
            return {
                billing_details: this.billingDetails,
                modules: {
                    option_type: module.option_type,
                    subscription_id: module.subscription_id,
                },
                restaurant_id: this.restaurant_id,
                training: this.training,
                url_suffix: "_dispatch",
                promocode: this.promoCode,
            };
        },
    },
    methods: {
        initStripe() {
            if (this.stripe.api === null) {
                this.stripe.api = Stripe(STRIPE_PUBLIC_API_KEY, {
                    locale: this.userLang,
                });
            }
        },
        createSubscription() {
            this.errorMessage = null;
            this.formErrors = null;
            if (this.checkCgvs()) {
                this.loading = true;
                this.$store
                    .dispatch("stripe/createSubscription", this.builtData)
                    .then((response) => {
                        if (response.data.pending_setup_intent) {
                            this.stripe.api.confirmCardSetup(response.data.pending_setup_intent).then((result) => {
                                if (result.error) {
                                    this.errorMessage = result.error.message;
                                } else {
                                    if (this.redirect) {
                                        this.$router.push(this.redirect).then(() => {
                                            this.$router.go(0);
                                        });
                                    } else {
                                        this.$router.go(0);
                                    }
                                }
                            });
                        } else {
                            if (this.redirect) {
                                this.$router.push(this.redirect).then(() => {
                                    this.$router.go(0);
                                });
                            } else {
                                this.$router.go(0);
                            }
                        }
                    })
                    .catch((error) => {
                        if (error.response && error.response.data.errors) {
                            this.formErrors = error.response.data.errors;
                        }

                        if (error.response && error.response.data.message) {
                            this.errorMessage = error.response.data.message;
                        } else if (error.message) {
                            this.errorMessage = error.message;
                        } else {
                            this.errorMessage = "Une erreur est survenue.";
                        }
                        this.$notify({
                            group: "notification",
                            type: "error",
                            title: this.errorMessage,
                        });
                        this.loading = false;
                    });
            }
        },
        checkCgvs() {
            for (var module of this.modules) {
                if (!this.cgvs[module.type]) {
                    this.cgvsError = true;
                    return false;
                }
            }

            this.cgvsError = false;
            return true;
        },
        cgvAcceptationChanged(e, module_type) {
            this.$set(this.cgvs, module_type, e.target.checked);
        },
    },
};
</script>
