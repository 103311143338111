var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "credit-card p-4 text-right" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", [
      _c("span", [_vm._v(_vm._s(_vm.card.brand))]),
      _vm._v(" "),
      _c("span", [_vm._v("XXXX XXXX XXXX " + _vm._s(_vm.card.last4))]),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          _vm._s(
            _vm.$t("labels.payments.expiresOn", {
              date:
                _vm.card.exp_month.toString().padStart(2, "0") +
                "/" +
                _vm.card.exp_year,
            })
          )
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-chip" }, [
      _c("div", { staticClass: "card-line" }),
      _vm._v(" "),
      _c("div", { staticClass: "card-line" }),
      _vm._v(" "),
      _c("div", { staticClass: "card-line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }